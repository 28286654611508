import {
  connectGizmo,
  type Dispatch,
  type MapContentDictToProps,
} from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import type { DropTargetContent, FreeDragItemTranslate } from '@bettermarks/gizmo-types';

import {
  type DropTargetCallbacks,
  DropTargetGizmo,
  type DropTargetGizmoProps,
} from './DropTargetGizmo';
import { resolveDragSource } from '@bettermarks/importers';
import { dropAction, removeItem } from '../actions';
import { gizmoAction } from '../../../gizmo-utils/redux/gizmoActions';

export const mapContentDictToProps: MapContentDictToProps<
  DropTargetContent,
  DropTargetGizmoProps
> = (content, contentDict) => {
  const resolved = resolveDragSource(contentDict, content);
  return {
    items: [
      {
        ...content.items[0],
        resolved,
      },
    ],
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): DropTargetCallbacks => ({
  onDropItem: (source: string, freeDragItemTranslate: FreeDragItemTranslate) =>
    dispatch(gizmoAction(dropAction({ source, target: refid, freeDragItemTranslate }), refid)),
  onRemoveItem: (index: number, freeDragItemTranslate: FreeDragItemTranslate) =>
    dispatch(gizmoAction(removeItem({ source: refid, index, freeDragItemTranslate }), refid)),
});

export const DropTargetContainer = connectGizmo(mapDispatchToProps, DropTargetGizmo, {
  mapContentDictToProps,
});
