import * as React from 'react';
import styles from './MathAlien.scss';

export type MathAlienProps = {
  children: JSX.Element;
};

export const MathAlien: React.FC<MathAlienProps> = ({ children }) => (
  <span className={styles.mathalien}>{children}</span>
);

MathAlien.displayName = 'MathAlien';
