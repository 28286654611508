import { type Action, handleActions } from 'redux-actions';
import {
  SET_DROP_TARGET_DEFAULT_CONTENT,
  type SetDropTargetContent,
} from '@bettermarks/gizmo-types';
import { RESET_ERROR_STATE, SET_DROP_TARGET_SIZE, type SizePayload } from '../actions';
import { omit } from 'lodash';

export type SetDropTargetPayload = SizePayload | number;

export const setDropTargetReducer = handleActions<SetDropTargetContent, SetDropTargetPayload>(
  {
    [SET_DROP_TARGET_SIZE]: (content, { payload }: Action<SizePayload>) =>
      payload
        ? {
            ...content,
            width: payload.width,
            height: payload.height,
          }
        : content,
    [RESET_ERROR_STATE]: (content, { payload }: Action<number>) =>
      payload && payload < content.items.length
        ? {
            ...omit(content, ['severity']),
            items: [
              ...content.items.slice(0, payload),
              omit(content.items[payload], ['severity']),
              ...content.items.slice(payload + 1),
            ],
          }
        : content,
  },
  {
    ...SET_DROP_TARGET_DEFAULT_CONTENT,
  }
);
