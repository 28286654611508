import React from 'react';
import type { ApplicationState } from '../../types';
import { gizmoRegistry } from '../../gizmo-utils/configuration/gizmos';
import styles from '../seriesplayer/containers/Exercise/exercise.scss';
import { ContentBox } from '../seriesplayer/components';
import { useDispatch, useSelector } from 'react-redux';
import { isTouchDevice } from '../seriesplayer/services';
import { DEFAULT_MEDIA_ROUTE, numberFromStyles } from '@bettermarks/gizmo-types';

export function ExerciseWithSolution() {
  const { setting, step, availableWidth, staticMediaUrl } = useSelector(
    (state: ApplicationState) => ({
      setting: state.series.exercises[0].setting,
      step: state.series.exercises[0].steps[0],
      availableWidth: state.runtimeState.availableWidth,
      staticMediaUrl: state.appSettings.staticMediaUrl,
    })
  );

  const dispatch = useDispatch();

  const availableChildWidth = availableWidth - numberFromStyles(styles.PADDING_SETTING_HALF) * 2;

  return (
    <div className={styles.containerExample} style={{ maxWidth: availableWidth }}>
      <div className={styles.setting}>
        <ContentBox
          availableWidth={availableChildWidth}
          contentDict={setting}
          dispatch={dispatch}
          gizmoRegistry={gizmoRegistry}
          isTouch={isTouchDevice()}
          staticMediaUrl={DEFAULT_MEDIA_ROUTE}
          dataCy={'setting-example'}
        />
      </div>
      <div className={styles.wrapupExample}>
        <ContentBox
          contentDict={step.explanation}
          inactive={false}
          dispatch={dispatch}
          availableWidth={availableChildWidth}
          gizmoRegistry={gizmoRegistry}
          isTouch={isTouchDevice()}
          staticMediaUrl={staticMediaUrl}
          dataCy={`explanation-example`}
        />
        <ContentBox
          contentDict={step.answer}
          dispatch={dispatch}
          availableWidth={availableChildWidth}
          gizmoRegistry={gizmoRegistry}
          isTouch={isTouchDevice()}
          staticMediaUrl={staticMediaUrl}
          dataCy={`answer-example`}
        />
      </div>
    </div>
  );
}
