import { noop } from 'lodash';
import log from 'loglevel';
import * as StackTrace from 'stacktrace-js';
import { type Metadata } from './metadata';

export const enum ExceptionType {
  api = 'api',
  component = 'component',
  devtools = 'devtools',
  other = 'other',
  reducer = 'reducer',
  saga = 'saga',
  validation = 'validation',
}

type Level = {
  label: string;
  value: number;
};

export type LogLevelObject = {
  level: Level;
  logger: string;
  message: string | LogObject[];
  metadata?: Metadata;
  stacktrace: string;
  timestamp: string;
};

export type Extra = Nullable<Record<string, any>>;

export type BmLogObject = {
  extra?: Extra;
  message: string;
};

export type ExceptionLogObject = {
  exceptionType: ExceptionType;
  extra?: Extra;
  message: string;
  stacktrace?: string;
};

export type LogObject = BmLogObject | ExceptionLogObject;

export const isObjectLog = (log: LogLevelObject): log is LogLevelObject =>
  typeof log.message === 'object' && log.message.length >= 1 && typeof log.message[0] === 'object';

export type LogLevelFunctionName = keyof Pick<log.Logger, 'error' | 'warn'>;
const logStack =
  (logObject: ExceptionLogObject, loglevel: LogLevelFunctionName) =>
  (stack: StackTrace.StackFrame[]) => {
    logObject.stacktrace = stack.map((frame) => frame.toString()).join('\n');
    log.getLogger('exception')[loglevel](logObject);
  };

export const exceptionLog = (
  exceptionType: ExceptionType,
  error: Error,
  extra?: Extra,
  loglevel: LogLevelFunctionName = 'error'
) => {
  const logObject: ExceptionLogObject = {
    exceptionType,
    message: error.message,
    ...(extra && { extra }),
  };

  StackTrace.fromError(error).then(logStack(logObject, loglevel)).catch(noop);
};
