import {
  annotationInner,
  DAY_DURATION_DEFAULT_CONTENT,
  type DayDurationGizmoContent,
  type Duration,
  exportContent,
  type Exporter,
  semantics,
  TIME_DURATION_DEFAULT_CONTENT,
  type TimeDurationGizmoContent,
  type TimeSpanGizmoContent,
} from '@bettermarks/gizmo-types';

const DAY_DURATION_CONTENT = (duration: Duration): DayDurationGizmoContent => ({
  ...DAY_DURATION_DEFAULT_CONTENT,
  ...duration,
  labelRef: duration.contentRefs[0],
});

const TIME_DURATION_CONTENT = (duration: Duration): TimeDurationGizmoContent => {
  const [hourRef, minuteRef] = duration.contentRefs;
  return {
    ...TIME_DURATION_DEFAULT_CONTENT,
    ...duration,
    hourRef,
    minuteRef,
  };
};

export const exportTimeSpan: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as TimeSpanGizmoContent;
  const { contentRefs, thirdDuration } = content;
  let thirdDurationStr = '';
  if (thirdDuration) {
    // We construct a new context for the third duration
    // to directly use the {day,time} duration gizmo exporter
    const THIRD_DURATION_REFID = 'thirdDurationRefid';
    const thirdDurationContext = {
      exporters: context.exporters,
      content: {
        // just keeping duration content references from the root context
        ...thirdDuration.contentRefs.reduce(
          (obj, ref) => ({ ...obj, [ref.$refid]: context.content[ref.$refid] }),
          {}
        ),
        [THIRD_DURATION_REFID]:
          thirdDuration.contentRefs.length === 1
            ? DAY_DURATION_CONTENT(thirdDuration)
            : TIME_DURATION_CONTENT(thirdDuration),
      },
    };
    thirdDurationStr = exportContent(THIRD_DURATION_REFID, thirdDurationContext);
  }
  return semantics(
    `<mrow>
      ${contentRefs.reduce((str, { $refid }) => str + exportContent($refid, context), '')}
      ${thirdDurationStr}
    </mrow>`,
    annotationInner(content.$, content)
  );
};
