import * as React from 'react';
import { KeyboardLayout, type LayoutProps } from './KeyboardLayout';
import { CharBlock } from './CharBlock';
import { NumBlock } from './NumBlock';
import { OperatorBlock } from './OperatorBlock';
import { KeyPage } from './KeyPage';
import { padOperators, sortOperators } from './helpers';

export const ExtraLargeLayout: React.FC<LayoutProps> = ({
  onKey,
  hasNumbers,
  hasDecimalPoint,
  hasLetters,
  operators,
}) => (
  <KeyboardLayout size="extra-large" selectedPage={0}>
    <KeyPage onKey={onKey} slim>
      {hasNumbers && <NumBlock onKey={onKey} slim hasDecimalPoint={hasDecimalPoint} />}
      {hasLetters && <CharBlock onKey={onKey} size="extralarge" />}
      <OperatorBlock onKey={onKey} operators={padOperators(sortOperators(operators, true), 2)} />
    </KeyPage>
  </KeyboardLayout>
);

ExtraLargeLayout.displayName = 'ExtraLargeLayout';
