import { isMI, type MToken } from '@bettermarks/gizmo-types';
import { type EnrichMathContent } from '../../measure/measureStatic';
import { enrich, getFontLayoutMetrics } from '../helpers';

export const enrichMToken: EnrichMathContent<MToken> = (formulaStyles, content) => {
  const fontStyle = isMI(content) && !formulaStyles.fontStyle ? 'italic' : formulaStyles.fontStyle;
  const lm = getFontLayoutMetrics(formulaStyles.fontSize, formulaStyles.fontWeight, fontStyle);
  return enrich(
    content,
    /* When the fontStyle is italic, there is a chance that ascent and descent touch each other
    in case of (sub-/super-) scripts.
    So we simulate this behaviour by setting a negative padding value
    This works nicely for superscript but not so nice for subScript.
    See http://trac.bm.loc/ticket/42550 for more details
     */
    fontStyle === 'italic' ? { ...lm, padding: -1 } : lm,
    formulaStyles
  );
};
