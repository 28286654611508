import log from 'loglevel';
import { call, type Effect, select } from 'redux-saga/effects';

import { ApplicationState } from '../../types';
import { type Content } from '@bettermarks/gizmo-types';

import { type ReadonlyDict } from '../append-only';
import { combinedKey } from '../configuration/combinedKey';

import { type GizmoAction, gizmoActionType } from './gizmoActions';

type GizmoSaga = (ownRefId: string, content: Content, payload: any) => IterableIterator<Effect>;

export type GizmoSagas = ReadonlyDict<GizmoSaga>;
export type GizmoSagaRegistry = ReadonlyDict<GizmoSagas>;

/**
 * Creates a saga that selects corresponding sagas from a registry, depending
 * on the gizmo type. Put the side effect code for your specific gizmo in one
 * of these and register them in the registry. It can be found in the
 * configuration directory - where all other gizmo registries reside.
 * @param registry The gizmo registry
 *
 * TODO check if this is still required since we are now able to do selection of gizmos without saga
 */
export const createGizmoSaga = (registry: GizmoSagaRegistry) =>
  // The types.ts in the signature are mainly there to make tsc happy in the tests
  function* gizmoSaga({ type, meta, payload }: GizmoAction<any>) {
    const actionType = gizmoActionType(type);
    if (!actionType || !meta) {
      // This is not a gizmo action, or it is a gizmo-action without a gizmoId (undo/redo)
      return;
    }

    const state: ApplicationState = yield select();
    const content = ApplicationState.toGizmoContent(meta.gizmoId, state).get(state);
    if (!content) {
      // We set this from error to warn for now. This can only happen, when after performing a gizmo
      // action, something major like validation happens and before the gizmo action arrives here,
      // we already have different content.
      const step = ApplicationState.toCurrentStep.get(state);
      log.debug({
        message: 'gizmoSaga skips, content not found in contentDict',
        extra: {
          actionType,
          meta: JSON.stringify(meta),
          payload: JSON.stringify(payload),
          stepStatus: step && step.status,
        },
      });
      return;
    }

    const sagas = registry[combinedKey(content)];
    if (sagas) {
      const mappedGizmoSaga = sagas[actionType];
      if (mappedGizmoSaga) {
        yield call(mappedGizmoSaga, meta.gizmoId, content, payload);
      }
    }
  };
