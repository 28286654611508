import * as React from 'react';
import classNames from 'classnames';

import { CX, CY, WIDTH } from './constants';
import styles from '../FormStyles.scss';

export type PieSegmentProps = {
  index: number;
  denom: number;
  filled: boolean;
  hover: boolean;
  pxWidth: number;
  onToggleSegment?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

export const PieSegment: React.FC<PieSegmentProps> = ({
  index,
  denom,
  filled,
  hover,
  pxWidth,
  onToggleSegment,
  onMouseOver,
  onMouseLeave,
}) => {
  const theta = 0.5 * Math.PI + 2 * Math.PI * (index / denom);
  const phi = (2 * Math.PI) / denom;

  const onePX = WIDTH / pxWidth;
  const radius = CX - onePX;

  const startx = CX + radius * Math.cos(theta);
  const starty = CY + radius * Math.sin(theta);
  const endx = CX + radius * Math.cos(theta + phi);
  const endy = CY + radius * Math.sin(theta + phi);

  const path = [
    `M${CX},${CY}`,
    `L${startx},${starty}`,
    `A${radius},${radius} 0 0 1 ${endx},${endy}`,
    'Z',
  ].join('');

  const className = classNames(filled ? styles.filled : styles.empty, {
    [styles.hover]: hover,
  });
  return (
    <path
      d={path}
      className={className}
      onClick={onToggleSegment}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};
