import * as React from 'react';
import { range } from 'lodash';
import { CircleShape, type ShapePadding } from '../../components/BasicShape';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';

import { Background } from './components/Background';
import { type BallotBoxContent } from '@bettermarks/gizmo-types';

export type BallotBoxProps = BallotBoxContent & ContextState;

const BIRO_BORDER_WIDTH = 2.5;

const BALLOT_WIDTH = 132;
const BALLOT_HEIGHT = 165;
const BALLOT_LEFT_PADDING = 8;
const BALLOT_BOTTOM_PADDING = 18;

const MAX_CIRCLE_PER_ROW = 4;
/*
  This value is required for correct spacing between Biros,
  The actual size of Biro svg is 32, we want the paddings to overlap
  when two Biros are neighbours.
*/
const BIRO_MIDDLE_WIDTH_HEIGHT = 28;

const BALLOT_TOP_PADDING =
  BALLOT_HEIGHT - BALLOT_BOTTOM_PADDING - BIRO_MIDDLE_WIDTH_HEIGHT * MAX_CIRCLE_PER_ROW;
// Needed to substract SVG_CIRCLE_RADIUS by 0.5,
// to scope with new radius calculation from scale factor
const BIRO_SCALE_FACTOR = 1.5;
// svg coordinate system starts from top-left corner,
// But we want the distribution to start from bottom
const FIRST_BIRO_X = 0;
const FIRST_BIRO_Y = BIRO_MIDDLE_WIDTH_HEIGHT * 3;
// Dimensions without Ballot Box background
const INNER_WIDTH_HEIGHT = (BIRO_MIDDLE_WIDTH_HEIGHT + 1) * MAX_CIRCLE_PER_ROW;

type Positions = ReadonlyArray<[number, number]>;

const createRow = (y: number): Positions =>
  range(0, MAX_CIRCLE_PER_ROW).map<[number, number]>((value) => [
    FIRST_BIRO_X + BIRO_MIDDLE_WIDTH_HEIGHT * value,
    y,
  ]);

const POSITIONS: Positions = [
  ...createRow(FIRST_BIRO_Y),
  ...createRow(FIRST_BIRO_Y - BIRO_MIDDLE_WIDTH_HEIGHT),
  ...createRow(FIRST_BIRO_Y - BIRO_MIDDLE_WIDTH_HEIGHT * 2),
  ...createRow(FIRST_BIRO_Y - BIRO_MIDDLE_WIDTH_HEIGHT * 3),
];

const BIRO_PADDING: Readonly<ShapePadding> = {
  top: 4,
  bottom: 4,
  left: 4,
  right: 4,
};

export const BallotBox: React.FC<BallotBoxProps> = ({ items, showBackground }) => {
  const width = showBackground ? BALLOT_WIDTH : INNER_WIDTH_HEIGHT;
  const height = showBackground ? BALLOT_HEIGHT : INNER_WIDTH_HEIGHT;
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      {showBackground && <Background />}
      {items.map((item, index) => {
        const [x, y] = POSITIONS[index];
        const left = showBackground ? BALLOT_LEFT_PADDING : 0;
        const top = showBackground ? BALLOT_TOP_PADDING : 0;
        return (
          <g transform={`translate(${x + left} ${y + top})`} key={index}>
            <CircleShape
              scalefactor={BIRO_SCALE_FACTOR}
              color={item.color}
              gradientType="radial"
              label={item.label}
              padding={BIRO_PADDING}
              borderWidth={BIRO_BORDER_WIDTH}
            />
          </g>
        );
      })}
    </svg>
  );
};

BallotBox.displayName = 'BallotBox';
