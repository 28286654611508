import {
  type Content,
  type ContentDict,
  type ContentReference,
  type FieldSetContent,
  getSeverityFromChildren,
  hasInteractionType,
  type Importer,
  RS,
  resolveContentReferences,
  type FormulaContent,
} from '@bettermarks/gizmo-types';
import { importFormulaSemantics } from '../formula/importer';

/**
 * Creates a filter for ContentReferences that only keeps those,
 * that are of renderStyle `INNER_TEXT`.
 *
 * @param {ContentDict} contentDict
 * @returns {(ContentReference) => boolean}
 */
const isInnerText =
  (contentDict: ContentDict) =>
  ({ $refid }: ContentReference): boolean =>
    (contentDict[$refid] as Content).$renderStyle === RS.INNER_TEXT;

/**
 * Finds out if any of the inner field set is interactive
 * @param {string[]} innerTextRefIds
 * @param {ContentDict} contentDict
 * @return {boolean}
 */
const hasInteractiveChild = (innerTextRefIds: string[], contentDict: ContentDict): boolean =>
  innerTextRefIds.some((refId: string) => hasInteractionType(contentDict[refId]));

/**
 * Wraps around `importFormulaSemantics` since the XML structure is the same.
 *
 */
export const importFieldSet: Importer<FieldSetContent> = (preContent, xml, outer) => {
  const context = outer.tempContext();

  // Get the formula content of the fieldSet renderStyle
  const importedFormula = context.invoke(importFormulaSemantics, xml);

  const contentDict = context.contentDict;
  const innerTextRefIds = resolveContentReferences(importedFormula)
    .filter(isInnerText(contentDict))
    .map(({ $refid }) => $refid);

  innerTextRefIds.forEach((innerId, innerIndex) => {
    const leftSibling = innerTextRefIds[innerIndex - 1];
    const rightSibling = innerTextRefIds[innerIndex + 1];
    const innerContent = {
      ...(contentDict[innerId] as FormulaContent),
      leftSibling,
      rightSibling,
    };

    outer.content.set(innerId, innerContent);
  });

  const fieldSetDecoration = getSeverityFromChildren(innerTextRefIds, contentDict);

  return {
    ...importedFormula,
    innerTextRefIds,
    hasInteractiveChild: hasInteractiveChild(innerTextRefIds, contentDict),
    ...(fieldSetDecoration && { fieldSetDecoration }),
  };
};
