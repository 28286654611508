import React from 'react';
import { DEFAULT_GAP } from './constants';

export interface VLayoutContext {
  wrap: boolean;
  marginTop: number;
  maxWidth?: number;
}

export interface HLayoutContext {
  margin: number;
}

export const VLayoutContext = React.createContext<VLayoutContext>({
  wrap: true,
  marginTop: DEFAULT_GAP,
  maxWidth: 0,
});

export const HLayoutContext = React.createContext<number>(DEFAULT_GAP);
