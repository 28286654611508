import { type MouseEvent } from 'react';
import { get } from 'lodash';

import {
  type AdvancedAxisObject,
  axisType,
  AxisType,
  type Coords,
  CursorPositionOffset,
  type GeoContentBase,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import {
  AXIS_MAJOR_TICKS_PIXEL,
  MAX_POINT_RADIUS,
  PIXEL_PER_XTICK,
  PIXEL_PER_YTICK,
  SVG_BORDER_EXTENSION,
  SVG_CANVAS,
  X_ADVANCED_AXIS_DEFAULT_LABEL_HEIGHT,
  X_AXIS_TICK_LABEL_OFFSET,
  X_SIMPLE_AXIS_DEFAULT_LABEL_HEIGHT,
  Y_ADVANCED_AXIS_DEFAULT_DIGIT_WIDTH,
  Y_ADVANCED_AXIS_DEFAULT_LABEL_WIDTH,
  Y_AXIS_TICK_LABEL_OFFSET,
  Y_SIMPLE_AXIS_DEFAULT_DIGIT_WIDTH,
} from '../constants';
import { round } from './numbers';
import { GENERATOR_MAX_DECIMALS } from '../tools_constants';

/**
 * Given an 'SVG' 2D transformation matrix array [a, b, c, d, e, f], the according 2d
 * transformation matrix (using homogeneous coordinates) without rotation! is defined by
 *
 *    a    c=0  e
 *    b=0  d    f
 *    0    0    1
 *
 *   with translation: (e,f) and scaling(a, d), we will get the 'world to screen'
 *   transformation by
 *
 *    screenX = a * worldX + e
 *    screenY = d * worldY + f
 *
 *   and the 'screen to world' transformation by
 *
 *    worldX = (screenX - e) / a
 *    worldY = (screenY - f) / d
 *
 *    We will call this array [a, b, c, d, e, f] 'matrix' or 'the matrix'.
 **/

/**
 * Creates an SVG transformation matrix (array) by the given params in world coords. Additionally,
 * the total width and height of the whole Geo scene (in Screen(pixel) units) will be generated!
 *
 * @param content (GeoContentBase =
 *   GeoContent wout the values that are calculated with this function)
 * @param configuration (of type GeoConfiguration)
 * @param horizontalAxis (of type AxisType)
 * @param verticalAxis (of type AxisType)
 * @param isInteractive (of type boolean)
 * @param scale (of type number)
 */
/* eslint-disable-next-line complexity */
export const transformationSettings = (
  content: GeoContentBase,
  scale = content.scale,
  configuration = content.configuration,
  horizontalAxis = content.horizontalAxis,
  verticalAxis = content.verticalAxis,
  isInteractive = !!content.$interactionType
): {
  matrix: number[];
  gridWidth: number;
  gridHeight: number;
  totalWidth: number;
  totalHeight: number;
} => {
  const {
    display: { cx, cy, width, height },
    tickValueInterval,
    borderExtension,
    showBorder,
    showNullLabel,
  } = configuration;

  const paintedBorder = !!content.grid || showBorder;

  // the translate X part in Pixels ...
  const transX = PIXEL_PER_XTICK * (width * 0.5 - cx);

  // the xLabelspace and yLabelspace denote some amount of space to fit all axis labels
  // the additional space the yAxis label needs
  let xAxisLabelSpace = 0;
  /**
   * xAxisToBorder - Signed distance of x-axis to geo border:
   *
   * POSITIVE:
   * ___________________
   * |                  |
   * |                  |
   * |                  |
   * |        c         | -     -
   * |                  | | cy  |
   * |     --------->x  | -     | height
   * |__________________|       -
   *
   * NEGATIVE:
   * ___________________
   * |                  |
   * |                  |
   * |                  |
   * |        c         | -     -
   * |                  | |     |
   * |                  | |     | height
   * |__________________| | cy  -
   *                      |
   *       --------->x    -
   */
  const xAxisToBorder = height * 0.5 - cy;
  switch (axisType(horizontalAxis)) {
    case AxisType.simple:
      xAxisLabelSpace =
        xAxisToBorder >= 0 && xAxisToBorder < 1
          ? X_SIMPLE_AXIS_DEFAULT_LABEL_HEIGHT + X_AXIS_TICK_LABEL_OFFSET
          : 0;
      break;
    case AxisType.advanced:
      const axis = horizontalAxis as AdvancedAxisObject;
      const axisCloseToBorder = xAxisToBorder >= 0 && xAxisToBorder < axis.maxLabelLines;
      xAxisLabelSpace =
        axisCloseToBorder && axis.hasAxisTickLabels
          ? // do not scale down the label space of advanced axes!!! -> dividing by scale in advance
            (X_ADVANCED_AXIS_DEFAULT_LABEL_HEIGHT * axis.maxLabelLines + X_AXIS_TICK_LABEL_OFFSET) /
            scale
          : axisCloseToBorder
          ? X_AXIS_TICK_LABEL_OFFSET
          : 0;
      break;
    default:
  }

  // the space the label uses of the already existing grid space
  let requiredAxisLabelSpace = 0;
  switch (axisType(verticalAxis)) {
    case AxisType.simple:
      const wholes = parseInt((tickValueInterval.y * height).toString(), 10);
      const parts = tickValueInterval.y - Math.floor(tickValueInterval.y);
      const len =
        wholes.toString().length + // # wholes
        (wholes >= 1000 ? Math.floor(Math.log10(wholes) / 3) : 0) + // # thousands separators
        (parts > 0 ? parts.toString().length - 1 : 0); // # decimals (without leading 0)

      requiredAxisLabelSpace = Y_SIMPLE_AXIS_DEFAULT_DIGIT_WIDTH * len - Y_AXIS_TICK_LABEL_OFFSET;
      break;
    case AxisType.advanced:
      const axis = verticalAxis as AdvancedAxisObject;
      const labelWidth = axis.maxLabelLength
        ? axis.maxLabelLength * Y_ADVANCED_AXIS_DEFAULT_DIGIT_WIDTH
        : Y_ADVANCED_AXIS_DEFAULT_LABEL_WIDTH;

      requiredAxisLabelSpace = axis.hasAxisTickLabels
        ? // do not scale down the label space of advanced axes!!! -> dividing by scale in advance
          (labelWidth - Y_AXIS_TICK_LABEL_OFFSET) / scale
        : AXIS_MAJOR_TICKS_PIXEL + Number(axis.contractionEnabled) + Number(showNullLabel) * 4;
      break;
    default:
  }
  // the additional space the yAxis label needs
  const yAxisLabelSpace = Math.max(
    0,
    Math.min(requiredAxisLabelSpace, requiredAxisLabelSpace - transX)
  );

  const gridWidth = width * PIXEL_PER_XTICK;
  const gridHeight = height * PIXEL_PER_YTICK;

  const borderSpace = borderExtension ? 2 * MAX_POINT_RADIUS : paintedBorder ? 1 : 0;

  let totalWidth = gridWidth + yAxisLabelSpace + borderSpace;
  let totalHeight = gridHeight + xAxisLabelSpace + borderSpace;
  if (isInteractive) {
    totalWidth +=
      SVG_BORDER_EXTENSION.left +
      SVG_BORDER_EXTENSION.right +
      (borderExtension ? (paintedBorder ? 1 : 0) : 2 * MAX_POINT_RADIUS);
    totalHeight +=
      SVG_BORDER_EXTENSION.top +
      SVG_BORDER_EXTENSION.bottom +
      (borderExtension ? (paintedBorder ? 1 : 0) : 2 * MAX_POINT_RADIUS);
  }

  const matrix = [
    (PIXEL_PER_XTICK / tickValueInterval.x) * scale,
    0,
    0,
    (-PIXEL_PER_YTICK / tickValueInterval.y) * scale,
    (yAxisLabelSpace + transX) * scale,
    PIXEL_PER_YTICK * (height * 0.5 + cy) * scale,
  ];
  return {
    matrix,
    gridWidth: gridWidth * scale,
    gridHeight: gridHeight * scale,
    totalWidth: totalWidth * scale,
    totalHeight: totalHeight * scale,
  };
};

/**
 * Performs a 'scaling' operation from 'screen scaling' to 'world scaling' or vice
 * versa. If the X-scaling and Y-scaling are different, the 'larger one' is taken
 * for both directions!
 * @param {number[]} matrix
 * @param {boolean} toScreen 'to screen scaling' or 'to world scaling'
 * @param {number} x the number to scale
 * @returns {number} the scaled value x_scaled
 */
export const scale =
  (matrix: number[], toScreen = true) =>
  (x: number) =>
    (toScreen
      ? Math.max(Math.abs(matrix[0]), Math.abs(matrix[3]))
      : Math.min(1 / Math.abs(matrix[0]), 1 / Math.abs(matrix[3]))) * x;

/**
 * Performs a transformation from world to screen or screen to world for just the
 * x-coordinate of some vector
 * @param {number[]} matrix
 * @param {boolean} toScreen
 * @returns {(x: number) => number}
 */
export const transformX =
  (matrix: number[], toScreen = true) =>
  (x: number) =>
    round(
      toScreen ? x * matrix[0] + matrix[4] : (x - matrix[4]) / matrix[0],
      GENERATOR_MAX_DECIMALS
    );

/**
 * Performs a transformation from world to screen or screen to world for just the
 * y-coordinate of some vector
 * @param {number[]} matrix
 * @param {boolean} toScreen
 * @returns {(x: number) => number}
 */
export const transformY =
  (matrix: number[], toScreen = true) =>
  (y: number) =>
    round(
      toScreen ? y * matrix[3] + matrix[5] : (y - matrix[5]) / matrix[3],
      GENERATOR_MAX_DECIMALS
    );

/**
 * Transforms screen to world coordinates with a given SVG transformation matrix.
 * @param {number[]} matrix
 * @param {Coords} screen
 * @returns {Coords}
 */
export const screenToWorld =
  (matrix: number[]) =>
  (screen: Coords): Coords => ({
    x: transformX(matrix, false)(screen.x),
    y: transformY(matrix, false)(screen.y),
  });

/**
 * Transforms world to screen coordinates with a given SVG transformation matrix.
 * @param matrix
 * @param world
 */
export const worldToScreen =
  (matrix: number[]) =>
  (world: Coords): Coords => ({
    x: transformX(matrix)(world.x),
    y: transformY(matrix)(world.y),
  });

/**
 * Gets the current Mouse position relative to a DOM element given by ID
 * @param {string} domElementId
 * @param {CursorPositionOffset} cursorOffset
 * @returns {Coords}
 */
export const mousePos =
  (domElementId: string, cursorOffset = CursorPositionOffset.MOUSE) =>
  (evt: MouseOrTouch): Coords => {
    const doc = document.getElementById(`${SVG_CANVAS}:${domElementId}`);
    const dim =
      doc !== null
        ? doc.getBoundingClientRect()
        : {
            left: -SVG_BORDER_EXTENSION.left - MAX_POINT_RADIUS,
            top: -SVG_BORDER_EXTENSION.top - MAX_POINT_RADIUS,
          };
    let eventCoords: Coords;
    const touches = get({ ...evt }, 'touches');
    if (touches && touches.length > 0) {
      eventCoords = {
        x: touches[0].clientX,
        y: touches[0].clientY - Number(cursorOffset === CursorPositionOffset.TOUCH) * 50,
      };
    } else {
      eventCoords = {
        x: (evt as MouseEvent<HTMLElement>).clientX,
        y: (evt as MouseEvent<HTMLElement>).clientY,
      };
    }

    const offset = cursorOffset === CursorPositionOffset.MOUSE ? MAX_POINT_RADIUS : 0;

    return {
      x: eventCoords.x - dim.left - SVG_BORDER_EXTENSION.left - offset,
      y: eventCoords.y - dim.top - SVG_BORDER_EXTENSION.top - offset,
    };
  };
