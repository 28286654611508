import { type Coords } from '@bettermarks/gizmo-types';
import { flatten } from 'lodash';

/**
 * Cartesian product of two number arrays as an array of coordinates.
 * @param {number[]} a
 * @param {number[]} b
 * @returns {Coords[]} a x b
 */
export const numberCartesian = (a: number[], b: number[]): Coords[] =>
  a.reduce((acc, a_) => [...acc, ...b.map((b_) => ({ x: a_, y: b_ }))], []);

/**
 * Generic cartesian product of two <any> arrays as an array of tuples of <any>.
 * @param {any} a
 * @param {any} b
 * @returns {[any, any][]} a x b
 */
export const cartesian = <T1, T2>(a: T1[], b: T2[]): [T1, T2][] =>
  a.reduce<[T1, T2][]>((acc, a_: T1) => [...acc, ...b.map<[T1, T2]>((b_: T2) => [a_, b_])], []);

/**
 * Generic combinations of 2-tuples of a given set s.
 * @param {any} s
 * @returns {[any, any][]} the n (number of elements in s) choose 2 array of 2 tuples.
 */
export const combinationsBy2 = <T>(s: T[]): T[][] =>
  flatten(s.map((v, idx) => s.slice(idx, s.length - 1).map((v, i) => [s[idx], s[idx + i + 1]])));
