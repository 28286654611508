import * as React from 'react';
import { get } from 'lodash';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import {
  type CommonCallbacks,
  type MouseOrTouch,
  type PieOrStackedBarChartContent as PieChartContent,
  type Slice,
} from '@bettermarks/gizmo-types';
import { WithLocalRedux } from '../../gizmo-utils/WithLocalRedux';
import { PieChartRenderer } from './PieChartRenderer';
import { type PieChartCallbacks } from './PieChartContainer';
import { initialState, pieChartLocalReducer } from './reducer/PieChartReducer';
import { end, pieChartMove, pieChartStart } from './reducer/actions';
import { rdN } from '@bettermarks/importers';
import { DEFAULT_PIE_CHART_CENTER_X, DEFAULT_PIE_CHART_CENTER_Y } from './defaults';

export type PieChartState = {
  item: number; // active item
  slices: Slice[];
};

export type PieChartProps = PieChartContent & PieChartCallbacks & ContextState & CommonCallbacks;

export const PieChartEditor: React.FC<PieChartProps> = ({ onPersist, uniqueId, ...props }) => (
  <WithLocalRedux
    store={{ ...initialState, slices: [...props.slices] }}
    reducer={pieChartLocalReducer}
    componentName={`PieChartEditor:${uniqueId}`}
  >
    {(state, dispatch) => {
      const { item, slices } = state;
      const screenToAngleValue = (evt: MouseOrTouch, elem: HTMLDivElement | null): number => {
        const dim = elem ? elem.getBoundingClientRect() : { left: 0, top: 0 };

        const touches = get({ ...evt }, 'touches');
        const [screenX, screenY] =
          touches && touches.length > 0
            ? [touches[0].clientX - dim.left, touches[0].clientY - dim.top]
            : [
                (evt as React.MouseEvent<any>).clientX - dim.left,
                (evt as React.MouseEvent<any>).clientY - dim.top,
              ];

        const distScreenPosCenter = Math.sqrt(
          (screenX - DEFAULT_PIE_CHART_CENTER_X) * (screenX - DEFAULT_PIE_CHART_CENTER_X) +
            (screenY - DEFAULT_PIE_CHART_CENTER_Y) * (screenY - DEFAULT_PIE_CHART_CENTER_Y)
        );

        const angle =
          (Math.acos((screenX - DEFAULT_PIE_CHART_CENTER_X) / distScreenPosCenter) / Math.PI) * 180;

        return screenX > DEFAULT_PIE_CHART_CENTER_X
          ? screenY > DEFAULT_PIE_CHART_CENTER_Y
            ? angle + 90
            : 90 - angle
          : screenY > DEFAULT_PIE_CHART_CENTER_Y
          ? 90 + angle
          : 450 - angle;
      };

      const activeSliceValueChanged = () =>
        state.slices[state.item].value !== props.slices[state.item].value;

      const onMoveBar = (elem: HTMLDivElement | null) => (evt: MouseOrTouch) => {
        if (!isNaN(item)) {
          const angle = screenToAngleValue(evt, elem);
          const cumulativeValue = slices.slice(0, item).reduce((sum, { value }) => sum + value, 0);
          const deltaValue = rdN(cumulativeValue - angle / 3.6, 5);

          if (deltaValue !== 0) {
            dispatch(pieChartMove(deltaValue));
          }
        }
      };

      const onMoveStart = (item: number) => dispatch(pieChartStart(item));

      const onMoveEnd = () => {
        if (!isNaN(item) && activeSliceValueChanged()) {
          // persist the current slices in the global store
          if (onPersist) {
            onPersist(slices);
          }
        }
        dispatch(end());
      };

      const localProps = { ...props, slices };
      const interactive = !props.disabled;

      return (
        <PieChartRenderer
          {...localProps}
          onMove={interactive ? onMoveBar : undefined}
          onMoveEnd={interactive ? onMoveEnd : undefined}
          onMoveStart={interactive ? onMoveStart : undefined}
        />
      );
    }}
  </WithLocalRedux>
);

PieChartEditor.displayName = 'PieChartEditor';
