import * as React from 'react';
import { Key, type KeyHandler } from './Key';
import { KeyCode } from './keyCodes';

export interface OperatorBlockProps {
  operators: (KeyCode | string)[];
  onKey?: KeyHandler;
}

export const OperatorBlock: React.FC<OperatorBlockProps> = ({ operators, onKey }) => (
  <>
    {operators.map((operator, i) => (
      /*
       * Because we are adding multiple "empty" keys, which would result in having multiple <Key>
       * elements with the same key-property, we need to reassign new keys to each of them.
       */
      <Key
        key={operator === KeyCode.Empty ? `${KeyCode.Empty}-${i}` : operator}
        keyCode={operator as KeyCode}
        iconProps={{ onKey }}
      />
    ))}
  </>
);

OperatorBlock.displayName = 'OperatorBlock';
