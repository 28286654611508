import {
  annotationInner,
  type ContentDict,
  type ContentReference,
  DECORATION,
  type DragItem,
  type DragSourceContent,
  type Exporter,
  type ExporterContext,
  RS,
  semantics,
  type SetDropTargetContent,
  DnDVerticalAlignment,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { decorationToString } from '../../../gizmo-utils/decoration';
import { findKey, isNil, isString } from 'lodash';
import { exportDragItem } from '../dragsource/exporter';

export const resolveDragSourceFromId = (
  contentDict: ContentDict,
  { dragSourceId }: DragItem
): undefined | (ContentReference & { content: DragSourceContent }) => {
  const $refid = findKey(contentDict, { $id: dragSourceId });
  if (!$refid) return undefined;
  const content = contentDict[$refid] as DragSourceContent;
  return { $refid, content };
};

export const exportSetDropTarget: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as SetDropTargetContent;
  const items = content.items
    .map((item, index) => {
      const dragSource = resolveDragSourceFromId(context.content, item);
      if (dragSource && dragSource.content.$renderStyle === RS.DRAG_SOURCE) {
        return exportDragItem(
          dragSource.$refid,
          context,
          item.severity,
          `${content.$id}:item:${index}`
        );
      }
    })
    .filter(isString)
    .join('');

  const decoration = decorationToString(
    {
      borderColor: content.borderColor,
      backgroundAlpha: content.backgroundAlpha,
    },
    undefined,
    content.severity
  );

  return semantics(
    `
      <mrow ${xmlAttr(DECORATION, decoration)}>
        <configuration>
          <targetGroup>${content.targetGroup}</targetGroup>
          <style>${content.style}</style>
          <percentWidth>${content.percentWidth}</percentWidth>
          ${
            content.verticalItemAlign !== DnDVerticalAlignment.top
              ? `<verticalItemAlign>${content.verticalItemAlign}</verticalItemAlign>`
              : ''
          }
          ${isNil(content.minHeight) ? '' : `<minHeight>${content.minHeight}</minHeight>`}
          ${isNil(content.width) ? '' : `<width>${content.width}</width>`}
          ${isNil(content.fixedWidth) ? '' : `<fixedWidth>${content.fixedWidth}</fixedWidth>`}
          ${isNil(content.height) ? '' : `<height>${content.height}</height>`}
          ${
            isNil(content.widthAsFactorOfMaxItemWidth)
              ? ''
              : `<widthAsFactorOfMaxItemWidth>${content.widthAsFactorOfMaxItemWidth}</widthAsFactorOfMaxItemWidth>`
          }
        </configuration>
        ${
          items.length > 0
            ? `<set sortable="yes">
            ${items}
          </set>`
            : '<set sortable="yes"/>'
        }
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
