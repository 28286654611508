import { type LineStyle, type LineWeight } from '../../types';
import { MarkerType } from '../components/Markers/types';
import { type AngleBase, type SnapType, type AxisDirection, type IntervalType } from './data/types';

export const ANGLE = 'angles' as const;
export const CIRCLES = 'circles' as const;
export const LABEL = 'labels' as const;
export const POINT = 'points' as const;
export const RAY = 'rays' as const;
export const SEGMENT = 'segments' as const;
export const STRAIGHTLINE = 'straightlines' as const;
export const VECTOR = 'vectors' as const;
export const BEZIER = 'beziers' as const;
export const CIRCLESEGMENT = 'circleSegments' as const;
export const POLYGON = 'polygons' as const;
export const INTERVAL = 'intervals' as const;
// used for labelstepper at 'left resp. right (lo resp. hi) limit of an interval'
export const INTERVAL_LO = 'interval-lo' as const;
export const INTERVAL_HI = 'interval-hi' as const;
export const LINE = 'line' as const;
export const GRID = 'grid' as const;
export const INVISIBLE = 'invisible' as const;

export type LineType =
  | typeof RAY
  | typeof SEGMENT
  | typeof STRAIGHTLINE
  | typeof VECTOR
  | typeof LINE;

export type SnapObject =
  | LineType
  | typeof POINT
  | typeof GRID
  | typeof LINE
  | typeof BEZIER
  | typeof INVISIBLE
  | typeof CIRCLES;

// interval limits are here, because we use the label stepper for selecting interval limits!
export type LabelableObject = LineType | typeof POINT | typeof INTERVAL_LO | typeof INTERVAL_HI;

export type Coords = {
  x: number;
  y: number;
};

export type IdCoords = {
  id: string;
  coords: Coords;
};

export type LineIdCoords = {
  id: string;
  coords: [Coords, Coords];
};

export type CircleIdCoords = {
  id: string;
  coords: Coords; // coords of the center
  radius: number;
};

export type Line = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export type SnapPoint = {
  x: number;
  y: number;
  snapObject: SnapObject;
  id: string;
};

export type PreviewCircle = {
  coords: Coords;
  radius: number;
  decoration: CircleDecoration;
  visible: boolean;
  screenCoords?: Coords;
  toolDisplayContent?: string;
};

export type PreviewPoint = IdCoords & {
  invisible?: boolean;
};

export type PreviewLine = {
  p1: Coords | undefined;
  p2: Coords | undefined;
  decoration: LineDecoration;
  visible: boolean;
  toolDisplayContent?: string;
  toolDisplayPos?: Coords;
};

export type PreviewAngle = AngleBase & {
  visible: boolean;
};

export type PreviewInterval = {
  direction: AxisDirection;
  min: number;
  max: number;
  intervalType: IntervalType;
  visible: boolean;
  snapHighlight: 'min' | 'max';
};

export type GeoScene = {
  snappingGrid: Coords[];
  points: IdCoords[];
  rays: LineIdCoords[];
  segments: LineIdCoords[];
  straightlines: LineIdCoords[];
  vectors: LineIdCoords[];
  invisibleSnapPoints: IdCoords[];
  circles: CircleIdCoords[];
  snapType: SnapType;
};

export enum HAlignment {
  start = 'left',
  middle = 'center',
  end = 'right',
}

export enum VAlignment {
  hanging = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

export const CIRCLE = 'circle' as const;
export const DISC = 'disc' as const;
export const PLUS = 'plus' as const;
export const CROSS = 'cross' as const;

export type POINT_STYLE = typeof CIRCLE | typeof DISC | typeof PLUS | typeof CROSS;

export const CAP_TRIANGLE = 'triangle' as const;
export const CAP_T_ARROW = 't-arrow' as const;
export const CAP_NARROW_ARROW = 'narrow-arrow' as const;
export const CAP_ARROW = 'arrow' as const;

export type LineCapStyle =
  | typeof CAP_TRIANGLE
  | typeof CAP_T_ARROW
  | typeof CAP_NARROW_ARROW
  | typeof CAP_ARROW;

export const isLineCapStyle = (style: string | undefined): style is LineCapStyle =>
  style === CAP_TRIANGLE ||
  style === CAP_T_ARROW ||
  style === CAP_NARROW_ARROW ||
  style === CAP_ARROW;

export const HINT_EXPECTED = 'expected' as const;
export const HINT_ADDITIONAL = 'additional' as const;

export type ValidationHint = typeof HINT_EXPECTED | typeof HINT_ADDITIONAL;

export const METRICS_INTERVAL_X = 'metrics-interval-x' as const;

export interface GeoDecoration {
  color?: string;
  fillColor?: string;
  fillTransparency?: number;
  lineWeight?: LineWeight;
  marked?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

/**
 * Using typescript tagged union
 */
export interface PointDecoration extends GeoDecoration {
  pointStyleType?: POINT_STYLE;
  lineWeight?: LineWeight;
}

export interface LabelDecoration extends GeoDecoration {
  glowFilter: string;
}

export interface LineDecoration extends GeoDecoration {
  lineStyle?: LineStyle;
  lineCapStyleTop?: LineCapStyle;
  lineCapStyleBottom?: LineCapStyle;
}

export const isLineCapProp = (key: string): boolean =>
  key === 'lineCapStyleTop' || key === 'lineCapStyleBottom';

export interface CircleDecoration extends GeoDecoration {
  borderStyle: LineStyle;
  lineWeight: LineWeight;
  fillColor?: string;
}

export interface IntervalHelperLineDecoration extends GeoDecoration {
  lineStyle?: LineStyle;
}

export type LabelStepperAnchor = {
  refPointCoords: Coords;
  up: boolean;
  transX: number; // translation in x direction (when refPoint is too close to border)
  transY: number; // translation in y direction (when refPoint is too close to border)
  noseOffsetX: number; // horizontal shift of nose with respect to center of pickerWidget
};

export const LineCapStyleToMarkerType: Record<string, MarkerType> = {
  [CAP_T_ARROW]: MarkerType.TArrow,
  [CAP_ARROW]: MarkerType.Arrow,
  [CAP_NARROW_ARROW]: MarkerType.NarrowArrow,
  [CAP_TRIANGLE]: MarkerType.Triangle,
};

export type TouchHandlerState = {
  lastScale: number;
  currentScale: number;
  inPinch: boolean;
  lastPan: Coords;
  currentPan: Coords;
  inPan: boolean;
};

export type SnapHighlightObjects = {
  [CIRCLES]: string[];
  [RAY]: string[];
  [SEGMENT]: string[];
  [STRAIGHTLINE]: string[];
  [VECTOR]: string[];
  [INVISIBLE]: string[];
};

export enum CursorPositionOffset {
  MOUSE = 'mouse',
  NONE = 'none',
  TOUCH = 'touch',
}

export type ViewboxCorners = {
  sw: Coords;
  se: Coords;
  nw: Coords;
  ne: Coords;
};

export type Rect = [Coords, Coords];

export enum AxisTickStyle {
  INVISIBLE = 'invisible',
  NORMAL = 'normal',
}
