import React, { useEffect } from 'react';
import { isNil, uniq } from 'lodash';
import { Severity } from '@bettermarks/umc-kotlin';
import { ControlPosition, type FunctionParameter } from '@bettermarks/gizmo-types';
import { type ContextState, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { Dropdown, Slider, STATE_ERROR, STATE_UNSELECTED } from '../../../components/';
import DropdownButton from './DropdownButton';
import { DropdownOptionList } from './DropdownOptionList';
import DropdownOption from './DropdownOption';
import DropdownOptionContent from './DropdownOptionContent';

import styles from './SlidersContainer.scss';
import { type FunctionPlotterCallbacks } from '../../../gizmos/function-plotter/FunctionPlotter';
import classNames from 'classnames';
import { type ContentRect, useMeasure } from '../../../gizmo-utils/useMeasure';

const MAX_SLIDER_SIZE = 480;

interface SlidersMeta {
  hasError: boolean;
  isMonoColor: boolean;
}

const getSlidersMeta = (slidersData: FunctionParameter[]): SlidersMeta => {
  const colors: (string | undefined)[] = [];
  let hasError = false;
  for (const { severity, decoration } of slidersData) {
    if (severity && severity === 'error') {
      hasError = true;
    }
    colors.push(decoration.color);
  }

  return {
    hasError,
    isMonoColor: uniq<string | undefined>(colors).length !== slidersData.length,
  };
};

export interface SlidersContainerProps
  extends FunctionPlotterCallbacks,
    Pick<ContextState, 'selected' | 'isTouch'> {
  slidersData: FunctionParameter[];
  selectable: boolean;
  disabled?: boolean;
  onSetIntermediateParam: (id: string) => (value: number) => void;
  selectedParameter?: string;
  availableSize: number;
  isSmallScreen: boolean;
  position: ControlPosition;
}

interface SliderContainerProps {
  props: SlidersContainerProps;
  sliderProps: FunctionParameter;
}

/*
 * This component contains one slider and the corresponding label. It is responsible for the correct
 * layout
 * */
function SliderContainer({ props, sliderProps }: SliderContainerProps) {
  const [contentRect, ref] = useMeasure<ContentRect, HTMLDivElement>(
    {},
    ['client', 'offset', 'scroll', 'bounds', 'margin'],
    (contentRect: ContentRect) => contentRect
  );

  const {
    refId,
    decoration,
    severity,
    ignoreZero,
    rendererAllowsInteraction,
    minimum,
    maximum,
    step,
    value,
    label,
    showValue,
    sliderOrientation,
  } = sliderProps;

  useEffect(() => {
    if (!refId) {
      return;
    }
    if (value < minimum) {
      onSetParam(refId)(minimum);
    }
    if (value > maximum) {
      onSetParam(refId)(maximum);
    }
  }, [minimum, maximum]);

  if (!refId) {
    return null;
  }

  const { onSetParam, onSetIntermediateParam, disabled, availableSize, position } = props;

  const isVertical = sliderOrientation === 'vertical';

  // width if slider is horizontal, height if slider is vertical
  const sliderSize = isVertical
    ? /* eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain */
      Math.min(availableSize - (contentRect.client?.height ?? 0), MAX_SLIDER_SIZE)
    : availableSize;

  return (
    <div
      className={classNames(
        styles.sliderContainer,
        isVertical && label ? styles.verticalLabel : ''
      )}
    >
      {label && (
        <div ref={ref}>
          <PolymorphicGizmo refid={label.$refid} availableWidth={0} />
        </div>
      )}
      <div
        style={{
          minWidth: `${isVertical ? 0 : sliderSize}px`,
          height: 'inherit',
        }}
      >
        <Slider
          key={refId}
          onChange={(value: number) => {
            if (ignoreZero && value === 0) {
              return;
            }
            return onSetIntermediateParam(refId)(value);
          }}
          onFinalValue={() => onSetParam(refId)(value)}
          min={minimum}
          interactive={rendererAllowsInteraction || !disabled}
          max={maximum}
          step={step}
          value={value}
          color={decoration.color}
          error={isNil(severity) ? false : severity === Severity.error}
          orientation={isVertical ? 'vertical' : 'horizontal'}
          //availableSize={sliderSize}
          //insideDropdown={isSmallScreen}
          showValue={showValue}
          handleSite={position === ControlPosition.left ? 'left' : 'right'}
        />
      </div>
    </div>
  );
}

export const SlidersContainer: React.FC<SlidersContainerProps> = (props) => {
  const { slidersData, selectable, onFocusParam, onBlurParam, availableSize, isSmallScreen } =
    props;

  const [selectedSliderIndex, setSelectedSliderIndex] = React.useState(0);

  const { hasError, isMonoColor } = getSlidersMeta(slidersData);

  const isVertical =
    slidersData.length > 0 ? slidersData[0].sliderOrientation === 'vertical' : false;

  return isSmallScreen ? (
    <div className={styles.verticalSliderWrapper}>
      {slidersData.length > 1 && (
        <Dropdown
          interactive={true}
          open={false}
          selectedIndex={selectedSliderIndex}
          onItemSelected={setSelectedSliderIndex}
          dropdownButton={DropdownButton}
          dropdownOptionList={DropdownOptionList}
          hasError={hasError}
          selectedContent={
            <DropdownOptionContent
              decorationColor={slidersData[selectedSliderIndex].decoration.color}
              isColorBased={!isMonoColor}
              number={selectedSliderIndex + 1}
              isSelected
              isStatic
            />
          }
        >
          {slidersData.map(({ decoration, severity }, index) => (
            <DropdownOption
              key={index}
              state={severity && severity === 'error' ? STATE_ERROR : STATE_UNSELECTED}
            >
              <DropdownOptionContent
                decorationColor={decoration.color}
                isSelected={index === selectedSliderIndex}
                isColorBased={!isMonoColor}
                hasError={severity && severity === 'error'}
                number={index + 1}
                isFirst={index === 0}
                isLast={index === slidersData.length - 1}
              />
            </DropdownOption>
          ))}
        </Dropdown>
      )}
      <div
        style={{
          height: `${availableSize}px`,
          width: `${availableSize}px`,
        }}
      >
        {
          slidersData
            .map((s) => {
              return {
                ...s,
                sliderOrientation: 'vertical' as const,
              };
            })
            .map((sliderProps) => {
              return <SliderContainer props={props} sliderProps={sliderProps}></SliderContainer>;
            })[selectedSliderIndex]
        }
      </div>
    </div>
  ) : (
    <div className={classNames(styles.container, isVertical ? styles.vertical : '')}>
      {slidersData.map(
        (sliderProps) =>
          sliderProps.refId && (
            <div
              key={sliderProps.refId}
              tabIndex={selectable ? 0 : -1}
              onFocus={selectable ? onFocusParam(sliderProps.refId) : undefined}
              onBlur={selectable ? onBlurParam(sliderProps.refId) : undefined}
            >
              <SliderContainer props={props} sliderProps={sliderProps}></SliderContainer>
            </div>
          )
      )}
    </div>
  );
};

SlidersContainer.displayName = 'SlidersContainer';
