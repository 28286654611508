import * as React from 'react';

import { BASE, type MRow, type MSubSup as MSubSupContent } from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { Bucket } from '../Bucket';
import { enrich } from '../helpers';
import { type MPropsWithChildren } from '../types';

import { getScriptMetric, getSubScriptOverlap, getSuperScriptOverlap } from './getScriptMetric';
import { type ScriptMetric, type ScriptProps } from './types';

import styles from './MSubSup.scss';

export type MSubSupProps = MPropsWithChildren &
  ScriptProps & {
    subMetric: ScriptMetric;
    superMetric: ScriptMetric;
  };

/**
 * MSubSup: The MathML <msubsup> element is used to attach an accent or a limit
 * over an expression. It uses the following syntax:
 * <msubsup>
 *    base
 *    subscript
 *    superscript
 * </msubsup>
 */
export const MSubSup: React.FC<MSubSupProps> = ({
  baseHeight,
  children: [base, subscript, superscript],
  computedStyles: { backgroundColor },
  lowerFontSize,
  subMetric,
  superMetric,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <span
      className={styles.msubsupWrapper}
      style={{
        // offset can be negative, negative paddings are not valid css
        paddingBottom: subMetric.offset > 0 ? subMetric.offset : undefined,
        paddingTop: superMetric.offset > 0 ? superMetric.offset : undefined,
      }}
    >
      <Bucket height={baseHeight}>{base}</Bucket>

      <div className={styles.msubsupContent} style={{ fontSize: lowerFontSize }}>
        <div
          className={styles.contentSuperScript}
          style={{
            marginTop: -superMetric.offset,
            marginLeft: superMetric.spacing,
          }}
        >
          <Bucket height={superMetric.scriptHeight}>{superscript}</Bucket>
        </div>
        <div
          className={styles.contentSubScript}
          style={{
            marginBottom: -subMetric.offset,
            marginLeft: subMetric.spacing,
          }}
        >
          <Bucket height={subMetric.scriptHeight}>{subscript}</Bucket>
        </div>
      </div>
    </span>
  </BackgroundColor>
);

MSubSup.displayName = 'MSubSup';

export const enrichMSubSup: EnrichNestedMathContent<MSubSupContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const { enrichedContent: base, ...baseLM } = mathContentEnricher(formulaStyles, content.base, [
    ...path,
    BASE,
  ]);

  const { enrichedContent: subscript, ...subScriptLM } = mathContentEnricher(
    formulaStyles,
    content.subscript,
    [...path, 'subscript']
  );
  const lowerFontSize = subscript.computedStyles.fontSize;

  const { enrichedContent: superscript, ...superScriptLM } = mathContentEnricher(
    formulaStyles,
    content.superscript,
    [...path, 'superscript']
  );

  const subMetric = getScriptMetric(baseLM, subScriptLM, getSubScriptOverlap(formulaStyles));
  const superMetric = getScriptMetric(baseLM, superScriptLM, getSuperScriptOverlap(formulaStyles));

  const props: MSubSupContent & Partial<MSubSupProps> = {
    ...content,
    base,
    baseHeight: baseLM.height,
    lowerFontSize,
    subscript,
    subMetric,
    superscript,
    superMetric,
  };

  return enrich(
    props,
    {
      // the scriptMetrics heights each contain the baseHeight
      height: subMetric.height + superMetric.height - baseLM.height,
      refLine: subMetric.offset + baseLM.refLine,
      relativeToBaseLine: true,
    },
    formulaStyles
  );
};
