import {
  identity,
  type Importer,
  type ListSelectionContent,
  RS,
  SPECIAL,
} from '@bettermarks/gizmo-types';

export const LIST_SELECTION_DEFAULT_CONTENT: ListSelectionContent = {
  $: SPECIAL,
  $renderStyle: RS.LIST_SELECTION,
  items: [],
  dynamicIndexExpression: '',
};

/**
 * imports the list selection XML and parses it.
 * @param preContent
 * @param xml
 * @param context
 */
export const importListSelection: Importer<ListSelectionContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const { dynamicIndexExpression } = mrow.attributesToProps(identity, ['dynamicIndexExpression']);

  return {
    ...preContent,
    items: mrow.children.map((xml) => context.importXML(xml)),
    dynamicIndexExpression,
  };
};
