import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Bar,
  colors,
  dimensions,
  Layout as BaseLayout,
  numberFromStyles,
} from '@seriesplayer/common-ui';
import { type ApplicationState, EMStatus } from '../../types';
import { FEM_PLAYER_ELEMENT_ID, FEMPLAYER_MAX_WIDTH } from './constants';
import { type Dispatch } from '../../gizmo-utils/redux';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { EMError } from './EM/EMError';
import { FEMContent } from './FEMContent';
import { DialogContent, DialogHeader } from '../../components/';
import { getBackToNsp } from '../seriesplayer/containers/SeriesPlayer/quitSeriesPlayerSaga';
import { Loader } from '../seriesplayer/components/Loader';
import { closeDialog, closeFEM } from '../seriesplayer/containers/SeriesPlayer/actions';
import { type StateWithHistory } from 'redux-undo';
import { useSelector } from 'react-redux';

export interface FEMDialogProps {
  dispatch: Dispatch;
  femData?: StateWithHistory<ContentDict>;
  forWhiteboard?: boolean;
  id: string;
  inBook?: boolean;
  isTouch?: boolean;
  locale: string;
  onCancel?: () => void;
  /** Check out FEMs_and_the_Whiteboard_Mode.md */
  onCloseFromWhiteboard?: () => void;
  status?: EMStatus;
  staticMediaUrl: string;
  title: string;
}

const Content = styled.div`
  padding-top: 0;
  padding-bottom: 0;
`;

// this max width needs to be aligned with the one used in Dialog
const Layout = styled(BaseLayout)`
  max-width: ${(props) => (!props.fullsize ? FEMPLAYER_MAX_WIDTH : '')};
  > * {
    background-color: ${(props) => (!props.fullsize ? '' : colors.cWhite)};
  }
`;

export function FEMDialog({
  dispatch,
  status,
  femData,
  forWhiteboard,
  id,
  inBook,
  isTouch,
  locale,
  onCancel,
  onCloseFromWhiteboard,
  staticMediaUrl,
  title,
}: FEMDialogProps) {
  const [t] = useTranslation();

  const { inBookNavigation } = useSelector((state: ApplicationState) => ({
    inBookNavigation: state.inBookNavigation,
  }));

  const onClose =
    inBookNavigation === undefined
      ? () => {
          dispatch(closeDialog());
        }
      : () => {
          dispatch(closeFEM());
        };

  let femContent: JSX.Element | undefined;

  if (status === EMStatus.error) {
    femContent = <EMError />;
  } else {
    femContent = femData && (
      <FEMContent
        {...{
          femData,
          locale,
          dispatch,
          id,
          isTouch: !!isTouch,
          inBook,
          staticMediaUrl,
          ...(forWhiteboard && {
            availableWidth: Math.min(
              numberFromStyles(dimensions.layoutMaxWidth),
              window.innerWidth
            ),
          }),
        }}
      />
    );
  }

  const dialogContent = (
    <DialogContent as={Content} inBook={inBook}>
      <Loader loaded={!!status} onQuit={getBackToNsp}>
        {femContent}
      </Loader>
    </DialogContent>
  );

  return (
    <Layout
      id={FEM_PLAYER_ELEMENT_ID}
      data-cy="fem-player"
      vertical
      fullsize={inBook || forWhiteboard}
    >
      {!inBook && !forWhiteboard && (
        <>
          <DialogHeader
            title={t(title)}
            leftButton={
              onCancel && !onCloseFromWhiteboard
                ? {
                    icon: 'ArrowLeftLargeBold',
                    onClick: onCancel,
                    dataCy: 'fem-back-btn',
                  }
                : undefined
            }
            rightButton={{
              icon: 'TimesSmall',
              onClick: onCloseFromWhiteboard || onClose,
              dataCy: 'fem-close-btn',
            }}
          />
          <Bar />
        </>
      )}
      {dialogContent}
    </Layout>
  );
}
