// THIS FILE WAS AUTOGENERATED FROM THE CORRESPONDING SCSS FILE. DO NOT EDIT UNLESS YOU KNOW WHAT YOU ARE DOING!!!
export default {
  smallScreen: 'max-width: 699px',
  largeScreen: 'min-width: 700px',
  verySmallScreenWidth: '480px',
  verySmallScreenHeight: '376px',
  verySmallHeight: '400px',
  largeScreenWidth: '1024px',
  spaceXxs: '4px',
  spaceXs: '8px',
  spaceS: '12px',
  spaceM: '16px',
  spaceL: '20px',
  spaceXl: '24px',
  spaceXxl: '32px',
  spaceXxxl: '40px',
  fontSizeXxs: '10px',
  fontSizeXs: '12px',
  fontSizeS: '14px',
  fontSizeM: '16px',
  fontSizeL: '18px',
  fontSizeXl: '20px',
  fontSizeXxl: '24px',
  fontSizeXxxl: '32px',
  fontWeightNormal: 400,
  fontWeightBold: 700,
  fontWeightBolder: 900,
  lineHeightXxs: '14px',
  lineHeightXs: '16px',
  lineHeightS: '18px',
  lineHeightM: '20px',
  lineHeightL: '24px',
  lineHeightXl: '32px',
  lineHeightXxl: '40px',
  lineWidthXs: '1px',
  lineWidthM: '2px',
  lineWidthXl: '4px',
  blockXs: '16px',
  blockS: '24px',
  blockM: '32px',
  blockL: '44px',
  blockXl: '56px',
  sectionWidthXs: '128px',
  sectionWidthS: '256px',
  sectionWidthM: '320px',
  sectionWidthL: '480px',
  sectionWidthXl: '640px',
  sectionHeightS: '44px',
  sectionHeightM: '50px',
  sectionHeightL: '96px',
  minClickableSize: '44px',
  minClickableHeight: '44px',
  minClickableWidth: '32px',
  shadowBlurMedium: '25px',
  borderWidthS: '1px',
  borderWidthM: '2px',
  borderWidthL: '4px',
  borderRadiusM: '4px',
  borderRadiusL: '8px',
  confirmationBorder: '2px solid',
  scrollbarWidth: '8px',
  scrollbarMinHeight: '70px',
  btnSvgS: '12px',
  btnSvgM: '20px',
  btnSvgL: '24px',
  inputMinWidth: '18px',
  exerciseHorizontalContentSpacingS: '12px',
  exerciseHorizontalContentSpacingM: '16px',
  exerciseVerticalContentSpacingXs: '8px',
  exerciseVerticalContentSpacingS: '12px',
  exerciseVerticalContentSpacingM: '16px',
  exerciseVerticalContentSpacingL: '20px',
  exerciseVerticalContentSpacingXl: '24px',
  dialogL: '940px',
  dialogM: '600px',
  dialogS: '500px',
  dialogButtonMinWidth: '120px',
  layoutMaxWidth: '1200px',
  layoutMinWidth: '320px',
  zIndexOvertop: 3,
  zIndexTop: 2,
  zIndexMiddle: 1,
  zIndexBottom: -1,
  tableMaxWidth: '800px',
  svgSize: '32px',
  btnLineHeightXs: '12px',
  btnLineHeightL: '22px',
  btnLineHeightXl: '28px',
  btnPaddingTiny: '2px',
  btnPaddingExtended: '8px',
  btnSpaceS: '10px',
  btnMinHeightXs: '20px',
  btnMinHeightS: '24px',
  btnMinHeightM: '28px',
  btnMinHeightL: '36px',
  btnMinHeightL2: '44px',
  btnMinHeightXl: '48px',
  btnMinHeightXl2: '52px',
  btnMinHeightXxl: '56px',
  stepperPadding: '4px',
  stepperBtnSize: '40px',
  stepperBtnBorderRadius: '24px',
  menuMaxHeight: '330px',
};
