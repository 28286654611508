import * as React from 'react';
import { ToolbarButton, type ToggleProps } from '../../ToolbarButton';
import { KeyboardHideIcon, KeyboardShowIcon } from '../../icons';

export const KeyboardToggle: React.FC<ToggleProps> = (props) => (
  <ToolbarButton {...props} kind="toggle">
    <KeyboardHideIcon />
    <KeyboardShowIcon />
  </ToolbarButton>
);
