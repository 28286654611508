import { type EditorMode } from '../gizmo-utils/types';

/**
 * VirtualKeyboard Config
 *
 */
export type VirtualKeyboardConfig = Readonly<{
  numbers?: boolean;
  letters?: boolean;
  operators?: boolean;
  overbar?: boolean; // not implemented but the type is ready
  decimal_point?: boolean;
  fraction?: boolean;
  brackets?: boolean;
  exponent?: boolean;
  root?: boolean;
  sqrt?: boolean;
  pi?: boolean;
  absolute?: boolean;
  equals?: boolean;
}>;

export type KeyboardTool = {
  type: InputToolTypes.keyboard;
  layout: VirtualKeyboardConfig;
  selectedPage: number;
};

export type ModeSelectTool = {
  type: InputToolTypes.modeSelector;
  modes: ReadonlyArray<EditorMode>;
};

export const enum InputToolTypes {
  keyboard = 'keyboard',
  modeSelector = 'modeSelector',
}

export const enum ToolbarElements {
  calculator = 'calculator',
  dnd = 'dnd',
  keyboard = 'keyboard',
  modeSelector = 'mode_selector',
}

export type InputTool = KeyboardTool | ModeSelectTool;
