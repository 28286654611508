import * as React from 'react';
import classNames from 'classnames';

import { HEIGHT, WIDTH } from './constants';

import styles from '../FormStyles.scss';

type RectangleProps = {
  width: number;
  height: number;
  small: boolean;
  filled: boolean;
  hover: boolean;
  remove?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

export const Rectangle: React.FC<RectangleProps> = ({
  width,
  height,
  small,
  filled,
  hover,
  onClick,
  onMouseOver,
  onMouseLeave,
}) => {
  const onePX = WIDTH / width;

  return (
    <svg
      className={small ? styles.outerSmall : styles.outer}
      width={width}
      height={height}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      <rect
        x={onePX}
        y={onePX}
        width={WIDTH - 2 * onePX}
        height={HEIGHT - 2 * onePX}
        className={classNames(filled ? styles.filled : styles.empty, {
          [styles.hover]: hover,
        })}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    </svg>
  );
};
