import { type MeasureOptions, type ContentRect, type OnResize } from './useMeasure';
import ResizeObserver from 'resize-observer-polyfill';
import { isNil } from 'lodash';

/**
 * Get the measurements according to the requested types.ts.
 *
 * @param options Which types.ts of measurements to take
 * @param element The element to be measured
 * @returns an object whose keys are the requested measurement types.ts and the values are the
 *          measurements.
 */
export const getContentRect = (options: MeasureOptions, element: HTMLElement): ContentRect =>
  options.reduce((contentRect, option) => {
    switch (option) {
      case 'client':
        return {
          ...contentRect,
          client: {
            top: element.clientTop,
            left: element.clientLeft,
            width: element.clientWidth,
            height: element.clientHeight,
          },
        };

      case 'offset':
        return {
          ...contentRect,
          offset: {
            top: element.offsetTop,
            left: element.offsetLeft,
            width: element.offsetWidth,
            height: element.offsetHeight,
          },
        };

      case 'scroll':
        return {
          ...contentRect,
          scroll: {
            top: element.scrollTop,
            left: element.scrollLeft,
            width: element.scrollWidth,
            height: element.scrollHeight,
          },
        };

      case 'bounds':
        return {
          ...contentRect,
          bounds: element.getBoundingClientRect(),
        };

      case 'margin':
        const style = window.getComputedStyle(element);
        return {
          ...contentRect,
          margin: {
            top: style && style.marginTop ? parseInt(style.marginTop, 10) : 0,
            right: style && style.marginRight ? parseInt(style.marginRight, 10) : 0,
            bottom: style && style.marginBottom ? parseInt(style.marginBottom, 10) : 0,
            left: style && style.marginLeft ? parseInt(style.marginLeft, 10) : 0,
          },
        };

      default:
        return contentRect;
    }
  }, {});

/**
 * Internal function that creates a resizeObserver and whenever the registered element is
 * resized, it calls the provided onResize callback, and if the result is not null/undefined,
 * it calls the provided setState with the new state. It returns the "unsubscribe" function.
 *
 * This function is used as part of the implementation of the hooks useMeasure and useMeasures.
 *
 * It allows the user to skip a state change by returning null/undefined in the onResize callback.
 *
 * @param element The HTML element to observe
 * @param options The measurement types.ts to observe
 * @param setState The setState callback when a new measure state is recorded
 * @param onResize The callback to create the state measure whenever a the element is re-measured
 * @param RO For testing purpose, enables you to pass a custom sub class of ResizeObserver
 *           (from polyfills)
 * @returns the disconnect() function to unsubscribe from observing the element's dimensions
 */
export const observeElement = <T, E extends HTMLElement>(
  element: E,
  options: MeasureOptions,
  setState: (callback: (prevState: T) => T) => void,
  onResize: OnResize<T>,
  RO = ResizeObserver
) => {
  let animationId: number;
  const resizeObserver = new RO((entries) => {
    animationId = window.requestAnimationFrame(() => {
      // Just to be sure (entries should always be an array of 1 element with its dimensions)
      if (entries.length > 0 && entries[0].target === element) {
        const contentRect = getContentRect(options, element);
        setState((prevState) => {
          const newState = onResize(contentRect, prevState);
          // When onResize() returns null/undefined, the state will not change and no render
          // cycle will be triggered.
          return isNil(newState) ? prevState : newState;
        });
      }
    });
  });
  resizeObserver.observe(element);
  return () => {
    window.cancelAnimationFrame(animationId);
    resizeObserver.unobserve(element);
  };
};
