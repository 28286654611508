import { defaultTo } from 'lodash';
import {
  FunctionType,
  type FunctionParameter,
  type ParametricFunction,
  PARAMETER_DEFAULTS,
} from '@bettermarks/gizmo-types';

// map for getting a default function params for specific function types.ts
export const DEFAULT_PARAMS: { [key: string]: { [key: string]: number } } = {
  [FunctionType.linear]: { a: 0, b: 0 },
  [FunctionType.quadratic]: { a: 1, d: 0, e: 0 },
  [FunctionType.exponential]: { a: 1, b: 2.718, d: 0, e: 0 },
  [FunctionType.logarithmic]: { a: 1, b: 2.718, d: 0, e: 0 },
  [FunctionType.sinus]: { a: 1, b: 1, c: 0, d: 0 },
  [FunctionType.power]: { a: 1, e: 0, n: 1 },
  [FunctionType.polynomial]: { a0: 0, a1: 0, a2: 0, a3: 0, a4: 0 },
  [FunctionType.vline]: { e: 0 },
};

export const getParameter = (f: ParametricFunction, name: string) =>
  defaultTo<FunctionParameter>(
    f.parameters.find((p) => p.name === name),
    {
      ...PARAMETER_DEFAULTS,
      name,
      value: DEFAULT_PARAMS[f.type][name],
    }
  );
