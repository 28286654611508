import * as React from 'react';
import classNames from 'classnames';
import { Spinner } from '../../../../../components/icons/draft';
import styles from './LoaderScreen.scss';

export interface LoadScreenProps {
  className?: string;
}

export const LoaderScreen: React.FC<LoadScreenProps> = ({ className }) => (
  <div className={classNames(styles.loaderScreen, className)}>
    <Spinner className={styles.spinner} />
  </div>
);
