import { getFontMetric } from '../../../../../utils/fontMetric';
import classNames from 'classnames';
import { type HasStroke, type MO as MOContent, numberFromStyles } from '@bettermarks/gizmo-types';
import * as React from 'react';
import { type EnrichMathContent } from '../../measure/measureStatic';

import { enrich, getFontLayoutMetrics, onClickLeaf } from '../helpers';
import { type LeafProps, type MTokenProps } from '../types';
import { isStretchedShape, STRETCHED_SHAPE_DATA, StretchedShape } from './StretchedShape';

import styles from './tokens.scss';

export const MO_PADDING = numberFromStyles(styles.MO_PADDING);

export type MOProps = MTokenProps &
  LeafProps &
  Partial<HasStroke> & {
    stretchable?: boolean;
  };

export const MO: React.FC<MOProps> = ({
  children,
  computedStyles,
  interactive,
  onSelect,
  stretchable = false,
  strokeWidth,
}) => {
  if (isStretchedShape(children)) {
    return (
      <StretchedShape
        shape={children}
        stretchable={stretchable}
        computedStyles={computedStyles}
        strokeWidth={strokeWidth}
      />
    );
  }

  return (
    <span
      role="button"
      className={classNames(styles.mo, { [styles.interactive]: interactive })}
      style={computedStyles}
      onMouseDown={onClickLeaf(onSelect)}
    >
      {children}
    </span>
  );
};

MO.displayName = 'MO';

export const enrichMO: EnrichMathContent<MOContent> = (formulaStyles, content: MOContent) => {
  if (isStretchedShape(content.text)) {
    const height = STRETCHED_SHAPE_DATA[content.text][2];
    const { refLine, refLineFromBaseLine, strokeWidth } = getFontMetric(
      formulaStyles.fontSize,
      formulaStyles.fontWeight,
      formulaStyles.fontStyle
    );
    const baseLine = refLine - refLineFromBaseLine;

    return enrich(
      { ...content, strokeWidth },
      {
        height: height,
        // without verticalAlign the SVG has it's center on the baseLine,
        // subtracting from it makes verticalAlign work
        refLine: baseLine - height * 0.5,
        // do not apply verticalAlign when it is stretching
        relativeToBaseLine: content.stretchable,
        padding: MO_PADDING,
      },
      formulaStyles
    );
  }

  const metrics = getFontLayoutMetrics(formulaStyles.fontSize);

  return enrich(
    content,
    {
      ...metrics,
      ...// for MOs that indicate the derivatives of functions (e.g. f'(x), where ' is the MO),
      // we need to apply a y-shift
      (content.type === 'prime' && {
        refLine: metrics.refLine - 6,
        relativeToBaseLine: false,
      }),
      padding: MO_PADDING,
    },
    formulaStyles
  );
};
