import * as React from 'react';
import { InlineContainer } from '../../components';
import { RS } from '@bettermarks/gizmo-types';
import { SPECIAL } from '../../../gizmo-utils/constants';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { Formula } from '../../formula/Formula';
import { type SolutionSetContent } from '@bettermarks/gizmo-types';
import { getVisibleContent } from '../getVisibleContent';

export interface SolutionSetCallbacks {
  readonly onElementAdded?: () => void;
  readonly onElementRemoved?: () => void;
}

export type SolutionSetProps = SolutionSetContent & SolutionSetCallbacks & ContextState;

/**
 * Connects the [[components/Gizmo]] UIComponent to [[../SolutionSetContent]].
 * Assumptions:
 * 1. All aliens of SolutionSet are Formulas.
 * 2. Formulas align themselves vertically to the baseline
 * 3. Because of this, SolutionSet doesn't need to align them again.
 */
export const SolutionSet: React.FC<SolutionSetProps> = ({
  $interactionType,
  availableWidth,
  disabled,
  visibleElements,
  hiddenElements,
  configuration,
  onElementRemoved,
  onElementAdded,
}: SolutionSetProps) => {
  const interactive = $interactionType === RS.SOLUTION_SET && !disabled;
  const visibleContent = getVisibleContent(configuration, visibleElements);

  // mapping childrenAlignment to 0 implies that all children are baseline aligned.
  // 1. open 2. close 3. separator 4. All aliens.
  const children = (
    <Formula
      content={visibleContent}
      childrenAlignments={visibleContent.map(() => 0)}
      computedStyles={{ fontSize: 16 }}
      $={SPECIAL}
      $renderStyle={RS.TEXT}
      availableWidth={availableWidth}
    />
  );

  return interactive ? (
    <InlineContainer
      addItem={hiddenElements.length > 0 ? onElementAdded : undefined}
      removeItem={visibleElements.length > 0 ? onElementRemoved : undefined}
    >
      {children}
    </InlineContainer>
  ) : (
    children
  );
};

SolutionSet.displayName = 'SolutionSet';
