import { getMetadata as getMetadataUnstubable } from '../seriesplayer/services';
import { type LogLevelObject, isObjectLog, type LogObject } from '../seriesplayer/services/logging';

export const STUBABLE = {
  getMetadata: getMetadataUnstubable,
};

export const customJson = (log: LogLevelObject): LogLevelObject => ({
  ...log,
  metadata: STUBABLE.getMetadata(),
  ...(isObjectLog(log) && { ...(log.message[0] as LogObject) }),
});
