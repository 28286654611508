import {
  $MROW,
  CONFIGURATION,
  DRAG_SOURCE_DEFAULT_CONTENT,
  DragAndDropStyle,
  type DragSourceContent,
  type FElement,
  hasInteractionType,
  type Importer,
  SEMANTICS,
  switchMap,
  xmlText,
  xmlTextToBoolean,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';

export const parseDragAndDropStyle = switchMap(
  {
    border: DragAndDropStyle.border,
    none: DragAndDropStyle.none,
    rectangle: DragAndDropStyle.rectangle,
  },
  DragAndDropStyle.rectangle
);

export const dragSourceConfig = (xml: FElement): FElement =>
  xml.findChildTag($MROW).findChildTag(CONFIGURATION);

export const importDragSourceId = (config: FElement): { dragSourceId: string } =>
  config.tagsToProps(xmlText, ['dragSourceId']);

export const importDragSource: Importer<DragSourceContent> = (preContent, xml, context) => {
  const configuration = dragSourceConfig(xml);
  const contentXML = xml.findChildTag($MROW).findChildTag(SEMANTICS);
  const freeSnapping = xml.findChildTag($MROW).findChildTag(CONFIGURATION).hasChild('freeSnapping')
    ? true
    : undefined;

  return {
    ...DRAG_SOURCE_DEFAULT_CONTENT,
    ...preContent,
    ...configuration.tagsToProps(xmlTextToBoolean, [], ['autoSize']),
    ...configuration.tagsToProps(xmlTextToInt, [], ['instances', 'targetGroup', 'group']),
    ...(hasInteractionType(preContent) && importDragSourceId(configuration)),
    ...(hasInteractionType(preContent) && configuration.tagsToProps(xmlText, [], ['normalized'])),
    content: context.importXML(contentXML),
    style: parseDragAndDropStyle(configuration.findChildTag('style').text.trim()),
    ...(freeSnapping && { freeSnapping }),
  };
};
