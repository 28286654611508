import {
  type Coords,
  type GeoContentPersistProps,
  type GeoObject,
  type GeoObjectMap,
  type LabelObject,
  type PointObject,
  SEGMENT,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import { type GeoAddLabelState } from './GeoAddLabel';
import { persistLabel } from '../persist';
import { getLabelId } from '../../helpers';
import { resetSeverity } from '../helpers';
import {
  type ConfirmLabelPayload,
  type DeleteLabelPayload,
  initialState,
} from './geoAddLabelReducer';
import { removeVertices } from '../delete/helpers';
import { AdditionalButton, type AdditionalButtonProps } from '@seriesplayer/common-ui';

export const getTFromCoords = (
  mouseWorldCoords: Coords,
  p1WorldCoords: Coords,
  p2WorldCoords: Coords
): number => {
  // Note that this assumes that the mouse click is close enough to the line
  // that we can use the click coordinates as a good approximation for the point location directly
  // (instead of calculating the nearest point on the line)
  const { x, y } = mouseWorldCoords;
  const { x: x1, y: y1 } = p1WorldCoords;
  const { x: x2, y: y2 } = p2WorldCoords;

  let t: number;
  if (x1 !== x2 && y1 !== y2) {
    // We average tx and ty for to deal with cases where the mouse click is far enough outside
    // the line that simply taking tx or ty would look annoying for the user
    const tx = (x - x1) / (x2 - x1);
    const ty = (y - y1) / (y2 - y1);
    t = (tx + ty) / 2;
  } else if (x1 === x2 && y1 !== y2) {
    t = (y - y1) / (y2 - y1);
  } else if (x1 !== x2 && y1 === y2) {
    t = (x - x1) / (x2 - x1);
  } else {
    // If x1=== x2 && y1=== y2 (which shouldn't really happen in practice),
    // we put the label in the middle
    t = 0.5;
  }
  return t;
};

export const confirmLabel = (
  state: GeoAddLabelState,
  payload: ConfirmLabelPayload
): GeoAddLabelState => {
  const { index, labelAlignTop, onPersistLocalState } = payload;

  let newState = state;
  const labelId = getLabelId(state.labelingObjectId);

  const mouseWorldCoords = payload.mouseWorldCoords;

  // We calculate the t value (distance percentage from start point in [0, 1])
  // via the distance of the mouse click from start point P1 and from end point P2.
  // e.g. if t = 0.2 then the point will be 0.2 * line length from first point and
  // 0.8 * line length from second point
  let t: number | undefined = undefined;
  if (mouseWorldCoords !== undefined) {
    const refObject = state.persistProps.geoContentMap[state.labelingObjectId];
    if (
      refObject.type === STRAIGHTLINE ||
      refObject.type === SEGMENT ||
      refObject.type === VECTOR
    ) {
      const [point1Id, point2Id] = refObject.referringTo;
      const point1 = state.persistProps.geoContentMap[point1Id] as PointObject;
      const point2 = state.persistProps.geoContentMap[point2Id] as PointObject;

      t = getTFromCoords(mouseWorldCoords, point1.coords, point2.coords);
    }
  }

  if (!(labelId in newState.persistProps.geoContentMap)) {
    // create a new label
    newState = {
      ...newState,
      ...persistLabel<GeoAddLabelState>(
        labelId,
        newState.labelingObjectId,
        newState.labelList[index],
        index,
        newState,
        { labelAlignTop },
        undefined,
        t
      ),
    };
  } else {
    // adapt the content of an existing label
    const persistProps: GeoContentPersistProps = state.persistProps;
    const geoContentMap: GeoObjectMap<GeoObject> = persistProps.geoContentMap;
    // unset severity
    const label = resetSeverity(geoContentMap[labelId]);
    newState = {
      ...newState,
      persistProps: {
        ...persistProps,
        geoContentMap: {
          ...geoContentMap,
          [labelId]: {
            ...label,
            content: newState.labelList[index],
            activeIndex: index,
            labelType: undefined,
          },
        },
      },
    };
  }

  onPersistLocalState(newState.persistProps);

  return {
    ...newState,
    active: false,
    activeIndex: {
      ...newState.activeIndex,
      [newState.labelingObjectId]: index,
    },
    labelList: [],
    pickerList: [],
    initial: false,
  };
};

export const deleteLabel = (
  state: GeoAddLabelState,
  payload: DeleteLabelPayload
): GeoAddLabelState => {
  const { onPersistLocalState } = payload;

  let newState = state;
  const labelId = getLabelId(state.labelingObjectId);

  if (labelId in newState.persistProps.geoContentMap) {
    newState = {
      ...state,
      persistProps: removeVertices(state.persistProps, labelId),
    };
  }

  onPersistLocalState(newState.persistProps);

  return {
    ...initialState,
    persistProps: newState.persistProps,
  };
};

export const addAdditionalButton = (
  labelAlreadyPersisted: boolean,
  onCloseLabel: () => void,
  onDeleteLabel?: () => void
): AdditionalButtonProps => ({
  mode: labelAlreadyPersisted ? AdditionalButton.trash : AdditionalButton.close,
  onClose: onCloseLabel,
  onDelete: onDeleteLabel,
});

export const isUserLabel = (v: GeoObject): v is LabelObject => 'activeIndex' in v;
