import { type Content, type ContentReference } from '../../xml-converter/core';
import { type FontWeight } from '@bettermarks/bm-font';
import { type LineStyle } from '../components/SquaredPaper/types';

export const ALIGN = 'align';
export const OVERLAY_DIGIT_FONT_SIZE = 11;
export const SQUARED_PAPER_CELL_SIZE = 30;

export const enum DigitType {
  simple = 'simple',
  carry = 'carry',
  borrow = 'borrow',
}

export const enum CarryDigitAlignment {
  top = 'top',
  bottom = 'bottom',
}

export const enum LineAlignment {
  top = 'top',
  left = 'left',
  bottom = 'bottom',
  right = 'right',
  carry = 'carry',
}

export interface Line {
  coord: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  lineWeight?: 'thick';
  align?: LineAlignment;
  style?: LineStyle;
}

export interface DigitStyle {
  color?: string;
  backgroundColor?: string;
  fontWeight?: FontWeight;
}

export interface Digit extends DigitStyle {
  x: number;
  y: number;
  value: string;
  id?: string;
  align?: CarryDigitAlignment;
  type?: DigitType;
  crossedOut?: boolean;
  decimalPoint?: boolean;
  hasCarryLine?: boolean;
  overlay?: ContentReference;
}

export interface SquaredPaperContent extends Readonly<Content> {
  width: number;
  height: number;
  digits: Digit[];
  lines: Line[];
}

export const enum Orientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
}
