import * as React from 'react';
import { type FractionFormProps } from '../../FractionForm';
import { FractionFormRenderer } from '../FractionFormRenderer';

// removes all callbacks except the one to remove an entire form
export const Remove: React.FC<FractionFormProps> = ({
  onRemoveForm,
  onSetSegments,
  onToggleSegment,
  disabled,
  ...props
}) => <FractionFormRenderer {...props} onRemoveForm={!disabled ? onRemoveForm : undefined} />;
