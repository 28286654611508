import * as React from 'react';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import { type HLineContent } from '@bettermarks/gizmo-types';

import styles from './HLine.scss';

export type HLineProps = HLineContent & ContextState;

export const HLine: React.FC<HLineProps> = ({ count }) => (
  <div className={styles[['one', 'two'][count - 1]]} />
);

HLine.displayName = 'HLine';
