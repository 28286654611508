import { isNumber } from 'lodash';
import { EPS } from './constants';
// Types

type Polar = {
  r: number;
  phi: number;
};

export type Complex = {
  re: number;
  im: number;
};

export type AnyNumber = number | Complex;

const maybeZero = (x: number) => (Math.abs(x) < EPS ? 0 : x);

const toComplex = (x: AnyNumber): Complex => ({
  re: isNumber(x) ? x : x.re,
  im: isNumber(x) ? 0 : x.im,
});

const toPolar = (z: Complex): Polar => ({
  r: Math.sqrt(z.re * z.re + z.im * z.im),
  phi: maybeZero(Math.atan2(z.im, z.re)),
});

const polarToComplex = (p: Polar): Complex => ({
  re: maybeZero(p.r * Math.cos(p.phi)),
  im: maybeZero(p.r * Math.sin(p.phi)),
});

// internal functions

const cadd = (a: Complex, b: Complex): Complex => ({
  re: a.re + b.re,
  im: a.im + b.im,
});

const csub = (a: Complex, b: Complex): Complex => ({
  re: a.re - b.re,
  im: a.im - b.im,
});

const cmult = (a: Complex, b: Complex): Complex => ({
  re: a.re * b.re - a.im * b.im,
  im: a.re * b.im + a.im * b.re,
});

const cdiv = (a: Complex, b: Complex, denom = b.re * b.re + b.im * b.im): Complex => ({
  re: (a.re * b.re + a.im * b.im) / denom,
  im: (a.im * b.re - a.re * b.im) / denom,
});

const cabs = (z: Complex): number => Math.sqrt(z.re * z.re + z.im * z.im);

// the 'real' square root due to http://hal.upmc.fr/hal-00627327/document (real convention)
const polarSqrt = (p: Polar) => ({
  r: Math.pow(p.r, 1 / 2),
  phi: p.phi / 2,
});

// the 'real' cubic root due to http://hal.upmc.fr/hal-00627327/document (real convention)
const polarCbrt = (p: Polar) => ({
  r: Math.pow(p.r, 1 / 3),
  phi:
    -Math.PI < p.phi && p.phi < -Math.PI / 2
      ? (1 / 3) * p.phi - (2 / 3) * Math.PI
      : Math.abs(p.phi - -Math.PI / 2) < EPS
      ? Math.PI / 2
      : -Math.PI / 2 < p.phi && p.phi < Math.PI / 2
      ? (1 / 3) * p.phi
      : Math.abs(p.phi - Math.PI / 2) < EPS
      ? -Math.PI / 2
      : (1 / 3) * p.phi + (2 / 3) * Math.PI,
});

// all square roots in 'real' order
const polarSqrts = (p: Polar, first = polarSqrt(p)) => [
  first,
  { r: first.r, phi: first.phi + Math.PI },
];

// all cubic roots in 'real' order
const polarCbrts = (p: Polar, first = polarCbrt(p)) => [
  first,
  { r: first.r, phi: first.phi + (Math.PI * 2) / 3 },
  { r: first.r, phi: first.phi + (Math.PI * 4) / 3 },
];

// exported functions
export const add = (a: AnyNumber, b: AnyNumber): Complex => cadd(toComplex(a), toComplex(b));
export const sub = (a: AnyNumber, b: AnyNumber): Complex => csub(toComplex(a), toComplex(b));
export const mult = (a: AnyNumber, b: AnyNumber): Complex => cmult(toComplex(a), toComplex(b));
export const div = (a: AnyNumber, b: AnyNumber): Complex => cdiv(toComplex(a), toComplex(b));
export const abs = (x: AnyNumber): number => cabs(toComplex(x));

// the 'real' square root due to http://hal.upmc.fr/hal-00627327/document (real convention)
export const realSqrt = (x: AnyNumber, p = toPolar(toComplex(x))): Complex =>
  polarToComplex(polarSqrt(p));

// the 'real' cubic root due to http://hal.upmc.fr/hal-00627327/document (real convention)
export const realCbrt = (x: AnyNumber, p = toPolar(toComplex(x))): Complex =>
  polarToComplex(polarCbrt(p));

// all square roots in 'real' order
export const sqrt = (x: AnyNumber, p = toPolar(toComplex(x))): Complex[] =>
  polarSqrts(p).map(polarToComplex);

export const cbrt = (x: AnyNumber, p = toPolar(toComplex(x))): Complex[] =>
  polarCbrts(p).map(polarToComplex);
