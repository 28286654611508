import styled, { css } from 'styled-components';
import { Layout, defaultTheme } from '@seriesplayer/common-ui';

interface BaseLayout {
  heightAuto?: boolean;
  textCentered?: boolean;
}

export const plainText = css`
  font-size: ${defaultTheme.dimensions.fontSizeM};
  color: ${defaultTheme.colors.cTextDark};
  margin-bottom: ${defaultTheme.dimensions.spaceXs};
  line-height: ${defaultTheme.dimensions.lineHeightL};
`;

export const BaseLayout = styled(Layout)<BaseLayout>`
  ${(props) => !props.heightAuto && 'height: 100%;'}

  .plaintext {
    ${plainText}
    ${(props) => props.textCentered && 'text-align: center;'}
  }
`;
