import {
  annotationInner,
  DECORATION,
  type DropTargetContent,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { resolveDragSource } from './resolveDragSource';
import { decorationToString } from '../../../gizmo-utils/decoration';

export const exportDropTarget: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as DropTargetContent;

  let itemXML = '';
  const dragSource = resolveDragSource(context.content, content);
  if (dragSource) {
    itemXML = exportContent(dragSource.$refid, context);
    if (!content.dragItemFixed) {
      // when the contained item originates from an actual drag source,
      // replace id with unique one, otherwise the source id will be present more then once
      itemXML = itemXML.replace(` id="${dragSource.dragSource.$id}"`, ` id="${content.$id}:item"`);
      // (we are not doing this in case the item comes from dragItemFixed!)
    }
  }
  const decoration = decorationToString(
    {
      borderColor: content.borderColor,
      backgroundAlpha: content.backgroundAlpha,
    },
    undefined,
    content.severity
  );

  return semantics(
    `
      <mrow ${xmlAttr(DECORATION, decoration)}>
        <configuration>
          <targetGroup>${content.targetGroup}</targetGroup>
          <style>${content.style}</style>
        </configuration>
        ${itemXML}
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
