import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { DURATION_TOTAL_HEIGHT, DURATION_VERTICAL_PADDING } from '../day-duration/constants';
import { type TimeDurationGizmoContent } from '@bettermarks/gizmo-types';

export const measureTimeDuration: Ruler<TimeDurationGizmoContent> = (_, content) => {
  const height = DURATION_TOTAL_HEIGHT;
  return {
    height,
    refLine: DURATION_VERTICAL_PADDING, // the duration arrow level
  };
};

export const applyTimeDurationStyles: ApplyStyles = (
  { hourRef, minuteRef }: TimeDurationGizmoContent,
  style
) => [hourRef, minuteRef].map(({ $refid: refId }) => ({ refId, style }));
