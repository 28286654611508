import { type BasicShapeContent } from '@bettermarks/gizmo-types';
import { SVG_HEIGHT, SVG_PADDING_BOTTOM, SVG_PADDING_TOP } from '../components/BasicShape';
import { type Ruler } from '../../gizmo-utils/configuration';

// pre-calculated for the refLine
const SVG_HALF_HEIGHT = SVG_HEIGHT * 0.5;

export const measureBasicShape: Ruler = (outerStyles, { scalefactor }: BasicShapeContent) => ({
  height: SVG_PADDING_TOP + SVG_HEIGHT * scalefactor + SVG_PADDING_BOTTOM,
  refLine: SVG_PADDING_BOTTOM + SVG_HALF_HEIGHT * scalefactor,
});
