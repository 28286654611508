import { stepFontSize } from '@bettermarks/bm-font';
import { type ScaleProps } from '../../../../gizmo-utils/configuration';
import {
  $MFRAC,
  $MOVER,
  $MROOT,
  $MSUB,
  $MSUBSUP,
  $MSUP,
  $MUNDER,
  $MUNDEROVER,
  BASE,
  INDEX,
} from '@bettermarks/gizmo-types';

const SCALING_STEPS = {
  [$MFRAC]: -2,
  [$MROOT]: -4,
  [$MSUB]: -4,
  [$MSUP]: -4,
  [$MSUBSUP]: -4,
  [$MUNDEROVER]: -4,
  [$MUNDER]: -4,
  [$MOVER]: -4,
};

export const scaleFontSize = (
  fontSize: number,
  contentType: string,
  childKey: string | number,
  { fractionScaling, minimumFontSize = 10 }: ScaleProps = {}
): number => {
  switch (contentType) {
    case $MROOT:
      return childKey === INDEX
        ? stepFontSize(fontSize, SCALING_STEPS[contentType], minimumFontSize)
        : fontSize;
    case $MFRAC:
      return fractionScaling
        ? stepFontSize(fontSize, SCALING_STEPS[contentType], minimumFontSize)
        : fontSize;
    case $MSUB:
    case $MSUP:
    case $MSUBSUP:
    case $MUNDEROVER:
    case $MUNDER:
    case $MOVER:
      return childKey !== BASE
        ? stepFontSize(fontSize, SCALING_STEPS[contentType], minimumFontSize)
        : fontSize;
    default:
      return fontSize;
  }
};

export const nextScaleProps = (
  scaleProps: ScaleProps,
  interactive: boolean,
  contentType: string,
  childKey: string | number
): ScaleProps => {
  switch (contentType) {
    case $MFRAC:
      return interactive ? scaleProps : { fractionScaling: true };
    case $MROOT:
      return childKey === INDEX ? {} : scaleProps;
    case $MSUB:
    case $MSUP:
    case $MSUBSUP:
    case $MUNDEROVER:
    case $MUNDER:
    case $MOVER:
      return childKey !== BASE ? {} : scaleProps;
    default:
      return scaleProps;
  }
};
