import { connect } from 'react-redux';
import { getOr } from 'lodash/fp';

import { type Dispatch, gizmoAction } from '../../../../../gizmo-utils/redux';
import { setMode } from '../../../../../gizmo-utils/redux/gizmoActions';
import {
  type EditorMode,
  FractionFormEditorMode,
  GeoEditorMode,
  InputToolTypes,
} from '@bettermarks/gizmo-types';
import { ApplicationState } from '../../../../../types';
import {
  ModeSelector,
  type ModeSelectorDispatchProps,
  type ModeSelectorStateProps,
} from './ModeSelector';

const EMPTY_ARRAY: EditorMode[] = [];

const mapDispatchToProps = (dispatch: Dispatch): ModeSelectorDispatchProps => ({
  onSetMode: (mode: EditorMode, refid: string) => {
    dispatch(
      gizmoAction(setMode(mode), refid, {
        ...(mode !== FractionFormEditorMode.Add && { skipUndo: true }),
      })
    );
  },
});

export function getModesFromState(state: ApplicationState) {
  const content = ApplicationState.toSelectedContent(state).get(state);
  const isTouch = ApplicationState.toRuntimeStateIsTouch.get(state);
  const inputTool = content && content.tool;
  const modes =
    inputTool && inputTool.type === InputToolTypes.modeSelector
      ? getOr(EMPTY_ARRAY, 'modes', inputTool).filter((mode: EditorMode) =>
          isTouch ? mode : mode !== GeoEditorMode.SCROLL
        )
      : EMPTY_ARRAY;

  return modes;
}

const mapStateToProps = (state: ApplicationState): ModeSelectorStateProps => {
  const $refid = ApplicationState.toSelectedRefId(state).get(state);
  const content = ApplicationState.toSelectedContent(state).get(state);
  const isTouch = ApplicationState.toRuntimeStateIsTouch.get(state);
  const inputTool = content && content.tool;
  const modes =
    inputTool && inputTool.type === InputToolTypes.modeSelector
      ? getOr(EMPTY_ARRAY, 'modes', inputTool).filter((mode: EditorMode) =>
          isTouch ? mode : mode !== GeoEditorMode.SCROLL
        )
      : EMPTY_ARRAY;

  const editorMode = getOr(modes[0], 'selectedMode', content);

  return {
    contentId: $refid,
    editorMode: modes.indexOf(editorMode) !== -1 ? editorMode : modes[0],
    modes,
  };
};

export const ModeSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(ModeSelector);
