import { type KeyHandler } from '../Keyboard/Key';

export interface CalculatorProps {
  onKey?: KeyHandler;
  layout: CalculatorLayout;
}

export enum CalculatorLayout {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
