import * as React from 'react';
import { flow } from 'lodash/fp';
import { connect, type MapDispatchToPropsFunction, type MapStateToProps } from 'react-redux';

import { getStaticServerURL, parseEMPlayerQueryString } from '../../apps/emplayer/helper';
import { type ApplicationState } from '../../types';

import { emRequested } from './actions';
import { KEMPlayer, type KemPlayerDispatchProps, type KEMPlayerStateProps } from './KEMPlayer';
import { jumpToPastAndEnrich } from '../store/combineSeriesplayerReducer';
import { useLocation, useParams } from 'react-router-dom';

type StateProps = KEMPlayerStateProps;
type OwnProps = KEMPlayerStateProps;
const mapStateToProps: MapStateToProps<StateProps, OwnProps, ApplicationState> = (
  state,
  ownProps
) => {
  const kem = state.emData.kem;

  return {
    ...ownProps,
    kemData: kem.data,
    status: kem.status,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<KemPlayerDispatchProps, undefined> = (
  dispatch
) => ({
  dispatch,
  onInit: ({ ...requestParams }) => {
    dispatch(emRequested(requestParams));
  },
  onReset: () => {
    dispatch(jumpToPastAndEnrich(0));
  },
});

const KemPlayerContainer = connect(mapStateToProps, mapDispatchToProps)(KEMPlayer);

/**
 * Read the parameters for the KEMPlayer from the get route/params and pass them to the KEMPLayer.
 *
 * This is used for starting the KEM test player:
 * - [seriesplayer url]/#/kem/[locale]/[skillId]
 *
 * For using a local content server (using the local generator):
 * - [seriesplayer url]/#/kem/[locale]/[skillId]?staticUrl=[content server url]
 *
 * For running local tests with KEM served locally as assets:
 * - [seriesplayer url]/#/kem/[locale]/[skillId]?staticUrl=[relative path to KEM]&test=true
 *
 * When changing something here please update the main Readme section regarding deep links
 */
export function KEMPlayerRoute() {
  const location = useLocation();
  const { id, locale } = useParams();
  const { staticServerUrl, test, staticMediaUrl, encrypt, forWhiteboard, inBook } = flow(
    parseEMPlayerQueryString,
    getStaticServerURL
  )(location.search);

  return (
    <KemPlayerContainer
      id={id ?? ''}
      locale={locale ?? ''}
      staticServerUrl={staticServerUrl}
      staticMediaUrl={staticMediaUrl}
      test={test}
      encrypt={encrypt}
      forWhiteboard={forWhiteboard}
      inBook={inBook}
      noHeader
    />
  );
}
