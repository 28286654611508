import { connect } from 'react-redux';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { ApplicationState } from '../../../../types';
import { Toolbar, type ToolbarDispatchProps, type ToolbarStateProps } from './Toolbar';
import { type Dispatch } from '../../../../gizmo-utils/redux';
import { toggleToolbarDrawer } from './actions';
import { registerShortcuts, unregisterShortcuts } from '../../../../gizmo-utils/redux/gizmoActions';
import { shortcuts } from '../../../../gizmos/formula/Formula/FormulaContainer';
import { enterShortcut } from '../SeriesPlayer/SeriesPlayerContainer';
import { getAvailableToggles } from './getAvailableToggles';
import { isDragSourceDrawerAvailable } from './isDragSourceDrawerAvailable';

const mapStateToProps = (state: ApplicationState): ToolbarStateProps => ({
  toggles: getAvailableToggles(
    {
      [ToolbarElements.dnd]:
        ApplicationState.currentStepIncludesDragSource(state) &&
        isDragSourceDrawerAvailable(
          state.runtimeState.availableWidth,
          state.runtimeState.availableHeight
        ),
    },
    ApplicationState.toToolbarTools.get(state) as ToolbarElements[]
  ),
  openDrawer: ApplicationState.toToolbarOpenDrawerName.get(state) as Nullable<ToolbarElements>,
});

const mapDispatchToProps = (dispatch: Dispatch): ToolbarDispatchProps => ({
  toggle: (drawerName: Nullable<ToolbarElements>, openDrawerName: Nullable<string>) => {
    // unregistering keyboard shortcuts, when calculator is toggled ON
    if (
      drawerName === ToolbarElements.calculator &&
      openDrawerName !== ToolbarElements.calculator
    ) {
      dispatch(unregisterShortcuts({ shortcuts }));
      dispatch(unregisterShortcuts({ shortcuts: enterShortcut }));
    }

    dispatch(toggleToolbarDrawer({ drawerName, toggledByUser: true }));

    // Re-registering keyboard shortcuts, when calculator is deselected
    //
    // IMPORTANT:
    // This needs to be done after dispatching the actual toggling,
    // since registration of keyboard shortcuts is prevented, when the calculator is active!!!
    if (openDrawerName === ToolbarElements.calculator) {
      dispatch(registerShortcuts(shortcuts));
      dispatch(registerShortcuts(enterShortcut));
    }
  },
});

export const ToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(Toolbar);
