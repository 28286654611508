import { getFontMetric } from '../../../../../utils/fontMetric';
import classNames from 'classnames';
import {
  type FenceType,
  type HasHeight,
  isContentReference,
  isMAbs,
  isMFenced,
  type MAbs as MAbsContent,
  type MathContent,
  type MDecoratable,
  type MFenced as MFencedContent,
  NO_FENCE,
  numberFromStyles,
  ROUND_CLOSE,
  ROUND_OPEN,
} from '@bettermarks/gizmo-types';
import * as React from 'react';
import { enrichFormulaRow } from '../../measure/enrichFormulaRow';
import { type EnrichNestedMathContent } from '../../measure/measureStatic';
import { BackgroundColor } from '../BackgroundColor';
import { calculateYOffset, enrich, onClickBetweenChildren, onClickLeaf } from '../helpers';
import { type MProps, type SelectChildHandler, type SelectHandler } from '../types';
import { VerticalAlign } from '../VerticalAlign';

import { Fence, FENCE_VERTICAL_SHIFT, type FenceProps } from './Fence';

import styles from './MFenced.scss';

export const MATHML_FENCE_PADDING = numberFromStyles(styles.MATHML_FENCE_PADDING);

export const AlignedFence = (
  fence: FenceType,
  fencesOffset: number | undefined,
  onSelect: SelectHandler | undefined,
  props: Pick<FenceProps, 'computedStyles' | 'height'>
) => {
  // if no fence is rendered we also want to avoid rendering an empty VerticalAlign
  // this way a Cursor can be the last child in the MFenced
  // in which case it needs to have a width to always be visible
  if (fence === NO_FENCE) return undefined;
  return (
    <VerticalAlign yOffset={fencesOffset}>
      <Fence fence={fence} onClick={onClickLeaf(onSelect)} {...props} />
    </VerticalAlign>
  );
};

export type MFencedProps = MProps &
  MDecoratable &
  HasHeight & {
    ensureMinSpace?: boolean;
    open: FenceType;
    close: FenceType;
    fencesOffset?: number;
    onSelectFreeSpace?: SelectChildHandler;
    onSelectOpeningFence?: SelectHandler;
    onSelectClosingFence?: SelectHandler;
  };

/** U+2060 WORD JOINER, used to prevent opening or closing fence to "break alone" or "stand alone"
 *
 * "break alone"
 * ........(.....
 * ).............  <-- NOT WANTED
 *
 * "stand alone"
 * .............(  <-- NOT WANTED
 * ......).......
 *
 * see https://www.w3.org/html/wg/wiki/HTMLCharacterUsage
 * (WORD JOINER ~ NO BREAK SPACE without width)
 */
const noBreak = '\u2060'; // '\u202F'

/**
 * The `MFenced' component renders a MathML <mfenced> node
 *
 * The current implementation supports only round parentheses.
 */
export const MFenced: React.FC<MFencedProps> = ({
  children,
  close = ROUND_CLOSE,
  computedStyles,
  ensureMinSpace,
  height,
  fencesOffset,
  open = ROUND_OPEN,
  onSelectOpeningFence,
  onSelectClosingFence,
  onSelectFreeSpace,
}) => {
  const fenceProps = { computedStyles, height };
  return (
    <BackgroundColor backgroundColor={computedStyles.backgroundColor}>
      <span
        role="button"
        className={classNames(styles.mfenced, {
          [styles.takeSpace]: ensureMinSpace,
        })}
        style={computedStyles}
        onMouseDown={onClickBetweenChildren(onSelectFreeSpace)}
      >
        {AlignedFence(open, fencesOffset, onSelectOpeningFence, fenceProps)}
        {/*
        BM-50390: systemFont applies system font to noBreak to avoid rendering issues in Chrome.
        */}
        <span className={styles.systemFont}>{noBreak}</span>
        {children}
        {noBreak}
        {AlignedFence(close, fencesOffset, onSelectClosingFence, fenceProps)}
      </span>
    </BackgroundColor>
  );
};

MFenced.displayName = 'MFenced';

export const getHeightFactor = (children: MathContent[]) =>
  children.find((c) => isMFenced(c) || isContentReference(c) || isMAbs(c)) ? 1 : 0.75;

export const enrichMFenced: EnrichNestedMathContent<MFencedContent | MAbsContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher
) => {
  /**
   * We have to support the following two cases here:
   *
   * MFenced: {children: MathContent[], ...}
   * MAbs: {value: {children: MathContent[]}, ...}
   */
  const mrow = isMFenced(content) ? content : content.value;
  const {
    height: childrenHeight,
    refLine,
    enrichedContent,
  } = enrichFormulaRow(
    formulaStyles,
    mrow,
    isMFenced(content) ? path : [...path, 'value'],
    mathContentEnricher
  );
  const { computedStyles } = enrichedContent;

  const lowerHalf = refLine;
  const upperHalf = childrenHeight - refLine;
  const additionalFencesHeight = FENCE_VERTICAL_SHIFT * 2;
  const metricsHeight = Math.max(lowerHalf, upperHalf) * 2 + additionalFencesHeight;
  const height = metricsHeight * getHeightFactor(mrow.children);

  /* TODO: we may need a correction value based on strokeWidth for the vertical alignment
  (in an earlier implementation it was -0.5)
  We have not fully understood why, it seems to be related to fine tuning the vAlign,
  but it might point to another problem
  */
  const fencesOffset =
    calculateYOffset(getFontMetric(formulaStyles.fontSize, formulaStyles.fontWeight), height / 2) -
    0.5;

  return enrich(
    {
      ...(isMFenced(enrichedContent)
        ? enrichedContent
        : { ...content, value: enrichedContent, computedStyles }),
      height,
      fencesOffset,
    },
    {
      height: metricsHeight,
      padding: MATHML_FENCE_PADDING,
      refLine: height * 0.5,
      relativeToBaseLine: true,
    }
  );
};
