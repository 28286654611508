import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type PointerContent,
  semantics,
} from '@bettermarks/gizmo-types';
import { isNil } from 'lodash';

export const exportPointer: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as PointerContent;
  const { height, peakWidth, width, decoration } = context.content[contentRefId] as PointerContent;
  const { capStyleBottom, capStyleTop, color, dashGapLength, dashLength, thickness } = decoration;
  let dashDecoration = '';
  if (!isNil(dashGapLength) && !isNil(dashLength)) {
    dashDecoration = ` dash-length:${dashLength}; gap-length:${dashGapLength};`;
  }
  return semantics(
    `
      <pointer decoration="color:${color};${dashDecoration}
          cap-style-bottom:${capStyleBottom}; cap-style-top:${capStyleTop}; thickness:${thickness}"
          height="${height}"
          peakWidth="${peakWidth}"
          width="${width}"
      />
    `,
    annotationInner(content.$, content)
  );
};
