import * as React from 'react';
import qs from 'query-string';
import { Comparison } from './Comparison';
import { Error as ErrorCircle } from '../../components';
import { Loader } from '../seriesplayer/components';
import { useLocation } from 'react-router-dom';

type ParsedCRIComparisonRouteParams = {
  /**
   * The ID of the classroom intros to load.
   *
   * Example: data_piechart2.VAR01
   *
   */
  criId: string;
  criId1?: string;
  criId2?: string;
  /**
   * Specify the URL of the server that hosts the content that you want to fetch.
   * If not specified, it defaults to content.bettermarks.com
   */
  staticUrl1?: string;
  staticUrl2?: string;
  locale1: string;
  locale2: string;
  isTest1: boolean;
  isTest2: boolean;
};

class CRIParamsParser {
  criId: string;
  isTest1: boolean;
  isTest2: boolean;
  criId1?: string;
  criId2?: string;
  staticUrl1?: string;
  staticUrl2?: string;
  locale1: string;
  locale2: string;

  throwRequired(key: keyof ParsedCRIComparisonRouteParams): never {
    throw Error(`missing query parameter ${key}`);
  }

  constructor(parsed: object) {
    const required: Array<keyof ParsedCRIComparisonRouteParams> = [
      'criId',
      'isTest1',
      'isTest2',
      'locale1',
      'locale2',
    ];
    required.forEach((key) => {
      // Important to check undefined explicitly since we may parse "false" boolean
      // e.g. isTest1=false
      if (parsed[key] === undefined) this.throwRequired(key);
    });
    Object.keys(parsed).forEach((key) => {
      this[key] = parsed[key];
    });
  }

  get(): ParsedCRIComparisonRouteParams {
    return {
      criId: this.criId,
      criId1: this.criId1,
      criId2: this.criId2,
      staticUrl1: this.staticUrl1,
      staticUrl2: this.staticUrl2,
      locale1: this.locale1,
      locale2: this.locale2,
      isTest1: this.isTest1,
      isTest2: this.isTest2,
    };
  }
}

/**
 * Component to display two versions of a classroom intro (cri) next to each other.
 *
 * Example link:
 *
 *  https://gremlins.bettermarks.com/#/em-comparison?
 *    criId=data_piechart2.VAR01&
 *    staticUrl1=http://staticdev.jenkinsdev01.inf00.inf.bm.loc/content_output/&
 *    staticUrl2=https://content-generator-jvm.bettermarks.com&
 *    isTest1=true&
 *    isTest2=true&
 *    locale1=de_DE&
 *    locale2=de_DE
 *
 *
 * Possible query parameters, see CRIComparisonRouteParams.
 */
export function CRIComparison() {
  const location = useLocation();
  const [missingParamError, setMissingParamError] = React.useState<string>();
  const [params, setCriComparisonParams] = React.useState<ParsedCRIComparisonRouteParams>();
  const [urls, setUrls] = React.useState<{ url1: string; url2: string }>();
  const loaded = !!missingParamError || !!urls;

  React.useEffect(() => {
    try {
      setCriComparisonParams(
        new CRIParamsParser(
          qs.parse(location.search, {
            parseBooleans: true,
          })
        )
      );
    } catch (e) {
      setMissingParamError(`${e}`);
    }
  }, []);

  React.useEffect(() => {
    if (params) {
      const { criId, criId1, criId2, staticUrl1, staticUrl2, locale1, locale2, isTest1, isTest2 } =
        params;

      const cris = [
        {
          criId: criId1 ? criId1 : criId,
          staticUrl: staticUrl1,
          locale: locale1,
          isTest: isTest1,
        },
        {
          criId: criId2 ? criId2 : criId,
          staticUrl: staticUrl2,
          locale: locale2,
          isTest: isTest2,
        },
      ];
      const [url1, url2] = cris.map(
        (cri) =>
          `https://gremlins.bettermarks.com/#/classroom-intro/${cri.locale}/${
            cri.criId
          }?${qs.stringify({
            ...(cri.staticUrl && { staticUrl: cri.staticUrl }),
            forWhiteboard: false,
            test: cri.isTest,
          })}`
      );
      setUrls({ url1, url2 });
    }
  }, [missingParamError, params]);

  return (
    <Loader loaded={loaded}>
      {missingParamError ? (
        <>
          <ErrorCircle />
          {missingParamError}
        </>
      ) : (
        urls && <Comparison url1={urls.url1} url2={urls.url2} />
      )}
    </Loader>
  );
}
