import { compose, get, identity, isNil, omit, set } from 'lodash/fp';

import { type Content, ContentDict, type FormulaContent, Lens, RS } from '@bettermarks/gizmo-types';
import { type ShortcutRegistry } from '../gizmo-utils/keyboard';

import { type AppSettings, type LobLinkQueryParams } from './App';
import { type Features } from './Features';
import { AppExercise, ContentType, type ExerciseStatus } from './AppExercise';
import { type StepStatus, type UndoableQuestion } from './AppStep';
import {
  type ConfirmSubmitIncompletePayload,
  DialogType,
  type EndscreenPayload,
  type ErrorPayload,
  type LightBoxType,
  type SimpleDialogTypes,
} from './dialog';
import { type LoaderState } from './loader';
import { Series, SeriesMode, type SeriesStatus } from './Series';
import { Toolbar } from './Toolbar';
import { type ScreenLock } from './screenLock';
import { CRI } from './CRI';
import { EM, type EMData } from './EM';
import { isEmpty } from 'lodash';

export interface IRuntimeState {
  availableWidth: number;
  availableHeight: number;
  isOnline: boolean;
  isTouch: boolean;
}

export type RuntimeState = Readonly<IRuntimeState>;

export const enum ProblemReportedState {
  success = 'success',
  failed = 'failed',
  progress = 'progress',
  unsupported = 'unsupported',
}

/**
 * Draft v1
 *
 * | SeriesStatus          | ExerciseStatus       | UX outcome               |
 * | --------------------- | -------------------- | ------------------------ |
 * | exerciseStarted       | stepStarted          | show submit button       |
 * |                       | attemptCompleted     | show submit button       |
 * |                       | lastAttemptCompleted | show nextStep button     |
 * | exerciseCompleted     | lastStepCompleted    | show nextExercise button |
 * | lastExerciseCompleted | lastStepCompleted    | show results button |
 *
 * StepStatus can combine with Step.mode.
 */
export interface ApplicationStatus {
  seriesStatus: SeriesStatus;
  exerciseStatus: ExerciseStatus;
  stepStatus: StepStatus;
}

export interface TimedActionMeta {
  startTime: number;
}

export type SelectedDialog =
  | {
      type: DialogType.submitIncompleteConfirmation;
      payload: ConfirmSubmitIncompletePayload;
    }
  | { type: DialogType.resetWhiteboardConfirmation }
  | { type: DialogType.error; payload: ErrorPayload }
  | { type: DialogType.endscreen; payload: EndscreenPayload }
  | { type: DialogType.fem; payload: { id: string; onCloseFromWhiteboard?: () => void } }
  | { type: SimpleDialogTypes };

export type CalculatorState = {
  afterEval: boolean;
  error?: string;
  inputFormula: FormulaContent;
  memoryExpr: string;
  resultFormula?: FormulaContent;
};

export type LobLinkReportingSettings = LobLinkQueryParams;

export type CurrentContent = {
  type: ContentType;
  id?: string;
};

export type ScaffolderData = {
  rows: ContentDict;
  transformations: ContentDict;
  rowValidationNeeded?: boolean;
};

export interface IApplicationState {
  appSettings: AppSettings;
  calculatorState?: CalculatorState;
  classroomIntro?: CRI;
  dialog: SelectedDialog;
  previousDialog: SelectedDialog;
  emData: EMData;
  features: Features;

  /**
   * use for testing bot to focus on specific DOM element to take a screenshot
   */
  focusElement?: string;
  hideContent?: boolean;
  interceptBrowserBackButton: boolean;
  lightBoxType: LightBoxType;
  loaderState: LoaderState;
  lobLinkReportingSettings?: LobLinkReportingSettings;
  ltiReporting?: boolean;
  reportProblemState?: ProblemReportedState;
  runtimeState: RuntimeState;

  scaffolder?: ScaffolderData;

  screenLock: ScreenLock;
  series: Series;
  shortcuts: ShortcutRegistry;
  toolbar: Toolbar;
  inBookNavigation?: {
    femId?: string;
  };

  /** used for debugging */
  validation?: string;
}

export type ApplicationState = Readonly<IApplicationState>;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ApplicationState {
  export const toAppSettings = Lens.create<ApplicationState>('appSettings');
  export const toRuntimeState = Lens.create<ApplicationState, 'runtimeState'>('runtimeState');
  export const toSeries = Lens.create<ApplicationState, 'series'>('series');
  export const toSeriesId = Lens.compose(toSeries, Lens.create<Series, 'id'>('id'));
  export const toToolbar = Lens.create<ApplicationState, 'toolbar'>('toolbar');
  export const toScreenLock = Lens.create<ApplicationState>('screenLock');
  export const toClassroomIntro = Lens.create<ApplicationState>('classroomIntro');
  export const toEMData = Lens.create<ApplicationState, 'emData'>('emData');
  export const toScaffolderData = Lens.create<ApplicationState, 'scaffolder'>('scaffolder');
  export const toScreenLockType = Lens.compose(
    toScreenLock,
    Lens.create<ScreenLock, 'type'>('type')
  );
  export const toScreenLockAfterUnlockAction = Lens.compose(
    toScreenLock,
    Lens.create<ScreenLock, 'afterUnlockAction'>('afterUnlockAction')
  );
  export const toAssignment = Lens.compose(toSeries, Series.toAssignment);
  export const toSeriesMode = Lens.compose(toSeries, Series.toSeriesMode);
  export const toSeriesStatus = Lens.compose(toSeries, Series.toSeriesStatus);
  export const toSeriesReportingSetting = Lens.compose(toSeries, Series.toSeriesReportingSetting);
  export const toExercises = Lens.compose(toSeries, Series.toExercises);
  export const toCurrentExerciseIndex = Lens.compose(toSeries, Series.toCurrentExerciseIndex);
  export const toExercise = (index?: number) => Lens.compose(toSeries, Series.toExercise(index));
  export const toCurrentExercise = Lens.compose(toSeries, Series.toCurrentExercise);
  export const toCurrentQuestion = Lens.compose(toSeries, Series.toCurrentQuestion);
  export const toCurrentStep = Lens.compose(toSeries, Series.toCurrentStep);
  export const toToolbarTools = Lens.compose(toToolbar, Toolbar.toTools);
  export const toToolbarOpenDrawerName = Lens.compose(toToolbar, Toolbar.toOpenDrawerName);
  export const toRuntimeStateIsTouch = Lens.compose(
    toRuntimeState,
    Lens.create<RuntimeState>('isTouch')
  );
  export const toDialog = Lens.create<ApplicationState>('dialog');
  export const toDialogType = Lens.compose(toDialog, Lens.create<SelectedDialog, 'type'>('type'));
  export const toClassroomIntroContentDict = Lens.compose(toClassroomIntro, CRI.toContentDict);

  export const toGizmoContentDict = (
    gizmoId: string,
    applicationState: ApplicationState
  ): Lens<ApplicationState, ContentDict> => {
    const currentContentType = getCurrentContentType(applicationState);

    switch (currentContentType) {
      case ContentType.FEM:
      case ContentType.KEM:
        return Lens.compose(toEMData, EM.toEMContentDict(gizmoId));
      case ContentType.CRI:
        return toClassroomIntroContentDict;
      case ContentType.EXERCISE:
        return Lens.compose(toCurrentExercise, AppExercise.toGizmoContentDict(gizmoId));
      default:
        return Lens.compose(toScaffolderData, Scaffolder.toScaffolderContentDict(gizmoId));
    }
  };

  export const toGizmoContent = (
    gizmoId: string,
    applicationState: ApplicationState
  ): Lens<ApplicationState, Content> => {
    const toContentDict = toGizmoContentDict(gizmoId, applicationState);

    return {
      get: (appState) => toContentDict.get(appState)[gizmoId] as Content,
      set: (content) => (appState) =>
        toContentDict.set({
          ...toContentDict.get(appState),
          [gizmoId]: content,
        })(appState),
    };
  };

  export const toSelectedRefId = (
    appState: ApplicationState
  ): Lens<ApplicationState, string | undefined> => {
    if (appState.scaffolder?.rows) {
      return Scaffolder.toSelectedRefId;
    } else {
      //try {
      //  return Lens.compose(toCurrentExercise, AppExercise.toSelectedRefId);
      //} catch (e) {
      return { get: () => undefined, set: () => () => appState };
      //}
    }
  };

  export const toSelectedContent = (appState: ApplicationState) => {
    if (appState.scaffolder?.rows) {
      return Scaffolder.toSelectedContent;
    } else {
      //return Lens.compose(toCurrentExercise, AppExercise.toSelectedContent);
      return {
        get: () => undefined as Content | undefined,
        set: (contentDict: Content | undefined) => (state: ApplicationState) => appState,
      } as Lens<ApplicationState, Content | undefined>;
    }
  };

  export const toLastSelection = (appState: ApplicationState) => {
    if (appState.scaffolder?.rows) {
      throw new Error('arrived in toLastSelection, but unable to handle it in scaffolder');
    } else {
      //return Lens.compose(toCurrentExercise, AppExercise.toLastSelection);
      return {
        get: () => undefined as Content | undefined,
        set: (contentDict: Content | undefined) => (state: ApplicationState) => appState,
      } as Lens<ApplicationState, Content | undefined>;
    }
  };

  export const isExerciseContentPath = (path: string) => path.endsWith('.question');
  export const isEMContentPath = (path: string) =>
    path.startsWith('fems.data') || path.endsWith('kem.data');

  /**
   * This is a lens factory, that receives a path to a question in the current exercise
   * and returns a lens to its undoable ContentDict.
   */
  export const toUndoableContentDict = (path: string): Lens<ApplicationState, UndoableQuestion> => {
    if (isEMContentPath(path)) {
      return {
        get: (appState) => get(path, toEMData.get(appState)),
        set: (undoableQuestion) => (appState) =>
          toEMData.set(set(path, undoableQuestion, toEMData.get(appState)))(appState),
      };
    }

    return {
      get: (appState) => get(path, toCurrentExercise.get(appState)),
      set: (undoableQuestion) => (appState) =>
        toCurrentExercise.set(set(path, undoableQuestion, toCurrentExercise.get(appState)))(
          appState
        ),
    };
  };

  export const getCurrentContent = (
    appState: ApplicationState
  ): { contentDict: ContentDict | undefined; type: ContentType } => {
    let contentDict: ContentDict | undefined;
    let type: ContentType;

    const dialog = ApplicationState.toDialog.get(appState) as SelectedDialog;
    const seriesId = ApplicationState.toSeriesId.get(appState);

    const inSeries = !isEmpty(seriesId);
    const inClassroomIntro = appState.classroomIntro?.data;
    const inScaffolder = appState.scaffolder?.rows;

    if (inSeries || inClassroomIntro) {
      // we are within an exercise series or a classroom intro
      if (dialog?.type === DialogType.fem && dialog?.payload) {
        contentDict = appState.emData.fems.data?.[dialog.payload.id]?.present;
        type = ContentType.FEM;
      } else if (dialog?.type === DialogType.textbook) {
        contentDict = appState.emData.kem.data?.present;
        type = ContentType.KEM;
      } else if (inClassroomIntro) {
        contentDict = appState.classroomIntro.data.present;
        type = ContentType.CRI;
      } else {
        contentDict = AppExercise.toCurrentQuestion.get(toCurrentExercise.get(appState));
        type = ContentType.EXERCISE;
      }
    } else {
      // we are within standalone content outside a series or classroom intro (KEM / FEM)
      if (
        appState.emData.currentContent?.type === ContentType.FEM &&
        appState.emData.currentContent.id !== undefined
      ) {
        contentDict = appState.emData.fems.data?.[appState.emData.currentContent.id]?.present;
        type = ContentType.FEM;
      } else if (appState.emData.currentContent?.type === ContentType.KEM) {
        contentDict = appState.emData.kem.data?.present;
        type = ContentType.KEM;
      } else if (inScaffolder) {
        contentDict = appState.scaffolder?.rows;
        type = ContentType.SCAFFOLDER;
      } else {
        contentDict = AppExercise.toCurrentQuestion.get(toCurrentExercise.get(appState));
        type = ContentType.EXERCISE;
      }
    }

    return { contentDict, type };
  };

  const getCurrentContentType = (appState: ApplicationState): ContentType =>
    getCurrentContent(appState).type;

  export const pathToCurrentContent = (appState: ApplicationState): string | undefined => {
    const contentDict = getCurrentContent(appState).contentDict;

    return contentDict !== undefined && Object.keys(contentDict).length > 0
      ? ContentDict.root(contentDict)
      : undefined;
  };

  export const getCurrentUndoableQuestion = (
    appState: ApplicationState
  ): UndoableQuestion | undefined => {
    const path = pathToCurrentContent(appState);

    if (path && isExerciseContentPath(path)) {
      return get(path, toCurrentExercise.get(appState));
    } else if (path && isEMContentPath(path)) {
      return get(path, appState.emData);
    }

    return undefined;
  };

  export const isSeriesReady = (appState: ApplicationState): boolean =>
    appState.series.currentExerciseIndex > -1;

  export const isEndOfSeries = (appState: ApplicationState): boolean =>
    appState.series.currentExerciseIndex === appState.series.exercises.length - 1;

  export const isTestMode = ({ series: { mode } }: ApplicationState): boolean =>
    mode === SeriesMode.test;

  /**
   * Check if the shortcut registry should be used to handle keyboard events.
   * Certain scenarios need to allow the user to edit a text input or -area
   * while some gizmo like Formula might be selected in the background.
   *
   * It means that those Dialogs can not use the App wide shortcutRegistry
   * to register for e.g. Escape to close the die dialog,
   * instead they need to do those things on their own.
   * (It might still be possible to use an own/local shortcut registry.)
   */
  export const preventShortcuts = ({ dialog }: ApplicationState): boolean =>
    dialog && dialog.type === DialogType.reportProblem;

  export const currentStepIncludesDragSource = (appState: ApplicationState) => {
    const currentStepContentDict = ApplicationState.toCurrentStep.get(appState)?.question.present;
    if (currentStepContentDict) {
      const currentStepRenderStyles = Object.values(currentStepContentDict).map(
        (content) => content?.$renderStyle
      );
      return currentStepRenderStyles.includes(RS.DRAG_SOURCE);
    }
    return false;
  };
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace Scaffolder {
  export const toScaffolderContentDict = (gizmoId: string): Lens<ScaffolderData, ContentDict> => {
    const path = gizmoId.startsWith('transformations') ? 'transformations' : 'rows';

    return {
      get: (scaffolderData) => get(path, scaffolderData),
      set: (contentDict) => (scaffolderData) => set(path, contentDict, scaffolderData),
    };
  };

  export const toSelectedContent: Lens<ApplicationState, Content | undefined> = {
    get: (appState: ApplicationState) => {
      const refId = toSelectedRefId.get(appState);
      return !isNil(refId)
        ? ApplicationState.toGizmoContent(refId, appState).get(appState)
        : undefined;
    },
    set: (newSelectedContent: Content | undefined) => (appState: ApplicationState) => {
      const refId = toSelectedRefId.get(appState);

      return !isNil(newSelectedContent) && !isNil(refId)
        ? // The new content must still stay selected!
          // So, we make sure to keep the `selected` property as it was.
          Lens.update(
            ApplicationState.toGizmoContent(refId, appState),
            ({ selected }) => ({ ...newSelectedContent, selected }),
            appState
          )
        : appState;
    },
  };

  export const toSelectedRefId: Lens<ApplicationState, string | undefined> = {
    get: (appState: ApplicationState) => {
      const selectedRow =
        appState.scaffolder?.rows &&
        Object.entries(appState.scaffolder.rows).find(([, content]) => content?.selected)?.[0];
      const selectedTransformation =
        appState.scaffolder?.transformations &&
        Object.entries(appState.scaffolder.transformations).find(
          ([, content]) => content?.selected
        )?.[0];

      return selectedRow || selectedTransformation;
    },
    set: (newSelectedRefId: string | undefined) => (appState: ApplicationState) => ({
      ...appState,
      scaffolder: {
        ...appState.scaffolder,
        rows: appState.scaffolder?.rows
          ? Object.entries(appState.scaffolder.rows).reduce(
              (acc, [refId, content]) => ({
                ...acc,
                [refId]: compose(
                  refId === newSelectedRefId &&
                    !isNil(content) &&
                    //content.tool &&
                    content.$interactionType
                    ? (c: Content) => ({ ...c, selected: true })
                    : identity,
                  omit('selected')
                )(content),
              }),
              {}
            )
          : {},
        transformations: appState.scaffolder?.transformations
          ? Object.entries(appState.scaffolder.transformations).reduce(
              (acc, [refId, content]) => ({
                ...acc,
                [refId]: compose(
                  refId === newSelectedRefId &&
                    !isNil(content) &&
                    //content.tool &&
                    content.$interactionType
                    ? (c: Content) => ({ ...c, selected: true })
                    : identity,
                  omit('selected')
                )(content),
              }),
              {}
            )
          : {},
      },
    }),
  };
}
