import { camelCase, isEmpty, isNil } from 'lodash';
import { isContentColor } from '../../../components/colors';
import {
  annotationInner,
  type Cell,
  CELL_ATTRIBUTES,
  CELL_DECORATION_DEFAULT,
  CELL_DEFAULT,
  type CellDecoration,
  type Content,
  type ContentColor,
  type FElement,
  FLASH_DEFAULT_MAX_WIDTH,
  HEADER_ROW_PADDING_HORIZONTAL,
  HEADER_ROW_PADDING_VERTICAL,
  identity,
  type Importer,
  type ImporterContext,
  isContentReference,
  isFormulaContent,
  LINE_DECORATION_DEFAULT,
  MAX_ROW_WIDTH,
  MAX_ROW_WIDTH_SIMPLE,
  type Padding,
  PLACE_VALUE_TABLE_DEFAULT_CONTENT,
  type Row,
  ROW_DEFAULT,
  RS,
  semantics,
  SEMANTICS,
  SPECIAL,
  switchMap,
  TABLE_ATTRIBUTES,
  TABLE_DEFAULT_CONTENT,
  type TableContent,
  type TableLineDecoration,
  TableStyle,
  toXmlElement,
  VAlign,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';

import styles from '../table.scss';

const parseWidth = (value: string): number => parseFloat(value.replace('%', '')) * 0.01;

const parseColumnPercents = (value: string): number[] =>
  value.split('|').map((v) => parseFloat(v) * 0.01);

const parseBackgroundDecoration = (value: string): ContentColor | undefined =>
  isContentColor(value) ? value : CELL_DECORATION_DEFAULT.backgroundColor;

export const parseCellDecoration = (value: string): CellDecoration => {
  const { backgroundColor, backgroundAlpha } = {
    backgroundColor: parseBackgroundDecoration(value),
    ...parseDecoString<'backgroundColor' | 'backgroundAlpha'>(
      value
        .replace(/([^-]|^)color/, (match) => match.replace('color', 'background-color'))
        .replace(/([^-]|^)alpha/, (match) => match.replace('alpha', 'background-alpha'))
    ).object,
  };
  return {
    ...CELL_DECORATION_DEFAULT,
    ...(isContentColor(backgroundColor) && { backgroundColor }),
    ...(backgroundAlpha && { backgroundAlpha: parseFloat(backgroundAlpha) }),
  };
};

const parseLineDecoration = (value: string): TableLineDecoration => ({
  ...LINE_DECORATION_DEFAULT,
  ...(isContentColor(value) ? { color: value } : {}),
});

const parsePadding = (value: string): Padding => {
  const p = value
    .replace('px', '')
    .split(' ')
    .map((v) => parseInt(v, 10));
  switch (p.length) {
    case 1:
      return { top: p[0], bottom: p[0], left: p[0], right: p[0] };
    case 2:
      return { top: p[0], bottom: p[0], left: p[1], right: p[1] };
    case 3:
      return { top: p[0], left: p[1], right: p[1], bottom: p[2] };
    case 4:
      return { top: p[0], right: p[1], bottom: p[2], left: p[3] };
    default:
      return { top: 0, bottom: 0, left: 0, right: 0 };
  }
};

const attributeImporter = switchMap<
  (
    s: string
  ) => undefined | string | number | number[] | Padding | CellDecoration | TableLineDecoration
>(
  {
    'cell-height': parseInt,
    'cell-width': parseInt,
    'inner-horizontal-line-width': parseInt,
    'inner-vertical-line-width': parseInt,
    'outer-line-width': parseInt,
    rowspan: parseInt,
    colspan: parseInt,
    'column-percents': parseColumnPercents,
    width: parseWidth,
    'background-decoration': parseBackgroundDecoration,
    'line-decoration': parseLineDecoration,
    padding: parsePadding,
    'post-decimals': parseInt,
  },
  identity
);

export const importCell = (element: FElement, context: ImporterContext): Cell => {
  // the decoration may appear in the 'decoration' or the 'background-decoration' attrib :(
  const decoString = element.hasAttribute('decoration')
    ? element.attribute('decoration')
    : element.hasAttribute('background-decoration')
    ? element.attribute('background-decoration')
    : '';

  const content =
    element.firstChild.localName === SEMANTICS
      ? context.importXML(element.firstChild)
      : // there might be <mtext> nodes in plain <mtables>, need to wrap them in formula (#41781)
        context.importXML(
          toXmlElement(
            semantics(
              `<mrow>${element.firstChild.toString()}</mrow>`,
              annotationInner(SPECIAL, { $renderStyle: RS.FORMULA })
            )
          )
        );
  const childContent = context.content.get(content.$refid) as Content;
  const childIsTable =
    childContent.$renderStyle === RS.SIMPLE_TABLE ||
    (isFormulaContent(childContent) &&
      isContentReference(childContent.content[0]) &&
      childContent.content.length === 1 &&
      (context.content.get(childContent.content[0].$refid) as Content).$renderStyle ===
        RS.SIMPLE_TABLE);
  return {
    ...CELL_DEFAULT,
    decoration: parseCellDecoration(decoString),
    ...(element.hasChildren() && { content, childIsTable }),
    ...CELL_ATTRIBUTES.reduce(
      (acc, k) => ({
        ...acc,
        ...(element.hasAttribute(k) && {
          [camelCase(k)]: attributeImporter(k)(element.attribute(k)),
        }),
      }),
      {}
    ),
  };
};

export const importSimpleTable: Importer<TableContent> = (preContent, xml, context) => {
  const table = xml.findChildTag('mtable');
  const attribs: Partial<TableContent> = TABLE_ATTRIBUTES.reduce(
    (acc, k) => ({
      ...acc,
      ...(table.hasAttribute(k) && {
        [camelCase(k)]: attributeImporter(k)(table.attribute(k)),
      }),
    }),
    {}
  );
  const isHScrollTable = attribs.style
    ? [
        TableStyle.DEFAULT,
        TableStyle.UNSET,
        TableStyle.LINEAR_EQN_SYSTEM,
        TableStyle.EQNARRAY,
        TableStyle.UNIT_TABLE,
        TableStyle.VALUE_TABLE,
        TableStyle.PLACE_VALUE,
      ].includes(attribs.style)
    : true;
  const childOfHScroll = context.inHScrollContainer;
  if (isHScrollTable) {
    context.inHScrollContainer = true;
  }

  const percentWidth =
    (attribs.columnPercents ? attribs.columnPercents.reduce((acc, v) => acc + v, 0) : 1) *
    (attribs.width ? attribs.width : 1);

  return {
    ...TABLE_DEFAULT_CONTENT,
    ...preContent,
    ...attribs,
    childOfHScroll,
    maxRowWidth: context.widthTransformation(
      attribs.style !== TableStyle.SIMPLE_TABLE ? MAX_ROW_WIDTH : MAX_ROW_WIDTH_SIMPLE
    ),
    maxRowWidthFlash: context.widthTransformation(FLASH_DEFAULT_MAX_WIDTH),
    percentWidth,
    rows: table.getChildrenByTagName('mtr').reduce(
      (acc, row) => [
        ...acc,
        {
          ...ROW_DEFAULT,
          cells: row.getChildrenByTagName('mtd').reduce((acc: Cell[], col) => {
            return [...acc, importCell(col, context)];
          }, []),
          ...(row.hasAttribute('background-decoration') && {
            backgroundDecoration: parseBackgroundDecoration(row.attribute('background-decoration')),
          }),
          ...(row.hasAttribute('class') && { class: row.attribute('class') }),
        },
      ],
      []
    ),
    // low-level heuristic to figure out if we need to enforce strict nowrap
    ...(table.toString().match(/<(m?:?mo|mtext)>[^<]*=/) && {
      withEqualSign: true,
    }),
  };
};

/**
 * Common padding properties shared among title and subtitle heading rows
 */
const headingRowPadding = {
  left: HEADER_ROW_PADDING_HORIZONTAL,
  right: HEADER_ROW_PADDING_HORIZONTAL,
};

/**
 * Apply custom style on the first title row:
 *
 *   - the Row does not contain subtitle in any cells (titleLines === 1) -> padding needs
 *     to be adapted, getting rid of the bottom border
 *    (to make it pretty since heading row has a grey background)
 *
 *   - the Row contains subtitle (titleLines === 2)
 *     on every cells (emptyFirstSubtitle is false) -> same as above bit with different
 *     padding rules
 *
 *   - the Row contains subtitle (titleLines === 2)
 *     EXCEPT the first title cell (emptyFirstSubtitle is true) -> same as above except
 *     the first heading cell is displayed on 2 rows
 */
const applyStyleOnTitleRow = (row: Row, emptyFirstSubtitle: boolean, titleLines: number): Row => {
  const cells = row.cells.map((cell, idx) => ({
    ...cell,
    ignoreCellSize: true,
    ...(emptyFirstSubtitle && idx === 0 && { rowspan: titleLines }),
    ...(idx !== 0 && { verticalAlign: VAlign.bottom }),
    padding: {
      ...headingRowPadding,
      top: HEADER_ROW_PADDING_VERTICAL,
      // Remove the bottom padding if there is subtitle on under this title cell,
      // taking care of specific case where the first title cell has a subtitle under
      bottom:
        titleLines === 2 && (idx !== 0 || (idx === 0 && !emptyFirstSubtitle))
          ? 0
          : HEADER_ROW_PADDING_VERTICAL,
    },
  }));
  return {
    ...row,
    borderBottom: null,
    cells,
  };
};

/**
 * Apply custom style on the subtitle row: the second title row:
 *
 *  - All heading cells have a subtitle -> adapt alignment, and remove top border to "merge"
 *    the subtitle and title cells.
 *
 *  - All heading cells have a subtitle EXCEPT the first one -> same as above but also
 *    remove the first empty cell since the title cell is displayed on 2 row span.
 *    @see `applyStyleOnTitleRow`
 */
const applyStylesOnSubtitleRow = (row: Row, emptyFirstSubtitle: boolean): Row => {
  const cells = row.cells.map((cell, idx) => ({
    ...cell,
    ignoreCellSize: true,
    ...(idx !== 0 && { verticalAlign: VAlign.top }),
    padding: {
      ...headingRowPadding,
      top: 0,
      bottom: HEADER_ROW_PADDING_VERTICAL,
    },
  }));
  return {
    ...row,
    borderTop: null,
    cells: emptyFirstSubtitle ? cells.slice(1, cells.length) : cells,
  };
};

/**
 * Extracts the value of the first subtitle table cell
 * from the root table place value XML
 */
const extractFirstSubtitle = (root: FElement): string =>
  root
    .findChildTag('mtable')
    .findChildTagWithAttribute('mtr', 'class', 'subtitle')
    .firstChild.findChildTag('semantics')
    .findChildTag('mrow')
    .findChildTag('mtext').text;

/**
 * Apply custom stylings on table cells and row headers:
 *
 *  - Table headers contains subtitles -> first 2 table row needs to be "merged" as one
 *  - Table headers does not have subtitle -> padding should be apply differently
 *  - Table headers contains subtitles EXPECT the first heading cell -> custom "merge" and padding
 *    must be apply
 */
const addPlaceValueStyles = (rows: Row[], xml: FElement): Row[] => {
  const isSubtitleRow = (row: Row) => row.class === 'subtitle';
  const isTitleRow = (row: Row) => row.class === 'title';
  // Since Number(true) === 1, we use this to counts number of row parts of the title.
  // Could be 0,1 or 2 depending whether title and subtitles are defined
  const titleRowCount = rows.reduce((acc, r) => Number(!isNil(r.class)) + acc, 0);
  const firstSubtitleIsEmpty = isEmpty(extractFirstSubtitle(xml));

  return rows.map((r, rIdx) => {
    let row = r;
    const originalCells = [...r.cells];
    const isHeadingRow = isTitleRow(r) || isSubtitleRow(r);

    if (isTitleRow(r)) row = applyStyleOnTitleRow(r, firstSubtitleIsEmpty, titleRowCount);
    if (isSubtitleRow(r)) row = applyStylesOnSubtitleRow(r, firstSubtitleIsEmpty);

    return {
      ...row,
      backgroundDecoration: isHeadingRow
        ? styles.HEADER_COLOR_DARK
        : rIdx % 2 === 0
        ? styles.ZEBRA_COLOR_LIGHT
        : styles.ZEBRA_COLOR_DARK,
      ...(isHeadingRow && { originalCells }),
    };
  });
};

export const importPlaceValueTable: Importer<TableContent> = (
  // the table is never interactive itself, so we drop the interaction type
  { $interactionType, ...preContent },
  xml,
  context
) => {
  const table = importSimpleTable(preContent, xml, context);
  const rows = addPlaceValueStyles(table.rows, xml);
  return {
    ...table,
    // this is wanted for overriding the $renderStyle and other props for place value table
    ...PLACE_VALUE_TABLE_DEFAULT_CONTENT,
    rows,
  };
};
