import { ensureFontsLoaded as ensureFontsLoadedUnstubable } from '../../utils/fontMetric';
import { importers } from '@bettermarks/importers';
import { enrichContentDict as enrichContentDictUnstubable } from '../../gizmo-utils/measure';
import { type BMAction } from '../../gizmo-utils/types';
import { compose } from 'lodash/fp';
import { call, put } from 'redux-saga/effects';
import { type ContentDict, toXmlElement as toXmlElementUnstubable } from '@bettermarks/gizmo-types';
import {
  importFEM as importFEMUnstubable,
  importKEM as importKEMUnstubable,
} from '../../xml-converter/EM';
import { handleFontLoadingErrors } from '../handleFontLoadingErrors';
import {
  fetchFEM as fetchFEMUnstubable,
  type FetchFEMParams,
  fetchKEM as fetchKEMUnstubable,
  type FetchKEMParams,
} from '../seriesplayer/services/api/content-manager';
import { isKEMParams } from '../seriesplayer/services/api/content-manager/em';
import { isFetchException } from '../seriesplayer/services/exception';
import { exceptionLog, ExceptionType } from '../seriesplayer/services/logging';
import {
  emLoadingStarted,
  femLoaded,
  handleError,
  kemLoaded,
  type QuitWhiteboardActionPayload,
} from './actions';
import { rulers, stylers } from './configuration';
import { EMKind } from './reducer';
import { withEmptyHistory } from '../store/helper';
import { reportViewedEvent as reportViewedEventUnstubable } from '../seriesplayer/services';
import { type Action } from 'redux-actions';
import { quitSeriesPlayer } from '../seriesplayer/containers/SeriesPlayer/quitSeriesPlayerSaga';

export const STUBABLE = {
  fetchFEM: fetchFEMUnstubable,
  fetchKEM: fetchKEMUnstubable,
  importFEM: importFEMUnstubable,
  importKEM: importKEMUnstubable,
  toXmlElement: toXmlElementUnstubable,
  enrichContentDict: enrichContentDictUnstubable,
  ensureFontsLoaded: ensureFontsLoadedUnstubable,
  quitWhiteboard: reportViewedEventUnstubable,
};

export function* loadEMSaga({ payload }: BMAction<FetchKEMParams | FetchFEMParams>): Generator {
  try {
    const isKEM = isKEMParams(payload);
    yield put(emLoadingStarted(isKEM ? EMKind.kem : EMKind.fem));
    const xml = isKEM
      ? yield call(STUBABLE.fetchKEM, payload)
      : yield call(STUBABLE.fetchFEM, payload);
    yield call(STUBABLE.ensureFontsLoaded, 5000, handleFontLoadingErrors);
    const contentDict = compose(
      (contentDict: ContentDict) =>
        withEmptyHistory(STUBABLE.enrichContentDict(contentDict, stylers, rulers)),
      isKEM
        ? (xml) => STUBABLE.importKEM(xml, importers)
        : (xml) => STUBABLE.importFEM(xml, importers),
      STUBABLE.toXmlElement
    )(xml as string);

    if (isKEM) {
      yield put(kemLoaded(contentDict));
    } else {
      yield put(femLoaded({ femId: payload.femId, contentDict }));
    }
  } catch (error) {
    const isKEM = isKEMParams(payload);
    isFetchException(error)
      ? exceptionLog(
          ExceptionType.api,
          error,
          {
            response: error.response,
            url: error.url,
            requestInit: error.requestInit,
            type: error.type,
          },
          'warn'
        )
      : exceptionLog(ExceptionType.saga, error);
    yield put(handleError(isKEM ? EMKind.kem : EMKind.fem));
  }
}

export function* quitWhiteboardPlayerSaga({ payload }: Action<QuitWhiteboardActionPayload>) {
  yield call(STUBABLE.quitWhiteboard, {
    viewedBody: payload.viewedBody,
    resultManagerUrl: payload.resultManagerUrl,
  });

  yield call(quitSeriesPlayer);
}
