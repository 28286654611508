import { ArrowDirection } from '../components/Arrow/types';
import * as RS from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';

export interface TimeDurationGizmoContent extends Content {
  /**
   * Arrow direction; either to the left or to the right
   */
  direction: ArrowDirection;

  /**
   * Reference of the hour input gizmo before the colon
   */
  hourRef: ContentReference;
  /**
   * Reference of the minute input gizmo after the colon
   */
  minuteRef: ContentReference;
}

export const TIME_DURATION_DEFAULT_CONTENT: Defaults<
  TimeDurationGizmoContent,
  'hourRef' | 'minuteRef'
> = {
  $: SPECIAL,
  $renderStyle: RS.TIME_DURATION,
  direction: ArrowDirection.right,
};
