import * as React from 'react';
import { computeRotationDegrees } from './handler';

type ClockProps = {
  /**
   * The hour to be shown on the clock (small arrow)
   */
  hour: number;

  /**
   *  The minute to be shown on the clock (big arrow)
   */
  minute: number;

  /**
   * Width of the svg clock
   */
  width: number;

  /**
   * Height of the svg clock
   */
  height: number;
};

/**
 * Large clock ticks coordinates. For every 5 minutes or each hour
 */
const largeTicks = `
  M62.25,77.44L59.25,72.24 M23.75,10.76L20.75,5.56 M77.44,62.25L72.244,59.25
  M10.76,23.75L5.56,20.75 M83,41.5L77,41.5 M6,41.5L0,41.5 M77.44,20.75L72.244,23.75
  M10.76,59.25L5.56,62.25 M62.25,5.561L59.25,10.75 M23.75,72.24L20.75,77.44
  M41.5,0L41.5,6 M41.5,77L41.5,83
`;

/**
 * Small clock ticks coordinates. For every minutes that are not multiple of 5.
 */
const smallTicks = `
  M45.86,82.77L45.44,78.79 M37.56,4.21L37.14,0.23
  M50.15,82.09L49.32,78.18 M33.68,4.83L32.85,0.91 M54.35,80.96L53.11,77.16 M29.89,5.84L28.65,2.04
  M58.4,79.4L56.77,75.75 M26.23,7.25L24.6,3.6 M65.91,75.06L63.56,71.83 M19.44,11.17L17.09,7.94
  M69.28,72.33L66.61,69.35 M16.39,13.65L13.72,10.68 M72.35,69.25L69.38,66.58
  M13.62,16.42L10.65,13.75 M75.08,65.88L71.85,63.53 M11.15,19.47L7.92,17.12
  M79.42,58.36L75.76,56.74 M7.24,26.26L3.58,24.64 M80.97,54.31L77.17,53.07 M5.83,29.93L2.03,28.69
  M82.1,50.11L78.18,49.28 M4.82,33.72L0.9,32.89 M82.77,45.82L78.8,45.41 M4.2,37.59L0.23,37.18
  M82.77,37.15L78.79,37.57 M4.21,45.43L0.23,45.85 M82.09,32.86L78.18,33.69 M4.82,49.31L0.91,50.14
  M80.97,28.67L77.16,29.9 M5.84,53.1L2.04,54.33 M79.41,24.61L75.75,26.24 M7.25,56.76L3.59,58.39
  M75.07,17.1L71.83,19.45 M11.17,63.55L7.93,65.9 M72.34,13.73L69.36,16.4 M13.64,66.6L10.66,69.27
  M69.26,10.66L66.59,13.63 M16.41,69.37L13.74,72.34 M65.89,7.92L63.54,11.16 M19.46,71.84L17.11,75.08
  M58.38,3.59L56.75,7.24 M26.25,75.76L24.62,79.41 M54.32,2.03L53.09,5.84 M29.91,77.17L28.68,80.97
  M50.13,0.91L49.29,4.82 M33.71,78.18L32.87,82.09 M45.84,0.23L45.42,4.21 M37.58,78.8L37.16,82.77`;

/**
 * The `Clock` component render an analogic clock for the given
 * hour and minutes.
 *
 * You can also set the width and height of the clock to grow or shrink the Clock.
 *
 * ### Properties
 * | Name   | Type      | Default   | Description
 * |---     |---        |---        |---
 * | `hour` | `number` [1 - 24] | - | The hour offset
 * | `minute` | `number`[1 - 60] | - | The minute offset
 * | `width` | `number` | - | The width of the Clock
 * | `height` | `number` | - | The height of the Clock
 */
export const Clock: React.FC<ClockProps> = ({ width, height, hour, minute }) => {
  const { hourDegree, minuteDegree } = computeRotationDegrees(hour, minute);
  return (
    <svg x="0" y="0" viewBox="0 0 83 83" {...{ width, height }}>
      <path fill="none" stroke="#333333" strokeMiterlimit="10" d={largeTicks} />
      <path fill="none" stroke="#AAAAAA" strokeMiterlimit="10" d={smallTicks} />
      <polygon
        style={{
          transform: `rotate(${minuteDegree}deg)`,
          transformOrigin: 'center',
        }}
        fill="#999999"
        points="43.5,41.5 39.5,41.5 41.5,6.671"
      />
      <path
        style={{
          transform: `rotate(${hourDegree}deg)`,
          transformOrigin: 'center',
        }}
        fill="#999999"
        d="M41.5,17.521l-2,24c0,1.104,0.896,2,2,2s2-0.896,2-2L41.5,17.521z"
      />
    </svg>
  );
};

export default Clock;
