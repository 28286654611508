import {
  type DayDurationGizmoContent,
  type FElement,
  type Importer,
  parseCommonAttribs,
  RS,
  SEMANTICS,
  SpanType,
  TIME_SPAN_DEFAULT_CONTENT,
  type TimeDurationGizmoContent,
  type TimeSpanContentReference,
  type TimeSpanGizmoContent,
} from '@bettermarks/gizmo-types';
import { importDayDuration } from '../day-duration/importer';
import { importTimeDuration } from '../time-duration/importer';

/**
 * Handler method to get all semantics element of mrow
 */
const semanticChildren = (element: FElement) =>
  element.findChildTag('mrow').filterChildren((child) => child.localName === SEMANTICS);

/**
 * Handler to check if element has an interaction type
 */
const isInteractive = (element: FElement) => !!parseCommonAttribs(element).$interactionType;

/**
 * Check whether a `clock` render-style gizmo is in children.
 */
const gizmoHasClock = (gizmo: FElement): boolean =>
  gizmo
    .findChildTag('mrow')
    .filterChildren((child) => child.localName === SEMANTICS)
    .some((timeInnerGizmo) => parseCommonAttribs(timeInnerGizmo).$renderStyle === RS.CLOCK);
/**
 *  Check if the gizmo has (at least one) interactive input
 */
const gizmoIsInteractive = (gizmo: FElement): boolean =>
  semanticChildren(gizmo).some((innerTextOrFieldset) => {
    // During round trip test, the XML exported after import
    // differs (time-clock becomes a fieldset).
    // So we need to check deeper if we are in this case.
    const interactiveFieldset = semanticChildren(innerTextOrFieldset).some(isInteractive);
    return interactiveFieldset || isInteractive(innerTextOrFieldset);
  });

export const importTimeSpan: Importer<TimeSpanGizmoContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const gizmos = mrow.filterChildren((child) => child.localName === SEMANTICS);
  // get first duration gizmo to determine the type of time span (day or time)
  const attr = parseCommonAttribs(gizmos[1]);
  const spanType = attr.$renderStyle === RS.DAY_DURATION ? SpanType.day : SpanType.time;

  let thirdDuration;
  if (gizmos.length === 6) {
    // Typescript infers it bad,
    // pop() will never return undefined as gizmos array contains 6 elements
    const durationSemantic = gizmos.pop() as FElement;
    // small handler to import duration with correct importer (day or time)
    const importDuration = <T extends DayDurationGizmoContent | TimeDurationGizmoContent>(
      importer: Importer<T>
    ) => importer(attr, durationSemantic, context);

    if (spanType === SpanType.day) {
      const { direction, labelRef } = importDuration(importDayDuration);
      thirdDuration = { direction, contentRefs: [labelRef] };
    } else {
      const { direction, hourRef, minuteRef } = importDuration(importTimeDuration);
      thirdDuration = { direction, contentRefs: [hourRef, minuteRef] };
    }
  }

  // add some extra information to the gizmo ref for time span rendering
  const contentRefs = gizmos.map<TimeSpanContentReference>((gizmo) => ({
    $refid: context.importXML(gizmo).$refid,
    withClock: gizmoHasClock(gizmo),
    isInteractive: gizmoIsInteractive(gizmo),
  }));

  return {
    ...preContent,
    ...TIME_SPAN_DEFAULT_CONTENT,
    contentRefs,
    spanType,
    ...(thirdDuration ? { thirdDuration } : {}),
  };
};
