import { Severity, ValidationType, ValidatorEngine } from '@bettermarks/umc-kotlin';
import { DEFAULT_CONTENT_ROUTE, DEFAULT_MEDIA_ROUTE } from '../../gizmo-utils/constants';
import {
  type AppExercise,
  type ApplicationState,
  type AppSettings,
  type AppStep,
  CollapsibleContentState,
  DEFAULT_FEATURES,
  DEFAULT_SERIES_SETTINGS,
  DEFAULT_TOOLBAR_SETTINGS,
  DialogType,
  type EMData,
  LightBoxType,
  LoaderState,
  ScreenLockType,
  SeriesMode,
  SeriesStatus,
} from '../../types';
import {
  getAvailableHeight,
  getAvailableWidth,
  isOnline,
  isTouchDevice,
} from '../seriesplayer/services';
import { createHashHistory, type History } from 'history';

export const initialRuntimeState = {
  availableWidth: getAvailableWidth(),
  availableHeight: getAvailableHeight(),
  isOnline: isOnline(),
  isTouch: isTouchDevice(),
};

const toRouterState = ({ action, location }: History) => ({
  action,
  location,
});

export const initialRouterState = toRouterState(createHashHistory());

export const emptyStep: Readonly<AppStep> = {
  answer: {},
  aborted: false,
  description: '',
  explanation: {},
  showKEM: true,
  showBetty: false,
  feedbacks: [],
  hints: [],
  id: 'step.id',
  instruction: {},
  knowledgeGaps: [],
  maxErrors: 2,
  mandatory: false,
  question: {
    present: {},
    past: [],
    future: [],
  },
  questionIsAnswer: false,
  solutionState: CollapsibleContentState.hidden,
  explanationState: CollapsibleContentState.hidden,
  supports: [],
  skill: '',
  title: '',
  type: '',
  answerXML: '',
  questionXML: '',
  validation: {
    includedGlobalFeedbacks: [],
    includedGlobalFeedbacksInTest: [],
    includedPlaceholderFeedbacks: {},
    excludedGlobalFeedbacks: [],
    excludedGlobalFeedbacksInTest: [],
    excludedPlaceholderFeedbacks: {},
    validationType: ValidationType.default,
    embedPlaceholders: undefined,
    conditionCorrectExpr: undefined,
    conditionValidExpr: undefined,
    conditionValidPatterns: undefined,
    conditionCorrectPatterns: undefined,
    code: undefined,
    feedbacks: [
      {
        key: '',
        localKey: '',
        ignoreInTest: true,
        textXML: '',
        severity: Severity.ok,
        code: '',
        condition: '',
        required: false,
        patterns: '',
        learningObjectiveId: undefined,
      },
    ],
  },
  validatorEngine: ValidatorEngine.mathcore,
  numberOfErrors: 0,
  startTime: -1,
  maxTries: 2,
};

export const emptyExercise: Readonly<AppExercise> = {
  id: '',
  locale: '',
  dependencies: {
    fems: [],
    kems: [],
    images: [],
    importedRenderStyles: [],
  },
  exerciseType: '',
  featuresTimestamp: '',
  setting: {},
  description: '',
  steps: [emptyStep],
  currentStepId: 'step.id',
  wrapupState: CollapsibleContentState.hidden,
  startTime: -1,
};

export const emptyEMData: Readonly<EMData> = {
  fems: {},
  kem: {},
};

// All initial state should be right next to reducer and should not be used anywhere except for
// specs.

export const DEFAULT_APP_SETTINGS: AppSettings = {
  contentManagerUrl: '//school.bettermarks.loc',
  embedded: false,
  logLevel: 'trace',
  resultManagerUrl: '//school.bettermarks.loc',
  staticServerUrl: DEFAULT_CONTENT_ROUTE,
  staticMediaUrl: DEFAULT_MEDIA_ROUTE,
  validatorUrls: {
    // Mathcore validator engine as a service (currently out of service)
    [ValidatorEngine.mathcore]:
      process.env.NX_MATHCORE_VALIDATOR_URL || 'https://vaas-dev.bettermarks.com/validation',
    // NaturalLanguageProcessing validator engine
    // set URL to
    //    http://localhost:8501/validate
    // if you want to run with local NLP-validator
    [ValidatorEngine.nlp]: 'https://nlp-validator.bettermarks.com/validate',
  },
};

export const emptySeries = {
  currentExerciseIndex: -1,
  exercises: [],
  id: '',
  seriesStatus: SeriesStatus.started,
  seriesSettings: DEFAULT_SERIES_SETTINGS,
  mode: SeriesMode.practice,
};

export const initialSeriesplayerState: Readonly<ApplicationState> = {
  appSettings: DEFAULT_APP_SETTINGS,
  features: DEFAULT_FEATURES,
  series: emptySeries,
  emData: emptyEMData,
  previousDialog: { type: DialogType.none },
  dialog: { type: DialogType.none },
  lightBoxType: LightBoxType.none,
  runtimeState: initialRuntimeState,
  loaderState: LoaderState.error,
  shortcuts: [],
  toolbar: DEFAULT_TOOLBAR_SETTINGS,
  screenLock: { type: ScreenLockType.none },
  interceptBrowserBackButton: false,
  validation: process.env.NX_VALIDATOR_TYPE || undefined,
};
