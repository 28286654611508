import * as React from 'react';

/**
 * React Hook for handling click events outside of a specified HTML element of generic type `RefElement`.
 * @template RefElement Type of the HTML element. This should extend from HTMLElement.
 *
 * @param {React.RefObject<RefElement>} ref A reference to the HTML element of type `RefElement`.
 * @param {() => void} handleEvent Callback function to be invoked when a click outside the referenced element is detected.
 *
 * @description
 * This hook sets up a 'mousedown' event listener on the document, which triggers the `handleEvent` callback function when a click is detected outside the referenced element.
 * The event listener is removed when the component unmounts.
 *
 * It's important to note that the `ref` should be attached to the desired element during its render.
 *
 * This hook is useful in scenarios such as closing a dropdown menu, modal, or popover when a user clicks outside of it.
 *
 * @example
 * const myRef = useRef<HTMLDivElement>(null);
 * const handleClose = () => {
 *   console.log("Clicked outside the element");
 * }
 * useClickedOutside(myRef, handleClose);
 *
 * // In your component render:
 * <div ref={myRef}>This is my element</div>
 *
 */
export const useClickedOutside = <RefElement extends HTMLElement>(
  ref: React.RefObject<RefElement>,
  handleEvent: () => void
) => {
  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      e.preventDefault();
      if (ref === null || ref.current === null) {
        return;
      }
      if (!ref.current.contains(e.target as Node)) {
        handleEvent();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleEvent]);
};
