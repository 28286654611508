import {
  annotationInner,
  type Arrow,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
  type TableContainerContent,
} from '@bettermarks/gizmo-types';
import { swapEastWest } from '../importer/importTableContainer';
import { exportTableContent } from './exporter';

const exportArrow = (a: Arrow, context: ExporterContext): string =>
  semantics(
    `
      <mrow>
        <configuration>
          <orientation>${swapEastWest(a.orientation)}</orientation>
          <from semantics-ref-id="${a.from.id}" arrowHead="${a.from.head}"/>
          <to semantics-ref-id="${a.to.id}" arrowHead="${a.to.head}"/>
        </configuration>
        ${exportContent(a.label.$refid, context)}
      </mrow>
    `,
    annotationInner(a.annotationInner.$, a.annotationInner)
  );

export const exportTableContainer: Exporter = (contentRef, context) => {
  const content = context.content[contentRef] as TableContainerContent;

  return semantics(
    `
      <mrow>
        ${content.arrows.map((a) => exportArrow(a, context))}
        ${exportTableContent(content.table, context)}
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
