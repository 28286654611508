import * as React from 'react';
import { Whiteboard } from '../whiteboard';
import { useReportHeightToWhiteboard } from '../whiteboard/useReportHeightToWhiteboard';
import ExerciseInExampleMode, {
  initialNavigationItem,
  isExampleNavigationItem,
} from './ExerciseInExampleMode';
import { getExampleModeIFrameUrl } from './helper';
import { ContentType } from '../../types';
import WhiteBackground from './WhiteBackground';
import styled from 'styled-components';
import { type ExampleNavigationItem } from './ExampleNavigation';
import { useLocation } from 'react-router-dom';

export interface contentSize {
  width: number;
  height: number;
}

const ExampleModeWrapper = styled.div`
  height: 100%;
  display: flex;
`;

export default function ExampleModeRoute() {
  const location = useLocation();

  const exerciseId = new URLSearchParams(location.search).get('exerciseid') ?? '';
  const forWhiteboard = Boolean(new URLSearchParams(location.search).get('forWhiteboard'));
  const staticUrl = new URLSearchParams(location.search).get('staticUrl') ?? '';
  const parsedSelectedNavigation =
    new URLSearchParams(location.search).get('selectedNavigation') ?? '';
  const selectedNavigationItem: ExampleNavigationItem = isExampleNavigationItem(
    parsedSelectedNavigation
  )
    ? parsedSelectedNavigation
    : initialNavigationItem;

  const iframeUrl = getExampleModeIFrameUrl(exerciseId, staticUrl);

  const { ref } = useReportHeightToWhiteboard({
    contentId: exerciseId ?? '',
    contentType: 'example',
    playerHPadding: 0,
    shouldReportHeight: true,
  });

  return forWhiteboard ? (
    <Whiteboard
      iframeUrl={iframeUrl}
      contentType={ContentType.EXERCISE}
      selectedNavigationItem={selectedNavigationItem}
    />
  ) : (
    <ExampleModeWrapper ref={ref}>
      <WhiteBackground />
      <ExerciseInExampleMode exerciseId={exerciseId} staticUrl={staticUrl} />
    </ExampleModeWrapper>
  );
}
