import * as React from 'react';
import styles from './ZoomButton.scss';
import { useTranslation } from 'react-i18next';
import { ExcludedFromTabNavigationButton } from '../../../components/ExcludedFromTabNavigation';

interface ZoomButtonProps {
  zoomHandle: () => void;
}

export const ZoomButton: React.FC<ZoomButtonProps> = ({ zoomHandle, children }) => {
  const [t] = useTranslation();
  return (
    <ExcludedFromTabNavigationButton className={styles.zoomButton} onClick={zoomHandle}>
      <span className={styles.zoomButtonText}>{t('editors:geo.zoom')}</span>
      <span className={styles.zoomButtonIcon}>{children}</span>
    </ExcludedFromTabNavigationButton>
  );
};

ZoomButton.displayName = 'ZoomButton';
