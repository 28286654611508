import * as React from 'react';
import { defaultTo } from 'lodash';
import { Button, ButtonBackground, ButtonKind, ButtonSize } from '@seriesplayer/common-ui';

import { Icon } from '../../../../components';
import { SeriesMode } from '../../../../types';

import { Donut } from '../../components/Donut';
import { type HeaderCommonProps, type HeaderProps } from './Header';
import styles from './header.scss';

export type HeaderSmallProps = Pick<
  HeaderProps,
  | 'closeButton'
  | 'currentExercise'
  | 'mode'
  | 'nextExercise'
  | 'previousExercise'
  | 'progress'
  | 'showExerciseNavigation'
  | 'reportProblemButton'
  | 'showReportProblem'
> &
  HeaderCommonProps;

export const HeaderSmall: React.FC<HeaderSmallProps> = ({
  children,
  closeButton,
  currentExercise,
  mode,
  nextExercise,
  onClose,
  previousExercise,
  progress,
  reportProblemButton,
  showExerciseNavigation,
  showReportProblem,
}) => (
  <div id="header" className={styles.exerciseHeader}>
    {mode !== SeriesMode.practice && (
      <Button
        kind={ButtonKind.view}
        background={ButtonBackground.transparent}
        onClick={currentExercise !== 1 && previousExercise}
      >
        <Icon iconKey="ChevronLeftMedium" />
      </Button>
    )}
    <div role="button" data-cy="ex-nav-btn" onClick={showExerciseNavigation}>
      <Donut
        current={defaultTo<number>(currentExercise, 1)}
        progress={defaultTo<number>(progress, 1)}
      />
    </div>
    {mode !== SeriesMode.practice && (
      <Button
        kind={ButtonKind.view}
        background={ButtonBackground.transparent}
        onClick={nextExercise}
      >
        <Icon iconKey="ChevronRightMedium" />
      </Button>
    )}
    <div className={styles.spacer} />
    {reportProblemButton && (
      <Button
        dataCy="report-problem-btn-small"
        kind={ButtonKind.view}
        size={ButtonSize.l}
        background={ButtonBackground.transparent}
        onClick={showReportProblem}
      >
        <Icon iconKey="ReportProblemXLarge" />
      </Button>
    )}
    {children}
    <span className={styles.headerButton}>
      {closeButton && (
        <Button
          kind={ButtonKind.view}
          size={ButtonSize.l}
          background={ButtonBackground.transparent}
          onClick={onClose}
          dataCy="close-btn-small"
        >
          <Icon iconKey="TimesLarge" />
        </Button>
      )}
    </span>
  </div>
);

HeaderSmall.displayName = 'HeaderSmall';
