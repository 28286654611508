import * as React from 'react';
import { RectangleShape } from './RectangleShape';

/**
 * props
 */
export interface SquareProps {
  color: string;
  scalefactor: number;
}

/**
 * The `SquareShape` component renders a square shape
 *
 * The actual implementation is in <RectangleShape> with the boolean `square` set to true.
 */
export const SquareShape: React.FC<SquareProps> = (props) => <RectangleShape square {...props} />;
SquareShape.displayName = 'SquareShape';
