import { add as add_, clamp, compact, curry, flatten, isNil, omit } from 'lodash';
import { type Action, handleActions } from 'redux-actions';
import {
  DEFAULT_FUNCTION_PLOTTER_CONTENT,
  type FunctionPlotterContent,
  PARAMETER_DEFAULTS,
} from '@bettermarks/gizmo-types';
import {
  CURSOR_LEFT,
  CURSOR_RIGHT,
  type FunctionPlotterActionPayload,
  NEXT_PARAM,
  PREV_PARAM,
  SELECT_PARAM,
  SET_PARAM,
  type SetParamPayload,
} from './actions';
import { setFunctionParameter } from './helpers';

const add = curry(add_);

const nextParam =
  (nextIdx: (idx: number) => number) =>
  (
    state: FunctionPlotterContent,
    _: Action<void>,
    pids = flatten(state.functions.map((f) => compact(f.parameters.map((p) => p.refId))))
  ): FunctionPlotterContent => ({
    ...state,
    selectedParameter: isNil(state.selectedParameter)
      ? pids[0]
      : pids[
          clamp(nextIdx(pids.findIndex((x) => x === state.selectedParameter)), 0, pids.length - 1)
        ],
  });

const nextValue =
  (mult: number) =>
  (state: FunctionPlotterContent, _: Action<void>): FunctionPlotterContent =>
    isNil(state.selectedParameter)
      ? state
      : {
          ...state,
          functions: state.functions.map((f) => ({
            ...f,
            parameters: f.parameters.map((p) =>
              p.refId === state.selectedParameter
                ? {
                    ...PARAMETER_DEFAULTS, // because omit returns a partial type
                    ...omit(p, ['severity']), // reset severity
                    value: clamp(add(p.value, p.step * mult), p.minimum, p.maximum),
                  }
                : p
            ),
          })),
        };

export const functionPlotterReducer = handleActions<
  FunctionPlotterContent,
  FunctionPlotterActionPayload
>(
  {
    [SET_PARAM]: (state, { payload }: Action<SetParamPayload>) =>
      payload
        ? {
            ...state,
            selectedParameter: payload.id,
            functions: setFunctionParameter(payload.id, payload.value, state.functions),
          }
        : state,
    [NEXT_PARAM]: nextParam(add(1)),
    [PREV_PARAM]: nextParam(add(-1)),
    [CURSOR_RIGHT]: nextValue(1),
    [CURSOR_LEFT]: nextValue(-1),
    [SELECT_PARAM]: (state, { payload }: Action<string>) => ({
      ...(omit(state, ['selectedParameter']) as FunctionPlotterContent),
      ...(payload && { selectedParameter: payload }),
    }),
  },
  DEFAULT_FUNCTION_PLOTTER_CONTENT
);
