import { FLASH_DEFAULT_MAX_WIDTH } from '../../gizmo-utils/measure/constants';
import {
  type Cell,
  type CellDecoration,
  type LineDecoration,
  type Padding,
  type Row,
  type TableContainerContent,
  type TableContent,
  TableStyle,
} from './types';
import {
  PLACE_VALUE_TABLE,
  SIMPLE_TABLE,
  SIMPLE_TABLE_CONTAINER,
} from '../../gizmo-utils/configuration/renderStyles';

import { ContentColor } from '../../styles/colors';
import { SPECIAL } from '../../gizmo-utils/constants';
import { HAlign, VAlign } from '../../gizmo-utils/types';
import { colors } from '@seriesplayer/common-ui';

export const MAX_ROW_WIDTH = 800;
// stop "simple-table" from breaking too early + minimize distance between item name and value
// -> smaller max width
export const MAX_ROW_WIDTH_SIMPLE = 500;

export const TABLE_ATTRIBUTES = [
  'background-decoration',
  'cell-height',
  'cell-width',
  'column-percents',
  'inner-horizontal-line-width',
  'inner-vertical-line-width',
  'line-decoration',
  'outer-line-width',
  'post-decimals',
  'reading-order',
  'style',
  'width',
];

export const ROW_ATTRIBUTES = ['background-decoration', 'class'];

export const CELL_ATTRIBUTES = [
  'colspan',
  'horizontal-align',
  'padding',
  'rowspan',
  'vertical-align',
];

export const CELL_DECORATION_DEFAULT: Readonly<CellDecoration> = {
  backgroundAlpha: 1,
};

export const LINE_DECORATION_DEFAULT: LineDecoration = {
  color: ContentColor.BM_TRANSPARENT,
};

export const PADDING_DEFAULT: Readonly<Padding> = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};

export const TABLE_DEFAULT_CONTENT: Readonly<TableContent> = {
  $: SPECIAL,
  $renderStyle: SIMPLE_TABLE,
  rows: [],
  style: TableStyle.UNSET,
  outerLineWidth: 0,
  innerHorizontalLineWidth: 5,
  innerVerticalLineWidth: 5,
  lineDecoration: LINE_DECORATION_DEFAULT,
  childOfHScroll: false,
  percentWidth: 1,
  maxRowWidth: MAX_ROW_WIDTH,
  maxRowWidthFlash: FLASH_DEFAULT_MAX_WIDTH,
};

export const PLACE_VALUE_TABLE_DEFAULT_CONTENT: Readonly<TableContent> = {
  $: SPECIAL,
  $renderStyle: PLACE_VALUE_TABLE,
  rows: [],
  style: TableStyle.PLACE_VALUE,
  outerLineWidth: 1,
  innerHorizontalLineWidth: 1,
  innerVerticalLineWidth: 1,
  // @ts-ignore
  lineDecoration: { color: colors.cGray500 },
  cellHeight: 50,
  cellWidth: 60,
  childOfHScroll: false,
  percentWidth: 1,
  maxRowWidth: MAX_ROW_WIDTH,
  maxRowWidthFlash: FLASH_DEFAULT_MAX_WIDTH,
};

export const ROW_DEFAULT: Readonly<Row> = {
  cells: [],
};

export const CELL_DEFAULT: Readonly<Cell> = {
  decoration: CELL_DECORATION_DEFAULT,
  colspan: 1,
  horizontalAlign: HAlign.center,
  rowspan: 1,
  verticalAlign: VAlign.middle,
  ignoreCellSize: false,
  childIsTable: false,
};

export const HEADER_ROW_PADDING_HORIZONTAL = 3;
export const HEADER_ROW_PADDING_VERTICAL = 5;

export const TABLE_CONTAINER_DEFAULT_CONTENT: TableContainerContent = {
  $: SPECIAL,
  $renderStyle: SIMPLE_TABLE_CONTAINER,
  arrows: [],
  table: TABLE_DEFAULT_CONTENT,
};
