import * as React from 'react';
import classNames from 'classnames';
import { CircleMedium, CircleOMediumBold } from '../icons/index';

import styles from './styles.scss';
import { type BaseButton, OptionState } from './types';

/**
 * props
 */
export interface RadioButtonProps extends BaseButton {
  collapsedView: boolean | undefined;
}

/**
 * The `RadioButton` is a component for multiple choice single input options.
 *
 * ### Process State
 *
 * - Visuals: Working image tests
 * - Behaviour: None
 *
 * ### Properties
 | Name            | Type                                  | Default   | Description               |
 |---              |---                                    |---        |---                        |
 | `state`         | one of                                | Required  | interaction states        |
 |                 | `['selected', 'unselected', 'error']` |           | the choice can have       |
 | `interactive`   | `boolean`                             | Required  | mc option interactive for |
 |                 |                                       |           | user?                     |
 | `collapsedView` | `boolean`                             | Required  | mc in collapsed view?     |
 */
const RadioButton: React.FC<RadioButtonProps> = ({ state, interactive, collapsedView }) => {
  const style = classNames(styles[state], {
    [styles.disabled]: !interactive,
    [styles.collapsed]: collapsedView,
  });

  return state === OptionState.unselected ? (
    <CircleOMediumBold className={style} />
  ) : (
    <CircleMedium className={style} />
  );
};

RadioButton.displayName = 'RadioButton';

export default RadioButton;
