import { type Content } from '../../xml-converter/core';

export const enum BalanceValue {
  BALANCED = 'balanced',
  LEFT_DOWN = 'leftDown',
  RIGHT_DOWN = 'rightDown',
}

interface Balance extends Readonly<Content> {
  balance: BalanceValue;
  leftOneBullets?: number;
  leftXBullets?: number;
  rightOneBullets?: number;
  rightXBullets?: number;
}

export type BalanceContent = Readonly<Balance>;

export type Bullet = Readonly<{
  color: string;
  label?: string;
}>;
