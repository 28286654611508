import { defaultTo } from 'lodash';
import {
  $MROW,
  CONFIGURATION,
  DECORATION,
  type FElement,
  type Importer,
  SEMANTICS,
  SET_DROP_TARGET_DEFAULT_CONTENT,
  type SetDropTargetContent,
  DnDVerticalAlignment,
  xmlTextToFloat,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import { dragSourceConfig, importDragSourceId } from '../dragsource/importer';

const importChild = (element: FElement) => {
  const { severity } = parseDecoString(element.findChildTag($MROW).attribute(DECORATION));
  const dragSourceId = importDragSourceId(dragSourceConfig(element));

  return {
    ...(severity && { severity }),
    ...dragSourceId,
  };
};

const toVerticalAlign = (v: FElement) =>
  defaultTo(
    [DnDVerticalAlignment.top, DnDVerticalAlignment.bottom, DnDVerticalAlignment.middle].find(
      (s) => s === v.text.trim()
    ),
    DnDVerticalAlignment.top
  );

export const importSetDropTarget: Importer<SetDropTargetContent> = (preContent, xml, _) => {
  const mrow = xml.findChildTag($MROW);
  const configuration = mrow.findChildTag(CONFIGURATION);

  const {
    severity,
    object: { borderColor, backgroundAlpha },
  } = parseDecoString<'borderColor' | 'backgroundAlpha'>(mrow.attribute(DECORATION));
  const items = mrow.findChildTag('set').getChildrenByTagName(SEMANTICS).map(importChild);

  return {
    ...SET_DROP_TARGET_DEFAULT_CONTENT,
    ...preContent,
    ...configuration.tagsToProps<SetDropTargetContent, number>(
      xmlTextToInt,
      ['percentWidth'],
      ['minHeight', 'width', 'height', 'fixedWidth', 'targetGroup']
    ),
    ...configuration.tagsToProps(toVerticalAlign, [], ['verticalItemAlign']),
    ...configuration.tagsToProps(xmlTextToFloat, [], ['widthAsFactorOfMaxItemWidth']),
    ...(backgroundAlpha && { backgroundAlpha: parseFloat(backgroundAlpha) }),
    ...(borderColor && { borderColor }),
    ...(severity && { severity }),
    items,
  };
};
