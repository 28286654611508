import {
  annotationInner,
  type BallotBoxContent,
  type BallotBoxItem as Item,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';

/**
 * It accepts an element and returns xml for individual items
 * @param el
 */
const exportBallotBoxItem = (el: Item) => `
    <item>
      <color>${el.color}</color>
      ${el.label ? `<label>${el.label}</label>` : ''}
    </item>`;

/**
 * It exports the Ballot box Content to XML.
 * @param contentRefId
 * @param context
 */
export const exportBallotBox: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as BallotBoxContent;
  const contentXML = content.items.map(exportBallotBoxItem).join('');
  const configurationXML = `
    <showBackground>${content.showBackground ? 'true' : 'false'}</showBackground>
  `;

  return semantics(
    `
      <mrow>
        <configuration>
          ${configurationXML}
        </configuration>
        <set>
          ${contentXML}
        </set>
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
