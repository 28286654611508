import {
  type ClockGizmoContent,
  DEFAULT_CLOCK_GIZMO_CONTENT,
  type Importer,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';

export const importClock: Importer<ClockGizmoContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const [hour, minute] = mrow.getChildrenByTagName('mn').map(xmlTextToInt);

  return {
    ...DEFAULT_CLOCK_GIZMO_CONTENT,
    ...preContent,
    hour,
    minute,
  };
};
