import { type ContentAlign, type PaddingProps, vAlign, hAlign } from './imageDimensions';

export const padding = (vertical: number, horizontal: number, align: ContentAlign = {}) => {
  const result: PaddingProps = {};
  if (vertical > 0) {
    const { top, bottom } = vAlign(align.vAlign);
    if (top > 0) result.paddingTop = vertical * top;
    if (bottom > 0) result.paddingBottom = vertical * bottom;
  }
  if (horizontal > 0) {
    const { left, right } = hAlign(align.hAlign);
    if (right > 0) result.paddingRight = horizontal * right;
    if (left > 0) result.paddingLeft = horizontal * left;
  }
  return result;
};
