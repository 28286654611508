import * as React from 'react';
import classNames from 'classnames';
import { isNil, defaultTo } from 'lodash';
import { ChevronDownSmall } from '../icons';

import styles from './DropdownButton.scss';

export interface DropdownButtonProps {
  item?: JSX.Element;
  minWidth?: number;
  height?: number;
  open: boolean;
  interactive: boolean;
  lineBreak?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onOpenMenu?: React.MouseEventHandler<{}>;
  itemState: string;
  dataCy?: string;
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  open,
  itemState,
  interactive,
  height,
  minWidth,
  item,
  lineBreak,
  onOpenMenu,
  children,
  dataCy,
}) => {
  const buttonClassName = classNames(
    styles.button,
    styles[itemState || 'unselected'],
    open ? styles.open : styles.closed,
    {
      [styles.disabled]: !(open || interactive),
    }
  );

  const angleClassName = classNames(
    styles.arrow,
    styles[defaultTo<string>(itemState, 'unselected')],
    { [styles.disabled]: itemState === 'unselected' && !interactive }
  );

  const childrenClassName = classNames(
    styles.buttonContent,
    isNil(item) ? styles.selected : styles.unselected
  );

  const itemsClassName = classNames(
    styles.buttonContent,
    isNil(item) ? styles.unselected : styles.selected
  );

  const dropdownContentClassName = classNames(styles.buttonDropdownContent, {
    [styles.nowrap]: !lineBreak,
  });

  return (
    <div
      className={buttonClassName}
      role="menu"
      style={{ height }}
      onClick={onOpenMenu}
      data-cy={dataCy}
    >
      <div style={{ minWidth }}>
        <div className={dropdownContentClassName}>
          <div className={childrenClassName}>{children}</div>
          <div className={itemsClassName} data-cy="drop-down-content">
            {item}
          </div>
        </div>
      </div>
      <ChevronDownSmall className={angleClassName} />
    </div>
  );
};

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
