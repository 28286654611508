import * as React from 'react';
import { useContentTranslation } from '../../../../gizmo-utils/polymorphic-gizmo';

import styles from './PdfReviewOverview.scss';

export type PdfReviewOverviewProps = {
  score: string;
  handoverDate: string;
};

export const PdfReviewOverview: React.FC<PdfReviewOverviewProps> = ({ score, handoverDate }) => {
  const t = useContentTranslation();

  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <th>{t('seriesplayer:pdfReviewOverview.score')}:</th>
          <td>{score}</td>
        </tr>
        <tr>
          <th>{t('seriesplayer:pdfReviewOverview.date')}:</th>
          <td>{handoverDate}</td>
        </tr>
      </tbody>
    </table>
  );
};
