import log from 'loglevel';
import { type ExporterContext } from '../core/exporter';
import { exportUnknown } from '../../gizmos/unknown/exporter';

/**
 * Exports content with contentRefId from current content dict to xml-string.
 * Currently this function can return an emtpy string in rare cases. As empty strings
 * can lead to invalid mathML (for example msup always expects two children, returning
 * and empty string for the base will lead to an msup with one child) this should be
 * rethought.
 *
 * @param contentRefId
 * @param context
 */
export function exportContent(contentRefId: string, context: ExporterContext): string {
  const currentContent = context.content[contentRefId];

  if (!currentContent) {
    log.warn({
      message: 'Missing content in content map!',
      extra: { contentRefId },
    });
    return ''; // TODO: try to return empty mrow here - causes failing test
  }

  // Remove hidden content (unsupported by free-betty) when exporting
  if (currentContent.hidden) {
    return '';
  }

  let exporter = context.exporters[currentContent.$renderStyle];

  if (!exporter) {
    exporter = exportUnknown;
    log.warn({
      message: 'Missing exporter for render style',
      extra: { renderStyle: currentContent.$renderStyle },
    });
  }

  return exporter(contentRefId, context).trim();
}
