import React from 'react';
import { Icon, type IconKey } from '../../components/index';

export type TextWithIconProps = {
  text: string | React.ReactElement;
  icon: { key: IconKey; element?: undefined } | { element: JSX.Element; key?: undefined };
  onClick?: () => void;
  style?: React.CSSProperties;
  color?: string;
};

export default function TextWithIcon({ text, icon, onClick, style, color }: TextWithIconProps) {
  const defaultStyles = {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5rem',
    cursor: onClick ? 'pointer' : undefined,
  };
  const mergedStyle: React.CSSProperties = { ...defaultStyles, ...style };

  const iconElement = icon.key ? <Icon iconKey={icon.key} fill={color} /> : icon.element;

  return (
    <div style={mergedStyle} onClick={onClick}>
      {iconElement}
      <span>{text}</span>
    </div>
  );
}
