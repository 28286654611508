import { parse } from 'query-string';

import { type PdfReviewSeriesParams } from '../../../../types';

import { type PdfReviewOverviewProps } from '../../components/PdfReviewOverview/PdfReviewOverview';

export type QueryParams = PdfReviewOverviewProps & PdfReviewSeriesParams;
export const parseSeriesPlayerPdfReviewQueryString = (queryString: string): QueryParams => {
  const parsedQuery = parse(queryString);
  const requiredKeys: ReadonlyArray<keyof QueryParams> = [
    'score',
    'handoverDate',
    'seriesId',
    'studentId',
    'system',
    'token',
  ];
  requiredKeys.forEach((key) => {
    if (!(key in parsedQuery)) {
      throw new Error(`missing expected queryString key: "${key}"`);
    }
  });
  return parsedQuery as QueryParams;
};
