import { flattenDeep } from 'lodash/fp';
import {
  type ApplyStyles,
  type GizmoStyle,
  type GizmoStyleForRefId,
} from '../../gizmo-utils/configuration';
import { type ContentReference } from '@bettermarks/gizmo-types';
import { type CRIContent } from '../../types/CRI';

const gizmoStyle = (gizmoStyle: GizmoStyle, contentRef: ContentReference): GizmoStyleForRefId => ({
  refId: contentRef.$refid,
  style: gizmoStyle,
});

export const applyCRIStyles: ApplyStyles = (
  { setting, canvasConfiguration, studentTasks }: CRIContent,
  style
) => {
  return flattenDeep<GizmoStyleForRefId>([
    setting ? gizmoStyle(style, setting) : [],
    ...[gizmoStyle(style, canvasConfiguration)],
    ...studentTasks.map((task) => gizmoStyle(style, task)),
  ]);
};
