import { type Importer, type IncludeGizmoContent, toBoolean } from '@bettermarks/gizmo-types';

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @returns The metadata and parsed xml as `Content`
 */
export const importIncludeGizmo: Importer<IncludeGizmoContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const html = mrow.findChildTag('content').attribute('html');
  const withReset = toBoolean(mrow.findChildTag('withReset').text);
  const width = mrow.hasChild('width') ? parseInt(mrow.findChildTag('width').text, 10) : undefined;
  const height = mrow.hasChild('height')
    ? parseInt(mrow.findChildTag('height').text, 10)
    : undefined;

  return {
    ...preContent,
    htmlPathname: html,
    withReset,
    width,
    height,
  };
};
