import * as React from 'react';
import {
  createAction,
  type Action,
  type ActionMeta,
  type ReduxCompatibleReducer,
} from 'redux-actions';
import { isEqual } from 'lodash';
import {
  type DevStore,
  type DevToolsExtension,
} from '../../../../../types/declarations/devToolsExtension';

export type Dispatch<TPayLoad> = (action: Action<TPayLoad>) => void;
export type RenderWithLocalRedux<TStore, TPayLoad> = (
  state: TStore,
  dispatch: Dispatch<TPayLoad>
) => JSX.Element;

export type WithLocalReduxProps<TStore, TPayLoad> = {
  store: TStore;
  reducer: ReduxCompatibleReducer<TStore, TPayLoad>;
  componentName: string;
  children: RenderWithLocalRedux<TStore, TPayLoad>;
};

type WithLocalReduxDevProps<TStore, TPayLoad> = WithLocalReduxProps<TStore, TPayLoad> & {
  devTools: DevToolsExtension;
};

const FLUSH_STORE = '@@FLUSH_STORE';
export const flushStore = createAction(FLUSH_STORE);

/**
 * Production Mode:
 * With the props only state and children are defined and dispatch function is
 * defined to dispatch actions to reducer.
 */
export class WithLocalReduxProd<
  TStore,
  TPayLoad,
  TProps extends WithLocalReduxProps<TStore, TPayLoad>
> extends React.Component<TProps, TStore> {
  state = this.props.store;

  /**
   * This reducer wraps the reducer that was passed in and adds the
   * handling of the `FLUSH_STORE` action, used to handle the case when a
   * new store is passed by props.
   */
  protected reducer(state: TStore, action: Action<TPayLoad | TStore>): TStore {
    // Handle the FLUSH_STORE action (used when new props come in).
    if (action.type === FLUSH_STORE) return action.payload as TStore;

    return this.props.reducer(state, action as Action<TPayLoad>);
  }

  protected dispatch = (action: Action<TPayLoad>): void => {
    this.setState((prevState) =>
      this.reducer(prevState, localAction(action, this.props.componentName))
    );
  };

  public componentDidUpdate(prevProps: TProps): void {
    /*
     * In case a new local store is passed as a prop, flush the reset the local state, but only
     * for the keys that come from this.props.store. State keys that are only local (and undefined
     * in the store) are not flushed.
     *
     * We have to use deep equality, because this doesn't work with the label tool of Geo.
     * It seems that it creates new nested objects and arrays inside the store.
     * It is not clear why this problem was introduced with the new architecture of connectGizmo.
     * This is a @TODO: adapt the Geo label tool to work with shallowEqual
     */
    if (!isEqual(prevProps.store, this.props.store)) {
      this.dispatch(flushStore({ ...this.state, ...this.props.store }));
    }
  }

  public render(): JSX.Element {
    const { children: render } = this.props as TProps;
    return render(this.state, this.dispatch);
  }
}

/**
 * Development Mode:
 * With the props only state and children are defined.
 * Additionally, if the app is tested with the redux devtools installed,
 * this component will be connected to redux-dev-tool store.
 * Also, it subscribes to the store to dispatch actions and listen to state changes.
 * This enables us to track actions in browser through `redux-dev-tools`.
 *
 * The store will be given then name that was passed in the prop 'componentName'.
 */
export class WithLocalReduxDev<TStore, TPayLoad> extends WithLocalReduxProd<
  TStore,
  TPayLoad,
  WithLocalReduxDevProps<TStore, TPayLoad>
> {
  private readonly devStore: DevStore<TStore, TPayLoad> = this.props.devTools(
    this.reducer.bind(this),
    this.props.store,
    { name: this.props.componentName }
  );

  private unsubscribe: () => void;

  public UNSAFE_componentWillMount(): void {
    this.unsubscribe = this.devStore.subscribe(() => this.setState(this.devStore.getState()));
  }

  public componentWillUnmount(): void {
    if (this.unsubscribe) this.unsubscribe();
  }

  protected dispatch = (action: Action<TPayLoad>): void => {
    this.devStore.dispatch(localAction(action, this.props.componentName));
  };
}

// needs to be a const that is not exported, to make tree shaking work.
// for testing we replicated the condition in './WithLocalRedux.spec.tsx'
// KEEP IN SYNC with DEV_TOOLS in './WithLocalRedux.spec.tsx' !!!
const DEV_TOOLS: undefined | DevToolsExtension =
  process.env.NODE_ENV !== 'production' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__
    : undefined;

interface WithLocalRedux {
  <TStore extends Readonly<object>, TPayLoad>(
    props: WithLocalReduxProps<TStore, TPayLoad>
  ): JSX.Element;
  /** Name of the component in the React Dev tools */
  displayName?: string;
}
type MakeWithLocalRedux = (devTools: undefined | DevToolsExtension) => WithLocalRedux;

// todo: revamp Revamp WithLocalRedux DevTools integration (https://bettermarks.atlassian.net/browse/BM-56322)
export const makeWithLocalRedux: MakeWithLocalRedux = () => (props) =>
  <WithLocalReduxProd {...props} />;

/**
 * This component wraps the logic of local component state and provides a redux-like API.
 * This component makes use of this React Design Pattern: `Render-prop`
 * It gets following props:
 * `store`: The initial state of the local `store`
 * `reducer`: Reducer function created with `redux-actions` to update local state.
 * `componentName`: identifier for devStore and added to each `action.meta.component`.
 * `children`: of type `RenderWithLocalRedux`, a function that is called on each state change.
 *
 * Example Usage:
 * @example
 * ```typescript
 *  const MyComponent: React.FC<MYProps> = (props) => (
 *    <WithLocalRedux
 *      store={{mode: props.initialMode}}
 *      reducer={myReducer}
 *      componentName="Component"
 *    >
 *      {(state, dispatch) => {
 *        const onClick = (): void =>
 *          dispastch({
 *            type: 'CHANGE_MODE',
 *            payload: {currentMode: state.mode}
 *          });
 *        return (
 *          <div role="button" onClick={onClick}>
 *            current mode is {state.mode}
 *          </div>
 *        );
 *      }}
 *    </WithLocalRedux>
 *  );
 *```
 *
 * @param {WithLocalReduxProps<TStore, TPayLoad>} props
 * @returns {JSX.Element}
 */
export const WithLocalRedux = makeWithLocalRedux(DEV_TOOLS);
WithLocalRedux.displayName = 'WithLocalRedux';

type LocalAction<P, M> = ActionMeta<P, M & { component: string }>;

/**
 * Adds the meta data to Action
 *
 * @param {Action<P>} action
 * @param {string} component identifier
 * @returns {LocalAction<P>}
 */
function localAction<P, M>(
  action: ActionMeta<P, M> | Action<P>,
  component: string
): LocalAction<P, M> {
  return {
    ...action,
    meta: { ...(('meta' in action && action.meta) as M), component },
  };
}
