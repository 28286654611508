import { identity, pickBy } from 'lodash/fp';
import {
  type BubbleNode,
  type GizmoNode,
  type Graph,
  GRAPH_GIZMO_DEFAULT_CONTENT,
  type GraphEdge,
  type GraphNode,
  type LayoutTreeNode,
  NodeType,
  TreeChartDirection,
} from '@bettermarks/gizmo-types';
import { treeClone } from './layoutTree';
import { DEPTH_GAP, MARGIN, SIBLING_GAP, SUBTREE_GAP } from './constants';

// some helper object to remember the max values in 'depth dimension' per depth 'level'
type DepthMax = {
  [key: number]: {
    size: number;
    weight: number;
  };
};

/**
 * gets the 'size' in a specified 'direction' of a LayoutTreeNode
 *
 * @param v ... the tree node
 * @param dir ... the 'direction' of the tree
 * @param breadth ... dimension for 'breadth' dimension (true) or 'depth' (false)
 */
export const size = (v: LayoutTreeNode, dir: TreeChartDirection, breadth = true): number =>
  dir === TreeChartDirection.vertical
    ? breadth
      ? v.size.width
      : v.size.height
    : breadth
    ? v.size.height
    : v.size.width;

/**
 * some separation heuristics for siblings and subtrees in 'breadth dimension'
 * @param v ... first node
 * @param w ... second node
 */
export const separation = (v: LayoutTreeNode, w: LayoutTreeNode): number => {
  return v.parent && w.parent && v.parent.children.length === 1 && w.parent.children.length
    ? SIBLING_GAP
    : v.parent === w.parent
    ? SIBLING_GAP
    : SUBTREE_GAP;
};

/**
 * gets the left sibling of a node
 *
 * @param v ... the node
 */
export const leftSibling = (v: LayoutTreeNode): LayoutTreeNode | undefined =>
  v.parent ? v.parent.children[v.indexB - 1] : undefined;

/**
 * gets the leftmost sibling of a node
 *
 * @param v ... the node
 */
export const leftmostSibling = (v: LayoutTreeNode): LayoutTreeNode | undefined =>
  v.parent && v.indexB > 0 ? v.parent.children[0] : undefined;

/**
 * helper used to traverse the left contour of a subtree. For further
 * details, refer to http://dirk.jivas.de/papers/buchheim02improving.pdf
 *
 * @param v ... the (sub)tree
 */
export const nextLeft = (v: LayoutTreeNode | undefined): LayoutTreeNode | undefined =>
  (v && v.children[0]) || (v && v.thread) || undefined;

/**
 * helper used to traverse the right contour of a subtree. For further
 * details, refer to http://dirk.jivas.de/papers/buchheim02improving.pdf
 *
 * @param v ... the (sub)tree
 */
export const nextRight = (v: LayoutTreeNode | undefined): LayoutTreeNode | undefined =>
  (v && v.children[v.children.length - 1]) || (v && v.thread) || undefined;

/**
 * helper used to shift two given subtrees by a given amount. For further
 * details, refer to http://dirk.jivas.de/papers/buchheim02improving.pdf
 * ATTENTION: mutates the input tree!
 *
 * @param wm ... the left (sub)tree
 * @param wp ... the right (sub)tree
 * @param shift ... the value to shift the (sub)tree
 */
export const moveSubtree = (wm: LayoutTreeNode, wp: LayoutTreeNode, shift: number): void => {
  const change = shift / (wp.indexB - wm.indexB);
  wp.change -= change;
  wp.shift += shift;
  wm.change += change;
  wp.preB += shift;
  wp.modB += shift;
};

/**
 * performs a shift of a node and it's children
 * ATTENTION: mutates the input tree!
 *
 * @param v ... the (sub)tree
 */
export const executeShifts = (v: LayoutTreeNode): void => {
  let shift = 0,
    change = 0;
  v.children.reverse();
  v.children.map((w) => {
    w.preB += shift;
    w.modB += shift;
    change += w.change;
    shift += w.shift + change;
    return w;
  });
  v.children.reverse();
};

/**
 * helper to gets an 'ancestor' of a given node, that is 'greatest
 * uncommon ancestor' of a node and it's right neighbor.
 *
 * @param vim ... the right neighbor node
 * @param v ... the node itself
 * @param fallback ... the fallback ancestor
 */
export const ancestor = (
  vim: LayoutTreeNode | undefined,
  v: LayoutTreeNode,
  fallback: LayoutTreeNode
): LayoutTreeNode =>
  vim && vim.ancestor && vim.ancestor.parent === v.parent ? vim.ancestor : fallback;

/**
 * performs an 'apportion' within Buchheims improved Walkers algorithm
 * (Buchheim et al.). For further details, refer to
 * http://dirk.jivas.de/papers/buchheim02improving.pdf
 * ATTENTION: mutates the input tree!
 *
 * @param v
 * @param defaultAncestor
 * @param dir ... the layout direction
 */
export const apportion = (
  v: LayoutTreeNode,
  defaultAncestor: LayoutTreeNode,
  dir: TreeChartDirection
): LayoutTreeNode => {
  const w = leftSibling(v);
  let da = defaultAncestor;
  if (w) {
    let vip: LayoutTreeNode | undefined = v;
    let vop: LayoutTreeNode | undefined = v;
    let vim: LayoutTreeNode | undefined = w;
    let vom = leftmostSibling(v);
    let sip = v.modB;
    let sop = v.modB;
    let sim = vim.modB;
    let som = vom ? vom.modB : 0;
    let nm = nextRight(vim);
    let np = nextLeft(vip);
    while (nm && np) {
      vim = nm;
      vip = np;
      vom = nextLeft(vom);
      vop = nextRight(vop);
      if (vop) {
        vop.ancestor = v;
      }
      const distance = 0.5 * (size(vim, dir) + size(vip, dir)) + separation(vim, vip);
      const shift = vim.preB + sim - vip.preB - sip + distance;
      if (shift > 0) {
        moveSubtree(ancestor(vim, v, da), v, shift);
        sip += shift;
        sop += shift;
      }
      sim += vim.modB;
      sip += vip.modB;
      som += vom ? vom.modB : 0;
      sop += vop ? vop.modB : 0;
      nm = nextRight(vim);
      np = nextLeft(vip);
    }
    if (nm && vop && !nextRight(vop)) {
      vop.thread = nm;
      vop.modB += sim - sop;
    }
    if (np && vom && !nextLeft(vom)) {
      vom.thread = np;
      vom.modB += sip - som;
      da = v;
    }
  }
  return da;
};

/**
 * performs a first walk of an improved Walkers algorithm (Buchheim et al.).
 * The tree is walked via post order traversal (bottom-up). Relative positions
 * are calculated during this step. For further details, refer to
 * http://dirk.jivas.de/papers/buchheim02improving.pdf
 * ATTENTION: mutates the input tree!
 *
 * @param v ... out tree node
 * @param dir ... the layout direction
 * @param dmax ... a dictionary memoizing the max sizes per level of the depth dim
 */
export const firstWalk = (
  v: LayoutTreeNode,
  dir: TreeChartDirection,
  dmax: DepthMax
): [LayoutTreeNode, DepthMax] => {
  // we need that to remember the max depth sizes for the children ...
  let dmaxChildren;
  const left = leftSibling(v);

  // the distance for the 'breadth dimension'
  const distB = left ? 0.5 * (size(left, dir) + size(v, dir)) + separation(left, v) : 0;
  if (v.children.length === 0) {
    v.preB = left ? left.preB + distB : 0;
  } else {
    let a = v.children[0]; // a is the 'default ancestor'
    dmaxChildren = v.children.reduce(
      (acc, c) => {
        const fw = firstWalk(c, dir, acc);
        a = apportion(c, a, dir);
        return fw[1];
      },
      { ...dmax }
    );

    executeShifts(v);
    const mid = (v.children[0].preB + v.children[v.children.length - 1].preB) / 2;
    if (left) {
      v.preB = left.preB + distB;
      v.modB = v.preB - mid;
    } else {
      v.preB = mid;
    }
  }
  return [
    v,
    {
      ...dmax,
      ...dmaxChildren,
      // the size for the 'depth dimension' is written to the depthMinMax map!
      [v.indexD]: {
        size: Math.max(size(v, dir, false), (dmax[v.indexD] && dmax[v.indexD].size) || -Infinity),
        weight: v.weightD,
      },
    },
  ];
};

/**
 * walking a given LayoutTree a second time in order to apply additional
 * positioning information. Already calculated 'mod' values are inherited
 * to a nodes children. This is done via pre order traversal (top-down).
 * Second part of the improved Walkers Algorithm. For further details, refer
 * to  http://dirk.jivas.de/papers/buchheim02improving.pdf.
 * ATTENTION: mutates the input tree!
 *
 * @param v
 * @param dmax ... a dictionary memoizing the max sizes per level of the depth dim
 * @param modB ... the value to shift the given subtree in the breadth dimension
 *
 */
const secondWalk = (v: LayoutTreeNode, dmax: DepthMax, modB = 0): LayoutTreeNode => {
  v.preB += modB;
  v.preD =
    Object.keys(dmax)
      .map((n) => parseInt(n, 10))
      .sort()
      .reduce((sum, k) => sum + (k < v.indexD ? dmax[k].size + DEPTH_GAP * dmax[k].weight : 0), 0) +
    dmax[v.indexD].size * 0.5;

  v.children.map((w) => secondWalk(w, dmax, modB + v.modB));
  // remove also mutual references, they are no longer needed!
  v.parent = undefined;
  v.ancestor = undefined;
  v.thread = undefined;
  return v;
};

/**
 * additional third walk (also pre order) used to get the bounds of the tree
 * in 'breadth (B) dimension' and 'depth (D) dimension'.
 * @param v
 * @param dir ... the layout direction
 * @returns [minB, maxB, minD, maxD]
 */
const bounds = (v: LayoutTreeNode, dir: TreeChartDirection): [number, number, number, number] => {
  const [minB, maxB, minD, maxD] = v.children.reduce(
    (a, w) => {
      const [minB, maxB, minD, maxD] = bounds(w, dir);
      return [
        Math.min(a[0], minB),
        Math.max(a[1], maxB),
        Math.min(a[2], minD),
        Math.max(a[3], maxD),
      ];
    },
    [Infinity, -Infinity, Infinity, -Infinity]
  );
  const offsetB = 0.5 * size(v, dir);
  const offsetD = 0.5 * size(v, dir, false);
  return [
    -MARGIN + Math.min(v.preB - offsetB, minB),
    MARGIN + Math.max(v.preB + offsetB, maxB),
    -MARGIN + Math.min(v.preD - offsetD, minD),
    MARGIN + Math.max(v.preD + offsetD, maxD),
  ];
};

/**
 * walking a given LayoutTree a second time in order to apply additional
 * positioning information. Both parts of Walkers algorithm combined.
 * For further details, look above.
 *
 * @param r ... the tree
 * @param dir ... the layout direction
 * @rteturns a tuple [<layouted tree>, dmax]
 */
export const treeLayout = (
  r: LayoutTreeNode,
  dir: TreeChartDirection
): [LayoutTreeNode, [number, number, number, number]] => {
  const [r2, dmax] = firstWalk(treeClone(r), dir, {});
  const treeBounds = bounds(secondWalk(r2, dmax, -r2.preB), dir);
  return [r2, treeBounds];
};

/**
 * transforms linked LayoutTreeNode(s) to an array of GraphNode(s)
 * with automatic positioning depending on depth and order.
 * @param v ... the tree
 * @param dir ... the layout direction (horizontal or vertical)
 * @param offsetB ... some precalculated offset in 'breadth direction'
 * @param offsetD ... offset in 'depth direction'
 */
const graphNodes = (
  v: LayoutTreeNode,
  dir: TreeChartDirection,
  offsetB: number,
  offsetD: number
): GraphNode[] => {
  const graphNode = {
    id: v.nodeId,
    isRoot: v.indexD === 0,
    invisible: v.invisible,
    ...pickBy(identity, { border: v.border }),
    position:
      dir === TreeChartDirection.vertical
        ? { x: MARGIN + offsetB + v.preB, y: MARGIN + offsetD + v.preD }
        : { x: MARGIN + offsetD + v.preD, y: MARGIN + offsetB + v.preB },
    type: v.type,
    size: v.size,
    content: v.content,
    ...v.nodeDecoration,
  };
  return [
    graphNode.type === NodeType.bubble ? (graphNode as BubbleNode) : (graphNode as GizmoNode),
    ...v.children.reduce((acc, n) => [...acc, ...graphNodes(n, dir, offsetB, offsetD)], []),
  ];
};

/**
 * derives an array of GraphEdge(s) from linked treeNode(s)
 * @param p ... the parent node
 * @param c ... the current node
 */
const graphEdges = (p: LayoutTreeNode | undefined, c: LayoutTreeNode): GraphEdge[] => [
  ...(p && p.nodeId && c.nodeId
    ? [
        {
          nodeId1: p.nodeId,
          nodeId2: c.nodeId,
          ...c.edgeDecoration,
        },
      ]
    : []),
  ...c.children.reduce((acc, n) => [...acc, ...graphEdges(c, n)], []),
];

/**
 * converts some treechart input tree to a suitable 'graph' structure
 * @param dir
 * @param tree
 */
export const graph = (
  dir: TreeChartDirection,
  tree: LayoutTreeNode,
  [layoutedTree, [minB, maxB, minD, maxD]] = treeLayout(tree, dir)
): Graph => ({
  ...GRAPH_GIZMO_DEFAULT_CONTENT,
  ticksWidth: dir === TreeChartDirection.vertical ? maxB - minB : maxD - minD,
  ticksHeight: dir === TreeChartDirection.vertical ? maxD - minD : maxB - minB,
  nodes: graphNodes(layoutedTree, dir, -minB, -minD),
  edges: graphEdges(undefined, layoutedTree),
});
