import { type BLayoutContent, exportContent, type Exporter } from '@bettermarks/gizmo-types';
import { decorationAttribute } from '../../../gizmo-utils/decoration';

export const exportBorderLayout: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as BLayoutContent;

  return `
    <math render-style="border-layout"
      ${content.$id ? `id="${content.$id}"` : ''}
    >
      <mrow>
        <configuration>
          <horizontalGap>${content.hGap}</horizontalGap>
          <verticalGap>${content.vGap}</verticalGap>
          ${content.content
            .map((c) => {
              const child = context.content[c.$refid];
              return `
              <constraint
                orientation="${c.orientation}"
                refId="${child && child.$id}"
                ${c.width ? `width="${c.width}"` : ''}
                ${decorationAttribute(c.decoration)}
              />
            `;
            })
            .join('')}
        </configuration>
        ${content.content.map((c) => exportContent(c.$refid, context)).join('')}
      </mrow>
    </math>
  `;
};
