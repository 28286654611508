import * as React from 'react';
import styles from './ExerciseButtons.scss';
import toolbarButtonStyles from '../ToolbarButton.scss';
import { ChevronRightSmallBold } from '../../../../../components/icons';
import { useTranslation } from 'react-i18next';
import { ToolbarButton } from '../ToolbarButton';
import { RedoIcon, UndoIcon } from '../icons/ExerciseButtons';
import { Sound, useAudio } from '../../../providers/Audio';
import { type TFunction } from 'i18next';

export const enum ExerciseButtonState {
  handIn = 'handIn',
  nextExercise = 'nextExercise',
  nextExerciseTestMode = 'nextExerciseTestMode',
  nextStep = 'nextStep',
  results = 'results',
  submit = 'submit',
}

type SimpleAction = () => void;

export type ExerciseButtonsDispatchProps = {
  nextExercise: SimpleAction;
  nextStep: SimpleAction;
  onShowHandInConfirmation: SimpleAction;
  redo: SimpleAction;
  results: SimpleAction;
  submit: SimpleAction;
  undo: SimpleAction;
};

const getNextButtonContent = (text: string) => {
  const words = text.split(' ');
  const lastWord = words.pop();
  return words.length > 0 ? (
    <span>
      {`${words.join(' ')} `}
      <span className={styles.nobreak}>
        {lastWord}
        <ChevronRightSmallBold className={styles.chevronIcon} />
      </span>
    </span>
  ) : (
    <span className={styles.nobreak}>
      {lastWord}
      <ChevronRightSmallBold className={styles.chevronIcon} />
    </span>
  );
};

const getExerciseButton = (props: ExerciseButtonsProps, t: TFunction) => {
  const {
    buttonState,
    undo,
    undoDisabled,
    redo,
    redoDisabled,
    submit,
    submitDisabled,
    results,
    nextStep,
    nextExercise,
    nextExerciseDisabled,
    onShowHandInConfirmation,
  } = props;
  let buttons: React.ReactElement[];

  switch (buttonState) {
    case ExerciseButtonState.submit:
      buttons = [
        <ToolbarButton
          key="undo-btn"
          id="undo-btn"
          dataCy="undo-btn"
          onClick={undo}
          disabled={undoDisabled}
        >
          <UndoIcon className={toolbarButtonStyles.svgIcon} />
        </ToolbarButton>,
        <ToolbarButton
          key="redo-btn"
          id="redo-btn"
          dataCy="redo-btn"
          onClick={redo}
          disabled={redoDisabled}
        >
          <RedoIcon className={toolbarButtonStyles.svgIcon} />
        </ToolbarButton>,
        <ToolbarButton
          key="submit-btn"
          id="submit-btn"
          dataCy="submit-btn"
          onClick={submit}
          kind="submit"
          disabled={submitDisabled}
        >
          {t('seriesplayer:toolbar.submit')}
        </ToolbarButton>,
      ];
      break;
    case ExerciseButtonState.results:
      buttons = [
        <ToolbarButton
          key="results-btn"
          id="results-btn"
          dataCy="results-btn"
          kind="submit"
          onClick={results}
        >
          {getNextButtonContent(t('seriesplayer:toolbar.showResult'))}
        </ToolbarButton>,
      ];
      break;
    case ExerciseButtonState.nextStep:
      buttons = [
        <ToolbarButton
          key="next-step-btn"
          id="next-step-btn"
          dataCy="next-step-btn"
          onClick={nextStep}
          kind="action"
        >
          {t('seriesplayer:toolbar.nextStep')}
        </ToolbarButton>,
      ];
      break;
    case ExerciseButtonState.nextExercise:
      buttons = [
        <ToolbarButton
          key="next-exercise-btn"
          id="next-exercise-btn"
          dataCy="next-exercise-btn"
          onClick={nextExercise}
          kind="action"
          disabled={nextExerciseDisabled}
        >
          {getNextButtonContent(t('seriesplayer:toolbar.nextExercise'))}
        </ToolbarButton>,
      ];
      break;
    case ExerciseButtonState.nextExerciseTestMode:
      buttons = [
        <ToolbarButton
          key="undo-btn-test"
          id="undo-btn-test"
          onClick={undo}
          disabled={undoDisabled}
        >
          <UndoIcon className={toolbarButtonStyles.svgIcon} />
        </ToolbarButton>,
        <ToolbarButton
          key="redo-btn-test"
          id="redo-btn-test"
          onClick={redo}
          disabled={redoDisabled}
        >
          <RedoIcon className={toolbarButtonStyles.svgIcon} />
        </ToolbarButton>,
        <ToolbarButton
          key="next-exercise-btn"
          id="next-exercise-btn"
          dataCy="next-exercise-btn"
          onClick={nextExercise}
          kind="action"
        >
          {getNextButtonContent(t('seriesplayer:toolbar.nextExercise'))}
        </ToolbarButton>,
      ];
      break;
    case ExerciseButtonState.handIn:
      buttons = [
        <ToolbarButton
          key="undo-btn-test"
          id="undo-btn-test"
          onClick={undo}
          disabled={undoDisabled}
        >
          <UndoIcon className={toolbarButtonStyles.svgIcon} />
        </ToolbarButton>,
        <ToolbarButton
          key="redo-btn-test"
          id="redo-btn-test"
          onClick={redo}
          disabled={redoDisabled}
        >
          <RedoIcon className={toolbarButtonStyles.svgIcon} />
        </ToolbarButton>,
        <ToolbarButton
          key="next-exercise-btn"
          id="next-exercise-btn"
          dataCy="next-exercise-btn"
          onClick={onShowHandInConfirmation}
          kind="action"
        >
          {getNextButtonContent(t('seriesplayer:toolbar.nextExercise'))}
        </ToolbarButton>,
      ];
      break;
    default:
      buttons = [];
  }

  return <>{buttons}</>;
};

export type ExerciseButtonsStateProps = {
  buttonState: ExerciseButtonState;
  nextExerciseDisabled: boolean;
  submitDisabled: boolean;
  undoDisabled: boolean;
  redoDisabled: boolean;
};

export type ExerciseButtonsProps = ExerciseButtonsStateProps & ExerciseButtonsDispatchProps;

/**
 * ExerciseButtons: Buttons like "next step", "submit" etc. that handle actions in the current
 * exercise.
 */
export const ExerciseButtons: React.FC<ExerciseButtonsProps> = (props) => {
  const [t] = useTranslation();
  const [, prepare] = useAudio();
  /**
   * Due to the Android issue (see: src/packages/bm-toolbox/providers/Audio/AudioPlayer.tsx),
   * we need to use the button onClick callbacks to preload the sounds that would be played later.
   * Those are injected here.
   */
  const exerciseButtonsProps = {
    ...props,
    submit: () => {
      // submitting an exercise for validation could result in the 'correct' or 'wrong' sounds
      prepare(Sound.Correct);
      prepare(Sound.Wrong);
      props.submit();
    },
    results: () => {
      // the end-screen that appears after clicking the 'results' button could play coin and star
      // sounds.
      prepare(Sound.Coin1);
      prepare(Sound.Coin2);
      prepare(Sound.Coin3);
      prepare(Sound.Star);
      props.results();
    },
  };

  return getExerciseButton(exerciseButtonsProps, t);
};

ExerciseButtons.displayName = 'ExerciseButtons';
