import * as React from 'react';
import classNames from 'classnames';
import { range } from 'lodash/fp';
import { constructBorderedCells, type MultipleBorderedCell } from './helpers';
import styles from './SquaredPaper.scss';
import { type Line, type TableCellContent } from './types';

type SquareTableProps = {
  rows: number;
  columns: number;
  cellSize: number;
  cellContent?: TableCellContent[];
  lines?: Line[];
};

/**
 * Apply correct border CSS styles based on cell's bordered style (if any)
 */
const applyBorderStyles =
  (borderedCells: MultipleBorderedCell[]) => (row: number, column: number) => {
    const foundBorderedCell = borderedCells.find(
      ({ cellCoord: { x, y } }) => x === column && y === row
    );
    return foundBorderedCell
      ? foundBorderedCell.borders.reduce(
          (borderStyles, border) => [...borderStyles, styles[`${border.style}-${border.edge}`]],
          []
        )
      : [];
  };

/**
 * `SquaredPaper` component draw a squared grid where size of the cells
 * are in proportion to the font size.
 *
 * ### Properties
 * | Name   | Type      | Default   | Description
 * |---     |---        |---        |---
 * | rows   | `number`   | -   |  # of table rows
 * | columns | `number`  |  -    | # of table columns
 * | cellSize | `number` |   -    | the cell size (width === height === size)
 * | cellContent | `? TableCellContent[]`|  `[]` | List of cell content.
 * |  | | | Coordinates of cells are the index of the top left corner.
 * |  | | | First cell has coordinate `(0, 0)`
 * |  | | | and last cell has coordinates `(columns - 1, rows - 1)`
 * | lines | `? Line[]` | `[]` | Lines to be drawn on the paper. Same coordinate system as SVG line
 * |  | | | `x1: 1, y1: 1,  x2: 1, y2: 3` is drawing a vertical line of 2 cell length
 * |  | | | `x1: 1, y1: 1,  x2: 3, y2: 1` is drawing a horizontal line of 2 cell length
 *
 */
export const SquaredPaper: React.FC<SquareTableProps> = ({
  rows,
  columns,
  cellSize,
  cellContent,
  lines,
}) => {
  const findCellContent = (row: number, column: number) =>
    (cellContent || []).find(({ coord: { x, y } }) => x === column && y === row);
  const borderedCells = constructBorderedCells(lines || [], rows, columns);
  return (
    <table className={styles.squareTable} style={{}}>
      <tbody>
        {range(0)(rows).map((row) => (
          <tr
            key={row}
            style={{
              height: cellSize,
              minHeight: cellSize,
              maxHeight: cellSize,
            }}
          >
            {range(0)(columns).map((column) => {
              const tableCell = findCellContent(row, column);
              const cellStyle = tableCell && tableCell.cellStyle ? tableCell.cellStyle : {};
              const contentClass = tableCell ? tableCell.className : undefined;
              return (
                <td
                  style={{
                    width: cellSize,
                    minWidth: cellSize,
                    maxWidth: cellSize,
                    ...cellStyle,
                  }}
                  key={column}
                  className={classNames(
                    contentClass,
                    styles.cell,
                    ...applyBorderStyles(borderedCells)(row, column)
                  )}
                >
                  {tableCell && tableCell.content}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SquaredPaper.displayName = 'SquaredPaper';
