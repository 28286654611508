import { type Dispatch, gizmoAction, connectGizmo } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { toggleSegment, removeForm, setSegmentCount } from './fractionFormActions';
import { FractionForm, type FractionFormCallbacks } from './FractionForm';

const mapDispatchToProps = (dispatch: Dispatch, { refid }: GizmoProps): FractionFormCallbacks => ({
  onToggleSegment: (segIndex: number) => dispatch(gizmoAction(toggleSegment(segIndex), refid)),
  onRemoveForm: (index: number) => dispatch(gizmoAction(removeForm(index), refid)),
  onSetSegments: (count: number) => dispatch(gizmoAction(setSegmentCount(count), refid)),
});

export const FractionFormContainer = connectGizmo(mapDispatchToProps, FractionForm);
