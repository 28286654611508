import { newHistory, type StateWithHistory } from 'redux-undo';
import { entries, isNil } from 'lodash';
import { type Saga } from 'redux-saga';
import { call } from 'redux-saga/effects';

import {
  type Content,
  type ContentDict,
  hasInteractionType,
  InputToolTypes,
} from '@bettermarks/gizmo-types';
import {
  type AppExercise,
  ApplicationState,
  type AppStep,
  CollapsibleContentState,
  type ContentExercise,
  type ContentStep,
  StepStatus,
  type TimedActionMeta,
} from '../../types';
import { handleError } from '../seriesplayer/sagaHelpers';

/**
 * Returns a list of refIds pointing to interactive children in the specified `contentDict`
 *
 * @param {ContentDict} contentDict
 * @returns {ReadonlyArray<[string, Content]>} A list of [refId, content]
 */
export const interactiveChildren = (contentDict: ContentDict): ReadonlyArray<[string, Content]> =>
  entries(contentDict)
    .filter(([_, content]) => hasInteractionType(content))
    // Entries are not guaranteed to be in order.
    // Sort the refIds by the id number after the colon.
    .sort(([aRefId], [bRefId]) =>
      +aRefId.split(':')[1] > +bRefId.split(':')[1] ? 1 : 0
    ) as ReadonlyArray<[string, Content]>;

/**
 * Wrap an object with an empty history (StateWithHistory).
 * @param {T} present object
 * @returns {StateWithHistory<T>} The input object wrapped as the present of an StateWithHistory
 */
export const withEmptyHistory = <T>(present: T): StateWithHistory<T> => newHistory([], present, []);

/**
 * Wrap an object with a history (StateWithHistory).
 * @param {ReadonlyArray<T>} past array
 * @param {T} present object
 * @returns {StateWithHistory<T>} The input objects wrapped as past/present of an StateWithHistory
 */
export const withHistory = <T>(past: T[], present: T): StateWithHistory<T> =>
  newHistory(past, present, []);

export const getFirstInteractiveContent = (
  question: ContentDict
): [string, Content] | undefined => {
  const interactive = interactiveChildren(question);
  return interactive.find(([_, content]) => !isNil(content.tool));
};

export const timedMetaCreator = (): TimedActionMeta => ({
  startTime: new Date().getTime(),
});

export const isFormulaSelected = (state: ApplicationState): boolean => {
  const content = ApplicationState.toSelectedContent(state).get(state);
  return !isNil(content) && !isNil(content.tool) && content.tool.type === InputToolTypes.keyboard;
};

export const toAppStep = (contentStep: ContentStep): AppStep => ({
  ...contentStep,
  aborted: false,
  question: withEmptyHistory(contentStep.question),
  solutionState: CollapsibleContentState.hidden,
  explanationState: CollapsibleContentState.hidden,
  numberOfErrors: 0,
  status: StepStatus.attempted,
  startTime: -1,
  maxTries: 2,
});

export const toAppExercise = ({ steps, ...ex }: ContentExercise): AppExercise => ({
  ...ex,
  currentStepId: '',
  steps: steps.map(toAppStep),
  startTime: -1,
  wrapupState: CollapsibleContentState.hidden,
  stepValidationLoaded: false,
});

/**
 * A higher-order function that receives a saga and returns a saga that catches
 * errors and handles them based on their type.
 *
 * Makes sure that the execution of the saga is not continued after an error had been thrown.
 */
export const withErrorHandling = <P extends any[]>(saga: Saga<P>): Saga<P> =>
  function* (...params) {
    try {
      yield call(saga, ...params);
    } catch (err) {
      yield call(handleError, err);
      throw err;
    }
  };
