import { ArrowDirection } from '../components/Arrow/types';
import * as RS from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';

export interface DayDurationGizmoContent extends Content {
  /**
   * The direction where the arrow is pointing:
   *  - left:  <---------------
   *  - right: -------------->
   */
  direction: ArrowDirection;

  /**
   * The reference of the label gizmo above the arrow
   */
  labelRef: ContentReference;
}

/**
 *
 */
export const DAY_DURATION_DEFAULT_CONTENT: Defaults<DayDurationGizmoContent, 'labelRef'> = {
  $: SPECIAL,
  $renderStyle: RS.DAY_DURATION,
  direction: ArrowDirection.right,
};
