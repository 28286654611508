import * as React from 'react';
import { defaultTo } from 'lodash';
import { type ContextState } from '../../../../gizmo-utils/polymorphic-gizmo';
import {
  type BezierGroupObject,
  CursorPositionOffset,
  type GeoContent,
  type GeoContentPersistProps,
  type GeoEditorMode,
  type GeoScene,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import { mousePos } from '@bettermarks/importers';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  dragAction,
  endDragAction,
  geoMoveBeziersReducer,
  hoverAction,
  initialState,
  outAction,
  startDragAction,
} from './geoMoveBeziersReducer';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';
import { Maybe } from '../../../../utils/maybe';

interface GeoMoveBezierCallbacks {
  readonly onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export type GeoMoveBeziersProps = GeoContent &
  GeoMoveBezierCallbacks &
  DragScrollableProps &
  ContextState & {
    mode: GeoEditorMode;
  };

export const GeoMoveBeziers: React.FC<GeoMoveBeziersProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoMoveBeziersReducer}
    componentName={`MoveBeziers:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const {
        geoContentMap,
        onPersistLocalState,
        matrix,
        uniqueId,
        isTouch,
        scrollBehaviour,
        configuration: { tickValueInterval },
      } = props;
      const maybeScrollBehaviour = Maybe(scrollBehaviour);

      const hover = (id: string) => (evt: React.MouseEvent<any>) =>
        dispatch(hoverAction({ gizmoId: uniqueId, id, matrix }));

      const out = (id: string) => (evt: React.MouseEvent<any>) => {
        const origBezier = geoContentMap[id] as BezierGroupObject;

        dispatch(outAction({ id, origBezier }));
      };

      const startDrag = (id: string) => (evt: MouseOrTouch) => {
        const startPos = mousePos(defaultTo<string>(uniqueId, ''))(evt);
        maybeScrollBehaviour.ap(startScrolling);
        dispatch(startDragAction({ gizmoId: uniqueId, id, matrix, startPos }));
      };

      const drag = (scene: GeoScene) => (evt: MouseOrTouch) => {
        const mouseP = mousePos(
          defaultTo<string>(uniqueId, ''),
          isTouch ? CursorPositionOffset.TOUCH : undefined
        )(evt);

        dispatch(dragAction({ mouseP, matrix, tickValueInterval }));
      };

      const endDrag = () => {
        maybeScrollBehaviour.ap(stopScrolling);
        return dispatch(endDragAction({ onPersistLocalState, geoContentMap }));
      };

      const geoContentProps = { ...props, ...state.persistProps };

      return (
        <GeoRenderer
          onMouseMove={drag}
          onBezierMouseDown={startDrag}
          onBezierMouseOut={out}
          onBezierHover={hover}
          onMouseUp={endDrag}
          onMouseLeave={endDrag}
          snapPoints={state.snapPoints}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoMoveBeziers.displayName = 'GeoMoveBeziers';
