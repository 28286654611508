import { identity } from '../../../../gizmo-utils/filters';
import { enrichMetaCreator } from '../../../../gizmo-utils/redux/gizmoActions';
import { type ShouldEnrich } from '@bettermarks/gizmo-types';
import { createAction } from 'redux-actions';
import { timedMetaCreator } from '../../../../apps/store/helper';
import type {
  ExerciseValidity,
  SeriesScore,
  SeriesStatus,
  TimedActionMeta,
} from '../../../../types';
import { type Validity } from '@bettermarks/umc-kotlin';

export const NEXT_EXERCISE = 'NEXT_EXERCISE';
export const nextExercise = createAction<TimedActionMeta & ShouldEnrich>(
  NEXT_EXERCISE,
  undefined,
  () => ({ ...timedMetaCreator(), ...enrichMetaCreator() })
);

export const SET_SERIES_STATUS = 'SET_SERIES_STATUS';
export const setSeriesStatus = createAction<SeriesStatus>(SET_SERIES_STATUS);

export const SET_SERIES_SCORE = 'SET_SERIES_SCORE';
export const setSeriesScore = createAction<SeriesScore>(SET_SERIES_SCORE);

export type ExerciseWithStepValidity = {
  validity: ExerciseValidity | undefined;
  steps: (Validity | undefined)[];
};

export const SET_EXERCISE_STATUS_COMPLETED_AND_VALIDITIES =
  'SET_EXERCISE_STATUS_COMPLETED_AND_VALIDITIES';
export const setExerciseCompletedAndValidities = createAction<
  (ExerciseWithStepValidity | undefined)[]
>(SET_EXERCISE_STATUS_COMPLETED_AND_VALIDITIES);

export const SWITCH_EXERCISE = 'SWITCH_EXERCISE';
export const switchExercise = createAction<number, TimedActionMeta>(
  SWITCH_EXERCISE,
  identity,
  timedMetaCreator
);

export const HAND_IN = 'HAND_IN';
export const handIn = createAction(HAND_IN);

export const USER_PRESSED_ENTER = 'USER_PRESSED_ENTER';
export const userPressedEnter = createAction(USER_PRESSED_ENTER);
