import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, defaultTheme, Layout } from '@seriesplayer/common-ui';
import { type ContentDict, numberFromStyles } from '@bettermarks/gizmo-types';
import { gizmoRegistry } from '../../../../../gizmo-utils/configuration/gizmos';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { DialogContent, DialogHeader } from '../../../../../components';
import { CollapsibleContentState } from '../../../../../types';

import { CollapsibleContentStyle, ContentBox } from '../../../components/ContentBox';
import { useMeasure } from '../../../../../gizmo-utils/useMeasure';

export const CONTENT_PADDING = numberFromStyles(defaultTheme.dimensions.spaceS);

export interface HintsDispatchProps {
  onCancel: () => void;
  dispatch: Dispatch;
}

export interface HintsStateProps {
  hints?: ReadonlyArray<ContentDict>;
  supports?: ReadonlyArray<ContentDict>;
  isTouch: boolean;
  staticMediaUrl: string;
}

export type HintsProps = HintsDispatchProps & HintsStateProps;

/**
 * Hints Component
 * Used to render Hints of step
 * Here we are reusing Layout component for layouting and
 * Box Component for stretching the Dialog content
 * Wrapping inside a Dialog for styling
 */
export const Hints: React.FC<HintsProps> = ({
  dispatch,
  hints,
  isTouch,
  onCancel,
  staticMediaUrl,
}) => {
  const [t] = useTranslation();
  const [contentWidth, contentRef] = useMeasure<number, HTMLDivElement>(
    0,
    ['client'],
    ({ client }, prevContentWidth) =>
      client && client.width !== prevContentWidth ? client.width : null
  );
  return (
    <Layout vertical>
      <DialogHeader
        title={t('seriesplayer:dialog.hints.title')}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
          dataCy: 'close-hints-modal',
        }}
      />
      <Bar />
      <DialogContent ref={contentRef}>
        {/* Additional div needed to enable scrolling on iOS */}
        {hints &&
          hints.map((hint, i) => (
            <ContentBox
              key={i}
              dataCy={`hint-${i}`}
              availableWidth={contentWidth - 2 * CONTENT_PADDING}
              collapsibleKind={CollapsibleContentStyle.hint}
              collapsible={false}
              collapse={CollapsibleContentState.expanded}
              contentDict={hint}
              gizmoRegistry={gizmoRegistry}
              dispatch={dispatch}
              isTouch={isTouch}
              staticMediaUrl={staticMediaUrl}
            />
          ))}
      </DialogContent>
    </Layout>
  );
};

Hints.displayName = 'Hints';
