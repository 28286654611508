import { createAction } from 'redux-actions';
import { type GeoContentPersistProps } from '@bettermarks/gizmo-types';

export const GEO_TRIGGER_PERSISTENCE = 'GEO_TRIGGER_PERSISTENCE';
export const GEO_PERSIST_LOCAL_STATE = 'GEO_PERSIST_LOCAL_STATE';
export const SCALE_DOWN = 'SCALE_DOWN';

export const scaleDown = createAction<GeoScaleDownPayload>(SCALE_DOWN);
export const localStoreToRedux = createAction<GeoPersistLocalStatePayload>(GEO_PERSIST_LOCAL_STATE);

export type GeoActionTriggerPersistencePayload = {
  refid: string;
  props: GeoContentPersistProps;
};

export type GeoPersistLocalStatePayload = GeoContentPersistProps;
export type GeoScaleDownPayload = { scale: number };
