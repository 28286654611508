import {
  type ContentDict,
  type FormulaContent,
  hasInteractionType,
  type InputDecoration,
} from '@bettermarks/gizmo-types';
import { hasCursor } from '@bettermarks/importers';
import { INPUT_FOCUS } from '../../../gizmo-utils/constants';
import { STUBABLE } from './FieldSetContainer';

/**
 * Gets the decoration to be applied on FieldSet
 * @param {string[]} childrenRefIds
 * @param {ContentDict} contentDict
 * @return {InputDecoration}
 */
export const getFieldSetDecoration = (
  childrenRefIds: string[],
  contentDict: ContentDict
): InputDecoration => {
  const severity = STUBABLE.getSeverityFromChildren(childrenRefIds, contentDict);
  // Here the assumption is made that FieldSet could only have children as FormulaContent.
  // This assumption is valid as per our current content.
  const hasCursorInChildren = childrenRefIds.some((refId) =>
    hasCursor((contentDict[refId] as FormulaContent).content)
  );
  return severity ? severity : hasCursorInChildren ? INPUT_FOCUS : undefined;
};
/**
 * Derives disabled prop from its interactive children
 */
export const getFieldSetDisabled = (childrenRefIds: string[], contentDict: ContentDict): boolean =>
  childrenRefIds.some((refId) => {
    const content = contentDict[refId] as FormulaContent;
    return !!(hasInteractionType(content) && content.disabled);
  });
