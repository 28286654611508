import * as React from 'react';
import classNames from 'classnames';
import {
  type ContextState,
  renderGizmos,
  useContentTranslation,
} from '../../gizmo-utils/polymorphic-gizmo';
import { Duration } from '../components/Duration';
import { type TimeDurationGizmoContent } from '@bettermarks/gizmo-types';
import styles from './TimeDuration.scss';

export type TimeDurationGizmoProps = TimeDurationGizmoContent & ContextState;

/**
 * `TimeDurationGizmo` component.
 *
 */
export const TimeDurationGizmo: React.FC<TimeDurationGizmoProps> = ({
  direction,
  hourRef,
  minuteRef,
  availableWidth,
}) => {
  const t = useContentTranslation();
  return (
    <Duration direction={direction} labelHorizontalPadding={24} labelVerticalPadding={16}>
      <div className={classNames(styles.timeDurationLabel)}>
        <span>{renderGizmos([hourRef], availableWidth)}</span>&nbsp;
        <span>{t('timeDuration.hours')}</span>&nbsp;
        <span>{renderGizmos([minuteRef], availableWidth)}</span>&nbsp;
        <span>{t('timeDuration.minutes')}</span>
      </div>
    </Duration>
  );
};

TimeDurationGizmo.displayName = 'TimeDurationGizmo';
