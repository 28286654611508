import { INCLUDE_GIZMO_IFRAME_ID } from './constants';

/**
 * This method fires the reset message for the include gizmo.
 * See.
 *  packages/bm-toolbox/public/js/reset-experiment.js
 *  http://i18nwiki.bm.loc/index.php/Edl:applyTemplate:include
 */
export const resetIncludeGizmo = () => {
  const maybeIncludeGizmoIframe: HTMLElement | null | undefined =
    document.getElementById(INCLUDE_GIZMO_IFRAME_ID);
  if (maybeIncludeGizmoIframe) {
    (maybeIncludeGizmoIframe as HTMLIFrameElement).contentWindow?.postMessage(
      'resetIncludeGizmo', // WARNING: this needs to match the packages/bm-toolbox/public/js/reset-experiment.js
      '*'
    );
  }
};
