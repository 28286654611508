import { type Content, type ContentReference } from '../../xml-converter/core';
import { type Decoration } from '../../gizmo-utils/types';
import * as RS from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';
import type { ValueStepperPosition } from '../value-stepper/data';

export enum ControlPosition {
  left = 'left',
  right = 'right',
  bottom = 'bottom',
}

export enum ControlAppearance {
  radioButton = 'radioButton',
  dropdown = 'dropdown',
}

export type Slider = {
  min: number | string;
  max: number | string;
  snapInterval: number;
  value: number;
  binding: string;
  decoration: Decoration;
  label?: ContentReference;
  orientation?: ControlOrientation;
  position?: ControlPosition;
  showValue?: boolean;
};

export type OnOffControl = {
  value: number;
  binding: string;
  position: ControlPosition;
  label: ContentReference;
};

export type OptionsControl = {
  value: number;
  binding: string;
  position: ControlPosition;
  orientation: ControlOrientation;
  appearance: ControlAppearance;
  options: ContentReference[];
  label?: ContentReference;
};

export type ValueStepper = {
  min: number | string;
  max: number | string;
  step: number;
  value: number;
  binding: string;
  position: ValueStepperPosition;
  decoration: Decoration;
  initiallyActive?: true;
};

export const DEFAULT_SLIDER_PROPS: Slider = {
  binding: '',
  decoration: {},
  max: 1,
  min: 0,
  orientation: 'horizontal',
  position: ControlPosition.right,
  showValue: false,
  snapInterval: 0,
  value: 0,
};

export interface IDynamicRepresentationContent extends Readonly<Content> {
  sliders: Slider[];
  onOffControls: OnOffControl[];
  optionsControls: OptionsControl[];
  valueSteppers: ValueStepper[];
  representation: ContentReference;
}

export const DEFAULT_DYNAMIC_REPRESENTATION_CONTENT: IDynamicRepresentationContent = {
  $: SPECIAL,
  sliders: [],
  onOffControls: [],
  optionsControls: [],
  valueSteppers: [],
  representation: {
    $refid: '',
  },
  $renderStyle: RS.DYNAMIC_REPRESENTATION,
};

export type ControlOrientation = 'horizontal' | 'vertical';
