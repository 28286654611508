import * as React from 'react';
import { Bar, Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  Content,
  BaseLayout,
} from '../../../../../components';
import { type BackButtonDialogs } from '../../../../../types';

export interface ConfirmSubmitIncompleteDispatchProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export interface ConfirmSubmitIncompleteOwnProps {
  title: string;
  mainText?: string;
  restartText?: string;
  abortRestartText?: string;
  goBackDialog: BackButtonDialogs;
}

type ConfirmSubmitIncompleteProps = ConfirmSubmitIncompleteDispatchProps &
  ConfirmSubmitIncompleteOwnProps;

export const ConfirmSubmitIncomplete: React.FC<ConfirmSubmitIncompleteProps> = ({
  onConfirm,
  onCancel,
  ...textProps
}) => (
  <BaseLayout vertical>
    <DialogHeader
      title={textProps.title}
      leftButton={{
        icon: 'ArrowLeftLargeBold',
        onClick: onCancel,
      }}
    />
    <Bar />
    <Content as={DialogContent} colored>
      <p className="plaintext">{textProps.mainText}</p>
      <DialogFooter align="center">
        <Button
          key="submitincomplete"
          kind={ButtonKind.warning}
          dataCy="submit-incomplete"
          onClick={onConfirm}
          stretch={ButtonStretch.responsive}
        >
          {textProps.restartText}
        </Button>
        <Button
          key="unquit"
          kind={ButtonKind.view}
          onClick={onCancel}
          stretch={ButtonStretch.responsive}
        >
          {textProps.abortRestartText}
        </Button>
      </DialogFooter>
    </Content>
  </BaseLayout>
);

ConfirmSubmitIncomplete.displayName = 'ConfirmSubmitIncomplete';
