import * as React from 'react';
import {
  DrawingPenBlack,
  DrawingPenBlue,
  DrawingPenGreen,
  DrawingPenRed,
  DrawingPenWhite,
  DrawingPenYellow,
} from '../../../components/icons';
import { type PenColor } from '../types';

type PenIconProps = {
  color?: PenColor;
};

export default function PenIcon({ color }: PenIconProps): JSX.Element {
  switch (color) {
    case 'cGray800':
      return <DrawingPenBlack width={30} height={30} />;
    case 'cBlue700':
      return <DrawingPenBlue width={30} height={30} />;
    case 'cGreen700':
      return <DrawingPenGreen width={30} height={30} />;
    case 'cRed700':
      return <DrawingPenRed width={30} height={30} />;
    case 'cWhite':
      return <DrawingPenWhite width={30} height={30} />;
    case 'cYellow400':
      return <DrawingPenYellow width={30} height={30} />;
    default:
      return <DrawingPenBlack width={30} height={30} />;
  }
}
