export const DATA_ATTRIBUTE_GIZMO_ID = 'data-gizmo-id';
export const DATA_ATTRIBUTE_CONTENT_ID = 'data-content-id';

export const INPUT_FOCUS: 'focus' = 'focus';

export const MATH: 'math' = 'math';
export const SEMANTICS: 'semantics' = 'semantics';

export const ANNOTATION_XML: 'annotation-xml' = 'annotation-xml';
export const SPECIAL: 'special' = 'special';
export const PLACEHOLDER: 'placeholder' = 'placeholder';
export const DATA: 'data' = 'data';

export const ID: 'id' = 'id';
export const RENDER_STYLE: 'render-style' = 'render-style';
export const INTERACTION_TYPE: 'interaction-type' = 'interaction-type';
export const BINDING: 'binding' = 'binding';
export const DEFAULT_TEXT: 'default-text' = 'default-text';
export const DIRECTION: 'direction' = 'direction';
export const FLAVOUR: 'flavour' = 'flavour';
export const SELECTED: 'selected' = 'selected';
export const GAP: 'gap' = 'gap';
export const HALIGN: 'hAlign' = 'hAlign';
export const HIDE_CONTENT: 'hideContent' = 'hideContent';
export const NO_SCALE: 'noScale' = 'noScale';
export const KEY: 'key' = 'key';
export const LAYOUT: 'layout' = 'layout';
export const REF_ID: 'ref-id' = 'ref-id';
export const REPLACEMENTS: 'replacements' = 'replacements';
export const SELECTED_MODE: 'selected-mode' = 'selected-mode';
export const TOOL_SET: 'tool-set' = 'tool-set';
export const MIN_INPUT_WIDTH: 'minInputWidth' = 'minInputWidth';
export const MAX_INPUT_LENGTH: 'maxInputLength' = 'maxInputLength';
export const POSX: 'posX' = 'posX';
export const POSY: 'posY' = 'posY';
export const VALIGN: 'vAlign' = 'vAlign';
export const SKIP_ROUNDTRIP: 'skip-roundtrip' = 'skip-roundtrip';

export const CONFIGURATION: 'configuration' = 'configuration';
export const DECORATION: 'decoration' = 'decoration';
export const SET: 'set' = 'set';

export const LAYER: 'layer' = 'layer';
export const AXIS: 'axis' = 'axis';
export const ADVANCEDAXIS: 'advancedAxis' = 'advancedAxis';

export const BACKGROUND: 'background' = 'background';

// This is required to load KEM/FEMs independently and when no staticUrl is provided.
export const DEFAULT_CONTENT_ROUTE = 'https://content.bettermarks.com';
export const DEFAULT_MEDIA_ROUTE = 'https://media-acc.bettermarks.com';

// Used for a proper document.getElementById within the code.
export const CONTENT_ID = 'content';

/**
 * The portal to use for displaying drag sources inside the toolbar drawer.
 */
export const DRAWER_PORTAL_ID = 'drawerPortal';
