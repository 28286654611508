import { handleActions } from 'redux-actions';
import { SOLUTION_SET } from '@bettermarks/gizmo-types';
import { SOLUTION_SET_DEFAULT_CONTENT, type SolutionSetContent } from '@bettermarks/gizmo-types';
import { ADD_ELEMENT, REMOVE_ELEMENT } from './solutionSetActions';

export const solutionSetReducer = handleActions<SolutionSetContent, void>(
  {
    [ADD_ELEMENT]: (state: SolutionSetContent): SolutionSetContent => {
      const [newVisibleElement, ...newHiddenElements] = state.hiddenElements;

      return {
        ...state,
        visibleElements: [...state.visibleElements, newVisibleElement],
        hiddenElements: [...newHiddenElements],
      };
    },
    [REMOVE_ELEMENT]: (state: SolutionSetContent): SolutionSetContent => {
      const newInvisibleElement = state.visibleElements[state.visibleElements.length - 1];

      return {
        ...state,
        visibleElements: state.visibleElements.slice(0, -1),
        hiddenElements: [newInvisibleElement, ...state.hiddenElements],
      };
    },
  },
  {
    ...SOLUTION_SET_DEFAULT_CONTENT(),
    $interactionType: SOLUTION_SET,
  }
);
