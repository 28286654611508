import styled from 'styled-components';
import { colors } from '@seriesplayer/common-ui';

interface IColorSelection {
  color: string;
  isSelected?: boolean;
}

export const ColorSelection = styled.button<IColorSelection>`
  cursor: pointer;
  background-color: ${(props) => props.color};
  color: ${(props) => props.color};
  width: 32px;
  height: 32px;
  border: 1px solid rgba(153, 153, 153, 0.4);
  border-radius: 18px;
  margin-top: 6px;
  margin-bottom: 6px;
  box-shadow: ${(props) => {
    if (props.isSelected) {
      if ([colors['cYellow400'], colors['cWhite']].includes(props.color)) {
        return 'inset 0 0 0 2px rgba(221,221,221,0.7)';
      } else return 'inset 0 0 0 2px rgba(255,255,255,0.7)';
    }
  }};

  &:hover {
    filter: ${(props) => (!props.isSelected ? 'brightness(80%)' : '')};
    box-shadow: ${(props) => (!props.isSelected ? 'inset 0 0 0 1px rgba(255,255,255,0.6)' : '')};
  }
`;
