import { DEFAULT_MEDIA_ROUTE } from '@bettermarks/gizmo-types';
import { isEmpty } from 'lodash';

/**
 * For animations to serve from same root domain, following changes were made:
 * 1. Following cloudfront distribution configuration provide `media_*` from
 * s3://bm-content-data/media-release/current/.
 *  - gremlins.bettermarks.com - CI/Dev Environment
 *  - seriesplayer.bettermarks.com - Production Environment.
 * 2. The nginx configuration for NSP was adapted
 * to serve `/media*` routes from above Cloudfront distributions. (SVN revision: 309601)
 */
export const getMediaUrl = (source: string, staticMediaUrl = DEFAULT_MEDIA_ROUTE): string => {
  // For dev and testing, in handcrafted XMLs,
  // 1. isEmpty is to replace with default Betty in MediaPlayer Component
  if (isEmpty(source)) return source;
  // 2. '/' is to load the images from same host in gizmo viewer
  // stripping of the beginning slash to load relative to page instead of server root
  if (source.startsWith('/')) return source.substring(1);
  // Production mode & real exercise will always go to `${staticMediaUrl}/${source}`

  // to prevent images from being blocked as advertisements/banners we break the dimension pattern
  // (WIDTHxHEIGHT) by adding _NO_AD_ which will always be removed by nginx via rewrite rule
  // eslint-disable-next-line no-useless-escape
  const dimRegex = new RegExp(`([\d]*)x([\d])*`, 'g');

  if (source.match(dimRegex)) {
    return `${staticMediaUrl}/${source.replace(dimRegex, '$1_NO_AD_x$2')}`;
  }

  return `${staticMediaUrl}/${source}`;
};
