import {
  Direction,
  isContainer,
  isPureMathContent,
  type MathContent,
  type Path,
} from '@bettermarks/gizmo-types';
import { getContainerInfo } from '@bettermarks/importers';
import { type HandleContainerFunction } from './types';
import { enterBase, enterBucket } from './moveCursor';

/**
 * The dictionary containing our rules for entering a container. The function is selecten from
 * - the container we want to enter
 * - the direction from where we want to enter the container
 */
const enterContainerFunction: { [key: string]: HandleContainerFunction } = {
  'mfrac:left': enterBucket('denominator'),
  'mfrac:right': enterBucket('numerator'),
  'mroot:left': enterBucket('radicand'),
  'mroot:right': enterBucket('index'),
  'msqrt:left': enterBucket('radicand'),
  'msqrt:right': enterBucket('radicand'),
  'mabs:left': enterBucket('value'),
  'mabs:right': enterBucket('value'),
  'msub:left': enterBucket('subscript'),
  'msup:left': enterBucket('superscript'),
  'msub:right': enterBase,
  'msup:right': enterBase,
};

/**
 * This function is called, when we are about to enter a new container (e.g. an
 * <mfrac>). Depending on the direction and the given mfrac, we need to decide
 * where the cursor will be placed inside the container.
 * @param {MathContent} input our math content
 * @param {Path} containerPath the path of the cursor inside the math content
 * @param {Direction} direction the direction of cursor movement
 */
export const enterContainer = (input: MathContent, containerPath: Path, direction: Direction) => {
  const { container } = getContainerInfo(input, containerPath);
  if (isPureMathContent(container) && isContainer(container)) {
    const key = `${container.$}:${direction === Direction.Left ? 'left' : 'right'}`;
    if (key in enterContainerFunction) {
      return enterContainerFunction[key](input, containerPath, direction);
    }
  }

  return input;
};
