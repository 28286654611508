import { enrichContentDict as enrichContentDictUnstubable } from '../../../../gizmo-utils/measure';
import { stylers } from '../../../../gizmo-utils/configuration/stylers';
import { rulers } from '../../../../gizmo-utils/configuration/rulers';
import { type Action, handleAction } from 'redux-actions';
import { withEmptyHistory } from '../../../store/helper';
import { type ApplicationState, LoaderState } from '../../../../types';

import { emptyExercise, emptyStep, initialSeriesplayerState } from '../../../store/constants';
import { type GizmoViewerLoadData } from '../../types';
import { LOAD_GIZMO_FAILURE, LOAD_GIZMO_SUCCESS, LOAD_GIZMO_PROGRESS } from './actions';
import { reduceReducers } from '../../../../gizmo-utils/reduceReducers';

export const STUBABLE = {
  enrichContentDict: enrichContentDictUnstubable,
};

const handleLoadGizmoProgress = handleAction(
  LOAD_GIZMO_PROGRESS,
  (state: ApplicationState) => ({
    ...state,
    loaderState: LoaderState.loading,
  }),
  initialSeriesplayerState
);

const handleLoadGizmoSuccess = handleAction(
  LOAD_GIZMO_SUCCESS,
  (state: ApplicationState, { payload }: Action<GizmoViewerLoadData>) => {
    if (!payload) {
      return state;
    }
    const contentDict = STUBABLE.enrichContentDict(payload.contentDict, stylers, rulers);
    return {
      ...state,
      series: {
        ...state.series,
        currentExerciseIndex: 0,
        exercises: [
          {
            ...emptyExercise,
            currentStepId: 'gizmoViewer',
            steps: [
              {
                ...emptyStep,
                id: 'gizmoViewer',
                question: withEmptyHistory(contentDict),
              },
            ],
          },
        ],
      },
      loaderState: LoaderState.success,
    };
  },
  initialSeriesplayerState
);

const handleLoadGizmoFailure = handleAction(
  LOAD_GIZMO_FAILURE,
  (state: ApplicationState) => ({
    ...state,
    loaderState: LoaderState.error,
  }),
  initialSeriesplayerState
);

export const gizmoViewerReducer = reduceReducers<ApplicationState>(
  handleLoadGizmoFailure,
  handleLoadGizmoProgress,
  handleLoadGizmoSuccess
);
