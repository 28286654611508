import * as React from 'react';
import { type KeyHandler, Key } from './Key';
import { KeyCode } from './keyCodes';

export type SpecialKeysProps = {
  slim?: boolean;
  onKey?: KeyHandler;
};

/**
 * SpecialKeys: Back, Forward, ToggleKeyboard and Delete (Delete)
 */
export const SpecialKeys: React.FC<SpecialKeysProps> = ({ slim, onKey }) => (
  <>
    {(slim
      ? [KeyCode.Delete, KeyCode.CaretLeft, KeyCode.Toggle, KeyCode.CaretRight]
      : [KeyCode.CaretLeft, KeyCode.Delete, KeyCode.Toggle, KeyCode.CaretRight]
    ).map((k) => (
      <Key key={k} keyCode={k} iconProps={{ onKey, type: 'dark' }} />
    ))}
  </>
);

SpecialKeys.displayName = 'SpecialKeys';
