import {
  type BubbleNode,
  type BubbleNodeContent,
  CONFIGURATION,
  type ContentColor,
  type FElement,
  type Graph,
  GRAPH_GIZMO_DEFAULT_CONTENT,
  type GraphEdge,
  type GraphEditorMode,
  GraphEditorModes,
  type GraphNode,
  importContent,
  type Importer,
  type ImporterContext,
  InputToolTypes,
  LineStyle,
  type NodeDecoration,
  NodeOptionType,
  NodeType,
  parseCommonAttribs,
  Position,
  SEMANTICS,
  toXmlElement,
  xmlTextToBoolean,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';
import { compact, identity, pickBy } from 'lodash/fp';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import { isColorName } from '../../../components/colors';
import { GRAPH_MIN_WIDTH } from './constants';

const isNode = (xml: FElement) => parseCommonAttribs(xml).$renderStyle === 'tree-node';
const isEdge = (xml: FElement) => parseCommonAttribs(xml).$renderStyle === 'graph-edge';

const getOptionType = (type: string): NodeOptionType => {
  switch (type) {
    case 'colorchooser':
      return NodeOptionType.colorChooser;
    case 'selectbox':
      return NodeOptionType.selectBox;
    default:
      return NodeOptionType.selectBox;
  }
};

const parseModes = (modes = ''): GraphEditorMode[] =>
  compact(
    modes.split(';').map((mode) => {
      switch (mode) {
        case 'create_node':
          return GraphEditorModes.Add;
        case 'remove':
          return GraphEditorModes.Remove;
        case 'connect_nodes':
          return GraphEditorModes.Connect;
        case 'default_cursor':
          return GraphEditorModes.Move;
        default:
          return undefined;
      }
    })
  );

/* use the given color options of the template node as editor modes (will be shown as different
 * colored pencils in the tool bar).
 * If we have a selector with labels, just add editor mode 'add label', otherwise there's no
 * additional mode needed.
 */
const parseEditOptions = (content?: BubbleNodeContent): GraphEditorMode[] =>
  content
    ? content.type === NodeOptionType.colorChooser
      ? content.options
      : [GraphEditorModes.Label]
    : [];

export const parseNode =
  (context: ImporterContext) =>
  (xml: FElement): GraphNode => {
    const attribs = parseCommonAttribs(xml);
    const mrow = xml.findChildTag('mrow');
    const configuration = mrow.findChildTag(CONFIGURATION);
    const semantics = mrow.findChildTag(SEMANTICS);
    const select = mrow.findChildTag('select');
    const { isRoot } = configuration.tagsToProps(xmlTextToBoolean, [], ['isRoot']);
    const posXML = configuration.findChildTag('position');

    const position = {
      x: parseFloat(posXML.findChildTag('x').text) || 0,
      y: parseFloat(posXML.findChildTag('y').text) || 0,
    };

    const id = attribs.$id;
    const addedByUser = attribs.$interactionType ? true : undefined;

    const node: GraphNode = {
      ...(isNaN(position.x) || isNaN(position.y) ? { position: { x: 0, y: 0 } } : { position }),
      type: NodeType.bubble,
      ...pickBy(identity, { isRoot, id, addedByUser }),
    };

    if (semantics.exists) {
      return {
        ...node,
        type: NodeType.gizmo,
        content: importContent(semantics, context),
      };
    }

    const {
      object: { borderColor, highlightColor, color, fontWeight },
      severity,
    } = parseDecoString<keyof NodeDecoration>(mrow.attribute('decoration'));

    let content: BubbleNodeContent | undefined;

    if (select.exists) {
      const optionType = getOptionType(select.attribute('type').trim());

      const optXML = select.getChildrenByTagName('option');
      const optionStrings = optXML.map((xml) => xml.text).filter(identity);
      const textXML = optXML.find((xml) => xml.attribute('selected') === 'true');
      const selectString = textXML && textXML.text;
      if (optionStrings.length > 0) {
        if (optionType === NodeOptionType.colorChooser) {
          const options: ContentColor[] = optionStrings.filter(isColorName);
          const selected = selectString && isColorName(selectString) && selectString;
          content = {
            type: NodeOptionType.colorChooser,
            options,
            ...pickBy(identity, { selected }),
          };
        } else {
          content = {
            type: NodeOptionType.selectBox,
            options: optionStrings,
            ...pickBy(identity, { selected: selectString }),
          };
        }
      }
    }

    return {
      ...node,
      ...pickBy(identity, {
        borderColor,
        highlightColor,
        color,
        fontWeight,
        content,
        severity,
      }),
      type: NodeType.bubble,
    };
  };

const isLineStyle = (text?: string): text is LineStyle => (text ? text in LineStyle : false);

export const parseEdge = (xml: FElement): GraphEdge => {
  const attribs = parseCommonAttribs(xml);
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);
  const {
    object: { lineColor, lineHighlightColor, lineStyle },
    severity,
  } = parseDecoString<'lineColor' | 'lineHighlightColor' | 'lineStyle'>(
    mrow.attribute('decoration')
  );

  const refIds = configuration.findChildTag('refIds').getChildrenByTagName('refId');
  if (refIds.length !== 2) {
    throw Error('Graph edge with less or more than two nodes');
  }

  return {
    $id: attribs.$id,
    nodeId1: refIds[0].text,
    nodeId2: refIds[1].text,
    ...pickBy(identity, {
      color: lineColor && isColorName(lineColor) ? lineColor : undefined,
      highlightColor:
        lineHighlightColor && isColorName(lineHighlightColor) ? lineHighlightColor : undefined,
      severity,
      addedByUser: attribs.$interactionType ? true : undefined,
      style: isLineStyle(lineStyle) ? lineStyle : undefined,
    }),
  };
};

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * color is optional in the XML, default value is `gray` (using [[GRAPH_GIZMO_DEFAULT_CONTENT]]
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @returns The metadata and parsed xml as `Content`
 */
export const importGraph: Importer<Graph> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);
  const semantics = mrow.getChildrenByTagName(SEMANTICS);
  const templateXML = configuration.findChildTag('template').findChildTag('mrow');

  const templateNodeXML = `<semantics>
    ${templateXML.toString()}
    <annotation-xml encoding='bettermarks'>
      <special render-style='tree-node'/>
    </annotation-xml>
  </semantics>`;

  const template = parseNode(context)(toXmlElement(templateNodeXML)) as BubbleNode;

  let modes = parseModes(preContent.toolSet);
  // only if the toolset contains 'pointer_default' aka Move we try parse the template
  if (modes.find((x) => x === GraphEditorModes.Move)) {
    modes = modes.concat(parseEditOptions(template.content));
  }

  modes = modes.length > 0 ? [GraphEditorModes.SCROLL, ...modes] : modes;

  const { ticksWidth, ticksHeight } = configuration.tagsToProps(
    xmlTextToInt,
    ['ticksWidth', 'ticksHeight'],
    []
  );

  const scale = GRAPH_MIN_WIDTH / ticksWidth;

  const nodes = semantics
    .filter(isNode)
    .map(parseNode(context))
    .map((n) => ({
      ...n,
      position: Position.scale(n.position, scale),
    }));
  const edges = semantics.filter(isEdge).map(parseEdge);

  return {
    ...GRAPH_GIZMO_DEFAULT_CONTENT,
    ...preContent,
    ticksHeight,
    ticksWidth,
    nodes,
    edges,
    template,
    tool: {
      type: InputToolTypes.modeSelector,
      modes,
    },
    selectedMode: modes[0],
  };
};
