import { MULTIPLE_CHOICE_DROP_DOWN as IT_MC_DROP_DOWN } from '@bettermarks/gizmo-types';
import { DEFAULT_TEXT_KEY, type MultipleChoiceDropDownContent, RS } from '@bettermarks/gizmo-types';
import { type Action, handleActions } from 'redux-actions';
import { type MultipleChoiceActionPayload, SELECT_ITEM } from './multipleChoiceDropdownActions';

const initialState: MultipleChoiceDropDownContent = {
  $: 'special',
  $interactionType: IT_MC_DROP_DOWN,
  $id: '',
  $renderStyle: RS.MULTIPLE_CHOICE_DROP_DOWN,
  choices: [],
  configuration: {
    dropDownDefaultText: DEFAULT_TEXT_KEY,
  },
  checkedIdx: -1,
};

export const multipleChoiceDropdownReducer = handleActions<
  MultipleChoiceDropDownContent,
  MultipleChoiceActionPayload
>(
  {
    [SELECT_ITEM]: (state, { payload }: Action<MultipleChoiceActionPayload>) => {
      if (
        !payload ||
        !(payload.item !== undefined && payload.item >= 0 && payload.item < state.choices.length)
      ) {
        return state;
      }

      return { ...state, checkedIdx: payload.item };
    },
  },
  initialState
);
