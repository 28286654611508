import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';

import { reorder } from './sortingReducer';
import { SortingGizmo, type SortingGizmoProps, type SortingGizmoCallbacks } from './SortingGizmo';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): SortingGizmoCallbacks => ({
  onReorder: (sourceIndex, destinationIndex) =>
    dispatch(gizmoAction(reorder({ sourceIndex, destinationIndex }), refid)),
});

export const SortingGizmoContainer = connectGizmo<SortingGizmoProps>(
  mapDispatchToProps,
  SortingGizmo
);

SortingGizmoContainer.displayName = 'SortingGizmoContainer';
