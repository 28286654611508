import * as React from 'react';
import { Image } from '../../components/Image';
import { getMediaUrl } from '@bettermarks/importers';
import { type ContextState, useStaticMediaUrl } from '../../gizmo-utils/polymorphic-gizmo';
import { IMAGE_DEFAULT_CONTENT, type ImageContent } from '@bettermarks/gizmo-types';
import { isEmpty } from 'lodash/fp';
import { evaluateValueSetterExpression } from '@bettermarks/umc-kotlin';
import { valueSetterMapToValidatorValueMap } from '../formula/Formula/helper';

export type ImageProps = ImageContent &
  ContextState & {
    /**
     * Default for this Gizmo is undefined but true for Image Component
     * @see: Image
     */
    allowObjectTag?: boolean;
  };

/**
 * Connects the [[components/Image]] UIComponent to [[../ImageContent]].
 *
 * Props:
 *  - allowObjectTag: Default for this Gizmo is undefined but true for Image Component
 *  - src: the image source
 *  - width: the image maximum width
 *  - height: the image maximum height
 *  - contentLanguage: the locale (of the exercise) to pass to getImageUrl
 */
export const ImageGizmo: React.FC<ImageProps> = (props) => {
  const {
    availableWidth,
    source,
    width,
    height,
    hAlign,
    vAlign,
    allowObjectTag,
    valueSetterMap,
    dynamicScale,
    scaleCenter,
  } = {
    ...IMAGE_DEFAULT_CONTENT,
    ...props,
  };

  const withZoom =
    valueSetterMap !== undefined && !isEmpty(valueSetterMap) && dynamicScale !== undefined;

  let dynamicScaleEv = 1.0;
  if (withZoom) {
    dynamicScaleEv = evaluateValueSetterExpression({
      expression: dynamicScale,
      valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
    });
  }

  const staticMediaUrl = useStaticMediaUrl();

  const scale = dynamicScaleEv * (width > availableWidth ? availableWidth / width : 1);
  const imageUrl = getMediaUrl(source, staticMediaUrl);

  return withZoom ? (
    <div style={{ position: 'relative', overflow: 'hidden', width, height }}>
      <div
        style={{
          position: 'absolute',
          left: -(scale - 1) * (scaleCenter?.x || 0),
          top: (scale - 1) * ((scaleCenter?.y || 0) - height),
          width: width * scale,
          height: height * scale,
        }}
      >
        <Image
          src={imageUrl}
          width={width}
          height={height}
          scale={scale}
          hAlign={hAlign}
          vAlign={vAlign}
          allowObjectTag={false}
        />
      </div>
    </div>
  ) : (
    <Image
      src={imageUrl}
      width={width}
      height={height}
      scale={scale}
      hAlign={hAlign}
      vAlign={vAlign}
      allowObjectTag={allowObjectTag}
    />
  );
};

ImageGizmo.displayName = 'ImageGizmo';
