import * as React from 'react';

import { WithLocalRedux } from '../../../../../../gizmo-utils/WithLocalRedux';

import { type FractionFormProps } from '../../../FractionForm';
import { FractionFormRenderer } from '../../FractionFormRenderer';
import {
  initialState,
  out,
  over,
  toggleFillReducer,
  toggleSegmentLocal,
} from './toggleFillReducer';

// removes all callbacks except the one to toggle segments and adds hover behavior
export const ToggleFill: React.FC<FractionFormProps> = ({
  $id,
  onRemoveForm,
  onSetSegments,
  onToggleSegment,
  disabled,
  ...props
}) => (
  <WithLocalRedux
    componentName={`FractionFormToggleFill:${$id}`}
    store={initialState}
    reducer={toggleFillReducer}
  >
    {(state, dispatch) => {
      const onHoverSegment = (index: number) => dispatch(over(index));
      const onLeaveSegment = () => dispatch(out());
      const onToggle = (index: number) => {
        // dispatch local toggle action (removes hoverIndex from local state)
        dispatch(toggleSegmentLocal(index));

        // trigger dispatching redux toggle action
        if (onToggleSegment) {
          onToggleSegment(index);
        }
      };

      return (
        <FractionFormRenderer
          {...props}
          onToggleSegment={!disabled ? onToggle : undefined}
          onHoverSegment={!disabled ? onHoverSegment : undefined}
          onLeaveSegment={!disabled ? onLeaveSegment : undefined}
          hoverIndex={state.hoverIndex}
        />
      );
    }}
  </WithLocalRedux>
);
