import * as React from 'react';
import { CalculatorSpecialKeysBlock } from './CalculatorSpecialKeysBlock';
import { CalculatorNumBlock } from './CalculatorNumBlock';
import { CalculatorOperatorsBlock } from './CalculatorOperatorsBlock';
import { CalculatorLayout } from './types';

import styles from './CalculatorKeyboard.scss';

export type CalculatorKeyboardProps = {
  onKey: (key: string) => void;
  layout: CalculatorLayout;
};

export const CalculatorKeyboard: React.FC<CalculatorKeyboardProps> = ({ onKey, layout }) => {
  return (
    <div className={styles.keyboardWrapper}>
      <div
        className={
          layout === CalculatorLayout.VERTICAL ? styles.layoutFixed : styles.layoutStretched
        }
      >
        <CalculatorSpecialKeysBlock onKey={onKey} layout={layout} />
        <CalculatorNumBlock onKey={onKey} layout={layout} />
        <CalculatorOperatorsBlock onKey={onKey} layout={layout} />
      </div>
    </div>
  );
};
