import * as React from 'react';
import { defaultTo, isEmpty } from 'lodash';
import { type ContextState } from '../../../../gizmo-utils/polymorphic-gizmo';
import {
  type Coords,
  type GeoContent,
  type GeoContentPersistProps,
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type GeoScene,
  type IdCoords,
  type MouseOrTouch,
  POINT,
  type PointHighlight,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { mousePos } from '@bettermarks/importers';
import { getSnapPoint } from '../../snap';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';
import {
  dragCircleAction,
  dragPointAction,
  endDragAction,
  geoMoveReducer,
  initialState,
  leaveCircleAction,
  overCircleAction,
  snapAction,
  startCircleDragAction,
  startPointDragAction,
  stopSnapAction,
} from './geoMoveReducer';
import { addedByUser, offset } from '../helpers';
import { Maybe } from '../../../../utils/maybe';

export type GeoMoveState = GeoInteractiveBaseState & {
  selectedObjectId: string;
  prevPos: IdCoords;
  highlight?: PointHighlight;
  isCircleDrag: boolean;
  // offset of mouse to snap point
  // for point drag (0,0); for circle drag vector from circle center to mousePos
  mousePosOffset: Coords;
};

export interface GeoMoveCallbacks {
  readonly onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export type GeoMoveProps = GeoContent & GeoMoveCallbacks & ContextState & DragScrollableProps;

export const GeoMove: React.FC<GeoMoveProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoMoveReducer}
    componentName={`Move:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const {
        configuration,
        geoContentMap,
        uniqueId,
        isTouch,
        matrix,
        onPersistLocalState,
        scale,
      } = props;
      const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

      const startPointDrag = (id: string) => (evt: MouseOrTouch) =>
        addedByUser(id, props.geoContentMap) && dispatch(startPointDragAction(id));

      const startCircleDrag = (id: string) => (evt: MouseOrTouch) => {
        const mouseP = mousePos(defaultTo<string>(uniqueId, ''), offset(!!isTouch, true))(evt);

        addedByUser(id, props.geoContentMap) &&
          dispatch(startCircleDragAction({ id, matrix, mouseP }));
      };

      const circleDrag = (scene: GeoScene, mouseP: Coords) =>
        addedByUser(state.selectedObjectId, geoContentMap) &&
        dispatch(dragCircleAction({ matrix, scene, scale, configuration, mouseP }));

      const pointDrag = (scene: GeoScene, snapPoint: SnapPoint) =>
        addedByUser(state.selectedObjectId, geoContentMap) &&
        dispatch(dragPointAction({ snapPoint, matrix, configuration }));

      const drag =
        (scene: GeoScene, isTouchStart = false) =>
        (evt: MouseOrTouch) => {
          const mouseP = mousePos(
            defaultTo<string>(uniqueId, ''),
            offset(!!isTouch, isTouchStart || state.isCircleDrag)
          )(evt);

          const snapPoint = getSnapPoint(matrix, scene, scale)(mouseP);

          if (snapPoint) {
            // snapping
            if (addedByUser(snapPoint.id, geoContentMap) && snapPoint.snapObject === POINT) {
              dispatch(snapAction(snapPoint));
            } else {
              dispatch(stopSnapAction());
            }

            // dragging
            if (state.isCircleDrag) {
              circleDrag(scene, mouseP);
            } else {
              pointDrag(scene, snapPoint);
            }
          }
        };

      const endDrag = () => {
        maybeScrollBehaviour.ap(stopScrolling);
        return dispatch(endDragAction({ onPersistLocalState }));
      };

      const hoverCircle = (id: string) => (evt: MouseOrTouch) => {
        if (isEmpty(state.selectedObjectId) || id === state.selectedObjectId) {
          dispatch(overCircleAction(id));
        }
      };

      const onTouchStart = (scene: GeoScene) => (evt: MouseOrTouch) => {
        drag(scene, true);
        maybeScrollBehaviour.ap(startScrolling);
      };

      const leaveCircle = (id: string) => (evt: MouseOrTouch) => dispatch(leaveCircleAction(id));

      const geoContentProps = { ...props, ...state.persistProps };

      return (
        <GeoRenderer
          mode={GeoEditorMode.MOVE}
          highlight={state.highlight}
          onCircleDown={startCircleDrag}
          onCircleOver={hoverCircle}
          onCircleLeave={leaveCircle}
          onMouseMove={drag}
          onMouseUp={endDrag}
          onPointMouseDown={startPointDrag}
          onMouseLeave={endDrag}
          onTouchStart={onTouchStart}
          snapPoints={state.snapPoints}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoMove.displayName = 'GeoMove';
