import * as React from 'react';
import log from 'loglevel';
import { defaultTo, isString, toString } from 'lodash';
import * as remote from 'loglevel-plugin-remote';
import { type TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_SERIES_SETTINGS,
  DialogType,
  ErrorHandleKind,
  type GetParams,
  LoaderState,
  type LobLinkQueryParams,
  ScreenLockType,
  SeriesFlow,
  SeriesMode,
} from '../../../../types';
import { DEFAULT_CONTENT_ROUTE } from '../../../../gizmo-utils/constants';
import { LocaleProvider } from '../../../../gizmo-utils/polymorphic-gizmo';

import { BM_TOOLBOX_SERIES, getCookie } from '../../../../utils/cookie';
import { customJson } from '../../../log/log-format';
import { LoadingScreenLock } from '../../components/ScreenLock/LoadingScreenLock';
import { Loader } from '../../components/Loader';
import { Dialog } from '../Dialog';
import { getBackToNsp } from './quitSeriesPlayerSaga';
import { SeriesPlayerContent, type SeriesPlayerContentProps } from './SeriesPlayerContent';
import { type SeriesPlayerReduxProps } from './SeriesPlayerContainer';
import {
  parseSeriesPlayerQueryString,
  makeNullableGetParams,
} from './parseSeriesPlayerQueryString';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { insertFakeRoute } from './navigation-helpers';
import { hasAssignmentEndDateElapsed } from './utils';

export type ResizePayload = { availableWidth: number; availableHeight: number };
export type ResizeCallback = (payload: ResizePayload) => void;

export type SeriesConfigRouteParams = Record<keyof GetParams, string>;

export type SeriesPlayerProps = SeriesPlayerReduxProps;

interface Props extends SeriesPlayerProps {
  t: TFunction;
}

export function SeriesPlayerComponent(props: Props) {
  const {
    assignmentEndDate,
    onOnline,
    onOffline,
    contentLocale,
    currentExerciseIndex,
    dialog,
    exerciseDisplayStatus,
    hideContent,
    loaderState,
    mode,
    onCloseDialog,
    onNextExercise,
    onPreviousExercise,
    onResize,
    onSwitchExercise,
    openDrawerName,
    screenLockType,
    stepValidationLoaded,
    onQuit,
    quitOnBrowserBack,
    closeSeriesPlayerWhenTestModeAndAfterDueDate,
    onShowCloseConfirmation,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams();
  const parsedQuery = parseSeriesPlayerQueryString(defaultTo(location.search, ''));

  const isLobLinkSeries = React.useMemo(() => {
    return !!params.contentListId;
  }, [params.contentListId]);

  /* eslint-disable complexity */
  function componentDidMount(): void {
    // eslint-disable-next-line no-extra-boolean-cast
    const staticUrl: string = Boolean(parsedQuery.staticUrl)
      ? toString(parsedQuery.staticUrl)
      : DEFAULT_CONTENT_ROUTE;
    const testMode = Boolean(parsedQuery.testMode);
    const previewMode = Boolean(parsedQuery.previewMode);
    const reporting = Boolean(parsedQuery.reporting);
    const calculator = Boolean(parsedQuery.calculator);
    const validationAtEndOfSeries = Boolean(parsedQuery.validationAtEndOfSeries);
    const qaMode = Boolean(parsedQuery.qaMode);
    const nullableGetParams = makeNullableGetParams(parsedQuery);
    const getParams: undefined | (GetParams & Partial<{ assignmentEndDate: string }>) =
      nullableGetParams && {
        ...nullableGetParams,
        ...(nullableGetParams.focusElement === undefined && {
          focusElement: defaultTo(props.focusElement, undefined),
        }),
      };
    log.setLevel(props.logLevel);
    if (getParams?.remoteLogging) {
      /*
        Logger can post to absolute url of the current domain as we failed to
        initialize series, the logger will try to post to default value which is
        not the desired behavior.
      */
      remote.apply(log, { url: '/v2.1/logger', format: customJson });
    }

    if (getParams?.focusElement) {
      focusOnSelectedElement(getParams.focusElement);
    }

    /*
     If seriesFile name and staticUrl query parameters are passed then get the series and
     exercise file from the static http server reachable under static url.

     This is used while locally generating an exercise series from the generator plugin.

     Further explanation:
     https://bettermarks.atlassian.net/browse/BM-55303

     */
    // from the static server
    if (parsedQuery.seriesFile && parsedQuery.staticUrl) {
      const payload = {
        features: {},
        seriesReview: false,
        previewMode: previewMode,
        seriesSettings: {
          ...DEFAULT_SERIES_SETTINGS,
          reporting,
          calculator,
          validationAtEndOfSeries,
          ...(testMode && { flow: SeriesFlow.random }),
        },
        qaMode: qaMode,
        exerciseid: '',
        seriesFile: String(parsedQuery.seriesFile),
        staticUrl: staticUrl,
        seriesOnStaticServer: true,
        navigate: () => navigate(`/series${location.search}`),
      };
      props.onFetchSeriesFromFile(payload);
    }
    // hello, we are in NSP and about to fetch a series
    else if (
      getParams?.seriesId ||
      getParams?.exerciseId
      // TODO: `exerciseId` is capital letter but there could also be an `exerciseid`
    ) {
      props.onFetchSeries(getParams);
    } else if (
      parsedQuery.exerciseid
      // TODO: why here we are cheking for an `exerciseid` lowercase from the query-params?
    ) {
      /*
        now leaving NSP, following fetch is happening only outside the
        NSP context (e.g. for the qa-tool links, the links provided for content
        development, links in image tests)
      */
      const assignmentEndDate: undefined | string =
        isString(parsedQuery.assignmentEndDate) &&
        !Number.isNaN(Date.parse(parsedQuery.assignmentEndDate))
          ? new Date(Date.parse(parsedQuery.assignmentEndDate)).toISOString()
          : undefined;
      props.onFetchRemoteXML({
        ...(assignmentEndDate && { assignmentEndDate }),
        exerciseid: toString(parsedQuery.exerciseid),
        features: {},
        qaMode,
        previewMode,
        seriesReview: false,
        seriesSettings: {
          ...DEFAULT_SERIES_SETTINGS,
          reporting,
          calculator,
          validationAtEndOfSeries,
          ...(testMode && { flow: SeriesFlow.random }),
        },
        staticUrl,
        navigate: () => navigate(`/series?exerciseid=${parsedQuery.exerciseid}`),
      });

      /*
        developing?
        lucky you, it's going to be restored after hot reload for you
      */
    } else if (process.env.NODE_ENV === 'development' && props.currentExerciseIndex === -1) {
      let seriesData = getCookie(BM_TOOLBOX_SERIES);
      if (seriesData) {
        // restore previously loaded exercise after hot reload
        props.onFetchRemoteXML(seriesData as any);
      } else if (localStorage && props.currentExerciseIndex === -1) {
        try {
          // restore previously uploaded XML after hot reload
          seriesData = JSON.parse(localStorage.getItem(BM_TOOLBOX_SERIES) || '');
          seriesData && props.onLoadFiles(seriesData as any);
        } catch (e) {
          localStorage.removeItem(BM_TOOLBOX_SERIES);
        }
      }
    }

    if (isLobLinkSeries) {
      const { contentListId, locale, token } = params;
      const ltiReporting = Boolean(parsedQuery.ltiReporting);

      if (contentListId && locale && token) {
        props.onFetchLobLinkSeries({ contentListId, locale, token });
      }

      if (ltiReporting) {
        props.onSetLtiReporting();
      } else if (
        parsedQuery.userId &&
        parsedQuery.lobId &&
        parsedQuery.bookId &&
        parsedQuery.token
      ) {
        const lobLinkQueryParams: LobLinkQueryParams = {
          userId: toString(parsedQuery.userId),
          bookId: toString(parsedQuery.bookId),
          lobId: toString(parsedQuery.lobId),
          token: toString(parsedQuery.token),
        };
        props.onSetLobLinkReportingParams(lobLinkQueryParams);
      }
    }
  }

  function handlePopState(event: PopStateEvent) {
    handleBackWindowHistory();
    // Handle the back button click or popstate event here
    // For example, you can navigate to a different route
    //navigate('/some-other-route');
  }

  React.useEffect(() => {
    // Add a popstate event listener when the component mounts
    window.addEventListener('popstate', handlePopState);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [mode]);

  React.useEffect(() => {
    componentDidMount();
    insertFakeRoute();
  }, []);

  React.useEffect(() => {
    function handleOnline() {
      onOnline();
    }
    function handleOffline() {
      onOffline();
    }

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [onOnline, onOffline]);

  function handleBackWindowHistory() {
    if (dialog?.type === DialogType.none) {
      if (mode === SeriesMode.review || quitOnBrowserBack) {
        onQuit();
      } else if (
        mode === SeriesMode.test &&
        hasAssignmentEndDateElapsed(
          new Date().toISOString(),
          assignmentEndDate || parsedQuery.assignmentEndDate
        )
      ) {
        closeSeriesPlayerWhenTestModeAndAfterDueDate();
      } else {
        onShowCloseConfirmation(mode);
        window.history.pushState(null, document.title, window.location.href);
      }
    } else if (
      dialog?.type === DialogType.endscreen ||
      (dialog?.type === DialogType.error && dialog?.payload?.kind === ErrorHandleKind.quit)
    ) {
      onQuit();
    } else {
      onCloseDialog?.();
      window.history.pushState(null, document.title, window.location.href);
    }
  }

  /**
   * For QA purpose, we want to be able to select a specific DOM element
   * by its ID directly as an URL query option.
   * This will allow testingbot API to take a screenshot on a specific
   * exercise step / DOM element.
   */
  const focusOnSelectedElement = (elementId: string): void => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  };

  const seriesPlayerContentProps: SeriesPlayerContentProps = {
    currentExerciseIndex,
    exerciseDisplayStatus,
    mode,
    nextExercise: onNextExercise,
    onResize,
    openDrawerName,
    previousExercise: onPreviousExercise,
    stepValidationLoaded,
    switchExercise: onSwitchExercise,
  };

  const errorIsHandled = dialog?.type === DialogType.error;
  return (
    <LocaleProvider contentLocale={contentLocale}>
      <Loader
        loaded={loaderState === LoaderState.success || errorIsHandled}
        hasError={loaderState === LoaderState.error && !errorIsHandled}
        onQuit={getBackToNsp}
      >
        {!(hideContent || errorIsHandled) && <SeriesPlayerContent {...seriesPlayerContentProps} />}
        <Dialog dialog={dialog} onCloseDialog={onCloseDialog} />
      </Loader>
      <LoadingScreenLock show={screenLockType === ScreenLockType.loading} />
    </LocaleProvider>
  );
}

export function SeriesPlayer(props: SeriesPlayerReduxProps) {
  const [t] = useTranslation();

  return <SeriesPlayerComponent t={t} {...props} />;
}
