import { gzip } from 'pako';

export const compress = (value: any): string => {
  return btoa(
    gzip(
      JSON.stringify(value),
      // using windowBits 31 because that is the only value that makes decompress of python's
      // zlib cooperate with pako's gzip. For further reading, see
      //
      // https://stackoverflow.com/a/40985068
      { to: 'string', windowBits: 31 }
    )
  );
};
