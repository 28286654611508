import {
  type ContentColor,
  MAX_ROW_WIDTH,
  type TableContent,
  TableStyle,
} from '@bettermarks/gizmo-types';
import type { RendererCell, RendererRow, TableBorder, TableRendererContent } from './types';
import { exportPadding, getNamedColor } from '@bettermarks/importers';

export const borderStyle = (colorName: ContentColor, strokeWidth: number): string =>
  `${strokeWidth}px ${getNamedColor(colorName)} solid`;

export const hexToRgba = (hex: string | undefined, alpha = 1): string | undefined => {
  if (!hex) {
    return hex;
  }
  // what already is rgba does not need to be converted
  // (e.g. bm-transparent -> transparent -> rgba(0, 0, 0, 0))
  if (hex.startsWith('rgba')) {
    return hex;
  }

  // what comes as shorthand hex needs to be expanded (e.g. "03F" -> "0033FF")
  const normHex = hex.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => r + r + g + g + b + b
  );
  // split in array of 3 two digit hex strings
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(normHex);
  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 255, g: 255, b: 255 };
  return `rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`;
};

export const cellWidth = (
  columnPercents: number[],
  colIdx: number,
  percentWidth: number,
  width: number,
  colSpan = 1
) =>
  ((columnPercents.reduce(
    (acc, p, i) => (i >= colIdx && i <= colIdx + colSpan - 1 ? acc + p : acc),
    0
  ) || 1) /
    percentWidth) *
  width;

/*
method to transform the TableContent (as it is imported and can directly be used for exporting) to
TableRendererContent (that can directly be used for rendering in our different renderer components)
 */
export const createRendererContent = ({
  style,
  width,
  withEqualSign,
  columnPercents,
  cellHeight,
  cellWidth,
  rows,
  childOfHScroll,
  lineDecoration,
  innerVerticalLineWidth,
  innerHorizontalLineWidth,
  outerLineWidth,
  maxRowWidth,
  maxRowWidthFlash,
  percentWidth,
  postDecimals,
  ...props
}: TableContent): TableRendererContent => {
  const hBorder = (color = lineDecoration.color) => borderStyle(color, innerVerticalLineWidth);
  const vBorder = borderStyle(lineDecoration.color, innerHorizontalLineWidth);
  const maxNumOfCols = rows.reduce((acc, r) => Math.max(acc, r.cells.length), 0);

  return {
    style,
    width,
    withEqualSign,
    childOfHScroll,
    columnPercents,
    maxRowWidth,
    maxRowWidthFlash,
    percentWidth,
    postDecimals,
    borderStyle: {
      color: lineDecoration.color,
      lineWidth: outerLineWidth,
    },
    limitAvailableWidthBy: (maxNumOfCols - 1) * innerVerticalLineWidth - 2 * outerLineWidth,
    rows: rows.map((r, i) => ({
      /* eslint-disable-next-line complexity */
      cells: r.cells.map((c, j) => ({
        content: c.content,
        verticalAlign: c.verticalAlign,
        horizontalAlign: c.horizontalAlign,
        childIsTable: c.childIsTable,
        backgroundColor: hexToRgba(
          getNamedColor(
            c.decoration.backgroundColor || r.backgroundDecoration || props.backgroundDecoration
          ) as string,
          c.decoration.backgroundAlpha
        ),
        limitAvailableWidthBy: c.padding ? c.padding.right + c.padding.left : 0,
        ...(style === TableStyle.DEFAULT && i === 0 && { padding: '0 3px' }),
        ...(c.padding && { padding: exportPadding(c.padding) }),
        ...(cellWidth && !c.ignoreCellSize && { width: cellWidth }),
        ...(cellHeight && !c.ignoreCellSize && { height: cellHeight }),
        ...(i > 0 &&
          r.borderTop !== null && {
            borderTop: hBorder(r.borderTop),
          }),
        ...(i < rows.length - 1 &&
          i + c.rowspan < rows.length &&
          r.borderBottom !== null && {
            borderBottom: hBorder(r.borderBottom),
          }),
        ...(j > 0 && {
          borderLeft: vBorder,
        }),
        ...(j < r.cells.length - 1 && {
          borderRight: vBorder,
        }),
        colspan: c.colspan,
        rowspan: c.rowspan,
      })),
    })),
  };
};

export const borderFromStyle = (borderStyle: TableBorder) =>
  `${borderStyle.lineWidth}px ${getNamedColor(borderStyle.color)} solid`;

export const prepareContainerCells = ({
  columnPercents,
  ...table
}: TableContent): TableRendererContent => {
  const rendererContent = createRendererContent(table);

  return !columnPercents
    ? rendererContent
    : {
        ...rendererContent,
        rows: rendererContent.rows.map((r) => ({
          ...r,
          cells: r.cells.map((c, j) => ({
            ...c,
            width: columnPercents[j] * MAX_ROW_WIDTH,
          })),
        })),
      };
};

/**
 * from a list of elements, it returns the list of list of **numberOfElementsPerSublist** length.
 * Except for the last sublist, it contains the rest from 1 to **numberOfElementsPerSublist**
 * @param list the list of elements
 * @param numberOfElementsPerSublist number of element per sublists
 * @returns a list of sublists of **numberOfElementsPerSublist** size
 */
export const splitIntoSublists = <T>(list: T[], numberOfElementsPerSublist: number): T[][] => {
  const { total } = list.reduce<{ total: T[][]; buffer: T[] }>(
    (acc, col, idx) => {
      const isLastElement = idx === list.length - 1;
      if (acc.buffer.length < numberOfElementsPerSublist) {
        return isLastElement
          ? { buffer: acc.buffer, total: [...acc.total, [...acc.buffer, col]] }
          : { buffer: [...acc.buffer, col], total: acc.total };
      } else {
        return isLastElement
          ? { buffer: acc.buffer, total: [...acc.total, acc.buffer, [col]] }
          : { buffer: [col], total: [...acc.total, acc.buffer] };
      }
    },
    { total: [], buffer: [] }
  );
  return total;
};

/**
 * Base on the available width, this functions decides how many
 * colums can be display on each lines:
 *
 * - if all can fit in one line, it returns all column on 1 line
 * - if some col but not all can fit, it return a list of columns to be displayed
 *   on each line
 * @param availableWidth the available width for columns
 * @param columns all columns to be displayed
 * @returns a list of columns (a list of list of Renderer row)
 */
export const columnsFromAvailableWidth = (
  availableWidth: number,
  columns: RendererRow[],
  maxRowWidth: number
): RendererRow[][] => {
  if (availableWidth >= maxRowWidth) {
    return [columns];
  } else {
    const totalNumberOfColumns = columns.length;
    const columnWidth = maxRowWidth / totalNumberOfColumns;
    const maxNumberOfColumnsPerRow = Math.floor(availableWidth / columnWidth);
    return splitIntoSublists(columns, maxNumberOfColumnsPerRow);
  }
};

export const getMaxRowWidth = (columns: RendererRow[]) => {
  let maxRowWidth: number = MAX_ROW_WIDTH;
  const contentCellWidth = columns.every(({ cells }) => cells.every(({ width }) => width));
  if (contentCellWidth && columns[0]?.cells[0]?.width) {
    maxRowWidth = columns[0].cells[0].width * columns.length;
  }
  return maxRowWidth;
};

export const transposeRows = <T>(rows: T[], rowsCount: number): T[] => {
  const indexMap = rows.map((_, idx) => idx);
  indexMap.sort((prev, next) => (prev % rowsCount) - (next % rowsCount));
  return indexMap.map((index) => rows[index]);
};

/**
 * convert a list of RendererCells to a list of RendererRow,
 * representing columns to display
 * @param cells an array of cells
 * @param numberOfRows cells per columns
 * @returns a Renderer Row list
 */
export const contructRowsFromCells = (cells: RendererCell[], numberOfRows: number): RendererRow[] =>
  cells.reduce<RendererRow[]>((rows, cell, idx) => {
    if (idx % numberOfRows === 0) {
      return [...rows, { cells: [cell] }];
    } else {
      const last = rows.pop();
      const updated = last ? { cells: [...last.cells, cell] } : { cells: [cell] };
      return [...rows, updated];
    }
  }, []);
