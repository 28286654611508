export const DATA_ATTRIBUTE_GIZMO_ID = 'data-gizmo-id';
export const DATA_ATTRIBUTE_CONTENT_ID = 'data-content-id';

export const INPUT_FOCUS = 'focus' as const;

export const MATH = 'math' as const;
export const SEMANTICS = 'semantics' as const;

export const ANNOTATION_XML = 'annotation-xml' as const;
export const SPECIAL = 'special' as const;
export const PLACEHOLDER = 'placeholder' as const;
export const DATA = 'data' as const;

export const ID = 'id' as const;
export const RENDER_STYLE = 'render-style' as const;
export const INTERACTION_TYPE = 'interaction-type' as const;
export const BINDING = 'binding' as const;
export const DEFAULT_TEXT = 'default-text' as const;
export const DIRECTION = 'direction' as const;
export const FLAVOUR = 'flavour' as const;
export const SELECTED = 'selected' as const;
export const GAP = 'gap' as const;
export const HALIGN = 'hAlign' as const;
export const HIDE_CONTENT = 'hideContent' as const;
export const NO_SCALE = 'noScale' as const;
export const KEY = 'key' as const;
export const LAYOUT = 'layout' as const;
export const REF_ID = 'ref-id' as const;
export const REPLACEMENTS = 'replacements' as const;
export const SELECTED_MODE = 'selected-mode' as const;
export const TOOL_SET = 'tool-set' as const;
export const MIN_INPUT_WIDTH = 'minInputWidth' as const;
export const MAX_INPUT_LENGTH = 'maxInputLength' as const;
export const POSX = 'posX' as const;
export const POSY = 'posY' as const;
export const VALIGN = 'vAlign' as const;
export const SKIP_ROUNDTRIP = 'skip-roundtrip' as const;

export const CONFIGURATION = 'configuration' as const;
export const DECORATION = 'decoration' as const;
export const DYNAMIC_DECORATION = 'dynamicDecoration' as const;
export const SET = 'set' as const;

export const LAYER = 'layer' as const;
export const AXIS = 'axis' as const;
export const ADVANCEDAXIS = 'advancedAxis' as const;

export const IS_REMOVED_AT_ACTIVATION = 'isRemovedAtActivation' as const;
export const BACKGROUND = 'background' as const;

// This is required to load KEM/FEMs independently and when no staticUrl is provided.
export const DEFAULT_CONTENT_ROUTE = 'https://content.bettermarks.com';
export const DEFAULT_MEDIA_ROUTE = 'https://media-acc.bettermarks.com';

// Used for a proper document.getElementById within the code.
export const CONTENT_ID = 'content';

/**
 * The portal to use for displaying drag sources inside the toolbar drawer.
 */
export const DRAWER_PORTAL_ID = 'drawerPortal';
