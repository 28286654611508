import { DEFAULT_HLINE_CONTENT, type Importer, toInt, zeroNaN } from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../gizmo-utils/decoration';

export const importHLine: Importer = (preContent, xml, _) => {
  const importedCount = Math.max(
    zeroNaN(toInt(xml.getPath(['mrow', 'configuration', 'count']).text)),
    1
  );

  return {
    ...DEFAULT_HLINE_CONTENT,
    ...preContent,
    count: Math.min(importedCount, 2), // not allowing count > 2
    ...(xml.findChildTag('mrow').hasAttribute('decoration') &&
      parseDecoString<'lineStyle'>(xml.findChildTag('mrow').attribute('decoration')).object),
  };
};
