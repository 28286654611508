import * as React from 'react';
import styled from 'styled-components';
import { Bar, StyledBox as Box, Layout } from '@seriesplayer/common-ui';
import { DialogHeader } from '../../../../../components';
import { ExerciseNavigationButton } from '../../../components/ExerciseNavigationButton';
import { type ExerciseDisplayStatus } from '../../../../../types';
import { Appearance } from '../../../components/ExerciseNavigationButton/ExerciseNavigationButton';

import { BUTTON_WRAPPER_WIDTH } from '../../../constants';

export interface ExerciseNavigationDispatchProps {
  onCancel: () => void;
  onSwitchExercise: (index: number) => void;
}

export interface ExerciseNavigationStateProps {
  exerciseStatuses: ExerciseDisplayStatus[];
  currentExerciseIndex: number;
  availableWidth: number;
}

export interface ExerciseNavigationOwnProps {
  title: string;
}

type ExerciseNavigationProps = ExerciseNavigationDispatchProps &
  ExerciseNavigationStateProps &
  ExerciseNavigationOwnProps;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;

  .empty-div {
    width: ${BUTTON_WRAPPER_WIDTH}px;
  }
`;

/**
 * ExerciseNavigation Component
 * Used to render Exercise Navigation dialog header, content and to handle events on it.
 * Here we are reusing Layout component for layouting and
 * Box Component for stretching the Dialog content
 * Wrapping inside a Dialog for styling
 */

export const ExerciseNavigation: React.FC<ExerciseNavigationProps> = ({
  availableWidth,
  currentExerciseIndex,
  exerciseStatuses,
  onCancel,
  onSwitchExercise,
  title,
}) => {
  // number of iocns that can be present in one row
  const iconsPerRow = Math.floor(availableWidth / BUTTON_WRAPPER_WIDTH);

  // icons in last line
  const iconsLastRow = exerciseStatuses.length % iconsPerRow;

  // empty place holders
  const emptyIcons = iconsLastRow === 0 ? 0 : iconsPerRow - iconsLastRow;

  const navigationButtons = [
    ...exerciseStatuses.map((status, i) => (
      <ExerciseNavigationButton
        exerciseIndex={i}
        exerciseDisplayStatus={status}
        switchExercise={onSwitchExercise}
        selected={currentExerciseIndex === i}
        appearance={Appearance.mobile}
        key={i}
      />
    )),
    ...Array.from({ length: emptyIcons }).map((_, i) => (
      <div className="empty-div" key={`empty${i}`} />
    )),
  ];

  return (
    <Layout vertical>
      <DialogHeader
        title={title}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
        }}
      />
      <Bar />
      <Box stretch>
        <Content>{navigationButtons}</Content>
      </Box>
    </Layout>
  );
};

ExerciseNavigation.displayName = 'ExerciseNavigation';
