/**
 * @module cursorRules
 *
 * These rules handle the different cases of how a cursor moves into and inside
 * a container MathML element. A container can be for example an <mfrac> or an
 * <msub>. Especially the <msub> or other containers having a "base"
 * (BasefulElement) are handled very differently. You can take a look here:
 * {@link http://wiki.bm.loc/index.php/Virtual_keyboard#Behaviour_3} to get an
 * overview over the different rules.
 *
 * To tackle these rules in a generic way we described a container as a
 * dictionary of buckets. A bucket is one <mrow> inside our container. For
 * example the <mfrac> has two buckets: numerator and denominator. The
 * <msubsup> has three buckets: base, subscript and superscript. Finally the
 * <mabs> only has one bucket called value.
 *
 *  MFrac = {    // container
 *    $: 'mfrac',
 *    denominator: { $: 'mrow' }, // bucket
 *    numerator: { $: 'mrow' }    // bucket
 *  };
 *
 * Because of the preprocessing (and it's always assumed here, that the formula
 * content is presented in a nice preprocessed way) all buckets are indeed
 * <mrows>, all <mn> tags are splitted in single digits and finally all "bases"
 * of <msub>/<msup> and <msubsup> contain only a single-element-mrow.
 *
 * The movement of the cursor inside a bucket is handled in {@link module:FormulaReducer.move}.
 * Here we only handle the cases when we hit the beginning or the end of a
 * bucket or if we enter a container (i.e. enter one of it's buckets).
 *
 * One important note: The cursor is (and should be) already removed in the
 * outside function calls! The HandleContainerFunction only puts the cursor
 * at it's new place. This has some implications regarding indices of the
 * next or previous element.
 *
 */

import { get, set } from 'lodash/fp';
import log from 'loglevel';
import { CURSOR, getContainerInfo, lastPathElements, MROW } from '@bettermarks/importers';
import {
  $MSUP,
  type BasefulElement,
  Direction,
  type FormulaContent,
  hasBase,
  isMAbs,
  isMO,
  isMSup,
  isPureMathContent,
  type MAbs,
  type MathContent,
  type MRow,
  type Path,
  ROUND_CLOSE,
} from '@bettermarks/gizmo-types';
import { type HandleContainerFunction } from './types';
import { unwrapContentIfNeeded } from '@bettermarks/importers';

/**
 *  Enter the given bucket from the passed direction. It's a higher order
 *  function, that returns a {@link HandleContainerFunction}. The latter will
 *  place the cursor either at the beginning of the end of the bucket.
 *  @param bucketName the name of the bucket to enter.
 */
export const enterBucket =
  (bucketName: string): HandleContainerFunction =>
  (input, containerPath, direction) => {
    const bucketPath = [...containerPath, bucketName, 'children'];
    const bucket: MathContent[] = get(bucketPath, input);

    return set(
      bucketPath,
      direction === Direction.Right ? [CURSOR, ...bucket] : [...bucket, CURSOR],
      input
    );
  };

/**
 * Leave the base of a baseful element (like <msub>). Per definition (after preprocessing) a base
 * may only contain a single element. Thus the elements in front of the base are either pulled in or
 * — if it's an operator or no element is available — the exponent is flattened into the parent.
 *
 * x[|]^[y] --> [x]^[|y]
 * or
 * x[|]^[y] --> |[x]^[y]
 * or
 * [|]^[y] --> |y
 * or
 * |x|[|]^[y] --> [|x||]^[y]
 * @param input our math content
 * @param containerPath the path of the container (NOT the cursor path).
 * @param direction Direction of cursor movement.
 * @logStrategy loglevel.LoggingMethod
 */
const leaveBasefulBase: HandleContainerFunction = (
  input,
  containerPath,
  _,
  logStrategy = log.warn
) => {
  const { container, pos, parent, parentPath } = getContainerInfo(input, containerPath);

  // just switch it with the item in front of the base
  const prev = parent[pos - 1];
  if (!hasBase(container)) {
    logStrategy({
      message:
        'unexpected "MContent" node, will not process it further. Expected "MContent" to be of type: "MSUB" | "MSUP"',
      extra: {
        container: JSON.stringify(container),
        thrownBy: 'leaveBasefulBase',
      },
    });
    return input;
  }
  // as long as `MSubSup` is not (&) `Interactable` the following stands
  const exponent = container.$ === $MSUP ? container.superscript : container.subscript;

  if (pos !== 0) {
    if (isMO(prev) && prev.text !== ROUND_CLOSE) {
      // jump over the operator and replace the msub/msup with it's sub- or super script
      return set(
        parentPath,
        [...parent.slice(0, pos - 1), CURSOR, prev, ...exponent.children, ...parent.slice(pos + 1)],
        input
      );
    } else if (isMAbs(prev)) {
      const modified: MAbs = {
        ...prev,
        value: MROW(...prev.value.children, CURSOR),
      };
      return set(
        parentPath,
        [
          ...parent.slice(0, pos - 1),
          {
            ...container,
            base: MROW(modified),
          },
          ...parent.slice(pos + 1),
        ],
        input
      );
    } else if (hasBase(prev)) {
      const prevExponent = isMSup(prev)
        ? { superscript: MROW(...prev.superscript.children, CURSOR) }
        : { subscript: MROW(...prev.subscript.children, CURSOR) };
      return set(
        parentPath,
        [
          ...parent.slice(0, pos - 1),
          {
            ...prev,
            ...prevExponent,
          },
          ...exponent.children,
          ...parent.slice(pos + 1),
        ],
        input
      );
    }

    return set(
      parentPath,
      [
        ...parent.slice(0, pos - 1),
        CURSOR,
        {
          ...container,
          base: MROW(prev),
        },
        ...parent.slice(pos + 1),
      ],
      input
    );
  }

  // just replace the baseful element
  return set(parentPath, [CURSOR, ...exponent.children, ...parent.slice(pos + 1)], input);
};

/**
 * This function is called, when we enter the base (either from exponent or
 * from the left side).  Per definition (after preprocessing) a base may only
 * contain a single element. This means, the current element in the base is
 * pushed out of the base and placed in front of the container.
 *
 * [x]^[|y] --> x[|]^[y]
 * [x]^[|] --> x|
 *
 * @param input our math content
 * @param containerPath the path of the container (NOT the cursor path).
 * @param direction Direction of cursor movement.
 */
export const enterBase: HandleContainerFunction = (input, containerPath, direction) => {
  const { container, pos, parent, parentPath } = getContainerInfo<BasefulElement>(
    input,
    containerPath
  );
  const base = container.base;

  const exponent = container.$ === $MSUP ? container.superscript : container.subscript;

  if (exponent.children.length > 0) {
    if (isMAbs(base.children[0]) && direction === Direction.Right) {
      const baseMAbs = base.children[0];
      const modified: MAbs = {
        ...baseMAbs,
        value: MROW(CURSOR, ...baseMAbs.value.children),
      };
      return set(
        parentPath,
        [
          ...parent.slice(0, pos),
          {
            ...container,
            base: MROW(modified),
          },
          ...parent.slice(pos + 1),
        ],
        input
      );
    }

    return set(
      parentPath,
      [
        ...parent.slice(0, pos),
        base.children[0], // old base is kicked out!
        {
          ...container,
          base: {
            ...base,
            children: [CURSOR], // there can only be one!
          },
        },
        ...parent.slice(pos + 1),
      ],
      input
    );
  }

  return set(
    parentPath,
    [
      ...parent.slice(0, pos),
      base.children[0], // only keep the base (remove container)
      CURSOR,
      ...parent.slice(pos + 1),
    ],
    input
  );
};

/**
 * Leave the container in one direction — place the cursor either on the left
 * or right side of the container
 *
 * @param input our math content
 * @param containerPath the path of the container (NOT the cursor path).
 * @param direction Direction of cursor movement.
 */
const leaveContainer: HandleContainerFunction = (input, containerPath, direction) => {
  const { container, pos, parent, parentPath } = getContainerInfo(input, containerPath);

  return set(
    parentPath,
    [
      ...parent.slice(0, pos),
      ...(direction === Direction.Right ? [container, CURSOR] : [CURSOR, container]),
      ...parent.slice(pos + 1),
    ],
    input
  );
};

/**
 * [x]^[y|] --> [x]^[y]|
 * [x]^[|] --> x|
 */

const leaveExponent: HandleContainerFunction = (
  input,
  containerPath,
  direction,
  logStrategy = log.warn
) => {
  const { container, pos, parent, parentPath } = getContainerInfo(input, containerPath);

  if (!hasBase(container)) {
    logStrategy({
      message:
        'unexpected "MContent" node, will not process it further. Expected "MContent" to be of type: "MSUB" | "MSUP"',
      extra: {
        container: JSON.stringify(container),
        thrownBy: 'leaveExponent',
      },
    });
    return input;
  }

  const exponent: MRow = container.$ === $MSUP ? container.superscript : container.subscript;

  if (exponent.children.length === 0) {
    return set(
      parentPath,
      [...parent.slice(0, pos), container.base.children[0], CURSOR, ...parent.slice(pos + 1)],
      input
    );
  }
  return leaveContainer(input, containerPath, direction);
};

/**
 * Clear the base of the current element and put it in front of the container.
 * Then call the passed container handler (e.g. enter base). Look at how it's
 * used in the [container handler dictionary]{@link leaveBucketFunction}.
 *
 * @param input our math content
 * @param containerPath the path of the container (NOT the cursor path).
 * @param direction Direction of cursor movement.
 */
const clearBaseAndThen =
  (func: HandleContainerFunction): HandleContainerFunction =>
  (input, containerPath, direction) => {
    const { container, pos, parent, parentPath } = getContainerInfo<BasefulElement>(
      input,
      containerPath
    );
    const base = container.base;
    const prev = parent[pos - 1];
    const exponent = container.$ === $MSUP ? container.superscript : container.subscript;

    if (pos === 0 || (isMO(prev) && prev.text !== ROUND_CLOSE) || hasBase(prev)) {
      // remove msub/msup (and replace with it's sub/super script
      return set(
        parentPath,
        [
          ...parent.slice(0, pos),
          exponent.children[0],
          CURSOR,
          ...exponent.children.slice(1),
          ...parent.slice(pos + 1),
        ],
        input
      );
    }

    return func(
      set(
        parentPath,
        [
          ...parent.slice(0, pos - 1),
          {
            ...container,
            base: {
              ...base,
              children: [prev],
            },
          },
          ...parent.slice(pos + 1),
        ],
        input
      ),
      [...containerPath.slice(0, -1), pos - 1],
      direction
    );
  };

/**
 * Here we collect all rules for moving the cursor inside the container (or for
 * leaving the container). The rule is selected according to:
 * - which container i'm in
 * - what is the current bucket which contained the cursor
 * - which direction i want to move
 */
const leaveBucketFunction: Record<string, HandleContainerFunction> = {
  'msub:base:left': leaveBasefulBase,
  'msub:base:right': clearBaseAndThen(enterBucket('subscript')),
  'msup:base:left': leaveBasefulBase,
  'msup:base:right': clearBaseAndThen(enterBucket('superscript')),
  'msup:superscript:left': enterBase,
  'msup:superscript:right': leaveExponent,
  'msub:subscript:left': enterBase,
  'msub:subscript:right': leaveExponent,
  'mfrac:denominator:left': enterBucket('numerator'),
  'mfrac:denominator:right': leaveContainer,
  'mfrac:numerator:left': leaveContainer,
  'mfrac:numerator:right': enterBucket('denominator'),
  'mroot:index:left': leaveContainer,
  'mroot:index:right': enterBucket('radicand'),
  'mroot:radicand:left': enterBucket('index'),
  'mroot:radicand:right': leaveContainer,
  'msqrt:radicand:left': leaveContainer,
  'msqrt:radicand:right': leaveContainer,
  'mabs:value:left': leaveContainer,
  'mabs:value:right': leaveContainer,
};

/**
 * This function is applied after we decided, we leave a bucket. It selects a
 * specific function from {@link leaveBucketFunction} and applied it to the
 * math content.
 *
 * @param input our math content
 * @param containerPath the path of the container (NOT the cursor path).
 * @param direction Direction of cursor movement.
 * @param bucketName The name of the bucket to leave (e.g. base, denominator or subscript)
 */
const leaveBucket = (
  input: MathContent,
  containerPath: Path,
  direction: Direction,
  bucketName: string
): MathContent => {
  const { container } = getContainerInfo(input, containerPath);

  if (isPureMathContent(container)) {
    const dir = direction === Direction.Left ? 'left' : 'right';
    const key = `${container.$}:${bucketName}:${dir}`;

    if (key in leaveBucketFunction) {
      return leaveBucketFunction[key](input, containerPath, direction);
    }
  }

  // fallback if the container is missing in the registry
  return input;
};

/**
 * Here we place the cursor at a new position inside the current bucket (i.e. mrow).
 * We also decide whether the cursor should jump to the left/right sibling. This
 * information is needed by the {@link formulaSaga}.
 *
 * @param input our math content.
 * @param pos the current cursor position.
 * @param bucketPath the path of the bucket.
 * @param direction direction of cursor movement.
 * @param shouldCheckIfCursorJumpIsNeeded needed by the `formulaSaga`
 * @param formula the formula that has `input` as its `content`
 */
const moveInsideBucket = (
  input: MathContent,
  pos: number,
  bucketPath: Path,
  direction: Direction,
  shouldCheckIfCursorJumpIsNeeded: boolean,
  formula: FormulaContent
): FormulaContent => {
  const newPos = direction === Direction.Left ? Math.max(0, pos - 1) : pos + 1;
  const bucket: MathContent[] = get(bucketPath, input);

  const shouldMoveCursorToSibling =
    shouldCheckIfCursorJumpIsNeeded &&
    ((direction === Direction.Left && formula.leftSibling && pos === 0) ||
      (direction === Direction.Right && formula.rightSibling && pos === bucket.length));

  const newContent = set(
    bucketPath,
    [...bucket.slice(0, newPos), CURSOR, ...bucket.slice(newPos)],
    input
  );

  return {
    ...formula,
    ...(shouldMoveCursorToSibling && { shouldMoveCursorToSibling }),
    content: unwrapContentIfNeeded(newContent),
  };
};

/**
 * Move the cursor in the given direction. This function is only called when we
 * are already inside a container — not when we are about to enter a container.
 * The latter is handled by the {@link enterContainer} function below.
 * Which function is called is determined in FormulaReducer.ts in the
 * [move function]{@link @module:FormulaReducer.move}.
 *
 * @param {MathContent} input our math content
 * @param {Path} cursorPath the path of the cursor inside the math content
 * @param {Direction} direction the direction of cursor movement
 */
export const moveCursor = (
  input: MathContent,
  cursorPath: Path,
  direction: Direction,
  formula: FormulaContent
): FormulaContent => {
  if (cursorPath.length === 2) {
    // top-level mrow
    const [bucketPath, pos] = cursorPath;

    return moveInsideBucket(input, pos as number, [bucketPath], direction, true, formula);
  }

  const [containerPos, bucketName, , bucketPos, ...parentPath] = lastPathElements(cursorPath, 4);

  const { length: len }: MathContent[] = get(cursorPath.slice(0, -1), input);

  // move from the edge of the mrow outside ("leave bucket")
  if (
    (direction === Direction.Left && bucketPos === 0) ||
    (direction === Direction.Right && bucketPos === len)
  ) {
    return {
      ...formula,
      content: unwrapContentIfNeeded(
        leaveBucket(input, [...parentPath, containerPos], direction, bucketName as string)
      ),
    };
  }

  // move within the mrow
  return moveInsideBucket(
    input,
    bucketPos as number,
    cursorPath.slice(0, -1),
    direction,
    false,
    formula
  );
};
