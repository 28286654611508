/**
 * returns a postive angle in degrees.
 * @param angleInDegrees
 */
export const positiveAngle = (angleInDegrees: number): number =>
  angleInDegrees < 0 ? 360 + (angleInDegrees % 360) : angleInDegrees;

/**
 * Converts an angle in degrees to its corresponding value in radians
 *
 * @param {number} angleInDegrees
 * @returns {number}
 */
export const degToRad = (angleInDegrees: number): number => (angleInDegrees * Math.PI) / 180;

/**
 * Converts an angle in radians to its corresponding value in degrees
 *
 * @param {number} angleInRadians
 * @returns {number}
 */
export const radToDeg = (angleInRadians: number): number => (angleInRadians * 180) / Math.PI;

/**
 * An extension to the Math.atan2 function returning an angle
 * between 0 and 2PI in every case avoiding negative angles.
 * @param {number} y .. the y part
 * @param {number} x .. the x part
 * @returns {number} .. the angle in radians in [0, 2PI]
 */
export const atan2 = (y: number, x: number, atan = Math.atan2(y, x)): number =>
  atan < 0 ? 2 * Math.PI + atan : atan;
