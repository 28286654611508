import { type FontWeight } from '@bettermarks/bm-font';
import styles from './formulaBase.scss';
import { numberFromStyles } from '@bettermarks/gizmo-types';

export const DEFAULT_FONT_WEIGHT = styles.DEFAULT_FONT_WEIGHT.toString() as FontWeight;

export const INTERACTIVE_FORMULA_SCALING_STEP = 0;

export const RADIX_CONTENT_SPACING = numberFromStyles(styles.RADIX_CONTENT_SPACING);
export const RADIX_PADDING_BOTTOM = numberFromStyles(styles.RADIX_PADDING_BOTTOM);
export const SCRIPT_VERTICAL_MARGIN = numberFromStyles(styles.SCRIPT_VERTICAL_MARGIN);

// Unicode constants
export const ARROW_RIGHT = '\u2192';
export const CIRCUMFLEX = '\u0302';
export const CURLY_BOTTOM = '\u23DF';
export const CURLY_TOP = '\u23DE';
export const OVERBAR_MO = '\u203e';
export const SEGMENT_DE = '\u00af';
export const SEGMENT = '\uE199';
// For non-unicode shape
export const CURVE_BOTTOM: 'curve_bottom' = 'curve_bottom';
export const CURVE_TOP: 'curve_top' = 'curve_top';

export enum MexpansionElements {
  denFactor = 'denFactor',
  lhsFraction = 'lhsFraction',
  numFactor = 'numFactor',
  operator = 'operator',
  rhsFraction = 'rhsFraction',
}

export const PLACEHOLDER_LABEL = 'placeholderLabel';
