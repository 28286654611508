import * as React from 'react';
import { defaultTo, get } from 'lodash';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  CursorPositionOffset,
  GeoEditorMode,
  type GeoScene,
  INTERVAL_HI,
  INTERVAL_LO,
  type IntervalConfiguration,
  LabelingObjectRefType,
  type LabelStepperProps,
  type MouseOrTouch,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import {
  addIntervalAction,
  dragAction,
  geoIntervalReducer,
  initialState,
  limitsAction,
  snapAction,
  startDragAction,
} from './geoIntervalReducer';
import { type GeoProps } from '../../Geo';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import {
  EPS,
  mousePos,
  PIXEL_PER_XTICK,
  SVG_BORDER_EXTENSION,
  transformX,
  transformY,
} from '@bettermarks/importers';
import { getSnapPoint } from '../../snap';
import { limitPickersConfirmed } from './helpers';
import constantsFromStyles from '../../constantsFromStyles.scss';
import { Maybe } from '../../../../utils/maybe';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export const GeoInterval: React.FC<GeoProps & DragScrollableProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoIntervalReducer}
    componentName={`Interval:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { configuration, matrix, onPersistLocalState, scale, uniqueId } = props;
      const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

      const [toWorldX, toScreenY] = [
        // running into validation issues when not rounding x to validator precision
        (x: number) => Math.round(transformX(matrix, false)(x) / EPS) * EPS,
        transformY(matrix),
      ];
      const intervalConfiguration = configuration.toolConfiguration
        .intervalConfiguration as IntervalConfiguration;

      // is one of our labelStepper dialogs active?
      const labelStepperActive = () => state.limitPickerOpen.reduce((a, v) => a || v);

      const snap =
        (scene: GeoScene, isTouchStart = false) =>
        (evt: MouseOrTouch) => {
          const mouseP = mousePos(defaultTo<string>(uniqueId, ''), CursorPositionOffset.NONE)(evt);
          // for the interval tool we have our own geo scene (axis ticks + additional points =>
          // collected in the importer and added to intervalConfiguration)
          const customScene = intervalConfiguration ? intervalConfiguration.scene : scene;
          // regardless where you are in y-position you want to snap to your points at the x-axis
          const snapPoint: SnapPoint | null = getSnapPoint(
            matrix,
            customScene,
            scale
          )({ ...mouseP, y: toScreenY(0) });

          if (snapPoint) {
            dispatch(snapAction(snapPoint));
            const snapPos = toWorldX(snapPoint.x);
            if (state.dragging) {
              dispatch(
                dragAction({
                  configuration: intervalConfiguration,
                  matrix,
                  mouseP,
                  snapPos,
                })
              );
            } else if (isTouchStart && !labelStepperActive()) {
              dispatch(startDragAction(snapPos));
              maybeScrollBehaviour.ap(startScrolling);
            }
          }
        };

      const down = () => {
        state.snapPoints.length > 0 &&
          !labelStepperActive() &&
          dispatch(startDragAction(toWorldX(state.snapPoints[0].x)));
      };

      const up = () => {
        maybeScrollBehaviour.ap(stopScrolling);
        return (
          state.dragging &&
          !labelStepperActive() &&
          (intervalConfiguration.showDialog
            ? dispatch(
                limitsAction({
                  limitIndex: undefined,
                  index: undefined,
                  confirmed: false,
                })
              )
            : dispatch(
                addIntervalAction({
                  configuration: intervalConfiguration,
                  onPersistLocalState,
                })
              ))
        );
      };

      const touchStart = (scene: GeoScene) => (evt: MouseOrTouch) => snap(scene, true)(evt);

      const onSelectLimit = (limitIndex: number, index: number) =>
        dispatch(limitsAction({ limitIndex, index, confirmed: false }));

      const onConfirmLimit = (limitIndex: number, index: number) =>
        dispatch(limitsAction({ limitIndex, index, confirmed: true }));

      const geoContentProps = {
        ...props,
        ...state.persistProps,
        // ensure that the pickers are always visible when displayed below the x-axis
        totalHeight: Math.max(110, props.totalHeight),
      };

      const [limitsHi, limitsLo] = [INTERVAL_HI, INTERVAL_LO].map((s) =>
        get(intervalConfiguration, `${s}.pickerLabels`, [])
      );

      // add interval has dispatched here without a specific action ...
      // just after confirming ALL limit pickers
      if (intervalConfiguration.showDialog && limitPickersConfirmed(state.limitPickerOpen)) {
        dispatch(
          addIntervalAction({
            configuration: intervalConfiguration,
            onPersistLocalState,
          })
        );
      }

      const pickerWidth = parseInt(constantsFromStyles.PICKER_MIN_WIDTH, 10);
      // add border extensions with respect to the border
      const borderThreshold = (i: number) =>
        (pickerWidth + SVG_BORDER_EXTENSION.left * scale + SVG_BORDER_EXTENSION.right * scale * i) /
        (2 * PIXEL_PER_XTICK * scale * configuration.tickValueInterval.x);

      // we need to take the default picker width and subtract the length of our interval
      // for calculating the shift of the picker noses (customNoseOffset)
      const screenPickerWidthOverlap =
        Math.max(
          pickerWidth -
            (state.prevInterval.max - state.prevInterval.min) *
              PIXEL_PER_XTICK *
              scale *
              configuration.tickValueInterval.x,
          0
        ) + 3;

      let noseUp = false;

      return (
        <GeoRenderer
          {...geoContentProps}
          mode={GeoEditorMode.INTERVAL}
          onMouseDown={down}
          onMouseMove={snap}
          onMouseUp={up}
          onMouseLeave={up}
          onTouchStart={touchStart}
          previewObjects={{
            intervals: !isNaN(state.prevInterval.min) ? [state.prevInterval] : [],
            lines: state.helperLine.visible ? [state.helperLine] : [],
          }}
          labelSteppers={
            intervalConfiguration.showDialog &&
            limitsLo &&
            limitsHi &&
            [
              [limitsLo, state.prevInterval.min],
              [limitsHi, state.prevInterval.max],
            ].map((el, i): LabelStepperProps => {
              // we need to distinguish whether we are at the left, at the right or at any border
              // customNoseOffset shall only be calculated when we are at no border
              // (regardless whether we are at the min/max with the left/right border)
              const nearLeftBorder =
                scale * (el[1] - configuration.display.xMin) <= borderThreshold(i);
              const nearRightBorder =
                scale * (configuration.display.xMax - el[1]) <= borderThreshold(i);
              const nearAnyBorder = nearLeftBorder || nearRightBorder;

              // check whether one and a half pickers can be put next to each other
              const notEnoughSpace = (min: number, max: number, i: number) =>
                scale * (max - min) <= 3 * borderThreshold(i);

              // if necessary display the left border with nose up
              noseUp =
                noseUp ||
                (i === 0
                  ? nearLeftBorder &&
                    notEnoughSpace(configuration.display.xMin, state.prevInterval.max, i)
                  : nearRightBorder &&
                    notEnoughSpace(state.prevInterval.min, configuration.display.xMax, i));

              return {
                active: state.limitPickerOpen[i] as boolean,
                initialIndex: 0,
                list: el[0],
                customNoseOffset:
                  -0.5 * screenPickerWidthOverlap * Math.pow(-1, i % 2) * Number(!nearAnyBorder),
                noseUp: i === 0 ? false : noseUp,
                labelingObjectRef: {
                  type: LabelingObjectRefType.coords,
                  coords: { x: el[1], y: 0 },
                },
                onConfirm: (index: number) => onConfirmLimit(i, index),
                onSelect: (index: number) => onSelectLimit(i, index),
              };
            })
          }
        />
      );
    }}
  </WithLocalRedux>
);

GeoInterval.displayName = 'GeoInterval';
