import {
  type AbsoluteLayoutPayload,
  absoluteLayoutReducer,
} from '../../gizmos/layout-container/absoluteLayoutReducer';
import { type HelloGizmoActionPayload, helloGizmoReducer } from '../../gizmos/_template/redux';
import { type FormulaActionPayload, formulaReducer } from '../../gizmos/formula/redux';
import {
  type FractionFormActionPayload,
  fractionFormReducer,
} from '../../gizmos/fraction-form/FractionForm';
import {
  type FunctionPlotterActionPayload,
  functionPlotterReducer,
} from '../../gizmos/function-plotter/redux';
import { type GeoPersistLocalStatePayload, geoReducer } from '../../gizmos/geo/redux';
import {
  type MultipleChoiceActionPayload,
  multipleChoiceDropdownReducer,
} from '../../gizmos/multiple-choice-drop-down/redux';
import {
  type MultipleChoiceMultiActionPayload,
  multipleChoiceMultiReducer,
} from '../../gizmos/multiple-choice-multi/redux';
import {
  type MultipleChoiceSingleActionPayload,
  multipleChoiceSingleReducer,
} from '../../gizmos/multiple-choice-single/redux';
import {
  type SetDropTargetPayload,
  setDropTargetReducer,
} from '../../gizmos/drag-and-drop/set-droptarget/reducer';
import { type SolutionSetActionPayload, solutionSetReducer } from '../../gizmos/solution-set/redux';
import { InteractionTypes as IT, RS } from '@bettermarks/gizmo-types';
import { type ReducerRegistry } from './types';
import { thermometerReducer } from '../../gizmos/thermometer/Thermometer/thermometerReducer';
import { barChartReducer } from '../../gizmos/chart/reducer/BarChartReducer';
import { stackedBarChartReducer } from '../../gizmos/chart/reducer/StackedBarChartReducer';
import { type SortingGizmoPayload, sortingReducer } from '../../gizmos/sorting/sortingReducer';
import { pieChartReducer } from '../../gizmos/chart/reducer/PieChartReducer';
import { graphReducer, type NodeSizePayload, treeChartReducer } from '../../gizmos/graph/redux';
import { nlpTextReducer } from '../../gizmos/nlp-text/reducer';
import {
  type DynamicRepresentationActionPayload,
  dynamicRepresentationReducer,
} from '../../gizmos/dynamic-representation/redux';

/**
 * Some gizmos don't need a gizmo reducer but still support interaction,
 * usually the reducers are attached at a higher level of the state.
 *
 * This is telling the automated checks for QA tool, that we are aware,
 * that those reducers are not missing.
 */
export const NO_GIZMO_REDUCERS_REQUIRED: ReadonlyArray<string> = [
  // DragDrop has an reducer at the level of contentDict,
  // since every action needs to touch multiple gizmos in a content tree
  `${RS.DRAG_SOURCE} ${IT.DRAG_SOURCE}`,
  `${RS.DROP_TARGET} ${IT.DROP_TARGET}`,
];

export const reducers: ReducerRegistry = {
  [RS.ABSOLUTE_LAYOUT]: absoluteLayoutReducer,
  [`${RS.CIRCLE} ${IT.FORM_COLORIZE}`]: fractionFormReducer,
  [`${RS.CIRCLE} ${IT.FORM_BREAK_COLORIZE}`]: fractionFormReducer,
  [`${RS.CIRCLE} ${IT.FORM_ALL}`]: fractionFormReducer,
  [`${RS.DIAGRAM_BAR_CHART} ${IT.DIAGRAM_BAR_CHART}`]: barChartReducer,
  [`${RS.RECTANGLE} ${IT.FORM_COLORIZE}`]: fractionFormReducer,
  [`${RS.RECTANGLE} ${IT.FORM_BREAK_COLORIZE}`]: fractionFormReducer,
  [`${RS.RECTANGLE} ${IT.FORM_ALL}`]: fractionFormReducer,
  [RS.DRAW2DGEO]: geoReducer,
  [`${RS.HELLO_GIZMO} ${IT.HELLO_GIZMO}`]: helloGizmoReducer,
  [`${RS.FORMULA} ${IT.FORMULA}`]: formulaReducer,
  [`${RS.GRAPH}`]: graphReducer,
  [`${RS.GRAPH} ${IT.GRAPH}`]: graphReducer,
  [`${RS.DIGIT} ${IT.DIGIT}`]: formulaReducer,
  [`${RS.TABLECELL_TEXT} ${IT.TEXT}`]: formulaReducer,
  [`${RS.TREE_DIAGRAM}`]: treeChartReducer,
  [`${RS.INNER_TEXT} ${IT.TEXT}`]: formulaReducer,
  [`${RS.TEXT} ${IT.TEXT}`]: formulaReducer,
  [RS.FUNCTION_PLOTTER]: functionPlotterReducer,
  [`${RS.FUNCTION_PLOTTER} ${IT.SLIDER}`]: functionPlotterReducer,
  [`${RS.MULTIPLE_CHOICE_DROP_DOWN} ${IT.MULTIPLE_CHOICE_DROP_DOWN}`]:
    multipleChoiceDropdownReducer,
  [`${RS.MULTIPLE_CHOICE_SINGLE} ${IT.MULTIPLE_CHOICE_SINGLE}`]: multipleChoiceSingleReducer,
  [`${RS.STACK_LAYOUT} ${IT.MULTIPLE_CHOICE_SINGLE}`]: multipleChoiceSingleReducer,
  [`${RS.MULTIPLE_CHOICE_MULTI} ${IT.MULTIPLE_CHOICE_MULTI}`]: multipleChoiceMultiReducer,
  [`${RS.SET_DROP_TARGET} ${RS.SET_DROP_TARGET}`]: setDropTargetReducer,
  [`${RS.SOLUTION_SET} ${IT.SOLUTION_SET}`]: solutionSetReducer,
  [`${RS.STACKED_BAR_CHART} ${IT.STACKED_BAR_CHART}`]: stackedBarChartReducer,
  [`${RS.PIE_CHART} ${IT.PIE_CHART}`]: pieChartReducer,
  [`${RS.METRICS_SYSTEM} ${IT.METRICS_SYSTEM}`]: geoReducer,
  [`${RS.METRICS_SYSTEM} ${IT.METRICS_POLYGONSELECTION}`]: geoReducer,
  [RS.METRICS_SYSTEM]: geoReducer,
  [`${RS.NLP_TEXT} ${IT.NLP_TEXT}`]: nlpTextReducer,
  [`${RS.SORTING} ${IT.SORTING}`]: sortingReducer,
  [`${RS.THERMOMETER} ${IT.THERMOMETER}`]: thermometerReducer,
  [RS.DYNAMIC_REPRESENTATION]: dynamicRepresentationReducer,
};

export type GizmoPayload =
  | AbsoluteLayoutPayload
  | DynamicRepresentationActionPayload
  | FormulaActionPayload
  | FractionFormActionPayload
  | FunctionPlotterActionPayload
  | HelloGizmoActionPayload
  | MultipleChoiceSingleActionPayload
  | MultipleChoiceActionPayload
  | MultipleChoiceMultiActionPayload
  | NodeSizePayload
  | SetDropTargetPayload
  | SolutionSetActionPayload
  | SortingGizmoPayload
  | GeoPersistLocalStatePayload;
