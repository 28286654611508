import * as React from 'react';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { ResizableDrawer } from './ResizableDrawer';
import { DragAndDropTool } from '../Tools/DragAndDrop';
import { KeyboardContainer } from '../Tools/Keyboard/KeyboardContainer';
import { Drawer, DrawerState } from './Drawer';
import geoDrawerStyles from './GeoDrawer.scss';
import calculatorDrawerStyles from './CalculatorDrawer.scss';
import keyboardDrawerStyles from './KeyboardDrawer.scss';
import { ModeSelectorContainer } from '../Tools/ModeSelectorContainer';
import { CalculatorContainer } from '../Tools/Calculator';

export interface DrawerSwitchProps {
  availableDrawers: Set<ToolbarElements>;
  openDrawer: Nullable<ToolbarElements>;
  contentHeight: number;
}

export const getDrawerStateForToolbarElement = (
  element: ToolbarElements,
  openDrawer: Nullable<ToolbarElements>
): DrawerState => {
  if (element === openDrawer) {
    return DrawerState.Open;
  } else if (openDrawer === null) {
    return DrawerState.Close;
  }
  return DrawerState.Switch;
};

export const DrawerSwitch: React.FC<DrawerSwitchProps> = ({
  availableDrawers,
  openDrawer,
  contentHeight,
}) => (
  <>
    {availableDrawers.has(ToolbarElements.keyboard) && (
      <Drawer
        state={getDrawerStateForToolbarElement(ToolbarElements.keyboard, openDrawer)}
        styles={keyboardDrawerStyles}
      >
        <KeyboardContainer />
      </Drawer>
    )}
    {availableDrawers.has(ToolbarElements.calculator) && (
      <Drawer
        state={getDrawerStateForToolbarElement(ToolbarElements.calculator, openDrawer)}
        styles={calculatorDrawerStyles}
      >
        <CalculatorContainer />
      </Drawer>
    )}
    {availableDrawers.has(ToolbarElements.modeSelector) && (
      <Drawer
        state={getDrawerStateForToolbarElement(ToolbarElements.modeSelector, openDrawer)}
        styles={geoDrawerStyles}
      >
        <div className={geoDrawerStyles.scrollWrapper}>
          <ModeSelectorContainer />
        </div>
      </Drawer>
    )}
    {availableDrawers.has(ToolbarElements.dnd) && (
      <ResizableDrawer
        contentHeight={contentHeight}
        state={getDrawerStateForToolbarElement(ToolbarElements.dnd, openDrawer)}
      >
        <DragAndDropTool />
      </ResizableDrawer>
    )}
  </>
);

DrawerSwitch.displayName = 'DrawerSwitch';
