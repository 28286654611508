import { get, isNil } from 'lodash';
import { type Action, createAction, handleActions } from 'redux-actions';
import {
  type Coords,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type GeoParallelsState,
  type GeoScene,
  LineHighlight,
  type LineObject,
  type PointObject,
  STRAIGHTLINE,
} from '@bettermarks/gizmo-types';
import {
  addReferencedBy,
  createPoint,
  GEO_DEFAULT_PERSIST_PROPS,
  getPointCoordsId,
  INV_POINT_PREFIX,
  linesAreEqul,
  sub,
  visibleCenter,
} from '@bettermarks/importers';
import { createLine, getStraightlineId } from '../../helpers';
import { DEFAULT_HOVER_COLOR, DEFAULT_PARALLEL_TOOL_PREVLINES } from '../constants';
import { getPreview } from './helpers';

export interface DragPayload {
  configuration: GeoConfiguration;
  mouseP: Coords;
  scene: GeoScene;
  matrix: number[];
  scale: number;
}

export interface AddPayload {
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface HoverPayload {
  id: string;
}

export interface OutPayload {
  id: string;
  origLine: LineObject;
}

export type GeoParallelsPayload = DragPayload | AddPayload | HoverPayload | void;

const DRAG: 'DRAG' = 'DRAG';
export const dragAction = createAction<DragPayload>(DRAG);
const ADD_PARALLEL: 'ADD_PARALLEL' = 'ADD_PARALLEL';
export const addParallelAction = createAction<AddPayload>(ADD_PARALLEL);
const START_DRAG: 'START_DRAG' = 'START_DRAG';
export const startDragAction = createAction<HoverPayload>(START_DRAG);
const STOP_DRAG: 'STOP_DRAG' = 'STOP_DRAG';
export const stopDragAction = createAction(STOP_DRAG);
const HOVER: 'HOVER' = 'HOVER';
export const hoverAction = createAction<HoverPayload>(HOVER);
const OUT: 'OUT' = 'OUT';
export const outAction = createAction<OutPayload>(OUT);

export const initialState: GeoParallelsState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  selectedLineType: STRAIGHTLINE,
  selectedLine: [
    { x: NaN, y: NaN },
    { x: NaN, y: NaN },
  ],
  selectedDirection: { x: NaN, y: NaN },
  prevLines: DEFAULT_PARALLEL_TOOL_PREVLINES,
  highlight: LineHighlight.parallel,
  dragging: false,
  toolValueLabels: [],
};

export const geoParallelsReducer = handleActions<GeoParallelsState, GeoParallelsPayload>(
  {
    [ADD_PARALLEL]: (state: GeoParallelsState, { payload }: Action<AddPayload>) => {
      if (!payload) {
        return state;
      }

      // in case of no prevline, just do not add one ...
      if (!get(state, 'prevLines.parallel.p1')) {
        return {
          ...initialState,
          persistProps: state.persistProps,
        };
      }

      const { configuration, onPersistLocalState } = payload;
      const p1 = createPoint(state.prevLines.parallel.p1 as Coords, {}, true);
      const p1Id = getPointCoordsId(p1.coords, INV_POINT_PREFIX);
      const p2 = createPoint(state.prevLines.parallel.p2 as Coords, {}, true);
      const p2Id = getPointCoordsId(p2.coords, INV_POINT_PREFIX);
      const lineId = getStraightlineId(p1Id, p2Id);
      const line = createLine(
        p1Id,
        p2Id,
        configuration.defaultDecorations[STRAIGHTLINE],
        STRAIGHTLINE
      );

      // do not add line, if it is not visible or already existing
      if (
        !visibleCenter(p1.coords, p2.coords, configuration.display, STRAIGHTLINE) ||
        !isNil(
          state.persistProps.straightlines.find((l) => {
            const line = state.persistProps.geoContentMap[l] as LineObject;
            const l1 = state.persistProps.geoContentMap[line.p1Id] as PointObject;
            const l2 = state.persistProps.geoContentMap[line.p2Id] as PointObject;
            return linesAreEqul([p1.coords, p2.coords], [l1.coords, l2.coords]);
          })
        )
      ) {
        return {
          ...initialState,
          persistProps: state.persistProps,
        };
      }

      const persistProps = {
        ...state.persistProps,
        invisiblePoints: [...state.persistProps.invisiblePoints, p1Id, p2Id],
        straightlines: [...state.persistProps.straightlines, lineId],
        geoContentMap: addReferencedBy({
          ...state.persistProps.geoContentMap,
          [p1Id]: p1,
          [p2Id]: p2,
          [lineId]: line,
        }),
      };

      onPersistLocalState(persistProps);

      return {
        ...initialState,
        persistProps,
      };
    },

    [DRAG]: (state: GeoParallelsState, { payload }: Action<DragPayload>) => {
      if (!payload || !state.dragging) {
        return state;
      }
      const { configuration, mouseP, scene, matrix, scale } = payload;
      const [snapPoints, prevLines, toolValueLabel] = getPreview(
        configuration,
        mouseP,
        scene,
        matrix,
        scale,
        state.selectedLineType,
        state.selectedLine,
        state.selectedDirection
      );

      return {
        ...state,
        snapPoints,
        prevLines,
        toolValueLabels: toolValueLabel ? [toolValueLabel] : [],
      };
    },

    [START_DRAG]: (state: GeoParallelsState, { payload }: Action<HoverPayload>) => {
      if (!payload) {
        return state;
      }

      const { id } = payload;
      const line = state.persistProps.geoContentMap[id] as LineObject;
      const [p1, p2] = [
        state.persistProps.geoContentMap[line.p1Id] as PointObject,
        state.persistProps.geoContentMap[line.p2Id] as PointObject,
      ];

      return {
        ...state,
        dragging: true,
        selectedLineType: line.type,
        selectedLine: [p1.coords, p2.coords],
        selectedDirection: sub(p1.coords, p2.coords),
        highlight: undefined,
      };
    },

    [STOP_DRAG]: (state: GeoParallelsState) => {
      return {
        ...initialState,
        persistProps: state.persistProps,
      };
    },

    [HOVER]: (state: GeoParallelsState, { payload }: Action<HoverPayload>) => {
      if (!payload) {
        return state;
      }

      const { id } = payload;
      const line = state.persistProps.geoContentMap[id] as LineObject;

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...line,
              decoration: {
                ...line.decoration,
                color: DEFAULT_HOVER_COLOR,
              },
            },
          },
        },
      };
    },

    [OUT]: (state: GeoParallelsState, { payload }: Action<OutPayload>) => {
      if (!payload) {
        return state;
      }

      const { id, origLine } = payload;

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: origLine,
          },
        },
      };
    },
  },
  initialState
);
