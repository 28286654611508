import { type Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../../types';
import { closeDialog } from '../../../../../apps/seriesplayer/containers/SeriesPlayer/actions';
import { Retry } from './Retry';
// import {REPORT_STEP_RESULT} from '../../Series/actions';

export interface RetryPayload {
  type: string;
  payload?: any;
}

const mapDispatch = (dispatch: Dispatch) => ({ dispatch });

const mergeProps = (
  afterUnlockAction: RetryPayload | undefined,
  { dispatch }: { dispatch: Dispatch }
) => ({
  showCloseBtn: true, // afterUnlockAction?.type !== REPORT_STEP_RESULT,
  onRetry: () => {
    afterUnlockAction && dispatch(afterUnlockAction);
    dispatch(closeDialog());
  },
  onCancel: () => dispatch(closeDialog()),
});

export const RetryContainer = connect(
  ApplicationState.toScreenLockAfterUnlockAction.get,
  mapDispatch,
  mergeProps
)(Retry);
