import * as React from 'react';
import classNames from 'classnames';
import { resolveFillColor, resolveIconColor, resolveStrokeColor } from './helpers';
import { HandleState } from './types';
import styles from './Handle.scss';
import { colors } from '@seriesplayer/common-ui';

export const HANDLE_HEIGHT = styles.handleSize;
export const HANDLE_WIDTH = styles.handleSize;

export const HANDLE_HEIGHT_DEFAULT = 53;
export const HANDLE_WIDTH_DEFAULT = 44;

export type HandleShape = 'round' | 'raindrop';

export type HandleProps = {
  flip?: number;
  vertical?: boolean;
  color?: string;
  error?: boolean;
  handleShape?: HandleShape;
  disabled?: boolean;
};

export type DefaultDisableColorMap = {
  [HandleState.Disabled]: string;
};
export type DefaultFillColorMap = {
  [HandleState.Idle]: string;
};
export type DefaultIconColorMap = {
  [HandleState.Hovered]: string;
} & DefaultDisableColorMap;

export default function Handle({
  flip = 0,
  vertical = false,
  color = colors.cBlue700,
  error = false,
  handleShape = 'round',
  disabled = false,
}: HandleProps) {
  // You could create helper functions, but I do not think that the additional abstraction is worth it
  const [
    strokeHoverColor,
    fillHoverColor,
    iconHoverColor,
    strokeIdleColor,
    fillIdleColor,
    iconIdleColor,
    strokeActiveColor,
    fillActiveColor,
    iconActiveColor,
  ] = React.useMemo(
    () => [
      resolveStrokeColor(color, HandleState.Hovered),
      resolveFillColor(color, HandleState.Hovered),
      resolveIconColor(color, HandleState.Hovered),
      resolveStrokeColor(color, HandleState.Idle),
      resolveFillColor(color, HandleState.Idle),
      resolveIconColor(color, HandleState.Idle),
      resolveStrokeColor(color, HandleState.Grabbed),
      resolveFillColor(color, HandleState.Grabbed),
      resolveIconColor(color, HandleState.Grabbed),
    ],
    [color]
  );

  return (
    <div
      style={
        {
          transformOrigin: '0 0',
          transform: `rotate(${flip}deg)`,
          '--fillHoverColor': fillHoverColor,
          '--strokeHoverColor': strokeHoverColor,
          '--iconHoverColor': iconHoverColor,
          '--fillIdleColor': fillIdleColor,
          '--strokeIdleColor': strokeIdleColor,
          '--iconIdleColor': iconIdleColor,
          '--fillActiveColor': fillActiveColor,
          '--strokeActiveColor': strokeActiveColor,
          '--iconActiveColor': iconActiveColor,
        } as React.CSSProperties
      }
    >
      {handleShape === 'raindrop' ? (
        <svg
          width={`${HANDLE_WIDTH_DEFAULT}px`}
          height={`${HANDLE_HEIGHT_DEFAULT}px`}
          viewBox={`0 0 ${HANDLE_WIDTH_DEFAULT} ${HANDLE_HEIGHT_DEFAULT}`}
          className={classNames(
            styles.handleRaindrop,
            disabled ? styles.disabled : '',
            vertical ? styles.vertical : ''
          )}
        >
          <path
            className={styles.frame}
            d={`M44,31c0-6.07-2.458-11.565-6.434-15.546L22,
          0L6.418,15.47C2.452,19.449,0,24.938,0,31 c0,12.15,9.85,22,22,22S44,43.15,44,31z`}
          />
          <circle className={styles.background} cx="22" cy="31" r="20" />
          <path
            className={styles.icon}
            d={`M28,25l6,5.999L28,37V25z
            M16,25l-6,6.001L16,37V25z M23,19h-2v24h2V19z`}
          />
        </svg>
      ) : (
        <svg
          width={HANDLE_WIDTH}
          height={HANDLE_HEIGHT}
          viewBox={`0 0 ${parseInt(HANDLE_WIDTH, 10)} ${parseInt(HANDLE_HEIGHT, 10)}`}
          className={classNames(
            styles.handle,
            disabled ? styles.disabled : '',
            vertical && styles.vertical
          )}
        >
          {error && <circle className={styles.error} cx="23" cy="23" r="22" strokeWidth="2" />}

          <circle className={styles.background} cx="23" cy="23" r="18" strokeWidth="2" />

          <g transform="translate(13, 13)">
            <path
              className={styles.icon}
              d={`M15.013 5L20 9.999 15.013 15V5zM4.987 5L0
                10.001 4.987 15V5zm6.011-5H9.002v20h1.996V0z`}
            />
          </g>
        </svg>
      )}
    </div>
  );
}

Handle.displayName = 'Handle';
