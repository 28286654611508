import * as React from 'react';
import { EM } from '../../../types';
import { KEM_HORIZONTAL_PADDING } from './constants';
import { EMContentElement } from './EMContentElement';
import { ExampleGroup } from './ExampleGroup';

import styles from './EM.scss';
import { isEmpty } from 'lodash';

export type KEMPageProps = Readonly<EM.Page & { availableWidth: number }>;

export const KEMPage: React.FC<KEMPageProps> = ({ title, elements, availableWidth }) => (
  <div className={styles.kemPage}>
    {!isEmpty(title) && <h1 className={styles.kemPageTitle}>{title}</h1>}
    {elements.map((element, i) => {
      const width = availableWidth - 2 * KEM_HORIZONTAL_PADDING;
      switch (element.type) {
        case EM.ElementType.Content:
        case EM.ElementType.Comment:
        case EM.ElementType.Rule:
          return (
            <EMContentElement
              {...element}
              availableWidth={width}
              first={false}
              last={false}
              key={i}
            />
          );

        default:
          // EM.ElementType.ExampleGroup
          return <ExampleGroup {...element} mode="KEM" availableWidth={width} key={i} />;
      }
    })}
  </div>
);

KEMPage.displayName = 'KEMPage';
