import React from 'react';
import styled from 'styled-components';
import { StyledBox, VAlign } from '../Box';
import defaultTheme from '../../themes/defaultTheme';
import { type ContextNotificationProps } from './types';
import { applyDecoration } from './decorations';
import { Button } from '../Button/ButtonWrapper';
import { StyledButton } from '../Button/Button';

export const Box = styled(StyledBox)<{ customStretch?: boolean } & ContextNotificationProps>`
  display: ${(p) => (p.displayFlex ? 'flex' : 'inline-block')};
  border: ${defaultTheme.dimensions.borderWidthS} solid;
  padding: ${(p) =>
    p.increasedPadding ? defaultTheme.dimensions.spaceS : defaultTheme.dimensions.spaceXs};
  width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
  font-size: ${defaultTheme.dimensions.fontSizeM};
  line-height: normal;
  ${(props) => props.customStretch && 'display: block;'}
  align-items: center;

  ${applyDecoration}
`;

export const ContextNotificationLabel = styled.div`
  flex: 0;
  flex-basis: auto;
`;

export const ContextNotificationContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${defaultTheme.dimensions.spaceXl};
`;

export const ContentWrapper = styled.div<{ withMarginLeft: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.withMarginLeft && `margin-left: ${defaultTheme.dimensions.spaceXl};`}
`;

export const OutsideContentWrapper = styled(ContentWrapper)`
  flex: 1;
`;

export const ContextNotificationButton = styled(Button)`
  ${StyledButton} {
    margin-left: ${defaultTheme.dimensions.spaceS};
    padding: ${defaultTheme.dimensions.spaceXs};
    ${defaultTheme.dimensions.spaceS};
  }
`;

export const ContextNotification: React.FC<ContextNotificationProps> = ({
  children,
  kind,
  increasedPadding,
  stretch,
  dataCy,
  width,
  displayFlex,
}) => (
  <Box
    vAlign={VAlign.middle}
    dataCy={dataCy}
    kind={kind}
    increasedPadding={increasedPadding}
    customStretch={stretch}
    displayFlex={displayFlex}
    width={width}
  >
    {children}
  </Box>
);

ContextNotification.displayName = 'ContextNotification';
