import { defaultTheme, numberFromStyles } from '@seriesplayer/common-ui';
import { FlexAlign, MAX_ROW_WIDTH, VAlign } from '@bettermarks/gizmo-types';
import { isEmpty } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  columnsFromAvailableWidth,
  contructRowsFromCells,
  getMaxRowWidth,
  transposeRows,
} from '../helper';
import type { RendererCell, RendererRow, TableRendererProps } from '../types';

const DEFAULT_CELL_PADDING = defaultTheme.dimensions.spaceXs;
const CELL_PADDING_VALUE = numberFromStyles(DEFAULT_CELL_PADDING);

const mapVerticalAlignToAlignItems = (align: VAlign): FlexAlign => {
  switch (align) {
    case VAlign.bottom:
      return FlexAlign.flexEnd;
    case VAlign.top:
      return FlexAlign.flexStart;
    default:
      return FlexAlign.center;
  }
};

const LinearizeGridsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LinearizeColumnGrid = styled.div<{
  templateColumns: string;
  templateRows: string;
  availableWidth: number;
  percentWidth?: number;
}>`
  display: grid;
  width: ${({ availableWidth }) => availableWidth}px;
  grid-auto-flow: column;
  grid-template-rows: ${({ templateRows }) => templateRows};
  grid-template-columns: ${({ templateColumns }) => templateColumns};
  align-items: center;
`;

const CellWrapper = styled.div<{ cellProps: RendererCell }>`
  ${({ cellProps: { minWidth, width } }) =>
    (minWidth || width) && `min-width: ${minWidth || width}px;`}
  ${({ cellProps: { maxWidth } }) => maxWidth && `max-width: ${maxWidth}px;`}
  ${({ cellProps: { height } }) => height && `min-height: ${height}px;`}
  text-align: ${({ cellProps: { horizontalAlign } }) => horizontalAlign};
  justify-content: ${({ cellProps: { horizontalAlign } }) => horizontalAlign};
  align-items: ${({ cellProps: { verticalAlign } }) => mapVerticalAlignToAlignItems(verticalAlign)};
  box-sizing: border-box;
  display: flex;
  align-self: stretch;
  ${({ cellProps: { backgroundColor } }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  > :first-child  > :first-child {
    ${({ cellProps: { padding, width } }) =>
      !width && `padding: ${padding || DEFAULT_CELL_PADDING};`}
  }
`;

/**
 * `LinearizeColumnTable` must respect the following:
 *
 *  - All table item to be aligned on the same line, if enough available width
 *  - Break to a newline elements that does not fit if not enough available width
 *  - All table item elements to have the **same height if they are on the same line**
 *
 * // There is enough available width
 *
 *     col 1          col 2           col 3
 * .------------. .------------. .------------.
 * |            | |            | |            |
 * |            | |            | |            |  // 2nd item of col 1 and col 2
 * '------------' '------------' '------------'  // must have the same height as
 *                               .------------.  // 2nd item of col 3
 * .------------. .------------. |            |
 * |            | |            | |            |
 * '------------' '------------' |            |
 *                               '------------'
 *
 *
 * // There is NOT enough available width (e.g. availableWidth < MAX_ROW_WIDTH)
 *
 *     col 1          col 2
 * .------------. .------------.
 * |            | |            |
 * |            | |            |
 * '------------' '------------'
 * .------------. .------------.
 * |            | |            |
 * '------------' '------------'
 *      col 3
 * .------------.
 * |            |
 * |            |  // 2nd item of col 1 and col 2
 * '------------'  // MUST NOT have the same height as
 * .------------.  // 2nd item of col 3 as  it is not on the
 * |            |  // same line anymore
 * |            |
 * |            |
 * '------------'
 */
export const LinearizeColumnTable: React.FC<TableRendererProps> = ({
  rows,
  availableWidth,
  columnPercents,
}) => {
  const columnsCount = rows[0]?.cells.length || 0;
  const rowsCount = rows.length;
  const cellsByColumn: RendererCell[] = transposeRows(
    rows.flatMap(({ cells }) => cells),
    columnsCount
  );
  const columns: RendererRow[] = contructRowsFromCells(cellsByColumn, rowsCount);

  const maxRowWidth: number = getMaxRowWidth(columns);
  const gridWidth = Math.min(maxRowWidth, availableWidth);
  const rowsSplitted: RendererRow[][] = columnsFromAvailableWidth(
    availableWidth,
    columns,
    maxRowWidth
  );

  return (
    <LinearizeGridsWrapper>
      {rowsSplitted.map((rows, idx) => (
        <LinearizeColumnRows
          key={idx}
          rows={rows}
          availableWidth={gridWidth}
          columnPercents={columnPercents}
        />
      ))}
    </LinearizeGridsWrapper>
  );
};

const LinearizeColumnRows: React.FC<
  Pick<TableRendererProps, 'rows' | 'availableWidth' | 'columnPercents'>
> = ({ rows, availableWidth, columnPercents }) => {
  const columnsCount: number = rows.length;
  const rowsCount: number = rows[0]?.cells.length || 0;
  const availableCellWidth: number = availableWidth / columnsCount - CELL_PADDING_VALUE;
  const templateRows: string = rowsCount > 1 ? `repeat(${rowsCount}, max-content)` : 'none';
  const templateColumns: string =
    availableWidth >= MAX_ROW_WIDTH && columnPercents
      ? rows.reduce(
          (str, _, idx) => `${isEmpty(str) ? '' : `${str} `}${columnPercents[idx] * 100}%`,
          ''
        )
      : columnsCount > 1
      ? `repeat(${columnsCount}, max-content)`
      : 'fit-content(100%)';

  return (
    <LinearizeColumnGrid
      templateRows={templateRows}
      templateColumns={templateColumns}
      availableWidth={availableWidth}
    >
      {rows.flatMap((row: RendererRow, i: number) =>
        row.cells.map((cell: RendererCell, j: number) => (
          <Cell key={`${i}-${j}`} cellProps={cell} availableWidth={availableCellWidth} />
        ))
      )}
    </LinearizeColumnGrid>
  );
};

const Cell: React.FC<{ cellProps: RendererCell; availableWidth: number }> = ({
  cellProps,
  availableWidth,
}) =>
  cellProps.content?.$refid ? (
    <CellWrapper cellProps={cellProps}>
      <PolymorphicGizmo refid={cellProps.content.$refid} availableWidth={availableWidth} />
    </CellWrapper>
  ) : null;
