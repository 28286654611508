import * as React from 'react';
import { includes } from 'lodash';
import { Hover, type LineObject, type PointObject, SEGMENT } from '@bettermarks/gizmo-types';
import { Line } from '../../components';
import { type LineSetProps, type SetProps } from './types';
import { angle, DEFAULT_VECTOR_DECORATION, visibleCenter } from '@bettermarks/importers';
import { isSelectableObject } from './setHelpers';

export type VectorSetProps = SetProps &
  LineSetProps & {
    vectors: ReadonlyArray<string>;
  };

export const VectorSet: React.FC<VectorSetProps> = ({
  vectors,
  geoContentMap,
  matrix,
  borderRectId,
  highlight,
  configuration,
  mode,
  onClick,
  onHoverId,
  onMouseOutId,
  onMouseDownId,
  snapHighlightIds,
}) => {
  const [onHover, onMouseOut, onMouseDown] = [onHoverId, onMouseOutId, onMouseDownId];
  return (
    <g>
      {vectors.map((id) => {
        const v = geoContentMap[id] as LineObject;
        const p1 = geoContentMap[v.p1Id] as PointObject;
        const p2 = geoContentMap[v.p2Id] as PointObject;
        const center = highlight
          ? visibleCenter(p1.coords, p2.coords, configuration.display, SEGMENT)
          : undefined;
        const rotate = highlight ? angle(p1.coords, p2.coords) : undefined;
        const decoration = {
          ...DEFAULT_VECTOR_DECORATION,
          ...v.decoration,
        };

        const callbacks = {
          ...(onClick && { onClick: onClick(id) }),
          ...(onHover && { onHover: onHover(id) }),
          ...(onMouseDown && { onMouseDown: onMouseDown(id) }),
          ...(onMouseOut && { onMouseOut: onMouseOut(id) }),
        };

        const hover = v.hover || (includes(snapHighlightIds, id) ? Hover.DEFAULT : undefined);

        return (
          <Line
            key={id}
            {...{
              id,
              matrix,
              decoration,
              borderRectId,
              highlight,
              center,
              rotate,
              hover,
            }}
            x1={p1.coords.x}
            y1={p1.coords.y}
            x2={p2.coords.x}
            y2={p2.coords.y}
            notLabelable={v.notLabelable}
            severity={v.severity}
            selectable={isSelectableObject(v, mode)}
            {...callbacks}
          />
        );
      })}
    </g>
  );
};

VectorSet.displayName = 'VectorSet';
