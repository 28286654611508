import { isEmpty } from 'lodash';
import * as React from 'react';

import styles from './BackgroundColor.scss';

type BackgroundColorProps = {
  backgroundColor?: string;
  children: React.ReactElement;
};

/**
 * This wraps a single child with an `display: inline-block;` element
 * to apply `backgroundColor` as element style.
 * Since `backgroundColor` on `display: inline;` will create "sky scraper"-effect,
 * this is needed (only on the first level of new `backgroundColor`) to prevent that effect.
 *
 * If `backgroundColor` is not set this component is a noop.
 */
export const BackgroundColor: React.FC<BackgroundColorProps> = ({ backgroundColor, children }) =>
  isEmpty(backgroundColor) ? (
    children
  ) : (
    <span style={{ backgroundColor }} className={styles.backgroundColor}>
      {children}
    </span>
  );

BackgroundColor.displayName = 'BackgroundColor';
