import { type Action, handleActions } from 'redux-actions';
import { THERMOMETER_DEFAULT_CONTENT } from '@bettermarks/importers';
import { type ThermometerContent } from '@bettermarks/gizmo-types';
import { CHANGE_FLUID_LEVEL } from './thermometerActions';
import { isNil } from 'lodash';

export const thermometerReducer = handleActions<ThermometerContent, number>(
  {
    /**
     * changes the fluid level by changing the value of the thermometer
     */
    [CHANGE_FLUID_LEVEL]: (state, { payload }: Action<number>): ThermometerContent => {
      if (isNil(payload)) return state;
      const { severity, ...newState } = state;
      return { ...newState, value: payload };
    },
  },
  { ...THERMOMETER_DEFAULT_CONTENT, configuration: { min: 0, max: 0 } }
);
