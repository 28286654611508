import { defaultTo, isNil, merge, uniq } from 'lodash';
import { type GeoProps } from '../Geo';
import {
  type CircleDecoration,
  type Coords,
  type GeoAddCircleState,
  type GeoConfiguration,
  type GeoContentPersistProps,
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type IdCoords,
  type LabelContent,
  type LabelWidgetProps,
  type GeoLineDecoration as LineDecoration,
  type LineObjectType,
  LineStyle,
  type PointDecoration,
} from '@bettermarks/gizmo-types';
import {
  addReferencedBy,
  createPoint,
  DEFAULT_CIRCLE_CONFIGURATION,
  DEFAULT_CIRCLE_DECORATION,
  DEFAULT_POINT_DECORATION,
  DEFAULT_PREVLINE,
} from '@bettermarks/importers';
import { createCircle, createLabel, createLine } from '../helpers';

export const addReferences = <State extends GeoInteractiveBaseState>(state: State): State => ({
  ...state,
  persistProps: {
    ...state.persistProps,
    geoContentMap: addReferencedBy(state.persistProps.geoContentMap),
  },
});

export const persistLabel = <State extends GeoInteractiveBaseState>(
  labelId: string,
  refObjectId: string,
  content: LabelContent,
  activeIndex: number,
  state: State,
  labelWidgetProps: LabelWidgetProps,
  position?: Coords,
  t?: number
): State => {
  const persistProps: GeoContentPersistProps = state.persistProps;

  return addReferences<State>({
    ...state,
    persistProps: {
      ...persistProps,
      geoContentMap: {
        ...persistProps.geoContentMap,
        [labelId]: createLabel(refObjectId, content, activeIndex, labelWidgetProps, position, t),
      },
      labels: uniq([...persistProps.labels, labelId]),
    },
  });
};

export const persistPoint = <State extends GeoInteractiveBaseState>(
  point: IdCoords,
  state: State,
  defaultPointDecoration: PointDecoration | undefined,
  visible = true
): State => {
  const persistProps: GeoContentPersistProps = state.persistProps;

  return {
    ...state,
    persistProps: {
      ...persistProps,
      geoContentMap: {
        ...persistProps.geoContentMap,
        [point.id]: {
          ...createPoint(
            point.coords,
            merge({ ...DEFAULT_POINT_DECORATION }, defaultPointDecoration),
            false,
            !visible
          ),
        },
      },
      ...(visible
        ? { points: uniq([...persistProps.points, point.id]) }
        : {
            invisiblePoints: uniq([...persistProps.invisiblePoints, point.id]),
          }),
    },
  };
};

export const persistLine =
  (lineType: LineObjectType) =>
  <State extends GeoInteractiveBaseState>(
    state: State,
    p1Id: string,
    p2Id: string,
    lineId: string,
    defaultLineDecoration: LineDecoration | undefined,
    notLabelable = false
  ): State => {
    const persistProps: GeoContentPersistProps = state.persistProps;
    const lineObj = {
      id: lineId,
      line: createLine(p1Id, p2Id, defaultTo(defaultLineDecoration, {}), lineType, notLabelable),
    };

    return addReferences<State>({
      ...state,
      persistProps: {
        ...state.persistProps,
        [lineType]: uniq([...persistProps[lineType], lineId]),
        geoContentMap: {
          ...persistProps.geoContentMap,
          [lineObj.id]: lineObj.line,
        },
      },
      snapPoints: [],
      prevLine: DEFAULT_PREVLINE,
    });
  };

export const persistCircle = (
  state: GeoAddCircleState,
  circleId: string,
  configuration: GeoConfiguration
): GeoAddCircleState => {
  const persistProps: GeoContentPersistProps = state.persistProps;
  const {
    prevCircle: { coords, radius },
  } = state;

  if (coords) {
    const { toolConfiguration, defaultDecorations } = configuration;
    const circleConfig = {
      ...DEFAULT_CIRCLE_CONFIGURATION,
      ...(!isNil(toolConfiguration) && toolConfiguration.circleConfiguration),
    };
    const circleDecoration = {
      ...DEFAULT_CIRCLE_DECORATION,
      ...getDefaultCircleDecoration(defaultDecorations.circles),
    };
    return {
      ...state,
      persistProps: {
        ...persistProps,
        geoContentMap: {
          ...persistProps.geoContentMap,
          [circleId]: createCircle(circleConfig, coords, circleDecoration, radius),
        },
        circles: [...persistProps.circles, circleId],
      },
    };
  }
  return state;
};

function getDefaultCircleDecoration(defaultCircleDecoration?: CircleDecoration) {
  return {
    ...(defaultCircleDecoration && { ...defaultCircleDecoration }),
    ...(defaultCircleDecoration &&
      defaultCircleDecoration.lineStyle && {
        borderStyle: defaultCircleDecoration.lineStyle,
      }),
  };
}

export const prevLineStyle = (mode: GeoEditorMode): LineStyle => {
  switch (mode) {
    case GeoEditorMode.ADD_RAY_DASHED:
    case GeoEditorMode.ADD_SEGMENT_DASHED:
    case GeoEditorMode.ADD_STRAIGHTLINE_DASHED:
    case GeoEditorMode.ADD_VECTOR_DASHED:
    case GeoEditorMode.MIDDLEPOINT:
      return LineStyle.dashed;
    default:
      return LineStyle.solid;
  }
};

export const persistProps = ({
  geoContentMap,
  points,
  invisiblePoints,
  circles,
  labels,
  beziers,
  dynamicBeziers,
  segments,
  rays,
  straightlines,
  vectors,
  intervals,
  readinghelps,
  persistToolValueLabels,
}: GeoProps): GeoContentPersistProps => ({
  geoContentMap,
  points,
  invisiblePoints,
  circles,
  labels,
  beziers,
  dynamicBeziers,
  segments,
  rays,
  straightlines,
  vectors,
  intervals,
  readinghelps,
  persistToolValueLabels,
});
