import { numberFromStyles } from '@bettermarks/gizmo-types';
import femstyles from './FEMPlayerRoute.scss';
import femPlayerStyles from './FEMPlayer.scss';
import kemstyles from './KEMPlayer.scss';

export const FEMPLAYER_HORIZONTAL_PADDING = numberFromStyles(
  femPlayerStyles.FEMPLAYER_HORIZONTAL_PADDING
);
export const FEMPLAYER_MAX_WIDTH = numberFromStyles(femstyles.FEMPLAYER_MAX_WIDTH);
export const KEMPLAYER_MAX_WIDTH = numberFromStyles(kemstyles.KEMPLAYER_MAX_WIDTH);
export const FEM_PLAYER_ELEMENT_ID = 'fem-player';
