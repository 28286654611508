export enum FeatureKey {
  hideCloseButton = 'hideCloseButton',
  hideReportProblem = 'hideReportProblem',
  quitOnBrowserBack = 'quitOnBrowserBack',
  reportProblemV2 = 'reportProblemV2',
  persistReduxStateFlow = 'persistReduxStateFlow',
}

export type Features = Readonly<Partial<Record<FeatureKey, boolean>>>;

export const DEFAULT_FEATURES: Features = {
  // only add keys that default to true, since false(/undefined) already is the default
};

export enum Roles {
  rStudent = 'r:student',
}
