import { type ContentColor } from '../../styles/colors';
import { type Annotations, type Content, type ContentReference } from '../../xml-converter/core';
import { type HAlign, type VAlign } from '../../gizmo-utils/types';

export interface LineDecoration {
  color: ContentColor; // optional in XML, default bm-transparent
}

export interface CellDecoration {
  backgroundColor?: ContentColor; // default bm-transparent
  backgroundAlpha: number; // default 1
}

export interface Padding {
  top: number;
  bottom: number;
  right: number;
  left: number;
}

export type Cell = {
  content?: ContentReference;
  horizontalAlign: HAlign; // default center
  verticalAlign: VAlign; // default middle
  decoration: CellDecoration;
  padding?: Padding; // in string "0px 5px" -> Padding
  colspan: number; // default 1
  rowspan: number; // default 1
  ignoreCellSize: boolean;
  childIsTable: boolean;
};

export type Row = {
  backgroundDecoration?: string;
  cells: Cell[];
  class?: string;
  borderTop?: ContentColor | null;
  borderBottom?: ContentColor | null;
  originalCells?: Cell[]; // for exporting the original cells store them additionally
};

export const enum ReadingOrder {
  row = 'row',
  column = 'column',
}

export const enum TableStyle {
  UNSET = 'unset',
  SIMPLE_TABLE = 'simple-table',
  DEFAULT = 'default',
  UNIT_TABLE = 'unit-table',
  VALUE_TABLE = 'value-table',
  EQNARRAY = 'eqnarray',
  LINEAR_EQN_SYSTEM = 'linear-equations-system',
  PLACE_VALUE = 'place-value-table',
  MATRIX = 'matrix',
}

export interface TableContent extends Content {
  rows: Row[];
  style: TableStyle;
  childOfHScroll: boolean;
  readingOrder?: ReadingOrder;
  width?: number; // in '...%' -> decimal 0-1
  columnPercents?: number[]; // in '60|40' -> decimal 0-1
  cellWidth?: number; // in pixel string -> number
  cellHeight?: number; // in pixel string -> number
  outerLineWidth: number; // string -> default 0, number
  innerHorizontalLineWidth: number; // string -> default 5, number
  innerVerticalLineWidth: number; // string -> default 5, number
  lineDecoration: LineDecoration;
  backgroundDecoration?: string;
  withEqualSign?: boolean;
  percentWidth: number;
  maxRowWidth: number;
  maxRowWidthFlash: number;
  postDecimals?: number;
}

export const enum Orientation {
  north = 'north',
  west = 'west',
  east = 'east',
  south = 'south',
}

export type ArrowTip = {
  id: string;
  head: boolean;
  coords: [number, number]; // cell coords
};

export type Arrow = {
  orientation: Orientation;
  label: ContentReference;
  from: ArrowTip;
  to: ArrowTip;
  annotationInner: Annotations;
};

export interface TableContainerContent extends Content {
  arrows: Arrow[];
  table: TableContent;
}
