import { SPECIAL } from '../../gizmo-utils/constants';
import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';
import { type Severity } from '@bettermarks/umc-kotlin';
import { type MathContent, type FormulaContent } from '../formula/types';
import { type GeoContent } from '../geo/data/types';
import { type GeoDecoration } from '../geo/types';
import * as RS from '../../gizmo-utils/configuration/renderStyles';
import { type Decoration } from '../../gizmo-utils/types';
import { type ControlOrientation } from '../dynamic-representation/data';

export enum FunctionType {
  exponential = 'ExponentialFunction',
  linear = 'LinearFunction',
  logarithmic = 'LogarithmFunction',
  polynomial = 'PolynomialFunction',
  power = 'PowerFunction',
  quadratic = 'QuadraticFunction',
  sinus = 'SinusFunction',
  vline = 'VLineFunction',
}

export enum FunctionDerivative {
  slope = 'slope',
  value = 'value',
}

/**
 * 0  ... denotes the function itself (f)
 * 1  ... denotes the first derivative of the function (f')
 **/
export type DerivativeType = 0 | 1;

export type FunctToFormulaSign = '+' | '-';

export enum ReadinghelpRestriction {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface FunctionPlotterContent extends Content {
  decoration?: Decoration;
  percentWidth?: number;
  functions: ReadonlyArray<ParametricFunction>;
  selectedParameter?: string;
  geo?: GeoContent;
  /**
   * stores the initial XML to simplify exporting
   * TODO: This should be removed when we get rid of xml import/export
   */
  xml: string;
}

export enum RenderMode {
  None = 0,
  Formula = 1,
  Graph = 2,
}

export type Readinghelps = {
  tangentReadinghelp?: Readinghelp;
  secantReadinghelp1?: Readinghelp;
  secantReadinghelp2?: Readinghelp;
  vertexReadinghelp?: Readinghelp;
};

export type ReadinghelpIndex = {
  [key in keyof Readinghelps]?: Readinghelp;
};

export type ParametricFunction = Readinghelps &
  ReadinghelpIndex & {
    type: FunctionType;
    id?: string;
    decoration?: GeoDecoration;
    derivative?: FunctionDerivative;
    parameters: FunctionParameter[];
    x: string;
    prefix?: FormulaContent;
    valuePrefix?: FormulaContent;
    slopePrefix?: FormulaContent;
    renderMode: RenderMode;

    ghostLabel?: FunctionLabel;
    graphLabel?: FunctionLabel;
    tangentLabel?: FunctionLabel;
    secantLabel1?: FunctionLabel;
    secantLabel2?: FunctionLabel;
    vertexLabel?: FunctionLabel;

    intersectionLines?: IntersectionLine[];
    tangent?: FunctionLine;
    secant?: FunctionLine;
  };

export const FUNCTION_DEFAULTS: ParametricFunction = {
  type: FunctionType.polynomial,
  parameters: [],
  x: '',
  renderMode: RenderMode.None,
};

export type FunctionLabel = {
  label: MathContent | string;
  decoration: GeoDecoration;
  readinghelp: boolean;
};

export type IntersectionLine = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  decoration?: GeoDecoration;
};

export type FunctionLine = {
  length?: number;
  decoration: GeoDecoration;
};

export type Readinghelp = {
  restrict?: ReadinghelpRestriction;
  decoration?: GeoDecoration;
  straightline?: boolean;
};

export type FunctionParameter = {
  name: string;
  value: number;
  showValue: boolean;
  sliderOrientation: ControlOrientation;
  refId?: string;
  minimum: number;
  maximum: number;
  step: number;
  label?: ContentReference;
  unit?: string;
  unitValue: number;
  ignoreZero?: boolean;
  errorDecorationForPlotter?: boolean;
  rendererAllowsInteraction?: boolean;
  decoration: Decoration;
  severity?: Severity;
};

export const PARAMETER_DEFAULTS: FunctionParameter = {
  name: '',
  value: 0,
  minimum: -5,
  maximum: 5,
  step: 0.1,
  unitValue: 1,
  rendererAllowsInteraction: true,
  decoration: {},
  showValue: false,
  sliderOrientation: 'horizontal',
};

export const DEFAULT_FUNCTION_PLOTTER_CONTENT: Defaults<FunctionPlotterContent> = {
  $: SPECIAL,
  $renderStyle: RS.FUNCTION_PLOTTER,
  functions: [],
  xml: '',
};
