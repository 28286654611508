import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  connectGizmo,
  type Dispatch,
  type MapContentDictToProps,
} from '../../../gizmo-utils/redux';
import {
  type SetDropTargetCallbacks,
  SetDropTargetGizmo,
  type SetDropTargetProps,
} from './SetDropTargetGizmo';
import { dropAction, removeItem, resetErrorState, setDropTargetSize } from '../actions';
import {
  type DragSourceContent,
  type SetDropTargetContent,
  ShouldEnrichKind,
  type FreeDragItemTranslate,
} from '@bettermarks/gizmo-types';
import { findKey } from 'lodash';
import { gizmoAction } from '../../../gizmo-utils/redux/gizmoActions';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): SetDropTargetCallbacks => ({
  onDropItem: (source: string, freeDragItemTranslate: FreeDragItemTranslate, index?: number) =>
    dispatch(
      gizmoAction(dropAction({ source, target: refid, freeDragItemTranslate, index }), refid)
    ),
  onRemoveItem: (index: number, freeDragItemTranslate: FreeDragItemTranslate) =>
    dispatch(gizmoAction(removeItem({ source: refid, index, freeDragItemTranslate }), refid)),
  onResize: (width, height) =>
    dispatch(
      gizmoAction(setDropTargetSize({ width, height }), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        skipUndo: true,
      })
    ),
  onDragStarted: (index: number) =>
    dispatch(gizmoAction(resetErrorState(index), refid, { skipUndo: true })),
});

export const mapContentDictToProps: MapContentDictToProps<
  SetDropTargetContent,
  SetDropTargetProps
> = (content, contentDict) => {
  return {
    items: content.items.map((item) => {
      const $refid = findKey(contentDict, { $id: item.dragSourceId });
      const dragSource = $refid && (contentDict[$refid] as DragSourceContent);
      return $refid && dragSource
        ? {
            ...item,
            resolved: { dragSource, $refid },
          }
        : item;
    }),
  };
};

export const SetDropTargetGizmoContainer = connectGizmo(mapDispatchToProps, SetDropTargetGizmo, {
  mapContentDictToProps,
});
