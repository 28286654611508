import { ScaffoldingRow } from '../styled-components';
import React from 'react';
import { FormulaContent } from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { getFormulaStringContent } from '../helpers';

export type TermRowProps = {
  term: FormulaContent;
  refId: string;
  icon?: React.ReactElement;
};

export default function TermRow({ term, refId, icon }: TermRowProps) {
  const termElement = term.$interactionType ? (
    <PolymorphicGizmo availableWidth={400} refid={refId} />
  ) : (
    getFormulaStringContent(term, true)
  );

  return (
    <ScaffoldingRow style={{ gap: '10px', justifyContent: 'start' }}>
      {icon}
      <span style={{ fontSize: '20px' }}>=</span>
      {termElement}
    </ScaffoldingRow>
  );
}
