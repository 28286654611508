import { type ImporterRegistry } from '../../gizmo-utils/configuration';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type FEMContent } from '../../types';
import {
  type ContentDict,
  createImporterContext,
  DependencyCollector,
  type FElement,
  xmlText,
} from '@bettermarks/gizmo-types';
import { createImportWrappedContent, importEMElement } from './importerEMElements';

/**
 * This function parses a FEM XML and creates a `FEM` object.
 * In AppExercise fems are added as key value pair: {string: contentDict}
 * "fems": {
 *   "femIdA": {
 *     "fems.femIdA": {
 *       ...root content
 *     }
 *     "fems.femIdA:1": {
 *       ...second content
 *     }
 *   },
 *   "femIdB": {
 *     "fems.femIdB": {
 *       ...first content
 *     }
 *     "fems.femIdB:1": {
 *       ...second content
 *     }
 *   }
 * }
 * @param xml
 * @param importers: Importer Registry
 * @param pathToFEM
 * @param dependencies any dependency collector that can be used
 *                     to collect from multiple import functions
 */
export const importFEM = (
  xml: FElement,
  importers: ImporterRegistry,
  pathToFEM = 'fems.data',
  dependencies = new DependencyCollector()
): ContentDict => {
  const id = xml.findChildTag('id').text;
  const context = createImporterContext(
    importers,
    `${pathToFEM}.${id}`,
    dependencies,
    id
  ).withNextId();
  const importWrappedContent = createImportWrappedContent(context);
  const elements = xml
    .getChildrenByTagName('element')
    .map((element) => importEMElement(element, importWrappedContent));

  const fem: FEMContent = {
    $: SPECIAL,
    $renderStyle: 'fem',
    ...xml.tagsToProps(xmlText, ['id', 'name']),
    elements,
  };

  context.content.set(context.root, fem);
  return context.contentDict;
};
