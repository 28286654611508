import {
  type Content,
  hasInteractionType,
  isFormulaContent,
  ToolbarElements,
} from '@bettermarks/gizmo-types';
import { compose, curry, includes } from 'lodash/fp';
import { type Action } from 'redux-actions';
import { AppExercise, ApplicationState } from '../../types';
import { getFirstInteractiveContent } from '../store/helper';
import { reducers } from '../../gizmo-utils/configuration/reducers';
import { deselectContent, selectContent } from '../../gizmo-utils/redux/gizmoActions';
import { combinedKey } from '../../gizmo-utils/configuration/combinedKey';
import { deselectGizmo, selectGizmo } from './helper';

/**
 * A factory function to create notifySelectedGizmo and notifyDeselectedGizmo
 *
 * `content` is optional for the use case of notificyDeselectedGizmo when there is no selected
 * content.
 */
const createNotifyGizmo =
  <Payload>(action: Action<Payload>) =>
  (
    content: Content | undefined,
    reducerRegistry = reducers,
    reducer = content && reducerRegistry[combinedKey(content)]
  ): Content =>
    content && reducer ? reducer(content, action) : content;

/**
 * Dispatch a SELECT_CONTENT action to the right gizmo.
 *
 * @param {Content} content
 * @param {ReducerRegistry} reducerRegistry passed as an argument to allow mocking it in tests
 * @returns {AppExercise}
 */
export const notifySelectedGizmo = createNotifyGizmo(selectContent());

/**
 * Dispatch a DESELECT_CONTENT action to the right gizmo.
 *
 * @param {Content} content
 * @param {ReducerRegistry} reducerRegistry passed as an argument to allow mocking it in tests
 * @returns {AppExercise}
 */
export const notifyDeselectedGizmo = createNotifyGizmo(deselectContent());

/**
 * Open the keyboard drawer
 */
export const openKeyboardDrawer = curry(
  (triggeredByUser: boolean, state: ApplicationState): ApplicationState => {
    const selected = ApplicationState.toSelectedContent(state).get(state);
    const toolbar = ApplicationState.toToolbar.get(state);
    const openDrawer = ApplicationState.toToolbarOpenDrawerName.get(state);
    return selected &&
      isFormulaContent(selected) &&
      includes(ToolbarElements.keyboard, toolbar.tools) &&
      (openDrawer !== ToolbarElements.dnd || triggeredByUser) &&
      openDrawer !== ToolbarElements.calculator
      ? ApplicationState.toToolbar.set({
          ...toolbar,
          openDrawerName: ToolbarElements.keyboard,
        })(state)
      : state;
  }
);

/**
 * Only return the lastSelection refId when lastSelection refers to an interactive content
 * @param exercise
 */
const getInteractiveLastSelection = (exercise: AppExercise) =>
  exercise.lastSelection &&
  hasInteractionType(AppExercise.toGizmoContent(exercise.lastSelection).get(exercise))
    ? exercise.lastSelection
    : undefined;

/**
 * Selects the first interactive child or previous selected interactive child.
 *
 * 3 cases:
 *  - initially selects first interactive content
 *  - if it's a formula dont select first interactive content
 *  - after validation selects content that has (kept) selected prop
 *  - after closing toolpad content looses selected prop but is saved in lastSelection prop of
 *    exercise
 *
 * @param {boolean} force A flag to force selection.
 * Formula has the initiallyUnselected flag which means it should not be selected initially.
 * This flag is used to override this behavior
 * @param {ApplicationState} exercise The source exercise
 * @param {ApplicationState} exercise
 * @returns {ApplicationState}
 */
export const selectInteractiveChild = curry(
  (force: boolean, state: ApplicationState): ApplicationState => {
    const question = ApplicationState.toCurrentQuestion.get(state);
    const exercise = ApplicationState.toCurrentExercise.get(state);

    if (!question) {
      return state;
    }

    const firstInteractiveContent = getFirstInteractiveContent(question);
    if (firstInteractiveContent) {
      // if there is an interactive content
      const selectedGizmoRefid = ApplicationState.toSelectedRefId(state).get(state);
      if (selectedGizmoRefid) {
        // if there is a previous selected content (will be kept in validation response) reuse it
        return selectGizmo(selectedGizmoRefid, true, false, state);
      } else {
        // otherwise use saved lastSelection or first interactive content
        const [refid, content] = firstInteractiveContent;
        if (content && (!content.initiallyUnselected || force)) {
          return compose(
            selectGizmo(getInteractiveLastSelection(exercise) || refid, true, false),
            deselectGizmo(true)
          )(state);
        }
      }
    }

    return ApplicationState.toToolbarOpenDrawerName.set(null)(state);
  }
);
