import {
  type AxisTickStyle,
  BEZIER,
  type CircleDecoration,
  CIRCLES,
  CIRCLESEGMENT,
  type Coords,
  type GeoDecoration,
  type GeoScene,
  type IdCoords,
  type INTERVAL,
  INTERVAL_HI,
  INTERVAL_LO,
  type IntervalHelperLineDecoration,
  type LABEL,
  type LabelableObject,
  type Line,
  type LineDecoration,
  POINT,
  type PointDecoration,
  POLYGON,
  type PreviewAngle,
  type PreviewCircle,
  type PreviewInterval,
  type PreviewLine,
  RAY,
  SEGMENT,
  type SnapPoint,
  STRAIGHTLINE,
  type ValidationHint,
  VECTOR,
  type ANGLE,
} from '../types';
import {
  type Content,
  type ContentReference,
  type ScalableContent,
} from '../../../xml-converter/core';
import { type Hover } from '../tools/types';
import { type ImageContent } from '../../image/data';
import { type PureMathContent } from '../../formula/types';
import { type Severity } from '@bettermarks/umc-kotlin';
import { type GeoEditorMode } from '../../../gizmo-utils/types';
import { type ModeSelectTool } from '../../../types';

/**
 * Setting of SnapType is obviously as follows:
 *   snapType is set explicitly: -> as set
 *   snapType is NOT set explicitly: ->
 *      if grid is defined -> snapType = 'grid'
 *      if grid is NOT defined -> snapType = 'continuous'
 */
export enum SnapType {
  none = 'none',
  grid = 'grid',
  continuous = 'continuous',
}

export enum AxisType {
  advanced = 'advanced',
  simple = 'simple',
  none = 'none',
}

export enum AxisDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
  diagonal = 'diagonal',
}

export enum AxisNullPosition {
  SouthWest = 'SouthWest',
  South = 'South',
  West = 'West',
  None = 'None',
}

export enum AxisCapStyle {
  Triangle = 'triangle',
  None = 'none',
  Line = 'line',
}

export interface InterceptTheorem {
  theorem: string;
  colors: string[];
}

export interface TickValueInterval {
  x: number;
  y: number;
}

export interface GeoConfiguration {
  display: GeoConfigurationDisplay;
  borderExtension: boolean;
  tickValueInterval: TickValueInterval;
  showBorder: boolean;
  showNullLabel: boolean;
  snapType: SnapType;
  nullLabelPosition: AxisNullPosition;
  subSnapping: {
    x: number;
    y: number;
  };
  defaultDecorations: GeoDefaultDecorations;
  labelValues: LabelValuesMap;
  autoLabeling: AutoLabeling;
  toolConfiguration: ToolConfiguration;
  polygonSelectionColor?: string;
  interceptTheorem?: InterceptTheorem;
}

export interface SelectionConfiguration {
  validation?: Validation;
  color?: string;
}

export const enum Validation {
  optional = 'OPTIONAL',
  required = 'REQUIRED',
}

export interface ToolConfiguration {
  angleConfiguration?: AngleConfiguration;
  parallelsConfiguration?: ParallelsConfiguration;
  circleConfiguration?: CircleConfiguration;
  selectionConfiguration?: SelectionConfiguration;
  intervalConfiguration?: IntervalConfiguration;
}

export type GeoConfigurationDisplay = {
  width: number;
  height: number;
  cx: number;
  cy: number;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

export type AutoLabeling = {
  points?: true;
  rays?: true;
  segments?: true;
  straightlines?: true;
  vectors?: true;
  [k: string]: true | undefined;
};

export const LabelValueTypeMap: Record<string, LabelableObject> = {
  point: POINT,
  points: POINT,
  ray: RAY,
  rays: RAY,
  segments: SEGMENT,
  'segment-line': SEGMENT,
  straightlines: STRAIGHTLINE,
  'straight-line': STRAIGHTLINE,
  vectors: VECTOR,
  'vector-line': VECTOR,
  // These are for the interval limit values to select ...
  'interval-hi': INTERVAL_HI,
  'interval-lo': INTERVAL_LO,
};

export type LabelValuesMap = {
  [key: string]: LabelValues;
};

export type LabelValues = {
  errorLabels: ReadonlyArray<ContentReference>;
  geoLabels: ReadonlyArray<ContentReference>;
  pickerLabels: ReadonlyArray<ContentReference>;
};

export enum AngleType {
  BOTH_360 = 'BOTH360',
  LEFT_360 = 'LEFT360',
  MIDDLE_180_180 = 'MIDDLE180180',
  RIGHT_360 = 'RIGHT360',
}

export enum AngleLineType {
  RAY = 'metrics-ray',
  LINE = 'metrics-line',
}

export type AngleShowPermanent = 'NONE' | 'ARC' | 'SIZE';

export interface AngleConfiguration {
  snapAngle: number;
  type: AngleType;
  lineType: AngleLineType;
  showPermanent: AngleShowPermanent;
}

export interface IntervalConfiguration {
  scene: GeoScene;
  type: string;
  showDialog: boolean;
  decoration: GeoDecoration;
  helperLine: {
    decoration: IntervalHelperLineDecoration;
    visible: boolean;
  };
  intervalLimits?: LabelValuesMap;
  additionalSteps?: number[];
  inputRestriction?: boolean;
}

export interface ParallelsConfiguration {
  snapValue?: number;
  snapPoints?: boolean;
  hideUnit?: true;
  unit: string;
}

export interface CircleConfiguration {
  hideRadius?: true;
  hideUnit?: true;
  snapInterval: number;
  snapPoints?: true;
  unit: string;
}

export interface AdvancedAxisTick {
  pos: Coords;
  label?: ContentReference;
  decoration?: GeoDecoration;
  style?: AxisTickStyle;
}

/**
 * contains all the props of the GeoContent you want to persist in the redux
 * store after defined localstate changes
 */
export interface GeoContentPersistProps {
  /** holds the list of invisible points ids */
  readonly invisiblePoints: ReadonlyArray<string>;

  /** holds the list of points ids */
  readonly points: ReadonlyArray<string>;

  /** holds the list of label ids */
  readonly labels: ReadonlyArray<string>;

  /** holds the list of bezier ids */
  readonly beziers: ReadonlyArray<string>;

  /** holds the list of dynamic bezier ids */
  readonly dynamicBeziers: ReadonlyArray<string>;

  /** holds the list of ray ids */
  readonly rays: ReadonlyArray<string>;

  /** holds the list of segment ids */
  readonly segments: ReadonlyArray<string>;

  /** holds the list of straightline ids */
  readonly straightlines: ReadonlyArray<string>;

  /** holds the list of circle ids */
  readonly circles: ReadonlyArray<string>;

  /** holds the list of vector ids */
  readonly vectors: ReadonlyArray<string>;

  /** holds the list of interval ids */
  readonly intervals: ReadonlyArray<string>;

  /** a 'map' with an 'Id'-key */
  readonly geoContentMap: GeoObjectMap<GeoObject>;

  /** An array with the ids of the reading helps */
  readonly readinghelps: Array<string>;

  /** An array with the persistent labels of the tools */
  readonly persistToolValueLabels?: ToolValueLabel[];
}

export interface GeoContentBase extends Content, GeoContentPersistProps {
  configuration: GeoConfiguration;
  scale: number;
  uniqueId: string;
  grid?: GridObject;
  snappingGrid?: Coords[];
  readinghelps: string[];
  selectedMode: GeoEditorMode;
  verticalAxis?: AxisObject | AdvancedAxisObject;
  horizontalAxis?: AxisObject | AdvancedAxisObject;
  diagonalAxis?: AdvancedAxisObject;
  backgroundImages?: BackgroundImages;
  previewObjects?: GeoPreviewObjects;
  highlight?: PointHighlight | LineHighlight;
  toolValueLabels?: ToolValueLabel[];
  suppressSnapHighlight?: true;
  key?: string;
  tool?: ModeSelectTool;
}

export type GeoContent = GeoContentBase &
  ScalableContent & {
    matrix: number[];
    gridWidth: number;
    gridHeight: number;
    totalWidth: number;
    totalHeight: number;
  };

export type ToolValueLabel = {
  content: string;
  coords: Coords;
  alternativeStyle?: boolean;
};

export enum PointHighlight {
  dragOut = 'dragOut',
  move = 'move',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isPointHighlight = (arg: any): arg is PointHighlight => {
  return arg in PointHighlight;
};

export enum LineHighlight {
  perpendicular = 'perpendicular',
  parallel = 'parallel',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isLineHighlight = (arg: any): arg is LineHighlight => {
  return arg in LineHighlight;
};

// TODO: 'string' is not the most appropriate type to define the unique GeoObject ids
type GeoObjectId = string;

export type GeoObjectMap<GeoObject> = Readonly<Record<GeoObjectId, GeoObject>>;

export type GeoPreviewObjects = {
  snapPoint?: SnapPoint;
  points?: IdCoords[];
  lines?: PreviewLine[];
  circles?: PreviewCircle[];
  angles?: PreviewAngle[];
  intervals?: PreviewInterval[];
};

export enum LabelAlignDirection {
  auto = 'auto',
  leftTop = 'left-top',
  top = 'top',
  rightTop = 'right-top',
  left = 'left',
  center = 'center',
  right = 'right',
  leftBottom = 'left-bottom',
  bottom = 'bottom',
  rightBottom = 'right-bottom',
}

export type VerticalAlignment = LabelAlignDirection.top | LabelAlignDirection.bottom;

export enum LabelType {
  initial = 'initial',
  delete = 'delete',
}

export enum IntervalType {
  open = 'open',
  closed = 'closed',
  lowOpen = 'low-open',
  highOpen = 'high-open',
  none = 'none',
}

export type LineObjectType = typeof SEGMENT | typeof RAY | typeof STRAIGHTLINE | typeof VECTOR;
export type GeoObjectPersistType =
  | typeof POINT
  | typeof LABEL
  | typeof BEZIER
  | typeof CIRCLES
  | typeof INTERVAL
  | typeof CIRCLESEGMENT
  | typeof POLYGON
  | typeof ANGLE
  | LineObjectType;
export type GeoObjectType = 'readinghelps' | GeoObjectPersistType;

export interface CommonOptionals {
  hint?: ValidationHint;
  interactionType?: string;
  severity?: Severity;
  decoration?: GeoDecoration;
  selectable?: boolean;
  addedByUser?: true;
  originalDeco?: GeoDecoration;
  continuingMark?: string;
  dynamicDecoration?: string;
}

export interface GeoObjectBase extends CommonOptionals {
  type: GeoObjectType;
  referencedBy: string[];
  referringTo: string[];
  notLabelable?: true;
  hover?: Hover;
  invisible?: true;
}

export interface GridObject {
  id: string;
  quadrantLabelsVisible: boolean;
  vLines: Line[];
  hLines: Line[];
  width: number;
  height: number;
  color?: string;
}

export type LineObject = DeepImmutableObject<
  GeoObjectBase & {
    type: LineObjectType;
    p1Id: string;
    p2Id: string;
    decoration?: GeoDecoration & { filter?: string };
  }
>;

export type PointObject = DeepImmutableObject<
  GeoObjectBase & {
    type: typeof POINT;
    coords: Coords;
    noSnap?: true;
    dynamicX?: string;
    dynamicY?: string;
    valueSetterRefIdsX?: string;
    valueSetterRefIdsY?: string;
  }
>;

export type CircleObject = DeepImmutableObject<
  GeoObjectBase & {
    configuration: CircleConfiguration;
    coords: Coords;
    radius: number;
    centerHighlight?: PointHighlight;
    dynamicX?: string;
    dynamicY?: string;
    dynamicRadius?: string;
    valueSetterRefIdsX?: string;
    valueSetterRefIdsY?: string;
    valueSetterRefIdsRadius?: string;
  }
>;

export type LabelContent = Readonly<ContentReference> | Readonly<PureMathContent> | string;

export type LabelObject = DeepImmutableObject<
  GeoObjectBase & {
    type: typeof LABEL;
    content: LabelContent;
    // if you don't want to use the default refObject position but an explicit one
    position?: Coords;
    refid?: string;
    styleType?: string;
    align?: LabelAlignDirection;
    labelType?: LabelType;
    distance?: number;
    shift?: number;
    // --------------------------------------------------------------------
    // props for user labeling
    // --------------------------------------------------------------------
    // to know the index in the available labelList:
    activeIndex?: number;
    // to have the vertical alignment of the picker (we want to have it
    // in sync with the vertical alignment of the final label):
    verticalAlign?: LabelAlignDirection.top | LabelAlignDirection.bottom;
    t?: number;
  }
>;

export type IntervalObject = DeepImmutableObject<
  GeoObjectBase & {
    type: typeof INTERVAL;
    direction: AxisDirection;
    min: number;
    max: number;
    intervalType: IntervalType;
    interactionType: string;
  }
>;

export interface AngleBase {
  coords: Coords;
  startAngle: number;
  endAngle: number;
  radius: number;
  hasAngleLegs: boolean;
  isRightAngle?: true;
  labelOffset?: number;
  decoration?: GeoDecoration;
  dynamicX?: string;
  dynamicY?: string;
  dynamicStartAngleX?: string;
  dynamicStartAngleY?: string;
  dynamicEndAngleX?: string;
  dynamicEndAngleY?: string;
  valueSetterRefIdsX?: string;
  valueSetterRefIdsY?: string;
  valueSetterRefIdsStartAngleX?: string;
  valueSetterRefIdsStartAngleY?: string;
  valueSetterRefIdsEndAngleX?: string;
  valueSetterRefIdsEndAngleY?: string;
}

export type AngleObject = DeepImmutableObject<GeoObjectBase & AngleBase>;

export const isAngleObject = (angle: GeoObject | undefined): angle is AngleObject =>
  angle ? 'endAngle' in angle && 'startAngle' in angle : false;

export type PointReadingHelpObject = DeepImmutableObject<
  GeoObjectBase & {
    decoration: LineDecoration;
    pointId: string;
    planePoint?: Coords;
    axesPoints?: Coords[];
  }
>;

export const isPointReadingHelpObject = (
  readingHelp: GeoObject | undefined
): readingHelp is PointReadingHelpObject => (readingHelp ? 'pointId' in readingHelp : false);

export const isPointObject = (obj: GeoObject | undefined): obj is PointObject =>
  obj?.type === POINT;

export const isCircleObject = (obj: GeoObject | undefined): obj is CircleObject =>
  obj?.type === CIRCLES;

export const isCircleSegmentObject = (obj: GeoObject | undefined): obj is CircleSegmentObject =>
  obj?.type === CIRCLESEGMENT;

export const isPolygonObject = (obj: GeoObject | undefined): obj is PolygonObject =>
  obj?.type === POLYGON;

export type ReadingHelpObject = AngleObject | PointReadingHelpObject;

export interface Translation {
  x: number;
  y: number;
  right: number;
  left: number;
  top: number;
  bottom: number;
}

export type StringOrNumber = string | number;

export interface Bezier {
  points: [number, number][];
  /** If the Bezier is an arrow curve, it can have dynamic coordinates.
   * This tuple-array contains a string for each coordinate that is dynamic
   * (i.e. a serialized value setter expression), and a number for the
   * static ones. The static ones can also be found in `points`, but the
   * whole topic is easier to handle if they're all in one place.
   * */
  dynamicPoints?: [StringOrNumber, StringOrNumber][];
  hitAreaPoints?: [number, number][];
  decoration?: GeoDecoration & { filter?: string };
}

export type CircleSegmentObject = DeepImmutableObject<
  GeoObjectBase & {
    type: typeof CIRCLESEGMENT;
    dynamicX: string;
    dynamicY: string;
    dynamicOuterRadius: string;
    dynamicInnerRadius: string;
    dynamicStartAngle: string;
    dynamicAngle: string;
    points: [number, number][];
    valueSetterRefIdsX?: string;
    valueSetterRefIdsY?: string;
    valueSetterRefIdsOuterRadius?: string;
    valueSetterRefIdsInnerRadius?: string;
    valueSetterRefIdsStartAngle?: string;
    valueSetterRefIdsAngle?: string;
    labelOffset?: number;
  }
>;

export type PolygonObject = DeepImmutableObject<
  GeoObjectBase & {
    type: typeof POLYGON;
    points: [number, number][];
    refIds: string[];
  }
>;

export enum BezierSubtype {
  lines = 'lines',
  areas = 'areas',
}

export const SUBTYPE_TO_TAG_MAP: { [key: string]: string } = {
  [BezierSubtype.lines]: 'bezierLines',
  [BezierSubtype.areas]: 'bezierArea',
};

export type BezierGroupObject = DeepImmutableObject<
  GeoObjectBase & {
    type: typeof BEZIER;
    subtype: BezierSubtype;
    beziers: Bezier[];
    decoration?: GeoDecoration & { filter?: string };
    name?: string;
    translation?: Translation;
    moveGroup?: string[];
  }
>;

export type PolygonAsBezier = BezierGroupObject &
  DeepImmutableObject<{
    pointIds: string[]; // implicit identifier for beziers that were polygons (list of vertex ids)
    hitAreaPointIds?: string[]; // - // -
  }>;

export const isBezier = (obj: GeoObject): obj is BezierGroupObject => obj.type === BEZIER;

export interface BaseAxisObject {
  id: string;
  direction: AxisDirection;
  capStyle: AxisCapStyle;
  label: string | ContentReference;
}

export interface AxisObject extends BaseAxisObject {
  tickLabelInterval: number;
  tickValueInterval: number;
}

export type AxisPosition = {
  min: Coords;
  max: Coords;
};

export interface AdvancedAxisObject extends BaseAxisObject {
  contractionEnabled: boolean;
  position: AxisPosition | number;
  ticks: AdvancedAxisTick[];
  maxLabelLines: number; // max number of lines in label formulas
  width?: number;
  height?: number;
  hasAxisTickLabels?: boolean;
  maxLabelLength?: number; // best guess of max number of digits/characters in formula
  onlyPositive?: boolean;
}

export type Axis = AxisObject | AdvancedAxisObject;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAdvancedAxis = (axis: any): axis is AdvancedAxisObject =>
  axis
    ? axis.hasOwnProperty('id') &&
      axis.hasOwnProperty('direction') &&
      axis.hasOwnProperty('capStyle') &&
      axis.hasOwnProperty('contractionEnabled') &&
      axis.hasOwnProperty('label') &&
      axis.hasOwnProperty('position') &&
      axis.hasOwnProperty('ticks')
    : false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const axisType = (axis: any): AxisType =>
  !axis ? AxisType.none : isAdvancedAxis(axis) ? AxisType.advanced : AxisType.simple;

export interface GeoDefaultDecorations {
  points?: PointDecoration;
  rays?: LineDecoration;
  segments?: LineDecoration;
  straightlines?: LineDecoration;
  vectors?: LineDecoration;
  circles?: CircleDecoration;
  [id: string]: PointDecoration | LineDecoration | CircleDecoration | GeoDecoration | undefined;
}

export interface BackgroundImage {
  coords: Coords;
  dynamicX?: string;
  dynamicY?: string;
  valueSetterRefIdsX?: string;
  valueSetterRefIdsY?: string;
  aboveGrid: boolean;
  imageContent: ImageContent;
}

export interface BackgroundImages {
  belowGrid: BackgroundImage[];
  aboveGrid: BackgroundImage[];
}

export type GeoObject =
  | PointObject
  | LineObject
  | CircleObject
  | BezierGroupObject
  | PolygonAsBezier
  | LabelObject
  | IntervalObject
  | ReadingHelpObject
  | CircleSegmentObject
  | PolygonObject;

export type GeoDecorationKeys =
  | 'borderStyle'
  | 'borderWeight'
  | 'capStyle'
  | 'capStyleBottom'
  | 'capStyleTop'
  | 'color'
  | 'fillColor'
  | 'fillTransparency'
  | 'fontSize'
  | 'glowFilter'
  | 'lineStyle'
  | 'lineWeight'
  | 'marked'
  | 'pointStyleType'
  | 'interceptName'
  | 'hitWidth'
  | 'extendedHitArea';

export const VERTICALLY_MOVABLE_POINT = 'coordinate-point-vertically-movable';
