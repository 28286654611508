import { type Action, type ActionMeta, handleAction } from 'redux-actions';
import { compose } from 'lodash/fp';
import { initialSeriesplayerState } from '../../store/constants';
import {
  ApplicationState,
  SeriesMode,
  SeriesStatus,
  type StartSeriesPayload,
  type TimedActionMeta,
} from '../../../types';
import { identity } from '../../../gizmo-utils/filters';
import { START_SERIES } from './applicationActions';
import { reduceReducers } from '../../../gizmo-utils/reduceReducers';
import { NEXT_STEP } from '../containers/Exercise/actions';
import { NEXT_EXERCISE, SWITCH_EXERCISE } from '../containers/Series/actions';
import {
  DESELECT_CONTENT,
  GIZMO_ACTION,
  type GizmoAction,
  SELECT_CONTENT,
  SELECT_FIRST_INTERACTIVE_CHILD,
} from '../../../gizmo-utils/redux';
import { openKeyboardDrawer, selectInteractiveChild } from '../selectHelpers';
import {
  goToNextStep,
  startSeries,
  updateDragSourceDrawerState,
  updateExercisesForNavigation,
} from './helpers';
import { resolveTools, setInitialDrawerState } from '../containers/Toolbar/reducer/reducer';
import { handleValidationSuccess } from './validationSuccess';
import { handleSubmitIncompleteSeries } from './submitIncompleteSeries';
import { handleLockScreen } from './lockScreen';
import { handleUnlockScreen } from './unlockScreen';
import { deselectGizmo, selectGizmo } from '../helper';
import {
  handleInitializeDummyRows,
  handleInitiateRowValidation,
  handleInsertNewEmptyRow,
  handleMakeAllRowsNoninteractive,
  handleResetScaffolder,
  handleRowValidationFinished,
  handleShowSolution,
} from '../../scaffolding-gizmo-prototype/reducer';

const handleStartSeries = handleAction(
  START_SERIES,
  (state: ApplicationState, { payload: startSeriesPayload }: Action<StartSeriesPayload>) =>
    compose(
      selectInteractiveChild(state.series.seriesStatus === SeriesStatus.started),
      updateDragSourceDrawerState,
      setInitialDrawerState,
      resolveTools,
      startSeries(startSeriesPayload)
    )(state),
  initialSeriesplayerState
);

const handleNextStep = handleAction(
  NEXT_STEP,
  (state: ApplicationState, { meta: { startTime } }: ActionMeta<never, TimedActionMeta>) =>
    compose(
      selectInteractiveChild(state.series.seriesStatus === SeriesStatus.started),
      updateDragSourceDrawerState,
      setInitialDrawerState,
      resolveTools,
      goToNextStep(SeriesMode.practice, startTime),
      ApplicationState.toSeriesStatus.set(SeriesStatus.started)
    )(state),
  initialSeriesplayerState
);

const handleNextExercise = handleAction(
  NEXT_EXERCISE,
  (state: ApplicationState, { meta: { startTime } }: ActionMeta<undefined, TimedActionMeta>) => {
    const { currentExerciseIndex, mode } = state.series;
    if (currentExerciseIndex === state.series.exercises.length - 1) {
      return state;
    }
    const nextExerciseIndex = currentExerciseIndex + 1;
    return compose(
      selectInteractiveChild(state.series.seriesStatus === SeriesStatus.started),
      updateDragSourceDrawerState,
      setInitialDrawerState,
      resolveTools,
      updateExercisesForNavigation(true, currentExerciseIndex, nextExerciseIndex, mode, startTime)
    )(state);
  },
  initialSeriesplayerState
);

const handleSwitchExercise = handleAction(
  SWITCH_EXERCISE,
  (
    state: ApplicationState,
    { payload: nextExerciseIndex, meta: { startTime } }: ActionMeta<number, TimedActionMeta>
  ) => {
    const { mode, currentExerciseIndex } = state.series;
    return compose(
      openKeyboardDrawer(false),
      updateDragSourceDrawerState,
      setInitialDrawerState,
      resolveTools,
      selectInteractiveChild(state.series.seriesStatus === SeriesStatus.started),
      updateExercisesForNavigation(false, currentExerciseIndex, nextExerciseIndex, mode, startTime)
    )(state);
  },
  initialSeriesplayerState
);

const handleDeselect = handleAction(
  DESELECT_CONTENT,
  deselectGizmo(true),
  initialSeriesplayerState
);

const handleSelectContent = handleAction(
  GIZMO_ACTION(SELECT_CONTENT),
  (state: ApplicationState, { meta: { gizmoId, triggeredByUser } }: GizmoAction) => {
    const content = ApplicationState.toGizmoContent(gizmoId, state).get(state);
    return compose(
      selectGizmo(gizmoId, true, !!triggeredByUser),
      content && content.selected ? identity : deselectGizmo(true)
    )(state);
  },
  initialSeriesplayerState
);

const handleSelectFirstInteractiveChild = handleAction(
  SELECT_FIRST_INTERACTIVE_CHILD,
  (state: ApplicationState, { payload }: Action<boolean>) => selectInteractiveChild(payload, state),
  initialSeriesplayerState
);

/**
 * TODO:
 * The current state of our reducers is a bit messy. In a future step we should get rid of
 * - exerciseReducer
 * - seriesReducer
 * - maybe even contentdict reducer (not sure)
 * and let them all run on the full state and combine them here.
 *
 * Ideally the single action handlers can be spread over different files, depending on the main
 * focus they have. E.g. we could have exerciseActionHandler.ts, seriesActionHandlers.ts etc...
 *
 * Another idea would be to put every action handler in its own file. This would safe us from
 * overthinking where to put them:
 * - nextStep.ts
 * - nextStep.spec.ts
 * - nextExercise.ts
 * - nextExercise.spec.ts
 * etc.
 *
 * Even if it would increate the amount of files and imports I think it would be much clearer and
 * the action handlers would be easier to find.
 * Another advantage of this approach would be, that we would be less inclined to have multiple
 * action handlers for one action. This should only happen with gizmoReducers <-> applicationRed.
 *
 */

export const applicationReducer = reduceReducers(
  handleStartSeries,
  handleNextStep,
  handleNextExercise,
  handleSwitchExercise,
  handleDeselect,
  handleSelectContent,
  handleSelectFirstInteractiveChild,
  handleSubmitIncompleteSeries,
  handleValidationSuccess,
  handleLockScreen,
  handleUnlockScreen,

  // SCAFFOLDER
  handleResetScaffolder,
  handleInitializeDummyRows,
  handleInsertNewEmptyRow,
  // handleInsertNewRowWithContentFromPreviousRow,
  handleMakeAllRowsNoninteractive,
  handleShowSolution,
  handleInitiateRowValidation,
  handleRowValidationFinished
);
