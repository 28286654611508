import * as React from 'react';
import { ToolTip } from '../../../../components';
import { type ErrorMessage } from '@bettermarks/gizmo-types';
import { useContentTranslation } from '../../../../gizmo-utils/polymorphic-gizmo';
import { calculateExerciseToolTipBounds } from './helper';
import Measure, { type ContentRect, type BoundingRect } from 'react-measure';
import { type TFunction } from 'i18next';

export interface ExerciseToolTipProps {
  element: HTMLDivElement;
  exercise: HTMLDivElement;
  toolTipMessage: ErrorMessage;
  timeout?: number;
  show?: boolean;
  onHide?: () => void;
}

interface Props extends ExerciseToolTipProps {
  t: TFunction;
}

export type ExerciseToolTipState = {
  bounds?: BoundingRect;
  show: boolean;
};

const DEFAULT_TIMEOUT = 5000;
/**
 * ExerciseToolTip component uses the ToolTip to show
 * the Selected Gizmo's errorMessages in the series player
 * based on the SelectedGizmo, its position in the exercise and
 * tooltip boundaries
 */
export class _ExerciseToolTip extends React.Component<Props, ExerciseToolTipState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: !!this.props.show,
    };
  }
  onToolTipResize = ({ bounds }: ContentRect) => this.setState({ bounds });
  getBounds = () => {
    const { element, exercise } = this.props;
    const { bounds } = this.state;
    if (bounds) {
      return calculateExerciseToolTipBounds(element, bounds, exercise);
    }
    return {
      bounds: {},
      noseBounds: {},
    };
  };
  tooltipTimeout: number | undefined;
  clearTooltipTimeout = () => this.tooltipTimeout && clearTimeout(this.tooltipTimeout);
  hideToolTip = () => {
    const { show } = this.state;
    if (show) {
      this.clearTooltipTimeout();
      this.tooltipTimeout = setTimeout(() => {
        this.props.onHide && this.props.onHide();
        this.setState({ show: false });
      }, this.props.timeout || DEFAULT_TIMEOUT) as any;
    }
  };

  componentDidMount() {
    this.hideToolTip();
  }

  componentWillUnmount = () => {
    this.clearTooltipTimeout();
  };

  UNSAFE_componentWillReceiveProps() {
    this.props.show && !this.state.show && this.setState({ show: true }, this.hideToolTip);
  }
  render() {
    const { toolTipMessage, t } = this.props;
    const { bounds, noseBounds, place } = this.getBounds();
    return (
      <Measure bounds onResize={this.onToolTipResize}>
        {({ measureRef }) => (
          <ToolTip
            bounds={bounds}
            noseBounds={noseBounds}
            place={place}
            ref={measureRef}
            show={this.state.show}
          >
            {t(toolTipMessage.i18nKey, [toolTipMessage.param])}
          </ToolTip>
        )}
      </Measure>
    );
  }
}

export const ExerciseToolTip: React.FC<ExerciseToolTipProps> = (props) => {
  const t = useContentTranslation();
  return <_ExerciseToolTip {...props} t={t} />;
};
