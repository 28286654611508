import * as React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import {
  Bar,
  BarStyle,
  BorderDirection,
  BorderThickness,
  defaultTheme,
  Layout as BaseLayout,
  Orientation,
  StyledBox,
} from '@seriesplayer/common-ui';
import dimensions from '../../../../../../styles/dimensions.scss';
import { BaseDragScrollBehaviour as DragScrollBehaviour } from '../../../../gizmo-utils/drag-scroll-behaviour';
import DnD, { DragEvents } from '../../../../components/DragAndDrop/dnd';
import { Loader, NavigationLarge, type NavigationLargeProps } from '../../components';
import { NAVBAR_WIDTH, VERY_SMALL_SCREEN_HEIGHT, VERY_SMALL_SCREEN_WIDTH } from '../../constants';
import { ErrorBoundaryContainer } from '../ErrorBoundary';
import { ExerciseContainer } from '../Exercise';
import { HeaderContainer } from '../Header';
import { LightBoxContainer } from '../LightBox';
import { ToolbarContainer } from '../Toolbar/ToolbarContainer';
import { AsideDrawerSwitchContainer, DrawerSwitchContainer } from '../Toolbar';
import { type ResizeCallback } from './SeriesPlayer';
import styles from './seriesPlayer.scss';
import { getAvailableHeight, getAvailableWidth } from '../../services/runtime-manager';
import { SeriesMode } from '../../../../types';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import {
  CALCULATOR_ASIDE_WIDTH,
  USE_CALCULATOR_ASIDE_WIDTH,
} from '../Toolbar/Tools/Calculator/constants';
import { getBackToNsp } from './quitSeriesPlayerSaga';

export type SeriesPlayerContentProps = Readonly<{
  stepValidationLoaded: boolean;
  onResize: ResizeCallback;
  openDrawerName: Nullable<ToolbarElements>;
}> &
  NavigationLargeProps;

const Box = styled(StyledBox)`
  flex: 1 1 auto;
`;

const Layout = styled(BaseLayout)`
  background-color: ${defaultTheme.colors.cWhite};
`;

export class SeriesPlayerContent extends React.Component<SeriesPlayerContentProps> {
  private readonly exerciseBoxRef: React.RefObject<HTMLDivElement>;
  private scrollBehaviour: DragScrollBehaviour;

  constructor(props: SeriesPlayerContentProps) {
    super(props);

    this.exerciseBoxRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    if (this.exerciseBoxRef.current) {
      this.scrollBehaviour = new DragScrollBehaviour(this.exerciseBoxRef.current);
      DnD.addEventListener(DragEvents.leftInitialRect, this.scrollBehaviour.startScrolling);
      DnD.addEventListener(DragEvents.dragEnd, this.scrollBehaviour.stopScrolling);
      DnD.addEventListener(DragEvents.drop, this.scrollBehaviour.stopScrolling);
    }
  }

  componentDidUpdate(prevProps: SeriesPlayerContentProps) {
    if (
      prevProps.openDrawerName !== this.props.openDrawerName &&
      (prevProps.openDrawerName === ToolbarElements.calculator ||
        this.props.openDrawerName === ToolbarElements.calculator)
    ) {
      this.updateDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    if (this.scrollBehaviour) {
      DnD.removeEventListener(DragEvents.leftInitialRect, this.scrollBehaviour.startScrolling);
      DnD.removeEventListener(DragEvents.dragEnd, this.scrollBehaviour.stopScrolling);
      DnD.removeEventListener(DragEvents.drop, this.scrollBehaviour.stopScrolling);
    }
  }

  updateDimensions = () => {
    if (this.exerciseBoxRef.current) {
      const windowWidth = Math.min(getAvailableWidth(), parseInt(dimensions.LAYOUT_MAX_WIDTH, 10));
      const windowHeight = getAvailableHeight();
      const navBarPresent =
        windowWidth < VERY_SMALL_SCREEN_WIDTH || windowHeight < VERY_SMALL_SCREEN_HEIGHT ? 0 : 1;
      const asideDrawerPresent =
        windowWidth >= USE_CALCULATOR_ASIDE_WIDTH &&
        this.props.openDrawerName === ToolbarElements.calculator
          ? 1
          : 0;
      const scrollBarPresent =
        this.exerciseBoxRef.current.scrollHeight > this.exerciseBoxRef.current.clientHeight ? 1 : 0;
      const scrollBarWidth =
        this.exerciseBoxRef.current.offsetWidth - this.exerciseBoxRef.current.clientWidth;
      const [availableWidth, availableHeight] = [
        // need to subtract
        //
        // - NAVBAR_WIDTH (if in "large screen mode")
        // - width of the calculator, if it is in "aside mode" (displayed on the right)
        // - width of the Box component's scrollbar (ref = this.exerciseBoxRef.current), if present
        //
        // to receive the correct availableWidth
        windowWidth -
          navBarPresent * NAVBAR_WIDTH -
          asideDrawerPresent * CALCULATOR_ASIDE_WIDTH -
          scrollBarPresent * scrollBarWidth,
        this.exerciseBoxRef.current.clientHeight,
      ];

      // let SeriesPlayerContainer dispatch the updated values to the store
      // (ExerciseContainer will pick them up from there)
      this.props.onResize({ availableWidth, availableHeight });
    }
  };

  render() {
    const {
      currentExerciseIndex,
      exerciseDisplayStatus,
      switchExercise,
      mode,
      stepValidationLoaded,
      previousExercise,
      nextExercise,
    } = this.props;

    const navigationLargeProps: NavigationLargeProps = {
      currentExerciseIndex,
      exerciseDisplayStatus,
      switchExercise,
      mode,
      previousExercise,
      nextExercise,
    };

    return (
      <>
        <Loader loaded={stepValidationLoaded} onQuit={getBackToNsp} />
        <Layout fullsize>
          {mode !== SeriesMode.preview && (
            <MediaQuery minWidth={VERY_SMALL_SCREEN_WIDTH} minHeight={VERY_SMALL_SCREEN_HEIGHT}>
              <Bar
                borderThickness={BorderThickness.THICK}
                borderDirection={BorderDirection.RIGHT}
                barStyle={BarStyle.LIGHT}
                orientation={Orientation.VERTICAL}
              >
                <ErrorBoundaryContainer>
                  <NavigationLarge {...navigationLargeProps} />
                </ErrorBoundaryContainer>
              </Bar>
            </MediaQuery>
          )}
          <Box stretch>
            <Layout vertical>
              {mode !== SeriesMode.preview && (
                <ErrorBoundaryContainer>
                  <HeaderContainer />
                </ErrorBoundaryContainer>
              )}
              <div className={styles.asideDrawerWrapper}>
                <Box id="content" dataCy="content" ref={this.exerciseBoxRef} stretch scrollable>
                  <ErrorBoundaryContainer>
                    <ExerciseContainer />
                  </ErrorBoundaryContainer>
                </Box>
                {mode !== SeriesMode.preview && <AsideDrawerSwitchContainer />}
              </div>
              {mode !== SeriesMode.preview && (
                <>
                  <DrawerSwitchContainer />
                  <ToolbarContainer />
                </>
              )}
            </Layout>
          </Box>
        </Layout>
        <LightBoxContainer />
      </>
    );
  }
}
