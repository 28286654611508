import { type Ruler } from '../../gizmo-utils/configuration/types';
import styles from '../../components/ValueStepper/ValueStepper.scss';
import { numberFromStyles } from '@bettermarks/gizmo-types';

export const measureValueStepper: Ruler = () => {
  const valueStepperMinHeight = numberFromStyles(styles.STEPPER_MIN_HEIGHT);

  return {
    height: valueStepperMinHeight,
    // Magic number! Without padding, a potential subscript/superscript would
    // stick directly to the value stepper's box, which doesn't look nice :(
    padding: -2,
    refLine: valueStepperMinHeight * 0.5,
    relativeToBaseLine: true,
  };
};
