import {
  connect,
  type MapDispatchToPropsFunction,
  type MapStateToProps,
  type ConnectedProps,
} from 'react-redux';

import { type ApplicationState, DialogType, SeriesMode } from '../../../../../types';

import { closeDialog, openDialog, quitSeriesPlayer } from '../../SeriesPlayer/actions';
import { QuitConfirmation, type QuitConfirmationOwnProps } from './QuitConfirmation';

interface DispatchProps {
  onCancel: () => void;
  onQuit: () => void;
  onSubmitIncomplete: () => void;
}

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, QuitConfirmationOwnProps> = (
  dispatch
) => ({
  onCancel: () => dispatch(closeDialog()),
  onQuit: () => dispatch(quitSeriesPlayer()),
  onSubmitIncomplete: () =>
    dispatch(
      openDialog({
        type: DialogType.submitIncompleteConfirmation,
        payload: {
          backButtonDialog: DialogType.closeConfirmation,
        },
      })
    ),
});

interface StateProps {
  canSubmitIncompleteSeries: boolean;
  hasStepReporting: boolean;
}

const mapStateToProps: MapStateToProps<StateProps, QuitConfirmationOwnProps, ApplicationState> = (
  state
) => ({
  canSubmitIncompleteSeries:
    state.series.mode === SeriesMode.practice && state.series.seriesSettings.reporting,
  hasStepReporting: state.series.seriesSettings.reporting,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type QuitConfirmationReduxProps = ConnectedProps<typeof connector>;

export const QuitConfirmationContainer = connector(QuitConfirmation);
