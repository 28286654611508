import * as React from 'react';
import classNames from 'classnames';
import { HLayoutChildWrapper, StyledHLayout } from '@seriesplayer/common-ui';
import { CalendarMonth, getMonthName, getWeekDaysHeader } from '../../components/CalendarMonth';
import { type ContextState, useContentTranslation } from '../../gizmo-utils/polymorphic-gizmo';
import { type CalendarGizmoContent } from '@bettermarks/gizmo-types';
import { constructCalendarDayGrids } from './helper';
import styles from './CalendarGizmo.scss';

export type CalendarGizmoProps = CalendarGizmoContent & ContextState;

export const CalendarGizmo: React.FC<CalendarGizmoProps> = (props) => {
  const t = useContentTranslation();
  // In order to get the same calendar height:
  // 1. Construct all calendar day grids (an array of array of day cells)
  // 2. Get the maximum number of rows to display (e.g. max # of day cells arrays)
  // 3. Add extra rows to calendar month that need to get at the same height
  const { count, firstDayOfWeek, showWeekDays, daySpans } = props;
  const year = props.year || new Date().getFullYear();
  // month - 1 is because EPL express months 1-indexed
  // and JS Date object expect month as 0-indexed
  const month = props.month - 1;
  const daysHeader = showWeekDays ? getWeekDaysHeader(firstDayOfWeek, t) : undefined;

  // 1. Construct all calendar day grids
  const dayGrids = constructCalendarDayGrids(month, year, count, daySpans, firstDayOfWeek);

  // 2. Get the maximum number of rows
  const maxRows = Math.max(...dayGrids.map(({ length }) => length));

  return (
    <div className={classNames(styles.calendarGizmo)}>
      <StyledHLayout gap={6}>
        {dayGrids.map((dayGrid, i) => {
          // 3. Add extra rows if needed
          const extraRows = maxRows - dayGrid.length;
          const currentMonth = (month + i) % 12;
          return (
            <HLayoutChildWrapper key={currentMonth}>
              <CalendarMonth
                month={getMonthName(currentMonth, t)}
                {...{ daysHeader, dayGrid, extraRows }}
              />
            </HLayoutChildWrapper>
          );
        })}
      </StyledHLayout>
    </div>
  );
};
