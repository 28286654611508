import { Connection, DATA_TYPE } from 'jsstore';
import log from 'loglevel';

export const idbCon = new Connection(window.Worker ? new Worker('./worker/worker.js') : undefined);
// for local debugging
// idbCon.logStatus = true;
export const dbName = 'BMLocalStorage';
export const tableName = 'ReduxStateFlow';

const getDatabase = () => {
  const seriesStates = {
    name: tableName,
    columns: {
      id: {
        primaryKey: true,
        unique: true,
        autoIncrement: true,
      },
      preloadedState: {
        notNull: false,
        dataType: DATA_TYPE.Object,
      },
      actions: {
        notNull: true,
        dataType: DATA_TYPE.Array,
        default: [],
      },
      timestamp: {
        notNull: true,
        dataType: DATA_TYPE.DateTime,
      },
    },
  };

  return {
    name: dbName,
    tables: [seriesStates],
  };
};

export const initJsStore = async () => {
  try {
    const dataBase = getDatabase();
    await idbCon.initDb(dataBase);
  } catch (ex) {
    log.warn('Failed to initialize jsStore.', ex);
  }
};
