import { type LogLevelDesc } from 'loglevel';
import { type ValidatorEngine } from '@bettermarks/umc-kotlin';

/**
 * When importing the settings from the backend only these keys are used.
 * Other fields can only be set inside an App.
 **/
export enum AppSettingsKey {
  contentManagerUrl = 'contentManagerUrl',
  contentManagerApiKey = 'contentManagerApiKey',
  embedded = 'embedded',
  logLevel = 'logLevel',
  resultManagerUrl = 'resultManagerUrl',
  staticServerUrl = 'staticServerUrl',
  staticMediaUrl = 'staticMediaUrl',
  validatorUrls = 'validatorUrls',
}

export type ValidatorUrlMap = { [key in ValidatorEngine]: string };

/**
 * User specific configuration fetched from the backend.
 * For using a key when it coms from NSP backend you need to also add it to `AppSettingsKey`.
 *
 * @see getAppConfig
 * @see AppConfig
 * @see AppSettingsKey
 */
export type AppSettings = Readonly<{
  contentManagerUrl: string;
  contentManagerApiKey?: string;
  embedded: boolean;
  logLevel: LogLevelDesc;
  resultManagerUrl: string;
  staticServerUrl: string;
  staticMediaUrl: string;
  validatorUrls: ValidatorUrlMap;
}>;

export type GetParams = Readonly<{
  appLocale: string;
  exerciseId?: string;
  focusElement?: string;
  remoteLogging: boolean;
  seriesId?: string;
  seriesReview: boolean;
  previewMode: boolean;
  studentId?: string;
  userId: string;
}>;

export type LobLinkGetParams = Readonly<{
  lti: boolean;
}>;

export type PdfReviewSeriesParams = Readonly<{
  seriesId: string;
  studentId: string;
  system: string;
  token: string;
}>;

export type LobLinkParams = Readonly<{
  contentListId: string;
  locale: string;
  token: string;
  reportingParams?: LobLinkQueryParams;
}>;

export type LobLinkQueryParams = Readonly<{
  userId: string;
  bookId: string;
  lobId: string;
  token: string;
}>;
