import { get, has } from 'lodash';
import {
  type Coords,
  type GeoConfiguration,
  type GeoScene,
  INVISIBLE,
  type LineObjectType,
  LineStyle,
  type ParallelToolPreviewLines,
  POINT,
  RAY,
  SEGMENT,
  type SnapPoint,
  STRAIGHTLINE,
  type ToolValueLabel,
  VECTOR,
} from '@bettermarks/gizmo-types';
import {
  add,
  DEFAULT_PREVLINE_DECORATION,
  midpoint,
  project,
  rdN,
  round,
  screenToWorld,
  smult,
  squaredEuclidianDistance,
  sub,
  visibleCenter,
  worldToScreen,
} from '@bettermarks/importers';
import { getLineCoords } from '../../helpers';
import { DEFAULT_PARALLEL_TOOL_PREVLINES } from '../constants';
import { snapLineToPoints, snapPoints as snapPts } from '../../snap';

const PARALLELTOOL_CONFIG = 'toolConfiguration.parallelsConfiguration';

/* eslint-disable-next-line complexity*/
export const getPreview = (
  configuration: GeoConfiguration,
  mouseP: Coords,
  scene: GeoScene,
  matrix: number[],
  scale: number,
  lineType: LineObjectType,
  [s1, s2]: [Coords, Coords], // selected line
  selDir: Coords
): [SnapPoint[], ParallelToolPreviewLines, ToolValueLabel | null] => {
  /**
   * Config parameters:
   *
   * hideUnit     - don't show distance of preview line to selected line
   * snapValue    - snap "steps" of the preview line in world units
   * snapToPoints - snap to existing (invisible) points in snap distance to preview line,
   *                even if the mouse is not in snap distance to those points
   *                (NOT to be combined with snapValue)
   */
  const hideUnit = has(configuration, `${PARALLELTOOL_CONFIG}.hideUnit`);
  const snapValue = get(configuration, `${PARALLELTOOL_CONFIG}.snapValue`) || 1;
  const snapToPoints = get(configuration, `${PARALLELTOOL_CONFIG}.snapPoints`) || false;
  const unit = get(configuration, `${PARALLELTOOL_CONFIG}.unit`) || '';

  // initially, get preview line at mouse position without snap
  let [p1, p2] = getLineCoords(mouseP, add(mouseP, selDir), STRAIGHTLINE);
  const sp1 = project(mouseP, [s1, s2], STRAIGHTLINE); // projection of mouse to line ...
  let d = Math.sqrt(squaredEuclidianDistance(mouseP, sp1));

  // calc snap point(s) ...
  const mouseScreen = worldToScreen(matrix)(mouseP);
  const snapPoints = snapToPoints
    ? // if snapToPoints is ON, snap to any point that is snap-close to the current preview line
      snapLineToPoints(
        [p1, p2],
        STRAIGHTLINE,
        scene.points,
        scene.invisibleSnapPoints,
        matrix,
        scale
      )
    : // otherwise only snap to (invisible) points close to the mouse position
      [
        snapPts(matrix, scene.points, POINT, scale),
        snapPts(matrix, scene.invisibleSnapPoints, INVISIBLE, scale),
        // if visible found don't search invisible
      ].reduce((acc, f) => (acc.length === 0 ? f(mouseScreen) : acc), []);

  // update preview line and distance to selected line...
  if (snapPoints.length > 0) {
    // calculate new preview line
    const snapPoint = snapPoints[0];
    const coords = screenToWorld(matrix)({ x: snapPoint.x, y: snapPoint.y });
    [p1, p2] = getLineCoords(coords, add(coords, selDir), STRAIGHTLINE);

    // calculate new distance
    const foot = project(coords, [s1, s2], STRAIGHTLINE);
    d = round(Math.sqrt(squaredEuclidianDistance(coords, foot)), 2);
  }

  // snap to value only, if no snap points detected and snapToPoints is OFF!
  if (snapPoints.length === 0 && !snapToPoints) {
    const dNew = rdN(d, snapValue);
    const p11 = add(sp1, smult(dNew / d, sub(sp1, mouseP)));
    [p1, p2] = getLineCoords(p11, add(p11, selDir), STRAIGHTLINE);
    d = dNew;
  }

  // no preview for identical line!
  if (d === 0) {
    return [snapPoints, DEFAULT_PARALLEL_TOOL_PREVLINES, null];
  }

  /**
   * this is for the dashed line between selected line and preview line
   * s1 ------- mid ------- s2  <-- selected line
   *             |
   *             |
   * p1 -------perp ------- p2  <-- preview line
   */
  const mid =
    visibleCenter(s1, s2, configuration.display, lineType === VECTOR ? SEGMENT : lineType) || s1;
  const perp = project(mid, [p1, p2], STRAIGHTLINE);

  const toolValueLabel = !hideUnit
    ? { content: `${round(d, 2)}${unit}`, coords: midpoint(mid, perp) }
    : null;

  const prevLines = {
    ...DEFAULT_PARALLEL_TOOL_PREVLINES,
    parallel: {
      p1: p1,
      p2: p2,
      decoration: DEFAULT_PREVLINE_DECORATION,
      visible: true,
    },
  };

  const common = {
    decoration: { ...DEFAULT_PREVLINE_DECORATION, lineStyle: LineStyle.dashed },
    visible: true,
  };
  const perpendicular = { ...common, p1: mid, p2: perp };

  switch (lineType) {
    case STRAIGHTLINE:
    default:
      return [snapPoints, { ...prevLines, perpendicular }, toolValueLabel];
    case RAY:
      const [h1, h2] = getLineCoords(s1, sub(selDir, s1), RAY);
      return [
        snapPoints,
        {
          ...prevLines,
          perpendicular,
          others: [{ ...common, p1: h1, p2: h2 }],
        },
        toolValueLabel,
      ];
    case SEGMENT:
    case VECTOR:
      const [h11, h12] = getLineCoords(s1, sub(selDir, s1), RAY);
      const [h21, h22] = getLineCoords(s2, add(s2, selDir), RAY);
      return [
        snapPoints,
        {
          ...prevLines,
          perpendicular,
          others: [
            { ...common, p1: h11, p2: h12 },
            { ...common, p1: h21, p2: h22 },
          ],
        },
        toolValueLabel,
      ];
  }
};
