import {
  type ApplyStylesRegistry,
  type GizmoRegistry,
  type RulerRegistry,
} from '../../gizmo-utils/configuration';

import { stylers as _stylers } from '../../gizmo-utils/configuration/stylers';
import { rulers as _rulers } from '../../gizmo-utils/configuration/rulers';
import { gizmoRegistry as _gizmos } from '../../gizmo-utils/configuration/gizmos';
import CRI from './CRI';
import { applyCRIStyles } from './measure';

export const stylers: ApplyStylesRegistry = {
  ..._stylers,
  cri: applyCRIStyles,
};

export const gizmos: GizmoRegistry = {
  ..._gizmos,
  cri: CRI,
};

export const rulers: RulerRegistry = {
  ..._rulers,
};
