import { css, type CSSProperties, type FlattenInterpolation } from 'styled-components';
import { compose } from 'lodash/fp';
import colors from '../../../sass/colors';
import { ButtonKind } from '../types';
import { type StyledButtonProps } from '../Button';
import {
  applyCssForBorderType,
  getBackgroundType,
  isBorder,
  isNotTransparent,
  isTransparent,
  or,
  orEmpty,
} from './utils';

const applyTransparentCss = compose(
  orEmpty(`background-color: ${colors.btnTransparencyActive};`),
  isTransparent,
  getBackgroundType
);

const borderAndBackGroundCss = css`
  border-color: var(--border-and-background-color);
  background-color: var(--border-and-background-color);
`;

const handleActive = (inner: FlattenInterpolation<CSSProperties>) => css<StyledButtonProps>`
  @media (hover: hover) {
    &:active {
      ${inner}
    }
  }
`;

interface ColorSchema {
  color: string;
  borderBackground: string;
  filledBackground?: string;
}

const getDecorationForColorSchema = ({
  color,
  borderBackground,
  filledBackground = borderBackground,
}: ColorSchema) =>
  handleActive(css<StyledButtonProps>`
    --border-and-background-color: ${compose(
      or(borderBackground, filledBackground),
      isBorder,
      getBackgroundType
    )};

    ${compose(orEmpty(borderAndBackGroundCss), isNotTransparent, getBackgroundType)}
    ${applyCssForBorderType(`color: ${color};`)}
    ${applyCssForBorderType(`fill: ${color};`)}
    ${applyTransparentCss}
  `);

const decorationMap: Record<ButtonKind, FlattenInterpolation<StyledButtonProps>> = {
  [ButtonKind.abort]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cRed500,
  }),
  [ButtonKind.action]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cBlue500,
  }),
  [ButtonKind.confirm]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cGreen500,
  }),
  [ButtonKind.disabled]: css``,
  [ButtonKind.final]: getDecorationForColorSchema({
    color: colors.cGray800,
    borderBackground: colors.cOrange500,
    filledBackground: colors.cOrange400,
  }),
  [ButtonKind.view]: getDecorationForColorSchema({
    color: colors.cWhite,
    borderBackground: colors.cGray500,
  }),
  [ButtonKind.warning]: getDecorationForColorSchema({
    color: colors.cGray800,
    borderBackground: colors.cYellow500,
    filledBackground: colors.cYellow400,
  }),
};

export const applyDecoration = ({ kind }: StyledButtonProps) => decorationMap[kind];
