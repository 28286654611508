import {
  annotationInner,
  type DragSourceContent,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';
import { type Severity } from '@bettermarks/umc-kotlin';

export const exportDragItem = (
  contentRefId: string,
  context: ExporterContext,
  severity?: Severity,
  dragItemId?: string
): string => {
  let content = context.content[contentRefId] as DragSourceContent;
  if (dragItemId) {
    content = { ...content, $id: dragItemId };
  }
  return semantics(
    `
      <mrow${severity ? ` decoration="${severity}"` : ''}>
        <configuration>
          <instances>${content.instances.toFixed(0)}</instances>
          <targetGroup>${content.targetGroup.toFixed(0)}</targetGroup>
          ${content.dragSourceId ? `<dragSourceId>${content.dragSourceId}</dragSourceId>` : ''}
          <style>${content.style}</style>
          ${content.autoSize ? '<autoSize>true</autoSize>' : ''}
          ${content.normalized ? `<normalized>${content.normalized}</normalized>` : ''}
          ${typeof content.group === 'number' ? `<group>${content.group}</group>` : ''}
        </configuration>
        ${exportContent(content.content.$refid, context)}
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
export const exportDragSource: Exporter = exportDragItem;
