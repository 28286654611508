import * as React from 'react';

import {
  BASE,
  type Interactable,
  type MRow,
  type MSup as MSupContent,
} from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { Bucket } from '../Bucket';
import { enrich, onClickBetweenChildren } from '../helpers';
import { type MPropsWithChildren } from '../types';

import { getScriptMetric, getSuperScriptOverlap } from './getScriptMetric';
import { type ScriptMetric, type ScriptProps } from './types';

import styles from './MSup.scss';

export type MSupProps = MPropsWithChildren &
  Interactable &
  ScriptProps & {
    metric: ScriptMetric;
  };

/**
 * MSup: The MathML <msup> element is used to attach an accent or a limit
 * over an expression. It uses the following syntax:
 * <msup>
 *    base
 *    superscript
 * </msup>
 */
export const MSup: React.FC<MSupProps> = ({
  baseHeight,
  children: [base, superscript],
  computedStyles: { backgroundColor },
  interactive,
  lowerFontSize,
  metric: { shift, spacing, scriptHeight },
  onSelect,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <span
      className={styles.msuperWrapper}
      onClick={onClickBetweenChildren(onSelect)}
      role={onSelect && 'button'}
    >
      <Bucket height={baseHeight} textAlign={interactive ? 'right' : undefined}>
        {base}
      </Bucket>
      <div
        className={styles.msuperscript}
        style={{
          marginBottom: shift,
          fontSize: lowerFontSize,
          marginLeft: spacing,
          textAlign: interactive ? 'left' : undefined,
        }}
      >
        <Bucket height={scriptHeight}>{superscript}</Bucket>
      </div>
    </span>
  </BackgroundColor>
);

MSup.displayName = 'MSup';

export const enrichMSup: EnrichNestedMathContent<MSupContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const {
    enrichedContent: base,
    refLine,
    ...baseLM
  } = mathContentEnricher(formulaStyles, content.base, [...path, BASE]);
  const { enrichedContent: superscript, ...scriptLM } = mathContentEnricher(
    formulaStyles,
    content.superscript,
    [...path, 'superscript']
  );
  const lowerFontSize = superscript.computedStyles.fontSize;

  const { height, ...metric } = getScriptMetric(
    baseLM,
    scriptLM,
    getSuperScriptOverlap(formulaStyles)
  );

  const props: MSupContent & Partial<MSupProps> = {
    ...content,
    base,
    baseHeight: baseLM.height,
    lowerFontSize,
    metric,
    superscript,
  };
  return enrich(
    props,
    {
      height,
      refLine: refLine,
      relativeToBaseLine: true,
    },
    formulaStyles
  );
};
