import { rulers } from '../../gizmo-utils/configuration/rulers';
import { stylers } from '../../gizmo-utils/configuration/stylers';
import { createContentDictReducer } from '../../gizmo-utils/redux';
import { reducers } from '../../gizmo-utils/configuration/reducers';
import { enrichReducer } from '../../gizmo-utils/measure';
import { emReducer } from '../emplayer';
import { gizmoViewerReducer } from '../gizmoviewer';
import {
  exerciseReducer,
  seriesPlayerReducer,
  seriesReducer,
  toolbarReducer,
  uploadReducer,
} from '../seriesplayer/containers';
import { applicationReducer } from '../seriesplayer/reducers';
import { calculatorReducer } from '../seriesplayer/containers/Toolbar/Tools/Calculator/reducer';
import { combineSeriesplayerReducer } from './combineSeriesplayerReducer';
import { contentDictReducer } from '../../gizmo-utils/redux/contentDictReducer';
import { criReducer } from '../criplayer/reducer';

export const combinedSeriesplayerReducer = combineSeriesplayerReducer(
  createContentDictReducer(contentDictReducer, reducers),
  [applicationReducer, uploadReducer, gizmoViewerReducer, seriesPlayerReducer, toolbarReducer],
  seriesReducer,
  exerciseReducer,
  emReducer,
  criReducer,
  enrichReducer(rulers, stylers),
  calculatorReducer
);
