import * as React from 'react';
import styles from './thermometer.scss';
import { range, round } from 'lodash';
import { getNamedColor } from '@bettermarks/importers';
import log from 'loglevel';

export const BORDERWIDTH = 2;
export const LABELOFFSET = 5;
export const OFFSETLABEL = 15;
export const OFFSETTOPLABEL = 20;
export const OUTER_PADDING = 10;
export const RADIUS = 12;
export const STARTXCOORDINATE = 40;
export const STARTYCOORDINATE = 350;
export const INNER_SVG_WIDTH = 50;
export const THERMOMETER_HEIGHT = 390;
export const TICKSIZE = 12;
export const TICKSIZE_SMALL = 6;
export const VERTICALLENGTH = 324;

export const MIN_TICK_POS_Y = STARTYCOORDINATE - OFFSETLABEL;
export const TICK_SPACE = VERTICALLENGTH - OFFSETLABEL - OFFSETTOPLABEL;
export const THERMOMETER_WIDTH = INNER_SVG_WIDTH + 2 * OUTER_PADDING;

export type Tick = {
  value: number;
  hasLabel: boolean;
  position: number;
};
/**
 * It calculates the tick position and the value from the min and max value
 * @param min
 * @param max
 * @param tickSpace
 */
export const calculateTicks = (min: number, max: number, tickSpace = TICK_SPACE): Tick[] =>
  range(min, max + 1).map((value, index, { length }) => ({
    value,
    hasLabel: index % 5 === 0,
    position: round((index * tickSpace) / (length - 1), 2),
  }));

export type ThermometerProps = {
  color?: string;
  label?: string;
  labelColor?: string;
  value: number;
  min: number;
  max: number;
};
/**
 * This is used for rendering the ticks
 * @param param0
 * @param index
 */
const renderTick = ({ hasLabel, position }: Tick, index: number) => {
  const tickSize = hasLabel ? TICKSIZE : TICKSIZE_SMALL;
  return (
    <rect
      key={`rect${index}`}
      className={hasLabel ? styles.primaryTick : styles.secTick}
      width={tickSize}
      height="2px"
      x={STARTXCOORDINATE - tickSize - BORDERWIDTH / 2}
      y={MIN_TICK_POS_Y - position}
    />
  );
};

/**
 *This is used for rendering the labels of the tick
 * @param labelColor
 * @param param1
 * @param index
 */
const renderTickLabel = (
  labelColor: string | undefined,
  { hasLabel, position, value }: Tick,
  index: number
) =>
  hasLabel && (
    <text
      className={styles.label}
      key={index}
      textAnchor="end"
      fill={labelColor}
      style={labelColor ? { fill: labelColor } : {}}
      x={STARTXCOORDINATE - 15 /*tick size + some padding*/}
      y={MIN_TICK_POS_Y + LABELOFFSET - position}
    >
      {value}
    </text>
  );

export const tickPositionToSvgPos = (pos: number) => STARTYCOORDINATE - OFFSETLABEL - pos;

export const Thermometer: React.FC<ThermometerProps> = ({
  color,
  label,
  labelColor,
  value,
  min,
  max,
}) => {
  const ticks = calculateTicks(min, max);
  // we only expect values to be one of the ticks values
  const tick = ticks.find((t) => t.value === value);
  let position = tick ? tick.position : min;
  if (tick === undefined) {
    // but we had kibana logs regarding position of undefined
    // so we added the below code to be more resilient in those cases
    if (ticks.length === 0) {
      log.warn({
        msg: 'Thermometer without ticks',
        extra: { min, max, value },
      });
    } else {
      position = ticks.reduce((tick, curr) => {
        // find tick closest to value
        return Math.abs(curr.value - value) < Math.abs(tick.value - value) ? curr : tick;
      }, ticks[0]).position;
      log.warn({
        msg: 'Thermometer searched closest tick',
        extra: { min, max, value, position },
      });
    }
  }
  const colorCSS = getNamedColor(color);
  const labelColorCSS = getNamedColor(labelColor);
  const fluidStyle = colorCSS ? { fill: colorCSS } : {};
  const labelStyle = labelColorCSS ? { fill: labelColorCSS } : {};
  return (
    <svg
      width={`${THERMOMETER_WIDTH}px`}
      height={`${THERMOMETER_HEIGHT}px`}
      viewBox={`0 0 ${THERMOMETER_WIDTH} ${THERMOMETER_HEIGHT}`}
    >
      {/*glas tube*/}
      <path
        d={`M${STARTXCOORDINATE} ${STARTYCOORDINATE}
              a${RADIUS} ${RADIUS} 0 1 0 10 0
              v-${VERTICALLENGTH} h-10 v${VERTICALLENGTH} z`}
        strokeWidth={BORDERWIDTH}
        className={styles.thermometer}
      />
      {/*circle and rect for the fluid*/}
      <circle
        cx={STARTXCOORDINATE + 5}
        cy={STARTYCOORDINATE + RADIUS - BORDERWIDTH / 2}
        r={RADIUS - BORDERWIDTH / 2}
        className={styles.fluidColor}
        style={fluidStyle}
      />
      <rect
        x={STARTXCOORDINATE + BORDERWIDTH / 2}
        className={styles.fluidColor}
        y={tickPositionToSvgPos(position)}
        width={10 - BORDERWIDTH}
        height={position + RADIUS + OFFSETLABEL}
        style={fluidStyle}
      />
      {ticks.map((t, i) => renderTickLabel(labelColorCSS, t, i))}
      {ticks.map(renderTick)}
      {/*unit label*/}
      label && (
      <text
        className={styles.label}
        x={STARTXCOORDINATE - 20 - TICKSIZE}
        y={MIN_TICK_POS_Y - VERTICALLENGTH + 8}
        fill={labelColorCSS}
        style={labelStyle}
        fontWeight="bold"
      >
        {label}
      </text>
      )
    </svg>
  );
};

Thermometer.displayName = 'Thermometer';
