import { type Action, createAction, handleActions } from 'redux-actions';
import { DEFAULT_OBJECT_SELECT_DECORATION } from '../constants';
import { GEO_DEFAULT_PERSIST_PROPS } from '@bettermarks/importers';
import {
  type GeoContentPersistProps,
  type GeoSelectState,
  HINT_EXPECTED,
  Hover,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { deselected } from './helpers';
import { resetSeverity } from '../helpers';

export interface SelectPayload {
  id: string;
  selectColor: string;
}

export interface ToggleSelectPayload extends SelectPayload {
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export type GeoSelectPayload = SelectPayload | ToggleSelectPayload | SnapPoint | void;

const HOVER: 'HOVER' = 'HOVER';
export const hoverAction = createAction<SelectPayload>(HOVER);
const SELECT: 'SELECT' = 'SELECT';
export const selectAction = createAction<ToggleSelectPayload>(SELECT);
const DESELECT: 'DESELECT' = 'DESELECT';
export const deSelectAction = createAction<ToggleSelectPayload>(DESELECT);
const OUT: 'OUT' = 'OUT';
export const outAction = createAction<SelectPayload>(OUT);

export const initialState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
};

export const geoSelectReducer = handleActions<GeoSelectState, GeoSelectPayload>(
  {
    [SELECT]: (state: GeoSelectState, { payload }: Action<ToggleSelectPayload>) => {
      if (!payload) {
        return state;
      }

      const { id, onPersistLocalState, selectColor } = payload;
      // 1. select object from map and remove hover from it.
      // 2. reset the severity when the user is selecting again
      //    to avoid having the severity color sticking around
      //    see. http://trac.bm.loc/ticket/43401
      const { hover, ...object } = resetSeverity(state.persistProps.geoContentMap[id]);

      const returnState: GeoSelectState = {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...object,
              originalDeco: object.decoration,
              decoration: {
                ...object.decoration,
                ...DEFAULT_OBJECT_SELECT_DECORATION,
                color: selectColor,
              },
              hint: HINT_EXPECTED,
            },
          },
        },
      };

      onPersistLocalState(returnState.persistProps);

      return returnState;
    },

    [DESELECT]: (state: GeoSelectState, { payload }: Action<ToggleSelectPayload>) => {
      if (!payload) {
        return state;
      }
      const { id, onPersistLocalState } = payload;
      // select object from map and remove hover from it
      const { hover, ...object } = state.persistProps.geoContentMap[id];

      const returnState = {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: deselected(object),
          },
        },
      };

      onPersistLocalState(returnState.persistProps);

      return returnState;
    },
    [HOVER]: (state: GeoSelectState, { payload }: Action<SelectPayload>) => {
      if (!payload) {
        return state;
      }
      const { id } = payload;

      const object = state.persistProps.geoContentMap[id];

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...object,
              hover: Hover.SELECT,
            },
          },
        },
      };
    },

    [OUT]: (state: GeoSelectState, { payload }: Action<SelectPayload>) => {
      if (!payload) {
        return state;
      }

      const { id } = payload;
      // select object from map and remove hover from it
      const { hover, ...object } = state.persistProps.geoContentMap[id];

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: object,
          },
        },
      };
    },
  },
  initialState
);
