import * as React from 'react';
import {
  type CommonCallbacks,
  type FractionFormContent,
  FractionFormEditorMode,
} from '@bettermarks/gizmo-types';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { FractionFormRenderer } from './components/FractionFormRenderer';
import { switchMap } from '../../../gizmo-utils/fpTools';
import { Divide, Remove, ToggleFill } from './components/tools';

export type FractionFormCallbacks = {
  onToggleSegment?: (segIndex: number) => void;
  onSetSegments?: (count: number) => void;
  onRemoveForm?: (formIndex: number) => void;
};

export type FractionFormProps = FractionFormContent &
  ContextState &
  FractionFormCallbacks &
  CommonCallbacks;

export const FractionForm: React.FC<FractionFormProps> = ({
  selectedMode,
  onFocus,
  ...content
}) => {
  const FormComponent = switchMap<React.FC<FractionFormProps>>(
    {
      [FractionFormEditorMode.ToggleFill]: ToggleFill,
      [FractionFormEditorMode.Divide]: Divide,
      [FractionFormEditorMode.Remove]: Remove,
    },
    FractionFormRenderer
  )(selectedMode);

  return (
    <span tabIndex={0} onFocus={onFocus}>
      <FormComponent {...content} />
    </span>
  );
};
