import { type ToolbarElements } from '@bettermarks/gizmo-types';

/**
 * Creates a Set containing the available tools after removing the `filter`ed ones.
 *
 * @param filters Any toggle that is marked as `false` in this dict
 *        will not be present in the result (e.g. `{dnd: false}`).
 * @param toggles the initial list of toggles from the state
 */
export const getAvailableToggles = (
  filters: Partial<Record<ToolbarElements, boolean>>,
  toggles: ToolbarElements[]
) => new Set(toggles.filter((tool) => filters[tool] !== false));
