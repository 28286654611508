import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { ShouldEnrichKind } from '@bettermarks/gizmo-types';

import { selectItem } from './multipleChoiceDropdownActions';
import {
  MultipleChoiceDropdown,
  type MultipleChoiceDropdownCallbacks,
} from './MultipleChoiceDropdown';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): MultipleChoiceDropdownCallbacks => ({
  itemClicked: (item: number) =>
    dispatch(
      gizmoAction(selectItem({ item }), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
      })
    ),
});

export const MultipleChoiceDropdownContainer = connectGizmo(
  mapDispatchToProps,
  MultipleChoiceDropdown
);
