import { type FontStyle, type FontWeight } from '@bettermarks/bm-font';
import { isArray, isEmpty } from 'lodash';
import { type CSSProperties } from 'react';
import type React from 'react';
import { type Annotations, type Content } from '../xml-converter/core';
import { type Severity } from '@bettermarks/umc-kotlin';
import { type INPUT_FOCUS } from './constants';
import * as RS from './configuration/renderStyles';
import { type ContentColor } from '../styles/colors';

export enum Direction {
  Left = 'left',
  Right = 'right',
}

export const enum GraphEditorModes {
  Add = 'graph_add',
  Connect = 'graph_connect',
  Move = 'graph_move',
  Remove = 'graph_remove',
  Grab = 'graph_grab',
  Label = 'graph_label',
  SCROLL = 'metrics_tool_scroll',
}

export type GraphEditorMode = GraphEditorModes | ContentColor;

export const enum FractionFormEditorMode {
  ToggleFill = 'form_toggle_fill',
  Add = 'form_add',
  Remove = 'form_remove',
  Divide = 'form_divide',
}

export const enum GeoEditorMode {
  ADD_ANGLE = 'metrics_tool_angle',
  ADD_POINT = 'metrics_add_point',
  ADD_LABEL = 'metrics_add_label',
  ADD_CIRCLE = 'metrics_add_circle',
  ADD_SEGMENT = 'metrics_add_segment',
  ADD_SEGMENT_DASHED = 'metrics_add_segment_dashed',
  ADD_RAY = 'metrics_add_ray',
  ADD_RAY_DASHED = 'metrics_add_ray_dashed',
  ADD_STRAIGHTLINE = 'metrics_add_straightline',
  ADD_STRAIGHTLINE_DASHED = 'metrics_add_straightline_dashed',
  ADD_VECTOR = 'metrics_add_vector',
  ADD_VECTOR_DASHED = 'metrics_add_vector_dashed',
  DEFAULT = 'default',
  DEFAULT_CURSOR = 'metrics_default_cursor',
  DELETE = 'remove',
  INTERVAL = 'metrics_tool_interval',
  MIDDLEPOINT = 'metrics_tool_middlepoint',
  MOVE = 'move',
  MOVE_POINTS_VERTICALLY = 'metrics_move_points_vertically',
  NOT_INTERACTIVE = 'not_interactive',
  PARALLELS = 'metrics_tool_parallels',
  PERPENDICULAR = 'metrics_tool_perpendicular',
  SELECT = 'metrics_tool_selection',
  COLORING = 'metrics_polygon_selection',
  SCROLL = 'metrics_tool_scroll',
}

export type EditorMode = FractionFormEditorMode | GeoEditorMode | GraphEditorMode | '';

export type InputDecoration = Severity | typeof INPUT_FOCUS | undefined;

export type NumberOrTuple = number | [number, number];

export type ScriptMetricInput = {
  height: number;
  /**
   * The vertical padding the component will add.
   * If there is a difference between left and right, the value is a tuple for `[left, right]`
   */
  padding?: NumberOrTuple;
};

export type LayoutMetrics = ScriptMetricInput & {
  refLine: number;
  relativeToBaseLine?: boolean;
};

/**
 * Converts a `NumberOrTuple` value to a `[number, number]` tuple or consistent usage of the values.
 */
export const toNumberTuple = (either: NumberOrTuple = 0): [number, number] =>
  isArray(either) ? either : [either, either];

export const DEFAULT_LAYOUT_METRICS: Readonly<LayoutMetrics> = {
  height: 0,
  refLine: 0,
};

export const DEFAULT_LAYOUT_METRICS_RELATIVE: Readonly<LayoutMetrics> = {
  ...DEFAULT_LAYOUT_METRICS,
  relativeToBaseLine: true,
};

export type MetricsGetter = (refId: string) => Readonly<LayoutMetrics>;

export type FontProps = {
  fontSize: number;
  fontWeight?: FontWeight;
  fontStyle?: FontStyle;
};

export type StyleProps = {
  color?: string;
  backgroundColor?: string;
  textAlign?: TextAlignment;
  textShadow?: string;
  whiteSpace?: WhiteSpace;
};

export type FormulaStyles = FontProps &
  StyleProps & {
    interactive?: boolean;
    marginLeft?: number;
    marginRight?: number;
  };
export type ContentPath = ReadonlyArray<string | number>;
export type StyleResolver = (fp: ContentPath) => Readonly<FormulaStyles>;

export type ComputedStyles = CSSProperties & Partial<FontProps>;

export type TextAlignment = 'left' | 'center' | 'right';

export type WhiteSpace =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-line'
  | 'pre-wrap'
  | 'initial'
  | 'inherit';

export enum MouseCursor {
  default = 'default',
  grab = 'grab',
  grabbing = 'grabbing',
}

export function isTextAlignment(arg?: string): arg is TextAlignment {
  return arg === 'left' || arg === 'center' || arg === 'right';
}

export type Decoration = Partial<FormulaStyles>;

/**
 * Tests `$interactionType` property of `content` is a valid interaction type.
 *
 * Only returns `true` if `content.$interactionType` is not an empty string.
 *
 * This function exists to have a consistent way of doing this decision.
 */
export const hasInteractionType = <T>(
  content: undefined | Readonly<T & Pick<Annotations, '$interactionType'>>
): content is T & Pick<Annotations, '$interactionType'> =>
  !!content && !isEmpty(content.$interactionType);

/**
 * The most common check before attaching any interactive behavior to a gizmo:
 * When content has an $interactionType and is not `disabled`,
 * it is in the `interactive` mode.
 *
 * In all cases where `rendered` and `disabled` modes are treated equally
 * both cases can be checked by negating the result of this function.
 *
 * @see hasInteractionType
 */
export const isInteractive = (content: Content | undefined) =>
  hasInteractionType(content) && !content.disabled;

export type DecorationWithSeverity = {
  decoration: Decoration;
  severity?: Severity;
};

export type CommonCallbacks = {
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
};

export const enum ShouldEnrichKind {
  justEnrich = 'enrich',
  enrichDisable = 'enrichDisable',
  enrichEnable = 'enrichEnable',
}

export type ShouldEnrich = {
  shouldEnrich?: ShouldEnrichKind;
};

export const enum VAlign {
  bottom = 'bottom',
  middle = 'middle',
  top = 'top',
}

export const enum FlexAlign {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
}

export const isVAlign = (value?: string): value is VAlign =>
  value === VAlign.bottom || value === VAlign.middle || value === VAlign.top;

/**
 * In the content the values of vAlign and hAlign have opposite meaning:
 * To have more maintainable code we decided to flip the meaning of VAlign
 * when moving from XML to Annotations.
 * Has to be used for import and export, so the XML stays consistent.
 *
 * http://wiki.bm.loc/index.php/EPL:XYLayout
 */
export const translateVAlign = (value: VAlign): VAlign => {
  if (value === VAlign.bottom) return VAlign.top;
  if (value === VAlign.top) return VAlign.bottom;
  return value;
};

export const enum HAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export const isHAlign = (value?: string): value is HAlign =>
  value === HAlign.left || value === HAlign.center || value === HAlign.right;

export const isTableCell = (rs: string) => rs === RS.TABLECELL_TEXT || rs === RS.DIGIT;

export type MouseOrTouch =
  | React.MouseEvent<HTMLElement | SVGElement>
  | React.TouchEvent<HTMLElement | SVGElement>;

export const enum SortingDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
}
