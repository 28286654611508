import * as React from 'react';
import classNames from 'classnames';
import defaultTo from 'lodash/defaultTo';
import { getNamedColor } from '@bettermarks/importers';
import { CheckSmall, ChevronDownSmall } from '../../../components';
import styles from './DropdownOptionContent.scss';

export interface DropdownOptionContentProps {
  hasError?: boolean;
  isColorBased?: boolean;
  isSelected: boolean;
  decorationColor: string | undefined;
  number: number;
  isStatic?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const getContent = (
  isColorBased: boolean,
  isSelected: boolean,
  isStatic: boolean,
  number: number
): JSX.Element | number | null => {
  if (isStatic) {
    return (
      <>
        {isColorBased ? null : <span className={styles.number}>{number}</span>}
        <ChevronDownSmall
          className={classNames(styles.icon, !isColorBased && styles.iconWithNumber)}
        />
      </>
    );
  }

  return isColorBased ? isSelected ? <CheckSmall className={styles.icon} /> : null : number;
};

const getStyle = (
  isColorBased: boolean,
  isSelected: boolean,
  decorationColor: string | undefined
): React.CSSProperties => {
  const color = getNamedColor(defaultTo<string>(decorationColor, 'bm-grey'));
  return isColorBased
    ? { backgroundColor: color }
    : isSelected
    ? { backgroundColor: color }
    : { borderColor: color, color };
};

const DropdownOptionContent: React.FC<DropdownOptionContentProps> = ({
  hasError = false,
  isColorBased = true,
  isStatic = false,
  isFirst = false,
  isLast = false,
  isSelected,
  number,
  decorationColor,
}) => (
  <>
    <div
      style={getStyle(isColorBased, isSelected, decorationColor)}
      className={classNames(
        styles.sliderPicker,
        !isColorBased && styles.sliderPickerWithNumber,
        !isColorBased && isSelected && styles.selectedWithNumber
      )}
      role="button"
    >
      {getContent(isColorBased, isSelected, isStatic, number)}
    </div>
    {hasError && (
      <div
        className={classNames(
          styles.errorDecoration,
          isFirst && styles.errorDecorationFirst,
          isLast && styles.errorDecorationLast
        )}
      />
    )}
  </>
);

DropdownOptionContent.displayName = 'DropdownOptionContent';

export default DropdownOptionContent;
