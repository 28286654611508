import {
  type CalendarGizmoContent,
  CONFIGURATION,
  type DaySpan,
  DEFAULT_CALENDAR_GIZMO_CONTENT,
  type FirstDayOfWeek,
  type Importer,
  SET,
  xmlTextToBoolean,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';

export const importCalendar: Importer<CalendarGizmoContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);
  const setOf = mrow.findChildTagWithAttribute(SET, 'of', 'dayspans');
  const { month, count, year } = configuration.tagsToProps(
    xmlTextToInt,
    ['month', 'count'],
    ['year']
  );
  const firstDayOfWeek = configuration.findChildTag('firstDayOfWeek').text as FirstDayOfWeek;
  const showWeekDays = xmlTextToBoolean(configuration.findChildTag('showWeekDays'));
  const daySpans: DaySpan[] = setOf.children.map<DaySpan>((mrow) => {
    const [from, to] = mrow.children.map((mtext) => mtext.text);
    return { from, to };
  });

  return {
    ...DEFAULT_CALENDAR_GIZMO_CONTENT,
    ...preContent,
    ...(year ? { year } : {}),
    month, // Note: 1-indexed (1 to 12)
    count,
    firstDayOfWeek,
    showWeekDays,
    daySpans,
  };
};
