import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { isWebviewInOutdatedIOs } from '@bettermarks/browserslist-config/dist/isWebviewInOutdatedIOs';
import { type ApplicationState, ProblemReportedState } from '../../../../../types';
import { reportProblem } from './actions';
import {
  ReportProblem,
  type ReportProblemDispatchProps,
  type ReportProblemStateProps,
} from './ReportProblem';
import { closeDialog } from '../../SeriesPlayer/actions';
import { type ReportProblemPayload } from './types';

const mapDispatchToProps = (dispatch: Dispatch): ReportProblemDispatchProps => ({
  onCancel: () => dispatch(closeDialog()),
  onSubmit: (data: ReportProblemPayload) => dispatch(reportProblem(data)),
});

const mapStateToProps = (state: ApplicationState): ReportProblemStateProps => {
  return {
    reportState: isWebviewInOutdatedIOs()
      ? ProblemReportedState.unsupported
      : state.reportProblemState,
  };
};

export const ReportProblemContainer = connect(mapStateToProps, mapDispatchToProps)(ReportProblem);
