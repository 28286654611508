import { Severity } from '@bettermarks/umc-kotlin';
import {
  type Annotations,
  DATA,
  DECORATION,
  type FElement,
  parseCommonAttribs,
  PLACEHOLDER,
  type SelectedItem,
  SEMANTICS,
} from '@bettermarks/gizmo-types';
import { isEmpty } from 'lodash';

/**
 * The placeholder tag in MC doesn't always have a render-style attribute,
 * but the $id is Mandatory for every data and placeholder field inside MC
 */
export type SemanticChild = Partial<Annotations> & {
  $id: string;
  xml: FElement;
};

/**
 * The parsing of semantics nodes that is needed by every mc importer.
 * There are some amount of `data` and a single `placeholder` semantics
 * in the top level mrow.
 * @returns {{options: SemanticChild[], selection: FElement, placeholder: Partial<Annotation>}}
 */
export const parseSemanticChildren = (mrow: FElement) => {
  // get all semantics nodes: choices + solution
  const allSemantics = mrow.getChildrenByTagName(SEMANTICS).map(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    (xml): SemanticChild => ({ ...parseCommonAttribs(xml), xml } as SemanticChild)
  );
  // $ key is redundant since it is always 'placeholder'
  const {
    xml: selection,
    $,
    ...annotations
  }: SemanticChild = allSemantics.filter(({ $ }) => $ === PLACEHOLDER)[0];
  const options: SemanticChild[] = allSemantics.filter(({ $ }) => $ === DATA);
  // filter out empty renderstyle from parseCommonAttribs (it is present sometimes)
  const { $renderStyle, ...placeholder } = annotations;
  return {
    options,
    placeholder: $renderStyle ? annotations : placeholder,
    selection,
  };
};

export const severityDeco = (node: FElement): Severity | undefined =>
  node.attribute(DECORATION, undefined) === Severity.error ? Severity.error : undefined;

const parseItem =
  (choicesIds: string[]) =>
  (mn: FElement, severity = severityDeco(mn)): SelectedItem => {
    // it sounds strange but in the old mathml the answer is set as (id - 1)
    // =>  don't ask why, nobody knows that or can even find any explanation
    const magicPointer = (parseInt(mn.text, 10) + 1).toString();
    const index = choicesIds.indexOf(magicPointer);
    return severity ? { index, severity } : { index };
  };

export const getSelectedItems = (semantic: FElement, choicesIds: string[]): SelectedItem[] => {
  const parser = parseItem(choicesIds);
  return semantic
    .findChildTag('mrow')
    .findChildTag('set')
    .getChildrenByTagName('mn')
    .map((mn) => parser(mn));
};

export const getSelectedItem = (
  semantic: FElement,
  choicesIds: string[],
  customSeverity?: Severity
): SelectedItem => {
  const [item] = semantic
    .findChildTag('mrow')
    .findChildTag('set')
    .getChildrenByTagName('mn')
    .map((mn) => parseItem(choicesIds)(mn, customSeverity));

  const index = item ? item.index : -1;
  const severity = item ? item.severity : customSeverity;

  return severity ? { index, severity: severity } : { index };
};

export const getElementsPerLine = (config: FElement): number => {
  // ignore horizontal/vertical in case maxElementsPerRow is defined
  const elementsPerLine = config.findChildTag('maxElementsPerRow').text;
  if (!isEmpty(elementsPerLine)) {
    return parseFloat(elementsPerLine);
  }

  // vertical forces 1 element per line
  const direction = config.findChildTag('direction').text;
  if (direction === 'vertical') {
    return 1;
  }

  // if there is no maxElementsPerRow tag one does not limit it for direction horizontal
  return Number.MAX_VALUE;
};
