import { isNil, isNaN } from 'lodash';
import log from 'loglevel';

/**
 * Securely parse a numeric value from a SASS file that is going to be used as a constant.
 *
 * In case of a typo the value passed to the function will be undefined,
 * so it uses `logger` to warn about this case.
 *
 * If parsing the value results in `NaN` th method also uses `logger` to report that case.
 *
 * in both of the unexpected cases the values returned is `0`,
 * so further calculation with those constants do not result in more `NaN` values.
 *
 * @param {string | null | undefined} value
 * @param logger optional target for warning messages, log.warn is used by default
 * @returns {number}
 */
export const numberFromStyles = (value: Nullable<string>, logger = log.error): number => {
  if (isNil(value)) {
    logger('possible typo or missing export when importing value from styles, returning 0');
    return 0;
  }
  const numeric = parseFloat(value);
  if (isNaN(numeric)) {
    logger('parsed NaN for an expected numeric value, returning 0');
    return 0;
  }
  return numeric;
};
