export const ENDSCREEN_ANIMATION_START_DELAY = 300;
export const ENDSCREEN_ANIMATION_DURATION = 2000;
export const ENDSCREEN_ELEMENT_ANIMATION_DURATION = 500;

// http://wiki.bm.loc/index.php/NSP:_Functional_details#Stars_and_coins
export const ENDSCREEN_COINS_THRESHOLDS: [number, number, number] = [60, 75, 90];

export const enum EndscreenMode {
  practice = 'practice',
  test = 'test',
  message = 'message',
}
