import * as React from 'react';

export default function Track({ width = '36', height = '164', color = 'black' }) {
  return (
    <svg
      id="Ebene_1"
      x="0px"
      y="0px"
      viewBox="0 0 32 164"
      width={32}
      height={164}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs41" />
      <polygon
        className="st0"
        points="146,26 18,16 146,6 "
        id="polygon36"
        transform="rotate(-90,82,82)"
        style={{ fill: color }}
      />
    </svg>
  );
}
