import type * as Redux from 'redux';
import { type Action, type ActionMeta, type BaseAction } from 'redux-actions';
import { type ApplicationState } from '../../types';

export interface Dispatch extends Redux.Dispatch<any> {
  (action: any): ApplicationState;
}

export class EmptyPayloadException<P> extends Error {
  constructor(action: Action<P>) {
    super(`Action needs payload: ${action}`);
  }
}

export type AnyAction<P = any, M = any> = Action<P> | ActionMeta<P, M> | BaseAction;

export const hasPayload = (action: AnyAction): action is Action<any> | ActionMeta<any, any> =>
  'payload' in action;
