import * as React from 'react';
import { defaultTo, flowRight, isEmpty } from 'lodash';

import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  CursorPositionOffset,
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type GeoScene,
  type MouseOrTouch,
  POINT,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { type GeoProps } from '../../Geo';
import { GeoRenderer } from '../../GeoRenderer';
import { getSnapPoint } from '../../snap';
import { mousePos, screenToWorld } from '@bettermarks/importers';
import { persistProps } from '../persist';
import {
  deleteAction,
  geoDeleteReducer,
  hideDeleteLabelAction,
  hoverAction,
  initialState,
  outAction,
  showDeleteLabelAction,
  snapAction,
  stopSnapAction,
} from './geoDeleteReducer';
import { addedByUser } from '../helpers';

export type GeoDeleteState = GeoInteractiveBaseState & {
  deleteObjectId: string;
};

export const GeoDelete: React.FC<GeoProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoDeleteReducer}
    componentName={`Delete:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { configuration, uniqueId, isTouch, matrix, onPersistLocalState, scale } = props;
      const { labelValues } = configuration;

      const snap = (scene: GeoScene) => (evt: MouseOrTouch) => {
        const snapPoint: SnapPoint | null = flowRight([
          getSnapPoint(matrix, scene, scale),
          mousePos(defaultTo<string>(uniqueId, ''), CursorPositionOffset.NONE),
        ])(evt);

        if (
          snapPoint &&
          snapPoint.snapObject === POINT &&
          addedByUser(snapPoint.id, props.geoContentMap) &&
          !isTouch
        ) {
          dispatch(snapAction(snapPoint));
          dispatch(hoverAction({ id: snapPoint.id, labelValues }));
        } else {
          dispatch(stopSnapAction());
          // handles the special case to leave a snapPoint that is on a line:
          // as the snapRadius is bigger than the clickArea of the line you will otherwise not lose
          // the line's delete decoration
          if (state.snapPoints.length > 0) {
            const oldSnapPoint = state.snapPoints[0];

            if (addedByUser(oldSnapPoint.id, props.geoContentMap)) {
              dispatch(outAction({ id: oldSnapPoint.id, labelValues }));
            }
          }
        }
      };

      const stopSnap = () => dispatch(stopSnapAction());

      const out = (id: string) => (evt: React.MouseEvent<any>) =>
        dispatch(outAction({ id, labelValues }));

      const deleteObj = (id: string) => (evt: MouseOrTouch) => {
        if (addedByUser(id, state.persistProps.geoContentMap)) {
          if (isTouch) {
            const mouseP = flowRight([
              screenToWorld(matrix),
              mousePos(defaultTo<string>(uniqueId, ''), CursorPositionOffset.NONE),
            ])(evt);
            dispatch(showDeleteLabelAction({ id, labelValues, mouseP }));
          } else {
            dispatch(deleteAction({ id, onPersistLocalState }));
          }
        } else {
          dispatch(hideDeleteLabelAction());
        }
      };

      const hideDeleteLabel = () => dispatch(hideDeleteLabelAction());

      const deleteObjTouch = (id: string) => (evt: MouseOrTouch) =>
        !isEmpty(state.deleteObjectId) &&
        dispatch(deleteAction({ id: state.deleteObjectId, onPersistLocalState }));

      const hover = (id: string) => (evt: MouseOrTouch) => {
        if (addedByUser(id, state.persistProps.geoContentMap)) {
          // first un-hover on all other objects
          dispatch(outAction());
          dispatch(hoverAction({ id, labelValues }));
        }
      };

      const geoContentProps = {
        ...props,
        ...state.persistProps,
      };

      return (
        <GeoRenderer
          mode={GeoEditorMode.DELETE}
          onMouseMove={snap}
          onMouseLeave={stopSnap}
          onPointClick={deleteObj}
          onPointMouseOut={out}
          onLineClick={deleteObj}
          onLineHover={hover}
          onLineOut={out}
          onCircleClick={deleteObj}
          onCircleOver={hover}
          onCircleLeave={out}
          onIntervalClick={deleteObj}
          onIntervalOver={hover}
          onIntervalLeave={out}
          onLabelClick={deleteObjTouch}
          onAnyOtherClick={hideDeleteLabel}
          snapPoints={state.snapPoints}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoDelete.displayName = 'GeoDelete';
