import { type Action, createAction, handleActions } from 'redux-actions';
import { type ALayoutContent, DEFAULT_A_LAYOUT_CONTENT } from '@bettermarks/gizmo-types';

export interface ScaleDownPayload {
  scale: number;
}

const SCALE_DOWN = 'SCALE_DOWN';
export const scaleDown = createAction<ScaleDownPayload>(SCALE_DOWN);

export type AbsoluteLayoutPayload = ScaleDownPayload;

export const absoluteLayoutReducer = handleActions<ALayoutContent, AbsoluteLayoutPayload>(
  {
    [SCALE_DOWN]: (state: ALayoutContent, { payload }: Action<ScaleDownPayload>) => {
      if (!payload) {
        return state;
      }
      return { ...state, scale: payload.scale };
    },
  },
  DEFAULT_A_LAYOUT_CONTENT
);
