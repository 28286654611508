import { type Action, handleActions } from 'redux-actions';
import { type MultipleChoiceSingleContent } from '@bettermarks/gizmo-types';
import { SELECT_ITEM, type MultipleChoiceSingleActionPayload } from './multipleChoiceActions';

const initialState: MultipleChoiceSingleContent = {
  $: 'special',
  $interactionType: 'multiple-choice-single',
  $id: '',
  $renderStyle: 'multiple-choice-single',
  choices: [],
  configuration: {},
  checkedIdx: -1,
};

export const multipleChoiceSingleReducer = handleActions<
  MultipleChoiceSingleContent,
  MultipleChoiceSingleActionPayload
>(
  {
    [SELECT_ITEM]: (
      { severity, ...state },
      { payload }: Action<MultipleChoiceSingleActionPayload>
    ) =>
      payload !== undefined &&
      payload >= 0 &&
      // set the checked index to the new value. If it's a new index, also remove
      // the severity property from the state.
      // We must select the new option and remove the severity from previously selected option.
      payload < state.choices.length
        ? {
            ...state,
            checkedIdx: payload,
            ...(state.checkedIdx === payload && severity && { severity }),
          }
        : {
            ...state,
            ...(severity && { severity }),
          },
  },
  initialState
);
