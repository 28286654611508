import * as React from 'react';
import { type GraphRendererProps, GraphRenderer } from '../GraphRenderer';
import { type GraphNode, type Position, type GraphEdge } from '@bettermarks/gizmo-types';
import {
  getWorldPos,
  doesntConnectNode,
  connectsNode,
  useDOMEvent,
  onPickEvent,
  clampPosition,
  coveredNodes,
} from '../helpers';
import { withHoverHighlight } from './withHoverHighlight';
import { TOUCH_OFFSET, MIN_NODE_RADIUS, MIN_NODE_DIAMETER } from '@bettermarks/importers';
import { Maybe } from '../../../..//utils/maybe';
import {
  startScrolling,
  stopScrolling,
  type DragScrollableProps,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export type MoveNodeProps = GraphRendererProps &
  DragScrollableProps & {
    onMoveNode?: (id: string, position: Position) => void;
  };

/**
 * MoveNode: Graph editor mode for moving nodes around. You can drag nodes (including their
 * connections) around with the mouse or via touch. Dragging to the left or right border will scroll
 * the view accordingly.
 */
const MoveNode_: React.FC<MoveNodeProps> = (props) => {
  const { edges, nodes, onMoveNode, scrollBehaviour, width, height } = props;

  const spanEl = React.useRef<HTMLSpanElement>(null);
  /**
   * the node that's moved around
   */
  const [movedNode, setMovedNode] = React.useState<GraphNode | null>(null);

  /**
   * Scroll behaviour that scrolls if you drag inside a left or right sensitive area.
   */
  const maybeScrollBehaviour = Maybe(scrollBehaviour);

  const r = MIN_NODE_RADIUS;

  /**
   * Set the moved node (no action dispatched). If the current mouse/touch position covers other
   * nodes, then do nothing and leave the moved node in it's previous place.
   */
  const onMove = (pos: Position) => {
    const worldPos = getWorldPos(pos.x, pos.y, spanEl);
    const position = clampPosition(worldPos, r, width - r, r, height - r);
    const otherNodes = nodes.filter((n) => !movedNode || n.id !== movedNode.id);
    const covered = coveredNodes(position, MIN_NODE_DIAMETER)(otherNodes);
    if (movedNode && covered.length === 0) {
      setMovedNode({
        ...movedNode,
        highlight: true,
        position,
      });
    }
  };

  /*--------------------------------------------------------------------------------*
   *                              Event Handlers                                    *
   *--------------------------------------------------------------------------------*/

  const onMouseMove: React.MouseEventHandler<any> = (evt) => {
    if (movedNode) {
      onMove({ x: evt.clientX, y: evt.clientY });
    }
  };

  const onTouchMove = (evt: TouchEvent) => {
    if (movedNode) {
      evt.preventDefault();
      onMove({
        x: evt.changedTouches[0].clientX + TOUCH_OFFSET.x,
        y: evt.changedTouches[0].clientY + TOUCH_OFFSET.y,
      });
    }
  };

  const onMoveEnd = () => {
    if (movedNode && movedNode.id) {
      onMoveNode && onMoveNode(movedNode.id, movedNode.position);
    }
    setMovedNode(null);
    maybeScrollBehaviour.ap(stopScrolling);
  };

  const onStartMove = (node: GraphNode) => {
    node.addedByUser && setMovedNode(node);
    maybeScrollBehaviour.ap(startScrolling);
  };

  const onMouseDown = onPickEvent(spanEl, edges, nodes, onStartMove);

  useDOMEvent(
    'touchstart',
    onPickEvent<TouchEvent>(spanEl, edges, nodes, onStartMove, undefined, undefined, false),
    spanEl,
    [movedNode, nodes]
  );
  useDOMEvent('touchend', onMoveEnd, spanEl, [movedNode]);
  useDOMEvent('touchmove', onTouchMove, spanEl, [movedNode]);

  /*--------------------------------------------------------------------------------*
   *                         Transform Edges and Nodes                              *
   *--------------------------------------------------------------------------------*/

  // Get the other nodes that are not moved and remove the highlight
  const updatedNodes = movedNode
    ? [
        ...nodes
          .filter((n) => !movedNode || n.id !== movedNode.id)
          .map((n) => ({ ...n, highlight: false })),
        movedNode,
      ]
    : nodes.map((n) => ({
        ...n,
        highlight: !n.addedByUser ? false : n.highlight,
      }));

  // Highlight all edges that are connected to the moved node
  const highlightedEdges = movedNode
    ? [
        ...edges.filter(doesntConnectNode(movedNode.id)),
        ...edges.filter(connectsNode(movedNode.id)).map<GraphEdge>((e) => ({
          ...e,
          highlight: true,
        })),
      ]
    : edges;

  return (
    <span
      ref={spanEl}
      role="button"
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMoveEnd}
    >
      <GraphRenderer
        {...props}
        nodes={updatedNodes}
        edges={highlightedEdges}
        scrollBehaviour={scrollBehaviour}
      />
    </span>
  );
};

MoveNode_.displayName = 'MoveNode';

export const MoveNode = withHoverHighlight(MoveNode_);
