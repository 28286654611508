import * as React from 'react';
import classNames from 'classnames';
import styles from './DropdownButton.scss';

export interface DropdownButtonProps {
  item?: JSX.Element;
  open: boolean;
  hasError: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onOpenMenu?: React.MouseEventHandler<{}>;
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  item,
  open,
  hasError,
  onOpenMenu,
}) => (
  <div
    className={classNames(
      styles.button,
      open ? styles.open : styles.closed,
      hasError && styles.buttonWithError
    )}
    role="menu"
    onClick={onOpenMenu}
  >
    <div className={styles.iconWrapper}>{item}</div>
  </div>
);

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
