import log from 'loglevel';
import { type ExporterRegistry, type ImporterRegistry } from '../../gizmo-utils/configuration';
import { toBoolean } from '../../gizmo-utils/filters';
import { isEmpty } from 'lodash';
import {
  type ContentExercise,
  type ContentStep,
  fromStringToInstructionHelpType,
  type InstructionHelpType,
} from '../../types';
import {
  type ContentTreeImporter,
  exportContentTree,
  importContentTree,
} from '@bettermarks/importers';
import {
  ContentDict,
  DependencyCollector,
  type FElement,
  RS,
  xmlText,
  xmlTextToList,
} from '@bettermarks/gizmo-types';
import { type Validation, ValidationType, type ValidatorEngine } from '@bettermarks/umc-kotlin';

function firstText(list: FElement[], fallback: string): string {
  if (!isEmpty(list) && !isEmpty(list[0].text)) {
    return list[0].text;
  } else {
    return fallback;
  }
}

export const _importWrappedContent = (
  importers: ImporterRegistry,
  dependencies: DependencyCollector,
  exerciseId: string
) => {
  const importTree = importContentTree(importers, dependencies, exerciseId);
  return (xml: FElement, pathToContentDict: string) =>
    importTree(xml.firstChild, pathToContentDict);
};

/**
 * This function parses an exercise XML and creates an `Exercise` with
 * the `ContentMap` added to it
 *
 *
 * @param {XMLDocument} xml
 * @param importers
 * @param exporters
 * @param dependencies
 * @returns Exercise
 */
export function importExerciseXML(
  xml: FElement,
  importers: ImporterRegistry,
  exporters: ExporterRegistry,
  dependencies = new DependencyCollector()
): ContentExercise {
  const id = xml.attribute('id', '');

  const importWrappedContent = _importWrappedContent(importers, dependencies, id);

  const kemId = xml.findChildTag('skillName').attribute('skillId');
  dependencies.kems.add(kemId);

  const xmlSteps = xml.findChildTag('steps');

  const setting = importWrappedContent(xml.findChildTag('setting'), 'setting');

  const steps = xmlSteps.children.map((step, i) =>
    importStep(step, importWrappedContent, exporters, `steps[${i}]`)
  );

  let wrapup: ContentDict | undefined;
  if (xml.findChildTag('wrapup').hasChildren()) {
    wrapup = importWrappedContent(xml.findChildTag('wrapup'), 'wrapup');
  }

  return {
    id,
    locale: xml.attribute('locale', ''),
    dependencies: dependencies.dependencies,
    exerciseType: xml.findChildTag('exerciseTypeId').text,
    featuresTimestamp: xml.findChildTag('featuresTimestamp').text,
    description: xml.findChildTag('description').text,
    setting,
    steps,
    ...(wrapup && { wrapup }),
  };
}

export function importStep(
  stepXml: FElement,
  importWrappedContent: ContentTreeImporter,
  exporters: ExporterRegistry,
  pathToStep: string
): ContentStep {
  // Find instructionHelp and add if it exists
  let instructionHelp: { type: InstructionHelpType; text: string } | null = null;

  if (stepXml.hasChild('instructionHelp')) {
    const instructionHelpTag = stepXml.findChildTag('instructionHelp');
    instructionHelp = {
      type: fromStringToInstructionHelpType(instructionHelpTag.attribute('type')),
      text: instructionHelpTag.text,
    };
  }

  const { answer, explanation, instruction, question } = stepXml.tagsToProps(
    (element) => importWrappedContent(element, `${pathToStep}.${element.localName}`),
    ['answer', 'explanation', 'instruction', 'question']
  );

  /**
   * In case the step has geo as interaction tool,
   * we need to make sure the validator finds the optimized geo data not only in the `userAnswerXML`
   * but also in the `answerXML` and the `questionXML` that is used to validate `userAnswerXML`
   * -> exporting imported data instead of taking as is
   */
  const questionContent = ContentDict.content(question);
  const isGeoQuestion =
    (questionContent?.$interactionType &&
      // caters for the 2 geo interactionTypes METRICS_SYSTEM and METRICS_POLYGONSELECTION
      questionContent.$interactionType.startsWith('metrics-')) ||
    // function-plotter XML can contain template syntax that is not compatible with the
    // XMLParser of our validator (<sometag>{0}</sometag>, without passing a value for 0)
    // -> a round of import/export to replace this syntax with a validator friendly one
    questionContent?.$renderStyle === RS.FUNCTION_PLOTTER;
  const questionXML = isGeoQuestion
    ? exportContentTree(exporters, question)
    : stepXml.findChildTag('question').firstChild.toString();
  const answerXML = isGeoQuestion
    ? exportContentTree(exporters, answer)
    : stepXml.findChildTag('answer').firstChild.toString();

  const questionIsAnswer = isQAEqual(pathToStep, question, answer);

  const validationText = stepXml.findChildTag('validationJSON').text;
  let validation: Validation = {
    includedGlobalFeedbacks: [],
    includedGlobalFeedbacksInTest: [],
    includedPlaceholderFeedbacks: {},
    excludedGlobalFeedbacks: [],
    excludedGlobalFeedbacksInTest: [],
    excludedPlaceholderFeedbacks: {},
    validationType: ValidationType.default,
    embedPlaceholders: undefined,
    conditionCorrectExpr: undefined,
    conditionValidExpr: undefined,
    conditionValidPatterns: undefined,
    conditionCorrectPatterns: undefined,
    code: undefined,
    feedbacks: [],
  };

  try {
    if (validationText) {
      validation = JSON.parse(validationText);
    }
  } catch (err) {
    log.error('ERROR parsing validationJSON: ', err);
  }

  return {
    ...stepXml.tagsToProps(xmlText, ['description', 'id', 'skill', 'type', 'title']),
    ...(instructionHelp && { instructionHelp }),
    explanation,
    showKEM: stepXml.findChildTag('explanation').hasAttribute('kemPageId'),
    instruction,
    question: question,
    questionIsAnswer,
    answer: answer,
    questionXML,
    answerXML,
    mandatory: toBoolean(firstText(stepXml.getChildrenByTagName('mandatory'), '')),
    maxErrors: stepXml.findChildTag('maxErrors').text === '1' ? 1 : 2,
    hints: stepXml
      .findChildTag('hints')
      .children.map((hint, i) => importWrappedContent(hint, `${pathToStep}.hints[${i}]`)),
    supports: stepXml
      .findChildTag('supports')
      .children.map((support, i) => importWrappedContent(support, `${pathToStep}.supports[${i}]`)),
    feedbacks: [],
    knowledgeGaps: xmlTextToList(',')(stepXml.findChildTag('knowledgeGaps')),
    validation,
    validatorEngine: stepXml.findChildTag('validatorEngine').text as ValidatorEngine,
  };
}

function isQAEqual(pathToStep: string, question: ContentDict, answer: ContentDict): boolean {
  const answerKeys = Object.keys(answer);

  if (Object.keys(question).length !== answerKeys.length) {
    return false;
  }
  for (const answerKey of answerKeys) {
    const questionKey = answerKey.replace(`${pathToStep}.answer`, `${pathToStep}.question`);

    if (!isContentEqual(pathToStep, question[questionKey], answer[answerKey])) {
      return false;
    }
  }

  return true;
}

function isContentEqual(pathToStep: string, questionContent: any, answerContent: any): boolean {
  if (typeof questionContent !== typeof answerContent) {
    return false;
  }
  switch (typeof answerContent) {
    case 'object':
      const answerKeys = Object.keys(answerContent);

      for (const key of answerKeys) {
        if (key === 'tool' || key === 'toolSet') continue;

        if (!isContentEqual(pathToStep, questionContent[key], answerContent[key])) {
          return false;
        }
      }
      return true;
    case 'string':
      return (
        questionContent === answerContent.replace(`${pathToStep}.answer`, `${pathToStep}.question`)
      );
    default:
      return questionContent === answerContent;
  }
}
