import { defaultTo } from 'lodash';

import { type Severity } from '@bettermarks/umc-kotlin';
import { type ContentDict, Lens } from '@bettermarks/gizmo-types';
import { type AppSettings, type Features } from './';

import { AppExercise } from './AppExercise';
import { type AppStep, type Question } from './AppStep';

export const enum SeriesFlow {
  random = 'random',
  linear = 'linear',
}

export const enum SeriesMode {
  practice = 'practice', // default mode
  test = 'test',
  review = 'review',
  preview = 'preview',
}

/**
 * This type is a contract between response from NSP-Backend & free-betty.
 * http://gitlab.bm.loc/poser/backend/blob/master/bm/views/free_betty/schema.py#L28
 *
 * calculator: Whether to show calculator.
 * exerciseMaxPoints: Maximum points that can be earned.
 * flow: whether exercises can be solved linear or random order. Currently used only for test /
 * pratice mode.
 * hideHelpTools: Show/hide help tools
 * repeatable: Whether Series can be repeated or not.
 * showBetty: Whether betty should be shown on user response.
 * showResults: Whether results to be shown after user finish the exercise series.
 * validationAtEndOfSeries: Validate only after user has finished the entire exercise series.
 */
export type SeriesSettings = Readonly<{
  calculator: boolean;
  exerciseMaxPoints: number;
  flow: SeriesFlow;
  hideHelpTools: boolean;
  repeatable: boolean;
  reporting: boolean;
  showBetty: boolean;
  showResults: boolean;
  validationAtEndOfSeries: boolean;
}>;

export const DEFAULT_SERIES_SETTINGS: SeriesSettings = {
  calculator: false,
  exerciseMaxPoints: 2,
  flow: SeriesFlow.linear,
  hideHelpTools: false,
  repeatable: true,
  reporting: false,
  showBetty: true,
  showResults: true,
  validationAtEndOfSeries: false,
};

export type StepFeedback = {
  id?: string; // gizmo-id can be use to refer to a gizmo
  key?: string; // translation key, need i18n to translate
  severity: Severity;
  contentDict?: ContentDict;
  learningObjectiveId?: Nullable<string>; // optionally reference LOB to support more detailed reporting
};

export type CurrentStep = AppStep | undefined;

// seriesSettings, config and title are only set after series is fetched.
export interface ISeries {
  currentExerciseIndex: number;
  exercises: ReadonlyArray<AppExercise>;
  id: string;
  mode: SeriesMode;
  seriesStatus: SeriesStatus;
  seriesSettings: SeriesSettings;
  seriesScore?: SeriesScore;
  title?: string;
  userId?: string;
  groupId?: string;
  assignment?: AssignmentData;
}

export type Series = Readonly<ISeries>;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Series {
  export const toExercise = (index?: number): Lens<Series, AppExercise> => ({
    get: (series) => series.exercises[defaultTo(index, series.currentExerciseIndex)],
    set: (exercise) => (series) => ({
      ...series,
      exercises: [
        ...series.exercises.slice(0, defaultTo(index, series.currentExerciseIndex)),
        exercise,
        ...series.exercises.slice(defaultTo(index, series.currentExerciseIndex) + 1),
      ],
    }),
  });

  export const toCurrentExercise = toExercise();

  export const toExercises = Lens.create<Series, 'exercises'>('exercises');

  export const toSeriesSettings = Lens.create<Series, 'seriesSettings'>('seriesSettings');
  export const toSeriesReportingSetting = Lens.compose(
    toSeriesSettings,
    Lens.create<SeriesSettings, 'reporting'>('reporting')
  );

  export const toCurrentExerciseIndex = Lens.create<Series, 'currentExerciseIndex'>(
    'currentExerciseIndex'
  );

  export const toSeriesMode = Lens.create<Series, 'mode'>('mode');
  export const toSeriesStatus = Lens.create<Series, 'seriesStatus'>('seriesStatus');

  export const toCurrentQuestion: Lens<Series, Question | undefined> = Lens.compose(
    toCurrentExercise,
    AppExercise.toCurrentQuestion
  );
  export const toCurrentStep: Lens<Series, CurrentStep> = Lens.compose(
    toCurrentExercise,
    AppExercise.toCurrentStep
  );

  export const toAssignment = Lens.create<Series, 'assignment'>('assignment');

  export const isCompleted = (series: Series): boolean =>
    series.exercises.reduce((acc, ex) => acc && AppExercise.isCompleted(ex), true);
}

export const enum SeriesStatus {
  started = 'started',
  completed = 'completed',
}

export const enum SeriesType {
  KnowledgeGap = 'knowledge_gap',
  Book = 'book',
  PrivateBook = 'private_book',
  Todo = 'todo',
}

export const enum StarModeEnum {
  New = 'new',
  Previous = 'previous',
}

export type StarMode = StarModeEnum | null;

export type SeriesScore = Readonly<{
  pointsMax: number;
  pointsReached: number;
  coinThresholds: [number, number, number];
  starMode: StarMode;
}>;

export type StartSeriesPayload = {
  appSettings?: AppSettings;
  assignment?: AssignmentData;
  currentExerciseIndex: number;
  exercises: ReadonlyArray<AppExercise>;
  features?: Features;
  qaMode?: boolean;
  seriesSettings: SeriesSettings;
  seriesReview: boolean;
  previewMode: boolean;
  seriesId?: string;
  title?: string;
  userId?: string;
  groupId?: string;
};

export type AssignmentData = {
  id?: string;
  endDate?: string;
};
