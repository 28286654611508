import { numberFromStyles } from '@bettermarks/gizmo-types';
import styles from './EM.scss';

export const KEM_BLUE = styles.KEM_BLUE;
export const KEM_HORIZONTAL_PADDING = numberFromStyles(styles.KEM_HORIZONTAL_PADDING);
export const EM_EXAMPLE_GROUP_HEADING_COLOR = styles.EM_EXAMPLE_GROUP_HEADING_COLOR;
export const EM_EXAMPLE_GROUP_HEADING_FONT_SIZE = numberFromStyles(
  styles.EM_EXAMPLE_GROUP_HEADING_FONT_SIZE
);
export const EM_EXAMPLE_PADDING = numberFromStyles(styles.EM_EXAMPLE_PADDING);
