import * as React from 'react';
import { defaultTo } from 'lodash';
import { type GridObject } from '@bettermarks/gizmo-types';
import { transformX, transformY } from '@bettermarks/importers';

import styles from './Grid.scss';
import { getNamedColor } from '@bettermarks/importers';

export type GridProps = {
  content: GridObject;
  matrix: number[];
};

export const Grid: React.FC<GridProps> = (props) => {
  const { matrix } = props;
  const { vLines, hLines, color } = props.content;
  const namedColor = getNamedColor(color);
  const { DEFAULT_GRID_COLOR } = styles;

  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  const [verticalGridLines, horizontalGridLines] = [vLines, hLines].map((lines, i) =>
    lines.map((l, j) => (
      <line
        key={`${i}_${j}`}
        x1={sx(l.x1)}
        y1={sy(l.y1)}
        x2={sx(l.x2)}
        y2={sy(l.y2)}
        stroke={defaultTo<string>(namedColor, DEFAULT_GRID_COLOR)}
      />
    ))
  );

  return (
    <g className={styles.grid}>
      {horizontalGridLines}
      {verticalGridLines}
    </g>
  );
};

Grid.displayName = 'Grid';
