import { accentCorrection } from './accentCorrection';
import * as React from 'react';

import { BASE, type MOver as MOverContent, type MRow } from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { Bucket } from '../Bucket';
import { enrich } from '../helpers';
import { type MUnderOverProps } from './types';

import styles from './UnderOver.scss';

/**
 * MOver: The MathML <mover> element is used to attach an accent or a limit
 * over an expression. It uses the following syntax:
 * <mover>
 *    base
 *    overscript
 * </mover>
 */
export const MOver: React.FC<MUnderOverProps & MOverContent> = ({
  accent,
  children: [base, overscript],
  computedStyles: { backgroundColor },
  baseHeight = 0,
  overscriptHeight = 0,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <span className={styles.mover}>
      <div className={styles.moverOverscript}>
        <Bucket height={overscriptHeight} stretched>
          {overscript}
        </Bucket>
      </div>
      <div
        className={styles.moverBase}
        style={{
          marginTop: accentCorrection(accent, overscriptHeight) || undefined,
        }}
      >
        <Bucket height={baseHeight}>{base}</Bucket>
      </div>
    </span>
  </BackgroundColor>
);

MOver.displayName = 'MOver';

export const enrichMOver: EnrichNestedMathContent<MOverContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const {
    enrichedContent: base,
    height: baseHeight,
    refLine,
    relativeToBaseLine,
  } = mathContentEnricher(formulaStyles, content.base, [...path, BASE]);
  const { enrichedContent: overscript, height: overscriptHeight } = mathContentEnricher(
    formulaStyles,
    content.overscript,
    [...path, 'overscript']
  );
  // accent: https://www.w3.org/TR/MathML2/chapter3.html#presm.mover
  const height = baseHeight + overscriptHeight + accentCorrection(content.accent, overscriptHeight);

  return enrich(
    { ...content, base, overscript, baseHeight, overscriptHeight },
    { height, refLine, relativeToBaseLine },
    formulaStyles
  );
};
