import { type ExporterContext } from '@bettermarks/gizmo-types';
import { exportContent } from '@bettermarks/gizmo-types';
import { exportFormulaContent } from '../../formula/exporter/exportFormula';
import { type ChartLabel, type Legend } from '@bettermarks/gizmo-types';

export const exportLabel = (label: ChartLabel, context: ExporterContext) =>
  '$refid' in label ? exportContent(label.$refid, context) : exportFormulaContent(label, context);

export const exportLabels = (legend: Legend[], context: ExporterContext): string =>
  legend.map(({ label }) => exportLabel(label, context)).join('');
