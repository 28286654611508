import * as React from 'react';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { type Graph, type Size, type Position } from '@bettermarks/gizmo-types';
import { GraphRenderer } from './GraphRenderer';
import { GraphEditorModes } from '@bettermarks/gizmo-types';
import { AddNode } from './modes/AddNode';
import { ConnectNode } from './modes/ConnectNode';
import { Delete } from './modes/Delete';
import { MoveNode } from './modes/MoveNode';
import { LabelNode } from './modes/LabelNode';
import { ColorNode } from './modes/ColorNode';
import { isContentColor } from '@bettermarks/importers';
import { GRAPH_MIN_WIDTH } from '@bettermarks/importers';
import { useGrabMode, useNoDefaultScrolling } from './helpers';
import { ShadowScrollbars, useDragScrollBehaviour } from '../../components';
import { getDragScrollBehaviour } from '../../../gizmo-utils/drag-scroll-behaviour';
import { CONTENT_ID } from '../../../gizmo-utils/constants';

export interface GraphGizmoCallbacks {
  readonly onNodeSize?: (nodeId: string, size: Size) => void;
  readonly onAddNode?: (position: Position) => void;
  readonly onConnectNode?: (source: string, target: string) => void;
  readonly onDeleteEdge?: (id: string) => void;
  readonly onDeleteNode?: (id: string) => void;
  readonly onLabelNode?: (id: string, index: number) => void;
  readonly onColorNode?: (id: string) => void;
  readonly onMoveNode?: (id: string, position: Position) => void;
  readonly onNeedGrab?: (needGrab: boolean) => void;
}

export type GraphGizmoProps = Graph & GraphGizmoCallbacks & ContextState;

const getYContainer = () => document.getElementById(CONTENT_ID);

/**
 * The graph gizmo let's the user construct and edit undirectional graphs. The user can add, move,
 * label or color nodes. He can connect nodes with edges and remove nodes and ajdacent edges or
 * single edges. It's mainly used in exercises where a propability tree has to be constructed and
 * labeled or colorized.
 *
 * As in the geometric system gizmo the user can switch between different editing modes. This is
 * realized by handling each mode in a separate component. Each mode renders a GraphRenderer
 * component and provides it with nodes and edges, triggers actions and manages local state for
 * intermediate graphical representations (e.g. preview nodes or highlights). The graph renderer
 * just gets this updated data and renders it.
 *
 *                                    *----------------*
 *                                    |   GraphGizmo   |
 *                                    *----------------*
 *                                   /         |        \
 *                      *-----------*   *------------*   *------------*
 *                      |  AddNode  |   |  MoveNode  |   |   Delete   |   ...
 *                      *-----------*   *------------*   *------------*
 *                            |                |                |
 *                 *---------------*  *---------------*  *---------------*
 *                 | GraphRenderer |  | GraphRenderer |  | GraphRenderer |
 *                 *---------------*  *---------------*  *---------------*
 *
 */
export const GraphGizmo: React.FC<GraphGizmoProps> = ({
  availableWidth,
  disabled,
  edges,
  isTouch,
  nodes,
  onAddNode,
  onConnectNode,
  onLabelNode,
  onColorNode,
  onDeleteEdge,
  onDeleteNode,
  onMoveNode,
  onNodeSize,
  onNeedGrab,
  selectedMode,
  template,
  ticksWidth,
  ticksHeight,
}) => {
  useGrabMode(availableWidth, isTouch, onNeedGrab, [nodes, edges]);

  const { scrollBehaviour, onRef } = useDragScrollBehaviour(
    undefined,
    undefined,
    getDragScrollBehaviour(getYContainer)
  );

  /**
   * The scroll behaviour is used here only to scroll to the initial position (e.g. when you
   * scrolled in another mode) and to scroll the labeled node into view (so that the label widget
   * can be positioned correctly.
   */
  useNoDefaultScrolling(scrollBehaviour);

  const renderProps = {
    border: true,
    edges,
    nodes,
    onNodeSize,
    template,
    ticksWidth,
    ticksHeight,
    availableWidth: Math.min(GRAPH_MIN_WIDTH, availableWidth),
    width: GRAPH_MIN_WIDTH,
    height: (GRAPH_MIN_WIDTH * ticksHeight) / ticksWidth,
    scrollBehaviour,
  };

  if (disabled) {
    return <GraphRenderer {...renderProps} />;
  }

  const getMode = () => {
    switch (selectedMode) {
      case GraphEditorModes.Add:
        return <AddNode {...renderProps} onAddNode={onAddNode} />;
      case GraphEditorModes.Move:
        return <MoveNode {...renderProps} onMoveNode={onMoveNode} />;
      case GraphEditorModes.Connect:
        return <ConnectNode {...renderProps} onConnectNode={onConnectNode} />;
      case GraphEditorModes.Label:
        return <LabelNode {...renderProps} onLabelNode={onLabelNode} />;
      case GraphEditorModes.Remove:
        return (
          <Delete
            {...renderProps}
            touch={isTouch}
            onDeleteNode={onDeleteNode}
            onDeleteEdge={onDeleteEdge}
          />
        );
      default:
        if (isContentColor(selectedMode)) {
          return <ColorNode {...renderProps} color={selectedMode} onColorNode={onColorNode} />;
        }
        return <GraphRenderer {...renderProps} />;
    }
  };

  return (
    <ShadowScrollbars
      availableWidth={availableWidth}
      scrollBehaviour={scrollBehaviour}
      fixedChildScrollWidth={GRAPH_MIN_WIDTH}
      ref={onRef}
    >
      {getMode()}
    </ShadowScrollbars>
  );
};

GraphGizmo.displayName = 'GraphGizmo';
