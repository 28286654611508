import { type Action, handleActions } from 'redux-actions';
import { isNil } from 'lodash';
import {
  type Bar,
  type BarChartContent,
  type BarChartGroup,
  MouseCursor,
} from '@bettermarks/gizmo-types';
import {
  type BarChartMovePayload,
  type BarChartPersistPayload,
  type BarChartStartPayload,
  END,
  HANDLE_HOVER,
  MOVE,
  PERSIST,
  START,
} from './actions';
import { type BarChartState } from '../BarChartEditor';
import { BARCHART_DEFAULT_CONTENT } from '../constants';

export const initialState: BarChartState = {
  groups: [],
  group: NaN,
  item: NaN,
  cursor: MouseCursor.default,
};

export type BarChartPayload = BarChartMovePayload | BarChartStartPayload | boolean;

export const changeActiveItemProps = (
  groups: BarChartGroup[],
  groupIdx: number,
  itemIdx: number,
  payload: Partial<Bar>
): BarChartGroup[] => {
  if (isNaN(groupIdx)) {
    return groups;
  }

  const items = groups[groupIdx].items;

  return [
    ...groups.slice(0, groupIdx),
    {
      ...groups[groupIdx],
      items: [
        ...items.slice(0, itemIdx),
        { ...items[itemIdx], ...payload },
        ...items.slice(itemIdx + 1),
      ],
    },
    ...groups.slice(groupIdx + 1),
  ];
};

export const barChartLocalReducer = handleActions<BarChartState, BarChartPayload>(
  {
    [MOVE]: (state, { payload }: Action<BarChartMovePayload>) => {
      if (isNil(payload) || isNaN(state.group)) {
        return state;
      }

      const { item, group } = state;
      return {
        ...state,
        groups: changeActiveItemProps(state.groups, group, item, {
          yValue: payload,
          severity: undefined,
        }),
        cursor: MouseCursor.grabbing,
      };
    },
    [START]: (state, { payload }: Action<BarChartStartPayload>) => {
      if (!payload) {
        return state;
      }
      const { group, item } = payload;

      return {
        ...state,
        group,
        item,
        groups: changeActiveItemProps(state.groups, group, item, {
          active: true,
          readingHelp: true,
        }),
        cursor: MouseCursor.grabbing,
      };
    },
    [END]: (state) => ({
      ...state,
      groups: changeActiveItemProps(state.groups, state.group, state.item, {
        active: false,
        readingHelp: false,
      }),
      group: NaN,
      item: NaN,
      cursor: MouseCursor.default,
    }),
    [HANDLE_HOVER]: (state, { payload }: Action<boolean>) => ({
      ...state,
      cursor: payload ? MouseCursor.grab : MouseCursor.default,
    }),
  },
  initialState
);

export const barChartReducer = handleActions<BarChartContent, BarChartPersistPayload>(
  {
    [PERSIST]: (state, { payload }: Action<BarChartPersistPayload>) => {
      return payload ? { ...state, groups: payload } : state;
    },
  },
  BARCHART_DEFAULT_CONTENT
);
