import colors from '../sass/colors';
import dimensions from '../sass/dimensions';
export interface BettermarksTheme {
  colors: {
    [k: string]: string;
  };
  dimensions: {
    [k: string]: string | number;
  };
}
export default {
  colors,
  dimensions,
};
