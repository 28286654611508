import * as React from 'react';
import { ToolColumn } from '../toolbar/Toolbar';
import { useWhiteboardContext } from '../whiteboard-context';
import { SECOND_SIDEBAR_WIDTH } from '../toolbar/constants';
import { postToWhiteboardChild, type WhiteboardMessage } from '../../iframe-posts';
import { ModeSelector } from '../../seriesplayer/containers/Toolbar/Tools/ModeSelector';

export default function ContentToolsSidebar(): JSX.Element {
  const {
    state: { contentToolConfig },
    dispatch,
  } = useWhiteboardContext();

  function postMessageToChild(message: WhiteboardMessage) {
    postToWhiteboardChild(message);
  }

  return (
    <ToolColumn width={SECOND_SIDEBAR_WIDTH}>
      <div style={{ marginTop: 'auto' }}>
        <ModeSelector
          modes={contentToolConfig?.modes ?? []}
          onSetMode={(mode) => {
            postMessageToChild({
              type: 'changeMode',
              mode,
            });
            dispatch({
              type: 'content-tools',
              payload: { ...contentToolConfig, editorMode: mode },
            });
          }}
          editorMode={contentToolConfig.editorMode}
        />
      </div>
    </ToolColumn>
  );
}
