import * as React from 'react';
import { type TFunction } from 'i18next';
import classNames from 'classnames';
import { CollapsibleSection, CSInitialMode, SectionKind } from '@seriesplayer/common-ui';
import { PolymorphicGizmo, useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';
import { type EM } from '../../../types';

import { Example } from './Example';
import { EM_EXAMPLE_PADDING } from './constants';

import styles from './EM.scss';

export type ExampleGroupProps = Readonly<
  EM.ElementExampleGroup & {
    availableWidth: number;
    mode: 'KEM' | 'FEM';
  }
>;

const exampleHeading = (
  t: TFunction,
  title: string | undefined,
  iq: boolean,
  i: number,
  nExamples: number
): string => {
  const header = t('exampleHeader', { ns: 'kem' });

  if (title) {
    return iq ? `${title}` : `${header}: ${title}`;
  } else if (nExamples > 1) {
    return `${header} ${i + 1}`;
  } else {
    return header;
  }
};

export const ExampleGroup: React.FC<ExampleGroupProps> = ({
  heading,
  iq,
  examples,
  availableWidth,
  mode,
}) => {
  // The example box reduces the availableWidth by 12px on each side
  const width = availableWidth - 2 * EM_EXAMPLE_PADDING;
  const t = useContentTranslation();
  return (
    <div className={classNames(styles.exampleGroup, styles[mode])}>
      {heading && (
        <h2 className={styles.exampleGroupHeading}>
          <PolymorphicGizmo refid={heading.$refid} availableWidth={width} />
        </h2>
      )}
      {examples.map((example, i) => (
        <div className={styles.exampleWrapper} key={i}>
          <CollapsibleSection
            kind={SectionKind.neutral}
            collapsible={true}
            title={exampleHeading(t, example.title, iq, i, examples.length)}
            // This is needed for the negative margins of the comment blocks
            innerStyle={{ overflow: 'hidden' }}
            initialMode={iq ? CSInitialMode.collapsed : CSInitialMode.open}
          >
            <Example {...example} availableWidth={width} />
          </CollapsibleSection>
        </div>
      ))}
    </div>
  );
};

ExampleGroup.displayName = 'ExampleGroup';
