import {
  deselectContent,
  gizmoAction,
  type GizmoActionOptions,
  selectContent,
} from '../../../gizmo-utils/redux';
import { type GizmoSagas } from '../../../gizmo-utils/redux/gizmoSaga';
import { ShouldEnrichKind, type SolutionSetContent } from '@bettermarks/gizmo-types';
import { type Effect, put } from 'redux-saga/effects';
import { ADD_ELEMENT, REMOVE_ELEMENT } from './solutionSetActions';

export const ENRICH_AND_SKIP_UNDO: GizmoActionOptions = {
  shouldEnrich: ShouldEnrichKind.justEnrich,
  skipUndo: true,
};

/**
 * When this saga is called the reducer already added or removed an element.
 * We always want to select the last visible element.
 * It will not be focused using the browser though,
 * which allows the user to add/remove multiple items using keyboard.
 * (The focus will stay on the button, using space it will be clicked again.)
 */
export function* elementFocusSaga(
  ownRefId: string,
  { visibleElements, selectableRefIds }: SolutionSetContent
): IterableIterator<Effect> {
  if (selectableRefIds) {
    if (visibleElements.length === 0) {
      const toBlur = selectableRefIds[0];
      if (toBlur) {
        // the last visible element was hidden, it needs to be deselected
        yield put(gizmoAction(deselectContent(), toBlur, ENRICH_AND_SKIP_UNDO));
      }
    } else {
      const toFocus = selectableRefIds[visibleElements.length - 1];
      if (toFocus) {
        /* calling focusGizmo is not what we want:
        We want to leave the browser focus on the button.
        But the cursor will still be set to the last element,
        since that one becomes the selectedContent.
        */
        yield put(gizmoAction(selectContent(undefined), toFocus, ENRICH_AND_SKIP_UNDO));
      }
    }
  }
}

export const solutionSetSaga: GizmoSagas = {
  [ADD_ELEMENT]: elementFocusSaga,
  [REMOVE_ELEMENT]: elementFocusSaga,
};
