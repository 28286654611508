import styles from './Gizmo.scss';

import { numberFromStyles } from '@bettermarks/gizmo-types';

export const COLOR_GRAY = 'gray' as const;
export const COLOR_GREEN = 'green' as const;
export const COLOR_RED = 'red' as const;
export const COLOR_YELLOW = 'yellow' as const;
export const COLOR_DEFAULT = COLOR_GRAY;

export type GizmoColor =
  | typeof COLOR_GRAY
  | typeof COLOR_GREEN
  | typeof COLOR_RED
  | typeof COLOR_YELLOW;

export const GIZMO_COLORS: ReadonlyArray<GizmoColor> = [
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_YELLOW,
];

export const GIZMO_HEIGHT = 27;
export const GIZMO_FONTSIZE = numberFromStyles(styles.GIZMO_FONTSIZE);
