import log from 'loglevel';
import { DATA_ATTRIBUTE_GIZMO_ID } from './constants';

/**
 * Given a direction, the next (or previous) DOM element is focused. This
 * function can be used if you want to "tab-select" by other means than
 * pressing Tab (i.e. with cursor keys, mouse clicks or other events). Usually
 * this is called in a saga as a side effect after receiving an action.
 * @param refId of the gizmo to select
 * @param doc allows passing a mocked document for testing
 */
export const focusGizmo = (refId: string, doc = document) => {
  const focusableElement = doc.querySelector<HTMLElement>(
    `[${DATA_ATTRIBUTE_GIZMO_ID}="${refId}"] [tabindex="0"]`
  );
  if (focusableElement) {
    focusableElement.focus();
  } else {
    log.warn({
      message: 'focusGizmo could not select anything for refId',
      extra: { refId },
    });
  }
};
