import { call, put, select } from 'redux-saga/effects';
import { toLogExtra } from '../store/toLogExtra';
import { ErrorHandleKind } from '../../types';
import { showErrorDialog } from './containers/SeriesPlayer/actions';
import {
  ErrorReason,
  FetchExceptionType,
  isFetchException,
  isValidationError,
  isForbiddenToReviewUnfinishedSeriesError,
} from './services/exception';
import { exceptionLog, ExceptionType } from './services/logging';

export function* handleError(err: Error) {
  let kind: ErrorHandleKind;
  let reasonCode: Nullable<ErrorReason>;
  const extra = yield call(toLogExtra, yield select());

  if (isFetchException(err)) {
    exceptionLog(
      ExceptionType.api,
      err,
      {
        response: JSON.stringify(err.response),
        requestInit: err.requestInit,
        url: err.url,
        type: err.type,
        ...extra,
      },
      'warn'
    );
    kind = ErrorHandleKind.quit;
    if (err.type === FetchExceptionType.testHasBeenCollectedException) {
      reasonCode = (err?.response as any)?.data?.title;
    }
  } else if (isValidationError(err)) {
    exceptionLog(ExceptionType.validation, err, {
      response: JSON.stringify(err.response),
      ...extra,
    });
    kind = ErrorHandleKind.quit;
  } else if (isForbiddenToReviewUnfinishedSeriesError(err)) {
    exceptionLog(ExceptionType.api, err, extra);
    reasonCode = ErrorReason.SERIES_CANNOT_BE_REVIEWED;
    kind = ErrorHandleKind.quit;
  } else {
    exceptionLog(ExceptionType.saga, err, extra);
    kind = ErrorHandleKind.quit;
  }

  yield put(showErrorDialog({ kind, reasonCode }));
}
