import { connectGizmo, type Dispatch, showFEM } from '../../../gizmo-utils/redux';
import { Formula, type FormulaCallbacks } from './Formula';

const mapDispatchToProps = (dispatch: Dispatch): FormulaCallbacks => ({
  onClickFEM: (id: string, search: string) => dispatch(showFEM({ id, search })),
});

/**
 * A Gizmo container for the non-interactive formula.
 * It is needed for dispatching an action when an FEM link is clicked.
 */
export const NonInteractiveFormulaContainer = connectGizmo(mapDispatchToProps, Formula);
NonInteractiveFormulaContainer.displayName = 'NonInteractiveFormulaContainer';
