import { ContentColor } from '@bettermarks/gizmo-types';

export const MARGIN = 1; // Margin around the tree. Looks better!
export const SIBLING_GAP = 8; // min space between siblings in ticks (breadth dim)
export const SUBTREE_GAP = 20; // min space between subtrees in ticks (breadth dim)
export const DEPTH_GAP = 12; // min space between elements in ticks (depth dim)

export const TREE_FORMULA_FONTSIZE = 14; // adapted formula - fontsize for the tree gizmo
export const TREE_DEFAULT_NODE_COLOR = ContentColor.BM_BLUE;
export const TREE_BUBBLENODE_WEIGHT = 2.5;
