import type React from 'react';

export const enum SectionKind {
  inactive = 'inactive',
  positive = 'positive',
  negative = 'negative',
  supportive = 'supportive',
  neutral = 'neutral',
}

export const enum CSInitialMode {
  /**
   * Render collapsed (no animation)
   */
  collapsed = 'collapsed',
  /**
   * Render open (no animation)
   */
  open = 'open',
  /**
   * Animate open.
   */
  openAnimated = 'openAnimated',
}

export const enum CSModeFromState {
  openToggleAnimated = 'openToggleAnimated',
  collapsedToggleAnimated = 'collapsedToggleAnimated',
}

export interface CollapsibleSectionProps {
  /**
   * Determines the coloring of the section.
   */
  kind: SectionKind;
  /**
   * The header text to show. Mandatory for a section to be collapsible.
   */
  title?: string;
  /**
   * Whether the user is allowed to collapse/expand the section.
   * (Only possible when a title is present.)
   */
  collapsible: boolean;
  /**
   * Controls the mode to initially render the section in.
   *
   * @see CSInitialMode
   */
  initialMode?: CSInitialMode;
  /**
   * additional styling to apply to the wrapper around the content
   */
  innerStyle?: React.CSSProperties;
  /**
   * If true then the whole header is clickable for toggling. Otherwise toggle is only possible
   * by clicking on the + icon.
   * Set to true by default.
   */
  headerAsToggle?: boolean;

  className?: string;
}
