import {
  annotationInner,
  type BoxContent,
  type Exporter,
  semantics,
} from '@bettermarks/gizmo-types';
import { decorationAttribute } from '../../gizmo-utils/decoration';

/**
 * Exports the Empty Gizmo Content to XML Data
 * This function is registered in [[gizmo-utils/configuration/exporters]]
 * @param contentRefId
 * @param context
 */
export const exportBox: Exporter = (contentRefId, context): string => {
  const content = context.content[contentRefId] as BoxContent;
  return semantics(
    `
    <mrow ${decorationAttribute(content.decoration)}/>
    `,
    annotationInner(content.$, content)
  );
};
