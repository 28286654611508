import { Lens, type ToolbarElements } from '@bettermarks/gizmo-types';

export interface Toolbar {
  tools: ToolbarElements[];
  openDrawerName: Nullable<string>;
}

export const DEFAULT_TOOLBAR_SETTINGS: Toolbar = {
  tools: [],
  openDrawerName: null,
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Toolbar {
  export const toTools = Lens.create<Toolbar>('tools');
  export const toOpenDrawerName = Lens.create<Toolbar>('openDrawerName');
}
