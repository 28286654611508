import { type ContentDict, Lens, ShouldEnrichKind } from '@bettermarks/gizmo-types';
import { type ShouldEnrich } from '../../gizmo-utils/measure';
import { type ContentDictReducer } from '../../gizmo-utils/redux/createContentDictReducer';
import { type GizmoAction, gizmoActionType } from '../../gizmo-utils/redux/gizmoActions';
import { isNil } from 'lodash';
import {
  type Action,
  type ActionMeta,
  type BaseAction,
  type Reducer,
  type ReducerMeta,
} from 'redux-actions';
import undoable from 'redux-undo';
import { type AppExercise, ApplicationState, type CalculatorState, type Series } from '../../types';
import { reduceReducers } from '../../gizmo-utils/reduceReducers';
import { initialCalculatorState } from '../seriesplayer/containers/Toolbar/Tools/Calculator/reducer';
import isExerciseContentPath = ApplicationState.isExerciseContentPath;
import isEMContentPath = ApplicationState.isEMContentPath;

export type CombinedSeriesplayerReducer<P = void> = (
  state: ApplicationState,
  action: Action<P> | GizmoAction<P> | BaseAction
) => ApplicationState;

const applySeriesReducer =
  <P>(reducer: Reducer<Series, P>) =>
  (state: ApplicationState, action: Action<P>): ApplicationState => ({
    ...state,
    series: reducer(state.series, action),
  });

const applyExerciseReducer =
  <P>(reducer: Reducer<AppExercise, P>) =>
  (state: ApplicationState, action: Action<P>): ApplicationState =>
    ApplicationState.isSeriesReady(state)
      ? Lens.update(
          ApplicationState.toCurrentExercise,
          (exercise) => reducer(exercise, action),
          state
        )
      : state;

const applyCalculatorReducer =
  <P>(reducer: Reducer<CalculatorState, P>) =>
  (state: ApplicationState, action: Action<P>): ApplicationState => ({
    ...state,
    calculatorState: reducer(state.calculatorState || initialCalculatorState, action),
  });

type WithGizmoId = { gizmoId: string };
export type EnricherInputAction = ActionMeta<
  any,
  (ShouldEnrich & Partial<WithGizmoId>) | undefined
>;
export type EnricherAction = ActionMeta<any, ShouldEnrich & WithGizmoId>;

/**
 * Enrichment is also required if the exercise being navigated is in review mode because we use
 * GIZMO_ACTION:SCALE_DOWN to resize specific gizmos on window-size change.
 *
 * If no gizmoId is passed with the action we are defaulting to root of current question.
 * - This happens when with navigate actions (for example next-exercise/switch-exercise)
 * - ApplicationState.pathToCurrentQuestion can return an emtpy string if there is no current
 *   question available. This can happen when navigating to or from exercises that are already
 *   completed.
 * @param enrichReducer
 */
const applyEnrichReducer =
  (enrichReducer: ContentDictReducer<any>) =>
  (applicationState: ApplicationState, action: EnricherInputAction): ApplicationState => {
    let enrichedApplicationState = applicationState;
    let gizmoId;
    if (action.meta && action.meta.shouldEnrich) {
      gizmoId = action.meta.gizmoId || ApplicationState.pathToCurrentContent(applicationState);
    }

    if (gizmoId) {
      const enricherAction: EnricherAction = { ...action, meta: { ...action.meta, gizmoId } };

      const contentDictLens: Lens<ApplicationState, ContentDict> =
        ApplicationState.toGizmoContentDict(gizmoId, applicationState);

      enrichedApplicationState = Lens.update(
        contentDictLens,
        (content) => enrichReducer(content, enricherAction),
        applicationState
      );
    }

    return enrichedApplicationState;
  };

export const UNDO_AND_ENRICH = 'UNDO_AND_ENRICH';
export const REDO_AND_ENRICH = 'REDO_AND_ENRICH';
export const JUMP_TO_PAST_AND_ENRICH = 'JUMP_TO_PAST_AND_ENRICH';

export const undoAndEnrich = () => ({
  type: UNDO_AND_ENRICH,
  meta: { shouldEnrich: ShouldEnrichKind.justEnrich },
});

export const redoAndEnrich = () => ({
  type: REDO_AND_ENRICH,
  meta: { shouldEnrich: ShouldEnrichKind.justEnrich },
});

export const jumpToPastAndEnrich = (index: number) => ({
  type: JUMP_TO_PAST_AND_ENRICH,
  index,
  meta: { shouldEnrich: ShouldEnrichKind.justEnrich },
});

const REDUX_UNDO_ACTION_TYPES = [UNDO_AND_ENRICH, REDO_AND_ENRICH, JUMP_TO_PAST_AND_ENRICH];
const isReduxUndoAction = (actionType: string): boolean =>
  REDUX_UNDO_ACTION_TYPES.some((reduxUndoAction) => reduxUndoAction === actionType);

const withUndo = <P>(contentDictReducer: ContentDictReducer<P>) => {
  const undoableContentDictReducer = undoable(contentDictReducer, {
    filter: (action: GizmoAction<any>) => !action.meta.skipUndo,
    undoType: UNDO_AND_ENRICH,
    redoType: REDO_AND_ENRICH,
    jumpToPastType: JUMP_TO_PAST_AND_ENRICH,
  });

  return (applicationState: ApplicationState, action: GizmoAction<P>): ApplicationState => {
    const actionType = gizmoActionType(action.type);

    if (!isNil(actionType) || isReduxUndoAction(action.type)) {
      /* If it is a GIZMO_ACTION, remove the namespace;
       * If it is a redux-undo action, leave it as it is.
       */
      const strippedAction = !isNil(actionType) ? { ...action, type: actionType } : action;

      const path =
        strippedAction.meta && strippedAction.meta.gizmoId
          ? strippedAction.meta.gizmoId.split(':')[0]
          : ApplicationState.pathToCurrentContent(applicationState);

      if (path && (isExerciseContentPath(path) || isEMContentPath(path))) {
        const toUndoableContent = ApplicationState.toUndoableContentDict(path);
        const prevUndoableContentDict = toUndoableContent.get(applicationState);
        const newUndoableContentDict = undoableContentDictReducer(
          prevUndoableContentDict,
          strippedAction
        );
        const questionBeforePastLength = prevUndoableContentDict?.past.length || 0;
        const hasChangedSinceLastAttempt =
          prevUndoableContentDict?.hasChangedSinceLastAttempt ||
          questionBeforePastLength !== newUndoableContentDict.past.length;

        return toUndoableContent.set({ ...newUndoableContentDict, hasChangedSinceLastAttempt })(
          applicationState
        );
      } else {
        return Lens.update(
          ApplicationState.toGizmoContentDict(strippedAction.meta.gizmoId, applicationState),
          (contentDict) => contentDictReducer(contentDict, strippedAction),
          applicationState
        );
      }
    }

    return applicationState;
  };
};

/**
 * Combines multiple reducer functions working on subtrees of the application state.
 *
 * The gizmoReducer only handles actions prefixed with 'GIZMO_ACTION:'
 *
 * Most of the time, each action triggers only one reducer and the rest don't have any effect.
 * The order in which the reducers are applied is therefore of no consequence.
 * Reducers which have to be applied in a certain order should have a test to guarantee this
 * behavior in combineSeriesplayerReducer.spec.ts.
 *
 * @param {ContentDictReducer<P>} contentDictReducer
 * @param {Reducer<ApplicationState, P>[]} appReducer
 * @param {Reducer<Series, P>} seriesReducer
 * @param {Reducer<AppExercise, P>} exerciseReducer
 * @param {Reducer<AppExercise, P>} emReducer
 * @param {ReducerMeta<ContentDict, {}, ShouldEnrich>} enrichReducer
 * @param {Reducer<CalculatorState, P>} calculatorReducer
 * @returns {CombinedSeriesplayerReducer<P>}
 */
export const combineSeriesplayerReducer = <P>(
  contentDictReducer: ContentDictReducer<P>,
  appReducer: Reducer<ApplicationState, P>[],
  seriesReducer: Reducer<Series, P>,
  exerciseReducer: Reducer<AppExercise, P>,
  emReducer: Reducer<ApplicationState, P>,
  criReducer: Reducer<ApplicationState, P>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  enrichReducer: ReducerMeta<ContentDict, {}, ShouldEnrich>,
  calculatorReducer: Reducer<CalculatorState, P>
): CombinedSeriesplayerReducer<P> => {
  return reduceReducers(
    ...appReducer,
    applySeriesReducer(seriesReducer),
    applyExerciseReducer(exerciseReducer),
    emReducer,
    criReducer,
    withUndo(contentDictReducer),
    applyEnrichReducer(enrichReducer),
    applyCalculatorReducer(calculatorReducer)
  );
};
