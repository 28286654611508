import * as React from 'react';
import { type EditorMode } from '@bettermarks/gizmo-types';
import { ToolbarButton } from './ToolbarButton';
import styles from './RadioButtonGroup.scss';
import toolbarButtonStyles from './ToolbarButton.scss';

export type RadioButtonContentType = React.ComponentType<any>[][];

export type RadioButtonGroupProps = {
  kind?: 'toggle' | 'tool';
  value?: number;
  onToggle?: (i: number, on?: boolean) => void;
  buttons?: RadioButtonContentType;
  buttonClassName?: string;
  modeItemWrapper?: any;
  modes?: ReadonlyArray<EditorMode>;
};

const enum ItemsTypes {
  children = 'children',
  buttons = 'buttons',
}

const renderChild = (child: React.ReactNode) => <span className={styles.child}>{child}</span>;

const renderButton = ([OnButton, OffButton]: React.ComponentType<any>[]) => [
  <OnButton key="on" className={toolbarButtonStyles.svgIcon} />,
  OffButton ? <OffButton key="off" className={toolbarButtonStyles.svgIcon} /> : null,
];

const renderMap = {
  [ItemsTypes.children]: renderChild,
  [ItemsTypes.buttons]: renderButton,
};

/**
 * RadioButtonGroup: A group of toggle buttons, that allow one or none to be selected.
 */
export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
  const {
    onToggle,
    children,
    buttonClassName,
    modeItemWrapper: ModeItemWrapper,
    modes = [],
    value = -1,
    kind = 'toggle',
    buttons = [],
  } = props;

  const childArray = React.Children.toArray(children);

  const [items, itemsType] = childArray.length
    ? [childArray, ItemsTypes.children]
    : [buttons, ItemsTypes.buttons];

  const toggle = (index: number) => (evt: React.MouseEvent<any>) => {
    onToggle && onToggle(index, index !== value);
  };

  return (
    <>
      {items.map((item, i) => {
        const toolbarButton = (
          <ToolbarButton
            key={i}
            onClick={toggle(i)}
            on={value === i}
            kind={kind}
            className={buttonClassName}
          >
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion */}
            {renderMap[itemsType](item as any)}
          </ToolbarButton>
        );

        return ModeItemWrapper ? (
          <ModeItemWrapper mode={modes[i]} key={i}>
            {toolbarButton}
          </ModeItemWrapper>
        ) : (
          toolbarButton
        );
      })}
    </>
  );
};
