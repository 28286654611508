import React from 'react';
import styled from 'styled-components';
import dimensions from '../../sass/dimensions';
import { StyledButton, type StyledButtonProps } from './Button';
import { ButtonKind, ButtonStretch } from './types';

export interface ButtonWrapperProps {
  /**
   * When set, the button uses the full available width.
   * @default false
   */
  stretch?: ButtonStretch;
  /**
   * If true, the size is determined by the size of the label or icon.
   * The `size` is only used for the clickable area (absolute positioning!).
   * @default false
   */
  collapseSpace?: boolean;
  buttonRef?: React.Ref<HTMLDivElement>;
  className?: string;
}

const applyStretchStyling = ({ stretch }: ButtonWrapperProps) =>
  stretch === ButtonStretch.stretch
    ? `
    display: flex;
    ${StyledButton} {
      flex-grow: 1;
    }
  `
    : stretch === ButtonStretch.responsive
    ? `
    display: flex;
    ${StyledButton} {
      flex-grow: 1;
    }
    
    @media (min-width: ${dimensions.verySmallScreenWidth}) {
      display: inline-flex;
      
      ${StyledButton} {
        flex-grow: 0;
      }
    }
  `
    : '';

const applySpaceStyling = ({ collapseSpace }: ButtonWrapperProps) =>
  collapseSpace
    ? `
  margin: 0;
  ${StyledButton}:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin: 0;
    height: ${dimensions.minClickableHeight};
    transform: translateY(-50%);
    pointer-events: all;
  }
  `
    : `
  min-height: ${dimensions.minClickableHeight};
  align-items: center;
  `;

type ButtonProps = StyledButtonProps & ButtonWrapperProps;

const ButtonBase: React.FC<ButtonProps> = (props) => {
  const {
    className,
    buttonRef,
    onClick,
    kind,
    id,
    dataCy,
    type,
    size,
    background,
    children,
    useExtendedPadding,
    useButtonTransition,
    disableFocusOutline = true,
  } = props;

  const buttonKind = onClick ? kind : ButtonKind.disabled;

  return (
    <div ref={buttonRef} className={className}>
      <StyledButton
        onClick={onClick}
        kind={buttonKind}
        id={id}
        dataCy={dataCy}
        type={type}
        size={size}
        background={background}
        useExtendedPadding={useExtendedPadding}
        useButtonTransition={useButtonTransition}
        disableFocusOutline={disableFocusOutline}
      >
        {children}
      </StyledButton>
    </div>
  );
};

export const Button = styled(ButtonBase)`
  display: inline-flex;
  cursor: pointer;
  vertical-align: middle;
  position: relative;

  ${applyStretchStyling}
  ${applySpaceStyling}
`;
