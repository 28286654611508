import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';
import { type Severity } from '@bettermarks/umc-kotlin';
import { SPECIAL } from '../../gizmo-utils/constants';
import { SORTING } from '../../gizmo-utils/configuration/renderStyles';
import { SortingDirection } from '../../gizmo-utils/types';

export interface SortingItem extends ContentReference {
  severity?: Severity;
}

export interface SortingContent extends Readonly<Content> {
  minText: string;
  maxText: string;
  direction: SortingDirection;
  items: SortingItem[];
}

export const SORTING_DEFAULT_CONTENT: Defaults<SortingContent> = {
  $: SPECIAL,
  $renderStyle: SORTING,
  items: [],
  direction: SortingDirection.horizontal,
  minText: 'dummy_min_text',
  maxText: 'dummy_max_text',
};
