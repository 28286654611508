import * as React from 'react';
import { COLOR_FILTER_ID } from '../../tools/constants';
import { type GeoEditorMode } from '@bettermarks/gizmo-types';
import { colorFromMode } from '../../tools/coloring/helpers';
import { getNamedColor } from '@bettermarks/importers';

interface FilterProps {
  id: string;
  mode: GeoEditorMode;
}

export const ColorToolHoverFilter: React.FC<FilterProps> = ({ id, mode }) => (
  <defs>
    <filter id={COLOR_FILTER_ID(id)} x="-50%" y="-50%" width="200%" height="200%">
      <feFlood floodColor={getNamedColor(colorFromMode(mode))} floodOpacity={0.5} />
      <feComposite in2="SourceAlpha" operator="in" result="inside-stroke" />
      <feMorphology in="SourceAlpha" operator="erode" radius="4" />
      <feComposite in="SourceGraphic" operator="out" result="fill-area" />
      <feMerge>
        <feMergeNode in="inside-stroke" />
        <feMergeNode in="fill-area" />
      </feMerge>
    </filter>
    ))
  </defs>
);

ColorToolHoverFilter.displayName = 'ColorToolHoverFilter';
