import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import { DialogFooter, Dropdown, DropdownOption } from '../../../../../../components';
import { Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';
import { type ReportProblemPayload } from '../types';
import { useTranslation } from 'react-i18next';
import { Content } from './Content';
import { useMaybeWhiteboardContext } from '../../../../../whiteboard/whiteboard-context';
import { getBaseMetadata } from '../../../../services';
import { compress } from '../../../../services/api/helper';
import dialogWrapperStyles from '../../../../../../components/DialogWrapper/DialogWrapper.scss';

const MAX_CHARS = 500;

type ReportProblemFormProps = {
  onCancel: () => void;
  onSubmit: (data: ReportProblemPayload) => void;
  children?: React.ReactNode;
};

const reportProblemTranslationKey = {
  abort: 'seriesplayer:dialog.reportProblem.abort',
  body: 'seriesplayer:dialog.reportProblem.body',
  comment: 'seriesplayer:dialog.reportProblem.comment',
  privacyWarning: 'seriesplayer:dialog.reportProblem.privacyWarning',
  select: 'seriesplayer:dialog.reportProblem.select',
  submit: 'seriesplayer:dialog.reportProblem.submit',
};

const reportProblemOptions = [
  'seriesplayer:dialog.reportProblem.option1',
  'seriesplayer:dialog.reportProblem.option2',
  'seriesplayer:dialog.reportProblem.option3',
  'seriesplayer:dialog.reportProblem.option4',
  'seriesplayer:dialog.reportProblem.option5',
];

const whiteboardHelpMenuTranslationKey = {
  abort: 'seriesplayer:dialog.reportProblem.abort',
  body: 'seriesplayer:dialog.reportProblem.body',
  comment: 'seriesplayer:dialog.reportProblem.comment',
  privacyWarning: 'seriesplayer:dialog.reportProblem.privacyWarning',
  select: 'seriesplayer:dialog.reportProblem.select',
  submit: 'seriesplayer:dialog.reportProblem.submit',
};

const whiteboardHelpMenuOptions = [
  'seriesplayer:dialog.whiteboardHelpMenu.option1',
  'seriesplayer:dialog.whiteboardHelpMenu.option2',
  'seriesplayer:dialog.whiteboardHelpMenu.option3',
  'seriesplayer:dialog.whiteboardHelpMenu.option4',
];

export function ReportProblemForm({ onCancel, onSubmit, children }: ReportProblemFormProps) {
  const [t] = useTranslation();
  const [remainingCharacters, setRemainingCharacters] = React.useState(MAX_CHARS);
  const [selectIndex, setSelectIndex] = React.useState(-1);
  const [selectValue, setSelectValue] = React.useState('n/a');
  const [textareaValue, setTextareaValue] = React.useState('');

  const whiteboardContext = useMaybeWhiteboardContext();
  const isWhiteboard = whiteboardContext !== undefined;
  const handleSubmit = React.useCallback(() => {
    if (textareaValue.length > 0 && selectIndex !== -1) {
      if (whiteboardContext === undefined) {
        onSubmit({
          type: 'seriesplayer',
          message: textareaValue,
          category: selectValue,
        });
      } else {
        const { scaleConfig, toolConfig, id, resultManagerUrl } = whiteboardContext.state;
        onSubmit({
          type: 'whiteboard',
          resultManagerUrl,
          message: compress(textareaValue),
          category: selectValue,
          femId: id ?? '',
          metadata: {
            zoomFactor: scaleConfig.currentScale,
            penColor: toolConfig.color,
            penWidth: toolConfig.penThickness,
            eraserWidth: toolConfig.eraserThickness,
            ...getBaseMetadata(),
          },
        });
      }
    }
  }, [textareaValue, selectValue, selectIndex, onSubmit, whiteboardContext]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newRemainingCharacters = MAX_CHARS - e.target.value.length;
    if (newRemainingCharacters >= 0) {
      setRemainingCharacters(newRemainingCharacters);
      setTextareaValue(e.target.value);
    }
  };

  const handleSelectChange = (index: number, value: React.ReactNode) => {
    setSelectIndex(index);
    setSelectValue(!value || isEmpty(value) ? 'n/a' : value.toString());
  };

  const options = isWhiteboard ? whiteboardHelpMenuOptions : reportProblemOptions;

  const translationKeys = isWhiteboard
    ? whiteboardHelpMenuTranslationKey
    : reportProblemTranslationKey;

  return (
    <Content>
      <div>
        <Dropdown
          stretch
          interactive
          open={false}
          selectedIndex={selectIndex}
          noSelectedStyles
          defaultText={t(translationKeys.select)}
          onItemSelected={handleSelectChange}
          lineBreak
          maxWidth={dialogWrapperStyles.DIALOG_L_CONTENT_WIDTH}
          dataCy="drop-down"
        >
          {options.map((option, index) => (
            <DropdownOption key={option} lineBreak dataCy={`drop-down-${index + 1}`}>
              {t(option)}
            </DropdownOption>
          ))}
        </Dropdown>
      </div>
      <p className="plaintext">{t(translationKeys.body)}</p>
      <textarea
        className="text-input"
        rows={6}
        value={textareaValue}
        placeholder={t(translationKeys.privacyWarning)}
        onChange={handleTextChange}
        data-cy="report-p-txt"
      />
      <p className="comment">{t(translationKeys.comment, { characters: remainingCharacters })}</p>
      {children}
      <DialogFooter align="center">
        <Button
          key="submit"
          dataCy="report-p-submit-btn"
          kind={ButtonKind.confirm}
          stretch={ButtonStretch.responsive}
          onClick={handleSubmit}
        >
          {t(translationKeys.submit)}
        </Button>
        <Button
          key="cancel"
          dataCy="report-p-abort-btn"
          kind={ButtonKind.abort}
          stretch={ButtonStretch.responsive}
          onClick={onCancel}
        >
          {t(translationKeys.abort)}
        </Button>
      </DialogFooter>
    </Content>
  );
}
