import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { connect } from 'react-redux';
import { isHintAvailable, isKEMAvailable } from '../../Exercise/helper';
import { ApplicationState, DialogType, StepStatus } from '../../../../../types';
import { showSolution } from '../../Exercise/actions';
import { closeDialog, openDialog } from '../../SeriesPlayer/actions';
import { HelpMenu, type HelpMenuDispatchProps, type HelpMenuStateProps } from './HelpMenu';

const mapDispatchToProps = (dispatch: Dispatch): HelpMenuDispatchProps => ({
  onCancel: () => dispatch(closeDialog()),
  onShowHints: () => {
    dispatch(closeDialog());
    dispatch(openDialog({ type: DialogType.hints }));
  },
  onShowTextBook: () => dispatch(openDialog({ type: DialogType.textbook })),
  onShowSolution: () => {
    dispatch(closeDialog());
    dispatch(showSolution());
  },
});

export const mapStateToProps = (applicationState: ApplicationState): HelpMenuStateProps => {
  const currentStep = ApplicationState.toCurrentStep.get(applicationState);
  const currentExercise = ApplicationState.toCurrentExercise.get(applicationState);
  return {
    isHelpShown: !!applicationState.dialog && applicationState.dialog.type === DialogType.help,
    isHintAvailable: !!currentStep && !!currentStep.hints && isHintAvailable(currentStep),
    isKEMAvailable: !!currentStep && isKEMAvailable(currentExercise, currentStep),
    isShowSolutionAvailable: !!currentStep && currentStep.status !== StepStatus.completed,
  };
};

export const HelpMenuContainer = connect(mapStateToProps, mapDispatchToProps)(HelpMenu);
