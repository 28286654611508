import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import {
  TreeChartGizmo,
  type TreeChartGizmoProps,
  type TreeChartGizmoCallbacks,
} from './TreeChartGizmo';
import { setNodeSize } from '../Graph/graphActions';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): TreeChartGizmoCallbacks => ({
  onNodeSize: (id, size) =>
    dispatch(gizmoAction(setNodeSize({ id, size }), refid, { skipUndo: true })),
});

export const TreeChartContainer = connectGizmo<TreeChartGizmoProps>(
  mapDispatchToProps,
  TreeChartGizmo
);
