import {
  type GizmoRoute,
  importContentTree,
  importers,
  isGizmoRoute,
  XMLs,
} from '@bettermarks/importers';
import { type FElement, toXmlElement } from '@bettermarks/gizmo-types';
import { Request } from '../../../seriesplayer/services/api/bm-api';
import { type GizmoViewerLoadData } from '../../types';

/**
 * get gizmo name as string and fetch XML
 * @param {string} gizmo
 * @returns {Promise<string>}
 */
const fetchGizmoXML = async (gizmo: GizmoRoute): Promise<string> => {
  const gizmoURL = `assets/xml/${XMLs[gizmo]}`;
  // Added to make sure artifacts can be loaded via circleci also.
  return Request.get(gizmoURL, 'include').then(async (result) => result.text());
};

export const loadGizmoService = async (gizmoRoute: string): Promise<GizmoViewerLoadData> => {
  let xml: FElement;
  if (isGizmoRoute(gizmoRoute)) {
    xml = toXmlElement(await fetchGizmoXML(gizmoRoute));
  } else {
    xml = toXmlElement(await fetchGizmoXML('error_message'));
  }

  const contentDict = importContentTree(importers)(xml, 'steps[0].question');
  return { contentDict };
};
