import * as React from 'react';
import {
  getStrokeWidth,
  POINT_RADIUS,
  SNAP_RADIUS,
  transformX,
  transformY,
} from '@bettermarks/importers';
import { getObjectColor } from './decorations';
import styles from './Point.scss';
import { type PointProps } from './Point';
import { type PointDecoration } from '@bettermarks/gizmo-types';

export type CircleShapeProps = PointProps & {
  activeAreaStyles: string;
  fillShape: boolean;
  decoration: PointDecoration;
};

export const CircleShape: React.FC<CircleShapeProps> = ({
  matrix,
  id,
  x,
  y,
  decoration,
  activeAreaStyles,
  fillShape,
  hover,
  onMouseDown,
  onMouseUp,
  onMouseOver,
  onClick,
  onMouseOut,
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  const displayR = POINT_RADIUS;
  const mouseR = SNAP_RADIUS;

  const fillColor = fillShape ? getObjectColor(decoration) : 'white';
  const interactive = onClick || onMouseDown || onMouseUp || onMouseOver || onMouseOut;

  return (
    <g className={activeAreaStyles}>
      <circle
        cx={sx(x)}
        cy={sy(y)}
        r={displayR}
        fill={fillColor}
        stroke={getObjectColor(decoration)}
        strokeWidth={getStrokeWidth(decoration, hover)}
      />

      {interactive && (
        <circle
          id={id}
          cx={sx(x)}
          cy={sy(y)}
          r={mouseR}
          className={styles.invisible}
          onMouseDown={onMouseDown && onMouseDown(id)}
          onTouchStart={onMouseDown && onMouseDown(id)}
          onMouseUp={onMouseUp}
          onMouseOver={onMouseOver && onMouseOver(id)}
          onClick={onClick && onClick(id)}
          onMouseOut={onMouseOut && onMouseOut(id)}
        />
      )}
    </g>
  );
};

CircleShape.displayName = 'CircleShape';
