import * as React from 'react';
import { Severity } from '@bettermarks/umc-kotlin';

import styles from './step.scss';
import { useTranslation } from 'react-i18next';

export type StepAttemptHeaderProps = Readonly<{
  count: number;
  severity: Severity;
  singleTry: boolean;
  skipped?: boolean;
}>;

export const StepAttemptHeader: React.FC<StepAttemptHeaderProps> = ({
  count,
  severity,
  singleTry,
  skipped,
}) => {
  const [t] = useTranslation();

  const getTitleText = () => {
    switch (severity) {
      case Severity.ok:
        if (skipped) {
          return 'skipped';
        }

        if (singleTry) {
          return 'correct';
        }
        return `${count === 1 ? 'first' : 'second'}Correct`;
      case Severity.remark:
        return 'remark';
      default:
        if (singleTry) {
          return 'incorrect';
        }
        return `${count === 1 ? 'first' : 'second'}Incorrect`;
    }
  };

  return (
    <div className={styles.attemptTitle}>
      <div className={styles.title}>
        {t(`seriesplayer:step.review.attemptTitle.${getTitleText()}`)}
      </div>
      <div className={styles.line} />
    </div>
  );
};

StepAttemptHeader.displayName = 'StepAttemptHeader';
