import log from 'loglevel';
import { select } from 'redux-saga/effects';
import { closeSeriesPlayer } from '../../constants';
import { type ApplicationState } from '../../../../types';
import { reduxStateFlowService } from '../../../store/jsstore';

/**
 * We communicate with the parent, which contains the Seriesplayer in iframe using
 * `postMessage`.
 * This publishes the event to containing Angular App, that Seriesplayer is to be closed.
 * The event listeners at Angular app takes care of managing the state of entire application.
 */
export const quitSeriesPlayer = () => {
  // eslint-disable-next-line no-restricted-globals
  parent && parent.postMessage(closeSeriesPlayer, '*');
};

/**
 * At the moment of catching exception, it's possible we don't have a redux state.
 * This makes us using `quitSeriesPlayer` method directly.
 */
export const getBackToNsp = () => {
  // eslint-disable-next-line no-restricted-globals
  if (parent && parent !== window) {
    // Due to Series Player is running in iframe,
    // this is expected to be called in production.
    quitSeriesPlayer();
  } else {
    // HACK: due to current routing via #, we need to rollback for two steps in history.
    // This should not be triggered in production.
    // eslint-disable-next-line no-restricted-globals
    history.go(-2);
  }
};

export function* quitSeriesPlayerSaga() {
  const state: ApplicationState = yield select();

  if (state.features.persistReduxStateFlow) {
    try {
      reduxStateFlowService.instance?.removeSeriesOlderThan(5);
    } catch (err) {
      log.warn('Could not remove expired series from IndexedDB.');
    }
  }

  // state.series.config is available only when user comes from NSP.
  // It contains the fetched config from backend.

  // Note that we currently quit the series player even if it's not embedded.
  // The reason for this is that we need to be able to quit the series player
  // even if we are looking at a single exercise instead of an exercise series
  // (in which case the series player is not embedded).
  // See also https://bettermarks.atlassian.net/browse/BM-59328
  quitSeriesPlayer();
  if (!state.appSettings.embedded) {
    // eslint-disable-next-line no-restricted-globals
    history.go(-1);
  }
}
