import { type ImporterRegistry, RS } from '@bettermarks/gizmo-types';
import { importHelloGizmo } from './gizmos/_template/importer';
import { importBallotBox } from './gizmos/ballot-box/importer';
import { importBalance } from './gizmos/balance/importer';
import { importClock } from './gizmos/clock/importer';
import { importSuperGizmo } from './gizmos/_super-gizmo/importer';
import { importBox } from './gizmos/_box/importer';
import { importBasicShape } from './gizmos/basic-shape/importer';
import { importAnimation } from './gizmos/animation/importer';
import { importGraph } from './gizmos/graph/Graph/importer';
import { importTreeChartGizmo } from './gizmos/graph/TreeChart/importer';
import { importHLine } from './gizmos/hline/importer';
import { importImage } from './gizmos/image/importer';
import { importIncludeGizmo } from './gizmos/include-gizmo/importer';
import { importItemList } from './gizmos/item-list/importer';
import { importLineHeader } from './gizmos/line-header/importer';
import { importMultipleChoiceDropDown } from './gizmos/multiple-choice-drop-down/importer';
import { importMultipleChoiceMulti } from './gizmos/multiple-choice-multi/importer';
import {
  importMultipleChoiceSingle,
  importMultipleChoiceSingleStack,
} from './gizmos/multiple-choice-single/importer';
import { importNlpText } from './gizmos/nlp-text/importer';
import { importPointer } from './gizmos/pointer/importer';
import { importSolutionSet } from './gizmos/solution-set/importer';
import { importSorting } from './gizmos/sorting/importer';
import { importSquaredPaper } from './gizmos/squaredpaper/importer';
import { importThermometer } from './gizmos/thermometer/importer';
import { importWheelOfFortune } from './gizmos/wheel-of-fortune/importer';
import { importTimeDuration } from './gizmos/time-duration/importer';
import { importBorderLayout } from './gizmos/layout-container/importer/importBorderLayout';
import { importLayoutContainer } from './gizmos/layout-container/importer/importLayoutContainer';
import { importDigit, importFormula, importTranslationKey } from './gizmos/formula/importer';
import { importCalendar } from './gizmos/calendar/importer';
import { importDayDuration } from './gizmos/day-duration/importer';
import { importFieldSet } from './gizmos/fieldset/importer';
import {
  importCaption,
  importDiagramBarChart,
  importPieChart,
  importStackedBarChart,
} from './gizmos/chart/importer';
import { importDraw2d, importDraw2dGeo } from './gizmos/draw2d/importer';
import { importGeo } from './gizmos/geo/importer';
import { importDragSource } from './gizmos/drag-and-drop/dragsource/importer';
import { importDropTarget } from './gizmos/drag-and-drop/droptarget/importer';
import { importSetDropTarget } from './gizmos/drag-and-drop/set-droptarget/importer';
import { importFractionForm } from './gizmos/fraction-form/importer';
import { importTimeSpan } from './gizmos/time-span/importer';
import { importTime } from './gizmos/time/importer';
import {
  importPlaceValueTable,
  importSimpleTable,
  importTableContainer,
} from './gizmos/table/importer';
import { importDynamicRepresentation } from './gizmos/dynamic-representation/importer';
import { importListSelection } from './gizmos/list-selection/importer';
import { importPdfLink } from './gizmos/pdf-link/importer';
import { importValueStepperReference } from './gizmos/value-stepper/importer';
import { importFreeDragAndDrop } from './gizmos/drag-and-drop/draganddrop/importer';

/**
 * This dictionary maps render styles to importers (xml to json converters).
 *
 * (Please add importers in alphabetical order)
 */

export const importers: ImporterRegistry = {
  [RS.ANIMATION]: importAnimation,
  [RS.BALANCE]: importBalance,
  [RS.BALLOT_BOX]: importBallotBox,
  [RS.BASIC_SHAPE]: importBasicShape,
  [RS.BORDER_LAYOUT]: importBorderLayout,
  [RS.CALENDAR]: importCalendar,
  [RS.CAPTION_BAR_CHART]: importCaption,
  [RS.CAPTION_CURVE_CHART]: importCaption,
  [RS.CAPTION_PIE_CHART]: importCaption,
  [RS.CIRCLE]: importFractionForm,
  [RS.CLOCK]: importClock,
  [RS.CLOCKTIME]: importTime,
  [RS.DAY_DURATION]: importDayDuration,
  [RS.DIAGRAM_BAR_CHART]: importDiagramBarChart,
  [RS.DIGIT]: importDigit,
  [RS.DRAG_SOURCE]: importDragSource,
  [RS.DROP_TARGET]: importDropTarget,
  [RS.FREE_DRAG_AND_DROP]: importFreeDragAndDrop,
  [RS.SET_DROP_TARGET]: importSetDropTarget,
  [RS.DRAW2D]: importDraw2d, // used for the 'original draw2d'
  [RS.DRAW2DGEO]: importDraw2dGeo, // used for exported and 'reimported' 'geoized draw2d'
  [RS.DYNAMIC_REPRESENTATION]: importDynamicRepresentation,
  [RS.EMPTY]: importBox,
  [RS.FIELD_SET]: importFieldSet,
  [RS.FORMULA]: importFormula,
  [RS.GRAPH]: importGraph,
  [RS.HELLO_GIZMO]: importHelloGizmo,
  [RS.HLINE]: importHLine,
  [RS.IMAGE]: importImage,
  [RS.INCLUDE_GIZMO]: importIncludeGizmo,
  [RS.INNER_TEXT]: importFormula,
  [RS.ITEM_LIST]: importItemList,
  [RS.LAYOUT_CONTAINER]: importLayoutContainer,
  [RS.LINE_HEADER]: importLineHeader,
  [RS.LIST_SELECTION]: importListSelection,
  [RS.METRICS_SYSTEM]: importGeo,
  [RS.MULTIPLE_CHOICE_DROP_DOWN]: importMultipleChoiceDropDown,
  [RS.MULTIPLE_CHOICE_MULTI]: importMultipleChoiceMulti,
  [RS.MULTIPLE_CHOICE_SINGLE]: importMultipleChoiceSingle,
  [RS.NLP_TEXT]: importNlpText,
  [RS.PDF_LINK]: importPdfLink,
  [RS.PIE_CHART]: importPieChart,
  [RS.PLACE_VALUE_TABLE]: importPlaceValueTable,
  [RS.POINTER]: importPointer,
  [RS.RECTANGLE]: importFractionForm,
  [RS.SIMPLE_TABLE]: importSimpleTable,
  [RS.SIMPLE_TABLE_CONTAINER]: importTableContainer,
  [RS.SOLUTION_SET]: importSolutionSet,
  [RS.SORTING]: importSorting,
  [RS.SQUARED_PAPER]: importSquaredPaper,
  [RS.STACK_LAYOUT]: importMultipleChoiceSingleStack,
  [RS.STACKED_BAR_CHART]: importStackedBarChart,
  [RS.SUPER_GIZMO]: importSuperGizmo,
  [RS.TRANSLATION_KEY]: importTranslationKey,
  [RS.TREE_DIAGRAM]: importTreeChartGizmo,
  [RS.TABLECELL_TEXT]: importFormula,
  [RS.TEXT]: importFormula,
  [RS.THERMOMETER]: importThermometer,
  [RS.TIME]: importTime,
  [RS.TIME_DURATION]: importTimeDuration,
  [RS.TIMESPAN]: importTimeSpan,
  [RS.VALUE_STEPPER]: importValueStepperReference,
  [RS.WHEEL_OF_FORTUNE]: importWheelOfFortune,
};
