import { connectGizmo, type MapContentDictToProps } from '../../../gizmo-utils/redux';
import {
  type FieldSetContent,
  getSeverityFromChildren as getSeverityFromChildrenUnstubable,
} from '@bettermarks/gizmo-types';

import { FieldSet, type FieldSetProps } from './FieldSet';
import { getFieldSetDecoration, getFieldSetDisabled } from './helper';

export const STUBABLE = {
  getSeverityFromChildren: getSeverityFromChildrenUnstubable,
};

export const mapContentDictToProps: MapContentDictToProps<FieldSetContent, FieldSetProps> = (
  { innerTextRefIds, hasInteractiveChild },
  contentDict
) => ({
  disabled: hasInteractiveChild && getFieldSetDisabled(innerTextRefIds, contentDict),
  fieldSetDecoration: getFieldSetDecoration(innerTextRefIds, contentDict),
});

export const FieldSetContainer = connectGizmo(() => ({}), FieldSet, {
  mapContentDictToProps,
});
