import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProblemReportedState } from '../../../../../../types';
import { Confirmation } from './Confirmation';
import { ReportProblemForm } from './ReportProblemForm';
import { Unsupported } from './Unsupported';
import { type ReportProblemDispatchProps, type ReportProblemStateProps } from '../ReportProblem';
import { ContextNotification, ContextNotificationKind } from '@seriesplayer/common-ui';

type ContentProviderProps = ReportProblemDispatchProps &
  ReportProblemStateProps & {
    isWhiteboard?: boolean;
  };

export function ContentProvider({
  onCancel,
  onSubmit,
  reportState,
  isWhiteboard,
}: ContentProviderProps) {
  const [t] = useTranslation();

  switch (reportState) {
    case ProblemReportedState.success:
      return <Confirmation onCancel={onCancel} />;
    case ProblemReportedState.failed:
      return (
        <ReportProblemForm onCancel={onCancel} onSubmit={onSubmit}>
          <ContextNotification
            dataCy="reporting-failed"
            kind={ContextNotificationKind.error}
            stretch
          >
            {t('seriesplayer:dialog.reportProblem.error')}
          </ContextNotification>
        </ReportProblemForm>
      );
    case ProblemReportedState.unsupported:
      return <Unsupported onCancel={onCancel} />;
    default:
      return <ReportProblemForm onCancel={onCancel} onSubmit={onSubmit} />;
  }
}
