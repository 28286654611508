import {
  $MI,
  $MN,
  $MO,
  type Content,
  type FormulaContent,
  isCursor,
  isMToken,
  type MathContent,
} from '@bettermarks/gizmo-types';
import * as ts from 'typescript';

const ACTUAL_EQUATION_X = '4';
export const FINAL_EQUATION_SOLUTION = 'x=4';

export const FINAL_TERM_SOLUTION = '34';

export const FINAL_SKIP_TERM_SOLUTION = '19.088';

export enum EquationValidatorResult {
  CORRECT,
  INCORRECT,
  INVALID_TOKEN,
  NOT_AN_EQUATION,
  NO_X,
  DONE,
  DONE_WITH_HELP,
  INTERNAL_ERROR,
}

export const wrongValidatorResults = [
  EquationValidatorResult.NO_X,
  EquationValidatorResult.NOT_AN_EQUATION,
  EquationValidatorResult.INVALID_TOKEN,
  EquationValidatorResult.INCORRECT,
];

export const validateEquation = (row: FormulaContent): EquationValidatorResult => {
  const isEmpty = row.content.filter((mathContent) => isMToken(mathContent)).length === 0;
  if (isEmpty) {
    return EquationValidatorResult.NOT_AN_EQUATION;
  }

  let lastOperatorIndex = -1;
  let containsInvalidToken = false;
  let rowLeftSideContentWithActualX: MathContent[] = [];
  let rowRightSideContentWithActualX: MathContent[] = [];
  let beforeEqualsSign = true;
  let containsX = false;

  row.content?.forEach((token, index) => {
    if (!isMToken(token) && !isCursor(token)) {
      containsInvalidToken = true;
    } else {
      if (token.$ === $MI) {
        containsX = true;
        const numberToken = { ...token, $: $MN, text: ACTUAL_EQUATION_X };

        if (lastOperatorIndex === index - 1) {
          if (beforeEqualsSign) {
            rowLeftSideContentWithActualX = rowLeftSideContentWithActualX.concat(numberToken);
          } else {
            rowRightSideContentWithActualX = rowRightSideContentWithActualX.concat(numberToken);
          }
        } else {
          if (beforeEqualsSign) {
            rowLeftSideContentWithActualX = rowLeftSideContentWithActualX.concat(
              operatorToken('*'),
              numberToken
            );
          } else {
            rowRightSideContentWithActualX = rowRightSideContentWithActualX.concat(
              operatorToken('*'),
              numberToken
            );
          }
        }
      } else {
        if (token.$ === $MO) {
          lastOperatorIndex = index;

          if (token.text === '=') {
            if (!beforeEqualsSign) {
              containsInvalidToken = true;
            }
            beforeEqualsSign = false;
          }
        }

        if (beforeEqualsSign) {
          rowLeftSideContentWithActualX = rowLeftSideContentWithActualX.concat(token);
        } else {
          if (isMToken(token) && token.text !== '=') {
            rowRightSideContentWithActualX = rowRightSideContentWithActualX.concat(token);
          }
        }
      }
    }
  });

  const leftSideResult = evaluateRowContentAsTypescript(rowLeftSideContentWithActualX);
  const rightSideResult = evaluateRowContentAsTypescript(rowRightSideContentWithActualX);

  const isCorrect = leftSideResult === rightSideResult;
  const isDone = isCorrect && getFormulaStringContent(row, false) === FINAL_EQUATION_SOLUTION;

  let validationResult;
  if (beforeEqualsSign) {
    validationResult = EquationValidatorResult.NOT_AN_EQUATION;
  } else if (!containsX) {
    validationResult = EquationValidatorResult.NO_X;
  } else if (containsInvalidToken) {
    validationResult = EquationValidatorResult.INVALID_TOKEN;
  } else if (isDone) {
    validationResult = EquationValidatorResult.DONE;
  } else if (isCorrect) {
    validationResult = EquationValidatorResult.CORRECT;
  } else {
    validationResult = EquationValidatorResult.INCORRECT;
  }

  return validationResult;
};

export const validateTerm = (
  row: FormulaContent,
  finalSolution?: string
): EquationValidatorResult => {
  const isEmpty = row.content.filter((mathContent) => isMToken(mathContent)).length === 0;
  if (isEmpty) {
    return EquationValidatorResult.NOT_AN_EQUATION;
  }

  let lastOperatorIndex = -1;
  let containsInvalidToken = false;
  let rowContentWithActualX: MathContent[] = [];

  row.content?.forEach((token, index) => {
    if (!isMToken(token) && !isCursor(token)) {
      containsInvalidToken = true;
    } else {
      if (token.$ === $MI) {
        const numberToken = { ...token, $: $MN, text: ACTUAL_EQUATION_X };

        if (lastOperatorIndex === index - 1) {
          rowContentWithActualX = rowContentWithActualX.concat(numberToken);
        } else {
          rowContentWithActualX = rowContentWithActualX.concat(operatorToken('*'), numberToken);
        }
      } else {
        if (token.$ === $MO) {
          lastOperatorIndex = index;

          if (token.text === '=') {
            containsInvalidToken = true;
          }
        }

        if (isMToken(token) && token.text !== '=') {
          rowContentWithActualX = rowContentWithActualX.concat(token);
        }
      }
    }
  });

  const leftSideResult = evaluateRowContentAsTypescript(
    convertStringToMathContentArray(finalSolution ?? FINAL_TERM_SOLUTION)
  );
  const rightSideResult = evaluateRowContentAsTypescript(rowContentWithActualX);

  const isCorrect = leftSideResult === rightSideResult;
  const isDone =
    isCorrect && getFormulaStringContent(row, false) === (finalSolution ?? FINAL_TERM_SOLUTION);

  let validationResult;
  if (containsInvalidToken) {
    validationResult = EquationValidatorResult.INVALID_TOKEN;
  } else if (isDone) {
    validationResult = EquationValidatorResult.DONE;
  } else if (isCorrect) {
    validationResult = EquationValidatorResult.CORRECT;
  } else {
    validationResult = EquationValidatorResult.INCORRECT;
  }

  return validationResult;
};

export const evaluateRowContentAsTypescript = (mathContentArray: MathContent[]) => {
  const expression = convertMathContentArrayToString(mathContentArray);
  const result = expression && ts.transpile(expression);
  return eval(result);
};

export const getFormulaStringContent = (row: Content | undefined, prettify?: boolean) => {
  return row
    ? convertMathContentArrayToString((row as unknown as FormulaContent).content, prettify)
    : '';
};

export const prettifyOperators = (expression: string) =>
  expression.split('*').join('⋅').split('/').join(':');

export const convertMathContentArrayToString = (
  mathContentArray: MathContent[],
  prettify?: boolean
) => {
  const operatorIndices = mathContentArray
    .map((token, index) => {
      return isMToken(token) && token.$ === $MO ? index : undefined;
    })
    .filter((index) => index);

  const expressionString = mathContentArray
    .map((token, index) => {
      if (isMToken(token)) {
        if (
          (token.text === 'x' || token.text === 'y' || index === 0) &&
          !operatorIndices.includes(index - 1)
        ) {
          return token.text;
        }
        return (prettify ? ' ' : '') + token.text;
      }
    })
    .join('');

  return prettify ? prettifyOperators(expressionString) : expressionString;
};

export const convertStringToMathContentArray = (string: string) => {
  const stringTokens = string.split('').filter((token) => token !== ' ');
  let mathContentTokens: MathContent[] = [];

  let currentNumber = '';

  stringTokens.forEach((stringToken) => {
    if (['+', '-', '*', '/', '='].includes(stringToken)) {
      mathContentTokens = [
        ...mathContentTokens,
        ...(currentNumber !== '' ? [numberToken(currentNumber)] : []),
        operatorToken(stringToken),
      ];
      currentNumber = '';
    } else if (['x', 'y'].includes(stringToken)) {
      mathContentTokens = [
        ...mathContentTokens,
        ...(currentNumber !== '' ? [numberToken(currentNumber)] : []),
        variableToken(stringToken),
      ];
      currentNumber = '';
    } else {
      currentNumber = currentNumber + stringToken;
    }
  });

  if (currentNumber !== '') {
    mathContentTokens = [...mathContentTokens, numberToken(currentNumber)];
  }

  return mathContentTokens;
};

const operatorToken = (text: string) => ({
  $: $MO,
  text,
  interactive: true,
  computedStyles: {},
});

const numberToken = (text: string) => ({
  $: $MN,
  text,
  interactive: true,
  computedStyles: {},
});

const variableToken = (text: string) => ({
  $: $MI,
  text,
  interactive: true,
  computedStyles: {},
});
