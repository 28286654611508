import { type ApplyStyles, type GizmoStyleForRefId } from '../../../../gizmo-utils/configuration';
import { flatten, isEmpty } from 'lodash';
import { type FormulaContent, isOuterInteractive } from '@bettermarks/gizmo-types';
import { mapSeverityToDecoration } from '@bettermarks/importers';
import { PLACEHOLDER_LABEL } from '../components/constants';
import { applyMathContentStyles } from './applyMathContentStyles';
import { mergeGizmoStyle } from './mergeGizmoStyle';
import { stepUpFormulaFontSize } from './stepUpFormulaFontSize';

/**
 * This is the applyStyles function for the formula gizmo. It returns a list of all "alien" gizmo
 * children with the style to be applied on them. It also populates the innerStyles map that is
 * passed to it with the styles applied to all m-tags.
 *
 * It is applied to the top level formula for the content and for the placeholder label. The content
 * is traversed using the function applyMathContentStyles().
 *
 * It handles interactive mode by stepping up the font size and passing it down to
 * applyMathContentStyles() to make sure that fractions are not scaled down.
 *
 * @param {FormulaContent} content
 * @param {GizmoStyle} outerStyles
 * @param {AppendOnlyMap<FormulaStyles>} innerStyles
 * @returns {ReadonlyArray<GizmoStyleForRefId>}
 */
export const applyFormulaStyles: ApplyStyles = (
  {
    $interactionType,
    content,
    placeholderLabel,
    decoration,
    $renderStyle,
    severity,
  }: FormulaContent,
  outerStyles,
  innerStyles
) => {
  const interactive = !isEmpty($interactionType);

  const mergedGizmoStyle = mergeGizmoStyle(
    { ...decoration, ...mapSeverityToDecoration(severity) },
    isOuterInteractive($renderStyle, $interactionType)
      ? stepUpFormulaFontSize(outerStyles)
      : outerStyles
  );

  innerStyles.set(
    '',
    interactive
      ? { ...mergedGizmoStyle.formulaStyles, interactive }
      : mergedGizmoStyle.formulaStyles
  );

  const styledPlaceHolderLabel: ReadonlyArray<GizmoStyleForRefId> = placeholderLabel
    ? applyMathContentStyles(
        placeholderLabel,
        mergedGizmoStyle,
        innerStyles,
        [PLACEHOLDER_LABEL],
        interactive
      )
    : [];

  const mappedContent = content.map((c, i) =>
    applyMathContentStyles(c, mergedGizmoStyle, innerStyles, [i], interactive)
  );

  mappedContent.push(styledPlaceHolderLabel);

  return flatten(mappedContent);
};
