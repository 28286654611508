import { Severity } from '@bettermarks/umc-kotlin';
import { numberFromStyles } from '@bettermarks/gizmo-types';
import { OptionState } from '../../components/MultipleChoice/types';
import odStyles from '../../components/MultipleChoice/OptionDecorator.scss';
import buttonStyles from '../../components/MultipleChoice/styles.scss';

export const OPTION_DECORATOR_WIDTHS =
  2 *
  (numberFromStyles(odStyles.OPTION_BORDER_WIDTH) +
    numberFromStyles(odStyles.OPTION_PADDING) +
    numberFromStyles(odStyles.OPTION_MARGIN));

export const BUTTON_WIDTH = numberFromStyles(buttonStyles.BUTTON_WIDTH);

export const getRenderState = (selected: boolean, severity?: Severity) => {
  if (!selected) {
    return OptionState.unselected;
  } else if (severity === Severity.error) {
    return OptionState.error;
  } else {
    return OptionState.selected;
  }
};
