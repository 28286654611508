import { type Severity } from '@bettermarks/umc-kotlin';
import { type AppendOnlyMap, type ReadonlyDict } from '../../gizmo-utils/append-only';
import { DATA, type MATH, PLACEHOLDER, SPECIAL } from '../../gizmo-utils/constants';
import {
  type Direction,
  type EditorMode,
  type HAlign,
  type LayoutMetrics,
  type VAlign,
} from '../../gizmo-utils/types';
import { type InputTool } from '../../types';
import { exceptionLog, ExceptionType } from '../../logging';

export type SemanticKind = typeof SPECIAL | typeof PLACEHOLDER | typeof DATA;
export function isSemanticsKind(kind: string | null): kind is SemanticKind {
  return kind === SPECIAL || kind === PLACEHOLDER || kind === DATA;
}

export type MathKind = typeof MATH;
export type ContentKind = SemanticKind | MathKind;

/**
 * Attributes directly derived from one of the possible tags inside `annotation`
 */
export type Annotations = {
  /**
   * The local name of the tag the attributes are derived from.
   * This is added so the same object literal can be used in tests as
   * - `preContent` for the first argument in gizmo importer,
   * - te create the expected or input XML using `annotationInner`/`placeholder`
   * - to spread into expected or input content object
   */
  $: ContentKind;

  /**
   * the unique id per exercise that was set by the generator
   */
  $id?: string;

  /**
   * The value that indicates what interactions that are possible.
   * If not present or empty, the content is considered to be displayed in "rendered" mode.
   * If present and not empty, the content is considered to be rendered in "interactive" mode.
   * (The "interactive" mode can still be changed to "disabled" mode,
   * but this is data that is not coming from the content but from the application state.)
   */
  $interactionType?: string;

  /**
   * The value that determines which Gizmo is responsible for this data.
   * In all the gizmo registries the render style, sometimes combined with $interactionType,
   * is mapped to some function from a gizmo.
   */
  $renderStyle: string;

  /**
   * used for formula validation
   */
  binding?: string;

  /**
   * @see FormulaContent.placeholderLabel
   */
  defaultText?: boolean;

  /**
   * used on interactive formulas inside "simple-table-container"
   * (read by the validator to check for specific feedback(s))
   */
  flavour?: string;

  /**
   * The selected gizmo will be kept over validation
   */
  selected?: boolean;

  /**
   * Used by layout container
   */
  gap?: number;

  /**
   * Used by layout container
   */
  hAlign?: HAlign;

  /**
   * hide this content => as there is an additional FB-only xml provided by the generator
   */
  hidden?: true;

  /**
   * Used by layout container
   */
  layout?: string;

  /**
   * used by multiple choice (stack layout)
   */
  xmlRefId?: string;

  /**
   * used by multiple choice
   */
  replacements?: string;

  /**
   * minimum formula input width in pixels
   */
  minInputWidth?: number;

  /**
   * maximum formula input width in pixels
   */
  maxInputWidth?: number;

  /**
   * maximum formula input height in pixels
   */
  maxInputHeight?: number;

  /**
   * minimum formula input length in characters
   */
  maxInputLength?: number;

  /**
   * Used by layout container (absolute)
   */
  posX?: number;

  /**
   * Used by layout container (absolute)
   */
  posY?: number;

  /**
   * active tool => to not lose currently selected tool in further attempt
   */

  selectedMode?: EditorMode;

  /**
   * available tools for the toolbar (just used for import/export)
   */
  toolSet?: string;

  /**
   * Used by layout container
   */
  vAlign?: VAlign;

  /**
   * Prevent scaling
   */
  noScale?: true;
};

export type Interactive = Readonly<Annotations> & {
  $interactionType: string;
};

// Used by Exercise Tooltip to localize the errors
export type ErrorMessage = {
  i18nKey: string;
  param?: string | number;
};

export type Content = Annotations & {
  modes?: ReadonlyArray<EditorMode>;
  tool?: InputTool;
  severity?: Severity;
  metrics?: LayoutMetrics; // extends Measurable
  disabled?: boolean;
  initiallyUnselected?: boolean; // whether it's not selected when entering a new step(e.g. formula)
  selectDirection?: Direction; // from where to enter the next gizmo
  unscaledWidth?: number;
  unscaledHeight?: number;
  errorMessage?: ErrorMessage; // shown in tooltip
};

export type ScalableContent = Content & {
  unscaledWidth: number;
  unscaledHeight: number;
  scalable: boolean;
};

export type NoDefaults =
  | '$id'
  | '$interactionType'
  | 'disabled'
  | 'selected'
  | 'unscaledWidth'
  | 'unscaledHeight';

/**
 * Allows to provide a readonly type that matches a content type,
 * but doesn't need to give all mandatory fields.
 *
 * Main use case is DEFAULT_CONTENT where certain mandatory fields
 * need to be added by the importer.
 *
 * This is why Defaults does not allow certain fields to be present:
 * @see NoDefaults
 * @see Omit
 * @see Readonly
 * FIXME would have loved to use DeepImmutableObject but it has issues
 */
export type Defaults<T extends Content, Without extends keyof T = NoDefaults> = Readonly<
  Omit<T, NoDefaults | Without>
>;

/**
 * A reference to content in a `ContentDict` or `ContentMap`.
 * `$refid` is also sometimes used as variable name for a string value to communicate
 * that it is one of the keys present in that ContentDict/-Map.
 */
export interface ContentReference {
  readonly $refid: string;
}

export const optionalRefId = (it?: ContentReference) => (it ? it.$refid : undefined);

/**
 * An (append only) map from $refid keys to an (ongoing) collection of (readonly) content.
 * Used during imprter phase for gathering all content (the flattened content tree).
 *
 * `AppendOnlyMap` provides `toDict()` to put the currently collected data
 * into a readonly object literal. This can be used to convert from `ContentMap` to `ContentDict`.
 */
export type ContentMap = AppendOnlyMap<Readonly<Content>>;

/**
 * A readonly object literal mapping $refid keys to (readonly) content.
 *
 * When the importer phase is done, the `ContentMap` that was used during that phase
 * is converted into an immutable `ContentDict`.
 */
export type ContentDict = ReadonlyDict<Readonly<Content>>;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ContentDict {
  /**
   * A key in a ContentDict is a string containing `${path}:${id}`
   * where `path` is not allowed to contain `:`.
   */
  export type Key = string;
  export const pathFromKey = (key: Key) => key.replace(/:.+$/, '');
  export const root = (it: ContentDict): Key => {
    let key: Key = '';
    try {
      const keys = Object.keys(it);
      key = pathFromKey(keys[0]);
    } catch (error) {
      exceptionLog(ExceptionType.other, error, { contentDictType: typeof it });
    }
    return key;
  };
  export const content = <T extends Content = Content>(
    it: ContentDict,
    key: Key = root(it)
  ): Nullable<T> => it[key] as Nullable<T>;
}
