export const ABSOLUTE_LAYOUT = 'absolute-layout';
export const ANIMATION = 'animation';
export const ARROW_WITH_LABEL = 'arrow-with-label';
export const ARROW_WITH_LABEL_HORIZONTAL = 'arrow-with-label-horizontal';
export const BALANCE = 'balance';
export const BALLOT_BOX = 'urn';
export const BASIC_SHAPE = 'basic-shape';
export const BORDER_LAYOUT = 'border-layout';
export const CALENDAR = 'calendar';
export const CAPTION_BAR_CHART = 'caption-bar-chart';
export const CAPTION_CURVE_CHART = 'caption-curve-chart';
export const CAPTION_PIE_CHART = 'caption-pie-chart';
export const CIRCLE = 'circle';
export const CLOCK = 'clock';
export const DAY_DURATION = 'dayduration';
export const DAY_SPAN = 'dayspan';
export const DIAGRAM_BAR = 'diagram-bar';
export const DIAGRAM_BAR_CHART = 'diagram-bar-chart';
export const DIAGRAM_BAR_GROUP = 'diagram-bar-group';
export const DIGIT = 'digit';
export const DRAG_SOURCE = 'dragV2-source';
export const DROP_TARGET = 'dragV2-target';
export const DRAW2D = 'draw2d';
export const DRAW2DGEO = 'draw2d-geo';
export const DYNAMIC_FORMULA = 'dynamic-formula';
export const DYNAMIC_REPRESENTATION = 'dynamic-representation';
export const EMPTY = 'empty';
export const FIELD_SET = 'fieldset';
export const FORMULA = 'formula';
export const FREE_DRAG_AND_DROP = 'free-drag-and-drop';
export const FUNCTION_PLOTTER = 'function-plotter'; // artificial render style!!
export const GRAPH = 'graph';
export const TREE_DIAGRAM = 'tree-diagram';
export const HELLO_GIZMO = 'hello-gizmo';
export const HLINE = 'hline';
export const IMAGE = 'image';
export const INCLUDE_GIZMO = 'include';
export const INNER_TEXT = 'inner-text';
export const ITEM_LIST = 'item-list';
export const LAYOUT_CONTAINER = 'layout-container';
export const LINE_HEADER = 'line-header';
export const LIST_SELECTION = 'list-selection';
export const METRICS_SYSTEM = 'metrics-system';
export const MULTIPLE_CHOICE_DROP_DOWN = 'multiple-choice-drop-down';
export const MULTIPLE_CHOICE_MULTI = 'multiple-choice-multi';
export const MULTIPLE_CHOICE_SINGLE = 'multiple-choice-single';
export const NLP_TEXT = 'nlp-text';
export const EDITABLE_NLP_PRESENTATION = 'editable-nlp-presentation';
export const PANEL = 'panel';
export const PDF_LINK = 'pdf-link';
export const PIE_CHART = 'pie-chart';
export const PLACE_VALUE_TABLE = 'place-value-table';
export const PLAINTEXT = 'plaintext';
export const POINTER = 'pointer';
export const RECTANGLE = 'rectangle';
export const SET_DROP_TARGET = 'dragV2-set-target';
export const SIMPLE_TABLE = 'simple-table';
export const SIMPLE_TABLE_CONTAINER = 'simple-table-container';
export const SLIDER = 'slider';
export const SORTING = 'sorting';
export const SQUARED_PAPER = 'squared-paper';
export const SOLUTION_SET = 'solution-set2';
export const STACK_LAYOUT = 'stack-layout';
export const STACKED_BAR_CHART = 'stacked-bar-chart';
export const SUPER_GIZMO = 'super-gizmo';
export const TABLECELL_TEXT = 'tablecell-text';
export const TIME_DURATION = 'timeduration';
export const TRANSLATION_KEY = 'translation-key';
export const TEXT = 'text';
export const TIME = 'time';
export const TIMESPAN = 'timespan';
export const CLOCKTIME = 'clock-time';
export const THERMOMETER = 'thermometer';
export const UNKNOWN = 'unknown';
export const VALUE_STEPPER = 'value-stepper';
export const WHEEL_OF_FORTUNE = 'wheel-of-fortune';
