import { defaultTo, head, last } from 'lodash';
import {
  DEFAULT_LINE_DECORATION,
  type Importer,
  LINE_HEADER_DEFAULT_CONTENT,
  type LineHeaderLineDecoration,
  type LineHeaderContent,
  LineStyle,
  LineWeight,
} from '@bettermarks/gizmo-types';

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style
 * @param xml The MathML (`semantics` Node) to parse
 * @returns The metadata and parsed xml as `Content`
 */
export const importLineHeader: Importer<LineHeaderContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const text = mrow.findChildTag('mtext').text;

  const decorationStr = mrow.attribute('decoration');
  const decoration = parseLineHeaderDeco(decorationStr);

  return {
    ...LINE_HEADER_DEFAULT_CONTENT,
    ...preContent,
    decoration,
    text,
  };
};

function parseLineHeaderDeco(decoString: string): LineHeaderLineDecoration {
  const propsArray = decoString
    .replace(/\s/g, '')
    .split(';')
    .map((deco) => {
      const splitDeco = deco.split(':');
      return { [head(splitDeco) as string]: last(splitDeco) };
    });

  const props = Object.assign({}, ...propsArray);

  let { lineStyle, lineWeight } = DEFAULT_LINE_DECORATION;
  Object.keys(props).forEach((key) => {
    const value = props[key];
    switch (key) {
      case 'line-style':
        lineStyle = defaultTo(value, LineStyle.dashed);
        break;
      case 'line-weight':
        lineWeight = defaultTo(value, LineWeight.medium);
        break;
      default:
    }
  });

  return { lineStyle, lineWeight };
}
