import { type ImageContent } from '@bettermarks/gizmo-types';
import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportImageContent = (content: ImageContent): string => {
  return semantics(
    `
      <source>${content.source}</source>
      <width>${content.width}</width>
      <height>${content.height}</height>
      `,
    annotationInner(content.$, content)
  );
};

export const exportImage: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as ImageContent;
  return exportImageContent(content);
};
