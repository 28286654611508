import {
  DROP_TARGET_BORDER_WIDTH,
  MARGIN,
  MIN_HEIGHT,
  PADDING,
  RECTANGLE_BORDER_WIDTH,
} from '../../../components/DragAndDrop';
import { defaultTo } from 'lodash';
import { type ApplyStyles, type Enricher } from '../../../gizmo-utils/configuration';

import { DragAndDropStyle, type DropTargetContent } from '@bettermarks/gizmo-types';

export const applyDropTargetStyles: ApplyStyles = (content: DropTargetContent, outerStyles) =>
  content.dragItemFixed
    ? [
        {
          refId: content.dragItemFixed,
          style: outerStyles,
        },
      ]
    : [];

export const measureDropTarget: Enricher<DropTargetContent> = (_, content) => {
  const offset =
    content.style === DragAndDropStyle.rectangle
      ? MARGIN + PADDING + RECTANGLE_BORDER_WIDTH
      : DROP_TARGET_BORDER_WIDTH;

  // for the target the height is either the empty min height or the height determined by auto
  // sizing
  const height = defaultTo(content.height, MIN_HEIGHT) + 2 * offset;

  return {
    enrichedContent: content,
    height,
    refLine: 0.5 * height - offset,
  };
};
