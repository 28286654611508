import * as React from 'react';
import classNames from 'classnames';
import { Icon, type IconProps } from '../../../../components/Icon';
import styles from './ExerciseTable.scss';

type SharedProps = {
  exercisesWithIconB: ReadonlyArray<number>;
  exerciseText: string;
  iconPropsA: IconProps;
  iconPropsB: IconProps;
  onClickItem?: (index: number) => void;
};

export type ExerciseTableProps = {
  numExercises: number;
} & SharedProps;

type ExerciseItemProps = {
  exerciseIndex: number;
} & SharedProps;

const ITEMS_PER_ROW = 4;

const ExerciseItem: React.FC<ExerciseItemProps> = ({
  exerciseIndex,
  exerciseText,
  exercisesWithIconB,
  iconPropsA,
  iconPropsB,
  onClickItem,
}) => {
  const withB = exercisesWithIconB.some((i) => i === exerciseIndex);
  const iconProps = withB ? iconPropsB : iconPropsA;
  const onClick = () => onClickItem && onClickItem(exerciseIndex);
  return (
    <div
      key={exerciseIndex}
      data-cy={`exercise-item-${withB ? 'b' : 'a'}`}
      role="button"
      className={classNames(styles.cell, { [styles.disabled]: !onClickItem })}
      onClick={onClick}
    >
      <Icon {...iconProps} className={styles.statusIcon} />
      <span>{`${exerciseText} ${exerciseIndex + 1}`}</span>
    </div>
  );
};

/**
 * The `ExerciseTable` displays a table of exercises with `numExercises` items consisting
 * of an icon and the `exerciseText`. The icons can be controlled from outside by passing
 * `IconProps` for two different icons (A and B) and a list of indices where to use icon B
 * (`exercisesWithIconB`).
 *
 * When a `onClickItem` function is passed the items have a hover effect and can be clicked.
 *
 *
 * ### Properties
 | Name          | Type                          | Default | Description               |
 |---            |---                            |---      |---                        |
 | `numExercises`   | `number`                   |      | The number of items in the table |
 | `iconPropsA`   | `IconProps`                  |      | The props for icon A (iconKey + styles) |
 | `iconPropsB`   | `IconProps`                  |      | The props for icon B (iconKey + styles) |
 | `exercisesWithIconB`   | ReadonlyArray<number>|      | The indices that use icon B |
 | `exerciseText`   | string |      | The text to display on all items |
 | `onClickItem`   | | function     | The click handler for the items |
 */
export const ExerciseTable: React.FC<ExerciseTableProps> = ({
  exerciseText,
  iconPropsA,
  iconPropsB,
  numExercises,
  onClickItem,
  exercisesWithIconB,
}) => {
  const numFullRows = Math.floor(numExercises / ITEMS_PER_ROW);
  const numItemsPartialRow = numExercises % ITEMS_PER_ROW;
  const exerciseItemProps = {
    exerciseText,
    exercisesWithIconB,
    iconPropsA,
    iconPropsB,
    onClickItem,
  };

  const exerciseIndex = (idx: number, cellIdx: number) => idx * ITEMS_PER_ROW + cellIdx;

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table} data-cy="exercise-table">
        {Array.from({ length: numFullRows }).map((_, rowIdx) => (
          <div key={rowIdx} className={styles.row}>
            {Array.from({ length: ITEMS_PER_ROW }).map((_, cellIdx) => (
              <ExerciseItem
                key={exerciseIndex(rowIdx, cellIdx)}
                exerciseIndex={exerciseIndex(rowIdx, cellIdx)}
                {...exerciseItemProps}
              />
            ))}
          </div>
        ))}
        {numItemsPartialRow > 0 && (
          <div className={styles.row}>
            {Array.from({ length: numItemsPartialRow }).map((_, cellIdx) => (
              <ExerciseItem
                key={exerciseIndex(numFullRows, cellIdx)}
                exerciseIndex={exerciseIndex(numFullRows, cellIdx)}
                {...exerciseItemProps}
              />
            ))}
            {Array.from({ length: ITEMS_PER_ROW - numItemsPartialRow }).map((_, idx) => (
              <div key={idx} className={classNames(styles.cell, styles.noBorder)} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
