import { stepFontSize } from '@bettermarks/bm-font';
import { type GizmoStyle } from '../../../../gizmo-utils/configuration';
import { INTERACTIVE_FORMULA_SCALING_STEP } from '../components/constants';

export const stepUpFormulaFontSize = ({
  formulaStyles: { fontSize, ...formulaStyles },
  ...outerStyles
}: GizmoStyle): GizmoStyle => ({
  ...outerStyles,
  formulaStyles: {
    ...formulaStyles,
    fontSize: stepFontSize(fontSize, INTERACTIVE_FORMULA_SCALING_STEP),
  },
});
