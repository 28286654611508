import {
  annotationInner,
  type Bitmask,
  type Exporter,
  type ExporterContext,
  type FractionFormContent,
  semantics,
} from '@bettermarks/gizmo-types';

const obtainSegments = (bitmasks: ReadonlyArray<Bitmask>) => {
  // need to export "wholes" that are not filled (i.e. [false]) as fractions for validation
  const fractionBitmask = bitmasks.find((bitmask) => bitmask.length > 1 || !bitmask[0]);
  const num = fractionBitmask && fractionBitmask.filter((value) => value).length;
  const denom = fractionBitmask && fractionBitmask.length;
  return (
    fractionBitmask &&
    `
    <mfrac>
      <mn>${num}</mn>
      <mn>${denom}</mn>
    </mfrac>
    `
  );
};

export const exportFractionForm: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as FractionFormContent;

  if (content.bitmasks.length > 0) {
    // wholes that are not filled (i.e. [false]) need to be exported as fractions for validation
    const wholes = content.bitmasks.filter((bitmask) => bitmask.length === 1 && bitmask[0]).length;
    return semantics(
      // exporting bitmasks to reconstruct the user's coloring scheme after validation
      `<mrow bitmasks="${JSON.stringify(content.bitmasks)}">
          ${wholes > 0 ? `<mn>${wholes}</mn>` : ''}
          ${obtainSegments(content.bitmasks)}
        </mrow>
      `,
      annotationInner(content.$, content)
    );
  } else {
    return semantics(`<mrow/>`, annotationInner(content.$, content));
  }
};
