import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { Button, ButtonKind, ButtonSize, ButtonBackground } from '@seriesplayer/common-ui';
import styles from './Betty.scss';
import { TimesLargeBold } from '../../../../components';
import {
  BETTY_ANIMATIONS,
  BETTY_SUCCESS_TEXTS,
  ENTER_ANIMATION_TIME,
  EXIT_ANIMATION_TIME,
  SPRITE_SHEET,
} from './constants';
import { SpriteSheet, type SpriteSheetAPI } from '../SpriteSheet';

export type AnimState = 'init' | 'appear' | 'disappear';

export interface DispatchProps {
  onClose: () => void;
}

export interface StateProps {
  show: boolean;
}

interface BettyProps extends StateProps, DispatchProps {}

function getRandomBettyText() {
  return BETTY_SUCCESS_TEXTS[Math.floor(Math.random() * BETTY_SUCCESS_TEXTS.length)];
}

export const Betty: React.FC<BettyProps> = ({ onClose, show }) => {
  const [animation, setAnimation] = React.useState<AnimState>('appear');
  const [betty, setBetty] = React.useState<SpriteSheetAPI>();
  const textKey = getRandomBettyText();

  const onLoad = (api: SpriteSheetAPI) => {
    setBetty(api);
  };

  const [t] = useTranslation();

  return (
    <CSSTransition
      in={show}
      appear={true}
      classNames={{
        enter: styles.bettySectionEnter,
        enterActive: styles.bettySectionEnterActive,
        enterDone: styles.bettySectionEnterDone,
        exit: styles.bettySectionExit,
        exitActive: styles.bettySectionExitActive,
        exitDone: styles.bettySectionExitDone,
      }}
      onExited={() => {
        betty && betty.stop();
      }}
      onEntered={() => {
        setAnimation('appear');
        betty && betty.play();
      }}
      timeout={{
        enter: ENTER_ANIMATION_TIME,
        exit: EXIT_ANIMATION_TIME,
      }}
    >
      <div
        className={classNames(styles.bettySection, {
          [styles.expanded]: show,
        })}
      >
        <div className={styles.bettyContainer}>
          <div className={styles.betty}>
            <SpriteSheet
              frameRate={12}
              width={182}
              height={202}
              startFrame={BETTY_ANIMATIONS[animation].start}
              numFrames={BETTY_ANIMATIONS[animation].count}
              onLoad={onLoad}
              loop={false}
              onFinished={animation === 'disappear' ? onClose : undefined}
              src={SPRITE_SHEET}
            />
          </div>
          <div className={styles.bettyTextSection}>
            <p className={styles.bettyText}>{t(textKey)}</p>
          </div>
          <Button
            size={ButtonSize.m}
            kind={ButtonKind.action}
            collapseSpace={true}
            background={ButtonBackground.transparent}
            onClick={onClose}
          >
            <TimesLargeBold />
          </Button>
        </div>
      </div>
    </CSSTransition>
  );
};

Betty.displayName = 'Betty';
