import { dimensions } from '@seriesplayer/common-ui';
import { type ContentReference, type IFreeDragAndDrop } from '@bettermarks/gizmo-types';
import { isNil } from 'lodash/fp';
import * as React from 'react';
import styled, { css } from 'styled-components';
import DnD from '../../../components/DragAndDrop/dnd';
import { PolymorphicGizmo, type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { getContentRect } from '../../../gizmo-utils/useMeasure/helpers';
import { FREE_DRAG_ITEM_PREFIX, FREE_DROPPED_ITEM_COPY_SUFFIX } from '../constants';
import { itemsAreSecond, itemsDirection, type Direction } from './utils';
import { VIRTUAL_CONTENT_WIDTH } from '../../../apps/whiteboard/constants';
import { getOnTopZIndex } from '../../../utils/z-index/update-z-index';

const STYLED_BORDER_WIDTH_OFFSET = 24;
const FREE_DRAG_AND_DROP_MIN_HEIGHT = 425;
const FREE_DRAG_AND_DROP_MAX_HEIGHT = 560;

interface StyledBorderProps {
  width: number;
  direction: Direction;
  itemsAreSecond: boolean;
}

const StyledBorder = styled.div<StyledBorderProps>`
  min-height: ${FREE_DRAG_AND_DROP_MIN_HEIGHT}px;
  ${({ direction }) =>
    direction === 'horizontal' && `max-height: ${FREE_DRAG_AND_DROP_MAX_HEIGHT}px;`}
  display: flex;
  flex-direction: ${({ direction, itemsAreSecond }) =>
    `${direction === 'horizontal' ? 'row' : 'column'}${itemsAreSecond ? '-reverse' : ''}`};
  border: 1px solid black;
  padding: 10px;
  max-width: ${VIRTUAL_CONTENT_WIDTH}px;

  ${(props) =>
    css`
      width: ${props.width - STYLED_BORDER_WIDTH_OFFSET}px;
    `}
`;

interface StyledContentAreaProps {
  ratio: number;
  direction: Direction;
}

const StyledContentArea = styled.div<StyledContentAreaProps>`
  display: flex;
  ${({ ratio, direction }) =>
    direction === 'horizontal' ? `width: ${ratio}%` : `flex-grow: ${ratio}`};
`;

interface StyledItemAreaProps {
  ratio: number;
  direction: Direction;
  itemsAreSecond: boolean;
}

const StyledItemArea = styled.div<StyledItemAreaProps>`
  ${({ ratio, direction }) =>
    direction === 'horizontal' ? `width: ${ratio}%` : `flex-grow: ${ratio}`};
  ${({ direction }) => direction === 'horizontal' && `flex-direction: column;`}
  align-items: ${({ itemsAreSecond }) => (itemsAreSecond ? 'flex-end' : 'flex-start')}};
  display: flex;
  flex-wrap: wrap;
  flex-basis: fit-content;
  align-content: ${({ itemsAreSecond }) => (itemsAreSecond ? 'flex-end' : 'flex-start')};
`;

const DraggableItem = styled.div`
  position: relative;
  margin: ${dimensions.spaceXxs};
`;

const DroppedItem = styled.div`
  position: relative;
`;

type FreeDragAndDropGizmoProps = IFreeDragAndDrop & ContextState;

interface FreeDragItemProps {
  item: ContentReference;
  width?: number;
  height?: number;
}

const FreeDragItem = React.forwardRef<HTMLDivElement, FreeDragItemProps>(({ item }, ref) => (
  <DraggableItem
    id={`${FREE_DRAG_ITEM_PREFIX}-${item.$refid}`}
    key={`${FREE_DRAG_ITEM_PREFIX}-${item.$refid}`}
    ref={ref}
  >
    <PolymorphicGizmo refid={item.$refid} availableWidth={200} />
  </DraggableItem>
));

const FreeDroppedItem: React.FC<{
  item: ContentReference;
  droppedItemRef: DragItemRef;
}> = ({ item, droppedItemRef }) => (
  <DroppedItem
    id={`${FREE_DRAG_ITEM_PREFIX}-${item.$refid}`}
    key={`${FREE_DRAG_ITEM_PREFIX}-${item.$refid}`}
    ref={(element) => {
      if (droppedItemRef) {
        droppedItemRef[item.$refid] = element;
      }
    }}
  >
    <PolymorphicGizmo refid={item.$refid} availableWidth={200} />
  </DroppedItem>
);

type DragItemRef = {
  [$refId: string]: HTMLElement | null;
};

export default function FreeDragAndDropGizmo({
  configuration,
  items,
  translations,
  inTarget,
  content,
  droppedItems,
  availableWidth,
}: FreeDragAndDropGizmoProps) {
  const {
    alignItems,
    screenRatio: [firstRatio, secondRatio],
  } = configuration;

  const initialDragItemRefs = items.reduce((acc, item) => ({ ...acc, [item.$refid]: null }), {});
  const initialDroppedItemRefs = droppedItems.reduce(
    (acc, item) => ({ ...acc, [item.$refid]: null }),
    {}
  );
  const dragItemRefs = React.useRef<DragItemRef>(initialDragItemRefs);
  const droppedItemRefs = React.useRef<DragItemRef>(initialDroppedItemRefs);

  const styledBorderRef = React.useRef<HTMLDivElement>(null);
  const direction = itemsDirection(alignItems);
  const itemsSecond = itemsAreSecond(alignItems);

  React.useEffect(() => {
    if (styledBorderRef.current !== null) {
      const ref = styledBorderRef.current;
      const measureBoundsFn = () => getContentRect(['bounds'], ref).bounds;
      items.map(({ $refid }) => DnD.addFreeSnappingRect($refid, measureBoundsFn));
    }
  }, [styledBorderRef, items]);

  // Once measures are done, it applies CSS translations on each drag item
  React.useEffect(() => {
    if (Object.values(dragItemRefs).every((element) => element !== null)) {
      Object.entries(translations).forEach(([itemRefId, translate]) => {
        // this is used for stacked items which are copied, they need to be added
        // manually to the refs array
        if (itemRefId.includes(FREE_DROPPED_ITEM_COPY_SUFFIX)) {
          dragItemRefs.current[itemRefId] = document.getElementById(
            itemRefId.replace('fems', 'free-drag-item-fems')
          );
        }

        const freeItem = dragItemRefs.current[itemRefId];

        const innerGizmo = freeItem?.firstElementChild;
        if (!isNil(freeItem) && !isNil(innerGizmo) && innerGizmo instanceof HTMLElement) {
          if (translate.translateX === 0 && translate.translateY === 0) {
            freeItem.style.zIndex = getOnTopZIndex();
            DnD.resetTransformation(innerGizmo);
          } else {
            // When the item is inside a drop target, the drop target is already
            // rendering the free item.
            // We still need to keep the freeItem rendered by the Free Drag and Drop gizmo
            // to keep translations up to date.
            freeItem.style.zIndex = inTarget ? '-1' : getOnTopZIndex();
            innerGizmo.style.position = 'absolute';
            freeItem.style.pointerEvents = 'none'; // prevent click events on the free item container, so that it does not interfere with other gizmos
            innerGizmo.style.pointerEvents = 'auto'; // allow click events on the inner gizmo, so that it can be interacted with
            const isCopy = itemRefId.includes(FREE_DROPPED_ITEM_COPY_SUFFIX);
            DnD.transformFreeDragItem(freeItem, innerGizmo, translate, isCopy);
          }
        }
      });
    }
  }, [translations, dragItemRefs, inTarget]);

  const droppedItemList = droppedItems.map((item, idx) => (
    <FreeDroppedItem item={item} key={idx} droppedItemRef={droppedItemRefs.current} />
  ));

  const dragItems = items.map((item, idx) => (
    <FreeDragItem
      item={item}
      key={idx}
      ref={(element) => {
        if (dragItemRefs.current) {
          dragItemRefs.current[item.$refid] = element;
        }
      }}
    />
  ));

  const [itemRatio, contentRatio] = itemsSecond
    ? [secondRatio, firstRatio]
    : [firstRatio, secondRatio];

  return (
    <StyledBorder
      ref={styledBorderRef}
      width={availableWidth}
      direction={direction}
      itemsAreSecond={itemsSecond}
    >
      <StyledItemArea itemsAreSecond={itemsSecond} direction={direction} ratio={itemRatio}>
        {droppedItemList}
        {dragItems}
      </StyledItemArea>

      <StyledContentArea direction={direction} ratio={contentRatio}>
        <PolymorphicGizmo
          key={content.$refid}
          refid={content.$refid}
          availableWidth={availableWidth}
        />
      </StyledContentArea>
    </StyledBorder>
  );
}
