import { SECOND_SIDEBAR_WIDTH, TOOLBAR_WIDTH } from './toolbar/constants';
import { MAX_AUTO_SCALE, MIN_AUTO_SCALE, USER_SCALE_VALUES } from './constants';
import { type ScaleSetting } from './types';

export function calculateAutoScale(
  twoSidebars: boolean,
  virtualContentWidth: number,
  virtualContentHeight: number,
  windowWidth: number,
  windowHeight: number
) {
  const { availableWidthFactor, availableHeightFactor } = calculateAvailableSizeFactors(
    twoSidebars,
    virtualContentWidth,
    virtualContentHeight,
    windowWidth,
    windowHeight
  );
  return Math.min(
    Math.max(Math.min(availableWidthFactor, availableHeightFactor), MIN_AUTO_SCALE),
    MAX_AUTO_SCALE
  );
}

export function getToolbarsWidth(twoSidebars: boolean) {
  return TOOLBAR_WIDTH + (twoSidebars ? SECOND_SIDEBAR_WIDTH : 0);
}

export function getWidthWithoutToolbars(twoSidebars: boolean, windowWidth: number) {
  const toolbarWidth = getToolbarsWidth(twoSidebars);
  return windowWidth - toolbarWidth;
}

function calculateAvailableSizeFactors(
  twoSidebars: boolean,
  virtualContentWidth: number,
  virtualContentHeight: number,
  windowWidth: number,
  windowHeight: number
) {
  const availableWidthFactor =
    getWidthWithoutToolbars(twoSidebars, windowWidth) / virtualContentWidth;
  const availableHeightFactor = windowHeight / virtualContentHeight;
  return { availableWidthFactor, availableHeightFactor };
}

export function getNextHigherScale(previousScale: number): number {
  const firstHigherIndex = USER_SCALE_VALUES.findIndex((n) => n > previousScale);
  let scale = previousScale;
  switch (firstHigherIndex) {
    case -1:
      scale = USER_SCALE_VALUES[USER_SCALE_VALUES.length - 1];
      break;
    default:
      scale = USER_SCALE_VALUES[firstHigherIndex];
  }

  return scale;
}

export function getNextLowerScale(previousScale: number): number {
  const firstHigherIndex = USER_SCALE_VALUES.findIndex((n) => n >= previousScale);
  let scale = previousScale;
  switch (firstHigherIndex) {
    case -1:
      scale = USER_SCALE_VALUES[USER_SCALE_VALUES.length - 1];
      break;
    case 0:
      scale = USER_SCALE_VALUES[0];
      break;
    default:
      scale = USER_SCALE_VALUES[firstHigherIndex - 1];
  }

  return scale;
}

export function getScale(currentScale: number, setScale: ScaleSetting, autoScaleFn: () => number) {
  let scale = currentScale;
  switch (setScale) {
    case 'auto':
      scale = autoScaleFn();
      break;
    case 'off':
      scale = 1;
      break;
    case 'bigger':
      scale = getNextHigherScale(currentScale);
      break;
    case 'smaller':
      scale = getNextLowerScale(currentScale);
      break;
  }
  return scale;
}
