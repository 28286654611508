import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import {
  type GeoCallbacks,
  type GeoContentPersistProps,
  ShouldEnrichKind,
} from '@bettermarks/gizmo-types';
import { Geo as GeoComponent } from './Geo';
import { localStoreToRedux, scaleDown } from './geoActions';

export const mapDispatchToProps = (dispatch: Dispatch, { refid }: GizmoProps): GeoCallbacks => ({
  onPersistLocalState: (props: GeoContentPersistProps) =>
    dispatch(
      gizmoAction(localStoreToRedux(props), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
      })
    ),
  onScalingDown: (scale: number) =>
    dispatch(
      gizmoAction(scaleDown({ scale }), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        skipUndo: true,
      })
    ),
});

export const GeoContainer = connectGizmo(mapDispatchToProps, GeoComponent);
