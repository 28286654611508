import { type Action, createAction, handleActions } from 'redux-actions';
import { isEmpty, omit, uniq } from 'lodash';
import {
  type CircleObject,
  type Coords,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type GeoScene,
  Hover,
  POINT,
  PointHighlight,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import {
  add,
  DEFAULT_IDCOORDS,
  GEO_DEFAULT_PERSIST_PROPS,
  getPointCoordsId,
  inViewbox,
  screenToWorld,
  sub,
  TEMP_POINT,
  worldToScreen,
} from '@bettermarks/importers';
import { type GeoMoveState } from './GeoMove';
import { getCircleId } from '../../helpers';
import { mergeTwoPoints, removePoint, replacePoint } from './helper';
import { getSnapPoint } from '../../snap';
import { deepResetSeverity, resetSeverity } from '../helpers';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */

export interface DragPayload {
  configuration: GeoConfiguration;
  matrix: number[];
}

export interface DragPointPayload extends DragPayload {
  snapPoint: SnapPoint | null;
}

export interface DragCirclePayload extends DragPayload {
  scene: GeoScene;
  scale: number;
  mouseP: Coords;
}

export interface StartCircleDragPayload {
  id: string;
  matrix: number[];
  mouseP: Coords;
}

export interface EndDragPayload {
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface PointOverPayload {
  id: string;
  matrix: number[];
}

export type GeoMovePointPayload =
  | EndDragPayload
  | DragPointPayload
  | DragCirclePayload
  | StartCircleDragPayload
  | PointOverPayload
  | SnapPoint
  | string
  | void;

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const DRAG_POINT: 'DRAG_POINT' = 'DRAG_POINT';
export const dragPointAction = createAction<DragPointPayload>(DRAG_POINT);
const DRAG_CIRCLE: 'DRAG_CIRCLE' = 'DRAG_CIRCLE';
export const dragCircleAction = createAction<DragCirclePayload>(DRAG_CIRCLE);
const END_DRAG: 'END_DRAG' = 'END_DRAG';
export const endDragAction = createAction<EndDragPayload>(END_DRAG);
const START_POINT_DRAG: 'START_POINT_DRAG' = 'START_POINT_DRAG';
export const startPointDragAction = createAction<string>(START_POINT_DRAG);
const START_CIRCLE_DRAG: 'START_CIRCLE_DRAG' = 'START_CIRCLE_DRAG';
export const startCircleDragAction = createAction<StartCircleDragPayload>(START_CIRCLE_DRAG);
const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPoint>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);
const CIRCLE_OVER: 'CIRCLE_OVER' = 'CIRCLE_OVER';
export const overCircleAction = createAction<string>(CIRCLE_OVER);
const CIRCLE_LEAVE: 'CIRCLE_LEAVE' = 'CIRCLE_LEAVE';
export const leaveCircleAction = createAction<string>(CIRCLE_LEAVE);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  selectedObjectId: '',
  prevPos: DEFAULT_IDCOORDS,
  highlight: PointHighlight.move,
  isCircleDrag: false,
  mousePosOffset: { x: 0, y: 0 },
};

export const geoMoveReducer = handleActions<GeoMoveState, GeoMovePointPayload>(
  {
    [START_POINT_DRAG]: (state: GeoMoveState, { payload }: Action<string>) => {
      if (!payload) {
        return state;
      }
      return {
        ...state,
        selectedObjectId: payload,
        snapPoints: [],
        // reset severity of point and objects referring to point
        persistProps: {
          ...state.persistProps,
          geoContentMap: deepResetSeverity(state.persistProps.geoContentMap, payload),
        },
      };
    },
    [START_CIRCLE_DRAG]: (state: GeoMoveState, { payload }: Action<StartCircleDragPayload>) => {
      if (!payload) {
        return state;
      }
      const { id, matrix, mouseP } = payload;

      return {
        ...state,
        selectedObjectId: id,
        isCircleDrag: true,
        mousePosOffset: sub(
          screenToWorld(matrix)(mouseP),
          (state.persistProps.geoContentMap[id] as CircleObject).coords
        ),
        snapPoints: [],
        // reset severity of circle once you start dragging
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: resetSeverity(state.persistProps.geoContentMap[id]),
          },
        },
      };
    },
    [DRAG_POINT]: (state: GeoMoveState, { payload }: Action<DragPointPayload>) => {
      if (!payload || isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { snapPoint, matrix, configuration } = payload;
      const currentTargetId = isEmpty(state.prevPos.id) ? state.selectedObjectId : state.prevPos.id;

      if (snapPoint && currentTargetId in state.persistProps.geoContentMap) {
        const snapPointCoords = screenToWorld(matrix)(snapPoint);

        // point needs to be within our viewbox!!!
        if (!inViewbox(snapPointCoords, configuration.display)) {
          return state;
        }

        const newPoint = { id: TEMP_POINT, coords: snapPointCoords };
        const newPersistProps = replacePoint(state.persistProps, currentTargetId, newPoint, false);

        const points = uniq([...newPersistProps.points, state.selectedObjectId]);
        return {
          ...state,
          persistProps: {
            ...newPersistProps,
            geoContentMap: {
              ...newPersistProps.geoContentMap,
              [state.selectedObjectId]: {
                ...state.persistProps.geoContentMap[state.selectedObjectId],
                invisible: true,
              },
            },
            points,
          },
          prevPos: newPoint,
          snapPoints: snapPoint ? [snapPoint] : [],
          highlight: undefined,
        };
      }

      return {
        ...state,
        snapPoints: [],
        highlight: undefined,
      };
    },
    [DRAG_CIRCLE]: (state: GeoMoveState, { payload }: Action<DragCirclePayload>) => {
      if (!payload || isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { configuration, matrix, mouseP, scene, scale } = payload;
      const id = state.selectedObjectId;

      const snapPointCoords = screenToWorld(matrix)(mouseP);
      const circle = state.persistProps.geoContentMap[id] as CircleObject;

      // need to add the mousePosOffset to the new circleCenter position
      const newCircleCenterCoords = isNaN(state.prevPos.coords.x)
        ? circle.coords
        : add(state.mousePosOffset, add(circle.coords, sub(state.prevPos.coords, snapPointCoords)));

      const circleCenterSnapPoint = getSnapPoint(
        matrix,
        scene,
        scale
      )(worldToScreen(matrix)(newCircleCenterCoords));

      const newSnapCenterCoords = circleCenterSnapPoint
        ? screenToWorld(matrix)({
            x: circleCenterSnapPoint.x,
            y: circleCenterSnapPoint.y,
          })
        : newCircleCenterCoords;

      // circle center needs to be within our viewbox!!!
      if (!inViewbox(newSnapCenterCoords, configuration.display)) {
        return state;
      }

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: circleCenterSnapPoint
              ? {
                  ...circle,
                  coords: newSnapCenterCoords,
                  centerHighlight: PointHighlight.move,
                  hover: Hover.PREVIEW,
                }
              : state.persistProps.geoContentMap[id],
          },
        },
        prevPos: circleCenterSnapPoint
          ? {
              id: getCircleId(newSnapCenterCoords, circle.radius),
              coords: newCircleCenterCoords,
            }
          : state.prevPos,
        highlight: undefined,
        snapPoints: circleCenterSnapPoint ? [circleCenterSnapPoint] : state.snapPoints,
      };
    },
    /* eslint-disable-next-line complexity*/
    [END_DRAG]: (state: GeoMoveState, { payload }: Action<EndDragPayload>) => {
      if (!payload || isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { onPersistLocalState } = payload;

      let persistProps = state.persistProps;

      if (state.isCircleDrag) {
        if (!isEmpty(state.prevPos.id) && state.selectedObjectId !== state.prevPos.id) {
          const newCircleId = state.prevPos.id;

          persistProps = {
            ...state.persistProps,
            geoContentMap: omit(
              {
                ...persistProps.geoContentMap,
                [newCircleId]: omit(
                  {
                    ...state.persistProps.geoContentMap[state.selectedObjectId],
                    hover: undefined,
                  },
                  ['centerHighlight', 'hover']
                ) as CircleObject,
              },
              [state.selectedObjectId]
            ),
            circles: uniq([...state.persistProps.circles, newCircleId]).filter(
              (id) => id !== state.selectedObjectId
            ),
          };
        }
      } else {
        const snapPoint = state.snapPoints.length > 0 && state.snapPoints[0];
        // final point is already in the contentMap
        if (
          snapPoint &&
          snapPoint.snapObject === POINT &&
          state.prevPos.id !== snapPoint.id &&
          snapPoint.id in state.persistProps.geoContentMap
        ) {
          persistProps = mergeTwoPoints(state.persistProps, state.prevPos.id, snapPoint.id);

          if (state.selectedObjectId !== snapPoint.id) {
            // remove the still existing invisible starting point (as it needs to be in the DOM as
            // long as one wants to move it and receive touchevents for it)
            persistProps = removePoint(persistProps, state.selectedObjectId);
          }
        } else {
          if (!isNaN(state.prevPos.coords.x)) {
            const NEW_POINT = {
              id: getPointCoordsId(state.prevPos.coords),
              coords: state.prevPos.coords,
            };

            // we only replace+remove in case of a different point
            if (NEW_POINT.id !== state.selectedObjectId) {
              persistProps = replacePoint(state.persistProps, TEMP_POINT, NEW_POINT);

              persistProps = removePoint(persistProps, state.selectedObjectId);
            }
          }
        }
      }

      onPersistLocalState(persistProps);

      return {
        ...initialState,
        persistProps,
      };
    },
    [SNAP]: (state: GeoMoveState, { payload }: Action<SnapPoint>) => {
      if (!payload) {
        return state;
      }
      return {
        ...state,
        snapPoints: payload ? [payload] : [],
      };
    },
    [STOP_SNAP]: (state: GeoMoveState) => ({
      ...state,
      snapPoints: [],
    }),
    [CIRCLE_OVER]: (state: GeoMoveState, { payload }: Action<string>) => {
      if (!payload || !(payload in state.persistProps.geoContentMap)) {
        return state;
      }

      const persistProps = state.persistProps;

      return {
        ...state,
        persistProps: {
          ...persistProps,
          geoContentMap: {
            ...persistProps.geoContentMap,
            [payload]: {
              ...persistProps.geoContentMap[payload],
              hover: Hover.DEFAULT,
            },
          },
        },
      };
    },
    [CIRCLE_LEAVE]: (state: GeoMoveState, { payload }: Action<string>) => {
      if (!payload || !(payload in state.persistProps.geoContentMap)) {
        return state;
      }

      const persistProps = state.persistProps;

      return {
        ...state,
        persistProps: {
          ...persistProps,
          geoContentMap: {
            ...persistProps.geoContentMap,
            [payload]: omit(
              {
                ...persistProps.geoContentMap[payload],
              },
              'hover'
            ) as CircleObject,
          },
        },
      };
    },
  },
  initialState
);
