import {
  type ContentDict,
  createImporterContext,
  DependencyCollector,
  type ImporterRegistry,
} from '@bettermarks/gizmo-types';
import { type ContentTreeImporter } from './types';

export const importContentTree =
  (
    importers: ImporterRegistry,
    dependencies = new DependencyCollector(),
    contentId = ''
  ): ContentTreeImporter =>
  (xml, pathToContentDict): ContentDict => {
    const context = createImporterContext(importers, pathToContentDict, dependencies, contentId);
    context.importXML(xml, true);
    return context.contentDict;
  };
