import { Loader } from '../seriesplayer/components';
import { CRIStatus } from './types';
import CRIError from './CRIError';
import { ContentRoot } from '../../gizmo-utils/polymorphic-gizmo';
import { VERY_SMALL_SCREEN_WIDTH } from '../seriesplayer/constants';
import { gizmos } from './configuration';
import { isTouchDevice } from '../seriesplayer/services';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FractionFormEditorMode,
  numberFromStyles,
  ToolbarElements,
} from '@bettermarks/gizmo-types';
import { criRequested } from './actions';
import { dimensions } from '@seriesplayer/common-ui';
import { useHandleReset } from '../whiteboard/useHandleReset';
import { resetIncludeGizmo } from '../../gizmos/include-gizmo/resetIncludeGizmo';
import { jumpToPastAndEnrich } from '../store/combineSeriesplayerReducer';
import { ApplicationState } from '../../types';
import { postToWhiteboardParent, type WhiteboardMessage } from '../iframe-posts';
import { FEMDispatchContextProvider } from '../whiteboard/FEMDispatchContext';
import { getModesFromState } from '../seriesplayer/containers/Toolbar/Tools/ModeSelectorContainer';
import { gizmoAction } from '../../gizmo-utils/redux';
import { setMode } from '../../gizmo-utils/redux/gizmoActions';
import { DEFAULT_MEDIA_ROUTE } from '../../gizmo-utils/constants';
import { isEqual } from 'lodash';

type CRIContentProps = {
  id: string;
  locale: string;
  staticServerUrl: string;
  test: boolean;
};

export function CRIContent({ id, locale, staticServerUrl, test }: CRIContentProps) {
  const dispatch = useDispatch();

  const { criData, status } = useSelector((state: ApplicationState) => ({
    criData: state.classroomIntro?.data?.present,
    status: state.classroomIntro?.status,
  }));

  const availableWidth = Math.min(numberFromStyles(dimensions.layoutMaxWidth), window.innerWidth);

  const { modes, tools, contentId } = useSelector((state: ApplicationState) => ({
    tools: state.toolbar.tools,
    modes: state.classroomIntro?.data?.present ? getModesFromState(state) : [],
    loaded: state.classroomIntro?.status === CRIStatus.loaded,
    contentId:
      (state.classroomIntro?.data?.present && ApplicationState.toSelectedRefId(state).get(state)) ||
      undefined,
    state: state,
  }));

  React.useEffect(() => {
    if (status === CRIStatus.loaded) {
      postToWhiteboardParent({ type: 'contentLoaded' });
    }
  }, [status]);

  //useRef for "caching" the value of modes between renders. We need this to use array as
  // dependency in useEffect hook.
  const modesRef = React.useRef(modes);
  if (!isEqual(modesRef.current, modes)) {
    modesRef.current = modes;
  }

  React.useEffect(() => {
    if (!tools.includes(ToolbarElements.keyboard)) {
      postToWhiteboardParent({ type: 'availableTools', tools, modes });
    }
  }, [modesRef.current]);

  React.useEffect(() => {
    function handleReceiveMessage(e: MessageEvent<WhiteboardMessage>) {
      if (e.data.type === 'changeMode') {
        const mode = e.data.mode;
        contentId &&
          dispatch(
            gizmoAction(setMode(mode), contentId, {
              ...(mode !== FractionFormEditorMode.Add && { skipUndo: true }),
            })
          );
      }
    }

    window.addEventListener('message', handleReceiveMessage);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [contentId]);

  React.useEffect(() => {
    dispatch(
      criRequested({
        criId: id,
        locale,
        staticServerUrl,
        test,
      })
    );
  }, []);

  useHandleReset(() => {
    resetIncludeGizmo();
    dispatch(jumpToPastAndEnrich(0));
  });

  return (
    <Loader loaded={!!criData}>
      {status === CRIStatus.error ? (
        <CRIError />
      ) : (
        criData && (
          <FEMDispatchContextProvider
            value={{
              dispatchFEMOpen: (femId: string) => {
                postToWhiteboardParent({ type: 'showFEM', femId });
              },
            }}
          >
            <ContentRoot
              availableWidth={availableWidth || VERY_SMALL_SCREEN_WIDTH}
              contentDict={criData}
              gizmoRegistry={gizmos}
              dispatch={dispatch}
              isTouch={isTouchDevice()}
              staticMediaUrl={DEFAULT_MEDIA_ROUTE}
            />
          </FEMDispatchContextProvider>
        )
      )}
    </Loader>
  );
}
