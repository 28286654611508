import * as React from 'react';
import {
  Bar,
  Layout,
  VerticalMenu,
  VerticalMenuItem,
  VerticalMenuItemType,
  MenuItemElement,
  MenuItemElementLabel,
} from '@seriesplayer/common-ui';
import { DIALOG_HEADER_HEIGHT, DialogHeader, Icon } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { type ExerciseDisplayStatus, ExerciseValidity } from '../../../../../types';
import { getAvailableHeight } from '../../../services/runtime-manager';
import styles from './ExerciseOverview.scss';

export type ExerciseOverviewDispatchProps = {
  onCancel: () => void;
};

export type ExerciseOverviewStateProps = {
  exerciseStatuses: ExerciseDisplayStatus[];
};

export type ExerciseOverviewProps = ExerciseOverviewDispatchProps & ExerciseOverviewStateProps;

const correctIcon = <Icon iconKey="CheckMediumBold" style={{ fill: styles.ICON_COLOR_CORRECT }} />;
const wrongIcon = <Icon iconKey="TimesSmallBold" style={{ fill: styles.ICON_COLOR_WRONG }} />;

/**
 * ExerciseOverview Component
 * Used to render Exercise validity after hand-in a test
 */
export const ExerciseOverview: React.FC<ExerciseOverviewProps> = ({
  onCancel,
  exerciseStatuses,
}) => {
  const [t] = useTranslation();
  return (
    <Layout vertical>
      <DialogHeader
        title={t('seriesplayer:dialog.exerciseOverview.title')}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
        }}
      />
      <Bar />
      <div
        className={styles.content}
        style={{ maxHeight: getAvailableHeight() - DIALOG_HEADER_HEIGHT }}
      >
        <VerticalMenu limitHeight={false} separator>
          {exerciseStatuses.map((status, i) => (
            <VerticalMenuItem key={i} type={VerticalMenuItemType.neutral}>
              <MenuItemElement>
                {status === ExerciseValidity.correct ? correctIcon : wrongIcon}
              </MenuItemElement>
              <MenuItemElementLabel>
                {`${t('seriesplayer:dialog.exerciseOverview.exercise')} ${i + 1}`}
              </MenuItemElementLabel>
            </VerticalMenuItem>
          ))}
        </VerticalMenu>
      </div>
    </Layout>
  );
};

ExerciseOverview.displayName = 'ExerciseOverview';
