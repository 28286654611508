import { identity } from 'lodash/fp';
import { createAction } from 'redux-actions';

import { type ShouldEnrich, type ToolbarElements } from '@bettermarks/gizmo-types';
import { enrichMetaCreator } from '../../../../gizmo-utils/redux/gizmoActions';

export interface ToggleToolbarDrawerPayload {
  drawerName: Nullable<ToolbarElements>;
  preserveIfOpened?: boolean;
  toggledByUser?: boolean;
}

export const TOGGLE_TOOLBAR_DRAWER = 'TOGGLE_TOOLBAR_DRAWER' as const;
export const toggleToolbarDrawer = createAction<
  ToggleToolbarDrawerPayload,
  ShouldEnrich,
  ToggleToolbarDrawerPayload
>(TOGGLE_TOOLBAR_DRAWER, identity, enrichMetaCreator);
