import * as React from 'react';
import styles from './MediaPlayerControls.scss';

const PROGRESSED_COLOR = styles.PROGRESSED_COLOR;
const REST_COLOR = styles.REST_COLOR;

type MediaButtonProps = {
  Icons: [any, any];
  size: number;
  onClick: () => void;
};

type MediaButtonState = {
  isHovered: boolean;
};

type SeekbarProps = {
  width: number;
  currFrame: number;
  maxFrame: number;
  onSelectFrame: (frame: number) => void;
  onFrameSelected: (frame: number) => void;
};

type SeekbarState = {
  minPos: number;
};

/**
 * helper function to add some listeners to the document
 * @param listeners
 */
const addListeners = (listeners: [string, any, any][]) =>
  listeners.map((l) => document.addEventListener(l[0], l[1], l[2]));

/**
 * helper function to remove some listeners from the document
 * @param listeners
 */
const removeListeners = (listeners: [string, any, any][]) =>
  listeners.map((l) => document.removeEventListener(l[0], l[1], l[2]));

export class MediaButton extends React.Component<MediaButtonProps, MediaButtonState> {
  state = {
    isHovered: false,
  };

  render() {
    const { Icons, onClick, size } = this.props;
    const Icon = this.state.isHovered ? Icons[1] : Icons[0];
    return (
      <Icon
        className={styles.mediaButton}
        width={size}
        height={size}
        onClick={onClick}
        // only handle mouseover and mousleave for NON-touch devices!!!
        {...(!('ontouchstart' in document.documentElement)
          ? {
              onMouseOver: () => this.setState({ isHovered: true }),
              onMouseLeave: () => this.setState({ isHovered: false }),
            }
          : {})}
      />
    );
  }
}

export class Seekbar extends React.Component<SeekbarProps, SeekbarState> {
  progressbarRef = React.createRef<HTMLDivElement>(); // ref is used to get bounds
  state = {
    minPos: -1,
  };

  componentDidMount() {
    const progressBar = this.progressbarRef.current;
    this.setState((prevstate: Readonly<SeekbarState>) => ({
      minPos: progressBar ? progressBar.getBoundingClientRect().left : -1,
    }));
  }

  componentWillUnmount() {
    removeListeners(this.mouseListeners());
    removeListeners(this.touchListeners());
  }

  mouseListeners = (): [string, any, any][] => [
    ['mousemove', this.onMouseMove, {}],
    ['mouseup', this.onMouseUp, {}],
  ];
  touchListeners = (): [string, any, any][] => [
    ['touchmove', this.onTouchMove, { passive: false }],
    ['touchend', this.onTouchEnd, {}],
  ];

  // Mouse event implementations
  onMouseDown = (event: React.MouseEvent<any>) => {
    addListeners(this.mouseListeners());
  };
  onMouseMove = (event: React.MouseEvent<any>) => {
    event.preventDefault();
    this.dragTo(event.clientX);
  };
  onMouseUp = (event: React.MouseEvent<any>) => {
    removeListeners(this.mouseListeners());
    this.props.onFrameSelected(this.dragTo(event.clientX));
  };

  // Touch event implementations
  onTouchStart = (event: React.TouchEvent<any>) => {
    addListeners(this.touchListeners());
  };
  onTouchMove = (event: React.TouchEvent<any>) => {
    event.preventDefault();
    this.dragTo(event.touches[0].clientX);
  };
  onTouchEnd = (event: React.TouchEvent<any>) => {
    removeListeners(this.touchListeners());
    this.props.onFrameSelected(this.dragTo(event.changedTouches[0].clientX));
  };

  // click on the seekbar
  onClick = (event: React.MouseEvent<any>) =>
    this.props.onFrameSelected(this.dragTo(event.clientX));

  dragTo = (x: number) => {
    const { width, maxFrame } = this.props;
    const pos = Math.floor(((x - this.state.minPos) / width) * maxFrame);
    const nextFrame = pos < 0 ? 0 : pos >= maxFrame ? maxFrame : pos;
    this.props.onSelectFrame(nextFrame);
    return nextFrame;
  };

  render() {
    const progress = (this.props.currFrame / this.props.maxFrame) * 100;
    return (
      <div className={styles.seekbar}>
        <div
          role={'button'}
          ref={this.progressbarRef}
          className={styles.progressbar}
          style={{
            backgroundImage: `linear-gradient(${[
              'to right',
              PROGRESSED_COLOR,
              `${PROGRESSED_COLOR} ${progress}%`,
              `${REST_COLOR} ${progress}%`,
              REST_COLOR,
            ].join(',')})`,
          }}
          onClick={this.onClick}
        />
        <div
          role={'button'}
          className={styles.sliderHandle}
          style={{ left: `calc(${progress}% - 8px)` }}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onTouchStart}
        />
      </div>
    );
  }
}
