import * as React from 'react';
import styled from 'styled-components';
import { colors, dimensions } from '@seriesplayer/common-ui';
import type { StyledProps } from './types';

type CollapseButtonProps = StyledProps & {
  onClick?: () => void;
  variant?: CollapseButtonVariant;
};

type CollapseButtonVariant = 'topLevelSection' | 'childSection';

function CollapseButton({
  onClick,
  className,
  variant = 'topLevelSection',
  dataCy,
}: CollapseButtonProps) {
  return (
    <div className={className}>
      <div className="button" role="button" onClick={onClick} data-cy={dataCy}>
        {variant === 'topLevelSection' ? topLevelSectionHtml : childSectionHtml}
      </div>
    </div>
  );
}

export const BUTTON_RECT_CLASSNAME = 'rounded-button-rect';

const topLevelSectionHtml = (
  <div className={BUTTON_RECT_CLASSNAME}>
    <div className="toggle">
      <svg width="20" height="20">
        <line x1="0" y1="10" x2="20" y2="10" strokeWidth="2" />
        <line x1="0" y1="10" x2="20" y2="10" strokeWidth="2" />
      </svg>
    </div>
  </div>
);

const childSectionHtml = (
  <div className="collapsible-list-toggle-icon">
    <span />
    <span />
  </div>
);

interface StyledCollapseButtonProps {
  collapsed: boolean;
  padding?: string | number;
}

const centerChildren = `display: flex; align-items: center; justify-content: center;`;

export const StyledCollapseButton = styled(CollapseButton)<StyledCollapseButtonProps>`
  align-self: flex-start;

  .button {
    ${centerChildren};
    min-width: ${dimensions.minClickableSize};
    min-height: ${dimensions.minClickableSize};
    position: relative;
    text-align: center;
    box-sizing: border-box;
    margin: auto;
    cursor: pointer;
  }

  .collapsible-list-toggle-icon {
    position: absolute;
    top: 11px;
    left: 11px;
    width: 21px;
    height: 21px;
    padding: 0;
    border-radius: 16px;
    box-sizing: border-box;
    border: 1px solid #0077cc;
    background: transparent;
  }

  .collapsible-list-toggle-icon span {
    position: absolute;
    transition: 0s;
    background: #0077cc;
  }

  .collapsible-list-toggle-icon span:first-of-type {
    height: 11px;
    width: 1px;
    left: 9px;
    top: 4px;
    transform: ${(props) => (props.collapsed ? 'rotate(0)' : 'rotate(90deg)')};
  }

  .collapsible-list-toggle-icon span:last-of-type {
    height: 1px;
    width: 11px;
    top: 9px;
    left: 4px;
    transform: ${(props) => (props.collapsed ? 'rotate(0)' : 'rotate(180deg)')};
  }

  .${BUTTON_RECT_CLASSNAME} {
    width: ${dimensions.lineHeightM};
    height: ${dimensions.lineHeightM};
    background: transparent;
    border: transparent solid ${dimensions.borderWidthL};
    border-radius: ${dimensions.borderRadiusM};
  }

  .toggle {
    line {
      // transition: transform 0.6s; <-- uncomment this line to enable animation
      transform: rotate(0deg);
      transform-origin: center;
      stroke: ${(props) =>
        props.variant === 'topLevelSection' ? colors.cNeutralBorder : colors.cBlue800};
    }

    ${(props) =>
      props.collapsed &&
      `
      line:first-child {
        transform: rotate(90deg);
      }
      line:last-child {
        transform: rotate(180deg);
      }
    `}
  }
`;
