import {
  annotationInner,
  exportContent,
  type Exporter,
  semantics,
  type TimeDurationGizmoContent,
} from '@bettermarks/gizmo-types';

export const exportTimeDuration: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as TimeDurationGizmoContent;
  return semantics(
    `<mrow>
    <configuration>
      <direction>${content.direction}</direction>
    </configuration>
    ${exportContent(content.hourRef.$refid, context)}
    ${exportContent(content.minuteRef.$refid, context)}
    </mrow>`,
    annotationInner(content.$, content)
  );
};
