import { isEmpty } from 'lodash';
import * as React from 'react';
import { Thermometer, TICK_SPACE } from './components/Thermometer';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { Slider } from '../../../components';
import { ContentColor, type ThermometerContent } from '@bettermarks/gizmo-types';
import styles from './components/thermometer.scss';
import { colors } from '@seriesplayer/common-ui';

export type ThermometerProps = ThermometerContent & ContextState & ThermometerCallbacks;
export interface ThermometerCallbacks {
  readonly onChange: (value: number) => void;
}
/**
 * Renders Thermometer, eventually uses the Slider component to move the Thermometer
 * The two different actions are meant to store in the global AppState only values that occurs
 * at the end of the dragging action
 */
export class ThermometerGizmo extends React.Component<ThermometerProps> {
  state = {
    value: this.props.value,
  };

  // overwrite local state when value prop changes
  componentDidUpdate(prevProps: ThermometerProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  onMove = (value: number) => {
    this.setState({ value });
  };

  onChange = () => {
    this.props.onChange(this.state.value);
  };

  render() {
    const {
      $interactionType,
      configuration: { min, max, label },
      severity,
      disabled,
    } = this.props;

    const interactive = !isEmpty($interactionType) && !disabled;
    return (
      <div>
        <Thermometer
          color={severity}
          label={label}
          labelColor={severity}
          value={this.state.value}
          min={min}
          max={max}
        />
        {interactive && (
          <div className={styles.sliderWrap} style={{ height: TICK_SPACE }} tabIndex={0}>
            <Slider
              onChange={this.onMove}
              onFinalValue={this.onChange}
              min={min}
              interactive={interactive}
              max={max}
              step={1}
              value={this.state.value}
              color={ContentColor.BM_TRANSPARENT}
              handleColor={colors.cBlue700}
              orientation={'vertical'}
              handleShape={'raindrop'}
              handleSite={'right'}
            />
          </div>
        )}
      </div>
    );
  }
}
