import { type Action, createAction, handleActions } from 'redux-actions';
import { isNil, omit, uniq } from 'lodash';
import {
  DEFAULT_PREVINTERVAL,
  DEFAULT_PREVLINE,
  EPS,
  GEO_DEFAULT_PERSIST_PROPS,
  screenToWorld,
} from '@bettermarks/importers';
import {
  type Coords,
  type GeoContentPersistProps,
  type GeoIntervalState,
  type IntervalConfiguration,
  IntervalType,
  type SnapPoint,
  STRAIGHTLINE,
} from '@bettermarks/gizmo-types';
import { intervalType, isLimitOpen, prevInterval } from './helpers';
import { createInterval, getIntervalId, getLineCoords } from '../../helpers';

export interface AddPayload {
  onPersistLocalState: (props: GeoContentPersistProps) => void;
  configuration: IntervalConfiguration;
}

export interface DragPayload {
  configuration: IntervalConfiguration;
  matrix: number[];
  mouseP: Coords;
  snapPos: number;
}

export interface LimitPayload {
  limitIndex: number | undefined;
  index: number | undefined;
  confirmed: boolean;
}

export type GeoIntervalPayload =
  | LimitPayload
  | DragPayload
  | AddPayload
  | SnapPoint
  | number
  | void;

const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPoint>(SNAP);
const ADD_INTERVAL: 'ADD_INTERVAL' = 'ADD_INTERVAL';
export const addIntervalAction = createAction<AddPayload>(ADD_INTERVAL);
const START_DRAG: 'START_DRAG' = 'START_DRAG';
export const startDragAction = createAction<number>(START_DRAG);
const DRAG: 'DRAG' = 'DRAG';
export const dragAction = createAction<DragPayload>(DRAG);
const STOP_DRAG: 'STOP_DRAG' = 'STOP_DRAG';
export const stopDragAction = createAction(STOP_DRAG);
const LIMITS: 'LIMITS' = 'LIMITS';
export const limitsAction = createAction<LimitPayload>(LIMITS);

export const initialState: GeoIntervalState = {
  snapPoints: [],
  start: NaN,
  end: NaN,
  limitPickerOpen: [undefined, undefined],
  limitOpen: [undefined, undefined],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  prevInterval: DEFAULT_PREVINTERVAL,
  dragging: false,
  helperLine: DEFAULT_PREVLINE,
};

const INTERVAL = 'INTERVAL';

export const geoIntervalReducer = handleActions<GeoIntervalState, GeoIntervalPayload>(
  {
    [SNAP]: (state: GeoIntervalState, { payload }: Action<SnapPoint>) =>
      isNil(payload) ? state : { ...state, snapPoints: [payload] },

    [START_DRAG]: (state: GeoIntervalState, { payload }: Action<number>) =>
      isNil(payload) ? state : { ...state, start: payload, dragging: true },

    [DRAG]: (state: GeoIntervalState, { payload }: Action<DragPayload>) => {
      if (!payload) {
        return state;
      }

      const { configuration, matrix, mouseP, snapPos } = payload;
      const { helperLine } = configuration;
      const [p1, p2] = getLineCoords(
        screenToWorld(matrix)({ ...mouseP, y: mouseP.y + 1 }),
        screenToWorld(matrix)({ ...mouseP, y: mouseP.y - 1 }),
        STRAIGHTLINE
      );

      return {
        ...state,
        end: payload.snapPos,
        prevInterval: prevInterval(state.start, snapPos, IntervalType.none),
        persistProps: {
          ...state.persistProps,
          ...(configuration.inputRestriction
            ? {
                geoContentMap: omit(state.persistProps.geoContentMap, INTERVAL),
                intervals: state.persistProps.intervals.filter((id) => id !== INTERVAL),
              }
            : null),
        },
        ...(helperLine && {
          helperLine: {
            p1,
            p2,
            ...helperLine,
          },
        }),
      };
    },

    [LIMITS]: (state: GeoIntervalState, { payload }: Action<LimitPayload>) => {
      if (!payload) {
        return state;
      }

      // 0-length or 'NaN' interval. Try again!
      if (Math.abs(state.start - state.end) < EPS || isNaN(state.end) || isNaN(state.start)) {
        return {
          ...initialState,
          persistProps: state.persistProps,
        };
      }

      const limitOpen = [0, 1].map((i) =>
        payload.limitIndex === i
          ? isLimitOpen(payload.index)
          : isNil(state.limitOpen[i])
          ? false
          : state.limitOpen[i]
      ) as [boolean, boolean];

      // a picker is open, if and only if
      // 1a. - we are in initital state (p1 AND p2 are undefined!) OR
      // 1b. - the current state (p1 resp. p2) is OPEN
      // 2.  - it has not yet been confirmed
      const limitPickerOpen = [0, 1].map((i) =>
        payload.limitIndex === i
          ? !payload.confirmed
          : state.limitPickerOpen.reduce((a, v) => a && isNil(v), true) || state.limitPickerOpen[i]
      ) as [boolean, boolean];

      return {
        ...state,
        limitOpen,
        limitPickerOpen,
        prevInterval: prevInterval(
          state.start,
          state.end,
          intervalType(limitOpen[0], limitOpen[1])
        ),
        dragging: false,
      };
    },

    [STOP_DRAG]: (state: GeoIntervalState) => ({
      ...initialState,
      persistProps: state.persistProps,
    }),

    [ADD_INTERVAL]: (state: GeoIntervalState, { payload }: Action<AddPayload>) => {
      if (!payload) {
        return state;
      }

      if (isNaN(state.end)) {
        return {
          ...state,
          dragging: false,
        };
      }

      const { configuration, onPersistLocalState } = payload;

      const [min, max] = [state.start, state.end].sort((a, b) => a - b);

      const intervalIsEmpty = min === max;
      const intervalId = configuration.inputRestriction ? INTERVAL : getIntervalId(min, max);

      const geoContentMap = intervalIsEmpty
        ? { ...state.persistProps.geoContentMap }
        : {
            ...state.persistProps.geoContentMap,
            [intervalId]: createInterval(
              min,
              max,
              intervalType(state.limitOpen[0], state.limitOpen[1]),
              configuration.decoration
            ),
          };

      const intervals = intervalIsEmpty
        ? uniq([...state.persistProps.intervals])
        : uniq([...state.persistProps.intervals, intervalId]);

      const newPersistProps = {
        ...state.persistProps,
        geoContentMap,
        intervals,
      };

      onPersistLocalState(newPersistProps);

      return {
        ...initialState,
        persistProps: newPersistProps,
      };
    },
  },
  initialState
);
