import * as React from 'react';
import classNames from 'classnames';
import { type GeoEditorMode, type PointDecoration } from '@bettermarks/gizmo-types';
import { getSnapColor } from './decorations';
import { getStrokeWidth, POINT_RADIUS } from '@bettermarks/importers';
import styles from './SnapPoint.scss';

export type CircleSnapShapeProps = {
  mode: GeoEditorMode;
  style: string;
  x: number;
  y: number;
  decoration: PointDecoration;
};

export const CircleSnapShape: React.FC<CircleSnapShapeProps> = ({
  mode,
  style,
  x,
  y,
  decoration,
}) => {
  const strokeWidth = getStrokeWidth(decoration);
  const color = getSnapColor(mode);

  return (
    <>
      <circle
        cx={x}
        cy={y}
        r={POINT_RADIUS * 1.4 + strokeWidth}
        className={classNames(styles.baseSnappoint, style)}
        stroke={color}
        strokeWidth={1}
      />
      <circle
        cx={x}
        cy={y}
        r={POINT_RADIUS}
        className={styles.baseSnappoint}
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </>
  );
};

CircleSnapShape.displayName = 'CircleSnapShape';
