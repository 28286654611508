import {
  type ContextState,
  renderGizmos,
  useContentTranslation,
} from '../../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';
import { type Duration } from '@bettermarks/gizmo-types';
import styles from './DurationLabel.scss';

type DurationLabelProps = ContextState & {
  duration: Duration;
};

/**
 * `DurationLabel` component
 */
export const DurationLabel: React.FC<DurationLabelProps> = ({ duration, availableWidth }) => {
  let arrowLabel;
  const isDayDuration = duration.contentRefs.length === 1;
  const t = useContentTranslation();
  if (isDayDuration) {
    const [labelRef] = duration.contentRefs;
    arrowLabel = (
      <>
        <div className={styles.day}>{renderGizmos([labelRef], availableWidth)}</div>
        &nbsp;
        <span>{t('timeEditor.days')}</span>
      </>
    );
  } else {
    const [hourRef, minuteRef] = duration.contentRefs;
    arrowLabel = (
      <>
        {renderGizmos([hourRef], availableWidth)}&nbsp;
        <span>{t('timeDuration.hours')}</span>&nbsp;
        {renderGizmos([minuteRef], availableWidth)}&nbsp;
        <span>{t('timeDuration.minutes')}</span>
      </>
    );
  }
  return <div className={styles.durationLabel}>{arrowLabel}</div>;
};

DurationLabel.displayName = 'DurationLabel';
