import * as React from 'react';
import { type DropResult } from 'react-beautiful-dnd';
import Measure, { type ContentRect } from 'react-measure';
import { type ContextState, renderGizmos } from '../../gizmo-utils/polymorphic-gizmo';
import { type SortingContent, SortingDirection } from '@bettermarks/gizmo-types';
import {
  PADDING,
  RECTANGLE_BORDER_WIDTH,
  Sorting,
  SORTING_BORDER_WIDTH,
  SORTING_PADDING,
} from '../../components/DragAndDrop';

import styles from './SortingGizmo.scss';

export type SortingGizmoCallbacks = {
  onReorder?: (sourceIndex: number, destinationIndex: number) => void;
};
export type SortingGizmoProps = SortingContent & SortingGizmoCallbacks & ContextState;

type SortingGizmoState = {
  width: number;
  direction: SortingDirection;
};

export class SortingGizmo extends React.Component<SortingGizmoProps, SortingGizmoState> {
  state: SortingGizmoState = {
    width: 0,
    direction: this.props.direction,
  };

  maxIndex = this.props.items.length - 1;

  invertedIndex = (index: number) => this.maxIndex - index;

  reverseItems = () => this.state.direction === SortingDirection.vertical;

  static getDerivedStateFromProps(currProps: SortingGizmoProps, state: SortingGizmoState) {
    return {
      direction:
        state.width <= currProps.availableWidth ? currProps.direction : SortingDirection.vertical,
    };
  }

  onSize = ({ bounds }: ContentRect) =>
    this.state.direction !== SortingDirection.vertical &&
    bounds &&
    this.setState({ width: bounds.width });

  onDragEnd = (result: DropResult) => {
    // dropped outside the list or not moved
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    this.props.onReorder &&
      this.props.onReorder(
        this.reverseItems() ? this.invertedIndex(result.source.index) : result.source.index,
        this.reverseItems()
          ? this.invertedIndex(result.destination.index)
          : result.destination.index
      );
  };

  render() {
    const { items, availableWidth, disabled, ...common } = this.props;
    const { direction } = this.state;
    const severities = this.reverseItems()
      ? items.map((it) => it.severity).reverse()
      : items.map((it) => it.severity);
    return (
      <Measure bounds onResize={this.onSize}>
        {({ measureRef }) => (
          <div ref={measureRef} className={styles.inline}>
            <Sorting
              {...common}
              direction={direction}
              severities={severities}
              onDragEnd={!disabled ? this.onDragEnd : undefined}
            >
              {renderGizmos(
                items,
                availableWidth -
                  // subtract item styling
                  PADDING -
                  RECTANGLE_BORDER_WIDTH -
                  // subtract sorting container styling
                  SORTING_BORDER_WIDTH -
                  SORTING_PADDING
              ).reduce((acc, it) => (this.reverseItems() ? [it, ...acc] : [...acc, it]), [])}
            </Sorting>
          </div>
        )}
      </Measure>
    );
  }
}
