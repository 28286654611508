const problemReport = (baseURL: string) => `${baseURL}/v3.0/reporting/problem`;

const whiteboardProblemReport = (baseURL: string) => `${baseURL}/v1.0/reporting/whiteboard/problem`;

const ROUTES = {
  problemReport,
  whiteboardProblemReport,
};

export const ReportProblemManagerRoutes: Readonly<typeof ROUTES> = ROUTES;
