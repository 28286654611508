import * as React from 'react';
import classNames from 'classnames';
import Measure, { type ContentRect } from 'react-measure';
import { StyledHLayout, FlexAlign } from '@seriesplayer/common-ui';
import { type BaseDropTargetProps } from './DropTarget';
import { DROP_TARGET_PADDING } from './constants';
import styles from './DragAndDrop.scss';

export enum VerticalAlignment {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

export type SetDropTargetProps = BaseDropTargetProps & {
  minHeight?: number;
  minWidth?: number;
  width: number;
  verticalItemAlign: VerticalAlignment;
  onResize?: (width: number, height: number) => void;
};

function itemAlignment(align: VerticalAlignment): FlexAlign {
  switch (align) {
    case VerticalAlignment.top:
      return FlexAlign.flexStart;
    case VerticalAlignment.bottom:
      return FlexAlign.flexEnd;
    default:
      return FlexAlign.center;
  }
}

const onResize_ = (onResize?: (w: number, h: number) => void) => (contentRect: ContentRect) =>
  contentRect.client && onResize && onResize(contentRect.client.width, contentRect.client.height);

const SetDropTargetElement: React.FC<SetDropTargetProps & { ref?: React.Ref<any> }> = ({
  shape,
  itemType = 1,
  error,
  remark,
  dragOver,
  ref,
  width,
  minHeight,
  minWidth,
  fontSize,
  verticalItemAlign,
  children,
}) => (
  <div
    className={classNames(
      styles.dropTarget,
      styles.setDropTarget,
      shape && styles[shape],
      styles[`itemType${itemType}`],
      {
        [styles.error]: error,
        [styles.remark]: remark,
        [styles.dragOver]: dragOver,
      }
    )}
    ref={ref}
    style={{
      width,
      minHeight,
      minWidth,
      fontSize,
    }}
  >
    <StyledHLayout gap={DROP_TARGET_PADDING} align={itemAlignment(verticalItemAlign)}>
      {children}
    </StyledHLayout>
  </div>
);

/**
 * SetDropTarget: Component that acts as a target for multiple drag items
 *
 * ### Properties
 | Name              | Type                            | Default    | Description                 |
 |---                | ---                             |---         | ---                         |
 | error             | boolean                         | false      | severity from validation    |
 | remark            | boolean                         | false      | severity from validation    |
 | fontSize          | number                          | inherit    | align empty relative        |
 | itemType          | 0 to 9                          | 1          | color group                 |
 | minHeight         | number                          | 44         |                             |
 | shape             | 'border', 'retangle', undefined | undefined  | shape and style             |
 | percentWidth      | number                          | Required   | width of the component      |
 | verticalItemAlign | 'top', 'middle', 'bottom'       | 'middle'   | alignment of the items      |
 */
export const SetDropTarget: React.FC<SetDropTargetProps> = ({
  children,
  onResize,
  ...setDropTargetElementProps
}) =>
  onResize ? (
    <Measure client onResize={onResize_(onResize)}>
      {({ measureRef }) => (
        <SetDropTargetElement ref={measureRef} {...setDropTargetElementProps}>
          {children}
        </SetDropTargetElement>
      )}
    </Measure>
  ) : (
    <SetDropTargetElement {...setDropTargetElementProps}>{children}</SetDropTargetElement>
  );

SetDropTarget.displayName = 'SetDropTarget';
