import log from 'loglevel';
import { rulers } from '../../../../../gizmo-utils/configuration/rulers';
import { stylers } from '../../../../../gizmo-utils/configuration/stylers';
import { Lens } from '@bettermarks/gizmo-types';
import { enrichContentDict } from '../../../../../gizmo-utils/measure';
import { type Action, handleAction } from 'redux-actions';
import { reduceReducers } from '../../../../../gizmo-utils/reduceReducers';

import { emptyExercise } from '../../../../store/constants';
import { AppExercise, ExerciseStatus, type AppStep } from '../../../../../types';
import {
  HIDE_STEP_VALIDATION_LOADER,
  UPDATE_FILLED_STATUS,
  VALIDATION_FAILED,
  VALIDATION_PROGRESS,
  CLOSE_BETTY_SECTION,
  type ValidationProgressPayload,
} from '../actions';
import { isExerciseFilled } from '../../Series/updateFlowHelper';

const handleHideStepValidationLoader = handleAction(
  HIDE_STEP_VALIDATION_LOADER,
  (exercise: AppExercise) => {
    return {
      ...exercise,
      stepValidationLoaded: true,
    };
  },
  emptyExercise
);

const handleUpdateFilledStatus = handleAction(
  UPDATE_FILLED_STATUS,
  // TODO: move to seriesReducer and check mode is test
  (exercise: AppExercise) => ({
    ...exercise,
    status: isExerciseFilled(exercise.steps) ? ExerciseStatus.withInput : ExerciseStatus.started,
  }),
  emptyExercise
);

const handleValidationProgress = handleAction(
  VALIDATION_PROGRESS,
  (
    exercise: AppExercise,
    { payload: { handIn, stepValidationLoaded = false } }: Action<ValidationProgressPayload>
  ) => ({
    ...Lens.update(
      AppExercise.toCurrentQuestion,
      (contentDict) => contentDict && enrichContentDict(contentDict, stylers, rulers, true),
      exercise
    ),
    handIn,
    stepValidationLoaded,
  }),
  emptyExercise
);

const handleValidationFailed = handleAction(
  VALIDATION_FAILED,
  // TODO: proper handling of errors
  (exercise: AppExercise, { payload }: Action<string>) => {
    log.error(payload);
    return exercise;
  },
  emptyExercise
);

const handleCloseBettySection = handleAction(
  CLOSE_BETTY_SECTION,
  (exercise: AppExercise) =>
    Lens.update(
      AppExercise.toCurrentStep,
      (step: AppStep) => ({
        ...step,
        showBetty: false,
      }),
      exercise
    ),
  emptyExercise
);

export const exerciseReducer = reduceReducers(
  handleCloseBettySection,
  handleHideStepValidationLoader,
  handleUpdateFilledStatus,
  handleValidationProgress,
  handleValidationFailed
);
