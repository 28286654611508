import { type ContentReference, type TranslateCoords } from '@bettermarks/gizmo-types';
import { type FreeDragItemTranslate } from '@bettermarks/gizmo-types';
import { createAction } from 'redux-actions';

export interface DnDPayload {
  source: string;
  target: string;
  freeDragItemTranslate: FreeDragItemTranslate;
  index?: number;
}

export interface StartDragPayload {
  dragItem: ContentReference;
}

export interface FreeDnDPayload {
  freeDragItem: ContentReference;
  newPos: TranslateCoords;
}

export interface DnDRemovePayload {
  source: string;
  freeDragItemTranslate?: FreeDragItemTranslate;
  index?: number;
}

export interface SizePayload {
  width: number;
  height: number;
}

export const DROP = 'DROP';
export const dropAction = createAction<DnDPayload>(DROP);

// when you can drop an item withut a target drop zone
export const FREE_DROP = 'FREE_DROP';
export const freeDropAction = createAction<FreeDnDPayload>(FREE_DROP);

export const REMOVE_DRAG_ITEM = 'REMOVE_DRAG_ITEM';
export const removeItem = createAction<DnDRemovePayload>(REMOVE_DRAG_ITEM);

export const RESET_ERROR_STATE = 'RESET_ERROR_STATE';
export const resetErrorState = createAction<number>(RESET_ERROR_STATE);

export const SET_DROP_TARGET_SIZE = 'SET_DROP_TARGET_SIZE';
export const setDropTargetSize = createAction<SizePayload>(SET_DROP_TARGET_SIZE);
