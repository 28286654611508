import * as React from 'react';
import classNames from 'classnames';

import {
  ContextNotification,
  ContextNotificationKind,
  defaultTheme,
} from '@seriesplayer/common-ui';
import { ShadowScrollbars } from '../../../gizmos/components';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { EM } from '../../../types';

import styles from './EM.scss';
import { numberFromStyles } from '@bettermarks/gizmo-types';

const RULE_CONTENT_WIDTH_DIFFERENCE =
  2 * numberFromStyles(defaultTheme.dimensions.spaceXs) +
  2 * numberFromStyles(defaultTheme.dimensions.borderWidthS);

export type EMContentElementProps = Readonly<
  (EM.ElementContent | EM.ElementComment | EM.ElementRule) & {
    availableWidth: number;
    first: boolean;
    last: boolean;
  }
>;

export const EMContentElement: React.FC<EMContentElementProps> = ({
  content: { $refid },
  type,
  availableWidth,
  first,
  last,
}) => {
  let content;

  switch (type) {
    case EM.ElementType.Content:
    case EM.ElementType.Comment:
      content = <PolymorphicGizmo refid={$refid} availableWidth={availableWidth} />;
      break;

    default:
      // EM.ElementType.Rule:
      const width = availableWidth - RULE_CONTENT_WIDTH_DIFFERENCE;
      content = (
        <ContextNotification kind={ContextNotificationKind.attention} stretch>
          <div className={styles.ruleContent} style={{ width }}>
            <PolymorphicGizmo refid={$refid} availableWidth={width} />
          </div>
        </ContextNotification>
      );
  }

  // `type` here is 'Content' | 'Comment' | 'Rule'
  const className = classNames(styles[`emContentElement${type}`], {
    [styles.emContentElementCommentFirst]: type === EM.ElementType.Comment && first,
    [styles.emContentElementCommentLast]: type === EM.ElementType.Comment && last,
  });

  return (
    <div className={className}>
      <ShadowScrollbars availableWidth={availableWidth}>{content}</ShadowScrollbars>
    </div>
  );
};

EMContentElement.displayName = 'EMContentElement';
