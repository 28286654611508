import {
  type ALayoutChild,
  type ALayoutContent,
  CONFIGURATION,
  type Content,
  DECORATION,
  DEFAULT_A_LAYOUT_ALIGNMENT,
  DEFAULT_A_LAYOUT_CONTENT,
  DEFAULT_H_LAYOUT_CONTENT,
  DEFAULT_V_LAYOUT_CONTENT,
  type FElement,
  HAlign,
  type HLayoutContent,
  type Importer,
  importUnknown,
  isHAlign,
  isVAlign,
  Layout,
  MATH,
  parseCommonAttribs,
  RS,
  SEMANTICS,
  toBoolean,
  toInt,
  VAlign,
  type VLayoutContent,
  $MROW,
  isFormulaContent,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import { importFunctionPlotter } from '../../function-plotter/importer';
import { importChildren } from './importChildren';

// default width and height of children of the absolute layout gizmo
export const DEFAULT_GIZMO_WIDTH = 44;
export const DEFAULT_GIZMO_HEIGHT = 44;

const isFunctionPlotter = (xml: FElement): boolean => {
  if (xml.localName === SEMANTICS && parseCommonAttribs(xml).$renderStyle === RS.SLIDER) {
    return true;
  }
  return xml.children.reduce((acc, cur) => isFunctionPlotter(cur) || acc, false);
};

export const importVLayout: Importer<VLayoutContent> = (
  { layout, ...preContent },
  xml,
  context
) => {
  context.childrenAreRoot = true;
  const contentRoot = preContent.$ === MATH ? xml : xml.findChildTag($MROW);
  const content = importChildren(contentRoot, context);
  const { object } = parseDecoString<'horizontalAlign' | 'fullWidth'>(
    contentRoot.attribute(DECORATION, '')
  );
  const hAlignment = isHAlign(object.horizontalAlign) && object.horizontalAlign;
  const fullWidth = toBoolean(object.fullWidth);

  return {
    ...DEFAULT_V_LAYOUT_CONTENT,
    ...preContent,
    content,
    // returning max of children's "unscaled width" for the case that
    // v-layout is nested inside a-layout (see bug http://trac.bm.loc/ticket/43448)
    unscaledWidth: content
      .map((c) => context.content.get(c.$refid))
      .reduce(
        (acc, c?: Content) => (c && c.unscaledWidth ? Math.max(acc, c.unscaledWidth) : acc),
        DEFAULT_GIZMO_WIDTH
      ),
    // returning sum of children's "unscaled height" for the case that
    // v-layout is nested inside a-layout
    // (see bug https://bettermarks.atlassian.net/browse/BM-50169)
    unscaledHeight: content
      .map((c) => context.content.get(c.$refid))
      .reduce(
        (acc, c?: Content) =>
          c && c.unscaledHeight ? acc + c.unscaledHeight : acc + DEFAULT_GIZMO_HEIGHT,
        0
      ),
    ...(hAlignment && { hAlignment }),
    ...(fullWidth && { fullWidth }),
  };
};

export const importHLayout: Importer<HLayoutContent> = (
  { layout, ...preContent },
  xml,
  context
) => {
  context.childrenAreRoot = true;
  const contentRoot = preContent.$ === MATH ? xml : xml.findChildTag($MROW);
  const content = importChildren(contentRoot, context);
  const { object } = parseDecoString<'verticalAlign'>(contentRoot.attribute(DECORATION, ''));
  const vAlignment = isVAlign(object.verticalAlign) && object.verticalAlign;
  return {
    ...DEFAULT_H_LAYOUT_CONTENT,
    ...preContent,
    content,
    ...(vAlignment && { vAlignment }),
  };
};

export const absoluteLayoutBoundsWidth = (children: ALayoutChild[]): number =>
  Math.max(
    0,
    ...children.map(({ hAlign, unscaledWidth, pos, isFormula }) => {
      if (isFormula) {
        return pos.x;
      }
      switch (hAlign) {
        case HAlign.right:
          return pos.x + unscaledWidth;
        case HAlign.center:
          return pos.x + unscaledWidth / 2;
        case HAlign.left:
          return pos.x;
        // no default
      }
    })
  );

export const absoluteLayoutBoundsHeight = (children: ALayoutChild[]): number =>
  Math.max(
    0,
    ...children.map(({ vAlign, unscaledHeight, pos, isFormula }) => {
      if (isFormula) {
        return pos.y;
      }
      switch (vAlign) {
        case VAlign.top:
          return pos.y;
        case VAlign.middle:
          return pos.y + unscaledHeight / 2;
        case VAlign.bottom:
          return pos.y + unscaledHeight;
        // no default
      }
    })
  );

export const importAbsoluteLayout: Importer<ALayoutContent> = (
  { layout, $renderStyle, ...preContent },
  xml,
  context
) => {
  context.convertFontSize = false;
  context.inHScrollContainer = !!preContent.noScale;
  const contentRoot = preContent.$ === MATH ? xml : xml.findChildTag($MROW);
  const children = importChildren(contentRoot, context);
  const configuration = contentRoot.findChildTag(CONFIGURATION);
  const debug = toBoolean(configuration.findChildTag('debug').text);
  const width = toInt(configuration.findChildTag('width').text);
  const height = toInt(configuration.findChildTag('height').text);

  const content: ALayoutChild[] = children.map((c) => {
    const childContent = context.content.get(c.$refid);
    // TODO: the value for non-scalable unscaledWidth might depend on the gizmo
    const {
      hAlign,
      vAlign,
      posX = 0,
      posY = 0,
      scalable = false,
      unscaledWidth = DEFAULT_GIZMO_WIDTH,
      unscaledHeight = DEFAULT_GIZMO_HEIGHT,
    } = { ...DEFAULT_A_LAYOUT_ALIGNMENT, ...childContent };
    return {
      ...c,
      hAlign,
      vAlign,
      pos: { x: posX, y: posY },
      scalable,
      unscaledWidth,
      unscaledHeight,
      isFormula: isFormulaContent(childContent),
    };
  });

  const maxChildWidth = absoluteLayoutBoundsWidth(content);
  const maxChildHeight = absoluteLayoutBoundsHeight(content);

  return {
    ...DEFAULT_A_LAYOUT_CONTENT,
    ...preContent,
    content,
    unscaledWidth: !isNaN(width) ? Math.max(maxChildWidth, width) : maxChildWidth,
    unscaledHeight: !isNaN(height) ? Math.max(maxChildHeight, height) : maxChildHeight,
    debug,
  };
};

export const importLayoutContainer: Importer = (preContent, xml, context) => {
  if (isFunctionPlotter(xml)) {
    return importFunctionPlotter(preContent, xml, context);
  }

  switch (preContent.layout) {
    case Layout.Horizontal:
      return importHLayout(preContent, xml, context);
    case Layout.Vertical:
      return importVLayout(preContent, xml, context);
    case Layout.Absolute:
      return importAbsoluteLayout(preContent, xml, context);
    default:
      return {
        ...importUnknown(preContent, xml, context),
        ...preContent,
      };
  }
};
