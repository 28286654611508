export const HTML5_ENTITIES: { [key: string]: string } = {
  Aacute: 'Á',
  aacute: 'á',
  Abreve: 'Ă',
  abreve: 'ă',
  ac: '∾',
  acd: '∿',
  acE: '∾̳',
  Acirc: 'Â',
  acirc: 'â',
  acute: '´',
  Acy: 'А',
  acy: 'а',
  AElig: 'Æ',
  aelig: 'æ',
  af: '⁡',
  Afr: '프',
  afr: '픞',
  Agrave: 'À',
  agrave: 'à',
  alefsym: 'ℵ',
  aleph: 'ℵ',
  Alpha: 'Α',
  alpha: 'α',
  Amacr: 'Ā',
  amacr: 'ā',
  amalg: '⨿',
  andand: '⩕',
  And: '⩓',
  and: '∧',
  andd: '⩜',
  andslope: '⩘',
  andv: '⩚',
  ang: '∠',
  ange: '⦤',
  angle: '∠',
  angmsdaa: '⦨',
  angmsdab: '⦩',
  angmsdac: '⦪',
  angmsdad: '⦫',
  angmsdae: '⦬',
  angmsdaf: '⦭',
  angmsdag: '⦮',
  angmsdah: '⦯',
  angmsd: '∡',
  angrt: '∟',
  angrtvb: '⊾',
  angrtvbd: '⦝',
  angsph: '∢',
  angst: 'Å',
  angzarr: '⍼',
  Aogon: 'Ą',
  aogon: 'ą',
  Aopf: '픸',
  aopf: '핒',
  apacir: '⩯',
  ap: '≈',
  apE: '⩰',
  ape: '≊',
  apid: '≋',
  ApplyFunction: '⁡',
  approx: '≈',
  approxeq: '≊',
  Aring: 'Å',
  aring: 'å',
  Ascr: '풜',
  ascr: '풶',
  Assign: '≔',
  ast: '*',
  asymp: '≈',
  asympeq: '≍',
  Atilde: 'Ã',
  atilde: 'ã',
  Auml: 'Ä',
  auml: 'ä',
  awconint: '∳',
  awint: '⨑',
  backcong: '≌',
  backepsilon: '϶',
  backprime: '‵',
  backsim: '∽',
  backsimeq: '⋍',
  Backslash: '∖',
  Barv: '⫧',
  barvee: '⊽',
  barwed: '⌅',
  Barwed: '⌆',
  barwedge: '⌅',
  bbrk: '⎵',
  bbrktbrk: '⎶',
  bcong: '≌',
  Bcy: 'Б',
  bcy: 'б',
  bdquo: '„',
  becaus: '∵',
  because: '∵',
  Because: '∵',
  bemptyv: '⦰',
  bepsi: '϶',
  bernou: 'ℬ',
  Bernoullis: 'ℬ',
  Beta: 'Β',
  beta: 'β',
  beth: 'ℶ',
  between: '≬',
  Bfr: '픅',
  bfr: '픟',
  bigcap: '⋂',
  bigcirc: '◯',
  bigcup: '⋃',
  bigodot: '⨀',
  bigoplus: '⨁',
  bigotimes: '⨂',
  bigsqcup: '⨆',
  bigstar: '★',
  bigtriangledown: '▽',
  bigtriangleup: '△',
  biguplus: '⨄',
  bigvee: '⋁',
  bigwedge: '⋀',
  bkarow: '⤍',
  blacklozenge: '⧫',
  blacksquare: '▪',
  blacktriangle: '▴',
  blacktriangledown: '▾',
  blacktriangleleft: '◂',
  blacktriangleright: '▸',
  blank: '␣',
  blk12: '▒',
  blk14: '░',
  blk34: '▓',
  block: '█',
  bne: '=⃥',
  bnequiv: '≡⃥',
  bNot: '⫭',
  bnot: '⌐',
  Bopf: '픹',
  bopf: '핓',
  bot: '⊥',
  bottom: '⊥',
  bowtie: '⋈',
  boxbox: '⧉',
  boxdl: '┐',
  boxdL: '╕',
  boxDl: '╖',
  boxDL: '╗',
  boxdr: '┌',
  boxdR: '╒',
  boxDr: '╓',
  boxDR: '╔',
  boxh: '─',
  boxH: '═',
  boxhd: '┬',
  boxHd: '╤',
  boxhD: '╥',
  boxHD: '╦',
  boxhu: '┴',
  boxHu: '╧',
  boxhU: '╨',
  boxHU: '╩',
  boxminus: '⊟',
  boxplus: '⊞',
  boxtimes: '⊠',
  boxul: '┘',
  boxuL: '╛',
  boxUl: '╜',
  boxUL: '╝',
  boxur: '└',
  boxuR: '╘',
  boxUr: '╙',
  boxUR: '╚',
  boxv: '│',
  boxV: '║',
  boxvh: '┼',
  boxvH: '╪',
  boxVh: '╫',
  boxVH: '╬',
  boxvl: '┤',
  boxvL: '╡',
  boxVl: '╢',
  boxVL: '╣',
  boxvr: '├',
  boxvR: '╞',
  boxVr: '╟',
  boxVR: '╠',
  bprime: '‵',
  breve: '˘',
  Breve: '˘',
  brvbar: '¦',
  bscr: '풷',
  Bscr: 'ℬ',
  bsemi: '⁏',
  bsim: '∽',
  bsime: '⋍',
  bsolb: '⧅',
  bsol: '\\',
  bsolhsub: '⟈',
  bull: '•',
  bullet: '•',
  bump: '≎',
  bumpE: '⪮',
  bumpe: '≏',
  Bumpeq: '≎',
  bumpeq: '≏',
  Cacute: 'Ć',
  cacute: 'ć',
  capand: '⩄',
  capbrcup: '⩉',
  capcap: '⩋',
  cap: '∩',
  Cap: '⋒',
  capcup: '⩇',
  capdot: '⩀',
  CapitalDifferentialD: 'ⅅ',
  caps: '∩︀',
  caret: '⁁',
  caron: 'ˇ',
  Cayleys: 'ℭ',
  ccaps: '⩍',
  Ccaron: 'Č',
  ccaron: 'č',
  Ccedil: 'Ç',
  ccedil: 'ç',
  Ccirc: 'Ĉ',
  ccirc: 'ĉ',
  Cconint: '∰',
  ccups: '⩌',
  ccupssm: '⩐',
  Cdot: 'Ċ',
  cdot: 'ċ',
  cedil: '¸',
  Cedilla: '¸',
  cemptyv: '⦲',
  cent: '¢',
  centerdot: '·',
  CenterDot: '·',
  cfr: '픠',
  Cfr: 'ℭ',
  CHcy: 'Ч',
  chcy: 'ч',
  check: '✓',
  checkmark: '✓',
  Chi: 'Χ',
  chi: 'χ',
  circ: 'ˆ',
  circeq: '≗',
  circlearrowleft: '↺',
  circlearrowright: '↻',
  circledast: '⊛',
  circledcirc: '⊚',
  circleddash: '⊝',
  CircleDot: '⊙',
  circledR: '®',
  circledS: 'Ⓢ',
  CircleMinus: '⊖',
  CirclePlus: '⊕',
  CircleTimes: '⊗',
  cir: '○',
  cirE: '⧃',
  cire: '≗',
  cirfnint: '⨐',
  cirmid: '⫯',
  cirscir: '⧂',
  ClockwiseContourIntegral: '∲',
  clubs: '♣',
  clubsuit: '♣',
  colon: ':',
  Colon: '∷',
  Colone: '⩴',
  colone: '≔',
  coloneq: '≔',
  comma: ',',
  commat: '@',
  comp: '∁',
  compfn: '∘',
  complement: '∁',
  complexes: 'ℂ',
  cong: '≅',
  congdot: '⩭',
  Congruent: '≡',
  conint: '∮',
  Conint: '∯',
  ContourIntegral: '∮',
  copf: '핔',
  Copf: 'ℂ',
  coprod: '∐',
  Coproduct: '∐',
  copy: '©',
  COPY: '©',
  copysr: '℗',
  CounterClockwiseContourIntegral: '∳',
  crarr: '↵',
  cross: '✗',
  Cross: '⨯',
  Cscr: '풞',
  cscr: '풸',
  csub: '⫏',
  csube: '⫑',
  csup: '⫐',
  csupe: '⫒',
  ctdot: '⋯',
  cudarrl: '⤸',
  cudarrr: '⤵',
  cuepr: '⋞',
  cuesc: '⋟',
  cularr: '↶',
  cularrp: '⤽',
  cupbrcap: '⩈',
  cupcap: '⩆',
  CupCap: '≍',
  cup: '∪',
  Cup: '⋓',
  cupcup: '⩊',
  cupdot: '⊍',
  cupor: '⩅',
  cups: '∪︀',
  curarr: '↷',
  curarrm: '⤼',
  curlyeqprec: '⋞',
  curlyeqsucc: '⋟',
  curlyvee: '⋎',
  curlywedge: '⋏',
  curren: '¤',
  curvearrowleft: '↶',
  curvearrowright: '↷',
  cuvee: '⋎',
  cuwed: '⋏',
  cwconint: '∲',
  cwint: '∱',
  cylcty: '⌭',
  dagger: '†',
  Dagger: '‡',
  daleth: 'ℸ',
  darr: '↓',
  Darr: '↡',
  dArr: '⇓',
  dash: '‐',
  Dashv: '⫤',
  dashv: '⊣',
  dbkarow: '⤏',
  dblac: '˝',
  Dcaron: 'Ď',
  dcaron: 'ď',
  Dcy: 'Д',
  dcy: 'д',
  ddagger: '‡',
  ddarr: '⇊',
  DD: 'ⅅ',
  dd: 'ⅆ',
  DDotrahd: '⤑',
  ddotseq: '⩷',
  deg: '°',
  Del: '∇',
  Delta: 'Δ',
  delta: 'δ',
  demptyv: '⦱',
  dfisht: '⥿',
  Dfr: '픇',
  dfr: '픡',
  dHar: '⥥',
  dharl: '⇃',
  dharr: '⇂',
  DiacriticalAcute: '´',
  DiacriticalDot: '˙',
  DiacriticalDoubleAcute: '˝',
  DiacriticalGrave: '`',
  DiacriticalTilde: '˜',
  diam: '⋄',
  diamond: '⋄',
  Diamond: '⋄',
  diamondsuit: '♦',
  diams: '♦',
  die: '¨',
  DifferentialD: 'ⅆ',
  digamma: 'ϝ',
  disin: '⋲',
  div: '÷',
  divide: '÷',
  divideontimes: '⋇',
  divonx: '⋇',
  DJcy: 'Ђ',
  djcy: 'ђ',
  dlcorn: '⌞',
  dlcrop: '⌍',
  dollar: '$',
  Dopf: '픻',
  dopf: '핕',
  Dot: '¨',
  dot: '˙',
  DotDot: '⃜',
  doteq: '≐',
  doteqdot: '≑',
  DotEqual: '≐',
  dotminus: '∸',
  dotplus: '∔',
  dotsquare: '⊡',
  doublebarwedge: '⌆',
  DoubleContourIntegral: '∯',
  DoubleDot: '¨',
  DoubleDownArrow: '⇓',
  DoubleLeftArrow: '⇐',
  DoubleLeftRightArrow: '⇔',
  DoubleLeftTee: '⫤',
  DoubleLongLeftArrow: '⟸',
  DoubleLongLeftRightArrow: '⟺',
  DoubleLongRightArrow: '⟹',
  DoubleRightArrow: '⇒',
  DoubleRightTee: '⊨',
  DoubleUpArrow: '⇑',
  DoubleUpDownArrow: '⇕',
  DoubleVerticalBar: '∥',
  DownArrowBar: '⤓',
  downarrow: '↓',
  DownArrow: '↓',
  Downarrow: '⇓',
  DownArrowUpArrow: '⇵',
  DownBreve: '̑',
  downdownarrows: '⇊',
  downharpoonleft: '⇃',
  downharpoonright: '⇂',
  DownLeftRightVector: '⥐',
  DownLeftTeeVector: '⥞',
  DownLeftVectorBar: '⥖',
  DownLeftVector: '↽',
  DownRightTeeVector: '⥟',
  DownRightVectorBar: '⥗',
  DownRightVector: '⇁',
  DownTeeArrow: '↧',
  DownTee: '⊤',
  drbkarow: '⤐',
  drcorn: '⌟',
  drcrop: '⌌',
  Dscr: '풟',
  dscr: '풹',
  DScy: 'Ѕ',
  dscy: 'ѕ',
  dsol: '⧶',
  Dstrok: 'Đ',
  dstrok: 'đ',
  dtdot: '⋱',
  dtri: '▿',
  dtrif: '▾',
  duarr: '⇵',
  duhar: '⥯',
  dwangle: '⦦',
  DZcy: 'Џ',
  dzcy: 'џ',
  dzigrarr: '⟿',
  Eacute: 'É',
  eacute: 'é',
  easter: '⩮',
  Ecaron: 'Ě',
  ecaron: 'ě',
  Ecirc: 'Ê',
  ecirc: 'ê',
  ecir: '≖',
  ecolon: '≕',
  Ecy: 'Э',
  ecy: 'э',
  eDDot: '⩷',
  Edot: 'Ė',
  edot: 'ė',
  eDot: '≑',
  ee: 'ⅇ',
  efDot: '≒',
  Efr: '픈',
  efr: '픢',
  eg: '⪚',
  Egrave: 'È',
  egrave: 'è',
  egs: '⪖',
  egsdot: '⪘',
  el: '⪙',
  Element: '∈',
  elinters: '⏧',
  ell: 'ℓ',
  els: '⪕',
  elsdot: '⪗',
  Emacr: 'Ē',
  emacr: 'ē',
  empty: '∅',
  emptyset: '∅',
  EmptySmallSquare: '◻',
  emptyv: '∅',
  EmptyVerySmallSquare: '▫',
  emsp13: ' ',
  emsp14: ' ',
  emsp: ' ',
  ENG: 'Ŋ',
  eng: 'ŋ',
  ensp: ' ',
  Eogon: 'Ę',
  eogon: 'ę',
  Eopf: '피',
  eopf: '핖',
  epar: '⋕',
  eparsl: '⧣',
  eplus: '⩱',
  epsi: 'ε',
  Epsilon: 'Ε',
  epsilon: 'ε',
  epsiv: 'ϵ',
  eqcirc: '≖',
  eqcolon: '≕',
  eqsim: '≂',
  eqslantgtr: '⪖',
  eqslantless: '⪕',
  Equal: '⩵',
  equals: '=',
  EqualTilde: '≂',
  equest: '≟',
  Equilibrium: '⇌',
  equiv: '≡',
  equivDD: '⩸',
  eqvparsl: '⧥',
  erarr: '⥱',
  erDot: '≓',
  escr: 'ℯ',
  Escr: 'ℰ',
  esdot: '≐',
  Esim: '⩳',
  esim: '≂',
  Eta: 'Η',
  eta: 'η',
  ETH: 'Ð',
  eth: 'ð',
  Euml: 'Ë',
  euml: 'ë',
  euro: '€',
  excl: '!',
  exist: '∃',
  Exists: '∃',
  expectation: 'ℰ',
  exponentiale: 'ⅇ',
  ExponentialE: 'ⅇ',
  fallingdotseq: '≒',
  Fcy: 'Ф',
  fcy: 'ф',
  female: '♀',
  ffilig: 'ﬃ',
  fflig: 'ﬀ',
  ffllig: 'ﬄ',
  Ffr: '픉',
  ffr: '픣',
  filig: 'ﬁ',
  FilledSmallSquare: '◼',
  FilledVerySmallSquare: '▪',
  fjlig: 'fj',
  flat: '♭',
  fllig: 'ﬂ',
  fltns: '▱',
  fnof: 'ƒ',
  Fopf: '픽',
  fopf: '핗',
  forall: '∀',
  ForAll: '∀',
  fork: '⋔',
  forkv: '⫙',
  Fouriertrf: 'ℱ',
  fpartint: '⨍',
  frac12: '½',
  frac13: '⅓',
  frac14: '¼',
  frac15: '⅕',
  frac16: '⅙',
  frac18: '⅛',
  frac23: '⅔',
  frac25: '⅖',
  frac34: '¾',
  frac35: '⅗',
  frac38: '⅜',
  frac45: '⅘',
  frac56: '⅚',
  frac58: '⅝',
  frac78: '⅞',
  frasl: '⁄',
  frown: '⌢',
  fscr: '풻',
  Fscr: 'ℱ',
  gacute: 'ǵ',
  Gamma: 'Γ',
  gamma: 'γ',
  Gammad: 'Ϝ',
  gammad: 'ϝ',
  gap: '⪆',
  Gbreve: 'Ğ',
  gbreve: 'ğ',
  Gcedil: 'Ģ',
  Gcirc: 'Ĝ',
  gcirc: 'ĝ',
  Gcy: 'Г',
  gcy: 'г',
  Gdot: 'Ġ',
  gdot: 'ġ',
  ge: '≥',
  gE: '≧',
  gEl: '⪌',
  gel: '⋛',
  geq: '≥',
  geqq: '≧',
  geqslant: '⩾',
  gescc: '⪩',
  ges: '⩾',
  gesdot: '⪀',
  gesdoto: '⪂',
  gesdotol: '⪄',
  gesl: '⋛︀',
  gesles: '⪔',
  Gfr: '픊',
  gfr: '픤',
  gg: '≫',
  Gg: '⋙',
  ggg: '⋙',
  gimel: 'ℷ',
  GJcy: 'Ѓ',
  gjcy: 'ѓ',
  gla: '⪥',
  gl: '≷',
  glE: '⪒',
  glj: '⪤',
  gnap: '⪊',
  gnapprox: '⪊',
  gne: '⪈',
  gnE: '≩',
  gneq: '⪈',
  gneqq: '≩',
  gnsim: '⋧',
  Gopf: '픾',
  gopf: '하',
  grave: '`',
  GreaterEqual: '≥',
  GreaterEqualLess: '⋛',
  GreaterFullEqual: '≧',
  GreaterGreater: '⪢',
  GreaterLess: '≷',
  GreaterSlantEqual: '⩾',
  GreaterTilde: '≳',
  Gscr: '풢',
  gscr: 'ℊ',
  gsim: '≳',
  gsime: '⪎',
  gsiml: '⪐',
  gtcc: '⪧',
  gtcir: '⩺',
  Gt: '≫',
  gtdot: '⋗',
  gtlPar: '⦕',
  gtquest: '⩼',
  gtrapprox: '⪆',
  gtrarr: '⥸',
  gtrdot: '⋗',
  gtreqless: '⋛',
  gtreqqless: '⪌',
  gtrless: '≷',
  gtrsim: '≳',
  gvertneqq: '≩︀',
  gvnE: '≩︀',
  Hacek: 'ˇ',
  hairsp: ' ',
  half: '½',
  hamilt: 'ℋ',
  HARDcy: 'Ъ',
  hardcy: 'ъ',
  harrcir: '⥈',
  harr: '↔',
  hArr: '⇔',
  harrw: '↭',
  Hat: '^',
  hbar: 'ℏ',
  Hcirc: 'Ĥ',
  hcirc: 'ĥ',
  hearts: '♥',
  heartsuit: '♥',
  hellip: '…',
  hercon: '⊹',
  hfr: '픥',
  Hfr: 'ℌ',
  HilbertSpace: 'ℋ',
  hksearow: '⤥',
  hkswarow: '⤦',
  hoarr: '⇿',
  homtht: '∻',
  hookleftarrow: '↩',
  hookrightarrow: '↪',
  hopf: '학',
  Hopf: 'ℍ',
  horbar: '―',
  HorizontalLine: '─',
  hscr: '풽',
  Hscr: 'ℋ',
  hslash: 'ℏ',
  Hstrok: 'Ħ',
  hstrok: 'ħ',
  HumpDownHump: '≎',
  HumpEqual: '≏',
  hybull: '⁃',
  hyphen: '‐',
  Iacute: 'Í',
  iacute: 'í',
  ic: '⁣',
  Icirc: 'Î',
  icirc: 'î',
  Icy: 'И',
  icy: 'и',
  Idot: 'İ',
  IEcy: 'Е',
  iecy: 'е',
  iexcl: '¡',
  iff: '⇔',
  ifr: '픦',
  Ifr: 'ℑ',
  Igrave: 'Ì',
  igrave: 'ì',
  ii: 'ⅈ',
  iiiint: '⨌',
  iiint: '∭',
  iinfin: '⧜',
  iiota: '℩',
  IJlig: 'Ĳ',
  ijlig: 'ĳ',
  Imacr: 'Ī',
  imacr: 'ī',
  image: 'ℑ',
  ImaginaryI: 'ⅈ',
  imagline: 'ℐ',
  imagpart: 'ℑ',
  imath: 'ı',
  Im: 'ℑ',
  imof: '⊷',
  imped: 'Ƶ',
  Implies: '⇒',
  incare: '℅',
  in: '∈',
  infin: '∞',
  infintie: '⧝',
  inodot: 'ı',
  intcal: '⊺',
  int: '∫',
  Int: '∬',
  integers: 'ℤ',
  Integral: '∫',
  intercal: '⊺',
  Intersection: '⋂',
  intlarhk: '⨗',
  intprod: '⨼',
  // to preserve trailing and leading space even in non inline elements
  InvisibleComma: '\u2063',
  InvisibleTimes: '⁢',
  IOcy: 'Ё',
  iocy: 'ё',
  Iogon: 'Į',
  iogon: 'į',
  Iopf: '핀',
  iopf: '핚',
  Iota: 'Ι',
  iota: 'ι',
  iprod: '⨼',
  iquest: '¿',
  iscr: '풾',
  Iscr: 'ℐ',
  isin: '∈',
  isindot: '⋵',
  isinE: '⋹',
  isins: '⋴',
  isinsv: '⋳',
  isinv: '∈',
  it: '⁢',
  Itilde: 'Ĩ',
  itilde: 'ĩ',
  Iukcy: 'І',
  iukcy: 'і',
  Iuml: 'Ï',
  iuml: 'ï',
  Jcirc: 'Ĵ',
  jcirc: 'ĵ',
  Jcy: 'Й',
  jcy: 'й',
  Jfr: '픍',
  jfr: '픧',
  jmath: 'ȷ',
  Jopf: '핁',
  jopf: '핛',
  Jscr: '풥',
  jscr: '풿',
  Jsercy: 'Ј',
  jsercy: 'ј',
  Jukcy: 'Є',
  jukcy: 'є',
  Kappa: 'Κ',
  kappa: 'κ',
  kappav: 'ϰ',
  Kcedil: 'Ķ',
  kcedil: 'ķ',
  Kcy: 'К',
  kcy: 'к',
  Kfr: '픎',
  kfr: '픨',
  kgreen: 'ĸ',
  KHcy: 'Х',
  khcy: 'х',
  KJcy: 'Ќ',
  kjcy: 'ќ',
  Kopf: '핂',
  kopf: '한',
  Kscr: '풦',
  kscr: '퓀',
  lAarr: '⇚',
  Lacute: 'Ĺ',
  lacute: 'ĺ',
  laemptyv: '⦴',
  lagran: 'ℒ',
  Lambda: 'Λ',
  lambda: 'λ',
  lang: '⟨',
  Lang: '⟪',
  langd: '⦑',
  langle: '⟨',
  lap: '⪅',
  Laplacetrf: 'ℒ',
  laquo: '«',
  larrb: '⇤',
  larrbfs: '⤟',
  larr: '←',
  Larr: '↞',
  lArr: '⇐',
  larrfs: '⤝',
  larrhk: '↩',
  larrlp: '↫',
  larrpl: '⤹',
  larrsim: '⥳',
  larrtl: '↢',
  latail: '⤙',
  lAtail: '⤛',
  lat: '⪫',
  late: '⪭',
  lates: '⪭︀',
  lbarr: '⤌',
  lBarr: '⤎',
  lbbrk: '❲',
  lbrace: '{',
  lbrack: '[',
  lbrke: '⦋',
  lbrksld: '⦏',
  lbrkslu: '⦍',
  Lcaron: 'Ľ',
  lcaron: 'ľ',
  Lcedil: 'Ļ',
  lcedil: 'ļ',
  lceil: '⌈',
  lcub: '{',
  Lcy: 'Л',
  lcy: 'л',
  ldca: '⤶',
  ldquo: '“',
  ldquor: '„',
  ldrdhar: '⥧',
  ldrushar: '⥋',
  ldsh: '↲',
  le: '≤',
  lE: '≦',
  LeftAngleBracket: '⟨',
  LeftArrowBar: '⇤',
  leftarrow: '←',
  LeftArrow: '←',
  Leftarrow: '⇐',
  LeftArrowRightArrow: '⇆',
  leftarrowtail: '↢',
  LeftCeiling: '⌈',
  LeftDoubleBracket: '⟦',
  LeftDownTeeVector: '⥡',
  LeftDownVectorBar: '⥙',
  LeftDownVector: '⇃',
  LeftFloor: '⌊',
  leftharpoondown: '↽',
  leftharpoonup: '↼',
  leftleftarrows: '⇇',
  leftrightarrow: '↔',
  LeftRightArrow: '↔',
  Leftrightarrow: '⇔',
  leftrightarrows: '⇆',
  leftrightharpoons: '⇋',
  leftrightsquigarrow: '↭',
  LeftRightVector: '⥎',
  LeftTeeArrow: '↤',
  LeftTee: '⊣',
  LeftTeeVector: '⥚',
  leftthreetimes: '⋋',
  LeftTriangleBar: '⧏',
  LeftTriangle: '⊲',
  LeftTriangleEqual: '⊴',
  LeftUpDownVector: '⥑',
  LeftUpTeeVector: '⥠',
  LeftUpVectorBar: '⥘',
  LeftUpVector: '↿',
  LeftVectorBar: '⥒',
  LeftVector: '↼',
  lEg: '⪋',
  leg: '⋚',
  leq: '≤',
  leqq: '≦',
  leqslant: '⩽',
  lescc: '⪨',
  les: '⩽',
  lesdot: '⩿',
  lesdoto: '⪁',
  lesdotor: '⪃',
  lesg: '⋚︀',
  lesges: '⪓',
  lessapprox: '⪅',
  lessdot: '⋖',
  lesseqgtr: '⋚',
  lesseqqgtr: '⪋',
  LessEqualGreater: '⋚',
  LessFullEqual: '≦',
  LessGreater: '≶',
  lessgtr: '≶',
  LessLess: '⪡',
  lesssim: '≲',
  LessSlantEqual: '⩽',
  LessTilde: '≲',
  lfisht: '⥼',
  lfloor: '⌊',
  Lfr: '픏',
  lfr: '픩',
  lg: '≶',
  lgE: '⪑',
  lHar: '⥢',
  lhard: '↽',
  lharu: '↼',
  lharul: '⥪',
  lhblk: '▄',
  LJcy: 'Љ',
  ljcy: 'љ',
  llarr: '⇇',
  ll: '≪',
  Ll: '⋘',
  llcorner: '⌞',
  Lleftarrow: '⇚',
  llhard: '⥫',
  lltri: '◺',
  Lmidot: 'Ŀ',
  lmidot: 'ŀ',
  lmoustache: '⎰',
  lmoust: '⎰',
  lnap: '⪉',
  lnapprox: '⪉',
  lne: '⪇',
  lnE: '≨',
  lneq: '⪇',
  lneqq: '≨',
  lnsim: '⋦',
  loang: '⟬',
  loarr: '⇽',
  lobrk: '⟦',
  longleftarrow: '⟵',
  LongLeftArrow: '⟵',
  Longleftarrow: '⟸',
  longleftrightarrow: '⟷',
  LongLeftRightArrow: '⟷',
  Longleftrightarrow: '⟺',
  longmapsto: '⟼',
  longrightarrow: '⟶',
  LongRightArrow: '⟶',
  Longrightarrow: '⟹',
  looparrowleft: '↫',
  looparrowright: '↬',
  lopar: '⦅',
  Lopf: '핃',
  lopf: '핝',
  loplus: '⨭',
  lotimes: '⨴',
  lowast: '∗',
  lowbar: '_',
  LowerLeftArrow: '↙',
  LowerRightArrow: '↘',
  loz: '◊',
  lozenge: '◊',
  lozf: '⧫',
  lpar: '(',
  lparlt: '⦓',
  lrarr: '⇆',
  lrcorner: '⌟',
  lrhar: '⇋',
  lrhard: '⥭',
  lrm: '‎',
  lrtri: '⊿',
  lsaquo: '‹',
  lscr: '퓁',
  Lscr: 'ℒ',
  lsh: '↰',
  Lsh: '↰',
  lsim: '≲',
  lsime: '⪍',
  lsimg: '⪏',
  lsqb: '[',
  lsquo: '‘',
  lsquor: '‚',
  Lstrok: 'Ł',
  lstrok: 'ł',
  ltcc: '⪦',
  ltcir: '⩹',
  Lt: '≪',
  ltdot: '⋖',
  lthree: '⋋',
  ltimes: '⋉',
  ltlarr: '⥶',
  ltquest: '⩻',
  ltri: '◃',
  ltrie: '⊴',
  ltrif: '◂',
  ltrPar: '⦖',
  lurdshar: '⥊',
  luruhar: '⥦',
  lvertneqq: '≨︀',
  lvnE: '≨︀',
  macr: '¯',
  male: '♂',
  malt: '✠',
  maltese: '✠',
  Map: '⤅',
  map: '↦',
  mapsto: '↦',
  mapstodown: '↧',
  mapstoleft: '↤',
  mapstoup: '↥',
  marker: '▮',
  mcomma: '⨩',
  Mcy: 'М',
  mcy: 'м',
  mdash: '—',
  mDDot: '∺',
  measuredangle: '∡',
  MediumSpace: ' ',
  Mellintrf: 'ℳ',
  Mfr: '픐',
  mfr: '픪',
  mho: '℧',
  micro: 'µ',
  midast: '*',
  midcir: '⫰',
  mid: '∣',
  middot: '·',
  minusb: '⊟',
  minus: '−',
  minusd: '∸',
  minusdu: '⨪',
  MinusPlus: '∓',
  mlcp: '⫛',
  mldr: '…',
  mnplus: '∓',
  models: '⊧',
  Mopf: '필',
  mopf: '핞',
  mp: '∓',
  mscr: '퓂',
  Mscr: 'ℳ',
  mstpos: '∾',
  Mu: 'Μ',
  mu: 'μ',
  multimap: '⊸',
  mumap: '⊸',
  nabla: '∇',
  Nacute: 'Ń',
  nacute: 'ń',
  nang: '∠⃒',
  nap: '≉',
  napE: '⩰̸',
  napid: '≋̸',
  napos: 'ŉ',
  napprox: '≉',
  natural: '♮',
  naturals: 'ℕ',
  natur: '♮',
  nbsp: ' ',
  nbump: '≎̸',
  nbumpe: '≏̸',
  ncap: '⩃',
  Ncaron: 'Ň',
  ncaron: 'ň',
  Ncedil: 'Ņ',
  ncedil: 'ņ',
  ncong: '≇',
  ncongdot: '⩭̸',
  ncup: '⩂',
  Ncy: 'Н',
  ncy: 'н',
  ndash: '–',
  nearhk: '⤤',
  nearr: '↗',
  neArr: '⇗',
  nearrow: '↗',
  ne: '≠',
  nedot: '≐̸',
  NegativeMediumSpace: '​',
  NegativeThickSpace: '​',
  NegativeThinSpace: '​',
  NegativeVeryThinSpace: '​',
  nequiv: '≢',
  nesear: '⤨',
  nesim: '≂̸',
  NestedGreaterGreater: '≫',
  NestedLessLess: '≪',
  nexist: '∄',
  nexists: '∄',
  Nfr: '픑',
  nfr: '픫',
  ngE: '≧̸',
  nge: '≱',
  ngeq: '≱',
  ngeqq: '≧̸',
  ngeqslant: '⩾̸',
  nges: '⩾̸',
  nGg: '⋙̸',
  ngsim: '≵',
  nGt: '≫⃒',
  ngt: '≯',
  ngtr: '≯',
  nGtv: '≫̸',
  nharr: '↮',
  nhArr: '⇎',
  nhpar: '⫲',
  ni: '∋',
  nis: '⋼',
  nisd: '⋺',
  niv: '∋',
  NJcy: 'Њ',
  njcy: 'њ',
  nlarr: '↚',
  nlArr: '⇍',
  nldr: '‥',
  nlE: '≦̸',
  nle: '≰',
  nleftarrow: '↚',
  nLeftarrow: '⇍',
  nleftrightarrow: '↮',
  nLeftrightarrow: '⇎',
  nleq: '≰',
  nleqq: '≦̸',
  nleqslant: '⩽̸',
  nles: '⩽̸',
  nless: '≮',
  nLl: '⋘̸',
  nlsim: '≴',
  nLt: '≪⃒',
  nlt: '≮',
  nltri: '⋪',
  nltrie: '⋬',
  nLtv: '≪̸',
  nmid: '∤',
  NoBreak: '⁠',
  NonBreakingSpace: ' ',
  nopf: '핟',
  Nopf: 'ℕ',
  Not: '⫬',
  not: '¬',
  NotCongruent: '≢',
  NotCupCap: '≭',
  NotDoubleVerticalBar: '∦',
  NotElement: '∉',
  NotEqual: '≠',
  NotEqualTilde: '≂̸',
  NotExists: '∄',
  NotGreater: '≯',
  NotGreaterEqual: '≱',
  NotGreaterFullEqual: '≧̸',
  NotGreaterGreater: '≫̸',
  NotGreaterLess: '≹',
  NotGreaterSlantEqual: '⩾̸',
  NotGreaterTilde: '≵',
  NotHumpDownHump: '≎̸',
  NotHumpEqual: '≏̸',
  notin: '∉',
  notindot: '⋵̸',
  notinE: '⋹̸',
  notinva: '∉',
  notinvb: '⋷',
  notinvc: '⋶',
  NotLeftTriangleBar: '⧏̸',
  NotLeftTriangle: '⋪',
  NotLeftTriangleEqual: '⋬',
  NotLess: '≮',
  NotLessEqual: '≰',
  NotLessGreater: '≸',
  NotLessLess: '≪̸',
  NotLessSlantEqual: '⩽̸',
  NotLessTilde: '≴',
  NotNestedGreaterGreater: '⪢̸',
  NotNestedLessLess: '⪡̸',
  notni: '∌',
  notniva: '∌',
  notnivb: '⋾',
  notnivc: '⋽',
  NotPrecedes: '⊀',
  NotPrecedesEqual: '⪯̸',
  NotPrecedesSlantEqual: '⋠',
  NotReverseElement: '∌',
  NotRightTriangleBar: '⧐̸',
  NotRightTriangle: '⋫',
  NotRightTriangleEqual: '⋭',
  NotSquareSubset: '⊏̸',
  NotSquareSubsetEqual: '⋢',
  NotSquareSuperset: '⊐̸',
  NotSquareSupersetEqual: '⋣',
  NotSubset: '⊂⃒',
  NotSubsetEqual: '⊈',
  NotSucceeds: '⊁',
  NotSucceedsEqual: '⪰̸',
  NotSucceedsSlantEqual: '⋡',
  NotSucceedsTilde: '≿̸',
  NotSuperset: '⊃⃒',
  NotSupersetEqual: '⊉',
  NotTilde: '≁',
  NotTildeEqual: '≄',
  NotTildeFullEqual: '≇',
  NotTildeTilde: '≉',
  NotVerticalBar: '∤',
  nparallel: '∦',
  npar: '∦',
  nparsl: '⫽⃥',
  npart: '∂̸',
  npolint: '⨔',
  npr: '⊀',
  nprcue: '⋠',
  nprec: '⊀',
  npreceq: '⪯̸',
  npre: '⪯̸',
  nrarrc: '⤳̸',
  nrarr: '↛',
  nrArr: '⇏',
  nrarrw: '↝̸',
  nrightarrow: '↛',
  nRightarrow: '⇏',
  nrtri: '⋫',
  nrtrie: '⋭',
  nsc: '⊁',
  nsccue: '⋡',
  nsce: '⪰̸',
  Nscr: '풩',
  nscr: '퓃',
  nshortmid: '∤',
  nshortparallel: '∦',
  nsim: '≁',
  nsime: '≄',
  nsimeq: '≄',
  nsmid: '∤',
  nspar: '∦',
  nsqsube: '⋢',
  nsqsupe: '⋣',
  nsub: '⊄',
  nsubE: '⫅̸',
  nsube: '⊈',
  nsubset: '⊂⃒',
  nsubseteq: '⊈',
  nsubseteqq: '⫅̸',
  nsucc: '⊁',
  nsucceq: '⪰̸',
  nsup: '⊅',
  nsupE: '⫆̸',
  nsupe: '⊉',
  nsupset: '⊃⃒',
  nsupseteq: '⊉',
  nsupseteqq: '⫆̸',
  ntgl: '≹',
  Ntilde: 'Ñ',
  ntilde: 'ñ',
  ntlg: '≸',
  ntriangleleft: '⋪',
  ntrianglelefteq: '⋬',
  ntriangleright: '⋫',
  ntrianglerighteq: '⋭',
  Nu: 'Ν',
  nu: 'ν',
  num: '#',
  numero: '№',
  numsp: ' ',
  nvap: '≍⃒',
  nvdash: '⊬',
  nvDash: '⊭',
  nVdash: '⊮',
  nVDash: '⊯',
  nvge: '≥⃒',
  nvgt: '>⃒',
  nvHarr: '⤄',
  nvinfin: '⧞',
  nvlArr: '⤂',
  nvle: '≤⃒',
  nvlt: '<⃒',
  nvltrie: '⊴⃒',
  nvrArr: '⤃',
  nvrtrie: '⊵⃒',
  nvsim: '∼⃒',
  nwarhk: '⤣',
  nwarr: '↖',
  nwArr: '⇖',
  nwarrow: '↖',
  nwnear: '⤧',
  Oacute: 'Ó',
  oacute: 'ó',
  oast: '⊛',
  Ocirc: 'Ô',
  ocirc: 'ô',
  ocir: '⊚',
  Ocy: 'О',
  ocy: 'о',
  odash: '⊝',
  Odblac: 'Ő',
  odblac: 'ő',
  odiv: '⨸',
  odot: '⊙',
  odsold: '⦼',
  OElig: 'Œ',
  oelig: 'œ',
  ofcir: '⦿',
  Ofr: '픒',
  ofr: '픬',
  ogon: '˛',
  Ograve: 'Ò',
  ograve: 'ò',
  ogt: '⧁',
  ohbar: '⦵',
  ohm: 'Ω',
  oint: '∮',
  olarr: '↺',
  olcir: '⦾',
  olcross: '⦻',
  oline: '‾',
  olt: '⧀',
  Omacr: 'Ō',
  omacr: 'ō',
  Omega: 'Ω',
  omega: 'ω',
  Omicron: 'Ο',
  omicron: 'ο',
  omid: '⦶',
  ominus: '⊖',
  Oopf: '핆',
  oopf: '할',
  opar: '⦷',
  OpenCurlyDoubleQuote: '“',
  OpenCurlyQuote: '‘',
  operp: '⦹',
  oplus: '⊕',
  orarr: '↻',
  Or: '⩔',
  or: '∨',
  ord: '⩝',
  order: 'ℴ',
  orderof: 'ℴ',
  ordf: 'ª',
  ordm: 'º',
  origof: '⊶',
  oror: '⩖',
  orslope: '⩗',
  orv: '⩛',
  oS: 'Ⓢ',
  Oscr: '풪',
  oscr: 'ℴ',
  Oslash: 'Ø',
  oslash: 'ø',
  osol: '⊘',
  Otilde: 'Õ',
  otilde: 'õ',
  otimesas: '⨶',
  Otimes: '⨷',
  otimes: '⊗',
  Ouml: 'Ö',
  ouml: 'ö',
  ovbar: '⌽',
  OverBar: '‾',
  OverBrace: '⏞',
  OverBracket: '⎴',
  OverParenthesis: '⏜',
  para: '¶',
  parallel: '∥',
  par: '∥',
  parsim: '⫳',
  parsl: '⫽',
  part: '∂',
  PartialD: '∂',
  Pcy: 'П',
  pcy: 'п',
  percnt: '%',
  period: '.',
  permil: '‰',
  perp: '⊥',
  pertenk: '‱',
  Pfr: '픓',
  pfr: '픭',
  Phi: 'Φ',
  phi: 'φ',
  phiv: 'ϕ',
  phmmat: 'ℳ',
  phone: '☎',
  Pi: 'Π',
  pi: 'π',
  pitchfork: '⋔',
  piv: 'ϖ',
  planck: 'ℏ',
  planckh: 'ℎ',
  plankv: 'ℏ',
  plusacir: '⨣',
  plusb: '⊞',
  pluscir: '⨢',
  plus: '+',
  plusdo: '∔',
  plusdu: '⨥',
  pluse: '⩲',
  PlusMinus: '±',
  plusmn: '±',
  plussim: '⨦',
  plustwo: '⨧',
  pm: '±',
  Poincareplane: 'ℌ',
  pointint: '⨕',
  popf: '핡',
  Popf: 'ℙ',
  pound: '£',
  prap: '⪷',
  Pr: '⪻',
  pr: '≺',
  prcue: '≼',
  precapprox: '⪷',
  prec: '≺',
  preccurlyeq: '≼',
  Precedes: '≺',
  PrecedesEqual: '⪯',
  PrecedesSlantEqual: '≼',
  PrecedesTilde: '≾',
  preceq: '⪯',
  precnapprox: '⪹',
  precneqq: '⪵',
  precnsim: '⋨',
  pre: '⪯',
  prE: '⪳',
  precsim: '≾',
  prime: '′',
  Prime: '″',
  primes: 'ℙ',
  prnap: '⪹',
  prnE: '⪵',
  prnsim: '⋨',
  prod: '∏',
  Product: '∏',
  profalar: '⌮',
  profline: '⌒',
  profsurf: '⌓',
  prop: '∝',
  Proportional: '∝',
  Proportion: '∷',
  propto: '∝',
  prsim: '≾',
  prurel: '⊰',
  Pscr: '풫',
  pscr: '퓅',
  Psi: 'Ψ',
  psi: 'ψ',
  puncsp: ' ',
  Qfr: '픔',
  qfr: '픮',
  qint: '⨌',
  qopf: '핢',
  Qopf: 'ℚ',
  qprime: '⁗',
  Qscr: '풬',
  qscr: '퓆',
  quaternions: 'ℍ',
  quatint: '⨖',
  quest: '?',
  questeq: '≟',
  rAarr: '⇛',
  race: '∽̱',
  Racute: 'Ŕ',
  racute: 'ŕ',
  radic: '√',
  raemptyv: '⦳',
  rang: '⟩',
  Rang: '⟫',
  rangd: '⦒',
  range: '⦥',
  rangle: '⟩',
  raquo: '»',
  rarrap: '⥵',
  rarrb: '⇥',
  rarrbfs: '⤠',
  rarrc: '⤳',
  rarr: '→',
  Rarr: '↠',
  rArr: '⇒',
  rarrfs: '⤞',
  rarrhk: '↪',
  rarrlp: '↬',
  rarrpl: '⥅',
  rarrsim: '⥴',
  Rarrtl: '⤖',
  rarrtl: '↣',
  rarrw: '↝',
  ratail: '⤚',
  rAtail: '⤜',
  ratio: '∶',
  rationals: 'ℚ',
  rbarr: '⤍',
  rBarr: '⤏',
  RBarr: '⤐',
  rbbrk: '❳',
  rbrace: '}',
  rbrack: ']',
  rbrke: '⦌',
  rbrksld: '⦎',
  rbrkslu: '⦐',
  Rcaron: 'Ř',
  rcaron: 'ř',
  Rcedil: 'Ŗ',
  rcedil: 'ŗ',
  rceil: '⌉',
  rcub: '}',
  Rcy: 'Р',
  rcy: 'р',
  rdca: '⤷',
  rdldhar: '⥩',
  rdquo: '”',
  rdquor: '”',
  CloseCurlyDoubleQuote: '”',
  rdsh: '↳',
  real: 'ℜ',
  realine: 'ℛ',
  realpart: 'ℜ',
  reals: 'ℝ',
  Re: 'ℜ',
  rect: '▭',
  reg: '®',
  REG: '®',
  ReverseElement: '∋',
  ReverseEquilibrium: '⇋',
  ReverseUpEquilibrium: '⥯',
  rfisht: '⥽',
  rfloor: '⌋',
  rfr: '픯',
  Rfr: 'ℜ',
  rHar: '⥤',
  rhard: '⇁',
  rharu: '⇀',
  rharul: '⥬',
  Rho: 'Ρ',
  rho: 'ρ',
  rhov: 'ϱ',
  RightAngleBracket: '⟩',
  RightArrowBar: '⇥',
  rightarrow: '→',
  RightArrow: '→',
  Rightarrow: '⇒',
  RightArrowLeftArrow: '⇄',
  rightarrowtail: '↣',
  RightCeiling: '⌉',
  RightDoubleBracket: '⟧',
  RightDownTeeVector: '⥝',
  RightDownVectorBar: '⥕',
  RightDownVector: '⇂',
  RightFloor: '⌋',
  rightharpoondown: '⇁',
  rightharpoonup: '⇀',
  rightleftarrows: '⇄',
  rightleftharpoons: '⇌',
  rightrightarrows: '⇉',
  rightsquigarrow: '↝',
  RightTeeArrow: '↦',
  RightTee: '⊢',
  RightTeeVector: '⥛',
  rightthreetimes: '⋌',
  RightTriangleBar: '⧐',
  RightTriangle: '⊳',
  RightTriangleEqual: '⊵',
  RightUpDownVector: '⥏',
  RightUpTeeVector: '⥜',
  RightUpVectorBar: '⥔',
  RightUpVector: '↾',
  RightVectorBar: '⥓',
  RightVector: '⇀',
  ring: '˚',
  risingdotseq: '≓',
  rlarr: '⇄',
  rlhar: '⇌',
  rlm: '‏',
  rmoustache: '⎱',
  rmoust: '⎱',
  rnmid: '⫮',
  roang: '⟭',
  roarr: '⇾',
  robrk: '⟧',
  ropar: '⦆',
  ropf: '핣',
  Ropf: 'ℝ',
  roplus: '⨮',
  rotimes: '⨵',
  RoundImplies: '⥰',
  rpar: ')',
  rpargt: '⦔',
  rppolint: '⨒',
  rrarr: '⇉',
  Rrightarrow: '⇛',
  rsaquo: '›',
  rscr: '퓇',
  Rscr: 'ℛ',
  rsh: '↱',
  Rsh: '↱',
  rsqb: ']',
  rsquo: '’',
  rsquor: '’',
  CloseCurlyQuote: '’',
  rthree: '⋌',
  rtimes: '⋊',
  rtri: '▹',
  rtrie: '⊵',
  rtrif: '▸',
  rtriltri: '⧎',
  RuleDelayed: '⧴',
  ruluhar: '⥨',
  rx: '℞',
  Sacute: 'Ś',
  sacute: 'ś',
  sbquo: '‚',
  scap: '⪸',
  Scaron: 'Š',
  scaron: 'š',
  Sc: '⪼',
  sc: '≻',
  sccue: '≽',
  sce: '⪰',
  scE: '⪴',
  Scedil: 'Ş',
  scedil: 'ş',
  Scirc: 'Ŝ',
  scirc: 'ŝ',
  scnap: '⪺',
  scnE: '⪶',
  scnsim: '⋩',
  scpolint: '⨓',
  scsim: '≿',
  Scy: 'С',
  scy: 'с',
  sdotb: '⊡',
  sdot: '⋅',
  sdote: '⩦',
  searhk: '⤥',
  searr: '↘',
  seArr: '⇘',
  searrow: '↘',
  sect: '§',
  semi: ';',
  seswar: '⤩',
  setminus: '∖',
  setmn: '∖',
  sext: '✶',
  Sfr: '픖',
  sfr: '픰',
  sfrown: '⌢',
  sharp: '♯',
  SHCHcy: 'Щ',
  shchcy: 'щ',
  SHcy: 'Ш',
  shcy: 'ш',
  ShortDownArrow: '↓',
  ShortLeftArrow: '←',
  shortmid: '∣',
  shortparallel: '∥',
  ShortRightArrow: '→',
  ShortUpArrow: '↑',
  shy: '­',
  Sigma: 'Σ',
  sigma: 'σ',
  sigmaf: 'ς',
  sigmav: 'ς',
  sim: '∼',
  simdot: '⩪',
  sime: '≃',
  simeq: '≃',
  simg: '⪞',
  simgE: '⪠',
  siml: '⪝',
  simlE: '⪟',
  simne: '≆',
  simplus: '⨤',
  simrarr: '⥲',
  slarr: '←',
  SmallCircle: '∘',
  smallsetminus: '∖',
  smashp: '⨳',
  smeparsl: '⧤',
  smid: '∣',
  smile: '⌣',
  smt: '⪪',
  smte: '⪬',
  smtes: '⪬︀',
  SOFTcy: 'Ь',
  softcy: 'ь',
  solbar: '⌿',
  solb: '⧄',
  sol: '/',
  Sopf: '핊',
  sopf: '핤',
  spades: '♠',
  spadesuit: '♠',
  spar: '∥',
  sqcap: '⊓',
  sqcaps: '⊓︀',
  sqcup: '⊔',
  sqcups: '⊔︀',
  Sqrt: '√',
  sqsub: '⊏',
  sqsube: '⊑',
  sqsubset: '⊏',
  sqsubseteq: '⊑',
  sqsup: '⊐',
  sqsupe: '⊒',
  sqsupset: '⊐',
  sqsupseteq: '⊒',
  square: '□',
  Square: '□',
  SquareIntersection: '⊓',
  SquareSubset: '⊏',
  SquareSubsetEqual: '⊑',
  SquareSuperset: '⊐',
  SquareSupersetEqual: '⊒',
  SquareUnion: '⊔',
  squarf: '▪',
  squ: '□',
  squf: '▪',
  srarr: '→',
  Sscr: '풮',
  sscr: '퓈',
  ssetmn: '∖',
  ssmile: '⌣',
  sstarf: '⋆',
  Star: '⋆',
  star: '☆',
  starf: '★',
  straightepsilon: 'ϵ',
  straightphi: 'ϕ',
  strns: '¯',
  sub: '⊂',
  Sub: '⋐',
  subdot: '⪽',
  subE: '⫅',
  sube: '⊆',
  subedot: '⫃',
  submult: '⫁',
  subnE: '⫋',
  subne: '⊊',
  subplus: '⪿',
  subrarr: '⥹',
  subset: '⊂',
  Subset: '⋐',
  subseteq: '⊆',
  subseteqq: '⫅',
  SubsetEqual: '⊆',
  subsetneq: '⊊',
  subsetneqq: '⫋',
  subsim: '⫇',
  subsub: '⫕',
  subsup: '⫓',
  succapprox: '⪸',
  succ: '≻',
  succcurlyeq: '≽',
  Succeeds: '≻',
  SucceedsEqual: '⪰',
  SucceedsSlantEqual: '≽',
  SucceedsTilde: '≿',
  succeq: '⪰',
  succnapprox: '⪺',
  succneqq: '⪶',
  succnsim: '⋩',
  succsim: '≿',
  SuchThat: '∋',
  sum: '∑',
  Sum: '∑',
  sung: '♪',
  sup1: '¹',
  sup2: '²',
  sup3: '³',
  sup: '⊃',
  Sup: '⋑',
  supdot: '⪾',
  supdsub: '⫘',
  supE: '⫆',
  supe: '⊇',
  supedot: '⫄',
  Superset: '⊃',
  SupersetEqual: '⊇',
  suphsol: '⟉',
  suphsub: '⫗',
  suplarr: '⥻',
  supmult: '⫂',
  supnE: '⫌',
  supne: '⊋',
  supplus: '⫀',
  supset: '⊃',
  Supset: '⋑',
  supseteq: '⊇',
  supseteqq: '⫆',
  supsetneq: '⊋',
  supsetneqq: '⫌',
  supsim: '⫈',
  supsub: '⫔',
  supsup: '⫖',
  swarhk: '⤦',
  swarr: '↙',
  swArr: '⇙',
  swarrow: '↙',
  swnwar: '⤪',
  szlig: 'ß',
  Tab: '\t',
  target: '⌖',
  Tau: 'Τ',
  tau: 'τ',
  tbrk: '⎴',
  Tcaron: 'Ť',
  tcaron: 'ť',
  Tcedil: 'Ţ',
  tcedil: 'ţ',
  Tcy: 'Т',
  tcy: 'т',
  tdot: '⃛',
  telrec: '⌕',
  Tfr: '픗',
  tfr: '픱',
  there4: '∴',
  therefore: '∴',
  Therefore: '∴',
  Theta: 'Θ',
  theta: 'θ',
  thetasym: 'ϑ',
  thetav: 'ϑ',
  thickapprox: '≈',
  thicksim: '∼',
  ThickSpace: '  ',
  ThinSpace: ' ',
  thinsp: ' ',
  thkap: '≈',
  thksim: '∼',
  THORN: 'Þ',
  thorn: 'þ',
  tilde: '˜',
  Tilde: '∼',
  TildeEqual: '≃',
  TildeFullEqual: '≅',
  TildeTilde: '≈',
  timesbar: '⨱',
  timesb: '⊠',
  times: '×',
  timesd: '⨰',
  tint: '∭',
  toea: '⤨',
  topbot: '⌶',
  topcir: '⫱',
  top: '⊤',
  Topf: '핋',
  topf: '핥',
  topfork: '⫚',
  tosa: '⤩',
  tprime: '‴',
  trade: '™',
  TRADE: '™',
  triangle: '▵',
  triangledown: '▿',
  triangleleft: '◃',
  trianglelefteq: '⊴',
  triangleq: '≜',
  triangleright: '▹',
  trianglerighteq: '⊵',
  tridot: '◬',
  trie: '≜',
  triminus: '⨺',
  TripleDot: '⃛',
  triplus: '⨹',
  trisb: '⧍',
  tritime: '⨻',
  trpezium: '⏢',
  Tscr: '풯',
  tscr: '퓉',
  TScy: 'Ц',
  tscy: 'ц',
  TSHcy: 'Ћ',
  tshcy: 'ћ',
  Tstrok: 'Ŧ',
  tstrok: 'ŧ',
  twixt: '≬',
  twoheadleftarrow: '↞',
  twoheadrightarrow: '↠',
  Uacute: 'Ú',
  uacute: 'ú',
  uarr: '↑',
  Uarr: '↟',
  uArr: '⇑',
  Uarrocir: '⥉',
  Ubrcy: 'Ў',
  ubrcy: 'ў',
  Ubreve: 'Ŭ',
  ubreve: 'ŭ',
  Ucirc: 'Û',
  ucirc: 'û',
  Ucy: 'У',
  ucy: 'у',
  udarr: '⇅',
  Udblac: 'Ű',
  udblac: 'ű',
  udhar: '⥮',
  ufisht: '⥾',
  Ufr: '픘',
  ufr: '픲',
  Ugrave: 'Ù',
  ugrave: 'ù',
  uHar: '⥣',
  uharl: '↿',
  uharr: '↾',
  uhblk: '▀',
  ulcorn: '⌜',
  ulcorner: '⌜',
  ulcrop: '⌏',
  ultri: '◸',
  Umacr: 'Ū',
  umacr: 'ū',
  uml: '¨',
  UnderBar: '_',
  UnderBrace: '⏟',
  UnderBracket: '⎵',
  UnderParenthesis: '⏝',
  Union: '⋃',
  UnionPlus: '⊎',
  Uogon: 'Ų',
  uogon: 'ų',
  Uopf: '핌',
  uopf: '핦',
  UpArrowBar: '⤒',
  uparrow: '↑',
  UpArrow: '↑',
  Uparrow: '⇑',
  UpArrowDownArrow: '⇅',
  updownarrow: '↕',
  UpDownArrow: '↕',
  Updownarrow: '⇕',
  UpEquilibrium: '⥮',
  upharpoonleft: '↿',
  upharpoonright: '↾',
  uplus: '⊎',
  UpperLeftArrow: '↖',
  UpperRightArrow: '↗',
  upsi: 'υ',
  Upsi: 'ϒ',
  upsih: 'ϒ',
  Upsilon: 'Υ',
  upsilon: 'υ',
  UpTeeArrow: '↥',
  UpTee: '⊥',
  upuparrows: '⇈',
  urcorn: '⌝',
  urcorner: '⌝',
  urcrop: '⌎',
  Uring: 'Ů',
  uring: 'ů',
  urtri: '◹',
  Uscr: '풰',
  uscr: '퓊',
  utdot: '⋰',
  Utilde: 'Ũ',
  utilde: 'ũ',
  utri: '▵',
  utrif: '▴',
  uuarr: '⇈',
  Uuml: 'Ü',
  uuml: 'ü',
  uwangle: '⦧',
  vangrt: '⦜',
  varepsilon: 'ϵ',
  varkappa: 'ϰ',
  varnothing: '∅',
  varphi: 'ϕ',
  varpi: 'ϖ',
  varpropto: '∝',
  varr: '↕',
  vArr: '⇕',
  varrho: 'ϱ',
  varsigma: 'ς',
  varsubsetneq: '⊊︀',
  varsubsetneqq: '⫋︀',
  varsupsetneq: '⊋︀',
  varsupsetneqq: '⫌︀',
  vartheta: 'ϑ',
  vartriangleleft: '⊲',
  vartriangleright: '⊳',
  vBar: '⫨',
  Vbar: '⫫',
  vBarv: '⫩',
  Vcy: 'В',
  vcy: 'в',
  vdash: '⊢',
  vDash: '⊨',
  Vdash: '⊩',
  VDash: '⊫',
  Vdashl: '⫦',
  veebar: '⊻',
  vee: '∨',
  Vee: '⋁',
  veeeq: '≚',
  vellip: '⋮',
  verbar: '|',
  Verbar: '‖',
  vert: '|',
  Vert: '‖',
  VerticalBar: '∣',
  VerticalLine: '|',
  VerticalSeparator: '❘',
  VerticalTilde: '≀',
  VeryThinSpace: ' ',
  Vfr: '픙',
  vfr: '픳',
  vltri: '⊲',
  vnsub: '⊂⃒',
  vnsup: '⊃⃒',
  Vopf: '핍',
  vopf: '핧',
  vprop: '∝',
  vrtri: '⊳',
  Vscr: '풱',
  vscr: '퓋',
  vsubnE: '⫋︀',
  vsubne: '⊊︀',
  vsupnE: '⫌︀',
  vsupne: '⊋︀',
  Vvdash: '⊪',
  vzigzag: '⦚',
  Wcirc: 'Ŵ',
  wcirc: 'ŵ',
  wedbar: '⩟',
  wedge: '∧',
  Wedge: '⋀',
  wedgeq: '≙',
  weierp: '℘',
  Wfr: '픚',
  wfr: '픴',
  Wopf: '핎',
  wopf: '함',
  wp: '℘',
  wr: '≀',
  wreath: '≀',
  Wscr: '풲',
  wscr: '퓌',
  xcap: '⋂',
  xcirc: '◯',
  xcup: '⋃',
  xdtri: '▽',
  Xfr: '픛',
  xfr: '픵',
  xharr: '⟷',
  xhArr: '⟺',
  Xi: 'Ξ',
  xi: 'ξ',
  xlarr: '⟵',
  xlArr: '⟸',
  xmap: '⟼',
  xnis: '⋻',
  xodot: '⨀',
  Xopf: '핏',
  xopf: '합',
  xoplus: '⨁',
  xotime: '⨂',
  xrarr: '⟶',
  xrArr: '⟹',
  Xscr: '풳',
  xscr: '퓍',
  xsqcup: '⨆',
  xuplus: '⨄',
  xutri: '△',
  xvee: '⋁',
  xwedge: '⋀',
  Yacute: 'Ý',
  yacute: 'ý',
  YAcy: 'Я',
  yacy: 'я',
  Ycirc: 'Ŷ',
  ycirc: 'ŷ',
  Ycy: 'Ы',
  ycy: 'ы',
  yen: '¥',
  Yfr: '픜',
  yfr: '픶',
  YIcy: 'Ї',
  yicy: 'ї',
  Yopf: '핐',
  yopf: '핪',
  Yscr: '풴',
  yscr: '퓎',
  YUcy: 'Ю',
  yucy: 'ю',
  yuml: 'ÿ',
  Yuml: 'Ÿ',
  Zacute: 'Ź',
  zacute: 'ź',
  Zcaron: 'Ž',
  zcaron: 'ž',
  Zcy: 'З',
  zcy: 'з',
  Zdot: 'Ż',
  zdot: 'ż',
  zeetrf: 'ℨ',
  ZeroWidthSpace: '​',
  Zeta: 'Ζ',
  zeta: 'ζ',
  zfr: '픷',
  Zfr: 'ℨ',
  ZHcy: 'Ж',
  zhcy: 'ж',
  zigrarr: '⇝',
  zopf: '핫',
  Zopf: 'ℤ',
  Zscr: '풵',
  zscr: '퓏',
  zwj: '‍',
  zwnj: '‌',
};
