import { type CSSProperties } from 'react';
import { switchMap } from '../../gizmo-utils/fpTools';
import { type Annotations, HAlign, VAlign } from '@bettermarks/gizmo-types';
import { padding } from './padding';

export type PaddingProps = Pick<
  CSSProperties,
  'paddingLeft' | 'paddingRight' | 'paddingTop' | 'paddingBottom'
>;

export type ImageDimensions = {
  real: {
    width: number;
    height: number;
  };
  requested: {
    width: number;
    height: number;
  };
  style: PaddingProps;
};

export type ContentAlign = Pick<Annotations, 'hAlign' | 'vAlign'>;
export const hAlign = switchMap(
  {
    [HAlign.center]: { left: 0.5, right: 0.5 },
    [HAlign.left]: { left: 1, right: 0 },
    [HAlign.right]: { left: 0, right: 1 },
  },
  { left: 0.5, right: 0.5 }
);
export const vAlign = switchMap(
  {
    [VAlign.middle]: { top: 0.5, bottom: 0.5 },
    [VAlign.top]: { top: 1, bottom: 0 },
    [VAlign.bottom]: { top: 0, bottom: 1 },
  },
  { top: 0.5, bottom: 0.5 }
);

/**
 * Calculates the requested (dom), and real (image) dimensions of an image
 * according to the arguments.
 *
 * It detects the right scale factor for which the image fits completely
 * in at least one direction (scaling down wins over scaling up).
 * The other dimension is filled with padding to center the image.
 *
 * A padding is returned in `style`,
 * to fill up the space required to reach the requested (dom) dimensions
 * and position the image at the center.
 *
 * @param width the unscaled target width
 * @param height that unscaled target height
 * @param realWidth the natural (unscaled) width of the image
 * @param realHeight the natural (unscaled) height of the image
 * @param contentAlign alignment dictated by content
 * @param scale the scale to apply to all dimensions (after proportional scaling)
 */
export const imageDimensions = (
  width: number,
  height: number,
  realWidth: number,
  realHeight: number,
  contentAlign: ContentAlign = {},
  scale = 1
): ImageDimensions => {
  /*
   When the natural size of the image is different
   from the unscaled requested one,
   we need to scale it, but preserve the proportions.
   The scale factor that is used is the lowest one
   */
  const innerScale = Math.min(width / realWidth, height / realHeight);
  // variables ending with S are already scaled
  const domWidthS = realWidth * innerScale * scale;
  const domHeightS = realHeight * innerScale * scale;
  const requestedWidthS = width * scale;
  const requestedHeightS = height * scale;

  /*
   If the requested image is smaller than the originally specified size,
   add padding on the top (for the height) and on both left and right (for the width).
   */
  const paddingHorizontalS = domWidthS < requestedWidthS ? requestedWidthS - domWidthS : 0;
  const paddingVerticalS = domHeightS < requestedHeightS ? requestedHeightS - domHeightS : 0;
  return {
    real: { width: domWidthS, height: domHeightS },
    requested: { width: requestedWidthS, height: requestedHeightS },
    style: padding(paddingVerticalS, paddingHorizontalS, contentAlign),
  };
};
