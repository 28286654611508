import * as React from 'react';
import { isNil } from 'lodash';
import {
  type ContentReference,
  type Coords,
  type GeoConfigurationDisplay,
  type GeoObject,
  type GeoObjectMap,
  LabelingObjectRefType,
  type LabelStepperAnchor,
  type LabelStepperProps,
} from '@bettermarks/gizmo-types';
import { getRefPointCoords } from '../sets';
import { POINT_RADIUS, worldToScreen } from '@bettermarks/importers';
import { PickerWidget } from '@seriesplayer/common-ui';

import Measure from 'react-measure';
import styles from './PickerWidgetAPO.scss';
import { PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';
import { icons } from '../../../../components/PickerWidget/constants';

export type PickerWidgetAPOProps = {
  labelStepper: LabelStepperProps;
  matrix: number[];
  configurationDisplay: GeoConfigurationDisplay;
  borderExtensionLeft: number;
  borderExtensionTop: number;
  width: number;
  height: number;
  geoContentMap: GeoObjectMap<GeoObject>;
};

export type PickerWidgetAPOState = {
  index: number;
  initialMeasurement: boolean;
  measurements: { width: number; height: number }[];
  maxHeight: number;
  maxWidth: number;
};

export class PickerWidgetAPO extends React.Component<PickerWidgetAPOProps, PickerWidgetAPOState> {
  getLabelStepperPosition = (
    refPointCoords: Coords,
    pickerDimensions: { width: number; height: number },
    maxHeight: number,
    customNoseOffset = 0,
    noseUp?: boolean
  ): LabelStepperAnchor => {
    const { width, height, borderExtensionLeft, borderExtensionTop } = this.props;
    const { x, y } = refPointCoords;
    let transX = -pickerDimensions.width * 0.5;
    let transY = 0;
    let noseOffsetX = 0;
    let up = noseUp;

    // if noseUp is undefined we check for enough space otherwise the passed value is taken
    // to ensure that you can enforce a label position from the outside (e.g. for the interval tool)
    if (
      up ||
      (isNil(up) &&
        ((y > height * 0.5 && y < height - maxHeight - borderExtensionTop) ||
          y < maxHeight + borderExtensionTop))
    ) {
      transY = 2 * POINT_RADIUS;
      up = true;
    } else {
      transY = -pickerDimensions.height - 2 * POINT_RADIUS;
      up = false;
    }

    if (x > width + borderExtensionLeft - pickerDimensions.width * 0.5) {
      noseOffsetX = pickerDimensions.width * 0.5 - (width - x + borderExtensionLeft);
      transX = -pickerDimensions.width + (width - x) + borderExtensionLeft;
    }

    if (x < pickerDimensions.width * 0.5) {
      noseOffsetX = -pickerDimensions.width * 0.5 + x - borderExtensionLeft;
      transX = -x + borderExtensionLeft;
    }

    transX += customNoseOffset;
    noseOffsetX -= customNoseOffset;

    return { refPointCoords, up, transX, transY, noseOffsetX };
  };

  state = {
    initialMeasurement: true,
    index: this.props.labelStepper.initialIndex,
    measurements: [],
    maxHeight: 0,
    maxWidth: 0,
  };

  onClick = (index: number) => {
    const onSelect = this.props.labelStepper.onSelect;
    onSelect && onSelect(index); // call the provided callback if defined!
    this.setState({ index });
  };

  onResize = (clientRect: any) =>
    this.setState((prevState: PickerWidgetAPOState) => ({
      initialMeasurement: prevState.measurements.length + 1 < this.props.labelStepper.list.length,
      measurements: [
        ...prevState.measurements,
        { width: clientRect.offset.width, height: clientRect.offset.height },
      ],
      maxHeight: Math.max(prevState.maxHeight, clientRect.offset.height),
      maxWidth: Math.max(prevState.maxWidth, clientRect.offset.width),
    }));

  render() {
    const { configurationDisplay, geoContentMap, labelStepper, matrix, width } = this.props;

    let labelStepperAnchor: LabelStepperAnchor = {
      refPointCoords: { x: 0, y: 0 },
      up: true,
      transX: 0,
      transY: 0,
      noseOffsetX: 0,
    };

    if (!this.state.initialMeasurement && labelStepper && labelStepper.active) {
      labelStepperAnchor = this.getLabelStepperPosition(
        worldToScreen(matrix)(
          labelStepper.labelingObjectRef.type === LabelingObjectRefType.id
            ? getRefPointCoords(
                geoContentMap[labelStepper.labelingObjectRef.id],
                geoContentMap,
                configurationDisplay
              )
            : labelStepper.labelingObjectRef.coords
        ),
        this.state.measurements[this.state.index],
        this.state.maxHeight,
        labelStepper.customNoseOffset,
        labelStepper.noseUp
      );
    }

    const stepperItems = labelStepper.list.map((l: ContentReference, idx) => (
      <PolymorphicGizmo key={`PickerItem_${idx}`} refid={l.$refid} availableWidth={width} />
    ));

    let pickerWidgets: any;
    if (this.state.initialMeasurement) {
      pickerWidgets = labelStepper.list.map((_, i) => (
        <Measure bounds offset onResize={this.onResize} key={i}>
          {({ measureRef }: any) => (
            <div className={styles.invisible}>
              <PickerWidget
                initialIndex={i}
                list={stepperItems}
                measureRef={measureRef}
                nose={{ up: true }}
                additionalButton={labelStepper.additionalButton}
                icons={icons}
              />
            </div>
          )}
        </Measure>
      ));
    } else {
      pickerWidgets = (
        <PickerWidget
          {...{ ...labelStepper, list: stepperItems }}
          nose={{
            xOffset: labelStepperAnchor.noseOffsetX,
            up: labelStepperAnchor.up,
          }}
          onClick={this.onClick}
          icons={icons}
        />
      );
    }

    const labelStepperX =
      this.props.labelStepper.mouseScreenCoords !== undefined
        ? this.props.labelStepper.mouseScreenCoords.x
        : labelStepperAnchor.refPointCoords.x;
    const labelStepperY =
      this.props.labelStepper.mouseScreenCoords !== undefined
        ? this.props.labelStepper.mouseScreenCoords.y
        : labelStepperAnchor.refPointCoords.y;

    const posX = labelStepperX + labelStepperAnchor.transX - this.props.borderExtensionLeft;
    const posY = labelStepperY + labelStepperAnchor.transY - this.props.borderExtensionTop;

    return (
      <div
        className={styles.absolute}
        style={{
          top: posY,
          left: posX,
          width: this.state.maxWidth,
          height: this.state.maxHeight,
        }}
      >
        {pickerWidgets}
      </div>
    );
  }
}
