import * as React from 'react';
import { CircleShape, RectangleShape, SquareShape } from '../../components/BasicShape';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { type BasicShapeContent, CIRCLE, SQUARE } from '@bettermarks/gizmo-types';

export type BasicShapeProps = BasicShapeContent & ContextState;

/**
 * Render the correct shape component according to the content
 */
export const BasicShape: React.FC<BasicShapeProps> = ({
  shape,
  scalefactor,
  color,
  gradientType,
}) => {
  const props = { scalefactor, color, gradientType };

  switch (shape) {
    case CIRCLE:
      return <CircleShape {...props} />;

    case SQUARE:
      return <SquareShape {...props} />;

    default:
      // RECTANGLE
      return <RectangleShape {...props} />;
  }
};

BasicShape.displayName = 'BasicShape';
