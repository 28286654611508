import { type Action, handleAction } from 'redux-actions';
import {
  ApplicationState,
  DialogType,
  type EndscreenPayload,
  type ErrorPayload,
  LightBoxType,
  type LoaderState,
  type LobLinkReportingSettings,
  ProblemReportedState,
  type SelectedDialog,
} from '../../../../types';
import { Lens, ToolbarElements } from '@bettermarks/gizmo-types';
import { compose } from 'lodash/fp';

import { initialSeriesplayerState } from '../../../store/constants';
import { isOnline } from '../../services/runtime-manager';
import {
  REPORT_PROBLEM_FAILED,
  REPORT_PROBLEM_PROGRESS,
  REPORT_PROBLEM_SUCCESS,
} from '../Dialog/ReportProblem/actions';
import {
  CHANGE_LOADER_STATE,
  CLOSE_DIALOG,
  CLOSE_FEM,
  CLOSE_LIGHTBOX,
  HIDE_CONTENT,
  INTERCEPT_BROWSER_BACK_BUTTON,
  OPEN_DIALOG,
  RESET_APP,
  SET_LTI_REPORTING,
  SET_REPORT_LOBLINK_SETTINGS,
  SHOW_EMPTY_INPUT_DIALOG,
  SHOW_ERROR_DIALOG,
  SHOW_RESULTS,
  SWITCH_TO_OFFLINE,
  SWITCH_TO_ONLINE,
  TO_PREVIOUS_DIALOG,
  WINDOW_RESIZED,
} from './actions';
import { register, type ShortcutsMap, unregister } from '../../../../gizmo-utils/keyboard';
import {
  REGISTER_SHORTCUTS,
  SHOW_FEM,
  type ShowFEMPayload,
  UNREGISTER_SHORTCUTS,
  type UnregisterShortcutsPayload,
} from '../../../../gizmo-utils/redux/gizmoActions';
import { type ResizePayload } from './SeriesPlayer';
import { reduceReducers } from '../../../../gizmo-utils/reduceReducers';
import { enterShortcut } from './SeriesPlayerContainer';
import { identity } from '../../../../gizmo-utils/filters';
import { enrichContentDict } from '../../../../gizmo-utils/measure';
import { stylers } from '../../../../gizmo-utils/configuration/stylers';
import { rulers } from '../../../../gizmo-utils/configuration/rulers';
import { shortcuts } from '../../../../gizmos/formula/Formula/FormulaContainer';

const recoverFromEmptyInput = compose(
  (state: ApplicationState) => ({
    ...state,
    shortcuts: compose(
      register(
        ApplicationState.toCurrentExercise.get(state)
          ? ApplicationState.toSelectedRefId(state).get(state)
          : undefined,
        shortcuts
      ),
      register(undefined, enterShortcut)
    )(state.shortcuts),
  }),
  (state: ApplicationState) => ({
    ...Lens.update(
      ApplicationState.toCurrentQuestion,
      (contentDict) =>
        contentDict &&
        enrichContentDict(
          contentDict,
          stylers,
          rulers,
          false // re-enable all interactive gizmos
        ),
      state
    ),
  })
);

const handleCloseDialog = handleAction(
  CLOSE_DIALOG,
  (state: ApplicationState) => {
    const emptyInputDialogOpen = state.dialog.type === DialogType.emptyUserInput;
    return compose(
      ({ reportProblemState: _, ...rest }) => ({
        ...rest,
        dialog: { type: DialogType.none },
      }),
      emptyInputDialogOpen ? recoverFromEmptyInput : identity
    )(state);
  },
  initialSeriesplayerState
);

const handleOpenDialog = handleAction(
  OPEN_DIALOG,
  (state: ApplicationState, { payload: dialog }: Action<SelectedDialog>) => {
    const emptyInputDialogOpen = state.dialog.type === DialogType.emptyUserInput;
    return compose(
      (state: ApplicationState) => ({
        ...state,
        dialog,
      }),
      emptyInputDialogOpen ? recoverFromEmptyInput : identity
    )(state);
  },
  initialSeriesplayerState
);

const handleCloseFEM = handleAction(
  CLOSE_FEM,
  (state: ApplicationState) => ({
    ...state,
    inBookNavigation: undefined,
  }),
  initialSeriesplayerState
);

const handleToPreviousDialog = handleAction(
  TO_PREVIOUS_DIALOG,
  (state: ApplicationState) => ({
    ...state,
    previousDialog: { type: DialogType.none as const },
    dialog: state.previousDialog,
  }),
  initialSeriesplayerState
);

function constructInBookNavigation(femId: string, searchParamsString: string) {
  const params = new URLSearchParams(searchParamsString);

  return params.get('inBook') === 'true'
    ? {
        femId,
      }
    : undefined;
}

const handleShowFEM = handleAction(
  SHOW_FEM,
  (state: ApplicationState, { payload }: Action<ShowFEMPayload>) => ({
    ...state,
    previousDialog: state.dialog,
    dialog: {
      type: DialogType.fem as const,
      payload: {
        id: payload.id,
        ...(payload.onCloseFromWhiteboard
          ? { onCloseFromWhiteboard: payload.onCloseFromWhiteboard }
          : {}),
      },
    },
    inBookNavigation: constructInBookNavigation(payload.id, payload.search),
  }),
  initialSeriesplayerState
);

const handleCloseLightbox = handleAction(
  CLOSE_LIGHTBOX,
  (state: ApplicationState) => {
    return {
      ...state,
      lightBoxType: LightBoxType.none,
    };
  },
  initialSeriesplayerState
);

const handleShowResult = handleAction(
  SHOW_RESULTS,
  (state: ApplicationState, { payload }: Action<EndscreenPayload>) => {
    return {
      ...state,
      dialog: { type: DialogType.endscreen as const, payload },
    };
  },
  initialSeriesplayerState
);

const handleSwitchToOffline = handleAction(
  SWITCH_TO_OFFLINE,
  (state: ApplicationState) => {
    const runtimeState = {
      ...state.runtimeState,
      isOnline: isOnline(),
    };
    return {
      ...state,
      runtimeState,
    };
  },
  initialSeriesplayerState
);

const handleSwitchToOnline = handleAction(
  SWITCH_TO_ONLINE,
  (state: ApplicationState) => {
    const runtimeState = {
      ...state.runtimeState,
      isOnline: isOnline(),
    };
    return {
      ...state,
      runtimeState,
    };
  },
  initialSeriesplayerState
);

const handleWindowResized = handleAction(
  WINDOW_RESIZED,
  (state: ApplicationState, { payload }: Action<ResizePayload>) => {
    const runtimeState = {
      ...state.runtimeState,
      availableWidth: payload.availableWidth,
      availableHeight: payload.availableHeight,
    };
    return {
      ...state,
      runtimeState,
    };
  },
  initialSeriesplayerState
);

const handleChangeLoaderState = handleAction(
  CHANGE_LOADER_STATE,
  (state: ApplicationState, { payload: loaderState }: Action<LoaderState>) => {
    return {
      ...state,
      loaderState,
    };
  },
  initialSeriesplayerState
);

const handleResetApp = handleAction(
  RESET_APP,
  () => initialSeriesplayerState,
  initialSeriesplayerState
);

const handleReportProblemSuccess = handleAction(
  REPORT_PROBLEM_SUCCESS,
  (state: ApplicationState) => {
    return {
      ...state,
      reportProblemState: ProblemReportedState.success,
    };
  },
  initialSeriesplayerState
);

const handleReportProblemFailed = handleAction(
  REPORT_PROBLEM_FAILED,
  (state: ApplicationState) => {
    return {
      ...state,
      reportProblemState: ProblemReportedState.failed,
    };
  },
  initialSeriesplayerState
);

const handleReportProblemProgress = handleAction(
  REPORT_PROBLEM_PROGRESS,
  (state: ApplicationState) => {
    return {
      ...state,
      reportProblemState: ProblemReportedState.progress,
    };
  },
  initialSeriesplayerState
);

const handleRegisterShortcuts = handleAction(
  REGISTER_SHORTCUTS,
  (state: ApplicationState, action: Action<ShortcutsMap>) => ({
    ...state,
    // prevent registering keyboard shortcuts, when the user selects an input field
    // while the calculator is open
    shortcuts:
      ApplicationState.toToolbarOpenDrawerName.get(state) !== ToolbarElements.calculator
        ? register(
            ApplicationState.toCurrentExercise.get(state)
              ? ApplicationState.toSelectedRefId(state).get(state)
              : undefined,
            action.payload,
            state.shortcuts
          )
        : state.shortcuts,
  }),
  initialSeriesplayerState
);

const handleUnregisterShortcuts = handleAction(
  UNREGISTER_SHORTCUTS,
  (state: ApplicationState, action: Action<UnregisterShortcutsPayload>) => ({
    ...state,
    shortcuts: unregister(
      action.payload.gizmoId || ApplicationState.toSelectedRefId(state).get(state),
      action.payload.shortcuts,
      state.shortcuts
    ),
  }),
  initialSeriesplayerState
);

const handleShowErrorDialog = handleAction(
  SHOW_ERROR_DIALOG,
  (state: ApplicationState, { payload }: Action<ErrorPayload>) => ({
    ...state,
    dialog: { type: DialogType.error as const, payload },
  }),
  initialSeriesplayerState
);

const handleShowEmptyInputDialog = handleAction(
  SHOW_EMPTY_INPUT_DIALOG,
  (state: ApplicationState) =>
    compose((state) => ({
      ...state,
      dialog: { type: DialogType.emptyUserInput as const },
      shortcuts: compose(
        unregister(
          // unregister formula's keyboard shortcuts
          // to avoid students entering stuff while "empty input" dialog is up
          ApplicationState.toCurrentExercise.get(state)
            ? ApplicationState.toSelectedRefId(state).get(state)
            : undefined,
          shortcuts
        ),
        unregister(
          // unregister enter shortcut
          // to avoid submitting again with enter key while "empty input" dialog is up
          undefined,
          enterShortcut
        )
      )(state.shortcuts),
    }))(state),
  initialSeriesplayerState
);

const handleInterceptBrowserBackButton = handleAction(
  INTERCEPT_BROWSER_BACK_BUTTON,
  (state: ApplicationState) => ({
    ...state,
    interceptBrowserBackButton: true,
  }),
  initialSeriesplayerState
);

const handleHideContent = handleAction(
  HIDE_CONTENT,
  (state: ApplicationState) => ({
    ...state,
    hideContent: true,
  }),
  initialSeriesplayerState
);

const handleLobLinkReportingSettings = handleAction(
  SET_REPORT_LOBLINK_SETTINGS,
  (state: ApplicationState, { payload }: Action<LobLinkReportingSettings>) => ({
    ...state,
    lobLinkReportingSettings: payload,
  }),
  initialSeriesplayerState
);

const handleLti = handleAction(
  SET_LTI_REPORTING,
  (state: ApplicationState) => ({
    ...state,
    ltiReporting: true,
  }),
  initialSeriesplayerState
);

export const seriesPlayerReducer = reduceReducers(
  handleCloseDialog,
  handleOpenDialog,
  handleShowFEM,
  handleCloseFEM,
  handleCloseLightbox,
  handleToPreviousDialog,
  handleShowResult,
  handleSwitchToOffline,
  handleSwitchToOnline,
  handleWindowResized,
  handleChangeLoaderState,
  handleResetApp,
  handleReportProblemSuccess,
  handleReportProblemFailed,
  handleReportProblemProgress,
  handleRegisterShortcuts,
  handleUnregisterShortcuts,
  handleShowErrorDialog,
  handleShowEmptyInputDialog,
  handleHideContent,
  handleInterceptBrowserBackButton,
  handleLobLinkReportingSettings,
  handleLti
);
