import * as React from 'react';
import { type GraphRendererProps, GraphRenderer } from '../GraphRenderer';
import { type BubbleNode, type GraphNode, Position } from '@bettermarks/gizmo-types';
import { compact } from 'lodash/fp';
import {
  PREVIEW_NODE,
  TOUCH_OFFSET,
  TOUCH_OFFSET_MOVE_THRESHOLD,
  MIN_NODE_RADIUS,
  MIN_NODE_DIAMETER,
} from '@bettermarks/importers';
import { getWorldPos, useDOMEvent, clampPosition, coveredNodes } from '../helpers';
import { withHoverHighlight } from './withHoverHighlight';
import { Maybe } from '../../../../utils/maybe';
import {
  startScrolling,
  stopScrolling,
  type DragScrollableProps,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export type AddNodeProps = GraphRendererProps &
  DragScrollableProps & {
    onAddNode?: (position: Position) => void;
  };

/**
 * AddNode: Graph editor mode handling the creation and placing of a new node to the graph. It works
 * slightly different for desktop and mobile devices.
 * - On desktop a preview node will be under your mouse cursor while you move over the working area.
 *   You can place it with a click
 * - On mobile devices the preview will only appear while you drag with your finger and will be left
 *   where you end the touch.
 */
const AddNode_: React.FC<AddNodeProps> = (props) => {
  const { onAddNode, nodes, width, height, scrollBehaviour } = props;

  const spanEl = React.useRef<HTMLSpanElement>(null);
  /**
   * The preview node to be shown
   */
  const [preview, setPreview] = React.useState<BubbleNode | null>(null);
  /**
   * Scroll behaviour that scrolls if you drag inside a left or right sensitive area.
   */
  const maybeScrollBehaviour = Maybe(scrollBehaviour);

  const [startPosition, setStartPosition] = React.useState<Position>();

  const r = MIN_NODE_RADIUS;

  /**
   * If we don't cover any other nodes, trigger the add node action.
   */
  const addNode = () => {
    if (preview) {
      const covered = coveredNodes(preview.position, MIN_NODE_DIAMETER)(nodes);
      if (covered.length === 0) {
        onAddNode && onAddNode(clampPosition(preview.position, r, width - r, r, height - r));
      }
    }
    setPreview(null);
  };

  /**
   * If we don't cover any other node, set the preview node to it's new position.
   */
  const updatePreview = (pos: Position) => {
    const position = getWorldPos(pos.x, pos.y, spanEl);
    const covered = coveredNodes(position, MIN_NODE_DIAMETER)(nodes);
    if (covered.length === 0) {
      setPreview({
        ...PREVIEW_NODE,
        id: 'preview',
        position: clampPosition(position, r, width - r, r, height - r),
      });
      return;
    }
  };

  /*--------------------------------------------------------------------------------*
   *                              Event Handlers                                    *
   *--------------------------------------------------------------------------------*/

  const onMouseMove = (e: React.MouseEvent<any>) => {
    updatePreview({ x: e.clientX, y: e.clientY });
  };

  const onTouchMove = (e: TouchEvent) => {
    const touchPosition = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY,
    };
    const dragDistance = startPosition ? Position.distance(startPosition, touchPosition) : 0;
    const offset = dragDistance >= TOUCH_OFFSET_MOVE_THRESHOLD ? TOUCH_OFFSET : { x: 0, y: 0 };
    const nodePosition = Position.add(touchPosition, offset);
    updatePreview(nodePosition);
    e.preventDefault();
  };

  const onTouchStart = (e: TouchEvent) => {
    maybeScrollBehaviour.ap(startScrolling);
    const touchPosition = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY,
    };
    setStartPosition(touchPosition);
    updatePreview(touchPosition);
    e.preventDefault();
  };

  const onTouchEnd = (e: TouchEvent) => {
    maybeScrollBehaviour.ap(stopScrolling);
    setStartPosition(undefined);
    addNode();
    e.preventDefault();
  };

  const onMouseEnter = () => {
    scrollBehaviour && scrollBehaviour.startScrolling();
  };

  const onMouseLeave = () => {
    scrollBehaviour && scrollBehaviour.stopScrolling();
  };

  useDOMEvent('touchstart', onTouchStart, spanEl, [preview, nodes, scrollBehaviour]);
  useDOMEvent('touchmove', onTouchMove, spanEl, [preview, nodes, scrollBehaviour]);
  useDOMEvent('touchend', onTouchEnd, spanEl, [preview, nodes, scrollBehaviour]);

  return (
    <span
      ref={spanEl}
      role="button"
      onMouseDown={(e) => addNode()}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <GraphRenderer
        {...props}
        // all nodes without highlight + the preview node
        nodes={compact([...nodes.map<GraphNode>((n) => ({ ...n, highlight: false })), preview])}
      />
    </span>
  );
};

AddNode_.displayName = 'AddNode';

export const AddNode = withHoverHighlight(AddNode_);
