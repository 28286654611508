import { type ApplyStyles } from '../../gizmo-utils/configuration';
import { type ListSelectionContent } from '@bettermarks/gizmo-types';

import { type Ruler } from '../../gizmo-utils/configuration';
import { boundingLayoutMetrics, getMaximumHeightFromChildren } from '../formula/Formula';

export const applyListSelectionStyles: ApplyStyles = ({ items }: ListSelectionContent, style) =>
  items.map(({ $refid: refId }) => ({ refId, style }));

export const measureListSelectionGizmo: Ruler<ListSelectionContent> = (
  computedStyles,
  content,
  getMetrics
) => {
  const metrics = content.items.map((item) => getMetrics(item.$refid));

  // we might want to consider taking the height of the currently selected item and not the max
  // from all children, see: https://github.com/bettermarks/bm-toolbox/pull/4451#discussion_r1052427658
  const height = getMaximumHeightFromChildren(metrics);

  const { refLine } = boundingLayoutMetrics(metrics);

  return { height, refLine, relativeToBaseLine: true };
};
