import {
  type FElement,
  type FunctionDerivative,
  type FunctionParameter,
  type FunctionType,
  FUNCTION_DEFAULTS,
  identity,
  PARAMETER_DEFAULTS,
  type ParametricFunction,
} from '@bettermarks/gizmo-types';
import { defaultTo, isEmpty, isNaN, isNil, isNumber, omitBy, pickBy } from 'lodash';

export const importFunction = (
  el: FElement,
  { type, x } = el.attributesToProps(identity, [], ['type', 'x'])
): ParametricFunction => ({
  ...FUNCTION_DEFAULTS,
  ...omitBy(
    {
      type: el.text.trim() as FunctionType,
      derivative: type as FunctionDerivative,
      x,
    },
    isNil
  ),
});

const isValid = (v: string) => (isNumber(v) && !isNaN(v)) || (!isEmpty(v) && !isNil(v));

export const importFunctionParameters = (el: FElement): FunctionParameter[] =>
  el.getChildrenByTagName('value').map(
    (p: FElement): FunctionParameter => ({
      ...PARAMETER_DEFAULTS,
      ...pickBy<Partial<FunctionParameter>>(
        {
          name: p.attribute('name'),
          refId: p.attribute('semantics-ref-id'),
          value: defaultTo(parseFloat(p.text), 0),
        },
        isValid
      ),
    })
  );
