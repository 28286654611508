import * as React from 'react';
import { GraphRenderer, type GraphRendererProps } from '../GraphRenderer';
import {
  type ContentColor,
  type GraphNode,
  isBubbleNode,
  NodeOptionType,
} from '@bettermarks/gizmo-types';
import { withHoverHighlight } from './withHoverHighlight';
import { onPickEvent, useDOMEvent } from '../helpers';

export type ColorNodeProps = GraphRendererProps & {
  color: ContentColor;
  touch?: boolean;
  onColorNode?: (id: string) => void;
};

/**
 * ColorNode: Graph editor mode to color nodes in one color. There is one coloring tool for each
 * color, so this mode only applies one color.
 */

const ColorNode_: React.FC<ColorNodeProps> = (props) => {
  const { onColorNode, edges, nodes, color } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  /*--------------------------------------------------------------------------------*
   *                              Event Handlers                                    *
   *--------------------------------------------------------------------------------*/

  /**
   * Immediately color the selected node if the node's color is different.
   */
  const onNodeStart = (node: GraphNode) => {
    if (
      isBubbleNode(node) &&
      node.id &&
      node.addedByUser &&
      node.content &&
      node.content.type === NodeOptionType.colorChooser &&
      color !== node.content.selected
    ) {
      onColorNode && onColorNode(node.id);
    }
  };

  useDOMEvent(
    'touchstart',
    onPickEvent<TouchEvent>(ref, edges, nodes, onNodeStart, undefined, undefined, false),
    ref,
    [nodes, edges]
  );

  const preprocessedNodes = nodes.map((n) => ({
    ...n,
    highlight: !n.addedByUser ? false : n.highlight,
  }));

  return (
    <div ref={ref} role="button" onClick={onPickEvent(ref, edges, nodes, onNodeStart)}>
      <GraphRenderer {...props} nodes={preprocessedNodes} />
    </div>
  );
};

ColorNode_.displayName = 'ColorNode';

export const ColorNode = withHoverHighlight(ColorNode_);
