import { type BMAction } from '../../gizmo-utils/types';
import { type Action, handleAction } from 'redux-actions';
import { initialSeriesplayerState } from '../store/constants';
import { type ApplicationState, ContentType, EMStatus } from '../../types';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { EM_LOADING_STARTED, FEM_LOADED, HANDLE_EM_ERROR, KEM_LOADED } from './actions';
import { reduceReducers } from '../../gizmo-utils/reduceReducers';
import { type StateWithHistory } from 'redux-undo';

export type FEMLoadedPayload = {
  femId: string;
  contentDict: StateWithHistory<ContentDict>;
};

export const enum EMKind {
  fem = 'fem',
  kem = 'kem',
}

const handleEMLoadingStarted = handleAction(
  EM_LOADING_STARTED,
  (state: ApplicationState, { payload: kind }: Action<EMKind>) => ({
    ...state,
    emData: {
      ...state.emData,
      kem: {
        ...(kind === EMKind.kem ? { data: state.emData.kem.data } : state.emData.kem),
      },
      fems: {
        ...(kind === EMKind.fem ? { data: state.emData.fems.data } : state.emData.fems),
      },
    },
  }),
  initialSeriesplayerState
);

const handleKEMLoaded = handleAction(
  KEM_LOADED,
  (state: ApplicationState, { payload: kem }: Action<StateWithHistory<ContentDict>>) => ({
    ...state,
    emData: {
      ...state.emData,
      kem: {
        ...state.emData.kem,
        data: kem,
        status: EMStatus.loaded,
      },
      currentContent: {
        type: ContentType.KEM,
      },
    },
  }),
  initialSeriesplayerState
);

const handleFEMLoaded = handleAction(
  FEM_LOADED,
  (state: ApplicationState, { payload }: Action<FEMLoadedPayload>) => ({
    ...state,
    emData: {
      ...state.emData,
      fems: {
        ...state.emData.fems,
        data: {
          ...state.emData.fems.data,
          [payload.femId]: payload.contentDict,
        },
        status: EMStatus.loaded,
      },
      currentContent: {
        type: ContentType.FEM,
        id: payload.femId,
      },
    },
  }),
  initialSeriesplayerState
);

const handleError = handleAction(
  HANDLE_EM_ERROR,
  (state: ApplicationState, { payload: kind }: BMAction<EMKind>) => ({
    ...state,
    emData: {
      ...state.emData,
      kem: {
        ...state.emData.kem,
        ...(kind === EMKind.kem && { status: EMStatus.error }),
      },
      fems: {
        ...state.emData.fems,
        ...(kind === EMKind.fem && { status: EMStatus.error }),
      },
    },
  }),
  initialSeriesplayerState
);

export const emReducer = reduceReducers(
  handleEMLoadingStarted,
  handleKEMLoaded,
  handleFEMLoaded,
  handleError
);
