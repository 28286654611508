import { numberFromStyles } from '@bettermarks/gizmo-types';
import styles from './components/forms/FormStyles.scss';

export const MIN_NUMBER_OF_SEGMENTS = 1;
export const MAX_NUMBER_OF_SEGMENTS = 12;

export const CIRCLE_WIDTH_LARGE = 190;
export const CIRCLE_WIDTH_SMALL = 130;

export const CIRCLE_LARGE = {
  radius: CIRCLE_WIDTH_LARGE / 2,
  cx: CIRCLE_WIDTH_LARGE / 2 + 0.5,
  cy: CIRCLE_WIDTH_LARGE / 2 + 0.5,
};

export const CIRCLE_SMALL = {
  radius: CIRCLE_WIDTH_SMALL / 2,
  cx: CIRCLE_WIDTH_SMALL / 2 + 0.5,
  cy: CIRCLE_WIDTH_SMALL / 2 + 0.5,
};

export const RECT_WIDTH_LARGE = 200;
export const RECT_WIDTH_SMALL = 150;

export const RECT_LARGE = {
  width: RECT_WIDTH_LARGE,
  height: (RECT_WIDTH_LARGE * 3) / 4,
};

export const RECT_SMALL = {
  width: RECT_WIDTH_SMALL,
  height: (RECT_WIDTH_SMALL * 3) / 4,
};

/**
 * Standard scenario:
 *
 * formula = form form ...
 *
 * With the specified min widths for "large rendering" of the forms it is ensured that
 * X forms will switch to "small rendering" before breaking to the next line
 */
export const FORMULA_WIDTH = 60;
export const MIN_LARGE_CIRCLE = CIRCLE_WIDTH_LARGE;
export const MIN_LARGE_RECTANGLE = RECT_WIDTH_LARGE;

export const FORM_MARGIN = numberFromStyles(styles.FORM_MARGIN);
