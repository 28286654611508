import * as React from 'react';
import { type TFunction } from 'i18next';
import { isEmpty, merge } from 'lodash';
import { Marker } from '../../components';
import {
  CAP_ARROW_REF_PIXEL,
  DEFAULT_ANGLE_DECORATION,
  DEFAULT_ANGLE_LEG_DECORATION,
  DEFAULT_ANGLE_RADIUS,
  degToRad,
  getStrokeWidth,
  polarToCartesian,
  RIGHT_ANGLE_NOTATION_DOT,
  RIGHT_ANGLE_NOTATION_SQUARE,
  RIGHT_ANGLE_POINT_RADIUS,
  RIGHT_ANGLE_SQUARE_SIZE_FACTOR,
  scale,
  worldToScreen,
} from '@bettermarks/importers';
import {
  type AngleBase,
  CAP_T_ARROW,
  type Coords,
  type GeoConfiguration,
  LineCapStyleToMarkerType,
} from '@bettermarks/gizmo-types';
import { getObjectColor } from './decorations';
import { Line } from '.';
import { useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';

export type AngleProps = AngleBase & {
  borderRectId: string;
  id: string;
  matrix: number[];
  configuration: GeoConfiguration;
};

/**
 * Returns the coordinates of a point on the angle bisector
 * @param {Coords} point Point where the angle is rendered
 * @param {number} distanceFromPoint distance of the new point on the bisector from the angle point
 * @param {number} startAngle
 * @param {number} endAngle
 * @param {number[]} matrix
 * @return {Coords} Coordinates of the point on the angle bisector
 */
export const getCoordsOnAngleBisector = (
  point: Coords,
  distanceFromPoint: number,
  startAngle: number,
  endAngle: number,
  matrix: number[]
): Coords => {
  const start = startAngle > endAngle ? startAngle - 360 : startAngle;
  const angleBisector = (degToRad(start) + degToRad(endAngle)) / 2;
  return worldToScreen(matrix)(polarToCartesian(point, distanceFromPoint, angleBisector));
};

/**
 * returns the border of the square notation of right angle
 *
 * @param {Coords} point Point where the angle is rendered
 * @param {number} radius Radius of the arc (length of the diagonal of the square)
 * @param {number} startAngle
 * @param {number} endAngle
 * @param {number[]} matrix
 * @return {string} path definition for the square notation
 */
function getRightAngleSquareNotationPath(
  p: Coords,
  radius: number,
  startAngle: number,
  endAngle: number,
  matrix: number[]
): string {
  const edgeOfRightAngleSquare = getLengthOfAngleLeg(radius, true);
  const [s, e] = [startAngle, endAngle].map((a) =>
    polarToCartesian(p, edgeOfRightAngleSquare, degToRad(a))
  );
  const m = { x: s.x + e.x - p.x, y: s.y + e.y - p.y };
  const [ss, es, ds] = [s, e, m].map(worldToScreen(matrix));

  return ['M', ss.x, ss.y, 'L', ds.x, ds.y, 'L', es.x, es.y].join(' ');
}

/**
 * returns the border of the angle arc
 *
 * @param {Coords} point Point where the angle is rendered
 * @param {number} radius Radius of the arc
 * @param {number} startAngle
 * @param {number} endAngle
 * @param {number[]} matrix
 * @param {string | undefined} rightAngleNotation notation based on localization
 * @param {string} capB Arrow cap style (bottom)
 * @param {string} capT Arrow cap style (top)
 * @return {string} path definition for the arc border
 */
export const getArcBorderPath = (
  point: Coords,
  radius: number,
  startAngle: number,
  endAngle: number,
  matrix: number[],
  rightAngleNotation?: string,
  capB?: string,
  capT?: string
): string => {
  if (rightAngleNotation === RIGHT_ANGLE_NOTATION_SQUARE) {
    return getRightAngleSquareNotationPath(point, radius, startAngle, endAngle, matrix);
  }

  const angleSize = startAngle < endAngle ? endAngle - startAngle : 360 - startAngle + endAngle;

  const arcSweep = angleSize <= 180 ? '0' : '1';
  const scaledRadius = scale(matrix, true)(radius);

  // shorten the arc by CAP_ARROW_REF_PIXELS pixels (or by CAP_T_ARROW_REF_PIXELS pixels) ...
  const tb = (isEmpty(capB) || capB === CAP_T_ARROW ? 0 : CAP_ARROW_REF_PIXEL) / scaledRadius;
  const tt = (isEmpty(capT) || capT === CAP_T_ARROW ? 0 : CAP_ARROW_REF_PIXEL) / scaledRadius;

  const start = worldToScreen(matrix)(polarToCartesian(point, radius, degToRad(startAngle) + tt));
  const end = worldToScreen(matrix)(polarToCartesian(point, radius, degToRad(endAngle) - tb));

  return [
    'M',
    start.x,
    start.y,
    'A',
    scaledRadius,
    scaledRadius,
    0,
    arcSweep,
    0,
    end.x,
    end.y,
  ].join(' ');
};

/**
 * returns the circular sector of the angle
 *
 * @param {Coords} point Point where the angle is rendered
 * @param {number} radius Radius of the arc
 * @param {number} startAngle
 * @param {number} endAngle
 * @param {Coords} startCoords Point where the angle arc starts
 * @param {number[]} matrix
 * @param {string | undefined} rightAngleNotation notation based on localization
 * @return {string} path definition for the angle arc
 */
export const getArcPath = (
  point: Coords,
  radius: number,
  startAngle: number,
  endAngle: number,
  startCoords: Coords,
  matrix: number[],
  rightAngleNotation?: string
): string => {
  const { x, y } = worldToScreen(matrix)(point);
  const start = worldToScreen(matrix)(startCoords);
  return [
    getArcBorderPath(point, radius, startAngle, endAngle, matrix, rightAngleNotation),
    'L',
    x,
    y,
    'L',
    start.x,
    start.y,
  ].join(' ');
};

function getRightAngleDotCoords(
  point: Coords,
  radius: number,
  startAngle: number,
  endAngle: number,
  matrix: number[]
) {
  const { x, y } = getCoordsOnAngleBisector(point, radius / 2, startAngle, endAngle, matrix);
  return { cx: x, cy: y };
}

/**
 * Returns the type of right angle notation as per the localization if the angle is 90 degrees
 *
 * @param  {TFunction} t
 * @param  {boolean | udefined} isRightAngle
 * @return {string | udefined}
 */
function getRightAngleNotation(t: TFunction, isRightAngle?: boolean): string | undefined {
  if (isRightAngle) {
    return t ? t('angle.rightAngle') : RIGHT_ANGLE_NOTATION_SQUARE;
  }
}

function getLengthOfAngleLeg(radius: number, isSquareNotation: boolean): number {
  return isSquareNotation ? (RIGHT_ANGLE_SQUARE_SIZE_FACTOR * radius) / Math.SQRT2 : radius;
}

export const Angle: React.FC<AngleProps> = ({
  borderRectId,
  coords,
  decoration,
  endAngle,
  hasAngleLegs,
  id,
  isRightAngle,
  matrix,
  radius = DEFAULT_ANGLE_RADIUS,
  startAngle,
  configuration: { tickValueInterval },
}) => {
  const t = useContentTranslation();
  // for the calculation of the arc,
  // we need to make the radius invariant with regards to the tickValueInterval
  const tvInvariantMatrix = matrix.map(
    (el, i) => [el * tickValueInterval.x, el, el, el * tickValueInterval.y, el, el][i]
  );
  const tvInvariantCoords = {
    x: coords.x / tickValueInterval.x,
    y: coords.y / tickValueInterval.y,
  };

  const angleDecoration = merge(
    { ...DEFAULT_ANGLE_DECORATION },
    !isEmpty(decoration) ? decoration : {}
  );
  const angleLegDecoration = merge(
    { ...DEFAULT_ANGLE_LEG_DECORATION },
    decoration ? { color: decoration.color, lineWeight: decoration.lineWeight } : {}
  );
  const fillColor = getObjectColor(angleDecoration, 'fillColor');
  const [capT, capB] = ['lineCapStyleTop', 'lineCapStyleBottom'].map((d) =>
    !isRightAngle && d in angleDecoration ? angleDecoration[d] : null
  );
  const [mIdT, mIdB] = [capT, capB].map((d) => (d !== null ? `marker-${d}-${id}` : null));

  const rightAngleNotation = getRightAngleNotation(t, isRightAngle);

  const lengthOfAngleLeg = getLengthOfAngleLeg(
    radius,
    rightAngleNotation === RIGHT_ANGLE_NOTATION_SQUARE
  );
  const start = polarToCartesian(coords, lengthOfAngleLeg, degToRad(startAngle));
  const end = polarToCartesian(coords, lengthOfAngleLeg, degToRad(endAngle));

  // tickValueInterval invariance for the arc calculation again
  const tvInvariantStart = {
    x: start.x / tickValueInterval.x,
    y: start.y / tickValueInterval.y,
  };
  return (
    <g clipPath={`url(#${borderRectId})`} pointerEvents={'none'}>
      {/* For the capStyleTop arrow*/}
      {capT && mIdT && (
        <Marker
          id={mIdT}
          type={LineCapStyleToMarkerType[capT as string]}
          color={angleDecoration.color}
          flip={true}
          strokeWidth={getStrokeWidth(angleDecoration)}
          offset={CAP_ARROW_REF_PIXEL}
        />
      )}
      {/* For the capStyleBottom arrow*/}
      {capB && mIdB && (
        <Marker
          id={mIdB}
          type={LineCapStyleToMarkerType[capB]}
          color={angleDecoration.color}
          strokeWidth={getStrokeWidth(angleDecoration)}
          offset={CAP_ARROW_REF_PIXEL}
        />
      )}
      {/* For the angle arc*/}
      <path
        d={getArcPath(
          tvInvariantCoords,
          radius,
          startAngle,
          endAngle,
          tvInvariantStart,
          tvInvariantMatrix,
          rightAngleNotation
        )}
        fill={fillColor}
        fillOpacity={angleDecoration.fillTransparency}
        strokeWidth={0}
      />
      <path
        d={getArcBorderPath(
          tvInvariantCoords,
          radius,
          startAngle,
          endAngle,
          tvInvariantMatrix,
          rightAngleNotation,
          capB,
          capT
        )}
        stroke={getObjectColor(angleDecoration)}
        fillOpacity={0}
        strokeWidth={getStrokeWidth(angleDecoration)}
        clipPath={`url(#${borderRectId})`}
        markerEnd={mIdB ? `url(#${mIdB})` : ''}
        markerStart={mIdT ? `url(#${mIdT})` : ''}
      />
      {/* For the point inside the angle sector for right angles*/}
      {rightAngleNotation === RIGHT_ANGLE_NOTATION_DOT && (
        <circle
          {...getRightAngleDotCoords(
            tvInvariantCoords,
            radius,
            startAngle,
            endAngle,
            tvInvariantMatrix
          )}
          r={RIGHT_ANGLE_POINT_RADIUS}
          fill={getObjectColor(angleDecoration)}
          stroke={getObjectColor(angleDecoration)}
          strokeWidth={getStrokeWidth(angleDecoration)}
        />
      )}
      {/* For the angle legs*/}
      {hasAngleLegs && (
        <g>
          <Line
            {...{ id: `${id}_start`, matrix, borderRectId }}
            x1={coords.x}
            y1={coords.y}
            x2={start.x}
            y2={start.y}
            decoration={angleLegDecoration}
          />
          <Line
            {...{ id: `${id}_end`, matrix, borderRectId }}
            x1={coords.x}
            y1={coords.y}
            x2={end.x}
            y2={end.y}
            decoration={angleLegDecoration}
          />
        </g>
      )}
    </g>
  );
};

Angle.displayName = 'Angle';
