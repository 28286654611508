import * as React from 'react';
import { compose, isNil } from 'lodash/fp';
import { type MapDispatchToPropsFunction } from 'react-redux';

import {
  type CommonCallbacks,
  type Content,
  type ContentDict,
  GeoEditorMode,
  GRAPH,
  Lens,
  METRICS_POLYGONSELECTION,
  METRICS_SYSTEM,
  type ModeSelectTool,
} from '@bettermarks/gizmo-types';

import { type ContextState, type GizmoProps } from '../polymorphic-gizmo';
import { type GizmoShortcutsMap, withShortcutsOnSelection } from '../keyboard';
import { GizmoContext } from '../polymorphic-gizmo/GizmoProvider';
import { InteractiveGizmo } from './InteractiveGizmo';

const isTouchLens = Lens.create<ContextState>('isTouch');
const selectedModeLens = Lens.create<Content>('selectedMode');
const toolLens = Lens.create<Content>('tool');
const toolModesLens = Lens.compose(toolLens, Lens.create<ModeSelectTool>('modes'));

const INTERACTION_TYPES_TO_NORMALIZE: Set<string | undefined> = new Set([
  METRICS_SYSTEM,
  METRICS_POLYGONSELECTION,
  GRAPH,
]);

export const normalizeGeoGizmoProps = <GizmoContent extends Content>(
  props: GizmoContent & GizmoProps & ContextState
) => {
  let normalizedProps = props;

  const isTouch = isTouchLens.get(props);
  const tool = toolLens.get(props);

  if (INTERACTION_TYPES_TO_NORMALIZE.has(props.$interactionType) && !isTouch && !isNil(tool)) {
    const modes = (toolModesLens.get(props) as GeoEditorMode[]).filter(
      (mode: GeoEditorMode) => mode !== GeoEditorMode.SCROLL
    );
    const selectedMode = selectedModeLens.get(props);

    normalizedProps = compose(
      selectedModeLens.set(selectedMode === GeoEditorMode.SCROLL ? modes[0] : selectedMode),
      toolModesLens.set(modes)
    )(props) as GizmoContent & GizmoProps & ContextState;
  }

  return normalizedProps;
};

/**
 * Type definition for mapContentDictToProps.
 * MapContentDictToProps can be used by gizmos to optionally pass
 * mapping of their to props to contentDict. Helps to get parent props based on children.
 * This definition computes the correct severity for FieldSet.
 * @param {TContent} content type of Gizmo
 * @param {ContentDict} contentDict
 * @return Partial<TContent> partial properties of TContent which are mapped to contentDict
 */
export type MapContentDictToProps<TContent extends Content, Props = TContent> = (
  content: TContent,
  contentDict: ContentDict
) => Partial<Props>;

/**
 * connectGizmo;
 *
 * This a custom redux connect function for creating a gizmo container.
 *
 * It makes the following assumptions about Gizmos:
 * - its props are a union of the gizmo specific Content type and the (optional) `Callbacks` type
 *   (no `refId` in the props of the Gizmo)
 * - the internally created mapStateToProps uses the `GizmoProps.refid`
 *   to get the state of the correct `content` from the `Exercise`
 * - Each Gizmo might need the possibility to acquire metrics (height, refLine) of its children
 *   to know how to align the children based on the provided content data
 *   -> each gizmo can access those values via the getMetricsForRefId method
 *
 * Both type parameters don't need to be provided, as they can be inferred from the arguments.
 *
 * @param {MapDispatchToPropsParam<Callbacks, GizmoProps>} mapDispatchToProps
 *        For connecting dispatch to gizmo callbacks
 * @param {Component<GizmoContent & Callbacks>} gizmo
 *        Component to be converted to container.
 * @param options
 *        Each gizmo can optionally pass the mapContentDictToProps definition if some props
 *        of gizmo depends on other gizmos from the content dictionary. E.g. FieldSet
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const connectGizmo = <GizmoContent extends Content, Callbacks = {}>(
  mapDispatchToProps: MapDispatchToPropsFunction<Callbacks, GizmoProps>,
  gizmo: React.ComponentType<GizmoContent & Callbacks & CommonCallbacks & ContextState>,
  options?: {
    mapContentDictToProps?: MapContentDictToProps<GizmoContent, GizmoProps>;
    shortcuts?: GizmoShortcutsMap;
  }
): React.FC<GizmoContent & GizmoProps> => {
  const Gizmo = withShortcutsOnSelection(gizmo, options && options.shortcuts);

  return (props: GizmoContent & GizmoProps & ContextState) => (
    <GizmoContext.Consumer>
      {({ dispatch, contentDict }) => (
        <InteractiveGizmo
          contentDict={contentDict}
          dispatch={dispatch}
          gizmoContentProps={normalizeGeoGizmoProps(props)}
          mapDispatchToProps={mapDispatchToProps}
          options={options}
        >
          {Gizmo}
        </InteractiveGizmo>
      )}
    </GizmoContext.Consumer>
  );
};
