import * as React from 'react';
import classNames from 'classnames';
import { isString } from 'lodash';

import { type MTokenProps, type SelectChildHandler } from '../types';
import { onClickLeaf } from '../helpers';

import styles from './tokens.scss';

export type MNProps = MTokenProps & {
  onSelect?: SelectChildHandler;
};

export const MN: React.FC<MNProps> = ({ children, computedStyles, interactive, onSelect }) => (
  <span
    className={classNames(styles.mn, { [styles.interactive]: interactive })}
    style={computedStyles}
  >
    {interactive && isString(children)
      ? children.split('').map((c, i) => (
          <span key={i} role="button" onMouseDown={onSelect && onClickLeaf(onSelect.bind(null, i))}>
            {c}
          </span>
        ))
      : children}
  </span>
);

MN.displayName = 'MN';
