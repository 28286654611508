import * as React from 'react';
import { CIRCLE_LARGE, CIRCLE_SMALL } from '../../../constants';
import { Circle } from './Circle';
import { type WholeProps } from '../types';

export const WholePie: React.FC<WholeProps> = ({ filled, small }) => (
  // a padding of a 1/10 of the circle diameter is accounted for the width & height calculation
  <Circle
    width={(small ? CIRCLE_SMALL.cx : CIRCLE_LARGE.cx) * 2}
    height={(small ? CIRCLE_SMALL.cy : CIRCLE_LARGE.cy) * 2}
    filled={filled}
    hover={false}
    small={small}
  />
);
