import * as React from 'react';
import { ToolbarButton, type ToggleProps } from '../../ToolbarButton';
import { DragAndDropHideIcon, DragAndDropShowIcon } from '../../icons';

export const DragAndDropToggle: React.FC<ToggleProps> = (props) => (
  <ToolbarButton {...props} kind="toggle">
    <DragAndDropHideIcon />
    <DragAndDropShowIcon />
  </ToolbarButton>
);
