import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { ApplicationState } from '../../../../../types';
import { closeDialog } from '../../SeriesPlayer/actions';
import { Hints, type HintsDispatchProps, type HintsStateProps } from './Hints';

const mapDispatchToProps = (dispatch: Dispatch): HintsDispatchProps => ({
  onCancel: () => dispatch(closeDialog()),
  dispatch,
});

const mapStateToProps = (state: ApplicationState): HintsStateProps => {
  const currentStep = ApplicationState.toCurrentStep.get(state);
  const { isTouch } = state.runtimeState;
  return {
    hints: currentStep && currentStep.hints,
    supports: currentStep && currentStep.supports,
    isTouch,
    staticMediaUrl: state.appSettings.staticMediaUrl,
  };
};

export const HintsContainer = connect(mapStateToProps, mapDispatchToProps)(Hints);
