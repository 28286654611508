import * as React from 'react';
import { Key } from '../Keyboard/Key';
import { CalculatorLayout, type CalculatorProps } from './types';
import { SPECIAL_KEYS_FIXED, SPECIAL_KEYS_STRETCHED } from './CalculatorLayouts';

import styles from './CalculatorKeyboard.scss';

export const CalculatorSpecialKeysBlock: React.FC<CalculatorProps> = ({ onKey, layout }) => (
  <div
    className={
      layout === CalculatorLayout.VERTICAL
        ? styles.specialKeysBlockFixed
        : styles.specialKeysBlockStretched
    }
  >
    {(layout === CalculatorLayout.VERTICAL ? SPECIAL_KEYS_FIXED : SPECIAL_KEYS_STRETCHED).map(
      (operator) => (
        <Key key={operator} keyCode={operator} iconProps={{ onKey, type: 'dark' }} />
      )
    )}
  </div>
);

CalculatorSpecialKeysBlock.displayName = 'CalculatorSpecialKeysBlock';
