import * as React from 'react';
import log from 'loglevel';
import { isNil } from 'lodash';
import classNames from 'classnames';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';

import {
  type Content,
  type ContentDict,
  type ContentReference,
  isTableCell,
} from '@bettermarks/gizmo-types';
import { combinedKey } from '../configuration/combinedKey';
import { type GizmoRegistry } from '../configuration/types';
import { DATA_ATTRIBUTE_CONTENT_ID, DATA_ATTRIBUTE_GIZMO_ID } from '../constants';
import { GizmoContext } from './GizmoProvider';

import styles from './styles.scss';
import { useValueSetterContext, type ValueSetterMap } from './ValueSetterProvider';

const isInteractiveTableCell = (content: Content) =>
  content.$interactionType && isTableCell(content.$renderStyle);

export type GizmoProps = {
  readonly refid: string;
  readonly availableWidth: number;
  /**
   * Some Gizmos want to specify a lineHeight when rendering children.
   * Since PolymorphicGizmo resets the lineHeight to 0 to prevent the wrapping `div`
   * having an effect on the rendering you can set this prop allow the PolymorphicGizmo
   * to inherit the lineHeight the usual CSS way.
   *
   * This property can be removed when we finally removed the `div` and replaced it with a `</>`
   * (fragment).
   */
  readonly keepLineHeight?: true;
  valueSetterMap?: ValueSetterMap;
};

export const DUMMY_GIZMO_PROPS = {
  refid: 'DUMMY',
  availableWidth: 700,
};

/**
 * This type describes the additional props that a Gizmo receives which are not part of the
 * imported content.
 */
export type ContextState = Readonly<{
  /**
   * used to measure alien children (ContentReferences)
   *
   * This property is passed down from one gizmo to another, each getting the remaining available
   * width.
   */
  readonly availableWidth: number;
  /**
   * Actual values come from ValueSetterProvider added by a parent component
   * (e.g. a dynamic-representation gizmo)
   *
   * Consumer inside PolymorphicGizmo always comes with empty map as default value.
   * Optional since some gizmo components are used outside Consumer,
   * but contain ContextState in type definition of props
   */
  readonly valueSetterMap?: ValueSetterMap;
  /**
   * used to disable help tools in gizmos (currently only FEM links)
   */
  readonly hideHelpTools?: boolean;
  /**
   * Whether the current gizmo is the "selected" one, i.e., received focus and not lost it to
   * another gizmo.
   */
  readonly selected?: boolean;
  /**
   * This comes from the RuntimeState in the ApplicationState: is this a touch device or not?
   */
  readonly isTouch?: boolean;
}>;

type InternalGizmoProps = Readonly<{
  gizmoProps: GizmoProps;
  gizmos: GizmoRegistry;
  contentDict: ContentDict;
  hideHelpTools?: boolean;
  isTouch: boolean;
  selectedGizmoRef?: React.RefObject<HTMLDivElement>;
  valueSetterMap?: ValueSetterMap;
}>;

/**
 * Extract the content node from the gizmo props.
 */
const contentFromProps = ({ contentDict, gizmoProps: { refid } }: InternalGizmoProps) =>
  contentDict && contentDict[refid];

/**
 * This internal class is created so that we can have the shouldComponentUpdate lifecycle hook.
 * This is because the consumer of the react Context API bypassed its parent's
 * shouldComponentUpdate.
 */
class _PolymorphicGizmo extends React.Component<InternalGizmoProps> {
  // this can be enabled for debugging
  // componentDidCatch(error: any, info: any) {
  //   log.error(`
  //     PolymorphicGizmo error: ${error},
  //     info: ${JSON.stringify(info)},
  //     refid: ${this.props.gizmoProps.refid}
  //   `);
  // }

  shouldComponentUpdate(nextProps: InternalGizmoProps) {
    const nextContent = contentFromProps(nextProps);

    return (
      nextContent !== undefined &&
      (contentFromProps(this.props) !== nextContent ||
        !shallowEqual(this.props.gizmoProps, nextProps.gizmoProps) ||
        !shallowEqual(this.props.valueSetterMap, nextProps.valueSetterMap))
    );
  }

  render() {
    const { gizmoProps, gizmos, hideHelpTools, isTouch, selectedGizmoRef, valueSetterMap } =
      this.props;

    const attributes: any = {
      [DATA_ATTRIBUTE_GIZMO_ID]: gizmoProps.refid,
      className: styles.gizmoError,
    };

    const content = contentFromProps(this.props);

    if (!content) {
      log.warn({
        message: 'No content for refId',
        extra: { refid: gizmoProps.refid },
      });
      return null;
    }
    if (content.hidden) {
      return null;
    }

    const key = combinedKey(content);
    const Gizmo = gizmos && gizmos[key];

    if (content.$id) {
      // TODO: remove id once image tests can deal with it,
      // since it is not guaranteed to be unique
      attributes.id = content.$id;
      attributes[DATA_ATTRIBUTE_CONTENT_ID] = content.$id;
    }

    if (isNil(Gizmo)) {
      log.warn({
        message: `No gizmo found for "${key}"`,
        extra: { refid: gizmoProps.refid },
      });
      return null;
    }

    return (
      <div
        {...attributes}
        ref={content.selected ? selectedGizmoRef : undefined}
        className={classNames({
          [styles.resetLineHeight]: !gizmoProps.keepLineHeight,
          [styles.tableCell]: isInteractiveTableCell(content),
        })}
      >
        <Gizmo
          {...content}
          {...gizmoProps}
          isTouch={isTouch}
          {...(hideHelpTools && { hideHelpTools })}
          {...(valueSetterMap && { valueSetterMap })}
        />
      </div>
    );
  }
}

/**
 * This component resolves the ValueSetter context and Gizmo context and gives the
 * props to the Polymorphic Gizmo component.
 * @param props
 * @constructor
 */
export const PolymorphicGizmo: React.FC<GizmoProps> = (props: GizmoProps) => {
  const gizmoContextProps = React.useContext(GizmoContext);
  const valueSetterContext = useValueSetterContext();
  const { valueSetterMap } = props.valueSetterMap ? props : valueSetterContext;

  return (
    <_PolymorphicGizmo gizmoProps={props} {...gizmoContextProps} valueSetterMap={valueSetterMap} />
  );
};

PolymorphicGizmo.displayName = 'PolymorphicGizmo';

// According to React's documentation it is possible to return arrays from SFC:
// https://reactjs.org/docs/jsx-in-depth.html#jsx-children
// unfortunately TS does not yet know
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
// otherwise this would be possible:
// export const renderGizmos: React.FC<ReadonlyArray<ContentReference> | undefined> = refs =>
export const renderGizmos = (
  refs: ReadonlyArray<ContentReference> | undefined,
  availableWidth: number
) =>
  refs
    ? refs.map(({ $refid }) => (
        <PolymorphicGizmo refid={$refid} key={$refid} availableWidth={availableWidth} />
      ))
    : [];
