import { xmlParse, xsltProcess } from 'xslt-processor';
import {
  type FElement,
  type Importer,
  RS,
  toXmlElement,
  type GeoContent,
} from '@bettermarks/gizmo-types';
import { importGeo } from '../geo/importer';

const xsl = `
<?xml version="1.0"?>
<xsl:stylesheet
  version="1.0"
  xmlns:xsl="http://www.w3.org/1999/XSL/Transform"
  xmlns:g="http://bettermarks.com/mathcore/geometry"
  xmlns:d="http://bettermarks.com/mathcore/draw2d" >

  <!-- We will need to lookup position coordinates later! -->
  <xsl:variable name="posLookup" select="semantics/mrow/d:scene/d:position-set/d:position"/>

  <!-- The root template -->
  <xsl:template match="/">
    <xsl:apply-templates select="semantics/mrow/d:scene"/>
  </xsl:template>


  <!-- The d:scene transformation -->
  <xsl:template match="semantics/mrow/d:scene">

    <xsl:variable name="zoom">
      <xsl:call-template name="decoration-value">
        <xsl:with-param name="decoration" select="../@decoration" />
        <xsl:with-param name="key" select="'zoom'" />
        <xsl:with-param name="default" select="1" />
      </xsl:call-template>
    </xsl:variable>
    <!-- width and height attributes with both default 1 -->
    <xsl:variable name="d2dW">
      <xsl:call-template name="default-to">
        <xsl:with-param name="value" select="@width" />
        <xsl:with-param name="default" select="1" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="d2dH">
      <xsl:call-template name="default-to">
        <xsl:with-param name="value" select="@height" />
        <xsl:with-param name="default" select="1" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="d2dMaxWH">
      <xsl:call-template name="max">
        <xsl:with-param name="x" select="$d2dW" />
        <xsl:with-param name="y" select="$d2dH" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="scale" select="$zoom * 20" />
    <xsl:variable name="width" select="$scale * ($d2dW div $d2dMaxWH)" />
    <xsl:variable name="height" select="$scale * ($d2dH div $d2dMaxWH)" />
    <xsl:variable name="tickValueInterval" select="1 div $scale " />
    <xsl:variable name="scaledTickValueInterval" select="$scale * ($tickValueInterval)" />

    <semantics>
      <g:system key="draw2dToGeo" xmlns:g="http://bettermarks.com/mathcore/geometry">
        <g:configuration>
          <!-- let the width and height be a little bit more than 1 to fit the figure nicely innto the frame -->
          <g:display width="{$width * 1.04}" height="{$height * 1.06}" x="{$width * 0.5}" y="{-($height * 0.5)}" />
          <g:tickValueInterval x="{$tickValueInterval}" y="{$tickValueInterval}"/>
          <g:showBorder>false</g:showBorder>
          <g:addBorderExtension/>
        </g:configuration>

        <g:layer type="invisible-set">
          <xsl:apply-templates select="d:position-set/d:position"/>
        </g:layer>

        <g:layer type="point-set">
          <xsl:apply-templates select="d:point"/>
        </g:layer>

        <g:layer type="polygon-set">
          <xsl:apply-templates select="d:fill"/>
        </g:layer>

        <g:layer type="segment-set">
          <xsl:apply-templates select="d:segment"/>
        </g:layer>

        <g:layer type="circle-set">
          <xsl:apply-templates select="d:circle"/>
        </g:layer>

        <g:layer type="readinghelp-set">
          <xsl:apply-templates select="d:angle">
            <xsl:with-param name="tickValueInterval" select="$scaledTickValueInterval" />
          </xsl:apply-templates>
        </g:layer>

        <g:layer type="label-set">
          <xsl:apply-templates select="d:segment/d:label">
            <xsl:with-param name="tickValueInterval" select="$tickValueInterval" />
          </xsl:apply-templates>
          <xsl:apply-templates select="d:point/d:label" />
          <xsl:apply-templates select="d:circle/d:label" />
          <xsl:apply-templates select="d:angle/d:label">
            <xsl:with-param name="tickValueInterval" select="$tickValueInterval" />
          </xsl:apply-templates>
        </g:layer>

      </g:system>
      <annotation-xml encoding="bettermarks">
        <special id="draw2d" render-style="metrics-system"/>
      </annotation-xml>
    </semantics>
  </xsl:template>


  <!-- 'd:position-set/d:position' -> 'g:invisible-set/g:point' -->
  <xsl:template match="d:position-set/d:position">
    <g:point id="{d:id}" x="{d:x}" y="{-d:y}"/>
  </xsl:template>


  <!-- d:point -> 'g:point-set/g:point' (only visible, if 'alpha' is set!) -->
  <xsl:template match="d:point">
    <xsl:variable name="curPosId" select="d:positionId"/>
    <xsl:variable name="x" select="$posLookup[d:id=$curPosId]/d:x"/>
    <xsl:variable name="y" select="-$posLookup[d:id=$curPosId]/d:y"/>
    <!-- decide about visibility of points is a little bit tricky (using 'alpha')... -->
    <xsl:choose>
      <xsl:when test="not(contains(d:decoration, 'alpha:0')) and contains(d:decoration, 'alpha')">
        <g:point id="{d:id}" x="0" y="0" decoration="{d:decoration};">
          <xsl:attribute name="x"><xsl:value-of select="$x"/></xsl:attribute>
          <xsl:attribute name="y"><xsl:value-of select="$y"/></xsl:attribute>
        </g:point>
      </xsl:when>
      <xsl:otherwise></xsl:otherwise>
    </xsl:choose>
  </xsl:template>


  <!-- d:fill -> g:polygon -->
  <xsl:template match="d:fill">
    <!-- the modified 'geoable' decoration -->
    <xsl:variable name="newDecoration">
      <xsl:call-template name="fill-decorations">
        <xsl:with-param name="decoration" select="d:decoration" />
      </xsl:call-template>
    </xsl:variable>
    <!-- do not draw polygon lines by default (color:bm-transparent) -->
    <g:polygon id="{d:id}" decoration="color:bm-transparent; {$newDecoration}">
      <g:refIds>
        <xsl:for-each select="d:positionIds/d:positionId">
          <xsl:value-of select="."/>
          <xsl:if test="position()!=last()">
            <xsl:text>;</xsl:text>
          </xsl:if>
        </xsl:for-each>
      </g:refIds>
    </g:polygon>
  </xsl:template>


  <!-- d:segment -> g:segment -->
  <xsl:template match="d:segment">
    <!-- the modified 'geoable' decoration -->
    <xsl:variable name="newDecoration">
      <xsl:call-template name="segment-decorations">
        <xsl:with-param name="decoration" select="d:decoration" />
      </xsl:call-template>
    </xsl:variable>
    <g:segment id="{d:id}" decoration="line-weight:thin; {$newDecoration}">
      <g:position refid="{d:from}"/>
      <g:position refid="{d:to}"/>
    </g:segment>
  </xsl:template>


  <!-- d:circle -> g:circle -->
  <xsl:template match="d:circle">
    <xsl:variable name="curPosId" select="d:positionId"/>
    <xsl:variable name="x" select="$posLookup[d:id=$curPosId]/d:x"/>
    <xsl:variable name="y" select="-$posLookup[d:id=$curPosId]/d:y"/>
    <xsl:variable name="newDecoration">
      <xsl:call-template name="circle-decorations">
        <xsl:with-param name="decoration" select="d:decoration" />
      </xsl:call-template>
    </xsl:variable>

    <g:circle id="{d:id}" radius="{d:radius}" decoration="line-weight:thin; {$newDecoration}">
      <xsl:attribute name="x"><xsl:value-of select="$x"/></xsl:attribute>
      <xsl:attribute name="y"><xsl:value-of select="$y"/></xsl:attribute>
    </g:circle>
  </xsl:template>


  <xsl:template match="d:segment/d:label">
    <xsl:param name="tickValueInterval" select="1000"/>
    <xsl:call-template name="label">
      <xsl:with-param name="refidFromParent" select="'true'" />
      <xsl:with-param name="customPosition" select="'true'" />
      <xsl:with-param name="tickValueInterval" select="$tickValueInterval" />
    </xsl:call-template>
  </xsl:template>

  <xsl:template match="d:circle/d:label">
    <xsl:call-template name="label">
      <xsl:with-param name="refidFromParent" select="'true'" />
    </xsl:call-template>
  </xsl:template>

  <xsl:template match="d:point/d:label">
    <xsl:call-template name="label" />
  </xsl:template>


  <!-- d:label -> g:label -->
  <xsl:template name="label">
    <xsl:param name="refidFromParent" select="'false'"/>
    <xsl:param name="customPosition" select="'false'" />
    <xsl:param name="tickValueInterval" select="1000"/>

    <!-- for creating a unique ID, we need the ID of the parent! -->
    <xsl:variable name="parentId" select="../d:id"/>
    <xsl:variable name="curPosId">
      <xsl:choose>
        <xsl:when test="d:positionId"><xsl:value-of select="d:positionId"/></xsl:when>
        <xsl:otherwise><xsl:value-of select="../d:positionId"/></xsl:otherwise>
      </xsl:choose>
    </xsl:variable>
    <xsl:variable name="refid">
      <xsl:choose><xsl:when test="$refidFromParent='true'"><xsl:value-of select="$parentId"/></xsl:when>
        <xsl:otherwise><xsl:value-of select="$curPosId"/></xsl:otherwise>
      </xsl:choose>
    </xsl:variable>
    <!-- position values are just used to generate unique label Ids!!! -->
    <xsl:variable name="dx" select="d:direction/d:x" />
    <xsl:variable name="dy" select="d:direction/d:y" />
    <xsl:variable name="lx" select="$posLookup[d:id=$curPosId]/d:x + $dx"/>
    <xsl:variable name="ly" select="-$posLookup[d:id=$curPosId]/d:y - $dy"/>
    <xsl:variable name="alignment">
      <xsl:call-template name="label-alignment">
        <xsl:with-param name="dx" select="$dx" />
        <xsl:with-param name="dy" select="$dy" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="offsetx">
      <xsl:call-template name="decoration-value">
        <xsl:with-param name="decoration" select="../d:decoration" />
        <xsl:with-param name="key" select="'label-offset-x'" />
        <xsl:with-param name="default" select="0" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="offsety">
      <xsl:call-template name="decoration-value">
        <xsl:with-param name="decoration" select="../d:decoration" />
        <xsl:with-param name="key" select="'label-offset-y'" />
        <xsl:with-param name="default" select="0" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="length">
      <xsl:call-template name="default-to">
        <xsl:with-param name="value">
          <xsl:call-template name="sqrt">
            <xsl:with-param name="x">
              <xsl:value-of select="$dx * ($dx * 1.0) + $dy * ($dy * 1.0)" />
            </xsl:with-param>
          </xsl:call-template>
        </xsl:with-param>
        <xsl:with-param name="default" select="1" />
      </xsl:call-template>
    </xsl:variable>

    <!-- Unfortunately, sometimes label ids in the original XML are not unique! So, .... -->
    <g:label
      id="label_{$parentId}_{$curPosId}_{$lx}_{$ly}"
      align="{$alignment}"
      distance="{$length * 0.4}"
    >
      <g:position refid="{$refid}">
        <xsl:choose>
          <xsl:when test="$customPosition='true'">
            <xsl:attribute name="x">
              <xsl:value-of
                select="$posLookup[d:id=$curPosId]/d:x + $offsetx * ($tickValueInterval div 27)"/>
            </xsl:attribute>
            <xsl:attribute name="y">
              <xsl:value-of
                select="-$posLookup[d:id=$curPosId]/d:y - $offsety * ($tickValueInterval div 27)"/>
            </xsl:attribute>
          </xsl:when>
          <xsl:otherwise></xsl:otherwise>
        </xsl:choose>
      </g:position>
      <xsl:apply-templates  select="semantics"/>
    </g:label>
  </xsl:template>


  <!-- d:angle -> g:angle -->
  <xsl:template match="d:angle">
    <xsl:param name="tickValueInterval" />
    <xsl:variable name="curPosId" select="d:positionId"/>
    <xsl:variable name="x" select="$posLookup[d:id=$curPosId]/d:x"/>
    <xsl:variable name="y" select="-$posLookup[d:id=$curPosId]/d:y"/>
    <xsl:variable name="from" select="d:from"/>
    <xsl:variable name="amount" select="d:amount"/>
    <xsl:variable name="isRightAngle">
      <xsl:if test="round($amount)=90"><xsl:value-of select="'true'"/></xsl:if>
    </xsl:variable>
    <xsl:variable name="fillColor">
      <xsl:call-template name="decoration-value">
        <xsl:with-param name="decoration" select="d:decoration" />
        <xsl:with-param name="key" select="'color'" />
        <xsl:with-param name="default" select="'bm-black'" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="angleFillDecoration">
      <xsl:choose>
        <xsl:when test="$isRightAngle='true'"></xsl:when>
        <xsl:otherwise>
          <xsl:value-of select="concat(concat('fill-transparency: 0.2; fillColor:', $fillColor), ';')" />
        </xsl:otherwise>
      </xsl:choose>
    </xsl:variable>
    <xsl:variable name="newDecoration">
      <xsl:call-template name="angle-decorations">
        <xsl:with-param name="decoration" select="d:decoration" />
      </xsl:call-template>
    </xsl:variable>

    <g:angle
      id="{d:id}" amount="{$amount}" isRightAngle="{$isRightAngle}"
      labelOffset="0" radius="{$tickValueInterval}"
      decoration="line-weight:thin; {$angleFillDecoration} {$newDecoration}"
    >
      <xsl:attribute name="x"><xsl:value-of select="$x"/></xsl:attribute>
      <xsl:attribute name="y"><xsl:value-of select="$y"/></xsl:attribute>
      <xsl:attribute name="start"><xsl:value-of select="$from"/></xsl:attribute>
      <xsl:attribute name="end"><xsl:value-of select="$from + $amount"/></xsl:attribute>
    </g:angle>
  </xsl:template>


  <!-- all is special about angle-labels ... -->
  <xsl:template match="d:angle/d:label">
    <xsl:param name="tickValueInterval" />
    <xsl:variable name="parentId" select="../d:id"/>
    <xsl:variable name="curPosId" select="d:positionId"/>
    <xsl:variable name="x" select="$posLookup[d:id=$curPosId]/d:x"/>
    <xsl:variable name="y" select="-$posLookup[d:id=$curPosId]/d:y"/>
    <xsl:variable name="from" select="../d:from"/>
    <xsl:variable name="amount" select="../d:amount"/>
    <xsl:variable name="isRightAngle">
      <xsl:if test="round($amount)=90"><xsl:value-of select="'true'"/></xsl:if>
    </xsl:variable>

    <!-- Labels only for 'non right' angles -->
    <xsl:choose>
      <xsl:when test="$isRightAngle='true'"></xsl:when>
      <xsl:otherwise>
        <g:angle
          id="anglelabel_{$parentId}_{$curPosId}"
          isRightAngle="{$isRightAngle}"
          labelOffset="0" radius="{$tickValueInterval}"
        >
          <xsl:attribute name="x"><xsl:value-of select="$x"/></xsl:attribute>
          <xsl:attribute name="y"><xsl:value-of select="$y"/></xsl:attribute>
          <xsl:attribute name="start"><xsl:value-of select="$from"/></xsl:attribute>
          <xsl:attribute name="end"><xsl:value-of select="$from + $amount"/></xsl:attribute>
          <xsl:apply-templates  select="semantics"/>
        </g:angle>
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>


  <!-- The d:label 'semantics' content used for label contents is just copied ...-->
  <xsl:template match="semantics">
    <g:math xmlns="http://www.w3.org/1998/Math/MathML">
      <semantics>
        <xsl:copy-of select="node()"/>
      </semantics>
    </g:math>
  </xsl:template>


  <!--
  simple 'decoration modification' template ...
  This should have a nice loop over fixed replacement values,
  but our lib does not support this!!!
  -->
  <xsl:template name="all-decorations">
    <xsl:param name="decoration" />
    <xsl:call-template name="string-replace">
      <xsl:with-param name="string" select="$decoration" />
      <xsl:with-param name="replace" select="'alpha'" />
      <xsl:with-param name="with" select="'fill-transparency'" />
    </xsl:call-template>
  </xsl:template>


  <!--
    the special 'fill' decorations
    unchanged decorations: fill-color
  -->
  <xsl:template name="fill-decorations">
    <xsl:param name="decoration" />
    <xsl:call-template name="all-decorations">
      <xsl:with-param name="decoration" select="$decoration" />
    </xsl:call-template>
  </xsl:template>


  <!-- special 'segment' decorations -->
  <xsl:template name="segment-decorations">
    <xsl:param name="decoration" />
    <xsl:variable name="replace1">
      <xsl:call-template name="string-replace">
        <xsl:with-param name="string" select="$decoration" />
        <xsl:with-param name="replace" select="'thickness:0'" />
        <xsl:with-param name="with" select="'line-weight:thin'" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="replace2">
      <xsl:call-template name="string-replace">
        <xsl:with-param name="string" select="$replace1" />
        <xsl:with-param name="replace" select="'thickness:1'" />
        <xsl:with-param name="with" select="'line-weight:thin'" /> <!-- TODO -->
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="replace3">
      <xsl:call-template name="string-replace">
        <xsl:with-param name="string" select="$replace2" />
        <xsl:with-param name="replace" select="'thickness:2'" />
        <xsl:with-param name="with" select="'line-weight:normal'" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="replace4">
      <xsl:call-template name="string-replace">
        <xsl:with-param name="string" select="$replace3" />
        <xsl:with-param name="replace" select="'thickness:3'" />
        <xsl:with-param name="with" select="'line-weight:thick'" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="replace5">
      <xsl:call-template name="string-replace">
        <xsl:with-param name="string" select="$replace4" />
        <xsl:with-param name="replace" select="'thickness:4'" />
        <xsl:with-param name="with" select="'line-weight:thick'" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="replace6">
      <xsl:call-template name="string-replace">
        <xsl:with-param name="string" select="$replace5" />
        <xsl:with-param name="replace" select="'thickness:5'" />
        <xsl:with-param name="with" select="'line-weight:thick'" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:value-of select="$replace6"/>
  </xsl:template>


  <!-- special 'circle' decorations -->
  <xsl:template name="circle-decorations">
    <xsl:param name="decoration" />
    <xsl:variable name="replace1">
      <xsl:call-template name="all-decorations">
        <xsl:with-param name="decoration" select="$decoration" />
      </xsl:call-template>
    </xsl:variable>
    <xsl:call-template name="string-replace">
      <xsl:with-param name="string" select="$replace1" />
      <xsl:with-param name="replace" select="'line-style'" />
      <xsl:with-param name="with" select="'border-style'" />
    </xsl:call-template>
  </xsl:template>


  <!-- special 'angle' decorations -->
  <xsl:template name="angle-decorations">
    <xsl:param name="decoration" />
    <xsl:call-template name="all-decorations">
      <xsl:with-param name="decoration" select="$decoration" />
    </xsl:call-template>
  </xsl:template>


  <!--
    Thx to https://gist.github.com/ijy/6572481:
    ALTERNATIVE SEARCH & REPLACE
    string:     The text to be evaluated
    replace:    The character or string to look for in the above string
    with:       What to replace it with
    Slightly more long winded approach if that's how you prefer to roll.
  -->
  <xsl:template name="string-replace">
    <xsl:param name="string" />
    <xsl:param name="replace" />
    <xsl:param name="with" />
    <xsl:choose>
      <xsl:when test="contains($string, $replace)">
        <xsl:value-of select="substring-before($string, $replace)" />
        <xsl:value-of select="$with" />
        <xsl:call-template name="string-replace">
          <xsl:with-param name="string" select="substring-after($string,$replace)" />
          <xsl:with-param name="replace" select="$replace" />
          <xsl:with-param name="with" select="$with" />
        </xsl:call-template>
      </xsl:when>
      <xsl:otherwise>
        <xsl:value-of select="$string" />
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>


  <!-- template to set a given default, if te according value is null or empty -->
  <xsl:template name="default-to">
    <xsl:param name="value" />
    <xsl:param name="default" />
    <xsl:choose>
      <xsl:when test="not($value) or  $value = ''">
        <xsl:value-of select="$default"/>
      </xsl:when>
      <xsl:otherwise>
        <xsl:value-of select="$value" />
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>


  <!--
   template to get a specific value (by key) from a given decoration
   string "{key1}:{value1};{key2}:{value2};...{key_n}:{value_n}"
   -->
  <xsl:template name="decoration-value">
    <xsl:param name="decoration" />
    <xsl:param name="key" />
    <xsl:param name="default" />
    <xsl:call-template name="default-to">
      <xsl:with-param name="value">
        <xsl:value-of select="substring-before(concat(substring-after($decoration, concat($key, ':')), ';'), ';')"/>
      </xsl:with-param>
      <xsl:with-param name="default" select="$default" />
    </xsl:call-template>
  </xsl:template>


  <!--
  set the alignment of a label depending on a direction (unit) vector given by a tuple (dx, dy)
  -->
  <xsl:template name="label-alignment">
    <xsl:param name="dx" />
    <xsl:param name="dy" />
    <xsl:variable name="length">
      <xsl:call-template name="sqrt">
        <xsl:with-param name="x">
          <xsl:value-of select="$dx * ($dx * 1.0) + $dy * ($dy * 1.0)" />
        </xsl:with-param>
      </xsl:call-template>
    </xsl:variable>
    <xsl:variable name="idx" select="round($dx div $length)" />
    <xsl:variable name="idy" select="-round($dy div $length)" />
    <xsl:choose>
      <xsl:when test="$idx =  1 and $idy =  0"><xsl:value-of select="'right'"/></xsl:when>
      <xsl:when test="$idx = -1 and $idy =  0"><xsl:value-of select="'left'"/></xsl:when>
      <xsl:when test="$idx =  0 and $idy =  1"><xsl:value-of select="'top'"/></xsl:when>
      <xsl:when test="$idx =  1 and $idy =  1"><xsl:value-of select="'right-top'"/></xsl:when>
      <xsl:when test="$idx = -1 and $idy =  1"><xsl:value-of select="'left-top'"/></xsl:when>
      <xsl:when test="$idx =  0 and $idy = -1"><xsl:value-of select="'bottom'"/></xsl:when>
      <xsl:when test="$idx =  1 and $idy = -1"><xsl:value-of select="'right-bottom'"/></xsl:when>
      <xsl:when test="$idx = -1 and $idy = -1"><xsl:value-of select="'left-bottom'"/></xsl:when>
      <xsl:otherwise><xsl:value-of select="'center'" /></xsl:otherwise>
    </xsl:choose>
  </xsl:template>


  <!-- our simple max function -->
  <xsl:template name="max">
    <xsl:param name="x"/>
    <xsl:param name="y"/>
    <xsl:choose>
      <xsl:when test="$x &lt; $y">
        <xsl:value-of select="$y"/>
      </xsl:when>
      <xsl:otherwise>
        <xsl:value-of select="$x"/>
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>


  <!-- Newtons method for square root  -->
  <xsl:template name="sqrt">
    <xsl:param name="x" select="0"/>
    <xsl:param name="xc" select="1"/> <!-- the result so far 'xcurrent' -->
    <xsl:param name="iter" select="1"/> <!-- iteration control (with maxiter) -->
    <xsl:param name="maxiter" select="20"/>
    <xsl:choose>
      <xsl:when test="$xc * ($xc * 1.0) = $x or $iter &gt; $maxiter">
        <xsl:value-of select="$xc"/>
      </xsl:when>
      <xsl:otherwise>
        <xsl:call-template name="sqrt">
          <xsl:with-param name="x" select="$x"/>
          <xsl:with-param name="xc" select="$xc - (($xc * ($xc * 1.0) - $x) div ($xc * 2.0))"/>
          <xsl:with-param name="iter" select="$iter + 1"/>
          <xsl:with-param name="maxiter" select="$maxiter"/>
        </xsl:call-template>
      </xsl:otherwise>
    </xsl:choose>
  </xsl:template>

</xsl:stylesheet>
`;

const draw2dToGeo = (draw2dXml: FElement): FElement =>
  toXmlElement(xsltProcess(xmlParse(draw2dXml.toString()), xmlParse(xsl)));

export const importDraw2d: Importer<GeoContent> = (preContent, draw2dXml, context) =>
  importGeo({ ...preContent, $renderStyle: RS.DRAW2DGEO }, draw2dToGeo(draw2dXml), context);

/**
 * As we use a custom Draw2dGeo renderer, and NOT the default Geo renderer to display
 * Draw2dGeo content (which is transformed to Geo content), we need some mechanism, to
 * recognize draw2d content when exported and imported again. We introduce the artificial
 * 'draw2d-geo' render style. This one is used like so:
 *             import                  export                     import
 * ['draw2d'] -------> ['draw2d-geo'] ------> ['draw2d-geo' XML] ------> ['draw2d-geo']
 *                                                  ^                          |
 *                                                  |           export         |
 *                                                  ---------------------------
 */
export const importDraw2dGeo = importGeo;
