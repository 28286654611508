import { createGlobalStyle } from 'styled-components';

// This is necessary to block the "pull-to-refresh" feature of tablets
// See. https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior#preventing_an_underlying_element_from_scrolling
// Note: types are not correctly inferred, so we must type it as any.
//      For more info, see: https://github.com/styled-components/styled-components/issues/3738
// TODO: should be able to remove type `any` when moving to React 18
const DisablePullToRefresh: any = createGlobalStyle`
  html {
    overscroll-behavior: none;
    overflow: hidden;
  }
`;

export default DisablePullToRefresh;
