import log from 'loglevel';
import { get, isNumber } from 'lodash';

import {
  $MSUP,
  type BasefulElement,
  isMO,
  type MathContent,
  type Path,
  ROUND_CLOSE,
} from '@bettermarks/gizmo-types';
import { cursorPath, getContainerInfo, lastPathElements, set } from './cursorHelpers';

const removeCursorFromBase = (input: MathContent, containerPath: Path) => {
  const { container, pos, parent, parentPath } = getContainerInfo<BasefulElement>(
    input,
    containerPath
  );
  const base = container.base;
  const prev = parent[pos - 1];
  const exponent = container.$ === $MSUP ? container.superscript : container.subscript;

  if (pos === 0 || (isMO(prev) && prev.text !== ROUND_CLOSE)) {
    // remove msub/msup (and replace with it's sub-/super-script)
    return set(input, parentPath, [
      ...parent.slice(0, pos),
      exponent.children[0],
      ...exponent.children.slice(1),
      ...parent.slice(pos + 1),
    ]);
  }

  // just remove the cursor and pull the previous element inside the base
  return set(input, parentPath, [
    ...parent.slice(0, pos - 1),
    {
      ...container,
      base: {
        ...base,
        children: [prev],
      },
    },
    ...parent.slice(pos + 1),
  ]);
};

/**
 * Remove the cursor from content without any checks.
 * This is fine when replacing the cursor with content that contains the new cursor.
 *
 * For all other cases use {@link removeCursor}.
 *
 * @param {MathContent} content the content to remove the cursor from.
 * @param {PathElement[]} path the location of the cursor in the content.
 *        Can be passed if it has already been calculated.
 */
export const removeCursorSimple = (
  content: MathContent,
  path = cursorPath(content)
): MathContent => {
  if (path.length === 0) return content;
  const idx = path.pop();
  if (isNumber(idx)) {
    const mrow = get(content, path) as MathContent[];
    return set(content, path, mrow.slice(0, idx).concat(mrow.slice(idx + 1)));
  } else {
    // we can't reuse `path` because it has been modified
    log.error({
      message: 'Not able to remove cursor from path',
      extra: { cursorPath: JSON.stringify(cursorPath(content)) },
    });
  }
  return content;
};

/**
 * Removes the cursor from the superscript or subscript. In the special case where the
 * cursor is the only element in the sup- / superscript of the msub/msup the msub/msup will be
 * replaced by it's base.
 * [x]^[|] -> x
 */
const removeCursorFromExponent = (input: MathContent, containerPath: Path) => {
  const { container, pos, parent, parentPath } = getContainerInfo<BasefulElement>(
    input,
    containerPath
  );
  const base = container.base;
  const exponent = container.$ === $MSUP ? container.superscript : container.subscript;

  if (exponent.children.length === 1) {
    return set(input, parentPath, [
      ...parent.slice(0, pos),
      ...base.children,
      ...parent.slice(pos + 1),
    ]);
  }

  return removeCursorSimple(input, cursorPath(input));
};

/**
 * This function properly removes the cursor and sanitizes the formula
 * according to where the cursor has been removed. E.g. a baseful element
 * (msup/msub) should be either removed or it's predecessors should be put
 * inside the base.
 * @param input our math content
 */
export const removeCursor = (input: MathContent): MathContent => {
  // we just have one special case:
  // - base of an msup/msub -> clear base properly
  const path = cursorPath(input);
  const [containerPos, bucketName /*'children'*/ /*bucketPos*/, , , ...parentPath] =
    lastPathElements(path, 4);
  if (bucketName === 'base') {
    return removeCursorFromBase(input, [...parentPath, containerPos]);
  } else if (bucketName === 'superscript' || bucketName === 'subscript') {
    return removeCursorFromExponent(input, [...parentPath, containerPos]);
  }
  // in all other cases (e.g. no cursor, cursor in MRow/MFenced) :
  return removeCursorSimple(input, path);
};
