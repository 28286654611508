import { concat, each, filter, first, flatten, isNil } from 'lodash';
import {
  cartesian,
  combinationsBy2,
  CONTINUOUS_SNAPPING_MARKER,
  DEFAULT_SNAP_HIGHLIGHT_OBJECTS,
  intersectCircles,
  intersectLineCircle,
  intersectLines,
  inViewbox,
  isPointOnCircle,
  isPointOnLine,
  isValidMap,
  project,
  scale,
  screenToWorld,
  SQR_SNAP_RADIUS,
  squaredEuclidianDistance,
  worldToScreen,
} from '@bettermarks/importers';
import {
  type CircleIdCoords,
  CIRCLES,
  type Coords,
  type GeoConfiguration,
  type GeoScene,
  GRID,
  type IdCoords,
  INVISIBLE,
  type LineIdCoords,
  type LineType,
  POINT,
  RAY,
  SEGMENT,
  type SnapHighlightObjects,
  type SnapObject,
  type SnapPoint,
  SnapType,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import {
  createSnapPoints,
  filterForSnapPoints,
  isInRect,
  snapsToPoint,
  squaredDistToCircle,
} from './snapHelpers';

/**
 * Performs a Snap operation on a given coords set and a given mouse position.
 * @param {number[]} matrix
 * @param {Coords[]} points - list of points to check for being in 'snap' close
 * @param {SnapObject} type - type of points to check (invisible or visible points)
 * @param {number} scale - current zoom factor of the geo
 * @param {number} radius - relevant only for `snapPoints` configuration of circle tool
 *                          (dist between circle center and mouse pos)
 * @returns {Coords}
 */
export const snapPoints =
  (matrix: number[], points: IdCoords[], type: SnapObject, scale: number, radius?: number) =>
  (mousePos: Coords): SnapPoint[] => {
    const sp = filterForSnapPoints(
      points.map((p) => {
        const pScreen = worldToScreen(matrix)(p.coords);
        return {
          id: p.id,
          coords: pScreen,
          dist: isNil(radius)
            ? squaredEuclidianDistance(pScreen, mousePos)
            : squaredDistToCircle(pScreen, mousePos, radius),
        };
      }),
      scale
    );

    return createSnapPoints(sp, type);
  };

/**
 * Performs a Snap operation on a given line object set and a given mouse position.
 *
 * @param {number[]} matrix
 * @param {IdCoords[]} lines
 * @param {string} lineType
 * @param {number} scale, current zoom factor of the geo
 * @returns {Coords}
 */
export const snapLines =
  (matrix: number[], lines: LineIdCoords[], lineType: LineType, scale: number) =>
  (mousePos: Coords): SnapPoint[] => {
    const feet: IdCoords[] = lines.map((s) => ({
      id: s.id,
      coords: project(
        mousePos,
        s.coords.map((c: Coords) => worldToScreen(matrix)(c)) as [Coords, Coords],
        lineType
      ),
    }));

    const snapIdCoords = feet
      .map((f) => ({ ...f, dist: squaredEuclidianDistance(mousePos, f.coords) }))
      .sort((a, b) => a.dist - b.dist)
      .find((f) => snapsToPoint(f.dist, scale));

    return snapIdCoords ? createSnapPoints([snapIdCoords], lineType) : [];
  };

export const snapCircles =
  (matrix: number[], circles: CircleIdCoords[], scaleFactor: number) =>
  (mousePos: Coords): SnapPoint[] => {
    type IdCoordsDist = { coords: Coords; dist: number; id: string };
    const snapIdCoords = circles
      .map((c: CircleIdCoords) => ({
        ...c,
        coords: worldToScreen(matrix)(c.coords),
        radius: scale(matrix)(c.radius),
      }))
      .map((circle: CircleIdCoords) => {
        const intersect: Coords[] = intersectLineCircle(
          [circle.coords, mousePos],
          [circle.coords, circle.radius],
          RAY
        );

        return intersect.length > 0
          ? {
              coords: intersect[0],
              dist: squaredEuclidianDistance(intersect[0], mousePos),
              id: circle.id,
            }
          : null;
      })
      .filter(
        (intersect: IdCoordsDist) => intersect !== null && snapsToPoint(intersect.dist, scaleFactor)
      )
      .sort((a: IdCoordsDist, b: IdCoordsDist) => a.dist - b.dist)
      .map((p: IdCoordsDist) => ({ coords: p.coords, id: p.id }));

    const nearestSnapPointIdCoords = snapIdCoords[0];
    return nearestSnapPointIdCoords ? createSnapPoints([nearestSnapPointIdCoords], CIRCLES) : [];
  };

/**
 * Performs a Snap operation of a given line object on a given (invisible) point object set.
 *
 * @param {[Coords, Coords]} line
 * @param {string} lineType
 * @param {IdCoords[]} points
 * @param {IdCoords[]} invPoints
 * @param {number[]} matrix
 * @param {number} scale, current zoom factor of the geo
 * @returns {Coords}
 */
export const snapLineToPoints = (
  line: [Coords, Coords],
  lineType: LineType,
  points: IdCoords[],
  invPoints: IdCoords[],
  matrix: number[],
  scale: number
): SnapPoint[] => {
  // convert line to screen coords (we are always snapping in screen coords)
  const lineScreen = line.map((p) => worldToScreen(matrix)(p)) as [Coords, Coords];
  // first check for snappy visible points (again conversion to screen coords in advance) and
  // then sort them by distance to line (you want to snap to the closest points)
  let snapIdCoords = filterForSnapPoints(
    points.map((p) => {
      const pScreen = worldToScreen(matrix)(p.coords);
      return {
        id: p.id,
        coords: pScreen,
        dist: squaredEuclidianDistance(project(pScreen, lineScreen, lineType), pScreen),
      };
    }),
    scale
  );

  if (snapIdCoords.length > 0) {
    return createSnapPoints(snapIdCoords, POINT);
  }
  // no visible points found? check for invisible points too
  snapIdCoords = filterForSnapPoints(
    invPoints.map((p) => {
      const pScreen = worldToScreen(matrix)(p.coords);
      return {
        id: p.id,
        coords: pScreen,
        dist: squaredEuclidianDistance(project(pScreen, lineScreen, lineType), pScreen),
      };
    }),
    scale
  );
  return snapIdCoords.length > 0 ? createSnapPoints(snapIdCoords, INVISIBLE) : [];
};

/**
 * Performs a Snap operation on a given grid (by vLines and hLines) and a given mouse position.
 */
export const snapGrid =
  (matrix: number[], grid: Coords[], scale: number) =>
  (mousePos: Coords): SnapPoint[] => {
    const toScreen = worldToScreen(matrix);

    const squaredMaxDistance = SQR_SNAP_RADIUS * scale * scale;

    return (
      // continuous snapping ?
      grid && grid[0] === CONTINUOUS_SNAPPING_MARKER
        ? isInRect(
            mousePos.x,
            mousePos.y,
            toScreen(grid[1]).x,
            toScreen(grid[2]).y,
            toScreen(grid[2]).x,
            toScreen(grid[1]).y
          )
          ? createSnapPoints([{ id: '', coords: mousePos }], GRID)
          : []
        : // or grid snapping ?
          createSnapPoints(
            [
              {
                id: '',
                coords: first(
                  grid
                    .map((p) => ({
                      pos: toScreen(p),
                      squaredDistance: squaredEuclidianDistance(toScreen(p), mousePos),
                    }))
                    .filter((o) => o.squaredDistance < squaredMaxDistance)
                    .sort((a, b) => a.squaredDistance - b.squaredDistance)
                    .map((p) => p.pos)
                ),
              },
            ],
            GRID
          )
    );
  };

/**
 * Generic Snap function. Tries to snap to a specific content object for a given mouse pos
 */
export const getSnapPoints =
  (
    matrix: number[],
    scene: GeoScene,
    scale: number,
    circleSnapRadius?: number,
    useGridOnly?: boolean
  ) =>
  (mousePos: Coords): SnapPoint[] => {
    const pointSnapFunctions = [
      snapPoints(matrix, scene.points, POINT, scale, circleSnapRadius),
      snapPoints(matrix, scene.invisibleSnapPoints, INVISIBLE, scale, circleSnapRadius),
    ];

    const objectSnapFunctions = [
      snapLines(matrix, scene.segments, SEGMENT, scale),
      snapLines(matrix, scene.vectors, VECTOR, scale),
      snapLines(matrix, scene.straightlines, STRAIGHTLINE, scale),
      snapLines(matrix, scene.rays, RAY, scale),
      snapCircles(matrix, scene.circles, scale),
    ];

    const gridSnapFunctions = [snapGrid(matrix, scene.snappingGrid, scale)];

    const snapLayerFunctions = useGridOnly
      ? gridSnapFunctions
      : concat(
          pointSnapFunctions,
          scene.snapType === SnapType.continuous ? objectSnapFunctions : [],
          gridSnapFunctions
        );

    let matchingSnapPoints: SnapPoint[] = [];
    each(snapLayerFunctions, (f) => {
      matchingSnapPoints = f(mousePos);
      if (matchingSnapPoints.length > 0) {
        return false; // breaks the each
      }
    });

    return matchingSnapPoints;
  };

/**
 * if snapPoints are existing it returns the first snapPoint, otherwise null
 */

export const getSnapPoint =
  (
    matrix: number[],
    scene: GeoScene,
    scale = 1,
    circleSnapRadius?: number,
    useGridOnly?: boolean
  ) =>
  (mousePos: Coords): SnapPoint | null => {
    const snapPoints = getSnapPoints(matrix, scene, scale, circleSnapRadius, useGridOnly)(mousePos);

    return snapPoints.length > 0 ? snapPoints[0] : null;
  };

/**
 * Gets all invisible intersection points in the scene
 * @param {LineIdCoords[]} segmentsAndVectors
 * @param {LineIdCoords[]} rays
 * @param {LineIdCoords[]} lines
 * @param {CircleIdCoords[]} circles
 * @param {GeoConfiguration} config (to determine if calculated points are in viewbox)
 * @returns {Coords[]}
 */
export const invisibleIntersectionPoints = (
  segmentsAndVectors: LineIdCoords[],
  rays: LineIdCoords[],
  lines: LineIdCoords[],
  circles: CircleIdCoords[],
  config: GeoConfiguration
): IdCoords[] =>
  filter(
    [
      ...(combinationsBy2(segmentsAndVectors).map((sp: [LineIdCoords, LineIdCoords]) => {
        return {
          id: '',
          coords: intersectLines(sp[0].coords, sp[1].coords, isValidMap[`${SEGMENT}-${SEGMENT}`]),
        };
      }) as IdCoords[]),
      ...(combinationsBy2(rays).map((sp: [LineIdCoords, LineIdCoords]) => {
        return {
          id: '',
          coords: intersectLines(sp[0].coords, sp[1].coords, isValidMap[`${RAY}-${RAY}`]),
        };
      }) as IdCoords[]),
      ...(combinationsBy2(lines).map((sp: [LineIdCoords, LineIdCoords]) => {
        return {
          id: '',
          coords: intersectLines(
            sp[0].coords,
            sp[1].coords,
            isValidMap[`${STRAIGHTLINE}-${STRAIGHTLINE}`]
          ),
        };
      }) as IdCoords[]),
      ...(flatten(
        combinationsBy2(circles).map((sp) => {
          const [c1, c2] = sp;
          return intersectCircles([c1.coords, c1.radius], [c2.coords, c2.radius]).map((coords) => ({
            id: '',
            coords: coords,
          }));
        })
      ) as IdCoords[]),
      ...(cartesian(
        segmentsAndVectors.map((s) => s.coords),
        rays.map((l) => l.coords)
      ).map((sp) => {
        return {
          id: '',
          coords: intersectLines(sp[0], sp[1], isValidMap[`${SEGMENT}-${RAY}`]),
        };
      }) as IdCoords[]),
      ...(cartesian(
        segmentsAndVectors.map((s) => s.coords),
        lines.map((l) => l.coords)
      ).map((sp) => {
        return {
          id: '',
          coords: intersectLines(sp[0], sp[1], isValidMap[`${SEGMENT}-${STRAIGHTLINE}`]),
        };
      }) as IdCoords[]),
      ...(cartesian(
        rays.map((r) => r.coords),
        lines.map((l) => l.coords)
      ).map((sp) => {
        return {
          id: '',
          coords: intersectLines(sp[0], sp[1], isValidMap[`${RAY}-${STRAIGHTLINE}`]),
        };
      }) as IdCoords[]),
      ...(flatten(
        cartesian(
          circles,
          segmentsAndVectors.map((s) => s.coords)
        ).map((sp) => {
          const [c, [p, q]] = sp;
          return intersectLineCircle([p, q], [c.coords, c.radius], SEGMENT).map((coords) => ({
            id: '',
            coords,
          }));
        })
      ) as IdCoords[]),
      ...(flatten(
        cartesian(
          circles,
          rays.map((r) => r.coords)
        ).map((sp) => {
          const [c, [p, q]] = sp;
          return intersectLineCircle([p, q], [c.coords, c.radius], RAY).map((coords) => ({
            id: '',
            coords,
          }));
        })
      ) as IdCoords[]),
      ...(flatten(
        cartesian(
          circles,
          lines.map((l) => l.coords)
        ).map((sp) => {
          const [c, [p, q]] = sp;
          return intersectLineCircle([p, q], [c.coords, c.radius], STRAIGHTLINE).map((coords) => ({
            id: '',
            coords,
          }));
        })
      ) as IdCoords[]),
    ],
    (el) => !isNil(el.coords) && inViewbox(el.coords, config.display)
  );

export const getSnapHighlightObjects = (
  snapPoints: SnapPoint[],
  matrix: number[],
  scene: GeoScene
): SnapHighlightObjects => {
  const result: SnapHighlightObjects = { ...DEFAULT_SNAP_HIGHLIGHT_OBJECTS };
  const { rays, segments, straightlines, vectors, circles } = scene;

  snapPoints.forEach((snapPoint) => {
    const snapPointWorld = screenToWorld(matrix)({
      x: snapPoint.x,
      y: snapPoint.y,
    });

    rays.forEach((ray) => {
      if (isPointOnLine(ray.coords, snapPointWorld, isValidMap[RAY])) {
        result[RAY] = [...result[RAY], ray.id];
      }
    });
    segments.forEach((seg) => {
      if (isPointOnLine(seg.coords, snapPointWorld, isValidMap[SEGMENT])) {
        result[SEGMENT] = [...result[SEGMENT], seg.id];
      }
    });
    straightlines.forEach((straightline) => {
      if (isPointOnLine(straightline.coords, snapPointWorld, isValidMap[STRAIGHTLINE])) {
        result[STRAIGHTLINE] = [...result[STRAIGHTLINE], straightline.id];
      }
    });
    vectors.forEach((vec) => {
      if (isPointOnLine(vec.coords, snapPointWorld, isValidMap[SEGMENT])) {
        result[VECTOR] = [...result[VECTOR], vec.id];
      }
    });
    circles.forEach((circle) => {
      if (isPointOnCircle(snapPointWorld, circle.coords, circle.radius)) {
        result[CIRCLES] = [...result[CIRCLES], circle.id];
      }
    });
  });

  return result;
};
