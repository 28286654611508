/**
 * The (negative) amount of pixels that the overscript should be displayed closer to the base
 * in comparison to accent being falsy.
 *
 * Has to work for all kind of streched shapes.
 * We cap at 5 so curly braces don't "touch" the base.
 * Wee floor the value to avoid antialiasing issues.
 */
export const accentCorrection = (accent: boolean | undefined, overscriptHeight: number): number =>
  accent ? -Math.min(overscriptHeight, 5) : 0;
