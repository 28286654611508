import { type ComputedStyles, type FormulaStyles } from '@bettermarks/gizmo-types';
import { getNamedColor } from '@bettermarks/importers';

/**
 * Creates valid styles object that can be used on JSX elements.
 *
 *  - maps bm-color-names to hex color codes
 *  - stops `interactive` prop from being passed to CSS styles
 *
 * @param {FormulaStyles} formulaStyles
 * @returns {ComputedStyles}
 */
export const mapToComputedStyles = ({
  color,
  backgroundColor,
  interactive,
  ...formulaStyles
}: FormulaStyles): ComputedStyles => ({
  ...formulaStyles,
  ...(color ? { color: getNamedColor(color) } : null),
  ...(backgroundColor ? { backgroundColor: getNamedColor(backgroundColor) } : null),
});
