import { type Action } from 'redux';

export const enum ScreenLockType {
  loading = 'loading',
  none = 'none',
}

export interface ScreenLock {
  type: ScreenLockType;
  afterUnlockAction?: Action;
}
