import * as Icon from '../../icons/Keyboard';

export const enum KeyCode {
  Num0 = '0',
  Num1 = '1',
  Num2 = '2',
  Num3 = '3',
  Num4 = '4',
  Num5 = '5',
  Num6 = '6',
  Num7 = '7',
  Num8 = '8',
  Num9 = '9',
  A = 'a',
  B = 'b',
  C = 'c',
  H = 'h',
  M = 'm',
  N = 'n',
  P = 'p',
  Q = 'q',
  R = 'r',
  S = 's',
  T = 't',
  U = 'u',
  V = 'v',
  X = 'x',
  Y = 'y',
  Z = 'z',
  Absolute = 'absolute',
  Add = 'add',
  Ans = 'Ans',
  BracketLeft = 'bracket-left',
  BracketRight = 'bracket-right',
  CaretLeft = 'caret-left',
  CaretRight = 'caret-right',
  Clear = 'clear',
  DecimalPoint = 'formula.decimalpoint',
  Delete = 'delete',
  Divide = 'formula.divisionSign',
  Empty = 'empty',
  Equals = 'equals',
  Exponent = 'exponent',
  Fraction = 'fraction',
  Multiply = 'formula.multiplicationSignFlashFree',
  Pi = 'pi',
  Root = 'root',
  Sqrt = 'sqrt',
  Square = 'square',
  Subtract = 'subtract',
  Toggle = 'toggle',
  XY = 'xy',
}

export type IconMap = Record<string, React.FC | MultiLangIcon>;

export type MultiLangIcon = {
  translate: { [key: string]: React.FC };
};

export const isMultiLangIcon = (icon: any): icon is MultiLangIcon =>
  icon.hasOwnProperty('translate');

const MULT_ICONS: MultiLangIcon = {
  translate: {
    '⋅': Icon.KeyMultiplyDe,
    '×': Icon.KeyMultiplyEn,
  },
};

const CALC_MULT_ICONS: MultiLangIcon = {
  translate: {
    '⋅': Icon.CalcKeyMultiplyDe,
    '×': Icon.CalcKeyMultiplyEn,
  },
};

const DIV_ICONS: MultiLangIcon = {
  translate: {
    ':': Icon.KeyDivideDE,
    '÷': Icon.KeyDivideEN,
  },
};

const CALC_DIV_ICONS: MultiLangIcon = {
  translate: {
    ':': Icon.CalcKeyDivideDe,
    '÷': Icon.CalcKeyDivideEn,
  },
};

const DECIMAL_ICONS: MultiLangIcon = {
  translate: {
    ',': Icon.KeyComma,
    '.': Icon.KeyPoint,
  },
};

export const KEY_ICONS: IconMap = {
  [KeyCode.Num0]: Icon.Key0,
  [KeyCode.Num1]: Icon.Key1,
  [KeyCode.Num2]: Icon.Key2,
  [KeyCode.Num3]: Icon.Key3,
  [KeyCode.Num4]: Icon.Key4,
  [KeyCode.Num5]: Icon.Key5,
  [KeyCode.Num6]: Icon.Key6,
  [KeyCode.Num7]: Icon.Key7,
  [KeyCode.Num8]: Icon.Key8,
  [KeyCode.Num9]: Icon.Key9,
  [KeyCode.A]: Icon.KeyA,
  [KeyCode.B]: Icon.KeyB,
  [KeyCode.C]: Icon.KeyC,
  [KeyCode.H]: Icon.KeyH,
  [KeyCode.M]: Icon.KeyM,
  [KeyCode.N]: Icon.KeyN,
  [KeyCode.P]: Icon.KeyP,
  [KeyCode.Q]: Icon.KeyQ,
  [KeyCode.R]: Icon.KeyR,
  [KeyCode.S]: Icon.KeyS,
  [KeyCode.T]: Icon.KeyT,
  [KeyCode.U]: Icon.KeyU,
  [KeyCode.V]: Icon.KeyV,
  [KeyCode.X]: Icon.KeyX,
  [KeyCode.Y]: Icon.KeyY,
  [KeyCode.Z]: Icon.KeyZ,
  [KeyCode.Absolute]: Icon.KeyAbsolute,
  [KeyCode.Add]: Icon.KeyAdd,
  [KeyCode.Ans]: Icon.KeyAns,
  [KeyCode.BracketLeft]: Icon.KeyBracketLeft,
  [KeyCode.BracketRight]: Icon.KeyBracketRight,
  [KeyCode.CaretLeft]: Icon.KeyCaretLeft,
  [KeyCode.CaretRight]: Icon.KeyCaretRight,
  [KeyCode.DecimalPoint]: DECIMAL_ICONS,
  [KeyCode.Delete]: Icon.KeyDelete,
  [KeyCode.Divide]: DIV_ICONS,
  [KeyCode.Equals]: Icon.CalcKeyEquals,
  [KeyCode.Exponent]: Icon.KeyExponent,
  [KeyCode.Fraction]: Icon.KeyFraction,
  [KeyCode.Multiply]: MULT_ICONS,
  [KeyCode.Pi]: Icon.KeyPi,
  [KeyCode.Root]: Icon.KeyRoot,
  [KeyCode.Sqrt]: Icon.KeyRoot,
  [KeyCode.Square]: Icon.KeySquare,
  [KeyCode.Subtract]: Icon.KeySubtract,
  [KeyCode.Toggle]: Icon.KeyKeyboardHide,
  [KeyCode.XY]: Icon.KeyXY,
};

export const KEY_ICONS_CALCULATOR: IconMap = {
  [KeyCode.Add]: Icon.CalcKeyAdd,
  [KeyCode.Divide]: CALC_DIV_ICONS,
  [KeyCode.Multiply]: CALC_MULT_ICONS,
  [KeyCode.Subtract]: Icon.CalcKeySubtract,
  [KeyCode.Clear]: Icon.CalcKeyClear,
  [KeyCode.Delete]: Icon.CalcKeyDelete,
  [KeyCode.Equals]: Icon.CalcKeyEquals,
};
