import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import {
  boundingLayoutMetrics,
  getMaximumHeightFromChildren,
} from '../formula/Formula/components/helpers';
import { DROPDOWN_PADDING, DROPDOWN_TOP_BOTTOM_MARGIN } from '../../components/Dropdown/constants';
import { type MultipleChoiceDropDownContent } from '@bettermarks/gizmo-types';

export const applyMultipleChoiceDropDownStyles: ApplyStyles = (
  { choices }: MultipleChoiceDropDownContent,
  style
) => choices.map(({ $refid: refId }) => ({ refId, style }));

// Current alignment solution for asymmetric options leads to neighboring content jumps.
// As discussed with Sven, alternative solutions might look it bad after adjusting the content.
// We cannot find any content with asymmetric height with respect to refline.
export const measureMultipleChoiceDropdown: Ruler<MultipleChoiceDropDownContent> = (
  computedStyles,
  content,
  getMetrics
) => {
  const metrics = content.choices.map((choice) => getMetrics(choice.$refid));

  const { refLine: maxRefLine } = boundingLayoutMetrics(metrics);

  // we cannot use height from boundingLayoutMetrics because we are interested in the max
  // height from children.
  const height =
    getMaximumHeightFromChildren(metrics) +
    // Adding padding above/below the content inside option
    2 * DROPDOWN_PADDING +
    // Add margins above/below the dropdown
    2 * DROPDOWN_TOP_BOTTOM_MARGIN;

  const { relativeToBaseLine, refLine } =
    content.checkedIdx !== -1
      ? metrics[content.checkedIdx]
      : { refLine: maxRefLine, relativeToBaseLine: true };

  return { height, refLine, relativeToBaseLine };
};
