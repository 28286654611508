import { get, getOr, some, values } from 'lodash/fp';
import { ApplicationState, StepStatus } from '../../../../types';
import { type InputTool, InputToolTypes, RS, ToolbarElements } from '@bettermarks/gizmo-types';

const disabledStepStatuses: Readonly<Set<Nullable<StepStatus>>> = new Set([
  StepStatus.locked,
  StepStatus.completed,
]);

export const getCurrentTool = (state: ApplicationState): InputTool | undefined => {
  // return {
  //   tool,
  // };

  const content = ApplicationState.toSelectedContent(state).get(state);

  if (content) {
    return content.tool;
  } else {
    return {
      type: InputToolTypes.keyboard,
      layout: {
        brackets: true,
        decimal_point: true,
        //fraction: true,
        letters: true,
        numbers: true,
        operators: true,
      },
      selectedPage: 0,
    };
    // const question = ApplicationState.toCurrentQuestion.get(state);
    // const firstInteractive = question && getFirstInteractiveContent(question);
    //
    // return firstInteractive && firstInteractive[1].tool;
  }
};

const hasDnD = (state: ApplicationState): boolean =>
  some(
    (c) => c && c.$interactionType && c.$interactionType === RS.DRAG_SOURCE,
    values(ApplicationState.toCurrentQuestion.get(state) || {})
  );

const hasCalculator = (state: ApplicationState): boolean =>
  getOr(false, 'series.seriesSettings.calculator', state) ||
  getOr(false, 'calculator', ApplicationState.toCurrentExercise.get(state));

export const showModeSelector = (state: ApplicationState): boolean => {
  const currentStep = ApplicationState.toCurrentStep.get(state);

  if (!currentStep || disabledStepStatuses.has(currentStep.status)) return false;

  const selected = ApplicationState.toSelectedContent(state).get(state);
  return Boolean(selected && selected.tool && selected.tool.type === InputToolTypes.modeSelector);
};

const getActiveTogglesByCond = (
  output: ToolbarElements[],
  [toolbarEl, cond]: [ToolbarElements, boolean]
) => (cond ? [...output, toolbarEl] : output);

/**
 * This is the place where we determine which toolbar elements are visible. This depends on
 * the available gizmos and the selected gizmo's tools as well as the screen size.
 * @param state The current application state
 */
export const getActiveToggles = (state: ApplicationState): ToolbarElements[] => {
  const hasStatus = !disabledStepStatuses.has(
    get('status', ApplicationState.toCurrentStep.get(state))
  );

  const selectedToolType = getOr(
    get('type', getCurrentTool(state)),
    'tool.type',
    ApplicationState.toSelectedContent(state).get(state)
  );

  const elements: [ToolbarElements, boolean][] = [
    [ToolbarElements.keyboard, hasStatus && selectedToolType === InputToolTypes.keyboard],
    [ToolbarElements.calculator, hasStatus && hasCalculator(state)],
    [ToolbarElements.modeSelector, hasStatus && selectedToolType === InputToolTypes.modeSelector],
    [ToolbarElements.dnd, hasStatus && hasDnD(state)],
  ];

  return elements.reduce(getActiveTogglesByCond, []);
};
