import * as React from 'react';
import isEmpty from 'lodash/isEmpty';

import { BettyOops } from '../icons/draft';
import { PdfLinkIcon } from '../icons';
import styles from './PdfLink.scss';
import { Button, ButtonBackground, ButtonKind, ButtonSize } from '@seriesplayer/common-ui';
import { ExcludedFromTabNavigationLink } from '../ExcludedFromTabNavigation';

export type PdfLinkProps = {
  src: string;
  text: string;
  id?: string;
};

export default function PdfLink({ src, id, text }: PdfLinkProps): JSX.Element {
  if (isEmpty(src)) {
    return <BettyOops />;
  }

  return (
    <Button
      size={ButtonSize.m}
      kind={ButtonKind.action}
      background={ButtonBackground.transparent}
      onClick={() => {}}
    >
      <ExcludedFromTabNavigationLink
        className={styles.pdfLinkContainer}
        id={id}
        href={src}
        target={'blank'}
      >
        <PdfLinkIcon width={32} height={32} />
        <span style={{ marginLeft: '5px' }}>{text}</span>
      </ExcludedFromTabNavigationLink>
    </Button>
  );
}

PdfLink.displayName = 'PdfLink';
