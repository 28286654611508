import * as React from 'react';
import classNames from 'classnames';
import Measure, { type ContentRect, type MeasuredComponentProps } from 'react-measure';
import { Overlay, STATE_SELECTED, STATE_UNSELECTED, STATE_ERROR } from '../../../components';
import { getDocumentElementHeight } from '../../../apps/seriesplayer/services/runtime-manager';

import styles from './DropdownOptionList.scss';
import { type DropdownOptionProps } from './DropdownOption';

export interface DropdownOptionListProps {
  position: { top: number; left: number };
  scrollPosition: number;
  selectedIndex: number;
  open: boolean;
  defaultWidth: number;
  limitOptionWidth?: boolean;
  overlayId?: string;
  onItemSelected: (index: number, content: JSX.Element) => void;
  onResizeList?: (clientRect: ContentRect) => void;
  onResizeListItem: (index: number, clientRect: ContentRect) => void;
}

const renderChildren = (children: React.ReactNode, props: DropdownOptionListProps) =>
  React.Children.map(children, (child: React.ReactElement<DropdownOptionProps>, i: number) =>
    React.cloneElement(child, {
      onItemClicked: props.onItemSelected.bind(null, i),
      onResizeListItem: props.onResizeListItem.bind(null, i),
      state:
        child.props.state === STATE_ERROR
          ? STATE_ERROR
          : i === props.selectedIndex
          ? STATE_SELECTED
          : STATE_UNSELECTED,
    })
  );

export const DropdownOptionList: React.FC<DropdownOptionListProps> = (props) => {
  const {
    defaultWidth,
    children,
    limitOptionWidth,
    scrollPosition,
    onResizeList,
    position,
    open,
    overlayId,
  } = props;

  const domNode = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (domNode.current) {
      domNode.current.scrollTop = scrollPosition;
    }
  }, [domNode, scrollPosition]);

  return (
    <Overlay overlayId={overlayId}>
      <Measure bounds={true} offset={true} scroll={true} onResize={onResizeList}>
        {({ measureRef }: MeasuredComponentProps) => (
          <div
            ref={measureRef}
            style={{
              top: position.top,
              ...(limitOptionWidth && { right: 10 }),
              left: position.left,
              minWidth: defaultWidth,
              maxHeight: getDocumentElementHeight(),
            }}
            className={classNames(styles.optionList, {
              [styles.opened]: open,
              [styles.closed]: !open,
            })}
          >
            <ul>{renderChildren(children, props)}</ul>
          </div>
        )}
      </Measure>
    </Overlay>
  );
};
