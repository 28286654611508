import { defaultTo } from 'lodash';

const INITIAL_SCALE = 1;

export const getScale = (
  totalWidth: number,
  availableWidth: number | undefined,
  editorScale: number
) =>
  defaultTo<number>(editorScale, INITIAL_SCALE) *
  (availableWidth && totalWidth > availableWidth ? availableWidth / totalWidth : INITIAL_SCALE);
