import {
  isPureMathContent,
  type MathContent,
  type MAbs,
  type MToken,
  type MFenced,
  type MRow,
  type MSup,
  type MSub,
  type MSubSup,
  type MFrac,
  type MRoot,
  type MSqrt,
  $MI,
  $MO,
  $MN,
  $MFENCED,
  $MROW,
  $MSUP,
  $MSUB,
  $MSUBSUP,
  $MFRAC,
  $MROOT,
  $MSQRT,
  $MABS,
} from '@bettermarks/gizmo-types';
import { cursorPath } from '@bettermarks/importers';

type GetLengthFunction<T = MathContent> = (content: T) => number;

export const getLength: GetLengthFunction = (content) => {
  if (isPureMathContent(content) && content.$ in getLengthForContentType) {
    return getLengthForContentType[content.$](content);
  }
  return 0;
};

const getMTokenLength: GetLengthFunction<MToken> = (content) => content.text.length;

const getMAbsLength: GetLengthFunction<MAbs> = (content) => getLength(content.value) + 2;

const getMFencedLength: GetLengthFunction<MFenced> = (content) =>
  content.children.reduce(
    (acc, c) => acc + getLength(c),
    (content.open === '' ? 0 : 1) + (content.close === '' ? 0 : 1)
  );

const getMRowLength: GetLengthFunction<MRow> = (content) =>
  content.children.reduce((acc, c) => acc + getLength(c), 0);

const getMSubLength: GetLengthFunction<MSub> = (content) =>
  getLength(content.base) + getLength(content.subscript);

const getMSupLength: GetLengthFunction<MSup> = (content) =>
  getLength(content.base) + getLength(content.superscript);

const getMSubSupLength: GetLengthFunction<MSubSup> = (content) =>
  getLength(content.base) + Math.max(getLength(content.subscript), getLength(content.superscript));

const getMFracLength: GetLengthFunction<MFrac> = (content) => {
  /**
   * In case the cursor is either in the numerator or the denominator,
   * we report the length of the numerator or the denominator,
   * to still be able to enter something in case max input length was reached for the other, e.g:
   * - max input length: 2
   * - fraction:
   *    12  <-- numerator already at max
   *   ----
   *    |   <-- still want to be able to enter 2 digits/chars in the denominator
   */

  // cursor is in numerator
  if (cursorPath(content.numerator).length > 0) {
    return getLength(content.numerator);
  }
  // cursor is in denominator
  if (cursorPath(content.denominator).length > 0) {
    return getLength(content.denominator);
  }

  return Math.max(getLength(content.numerator), getLength(content.denominator));
};

const getMRootLength: GetLengthFunction<MRoot> = (content) =>
  getLength(content.index) + getLength(content.radicand);

const getMSqrtLength: GetLengthFunction<MSqrt> = (content) => getLength(content.radicand);

const getLengthForContentType: { [key: string]: GetLengthFunction } = {
  [$MN]: getMTokenLength,
  [$MI]: getMTokenLength,
  [$MO]: getMTokenLength,
  [$MABS]: getMAbsLength,
  [$MFENCED]: getMFencedLength,
  [$MFRAC]: getMFracLength,
  [$MROOT]: getMRootLength,
  [$MROW]: getMRowLength,
  [$MSQRT]: getMSqrtLength,
  [$MSUB]: getMSubLength,
  [$MSUP]: getMSupLength,
  [$MSUBSUP]: getMSubSupLength,
};
