import { type Series, ExerciseStatus, SeriesMode, SeriesStatus, StepStatus } from '../../../types';
import { type StepResult } from '../services/api/result-manager/types';
import { type ContentDict } from '@bettermarks/gizmo-types';

export const deepOmitEnrichment = (question: ContentDict): ContentDict => {
  return JSON.parse(JSON.stringify(question), (key: string, value: any) =>
    key === 'computedStyles' || key === 'childrenAlignments' ? undefined : value
  );
};

export const getStepResult = (
  series: Series,
  exerciseIndex: number,
  stepIndex: number,
  stepTime = 0,
  exerciseTime = 0
): StepResult => {
  if (exerciseIndex >= series.exercises.length) {
    throw Error(`Exercise index ${exerciseIndex} out of bounds for series ${series.id}`);
  }
  const exercise = series.exercises[exerciseIndex];
  if (stepIndex >= exercise.steps.length) {
    throw Error(`Step index ${stepIndex} out of bounds for exercise ${exercise.id}`);
  }
  const step = exercise.steps[stepIndex];

  return {
    userId: series.userId || '',
    groupId: series.groupId,
    seriesId: series.id,
    exerciseId: exercise.id,
    exerciseIndex,
    stepId: step.id,
    stepIndex,
    sumErrors: step.numberOfErrors,
    maxErrors: step.maxErrors,
    timeUsed: stepTime,
    incomplete: step.status !== StepStatus.completed,
    userInput: {
      feedbacks: step.feedbacks,
      question: deepOmitEnrichment(step.question.present),
    },
    knowledgeGaps: step.knowledgeGaps,
    hasUserInput: step.question?.past.length > 0,
    aborted: step.aborted,
    lastStep: exercise.status === ExerciseStatus.completed,
    lastAttemptInExercise:
      exercise.status === ExerciseStatus.completed && stepIndex === exercise.steps.length - 1,
    lastAttemptInSeries:
      series.mode === SeriesMode.practice
        ? series.seriesStatus === SeriesStatus.completed
        : series.seriesStatus === SeriesStatus.completed &&
          series.exercises.length - 1 === exerciseIndex &&
          exercise.steps.length - 1 === stepIndex,
    exerciseTimeUsed: exerciseTime,
    ...(series.assignment?.id && { assignmentId: series.assignment?.id }),
    ...(series.assignment?.endDate && {
      assignmentEndDate: series.assignment?.endDate,
    }),
    mode: series.mode,
  };
};
