import { Error } from '../../../../components/icons/draft';
import * as React from 'react';
import { exceptionLog, ExceptionType } from '../../services/logging';

export type ErrorBoundaryDispatchProps = {
  onShowErrorDialog: () => void;
};

export type ErrorBoundaryStateProps = {
  hideChildrenOnError?: boolean;
  toLogExtra?: () => object;
};

export type ErrorBoundaryState = {
  hasError: boolean;
};

type ErrorBoundaryProps = React.PropsWithChildren<
  ErrorBoundaryDispatchProps & ErrorBoundaryStateProps
>;

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: any) {
    // Display fallback UI
    this.setState({ hasError: true });
    this.props.onShowErrorDialog();
    const toLogExtra = this.props.toLogExtra || (() => undefined);
    exceptionLog(ExceptionType.component, error, {
      ...toLogExtra(),
      componentStack: info.componentStack,
    });
  }

  render() {
    return this.state.hasError && this.props.hideChildrenOnError ? <Error /> : this.props.children;
  }
}
