import { isEmpty } from 'lodash';
import { DEFAULT_COLORS } from '../defaults';
import { type CaptionContent } from '@bettermarks/gizmo-types';
import { $MROW, type ContentColor, type Importer } from '@bettermarks/gizmo-types';
import { CONFIGURATION, SEMANTICS } from '@bettermarks/gizmo-types';

export const importCaption: Importer<CaptionContent> = (preContent, xml, context) => {
  const configuration = xml.findChildTag(CONFIGURATION);
  const colors = configuration.getChildrenByTagName('color').map((tag) => tag.text as ContentColor);
  const customTitle = xml.findChildTag($MROW).findChildTag('header');
  const header = customTitle.exists
    ? context.importXML(customTitle.findChildTag(SEMANTICS), false, preContent)
    : undefined;
  const legend = xml
    .findChildTag($MROW)
    .getChildrenByTagName(SEMANTICS)
    .map((val, i) => ({
      color: !isEmpty(colors[i]) ? colors[i] : DEFAULT_COLORS[i],
      label: context.importXML(val),
    }));
  return {
    ...preContent,
    header,
    legend,
  };
};
