import { identity } from 'lodash/fp';
import { DragScrollDirectionStrategy } from './DragScrollDirectionStrategy';
import { SCROLL_AREA_OFFSET } from './constants';
import { type ScrollPosition } from './types';

export class DragScrollXStrategy extends DragScrollDirectionStrategy {
  protected setInitialOverflowValue(c: HTMLElement) {
    return c.style.overflowX;
  }

  getScroll = (c: HTMLElement) => c.scrollLeft;

  incrementScroll = (delta: number) => (c: HTMLElement) => (c.scrollLeft += delta);

  getNewScrollPosition = (scroll: number) => ({ x: scroll, y: 0 });

  hideOverflow = (c: HTMLElement) => (c.style.overflowX = 'hidden');

  restoreOverflow = (c: HTMLElement) =>
    (c.style.overflowX = this.initialOverflowValue.ap(identity));

  getTopDist = ({ x }: ScrollPosition) =>
    this.scrollRect
      .map((r) => r.left + SCROLL_AREA_OFFSET)
      .ap((topSize) => (x - topSize) / topSize) || 0;

  getBottomDist = ({ x }: ScrollPosition) =>
    this.scrollRect
      .map((r) => r.right - SCROLL_AREA_OFFSET)
      .ap((bottomSize) => (bottomSize - x) / (window.innerWidth - bottomSize)) || 0;
}
