import styled from 'styled-components';
import React, { useContext } from 'react';
import { BUTTON_RECT_CLASSNAME } from './CollapseButton';
import type { StyledProps } from './types';
import { colors, dimensions, handleHover } from '@seriesplayer/common-ui';
import CollapsibleTopLevelSection from './CollapsibleTopLevelSection';
import CollapsibleChildSection from './CollapsibleChildSection';
import { StudentTasksContext } from '../../whiteboard/Whiteboard';

export const SECTION_HEADER_CLASSNAME = 'section-header';

export type CollapsibleSectionMode = 'collapsed' | 'open';

export type CollapsibleSectionProps = StyledProps & {
  title: string;
  children: React.ReactNode;
  initialMode?: CollapsibleSectionMode;
  isChildSection?: boolean;
};

export default function CollapsibleSection({
  initialMode = 'open',
  title,
  children,
  isChildSection = false,
}: CollapsibleSectionProps) {
  const [currentMode, setCurrentMode] = React.useState<CollapsibleSectionMode>(initialMode);

  const context = useContext(StudentTasksContext);
  const ref = React.useRef<HTMLDivElement>(null);

  const toggleCollapsed = React.useCallback(() => {
    setCurrentMode(currentMode === 'open' ? 'collapsed' : 'open');
  }, [currentMode]);

  React.useEffect(() => {
    ref.current?.clientHeight && context?.reportHeight(ref.current?.clientHeight);
  }, [currentMode]);

  return isChildSection ? (
    <CollapsibleChildSection
      title={title}
      // The type definition of `CollapsibleSectionProps` makes sure that `fullText` is always
      // defined if `isChildSection` is true, but the IDE doesn't understand that, hence the "!!".
      fullText={children}
      isCollapsed={currentMode === 'collapsed'}
      toggleCollapsed={toggleCollapsed}
    />
  ) : (
    <div ref={ref}>
      <CollapsibleTopLevelSection
        title={title}
        isCollapsed={currentMode === 'collapsed'}
        toggleCollapsed={toggleCollapsed}
      >
        {children}
      </CollapsibleTopLevelSection>
    </div>
  );
}

export const HoverHandler = styled.div`
  ${handleHover(`background: ${colors.cGray100};`, `.${SECTION_HEADER_CLASSNAME}`)}
  ${handleHover(`background: ${colors.cGray200};`, `.${BUTTON_RECT_CLASSNAME}`)}
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  width: 100%;
  min-height: ${dimensions.minClickableHeight};

  .title {
    align-self: center;
    line-height: normal;
    ${(props) => props.theme};
    font-size: 16px;
  }
`;
