import * as React from 'react';
import ReactDOM from 'react-dom';

export type OverlayProps = {
  overlayId?: string;
};

export class Overlay extends React.Component<OverlayProps> {
  overlay: HTMLDivElement;

  constructor(props: OverlayProps) {
    super(props);
    let overlayParent = document.getElementById(
      this.props.overlayId || 'overlay'
    ) as HTMLDivElement;

    if (process.env.NODE_ENV === 'test' && overlayParent === null) {
      overlayParent = document.createElement('div');
    }

    this.overlay = document.createElement('div');
    overlayParent.appendChild(this.overlay);
  }

  componentWillUnmount(): void {
    this.overlay.parentElement && this.overlay.parentElement.removeChild(this.overlay);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.overlay);
  }
}
