import { fillGaps } from './fillGaps';
import {
  Action,
  type ActionStack,
  type CanvasDimensions,
  type Slice,
  type ToolInfo,
} from './types';
import { colors } from '@seriesplayer/common-ui';

export const drawOrErase =
  (
    ctx: CanvasRenderingContext2D | null | undefined,
    toolInfo: ToolInfo,
    dimensions: CanvasDimensions,
    topOffset: number,
    appendToActionStack?: (actionSlice: Slice) => void
  ) =>
  (x: number, y: number) => {
    const { selectedTool: tool, toolConfig } = toolInfo;
    const radius = tool === 'pen' ? toolConfig.penThickness : toolConfig.eraserThickness;

    if (ctx === undefined || ctx === null) return;
    ctx.globalCompositeOperation = 'source-over';
    ctx.fillStyle = colors[toolConfig.color];
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    if (tool === 'eraser') {
      ctx.globalCompositeOperation = 'destination-out';
    }
    ctx.fill();
    appendToActionStack &&
      appendToActionStack({
        action: tool === 'pen' ? Action.draw : Action.erase,
        position: { x, y: y + topOffset },
        dimensions,
        toolInfo,
      });
  };

export const replayActionStack = (
  ctx: CanvasRenderingContext2D | null | undefined,
  actionStack: ActionStack,
  { canvasWidth: currentWidth, canvasHeight: currentHeight }: CanvasDimensions,
  topOffset: number
) => {
  actionStack.forEach((slice, index) => {
    const { action, position, dimensions, toolInfo } = slice;

    // scale x/y coordinates from stacked action to current scale factor
    const xTransform = currentWidth / dimensions.canvasWidth;
    const yTransform = currentHeight / dimensions.canvasHeight;

    const previousAction = index > 0 ? actionStack[index - 1].action : action;
    const previousPosition = index > 0 ? actionStack[index - 1].position : position;

    const drawOrEraseAt = drawOrErase(
      ctx,
      toolInfo,
      {
        canvasWidth: currentWidth,
        canvasHeight: currentHeight,
      },
      topOffset
    );

    if (previousAction === action && index > 0) {
      // fill gaps between two mouse positions, if mouse is moved fast
      fillGaps(
        ctx,
        {
          x: previousPosition.x * xTransform,
          y: (previousPosition.y - topOffset) * yTransform,
        },
        {
          x: position.x * xTransform,
          y: (position.y - topOffset) * yTransform,
        },
        toolInfo
      );
    }
    // draw or erase for current position
    action !== Action.stop &&
      drawOrEraseAt(position.x * xTransform, (position.y - topOffset) * yTransform);
  });
};

export const zeroIfUndefined = (it: number | undefined) => it || 0;
