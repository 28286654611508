import { type DefaultFillColorMap, type DefaultIconColorMap } from './Handle';
import Color from 'color';
import bmcolors from 'styles/bmcolors.scss';
import { HandleState } from './types';

const defaultFillColorMap = {
  [HandleState.Idle]: bmcolors['bm-white'],
};

const defaultIconColorMap = {
  [HandleState.Hovered]: bmcolors['bm-white'],
  [HandleState.Disabled]: bmcolors['bm-grey'],
};

/**
 * helps to resolve Handle circle stroke color
 * @param color
 * @param state
 * @param disableColor
 */
export const resolveStrokeColor = (
  color: string = bmcolors['bm-white'],
  state: HandleState,
  disableColor: string = bmcolors['bm-grey']
) => {
  if (state === HandleState.Disabled) {
    return disableColor;
  }

  return color;
};

/**
 * helps to resolve Handle fill color based on the color and state
 * @param color
 * @param state
 * @param colorMap
 */
export const resolveFillColor = (
  color: string = bmcolors['bm-white'],
  state: HandleState,
  colorMap: DefaultFillColorMap = defaultFillColorMap
): string => {
  switch (state) {
    case HandleState.Hovered:
      return color;
    case HandleState.Grabbed:
      /**
       * TODO: maybe there is a better solution thn applying `mix` twice
       * for some reason `whiten` method does not work :(
       */
      return Color(color).mix(Color('white')).mix(Color('white')).hex();
    case HandleState.Idle:
    case HandleState.Disabled:
    default:
      return colorMap[HandleState.Idle];
  }
};

/**
 * helps to resolve Handle icon color based on the color and state
 * @param color
 * @param state
 * @param colorMap
 */
export const resolveIconColor = (
  color = '#fff',
  state: HandleState,
  colorMap: DefaultIconColorMap = defaultIconColorMap
): string => {
  switch (state) {
    case HandleState.Disabled:
      return colorMap[HandleState.Disabled];
    case HandleState.Hovered:
      return colorMap[HandleState.Hovered];
    case HandleState.Idle:
    case HandleState.Grabbed:
      return color;
    default:
      return color;
  }
};
