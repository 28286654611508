import * as React from 'react';
import { PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';
import { type Arrow, TableOrientation as Orientation } from '@bettermarks/gizmo-types';
import { ContainerArrow } from './ContainerArrow';

import styles from '../DecoratedCell.scss';

type HorizontalArrowsProps = {
  arrows: Arrow[];
  colWidths: number[];
  orientation: Orientation;
};

export const HorizontalArrows: React.FC<HorizontalArrowsProps> = ({
  arrows,
  colWidths,
  orientation,
}) => {
  const arrowsWithWidth = arrows.map((a) => {
    const [fromCol, toCol] = [a.from.coords[1], a.to.coords[1]].sort();
    return {
      ...a,
      width: colWidths.reduce((acc, rh, i) => (i >= fromCol && i < toCol ? acc + rh : acc), 0),
      fromCol,
      toCol,
    };
  });

  const labelRow = (
    <tr>
      {colWidths.reduce((acc, cw, i) => {
        const arrowFrom = arrowsWithWidth.find((a) => a.fromCol === i);
        const arrowTo = arrowsWithWidth.find((a) => a.toCol === i);
        const arrowIn = arrowsWithWidth.find((a) => a.fromCol < i && a.toCol > i);
        return arrowFrom
          ? [
              ...acc,
              <React.Fragment key={i}>
                {!arrowTo && <td style={{ width: 0.5 * cw }} />}
                <td className={styles.center} style={{ textAlign: 'center' }}>
                  <PolymorphicGizmo
                    refid={arrowFrom.label.$refid}
                    availableWidth={arrowFrom.width}
                  />
                </td>
              </React.Fragment>,
            ]
          : arrowIn
          ? acc
          : [...acc, <td key={`after_${i}`} style={{ width: arrowTo ? 0.5 * cw : cw }} />];
      }, [])}
    </tr>
  );

  return (
    <table>
      <tbody>
        {orientation === Orientation.north && labelRow}
        <tr>
          {colWidths.reduce((acc, cw, i) => {
            const arrowFrom = arrowsWithWidth.find((a) => a.fromCol === i);
            const arrowTo = arrowsWithWidth.find((a) => a.toCol === i);
            const arrowIn = arrowsWithWidth.find((a) => a.fromCol < i && a.toCol > i);
            return arrowFrom
              ? [
                  ...acc,
                  <React.Fragment key={i}>
                    {!arrowTo && <td style={{ width: 0.5 * cw }} />}
                    <td>
                      <ContainerArrow
                        orientation={arrowFrom.orientation}
                        length={arrowFrom.width}
                        arrowStart={arrowFrom.from.head}
                        arrowEnd={arrowFrom.to.head}
                      />
                    </td>
                  </React.Fragment>,
                ]
              : arrowIn
              ? acc
              : [...acc, <td key={`after_${i}`} style={{ width: arrowTo ? 0.5 * cw : cw }} />];
          }, [])}
        </tr>
        {orientation === Orientation.south && labelRow}
      </tbody>
    </table>
  );
};

HorizontalArrows.displayName = 'HorizontalArrows';
