import * as React from 'react';
import { DOWN_FILTER_ID, HOVER_FILTER_ID } from '../../tools/constants';

const DARK_FACTOR = 0.5;
const LIGHT_FACTOR = 2;

interface FilterProps {
  id: string;
}

// Want to see a MATRIX, hence:
export const AreaInteractionFilters: React.FC<FilterProps> = ({ id }) => (
  <defs>
    <filter id={HOVER_FILTER_ID(id)}>
      <feColorMatrix
        type="matrix"
        values={[
          DARK_FACTOR,
          0,
          0,
          0,
          0,
          0,
          DARK_FACTOR,
          0,
          0,
          0,
          0,
          0,
          DARK_FACTOR,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
        ].join(' ')}
      />
    </filter>
    <filter id={DOWN_FILTER_ID(id)}>
      <feColorMatrix
        type="matrix"
        values={[
          LIGHT_FACTOR,
          0,
          0,
          0,
          0,
          0,
          LIGHT_FACTOR,
          0,
          0,
          0,
          0,
          0,
          LIGHT_FACTOR,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
        ].join(' ')}
      />
    </filter>
  </defs>
);

AreaInteractionFilters.displayName = 'AreaInteractionFilters';
