import type * as React from 'react';
import {
  ParallelLineHighlight,
  PerpendicularHighlight,
  PointDragOut,
  PointMove,
} from '../../../components/icons/geo';
import {
  BezierSubtype,
  ContentColor,
  type GeoAddLabelBaseState,
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type GeoLineDecoration,
  LineHighlight,
  type LineStyle,
  LineWeight,
  POINT,
  PointHighlight,
  RAY,
  SEGMENT,
  SeverityColor,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import { DEFAULT_PREVLINE, DEFAULT_SELECT_COLOR } from '@bettermarks/importers';
import { getRayId, getSegmentId, getStraightlineId, getVectorId } from '../helpers';
import { persistLine, prevLineStyle } from './persist';

export type PersistLineFunction = <State extends GeoInteractiveBaseState>(
  state: State,
  p1Id: string,
  p2Id: string,
  lineId: string,
  deco: GeoLineDecoration,
  notLabelable?: boolean
) => State;

export type LineTypeProps = {
  type: typeof RAY | typeof SEGMENT | typeof STRAIGHTLINE | typeof VECTOR;
  persistLine: PersistLineFunction;
  getLineId: (p1Id: string, p2Id: string, prefix?: string) => string;
  lineStyle: LineStyle;
  color?: string;
};

export const getLineTypeProps = (mode: GeoEditorMode): LineTypeProps => {
  switch (mode) {
    case GeoEditorMode.ADD_RAY:
    case GeoEditorMode.ADD_RAY_DASHED:
      return {
        type: RAY,
        persistLine: persistLine(RAY),
        getLineId: getRayId,
        lineStyle: prevLineStyle(mode),
      };
    case GeoEditorMode.ADD_STRAIGHTLINE:
    case GeoEditorMode.ADD_STRAIGHTLINE_DASHED:
      return {
        type: STRAIGHTLINE,
        persistLine: persistLine(STRAIGHTLINE),
        getLineId: getStraightlineId,
        lineStyle: prevLineStyle(mode),
      };
    case GeoEditorMode.ADD_VECTOR:
    case GeoEditorMode.ADD_VECTOR_DASHED:
      return {
        type: VECTOR,
        persistLine: persistLine(VECTOR),
        getLineId: getVectorId,
        lineStyle: prevLineStyle(mode),
      };
    default:
      return {
        type: SEGMENT,
        persistLine: persistLine(SEGMENT),
        getLineId: getSegmentId,
        lineStyle: prevLineStyle(mode),
      };
  }
};

export const DEFAULT_HOVER_COLOR = ContentColor.BM_LIGHT_BLUE;
export const DEFAULT_DELETE_COLOR = SeverityColor.ERROR;
export const DEFAULT_PREVIEW_COLOR = ContentColor.BM_LIGHT_GREY;
export const HOVER_FILTER_ID = (id: string) => `darker_${id}`;
export const DOWN_FILTER_ID = (id: string) => `lighter_${id}`;
export const COLOR_FILTER_ID = (id: string) => `color_hover_${id}`;

export const FILTER_URL_FROM_ID = (id: string) => `url(#${id})`;

export const DEFAULT_HOVER_DECORATION = (subtype: BezierSubtype, id: string) => ({
  ...(subtype === BezierSubtype.lines && {
    color: DEFAULT_HOVER_COLOR,
    lineWeight: LineWeight.thick,
  }),
  ...(subtype === BezierSubtype.areas && {
    filter: FILTER_URL_FROM_ID(HOVER_FILTER_ID(id)),
  }),
});

export const DEFAULT_DOWN_DECORATION = (subtype: BezierSubtype, id: string) => ({
  ...(subtype === BezierSubtype.areas && {
    filter: FILTER_URL_FROM_ID(DOWN_FILTER_ID(id)),
  }),
});

export const DEFAULT_ADD_LABEL_STATE: GeoAddLabelBaseState = {
  labelingObjectId: '',
  labelingObjectType: POINT,
  labelList: [],
  pickerList: [],
  activeIndex: {},
  active: false,
  onInitialLabelOver: false,
  initial: false,
};

type HighlightSVG = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: React.FunctionComponent<React.SVGAttributes<{}>>;
  width: number;
  height: number;
};

const GeoHighlight = { ...PointHighlight, ...LineHighlight };

export const HIGHLIGHT_MAP: {
  [key in keyof typeof GeoHighlight]: HighlightSVG;
} = {
  [PointHighlight.move]: { icon: PointMove, width: 36, height: 36 },
  [PointHighlight.dragOut]: { icon: PointDragOut, width: 26, height: 26 },
  [LineHighlight.perpendicular]: {
    icon: PerpendicularHighlight,
    width: 20,
    height: 24,
  },
  [LineHighlight.parallel]: {
    icon: ParallelLineHighlight,
    width: 24,
    height: 20,
  },
};
export const DEFAULT_OBJECT_SELECT_DECORATION = {
  color: DEFAULT_SELECT_COLOR,
  lineWeight: LineWeight.thick,
};

export const SPACE = ' ';

export const DEFAULT_PARALLEL_TOOL_PREVLINES = {
  perpendicular: DEFAULT_PREVLINE,
  parallel: DEFAULT_PREVLINE,
  others: [],
};
