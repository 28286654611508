import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { DURATION_TOTAL_HEIGHT, DURATION_VERTICAL_PADDING } from './constants';
import { type DayDurationGizmoContent } from '@bettermarks/gizmo-types';

export const applyDayDurationStyles: ApplyStyles = (
  { labelRef }: DayDurationGizmoContent,
  style
) => {
  return [
    {
      refId: labelRef.$refid,
      style,
    },
  ];
};

export const measureDayDuration: Ruler<DayDurationGizmoContent> = (_, content) => {
  const height = DURATION_TOTAL_HEIGHT;
  return {
    height,
    refLine: DURATION_VERTICAL_PADDING, // the duration arrow level
  };
};
