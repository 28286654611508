import { MULTIPLE_CHOICE_DROP_DOWN } from '../../gizmo-utils/configuration/renderStyles';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { type Annotations, type Content, type ContentReference } from '../../xml-converter/core';
import { type SelectedItem } from '../multiple-choice/data';

export const STATE_SELECTED = 'selected' as const;
export const STATE_UNSELECTED = 'unselected' as const;
export const STATE_ERROR = 'error' as const;

export const DEFAULT_TEXT_KEY = 'selection.default';

export interface MultipleChoiceDropDownContent extends Readonly<Content> {
  readonly choices: ContentReference[];
  readonly checkedIdx: number;
  readonly configuration: MultipleChoiceDropDownConfiguration;
  readonly itemSeverity?: SelectedItem;
}

export const MULTIPLE_CHOICE_DROP_DOWN_DEFAULT_CONTENT: Readonly<MultipleChoiceDropDownContent> = {
  $: PLACEHOLDER,
  $renderStyle: MULTIPLE_CHOICE_DROP_DOWN,
  configuration: {
    dropDownDefaultText: 'selection.default',
  },
  choices: [],
  checkedIdx: -1,
};

export type MultipleChoiceDropDownConfiguration = {
  dropDownDefaultText: string;
  placeholder?: Partial<Annotations>;
};
