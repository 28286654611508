import { type Action, handleActions } from 'redux-actions';
import { type MultipleChoiceMultiContent } from '@bettermarks/gizmo-types';
import { SELECT_ITEM, type MultipleChoiceMultiActionPayload } from './multipleChoiceActions';

const initialState: MultipleChoiceMultiContent = {
  $: 'special',
  $interactionType: 'multiple-choice-multi',
  $id: '',
  $renderStyle: 'multiple-choice-multi',
  choices: [],
  configuration: {},
  checkedIdx: [],
};

const getCheckedIdx: any = (payload: number, state: MultipleChoiceMultiContent) => {
  const checkedIdx: number[] = state.checkedIdx.slice(0);
  if (checkedIdx.indexOf(payload) === -1) {
    checkedIdx.push(payload);
  } else {
    checkedIdx.splice(checkedIdx.indexOf(payload), 1);
  }
  return checkedIdx;
};

export const multipleChoiceMultiReducer = handleActions<
  MultipleChoiceMultiContent,
  MultipleChoiceMultiActionPayload
>(
  {
    [SELECT_ITEM]: (state, { payload }: Action<MultipleChoiceMultiActionPayload>) =>
      payload !== undefined && payload >= 0 && payload < state.choices.length
        ? {
            ...state,
            checkedIdx: getCheckedIdx(payload, state),
          }
        : state,
  },
  initialState
);
