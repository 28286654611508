import React from 'react';
import { isEmpty, isBoolean, isString } from 'lodash';
import log from 'loglevel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, ButtonKind } from '@seriesplayer/common-ui';

import {
  DEFAULT_SERIES_SETTINGS,
  type Features,
  type ILoadExercisePayload,
  LoaderState,
  SeriesFlow,
  type SeriesSettings,
  FeatureKey,
} from '../../../../types';

import { gizmoViewerPath } from '../../../gizmoviewer';
import { getBackToNsp } from '../SeriesPlayer/quitSeriesPlayerSaga';
import { Loader } from '../../components/Loader';
import { getFullExercisePath, loadLocalXMLFiles } from './helper';
import { type UploadContainerReduxProps } from './UploadContainer';
import styles from './upload.scss';
import { parseUploadQueryString } from './parseUploadQueryString';

type Option = {
  label: string;
  value: string;
};

const featureKeyArray = Object.keys(FeatureKey) as ReadonlyArray<keyof typeof FeatureKey>;

const mapToLabelValue = (x: string) => ({ label: x, value: x });

export type UploadLocalState = Omit<ILoadExercisePayload, 'features' | 'assignmentEndDate'> & {
  assignmentEndDate: string;
  autostart?: boolean;
  focusElement?: string;
  selectedFeatures: string[];
  selectedSeriesSettings: string[];
  showAssignmentEndDate: boolean;
  validation: string;
};

export type UploadProps = React.PropsWithChildren<UploadContainerReduxProps>;

function useSearchParams<T extends string>(search: string, params: T[]): Record<T, string | null> {
  const _params = new URLSearchParams(search);
  return params.reduce((acc, cur) => {
    acc[cur] = _params.get(cur);
    return acc;
  }, {} as Record<T, string | null>);
}

export function Upload(props: UploadProps) {
  const location = useLocation();
  const params = useSearchParams(location.search, ['autostart', 'exerciseid', 'seriesFile']);
  const navigate = useNavigate();

  const { assignmentEndDate, focusElement, qaMode, reporting, staticUrl, testMode, validation } =
    parseUploadQueryString(location.search);

  const [state, setState] = React.useState({
    assignmentEndDate: '',
    autostart: params.autostart === 'true',
    exerciseid: params.exerciseid ?? '',
    focusElement,
    qaMode,
    selectedFeatures: [],
    selectedSeriesSettings: [],
    seriesFile: params.seriesFile ?? '',
    seriesReview: false,
    previewMode: false,
    showAssignmentEndDate: false,
    ...(staticUrl && { staticUrl }),
    ...(params.exerciseid && { exerciseid: params.exerciseid ?? '' }),
    ...(params.seriesFile && { seriesFile: params.seriesFile ?? '' }),
    ...(validation && { validation }),
    ...(focusElement && { focusElement }),
    staticUrl,
    validation,
    ...(validateStringDate(assignmentEndDate) && {
      assignmentEndDate: new Date(assignmentEndDate).toISOString(),
      showAssignmentEndDate: true,
    }),
    seriesSettings: {
      ...DEFAULT_SERIES_SETTINGS,
      reporting,
      flow: testMode ? SeriesFlow.random : SeriesFlow.linear,
    },
    navigate: () => {},
  } as UploadLocalState);

  React.useEffect(() => {
    props.initialize();
    if (state.focusElement) {
      props.setElementToFocus(this.state.focusElement);
    }

    const {
      exerciseid,
      autostart,
      seriesFile,
      seriesReview,
      previewMode,
      seriesSettings,
      staticUrl,
      qaMode,
      assignmentEndDate,
    } = state;

    if ((exerciseid || seriesFile) && autostart) {
      props.onStartFromId({
        ...(validateStringDate(assignmentEndDate) && {
          assignmentEndDate: new Date(assignmentEndDate).toISOString(),
        }),
        exerciseid,
        features: {},
        seriesFile,
        seriesReview,
        previewMode,
        seriesSettings,
        staticUrl: getDefaultStaticUrl(staticUrl),
        qaMode,
        navigate: () => navigate(`/series?exerciseid=${getFullExercisePath(exerciseid)}`),
      });
    }
  }, []);

  function validateStringDate(stringDate: unknown): stringDate is string {
    return isString(stringDate) && !isNaN(Date.parse(stringDate));
  }

  /**
   * Returns a list of seriesSettings keys that can be toggled on/off
   */
  const getBooleanSeriesSettingKeys = () =>
    Object.keys(DEFAULT_SERIES_SETTINGS).filter((x: keyof SeriesSettings) =>
      isBoolean(DEFAULT_SERIES_SETTINGS[x])
    );

  const getDefaultStaticUrl = (staticUrl: string) => {
    // Input can either happen via URL query param or input field
    const fromInput = isEmpty(staticUrl) ? props.staticServerUrl : staticUrl;
    /*
      there are deep links where staticUrl included 'content_exercises'
      worked earlier but stopped working with #1113
      stripping it of from those URLs fixes the regression
    */
    return fromInput.split('content_exercises')[0];
  };

  /**
   * Returns the features with values selected from dropdown saved in
   * state.selectedFeatures
   */
  const getFeatureStates = () =>
    featureKeyArray.reduce<Features>(
      (features, featureKey) => ({
        ...features,
        [featureKey]: state.selectedFeatures.includes(featureKey),
      }),
      {}
    );

  /**
   * Returns the seriesSettings with values selected from dropdown saved in
   * state.selectedSeriesSettings
   */
  const getSeriesSettingStates = () =>
    getBooleanSeriesSettingKeys().reduce(
      (seriesSettings: SeriesSettings, curr: string) => ({
        ...seriesSettings,
        [curr]: state.selectedSeriesSettings.includes(curr),
      }),
      state.seriesSettings
    );

  const handleExerciseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({ ...prev, exerciseid: event.target.value }));
  };

  const handleFeaturesChange = (options: Option[]) =>
    setState((prev) => ({
      ...prev,
      selectedFeatures: options ? options.map((o) => o.value) : [],
    }));

  /**
   * Used example from here:
   * https://stackoverflow.com/questions/48172934/error-using-async-and-await-with-filereader
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Gets array like object {}
    const files = Array.prototype.slice.call(event.target.files);
    const { qaMode, seriesReview, previewMode, assignmentEndDate } = state;
    loadLocalXMLFiles(files)
      .then((xmlStrings) => {
        return props.onStartFromFiles({
          assignmentEndDate,
          features: getFeatureStates(),
          qaMode,
          seriesReview,
          previewMode,
          seriesSettings: getSeriesSettingStates(),
          xmlStrings,
          navigate: () => navigate('/series'),
        });
      })
      .catch((error) => {
        log.error(`Failed to load file due to ${error}`);
      });
  };

  const handleQAMode = (_: React.ChangeEvent<HTMLInputElement>) =>
    setState((prev) => ({ ...prev, qaMode: !prev.qaMode }));

  const handleShowAssignmentEndDate = (_: React.FormEvent<HTMLInputElement>) => {
    setState((prevState) => {
      const tenMinutesInMicroseconds = 10 * 60 * 1000;
      // reset assignmentEndDate to base default if toggling off showAssignmentEndDate
      const nextAssignmentEndDate = prevState.showAssignmentEndDate
        ? '' // reset it to string identity
        : validateStringDate(prevState.assignmentEndDate) // if is a valid sting?
        ? prevState.assignmentEndDate // leave it as such
        : new Date(
            Date.now() + tenMinutesInMicroseconds // else set it to a new DateISOString
          ).toISOString();

      return {
        ...prevState,
        showAssignmentEndDate: !prevState.showAssignmentEndDate,
        assignmentEndDate: nextAssignmentEndDate,
      };
    });
  };

  const handleAssignmentEndDate = (event: React.FormEvent<HTMLInputElement>) =>
    setState((prev) => ({ ...prev, assignmentEndDate: event.currentTarget.value }));

  const handleSeriesReview = (_: React.FormEvent<HTMLInputElement>) =>
    setState((prev) => ({
      ...prev,
      seriesReview: !prev.seriesReview,
      previewMode: false,
    }));

  const handleSeriesPreviewMode = (_: React.FormEvent<HTMLInputElement>) =>
    setState((prev) => ({
      ...prev,
      previewMode: !prev.previewMode,
      seriesReview: false,
    }));

  const handleSeriesSettingsChange = (options: Option[]) =>
    setState((prev) => ({
      ...prev,
      selectedSeriesSettings: options ? options.map((o) => o.value) : [],
    }));

  const handleSubmit = () => {
    const { exerciseid, seriesReview, previewMode, staticUrl, qaMode, assignmentEndDate } = state;

    props.onStartFromId({
      ...(validateStringDate(assignmentEndDate) && {
        assignmentEndDate: new Date(assignmentEndDate).toISOString(),
      }),
      exerciseid,
      features: getFeatureStates(),
      qaMode,
      seriesReview,
      previewMode,
      seriesSettings: getSeriesSettingStates(),
      staticUrl: getDefaultStaticUrl(staticUrl),
      navigate: () => navigate(`/series?exerciseid=${getFullExercisePath(exerciseid)}`),
    });
  };

  const handleTestMode = ({ currentTarget }: React.FormEvent<HTMLInputElement>) =>
    setState((prev) => ({
      ...prev,
      seriesSettings: {
        ...prev.seriesSettings,
        flow: currentTarget.checked ? SeriesFlow.random : SeriesFlow.linear,
      },
    }));

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setState((prev) => ({ ...prev, staticUrl: event.target.value }));

  const { loaderState } = props;
  const { showAssignmentEndDate } = state;

  // Show the uploader screen back if error happened
  return (
    <Loader
      loaded={loaderState === LoaderState.success || loaderState === LoaderState.error}
      onQuit={getBackToNsp}
    >
      <div className={styles.loadHeader}>
        <div className={styles.headerItem}>
          <label htmlFor="exerciseId">ExerciseId:</label>
          <input
            id="exerciseId"
            type="text"
            size={26}
            value={state.exerciseid}
            onChange={handleExerciseChange}
          />
        </div>

        <div className={styles.headerItem}>
          <label htmlFor="staticUrl">Static Host:</label>
          <input
            id="staticUrl"
            type="text"
            size={26}
            value={staticUrl}
            onChange={handleUrlChange}
          />
        </div>

        <div className={styles.headerItem}>
          <label htmlFor="qaMode">QAMode:</label>
          <input
            id="qaMode"
            type="checkbox"
            aria-checked={qaMode}
            checked={state.qaMode}
            onChange={handleQAMode}
          />
        </div>

        <Button kind={ButtonKind.action} className={styles.submitButton} onClick={handleSubmit}>
          Go
        </Button>
      </div>

      <div className={styles.loadHeader}>
        <div className={styles.headerItem}>
          <label htmlFor="features">Features:</label>
          <Select
            id="features"
            isMulti
            dropdownHeight="200px"
            placeholder="Select to enable..."
            options={featureKeyArray.map((feature) => ({
              label: feature,
              value: feature,
            }))}
            onChange={handleFeaturesChange}
            className={styles.featuresSelect}
          />
        </div>

        <div className={styles.headerItem}>
          <label htmlFor="seriesSettings">Series Settings:</label>
          <Select
            id="seriesSettings"
            isMulti
            dropdownHeight="200px"
            placeholder="Select to enable..."
            options={getBooleanSeriesSettingKeys().map(mapToLabelValue)}
            defaultValue={getBooleanSeriesSettingKeys()
              .filter((x: keyof SeriesSettings) => DEFAULT_SERIES_SETTINGS[x] === true)
              .map(mapToLabelValue)}
            onChange={handleSeriesSettingsChange}
            className={styles.featuresSelect}
          />
        </div>

        <div className={styles.headerItem}>
          <label htmlFor="testMode">TestMode:</label>
          <input
            id="testMode"
            type="checkbox"
            aria-checked={testMode}
            checked={testMode}
            onChange={handleTestMode}
          />
        </div>

        <div className={styles.headerItem}>
          <label htmlFor="review">Review:</label>
          <input
            id="review"
            type="checkbox"
            aria-checked={state.seriesReview}
            checked={state.seriesReview}
            onChange={handleSeriesReview}
          />
        </div>

        <div className={styles.headerItem}>
          <label htmlFor="preview">Preview Mode:</label>
          <input
            id="preview"
            type="checkbox"
            aria-checked={state.previewMode}
            checked={state.previewMode}
            onChange={handleSeriesPreviewMode}
          />
        </div>

        {testMode && (
          <div className={styles.headerItem}>
            <label htmlFor="showAssignmentEndDate">Set Assignment End Date:</label>
            <input
              id="showAssignmentEndDate"
              type="checkbox"
              aria-checked={state.showAssignmentEndDate}
              checked={state.showAssignmentEndDate}
              onChange={handleShowAssignmentEndDate}
            />
          </div>
        )}

        {testMode && showAssignmentEndDate && assignmentEndDate && (
          <div className={styles.headerItem}>
            <label htmlFor="assignmentEndDate">Assignment end-date (ISO UTC):</label>
            <input
              id="assignmentEndDate"
              type="datetime-local"
              value={assignmentEndDate}
              onChange={handleAssignmentEndDate}
            />
          </div>
        )}
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          <input type="file" accept=".xml" onChange={handleFileChange} multiple />
          <div>Select exercise xml files</div>
        </div>

        <div className={styles.content}>
          <Link to={gizmoViewerPath('AllGizmos')} className={styles.gizmolink}>
            Click here to see all Gizmos
          </Link>
        </div>
      </div>
    </Loader>
  );
}
