import * as React from 'react';
import classNames from 'classnames';
import { GizmoIcon } from '../../../components/icons/draft';
import { GIZMO_HEIGHT, type GizmoColor } from './constants';

import styles from './Gizmo.scss';

export type GizmoComponentProps = {
  /** the color of the gizmo icon */
  color: GizmoColor;
  /** the size of the gizmo icon in pixels */
  size: number;
  /** text to show on top of the gizmo icon */
  text?: string;
  /** control hovered state */
  hovered?: boolean;
  /** callback for click event */
  onClick?: () => void;
  /** callback for hover */
  onHover?: () => void;
  /** callback for stop hover */
  onHoverStop?: () => void;
};

/**
 * The `Gizmo` component is a colorizable gizmo image. It used for the 'HelloGizmo' template.
 *
 * ### Process State
 *
 * - Visuals: Draft from DEV
 * - Behaviour: Draft from DEV
 */
export default function Gizmo({
  color,
  hovered,
  onClick,
  onHover,
  onHoverStop,
  size,
  text,
}: GizmoComponentProps) {
  const style = classNames(
    styles[color],
    onHover ? styles.hoverable : '',
    hovered ? styles.hovered : ''
  );
  const height = isNaN(size) ? GIZMO_HEIGHT : size;
  const wFactor = height / GIZMO_HEIGHT;

  return (
    <div>
      <p className={styles.text}>{text}</p>
      <GizmoIcon
        width={`${40 * wFactor}px`}
        height={`${height}px`}
        role="button"
        className={style}
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={onHoverStop}
      />
    </div>
  );
}
