import React from 'react';
import { postToWhiteboardChild } from '../../iframe-posts';
import { Keyboard } from '../../seriesplayer/containers/Toolbar/Tools/Keyboard/Keyboard';
import { useWhiteboardContext } from '../whiteboard-context';
import styled from 'styled-components';
import { colors } from '@seriesplayer/common-ui';
import { TOOLBAR_BORDER } from '../toolbar/constants';

interface WhiteboardKeyboardProps {
  availableWidth: number;
  toolbarsWidth: number;
}

const KeyboardContainer = styled.div<WhiteboardKeyboardProps>`
  background-color: ${colors.cGray300};
  border-top: ${TOOLBAR_BORDER}px solid ${colors.cGray500};
  position: absolute;
  bottom: 0;
  width: ${({ availableWidth }) => availableWidth}px;
  left: ${({ toolbarsWidth }) => toolbarsWidth}px;
`;

export default function WhiteboardKeyboard({
  availableWidth,
  toolbarsWidth,
}: WhiteboardKeyboardProps): JSX.Element {
  const {
    state: {
      contentToolConfig: { keyboard, gizmoId, openDrawerName },
    },
  } = useWhiteboardContext();

  const [selectedPage, setSelectedPage] = React.useState(0);

  if (!keyboard || openDrawerName !== 'keyboard') {
    return <></>;
  }

  return (
    <KeyboardContainer availableWidth={availableWidth} toolbarsWidth={toolbarsWidth}>
      <Keyboard
        gizmoId={gizmoId}
        availableWidth={availableWidth}
        onKey={(gizmoId: string) => (key: string) => {
          postToWhiteboardChild({ type: 'keyboardInput', key, gizmoId });
        }}
        onTogglePage={() => (index: number) => {
          setSelectedPage(index);
        }}
        operators={keyboard.operators || []}
        hasDecimalPoint={keyboard.hasDecimalPoint}
        hasLetters={keyboard.hasLetters}
        hasNumbers={keyboard.hasNumbers}
        selectedPage={selectedPage}
      />
    </KeyboardContainer>
  );
}
