import { put, select } from 'redux-saga/effects';
import { dropWhile } from 'lodash/fp';
import {
  AppExercise,
  ApplicationState,
  DialogType,
  ScreenLockType,
  Series,
  SeriesMode,
  StepStatus,
} from '../../../../types';
import { nextStep, submit, switchExerciseResumable } from '../Exercise/actions';
import { closeDialog, openDialog, showResults } from '../SeriesPlayer/actions';
import { nextExercise } from './actions';

const nextStepsLocked = (ex: AppExercise): boolean =>
  dropWhile((s) => s.status !== StepStatus.locked, ex.steps).length > 0;

function* handlePracticeMode(state: ApplicationState) {
  const currentExercise = ApplicationState.toCurrentExercise.get(state);
  const currentStep = ApplicationState.toCurrentStep.get(state);
  const series = state.series;

  if (currentStep?.status === StepStatus.started || currentStep?.status === StepStatus.attempted) {
    yield put(submit());
  } else if (
    currentStep?.status === StepStatus.completed &&
    !AppExercise.isCompleted(currentExercise)
  ) {
    yield put(nextStep());
  } else if (AppExercise.isCompleted(currentExercise)) {
    if (Series.isCompleted(series)) {
      yield put(showResults({ animate: true }));
    } else {
      yield put(nextExercise());
    }
  }
}

function* handleTestMode(state: ApplicationState) {
  const currentExercise = ApplicationState.toCurrentExercise.get(state);
  const currentStep = ApplicationState.toCurrentStep.get(state);
  const isRetryDialogOpened = ApplicationState.toDialogType.get(state) === DialogType.retry;

  if (isRetryDialogOpened) {
    const afterUnlockAction = ApplicationState.toScreenLockAfterUnlockAction.get(state);
    if (afterUnlockAction) {
      yield put(afterUnlockAction);
    }
    yield put(closeDialog());
  } else if (currentStep?.status === StepStatus.started && nextStepsLocked(currentExercise)) {
    yield put(openDialog({ type: DialogType.enterInputConfirmation }));
  } else {
    yield put(switchExerciseResumable(undefined));
  }
}

export function* userPressedEnterSaga() {
  const state: ApplicationState = yield select();
  const currentExercise = ApplicationState.toCurrentExercise.get(state);
  const currentStep = ApplicationState.toCurrentStep.get(state);
  const isScreenLocked = ApplicationState.toScreenLockType.get(state) === ScreenLockType.loading;

  if (!currentStep || !currentExercise.stepValidationLoaded || isScreenLocked) {
    return;
  }

  if (state.series.mode === SeriesMode.practice) {
    yield handlePracticeMode(state);
  } else if (state.series.mode === SeriesMode.test) {
    yield handleTestMode(state);
  }
}
