import { type ValueSetterMap } from '../../../gizmo-utils/polymorphic-gizmo';

/**
 {
     "a": {value: 1 },
     "b": {value: 2 }
 }
 =>
 {"a:" 1, "b": 2}
 */
export function valueSetterMapToValidatorValueMap(
  valueSetter: ValueSetterMap
): Record<string, number> {
  return Object.keys(valueSetter).reduce((acc, currentValue, index) => {
    acc[currentValue] = valueSetter[currentValue].value;
    return acc;
  }, {});
}
