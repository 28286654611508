import * as React from 'react';
import {
  CheckXLargeBold,
  ChevronLeftLargeBold,
  ChevronRightLargeBold,
  TimesLargeBold,
  TrashXLargeBold,
} from '../icons';
import styles from './icons.scss';
import { type PickerWidgetIcons } from '@seriesplayer/common-ui';

export const icons: PickerWidgetIcons = {
  left: <ChevronLeftLargeBold className={styles.iconBlack} width={12} height={20} />,
  right: <ChevronRightLargeBold className={styles.iconBlack} width={12} height={20} />,
  confirm: <CheckXLargeBold className={styles.iconWhite} width={24} height={18} />,
  delete: <TrashXLargeBold className={styles.iconWhite} width={24} height={24} />,
  close: <TimesLargeBold className={styles.iconWhite} width={20} height={20} />,
};
