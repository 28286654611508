import { type MouseOrTouch } from '@bettermarks/gizmo-types';

// XXX Since Chrome 56 and IOS 10.3 we need to explicitly set event options
// in touch event handlers, to allow preventDefault();
// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
//
// React doesn't support this, so we need to add the event listeners by hand.
// Hopefully this will be resolved in future releases.

export const onMove: EventListener = (evt: any) => {
  evt.preventDefault();
};

export function onTouchStart(evt: MouseOrTouch) {
  evt.preventDefault();
  document.body.style.touchAction = 'none';
  document.addEventListener('mousemove', onMove, { passive: false });
  document.addEventListener('touchmove', onMove, { passive: false });
  document.addEventListener('mouseup', onTouchEnd);
  document.addEventListener('touchend', onTouchEnd);
}

export function onTouchEnd() {
  document.body.style.touchAction = 'auto';
  document.removeEventListener('mousemove', onMove);
  document.removeEventListener('touchmove', onMove);
  document.removeEventListener('mouseup', onTouchEnd);
  document.removeEventListener('touchend', onTouchEnd);
}

export const addTouchStartListener = (ref: any) =>
  ref.current.addEventListener('touchstart', onTouchStart, { passive: false });

export const removeTouchStartListener = (ref: any) =>
  ref.current.removeEventListener('touchstart', onTouchStart);
