import * as React from 'react';
import { identity, pickBy } from 'lodash/fp';
import styles from './graph.scss';
import classNames from 'classnames';
import {
  type GraphEdge,
  type GraphNode,
  isBubbleNode,
  LineStyle,
  LineWeight,
  Position,
} from '@bettermarks/gizmo-types';
import { edgeStart } from './helpers';

const EDGE_WIDTH = 2;
const HIGHLIGHT_EDGE_WIDTH = 4;

export interface GraphEdgeProps {
  node1: GraphNode;
  node2: GraphNode;
  edge: GraphEdge;
}

const lineWidth = (weight: LineWeight): number => {
  switch (weight) {
    case LineWeight.thin:
      return 1;
      break;
    case LineWeight.thick:
      return 4;
      break;
    case LineWeight.medium:
    default:
      return 2;
  }
};

/* eslint-disable-next-line complexity*/
export const GraphEdgeComponent: React.FC<GraphEdgeProps> = ({ node1, node2, edge }) => {
  const { deleteHighlight, highlightColor, color, style, weight, highlight, severity } = edge;

  // edges must be calculated on base of 'actual' position of nodes and
  // nodes are positioned at rounded coordinates!
  const rnode1 = { ...node1, position: Position.round(node1.position) };
  const rnode2 = { ...node2, position: Position.round(node2.position) };

  const p1 = edgeStart(rnode1, rnode2);
  const p2 = edgeStart(rnode2, rnode1);

  const strokeWidth =
    highlightColor || deleteHighlight
      ? HIGHLIGHT_EDGE_WIDTH
      : weight
      ? lineWidth(weight)
      : EDGE_WIDTH;

  const styleClass = classNames(
    styles.edge,
    severity && styles[severity],
    color && styles[color],
    highlightColor && styles[highlightColor],
    {
      [styles.highlight]: highlight,
      [styles.deleteHighlight]: deleteHighlight,
    }
  );

  const connectionCircle = isBubbleNode(node1) && node1.invisible && style !== LineStyle.dashed;

  // the 'circle' tag below is just some artificial circle just to
  // connect 'knee'- edges (two edges without a visible node)
  return (
    <g>
      <circle
        cx={p1.x}
        cy={p1.y}
        // the magic 0.46 should be 0.5, but due to SVG rounding, 0.46 looks better!
        r={Math.round(connectionCircle ? strokeWidth * 0.46 : 0)}
        className={styleClass}
        style={{ strokeWidth: 0 }}
      />
      <line
        className={styleClass}
        vectorEffect="non-scaling-stroke"
        x1={p1.x}
        y1={p1.y}
        x2={p2.x}
        y2={p2.y}
        style={{
          strokeWidth,
          ...pickBy(identity, { stroke: color }), // overridden custom color
          strokeDasharray: style === LineStyle.dashed ? '5 3' : 'none',
        }}
      />
    </g>
  );
};

GraphEdgeComponent.displayName = 'GraphEdge';
