import { type FlexAlign } from '@seriesplayer/common-ui';
import {
  ABSOLUTE_LAYOUT,
  BORDER_LAYOUT,
  LAYOUT_CONTAINER,
} from '../../gizmo-utils/configuration/renderStyles';
import { MATH, SPECIAL } from '../../gizmo-utils/constants';
import { HAlign, VAlign } from '../../gizmo-utils/types';
import {
  type Content,
  type ContentReference,
  type ScalableContent,
} from '../../xml-converter/core';

export const enum Layout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Absolute = 'absolute',
  Border = 'border',
}

export interface HLayoutContent extends Readonly<Content> {
  readonly layout: 'horizontal';
  content: ContentReference[];
  vAlignment: VAlign;
  gap: number;
}

export interface VLayoutContent extends Readonly<Content> {
  readonly layout: 'vertical';
  content: ContentReference[];
  hAlignment: HAlign;
  gap: number;
  fullWidth?: boolean;
}

export interface ALayoutChild extends ContentReference {
  pos: { x: number; y: number };
  hAlign: HAlign;
  vAlign: VAlign;
  scalable: boolean;
  unscaledWidth: number;
  unscaledHeight: number;
  isFormula: boolean;
}

export interface ALayoutContent extends Readonly<ScalableContent> {
  readonly layout: 'absolute';
  readonly content: ReadonlyArray<ALayoutChild>;
  readonly debug: boolean;
  scale: number;
}

export const enum Orientation {
  north = 'north',
  west = 'west',
  center = 'center',
  east = 'east',
  south = 'south',
}

export enum OrientationWithWidth {
  west = 'west',
  center = 'center',
  east = 'east',
}

export const enum BLayoutAlign {
  horizontalAlign = 'horizontalAlign',
  verticalAlign = 'verticalAlign',
}

export type BChildDecoration = {
  backgroundColor?: string;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  horizontalAlign?: HAlign;
  verticalAlign?: VAlign;
};

export type BLayoutDecoration = {
  alignSelf?: FlexAlign;
};

export interface BorderChild extends ContentReference {
  readonly orientation: Orientation;
  width?: number; // value between 0 and 1, unfortunately TS does not provide ranges :(
  decoration?: BChildDecoration;
}

export interface BLayoutContent extends Readonly<Content> {
  readonly layout: 'border';
  readonly content: BorderChild[];
  vGap: number;
  hGap: number;
}

export type LayoutContainerContent =
  | ALayoutContent
  | BLayoutContent
  | HLayoutContent
  | VLayoutContent;

export const DEFAULT_H_LAYOUT_CONTENT: HLayoutContent = {
  $: SPECIAL,
  $renderStyle: LAYOUT_CONTAINER,
  layout: Layout.Horizontal,
  vAlignment: VAlign.top,
  gap: 8,
  content: [],
};

export const DEFAULT_V_LAYOUT_CONTENT: VLayoutContent = {
  $: SPECIAL,
  $renderStyle: LAYOUT_CONTAINER,
  layout: Layout.Vertical,
  hAlignment: HAlign.left,
  gap: 8,
  content: [],
};

export const DEFAULT_B_LAYOUT_CONTENT: BLayoutContent = {
  $: MATH,
  $renderStyle: BORDER_LAYOUT,
  layout: Layout.Border,
  vGap: 6,
  hGap: 8,
  content: [],
};

export const DEFAULT_A_LAYOUT_ALIGNMENT = {
  hAlign: HAlign.right,
  vAlign: VAlign.middle,
};

export const DEFAULT_A_LAYOUT_CONTENT: ALayoutContent = {
  $: SPECIAL,
  $renderStyle: ABSOLUTE_LAYOUT,
  layout: Layout.Absolute,
  content: [],
  unscaledWidth: NaN,
  unscaledHeight: NaN,
  scalable: true,
  scale: 1,
  debug: false,
};
