import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  type CommonCallbacks,
  MULTIPLE_CHOICE_MULTI_DEFAULT_CONTENT,
  type MultipleChoiceMultiContent,
} from '@bettermarks/gizmo-types';
import { defaultTo } from 'lodash';
import * as React from 'react';
import { MultipleChoiceMultiItem } from './MultipleChoiceMultiItem';

import styles from './MultipleChoiceMulti.scss';

export interface MultipleChoiceMultiCallbacks {
  itemChosen?: (item: number) => void;
}

export type MultipleChoiceMultiProps = MultipleChoiceMultiContent &
  MultipleChoiceMultiCallbacks &
  CommonCallbacks &
  ContextState;

export const MultipleChoiceMulti: React.FC<MultipleChoiceMultiProps> = (
  multipleChoiceProps: MultipleChoiceMultiProps
) => {
  const props: MultipleChoiceMultiProps = {
    ...MULTIPLE_CHOICE_MULTI_DEFAULT_CONTENT,
    ...multipleChoiceProps,
  };
  const { choices, disabled, configuration, onFocus, availableWidth } = props;
  const interactive = !props.disabled;
  const elementsPerLine: number = defaultTo<number>(
    configuration.elementsPerLine,
    Number.MAX_VALUE
  );
  const choicesElements = choices.map((element, i) => {
    let itemChosen;
    if (!disabled && props.itemChosen) {
      itemChosen = props.itemChosen.bind(null, i);
    }
    return (
      <React.Fragment key={i}>
        <MultipleChoiceMultiItem
          index={i}
          selected={props.checkedIdx.indexOf(i) > -1}
          content={element}
          interactive={interactive}
          buttons={configuration.buttons}
          onChosen={itemChosen}
          availableWidth={availableWidth}
        />
        {(i + 1) % elementsPerLine === 0 && <div className={styles.flexBreaker} />}
      </React.Fragment>
    );
  });

  return (
    <div className={styles.multipleChoiceMulti} tabIndex={0} onFocus={onFocus}>
      {choicesElements}
    </div>
  );
};

MultipleChoiceMulti.displayName = 'MultipleChoiceMulti';
