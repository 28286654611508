import {
  ADVANCEDAXIS,
  type AdvancedAxisObject,
  type AngleConfiguration,
  type AngleLineType,
  type AngleObject,
  type AngleType,
  ANNOTATION_XML,
  type Annotations,
  AXIS,
  type Axis,
  AxisCapStyle,
  AxisDirection,
  AxisNullPosition,
  type AxisObject,
  type AxisPosition,
  AxisTickStyle,
  BACKGROUND,
  BEZIER,
  type BezierGroupObject,
  BezierSubtype,
  type CircleConfiguration,
  CIRCLESEGMENT,
  type CircleSegmentObject,
  type CommonOptionals,
  CONFIGURATION,
  type ContentReference,
  type Coords,
  type Dict,
  type FElement,
  type GeoConfiguration,
  type GeoConfigurationDisplay,
  type GeoContent,
  type GeoContentBase,
  type GeoDecoration,
  type GeoDecorationKeys,
  type GeoDefaultDecorations,
  GeoEditorMode,
  type GeoLine as Line,
  type GeoLineDecoration,
  type GeoObject,
  type GeoObjectMap,
  type GeoObjectType,
  GRID,
  type GridObject,
  hasInteractionType,
  type IdCoords,
  identity,
  type Importer,
  type ImporterContext,
  InputToolTypes,
  type InterceptTheorem,
  type IntervalConfiguration,
  type IntervalObject,
  IntervalType,
  isAdvancedAxis,
  isLineCapStyle,
  isMFrac,
  isMSpace,
  isMToken,
  LABEL,
  type LabelObject,
  type LabelValuesMap,
  LabelValueTypeMap,
  LAYER,
  type LineObject,
  type LineObjectType,
  type LineWeight,
  NEWLINE,
  NO_SCALE,
  type ParallelsConfiguration,
  POINT,
  type PointObject,
  type PointReadingHelpObject,
  POLYGON,
  type PolygonAsBezier,
  type PolygonObject,
  type ReadingHelpObject,
  RENDER_STYLE,
  type SelectionConfiguration,
  SnapType,
  SPECIAL,
  SUBTYPE_TO_TAG_MAP,
  toBoolean,
  toInt,
  type ToolConfiguration,
  toTrueOrUndefined,
  toXmlElement,
  type Validation,
  type ValidationHint,
  VERTICALLY_MOVABLE_POINT,
  isAngleShowPermanentType,
} from '@bettermarks/gizmo-types';
import { type Severity } from '@bettermarks/umc-kotlin';
import {
  CONTINUOUS_SNAPPING_MARKER,
  DEFAULT_ANGLE_CONFIGURATION,
  DEFAULT_INTERVAL_CONFIGURATION,
  DEFAULT_PARALLELS_CONFIGURATION,
  DEFAULT_PREVLINE_DECORATION,
  INV_POINT_PREFIX,
  MIN_ANGLE_FOR_LABEL,
} from './constants';
import { DEFAULT_SELECT_COLOR } from './tools_constants';
import { intervalLimitsHi, intervalLimitsLo } from './data_constants';
import {
  createPoint,
  geoConfigurationDisplay,
  getPointCoordsId,
  getTicks,
  setAngleFillTransparency,
} from './helper';
import {
  defaultTo,
  flatten,
  forOwn,
  includes,
  invert,
  isEmpty,
  isNil,
  keys,
  map,
  negate,
  pickBy,
  uniq,
  values,
} from 'lodash';
import {
  degToRad,
  numberCartesian,
  polarToCartesian,
  positiveAngle,
  transformationSettings,
} from './math';
import { importImage } from '../image/importer';
import { importFormula } from '../formula/importer/importer';
import { parseDecoString } from '../../gizmo-utils/decoration';
import uuid from 'uuid';
import { type AngleShowPermanent, type StringOrNumber } from '@bettermarks/gizmo-types';

export const importGeo: Importer<GeoContent> = (preContent, xml, context) => {
  /**
   * preventing flash to fb font-size conversion for all our children
   * WHY?
   * font-sizes specified in geo labels should stay as specified, otherwise they might not fit
   */
  context.convertFontSize = false;

  const system = xml.findChildTag('system');
  const key = system.hasAttribute('key') ? system.attribute('key') : undefined;

  const vAxis = system.findChildTagWithAttribute(AXIS, 'direction', 'vertical');
  const hAxis = system.findChildTagWithAttribute(AXIS, 'direction', 'horizontal');
  const vAdvancedAxis = system.findChildTagWithAttribute(ADVANCEDAXIS, 'direction', 'vertical');
  const hAdvancedAxis = system.findChildTagWithAttribute(ADVANCEDAXIS, 'direction', 'horizontal');
  const dAdvancedAxis = system.findChildTagWithAttribute(ADVANCEDAXIS, 'direction', 'diagonal');
  const horizontalAxis = importAxis(hAxis) || importAdvancedAxis(hAdvancedAxis, context);
  const verticalAxis = importAxis(vAxis) || importAdvancedAxis(vAdvancedAxis, context);
  const diagonalAxis = importAdvancedAxis(dAdvancedAxis, context);

  const configurationXml = system.findChildTag(CONFIGURATION);
  const preConfiguration = importConfiguration(configurationXml, context, horizontalAxis);
  const pointSet = system.findChildTagWithAttribute(LAYER, 'type', 'point-set');
  const invisiblePointSet = system.findChildTagWithAttribute(LAYER, 'type', 'invisible-set');
  const circleSet = system.findChildTagWithAttribute(LAYER, 'type', 'circle-set');
  const labelSet = system.findChildTagWithAttribute(LAYER, 'type', 'label-set');
  const bezierSet = system.findChildTagWithAttribute(LAYER, 'type', 'bezier-set');
  const dynamicBezierSet = system.findChildTagWithAttribute(LAYER, 'type', 'dynamic-bezier-set');
  const polygonSet = system.findChildTagWithAttribute(LAYER, 'type', 'polygon-set');
  const raySet = system.findChildTagWithAttribute(LAYER, 'type', 'ray-set');
  const readingHelpSet = system.findChildTagWithAttribute(LAYER, 'type', 'readinghelp-set');
  const segmentSet = system.findChildTagWithAttribute(LAYER, 'type', 'segment-set');
  const straightlineSet = system.findChildTagWithAttribute(LAYER, 'type', 'straightline-set');
  const vectorSet = system.findChildTagWithAttribute(LAYER, 'type', 'vector-set');
  const intervalSet = system.findChildTagWithAttribute(LAYER, 'type', 'interval-set');
  const grid = system.findChildTag(GRID);
  const backgroundImages = importBgContent(system, context);

  const nullLabelPosition = getNullLabelPosition(
    preConfiguration,
    hAxis.exists || hAdvancedAxis.exists,
    vAxis.exists || vAdvancedAxis.exists
  );

  const points = importPointSet(pointSet, preConfiguration.defaultDecorations.points);
  const circles = importCircleSet(circleSet, preConfiguration);
  const invPoints = importPointSet(invisiblePointSet, preConfiguration.defaultDecorations.points);
  const pBeziers = {
    ...importBezierSet(polygonSet), // polygonsets with same structure like bezier sets
    ...importPolygonSet(polygonSet, invPoints), // polygonsets with their special structure
  };
  const oBeziers = importBezierSet(bezierSet); // ordinary bezier sets
  const dBeziers = importDynamicBezierSet(dynamicBezierSet);
  const beziers = { ...pBeziers, ...oBeziers };

  const { lines: rays, invisiblePoints: iPsRays } = importLineSet(raySet);
  const { lines: segments, invisiblePoints: iPsSegments } = importLineSet(segmentSet);
  const { lines: straightlines, invisiblePoints: iPsStraightLines } =
    importLineSet(straightlineSet);
  const { lines: vectors, invisiblePoints: iPsVectors } = importLineSet(vectorSet);
  const { readingHelps, invisiblePoints: iPsReadingH } = importReadingHelpSet(readingHelpSet);

  const invisiblePoints = {
    ...invPoints,
    ...iPsRays,
    ...iPsSegments,
    ...iPsStraightLines,
    ...iPsVectors,
    ...iPsReadingH,
  };

  // we need some attributes from configurations to place labels properly ...
  const labels = importLabelSet(labelSet, preConfiguration, context);
  const intervals = importIntervalSet(intervalSet);

  const geoContentMap = addNotLabelableProp(
    addReferencedBy({
      ...points,
      ...invisiblePoints,
      ...labels,
      ...beziers,
      ...dBeziers,
      ...circles,
      ...segments,
      ...rays,
      ...straightlines,
      ...vectors,
      ...intervals,
      ...readingHelps,
    }),
    keys(labels)
  );

  const ggrid = importGrid(grid, preConfiguration);

  // configuration.snapType import must be postponed till now, because we must know about the grid.
  const snapType = importSnapType(configurationXml.findChildTag('snapType'), !!ggrid);

  const configuration = { ...preConfiguration, nullLabelPosition, snapType };

  const allTools =
    toolHacks(preContent, configuration, points) || importTools(preContent, configuration);

  const supportedTools = allTools.length > 0 ? [GeoEditorMode.SCROLL, ...allTools] : undefined;

  const snappingGrid = setSnappingGrid(configuration);

  const optionals = pickBy(
    {
      key,
      verticalAxis,
      horizontalAxis,
      diagonalAxis,
      backgroundImages,
      grid: ggrid,
    },
    negate(isNil)
  );

  const content: GeoContentBase = {
    ...preContent,
    /**
     * uniqueId for markers/clip-path/filters
     *
     * If two SVGs with the same id are displayed in the same DOM,
     * we run into problems with the above
     *
     * (e.g. for the pdf-review view where multiple exercises are loaded into the same DOM, see
     *    https://bettermarks.atlassian.net/browse/BM-50192
     * )
     *
     * Assumption: ID needs to be unique inside a series
     */
    uniqueId: uuid.v4(), // using v4 explicitly, to be able to stub it in tests
    configuration,
    scale: 1,
    points: keys(points),
    circles: keys(circles),
    invisiblePoints: keys(invisiblePoints),
    labels: keys(labels),
    // make sure polygons are rendered before "ordinary" beziers
    beziers: [...keys(pBeziers), ...keys(oBeziers)],
    dynamicBeziers: keys(dBeziers),
    rays: keys(rays),
    segments: keys(segments),
    straightlines: keys(straightlines),
    vectors: keys(vectors),
    intervals: keys(intervals),
    readinghelps: keys(readingHelps),
    geoContentMap,
    ...(supportedTools && {
      tool: {
        type: InputToolTypes.modeSelector,
        modes: supportedTools,
      },
    }),
    selectedMode:
      (preContent.selectedMode as GeoEditorMode) ||
      (supportedTools
        ? supportedTools[0]
        : hasInteractionType(preContent)
        ? GeoEditorMode.DEFAULT
        : GeoEditorMode.NOT_INTERACTIVE),
    snappingGrid,
    ...optionals,
  };

  const { matrix, gridWidth, gridHeight, totalWidth, totalHeight } =
    transformationSettings(content);
  const scalable = !noScale(configurationXml.findChildTag(NO_SCALE));

  return {
    ...content,
    matrix,
    gridWidth,
    gridHeight,
    totalWidth,
    totalHeight,
    unscaledWidth: totalWidth,
    unscaledHeight: totalHeight,
    scalable,
  };
};

export const importValidationHint = (hint: string): ValidationHint => hint as ValidationHint;

const parseCommonOptionals = (
  xml: FElement,
  defaultDecoration?: GeoDecoration
): CommonOptionals => {
  const decorationS: string = xml.attribute('decoration');
  const { severity, decoration } = importDecorationMap(decorationS, defaultDecoration);
  const dynamicDecorationS: string = xml.attribute('dynamicDecoration');
  const originalDecoS: string = xml.attribute('originalDeco');
  const originalDeco = importDecorationMap(originalDecoS).decoration;
  const selectable = !xml.hasAttribute('unselectable') || !toBoolean(xml.attribute('unselectable'));
  const interactionType = xml.attribute('interaction-type');
  const continuingMark = xml.attribute('continuingMark');
  const addedByUser = toTrueOrUndefined(xml.attribute('addedByUser'));
  const hint = importValidationHint(xml.attribute('hint'));
  return {
    selectable,
    ...(!isEmpty(decoration) && { decoration }),
    ...(!isEmpty(dynamicDecorationS) && { dynamicDecoration: dynamicDecorationS }),
    ...(!isEmpty(originalDeco) && { originalDeco }),
    ...(!isEmpty(severity) && { severity }),
    ...(!isEmpty(interactionType) && { interactionType }),
    ...(!isEmpty(continuingMark) && { continuingMark }),
    ...(!isEmpty(hint) && { hint }),
    ...(addedByUser && { addedByUser }),
  };
};

// already preLabeled objects shall not be labelable
const preLabeled = (refIds: ReadonlyArray<string>, labelIds: ReadonlyArray<string>): boolean =>
  !!refIds.find((id) => includes(labelIds, id));

export function addNotLabelableProp(
  geoContentMap: GeoObjectMap<GeoObject>,
  labelIds: ReadonlyArray<string>
): GeoObjectMap<GeoObject> {
  return keys(geoContentMap).reduce((acc, id) => {
    return {
      ...acc,
      [id]: {
        // tslint:disable-line:no-object-literal-type-assertion
        ...acc[id],
        notLabelable:
          acc[id].addedByUser || !preLabeled(acc[id].referencedBy, labelIds) ? undefined : true,
      } as GeoObject,
    };
  }, geoContentMap);
}

function setSnappingGrid(config: GeoConfiguration): Coords[] {
  const display = config.display;
  const tickValX = config.tickValueInterval.x;
  const tickValY = config.tickValueInterval.y;
  const { xMin, xMax, yMin, yMax } = display;

  /*
     The snapping grid for continuous snapping is a special case: It
     consists of a 'marker' - denoting, that this is a 'continuous'
     snapping grid and 2 gridpoints denoting the boundaries for
     continuous snapping.
  */
  if (config.snapType === SnapType.continuous || config.snapType === SnapType.none) {
    return [CONTINUOUS_SNAPPING_MARKER, { x: xMin, y: yMin }, { x: xMax, y: yMax }];
  }

  const tickCountX = Math.ceil(display.width * config.subSnapping.x + 1);
  const tickCountY = Math.ceil(display.height * config.subSnapping.y + 1);

  return numberCartesian(
    map([...Array(tickCountX)], (_, i) => display.xMin + (i * tickValX) / config.subSnapping.x),
    map([...Array(tickCountY)], (_, i) => display.yMin + (i * tickValY) / config.subSnapping.y)
  );
}

export const polygonInterceptMode = (color: string) =>
  color.replace(`${color}`, `metrics_polygon_selection_${color}`) as GeoEditorMode;

// handles all special cases that would lead to an immediate return in the importTools method
function toolHacks(
  preContent: Annotations,
  configuration: GeoConfiguration,
  points: GeoObjectMap<PointObject>
) {
  if (values(points).find((p) => p.interactionType === VERTICALLY_MOVABLE_POINT)) {
    return [GeoEditorMode.MOVE_POINTS_VERTICALLY];
  }

  if (preContent.$interactionType && preContent.$interactionType === 'metrics-polygonselection') {
    if (configuration.polygonSelectionColor) {
      return [polygonInterceptMode(configuration.polygonSelectionColor)];
    }
    return [GeoEditorMode.COLORING];
  }

  return undefined;
}

// tslint:disable-next-line cyclomatic-complexity
function importTools(
  preContent: Annotations,
  configuration: GeoConfiguration
): ReadonlyArray<GeoEditorMode> {
  const tools: GeoEditorMode[] = [];
  let hasDefaultCursor = false;
  if (preContent.toolSet) {
    map(preContent.toolSet.replace(/\s/g, '').split(';'), (name: GeoEditorMode) => {
      if (name === GeoEditorMode.DEFAULT_CURSOR) {
        hasDefaultCursor = true;
        if (
          // condition to have GeoMoveBeziers in the tools:
          // No user points to move with the standard move tool
          // - neither added with GeoAddPoints,
          // - nor with a line tool or the circle tool (snapType=none)
          preContent.toolSet &&
          !preContent.toolSet.includes(GeoEditorMode.ADD_POINT) &&
          configuration.snapType === SnapType.none
        ) {
          tools.push(name);
        }
      } else if (
        name === GeoEditorMode.SELECT &&
        configuration.toolConfiguration.selectionConfiguration &&
        configuration.toolConfiguration.selectionConfiguration.color
      ) {
        const selectColor = configuration.toolConfiguration.selectionConfiguration.color;
        tools.push(`${name}_${selectColor}` as GeoEditorMode);
      } else {
        tools.push(name);
      }
    });
  }

  if (
    ((hasDefaultCursor && tools.length > 1) ||
      // add move tool for tools that might need "first point selection correction" on touch/small
      includes(tools, GeoEditorMode.ADD_CIRCLE) ||
      includes(tools, GeoEditorMode.ADD_SEGMENT) ||
      includes(tools, GeoEditorMode.ADD_SEGMENT_DASHED) ||
      includes(tools, GeoEditorMode.ADD_VECTOR) ||
      includes(tools, GeoEditorMode.ADD_VECTOR_DASHED) ||
      includes(tools, GeoEditorMode.ADD_RAY) ||
      includes(tools, GeoEditorMode.ADD_RAY_DASHED) ||
      includes(tools, GeoEditorMode.ADD_STRAIGHTLINE) ||
      includes(tools, GeoEditorMode.ADD_STRAIGHTLINE_DASHED)) &&
    // GeoMoveBeziers is on!!!
    !includes(tools, GeoEditorMode.DEFAULT_CURSOR)
  ) {
    tools.push(GeoEditorMode.MOVE);
  }

  // ensure label editing is possible with the add-label-tool
  if (keys(configuration.autoLabeling).length > 0) {
    return uniq([...tools, GeoEditorMode.ADD_LABEL]);
  }

  return uniq(tools);
}

export function importAngleConfiguration(angleConfiguration: FElement): AngleConfiguration {
  const lineType = angleConfiguration.findChildTag('lineType').text as AngleLineType;
  const snapAngle = parseFloat(angleConfiguration.findChildTag('snapAngle').text);
  const type = angleConfiguration.findChildTag('type').text as AngleType;
  const _showPermanent = angleConfiguration.findChildTag('showPermanent').text;
  const showPermanent: AngleShowPermanent = isAngleShowPermanentType(_showPermanent)
    ? _showPermanent
    : 'NONE';

  return {
    ...DEFAULT_ANGLE_CONFIGURATION,
    snapAngle,
    showPermanent,
    ...(!isEmpty(type) && { type }),
    ...(!isEmpty(lineType) && { lineType }),
  };
}

function importParallelsConfiguration(parallelsConfiguration: FElement): ParallelsConfiguration {
  const snapValue = parseFloat(parallelsConfiguration.findChildTag('snapValue').text);
  const hideUnit = parallelsConfiguration.hasChild('hideunit');
  const snapPoints = parallelsConfiguration.hasChild('snapPoints');

  return {
    ...DEFAULT_PARALLELS_CONFIGURATION,
    ...(snapValue ? { snapValue: snapValue } : undefined),
    ...(hideUnit ? { hideUnit: hideUnit } : undefined),
    ...(snapPoints ? { snapPoints: snapPoints } : undefined),
    unit: parallelsConfiguration.findChildTag('unit').text,
  };
}

function getHorizontalDefaultAxisTicks(
  axis: AxisObject,
  display: GeoConfigurationDisplay
): IdCoords[] {
  const { xMax, xMin } = display;
  const { tickValueInterval, tickLabelInterval } = axis;
  const minMajorTick = [...Array(Math.ceil((xMax - xMin) / tickValueInterval) + 1)]
    .map((_, i) => xMin + i * tickValueInterval)
    .find((tickValue) => tickValue % (tickValueInterval * tickLabelInterval) === 0) as number;
  const obtainedTicks = getTicks(
    xMin,
    xMax,
    tickValueInterval,
    tickLabelInterval,
    minMajorTick,
    false
  );

  // we actually do not need the id but set the empty to fulfill type IdCoords
  return [...Array(Math.ceil((xMax - xMin) / tickValueInterval) + 1)].map((_, i) => ({
    id: '',
    coords: obtainedTicks[i].pos,
  }));
}

function getHorizontalAxisTicks(axis: Axis, display: GeoConfigurationDisplay): IdCoords[] {
  return isAdvancedAxis(axis)
    ? getHorizontalAdvancedAxisTicks(axis)
    : getHorizontalDefaultAxisTicks(axis, display);
}

function getHorizontalAdvancedAxisTicks(horizontalAxis: AdvancedAxisObject): IdCoords[] {
  return [
    ...[...Array(horizontalAxis.ticks.length)].map((_, i) => ({
      id: '',
      coords: horizontalAxis.ticks[i].pos,
    })),
    // to ensure snapping to pos=0 in case it is necessary:
    // we always add (0|0) to the list of axis ticks without checking
    // whether it is inside the range of ticks or not
    { id: '', coords: { x: 0, y: 0 } },
  ];
}

function importIntervalConfiguration(
  intervalConfiguration: FElement,
  context: ImporterContext,
  display: GeoConfigurationDisplay,
  horizontalAxis?: Axis
): IntervalConfiguration {
  const type = intervalConfiguration.findChildTag('type').text;
  const showDialog = toBoolean(intervalConfiguration.findChildTag('showDialog').text);
  // we need some lists for our 'limits picker' ...
  const intervalLimits =
    (showDialog && importLabelValues([intervalLimitsLo(), intervalLimitsHi()], context)) || null;

  const defaultDecoration = importDecorationMap(
    intervalConfiguration.findChildTag('defaultDecoration').text
  );
  const helperLine = intervalConfiguration.findChildTag('helperLine');
  const helperLineDeco = helperLine.attribute('decoration');
  const { decoration } = importDecorationMap(helperLineDeco);
  const additionalStepsCheck = intervalConfiguration.hasChild('additionalSteps')
    ? intervalConfiguration.findChildTag('additionalSteps').text
    : undefined;
  const additionalSteps = additionalStepsCheck
    ? additionalStepsCheck
        .split(',')
        .map(parseFloat)
        .filter((v) => !isNaN(v))
    : [];
  const inputRestriction = intervalConfiguration.hasChild('inputRestriction')
    ? toBoolean(intervalConfiguration.findChildTag('inputRestriction').text)
    : undefined;

  const scene = {
    ...DEFAULT_INTERVAL_CONFIGURATION.scene,
    // we actually do not need the id but set the empty to fulfill type IdCoords
    invisibleSnapPoints: uniq([
      ...additionalSteps.map((v) => ({ id: '', coords: { x: v, y: 0 } })),
      ...(horizontalAxis ? getHorizontalAxisTicks(horizontalAxis, display) : []),
    ]),
    snapType: SnapType.none,
  };

  return {
    ...DEFAULT_INTERVAL_CONFIGURATION,
    scene,
    type,
    showDialog,
    decoration: defaultDecoration.decoration,
    helperLine: {
      decoration: helperLineDeco === 'NONE' ? DEFAULT_PREVLINE_DECORATION : decoration,
      visible: toBoolean(helperLine.attribute('visible')),
    },
    ...(intervalLimits && intervalLimits),
    ...(additionalStepsCheck && { additionalSteps }),
    ...(inputRestriction && { inputRestriction }),
  };
}

function importToolConfiguration(
  toolConfiguration: FElement,
  context: ImporterContext,
  display: GeoConfigurationDisplay,
  horizontalAxis?: Axis
): ToolConfiguration {
  return {
    ...(toolConfiguration.hasChild('angle') && {
      angleConfiguration: importAngleConfiguration(toolConfiguration.findChildTag('angle')),
    }),
    ...(toolConfiguration.hasChild('selection') && {
      selectionConfiguration: importSelectionConfiguration(
        toolConfiguration.findChildTag('selection')
      ),
    }),
    ...(toolConfiguration.hasChild('parallels') && {
      parallelsConfiguration: importParallelsConfiguration(
        toolConfiguration.findChildTag('parallels')
      ),
    }),
    ...(toolConfiguration.hasChild('interval') && {
      intervalConfiguration: importIntervalConfiguration(
        toolConfiguration.findChildTag('interval'),
        context,
        display,
        horizontalAxis
      ),
    }),
  };
}

const importInterceptTheorem = (itXML: FElement): InterceptTheorem => {
  const colors = itXML.getChildrenByTagName('color').map((tag) => tag.text);
  return {
    theorem: itXML.findChildTag('theorem').text,
    colors,
  };
};

function importConfiguration(
  configuration: FElement,
  context: ImporterContext,
  horizontalAxis?: Axis
): GeoConfiguration {
  const { width, height, x, y } = configuration
    .findChildTag('display')
    .attributesToProps(parseFloat, ['width', 'height', 'x', 'y']);

  const tickValueInterval = configuration
    .findChildTag('tickValueInterval')
    .attributesToProps(parseFloat, ['x', 'y']);
  const polygonSelectionColor = configuration.hasChild('polygonSelectionColor')
    ? configuration.findChildTag('polygonSelectionColor').text
    : undefined;

  const display = geoConfigurationDisplay(width, height, x, y, tickValueInterval);

  return {
    display,
    tickValueInterval,
    borderExtension: configuration.hasChild('addBorderExtension'),
    showBorder: showBorder(configuration.findChildTag('showBorder')),
    showNullLabel: configuration.hasChild('showNullLabel'),
    nullLabelPosition: AxisNullPosition.None,
    defaultDecorations: importDefaultDecorations(
      configuration.getChildrenByTagName('defaultdecoration')
    ),
    subSnapping: importSubSnapping(configuration.findChildTag('subSnapping')),
    snapType: SnapType.grid,
    autoLabeling: pickBy(
      {
        points: toTrueOrUndefined(configuration.findChildTag('autoPointLabels').text),
        rays: toTrueOrUndefined(configuration.findChildTag('autoRayLabels').text),
        segments: toTrueOrUndefined(configuration.findChildTag('autoSegmentLabels').text),
        straightlines: toTrueOrUndefined(configuration.findChildTag('autoStraightlineLabels').text),
        vectors: toTrueOrUndefined(configuration.findChildTag('autoVectorLabels').text),
      },
      negate(isNil)
    ),
    labelValues: importLabelValues(configuration.getChildrenByTagName('label'), context),
    toolConfiguration: importToolConfiguration(
      configuration.findChildTag('tools'),
      context,
      display,
      horizontalAxis
    ),
    ...pickBy(
      {
        polygonSelectionColor: polygonSelectionColor,
        interceptTheorem: configuration.hasChild('interceptTheorem')
          ? importInterceptTheorem(configuration.findChildTag('interceptTheorem'))
          : undefined,
      },
      negate(isNil)
    ),
  };
}

function createNewLabelContent(value: string, id?: string): FElement {
  // for labels that the user set (i.e. picker labels) the id attrib is mandatory for validation
  const idAttr = !isEmpty(id) ? `id="${id}"` : '';
  return toXmlElement(`
    <semantics>
      <mrow>
        <mtext>${value}</mtext>
      </mrow>
      <annotation-xml encoding="bettermarks">
        <special render-style="text" ${idAttr}/>
      </annotation-xml>
    </semantics>
  `);
}

export function importLabelContent(
  objType: string,
  xmls: FElement[],
  context: ImporterContext,
  style: string
): ReadonlyArray<ContentReference> {
  return xmls.reduce((accVals, val, i) => {
    const mathNode = val.findChildTag('math') || val.findChildTag('semantics');
    // we check for formula content
    // and otherwise create new formula content (with given string as mtext)
    const content = mathNode.exists
      ? mathNode.firstChild.exists
        ? mathNode.firstChild
        : mathNode
      : createNewLabelContent(val.text, `${context.$refid}_${style}_${objType}_${i}`);

    return [...accVals, context.importXML(content)];
  }, []);
}

export function importLabelValues(xmls: FElement[], context: ImporterContext): LabelValuesMap {
  return xmls.reduce((acc, xml) => {
    const { of, auto } = xml.attributesToProps(identity, ['of', 'auto']);
    const valueChildren = xml.getChildrenByTagName('value');

    return {
      ...acc,
      ...(LabelValueTypeMap[of] && {
        [LabelValueTypeMap[of]]: {
          autoLabeling: toBoolean(auto),
          geoLabels: importLabelContent(
            // for repr. as GeoLabels
            of,
            valueChildren,
            context,
            'geo'
          ),
          errorLabels: importLabelContent(
            // for repr. as error highlighted GeoLabels
            of,
            valueChildren,
            context,
            'error'
          ),
          pickerLabels: importLabelContent(
            // for repr. as PickerLabels
            of,
            valueChildren,
            context,
            'picker'
          ),
        },
      }),
    };
  }, {});
}

export function importBgContent(xml: FElement, outer: ImporterContext) {
  /**
   * fetching the bg image tags in reverse order, since the layering in flex puts the first on top
   * and in svg in contrast first becomes the bottom most
   */
  const backgroundXml = xml.getChildrenByTagName(BACKGROUND).reverse();
  if (backgroundXml && backgroundXml.length > 0) {
    // we take care of rendering the content without going through PolymorphicGizmo
    // but the dependency to the images needs to be collected
    const context = outer.tempContext();
    const bgContent = backgroundXml.map((bg) => {
      const position = bg.findChildTag('position');

      return {
        aboveGrid: toBoolean(bg.attribute('aboveGrid')),
        coords: position.attributesToProps(parseFloat, ['x', 'y']),
        ...(position.hasAttribute('valueSetterRefIdsX') && {
          valueSetterRefIdsX: position.attribute('valueSetterRefIdsX'),
        }),
        ...(position.hasAttribute('valueSetterRefIdsY') && {
          valueSetterRefIdsY: position.attribute('valueSetterRefIdsY'),
        }),
        ...(position.hasAttribute('dynamicX') && {
          dynamicX: position.attribute('dynamicX'),
        }),
        ...(position.hasAttribute('dynamicY') && {
          dynamicY: position.attribute('dynamicY'),
        }),
        imageContent: context.invoke(
          importImage,
          bg.findChildTag('math').findChildTag('semantics')
        ),
      };
    });
    return {
      belowGrid: bgContent.filter((img) => !img.aboveGrid),
      aboveGrid: bgContent.filter((img) => img.aboveGrid),
    };
  }
}

export function importSubSnapping(subSnapping: FElement): Coords {
  if (!subSnapping.exists) {
    return { x: 1, y: 1 };
  }

  return subSnapping.attributesToProps(parseFloat, ['x', 'y']);
}

export function importSnapType(snapType: FElement, isGridDefined: boolean): SnapType {
  return snapType.exists
    ? (snapType.text as SnapType)
    : isGridDefined
    ? SnapType.grid
    : SnapType.continuous;
}

export function importDefaultDecorations(elements: FElement[]): GeoDefaultDecorations {
  const result: GeoDefaultDecorations = {};

  map(elements, (element: FElement) => {
    const { decoration } = importDecorationMap(element.text);
    result[`${element.attribute('type')}s`] = decoration as GeoDefaultDecorations;
  });

  return result;
}

export function showBorder(border: FElement): boolean {
  return border.exists ? toBoolean(border.text.trim()) : true;
}

export function noScale(noScale: FElement): true | undefined {
  return noScale.exists && noScale.text.trim().toUpperCase() === 'TRUE' ? true : undefined;
}

export function importDecorationMap(
  decorationS: string,
  defaultDecoration?: GeoDecoration
): { decoration: GeoDecoration; severity: Severity | undefined } {
  const { object, severity } = parseDecoString<GeoDecorationKeys>(decorationS, defaultDecoration);

  const decoration: GeoDecoration = keys(object).reduce((acc, k) => {
    switch (k) {
      // For the importer we will assume that border-weight has the same behavior as
      // line-weight. At the exporter they will become line-weight as there are no differences.
      case 'borderWeight':
        return {
          ...acc,
          lineWeight: object[k] as LineWeight,
        };
      case 'capStyle':
      case 'capStyleTop':
        return {
          ...acc,
          ...(isLineCapStyle(object[k]) && { lineCapStyleTop: object[k] }),
        };
      case 'capStyleBottom':
        return {
          ...acc,
          ...(isLineCapStyle(object[k]) && { lineCapStyleBottom: object[k] }),
        };
      case 'fillTransparency':
      case 'hitWidth':
        return {
          ...acc,
          [k]: parseFloat(object[k] as string),
        };
      default:
        return {
          ...acc,
          [k]: object[k as GeoDecorationKeys],
        };
    }
  }, {});

  return { decoration, severity };
}

export function importPointSet(
  pointSet: FElement,
  defaultDecoration?: GeoDecoration
): GeoObjectMap<PointObject> {
  const points = pointSet.getChildrenByTagName('point');
  return points.reduce((acc, p) => {
    const id = p.attribute('id');
    return {
      ...acc,
      [id]: {
        type: POINT,
        coords: { ...p.attributesToProps(parseFloat, ['x', 'y']) },
        referencedBy: [],
        referringTo: [],
        ...(p.hasAttribute('valueSetterRefIdsX') && {
          valueSetterRefIdsX: p.attribute('valueSetterRefIdsX'),
        }),
        ...(p.hasAttribute('valueSetterRefIdsY') && {
          valueSetterRefIdsY: p.attribute('valueSetterRefIdsY'),
        }),
        ...(p.hasAttribute('dynamicX') && {
          dynamicX: p.attribute('dynamicX'),
        }),
        ...(p.hasAttribute('dynamicY') && {
          dynamicY: p.attribute('dynamicY'),
        }),
        ...(toBoolean(p.attribute('noSnap')) && { noSnap: true }),
        ...parseCommonOptionals(p, defaultDecoration),
      },
    };
  }, {});
}

function importDynamicArrowCurvePoints(
  bezier: FElement,
  points: number[][]
): [StringOrNumber, StringOrNumber][] | undefined {
  let hasDynamicPoint = false;

  const checkIfDynamic = (value: StringOrNumber) => {
    if (typeof value === 'string') {
      hasDynamicPoint = true;
    }
    return value;
  };

  const possiblyDynamicAttributes: [StringOrNumber, StringOrNumber][] = [
    [
      checkIfDynamic(bezier.attribute('dynamicStartX') || points[0][0]),
      checkIfDynamic(bezier.attribute('dynamicStartY') || points[0][1]),
    ],
    [
      checkIfDynamic(bezier.attribute('dynamicMidX') || points[1][0]),
      checkIfDynamic(bezier.attribute('dynamicMidY') || points[1][1]),
    ],
    [
      checkIfDynamic(bezier.attribute('dynamicEndX') || points[2][0]),
      checkIfDynamic(bezier.attribute('dynamicEndY') || points[2][1]),
    ],
  ];

  if (hasDynamicPoint) {
    return possiblyDynamicAttributes;
  }

  return undefined;
}

export function importBezierSet(bezierSet: FElement): GeoObjectMap<BezierGroupObject> {
  return bezierSet
    .filterChildren(
      (b) => b.localName !== 'bezierFunction' && !b.hasAttribute('extendedHitAreaFor')
    )
    .reduce((acc, b) => {
      const subtype = (invert(SUBTYPE_TO_TAG_MAP) as { [key: string]: string })[b.localName];
      const id = b.attribute('id');
      const name = b.attribute('name');

      const translation = b
        .findChildTag('translation')
        .attributesToProps(parseFloat, ['x', 'y', 'top', 'bottom', 'right', 'left']);
      const moveGroup = b
        .findChildTag('moveGroup')
        .text.replace(/[\s]/g, '')
        .split(';')
        .filter((v) => !isEmpty(v));

      const beziers = b.getChildrenByTagName('bezier').map((bz) => {
        const { decoration } = importDecorationMap(bz.attribute('decoration'));

        const points = bz.text
          .replace(/[\n\t\s]/g, '')
          .split(';')
          .filter((s) => !isEmpty(s))
          .map((s) => s.split(',').map(parseFloat));

        const dynamicPoints = name === 'arrow' && importDynamicArrowCurvePoints(b, points);

        return {
          points,
          ...(dynamicPoints && { dynamicPoints }),
          ...(!isEmpty(decoration) && { decoration }),
        };
      });

      return {
        ...acc,
        [id]: {
          type: BEZIER,
          subtype: subtype,
          beziers,
          referencedBy: [],
          referringTo: [],
          ...parseCommonOptionals(b),
          ...(!isEmpty(name) && { name }),
          ...(!isEmpty(translation) && { translation }),
          ...(!isEmpty(moveGroup) && { moveGroup }),
        },
      };
    }, {});
}

export function importDynamicBezierSet(
  dynamicBezierSet: FElement
): GeoObjectMap<CircleSegmentObject | PolygonObject> {
  const circleSegments = dynamicBezierSet
    .filterChildren((b) => b.localName === 'circleSegment')
    .reduce((acc, b) => {
      return importCircleSegment(acc, b);
    }, {});

  const polygons = dynamicBezierSet
    .filterChildren((b) => b.localName === 'polygon')
    .reduce((acc, b) => {
      return importPolygon(acc, b);
    }, {});

  return {
    ...circleSegments,
    ...polygons,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function importPolygon(acc: {}, b: FElement): GeoObjectMap<PolygonObject> {
  const id = b.attribute('id');
  const labelOffset = b.attribute('labelOffset');
  const decoration = b.attribute('decoration');

  return {
    ...acc,
    [id]: {
      type: POLYGON,
      decoration,
      labelOffset,
      refIds: b.findChildTag('refIds').text.split(';'),
      points: [],
      referencedBy: [],
      referringTo: [],
      selectable: false,
      ...parseCommonOptionals(b),
    },
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function importCircleSegment(acc: {}, b: FElement): GeoObjectMap<CircleSegmentObject> {
  const id = b.attribute('id');
  const labelOffset = b.attribute('labelOffset');
  const decoration = b.attribute('decoration');

  const attrs = b.attributesToProps(
    identity,
    [
      'dynamicX',
      'dynamicY',
      'dynamicOuterRadius',
      'dynamicInnerRadius',
      'dynamicStartAngle',
      'dynamicAngle',
    ],
    [
      'valueSetterRefIdsX',
      'valueSetterRefIdsY',
      'valueSetterRefIdsOuterRadius',
      'valueSetterRefIdsInnerRadius',
      'valueSetterRefIdsStartAngle',
      'valueSetterRefIdsAngle',
    ]
  );

  return {
    ...acc,
    [id]: {
      type: CIRCLESEGMENT,
      ...attrs,
      decoration,
      labelOffset,
      points: [],
      referencedBy: [],
      referringTo: [],
      selectable: false,
      ...parseCommonOptionals(b),
    },
  };
}

// some special 'remapping' for polygons.
const importPolygonDecorationMap = (
  decoString: string
): { decoration: GeoDecoration; severity: Severity | undefined } => {
  const { decoration, severity } = importDecorationMap(decoString);

  return {
    decoration: {
      ...decoration,
      ...(decoration && decoration.fillColor && { color: decoration.fillColor }),
    },
    severity,
  };
};

export function importPolygonSet(
  polygonSet: FElement,
  points: GeoObjectMap<PointObject>
): GeoObjectMap<PolygonAsBezier> {
  const polygonHitAreas: Dict<FElement> = {};
  const regularPolygons: FElement[] = [];

  polygonSet
    .filterChildren((p) => p.localName === 'polygon')
    .map((p) => {
      if (p.hasAttribute('extendedHitAreaFor')) {
        const key = p.attribute('extendedHitAreaFor', '');
        if (!isEmpty(key)) {
          polygonHitAreas[key] = p;
        }
      } else {
        regularPolygons.push(p);
      }
    });

  return regularPolygons.reduce((acc, p) => {
    const id = p.attribute('id');
    const name = p.attribute('name');
    const decorationS: string = p.attribute('decoration');
    const { severity, decoration } = importPolygonDecorationMap(decorationS);

    const ids = p.findChildTag('refIds').text.split(';');

    const extendedHitArea =
      'interceptName' in decoration && decoration.interceptName in polygonHitAreas
        ? polygonHitAreas[decoration.interceptName]
        : undefined;
    const hitAreaIds = extendedHitArea
      ? extendedHitArea.findChildTag('refIds').text.split(';')
      : undefined;

    const beziers = [
      {
        points: flatten(
          ids.map((s) => [s, s]) // points have to be doubled to display bezier properly!
        )
          .concat(ids[0]) // figure needs to be closed explicitely!
          .map((s) => [points[s].coords.x, points[s].coords.y]),
        ...(hitAreaIds && {
          hitAreaPoints: flatten(
            hitAreaIds.map((s) => [s, s]) // points have to be doubled to display bezier properly!
          )
            .concat(hitAreaIds[0]) // figure needs to be closed explicitely!
            .map((s) => [points[s].coords.x, points[s].coords.y]),
        }),
      },
    ];

    return {
      ...acc,
      [id]: {
        pointIds: ids, // importing refids of vertices to export back as polygon (validation!)
        ...(hitAreaIds && { hitAreaPointIds: hitAreaIds }), // - // -
        type: BEZIER,
        subtype: BezierSubtype.areas,
        beziers,
        referencedBy: [],
        referringTo: [],
        ...parseCommonOptionals(p),
        selectable: false,
        name: !isEmpty(name) ? name : beziers[0].points.toString(), // need name for validation
        ...(!isEmpty(severity) && { severity }),
        ...(!isEmpty(decoration) && { decoration }),
      },
    };
  }, {});
}

function importCircleConfiguration(configuration: FElement): CircleConfiguration | undefined {
  let circleConfiguration: CircleConfiguration;
  if (configuration.hasChildren()) {
    const snapPoints = configuration.hasChild('snapPoints');
    // When snapPoints is true, set default to 0.01, otherwise to 1
    const snapInterval = defaultTo<number>(
      parseFloat(configuration.findChildTag('snapInterval').text),
      snapPoints ? 0.01 : 1
    );
    const unit = configuration.findChildTag('unit').text;
    const hideunit = configuration.hasChild('hideunit');
    const hideradius = configuration.hasChild('hideradius');
    circleConfiguration = {
      snapInterval,
      unit,
      ...(hideunit ? { hideUnit: true } : undefined),
      ...(hideradius ? { hideRadius: true } : undefined),
      ...(snapPoints ? { snapPoints: true } : undefined),
    };
    return circleConfiguration;
  }
}

export function importCircleSet(circleSet: FElement, geoConfiguration: GeoConfiguration) {
  const circles = circleSet.getChildrenByTagName('circle');
  const configuration = circleSet.findChildTag('configuration');
  const circleConfiguration = importCircleConfiguration(configuration);
  if (!isEmpty(circleConfiguration)) {
    if (isNil(geoConfiguration.toolConfiguration)) {
      geoConfiguration.toolConfiguration = { circleConfiguration };
    } else {
      geoConfiguration.toolConfiguration = {
        ...geoConfiguration.toolConfiguration,
        circleConfiguration,
      };
    }
  }
  return circles.reduce((acc, c) => {
    const id = c.attribute('id');

    return {
      ...acc,
      [id]: {
        type: 'circles',
        coords: { ...c.attributesToProps(parseFloat, ['x', 'y']) },
        radius: parseFloat(c.attribute('radius')),
        referencedBy: [],
        referringTo: [],
        ...parseCommonOptionals(c),
        ...(!isEmpty(circleConfiguration) && {
          configuration: circleConfiguration,
        }),
        ...(c.hasAttribute('valueSetterRefIdsX') && {
          valueSetterRefIdsX: c.attribute('valueSetterRefIdsX'),
        }),
        ...(c.hasAttribute('valueSetterRefIdsY') && {
          valueSetterRefIdsY: c.attribute('valueSetterRefIdsY'),
        }),
        ...(c.hasAttribute('valueSetterRefIdsRadius') && {
          valueSetterRefIdsRadius: c.attribute('valueSetterRefIdsRadius'),
        }),
        ...(c.hasAttribute('dynamicX') && {
          dynamicX: c.attribute('dynamicX'),
        }),
        ...(c.hasAttribute('dynamicY') && {
          dynamicY: c.attribute('dynamicY'),
        }),
        ...(c.hasAttribute('dynamicRadius') && {
          dynamicRadius: c.attribute('dynamicRadius'),
        }),
      },
    };
  }, {});
}

export function importLineSet(lineSet: FElement): {
  lines: GeoObjectMap<LineObject>;
  invisiblePoints: GeoObjectMap<PointObject>;
} {
  if (!lineSet.hasAttribute('type')) {
    return { lines: {}, invisiblePoints: {} };
  }

  const childTagName = lineSet.attribute('type').split('-set')[0];
  const lines = lineSet.getChildrenByTagName(childTagName);
  return lines.reduce(
    (acc, s) => {
      const id = s.attribute('id');
      let newInvPoints = {};
      const points = s.getChildrenByTagName('position').map((p) => {
        if (p.hasAttribute('refid')) {
          return p.attribute('refid');
        }

        // add new points to the GeoObjectMap when they are referenced via coords
        const coords = p.attributesToProps(parseFloat, ['x', 'y']) as Coords;
        const pId = getPointCoordsId(coords, INV_POINT_PREFIX);
        // create point and remove addedByUser from it
        const { addedByUser, ...point } = createPoint(coords, {}, true);
        newInvPoints = {
          ...newInvPoints,
          [pId]: { ...point, selectable: false },
        };

        return pId;
      });
      const [p1, p2] = points;

      const line: LineObject = {
        type: `${childTagName}s` as LineObjectType,
        p1Id: p1,
        p2Id: p2,
        referencedBy: [],
        referringTo: points,
        ...parseCommonOptionals(s),
      };
      return {
        lines: {
          ...acc.lines,
          [id]: line,
        },
        invisiblePoints: {
          ...acc.invisiblePoints,
          ...newInvPoints,
        },
      };
    },
    { lines: {}, invisiblePoints: {} }
  );
}

/**
 some heuristics for angle label distance like so (angles in deg):
 1.6 -------
 ---          \
 1            ----------
 0.5                      ----
 0  0----10...30.........90...
 */
const angleLabelDistance = (
  angle: number,
  k = (1.6 - 1) / (MIN_ANGLE_FOR_LABEL - 30),
  d = 1.6 - k * MIN_ANGLE_FOR_LABEL
): number =>
  angle <= MIN_ANGLE_FOR_LABEL ? 1.6 : angle < 30 ? k * angle + d : angle < 90 ? 1 : 0.5;

export const getAngleLabelPosition = (
  tickvalueInterval: number,
  vertex: Coords,
  start: number,
  end: number,
  rad: number,
  labelOffset: number,
  angle = positiveAngle(end - start) % 360,
  alpha = degToRad((positiveAngle(start) + 0.5 * angle) % 360),
  shift = angle < 90 ? tickvalueInterval * 0.55 : 0,
  // similar to the flex logic: prefer distance settings using labeloffset, if given!
  // using '0.7 * (rad + labeloffset)' seems to place the labels better
  distance = labelOffset !== 0 ? 0.7 * (rad + labelOffset) : angleLabelDistance(angle) * rad + shift
): Coords => polarToCartesian(vertex, distance, alpha);

const isLabelStepper = (xml: FElement) =>
  xml.getPath([ANNOTATION_XML, SPECIAL]).attribute(RENDER_STYLE) === 'alpha-numeric-stepper';

const getLabelStepperText = (xml: FElement) =>
  xml.getPath(['mrow', 'configuration', 'currentValue']).text;

const isEmptyLabelStepper = (
  xml: FElement,
  labelContentXML = xml.findChildTag('math').firstChild
) => isLabelStepper(labelContentXML) && isEmpty(getLabelStepperText(labelContentXML));

const importLabelFormula = (
  xml: FElement,
  context: ImporterContext,
  labelId?: string
): ContentReference => {
  let labelContentXML = xml.findChildTag('math').firstChild;

  // check for interaction-type 'alpha-numeric-stepper' and import the current value as
  // LabelContent
  if (isLabelStepper(labelContentXML)) {
    labelContentXML = createNewLabelContent(getLabelStepperText(labelContentXML), labelId);
  }
  return context.importXML(labelContentXML);
};

export function importAngleLabels(
  labelSet: FElement,
  config: GeoConfiguration,
  context: ImporterContext
): GeoObjectMap<LabelObject> {
  const angleLabels = labelSet.getChildrenByTagName('angle');
  return angleLabels.reduce((acc, l) => {
    const id = l.attribute('id');
    const decorationS: string = l.attribute('decoration');
    const point = l.attributesToProps(parseFloat, ['x', 'y']) as Coords;
    const {
      end: endAngle,
      labelOffset,
      radius,
      start: startAngle,
    } = l.attributesToProps(parseFloat, ['end', 'start'], ['labelOffset', 'radius']);

    const tickvalueInterval = Math.max(config.tickValueInterval.x, config.tickValueInterval.y);
    const position = getAngleLabelPosition(
      tickvalueInterval,
      point,
      startAngle,
      endAngle,
      defaultTo(radius, tickvalueInterval),
      defaultTo(labelOffset, 0)
    );

    const ref = importLabelFormula(l, context);
    const { severity, decoration } = importDecorationMap(decorationS);

    return {
      ...acc,
      [id]: {
        type: LABEL,
        content: ref,
        referencedBy: [],
        referringTo: [],
        position,
        distance: labelOffset,
        ...(!isEmpty(decoration) && { decoration }),
        ...(!isEmpty(severity) && { severity }),
      },
    };
  }, {});
}

export function importLabelSet(
  labelSet: FElement,
  config: GeoConfiguration,
  context: ImporterContext
): GeoObjectMap<LabelObject> {
  const labels = labelSet.getChildrenByTagName('label');

  const labelObjects = labels.reduce((acc, l) => {
    // we don't want to import empty label steppers, the validator does not like them
    if (isEmptyLabelStepper(l)) {
      return acc;
    }

    const id = l.attribute('id');
    const strValProps = l.attributesToProps(identity, [], ['styleType', 'align']);
    const numValProps = l.attributesToProps(parseFloat, [], ['distance', 'shift']);
    const addedByUser = toTrueOrUndefined(l.attribute('addedByUser'));

    const positionEl = l.findChildTag('position');
    const refid = positionEl.attribute('refid');
    const position = positionEl.attributesToProps(parseFloat, [], ['x', 'y']);

    const ref = importLabelFormula(l, context, id);
    const { severity, decoration } = importDecorationMap(l.attribute('decoration'));

    return {
      ...acc,
      [id]: {
        type: 'labels',
        content: ref,
        referencedBy: [],
        referringTo: isEmpty(refid) ? [] : [refid],
        ...strValProps,
        ...numValProps,
        ...(!isEmpty(refid) && { refid }),
        ...(!isEmpty(position) && { position }),
        ...(!isEmpty(decoration) && { decoration }),
        ...(severity && { severity }),
        ...(addedByUser && { addedByUser }),
      },
    };
  }, {});
  const angleLabelObjects = importAngleLabels(labelSet, config, context);
  return {
    ...labelObjects,
    ...angleLabelObjects,
  };
}

export function importIntervalSet(intervalSet: FElement): GeoObjectMap<IntervalObject> {
  if (!intervalSet.hasAttribute('type')) {
    return {};
  }

  const childTagName = intervalSet.attribute('type').split('-set')[0];
  const intervals = intervalSet.getChildrenByTagName(childTagName);

  return intervals.reduce((acc, i) => {
    const { id, direction, min, max, type } = i.attributesToProps(identity, [
      'id',
      'direction',
      'min',
      'max',
      'type',
    ]);

    return {
      ...acc,
      [id]: {
        type: 'intervals',
        intervalType: type in invert(IntervalType) ? type : IntervalType.none,
        direction: direction as AxisDirection,
        min,
        max,
        referencedBy: [],
        referringTo: [],
        ...parseCommonOptionals(i),
      },
    };
  }, {});
}

export function importAxis(axis: FElement): AxisObject | undefined {
  if (!axis.exists) {
    return undefined;
  }

  const axisContent = axis.attributesToProps(identity, ['id', 'direction']);
  const capStyle =
    axis.attribute('capStyle') === 'none' ? AxisCapStyle.None : AxisCapStyle.Triangle;

  const tickLabelInterval = defaultTo<number>(
    parseFloat(axis.findChildTag('tickLabelInterval').text),
    1
  );
  const tickValueInterval = defaultTo<number>(
    parseFloat(axis.findChildTag('tickValueInterval').text),
    1
  );
  const labelText = axis.findChildTag('label').text;
  const label = !isEmpty(labelText)
    ? labelText
    : axisContent.direction === 'horizontal'
    ? 'x'
    : 'y';

  return {
    ...axisContent,
    direction: axisContent.direction as AxisDirection,
    tickLabelInterval,
    tickValueInterval,
    capStyle,
    label,
  };
}

export function importAdvancedAxis(
  axis: FElement,
  context: ImporterContext
): AdvancedAxisObject | undefined {
  if (!axis.exists) {
    return undefined;
  }

  const axisContent = {
    ...axis.attributesToProps(identity, ['id', 'direction']),
    ...axis.attributesToProps(toBoolean, [], ['onlyPositive']),
  };
  const contractionEnabled = toBoolean(axis.attribute('contractionEnabled'));
  const capStyle = axis.attribute('capStyle', AxisCapStyle.Triangle) as AxisCapStyle;

  const [width, height] = ['width', 'height'].map((attr) => toInt(axis.attribute(attr)));

  const positionTag = axis.findChildTag('position');

  let position: AxisPosition | number;
  if (positionTag.exists && positionTag.hasAttribute('x1')) {
    position = {
      min: {
        x: parseFloat(positionTag.attribute('x')),
        y: parseFloat(positionTag.attribute('y')),
      },
      max: {
        x: parseFloat(positionTag.attribute('x1')),
        y: parseFloat(positionTag.attribute('y1')),
      },
    };
  } else {
    position = defaultTo<number>(toInt(positionTag.text), 0);
  }

  let label: string | ContentReference;
  const labelNode = axis.findChildTag('label');
  if (labelNode.findChildTag('mrow').hasChild('semantics')) {
    label = context.importXML(labelNode.firstChild.firstChild);
  } else {
    label = labelNode.text;
  }

  let hasAxisTickLabels = false;
  let maxLabelLength = 0;
  let maxLabelLines = 1;

  const importAxisTickContent = (
    tick: FElement,
    tickDecoration?: GeoDecoration
  ): ContentReference | undefined => {
    if (!tick.findChildTag('math').text) {
      return undefined;
    }

    const formula = context.invoke(importFormula, tick.findChildTag('math').firstChild);

    /**
     * Best guess maximum label from formula:
     * Take sum of all mn, mtext, mo, mi children's lengths of formula & check against previous max
     */
    const tokens = formula.content.filter(isMToken);
    maxLabelLength = Math.max(
      maxLabelLength,
      tokens.reduce((acc, t) => acc + t.text.length, 0)
    );

    /**
     * Detect newlines and fractions (relevant to calculate needed vertical space for x-axis labels)
     *
     * Simplification of reality:
     * Assume no newlines when there is a fraction
     * (-> fraction 2 + 1; 1 for numerator, 1 for denominator, 1 for padding and vinculum)
     * Consequence:
     * Label with [fraction, newline, fraction] will look cut off
     */
    const newlines = formula.content.filter((c) => isMSpace(c, NEWLINE)).length;
    const fraction = Number(!!formula.content.find(isMFrac));
    maxLabelLines = Math.max(maxLabelLines, fraction * 2 + newlines + 1);

    /**
     * Remove font-size specification from formula to enable rendering labels in the
     * correct font size (AXIS_LABEL_FONT_SIZE)
     */
    const formulaWoutFontSize = tick
      .findChildTag('math')
      .firstChild.toString()
      .replace(/font-size:(\s?[0-9]*;?)/g, '');

    if (tickDecoration) {
      return context.importXML(toXmlElement(`${formulaWoutFontSize}; ${tickDecoration}`));
    }

    return context.importXML(toXmlElement(formulaWoutFontSize));
  };

  const ticks = axis.getChildrenByTagName('tick').map((tick) => {
    let pos = { x: 0, y: 0 };

    if (axisContent.direction === AxisDirection.diagonal) {
      pos = tick.attributesToProps(parseFloat, ['x', 'y']) as Coords;
    } else {
      const tickPos = defaultTo<number>(parseFloat(tick.attribute('pos')), 0);

      pos =
        axisContent.direction === AxisDirection.horizontal
          ? { x: tickPos, y: 0 }
          : { x: 0, y: tickPos };
    }

    const decorationS: string = tick.attribute('decoration');
    const { decoration } = importDecorationMap(decorationS);

    const label = importAxisTickContent(tick, decoration);

    if (!isNil(label) && (pos.x !== 0 || pos.y !== 0)) {
      hasAxisTickLabels = true;
    }

    const style: AxisTickStyle = (tick.attribute('style') as AxisTickStyle) || AxisTickStyle.NORMAL;

    return { pos, label, decoration, style };
  });

  return {
    ...axisContent,
    direction: axisContent.direction as AxisDirection,
    contractionEnabled,
    capStyle,
    ...(isNaN(width) ? null : { width }),
    ...(isNaN(height) ? null : { height }),
    position,
    label,
    ticks,
    hasAxisTickLabels,
    maxLabelLength,
    maxLabelLines,
  };
}

function isWest(cx: number, width: number) {
  return cx - width * 0.5 === 0;
}

function isSouth(cy: number, height: number) {
  return cy - height * 0.5 === 0;
}

function getNullLabelPosition(configuration: GeoConfiguration, hAxis: boolean, vAxis: boolean) {
  const {
    display: { cx, cy, width, height },
    showNullLabel,
  } = configuration;

  return !showNullLabel
    ? AxisNullPosition.None
    : hAxis && vAxis
    ? isWest(cx, width) === isSouth(cy, height)
      ? AxisNullPosition.SouthWest
      : isWest(cx, width)
      ? AxisNullPosition.West
      : AxisNullPosition.South
    : hAxis
    ? AxisNullPosition.South
    : AxisNullPosition.West;
}

export const extractGridLinePositions = (tag: FElement): number[] => {
  const content: string = tag.text;

  if (!content) {
    return [];
  }

  return content.split(',').map((a) => {
    return parseFloat(a);
  });
};

export const getGridLines = (
  grid: FElement,
  lineType: string,
  tickCount: number,
  startTickValue: number,
  min: number,
  max: number,
  tickValueInterval: number
): Line[] => {
  const gridLineTag = grid.findChildTag(lineType);

  let ticks: number[];
  if (gridLineTag.exists) {
    ticks = extractGridLinePositions(gridLineTag);
  } else {
    ticks = map([...Array(tickCount + 1)], (_, i) => startTickValue + i * tickValueInterval);
  }

  return ticks.map((t) =>
    lineType === 'hLines' ? { x1: min, y1: t, x2: max, y2: t } : { x1: t, y1: min, x2: t, y2: max }
  );
};

export function importGrid(grid: FElement, config: GeoConfiguration): GridObject | undefined {
  if (!grid.exists) {
    return undefined;
  }

  const id = grid.attribute('id');
  const display = config.display;

  const width: number = defaultTo<number>(parseFloat(grid.attribute('width')), display.width);
  const height: number = defaultTo<number>(parseFloat(grid.attribute('height')), display.height);
  const yMin = display.yMax - height * config.tickValueInterval.y;
  const xMax = display.xMin + width * config.tickValueInterval.x;
  return {
    width,
    height,
    id,
    quadrantLabelsVisible: toBoolean(grid.attribute('quadrantLabelsVisible')),
    hLines: getGridLines(
      grid,
      'hLines',
      height,
      yMin,
      display.xMin,
      xMax,
      config.tickValueInterval.y
    ),
    vLines: getGridLines(
      grid,
      'vLines',
      width,
      display.xMin,
      yMin,
      display.yMax,
      config.tickValueInterval.x
    ),
    ...pickBy(
      {
        color: grid.hasAttribute('color') ? grid.attribute('color') : undefined,
      },
      negate(isNil)
    ),
  };
}

export const importPointReadingHelp = (
  readingHelps: FElement[],
  type: GeoObjectType
): {
  readingHelps: GeoObjectMap<PointReadingHelpObject>;
  invisiblePoints: GeoObjectMap<PointObject>;
} =>
  readingHelps.reduce(
    (acc, s) => {
      const id = s.attribute('id');
      const decorationS: string = s.attribute('decoration');
      const positionTags = s.getChildrenByTagName('position');
      const position = positionTags.find((p) => !p.hasAttribute('type'));

      if (isNil(position)) {
        return acc;
      }

      let pointId = '';
      let newInvPoint = {};
      if (position.hasAttribute('refId')) {
        pointId = position.attribute('refId');
      } else {
        // add new points to the GeoObjectMap when they are referenced via coords
        const coords = position.attributesToProps(parseFloat, ['x', 'y']) as Coords;
        pointId = getPointCoordsId(coords, INV_POINT_PREFIX);
        const { addedByUser, ...point } = createPoint(coords, {}, true);
        newInvPoint = { [pointId]: { ...point, selectable: false } };
      }

      let axesPoints;
      let planePoint;
      const planePointPositionTag = positionTags.find((p) => p.attribute('type') === 'plane-point');

      if (!isNil(planePointPositionTag)) {
        axesPoints = positionTags
          .filter((p) => p.attribute('type') === 'axis-point')
          .map((ap) => ap.attributesToProps(parseFloat, ['x', 'y']));
        planePoint = planePointPositionTag.attributesToProps(parseFloat, ['x', 'y']);
      }

      const { severity, decoration } = importDecorationMap(decorationS);

      const item: PointReadingHelpObject = {
        decoration: decoration as GeoLineDecoration,
        pointId,
        referencedBy: [],
        referringTo: [pointId],
        type,
        ...(axesPoints && { axesPoints }),
        ...(planePoint && { planePoint }),
        ...(severity && { severity }),
      };

      return {
        readingHelps: { ...acc.readingHelps, [id]: item },
        invisiblePoints: { ...acc.invisiblePoints, ...newInvPoint },
      };
    },
    { readingHelps: {}, invisiblePoints: {} }
  );

export const importAngles = (angles: FElement[], type: GeoObjectType): GeoObjectMap<AngleObject> =>
  angles.reduce((acc, s) => {
    const id = s.attribute('id');
    const decorationS: string = s.attribute('decoration');
    const coords = s.attributesToProps(parseFloat, ['x', 'y']) as Coords;
    const {
      end: endAngle,
      labelOffset,
      radius,
      start: startAngle,
    } = s.attributesToProps(parseFloat, ['end', 'radius', 'start'], ['labelOffset']);
    const angle = {
      coords,
      endAngle,
      hasAngleLegs: toBoolean(s.attribute('hasAngleLegs')),
      radius,
      startAngle,
    };
    const { severity, decoration } = importDecorationMap(decorationS);
    const { selectable, ...otherCommonOptionals } = parseCommonOptionals(s);

    return {
      ...acc,
      [id]: {
        ...angle,
        referencedBy: [],
        referringTo: [],
        ...otherCommonOptionals,
        type,
        ...pickBy(
          {
            decoration: decoration && {
              ...setAngleFillTransparency(decoration),
            },
            severity,
            isRightAngle: toTrueOrUndefined(s.attribute('isRightAngle')),
            labelOffset,
          },
          (v) => !isNil(v)
        ),
        ...(s.hasAttribute('valueSetterRefIdsX') && {
          valueSetterRefIdsX: s.attribute('valueSetterRefIdsX'),
        }),
        ...(s.hasAttribute('valueSetterRefIdsY') && {
          valueSetterRefIdsY: s.attribute('valueSetterRefIdsY'),
        }),
        ...(s.hasAttribute('valueSetterRefIdsStartAngleX') && {
          valueSetterRefIdsStartAngleX: s.attribute('valueSetterRefIdsStartAngleX'),
        }),
        ...(s.hasAttribute('valueSetterRefIdsStartAngleY') && {
          valueSetterRefIdsStartAngleY: s.attribute('valueSetterRefIdsStartAngleY'),
        }),
        ...(s.hasAttribute('dynamicX') && {
          dynamicX: s.attribute('dynamicX'),
        }),
        ...(s.hasAttribute('dynamicY') && {
          dynamicY: s.attribute('dynamicY'),
        }),
        ...(s.hasAttribute('dynamicStartAngleX') && {
          dynamicStartAngleX: s.attribute('dynamicStartAngleX'),
        }),
        ...(s.hasAttribute('dynamicStartAngleY') && {
          dynamicStartAngleY: s.attribute('dynamicStartAngleY'),
        }),
        ...(s.hasAttribute('dynamicEndAngleX') && {
          dynamicEndAngleX: s.attribute('dynamicEndAngleX'),
        }),
        ...(s.hasAttribute('dynamicEndAngleY') && {
          dynamicEndAngleY: s.attribute('dynamicEndAngleY'),
        }),
      },
    };
  }, {});

export function importReadingHelpSet(readingHelpSet: FElement): {
  readingHelps: GeoObjectMap<ReadingHelpObject>;
  invisiblePoints: GeoObjectMap<PointObject>;
} {
  if (!readingHelpSet.hasAttribute('type')) {
    return { readingHelps: {}, invisiblePoints: {} };
  }

  const childTagName = readingHelpSet.attribute('type').split('-set')[0];
  const { readingHelps, invisiblePoints } = importPointReadingHelp(
    readingHelpSet.getChildrenByTagName(childTagName),
    `${childTagName}s` as GeoObjectType
  );
  const angles = importAngles(
    readingHelpSet.getChildrenByTagName('angle'),
    `${childTagName}s` as GeoObjectType
  );
  return {
    readingHelps: {
      ...angles,
      ...readingHelps,
    },
    invisiblePoints,
  };
}

/**
 * add referencedBy to geo content
 *
 * @param {GeoObjectMap<GeoObject>} map
 * @returns {GeoObjectMap<GeoObject>}
 */
export function addReferencedBy(map: GeoObjectMap<GeoObject>): GeoObjectMap<GeoObject> {
  let outMap = map;

  forOwn(map, (object, id) => {
    object.referringTo.forEach((refid) => {
      if (refid in outMap) {
        // avoid trying to access not yet imported geo objects
        const idList: string[] = uniq([...outMap[refid].referencedBy, id]);

        outMap = {
          ...outMap,
          [refid]: {
            ...outMap[refid],
            referencedBy: idList,
          },
        };
      }
    });
  });

  return outMap;
}

export function importSelectionConfiguration(configXML: FElement): SelectionConfiguration {
  const validation = configXML.attribute('validation') as Validation;
  const color = configXML.hasChild('color')
    ? configXML.findChildTag('color').text
    : DEFAULT_SELECT_COLOR;
  return {
    validation,
    color,
  };
}
