import { type GizmoRoute } from './types';

const managers_showroom = 'managers_showroom.xml';

/*
 * Please keep in alphabetic order
 */

const absolute_layout = 'absolute-layout.xml';
const animation = 'animation.xml';
const balance = 'balance.xml';
const ballot_box = 'ballot-box.xml';
const basic_shape = 'basic-shape.xml';
const border_layout = 'border-layout.xml';
const box = 'box.xml';
const calendar = 'calendar.xml';
const clock = 'clock.xml';
const clock_time = 'clock-time.xml';
const chart_bar = 'chart-bar.xml';
const chart_caption = 'chart-caption.xml';
const chart_pie = 'chart-pie.xml';
const chart_stacked_bar = 'chart-stacked-bar.xml';
const day_duration = 'day-duration.xml';
const draw2d = 'draw2d.xml';
const dragdrop_layout_endless = 'dragdrop-layout-endless.xml';
const dragdrop_layout_horizontal_divided = 'dragdrop-layout-horizontal-divided.xml';
const dragdrop_width_as_factor_of_max_item_width = 'dragdrop-width-as-factor-of-max-item-width.xml';
const dragdrop_layout_table = 'dragdrop-layout-table.xml';
const dragdrop_misc_images = 'dragdrop-misc-images.xml';
const dragdrop_misc_images_error = 'dragdrop-misc-images-error.xml';
const dragdrop_misc_metrics = 'dragdrop-misc-metrics.xml';
const dragdrop_border = 'dragdrop-border.xml';
const dragdrop_none = 'dragdrop-none.xml';
const dragdrop_none_transparent = 'dragdrop-none-transparent.xml';
const dragdrop_rectangle = 'dragdrop-rectangle.xml';
const dragdrop_rectangle_alignment = 'dragdrop-rectangle-alignment.xml';
const dragdrop_rectangle_alignment_autosize = 'dragdrop-rectangle-alignment-autosize.xml';
const dragdrop_rectangle_alignment_geo = 'dragdrop-rectangle-alignment-geo.xml';
const dragdrop_rectangle_decorated = 'dragdrop-rectangle-decorated.xml';
const dragdrop_autosize = 'dragdrop-autosize.xml';
const dragdrop_set = 'dragdrop-set.xml';
const dragdrop_set_with_errors = 'dragdrop-set-with-errors.xml';
const dragdrop_set_item_align = 'dragdrop-set-item-align.xml';
const dragdrop_sorting = 'dragdrop-sorting.xml';
const dynamic_representation_with_controls = 'dynamic-representation-with-controls.xml';
const dynamic_representation_with_slider = 'dynamic-representation-with-slider.xml';
const dynamic_representation_with_value_stepper = 'dynamic-representation-with-value-stepper.xml';
const error_message = 'error-message.xml';
const field_set = 'fieldset.xml';
const formula_basic = 'formula-basic.xml';
const formula_linebreak = 'formula-linebreak.xml';
const formula_mexpansion = 'formula-mexpansion.xml';
const formula_msubsup = 'formula-msubsup.xml';
const formula_munderover = 'formula-munderover.xml';
const formula_integration = 'formula-integration.xml';
const formula_interaction = 'formula-interaction.xml';
const formula_torture = 'formula-torture.xml';
const fraction_form = 'fraction-form.xml';
const freedraganddrop = 'freedraganddrop.xml';
const function_plotter = 'function-plotter.xml';
const function_plotter_labels = 'function-plotter-labels.xml';
const function_plotter_intersection_lines = 'function-plotter-intersection-lines.xml';
const function_plotter_tangents_secants = 'function-plotter-tangents-secants.xml';
const geo = 'geo.xml';
const geo_add_labels = 'geo-add-labels.xml';
const geo_axes = 'geo-axes.xml';
const geo_axes_advanced = 'geo-axes-advanced.xml';
const geo_background_image = 'geo-background-image.xml';
const geo_beziers = 'geo-beziers.xml';
const geo_grid = 'geo-grid.xml';
const geo_grid_placement_snap = 'geo-grid-placement-snap.xml';
const geo_interactive = 'geo-interactive.xml';
const geo_rendering_angles = 'geo-rendering-angles.xml';
const geo_rendering_points = 'geo-rendering-points.xml';
const geo_rendering_circles = 'geo-rendering-circles.xml';
const geo_rendering_reading_help = 'geo-rendering-reading-help.xml';
const geo_rendering_lines = 'geo-rendering-lines.xml';
const geo_rendering_intervals = 'geo-rendering-intervals.xml';
const geo_tool_angleline = 'geo-tool-angleline.xml';
const geo_tool_color = 'geo-tool-color.xml';
const geo_tool_interval = 'geo-tool-interval.xml';
const geo_tool_middlepoint = 'geo-tool-middlepoint.xml';
const geo_tool_move = 'geo-tool-move.xml';
const geo_tool_move_points_vertically = 'geo-tool-move-points-vertically.xml';
const geo_tool_parallels = 'geo-tool-parallels.xml';
const geo_tool_perpendicular = 'geo-tool-perpendicular.xml';
const geo_tool_select = 'geo-tool-select.xml';
const graph_treechart_01 = 'graph-treechart-01.xml';
const graph_treechart_02 = 'graph-treechart-02.xml';
const graph_treechart_03 = 'graph-treechart-03.xml';
const graph_treechart_04 = 'graph-treechart-04.xml';
const graph_treechart_05 = 'graph-treechart-05.xml';
const graph_treechart_06 = 'graph-treechart-06.xml';
const graph_treechart_07 = 'graph-treechart-07.xml';
const graph_treechart_08 = 'graph-treechart-08.xml';
const graph_treechart_09 = 'graph-treechart-09.xml';
const graph_treechart_10 = 'graph-treechart-10.xml';
const graph_treechart_11 = 'graph-treechart-11.xml';
const graph_treechart_12 = 'graph-treechart-12.xml';
const graph_graph = 'graph-graph.xml';
const hello_gizmo = 'hello-gizmo.xml';
const image_gizmo = 'image-gizmo.xml';
const include_gizmo = 'include-gizmo.xml';
const item_list = 'item-list.xml';
const layout_container = 'layout-container.xml';
const line_header = 'line-header.xml';
const mc_single_stack_layout = 'mc-single-stack-layout.xml';
const multiple_choice_dropdown = 'multiple-choice-dropdown.xml';
const multiple_choice_single = 'multiple-choice-single.xml';
const multiple_choice_multi = 'multiple-choice-multi.xml';
const nlp_text = 'nlp-text.xml';
const pdf_link = 'pdf-link-gizmo.xml';
const pointer = 'pointer.xml';
const solution_set = 'solution-set.xml';
const squared_paper = 'squared-paper.xml';
const table_decorations = 'table-decorations.xml';
const table_container = 'table-container.xml';
const table_hline = 'table-hline.xml';
const table_hscroll = 'table-hscroll.xml';
const table_linearize = 'table-linearize.xml';
const table_place_value = 'table-place-value.xml';
const table_plain_mtable = 'table-plain-mtable.xml';
const thermometer = 'thermometer.xml';
const time_duration = 'time-duration.xml';
const time_span = 'time-span.xml';
const wheel_of_fortune = 'wheel-of-fortune.xml';
const value_stepper = 'value-stepper.xml';

const XMLs = {
  managers_showroom,
  absolute_layout,
  animation,
  balance,
  ballot_box,
  basic_shape,
  box,
  border_layout,
  calendar,
  chart_bar,
  chart_caption,
  chart_pie,
  chart_stacked_bar,
  clock,
  clock_time,
  day_duration,
  draw2d,
  dragdrop_autosize,
  dragdrop_border,
  dragdrop_layout_endless,
  dragdrop_layout_horizontal_divided,
  dragdrop_width_as_factor_of_max_item_width,
  dragdrop_layout_table,
  dragdrop_misc_images,
  dragdrop_misc_images_error,
  dragdrop_misc_metrics,
  dragdrop_none,
  dragdrop_none_transparent,
  dragdrop_rectangle,
  dragdrop_rectangle_alignment,
  dragdrop_rectangle_alignment_autosize,
  dragdrop_rectangle_alignment_geo,
  dragdrop_rectangle_decorated,
  dragdrop_set,
  dragdrop_set_with_errors,
  dragdrop_set_item_align,
  dragdrop_sorting,
  dynamic_representation_with_controls,
  dynamic_representation_with_slider,
  dynamic_representation_with_value_stepper,
  error_message,
  field_set,
  formula_basic,
  formula_linebreak,
  formula_integration,
  formula_interaction,
  formula_mexpansion,
  formula_msubsup,
  formula_munderover,
  formula_torture,
  fraction_form,
  freedraganddrop,
  function_plotter,
  function_plotter_labels,
  function_plotter_intersection_lines,
  function_plotter_tangents_secants,
  geo,
  geo_add_labels,
  geo_axes,
  geo_axes_advanced,
  geo_background_image,
  geo_beziers,
  geo_grid,
  geo_grid_placement_snap,
  geo_interactive,
  geo_rendering_angles,
  geo_rendering_points,
  geo_rendering_circles,
  geo_rendering_intervals,
  geo_rendering_reading_help,
  geo_rendering_lines,
  geo_tool_angleline,
  geo_tool_color,
  geo_tool_interval,
  geo_tool_middlepoint,
  geo_tool_move,
  geo_tool_move_points_vertically,
  geo_tool_parallels,
  geo_tool_perpendicular,
  geo_tool_select,
  graph_treechart_01,
  graph_treechart_02,
  graph_treechart_03,
  graph_treechart_04,
  graph_treechart_05,
  graph_treechart_06,
  graph_treechart_07,
  graph_treechart_08,
  graph_treechart_09,
  graph_treechart_10,
  graph_treechart_11,
  graph_treechart_12,
  graph_graph,
  hello_gizmo,
  image_gizmo,
  include_gizmo,
  item_list,
  layout_container,
  line_header,
  mc_single_stack_layout,
  multiple_choice_dropdown,
  multiple_choice_single,
  multiple_choice_multi,
  nlp_text,
  pdf_link,
  pointer,
  solution_set,
  squared_paper,
  table_container,
  table_decorations,
  table_hline,
  table_hscroll,
  table_linearize,
  table_place_value,
  table_plain_mtable,
  thermometer,
  time_duration,
  time_span,
  wheel_of_fortune,
  value_stepper,
};

export default XMLs as Readonly<typeof XMLs>;
export const isGizmoRoute = (input: string): input is GizmoRoute => input in XMLs;
