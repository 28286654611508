import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type Dispatch } from 'redux';
import noop from 'lodash/noop';
import styled from 'styled-components';

import { defaultTheme, StyledBox, Layout as BaseLayout } from '@seriesplayer/common-ui';
import { LocaleProvider } from '../../../../gizmo-utils/polymorphic-gizmo';

import {
  type ApplicationState,
  CollapsibleContentState,
  LoaderState,
  SeriesFlow,
  SeriesMode,
} from '../../../../types';

import { getAvailableHeight, getAvailableWidth } from '../../services/runtime-manager';
import { Exercise } from '../Exercise/Exercise';
import { HeaderSize, PdfReviewHeader } from '../../components/PdfReviewHeader/PdfReviewHeader';
import { PdfReviewOverview } from '../../components/PdfReviewOverview/PdfReviewOverview';
import { Loader } from '../../components/Loader';
import { fetchPdfReview } from './actions';
import { getBackToNsp } from './quitSeriesPlayerSaga';
import { parseSeriesPlayerPdfReviewQueryString } from './parseSeriesPlayerPdfReviewQueryString';
import { useLocation } from 'react-router-dom';

const Box = styled(StyledBox)`
  flex: 1 1 auto;
`;

const Layout = styled(BaseLayout)`
  background-color: ${defaultTheme.colors.cWhite};
`;

export const SeriesPlayerPdfReview = () => {
  const location = useLocation();
  const { loaded, series, mediaUrl } = useSelector((state: ApplicationState) => ({
    loaded: state.loaderState === LoaderState.success,
    series: state.series,
    mediaUrl: state.appSettings.staticMediaUrl,
  }));

  const { score, handoverDate, ...seriesParams } = parseSeriesPlayerPdfReviewQueryString(
    location.search
  );

  const dispatch: Dispatch = useDispatch();

  React.useEffect(() => {
    location && location.search && dispatch(fetchPdfReview(seriesParams));
  }, [location]);

  const [availableWidth, availableHeight] = [getAvailableWidth(), getAvailableHeight()];

  return (
    <LocaleProvider
      contentLocale={series.exercises.length > 0 ? series.exercises[0].locale : 'en_US'}
    >
      <Loader loaded={loaded} onQuit={getBackToNsp}>
        <Layout fullsize>
          <Box stretch>
            <Layout vertical>
              <Box id="content" stretch scrollable>
                <PdfReviewOverview score={score} handoverDate={handoverDate} />
                {series.title && <PdfReviewHeader title={series.title} size={HeaderSize.large} />}
                {series.exercises.map(({ id, setting, steps }, i) => (
                  <React.Fragment key={id}>
                    <PdfReviewHeader
                      title={{
                        i18nKey: 'seriesplayer:header.exercise',
                        param: {
                          currentExercise: i + 1,
                          total: series.exercises.length,
                        },
                      }}
                      size={HeaderSize.small}
                    />
                    <Exercise
                      exerciseId={id}
                      setting={setting}
                      steps={steps}
                      availableWidth={availableWidth}
                      availableHeight={availableHeight}
                      isTouch={false}
                      wrapupState={CollapsibleContentState.collapsed}
                      mode={SeriesMode.review}
                      flow={SeriesFlow.linear}
                      staticMediaUrl={mediaUrl}
                      dispatch={noop as Dispatch}
                    />
                  </React.Fragment>
                ))}
              </Box>
            </Layout>
          </Box>
        </Layout>
      </Loader>
    </LocaleProvider>
  );
};
