import { type colors } from '@seriesplayer/common-ui';
import type { Coords } from '@bettermarks/gizmo-types';
import { type SelectedDialog } from '../../types';
import { type ExampleNavigationItem } from '../example-mode/ExampleNavigation';
import { initialNavigationItem } from '../example-mode/ExerciseInExampleMode';
import type { EditorMode, ToolbarElements } from '@bettermarks/gizmo-types';
import type { KeyboardStateProps } from '../seriesplayer/containers/Toolbar/Tools/Keyboard/Keyboard';

const tools = [
  'back',
  'magnifier',
  'help-menu',
  'fullscreen',
  'cursor',
  'content-tools',
  'pen',
  'eraser',
  'undo',
  'redo',
  'reset',
] as const;
export type Tool = typeof tools[number];

export const penColors = [
  'cGray800',
  'cBlue700',
  'cGreen700',
  'cRed700',
  'cYellow400',
  'cWhite',
] as const;

// We use typeof colors to verify that the defined penColor is defined in the colors array of bm-ui-components
export type PenColor = keyof Pick<typeof colors, typeof penColors[number]>;

export type ToolConfig = {
  penThickness: number;
  eraserThickness: number;
  color: PenColor;
};

export type ContentToolConfig = {
  tools: ToolbarElements[];
  modes: EditorMode[];
  editorMode?: EditorMode;
  keyboard?: Partial<KeyboardStateProps>;
  gizmoId?: string;
  openDrawerName?: ToolbarElements | Nullable<string>;
};

export type ScaleSetting = 'auto' | 'off' | 'bigger' | 'smaller';

export type ScaleConfig = {
  dimensions: Dimensions;
  scaleType: 'auto' | 'user';
  currentScale: number;
};

export type ToolInfo = {
  selectedTool: Omit<Tool, 'magnifier'> | null;
  toolConfig: ToolConfig;
  contentToolConfig: ContentToolConfig;
};

export enum Action {
  draw = 'draw',
  erase = 'erase',
  stop = 'stop',
}

export type CanvasDimensions = { canvasWidth: number; canvasHeight: number };

export type Slice = {
  action: Action;
  position: Coords;
  dimensions: CanvasDimensions;
  toolInfo: ToolInfo;
};

export type ActionStack = Slice[];

export type WhiteboardState = {
  canvasActionStack: ActionStack;
  activeDialog: SelectedDialog | null;
  isFullScreen: boolean;
  zoomPopupActive: boolean;
  scaleConfig: ScaleConfig;
  resultManagerUrl: string;
  selectedNavigationItem: ExampleNavigationItem;
  canvasHeight: number;
  id?: string;
} & ToolInfo;

export const defaultWhiteboardState: Omit<WhiteboardState, 'resultManagerUrl'> = {
  canvasActionStack: [],
  selectedTool: 'cursor',
  contentToolConfig: {
    tools: [],
    modes: [],
  },
  zoomPopupActive: false,
  toolConfig: {
    penThickness: 4,
    eraserThickness: 4,
    color: 'cGray800',
  },
  activeDialog: null,
  isFullScreen: false,
  scaleConfig: {
    scaleType: 'auto',
    currentScale: 1,
    dimensions: { width: window.innerWidth, height: window.innerHeight },
  },
  selectedNavigationItem: initialNavigationItem,
  canvasHeight: window.innerHeight,
};

export type Dimensions = {
  width: number;
  height: number;
};
