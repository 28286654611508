import { ContentColor } from '@bettermarks/gizmo-types';
import { ACCORDION_HEIGHT_PIXEL } from '@bettermarks/importers';

/* ------------------ */
/*  GENERAL DEFAULTS  */
/* ------------------ */

export const DEFAULT_COLORS = [
  ContentColor.BM_BLUE,
  ContentColor.BM_PURPLE,
  ContentColor.BM_YELLOW,
  ContentColor.BM_LIGHT_BLUE,
  ContentColor.BM_DARK_GREEN,
  ContentColor.BM_MEDIUM_STEELBLUE,
  ContentColor.BM_GREEN,
  ContentColor.BM_PETROL,
  ContentColor.BM_LIGHT_GREEN,
  ContentColor.BM_PINK,
  ContentColor.BM_LIGHT_STEELBLUE,
  ContentColor.BM_ORANGE,
];

/* ------------------ */
/* BAR CHART DEFAULTS */
/* ------------------ */

// general
export const DEFAULT_TICK_WIDTH = 21;
export const MAX_ROW_WIDTH = 800;
export const DEFAULT_BORDER_STROKE = '#999999'; // $c-gray-600

// bars
export const DEFAULT_BAR_WIDTH = 44;
export const DEFAULT_BAR_MIN_HEIGHT = 10;
export const DEFAULT_GROUPS_GAP_SMALL = 18;
export const DEFAULT_GROUPS_GAP_MEDIUM = 28;
export const DEFAULT_BAR_GAP = 8;
export const DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH = 2;

// handle
export const DEFAULT_HANDLE_WIDTH = 44;
export const DEFAULT_HANDLE_HEIGHT = 44;
export const DEFAULT_HANDLE_DISTANCE = 4;

// axes
export const DEFAULT_XAXIS_SPACE = 16;
export const DEFAULT_YAXIS_SPACE = 12;
export const DEFAULT_AXIS_LABEL_MARGIN = 5;
export const DEFAULT_ARROW_LENGTH = 15;
export const DEFAULT_READHELP_LABEL_BORDER_RADIUS = 10;
export const DEFAULT_READHELP_LABEL_PADDING = 8;
export const DEFAULT_YAXIS_TICK_LABEL_OFFSET = 5;
export const DEFAULT_YAXIS_ADDITIONAL_BOTTOM_SPACE = 5;
export const DEFAULT_YAXIS_LABEL_PADDING = 8;
export const DEFAULT_XAXIS_LABEL_SPACE = 200;
export const DEFAULT_AXIS_TICK_LENGTH_MAJOR = 8;
export const DEFAULT_AXIS_TICK_LENGTH_MINOR = 4;
export const DEFAULT_CHAR_WIDTH = 6;
export const DEFAULT_CHAR_HEIGHT = 12;
export const DEFAULT_YAXIS_EXTENSION =
  DEFAULT_HANDLE_HEIGHT -
  DEFAULT_ARROW_LENGTH +
  DEFAULT_HANDLE_DISTANCE +
  DEFAULT_YAXIS_LABEL_PADDING;

// axis contraction
export const DEFAULT_AXIS_CONTRACTION_HEIGHT = 2 * ACCORDION_HEIGHT_PIXEL;

// readingHelp
export const DEFAULT_READINGHELP_COLOR = 'bm-light-grey';
export const DEFAULT_READINGHELP_DASHED_ARRAY = '7 4';
export const DEFAULT_READINGHELP_STROKE_WIDTH = 1;

/* --------------- */
/* LEGEND DEFAULTS */
/* --------------- */

// legend defaults
export const DEFAULT_LEGEND_GAP = 40;
export const DEFAULT_LEGEND_GAP_SMALL = 20;

/* ------------------ */
/* STACKED BAR CHART DEFAULTS */
/* ------------------ */
export const DEFAULT_STACKED_BAR_HEIGHT = 48;
export const DEFAULT_STACKED_BAR_MAX_WIDTH = 800;
export const DEFAULT_STACKED_BAR_OFFSET = 20;
export const DEFAULT_STACKED_BAR_LABEL_SPACE = 20;
export const DEFAULT_STACKED_BAR_STROKE = 'white';
export const DEFAULT_STACKED_BAR_STROKE_WIDTH = 2;
export const DEFAULT_STACKED_BAR_TICK = 5;

/* ------------------ */
/* PIE CHART DEFAULTS */
/* ------------------ */
export const DEFAULT_PIE_CHART_RADIUS = 120;
export const DEFAULT_PIE_CHART_OFFSET_X = 60;
export const DEFAULT_PIE_CHART_OFFSET_Y = 55;
export const DEFAULT_PIE_CHART_CENTER_X = DEFAULT_PIE_CHART_RADIUS + DEFAULT_PIE_CHART_OFFSET_X;
export const DEFAULT_PIE_CHART_CENTER_Y = DEFAULT_PIE_CHART_RADIUS + DEFAULT_PIE_CHART_OFFSET_Y;
export const DEFAULT_PIE_CHART_STROKE = 'white';
export const DEFAULT_PIE_CHART_STROKE_WIDTH = 2;
export const DEFAULT_PIE_CHART_LABEL_OFFSET = 5;
export const DEFAULT_PIE_CHART_MARK_EXTENSION = 15;
export const DEFAULT_PIE_CHART_MIN_SCALE = 0.5;
