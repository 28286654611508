import * as React from 'react';
import { type ContentReference, HAlignment, VAlignment } from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../polymorphic-gizmo';

import Measure from 'react-measure';

import styles from './styles.scss';

export type SVGForeignObjectProps = {
  content: ContentReference;
  x: number;
  y: number;
  availableWidth: number;
  hAlign: HAlignment | undefined;
  vAlign: VAlignment | undefined;
  onMeasured?: (width: number, height: number) => void;
};

export type SVGForeignObjectState = {
  width: number;
  height: number;
};

export class SVGForeignObject extends React.Component<
  SVGForeignObjectProps,
  SVGForeignObjectState
> {
  state = {
    width: 0,
    height: 0,
  };

  onResize = ({ offset }: any) => {
    this.setState({ ...offset });

    this.props.onMeasured && this.props.onMeasured(this.state.width, this.state.height);
  };

  render() {
    const { availableWidth, content, hAlign, vAlign, x, y } = this.props;
    const { width, height } = this.state;

    const posX =
      x - (hAlign === HAlignment.middle ? width * 0.5 : hAlign === HAlignment.end ? width : 0);
    const posY =
      y - (vAlign === VAlignment.middle ? height * 0.5 : vAlign === VAlignment.bottom ? height : 0);
    return (
      <foreignObject
        transform={`translate(${posX}, ${posY})`}
        width={width === 0 ? availableWidth : width + 20}
        height={height === 0 ? 200 : height}
      >
        <div className={styles.foreignObject}>
          <Measure offset onResize={this.onResize}>
            {({ measureRef }) => (
              <div ref={measureRef}>
                <PolymorphicGizmo refid={content.$refid} availableWidth={availableWidth} />
              </div>
            )}
          </Measure>
        </div>
      </foreignObject>
    );
  }
}
