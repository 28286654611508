import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { NLPText, type NLPTextProps, type NLPTextCallbacks } from './NLPText';
import { changeText } from './reducer';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): NLPTextCallbacks => ({
  onTextChange: (text: string) => {
    dispatch(gizmoAction(changeText(text), refid));
  },
});

export const NLPTextContainer = connectGizmo<NLPTextProps>(mapDispatchToProps, NLPText);
