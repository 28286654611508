import * as React from 'react';

import { type Sector } from '@bettermarks/gizmo-types';
import styles from './styles.scss';
import { getNamedColor } from '@bettermarks/importers';
import classNames from 'classnames';
import { CX, CY, RADIUS } from './WheelOfFortune';
import { getLabelColor } from './helper';
export type SectorComponentProps = Sector &
  Readonly<{
    index: number;
    total: number;
  }>;

const LABEL_OFFSET = 20;

export const SectorComponent: React.FC<SectorComponentProps> = ({ index, total, color, label }) => {
  // Maths for drawing the path
  // angle for each sector of circle
  const sectorAngle = (2 * Math.PI) / total;
  // offset calculated as it starts from the vertical axis
  const offset = 0.5 * Math.PI + sectorAngle / 2;

  /* Calculating the startangle and end angle for each sectors.
    Angle of arc starting is calculated by offset and index of each sector.
    Angle of arc ending is calculated by adding the sector angle to the start angle */
  const thetaStart = offset + index * sectorAngle;
  const thetaEnd = thetaStart + sectorAngle;
  // calculate the start x,y  coordinates of the arc
  const startx = CX + RADIUS * Math.cos(thetaStart);
  const starty = CY - RADIUS * Math.sin(thetaStart);
  // calculate the end coordinates of the arc
  const endx = CX + RADIUS * Math.cos(thetaEnd);
  const endy = CY - RADIUS * Math.sin(thetaEnd);

  // Drawing path for sector based on above values
  const path = `
    M${CX},${CY}
    L${startx},${starty}
    A${RADIUS},${RADIUS} 0 0 0 ${endx},${endy}
    Z
  `;

  // Maths for calculating text position and rotation
  const thetaText = thetaStart + sectorAngle / 2;
  /* The labels will be 20px from the circle boundary,
  so the LABEL_OFFSET is 20*/
  const LABEL_DISTANCE = RADIUS - LABEL_OFFSET;
  // x,y coordinates of the text is calculated
  const textX = CX + LABEL_DISTANCE * Math.cos(thetaText);
  const textY = CY - LABEL_DISTANCE * Math.sin(thetaText);

  // The labels are rotated by (PI- (0.5*PI + startangle + 0.5 *sectorangle))
  const rotateText = ((0.5 * Math.PI - thetaText) * 180) / Math.PI;

  // The colors received from the parsed xml are mapped to corresponding color codes.
  const fillColor = getNamedColor(color);
  const classname = classNames(styles.strokeWidth);
  const labelColor = getLabelColor(color);
  return (
    <g>
      <path id={index.toString()} d={path} className={classname} fill={fillColor} />
      <text
        x={textX}
        textAnchor="middle"
        y={textY}
        fill={labelColor}
        className={styles.sectorText}
        transform={`rotate(${rotateText} ${textX} ${textY})`}
      >
        {label}
      </text>
    </g>
  );
};

SectorComponent.displayName = 'SectorComponent';
