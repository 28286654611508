import { connect } from 'react-redux';

import { type Dispatch } from '../../../../gizmo-utils/redux';
import { type ApplicationState } from '../../../../types';

import { type LightBoxDispatchProps, type LightBoxStateProps, LightBox } from './LightBox';
import { closeLightBox } from '../SeriesPlayer/actions';

const mapDispatchToProps = (dispatch: Dispatch): LightBoxDispatchProps => ({
  onClose: () => {
    dispatch(closeLightBox());
  },
});

const mapStateToProps = ({ lightBoxType }: ApplicationState): LightBoxStateProps => ({
  lightBoxType,
});

export const LightBoxContainer = connect(mapStateToProps, mapDispatchToProps)(LightBox);
