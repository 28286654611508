import {
  type ArrowDirection,
  CONFIGURATION,
  DIRECTION,
  type Importer,
  SEMANTICS,
  TIME_DURATION_DEFAULT_CONTENT,
  type TimeDurationGizmoContent,
  xmlText,
} from '@bettermarks/gizmo-types';
import { limitInputDigits } from '../day-duration/importer';

export const importTimeDuration: Importer<TimeDurationGizmoContent> = (
  preContent,
  xml,
  context
) => {
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);
  const direction = xmlText(configuration.findChildTag(DIRECTION)) as ArrowDirection;
  const [hourRef, minuteRef] = mrow
    .filterChildren((child) => child.localName === SEMANTICS)
    .map((gizmo) => limitInputDigits(2)(gizmo, context));
  return {
    ...preContent,
    ...TIME_DURATION_DEFAULT_CONTENT,
    direction,
    hourRef,
    minuteRef,
  };
};
