import * as React from 'react';
import { BezierArea, BezierLine } from '../../components';
import { type CircleSegmentObject, type PolygonObject } from '@bettermarks/gizmo-types';
import { type SetProps, type WithBorderRect } from './types';
import {
  DEFAULT_BEZIER_DECORATION,
  DEFAULT_LINE_DECORATION,
  transformX,
  transformY,
} from '@bettermarks/importers';

export type DynamicBezierSetProps = SetProps &
  WithBorderRect & {
    dynamicBeziers: ReadonlyArray<string>;
    geoId: string;
  };

export const DynamicBezierSet: React.FC<DynamicBezierSetProps> = ({
  dynamicBeziers,
  geoContentMap,
  matrix,
  mode,
  borderRectId,
  geoId,
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  return (
    <g clipPath={`url(#${borderRectId})`}>
      {
        // checking on dynamicBeziers with ? operator, since there are user submissions around that
        // do not yet contain the property dynamicBeziers
        dynamicBeziers?.map((id, i) => {
          const b = geoContentMap[id] as CircleSegmentObject | PolygonObject;

          const props = {
            geoId,
            mode,
            points: b.points.map((p) => [sx(p[0]), sy(p[1])]) as [number, number][],
            id,
          };

          const areaProps = {
            ...props,
            decoration: {
              ...DEFAULT_BEZIER_DECORATION,
              ...b.decoration,
            },
            bezierId: `${id}:${i}:area`,
          };

          const lineProps = {
            ...props,
            decoration: {
              ...DEFAULT_LINE_DECORATION,
              ...b.decoration,
            },
            bezierId: `${id}:${i}:line`,
          };

          return (
            <g key={id}>
              <BezierArea {...areaProps} />
              <BezierLine {...lineProps} closed />
            </g>
          );
        })
      }
    </g>
  );
};

DynamicBezierSet.displayName = 'DynamicBezierSet';
