import * as React from 'react';
import type * as CSS from 'csstype';
import classNames from 'classnames';

import styles from './MathML.scss';

export type BucketProps = {
  children: React.ReactNode;
  height?: number;
  stretched?: boolean;
  // @ts-ignore
  textAlign?: CSS.Property.TextAlign;
  inline?: boolean;
};

/**
 * Since the height of an Mrow that is calculated based on fontSize is not reliable,
 * `Bucket` can be used to enforce the calculated height for a part of a math element.
 * It is only meant for doing that for a single element,
 * which works because every bucket is wrapped in an MRow.
 *
 * You can optionally set a text-align that will be applied as a style.
 * (Every MRow takes care of resetting the `text-align`.)
 */
export const Bucket: React.FC<BucketProps> = ({
  children,
  height,
  stretched,
  textAlign,
  inline = false,
}) => (
  <div
    className={classNames(styles.bucket, { [styles.inline]: inline })}
    style={{
      height,
      width: stretched ? '100%' : undefined,
      textAlign,
    }}
  >
    {children}
  </div>
);

Bucket.displayName = 'Bucket';
