import { type TFunction } from 'i18next';
import * as React from 'react';
import { type Severity } from '@bettermarks/umc-kotlin';
import {
  type CommonCallbacks,
  MULTIPLE_CHOICE_DROP_DOWN_DEFAULT_CONTENT,
  type MultipleChoiceDropDownContent,
  STATE_SELECTED,
  STATE_UNSELECTED,
} from '@bettermarks/gizmo-types';
import {
  type ContextState,
  PolymorphicGizmo,
  translateContent,
} from '../../../gizmo-utils/polymorphic-gizmo';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { DropdownOption } from '../../../components/Dropdown/DropdownOption';
import { type DropdownState } from '../../../components/Dropdown/constants';

import styles from './MultipleChoiceDropdown.scss';

export interface MultipleChoiceDropdownCallbacks {
  itemClicked?: (item: number) => void;
}

export type MultipleChoiceDropdownProps = MultipleChoiceDropDownContent &
  MultipleChoiceDropdownCallbacks &
  CommonCallbacks &
  ContextState & {
    t?: TFunction;
  };

export const _MultipleChoiceDropdown: React.FC<MultipleChoiceDropdownProps> = (
  multipleChoiceProps: MultipleChoiceDropdownProps
) => {
  // TODO: applying defaults should live in the importer not in the gizmo component
  const props = {
    ...MULTIPLE_CHOICE_DROP_DOWN_DEFAULT_CONTENT,
    ...multipleChoiceProps,
  };
  const {
    choices,
    disabled,
    configuration,
    itemSeverity,
    onFocus,
    t,
    checkedIdx,
    itemClicked,
    availableWidth,
  } = props;
  const interactive = !disabled;

  const getRenderState = (index: number): DropdownState | Severity => {
    if (itemSeverity && itemSeverity.severity && itemSeverity.index === index) {
      return itemSeverity.severity;
    } else if (index === checkedIdx) {
      return STATE_SELECTED;
    } else {
      return STATE_UNSELECTED;
    }
  };

  const choicesElements = choices.map((element, i) => (
    <DropdownOption state={getRenderState(i)} key={i}>
      <PolymorphicGizmo refid={element.$refid} availableWidth={availableWidth} keepLineHeight />
    </DropdownOption>
  ));

  const text = t ? t(configuration.dropDownDefaultText) : '';

  return (
    <div className={styles.spacing}>
      <Dropdown
        interactive={interactive}
        open={false}
        selectedIndex={checkedIdx}
        onItemSelected={itemClicked}
        defaultText={text}
        onFocus={onFocus}
      >
        {choicesElements}
      </Dropdown>
    </div>
  );
};

_MultipleChoiceDropdown.displayName = '_MultipleChoiceDropdown';

export const MultipleChoiceDropdown = translateContent(_MultipleChoiceDropdown);
