import {
  type ContentReference,
  ShouldEnrichKind,
  type TranslateCoords,
} from '@bettermarks/gizmo-types';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, gizmoAction, type Dispatch } from '../../../gizmo-utils/redux';
import { dndAutoSize } from '../../../gizmo-utils/redux/gizmoActions';
import { freeDropAction } from '../actions';
import { DragSourceGizmo, type DragSourceCallbacks } from './DragSourceGizmo';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): DragSourceCallbacks & {
  onToggleDrawer?: (index: number) => void;
} => ({
  onResize: (width: number, height: number, left: number, top: number) =>
    dispatch(
      gizmoAction(
        dndAutoSize({ width, height, left, top }),
        refid,
        // our size changed, so we need to report it to the outer content tree.
        { shouldEnrich: ShouldEnrichKind.justEnrich, skipUndo: true }
      )
    ),
  onDropFreeDragItem: (freeDragItem: ContentReference, newPos: TranslateCoords) =>
    dispatch(gizmoAction(freeDropAction({ freeDragItem, newPos }), refid)),
});

export const DragSourceContainer = connectGizmo(mapDispatchToProps, DragSourceGizmo);
