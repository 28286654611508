import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import isNumber from 'lodash/isNumber';
import Measure, { type ContentRect } from 'react-measure';

import { calculatePopupBounds, areBoundsAvailable } from './helper';
import { type PopupBounds, PopupPlace, type PopupProps, type PopupState } from './types';
import { OverlayShadow } from '../OverlayShadow';
import * as themes from './themes';
import defaultTheme from '../../themes/defaultTheme';
import { POPUP_L, POPUP_MARGIN, POPUP_S } from './constants';
import { InnerArrow, Nose } from './common';

/**
 * Popup - UI Component
 * Used to render the Popups - Small/Large
 * Positioning the popup based on `place` and Triggered Element position in the window
 *
 * ### Properties
 | Name       | Type                               | Default    | Description                     |
 |---         | ---                                |---         | ---                             |
 |place       | `left`, `right`, `top`, `bottom`   |Required    |popup with nose placed at `place`|
 |kind        | `small`, `large`                   |Required    |Popup type                       |
 |still       | `boolean`                          |Optional    |show the popup UI without overlay|
 |show        | `boolean`                          |Required    |to toggle the popup visisibilty  |
 |elementBounds| `BoundingRect`                    |Required    |Triggered elements boundaries    |
 |onClickOverlay| Mouse Event                      |Required  |event triggers when click on overlay|
 |---         | ---                                |---         | ---                             |
 */

const { colors, dimensions } = defaultTheme;

const PopupWrapper = styled.div<
  Partial<PopupProps> & { popupHeight?: number | string; hidden: boolean }
>`
  display: inline-block;

  & > div {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: ${dimensions.zIndexBottom};
    display: none;
  }

  ${({ show }) =>
    show &&
    `
    & > div {
      z-index: ${dimensions.zIndexTop};
      opacity: 1;
      display: inline-block;
    }
  `}

  ${({ still }) =>
    still &&
    `
    & > div {
      position: relative;
      margin: ${POPUP_MARGIN};
      min-height: ${POPUP_S};
    }
  `}
  
  ${({ hidden }) =>
    hidden &&
    `
    visibility: hidden;
  `}
`;

const placeStylingMap: Record<PopupPlace, string> = {
  [PopupPlace.top]: `0 ${POPUP_MARGIN} ${POPUP_MARGIN};`,
  [PopupPlace.right]: `${POPUP_MARGIN} ${POPUP_MARGIN} 0 ${POPUP_MARGIN};`,
  [PopupPlace.bottom]: `${POPUP_MARGIN} ${POPUP_MARGIN} 0;`,
  [PopupPlace.left]: `${POPUP_MARGIN} ${POPUP_MARGIN} ${POPUP_MARGIN} 0;`,
};

const PopupContainer = styled.div<Partial<PopupProps>>`
  position: relative;
  background: ${colors.cWhite};
  border: ${dimensions.borderWidthS} solid ${colors.cAreaBorder};
  display: inline-block;
  text-align: center;
  box-shadow: 0 0 ${dimensions.shadowBlurMedium} 0 ${colors.cShadowLight};

  width: ${({ kind }) => (kind === 'small' ? POPUP_S : POPUP_L)};

  margin: ${({ place }) => (place ? placeStylingMap[place] : 0)};
`;

const PopupContent = styled.div<Partial<PopupProps> & { popupHeight?: number | string }>`
  ${({ place, popupHeight }) =>
    isNumber(popupHeight) &&
    (place === 'top' || place === 'bottom') &&
    `
    height: ${popupHeight};
    overflowY: 'auto'
  `}
`;

class Popup extends React.Component<PopupProps, PopupState> {
  state: PopupState = {};

  onPopupResize = ({ bounds }: ContentRect) => this.setState({ bounds });

  render() {
    const { elementBounds, children, onClickOverlay, kind, place, show, still } = this.props;
    const { bounds } = this.state;
    const calculatedBounds =
      bounds && elementBounds
        ? (calculatePopupBounds(elementBounds, bounds, place) as PopupBounds)
        : undefined;

    const updatedBounds = calculatedBounds ? calculatedBounds.bounds : {};
    const noseBounds = calculatedBounds ? calculatedBounds.noseBounds : {};
    const popupHeight = calculatedBounds ? calculatedBounds.popupHeight : undefined;

    return (
      <PopupWrapper still={still} show={!!(show || still)} hidden={!areBoundsAvailable(bounds)}>
        <Measure bounds onResize={this.onPopupResize}>
          {({ measureRef }) => (
            <PopupContainer kind={kind} place={place} ref={measureRef} style={updatedBounds}>
              <PopupContent place={place} popupHeight={popupHeight}>
                {children}
              </PopupContent>
              <ThemeProvider theme={themes[place]}>
                <Nose style={noseBounds}>
                  <InnerArrow />
                </Nose>
              </ThemeProvider>
            </PopupContainer>
          )}
        </Measure>
        {show && <OverlayShadow kind="popup" onClick={onClickOverlay} />}
      </PopupWrapper>
    );
  }
}

export default Popup;
