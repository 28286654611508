import * as React from 'react';
import { RECT_LARGE, RECT_SMALL } from '../../../constants';
import { RectSegment } from './RectSegment';
import { Rectangle } from './Rectangle';
import { type SegmentedProps } from '../types';
import { HEIGHT, WIDTH } from './constants';

import styles from '../FormStyles.scss';

export const SegmentedRect: React.FC<SegmentedProps> = ({
  bitmask,
  small,
  hoverIndex,
  onToggleSegment,
  onHoverSegment,
  onLeaveSegment,
}) => {
  const width = small ? RECT_SMALL.width : RECT_LARGE.width;
  const height = small ? RECT_SMALL.height : RECT_LARGE.height;
  return bitmask.length > 1 ? (
    <svg
      className={small ? styles.outerSmall : styles.outer}
      width={width}
      height={height}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      {bitmask.map((filled, i) => (
        <RectSegment
          key={i}
          index={i}
          denom={bitmask.length}
          filled={filled}
          pxWidth={width}
          hover={hoverIndex === i}
          onToggleSegment={onToggleSegment ? () => onToggleSegment(i) : undefined}
          onMouseOver={onHoverSegment ? () => onHoverSegment(i) : undefined}
          onMouseLeave={onLeaveSegment ? onLeaveSegment : undefined}
        />
      ))}
    </svg>
  ) : (
    <Rectangle
      width={width}
      height={height}
      small={small}
      filled={bitmask[0]}
      hover={hoverIndex === 0}
      onClick={onToggleSegment ? () => onToggleSegment(0) : undefined}
      onMouseOver={onHoverSegment ? () => onHoverSegment(0) : undefined}
      onMouseLeave={onLeaveSegment ? onLeaveSegment : undefined}
    />
  );
};
