import { measureFormulaInput } from '../components/FormulaInput';
import { PLACEHOLDER_LABEL } from '../components/constants';
import { type Enricher } from '../../../../gizmo-utils/configuration';
import {
  type ContentPath,
  createAlignableRow,
  type FormulaContent,
  type FormulaStyles,
  isOuterInteractive,
  type LayoutMetrics,
} from '@bettermarks/gizmo-types';
import { MathContentEnricher } from './measureStatic';
import { enrichFormulaRow } from './enrichFormulaRow';

/**
 * This is the enricher function for the formula gizmo.
 *
 * It enriches the top level math row of the formula which is not structured as an mrow, by caling
 * the enrichFormulaRow.
 *
 * We have three sources of truth applied in the following order for presentation:
 *   1. OuterStyles
 *   2. Own decoration from XML
 *   3. Decoration inferred from severity
 */
export const formulaEnricher: Enricher<FormulaContent> = (
  outerStyles,
  formula,
  measurements,
  getInnerStyles
) => {
  const path: ContentPath = [];

  const mergedStyles: FormulaStyles = getInnerStyles(path);
  const mathContentEnricher = MathContentEnricher(measurements, getInnerStyles);
  const enrichedRow = enrichFormulaRow(
    mergedStyles,
    createAlignableRow(formula.content),
    path,
    mathContentEnricher
  );
  // createAlignableRow adds $ property for enrichFormulaRow to distinguish between MFenced and MRow
  // we need to remove it before we can spread the result
  const {
    enrichedContent: { $, children: content, ...enrichedFormulaRow },
  } = enrichedRow;

  const enrichedPlaceHolder =
    formula.placeholderLabel &&
    enrichFormulaRow(
      mergedStyles,
      formula.placeholderLabel,
      [PLACEHOLDER_LABEL],
      mathContentEnricher
    );

  const layoutMetrics: LayoutMetrics = measureFormulaInput(
    enrichedRow,
    // INNER_TEXT is only used inside FieldSet, so those need not be measured as interactive
    isOuterInteractive(formula.$renderStyle, formula.$interactionType)
  );
  const enrichedPL = enrichedPlaceHolder
    ? { [PLACEHOLDER_LABEL]: enrichedPlaceHolder.enrichedContent }
    : {};

  return {
    ...enrichedRow,
    ...layoutMetrics,
    enrichedContent: {
      ...formula,
      ...enrichedFormulaRow,
      ...enrichedPL,
      content,
    },
  };
};
