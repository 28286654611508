import log from 'loglevel';
import { type Action } from 'redux-actions';
import { all, call, put } from 'redux-saga/effects';
import { handleFontLoadingErrors } from '../../../handleFontLoadingErrors';
import { loadGizmoFailure, loadGizmoSuccess, loadGizmoProgress } from './actions';
import { type GizmoRoute } from '@bettermarks/importers';
import { type GizmoViewerLoadData } from '../../types';
import { ensureFontsLoaded } from '../../../../utils/fontMetric';
import { loadGizmoService } from '../../services/api';

export function* loadGizmoSaga({ payload }: Action<GizmoRoute>) {
  try {
    yield put(loadGizmoProgress());
    const [{ contentDict }]: [GizmoViewerLoadData, undefined] = yield all([
      call(loadGizmoService, payload),
      call(ensureFontsLoaded, 5000, handleFontLoadingErrors),
    ]);
    yield put(loadGizmoSuccess({ contentDict }));
  } catch (error) {
    log.error(error);
    yield put(loadGizmoFailure());
  }
}
