import {
  type ALayoutChild,
  type ALayoutContent,
  annotationInner,
  type Annotations,
  exportContent,
  type Exporter,
  RS,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportAbsoluteLayout: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as ALayoutContent;
  const references: ReadonlyArray<ALayoutChild> = content.content;

  return semantics(
    `
      <mrow>
        ${[
          [
            `<configuration>`,
            content.debug ? `<debug>true</debug>` : '',
            `<width>${content.unscaledWidth}</width>`,
            `<height>${content.unscaledHeight}</height>`,
            `</configuration>`,
          ].join(''),
          ...references.map((c) => exportContent(c.$refid, context)),
        ].join('')}
      </mrow>
    `,
    annotationInner(content.$, {
      ...(content as Annotations),
      $renderStyle: RS.LAYOUT_CONTAINER,
    })
  );
};
