import { defaultTo, flatten, get, isNil, round } from 'lodash';
import {
  type AngleObject,
  AxisDirection,
  type CircleConfiguration,
  type CircleIdCoords,
  type CircleObject,
  type Coords,
  type GeoContent,
  type GeoDecoration,
  type GeoEditorMode,
  type GeoObject,
  type GeoObjectMap,
  type GeoScene,
  type IdCoords,
  INTERVAL,
  type IntervalHelperLineDecoration,
  type IntervalObject,
  type IntervalType,
  isAngleObject,
  isCircleObject,
  isCircleSegmentObject,
  isPointObject,
  isPolygonObject,
  LABEL,
  LabelAlignDirection,
  type LabelContent,
  type LabelObject,
  type LabelWidgetProps,
  type LineIdCoords,
  type LineObject,
  type LineObjectType,
  POINT,
  type PointDecoration,
  type PointObject,
  RAY,
  SEGMENT,
  type SnapPoint,
  STRAIGHTLINE,
  VECTOR,
  type AngleBase,
} from '@bettermarks/gizmo-types';
import {
  add,
  DEFAULT_POINT_DECORATION,
  GENERATOR_MAX_DECIMALS,
  getPointCoordsId,
  importDecorationMap,
  isPointOnLine,
  isValidMap,
  roundV,
  smult,
  sub,
  TEMP_POINT,
} from '@bettermarks/importers';
import { invisibleIntersectionPoints } from './snap';
import { type ValueSetterMap } from '../../gizmo-utils/polymorphic-gizmo';
import {
  calculateCircleSegmentBezierPath,
  calculatePolygonBezierPath,
  evaluateValueSetterExpression,
  evaluateValueSetterExpressionToString,
} from '@bettermarks/umc-kotlin';
import log from 'loglevel';
import { valueSetterMapToValidatorValueMap } from '../formula/Formula/helper';
import type { WhiteboardMessage } from '../../apps/iframe-posts';

export const getAngleId = (p1Id: string) => `angle_${p1Id}`;
export const getLineId = (p1Id: string, p2Id: string, prefix: LineObjectType) => {
  const sortedIds = [p1Id, p2Id].sort();
  return `${prefix.substring(0, 3)}:${sortedIds[0]}_${sortedIds[1]}`;
};

export const getLabelId = (refId: string) => `label:${refId}`;
export const getRayId = (p1Id: string, p2Id: string) => getLineId(p1Id, p2Id, RAY);
export const getSegmentId = (p1Id: string, p2Id: string) => getLineId(p1Id, p2Id, SEGMENT);
export const getStraightlineId = (p1Id: string, p2Id: string) =>
  getLineId(p1Id, p2Id, STRAIGHTLINE);
export const getVectorId = (p1Id: string, p2Id: string) => getLineId(p1Id, p2Id, VECTOR);

export const getCircleId = (coords: Coords, radius: number, prefix = 'c') =>
  `${getPointCoordsId(coords, prefix)}_${round(radius, 7)}`;

export const getIntervalId = (min: number, max: number) => `interval:${min}_${max}`;

export const createLabel = (
  refid: string,
  content: LabelContent,
  activeIndex: number,
  labelWidgetProps: LabelWidgetProps,
  position?: Coords,
  t?: number
): LabelObject => ({
  type: LABEL,
  refid,
  content,
  referringTo: [refid],
  referencedBy: [],
  activeIndex,
  verticalAlign: labelWidgetProps.labelAlignTop
    ? LabelAlignDirection.top
    : LabelAlignDirection.bottom,
  addedByUser: true,
  ...(labelWidgetProps.labelType && { labelType: labelWidgetProps.labelType }),
  ...(position && { position }),
  t,
});

export const createLine = (
  p1Id: string,
  p2Id: string,
  decoration = {},
  type: LineObjectType,
  notLabelable = false
): LineObject => ({
  type,
  p1Id: p1Id,
  p2Id: p2Id,
  referringTo: [p1Id, p2Id],
  referencedBy: [],
  decoration,
  addedByUser: true,
  ...(notLabelable && { notLabelable }),
});

export const createCircle = (
  configuration: CircleConfiguration,
  coords: Coords,
  decoration = {},
  radius: number
): CircleObject => ({
  coords,
  radius,
  configuration,
  referringTo: [],
  referencedBy: [],
  decoration,
  addedByUser: true,
  type: 'circles',
});

export const createAngle = (angleProps: AngleBase): AngleObject => ({
  referringTo: [],
  referencedBy: [],
  addedByUser: true,
  type: 'angles',
  ...angleProps,
});

export const createInterval = (
  min: number,
  max: number,
  intervalType: IntervalType,
  decoration: IntervalHelperLineDecoration
): IntervalObject => ({
  min,
  max,
  direction: AxisDirection.horizontal,
  decoration,
  intervalType,
  referringTo: [],
  referencedBy: [],
  interactionType: '',
  addedByUser: true,
  type: INTERVAL,
});

export const getSnapPointDecoration = (
  points: ReadonlyArray<string>,
  snapPoint: Nullable<SnapPoint>,
  geoContentMap: GeoObjectMap<GeoObject>
): PointDecoration => {
  const decoration: PointDecoration = { ...DEFAULT_POINT_DECORATION };

  if (snapPoint && snapPoint.snapObject === POINT && snapPoint.id) {
    return get(geoContentMap, `${snapPoint.id}.decoration`, decoration);
  }
  return decoration;
};

export const getLineIdCoords = (
  contentMap: GeoObjectMap<GeoObject>,
  ids: ReadonlyArray<string>
): LineIdCoords[] =>
  ids.map((id) => {
    const l = contentMap[id] as LineObject;

    if (contentMap[l.p1Id] === undefined || contentMap[l.p2Id] === undefined)
      log.warn({
        message: "Line's point is not in contentMap",
        extra: {
          line: JSON.stringify(l),
          contentMapKeys: JSON.stringify(Object.keys(contentMap)),
        },
      });

    const p1Coords = (contentMap[l.p1Id] as PointObject).coords;
    const p2Coords = (contentMap[l.p2Id] as PointObject).coords;

    return { id: id, coords: [p1Coords, p2Coords] as [Coords, Coords] };
  });

const MAX_LINE_PARAM = 1e5;

const isNumericalFinite = (n: number) => Math.abs(n) < MAX_LINE_PARAM;

/**
 * reorganizes coordinates for two points defining a line. Both points should be
 * 'out of sight' for straight lines, one point should be out of sight for rays,
 * both points should be left for segments and vectors ...
 * @param {Coords} p1
 * @param {Coords} p2
 * @param {string} lineType
 * @returns {[Coords]}
 */
export const getLineCoords = (p1: Coords, p2: Coords, lineType: string): [Coords, Coords] => {
  const r = sub(p1, p2);
  let ts = flatten([-1000, 1000].map((a) => [(a - p1.x) / r.x, (a - p1.y) / r.y])).filter(
    isNumericalFinite
  );

  // handle the case where both coords of the direction vector are almost 0
  if (ts.length < 2) {
    ts = [-MAX_LINE_PARAM * 100, MAX_LINE_PARAM * 100];
  }

  switch (lineType) {
    case RAY:
      return [p1, add(p1, smult(Math.min(...ts.filter((t) => t > 0)), r))];
    case STRAIGHTLINE:
      return [add(p1, smult(Math.min(...ts), r)), add(p1, smult(Math.max(...ts), r))];
    case SEGMENT:
    case VECTOR:
    default:
      return [roundV(p1, GENERATOR_MAX_DECIMALS), roundV(p2, GENERATOR_MAX_DECIMALS)];
  }
};

/**
 * returns all lines of a given ID-list, a given point is 'member of'.
 * @param {GeoObjectMap<GeoObject>} geoContentMap
 * @param {Coords} point
 * @param {string[]} lineIds
 * @returns {boolean}
 */
export const linesPointIsOn = (
  geoContentMap: GeoObjectMap<GeoObject>,
  point: Coords,
  lineIds?: string[]
): string[] =>
  (isNil(lineIds) ? [] : lineIds).filter((id) => {
    const { p1Id, p2Id, type } = geoContentMap[id] as LineObject;
    const { coords: p } = geoContentMap[p1Id] as PointObject;
    const { coords: q } = geoContentMap[p2Id] as PointObject;
    return isPointOnLine([p, q], point, isValidMap[type === VECTOR ? SEGMENT : type]);
  });

export const isPointOnAnyLine = (
  geoContentMap: GeoObjectMap<GeoObject>,
  point: Coords,
  lineIds?: string[]
): boolean => linesPointIsOn(geoContentMap, point, lineIds).length > 0;

export const getGeoScene = (props: GeoContent): GeoScene => {
  const {
    circles,
    configuration,
    geoContentMap,
    invisiblePoints,
    points,
    rays,
    segments,
    snappingGrid,
    straightlines,
    vectors,
  } = props;

  const rayCoords: LineIdCoords[] = getLineIdCoords(geoContentMap, rays);
  const segmentCoords: LineIdCoords[] = getLineIdCoords(geoContentMap, segments);
  const straightlineCoords: LineIdCoords[] = getLineIdCoords(geoContentMap, straightlines);
  const vectorCoords: LineIdCoords[] = getLineIdCoords(geoContentMap, vectors);

  // snapping to the TEMP_POINT results in flickering for snapType===continuous
  const pointCoords: IdCoords[] = points
    .filter((p) => p !== TEMP_POINT)
    .map((id: string) => ({
      id,
      coords: (geoContentMap[id] as PointObject).coords,
    }));

  const circleCoords: CircleIdCoords[] = circles.map((id: string): CircleIdCoords => {
    const circle = geoContentMap[id] as CircleObject;
    return { id, coords: circle.coords, radius: circle.radius };
  });

  const invisiblePointCoords: IdCoords[] = invisiblePoints
    .filter((id) => !(geoContentMap[id] as PointObject).noSnap)
    .map((id) => ({ id, coords: (geoContentMap[id] as PointObject).coords }));

  const invisibleCoords: IdCoords[] = [
    ...invisibleIntersectionPoints(
      [...segmentCoords, ...vectorCoords],
      rayCoords,
      straightlineCoords,
      circleCoords,
      configuration
    ),
    ...invisiblePointCoords,
  ];

  return {
    snappingGrid: defaultTo<Coords[]>(snappingGrid, []),
    points: pointCoords,
    rays: rayCoords,
    segments: segmentCoords,
    straightlines: straightlineCoords,
    vectors: vectorCoords,
    invisibleSnapPoints: invisibleCoords,
    circles: circleCoords,
    snapType: configuration.snapType,
  };
};

export const stripColor = (mode: GeoEditorMode) => mode.replace(/_bm-[\w-]+/g, '');

const dynamicCoords = (
  obj: PointObject | CircleObject | AngleObject,
  valueMap: Record<string, number>
): { x: number; y: number } => {
  const x = obj.dynamicX
    ? evaluateValueSetterExpression({ expression: obj.dynamicX, valueMap })
    : obj.coords.x;
  const y = obj.dynamicY
    ? evaluateValueSetterExpression({ expression: obj.dynamicY, valueMap })
    : obj.coords.y;

  return { x, y };
};

const dynamicDecoration = (obj: GeoObject, valueMap: Record<string, number>): GeoDecoration => {
  const decorationString: string = obj.dynamicDecoration
    ? evaluateValueSetterExpressionToString({ expression: obj.dynamicDecoration, valueMap })
    : '';
  const { decoration }: GeoDecoration = importDecorationMap(decorationString);

  return decoration;
};

const calculateAngle = (x: string, y: string, valueMap): number => {
  const xValue = evaluateValueSetterExpression({ expression: x, valueMap });
  const yValue = evaluateValueSetterExpression({ expression: y, valueMap });

  // normalize such that the angle is ALWAYS between 0 (inclusive) and 2 PI (exclusive)
  let p = Math.atan2(yValue, xValue);
  if (1e-7 + p < 0.0) {
    p += 2.0 * Math.PI;
  }

  // rad to deg
  return (p * 180) / Math.PI;
};

/* eslint-disable complexity */
export const loadDynamicValues = (
  geoContentMap: GeoObjectMap<GeoObject>,
  valueSetterMap: ValueSetterMap
) => {
  const valueMap = valueSetterMapToValidatorValueMap(valueSetterMap);
  let result = geoContentMap;
  Object.keys(geoContentMap).forEach((key) => {
    let obj = geoContentMap[key];

    if (obj.dynamicDecoration) {
      obj = {
        ...obj,
        decoration: dynamicDecoration(obj, valueMap),
      };
    }

    if (isPointObject(obj) && (obj.dynamicX || obj.dynamicY)) {
      obj = {
        ...obj,
        coords: dynamicCoords(obj, valueMap),
      };
    } else if (isCircleObject(obj) && (obj.dynamicX || obj.dynamicY || obj.dynamicRadius)) {
      obj = {
        ...obj,
        coords: dynamicCoords(obj, valueMap),
        radius: obj.dynamicRadius
          ? evaluateValueSetterExpression({
              expression: obj.dynamicRadius,
              valueMap,
            })
          : obj.radius,
      };
    } else if (
      isAngleObject(obj) &&
      (obj.dynamicX ||
        obj.dynamicY ||
        obj.dynamicStartAngleX ||
        obj.dynamicStartAngleY ||
        obj.dynamicEndAngleX ||
        obj.dynamicEndAngleY)
    ) {
      obj = {
        ...obj,
        coords: dynamicCoords(obj, valueMap),
        startAngle:
          obj.dynamicStartAngleX && obj.dynamicStartAngleY
            ? calculateAngle(obj.dynamicStartAngleX, obj.dynamicStartAngleY, valueMap)
            : obj.startAngle,
        endAngle:
          obj.dynamicEndAngleX && obj.dynamicEndAngleY
            ? calculateAngle(obj.dynamicEndAngleX, obj.dynamicEndAngleY, valueMap)
            : obj.endAngle,
      };
    } else if (isCircleSegmentObject(obj)) {
      obj = {
        ...obj,
        points: calculateCircleSegmentPoints(
          evaluateValueSetterExpression({
            expression: obj.dynamicX,
            valueMap,
          }).toString(),
          evaluateValueSetterExpression({
            expression: obj.dynamicY,
            valueMap,
          }).toString(),
          evaluateValueSetterExpression({
            expression: obj.dynamicOuterRadius,
            valueMap,
          }).toString(),
          evaluateValueSetterExpression({
            expression: obj.dynamicInnerRadius,
            valueMap,
          }).toString(),
          evaluateValueSetterExpression({
            expression: obj.dynamicStartAngle,
            valueMap,
          }).toString(),
          evaluateValueSetterExpression({
            expression: obj.dynamicAngle,
            valueMap,
          }).toString()
        ),
      };
    }

    result = {
      ...result,
      [key]: obj,
    };
  });

  Object.keys(geoContentMap).forEach((key) => {
    const obj = geoContentMap[key];

    if (isPolygonObject(obj)) {
      result = {
        ...result,
        [key]: {
          ...obj,
          points: calculatePolygonPoints(result, obj.refIds as string[]),
        },
      };
    }
  });

  return result;
};

export const calculateCircleSegmentPoints = (
  centerX: string,
  centerY: string,
  oRadius: string,
  iRadius: string,
  startAngle: string,
  angle: string
): [number, number][] =>
  calculateCircleSegmentBezierPath({
    centerX,
    centerY,
    oRadius,
    iRadius,
    startAngle,
    angle,
  })
    .split(';')
    .map((p) => p.split(',').map((c) => parseFloat(c))) as [number, number][];

export const calculatePolygonPoints = (
  geoContentMap: GeoObjectMap<GeoObject>,
  refIds: string[]
): [number, number][] => {
  const points = refIds.map((id) => {
    const coords = (geoContentMap[id] as PointObject).coords;
    return `${coords.x};${coords.y}`;
  });

  return calculatePolygonBezierPath({ points })
    .split(';')
    .map((p) => p.split(',').map((c) => parseFloat(c))) as [number, number][];
};

async function lazyNotifyWhiteboard(message: WhiteboardMessage) {
  // Note: we have to import this method lazily to avoid an import conflict resulting
  // in the error `Cannot access 'gizmoRegistry' before initialization`
  const { postToWhiteboardParent } = await import('../../apps/iframe-posts');
  postToWhiteboardParent(message);
}

export function notifyWhitboardToLockScroll() {
  lazyNotifyWhiteboard({ type: 'lockScroll' });
}

export function notifyWhitboardToUnlockScroll() {
  lazyNotifyWhiteboard({ type: 'unlockScroll' });
}
