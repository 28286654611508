import { getFontMetric } from '../../utils/fontMetric';
import { type Ruler } from '../../gizmo-utils/configuration';
import { GIZMO_FONTSIZE } from '../components';
import { type HelloGizmoContent } from '@bettermarks/gizmo-types';

export const measureHelloGizmo: Ruler<HelloGizmoContent> = (_, content) => {
  const height = content.size + getFontMetric(GIZMO_FONTSIZE).height;

  return {
    height,
    refLine: height * 0.5,
  };
};
