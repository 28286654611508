import React from 'react';
import { colors, dimensions } from '@seriesplayer/common-ui';
import { StyledCollapseButton } from './CollapseButton';
import { HoverHandler, SECTION_HEADER_CLASSNAME, StyledSectionHeader } from './CollapsibleSection';
import type { StyledProps } from './types';

export type CollapsibleTopLevelSectionProps = StyledProps & {
  title: string;
  isCollapsed: boolean;
  children: React.ReactNode;
  toggleCollapsed: () => void;
};

export default function CollapsibleTopLevelSection({
  title,
  isCollapsed,
  toggleCollapsed,
  children,
}: CollapsibleTopLevelSectionProps) {
  return (
    <>
      <HoverHandler>
        <StyledSectionHeader
          className={SECTION_HEADER_CLASSNAME}
          onClick={toggleCollapsed}
          theme={`font-weight: ${dimensions.fontWeightBold}; color: ${colors.cTextMedium}; padding-right: ${dimensions.spaceS};`}
          data-cy="student-tasks-top-level"
        >
          <StyledCollapseButton
            collapsed={isCollapsed}
            onClick={toggleCollapsed}
            variant={'topLevelSection'}
          />
          <div className="title">{title}</div>
        </StyledSectionHeader>
      </HoverHandler>
      {!isCollapsed && children}
    </>
  );
}
