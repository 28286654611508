export const enum AdditionalButton {
  close = 'close',
  trash = 'trash',
}

export type AdditionalButtonProps = {
  mode: AdditionalButton;
  onClose?: () => void;
  onDelete?: () => void;
};
