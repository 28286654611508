import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { type BarChartGroup, type MouseOrTouch } from '@bettermarks/gizmo-types';
import { persist } from './reducer/actions';
import { BarChartEditor } from './BarChartEditor';

export type BarChartCallbacks = {
  onHandleOver?: (group: number, item: number) => void;
  onHandleLeave?: (group: number, item: number) => void;
  onMoveStart?: (group: number, item: number) => void;
  onMove?: (evt: MouseOrTouch) => void;
  onMoveEnd?: () => void;
  onPersist?: (groups: BarChartGroup[]) => void;
};

const mapDispatchToProps = (dispatch: Dispatch, { refid }: GizmoProps): BarChartCallbacks => ({
  onPersist: (groups: BarChartGroup[]) => dispatch(gizmoAction(persist(groups), refid)),
});

export const BarChartContainer = connectGizmo(mapDispatchToProps, BarChartEditor);
