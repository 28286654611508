import { type CollapsibleSectionProps, CSInitialMode, CSModeFromState } from './types';

export const isOpen = (mode: CSInitialMode | CSModeFromState): boolean =>
  mode === CSInitialMode.open ||
  mode === CSInitialMode.openAnimated ||
  mode === CSModeFromState.openToggleAnimated;

export const isAnimated = (mode: CSInitialMode | CSModeFromState): boolean =>
  mode === CSInitialMode.openAnimated ||
  mode === CSModeFromState.openToggleAnimated ||
  mode === CSModeFromState.collapsedToggleAnimated;

export const isCollapsible = ({ collapsible, title }: CollapsibleSectionProps): boolean =>
  collapsible && title !== undefined;
