import { CALENDAR } from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type Content, type Defaults } from '../../xml-converter/core';

export type FirstDayOfWeek = 'Mo' | 'So';
export type DaySpan = {
  /**
   * Date string as MM-DD format
   */
  from: string;
  /**
   * Date string as MM-DD format
   */
  to: string;
};

export interface CalendarGizmoContent extends Readonly<Content> {
  /**
   * Optional: Calendar year. Default is current year.
   */
  readonly year?: number;
  /**
   * Calendar month 1-indexed (1 - January to 12 - December)
   */
  readonly month: number;
  /**
   *  Number of calendar to display
   */
  readonly count: number;
  /**
   * First day of the week in the calendar header (e.g. Mo or So)
   */
  readonly firstDayOfWeek: FirstDayOfWeek;
  /**
   * If week days headers should appear on calendar
   */
  readonly showWeekDays: boolean;
  /**
   * Days that must be highlighted on calendar
   */
  readonly daySpans: DaySpan[];
}

/**
 * Default JSON set to:
 *  - unknown year (should be default to current year by the Gizmo)
 *  - January
 *  - 1 calendar month
 *  - Starting the week layout on Monday
 *  - Showing week days header
 *  - Without any highlighted days
 */
export const DEFAULT_CALENDAR_GIZMO_CONTENT: Defaults<CalendarGizmoContent> = {
  $: SPECIAL,
  $renderStyle: CALENDAR,
  month: 1,
  count: 1,
  firstDayOfWeek: 'Mo',
  showWeekDays: true,
  daySpans: [],
};
