import { CheckBox, OptionDecorator } from '../../../components';
import { OptionDecoratorMode } from '../../../components/MultipleChoice/types';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';

import {
  BUTTON_WIDTH,
  getRenderState,
  OPTION_DECORATOR_WIDTHS,
} from '../../multiple-choice/constants';
import { type MultipleChoiceMultiChoiceContent as ChoiceContent } from '@bettermarks/gizmo-types';

export type MultipleChoiceItemProps = {
  index: number;
  selected: boolean;
  content: ChoiceContent;
  availableWidth: number;
  buttons?: boolean;
  interactive?: boolean;
  onChosen?: (idx: number) => void;
};

export const MultipleChoiceMultiItem: React.FC<MultipleChoiceItemProps> = (props) => {
  const { index, selected, content, interactive, buttons, availableWidth } = props;

  const decoratorProps = {
    mode: OptionDecoratorMode.multi,
    state: getRenderState(selected, content.severity),
    interactive,
    hasButton: buttons,
  };

  const checkBoxProps = {
    state: decoratorProps.state,
    interactive: decoratorProps.interactive,
  };

  const onClick = () => {
    props.onChosen && props.onChosen(index);
  };

  return (
    <OptionDecorator {...decoratorProps} onClick={onClick}>
      {buttons && <CheckBox {...checkBoxProps} />}
      <PolymorphicGizmo
        refid={content.$refid}
        availableWidth={availableWidth - OPTION_DECORATOR_WIDTHS - Number(buttons) * BUTTON_WIDTH}
      />
    </OptionDecorator>
  );
};

MultipleChoiceMultiItem.displayName = 'MultipleChoiceMultiItem';
