import * as React from 'react';
import classNames from 'classnames';
import defaultTo from 'lodash/defaultTo';
import styles from './PopupBubble.scss';

export interface PopupBubbleProps {
  nose?: { xOffset?: number; up: boolean };
  id?: string;
  measureRef?: any;
}

const PopupBubble: React.FC<PopupBubbleProps> = ({ children, id, measureRef, nose }) => {
  return (
    <div id={id} className={styles.wrapper} ref={measureRef}>
      {nose && nose.up && (
        <div className={styles.noseContainer}>
          <div
            className={classNames(styles.nose, styles.noseUp)}
            style={{
              marginLeft: `${defaultTo(nose.xOffset, 0)}px`,
            }}
          />
        </div>
      )}
      <div className={styles.container}>{children}</div>
      {nose && !nose.up && (
        <div className={styles.noseContainer}>
          <div
            className={classNames(styles.nose, styles.noseDown)}
            style={{
              marginLeft: `${defaultTo(nose.xOffset, 0)}px`,
            }}
          />
        </div>
      )}
    </div>
  );
};

PopupBubble.displayName = 'PopupBubble';

export default PopupBubble;
