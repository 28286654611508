import { type AudioSource, Sound, type Sounds } from './types';

const CORRECT_AUDIO: ReadonlyArray<AudioSource> = [{ src: 'assets/audio/correct.mp3' }];

const WRONG_AUDIO: ReadonlyArray<AudioSource> = [{ src: 'assets/audio/wrong.mp3' }];

const COIN_AUDIO: ReadonlyArray<AudioSource> = [{ src: 'assets/audio/coin.mp3' }];

export const SERIES_PLAYER_SOUNDS: Sounds = {
  [Sound.Correct]: CORRECT_AUDIO,
  [Sound.Wrong]: WRONG_AUDIO,
  [Sound.Coin1]: COIN_AUDIO,
  [Sound.Coin2]: COIN_AUDIO,
  [Sound.Coin3]: COIN_AUDIO,
  [Sound.Star]: COIN_AUDIO,
};
