import { type SeriesId } from './types';

const stepResult = (resultManagerUrl: string) => `${resultManagerUrl}/v3.2/reporting`;
const seriesResult = (resultManagerUrl: string, params: SeriesId) =>
  `${resultManagerUrl}/v2.0/result/series?series_id=${params.seriesId}`;
const reportSeriesResults = (resultManagerUrl: string) =>
  `${resultManagerUrl}/v3.3/reporting/series`;
const reportTestHandedIn = (resultManagerUrl: string) =>
  `${resultManagerUrl}/v1.0/reporting/testHandedIn`;
const reportLobLinkScore = (resultManagerUrl: string) =>
  `${resultManagerUrl}/v1.0/reporting/lobLinkScore`;
const reportLtiResult = (resultManagerUrl: string) =>
  `${resultManagerUrl}/v1.0/lti/exercise/result`;
const reportViewedEvent = (resultManagerUrl: string) => `${resultManagerUrl}/v1.1/reporting/viewed`;

const ROUTES = {
  stepResult,
  seriesResult,
  reportLobLinkScore,
  reportLtiResult,
  reportSeriesResults,
  reportTestHandedIn,
  reportViewedEvent,
};

export const ResultManagerRoutes: Readonly<typeof ROUTES> = ROUTES;
