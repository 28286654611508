import { ENCRYPTION_VERSION } from './decrypt';
import { type AppSettings } from '../../../../../types';

export type ContentManagerBase = Pick<AppSettings, 'contentManagerUrl'>;
export type StaticServerBase = Pick<AppSettings, 'staticServerUrl'>;

export type GetExerciseParams = { path: string } & StaticServerBase;
export type GetStaticParams = { path: string } & StaticServerBase;
export type GetEMParams = { path: string; encrypt: boolean } & StaticServerBase;
export type GetCRIParams = { path: string; encrypt: boolean } & StaticServerBase;
export type GetSeriesParams = {
  seriesId: string;
  seriesReview: boolean;
  studentId?: string;
} & ContentManagerBase;

export type GetLobLinkSeriesParams = {
  contentListId: string;
  locale: string;
  token: string;
} & ContentManagerBase;

export type GetPdfReviewSeriesParams = {
  seriesId: string;
  studentId: string;
  system: string;
  token: string;
} & ContentManagerBase;

export type QueryStringParam = {
  key: string;
  value: string;
};

const queryString = (params: QueryStringParam[]) =>
  params.length > 0
    ? params.reduce(
        (acc, v, idx) => `${acc}${v.key}=${v.value}${idx < params.length - 1 ? '&' : ''}`,
        '?'
      )
    : '';

const ROUTES = {
  getLocalExercise: ({ staticServerUrl, path }: GetExerciseParams) =>
    `${staticServerUrl}/${path}.xml`,
  getExercise: ({ staticServerUrl, path }: GetExerciseParams) =>
    `${staticServerUrl}/${ENCRYPTION_VERSION}/${path}.xml`,
  getSeries: ({ contentManagerUrl, seriesId, studentId, seriesReview }: GetSeriesParams) =>
    `${contentManagerUrl}/v2.0/content/series/${seriesId}${queryString(
      [
        { key: 'studentId', value: studentId },
        { key: 'review', value: seriesReview.toString() },
      ].filter((v) => v.value !== undefined) as QueryStringParam[]
    )}`,
  getStatic: ({ staticServerUrl, path }: GetStaticParams) => `${staticServerUrl}/${path}`,
  getEM: ({ staticServerUrl, path, encrypt }: GetEMParams) =>
    `${staticServerUrl}${encrypt ? `/${ENCRYPTION_VERSION}` : ''}/${path}`,
  getCRI: ({ staticServerUrl, path, encrypt }: GetCRIParams) =>
    `${staticServerUrl}${encrypt ? `/${ENCRYPTION_VERSION}` : ''}/${path}`,
  getLobLinkSeries: ({ contentListId, contentManagerUrl, locale, token }: GetLobLinkSeriesParams) =>
    `${contentManagerUrl}/v2.0/exercise/${token}/${locale}/${contentListId}.json`,
  getPdfReviewSeries: ({
    contentManagerUrl,
    seriesId,
    studentId,
    system,
    token,
  }: GetPdfReviewSeriesParams) =>
    `${contentManagerUrl}/v2.0/content/series/${seriesId}/${token}${queryString(
      [
        { key: 'studentId', value: studentId },
        { key: 'system', value: system },
      ].filter((v) => v.value !== undefined) as QueryStringParam[]
    )}`,
};

export const ContentManagerRoutes: Readonly<typeof ROUTES> = ROUTES;
