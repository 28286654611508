import { Validity } from '@bettermarks/umc-kotlin';
import { isUndefined, last } from 'lodash';
import { type Bounds, POPUP_MARGIN } from '../../../../components/ContextPopup/Popup';
import { type ToolTipBounds } from '../../../../components/ContextPopup/ToolTip';
import * as ReactDOM from 'react-dom';
import {
  type AdditionalHelpType,
  AppExercise,
  type AppStep,
  DialogType,
  ExerciseStatus,
  SeriesMode,
  StepStatus,
} from '../../../../types';
import { type ExerciseStateProps } from './Exercise';
import log from 'loglevel';
import { type BoundingRect } from 'react-measure';

const TOOLTIP_TOP_MARGIN = 3,
  TOOLTIP_BOTTOM_MARGIN = 5;

export const isHTMLElement = (elem: Nullable<Element | Text>): elem is HTMLElement =>
  !!(elem && 'style' in elem && (elem as HTMLElement).style);

export const getScrollableElement = () => document.querySelector('#content');

export const withoutSmoothScrolling = (func: (scrollableElement: HTMLElement) => void) => {
  const scrollableNode = ReactDOM.findDOMNode(getScrollableElement());
  if (isHTMLElement(scrollableNode)) {
    scrollableNode.style.scrollBehavior = 'auto';
    func(scrollableNode);
    scrollableNode.style.scrollBehavior = 'smooth';
  }
};
/**
 * Determines if a given element is in the "viewport" by checking it's boundaries against the
 * element with ID #content.
 *
 *  TRUE                  FALSE                FALSE
 *  +-----------------+   +-----------------+       +-------------+
 *  | #content        |   | #content        |       | elem        |
 *  |   +-------------+   |   +-------------+   +-----------------+
 *  |   | elem        |   |   | elem        |   | #content        |
 *  |   |             |   +-----------------+   |   |             |
 *  |   +-------------+       +-------------+   |   +-------------+
 *  +-----------------+                         +-----------------+
 *
 * In case the boundaries of the given element cannot be determined we return true to
 * avoid unnecessary scrolling (usages are all checking on !isInViewport to induce scrolling
 * to the given element).
 *
 * @param elem                - the element for which we want to check if it is "in viewport"
 * @param getScrollingParent  - the function that returns the scrollable parent (hardcoded to
 *                              #content, currently) that is used to as reference to determine if
 *                              elem is "in viewport"
 * @return boolean
 */

export const isInViewport = (elem: HTMLElement, getScrollingParent = getScrollableElement) => {
  const bounding = elem.getBoundingClientRect();
  const scrollableElement = getScrollingParent();
  if (!bounding || !scrollableElement) {
    if (!bounding) {
      log.info({
        message: `No bounding box for element ${elem.toString()} in isInViewport`,
      });
    }
    return true;
  }
  return bounding.top >= 0 && bounding.bottom <= scrollableElement.clientHeight;
};

export type ScrollTarget =
  | {
      type: 'step';
      stepIndex: number;
    }
  | {
      type: 'setting';
    }
  | null;

/**
 * Helper function which returns the target to auto-scroll to.
 *
 * 1. When switching exercise ...
 *  1.1. Auto scroll to 'setting' (top of exercise) when switching to completed or fresh exercise
 *  1.2. Auto scroll to current step if user already worked on it
 * 2. When exercise remains same but step status changed (i.e. started -> attemted)
 *    scroll to current step.
 * By default returns null if there is nothing to scroll
 */
export const getScrollTarget = (
  prevProps: ExerciseStateProps,
  currentProps: ExerciseStateProps
): ScrollTarget => {
  if (prevProps.exerciseId !== currentProps.exerciseId) {
    // changing exercise
    const stepsUnworked = currentProps.steps.every(
      (step) => step.status !== StepStatus.attempted && step.status !== StepStatus.completed
    );
    if (
      currentProps.status === ExerciseStatus.completed ||
      currentProps.status === ExerciseStatus.review ||
      (currentProps.status === ExerciseStatus.started && stepsUnworked)
    ) {
      // fresh exercise and completed exercise scrolls to the very top (setting)
      return { type: 'setting' };
    } else {
      // in current active exercise scroll to current step
      const { steps, currentStepId } = currentProps;
      return {
        type: 'step',
        stepIndex: steps.findIndex((step) => step.id === currentStepId),
      };
    }
  } else {
    const prevCurrenStep = AppExercise.getCurrentStep(prevProps.steps, prevProps.currentStepId);
    const currenStep = AppExercise.getCurrentStep(currentProps.steps, currentProps.currentStepId);

    if (
      (prevCurrenStep && currenStep && prevCurrenStep.status !== currenStep.status) ||
      (prevProps.stepValidationLoaded !== currentProps.stepValidationLoaded &&
        currentProps.stepValidationLoaded === true)
    ) {
      // status of current step changed or just stepValidationLoaded changed (multiple times
      // valid input) so we scroll to it's top
      return {
        type: 'step',
        stepIndex: AppExercise.getCurrentStepIndex(currentProps.steps, currentProps.currentStepId),
      };
    }
  }
  return null;
};

/**
 * getParentElement - recursive method which returns the parentElement at the `level`th position
 * if parentElement exists, otherwise returns false
 *
 * @param el - HtmlDivElement
 * @param level - number
 */
export const getParentElement = (el: HTMLDivElement, level: number): HTMLDivElement | false => {
  const parentElement = el.parentElement as HTMLDivElement;
  if (el && parentElement) {
    if (level === 0) {
      return parentElement;
    } else {
      return getParentElement(parentElement, level - 1);
    }
  }
  return false;
};

/**
 * Method to calculate the Exercise's Tooltip boundaries to position the tooltip at
 * top or bottom at the selected Gizmo.
 */
export const calculateExerciseToolTipBounds = (
  element: HTMLDivElement,
  toolTip: BoundingRect,
  exercise: HTMLDivElement
): ToolTipBounds => {
  let noseBounds: Bounds = {},
    boundLeft;
  const {
    height: elementHeight,
    width: elementWidth,
    left: elementLeft,
    top: elementTop,
  } = element.getBoundingClientRect();
  const { left: containerLeft, top: containerTop } = exercise.getBoundingClientRect();
  const { height: tooltipHeight, width: tooltipWidth } = toolTip;
  const halfObjectWidth = elementWidth / 2;
  const halfToolTipWidth = tooltipWidth / 2;
  // to place tooltip at top or bottom automatically
  const place =
    exercise.offsetTop + containerTop + tooltipHeight + TOOLTIP_BOTTOM_MARGIN <= elementTop
      ? 'bottom'
      : 'top';
  const boundTop =
    place === 'top'
      ? elementTop + elementHeight + TOOLTIP_TOP_MARGIN
      : elementTop - tooltipHeight - TOOLTIP_BOTTOM_MARGIN - POPUP_MARGIN;
  const elementLeftFromContainer = elementLeft - containerLeft;
  const tooltipLeft = elementLeftFromContainer + halfObjectWidth - halfToolTipWidth;
  if (tooltipLeft < 0) {
    boundLeft =
      containerLeft === 0 ? elementLeft - POPUP_MARGIN : elementLeftFromContainer - POPUP_MARGIN;
    noseBounds = {
      left: halfObjectWidth,
    };
  } else {
    boundLeft = tooltipLeft - POPUP_MARGIN;
  }
  return {
    place,
    bounds: {
      top: boundTop - containerTop,
      left: boundLeft,
    },
    noseBounds,
  };
};

/**
 * Check if KEM is available for given exercise and step.
 * @param exercise
 * @param step
 */
export const isKEMAvailable = (exercise: AppExercise, step: AppStep) =>
  exercise.dependencies.kems.length > 0 && step.showKEM;

/**
 * Check if hints & support available for given step
 * @param AppStep
 */
export const isHintAvailable = ({ hints }: AppStep) => !!hints && hints.length > 0;
export const isSupportAvailable = ({ supports }: AppStep) => !!supports && supports.length > 0;

/**
 * http://trac.bm.loc/ticket/42626
 * Show additional help to user:
 * -  When current attempt on the step is wrong.
 * -  Show Hints button if hints & supports is available else Show Example button if KEM
 * is available.
 * @param exercise
 */
export const getAdditionalHelpType = (exercise: AppExercise): AdditionalHelpType => {
  const appStep = AppExercise.toCurrentStep.get(exercise);
  if (appStep) {
    const { status, validity } = appStep;
    // Conditions to check for showing hint
    const showHint = isHintAvailable(appStep) || isSupportAvailable(appStep);
    const showKEM = isKEMAvailable(exercise, appStep);

    if (status === StepStatus.attempted && validity === Validity.wrong) {
      let dialogType: AdditionalHelpType;
      if (showHint) {
        dialogType = DialogType.hints;
      } else if (showKEM) {
        dialogType = DialogType.textbook;
      }
      return dialogType;
    }
  }
};

/**
 * Calculates whether `SubmitIrrevocably` should be shown or not for a step in an exercise.
 * @param appSteps
 * @param mode
 * @param currentStepId
 */
export const showIrrevocablyButton = (
  appSteps: ReadonlyArray<AppStep>,
  mode: SeriesMode,
  currentStepId?: string
) => {
  /**
   * Conditions to check when there is a current Step.
   * 1. More than one step
   * 2. CurrentStep is not last step
   * 3. CurrentStep not completed.
   * 4. series is in testMode
   */
  const currentStep = currentStepId && appSteps.find(({ id }) => currentStepId === id);
  const lastStep = last(appSteps) as AppStep;
  return (
    mode === SeriesMode.test &&
    currentStep &&
    appSteps.length > 1 &&
    currentStep.status !== StepStatus.completed &&
    currentStepId !== lastStep.id
  );
};

/**
 * To enable the tooltip only when no dialogs opened
 * Eg: Tooltip should not appears, when we close any other dialogs like Help,
 * Quit confirmation etc
 * triggering tooltip when dialog type is not defined - fix for 43503
 * */
export const shouldShowTooltip = (
  prevDialog: DialogType | undefined,
  currentDialog: DialogType | undefined
) =>
  currentDialog !== DialogType.notification &&
  ((prevDialog === DialogType.none && currentDialog === DialogType.none) ||
    isUndefined(currentDialog));
