import { rulers } from '../../../../../gizmo-utils/configuration/rulers';
import { stylers } from '../../../../../gizmo-utils/configuration/stylers';
import { enrichContentDict } from '../../../../../gizmo-utils/measure';
import { type ContentDict, isFormulaContent } from '@bettermarks/gizmo-types';
import { removeCursor, processFormula } from '@bettermarks/importers';
import { entries } from 'lodash/fp';
import { decryptContent } from './decrypt';
import { type UserInput } from '../result-manager/types';
import { type UserInputResponse } from './types';

const removeAllCursors = (contentDict: ContentDict) => {
  const selectedContent = entries(contentDict).find(
    ([, content]) => !!(content && content.selected)
  );

  return {
    ...contentDict,
    ...(selectedContent &&
      isFormulaContent(selectedContent[1]) && {
        [selectedContent[0]]: processFormula(removeCursor, false)(selectedContent[1]),
      }),
  };
};

export const prepareContentDict = (content: string, seriesReview: boolean) => {
  const contentParsed = seriesReview ? removeAllCursors(JSON.parse(content)) : JSON.parse(content);
  return enrichContentDict(contentParsed, stylers, rulers, seriesReview);
};

export const parseUserInput = (userInput: UserInputResponse, seriesReview: boolean): UserInput => ({
  ...userInput,
  question: prepareContentDict(userInput.question, seriesReview),
  feedbacks: userInput.feedbacks.map((f) => ({
    id: f.id,
    ...(f.key && { key: f.key }),
    severity: f.severity,
    learningObjectiveId: f.learningObjectiveId,
    ...(f.contentDict && {
      contentDict: prepareContentDict(f.contentDict, seriesReview),
    }),
  })),
});

export const getPlainTextResponse = (text: string) =>
  text.match(/^v\d\/\/\/\//) ? decryptContent(text) : text;
