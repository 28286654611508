import {
  annotationInner,
  type CalendarGizmoContent,
  type DaySpan,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';

const toXMLDaySpans = (daySpans: DaySpan[]): string => {
  return daySpans.reduce<string>(
    (xml, daySpan) => `
    ${xml}
    <mrow>
      <mtext>${daySpan.from}</mtext>
      <mtext>${daySpan.to}</mtext>
    </mrow>
    `,
    ''
  );
};

export const exportCalendar: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as CalendarGizmoContent;
  const daySpansXML = toXMLDaySpans(content.daySpans);
  const { year, month, count, firstDayOfWeek, showWeekDays } = content;
  const maybeYear = year ? `<year>${year}</year>` : '';

  return semantics(
    `
    <mrow>
      <configuration>
        ${maybeYear}
        <month>${month}</month>
        <count>${count}</count>
        <firstDayOfWeek>${firstDayOfWeek}</firstDayOfWeek>
        <showWeekDays>${showWeekDays}</showWeekDays>
      </configuration>
      <set of="dayspans">
        ${daySpansXML}
      </set>
    </mrow>
    `,
    annotationInner(content.$, content)
  );
};
