import type * as Redux from 'redux';
import { type ValidatorResponse, type ValidatorStepResponse } from '@bettermarks/umc-kotlin';
import {
  type ApplicationState,
  type ExerciseStatus,
  type SeriesMode,
  type SeriesStatus,
  type StepStatus,
} from '../../types';
import { type ContentDict } from '@bettermarks/gizmo-types';
import {
  type ExtendedValidatorResponse,
  type NlpValidatorResponse,
} from '../seriesplayer/containers/Series/ExtendedValidatorResponse';

export type NextStep = {
  id: string;
  question: ContentDict;
  questionXML: string;
  answer: ContentDict;
  answerXML: string;
};

export type ValidationPayload = {
  seriesStatus: SeriesStatus;
  exerciseStatus: ExerciseStatus;
  stepStatus: StepStatus;
  aborted: boolean;
  mode: SeriesMode;
} & ExtendedValidatorResponse;

type CommonValidatorPayload = {
  seriesStatus: SeriesStatus;
  exerciseStatus: ExerciseStatus;
  stepStatus: StepStatus;
  aborted: boolean;
  mode: SeriesMode;
  isEmptyInput: boolean;
};

export type MathValidatorPayload = CommonValidatorPayload & ValidatorResponse;

export type NlpValidatorPayload = CommonValidatorPayload & NlpValidatorResponse;

export type NlpValidatorStepResponse = {
  id: string;
  index: number;
};

export const isMathValidatorStep = (
  stepResponse: NlpValidatorStepResponse | ValidatorStepResponse
): stepResponse is ValidatorStepResponse =>
  stepResponse.hasOwnProperty('questionXML') && stepResponse.hasOwnProperty('answerXML');

export const isMathValidatorPayload = (
  payload: ValidationPayload | TestValidationPayload
): payload is MathValidatorPayload => payload.hasOwnProperty('annotatedUserAnswerXML');

export type TestValidationPayload = {
  exerciseIndex: number;
  stepIndex: number;
} & (ValidatorResponse | NlpValidatorResponse);

export type Store = Redux.Store<ApplicationState>;
