import { type ArrowDirection } from '../components/Arrow';
import {
  CONFIGURATION,
  type ContentReference,
  DAY_DURATION_DEFAULT_CONTENT,
  type DayDurationGizmoContent,
  DIRECTION,
  type FElement,
  type Importer,
  type ImporterContext,
  SEMANTICS,
  TEXT,
  xmlText,
  type FormulaContent,
  Restriction,
} from '@bettermarks/gizmo-types';
import { importFormula, KeyboardToolInput } from '../formula/importer';

/**
 * We need to add a control rule of 2 digits max. on duration input.
 * To do so, we add needed properties to the imported formula content
 * at the import time.
 */
export const limitInputDigits =
  (limit: number) =>
  (textElement: FElement, context: ImporterContext): ContentReference => {
    const temp = context.tempContext();
    const refId = context.generateId();
    const content = temp.invoke<FormulaContent>(importFormula, textElement);
    // adding digit limit rule on input if text is interactive
    context.content.set(
      refId,
      content.$interactionType === TEXT
        ? {
            ...content,
            maxInputLength: limit,
            toolSet: KeyboardToolInput.numbers_letters,
            configuration: {
              ...content.configuration,
              restrictionType: Restriction.Numbers,
            },
          }
        : content
    );
    return { $refid: refId };
  };

export const importDayDuration: Importer<DayDurationGizmoContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);
  const direction = xmlText(configuration.findChildTag(DIRECTION)) as ArrowDirection;
  const label = mrow.findChildTag(SEMANTICS);
  const labelRef = limitInputDigits(3)(label, context);

  return {
    ...preContent,
    ...DAY_DURATION_DEFAULT_CONTENT,
    direction,
    labelRef,
  };
};
