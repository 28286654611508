import { handleAction } from 'redux-actions';
import {
  ApplicationState,
  type AppStep,
  ExerciseStatus,
  SeriesStatus,
  StepStatus,
} from '../../../types';
import { initialSeriesplayerState } from '../../store/constants';
import { Lens } from '@bettermarks/gizmo-types';
import { compose, map } from 'lodash/fp';
import { Severity, Validity } from '@bettermarks/umc-kotlin';
import { SUBMIT_INCOMPLETE_SERIES } from './applicationActions';

const submitStepIncomplete = (step: AppStep): AppStep =>
  step.status === StepStatus.completed
    ? step
    : {
        ...step,
        status: StepStatus.completed,
        validity: Validity.wrong,
        feedbacks: [
          {
            id: step.id,
            severity: Severity.error,
            key: 'feedbacks:standard.empty',
            learningObjectiveId: undefined,
          },
        ],
        submittedIncomplete: true,
        numberOfErrors: step.maxErrors,
        aborted: true,
        knowledgeGaps: [],
      };

export const handleSubmitIncompleteSeries = handleAction(
  SUBMIT_INCOMPLETE_SERIES,
  (state: ApplicationState) =>
    compose(
      Lens.update(ApplicationState.toExercises)(
        map((ex) => ({
          ...ex,
          status: ExerciseStatus.completed,
          ...(ex.steps.length > 0 ? { currentStepId: ex.steps[ex.steps.length - 1].id } : {}),
          steps: ex.steps
            // sanitize steps array (we have cases where it contains undefined)
            // TODO find out which code is adding those `undefined`s to the steps array
            .filter(Boolean)
            .map(submitStepIncomplete),
        }))
      ),
      ApplicationState.toCurrentExerciseIndex.set(state.series.exercises.length - 1),
      ApplicationState.toSeriesStatus.set(SeriesStatus.completed)
    )(state),
  initialSeriesplayerState
);
