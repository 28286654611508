import * as React from 'react';
import {
  type ContextState,
  renderGizmos,
  useContentTranslation,
} from '../../gizmo-utils/polymorphic-gizmo';
import { Duration } from '../components/Duration';
import { DURATION_HORIZONTAL_PADDING, DURATION_VERTICAL_PADDING } from './constants';
import { type DayDurationGizmoContent } from '@bettermarks/gizmo-types';
import styles from './DayDuration.scss';

export type DayDurationGizmoProps = DayDurationGizmoContent & ContextState;

/**
 * `DayDurationGizmo` component.
 *
 *      7 Days
 * ------------------->
 *
 * UI speification sets:
 *  - `24px` at the left and right of the label above + the width of the arrow marker.
 * (the arrow marker width is added by the `Duration` UI component)
 *  - `16px` between the arrow and the label above.
 *
 */
export const DayDurationGizmo: React.FC<DayDurationGizmoProps> = ({
  direction,
  labelRef,
  availableWidth,
}) => {
  const t = useContentTranslation();
  return (
    <Duration
      direction={direction}
      labelHorizontalPadding={DURATION_HORIZONTAL_PADDING}
      labelVerticalPadding={DURATION_VERTICAL_PADDING}
    >
      <div className={styles.dayDurationLabel}>
        {renderGizmos([labelRef], availableWidth)}
        <span>&nbsp;{t('timeEditor.days')}</span>
      </div>
    </Duration>
  );
};
