// this import is important to avoid TS4023 error when running tsc (ImporterContextImpl)
import {
  annotationInner,
  type Annotations,
  CONFIGURATION,
  createImporterContext,
  DECORATION,
  DYNAMIC_DECORATION,
  type FElement,
  type FormulaStyles,
  hasInteractionType,
  type Importer,
  type ImporterContext,
  InputToolTypes,
  IS_REMOVED_AT_ACTIVATION,
  KEY,
  type KeyboardTool,
  MATH,
  MAX_INPUT_LENGTH,
  RS,
  SEMANTICS,
  semantics,
  SPECIAL,
  switchMap,
  toBoolean,
  toInt,
  toTrueOrUndefined,
  toXmlElement,
} from '@bettermarks/gizmo-types';
import * as T from '@bettermarks/gizmo-types';
import { identity, includes, isEmpty, isNil, omitBy } from 'lodash';
import {
  ensureMRow,
  MABS,
  MFENCED,
  MFRAC,
  MOVER,
  MROOT,
  MROW,
  MSPACE,
  MSQRT,
  MSUB,
  MSUBSUP,
  MSUP,
  MTEXT,
  MUNDER,
  MUNDEROVER,
} from '../constructors';
import { parseDecoration } from './parseDecoration';

type FormulaElementImporter = (
  element: FElement,
  context: ImporterContext,
  interactive?: boolean
) => T.PureMathContent;

/**
 * The input format in the xml
 */
export const enum KeyboardToolInput {
  _delimiter = ',',
  absolute = 'absolute',
  brackets = 'brackets',
  decimal_point = 'decimal_point',
  exponent = 'exponent',
  fraction = 'fraction',
  numbers_letters = 'numbers_letters',
  numbers = 'numbers',
  letters = 'letters',
  operators = 'operators',
  pi = 'pi',
  root = 'root',
  sqrt = 'sqrt',
}

export const ALL_KEYBOARD_TOOLS = [
  KeyboardToolInput.absolute,
  KeyboardToolInput.brackets,
  KeyboardToolInput.decimal_point,
  KeyboardToolInput.exponent,
  KeyboardToolInput.fraction,
  KeyboardToolInput.numbers_letters,
  KeyboardToolInput.numbers,
  KeyboardToolInput.letters,
  KeyboardToolInput.operators,
  KeyboardToolInput.pi,
  KeyboardToolInput.root,
  KeyboardToolInput.sqrt,
].join(KeyboardToolInput._delimiter);

export const parseToolSet = (toolList: string): KeyboardTool => {
  const tools = toolList.split(KeyboardToolInput._delimiter);
  return {
    type: InputToolTypes.keyboard,
    layout: omitBy(
      {
        absolute: includes(tools, KeyboardToolInput.absolute),
        brackets: includes(tools, KeyboardToolInput.brackets),
        decimal_point: includes(tools, KeyboardToolInput.decimal_point),
        exponent: includes(tools, KeyboardToolInput.exponent),
        fraction: includes(tools, KeyboardToolInput.fraction),
        letters:
          includes(tools, KeyboardToolInput.numbers_letters) ||
          includes(tools, KeyboardToolInput.letters),
        numbers:
          includes(tools, KeyboardToolInput.numbers_letters) ||
          includes(tools, KeyboardToolInput.numbers),
        operators: includes(tools, KeyboardToolInput.operators),
        pi: includes(tools, KeyboardToolInput.pi),
        root: includes(tools, KeyboardToolInput.root),
        sqrt: includes(tools, KeyboardToolInput.sqrt),
      },
      (val) => !val
    ),
    selectedPage: 0,
  };
};

export const importDenormalized = (
  element: FElement,
  context: ImporterContext,
  interactive: boolean
): T.MRow => ensureMRow(importSingleElement(element, context, interactive), interactive);

/**
 * Determines if formula XML contents lead to skipped `formula`.
 */
const isSkippableXml = (formulaContent: FElement): boolean =>
  formulaContent.children.length === 1 &&
  (formulaContent.firstChild.localName === SEMANTICS || isSkippableXml(formulaContent.firstChild));
/**
 * Determines if `formulaContent` can be skipped.
 */
const isSkippable = (
  { $, $interactionType }: Partial<Annotations>,
  decoration: Partial<FormulaStyles>
) => ($ === MATH || $ === SPECIAL) && isEmpty(decoration) && isEmpty($interactionType);

/**
 * In some exercises the following pattern is part of the content: `<mi>.</mi>`.
 * In earlier days this was put there instead of the formal `<none>`.
 *
 * @see http://trac.bm.loc/ticket/43088
 * @see https://www.w3.org/TR/MathML3/chapter3.html#presm.mmultiscripts
 */
const stripMiDot = (mc: T.MathContent) => !(T.isMI(mc) && mc.text === '.');

const formulaDecoString = switchMap<(mrow: FElement, dynamic: boolean) => string>(
  {
    // in case of render-style digit, the validator has the habit of attaching the
    // validity decoration to the (one and only) mn child (ignore, if it has no contents!!)
    [RS.DIGIT]: (mrow, dynamic, mn = mrow.findChildTag(T.$MN)) =>
      !isEmpty(mn.text) ? mn.attribute(dynamic ? DYNAMIC_DECORATION : DECORATION, '') : '',
    // in case of render-style tablecell-text, ignore validity on empty mrows
    [RS.TABLECELL_TEXT]: (mrow, dynamic) =>
      mrow.hasChildren() ? mrow.attribute(dynamic ? DYNAMIC_DECORATION : DECORATION, '') : '',
  },
  // for all other cases it is accessible at the top level mrow, no exceptions for emptiness
  (mrow, dynamic) => mrow.attribute(dynamic ? DYNAMIC_DECORATION : DECORATION, '')
);

const importFormulaMath: Importer<T.FormulaContent> = (preContent, math, context) => {
  const decoString = math.firstChild.attribute(DECORATION, '');
  const { severity } = parseDecoration(decoString, context.convertFontSize);

  return {
    ...T.DEFAULT_UNSTYLED,
    ...T.DEFAULT_UNALIGNED,
    ...preContent,
    ...(severity && { severity }),
    content: math.children
      .map((item) => importSingleElement(item, context, false, isSkippableXml(math), preContent))
      .filter(stripMiDot),
  };
};

export const importFormulaSemantics: Importer<T.FormulaContent> = (
  preContent,
  semantics,
  context
) => {
  const mrow = semantics.findChildTag(T.$MROW);

  const decoString = formulaDecoString(preContent.$renderStyle)(mrow, false);
  const { decoration, severity } = parseDecoration(decoString, context.convertFontSize);

  const dynamicDecoString = formulaDecoString(preContent.$renderStyle)(mrow, true);

  const skip = isSkippableXml(mrow) && isSkippable(preContent, decoration);

  const interactive = hasInteractionType(preContent);
  let result: T.FormulaContent = {
    ...T.DEFAULT_UNSTYLED,
    ...T.DEFAULT_UNALIGNED,
    ...preContent,
    ...(!isEmpty(decoration) && { decoration }),
    ...(dynamicDecoString && { dynamicDecoration: dynamicDecoString }),
    ...(severity && { severity }),
    content: mrow
      .filterChildren((item) => item.localName !== CONFIGURATION)
      .map((item) => importSingleElement(item, context, interactive, skip, preContent))
      .filter(stripMiDot),
  };

  const configuration = mrow.findChildTag(CONFIGURATION);

  if (configuration) {
    const { restrictionType } = configuration.tagsToProps(
      T.toRestrictionType,
      [],
      ['restrictionType']
    );
    const { restrictedToKeys } = configuration.tagsToProps(
      T.toRestrictedToKeys,
      [],
      ['restrictedToKeys']
    );

    if (restrictionType || restrictedToKeys) {
      result = {
        ...result,
        configuration: {
          restrictionType,
          restrictedToKeys,
        },
      };
    }
  }

  if (preContent.toolSet) {
    result.tool = parseToolSet(preContent.toolSet);
  }

  if (preContent.$interactionType) {
    result.initiallyUnselected = true;
  }

  const label = result.content[0];
  // remember
  if (label && label.hasOwnProperty('isRemovedAtActivation')) {
    result = {
      ...result,
      placeholderLabel: ensureMRow(result.content[0], interactive),
      content: [],
      decoration: parseDecoration(
        decoString.replace(/default-content;?/, ''),
        context.convertFontSize
      ).decoration,
    };
  }

  return result;
};

export const importFormula: Importer<T.FormulaContent> = (preContent, xml, context) => {
  const formula = {
    ...(preContent.$ === MATH
      ? importFormulaMath(preContent, xml, context)
      : importFormulaSemantics(preContent, xml, context)),
    ...(!context.convertFontSize && {
      withConvertedFontSize: context.convertFontSize,
    }),
  };
  const isRoot = context.isRoot();
  return isRoot
    ? {
        ...formula,
        isRoot,
      }
    : formula;
};

export const importDigit: Importer<T.FormulaContent> = (preContent, xml, context) =>
  importFormula(
    {
      ...preContent,
      [MAX_INPUT_LENGTH]: 1,
      toolSet: KeyboardToolInput.numbers_letters,
    },
    xml,
    context
  );

function importCursor(element: FElement): T.Cursor {
  return {
    $: T.$CURSOR,
    ...element.attributesToProps(toBoolean, [], ['still']),
  };
}

function isLocalName(name: string): name is T.MToken['$'] {
  const tokens = [T.$MI, T.$MN, T.$MO, T.$MTEXT];
  return includes(tokens, name);
}

function importMToken(element: FElement): T.MToken {
  const localName: T.MToken['$'] = isLocalName(element.localName) ? element.localName : T.$MTEXT;
  const result: T.MToken = {
    ...T.DEFAULT_UNSTYLED,
    $: localName,
    text: element.text
      // multiple whitespaces get one
      .replace(/(\\n|\s){2,}/g, ' ')
      // remove trailing and leading whitespace, except \u202F
      // (NARROW NO-BREAK SPACE, added by generator between unit and value, e.g.:
      //  <mn>2</mn><mtext>\u202F</mtext><mi>cm</mi>
      // )
      .replace(/(^[^\S\u202F]|[^\S\u202F]$)/g, '')
      /**
       * The content contains inside formula cases of `&amp;lt;` for `<` and `&amp;gt;` for `>`.
       * Those are not handled automatically by the DOMParser, since it only unescapes `&amp;` to
       * `&`, thus emitting: `&lt;` and `&gt;`. The following lines unescape them.
       */
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>'),
  };
  if (element.hasAttribute('form')) {
    result.form = element.attribute('form', 'inline');
  }
  if (element.hasAttribute('id')) {
    result.id = element.attribute('id');
  }
  if (element.hasAttribute('type')) {
    result.type = element.attribute('type');
  }
  if (element.hasAttribute('valueSetterRefId')) {
    result.valueSetterRefId = element.attribute('valueSetterRefId');
  }
  if (element.hasAttribute('dynamic')) {
    result.dynamic = element.attribute('dynamic');
  }
  return result;
}

function importMCal(element: FElement): T.MCalibrate {
  return {
    $: T.$MCAL,
    ...element.attributesToProps(toInt, [], ['height', 'refLine']),
  };
}

function importMExpansion(element: FElement, context: ImporterContext): T.MExpansion {
  const [numeratorFactor, lhsFraction, operator, rhsFraction, denominatorFactor] =
    element.children.map((element, index) => {
      let importedElement = importSingleElement(element, context);
      // fraction on the left hand side or fraction on right hand side
      if (index === 1 || index === 3) {
        if (T.isContentReference(importedElement)) {
          // The below code is to pull the mfrac out of the semantics node, if it is wrapped
          // so as to make LHS fraction and RHS fraction symmetric.
          const fmContent = context.content.get(importedElement.$refid) as T.FormulaContent;

          if (
            (fmContent.$renderStyle === RS.TEXT || fmContent.$renderStyle === RS.FORMULA) &&
            isNil(fmContent.$interactionType) &&
            fmContent.content.length > 0
          ) {
            importedElement = fmContent.content[0];
          }
        }
      }
      return importedElement;
    });
  return {
    ...T.DEFAULT_UNSTYLED,
    $: T.$MEXPANSION,
    numeratorFactor,
    lhsFraction,
    operator,
    rhsFraction,
    denominatorFactor,
  };
}

function importMFrac(element: FElement, context: ImporterContext, interactive: boolean): T.MFrac {
  const [numerator, denominator] = element.children.map((element) =>
    importDenormalized(element, context, interactive)
  );
  return MFRAC(numerator, denominator);
}

function importAccentAttr(element: FElement): boolean | undefined {
  return element.hasAttribute('accent') ? toBoolean(element.attribute('accent')) : undefined;
}

function importMOver(
  element: FElement,
  context: ImporterContext,
  interactive: boolean
): T.MOver | T.MText {
  const [base, overscript] = element.children.map((element) =>
    importSingleElement(element, context, interactive)
  );
  // if a text overscript is empty, we want to import as text
  // (to avoid unnecessary overscript format).
  // It could occurs for NL locale. a distance does not have
  // any overscript
  if (T.isMO(overscript) && isEmpty(overscript.text) && T.isMText(base) && !isEmpty(base.text)) {
    return base;
  }
  return MOVER(
    base,
    T.isMO(overscript) ? { ...overscript, stretchable: true } : ensureMRow(overscript, interactive),
    importAccentAttr(element)
  );
}

function importMRoot(element: FElement, context: ImporterContext, interactive: boolean): T.MRoot {
  const [radicand, index] = element.children.map((element) =>
    importDenormalized(element, context, interactive)
  );
  return MROOT(index, radicand);
}

function importMSqrt(
  element: FElement,
  context: ImporterContext,
  interactive: boolean
): T.MSqrt | T.MRoot {
  const radicand = importDenormalized(element.firstChild, context, interactive);
  if (interactive) {
    return MROOT({ ...MROW(), interactive }, radicand);
  } else {
    return MSQRT(radicand);
  }
}

function importMSub(element: FElement, context: ImporterContext, interactive: boolean): T.MSub {
  const [base, subscript] = element.children.map((element) =>
    importDenormalized(element, context, interactive)
  );
  return MSUB(base, subscript);
}

function importMSubSup(
  element: FElement,
  context: ImporterContext,
  interactive: boolean
): T.MSubSup {
  // order of children is similar to flex client and differs with standard MathML
  const [base, superscript, subscript] = element.children.map((element) =>
    importDenormalized(element, context, interactive)
  );
  return MSUBSUP(base, subscript, superscript);
}

function importMSup(element: FElement, context: ImporterContext, interactive: boolean): T.MSup {
  const [base, superscript] = element.children.map((element) =>
    importDenormalized(element, context, interactive)
  );
  return MSUP(base, superscript);
}

function importMRow(element: FElement, context: ImporterContext, interactive: boolean): T.MRow {
  return MROW(
    ...element.children
      .map((element) => importSingleElement(element, context, interactive))
      .filter(stripMiDot)
  );
}

function importMFenced(
  element: FElement,
  context: ImporterContext,
  interactive: boolean
): T.MFenced | T.MAbs {
  const children = element.children
    .map((element) => importSingleElement(element, context, interactive))
    .filter(stripMiDot);
  const { open, close } = element.attributesToProps(identity, [], ['open', 'close']);
  return open === T.ABSOLUTE_BAR && close === T.ABSOLUTE_BAR
    ? MABS(element.children.length === 1 ? children[0] : MROW(...children))
    : // for continence the FENCED constructor has the defaults '(', ')'
      // but open and close not being present in the XML is expected
      // to also yield no attributes when exported.
      // The attributes not being present means ( )
      // while the attributes with empty string means no visible fence
      { ...MFENCED(children), open, close };
}

function importMSpace(element: FElement): T.MSpace {
  const result: T.MSpace = {
    ...MSPACE,
    ...element.attributesToProps(toInt, [], ['width', 'height']),
  };

  const linebreak = T.toLinebreakKind(element.attribute('linebreak', undefined));
  if (linebreak) {
    result.linebreak = linebreak;
  }
  return result;
}

function importMUnder(element: FElement, context: ImporterContext, interactive: boolean): T.MUnder {
  const [base, underscript] = element.children.map((element) =>
    importSingleElement(element, context, interactive)
  );

  return MUNDER(
    base,
    T.isMO(underscript)
      ? { ...underscript, stretchable: true }
      : ensureMRow(underscript, interactive),
    importAccentAttr(element)
  );
}

function importMUnderOver(
  element: FElement,
  context: ImporterContext,
  interactive: boolean
): T.MUnderOver {
  const [base, underscript, overscript] = element.children.map((element) =>
    importSingleElement(element, context, interactive)
  );

  return MUNDEROVER(
    base,
    T.isMO(overscript) ? { ...overscript, stretchable: true } : ensureMRow(overscript, interactive),
    T.isMO(underscript)
      ? { ...underscript, stretchable: true }
      : ensureMRow(underscript, interactive),
    importAccentAttr(element)
  );
}

const importMN: FormulaElementImporter = (element, context, interactive) => {
  const mn = {
    ...(importMToken(element) as T.MN),
  };

  // In interactive formulas, thousands separators need to be removed
  return interactive
    ? {
        ...mn,
        text: mn.text.replace(/,/g, ''),
      }
    : mn;
};

const IMPORTER_MAP: { [k: string]: FormulaElementImporter } = {
  [T.$CURSOR]: importCursor,
  [T.$MCAL]: importMCal,
  [T.$MI]: importMToken,
  [T.$MO]: importMToken,
  [T.$MN]: importMN,
  [T.$MTEXT]: importMToken,
  [T.$MEXPANSION]: importMExpansion,
  [T.$MFENCED]: importMFenced,
  [T.$MFRAC]: importMFrac,
  [T.$MOVER]: importMOver,
  [T.$MROOT]: importMRoot,
  [T.$MROW]: importMRow,
  [T.$MSPACE]: importMSpace,
  [T.$MSQRT]: importMSqrt,
  [T.$MSUB]: importMSub,
  [T.$MSUBSUP]: importMSubSup,
  [T.$MSUP]: importMSup,
  [T.$MUNDER]: importMUnder,
  [T.$MUNDEROVER]: importMUnderOver,
};

const LINK_ATTR = 'xl:href';

export function importSingleElement(
  element: FElement,
  context: ImporterContext,
  interactive = false,
  skip = false,
  preContent?: Annotations
): T.MathContent {
  if (element.localName === SEMANTICS || element.localName === MATH) {
    return context.importXML(element, skip, preContent);
  }
  if (element.localName === T.$MTABLE) {
    // forward <mtable> children to the table gizmo
    // by wrapping it into a semantics node with render-style simple-table (#41781)
    return context.importXML(
      toXmlElement(
        semantics(element.toString(), annotationInner(SPECIAL, { $renderStyle: RS.SIMPLE_TABLE }))
      )
    );
  }
  // removes mrow tag details if it matches following condition
  // eg: <mrow><mtext>test</mtext></mrow>
  // outcome: only mtext data will be imported
  if (
    element.localName === 'mrow' &&
    !element.hasAttribute(DECORATION) &&
    !element.hasAttribute(LINK_ATTR) &&
    element.children.length === 1
  ) {
    return importSingleElement(element.firstChild, context, interactive, skip, preContent);
  }
  return importPureMathContent(element, context, interactive);
}

function importPureMathContent(
  element: FElement,
  context: ImporterContext,
  interactive = false
): T.PureMathContent {
  const localName = element.localName;
  if (!(localName !== null && localName in IMPORTER_MAP)) {
    throw new Error(`unparsed element ${localName === null ? 'null' : localName}`);
  }

  const result: T.PureMathContent = IMPORTER_MAP[localName](element, context, interactive);

  if (element.hasAttribute(IS_REMOVED_AT_ACTIVATION)) {
    console.log(element.localName);
    const isRemovedAtActivation = element.attribute(IS_REMOVED_AT_ACTIVATION, 'false');
    (result as T.MTokenBase).isRemovedAtActivation = toTrueOrUndefined(isRemovedAtActivation);
  }

  if (element.hasAttribute(DECORATION)) {
    const decoFromXml = element.attribute(DECORATION, '');
    // following condition avoids adding empty decoration object to content
    const { decoration } = parseDecoration(decoFromXml, context.convertFontSize);
    if (!isEmpty(decoration)) {
      (result as T.MDecoratable).decoration = decoration;
    }
  }
  if (element.hasAttribute(DYNAMIC_DECORATION)) {
    const dynamicDecoFromXml = element.attribute(DYNAMIC_DECORATION, '');
    if (dynamicDecoFromXml) {
      (result as T.MDecoratable).dynamicDecoration = dynamicDecoFromXml;
    }
  }

  const linkValue = element.attribute(LINK_ATTR);
  if (/^fem:/.test(linkValue)) {
    const femLink = linkValue.replace('fem:', '');
    context.fems.add(femLink);
    (result as T.MRow).femLink = femLink;
  }

  if (interactive) {
    (result as T.Interactable).interactive = interactive;
  }

  return result;
}

export const importOnlyPureMathContent =
  (context = createImporterContext()) =>
  (element: FElement): T.PureMathContent =>
    importPureMathContent(element, context);

/**
 * special content without render-style, just only the reference key to the localized resource
 * mostly used for the step instruction and standard feedbacks texts.
 */
export const importTranslationKey: Importer<T.FormulaContent> = (preContent, xml) => {
  return {
    ...T.DEFAULT_MATH_CONTENT,
    ...preContent,
    $renderStyle: RS.TEXT,
    content: [{ ...MTEXT(xml.attribute(KEY, '')), translate: true }],
  };
};
