export const DIAGRAM_BAR_CHART = 'diagram-bar-chart';
export const DIGIT = 'digit';
export const DRAG_SOURCE = 'dragV2-source';
export const DROP_TARGET = 'dragV2-target';
export const FORM_ALL = 'form-all';
export const FORM_COLORIZE = 'form-colorize';
export const FORM_BREAK_COLORIZE = 'form-break-colorize';
export const FORMULA = 'formula';
export const GRAPH = 'graph';
export const HELLO_GIZMO = 'hello-gizmo';
export const METRICS_POLYGONSELECTION = 'metrics-polygonselection';
export const METRICS_SYSTEM = 'metrics-system';
export const MULTIPLE_CHOICE_DROP_DOWN = 'multiple-choice-drop-down';
export const MULTIPLE_CHOICE_MULTI = 'multiple-choice-multi';
export const MULTIPLE_CHOICE_SINGLE = 'multiple-choice-single';
export const NLP_TEXT = 'nlp-text';
export const EDITABLE_NLP_PRESENTATION = 'editable-nlp-presentation';
export const PIE_CHART = 'pie-chart';
export const SET_DROP_TARGET = 'dragV2-set-target';
export const SLIDER = 'slider';
export const SORTING = 'sort-drag-drop';
export const SOLUTION_SET = 'solution-set2';
export const STACKED_BAR_CHART = 'stacked-bar-chart';
export const TEXT = 'text';
export const THERMOMETER = 'thermometer';
export const VALUE_STEPPER = 'value-stepper';
export const LIST_SELECTION = 'list-selection';
