import * as React from 'react';
import { EM } from '../../../types';

import { EMContentElement } from './EMContentElement';
import { ExampleGroup } from './ExampleGroup';

export type EMElementListProps = Readonly<{
  elements: EM.ElementList;
  availableWidth: number;
  mode: 'KEM' | 'FEM';
}>;

export const EMElementList: React.FC<EMElementListProps> = ({ elements, availableWidth, mode }) => (
  <>
    {elements.map((element, i) => {
      switch (element.type) {
        case EM.ElementType.Content:
        case EM.ElementType.Comment:
        case EM.ElementType.Rule:
          return (
            <EMContentElement
              {...element}
              availableWidth={availableWidth}
              first={false}
              last={false}
              key={i}
            />
          );

        default:
          // EM.ElementType.ExampleGroup
          return <ExampleGroup {...element} availableWidth={availableWidth} mode={mode} key={i} />;
      }
    })}
  </>
);
