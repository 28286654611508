import * as React from 'react';
import classNames from 'classnames';
import { POINT_RADIUS } from '@bettermarks/importers';
import {
  CIRCLE,
  CROSS,
  DISC,
  GeoEditorMode,
  GRID,
  INVISIBLE,
  PLUS,
  POINT,
  type POINT_STYLE,
  type PointDecoration,
  type SnapObject,
} from '@bettermarks/gizmo-types';
import { CircleSnapShape } from './CircleSnapShape';
import { CrossSnapShape } from './CrossSnapShape';

import styles from './SnapPoint.scss';

export type SnapPointProps = {
  mode: GeoEditorMode;
  x: number;
  y: number;
  snapObject: SnapObject;
  decoration: PointDecoration;
};

const getSnapPointShape = (
  mode: GeoEditorMode,
  x: number,
  y: number,
  decoration: PointDecoration
) => {
  const decorationType: POINT_STYLE | undefined = decoration.pointStyleType;

  const style = classNames(styles.circle, {
    [styles.delete]: mode === GeoEditorMode.DELETE,
  });

  switch (decorationType) {
    case CROSS:
    case PLUS:
      const transform = decorationType === CROSS;
      return <CrossSnapShape {...{ mode, x, y, decoration, transform }} />;

    case DISC:
    case CIRCLE:
    default:
      return <CircleSnapShape {...{ mode, style, x, y, decoration }} />;
  }
};

// Snappoint gets Screen coordinates by design!!!
export const SnapPoint: React.FC<SnapPointProps> = ({ mode, decoration, x, y, snapObject }) => {
  switch (snapObject) {
    case INVISIBLE:
      return <circle cx={x} cy={y} r={POINT_RADIUS} className={styles.invisiblePoint} />;
    case POINT:
    case GRID:
      return getSnapPointShape(mode, x, y, decoration);
    default:
      return <circle cx={x} cy={y} r={POINT_RADIUS} className={styles.preview} />;
  }
};

SnapPoint.displayName = 'SnapPoint';
