import * as React from 'react';
import { useEffect } from 'react';
import Measure, { type ContentRect } from 'react-measure';
import classNames from 'classnames';
import { type Decoration, ValueStepperPosition } from '@bettermarks/gizmo-types';
import styles from './ValueStepper.scss';
import { MinusXLargeBold, PlusXLargeBold } from '../icons';
import { noop } from 'lodash/fp';
import { useClickedOutside } from '../../utils/hooks';
import { decorationToString, parseDecoString } from '../../gizmo-utils/decoration';
import { getNamedColor } from '@bettermarks/importers';
import { useContentTranslation } from '../../gizmo-utils/polymorphic-gizmo';
import { ExcludedFromTabNavigationButton } from '../ExcludedFromTabNavigation';

export interface IValueStepperProps {
  id: string;
  stepperPosition: ValueStepperPosition;
  initiallyActive?: true;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange?: (value: number) => void;
  decoration: Decoration;
}

const ValueStepper: React.FC<IValueStepperProps> = ({
  id,
  min,
  max,
  step,
  value,
  stepperPosition,
  initiallyActive,
  onChange,
  decoration = {},
}) => {
  const t = useContentTranslation();
  const [stepperActive, setStepperActive] = React.useState(!!initiallyActive);
  const [plusButtonActive, setPlusButtonActive] = React.useState(value < max);
  const [minusButtonActive, setMinusButtonActive] = React.useState(value > min);
  const [valueFieldLength, setValueFieldLength] = React.useState(styles.INPUT_FIELD_MIN_WIDTH);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = () => {
    deactivateStepper();
  };

  useClickedOutside(ref, handleOutsideClick);

  const getValueWithinBoundaries = (proposedValue: number) => {
    return proposedValue <= min ? min : proposedValue >= max ? max : proposedValue;
  };

  const setValueWithinBoundaries = (proposedValue: number) => {
    const valueWithinBoundaries = getValueWithinBoundaries(proposedValue);

    setPlusButtonActive(valueWithinBoundaries + step <= max);
    setMinusButtonActive(valueWithinBoundaries >= min + step);

    // Only call onChange if the new value is different from the current one
    if (onChange && valueWithinBoundaries !== value) {
      onChange(valueWithinBoundaries);
    }
  };

  useEffect(() => {
    setValueWithinBoundaries(value);
  }, [min, max]);

  const plus = () => {
    setValueWithinBoundaries(value + step);
  };

  const minus = () => {
    setValueWithinBoundaries(value - step);
  };

  const activateStepper = () => setStepperActive(true);
  const deactivateStepper = () => setStepperActive(false);

  const decorationStyle = parseDecoString(decorationToString(decoration)).object;

  const inputFieldStyle = {
    fontSize: `${decorationStyle.fontSize}px`,
    fontWeight: decorationStyle.fontWeight,
    color: getNamedColor(decorationStyle.color),
  };

  const valueStepper = (
    <div className={styles.container} style={decorationStyle}>
      <ExcludedFromTabNavigationButton
        className={
          minusButtonActive ? styles.buttonClickAreaActive : styles.buttonClickAreaInactive
        }
        onClick={minusButtonActive ? minus : noop}
      />
      <ExcludedFromTabNavigationButton
        className={classNames(styles.stepperButton, styles.stepperButtonLeft)}
        tabIndex={-1}
      >
        <MinusXLargeBold
          className={minusButtonActive ? styles.iconEnabled : styles.iconDisabled}
          width={20}
          height={20}
        />
      </ExcludedFromTabNavigationButton>
      <ExcludedFromTabNavigationButton
        className={plusButtonActive ? styles.buttonClickAreaActive : styles.buttonClickAreaInactive}
        onClick={plusButtonActive ? plus : noop}
      />
      <ExcludedFromTabNavigationButton
        className={classNames(styles.stepperButton, styles.stepperButtonRight)}
        tabIndex={-1}
      >
        <PlusXLargeBold
          className={plusButtonActive ? styles.iconEnabled : styles.iconDisabled}
          width={20}
          height={20}
        />
      </ExcludedFromTabNavigationButton>
    </div>
  );

  const onResize = ({ bounds }: ContentRect): void => {
    return setValueFieldLength(bounds?.width);
  };

  const translateX = (): string => {
    switch (stepperPosition) {
      case ValueStepperPosition.RIGHT:
        return `calc(${valueFieldLength}px + ${styles.NOSE_WIDTH})`;
      case ValueStepperPosition.LEFT:
        return `calc(-100% - ${styles.NOSE_WIDTH})`;
      case ValueStepperPosition.BOTTOM:
      case ValueStepperPosition.TOP:
      default:
        return `calc(-50% + ${valueFieldLength / 2}px)`;
    }
  };

  const translateY = (): string => {
    switch (stepperPosition) {
      case ValueStepperPosition.RIGHT:
        return `calc(-100% + ${styles.VALUE_STEPPER_PADDING})`;
      case ValueStepperPosition.LEFT:
        return `calc(-${styles.VALUE_STEPPER_PADDING})`;
      case ValueStepperPosition.BOTTOM:
        return `0`;
      case ValueStepperPosition.TOP:
      default:
        return `-100%`;
    }
  };

  const localizedValueWithinBoundaries = (): string => {
    const unaryMinus = `${t ? t('formula.unaryMinusSignFlashFree') : '-'}\u202F`;
    const tSeparator = t ? t('formula.thousandsseparator') : '\u2009';
    const decimalPoint = t ? t('formula.decimalpoint') : '.';
    const localizedValue = String(getValueWithinBoundaries(value))
      .replace(/,/g, tSeparator)
      .replace(/\./g, decimalPoint)
      .replace(/-/g, unaryMinus);

    return localizedValue;
  };

  return (
    <div id={id} className={styles.wrapper} ref={ref}>
      {stepperActive &&
        (stepperPosition === ValueStepperPosition.TOP ||
          stepperPosition === ValueStepperPosition.LEFT) && (
          <div
            className={styles.stepper}
            style={{ transform: `translate(${translateX()},${translateY()})` }}
          >
            {valueStepper}
            <div
              className={
                stepperPosition === ValueStepperPosition.TOP ? styles.noseBottom : styles.noseRight
              }
            />
          </div>
        )}
      <Measure bounds onResize={onResize}>
        {({ measureRef }) => (
          <div
            ref={measureRef}
            className={styles.valueWrapper}
            role="button"
            onMouseDown={activateStepper}
          >
            <div onMouseDown={activateStepper} role="textbox">
              <span
                className={stepperActive ? styles.valueActive : styles.valueInactive}
                style={inputFieldStyle}
              >
                {value && localizedValueWithinBoundaries()}
              </span>
            </div>
          </div>
        )}
      </Measure>
      {stepperActive &&
        (stepperPosition === ValueStepperPosition.BOTTOM ||
          stepperPosition === ValueStepperPosition.RIGHT) && (
          <div
            className={styles.stepper}
            style={{ transform: `translate(${translateX()},${translateY()})` }}
          >
            <div
              className={
                stepperPosition === ValueStepperPosition.BOTTOM ? styles.noseTop : styles.noseLeft
              }
            />
            {valueStepper}
          </div>
        )}
    </div>
  );
};
ValueStepper.displayName = 'ValueStepper';

export default ValueStepper;
