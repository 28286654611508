import { accentCorrection } from './accentCorrection';
import * as React from 'react';

import { BASE, type MRow, type MUnderOver as MUnderOverContent } from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { Bucket } from '../Bucket';
import { enrich } from '../helpers';

import { type MUnderOverProps } from './types';

import styles from './UnderOver.scss';

/**
 * MUnderOver: The MathML <munderover> element is used to attach an accent or a limit
 * over an expression. It uses the following syntax:
 * <munderover>
 *    base
 *    underscript
 *    overscript
 * </munderover>
 */
export const MUnderOver: React.FC<MUnderOverProps & MUnderOverContent> = ({
  accent,
  children: [base, underscript, overscript],
  computedStyles: { backgroundColor },
  baseHeight,
  underscriptHeight,
  overscriptHeight = 0,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <span className={styles.munderover}>
      <div className={styles.munderoverOverscript}>
        <Bucket height={overscriptHeight} stretched>
          {overscript}
        </Bucket>
      </div>
      <div
        style={{
          marginTop: accentCorrection(accent, overscriptHeight) || undefined,
        }}
      >
        <Bucket height={baseHeight}>{base}</Bucket>
      </div>
      <div className={styles.munderoverUnderscript}>
        <Bucket height={underscriptHeight} stretched>
          {underscript}
        </Bucket>
      </div>
    </span>
  </BackgroundColor>
);

MUnderOver.displayName = 'MUnderOver';

export const enrichMUnderOver: EnrichNestedMathContent<MUnderOverContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const {
    enrichedContent: base,
    height: baseHeight,
    refLine: baseRefLine,
  } = mathContentEnricher(formulaStyles, content.base, [...path, BASE]);
  const { enrichedContent: overscript, height: overscriptHeight } = mathContentEnricher(
    formulaStyles,
    content.overscript,
    [...path, 'overscript']
  );
  const { enrichedContent: underscript, height: underscriptHeight } = mathContentEnricher(
    formulaStyles,
    content.underscript,
    [...path, 'underscript']
  );

  const height =
    baseHeight +
    overscriptHeight +
    underscriptHeight +
    accentCorrection(content.accent, overscriptHeight);
  const refLine = baseRefLine + underscriptHeight;

  return enrich(
    {
      ...content,
      base,
      overscript,
      underscript,
      overscriptHeight,
      underscriptHeight,
    },
    { height, refLine },
    formulaStyles
  );
};
