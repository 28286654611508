import React from 'react';
import styled, { css } from 'styled-components';
import dimensions from '../../sass/dimensions';
import colors from '../../sass/colors';
import { VerticalMenuItem } from './VerticalMenuItem';

export type MenuProps = {
  limitHeight?: boolean; // true limits the height of the list to 7,5 items
  separator?: boolean; // true create menu with separator
  className?: string;
};

const List = styled.div<Pick<MenuProps, 'separator'>>`
  display: table;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  border-radius: 5px;
  ${({ separator }) =>
    separator &&
    css`
      ${VerticalMenuItem} {
        border-bottom: ${dimensions.borderWidthS} solid ${colors.cElementBorder};
      }

      ${VerticalMenuItem}:last-of-type {
        border: none;
      }
    `}
`;

const BaseVerticalMenu: React.FC<MenuProps> = ({ separator, children, className }) => (
  <div className={className} role="menu">
    <List separator={separator}>{children}</List>
  </div>
);

export const VerticalMenu = styled(BaseVerticalMenu)`
  width: 100%;
  overflow-y: auto;
  ${({ limitHeight }) => limitHeight && `max-height: ${dimensions.menuMaxHeight};`}
`;
