import { ContentPathHandler } from '@bettermarks/umc-kotlin';
import { getPlainTextResponse } from './parseHelpers';
import { Request } from '../bm-api/bmApi';
import { ContentManagerRoutes } from './constants';

export interface FetchEMParams {
  locale: string;
  /**
   * This is used for setting an alternative content server (mostly for local generation).
   * When it is undefined, it defaults to the content server.
   */
  staticServerUrl: string;
  encrypt: boolean;
  test?: boolean;
}

export interface FetchFEMParams extends FetchEMParams {
  femId: string;
}

export const fetchFEM = async (
  { locale, femId, staticServerUrl, encrypt, test }: FetchFEMParams,
  requestApi = Request
): Promise<string> => {
  const path = ContentPathHandler.pathFEMFile(femId, locale, '.xml', test);
  // For static resources, we can `omit` credentials
  const response = await requestApi.get(
    ContentManagerRoutes.getEM({ path, staticServerUrl, encrypt }),
    'omit'
  );
  return getPlainTextResponse(await response.text());
};

export interface FetchKEMParams extends FetchEMParams {
  /**
   * skillId or path to the xml file in the local assets for test mode
   */
  kemId: string;
}

export const fetchKEM = async ({
  locale,
  kemId,
  staticServerUrl,
  test,
  encrypt,
}: FetchKEMParams): Promise<string> => {
  const path = ContentPathHandler.pathKEMFile(kemId, locale, '.xml', test);
  // For static resources, we can `omit` credentials
  const response = await Request.get(
    ContentManagerRoutes.getEM({ path, staticServerUrl, encrypt }),
    'omit'
  );
  return getPlainTextResponse(await response.text());
};

export function isKEMParams(params: FetchKEMParams | FetchFEMParams): params is FetchKEMParams {
  return (params as FetchKEMParams).kemId !== undefined;
}

export function isFEMParams(params: FetchKEMParams | FetchFEMParams): params is FetchFEMParams {
  return (params as FetchFEMParams).femId !== undefined;
}
