import * as React from 'react';
import { styleName } from '../../../components/_utils';
import styles from './BasicShape.scss';
import { type CircleShapeProps } from './types';
import { dimensionsCircle } from './helper';
/**
 * The `CircleShape` component renders a circle shape
 */

export const CircleShape: React.FC<CircleShapeProps> = ({
  color,
  gradientType,
  label,
  scalefactor,
  ...props
}) => {
  const { radius, width, height, cx, cy, borderWidth } = dimensionsCircle(
    scalefactor,
    props.borderWidth,
    props.padding
  );
  const className = styleName(color, gradientType);

  /** The Circle is of radius r and stroke width is used to draw the border
  outside the circle*/
  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      className={styles[className]}
    >
      <circle cx={cx} cy={cy} r={radius} strokeWidth={borderWidth} />
      {label && (
        <text x="50%" textAnchor="middle" y="67%" className={styles.label}>
          {label}
        </text>
      )}
    </svg>
  );
};

CircleShape.displayName = 'CircleShape';
