import { SVG_HEIGHT, SVG_SQUARE_SIDE, SVG_RECTANGLE_WIDTH, SVG_CIRCLE_RADIUS } from './constants';
import { type ShapePadding } from './types';

export const dimensionsRectangle = (scalefactor: number, square?: boolean) => {
  const svgWidth = (square ? SVG_SQUARE_SIDE : SVG_RECTANGLE_WIDTH) * scalefactor;
  const svgHeight = SVG_HEIGHT * scalefactor;
  const widthShape = svgWidth - scalefactor;
  const heightShape = svgHeight - scalefactor;
  const leftPadding = scalefactor * 0.5;
  const rightPadding = leftPadding;
  const topPadding = scalefactor * 0.5;
  const bottomPadding = scalefactor * 0.5;
  const heightWithPadding = topPadding + heightShape + bottomPadding;
  const widthWithPadding = leftPadding + widthShape + rightPadding;
  return {
    width: widthShape,
    height: heightShape,
    leftPadding: leftPadding,
    topPadding: topPadding,
    heightWithPadding: heightWithPadding,
    widthWithPadding: widthWithPadding,
  };
};
export const dimensionsCircle = (
  scaleFactor: number,
  borderWidth = scaleFactor,
  { left = 0, right = 0, top = 0, bottom = 0 }: Partial<ShapePadding> = {}
) => {
  // when drawing an svg circle with the strokeWidth being the border width
  // the border grows an equal amount towards and away from the center
  // to be able to calculate based on the resulting visual shape,
  // we need to subtract half the borderWidth from the radius
  const borderPadding = borderWidth / 2;
  const radius = SVG_CIRCLE_RADIUS * scaleFactor - borderPadding;

  // The same amount needs to be added to the given padding,
  // so that the border is not clipped
  const padding: ShapePadding = {
    left: borderPadding + left,
    right: borderPadding + right,
    top: borderPadding + top,
    bottom: borderPadding + bottom,
  };

  const width = padding.left + radius * 2 + padding.right;
  const height = padding.top + radius * 2 + padding.bottom;

  const cx = padding.left + radius;
  const cy = padding.top + radius;
  return { width, height, cx, cy, radius, borderWidth };
};
