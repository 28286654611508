import { curry } from 'lodash/fp';
import { isEmpty } from 'lodash';

import { _GIZMO_ACTION, type GizmoAction } from '../redux';
import { type AnyAction } from '../redux/types';
import { wrapShortcuts } from '../keyboard';
import { type KeyboardShortcut } from './types';

export type ShortcutsMap = Record<string, AnyAction>;
export type GizmoShortcutsMap = Record<string, GizmoAction<any>>;
export type ShortcutRegistry = ReadonlyArray<ShortcutsMap>;

export const isGizmoShortcutsMap = (map: ShortcutsMap): map is GizmoShortcutsMap =>
  !isEmpty(map)
    ? Object.values(map)[0].type.includes(_GIZMO_ACTION) //
    : false;

export const serializeShortcut = (shortcut: KeyboardShortcut) => `${shortcut.key}`;

export const simpleShortcut = (c: string): string =>
  serializeShortcut({ key: c, shift: false, control: false, meta: false });

export const getAction = curry((shortcut: KeyboardShortcut, registry: ShortcutRegistry) => {
  for (let i = registry.length - 1; i >= 0; i--) {
    const action = registry[i][serializeShortcut(shortcut)];
    if (action) {
      return action;
    }
  }
});

/**
 * Add id of currently selected gizmo, to the actions in the shortcuts map,
 * if it is a GizmoShortcutsMap.
 *
 * @param {ShortcutsMap} shortcuts
 * @param {(string | undefined)} gizmoId
 * @returns {(ShortcutsMap | GizmoShortcutsMap)}
 */
const wrapIfGizmoShortcutsMap = (
  shortcuts: ShortcutsMap,
  gizmoId?: string
): ShortcutsMap | GizmoShortcutsMap =>
  isGizmoShortcutsMap(shortcuts) && gizmoId
    ? wrapShortcuts(shortcuts, gizmoId) // GizmoShortcutsMap
    : shortcuts; // ShortcutsMap

export const register = curry(
  (gizmoId: string | undefined, shortcuts: ShortcutsMap, registry: ShortcutRegistry) => [
    ...registry,
    wrapIfGizmoShortcutsMap(shortcuts, gizmoId),
  ]
);

export const unregister = curry(
  (gizmoId: string | undefined, shortcuts: ShortcutsMap, registry: ShortcutRegistry) =>
    registry.filter(
      (s) => JSON.stringify(s) !== JSON.stringify(wrapIfGizmoShortcutsMap(shortcuts, gizmoId))
    )
);
