import ISO6391, { type LanguageCode } from 'iso-639-1';
const LANG_LOCALE_SEPARATOR: '_' | '-' = '_';

function isValidLanguage(lang: string): lang is LanguageCode {
  return ISO6391.validate(lang);
}

export const getLanguageFromLocale = (locale: string, separator = LANG_LOCALE_SEPARATOR) => {
  if (!locale) return null; // this is only for enzyme tests
  const languagePart = locale.split(separator)[0];
  if (!isValidLanguage(languagePart)) return null;
  return languagePart;
};
