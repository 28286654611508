import * as React from 'react';
import { type Coords, type GeoLineDecoration } from '@bettermarks/gizmo-types';
import { Line } from '.';
import styles from './ReadingHelp.scss';

export type ReadingHelpProps = {
  borderRectId: string;
  decoration: GeoLineDecoration;
  id: string;
  matrix: number[];
  x: number;
  y: number;
  planePoint?: Coords;
  axesPoints?: Coords[];
};

export const ReadingHelp: React.FC<ReadingHelpProps> = ({
  borderRectId,
  decoration,
  id,
  matrix,
  x,
  y,
  planePoint,
  axesPoints,
}) => {
  return (
    <g className={styles.pointHelp}>
      {planePoint && axesPoints && axesPoints.length === 2 ? (
        /* 3D */
        <>
          <Line
            {...{ id: `${id}_l1`, matrix, borderRectId }}
            x1={x}
            y1={y}
            x2={planePoint.x}
            y2={planePoint.y}
            decoration={decoration}
          />
          <Line
            {...{ id: `${id}_l2`, matrix, borderRectId }}
            x1={axesPoints[0].x}
            y1={axesPoints[0].y}
            x2={planePoint.x}
            y2={planePoint.y}
            decoration={decoration}
          />
          <Line
            {...{ id: `${id}_l3`, matrix, borderRectId }}
            x1={axesPoints[1].x}
            y1={axesPoints[1].y}
            x2={planePoint.x}
            y2={planePoint.y}
            decoration={decoration}
          />
        </>
      ) : (
        /* 2D */
        <>
          <Line
            {...{ id: `${id}_x`, matrix, borderRectId }}
            x1={x}
            y1={y}
            x2={x}
            y2={0}
            decoration={decoration}
          />
          <Line
            {...{ id: `${id}_y`, matrix, borderRectId }}
            x1={x}
            y1={y}
            x2={0}
            y2={y}
            decoration={decoration}
          />
        </>
      )}
    </g>
  );
};

ReadingHelp.displayName = 'ReadingHelp';
