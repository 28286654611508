import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { type MouseOrTouch, type Slice } from '@bettermarks/gizmo-types';
import { persist } from './reducer/actions';
import { PieChartEditor } from './PieChartEditor';

export type PieChartCallbacks = {
  onMove?: (elem: HTMLDivElement | null) => (evt: MouseOrTouch) => void;
  onMoveEnd?: () => void;
  onMoveStart?: (item: number) => void;
  onPersist?: (slice: Slice[]) => void;
};

const mapDispatchToProps = (dispatch: Dispatch, { refid }: GizmoProps): PieChartCallbacks => ({
  onPersist: (slices: Slice[]) => dispatch(gizmoAction(persist(slices), refid)),
});

export const PieChartContainer = connectGizmo(mapDispatchToProps, PieChartEditor);
