import * as React from 'react';
import { isNil } from 'lodash';
import { type MProps } from './types';

import styles from './VerticalAlign.scss';
import { Bucket } from './Bucket';

type VerticalAlignChild = React.ReactElement<MProps>;
type VerticalAlignProps = {
  yOffset?: number;
  children: VerticalAlignChild;
};

/**
 * This wraps a single child with an element to apply `verticalAlign` as element style.
 *
 * If `yOffset` is not passed or 0 this component is a noop.
 *
 * It only allows a single child because every child needs an individual (on no) alignment.
 *
 * Since inside Formula we are applying `verticalAlign`
 * on lots of different math elements and gizmos,
 * this component frees both the individual math components/ `PolymorphicGizmo`
 * and the components that do align their children from redundant implementations.
 *
 * @see VerticalAlignedRow
 */
export const VerticalAlign: React.FC<VerticalAlignProps> = ({ yOffset, children }) =>
  isNil(yOffset) || yOffset === 0 ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <span className={styles.verticalAlign} style={{ verticalAlign: yOffset }}>
      {children}
    </span>
  );

VerticalAlign.displayName = 'VerticalAlign';

/**
 * Wraps every element in `children` in a `VerticalAlign`,
 * using the value from `childrenAlignments` at the same index as `yOffset`.
 */
export const VerticalAlignedRow = (
  children: VerticalAlignChild[],
  childrenAlignments: ReadonlyArray<number>
): JSX.Element[] =>
  React.Children.map(children, (child: VerticalAlignChild, i) => (
    <VerticalAlign key={i} yOffset={childrenAlignments?.[i]}>
      {child}
    </VerticalAlign>
  ));

/**
 * Put chunks of an mrow, that should not break, into buckets
 * (+ some dirty stuff, so that around those buckets the browser wraps lines)
 * @param buckets
 * @param childrenAlignments
 * @constructor
 */
export const VerticalAlignedBuckets = (
  buckets: { elements: VerticalAlignChild[]; inline: boolean }[],
  childrenAlignments: ReadonlyArray<number>
): JSX.Element[] => {
  const bucketLengths = buckets.reduce(
    (acc: number[], b) => [...acc, acc[acc.length - 1] + b.elements.length],
    [0]
  );

  return buckets.map((bucket, i) => {
    return (
      <Bucket key={i} inline={bucket.inline}>
        {VerticalAlignedRow(
          bucket.elements,
          childrenAlignments.slice(bucketLengths[i], bucketLengths[i + 1])
        )}
      </Bucket>
    );
  });
};
