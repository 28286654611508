import * as React from 'react';
import { IntegralSign, ProductPi, SumSigma } from './components/images';
import { DEFAULT_FONT_SIZE } from '../../../gizmo-utils/measure';
import { type ComputedStyles } from '@bettermarks/gizmo-types';

export type MathOperatorSVGProps = {
  operator: string;
  computedStyles: ComputedStyles;
};

export enum SVG_OPERATORS {
  SUM_SIGMA = '∑',
  PRODUCT_PI = '∏',
  INTEGRAL_SIGN = '∫',
}

export const MathOperatorSVG: React.FC<MathOperatorSVGProps> = ({
  operator,
  computedStyles,
}): JSX.Element => {
  const { color, fontSize = DEFAULT_FONT_SIZE } = computedStyles;
  const [width, height] = [...Array(2)].map((_) => fontSize * 1.5);

  const styles = {
    width,
    height,
    color,
  };

  switch (operator) {
    case SVG_OPERATORS.SUM_SIGMA:
      return <SumSigma {...styles} />;
    case SVG_OPERATORS.PRODUCT_PI:
      return <ProductPi {...styles} />;
    case SVG_OPERATORS.INTEGRAL_SIGN:
      return <IntegralSign {...styles} />;
    default:
      return <div />;
  }
};
