import {
  type BallotBoxContent,
  type BallotBoxItem as Item,
  ContentColor,
  type FElement,
  type Importer,
  toBoolean,
  xmlText,
} from '@bettermarks/gizmo-types';

const DEFAULT_ITEM: Item = {
  color: ContentColor.BM_BLUE,
};

/**
 * Imports item tag with color and label if available
 * @param {FElement} xml
 * @returns Sector
 */
const importItem = (xml: FElement) =>
  xml.hasChild('color') ? xml.tagsToProps(xmlText, ['color'], ['label']) : DEFAULT_ITEM;

/**
 * imports the Ballot Box XML and parses it.
 * It contains an array of objects containing a color value and an optional label value.
 * @param preContent
 * @param xml
 */
export const importBallotBox: Importer = (preContent, xml): BallotBoxContent => {
  const mrow = xml.findChildTag('mrow');
  const config = mrow.findChildTag('configuration');

  // Default is true
  const showBackground = toBoolean(
    config.hasChild('showBackground') ? config.findChildTag('showBackground').text : 'true'
  );

  const set = mrow.findChildTag('set');

  const items = set.children.map(importItem);

  return {
    ...preContent,
    items,
    showBackground,
  };
};
