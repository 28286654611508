import * as React from 'react';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import { type GeoEditorMode } from '@bettermarks/gizmo-types';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import {
  colorAction,
  coloringReducer,
  deColorAction,
  hoverAction,
  initialState,
  outAction,
} from './coloringReducer';
import { type GeoProps } from '../../Geo';
import { colorFromMode, isColored, isColorizable } from './helpers';

export type GeoColoringProps = GeoProps & {
  mode: GeoEditorMode;
};

export const GeoColoring: React.FC<GeoColoringProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={coloringReducer}
    componentName={`Coloring:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { mode, onPersistLocalState, geoContentMap } = props;
      const selectColor = colorFromMode(mode);

      const toggleColor = (id: string) => (evt: React.MouseEvent<any>) => {
        if (isColorizable(id, geoContentMap)) {
          isColored(id, geoContentMap, selectColor)
            ? dispatch(deColorAction({ id, onPersistLocalState }))
            : dispatch(colorAction({ id, onPersistLocalState, selectColor }));
        }
      };
      const hover = (id: string) => (evt: React.MouseEvent<any>) => {
        if (isColorizable(id, geoContentMap)) {
          dispatch(hoverAction({ id, selectColor, gizmoId: props.uniqueId }));
        }
      };
      const out = (id: string) => (evt: React.MouseEvent<any>) => {
        if (isColorizable(id, geoContentMap)) {
          dispatch(outAction({ id, selectColor }));
        }
      };
      const geoContentProps = { ...props, ...state.persistProps };
      return (
        <GeoRenderer
          {...geoContentProps}
          onBezierClick={toggleColor}
          onBezierHover={hover}
          onBezierMouseOut={out}
          onLineClick={toggleColor}
          onLineHover={hover}
          onLineOut={out}
          onCircleClick={toggleColor}
          onCircleOver={hover}
          onCircleLeave={out}
        />
      );
    }}
  </WithLocalRedux>
);

GeoColoring.displayName = 'GeoColoring';
