import {
  ANNOTATION_XML,
  type Annotations,
  CONFIGURATION,
  type ContentReference,
  type FElement,
  type Importer,
  type ImporterContext,
  type MultipleChoiceSingleContent,
  SEMANTICS,
} from '@bettermarks/gizmo-types';
import { uniq } from 'lodash';

import {
  getElementsPerLine,
  getSelectedItem,
  parseSemanticChildren,
} from '../multiple-choice/importer';

export const importMultipleChoiceSingle: Importer<MultipleChoiceSingleContent> = (
  preContent,
  xml,
  context
) => {
  const result: MultipleChoiceSingleContent = {
    ...preContent,
    choices: [],
    configuration: {
      radioButtons: true,
      elementsPerLine: Number.MAX_VALUE,
      placeholder: { $id: 'defaultId' },
    },
    checkedIdx: -1,
  };
  const mrow = xml.findChildTag('mrow');
  const { options, placeholder, selection } = parseSemanticChildren(mrow);

  const config = mrow.findChildTag('configuration');
  result.configuration.elementsPerLine = getElementsPerLine(config);
  result.configuration.radioButtons = !config.hasChild('hideButtons');
  result.configuration.placeholder = placeholder;

  const choices = options
    .map(({ xml }) => context.importXML(xml))
    .map((ref) => {
      const { $id = '', xmlRefId = '' } = context.content.get(ref.$refid) as Annotations;
      return {
        contentRef: ref,
        choiceId: $id,
        visualRefId: xmlRefId,
      };
    });
  const { index: checkedIdx, severity } = getSelectedItem(
    selection,
    choices.map((c) => c.choiceId)
  );
  return severity
    ? {
        ...result,
        choices,
        checkedIdx,
        severity,
      }
    : {
        ...result,
        choices,
        checkedIdx,
      };
};

function setVisualContents(
  semanticsNodes: FElement[],
  context: ImporterContext,
  result: MultipleChoiceSingleContent
): MultipleChoiceSingleContent {
  const defaultSelection =
    result.configuration.stackLayout && result.configuration.stackLayout.defaultSelection;

  const visualRefIds = uniq([
    ...result.choices.map((c) => c.visualRefId),
    ...(defaultSelection ? [defaultSelection.visualRefId] : []),
  ]);
  const visualContents: { [key: string]: ContentReference } = visualRefIds.reduce((acc, id) => {
    const semantics = semanticsNodes.find(
      (s) => s.findChildTag(ANNOTATION_XML).firstChild.attribute('id', '') === id
    );
    return semantics
      ? {
          ...acc,
          [id]: context.importXML(semantics),
        }
      : acc;
  }, {});
  return {
    ...result,
    choices: result.choices.map((c) => ({
      ...c,
      visualContent: visualContents[c.visualRefId],
    })),
    ...(result.configuration.stackLayout && {
      configuration: {
        ...result.configuration,
        stackLayout: {
          ...result.configuration.stackLayout,
          ...(defaultSelection && {
            defaultSelection: {
              ...defaultSelection,
              visualContent: visualContents[defaultSelection.visualRefId],
            },
          }),
        },
      },
    }),
  };
}

export const importMultipleChoiceSingleStack: Importer<MultipleChoiceSingleContent> = (
  preContent,
  xml,
  context
) => {
  const mrow = xml.findChildTag('mrow');
  const result = context.invoke(importMultipleChoiceSingle, mrow.findChildTag(SEMANTICS));
  result.$renderStyle = preContent.$renderStyle;

  /**
   * now spice up mc-content with stack-layout
   */
  const config = mrow.findChildTag(CONFIGURATION);

  result.configuration.stackLayout = {
    $id: preContent.$id ? preContent.$id : '',
    defaultSelection: {
      visualRefId: config.findChildTag('defaultSelection').text,
      visualContent: {
        $refid: '',
      },
    },
  };

  // prepare visualization contents
  const set = mrow.findChildTag('set');
  const semantics: FElement[] = set.getChildrenByTagName(SEMANTICS);

  return setVisualContents(semantics, context, result);
};
