import { ContentManagerRoutes } from './constants';
import { Request } from '../bm-api';
import { getPlainTextResponse } from './parseHelpers';
import { sanitizeURL } from './exerciseSeries';
import type { BackendSeries, LoadSeriesErrorResponse } from './types';

export const fetchExercise = async (
  staticServerUrl: string,
  fileName: string,
  localExercise = false
): Promise<string> => {
  // For static resources, we can `omit` credentials
  // eslint-disable-next-line @typescript-eslint/ban-types
  let getExerciseFunc: Function;
  if (!localExercise) {
    getExerciseFunc = ContentManagerRoutes.getExercise;
  } else {
    getExerciseFunc = ContentManagerRoutes.getLocalExercise;
  }

  const response = await Request.get(
    getExerciseFunc({
      staticServerUrl,
      path: sanitizeURL(fileName),
    }),
    'omit'
  );
  return getPlainTextResponse(await response.text());
};

export function responseIsLoadSeriesError(
  responseJson: BackendSeries | LoadSeriesErrorResponse
): responseJson is LoadSeriesErrorResponse {
  return (responseJson as LoadSeriesErrorResponse)?.error !== undefined;
}
