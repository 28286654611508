import { concat, isNil, omit, pickBy, uniq, without } from 'lodash';
import {
  type GeoContentPersistProps,
  type GeoObjectPersistType,
  Hover,
  type IdCoords,
  LABEL,
  type LabelObject,
  type LineObject,
  type LineType,
  RAY,
  SEGMENT,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import {
  getLabelId,
  getLineId,
  getRayId,
  getSegmentId,
  getStraightlineId,
  getVectorId,
} from '../../helpers';
import { addReferencedBy } from '@bettermarks/importers';

export const removePoint = (
  persistProps: GeoContentPersistProps,
  id: string
): GeoContentPersistProps => ({
  ...persistProps,
  geoContentMap: omit(persistProps.geoContentMap, [id]),
  points: persistProps.points.filter((pId) => pId !== id),
});

export const replaceReferenceIDs = (
  persistProps: GeoContentPersistProps,
  oldId: string,
  newId: string,
  endMove = true
): GeoContentPersistProps => {
  let resultProps = persistProps;
  let resultMap = resultProps.geoContentMap;

  // handle the referencedBy objects
  const refs = resultMap[oldId].referencedBy;

  refs.forEach((id) => {
    const contentType = resultMap[id].type as GeoObjectPersistType;

    switch (contentType) {
      case LABEL:
        if (newId in persistProps.geoContentMap && persistProps.geoContentMap[newId].notLabelable) {
          break;
        }

        const labelId: string = getLabelId(newId);
        let label = resultMap[id];
        // check whether there is already a label in the contentMap
        if (labelId in resultMap) {
          label = { ...resultMap[labelId], ...label };
        } else {
          // add the new label to the label list
          resultProps = {
            ...resultProps,
            labels: [...resultProps.labels, labelId],
          };
        }

        resultMap = omit(
          {
            ...resultMap,
            [labelId]: {
              ...label,
              refid: newId,
              referringTo: concat(without(resultMap[id].referringTo, oldId), newId),
            },
          },
          id
        );
        // remove the old label from the label list
        resultProps = {
          ...resultProps,
          labels: resultProps.labels.filter((i) => i !== id),
        };
        break;
      case RAY:
      case SEGMENT:
      case STRAIGHTLINE:
      case VECTOR:
        const line = resultMap[id] as LineObject;
        const lineType: LineType = line.type;
        const p1Id = line.p1Id === oldId ? newId : line.p1Id;
        const p2Id = line.p2Id === oldId ? newId : line.p2Id;
        const newLineId = getLineId(p1Id, p2Id, lineType);
        let newLineLabel;
        let oldLineLabelId = '';
        line.referencedBy.map((k) => {
          resultMap = {
            ...resultMap,
            [k]: {
              ...resultMap[k],
              referringTo: concat(without(resultMap[k].referringTo, id), newLineId),
            },
          };

          if (resultMap[k].type === LABEL) {
            oldLineLabelId = k;
            newLineLabel = resultMap[k] as LabelObject;
            newLineLabel = {
              ...newLineLabel,
              refid: newLineId,
            };
          }
        });

        // Old replacement: const referringTo = concat(without(line.referringTo, oldId), newId);
        // We need to make sure we don't change the order of the points when replacing the old ID.
        // Otherwise the start point of the line might be swapped with the end point, which will ruin
        // relative positioning of labels.
        const referringTo = line.referringTo.slice();
        const oldIdIndex = line.referringTo.indexOf(oldId);
        if (oldIdIndex !== -1) {
          referringTo[oldIdIndex] = newId;
        }

        resultMap = omit(
          {
            ...resultMap,
            [newLineId]: pickBy(
              {
                ...line,
                p1Id,
                p2Id,
                referringTo,
                referencedBy: [],
                hover: endMove ? undefined : Hover.PREVIEW,
              },
              (v) => !isNil(v)
            ) as LineObject,
            [p1Id]: {
              ...resultMap[p1Id],
              referencedBy: [],
            },
            [p2Id]: {
              ...resultMap[p2Id],
              referencedBy: [],
            },
          },
          id
        );

        if (!isNil(newLineLabel)) {
          newLineLabel = newLineLabel as LabelObject;
          const newLineId = getLabelId(newLineLabel.refid as string);
          resultMap = omit(
            {
              ...resultMap,
              [getLabelId(newLineLabel.refid as string)]: newLineLabel,
            },
            oldLineLabelId
          );

          // add the new label to + remove the old label from the label list
          resultProps = {
            ...resultProps,
            labels: [...resultProps.labels, newLineId].filter((i) => i !== oldLineLabelId),
          };
        }

        resultProps = {
          ...resultProps,
          // add the new line to the + remove the old line from the corresponding lines list
          [lineType]: uniq([...resultProps[lineType], newLineId]).filter((i) => i !== id),
        };
        break;
      default:
    }
  });

  return {
    ...resultProps,
    geoContentMap: resultMap,
  };
};

export const replacePoint = (
  geoContentPersistProps: GeoContentPersistProps,
  oldId: string,
  newPoint: IdCoords,
  endMove = true
): GeoContentPersistProps => {
  let persistProps = { ...geoContentPersistProps };
  const newPointAlreadyExisting = newPoint.id in persistProps.geoContentMap;

  if (oldId !== newPoint.id) {
    if (!newPointAlreadyExisting) {
      // add the new point to the points list
      persistProps = {
        ...persistProps,
        points: [...persistProps.points, newPoint.id],
      };
    }

    persistProps = {
      ...replaceReferenceIDs(persistProps, oldId, newPoint.id, endMove),
      // remove the old point from the points list
      points: persistProps.points.filter((id) => id !== oldId),
    };
  }

  let newContentMap = persistProps.geoContentMap;
  // add a new point to the contentMap
  newContentMap = {
    ...newContentMap,
    [newPoint.id]: {
      ...newContentMap[oldId],
      coords: newPoint.coords,
      invisible: undefined,
      referencedBy: [],
    },
  };

  if (!newPointAlreadyExisting) {
    newContentMap = omit(newContentMap, oldId);
  }

  return {
    ...persistProps,
    geoContentMap: addReferencedBy(newContentMap),
  };
};

export const mergeTwoPoints = (
  persistProps: GeoContentPersistProps,
  moveFromPointId: string,
  moveToPointId: string
): GeoContentPersistProps => {
  const resultProps = replaceReferenceIDs(persistProps, moveFromPointId, moveToPointId, true);
  const resultContentMap = { ...resultProps.geoContentMap };
  const movingPointSuperior = resultContentMap[moveToPointId].addedByUser;
  const fromPointLabelId = getLabelId(moveFromPointId);
  const toPointLabelId = getLabelId(moveToPointId);

  // handle the case where one reference point of a line is moved to the other reference point
  const samePointRayId = getRayId(moveToPointId, moveToPointId);
  const samePointSegmentId = getSegmentId(moveToPointId, moveToPointId);
  const samePointStraighlineId = getStraightlineId(moveToPointId, moveToPointId);
  const samePointVectorId = getVectorId(moveToPointId, moveToPointId);

  const toBeRemovedLabelId = movingPointSuperior
    ? fromPointLabelId in resultContentMap
      ? toPointLabelId
      : ''
    : fromPointLabelId;

  const newContentMap = omit(
    {
      ...resultContentMap,
      [moveToPointId]: movingPointSuperior
        ? // the point the user is moving is superior
          {
            ...resultContentMap[moveToPointId],
            ...resultContentMap[moveFromPointId],
            referencedBy: [],
          }
        : // the point the user did not add is superior
          {
            ...resultContentMap[moveFromPointId],
            ...resultContentMap[moveToPointId],
            addedByUser: undefined,
            referencedBy: [],
          },
    },
    [
      moveFromPointId,
      toBeRemovedLabelId,
      samePointRayId,
      samePointSegmentId,
      samePointStraighlineId,
      samePointVectorId,
    ]
  );

  return {
    ...resultProps,
    geoContentMap: addReferencedBy(newContentMap),
    points: resultProps.points.filter((id) => id !== moveFromPointId),
    labels: resultProps.labels.filter((id) => id !== toBeRemovedLabelId),
    rays: resultProps.rays.filter((id) => id !== samePointRayId),
    segments: resultProps.segments.filter((id) => id !== samePointSegmentId),
    straightlines: resultProps.straightlines.filter((id) => id !== samePointStraighlineId),
    vectors: resultProps.vectors.filter((id) => id !== samePointVectorId),
  };
};
