import { createAction } from 'redux-actions';

import { enrichMetaCreator } from '../../../../gizmo-utils/redux/gizmoActions';
import { type ShouldEnrich } from '@bettermarks/gizmo-types';
import { timedMetaCreator } from '../../../store/helper';
import { type TimedActionMeta } from '../../../../types';

export const HIDE_STEP_VALIDATION_LOADER = 'HIDE_STEP_VALIDATION_LOADER' as const;
export const hideStepValidationLoader = createAction(HIDE_STEP_VALIDATION_LOADER);

export const NEXT_STEP = 'NEXT_STEP' as const;
export const nextStep = createAction<TimedActionMeta & ShouldEnrich>(NEXT_STEP, undefined, () => ({
  ...timedMetaCreator(),
  ...enrichMetaCreator(),
}));

export const SAVE_INPUT = 'SAVE_INPUT' as const;
export const saveInput = createAction(SAVE_INPUT);

export const SHOW_SOLUTION = 'SHOW_SOLUTION' as const;
export const showSolution = createAction(SHOW_SOLUTION);

export const SHOW_HINT = 'SHOW_HINT' as const;
export const showHint = createAction(SHOW_HINT);

export const SWITCH_EXERCISE_RESUMABLE = 'SWITCH_EXERCISE_RESUMABLE' as const;
export const switchExerciseResumable = createAction<number | undefined>(SWITCH_EXERCISE_RESUMABLE);

export const SUBMIT = 'SUBMIT' as const;
export const submit = createAction(SUBMIT);

export const UPDATE_FILLED_STATUS = 'UPDATE_FILLED_STATUS' as const;
export const updateFilledStatus = createAction(UPDATE_FILLED_STATUS);

export interface ValidationProgressPayload {
  handIn?: boolean;
  disableInput?: boolean;
  stepValidationLoaded?: boolean;
}

export const VALIDATION_PROGRESS = 'VALIDATION_PROGRESS' as const;
export const validationProgress = createAction<ValidationProgressPayload>(VALIDATION_PROGRESS);

// Gets message as reason for failing validation
export const VALIDATION_FAILED = 'VALIDATION_FAILED' as const;
export const validationFailed = createAction<string>(VALIDATION_FAILED);

export const CLOSE_BETTY_SECTION = 'CLOSE_BETTY_SECTION' as const;
export const closeBettySection = createAction(CLOSE_BETTY_SECTION);
