import { colors } from '@seriesplayer/common-ui';
import * as React from 'react';
import styled from 'styled-components';
import { type PenColor, penColors } from '../types';
import Slider from './Slider';
import { ToolColumn } from './Toolbar';
import { ToolbarLabel } from './ToolbarLabel';
import { ColorSelection } from './ColorSelection';
import { useWhiteboardContext } from '../whiteboard-context';
import { SECOND_SIDEBAR_WIDTH } from './constants';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  text-align: center;
`;

export default function PenSidebar(): JSX.Element {
  const { state, dispatch } = useWhiteboardContext();
  const penWidth = state.toolConfig.penThickness;
  const selectedColor = state.toolConfig.color;
  const canvasScale = state.scaleConfig.currentScale;
  const [t] = useTranslation();

  const changeColor = (color: PenColor) => {
    dispatch({ type: 'setPenColor', color });
  };

  return (
    <ToolColumn width={SECOND_SIDEBAR_WIDTH}>
      <ToolbarLabel
        style={{ marginBottom: '12px', marginTop: 'auto' }}
        data-testid="colorSelectionLabel"
      >
        {t('seriesplayer:dialog.whiteboard.penTool.width')}
      </ToolbarLabel>
      <Container title={t('seriesplayer:dialog.whiteboard.penTool.setThickness')}>
        <Slider
          min={2}
          max={32}
          step={2}
          value={penWidth}
          onChange={(value) => dispatch({ type: 'setThickness', tool: 'pen', thickness: value })}
          penPreviewScale={canvasScale}
        />
      </Container>
      <ToolbarLabel style={{ marginTop: '16px' }} data-testid="penWidthSliderLabel">
        {t('seriesplayer:dialog.whiteboard.penTool.color')}
      </ToolbarLabel>
      <Container title={t('seriesplayer:dialog.whiteboard.penTool.selectColor')}>
        {penColors.map((color, i, row) => {
          return (
            <ColorSelection
              color={colors[color]}
              isSelected={selectedColor === color}
              onClick={() => changeColor(color)}
              role="radio"
              tabIndex={0}
              key={color}
            />
          );
        })}
      </Container>
    </ToolColumn>
  );
}
