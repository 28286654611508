import { type ApplicationState } from '../../../types';
import { idbCon, tableName } from './store';
import { type ReduxStateFlowSeries } from './types';
import { type ActionWithPayload } from '../middleware';

export class ReduxStateFlowService {
  get connection() {
    return idbCon;
  }

  update = async (id: string, state: ApplicationState, action: ActionWithPayload) => {
    try {
      await this.addSeries(id, state, [action]);
    } catch (err) {
      await this.updateSeries(id, action);
    }
  };

  getSeries = async (id: string) =>
    this.connection.select<ReduxStateFlowSeries>({
      from: tableName,
      where: {
        id: id,
      },
    });

  addSeries = async (id: string, preloadedState: ApplicationState, actions: ActionWithPayload[]) =>
    this.connection.insert({
      into: tableName,
      values: [{ id, preloadedState, actions, timestamp: new Date() }],
    });

  removeSeriesById = async (id: string) =>
    this.connection.remove({
      from: tableName,
      where: {
        id: id,
      },
    });

  removeSeriesOlderThan = async (days: number) => {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - days);

    return this.connection.remove({
      from: tableName,
      where: {
        timestamp: {
          '<=': expireDate,
        },
      },
    });
  };

  updateSeries = async (id: string, action: ActionWithPayload) =>
    this.connection.update({
      in: tableName,
      set: {
        actions: {
          '{push}': action,
        },
      },
      where: {
        id: id,
      },
    });
}
