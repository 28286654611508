import { connect } from 'react-redux';
import { type Action } from 'redux-actions';
import { compact, isEqual } from 'lodash';

import { type ReadonlyDict } from '../../../../../../gizmo-utils/append-only';
import { type Dispatch, gizmoAction } from '../../../../../../gizmo-utils/redux';
import {
  InputToolTypes,
  type KeyboardTool,
  ShouldEnrichKind,
  ToolbarElements,
} from '@bettermarks/gizmo-types';
import {
  backwardDelete,
  cursorLeft,
  cursorRight,
  enterAbs,
  enterBracket,
  enterChar,
  enterFrac,
  enterIdentifier,
  enterNumber,
  enterOperator,
  enterRoot,
  enterSqrt,
  enterSup,
  type FormulaActionPayload,
  toggleKeyboardPage,
} from '../../../../../../gizmos/formula/Formula/actions';
import { ApplicationState } from '../../../../../../types';

import { Keyboard, type KeyboardDispatchProps, type KeyboardStateProps } from './Keyboard';
import { KeyCode } from './keyCodes';
import { getCurrentTool } from '../../helpers';
import { toggleToolbarDrawer } from '../../actions';

/**
 * KeyCodeToAction maps from a keyboard key (identified by a keycode) to a tuple of:
 * [the action to be dispatched, whether the action should be skipped by undo (true = skipUndo)]
 */
type KeyCodeToAction = ReadonlyDict<[Action<FormulaActionPayload>, boolean]>;

export const keyCodeToAction: KeyCodeToAction = {
  [KeyCode.DecimalPoint]: [enterNumber('.'), false],
  [KeyCode.Num0]: [enterNumber('0'), false],
  [KeyCode.Num1]: [enterNumber('1'), false],
  [KeyCode.Num2]: [enterNumber('2'), false],
  [KeyCode.Num3]: [enterNumber('3'), false],
  [KeyCode.Num4]: [enterNumber('4'), false],
  [KeyCode.Num5]: [enterNumber('5'), false],
  [KeyCode.Num6]: [enterNumber('6'), false],
  [KeyCode.Num7]: [enterNumber('7'), false],
  [KeyCode.Num8]: [enterNumber('8'), false],
  [KeyCode.Num9]: [enterNumber('9'), false],
  [KeyCode.A]: [enterIdentifier('a'), false],
  [KeyCode.B]: [enterIdentifier('b'), false],
  [KeyCode.C]: [enterIdentifier('c'), false],
  [KeyCode.H]: [enterIdentifier('h'), false],
  [KeyCode.M]: [enterIdentifier('m'), false],
  [KeyCode.N]: [enterIdentifier('n'), false],
  [KeyCode.P]: [enterIdentifier('p'), false],
  [KeyCode.Q]: [enterIdentifier('q'), false],
  [KeyCode.R]: [enterIdentifier('r'), false],
  [KeyCode.S]: [enterIdentifier('s'), false],
  [KeyCode.T]: [enterIdentifier('t'), false],
  [KeyCode.U]: [enterIdentifier('u'), false],
  [KeyCode.V]: [enterIdentifier('v'), false],
  [KeyCode.X]: [enterIdentifier('x'), false],
  [KeyCode.Y]: [enterIdentifier('y'), false],
  [KeyCode.Z]: [enterIdentifier('z'), false],
  [KeyCode.Pi]: [enterChar('π'), false],
  [KeyCode.Absolute]: [enterAbs(), false],
  [KeyCode.Add]: [enterOperator('+'), false],
  [KeyCode.Exponent]: [enterSup(), false],
  [KeyCode.Subtract]: [enterOperator('-'), false],
  [KeyCode.Multiply]: [enterOperator('*'), false],
  [KeyCode.Divide]: [enterOperator('/'), false],
  [KeyCode.CaretLeft]: [cursorLeft(), true],
  [KeyCode.CaretRight]: [cursorRight(), true],
  [KeyCode.Delete]: [backwardDelete(), false],
  [KeyCode.BracketLeft]: [enterBracket('('), false],
  [KeyCode.BracketRight]: [enterBracket(')'), false],
  [KeyCode.Fraction]: [enterFrac(), false],
  [KeyCode.Sqrt]: [enterSqrt(), false],
  [KeyCode.Root]: [enterRoot(), false],
  [KeyCode.Equals]: [enterOperator('='), false],
};

export const createOnKeyGizmoAction = ($refid: string) => (key: string) => {
  // Quick fix - if this happens with other keys as well, we should think of a more general solution
  const keyAlias = key === 'Backspace' || key === 'Delete' ? KeyCode.Delete : key;
  if (key === KeyCode.Toggle) {
    return toggleToolbarDrawer({ drawerName: ToolbarElements.keyboard, toggledByUser: true });
  } else {
    const actionTuple = keyCodeToAction[keyAlias];
    if (actionTuple) {
      const [action, skipUndo] = actionTuple;
      return gizmoAction(action, $refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        ...(skipUndo && { skipUndo }),
      });
    }
  }
};

export const mapDispatchToProps = (dispatch: Dispatch): KeyboardDispatchProps => ({
  onKey: ($refid: string) => (key: string) => {
    if (key === KeyCode.Toggle) {
      dispatch(toggleToolbarDrawer({ drawerName: ToolbarElements.keyboard, toggledByUser: true }));
    } else {
      const actionTuple = keyCodeToAction[key];
      if (actionTuple) {
        const [action, skipUndo] = actionTuple;
        {
          dispatch(
            gizmoAction(action, $refid, {
              shouldEnrich: ShouldEnrichKind.justEnrich,
              ...(skipUndo && { skipUndo }),
            })
          );
        }
      }
    }
  },
  onTogglePage: ($refid: string) => (index: number) =>
    dispatch(gizmoAction(toggleKeyboardPage(index), $refid, { skipUndo: true })),
});

export const operatorsFromTool = ({ layout }: KeyboardTool) =>
  compact([
    ...(layout.operators ? [KeyCode.Add, KeyCode.Multiply, KeyCode.Subtract, KeyCode.Divide] : []),
    ...(layout.brackets ? [KeyCode.BracketLeft, KeyCode.BracketRight] : []),
    layout.absolute && KeyCode.Absolute,
    layout.fraction && KeyCode.Fraction,
    layout.exponent && KeyCode.Exponent,
    layout.pi && KeyCode.Pi,
    layout.sqrt && KeyCode.Sqrt,
    layout.root && KeyCode.Root,
    layout.equals && KeyCode.Equals,
  ]);

export function getKeyboardToolState(tool: KeyboardTool): Partial<KeyboardStateProps> | undefined {
  if (tool && tool.type === InputToolTypes.keyboard) {
    return {
      hasDecimalPoint: tool.layout.decimal_point,
      operators: operatorsFromTool(tool),
      hasNumbers: tool.layout.numbers,
      hasLetters: tool.layout.letters,
      selectedPage: tool.selectedPage,
    };
  }
}

const mapStateToProps = (
  state: ApplicationState,
  _: any,
  $refid = ApplicationState.toSelectedRefId(state).get(state),
  tool = getCurrentTool(state) as KeyboardTool
): KeyboardStateProps => ({
  availableWidth: state.runtimeState.availableWidth,
  operators: [],
  ...getKeyboardToolState(tool),
  gizmoId: $refid,
});

/**
 * KeyboardTool: virtual keyboard to be placed inside a drawer in the toolbar. Used to edit
 * formulas.
 */
export const KeyboardContainer = connect(mapStateToProps, mapDispatchToProps, undefined, {
  areStatePropsEqual: isEqual,
})(Keyboard);
