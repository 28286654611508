import * as React from 'react';
import { type MProps } from '../types';

import styles from '../MathML.scss';
import { ExcludedFromTabNavigationLink } from '../../../../../components/ExcludedFromTabNavigation';

/**
 * props
 */
export interface FEMLinkProps extends MProps {
  femId: string;
  hideHelpTools?: boolean;
  /** Check out FEMs_and_the_Whiteboard_Mode.md */
  onClickFEM?: (id: string) => void;
}

/**
 * The `FEMLink` displays links to FEMs (FreeExplanationModules, i.e. glossary terms) in exercises.
 *
 * This is only a dummy implementation pointing to the flash FEM-viewer.
 */
export const FEMLink: React.FC<FEMLinkProps> = ({
  children,
  computedStyles,
  femId,
  hideHelpTools,
  onClickFEM,
}) => {
  if (hideHelpTools) {
    return <>{children}</>;
  }
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClickFEM && onClickFEM(femId);
  };

  const preventDefault = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  return (
    <ExcludedFromTabNavigationLink
      href=""
      role="link"
      data-cy={`fem-link-${femId}`}
      className={styles.femLink}
      style={computedStyles}
      onClick={onClick}
      onContextMenu={preventDefault} // no action on right click
      onAuxClick={preventDefault} // no action on middle click
    >
      {children}
    </ExcludedFromTabNavigationLink>
  );
};

FEMLink.displayName = 'FEMLink';
