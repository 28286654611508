import {
  ANNOTATION_XML,
  type ContentReference,
  type FElement,
  type ImporterContext,
  SEMANTICS,
  $MROW,
} from '@bettermarks/gizmo-types';
import { compose, filter, map } from 'lodash/fp';

const skipMrow = (xml: FElement): FElement => (xml.localName === $MROW ? xml.firstChild : xml);
const isSemanticsNode = (xml: FElement): boolean => xml.localName === SEMANTICS;

const onlyGizmoNodes = compose([filter(isSemanticsNode), map(skipMrow)]);

export const importChildren = (mrow: FElement, context: ImporterContext): ContentReference[] =>
  compose([map((x: FElement) => context.importXML(x)), onlyGizmoNodes])(mrow.children);

export const filterForChildGizmo = (id: string, mrow: FElement): FElement[] =>
  compose([
    filter((x: FElement) => x.findChildTag(ANNOTATION_XML).firstChild.attribute('id') === id),
    onlyGizmoNodes,
  ])(mrow.children);
