import { isNil, without } from 'lodash/fp';
import anime from 'animejs';
import styles from './DragAndDrop.scss';
import { type BoundingRect } from 'react-measure';
import { DRAG_SOURCE_PREFIX } from '../../gizmos/drag-and-drop/constants';
import { extractTranslateProps } from '../_utils';
import { getFreeDroppedItemSourceId } from '../../gizmos/drag-and-drop/utils';
import { getContentRect } from '../../gizmo-utils/useMeasure/helpers';
import { getOnTopZIndex } from '../../utils/z-index/update-z-index';
import { type TranslateCoords } from '@bettermarks/gizmo-types';

const numericCSSValue = (value: string | undefined): number =>
  value === undefined ? 0 : parseFloat(value) || 0;

export type DragCallback = () => void;

export const enum DragEvents {
  dragStart = 'dragstart',
  dragEnd = 'dragend',
  drop = 'drop',
  leftInitialRect = 'leftInitialRect',
}

export const isInside = (x: number, y: number, r: BoundingRect) =>
  x >= r.left && x <= r.right && y >= r.top && y <= r.bottom;

const tooFarRight = (candidate: BoundingRect, bounds: BoundingRect) =>
  candidate.left + candidate.width >= bounds.left + bounds.width;
const tooFarLeft = (candidate: BoundingRect, bounds: BoundingRect) => candidate.left <= bounds.left;
const tooHigh = (candidate: BoundingRect, bounds: BoundingRect) => candidate.top <= bounds.top;
const tooLow = (candidate: BoundingRect, bounds: BoundingRect) =>
  candidate.top + candidate.height >= bounds.top + bounds.height;

export const isInsideBounds = (candidate: BoundingRect, bounds: BoundingRect) =>
  !tooFarRight(candidate, bounds) &&
  !tooFarLeft(candidate, bounds) &&
  !tooHigh(candidate, bounds) &&
  !tooLow(candidate, bounds);

export const keepMeInside = (
  candidate: BoundingRect,
  bounds: BoundingRect
): { x: number; y: number } => {
  const pos = { x: candidate.left, y: candidate.top };
  if (tooFarLeft(candidate, bounds)) {
    pos.x = bounds.left;
  } else if (tooFarRight(candidate, bounds)) {
    pos.x = bounds.left + bounds.width - candidate.width;
  }
  if (tooHigh(candidate, bounds)) {
    pos.y = bounds.top;
  } else if (tooLow(candidate, bounds)) {
    pos.y = bounds.top + bounds.height - candidate.height;
  }
  return pos;
};

export interface SourceInfo {
  targetGroup: number;
  originalSourceRefId?: string;
  srcDropTargetRefId?: string;
  srcDropTargetIndex?: number;
  sourceRef: Element;
  x: number;
  y: number;
  initialRect?: BoundingRect;
  freeSnapping?: boolean;
  instances?: number;
}

export class DnD {
  source: SourceInfo | null;
  listeners: { [key: string]: DragCallback[] } = {};
  freeSnappingRectMap: { [refid: string]: () => BoundingRect | undefined } = {};

  dragItem?: HTMLDivElement; // the drag item (adjusted clone of the source item)
  dragItemOffset: [number, number]; // offset between mouse & top left corner
  parentMargin: string | null; // need to get rid of the parent margin

  addEventListener(key: string, callback: DragCallback) {
    if (!this.listeners.hasOwnProperty(key)) {
      this.listeners[key] = [];
    }
    this.listeners[key] = [...this.listeners[key], callback];
  }

  removeEventListener(key: string, callback: DragCallback) {
    if (key in this.listeners) {
      this.listeners[key] = without([callback], this.listeners[key]);
    }
  }

  dispatchEvent(key: string) {
    if (key in this.listeners) {
      this.listeners[key].forEach((listener) => listener());
    }
  }

  isDragging() {
    return !isNil(this.source);
  }

  addFreeSnappingRect($refid: string, boundsFn: () => BoundingRect | undefined) {
    this.freeSnappingRectMap = { ...this.freeSnappingRectMap, [$refid]: boundsFn };
  }

  /**
   * Returns the position of the item being dragged.
   *
   * @returns a dict with x: position from left in pixels, and y: position from the top in pixels
   */
  getDragItemTranslationPosition(): { translateX: number; translateY: number } {
    if (this.dragItem && this.source) {
      const { x, y } = this.source;
      const extract = extractTranslateProps(this.dragItem.style.transform);
      if (extract) {
        const { translateX, translateY } = extract;
        return {
          translateX: translateX - x + this.dragItemOffset[0],
          translateY: translateY - y + this.dragItemOffset[1],
        };
      }
    }

    return { translateX: 0, translateY: 0 };
  }

  /**
   * Checks if the drag item is inside the "free zone". In case context is free drag and drop,
   * user can drag the item only in the "free" bordered zone.
   * See. free-drag-and-drop gremlins example.
   * @param x the x-coord of the **next** top left corner of the dragged item
   * @param y the y-coord of the **next** top left corner of the dragged item
   * @returns x y coords adjusted to be inside the free zone.
   */
  insideFreeZone(x: number, y: number): { x: number; y: number } {
    let pos = { x, y };
    if (
      this.source &&
      this.dragItem &&
      this.source.freeSnapping &&
      this.source.originalSourceRefId
    ) {
      const sourceID = getFreeDroppedItemSourceId(this.source.originalSourceRefId);
      const boundsFn = this.freeSnappingRectMap[sourceID];
      if (boundsFn) {
        const bounds = boundsFn();
        if (bounds) {
          const candidateBounds: BoundingRect | undefined = getContentRect(
            ['bounds'],
            this.dragItem
          ).bounds;
          if (candidateBounds) {
            const candidate: BoundingRect = {
              left: x,
              top: y,
              width: candidateBounds.width,
              height: candidateBounds.height,
              bottom: candidateBounds.bottom,
              right: candidateBounds.right,
            };
            const inside = isInsideBounds(candidate, bounds);
            if (!inside) {
              pos = keepMeInside(candidate, bounds);
            }
          }
        }
      }
    }
    return pos;
  }

  startDrag(source: SourceInfo) {
    if (this.source) return; // do not allow drag when we are already dragging
    this.source = source;
    const sourceRef = source.sourceRef;

    document.addEventListener('touchmove', this.onTouchMove, {
      passive: false,
    });
    document.addEventListener('touchend', this.onTouchEnd, { passive: false });
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    document.body.style.cursor = 'grabbing';

    // remove stale drag item (e.g. when starting new drag before old animation was done)
    this.dragItem && this.dragItem.remove();

    // Save the parent margin (usually of a HLayout) and set it to zero. As we cannot delete the
    // dragged element (to keep our touch events), we have to make it invisible, which includes
    // removing the margin of the parent layout container.
    if (sourceRef.classList.contains(styles.setDragSource) && sourceRef.parentElement) {
      const m = sourceRef.parentElement.style.marginLeft;
      if (numericCSSValue(m)) {
        this.parentMargin = m;
        sourceRef.parentElement.style.marginLeft = '0';
      }
    }

    // save offset of mouse cursor inside the item (used to place it correctly under the mouse
    // cursor while grabbing)
    const clientRect = sourceRef.getBoundingClientRect();
    this.dragItemOffset = [
      source.x - (clientRect.left + window.scrollX),
      source.y - (clientRect.top + window.scrollY),
    ];

    // clone current source incl. content as new drag item
    this.dragItem = sourceRef.cloneNode(true) as HTMLDivElement;
    this.dragItem.classList.add(styles.dragItem);
    const xx = source.x - this.dragItemOffset[0];
    const yy = source.y - this.dragItemOffset[1];

    this.dragItem.style.transform = `translateX(${xx}px) translateY(${yy}px)`;
    this.dragItem.style.zIndex = getOnTopZIndex();
    document.body.appendChild(this.dragItem);

    // hide source item
    sourceRef.classList.add(styles.dragging);

    this.dispatchEvent(DragEvents.dragStart);
    if (isNil(this.source.initialRect)) {
      this.dispatchEvent(DragEvents.leftInitialRect);
    }
  }

  finishDrag = () => {
    this.resetMargin();
    document.removeEventListener('touchmove', this.onTouchMove);
    document.removeEventListener('touchend', this.onTouchEnd);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.body.style.cursor = '';
    this.source && this.source.sourceRef.classList.remove(styles.dragging);
    this.source = null;
    this.dragItem && this.dragItem.remove();
    delete this.dragItem;
  };

  /**
   * Reset position to the initial position; before any user drag events
   *
   * @param element the element to reset position for
   */
  resetTransformation(element: Element) {
    if (element instanceof HTMLElement) {
      element.style.transform = '';
    }
  }

  resetZIndex(element: Element) {
    if (element instanceof HTMLElement) {
      element.style.zIndex = '';
    }
  }

  transformFreeDragItem(
    parent: HTMLElement,
    son: HTMLElement,
    pos: TranslateCoords,
    isCopy: boolean
  ) {
    const { width, height } = son.getBoundingClientRect();
    // We want to add the width and height of the source item to the relative div parent...
    // ...only if it's a single drag item.
    // We do this not to have change of size when items leaves the item area.
    // For drag item stack's copy, we do not want to increase space for each copy.
    parent.style.width = `${isCopy ? 0 : width}px`;
    parent.style.height = `${isCopy ? 0 : height}px`;

    const { translateX, translateY } = extractTranslateProps(son.style.transform) || {
      translateX: 0,
      translateY: 0,
    };

    son.style.transform = `translateX(${translateX + pos.translateX}px) translateY(${
      translateY + pos.translateY
    }px)`;
    son.style.zIndex = getOnTopZIndex();
  }

  transformDragParentItem(
    element: Element,
    parent: Element,
    pos: { translateX: number; translateY: number } | undefined
  ) {
    if (parent instanceof HTMLElement && pos) {
      const dragSource = element.getBoundingClientRect();
      // Makes sure we drop item inside the free zone. This caters for scrolling behaviour
      const { x: insideX, y: insideY } = this.insideFreeZone(pos.translateX, pos.translateY);
      parent.style.transform += `translate(
        ${(insideX - dragSource.x).toString()}px,
        ${(insideY - dragSource.y).toString()}px
        )`;
    } else if (parent instanceof HTMLElement && !pos) {
      this.resetTransformation(parent);
    }
  }

  resetMargin() {
    if (this.source) {
      const sourceRef = this.source.sourceRef;
      if (sourceRef.parentElement && this.parentMargin) {
        sourceRef.parentElement.style.marginLeft = this.parentMargin;
      }
      this.parentMargin = null;
    }
  }

  endDrag = () => {
    if (isNil(this.source)) return;

    if (!this.source.freeSnapping) {
      const targetEl = document.getElementById(
        `${DRAG_SOURCE_PREFIX}-${this.source.originalSourceRefId}`
      );
      let targetPos = [0, 0];
      if (targetEl) {
        const targetClientRect = targetEl.getBoundingClientRect();
        targetPos = [targetClientRect.left + window.scrollX, targetClientRect.top + window.scrollY];
      }

      // unsuccessful drag -> animate back to source
      anime({
        targets: this.dragItem,
        translateX: targetPos[0],
        translateY: targetPos[1],
        easing: 'easeOutQuad',
        duration: 300,
        complete: () => {
          if (!this.source) return;
          this.resetMargin();
          this.dispatchEvent(DragEvents.dragEnd);
          this.finishDrag();
        },
      });
    } else {
      if (!this.source) return;
      this.resetMargin();
      this.dispatchEvent(DragEvents.dragEnd);
      this.finishDrag();
    }
  };

  canDrop(targetGroup: number) {
    return !isNil(this.source) && this.source.targetGroup === targetGroup;
  }

  getTargetToSourceTranslation(target: Element) {
    if (!this.source) {
      return;
    }

    const sourceBox = this.source.sourceRef.getBoundingClientRect();
    const targetBox = target.getBoundingClientRect();
    const translation = {
      translateX: sourceBox.left - targetBox.left,
      translateY: sourceBox.top - targetBox.top,
    };

    return translation;
  }

  getSourceToTargetTranslation(target: Element) {
    if (!this.source) {
      return;
    }

    const sourceBox = this.source.sourceRef.getBoundingClientRect();
    const targetBox = target.getBoundingClientRect();
    const translation = {
      translateX: targetBox.left - sourceBox.left,
      translateY: targetBox.top - sourceBox.top,
    };

    return translation;
  }

  drop = () => {
    this.resetMargin();
    this.dispatchEvent(DragEvents.drop);
    this.finishDrag();
  };

  drag = (x: number, y: number) => {
    if (this.dragItem) {
      let xx = x - this.dragItemOffset[0];
      let yy = y - this.dragItemOffset[1];
      if (this.source?.freeSnapping) {
        const { x: insideX, y: insideY } = this.insideFreeZone(xx, yy);
        xx = insideX;
        yy = insideY;
      }
      this.dragItem.style.transform = `translateX(${xx}px) translateY(${yy}px)`;
      if (this.source?.initialRect && !isInside(x, y, this.source?.initialRect)) {
        delete this.source.initialRect;
        this.dispatchEvent(DragEvents.leftInitialRect);
      }
    }
  };

  onTouchMove = (evt: TouchEvent) => {
    this.drag(evt.touches[0].clientX, evt.touches[0].clientY);
    evt.preventDefault();
  };

  onTouchEnd = (evt: TouchEvent) => {
    if (this.isDragging()) {
      this.endDrag(); // didn't drop on a valid target -> animate item back to source
    }
  };

  onMouseMove = ({ clientX, clientY }: MouseEvent) => this.drag(clientX, clientY);

  onMouseUp = (_: MouseEvent) => this.isDragging() && this.endDrag();
}

const instance = new DnD();

export default instance;
