import { type Action, handleActions } from 'redux-actions';
import {
  DEFAULT_DYNAMIC_REPRESENTATION_CONTENT,
  type IDynamicRepresentationContent,
} from '@bettermarks/gizmo-types';
import {
  type DynamicRepresentationActionPayload,
  SET_PARAM,
  type SetParamPayload,
} from './actions';

export const dynamicRepresentationReducer = handleActions<
  IDynamicRepresentationContent,
  DynamicRepresentationActionPayload
>(
  {
    [SET_PARAM]: (state, { payload }: Action<SetParamPayload>) =>
      payload
        ? {
            ...state,
            sliders: [
              ...state.sliders.map((s) =>
                s.binding === payload.id
                  ? {
                      ...s,
                      value: payload.value,
                    }
                  : s
              ),
            ],
            onOffControls: [
              ...state.onOffControls.map((s) =>
                s.binding === payload.id
                  ? {
                      ...s,
                      value: payload.value,
                    }
                  : s
              ),
            ],
            optionsControls: [
              ...state.optionsControls.map((s) =>
                s.binding === payload.id
                  ? {
                      ...s,
                      value: payload.value,
                    }
                  : s
              ),
            ],
            valueSteppers: [
              ...state.valueSteppers.map((vs) =>
                vs.binding === payload.id
                  ? {
                      ...vs,
                      value: payload.value,
                    }
                  : vs
              ),
            ],
          }
        : state,
  },
  DEFAULT_DYNAMIC_REPRESENTATION_CONTENT
);
