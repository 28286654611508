import React from 'react';

export function UndoIcon() {
  return (
    <svg id="labels" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <path d="M24.83,13.42c-2-2.66-6.53-2.93-12.22-.8L10.8,9.06,5.37,17.42l10,.52-1.8-3.53c5.46-2,8.58-1.26,9.71.22s.53,4.3-1.72,7.11L23.08,23C26,19.37,26.63,15.8,24.83,13.42Z" />
    </svg>
  );
}

export function RedoIcon() {
  return (
    <svg id="labels" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <path d="M26.63,17.42,21.2,9.06l-1.81,3.56c-5.69-2.13-10.21-1.86-12.22.8C5.37,15.8,6,19.37,8.92,23l1.56-1.25c-2.25-2.81-2.91-5.54-1.72-7.11s4.25-2.23,9.71-.22l-1.8,3.53Z" />
    </svg>
  );
}

export function PlusCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zM10 19c-5 0-9-4-9-9s4-9 9-9 9 4 9 9S15 19 10 19zM10.5 9.5H15v1h-4.5V15h-1v-4.5H5v-1h4.5V5h1V9.5z"></path>
    </svg>
  );
}

export type IconProps = {
  fillColor?: string;
  size?: number;
};

export function CheckmarkCircleFilled({
  fillColor = '#347d3a',
  size = 24,
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-check-circle-fill"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>
  );
}

export function CheckmarkCircle({ fillColor = '#C49F39', size = 24 }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-check-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
    </svg>
  );
}

export function ReturnArrow({ fillColor = '#ffffff', size = 20 }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fillColor}
      className="bi bi-arrow-return-left"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"
      />
    </svg>
  );
}

export function LifeRing({ fillColor = '#000000', size = 20 }: IconProps): JSX.Element {
  return (
    <svg version="1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={fillColor}>
      <path d="M18.517 15.222c.03-.05.068-.094.098-.144l-.006-.006a9.94 9.94 0 0 0-.001-10.144l.006-.006c-.03-.05-.067-.094-.098-.144a10.413 10.413 0 0 0-.518-.762c-.053-.07-.104-.142-.158-.211a10.012 10.012 0 0 0-1.646-1.646c-.069-.054-.141-.105-.211-.158-.246-.184-.5-.356-.762-.518-.05-.03-.094-.068-.144-.098l-.006.006a9.942 9.942 0 0 0-10.143.001l-.006-.006c-.05.03-.094.067-.144.098-.262.161-.516.333-.762.517-.07.053-.142.104-.211.158a10.09 10.09 0 0 0-1.646 1.646c-.054.069-.105.141-.158.211-.184.246-.356.5-.518.762-.03.05-.068.094-.098.144l.006.006a9.94 9.94 0 0 0 .001 10.144l-.006.006c.03.05.067.094.098.144.162.262.334.516.518.762.053.07.104.142.158.211a10.012 10.012 0 0 0 1.646 1.646c.069.054.141.105.211.158.246.184.5.356.762.518.05.03.094.068.144.098l.006-.006a9.942 9.942 0 0 0 10.143-.001l.006.006c.05-.03.094-.067.144-.098.262-.162.516-.334.762-.518.07-.053.142-.104.211-.158a10.012 10.012 0 0 0 1.646-1.646c.054-.069.105-.141.158-.211.184-.245.356-.499.518-.761zM19 10a8.94 8.94 0 0 1-1.122 4.342l-2.712-2.712a5.421 5.421 0 0 0 0-3.26l2.712-2.712A8.94 8.94 0 0 1 19 10zM5.583 10c0-2.435 1.981-4.417 4.417-4.417S14.417 7.565 14.417 10 12.435 14.417 10 14.417 5.583 12.435 5.583 10zM10 1a8.94 8.94 0 0 1 4.342 1.122L11.63 4.834a5.421 5.421 0 0 0-3.26 0L5.658 2.122A8.94 8.94 0 0 1 10 1zm-9 9a8.94 8.94 0 0 1 1.122-4.342L4.834 8.37a5.417 5.417 0 0 0-.001 3.26l-2.712 2.712A8.95 8.95 0 0 1 1 10zm9 9a8.94 8.94 0 0 1-4.342-1.122l2.712-2.712a5.421 5.421 0 0 0 3.26 0l2.712 2.712A8.94 8.94 0 0 1 10 19z" />
    </svg>
  );
}
