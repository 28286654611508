import { first, includes, isEmpty } from 'lodash';
import { type Action, createAction, handleActions } from 'redux-actions';
import {
  type Coords,
  type GeoAddLineState,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type IdCoords,
  type LabelObject,
  GeoLabelType,
  POINT,
  type SnapPoint,
  SnapType,
  VECTOR,
} from '@bettermarks/gizmo-types';
import {
  DEFAULT_LINE_DECORATION,
  DEFAULT_PREVLINE,
  eq,
  GEO_DEFAULT_PERSIST_PROPS,
  getPointCoordsId,
  INV_POINT_PREFIX,
  screenToWorld,
} from '@bettermarks/importers';
import { DEFAULT_ADD_LABEL_STATE, type LineTypeProps } from '../constants';
import { addAutoLabel, addPreviewLine, addPreviewPoint } from '../helpers';
import { getRefPointCoords } from '../../components/sets';
import { persistPoint } from '../persist';
import { confirmLabel } from '../addlabel/helpers';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */
export interface SnapPayload {
  snapPoint: SnapPoint | null;
  configuration: GeoConfiguration;
  mouseP: Coords;
  matrix: number[];
  lineTypeProps: LineTypeProps;
}

export interface DownPayload {
  matrix: number[];
  lineTypeProps: LineTypeProps;
}

export interface ConfirmLabelPayload {
  index: number;
  labelAlignTop: boolean;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface LabelConfirmPayload {
  index: number;
  labelAlignTop: boolean;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface AddLinePayload {
  configuration: GeoConfiguration;
  matrix: number[];
  lineTypeProps: LineTypeProps;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface InitialLabelClickPayload {
  id: string;
  configuration: GeoConfiguration;
}

export interface InitialLabelOverPayload {
  onInitialLabelOver: boolean;
}

export type GeoAddLinePayload =
  | SnapPayload
  | DownPayload
  | AddLinePayload
  | ConfirmLabelPayload
  | InitialLabelOverPayload
  | InitialLabelClickPayload
  | void;

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPayload>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);
const DOWN: 'DOWN' = 'DOWN';
export const downAction = createAction<DownPayload>(DOWN);
const ADD_LINE: 'ADD_LINE' = 'ADD_LINE';
export const addLineAction = createAction<AddLinePayload>(ADD_LINE);
const CLICK_INITIAL_LABEL: 'CLICK_INITIAL_LABEL' = 'CLICK_INITIAL_LABEL';
export const clickInitialLabelAction = createAction<InitialLabelClickPayload>(CLICK_INITIAL_LABEL);
const OVER_INITIAL_LABEL: 'OVER_INITIAL_LABEL' = 'OVER_INITIAL_LABEL';
export const overInitialLabelAction = createAction<InitialLabelOverPayload>(OVER_INITIAL_LABEL);
const LABEL_CONFIRM: 'LABEL_CONFIRM' = 'LABEL_CONFIRM';
export const labelConfirmAction = createAction<LabelConfirmPayload>(LABEL_CONFIRM);
const LABEL_CLOSE: 'LABEL_CLOSE' = 'LABEL_CLOSE';
export const closeLabelAction = createAction(LABEL_CLOSE);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState = {
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  snapPoints: [],
  prevLine: DEFAULT_PREVLINE,
  prevPoints: [],
  labels: DEFAULT_ADD_LABEL_STATE,
};

export const geoAddLineReducer = handleActions<GeoAddLineState, GeoAddLinePayload>(
  {
    [SNAP]: (state: GeoAddLineState, { payload }: Action<SnapPayload>) => {
      if (!payload) {
        return state;
      }

      const { snapPoint, configuration, mouseP, matrix, lineTypeProps } = payload;

      let newState = state;
      if (snapPoint) {
        newState = {
          ...newState,
          snapPoints: state.labels.onInitialLabelOver ? [] : [snapPoint],
        };
      } else if (!snapPoint && configuration.snapType === SnapType.none) {
        newState = {
          ...newState,
          snapPoints: [],
        };
      }

      // draw preview line if snapPoint or mouseP second construction point
      if (
        !isEmpty(newState.prevPoints) &&
        (snapPoint || configuration.snapType === SnapType.none)
      ) {
        const resultState = addPreviewLine<GeoAddLineState>(
          newState,
          mouseP,
          matrix,
          lineTypeProps,
          configuration.defaultDecorations
        );

        return {
          ...resultState,
          prevPoints: resultState.prevPoints.map((p) => ({
            ...p,
            invisible: lineTypeProps.type === VECTOR,
          })),
        };
      }

      return newState;
    },

    [STOP_SNAP]: (state: GeoAddLineState) => {
      return {
        ...initialState,
        persistProps: state.persistProps,
        labels: state.labels,
      };
    },

    [DOWN]: (state: GeoAddLineState, { payload }: Action<DownPayload>) => {
      if (!payload) {
        return state;
      }

      const { matrix, lineTypeProps } = payload;

      if (isEmpty(state.prevPoints) && state.snapPoints.length > 0) {
        return addPreviewPoint<GeoAddLineState>(
          state,
          first(state.snapPoints) as SnapPoint,
          matrix,
          lineTypeProps.type === VECTOR
        );
      }

      return {
        ...initialState,
        persistProps: state.persistProps,
      };
    },

    [ADD_LINE]: (state: GeoAddLineState, { payload }: Action<AddLinePayload>) => {
      if (!payload) {
        return state;
      }

      const { configuration, lineTypeProps, matrix, onPersistLocalState } = payload;

      const snapPoint = first(state.snapPoints);
      const firstPoint: IdCoords = state.prevPoints[0];

      // don't persist line if
      // - no preview points or
      // - no snapPoint received or
      // - snapPoint (= second point) is same as first point
      if (
        isEmpty(state.prevPoints) ||
        !snapPoint ||
        eq(screenToWorld(matrix)(snapPoint), firstPoint.coords)
      ) {
        return {
          ...initialState,
          persistProps: state.persistProps,
        };
      }

      let newState = state;
      const snapCoords = screenToWorld(matrix)(snapPoint);

      // handle first construction point
      if (
        !includes(newState.persistProps.points, firstPoint.id) &&
        !includes(newState.persistProps.invisiblePoints, firstPoint.id)
      ) {
        newState = persistPoint<GeoAddLineState>(
          firstPoint,
          newState,
          configuration.defaultDecorations.points,
          lineTypeProps.type !== VECTOR
        );

        if (POINT in configuration.autoLabeling) {
          newState = addAutoLabel<GeoAddLineState>(firstPoint, newState, configuration);
        }
      }

      const secondPoint: IdCoords = { id: '', coords: snapCoords };
      // second construction point already exists
      if (snapPoint.snapObject === POINT && includes(newState.persistProps.points, snapPoint.id)) {
        secondPoint.id = snapPoint.id;
      } else {
        // we need to create a new point for the second construction point
        secondPoint.id = getPointCoordsId(
          snapCoords,
          lineTypeProps.type === VECTOR ? INV_POINT_PREFIX : undefined
        );

        newState = persistPoint<GeoAddLineState>(
          secondPoint,
          newState,
          configuration.defaultDecorations.points,
          lineTypeProps.type !== VECTOR
        );

        if (POINT in configuration.autoLabeling) {
          newState = addAutoLabel<GeoAddLineState>(secondPoint, newState, configuration);
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-types
      const getLineId: Function = lineTypeProps.getLineId;
      const newLineId = getLineId(firstPoint.id, secondPoint.id);
      // only create new line, if same line does not yet exist
      if (!(newLineId in newState.persistProps.geoContentMap)) {
        const persistLine = lineTypeProps.persistLine;
        newState = persistLine<GeoAddLineState>(
          newState,
          firstPoint.id,
          secondPoint.id,
          newLineId,
          {
            ...DEFAULT_LINE_DECORATION,
            ...configuration.defaultDecorations[lineTypeProps.type],
            lineStyle: lineTypeProps.lineStyle,
          },
          !(lineTypeProps.type in configuration.labelValues)
        );

        if (lineTypeProps.type in configuration.autoLabeling) {
          newState = addAutoLabel<GeoAddLineState>(
            {
              id: newLineId,
              coords: getRefPointCoords(
                newState.persistProps.geoContentMap[newLineId],
                newState.persistProps.geoContentMap,
                configuration.display
              ),
            },
            newState,
            configuration
          );
        }
      }

      onPersistLocalState(newState.persistProps);

      return {
        ...initialState,
        persistProps: newState.persistProps,
      };
    },

    [CLICK_INITIAL_LABEL]: (
      state: GeoAddLineState,
      { payload }: Action<InitialLabelClickPayload>
    ) => {
      if (!payload) {
        return state;
      }

      const { configuration, id } = payload;

      const label = state.persistProps.geoContentMap[id] as LabelObject;
      if (label.labelType !== GeoLabelType.initial) {
        return state;
      }

      const labelingObjectId = first(label.referringTo) as string;
      const labelingObjectType = state.persistProps.geoContentMap[labelingObjectId].type;

      return {
        ...state,
        labels: {
          ...state.labels,
          labelingObjectId,
          labelList: configuration.labelValues[labelingObjectType].geoLabels,
          pickerList: configuration.labelValues[labelingObjectType].pickerLabels,
          active: true,
        },
      };
    },

    [LABEL_CONFIRM]: (state: GeoAddLineState, { payload }: Action<ConfirmLabelPayload>) => {
      if (!payload) {
        return state;
      }

      const labelState = confirmLabel(
        {
          persistProps: { ...state.persistProps },
          snapPoints: [...state.snapPoints],
          ...state.labels,
        },
        payload
      );

      return {
        ...state,
        persistProps: labelState.persistProps,
        labels: labelState,
      };
    },

    [OVER_INITIAL_LABEL]: (
      state: GeoAddLineState,
      { payload }: Action<InitialLabelOverPayload>
    ) => {
      if (!payload) {
        return state;
      }

      const { onInitialLabelOver } = payload;

      return {
        ...state,
        labels: {
          ...state.labels,
          onInitialLabelOver,
        },
        snapPoints: onInitialLabelOver ? [] : state.snapPoints,
      };
    },

    [LABEL_CLOSE]: (state: GeoAddLineState) => ({
      ...initialState,
      persistProps: state.persistProps,
    }),
  },
  initialState
);
