export const DEFAULT_FONT_SIZE = 16;
export const DEFAULT_GEO_LABEL_FONT_SIZE = 16;
export const DEFAULT_FONT_SIZE_FLASH = 13;

export const MIN_SCALABLE_FONT_SIZE = 6;
export const fontSizeFromScale = (scale: number, fontSize: number, min = MIN_SCALABLE_FONT_SIZE) =>
  Math.max(Math.round(fontSize * scale), min);

export const FREE_BETTY_FACTOR = DEFAULT_FONT_SIZE / DEFAULT_FONT_SIZE_FLASH;

export const FLASH_DEFAULT_MAX_WIDTH = 661;
