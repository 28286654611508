import styled from 'styled-components';
import { defaultTheme } from '@seriesplayer/common-ui';

interface ContentProps {
  padding?: boolean;
  colored?: boolean;
  stretched?: boolean;
}

export const Content = styled.div<ContentProps>`
  ${(props) => props.padding && `padding: ${defaultTheme.dimensions.spaceS};`}
  ${(props) => props.colored && `color: ${defaultTheme.colors.cTextMedium};`}
  ${(props) => props.stretched && 'width: 100%;'}

  & > * {
    margin-bottom: ${defaultTheme.dimensions.spaceXs};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
