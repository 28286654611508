import {
  DEFAULT_FEATURES,
  DEFAULT_SERIES_SETTINGS,
  type GetParams,
  type ILoadExercisePayload,
  type ILoadFilesPayload,
  SeriesFlow,
  type StartSeriesPayload,
} from '../../../../types';
import {
  fetchExercise as fetchExerciseUnstubable,
  fetchSeries as fetchSeriesUnstubable,
  getExerciseFromHostPath as getExerciseFromHostPathUnstubable,
  type SeriesResponse,
} from '../../services';
import { DEFAULT_APP_SETTINGS } from '../../../store/constants';
import {
  getExercises as getExercisesUnstubable,
  getFullExercisePath as getFullExercisePathUnstubable,
} from '../Upload/helper';
import { type AppConfig } from '../../services/api/getAppConfig';
import { type LoadSeriesArgs } from './startSeriesHelpers';

export const STUBABLE = {
  getExercises: getExercisesUnstubable,
  getFullExercisePath: getFullExercisePathUnstubable,
  fetchExercise: fetchExerciseUnstubable,
  fetchSeries: fetchSeriesUnstubable,
  getExerciseFromHostPath: getExerciseFromHostPathUnstubable,
};
export type LoadSeriesFunc = (
  payload: LoadSeriesArgs,
  appConfig?: AppConfig | undefined
) => Promise<StartSeriesPayload>;
/**
 * Call appropriate route based on parameters.
 * - It is guaranteed from interface we will always get one of seriesId or exerciseId
 * @param seriesId
 * @param exerciseId
 * @param studentId
 * @param seriesReview
 * @param userId
 * @param reporting
 * @param appConfig
 */
export const loadSeriesFromSeriesIdInNSP: LoadSeriesFunc = async (
  {
    seriesId,
    exerciseId,
    studentId,
    seriesReview,
    previewMode,
    userId,
    assignmentEndDate: assignmentEndDateQueryParams,
  }: GetParams & Partial<{ assignmentEndDate: string }>,
  appConfig
) => {
  let seriesResponse: SeriesResponse;
  const {
    appSettings = DEFAULT_APP_SETTINGS,
    features = DEFAULT_FEATURES,
    isStudent = false,
  } = appConfig || {};
  const { contentManagerUrl, staticServerUrl } = appSettings;
  if (seriesId) {
    seriesResponse = await STUBABLE.fetchSeries(
      contentManagerUrl,
      staticServerUrl,
      seriesId,
      seriesReview,
      studentId
    );
  } else {
    const xmlString = await STUBABLE.fetchExercise(staticServerUrl, exerciseId as string);
    /*
     - `qaMode` set to false: Not possible to arrive here while testing & not required.
     - `testMode` set to false because just need to load single exercise for top 5 mistakes for
     teacher only.
     Not relevant for student.stepResult
    */
    seriesResponse = {
      exercises: STUBABLE.getExercises([xmlString], false, false),
      currentExerciseIndex: 0,
      settings: DEFAULT_SERIES_SETTINGS,
    };
  }
  const { title, currentExerciseIndex, settings, exercises, assignment, groupId } = seriesResponse;
  const seriesSettings = settings || DEFAULT_SERIES_SETTINGS;
  /*
    Here we provide a way to SET `assignmentEndDate` from the action payload
    (that gets its property also by url query parameters) IF such field is not already
    included in the response.
    This is only met to be used for testing purpose
  */
  const assignmentEndDate: undefined | string = seriesResponse.assignment?.endDate
    ? seriesResponse.assignment?.endDate
    : assignmentEndDateQueryParams
    ? assignmentEndDateQueryParams
    : undefined;

  return {
    appSettings,
    exercises,
    features,
    ...(assignmentEndDate && {
      assignment: {
        ...assignment,
        endDate: assignmentEndDate,
      },
    }),
    ...(title && { title }),
    currentExerciseIndex,
    ...(seriesId && { seriesId }),
    seriesSettings: { ...seriesSettings, reporting: isStudent },
    seriesReview,
    previewMode,
    userId,
    ...(groupId && { groupId }),
  };
};
/**
 * Fetch the remote exercise on action from Upload container and initialize exercises
 * @param {ILoadExercisePayload} payload
 */
export const loadSeriesFromExerciseId: LoadSeriesFunc = async ({
  exerciseid,
  features,
  qaMode,
  seriesReview,
  previewMode,
  seriesSettings,
  staticUrl,
  assignmentEndDate,
}: ILoadExercisePayload) => {
  const xmlString = await STUBABLE.getExerciseFromHostPath(
    staticUrl,
    `${STUBABLE.getFullExercisePath(exerciseid)}.xml`
  );
  const testMode = seriesSettings.flow === SeriesFlow.random;

  return {
    exercises: STUBABLE.getExercises([xmlString], !!qaMode, testMode),
    currentExerciseIndex: 0,
    features,
    seriesReview,
    previewMode,
    seriesSettings,
    ...(qaMode && { qaMode }),
    ...(assignmentEndDate && {
      assignment: {
        endDate: assignmentEndDate,
      },
    }),
  };
};
/**
 * Initialise the exercises from the files uploaded.
 * @param {ReadonlyArray<string>} payload
 */
export const loadSeriesFromFileUpload: LoadSeriesFunc = async ({
  features,
  qaMode,
  seriesReview,
  previewMode,
  seriesSettings,
  xmlStrings,
  assignmentEndDate,
}: ILoadFilesPayload) => {
  const testMode = seriesSettings.flow === SeriesFlow.random;

  return {
    exercises: STUBABLE.getExercises(xmlStrings, !!qaMode, testMode),
    currentExerciseIndex: 0,
    features,
    seriesReview,
    previewMode,
    seriesSettings,
    ...(assignmentEndDate && {
      assignment: {
        endDate: assignmentEndDate,
      },
    }),
  };
};
