import * as React from 'react';
import upperFirst from 'lodash/upperFirst';
import classNames from 'classnames';

import * as icons from '../icons';
import * as draftIcons from '../icons/draft';
import * as geoIcons from '../icons/geo';
import styles from './Icon.scss';
import { type ContextNotificationKind } from '@seriesplayer/common-ui';

const iconMap = {
  ...icons,
  ...draftIcons,
  ...geoIcons,
};

export type IconKey = keyof typeof iconMap;

// eslint-disable-next-line @typescript-eslint/ban-types
export type IconProps = React.SVGAttributes<{}> & {
  iconKey: IconKey;
};

/**
 * A generic component to show icons from our ICON library.
 * - The height and width are encoded in the name of the svg files composing our icons.
 * - This component uses the default values for height & width from the name.
 * - The height and width from name is applied in lib/svgRuntimeGenerator/svgRuntimeGenerator.js.
 * - `svgAttributes`, however allows to override these values when `Icon` is used anywhere.
 * @param iconKey: Valid Icon name in the list of our Icons.
 * @param svgAttributes: Attributes for SVG that we would like to override.
 */
export const Icon: React.FC<IconProps> = ({ iconKey, ...svgAttributes }) => {
  const Component = iconMap[iconKey];
  return <Component {...svgAttributes} />;
};

export const getIconByKey = (
  iconKey: string,
  kind: ContextNotificationKind,
  additionalStyles = ''
) => {
  const Icon = icons[upperFirst(iconKey) as keyof typeof icons];

  return Icon && <Icon className={classNames(styles.icon, styles[kind], additionalStyles)} />;
};
