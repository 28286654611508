import * as React from 'react';
import Scrollbars, { type ScrollbarProps } from 'react-custom-scrollbars';
import {
  BaseDragScrollBehaviour,
  type DragScrollBehaviour,
  getDragScrollBehaviour,
} from '../../../gizmo-utils/drag-scroll-behaviour';
import { useMeasure } from '../../../gizmo-utils/useMeasure';
import { CONTENT_ID } from '../../../gizmo-utils/constants';
import DnD, { DragEvents } from '../../../components/DragAndDrop/dnd';
import { Invisible } from './Invisible';
import styles from './ShadowScrollbars.scss';
import { MAX_SCROLL_CONTAINER_HEIGHT } from './constants';

interface UpdateValues {
  clientWidth: number; // Width of the view
  scrollWidth: number; // Native scrollWidth
  scrollLeft: number; // Native scrollLeft
}

interface ShadowScrollbarState {
  scrollLeft: number;
  scrollWidth: number;
  clientWidth: number;
  shadowLeftOpacity: number;
  shadowRightOpacity: number;
  adjustLeftScroll: number;
}

/*
 * Omitting autoHeight and autoHeightMax, since ShadowScrollbars is setting it.
 * We also need to remove the ref parameter from the props, that are passed to the Scrollbars
 * component, because it expects a prop called ref from a different type! The 'forwardedRef'
 * parameter is our own ref we share with the parent element. This enables us to controll the scroll
 * area from the outside (e.g.  via drag&scroll behaviour).
 */
export type ShadowScrollbarProps = Omit<ScrollbarProps, 'autoHeight' | 'autoHeightMax' | 'ref'> & {
  availableWidth: number;
  scrollBehaviour?: DragScrollBehaviour;
  scrollPosition?: number;
  /**
   * If you know the scroll width of your child, you don't need useMeasure to be called. This might
   * prevent flickering.
   */
  fixedChildScrollWidth?: number;
};

type BaseShadowScrollbarProps = ShadowScrollbarProps & {
  /**
   * callback to unregister drag-scroll-behaviour when there is no scrolling needed
   */
  onNoScrollNeeded: () => void;
};

/**
 * Wraps the child into a scrollable container (using Scrollbars from 'react-custom-scrollbars').
 * Adds shadow(s) to the side(s) (left or right) where content is hidden.
 */
export const BaseShadowScrollbars = React.forwardRef<HTMLDivElement, BaseShadowScrollbarProps>(
  (props, forwardedRef) => {
    const { style, availableWidth, fixedChildScrollWidth, onNoScrollNeeded, ...rest } = props;
    const [scrollState, setScrollState] = React.useState<ShadowScrollbarState>({
      scrollLeft: 0,
      scrollWidth: 0,
      clientWidth: 0,
      adjustLeftScroll: 0,
      shadowLeftOpacity: 0,
      shadowRightOpacity: 0,
    });

    const [childScrollWidth, childRef] = useMeasure<number, HTMLDivElement>(
      0,
      ['scroll'],
      ({ scroll }, scrollWidth) => (scroll && scrollWidth !== scroll.width ? scroll.width : null)
    );

    const needScroll =
      availableWidth < (fixedChildScrollWidth || scrollState.scrollWidth || childScrollWidth);

    React.useEffect(onNoScrollNeeded, [needScroll]);

    const handleUpdate = ({ scrollLeft, scrollWidth, clientWidth }: UpdateValues) => {
      const SHADOW_WIDTH = parseInt(styles.SHADOW_WIDTH, 10);
      if (
        scrollState.scrollLeft !== scrollLeft ||
        scrollState.clientWidth !== clientWidth ||
        scrollState.scrollWidth !== scrollWidth
      ) {
        const shadowLeftOpacity = (1 / SHADOW_WIDTH) * Math.min(scrollLeft, SHADOW_WIDTH);
        const rightScrollLeft = scrollWidth - clientWidth;
        const shadowRightOpacity =
          (1 / SHADOW_WIDTH) *
          (rightScrollLeft - Math.max(scrollLeft, rightScrollLeft - SHADOW_WIDTH));
        // We don't want to scroll left on initial phase,
        // but only when child is growing.
        const childGrowth =
          scrollState.scrollWidth !== 0 ? scrollWidth - scrollState.scrollWidth : 0;
        setScrollState({
          shadowLeftOpacity,
          shadowRightOpacity,
          scrollLeft,
          scrollWidth,
          clientWidth,
          adjustLeftScroll: scrollLeft + Math.max(0, childGrowth),
        });
      }
    };

    const onScrollbarRef = React.useCallback(
      (ref) => {
        ref && ref.scrollLeft(scrollState.adjustLeftScroll);
      },
      [scrollState.adjustLeftScroll]
    );

    return needScroll ? (
      <div
        className={styles.container}
        ref={forwardedRef}
        style={{ maxWidth: availableWidth, ...style }}
      >
        <Scrollbars
          {...rest}
          // When the child component is growing (e.g. on user input), we want
          // the scroll bar to follow the growth.
          ref={onScrollbarRef}
          autoHeight
          autoHeightMax={MAX_SCROLL_CONTAINER_HEIGHT}
          hideTracksWhenNotNeeded
          // don't show vertical scroll bars, ever
          renderTrackVertical={Invisible}
          // HACK: Here we have to use blunt force to prevent y-scrolling.
          renderView={(props) => (
            <div
              {...props}
              style={{
                ...props.style,
                overflowY: 'hidden',
                marginRight: 0,
              }}
            />
          )}
          onUpdate={handleUpdate}
        />
        <div className={styles.shadowLeft} style={{ opacity: scrollState.shadowLeftOpacity }} />
        <div className={styles.shadowRight} style={{ opacity: scrollState.shadowRightOpacity }} />
      </div>
    ) : (
      <div ref={childRef}>{rest.children}</div>
    );
  }
);

BaseShadowScrollbars.displayName = 'BaseShadowScrollbars';

/**
 * Currently the only two implementations of ElementCSSInlineStyle are HTMLElement and SVGElement.
 * Since we don't need to care about SVG in this case, we assume the other option.
 *
 * @param el the Element to inspect
 */
const hasStyle = (el: Element | HTMLElement): el is HTMLElement =>
  'style' in el && typeof el.style === 'object';

export const getScrollWrapper = (el: HTMLElement) => {
  let wrapper: Nullable<Element> = el;
  while (wrapper) {
    if (hasStyle(wrapper) && wrapper.style.overflowX === 'scroll') {
      return wrapper;
    }
    wrapper = wrapper.firstElementChild;
  }
  return null;
};

const setScrollPosition = (
  scrollRef: React.MutableRefObject<Nullable<HTMLDivElement>>,
  scrollPosition?: number
) => {
  if (scrollRef.current) {
    const scrollWrapper = getScrollWrapper(scrollRef.current);
    if (scrollWrapper && scrollPosition !== undefined) {
      scrollWrapper.scrollLeft = scrollPosition;
    }
  }
};

/**
 *
 * Hook to create a scroll behaviour for dragging and scrolling in the local state.
 *
 * Because the scroll behaviour has to be added when the ref has been mounted (i.e. when the actual
 * DOM element of the scroll wrapper is available), we have to use a callback instead of a ref
 * object. The actual ref object "scrollRef" will get the DOM element assigned by hand and the
 * scroll behaviour gets initialized.
 * see https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 *
 * The actual re-render will be caused by the initialization of the scroll behaviour.
 *
 * @param scrollPosition: the initial position (or undefined if you don't want to specify it)
 * behaviour. In this case the returned ref is NOT connected to your behaviour of course.
 * @param initialBehaviour the initial scroll behaviour to use. If not set then
 * BaseDragScrollBehaviour will be use as a default.
 * @param bindXContainer used for late binding of horizontal container. Needed when
 * two containers(vertical and horizontal) are used for DragScrollBehaviour initialization.
 * e.g. for GeoDragScrollBehaviour.
 */
export const useDragScrollBehaviour = (
  scrollPosition?: number,
  initialBehaviour?: DragScrollBehaviour,
  bindXContainer?: (_: HTMLElement) => DragScrollBehaviour
) => {
  const [scrollBehaviour, setScrollBehaviour] = React.useState<DragScrollBehaviour | undefined>(
    initialBehaviour
  );
  const scrollRef = React.useRef<HTMLDivElement>();

  const onRef = React.useCallback((el: HTMLDivElement) => {
    scrollRef.current = el;
    const scrollWrapper = getScrollWrapper(el);
    if (scrollWrapper) {
      if (bindXContainer) {
        setScrollBehaviour(bindXContainer(scrollWrapper));
      } else if (!scrollBehaviour) {
        setScrollBehaviour(new BaseDragScrollBehaviour(scrollWrapper, true));
      }
    }
    setScrollPosition(scrollRef, scrollPosition);
  }, []);

  return { scrollBehaviour, scrollRef, onRef };
};

const createUnregisterScrollBehaviourCallback =
  (scrollBehaviour: DragScrollBehaviour | undefined) => () => {
    if (scrollBehaviour) {
      DnD.removeEventListener(DragEvents.dragStart, scrollBehaviour.startScrolling);
      DnD.removeEventListener(DragEvents.dragEnd, scrollBehaviour.stopScrolling);
      DnD.removeEventListener(DragEvents.drop, scrollBehaviour.stopScrolling);
    }
  };

/**
 * connect the DnD API's events to the given scroll behaviour.
 */
export const useDnDScrollEvents = (scrollBehaviour?: DragScrollBehaviour) => {
  React.useEffect(() => {
    if (scrollBehaviour) {
      DnD.addEventListener(DragEvents.dragStart, scrollBehaviour.startScrolling);
      DnD.addEventListener(DragEvents.dragEnd, scrollBehaviour.stopScrolling);
      DnD.addEventListener(DragEvents.drop, scrollBehaviour.stopScrolling);
      return createUnregisterScrollBehaviourCallback(scrollBehaviour);
    }
  }, [scrollBehaviour]);
};

const getYContainer = () => document.getElementById(CONTENT_ID);

/**
 * Per default we have to enable dragging drag-items and scrolling everywhere in the application.
 * If you supply an initial scroll behaviour, this one will be used instead and the DnD events will
 * be connected to it. In this case you also should provide the connected ref callback for the outer
 * behaviour, which will then be passed on to the actual scrollbar component.
 * If you don't do that, DnD-scroll will not work!
 */
export const ShadowScrollbars = React.forwardRef<HTMLDivElement, ShadowScrollbarProps>(
  ({ scrollBehaviour: initialScrollBehaviour, scrollPosition, ...props }, ref) => {
    const { scrollBehaviour, onRef } = useDragScrollBehaviour(
      scrollPosition,
      initialScrollBehaviour,
      getDragScrollBehaviour(getYContainer)
    );
    useDnDScrollEvents(scrollBehaviour);

    const unregisterDnD = React.useCallback(
      createUnregisterScrollBehaviourCallback(scrollBehaviour),
      [scrollBehaviour]
    );

    return (
      <BaseShadowScrollbars {...props} ref={ref ? ref : onRef} onNoScrollNeeded={unregisterDnD} />
    );
  }
);

ShadowScrollbars.displayName = 'ShadowScrollbars';
