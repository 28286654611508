import { type Action, handleActions } from 'redux-actions';
import { isNil } from 'lodash';
import {
  END,
  MOVE,
  PERSIST,
  type PieChartMovePayload,
  type PieChartStartPayload,
  type PieOrStackedBarChartPersistPayload as PieChartPersistPayload,
  START,
} from './actions';
import { PIE_CHART_DEFAULT_CONTENT } from '../constants';
import {
  isContentReference,
  isMN,
  type MathContent,
  type PieOrStackedBarChartContent as PieChartContent,
  type Slice,
} from '@bettermarks/gizmo-types';
import { type PieChartState } from '../PieChartEditor';

export const initialState: PieChartState = {
  item: NaN,
  slices: [],
};

export type PieChartPayload =
  | PieChartMovePayload
  | PieChartStartPayload
  | PieChartPersistPayload
  | boolean;

/**
 * Check if the handle moved towards left
 * @param  {number} value
 * @return {boolean}
 */
export const isInLeftDirection = (value: number): boolean => value > 0;

/**
 * To update the slice label with the new value of the slice
 * @param  {Slice} slice to be updated
 * @return {Slice}
 */
export const updateSliceLabel = (slice: Slice): Slice =>
  !isNil(slice.sliceLabel)
    ? {
        ...slice,
        sliceLabel: isContentReference(slice.sliceLabel)
          ? slice.sliceLabel
          : {
              ...slice.sliceLabel,
              content: slice.sliceLabel.content.map((mContent: MathContent) =>
                isMN(mContent) ? { ...mContent, text: slice.value.toString() } : mContent
              ),
            },
      }
    : slice;

/**
 * Update the slice with the new value when the handle is moved
 * @param  {number} payload ie., the change in value
 * @param  {Slice} slice to be updated
 * @param  {boolean} is the slice on the left side of the handle
 * @param  {boolean} is the slice on the right side of the handle
 * @return {Slice}
 */
export const updateSlice = (
  payload: number,
  slice: Slice,
  isLeftSlice: boolean,
  isRightSlice: boolean
): Slice => {
  if (!isLeftSlice && !isRightSlice) {
    return slice;
  } else {
    const updatedSlice = {
      ...slice,
      value: slice.value + Math.pow(-1, Number(isLeftSlice)) * payload,
      severity: undefined,
    };
    return updateSliceLabel(updatedSlice);
  }
};

export const pieChartLocalReducer = handleActions<PieChartState, PieChartPayload>(
  {
    [END]: (state) => ({ ...state, item: NaN }),
    [MOVE]: (state, { payload }: Action<PieChartMovePayload>) => {
      if (isNil(payload)) {
        return state;
      }
      const { item, slices } = state;
      // slider is blocked by the edge of the bar at 5% distance
      const updateValue = isInLeftDirection(payload)
        ? slices[item - 1].value - payload >= 5
        : slices[item].value + payload >= 5;
      // i === item - 1 is the slice on the left of the handle
      // i === item is the slice on the right of the handle
      const updatedSlices = slices.map((slice, i) =>
        updateValue ? updateSlice(payload, slice, i === item - 1, i === item) : slice
      );
      return {
        ...state,
        slices: updatedSlices,
      };
    },
    [START]: (state, { payload }: Action<PieChartStartPayload>) =>
      !isNil(payload) ? { ...state, item: payload } : state,
  },
  initialState
);

export const pieChartReducer = handleActions<PieChartContent, PieChartPersistPayload>(
  {
    [PERSIST]: (state, { payload }: Action<PieChartPersistPayload>) =>
      payload ? { ...state, slices: payload } : state,
  },
  PIE_CHART_DEFAULT_CONTENT
);
