import {
  ArrowLeftLargeBold,
  MagnifierPlus,
  QuestionMarkCircle,
  ToolpadButtonReset,
  ToolpadButtonRubberRedBlue,
  ToolpadCursorIcon,
} from '../../../components/icons';
import * as React from 'react';
import styled from 'styled-components';
import { type Tool, type WhiteboardState } from '../types';
import { type WhiteboardAction } from '../reducer';
import { colors } from '@seriesplayer/common-ui';
import { Button } from './Button';
import { ToolButton } from './ToolButton';
import PenIcon from './PenIcon';
import { useTranslation } from 'react-i18next';
import { ToolbarLabel } from './ToolbarLabel';
import { DialogType } from '../../../types';
import ZoomPopup from './ZoomPopup';
import { useWhiteboardContext } from '../whiteboard-context';
import { TOOLBAR_BORDER, TOOLBAR_WIDTH } from './constants';
import { ContentToolIcon, getTooltipText } from '../tools/ContentToolIcon';
import { type WhiteboardMessage } from '../../iframe-posts';

interface IToolColumn {
  width: number;
}

type ToolButton = {
  tool: Tool;
  icon(whiteboardState: WhiteboardState, text?: string): JSX.Element;
  action: WhiteboardAction;
  titleKey(whiteboardState: WhiteboardState): string;
};

export const ToolColumn = styled.div<IToolColumn>`
  min-width: ${(props) => `${props.width}px`};
  max-width: ${(props) => `${props.width}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${colors.cGray100};
  border-right: ${TOOLBAR_BORDER}px solid ${colors.cGray500};
  box-sizing: border-box;
  padding-bottom: 12px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ButtonWrapperTop = styled.div`
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box;
`;

const IconWithLabelWrapper = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const toolsTop: ReadonlyArray<ToolButton> = [
  {
    tool: 'back',
    icon: (state, text) => (
      <IconWithLabelWrapper>
        <ArrowLeftLargeBold />
        <ToolbarLabel>{text}</ToolbarLabel>
      </IconWithLabelWrapper>
    ),
    action: {
      type: 'openDialog',
      dialogType: { type: DialogType.exitWhiteboardModeConfirmation },
    },
    titleKey: () => 'seriesplayer:dialog.whiteboard.exitButtonTooltipTitle',
  },
  {
    tool: 'magnifier',
    icon: (whiteboardState, text) => (
      <IconWithLabelWrapper>
        <MagnifierPlus />
        <ToolbarLabel>
          {whiteboardState.scaleConfig.scaleType === 'auto'
            ? text
            : `${Math.round(whiteboardState.scaleConfig.currentScale * 100)}%`}
        </ToolbarLabel>
      </IconWithLabelWrapper>
    ),
    action: { type: 'magnifier' },
    titleKey: () => 'seriesplayer:dialog.whiteboard.zoom.toolTipText',
  },
  {
    tool: 'help-menu',
    icon: () => <QuestionMarkCircle width={24} height={24} />,
    action: { type: 'openDialog', dialogType: { type: DialogType.whiteboardHelpMenu } },
    titleKey: () => 'seriesplayer:dialog.whiteboard.reportProblem.tooltipText',
  },
];

const toolsBottom: ReadonlyArray<ToolButton> = [
  /*{
    tool: 'fullscreen',
    icon: (whiteboardState) =>
      !whiteboardState.isFullScreen ? (
        <FullScreenIcon width={30} height={30} />
      ) : (
        <ExitFullScreenIcon width={30} height={30} />
      ),
    action: { type: 'toggleFullscreen' },
  },*/
  {
    tool: 'cursor',
    icon: () => <ToolpadCursorIcon width={30} height={30} />,
    action: { type: 'tool', payload: 'cursor' },
    titleKey: () => 'seriesplayer:dialog.whiteboard.cursorTool.tooltipText',
  },
  {
    tool: 'content-tools',
    icon: (whiteboardState) => (
      <ContentToolIcon editorModes={whiteboardState.contentToolConfig.modes} />
    ),
    action: { type: 'tool', payload: 'content-tools' },
    titleKey: (whiteboardState) => getTooltipText(whiteboardState.contentToolConfig.modes),
  },
  {
    tool: 'pen',
    icon: (whiteboardState) => <PenIcon color={whiteboardState.toolConfig.color} />,
    action: { type: 'tool', payload: 'pen' },
    titleKey: () => 'seriesplayer:dialog.whiteboard.penTool.tooltipText',
  },
  {
    tool: 'eraser',
    icon: () => <ToolpadButtonRubberRedBlue width={30} height={30} />,
    action: { type: 'tool', payload: 'eraser' },
    titleKey: () => 'seriesplayer:dialog.whiteboard.eraserTool.tooltipText',
  },
  // TODO: Deactivate until https://bettermarks.atlassian.net/browse/BM-58437
  //  is worked on.
  // {
  //   tool: 'undo',
  //   icon: () => <ToolpadButtonUndo width={30} height={30} />,
  //   action: { type: 'undo' },
  // },
  // {
  //   tool: 'redo',
  //   icon: () => <ToolpadButtonRedo width={30} height={30} />,
  //   action: { type: 'redo' },
  // },
  {
    tool: 'reset',
    icon: () => <ToolpadButtonReset width={30} height={30} />,
    action: { type: 'initiateReset' },
    titleKey: () => 'seriesplayer:dialog.whiteboardReset.tooltipText',
  },
];

type ToolbarProps = {
  id?: string;
};

export default function Toolbar({ id }: ToolbarProps) {
  const [t] = useTranslation();
  const { state, dispatch } = useWhiteboardContext();
  const activeTool = state.selectedTool;
  const zoomPopupActive = state.zoomPopupActive;
  const zoomButtonRef = React.useRef(null);
  const toolButtonsInitiallyActive = id === 'mockWhiteboard';
  const [toolButtonsActive, setToolButtonsActive] = React.useState(toolButtonsInitiallyActive);

  React.useEffect(() => {
    function handleReceiveMessage(e: MessageEvent<WhiteboardMessage>) {
      const data = e.data;
      if (data.type === 'contentLoaded') {
        setToolButtonsActive(true);
      }
    }
    window.addEventListener('message', handleReceiveMessage);
    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  });

  const getToolText = (tool: Tool): string | undefined => {
    const autoText = t('seriesplayer:dialog.whiteboard.autoButtonText');
    const backText = t('seriesplayer:dialog.whiteboard.exitButtonText');

    return tool === 'magnifier' ? autoText : tool === 'back' ? backText : undefined;
  };

  return (
    <ToolColumn width={TOOLBAR_WIDTH}>
      <ButtonWrapperTop>
        {toolsTop.map(({ tool, icon, action, titleKey }) => (
          <Button
            onClick={() => dispatch(action)}
            data-cy={`whiteboard-${tool}`}
            key={tool}
            title={t(titleKey(state))}
            ref={tool === 'magnifier' ? zoomButtonRef : undefined}
            selected={tool === 'magnifier' && zoomPopupActive}
          >
            {icon(state, getToolText(tool))}
          </Button>
        ))}
        {zoomPopupActive && (
          <ZoomPopup
            scale={state.scaleConfig.currentScale}
            show={zoomPopupActive}
            zoomButtonRef={zoomButtonRef}
          />
        )}
      </ButtonWrapperTop>
      {toolButtonsActive && (
        <div>
          {toolsBottom.map(
            ({ tool, icon, action, titleKey }) =>
              (tool !== 'content-tools' ||
                (state.contentToolConfig.modes.length > 1 &&
                  state.selectedNavigationItem === 'exercise')) && (
                <ToolButton
                  onClick={() => dispatch(action)}
                  selected={tool === activeTool}
                  data-cy={`whiteboard-${tool}`}
                  key={tool}
                  title={t(titleKey(state))}
                >
                  {icon(state)}
                </ToolButton>
              )
          )}
        </div>
      )}
    </ToolColumn>
  );
}
