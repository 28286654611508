import * as React from 'react';
import classNames from 'classnames';
import { Marker, MarkerType } from '../../../components';
import { TableOrientation as Orientation } from '@bettermarks/gizmo-types';
// did not shift this to gizmo-utils since it uses the vector calculus also contained in geo
import { shortenForCap } from '../../../geo/components';

import styles from './ContainerArrow.scss';

type ContainerArrowProps = {
  orientation: Orientation;
  arrowStart: boolean;
  arrowEnd: boolean;
  length?: number;
};

const height = 40;
const yOffset = 6;
const xOffset = 4;
const markerW = 10;
const markerH = 12;

const controlPts = (length: number): [number, number][] => [
  [xOffset, yOffset],
  [length * 0.5, height],
  [length - xOffset, yOffset],
];

const qBezier = (direction: string) => (length: number, arrowStart: boolean, arrowEnd: boolean) => {
  let pts =
    direction === 'horizontal'
      ? controlPts(length)
      : controlPts(length).map((cpt) => [cpt[1], cpt[0]] as [number, number]);

  if (arrowStart) {
    pts = shortenForCap(pts, 'top', markerW * 0.5);
  }
  if (arrowEnd) {
    pts = shortenForCap(pts, 'bottom', markerW * 0.5);
  }

  return `M${pts[0][0]},${pts[0][1]} Q${pts[1][0]},${pts[1][1]} ${pts[2][0]},${pts[2][1]}`;
};

const path: {
  [key in Orientation]: (length: number, arrowStart: boolean, arrowEnd: boolean) => string;
} = {
  [Orientation.south]: qBezier('horizontal'),
  [Orientation.east]: qBezier('vertical'),
  [Orientation.west]: qBezier('vertical'),
  [Orientation.north]: qBezier('horizontal'),
};

const isHorizontal = (o: Orientation) => o === Orientation.north || o === Orientation.south;

export const ContainerArrow: React.FC<ContainerArrowProps> = ({
  orientation,
  arrowStart,
  arrowEnd,
  length = 0,
}) => {
  const horizontal = isHorizontal(orientation);
  const markerProps = {
    type: MarkerType.EquiTriangle,
    offset: markerW * 0.5,
    width: markerW,
    height: markerH,
  };
  return (
    <svg
      className={classNames(styles.arc, {
        [styles.west]: orientation === Orientation.west,
        [styles.north]: orientation === Orientation.north,
      })}
      width={horizontal ? length : height}
      height={horizontal ? height : length}
    >
      <Marker {...markerProps} id={'end'} flip={false} />
      <Marker {...markerProps} id={'start'} flip={true} />
      <path
        d={path[orientation](length, arrowStart, arrowEnd)}
        markerEnd={arrowEnd ? 'url(#end)' : ''}
        markerStart={arrowStart ? 'url(#start)' : ''}
      />
    </svg>
  );
};
