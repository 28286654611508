import { type Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  ConfirmSubmitIncomplete,
  type ConfirmSubmitIncompleteDispatchProps,
  type ConfirmSubmitIncompleteOwnProps,
} from './ConfirmSubmitIncomplete';
import { openDialog } from '../../../../../apps/seriesplayer/containers/SeriesPlayer/actions';
import { submitIncompleteSeries } from '../../../../../apps/seriesplayer/reducers/applicationActions';

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: ConfirmSubmitIncompleteOwnProps
): ConfirmSubmitIncompleteDispatchProps => ({
  onConfirm: () => dispatch(submitIncompleteSeries()),
  onCancel: () => dispatch(openDialog({ type: ownProps.goBackDialog })),
});

export const ConfirmSubmitIncompleteContainer = connect(
  null,
  mapDispatchToProps
)(ConfirmSubmitIncomplete);
