import * as React from 'react';
import classNames from 'classnames';
import { CheckboxMedium, CheckboxCheckedMedium } from '../icons/index';

import styles from './styles.scss';
import { type BaseButton, OptionState } from './types';

/**
 * The `CheckBox` is a component for multiple choice multiple input options.
 *
 * ### Process State
 *
 * - Visuals: Working image tests
 * - Behaviour: None
 *
 * ### Properties
 | Name          | Type                                  | Default   | Description               |
 |---            |---                                    |---        |---                        |
 | `state`       | one of                                | Required  | interaction states        |
 |               | `['selected', 'unselected', 'error']` |           | the choice can have       |
 | `interactive` | `boolean`                             | Required  | mc option interactive for |
 |               |                                       |           | user?                     |
 */
const CheckBox: React.FC<BaseButton> = ({ state, interactive }) => {
  const style = classNames(styles[state], !interactive ? styles.disabled : '');

  return state === OptionState.unselected ? (
    <CheckboxMedium className={style} />
  ) : (
    <CheckboxCheckedMedium className={style} />
  );
};

CheckBox.displayName = 'CheckBox';

export default CheckBox;
