import * as React from 'react';
import classNames from 'classnames';
import styles from './DialogFooter.scss';

interface DialogFooterProps {
  align?: 'left' | 'center' | 'right';
}

export const DialogFooter: React.FC<DialogFooterProps> = ({ children, align = 'left' }) => (
  <footer className={classNames(styles.footer, styles[`align-${align}`])}>{children}</footer>
);
