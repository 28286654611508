import { Validity } from '@bettermarks/umc-kotlin';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { flatten } from 'lodash';
import { connect } from 'react-redux';
import {
  type AppExercise,
  type ApplicationState,
  DialogType,
  ExerciseValidity,
  type Series,
  SeriesMode,
  type SeriesScore,
  StarModeEnum,
  type EndscreenPayload,
} from '../../../../../types';
// Again a instance, where importing from `index.ts` led to circular dependency which caused
// undefined imports.
import { openDialog, quitSeriesPlayer } from '../../SeriesPlayer/actions';
import { ENDSCREEN_COINS_THRESHOLDS, EndscreenMode } from './constants';
import { Endscreen, type EndscreenDispatchProps, type EndscreenStateProps } from './Endscreen';

/* Calculate the points earned in a series. This function gets an array of exercises and returns
 * an object of type `SeriesScore`.
 *
 * The number of potential points is the total number of steps in all exercises × 2
 * In every step, a wrong answer gains 0 points, a correct answer on the first attempt gains 2
 * points, and an answer that had at least one mistake on one of its attempts gains 1 point.
 *
 * The specification can be found here:
 *
 * http://wiki.bm.loc/index.php/Acceptance_criteria_NSP#
 *                                                  User_wants_to_earn_points_when_solving_exercises
 *
 * http://trac.bm.loc/ticket/32042
 *
 * Note: This is usually calculated by the backend. This helper function is only called when the
 * server is not reachable for whatever reason.
 */
export const getSeriesScore = (exercises: ReadonlyArray<AppExercise>): SeriesScore => {
  const steps = flatten(exercises.map((exercise) => exercise.steps));

  const pointsMax = steps.length * 2;
  const pointsReached = steps.reduce((acc, step) => {
    if (step.validity === Validity.correct) {
      return step.numberOfErrors === 0 ? acc + 2 : acc + 1;
    } else {
      return acc;
    }
  }, 0);

  return {
    pointsMax,
    pointsReached,
    coinThresholds: ENDSCREEN_COINS_THRESHOLDS,
    starMode: pointsMax === pointsReached ? StarModeEnum.New : null,
  };
};

const ENDSCREEN_MODE = {
  [SeriesMode.practice]: EndscreenMode.practice,
  [SeriesMode.review]: EndscreenMode.message,
  [SeriesMode.preview]: EndscreenMode.message,
  [SeriesMode.test]: EndscreenMode.test,
};

export const getEndscreenMode = (series: Series) => {
  return series.seriesSettings && !series.seriesSettings.showResults
    ? EndscreenMode.message
    : ENDSCREEN_MODE[series.mode];
};

type EndscreenOwnProps = {
  payload: EndscreenPayload;
};

const mapStateToProps = (
  { series }: ApplicationState,
  ownProps: EndscreenOwnProps
): EndscreenStateProps => ({
  animate: ownProps.payload.animate,
  seriesScore: series.seriesScore ? series.seriesScore : getSeriesScore(series.exercises),
  mode: getEndscreenMode(series),
  numExercises: series.exercises.length,
  wrongExercises: series.exercises
    .map((e, i) => (e.validity !== ExerciseValidity.correct ? i : undefined))
    .filter((i): i is number => i !== undefined),
});

const mapDispatchToProps = (dispatch: Dispatch): EndscreenDispatchProps => ({
  onCloseEndscreen: () => dispatch(quitSeriesPlayer()),
  onExerciseOverview: () => dispatch(openDialog({ type: DialogType.exerciseOverview })),
});

export const EndscreenContainer = connect(mapStateToProps, mapDispatchToProps)(Endscreen);
