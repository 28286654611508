import {
  DEFAULT_CIRCLE_CONFIGURATION,
  EPS,
  eq,
  norm,
  roundNumber,
  screenToWorld,
  truncateIfTooManyZeros,
  worldToScreen,
} from '@bettermarks/importers';
import {
  type CircleConfiguration,
  type Coords,
  type GeoAddCircleState,
  type GeoScene,
  INVISIBLE,
  POINT,
  type SnapPoint,
  SnapType,
  type TickValueInterval,
} from '@bettermarks/gizmo-types';
import { getSnapPoint, getSnapPoints } from '../../snap';
import { SPACE } from '../constants';
import { effectiveRadius } from '../helpers';

/**
 * returns distance between 2 points
 *
 * @param {Coords} p1 first point
 * @param {Coords} p2 second point
 * @param {number} snapInterval
 *
 * @return {number}
 */
const getLengthInUnits = (p1: Coords, p2: Coords, snapInterval: number): number =>
  roundNumber(norm(p1, p2), snapInterval);

const getToolDisplayContent = (
  radius: number,
  { hideRadius, hideUnit, unit }: CircleConfiguration
): string =>
  hideRadius || radius === 0
    ? ''
    : `${truncateIfTooManyZeros(radius)}${!hideUnit ? `${SPACE}${unit}` : ''}`;

export const addPreviewCircle = (
  state: GeoAddCircleState,
  mouseP: Coords,
  matrix: number[],
  snapType: SnapType,
  scene: GeoScene,
  scale: number,
  tickValueInterval: TickValueInterval,
  circleConfiguration?: CircleConfiguration
): GeoAddCircleState => {
  const { coords: worldCoords } = state.prevCircle;
  if (worldCoords) {
    const screenCoords = worldToScreen(matrix)(worldCoords);
    let snapPoints: SnapPoint[] = [];
    const circleConfig = {
      ...DEFAULT_CIRCLE_CONFIGURATION,
      ...circleConfiguration,
    };
    let mousePWorld = screenToWorld(matrix)(mouseP);
    let radius = getLengthInUnits(worldCoords, mousePWorld, circleConfig.snapInterval);
    if (circleConfig.snapPoints) {
      // snap the circle to the nearest snap point
      const radiusScreen = getLengthInUnits(screenCoords, mouseP, circleConfig.snapInterval);
      snapPoints = getSnapPoints(
        matrix,
        { ...scene, snapType: SnapType.none },
        scale,
        radiusScreen
      )(screenCoords);
      const snapPoint = snapPoints.length > 0 ? snapPoints[0] : null;
      if (snapPoint && !eq(screenCoords, snapPoint)) {
        mousePWorld = screenToWorld(matrix)(snapPoint);
      }
      radius = norm(worldCoords, mousePWorld);
    } else {
      // To snap the circle to a point when the mouseP is same as a point
      const snapPoint = getSnapPoint(matrix, scene, scale)(mouseP);
      if (
        snapPoint &&
        (snapPoint.snapObject === POINT || snapPoint.snapObject === INVISIBLE) &&
        !eq(screenCoords, snapPoint)
      ) {
        mousePWorld = screenToWorld(matrix)(snapPoint);
        // calculate the new radius to the snap point
        radius = getLengthInUnits(worldCoords, mousePWorld, EPS);
        snapPoints = [snapPoint];
      }
    }

    radius = effectiveRadius(radius, tickValueInterval);

    return {
      ...state,
      prevCircle: {
        ...state.prevCircle,
        radius,
        visible: radius !== 0,
      },
      toolValueLabels:
        !circleConfig.hideRadius && radius !== 0
          ? [
              {
                content: getToolDisplayContent(radius, circleConfig),
                coords: state.prevCircle.coords,
              },
            ]
          : [],
      snapPoints,
    };
  }
  return state;
};
