import * as React from 'react';
import {
  LabelAlignDirection,
  type ToolValueLabel as ToolValueLabelType,
} from '@bettermarks/gizmo-types';
import { Label } from '.';

import styles from './ToolValueLabel.scss';

export type ToolValueLabelProps = ToolValueLabelType & {
  height: number;
  id: string;
  matrix: number[];
  width: number;
};

export const ToolValueLabel: React.FC<ToolValueLabelProps> = ({
  content,
  alternativeStyle,
  coords,
  height,
  id,
  matrix,
  width,
}) => (
  <Label
    {...{ content, height, id, matrix, width }}
    align={LabelAlignDirection.center}
    {...coords}
    distance={0}
    className={(alternativeStyle && styles.toolDisplayOrange) || styles.toolDisplayBlue}
  />
);

ToolValueLabel.displayName = 'ToolValueLabel';
