import { type Content } from '../../xml-converter/core';
import { SPECIAL } from '../../gizmo-utils/constants';
import * as RS from '../../gizmo-utils/configuration/renderStyles';
import { type Decoration } from '../../gizmo-utils/types';

export enum ValueStepperPosition {
  TOP = 'TOP',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
}

export interface IValueStepperConfiguration {
  readonly min: number | string;
  readonly max: number | string;
  readonly step: number;
  readonly position: ValueStepperPosition;
  readonly initiallyActive?: true;
}

export interface IValueStepperBase {
  readonly value: number;
  readonly binding: string;
}

export interface IValueStepper extends IValueStepperBase {
  readonly configuration: IValueStepperConfiguration;
  readonly decoration: Decoration;
}

export type ValueStepperReferenceContent = Readonly<Content>;

export const VALUE_STEPPER_REFERENCE_DEFAULT_CONTENT: ValueStepperReferenceContent = {
  $: SPECIAL,
  $renderStyle: RS.VALUE_STEPPER,
  $interactionType: RS.VALUE_STEPPER,
};
