import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Measure, { type ContentRect } from 'react-measure';
import dimensions from '../../sass/dimensions';
import { StyledCollapseButton } from './CollapseButton';
import { StyledSectionHeader } from './SectionHeader';
import { type CollapsibleSectionProps, CSInitialMode, CSModeFromState } from './types';
import { isAnimated, isCollapsible, isOpen } from './utils';
import * as themes from './themes';

const Content = styled.div<{ isAnimated: boolean }>`
  overflow-y: hidden;
  & > div {
    padding: ${(props) => (!props.title ? dimensions.spaceXs : 0)} ${dimensions.spaceXs}
      ${dimensions.spaceXs};
  }
  ${(props) =>
    props.isAnimated &&
    `
    transition: max-height 0.6s;
  `}
`;

const CollapsibleSectionContainer = styled.div`
  border: ${dimensions.borderWidthL} solid ${(props) => props.theme.borderColor};
  border-radius: ${dimensions.borderRadiusM};

  // When printing out the series in review mode (https://bettermarks.atlassian.net/browse/BM-50115)
  // we do not show explanations
  @media print {
    display: none;
  }
`;

/**
 * CollapsibleSection is rendered into one of the following modes initially:
 * `open` (default), `openAnimated` or `collapsed`.
 * When there is a `title` and `collapsible` is `true` there will be a button
 * that allows the user to toggle between open and closed mode.
 * Toggling by the user is always animated.
 *
 * ### Properties
 | Name          | Type            | Default     | Description                                     |
 |---            | ---             |---          | ---                                             |
 | `kind`        | `SectionKind`   | Required    | Coloring CollapsibleSection                     |
 | `collapsible` | `boolean`       | Required    | If true, component can be collapsed/expanded    |
 | `title`       | `string`        | `undefined` | Header text, mandatory to allow collapsible     |
 | `initialMode` | `CSInitialMode` | `open`      | The initial mode to render in                   |
 | `innerStyle`  | `CSSProperties` | `undefined` | CSS styles applied to the <div> around children |
 */
export const CollapsibleSection: React.FC<CollapsibleSectionProps> = (props) => {
  const {
    initialMode = CSInitialMode.open,
    title,
    children,
    innerStyle,
    kind,
    headerAsToggle = true,
  } = props;

  const [current, setCurrent] = React.useState<CSInitialMode | CSModeFromState>(() =>
    !isCollapsible(props) && !isOpen(initialMode) ? CSInitialMode.open : initialMode
  );

  const [contentHeight, setContentHeight] = React.useState(0);

  const onMeasure = React.useCallback(
    ({ scroll }: ContentRect) => {
      if (scroll && scroll.height !== undefined) {
        setContentHeight(scroll.height);
      }
    },
    [setContentHeight]
  );

  const toggleCollapsed = React.useCallback(() => {
    setCurrent(
      isOpen(current) ? CSModeFromState.collapsedToggleAnimated : CSModeFromState.openToggleAnimated
    );
  }, [current]);

  const collapsed = !isOpen(current);

  const targetHeight = collapsed ? 0 : contentHeight;
  const _isCollapsible = isCollapsible(props);

  return (
    <ThemeProvider theme={themes[kind]}>
      <CollapsibleSectionContainer>
        {title && (
          <StyledSectionHeader
            title={title}
            onClick={headerAsToggle && _isCollapsible ? toggleCollapsed : undefined}
          >
            {_isCollapsible && (
              <StyledCollapseButton
                collapsed={collapsed}
                onClick={!headerAsToggle ? toggleCollapsed : undefined}
              />
            )}
          </StyledSectionHeader>
        )}
        <Content isAnimated={isAnimated(current)} title={title} style={{ maxHeight: targetHeight }}>
          <Measure scroll onResize={onMeasure}>
            {({ measureRef }) => (
              <div ref={measureRef} style={innerStyle}>
                {children}
              </div>
            )}
          </Measure>
        </Content>
      </CollapsibleSectionContainer>
    </ThemeProvider>
  );
};
