import { type ExporterRegistry } from '../../gizmo-utils/configuration';
import { type Content, type ContentDict } from './types';

export type Exporter = (contentRefId: string, context: ExporterContext) => string;

export interface ExporterContext {
  readonly exporters: ExporterRegistry;
  readonly content: ContentDict; // maps $refids to Content
}

export const createExporterContext = (
  exporters: ExporterRegistry = {},
  content: ContentDict = {}
): ExporterContext => ({ exporters, content });

/**
 * A helper to export a single `Content` without `ContentReference`s using a specific `Exporter`.
 *
 * @param exporter the exporter to pass the content to
 * @param content the content to convert to XML
 *
 * @returns An XML string representation of the content.
 */
export const exportSingleContent = <T extends Content = Content>(
  exporter: Exporter,
  content: T
) => {
  const refId = '__test__';
  const context = createExporterContext({}, { [refId]: content });
  return exporter(refId, context);
};
