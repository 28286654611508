import { isNil } from 'lodash';
import {
  type LabelObject,
  type LabelValuesMap,
  type GeoObjectType,
  type GeoContentPersistProps,
} from '@bettermarks/gizmo-types';
import { Hover } from '@bettermarks/gizmo-types';
import { isUserLabel } from '../../addlabel/helpers';
import { unHoverVertices } from './unHoverVertices';
import { getIncidentEdgesRecursively } from './getIncidentEdgesRecursively';

/**
 * TODO: Document what this fn do... But what this fn actually do?
 * FIXME: too many arguments??!
 */
const hoverLabelContent = (
  labelValues: LabelValuesMap,
  label: LabelObject,
  hover: Hover | undefined,
  refType: GeoObjectType
  // eslint-disable-next-line @typescript-eslint/ban-types
): Pick<LabelObject, 'content'> | {} =>
  !isNil(label.activeIndex) && label.content.hasOwnProperty('$refid')
    ? {
        content: (hover === Hover.DELETE
          ? labelValues[refType].errorLabels
          : labelValues[refType].geoLabels)[label.activeIndex],
      }
    : {};

/**
 * Given a GeoContentMap and a starting vertexID it returns a new immutable copy
 * of GeoContentMap where all of the incident edges (recursively) have been set
 * to an hovered for deletion state
 *
 * FIXME: this fn is still being called in too many different ways...
 */
export const hoverIncidentEdges = <
  GeoContentMap extends GeoContentPersistProps['geoContentMap'],
  VertexID extends Extract<keyof GeoContentMap, string>
>(
  geoContentMap: GeoContentMap,
  vertexID: VertexID,
  hover: undefined | Hover,
  labelValues?: LabelValuesMap
): GeoContentMap => {
  /**
   * make sure that the graph state is consistently un-hovered before applying a
   * new hover state
   */
  const unHoveredGeoContentMap: GeoContentMap =
    hover === Hover.DELETE ? unHoverVertices(geoContentMap) : geoContentMap;

  const vertexIDsToBeHovered = getIncidentEdgesRecursively(unHoveredGeoContentMap, vertexID);

  return vertexIDsToBeHovered.reduce((geoGraph, vertexID) => {
    const currentVertex = geoGraph[vertexID];

    return {
      ...geoGraph,
      [vertexID]: {
        ...currentVertex,
        hover,
        ...((labelValues &&
          isUserLabel(currentVertex) &&
          currentVertex.refid &&
          hoverLabelContent(
            labelValues,
            currentVertex,
            hover,
            unHoveredGeoContentMap[currentVertex.refid].type
          )) as LabelObject),
      },
    };
  }, unHoveredGeoContentMap);
};
