import * as React from 'react';

export const ExcludedFromTabNavigationButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => (
  <button {...props} tabIndex={-1}>
    {props.children}
  </button>
);
export const ExcludedFromTabNavigationLink = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>
) => (
  <a {...props} tabIndex={-1}>
    {props.children}
  </a>
);
