import * as React from 'react';
import classNames from 'classnames';
import Measure, { type ContentRect } from 'react-measure';
import { type GizmoNode, type Size } from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo/PolymorphicGizmo';

import styles from './graph.scss';

export type SizeCallback = (size: Size) => void;

export interface GizmoNodeProps {
  availableWidth: number;
  node: GizmoNode;
  border: boolean;
  onNodeSize?: SizeCallback;
}

const mapToMeasure =
  (cb?: SizeCallback) =>
  (rect: ContentRect): void =>
    rect.client && cb && cb({ width: rect.client.width, height: rect.client.height });

/* eslint-disable-next-line complexity*/
export const GizmoNodeComponent: React.FC<GizmoNodeProps> = ({
  availableWidth,
  onNodeSize,
  node: { content, position, size = { width: 0, height: 0 } },
  border,
}) => {
  const [sizeState, setSize] = React.useState<Size>({ width: 0, height: 0 });

  if (
    onNodeSize &&
    size.width === 0 &&
    sizeState.width !== 0 &&
    size.height === 0 &&
    sizeState.height !== 0
  ) {
    // resend the stored size if we get an "empty" size after validation
    onNodeSize(sizeState);
  } else if (
    size.width !== 0 &&
    size.height !== 0 &&
    (sizeState.width !== size.width || sizeState.height !== size.height)
  ) {
    // store size for later use
    setSize(size);
  }

  return content && position ? (
    <Measure client onResize={mapToMeasure(onNodeSize)}>
      {({ measureRef }) => (
        <div
          ref={measureRef}
          className={classNames(styles.gizmoNode, { [styles.border]: border })}
          style={{
            transform: `translate(
              ${Math.round(position.x - 0.5 * sizeState.width)}px,
              ${Math.round(position.y - 0.5 * sizeState.height)}px
            )`,
          }}
        >
          <PolymorphicGizmo refid={content && content.$refid} availableWidth={availableWidth} />
        </div>
      )}
    </Measure>
  ) : (
    <div />
  );
};

GizmoNodeComponent.displayName = 'GizmoNode';
