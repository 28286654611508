/**
 * EM (Explanation Module)
 *
 * @see src/xml-converter/EM/README.md
 */
import {
  type Content,
  type ContentDict,
  type ContentReference,
  type Lens,
} from '@bettermarks/gizmo-types';
import { type StateWithHistory } from 'redux-undo';
import { type CurrentContent } from './ApplicationState';
import { get, set } from 'lodash/fp';

/**
 * The KEM object, containing one or more pages.
 *
 * See: http://gitlab.bm.loc/bettermarks/bm_generator/blob/master/
 *                                     src/com/bettermarks/mathcore/kfem/generator/model/KEMModel.as
 */
export type KEMContent = Readonly<
  Content & {
    $renderStyle: 'kem';
    id: string;
    teaser: ContentReference;
    pages: ReadonlyArray<EM.Page>;
  }
>;

export const isKEMContent = (c: Content | undefined): c is KEMContent =>
  c !== undefined && c.$renderStyle === 'kem';

/**
 * The FEM object, containing one or more Elements.
 *
 * See: http://gitlab.bm.loc/bettermarks/bm_generator/blob/master/
 *                                     src/com/bettermarks/mathcore/kfem/generator/model/KEMModel.as
 */
export type FEMContent = Readonly<
  Content & {
    $renderStyle: 'fem';
    id: string;
    name: string;
    elements: EM.ElementList;
  }
>;

export const isFEMContent = (c: Content | undefined): c is FEMContent =>
  c !== undefined && c.$renderStyle === 'fem';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EM {
  export const enum ElementType {
    Content = 'Content',
    Comment = 'Comment',
    Rule = 'Rule',
    ExampleGroup = 'ExampleGroup',
    FreeExample = 'FreeExample',
    StructuredExample = 'StructuredExample',
  }

  type ContentElement<Type> = Readonly<{
    type: Type;
    content: ContentReference;
  }>;
  export type ElementContent = ContentElement<ElementType.Content>;
  export type ElementComment = ContentElement<ElementType.Comment>;
  export type ElementRule = ContentElement<ElementType.Rule>;

  export type ElementFreeExample = Readonly<{
    type: ElementType.FreeExample;
    title?: string;
    content: ReadonlyArray<ElementContent | ElementComment /* | Title */>;
  }>;

  export type ElementStructuredExample = Readonly<{
    type: ElementType.StructuredExample;
    title?: string;
    setting?: ContentReference;
    steps?: ReadonlyArray<ElementStructuredExampleStep>;
    overview?: ContentReference;
  }>;

  export type ElementStructuredExampleStep = Readonly<{
    title: string;
    /**
     * Lösungsweg
     */
    comment?: ElementComment;
    /**
     * The answer
     */
    result: ElementContent;
  }>;

  export type ElementExample = ElementFreeExample | ElementStructuredExample;

  export type ElementExampleGroup = Readonly<{
    type: ElementType.ExampleGroup;
    iq: boolean;
    heading?: ContentReference;
    /**
     * Every example is in a box with the color of explanation
     */
    examples: ReadonlyArray<ElementExample>;
  }>;

  export type ElementList = ReadonlyArray<
    ElementContent | ElementComment | ElementRule | ElementExampleGroup
  >;

  /**
   * A single KEM page object.
   *
   * See: http://gitlab.bm.loc/bettermarks/bm_generator/blob/master/
   *                               src/com/bettermarks/mathcore/kfem/generator/model/KEMPageModel.as
   */
  export type Page = Readonly<{
    id: string;
    title: string;
    elements: ElementList;
  }>;

  export const toEMContentDict = (path: string): Lens<EMData, ContentDict> => {
    const pathToPresent = path.split(':')[0] + '.present';

    return {
      get: (emData) => get(pathToPresent, emData),
      set: (contentDict) => (emData) => set(pathToPresent, contentDict, emData),
    };
  };
}

export type EMPlayerQueryString = {
  staticUrl?: string;
  test?: boolean;
  inBook?: boolean;
  forWhiteboard?: boolean;
};

export const enum EMStatus {
  loaded = 'loaded',
  error = 'error',
}

export type KEM = Readonly<{
  data?: StateWithHistory<ContentDict>;
  status?: EMStatus;
}>;

export type FEMs = Readonly<{
  data?: { [id: string]: StateWithHistory<ContentDict> };
  status?: EMStatus;
}>;

export type EMData = {
  fems: FEMs;
  kem: KEM;
  currentContent?: CurrentContent;
};
