import { type Severity } from '@bettermarks/umc-kotlin';
import { decorationAttribute } from '../../../gizmo-utils/decoration';
import * as T from '@bettermarks/gizmo-types';
import {
  type Decoration,
  exportContent,
  type ExporterContext,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { isEmpty, isUndefined, kebabCase } from 'lodash';
import log from 'loglevel';
import { revertToFlashFontSize } from '../fontSize';

export const exportDecoration = (
  deco: Decoration | undefined,
  convertFontSize = true,
  severity?: Severity | undefined
) => {
  const { marginLeft, marginRight, ...decoration } = deco
    ? deco
    : { marginLeft: '', marginRight: '' };
  return decorationAttribute(
    convertFontSize ? revertToFlashFontSize(decoration) : decoration,
    kebabCase,
    severity
  );
};

type FormulaElementExporter = (
  m: T.PureMathContent,
  context: ExporterContext,
  withConvertedFontSize: boolean
) => string;

export const exportMathContent = (
  mathContent: T.MathContent,
  context: ExporterContext,
  withConvertedFontSize = true
): string => {
  if (!T.isMathContent(mathContent)) {
    log.warn({
      message: 'Trying to export invalid mathContent',
      extra: { mathContent: JSON.stringify(mathContent) },
    });
    return '<mrow/>';
  }
  return T.isPureMathContent(mathContent)
    ? exportPureMathContent(mathContent, context, withConvertedFontSize)
    : exportContent(mathContent.$refid, context);
};

const exportMRow = (
  { children, decoration, femLink, interactive }: T.MRow,
  context: ExporterContext,
  withConvertedFontSize = true
): string => {
  const exported = children.map((c) => exportMathContent(c, context, withConvertedFontSize));
  if (!femLink && isEmpty(decoration) && exported.length === 1) {
    return exported[0];
  }

  const femLink$ = femLink
    ? ` xmlns:xl="http://www.w3.org/1999/xlink" xl:href="fem:${femLink}"`
    : '';
  return `<${T.$MROW} ${exportDecoration(decoration, withConvertedFontSize)}${femLink$}>
    ${exported.join('')}
  </${T.$MROW}>`;
};

const exportMCal = (m: T.MCalibrate): string =>
  `<${T.$MCAL}${xmlAttr('height', m.height)}${xmlAttr('refLine', m.refLine)}/>`;

const exportMToken = (
  m: T.MToken,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${m.$} ${xmlAttr('type', m.type)} ${exportDecoration(m.decoration, withConvertedFontSize)} ${
    m.id ? `id='${m.id}'` : ''
  }>${m.text.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;')}</${m.$}>`;

const exportMO = (m: T.MToken, context: ExporterContext, withConvertedFontSize: boolean): string =>
  `<${T.$MO} ${xmlAttr('form', m.form)} ${xmlAttr('type', m.type)} ${exportDecoration(
    m.decoration,
    withConvertedFontSize
  )}>${m.text}</${T.$MO}>`;

const exportMExpansion = (
  m: T.MExpansion,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MEXPANSION} ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${exportMathContent(m.numeratorFactor, context, withConvertedFontSize)}
    ${exportMathContent(m.lhsFraction, context, withConvertedFontSize)}
    ${exportMathContent(m.operator, context, withConvertedFontSize)}
    ${exportMathContent(m.rhsFraction, context, withConvertedFontSize)}
    ${exportMathContent(m.denominatorFactor, context, withConvertedFontSize)}
  </${T.$MEXPANSION}>`;

const exportMFenced = (
  m: T.MFenced,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MFENCED}${
    // undefined and empty string have different meaning
    // so e need to export accordingly, only undefined can be ommited
    xmlAttr('open', m.open, isUndefined)
  }${xmlAttr('close', m.close, isUndefined)}${exportDecoration(m.decoration)}>${m.children
    .map((c) => exportMathContent(c, context, withConvertedFontSize))
    .join('')}</${T.$MFENCED}>`;

const exportMAbs = (m: T.MAbs, context: ExporterContext, withConvertedFontSize: boolean): string =>
  `<${T.$MFENCED} open="|" close="|" ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${m.value.children.map((c) => exportMathContent(c, context, withConvertedFontSize)).join('')}
  </${T.$MFENCED}>`;

const exportMFrac = (
  m: T.MFrac,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MFRAC} ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${exportMRow(m.numerator, context, withConvertedFontSize)}
    ${exportMRow(m.denominator, context, withConvertedFontSize)}
  </${T.$MFRAC}>`;

const exportMSqrt = (
  m: T.MSqrt | T.MRoot,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MSQRT} ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${exportMRow(m.radicand, context, withConvertedFontSize)}
  </${T.$MSQRT}>`;

const exportMRoot = (
  m: T.MRoot,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  m.interactive && m.index.children.length === 0
    ? exportMSqrt(m, context, withConvertedFontSize)
    : `<${T.$MROOT} ${exportDecoration(m.decoration, withConvertedFontSize)}>
      ${exportMRow(m.radicand, context, withConvertedFontSize)}
      ${exportMRow(m.index, context, withConvertedFontSize)}
    </${T.$MROOT}>`;

const exportMSpace = (m: T.MSpace): string =>
  `<${T.$MSPACE}${!isUndefined(m.width) ? ` width="${m.width}"` : ''}${
    !isUndefined(m.height) ? ` height="${m.height}"` : ''
  }${!isUndefined(m.linebreak) ? ` linebreak="${m.linebreak}"` : ''}/>`;

const exportMSub = (m: T.MSub, context: ExporterContext, withConvertedFontSize: boolean): string =>
  `<${T.$MSUB} ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${exportMRow(m.base, context, withConvertedFontSize)}
    ${exportMRow(m.subscript, context, withConvertedFontSize)}
  </${T.$MSUB}>`;

const exportMSubSup = (
  m: T.MSubSup,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  // order of children is similar to flex client and differs with standard MathML
  `<${T.$MSUBSUP} ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${exportMRow(m.base, context, withConvertedFontSize)}
    ${exportMRow(m.superscript, context, withConvertedFontSize)}
    ${exportMRow(m.subscript, context, withConvertedFontSize)}
  </${T.$MSUBSUP}>`;

const exportMSup = (m: T.MSup, context: ExporterContext, withConvertedFontSize: boolean): string =>
  `<${T.$MSUP} ${exportDecoration(m.decoration, withConvertedFontSize)}>
    ${exportMRow(m.base, context, withConvertedFontSize)}
    ${exportMRow(m.superscript, context, withConvertedFontSize)}
  </${T.$MSUP}>`;

const exportMUnder = (
  m: T.MUnder,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MUNDER}${xmlAttr('accent', m.accent)}${exportDecoration(
    m.decoration,
    withConvertedFontSize
  )}>
    ${exportMRow(m.base, context, withConvertedFontSize)}
    ${exportMRow(m.underscript, context, withConvertedFontSize)}
  </${T.$MUNDER}>`;

const exportMOver = (
  m: T.MOver,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MOVER}${xmlAttr('accent', m.accent)}${exportDecoration(
    m.decoration,
    withConvertedFontSize
  )}>
    ${exportMRow(m.base, context, withConvertedFontSize)}
    ${exportMRow(m.overscript, context, withConvertedFontSize)}
  </${T.$MOVER}>`;

const exportMUnderOver = (
  m: T.MUnderOver,
  context: ExporterContext,
  withConvertedFontSize: boolean
): string =>
  `<${T.$MUNDEROVER}${xmlAttr('accent', m.accent)}${exportDecoration(
    m.decoration,
    withConvertedFontSize
  )}>
    ${exportMRow(m.base, context, withConvertedFontSize)}
    ${exportMRow(m.underscript, context, withConvertedFontSize)}
    ${exportMRow(m.overscript, context, withConvertedFontSize)}
  </${T.$MUNDEROVER}>`;

const EXPORT_MATHML_MAP: { [k: string]: FormulaElementExporter } = {
  [T.$CURSOR]: () => '',
  [T.$MABS]: exportMAbs,
  [T.$MCAL]: exportMCal,
  [T.$MEXPANSION]: exportMExpansion,
  [T.$MFENCED]: exportMFenced,
  [T.$MFRAC]: exportMFrac,
  [T.$MI]: exportMToken,
  [T.$MN]: exportMToken,
  [T.$MO]: exportMO,
  [T.$MOVER]: exportMOver,
  [T.$MROOT]: exportMRoot,
  [T.$MROW]: exportMRow,
  [T.$MSPACE]: exportMSpace,
  [T.$MSQRT]: exportMSqrt,
  [T.$MSUB]: exportMSub,
  [T.$MSUBSUP]: exportMSubSup,
  [T.$MSUP]: exportMSup,
  [T.$MTEXT]: exportMToken,
  [T.$MUNDER]: exportMUnder,
  [T.$MUNDEROVER]: exportMUnderOver,
};

export function exportPureMathContent(
  content: T.PureMathContent,
  context: ExporterContext,
  withConvertedFontSize = true
): string {
  return !(content.$ !== null && content.$ in EXPORT_MATHML_MAP)
    ? '<missing-math-content-exporter/>'
    : EXPORT_MATHML_MAP[content.$](content, context, withConvertedFontSize);
}
