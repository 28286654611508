import * as React from 'react';
import classNames from 'classnames';

import { type ContextState, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { type ALayoutContent, HAlign, VAlign } from '@bettermarks/gizmo-types';
import { ShadowScrollbars } from '../../components';

import styles from './ALayout.scss';

export type ALayoutProps = ALayoutContent & ContextState;

export const ALIGN_TO_TRANSLATE = {
  [`${HAlign.left}-${VAlign.top}`]: 'translate(-100%, -100%)',
  [`${HAlign.left}-${VAlign.middle}`]: 'translate(-100%, -50%)',
  [`${HAlign.left}-${VAlign.bottom}`]: 'translate(-100%, 0%)',
  [`${HAlign.center}-${VAlign.top}`]: 'translate(-50%, -100%)',
  [`${HAlign.center}-${VAlign.middle}`]: 'translate(-50%, -50%)',
  [`${HAlign.center}-${VAlign.bottom}`]: 'translate(-50%, 0%)',
  [`${HAlign.right}-${VAlign.top}`]: 'translate(0%, -100%)',
  [`${HAlign.right}-${VAlign.middle}`]: 'translate(0%, -50%)',
  [`${HAlign.right}-${VAlign.bottom}`]: 'none',
};

/**
 * Logs mouse position
 *
 * Used for debug configuration of absolute layout (convenience feature for content development)
 * using loglevel would prevent this information from being displayed in devtools for ConDev
 */
const logMousePos = (evt: React.MouseEvent<any>) => {
  const bounds = evt.currentTarget.getBoundingClientRect();
  /* eslint-disable-next-line no-console */
  console.log('Debug Mouse Coordinates: ', evt.clientX - bounds.left, evt.clientY - bounds.top);
};

export const ALayout: React.FC<ALayoutProps> = ({
  availableWidth,
  content,
  unscaledWidth,
  unscaledHeight,
  debug,
  noScale,
  scale,
}) => {
  const style = {
    width: unscaledWidth * scale,
    height: unscaledHeight * scale,
  };

  const aLayout = (
    <div
      className={classNames(styles.container, { [styles.debug]: debug })}
      style={style}
      {...(debug && { onMouseDown: logMousePos })}
    >
      {content.map((c, i) => (
        <div
          className={classNames(styles.child, {
            [styles.formula]: c.isFormula,
          })}
          style={{
            top: c.pos.y * scale,
            left: c.pos.x * scale,
            transform: ALIGN_TO_TRANSLATE[`${c.hAlign}-${c.vAlign}`],
          }}
          key={i}
        >
          <PolymorphicGizmo
            refid={c.$refid}
            availableWidth={c.scalable ? c.unscaledWidth * scale : style.width}
          />
        </div>
      ))}
    </div>
  );

  return noScale ? (
    <ShadowScrollbars availableWidth={availableWidth}>{aLayout}</ShadowScrollbars>
  ) : (
    aLayout
  );
};

ALayout.displayName = 'ALayout';
