import * as React from 'react';
import classNames from 'classnames';
import { type DrawerProps, getDrawerStyleForState } from './Drawer';
import rootStyles from './AsideDrawer.scss';

export const enum Side {
  right = 'right',
  left = 'left',
}

export const AsideDrawer: React.FC<DrawerProps> = (props) => {
  const { state, style, styles, children } = props;

  return (
    <div
      style={style}
      className={classNames(
        rootStyles.asideDrawer,
        styles.drawer,
        getDrawerStyleForState(state, styles)
      )}
    >
      {children}
    </div>
  );
};
