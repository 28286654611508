import * as React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { CollapsibleSection, CSInitialMode, SectionKind } from '@seriesplayer/common-ui';
import {
  ContentRoot,
  type ContentRootCommonProps,
} from '../../../../gizmo-utils/polymorphic-gizmo';
import { CollapsibleContentState } from '../../../../types';

import { BORDER_COLLAPSIBLE, PADDING_COLLAPSIBLE, VERY_SMALL_SCREEN_WIDTH } from '../../constants';

import styles from './contentBox.scss';

export enum CollapsibleContentStyle {
  answer = 'answer',
  explanation = 'explanation',
  hint = 'hint',
  inactive = 'inactive',
  wrapup = 'wrapup',
  support = 'support',
  none = 'none',
}

export type CollapsibleContentBoxProps = ContentRootCommonProps & {
  collapse?: CollapsibleContentState;
  collapsibleKind?: CollapsibleContentStyle;
  collapsible?: boolean;
  title?: string;
  inactive?: boolean;
  switched?: boolean;
  fitToContent?: boolean;
  topBottomMargin?: boolean;
  dataCy?: string;
};

const kindMap = {
  [CollapsibleContentStyle.explanation]: SectionKind.neutral,
  [CollapsibleContentStyle.answer]: SectionKind.positive,
  [CollapsibleContentStyle.wrapup]: SectionKind.positive,
  [CollapsibleContentStyle.support]: SectionKind.supportive,
  [CollapsibleContentStyle.hint]: SectionKind.supportive,
  [CollapsibleContentStyle.inactive]: SectionKind.inactive,
};

/**
 * This component provides a common interface
 * to display any ContentDict containing a single content tree, e.g.
 * - Setting
 * - Feedback
 * - Question
 * - Answer
 * - Explanation
 * - Wrapup
 * Optional Props:
 * - collapsible: By default, all contentbox are collapsible unless explicitly stated.
 * - topBottomMargin: By default, always keep the margins. Set to false when used inside any
 * other component to control margins.
 */
export const ContentBox: React.FC<CollapsibleContentBoxProps> = ({
  contentDict,
  collapsibleKind = CollapsibleContentStyle.none,
  collapse,
  collapsible = true,
  dispatch,
  gizmoRegistry,
  hideHelpTools,
  availableWidth,
  inactive,
  switched,
  title,
  isTouch,
  selectedGizmoRef,
  staticMediaUrl,
  fitToContent = false,
  topBottomMargin = true,
  dataCy,
}) => {
  React.useEffect(() => {
    if (availableWidth < VERY_SMALL_SCREEN_WIDTH) {
      document.body.style.overflowX = 'hidden';
    }

    return () => {
      document.body.style.overflowX = '';
    };
  }, [availableWidth]);

  if (isEmpty(contentDict)) {
    return null;
  }

  const derivedWidth =
    collapsibleKind === CollapsibleContentStyle.none
      ? availableWidth
      : availableWidth - 2 * PADDING_COLLAPSIBLE - 2 * BORDER_COLLAPSIBLE;

  if (derivedWidth <= 0) {
    // avoid availableWidth with negative value or 0
    // since it can cause some strange values in measurement
    // e.g. when dialogs are opened that need a measure cycle before having a real availableWidth
    return null;
  }

  const contentRoot = (
    <ContentRoot
      gizmoRegistry={gizmoRegistry}
      contentDict={contentDict}
      dispatch={dispatch}
      hideHelpTools={hideHelpTools}
      availableWidth={derivedWidth}
      isTouch={isTouch}
      selectedGizmoRef={selectedGizmoRef}
      staticMediaUrl={staticMediaUrl}
    />
  );

  if (collapse === CollapsibleContentState.hidden) {
    return null;
  } else {
    const mode =
      collapse === CollapsibleContentState.collapsed
        ? CSInitialMode.collapsed
        : switched
        ? CSInitialMode.openAnimated
        : CSInitialMode.open;
    const contentBox =
      collapsibleKind === CollapsibleContentStyle.none ? (
        contentRoot
      ) : (
        <CollapsibleSection
          collapsible={collapsible}
          title={title}
          kind={inactive ? SectionKind.inactive : kindMap[collapsibleKind]}
          initialMode={mode}
        >
          {contentRoot}
        </CollapsibleSection>
      );
    const contentCls = classNames({
      [styles.content]: topBottomMargin,
      [styles.contentFit]: fitToContent,
    });
    return (
      <div data-cy={dataCy} className={contentCls}>
        {contentBox}
      </div>
    );
  }
};

ContentBox.displayName = 'ContentBox';
