// eslint-disable-next-line no-useless-escape
import {
  Severity,
  ValidatorEngine,
  type ValidatorExercise,
  type ValidatorRequest,
  type ValidatorResponse,
  type ValidatorStep,
  Validity,
} from '@bettermarks/umc-kotlin';
import {
  type ExtendedValidatorResponse,
  type NlpValidatorResponse,
} from './containers/Series/ExtendedValidatorResponse';
import {
  AppExercise,
  ApplicationState,
  type ApplicationStatus,
  type AppStep,
  ExerciseStatus,
  Series,
  SeriesMode,
  SeriesStatus,
  StepStatus,
} from '../../types';
import { isNil, pick } from 'lodash';
import { ContentDict, Lens } from '@bettermarks/gizmo-types';
import { STUBABLE, toValidatorSteps } from './validationHelpers';
import { exporters } from '@bettermarks/importers';
import { compose, curry, identity } from 'lodash/fp';
import { notifyDeselectedGizmo, notifySelectedGizmo, openKeyboardDrawer } from './selectHelpers';

/* eslint-disable-next-line no-useless-escape */
const localeRegex = new RegExp(`#\/?([a-z]{2}_[A-Z]{2}(_[^/]*)?)\/.*`, 'g');

// for lobLinks we need to retrieve the appLocale from the path
// TODO: convert this fn to return a Maybe of string
export const lobLinkLocale = (path: string): undefined | string =>
  path.match(localeRegex)
    ? path.replace(localeRegex, '$1') // string
    : undefined;
export const getApplicationStatus = (
  response: ValidatorResponse | NlpValidatorResponse,
  series: Series,
  exercise: AppExercise,
  exerciseIndex: number,
  step: AppStep
): ApplicationStatus => {
  const mode = Series.toSeriesMode.get(series);
  const applicationStatus: ApplicationStatus = {
    seriesStatus: series.seriesStatus,
    exerciseStatus: exercise.status as ExerciseStatus,
    stepStatus: step.status as StepStatus,
  };

  if (isNil(response.nextStep)) {
    if (mode === SeriesMode.test) {
      // Series is already set to completed in handInSaga

      // In test mode, possible status for exercise are: `started` or `withInput`
      // Validation is possible in test mode, only in multi-step-case and in multi-step case
      // whether skipping or not skipping, some steps will be completed.
      // So status can be fixed to `withInput`.
      applicationStatus.exerciseStatus = ExerciseStatus.withInput;
      applicationStatus.stepStatus = StepStatus.completed;
    } else {
      const isLastExercise = exerciseIndex === series.exercises.length - 1;
      applicationStatus.seriesStatus = isLastExercise
        ? SeriesStatus.completed
        : applicationStatus.seriesStatus;
      applicationStatus.exerciseStatus = ExerciseStatus.completed;
      applicationStatus.stepStatus = StepStatus.completed;
    }
  } else {
    if (step.id === response.nextStep.id && step.maxErrors !== response.numberOfErrors) {
      applicationStatus.stepStatus = StepStatus.attempted;
    } else {
      applicationStatus.stepStatus = StepStatus.completed;
    }
  }
  return applicationStatus;
};
/**
 * Prepares the payload for validation
 * @param {AppExercise} appExercise
 * @param {ContentDict} contentDict
 * @param {string} stepId
 * @param {number} numberOfErrors
 * @param {boolean} testMode
 * @param {string} userAnswerXML Optional xml to validate instead of `contentDict`
 *
 * @returns ValidatorRequest
 */
export const createValidationPayload = (
  appExercise: AppExercise,
  contentDict: ContentDict,
  stepId: string,
  numberOfErrors: number,
  testMode: boolean
): ValidatorRequest => {
  // Create api payload
  const steps: ValidatorStep[] = toValidatorSteps<ValidatorStep>(
    appExercise.steps,
    ValidatorEngine.mathcore
  );
  const exercise: ValidatorExercise = {
    ...pick(appExercise, 'id', 'description', 'exerciseType', 'featuresTimestamp'),
    steps,
  };

  return {
    exercise,
    stepId,
    testMode,
    numberOfErrors,
    userAnswerXML: STUBABLE.exportContent(
      ContentDict.root(contentDict),
      STUBABLE.createExporterContext(exporters, contentDict)
    ),
  };
};
export const showSolutionResponse = (
  step: AppStep,
  exercise: AppExercise
): ExtendedValidatorResponse => {
  const { steps, currentStepId } = exercise;
  const nextStepIndex = AppExercise.getCurrentStepIndex(steps, currentStepId) + 1;
  const nextStep = AppExercise.isCurrentStepLast(exercise)
    ? null
    : {
        ...pick(steps[nextStepIndex], 'id', 'answerXML', 'questionXML'),
        index: nextStepIndex,
      };

  return {
    annotatedUserAnswerXML: step.questionXML,
    feedbacks: [
      {
        id: step.id,
        severity: Severity.error,
        key: 'showSolution',
        textXML: undefined,
        learningObjectiveId: undefined,
      },
    ],
    nextStep,
    numberOfErrors: step.maxErrors,
    maxNumberOfErrors: step.maxErrors,
    skipState: undefined,
    skippedStepIds: [],
    validity: Validity.wrong,
    isEmptyInput: false,
  };
};
/**
 * Deselect all gizmos (there should be at most 1 selected) in the current question.
 * If the keyboard drawer is open, close it.
 * If `notify` is `true`, dispatch the action DESELECT_GIZMO to its reducer.
 *
 * @param {boolean} notify - should the gizmo's reducer receive the action DESELECT_GIZMO
 * @param {AppExercise} exercise
 * @returns {AppExercise}
 */
export const deselectGizmo = curry(
  (notify: boolean, state: ApplicationState): ApplicationState =>
    compose(
      ApplicationState.toSelectedRefId(state).set(undefined),
      Lens.update(ApplicationState.toSelectedContent(state))(
        notify ? notifyDeselectedGizmo : identity
      )
    )(state)
);
/**
 * Select a gizmo (set its `selected` property to true).
 * Set the `lastSelection` property to the gizmoId.
 * If it has a keyboard tool, open the keyboard drawer.
 * If `notify` is `true`, dispatch the action SELECT_GIZMO to its reducer.
 *
 * @param {string} gizmoId - the $refid of the gizmo to select
 * @param {boolean} notify - should the gizmo's reducer receive the action SELECT_GIZMO
 * @param {boolean} triggeredByUser - needed to decide whether the keyboard drawer should be opened even if the DnD drawer is currently open
 * @returns {ApplicationState}
 */
export const selectGizmo = curry(
  (
    gizmoId: string,
    notify: boolean,
    triggeredByUser: boolean,
    state: ApplicationState
  ): ApplicationState =>
    compose(
      openKeyboardDrawer(triggeredByUser),
      ApplicationState.toSelectedRefId(state).set(gizmoId),
      Lens.update(ApplicationState.toGizmoContent(gizmoId, state))(
        notify ? notifySelectedGizmo : identity
      )
      // ApplicationState.toGizmoContent(gizmoId, state).get(state)?.tool
      //   ? ApplicationState.toLastSelection(state).set(undefined)
      //   : identity
    )(state)
);
