import {
  CONFIGURATION,
  type HelloGizmoContent,
  type Importer,
  xmlTextToBoolean,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';
import { GIZMO_COLORS, type GizmoColor } from '../components/Gizmo/constants';
import { HELLO_GIZMO_DEFAULT_CONTENT } from './constants';

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * color is optional in the XML, default value is `gray` (using [[HELLO_GIZMO_DEFAULT_CONTENT]]
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @returns The metadata and parsed xml as `Content`
 */
export const importHelloGizmo: Importer<HelloGizmoContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const name = mrow.findChildTag('mtext').text;

  /* eslint-disable-next-line no-constant-condition */
  if (false) {
    // this block exists for documentation purposes it will be stripped by webpack
    // in case a gizmo has a configuration tag with child tags containing the values
    const configuration = mrow.findChildTag(CONFIGURATION);

    // you can use tagsToProps to get all tags of the same data type
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mandatory, numeric, optional } = configuration.tagsToProps(
      xmlTextToInt, // all values will be parsed by this function
      ['mandatory', 'numeric'], // will throw if not present in XML
      ['optional'] // optional fields will only be present
      // on the resulting object if present in the XML
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { possible } = configuration.tagsToProps(
      // you can of course compose functions to reach the value you want
      xmlTextToBoolean,
      // only optional fields are possible by not passing mandatory fields
      [],
      ['possible'],
      false // whit default value, all optional fields will be present with this value
    );
  }

  /**
   * parse the data in mrow tag,
   * look for color attribute and fetch its value,
   * assign default value if color attribute is not present
   * assign default value If its value is not one of `GizmoColor`
   */
  let color = mrow.attribute('color', HELLO_GIZMO_DEFAULT_CONTENT.color) as GizmoColor;
  if (GIZMO_COLORS.indexOf(color) === -1) {
    color = HELLO_GIZMO_DEFAULT_CONTENT.color;
  }

  /**
   * look for size attribute and fetch its value,
   * assign default value if size attribute is not present
   */
  const size = parseFloat(mrow.attribute('size', HELLO_GIZMO_DEFAULT_CONTENT.size.toString()));
  return {
    ...preContent,
    color,
    name,
    size,
  };
};
