import * as React from 'react';

import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import { ContentDict, numberFromStyles } from '@bettermarks/gizmo-types';
import {
  GizmoProvider,
  LocaleProvider,
  PolymorphicGizmo,
} from '../../../../gizmo-utils/polymorphic-gizmo';
import { DragNDropContextProvider } from '../../../../gizmos/drag-and-drop/context';
import { LoaderState } from '../../../../types';

import { Loader } from '../../../seriesplayer/components/Loader';
import {
  getAvailableHeight,
  getAvailableWidth,
} from '../../../seriesplayer/services/runtime-manager/responsive';

import { getBackToNsp } from '../../../seriesplayer/containers/SeriesPlayer/quitSeriesPlayerSaga';
import { type GizmoRoute, isGizmoRoute } from '@bettermarks/importers';
import { GizmosList } from '../../gizmos/GizmosList';
import { type GizmoViewerReduxProps } from './GizmoViewerContainer';
import styles from './gizmoViewer.scss';
import { useNavigate, useParams } from 'react-router-dom';

const PADDING_LEFT = numberFromStyles(styles.PADDING_LEFT);

export type GizmoViewerRouterProps = {
  gizmo: string;
};

export type GizmoViewerProps = GizmoViewerReduxProps;

export type GizmoViewerState = Partial<Record<keyof GizmoViewerRouterProps, GizmoRoute>>;

const allGizmosRoutePath = 'AllGizmos';

export function GizmoViewer(props: GizmoViewerProps) {
  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    isGizmoRoute(params.gizmo ?? '') && props.onLoadGizmo(params.gizmo as GizmoRoute);

    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [params.gizmo]); // Empty dependency array ensures runs only on mount and unmount

  if (!isGizmoRoute(params.gizmo ?? '') && params.gizmo !== allGizmosRoutePath) {
    navigate(`gizmo/${allGizmosRoutePath}`);
  }

  // Equivalent to UNSAFE_componentWillReceiveProps

  function onWindowResize() {
    props.onResize({
      availableWidth: getAvailableWidth(),
      availableHeight: getAvailableHeight(),
    });
  }

  const { availableWidth, contentDict, dispatch, isTouch, loaderState, staticMediaUrl } = props;

  // selectedContent is always expected to be not undefined.
  // It will show blank if for any reason, reducer is not able to set selectedContent

  if (params.gizmo === allGizmosRoutePath) {
    return <GizmosList />;
  }

  const gizmoContent: undefined | JSX.Element = contentDict && (
    <div className={styles.gizmoViewer}>
      <DragNDropContextProvider>
        <GizmoProvider
          gizmos={gizmoRegistry}
          contentDict={contentDict}
          dispatch={dispatch}
          isTouch={isTouch}
          staticMediaUrl={staticMediaUrl}
        >
          <PolymorphicGizmo
            refid={ContentDict.root(contentDict)}
            availableWidth={availableWidth - PADDING_LEFT}
          />
        </GizmoProvider>
      </DragNDropContextProvider>
    </div>
  );

  /*
     LoaderState starts with `Loaderstate.loading`.
     When API call to fetch XMLs is finished and success action is dispatched.
     Success actions set loaderState to LoaderState.success
     Then reducer parses the response and populate the application state.
     */
  return (
    <LocaleProvider>
      <Loader
        loaded={loaderState === LoaderState.success}
        hasError={loaderState === LoaderState.error}
        onQuit={getBackToNsp}
      >
        {gizmoContent}
      </Loader>
    </LocaleProvider>
  );
}
