import * as React from 'react';
import styles from './components/legend/Legend.scss';
import { isNil } from 'lodash';
import {
  type ContextState,
  PolymorphicGizmo,
  useContentTranslation,
} from '../../gizmo-utils/polymorphic-gizmo';
import { LegendComponent, type LegendProps } from './components/legend/LegendComponent';
import { type CaptionContent } from '@bettermarks/gizmo-types';

export type LegendRendererProps = CaptionContent & ContextState;

export const LegendRenderer: React.FC<LegendRendererProps> = ({
  availableWidth,
  header,
  isTouch,
  legend,
}) => {
  const t = useContentTranslation();

  const getLegendProps = (): LegendProps => ({
    availableWidth,
    isTouch,
    legend,
  });

  return (
    <div className={styles.legend}>
      <div className={styles.legendHeader}>
        {!isNil(header) ? (
          <PolymorphicGizmo refid={header.$refid} availableWidth={availableWidth} />
        ) : (
          t('editors:diagram.caption.header')
        )}
      </div>
      <LegendComponent {...getLegendProps()} />
    </div>
  );
};

LegendRenderer.displayName = 'LegendRenderer';
