import { type ComputedStyles, type Interactable } from '@bettermarks/gizmo-types';
import type * as React from 'react';

export type MProps = {
  computedStyles: ComputedStyles;
  type?: string;
};

export enum Side {
  Front,
  Rear,
}

export type SelectHandler = (side: Side) => void;
export type SelectChildHandler = (index: number, side: Side) => void;

export type LeafProps = MProps & {
  onSelect?: SelectHandler;
};

export type MPropsWithChildren = MProps & {
  children: React.ReactNode[];
  onSelect?: SelectChildHandler;
};

export type MTokenProps = MProps &
  Interactable & {
    children: string;
  };
