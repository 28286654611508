import { EPS } from './constants';
import { FunctionType, type DerivativeType } from '@bettermarks/gizmo-types';

// the b-log function ...
const logb = (x: number, base: number) => Math.log(x) / Math.log(base);

/**
 * linear implements a linear function with 2 free parameters
 * f(x) = a x + b
 * @param {number} a
 * @param {number} b
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * ddx denotes a number indicating, if we want ...
 *  0 ... the value of the function at x (f(x))
 *  1 ... the slope at x (f'(x))
 */
export const linear =
  (a: number, b: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [a * x + b, a][ddx];

/**
 * implements a quadratic function with 3 free parameters
 * f(x) = a * (x - d)^2 + e
 * @param {number} a
 * @param {number} d
 * @param {number} e
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const quadratic =
  (a: number, d: number, e: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [a * (x - d) * (x - d) + e, 2 * a * (x - d)][ddx];

/**
 * implements an exponential function with 4 free parameters
 * f(x) = a*b^(x - d) + e
 * @param {number} a
 * @param {number} b
 * @param {number} d
 * @param {number} e
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const exponential =
  (a: number, b: number, d: number, e: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [a * Math.pow(b, x - d) + e, a * Math.log(b) * Math.pow(b, x - d)][ddx];

/**
 * implements a logarithmic function with 4 free parameters
 * f(x) = a * log_b(x + d) + e
 * @param {number} a
 * @param {number} b
 * @param {number} d
 * @param {number} e
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const logarithmic =
  (a: number, b: number, d: number, e: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    (b <= 0 || b === 1 || x - d < -EPS
      ? [NaN, NaN]
      : x - d < 10 * EPS
      ? [1, 1].map((_) => Math.sign(a * Math.log(b)) * -Infinity)
      : a === 0
      ? [e, 0]
      : [a * logb(x - d, b) + e, a / (Math.log(b) * (x - d))])[ddx];

/**
 * implements a sinus function with 4 free parameters
 * f(x) = a * sin(b * (x + c)) + d
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const sinus =
  (a: number, b: number, c: number, d: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [a * Math.sin(b * x + c) + d, a * b * Math.cos(b * x + c)][ddx];

/**
 * implements a power function with 3 free parameters
 * f(x) = a * x^n + e
 * @param {number} a
 * @param {number} e
 * @param {number} n
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const power =
  (a: number, e: number, n: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [a * Math.pow(x, n) + e, a * n * Math.pow(x, n - 1)][ddx];

/**
 * implements a polynomial function of grade <n-1> with <n> free parameters
 * f(x) = a[0] + a[1}*x + a[2]*x^2 + ... + a[n-1]*x^(n-1)
 * @param {number[]} a
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const polynomial =
  (...a: number[]) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [
      a.reduce((acc, a, i) => acc + a * Math.pow(x, i)),
      [0, ...a.slice(1)].reduce((acc, a, i) => acc + i * a * Math.pow(x, i - 1)),
    ][ddx];

/**
 * implements a horizontal line function with 1 free parameter
 * f(x) = e
 * @param {number} e
 * @returns {(ddx: DerivativeType) => (x: number) => number}
 * (more on ddx, see linear function above)
 */
export const hline =
  (e: number) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    [e, 0][ddx];

// map for getting a function's value or tangent or ... at x
export const F_MAP: {
  [key: string]: (...a: number[]) => (d: DerivativeType) => (x: number) => number;
} = {
  [FunctionType.linear]: linear,
  [FunctionType.quadratic]: quadratic,
  [FunctionType.exponential]: exponential,
  [FunctionType.logarithmic]: logarithmic,
  [FunctionType.sinus]: sinus,
  [FunctionType.power]: power,
  [FunctionType.polynomial]: polynomial,
  [FunctionType.vline]: hline, // vline will be used as a 'flipped' hline
};
