import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type Sector,
  semantics,
  type WheelOfFortuneContent,
} from '@bettermarks/gizmo-types';

/**
 * It accepts an element and returns xml for individual items
 * @param el
 */
const exportWOFItem = (el: Sector) => `
    <item>
      <color>${el.color}</color>
      ${el.label ? `<label>${el.label}</label>` : ''}
    </item>`;

/**
 * It exports the Wheel Of Content to XML.
 * @param contentRefId
 * @param context
 */
export const exportWheelOfFortune: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as WheelOfFortuneContent;
  const contentXML = (context.content[contentRefId] as WheelOfFortuneContent).sectors
    .map(exportWOFItem)
    .join('');

  return semantics(
    `
      <mrow>
        <set>
          ${contentXML}
        </set>
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
