import { getFontMetric } from '../../../../../utils/fontMetric';
import * as React from 'react';

import {
  type HasStroke,
  type MRow,
  type MSqrt as MSqrtContent,
  isEmptyContainer,
} from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { RADIX_CONTENT_SPACING, RADIX_PADDING_BOTTOM } from '../constants';
import { enrich } from '../helpers';
import { type MProps, type SelectHandler } from '../types';

import { Radix } from './Radix';

import styles from './Root.scss';

const MSQRT_EMPTY_INDEX_LINE_FACTOR = 2.5;
export type MSqrtProps = MProps &
  HasStroke & {
    children: JSX.Element;
    onSelectRadix?: SelectHandler;
    radicandHeight?: number;
    radicandRefLine?: number;
    height?: number;
  };

/**
 * The `MSqrt' component renders a MathML <msqrt> node and can be used like an <mrow>
 */
export const MSqrt: React.FC<MSqrtProps> = ({
  children: radicand,
  computedStyles: { color = styles.DEFAULT_FONT_COLOR, backgroundColor, fontSize },
  strokeWidth,
  onSelectRadix,
  radicandHeight = 0,
  radicandRefLine = 0,
  height = 0,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <div className={styles.mroot} style={{ height }}>
      <div className={styles.mrootIndex}>
        <div
          className={styles.mrootIndexLine}
          style={{
            borderTopWidth: strokeWidth,
            borderTopColor: color,
            height: radicandRefLine + RADIX_PADDING_BOTTOM - strokeWidth * 0.5,
            width: strokeWidth * MSQRT_EMPTY_INDEX_LINE_FACTOR,
          }}
        />
      </div>
      <Radix
        radicandHeight={radicandHeight}
        radicandRefLine={radicandRefLine}
        color={color}
        strokeWidth={strokeWidth}
        onSelectRadix={onSelectRadix}
        style={{ fontSize }}
      >
        {/* No need to use a <Bucket> with Radix, because Radix does the same thing */}
        {radicand}
      </Radix>
    </div>
  </BackgroundColor>
);

export const enrichMSqrt: EnrichNestedMathContent<MSqrtContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const {
    enrichedContent: radicand,
    height: radicandHeight,
    refLine: radicandRefLine,
    relativeToBaseLine,
  } = mathContentEnricher(formulaStyles, content.radicand, [...path, 'radicand']);

  const { strokeWidth } = getFontMetric(formulaStyles.fontSize, formulaStyles.fontWeight);

  const height = RADIX_PADDING_BOTTOM + radicandHeight + RADIX_CONTENT_SPACING + strokeWidth;
  return enrich(
    {
      ...content,
      radicand,
      radicandHeight,
      radicandRefLine,
      strokeWidth,
      height,
    },
    {
      height,
      refLine: radicandRefLine + RADIX_PADDING_BOTTOM,
      // special case of an impact of mrow with only empty tokens:
      // not visible but impacts alignment of the MRoot
      // when interactive the placeholder is relevant for alignment
      // see http://trac.bm.loc/ticket/43276 for details
      relativeToBaseLine: isEmptyContainer(radicand)
        ? formulaStyles.interactive
        : relativeToBaseLine,
    },
    formulaStyles
  );
};
