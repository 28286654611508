import * as React from 'react';
import classNames from 'classnames';
import { expectType, type TypeOf } from 'ts-expect';
import {
  Button,
  ButtonKind,
  ButtonStretch,
  ContextNotification,
  ContextNotificationKind,
} from '@seriesplayer/common-ui';

import { DialogFooter, type IconProps } from '../../../../../components/index';
import { ExerciseStatus } from '../../../../../types';

import { ExerciseTable } from '../../../components/ExerciseTable';
import { ExerciseNavigationButton } from '../../../components/ExerciseNavigationButton';
import { Appearance } from '../../../components/ExerciseNavigationButton/ExerciseNavigationButton';
import { type HandInReduxProps } from './HandInContainer';
import styles from './HandIn.scss';

export interface HandInOwnProps {
  cancelText?: string;
  confirmIncompleteText?: string;
  confirmText?: string;
  dataCy?: string;
  exerciseText: string;
  mainText1?: string;
  mainText2?: string;
  singleTryText?: string;
  title?: string;
}

const ICON_PROPS_UNFILLED: IconProps = {
  iconKey: 'CircleOSmall',
  style: { fill: styles.UNFILLED_COLOR },
};
const ICON_PROPS_FILLED: IconProps = {
  iconKey: 'CircleSmall',
  style: { fill: styles.FILLED_COLOR },
};

export type HandInProps = HandInOwnProps & HandInReduxProps;
// validating the contract between `HandInContainer` and `HandIn`
expectType<TypeOf<HandInReduxProps, HandInProps>>(true);

export const HandIn: React.FC<HandInProps> = ({
  dataCy = '',
  numExercises,
  onCancel,
  onConfirm,
  onSwitchExercise,
  singleTry,
  unfilledExercises,
  ...textProps
}) => {
  return (
    <div id="hand-in-dialog" data-cy={dataCy} className={styles.content}>
      <h1 className={classNames(styles.headline, styles.dialogSection)}>{textProps.title}</h1>
      <p className={classNames(styles.body, styles.dialogSection)}>
        {unfilledExercises.length === 0 ? textProps.mainText1 : textProps.mainText2}
      </p>
      <div className={styles.dialogSection}>
        <ExerciseTable
          exerciseText={textProps.exerciseText}
          numExercises={numExercises}
          exercisesWithIconB={unfilledExercises}
          iconPropsA={ICON_PROPS_FILLED}
          iconPropsB={ICON_PROPS_UNFILLED}
          onClickItem={onSwitchExercise}
        />
        <div className={styles.exerciseButtons}>
          {unfilledExercises.map((exIndex: number) => (
            <ExerciseNavigationButton
              exerciseIndex={exIndex}
              exerciseDisplayStatus={ExerciseStatus.started}
              switchExercise={onSwitchExercise}
              key={exIndex}
              appearance={Appearance.plain}
            />
          ))}
        </div>
      </div>
      {singleTry && (
        <div className={classNames(styles.notification, styles.dialogSection)}>
          <ContextNotification kind={ContextNotificationKind.attention} stretch={true}>
            {textProps.singleTryText}
          </ContextNotification>
        </div>
      )}
      <DialogFooter align="center">
        <Button
          id="hand-in-confirm-btn"
          dataCy="hand-in-confirm-btn"
          kind={ButtonKind.final}
          stretch={ButtonStretch.responsive}
          onClick={onConfirm}
          type="dialog"
        >
          {unfilledExercises.length === 0 ? textProps.confirmText : textProps.confirmIncompleteText}
        </Button>
        <Button
          id="hand-in-cancel-btn"
          dataCy="hand-in-cancel-btn"
          kind={ButtonKind.view}
          stretch={ButtonStretch.responsive}
          onClick={onCancel}
          type="dialog"
        >
          {textProps.cancelText}
        </Button>
      </DialogFooter>
    </div>
  );
};

HandIn.displayName = 'HandIn';
