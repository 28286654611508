import * as React from 'react';
import { get } from 'lodash';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import {
  type CommonCallbacks,
  type MouseOrTouch,
  type PieOrStackedBarChartContent as StackedBarChartContent,
  type Slice,
} from '@bettermarks/gizmo-types';
import { WithLocalRedux } from '../../gizmo-utils/WithLocalRedux';
import { StackedBarChartRenderer } from './StackedBarChartRenderer';
import { type StackedBarChartCallbacks } from './StackedBarChartContainer';
import { initialState, stackedBarChartLocalReducer } from './reducer/StackedBarChartReducer';
import { end, stackedBarMove, stackedBarStart } from './reducer/actions';
import { rdN } from '@bettermarks/importers';
import {
  DEFAULT_STACKED_BAR_MAX_WIDTH,
  DEFAULT_STACKED_BAR_OFFSET,
  DEFAULT_STACKED_BAR_TICK,
} from './defaults';

export type StackedBarChartState = {
  item: number; // active item
  slices: Slice[];
};

export type StackedBarChartProps = StackedBarChartContent &
  StackedBarChartCallbacks &
  ContextState &
  CommonCallbacks;

export const StackedBarChartEditor: React.FC<StackedBarChartProps> = ({
  onPersist,
  uniqueId,
  ...props
}) => (
  <WithLocalRedux
    store={{ ...initialState, slices: [...props.slices] }}
    reducer={stackedBarChartLocalReducer}
    componentName={`StackedBarChartEditor:${uniqueId}`}
  >
    {(state, dispatch) => {
      const { item, slices } = state;
      const barStart = DEFAULT_STACKED_BAR_OFFSET;
      const barEnd = Math.min(props.availableWidth, DEFAULT_STACKED_BAR_MAX_WIDTH);
      const xScreenToTickValue = (evt: MouseOrTouch, elem: HTMLDivElement | null): number => {
        const dim = elem ? elem.getBoundingClientRect() : { left: 0 };

        const touches = get({ ...evt }, 'touches');
        const screenX =
          touches && touches.length > 0
            ? touches[0].clientX
            : (evt as React.MouseEvent<any>).clientX;
        return rdN(((screenX - dim.left - barStart) / barEnd) * 100, DEFAULT_STACKED_BAR_TICK);
      };

      const activeSliceValueChanged = () =>
        state.slices[state.item].value !== props.slices[state.item].value;

      const onMoveBar = (elem: HTMLDivElement | null) => (evt: MouseOrTouch) => {
        if (!isNaN(item)) {
          const tick = xScreenToTickValue(evt, elem);
          const cumulativeValue = slices.slice(0, item).reduce((sum, { value }) => sum + value, 0);
          const deltaValue = cumulativeValue - tick;
          if (deltaValue !== 0) {
            dispatch(stackedBarMove(deltaValue));
          }
        }
      };

      const onMoveStart = (item: number) => dispatch(stackedBarStart(item));

      const onMoveEnd = () => {
        if (!isNaN(item) && activeSliceValueChanged()) {
          // persist the current slices in the global store
          if (onPersist) {
            onPersist(slices);
          }
        }
        dispatch(end());
      };

      const localProps = { ...props, slices };
      const interactive = !props.disabled;

      return (
        <StackedBarChartRenderer
          {...localProps}
          onMove={interactive ? onMoveBar : undefined}
          onMoveEnd={interactive ? onMoveEnd : undefined}
          onMoveStart={interactive ? onMoveStart : undefined}
        />
      );
    }}
  </WithLocalRedux>
);

StackedBarChartEditor.displayName = 'StackedBarChartEditor';
