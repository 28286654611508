import { FractionFormEditorMode, type KeyboardTool } from '@bettermarks/gizmo-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gizmoAction } from '../../gizmo-utils/redux';
import { setMode } from '../../gizmo-utils/redux/gizmoActions';
import { ApplicationState, LoaderState } from '../../types';
import { ExerciseContainer } from '../seriesplayer';
import { Loader } from '../seriesplayer/components';
import { ErrorBoundaryContainer } from '../seriesplayer/containers/ErrorBoundary';
import { getCurrentTool } from '../seriesplayer/containers/Toolbar/helpers';
import {
  createOnKeyGizmoAction,
  getKeyboardToolState,
} from '../seriesplayer/containers/Toolbar/Tools/Keyboard/KeyboardContainer';
import { getModesFromState } from '../seriesplayer/containers/Toolbar/Tools/ModeSelectorContainer';
import { initExampleModeSeries } from './actions';
import { postToWhiteboardParent, type WhiteboardMessage } from '../iframe-posts';
import { ExerciseWithSolution } from './ExerciseWithSolution';
import type { ExampleNavigationItem } from './ExampleNavigation';
import { FEMDispatchContextProvider } from '../whiteboard/FEMDispatchContext';
import { isEqual } from 'lodash';
import { useLocation } from 'react-router-dom';

type ExercisePostMessagesBridgeProps = {
  exerciseId: string;
  staticUrl: string;
};

export const initialNavigationItem: ExampleNavigationItem = 'exercise';

//TODO: add comparison
const navigationItems: string[] = ['exercise', 'solution'];

export function isExampleNavigationItem(
  maybeExampleNavigationItem: unknown
): maybeExampleNavigationItem is ExampleNavigationItem {
  return (
    typeof maybeExampleNavigationItem === 'string' &&
    navigationItems.includes(maybeExampleNavigationItem)
  );
}

function parseSelectedNavigation(search: string): ExampleNavigationItem {
  const maybeExampleNavigationItem = new URLSearchParams(search).get('selectedNavigation');
  return isExampleNavigationItem(maybeExampleNavigationItem)
    ? maybeExampleNavigationItem
    : initialNavigationItem;
}

export default function ExerciseInExampleMode({
  exerciseId,
  staticUrl,
}: ExercisePostMessagesBridgeProps): JSX.Element {
  const location = useLocation();

  const selectedNavigation = parseSelectedNavigation(location.search);

  const { gizmoId, tools, openDrawerName, modes, contentId, loaded, keyboard } = useSelector(
    (state: ApplicationState) => ({
      tools: state.toolbar.tools,
      openDrawerName: state.toolbar.openDrawerName,
      gizmoId:
        state.loaderState === LoaderState.success
          ? ApplicationState.toSelectedRefId(state).get(state)
          : undefined,
      keyboard:
        state.loaderState === LoaderState.success
          ? getKeyboardToolState(getCurrentTool(state) as KeyboardTool)
          : undefined,
      contentId:
        state.series.exercises.length > 0
          ? ApplicationState.toSelectedRefId(state).get(state)
          : undefined,
      modes: state.series.exercises.length > 0 ? getModesFromState(state) : [],
      loaded: state.loaderState === LoaderState.success,
    })
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initExampleModeSeries({ exerciseId, staticUrl }));
  }, [exerciseId, staticUrl]);

  React.useEffect(() => {
    if (loaded) {
      postToWhiteboardParent({ type: 'contentLoaded' });
    }
  }, [loaded]);

  //useRef for "caching" the value of modes and tools between renders. We need this to use arrays or objects as
  // dependencies in useEffect hook
  const modesRef = React.useRef(modes);
  if (!isEqual(modesRef.current, modes)) {
    modesRef.current = modes;
  }
  const toolsRef = React.useRef(tools);
  if (!isEqual(toolsRef.current, tools)) {
    toolsRef.current = tools;
  }
  const keyboardToolStateRef = React.useRef(keyboard);
  if (!isEqual(keyboardToolStateRef.current, keyboard)) {
    keyboardToolStateRef.current = keyboard;
  }

  // report the initial available tools to the parent whiteboard component
  React.useEffect(() => {
    postToWhiteboardParent({ type: 'availableTools', tools, modes, keyboard, gizmoId });
  }, [toolsRef, modesRef, keyboardToolStateRef, gizmoId]);

  React.useEffect(() => {
    postToWhiteboardParent({ type: 'setOpenDrawer', openDrawerName, gizmoId });
  }, [openDrawerName]);

  React.useEffect(() => {
    function handleReceiveMessage(e: MessageEvent<WhiteboardMessage>) {
      if (e.data.type === 'changeMode') {
        const mode = e.data.mode;
        dispatch(
          // TODO: get the contentId somehow to not be undefined
          gizmoAction(setMode(mode), contentId ?? 'steps[0].question', {
            ...(mode !== FractionFormEditorMode.Add && { skipUndo: true }),
          })
        );
      } else if (e.data.type === 'keyboardInput') {
        const { key, gizmoId } = e.data;
        dispatch(createOnKeyGizmoAction(gizmoId)(key));
      }
    }

    window.addEventListener('message', handleReceiveMessage);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [contentId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <Loader loaded={loaded}>
      <ErrorBoundaryContainer>
        <FEMDispatchContextProvider
          value={{
            dispatchFEMOpen: (femId: string) => {
              postToWhiteboardParent({ type: 'showFEM', femId });
            },
          }}
        >
          {selectedNavigation === 'solution' ? <ExerciseWithSolution /> : <ExerciseContainer />}
        </FEMDispatchContextProvider>
      </ErrorBoundaryContainer>
    </Loader>
  );
}
