export const SVG_HEIGHT = 16;

export const SVG_PADDING_TOP = 0;
export const SVG_PADDING_BOTTOM = 0;
export const SVG_RECTANGLE_WIDTH = 20;
export const SVG_RECTANGLE_HEIGHT = SVG_HEIGHT;

export const SVG_SQUARE_SIDE = SVG_HEIGHT;

export const SVG_CIRCLE_RADIUS = SVG_HEIGHT * 0.5;
