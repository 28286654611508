import { combineActions, handleAction } from 'redux-actions';
import { NEXT_EXERCISE, SWITCH_EXERCISE } from '../containers/Series/actions';
import { OPEN_DIALOG, SHOW_ERROR_DIALOG } from '../containers/SeriesPlayer/actions';
import { ApplicationState, ScreenLockType } from '../../../types';
import { initialSeriesplayerState } from '../../store/constants';
import { UNLOCK_SCREEN } from './applicationActions';

export const handleUnlockScreen = handleAction(
  combineActions(SWITCH_EXERCISE, NEXT_EXERCISE, OPEN_DIALOG, SHOW_ERROR_DIALOG, UNLOCK_SCREEN),
  ApplicationState.toScreenLockType.set(ScreenLockType.none),
  initialSeriesplayerState
);
