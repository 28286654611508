import {
  annotationInner,
  type Content,
  exportContent,
  type Exporter,
  type ExporterContext,
  type MultipleChoiceSingleChoiceContent as ChoiceContent,
  type MultipleChoiceSingleConfiguration,
  type MultipleChoiceSingleContent,
  placeholder,
  RS,
  semantics,
  SPECIAL,
} from '@bettermarks/gizmo-types';
import { isUndefined, uniq } from 'lodash';

type SingleToXML = (content: MultipleChoiceSingleContent, context: ExporterContext) => string;

const exportConfiguration = (config: MultipleChoiceSingleConfiguration | undefined): string => {
  if (config === undefined) {
    return '';
  }
  const layout =
    config.elementsPerLine === 1
      ? `<direction>vertical</direction>`
      : config.elementsPerLine === Number.MAX_VALUE
      ? `<direction>horizontal</direction>`
      : `<maxElementsPerRow>${config.elementsPerLine}</maxElementsPerRow>`;
  const hideButtons = config.radioButtons ? '' : '<hideButtons/>';

  return `<configuration>${layout}${hideButtons}</configuration>`;
};

const exportStackConfiguration = (config: MultipleChoiceSingleConfiguration): string => {
  const defaultSelection =
    config.stackLayout && config.stackLayout.defaultSelection
      ? `<defaultSelection>${config.stackLayout.defaultSelection.visualRefId}</defaultSelection>`
      : '';
  return `<configuration>${defaultSelection}</configuration>`;
};

const exportVisualContents: SingleToXML = (content, context) => {
  const defaultSelection = content.configuration.stackLayout
    ? content.configuration.stackLayout.defaultSelection
    : undefined;

  const children = uniq([
    ...(defaultSelection && defaultSelection.visualContent
      ? [defaultSelection.visualContent.$refid]
      : []),
    ...content.choices.reduce(
      (acc, c) => (c.visualContent ? [...acc, c.visualContent.$refid] : acc),
      []
    ),
  ]).map((refId) => exportContent(refId, context));

  return `
    <set>
    ${children.join('')}
    </set>
  `;
};

const exportSelectedOption: SingleToXML = (content, context) => {
  let checkedSet = '<set/>';
  if (content.checkedIdx > -1) {
    const refId = content.choices[content.checkedIdx].contentRef.$refid;
    const answerId = (context.content[refId] as Content).$id;

    if (!isUndefined(answerId)) {
      checkedSet = `
      <set>
        <mn ${content.severity ? `decoration="${content.severity}"` : ''}>
          ${parseFloat(answerId) - 1}
        </mn>
      </set>
    `;
    }
  }

  return semantics(`<mrow>${checkedSet}</mrow>`, placeholder(content.configuration.placeholder));
};

const exportMCSingleContentRaw: SingleToXML = (content, context) =>
  semantics(
    `
      <mrow>
        ${exportConfiguration(content.configuration)}
        ${content.choices
          .map((choice: ChoiceContent) => exportContent(choice.contentRef.$refid, context))
          .join('')}
        ${exportSelectedOption(content, context)}
      </mrow>
      `,
    annotationInner(content.$, content)
  );

export const exportMultipleChoiceSingle: Exporter = (contentRefId, context) =>
  exportMCSingleContentRaw(context.content[contentRefId] as MultipleChoiceSingleContent, context);

export const exportMultipleChoiceSingleStack: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as MultipleChoiceSingleContent;

  const stackId =
    content.configuration.stackLayout !== undefined ? content.configuration.stackLayout.$id : '';

  return semantics(
    `
      <mrow>
        ${exportStackConfiguration(content.configuration)}
        ${exportMCSingleContentRaw(
          { ...content, $renderStyle: RS.MULTIPLE_CHOICE_SINGLE },
          context
        )}
        ${exportVisualContents(content, context)}
      </mrow>
    `,
    annotationInner(SPECIAL, { $renderStyle: RS.STACK_LAYOUT, $id: stackId })
  );
};
