import * as React from 'react';
import classNames from 'classnames';
import styles from './DragAndDrop.scss';

interface SetDragItemProps {
  shape?: 'border' | 'rectangle';
  itemType?: number;
  dragging?: boolean;
  draggable?: boolean;
  error?: boolean;
  fontSize?: number;
  onTouchStart?: EventListener;
  onMouseDown?: React.MouseEventHandler<any>;
  width?: number;
  height?: number;
}

export class SetDragItem extends React.Component<SetDragItemProps> {
  ref = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.ref.current && this.props.onTouchStart) {
      this.ref.current.addEventListener('touchstart', this.props.onTouchStart, {
        passive: false,
      });
    }
  }

  render() {
    const {
      draggable = true,
      shape,
      itemType = 1,
      dragging,
      error,
      fontSize,
      children,
      onMouseDown,
      width,
      height,
    } = this.props;
    return (
      <div
        role="button"
        onMouseDown={onMouseDown}
        className={classNames(
          styles.dragSource,
          styles.setDragSource,
          shape && styles[shape],
          styles[`itemType${itemType}`],
          {
            [styles.error]: error,
            [styles.dragging]: dragging,
            [styles.draggable]: draggable,
          }
        )}
        style={{ fontSize, width, height }}
        ref={this.ref}
      >
        <span className={styles.content}>{children}</span>
      </div>
    );
  }
}
