import * as React from 'react';
import { groupBy } from 'lodash';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';

import {
  type Arrow,
  MAX_ROW_WIDTH,
  type TableContainerContent,
  TableOrientation as Orientation,
} from '@bettermarks/gizmo-types';
import { prepareContainerCells } from './helper';
import { ContainerTable, HorizontalArrows, VerticalArrows } from './components/TableContainer';

import styles from './table.scss';
import { ShadowScrollbars } from '../components';

type ArrowsByOrient = Partial<Record<Orientation, Arrow[]>>;

export const hArrowRow = (arrows: ArrowsByOrient, orientation: Orientation, colWidths: number[]) =>
  arrows[orientation] && (
    <HorizontalArrows
      arrows={arrows[orientation] || []}
      colWidths={colWidths}
      orientation={orientation}
    />
  );

export const vArrowColumn = (
  arrows: ArrowsByOrient,
  orientation: Orientation,
  rowHeights: number[],
  availableWidth: number
) =>
  arrows[orientation] && (
    <VerticalArrows
      arrows={arrows[orientation] || []}
      rowHeights={rowHeights}
      orientation={orientation}
      availableWidth={availableWidth}
    />
  );

export type TableContainerProps = TableContainerContent & ContextState;

export const TableContainerGizmo: React.FC<TableContainerProps> = (props) => {
  const table = prepareContainerCells(props.table);
  const rowHeights = table.rows.map((r) => r.cells[0].height || 0);
  const colWidths = table.rows[0].cells.map((c) => c.width || 0);
  const arrowsByOrient: ArrowsByOrient = groupBy(props.arrows, (a: Arrow) => a.orientation);

  return (
    <ShadowScrollbars availableWidth={Math.min(MAX_ROW_WIDTH, props.availableWidth)}>
      <div className={styles.tableContainer}>
        {hArrowRow(arrowsByOrient, Orientation.north, colWidths)}
        <div className={styles.row}>
          {vArrowColumn(arrowsByOrient, Orientation.west, rowHeights, props.availableWidth)}
          <ContainerTable {...table} />
          {vArrowColumn(arrowsByOrient, Orientation.east, rowHeights, props.availableWidth)}
        </div>
        {hArrowRow(arrowsByOrient, Orientation.south, colWidths)}
      </div>
    </ShadowScrollbars>
  );
};

TableContainerGizmo.displayName = 'TableContainerGizmo';
