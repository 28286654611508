import * as React from 'react';
import { type SetProps, type WithBorderRect } from './types';
import { type GeoDecoration, type IntervalObject } from '@bettermarks/gizmo-types';
import { Interval } from '../../components';

export type IntervalSetProps = SetProps &
  WithBorderRect & {
    intervals: ReadonlyArray<string>;
  };

export const IntervalSet: React.FC<IntervalSetProps> = ({
  intervals,
  mode,
  geoContentMap,
  matrix,
  borderRectId,
  onClick,
  onMouseOutId,
  onHoverId,
}) => {
  return (
    <g clipPath={`url(#${borderRectId})`}>
      {intervals.map((id) => {
        const callbacks = {
          ...(onClick && { onClick: onClick(id) }),
          ...(onHoverId && { onMouseOver: onHoverId(id) }),
          ...(onMouseOutId && { onMouseLeave: onMouseOutId(id) }),
        };

        const intervalObject = geoContentMap[id] as IntervalObject;
        const { min, max, intervalType, interactionType, hover, severity } = intervalObject;
        const decoration = (geoContentMap[id] as IntervalObject).decoration as GeoDecoration;

        return (
          <Interval
            key={id}
            {...{
              id,
              min,
              max,
              matrix,
              intervalType,
              interactionType,
              decoration,
              hover,
              severity,
            }}
            {...callbacks}
          />
        );
      })}
    </g>
  );
};

IntervalSet.displayName = 'IntervalSet';
