import {
  isSupported,
  isSupportedOs,
  type support,
} from '@bettermarks/browserslist-config/dist/isSupported';
import { setParser } from '@bettermarks/browserslist-config/dist/parser';
import { UAParser } from 'ua-parser-js';
export const PARSER = new UAParser();
setParser(PARSER);

export type AppInfo = {
  buildNo: string;
  version: string;
};

type Supported = {
  supported: support;
};

export type BaseMetadata = {
  devicePixelRatio: number;
  memory?: {
    used: number;
    total: number;
  };
  userAgent: string;
  appInfo: AppInfo;
  viewPortSize: string;
  connection?: typeof navigator.connection;
};

export type Metadata = BaseMetadata & {
  browser: UAParser.IBrowser & Supported;
  device: UAParser.IDevice;
  engine: UAParser.IEngine;
  os: UAParser.IOS & Supported;
  reportedAt: string;
};

const getNetworkConnection = () => {
  let connectionInfo;
  // https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  if (connection) {
    connectionInfo = {
      downLink: connection.downLink,
      effectiveType: connection.effectiveType,
      rtt: connection.rtt,
      type: connection.type,
      addEventListener: connection.addEventListener,
      dispatchEvent: connection.dispatchEvent,
      removeEventListener: connection.removeEventListener,
    };
  }
  return connectionInfo;
};

const getMemoryUsed = () => {
  let output;
  if (window.performance.memory) {
    const memory = window.performance.memory;
    output = {
      used: memory.usedJSHeapSize,
      total: memory.jsHeapSizeLimit,
    };
  }
  return output;
};

// In dev mode, it is alright to have the values as undefined.
// Webpack config ensures that these values always exist in our index.html.
export const getAppVersion = (): AppInfo => ({
  buildNo: (document.querySelector('meta[name="build"]') as Element)?.getAttribute(
    'content'
  ) as string,
  version: (document.querySelector('meta[name="version"]') as Element)?.getAttribute(
    'content'
  ) as string,
});

export const getBaseMetadata = (): BaseMetadata => ({
  appInfo: getAppVersion(),
  devicePixelRatio: window.devicePixelRatio,
  memory: getMemoryUsed(),
  userAgent: PARSER.getUA(),
  viewPortSize: `${window.innerWidth} X ${window.innerHeight}`,
  connection: getNetworkConnection(),
});

export const getMetadata = (): Metadata => {
  const engine = PARSER.getEngine();
  const browser = PARSER.getBrowser();
  const os = PARSER.getOS();
  const supportedOs = isSupportedOs(os);
  return {
    ...getBaseMetadata(),
    browser: {
      ...browser,
      supported: isSupported(browser, supportedOs),
    },
    device: PARSER.getDevice(),
    engine: {
      name: engine.name || 'name is undefined',
      version: engine.version || 'version is undefined',
    },
    os: {
      ...os,
      supported: supportedOs,
    },
    reportedAt: new Date().toISOString(),
  };
};
