import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/*
 * Create the i18next instance.
 *
 * For documentation, see: https://react.i18next.com/components/i18next-instance.html
 */
export const i18n = async (locale?: string) => {
  const instance = i18next
    .createInstance({
      fallbackLng: 'en_US',
      lng: locale || 'en_US',
      overloadTranslationOptionHandler: (d) => d,
      react: {
        useSuspense: false,
      },
      ns: ['calculator', 'editors', 'feedbacks', 'instructions', 'kem', 'seriesplayer', 'toolpad'],
      defaultNS: 'editors',

      interpolation: {
        prefix: '{',
        suffix: '}',
      },

      resources: {
        de_DE: require('./de_DE.json'),
        de_AT: require('./de_AT.json'),
        de_DE_HHN: require('./de_DE_HHN.json'),
        en_EN: require('./en_EN.json'),
        en_GB: require('./en_GB.json'),
        en_IN: require('./en_IN.json'),
        en_US: require('./en_US.json'),
        en_ZA: require('./en_ZA.json'),
        fr_CI: require('./fr_CI.json'),
        es_CL: require('./es_CL.json'),
        es_ES: require('./es_ES.json'),
        es_MX: require('./es_MX.json'),
        es_UY: require('./es_UY.json'),
        nl_NL: require('./nl_NL.json'),
        nl_NL_HAVO: require('./nl_NL_HAVO.json'),
        nl_NL_VMBO: require('./nl_NL_VMBO.json'),
        nl_NL_rekenen: require('./nl_NL_rekenen.json'),
        pt_BR: require('./pt_BR.json'),
        tr_TR: require('./tr_TR.json'),
      },
    })
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next);

  await instance.init();

  return instance;
};
