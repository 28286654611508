import {
  type Importer,
  PDF_LINK_DEFAULT_CONTENT,
  type PdfLinkContent,
  xmlText,
} from '@bettermarks/gizmo-types';

export const importPdfLink: Importer<PdfLinkContent> = (preContent, xml, context) => {
  const { source, text } = xml.tagsToProps(xmlText, ['source', 'text']);

  return {
    ...PDF_LINK_DEFAULT_CONTENT,
    ...preContent,
    source,
    text,
  };
};
