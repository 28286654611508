import { type ContextState, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';

import { ModeSelectorContainer } from '../../../apps/seriesplayer/containers/Toolbar/Tools/ModeSelectorContainer';
import { type GizmoContent, type SuperGizmoContent } from '@bettermarks/gizmo-types';

import styles from './SuperGizmo.scss';

export type SuperGizmoProps = SuperGizmoContent & ContextState;

/**
 * Swallows list of gizmos and prepares rendering for Gizmoviewer.
 *
 * the extra padding at the bottom has been added to manage situations where, in the
 * gizmoViewer, the last item may be affected by the margin of the window, if any measure occurs
 */
export const SuperGizmo: React.FC<SuperGizmoProps> = (props) => (
  <div style={{ paddingBottom: 20 }}>
    {props.gizmos.length === 0
      ? 'no gizmos to show'
      : props.gizmos.map((gizmo: GizmoContent, i) => {
          return (
            <div key={i}>
              <h1 className={styles.headline}>{gizmo.instanceId}</h1>
              <PolymorphicGizmo
                refid={gizmo.content.$refid}
                availableWidth={props.availableWidth}
              />
              <ModeSelectorContainer />
            </div>
          );
        })}
  </div>
);

SuperGizmo.displayName = 'SuperGizmo';
