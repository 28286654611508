import * as React from 'react';
import { useContentTranslation } from '../../../../../gizmo-utils/polymorphic-gizmo';
import { type EditorMode, FractionFormEditorMode } from '@bettermarks/gizmo-types';
import toolbarButtonStyles from '../ToolbarButton.scss';
import { RadioButtonGroup } from '../RadioButtonGroup';
import { buttonRegistry, localized } from './buttonRegistry';
import { injectModeSelectorToolWrappers } from './ModeSelector/ModeSelectorItemWrapper';

export type ModeSelectorDispatchProps = {
  onSetMode?: (mode: EditorMode, contentId?: string) => void;
};

export type ModeSelectorStateProps = {
  contentId?: string;
  modes: ReadonlyArray<EditorMode>;
  editorMode?: EditorMode;
  gridView?: boolean;
  modeItemWrapper?: React.ComponentType;
  modeItemsWrapper?: React.ComponentType;
};

export type ModeSelectorProps = ModeSelectorStateProps & ModeSelectorDispatchProps;

const onToggleMode =
  (
    modes: ReadonlyArray<EditorMode>,
    onSetMode?: (mode: EditorMode, id?: string) => void,
    id?: string
  ) =>
  (i: number, on: boolean) =>
    // fraction form's "add mode" selector is not really a mode selector, but executing adding a form
    // -> needs to be triggered even if already in "add mode"
    (on || modes[i] === FractionFormEditorMode.Add) && onSetMode && onSetMode(modes[i], id);

/**
 * ModeSelector: Component with geo tool buttons
 */
const _ModeSelector: React.FC<ModeSelectorProps> = (props) => {
  const {
    modes,
    editorMode,
    onSetMode,
    contentId,
    modeItemWrapper,
    modeItemsWrapper: ModeItemsWrapper,
  } = props;

  const t = useContentTranslation();

  const radioButtonGroup = (
    <RadioButtonGroup
      kind="tool"
      onToggle={onToggleMode(modes, onSetMode, contentId)}
      value={modes.findIndex((t) => t === editorMode)}
      modeItemWrapper={modeItemWrapper}
      modes={modeItemWrapper ? modes : undefined}
    >
      {modes.map((mode) =>
        React.createElement(buttonRegistry[localized(mode, t)], {
          key: mode,
          className: toolbarButtonStyles.svgIcon,
        })
      )}
    </RadioButtonGroup>
  );

  return ModeItemsWrapper ? (
    <ModeItemsWrapper>{radioButtonGroup}</ModeItemsWrapper>
  ) : (
    radioButtonGroup
  );
};

_ModeSelector.displayName = 'ModeSelector';

export const ModeSelector = injectModeSelectorToolWrappers(_ModeSelector);
