import {
  type ContentColor,
  type GeoColoringState,
  type GeoContentPersistProps,
  type GeoDecoration,
  Hover,
} from '@bettermarks/gizmo-types';
import { type Action, createAction, handleActions } from 'redux-actions';
import { GEO_DEFAULT_PERSIST_PROPS } from '@bettermarks/importers';
import { colorDecoration, colorHoverDecoration } from './helpers';
import { resetSeverity } from '../helpers';

export interface OutPayload {
  id: string;
  selectColor: ContentColor;
}
export interface HoverPayload extends OutPayload {
  gizmoId: string;
}
export interface DeColorPayload {
  id: string;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}
export interface ColorPayload extends DeColorPayload {
  selectColor: ContentColor;
}

export type GeoColorPayload = OutPayload | HoverPayload | DeColorPayload | ColorPayload;

const COLOR: 'COLOR' = 'COLOR';
export const colorAction = createAction<ColorPayload>(COLOR);
const DECOLOR: 'DECOLOR' = 'DECOLOR';
export const deColorAction = createAction<DeColorPayload>(DECOLOR);
const HOVER: 'HOVER' = 'HOVER';
export const hoverAction = createAction<HoverPayload>(HOVER);
const OUT: 'OUT' = 'OUT';
export const outAction = createAction<OutPayload>(OUT);

export const initialState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
};

export const coloringReducer = handleActions<GeoColoringState, GeoColorPayload>(
  {
    [COLOR]: (state: GeoColoringState, { payload }: Action<ColorPayload>) => {
      if (!payload) {
        return state;
      }
      const { id, onPersistLocalState, selectColor } = payload;

      const { hover, originalDeco, ...object } = resetSeverity(
        state.persistProps.geoContentMap[id]
      );

      const result = {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...object,
              decoration: colorDecoration(selectColor, {
                ...object,
                originalDeco,
              }),
            },
          },
        },
      };
      onPersistLocalState(result.persistProps);
      return result;
    },

    [DECOLOR]: (state: GeoColoringState, { payload }: Action<DeColorPayload>) => {
      if (!payload) {
        return state;
      }
      const { id, onPersistLocalState } = payload;

      const { hover, originalDeco, ...object } = resetSeverity(
        state.persistProps.geoContentMap[id]
      );
      const { marked, ...decoration }: GeoDecoration =
        (!hover ? object.decoration : originalDeco) || {};

      const result = {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...object,
              decoration: decoration,
            },
          },
        },
      };
      onPersistLocalState(result.persistProps);
      return result;
    },
    [HOVER]: (state: GeoColoringState, { payload }: Action<HoverPayload>) => {
      if (!payload) {
        return state;
      }
      const { id, selectColor, gizmoId } = payload;
      const object = state.persistProps.geoContentMap[id];

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...object,
              decoration: colorHoverDecoration(selectColor, object, gizmoId),
              originalDeco: object.decoration,
              hover: Hover.COLORING,
            },
          },
        },
      };
    },
    [OUT]: (state: GeoColoringState, { payload }: Action<OutPayload>) => {
      if (!payload) {
        return state;
      }

      const { id } = payload;

      const { hover, originalDeco, ...object } = state.persistProps.geoContentMap[id];

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...object,
              decoration: (!hover ? object.decoration : originalDeco) || {},
            },
          },
        },
      };
    },
  },
  initialState
);
