import {
  DASH_DOT_LINE_DASH_LENGTH,
  DASH_DOT_LINE_DOT_RADIUS,
  DASHED_LINE_DASH_LENGTH,
  getNamedColor,
} from '@bettermarks/importers';
import { type GeoDecoration, GeoEditorMode, LineStyle, LineWeight } from '@bettermarks/gizmo-types';

import geoStyles from '../Geo.scss';

export const getObjectColor = (decoration: GeoDecoration, property = 'color'): string | undefined =>
  getNamedColor(decoration[property]);

export const getSnapColor = (mode: GeoEditorMode) =>
  mode === GeoEditorMode.DELETE ? geoStyles.DELETE_HOVER_COLOR : geoStyles.DEFAULT_HOVER_COLOR;

export const getTickWidth = (decoration: GeoDecoration): number => {
  switch (decoration.lineWeight) {
    case LineWeight.medium:
      return 2;
    case LineWeight.thick:
      return 4;
    case LineWeight.thin:
    default:
      return 1;
  }
};

export const getLineStyle = (ls: LineStyle | undefined, lineWeight: number): string => {
  const dashSpace = 2 * lineWeight;

  switch (ls) {
    case LineStyle.dashed:
      return `${DASHED_LINE_DASH_LENGTH}, ${dashSpace}`;
    case LineStyle.dashDot:
      return `${DASH_DOT_LINE_DASH_LENGTH}, ${dashSpace},
    ${DASH_DOT_LINE_DOT_RADIUS}, ${dashSpace}`;
    default:
      return 'none';
  }
};
