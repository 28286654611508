import * as React from 'react';
import classNames from 'classnames';
import { LightBoxType } from '../../../../types';

import styles from './LightBox.scss';

export type LightBoxDispatchProps = {
  onClose?: () => void;
};

export type LightBoxStateProps = {
  lightBoxType?: LightBoxType;
};

export type LightBoxProps = LightBoxDispatchProps & LightBoxStateProps;

/**
 * Responsive LightBox Component used to display different LightBoxs
 *
 * ### Process State
 *
 * ### Properties
 | Name            | Type                   | Default    | Description                          |
 |---              | ---                    |---         | ---                                  |
 | onClose         | `function`             | Required   | Event handler to close the LightBox  |
 | lightBoxType   | `LightBoxType`         | Required   | Which lightbox to display as content |
 */
export const LightBox: React.FC<LightBoxProps> = ({ onClose, lightBoxType }) => {
  let lightBoxContent: React.ReactElement | undefined;
  const fullscreen = false;
  switch (lightBoxType) {
    case LightBoxType.none:
      lightBoxContent = undefined;
      break;
    default:
  }

  return (
    <div
      className={classNames(styles.shadowbox, lightBoxContent ? styles.visible : styles.invisible)}
      role="presentation"
      onClick={onClose}
    >
      <div
        className={classNames(styles.lightBox, {
          [styles.fullscreen]: fullscreen,
        })}
      >
        {lightBoxContent}
      </div>
    </div>
  );
};

LightBox.displayName = 'LightBox';
