import * as React from 'react';
import styles from './Keyboard.scss';
import classNames from 'classnames';
import { compact } from 'lodash';
import { KeyboardLayout, type LayoutProps } from './KeyboardLayout';
import { CharBlock } from './CharBlock';
import { NumBlock } from './NumBlock';
import { OperatorBlock } from './OperatorBlock';
import { SpecialKeys } from './SpecialKeys';
import { KeyPage } from './KeyPage';
import { KeyNumbers, KeyOperators, KeyLetters } from '../../icons/Keyboard';
import { sortOperators, padOperators } from './helpers';

export const SmallLayout: React.FC<LayoutProps> = ({
  hasNumbers,
  hasDecimalPoint,
  hasLetters,
  onKey,
  onTogglePage,
  operators,
  selectedPage,
}) => (
  <KeyboardLayout
    size="small"
    toggles={compact([
      hasNumbers && KeyNumbers,
      hasLetters && KeyLetters,
      operators.length > 0 && KeyOperators,
    ])}
    selectedPage={selectedPage}
    onTogglePage={onTogglePage}
  >
    {hasNumbers && (
      <KeyPage onKey={onKey}>
        <NumBlock hasDecimalPoint={hasDecimalPoint} onKey={onKey} onSinglePage={true} />
      </KeyPage>
    )}
    {
      /*
       * The char block here is a bit non standard, as it doesn't have the same key sizes as the
       * other blocks. This is why it needs extra styling and also extra structure, because the
       * special keys keep the standard size.
       */
      hasLetters && (
        <div className={styles.charPage}>
          <div className={classNames(styles.charBlock, styles.keyPage)}>
            <CharBlock onKey={onKey} />
            <SpecialKeys onKey={onKey} />
          </div>
        </div>
      )
    }
    <KeyPage onKey={onKey}>
      <OperatorBlock onKey={onKey} operators={padOperators(sortOperators(operators), 3)} />
    </KeyPage>
  </KeyboardLayout>
);

SmallLayout.displayName = 'SmallLayout';
