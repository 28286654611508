import * as React from 'react';

import styles from './Donut.scss';

export type DonutProps = {
  current: number;
  progress: number;
};

/**
 * Donut Component
 * Used to show the current exercise and the progress in Header for the Mobile screens
 *
 * ### Process State
 *
 * ### Properties
 | Name            | Type        | Default    | Description           |
 |---              | ---         |---         | ---                   |
 | current         | number      | Required   | The current index to show inside donut |
 | progress        | number      | Required   | % of donut to show complete       |
 */

const Donut: React.FC<DonutProps> = ({ current, progress }) => {
  const donutProgress = Math.round(progress * 180);
  const donutStyle: React.CSSProperties = {
    transform: `rotate(${donutProgress}deg)`,
  };
  return (
    <div className={styles.donut}>
      <div className={styles.donutText}>{current}</div>
      {donutProgress > 0 && (
        <div>
          <div className={styles.mask}>
            <div className={styles.bar} style={donutStyle} />
          </div>
          <div className={styles.mask} style={donutStyle}>
            <div className={styles.bar} style={donutStyle} />
          </div>
        </div>
      )}
    </div>
  );
};

Donut.displayName = 'Donut';

export default Donut;
