import React from 'react';
import isFunction from 'lodash/isFunction';
import styled, { css } from 'styled-components';
import dimensions from '../../sass/dimensions';
import {
  applyActiveDecoration,
  applyColorDecoration,
  applyHoverDecoration,
  applySizeDecoration,
} from './decorations';
import { type ButtonBackground, ButtonKind, type ButtonSize } from './types';

export interface StyledButtonProps {
  /**
   * Coloring of button that indicates the kind of user action.
   */
  kind: ButtonKind;
  /**
   * Button Id
   */
  id?: string;
  /**
   * Data attribute for cypress tests
   */
  dataCy?: string;

  className?: string;
  /**
   * Event handler (the button is disabled when none is passed.
   * `false` is here to support short circuit boolean expressions
   */
  onClick?: false | ((evt: React.MouseEvent<HTMLButtonElement>) => void) | (() => void);

  type?: string;
  /**
   * Determines the size and spacing of the button.
   * @default 'm'
   */
  size?: ButtonSize;
  /**
   * Where to apply button area color.
   * @default 'filled'
   */
  background?: ButtonBackground;

  disableFocusOutline?: boolean;
  /**
   * CR: add option to have an extended padding of 12px on the left & right
   */
  useExtendedPadding?: boolean;
  /**
   * CR: add option for button transitions
   */
  useButtonTransition?: boolean;

  tabIndex?: number;
}

export const ChildrenWrapper = styled.span`
  padding: 0 ${dimensions.btnPaddingTiny};

  svg {
    vertical-align: middle;
  }
`;

const Button: React.FC<StyledButtonProps> = ({
  id,
  dataCy,
  className,
  onClick,
  kind,
  children,
  tabIndex = -1,
}) => (
  <button
    id={id}
    data-cy={dataCy}
    className={className}
    onClick={onClick ? onClick : undefined}
    disabled={kind === ButtonKind.disabled}
    tabIndex={tabIndex}
  >
    {React.isValidElement(children) || !isFunction(children) ? (
      <ChildrenWrapper>{children}</ChildrenWrapper>
    ) : (
      children(ChildrenWrapper)
    )}
  </button>
);

export const StyledButton = styled(Button)`
  box-sizing: border-box;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: ${dimensions.borderWidthM} solid transparent;
  border-radius: ${dimensions.borderRadiusM};
  background-color: transparent;
  cursor: inherit;
  white-space: nowrap;
  min-width: ${(props) =>
    props.type === 'dialog' ? dimensions.dialogButtonMinWidth : dimensions.minClickableWidth};

  ${(p) => (p.disableFocusOutline ? ':focus { outline: none; }' : '')}

  ${applyColorDecoration}
  ${applyHoverDecoration}
  ${applyActiveDecoration}
  ${applySizeDecoration(ChildrenWrapper)}

  ${(p) =>
    p.useExtendedPadding &&
    css`
      padding-left: ${dimensions.btnPaddingExtended};
      padding-right: ${dimensions.btnPaddingExtended};
    `}

  ${(p) =>
    p.useButtonTransition &&
    css`
      transition: background-color 0.25s ease-in-out, border 0.25s ease-in-out;
    `}
`;
