import log from 'loglevel';
import {
  enrichCursor,
  enrichMCal,
  enrichMExpansion,
  enrichMFrac,
  enrichMO,
  enrichMOver,
  enrichMRoot,
  enrichMSpace,
  enrichMSqrt,
  enrichMSub,
  enrichMSubSup,
  enrichMSup,
  enrichMToken,
  enrichMUnder,
  enrichMUnderOver,
} from '../components';
import { enrich } from '../components/helpers';
import { enrichMFenced, enrichMRow } from '../components/MContainer';
import { type EnrichedContent } from '../../../../gizmo-utils/configuration';
import {
  type ContentPath,
  DEFAULT_LAYOUT_METRICS,
  type FormulaStyles,
  FT,
  type MetricsGetter,
  type StyleResolver,
} from '@bettermarks/gizmo-types';

export type EnrichMathContent<T> = (
  formulaStyles: FormulaStyles,
  content: T,
  path: ContentPath
) => EnrichedContent<T>;

export type EnrichNestedMathContent<T> = (
  formulaStyles: FormulaStyles,
  content: T,
  path: ContentPath,
  mathContentEnricher: EnrichMathContent<FT.MathContent>
) => EnrichedContent<T>;

/* eslint-disable complexity */
/**
 * Function that is used to measure formula children's height and refLine based on their content.
 *
 * All 'M-tags' implemented as UI-components need to register their measure/enrich method here.
 *
 * @param {FormulaStyles} outerStyles
 * @param {MathContent} content
 * @param {ContentPath} path
 * @param {MetricsGetter} getMetrics
 * @param {(path: ContentPath) => FormulaStyles} getInnerStyles
 * @param {EnrichMathContent<MathContent>} mathContentEnricher
 * @returns {EnrichedContent<MathContent>}
 */
const enrichMathContent = (
  outerStyles: FormulaStyles,
  content: FT.MathContent,
  path: ContentPath,
  getMetrics: MetricsGetter,
  getInnerStyles: (path: ContentPath) => FormulaStyles,
  mathContentEnricher: EnrichMathContent<FT.MathContent>
): EnrichedContent<FT.MathContent> => {
  if (FT.isContentReference(content)) {
    return enrich(content, getMetrics(content.$refid));
  }
  const mergedStyles = getInnerStyles(path);

  switch (content.$) {
    case FT.$CURSOR:
      return enrichCursor(mergedStyles, content, path);
    case FT.$MCAL:
      return enrichMCal(mergedStyles, content, path);
    case FT.$MO:
      return enrichMO(mergedStyles, content, path);
    case FT.$MI:
    case FT.$MN:
    case FT.$MTEXT:
      return enrichMToken(mergedStyles, content, path);
    case FT.$MABS:
    case FT.$MFENCED:
      return enrichMFenced(mergedStyles, content, path, mathContentEnricher);
    case FT.$MEXPANSION:
      return enrichMExpansion(mergedStyles, content, path, mathContentEnricher);
    case FT.$MROW:
      return enrichMRow(mergedStyles, content, path, mathContentEnricher);
    case FT.$MFRAC:
      return enrichMFrac(mergedStyles, content, path, mathContentEnricher);
    case FT.$MOVER:
      return enrichMOver(mergedStyles, content, path, mathContentEnricher);
    case FT.$MROOT:
      return enrichMRoot(mergedStyles, content, path, mathContentEnricher);
    case FT.$MSPACE:
      return enrichMSpace(mergedStyles, content, path);
    case FT.$MSQRT:
      return enrichMSqrt(mergedStyles, content, path, mathContentEnricher);
    case FT.$MSUB:
      return enrichMSub(mergedStyles, content, path, mathContentEnricher);
    case FT.$MSUBSUP:
      return enrichMSubSup(mergedStyles, content, path, mathContentEnricher);
    case FT.$MSUP:
      return enrichMSup(mergedStyles, content, path, mathContentEnricher);
    case FT.$MUNDER:
      return enrichMUnder(mergedStyles, content, path, mathContentEnricher);
    case FT.$MUNDEROVER:
      return enrichMUnderOver(mergedStyles, content, path, mathContentEnricher);
    default:
      let contentString;
      try {
        contentString = JSON.stringify(content);
      } catch (error) {
        contentString = 'N/A';
      }
      log.warn({
        message: 'no enrichMathContent function found for content',
        extra: { content: contentString, contentPath: path.join(':') },
      });
      return enrich(content, DEFAULT_LAYOUT_METRICS, mergedStyles);
  }
};

/**
 * Creates an enricher function (`EnrichMathContent`) that is hoisting `getMetrics` and itself,
 * to allow walking the tree without passing those values at every level.
 *
 * @param {MetricsGetter} getMetrics the map of refId to LayoutMetrics
 * @param {StyleResolver} getInnerStyles
 * @returns {EnrichMathContent<MathContent>} the Function that can be used to enrich MathContent,
 *          using `getMetrics` but without passing it on every level.
 *
 * @see enrichMathContent
 */
export const MathContentEnricher = (
  getMetrics: MetricsGetter,
  getInnerStyles: StyleResolver
): EnrichMathContent<FT.MathContent> => {
  const mathEnricher: EnrichMathContent<FT.MathContent> = (formulaStyles, content, path) =>
    enrichMathContent(formulaStyles, content, path, getMetrics, getInnerStyles, mathEnricher);

  return mathEnricher;
};
