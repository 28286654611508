import * as React from 'react';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { Formula, FormulaInput } from '../../formula/Formula';
import { type FieldSetContent } from '@bettermarks/gizmo-types';

export type FieldSetProps = FieldSetContent & ContextState;

export const FieldSet: React.FC<FieldSetProps> = (fieldSetProps: FieldSetProps) => {
  const { disabled, hasInteractiveChild, fieldSetDecoration } = fieldSetProps;

  const children = <Formula {...fieldSetProps} />;

  return hasInteractiveChild ? (
    <FormulaInput decoration={fieldSetDecoration} disabled={disabled} tabIndex={-1}>
      {children}
    </FormulaInput>
  ) : (
    children
  );
};

FieldSet.displayName = 'FieldSet';
