import * as React from 'react';
import classNames from 'classnames';
import styles from './graph.scss';

import {
  type BubbleNode,
  type GizmoNode,
  type Graph,
  NodeType,
  type Size,
} from '@bettermarks/gizmo-types';
import { BubbleNodeComponent } from './BubbleNode';
import { GraphEdgeComponent } from './GraphEdge';
import { GizmoNodeComponent } from './GizmoNode';
import { INVISIBLE_NODE } from '@bettermarks/importers';
import { type DragScrollBehaviour } from '../../../gizmo-utils/drag-scroll-behaviour';

export interface GraphCallbacks {
  readonly onNodeSize?: (nodeId: string, size: Size) => void;
}

export type GraphRendererProps = Pick<
  Graph,
  'edges' | 'nodes' | 'template' | 'ticksWidth' | 'ticksHeight'
> & {
  readonly availableWidth: number;
  readonly border?: boolean;
  readonly width: number;
  readonly height: number;
  readonly ref?: React.MutableRefObject<HTMLDivElement | undefined>;
  readonly scrollBehaviour?: DragScrollBehaviour;
} & GraphCallbacks;

/**
 * Render a graph of nodes connected with edges. We render it in three layers:
 * - Edges (SVG)
 * - Nodes (SVG)
 * - Gizmo Nodes (HTML)
 */
export const GraphRenderer: React.FC<GraphRendererProps> = (props) => {
  const { border, edges, nodes, width, height, onNodeSize } = props;

  // Styles for the html layer on top.
  const htmlLayerStyle = {
    position: 'absolute' as const,
    width: `${Math.round(width)}px`,
    height: `${Math.round(height)}px`,
    left: 0,
    top: 0,
  };

  const graphRendererContent = (
    <>
      <svg width={width} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="xMidYMid">
        <g>
          {nodes
            .filter((n) => n.type === NodeType.bubble)
            .map((n: BubbleNode) => (
              <BubbleNodeComponent key={n.id} node={n} />
            ))}
        </g>
        <g>
          {edges.map((e) => (
            <GraphEdgeComponent
              key={`edge-${e.nodeId1}-${e.nodeId2}`}
              edge={e}
              node1={nodes.find((n) => n.id === e.nodeId1) || INVISIBLE_NODE}
              node2={nodes.find((n) => n.id === e.nodeId2) || INVISIBLE_NODE}
            />
          ))}
        </g>
      </svg>
      <div style={htmlLayerStyle}>
        {nodes
          .filter((n) => n.type === NodeType.gizmo)
          .map((n: GizmoNode) => (
            <GizmoNodeComponent
              onNodeSize={onNodeSize && onNodeSize.bind(null, n.id)}
              key={n.id}
              node={n}
              availableWidth={Math.round(width)}
              border={n.border || false}
            />
          ))}
      </div>
    </>
  );

  return (
    <div
      role="button"
      style={{ width: htmlLayerStyle.width }}
      className={classNames(styles.graph, { [styles.border]: border })}
    >
      {graphRendererContent}
    </div>
  );
};

GraphRenderer.displayName = 'GraphRenderer';
