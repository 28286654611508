import { flatten, omit, isNil } from 'lodash';
import {
  type FunctionParameter,
  type ParametricFunction,
  PARAMETER_DEFAULTS,
} from '@bettermarks/gizmo-types';
import { Severity } from '@bettermarks/umc-kotlin';

export type SwitchFunction<T> = (key: string, ...p: any[]) => T;

const TOTAL_DROPDOWN_BUTTON_HEIGHT = 66;
const MINIMAL_HEIGHT_WHEN_DROPDOWN_IS_SHOWN = 174;

export const mapSliders = (fs: ReadonlyArray<ParametricFunction>): FunctionParameter[] =>
  flatten(fs.map((f) => f.parameters)).filter((p) => !isNil(p.refId));

export const switchStatement =
  <T>(map: { [key: string]: (...p: any[]) => T }, def: T): SwitchFunction<T> =>
  (key: string, ...p) =>
    key in map ? map[key](...p) : def;

/**
 * gets the key-value pairs of the function paramaters of a given parametric
 * function except for the values of the tangent parameter 'tx' and the secant
 * paramters 'sx1' and 'sx2'
 * @param {ParametricFunction} f
 * @returns {[key: string]: number}
 */
export const functionParams = (f: ParametricFunction): { [key: string]: number } =>
  f.parameters
    .filter((p) => p.name !== 'sx1' && p.name !== 'sx2' && p.name !== 'tx')
    .reduce((acc, p) => ({ ...acc, [p.name]: p.value * p.unitValue }), {});

/**
 * gets the value of the 'tangent' parameter 'tx' of a given parametric function
 * @param {ParametricFunction} f
 * @returns {number}
 */
export const tangentParamValue = (
  f: ParametricFunction,
  tx = f.parameters.find((p) => p.name === 'tx')
): number => (tx ? tx.value * tx.unitValue : NaN);

/**
 * gets the two x-values of the 'secant' parameters 'sx1' and 'sx2' of a given
 * parametric function
 * @param {ParametricFunction} f
 * @returns {[number, number]} - the values of the 'secant params' sx1 and sx2
 * or [NaN, NaN], if one of them or both do not exist or have proper values.
 */
export const secantParamValues = (
  f: ParametricFunction,
  sx = f.parameters.filter((p) => p.name === 'sx1' || p.name === 'sx2')
): [number, number] =>
  sx.length === 2 ? [sx[0].value * sx[0].unitValue, sx[1].value * sx[1].unitValue] : [NaN, NaN];
export const setFunctionParameter = (
  id: string,
  value: number,
  functions: ReadonlyArray<ParametricFunction>
) =>
  functions.map((f) => ({
    ...f,
    parameters: f.parameters.map((p) =>
      p.refId === id
        ? {
            ...PARAMETER_DEFAULTS, // because omit returns a partial type
            ...omit(p, ['severity']), // reset severity
            value: value,
          }
        : p
    ),
  }));

/**
 * Checks for error
 * @param p
 */
export const isError = (p: FunctionParameter): boolean =>
  Boolean(p.severity && p.severity === Severity.error);

/**
 * calculates minimal slider size
 * @param geoHeight
 * @param fewSliders
 * @param responsiveBreakpoint
 */
export const getSliderBoundariesSize = (
  geoHeight: number,
  fewSliders: boolean,
  responsiveBreakpoint: number
): number => {
  return Math.max(
    fewSliders ? MINIMAL_HEIGHT_WHEN_DROPDOWN_IS_SHOWN : responsiveBreakpoint,
    geoHeight - (fewSliders ? TOTAL_DROPDOWN_BUTTON_HEIGHT : 0)
  );
};
