import { Clock } from '../components/Clock';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';
import { type ClockGizmoContent } from '@bettermarks/gizmo-types';
import { CLOCK_SIZE } from './constants';

export type ClockGizmoProps = ClockGizmoContent & ContextState;

/**
 * Analogic `Clock` gizmo.
 *
 */
export const ClockGizmo: React.FC<ClockGizmoProps> = ({ hour, minute }) => (
  <Clock width={CLOCK_SIZE} height={CLOCK_SIZE} {...{ hour, minute }} />
);
