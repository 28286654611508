import * as React from 'react';
import { type CommonCallbacks, type Content } from '@bettermarks/gizmo-types';

import { type GizmoProps } from '../polymorphic-gizmo';
import { type UnregisterShortcutsPayload } from '../redux/gizmoActions';
import { type GizmoShortcutsMap, type ShortcutsMap } from './shortcutRegistry';

export interface ShortcutCallbacks {
  /**
   * called when shortcuts are availble. Use it to put them into the store.
   */
  onRegisterShortcuts: (shortcuts: ShortcutsMap) => void;
  /**
   * called when shortcuts should be removed. Use it to remove them from the store.
   */
  onUnregisterShortcuts: (payload: UnregisterShortcutsPayload) => void;
}

/**
 * HOC to register shortcuts for a component,
 * * when it's mounted and
 * * unregister them, when it's unmounted.
 *
 * TODO: we should refactor this, when we use the new version of react-redux:
 * The withShortcut HOC should dispatch the registerShortcuts action itself
 * when it mounts. This could be done by grabbing the dispatch function from the
 * context with `const {dispatch} = useContext(ReactReduxContext);`
 */
export const withShortcuts = <P extends object>(
  shortcuts: ShortcutsMap,
  WrappedComponent: React.ComponentType<P>
) => {
  return class extends React.Component<P & ShortcutCallbacks> {
    componentDidMount(): void {
      this.props.onRegisterShortcuts(shortcuts);
    }

    componentWillUnmount(): void {
      this.props.onUnregisterShortcuts({ shortcuts });
    }

    render(): JSX.Element {
      return <WrappedComponent {...this.props} />;
    }
  };
};

type WrapShortcuts = (shortcuts: GizmoShortcutsMap, refid: string) => GizmoShortcutsMap;
export const wrapShortcuts: WrapShortcuts = (shortcuts, refid) =>
  Object.keys(shortcuts).reduce<GizmoShortcutsMap>(
    (gizmoShortcutsMap, shortcutKey) =>
      shortcutKey in shortcuts
        ? {
            ...gizmoShortcutsMap, // {} | {[key]: {...}}
            [shortcutKey]: {
              ...shortcuts[shortcutKey], // GizmoAction<any>
              meta: { ...shortcuts[shortcutKey].meta, gizmoId: refid },
            },
          }
        : gizmoShortcutsMap,
    {}
  );

export const withShortcutsOnSelection = <P extends Content & CommonCallbacks>(
  WrappedComponent: React.ComponentType<P>,
  shortcuts?: GizmoShortcutsMap
): React.ComponentType<P> =>
  shortcuts
    ? class extends React.Component<P & GizmoProps & ShortcutCallbacks> {
        private _wrappedShortcuts: GizmoShortcutsMap;

        componentDidMount(): void {
          this._wrappedShortcuts = wrapShortcuts(shortcuts, this.props.refid);
          this.props.selected && this.props.onRegisterShortcuts(this._wrappedShortcuts);
        }

        componentDidUpdate(prevProps: P & GizmoProps & ShortcutCallbacks): void {
          if (this.props.refid !== prevProps.refid) {
            /*
            If we get new content (e.g. after validation) we need to rewrite the
            shortcuts, because of the new refid.
            */
            this.props.onUnregisterShortcuts({
              gizmoId: prevProps.refid,
              shortcuts: this._wrappedShortcuts,
            });
            this._wrappedShortcuts = wrapShortcuts(shortcuts, this.props.refid);
            this.props.selected && this.props.onRegisterShortcuts(this._wrappedShortcuts);
            return;
          }
          if (this.props.selected && !prevProps.selected) {
            this.props.onRegisterShortcuts(this._wrappedShortcuts);
          } else if (!this.props.selected && prevProps.selected) {
            this.props.onUnregisterShortcuts({
              gizmoId: prevProps.refid,
              shortcuts: this._wrappedShortcuts,
            });
          }
        }

        render(): JSX.Element {
          return <WrappedComponent {...this.props} />;
        }
      }
    : WrappedComponent;
