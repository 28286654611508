import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { connect } from 'react-redux';
import { type ApplicationState, type ExerciseDisplayStatus } from '../../../../../types';
import { getExerciseDisplayStatus } from '../../Series/helpers';
import { showResults } from '../../SeriesPlayer/actions';
import {
  ExerciseOverview,
  type ExerciseOverviewDispatchProps,
  type ExerciseOverviewStateProps,
} from './ExerciseOverview';

const mapDispatchToProps = (dispatch: Dispatch): ExerciseOverviewDispatchProps => ({
  onCancel: () => {
    dispatch(showResults({ animate: false }));
  },
});

const mapStateToProps = (state: ApplicationState): ExerciseOverviewStateProps => {
  const {
    series: { exercises },
  } = state;
  const exerciseDisplayStatus: ExerciseDisplayStatus[] = getExerciseDisplayStatus(exercises);
  return {
    exerciseStatuses: exerciseDisplayStatus,
  };
};

export const ExerciseOverviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseOverview);
