export type CellCoordinate = {
  x: number;
  y: number;
};

export type TableCellContent = {
  coord: CellCoordinate;
  content: JSX.Element;
  cellStyle?: React.CSSProperties;
  className?: string;
};

export enum LineStyle {
  single = 'single',
  thick = 'thick',
  double = 'double',
}

type LineCoordinate = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

export type Line = {
  style: LineStyle;
  coord: LineCoordinate;
};
