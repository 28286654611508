import { ScaffoldingRow } from '../styled-components';
import React from 'react';
import { type Content } from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { getFormulaStringContent } from '../helpers';

export type RowWithTransformationProps = {
  left: string | React.ReactElement;
  transformation: Content | undefined;
  transformationIndex: number;
};

export const transformationSeparator = (
  <span style={{ fontSize: '30px', position: 'relative', top: '-3px' }}> | </span>
);

export default function RowWithTransformation({
  left,
  transformation,
  transformationIndex,
}: RowWithTransformationProps) {
  const transformationElement = transformation?.$interactionType ? (
    <PolymorphicGizmo availableWidth={400} refid={`transformations:${transformationIndex}`} />
  ) : (
    getFormulaStringContent(transformation, true) || '?'
  );

  return (
    <ScaffoldingRow>
      {left}
      {transformation && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            gap: '8px',
            width: '145px',
          }}
        >
          {transformationSeparator}
          {transformationElement}
        </div>
      )}
    </ScaffoldingRow>
  );
}
