import {
  type ContentReference,
  type ControlOrientation,
  ControlPosition,
  type ControlAppearance,
  type FElement,
  type IDynamicRepresentationContent,
  type Importer,
  type ImporterContext,
  MATH,
  type OnOffControl,
  type OptionsControl,
  parseCommonAttribs,
  RS,
  SEMANTICS,
  type Slider,
  type ValueStepper,
  type ValueStepperPosition,
  xmlTagsToProperties,
  xmlTextToFloat,
} from '@bettermarks/gizmo-types';
import { parseDecoration } from '../formula/importer';
import log from 'loglevel';

/**
 * Imports a label for the slider, if a label exists.
 * @param config
 * @param context
 */
export const importControlLabel = (
  config: FElement,
  context: ImporterContext
): ContentReference | undefined =>
  config.hasChild('label') && config.findChildTag('label').hasChild('math')
    ? context.importXML(config.findChildTag('label').findChildTag('math'))
    : undefined;

/**
 * Parses a position to enum, not caring about lower/uppercase.
 * @param position
 */
function parseControlPosition(position: string): ControlPosition {
  const mapStringToEnum = {
    right: ControlPosition.right,
    left: ControlPosition.left,
    bottom: ControlPosition.bottom,
  };

  return mapStringToEnum[position.toLowerCase()] ?? ControlPosition.right;
}

/**
 * Parses an orientation to a string union, not caring about lower/uppercase.
 * @param orientation
 */
function parserSliderOrientation(orientation: string): ControlOrientation {
  orientation = orientation.toLowerCase();
  if (orientation === 'vertical' || orientation === 'horizontal') {
    return orientation;
  }
  return 'horizontal';
}

const parseSliderSemantics =
  (context: ImporterContext) =>
  (sliderXML: FElement): Slider => {
    const mrow = sliderXML.findChildTag('mrow');
    const config = mrow.findChildTag('configuration');

    const { snapInterval } = xmlTagsToProperties(config.children, (tag) => parseFloat(tag.text), [
      'snapInterval',
    ]);

    const { min, max } = xmlTagsToProperties(
      config.children,
      (tag) => {
        if (tag.hasAttribute('dynamic')) {
          return tag.attribute('dynamic');
        } else if (tag.text) {
          return parseFloat(tag.text);
        } else {
          throw new Error(`Couldn't find a value for the slider's ${tag.tagName}.`);
        }
      },
      ['min', 'max']
    );

    const position = parseControlPosition(config.findChildTag('position').text);
    let orientation = parserSliderOrientation(config.findChildTag('orientation').text);
    if (position === 'bottom' && orientation === 'vertical') {
      log.warn('You cannot set the orientation to vertical on a bottom slider!');
      orientation = 'horizontal';
    }

    const showValue = config.hasChild('showValue');
    const value = parseFloat(mrow.findChildTag('mn').text);

    const { binding } = parseCommonAttribs(sliderXML);

    const label = importControlLabel(config, context);

    return {
      min,
      max,
      snapInterval,
      value,
      decoration: parseDecoration(mrow.attribute('decoration')).decoration,
      binding: binding || '',
      orientation,
      position,
      showValue,
      label,
    };
  };

const findSliders = (xml: FElement, context: ImporterContext): Slider[] => {
  const mrow = xml.findChildTag('mrow');

  const sliderSemantics = mrow.children.filter((c) => {
    if (c.localName === SEMANTICS || c.localName === MATH) {
      const annotationProps = parseCommonAttribs(c);
      return annotationProps.$renderStyle === RS.SLIDER;
    } else {
      return false;
    }
  });

  return sliderSemantics.map(parseSliderSemantics(context));
};

const importOnOffControl =
  (context: ImporterContext) =>
  (xml: FElement): OnOffControl => {
    const label = importControlLabel(xml, context) as ContentReference;
    const { position, startValue } = xmlTagsToProperties(xml.children, (child) => child.text, [
      'position',
      'startValue',
    ]);
    const binding = xml.attribute('id');

    return {
      label,
      position: position as ControlPosition,
      value: parseInt(startValue),
      binding,
    };
  };

const importOptionsControl =
  (context: ImporterContext) =>
  (xml: FElement): OptionsControl => {
    const label = importControlLabel(xml, context);
    const { position, startValue, orientation, appearance } = xmlTagsToProperties(
      xml.children,
      (child) => child.text,
      ['position', 'startValue', 'orientation', 'appearance']
    );
    const binding = xml.attribute('id');

    const options = xml.findChildTag('options').children.map((o) => context.importXML(o));

    return {
      label,
      options,
      position: position as ControlPosition,
      orientation: orientation as ControlOrientation,
      value: parseInt(startValue),
      binding,
      appearance: appearance as ControlAppearance,
    };
  };

const importValueStepper =
  (context: ImporterContext) =>
  (xml: FElement): ValueStepper => {
    const { position, startValue } = xmlTagsToProperties(xml.children, (child) => child.text, [
      'position',
      'startValue',
    ]);
    const { step } = xml.tagsToProps(xmlTextToFloat, ['step']);
    const binding = xml.attribute('id');
    const initiallyActive = xml.hasChild('initiallyActive') ? true : undefined;
    const decoration = parseDecoration(xml.attribute('decoration')).decoration;

    const { min, max } = xmlTagsToProperties(
      xml.children,
      (tag) => {
        if (tag.hasAttribute('dynamic')) {
          return tag.attribute('dynamic');
        } else if (tag.text) {
          return parseFloat(tag.text);
        } else {
          throw new Error(`Couldn't find a value for the value stepper's ${tag.tagName}.`);
        }
      },
      ['min', 'max']
    );

    return {
      min,
      max,
      step,
      position: position as ValueStepperPosition,
      value: parseFloat(startValue),
      binding,
      initiallyActive,
      decoration,
    };
  };

const findOnOffControls = (xml: FElement, context: ImporterContext): OnOffControl[] => {
  const mrow = xml.findChildTag('mrow');

  const onOffControlNodes = mrow.children.filter((node) => node.localName === 'onOffControl');

  return onOffControlNodes.map(importOnOffControl(context));
};

const findOptionsControls = (xml: FElement, context: ImporterContext): OptionsControl[] => {
  const mrow = xml.findChildTag('mrow');

  const optionsControlNodes = mrow.children.filter((node) => node.localName === 'optionsControl');

  return optionsControlNodes.map(importOptionsControl(context));
};

const findValueSteppers = (xml: FElement, context: ImporterContext): ValueStepper[] => {
  const mrow = xml.findChildTag('mrow');

  const valueStepperNodes = mrow.children.filter((node) => node.localName === 'valueStepper');

  return valueStepperNodes.map(importValueStepper(context));
};

export const importDynamicRepresentation: Importer<IDynamicRepresentationContent> = (
  preContent,
  xml,
  context
) => {
  return {
    ...preContent,
    sliders: findSliders(xml, context),
    onOffControls: findOnOffControls(xml, context),
    optionsControls: findOptionsControls(xml, context),
    valueSteppers: findValueSteppers(xml, context),
    representation: context.importXML(xml.findChildTag('mrow').firstChild),
  };
};
