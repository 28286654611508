import { type ApplyStyles, type GizmoStyleForRefId } from '../../gizmo-utils/configuration';
import { type FunctionPlotterContent, type GeoContent } from '@bettermarks/gizmo-types';
import { applyGeoStyles } from '../geo/measure';

export const applyFunctionPlotterStyles: ApplyStyles = (
  { functions, geo }: FunctionPlotterContent,
  outerStyles,
  innerStyles
) => {
  const functionPlotterStyles: GizmoStyleForRefId[] = functions.reduce((previousParams, fun) => {
    const nextParams: GizmoStyleForRefId[] = fun.parameters
      .filter((param) => param.label !== undefined)
      .map((s) => ({
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        refId: s.label!.$refid,
        style: outerStyles,
      }));
    return [...previousParams, ...nextParams];
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  return [...applyGeoStyles(geo as GeoContent, outerStyles, innerStyles), ...functionPlotterStyles];
};
