import React, { useContext } from 'react';
import type { WhiteboardState } from './types';
import type { WhiteboardAction } from './reducer';

export type WhiteboardContextValue = {
  state: WhiteboardState;
  dispatch: (action: WhiteboardAction) => void;
};
export const WhiteboardContext = React.createContext<undefined | WhiteboardContextValue>(undefined);

type WhiteboardContextProviderProps = {
  children?: React.ReactNode;
  value: WhiteboardContextValue;
};

export function WhiteboardContextProvider({ children, value }: WhiteboardContextProviderProps) {
  return <WhiteboardContext.Provider value={value}>{children}</WhiteboardContext.Provider>;
}

export function useWhiteboardContext(): WhiteboardContextValue {
  const context = React.useContext(WhiteboardContext);
  if (context === undefined) {
    throw Error('useWhiteboardContext can only be used within a WhiteboardContextProvider');
  }
  return context;
}

export function useMaybeWhiteboardContext(): WhiteboardContextValue | undefined {
  return useContext(WhiteboardContext);
}
