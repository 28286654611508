import * as React from 'react';

import {
  SliderHandle,
  SliderInput,
  SliderOrientation as ReachSliderOrientation,
  SliderRange,
  SliderTrack,
} from '@reach/slider';
import styles from './Slider.scss';
import Handle, { type HandleShape } from './Handle';
import { defaultTo, noop } from 'lodash';
import classNames from 'classnames';
import { getNamedColor } from '@bettermarks/importers';
import './ReachSlider.css'; // because attribute selectors do not work in production with sass

export type HandleSide = 'left' | 'right';
export type SliderOrientation = 'vertical' | 'horizontal';

export interface GenericSliderProps {
  min?: number;
  max?: number;
  value: number;
  step?: number;
  showValue?: boolean;

  onChange: (value: number) => void;
  onFinalValue: () => void;

  color?: string;
  handleColor?: string;
  error?: boolean;
  handleSite?: HandleSide;
}

export interface SliderProps extends GenericSliderProps {
  handleShape?: HandleShape;
  orientation?: SliderOrientation;
  interactive?: boolean;
}

export interface InternSliderProps extends GenericSliderProps {
  handleShape: HandleShape;
  handleDisabled: boolean;
}

/**
 * If you use the vertical slider you have to give the height in the parent div which surrounds the
 * Slider components, otherwise the slider height will be 0 and the track will not be shown.
 * @param min the min value of the slider
 * @param max the max value of the slider
 * @param value the current value of the slider, you should provide a change function for the value
 * or the slider will not be interactive
 * @param step the step size of the slider
 * @param onChange the function to set the new value of the slider, if not set, you cannot move the slider
 * @param showValue if this is true, then the current value of the slider will be rendered and shown to the user
 * @param sliderForm 'round' or 'raindrop'
 * @param color the color of the whole slider (track + handle)
 * @param handleColor overwrites the color of the handle if set
 * @param orientation 'vertical' or 'horizontal'
 * @param handleSite the site of the handle from the track, if handle form is 'raindrop' then 'left' or 'right'
 * @param error if true, then the handle will have a red circle around it
 * @param interactive if false, then you cannot move the slider anymore
 * @constructor
 */
export default function Slider({
  min = 0,
  max = 100,
  value,
  step = 1,
  onChange,
  onFinalValue,
  showValue = false,
  handleShape = 'round',
  color,
  handleColor,
  orientation = 'horizontal',
  handleSite = 'left',
  error = false,
  interactive = true,
}: SliderProps) {
  const _handleShape: HandleShape = showValue || handleShape === 'raindrop' ? 'raindrop' : 'round';
  const _color = getNamedColor(defaultTo(color, 'bm-grey'));

  const props = {
    min,
    max,
    value,
    step,
    onChange: interactive ? onChange : noop,
    onFinalValue: interactive ? onFinalValue : noop,
    showValue,
    handleShape: _handleShape,
    color: _color,
    handleColor: handleColor !== undefined ? handleColor : _color,
    handleSite,
    error: error && !showValue,
    handleDisabled: !interactive,
  };

  if (orientation === 'horizontal') {
    return <HorizontalSlider {...props} />;
  } else {
    return <VerticalSlider {...props} />;
  }
}

export function HorizontalSlider({
  min,
  max,
  value,
  step,
  onChange,
  onFinalValue,
  showValue,
  handleShape,
  color,
  handleColor,
  error,
  handleDisabled,
}: InternSliderProps) {
  return (
    <div
      className={classNames(styles.horizontalContainer, showValue ? styles.horizontalValue : '')}
    >
      <SliderInput
        onTouchEndCapture={onFinalValue}
        onMouseUpCapture={onFinalValue}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        orientation={ReachSliderOrientation.Horizontal}
      >
        <SliderTrack style={{ height: '4px', backgroundColor: color }}>
          <SliderRange data-disabled data-orientation={'horizontal'} style={{ height: '0px' }} />
          <SliderHandle aria-disabled={true}>
            {showValue && <span className={styles.value}>{value}</span>}
            <div className={handleShape === 'round' ? styles.roundHandle : styles.raindropHandle}>
              <Handle
                handleShape={handleShape}
                color={handleColor}
                error={error}
                disabled={handleDisabled}
              ></Handle>
            </div>
          </SliderHandle>
        </SliderTrack>
      </SliderInput>
    </div>
  );
}

export function VerticalSlider({
  min,
  max,
  value,
  step,
  onChange,
  onFinalValue,
  showValue,
  handleShape,
  color,
  handleColor,
  handleSite,
  error,
  handleDisabled,
}: InternSliderProps) {
  return (
    <div
      className={classNames(
        styles.verticalContainer,
        showValue ? (handleSite === 'left' ? styles.left : styles.right) : '',
        handleShape === 'raindrop' && styles.raindrop
      )}
    >
      <SliderInput
        onTouchEndCapture={onFinalValue}
        onMouseUpCapture={onFinalValue}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        orientation={ReachSliderOrientation.Vertical}
        style={{ height: '100%' }}
      >
        <SliderTrack style={{ width: '4px', backgroundColor: color }}>
          <SliderRange data-disabled data-orientation={'horizontal'} style={{ height: '0px' }} />
          <SliderHandle aria-disabled={true}>
            {showValue && (
              <span
                className={classNames(
                  styles.value,
                  handleSite === 'left' ? styles.left : styles.right
                )}
              >
                {value}
              </span>
            )}
            <div
              className={classNames(
                handleShape === 'round' ? styles.roundHandle : styles.raindropHandle,
                showValue ? (handleSite === 'left' ? styles.left : styles.right) : styles.vertical,
                // this is for raindrop handle without value (so for thermometer gizmo)
                handleShape === 'raindrop'
                  ? handleSite === 'left'
                    ? styles.left
                    : styles.right
                  : ''
              )}
            >
              <Handle
                handleShape={handleShape}
                color={handleColor}
                error={error}
                disabled={handleDisabled}
              ></Handle>
            </div>
          </SliderHandle>
        </SliderTrack>
      </SliderInput>
    </div>
  );
}
