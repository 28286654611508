import * as React from 'react';
import { defaultTo, has, includes } from 'lodash';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  CursorPositionOffset,
  type GeoAddLabelBaseState,
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type GeoScene,
  type MouseOrTouch,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import {
  clickAction,
  closeLabelAction,
  confirmLabelAction,
  deleteLabelAction,
  geoAddLabelReducer,
  initialState,
  labelClickAction,
  snapAction,
  stopSnapAction,
  toggleLineHoverAction,
} from './geoAddLabelReducer';
import { type GeoProps } from '../../Geo';
import { GeoRenderer } from '../../GeoRenderer';
import { getLabelStepperProps, readActiveIndices } from '../helpers';
import { getSnapPoint } from '../../snap';
import { mousePos, screenToWorld } from '@bettermarks/importers';
import { persistProps } from '../persist';
import { addAdditionalButton } from './helpers';
import { getLabelId } from '../../helpers';

export type GeoAddLabelState = GeoInteractiveBaseState & GeoAddLabelBaseState;

export const GeoAddLabel: React.FC<GeoProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
      activeIndex: readActiveIndices(props.labels, props.geoContentMap),
    }}
    reducer={geoAddLabelReducer}
    componentName={`AddLabel:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const {
        configuration,
        geoContentMap,
        isTouch,
        uniqueId,
        matrix,
        onPersistLocalState,
        scale,
      } = props;
      const { labelValues } = configuration;
      const snap = (scene: GeoScene) => (evt: React.MouseEvent<any>) => {
        const mouseP = mousePos(defaultTo<string>(uniqueId, ''), CursorPositionOffset.NONE)(evt);
        const snapPoint: SnapPoint | null = getSnapPoint(matrix, scene, scale)(mouseP);
        const labelValues = configuration.labelValues;

        dispatch(snapAction({ snapPoint, labelValues, isTouch: !!isTouch }));
      };

      const stopSnap = () => dispatch(stopSnapAction());

      const onClick = (id: string) => (evt: React.MouseEvent<any>) => {
        const mouseScreenCoords = mousePos(
          defaultTo<string>(uniqueId, ''),
          CursorPositionOffset.NONE
        )(evt);
        dispatch(clickAction({ id, labelValues, mouseScreenCoords }));
      };

      const onLabelClick = (id: string) => (_: React.MouseEvent<any>) => {
        dispatch(labelClickAction({ id, labelValues }));

        // handle case that a label overlaps another object
        // (snapPoint belongs to another object one wants to label)
        dispatch(clickAction({ id, labelValues }));
      };

      const onLabelHover = () => dispatch(stopSnapAction());

      const onLineHover = (id: string) => (_: MouseOrTouch) => {
        const lineType = geoContentMap[id].type;
        if (lineType in labelValues) {
          dispatch(toggleLineHoverAction({ id, hover: true }));
        }
      };

      const onLineLeave = (id: string) => (_: MouseOrTouch) =>
        dispatch(toggleLineHoverAction({ id, hover: false }));

      const mouseWorldCoords =
        state.mouseScreenCoords !== undefined
          ? screenToWorld(matrix)({
              x: state.mouseScreenCoords.x,
              y: state.mouseScreenCoords.y,
            })
          : undefined;

      const onConfirmLabel = (index: number, labelAlignTop: boolean) => {
        dispatch(
          confirmLabelAction({
            index,
            labelAlignTop,
            onPersistLocalState,
            mouseWorldCoords,
          })
        );
      };

      const onDeleteLabel = () => {
        dispatch(deleteLabelAction({ onPersistLocalState }));
      };

      const onCloseLabel = () => dispatch(closeLabelAction());

      const autoLabeling: boolean = has(props.configuration.autoLabeling, state.labelingObjectType);

      const geoContentProps = { ...props, ...state.persistProps };
      const labelStepperProps = getLabelStepperProps(
        state,
        onConfirmLabel,
        autoLabeling
          ? addAdditionalButton(false, onCloseLabel)
          : addAdditionalButton(
              includes(state.persistProps.labels, getLabelId(state.labelingObjectId)),
              onCloseLabel,
              onDeleteLabel
            )
      );

      return (
        <GeoRenderer
          mode={GeoEditorMode.ADD_LABEL}
          onAnyOtherClick={onCloseLabel}
          onPointClick={onClick}
          onLabelClick={onLabelClick}
          onLabelOver={onLabelHover}
          onLineClick={onClick}
          onLineHover={onLineHover}
          onLineOut={onLineLeave}
          onMouseMove={snap}
          onMouseLeave={stopSnap}
          snapPoints={state.snapPoints}
          labelSteppers={[labelStepperProps]}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoAddLabel.displayName = 'GeoAddLabel';
