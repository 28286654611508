import {
  $MROW,
  CONFIGURATION,
  DECORATION,
  type FElement,
  type Importer,
  type ImporterContext,
  type SortingContent,
  SortingDirection,
  type SortingItem,
  toXmlElement,
  xmlText,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../gizmo-utils/decoration';

const parseSeverity = (semantics: FElement): Pick<SortingItem, 'severity'> => {
  const severity = parseDecoString(semantics.findChildTag($MROW).attribute(DECORATION)).severity;
  return severity ? { severity } : {};
};

const importItems = (items: FElement[], context: ImporterContext): SortingItem[] =>
  items.map((item) => {
    // parse severity from child formulas
    const severity = parseSeverity(item);
    // remove severity from child formulas
    const itemStr = item.toString().replace('error', '').replace('remark', '');
    return {
      ...context.importXML(toXmlElement(itemStr)),
      ...severity,
    };
  });

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @param context
 * @returns The metadata and parsed xml as `Content`
 */
export const importSorting: Importer<SortingContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);

  /**
   * look for size attribute and fetch its value,
   * assign default value if size attribute is not present
   */
  return {
    ...preContent,
    minText: xmlText(configuration.findChildTag('mintext')),
    maxText: xmlText(configuration.findChildTag('maxtext')),
    direction:
      xmlText(configuration.findChildTag('direction')) === SortingDirection.horizontal
        ? SortingDirection.horizontal
        : SortingDirection.vertical,
    items: importItems(mrow.getChildrenByTagName('semantics'), context),
  };
};
