import * as React from 'react';
import isEmpty from 'lodash/isEmpty';

import { BettyOops } from '../icons/draft';
import { type ContentAlign, type ImageDimensions, imageDimensions } from './imageDimensions';

import styles from './Image.scss';

export type ImageState = {
  realWidth: number;
  realHeight: number;
  detected?: boolean;
};

/**
 * props:
 * allowObjectTag - allow SVGs to use object/iframe. Defaults to true.
 * src - the image source URL
 * width - the image maximum width
 * height - the image maximum height
 * id - the DOM element id
 */
export type ImageProps = ContentAlign & {
  allowObjectTag?: boolean;
  src: string;
  width: number;
  height: number;
  scale?: number;
  id?: string;
};

const INVISIBLE: React.CSSProperties = { visibility: 'hidden' };
const DISPLAY_NONE: React.CSSProperties = { display: 'none' };

type SVGProps = {
  dim?: ImageDimensions | false;
  id?: string;
  src: string;
};

/**
 * To allow external styles and fonts inside SVG to load
 * they need to be embedded using the `<object/>` tag.
 * It will be rendered with `display:none` until `dim` is provided
 * to allow rendering as early as possible
 * `<object/>` doesn't respect style attribute,
 * the width and height set on it will be the real SVG dimension.
 */
const SVGWithExternalStyles: React.FC<SVGProps> = ({ dim, id, src }) => (
  <div
    id={id}
    className={styles.bubbleUpParent}
    style={dim ? { ...dim.real, ...dim.style } : DISPLAY_NONE}
  >
    <object type="image/svg+xml" data={src} {...(dim && dim.real)} />
    {/**
     * Transparent div on top to make sure that events, received by the area where the image is
     * rendered, bubble up
     * (Safari seems to be a bit <object> blind: http://trac.bm.loc/ticket/43881)
     **/}
    <div className={styles.bubbleUp} style={{ ...(dim && dim.real) }} />
  </div>
);

/**
 * This react hook is useful to capture the `naturalWidth` and `naturalHeight`
 * from an `img` tag.
 * It provides an `imgRef` and an `onLoad` event handler
 * that need to be used on the `img` tag to measure.
 *
 * Whenever `src` changes, any previously detected size is being reset.
 * `detected` can be used to check if the natural dimensions are already known
 * for the current `src`.
 *
 * @param src
 * @param initialWidth
 * @param initialHeight
 *
 * @returns {
 *   imgRef, onLoad, realWidth, realHeight, detected
 * }
 */
const useImageState = (src: string, initialWidth: number, initialHeight: number) => {
  const [url, setSource] = React.useState(src);
  const [state, setState] = React.useState<ImageState>({
    realWidth: initialWidth,
    realHeight: initialHeight,
  });
  if (url !== src) {
    // reset state when the URL changes since the dimensions can/will be different
    setState({ realWidth: initialWidth, realHeight: initialHeight });
    setSource(src);
  }
  const imgRef = React.useRef<HTMLImageElement>(null);
  const onLoad = () => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      setState({
        realWidth: naturalWidth,
        realHeight: naturalHeight,
        detected: true,
      });
    }
  };

  return { imgRef, onLoad, ...state };
};

/**
 * The `Image` is a component for rendering images.
 * Depending on the real dimensions of the image the image will be scaled proportionally.
 * It will always have the scaled requested dimensions in the DOM
 * using `padding` style to fill the gaps (in only one dimension) when required.
 *
 * ### Properties
 | Name            | Type      | Default   | Description        |
 |---              |---        |---        |---                        |
 | `height`        | `number`  | Required  | unscaled requested height |
 | `width`         | `number`  | Required  | unscaled requested width  |
 | `scale`         | `number`  | 1         | the scale to apply on resulting width and height|
 | `src`           | `string`  | Required  | Image source path / URL (or empty for `BettyOops`) |
 | `realHeight`    | `number`  | `width`   | unscaled original height(*) |
 | `realWidth`     | `number`  | `height`  | unscaled original width(*)  |
 | `id`            | `string`  | undefined | element id for testing      |
 | `allowObjectTag`|`boolean`  | true      | allow SVGs to use object/iframe|
 */
export const Image: React.FC<ImageProps> = ({
  width,
  height,
  scale = 1,
  src,
  id,
  allowObjectTag = true,
  ...contentAlign
}) => {
  const { imgRef, onLoad, detected, realWidth, realHeight } = useImageState(src, width, height);

  const dim = imageDimensions(width, height, realWidth, realHeight, contentAlign, scale);

  if (isEmpty(src)) {
    return <BettyOops {...dim.requested} />;
  }

  const useObjectTag = allowObjectTag && src.endsWith('.svg');
  /**
   * The img tag needs t be rendered in the following cases:
   * - rendering an SVG, invisible until we know the dimensions
   *   - the real dimensions of an SVG can not be measured using `<object/>`,
   *     only using `<img />`.
   *   - rendering `img` visible would cause "flickering"
   *     since rendering the embedded SVG takes longer in many cases
   *   - rendering the image with `display:none` has issues in drag drop items
   *     they are not measured correctly
   * - rendering PNG/GIF/JPG/... (visible all the time)
   */
  const [renderImg, imgStyle] = useObjectTag ? [!detected, INVISIBLE] : [true, dim.style];

  return (
    <>
      {renderImg && (
        <img
          className={styles.preventDnd}
          ref={imgRef}
          {...dim.real}
          id={id}
          src={src}
          style={imgStyle}
          onLoad={onLoad}
        />
      )}
      {useObjectTag && <SVGWithExternalStyles id={id} dim={detected && dim} src={src} />}
    </>
  );
};
Image.displayName = 'Image';

export default Image;
