import { type Dispatch } from '../../../../gizmo-utils/redux';
import { connect } from 'react-redux';
import { type ApplicationState, ErrorHandleKind } from '../../../../types';
import { showErrorDialog } from '../SeriesPlayer/actions';
import {
  ErrorBoundary,
  type ErrorBoundaryDispatchProps,
  type ErrorBoundaryStateProps,
} from './ErrorBoundary';
import { toLogExtra } from '../../../store/toLogExtra';

export const mapDispatchToProps = (dispatch: Dispatch): ErrorBoundaryDispatchProps => ({
  onShowErrorDialog: () => dispatch(showErrorDialog({ kind: ErrorHandleKind.confirm })),
});

const mapStateToProps = (
  state: ApplicationState,
  { hideChildrenOnError }: ErrorBoundaryStateProps
): ErrorBoundaryStateProps => ({
  hideChildrenOnError,
  toLogExtra: () => toLogExtra(state),
});

export const ErrorBoundaryContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
