import * as React from 'react';
import { type ContentColor } from '@bettermarks/gizmo-types';
import { getNamedColor } from '@bettermarks/importers';
export type ColoringIconProps = {
  color: ContentColor;
};

/**
 * ColoringIcon: Return an icon svg with the proper color included
 */
export const ColoringIcon: React.FC<ColoringIconProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g>
      <path
        d="M28.051,15.973c-.208-.718-.4-1.388-.544-1.976a5.256,5.256,0,0,0-.794-3.6,14.2,14.2,0,0,0-4.631-4.672A14.181,14.181,0,0,0,15.931,3.4c-2.054-.2-3.609.351-4.38,1.538L9.427,8.207c-3.307,1.165-5.209,2.942-5.209,4.9a3.3,3.3,0,0,0,.73,2L3.25,17.72c-.772,1.188-.639,2.832.375,4.628a14.206,14.206,0,0,0,4.631,4.671,14.2,14.2,0,0,0,6.151,2.331c.274.025.538.039.793.039a4.035,4.035,0,0,0,3.587-1.579l6.264-9.645c-.02.173-.043.359-.068.562-.209,1.648-.5,3.905.507,5.043a2.024,2.024,0,0,0,1.593.669,1.952,1.952,0,0,0,1.656-.75C29.854,22.207,28.9,18.9,28.051,15.973ZM5.533,14.205a2.216,2.216,0,0,1-.315-1.1c0-1.248,1.2-2.479,3.257-3.436Z"
        fill="#333"
      />
      <path
        d="M23.5,16.606q-.383,0-.793-.039a14.19,14.19,0,0,1-6.151-2.33c-3.068-1.992-5.058-4.605-5.431-6.81l-.7,1.077h0v0c-.558.861-.412,2.135.409,3.59a13.186,13.186,0,0,0,4.305,4.324.5.5,0,0,1,.146.692l-.541.833c.547.055,1.1.1,1.675.126a1.482,1.482,0,0,1,.143-.284,1.514,1.514,0,1,1-.16,1.287c-.784-.033-1.541-.1-2.273-.181l-3.2,4.928a.5.5,0,0,1-.316.217.515.515,0,0,1-.1.011.506.506,0,0,1-.273-.081c-3.067-1.992-5.057-4.605-5.429-6.811l-.719,1.107c-.558.86-.414,2.135.408,3.591A13.188,13.188,0,0,0,8.8,26.181a13.193,13.193,0,0,0,5.7,2.173c1.671.159,2.889-.228,3.447-1.089L25,16.4A5.859,5.859,0,0,1,23.5,16.606Z"
        fill="#ccc"
      />
      <path
        d="M9.158,10.458a6.744,6.744,0,0,0,.8,2.13,13.9,13.9,0,0,0,4.216,4.393l-.542.835c-3.363-.472-6.079-1.489-7.448-2.787ZM5.775,15.981c-.331,1.883,1.544,4.754,4.591,6.871l2.669-4.109A15.162,15.162,0,0,1,5.775,15.981Zm19.808.167a1.906,1.906,0,0,1,.514,1.413,11.838,11.838,0,0,1-.122,1.291c-.175,1.377-.439,3.457.265,4.257a1.044,1.044,0,0,0,.843.33.956.956,0,0,0,.857-.351c.814-1.083-.148-4.408-.85-6.837-.225-.777-.435-1.508-.585-2.132-.536-2-4.13-3.565-8.186-3.565a14.977,14.977,0,0,0-4.011.524A15.17,15.17,0,0,0,17.1,13.4a12.312,12.312,0,0,0,6.58,2.2C24.662,15.575,25.074,15.645,25.583,16.148Z"
        fill={getNamedColor(color)}
      />
      <path
        d="M25.84,10.894a6.277,6.277,0,0,1,.54,1.21c-1.59-1.54-4.6-2.55-8.06-2.55a15.83,15.83,0,0,0-4.72.7c-1.41-1.8-1.95-3.63-1.21-4.77a3.083,3.083,0,0,1,2.74-1.12c.23,0,.46.01.71.03a13.212,13.212,0,0,1,5.7,2.17A13.208,13.208,0,0,1,25.84,10.894Z"
        fill="#aaa"
      />
    </g>
  </svg>
);

export const makeColoringIcon =
  (color: ContentColor): React.FC =>
  () =>
    <ColoringIcon color={color} />;
