import { type Action, handleAction, combineActions } from 'redux-actions';
import { SWITCH_EXERCISE_RESUMABLE } from '../containers/Exercise/actions';
import { ApplicationState, ScreenLockType, SeriesMode } from '../../../types';
import { initialSeriesplayerState } from '../../store/constants';
import { VALIDATION_SUCCESS } from './validationSuccess';

export const handleLockScreen = handleAction(
  combineActions(SWITCH_EXERCISE_RESUMABLE, VALIDATION_SUCCESS),
  (state: ApplicationState, afterUnlockAction: Action<any>) =>
    ApplicationState.toSeriesMode.get(state) !== SeriesMode.review &&
    ApplicationState.toSeriesReportingSetting.get(state)
      ? ApplicationState.toScreenLock.set({
          type: ScreenLockType.loading,
          afterUnlockAction,
        })(state)
      : state,
  initialSeriesplayerState
);
