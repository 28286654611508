import { createAction } from 'redux-actions';
import { type MathContent } from '@bettermarks/gizmo-types';

type PlaceholderLabels = {
  row: string;
  transformation: string;
};

export const RESET_SCAFFOLDER = 'RESET_SCAFFOLDER';
export const resetScaffolder = createAction(RESET_SCAFFOLDER);

export const INITIALIZE_DUMMY_ROWS = 'INITIALIZE_DUMMY_ROWS';
export const initializeDummyRows = createAction(INITIALIZE_DUMMY_ROWS);

export type InsertEmptyRowPayload = {
  placeholderLabels: PlaceholderLabels;
};

export const INSERT_NEW_EMPTY_ROW = 'INSERT_NEW_EMPTY_ROW';
export const insertNewEmptyRow = createAction<InsertEmptyRowPayload>(INSERT_NEW_EMPTY_ROW);

export const INSERT_NEW_ROW_WITH_CONTENT_FROM_PREVIOUS_ROW =
  'INSERT_NEW_ROW_WITH_CONTENT_FROM_PREVIOUS_ROW';
export const insertNewRowWithContentFromPreviousRow = createAction(
  INSERT_NEW_ROW_WITH_CONTENT_FROM_PREVIOUS_ROW
);

export const MAKE_ALL_ROWS_NONINTERACTIVE = 'MAKE_ALL_ROWS_NONINTERACTIVE';
export const makeAllRowsNoninteractive = createAction(MAKE_ALL_ROWS_NONINTERACTIVE);

export type ShowSolutionPayload = {
  solutionContent: MathContent[];
  transformationContent: MathContent[];
  isFinalSolution: boolean;
  placeholderLabels: PlaceholderLabels;
};

export const SHOW_SCAFFOLDER_SOLUTION = 'SHOW_SCAFFOLDER_SOLUTION';
export const showScaffolderSolution = createAction<ShowSolutionPayload>(SHOW_SCAFFOLDER_SOLUTION);

export const INITIATE_ROW_VALIDATION = 'INITIATE_ROW_VALIDATION';
export const initiateRowValidation = createAction(INITIATE_ROW_VALIDATION);

export const ROW_VALIDATION_FINISHED = 'ROW_VALIDATION_FINISHED';
export const rowValidationFinished = createAction(ROW_VALIDATION_FINISHED);
