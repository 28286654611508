import * as React from 'react';
import { type ContextState, renderGizmos } from '../../gizmo-utils/polymorphic-gizmo';
import { type UnknownContent } from '@bettermarks/gizmo-types';

import styles from './Unknown.scss';

export type UnknownProps = UnknownContent & ContextState;

export const Unknown: React.FC<UnknownProps> = ({ message, content, availableWidth }) => (
  <div className={styles.unknown}>
    <p>{message}</p>
    {content.length > 0 && (
      <span className={styles.unknown_data}>{renderGizmos(content, availableWidth)}</span>
    )}
  </div>
);

Unknown.displayName = 'Unknown';
