import {
  Severity,
  type Validation,
  ValidationType,
  ValidatorEngine,
  type ValidatorRequest,
} from '@bettermarks/umc-kotlin';
import { DEFAULT_CONTENT_ROUTE, DEFAULT_MEDIA_ROUTE } from '../constants';
import {
  type AppExercise,
  type ApplicationState,
  type AppStep,
  CollapsibleContentState,
  type ContentExercise,
  type ContentStep,
  DEFAULT_FEATURES,
  DEFAULT_TOOLBAR_SETTINGS,
  DialogType,
  ExerciseStatus,
  LightBoxType,
  LoaderState,
  ScreenLockType,
  type Series,
  SeriesFlow,
  SeriesMode,
  SeriesStatus,
  StepStatus,
} from '../../types';
import {
  type Content,
  type ContentDict,
  InputToolTypes,
  type KeyboardTool,
} from '@bettermarks/gizmo-types';
import { DEFAULT_APP_SETTINGS, initialSeriesplayerState } from '../../apps/store/constants';
import { type ShortcutsMap } from '../keyboard';
import { withEmptyHistory } from '../../apps/store/helper';
import type { CRI, CRIContent } from '../../types/CRI';
import { type StateWithHistory } from 'redux-undo';
import { type CRIStatus } from '../../apps/criplayer';

export const mockTool: KeyboardTool = {
  type: InputToolTypes.keyboard,
  layout: {},
  selectedPage: 0,
};

export const mockQuestionIdZero = 'steps[0].question';
export const mockQuestionIdOne = 'steps[0].question:1';

export const mockContentZero = (path: string, disabled?: boolean, selected?: boolean): Content => ({
  ...(disabled && { disabled }),
  ...(selected && { selected }),
  $: 'placeholder',
  $renderStyle: 'test',
  $interactionType: 'gizmo',
  tool: mockTool,
});

export const mockContentOne = (path: string, disabled?: boolean, selected?: boolean): Content => ({
  ...(disabled && { disabled }),
  ...(selected && { selected }),
  $: 'placeholder',
  $renderStyle: 'test',
  $interactionType: 'gizmo',
  tool: mockTool,
});

export const mockCriContent = (path: string): CRIContent => ({
  $: 'placeholder',
  $renderStyle: 'cri',
  $interactionType: 'gizmo',
  tool: mockTool,
  id: 'mockId',
  variant: undefined,
  setting: undefined,
  canvasConfiguration: { $refid: `${path}:1` },
  studentTasks: [],
});

export const mockContentDict = (
  path: string,
  disabled?: boolean,
  selected?: boolean
): ContentDict => ({
  [path]: mockContentZero(path, disabled, selected),
  [`${path}:1`]: mockContentOne(path),
});

export const mockCriContentDict = (path: string): ContentDict => ({
  [path]: mockCriContent(path),
  [`${path}:1`]: mockContentOne(path),
});

export const mockUndoableContentDict = (
  path: string = mockQuestionIdZero,
  disabled?: boolean
): StateWithHistory<ContentDict> => withEmptyHistory(mockContentDict(path, disabled));

export const mockUndoableCriContentDict = (
  path: string = mockQuestionIdZero
): StateWithHistory<ContentDict> => withEmptyHistory(mockCriContentDict(path));

export const mockValidation: Validation = {
  includedGlobalFeedbacks: [],
  includedGlobalFeedbacksInTest: [],
  includedPlaceholderFeedbacks: {},
  excludedGlobalFeedbacks: [],
  excludedGlobalFeedbacksInTest: [],
  excludedPlaceholderFeedbacks: {},
  validationType: ValidationType.default,
  embedPlaceholders: undefined,
  conditionCorrectExpr: undefined,
  conditionValidExpr: undefined,
  conditionValidPatterns: undefined,
  conditionCorrectPatterns: undefined,
  code: undefined,
  feedbacks: [
    {
      key: '',
      localKey: '',
      ignoreInTest: true,
      textXML: '',
      severity: Severity.ok,
      code: '',
      condition: '',
      required: false,
      patterns: '',
      learningObjectiveId: undefined,
    },
  ],
};

/**
 * Create default [0-5] keyboard layout
 * Just need to provide a gizmo id on which interactive gizmo
 * this keyboard layout is applied
 * @param gizmoId the interactive gizmo id (e.g.: : 'steps[0].question:3')
 */
export const mockedShortcutsMap = (gizmoId: string): ShortcutsMap => ({
  0: {
    payload: '0',
    type: 'GIZMO_ACTION:ENTER_NUMBER',
    meta: {
      gizmoId,
      shouldEnrich: 'enrich',
    },
  },
  1: {
    payload: '1',
    type: 'GIZMO_ACTION:ENTER_NUMBER',
    meta: {
      gizmoId,
      shouldEnrich: 'enrich',
    },
  },
  2: {
    payload: '2',
    type: 'GIZMO_ACTION:ENTER_NUMBER',
    meta: {
      gizmoId,
      shouldEnrich: 'enrich',
    },
  },
  3: {
    payload: '3',
    type: 'GIZMO_ACTION:ENTER_NUMBER',
    meta: {
      gizmoId,
      shouldEnrich: 'enrich',
    },
  },
  4: {
    payload: '4',
    type: 'GIZMO_ACTION:ENTER_NUMBER',
    meta: {
      gizmoId,
      shouldEnrich: 'enrich',
    },
  },
  5: {
    payload: '5',
    type: 'GIZMO_ACTION:ENTER_NUMBER',
    meta: {
      gizmoId,
      shouldEnrich: 'enrich',
    },
  },
});

export const mockContentStep: ContentStep = {
  answer: mockContentDict('steps[0].answer'),
  description: '',
  explanation: mockContentDict('steps[0].explanation'),
  feedbacks: [],
  hints: [mockContentDict('steps[0].hints[0]')],
  id: 'mock.id',
  instruction: mockContentDict('steps[0].instruction'),
  knowledgeGaps: [],
  maxErrors: 2,
  mandatory: true,
  question: mockContentDict('steps[0].question'),
  questionIsAnswer: false,
  supports: [mockContentDict('steps[0].supports[0]')],
  skill: '',
  title: '',
  type: '',
  answerXML: 'ANSWER_XML',
  questionXML: 'QUESTION_XML',
  validation: mockValidation,
  showKEM: true,
  validatorEngine: ValidatorEngine.mathcore,
};

// Represents first active step
export const mockAppStep: Readonly<AppStep> = {
  ...mockContentStep,
  aborted: false,
  question: mockUndoableContentDict(),
  solutionState: CollapsibleContentState.hidden,
  explanationState: CollapsibleContentState.hidden,
  numberOfErrors: 0,
  status: StepStatus.started,
  startTime: -1,
  maxTries: 2,
};

export const mockContentExercise: ContentExercise = {
  id: '1',
  locale: '',
  dependencies: {
    fems: [],
    kems: [],
    images: [],
    importedRenderStyles: [],
  },
  exerciseType: '',
  featuresTimestamp: '',
  setting: {},
  description: '',
  steps: [mockContentStep],
  wrapup: {},
};

export const mockExercise: Readonly<AppExercise> = {
  id: '1',
  locale: '',
  dependencies: {
    fems: [],
    kems: [],
    images: [],
    importedRenderStyles: [],
  },
  exerciseType: '',
  featuresTimestamp: '',
  setting: mockContentDict('setting'),
  description: '',
  steps: [mockAppStep],
  currentStepId: 'mock.id',
  wrapupState: CollapsibleContentState.hidden,
  wrapup: mockContentDict('wrapup'),
  status: ExerciseStatus.started,
  startTime: -1,
  stepValidationLoaded: true,
};

export const mockSeries: Series = {
  currentExerciseIndex: 0,
  exercises: [mockExercise],
  id: 'ID1',
  mode: SeriesMode.practice,
  seriesSettings: {
    calculator: false,
    exerciseMaxPoints: 2,
    flow: SeriesFlow.random, // can be enum? as it has 'random' string as value
    hideHelpTools: true,
    repeatable: true,
    reporting: false,
    showBetty: true,
    showResults: true,
    validationAtEndOfSeries: true,
  },
  seriesStatus: SeriesStatus.started,
  title: 'Series Title',
};

export const mockClassroomIntro: CRI = {
  data: mockUndoableCriContentDict('classroomIntro'),

  status: 'loaded' as CRIStatus,
};

export const mockSeriesplayerAppState: ApplicationState = {
  appSettings: {
    contentManagerUrl: '//school.bettermarks.loc',
    embedded: false,
    logLevel: 'trace',
    resultManagerUrl: '//school.bettermarks.loc',
    staticServerUrl: DEFAULT_CONTENT_ROUTE,
    staticMediaUrl: DEFAULT_MEDIA_ROUTE,
    validatorUrls: DEFAULT_APP_SETTINGS.validatorUrls,
  },
  features: DEFAULT_FEATURES,
  series: mockSeries,
  previousDialog: {
    type: DialogType.none,
  },
  dialog: {
    type: DialogType.none,
  },
  lightBoxType: LightBoxType.none,
  screenLock: { type: ScreenLockType.none },
  runtimeState: {
    availableWidth: 1024,
    availableHeight: 768,
    isOnline: true,
    isTouch: false,
  },
  toolbar: DEFAULT_TOOLBAR_SETTINGS,
  loaderState: LoaderState.loading,
  shortcuts: [],
  interceptBrowserBackButton: true,
  emData: { fems: {}, kem: {} },
};

export const mockClassroomIntroAppState: ApplicationState = {
  ...initialSeriesplayerState,
  classroomIntro: mockClassroomIntro,
};
export const mockApplicationState = (): ApplicationState => ({
  ...mockSeriesplayerAppState,
  series: {
    ...mockSeries,
    exercises: [
      {
        ...mockExercise,
        steps: [
          {
            ...mockAppStep,
          },
        ],
      },
    ],
  },
});

export const mockValidatorRequest: ValidatorRequest = {
  exercise: {
    id: '1',
    description: '',
    exerciseType: '',
    featuresTimestamp: '',
    steps: [
      {
        id: '',
        title: '',
        description: '',
        answerXML: '',
        questionXML: '',
        maxErrors: 1,
        mandatory: true,
        skill: '',
        type: '',
        validation: mockValidation,
        validatorEngine: ValidatorEngine.mathcore,
      },
    ],
  },
  stepId: '',
  testMode: true,
  userAnswerXML: '',
  numberOfErrors: 0,
};
