import { defaultTo } from 'lodash';
import {
  annotationInner,
  type Annotations,
  type FElement,
  type Importer,
  type ImporterContext,
  type ItemListContent,
  type ItemListItem as Item,
  ItemListType,
  RS,
  semantics,
  SPECIAL,
  toXmlElement,
} from '@bettermarks/gizmo-types';

export const importItems = (
  items: FElement[],
  preContent: Annotations,
  context: ImporterContext
): Item[] =>
  items.map((item) => {
    const contentMathNode = item.getPath(['content', 'math']);
    const bulletMathNode = item.getPath(['bullet', 'math']);
    return {
      content:
        contentMathNode.children.length > 0 && !contentMathNode.hasAttribute('render-style')
          ? // for backwards compatibility:
            // repairing the lack of `render-style` on `math` node in generator output
            // TODO: remove this once content using MItemList is regenerated and published
            context.importXML(
              toXmlElement(
                semantics(
                  `<mrow>${contentMathNode.children.map((c) => c.toString())}</mrow>`,
                  annotationInner(SPECIAL, { $renderStyle: RS.FORMULA })
                )
              )
            )
          : // this is the part that should remain
            context.importXML(item.findChildTag('content').firstChild),
      ...(item.hasChild('bullet') && {
        bullet:
          bulletMathNode.children.length > 0 && !bulletMathNode.hasAttribute('render-style')
            ? // for backwards compatibility:
              // repairing the lack of `render-style` on `math` node in generator output
              // TODO: remove this once content using MItemList is regenerated and published
              context.importXML(
                toXmlElement(
                  semantics(
                    `<mrow>${bulletMathNode.children.map((c) => c.toString())}</mrow>`,
                    annotationInner(SPECIAL, { $renderStyle: RS.FORMULA })
                  )
                )
              )
            : // this is the part that should remain
              context.importXML(item.findChildTag('bullet').firstChild),
      }),
    };
  });

export const importItemList: Importer<ItemListContent> = (preContent, xml, context) => {
  const itemList = xml.getPath(['mrow', 'itemList']);

  return {
    ...preContent,
    items: importItems(itemList.getChildrenByTagName('item'), preContent, context),
    type: defaultTo<ItemListType>(itemList.attribute('type') as ItemListType, ItemListType.BULLET),
  };
};
