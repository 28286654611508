import * as React from 'react';

import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { type FEMContent } from '../../../types';

import { EMElementList } from './EMElementList';

export type FEMProps = FEMContent & ContextState;

export const FEM: React.FC<FEMProps> = (props) => <EMElementList {...props} mode="FEM" />;

FEM.displayName = 'FEM';
