import { type ContentDict } from '@bettermarks/gizmo-types';
import { type CoreStep, type CoreExercise } from './internal';

export type ContentStep = Readonly<
  CoreStep & {
    // Shared keys with different types.ts cannot be overridden,
    // hence a separate type to use in import phase.
    question: ContentDict;
    questionIsAnswer: boolean;
  }
>;

export type ContentExercise = Readonly<
  CoreExercise & {
    // Shared keys with different types.ts cannot be overridden,
    // hence a separate type to use in import phase.
    // ContentExercise has steps of type ContentStep
    steps: ReadonlyArray<ContentStep>;
  }
>;

export const enum InstructionHelpType {
  externalCalculator = 'externalCalculator',
  onPaper = 'onPaper',
  useFile = 'useFile',
}

export const fromStringToInstructionHelpType = (input: string) => {
  switch (input) {
    case InstructionHelpType.externalCalculator:
    case InstructionHelpType.onPaper:
    case InstructionHelpType.useFile:
      return input;
    default:
      // just in case an unexpected type pops up we fall back to the most general hint
      return InstructionHelpType.onPaper;
  }
};
