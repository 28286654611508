import styled, { css } from 'styled-components';
import dimensions from '../../sass/dimensions';

export type LayoutProps = {
  className?: string;
  fullsize?: boolean;
  paddingScheme?: boolean;
  vertical?: boolean;
};

/**
 * Layout
 * A Layouting component that serves to create horizontal/vertical layouts.
 * We can use Box component as child, whenever the content needs to have
 * styling like scroll or stretch for it.
 *
 * **Attention:** When used with `fullsize` it uses absolute positioning,
 * for layouts, to ensure the layout is absolute to its relative parent.
 * otherwise layout's children gets overlapped.
 *
 * ### Properties
 | Name            | Type          | Default    | Description                                      |
 |---              | ---           |---         | ---                                              |
 | `className`     | `string`      | Optional   | Any custom class to Direction component          |
 | `fullsize`      | `boolean`     | false      | If true, component takes full width/height       |
 | `paddingScheme` | `boolean`     | false      | If true, component takes predefined padding      |
 | `vertical`      | `boolean`     | false      | If true, Layout is vertical, else horizontal    |
 */

/*
PADDING SCHEME NOTE:
When direction component has paddingScheme styling, it applies padding to itself and
padding added to its Box component as well, to align its children.
FIX ME: We could not achieve PaddingScheme implementation in bm-toolbox, the one which is there in codesandbox, Webpack is preventing to apply styles from parent to child class using `&` selector
https://github.com/davezuko/react-redux-starter-kit/issues/560.
Importing the Direction styles in Box component is not a good idea, when Box component is utilized by any other component.
*/

export const Layout = styled.div<LayoutProps>`
  display: inline-flex;
  max-height: 100%;
  box-sizing: border-box;

  ${(props) =>
    props.vertical
      ? css`
          flex-direction: column;
          width: 100%;
        `
      : css`
          flex: 0 1 auto;
          min-height: 0; //fix for firefox

          .stretch {
            width: 100%;
            flex: 1 1 auto;
          }
        `}

  ${(props) =>
    props.fullsize &&
    css`
      display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      min-width: ${dimensions.layoutMinWidth};

      & > .stretch {
        position: relative;
      }
    `}
  
  ${(props) =>
    props.paddingScheme &&
    css`
      padding: ${dimensions.spaceXs};
      .box {
        padding-right: ${dimensions.spaceXs};
      }
      .box:last-child {
        padding-right: 0;
      }
    `}
  
  ${(props) =>
    props.fullsize &&
    !props.vertical &&
    css`
      @media (min-width: ${dimensions.layoutMaxWidth}) {
        max-width: ${dimensions.layoutMaxWidth};
        left: calc((100% - ${dimensions.layoutMaxWidth}) / 2);
        box-shadow: 0 0 24px 2px rgba(0, 0, 0, 0.3);
      }
    `}
`;

Layout.displayName = 'Layout';
