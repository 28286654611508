import { BASIC_SHAPE } from '../../gizmo-utils/configuration/renderStyles';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { type Content } from '../../xml-converter/core';

export const RECTANGLE = 'rectangle' as const;
export const SQUARE = 'square' as const;
export const CIRCLE = 'circle' as const;

export type BasicShapeType = typeof RECTANGLE | typeof SQUARE | typeof CIRCLE;

export interface BasicShapeContent extends Readonly<Content> {
  /**
   * The shape to display
   */
  readonly shape: BasicShapeType;
  /**
   * The scale factor of the shape
   */
  readonly scalefactor: number;
  /**
   * The color of the shape (like decoration color, e.g., 'bm-red')
   */
  readonly color: string;
  /**
   * The gradient style if present (e.g. gradient-type: radial)
   */
  readonly gradientType?: 'radial';
}

export const BASIC_SHAPE_DEFAULT_CONTENT: Readonly<BasicShapeContent> = {
  $: PLACEHOLDER,
  $renderStyle: BASIC_SHAPE,
  shape: RECTANGLE,
  scalefactor: 1,
  color: 'bm-red',
};
