import { createAction } from 'redux-actions';
import { type FractionFormEditorMode } from '@bettermarks/gizmo-types';

export type FractionFormActionPayload = FractionFormEditorMode | number | void;

export const TOGGLE_SEGMENT = 'TOGGLE_SEGMENT';
export const REMOVE_FORM = 'REMOVE_FORM';
export const SET_SEGMENT_COUNT = 'SET_SEGMENT_COUNT';

export const toggleSegment = createAction<number>(TOGGLE_SEGMENT);
export const removeForm = createAction<number>(REMOVE_FORM);
export const setSegmentCount = createAction<number>(SET_SEGMENT_COUNT);
