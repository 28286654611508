import React from 'react';
import { type Content, type FormulaContent } from '@bettermarks/gizmo-types';
import { getFormulaStringContent } from '../helpers';
import TextWithIcon from '../TextWithIcon';
import { CheckmarkCircle, CheckmarkCircleFilled } from '../icons';
import RowWithTransformation from './RowWithTransformation';

export type ValidatedRowWithTransformationProps = {
  row: FormulaContent;
  rowIndex: number;
  transformation: Content | undefined;
  solvedByStudent: boolean;
};

export default function ValidatedRowWithTransformation({
  row,
  rowIndex,
  transformation,
  solvedByStudent,
}: ValidatedRowWithTransformationProps) {
  return (
    <RowWithTransformation
      key={`scaffolding-gizmo-row-${rowIndex}`}
      transformation={transformation}
      transformationIndex={rowIndex + 1}
      left={
        <TextWithIcon
          text={
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {getFormulaStringContent(row, true)}
            </div>
          }
          icon={{
            element: solvedByStudent ? <CheckmarkCircleFilled /> : <CheckmarkCircle />,
          }}
        />
      }
    />
  );
}
