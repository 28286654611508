import { exporters, importers } from '@bettermarks/importers';
import log from 'loglevel';
import { isEmpty, isNil } from 'lodash';
import {
  type AppExercise,
  type AppStep,
  CollapsibleContentState,
  type ContentExercise,
  type ContentStep,
  DEFAULT_SERIES_SETTINGS,
  ExerciseStatus,
  SeriesFlow,
} from '../../../../../types';
import { toXmlElement as toXmlElementUnstubable } from '@bettermarks/gizmo-types';
import { importExerciseXML as importExerciseXMLUnstubable } from '../../../../../xml-converter/exercise';
import { completeStep, lockStep, startStep } from '../../../containers/Series/reducerHelpers';
import { enrichExercise, loadLocalSeriesFile } from '../../../containers/Upload/helper';
import { Request } from '../bm-api';
import { type Attempt, type UserInput } from '../result-manager/types';
import { ContentManagerRoutes } from './constants';
import { getPlainTextResponse, parseUserInput } from './parseHelpers';
import {
  type AttemptResponse,
  type BackendSeries,
  type ExerciseResponse,
  type SeriesResponse,
  type StepAttemptResponse,
  type StepResultResponse,
} from './types';
import { fetchExercise, responseIsLoadSeriesError } from './exerciseSeries.helper';
import { LoadSeriesException, LoadSeriesExceptionType } from '../../exception';

export const STUBABLE = {
  importExerciseXML: importExerciseXMLUnstubable,
  toXmlElement: toXmlElementUnstubable,
};

export const sanitizeURL = (exerciseId: string): string => exerciseId.replace(/\/\/+/g, '/');

/**
 * Fetch any static resource based on host and full path
 * @param {string} baseURL
 * @param {string} exercisePath
 * @returns {Promise<string>}
 */
export const getExerciseFromHostPath = async (
  baseURL: string,
  exercisePath: string
): Promise<string> => {
  let url: string;
  if (baseURL.length === 0) {
    url = exercisePath;
  } else {
    url = `${baseURL}${!baseURL.endsWith('/') ? '/' : ''}${exercisePath}`;
  }
  // Send user credentials (cookies, basic http auth, etc..) if the URL is on the
  // same origin as the calling script.
  // https://developer.mozilla.org/en-US/docs/Web/API/Request/credentials
  const response = await Request.get(url, 'same-origin');
  return getPlainTextResponse(await response.text());
};

/**
 * Initialising steps in review Mode:
 * - We just mark all steps to be complete.
 * @param steps
 * @param stepResults
 * @param stepAttempts
 */
export const initStepsReviewMode = (
  steps: ReadonlyArray<ContentStep>,
  stepResults: ReadonlyArray<StepResultResponse> = [],
  stepAttempts: ReadonlyArray<StepAttemptResponse> = []
) =>
  steps.map((step, stepIndex) => {
    const stepResult: StepResultResponse | undefined =
      stepResults && stepResults.find((stepResult) => stepResult.stepId === step.id);
    const userInput: UserInput | undefined =
      stepResult && stepResult.userInput && parseUserInput(stepResult.userInput, true);
    const currentStepAttempts =
      stepAttempts && stepAttempts.find((sa) => sa.stepIndex === stepIndex);
    const parseAttempt = (attempt: AttemptResponse): Attempt => ({
      ...attempt,
      userInput: parseUserInput(attempt.userInput, true),
    });
    const parsedStepAttempts = !isNil(currentStepAttempts)
      ? currentStepAttempts.attempts.map(parseAttempt)
      : [];
    return completeStep(step, stepResult, userInput, parsedStepAttempts, false);
  });

/**
 * Initialising steps in test Mode:
 * - If we have some stepResults & step is incomplete or stepIndex is currentStep Index.
 *    - Start the step with userInputs.
 * - If we have some step results & step is complete
 *    - Mark the step as complete.
 * - In all the other cases, mark step as locked.
 * @param steps
 * @param stepResults
 * @param currentStepIndex
 * @param lastReportedStepIndex
 * @param now
 */
export const initStepsTestMode = (
  steps: ReadonlyArray<ContentStep>,
  stepResults: ReadonlyArray<StepResultResponse> = [],
  currentStepIndex: number,
  lastReportedStepIndex: number,
  now: number
) =>
  steps.map((step, stepIndex) => {
    const stepResult: StepResultResponse | undefined =
      stepResults && stepResults.find((stepResult) => stepResult.stepId === step.id);
    const userInput: UserInput | undefined =
      stepResult &&
      stepResult.userInput &&
      // not a review mode hence false
      parseUserInput(stepResult.userInput, false);
    if ((stepResult && stepResult.incomplete) || stepIndex === currentStepIndex) {
      return startStep(step, now, stepResult, userInput, true);
    } else if ((stepResult && !stepResult.incomplete) || stepIndex <= lastReportedStepIndex) {
      // Test mode doesn't care about attempts.
      return completeStep(step, stepResult, userInput, undefined, true);
    } else {
      return lockStep(step);
    }
  });

/**
 * Initialising steps in practice Mode:
 * - If we have some stepResults & step is incomplete or step is currentStep of current Exercise
 *    - Start the step with userInputs.
 * - If we have some step results & step is complete
 *    - Mark the step as complete.
 * - In all the other cases, mark step as locked.
 * @param steps
 * @param stepResults
 * @param currentStepIndex
 * @param currentExercise
 * @param now
 */
export const initStepsPracticeMode = (
  steps: ReadonlyArray<ContentStep>,
  stepResults: ReadonlyArray<StepResultResponse> = [],
  currentStepIndex: number,
  currentExercise: boolean,
  now: number
) =>
  steps.map((step, stepIndex) => {
    const stepResult: StepResultResponse | undefined =
      stepResults && stepResults.find((stepResult) => stepResult.stepId === step.id);
    const userInput: UserInput | undefined =
      stepResult && stepResult.userInput && parseUserInput(stepResult.userInput, false);
    if (
      (stepResult && stepResult.incomplete) ||
      (stepIndex === currentStepIndex && currentExercise)
    ) {
      return startStep(step, now, stepResult, userInput, false);
    } else if (stepResult && !stepResult.incomplete) {
      // Practice mode doesn't care about attempts.
      return completeStep(step, stepResult, userInput, undefined, false);
    } else {
      return lockStep(step);
    }
  });

/**
 * The exercise of type ContentExercise have to be enriched and converted to AppExercise.
 * Additionally the steps have to be filled with already reported results and view states
 * have to be set accordingly.
 */
const initializeExercise = (
  exercise: ContentExercise,
  exerciseIndex: number,
  currentExerciseIndex: number,
  stepAttempts: ReadonlyArray<StepAttemptResponse> = [],
  stepResults: ReadonlyArray<StepResultResponse> = [],
  flow: SeriesFlow,
  seriesReview: boolean
): AppExercise => {
  const ex = enrichExercise(exercise);
  const now = new Date().getTime();

  let currentStepIndex: number;
  let lastReportedStepIndex = -1;
  if (isEmpty(stepResults)) {
    currentStepIndex = 0;
  } else {
    const lastStepResult = stepResults[stepResults.length - 1];
    lastReportedStepIndex = lastStepResult.stepIndex;
    if (lastStepResult.incomplete) {
      // stay on same step as it is not completed
      currentStepIndex = lastStepResult.stepIndex;
    } else if (lastStepResult.lastStep) {
      // exercise is completed as there is a result for the lastStep and it is completed
      currentStepIndex = -1;
    } else {
      // go to the next step
      currentStepIndex = lastStepResult.stepIndex + 1;
    }
  }

  let steps: ReadonlyArray<AppStep>;

  if (seriesReview) {
    steps = initStepsReviewMode(ex.steps, stepResults, stepAttempts);
  } else if (flow === SeriesFlow.random) {
    steps = initStepsTestMode(ex.steps, stepResults, currentStepIndex, lastReportedStepIndex, now);
  } else {
    steps = initStepsPracticeMode(
      ex.steps,
      stepResults,
      currentStepIndex,
      exerciseIndex === currentExerciseIndex,
      now
    );
  }

  const firstNotCompletedStepIndex = steps.findIndex((s) => s.validity === undefined);

  if (currentStepIndex !== -1 && steps[currentStepIndex] === undefined) {
    log.warn({
      message: 'Current step for exercise is undefined.',
      extra: {
        exerciseId: exercise.id,
        stepsCount: steps.length,
        currentStepIndex,
        firstNotCompletedStepIndex,
      },
    });
  }

  const currentStepId =
    currentStepIndex !== -1
      ? steps[currentStepIndex]?.id ?? steps[firstNotCompletedStepIndex]?.id
      : undefined;

  return {
    ...ex,
    currentStepId,
    startTime: exerciseIndex === currentExerciseIndex ? now : -1,
    steps,
    stepValidationLoaded: true, // TODO: this is only to fix a bug and should be removed
    wrapupState: CollapsibleContentState.hidden,
  };
};

export const parseExerciseResponses = (
  exerciseResponses: ReadonlyArray<ExerciseResponse>,
  exerciseXMLs: ReadonlyArray<string>,
  currentExerciseIndex: number,
  flow: SeriesFlow = SeriesFlow.linear,
  seriesReview = false
): ReadonlyArray<AppExercise> =>
  exerciseResponses.map((exerciseResponse: ExerciseResponse, exerciseIndex: number) => {
    const { id, calculator, status, stepAttempts, stepResults, validity } = exerciseResponse;
    const rawExercise = STUBABLE.importExerciseXML(
      STUBABLE.toXmlElement(exerciseXMLs[exerciseIndex]),
      importers,
      exporters
    );

    return {
      ...initializeExercise(
        rawExercise,
        exerciseIndex,
        currentExerciseIndex,
        stepAttempts,
        stepResults,
        flow,
        seriesReview
      ),
      id, // the exercise-id in the imported file points to the test release. override it!
      calculator,
      navigatedAwayFromExercise:
        status === ExerciseStatus.completed || status === ExerciseStatus.review,
      status,
      validity,
    };
  });

export const parseSeriesResponse =
  (seriesReview: boolean, staticServerUrl: string, localSeries = false) =>
  async (backendSeries: BackendSeries): Promise<SeriesResponse> => {
    const { exercises, settings, assignmentEndDate, assignmentId, groupId } = backendSeries;
    const currentExerciseIndex = Math.min(
      exercises.length - 1,
      Math.max(0, backendSeries.resumeIndex)
    );
    const exerciseXMLs = await Promise.all(
      exercises.map(async (exercise) => fetchExercise(staticServerUrl, exercise.id, localSeries))
    );

    return {
      title: backendSeries.title,
      currentExerciseIndex,
      ...((assignmentEndDate || assignmentId) && {
        assignment: {
          endDate: assignmentEndDate,
          id: assignmentId,
        },
      }),
      settings: { ...DEFAULT_SERIES_SETTINGS, ...settings },
      exercises: parseExerciseResponses(
        exercises,
        exerciseXMLs,
        currentExerciseIndex,
        settings.flow,
        seriesReview
      ),
      groupId,
    };
  };

export const fetchSeries = async (
  contentManagerUrl: string,
  staticServerUrl: string,
  seriesId: string,
  seriesReview: boolean,
  studentId?: string
): Promise<SeriesResponse> => {
  const response = await Request.get(
    ContentManagerRoutes.getSeries({
      contentManagerUrl,
      seriesId,
      seriesReview,
      studentId,
    }),
    'include'
  );
  const json = await response.json();
  if (responseIsLoadSeriesError(json)) {
    throw new LoadSeriesException(
      json.error,
      LoadSeriesExceptionType.forbiddenToReviewUnfinishedSeries,
      json.error,
      ''
    );
  }
  return await parseSeriesResponse(seriesReview, staticServerUrl)(json);
};

export const fetchLocalSeries = async (
  staticServerUrl: string,
  seriesFile: string,
  seriesReview: boolean,
  seriesOnStaticServer = false
): Promise<SeriesResponse> => {
  if (!seriesOnStaticServer) {
    return loadLocalSeriesFile(seriesFile).then(parseSeriesResponse(seriesReview, staticServerUrl));
  } else {
    return Request.get(`${staticServerUrl}/${seriesFile}`, 'include')
      .then(async (response) => response.json())
      .then(parseSeriesResponse(seriesReview, staticServerUrl, true));
  }
};

// @ToDo: To ask SMO how to handle encryption here? or will it go when default is encrypted
export const fetchLobLinkSeries = async (
  contentManagerUrl: string,
  staticServerUrl: string,
  contentListId: string,
  locale: string,
  token: string,
  apiKey?: string
): Promise<SeriesResponse> => {
  const headers = apiKey ? { 'x-api-key': apiKey } : undefined;
  return Request.get(
    ContentManagerRoutes.getLobLinkSeries({
      contentManagerUrl,
      contentListId,
      locale,
      token,
    }),
    headers ? 'omit' : 'include',
    headers
  )
    .then(async (response) => response.json())
    .then(parseSeriesResponse(false, staticServerUrl));
};

export const fetchPdfReviewSeries = async (
  contentManagerUrl: string,
  staticServerUrl: string,
  seriesId: string,
  studentId: string,
  system: string,
  token: string
): Promise<SeriesResponse> => {
  return Request.get(
    ContentManagerRoutes.getPdfReviewSeries({
      contentManagerUrl,
      seriesId,
      studentId,
      system,
      token,
    }),
    'include'
  )
    .then(async (response) => response.json())
    .then(parseSeriesResponse(true, staticServerUrl));
};
