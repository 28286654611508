import { get, isNil } from 'lodash';
import { type Action, createAction, handleActions } from 'redux-actions';
import {
  addReferencedBy,
  createPoint,
  DEFAULT_ANGLE_CONFIGURATION,
  DEFAULT_PREVANGLE,
  DEFAULT_PREVLINE,
  GEO_DEFAULT_PERSIST_PROPS,
  getPointCoordsId,
  INV_POINT_PREFIX,
  worldToScreen,
} from '@bettermarks/importers';
import {
  type AngleConfiguration,
  AngleLineType,
  AngleType,
  type Coords,
  type GeoAddAngleLineState,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type LineObjectType,
  POINT,
  PointHighlight,
  type PointObject,
  RAY,
  STRAIGHTLINE,
  type AngleBase,
  type ToolValueLabel,
} from '@bettermarks/gizmo-types';
import { createAngle, createLine, getAngleId, getLineId, isPointOnAnyLine } from '../../helpers';
import { previewLine } from './helper';
import { type AngleShowPermanent } from '@bettermarks/gizmo-types';

export interface DragPayload {
  mouseP: Coords;
  configuration: GeoConfiguration;
}

export interface AddPayload {
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface HoverPayload {
  id: string;
  matrix: number[];
}

export type GeoAddAngleLinePayload = DragPayload | AddPayload | HoverPayload | void;

const ADD_ANGLELINE = 'ADD_ANGLELINE' as const;
export const addAngleLineAction = createAction<AddPayload>(ADD_ANGLELINE);
const START_DRAG = 'START_DRAG' as const;
export const startDragAction = createAction<HoverPayload>(START_DRAG);
const DRAG = 'DRAG' as const;
export const dragAction = createAction<DragPayload>(DRAG);
const STOP_DRAG = 'STOP_DRAG' as const;
export const stopDragAction = createAction(STOP_DRAG);
const HOVER = 'HOVER' as const;
export const hoverAction = createAction<HoverPayload>(HOVER);
const OUT = 'OUT' as const;
export const outAction = createAction(OUT);

export const GEO_ADD_ANGLE_LINE_INITIAL_STATE: GeoAddAngleLineState = {
  selectedPointId: '',
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  prevAngles: [DEFAULT_PREVANGLE],
  prevPoints: [],
  prevLine: DEFAULT_PREVLINE,
  highlight: PointHighlight.dragOut,
  dragging: false,
  toolValueLabels: [],
};

export const geoAddAngleLineReducer = handleActions<GeoAddAngleLineState, GeoAddAngleLinePayload>(
  {
    [START_DRAG]: (state: GeoAddAngleLineState, { payload }: Action<HoverPayload>) => {
      if (!payload) {
        return state;
      }
      return {
        ...state,
        dragging: true,
        selectedPointId: payload.id,
        snapPoints: [],
        highlight: undefined,
        persistProps: {
          ...state.persistProps,
          persistToolValueLabels: [],
        },
      };
    },

    [DRAG]: (state: GeoAddAngleLineState, { payload }: Action<DragPayload>) => {
      const { geoContentMap, rays, segments, straightlines, vectors } = state.persistProps;

      if (
        !payload ||
        !state.dragging ||
        !state.selectedPointId ||
        !isPointOnAnyLine(
          geoContentMap,
          (geoContentMap[state.selectedPointId] as PointObject).coords,
          [...rays, ...segments, ...straightlines, ...vectors]
        )
      ) {
        return state;
      }

      const lineConfig: AngleConfiguration = {
        ...DEFAULT_ANGLE_CONFIGURATION,
        ...get(payload.configuration, 'toolConfiguration.angleConfiguration', {}),
      };

      return {
        ...state,
        dragging: true,
        ...previewLine(
          Math.max(
            payload.configuration.tickValueInterval.x,
            payload.configuration.tickValueInterval.y
          ),
          lineConfig.lineType === AngleLineType.RAY || isNil(lineConfig.lineType)
            ? RAY
            : (STRAIGHTLINE as LineObjectType),
          state.selectedPointId,
          lineConfig.snapAngle,
          state.persistProps,
          payload.mouseP,
          payload.configuration.toolConfiguration.angleConfiguration?.type || AngleType.BOTH_360
        ),
      };
    },

    [STOP_DRAG]: (state: GeoAddAngleLineState) => {
      return {
        ...GEO_ADD_ANGLE_LINE_INITIAL_STATE,
        dragging: false,
        persistProps: state.persistProps,
      };
    },

    [HOVER]: (state: GeoAddAngleLineState, { payload }: Action<HoverPayload>) => {
      if (!payload) {
        return state;
      }
      const { id, matrix } = payload;
      const { geoContentMap, rays, segments, straightlines, vectors } = state.persistProps;
      const snapPoint = geoContentMap[id] as PointObject;
      if (
        snapPoint &&
        isPointOnAnyLine(geoContentMap, snapPoint.coords, [
          ...rays,
          ...segments,
          ...straightlines,
          ...vectors,
        ])
      ) {
        const { x, y } = worldToScreen(matrix)(snapPoint.coords);
        return {
          ...state,
          snapPoints: [{ x, y, id, snapObject: POINT }],
        };
      }
      return {
        ...state,
      };
    },

    [OUT]: (state: GeoAddAngleLineState) => ({
      ...state,
      snapPoints: [],
    }),

    [ADD_ANGLELINE]: (state: GeoAddAngleLineState, { payload }: Action<AddPayload>) => {
      if (!payload) {
        return state;
      }
      if (!state.prevLine || !state.prevLine.p1) {
        // nothing to persist ?
        return {
          ...GEO_ADD_ANGLE_LINE_INITIAL_STATE,
          persistProps: state.persistProps,
        };
      }

      const { configuration, onPersistLocalState } = payload;
      const lineType = get(
        configuration,
        'toolConfiguration.angleConfiguration.lineType',
        undefined
      );
      const lineObjType =
        lineType === AngleLineType.RAY || isNil(lineType) ? RAY : (STRAIGHTLINE as LineObjectType);

      const p2 = createPoint(state.prevLine.p2 as Coords, {}, true);
      const p2Id = getPointCoordsId(p2.coords, INV_POINT_PREFIX);
      const lineId = getLineId(state.selectedPointId, p2Id, lineObjType);
      const line = createLine(
        state.selectedPointId,
        p2Id,
        configuration.defaultDecorations[lineObjType],
        lineObjType
      );

      let persistProps: GeoContentPersistProps = {
        ...state.persistProps,
        ...(lineObjType === RAY
          ? { rays: [...state.persistProps.rays, lineId] }
          : { straightlines: [...state.persistProps.straightlines, lineId] }),
        invisiblePoints: [...state.persistProps.invisiblePoints, p2Id],
        geoContentMap: addReferencedBy({
          ...state.persistProps.geoContentMap,
          [p2Id]: p2,
          [lineId]: line,
        }),
      };
      const showPermanent = configuration.toolConfiguration.angleConfiguration?.showPermanent;

      persistProps =
        showPermanent === 'ARC' || showPermanent === 'SIZE'
          ? addAngleIntoPersistState(
              persistProps,
              state.prevLine.p1,
              state.prevAngles[0],
              state.toolValueLabels,
              showPermanent
            )
          : persistProps;

      onPersistLocalState(persistProps);
      return {
        ...GEO_ADD_ANGLE_LINE_INITIAL_STATE,
        persistProps,
      };
    },
  },
  GEO_ADD_ANGLE_LINE_INITIAL_STATE
);

function addAngleIntoPersistState(
  persistProps: GeoContentPersistProps,
  coords: Coords,
  angleProps: AngleBase,
  toolValueLabels: ToolValueLabel[] | undefined,
  showPermanent: AngleShowPermanent
): GeoContentPersistProps {
  const angle = createAngle({ ...angleProps, ...coords });
  const angleId = getAngleId(getPointCoordsId(coords));

  return {
    ...persistProps,
    geoContentMap: {
      ...persistProps.geoContentMap,
      [angleId]: angle,
    },
    readinghelps: Array.from(new Set([...persistProps.readinghelps, angleId])),
    persistToolValueLabels: showPermanent === 'SIZE' ? [...(toolValueLabels ?? [])] : [],
  };
}
