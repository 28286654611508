import * as React from 'react';
import { useContentTranslation } from '../../../../../../gizmo-utils/polymorphic-gizmo';
import { Nothing } from '../../../../../../components';
import { buttonRegistry, localized } from '../buttonRegistry';
import styles from './ModeSelector.scss';

interface ModeSelectorProps {
  selectedMode: string | undefined;
}

export const ModeSelector: React.FC<ModeSelectorProps> = ({ selectedMode }) => {
  const t = useContentTranslation();
  const Icon = selectedMode ? buttonRegistry[localized(selectedMode, t)] : Nothing;
  return <Icon className={styles.svgIcon} />;
};

ModeSelector.displayName = 'ModeSelector';
