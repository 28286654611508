import { isNumber } from 'lodash';
import { type Complex, cbrt, realCbrt, realSqrt, add, sub, mult, div } from './complex';

// Sturm-Habicht sequence encoding for polynomial fo deg 4.
// For further details, refer to
// http://www.mmrc.iss.ac.cn/pub/mm15.pdf/yang.pdf pp 136ff
const REVISED_DISCRIMINANT_SIGNLIST_MAP: { [key: string]: number[] } = {
  3111: [1, -1, -1, -1],
  3112: [1, -1, -1, 0],
  3113: [1, -1, -1, 1],
  3121: [1, -1, 1, -1],
  3122: [1, -1, 0, 0],
  3123: [1, -1, 1, 1],
  3131: [1, -1, 1, -1],
  3132: [1, -1, 1, 0],
  3133: [1, -1, 1, 1],
  3211: [1, -1, -1, -1],
  3212: [1, -1, -1, 0],
  3213: [1, -1, -1, 1],
  3221: [1, -1, -1, -1],
  3222: [1, 0, 0, 0],
  3223: [1, -1, -1, 1],
  3231: [1, -1, 1, -1],
  3232: [1, -1, 1, 0],
  3233: [1, -1, 1, 1],
  3311: [1, 1, -1, -1],
  3312: [1, 1, -1, 0],
  3313: [1, 1, -1, 1],
  3321: [1, 1, -1, -1],
  3322: [1, 1, 0, 0],
  3323: [1, 1, -1, 1],
  3331: [1, 1, 1, -1],
  3332: [1, 1, 1, 0],
  3333: [1, 1, 1, 1],
};

/**
 * given an array of complex numbers, returns only the
 * real numbers with an imaginary part of zero.
 * @param {(number | Complex)[]} a
 * @returns {number[]}
 */
const onlyReals = (a: (number | Complex)[]): number[] =>
  a
    .map((r) => (isNumber(r) ? { re: r, im: 0 } : r))
    .filter((c) => Math.abs(c.im) < 0.0001)
    .map((c) => parseFloat(c.re.toFixed(5)));

/**
 * gets the only real root for a polynomial of deg 1.
 * f(x) = b + a*x
 * @param {number} b coefficient
 * @param {number} a coefficient
 * @returns {number[]} the root as number Array
 */
const linear = (b: number, a: number): number[] => [-b / a];

/**
 * gets all real roots of the quadratic equation.
 * f(x) = c + b*x + a*x^2
 * @param {number} c
 * @param {number} b
 * @param {number} a
 * @returns {number[]} the real root(s)
 */
const quadratic = (c: number, b: number, a: number): number[] =>
  onlyReals([-1, 1].map((k) => div(add(-b, mult(k, realSqrt(b * b - 4 * c * a))), 2 * a)));

/**
 * gets the real roots for a polynomial of deg 3 by Lagranges formula.
 * f(x) = d + c*x + b*x^2 + a*x^3
 * @param {number} d coefficient
 * @param {number} c coefficient
 * @param {number} b coefficient
 * @param {number} a coefficient
 * @returns {Complex[]} all complex roots.
 * for more info, refer to http://geo.cc4cm.org/draw/ZhaoThesis.pdf pp. 22
 */
const lagrangeCubic = (
  d: number,
  c: number,
  b: number,
  a: number,
  a0 = d / a,
  a1 = c / a,
  a2 = b / a,
  om = cbrt(1),
  a12 = a1 * a1,
  a13 = a12 * a1,
  a22 = a2 * a2,
  a23 = a22 * a2,
  p1 = a22 * a12 + 18 * a2 * a1 * a0 - 4 * a13 - 27 * a0 * a0 - 4 * a23 * a0,
  p2 = 9 * a2 * a1 - 27 * a0 - 2 * a23,
  s = realSqrt(mult(-3, p1)),
  c1 = realCbrt(mult(0.5, add(p2, mult(3, s)))),
  c2 = realCbrt(mult(0.5, sub(p2, mult(3, s))))
): number[] =>
  onlyReals([
    mult(1 / 3, add(add(-a2, mult(om[1], c1)), mult(om[2], c2))),
    mult(1 / 3, add(add(-a2, mult(om[0], c1)), mult(om[0], c2))),
    mult(1 / 3, add(add(-a2, mult(om[2], c1)), mult(om[1], c2))),
  ]);

/**
 * gets all real roots for a polynomial of deg 4 by Lagranges formula.
 * f(x) = e + d*x + c*x^2 + b*x^3 + a*x^4
 * @param {number} e coefficient
 * @param {number} d coefficient
 * @param {number} c coefficient
 * @param {number} b coefficient
 * @param {number} a coefficient
 * @returns {number[]} the real roots
 * - for detailed info on the algorithm,
 *   refer to http://geo.cc4cm.org/draw/ZhaoThesis.pdf pp.60 ff
 * - for more info on 'sign lists' and 'revised sign lists'
 *   refer to http://www.mmrc.iss.ac.cn/pub/mm15.pdf/yang.pdf
 */
const lagrangeQuartic = (
  e: number,
  d: number,
  c: number,
  b: number,
  a: number,
  a0 = e / a,
  a1 = d / a,
  a2 = c / a,
  a3 = b / a,
  om = cbrt(1),
  a02 = a0 * a0,
  a03 = a02 * a0,
  a12 = a1 * a1,
  a13 = a12 * a1,
  a14 = a13 * a1,
  a22 = a2 * a2,
  a23 = a22 * a2,
  a32 = a3 * a3,
  a33 = a32 * a3,
  p1 = 18 * a33 * a1 * a2 * a0 +
    256 * a03 -
    27 * a14 -
    6 * a32 * a12 * a0 -
    192 * a3 * a1 * a02 +
    18 * a3 * a13 * a2 +
    144 * a2 * a32 * a02 +
    a22 * a32 * a12 -
    4 * a23 * a32 * a0 +
    144 * a0 * a12 * a2 -
    4 * a33 * a13 -
    128 * a22 * a02 +
    16 * a23 * a2 * a0 -
    4 * a23 * a12 -
    27 * a33 * a3 * a02 -
    80 * a3 * a1 * a22 * a0,
  p2 = -27 * a32 * a0 + 9 * a3 * a2 * a1 - 2 * a23 + 72 * a2 * a0 - 27 * a12,
  p3 = 3 * a32 - 8 * a2,
  p4 = 4 * a2 * a3 - 8 * a1 - a33,
  p5 = 16 * a2 * a0 -
    18 * a12 -
    4 * a23 +
    14 * a1 * a2 * a3 -
    6 * a0 * a32 +
    a22 * a32 -
    3 * a1 * a33,
  signList = [1, p3, p5, p1].map((x) => Math.sign(x)),
  revList = REVISED_DISCRIMINANT_SIGNLIST_MAP[
    signList.reduce((acc, s, i) => acc + Math.pow(10, 3 - i) * (s + 2), 0)
  ],
  v = revList.reduce(
    (a, s, i) => a + (i > 0 && revList[i - 1] !== 0 && s !== 0 && revList[i - 1] !== s ? 1 : 0),
    0
  ),
  l = revList.reduce((acc, sign) => acc + (sign !== 0 ? 1 : 0), 0),
  r = l - 2 * v,
  s = realSqrt(mult(-3, p1)),
  sigma1 = p1 < 0 ? 0 : Math.sign(p2),
  sigma2 = (r <= 1 && p4 <= 0) || (r > 1 && p4 > 0) ? 1 : -1,
  t1 = mult(om[-sigma1 === -1 ? 2 : -sigma1], realCbrt(mult(0.5, add(p2, mult(3, s))))),
  t2 = mult(om[sigma1 === -1 ? 2 : sigma1], realCbrt(mult(0.5, sub(p2, mult(3, s))))),
  k1 = realSqrt(mult(1 / 3, add(add(p3, mult(mult(-4, om[2]), t1)), mult(mult(-4, om[1]), t2)))),
  k2 = realSqrt(mult(1 / 3, add(add(p3, mult(mult(-4, om[0]), t1)), mult(mult(-4, om[0]), t2)))),
  k3 = mult(
    sigma2,
    realSqrt(mult(1 / 3, add(add(p3, mult(mult(-4, om[1]), t1)), mult(mult(-4, om[2]), t2))))
  )
): number[] =>
  onlyReals([
    mult(1 / 4, add(-a3, add(mult(1, k1), add(mult(1, k2), mult(1, k3))))),
    mult(1 / 4, add(-a3, add(mult(1, k1), add(mult(-1, k2), mult(-1, k3))))),
    mult(1 / 4, add(-a3, add(mult(-1, k1), add(mult(1, k2), mult(-1, k3))))),
    mult(1 / 4, add(-a3, add(mult(-1, k1), add(mult(-1, k2), mult(1, k3))))),
  ]);

// a list of keys of the ParametricFunction providing information about specific reading help.
// eslint-disable-next-line @typescript-eslint/ban-types
export const polynomialRootsMap: { [key: number]: Function } = {
  1: linear,
  2: quadratic,
  3: lagrangeCubic,
  4: lagrangeQuartic,
};
