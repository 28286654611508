import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';
import { SOLUTION_SET } from '../../gizmo-utils/configuration/renderStyles';
import { PLACEHOLDER } from '../../gizmo-utils/constants';

export interface SolutionSetContent extends Readonly<Content> {
  readonly configuration: SolutionSetConfiguration;
  readonly hiddenElements: ReadonlyArray<ContentReference>;
  readonly visibleElements: ReadonlyArray<ContentReference>;
  /**
   * The reference ids that are used for switching focus between the interactive parts.
   * When the interactive Fields are nested inside non interactive content,
   * this list is not the same as the reference ids in visibleElements and hiddenElements.
   *
   * The `undefined` is needed because of types.ts and because the list is supposed
   * to have the same length as `visibleElements.length + hiddenElements.length`.
   */
  readonly selectableRefIds?: ReadonlyArray<string | undefined>;
}

export const SOLUTION_SET_DEFAULT_CONTENT = (): Defaults<SolutionSetContent> => ({
  $: PLACEHOLDER,
  $renderStyle: SOLUTION_SET,
  configuration: {
    ordered: 'false',
    separator: ',',
  },
  hiddenElements: [],
  visibleElements: [],
});

export type SolutionSetConfiguration = Readonly<{
  ordered: string;
  open?: string;
  close?: string;
  separator: string; // mind the difference to the wrong spelling in the XML!!!!
}>;

/**
 * typo on purpose - XML tag in MathML is "seperator"
 * @type {string}
 */
export const XML_TAG_SEPARATOR = 'seperator';

export const Spacing = {
  afterOpen: 2,
  beforeFormulaInput: 2,
  afterFormulaInput: 2,
  beforeSeparator: 2,
  afterSeparator: 4,
  beforeClose: 2,
};
