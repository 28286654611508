import { delay, put } from 'redux-saga/effects';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { toggleToolbarDrawer } from './actions';

export function* openDragAndDropDrawerSaga() {
  /**
   * One of the product's requirements for DND drawer.
   * to be opened not instantly but with a delay so users are able to see
   * the opening animation.
   */
  yield delay(300);
  yield put(toggleToolbarDrawer({ drawerName: ToolbarElements.dnd, preserveIfOpened: true }));
}
