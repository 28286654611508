import {
  annotationInner,
  exportContent,
  type Exporter,
  type ExporterContext,
  RS,
  semantics,
} from '@bettermarks/gizmo-types';
import { type ClockTimeContent } from './importer';

export const exportTime: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as ClockTimeContent;

  return semantics(
    `<mrow>
       ${content.clock ? exportContent(content.clock.$refid, context) : ''}
       ${exportContent(content.time.$refid, context)}
     </mrow>`,
    annotationInner(content.$, { ...content, $renderStyle: RS.CLOCKTIME })
  );
};
