import {
  annotationInner,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';
import { type Bar, type BarChartContent, type BarChartGroup } from '@bettermarks/gizmo-types';

export const exportItem = (b: Bar, context: ExporterContext): string => {
  return semantics(
    `
      <configuration>
        <mark>${b.marked ? '1' : '0'}</mark>
        ${b.label ? `<label>${exportContent(b.label.$refid, context)}</label>` : ''}
        <color>${b.color}</color>
        <readHelp>${b.readingHelp ? '1' : '0'}</readHelp>
      </configuration>
      <mrow ${b.severity ? `decoration = "${b.severity}"` : ``}>
        <mn>${b.yValue}</mn>
      </mrow>
    `,
    annotationInner(b.annotationInner.$, b.annotationInner)
  );
};

export const exportGroup = (g: BarChartGroup, context: ExporterContext): string => {
  return semantics(
    `
      <mrow>
        <set>${g.items.map((i) => `<item>${exportItem(i, context)}</item>`).join('')}</set>
        ${g.label ? `<label>${exportContent(g.label.$refid, context)}</label>` : ''}
      </mrow>
    `,
    annotationInner(g.annotationInner.$, g.annotationInner)
  );
};

export const exportDiagramBarChart: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as BarChartContent;
  const { yTickLabelInterval, yTickValueInterval, yTickValueStart, yTickValueEnd, xAxisArrow } =
    content.configuration;

  return semantics(
    `
      <mrow>
        ${content.xLabel ? `<xLabel>${exportContent(content.xLabel.$refid, context)}</xLabel>` : ''}
        ${content.yLabel ? `<yLabel>${exportContent(content.yLabel.$refid, context)}</yLabel>` : ''}
        ${content.title ? `<title>${exportContent(content.title.$refid, context)}</title>` : ''}
        <configuration>
          <yTickLabelInterval>${yTickLabelInterval}</yTickLabelInterval>
          <yTickValueInterval>${yTickValueInterval}</yTickValueInterval>
          <yTickValueStart>${yTickValueStart}</yTickValueStart>
          <yTickValueEnd>${yTickValueEnd}</yTickValueEnd>
          <xAxisArrow>${xAxisArrow ? '1' : '0'}</xAxisArrow>
        </configuration>
        ${content.groups.map((g) => `<group>${exportGroup(g, context)}</group>`).join('')}
        ${
          content.caption
            ? `<caption>${exportContent(content.caption.$refid, context)}</caption>`
            : ''
        }
      </mrow>
    `,
    annotationInner(content.$, { ...content, $interactionType: undefined })
  );
};
