import styles from './DragAndDrop.scss';
import { numberFromStyles } from '@bettermarks/gizmo-types';

export const PADDING = numberFromStyles(styles.PADDING);
export const DROP_TARGET_PADDING = numberFromStyles(styles.DROP_TARGET_PADDING);
export const MIN_HEIGHT = numberFromStyles(styles.MIN_HEIGHT);
export const SET_MIN_HEIGHT = numberFromStyles(styles.SET_MIN_HEIGHT);
export const MARGIN = numberFromStyles(styles.MARGIN);
export const DROP_TARGET_BORDER_WIDTH = numberFromStyles(styles.DROP_TARGET_BORDER_WIDTH);
export const RECTANGLE_BORDER_WIDTH = numberFromStyles(styles.RECTANGLE_BORDER_WIDTH);

export const SORTING_BORDER_WIDTH = numberFromStyles(styles.SORTING_BORDER_WIDTH);
export const SORTING_PADDING = numberFromStyles(styles.SORTING_PADDING);
