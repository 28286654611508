import * as React from 'react';
import classNames from 'classnames';
import Measure, { type ContentRect } from 'react-measure';
import styles from './DragAndDrop.scss';

export type DragSourceProps = {
  id?: string;
  itemType?: number;
  stack?: number;
  shape?: 'border' | 'rectangle';
  width?: number;
  height?: number;
  draggable?: boolean;
  onResize?: (p: ContentRect) => void;
};

/**
 * DragSource: Serves as a source for draggable items.
 */
export class DragSource extends React.Component<DragSourceProps> {
  ref: HTMLDivElement;

  componentDidUpdate(prevProps: DragSourceProps) {
    const ref = this.ref;
    if (prevProps.width && !this.props.width && ref && this.props.onResize) {
      const bounds = ref.getBoundingClientRect();

      // In case ref component unmounted but drag-n-drop still rendered.
      // i.e. drawer with sources has been unmounted
      if (bounds.height === 0 && bounds.width === 0) return;

      this.props.onResize({ bounds });
    }
  }

  setRef = (ref: HTMLDivElement) => {
    this.ref = ref;
  };

  render() {
    const {
      children,
      draggable = true,
      id,
      itemType = 1,
      width,
      height,
      shape,
      stack,
      onResize,
    } = this.props;

    const DragSourceElement = (ref?: React.Ref<any>) => (
      <div
        className={classNames(
          styles.dragSource,
          shape && styles[shape],
          styles[`itemType${itemType}`],
          {
            [styles.stackPlaceholder]: stack === 0,
            [styles.stack2]: stack === 2,
            [styles.stack3]: stack === -1 || (stack && stack >= 3),
            [styles.draggable]: draggable,
          }
        )}
        ref={ref}
        id={id}
        style={{ width, height }}
      >
        <span
          className={classNames(styles.content, {
            [styles.stackPlaceholderContent]: stack === 0,
          })}
        >
          {children}
        </span>
      </div>
    );

    return onResize ? (
      <Measure bounds={true} onResize={onResize} innerRef={this.setRef}>
        {({ measureRef }) => {
          return DragSourceElement(measureRef);
        }}
      </Measure>
    ) : (
      DragSourceElement()
    );
  }
}
