import { type Saga } from 'redux-saga';
import { type ForkEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { sagas } from '../../gizmo-utils/configuration/sagas';
import { createGizmoSaga } from '../../gizmo-utils/redux';

import { EM_REQUESTED, QUIT_WHITEBOARD } from '../emplayer';
import { loadEMSaga, quitWhiteboardPlayerSaga } from '../emplayer/emSaga';
import { LOAD_GIZMO, loadGizmoSaga } from '../gizmoviewer';
import {
  loadSeriesSaga,
  START_FROM_EXERCISE_ID,
  START_FROM_LOCAL_FILES,
  START_FROM_SERIES_FILE,
  switchExerciseSaga,
} from '../seriesplayer';
import { REPORT_PROBLEM } from '../seriesplayer/containers/Dialog/ReportProblem/actions';
import { reportProblemSaga } from '../seriesplayer/containers/Dialog/ReportProblem/reportProblemSaga';
import {
  NEXT_STEP,
  SAVE_INPUT,
  SHOW_SOLUTION,
  SUBMIT,
  SWITCH_EXERCISE_RESUMABLE,
} from '../seriesplayer/containers/Exercise/actions';
import {
  HAND_IN,
  NEXT_EXERCISE,
  SWITCH_EXERCISE,
  USER_PRESSED_ENTER,
} from '../seriesplayer/containers/Series/actions';
import { handInSaga } from '../seriesplayer/containers/Series/handInSaga';
import { saveInputSaga, submitSaga } from '../seriesplayer/containers/Series/seriesSaga';
import { submitIncompleteSeriesSaga } from '../seriesplayer/containers/Series/submitIncompleteSeriesSaga';
import { userPressedEnterSaga } from '../seriesplayer/containers/Series/userPressedEnterSaga';
import { quitSeriesPlayerSaga } from '../seriesplayer/containers/SeriesPlayer';
import {
  FETCH_LOBLINK_SERIES,
  FETCH_PDF_REVIEW_SERIES,
  FETCH_REMOTE_XML_SP,
  FETCH_SERIES,
  QUIT_SERIES_PLAYER,
} from '../seriesplayer/containers/SeriesPlayer/actions';
import {
  loadLobLinkSeriesSaga,
  loadPdfReviewSaga,
} from '../seriesplayer/containers/SeriesPlayer/loadSeriesSaga';
import { openDragAndDropDrawerSaga } from '../seriesplayer/containers/Toolbar/sagas';
import { calculatorSaga } from '../seriesplayer/containers/Toolbar/Tools/Calculator/calculatorSaga';
import { EVALUATE } from '../seriesplayer/containers/Toolbar/Tools/Calculator/reducer';
import { START_SERIES, SUBMIT_INCOMPLETE_SERIES } from '../seriesplayer/reducers';
import { withErrorHandling } from './helper';
import { CRI_REQUESTED } from '../criplayer';
import { loadCRISaga } from '../criplayer/criSaga';
import { INIT_EXAMPLE_MODE_SERIES } from '../example-mode/actions';
import { initExampleModeSeriesSaga } from '../example-mode/loadExampleModeSaga';

/**
 * Function to demonstrate logging on log.log using sagas
 * @param action
 */

// function* logger(action: any) {
//   log.log('Action:', action, 'state after:', yield select());
// }

/*
We could add a wrapper to ensure type matching: (maybe also possible by adding type declaration

const takeLatestT = <PayLoad>(
  creator: (payload: PayLoad) => Action<PayLoad>, saga: (action: Action<PayLoad>) => void
) => takeLatest(creator, saga);
*/

const getExerciseActionTypes = [
  START_FROM_LOCAL_FILES,
  START_FROM_EXERCISE_ID,
  START_FROM_SERIES_FILE,
  FETCH_REMOTE_XML_SP,
  FETCH_SERIES,
];

export default function* rootSaga(): IterableIterator<ForkEffect> {
  // yield takeEvery('*', logger);
  yield takeLatest(SWITCH_EXERCISE_RESUMABLE, switchExerciseSaga);
  yield takeLatest(SUBMIT_INCOMPLETE_SERIES, submitIncompleteSeriesSaga);
  yield takeLatest(SUBMIT, withErrorHandling(submitSaga as Saga));
  yield takeLatest(USER_PRESSED_ENTER, userPressedEnterSaga);
  yield takeLatest(SHOW_SOLUTION, withErrorHandling(submitSaga as Saga));
  yield takeLatest(SAVE_INPUT, saveInputSaga);
  yield takeLatest(HAND_IN, withErrorHandling(handInSaga as Saga));
  yield takeLatest(REPORT_PROBLEM, reportProblemSaga);
  yield takeLatest(LOAD_GIZMO, loadGizmoSaga);
  yield takeLatest(getExerciseActionTypes, loadSeriesSaga);
  yield takeLatest(
    [START_SERIES, NEXT_STEP, NEXT_EXERCISE, SWITCH_EXERCISE],
    openDragAndDropDrawerSaga
  );
  // yield takeLatestTyped(uploadedXMLs, loadSeriesFromFileUpload);
  yield takeLatest(FETCH_LOBLINK_SERIES, loadLobLinkSeriesSaga);
  yield takeLatest(FETCH_PDF_REVIEW_SERIES, loadPdfReviewSaga);
  yield takeLatest(QUIT_SERIES_PLAYER, quitSeriesPlayerSaga);
  yield takeLatest(EM_REQUESTED, loadEMSaga);
  yield takeLatest(CRI_REQUESTED, loadCRISaga);
  yield takeLatest(EVALUATE, calculatorSaga);
  yield takeLatest(QUIT_WHITEBOARD, quitWhiteboardPlayerSaga);
  yield takeLatest(INIT_EXAMPLE_MODE_SERIES, initExampleModeSeriesSaga);

  yield takeEvery('*', createGizmoSaga(sagas));
}
