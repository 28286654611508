import React from 'react';
import styled from 'styled-components';
import { type LayoutChildWrapperProps, type LayoutDecoration } from './types';
import { DEFAULT_GAP } from './constants';
import { VLayoutContext } from './context';
import { FlexAlign } from '../../types';

export interface VLayoutProps {
  align?: FlexAlign;
  gap?: number;
  stretch?: boolean;
  decorations?: LayoutDecoration[];
  width?: number;
  maxWidth?: number;
  className?: string;
}

export const VLayout: React.FC<VLayoutProps> = ({
  children,
  gap = DEFAULT_GAP,
  width,
  className,
}) => (
  <div className={className}>
    <VLayoutContext.Provider
      value={{
        wrap: gap !== DEFAULT_GAP,
        marginTop: gap,
        maxWidth: width,
      }}
    >
      {children}
    </VLayoutContext.Provider>
  </div>
);

interface VLayoutChildWrapperProps extends LayoutChildWrapperProps {
  noMargin?: boolean;
}

export const VLayoutChildWrapper: React.FC<VLayoutChildWrapperProps> = ({
  children,
  decoration,
  noMargin = false,
}) => {
  const { wrap, marginTop, maxWidth } = React.useContext<VLayoutContext>(VLayoutContext);
  return wrap ? (
    <div
      style={{
        ...decoration,
        marginTop: noMargin ? 0 : marginTop,
        maxWidth,
      }}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export const StyledVLayout = styled(VLayout)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || FlexAlign.flexStart};
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => (props.width ? `${props.width}px` : props.stretch ? '100%' : 'fit-content')};
  & > :nth-child(n) {
    margin-top: ${DEFAULT_GAP}px;
  }
  & > :first-child {
    margin-top: 0;
  }
`;
