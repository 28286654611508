import * as React from 'react';

import styles from './PdfReviewHeader.scss';
import { useContentTranslation } from '../../../../gizmo-utils/polymorphic-gizmo';

export const enum HeaderSize {
  small = 'small',
  large = 'large',
}

type ParamKey = {
  i18nKey: string;
  param: {
    currentExercise: number;
    total: number;
  };
};

const isParamKey = (thing: any): thing is ParamKey =>
  thing.hasOwnProperty('i18nKey') && thing.hasOwnProperty('param');

type PdfReviewHeaderProps = {
  title: string | ParamKey;
  size: HeaderSize;
};

export const PdfReviewHeader: React.FC<PdfReviewHeaderProps> = ({ title, size }) => {
  const t = useContentTranslation();

  return (
    <div className={styles[size]}>{isParamKey(title) ? t(title.i18nKey, title.param) : title}</div>
  );
};
