import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { type ThermometerCallbacks, ThermometerGizmo } from './ThermometerGizmo';
import { changeFluidLevel } from './thermometerActions';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): ThermometerCallbacks => ({
  onChange: (value) => dispatch(gizmoAction(changeFluidLevel(value), refid)),
});

export const ThermometerContainer = connectGizmo(mapDispatchToProps, ThermometerGizmo);
