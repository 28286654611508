import * as React from 'react';
import { defaultTo, has, isNil } from 'lodash';
import {
  type GeoEditorMode,
  type GeoScene,
  type LabelStepperProps,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { getLineTypeProps } from '../constants';
import { getLabelStepperProps, offset } from '../helpers';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { getSnapPoint } from '../../snap';
import { mousePos } from '@bettermarks/importers';
import { type GeoProps } from '../../Geo';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  addLineAction,
  clickInitialLabelAction,
  closeLabelAction,
  downAction,
  geoAddLineReducer,
  initialState,
  labelConfirmAction,
  overInitialLabelAction,
  snapAction,
  stopSnapAction,
} from './geoAddLineReducer';
import { addAdditionalButton } from '../addlabel/helpers';
import { Maybe } from '../../../../utils/maybe';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export type GeoAddLineProps = GeoProps &
  DragScrollableProps & {
    mode: GeoEditorMode;
  };

export const GeoAddLine: React.FC<GeoAddLineProps> = (props) => {
  const lineTypeProps = getLineTypeProps(props.mode);

  return (
    <WithLocalRedux
      store={{
        ...initialState,
        persistProps: persistProps(props),
      }}
      reducer={geoAddLineReducer}
      componentName={`AddLine:${props.uniqueId}`}
    >
      {(state, dispatch) => {
        const {
          uniqueId,
          configuration,
          isTouch = false,
          matrix,
          scale,
          onPersistLocalState,
        } = props;
        const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

        const snap =
          (scene: GeoScene, isTouchStart = false) =>
          (evt: React.MouseEvent<any>) => {
            // calculates nearest snap point in world coordinates via mousePos of MouseEvent
            const mouseP = mousePos(
              defaultTo<string>(uniqueId, ''),
              offset(isTouch, isTouchStart)
            )(evt);
            const snapPoint: SnapPoint | null = getSnapPoint(matrix, scene, scale)(mouseP);

            if (!state.labels.active) {
              dispatch(
                snapAction({
                  snapPoint,
                  configuration,
                  mouseP,
                  matrix,
                  lineTypeProps,
                })
              );
            }
          };

        const stopSnap = () => dispatch(stopSnapAction());

        const down = () => !state.labels.active && dispatch(downAction({ matrix, lineTypeProps }));

        const up = () => {
          !state.labels.active &&
            dispatch(
              addLineAction({
                configuration,
                matrix,
                lineTypeProps,
                onPersistLocalState,
              })
            );
          maybeScrollBehaviour.ap(stopScrolling);
        };

        const onLabelClick = (id: string) => (evt: React.MouseEvent<any>) =>
          dispatch(clickInitialLabelAction({ id, configuration }));

        const confirmLabel = (index: number, labelAlignTop: boolean) =>
          dispatch(labelConfirmAction({ index, labelAlignTop, onPersistLocalState }));

        const onLabelHover = () => dispatch(overInitialLabelAction({ onInitialLabelOver: true }));

        const onLabelLeave = () => dispatch(overInitialLabelAction({ onInitialLabelOver: false }));

        const onCloseLabel = () => dispatch(closeLabelAction({ onPersistLocalState }));

        const touchStart = (scene: GeoScene) => (evt: React.MouseEvent<any>) => {
          snap(scene, true)(evt);
          maybeScrollBehaviour.ap(startScrolling);
          down();
        };

        const geoContentProps = { ...props, ...state.persistProps };

        const autoLabeling: boolean = has(
          configuration.autoLabeling,
          state.labels.labelingObjectType
        );

        let labelStepperProps: LabelStepperProps | undefined;
        if (state.labels) {
          labelStepperProps = getLabelStepperProps(
            state.labels,
            confirmLabel,
            autoLabeling ? addAdditionalButton(false, onCloseLabel) : undefined
          );
        }

        return (
          <GeoRenderer
            {...geoContentProps}
            onAnyOtherClick={onCloseLabel}
            onLabelClick={onLabelClick}
            onLabelOver={onLabelHover}
            onLabelLeave={onLabelLeave}
            onMouseLeave={stopSnap}
            onMouseMove={snap}
            onMouseDown={down}
            onMouseUp={up}
            onTouchStart={touchStart}
            snapPoints={state.snapPoints}
            labelSteppers={isNil(labelStepperProps) ? [] : [labelStepperProps]}
            previewObjects={{
              lines: [state.prevLine],
              points: state.prevPoints.filter((p) => !p.invisible),
            }}
          />
        );
      }}
    </WithLocalRedux>
  );
};

GeoAddLine.displayName = 'GeoAddLine';
