export const equationFeedbacks = [
  {
    input: '6x+4=3x+16',
    support: 'Subtrahiere 4 auf beiden Seiten der Gleichung.',
    hint: 'Bringe die Zahlen auf eine Seite der Gleichung.',
    expected: '6x=3x+12',
    expectedTransformation: '-4',
  },
  {
    input: '6x=3x+12',
    support: 'Subtrahiere 3x auf beiden Seiten der Gleichung.',
    hint: 'Bringe x auf eine Seite der Gleichung.',
    expected: '3x=12',
    expectedTransformation: '-3x',
  },
  {
    input: '3x+4=16',
    support: 'Subtrahiere 4 auf beiden Seiten der Gleichung.',
    hint: 'Bringe die Zahlen auf eine Seite der Gleichung.',
    expected: '3x=12',
    expectedTransformation: '-4',
  },
  {
    input: '6x-12=3x',
    support: 'Addiere 12 auf beiden Seiten der Gleichung.',
    hint: 'Bringe die Zahlen auf eine Seite der Gleichung.',
    expected: '6x=3x+12',
    expectedTransformation: '+12',
  },
  {
    input: '2x-4=x',
    support: 'Addiere 4 auf beiden Seiten der Gleichung.',
    hint: 'Bringe die Zahlen auf eine Seite der Gleichung.',
    expected: '2x=x+4',
    expectedTransformation: '+4',
  },
  {
    input: '2x=x+4',
    support: 'Subtrahiere x auf beiden Seiten der Gleichung.',
    hint: 'Bringe x auf eine Seite der Gleichung.',
    expected: 'x=4',
    expectedTransformation: '-x',
  },
  {
    input: '3x=12',
    support: 'Teile auf beiden Seiten durch 3.',
    hint: 'Teile durch die Zahl vor x.',
    expected: 'x=4',
    expectedTransformation: ':3',
  },
  {
    input: '3x-12=0',
    support: 'Addiere 12 auf beiden Seiten der Gleichung.',
    hint: 'Bringe die Zahlen auf eine Seite der Gleichung.',
    expected: '3x=12',
    expectedTransformation: '+12',
  },
  {
    input: '-12=-3x',
    support: 'Teile auf beiden Seiten durch -3.',
    hint: 'Teile durch die Zahl vor x.',
    expected: '4=x',
    expectedTransformation: ':(-3)',
  },
  {
    input: 'x-4=0',
    support: 'Addiere 4 auf beiden Seiten der Gleichung.',
    hint: 'Bringe die Zahlen auf eine Seite der Gleichung.',
    expected: 'x=4',
    expectedTransformation: '+4',
  },
  {
    input: '-4=-x',
    support: 'Multipliziere beide Seiten mit oder teile beide Seiten durch -1.',
    hint: 'Teile durch die Zahl vor x.',
    expected: '4=x',
    expectedTransformation: ':(-1)',
  },
  {
    input: 'x=4',
    support: '',
    hint: '',
    expected: '',
  },
  {
    input: '4=x',
    support:
      'Prima, eventuell kannst du noch 4 und x tauschen, so dass x auf der linken Seite steht.',
    hint: 'Bringe die Gleichung noch in die richtige Reihenfolge.',
    expected: 'x=4',
    expectedTransformation: '+x-4',
  },
];

export const termFeedbacks = [
  {
    input: '4+5*(14-8)',
    support: 'Rechne 14 minus 8.',
    hint: 'Rechne zunächst in den Klammern aus.',
    expected: '4+5*6',
    expectedTransformation: '',
  },
  {
    input: '4+5*6',
    support: 'Rechne 5 mal 6.',
    hint: 'Punktrechnung geht vor Strichrechnung.',
    expected: '4+30',
    expectedTransformation: '',
  },
  {
    input: '4+30',
    support: 'Rechne 4 + 30.',
    hint: 'Bilde die Summe.',
    expected: '34',
    expectedTransformation: '',
  },
];
