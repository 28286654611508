import * as React from 'react';

import styles from './Handle.scss';
import classNames from 'classnames';
import { DEFAULT_HANDLE_HEIGHT, DEFAULT_HANDLE_WIDTH } from '../defaults';
import { type MouseOrTouch } from '@bettermarks/gizmo-types';

export type ChartHandleProps = {
  active?: boolean;
  onMouseDown?: (evt: MouseOrTouch) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

export const Handle: React.FC<ChartHandleProps> = ({
  active,
  onMouseDown,
  onMouseOver,
  onMouseLeave,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={DEFAULT_HANDLE_WIDTH}
    height={DEFAULT_HANDLE_HEIGHT}
    viewBox={`0 0 ${DEFAULT_HANDLE_WIDTH} ${DEFAULT_HANDLE_HEIGHT}`}
    className={classNames(styles.handle, active ? styles.active : '')}
    onMouseDown={onMouseDown}
    onTouchStart={onMouseDown}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    data-cy="chart-handle"
  >
    <path
      d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8.1 18-18S27.9 0 18 0L18 0z"
      className={styles.outerCircle}
    />
    <path
      d="M18 2c8.8 0 16 7.2 16 16s-7.2 16-16 16S2 26.8 2 18 9.2 2 18 2"
      className={styles.innerCircle}
    />
    <path d="M12 16l6-6L24 16H12zM12 20l6 6L24 20H12z" className={styles.arrows} />
  </svg>
);

Handle.displayName = 'Handle';
