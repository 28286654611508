import * as React from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';

import { type ContentReference } from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';

import styles from './BarGroup.scss';

export type BarGroupLabelProps = {
  content: ContentReference;
  availableWidth: number;
  x: number;
  y: number;
  onGroupMeasured?: (width: number, height: number, tooThick: boolean) => void;
};

export type BarGroupLabelState = {
  width: number;
  height: number;
};

export class BarGroupLabel extends React.Component<BarGroupLabelProps, BarGroupLabelState> {
  state = {
    width: 0,
    height: 0,
  };

  onMeasured = (mWidth: number, mHeight: number) =>
    this.props.onGroupMeasured &&
    this.props.onGroupMeasured(mWidth, mHeight, mWidth > this.props.availableWidth);

  onResize = ({ offset }: any) => {
    this.setState({ ...offset });

    this.onMeasured(this.state.width, this.state.height);
  };

  render() {
    const { availableWidth, content, x, y } = this.props;
    const { width } = this.state;

    return (
      <div
        className={classNames(styles.absolute, width === 0 && styles.invisible)}
        style={{
          left: x - width * 0.5,
          top: y,
        }}
      >
        <Measure offset onResize={this.onResize}>
          {({ measureRef }) => (
            <div ref={measureRef}>
              <PolymorphicGizmo refid={content.$refid} availableWidth={availableWidth} />
            </div>
          )}
        </Measure>
      </div>
    );
  }
}
