import {
  type FElement,
  type FunctionParameter,
  type ImporterContext,
  PARAMETER_DEFAULTS,
  parseCommonAttribs,
  toBoolean,
  xmlTextToBoolean,
} from '@bettermarks/gizmo-types';
import { isNaN, isNil, omit, omitBy } from 'lodash';
import { parseDecoration } from '../formula/importer';
import { importControlLabel } from '../dynamic-representation/importer';

const importSliderConfiguration = (
  configuration: FElement,
  context: ImporterContext,
  label = configuration.findChildTag('label'),
  unit = configuration.findChildTag('unit'),
  errorDecorationForPlotter = configuration.findChildTag('errorDecorationForPlotter')
): Partial<FunctionParameter> =>
  omitBy<Partial<FunctionParameter>>(
    {
      minimum: parseFloat(configuration.findChildTag('min').text),
      maximum: parseFloat(configuration.findChildTag('max').text),
      step: parseFloat(configuration.findChildTag('snapInterval').text),
      rendererAllowsInteraction: xmlTextToBoolean(
        configuration.findChildTag('rendererAllowsInteraction')
      ),
      ignoreZero: configuration.hasChild('ignoreZero'),
      label: importControlLabel(configuration, context),
      unit: !configuration.hasChild('unit') ? undefined : unit.text,
      unitValue: !configuration.hasChild('unitValue')
        ? undefined
        : parseFloat(configuration.findChildTag('unitValue').text),
      errorDecorationForPlotter: !configuration.hasChild('errorDecorationForPlotter')
        ? undefined
        : toBoolean(errorDecorationForPlotter.text),
    },
    (v) => isNil(v) || isNaN(v)
  );

/**
 * Imports the slider content. This is a partial parameter without the name
 * property. The name property is taken from the actual function parameter, but
 * we merge the slider properties on top.
 *
 * @param xml the xml element
 * @return FunctionParameter partial function parameter is returned and later applied on top.
 */
export const importSlider = (
  xml: FElement,
  context: ImporterContext,
  mrow = xml.findChildTag('mrow'),
  common = parseCommonAttribs(xml)
): Partial<FunctionParameter> =>
  omit(
    {
      ...PARAMETER_DEFAULTS,
      ...importSliderConfiguration(mrow.findChildTag('configuration'), context),
      ...omitBy<Partial<FunctionParameter>>(
        {
          ...parseDecoration(mrow.attribute('decoration')),
          value: parseFloat(mrow.findChildTag('mn').text),
          refId: common.$id,
        },
        (v) => isNil(v) || isNaN(v)
      ),
    },
    'name'
  );
