import { parse } from 'query-string';
import { isString } from 'lodash';

interface UploadQueryString {
  assignmentEndDate?: string;
  autostart?: boolean;
  exerciseid?: string;
  focusElement?: string;
  qaMode?: boolean;
  reporting?: boolean;
  seriesFile?: string;
  seriesReview?: boolean;
  staticUrl?: string;
  testMode?: boolean;
  validation?: string;
}

interface ParsedUploadQuery {
  /**
   * directly start exercise with specified `exerciseId` without showing start page
   * @default false
   */
  autostart: boolean;

  /**
   * the exercise to load from `staticUrl` in full or short format
   * (see `expandSeriesIdPath`)
   */
  exerciseid?: string;

  /**
   * can be used to focus on specific DOM element to take a screenshot.
   * Used for testing bot
   */
  focusElement?: string;

  /**
   * start the series in review mode
   * @default false
   */
  seriesReview: boolean;

  seriesFile?: string;

  /**
   * can be used to specified the url where to load content from
   */
  staticUrl?: string;

  /**
   * start the series in test-mode (random-flow)
   * @default false
   */
  testMode: boolean;

  /**
   * start the series in qa-mode (solved and expanded)
   * @default false
   */
  qaMode: boolean;

  /**
   * @default false
   */
  reporting: boolean;

  /**
   * can be used to change validation `ValidatorType` for debugging purpose
   * (`server` | `client` | `webworker` | `native` )
   */
  validation?: string;

  /**
   * can be used to manually set an due date for a series exercise
   */
  assignmentEndDate?: string;
}

export const parseUploadQueryString = (queryString: string): ParsedUploadQuery => {
  const parsedQuery: UploadQueryString = parse(queryString, {
    parseBooleans: true,
  });
  return {
    autostart: Boolean(parsedQuery.autostart),
    exerciseid: isString(parsedQuery.exerciseid) ? parsedQuery.exerciseid : undefined,
    focusElement: isString(parsedQuery.focusElement) ? parsedQuery.focusElement : undefined,
    seriesReview: Boolean(parsedQuery.seriesReview),
    seriesFile: isString(parsedQuery.seriesFile) ? parsedQuery.seriesFile : undefined,
    staticUrl: isString(parsedQuery.staticUrl) ? parsedQuery.staticUrl : undefined,
    testMode: Boolean(parsedQuery.testMode),
    qaMode: Boolean(parsedQuery.qaMode),
    reporting: Boolean(parsedQuery.reporting),
    validation: isString(parsedQuery.validation) ? parsedQuery.validation : undefined,
    assignmentEndDate:
      isString(parsedQuery.assignmentEndDate) && !isNaN(Date.parse(parsedQuery.assignmentEndDate))
        ? new Date(parsedQuery.assignmentEndDate).toISOString()
        : undefined,
  };
};
