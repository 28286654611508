import { noop } from 'lodash';
import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { observeElement } from './helpers';

export interface TopLeft {
  readonly top: number;
  readonly left: number;
}

export interface BottomRight {
  readonly bottom: number;
  readonly right: number;
}

export interface Dimensions {
  readonly width: number;
  readonly height: number;
}

export type BoundingRect = Dimensions & Margin;

export type Margin = TopLeft & BottomRight;

export type Rect = TopLeft & Dimensions;
export type MeasureType = 'client' | 'offset' | 'scroll' | 'bounds' | 'margin';
export type MeasureOptions = ReadonlyArray<MeasureType>;

/**
 * Different measures are grouped by measurement types.ts.
 * You should only follow the type that you are interested in.
 */
export type ContentRect = {
  client?: Rect;
  offset?: Rect;
  scroll?: Rect;
  bounds?: BoundingRect;
  margin?: Margin;
};

export interface ContentMeasures {
  contentWidth: number;
  contentHeight: number;
}

export type OnResize<T> = (contentRect: ContentRect, previousState: T) => Nullable<T>;

export type MeasureState<T> = {
  state: T;
};

export type MeasureStates<T> = ReadonlyArray<MeasureState<T>>;

/**
 *
 * Measurement hook for react component, subscribe to size changes of an element.
 *
 * It uses ResizeObserver browser API / polyfill if API is not implemented.
 *
 * Usage:
 * {{{
 * const MeasuredComponent: React.FC = () => {
 *   const [
 *      contentRect,                                       // State, resulting from `onResize`,
 *                                                         //   containing measures
 *      ref                                                // Ref to be passed to the DOM element
 *                                                         //   we want to measure
 *   ] = useMeasure<ContentRect, HTMLDivElement>(
 *     {},                                                 // default measures are empty
 *     ['client', 'offset', 'scroll', 'bounds', 'margin'], // subscribe to all measurement types.ts
 *     (contentRect: ContentRect) => contentRect           // return the full content measures of
 *                                                         //   the element
 *   );
 *   return (
 *     <div ref={ref}>
 *       <pre>{JSON.stringify(contentRect, null, 2)}</pre>
 *        // will output :
 *        {
 *         client: {
 *           top: 0,
 *           left: 0,
 *           width: 902,
 *           height: 576
 *         },
 *         offset: {
 *           top: 547,
 *           left: 437,
 *           width: 902,
 *           height: 576
 *         },
 *         scroll: {
 *           top: 0,
 *           left: 0,
 *           width: 902,
 *           height: 580
 *         },
 *         bounds: {
 *           x: 437.3999938964844,
 *           y: -3.8000030517578125,
 *           width: 902,
 *           height: 576,
 *           top: -3.8000030517578125,
 *           right: 1339.3999938964844,
 *           bottom: 572.1999969482422,
 *           left: 437.3999938964844
 *         },
 *         margin: {
 *           top: 90,
 *           right: 180,
 *           bottom: 90,
 *           left: 180
 *         }
 *     </div>
 *   );
 * };
 * }}}
 *
 * @param initialState first measure state before the element is measured
 * @param options which measurement(s) type(s) you want to follow:
 *                 'client' | 'offset' | 'scroll' | 'bounds' | 'margin'
 * @param onResize Callback when a new measurement is reported.
 *                 The return value is the new local state
 *                 If it return `null` or `undefined`, it will not rerender component on each resize
 * @param RO For testing purpose, enables you to pass a custom sub class of ResizeObserver
 *           (from polyfills)
 *
 * @returns A tuple of [measureState, ref]:
 *            - The measure state returned by the onResize() callback
 *            - An HTML element ref to be attached to the HTML element we wish to measure.
 *              This ref must be attached unconditionally and starting from the first render.
 */
export const useMeasure = <T, E extends HTMLElement>(
  initialState: T,
  options: MeasureOptions,
  onResize: OnResize<T>,
  RO = window.ResizeObserver
): [T, React.RefObject<E>] => {
  const ref = React.useRef<E>(null);
  const [state, setState] = React.useState<T>(initialState);

  React.useEffect(() => {
    const element = ref.current;
    return element ? observeElement(element, options, setState, onResize, RO) : noop;
  }, [ref.current]);

  return [state, ref];
};

/**
 * Returns `[{ contentWidth, contentHeight }, contentRef]`.
 * If you assign `contentRef` to an element as its `ref`, `contentWidth` and `contentHeight`
 * will take the values of the element's bounds.
 * */
export const useBoundsMeasure = () =>
  useMeasure<ContentMeasures, HTMLDivElement>(
    { contentWidth: 0, contentHeight: 0 },
    ['bounds'],
    ({ bounds }, { contentWidth, contentHeight }) =>
      bounds && (bounds.width !== contentWidth || bounds.height !== contentHeight)
        ? { contentWidth: bounds.width, contentHeight: bounds.height }
        : null
  );

/**
 *
 * Measurement hook for react component, subscribe to size changes of multiple elements.
 *
 * It follows the same logic as useMeasure, but is used for more than one element.
 *
 * @param initialState initial states of all elements to be measured.
 *                     **Important! - the length of initialStates must be fixed!**
 * @param options which measurement(s) type(s) you want to follow:
 *                 'client' | 'offset' | 'scroll' | 'bounds' | 'margin'
 * @param onResize Callback when a new measurement is reported.
 *                 The return value is the new local state
 *                 If it return `null` or `undefined`, it will not re-render component on each
 *                 resize.
 * @param refs  references of elements we want to measure
 * @param RO For testing purpose, enables you to pass a custom sub class of ResizeObserver
 *
 * @returns an array of {state, ref} where
 *            state = The measure state returned by the onResize() callback.
 *            ref = An HTML element ref to be attached to the HTML element we wish to measure.
 *                  This ref must be attached unconditionally and starting from the first render.
 */
export const useMeasures = <T, E extends HTMLElement>(
  initialStates: ReadonlyArray<T>,
  options: MeasureOptions,
  onResize: OnResize<T>,
  refs: ReadonlyArray<React.RefObject<E>>,
  RO = ResizeObserver
): ReadonlyArray<T> => {
  /*
   * refs in general should not be called in a loop, but here it is safe because we know that
   * initialStates must always have the same length. There is currently no idiomatic way to create
   * multiple refs with useRef.
   *
   * See: https://github.com/bettermarks/bm-toolbox/pull/2558/files/60b004903422d1357abb29e6b029f86db98b1322#r268996859
   */
  const [states, setStates] = React.useState<ReadonlyArray<T>>(initialStates);

  React.useEffect(() => {
    const setNewState = (idx: number) => (stateCallback: (prevState: T) => T) => {
      setStates((prevStates) => {
        const prevState = prevStates[idx];
        const newState = stateCallback(prevState);

        return newState === prevState
          ? prevStates
          : [
              ...prevStates.slice(0, idx),
              stateCallback(prevStates[idx]),
              ...prevStates.slice(idx + 1),
            ];
      });
    };

    const disconnections = refs.map(({ current: element }, index) =>
      element ? observeElement(element, options, setNewState(index), onResize, RO) : noop
    );

    return () => disconnections.forEach((disconnect) => disconnect());
  }, []);

  return states;
};
