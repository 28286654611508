import * as React from 'react';
import { PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';
import { type Arrow, TableOrientation as Orientation } from '@bettermarks/gizmo-types';
import { ContainerArrow } from './ContainerArrow';

import styles from '../DecoratedCell.scss';

type VerticalArrowsProps = {
  arrows: Arrow[];
  rowHeights: number[];
  orientation: Orientation;
  availableWidth: number;
};

export const VerticalArrows: React.FC<VerticalArrowsProps> = ({
  arrows,
  rowHeights,
  orientation,
  availableWidth,
}) => {
  const arrowsWithHeight = arrows.map((a) => {
    const [fromRow, toRow] = [a.from.coords[0], a.to.coords[0]].sort();
    return {
      ...a,
      height: rowHeights.reduce((acc, rh, i) => (i >= fromRow && i < toRow ? acc + rh : acc), 0),
      fromRow,
      toRow,
    };
  });
  return (
    <table>
      <tbody>
        {rowHeights.reduce((acc, rh, i) => {
          const arrowFrom = arrowsWithHeight.find((a) => a.fromRow === i);
          const arrowTo = arrowsWithHeight.find((a) => a.toRow === i);
          const arrowIn = arrowsWithHeight.find((a) => a.fromRow < i && a.toRow > i);
          return arrowFrom
            ? [
                ...acc,
                <React.Fragment key={i}>
                  {!arrowTo && (
                    <tr>
                      <td style={{ height: rh * 0.5 }} />
                    </tr>
                  )}
                  <tr>
                    {orientation === Orientation.west && (
                      <td className={styles.right} style={{ textAlign: 'right' }}>
                        <PolymorphicGizmo
                          refid={arrowFrom.label.$refid}
                          availableWidth={availableWidth}
                        />
                      </td>
                    )}
                    <td style={{ height: arrowFrom.height }}>
                      <ContainerArrow
                        orientation={arrowFrom.orientation}
                        length={arrowFrom.height}
                        arrowStart={arrowFrom.from.head}
                        arrowEnd={arrowFrom.to.head}
                      />
                    </td>
                    {orientation === Orientation.east && (
                      <td>
                        <PolymorphicGizmo
                          refid={arrowFrom.label.$refid}
                          availableWidth={availableWidth}
                        />
                      </td>
                    )}
                  </tr>
                </React.Fragment>,
              ]
            : arrowIn
            ? acc
            : [
                ...acc,
                <tr key={i}>
                  <td style={{ height: arrowTo ? 0.5 * rh : rh }} />
                </tr>,
              ];
        }, [])}
      </tbody>
    </table>
  );
};

VerticalArrows.displayName = 'VerticalArrows';
