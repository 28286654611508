import * as React from 'react';
import classNames from 'classnames';
import { ChevronUpSmall } from '../../../../../../components/icons';
import styles from './ModeSelectorDrawerToggle.scss';
import { type ToggleProps } from '../../ToolbarButton';
import { ModeSelector } from './ModeSelector';
import { ExcludedFromTabNavigationButton } from '../../../../../../components/ExcludedFromTabNavigation';

export const ModeSelectorDrawerToggle: React.FC<ToggleProps & { selectedMode?: string }> = ({
  onClick,
  on,
  selectedMode,
}) => (
  <ExcludedFromTabNavigationButton
    onClick={onClick}
    className={classNames(styles.button, on && styles.buttonActive)}
  >
    <span className={styles.buttonItem}>
      <ModeSelector selectedMode={selectedMode} />
    </span>
    <span className={styles.buttonIcon}>
      <ChevronUpSmall />
    </span>
  </ExcludedFromTabNavigationButton>
);
