import { defaultTo, isEmpty } from 'lodash';
import {
  BASIC_SHAPE_DEFAULT_CONTENT,
  type BasicShapeContent,
  type BasicShapeType,
  CIRCLE,
  type Importer,
  RECTANGLE,
  SQUARE,
} from '@bettermarks/gizmo-types';
import { dimensionsCircle, dimensionsRectangle } from '../components/BasicShape/helper';
import { parseDecoString } from '../../gizmo-utils/decoration';

type BasicShapeDecoKeys = 'color' | 'gradientType';

type Decoration = {
  color: string;
  gradientType?: 'radial';
};

const parseDecoration = (decoString: string): Decoration => {
  const {
    object: { color, gradientType },
  } = parseDecoString<BasicShapeDecoKeys>(decoString);
  return {
    ...(gradientType === 'radial' && { gradientType }),
    color: defaultTo<string>(color, BASIC_SHAPE_DEFAULT_CONTENT.color),
  };
};

/**
 * Converts XML data to the content of the basic-shape gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * 'type' can be 'rectangle', 'square' or 'circle'.
 * 'scalefactor' is optional and defaults to 1.
 * the 'color' in the 'decoration' is optional and defaults to 'bm-red'.
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @returns The metadata and parsed xml as `Content`
 */
export const importBasicShape: Importer<BasicShapeContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const decoration = parseDecoration(mrow.attribute('decoration'));
  const configuration = mrow.findChildTag('configuration');
  const scalefactorText = configuration.findChildTag('scalefactor').text;
  const scalefactor = isEmpty(scalefactorText)
    ? BASIC_SHAPE_DEFAULT_CONTENT.scalefactor
    : parseFloat(scalefactorText);
  const shape = configuration.findChildTag('type').text as BasicShapeType;

  const size = (shape: BasicShapeType) => {
    const s = {
      [RECTANGLE]: dimensionsRectangle(scalefactor, false),
      [SQUARE]: dimensionsRectangle(scalefactor, true),
      [CIRCLE]: dimensionsCircle(scalefactor),
    }[shape];
    return {
      unscaledWidth: s.width,
      unscaledHeight: s.height,
    };
  };

  return {
    ...preContent,
    ...decoration,
    shape,
    scalefactor,
    ...size(shape), // parent gizmos have to know about dimensions ...
  };
};
