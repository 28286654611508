import { toBoolean, toInt, toFloat } from '../../gizmo-utils/filters';
import { defaultTo } from 'lodash';
import { toXmlDoc } from './helpers';

export interface FElement {
  /**
   * Returns the value of the given attribute 'name', if no attribute with the name is found,
   * the defaultValue is returned.
   * @param name The attribute name to look for
   * @param defaultValue The default value to return if no matching attribute is found.
   */
  attribute(name: string, defaultValue?: string): string;

  /**
   * @see xmlAttributesToProperties
   */
  attributesToProps<M extends string, O extends string, RT>(
    filter: (value: string) => RT,
    mandatory: M[],
    optional?: O[]
  ): Record<M, RT> & Partial<Record<O, RT>>;

  readonly children: ReadonlyArray<FElement>;

  readonly exists: boolean;

  readonly tagName: string;

  hasAttribute(name: string): boolean;

  hasChildren(): boolean;

  hasChild(tag: string): boolean;

  filterChildren(callback: (child: FElement, i: number) => boolean): FElement[];

  findChildTag(tag: string): FElement;

  findChildTagWithAttribute(tag: string, attName: string, attValue: string): FElement;

  getChildrenByTagName(tag: string): FElement[];

  getPath(tagNames: string[]): FElement;

  readonly firstChild: FElement;

  readonly localName: string;

  /**
   * @see xmlTagsToProperties
   */
  tagsToProps<TProps, TValue>(
    map: (value: FElement) => TValue,
    mandatory: KeysOfType<TProps, TValue | undefined>[],
    optional?: KeysOfType<TProps, TValue | undefined>[],
    defaultValue?: TValue
  ): PartialWithKeysOfType<TProps, TValue>;

  readonly text: string;

  toString(): string;

  /**
   * Provides a copy of the XML tree represented by the current element,
   * that allows to modify the XML.
   */
  toMutableCopy(): MutableElement;
}

/**
 * Extension of FElement that allows modification of any child.
 *
 * @see FElement.toMutableCopy
 */
export interface MutableElement extends FElement {
  readonly children: ReadonlyArray<MutableElement>;

  filterChildren(callback: (child: MutableElement, i: number) => boolean): MutableElement[];

  findChildTag(tag: string): MutableElement;

  findChildTagWithAttribute(tag: string, attName: string, attValue: string): MutableElement;

  getChildrenByTagName(tag: string): MutableElement[];

  getPath(tagNames: string[]): MutableElement;

  readonly firstChild: MutableElement;

  /**
   * @see xmlTagsToProperties
   */
  tagsToProps<TProps, TValue>(
    map: (value: MutableElement) => TValue,
    mandatory: KeysOfType<TProps, TValue | undefined>[],
    optional?: KeysOfType<TProps, TValue | undefined>[],
    defaultValue?: TValue
  ): PartialWithKeysOfType<TProps, TValue>;

  setAttribute(name: string, value: string): void;

  remove(element: FElement): void;

  insertBefore(reference: FElement, xml: string): void;
}

class SingletonNullFElement implements MutableElement {
  attribute(name: string, defaultValue = '') {
    return defaultValue;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributesToProps(): any {
    return {};
  }

  readonly children = [];

  readonly exists = false;

  hasAttribute() {
    return false;
  }

  setAttribute() {
    /* ¯\_(ツ)_/¯ */
  }

  hasChildren() {
    return false;
  }

  filterChildren() {
    return [];
  }

  readonly tagName = '';

  findChildTag() {
    return this;
  }

  findChildTagWithAttribute() {
    return this;
  }

  getChildrenByTagName() {
    return [];
  }

  getPath() {
    return this;
  }

  hasChild() {
    return false;
  }

  remove(element: FElement): void {
    /**/
  }

  insertBefore(element: FElement, xml: string): void {
    /**/
  }

  readonly firstChild = this;

  readonly localName = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tagsToProps(): any {
    return {};
  }

  readonly text = '';

  toString() {
    return '';
  }

  toMutableCopy() {
    return this;
  }
}

/**
 * Use `FElement.exists` to test if the XML node exists.
 * This is an internal implementation detail that should not be exported!
 */
const NullFElement = new SingletonNullFElement();

class WrapperFElement implements MutableElement {
  private _elements: WrapperFElement[] | undefined;

  constructor(private readonly element: Element) {}

  attribute(name: string, defaultValue = '') {
    return this.element.hasAttribute(name)
      ? defaultTo(this.element.getAttribute(name), defaultValue)
      : defaultValue;
  }

  attributesToProps<M extends string, O extends string, RT>(
    filter: (value: string) => RT,
    mandatory: M[],
    optional?: O[]
  ): Record<M, RT> & Partial<Record<O, RT>> {
    return xmlAttributesToProperties(this, filter, mandatory, optional);
  }

  get children() {
    if (this._elements === undefined) {
      this._elements = childElements(this.element).map((element) => new WrapperFElement(element));
    }
    return this._elements;
  }

  get tagName() {
    return this.element.tagName;
  }

  readonly exists = true;

  hasAttribute(name: string) {
    return this.element.hasAttribute(name);
  }

  getAttribute(name: string): string | null {
    return this.element.getAttribute(name);
  }

  setAttribute(name: string, value: string) {
    this.element.setAttribute(name, value);
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  filterChildren(callback: (child: this, i: number) => boolean) {
    return this.children.filter(callback);
  }

  findChildTag(tag: string) {
    return this.children.find((e) => e.localName === tag) || NullFElement;
  }

  findChildTagWithAttribute(tag: string, attName: string, attValue: string) {
    const found = this.children.filter(
      (e) => e.localName === tag && e.attribute(attName) === attValue
    );
    return found[0] || NullFElement;
  }

  getChildrenByTagName(tag: string) {
    return this.filterChildren((e) => e.localName === tag);
  }

  getPath(tagNames: string[]) {
    return tagNames.reduce((acc: this, v: string) => acc.findChildTag(v), this);
  }

  hasChild(tag: string) {
    return this.getChildrenByTagName(tag).length > 0;
  }

  get firstChild() {
    return this.children[0] || NullFElement;
  }

  get localName(): string {
    return this.element.localName;
  }

  tagsToProps<TProps, TValue>(
    map: (value: MutableElement) => TValue,
    mandatory: KeysOfType<TProps, TValue | undefined>[],
    optional?: KeysOfType<TProps, TValue | undefined>[],
    defaultValue?: TValue
  ): PartialWithKeysOfType<TProps, TValue> {
    return xmlTagsToProperties(this.children, map, mandatory, optional, defaultValue);
  }

  get text() {
    return this.element.textContent || '';
  }

  remove(child: FElement): void {
    if (child instanceof WrapperFElement) {
      this.element.removeChild(child.element);
      this._elements = undefined;
    }
  }

  insertBefore(reference: FElement, xml: string): void {
    if (reference instanceof WrapperFElement) {
      this.element.insertBefore(toXmlDoc(xml).documentElement, reference.element);
      this._elements = undefined;
    }
  }

  toString() {
    return this.element.toString();
  }

  toMutableCopy() {
    return new WrapperFElement(toXmlDoc(this.toString()).documentElement);
  }
}

export const createFElement = (element: Element | null): FElement =>
  element !== null ? new WrapperFElement(element) : NullFElement;

/**
 * For simulating an FElement that is not present in the XML
 */
export const createNonExistingFElement = (): MutableElement => NullFElement;

/**
 * Returns all child `elements` of the `node`.
 * The `Node.childNodes` including non-element nodes like text and comment nodes
 * are filtered by nodeType `ELEMENT_NODE`.
 * @param {Node} node
 * @returns {Element[]}
 */
export function childElements(node: Node): Element[] {
  return Array.from(node.childNodes)
    .filter((c) => c.nodeType === Node.ELEMENT_NODE)
    .map((c) => c as Element);
}

/**
 * Converts xml tags to properties of the returned object.
 * `mandatory` tags have to exist exactly once, otherwise an error is thrown.
 * Properties for `optional` tags will only be present in the result when the tag is present.
 * When `defaultValue` is specified, all `optional` fields will be present with that value.
 *
 * @see src/gizmo-utils/filters.ts for possible filters
 */
export function xmlTagsToProperties<TProps, TValue>(
  elements: ReadonlyArray<FElement>,
  map: (xml: FElement) => TValue,
  mandatory: KeysOfType<TProps, TValue | undefined>[],
  optional?: KeysOfType<TProps, TValue | undefined>[],
  defaultValue?: TValue
): PartialWithKeysOfType<TProps, TValue> {
  const found = {} as PartialWithKeysOfType<TProps, TValue>;
  if (elements.length > 0) {
    mandatory.forEach((fKey) => {
      const filteredElements = elements.filter((e) => e.localName === fKey);
      if (filteredElements.length > 1) {
        throw new Error(
          `${filteredElements.length} occurrences of tag ${String(fKey)}, expected one.`
        );
      }
      if (filteredElements.length === 0) {
        throw new Error(`tag ${String(fKey)} is mandatory but not present`);
      }
      if (filteredElements.length === 1) {
        found[fKey] = map(filteredElements[0]);
      }
    });
  }

  optional &&
    optional.forEach((fKey) => {
      const filteredElements = elements.filter((e) => e.localName === fKey);
      if (filteredElements.length > 1) {
        throw new Error(
          `${filteredElements.length} occurrences of tag ${String(fKey)}, expected zero or one.`
        );
      }
      if (filteredElements.length === 1) {
        found[fKey] = map(filteredElements[0]);
      } else if (defaultValue !== undefined) {
        // when defaultValue is given apply it to the `optional` when not available as element
        found[fKey] = defaultValue;
      }
    });
  return found;
}

/**
 * Converts all attributes of a tag into properties on the returned object.
 * `mandatory` attributes have to exist, otherwise an error is thrown.
 * `optional` attributes will not be present in the returned object if the attribute is not present.
 *
 * @see src/gizmo-utils/filters.ts for possible filters
 */
export function xmlAttributesToProperties<M extends string, O extends string, RT>(
  element: FElement,
  filter: (value: string) => RT,
  mandatory: M[],
  optional?: O[]
): Record<M, RT> & Partial<Record<O, RT>> {
  const found = {} as Record<M | O, RT>;
  mandatory.forEach((fKey) => {
    if (!element.hasAttribute(fKey)) {
      throw new Error(`attribute ${fKey} is mandatory but not present`);
    } else {
      found[fKey] = filter(element.attribute(fKey));
    }
  });
  if (optional) {
    optional.forEach((fKey) => {
      if (element.hasAttribute(fKey)) {
        found[fKey] = filter(element.attribute(fKey));
      }
    });
  }
  return found;
}

/**
 * Filter for mapping an XML tag (FElement) to it's textContent.
 */
export const xmlText = (value: FElement) => {
  return value.text.trim();
};

/**
 * Filter for mapping the text content of an XML tag (FElement) to a boolean.
 */
export const xmlTextToBoolean = (value: FElement) => toBoolean(value.text.trim());

/**
 * Filter for mapping the text content of an XML tag (FElement) to a integer.
 */
export const xmlTextToInt = (value: FElement) => toInt(value.text);

/**
 * Filter for mapping the text content of an XML tag (FElement) to a float.
 */
export const xmlTextToFloat = (value: FElement) => toFloat(value.text);

/**
 * Filter for mapping the text content of an XML tag (FElement) to a list of strings.
 */
export const xmlTextToList =
  (separator: string) =>
  (value: FElement): string[] =>
    value.text ? value.text.split(separator) : [];
