import React from 'react';
import { type ContentMeasures, useBoundsMeasure } from '../../gizmo-utils/useMeasure';
import { type ReportContentType, reportHeightToParent } from '../emplayer/helper';

interface UseReportHeightToWhiteboardProps {
  contentId: string;
  contentType: ReportContentType;
  playerHPadding: number;
  shouldReportHeight: boolean;
}

export const useReportHeightToWhiteboard = ({
  contentId,
  contentType,
  playerHPadding,
  shouldReportHeight,
}: UseReportHeightToWhiteboardProps): {
  dimensions: ContentMeasures;
  ref: React.RefObject<HTMLDivElement>;
} => {
  const [{ contentWidth, contentHeight }, contentRef] = useBoundsMeasure();

  React.useEffect(
    () =>
      shouldReportHeight && contentHeight
        ? reportHeightToParent(contentHeight + 2 * playerHPadding, contentId, contentType)
        : undefined,
    [contentHeight, shouldReportHeight]
  );

  return { dimensions: { contentWidth, contentHeight }, ref: contentRef };
};
