import * as React from 'react';
import { type ContentColor } from '@bettermarks/gizmo-types';
import { getNamedColor } from '@bettermarks/importers';
import {
  DEFAULT_STACKED_BAR_HEIGHT,
  DEFAULT_STACKED_BAR_STROKE,
  DEFAULT_STACKED_BAR_STROKE_WIDTH,
} from '../../defaults';

export type BarProps = {
  barStart: number;
  barWidth: number;
  color: ContentColor;
};

export const Bar: React.FC<BarProps> = ({ barStart, barWidth, color }) => {
  return (
    <rect
      x={barStart}
      y={DEFAULT_STACKED_BAR_STROKE_WIDTH}
      width={barWidth}
      height={DEFAULT_STACKED_BAR_HEIGHT}
      style={{ fill: getNamedColor(color) }}
      stroke={DEFAULT_STACKED_BAR_STROKE}
      strokeWidth={DEFAULT_STACKED_BAR_STROKE_WIDTH}
    />
  );
};

Bar.displayName = 'Bar';
