import {
  connect,
  type ConnectedProps,
  type MapStateToProps,
  type MapDispatchToPropsFunction,
} from 'react-redux';

import { type ApplicationState, ExerciseStatus } from '../../../../../types';

import { switchExerciseResumable } from '../../Exercise/actions';
import { handIn } from '../../Series/actions';
import { closeDialog } from '../../SeriesPlayer/actions';
import { HandIn, type HandInOwnProps } from './HandIn';

type StateProps = {
  unfilledExercises: ReadonlyArray<number>;
  numExercises: number;
  singleTry?: boolean;
};
const mapStateToProps: MapStateToProps<StateProps, HandInOwnProps, ApplicationState> = (state) => ({
  numExercises: state.series.exercises.length,
  unfilledExercises: state.series.exercises
    .map((ex, idx) => (ex.status === ExerciseStatus.withInput ? undefined : idx))
    .filter((x): x is number => x !== undefined),
  singleTry: state.series.seriesSettings && !state.series.seriesSettings.repeatable,
});

type DispatchProps = {
  onCancel: () => void;
  onConfirm: () => void;
  onSwitchExercise: (index: number) => void;
};
const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, HandInOwnProps> = (
  dispatch
) => ({
  onCancel: () => dispatch(closeDialog()),
  onConfirm: () => dispatch(handIn()),
  onSwitchExercise: (index: number) => {
    dispatch(switchExerciseResumable(index));
    dispatch(closeDialog());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HandInReduxProps = ConnectedProps<typeof connector>;

export const HandInContainer = connector(HandIn);
