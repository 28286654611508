import * as React from 'react';
import type { Decoration, ValueStepperPosition } from '@bettermarks/gizmo-types';

export type ValueSetterMap = {
  [key: string]: {
    value: number;
  };
};

export type ValueStepperConfigMap = {
  [key: string]: {
    min: number;
    max: number;
    step: number;
    position: ValueStepperPosition;
    decoration: Decoration;
    initiallyActive?: true | undefined;
    onChange: (val: number) => void;
  };
};

export interface IValueSetterContext {
  valueSetterMap: ValueSetterMap;
  valueStepperConfigMap: ValueStepperConfigMap;
}

export type ValueSetterContext = Readonly<IValueSetterContext>;

export const ValueSetterContext = React.createContext<ValueSetterContext>({
  valueSetterMap: {},
  valueStepperConfigMap: {},
});

export const ValueSetterProvider: React.FC<ValueSetterContext> = (props) => (
  <ValueSetterContext.Provider value={props}>{props.children}</ValueSetterContext.Provider>
);

export const useValueSetterContext = () => {
  const context = React.useContext(ValueSetterContext);
  if (context === undefined) {
    throw new Error('useValueSetterContext can only be used inside a ValueSetterProvider');
  }
  return context;
};
