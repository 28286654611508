import { EquationValidatorResult } from './helpers';
import React from 'react';
import { FeedbackType } from './ScaffoldingGizmoRows';
import { linkColor } from './ScaffolderButtons';

export const getFeedbackTextAndColor = (
  feedbackType: FeedbackType,
  language: string,
  hint: string | undefined,
  support: string | undefined,
  currentValidationResult: EquationValidatorResult | undefined,
  nextFeedbackAvailable: boolean,
  handleShowNextFeedback: () => void
) => {
  const nextFeedbackButton = getNextFeedbackButton(
    feedbackType,
    language,
    nextFeedbackAvailable,
    handleShowNextFeedback
  );

  switch (feedbackType) {
    case FeedbackType.DONE:
      return {
        text: language === 'de' ? 'Das ist richtig.' : "That's correct!",
        color: '#33a201',
      };
    case FeedbackType.HINT:
      return {
        text: (
          <span>
            {language === 'de' ? 'Hinweis' : 'Hint'}:{' '}
            {hint || 'TODO: do not allow to get here if there is no hint available'}{' '}
            {nextFeedbackButton}
          </span>
        ),
        color: '#be9b01',
      };
    case FeedbackType.DONE_WITH_HELP:
    case FeedbackType.WRONG:
      return {
        text: getFeedbackTextForValidationResult(
          currentValidationResult || EquationValidatorResult.INTERNAL_ERROR,
          language,
          nextFeedbackButton
        ),
        color: '#980001',
      };
    case FeedbackType.SUPPORT:
      return {
        text: support || 'TODO: do not allow to get here if there is no support available',
        color: '#BE9B01',
      };
  }
};

export const getFeedbackTextForValidationResult = (
  result: EquationValidatorResult | undefined,
  language,
  nextFeedbackButton: JSX.Element
) => {
  if (!result) {
    return language === 'de' ? 'Das ist leider nicht richtig.' : "That's sadly wrong.";
  }
  const key = EquationValidatorResult[result];

  const localizedFeedbacks = {
    INVALID_TOKEN: {
      en: 'You have entered an invalid character.',
      de: 'Du hast ein ungültiges Zeichen eingegeben.',
    },
    CORRECT: {
      en: 'You have transformed it correctly.',
      de: 'Das hast du korrekt umgeformt.',
    },
    INCORRECT: {
      en: <p>That is unfortunately incorrect. {nextFeedbackButton}</p>,
      de: <p>Das ist leider nicht korrekt. {nextFeedbackButton}</p>,
    },
    NOT_AN_EQUATION: {
      en: <p>Please enter an equation. {nextFeedbackButton}</p>,
      de: <p>Bitte gib eine Gleichung ein. {nextFeedbackButton}</p>,
    },
    NO_X: {
      en: <p>Please enter an equation that contains x. {nextFeedbackButton}</p>,
      de: <p>Bitte gib eine Gleichung ein, die x enthält. {nextFeedbackButton}</p>,
    },
    DONE: {
      en: 'Done!',
      de: 'Fertig!',
    },
    DONE_WITH_HELP: {
      en: 'You chose to look at the answer.',
      de: 'Du hast dir die Lösung anzeigen lassen.',
    },
    INTERNAL_ERROR: {
      en: 'Sorry, something went wrong. There is no current validation result.',
      de: 'Sorry, etwas ist schiefgegangen. Es liegt kein aktuelles Validierungsergebnis vor.',
    },
  };
  return localizedFeedbacks[key][language];
};

export const getNextFeedbackType = (feedbackType: FeedbackType): FeedbackType | undefined => {
  switch (feedbackType) {
    case FeedbackType.NONE:
    case FeedbackType.WRONG:
      return FeedbackType.HINT;
    case FeedbackType.HINT:
      return FeedbackType.SUPPORT;
    default:
      return undefined;
  }
};

export const getNextFeedbackButton = (
  currentFeedbackType: FeedbackType,
  language: string,
  nextFeedbackAvailable: boolean,
  handleShowNextFeedback: () => void
) => {
  if (!nextFeedbackAvailable) {
    return <></>;
  }

  let nextFeedbackLinkText: string | undefined = undefined;
  switch (currentFeedbackType) {
    case FeedbackType.WRONG:
      nextFeedbackLinkText = language === 'de' ? 'Tipp anzeigen' : 'Show hint';
      break;
    case FeedbackType.HINT:
      nextFeedbackLinkText = language === 'de' ? 'Weiteren Tipp zeigen' : 'Show another hint';
  }

  if (nextFeedbackLinkText) {
    return (
      <span
        style={{ color: linkColor, textDecorationLine: 'underline', cursor: 'pointer' }}
        onClick={handleShowNextFeedback}
      >
        {nextFeedbackLinkText}
      </span>
    );
  }

  return <></>;
};
