import {
  type ValidatorFeedback,
  type ValidatorResponse,
  type Validity,
} from '@bettermarks/umc-kotlin';
import { type NLPTextInputContent } from '@bettermarks/gizmo-types';

export type NlpValidatorResponse = {
  annotatedNlpTextInputContents: NLPTextInputContent[];
  feedbacks: ValidatorFeedback[];
  nextStep: {
    id: string;
    index: number;
  };
  numberOfErrors: number;
  skippedStepIds: string[];
  validity: Validity;
};

export type ExtendedValidatorResponse = (NlpValidatorResponse | ValidatorResponse) & {
  isEmptyInput: boolean;
};

const emptyFeedbackKeys = [
  'standard.coordinates.empty',
  'standard.coordinates.emptyClassification',
  'standard.empty',
  'standard.emptyDropDown',
];

export function extendValidatorResponse(
  response: NlpValidatorResponse | ValidatorResponse
): ExtendedValidatorResponse {
  const allFeedbackKeysAreEmptyInput = response.feedbacks
    .map((fb) => emptyFeedbackKeys.includes(fb.key))
    .reduce((current, next) => current && next, true);

  return {
    ...response,
    isEmptyInput: allFeedbackKeysAreEmptyInput,
  };
}
