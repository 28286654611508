import {
  type Importer,
  type MultipleChoiceMultiChoiceContent as ChoiceContent,
  type MultipleChoiceMultiContent,
} from '@bettermarks/gizmo-types';
import {
  getElementsPerLine,
  getSelectedItems,
  parseSemanticChildren,
} from '../multiple-choice/importer';

export const importMultipleChoiceMulti: Importer<MultipleChoiceMultiContent> = (
  preContent,
  xml,
  context
) => {
  const result: MultipleChoiceMultiContent = {
    ...preContent,
    choices: [],
    configuration: {
      buttons: true,
      elementsPerLine: Number.MAX_VALUE,
    },
    checkedIdx: [],
  };
  const mrow = xml.findChildTag('mrow');
  const config = mrow.findChildTag('configuration');
  const { options, placeholder, selection } = parseSemanticChildren(mrow);

  result.configuration.elementsPerLine = getElementsPerLine(config);
  result.configuration.buttons = !config.hasChild('hideButtons');
  result.configuration.placeholder = placeholder;

  const selected = getSelectedItems(
    selection,
    options.map(({ $id }) => $id)
  );
  // Make formula choices horizontally scrollable for small screens
  context.childrenAreRoot = true;
  const choices = options
    .map(({ xml }) => context.importXML(xml))
    .map((choice, i): ChoiceContent => {
      const item = selected.find((s) => s.index === i);
      return item && item.severity ? { $refid: choice.$refid, severity: item.severity } : choice;
    });
  const checkedIdx = selected.map((it) => it.index);

  return {
    ...result,
    choices,
    checkedIdx,
  };
};
