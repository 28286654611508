import { Stretch } from '../types';
import type { CSSProperties, FlattenInterpolation } from 'styled-components';

export const applyStyleIf =
  <T>(condition: boolean) =>
  (strings: TemplateStringsArray, ...values: Array<T>): string =>
    condition ? strings.reduce((result, string, i) => `${result}${string}${values[i]}`, '') : '';

export const handleHover = (
  content: string | FlattenInterpolation<CSSProperties>,
  component: string = ''
) => `
  cursor: pointer;
  @media (hover: hover) {
    &:hover ${component} {
      ${content}
    }
  }
`;

export const handleActive = (content: string, component: string = '') => `
  @media (hover: hover) {
    &:active ${component} {
      ${content}
    }
  }
`;

export const getSafeStyling =
  (propName: string) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value?: any): string =>
    value === undefined ? '' : `${propName}: ${value};`;

export const getStretchCssValue = (value: Stretch | number) => {
  switch (value) {
    case Stretch.auto:
      return 'auto';
    case Stretch.stretched:
      return '100%';
    default:
      return `${value}px`;
  }
};
