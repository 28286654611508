import { createAction, handleActions, type Reducer } from 'redux-actions';
import { includes, isNil } from 'lodash';
import { GEO_DEFAULT_PERSIST_PROPS } from '@bettermarks/importers';
import {
  type Coords,
  type GeoContentPersistProps,
  Hover,
  LabelAlignDirection,
  type LabelObject,
  GeoLabelType as LabelType,
  type LabelValuesMap,
  POINT,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { type GeoDeleteState } from './GeoDelete';
import {
  getHoveredForDeletionVerticesID,
  hoverIncidentEdges,
  perpendicularFoot,
  removeVertices,
} from './helpers';
import { persistLabel } from '../persist';
import { getLabelId } from '../../helpers';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */

export type DeletePayload = {
  /**
   * the id of the Vertex to delete
   */
  id: string;
  /**
   * the callback to propagate the local changes outward
   */
  onPersistLocalState: (props: GeoContentPersistProps) => void;
};

export type HoverPayload = {
  id: string;
  labelValues: LabelValuesMap;
};

export type OutPayload = HoverPayload | void;

export type ShowDeleteLabelPayload = {
  id: string;
  labelValues: LabelValuesMap;
  mouseP: Coords;
};

export type GeoDeletePayload =
  | DeletePayload
  | ShowDeleteLabelPayload
  | SnapPoint
  | HoverPayload
  | OutPayload
  | void;

export const DELETE_LABEL = 'DELETE_LABEL';

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const SNAP = 'SNAP' as const;
const STOP_SNAP = 'STOP_SNAP' as const;
const HOVER = 'HOVER' as const;
const OUT = 'OUT' as const;
const DELETE = 'DELETE' as const;
const SHOW_DELETE_LABEL = 'SHOW_DELETE_LABEL' as const;
const HIDE_DELETE_LABEL = 'HIDE_DELETE_LABEL' as const;

export const snapAction = createAction<SnapPoint>(SNAP);
export const stopSnapAction = createAction(STOP_SNAP);
export const hoverAction = createAction<HoverPayload>(HOVER);
export const outAction = createAction<OutPayload>(OUT);
export const deleteAction = createAction<DeletePayload>(DELETE);
export const showDeleteLabelAction = createAction<ShowDeleteLabelPayload>(SHOW_DELETE_LABEL);
export const hideDeleteLabelAction = createAction(HIDE_DELETE_LABEL);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState: GeoDeleteState = {
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  snapPoints: [],
  deleteObjectId: '',
};

const showDeleteLabelReducer: Reducer<GeoDeleteState, ShowDeleteLabelPayload> = (
  state,
  { payload }
) => {
  if (!payload) return state;
  const { id: vertexID, labelValues, mouseP } = payload;
  const labelId = getLabelId(vertexID);

  // clear the possible previous showDeleteLabel state
  const clearedState: GeoDeleteState =
    DELETE_LABEL in state.persistProps.geoContentMap
      ? {
          ...state,
          persistProps: removeVertices(state.persistProps, DELETE_LABEL),
          deleteObjectId: '',
        }
      : state;

  // the delete label shall be shown opposite to the existing label
  const labelAlignTop =
    labelId in clearedState.persistProps.geoContentMap &&
    (clearedState.persistProps.geoContentMap[labelId] as LabelObject).verticalAlign !==
      LabelAlignDirection.top;

  const hoveredState: GeoDeleteState = {
    ...clearedState,
    persistProps: {
      ...clearedState.persistProps,
      geoContentMap: hoverIncidentEdges(
        clearedState.persistProps.geoContentMap,
        vertexID,
        Hover.DELETE,
        labelValues
      ),
    },
  };

  const touchLabeledState: GeoDeleteState = {
    ...hoveredState,
    persistProps: {
      ...hoveredState.persistProps,
      geoContentMap: {
        ...hoveredState.persistProps.geoContentMap,
        ...persistLabel(
          DELETE_LABEL,
          vertexID,
          '',
          0,
          hoveredState,
          { labelType: LabelType.delete, labelAlignTop },
          includes(hoveredState.persistProps.points, vertexID)
            ? undefined
            : perpendicularFoot(mouseP, vertexID, hoveredState.persistProps.geoContentMap)
        ).persistProps.geoContentMap,
      },
      labels: [...hoveredState.persistProps.labels, DELETE_LABEL],
    },
    deleteObjectId: vertexID,
  };

  return touchLabeledState;
};

const hideDeleteLabelReducer: Reducer<GeoDeleteState, void> = (state) => ({
  ...state,
  persistProps: removeVertices(state.persistProps, DELETE_LABEL),
  deleteObjectId: '',
});

const deleteReducer: Reducer<GeoDeleteState, DeletePayload> = (state, { payload }) => {
  if (!payload) return state;

  const persistProps = removeVertices(state.persistProps, payload.id);

  payload.onPersistLocalState(persistProps);

  return { ...initialState, persistProps };
};

const outReducer: Reducer<GeoDeleteState, OutPayload> = (state, { payload }) => {
  if (!payload || !(payload.id in state.persistProps.geoContentMap)) {
    return {
      ...state,
      ...(!isNil(payload) && {
        persistProps: removeVertices(
          state.persistProps,
          ...getHoveredForDeletionVerticesID(state.persistProps.geoContentMap)
        ),
      }),
    };
  }

  const { id: vertexID, labelValues } = payload;

  return {
    ...state,
    persistProps: {
      ...state.persistProps,
      geoContentMap: hoverIncidentEdges(
        state.persistProps.geoContentMap,
        vertexID,
        undefined,
        labelValues
      ),
    },
    snapPoints: [],
  };
};

const hoverReducer: Reducer<GeoDeleteState, HoverPayload> = (state, { payload }) => {
  if (!payload || !(payload.id in state.persistProps.geoContentMap)) {
    return state;
  }

  const { id: vertexID, labelValues } = payload;

  return {
    ...state,
    persistProps: {
      ...state.persistProps,
      geoContentMap: hoverIncidentEdges(
        state.persistProps.geoContentMap,
        vertexID,
        Hover.DELETE,
        labelValues
      ),
    },
    snapPoints: state.persistProps.geoContentMap[vertexID].type === POINT ? state.snapPoints : [],
  };
};

const stopSnapReducer: Reducer<GeoDeleteState, void> = (state) => ({
  ...state,
  snapPoints: [],
});

const snapReducer: Reducer<GeoDeleteState, SnapPoint> = (state, { payload }) => {
  if (!payload) return state;
  return {
    ...state,
    snapPoints: payload.snapObject === POINT ? [payload] : [],
  };
};

export const geoDeleteReducer = handleActions<GeoDeleteState, GeoDeletePayload>(
  {
    [SNAP]: snapReducer,
    [STOP_SNAP]: stopSnapReducer,
    [HOVER]: hoverReducer,
    [OUT]: outReducer,
    [DELETE]: deleteReducer,
    [SHOW_DELETE_LABEL]: showDeleteLabelReducer,
    [HIDE_DELETE_LABEL]: hideDeleteLabelReducer,
  },
  initialState
);
