import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from '@seriesplayer/common-ui';

// This is needed to overwrite the grey background that is set in seriesPlayer.scss
// to remove unwanted grey boxes.

// Note: types are not correctly inferred, so we must type it as any.
//      For more info, see: https://github.com/styled-components/styled-components/issues/3738
// TODO: should be able to remove type `any` when moving to React 18
const WhiteBackground: any = createGlobalStyle`
body {
  background-color: ${defaultTheme.colors.cWhite};
}`;

export default WhiteBackground;
