import {
  type AdvancedAxisObject,
  type AngleConfiguration,
  annotationInner,
  type AutoLabeling,
  AxisDirection,
  type AxisObject,
  type BackgroundImages,
  type BezierGroupObject,
  type CircleConfiguration,
  type CircleObject,
  type ContentReference,
  type Coords,
  exportContent,
  type Exporter,
  type ExporterContext,
  type GeoConfiguration,
  type GeoContent,
  type GeoDecoration,
  type GeoDefaultDecorations,
  type GeoObject,
  type GeoObjectMap,
  type GridObject,
  type InterceptTheorem,
  type IntervalConfiguration,
  type IntervalObject,
  isAdvancedAxis,
  isAngleObject,
  isContentReference,
  isPointReadingHelpObject,
  type LabelDecoration,
  type LabelObject,
  type LabelValues,
  type LabelValuesMap,
  type LineObject,
  type ParallelsConfiguration,
  type PointObject,
  type PolygonAsBezier,
  type SelectionConfiguration,
  semantics,
  SUBTYPE_TO_TAG_MAP,
  type ToolConfiguration,
  type Translation,
} from '@bettermarks/gizmo-types';
import * as T from '@bettermarks/gizmo-types';
import { decorationAttribute, decorationToString } from '../../gizmo-utils/decoration';
import { exportImageContent } from '../image/exporter';
import { identity } from 'lodash/fp';
import { isEmpty, isNil, isString, isUndefined, kebabCase, keys, map, pickBy, round } from 'lodash';

function getDecorationKey(key: string): string {
  switch (key) {
    case 'lineCapStyleTop':
      return 'cap-style-top';
    case 'lineCapStyleBottom':
      return 'cap-style-bottom';
    default:
      return kebabCase(key);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const propsToAttributes = (props: any) =>
  keys(props).reduce((acc, k) => `${acc} ${k}="${props[k]}"`, '');

export const exportGeo: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as GeoContent;

  const key = content.key ? `key="${content.key}"` : '';

  const xmlString = `
    <g:system xmlns:g="http://bettermarks.com/mathcore/geometry" ${key}>
      ${exportConfiguration(content, context)}
      ${content.grid !== undefined ? exportGrid(content.grid) : ''}
      ${content.backgroundImages ? exportBackgroundImage(content.backgroundImages) : ''}
      ${
        content.horizontalAxis
          ? isAdvancedAxis(content.horizontalAxis)
            ? exportAdvancedAxis(content.horizontalAxis, context)
            : exportAxis(content.horizontalAxis)
          : ''
      }
      ${
        content.verticalAxis
          ? isAdvancedAxis(content.verticalAxis)
            ? exportAdvancedAxis(content.verticalAxis, context)
            : exportAxis(content.verticalAxis)
          : ''
      }
      ${content.diagonalAxis ? exportAdvancedAxis(content.diagonalAxis, context) : ''}
      ${exportPoints(content.invisiblePoints, content.geoContentMap, 'invisible-set')}
      ${exportPoints(content.points, content.geoContentMap, 'point-set')}
      ${exportLabels(content.labels, content.geoContentMap, context)}
      ${exportLines(content.rays, content.geoContentMap, 'ray')}
      ${exportReadingHelp(content.readinghelps, content.geoContentMap, 'readinghelp-set')}
      ${exportLines(content.segments, content.geoContentMap, 'segment')}
      ${exportLines(content.straightlines, content.geoContentMap, 'straightline')}
      ${exportLines(content.vectors, content.geoContentMap, 'vector')}
      ${exportBeziers(content.beziers, content.geoContentMap, 'bezier-set')}
      ${exportCircles(
        content.circles,
        content.geoContentMap,
        'circle-set',
        content.configuration.toolConfiguration.circleConfiguration
      )}
      ${exportIntervals(content.intervals, content.geoContentMap, 'interval-set')}
    </g:system>
  `;

  return semantics(xmlString, annotationInner(content.$, content));
};

function exportDefaultDecorations(decos: GeoDefaultDecorations): string[] {
  return map(keys(pickBy(decos, identity)), (key) => {
    const attrKey = key.substr(0, key.length - 1);
    return `<g:defaultdecoration type="${attrKey}">${decorationToString(
      decos[key],
      getDecorationKey
    )}</g:defaultdecoration>`;
  });
}

function exportAutoLabelings(config: GeoConfiguration): string {
  return keys(config.autoLabeling)
    .map((labelingType) => {
      const tagName = `auto${
        labelingType.substr(0, 1).toUpperCase() + labelingType.substr(1, labelingType.length - 2)
      }Labels`;
      return `<g:${tagName}>true</g:${tagName}>`;
    })
    .join('');
}

function exportLabelValues(
  labelValMap: LabelValuesMap,
  autoLabeling: AutoLabeling,
  context: ExporterContext
): string {
  return keys(labelValMap)
    .map((type) => [type, labelValMap[type]])
    .reduce((acc, [type, labelValueType]: [string, LabelValues]) => {
      const result = [
        `<g:label of='${type}' auto='${!isNil(autoLabeling[type])}'>`,
        labelValueType.pickerLabels
          .map(
            (label: ContentReference) =>
              `<g:value>${exportContent(label.$refid, context)}</g:value>`
          )
          .join(''),
        `</g:label>`,
      ].join('');

      return [acc, result].join('');
    }, '');
}
function exportAngleConfiguration(configuration: AngleConfiguration): string {
  const { snapAngle, type, lineType, showPermanent } = configuration;

  return [
    `<g:angle>`,
    `<g:snapAngle>${snapAngle}</g:snapAngle>`,
    `<g:type>${type}</g:type>`,
    `<g:lineType>${lineType}</g:lineType>`,
    `<g:showPermanent>${showPermanent}</g:showPermanent>`,
    `</g:angle>`,
  ].join('');
}

function exportParallelsConfiguration(configuration: ParallelsConfiguration): string {
  const { snapValue, snapPoints, hideUnit, unit } = configuration;
  return [
    `<g:parallels>`,
    ...(snapValue ? `<g:snapValue>${snapValue}</g:snapValue>` : ''),
    ...(snapPoints ? `<g:snapPoints/>` : ''),
    ...(hideUnit ? `<g:hideunit/>` : ''),
    ...(unit ? `<g:unit>${unit}</g:unit>` : ''),
    `</g:parallels>`,
  ].join('');
}

function exportSelectionConfiguration(configuration: SelectionConfiguration): string {
  const { validation, color } = configuration;
  return `
  <g:selection validation="${validation}">
  <g:color>${color}</g:color>
  </g:selection>`;
}

function exportIntervalConfiguration(configuration: IntervalConfiguration): string {
  const { type, showDialog, decoration, helperLine, additionalSteps, inputRestriction } =
    configuration;
  return [
    `<g:interval>`,
    `<g:type>${type}</g:type>`,
    `<g:showDialog>${showDialog}</g:showDialog>`,
    `<g:defaultDecoration>${Object.keys(decoration)
      .reduce((actual: string, next: string): string => {
        const newKey = next.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
        const newVal = decoration[next] ? decoration[next] : '';
        return `${actual}${newKey}: ${newVal};`;
      }, '')
      .slice(0, -1)}</g:defaultDecoration>`,
    `<g:helperLine decoration="${Object.keys(helperLine.decoration)
      .reduce((actual: string, next: string): string => {
        const helperLineDeco = helperLine.decoration;
        const newKey = next.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
        const newVal = helperLineDeco[next] ? helperLineDeco[next] : '';
        return `${actual}${newKey}:${newVal}; `;
      }, '')
      .slice(0, -1)}"
    visible="${helperLine.visible}"/>`,
    additionalSteps
      ? `<g:additionalSteps>${additionalSteps.toString()}</g:additionalSteps>`
      : `<g:additionalSteps/>`,
    inputRestriction ? `<g:inputRestriction>${inputRestriction}</g:inputRestriction>` : ``,
    `</g:interval>`,
  ].join('');
}

function exportToolsConfiguration(toolConfiguration: ToolConfiguration): string {
  return `
  <g:tools>
  ${
    toolConfiguration.angleConfiguration
      ? exportAngleConfiguration(toolConfiguration.angleConfiguration)
      : ''
  }
  ${
    toolConfiguration.parallelsConfiguration
      ? exportParallelsConfiguration(toolConfiguration.parallelsConfiguration)
      : ''
  }
  ${
    toolConfiguration.selectionConfiguration
      ? exportSelectionConfiguration(toolConfiguration.selectionConfiguration)
      : ''
  }
  ${
    toolConfiguration.intervalConfiguration
      ? exportIntervalConfiguration(toolConfiguration.intervalConfiguration)
      : ''
  }
  </g:tools>`;
}

function exportPolygonSelectionColor(geoConfiguration: GeoConfiguration): string {
  return [
    '<g:polygonSelectionColor>',
    geoConfiguration.polygonSelectionColor,
    '</g:polygonSelectionColor>',
  ].join('');
}

const exportInterceptTheorem = (it: InterceptTheorem) => {
  return `<g:interceptTheorem>
            <g:theorem>${it.theorem}</g:theorem>
            ${it.colors.map((c) => `<g:color>${c}</g:color>`).join('')}
          </g:interceptTheorem>`;
};

function exportConfiguration(
  { configuration, scalable }: Pick<GeoContent, 'configuration' | 'scalable'>,
  context: ExporterContext
): string {
  const ticks = configuration.tickValueInterval;
  const defaultDecos = configuration.defaultDecorations;
  // circleConfiguration is currently exported in circle-set export
  const { circleConfiguration, ...toolConfiguration } = configuration.toolConfiguration;
  const { display } = configuration;
  return [
    `<g:configuration>`,
    exportDefaultDecorations(defaultDecos).join(''),
    `<g:display width="${display.width}" height="${display.height}"
      x="${display.cx}" y="${display.cy}"/>`,
    `<g:tickValueInterval x="${ticks.x}" y="${ticks.y}"/>`,
    `<g:showBorder>${configuration.showBorder}</g:showBorder>`,
    `${configuration.showNullLabel ? '<g:showNullLabel/>' : ''}`,
    `${configuration.borderExtension ? '<g:addBorderExtension/>' : ''}`,
    `<g:snapType>${configuration.snapType}</g:snapType>`,
    `${!scalable ? '<g:noScale>true</g:noScale>' : ''}`,
    `<g:subSnapping x="${configuration.subSnapping.x}" y="${configuration.subSnapping.y}"/>`,
    exportAutoLabelings(configuration),
    exportLabelValues(configuration.labelValues, configuration.autoLabeling, context),
    !isEmpty(toolConfiguration) ? exportToolsConfiguration(toolConfiguration) : '',
    !isEmpty(configuration.polygonSelectionColor) ? exportPolygonSelectionColor(configuration) : '',
    !isNil(configuration.interceptTheorem)
      ? exportInterceptTheorem(configuration.interceptTheorem)
      : '',
    `</g:configuration>`,
  ].join('');
}

function exportAxis(axis: AxisObject): string {
  return `
    <g:axis id="${axis.id}"
        direction="${axis.direction}"
        ${axis.capStyle !== undefined ? `capStyle="${axis.capStyle}"` : ''}>
      <g:tickValueInterval>${axis.tickValueInterval}</g:tickValueInterval>
      <g:tickLabelInterval>${axis.tickLabelInterval}</g:tickLabelInterval>
      <g:label>${axis.label}</g:label>
    </g:axis>`;
}

function exportAdvancedAxisTickPosition(position: Coords, direction: AxisDirection): string {
  return direction === AxisDirection.diagonal
    ? `x="${position.x}" y="${position.y}"`
    : `pos="${direction === AxisDirection.horizontal ? position.x : position.y}"`;
}

function exportAdvancedAxisTickDecoration(decoration: GeoDecoration): string {
  return `decoration="${decorationToString(decoration, getDecorationKey)}"`;
}

function exportAdvancedAxis(axis: AdvancedAxisObject, context: ExporterContext): string {
  let posTag;
  const pos = axis.position;
  if (typeof pos === 'number') {
    posTag = `<g:position>${axis.position}</g:position>`;
  } else {
    posTag = `<g:position x="${pos.min.x}" y="${pos.min.y}" x1="${pos.max.x}" y1="${pos.max.y}"/>`;
  }

  return [
    `<g:advancedAxis
        id="${axis.id}"
        direction="${axis.direction}"
        ${axis.capStyle !== undefined ? `capStyle="${axis.capStyle}"` : ''}
        contractionEnabled="${axis.contractionEnabled.toString()}"
        ${axis.width ? `width="${axis.width}"` : ''}
        ${axis.height ? `height="${axis.height}"` : ''}
        ${axis.onlyPositive ? `onlyPositive="${axis.onlyPositive}"` : ''}>`,
    `<g:label>${
      isContentReference(axis.label)
        ? `<mrow>${exportContent(axis.label.$refid, context)}</mrow>`
        : axis.label
    }</g:label>`,
    `${posTag}`,
    axis.ticks
      .map(
        (t) => `<g:tick
        ${exportAdvancedAxisTickPosition(t.pos, axis.direction)}
        ${t.decoration ? exportAdvancedAxisTickDecoration(t.decoration) : ''}
        ${t.style ? `style="${t.style}"` : ''}>
        ${t.label !== undefined ? `<g:math>${exportContent(t.label.$refid, context)}</g:math>` : ''}
       </g:tick>`
      )
      .join(''),
    `</g:advancedAxis>`,
  ].join('');
}

function exportGrid(grid: GridObject): string {
  return [
    `<g:grid
      id="${grid.id}" ${grid.color ? `color="${grid.color}"` : ''}
      quadrantLabelsVisible="${grid.quadrantLabelsVisible}"
      width="${grid.width}" height="${grid.height}">`,
    grid.hLines ? `<g:hLines>${grid.hLines.map((t) => t.y1)}</g:hLines>` : '',
    grid.vLines ? `<g:vLines>${grid.vLines.map((t) => t.x1)}</g:vLines>` : '',
    `</g:grid>`,
  ].join('');
}

function exportBackgroundImage(bgContent: BackgroundImages): string {
  return [...bgContent.aboveGrid, ...bgContent.belowGrid]
    .reverse()
    .map(
      (bg) =>
        `<g:background aboveGrid="${bg.aboveGrid}">
        <g:position x="${bg.coords.x}" y="${bg.coords.y}"/>
        <g:math>${exportImageContent(bg.imageContent)}</g:math>
      </g:background>`
    )
    .join('');
}
function exportHint(hint?: string): string {
  return !isUndefined(hint) ? `hint="${hint}"` : '';
}

function exportCommonOptionals(geoObject: GeoObject): string {
  const decoration = geoObject.decoration as GeoDecoration;
  const exportedDeco = decorationAttribute(decoration, getDecorationKey, geoObject.severity);
  const exportedOriginalDeco = decorationToString(
    geoObject.originalDeco as GeoDecoration,
    getDecorationKey
  );
  const originalDeco = !isEmpty(exportedOriginalDeco)
    ? `originalDeco="${exportedOriginalDeco}"`
    : '';
  const unselectable =
    !geoObject.selectable && !geoObject.addedByUser
      ? `unselectable="${!geoObject.selectable}"`
      : '';
  const hint = exportHint(geoObject.hint);
  const interactionType = geoObject.interactionType
    ? `interaction-type="${geoObject.interactionType}"`
    : '';
  const continuingMark = geoObject.continuingMark
    ? `continuingMark="${geoObject.continuingMark}"`
    : '';
  const addedByUser = geoObject.addedByUser ? `addedByUser="true"` : '';

  return `${addedByUser} ${hint} ${unselectable} ${exportedDeco} ${interactionType} ${originalDeco}
  ${continuingMark}`;
}

function exportLabels(
  labels: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  context: ExporterContext
): string {
  if (!labels || labels.length === 0) {
    return '';
  }

  return [
    '<g:layer type="label-set">',
    labels.reduce(
      // tslint:disable-next-line cyclomatic-complexity
      (acc, labelId) => {
        const label = contentMap[labelId] as LabelObject;
        const labelDecoration = label.decoration as LabelDecoration;

        let labelStr = `<g:label id="${labelId}"`;

        if (label.styleType) {
          labelStr += ` styleType="${label.styleType}"`;
        }

        if (!isNil(label.distance)) {
          labelStr += ` distance="${label.distance}"`;
        }

        if (!isNil(label.shift)) {
          labelStr += ` shift="${label.shift}"`;
        }

        if (label.addedByUser) {
          labelStr += ` addedByUser="true"`;
        }

        if (!isEmpty(labelDecoration)) {
          const decoration = label.decoration;
          const decorationStr = Object.keys(labelDecoration)
            .reduce((actual: string, next: string): string => {
              const newKey = next.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
              const newVal = decoration?.[next] ? decoration[next] : '';
              return `${actual}${newKey}:${newVal};`;
            }, '')
            .slice(0, -1);

          labelStr += ` decoration="${decorationStr}"`;
        }

        if (label.align) {
          labelStr += ` align="${label.align}"`;
        }

        labelStr += '>';

        if (!isString(label.content) && T.isContentReference(label.content)) {
          const refIdStr = label.refid ? `refid="${label.refid}"` : '';
          const posStr = label.position ? `x="${label.position.x}" y="${label.position.y}"` : '';
          const expContent = exportContent(label.content.$refid, context);
          const content = `<g:math>${expContent}</g:math>`;

          return `
            ${acc}
            ${labelStr}
              <g:position ${refIdStr} ${posStr}/>
              ${content}
            </g:label>
          `;
        }
      },
      ''
    ),
    '</g:layer>',
  ].join('');
}

export function exportPoint(id: string, p: PointObject): string {
  const noSnap = p.noSnap ? 'noSnap="true"' : '';
  const x = p.addedByUser ? round(p.coords.x, 7) : p.coords.x;
  const y = p.addedByUser ? round(p.coords.y, 7) : p.coords.y;
  return `<g:point id="${id}" ${exportCommonOptionals(p)}
    x="${x}" y="${y}" ${noSnap}/>`;
}

function exportPoints(
  pointIds: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  layerType: 'point-set' | 'invisible-set'
): string {
  if (pointIds.length === 0) {
    return `<g:layer type="${layerType}"/>`;
  }

  return [
    `<g:layer type="${layerType}">`,
    map(pointIds, (id) => exportPoint(id, contentMap[id] as PointObject)).join(''),
    `</g:layer>`,
  ].join('');
}

function exportLines(
  lineIds: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  lineType: 'ray' | 'segment' | 'straightline' | 'vector'
): string {
  const layerType = `${lineType}-set`;

  if (lineIds.length === 0) {
    return `<g:layer type="${layerType}"/>`;
  }

  return [
    `<g:layer type="${layerType}">`,
    map(lineIds, (id) => {
      const line = contentMap[id] as LineObject;

      return `<g:${lineType} id="${id}" ${exportCommonOptionals(line)}>
          <g:position refid="${line.p1Id}"/>
          <g:position refid="${line.p2Id}"/>
        </g:${lineType}>`;
    }).join(''),
    `</g:layer>`,
  ].join('');
}

function exportInterval(id: string, i: IntervalObject) {
  const min = i.min;
  const max = i.max;
  const direction = i.direction as string;
  const type = i.intervalType;

  return `<g:interval id="${id}" direction="${direction}" min="${min}" max="${max}" type="${type}"
${exportCommonOptionals(i)}/>`;
}

function exportIntervals(
  intervalIds: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  layerType: string
) {
  if (intervalIds.length === 0) {
    return `<g:layer type="${layerType}"/>`;
  }

  return [
    `<g:layer type="${layerType}">`,
    map(intervalIds, (id) => exportInterval(id, contentMap[id] as IntervalObject)).join(''),
    `</g:layer>`,
  ].join('');
}

export function exportBeziers(
  ids: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  layerType: string
): string {
  if (ids.length === 0) {
    return `<g:layer type="${layerType}"/>`;
  }

  const { beziers, polygons } = ids.reduce(
    (acc, id) =>
      'pointIds' in contentMap[id]
        ? { ...acc, polygons: [...acc.polygons, id] }
        : { ...acc, beziers: [...acc.beziers, id] },
    { beziers: [], polygons: [] }
  );

  return [
    `<g:layer type="${layerType}">`,
    map(beziers, (id) => {
      const bezier = contentMap[id] as BezierGroupObject;
      const translation = bezier.translation as Translation;
      const exportedTrans = !isUndefined(translation)
        ? `<g:translation ${propsToAttributes(translation)}/>`
        : '';
      const moveGroup = bezier.moveGroup as string[];
      const exportedMG = !isUndefined(moveGroup)
        ? `<g:moveGroup>${moveGroup.join(';')}</g:moveGroup>`
        : '';
      const name = !isUndefined(bezier.name) ? `name="${bezier.name}"` : '';

      const tag = SUBTYPE_TO_TAG_MAP[bezier.subtype];

      return `<g:${tag} id="${id}" ${name} ${exportCommonOptionals(bezier)}>
          ${exportedTrans}
          ${bezier.beziers
            .map((b) => {
              const subDeco = decorationAttribute(b.decoration, getDecorationKey);
              return `<g:bezier ${subDeco}>
              ${b.points.map((p) => p.toString()).join(';')}
            </g:bezier>`;
            })
            .join('')}
          ${exportedMG}
        </g:${tag}>`;
    }).join(''),
    `</g:layer>`,
    `<g:layer type="polygon-set">`,
    map(polygons, (id) => {
      const polygon = contentMap[id] as PolygonAsBezier;

      return `<g:polygon id="${id}" ${exportCommonOptionals(polygon)}>
          <g:refIds>${polygon.pointIds.join(';')}</g:refIds>
        </g:polygon>
        ${
          polygon.hitAreaPointIds
            ? `<g:polygon id="${id}" ${exportCommonOptionals(polygon)}
          extendedHitAreaFor="${polygon.decoration ? polygon.decoration.interceptName : ''}">
            <g:refIds>${polygon.hitAreaPointIds.join(';')}</g:refIds>
          </g:polygon>`
            : ''
        }`;
    }).join(''),
    `</g:layer>`,
  ].join('');
}

function exportCircles(
  circleIds: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  layerType: 'circle-set',
  configuration: CircleConfiguration | undefined
): string {
  return [
    `<g:layer type="${layerType}">`,
    configuration
      ? `<g:configuration>
      <g:snapInterval>${configuration.snapInterval}</g:snapInterval>
      <g:unit>${configuration.unit}</g:unit>
      ${configuration.snapPoints ? `<g:snapPoints>${configuration.snapPoints}</g:snapPoints>` : ''}
      ${configuration.hideRadius ? `<g:hideradius>${configuration.hideRadius}</g:hideradius>` : ''}
      ${configuration.hideUnit ? `<g:hideunit>${configuration.hideUnit}</g:hideunit>` : ''}
    </g:configuration>`
      : '',
    map(circleIds, (id) => {
      const circle = contentMap[id] as CircleObject;

      return `<g:circle id="${id}"
        x="${circle.coords.x}"
        y="${circle.coords.y}"
        radius="${circle.radius}"
        ${exportCommonOptionals(circle)}/>
      `;
    }).join(''),
    `</g:layer>`,
  ].join('');
}

function exportReadingHelp(
  readingHelpIds: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>,
  layerType: 'readinghelp-set'
): string {
  if (readingHelpIds.length === 0) {
    return `<g:layer type="${layerType}"/>`;
  }

  return [
    `<g:layer type="${layerType}">`,
    map(readingHelpIds, (id) => {
      const readingHelp = contentMap[id];
      const decoration = readingHelp?.decoration;
      const exportedDeco = decorationAttribute(decoration, getDecorationKey);
      if (isPointReadingHelpObject(readingHelp)) {
        const axesPoints = readingHelp.axesPoints;
        const planePoint = readingHelp.planePoint;
        return `<g:readinghelp id="${id}" ${exportedDeco}>
            <g:position refId="${readingHelp.pointId}"/>
            ${
              axesPoints
                ? axesPoints
                    .map((ap) => `<g:position type="axis-point" x="${ap.x}" y="${ap.y}"/>`)
                    .join('')
                : ''
            }
            ${
              planePoint
                ? `<g:position type="plane-point" x="${planePoint.x}" y="${planePoint.y}"/>`
                : ''
            }
          </g:readinghelp>`;
      } else if (isAngleObject(readingHelp)) {
        const {
          coords: { x, y },
          endAngle,
          hasAngleLegs,
          radius,
          startAngle,
        } = readingHelp;
        const isRightAngle = readingHelp.isRightAngle
          ? `isRightAngle="${readingHelp.isRightAngle}"`
          : '';
        const labelOffset = !isNil(readingHelp.labelOffset)
          ? `labelOffset="${readingHelp.labelOffset}"`
          : '';
        return `<g:angle id="${id}" start="${startAngle}" end="${endAngle}" radius="${radius}" x="${x}"
          y="${y}" hasAngleLegs="${hasAngleLegs}" ${isRightAngle} ${labelOffset} ${exportedDeco}/>`;
      }
    }).join(''),
    `</g:layer>`,
  ].join('');
}
