import { type LayoutMetrics, type MCalibrate as MCalContent } from '@bettermarks/gizmo-types';
import { type EnrichMathContent } from '../../measure/measureStatic';
import { defaultTo } from 'lodash';
import * as React from 'react';
import styles from './tokens.scss';
import { getFontMetric } from '../../../../../utils/fontMetric';

export type MCalProps = LayoutMetrics;

/**
 * Creates a layout metric from `height` and `refLine` params.
 * Defaults to values from fontMetric created with `computedStyles` for undefined
 * `height` or `refLine`.
 *
 * @param {number} height
 * @param {number} refLine
 * @param {FormulaStyles} formulaStyles
 * @returns {LayoutMetrics}
 */

export const MCal: React.FC<MCalProps> = ({ height, refLine }) => {
  // as we are using border top to draw the ref line, we need to subtract its width for positioning
  const refLineBorderWidth = 1; // used in tokens.scss in class mcal-ref
  return (
    <span className={styles.mcal} style={{ height }}>
      <span className={styles.mcalRef} style={{ height: refLine - refLineBorderWidth }} />
    </span>
  );
};

MCal.displayName = 'MCal';

export const enrichMCal: EnrichMathContent<MCalContent> = (formulaStyles, content) => {
  const fontMetric = getFontMetric(formulaStyles.fontSize);
  const lm: LayoutMetrics = {
    height: defaultTo(content.height, fontMetric.height),
    refLine: defaultTo(content.refLine, fontMetric.refLine),
  };

  return {
    ...lm,
    enrichedContent: { ...content, ...lm },
  };
};
