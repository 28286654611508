import { omit } from 'lodash';
import { type GeoContentPersistProps } from '@bettermarks/gizmo-types';
import { getHoveredForDeletionVerticesID } from './getHoveredForDeletionVerticesID';

/**
 * Returns a version of the geo Graph where previously hovered vertices are now
 * un-hovered
 */
export const unHoverVertices = <GeoContentMap extends GeoContentPersistProps['geoContentMap']>(
  geoContentMap: GeoContentMap
): GeoContentMap => {
  const hoveredVertices = getHoveredForDeletionVerticesID(geoContentMap);
  const unHoveredGeoContentMap = hoveredVertices.reduce(
    (_geoContentMap, hoveredVertex) => ({
      ..._geoContentMap,
      // remove the hover attribute from the enumerable property of the given geo vertex
      [hoveredVertex]: omit(_geoContentMap[hoveredVertex], 'hover'),
    }),
    geoContentMap
  );

  return { ...unHoveredGeoContentMap };
};
