import { colors } from '@seriesplayer/common-ui';
import styled from 'styled-components';
import { TOOLBUTTON_SIZE } from './constants';

export interface ISelectedButton {
  selected?: boolean;
}

export const Button = styled.div<ISelectedButton>`
  min-width: ${TOOLBUTTON_SIZE}px;
  min-height: ${TOOLBUTTON_SIZE}px;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid ${({ selected }) => (selected ? colors.cGray300 : colors.cGray100)};
  cursor: pointer;
  line-height: 1.5;
  font-size: 12px;
  box-sizing: border-box;
  backdrop-filter: ${({ selected }) => (selected ? 'brightness(0.9)' : 'none')};

  &:hover {
    backdrop-filter: brightness(0.9);
    border-color: ${colors.cGray300};
  }
`;
