import { type ImporterRegistry } from '../../gizmo-utils/configuration';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type KEMContent, type EM } from '../../types';
import {
  type ContentDict,
  type ContentReference,
  createImporterContext,
  DependencyCollector,
  type FElement,
  xmlText,
} from '@bettermarks/gizmo-types';
import { createImportWrappedContent, importEMElement } from './importerEMElements';

/**
 * Imports a individual page of a KEM
 * @param xml
 * @param importWrappedContent
 */
export const importKEMPage = (
  xml: FElement,
  importWrappedContent: (xml: FElement) => ContentReference
): EM.Page => ({
  ...xml.tagsToProps(xmlText, ['id', 'title'], []),
  elements: xml
    .getChildrenByTagName('element')
    .map((element) => importEMElement(element, importWrappedContent)),
});

/**
 * This function parses a KEM XML and creates a `KEM` with the `ContentMap` added to it
 *
 * @param {XMLDocument} xml
 * @param importers
 * @param {string} pathToKem
 * @param dependencies any dependency collector that can be used
 *                     to collect from multiple import functions
 * @returns KEMContent
 */
export const importKEM = (
  xml: FElement,
  importers: ImporterRegistry,
  pathToKem = 'kem.data',
  dependencies = new DependencyCollector()
): ContentDict => {
  const id = xml.findChildTag('id').text;
  const context = createImporterContext(importers, pathToKem, dependencies, id).withNextId();
  const importWrappedContent = createImportWrappedContent(context);

  const teaser = importWrappedContent(xml.findChildTag('teaser'));
  const pages = xml
    .getChildrenByTagName('page')
    .map((page) => importKEMPage(page, importWrappedContent));

  const kem: KEMContent = {
    $: SPECIAL,
    $renderStyle: 'kem',
    id,
    pages,
    teaser,
  };

  context.content.set(context.root, kem);

  return context.contentDict;
};
