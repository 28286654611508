import { createAction } from 'redux-actions';
import { type Size, type Position } from '@bettermarks/gizmo-types';

export interface NodePayload {
  id: string;
}

export interface NodePositionPayload extends NodePayload {
  position: Position;
}
export interface LabelNodePayload extends NodePayload {
  index: number;
}

export interface NodeSizePayload extends NodePayload {
  size: Size;
}

export interface ConnectNodesPayload {
  sourceId: string;
  targetId: string;
}

export const SET_NODE_SIZE = 'SET_NODE_SIZE';
/**
 * `setNodeSize({nodeId, width, height}: NodeSizePayload)`: report the size of the node
 * This is used by gizmo nodes which do not know their size in advance.
 */
export const setNodeSize = createAction<NodeSizePayload>(SET_NODE_SIZE);

export const ADD_NODE = 'ADD_NODE';
/**
 * `addNode(node: GraphNode)`: Add a node to the graph.
 */
export const addNode = createAction<Position>(ADD_NODE);

export const MOVE_NODE = 'MOVE_NODE';
/**
 * `moveNode(node: GraphNode)`: Move a node
 */
export const moveNode = createAction<NodePositionPayload>(MOVE_NODE);

export const LABEL_NODE = 'LABEL_NODE';
/**
 * `labelNode(node: GraphNode)`: Label a node
 */
export const labelNode = createAction<LabelNodePayload>(LABEL_NODE);

export const COLOR_NODE = 'COLOR_NODE';
/**
 * `colorNode(string: nodeid)`: Color a node
 */
export const colorNode = createAction<string>(COLOR_NODE);

export const DELETE_NODE = 'DELETE_NODE';
/**
 * `deleteNode(node: GraphNode)`: Delete a node
 */
export const deleteNode = createAction<string>(DELETE_NODE);

export const DELETE_EDGE = 'DELETE_EDGE';
/**
 * `deleteEdge(edge: GraphEdge)`: Delete an edge
 */
export const deleteEdge = createAction<string>(DELETE_EDGE);

export const CONNECT_NODES = 'CONNECT_NODES';

/**
 * `connectNodes(source: GraphNode, target: GraphNode)`: Conect two nodes with an edge
 */
export const connectNodes = createAction<ConnectNodesPayload>(CONNECT_NODES);

export const NEED_GRAB = 'NEED_GRAB';
export const needGrab = createAction<boolean>(NEED_GRAB);

export type GraphActionPayload =
  | NodeSizePayload
  | NodePositionPayload
  | LabelNodePayload
  | string
  | boolean
  | number
  | undefined
  | Position
  | ConnectNodesPayload;
