import { MARGIN, SET_MIN_HEIGHT } from '../../../components/DragAndDrop';
import { defaultTo } from 'lodash';
import { type ApplyStyles, type Ruler } from '../../../gizmo-utils/configuration';

import { isResolved, type SetDropTargetContent } from '@bettermarks/gizmo-types';

export const applySetDropTargetStyles: ApplyStyles = (content: SetDropTargetContent, outerStyles) =>
  content.items.filter(isResolved).map(({ resolved }) => ({
    refId: resolved.$refid,
    style: outerStyles,
  }));

export const measureSetDropTarget: Ruler<SetDropTargetContent> = (_, content) => {
  const height = defaultTo(content.height, SET_MIN_HEIGHT) + 2 * MARGIN;

  return {
    height,
    refLine: 0.5 * height + MARGIN, // somehow adding the margin looks better
  };
};
