import {
  DEFAULT_TEXT_KEY,
  type FElement,
  type Importer,
  type MultipleChoiceDropDownContent,
} from '@bettermarks/gizmo-types';
import { getSelectedItem, parseSemanticChildren, severityDeco } from '../multiple-choice/importer';

export const importMultipleChoiceDropDown: Importer<MultipleChoiceDropDownContent> = (
  preContent,
  xml,
  context
) => {
  const result: MultipleChoiceDropDownContent = {
    ...preContent,
    choices: [],
    checkedIdx: -1,
    configuration: {
      dropDownDefaultText: '',
    },
  };
  const mrow = xml.findChildTag('mrow');
  const { options, placeholder, selection } = parseSemanticChildren(mrow);
  const config = mrow.findChildTag('configuration');

  result.configuration.dropDownDefaultText = getDropDownDefaultText(config);
  result.configuration.placeholder = placeholder;

  const choices = options.map(({ xml }) => context.importXML(xml));
  const selected = getSelectedItem(
    selection,
    options.map(({ $id }) => $id),
    severityDeco(selection.findChildTag('mrow'))
  );

  return selected.severity
    ? {
        ...result,
        choices,
        checkedIdx: selected.index,
        itemSeverity: selected,
      }
    : {
        ...result,
        choices,
        checkedIdx: selected.index,
      };
};

function getDropDownDefaultText(config: FElement): string {
  // selection.default key is hard coded in the content.
  return config.findChildTag('drop-down-default-text').attribute('key', DEFAULT_TEXT_KEY);
}
