import * as React from 'react';
import { type ContextState, PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import { type ItemListContent, ItemListType } from '@bettermarks/gizmo-types';

import styles from './ItemList.scss';

export type ItemListProps = ItemListContent & ContextState;

export const ItemList: React.FC<ItemListProps> = ({ availableWidth, items, type }) => (
  <table className={styles.itemList}>
    <tbody>
      {items.map((item, i) => (
        <tr key={`row_${i}`}>
          <td className={styles.firstColumn} key={`bullet_${i}`}>
            {item.bullet ? (
              <PolymorphicGizmo refid={item.bullet.$refid} availableWidth={availableWidth} />
            ) : type === ItemListType.NUMERIC ? (
              `${i + 1}.`
            ) : (
              `\u2022`
            )}
          </td>
          <td key={`content_${i}`}>
            <PolymorphicGizmo refid={item.content.$refid} availableWidth={availableWidth} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

ItemList.displayName = 'ItemList';
