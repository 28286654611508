import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { Error, type ErrorDispatchProps } from './Error';
import { closeDialog, quitSeriesPlayer } from '../../SeriesPlayer/actions';

const mapDispatchToProps = (dispatch: Dispatch): ErrorDispatchProps => ({
  onCancel: () => dispatch(closeDialog()),
  onQuit: () => dispatch(quitSeriesPlayer()),
});

export const ErrorContainer = connect(null, mapDispatchToProps)(Error);
