import * as React from 'react';
import { isNil } from 'lodash';

import { type Severity } from '@bettermarks/umc-kotlin';
import { getNamedColor } from '@bettermarks/importers';
import {
  DEFAULT_BAR_GAP,
  DEFAULT_BAR_WIDTH,
  DEFAULT_READINGHELP_STROKE_WIDTH,
  DEFAULT_READINGHELP_COLOR,
  DEFAULT_READINGHELP_DASHED_ARRAY,
  DEFAULT_TICK_WIDTH,
} from '../../defaults';

export type ReadingHelpProps = {
  x: number;
  y: number;
  idx: number;
  severity?: Severity;
};

export const readingHelpCoords = (
  idx: number,
  yValue: number,
  xOffset: number,
  height: number,
  yTickValueStart: number,
  yTickValueInterval: number
) => ({
  x: idx * DEFAULT_BAR_WIDTH + idx * DEFAULT_BAR_GAP + xOffset,
  y: height - ((yValue - yTickValueStart) / yTickValueInterval) * DEFAULT_TICK_WIDTH - 1,
});

export const ReadingHelp: React.FC<ReadingHelpProps> = ({ x, y, idx, severity }) => {
  return (
    <line
      key={`readingHelp_${idx}`}
      stroke={getNamedColor(!isNil(severity) ? severity : DEFAULT_READINGHELP_COLOR, true)}
      strokeDasharray={DEFAULT_READINGHELP_DASHED_ARRAY}
      strokeWidth={DEFAULT_READINGHELP_STROKE_WIDTH}
      x1={0.5}
      y1={y}
      x2={x}
      y2={y}
    />
  );
};

ReadingHelp.displayName = 'ReadingHelp';
