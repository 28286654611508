import { type GeoContentPersistProps, Hover } from '@bettermarks/gizmo-types';

/**
 * Returns an immutable list of all the geo vertices id that have an attribute
 * of `hover` set to `Hover.DELETE`
 */
export const getHoveredForDeletionVerticesID = <
  GeoContentMap extends GeoContentPersistProps['geoContentMap'],
  VertexID extends Extract<keyof GeoContentMap, string>
>(
  geoContentMap: GeoContentMap
): ReadonlyArray<VertexID> =>
  (Object.keys(geoContentMap) as VertexID[]).filter(
    (vertexID) => geoContentMap[vertexID].hover === Hover.DELETE
  );
