export type CRIPlayerCommonPropsUnparsed = Readonly<{
  id: string;
  locale: string;
  variant: string;
  staticServerUrl: string;
  staticMediaUrl: string;
  test?: string;
}>;

export const enum CRIStatus {
  loaded = 'loaded',
  error = 'error',
}

export interface FetchCRIParams {
  locale: string;
  criId: string;
  /**
   * This is used for setting an alternative content server (mostly for local generation).
   * When it is undefined, it defaults to the content server.
   */
  staticServerUrl: string;
  test?: boolean;
}
