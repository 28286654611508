import { solutionSetSaga } from '../../gizmos/solution-set/SolutionSet/solutionSetSaga';
import { InteractionTypes as IT } from '@bettermarks/gizmo-types';
import { RS } from '@bettermarks/gizmo-types';
import { type GizmoSagaRegistry } from '../redux/gizmoSaga';
import { formulaSaga } from '../../gizmos/formula/Formula/formulaSaga';

/**
 * Side effect code for each gizmo. For example if you want to focus the next
 * or previous dom element or launch the missiles, do it one of these.
 */
export const sagas: GizmoSagaRegistry = {
  [`${RS.FORMULA} ${IT.FORMULA}`]: formulaSaga,
  [`${RS.INNER_TEXT} ${IT.TEXT}`]: formulaSaga,
  [`${RS.TEXT} ${IT.TEXT}`]: formulaSaga,
  [`${RS.SOLUTION_SET} ${IT.SOLUTION_SET}`]: solutionSetSaga,
};
