import { COLOR_GRAY, GIZMO_HEIGHT } from '../components/Gizmo/index';
import { type Defaults, type HelloGizmoContent, PLACEHOLDER, RS } from '@bettermarks/gizmo-types';

export const HELLO_GIZMO_DEFAULT_CONTENT: Defaults<HelloGizmoContent> = {
  $: PLACEHOLDER,
  $renderStyle: RS.HELLO_GIZMO,
  name: '',
  color: COLOR_GRAY,
  size: GIZMO_HEIGHT,
};
