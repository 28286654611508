import { createAction } from 'redux-actions';

import { type FT as T } from '@bettermarks/gizmo-types';

export type PathActionPayload = {
  path: T.Path;
  mode: T.PathMode;
};

export type Size = {
  width: number;
  height: number;
};

export const SET_CURSOR = 'SET_CURSOR' as const;
export const CURSOR_LEFT = 'CURSOR_LEFT' as const;
export const CURSOR_RIGHT = 'CURSOR_RIGHT' as const;

export const BACKWARD_DELETE = 'BACKWARD_DELETE' as const;
export const FORWARD_DELETE = 'FORWARD_DELETE' as const;
export const ENTER_NUMBER = 'ENTER_NUMBER' as const;
export const ENTER_IDENTIFIER = 'ENTER_IDENTIFIER' as const;
export const ENTER_INVALID_IDENTIFIER = 'ENTER_INVALID_IDENTIFIER' as const;
export const ENTER_CHAR = 'ENTER_CHAR' as const;
export const ENTER_OPERATOR = 'ENTER_OPERATOR' as const;
export const ENTER_BRACKET = 'ENTER_BRACKET' as const;
export const ENTER_FRAC = 'ENTER_FRAC' as const;
export const ENTER_SUB = 'ENTER_SUB' as const;
export const ENTER_SUP = 'ENTER_SUP' as const;
export const ENTER_SQR = 'ENTER_SQR' as const;
export const ENTER_SUBSUP = 'ENTER_SUBSUP' as const;
export const ENTER_ROOT = 'ENTER_ROOT' as const;
export const ENTER_SQRT = 'ENTER_SQRT' as const;
export const ENTER_ABS = 'ENTER_ABS' as const;
export const ENTER_MROW = 'ENTER_MROW' as const;
export const ENTER_EQUALS = 'ENTER_EQUALS' as const;
export const ERROR = 'ERROR' as const;
export const SET_SIZE = 'SET_SIZE' as const;
export const TOGGLE_KEYBOARD_PAGE = 'TOGGLE_KEYBOARD_PAGE' as const;

export const setCursor = createAction<PathActionPayload>(SET_CURSOR);
export const cursorLeft = createAction(CURSOR_LEFT);
export const cursorRight = createAction(CURSOR_RIGHT);

export const backwardDelete = createAction(BACKWARD_DELETE);
export const forwardDelete = createAction(FORWARD_DELETE);
export const enterNumber = createAction<string>(ENTER_NUMBER);
export const enterIdentifier = createAction<string>(ENTER_IDENTIFIER);
export const enterInvalidIdentifier = createAction<string>(ENTER_INVALID_IDENTIFIER);
export const enterChar = createAction<string>(ENTER_CHAR);
export const enterOperator = createAction<string>(ENTER_OPERATOR);
export const enterBracket = createAction<string>(ENTER_BRACKET);
export const enterFrac = createAction(ENTER_FRAC);
export const enterSub = createAction(ENTER_SUB);
export const enterSup = createAction(ENTER_SUP);
export const enterSqr = createAction(ENTER_SQR);
export const enterSubSup = createAction(ENTER_SUBSUP);
export const enterRoot = createAction(ENTER_ROOT);
export const enterSqrt = createAction(ENTER_SQRT);
export const enterAbs = createAction(ENTER_ABS);
export const enterMRow = createAction<T.MathContent[]>(ENTER_MROW);
export const enterEquals = createAction(ENTER_EQUALS);
export const error = createAction<string>(ERROR);

export const toggleKeyboardPage = createAction<number>(TOGGLE_KEYBOARD_PAGE);

export type FormulaActionPayload =
  | PathActionPayload
  | Size
  | T.MathContent[]
  | string
  | void
  | number;
