import * as React from 'react';

export const RESET_MESSAGE = 'reset on whiteboard';

const handleReset = (onReset: () => void) => (event: MessageEvent) => {
  if (event.data === RESET_MESSAGE) {
    onReset && onReset();
  }
};

export const useHandleReset = (onReset?: () => void) => {
  React.useEffect(() => {
    if (onReset) {
      window.addEventListener('message', handleReset(onReset));
      return window.removeEventListener('message', handleReset(onReset));
    }
  }, [onReset]);
};
