import colors from '../../sass/colors';

export const inactive = {
  borderColor: colors.cGray600,
  color: colors.cGray700,
  stroke: colors.cGray700,
};

export const neutral = {
  borderColor: colors.cBlue600,
  color: colors.cBlue700,
  stroke: colors.cBlue700,
};

export const positive = {
  borderColor: colors.cGreen600,
  color: colors.cGreen700,
  stroke: colors.cGreen700,
};

export const negative = {
  borderColor: colors.cRed600,
  color: colors.cRed700,
  stroke: colors.cRed700,
};

export const supportive = {
  borderColor: colors.cYellow600,
  color: colors.cYellow700,
  stroke: colors.cYellow700,
};
