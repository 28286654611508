import { type Action, createAction, handleActions } from 'redux-actions';
import { first } from 'lodash';
import {
  type Coords,
  type GeoAddPointState,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type IdCoords,
  type LabelObject,
  GeoLabelType,
  POINT,
  type SnapObject,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { GEO_DEFAULT_PERSIST_PROPS, getPointCoordsId } from '@bettermarks/importers';
import { persistPoint } from '../persist';
import { addAutoLabel } from '../helpers';
import { DEFAULT_ADD_LABEL_STATE } from '../constants';
import { confirmLabel } from '../addlabel/helpers';

export interface SnapPayload {
  snapPoint: SnapPoint | null;
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface AddPayload {
  point: Coords;
  snapObject: SnapObject;
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface ConfirmLabelPayload {
  index: number;
  labelAlignTop: boolean;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface InitialLabelOverPayload {
  onInitialLabelOver: boolean;
}

export interface InitialLabelClickPayload {
  id: string;
  configuration: GeoConfiguration;
}

export interface CloseLabelPayload {
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export type GeoAddPointPayload =
  | SnapPayload
  | CloseLabelPayload
  | AddPayload
  | ConfirmLabelPayload
  | InitialLabelOverPayload
  | InitialLabelClickPayload
  | void;

const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPayload>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);
const ADD_POINT: 'ADD_POINT' = 'ADD_POINT';
export const addPointAction = createAction<AddPayload>(ADD_POINT);
const CONFIRM_LABEL: 'CONFIRM_LABEL' = 'CONFIRM_LABEL';
export const confirmLabelAction = createAction<ConfirmLabelPayload>(CONFIRM_LABEL);
const OVER_INITIAL_LABEL: 'OVER_INITIAL_LABEL' = 'OVER_INITIAL_LABEL';
export const overInitialLabelAction = createAction<InitialLabelOverPayload>(OVER_INITIAL_LABEL);
const CLICK_INITIAL_LABEL: 'CLICK_INITIAL_LABEL' = 'CLICK_INITIAL_LABEL';
export const clickInitialLabelAction = createAction<InitialLabelClickPayload>(CLICK_INITIAL_LABEL);
const CLOSE_LABEL: 'CLOSE_LABEL' = 'CLOSE_LABEL';
export const closeLabelAction = createAction<CloseLabelPayload>(CLOSE_LABEL);

export const initialState: GeoAddPointState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  prevPoints: [],
  labels: DEFAULT_ADD_LABEL_STATE,
};

export const geoAddPointReducer = handleActions<GeoAddPointState, GeoAddPointPayload>(
  {
    [SNAP]: (state: GeoAddPointState, { payload }: Action<SnapPayload>) => {
      if (!payload || !payload.snapPoint) {
        return state;
      }

      const { snapPoint } = payload;

      return {
        ...state,
        snapPoints: state.labels.onInitialLabelOver ? [] : [snapPoint],
      };
    },

    [STOP_SNAP]: (state: GeoAddPointState) => ({
      ...state,
      snapPoints: [],
      labels: { ...state.labels, initial: false },
    }),

    [ADD_POINT]: (state: GeoAddPointState, { payload }: Action<AddPayload>) => {
      // do not create a new point on top of an already existing one
      if (!payload || payload.snapObject === POINT || state.labels.active) {
        return {
          ...state,
          labels: {
            ...state.labels,
            initial: false,
          },
        };
      }

      const { point, configuration, onPersistLocalState } = payload;
      const newPoint: IdCoords = { id: getPointCoordsId(point), coords: point };

      // create new point and add it to the persistProps of the local state
      let returnState = persistPoint<GeoAddPointState>(
        newPoint,
        state,
        configuration.defaultDecorations.points
      );

      // handle auto labeling
      if (returnState.labels && POINT in configuration.autoLabeling) {
        returnState = {
          ...addAutoLabel<GeoAddPointState>(newPoint, returnState, configuration),
          labels: {
            ...returnState.labels,
            labelingObjectId: newPoint.id,
            labelingObjectType: POINT,
            labelList: configuration.labelValues[POINT].geoLabels,
            pickerList: configuration.labelValues[POINT].pickerLabels,
            active: true,
            initial: true,
          },
        };
      } else {
        // call the global reducer to add the new point in the global state
        onPersistLocalState(returnState.persistProps);
      }

      return {
        ...initialState,
        ...returnState,
      };
    },

    [CONFIRM_LABEL]: (state: GeoAddPointState, { payload }: Action<ConfirmLabelPayload>) => {
      if (!payload) {
        return state;
      }

      const { persistProps, snapPoints, ...labelState } = confirmLabel(
        {
          ...state.labels,
          persistProps: state.persistProps,
          snapPoints: [...state.snapPoints],
        },
        payload
      );

      return {
        ...state,
        labels: labelState,
        persistProps,
        snapPoints,
        prevPoints: [],
      };
    },

    [OVER_INITIAL_LABEL]: (
      state: GeoAddPointState,
      { payload }: Action<InitialLabelOverPayload>
    ) => {
      if (!payload) {
        return state;
      }

      const { onInitialLabelOver } = payload;

      return {
        ...state,
        labels: {
          ...state.labels,
          onInitialLabelOver,
        },
        snapPoints: onInitialLabelOver ? [] : state.snapPoints,
      };
    },

    [CLICK_INITIAL_LABEL]: (
      state: GeoAddPointState,
      { payload }: Action<InitialLabelClickPayload>
    ) => {
      if (!payload) {
        return state;
      }

      const { configuration, id } = payload;

      const label = state.persistProps.geoContentMap[id] as LabelObject;
      if (label.labelType !== GeoLabelType.initial) {
        return state;
      }

      const labelingObjectId = first(label.referringTo) as string;
      const labelingObjectType = state.persistProps.geoContentMap[labelingObjectId].type;

      return {
        ...state,
        labels: {
          ...state.labels,
          labelingObjectId,
          labelList: configuration.labelValues[labelingObjectType].geoLabels,
          pickerList: configuration.labelValues[labelingObjectType].pickerLabels,
          active: true,
        },
      };
    },
    [CLOSE_LABEL]: (state: GeoAddPointState, { payload }: Action<CloseLabelPayload>) => {
      if (!payload) {
        return state;
      }

      const { onPersistLocalState } = payload;

      // call the global reducer to add the new point in the global state
      onPersistLocalState(state.persistProps);

      return {
        ...initialState,
        persistProps: state.persistProps,
      };
    },
  },
  initialState
);
