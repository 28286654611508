import { type Sound } from './types';
import { Howl } from 'howler';
import { SERIES_PLAYER_SOUNDS } from './constants';

type SoundCollection = { [sound in Sound]: Howl };

type Play = (sound: Sound) => void;
type Prepare = (sound: Sound) => void;
type UseAudio = [Play, Prepare];

let sounds: SoundCollection = Object.keys(SERIES_PLAYER_SOUNDS).reduce<SoundCollection>(
  (result, sound: Sound) => ({
    ...result,
    [sound]: new Howl({
      src: SERIES_PLAYER_SOUNDS[sound].map((a) => a.src),
      html5: false,
      preload: true,
    }),
  }),
  {} as any
);

export const setSounds = (newSounds: Partial<SoundCollection>) => {
  sounds = { ...sounds, ...newSounds };
};

/**
 * This function is needed for Android, because in Android, audio can only be loaded within
 * the callback of a user interaction.
 *
 * https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
 */
export const prepare: Prepare = (sound: Sound) => sounds[sound].load();

export const play: Play = (sound: Sound) => sounds[sound].play();

export const useAudio = (): UseAudio => {
  return [play, prepare];
};
