import { clone, get, setWith } from 'lodash';
import { MROW } from '../../constructors';
import {
  isCursor,
  isMRowLike,
  isMToken,
  isPureMathContent,
  mapMathChildrenToArray,
  type MathContent,
  type Path,
} from '@bettermarks/gizmo-types';

/**
 * An immutable set! (lodash/fp is not typed, and normal lodash set is mutable)
 */
export const set = <V, T extends object>(input: T, path: Path, value: V): T =>
  setWith<T>(clone(input), path, value, clone);

export const lastPathElements = (path: Path, num: number) => [
  ...path.slice(path.length - num),
  ...path.slice(0, path.length - num),
];

/**
 * Search for the cursor in the given math content and return its Path.
 * The second parameter is used when the function calls
 * itself recursively to pass the current path down the tree.
 * @param {MathContent} content the math content to search
 * @param {Path} currentPath the current path in the recursion
 * @returns {Path} the path where we can find the cursor (or an empty path)
 */
export const cursorPath = (content: MathContent, currentPath: Path = []): Path => {
  if (isCursor(content)) {
    return currentPath;
  } else if (!isPureMathContent(content) || isMToken(content)) {
    return [];
  }
  const cp = isMRowLike(content) ? [...currentPath, 'children'] : currentPath;
  const paths: Path[] = mapMathChildrenToArray(content, (child: MathContent, key: string) =>
    cursorPath(child, [...cp, key])
  );
  return paths.find((p) => p.length > 0) || [];
};

/**
 * Checks if the current MathContent have cursor or not.
 * @param {MathContent[]} content
 * @return {boolean}
 */
export const hasCursor = (content: MathContent[]): boolean =>
  cursorPath(MROW(...content)).length > 0;

type ContainerInfo<T> = {
  container: T;
  pos: number; // container position inside it's parent mrow
  parent: MathContent[];
  parentPath: Path;
};

export const getContainerInfo = <T = MathContent>(
  input: MathContent,
  containerPath: Path
): ContainerInfo<T> => {
  // this assumes that the last two elements in the path refer to an mrow, and
  // thus container path always returns a container child.
  // e.g.: [children, 2, denominator, children, 0] -> [children, 2, denominator]
  const container: T = get(input, containerPath);
  const [containerPos, ...parentPath] = lastPathElements(containerPath, 1);
  const parent: MathContent[] = get(input, parentPath);

  return { container, pos: containerPos as number, parent, parentPath };
};
