import * as React from 'react';
import classNames from 'classnames';
import {
  DragDropContext,
  Draggable,
  type DraggableId,
  Droppable,
  type DropResult,
} from 'react-beautiful-dnd';
import { Severity } from '@bettermarks/umc-kotlin';

import styles from './DragAndDrop.scss';
import { GrabberHorizontal, GrabberVertical } from '../icons';
import { SortingDirection } from '@bettermarks/gizmo-types';

export type SortingProps = {
  direction: SortingDirection;
  severities: (Severity | undefined)[];
  minText: string;
  maxText: string;
  isTouch?: boolean;
  onDragEnd?: (result: DropResult) => void;
};

type SortingContainerProps = {
  minText: string;
  maxText: string;
};

const noop = () => {
  // nothing
};

const LeftArrow: React.FC<React.SVGProps<SVGSVGElement>> = ({ style }) => (
  <svg width="16" height="18" style={style}>
    <path d="M7 10 l9 -4.5 v9 Z" />
  </svg>
);
const RightArrow: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <LeftArrow style={{ transform: 'scaleX(-1)' }} />
);

const HorizontalContainer: React.FC<SortingContainerProps> = ({ minText, maxText, children }) => (
  <div className={styles.sortingHorizontal}>
    <div className={styles.sortingHorizontalHeader}>
      <div className={styles.sortingLabels}>{minText}</div>
      <LeftArrow />
      <div className={styles.sortingArrowLine} />
      <RightArrow />
      <div className={styles.sortingLabels}>{maxText}</div>
    </div>
    {children}
  </div>
);

const VerticalContainer: React.FC<SortingContainerProps> = ({ minText, maxText, children }) => (
  <div className={styles.sortingVertical}>
    <div className={styles.sortingLabels}>{maxText}</div>
    {children}
    <div className={styles.sortingLabels}>{minText}</div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/ban-types
const handlerMap: { [key: string]: React.FC<React.SVGAttributes<{}>> } = {
  [SortingDirection.horizontal]: GrabberHorizontal,
  [SortingDirection.vertical]: GrabberVertical,
};

const containerMap: { [key: string]: React.FC<SortingContainerProps> } = {
  [SortingDirection.horizontal]: HorizontalContainer,
  [SortingDirection.vertical]: VerticalContainer,
};

const wrap =
  (
    severities: (Severity | undefined)[],
    disabled: boolean,
    direction: SortingDirection,
    isTouch?: boolean,
    draggingWith?: DraggableId
  ) =>
  (child: React.ReactChild, index: number) =>
    (
      <Draggable
        key={index}
        draggableId={index.toString()}
        index={index}
        // make sure that items are disabled
        // - if gizmo is disabled
        // - until the the item that is currently dragged is dropped completely
        isDragDisabled={disabled || (!!draggingWith && draggingWith !== index.toString())}
      >
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={classNames(styles.dragSource, styles.rectangle, styles.itemType100, {
              [styles.error]: severities[index] === Severity.error,
              [styles.remark]: severities[index] === Severity.remark,
              [styles.dragItem]: snapshot.isDragging && isTouch,
            })}
          >
            <span className={classNames(styles.content, isTouch && styles[direction])}>
              {isTouch ? (
                <>
                  <span className={classNames(styles.sortingContent, styles[direction])}>
                    {child}
                  </span>
                  {React.createElement(handlerMap[direction], {
                    className: classNames(styles.grabber, styles[direction], {
                      [styles.error]: severities[index] === Severity.error,
                      [styles.remark]: severities[index] === Severity.remark,
                    }),
                  })}
                </>
              ) : (
                child
              )}
            </span>
          </div>
        )}
      </Draggable>
    );

export const Sorting: React.FC<SortingProps> = ({
  children,
  direction,
  minText,
  maxText,
  severities,
  isTouch,
  onDragEnd,
}) => {
  const Container = containerMap[direction];

  return (
    <Container minText={minText} maxText={maxText}>
      <DragDropContext onDragEnd={onDragEnd || noop}>
        <Droppable droppableId="droppable" direction={direction}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.sortingItems}
            >
              {React.Children.map(
                children,
                wrap(severities, !onDragEnd, direction, isTouch, snapshot.draggingOverWith)
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

Sorting.displayName = 'Sorting';
