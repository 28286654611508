import * as React from 'react';
import { HELLO_GIZMO, type HelloGizmoContent } from '@bettermarks/gizmo-types';
import { type ContextState, useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';
import { HELLO_GIZMO_DEFAULT_CONTENT } from '@bettermarks/importers';
import { Gizmo } from '../../components/Gizmo';

export interface HelloGizmoCallbacks {
  readonly onGizmoClicked?: () => void;
  readonly onMeasured?: () => void;
}

export type HelloGizmoProps = HelloGizmoContent & HelloGizmoCallbacks & ContextState;

export type HelloGizmoInternalProps = HelloGizmoProps;

/**
 * Connects the [[components/Gizmo]] UIComponent to [[../HelloGizmoContent]].
 */
// disabling class-name rule here,
// Need to set the rule `class-name: false` in tslint configuration globally if required
export const HelloGizmo: React.FC<HelloGizmoInternalProps> = (props) => {
  const [hovered, updateHover] = React.useState(false);
  const t = useContentTranslation();
  const { disabled, $interactionType, onGizmoClicked } = {
    ...HELLO_GIZMO_DEFAULT_CONTENT,
    ...props,
  };
  const { color, name, size } = props;
  const text = t ? t('helloGizmo.hello', [name]) : name;

  let onClick, onHover, onHoverStop;
  if (!disabled && $interactionType === HELLO_GIZMO && onGizmoClicked) {
    onClick = onGizmoClicked;
    onHover = () => updateHover(true);
    onHoverStop = () => updateHover(false);
  }

  return (
    <Gizmo
      color={color}
      size={size}
      hovered={hovered}
      onClick={onClick}
      onHover={onHover}
      onHoverStop={onHoverStop}
      text={text}
    />
  );
};
