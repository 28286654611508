import * as React from 'react';
import { defaultTo } from 'lodash';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  CursorPositionOffset,
  GeoEditorMode,
  type GeoScene,
  type LineObject,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import { GeoRenderer } from '../../GeoRenderer';
import { mousePos, screenToWorld } from '@bettermarks/importers';
import { persistProps } from '../persist';
import {
  addParallelAction,
  dragAction,
  geoParallelsReducer,
  hoverAction,
  initialState,
  outAction,
  startDragAction,
  stopDragAction,
} from './geoParallelsReducer';
import { type GeoProps } from '../../Geo';
import { Maybe } from '../../../../utils/maybe';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export const GeoParallels: React.FC<GeoProps & DragScrollableProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoParallelsReducer}
    componentName={`Parallels:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { uniqueId, matrix, configuration, onPersistLocalState, scale } = props;
      const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

      const onTouchStart = () => maybeScrollBehaviour.ap(startScrolling);

      const drag = (scene: GeoScene) => (evt: MouseOrTouch) => {
        if (state.dragging) {
          const mouseP = screenToWorld(matrix)(
            mousePos(defaultTo<string>(uniqueId, ''), CursorPositionOffset.NONE)(evt)
          );
          dispatch(dragAction({ configuration, mouseP, scene, matrix, scale }));
        }
      };

      const stopDrag = () => dispatch(stopDragAction());

      const down = (id: string) => (evt: MouseOrTouch) => dispatch(startDragAction({ id }));

      const up = () => {
        if (state.dragging) {
          dispatch(addParallelAction({ configuration, onPersistLocalState }));
        }
        maybeScrollBehaviour.ap(stopScrolling);
      };

      const hover = (id: string) => (evt: MouseOrTouch) => {
        if (!state.dragging) {
          dispatch(hoverAction({ id }));
        }
      };

      const out = (id: string) => (evt: MouseOrTouch) => {
        const origLine = props.geoContentMap[id] as LineObject;
        dispatch(outAction({ id, origLine }));
      };

      const geoContentProps = { ...props, ...state.persistProps };

      return (
        <GeoRenderer
          mode={GeoEditorMode.PARALLELS}
          snapPoints={state.snapPoints}
          previewObjects={{
            lines: [
              state.prevLines.perpendicular,
              state.prevLines.parallel,
              ...state.prevLines.others,
            ],
            points: [],
          }}
          toolValueLabels={state.toolValueLabels}
          onTouchStart={onTouchStart}
          onMouseMove={drag}
          onMouseUp={up}
          onMouseLeave={stopDrag}
          onLineHover={hover}
          onLineOut={out}
          onLineDown={down}
          highlight={state.highlight}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoParallels.displayName = 'GeoParallels';
