import { initJsStore } from './store';
import { ReduxStateFlowService } from './service';

export const reduxStateFlowService: {
  instance: ReduxStateFlowService | undefined;
} = {
  instance: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
initJsStore().then(() => {
  reduxStateFlowService.instance = new ReduxStateFlowService();
});
