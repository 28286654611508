import { isNil } from 'lodash';
import { GeoEditorMode, type GeoObject } from '@bettermarks/gizmo-types';

export const isSelectableObject = (obj: GeoObject, mode: GeoEditorMode): boolean =>
  (mode === GeoEditorMode.DELETE && obj.addedByUser) ||
  (mode === GeoEditorMode.ADD_LABEL && isNil(obj.notLabelable)) ||
  (mode.includes(GeoEditorMode.SELECT) && (obj.selectable || obj.addedByUser)) ||
  (mode.includes(GeoEditorMode.COLORING) && !!obj.interactionType) ||
  mode === GeoEditorMode.PERPENDICULAR ||
  mode === GeoEditorMode.PARALLELS;
