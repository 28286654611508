import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import { setParam } from './actions';
import {
  DynamicRepresentation,
  type DynamicRepresentationCallbacks,
} from './DynamicRepresentation';

const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): DynamicRepresentationCallbacks => ({
  onPersistDynamicValue: (id) => (value) => dispatch(gizmoAction(setParam({ id, value }), refid)),
});

export const DynamicRepresentationContainer = connectGizmo(
  mapDispatchToProps,
  DynamicRepresentation
);
