import { enrichSingleFormula } from '../../../formula/measure';
import * as React from 'react';
import Measure from 'react-measure';
import { defaultTo } from 'lodash';
import {
  type ContentReference,
  DEFAULT_MATH_CONTENT,
  HAlignment,
  type LabelContent,
  type PureMathContent,
  VAlignment,
} from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';
import { Formula } from '../../../formula/Formula';

import styles from './GeoSVGForeignObject.scss';

export type GeoSVGForeignObjectProps = {
  content: DeepImmutableObject<LabelContent>;
  x: number;
  y: number;
  dx?: number;
  dy?: number;
  hAlign: HAlignment | undefined;
  vAlign: VAlignment | undefined;
  width: number;
  height: number;
  className?: string;
  fontSize?: number;
  onClick?: (evt: React.MouseEvent<any>) => void;
};

export type GeoSVGForeignObjectState = {
  width: number;
  height: number;
};

export const isReferencedContent = (content: DeepImmutableObject<LabelContent>): boolean =>
  content.hasOwnProperty('$refid');

export const isMathContent = (content: DeepImmutableObject<LabelContent>): boolean =>
  content.hasOwnProperty('$');

export class GeoSVGForeignObject extends React.Component<
  GeoSVGForeignObjectProps,
  GeoSVGForeignObjectState
> {
  state = {
    width: this.props.width,
    height: this.props.height,
  };

  onResize = (clientRect: any) =>
    this.setState({
      width: clientRect.offset.width,
      height: clientRect.offset.height,
    });

  render() {
    const { content, x, y, dx, dy, hAlign, vAlign, width, height, className, fontSize, onClick } =
      this.props;

    // eslint-disable-next-line @typescript-eslint/ban-types
    const childDivProps: React.HTMLAttributes<{}> = {
      className: styles.childDiv,
      style: {
        textAlign: defaultTo(hAlign, HAlignment.start),
        verticalAlign: defaultTo(vAlign, VAlignment.hanging),
        width,
        height,
      },
    };

    const posX =
      x +
      defaultTo<number>(dx, 0) -
      (hAlign === HAlignment.middle ? width * 0.5 : hAlign === HAlignment.end ? width : 0);
    const posY =
      y +
      defaultTo<number>(dy, 0) -
      (vAlign === VAlignment.middle ? height * 0.5 : vAlign === VAlignment.bottom ? height : 0);

    return (
      <>
        {/*using transform instead of x/y because of FF issues (see ticket #42610)*/}
        <foreignObject
          className={styles.dearFirefox}
          transform={`translate(${posX},${posY})`}
          width={width}
          height={height}
        >
          <Measure bounds={true} offset={true} onResize={this.onResize}>
            {({ measureRef }) => (
              <div className={styles.parentDiv}>
                <div {...childDivProps}>
                  <div ref={measureRef}>
                    {isReferencedContent(content) ? (
                      <PolymorphicGizmo
                        refid={(content as ContentReference).$refid}
                        availableWidth={width}
                        keepLineHeight
                      />
                    ) : isMathContent(content) ? (
                      <Formula
                        {...enrichSingleFormula({
                          ...DEFAULT_MATH_CONTENT,
                          content: [content as PureMathContent],
                        })}
                        availableWidth={width}
                      />
                    ) : (
                      <span className={className} style={{ fontSize }}>
                        {content}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Measure>
        </foreignObject>
        {onClick && (
          <circle
            className={styles.active}
            cx={
              x +
              0.5 *
                (defaultTo<number>(dx, 0) +
                  this.state.height *
                    (hAlign === HAlignment.start ? 1 : hAlign === HAlignment.end ? -1 : 0))
            }
            cy={
              y +
              0.5 *
                (defaultTo<number>(dy, 0) +
                  this.state.height *
                    (vAlign === VAlignment.hanging ? 1 : vAlign === VAlignment.bottom ? -1 : 0))
            }
            r={Math.max(this.state.height * 0.5, styles.MIN_CLICK_AREA_RADIUS)}
            onClick={onClick}
          />
        )}
      </>
    );
  }
}
