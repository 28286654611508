import { flatten } from 'lodash';
import { type ApplyStyles } from '../../gizmo-utils/configuration';
import { type ItemListContent } from '@bettermarks/gizmo-types';

export const applyItemListStyles: ApplyStyles = (content: ItemListContent, outerStyles) =>
  flatten(
    content.items.map((i) => [
      { refId: i.content.$refid, style: outerStyles },
      ...(i.bullet ? [{ refId: i.bullet.$refid, style: outerStyles }] : []),
    ])
  );
