import { compose, curry, isNil, omit } from 'lodash/fp';
import {
  type Content,
  type ContentDict,
  type DragSourceContent,
  type DropTargetContent,
  RS,
  SET_DROP_TARGET,
  type SetDropTargetContent,
  type ContentReference,
  type FreeDragItemTranslate,
  type FreeDragAndDropTranslations,
  type IFreeDragAndDrop,
  type TranslateCoords,
} from '@bettermarks/gizmo-types';
import { resolveDragSource } from '@bettermarks/importers';
import { findFreeDragItemParent, isFromTarget } from './utils';
import { FREE_DROPPED_ITEM_COPY_SUFFIX } from './constants';
import { MARGIN } from '../../components';
import { findKey } from 'lodash';

type DragAndDropFunction = (
  source: string,
  target: string,
  index: number | undefined,
  c: ContentDict
) => ContentDict;

type DropFunction = (
  source: string,
  target: string,
  freeDragItemTranslate: FreeDragItemTranslate,
  index: number | undefined,
  c: ContentDict
) => ContentDict;

type FreeDragAndDrop2Function = (
  freeDragItem: ContentReference,
  pos: TranslateCoords,
  c: ContentDict
) => ContentDict;

export const isTarget = (content: Content) => isDropTarget(content) || isSetDropTarget(content);

const isDropTarget = (content: Content): content is DropTargetContent =>
  content.$renderStyle === RS.DROP_TARGET;

const isSetDropTarget = (content: Content): content is SetDropTargetContent =>
  content.$renderStyle === SET_DROP_TARGET;

const isDragSource = (content: Content): content is DragSourceContent =>
  content.$renderStyle === RS.DRAG_SOURCE;

const isFreeDragAndDrop = (content: Content): content is IFreeDragAndDrop =>
  content.$renderStyle === RS.FREE_DRAG_AND_DROP;

const incrInstances = (c: DragSourceContent): DragSourceContent =>
  c.instances !== -1 ? { ...c, instances: c.instances + 1 } : c;

const decrInstances = (c: DragSourceContent): DragSourceContent =>
  c.instances !== -1 ? { ...c, instances: Math.max(c.instances - 1, 0) } : c;

const addItemToTarget = curry(
  (
    dragSourceId: string,
    c: DropTargetContent | SetDropTargetContent
  ): DropTargetContent | SetDropTargetContent =>
    omit(['severity'], {
      ...c,
      items: isSetDropTarget(c)
        ? [
            ...c.items,
            {
              dragSourceId,
            },
          ]
        : [{ dragSourceId }],
    })
);

const addFreeDroppedItem = curry(
  (
    freeDnDGizmoContent: IFreeDragAndDrop,
    freeDragAndDropCopyId: string,
    newPos: TranslateCoords,
    inTarget
  ) => {
    return omit(['severity'], {
      ...freeDnDGizmoContent,
      droppedItems: [...freeDnDGizmoContent.droppedItems, { $refid: freeDragAndDropCopyId }],
      translations: {
        [freeDragAndDropCopyId]: newPos,
      },
      inTarget,
    });
  }
);

const removeItemFromTarget = curry(
  (
    index: number,
    c: DropTargetContent | SetDropTargetContent
  ): DropTargetContent | SetDropTargetContent =>
    omit(['severity'], {
      ...c,
      items: [...c.items.slice(0, index), ...c.items.slice(index + 1)],
    })
);

const dragFromDropTarget =
  (source: DropTargetContent): DragAndDropFunction =>
  (sourceID, targetID, index = 0, content) => {
    if (source?.items.length <= index) return content;
    const target = content[targetID];
    const itemId = source.items[index].dragSourceId;
    if (target && (isDropTarget(target) || isSetDropTarget(target))) {
      if (target.items.length === 1 && !isSetDropTarget(target)) {
        // - drop target -> filled drop target
        return {
          ...content,
          [sourceID]: compose(
            addItemToTarget(target.items[0].dragSourceId),
            removeItemFromTarget(index)
          )(source),
          [targetID]: compose(
            addItemToTarget(itemId || source.items[0].dragSourceId),
            removeItemFromTarget(0)
          )(target),
        };
      } else {
        // - drop target -> empty drop target or any set target
        const removed = {
          ...content,
          [sourceID]: removeItemFromTarget(index, source),
        };
        return {
          ...removed,
          [targetID]: addItemToTarget(
            itemId || source.items[0].dragSourceId,
            removed[targetID] as DropTargetContent
          ),
        };
      }
    }
    return content;
  };

const dragFromDragSource =
  (source: DragSourceContent): DragAndDropFunction =>
  (sourceID, targetID, _, content) => {
    if (isNil(source.$id)) return content;
    const target = content[targetID] as DropTargetContent;
    const result = {
      ...content,
      [sourceID]: decrInstances(source),
      [targetID]: addItemToTarget(source.$id, target),
    };
    if (isDropTarget(target) && target.items.length === 1) {
      // - drag source -> filled drop target
      const source2 = resolveDragSource(content, target);
      if (!source2) return content;
      return {
        ...result,
        [source2.$refid]: incrInstances(source2.dragSource),
      };
    }
    return result;
  };

const adaptFreeDragAndDropTranslations = (
  content: ContentDict,
  inTarget: boolean,
  isTargetToTarget?: boolean,
  targetID?: string,
  freeDragItemTranslate?: FreeDragItemTranslate
): FreeDragAndDropTranslations => {
  let translations: FreeDragAndDropTranslations = {};
  if (inTarget && targetID) {
    const targetContent = content[targetID];
    if (!isNil(targetContent) && isDropTarget(targetContent)) {
      const otherItemInDropTarget = targetContent.items;
      translations = otherItemInDropTarget.reduce((acc, { dragSourceId }) => {
        let tr = {};
        const dragSourceTargetContentRefId = findKey(content, { $id: dragSourceId });
        if (!isNil(dragSourceTargetContentRefId)) {
          if (!isTargetToTarget) {
            tr = { [dragSourceTargetContentRefId]: { translateX: 0, translateY: 0 } };
          } else {
            if (freeDragItemTranslate && freeDragItemTranslate.sourcePos) {
              tr = { [dragSourceTargetContentRefId]: freeDragItemTranslate.sourcePos };
            }
          }
        }
        return { ...acc, ...tr };
      }, translations);
    }
  }
  return translations;
};

const setFreeDragAndDropTranslations = (
  content: ContentDict,
  freeDragItemTranslate: FreeDragItemTranslate,
  inTarget: boolean,
  isTargetToTarget?: boolean,
  targetID?: string
) => {
  let newContent = content;
  const { refid: freeDragItemRefId, newPos } = freeDragItemTranslate;
  const pair = findFreeDragItemParent(content, freeDragItemRefId);
  if (pair) {
    const [freeDnDGizmoRef, freeDnDGizmoContent] = pair;
    if (!isNil(freeDnDGizmoContent) && isFreeDragAndDrop(freeDnDGizmoContent)) {
      const toBeReset = adaptFreeDragAndDropTranslations(
        content,
        inTarget,
        isTargetToTarget,
        targetID,
        freeDragItemTranslate
      );
      const translationsUpdated: IFreeDragAndDrop = {
        ...freeDnDGizmoContent,
        translations: {
          ...toBeReset,
          [freeDragItemRefId]: {
            translateX: newPos.translateX,
            translateY: newPos.translateY,
          },
        },
        inTarget,
      };
      newContent = {
        ...content,
        [freeDnDGizmoRef]: translationsUpdated,
      };
    }
  }
  return newContent;
};

const drop_: DropFunction = (sourceID, targetID, freeDragItemTranslate, index, content) => {
  const source = content[sourceID];
  if (isNil(source)) return content;
  const isTargetToTarget = isDropTarget(source) || isSetDropTarget(source);
  const contentWithTranslations = setFreeDragAndDropTranslations(
    content,
    freeDragItemTranslate,
    true,
    isTargetToTarget,
    targetID
  );
  if (isTargetToTarget) {
    return dragFromDropTarget(source)(sourceID, targetID, index, contentWithTranslations);
  } else if (isDragSource(source)) {
    return dragFromDragSource(source)(sourceID, targetID, undefined, contentWithTranslations);
  }
  return contentWithTranslations;
};

const nextfreeDnDCopyId = (content: ContentDict, sourceID: string) => {
  const prefix = `${sourceID}${FREE_DROPPED_ITEM_COPY_SUFFIX}`;
  const copies = Object.keys(content).filter((refid) => refid.startsWith(prefix));
  return `${prefix}${copies.length + 1}`;
};

const freeDrop_: FreeDragAndDrop2Function = (
  freeDragAndDropItem: ContentReference,
  newPos: TranslateCoords,
  content: ContentDict
) => {
  let newContent = content;
  const sourceID = freeDragAndDropItem.$refid;
  const source = content[sourceID];

  const pair = findFreeDragItemParent(content, freeDragAndDropItem.$refid);
  const inTarget = isFromTarget(content, freeDragAndDropItem.$refid);
  if (pair) {
    const [freeDnDGizmoRef, freeDnDGizmoContent] = pair;
    if (
      !isNil(source) &&
      !inTarget &&
      isDragSource(source) &&
      !isNil(freeDnDGizmoContent) &&
      isFreeDragAndDrop(freeDnDGizmoContent)
    ) {
      if (source.instances > 1) {
        const singleDragItemCopyContent = {
          ...source,
          instances: 1,
        };

        const firstItem = content[freeDnDGizmoContent.items[0].$refid];

        const firstItemPos =
          !isNil(firstItem) && isDragSource(firstItem)
            ? {
                x: firstItem?.pos?.translateX || 0,
                y: firstItem?.pos?.translateY || 0,
              }
            : { x: 0, y: 0 };

        const translate = {
          translateX:
            newPos.translateX + (source.pos?.translateX || 0) - (source.width || 0) / 2 - MARGIN,
          translateY: newPos.translateY + ((source.pos?.translateY || 0) - firstItemPos.y),
        };

        const singleDragItemCopyRefId = nextfreeDnDCopyId(content, sourceID);
        newContent = {
          ...content,
          [sourceID]: decrInstances(source),
          [freeDnDGizmoRef]: addFreeDroppedItem(
            freeDnDGizmoContent,
            singleDragItemCopyRefId,
            translate,
            inTarget
          ),
          [singleDragItemCopyRefId]: singleDragItemCopyContent,
        };
      } else {
        const translationsUpdated: IFreeDragAndDrop = {
          ...freeDnDGizmoContent,
          translations: {
            [sourceID]: newPos,
          },
          inTarget,
        };
        newContent = {
          ...content,
          [freeDnDGizmoRef]: translationsUpdated,
        };
      }
    }
  }

  return newContent;
};

export const freeDrop = curry(freeDrop_);

// somehow type inferences doesn't work here, so let's be verbose :)
export const drop = curry(drop_);

export const remove = (
  dropTargetID: string,
  index: number,
  content: ContentDict,
  freeDragItemTranslate?: FreeDragItemTranslate
) => {
  const dropTarget = content[dropTargetID] as DropTargetContent;
  if (isNil(dropTarget)) return content;
  const source = resolveDragSource(content, dropTarget, index);
  if (isNil(source)) return content;
  let newContent = content;
  if (freeDragItemTranslate)
    newContent = setFreeDragAndDropTranslations(content, freeDragItemTranslate, false);
  return {
    ...newContent,
    [dropTargetID]: {
      ...dropTarget,
      items: [...dropTarget.items.slice(0, index), ...dropTarget.items.slice(index + 1)],
    },
    [source.$refid]: incrInstances(source.dragSource),
  };
};
