import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export default function useSetDocumentLanguage() {
  const [, i18n] = useTranslation();

  const setLang = (lang: string) => {
    document.documentElement.lang = lang;
  };

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);
}
