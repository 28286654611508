import * as React from 'react';
import classNames from 'classnames';
import { defaultTo, isEqual, throttle } from 'lodash';
import Measure, { type ContentRect } from 'react-measure';

import { numberFromStyles } from '@bettermarks/gizmo-types';
import { STATE_ERROR, STATE_SELECTED, STATE_UNSELECTED } from './constants';
import { DropdownButton as DefaultDropdownButton } from './DropdownButton';
import { DropdownOptionList as DefaultDropdownOptionList } from './DropdownOptionList';

import styles from './Dropdown.scss';

const borderWidth = numberFromStyles(styles.borderWidth);
const minMargin = numberFromStyles(styles.minMargin);
const iconWidth = numberFromStyles(styles.iconWidth);

const getDocumentHeight = () => document.documentElement.clientHeight;

const INITIAL_RECT = {
  width: 0,
  height: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const getSelectedContent = (index: number, children?: React.ReactNode[]) => {
  if (index > -1 && index < React.Children.count(children)) {
    const mapped = React.Children.map(
      children,
      (child: JSX.Element | null | undefined) => child?.props.children
    );
    return mapped ? mapped[index] : undefined;
  }
};

const hasErrorAt = (index: number, children?: React.ReactNode[]): boolean => {
  if (index === -1) {
    return false;
  } else {
    return (
      React.Children.toArray(children).findIndex(
        (child: JSX.Element) => child.props.state === STATE_ERROR
      ) === index
    );
  }
};

const getDropdownState = (
  index: number,
  noSelectedStyles?: boolean,
  children?: React.ReactNode[]
) => {
  if (hasErrorAt(index, children)) {
    return STATE_ERROR;
  }
  return index === -1 || noSelectedStyles ? STATE_UNSELECTED : STATE_SELECTED;
};

export type DropdownProps = {
  interactive: boolean;
  open: boolean;
  defaultText?: string;
  overlayId?: string; // to create an overlay element in styleguide
  children?: React.ReactNode[];
  lineBreak?: boolean;
  selectedIndex?: number;
  noSelectedStyles?: boolean;
  stretch?: boolean;
  hasError?: boolean;
  onItemSelected?: (index: number, value: React.ReactNode) => void;
  onFocus?: (e: React.FocusEvent<any>) => void;
  dropdownButton?: typeof React.Component | React.FunctionComponent;
  dropdownOptionList?: typeof React.Component | React.FunctionComponent;
  selectedContent?: JSX.Element;
  getAvailableHeight?: () => number;
  dataCy?: string;
  maxWidth?: string;
};

export type DropdownLocalState = {
  open: boolean;
  clickTarget?: EventTarget;
  selectedIndex: number;
  selectedContent?: JSX.Element;
  buttonState?: any;
  listDimensions: ContentRect;
  childDimensions: {
    [key: string]: ContentRect;
  };
  widestOption: number;
  tallestOption: number;
  position: { top: number; left: number };
  scrollPosition: number;
  defaultWidth: number;
};

export class Dropdown extends React.Component<DropdownProps, DropdownLocalState> {
  _isMounted = false;
  domNode: HTMLDivElement;

  state: DropdownLocalState = {
    open: this.props.open,
    selectedIndex: -1,
    position: { top: 0, left: 0 },
    scrollPosition: 0,
    listDimensions: {
      bounds: INITIAL_RECT,
      offset: INITIAL_RECT,
      scroll: INITIAL_RECT,
    },
    childDimensions: {},
    defaultWidth: -1,
    widestOption: 0,
    tallestOption: 0,
  };

  static getDerivedStateFromProps(currProps: DropdownProps, state: DropdownLocalState) {
    const selectedIndex = defaultTo<number>(currProps.selectedIndex, -1);
    return {
      selectedIndex,
      selectedContent:
        currProps.selectedContent || getSelectedContent(selectedIndex, currProps.children),
      buttonState: getDropdownState(selectedIndex, currProps.noSelectedStyles, currProps.children),
    };
  }

  componentDidMount() {
    // TODO: we probably won't need this event listener management. Use the global one
    this._isMounted = true;
    if (this._isMounted) {
      window.addEventListener('resize', this.setPosition);
      document.addEventListener('scroll', this.setPosition, true);
      document.addEventListener('click', this.close);
      document.addEventListener('touchend', this.close);
      document.addEventListener('touchmove', this.onTouchMove);
    }
  }

  componentWillUnmount() {
    // TODO: we probably won't need this event listener management. Use the global one
    this._isMounted = false;
    window.removeEventListener('resize', this.setPosition);
    document.removeEventListener('scroll', this.setPosition);
    document.removeEventListener('click', this.close);
    document.removeEventListener('touchend', this.close);
    document.removeEventListener('touchmove', this.onTouchMove);
  }

  // Did the props change (e.g. default text)? Did we get new or different
  // dropdown options? Then everything has to be recalculated. To prevent
  // endless loops, we have to carefully check if we got a change in the
  // particular property, so this function is only called once after a prop
  // update.
  //
  componentDidUpdate(prevProps: DropdownProps, prevState: DropdownLocalState) {
    const result = this.getMenuPosition(prevState);
    if (!isEqual(result.position, prevState.position)) {
      this.setState({ position: result.position });
    } else if (result.scrollPosition !== prevState.scrollPosition) {
      this.setState({ scrollPosition: result.scrollPosition });
    } else if (prevProps.selectedIndex !== this.props.selectedIndex) {
      const selectedIndex = defaultTo<number>(this.props.selectedIndex, -1);
      this.setState((prevState, currProps) => ({
        selectedIndex,
        selectedContent: getSelectedContent(selectedIndex, currProps.children),
      }));
    }
  }

  setPosition = () => {
    this.setState((prevState) => this.getMenuPosition(prevState));
  };

  close: EventListener = (e: Event) => {
    if (e.target !== null && this.state.open && e.target !== this.state.clickTarget) {
      const overlay = document.getElementById('overlay') as HTMLElement;
      const overlayClicked = overlay.contains(e.target as Node);
      if (!overlayClicked) {
        this.setState({ open: false });
      }
    }
  };

  onTouchMove = throttle(this.close, 100);

  getMenuPosition({
    tallestOption,
    listDimensions: { bounds, scroll },
    selectedIndex,
    childDimensions,
  }: DropdownLocalState) {
    const { getAvailableHeight = getDocumentHeight } = this.props;
    const { left, top } = this.domNode.getBoundingClientRect();
    const buttonTop = Math.max(0, top);

    // only applied in gizmo viewer and styleguide (= 0 in seriesplayer)
    const scrollTop = defaultTo(
      window.pageYOffset,
      defaultTo(document.documentElement.scrollTop, document.body.scrollTop)
    );

    const buttonPos = buttonTop + scrollTop;
    // listHeight: the actual height of the list in it's full glory
    const result = {
      position: { top: buttonPos, left },
      scrollPosition: 0,
    };
    if (bounds && scroll) {
      const index = selectedIndex > -1 ? selectedIndex : 0;
      const childKeys = Object.keys(childDimensions);

      if (childKeys.length > 0) {
        const buttonHeight = tallestOption;
        const selectedChild = childDimensions[index];
        const childHeight = selectedChild && selectedChild.offset ? selectedChild.offset.height : 0;
        // accumulate the heights of children up to the selected one.
        const childPos =
          -childKeys
            .map((i) => {
              const { offset } = childDimensions[i];
              return offset ? offset.height : 0;
            })
            .reduce((acc, val, i) => (i < index ? acc + val : acc), 0) +
          (buttonHeight - childHeight) / 2;

        /**
         * Corrections to avoid overflowing the viewport:
         *
         * DOWN:
         *       +-------------+
         *       | option list | <- down
         * +------------------------+
         * |     |             |    |
         * |     | button pos  |    |
         * |     |             |    | <- viewport
         * |     +-------------+    |
         * |                        |
         * |                        |
         * +------------------------+
         */
        const down = Math.max(Math.abs(childPos) - buttonTop, 0);
        /**
         * UP:
         *
         * +------------------------+
         * |                        |
         * |     +-------------+    |
         * |     | option list |    |
         * |     |             |    | <- viewport (height = getAvailableHeight())
         * |     |             |    |
         * |     | button pos  |    |
         * |     |             |    |
         * +------------------------+
         *       |             | <- up
         *       +-------------+
         */
        const up = Math.min(
          getAvailableHeight() - (bounds.height + minMargin + childPos + buttonTop),
          0
        );

        if (scroll.height <= bounds.height) {
          // no scrolling
          // clamp (we don't want to leave the container again)
          result.position.top = buttonPos + childPos + down + up;
          document.addEventListener('scroll', this.setPosition);
        } else if (scroll.height > bounds.height) {
          // scrolling
          document.removeEventListener('scroll', this.setPosition);
          result.position.top = buttonPos + childPos + down + up;
          result.scrollPosition = -childPos + buttonTop + minMargin; // subtract borders
        }
      }
    }

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  onDropdownClicked: React.MouseEventHandler<{}> = (e) => {
    if (this.props.interactive) {
      this.setState({ open: true, clickTarget: e.target });
    }
  };

  onItemClicked = (itemIndex: number) => {
    // only call the callback if the selection is new (i.e., not identical to the previous one)
    if (this.props.onItemSelected && itemIndex !== this.state.selectedIndex) {
      this.props.onItemSelected(itemIndex, getSelectedContent(itemIndex, this.props.children));
    }

    this.setState((prevState, currProps) => ({
      open: false,
      selectedIndex: itemIndex,
      selectedContent: getSelectedContent(itemIndex, currProps.children),
      buttonState: getDropdownState(itemIndex, currProps.noSelectedStyles, currProps.children),
    }));
  };

  onRef = (node: HTMLDivElement) => {
    this.domNode = node;
  };

  onResizeListItem = (index: number, dimensions: ContentRect) => {
    // the very first width rendered will be 100% of the space, so we won't consider it in the
    // widest child calculation.
    // this is not always true. Dropdown component alone is rendered twice, Multiple choice dropdown
    // is rendered only once.
    const newWidth = dimensions && dimensions.bounds ? dimensions.bounds.width : 0;
    const newHeight = dimensions && dimensions.bounds ? dimensions.bounds.height : 0;

    this.setState((prevState) => {
      const { childDimensions, tallestOption, widestOption } = prevState;
      return {
        childDimensions: { ...childDimensions, [index]: dimensions },
        widestOption: Math.max(
          // if my previous widestOption was defined via this child, if it was re-rendered, this is
          // (probably, but there are corner cases not covered) my new widest child
          this.isWidestOptionSame(childDimensions[index], widestOption) ? newWidth : widestOption,
          newWidth
        ),
        tallestOption: Math.max(tallestOption, newHeight),
      };
    });
  };

  isWidestOptionSame(childDimension: ContentRect, widestOption: number) {
    if (childDimension && childDimension.bounds) {
      const { bounds } = childDimension;
      return bounds.width === widestOption;
    } else {
      return false;
    }
  }

  onResizeList = (clientRect: ContentRect) => {
    this.setState({
      listDimensions: clientRect,
    });
  };

  onResizeDefault = ({ bounds }: ContentRect) => {
    if (bounds) {
      this.setState({
        defaultWidth: bounds.width + 2 * minMargin,
      });
    }
  };

  render() {
    const {
      children,
      defaultText,
      interactive,
      lineBreak,
      onFocus,
      overlayId,
      stretch,
      hasError,
      dataCy,
      dropdownButton: DropdownButton = DefaultDropdownButton,
      getAvailableHeight = getDocumentHeight,
      maxWidth,
    } = this.props;

    const OptionList = this.props.dropdownOptionList ?? DefaultDropdownOptionList;

    const {
      position,
      scrollPosition,
      selectedIndex,
      open,
      widestOption,
      tallestOption,
      defaultWidth,
      selectedContent,
      buttonState,
    } = this.state;

    return (
      <div
        className={classNames(styles.dropdown, stretch && styles.stretch)}
        ref={this.onRef}
        onFocus={interactive ? onFocus : undefined}
        tabIndex={0}
      >
        <OptionList
          onItemSelected={this.onItemClicked}
          onResizeListItem={this.onResizeListItem}
          onResizeList={this.onResizeList}
          documentHeight={getAvailableHeight()}
          defaultWidth={defaultWidth + iconWidth + borderWidth}
          maxWidth={maxWidth}
          position={position}
          limitOptionWidth={stretch}
          scrollPosition={scrollPosition}
          selectedIndex={selectedIndex}
          open={open}
          overlayId={overlayId}
          dataCy={dataCy}
        >
          {children}
        </OptionList>
        <DropdownButton
          minWidth={widestOption - iconWidth}
          height={tallestOption}
          item={selectedContent}
          itemState={buttonState}
          onOpenMenu={this.onDropdownClicked}
          open={open}
          interactive={interactive}
          lineBreak={lineBreak}
          hasError={hasError}
          dataCy={dataCy}
        >
          <Measure bounds={true} onResize={this.onResizeDefault}>
            {({ measureRef }) => (
              <div className={styles.defaultText} ref={measureRef}>
                {defaultText}
              </div>
            )}
          </Measure>
        </DropdownButton>
      </div>
    );
  }
}

export default Dropdown;
