import * as React from 'react';
import { ImageGizmo } from '../../image/Image';
import { type BackgroundImage as BackgroundImageObject } from '@bettermarks/gizmo-types';
import { transformX, transformY } from '@bettermarks/importers';

import styles from './BackgroundImage.scss';
import { evaluateValueSetterExpression } from '@bettermarks/umc-kotlin';
import { type ValueSetterMap } from '../../../gizmo-utils/polymorphic-gizmo';
import { isEmpty } from 'lodash/fp';
import { valueSetterMapToValidatorValueMap } from '../../formula/Formula/helper';

export type BackgroundImageProps = BackgroundImageObject & {
  matrix: number[];
  borderRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  scale?: number;
  valueSetterMap?: ValueSetterMap;
};

export const BackgroundImage: React.FC<BackgroundImageProps> = ({
  matrix,
  scale,
  borderRect,
  valueSetterMap,
  coords,
  dynamicX,
  dynamicY,
  imageContent,
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  const { dynamicScale, scaleCenter } = imageContent;

  let dynamicScaleEv = 1;
  if (valueSetterMap !== undefined && !isEmpty(valueSetterMap) && dynamicScale !== undefined) {
    dynamicScaleEv = evaluateValueSetterExpression({
      expression: dynamicScale,
      valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
    });
  }

  if (scale) {
    const scaleFactor = scale * dynamicScaleEv;
    imageContent = {
      ...imageContent,
      width: imageContent.width * scaleFactor,
      height: imageContent.height * scaleFactor,
    };
  }

  const scaleCenterCoords = {
    x: scaleCenter?.x || 0,
    y: scaleCenter?.y || 0,
  };

  let { x, y } = coords;
  if (valueSetterMap !== undefined && !isEmpty(valueSetterMap)) {
    const valueMap = valueSetterMapToValidatorValueMap(valueSetterMap);

    if (dynamicX !== undefined) {
      x = evaluateValueSetterExpression({
        expression: dynamicX,
        valueMap,
      });
    }

    if (dynamicY !== undefined) {
      y = evaluateValueSetterExpression({
        expression: dynamicY,
        valueMap,
      });
    }
  }

  const screenX = sx(x);
  const screenY = sy(y);

  return (
    // need to wrap into extra svg, since Chrome is not able to apply clipPaths on foreignObjects
    // properly
    // -> doing a manual clipping against geo's border rect, here
    <svg
      x={borderRect.x}
      y={borderRect.y}
      width={borderRect.width}
      height={borderRect.height}
      className={styles.clipSvg}
    >
      <foreignObject
        x={screenX - borderRect.x - (dynamicScaleEv - 1) * (sx(scaleCenterCoords.x) - screenX)}
        y={
          screenY -
          borderRect.y -
          imageContent.height -
          (dynamicScaleEv - 1) * (sy(scaleCenterCoords.y) - screenY)
        }
        width={imageContent.width}
        height={imageContent.height}
        className={styles.backgroundImage}
      >
        <ImageGizmo {...imageContent} availableWidth={imageContent.width} allowObjectTag={false} />
      </foreignObject>
    </svg>
  );
};

BackgroundImage.displayName = 'BackgroundImage';
