import { getFontMetric } from '../../../../../utils/fontMetric';
import {
  forEachMathChild,
  type MathContent,
  type MExpansion as MExpansionContent,
  numberFromStyles,
} from '@bettermarks/gizmo-types';
import { type EnrichedContent } from '../../../../../gizmo-utils/configuration';
import * as React from 'react';
import Measure, { type ContentRect } from 'react-measure';
import { type EnrichNestedMathContent } from '../../measure/measureStatic';
import { CURVE_BOTTOM, CURVE_TOP, MexpansionElements as c } from '../constants';
import { calculateYOffset, enrich } from '../helpers';
import { StretchedShape } from '../MToken/StretchedShape';
import { type MPropsWithChildren } from '../types';
import { VerticalAlign } from '../VerticalAlign';

import styles from './MExpansion.scss';

const CURVED_ARROW_HEIGHT = numberFromStyles(styles.CURVED_ARROW_HEIGHT);
const CURVED_ARROW_MARGIN = numberFromStyles(styles.CURVED_ARROW_MARGIN);
const ARROW_HEIGHT = CURVED_ARROW_HEIGHT + 2 * CURVED_ARROW_MARGIN;

export type MExpansionProps = MPropsWithChildren & {
  lhsYOffset?: number;
  rhsYOffset?: number;
};

export interface MExpansionState {
  arrowWidth: number;
  denFactorMargin: number;
  denFactorWidth: number;
  fractionMargin: number;
  fractionWidth: number;
  lhsWidth: number;
  marginLeft: number;
  marginRight: number;
  numFactorMargin: number;
  numFactorWidth: number;
  operatorWidth: number;
}

const HALF = 0.5;
/**
 * MExpansion: The MathML <mexpansion> element is used to add an expansion or reduction factor
 * over a fraction expression. It uses the following syntax:
 * <mexpansion>
 *    numeratorFactor
 *    LHS fraction
 *    operator
 *    RHS fraction
 *    denominatorFactor
 * </mover>
 */

// calculateChildMargin will helps to update the child's margin
const calculateChildMargin = (
  arrowMargin: number,
  arrowWidth: number,
  fractionWidth: number,
  maxWidth: number
): number => {
  if (maxWidth > fractionWidth) {
    const updatedMargin = arrowMargin + (arrowWidth - fractionWidth) * HALF;
    // Resetting to 0 when margin goes negative
    return updatedMargin < 0 ? 0 : updatedMargin;
  }
  return 0;
};

// updateChildMargins helps in the calculation of all the margins
const updateChildMargins = (
  arrowWidth: number,
  denFactorWidth?: number,
  lhsWidth?: number,
  fractionWidth?: number,
  numFactorWidth?: number
) => {
  const margins: Partial<MExpansionState> = {};
  const maxWidth = Math.max(
    denFactorWidth ? denFactorWidth : 0,
    fractionWidth ? fractionWidth : 0,
    numFactorWidth ? numFactorWidth : 0
  );
  if (maxWidth > 0 && fractionWidth) {
    margins.fractionMargin = (maxWidth - fractionWidth) * HALF;
    if (lhsWidth) {
      // update the marginLeft of arrows
      const arrowMarginLeft = margins.fractionMargin + lhsWidth * HALF;
      margins.marginLeft = arrowMarginLeft;

      if (denFactorWidth) {
        // set the margin for numerator and denominator
        margins.denFactorMargin = calculateChildMargin(
          arrowMarginLeft,
          arrowWidth,
          denFactorWidth,
          maxWidth
        );
      }

      if (numFactorWidth) {
        // set the margin for numerator and denominator
        margins.numFactorMargin = calculateChildMargin(
          arrowMarginLeft,
          arrowWidth,
          numFactorWidth,
          maxWidth
        );
      }
    }
  }
  return margins;
};

export class MExpansion extends React.Component<MExpansionProps, MExpansionState> {
  state = {
    arrowWidth: 0,
    denFactorMargin: 0,
    denFactorWidth: 0,
    fractionWidth: 0,
    fractionMargin: 0,
    lhsWidth: 0,
    marginLeft: 0,
    marginRight: 0,
    numFactorMargin: 0,
    numFactorWidth: 0,
    operatorWidth: 0,
  };

  // updateChildMeasure method updates the MExpansion Element's alignment
  updateChildMeasure = (updatedPartial: Partial<MExpansionState>) =>
    this.setState((prevState) => {
      let mExpansionState = { ...prevState, ...updatedPartial };

      if (mExpansionState.lhsWidth) {
        const marginLeft = mExpansionState.lhsWidth * HALF;
        mExpansionState = { ...mExpansionState, marginLeft };
        if (mExpansionState.operatorWidth && mExpansionState.marginRight) {
          // To calculate the width of the arrows
          mExpansionState.arrowWidth =
            marginLeft + mExpansionState.operatorWidth + mExpansionState.marginRight;
          // To calculate the total width of LHS, Operator, RHS Fractions
          mExpansionState.fractionWidth =
            mExpansionState.arrowWidth + marginLeft + mExpansionState.marginRight;
        }
      }
      const margins = updateChildMargins(
        mExpansionState.arrowWidth,
        mExpansionState.denFactorWidth,
        mExpansionState.lhsWidth,
        mExpansionState.fractionWidth,
        mExpansionState.numFactorWidth
      );
      return { ...mExpansionState, ...margins };
    });

  updateState = ({ bounds }: ContentRect, contentType: string) => {
    let mExpansionState = {};
    if (bounds) {
      switch (contentType) {
        case c.denFactor:
          // To calculate the denominator factor's width
          mExpansionState = {
            ...mExpansionState,
            denFactorWidth: bounds.width,
          };
          break;
        case c.lhsFraction:
          // To align the arrows to start from the middle of left fraction
          mExpansionState = { ...mExpansionState, lhsWidth: bounds.width };
          break;
        case c.numFactor:
          // To calculate the numerator factor's width
          mExpansionState = {
            ...mExpansionState,
            numFactorWidth: bounds.width,
          };
          break;
        case c.operator:
          mExpansionState = { ...mExpansionState, operatorWidth: bounds.width };
          break;
        case c.rhsFraction:
          mExpansionState = {
            ...mExpansionState,
            marginRight: bounds.width * HALF,
          };
          break;
        default:
      }
      this.updateChildMeasure(mExpansionState);
    }
  };

  renderChild = (childContent: JSX.Element, contentType: string) => {
    const resizeFn = (contentRect: ContentRect) => this.updateState(contentRect, contentType);

    return (
      <Measure bounds onResize={resizeFn}>
        {({ measureRef }) => (
          <div ref={measureRef} className={styles.inlineBlock}>
            {childContent}
          </div>
        )}
      </Measure>
    );
  };

  render() {
    const { children, computedStyles, lhsYOffset, rhsYOffset } = this.props;
    const [numeratorFactor, lhsFraction, operator, rhsFraction, denominatorFactor] =
      React.Children.toArray(children) as JSX.Element[];
    const {
      arrowWidth: width,
      denFactorMargin,
      fractionMargin,
      marginLeft,
      marginRight,
      numFactorMargin,
    } = this.state;
    const shapeProps = {
      stretchable: true,
      computedStyles,
      shapeStyles: {
        // style for the curved arrows
        marginLeft,
        marginRight,
        width,
      },
    };
    return (
      <span className={styles.inlineBlock} style={computedStyles}>
        <div style={{ marginLeft: numFactorMargin }}>
          {this.renderChild(numeratorFactor, c.numFactor)}
        </div>
        <StretchedShape shape={CURVE_TOP} {...shapeProps} />
        <div style={{ marginLeft: fractionMargin }}>
          <VerticalAlign yOffset={lhsYOffset}>
            {this.renderChild(lhsFraction, c.lhsFraction)}
          </VerticalAlign>
          {this.renderChild(operator, c.operator)}
          <VerticalAlign yOffset={rhsYOffset}>
            {this.renderChild(rhsFraction, c.rhsFraction)}
          </VerticalAlign>
        </div>
        <StretchedShape shape={CURVE_BOTTOM} {...shapeProps} />
        <div style={{ marginLeft: denFactorMargin }}>
          {this.renderChild(denominatorFactor, c.denFactor)}
        </div>
        <svg className={styles.arrow}>
          <marker
            id="arrow"
            className={styles.arrowHead}
            markerWidth="10"
            markerHeight="10"
            orient="auto"
            refX="8.5"
            refY="5"
            markerUnits="userSpaceOnUse"
          >
            <path d="M0,0 L0,10 L10,5Z" strokeWidth="0" stroke="inherit" />
          </marker>
        </svg>
      </span>
    );
  }
}

type ContentKeys = 'lhsFraction' | 'rhsFraction' | 'numeratorFactor' | 'denominatorFactor';

export const enrichMExpansion: EnrichNestedMathContent<MExpansionContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher
) => {
  const enriched: Record<ContentKeys, EnrichedContent<MathContent>> = {} as any;

  forEachMathChild(
    content,
    (child, key) =>
      (enriched[key as keyof typeof enriched] = mathContentEnricher(formulaStyles, child, [
        ...path,
        key,
      ]))
  );
  const {
    lhsFraction: { enrichedContent: lhsFraction, ...lhsMetric },
    rhsFraction: { enrichedContent: rhsFraction, ...rhsMetric },
    numeratorFactor: { enrichedContent: numeratorFactor, ...numFactorMetric },
    denominatorFactor: { enrichedContent: denominatorFactor, ...denFactorMetric },
  } = enriched;

  // Height is calculated as the sum of
  // - maximum of the heights of lhs fraction and rhs fractions
  // - twice the height of the arrows
  // - height of the numerator factor
  // - height of the denominator factor
  const height =
    Math.max(lhsMetric.height, rhsMetric.height) +
    ARROW_HEIGHT * 2 +
    numFactorMetric.height +
    denFactorMetric.height;
  // refLine is calculated as the sum of
  // - maximum of the refLines of lhs fraction and rhs fractions
  // - height of the bottom arrow
  // - height of the denominator factor
  const refLine =
    Math.max(lhsMetric.refLine, rhsMetric.refLine) + ARROW_HEIGHT + denFactorMetric.height;

  const fontMetric = getFontMetric(formulaStyles.fontSize, formulaStyles.fontWeight);

  return enrich(
    {
      ...content,
      denominatorFactor,
      lhsFraction,
      lhsYOffset: lhsMetric.relativeToBaseLine
        ? 0
        : calculateYOffset(fontMetric, lhsMetric.refLine) - 0.5,
      numeratorFactor,
      rhsFraction,
      rhsYOffset: rhsMetric.relativeToBaseLine
        ? 0
        : calculateYOffset(fontMetric, rhsMetric.refLine) - 0.5,
    },
    { height, refLine },
    formulaStyles
  );
};
