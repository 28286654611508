import { POINTER } from '../../gizmo-utils/configuration/renderStyles';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { type Content, type Defaults } from '../../xml-converter/core';

export const POINTER_HEIGHT = 'height' as const;
export const POINTER_PEAKWIDTH = 'peakWidth' as const;
export const POINTER_WIDTH = 'width' as const;
export const POINTER_TAG = 'pointer' as const;

export const enum CapStyle {
  NONE = 'none',
  OPEN_TRIANGLE = 'open-arrow',
  T_ARROW = 'stroke',
  TRIANGLE = 'arrow',
}

export const isCapStyle = (val?: string): val is CapStyle =>
  val === CapStyle.NONE ||
  val === CapStyle.OPEN_TRIANGLE ||
  val === CapStyle.T_ARROW ||
  val === CapStyle.TRIANGLE;

export type PointerDecoration = {
  color: string;
  capStyleBottom: CapStyle;
  capStyleTop: CapStyle;
  dashGapLength?: number;
  dashLength?: number;
  thickness?: number;
};

export interface PointerContent extends Readonly<Content> {
  readonly uniqueId: string;
  readonly decoration: PointerDecoration;
  readonly height: number;
  readonly peakWidth: number;
  readonly strokeWidth: number;
  readonly width: number;
}

export const POINTER_GIZMO_DEFAULT_CONTENT: Defaults<PointerContent, 'uniqueId'> = {
  $: PLACEHOLDER,
  $renderStyle: POINTER,
  height: 9,
  peakWidth: 10,
  strokeWidth: 1,
  width: 27,
  decoration: {
    capStyleBottom: CapStyle.TRIANGLE,
    capStyleTop: CapStyle.NONE,
    color: 'bm-blue',
  },
};
