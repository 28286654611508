import * as React from 'react';
import classNames from 'classnames';

import { type RendererCell } from '../types';
import styles from './DecoratedCell.scss';

export const DecoratedCell = React.forwardRef<HTMLTableDataCellElement, RendererCell>(
  (
    {
      children,
      maxWidth,
      minWidth,
      colspan,
      rowspan,
      horizontalAlign,
      content,
      measureRef,
      ...cellStyle
    },
    ref
  ) => (
    <td
      colSpan={colspan}
      rowSpan={rowspan}
      ref={ref}
      className={classNames(styles.general, styles[horizontalAlign])}
      style={{
        ...cellStyle,
        textAlign: horizontalAlign,
        ...(maxWidth && { maxWidth }),
        ...(minWidth && { minWidth }),
      }}
    >
      {children}
    </td>
  )
);

DecoratedCell.displayName = 'DecoratedCell';
