import * as React from 'react';
import { defaultTo } from 'lodash';
import Measure, { type ContentRect } from 'react-measure';

import {
  type CommonCallbacks,
  type ContentReference,
  type MultipleChoiceSingleChoiceContent as ChoiceContent,
  type MultipleChoiceSingleContent,
} from '@bettermarks/gizmo-types';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';

import { MARGIN_SIZE_SUM, MULTIPLE_CHOICE_SINGLE_DEFAULT_CONTENT } from '../constants';
import MultipleChoiceCollapsedView from './MultipleChoiceCollapsedView';
import { type MultipleChoiceItemProps, MultipleChoiceSingleItem } from './MultipleChoiceSingleItem';
import { StackLayout } from './StackLayout';
import styles from './MultipleChoiceSingle.scss';

export interface MultipleChoiceSingleCallbacks {
  itemChosen?: (item: number) => void;
}

export type MultipleChoiceSingleProps = MultipleChoiceSingleContent &
  MultipleChoiceSingleCallbacks &
  CommonCallbacks &
  ContextState;

export type MultipleChoiceSingleState = {
  availableWidth: number;
  collapsedView: boolean;
  hoveredChoice?: ContentReference;
  preRendering: boolean;
};

export class MultipleChoiceSingle extends React.Component<
  MultipleChoiceSingleProps,
  MultipleChoiceSingleState
> {
  constructor(props: MultipleChoiceSingleProps) {
    super(props);
    this.state = {
      availableWidth: props.availableWidth,
      collapsedView: false,
      preRendering: true,
    };
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: MultipleChoiceSingleProps,
    state: MultipleChoiceSingleState
  ): void {
    if (nextProps.availableWidth !== state.availableWidth) {
      this.updateLayout();
    }
  }

  private readonly updateLayout = (): void => {
    this.setState((prevState: MultipleChoiceSingleState, props: MultipleChoiceSingleProps) => {
      /**
       * Only try if expanded view fits, if
       * - currently in collapsed view
       * - resize made the window wider
       */
      if (prevState.collapsedView && prevState.availableWidth < props.availableWidth) {
        return {
          preRendering: true,
          collapsedView: false,
          availableWidth: props.availableWidth,
        };
      } else {
        return prevState;
      }
    });
  };

  private readonly setHeight = ({ scroll }: ContentRect): void => {
    if (scroll) {
      const { height } = scroll;
      const deviceHeight = window.innerHeight;
      const collapsedView: boolean = height > deviceHeight - MARGIN_SIZE_SUM;
      this.setState((prevState: MultipleChoiceSingleState, props: MultipleChoiceSingleProps) => {
        if (prevState.preRendering || !prevState.collapsedView) {
          return {
            collapsedView,
            availableWidth: props.availableWidth,
            preRendering: false,
          };
        } else {
          return {
            ...prevState,
            availableWidth: props.availableWidth,
            preRendering: false,
          };
        }
      });
    }
  };

  /**
   * returns true or false based on whether the option is selected or not
   * returns the first choice as default selected for collapsed view
   */
  private readonly getIsSelected = (index: number): boolean => {
    if (!this.state.collapsedView) {
      return this.props.checkedIdx === index;
    } else {
      const defaultIndex = this.props.checkedIdx !== -1 ? this.props.checkedIdx : 0;
      return defaultIndex === index;
    }
  };

  private readonly updateHoveredChoice = (hoveredIdx: number, isHovered: boolean): void => {
    this.setState({
      hoveredChoice: isHovered ? this.props.choices[hoveredIdx].contentRef : undefined,
    });
  };

  private readonly getMCItems = (
    choices: ChoiceContent[],
    availableWidth: number
  ): JSX.Element[] => {
    const elementsPerLine = defaultTo<number>(
      this.props.configuration.elementsPerLine,
      Number.MAX_VALUE
    );
    const interactive = !this.props.disabled;
    let choicesElements = choices.map((element: ChoiceContent, index) => {
      /**
       * A callback for selecting an item is needed only if the component is interactive and
       * if the selection is different from the previous one.
       * Otherwise, there is no point in dispatching an action.
       */
      const onChosen =
        interactive && this.props.itemChosen && index !== this.props.checkedIdx
          ? this.props.itemChosen
          : undefined;

      const multipleChoiceItemProps: MultipleChoiceItemProps = {
        index,
        selected: this.getIsSelected(index),
        content: element.contentRef,
        interactive,
        radioButtons: this.props.configuration.radioButtons,
        onChosen,
        severity: this.props.severity,
        collapsedView: this.state.collapsedView,
        availableWidth,
      };
      if (this.state.collapsedView && !this.props.isTouch && interactive) {
        multipleChoiceItemProps.onHover = this.updateHoveredChoice;
      }
      return (
        <React.Fragment key={`item_${index}`}>
          <MultipleChoiceSingleItem {...multipleChoiceItemProps} />
          {!this.state.collapsedView && (index + 1) % elementsPerLine === 0 && (
            <div className={styles.flexBreaker} />
          )}
        </React.Fragment>
      );
    });

    if (this.state.collapsedView) {
      const indexToShow = this.props.checkedIdx !== -1 ? this.props.checkedIdx : 0;
      if (indexToShow !== this.props.checkedIdx) {
        this.props.itemChosen && this.props.itemChosen(0);
      }
      const choiceToShow = this.state.hoveredChoice || choices[indexToShow].contentRef;
      choicesElements = [
        <MultipleChoiceCollapsedView
          key={0}
          choicesElements={choicesElements}
          choiceToShow={choiceToShow}
          stackLayout={false}
          availableWidth={availableWidth}
        />,
      ];
    }

    return choicesElements;
  };

  render(): JSX.Element {
    const props = { ...MULTIPLE_CHOICE_SINGLE_DEFAULT_CONTENT, ...this.props };
    const { choices, configuration, onFocus, availableWidth } = props;

    const choicesElements = this.getMCItems(choices, availableWidth);
    const style: React.CSSProperties =
      this.state.preRendering && !this.state.collapsedView ? { visibility: 'hidden' } : {};

    return (
      <span onFocus={onFocus} tabIndex={0} className={styles.multipleChoiceSingle}>
        {!configuration.stackLayout ? (
          <Measure scroll onResize={this.setHeight}>
            {({ measureRef }: any) => (
              <div ref={measureRef}>
                <div className={styles.flexWrapItems} style={style}>
                  {choicesElements}
                </div>
              </div>
            )}
          </Measure>
        ) : (
          <StackLayout {...props} />
        )}
      </span>
    );
  }
}
