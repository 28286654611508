import * as React from 'react';
import classNames from 'classnames';

import { CalculatorLayout } from './types';
import styles from './Calculator.scss';

export const CalculatorScreen: React.FC<{ layout: CalculatorLayout }> = ({ layout, children }) => (
  <div
    className={classNames({
      [styles.horizontalScreen]: layout === CalculatorLayout.HORIZONTAL,
      [styles.verticalScreen]: layout === CalculatorLayout.VERTICAL,
    })}
  >
    {children}
  </div>
);
