import { call, put, select } from 'redux-saga/effects';
import {
  type ApplicationState,
  type AppExercise,
  type AppStep,
  type SeriesScore,
} from '../../../../types';
import { flatMap, map } from 'lodash';
import { dropWhile } from 'lodash/fp';
import { getSeriesResult, postSeriesResults } from '../../../../apps/seriesplayer/services';
import { getStepResult } from '../../../../apps/seriesplayer/reducers/reporting';
import { setSeriesScore } from './actions';
import { showResults } from '../SeriesPlayer/actions';
import { logMissingFields } from './helpers';

interface StepInfo {
  exerciseIndex: number;
  step: AppStep;
  stepIndex: number;
}

const getIncompleteSteps = (exercises: ReadonlyArray<AppExercise>) => {
  const result: StepInfo[] = flatMap(exercises, (exercise: AppExercise, exerciseIndex: number) =>
    map(exercise.steps, (step: AppStep, stepIndex: number) => ({
      exerciseIndex,
      step,
      stepIndex,
    }))
  );
  return dropWhile(({ step }) => !step.submittedIncomplete, result);
};

export function* submitIncompleteSeriesSaga() {
  const state: ApplicationState = yield select();
  const steps: StepInfo[] = getIncompleteSteps(state.series.exercises);

  if (state.series.seriesSettings.reporting) {
    const stepSubmissions = steps
      .map(({ exerciseIndex, stepIndex }) => getStepResult(state.series, exerciseIndex, stepIndex))
      // Sanitize steps array (we have cases where it contains incomplete information).
      // All of those cases lead to non existing step indices (e.g. step index 1 for a single step
      // exercise), which makes filtering them out of the data we transfer to the backend reasonable
      .filter(({ stepId }) => stepId !== undefined);

    // only call BE reporting route, if there is incomplete steps that got submitted by pushing the
    // "Submit incomplete" button
    if (stepSubmissions.length > 0) {
      logMissingFields(stepSubmissions, 'submit-incomplete');
      yield call(
        postSeriesResults,
        state.appSettings.resultManagerUrl,
        stepSubmissions,
        state.series.userId || '',
        state.series.id
      );
    }

    const seriesScore: SeriesScore = yield call(
      getSeriesResult,
      state.appSettings.resultManagerUrl,
      { seriesId: state.series.id }
    );

    yield put(setSeriesScore(seriesScore));
  }
  yield put(showResults({ animate: true }));
}
