import { type ValidationError, ValidationExceptionType } from '@bettermarks/umc-kotlin';
import { HttpStatusCode } from '../../../types';

export const enum FetchExceptionType {
  clientException = 'clientException',
  networkException = 'networkException',
  serverException = 'serverException',
  testHasBeenCollectedException = 'testHasBeenCollectedException',
}

export const enum LoadSeriesExceptionType {
  forbiddenToReviewUnfinishedSeries = 'forbiddenToReviewUnfinishedSeries',
}

export const enum ErrorReason {
  TEST_HAS_BEEN_COLLECTED = 'TEST_HAS_BEEN_COLLECTED',
  DEFAULT = 'DEFAULT',
  SERIES_CANNOT_BE_REVIEWED = 'SERIES_CANNOT_BE_REVIEWED',
}

export class FetchException extends Error {
  constructor(
    message: string,
    public readonly type: FetchExceptionType,
    public response: Nullable<string>,
    public url: string,
    public requestInit?: any
  ) {
    super(message);
  }
}

export class LoadSeriesException extends Error {
  constructor(
    message: string,
    public readonly type: LoadSeriesExceptionType,
    public response: Nullable<string>,
    public url: string,
    public requestInit?: any
  ) {
    super(message);
  }
}

type ExceptionParams = [string, FetchExceptionType, Nullable<string>];

export const generateFetchException =
  (url: string, init?: RequestInit) =>
  (message: string, type: FetchExceptionType, response: Nullable<string>) =>
    new FetchException(message, type, response, url, init);

export const getExceptionParamsFromError = (err: Error): ExceptionParams => [
  `Failed to fetch (${err.name})`, // err.name is the type of the error
  FetchExceptionType.networkException,
  err.message, // browser specific localized message
];

export const getExceptionParamsFromResponse = (
  responseStatus: number,
  responseText: string
): ExceptionParams => [
  `Bad http status: ${responseStatus}`,
  responseStatus >= HttpStatusCode.INTERNAL_SERVER_ERROR
    ? FetchExceptionType.serverException
    : FetchExceptionType.clientException,
  responseText,
];

export const isThrowableStatus = (status: HttpStatusCode) =>
  status < HttpStatusCode.OK || status >= HttpStatusCode.BAD_REQUEST;

export const isTestHasBeenCollectedException = (
  err: FetchException | Error
): err is FetchException =>
  (err as FetchException).type === FetchExceptionType.testHasBeenCollectedException;

export const isFetchException = (err: FetchException | Error): err is FetchException =>
  (err as FetchException).type === FetchExceptionType.clientException ||
  (err as FetchException).type === FetchExceptionType.networkException ||
  (err as FetchException).type === FetchExceptionType.testHasBeenCollectedException ||
  (err as FetchException).type === FetchExceptionType.serverException;

export const isServerException = (err: FetchException | Error): err is FetchException =>
  (err as FetchException).type === FetchExceptionType.serverException;

export const isClientException = (err: FetchException | Error): err is FetchException =>
  (err as FetchException).type === FetchExceptionType.clientException;

export const isNetworkException = (err: FetchException | Error): err is FetchException =>
  (err as FetchException).type === FetchExceptionType.networkException;

export const isValidationError = (err: ValidationError | Error): err is ValidationError =>
  (err as ValidationError).type === ValidationExceptionType.validationError;

export const isForbiddenToReviewUnfinishedSeriesError = (
  err: LoadSeriesException | Error
): err is LoadSeriesException =>
  (err as LoadSeriesException).type === LoadSeriesExceptionType.forbiddenToReviewUnfinishedSeries;
