import {
  type AxisTick,
  type Coords,
  type GeoConfigurationDisplay,
  type GeoDecoration,
  Hover,
  LineWeight,
  POINT,
  type PointDecoration,
  type PointObject,
} from '@bettermarks/gizmo-types';
import {
  DEFAULT_ANGLE_ARC_FILL_COLOR,
  DEFAULT_ANGLE_ARC_FILL_OPACITY,
  DEFAULT_STROKE_WIDTH,
} from './constants';
import { isEmpty, isNil, round } from 'lodash';

export function getStrokeWidth(decoration: GeoDecoration, hover?: Hover): number {
  let result;

  switch (decoration.lineWeight) {
    case LineWeight.thin:
      result = 1;
      break;
    case LineWeight.thick:
      result = 4;
      break;
    case LineWeight.medium:
    default:
      result = DEFAULT_STROKE_WIDTH;
  }

  if (hover) {
    switch (hover) {
      case Hover.DELETE:
      case Hover.SELECT:
        result += 2;
        break;
      default:
      // nothing to do
    }
  }

  return result;
}

export const createPoint = (
  coords: Coords,
  decoration = {},
  noSnap = false,
  invisible = false
): PointObject => ({
  type: POINT,
  coords,
  referringTo: [],
  referencedBy: [],
  addedByUser: true,
  ...(!isEmpty(decoration) && { decoration: decoration as PointDecoration }),
  ...(noSnap && { noSnap }),
  ...(invisible && { invisible }),
});

export const geoConfigurationDisplay = (
  w: number,
  h: number,
  x: number,
  y: number,
  tickValueInt: Coords
): GeoConfigurationDisplay => ({
  width: w,
  height: h,
  cx: x,
  cy: y,
  xMin: (x - w * 0.5) * tickValueInt.x,
  yMin: (y - h * 0.5) * tickValueInt.y,
  xMax: (x + w * 0.5) * tickValueInt.x,
  yMax: (y + h * 0.5) * tickValueInt.y,
});

export const getPointCoordsId = (coords: Coords, prefix = 'p') =>
  `${prefix}:${round(coords.x, 7)}_${round(coords.y, 7)}`;

// To extract ticks for axis and interval
export const getTicks = (
  min: number,
  max: number,
  tickValueInterval: number,
  tickLabelInterval: number,
  minMajorTick: number,
  isVertical: boolean
): ReadonlyArray<AxisTick> => {
  const length = max - min;
  const numberOfTicks = [...Array(Math.ceil(length / tickValueInterval + 1))];
  return numberOfTicks.map((_, i) => {
    const val = min + i * tickValueInterval;
    return {
      major: (min / tickValueInterval + i) % tickLabelInterval === 0,
      pos: isVertical ? { x: 0, y: val } : { x: val, y: 0 },
    };
  });
};

export const setAngleFillTransparency = (decoration: GeoDecoration): GeoDecoration => {
  if (isNil(decoration.fillTransparency)) {
    const fillColor = decoration.fillColor;
    const fillTransparency =
      isNil(fillColor) || fillColor === DEFAULT_ANGLE_ARC_FILL_COLOR
        ? 0
        : DEFAULT_ANGLE_ARC_FILL_OPACITY;
    return {
      ...decoration,
      fillTransparency,
    };
  }
  return decoration;
};
