import { connect } from 'react-redux';
import { get, getOr } from 'lodash/fp';
import { ApplicationState } from '../../../../../../types';
import { ModeSelectorDrawerToggle } from './ModeSelectorDrawerToggle';
import { type EditorMode, GeoEditorMode } from '@bettermarks/gizmo-types';

const EMPTY_ARRAY: EditorMode[] = [];

const mapStateToProps = (state: ApplicationState) => {
  const { isTouch } = ApplicationState.toRuntimeState.get(state);
  const content = ApplicationState.toSelectedContent(state).get(state);
  const selectedMode = get('selectedMode', content);
  const modes = getOr(EMPTY_ARRAY, 'tool.modes', content).filter((mode: EditorMode) =>
    !isTouch ? mode !== GeoEditorMode.SCROLL : mode
  );

  return {
    selectedMode: isTouch
      ? selectedMode
      : modes.indexOf(selectedMode) !== -1
      ? selectedMode
      : modes[0],
  };
};

export const ModeSelectorDrawerToggleContainer = connect(mapStateToProps)(ModeSelectorDrawerToggle);
