import * as React from 'react';
import { styleName } from '../../../components/_utils';
import styles from './BasicShape.scss';
import { type BasicShapeProps } from './types';
import { dimensionsRectangle } from './helper';

export type RectangleProps = BasicShapeProps & {
  /*
   * the prop `square` is only used by <SquareShape>
   * which is implemented here as a special case of a rectangle
   */
  square?: true;
};

/**
 * The `RectangleShape` component renders a rectangle shape (or a square)
 */
export const RectangleShape: React.FC<RectangleProps> = ({
  color,
  scalefactor,
  square,
  gradientType,
}) => {
  const { width, height, leftPadding, topPadding, heightWithPadding, widthWithPadding } =
    dimensionsRectangle(scalefactor, square);
  const borderWidth = scalefactor;
  const className = styleName(color, gradientType ? gradientType : '');

  // The stroke width is used to draw the border around the rectangle
  return (
    <svg
      viewBox={`0 0 ${widthWithPadding} ${heightWithPadding}`}
      width={widthWithPadding}
      height={heightWithPadding}
      className={styles[className]}
    >
      <rect
        x={leftPadding}
        y={topPadding}
        width={width}
        height={height}
        strokeWidth={borderWidth}
        className={styles[className]}
      />
    </svg>
  );
};

RectangleShape.displayName = 'RectangleShape';
