import * as React from 'react';
import { useContentTranslation, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { type EM } from '../../../types';

import { StructuredExampleStep } from './StructuredExampleStep';
import styles from './EM.scss';

export type StructuredExampleProps = Readonly<
  EM.ElementStructuredExample & {
    availableWidth: number;
  }
>;

export const StructuredExample: React.FC<StructuredExampleProps> = ({
  setting,
  overview,
  steps,
  availableWidth,
}) => {
  const t = useContentTranslation();
  return (
    <div className={styles.emStructuredExample}>
      {setting && (
        <div className={styles.emStructuredExampleSetting}>
          <h4 className={styles.emStructuredExampleSettingTitle}>
            {t('example.exercise', { ns: 'kem' })}
          </h4>
          <PolymorphicGizmo refid={setting.$refid} availableWidth={availableWidth} />
        </div>
      )}
      <h4 className={styles.emStructuredExampleSettingSolution}>
        {t('example.solution', { ns: 'kem' })}
      </h4>
      {steps &&
        steps.map((step, i) => (
          <StructuredExampleStep
            {...step}
            availableWidth={availableWidth}
            last={i === steps.length - 1 && !overview}
            key={i}
          />
        ))}
      {overview && (
        <div className={styles.emStructuredExampleOverview}>
          <PolymorphicGizmo refid={overview.$refid} availableWidth={availableWidth} />
        </div>
      )}
    </div>
  );
};

StructuredExample.displayName = 'StructuredExample';
