import * as React from 'react';
import { includes } from 'lodash';
import { Hover, type LineObject, type PointObject, STRAIGHTLINE } from '@bettermarks/gizmo-types';
import { Line } from '../../components';
import { type LineSetProps, type SetProps } from './types';
import { getLineCoords } from '../../helpers';
import { angle, visibleCenter } from '@bettermarks/importers';
import { isSelectableObject } from './setHelpers';

export type StraightlineSetProps = SetProps &
  LineSetProps & {
    straightlines: ReadonlyArray<string>;
  };

export const StraightlineSet: React.FC<StraightlineSetProps> = ({
  straightlines,
  geoContentMap,
  matrix,
  borderRectId,
  highlight,
  configuration,
  mode,
  onClick,
  onHoverId,
  onMouseOutId,
  onMouseDownId,
  snapHighlightIds,
}) => {
  const [onHover, onMouseOut, onMouseDown] = [onHoverId, onMouseOutId, onMouseDownId];
  return (
    <g>
      {straightlines.map((id) => {
        const s = geoContentMap[id] as LineObject;

        const [p1, p2] = [
          (geoContentMap[s.p1Id] as PointObject).coords,
          (geoContentMap[s.p2Id] as PointObject).coords,
        ];
        const center = highlight
          ? visibleCenter(p1, p2, configuration.display, STRAIGHTLINE)
          : undefined;
        const rotate = highlight ? angle(p1, p2) : undefined;
        const [pt1, pt2] = getLineCoords(p1, p2, STRAIGHTLINE);

        const callbacks = {
          ...(onClick && { onClick: onClick(id) }),
          ...(onHover && { onHover: onHover(id) }),
          ...(onMouseDown && { onMouseDown: onMouseDown(id) }),
          ...(onMouseOut && { onMouseOut: onMouseOut(id) }),
        };

        const hover = s.hover || (includes(snapHighlightIds, id) ? Hover.DEFAULT : undefined);

        return (
          <Line
            key={id}
            {...{ id, matrix, borderRectId, highlight, center, rotate, hover }}
            x1={pt1.x}
            y1={pt1.y}
            x2={pt2.x}
            y2={pt2.y}
            decoration={s.decoration}
            notLabelable={s.notLabelable}
            severity={s.severity}
            selectable={isSelectableObject(s, mode)}
            {...callbacks}
          />
        );
      })}
    </g>
  );
};

StraightlineSet.displayName = 'StraightlineSet';
