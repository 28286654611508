import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { isNil } from 'lodash';
import { type ContextState, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  type ContentReference,
  DragAndDropStyle,
  type DragSourceContent,
  type TranslateCoords,
} from '@bettermarks/gizmo-types';
import { DragSource as DragSourceComponent } from '../../../components/DragAndDrop';
import { type ContentRect } from 'react-measure';
import DnD, { DragEvents } from '../../../components/DragAndDrop/dnd';
import { DragNDropContext } from '../context';
import { DRAWER_PORTAL_ID } from '../../../gizmo-utils/constants';
import classNames from 'classnames';
import styles from './DragSourceGizmo.scss';
import { DRAG_SOURCE_PREFIX } from '../constants';

export interface DragSourceCallbacks {
  onResize?: (width: number, height: number, left: number, top: number) => void;
  onDropFreeDragItem?: (freeDragItem: ContentReference, newPos: TranslateCoords) => void;
}

export type DragSourceProps = DragSourceContent &
  DragSourceCallbacks &
  ContextState & { refid: string } & { nextFreeDragItemCopyIndex: (refid: string) => number };

type DragSourceState = {
  dragging: boolean;
  draggable: boolean;
};

const getDrawer = (doc = document) => doc.getElementById(DRAWER_PORTAL_ID);

/**
 * Connects the [[components/DragSource]] UIComponent to [[../DragSourceContent]].
 */
export class DragSourceGizmo extends React.Component<DragSourceProps, DragSourceState> {
  static contextType = DragNDropContext;
  context!: React.ContextType<typeof DragNDropContext>;

  state = {
    dragging: false,
    draggable: true,
  };

  private ref: HTMLSpanElement;

  componentDidMount() {
    DnD.addEventListener(DragEvents.dragStart, this.onAnyDragStarted);
    DnD.addEventListener(DragEvents.dragEnd, this.onAnyDragEnded);
    DnD.addEventListener(DragEvents.drop, this.onAnyDragEnded);
  }

  componentWillUnmount() {
    DnD.removeEventListener(DragEvents.dragStart, this.onAnyDragStarted);
    DnD.removeEventListener(DragEvents.dragEnd, this.onAnyDragEnded);
    DnD.removeEventListener(DragEvents.drop, this.onAnyDragEnded);
  }

  componentDidUpdate(): void {
    const { maxItemWidth, setMaxItemWidth } = this.context;
    const { width } = this.props;

    if (width && width > maxItemWidth) {
      setMaxItemWidth(width);
    }
  }

  onAnyDragStarted = () => {
    this.setState({ draggable: false });
  };

  onAnyDragEnded = () => {
    this.setState({
      draggable: true,
      dragging: false,
    });
  };

  onMouseDown = ({ currentTarget, clientX, clientY }: React.MouseEvent) => {
    this.startDrag(currentTarget, clientX, clientY);
  };

  onTouchStart = (evt: TouchEvent) => {
    const interactive =
      !isNil(this.props.$interactionType) &&
      !this.props.disabled &&
      // we shouldn't prevent default behavior if there is no draggable element
      this.props.instances !== 0;

    if (evt.currentTarget && interactive) {
      this.startDrag(evt.currentTarget as Element, evt.touches[0].clientX, evt.touches[0].clientY);
      evt.preventDefault();
    }
  };

  startDrag = (target: EventTarget & Element, x: number, y: number) => {
    if (this.props.instances === 0) {
      return;
    }
    const sourceRef = target.firstChild as Element;
    DnD.startDrag({
      targetGroup: this.props.targetGroup,
      originalSourceRefId: this.props.refid,
      sourceRef,
      x,
      y,
      initialRect: getDrawer()?.getBoundingClientRect(),
      freeSnapping: this.props.freeSnapping,
      instances: this.props.instances,
    });
    DnD.addEventListener(DragEvents.dragEnd, this.onDragEnd);
    DnD.addEventListener('drop', this.onDragEnd);
    this.setState({
      dragging: true,
    });
  };

  onFreeDragEnd = () => {
    const itemPos = DnD.getDragItemTranslationPosition();
    // happens when user starts dragging but do not move
    const itemDidMove = !(itemPos.translateX === 0 && itemPos.translateY === 0);
    if (this.props?.onDropFreeDragItem && itemDidMove) {
      this.props.onDropFreeDragItem(
        {
          $refid: this.props.refid,
        },
        itemPos
      );
    }
  };

  onMouseUp: React.MouseEventHandler<any> = () => {
    DnD.endDrag();
  };

  onTouchEnd: React.TouchEventHandler<any> = () => {
    DnD.endDrag();
  };

  onDragEnd = () => {
    this.onFreeDragEnd();
    DnD.removeEventListener(DragEvents.dragEnd, this.onDragEnd);
    DnD.removeEventListener(DragEvents.drop, this.onDragEnd);

    this.setState({
      dragging: false,
    });
  };

  onResize = ({ bounds }: ContentRect) => {
    this.props.onResize &&
      bounds &&
      this.props.onResize(bounds.width, bounds.height, bounds.left, bounds.top);
  };

  render() {
    /*
     * If width or height are not defined, the CSS will take care of sizing the drag source. Either
     * the contained gizmo defines its size then or if it's too small, the min-width and min-height
     * CSS properties.
     *
     * Regarding the id property:
     * If you drag an item (either from a source or a filled target) and drop it somewhere on the
     * document, it should animate back to its original source.
     * Therefore the source or the filled drop target where we start the drag stores the refid of the
     * original source in the drag event. When we end the drag, it can then figure out via the
     * constructed element id, where the animation should end.
     * The refid of the original source (or of it's copy after validation) will NOT change during a
     * drag operation, so it's a good identifier, to figure out where to animate to. You will never
     * start a drag before a validation or content tree restructuring and end it after.
     *
     */

    const drawer = getDrawer();
    if (drawer && this.props.availableInDrawer) {
      return ReactDOM.createPortal(this.renderDragSource(), drawer);
    }

    return this.renderDragSource();
  }

  private readonly onSpanRefRender = (span: HTMLSpanElement | null) => {
    if (span === null || span === undefined) return;

    if (this.ref !== undefined) {
      this.ref.removeEventListener('touchstart', this.onTouchStart);
    }

    this.ref = span;
    this.ref.addEventListener('touchstart', this.onTouchStart, {
      passive: false,
    });
  };

  private readonly renderDragSource = (): React.ReactNode => {
    const {
      refid,
      availableWidth,
      content,
      instances,
      width,
      height,
      style,
      targetGroup,
      $interactionType,
      disabled,
    } = this.props;
    const { dragging } = this.state;

    const shownStack =
      instances <= 0
        ? // It's either empty, or infinite stack. Dragging shall not affect.
          instances
        : dragging
        ? instances - 1
        : instances;

    const interactive = !isNil($interactionType) && !disabled;

    return (
      <div
        className={classNames(styles.draggable_item_frame, {
          [styles.dragging]: dragging,
          [styles.single_item]: !shownStack,
          [styles.border]: style === DragAndDropStyle.border,
          [styles.rectangle]: style === DragAndDropStyle.rectangle,
          [styles.none]: style === DragAndDropStyle.none,
        })}
      >
        <span
          role="button"
          ref={this.onSpanRefRender}
          onMouseDown={interactive ? this.onMouseDown : undefined}
          onMouseUp={interactive ? this.onMouseUp : undefined}
        >
          <DragSourceComponent
            itemType={targetGroup}
            stack={shownStack}
            shape={style === DragAndDropStyle.none ? undefined : style}
            onResize={this.onResize}
            width={width}
            height={height}
            id={`${DRAG_SOURCE_PREFIX}-${refid}`}
            draggable={interactive && this.state.draggable}
          >
            <PolymorphicGizmo
              refid={content.$refid}
              availableWidth={availableWidth}
              keepLineHeight
            />
          </DragSourceComponent>
        </span>
      </div>
    );
  };
}
