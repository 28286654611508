import * as React from 'react';
import { defaultTo } from 'lodash';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  CursorPositionOffset,
  GeoEditorMode,
  type GeoScene,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import {
  addAngleLineAction,
  dragAction,
  geoAddAngleLineReducer,
  hoverAction,
  GEO_ADD_ANGLE_LINE_INITIAL_STATE,
  outAction,
  startDragAction,
  stopDragAction,
} from './geoAddAngleLineReducer';
import { type GeoProps } from '../../Geo';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { mousePos, screenToWorld } from '@bettermarks/importers';
import { Maybe } from '../../../../utils/maybe';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export const GeoAddAngleLine: React.FC<GeoProps & DragScrollableProps> = (props) => (
  <WithLocalRedux
    store={{
      ...GEO_ADD_ANGLE_LINE_INITIAL_STATE,
      persistProps: persistProps(props),
    }}
    reducer={geoAddAngleLineReducer}
    componentName={`AddAngleLine:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { uniqueId, configuration, matrix, onPersistLocalState } = props;
      const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

      const onTouchStart = () => maybeScrollBehaviour.ap(startScrolling);

      const drag = (scene: GeoScene) => (evt: MouseOrTouch) => {
        if (state.dragging) {
          const mouseP = screenToWorld(matrix)(
            mousePos(defaultTo<string>(uniqueId, ''), CursorPositionOffset.NONE)(evt)
          );
          dispatch(dragAction({ mouseP, configuration }));
        }
      };

      const stopDrag = () => dispatch(stopDragAction());

      const down = (id: string) => (evt: MouseOrTouch) => dispatch(startDragAction({ id, matrix }));

      const up = () => {
        if (state.dragging) {
          dispatch(addAngleLineAction({ configuration, onPersistLocalState }));
        }
        maybeScrollBehaviour.ap(stopScrolling);
      };

      const hover = (id: string) => (evt: MouseOrTouch) => {
        if (!state.dragging) {
          dispatch(hoverAction({ id, matrix }));
        }
      };

      const out = (id: string) => (evt: MouseOrTouch) => {
        dispatch(outAction());
      };

      const geoContentProps = { ...props, ...state.persistProps };

      return (
        <GeoRenderer
          {...geoContentProps}
          mode={GeoEditorMode.ADD_ANGLE}
          highlight={state.highlight}
          onMouseMove={drag}
          onMouseUp={up}
          onMouseLeave={stopDrag}
          onPointMouseOver={hover}
          onPointMouseOut={out}
          onPointMouseDown={down}
          onTouchStart={onTouchStart}
          snapPoints={state.snapPoints}
          previewObjects={{
            lines: state.prevLine.p1 ? [state.prevLine] : [],
            points: state.prevPoints ? state.prevPoints : [],
            angles: state.prevAngles ? state.prevAngles : [],
          }}
          toolValueLabels={state.toolValueLabels}
        />
      );
    }}
  </WithLocalRedux>
);

GeoAddAngleLine.displayName = 'GeoAddAngleLine';
