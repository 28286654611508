import { FONT_SIZE_MAX, FONT_SIZE_MIN } from '@bettermarks/bm-font';
import {
  type Decoration,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_SIZE_FLASH,
} from '@bettermarks/gizmo-types';

/**
 * calculates custom font-size with the boundary conditions:
 * - max and min custom font-size
 * - default font-size in flash app: 13
 * - default font-size in react formula: 16
 * -> delta of custom font-size to default font-size should be equal in both apps
 * @type {{fontSize: number}}
 */
export const convertFlashFontSize = (value: number | string) =>
  Math.max(
    FONT_SIZE_MIN,
    Math.min(DEFAULT_FONT_SIZE + (+value - DEFAULT_FONT_SIZE_FLASH), FONT_SIZE_MAX)
  );

export const flashFontSize = (value: number | string): number => parseInt(value.toString(), 10);

/**
 * Reverts the calculation for fontSize that has been done in `convertFlashFontSize`
 * in a way that applying `convertFlashFontSize` again will yield the same value.
 */
export const revertToFlashFontSize = (decoration: Decoration | undefined) =>
  decoration && decoration.fontSize
    ? {
        ...decoration,
        fontSize: decoration.fontSize - DEFAULT_FONT_SIZE + DEFAULT_FONT_SIZE_FLASH,
      }
    : decoration;
