import { type Action, handleAction } from 'redux-actions';
import { type Content, type ContentDict, type Direction } from '@bettermarks/gizmo-types';
import {
  type AutoSizePayload,
  DND_AUTO_SIZE,
  type GizmoAction,
  RESET_SHOULD_MOVE_CURSOR,
  SELECT_CONTENT_DIRECTION,
} from './gizmoActions';
import {
  type DnDPayload,
  type DnDRemovePayload,
  type FreeDnDPayload,
  DROP,
  FREE_DROP,
  REMOVE_DRAG_ITEM,
} from '../../gizmos/drag-and-drop/actions';
import { drop, freeDrop, remove } from '../../gizmos/drag-and-drop/dragging';
import { applyDragSourceSize } from '../../gizmos/drag-and-drop/dndAutoSize';
import { reduceReducers } from '../reduceReducers';
import { omit } from 'lodash';

export type ContentDictReducerPayload = AutoSizePayload | DnDPayload | DnDRemovePayload | Direction;
const emptyDict: ContentDict = {};

const handleDrop = handleAction(
  DROP,
  (contentDict: ContentDict, { payload }: Action<DnDPayload>) =>
    drop(payload.source, payload.target, payload.freeDragItemTranslate, payload.index, contentDict),
  emptyDict
);

const handleFreeDrop = handleAction(
  FREE_DROP,
  (contentDict: ContentDict, { payload }: Action<FreeDnDPayload>) =>
    freeDrop(payload.freeDragItem, payload.newPos, contentDict),
  emptyDict
);

const handleDndAutoSize = handleAction(
  DND_AUTO_SIZE,
  (contentDict: ContentDict, { payload, meta: { gizmoId } }: GizmoAction<AutoSizePayload>) =>
    applyDragSourceSize(
      gizmoId,
      payload.width,
      payload.height,
      payload.left,
      payload.top
    )(contentDict),
  emptyDict
);

const handleRemoveDragItem = handleAction(
  REMOVE_DRAG_ITEM,
  (contentDict: ContentDict, { payload }: Action<DnDRemovePayload>) =>
    remove(payload.source, payload.index || 0, contentDict, payload.freeDragItemTranslate),
  emptyDict
);

const handleSelectContentDirection = handleAction(
  SELECT_CONTENT_DIRECTION,
  (
    contentDict: ContentDict,
    { payload: selectDirection, meta: { gizmoId } }: GizmoAction<Direction>
  ) => {
    return {
      ...contentDict,
      [gizmoId]: {
        ...(contentDict[gizmoId] as Content),
        selectDirection,
      },
    };
  },
  emptyDict
);

const handleResetShouldMoveCursor = handleAction(
  RESET_SHOULD_MOVE_CURSOR,
  (contentDict: ContentDict, { meta: { gizmoId } }: GizmoAction) => {
    return {
      ...contentDict,
      [gizmoId]: omit(contentDict[gizmoId], 'shouldMoveCursorToSibling') as Content,
    };
  },
  emptyDict
);

export const contentDictReducer = reduceReducers<ContentDict>(
  handleDrop,
  handleFreeDrop,
  handleDndAutoSize,
  handleRemoveDragItem,
  handleSelectContentDirection,
  handleResetShouldMoveCursor
);
