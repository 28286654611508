import * as React from 'react';
import styles from './Keyboard.scss';
import { SpecialKeys } from './SpecialKeys';
import { type KeyHandler } from './Key';

export type KeyPageProps = {
  slim?: boolean; // do we only have two rows?
  onKey?: KeyHandler;
};

/**
 * KeyPage: Page of keys that are layed out in a grid.
 */
export const KeyPage: React.FC<KeyPageProps> = ({ children, slim, onKey }) => (
  <div className={styles.keyPage}>
    {children}
    <SpecialKeys onKey={onKey} slim={slim} />
  </div>
);

KeyPage.displayName = 'KeyPage';
