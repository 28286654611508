import * as React from 'react';
import styles from './LoadingScreenLock.scss';
import { LoaderScreen } from '../../Loader';

interface LoadingScreenLockProps {
  show: boolean;
  appearanceDelay?: number;
}

export const LoadingScreenLock: React.FC<LoadingScreenLockProps> = (props) => {
  const { show, appearanceDelay = 500 } = props;
  const [timeoutShow, setTimeoutShow] = React.useState(false);

  React.useEffect(() => {
    if (show) {
      const id = setTimeout(() => setTimeoutShow(true), appearanceDelay);

      return () => clearTimeout(id);
    }
    setTimeoutShow(false);
  }, [show, appearanceDelay, timeoutShow]);

  return timeoutShow ? <LoaderScreen className={styles.loaderScreen} /> : null;
};
