import * as React from 'react';
import { Key, type KeyHandler } from './Key';
import { KeyCode } from './keyCodes';

export type CharBlockProps = {
  size?: 'medium' | 'large' | 'extralarge';
  onKey?: KeyHandler;
};

const _makeKey = (onKey?: KeyHandler) => (keyCode: KeyCode, i: number) =>
  (
    <Key
      key={keyCode === KeyCode.Empty ? `${KeyCode.Empty}-${i}` : keyCode}
      keyCode={keyCode}
      iconProps={{ onKey }}
    />
  );

/**
 * CharBlock: CharBlock
 */
export const CharBlock: React.FC<CharBlockProps> = ({ size = 'medium', onKey }) => {
  let keys: string[];
  switch (size) {
    case 'medium':
      keys = 'xy'.split('');
      // keys = [...'ap'.split(''), KeyCode.Empty, ...'bqvcrxhsymtznu'.split(''), KeyCode.Empty];
      break;
    case 'large':
      keys = [...'xy'.split(''), KeyCode.Empty];
      // keys = [...'arbscthumvnxpyqz'.split(''), KeyCode.Empty];
      break;
    case 'extralarge':
      keys = 'xy'.split('');
      // keys = 'arbscthumvnxpyqz'.split('');
      break;
    default:
      keys = [];
  }
  return <>{keys.map(_makeKey(onKey))}</>;
};

CharBlock.displayName = 'CharBlock';
