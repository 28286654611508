import * as React from 'react';
import { isEmpty } from 'lodash';

import {
  type Bar as BarContent,
  type ContentReference,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import { Bar as BarComponent } from './Bar';
import { DEFAULT_BAR_GAP, DEFAULT_BAR_WIDTH } from '../../defaults';
import { ReadingHelp, readingHelpCoords } from './ReadingHelp';

export type BarGroupProps = {
  xOffset: number;
  disabled: boolean;
  items: BarContent[];
  yTickValueInterval: number;
  yTickValueStart: number;
  height: number;
  axisContraction: number;
  label?: ContentReference;
  onMouseDown?: (evt: MouseOrTouch) => (item: number) => void;
  onHandleOver?: (item: number) => void;
  onHandleLeave?: (item: number) => void;
};

export const BarGroup: React.FC<BarGroupProps> = ({
  axisContraction,
  disabled,
  height,
  items,
  onMouseDown,
  onHandleOver,
  onHandleLeave,
  xOffset,
  yTickValueInterval,
  yTickValueStart,
}) => {
  return (
    <g>
      {items.map((i, idx) => {
        const x = idx * DEFAULT_BAR_WIDTH + idx * DEFAULT_BAR_GAP;
        return (
          <BarComponent
            key={`Bar_${idx}`}
            color={i.color}
            yTick={(i.yValue - yTickValueStart) / yTickValueInterval}
            height={height}
            interactive={!disabled && !isEmpty(i.annotationInner.$interactionType)}
            x={Math.ceil(xOffset + x)}
            axisContraction={axisContraction}
            severity={i.severity}
            {...(i.active && { active: i.active })}
            onMouseDown={onMouseDown && ((evt) => onMouseDown(evt)(idx))}
            onHandleOver={onHandleOver && (() => onHandleOver(idx))}
            onHandleLeave={onHandleLeave && (() => onHandleLeave(idx))}
          />
        );
      })}
      {items.map((i, idx) => {
        return i.readingHelp || i.severity ? (
          <ReadingHelp
            key={`ReadingHelp_${idx}`}
            severity={i.severity}
            {...{ idx }}
            {...readingHelpCoords(
              idx,
              i.yValue,
              xOffset,
              height,
              yTickValueStart,
              yTickValueInterval
            )}
          />
        ) : (
          ''
        );
      })}
    </g>
  );
};

BarGroup.displayName = 'BarGroup';
