import {
  validateStep as validateStepUnstubable,
  type ValidatorConfig,
  ValidatorEngine,
  type ValidatorExercise,
  type ValidatorFeedback,
  type ValidatorStep,
  type ValidatorStepResponse,
  ValidatorType,
  Validity,
} from '@bettermarks/umc-kotlin';
import log from 'loglevel';
import { importContentTree as _importContentTree, importers } from '@bettermarks/importers';
import { rulers } from '../../gizmo-utils/configuration/rulers';
import { stylers } from '../../gizmo-utils/configuration/stylers';
import { enrichContentDict as enrichContentDictUnstubable } from '../../gizmo-utils/measure';
import { isUndefined, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import {
  isMathValidatorPayload,
  type NextStep,
  type NlpValidatorPayload,
  type TestValidationPayload,
  type ValidationPayload,
} from '../store/types';
import {
  type AppExercise,
  type AppStep,
  CollapsibleContentState,
  ExerciseStatus,
  ExerciseValidity,
  type IAppStep,
  SeriesMode,
  type StepFeedback,
  StepStatus,
  type ValidatorUrlMap,
} from '../../types';
import {
  type Content,
  ContentDict,
  createExporterContext as createExporterContextUnstubable,
  DependencyCollector,
  exportContent as exportContentUnstubable,
  type NLPTextInputContent,
  toXmlElement,
} from '@bettermarks/gizmo-types';
import { type ValidateStepPayload } from './containers/Series/seriesSaga';
import {
  type ExtendedValidatorResponse,
  extendValidatorResponse,
  type NlpValidatorResponse,
} from './containers/Series/ExtendedValidatorResponse';
import { NLP_TEXT } from '@bettermarks/gizmo-types';
import { Request } from './services/api/bm-api';
import { type AxiosResponse } from 'axios';
import { createValidationPayload, showSolutionResponse } from './helper';
import { parseAnnotatedAnswerAndFeedbacks } from './validationHelpers.helpers';

export const STUBABLE = {
  enrichContentDict: enrichContentDictUnstubable,
  exportContent: exportContentUnstubable,
  createExporterContext: createExporterContextUnstubable,
  validateStep: validateStepUnstubable,
};

/**
 * Based on validity get the display options
 * @param {Validity} validity
 * @param {StepStatus} stepStatus
 * @param mode: Series mode to update the explanation/solution
 * @returns {explanationState: CollapsibleContentState; solutionState: CollapsibleContentState}
 * @private
 */
export const getDisplayOptions = (validity: Validity, stepStatus: StepStatus, mode: SeriesMode) => {
  let explanationState = CollapsibleContentState.hidden;
  let solutionState = CollapsibleContentState.hidden;
  const isTestMode = mode === SeriesMode.test;
  if (stepStatus === StepStatus.completed) {
    switch (validity) {
      case Validity.correct:
        // Explanation is hidden in test mode & collapsed in practice mode.
        explanationState = isTestMode
          ? CollapsibleContentState.hidden
          : CollapsibleContentState.collapsed;
        break;
      case Validity.valid:
        // Explanation & solution always hidden.
        break;
      case Validity.wrong:
        // Explanation is hidden in test mode but expanded in practice mode.
        explanationState = isTestMode
          ? CollapsibleContentState.hidden
          : CollapsibleContentState.expanded;
        // Solution is always shown.
        solutionState = CollapsibleContentState.expanded;
        break;
      default:
    }
  }
  return { explanationState, solutionState };
};

/**
 * Imports and enriches a single validator feedback.
 *
 * For modifying the ImporterRegistry or DependencyCollector to use,
 * pass an instance of ContentTreeImporter as the last argument.
 * By default a proper one for the scope of this method call will be created.
 *
 * @param {ValidatorFeedback} validatorFeedback
 * @param {string} pathToFeedback prefix for keys in ContentDict
 * @param {ContentTreeImporter} importTree
 * @returns StepFeedback
 */
export const _importFeedback = (
  { id, key, severity, textXML, learningObjectiveId }: ValidatorFeedback,
  pathToFeedback: string,
  importTree = _importContentTree(importers, new DependencyCollector())
): StepFeedback => {
  // This check comes from the context we are working on. It may happen, in qaMode, that a
  // specific key is not available, as we are not working with a real feedback, but making it up
  // Invalid keys are set to undefined to handle in component correctly
  const i18nKey = isUndefined(key) || key.startsWith(':') ? undefined : `feedbacks:${key}`;

  if (textXML) {
    const feedbackDict = importTree(toXmlElement(textXML), pathToFeedback);
    (feedbackDict[pathToFeedback] as Content).severity = severity;

    const feedback = STUBABLE.enrichContentDict(feedbackDict, stylers, rulers, true);
    return {
      id,
      ...(i18nKey && { key: i18nKey }),
      severity,
      learningObjectiveId,
      contentDict: feedback,
    };
  } else {
    return {
      id,
      ...(i18nKey && { key: i18nKey }),
      severity,
      learningObjectiveId,
    };
  }
};

export const parseNextStepResponse = (
  { questionXML, answerXML, id }: ValidatorStepResponse,
  pathToNextStep: string,
  importTree = _importContentTree(importers, new DependencyCollector())
): NextStep => {
  const questionDict = importTree(toXmlElement(questionXML), `${pathToNextStep}.question`);
  const question = STUBABLE.enrichContentDict(questionDict, stylers, rulers, false);
  const answerDict = importTree(toXmlElement(answerXML), `${pathToNextStep}.answer`);
  const answer = STUBABLE.enrichContentDict(answerDict, stylers, rulers, true);

  return {
    answerXML,
    answer,
    questionXML,
    question,
    id,
  };
};

export type ParsedResponse = {
  question: ContentDict;
  feedbacks: StepFeedback[];
};

export const getExerciseValidity = (
  exercise: AppExercise,
  status: ExerciseStatus
): ExerciseValidity | undefined => {
  const { steps, validity } = exercise;
  // If there is any step with validity wrong
  if (status === ExerciseStatus.review || status === ExerciseStatus.completed) {
    // filtering out undefined from steps array
    const filteredSteps = steps.filter(Boolean);
    if (filteredSteps.length !== steps.length) {
      log.warn({
        message: 'Undefined in step array of getExerciseValidity',
        extra: {
          filteredStepsLength: filteredSteps.length,
          stepsLength: steps.length,
          exerciseId: exercise.id,
        },
      });
    }
    if (filteredSteps.some((step) => step.validity === Validity.wrong)) {
      return ExerciseValidity.wrong;
    } else {
      // All are correct but atleast one has number of errors > 0
      if (filteredSteps.some((step) => step.numberOfErrors !== 0)) {
        return ExerciseValidity.sufficient;
      } else {
        // All were answered correctly in first step.
        return ExerciseValidity.correct;
      }
    }
  } else {
    return validity;
  }
};

/**
 * Pick what's necessary from the app exercises steps and
 * make sure steps are not empty.
 * If a step is empty (e.g. {}), it crashes the validator.
 *
 * @param appExerciseSteps Application exercise step list
 * @param validatorEngine validator engine for current step
 */
export const toValidatorSteps = <T>(
  appExerciseSteps: readonly Readonly<IAppStep>[],
  validatorEngine = ValidatorEngine.mathcore
): T[] => {
  const clearEmptyStep = (steps: ValidatorStep[]) => steps.filter((step) => !!step.id);
  const pickSteps = (steps: readonly Readonly<IAppStep>[]): T[] =>
    steps.map(
      (step) =>
        pick(step, [
          'id',
          'title',
          'maxErrors',
          'mandatory',
          'skill',
          'type',
          'validation',
          ...(validatorEngine === ValidatorEngine.nlp ? ['question'] : []),
          ...(validatorEngine === ValidatorEngine.mathcore ? ['answerXML', 'questionXML'] : []),
        ]) as T
    );
  return pipe(pickSteps, clearEmptyStep)(appExerciseSteps);
};

export type NLPValidatorRequest = {
  exercise: NLPValidatorExercise;
  stepId: string;
  testMode: boolean;
  userAnswer: Content;
  contentDict: ContentDict;
  nlpTextInputContents: Content[];
  numberOfErrors: number;
  maxErrors: number;
};

export type NLPValidatorExercise = {
  id: string;
  locale: string;
  exerciseType: string;
  featuresTimestamp: string;
  steps: NLPValidatorStep[];
};

export type NLPValidatorStep = {
  id: string;
  maxErrors: number;
  mandatory: boolean;
  skill: string;
  type: string;
  validation: any;
  question: ContentDict;
};

/**
 * Prepares the payload for validation
 * @param {AppExercise} appExercise
 * @param {ContentDict} contentDict
 * @param {string} stepId
 * @param {number} numberOfErrors
 * @param {number} maxErrors
 * @param {boolean} testMode
 * @param {string} userAnswerXML Optional xml to validate instead of `contentDict`
 *
 * @returns ValidatorRequest
 */
export const createNlpValidationPayload = (
  appExercise: AppExercise,
  contentDict: ContentDict,
  stepId: string,
  numberOfErrors: number,
  maxErrors: number,
  testMode: boolean
): NLPValidatorRequest => {
  // Create api payload
  const steps: NLPValidatorStep[] = toValidatorSteps<NLPValidatorStep>(
    appExercise.steps,
    ValidatorEngine.nlp
  );
  const exercise: ValidatorExercise | NLPValidatorExercise = {
    ...pick(appExercise, 'id', 'locale', 'exerciseType', 'featuresTimestamp'),
    steps,
  };

  return {
    exercise,
    stepId,
    testMode,
    numberOfErrors,
    maxErrors,
    contentDict,
    userAnswer: contentDict[ContentDict.root(contentDict)] as Content,
    nlpTextInputContents: Object.keys(contentDict).reduce(
      (acc, key) => [
        ...acc,
        ...(contentDict[key]?.$interactionType === NLP_TEXT ? [contentDict[key] as Content] : []),
      ],
      []
    ),
  };
};

export async function getValidatorResponse(
  { exercise, step, question }: ValidateStepPayload,
  mode: SeriesMode,
  validation: Nullable<string>,
  validatorUrls: ValidatorUrlMap,
  showSolution: boolean
): Promise<ExtendedValidatorResponse> {
  if (showSolution) {
    return showSolutionResponse(step, exercise);
  } else {
    const validatorEngine = step.validatorEngine;

    // console.log(JSON.stringify(validatorRequest));
    const validatorConfiguration = validatorConfig(validation, validatorUrls, step.validatorEngine);

    let validatorRequest: any;

    try {
      if (validatorEngine === ValidatorEngine.nlp) {
        validatorRequest = createNlpValidationPayload(
          exercise,
          question,
          step.id,
          step.numberOfErrors,
          step.maxErrors,
          mode === SeriesMode.test
        );
        return extendValidatorResponse(
          (
            (await Request.post(
              validatorUrls[ValidatorEngine.nlp],
              validatorRequest,
              true
            )) as AxiosResponse<NlpValidatorResponse>
          ).data
        );
      } else {
        validatorRequest = createValidationPayload(
          exercise,
          question,
          step.id,
          step.numberOfErrors,
          mode === SeriesMode.test
        );
        const validatorResponse = await STUBABLE.validateStep(
          validatorRequest,
          validatorConfiguration
        );

        return extendValidatorResponse(validatorResponse);
      }
    } catch (e) {
      // We don't expect this, but in case it happens we need the input in our logs
      log.error({
        message: `Validation failed with '${e.message}'`,
        extra: {
          validatorRequest: JSON.stringify(validatorRequest),
          validatorConfiguration,
        },
      });
      // re-throw to not change behavior for now, could also be treated specially
      throw e;
    }
  }
}

function validatorConfig(
  validation: Nullable<string>,
  validatorUrls: ValidatorUrlMap,
  validatorEngine: ValidatorEngine
): ValidatorConfig {
  switch (validatorEngine) {
    case ValidatorEngine.nlp: {
      return {
        validatorType: ValidatorType.server,
        endpoint: validatorUrls[ValidatorEngine.nlp],
      };
    }
    case ValidatorEngine.mathcore:
    default: {
      switch (validation || ValidatorType.client) {
        case ValidatorType.client:
          return { validatorType: ValidatorType.client };

        case ValidatorType.webworker:
          return { validatorType: ValidatorType.webworker };

        case ValidatorType.native:
          return { validatorType: ValidatorType.native };

        case ValidatorType.server:
          return {
            validatorType: ValidatorType.server,
            endpoint: validatorUrls.mathcore,
          };

        default:
          // server url
          return {
            validatorType: ValidatorType.server,
            endpoint: validation ?? undefined,
          };
      }
    }
  }
}

const fromNlpResponse = (
  validationPayload: NlpValidatorPayload,
  step: AppStep
): { question: ContentDict; feedbacks: ValidatorFeedback[] } => {
  let question = step.question.present;
  validationPayload.annotatedNlpTextInputContents.forEach((c: NLPTextInputContent) => {
    question = {
      ...question,
      [c.refId]: c,
    };
  });
  return {
    question,
    feedbacks: validationPayload.feedbacks,
  };
};

export const questionAndFeedbackFromValidationPayload = (
  validationPayload: ValidationPayload | TestValidationPayload,
  currentStepPath: string,
  currentStep: AppStep
) =>
  isMathValidatorPayload(validationPayload)
    ? parseAnnotatedAnswerAndFeedbacks(validationPayload, currentStepPath)
    : fromNlpResponse(validationPayload as NlpValidatorPayload, currentStep);
