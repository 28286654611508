import { type BoxContent, type BoxDecoration, type Importer } from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../gizmo-utils/decoration';
import { DEFAULT_DECORATION } from './constants';

/**
 * This is used to parse the decoration string from the xml, if no decoration is there
 * default decoration is taken.
 * @param decoString
 */
const parseDecoration = (decoString: string): BoxDecoration => {
  const {
    object: { width, height, backgroundColor },
  } = parseDecoString<keyof BoxDecoration>(decoString);

  const decoration = { ...DEFAULT_DECORATION };
  if (width) {
    decoration.width = parseFloat(width);
  }
  if (height) {
    decoration.height = parseFloat(height);
  }
  if (backgroundColor) {
    decoration.backgroundColor = backgroundColor;
  }
  return decoration;
};
/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 * @param preContent
 * @param xml
 */
export const importBox: Importer<BoxContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const decoration: BoxDecoration = parseDecoration(mrow.attribute('decoration'));
  return {
    ...preContent,
    decoration,
    // props of a scalable gizmo
    unscaledWidth: decoration.width,
    unscaledHeight: decoration.height,
  };
};
