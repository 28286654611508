import { type Action, createAction, handleActions } from 'redux-actions';

export const OVER = 'OVER';
export const OUT = 'OUT';
export const TOGGLE_SEGMENT_LOCAL = 'TOGGLE_SEGMENT_LOCAL';

export const over = createAction<number>(OVER);
export const out = createAction(OUT);
export const toggleSegmentLocal = createAction<number>(TOGGLE_SEGMENT_LOCAL);

export type ToggleFillState = {
  hoverIndex?: number;
};

export const initialState: ToggleFillState = {};

export const toggleFillReducer = handleActions<ToggleFillState, void | number>(
  {
    [OVER]: (state, { payload: hoverIndex }: Action<number>) => ({
      ...state,
      hoverIndex,
    }),
    // sorry for not writing
    //   ({hoverIndex, ...state}) => state
    // but that won't work, see https://reactjs.org/docs/react-component.html#setstate
    [OUT]: (state) => ({ ...state, hoverIndex: undefined }),
    [TOGGLE_SEGMENT_LOCAL]: (state) => ({ ...state, hoverIndex: undefined }),
  },
  initialState
);
