import * as React from 'react';
import { type ContextState, useStaticMediaUrl } from '../../gizmo-utils/polymorphic-gizmo';
import { getMediaUrl } from '@bettermarks/importers';
import { MediaPlayer } from '../../components/MediaPlayer';
import { type AnimationContent } from '@bettermarks/gizmo-types';

const BORDER = 20; // 20px border!
const MINWIDTH = 200; // 200px min width ...

export type AnimationProps = AnimationContent & ContextState;

export const Animation: React.FC<AnimationProps> = ({ source, width, height, availableWidth }) => {
  const maxWidth = Math.max(availableWidth - BORDER, MINWIDTH);
  const staticMediaUrl = useStaticMediaUrl();
  const scale = maxWidth < width ? maxWidth / width : 1;
  return (
    <MediaPlayer
      src={getMediaUrl(`${source}.html`, staticMediaUrl)}
      {...{ width, height, scale }}
    />
  );
};

Animation.displayName = 'Animation';
