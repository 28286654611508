import * as React from 'react';
import { getDimensions } from './dimensionCalculator';
import { type ControlOrientation, ControlPosition, type Slider } from '@bettermarks/gizmo-types';
import { SlidersContainer } from '../components';
import { isEmpty, noop } from 'lodash/fp';
import { PolymorphicGizmo, useValueSetterContext } from '../../gizmo-utils/polymorphic-gizmo';
import { OnOffControls, OptionsControl } from './Controls';
import { type DynamicRepresentationProps, extractMinOrMax } from './DynamicRepresentation';
import { numberFromStyles } from '@seriesplayer/common-ui';
import componentStyles from 'styles/responsive.scss';
import sliderStyles from '../../components/Slider/Slider.scss';

const REPRESENTATION_MIN_WIDTH = numberFromStyles(componentStyles.VERY_SMALL_SCREEN_WIDTH);
const SLIDER_MAX_LENGTH = numberFromStyles(sliderStyles.SLIDER_RAIL_MAX_LENGTH);
const REPRESENTATION_MARGIN = 30;
const SLIDER_MIN_WIDTH = 300;
const SLIDER_OFFSET_RIGHT = 30;

export type DynamicRepresentationWithControlsProps = Omit<
  DynamicRepresentationProps,
  'valueSteppers' | 'valueSetterMap' | '$' | '$renderStyle'
> & { onControlValueChange: (id: string) => (value: number) => void };

export const DynamicRepresentationWithControls: React.FC<
  DynamicRepresentationWithControlsProps
> = ({
  sliders,
  onOffControls,
  optionsControls,
  representation,
  availableWidth,
  disabled,
  isTouch,
  $interactionType,
  onPersistDynamicValue,
  onControlValueChange,
}) => {
  const { valueSetterMap } = useValueSetterContext();

  const _leftSliders = sliders.filter((slider) => slider.position === 'left');
  const _rightSliders = sliders.filter((slider) => slider.position === 'right');

  const _leftOnOffControls = onOffControls.filter((c) => c.position === 'left');
  const _rightOnOffControls = onOffControls.filter((c) => c.position === 'right');

  const _leftOptionsControls = optionsControls.filter((c) => c.position === 'left');
  const _rightOptionsControls = optionsControls.filter((c) => c.position === 'right');

  const [availableLeft, availableMiddle, availableRight, canFitLeft, canFitRight] = React.useMemo(
    () =>
      getDimensions(
        availableWidth,
        REPRESENTATION_MIN_WIDTH,
        [..._leftSliders, ..._leftOnOffControls, ..._leftOptionsControls].length !== 0
          ? SLIDER_MIN_WIDTH
          : 0,
        [..._rightSliders, ..._rightOnOffControls, ..._rightOptionsControls].length !== 0
          ? SLIDER_MIN_WIDTH
          : 0,
        REPRESENTATION_MARGIN
      ),
    [availableWidth]
  );

  const leftSliders = canFitLeft ? _leftSliders : [];
  const rightSliders = canFitRight ? _rightSliders : [];

  // If sliders do not fit right or left, they go into the bottom
  const bottomSliders = sliders
    .filter((slider) => slider.position === 'bottom')
    .concat(canFitLeft ? [] : _leftSliders)
    .concat(canFitRight ? [] : _rightSliders);

  const sliderContainer = (
    slidersData: Slider[],
    sliderWidth: number,
    // this is here, so the slider can infer the handleSite property
    position: ControlPosition,
    // if the orientation is set, it will override all the preset slider orientations
    sliderOrientation?: ControlOrientation
  ) => (
    <SlidersContainer
      slidersData={slidersData.map((s) => ({
        name: s.binding,
        minimum: extractMinOrMax(s.min, valueSetterMap),
        maximum: extractMinOrMax(s.max, valueSetterMap),
        step: s.snapInterval,
        value: valueSetterMap[s.binding].value,
        unitValue: valueSetterMap[s.binding].value,
        label: s.label,
        decoration: s.decoration,
        refId: s.binding,
        showValue: s.showValue ?? false,
        sliderOrientation: sliderOrientation ? sliderOrientation : s.orientation ?? 'horizontal',
      }))}
      position={position}
      selectable={!isEmpty($interactionType) && !disabled}
      disabled={disabled}
      isTouch={isTouch}
      onSetIntermediateParam={onControlValueChange}
      availableSize={sliderWidth}
      // ToDo: This decides if sliders are displayed vertically
      //  calculate from remaining width after subtracting actual width of representation
      //  i.e. three step responsiveness
      //    1. slider widths shrinks to min
      //    2. slider goes into vertical mode
      //    3. slider floats below representation and goes back into horizontal mode
      isSmallScreen={false}
      onFocusParam={(_: string) => noop}
      onBlurParam={(_: string) => noop}
      onSetParam={onPersistDynamicValue}
    />
  );

  const leftOnOffControls = canFitLeft ? _leftOnOffControls : [];
  const rightOnOffControls = canFitRight ? _rightOnOffControls : [];
  const bottomOnOffControls = (canFitLeft ? [] : _leftOnOffControls)
    .concat(canFitRight ? [] : _rightOnOffControls)
    .concat(onOffControls.filter((c) => c.position === 'bottom'));

  const leftOptionsControls = canFitLeft ? _leftOptionsControls : [];
  const rightOptionsControls = canFitRight ? _rightOptionsControls : [];
  const bottomOptionsControls = (canFitLeft ? [] : _leftOptionsControls)
    .concat(canFitRight ? [] : _rightOptionsControls)
    .concat(optionsControls.filter((c) => c.position === 'bottom'));

  const hasLeft = [...leftSliders, ...leftOnOffControls, ...leftOptionsControls].length > 0;
  const hasRight = [...rightSliders, ...rightOnOffControls, ...rightOptionsControls].length > 0;
  const hasBottom = [...bottomSliders, ...bottomOnOffControls, ...bottomOptionsControls].length > 0;
  const centerColumn = hasLeft ? 2 : 1;
  const rightColumn = hasLeft ? 3 : 2;

  return (
    <div style={{ display: 'grid', columnGap: REPRESENTATION_MARGIN, rowGap: 15 }}>
      {hasLeft && (
        <div style={{ maxWidth: availableLeft, gridColumn: 1, gridRow: 1 }}>
          {leftSliders.length > 0 &&
            sliderContainer(leftSliders, availableLeft, ControlPosition.left)}
          {leftOnOffControls.length > 0 && (
            <OnOffControls
              controls={leftOnOffControls}
              position={ControlPosition.left}
              availableWidth={availableLeft}
              otherControlAbove={leftSliders.length > 0}
              onPersistControlValue={onPersistDynamicValue}
            />
          )}
          {leftOptionsControls.length > 0 &&
            leftOptionsControls.map((oc) => (
              <OptionsControl
                control={oc}
                position={ControlPosition.left}
                availableWidth={availableLeft}
                otherControlAbove={leftSliders.length > 0 || leftOnOffControls.length > 0}
                onPersistControlValue={onPersistDynamicValue}
              />
            ))}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: availableMiddle,
          gridColumn: centerColumn,
          gridRow: '1',
        }}
      >
        <PolymorphicGizmo refid={representation.$refid} availableWidth={availableMiddle} />
      </div>
      {hasRight && (
        <div style={{ maxWidth: availableRight, gridColumn: rightColumn, gridRow: '1' }}>
          {rightSliders.length > 0 &&
            sliderContainer(rightSliders, availableRight, ControlPosition.right)}
          {rightOnOffControls.length > 0 && (
            <OnOffControls
              controls={rightOnOffControls}
              position={ControlPosition.right}
              availableWidth={availableRight}
              otherControlAbove={rightSliders.length > 0}
              onPersistControlValue={onPersistDynamicValue}
            />
          )}
          {rightOptionsControls.length > 0 &&
            rightOptionsControls.map((oc) => (
              <OptionsControl
                control={oc}
                position={ControlPosition.right}
                availableWidth={availableRight}
                otherControlAbove={rightSliders.length > 0 || rightOnOffControls.length > 0}
                onPersistControlValue={onPersistDynamicValue}
              />
            ))}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          gridColumn: centerColumn,
          gridRow: 2,
          justifyContent: 'center',
        }}
      >
        {hasBottom && (
          <div
            style={{
              maxWidth: Math.min(SLIDER_MAX_LENGTH, availableMiddle - SLIDER_OFFSET_RIGHT),
            }}
          >
            {bottomSliders.length > 0 &&
              sliderContainer(
                bottomSliders,
                Math.min(SLIDER_MAX_LENGTH, availableMiddle - SLIDER_OFFSET_RIGHT),
                ControlPosition.bottom,
                'horizontal'
              )}
            {bottomOnOffControls.length > 0 && (
              <OnOffControls
                controls={bottomOnOffControls}
                position={ControlPosition.bottom}
                availableWidth={Math.min(SLIDER_MAX_LENGTH, availableMiddle - SLIDER_OFFSET_RIGHT)}
                otherControlAbove={bottomSliders.length > 0}
                onPersistControlValue={onPersistDynamicValue}
              />
            )}
            {bottomOptionsControls.length > 0 &&
              bottomOptionsControls.map((oc) => (
                <OptionsControl
                  control={oc}
                  position={ControlPosition.bottom}
                  availableWidth={Math.min(
                    SLIDER_MAX_LENGTH,
                    availableMiddle - SLIDER_OFFSET_RIGHT
                  )}
                  otherControlAbove={bottomSliders.length > 0 || bottomOnOffControls.length > 0}
                  onPersistControlValue={onPersistDynamicValue}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
