import { styleName } from '../../../../components/_utils';
import * as React from 'react';
import { type Severity } from '@bettermarks/umc-kotlin';

import { onClickLeaf } from './helpers';
import { type LeafProps } from './types';

import styles from './MathML.scss';

export type PlaceHolderProps = LeafProps & {
  severity?: Severity;
};

/**
 * This renders children for placeholder label or a block glyph if no children are passed.
 * Use `severity` to use predefined coloring for the glyph.
 */
export const Placeholder: React.FC<PlaceHolderProps> = ({
  children,
  computedStyles,
  onSelect,
  severity = 'default',
}) => (
  <span
    className={styles[styleName('placeholder', severity)]}
    style={computedStyles}
    onMouseDown={onClickLeaf(onSelect)}
    role="button"
  >
    {children}
  </span>
);

Placeholder.displayName = 'Placeholder';
