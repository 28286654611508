import { CLOCK } from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type Content, type Defaults } from '../../xml-converter/core';

export interface ClockGizmoContent extends Readonly<Content> {
  /**
   * Hour offset to be shown on the clock [1 - 24]
   */
  hour: number;

  /**
   * Minute offset to be shown on the clock [1 - 60]
   */
  minute: number;
}

/**
 * Default Clock content set to:
 *  - 13 hour
 *  - 42 minutes
 */
export const DEFAULT_CLOCK_GIZMO_CONTENT: Defaults<ClockGizmoContent> = {
  $: SPECIAL,
  $renderStyle: CLOCK,
  hour: 13,
  minute: 42,
};
