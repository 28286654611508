import {
  type BalanceContent,
  BalanceValue,
  type Defaults,
  type FElement,
  type Importer,
  RS,
  SPECIAL,
} from '@bettermarks/gizmo-types';
import { defaultTo } from 'lodash';

export const BALANCE_DEFAULT_CONTENT: Defaults<BalanceContent> = {
  $: SPECIAL,
  $renderStyle: RS.BALANCE,
  balance: BalanceValue.BALANCED,
  leftOneBullets: 0,
  leftXBullets: 0,
  rightOneBullets: 0,
  rightXBullets: 0,
};

/**
 * Returns the number of bullets present in each of the bullet name passed,
 * otherwise returns the default values respectively
 * @param configuration Accepts configuration of the type FElement
 * @param bulletString string of the bullet whose number of bullets present is to be extracted
 */

export const extractBullets = (configuration: FElement, bulletString: string): number => {
  return defaultTo<number>(parseInt(configuration.findChildTag(bulletString).text, 10), 0);
};

/**
 * Converts XML data to the content of the balance gizmo.
 * It contains an object which has balance(mandatory value)
 * which can be balanced | rightDown | leftDown,
 * leftOneBullets: Number of 'One' Bullets on the left, defaults to 0,
 * leftXBullets: Number of 'X' Bullets on the left, defaults to 0.
 * rightOneBullets: Number of 'One' Bullets on the right, defaults to 0,
 * rightXBullets: Number of 'X' Bullets on the right, defaults to 0,
 * @param preContent
 * @param xml
 */
export const importBalance: Importer = (preContent, xml): BalanceContent => {
  const mrow = xml.findChildTag('mrow');
  const config = mrow.findChildTag('configuration');
  const balance = config.findChildTag('balance').text as BalanceValue;
  const [leftOneBullets, leftXBullets, rightOneBullets, rightXBullets] = [
    'left-one-bullets',
    'left-x-bullets',
    'right-one-bullets',
    'right-x-bullets',
  ].map((t) => extractBullets(config, t));

  return {
    ...preContent,
    balance,
    leftOneBullets,
    leftXBullets,
    rightOneBullets,
    rightXBullets,
  };
};
