import { createAction } from 'redux-actions';
import { type BarChartGroup, type Slice } from '@bettermarks/gizmo-types';

/* ----- local reducer ----- */
export const MOVE = 'MOVE';
export const START = 'START';
export const END = 'END';
export const HANDLE_HOVER = 'HANDLE_HOVER';

export type BarChartStartPayload = {
  group: number;
  item: number;
};

export type BarChartMovePayload = number;

export const start = createAction<BarChartStartPayload>(START);
export const move = createAction<BarChartMovePayload>(MOVE);
export const end = createAction(END);
export const handleHover = createAction<boolean>(HANDLE_HOVER);

export type StackedBarChartStartPayload = number;
export type StackedBarChartMovePayload = number;
export const stackedBarMove = createAction<StackedBarChartMovePayload>(MOVE);
export const stackedBarStart = createAction<StackedBarChartStartPayload>(START);

/* ----- refactor later ----- */
export type PieChartStartPayload = number;
export type PieChartMovePayload = number;
export const pieChartMove = createAction<PieChartMovePayload>(MOVE);
export const pieChartStart = createAction<PieChartStartPayload>(START);

/* ----- global reducer ----- */
export const PERSIST = 'PERSIST';

export type BarChartPersistPayload = BarChartGroup[];
export type PieOrStackedBarChartPersistPayload = Slice[];

export const persist = createAction<BarChartPersistPayload | PieOrStackedBarChartPersistPayload>(
  PERSIST
);
