import {
  ALIGN,
  annotationInner,
  type ContentReference,
  DECORATION,
  type Digit,
  exportContent,
  type Exporter,
  type ExporterContext,
  type SquaredPaperLine as Line,
  semantics,
  type SquaredPaperContent,
  SquaredPaperOrientation,
  SquaredPaperTypes,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { defaultTo } from 'lodash';
import { decorationToString } from '../../gizmo-utils/decoration';

function exportDecoration(digit: Digit): string {
  const { color, backgroundColor, fontWeight } = digit;
  return xmlAttr(DECORATION, decorationToString({ color, backgroundColor, fontWeight }));
}

function exportLineDecoration(line: Line): string {
  const { lineWeight } = line;
  return xmlAttr(DECORATION, decorationToString({ lineWeight }));
}

function exportLine(line: Line): string {
  const align = xmlAttr(ALIGN, line.align);
  const decoration = exportLineDecoration(line);
  const numberOfLines =
    line.style === SquaredPaperTypes.LineStyle.double
      ? `<sqpaper:numberOfLines>2</sqpaper:numberOfLines>`
      : '';

  const horizontal = line.coord.y1 === line.coord.y2;

  const y2 = line.coord.y2 - (horizontal ? 0 : 1);
  const x2 = line.coord.x2 - (horizontal ? 1 : 0);

  return `<sqpaper:line ${align}${decoration}
             orientation="${
               horizontal ? SquaredPaperOrientation.horizontal : SquaredPaperOrientation.vertical
             }">
          <sqpaper:pos x="${line.coord.x1}" y="${line.coord.y1}"/>
          <sqpaper:pos x="${x2}" y="${y2}"/>
          ${numberOfLines}
        </sqpaper:line>`;
}

function exportDigit(digit: Digit): string {
  const decoration = exportDecoration(digit);

  const align = xmlAttr(ALIGN, digit.align);
  const type = defaultTo(digit.type, 'simple');
  const { x, y } = digit;
  const value = digit.value ? `<sqpaper:value>${digit.value}</sqpaper:value>` : '';
  const crossedOut = digit.crossedOut ? '<sqpaper:crossedOut/>' : '';
  const decimalPoint = digit.decimalPoint ? '<sqpaper:decimalPoint/>' : '';
  return `<sqpaper:${type}Digit ${decoration}${align} x="${x}" y="${y}">
    ${value}${crossedOut}${decimalPoint}</sqpaper:${type}Digit>`;
}

const exportOverlays = (digits: Digit[], context: ExporterContext): string =>
  digits
    .filter((digit) => digit.overlay)
    .reduce((overlayStr, { x, y, overlay }) => {
      const content = exportContent((overlay as ContentReference).$refid, context);
      return `<sqpaper:overlay x="${x}" y="${y}">${content}</sqpaper:overlay>${overlayStr}`;
    }, '');

/**
 * Exports the Squared Paper Gizmo Content to XML Data
 * This function is registered in [[gizmo-utils/configuration/exporters]]
 * @param contentRefId
 * @param context
 */
export const exportSquaredPaper: Exporter = (contentRefId, context: ExporterContext): string => {
  const content = context.content[contentRefId] as SquaredPaperContent;
  return semantics(
    `<sqpaper:paper xmlns:sqpaper="http://bettermarks.com/mathcore/squaredpaper">
        <sqpaper:display width="${content.width}" height="${content.height}"/>
        <sqpaper:items>
          <sqpaper:digits>${content.digits.map(exportDigit).join('')}</sqpaper:digits>
          <sqpaper:lines>${content.lines.map(exportLine).join('')}</sqpaper:lines>
          <sqpaper:overlays>${exportOverlays(content.digits, context)}</sqpaper:overlays>
        </sqpaper:items>
    </sqpaper:paper>`,
    annotationInner(content.$, content)
  );
};
