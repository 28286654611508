import { omit as lodashOmit } from 'lodash/fp';

/**
 * This little helper allows us to use distributed union types.ts in the form of
 *
 * interface A {
 *   type: 'a';
 *   name?: string;
 *   acontent: AContent;
 * }
 *
 * interface B {
 *   type: 'b';
 *   name?: string;
 *   bcontent: string;
 * }
 *
 * type C = A | B;
 *
 * with omit. The builtin Omit type (and the lodash Omit type) do not distribute over union types.ts.
 * This results in the following:
 *
 * type D = Omit<C, 'name'>; // {type: 'a' | 'b'} instead of {type: 'a' | type: 'b'}
 *
 * Therefore you cannot use lodash's omit with a type like C.
 *
 * Alternatively you could rewrite your types.ts like
 *
 * interface C {
 *  type: 'a' | 'b';
 *  name? string;
 *  content?: AContent | string;
 * }
 *
 * (as recommended in https://github.com/Microsoft/TypeScript/issues/28791#issuecomment-443520161)
 * but this would be too much of a change and IMHO does not make things clearer.
 *
 */

export type AllKeys<T> = T extends T ? keyof T : never;
export type Omit2<T, K extends AllKeys<T>> = T extends T ? Pick<T, Exclude<keyof T, K>> : never;
interface LodashOmit {
  <T, K extends AllKeys<T>>(key: K): (obj: T) => Omit2<T, K>;
  <T, K extends AllKeys<T>>(key: K, obj: T): Omit2<T, K>;
}

export const omit: LodashOmit = lodashOmit;
