import * as React from 'react';
import { getOr } from 'lodash/fp';
import classNames from 'classnames';

export const enum DrawerState {
  Open = 'open',
  Switch = 'switch',
  Close = 'close',
}

export interface DrawerBaseProps {
  state: DrawerState;
}

export interface DrawerProps extends DrawerBaseProps {
  style?: React.CSSProperties;
  styles: Record<string, string>;
}

const getOrEmptyStr = getOr('');

export const getDrawerStyleForState = (
  state: DrawerState,
  styles: Record<string, string>,
  propPostfix = ''
): string => {
  switch (state) {
    case DrawerState.Open:
      return getOrEmptyStr(`drawerInAnimation${propPostfix}`, styles);
    case DrawerState.Switch:
      return getOrEmptyStr(`drawerOut${propPostfix}`, styles);
    case DrawerState.Close:
      return getOrEmptyStr(`drawerOutAnimation${propPostfix}`, styles);
    default:
      return '';
  }
};

export const Drawer: React.FC<DrawerProps> = ({ style, styles, state, children }) => (
  <div style={style} className={classNames(styles.drawer, getDrawerStyleForState(state, styles))}>
    {children}
  </div>
);

Drawer.displayName = 'Drawer';
