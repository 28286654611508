import { flatten } from 'lodash';
import {
  add,
  atan2,
  DEFAULT_ANGLE_RADIUS,
  DEFAULT_PREVLINE_DECORATION,
  degToRad,
  MIN_ANGLE_DRAWN,
  mod,
  numeq,
  numuniq,
  PREVANGLE_HIGH_DECORATION,
  PREVANGLE_LOW_DECORATION,
  radToDeg,
  rdN,
  round,
  sigSum,
  sub,
} from '@bettermarks/importers';
import {
  AngleType,
  type Coords,
  type GeoContentPersistProps,
  type LineObject,
  type LineObjectType,
  type PointObject,
  type PreviewAngle,
  STRAIGHTLINE,
  type ToolValueLabel,
} from '@bettermarks/gizmo-types';
import { getLineCoords, linesPointIsOn } from '../../helpers';

/**
 * adapt an angle as follows: if an angle a is ensured to be
 * between s and e and e > 360, then a is translated to
 * a + 360!!
 * @param {number} a
 * @param {number} s
 * @param {number} e
 * @returns {number}
 */
const adaptedAngle = (a: number, s: number, e: number): number => (e > 360 && a < s ? a + 360 : a);

/**
 * calculates some angle snapping based on a given snap angle,
 * and a 'baseline' array containing an angle.
 * @param {number} snap .. angle to snap
 * @param {number[]} angles in ascending order
 * @param {Coords} the reference point
 * @param {Coords} mouse position
 * @returns {[number, number, number, Coords]} a 'start' angle,
 * an 'end angle' (which is the 'start' angle' of the second
 * angle and a second 'end angle'. a new 'reference point' near
 * or identical to the current mouse position, where to draw a
 * preview line.
 */
const snapToAngles = (
  snap: number,
  angles: number[],
  ref: Coords,
  mouse: Coords
): [[number, number, number], Coords] => {
  const mouseV = sub(ref, mouse); // vector to 'mouse position'
  const mouseA = radToDeg(atan2(mouseV.y, mouseV.x)); // the according 'mouse angle'
  const segs = angles.map((a, i, l) => {
    // all 'segments' with their
    const hi = mod(i + 1, l.length); //
    const e = l[hi] <= a ? 360 + l[hi] : l[hi];
    const mouseAA = adaptedAngle(mouseA, a, e);
    return {
      idx: i, // - index
      s: a, // - start angle of baseline
      e: e, // - end angle of baseline
      dS: round(a - mouseAA, 4), // - diff angle start to mouse
      dE: round(e - mouseAA, 4), // - diff angle end to mouse
      valid: e - a >= snap + MIN_ANGLE_DRAWN, // - valid (can be snapped here?)
    };
  });

  const orderedSegs = [
    // We use all segments (doubled)
    ...segs.map((a) => ({ ...a, diff: a.dS })), // with their start 'diff' and
    ...segs.map((a) => ({ ...a, diff: a.dE })), // their end 'diff' both to always
  ]
    .sort(
      (
        a,
        b // get the right (valid) segment.
      ) =>
        Math.abs(a.diff) === Math.abs(b.diff) // ordered by 'diff' ... and for
          ? sigSum(a.dS, a.dE) - sigSum(b.dS, b.dE) // equal diffs, by some 'inside'
          : Math.abs(a.diff) - Math.abs(b.diff) // detecting flag ...
    )
    .filter((a) => a.valid); // take only 'valid' ones

  const seg = segs[orderedSegs[0].idx]; // ok, this is the correct segment!
  const mouseAA = adaptedAngle(mouseA, seg.s, seg.e);
  const [aDS, aDE] =
    mouseAA <= seg.s
      ? [0, seg.e - seg.s] // adapt difference angles dS and dE
      : mouseAA >= seg.e
      ? [seg.e - seg.s, 0] // in case of invalid segment and build
      : [Math.abs(seg.s - mouseAA), Math.abs(seg.e - mouseAA)];

  const maxSnap = aDS + aDE - MIN_ANGLE_DRAWN; // never snap to an angle > maxSnap

  const [snapS, snapE] = [aDS, aDE].map(
    (
      d // try out snap to start or to end
    ) => Math.max(snap, rdN(d, snap, maxSnap)) // and then check, which one 'fits'
  ); // better ...
  const [restS, restE] = [snapS, snapE].map((s) => aDS + aDE - s);
  const [dDS, dDE] = [
    // these are the differences used to
    [snapS - aDS, restS - aDE], // check, which sanpping fits better
    [snapE - aDE, restE - aDS],
  ].map((f) => Math.abs(f[0]) + Math.abs(f[1]));

  const angleS =
    dDS < dDE || restE < MIN_ANGLE_DRAWN // at last, the correct 'start'
      ? snapS
      : restE; // and 'end' angles ...
  const degTheta = seg.s + angleS; // theta is the abs. 'snapped angle'
  const theta = degToRad(degTheta);

  return [
    [round(seg.s, 1), round(degTheta, 1), round(seg.e, 1)], // the start, mid and end angle
    add(ref, { x: Math.cos(theta), y: Math.sin(theta) }), // and the brandnew "mouse pos"!
  ];
};

/**
 * helper: returns a well fitted 'preview line'
 * @param {number} scale .. Id of the 'central point'
 * @param {LineObjectType} prevLineObjectType .. Id of the 'central point'
 * @param {string} pointId .. Id of the 'central point'
 * @param {number} snapAngle .. how large is the angle to snap on?
 * @param {GeoContentPersistProps} persistProps .. the content map, rays, etc..
 * @param {Coords} mouse .. the mouse position
 * @param {AngleType} angleType
 * @returns {{prevLine: {p1: Coords; p2: Coords; visible: boolean; decoration: LineDecoration}}}
 */
export const previewLine = (
  scale: number,
  prevLineObjectType: LineObjectType,
  pointId: string,
  snapAngle: number,
  persistProps: GeoContentPersistProps,
  mouse: Coords,
  angleType: AngleType
) => {
  const { geoContentMap, rays, segments, straightlines, vectors } = persistProps;
  const p = (geoContentMap[pointId] as PointObject).coords;

  const angles = numuniq(
    flatten(
      linesPointIsOn(geoContentMap, p, [...rays, ...segments, ...straightlines, ...vectors]).map(
        (s: string) => {
          const l = geoContentMap[s] as LineObject;
          const p1 = (geoContentMap[l.p1Id] as PointObject).coords;
          const p2 = (geoContentMap[l.p2Id] as PointObject).coords;
          const q = numeq(p, p1) ? p2 : p1;
          const v = sub(p, q);
          const angle = radToDeg(atan2(v.y, v.x)); // ...construct abs. angles
          return l.type === STRAIGHTLINE || (!numeq(p, p2) && !numeq(p, p1)) // for special cases ...
            ? [angle, mod(180 + angle, 360)]
            : angle; // ... provide additional angle
        }
      )
    )
  ); // ... angles are already sorted ascending by the numuniq function

  const [[s, m, e], nearMouse] = snapToAngles(snapAngle, angles, p, mouse); // ... get the snapped stuff ...
  const [loRad, hiRad] = [scale * DEFAULT_ANGLE_RADIUS * 1.5, scale * DEFAULT_ANGLE_RADIUS * 1.1]; // ... label positioning stuff
  const [loAngle, hiAngle] = [
    degToRad(s + (m - s) / 2 - 4), // magic +-6 just for separating
    degToRad(m + (e - m) / 2 + 4), // near labels for small angles.
  ];

  const loPosRad = Math.max(loRad * 1.5, Math.min(40 / (m - s), 3));
  const hiPosRad = hiRad * 1.5;

  const [loPos, hiPos] = [
    [loPosRad, loAngle],
    [hiPosRad, hiAngle],
  ].map((t) => add(p, { x: t[0] * Math.cos(t[1]), y: t[0] * Math.sin(t[1]) }));
  const [prev1, prev2] = getLineCoords(p, nearMouse, prevLineObjectType);
  // ... to draw the preview line

  const counterClockwiseAngle = {
    coords: p,
    startAngle: s,
    endAngle: m,
    radius: loRad,
    hasAngleLegs: false,
    labelOffset: 3,
    visible: true,
    decoration: PREVANGLE_HIGH_DECORATION,
  };

  const clockwiseAngle = {
    coords: p,
    startAngle: m,
    endAngle: e,
    radius: hiRad,
    hasAngleLegs: false,
    labelOffset: 3,
    visible: true,
    decoration: PREVANGLE_LOW_DECORATION,
  };

  const counterClockwiseToolValueLabel = {
    content: `${round(m - s, 1).toString()}°`,
    coords: loPos,
    alternativeStyle: true,
  };

  const clockwiseToolValueLabel = {
    content: `${round(e - m, 1).toString()}°`,
    coords: hiPos,
    alternativeStyle: false,
  };

  const prevAngles: PreviewAngle[] = [];
  const toolValueLabels: ToolValueLabel[] = [];

  switch (angleType) {
    case AngleType.MIDDLE_180_180: {
      if (clockwiseAngle.endAngle - clockwiseAngle.startAngle <= 180) {
        prevAngles.push(clockwiseAngle);
        toolValueLabels.push(clockwiseToolValueLabel);
      }

      if (counterClockwiseAngle.endAngle - counterClockwiseAngle.startAngle <= 180) {
        prevAngles.push(counterClockwiseAngle);
        toolValueLabels.push(counterClockwiseToolValueLabel);
      }
      break;
    }
    case AngleType.RIGHT_360: {
      prevAngles.push(clockwiseAngle);
      toolValueLabels.push(clockwiseToolValueLabel);
      break;
    }
    case AngleType.LEFT_360: {
      prevAngles.push(counterClockwiseAngle);
      toolValueLabels.push(counterClockwiseToolValueLabel);
      break;
    }
    case AngleType.BOTH_360:
    default: {
      prevAngles.push(clockwiseAngle);
      prevAngles.push(counterClockwiseAngle);
      toolValueLabels.push(clockwiseToolValueLabel);
      toolValueLabels.push(counterClockwiseToolValueLabel);
    }
  }

  return {
    prevAngles,
    prevPoints: [{ id: pointId, coords: prev1 }],
    prevLine: {
      p1: prev1,
      p2: prev2,
      visible: true,
      decoration: DEFAULT_PREVLINE_DECORATION,
    },
    toolValueLabels,
  };
};
