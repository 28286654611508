import { connectGizmo, type Dispatch, gizmoAction } from '../../gizmo-utils/redux';
import { scaleDown } from './absoluteLayoutReducer';
import { ShouldEnrichKind } from '@bettermarks/gizmo-types';
import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import {
  AbsoluteLayoutGizmo,
  type ALayoutCallbacks,
  type ALayoutGizmoProps,
} from './AbsoluteLayoutGizmo';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): ALayoutCallbacks => ({
  onScalingDown: (scale: number) =>
    dispatch(
      gizmoAction(scaleDown({ scale }), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        skipUndo: true,
      })
    ),
});

export const AbsoluteLayoutContainer = connectGizmo<ALayoutGizmoProps>(
  mapDispatchToProps,
  AbsoluteLayoutGizmo
);
