import { isUndefined } from 'lodash';

import {
  annotationInner,
  type Content,
  DEFAULT_TEXT_KEY,
  exportContent,
  type Exporter,
  type ExporterContext,
  type MultipleChoiceDropDownConfiguration,
  type MultipleChoiceDropDownContent,
  placeholder,
  semantics,
} from '@bettermarks/gizmo-types';

const exportConfiguration = (config: MultipleChoiceDropDownConfiguration | undefined): string => {
  if (config === undefined) {
    return '';
  }
  // TODO direction = horizontal can be hardcoded? Does never change for dropdowns?
  const defaultText =
    config.dropDownDefaultText !== DEFAULT_TEXT_KEY
      ? `<drop-down-default-text key="${config.dropDownDefaultText}" />`
      : '';
  return `
    <configuration>
      <direction>horizontal</direction>
      ${defaultText}
    </configuration>
  `;
};

// Don't be confused by this strange id handling
// (please have a look at 02_multiple_choice_drop-down_fifth_selected.xml for understanding it)
// We do need to export the actual id of the selected option (minus 1 -> expected by the validator)
// see: http://wiki.bm.loc/index.php/Multiple_choice_gizmo#Validation
export const exportSelectedOption: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as MultipleChoiceDropDownContent;
  const { checkedIdx, choices, itemSeverity, configuration } = content;
  let checkedSet = '<set/>';
  if (checkedIdx > -1) {
    const refId = choices[checkedIdx].$refid;

    const answerId = (context.content[refId] as Content).$id;

    if (!isUndefined(answerId)) {
      checkedSet = `
      <set>
        <mn>
          ${parseFloat(answerId) - 1}
        </mn>
      </set>
    `;
    }
  }

  return semantics(
    `
  <mrow ${itemSeverity ? `decoration="${itemSeverity.severity}"` : ''}>
  ${checkedSet}
  </mrow>
  `,
    placeholder(configuration.placeholder)
  );
};

export const exportMultipleChoiceDropDown: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as MultipleChoiceDropDownContent;
  return semantics(
    `
        <mrow>
        ${exportConfiguration(content.configuration)}
        ${content.choices.map((choice) => exportContent(choice.$refid, context)).join('')}
        ${exportSelectedOption(contentRefId, context)}
        </mrow>
        `,
    annotationInner(content.$, content)
  );
};
