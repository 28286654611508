import { HTML5_ENTITIES } from './html5-entities';
import { isString } from 'lodash';

export const replaceNumeric = (match: string, numeric: string): string => {
  const code =
    numeric.charAt(0) === 'x'
      ? parseInt(numeric.substr(1).toLowerCase(), 16)
      : parseInt(numeric, 10);

  return isNaN(code) || code < -32768 || code > 65535 ? match : String.fromCharCode(code);
};

export const replaceNamed = (match: string, entity: string): string =>
  entity in HTML5_ENTITIES ? HTML5_ENTITIES[entity] : match;

export const decodeHtml5Entities = (str: string) => {
  if (!isString(str)) {
    return '';
  }
  // &amp; is not part of HTML5_ENTITIES and even if it would be,
  // it wouldn't help to replace things like &amp;OverBar; "twice":
  // &amp;OverBar; => &OverBar; => literal from HTML5_ENTITIES
  // because replace seems to not look for candidates
  // in already replaced parts of the string
  // so we do this simple global replacement upfront.
  //
  // Do NOT convert &amp;lt; and &amp;gt;
  // otherwise DOMParser will turn &lt; into < and  &gt; into >,
  // which leads to invalid XML such as: <mtext>></mtext>
  return (
    str
      .replace(/&amp;(?!(lt|gt);)/g, '&')
      .replace(/&#(x?[a-fA-F\d]{2,});/g, replaceNumeric)
      .replace(/&([\w]+);/g, replaceNamed)
      // quickfix for http://trac.bm.loc/ticket/43275 to postpone changing the fonts now
      .replace(/\uE198/g, '×')
  );
};
