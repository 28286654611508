import { createAction } from 'redux-actions';
import { type ILoadFilesPayload, type ILoadExercisePayload } from '../../../../types';

export const SET_VALIDATION = 'SET_VALIDATION' as const;
export const setValidation = createAction<string>(SET_VALIDATION);

export const SET_ELEMENT_TO_FOCUS = 'SET_ELEMENT_TO_FOCUS' as const;
export const setElementToFocus = createAction<string>(SET_ELEMENT_TO_FOCUS);

export const START_FROM_LOCAL_FILES = 'START_FROM_LOCAL_FILES' as const;
export const startFromLocalFiles = createAction<ILoadFilesPayload>(START_FROM_LOCAL_FILES);

export const START_FROM_EXERCISE_ID = 'START_FROM_EXERCISE_ID' as const;
export const startFromExerciseId = createAction<ILoadExercisePayload>(START_FROM_EXERCISE_ID);

export const START_FROM_SERIES_FILE = 'START_FROM_SERIES_FILE' as const;
export const startFromSeriesFile = createAction<ILoadExercisePayload>(START_FROM_SERIES_FILE);
