import { InteractionTypes as IT, RS } from '@bettermarks/gizmo-types';
import { AbsoluteLayoutContainer } from '../../gizmos/layout-container/AbsoluteLayoutContainer';
import { Animation } from '../../gizmos/animation/Animation';
import { SuperGizmo } from '../../gizmos/_super-gizmo/SuperGizmo';
import { HelloGizmo, HelloGizmoContainer } from '../../gizmos/_template/HelloGizmo';
import { HLine } from '../../gizmos/hline/HLine';
import { BasicShape } from '../../gizmos/basic-shape/BasicShape';
import { ClockGizmo } from '../../gizmos/clock/Clock';
import { DayDurationGizmo } from '../../gizmos/day-duration/DayDuration';
import { DragSourceContainer } from '../../gizmos/drag-and-drop/dragsource/DragSourceContainer';
import { Draw2dGeo } from '../../gizmos/draw2d/Draw2dGeo';
import { DropTargetContainer } from '../../gizmos/drag-and-drop/droptarget/DropTargetContainer';
import { FieldSetContainer } from '../../gizmos/fieldset/FieldSet';
import { FormulaContainer, NonInteractiveFormulaContainer } from '../../gizmos/formula/Formula';
import { FunctionPlotterContainer } from '../../gizmos/function-plotter/FunctionPlotterContainer';
import { GeoContainer } from '../../gizmos/geo';
import { GraphGizmoContainer } from '../../gizmos/graph/Graph';
import { ImageGizmo } from '../../gizmos/image/Image';
import { IncludeGizmo } from '../../gizmos/include-gizmo/IncludeGizmo';
import { LegendRenderer } from '../../gizmos/chart/LegendRenderer';
import { LineHeader } from '../../gizmos/line-header/LineHeader';
import {
  MultipleChoiceDropdown,
  MultipleChoiceDropdownContainer,
} from '../../gizmos/multiple-choice-drop-down/MultipleChoiceDropdown';
import {
  MultipleChoiceMulti,
  MultipleChoiceMultiContainer,
} from '../../gizmos/multiple-choice-multi/MultipleChoiceMulti';
import {
  MultipleChoiceSingle,
  MultipleChoiceSingleContainer,
} from '../../gizmos/multiple-choice-single/MultipleChoiceSingle';
import { NLPTextContainer } from '../../gizmos/nlp-text/NLPTextContainer';
import { Pointer } from '../../gizmos/pointer/Pointer';
import { SetDropTargetGizmoContainer } from '../../gizmos/drag-and-drop/set-droptarget/SetDropTargetContainer';
import { SolutionSetContainer } from '../../gizmos/solution-set/SolutionSet/SolutionSetContainer';
import { Unknown } from '../../gizmos/unknown/Unknown';
import { type GizmoRegistry } from './types';
import { ThermometerContainer } from '../../gizmos/thermometer/Thermometer';
import { WheelOfFortune } from '../../gizmos/wheel-of-fortune/WheelOfFortune';
import { LayoutGizmo } from '../../gizmos/layout-container/LayoutGizmo';
import { BallotBox } from '../../gizmos/ballot-box/BallotBox';
import { Box } from '../../gizmos/_box/Box';
import { Balance } from '../../gizmos/balance';
import { CalendarGizmo } from '../../gizmos/calendar/CalendarGizmo';
import { TableGizmo } from '../../gizmos/table/TableGizmo';
import { TreeChartContainer } from '../../gizmos/graph/TreeChart/TreeChartContainer';
import { TableContainerGizmo } from '../../gizmos/table/TableContainerGizmo';
import { ItemList } from '../../gizmos/item-list/ItemList';
import { FractionForm, FractionFormContainer } from '../../gizmos/fraction-form/FractionForm';
import {
  BarChartContainer,
  BarChartRenderer,
  PieChartContainer,
  PieChartRenderer,
  StackedBarChartContainer,
  StackedBarChartRenderer,
} from '../../gizmos/chart';
import { SortingGizmo } from '../../gizmos/sorting/SortingGizmo';
import { SortingGizmoContainer } from '../../gizmos/sorting/SortingGizmoContainer';
import { TimeDurationGizmo } from '../../gizmos/time-duration/TimeDuration';
import { ClockAndTimeGizmo } from '../../gizmos/time/ClockAndTimeGizmo';
import { TimeSpanGizmo } from '../../gizmos/time-span/TimeSpan/TimeSpan';
import { SquaredPaperGizmo } from '../../gizmos/squaredpaper/SquaredPaperGizmo';
import { NLPText } from '../../gizmos/nlp-text/NLPText';
import { ListSelection } from '../../gizmos/list-selection/ListSelection';
import PdfLinkGizmo from '../../gizmos/pdf-link/PdfLink';
import { DynamicRepresentationContainer } from '../../gizmos/dynamic-representation/DynamicRepresentationContainer';
import { ValueStepperGizmo } from '../../gizmos/value-stepper/ValueStepper';
import FreeDragAndDropGizmo from '../../gizmos/drag-and-drop/freedraganddrop/FreeDragAndDropGizmo';

/**
 * This dictionary maps renderStyles to gizmo creators. Here you decide if you just add a container
 * or a gizmo directly (without interaction support)
 *
 * (Please add gizmos in alphabetical order)
 */

export const gizmoRegistry: GizmoRegistry = {
  [RS.ABSOLUTE_LAYOUT]: AbsoluteLayoutContainer,
  [RS.ANIMATION]: Animation,
  [RS.BALANCE]: Balance,
  [RS.BALLOT_BOX]: BallotBox,
  [RS.BASIC_SHAPE]: BasicShape,
  [RS.BORDER_LAYOUT]: LayoutGizmo,
  [RS.CALENDAR]: CalendarGizmo,
  [RS.CAPTION_BAR_CHART]: LegendRenderer,
  [RS.CAPTION_CURVE_CHART]: LegendRenderer,
  [RS.CAPTION_PIE_CHART]: LegendRenderer,
  [`${RS.CIRCLE} ${IT.FORM_ALL}`]: FractionFormContainer,
  [`${RS.CIRCLE} ${IT.FORM_COLORIZE}`]: FractionFormContainer,
  [`${RS.CIRCLE} ${IT.FORM_BREAK_COLORIZE}`]: FractionFormContainer,
  [`${RS.RECTANGLE} ${IT.FORM_ALL}`]: FractionFormContainer,
  [`${RS.RECTANGLE} ${IT.FORM_COLORIZE}`]: FractionFormContainer,
  [`${RS.RECTANGLE} ${IT.FORM_BREAK_COLORIZE}`]: FractionFormContainer,
  [RS.CIRCLE]: FractionForm,
  [RS.CLOCK]: ClockGizmo,
  [RS.TIME]: ClockAndTimeGizmo,
  [RS.CLOCKTIME]: ClockAndTimeGizmo,
  [RS.DAY_DURATION]: DayDurationGizmo,
  [RS.RECTANGLE]: FractionForm,
  [RS.DIAGRAM_BAR_CHART]: BarChartRenderer,
  [`${RS.DIAGRAM_BAR_CHART} ${IT.DIAGRAM_BAR_CHART}`]: BarChartContainer,
  [RS.DRAG_SOURCE]: DragSourceContainer,
  [`${RS.DRAG_SOURCE} ${IT.DRAG_SOURCE}`]: DragSourceContainer,
  [RS.DROP_TARGET]: DropTargetContainer,
  [RS.DRAW2DGEO]: Draw2dGeo,
  [`${RS.DROP_TARGET} ${IT.DROP_TARGET}`]: DropTargetContainer,
  [RS.DYNAMIC_REPRESENTATION]: DynamicRepresentationContainer,
  [RS.EMPTY]: Box,
  [RS.FIELD_SET]: FieldSetContainer,
  [RS.FORMULA]: NonInteractiveFormulaContainer,
  [RS.FREE_DRAG_AND_DROP]: FreeDragAndDropGizmo,
  [RS.FUNCTION_PLOTTER]: FunctionPlotterContainer,
  [`${RS.FUNCTION_PLOTTER} ${IT.SLIDER}`]: FunctionPlotterContainer,
  [RS.GRAPH]: GraphGizmoContainer,
  [`${RS.GRAPH} ${IT.GRAPH}`]: GraphGizmoContainer,
  [RS.HELLO_GIZMO]: HelloGizmo,
  [`${RS.HELLO_GIZMO} ${IT.HELLO_GIZMO}`]: HelloGizmoContainer,
  [RS.HLINE]: HLine,
  [RS.IMAGE]: ImageGizmo,
  [RS.PDF_LINK]: PdfLinkGizmo,
  [RS.INCLUDE_GIZMO]: IncludeGizmo,
  [RS.INNER_TEXT]: NonInteractiveFormulaContainer,
  [`${RS.INNER_TEXT} ${IT.TEXT}`]: FormulaContainer,
  [RS.ITEM_LIST]: ItemList,
  [RS.LINE_HEADER]: LineHeader,
  [RS.LIST_SELECTION]: ListSelection,
  [RS.METRICS_SYSTEM]: GeoContainer,
  [`${RS.METRICS_SYSTEM} ${IT.METRICS_POLYGONSELECTION}`]: GeoContainer,
  [`${RS.METRICS_SYSTEM} ${IT.METRICS_SYSTEM}`]: GeoContainer,
  [RS.LAYOUT_CONTAINER]: LayoutGizmo,
  [RS.MULTIPLE_CHOICE_DROP_DOWN]: MultipleChoiceDropdown,
  [`${RS.MULTIPLE_CHOICE_DROP_DOWN} ${IT.MULTIPLE_CHOICE_DROP_DOWN}`]:
    MultipleChoiceDropdownContainer,
  [RS.MULTIPLE_CHOICE_SINGLE]: MultipleChoiceSingle,
  [`${RS.MULTIPLE_CHOICE_SINGLE} ${IT.MULTIPLE_CHOICE_SINGLE}`]: MultipleChoiceSingleContainer,
  [RS.STACK_LAYOUT]: MultipleChoiceSingle,
  [`${RS.STACK_LAYOUT} ${IT.MULTIPLE_CHOICE_SINGLE}`]: MultipleChoiceSingleContainer,
  [RS.MULTIPLE_CHOICE_MULTI]: MultipleChoiceMulti,
  [`${RS.MULTIPLE_CHOICE_MULTI} ${IT.MULTIPLE_CHOICE_MULTI}`]: MultipleChoiceMultiContainer,
  [`${RS.NLP_TEXT} ${IT.NLP_TEXT}`]: NLPTextContainer,
  [RS.NLP_TEXT]: NLPText,
  [RS.PIE_CHART]: PieChartRenderer,
  [`${RS.PIE_CHART} ${IT.PIE_CHART}`]: PieChartContainer,
  [RS.PLACE_VALUE_TABLE]: TableGizmo,
  [RS.POINTER]: Pointer,
  [`${RS.SET_DROP_TARGET} ${IT.SET_DROP_TARGET}`]: SetDropTargetGizmoContainer,
  [RS.SIMPLE_TABLE]: TableGizmo,
  [RS.SIMPLE_TABLE_CONTAINER]: TableContainerGizmo,
  [RS.SOLUTION_SET]: SolutionSetContainer,
  [`${RS.SOLUTION_SET} ${IT.SOLUTION_SET}`]: SolutionSetContainer,
  [RS.STACKED_BAR_CHART]: StackedBarChartRenderer,
  [`${RS.STACKED_BAR_CHART} ${IT.STACKED_BAR_CHART}`]: StackedBarChartContainer,
  [RS.SUPER_GIZMO]: SuperGizmo,
  [RS.DIGIT]: NonInteractiveFormulaContainer,
  [RS.TABLECELL_TEXT]: NonInteractiveFormulaContainer,
  [RS.TEXT]: NonInteractiveFormulaContainer,
  [`${RS.TABLECELL_TEXT} ${IT.TEXT}`]: FormulaContainer,
  [`${RS.TEXT} ${IT.TEXT}`]: FormulaContainer,
  [`${RS.FORMULA} ${IT.FORMULA}`]: FormulaContainer,
  [`${RS.DIGIT} ${IT.DIGIT}`]: FormulaContainer,
  [RS.TREE_DIAGRAM]: TreeChartContainer,
  [RS.UNKNOWN]: Unknown,
  [RS.SORTING]: SortingGizmo,
  [`${RS.SORTING} ${IT.SORTING}`]: SortingGizmoContainer,
  [RS.SORTING]: SortingGizmo,
  [RS.SQUARED_PAPER]: SquaredPaperGizmo,
  [`${RS.SQUARED_PAPER} ${RS.SQUARED_PAPER}`]: SquaredPaperGizmo,
  [RS.THERMOMETER]: ThermometerContainer,
  [`${RS.THERMOMETER} ${IT.THERMOMETER}`]: ThermometerContainer,
  [RS.TIME_DURATION]: TimeDurationGizmo,
  [RS.TIMESPAN]: TimeSpanGizmo,
  [RS.WHEEL_OF_FORTUNE]: WheelOfFortune,
  [RS.VALUE_STEPPER]: ValueStepperGizmo,
  [`${RS.VALUE_STEPPER} ${IT.VALUE_STEPPER}`]: ValueStepperGizmo,
};
