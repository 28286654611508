import { type ApplyStyles, type Enricher } from '../../gizmo-utils/configuration';
import {
  applyFormulaStyles,
  formulaEnricher,
  measureFormulaInput,
  stepUpFormulaFontSize,
} from '../formula/Formula/measure';
import { type FieldSetContent } from '@bettermarks/gizmo-types';

export const applyFieldSetStyles: ApplyStyles = (content, outerStyles, innerStyles) => {
  const { hasInteractiveChild } = content as FieldSetContent;
  return applyFormulaStyles(
    content,
    hasInteractiveChild ? stepUpFormulaFontSize(outerStyles) : outerStyles,
    innerStyles
  );
};

/**
 * Enrich the fieldset content
 * @param {FormulaStyles} formulaStyles
 * @param {FieldSetContent} content
 * @param {MetricsGetter} measurements
 * @param {StyleResolver} getInnerStyle
 * @return {EnrichedContent<SolutionSetContent>}
 */
export const enrichFieldSet: Enricher<FieldSetContent> = (
  formulaStyles,
  content,
  measurements,
  getInnerStyle
) => {
  const enrichedFormula = formulaEnricher(formulaStyles, content, measurements, getInnerStyle);

  const layoutMetrics = measureFormulaInput(enrichedFormula, content.hasInteractiveChild);

  return {
    ...enrichedFormula,
    ...layoutMetrics,
    enrichedContent: {
      ...content,
      ...enrichedFormula.enrichedContent,
      severity: undefined,
    },
  };
};
