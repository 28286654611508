import { flatten, isEmpty } from 'lodash';
import { PLACEHOLDER_LABEL } from '../components/constants';
import { type AppendOnlyArray } from '../../../../gizmo-utils/append-only';
import {
  type ApplyStyles,
  type GizmoStyle,
  type GizmoStyleForRefId,
} from '../../../../gizmo-utils/configuration';
import {
  type ContentPath,
  forEachMathChild,
  type FormulaContent,
  type FormulaStyles,
  hasAccent,
  isContentReference,
  isOuterInteractive,
  type MathContent,
  type MDecoratable,
  type AppendOnlyMap,
} from '@bettermarks/gizmo-types';
import { nextScaleProps, scaleFontSize } from './scaleFontSize';
import { mapSeverityToDecoration } from '@bettermarks/importers';
import { mergeGizmoStyle } from './mergeGizmoStyle';
import { stepUpFormulaFontSize } from './stepUpFormulaFontSize';

/**
 * This function removes the background color from the styles, To avoid backgroundColor from being
 * passed to child mtags
 */
const removeBackgroundColor = (outerStyles: GizmoStyle): GizmoStyle => {
  const { backgroundColor, ...formulaStyles } = outerStyles.formulaStyles;
  return {
    ...outerStyles,
    formulaStyles,
  };
};

/**
 * Apply styles to the formula content by traversing the content recursively.
 *
 * The styles that are applied to the various m-tags are stored in the innerStyles Map that maps
 * the object-path of each m-tag to its style. The map is passed as a parameter and populated
 * inside the function.
 *
 * It returns a task-list of all alien children and the styles applied to them, like all
 * applyStyles functions.
 *
 * @param {MathContent} content
 * @param {GizmoStyle} outerStyles
 * @param {AppendOnlyMap<FormulaStyles>} innerStyles
 * @param {ContentPath} path
 * @param {boolean} interactive
 * @returns {ReadonlyArray<GizmoStyleForRefId>}
 */
export const applyMathContentStyles = (
  content: MathContent,
  outerStyles: GizmoStyle,
  innerStyles: AppendOnlyMap<FormulaStyles>,
  path: ContentPath,
  interactive = false
): ReadonlyArray<GizmoStyleForRefId> => {
  if (isContentReference(content)) {
    return [
      {
        refId: content.$refid,
        style: outerStyles,
      },
    ];
  }

  const mergedFormulaStyle = mergeGizmoStyle(
    (content as MDecoratable).decoration,
    removeBackgroundColor(outerStyles),
    outerStyles.formulaStyles.fontSize,
    outerStyles.scaleProps
  ).formulaStyles;
  if (isNaN(mergedFormulaStyle.fontSize)) {
    throw new Error('NaN: indicator for undefined at runtime problem');
  }

  innerStyles.set(
    path.join(':'),
    interactive ? { ...mergedFormulaStyle, interactive } : mergedFormulaStyle
  );

  const tasks: AppendOnlyArray<GizmoStyleForRefId> = [];

  forEachMathChild(content, (child, childKey) => {
    const scaledFontSize = hasAccent(content)
      ? outerStyles.formulaStyles.fontSize
      : scaleFontSize(
          outerStyles.formulaStyles.fontSize,
          content.$,
          childKey,
          outerStyles.scaleProps
        );
    const nextScaling = hasAccent(content)
      ? outerStyles.scaleProps
      : nextScaleProps(outerStyles.scaleProps, interactive, content.$, childKey);
    const mergedStyles = mergeGizmoStyle(
      (content as MDecoratable).decoration,
      outerStyles,
      scaledFontSize,
      nextScaling
    );

    tasks.push(
      ...applyMathContentStyles(child, mergedStyles, innerStyles, [...path, childKey], interactive)
    );
  });

  return tasks;
};

/**
 * This is the applyStyles function for the formula gizmo. It returns a list of all "alien" gizmo
 * children with the style to be applied on them. It also populates the innerStyles map that is
 * passed to it with the styles applied to all m-tags.
 *
 * It is applied to the top level formula for the content and for the placeholder label. The content
 * is traversed using the function applyMathContentStyles().
 *
 * It handles interactive mode by stepping up the font size and passing it down to
 * applyMathContentStyles() to make sure that fractions are not scaled down.
 *
 * @param {FormulaContent} content
 * @param {GizmoStyle} outerStyles
 * @param {AppendOnlyMap<FormulaStyles>} innerStyles
 * @returns {ReadonlyArray<GizmoStyleForRefId>}
 */
export const applyFormulaStyles: ApplyStyles = (
  {
    $interactionType,
    content,
    placeholderLabel,
    decoration,
    $renderStyle,
    severity,
  }: FormulaContent,
  outerStyles,
  innerStyles
) => {
  const interactive = !isEmpty($interactionType);

  const mergedGizmoStyle = mergeGizmoStyle(
    { ...decoration, ...mapSeverityToDecoration(severity) },
    isOuterInteractive($renderStyle, $interactionType)
      ? stepUpFormulaFontSize(outerStyles)
      : outerStyles
  );

  innerStyles.set('', mergedGizmoStyle.formulaStyles);

  const styledPlaceHolderLabel: ReadonlyArray<GizmoStyleForRefId> = placeholderLabel
    ? (applyMathContentStyles(
        placeholderLabel,
        mergedGizmoStyle,
        innerStyles,
        [PLACEHOLDER_LABEL],
        interactive
      ) as GizmoStyleForRefId[])
    : [];

  const mappedContent = content.map((c, i) =>
    applyMathContentStyles(c, mergedGizmoStyle, innerStyles, [i], interactive)
  ) as GizmoStyleForRefId[][]; // help flatten to know the types.ts

  mappedContent.push(styledPlaceHolderLabel as GizmoStyleForRefId[]);

  return flatten(mappedContent);
};
