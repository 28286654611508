import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { type Dispatch } from '../../gizmo-utils/redux/types';
import { EMStatus, type FEMContent } from '../../types';

import { type FetchFEMParams } from '../seriesplayer/services/api/content-manager';
import { FEMDialog } from './FEMDialog';
import { Whiteboard } from '../whiteboard';
import { EMKind } from './reducer';
import { useHandleReset } from '../whiteboard/useHandleReset';
import type { FEMStateProps } from './FEMPlayerRoute';
import { getEMPlayeriFrameUrl } from './helper';
import { ContentLocaleContext } from '../../gizmo-utils/polymorphic-gizmo';
import { postToWhiteboardParent } from '../iframe-posts';

export type FEMDispatchProps = Readonly<{
  dispatch: Dispatch;
  onInit: (initParams: FetchFEMParams) => void;
  onReset?: () => void;
  onCancel?: () => void;
}>;

export type FEMPlayerProps = FEMStateProps & FEMDispatchProps;

export const FEMPlayer: React.FC<FEMPlayerProps> = ({
  dispatch,
  encrypt,
  femData,
  id,
  inBook,
  isTouch,
  onCancel,
  locale: localeFromProps,
  onInit,
  onReset,
  staticMediaUrl,
  staticServerUrl,
  status,
  test,
  forWhiteboard,
  onCloseFromWhiteboard,
}) => {
  const localeContext = React.useContext(ContentLocaleContext);
  const locale = localeFromProps || localeContext.contentLocale;

  React.useEffect(() => {
    if (!femData && !forWhiteboard) {
      onInit({
        femId: id,
        locale,
        staticServerUrl,
        encrypt,
        test,
      });
    }
  }, [encrypt, femData, forWhiteboard, id, locale, onInit, staticServerUrl, test]);

  React.useEffect(() => {
    if (status === EMStatus.loaded) {
      postToWhiteboardParent({ type: 'contentLoaded' });
    }
  }, [status]);

  useHandleReset(onReset);

  const [t] = useTranslation();

  const key = `fems.data.${id}`;
  const femRoot: undefined | FEMContent =
    femData && key in femData
      ? (femData[key] as FEMContent) //
      : undefined;

  const title = femRoot
    ? femRoot.name
    : status === EMStatus.error
    ? 'errormessages:explanation.error'
    : '';

  return forWhiteboard ? (
    <Whiteboard
      iframeUrl={getEMPlayeriFrameUrl(locale, id, staticServerUrl, EMKind.fem, test)}
      id={id}
    />
  ) : (
    <FEMDialog
      {...{
        dispatch,
        femData,
        forWhiteboard,
        id,
        inBook,
        isTouch,
        locale,
        onCancel: onCancel,
        onCloseFromWhiteboard: onCloseFromWhiteboard,
        status,
        staticMediaUrl,
        title,
        t,
        onReset,
      }}
    />
  );
};
