import * as React from 'react';
import classNames from 'classnames';
import { type Severity } from '@bettermarks/umc-kotlin';
import {
  CIRCLE,
  CROSS,
  DISC,
  GeoEditorMode,
  type Hover,
  type MouseOrTouch,
  numberFromStyles,
  PLUS,
  type PointDecoration,
  PointHighlight,
} from '@bettermarks/gizmo-types';
import { CircleShape } from './CircleShape';
import { CrossShape } from './CrossShape';
import {
  DEFAULT_POINT_DECORATION,
  getNamedColor,
  transformX,
  transformY,
} from '@bettermarks/importers';
import { PointDragOut, PointMove } from '../../../components/icons/geo';

import styles from './Point.scss';
import { getHoverColor } from '../tools/helpers';

export type PointProps = {
  matrix: number[];
  id: string;
  x: number;
  y: number;
  mode?: GeoEditorMode;
  addedByUser?: true;
  hover?: Hover;
  decoration?: PointDecoration;
  highlight?: PointHighlight;
  invisible?: true;
  severity?: Severity;
  onClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onMouseDown?: (id: string) => (evt: MouseOrTouch) => void;
  onMouseUp?: (evt: React.MouseEvent<any>) => void;
  onMouseOver?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onMouseOut?: (id: string) => (evt: React.MouseEvent<any>) => void;
  hasInteractionType?: boolean;
};

export const Point: React.FC<PointProps> = ({
  matrix,
  id,
  highlight,
  mode,
  invisible,
  x,
  y,
  decoration,
  addedByUser,
  severity,
  hover,
  onMouseDown,
  onMouseUp,
  onMouseOver,
  onMouseOut,
  onClick,
  hasInteractionType,
}) => {
  const interactive = onMouseDown || onMouseUp || onMouseOver || onClick || onMouseOut;

  const activeAreaStyles = classNames({
    [styles.inactive]: !interactive,
  });

  const pointDeco: PointDecoration = {
    ...DEFAULT_POINT_DECORATION,
    ...decoration,
    ...(severity && { color: getNamedColor(severity) }),
    ...getHoverColor(hover),
  };

  const pointStyles = classNames({
    [styles.hide]: invisible,
    [styles.ignorePointEvents]:
      (!addedByUser && mode === GeoEditorMode.MOVE) ||
      (!hasInteractionType && mode === GeoEditorMode.MOVE_POINTS_VERTICALLY),
  });

  const decorationType = pointDeco.pointStyleType;

  const iconSize = numberFromStyles(styles.DEFAULT_HIGHLIGHT_ICON_SIZE);

  const pointHighlightMarkerProps = {
    width: iconSize,
    height: iconSize,
    x: transformX(matrix)(x) - iconSize * 0.5,
    y: transformY(matrix)(y) - iconSize * 0.5,
  };

  const pointHighlightMarker =
    !invisible && highlight === PointHighlight.dragOut ? (
      <PointDragOut {...pointHighlightMarkerProps} />
    ) : highlight === PointHighlight.move && addedByUser ? (
      <PointMove {...pointHighlightMarkerProps} />
    ) : null;

  switch (decorationType) {
    case CROSS:
    case PLUS:
      const transform = decorationType === CROSS;
      return (
        <g className={pointStyles}>
          <CrossShape
            {...{
              transform,
              matrix,
              id,
              x,
              y,
              activeAreaStyles,
              hover,
              onMouseDown,
              onMouseUp,
              onMouseOver,
              onClick,
              onMouseOut,
            }}
            decoration={pointDeco}
          />
          {pointHighlightMarker}
        </g>
      );

    case DISC:
    case CIRCLE:
    default:
      const fillShape = !(decorationType === CIRCLE);
      return (
        <g className={pointStyles}>
          <CircleShape
            {...{
              fillShape,
              matrix,
              id,
              x,
              y,
              activeAreaStyles,
              hover,
              onMouseDown,
              onMouseUp,
              onMouseOver,
              onClick,
              onMouseOut,
            }}
            decoration={pointDeco}
          />
          {pointHighlightMarker}
        </g>
      );
  }
};

Point.displayName = 'Point';
