import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { SolutionSet, type SolutionSetProps, type SolutionSetCallbacks } from './SolutionSet';
import { addElement, removeElement } from './solutionSetActions';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): SolutionSetCallbacks => ({
  onElementAdded: () => {
    dispatch(gizmoAction(addElement(), refid));
  },
  onElementRemoved: () => {
    dispatch(gizmoAction(removeElement(), refid));
  },
});

export const SolutionSetContainer = connectGizmo<SolutionSetProps>(mapDispatchToProps, SolutionSet);
