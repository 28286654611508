import stringify from 'json-stable-stringify';
import md5 from 'md5';
import { type SeriesScore } from '../../../../../types';
import { type SeriesId, type StepResult } from './types';
import { Request, Response, save as saveUnstubable } from '../bm-api';
import { ResultManagerRoutes } from './constants';
import { compress } from '../helper';
import { type QuitWhiteboardActionPayload } from '../../../../emplayer';
import { getBaseMetadata } from '../../metadata';

export const STUBABLE = {
  save: saveUnstubable,
};

const MAX_RETRIES = 4;

export const addTokenAndStringifyContent = (stepResult: StepResult) => {
  const stepResultWithStringifiedContent = {
    ...stepResult,
    userInput: {
      question: JSON.stringify(stepResult.userInput.question),
      feedbacks: stepResult.userInput.feedbacks.map((f) => ({
        ...f,
        ...(f.contentDict && { contentDict: JSON.stringify(f.contentDict) }),
      })),
    },
  };

  return {
    ...stepResultWithStringifiedContent,
    token: md5(
      stringify(stepResultWithStringifiedContent)
        .toLowerCase()
        // eslint-disable-next-line no-useless-escape
        .replace(/[,_\\\'\" ]/g, '')
    ),
  };
};

export const saveStepResult = async (
  resultManagerUrl: string,
  stepResult: StepResult,
  useAxios?: true
): Promise<number> => {
  return STUBABLE.save(
    [
      ResultManagerRoutes.stepResult(resultManagerUrl),
      {
        result: compress(addTokenAndStringifyContent(stepResult)),
      },
      useAxios,
    ],
    MAX_RETRIES
  );
};

export const getSeriesResult = async (
  resultManagerUrl: string,
  seriesId: SeriesId
): Promise<SeriesScore> => {
  // seriesResult also contains key for `seriesType`.
  // It is not used anywhere yet, and hence ignored from type definition.
  const response = await Request.get(
    ResultManagerRoutes.seriesResult(resultManagerUrl, seriesId),
    'include'
  );

  return Response.parseJSON<SeriesScore>(response);
};

export const postSeriesResults = async (
  resultManagerUrl: string,
  stepSubmissions: StepResult[],
  userId: string,
  seriesId: string
): Promise<number> =>
  STUBABLE.save(
    [
      ResultManagerRoutes.reportSeriesResults(resultManagerUrl),
      {
        stepSubmissions: compress(stepSubmissions.map(addTokenAndStringifyContent)),
        userId,
        seriesId,
      },
    ],
    MAX_RETRIES
  );

export const reportTestHandedIn = async (
  resultManagerUrl: string,
  userId: string,
  seriesId: string
): Promise<number> =>
  STUBABLE.save(
    [
      ResultManagerRoutes.reportTestHandedIn(resultManagerUrl),
      {
        userId,
        seriesId,
      },
    ],
    MAX_RETRIES
  );

export const reportLobLinkScore = async (
  resultManagerUrl: string,
  userId: string,
  learningObjectiveId: string,
  score: string,
  bookId: string,
  token: string,
  apiKey: string
): Promise<number> =>
  STUBABLE.save(
    [
      ResultManagerRoutes.reportLobLinkScore(resultManagerUrl),
      {
        userId,
        learningObjectiveId,
        score,
        bookId,
        token,
      },
      false,
      apiKey ? { 'x-api-key': apiKey } : undefined,
    ],
    MAX_RETRIES
  );

export const reportLtiResult = async (resultManagerUrl: string, result: string): Promise<number> =>
  STUBABLE.save(
    [ResultManagerRoutes.reportLtiResult(resultManagerUrl), { result }, false, undefined],
    MAX_RETRIES
  );

export async function reportViewedEvent({
  resultManagerUrl,
  viewedBody,
}: QuitWhiteboardActionPayload & { resultManagerUrl: string }): Promise<number> {
  const baseMetadata = getBaseMetadata();
  return STUBABLE.save(
    [
      ResultManagerRoutes.reportViewedEvent(resultManagerUrl),
      { ...viewedBody, userAgent: baseMetadata.userAgent },
      false,
      undefined,
    ],
    MAX_RETRIES
  );
}
