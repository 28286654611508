import * as React from 'react';
import { type ALayoutContent } from '@bettermarks/gizmo-types';
import { ALayout } from './components';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';

export type ALayoutCallbacks = { onScalingDown: (scale: number) => void };

export type ALayoutGizmoProps = ALayoutContent & ALayoutCallbacks & ContextState;

const scaleFactor = (unscaledWidth: number, availableWidth?: number) =>
  availableWidth && availableWidth < unscaledWidth ? availableWidth / unscaledWidth : 1;

export const AbsoluteLayoutGizmo: React.FC<ALayoutGizmoProps> = (props) => {
  const currScale = props.noScale ? 1 : scaleFactor(props.unscaledWidth, props.availableWidth);
  if (currScale !== props.scale) {
    props.onScalingDown(currScale);
  }

  return <ALayout {...props} scale={currScale} />;
};

AbsoluteLayoutGizmo.displayName = 'AbsoluteLayoutGizmo';
