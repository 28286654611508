import * as React from 'react';

export default function Handle() {
  return (
    <div style={{ zIndex: 20 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 36 36"
        version="1.1"
        viewBox="0 0 36 36"
        xmlSpace="preserve"
        width="30"
        height="30"
        style={{ transform: 'translateX(-7px) translateY(-9px) rotate(270deg)' }}
      >
        <path d="M16 0H20V36H16z" className="st0"></path>
      </svg>
    </div>
  );
}
