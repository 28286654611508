import { clone, flatten, identity, isNull, pickBy } from 'lodash/fp';
import { BUBBLENODE_RADIUS, ROOT_RADIUS } from '../Graph/constants';
import { TREE_BUBBLENODE_WEIGHT, TREE_DEFAULT_NODE_COLOR } from './constants';
import {
  type LayoutTreeNode,
  LineWeight,
  NodeType,
  type Tree,
  TreeChartNodeType,
  type TreeNode,
} from '@bettermarks/gizmo-types';

export const map_ =
  <T extends Tree>(func: (node: T) => T) =>
  (tree: T): T => ({
    ...func(tree),
    children: tree.children.map(map_(func)),
  });

export const map = <T extends Tree>(func: (node: T) => T, tree: T) => map_(func)(tree);

export const filter_ =
  <T extends Tree>(func: (node: T) => boolean) =>
  (tree: T): T[] =>
    flatten([...(func(tree) ? [tree] : []), ...tree.children.map(filter_(func))]);

export const filter = <T extends Tree>(func: (node: T) => boolean, tree: T) => filter_(func)(tree);

export const flattenTree = <T extends Tree>(tree: T) => filter((n) => true, tree);

/**
 * converts a TreeNode tree to some internal LayoutTreeNode structure and initial
 * positioning to 0! In this step, width and height of each node have to be measured.
 *
 * @param t ... the Tree node to convert to a LayoutTreeNode
 * @param indexB ... the index of the node in 'breadth dimension'
 * @param indexD ... the index of the node in 'depth dimension'
 * @param withAncestors ... if false, no mutual references to ancestor obects will be created!
 * @param parent ... an optional parent node (already as a LayoutTreeNode)
 */
/* eslint-disable complexity */
export const layoutTree = (
  t: TreeNode,
  indexB = 0,
  indexD = 0,
  withAncestors = true,
  parent?: LayoutTreeNode
): LayoutTreeNode => {
  // denotes, if there is a node ('probability node' or 'empty node') between two bubble nodes
  const isInBetweenNode = t.edge && (t.edge.probability || t.edge.invisibleNode);

  // artificial node for result probability (or null)
  const resultProbabilityNode =
    t.type === TreeChartNodeType.leaf && t.resultProbability
      ? {
          $id: `RESULTPROBABILITY_${t.$id}`,
          shape: { type: NodeType.gizmo, content: t.resultProbability },
          edge: {
            $id: `RESULTPROBABILITY_EDGE_${t.$id}`,
            decoration: {
              color: '#ddd',
              weight: LineWeight.medium,
              ...t.resultProbabilityDecoration,
            },
          },
          children: [],
          type: TreeChartNodeType.node,
          border: t.resultProbabilityBorder,
        }
      : null;

  // artificial node for result tuple (or null)
  const resultTupleNode =
    t.type === TreeChartNodeType.leaf && t.resultTuple
      ? {
          $id: `RESULT_TUPLE__${t.$id}`,
          shape: { type: NodeType.gizmo, content: t.resultTuple },
          edge: {
            $id: `RESULT_TUPLE__EDGE_${t.$id}`,
            decoration: {
              color: '#ddd',
              weight: LineWeight.medium,
              ...t.resultTupleDecoration,
            },
          },
          children: [resultProbabilityNode].filter((c) => !isNull(c)),
          type: TreeChartNodeType.node,
          border: t.resultTupleBorder,
        }
      : null;

  const l: LayoutTreeNode = {
    nodeId: isInBetweenNode ? `EDGE_${t.$id}` : `LAYOUTNODE_${t.$id}`,
    type: t.edge && t.edge.probability ? NodeType.gizmo : t.shape ? t.shape.type : NodeType.bubble,
    ...pickBy(identity, {
      content:
        t.edge && (t.edge.probability || t.edge.invisibleNode)
          ? t.edge.probability || undefined
          : t.shape && t.shape.content,
      invisible: t.edge && t.edge.invisibleNode,
    }),
    weightD: 1,
    indexB: indexB,
    indexD: indexD,
    modB: 0,
    preB: 0,
    preD: 0,
    change: 0,
    shift: 0,
    size: { width: 0, height: 0 },
    children: [],
    ...pickBy(identity, {
      border: t.edge && t.edge.probability ? t.edge.probabilityBorder : t.border,
    }),
  };

  // decoration annd weight for bubble nodes ...
  if (!isInBetweenNode && t.shape && t.shape.type === NodeType.bubble) {
    l.nodeDecoration = {
      ...pickBy(identity, {
        borderColor: t.shape.borderColor || TREE_DEFAULT_NODE_COLOR,
        highlightColor: t.shape.highlightColor,
        color: t.shape.color,
        fontWeight: t.shape.fontWeight,
      }),
    };
    // adapt weight
    l.weightD = TREE_BUBBLENODE_WEIGHT;
  }
  // edge decoration
  if (t.edge && t.edge.decoration) {
    l.edgeDecoration = t.edge.decoration;
  }

  // preset size ...
  // (BUBBLENODE_RADIUS + 6) comes from highlightinng borders.
  l.size.width = l.size.height = l.invisible
    ? 2
    : l.type === NodeType.bubble
    ? 2 *
      (!parent
        ? ROOT_RADIUS
        : BUBBLENODE_RADIUS + (l.nodeDecoration && l.nodeDecoration.highlightColor ? 6 : 0))
    : 0;
  if (withAncestors) {
    l.thread = undefined;
    l.parent = parent;
    l.ancestor = undefined;
  }

  l.children = (
    t.edge && (t.edge.probability || t.edge.invisibleNode)
      ? // additional edge in case of edge probability
        ([
          {
            ...t,
            edge: { $id: `CHILD_${t.edge.$id}`, decoration: t.edge.decoration },
          },
        ] as TreeNode[])
      : ([resultTupleNode || resultProbabilityNode, ...t.children] as TreeNode[])
  )
    .filter(
      (c) => !isNull(c)
      // Removing children from parent reference avoid circular dependency when using JSON.stringify
    )
    .map((c, i) => layoutTree(c, i, indexD + 1, withAncestors, { ...l, children: [] }));
  return l;
};

/** helper: clones a LayoutTreeNode with it's children and parent! (Ancestor is
 * alwyas assigned to the node itself, as this is the desired starting point!
 * tree is cloned BEFORE applying the layout, as layouting mutates the tree
 * structure.
 * @param t
 * @param parent
 */
export const treeClone = (t: LayoutTreeNode, parent?: LayoutTreeNode): LayoutTreeNode => {
  const t1 = clone(t);
  t1.parent = parent;
  t1.ancestor = t1;
  t1.children = t.children.map((c) => treeClone(c, t1));
  return t1;
};
