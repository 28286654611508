import React from 'react';
import styled from 'styled-components';
import dimensions from '../../sass/dimensions';
import { handleHover } from '../../utils/stylingHelpers';
import colors from '../../sass/colors';

interface SectionHeaderProps {
  title?: string;
  className?: string;
  onClick?: () => void;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  children,
  className,
  title,
  onClick,
}) => (
  <div className={className} onClick={onClick}>
    <div className="title">{title}</div>
    {children}
  </div>
);

export const StyledSectionHeader = styled(SectionHeader)`
  display: flex;
  width: 100%;
  min-height: ${dimensions.minClickableHeight};

  ${(props) =>
    props.onClick &&
    handleHover(
      `
    background: ${colors.cGray200};
    border-color: ${colors.cGray200};
  `,
      '.button .toggle'
    )}

  .title {
    flex: 1 1 auto;
    align-self: center;
    padding: ${dimensions.spaceXs};
    font-weight: ${dimensions.fontWeightBold};
    color: ${(props) => props.theme.color};
  }
`;
