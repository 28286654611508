import { type Severity } from '@bettermarks/umc-kotlin';
import {
  type Annotations,
  type Content,
  type ContentReference,
} from '../../xml-converter/core/types';
import { type FormulaContent } from '../formula/types';
import { type ContentColor } from '../../styles/colors';

export type ChartLabel = ContentReference | FormulaContent;

export interface CaptionContent extends Content {
  header?: ContentReference;
  legend: Legend[];
}

export type Legend = {
  color: ContentColor;
  label: ChartLabel;
};

export interface BarChartContent extends Content {
  configuration: BarChartConfiguration;
  groups: BarChartGroup[];
  xLabel?: ContentReference;
  yLabel?: ContentReference;
  title?: ContentReference;
  caption?: ContentReference;
  yAxisHeight: number;
  totalHeight: number;
  axisContraction: number;
  labeledGroups: number;
  uniqueId?: string;
  cursor?: 'grab' | 'grabbing' | 'default';
  activeItem?: [number, number];
}

export type BarChartConfiguration = {
  yTickLabelInterval: number;
  yTickValueInterval: number;
  yTickValueStart: number;
  yTickValueEnd: number;
  xAxisArrow: boolean;
};

export type BarChartGroup = {
  annotationInner: Annotations;
  items: Bar[];
  label?: ContentReference;
};

export type Bar = {
  annotationInner: Annotations;
  yValue: number;
  color: ContentColor;
  marked: boolean;
  readingHelp: boolean;
  label?: ContentReference;
  severity?: Severity;
  active?: boolean;
};

export interface PieOrStackedBarChartContent extends Content {
  hasLegend: boolean;
  slices: Slice[];
  title?: ContentReference;
  uniqueId?: string;
  hasMarkedSlice?: true;
  slicesWithLabels: number;
  needsBorder: boolean;
}

export interface Slice {
  color: ContentColor;
  id: string;
  legendLabel?: ChartLabel;
  mark?: boolean;
  severity?: Severity;
  sliceLabel?: ChartLabel;
  value: number;
}

export interface SliceWithLegendLabel extends Slice {
  legendLabel: ChartLabel;
}

export enum LabelType {
  LEGEND = 'legend',
  SLICE = 'slice',
}
