import * as React from 'react';
import { Button, ButtonKind } from '@seriesplayer/common-ui';
import { PlusMediumBold, MinusMediumBoxedBold } from '../../../components';

import styles from './InlineContainer.scss';

export interface InlineContainerProps {
  // `false` is here to support short circuit boolean expressions
  addItem?: () => void;
  removeItem?: () => void;
}

/**
 * The `InlineContainer` component is a box for grouping elements that belong together.
 * It also contains two buttons `+` / `-` for showing or hiding elements in SolutionSet.
 *
 * ### Process State
 *
 * - Design implemented after UX spec
 *
 * ### Properties
 | Name               | Type         | Default   | Description                    |
 |---                 |---           |---        |---                             |
 | `addItem`          | `() => void` |           | handler attached to `+` button |
 | `removeItem`       | `() => void` |           | handler attached to `-` button |
 */
const InlineContainer: React.FC<InlineContainerProps> = ({ addItem, removeItem, children }) => (
  <div className={styles.inlineContainer}>
    {children}
    <div className={styles.controlButtons}>
      <div className={styles.controlButtonWrapper}>
        <Button kind={ButtonKind.action} onClick={addItem}>
          <PlusMediumBold />
        </Button>
      </div>
      <div className={styles.controlButtonWrapper}>
        <Button kind={ButtonKind.action} onClick={removeItem}>
          <MinusMediumBoxedBold />
        </Button>
      </div>
    </div>
  </div>
);

InlineContainer.displayName = 'InlineContainer';

export default InlineContainer;
