import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { selectItem } from './multipleChoiceActions';
import { MultipleChoiceMulti, type MultipleChoiceMultiCallbacks } from './MultipleChoiceMulti';

const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): MultipleChoiceMultiCallbacks => ({
  itemChosen: (item: number) => dispatch(gizmoAction(selectItem(item), refid)),
});

export const MultipleChoiceMultiContainer = connectGizmo(mapDispatchToProps, MultipleChoiceMulti);
