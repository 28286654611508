import React, { useEffect } from 'react';
import { ContentDict, FormulaContent } from '@bettermarks/gizmo-types';
import { DEFAULT_MEDIA_ROUTE, gizmoRegistry } from '@seriesplayer/seriesplayer';
import { isTouchDevice } from '../seriesplayer/services';
import { GizmoProvider } from '../../gizmo-utils/polymorphic-gizmo';
import { useDispatch } from 'react-redux';
import TermRow from './term/TermRow';
import { FeedbackBox, ScaffoldingRow } from './styled-components';
import { transformationSeparator } from './equation/RowWithTransformation';
import { EquationValidatorResult, validateTerm } from './helpers';
import { ScaffoldingMode } from './ScaffoldingGizmoRoute';

export type DummyExerciseProps = {
  mode: ScaffoldingMode;
  exerciseKey: number;
  contentDict: ContentDict;
  shouldHandleSubmit: boolean;
  resetShouldHandleSubmit: () => void;
  setFinished: (value: boolean) => void;
};

export default function DummyExercise({
  mode,
  exerciseKey,
  contentDict,
  shouldHandleSubmit,
  resetShouldHandleSubmit,
  setFinished,
}: DummyExerciseProps) {
  const dispatch = useDispatch();
  const refId = `dummy-${exerciseKey}`;
  const [isDone, setIsDone] = React.useState<boolean | undefined>(undefined);
  const [attempts, setAttempts] = React.useState<number>(0);

  useEffect(() => {
    setIsDone(undefined);
    setAttempts(0);
  }, [exerciseKey]);

  useEffect(() => {
    if (shouldHandleSubmit) {
      if (attempts >= 1) {
        setFinished(true);
      } else {
        const formulaContent = contentDict[refId] as unknown as FormulaContent;
        const exercises = mode === 'equation' ? dummyEquationExercises : dummyTermExercises;
        const done =
          validateTerm(formulaContent, exercises[exerciseKey].solution) ===
          EquationValidatorResult.DONE;

        setIsDone(done);
        if (done) {
          setFinished(true);
        }
      }

      setAttempts((prevState) => prevState + 1);
    }

    resetShouldHandleSubmit();
  }, [shouldHandleSubmit]);

  let feedbackBox;
  if (isDone) {
    feedbackBox = <FeedbackBox color={'#33a201'}>Das ist richtig.</FeedbackBox>;
  } else if (isDone === false) {
    feedbackBox = <FeedbackBox color={'#980001'}>Das ist leider nicht richtig.</FeedbackBox>;
  }

  console.log(isDone, attempts);

  return exerciseKey === 3 ? (
    <></>
  ) : (
    <GizmoProvider
      gizmos={gizmoRegistry}
      contentDict={contentDict}
      dispatch={dispatch}
      isTouch={isTouchDevice()}
      selectedGizmoRef={React.createRef()}
      staticMediaUrl={DEFAULT_MEDIA_ROUTE}
    >
      <div style={{ padding: '12px', gap: '12px' }}>
        {mode === 'equation' ? (
          <>
            <ScaffoldingRow style={{ width: '150px' }}>
              {dummyEquationExercises[exerciseKey].originalExpression}
              {dummyEquationExercises[exerciseKey].transformation && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '8px',
                    width: 'fit-content',
                  }}
                >
                  {transformationSeparator}
                  {dummyEquationExercises[exerciseKey].transformation}
                </div>
              )}
            </ScaffoldingRow>
            {feedbackBox}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '12px',
                marginBottom: '12px',
              }}
            >
              x <TermRow refId={refId} term={contentDict[refId] as unknown as FormulaContent} />
            </div>
          </>
        ) : (
          <>
            {feedbackBox}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginTop: '12px',
                marginBottom: '12px',
                whiteSpace: 'nowrap',
              }}
            >
              {dummyTermExercises[exerciseKey].originalExpression}
              <TermRow refId={refId} term={contentDict[refId] as unknown as FormulaContent} />
            </div>
          </>
        )}
      </div>
    </GizmoProvider>
  );
}

const dummyEquationExercises = {
  1: {
    originalExpression: '4x = 20',
    transformation: ': 4',
    solution: '5',
  },
  2: {
    originalExpression: 'x + 3 = 6',
    transformation: '- 3',
    solution: '3',
  },
  4: {
    originalExpression: 'x = 12 - 4',
    solution: '8',
  },
};

const dummyTermExercises = {
  1: {
    originalExpression: '3 ⋅ 3',
    solution: '9',
  },
  2: {
    originalExpression: '3 ⋅ 2 + 4',
    solution: '10',
  },
  4: {
    originalExpression: '36 - 8',
    solution: '28',
  },
};
