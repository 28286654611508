import { type Validity } from '@bettermarks/umc-kotlin';
// The Step type as it is used in the app (with some additional properties)
import { Lens } from '@bettermarks/gizmo-types';
import { type StateWithHistory } from 'redux-undo';
import { type CoreStep } from './internal';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { type Attempt } from '../apps/seriesplayer/services/api/result-manager/types';

// Potentially enum keys don't need suffix duplicated in their name.
export const enum CollapsibleContentState {
  collapsed = 'collapsed',
  expanded = 'expanded',
  hidden = 'hidden',
}

export const enum StepStatus {
  started = 'started',
  locked = 'locked',
  attempted = 'attempted',
  completed = 'completed',
}

export interface IAppStep extends Readonly<CoreStep> {
  question: UndoableQuestion;
  questionIsAnswer: boolean;
  solutionState: CollapsibleContentState;
  explanationState: CollapsibleContentState;
  validity?: Validity; // result from validation
  numberOfErrors: number;
  status?: StepStatus; // Undefined implies not started
  startTime: number;
  aborted: boolean;
  submittedIncomplete?: boolean;
  showBetty?: boolean;
  attempts?: Attempt[];
  maxTries: number;
  qaMode?: boolean;
}

export type AppStep = Readonly<IAppStep>;
export type UndoableQuestion = Readonly<StateWithHistory<Question>> & {
  hasChangedSinceLastAttempt?: boolean;
};
export type Question = ContentDict;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AppStep {
  export const toCurrentQuestion: Lens<AppStep, Question> = Lens.create('question', 'present');
}

/**
 * Used to decide which key in content to validate
 */
export const enum ValidationKey {
  QUESTION = 'question',
  ANSWER = 'answer',
}
