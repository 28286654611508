import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../sass/colors';
import dimensions from '../../sass/dimensions';

export interface Disableable {
  disabled?: boolean;
}

export interface CheckRectProps {
  checked?: boolean;
}

export interface CheckBoxProps {
  onClick?: () => void;
  className?: string;
}

const Transition = css`
  transition: all 0.25s ease-in-out;
`;

const Label = styled.div<Disableable>`
  color: ${(p) => (p.disabled ? colors.cGray200 : colors.cGray800)};
  ${Transition}
`;
Label.displayName = 'Label';

const CheckMark = styled.span<Disableable>`
  display: inline-block;
  width: ${dimensions.spaceS};
  height: ${dimensions.spaceS};
  transform: rotate(-135deg);
  position: absolute;
  top: -4px;
  left: -1px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${(p) => (p.disabled ? colors.cGray200 : 'white')};
  }
  &:before {
    width: 6px;
    height: 2px;
  }

  &:after {
    width: 2px;
    height: 10px;
  }
`;
CheckMark.displayName = 'CheckMark';

const CheckRect = styled.span<CheckRectProps & Disableable>`
  width: ${dimensions.spaceM};
  height: ${dimensions.spaceM};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${dimensions.borderWidthM} solid transparent;
  border-radius: ${dimensions.borderRadiusM};
  margin: ${dimensions.fontSizeS} ${dimensions.spaceS};
  position: relative;
  ${Transition}
  ${(p) =>
    p.checked
      ? css`
          background: ${colors.cGray600};
          border-color: ${colors.cGray600};
        `
      : css`
          background: white;
          border-color: ${colors.cGray600};
        `}

  ${(p) =>
    p.disabled &&
    css`
      border-color: ${colors.cGray200};
      background: white;
    `}
`;

const _CheckBox: React.FC<CheckBoxProps & CheckRectProps & Disableable> = ({
  children,
  checked,
  disabled,
  className,
  onClick,
}) => {
  return (
    <div className={className} onClick={disabled ? undefined : onClick}>
      <CheckRect checked={checked} disabled={disabled}>
        {checked && <CheckMark disabled={disabled} />}
      </CheckRect>
      {children && <Label disabled={disabled}>{children}</Label>}
    </div>
  );
};

export const CheckBox = styled(_CheckBox)`
  display: flex;
  align-items: center;
  min-height: ${dimensions.minClickableHeight};
  user-select: none;
  cursor: pointer;
`;

CheckBox.displayName = 'CheckBox';
