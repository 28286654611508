import {
  type GeoContentPersistProps,
  type GeoObject,
  type GeoObjectPersistType,
} from '@bettermarks/gizmo-types';
import { clone, curry } from 'lodash';

export type VertexId = string;

export interface IVertex<VertexID extends string = string> {
  readonly vertexId: VertexID;
  hasHeads(): boolean;
  getHeads(): DeepImmutableArray<VertexId>;
  removeHead(vertexId: VertexID): this;
  hasTails(): boolean;
  getTails(): DeepImmutableArray<VertexId>;
  removeTail(vertexId: VertexID): this;
  getVertexType(): GeoObjectPersistType;
  toGeoObject(): GeoObject;
}

export class Vertex<VertexID extends string = string> implements IVertex<VertexID> {
  /**
   * the vertex identifier
   * @type {VertexID}
   * @memberof Vertex
   */
  public readonly vertexId: VertexID;

  /**
   * the vertex data
   * @private
   * @type {GeoObject}
   * @memberof Vertex
   */
  private _vertexData: GeoObject;

  /**
   * Creates a new immutable instance of Vertex.
   * @param {GeoObject} vertexData
   * @param {VertexID} vertexId
   * @memberof Vertex
   */
  public constructor(vertexData: GeoObject, vertexId: VertexID) {
    this.vertexId = vertexId;
    this._vertexData = clone(vertexData);
  }

  /**
   * Checks if the Vertex has `INCIDENT EDGES` / `heads` and returns a boolean
   * @returns {boolean}
   * @memberof Vertex
   */
  public hasHeads(): boolean {
    return this._vertexData.referencedBy.length > 0;
  }

  /**
   * Returns the list containing all the `INCIDENT EDGES IDs` / `heads`
   * @returns {DeepImmutableArray<VertexId>}
   * @memberof Vertex
   */
  public getHeads(): DeepImmutableArray<VertexId> {
    return this._vertexData.referencedBy;
  }

  /**
   * Removes a given vertex id from the INCIDENT EDGES / heads
   * It does so by mutating the internal state.
   * It returns the whole Object, to enable method chaining.
   * @param {VertexID} vertexId
   * @returns {this}
   * @memberof Vertex
   * @example
   * ```ts
   * const g: GeoObject = vertex
   *  .removeInboundEdge(vertexId)
   *  .toGeoObject()
   * ```
   */
  public removeHead(vertexId: VertexID): this {
    const referencedBy = this._vertexData.referencedBy.filter(
      (inboundEdgeId) => inboundEdgeId !== vertexId
    );
    this._vertexData = { ...this._vertexData, referencedBy };
    return this;
  }

  /**
   * Checks if the Vertex has `OUTBOUND EDGES` / `tails` and returns a boolean
   * @returns {boolean}
   * @memberof Vertex
   */
  public hasTails(): boolean {
    return this._vertexData.referringTo.length > 0;
  }

  /**
   * Returns the list containing all the `OUTBOUND EDGES IDs` / `tails`
   * @returns {DeepImmutableArray<VertexId>}
   * @memberof Vertex
   */
  public getTails(): DeepImmutableArray<VertexId> {
    return this._vertexData.referringTo;
  }
  /**
   * Removes a given vertex id from the OUTBOUND EDGES / tails
   * It does so by mutating the internal state.
   * It returns the whole Object, to enable method chaining.
   * @param {string} vertexId
   * @returns {this}
   * @memberof Vertex
   * @example
   * ```ts
   * const g: GeoObject = vertex
   *  .removeOutboundEdge(vertexId)
   *  .toGeoObject()
   * ```
   */
  public removeTail(vertexId: string): this {
    const referringTo = this._vertexData.referringTo.filter(
      (outboundEdgeId) => outboundEdgeId !== vertexId
    );
    this._vertexData = { ...this._vertexData, referringTo };
    return this;
  }

  /**
   * Returns the type of the Vertex
   * @returns {GeoObjectPersistType}
   * @memberof Vertex
   */
  public getVertexType(): GeoObjectPersistType {
    return this._vertexData.type as GeoObjectPersistType;
  }

  /**
   * This method behaves opposite of the Vertex constructor:
   * It enables to go from the Vertex back the GeoObject data structure.
   * Call this method once you have finished mutating the internal state and you
   * to want to retrieve the GeoObject
   * @returns {GeoObject}
   * @memberof Vertex
   */
  public toGeoObject(): GeoObject {
    return this._vertexData;
  }

  /**
   * Factory curried function for constructing Vertex Objects from the
   * geoContentMap data type and a given vertexID
   */
  public static fromGeoContentMap = curry(
    <
      GeoContentMap extends GeoContentPersistProps['geoContentMap'],
      VertexID extends Extract<keyof GeoContentMap, string>
    >(
      geoContentMap: GeoContentMap,
      vertexId: VertexID
    ): IVertex<VertexID> => {
      if (!(vertexId in geoContentMap)) {
        throw new Error(
          `VertexID "${vertexId}" not in the geoContentMap:
          ${JSON.stringify(geoContentMap, null, 2)}`
        );
      }
      return new Vertex(geoContentMap[vertexId], vertexId);
    }
  );
}
