import { type CalculatorEvaluationResponse, evaluateExpression } from '@bettermarks/umc-kotlin';
import { exportFormula } from '@bettermarks/importers';
import { call, put, select } from 'redux-saga/effects';
import { type ApplicationState, type CalculatorState } from '../../../../../../types';
import { evaluationSuccess, initialCalculatorState } from './reducer';
import { exportSingleContent } from '@bettermarks/gizmo-types';

export const getCalculator = (state: ApplicationState) =>
  state.calculatorState || initialCalculatorState;

export function* calculatorSaga() {
  const state: CalculatorState = yield select(getCalculator);
  const response = (yield call(evaluateExpression, {
    inputExpr: exportSingleContent(exportFormula, {
      ...state.inputFormula,
      content: state.inputFormula.content,
    }),
    memoryExpr: state.memoryExpr,
  })) as CalculatorEvaluationResponse;

  yield put(evaluationSuccess(response));
}
