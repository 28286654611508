import * as React from 'react';
import Color from 'color';
import styles from './graph.scss';
import classNames from 'classnames';
import {
  type BubbleNode,
  type BubbleNodeContent,
  ContentColor,
  NodeOptionType,
} from '@bettermarks/gizmo-types';
import {
  BORDER_WIDTH,
  ERROR_HIGHLIGHT_WIDTH,
  getNamedColor,
  HIGHLIGHT_PADDING,
  HIGHLIGHT_WIDTH,
  NODE_RADIUS,
  ROOT_RADIUS,
} from '@bettermarks/importers';

export interface BubbleNodeProps {
  node: BubbleNode;
}

export const colorClass = (
  borderColor?: ContentColor,
  color?: ContentColor,
  content?: BubbleNodeContent
) =>
  styles[
    content && content.type === NodeOptionType.colorChooser && content.selected
      ? content.selected
      : borderColor || ContentColor.BM_LIGHT_GREY
  ];

/* eslint-disable-next-line complexity*/
export const BubbleNodeComponent: React.FC<BubbleNodeProps> = ({ node }) => {
  const ref = React.useRef<SVGGElement>(null);
  const {
    content,
    deleteHighlight,
    highlight,
    invisible,
    isRoot,
    position,
    severity,
    highlightColor,
    borderColor,
    color,
    fontWeight,
  } = node;

  const highlightWidth = severity && !deleteHighlight ? ERROR_HIGHLIGHT_WIDTH : HIGHLIGHT_WIDTH;

  return (
    <>
      {position && !invisible ? (
        <g
          ref={ref}
          transform={`translate(${Math.round(position.x)},${Math.round(position.y)})`}
          className={classNames(severity && styles[severity])}
        >
          {
            // If the highlight color is present, render an additional outer circle around the node
            (highlightColor || severity || deleteHighlight) && (
              <circle
                vectorEffect="non-scaling-stroke"
                r={Math.round(NODE_RADIUS + HIGHLIGHT_PADDING + 0.5 * highlightWidth)}
                className={classNames(
                  styles.outerHighlight,
                  highlightColor && styles[highlightColor],
                  { [styles.deleteHighlight]: deleteHighlight }
                )}
                strokeWidth={highlightWidth}
                fill="white"
              />
            )
          }
          {
            // Root nodes are drawn as a small grey circle
            isRoot ? (
              <circle
                vectorEffect="non-scaling-stroke"
                r={Math.round(ROOT_RADIUS)}
                className={styles.rootNode}
              />
            ) : (
              <>
                <circle
                  vectorEffect="non-scaling-stroke"
                  r={Math.round(NODE_RADIUS - 0.5 * BORDER_WIDTH)}
                  strokeWidth={BORDER_WIDTH}
                  className={classNames(
                    styles.bubbleNode,
                    colorClass(borderColor, color, content),
                    { [styles.highlight]: highlight }
                  )}
                  // the border color is used for both, the fill and the border, 'color' is
                  // the text color (see below)
                  style={{
                    color: borderColor && Color(getNamedColor(borderColor)).lighten(0.6).toString(),
                  }} // if color is present, set it
                />
                {content && (content.type === NodeOptionType.selectBox || !content.selected) && (
                  <text
                    fill={getNamedColor(color || ContentColor.BM_BLACK)}
                    fontSize={16}
                    fontWeight={fontWeight || 'normal'}
                    dy="0.35em"
                    textAnchor="middle"
                  >
                    {content.selected || '?'}
                  </text>
                )}
              </>
            )
          }
        </g>
      ) : undefined}
    </>
  );
};

BubbleNodeComponent.displayName = 'BubbleNode';
