import {
  annotationInner,
  type BalanceContent,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';

/**
 * It exports the Balance Content to XML.
 * @param contentRefId
 * @param context
 */
export const exportBalance: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as BalanceContent;
  const configurationXML = `
      <balance>${content.balance}</balance>
      ${
        content.leftOneBullets
          ? `<left-one-bullets>${content.leftOneBullets}</left-one-bullets>`
          : ''
      }
      ${content.leftXBullets ? `<left-x-bullets>${content.leftXBullets}</left-x-bullets>` : ''}
      ${
        content.rightOneBullets
          ? `<right-one-bullets>${content.rightOneBullets}</right-one-bullets>`
          : ''
      }
      ${content.rightXBullets ? `<right-x-bullets>${content.rightXBullets}</right-x-bullets>` : ''}
  `;

  return semantics(
    `
    <mrow>
        <configuration>
        ${configurationXML}
        </configuration>
    </mrow>
    `,
    annotationInner(content.$, content)
  );
};
