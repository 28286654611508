import * as React from 'react';
import classNames from 'classnames';
import { range } from 'lodash/fp';
import styles from './CalendarMonth.scss';
import { type DateCell, type DayGrid, DateCellMark } from './types';

type CalendarMonthProps = {
  /**
   * The month that will appear on the calendar sheet (ex: January)
   */
  month: string;

  /**
   * The day grid to be displayed.
   * Note: It can be constructed using `constructDayGrid` from `./helper.ts`
   */
  dayGrid: DayGrid;

  /**
   * Optional: The list of weekdays to be shown as header (ex: [ 'Mo', 'Tu' ...]).
   */
  daysHeader?: string[];

  /**
   * Optional: Extra rows to add to the calendar month in order to respect
   *           same height if several calendar month are on the same line.
   */
  extraRows?: number;
};

/**
 * Add a `&nbsp;` (html number space) before the day of the month if:
 *  - the day is not highlighted or is highlighted but is not a boundary (mark-start or mark-end)
 *  - the day is between 1 and 9
 *
 * This rule takes care of the right alignment of a day number in the calendar month.
 *
 * @param day the day month number (1 - 31)
 * @param cellMark the cell mark (mark, mark-end ...)
 */
const formatDay = (day: number, cellMark: DateCellMark): string =>
  day < 10 && (cellMark === DateCellMark.Marked || cellMark === DateCellMark.Empty)
    ? `\u00A0 ${day}`
    : `${day}`;

/**
 * Format day grid under the week days header:
 *  - set empty `<td />` for first and last week of the month
 *  - set the right class name if a day is marked
 *
 * @param month the calendar month title (for react component key unicity),
 * @param row the current week to set
 * @param idx the current row index (for react component key unicity)
 */
const formatDayRow = (row: DateCell[], idx: number) => {
  // if row is icomplete, we add empty table cells (for first and last row potentially)
  let formatted = row.map((dc) => {
    const date = new Date(dc.day);
    const day = date.getDate();
    const cls = dc.mark !== '' ? classNames(styles[dc.mark.valueOf()]) : '';
    return (
      <td key={dc.day} className={cls}>
        <span>{formatDay(day, dc.mark)}</span>
      </td>
    );
  });
  const firstRow = idx === 0;
  if (row.length !== 7) {
    const missing = 7 - row.length;
    const emptyCells = range(1, missing + 1).map((i) => <td key={i} />);
    formatted = firstRow ? [...emptyCells, ...formatted] : [...formatted, ...emptyCells];
  }

  return formatted;
};

/**
 * The `CalendarMonth` is a component that displays a calendar sheet...
 * ...with possibly highlighted days
 *
 * ### Properties
 * | Name   | Type      | Default   | Description
 * |---     |---        |---        |---
 * | `dayGrid` | `DayGrid` | - | Day grid to be rendered. See `constructDayGird`
 * | `month` | `string` | - | the month to be displayed on the title of the calendar
 * | `daysHeader` | `?string[]` | - | days of the week header on calendar. See `getWeekDaysHeader`.
 * |  | | | To hide week days header, just ignore `daysHeader` property.
 * | `extraRows` | `?number` | 0 | Add extra rows to maintain same height
 * |  | | | among several calendar month
 */
const CalendarMonth: React.FC<CalendarMonthProps> = (props) => {
  const weekDays =
    props.daysHeader && props.daysHeader.length === 7 ? (
      <tr>
        {props.daysHeader.map((weekDay) => (
          <th key={weekDay}>{weekDay}</th>
        ))}
      </tr>
    ) : undefined;
  const extraRows = range(0, props.extraRows || 0).map((row) => (
    <tr key={row}>
      {range(0, 7).map((cell) => (
        <td className={classNames(styles.emptyCell)} key={`extra-${cell}`} />
      ))}
    </tr>
  ));

  return (
    <div
      className={classNames(styles.calendarMonth, {
        [styles.noDays]: !weekDays,
      })}
    >
      <table>
        <caption>{props.month}</caption>
        <tbody>
          {weekDays}
          {props.dayGrid.map((dr, i) => (
            <tr key={`week-${i}`}>{formatDayRow(dr, i)}</tr>
          ))}
          {extraRows}
        </tbody>
      </table>
    </div>
  );
};

export default CalendarMonth;
