import { createAction } from 'redux-actions';

export const SET_PARAM: 'SET_DYNAMIC_PARAM' = 'SET_DYNAMIC_PARAM';

export type SetParamPayload = {
  id: string;
  value: number;
};

export type DynamicRepresentationActionPayload = SetParamPayload;

export const setParam = createAction<SetParamPayload>(SET_PARAM);
