import { type ContentDict } from '@bettermarks/gizmo-types';
import { type StateWithHistory } from 'redux-undo';
import { type Action, handleAction } from 'redux-actions';
import { CRI_LOADED, CRI_LOADING_STARTED, HANDLE_CRI_ERROR, INIT_CRI } from './actions';
import { type ApplicationState } from '../../types';
import { CRIStatus } from './types';
import { initialSeriesplayerState } from '../store/constants';
import { reduceReducers } from '../../gizmo-utils/reduceReducers';
import { compose, omit } from 'lodash/fp';
import { getToolbar, selectCanvasConfiguration } from './helper';

const handleCRILoadingStarted = handleAction(
  CRI_LOADING_STARTED,
  (state: ApplicationState) => ({
    ...state,
    classroomIntro: omit('status', state.classroomIntro),
  }),
  initialSeriesplayerState
);

const handleCRILoaded = handleAction(
  CRI_LOADED,
  (state: ApplicationState, { payload: criData }: Action<StateWithHistory<ContentDict>>) => ({
    ...state,
    classroomIntro: {
      data: criData,
      status: CRIStatus.loaded,
    },
  }),
  initialSeriesplayerState
);

const handleCRIError = handleAction(
  HANDLE_CRI_ERROR,
  (state: ApplicationState) => ({
    ...state,
    classroomIntro: {
      ...state.classroomIntro,
      status: CRIStatus.error,
    },
  }),
  initialSeriesplayerState
);

const handleInitCri = handleAction(
  INIT_CRI,
  (state: ApplicationState) => compose(getToolbar, selectCanvasConfiguration)(state),
  initialSeriesplayerState
);

export const criReducer = reduceReducers(
  handleCRILoadingStarted,
  handleCRILoaded,
  handleInitCri,
  handleCRIError
);
