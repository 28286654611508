import * as React from 'react';
import {
  ControlAppearance,
  ControlPosition,
  numberFromStyles,
  type OnOffControl,
  type OptionsControl as OptionsControlType,
} from '@bettermarks/gizmo-types';
import { Dropdown, DropdownOption, OptionDecorator } from '../../components';
import sliderStyles from '../../components/Slider/Slider.scss';
import { OptionDecoratorMode, OptionState } from '../../components/MultipleChoice/types';
import CheckBox from '../../components/MultipleChoice/CheckBox';
import { PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import styled, { css } from 'styled-components';
import RadioButton from '../../components/MultipleChoice/RadioButton';

export type CommonControlProps = {
  position: ControlPosition;
  availableWidth: number;
  otherControlAbove: boolean;
  onPersistControlValue: (id: string) => (value: number) => void;
};

export type OnOffControlsProps = CommonControlProps & {
  controls: OnOffControl[];
};

export type OptionsControlProps = CommonControlProps & {
  control: OptionsControlType;
};

export const ControlsWrapper = styled.div<{ column: boolean; needsMargin: boolean }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  margin: ${(props) => (props.needsMargin ? '12px 0' : 0)};
  ${(props) =>
    props.column &&
    css`
      align-items: stretch;
      max-width: ${numberFromStyles(sliderStyles.SLIDER_RAIL_MAX_LENGTH) +
      2 *
        (numberFromStyles(sliderStyles.SLIDER_HORIZONTAL_PADDING) +
          numberFromStyles(sliderStyles.SLIDER_PADDING))}px;
    `}
`;

export const OnOffControls: React.FC<OnOffControlsProps> = ({
  controls,
  position,
  availableWidth,
  otherControlAbove,
  onPersistControlValue,
}) => {
  const column = [ControlPosition.right, ControlPosition.left].includes(position);
  return (
    <ControlsWrapper column={column} needsMargin={otherControlAbove}>
      {controls.map((c) => (
        <OptionDecorator
          state={c.value === 0 ? OptionState.unselected : OptionState.selected}
          mode={OptionDecoratorMode.multi}
          interactive={true}
          hasButton={true}
          stretch={column}
          onClick={() => onPersistControlValue(c.binding)(c.value === 0 ? 1 : 0)}
          key={c.binding}
        >
          <CheckBox
            state={c.value === 0 ? OptionState.unselected : OptionState.selected}
            interactive={true}
          />
          <PolymorphicGizmo refid={c.label.$refid} availableWidth={availableWidth} />
        </OptionDecorator>
      ))}
    </ControlsWrapper>
  );
};

export const OptionsControl: React.FC<OptionsControlProps> = ({
  control,
  position,
  availableWidth,
  onPersistControlValue,
}) => {
  const column = control.orientation === 'vertical' && position !== ControlPosition.bottom;

  return (
    <>
      {control.label && (
        <PolymorphicGizmo refid={control.label.$refid} availableWidth={availableWidth} />
      )}
      {control.appearance === ControlAppearance.radioButton ? (
        <ControlsWrapper column={column} needsMargin={true}>
          {control.options.map((o, index) => (
            <OptionDecorator
              state={control.value === index ? OptionState.selected : OptionState.unselected}
              mode={OptionDecoratorMode.multi}
              interactive={true}
              hasButton={true}
              stretch={column}
              onClick={() => onPersistControlValue(control.binding)(index)}
              key={o.$refid}
            >
              <RadioButton
                state={control.value === index ? OptionState.selected : OptionState.unselected}
                interactive={true}
                collapsedView={false}
              />
              <PolymorphicGizmo refid={o.$refid} availableWidth={availableWidth} />
            </OptionDecorator>
          ))}
        </ControlsWrapper>
      ) : (
        <Dropdown
          interactive={true}
          open={false}
          selectedIndex={control.value}
          onItemSelected={(index) => {
            onPersistControlValue(control.binding)(index);
          }}
        >
          {control.options.map((element, i) => (
            <DropdownOption key={i}>
              <PolymorphicGizmo
                refid={element.$refid}
                availableWidth={availableWidth}
                keepLineHeight
              />
            </DropdownOption>
          ))}
        </Dropdown>
      )}
    </>
  );
};
