import { CapStyle, isCapStyle, type PointerDecoration } from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../gizmo-utils/decoration';

const DEFAULT_COLOR = 'bm-black';
export const parseDecoration = (decoString: string): PointerDecoration => {
  const { object } = parseDecoString<
    'dashLength' | 'gapLength' | 'capStyleBottom' | 'capStyleTop' | 'thickness'
  >(decoString);

  const decoration: PointerDecoration = {
    color: DEFAULT_COLOR,
    capStyleBottom: CapStyle.NONE,
    capStyleTop: CapStyle.NONE,
  };

  if (object.color) {
    decoration.color = object.color;
  }

  const dashLength = object.dashLength && parseFloat(object.dashLength);
  if (dashLength) {
    decoration.dashLength = dashLength;
  }

  const gapLength = object.gapLength && parseFloat(object.gapLength);
  if (gapLength) {
    decoration.dashGapLength = gapLength;
  }

  if (isCapStyle(object.capStyleBottom)) {
    decoration.capStyleBottom = object.capStyleBottom;
  }
  if (isCapStyle(object.capStyleTop)) {
    decoration.capStyleTop = object.capStyleTop;
  }

  const thickness = object.thickness && parseFloat(object.thickness);
  if (thickness) {
    decoration.thickness = thickness;
  }

  return decoration;
};
