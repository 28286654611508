import { createAction } from 'redux-actions';
import { type FetchCRIParams } from './types';
import { type StateWithHistory } from 'redux-undo';
import { type ContentDict } from '@bettermarks/gizmo-types';

export const CRI_REQUESTED = 'CRI_REQUESTED';
export const criRequested = createAction<FetchCRIParams>(CRI_REQUESTED);

export const CRI_LOADING_STARTED = 'CRI_LOADING_STARTED';
export const criLoadingStarted = createAction(CRI_LOADING_STARTED);

export const CRI_LOADED = 'CRI_LOADED';
export const criLoaded = createAction<StateWithHistory<ContentDict>>(CRI_LOADED);

export const INIT_CRI = 'INIT_CRI';
export const initCri = createAction(INIT_CRI);

export const HANDLE_CRI_ERROR = 'HANDLE_CRI_ERROR';
export const handleError = createAction(HANDLE_CRI_ERROR);
