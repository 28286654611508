import classNames from 'classnames';
import { numberFromStyles } from '@bettermarks/gizmo-types';
import * as React from 'react';

import {
  ARROW_RIGHT,
  CIRCUMFLEX,
  CURLY_BOTTOM,
  CURLY_TOP,
  CURVE_BOTTOM,
  CURVE_TOP,
  OVERBAR_MO,
  SEGMENT,
  SEGMENT_DE,
} from '../constants';
import {
  ArrowRight,
  CircumFlex,
  CurlyBottom,
  CurlyTop,
  CurveBottom,
  CurveTop,
  OverbarMO,
  Segment,
} from '../images';
import { type MProps } from '../types';

import styles from './tokens.scss';

const OVERBAR_HEIGHT_S = numberFromStyles(styles.OVERBAR_HEIGHT_S);
const OVERBAR_HEIGHT_M = numberFromStyles(styles.OVERBAR_HEIGHT_M);
const OVERBAR_HEIGHT_L = numberFromStyles(styles.OVERBAR_HEIGHT_L);

/*
 * Check whether a string is the unicode representation of one of the supported stretched
 * shapes.
 */
export const isStretchedShape = (text: string): text is ShapeType => {
  return new RegExp(
    `^[${[ARROW_RIGHT, CIRCUMFLEX, CURLY_BOTTOM, CURLY_TOP, OVERBAR_MO, SEGMENT_DE, SEGMENT].join(
      ''
    )}]$`
  ).test(text);
};

/*
 * This dictionary contains data about the stretched shapes used in <mo>.
 * It is a 3-tuple of the following values:
 *   1. The SVG React component that renders it
 *   2. Whether the SVG has a viewBox (hasViewBox)
 *   3. The height of the rendered shape
 */
export const STRETCHED_SHAPE_DATA: {
  [key: string]: [React.FC<any>, boolean, number];
} = {
  [ARROW_RIGHT]: [ArrowRight, false, OVERBAR_HEIGHT_M],
  [CIRCUMFLEX]: [CircumFlex, true, OVERBAR_HEIGHT_S],
  [CURLY_BOTTOM]: [CurlyBottom, true, OVERBAR_HEIGHT_L],
  [CURLY_TOP]: [CurlyTop, true, OVERBAR_HEIGHT_L],
  [CURVE_BOTTOM]: [CurveBottom, false, OVERBAR_HEIGHT_L],
  [CURVE_TOP]: [CurveTop, false, OVERBAR_HEIGHT_L],
  [OVERBAR_MO]: [OverbarMO, false, OVERBAR_HEIGHT_S],
  [SEGMENT_DE]: [OverbarMO, false, OVERBAR_HEIGHT_S],
  [SEGMENT]: [Segment, false, OVERBAR_HEIGHT_S],
};

export type ShapeStyle = {
  marginLeft?: number;
  marginRight?: number;
  width?: number;
};

export type ShapeType =
  | typeof ARROW_RIGHT
  | typeof CIRCUMFLEX
  | typeof CURLY_BOTTOM
  | typeof CURLY_TOP
  | typeof CURVE_BOTTOM
  | typeof CURVE_TOP
  | typeof OVERBAR_MO
  | typeof SEGMENT_DE
  | typeof SEGMENT;

export type StretchedShapeProps = MProps & {
  shapeStyles?: ShapeStyle;
  shape: ShapeType;
  stretchable?: boolean;
  strokeWidth?: number;
  width?: string;
};

/**
 * The `StretchedShape' component
 *
 * Current Implementation supports
 * ArrowRight,
 * Circumflex,
 * Top and Bottom Curly Bracket,
 * OverbarMO,
 * Segment
 *
 * ### Properties
 | Name          | Type                                  | Default   | Description               |
 |---            |---                                    |---        |---                        |
 | `shape`       | `shapeType` ('circumflex' | 'curly')  | Required  | Renders the relavent      |
 |               |                                       |           | SVG Icon                  |
 | `stretchable` | `boolean` (default - false)           | Optional  | true enables stretched    |
 |               |                                       |           | shape                     |
 */

export const StretchedShape: React.FC<StretchedShapeProps> = ({
  computedStyles = {},
  shape,
  shapeStyles,
  stretchable = false,
  strokeWidth,
}) => {
  const stretchedShapeProps = {
    className: classNames(
      shape === CURLY_BOTTOM ? styles.stretchWrapCurlyBottom : styles.stretchWrap,
      styles[shape],
      {
        [styles.inline]: !stretchable,
      }
    ),
    style: { ...shapeStyles, ...computedStyles },
  };

  const [ShapeComponent, hasViewBox, height] = STRETCHED_SHAPE_DATA[shape];
  const style: React.CSSProperties = {
    stroke: computedStyles.color,
    height,
    strokeWidth,
  };
  const shapeComponentProps = {
    preserveAspectRatio: 'none',
    style,
    viewBox: hasViewBox ? '0 0 1 1' : undefined,
  };

  return (
    <span {...stretchedShapeProps}>
      <ShapeComponent {...shapeComponentProps} />
    </span>
  );
};

StretchedShape.displayName = 'StretchedShape';
