import {
  type AppExercise,
  type ExerciseDisplayStatus,
  ExerciseStatus,
  SeriesMode,
} from '../../../../types';
import log from 'loglevel';
import { type StepResult } from '../../services/api/result-manager/types';

/** If exercise is completed/review, always returns a validity.
 * If not completed, just returns status as it would be either started or undefined.
 * Results in the kind how buttons are displayed in the exercise navigation.
 *
 * @param exercises
 * @returns A list of ExerciseDisplayStatus which can be a validity or a status
 */
export const getExerciseDisplayStatus = (
  exercises: ReadonlyArray<AppExercise>
): ExerciseDisplayStatus[] =>
  exercises.map((exercise) => {
    if (exercise.status === ExerciseStatus.review || exercise.status === ExerciseStatus.completed) {
      return exercise.validity;
    } else {
      return exercise.status;
    }
  });

/** Calculate ratio of exercises completed to total.
 *
 * @param exercises
 * @param mode
 */
export const getSeriesProgress = (
  exercises: ReadonlyArray<AppExercise>,
  mode: SeriesMode
): number => {
  const progressFilter =
    mode === SeriesMode.test
      ? ({ status }: AppExercise) => status === ExerciseStatus.withInput
      : ({ status }: AppExercise) =>
          status === ExerciseStatus.completed || status === ExerciseStatus.review;

  return exercises.filter(progressFilter).length / exercises.length;
};

export const logMissingFields = (stepSubmissions: StepResult[], source: string) => {
  // additional logging to debug https://bettermarks.atlassian.net/browse/BM-54115
  const stepsWithMissingFields = stepSubmissions.filter(
    (s) => s && (s.maxErrors === undefined || s.stepId === undefined || s.sumErrors === undefined)
  );
  if (stepsWithMissingFields.length > 0) {
    log.error({
      message: `Steps missing mandatory fields in step submissions on ${source} route`,
      extra: {
        stepsWithMissingFields: JSON.stringify(stepsWithMissingFields),
      },
    });
  }
};
