import {
  annotationInner,
  type BasicShapeContent,
  type Exporter,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportBasicShape: Exporter = (contentRefId, context): string => {
  const content = context.content[contentRefId] as BasicShapeContent;
  const { color, shape, scalefactor, gradientType } = content;
  const decoration = `color:${color}${gradientType ? `; gradient-type:${gradientType}` : ''}`;

  return semantics(
    `
    <mrow decoration="${decoration}">
      <configuration>
        <type>${shape}</type>
        <scalefactor>${scalefactor}</scalefactor>
      </configuration>
    </mrow>
    `,
    annotationInner(content.$, content)
  );
};
