import { identity as _identity } from 'lodash/fp';

export const identity = _identity as <T>(value: T) => T;

export const toInt = (value: Nullable<string | number>) => parseInt(value as string, 10);

export const toFloat = (value: Nullable<string | number>) => parseFloat(value as string);

export function toBoolean(value: Nullable<string>) {
  return value === 'true';
}

export function toTrueOrUndefined(value: Nullable<string>): true | undefined {
  return toBoolean(value) || undefined;
}

/**
 * explicit version of default javascript behavior:
 * returns `defaultValue` if `condition` is `null`, `undefined`, `NaN` or `0`,
 * otherwise `condition` is returned.
 *
 * The reason for this function is to make it explicit that you want this,
 * instead of running in to the problem of by mistake having `0` handled as falsy value.
 *
 * @see lodashs `defaultTo` for a more strict version.
 */
export const booleanNumber = (condition: Nullable<number>, defaultValue: number): number =>
  condition ? condition : defaultValue;

/**
 * explicit version of default javascript behavior:
 * returns `defaultValue` if `condition` is `null`, `undefined`, ''(empty string),
 * otherwise `condition` is returned.
 *
 * The reason for this function is to make it explicit that you want this,
 * instead of running in to the problem of by mistake
 * having `''`(empty string) handled as falsy value.
 *
 * @see lodashs `defaultTo` for a more strict version.
 */
export const booleanString = (condition: Nullable<string>, defaultValue: string): string =>
  condition ? condition : defaultValue;

/**
 * Makes sure no number that is used to calculate is `NaN`.
 *
 * @param {number} input the number to check for `NaN`
 * @returns {number} either `input` or `0` in case `input` is `NaN`
 */
export const zeroNaN = (input: number): number => (isNaN(input) ? 0 : input);
