export const enum Sound {
  Correct = 'correct',
  Wrong = 'wrong',
  Coin1 = 'coin1',
  Coin2 = 'coin2',
  Coin3 = 'coin3',
  Star = 'star',
}

export type AudioSource = Readonly<{
  src: string;
}>;

export type Sounds = Readonly<{ [key in Sound]: ReadonlyArray<AudioSource> }>;

export type HasAudio = Readonly<{
  playSound: (sound: Sound) => void;
  prepareSound: (sound: Sound) => void;
}>;
