import * as React from 'react';
import { getObjectColor } from './decorations';
import styles from './Point.scss';
import { type PointProps } from './Point';
import { getStrokeWidth, POINT_PLUS_LENGTH, transformX, transformY } from '@bettermarks/importers';
import { type PointDecoration } from '@bettermarks/gizmo-types';

export type CrossShapeProps = PointProps & {
  activeAreaStyles: string;
  transform: boolean;
  decoration: PointDecoration;
};

export const CrossShape: React.FC<CrossShapeProps> = ({
  matrix,
  id,
  x,
  y,
  decoration,
  activeAreaStyles,
  transform,
  hover,
  onMouseDown,
  onMouseUp,
  onMouseOver,
  onClick,
  onMouseOut,
}) => {
  const [screenX, screenY] = [transformX(matrix)(x), transformY(matrix)(y)];

  // rotate for x-shape, do not rotate for +-shape
  const rotationCoords = transform ? `rotate(45, ${screenX}, ${screenY})` : '';

  const strokeWidth = getStrokeWidth(decoration, hover);
  const color = getObjectColor(decoration);

  // put an invisible rectangle above the point for receiving mouse events
  const rectOverlay = {
    x: screenX - POINT_PLUS_LENGTH,
    y: screenY - POINT_PLUS_LENGTH,
    width: 2 * POINT_PLUS_LENGTH,
    height: 2 * POINT_PLUS_LENGTH,
  };

  const interactive = onClick || onMouseDown || onMouseUp || onMouseOver || onMouseOut;

  return (
    <g className={activeAreaStyles}>
      <g className={styles.cross} transform={rotationCoords}>
        <line
          x1={screenX}
          y1={screenY - POINT_PLUS_LENGTH}
          x2={screenX}
          y2={screenY + POINT_PLUS_LENGTH}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <line
          x1={screenX - POINT_PLUS_LENGTH}
          y1={screenY}
          x2={screenX + POINT_PLUS_LENGTH}
          y2={screenY}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      </g>
      {interactive && (
        <rect
          className={styles.invisible}
          {...rectOverlay}
          {...{ id, onMouseUp, onClick }}
          onMouseDown={onMouseDown && onMouseDown(id)}
          onTouchStart={onMouseDown && onMouseDown(id)}
          onMouseOver={onMouseOver && onMouseOver(id)}
          onMouseOut={onMouseOut && onMouseOut(id)}
          onClick={onClick && onClick(id)}
        />
      )}
    </g>
  );
};

CrossShape.displayName = 'CrossShape';
