import { type FontMetric } from '@bettermarks/bm-font';
import { isUndefined } from 'lodash';
import * as React from 'react';
import {
  type Interactable,
  type LayoutMetrics,
  type MDecoratable,
  type MRow as MRowContent,
} from '@bettermarks/gizmo-types';
import { enrichFormulaRow } from '../../measure/enrichFormulaRow';
import { type EnrichNestedMathContent } from '../../measure/measureStatic';
import { BackgroundColor } from '../BackgroundColor';
import { boundingLayoutMetrics, onClickBetweenChildren } from '../helpers';
import { type MPropsWithChildren } from '../types';
import { FEMLink } from './FEMLink';

import styles from '../MathML.scss';
import classNames from 'classnames';

export type MRowProps = MPropsWithChildren &
  MDecoratable &
  Interactable & {
    hideHelpTools?: boolean;
    femLink?: string;
    onClickFEM?: (id: string) => void;
    isTopLevel?: boolean;
  };

/**
 * The `MRow' component renders a MathML <mrow> node
 *
 * It is assumed that an <mrow> has an array of children.
 */
export const MRow: React.FC<MRowProps> = ({
  children,
  computedStyles: { backgroundColor, ...computedStyles } = {
    backgroundColor: undefined,
  },
  interactive,
  hideHelpTools,
  femLink,
  onClickFEM,
  onSelect,
  isTopLevel,
}) => {
  return (
    <BackgroundColor backgroundColor={backgroundColor}>
      {isUndefined(femLink) ? (
        <span
          role="button"
          className={classNames(styles.mrow, { [styles.isTopLevel]: isTopLevel })}
          onClick={onClickBetweenChildren(onSelect)}
          style={interactive ? { fontSize: computedStyles.fontSize } : undefined}
        >
          {children}
        </span>
      ) : (
        <FEMLink
          femId={femLink}
          computedStyles={computedStyles}
          hideHelpTools={hideHelpTools}
          onClickFEM={onClickFEM}
        >
          {children}
        </FEMLink>
      )}
    </BackgroundColor>
  );
};

MRow.displayName = 'MRow';

/**
 * Always considers the fontHeight as the minimum height for the row.
 * This is important for cases of only containing the cursor which doesn't have an own height.
 *
 * @param {ReadonlyArray<LayoutMetrics>} childrenMetrics
 * @param {FontMetric} fontMetric
 * @returns {LayoutMetrics}
 */
const measureInteractiveMRow = (
  childrenMetrics: ReadonlyArray<LayoutMetrics>,
  { height, refLine }: FontMetric
): LayoutMetrics => boundingLayoutMetrics(childrenMetrics, true, { height, refLine });

export const enrichMRow: EnrichNestedMathContent<MRowContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher
) => {
  return enrichFormulaRow(
    formulaStyles,
    content,
    path,
    mathContentEnricher,
    formulaStyles.interactive ? measureInteractiveMRow : undefined // using the default one
  );
};
