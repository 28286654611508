import { createAction } from 'redux-actions';

export const SET_PARAM: 'SET_PARAM' = 'SET_PARAM';
export const NEXT_PARAM: 'NEXT_PARAM' = 'NEXT_PARAM';
export const PREV_PARAM: 'PREV_PARAM' = 'PREV_PARAM';
export const SELECT_PARAM: 'SELECT_PARAM' = 'SELECT_PARAM';
export const CURSOR_RIGHT: 'CURSOR_RIGHT' = 'CURSOR_RIGHT';
export const CURSOR_LEFT: 'CURSOR_LEFT' = 'CURSOR_LEFT';

export type SetParamPayload = {
  id: string;
  value: number;
};

export type FunctionPlotterActionPayload = void | SetParamPayload | string;

export const setParam = createAction<SetParamPayload>(SET_PARAM);
export const nextParam = createAction(NEXT_PARAM);
export const prevParam = createAction(PREV_PARAM);
export const selectParam = createAction<void | string>(SELECT_PARAM);
export const cursorRight = createAction(CURSOR_RIGHT);
export const cursorLeft = createAction(CURSOR_LEFT);
