import * as React from 'react';
import styles from './EndscreenProgressBar.scss';
import anime from 'animejs';
import { once } from 'lodash';
import { type animation } from '../../../../../../types';

import {
  ENDSCREEN_ANIMATION_START_DELAY,
  ENDSCREEN_ANIMATION_DURATION,
  ENDSCREEN_ELEMENT_ANIMATION_DURATION,
} from '../constants';
import { type HasAudio, Sound, withAudio } from '../../../../providers/Audio';

export type EndscreenProgressBarProps = Readonly<{
  animate?: boolean;
  scoreRatio: number;
  coinThresholds: [number, number, number];
}>;

interface CoinProps {
  threshold: number;
  scorePercent: number;
}

const Coin: React.FC<CoinProps> = ({ threshold, scorePercent }) => (
  <div
    data-cy={`endscreen-coin-${scorePercent >= threshold ? 'filled' : 'empty'}`}
    className={styles.coin}
    style={{ left: `${threshold}%` }}
  >
    <div
      className={styles.coin_filled}
      style={{
        opacity: scorePercent >= threshold ? 1 : 0,
      }}
    />
  </div>
);

export class _EndscreenProgressBar extends React.Component<EndscreenProgressBarProps & HasAudio> {
  componentDidMount() {
    const scorePercent = this.props.scoreRatio * 100;
    if (this.props.animate) {
      const coinPlay = (target: string, sound: Sound) => {
        anime({
          targets: `.${styles[target]}`,
          opacity: 1,
          easing: 'linear',
          duration: ENDSCREEN_ELEMENT_ANIMATION_DURATION,
          begin: () => this.props.playSound(sound),
        });
      };

      const coin1Play = once(coinPlay);
      const coin2Play = once(coinPlay);
      const coin3Play = once(coinPlay);

      // Fill up the bar with score ratio earned.
      anime({
        targets: `.${styles.barFilled}`,
        width: `${scorePercent}%`,
        easing: 'easeOutQuad',
        duration: this.props.scoreRatio * ENDSCREEN_ANIMATION_DURATION,
        offset: ENDSCREEN_ANIMATION_START_DELAY,
        update: (anim) => {
          const currentAnimation = anim.animations[0] as animation;
          const progress = parseFloat(currentAnimation.currentValue);
          if (progress >= this.props.coinThresholds[0]) {
            coin1Play('coin1', Sound.Coin1);
          }
          if (progress >= this.props.coinThresholds[1]) {
            coin2Play('coin2', Sound.Coin2);
          }
          if (progress >= this.props.coinThresholds[2]) {
            coin3Play('coin3', Sound.Coin3);
          }
        },
      });
    }
  }

  render() {
    const [firstThreshold, secondThreshold, thirdThreshold] = this.props.coinThresholds;
    const scorePercent = this.props.scoreRatio * 100;

    return (
      <div className={styles.endscreenProgressBar}>
        <div className={styles.barEmpty}>
          <div className={styles.barFilled} style={{ width: `${scorePercent}%` }} />
          <Coin threshold={firstThreshold} scorePercent={scorePercent} />
          {/*
            secondThreshold and thirdThreshold are optional,
            since students can only earn max one coin for knowledge gap series

            See:
             - http://trac.bm.loc/ticket/35297
             - https://bettermarks.atlassian.net/browse/BM-58152
             - https://github.com/bettermarks/bm-backend/blob/3984b1a1ca908219a0cac2ce9940e6a3856cdb3b/bm/models/series_result.py#L106-L115
          */}
          {secondThreshold && <Coin threshold={secondThreshold} scorePercent={scorePercent} />}
          {thirdThreshold && <Coin threshold={thirdThreshold} scorePercent={scorePercent} />}
        </div>
      </div>
    );
  }
}

export const EndscreenProgressBar = withAudio(_EndscreenProgressBar);
