import {
  annotationInner,
  exportContent,
  type Exporter,
  type ItemListContent,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportItemList: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as ItemListContent;

  return semantics(
    `<mrow>
        <itemList type="${content.type}">
          ${content.items
            .map(
              (i) =>
                `<item>
              ${i.bullet ? `<bullet>${exportContent(i.bullet.$refid, context)}</bullet>` : ''}
              <content>${exportContent(i.content.$refid, context)}</content>
          </item>`
            )
            .join('')}
        </itemList>
      </mrow>`,
    annotationInner(content.$, content)
  );
};
