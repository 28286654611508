import * as React from 'react';
import { getNamedColor } from '@bettermarks/importers';
import { Marker, MarkerType } from '../components';
import { isNil } from 'lodash';
import { CapStyle, type PointerContent } from '@bettermarks/gizmo-types';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';

const CapStyleToMarkerType = {
  [CapStyle.T_ARROW]: MarkerType.TArrow,
  [CapStyle.TRIANGLE]: MarkerType.Triangle,
  [CapStyle.OPEN_TRIANGLE]: MarkerType.OpenTriangle,
};

export type PointerProps = PointerContent & ContextState;

/* eslint-disable-next-line complexity*/
export const Pointer: React.FC<PointerProps> = ({
  uniqueId,
  decoration,
  width,
  peakWidth,
  height,
  strokeWidth,
}) => {
  const { capStyleBottom, capStyleTop } = decoration;
  let dashArray = 'none';
  if (!isNil(decoration.dashLength) && !isNil(decoration.dashGapLength)) {
    dashArray = `${decoration.dashLength}, ${decoration.dashGapLength}`;
  }
  let x1: number,
    x2 = width;
  if (capStyleBottom === CapStyle.NONE) {
    x1 = 0;
  } else if (capStyleBottom === CapStyle.T_ARROW) {
    x1 = 1;
  } else {
    x1 = peakWidth;
  }
  if (capStyleTop === CapStyle.T_ARROW) {
    x2 -= 1;
  } else if (capStyleTop !== CapStyle.NONE) {
    x2 -= peakWidth;
  }

  return (
    <svg viewBox={`0 0 ${width} ${height}`} width={`${width}px`} height={`${height}px`}>
      {capStyleBottom !== CapStyle.NONE && (
        <Marker
          id={`arrowbottom${uniqueId}`}
          type={CapStyleToMarkerType[capStyleBottom]}
          width={capStyleBottom === CapStyle.T_ARROW ? strokeWidth : peakWidth}
          height={height}
          color={getNamedColor(decoration.color)}
          flip={true}
        />
      )}
      {capStyleTop !== CapStyle.NONE && (
        <Marker
          id={`arrowtop${uniqueId}`}
          type={CapStyleToMarkerType[capStyleTop]}
          width={capStyleTop === CapStyle.T_ARROW ? strokeWidth : peakWidth}
          height={height}
          color={getNamedColor(decoration.color)}
        />
      )}
      <line
        x1={x1}
        y1={height * 0.5}
        x2={x2}
        y2={height * 0.5}
        strokeDasharray={dashArray}
        strokeWidth={strokeWidth}
        stroke={getNamedColor(decoration.color)}
        markerStart={`url(#arrowbottom${uniqueId})`}
        markerEnd={`url(#arrowtop${uniqueId})`}
      />
    </svg>
  );
};

export default Pointer;

Pointer.displayName = 'Pointer';
