import * as React from 'react';
import { defaultTo, get, isNaN } from 'lodash';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  type Coords,
  GeoEditorMode,
  type GeoScene,
  type MouseOrTouch,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import {
  addCircleAction,
  addPreviewCircleAction,
  geoAddCircleReducer,
  initialState,
  setCircleCentreAction,
  snapAction,
  stopSnapAction,
} from './geoAddCircleReducer';
import {
  DEFAULT_CIRCLE_CONFIGURATION,
  DEFAULT_PREVCIRCLE_DECORATION,
  mousePos,
} from '@bettermarks/importers';
import { type GeoProps } from '../../Geo';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { getSnapPoints } from '../../snap';
import { Maybe } from '../../../../utils/maybe';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export const GeoAddCircle: React.FC<GeoProps & DragScrollableProps> = (props) => {
  const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

  return (
    <WithLocalRedux
      store={{
        ...initialState,
        persistProps: persistProps(props),
        prevCircle: {
          ...initialState.prevCircle,
          decoration: {
            ...initialState.prevCircle.decoration,
            lineWeight: get(
              props.configuration,
              'defaultDecorations.circles.lineWeight',
              DEFAULT_PREVCIRCLE_DECORATION.lineWeight
            ),
          },
        },
        toolValueLabels: [],
      }}
      reducer={geoAddCircleReducer}
      componentName={`AddCircle:${props.uniqueId}`}
    >
      {(state, dispatch) => {
        const { uniqueId, configuration, matrix, scale, onPersistLocalState } = props;
        const { snapType, tickValueInterval } = configuration;

        const snap = (scene: GeoScene) => (evt: MouseOrTouch) => {
          // for circle touch we do not want to have an offset
          const mouseP: Coords = mousePos(defaultTo<string>(uniqueId, ''))(evt);
          const snapPoints: SnapPoint[] = getSnapPoints(matrix, scene, scale)(mouseP);

          dispatch(snapAction(snapPoints));
          dispatch(
            addPreviewCircleAction({
              configuration: get(
                configuration,
                'toolConfiguration.circleConfiguration',
                DEFAULT_CIRCLE_CONFIGURATION
              ),
              snapType,
              matrix,
              mouseP,
              scene,
              scale,
              tickValueInterval,
            })
          );
        };

        const stopSnap = () => dispatch(stopSnapAction());

        const onMouseDown = () => dispatch(setCircleCentreAction(matrix));

        const onMouseUp = () => {
          maybeScrollBehaviour.ap(stopScrolling);
          return dispatch(addCircleAction({ configuration, onPersistLocalState }));
        };

        const onTouchStart = (scene: GeoScene) => (evt: MouseOrTouch) => {
          snap(scene)(evt);
          maybeScrollBehaviour.ap(startScrolling);
          onMouseDown();
        };

        const geoContentProps = { ...props, ...state.persistProps };

        return (
          <GeoRenderer
            {...geoContentProps}
            mode={GeoEditorMode.ADD_CIRCLE}
            onMouseLeave={stopSnap}
            onMouseMove={snap}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onTouchStart={onTouchStart}
            snapPoints={state.snapPoints}
            previewObjects={{
              lines: [],
              points: [],
              circles: !isNaN(state.prevCircle.coords.x) ? [state.prevCircle] : [],
            }}
            toolValueLabels={state.toolValueLabels}
          />
        );
      }}
    </WithLocalRedux>
  );
};

GeoAddCircle.displayName = 'GeoAddCircle';
