import { getSeriesplayerBaseUrl } from '../emplayer/helper';
import { ApplicationState } from '../../types';
import { InputToolTypes, ToolbarElements } from '@bettermarks/gizmo-types';
import { getCurrentTool } from '../seriesplayer/containers/Toolbar/helpers';

export const getCRIPlayeriFrameUrl = (
  locale: string,
  id: string,
  staticServerUrl: string,
  test = false
): string => {
  const baseUrl = getSeriesplayerBaseUrl();
  return `${baseUrl}#/classroom-intro/${locale}/${id}?staticUrl=${staticServerUrl.replace(
    /\/+content_exercises/g,
    ''
  )}&test=${test}`;
};

export const getToolbar = (state: ApplicationState): ApplicationState => {
  const currentTool = state.classroomIntro?.data?.present && getCurrentTool(state);
  let tools: ToolbarElements[];
  switch (currentTool?.type) {
    case InputToolTypes.modeSelector:
      tools = [ToolbarElements.modeSelector];
      break;
    case InputToolTypes.keyboard:
      tools = [ToolbarElements.keyboard];
      break;
    default:
      tools = [];
  }
  return {
    ...state,
    toolbar: {
      tools,
      openDrawerName: null,
    },
  };
};

export const selectCanvasConfiguration = (state: ApplicationState): ApplicationState => {
  // const criContent = ApplicationState.toCriContent.get(state);
  // const canvasRefId = criContent?.canvasConfiguration.$refid;
  //
  // return state.classroomIntro
  //   ? {
  //       ...state,
  //       classroomIntro: CRI.toSelectedRefId.set(canvasRefId)(state.classroomIntro),
  //     }
  //   : state;
  return state;
};
