import {
  annotationInner,
  type Content,
  DECORATION,
  exportContent,
  type Exporter,
  type ExporterContext,
  type MultipleChoiceMultiChoiceContent as ChoiceContent,
  type MultipleChoiceMultiConfiguration,
  type MultipleChoiceMultiContent,
  placeholder,
  semantics,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { isUndefined } from 'lodash';

const exportConfiguration = (config: MultipleChoiceMultiConfiguration | undefined): string => {
  // eslint-disable-next-line no-undefined
  if (config === undefined) {
    return '';
  }
  const layout =
    config.elementsPerLine === 1
      ? `<direction>vertical</direction>`
      : config.elementsPerLine === Number.MAX_VALUE
      ? `<direction>horizontal</direction>`
      : `<maxElementsPerRow>${config.elementsPerLine}</maxElementsPerRow>`;
  const hideButtons = config.buttons ? '' : '<hideButtons/>';
  return `
    <configuration>
      ${layout}
      ${hideButtons}
    </configuration>
  `;
};

export const exportSelectedOptions: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as MultipleChoiceMultiContent;

  let checkedSet = '<set/>';
  if (content.checkedIdx.length > 0) {
    checkedSet = `
      <set>
         ${content.checkedIdx
           .map((checked: number) => {
             const { $refid: refId, severity } = content.choices[checked];
             const answerId = (context.content[refId] as Content).$id;

             if (!isUndefined(answerId)) {
               return `<mn ${xmlAttr(DECORATION, severity)}>
                   ${parseFloat(answerId) - 1}
                 </mn>`;
             }
           })
           .join('')}
      </set>
    `;
  }

  return semantics(`<mrow>${checkedSet}</mrow>`, placeholder(content.configuration.placeholder));
};

export const exportMultipleChoiceMulti: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as MultipleChoiceMultiContent;

  return semantics(
    `
      <mrow>
        ${exportConfiguration(content.configuration)}
        ${content.choices
          .map((choice: ChoiceContent) => exportContent(choice.$refid, context))
          .join('')}
        ${exportSelectedOptions(contentRefId, context)}
      </mrow>
      `,
    annotationInner(content.$, content)
  );
};
