import {
  type BezierGroupObject,
  CAP_NARROW_ARROW,
  type ContentColor,
  type Coords,
  CursorPositionOffset,
  type GeoAddLabelBaseState,
  type GeoAddLineBaseState,
  type GeoConfiguration,
  type GeoDefaultDecorations,
  type GeoInteractiveBaseState,
  type GeoInteractivePreviewState,
  type GeoMoveBeziersState,
  type GeoObject,
  type GeoObjectMap,
  Hover,
  type IdCoords,
  LABEL,
  LabelAlignDirection,
  LabelingObjectRefType,
  type LabelObject,
  type LabelStepperProps,
  GeoLabelType as LabelType,
  type GeoLineDecoration as LineDecoration,
  type LineObject,
  POINT,
  type PointObject,
  type SeverityColor,
  type SnapPoint,
  type TickValueInterval,
  VECTOR,
  VERTICALLY_MOVABLE_POINT,
} from '@bettermarks/gizmo-types';
import { type AdditionalButtonProps } from '@seriesplayer/common-ui';
import { first, get, isNil, reduce } from 'lodash';

import { getDefaultLabelAlign } from '../components/sets/LabelSet';
import {
  DEFAULT_SELECT_COLOR,
  eq,
  getPointCoordsId,
  INV_POINT_PREFIX,
  screenToWorld,
  worldToScreen,
} from '@bettermarks/importers';
import { getLabelId, getLineCoords } from '../helpers';
import { createSnapPoints } from '../snapHelpers';

import {
  DEFAULT_DELETE_COLOR,
  DEFAULT_DOWN_DECORATION,
  DEFAULT_HOVER_COLOR,
  DEFAULT_HOVER_DECORATION,
  DEFAULT_PREVIEW_COLOR,
  type LineTypeProps,
} from './constants';
import { persistLabel } from './persist';

export const addPreviewPoint = <State extends GeoInteractivePreviewState>(
  state: State,
  snapPoint: SnapPoint,
  matrix: number[],
  invisible = false
): State => {
  const newPointWorld: Coords = screenToWorld(matrix)(snapPoint);
  let firstPointId = snapPoint.id;

  if (snapPoint.snapObject !== POINT) {
    // a new point needs to be added
    firstPointId = getPointCoordsId(newPointWorld, invisible ? INV_POINT_PREFIX : undefined);
  }

  return {
    ...state,
    prevPoints: [...state.prevPoints, { id: firstPointId, coords: newPointWorld }],
  };
};

export const isLabelAlignTop = (
  state: GeoInteractiveBaseState,
  refObj: IdCoords,
  configuration: GeoConfiguration
) =>
  getDefaultLabelAlign(
    state.persistProps.geoContentMap[refObj.id],
    refObj.coords,
    configuration,
    LabelType.initial
  ) === LabelAlignDirection.top;

export const addAutoLabel = <State extends GeoInteractiveBaseState>(
  refObj: IdCoords,
  state: State,
  configuration: GeoConfiguration
) =>
  persistLabel<State>(getLabelId(refObj.id), refObj.id, '?', 0, state, {
    labelAlignTop: isLabelAlignTop(state, refObj, configuration),
    labelType: LabelType.initial,
  });

export const addPreviewLine = <State extends GeoAddLineBaseState>(
  state: State,
  mouseP: Coords,
  matrix: number[],
  lineTypeProps: LineTypeProps,
  defaultDecorations: GeoDefaultDecorations
): State => {
  const firstPointCoords = (first(state.prevPoints) as IdCoords).coords;
  const snapPoint = first(state.snapPoints);
  const secondPointCoords: Coords =
    snapPoint && !eq(screenToWorld(matrix)(snapPoint), firstPointCoords)
      ? screenToWorld(matrix)(snapPoint)
      : screenToWorld(matrix)(mouseP);

  const prevPointId = snapPoint ? snapPoint.id : '';

  if (eq(firstPointCoords, secondPointCoords)) {
    return { ...state, prevLine: { ...state.prevLine, visible: false } };
  }

  const [p1, p2] = getLineCoords(firstPointCoords, secondPointCoords, lineTypeProps.type);

  return {
    ...state,
    prevPoints: [
      { id: state.prevPoints[0].id, coords: firstPointCoords },
      { id: prevPointId, coords: secondPointCoords },
    ],
    prevLine: {
      ...state.prevLine,
      p1,
      p2,
      visible: true,
      decoration: {
        ...state.prevLine.decoration,
        lineStyle: lineTypeProps.lineStyle,
        ...(lineTypeProps.type in defaultDecorations && {
          lineWeight: (defaultDecorations[lineTypeProps.type] as LineDecoration).lineWeight,
        }),
        ...(lineTypeProps.type === VECTOR && {
          lineCapStyleTop: CAP_NARROW_ARROW,
        }),
      },
    },
  };
};

export const readActiveIndices = (
  labelIds: ReadonlyArray<string>,
  contentMap: GeoObjectMap<GeoObject>
) => {
  return reduce<string, { [key: string]: number }>(
    labelIds,
    (acc, labelId) => {
      const labelObj = contentMap[labelId] as LabelObject;
      acc[labelObj.refid as string] = labelObj.activeIndex || 0;
      return acc;
    },
    {}
  );
};

export const resetSeverity = ({ severity, ...woutSeverity }: GeoObject): GeoObject => woutSeverity;

export const deepResetSeverity = (
  map: GeoObjectMap<GeoObject>,
  id: string
): GeoObjectMap<GeoObject> => {
  const root = map[id];
  return root.referencedBy.reduce(
    (acc: GeoObjectMap<GeoObject>, id) => {
      // do not deep reset the severity of labels
      // reason:
      //   resetting the severity of a point/line... does not justify to assume
      //   that the label attached to it should change severity
      const firstDeg = acc[id].type === LABEL ? acc[id] : resetSeverity(acc[id]);
      return firstDeg.referringTo.reduce(
        (iacc: GeoObjectMap<GeoObject>, iid) => {
          const secondDeg = iacc[iid];
          const hasSevereRelative = !!secondDeg.referencedBy.find((i) =>
            iacc[i].hasOwnProperty('severity')
          );
          // remove severity of second degree relative,
          // only if it does not have a relation to an object with severity
          return hasSevereRelative
            ? iacc
            : {
                ...iacc,
                [iid]: resetSeverity(secondDeg),
              };
        },
        { ...acc, [id]: firstDeg }
      );
    },
    { ...map, [id]: resetSeverity(root) }
  );
};

/**
 * Helper method to change decoration of bezier curve and attached points on hover or down
 * @param {string} gizmoId - id of the geo gizmo
 * @param {string} id - id of the bezier object
 * @param {GeoMoveBeziersState} state - current state of GeoMoveBeziers
 * @param {number[]} matrix - transformation matrix of geo scene
 * @param {boolean} isDown
 * @return {GeoMoveBeziersState}
 */
export const applyDragDecoration = (
  gizmoId: string,
  id: string,
  state: GeoMoveBeziersState,
  matrix: number[],
  isDown = false
): GeoMoveBeziersState => {
  const prevBezier = state.persistProps.geoContentMap[id] as BezierGroupObject;
  // reset severity, if bezier is clicked/moved
  const currBezier = isDown ? (resetSeverity(prevBezier) as BezierGroupObject) : prevBezier;
  const moveGroup = prevBezier.moveGroup || [];
  // create snap points to highlight all points in the bezier's moveGroup
  let snapPoints: SnapPoint[] = [];
  if (!isDown) {
    snapPoints = moveGroup
      .map((id) => {
        const point = state.persistProps.geoContentMap[id] as PointObject;
        const snapP = createSnapPoints(
          [{ id, coords: worldToScreen(matrix)(point.coords) }],
          POINT
        );
        return snapP.length > 0 ? snapP[0] : undefined;
      })
      .filter((it) => !isNil(it)) as SnapPoint[];
  }

  return {
    ...state,
    snapPoints,
    persistProps: {
      ...state.persistProps,
      geoContentMap: {
        ...state.persistProps.geoContentMap,
        [id]: {
          ...currBezier,
          decoration: {
            ...currBezier.decoration,
            ...DEFAULT_HOVER_DECORATION(currBezier.subtype, gizmoId),
            ...(isDown && DEFAULT_DOWN_DECORATION(currBezier.subtype, gizmoId)),
          },
        },
      },
    },
  };
};

export const getLabelStepperProps = (
  state: GeoAddLabelBaseState,
  onConfirm: (index: number, labelAlignTop: boolean) => void,
  additionalButton?: AdditionalButtonProps
): LabelStepperProps => ({
  active: state.active,
  initialIndex: state.activeIndex[state.labelingObjectId] || 0,
  labelingObjectRef: {
    type: LabelingObjectRefType.id,
    id: state.labelingObjectId,
  },
  onConfirm: onConfirm,
  list: state.pickerList,
  additionalButton,
  mouseScreenCoords: state.mouseScreenCoords,
});

export const colorConfigForSelect = (configuration: GeoConfiguration) =>
  get(
    configuration,
    'toolConfiguration.selectionConfiguration.color',
    DEFAULT_SELECT_COLOR
  ) as string;

export const addedByUser = (id: string, contentMap: GeoObjectMap<GeoObject>): boolean =>
  !!(id in contentMap && contentMap[id].addedByUser);

export const verticallyMovable = (id: string, contentMap: GeoObjectMap<GeoObject>): boolean =>
  !!(id in contentMap && contentMap[id].interactionType === VERTICALLY_MOVABLE_POINT);

export const offset = (isTouch: boolean, isTouchStart: boolean): CursorPositionOffset =>
  isTouch
    ? !isTouchStart
      ? CursorPositionOffset.TOUCH
      : CursorPositionOffset.NONE
    : CursorPositionOffset.MOUSE;

export const getHoverColor = (
  hoverType: Hover | undefined
): { color: ContentColor | SeverityColor } | false => {
  if (!hoverType || hoverType === Hover.COLORING) {
    return false;
  }

  switch (hoverType) {
    case Hover.PREVIEW:
      return { color: DEFAULT_PREVIEW_COLOR };
    case Hover.DELETE:
      return { color: DEFAULT_DELETE_COLOR };
    default:
      return { color: DEFAULT_HOVER_COLOR };
  }
};

export const isLine = (obj: GeoObject): obj is LineObject => 'p1Id' in obj && 'p2Id' in obj;

export const effectiveRadius = (radius: number, { x, y }: TickValueInterval) =>
  Math.max(x / 2, y / 2, radius);
