import axios, { type AxiosInstance, type AxiosResponse } from 'axios';
import { fromPairs } from 'lodash';
import {
  ErrorReason,
  FetchExceptionType,
  generateFetchException,
  getExceptionParamsFromError,
  getExceptionParamsFromResponse,
  isNetworkException,
  isServerException,
  isTestHasBeenCollectedException,
  isThrowableStatus,
} from '../../exception';
import { HttpStatusCode } from '../../../../../types';

export const sleep = async (ms: number) => new Promise((resolve) => window.setTimeout(resolve, ms));

const savePost = axios.create({
  timeout: 30000,
  method: 'post',
});

export const raisingFetch = async (url: string, init?: RequestInit): Promise<Response> => {
  let response: Response;
  const generateFetchExceptionWith = generateFetchException(url, init);
  try {
    response = await fetch(url, init);
  } catch (err) {
    throw generateFetchExceptionWith(...getExceptionParamsFromError(err));
  }
  if (isThrowableStatus(response.status)) {
    throw generateFetchExceptionWith(
      ...getExceptionParamsFromResponse(
        response.status,
        (await response.text()) || response.statusText
      )
    );
  }
  return response;
};

const REQUEST_BODY_TO_AXIOS_DATA_KEYS_MAP: Record<string, string> = {
  body: 'data',
};

export const raisingFetchAxios = async (
  url: string,
  init: RequestInit = {},
  axiosInstance: AxiosInstance = savePost
): Promise<AxiosResponse> => {
  let response: AxiosResponse;
  const generateFetchExceptionWith = generateFetchException(url, init);
  try {
    response = await axiosInstance({
      url,
      ...fromPairs(
        Object.entries(init).map(([k, v]) => [REQUEST_BODY_TO_AXIOS_DATA_KEYS_MAP[k] || k, v])
      ),
    });
  } catch (err) {
    const { response: _response } = err;
    if (
      _response?.status === HttpStatusCode.BAD_REQUEST &&
      _response?.data?.title === ErrorReason.TEST_HAS_BEEN_COLLECTED
    ) {
      throw generateFetchExceptionWith(
        'This test has already been collected by the teacher.',
        FetchExceptionType.testHasBeenCollectedException,
        _response
      );
    } else {
      throw generateFetchExceptionWith(...getExceptionParamsFromError(err));
    }
  }
  if (isThrowableStatus(response.status)) {
    throw generateFetchExceptionWith(
      ...getExceptionParamsFromResponse(response.status, response.statusText)
    );
  }

  return response;
};

/**
 * Expected usage is always Request.function
 */
export const Request = {
  post: async <T>(url: string, body: T, useAxios?: boolean, headers?: HeadersInit) => {
    const credentials = headers ? 'omit' : 'include';
    const requestInit: RequestInit = {
      method: 'POST',
      credentials,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(body),
    };
    return (useAxios ? raisingFetchAxios : raisingFetch)(url, requestInit);
  },
  get: async (url: string, credentials: RequestCredentials, headers?: HeadersInit) => {
    const requestInit: RequestInit = {
      method: 'GET',
      credentials,
      headers,
    };

    return raisingFetch(url, requestInit);
  },
  put: async <T>(url: string, body: T) => {
    const requestInit: RequestInit = {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(body),
    };

    return raisingFetch(url, requestInit);
  },
  delete: async (url: string) => {
    const requestInit: RequestInit = {
      method: 'DELETE',
      credentials: 'include',
    };

    return raisingFetch(url, requestInit);
  },
};

type SaveArgs<T> = [string, T, boolean?, HeadersInit?];

/**
 * @param saveArgs {SaveArgs} if the third item is true we do not use recursive retry flow.
 * Instead retry logic is being delegated to a manual retry UI flow via a popup.
 * @param maxRetries
 * @param nRetry
 */
export const save = async <T>(
  saveArgs: SaveArgs<T>,
  maxRetries: number,
  nRetry = 0
): Promise<number> => {
  try {
    return (await Request.post(...saveArgs)).status;
  } catch (err) {
    if (isTestHasBeenCollectedException(err) || (isNetworkException(err) && saveArgs[2])) {
      throw err;
    } else if ((isServerException(err) || isNetworkException(err)) && nRetry < maxRetries) {
      await sleep((nRetry + 1) * 1000);
      return save(saveArgs, maxRetries, nRetry + 1);
    } else {
      throw err;
    }
  }
};

/**
 * Using BmResponse name as Response is standard type.
 * Expected usage is always BmResponse.function
 */
export const Response = {
  parseJSON: async <T>(response: Response): Promise<T> => {
    return response.json();
  },
  getBlob: async (response: Response) => {
    return response.blob();
  },
};
