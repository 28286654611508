/**
 * @module FormulaReducer
 */

import { type FormulaContent, isMRow, type MathContent, type MRow } from '@bettermarks/gizmo-types';
import { flow, identity } from 'lodash';
import { MROW } from '../constructors';
import { preprocessFormula, restoreFormula } from './preprocessing';

export type FormulaProcessor = (input: FormulaContent) => FormulaContent;

export type MathContentProcessor = (input: MathContent) => MathContent;

/**
 * If severity is error/remark
 * - Change the severity to default when content changes.
 * @param {FormulaContent} input
 * @return {FormulaContent}
 */
export const severityProcessor = ({ severity, ...input }: FormulaContent): FormulaContent => input;

/**
 * Preprocess formula, call the processor function and restor formula again. This is important for
 * all algorithms that assume to work in MRows (denormalized containers) and with singular number
 * elements (i.e. <mn> elements that contain only a single char)
 * Severity update is required for all cases except for cursor movement
 * @param {FormulaProcessor} processor
 * @param {boolean} updateSeverity
 */
export const process = (processor: FormulaProcessor, updateSeverity = true): FormulaProcessor =>
  flow([
    updateSeverity ? severityProcessor : identity,
    preprocessFormula,
    processor,
    restoreFormula,
  ]);

/**
 * Helper function that transform a MathContent processor into a
 * FormulaProcessor. This is necessary to work formulas. FormulaContent is not
 * a MathContent, so it can't be used recursively.  MathContent is a recursive
 * data structure and can be used easily by many algorithms. Thus it's the main
 * data type used internally. Externally we represent formulas as FormulaContent
 * and thus we need to convert to it again. This is done via converting a
 * formula into an mrow and back.
 * @param {MathContentProcessor} processor function that processes MathContent
 * @returns {FormulaProcessor} function that processes FormulaContent
 */
export const withFormula =
  (processor: MathContentProcessor): FormulaProcessor =>
  (input: FormulaContent): FormulaContent => {
    const mrow: MRow = MROW(...input.content);
    const result = processor(mrow);

    return {
      ...input,
      content: unwrapContentIfNeeded(result),
    };
  };

export const unwrapContentIfNeeded = (content: MathContent) => {
  if (isMRow(content)) {
    return content.children;
  }
  return [content];
};

/**
 * Process formula and update severity for specific cases
 * @param {MathContentProcessor} processor
 * @param {boolean} updateSeverity
 */
export const processFormula = (processor: MathContentProcessor, updateSeverity = true) =>
  process(withFormula(processor), updateSeverity);
