import { throttle, identity } from 'lodash/fp';
import { type DragScrollDirectionStrategy } from './DragScrollDirectionStrategy';
import { DragScrollXStrategy } from './DragScrollXStrategy';
import { DragScrollYStrategy } from './DragScrollYStrategy';
import { type DragScrollBehaviour, type ScrollListener } from './types';

const throttle100ms = throttle(100);

/**
 * This class manages the scrolling of the given box when dragging items to
 * its top or bottom border. Currently only the vertical case is supported!
 * Usually the default browser behaviour on desktop is to scroll the box content
 * when you drag a draggable item to its top or bottom border. This behaviour
 * has a few problems: First it doesn't work on all devices consistently, even when
 * using the drag & drop mobile shim. Second it will stop working as soon as you
 * drag beyond the border.
 * This class creates sensitive areas from the top of the screen to the top of
 * the container plus some offset and to the bottom accordingly. When you enter
 * the area with a dragged item, it will start scrolling with a scroll speed
 * depending how far you have entered said area.
 */
export class BaseDragScrollBehaviour implements DragScrollBehaviour {
  frame: number;
  speed: number;
  animSpeed: number;
  scrollContainer: DragScrollDirectionStrategy;
  last: number;
  listener?: ScrollListener;

  constructor(scrollContainer: Nullable<HTMLElement>, horizontal?: boolean) {
    this.scrollContainer = horizontal
      ? new DragScrollXStrategy(scrollContainer)
      : new DragScrollYStrategy(scrollContainer);
  }

  setListener = (listener?: ScrollListener) => {
    this.listener = listener;
  };

  getScrollAmount = () => this.scrollContainer.getScrollAmount();

  incrementalScroll = (delta: number) => {
    this.scrollContainer.incrementalScroll(delta);
    this.scrollContainer.getContainer().ap(() => this.listener && this.listener(0, 0));
  };

  getScrollWrapper = () => this.scrollContainer.getContainer().ap(identity);

  startScrolling = () => {
    // Turn smooth scrolling off while we drag - otherwise scrolling will not
    // exceed a certain speed.
    this.scrollContainer.startScrolling();

    this.scrollContainer.getContainer().ap(() => {
      this.animSpeed = 0;
      this.last = Date.now();
      this.frame = window.requestAnimationFrame(this.scroll);
    });
    if ('ontouchstart' in window) {
      document.addEventListener('touchmove', this.onTouchMove);
    } else {
      document.addEventListener('mousemove', this.onMouseMove);
    }
  };

  stopScrolling = () => {
    this.scrollContainer.stopScrolling();
    this.listener = undefined;
    window.cancelAnimationFrame(this.frame);
    if ('ontouchstart' in window) {
      document.removeEventListener('touchmove', this.onTouchMove);
    } else {
      document.removeEventListener('mousemove', this.onMouseMove);
    }
  };

  /**
   * We first define two areas on the top and the bottom (or left and right) where we will
   * trigger a scroll animation. If the mouse is in the top area, we scroll up and if we are in the
   * bottom area we scroll down. The speed of the scroll animation is defined by how far we entered
   * those scroll areas.
   *
   * There are two variables topDist and bottomDist that indicate the (normalized) distance from the
   * respective boundaries. If both are positive we are in the main area. If topDist is negative we
   * entered the top area, if bottomDist is negative we entered the bottom area.
   *
   * *--------------------*  *--------------------*  *--------------------*
   * |    x               |  |                    |  |                    |
   * |   ^ ^    < 0       |  |                    |  |                    |
   * |   | |   topDist    |  |                    |  |                    |
   * *--------------------*  *--------------------*  *--------------------* topDist === 0
   * |   |                |  |    x               |  |   |                |
   * |   |                |  |   ^ ^  topDist     |  |   |                |
   * |   |                |  |   | |    >= 0      |  |   |                |
   * |   | bottomDist     |  |   | bottomDist     |  |   | toptist        |
   * |   |   >= 0         |  |   |    >= 0        |  |   |   >= 0         |
   * |   |                |  |   |                |  |   |                |
   * |   |                |  |   |                |  |   |                |
   * |   |                |  |   |                |  |   |                |
   * *--------------------*  *--------------------*  *--------------------* bottomDist === 0
   * |                    |  |                    |  |   | | bottomDist   |
   * |                    |  |                    |  |    *     < 0       |
   * *--------------------*  *--------------------*  *--------------------*
   *
   */
  onMove = (x: number, y: number) => {
    const { topDist, bottomDist } = this.scrollContainer.onMove({ x, y });

    if (topDist >= 0 && bottomDist >= 0) {
      // between top and bottom sensitive area
      this.animSpeed = 0;
    } else if (topDist < 0) {
      // inside top sensitive area
      this.animSpeed = topDist;
    } else if (bottomDist < 0) {
      // inside bottom sensitive area
      this.animSpeed = -bottomDist;
    }
  };

  onTouchMove: EventListener = throttle100ms(({ touches }: TouchEvent) => {
    this.onMove(touches[0].clientX, touches[0].clientY);
  });

  onMouseMove: EventListener = throttle100ms(({ clientX, clientY }: MouseEvent) => {
    this.onMove(clientX, clientY);
  });

  scroll = () => {
    const now = Date.now();
    const deltaT = now - this.last;
    this.last = now;
    const coordinates = this.scrollContainer.scroll(this.animSpeed, deltaT);
    this.listener && coordinates && this.listener(coordinates.x, coordinates.y);
    this.frame = window.requestAnimationFrame(this.scroll);
  };
}
