import { type Ruler } from '../../gizmo-utils/configuration';
import { CLOCK_SIZE } from './constants';
import { type ClockGizmoContent } from '@bettermarks/gizmo-types';

/**
 * Clock inside a formula should always be middled-centered.
 *
 */
export const measureClock: Ruler<ClockGizmoContent> = (_) => ({
  height: CLOCK_SIZE,
  refLine: CLOCK_SIZE * 0.5,
});
