import { isEmpty, isNil } from 'lodash';
import {
  type BChildDecoration,
  type BLayoutContent,
  type BorderChild,
  DECORATION,
  DEFAULT_B_LAYOUT_CONTENT,
  identity,
  type Importer,
  isHAlign,
  isVAlign,
  type LayoutContainerOrientation,
  OrientationWithWidth,
  toFloat,
  toInt,
  $MROW,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import { filterForChildGizmo } from './importChildren';

type BorderDecoKeys = keyof BChildDecoration;

export const parseDecoration = (decoString: string): BChildDecoration => {
  const { object } = parseDecoString<BorderDecoKeys>(decoString);

  const decoration: BChildDecoration = {};
  if (object.backgroundColor) {
    decoration.backgroundColor = object.backgroundColor;
  }
  if (object.paddingLeft) {
    decoration.paddingLeft = parseInt(object.paddingLeft, 10);
  }
  if (object.paddingRight) {
    decoration.paddingRight = parseInt(object.paddingRight, 10);
  }
  if (object.paddingTop) {
    decoration.paddingTop = parseInt(object.paddingTop, 10);
  }
  if (object.paddingBottom) {
    decoration.paddingBottom = parseInt(object.paddingBottom, 10);
  }
  if (isHAlign(object.horizontalAlign)) {
    decoration.horizontalAlign = object.horizontalAlign;
  }
  if (isVAlign(object.verticalAlign)) {
    decoration.verticalAlign = object.verticalAlign;
  }
  return decoration;
};

const distributeWidth = (oContent: BorderChild[]): BorderChild[] => {
  const { wSum, noW } = oContent.reduce(
    (acc, c) =>
      c.width
        ? { ...acc, wSum: acc.wSum + c.width }
        : {
            ...acc,
            ...(c.orientation in OrientationWithWidth && { noW: acc.noW + 1 }),
          },
    { wSum: 0, noW: 0 }
  );
  if (wSum === 1) {
    return oContent;
  } else {
    const wPerNoW = (1 - wSum) / noW;
    return oContent.map((c) =>
      isNil(c.width) && c.orientation in OrientationWithWidth ? { ...c, width: wPerNoW } : c
    );
  }
};

export const importBorderLayout: Importer<BLayoutContent> = (preContent, xml, context) => {
  context.childrenAreRoot = true;
  const contentRoot = xml.findChildTag($MROW);

  const config = contentRoot.findChildTag('configuration');

  // the below returns NaN in case tag is not present or empty
  const vGap = toInt(config.findChildTag('verticalGap').text);
  const hGap = toInt(config.findChildTag('horizontalGap').text);

  // import n, w, c, e, s boxes with their meta-data
  const constraints = config.getChildrenByTagName('constraint');
  const orientedContent: BorderChild[] = constraints.map((c) => {
    // orientation attrib contains "north", "west", ...
    const orientation = c.attribute('orientation') as LayoutContainerOrientation;
    const decoration = parseDecoration(c.attribute(DECORATION));

    const mayBeWidth: { width?: number } = {};
    if (c.hasAttribute('width')) {
      const width = toFloat(c.attribute('width'));
      mayBeWidth.width = width;
      context.widthTransformation = (w: number) => w * width - (!isNaN(hGap) ? hGap : 0);
    }

    const child = filterForChildGizmo(c.attribute('refId'), contentRoot)[0];
    const { $refid } = context.importXML(child);

    context.widthTransformation = identity;

    return {
      orientation,
      $refid,
      ...(!isEmpty(decoration) && { decoration }),
      ...mayBeWidth,
    };
  });

  return {
    ...DEFAULT_B_LAYOUT_CONTENT,
    ...preContent,
    layout: 'border',
    content: distributeWidth(orientedContent),
    ...(!isNaN(vGap) && { vGap }),
    ...(!isNaN(hGap) && { hGap }),
  };
};
