import { isNil } from 'lodash/fp';

export interface Maybe<A> {
  map: (f: (_: A) => any) => Maybe<any>;
  ap: (f: (_: A) => any) => any;
  use: (f: (_: A) => any) => Maybe<A>;
}

export const Maybe = <A>(x: Nullable<A>): Maybe<A> => ({
  map: (f) => (isNil(x) ? Maybe(x) : Maybe(f(x))),
  ap: (f) => (isNil(x) ? undefined : f(x)),
  use: (f) => {
    !isNil(x) && f(x);
    return Maybe(x);
  },
});
