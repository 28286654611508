import { type Action, type ActionFunctionAny, createAction, handleActions } from 'redux-actions';
import { type CalculatorEvaluationResponse } from '@bettermarks/umc-kotlin';
import { isEmpty } from 'lodash';

import {
  applyImporter,
  type Direction,
  FORMULA_DEFAULT_CONTENT,
  RS,
} from '@bettermarks/gizmo-types';
import { MATH } from '../../../../../../gizmo-utils/constants';
import { CURSOR, importFormula } from '@bettermarks/importers';
import { type CalculatorState } from '../../../../../../types';
import { reduceReducers } from '../../../../../../gizmo-utils/reduceReducers';
import { formulaReducer } from '../../../../../../gizmos/formula/Formula';
import * as Actions from '../../../../../../gizmos/formula/Formula/actions';

export const EVALUATE = 'EVALUATE';
export const evaluate = createAction(EVALUATE);

const EVALUATION_SUCCESS = 'EVALUATION_SUCCESS';
export const evaluationSuccess = createAction<CalculatorEvaluationResponse>(EVALUATION_SUCCESS);

const CLEAR = 'CLEAR';
export const clear = createAction(CLEAR);

const CLEAR_INP = 'CLEAR_INP';
export const clearInp = createAction(CLEAR_INP);

export const initialCalculatorState: CalculatorState = {
  inputFormula: {
    ...FORMULA_DEFAULT_CONTENT(),
    $: MATH,
    $interactionType: RS.FORMULA,
    $renderStyle: RS.INNER_TEXT, // INNER_TEXT for "no input field around the interactive formula"
    content: [CURSOR],
  },
  memoryExpr: '<math><mn>0</mn></math>',
  afterEval: false,
};

export const handleCalculatorActions = handleActions(
  {
    [EVALUATION_SUCCESS]: (
      state: CalculatorState,
      { payload }: Action<CalculatorEvaluationResponse>
    ) => {
      const importedResult = applyImporter(payload.resultExpr, importFormula);
      const withoutError = isEmpty(payload.error);

      return {
        ...state,
        afterEval: withoutError,
        resultFormula: { ...importedResult, $renderStyle: RS.FORMULA },
        error: payload.error !== null ? payload.error : undefined,
        ...(withoutError && { memoryExpr: payload.resultExpr }),
      };
    },
    [CLEAR]: ({ memoryExpr }: CalculatorState) => ({
      ...initialCalculatorState,
      memoryExpr,
    }),
    [CLEAR_INP]: ({ memoryExpr, resultFormula }: CalculatorState) => ({
      ...initialCalculatorState,
      memoryExpr,
      resultFormula,
    }),
  },
  initialCalculatorState
);

export const RESET_AFTER_EVAL_ACTIONS: {
  type: string;
  action: ActionFunctionAny<any>;
  dummyPayload?: Actions.FormulaActionPayload | Direction;
}[] = [
  {
    type: Actions.ENTER_NUMBER,
    action: Actions.enterNumber,
    dummyPayload: '1',
  },
  {
    type: Actions.ENTER_BRACKET,
    action: Actions.enterBracket,
    dummyPayload: '(',
  },
  {
    type: Actions.ENTER_OPERATOR,
    action: Actions.enterOperator,
    dummyPayload: '+',
  },
  {
    type: Actions.ENTER_IDENTIFIER,
    action: Actions.enterIdentifier,
    dummyPayload: 'π',
  },
  { type: Actions.ENTER_FRAC, action: Actions.enterFrac },
  { type: Actions.ENTER_ROOT, action: Actions.enterRoot },
  { type: Actions.ENTER_SQRT, action: Actions.enterSqrt },
  { type: Actions.ENTER_SUP, action: Actions.enterSup },
  { type: Actions.ENTER_SQR, action: Actions.enterSqr },
  { type: Actions.CURSOR_LEFT, action: Actions.cursorLeft },
  { type: Actions.CURSOR_RIGHT, action: Actions.cursorRight },
  { type: Actions.BACKWARD_DELETE, action: Actions.backwardDelete },
];

const applyFormulaReducer = (
  state: CalculatorState,
  action: Action<Actions.FormulaActionPayload | Direction>
): CalculatorState => ({
  ...state,
  inputFormula: formulaReducer(state.inputFormula, action),
  afterEval: RESET_AFTER_EVAL_ACTIONS.find(({ type }) => type === action.type)
    ? false
    : state.afterEval,
});

export const calculatorReducer = reduceReducers(applyFormulaReducer, handleCalculatorActions);
