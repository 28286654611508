import { type Severity } from '@bettermarks/umc-kotlin';
import { MULTIPLE_CHOICE_MULTI } from '../../gizmo-utils/configuration/renderStyles';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { type Annotations, type Content, type ContentReference } from '../../xml-converter/core';

export interface MultipleChoiceMultiContent extends Content {
  readonly configuration: MultipleChoiceMultiConfiguration;
  readonly choices: ChoiceContent[];
  readonly checkedIdx: number[];
}

export const MULTIPLE_CHOICE_MULTI_DEFAULT_CONTENT: Readonly<MultipleChoiceMultiContent> = {
  $: PLACEHOLDER,
  $renderStyle: MULTIPLE_CHOICE_MULTI,
  configuration: {},
  choices: [],
  checkedIdx: [],
};

export type MultipleChoiceMultiConfiguration = {
  buttons?: boolean;
  elementsPerLine?: number;
  placeholder?: Partial<Annotations>;
};

export interface ChoiceContent extends ContentReference {
  severity?: Severity;
}
