import { compact } from 'lodash';
import { getFontMetric } from '../../../utils/fontMetric';
import {
  type ApplyStyles,
  type GizmoStyle,
  type Ruler,
} from '../../../gizmo-utils/configuration/types';
import { GIZMO_FONTSIZE } from '../../components/Gizmo';
import { type GizmoNode, type Graph, NodeType } from '@bettermarks/gizmo-types';

export const applyGraphStyles: ApplyStyles = ({ nodes }: Graph, { formulaStyles }: GizmoStyle) =>
  compact(
    nodes
      .filter((n) => n.type === NodeType.gizmo)
      .map((n: GizmoNode) =>
        n.content
          ? {
              refId: n.content.$refid,
              style: { formulaStyles, scaleProps: {} },
            }
          : undefined
      )
  );

export const measureGraphGizmo: Ruler<Graph> = (_, content) => {
  const height = getFontMetric(GIZMO_FONTSIZE).height;

  return {
    height,
    refLine: height * 0.5,
  };
};
