import { TRANSLATION_KEY } from '../../gizmo-utils/configuration/renderStyles';
import { ANNOTATION_XML, KEY, MATH, RENDER_STYLE, SEMANTICS } from '../../gizmo-utils/constants';
import { importUnknown } from '../../gizmos/unknown/importer';
import { isNil } from 'lodash';
import log from 'loglevel';
import {
  type Annotations,
  type Content,
  type ContentReference,
  type FElement,
  getAnnotationAttribs,
  type ImporterContext,
  isSemanticsKind,
} from '../core';

/**
 * Imports a `math` or `semantic` XML tag by making use of the `ImporterRegistry`
 * provided by `outerContext`.
 * It will use `importUnknown` as a fallback if no importer is mapped in the registry.
 *
 * @param xml: The `math` or `semantic` be imported
 * @param outerContext: a shared context for all subsequent calls to `importContent`
 * or other importers. (Every call to) `importContent` uses `outerContext.next()`
 * to create it's own context, to allocate a unique id ($refid) before calling the actual importer.
 * @param skip: Don't allocate a new $refid and use the current one
 *              (needed to not add skipped formulas to the contentDict)
 * @param preContent - the preContent of the to be skipped formula
 *
 * @returns ContentReference: The actual content is stored in two maps in the given
 * context.
 */
/* eslint-disable-next-line complexity */
export function importContent(
  xml: FElement,
  outerContext: ImporterContext,
  skip = false,
  preContent?: Annotations
): ContentReference {
  const context = skip ? outerContext : outerContext.withNextId();
  const { $refid } = context;

  let annotations = parseCommonAttribs(xml);
  if (skip && preContent) {
    const { posX, posY, vAlign, hAlign } = preContent;
    annotations = {
      ...annotations,
      ...(!isNil(posX) &&
        !isNil(posY) &&
        !isNil(hAlign) &&
        !isNil(vAlign) && {
          posX,
          posY,
          vAlign,
          hAlign,
        }),
    };
  }

  if (annotations.$id && context.hasXmlId(annotations.$id) && annotations.$interactionType) {
    // Either the id is used multiple times in that tree in the XML,
    // or the same XML is imported more than once.
    // This is only problematic, if the node is interactive, since the id is only used in by the
    // validator
    log.warn({
      message: 'When importing the content tree, multiple interactive nodes have the same id',
      extra: {
        rootPath: context.root,
        nodeId: annotations.$id,
        content: context.contentId,
      },
    });
  }

  let content: Content;
  if (annotations.hidden) {
    content = annotations;
  } else {
    let importer = context.importers[annotations.$renderStyle];
    context.importedRenderStyles.add(annotations.$renderStyle);
    if (isNil(importer)) {
      log.warn(`Importer not found for`, annotations);
      importer = importUnknown;
    }

    content = importer(annotations, xml, context);
  }

  if (content.$id) {
    context.mapXmlId(content.$id, $refid);
  }

  if (!context.content.has($refid)) {
    context.content.set($refid, content);
  }

  return { $refid };
}

export function parseCommonAttribs(xml: FElement): Annotations {
  const result: Annotations = {
    $: MATH,
    $renderStyle: '',
  };

  if (xml.localName === MATH) {
    if (!xml.hasAttribute(RENDER_STYLE) && xml.hasAttribute(KEY)) {
      return {
        ...result,
        $renderStyle: TRANSLATION_KEY,
      };
    } else {
      return {
        ...result,
        ...getAnnotationAttribs(xml),
      };
    }
  } else if (xml.localName === SEMANTICS) {
    const annotationXml = xml.findChildTag(ANNOTATION_XML);
    const element = annotationXml.firstChild;
    if (!isSemanticsKind(element.localName)) {
      throw new Error(`unexpected child tag '${element.localName}' in annotation-xml`);
    }
    return {
      ...result,
      $: element.localName,
      ...getAnnotationAttribs(element),
    };
  } else {
    throw new Error(`expected math or semantics tag but was ${xml.localName}`);
  }
}
