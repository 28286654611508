import {
  ANIMATION_DEFAULT_CONTENT,
  type AnimationContent,
  type Importer,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';

export const importAnimation: Importer<AnimationContent> = (preContent, xml) => {
  // Images scan also appear inside an mrow - Maybe, for animations, the same rules apply
  const rootElement = xml.hasChild('mrow') ? xml.findChildTag('mrow') : xml;
  // source without extension, remove .swf extension!
  const source = rootElement.findChildTag('source').text.replace(/.swf$/, '');

  const { width, height } = rootElement.tagsToProps(xmlTextToInt, ['width', 'height']);
  return {
    ...ANIMATION_DEFAULT_CONTENT,
    ...preContent,
    source,
    width,
    height,
  };
};
