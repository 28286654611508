import styled, { css } from 'styled-components';
import { defaultTheme } from '@seriesplayer/common-ui';

interface DialogContentProps {
  inBook?: boolean;
}

export const DialogContent = styled.div`
  padding: ${defaultTheme.dimensions.spaceS};
  overflow-x: hidden;
  overflow-y: auto;
  ${(props: DialogContentProps) =>
    props.inBook &&
    css`
      background-color: ${defaultTheme.colors.cWhite};
      height: 100%;
    `}
  & > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
