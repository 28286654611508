import * as React from 'react';
import { identity } from 'lodash/fp';
import { type TFunction } from 'i18next';

import { type FormulaContent, type PathHandler } from '@bettermarks/gizmo-types';
import { ExerciseToolTip } from '../../../Exercise/ExerciseToolTip';
import { CalculatorLayout } from './types';
import { CalculatorInput } from './CalculatorInput';
import { CalculatorResult } from './CalculatorResult';
import { CalculatorKeyboard } from './CalculatorKeyboard';
import { CalculatorScreen } from './CalculatorScreen';
import { USE_CALCULATOR_ASIDE_WIDTH } from './constants';

import styles from './Calculator.scss';

export type CalculatorDispatchProps = {
  onInsert?: () => void;
  onKey: (key: string) => void;
  onSelect: PathHandler;
};

export interface CalculatorStateProps {
  availableWidth: number;
  input: FormulaContent;
  result?: FormulaContent;
  layout?: CalculatorLayout;
  gizmoId?: string;
  enrich?: boolean;
}

export type CalculatorProps = CalculatorStateProps &
  CalculatorDispatchProps & {
    t: TFunction;
  };

export const Calculator: React.FC<CalculatorProps> = ({
  availableWidth,
  enrich = true,
  input,
  layout = CalculatorLayout.VERTICAL,
  result,
  t = identity,
  onKey,
  onInsert,
  onSelect,
}) => {
  // some refs for the tooltip positioning
  const calcRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLDivElement>(null);

  const screenLayout =
    (window.innerWidth <= 909 || window.innerWidth >= USE_CALCULATOR_ASIDE_WIDTH) &&
    window.innerHeight > 375
      ? CalculatorLayout.VERTICAL
      : CalculatorLayout.HORIZONTAL;

  const keyboardLayout =
    (window.innerWidth <= 554 || window.innerWidth >= USE_CALCULATOR_ASIDE_WIDTH) &&
    window.innerHeight > 375
      ? CalculatorLayout.VERTICAL
      : CalculatorLayout.HORIZONTAL;

  return (
    <div ref={calcRef} className={styles.calculator}>
      <CalculatorScreen layout={screenLayout}>
        <CalculatorInput
          content={input}
          availableWidth={availableWidth}
          layout={screenLayout}
          enrich={enrich}
          onKey={onKey}
          onSelect={onSelect}
          ref={inputRef}
        />
        <CalculatorResult
          content={result}
          availableWidth={availableWidth}
          layout={screenLayout}
          enrich={enrich}
          onInsert={onInsert}
          t={t}
        />
      </CalculatorScreen>
      <CalculatorKeyboard onKey={onKey} layout={keyboardLayout} />
      {input.errorMessage && calcRef.current && inputRef.current && (
        <ExerciseToolTip
          element={inputRef.current}
          exercise={calcRef.current}
          toolTipMessage={input.errorMessage}
          show
        />
      )}
    </div>
  );
};

Calculator.displayName = 'Calculator';
