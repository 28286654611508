import { type ActionMeta, createAction, handleAction } from 'redux-actions';
import { ApplicationState, SeriesMode, type TimedActionMeta } from '../../../types';
import { type ValidationPayload } from '../../store/types';
import {
  goToNextStep,
  skipSteps,
  updateCurrentStep,
  updateDragSourceDrawerState,
  updateExercise,
  updateNextStep,
  updateSelection,
} from './helpers';
import { identity } from 'lodash';
import { compose } from 'lodash/fp';
import { resolveTools } from '../containers/Toolbar/reducer/reducer';
import { Lens } from '@bettermarks/gizmo-types';
import { initialSeriesplayerState } from '../../store/constants';
import { timedMetaCreator } from '../../store/helper';

// XXX: This is a suggestion for future structuring of reducer & actions.
// - this approach keeps action & action handler in the same place (because of high cohesion) and
//   removes dependencies to other action handlers and actions (low coupling)
// - if the action is needed somewhere else only this module needs to be imported
// - we could put all actions in a single 'actions' modules, but then all handlers and all calling
//   modules would have to import it (or at least a 'action-constants' module)
//
// As discussed in https://github.com/bettermarks/bm-toolbox/pull/3082#discussion_r330519353 the
// problem with this particular reducer is, that it also imports quite a few other things, namely
// enrichment. So in this particular case it might be beneficial for our build & test performance
// to extract the action from here. I suggest testing this impact when have our reducer structure
// cleaned up in https://bettermarks.atlassian.net/browse/BM-50043

export const VALIDATION_SUCCESS = 'VALIDATION_SUCCESS';
export const validationSuccess = createAction<ValidationPayload, TimedActionMeta>(
  VALIDATION_SUCCESS,
  identity,
  timedMetaCreator
);

export const handleValidationSuccess = handleAction(
  VALIDATION_SUCCESS,
  (
    state: ApplicationState,
    {
      payload: validationPayload,
      meta: { startTime },
    }: ActionMeta<ValidationPayload, TimedActionMeta>
  ) => {
    const { skippedStepIds, nextStep, seriesStatus, exerciseStatus, stepStatus, mode } =
      validationPayload;

    const performNextStep = mode === SeriesMode.test ? goToNextStep(mode, startTime) : identity;

    // Making sure default behavior is retained.
    return compose(
      updateDragSourceDrawerState,
      resolveTools,
      performNextStep,
      updateSelection(stepStatus),
      ApplicationState.toSeriesStatus.set(seriesStatus),
      Lens.update(ApplicationState.toCurrentExercise)(
        compose(
          updateExercise(exerciseStatus, stepStatus, mode),
          updateCurrentStep(validationPayload),
          updateNextStep(stepStatus, nextStep),
          skipSteps(skippedStepIds, mode)
        )
      )
    )(state);
  },
  initialSeriesplayerState
);
