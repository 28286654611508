import { dropRightWhile, uniq } from 'lodash';
import { polynomialRootsMap } from './polynomialRoots';
import { FunctionType } from '@bettermarks/gizmo-types';

/**
 * implements the real roots of the function by adding a trans-
 * lation value y. Can be used as 'inverse function at y'.
 * f(x) = a x + b
 * @param {number} a
 * @param {number} b
 * @returns {(x: number) => number[]} the roots as number Array
 */
export const linearRoots =
  (a: number, b: number) =>
  (y: number): number[] =>
    [(y - b) / a];

/**
 * implements the real roots of the function by adding a trans-
 * lation value y. Can be used as 'inverse function at y'.
 * f(x) = a * (x - d)^2 + e
 * @param {number} a
 * @param {number} d
 * @param {number} e
 * @returns {(x: number) => number[]} the roots as number Array
 */
export const quadraticRoots =
  (a: number, d: number, e: number) =>
  (y: number): number[] =>
    [-1, 1].map(
      (k) =>
        (2 * a * d + k * Math.sqrt(4 * a * a * d * d - 4 * a * (a * d * d + (e - y)))) / (2 * a)
    );

/**
 * implements the real roots of the function by adding a trans-
 * lation value y. Can be used as 'inverse function at y'.
 * f(x) = a*b^(x - d) + e
 * @param {number} a
 * @param {number} b
 * @param {number} d
 * @param {number} e
 * @returns {(y: number) => number[]} the roots as number Array
 */
export const exponentialRoots =
  (a: number, b: number, d: number, e: number) =>
  (y: number): number[] =>
    [(Math.log(-(e - y) / a) + d * Math.log(b)) / Math.log(b)];

/**
 * implements the real roots of the function by adding a trans-
 * lation value y. Can be used as 'inverse function at y'.
 * f(x) = a * log_b(x + d) + e
 * @param {number} a
 * @param {number} b
 * @param {number} d
 * @param {number} e
 * @returns {(y: number) => number[]} the roots as number Array
 */
export const logarithmicRoots =
  (a: number, b: number, d: number, e: number) =>
  (y: number): number[] =>
    b <= 0 || b === 1 || a === 0 ? [] : [Math.pow(b, (y - e) / a) + d];

/**
 * implements the real roots of the function by adding a trans-
 * lation value y. Can be used as 'inverse function at y'.
 * f(x) = a * sin(b * (x + c)) + d
 * @param {number} a
 * @param {number} b
 * @param {number} c
 * @param {number} d
 * @returns {(y: number) => number[]} the roots as number Array
 */
export const sinusRoots =
  (a: number, b: number, c: number, d: number) =>
  (y: number): number[] =>
    []; // currently not supported

/**
 * implements the real roots of the function by adding a trans-
 * lation value y. Can be used as 'inverse function at y'.
 * f(x) = a * x^n + e
 * @param {number} a
 * @param {number} e
 * @param {number} n
 * @returns {(y: number) => number[]} the roots as number Array
 */
export const powerRoots =
  (a: number, e: number, n: number) =>
  (y: number): number[] =>
    [Math.pow(y - e, 1 / n) * Math.pow(1 / a, 1 / n)];

/**
 * implements the real roots of a polynomial of deg 1, 2, 3 or 4
 * by adding a translation value y. Can be used as 'inverse function
 * at y'. Complex roots are omitted. Polynomials of degree 5 or
 * greater are currently not supported!!!
 * deg 1: f(x) = a0 + a1*x
 * deg 2: f(x) = a0 + a1*x + a2*x^2
 * deg 3: f(x) = a0 + a1*x + a2*x^2 + a3*x^3
 * deg 4: f(x) = a0 + a1*x + a2*x^2 + a3*x^3 +a4*x^4
 * @param {number[]} a the coefficient array
 * @returns {(y: number) => number[]} the roots as number Array
 */
export const polynomialRoots =
  (...a: number[]) =>
  (y: number, aMod = [...dropRightWhile([a[0] - y, ...a.slice(1)], (e) => e === 0)]): number[] =>
    aMod.length > 1 && aMod.length < 6
      ? uniq(polynomialRootsMap[aMod.length - 1](...aMod).sort((a: number, b: number) => a - b))
      : [];

/**
 * implements the real roots of the vline
 * by adding a translation value y. Can be used as 'inverse function
 * at y'.
 * @param {e} e the offset param
 * @returns {(y: number) => number[]} the roots as number Array
 */
export const vlineRoots = (e: number) => (y: number) => [e];

// map for getting an inverse function's values at y
export const F_ROOTS_MAP: {
  [key: string]: (...a: number[]) => (y: number) => number[];
} = {
  [FunctionType.linear]: linearRoots,
  [FunctionType.quadratic]: quadraticRoots,
  [FunctionType.exponential]: exponentialRoots,
  [FunctionType.logarithmic]: logarithmicRoots,
  [FunctionType.sinus]: sinusRoots,
  [FunctionType.power]: powerRoots,
  [FunctionType.polynomial]: polynomialRoots,
  [FunctionType.vline]: vlineRoots,
};
