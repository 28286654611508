import * as React from 'react';
import {
  BaseLayout,
  Content,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../../../../components/index';
import { Bar, Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';

type ResetWhiteboardConfirmationProps = {
  title: string;
  mainText1: string;
  mainText2: string;
  confirmText: string;
  cancelText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ResetWhiteboardConfirmation: React.FC<ResetWhiteboardConfirmationProps> = ({
  title,
  mainText1,
  mainText2,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  return (
    <BaseLayout vertical>
      <DialogHeader
        title={title}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
          dataCy: 'back-arrow-dialog-header-btn',
        }}
      />
      <Bar />
      <DialogContent as={Content}>
        <p className="plaintext">{mainText1}</p>
        <p className="plaintext">{mainText2}</p>
        <DialogFooter align="center">
          <Button
            dataCy="confirm-reset"
            key="reset"
            kind={ButtonKind.action}
            stretch={ButtonStretch.responsive}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
          <Button
            dataCy="cancel-reset"
            key="cancel"
            kind={ButtonKind.view}
            stretch={ButtonStretch.responsive}
            onClick={onCancel}
          >
            {cancelText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </BaseLayout>
  );
};
