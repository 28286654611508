import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';
import { DialogFooter } from '../../../../../../components';
import { Content } from './Content';

type UnsupportedProps = {
  onCancel: () => void;
};

const enum TranslationKey {
  unsupportedBody = 'seriesplayer:dialog.reportProblem.unsupported.body',
  unsupportedOk = 'seriesplayer:dialog.reportProblem.unsupported.ok',
}

export const Unsupported: React.FC<UnsupportedProps> = ({ onCancel }) => {
  const [t] = useTranslation();
  return (
    <Content data-cy="unsupported-webview-in-outdated-ios">
      <div className="multi-line-body">{t(TranslationKey.unsupportedBody)}</div>
      <DialogFooter align="center">
        <Button
          key="cancel"
          kind={ButtonKind.action}
          stretch={ButtonStretch.responsive}
          onClick={onCancel}
        >
          {t(TranslationKey.unsupportedOk)}
        </Button>
      </DialogFooter>
    </Content>
  );
};
