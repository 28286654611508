import { type DragScrollBehaviour, type ScrollPosition } from './types';

export const startScrolling = (b: DragScrollBehaviour) => b.startScrolling();

export const stopScrolling = (b: DragScrollBehaviour) => b.stopScrolling();

export const incrementalScroll = (delta: number | ScrollPosition) => (b: DragScrollBehaviour) =>
  b.incrementalScroll(delta);

export const getScrollPosition = (b: DragScrollBehaviour): number | ScrollPosition =>
  b.getScrollAmount();
