import { reduceReducers } from '../../../../gizmo-utils/reduceReducers';
import { type Action, handleAction } from 'redux-actions';
import {
  DEFAULT_SERIES_SETTINGS,
  ExerciseStatus,
  type Series,
  SeriesMode,
  type SeriesScore,
  SeriesStatus,
} from '../../../../types';
import {
  type ExerciseWithStepValidity,
  SET_EXERCISE_STATUS_COMPLETED_AND_VALIDITIES,
  SET_SERIES_SCORE,
  SET_SERIES_STATUS,
} from './actions';

/**
 * Initial Series is empty as no Series has been loaded in the application.
 * In initial state for series:
 * - No exercise is currently selected.
 * - Exercises list is empty
 * - SeriesSettings and title is not available.
 */
const initialSeries: Readonly<Series> = {
  currentExerciseIndex: -1,
  exercises: [],
  id: '',
  seriesStatus: SeriesStatus.started,
  seriesSettings: DEFAULT_SERIES_SETTINGS,
  mode: SeriesMode.practice,
};

const handleSetSeriesStatus = handleAction(
  SET_SERIES_STATUS,
  (series: Series, { payload: seriesStatus }: Action<SeriesStatus>) => ({
    ...series,
    seriesStatus,
  }),
  initialSeries
);

const handleSetSeriesScore = handleAction(
  SET_SERIES_SCORE,
  (series: Series, { payload: seriesScore }: Action<SeriesScore>) => ({
    ...series,
    seriesScore,
  }),
  initialSeries
);

const handleSetExerciseCompletedAndValidities = handleAction(
  SET_EXERCISE_STATUS_COMPLETED_AND_VALIDITIES,
  (series: Series, { payload: exerciseValidities }: Action<ExerciseWithStepValidity[]>) => ({
    ...series,
    exercises: series.exercises.map((e, i) => ({
      ...e,
      status: ExerciseStatus.completed,
      steps: e.steps.map((s, j) => ({
        ...s,
        validity: exerciseValidities[i].steps[j],
      })),
      validity: exerciseValidities[i].validity,
    })),
  }),
  initialSeries
);

/**
 * All actions that affects series state and series navigation are handled here.
 */
export const seriesReducer = reduceReducers(
  handleSetSeriesStatus,
  handleSetSeriesScore,
  handleSetExerciseCompletedAndValidities
);
