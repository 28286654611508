import { kebabCase, toPairs, toString } from 'lodash';
import {
  annotationInner,
  type Cell,
  CELL_ATTRIBUTES,
  type CellDecoration,
  exportContent,
  type Exporter,
  type ExporterContext,
  type Padding,
  type Row,
  ROW_ATTRIBUTES,
  semantics,
  switchMap,
  TABLE_ATTRIBUTES,
  type TableContent,
  type TableLineDecoration,
} from '@bettermarks/gizmo-types';
import { decorationToString } from '../../../gizmo-utils/decoration';

export const exportTable: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as TableContent;

  return exportTableContent(content, context);
};

export function exportTableContent(content: TableContent, context: ExporterContext) {
  return semantics(
    `<mtable ${exportTableProps(content)}>
        ${content.rows
          .map(
            (r) =>
              `<mtr ${exportRowProps(r)}>
            ${(r.originalCells || r.cells)
              .map(
                (c) =>
                  `<mtd ${exportCellProps(c)}>
                ${c.content ? exportContent(c.content.$refid, context) : ''}
              </mtd>`
              )
              .join('')}
          </mtr>`
          )
          .join('')}
      </mtable>`,
    annotationInner(content.$, content)
  );
}

const exportColumnPercents = (cp: number[]): string => cp.map((p) => p * 100).join('|');

const exportWidth = (width: number): string => `${width * 100}%`;

export const exportPadding = (p: Padding): string =>
  `${p.top}px ${p.right}px ${p.bottom}px ${p.left}px`;

const exportLineDecoration = (ld: TableLineDecoration) => ld.color;

const attributeExporter = switchMap<
  (s: string | number | number[] | Padding | CellDecoration | TableLineDecoration) => string
>(
  {
    'column-percents': exportColumnPercents,
    width: exportWidth,
    padding: exportPadding,
    decoration: decorationToString,
    'line-decoration': exportLineDecoration,
  },
  toString
);

function exportTableProps(content: TableContent): string {
  const attribs = toPairs(content)
    .map(([k, v]) => [kebabCase(k), v])
    .filter(([k, v]) => !!TABLE_ATTRIBUTES.find((a) => a === k));
  return attribs.map(([k, v]) => `${k}="${attributeExporter(k)(v)}"`).join(' ');
}

function exportRowProps(row: Row): string {
  const attribs = toPairs(row)
    .map(([k, v]) => [kebabCase(k), v])
    .filter(([k, v]) => !![...ROW_ATTRIBUTES].find((a) => a === k));
  return attribs.map(([k, v]) => `${k}="${attributeExporter(k as string)(v as string)}"`).join(' ');
}

function exportCellProps(cell: Cell): string {
  const attribs = toPairs(cell)
    .map(([k, v]) => [kebabCase(k), v])
    .filter(([k, v]) => !![...CELL_ATTRIBUTES, 'decoration'].find((a) => a === k));
  return attribs.map(([k, v]) => `${k}="${attributeExporter(k as string)(v as string)}"`).join(' ');
}
