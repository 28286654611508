import { KeyCode } from '../Keyboard/keyCodes';
import { type ButtonConfig } from './CalculatorOperatorsBlock';

export const SPECIAL_KEYS_FIXED = [
  KeyCode.BracketLeft,
  KeyCode.Fraction,
  KeyCode.XY,
  KeyCode.Pi, // 1st column
  KeyCode.BracketRight,
  KeyCode.Root,
  KeyCode.Square,
  KeyCode.Ans, // 2nd column
];

export const SPECIAL_KEYS_STRETCHED = [
  KeyCode.BracketLeft,
  KeyCode.Root,
  KeyCode.Pi, // 1st column
  KeyCode.BracketRight,
  KeyCode.XY,
  KeyCode.Ans, // 2nd column
  KeyCode.Fraction,
  KeyCode.Square, // 3rd column
];

export const NUMBER_KEYS_FIXED: ButtonConfig[] = [
  { kc: KeyCode.Num7 },
  { kc: KeyCode.Num8 },
  { kc: KeyCode.Num9 }, // 1st row
  { kc: KeyCode.Num4 },
  { kc: KeyCode.Num5 },
  { kc: KeyCode.Num6 }, // 2nd row
  { kc: KeyCode.Num1 },
  { kc: KeyCode.Num2 },
  { kc: KeyCode.Num3 }, // 3rd row
  { kc: KeyCode.Num0, colspan: 2 },
  { kc: KeyCode.DecimalPoint }, // 4th row
];

export const NUMBER_KEYS_STRETCHED: ButtonConfig[] = [
  { kc: KeyCode.Num7 },
  { kc: KeyCode.Num4 },
  { kc: KeyCode.Num1 }, // 1st column
  { kc: KeyCode.Num8 },
  { kc: KeyCode.Num5 },
  { kc: KeyCode.Num2 }, // 2nd column
  { kc: KeyCode.Num9 },
  { kc: KeyCode.Num6 },
  { kc: KeyCode.Num3 }, // 3rd column
  { kc: KeyCode.Num0, rowspan: 2 },
  { kc: KeyCode.DecimalPoint }, // 4th column
];

export const OPERATOR_KEYS_FIXED: ButtonConfig[] = [
  { kc: KeyCode.Delete, isRed: true },
  { kc: KeyCode.Clear, isRed: true }, // 1st row
  { kc: KeyCode.Subtract },
  { kc: KeyCode.Divide }, // 2nd row
  { kc: KeyCode.Add },
  { kc: KeyCode.Multiply }, // 3rd row
  { kc: KeyCode.Equals, colspan: 2 }, // 4th row
];

export const OPERATOR_KEYS_STRETCHED: ButtonConfig[] = [
  { kc: KeyCode.Delete, isRed: true },
  { kc: KeyCode.Subtract },
  { kc: KeyCode.Add }, // 1st column
  { kc: KeyCode.Clear, isRed: true },
  { kc: KeyCode.Divide },
  { kc: KeyCode.Multiply }, // 2nd column
  { kc: KeyCode.Equals, rowspan: 3 }, // 3rd column
];
