import { Lens, type FormulaContent, type MathContent } from '@bettermarks/gizmo-types';

/**
 * Changes the actual font size of the MText Value in a formula,
 * if it exists.
 *
 * Also set the `lineHeight` correctly for the overlay to fit the top left
 * corner better.
 */
export const setFontSizeInFormula =
  (fontSize: number) =>
  (formulaContent: FormulaContent): FormulaContent => {
    const formulaToMathContent: Lens<FormulaContent, MathContent> = Lens.create('content', 0);
    const mathContentToFontSize: Lens<MathContent, number | undefined> = {
      set: (fontSize) => (mathContent: MathContent) =>
        'decoration' in mathContent
          ? {
              ...mathContent,
              decoration: {
                ...mathContent.decoration,
                fontSize,
                lineHeight: '12px',
              },
            }
          : mathContent,
      get: (mathContent) =>
        'decoration' in mathContent
          ? mathContent.decoration && mathContent.decoration.fontSize
          : undefined,
    };
    const formulaToFontSize = Lens.compose(formulaToMathContent, mathContentToFontSize);
    return formulaToFontSize.set(fontSize)(formulaContent);
  };

export const parseString = (str: string): string => {
  try {
    if (str[0] === '"') {
      return JSON.parse(str);
    }

    if (str[0] === "'" && str.substring(str.length - 1) === "'") {
      return parseString(
        str.replace(/\\.|"/g, (x) => (x === '"' ? '\\"' : x)).replace(/^'|'$/g, '"')
      );
    }

    return JSON.parse(`"${str}"`);
  } catch (e) {
    return str;
  }
};
