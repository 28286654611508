import {
  forEachMathChild,
  type Interactable,
  type PureMathContent,
} from '@bettermarks/gizmo-types';

/**
 * Recursively __modifies__ `content` by setting `interactive` to `true`.
 *
 * @param content
 * @returns content (same instance modified!)
 * @see forEachMathChild
 */
export const INTERACTIVE = <T extends PureMathContent & Interactable>(content: T): T => {
  content.interactive = true;
  forEachMathChild(content, INTERACTIVE);
  return content;
};
