import { type Dict, type ReadonlyDict } from './types';

/**
 * A `ReadonlyMap<string, T>` that only allows to set keys (once) that are not in it already,
 * When adding a key that is already present, the `set` method will raise an `Error`.
 *
 * This way it is safe to pass down this data structure when collecting data,
 * as it is not possible to remove or override values from it.
 *
 * It is not the responsibility of this class to prevent write access to the properties
 * of the values, make sure to wrap `T` into `Readonly` if you want that, e.g.
 *
 * `new AppendOnlyMap<Readonly<MyType>>();`
 *
 * There are two ways of joining multiple `(Readonly)Dict`s and/or `AppendOnlyMap`s,
 * like what you would usually use `Object.assign` for:
 * 1. `AppendOnlyMap.merge` takes any number of instances of those types.ts
 *    (including object literals) and creates a new `AppendOnlyMap`,
 *    in the same that `Object.assign` works.
 * 2. You can use `toDict` on your AppendOnlyMap instances to use them with `Object.assign`,
 *    **but** this will create an intermediate object that will be thrown away by `Object.assign`
 *    **and** you will loose type type information about the values.
 */
export class AppendOnlyMap<T> implements ReadonlyMap<string, T> {
  private readonly dict: Dict<T>;

  /**
   * `AppendOnlyMap` can optionally be initialized by using an object literal.
   * The keys -> value entries will be present in the created instance.
   *
   * @param {Dict<T>} initial values for initializing
   *
   * @see Dict about type inference using the initial value.
   */
  constructor(initial: Dict<T> = {}) {
    this.dict = { ...initial };
  }

  /**
   * If key is not present it will map `key to `value`.
   *
   * @throws if `key` is already present
   *
   * @param {string} key
   * @param {T} value
   * @returns {this} for easy reducing
   */
  set(key: string, value: T): this {
    if (this.has(key)) {
      throw new Error(`dict already has '${key}'`);
    }
    this.dict[key] = value;
    return this;
  }

  /**
   * Convert the current state to a ReadonlyDict<T>.
   *
   * @returns {ReadonlyDict<T>} a snapshot like shallow copy of the current entries.
   */
  toDict(): ReadonlyDict<T> {
    return { ...this.dict };
  }

  // ====================================
  // implementing `ReadonlyMap<string, T>`
  // ====================================

  has(key: string): boolean {
    return key in this.dict;
  }

  get(key: string): T | undefined {
    return this.dict[key];
  }

  entries() {
    return this[Symbol.iterator]();
  }

  keys() {
    return Object.keys(this.dict)[Symbol.iterator]();
  }

  values() {
    return Object.keys(this.dict)
      .map((key) => this.get(key) as T)
      [Symbol.iterator]();
  }

  /**
   * Performs `callbackfn` for each entry that is present, at the time `forEach` is called.
   *
   * @param callbackfn A function that accepts up to three arguments.
   * forEach calls the callbackfn function one time for each entry that present,
   * at the time `forEach` s called.
   */
  forEach(callbackfn: (value: T, key: string, aod: this) => void): void {
    for (const [key, value] of Array.from(this.entries())) {
      callbackfn(value, key, this);
    }
  }

  /**
   * The number of entries currently present.
   */
  get size() {
    return Object.keys(this.dict).length;
  }

  /**
   * This was needed to implement the interface `ReadonlyMap`.
   *
   * This method is used to implement `entries`.
   * `entries` method is used to implement `forEach`.
   * Tests are just written for `forEach`.
   *
   * @returns {IterableIterator<[string , T]>}
   */
  [Symbol.iterator](): IterableIterator<[string, T]> {
    let index = 0;
    const entries = Object.keys(this.dict).map((key) => [key, this.get(key) as T] as [string, T]);
    return {
      [Symbol.iterator]: () => this[Symbol.iterator](),
      next: (): IteratorResult<[string, T]> => ({
        done: index >= entries.length,
        value: entries[index++],
      }),
    };
  }
}
