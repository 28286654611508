export enum LineWeight {
  medium = 'medium',
  thick = 'thick',
  thin = 'thin',
}

export enum LineStyle {
  dashed = 'dashed',
  dashDot = 'dash-dot',
  solid = 'solid',
}
