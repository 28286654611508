import * as React from 'react';
import { ContentHeadline } from '@seriesplayer/common-ui';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';

import { type LineHeaderContent } from '@bettermarks/gizmo-types';

export type LineHeaderProps = LineHeaderContent & ContextState;

/**
 * Connects the [[components/ContentHeadline]] UIComponent to [[../LineHeaderContent]].
 */
export const LineHeader: React.FC<LineHeaderProps> = ({ text, decoration, availableWidth }) => {
  return (
    <ContentHeadline
      availableWidth={availableWidth}
      lineStyle={decoration.lineStyle}
      lineWeight={decoration.lineWeight}
    >
      {text}
    </ContentHeadline>
  );
};

LineHeader.displayName = 'LineHeader';
