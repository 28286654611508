import { Validity } from '@bettermarks/umc-kotlin';
import { type Content, type ContentDict, isEmptyTranslationKey } from '@bettermarks/gizmo-types';
import { type TFunction } from 'i18next';
import { keys, values } from 'lodash';
import { SeriesMode, StepStatus } from '../../../../types';
import { Sound } from '../../providers/Audio';
import { type StepProps } from './StepComponent';

/**
 * Following scenarios for navigation through the exercise needs to be considered:
 * Case 1:
 * - EX1 completed, EX2 completed, EX3 not started.
 * - If user comes back to EX2 after navigating away, always show Feedback, Expanded
 * Explanation & Wrapup.
 * - Logic: Current is completed & next is not started.
 * Case 2:
 * - EX1 completed, EX2 completed, EX3 started.
 * - If user comes back to EX2, always hide feedback, hide Wrapup if any & Collapsed Explanation.
 * - Logic: Current is completed & next is started.
 * Case 3:
 * - EX1 completed, EX2 completed, EX3 completed. (Last exercise completed)
 * - If user comes to EX2, same as case 2 when on EX2.
 * - But from EX2 if user comes to EX3, always show Feedback, Expanded
 * - Logic: Current is completed & there is no next Exercise.
 *
 * Above all cases are handled by simply adding property to the Exercise.
 * When a user moves away from exercise, with nextExercise, this is set to collapse everything.
 */

/**
 * We show feedback only:
 * 1. If Seriesplayer is in QA mode
 * 2. If Seriesplayer is in practice mode:
 *   - when a step is the current active one and is attempted or completed
 *   - (collapseLastStep) when exercise is not in review mode.
 * 3. When Seriesplayer is in test mode:
 *  - Feedbacks are always shown if the series is in TestMode and Step is completed.
 *
 * @param {StepProps} stepProps
 * @returns {false | boolean}
 */
export const showFeedback = ({
  step: { status, id, qaMode },
  currentStepId,
  collapseLastStep,
  mode,
}: StepProps) =>
  qaMode ||
  (mode === SeriesMode.practice &&
    currentStepId === id &&
    (status === StepStatus.attempted || status === StepStatus.completed) &&
    !collapseLastStep) ||
  (mode === SeriesMode.test && status === StepStatus.completed);

/**
 * When the current step is aborted (by show solution) the question is hidden in the component
 * @param stepProps
 */
export const isCurrentAbortedStep = ({ step, currentStepId }: StepProps) =>
  step.aborted && currentStepId === step.id;

/**
 * The explanation has only the active style when it is in the current completed step
 * or qaMode is enabled
 * @param step
 * @param currentStepId
 */
export const isExplanationInactive = ({ step, currentStepId }: StepProps) =>
  !((step.status === StepStatus.completed && currentStepId === step.id) || step.qaMode);

type ValidityAndErrorsTuple = [Validity | undefined, number];

/**
 * Get the sound that has to be played depending on the state transition of validation and number of
 * errors.
 * @param {ValidityAndErrorsTuple} prev - the previous state of validity and number of errors
 * @param {ValidityAndErrorsTuple} cur - the current state of validity and number of errors
 * @returns {Sound | null}
 */
export const getSoundToPlay = (
  [prevValidity, prevNumberOfErrors]: ValidityAndErrorsTuple,
  [curValidity, curNumberOfErrors]: ValidityAndErrorsTuple
): Sound | null => {
  if (curValidity !== prevValidity) {
    switch (curValidity) {
      case Validity.correct:
        return Sound.Correct;
      case Validity.wrong:
        return Sound.Wrong;
      default:
    }
  } else if (curValidity === Validity.wrong && curNumberOfErrors !== prevNumberOfErrors) {
    return Sound.Wrong;
  }

  return null;
};

// method checks the Step's instruction is empty or not
/**
 * As there are quite a lot of the translation values of instruction keys === ""
 * (see e.g. seriesplayer/i18n/en.json, instructions), testing for this in order not to render them
 *
 * @param contentDict
 * @param t
 */
export const isEmptyInstruction = (contentDict: ContentDict, t: TFunction) =>
  keys(contentDict).length === 1
    ? isEmptyTranslationKey(values(contentDict)[0] as Content, t)
    : false;

/**
 * Pick appropriate contentDict to display, based on current mode, step status and step validity.
 * - If a given step is not completed or the user is on the same given step, then show the question
 * (user-input).
 * - If a step is completed and the user is not on the same given step:
 *  - If Series Mode is practice:
 *    - If validity is correct then show the question(user-input).
 *    - If validity is not correct then show the answer.
 *  - If Series Mode is test, then show the question(user-input).
 * @param stepProps
 */
export const getContentDictForQuestion = ({
  step: { status, question, answer, id, validity },
  currentStepId,
  mode,
}: StepProps): ContentDict =>
  status === StepStatus.completed &&
  currentStepId !== id &&
  mode === SeriesMode.practice &&
  validity !== Validity.correct
    ? answer
    : question.present;
