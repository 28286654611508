import type { StyledProps } from './types';
import React from 'react';
import { colors, dimensions } from '@seriesplayer/common-ui';
import { StyledCollapseButton } from './CollapseButton';
import { HoverHandler, SECTION_HEADER_CLASSNAME, StyledSectionHeader } from './CollapsibleSection';

export type CollapsibleChildSectionProps = StyledProps & {
  title: string;
  fullText: React.ReactNode;
  isCollapsed: boolean;
  toggleCollapsed: () => void;
};

export default function CollapsibleChildSection({
  title,
  fullText,
  isCollapsed,
  toggleCollapsed,
}: CollapsibleChildSectionProps) {
  return (
    <HoverHandler>
      <StyledSectionHeader
        className={SECTION_HEADER_CLASSNAME}
        onClick={toggleCollapsed}
        theme={`color: ${colors.cTextDark}; padding: ${dimensions.spaceS}; padding-left: 0;`}
      >
        <StyledCollapseButton
          collapsed={isCollapsed}
          onClick={toggleCollapsed}
          variant={'childSection'}
        />
        <div className="title">{!isCollapsed ? fullText : title}</div>
      </StyledSectionHeader>
    </HoverHandler>
  );
}
