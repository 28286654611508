import * as React from 'react';
import styled, { css } from 'styled-components';

import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';

import { type NLPTextInputContent, PIXEL_PER_CHAR } from '@bettermarks/gizmo-types';
import { Validity } from '@bettermarks/umc-kotlin';
import { colors, dimensions } from '@seriesplayer/common-ui';

export type NLPTextCallbacks = {
  onTextChange: (text: string) => void;
};

export type NLPTextProps = NLPTextInputContent & ContextState & NLPTextCallbacks;

const PADDING = 12;

const borderByValidity = {
  [Validity.wrong]: colors.cErrorBorder,
  [Validity.valid]: colors.cRemarkBorder,
};

const NLPTextInputField = styled.input<{ validity?: Validity; focused: boolean }>`
  display: inline-block;
  height: 40px;
  border: 1px solid rgb(153, 153, 153);
  transition: border-color 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;
  padding: 0 ${PADDING}px;
  font-size: ${dimensions.fontSizeM};
  font-family: 'Roboto', sans-serif;
  color: ${colors.cTextDark};
  ${({ validity }) =>
    validity &&
    validity !== Validity.correct &&
    css`
      border: 2px solid ${borderByValidity[validity]};
    `}
  ${({ focused }) =>
    css`
      background-color: ${focused ? colors.cSelectedBackground : colors.cNeutralBackground};
    `}
`;

export const NLPText: React.FC<NLPTextProps> = ({
  content,
  configuration,
  disabled,
  validity,
  $interactionType,
  onTextChange,
}) => {
  const width = Math.max(content.length * PIXEL_PER_CHAR, configuration.minInputWidth);

  const onChange = (evt: React.FormEvent) => {
    evt.persist();
    if (evt.target && content !== (evt.target as HTMLInputElement).value) {
      onTextChange((evt.target as HTMLInputElement).value);
    }
  };

  const [focused, setFocused] = React.useState(false);

  return (
    <NLPTextInputField
      type="text"
      disabled={disabled || !$interactionType}
      value={content}
      style={{ width }}
      onChange={onChange}
      validity={validity}
      focused={focused}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};
