import * as React from 'react';
import { getNamedColor } from '@bettermarks/importers';
import { LineCrossOut } from '../../components/icons';
import {
  type Line as TableLine,
  LineStyle,
  ShadowScrollbars,
  SquaredPaper,
  type TableCellContent,
} from '../components';
import {
  type ContextState,
  renderGizmos,
  useContentTranslation,
} from '../../gizmo-utils/polymorphic-gizmo';
import {
  CarryDigitAlignment,
  type Digit,
  DigitType,
  type SquaredPaperLine as Line,
  LineAlignment,
  LineWeight,
  SQUARED_PAPER_CELL_SIZE,
  type SquaredPaperContent,
} from '@bettermarks/gizmo-types';
import styles from './SquaredPaperGizmo.scss';

export type SquaredPaperProps = SquaredPaperContent & ContextState;

const NBSP = '\u00a0'; // unicode of &nbsp;

const createCellStyle = ({ color, fontWeight, backgroundColor }: Digit): React.CSSProperties => ({
  color: getNamedColor(color),
  backgroundColor: getNamedColor(backgroundColor),
  fontWeight,
});

// select predefined css class for digit types.ts
function getClassName({ type, hasCarryLine, align }: Digit) {
  if (hasCarryLine || (type === DigitType.carry && align === CarryDigitAlignment.top)) {
    return styles.topCarry;
  } else if (type === DigitType.carry && align === CarryDigitAlignment.bottom) {
    return styles.bottomCarry;
  } else if (type === DigitType.borrow) {
    return styles.borrow;
  } else {
    return styles.simple;
  }
}

const createTableCellContent =
  (decimalPoint: string, availableWidth: number) =>
  (digit: Digit): TableCellContent => ({
    coord: digit,
    content: (
      <>
        {digit.decimalPoint && <span className={styles.decimalPoint}>{decimalPoint}</span>}
        {digit.hasCarryLine ? (
          <span className={styles.carryLine}>{digit.value || NBSP}</span>
        ) : (
          digit.value
        )}
        {/* {digit.crossedOut ? <span className={styles.slash}>&#x27cb;</span> : undefined} */}
        {digit.crossedOut && (
          <span className={styles.crossOut}>
            <LineCrossOut width={SQUARED_PAPER_CELL_SIZE} height={SQUARED_PAPER_CELL_SIZE} />
          </span>
        )}
        {digit.overlay && (
          <span className={styles.overlay}>{renderGizmos([digit.overlay], availableWidth)}</span>
        )}
      </>
    ),
    cellStyle: createCellStyle(digit),
    className: getClassName(digit),
  });

const createTableLine = ({ coord, style: lineStyle, lineWeight }: Line): TableLine => {
  const style = lineWeight === LineWeight.thick ? LineStyle.thick : lineStyle || LineStyle.single;

  return { coord, style };
};

/*
  carry lines are a style of the cell content.
  This fn add carryLine property to digits and add a new entry for empty cells
 */
function mergeCarryLines(lines: Line[], digits: Digit[]) {
  lines.forEach(({ coord: { y1: row, x1, x2 } }) => {
    for (let col = x1; col < x2; col++) {
      const digit = digits.find(({ x, y }) => y === row && x === col);
      if (digit) {
        digit.hasCarryLine = true;
      } else {
        digits.push({
          id: '',
          hasCarryLine: true,
          value: '',
          x: col,
          y: row,
        });
      }
    }
  });
  return digits;
}

export const SquaredPaperGizmo: React.FC<SquaredPaperProps> = ({
  availableWidth,
  width,
  height,
  digits,
  lines,
}) => {
  const t = useContentTranslation();
  return (
    <ShadowScrollbars availableWidth={availableWidth}>
      <SquaredPaper
        rows={height}
        columns={width}
        cellSize={SQUARED_PAPER_CELL_SIZE}
        cellContent={mergeCarryLines(
          lines.filter((line) => line.align === LineAlignment.carry),
          digits
        ).map(createTableCellContent(t('formula.decimalpoint'), availableWidth))}
        lines={lines.filter((line) => line.align !== LineAlignment.carry).map(createTableLine)}
      />
    </ShadowScrollbars>
  );
};

SquaredPaperGizmo.displayName = 'SquaredPaperGizmo';
