import styles from './styles.scss';

const { TEXT_WHITE, TEXT_GRAY } = styles;

const textColor: { [key: string]: string } = {
  'bm-red': TEXT_WHITE,
  'bm-yellow': TEXT_GRAY,
  'bm-orange': TEXT_WHITE,
  'bm-beige': TEXT_WHITE,
  'bm-pink': TEXT_WHITE,
  'bm-medium-pink': TEXT_WHITE,
  'bm-purple': TEXT_WHITE,
  'bm-petrol': TEXT_WHITE,
  'bm-blue': TEXT_WHITE,
  'bm-medium-blue': TEXT_WHITE,
  'bm-light-blue': TEXT_GRAY,
  'bm-very-light-blue': TEXT_GRAY,
  'bm-steelblue': TEXT_GRAY,
  'bm-medium-steelblue': TEXT_GRAY,
  'bm-light-steelblue': TEXT_GRAY,
  'bm-very-light-steelblue': TEXT_GRAY,
  'bm-green': TEXT_WHITE,
  'bm-dark-green': TEXT_WHITE,
  'bm-grass-green': TEXT_WHITE,
  'bm-light-green': TEXT_GRAY,
  'bm-black': TEXT_WHITE,
  'bm-grey': TEXT_WHITE,
  'bm-light-grey': TEXT_WHITE,
  'bm-dark-grey': TEXT_WHITE,
};

export const getLabelColor = (name: string) => (name in textColor ? textColor[name] : TEXT_GRAY);
