import { isNil, uniqBy } from 'lodash';
import { type MultipleChoiceSingleContent } from '@bettermarks/gizmo-types';
import { type ApplyStyles, type GizmoStyleForRefId } from '../../gizmo-utils/configuration';

export const applyMCSingleStyles: ApplyStyles = ({ choices }: MultipleChoiceSingleContent, style) =>
  choices.map(({ contentRef: { $refid: refId } }) => ({ refId, style }));

export const applyMCStackLayoutStyles: ApplyStyles = (
  { choices, configuration: { stackLayout } }: MultipleChoiceSingleContent,
  style
) => {
  let result: GizmoStyleForRefId[] = [];
  choices.forEach(({ contentRef, visualContent }) => {
    result = [...result, { refId: contentRef.$refid, style }];
    if (!isNil(visualContent)) {
      result = [...result, { refId: visualContent.$refid, style }];
    }
  });
  if (stackLayout && stackLayout.defaultSelection && stackLayout.defaultSelection.visualContent) {
    result = [...result, { refId: stackLayout.defaultSelection.visualContent.$refid, style }];
  }

  return uniqBy(result, (it) => it.refId);
};
