import {
  connect,
  type ConnectedProps,
  type MapDispatchToPropsFunction,
  type MapStateToProps,
} from 'react-redux';

import { ApplicationState, type LoaderState } from '../../../../types';

import { windowResized } from '../../../seriesplayer/containers/SeriesPlayer/actions';
import { type GizmoRoute } from '@bettermarks/importers';
import { GizmoViewer } from './GizmoViewer';
import { loadGizmo } from './actions';
import { type ResizeCallback } from '../../../../apps/seriesplayer/containers/SeriesPlayer/SeriesPlayer';
import { type Dispatch } from '../../../../gizmo-utils/redux';
import { type ContentDict } from '@bettermarks/gizmo-types';

interface StateProps {
  contentDict?: ContentDict;
  loaderState: LoaderState;
  availableWidth: number;
  isTouch: boolean;
  staticMediaUrl: string;
}
export const mapStateToProps: MapStateToProps<StateProps, unknown, ApplicationState> = (state) => ({
  contentDict:
    state.series.exercises.length > 0 ? ApplicationState.toCurrentQuestion.get(state) : undefined,
  loaderState: state.loaderState,
  availableWidth: state.runtimeState.availableWidth,
  isTouch: state.runtimeState.isTouch,
  staticMediaUrl: state.appSettings.staticMediaUrl,
});

interface DispatchProps {
  onLoadGizmo: (gizmo: GizmoRoute) => void;
  onResize: ResizeCallback;
  dispatch: Dispatch;
}
export const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, unknown> = (
  dispatch
) => ({
  onLoadGizmo: (gizmo: GizmoRoute) => dispatch(loadGizmo(gizmo)),
  onResize: (availableDimensions) => dispatch(windowResized(availableDimensions)),
  dispatch,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type GizmoViewerReduxProps = ConnectedProps<typeof connector>;
export const GizmoViewerContainer = connector(GizmoViewer);
