import { compose, flatMap, groupBy, range, toPairs } from 'lodash/fp';
import { type CellCoordinate, type Line, type LineStyle } from './types';

type CellBorder = {
  edge: 'top' | 'bottom' | 'left' | 'right';
  style: LineStyle;
};

type Cell = {
  cellCoord: CellCoordinate;
};

export type MultipleBorderedCell = Cell & {
  borders: CellBorder[];
};

type BorderedCell = Cell & {
  border: CellBorder;
};

/**
 * In case line coordinates are given from right to left, we need to re-order
 * boundaries for the range fn.
 */
const ensureAscending = (lower: number, greater: number) =>
  lower > greater ? [greater, lower] : [lower, greater];

/**
 * Return the list of cell with border style for vertical lines
 */
const verticalLineCells = (style: LineStyle, ...args: number[]) => {
  const [x1, y1, y2, rows, columns] = args;
  // specific case: right border if the vertical line is on the right paper edge
  const edge = x1 === columns ? 'right' : 'left';
  const x = x1 === rows ? x1 - 1 : x1;
  // in case line coordinates are given from right to left, we need to re-order boundaries
  const [lowerY, greaterY] = ensureAscending(y1, y2);
  return range(lowerY)(greaterY).map<BorderedCell>((y) => ({
    cellCoord: { x, y: y === rows ? y - 1 : y },
    border: { style, edge },
  }));
};

/**
 * Return the list of cell with border style for horizontal lines
 */
const horizontalLineCells = (style: LineStyle, ...args: number[]) => {
  // specific case: bottom border if the horizontal line is on the bottom paper edge
  const [x1, x2, y1, rows, columns] = args;
  const edge = y1 === rows ? 'bottom' : 'top';
  const y = y1 === columns ? y1 - 1 : y1;
  // in case line coordinates are given from right to left, we need to re-order boundaries
  const [lowerX, greaterX] = ensureAscending(x1, x2);
  return range(lowerX)(greaterX).map<BorderedCell>((x) => ({
    cellCoord: { x: x === columns ? x - 1 : x, y },
    border: { style, edge },
  }));
};

/**
 * Generates the list of cells with the correct border to be highlighted
 * according to lines coordinates.
 *
 * Rows and columns # are needed to handle specific cases when lines are drawn on edges
 *
 * @param rows # of rows of the square table
 * @param columns # of columns of the square table
 */
const getBorderedCells = (rows: number, columns: number) => (lines: Line[]) =>
  flatMap<Line, BorderedCell>((line) => {
    const {
      coord: { x1, y1, x2, y2 },
      style,
    } = line;
    let borderedCellList: BorderedCell[] = [];
    if (x1 === x2) {
      borderedCellList = verticalLineCells(style, x1, y1, y2, rows, columns);
    } else if (y1 === y2) {
      borderedCellList = horizontalLineCells(style, x1, x2, y1, rows, columns);
    }
    return borderedCellList;
  })(lines);

/**
 * Reduce lists of `BorderedCell` into a list of `MultipleBorderCell`.
 *
 * @param pairs BorderedCell grouped by cell coordinates
 */
const reduceToMultipleBorderCell = (pairs: [string, BorderedCell[]][]) =>
  pairs.map<MultipleBorderedCell>(([_, cells]) => {
    const head = cells[0];
    return cells.slice(1).reduce<MultipleBorderedCell>(
      (multipleBorderedCell, cell) => ({
        cellCoord: multipleBorderedCell.cellCoord,
        borders: [...multipleBorderedCell.borders, cell.border],
      }),
      { cellCoord: head.cellCoord, borders: [head.border] }
    );
  });

/**
 * Construct the list of cells with specific borders and line style
 * based on the lines we need to draw.
 *
 * To draw a line on the square table, we set borders of specific table data.
 *
 * @param lines  lines with coordinates to be drawn
 * @param rows  # of rows of the square table
 * @param columns # columns of the square table
 */
export const constructBorderedCells = (
  lines: Line[],
  rows: number,
  columns: number
): MultipleBorderedCell[] =>
  compose(
    reduceToMultipleBorderCell,
    toPairs,
    groupBy<BorderedCell>(({ cellCoord: { x, y } }) => `(${x}, ${y})`),
    getBorderedCells(rows, columns)
  )(lines);
