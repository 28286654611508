export const enum ContextNotificationKind {
  correct = 'correct',
  error = 'error',
  remark = 'remark',
  action = 'action',
  neutral = 'neutral',
  attention = 'attention',
}

export type ContextNotificationProps = {
  kind: ContextNotificationKind;
  increasedPadding?: boolean;
  width?: number;
  stretch?: boolean;
  dataCy?: string;
  displayFlex?: boolean;
};
