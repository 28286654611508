import {
  BezierSubtype,
  ContentColor,
  GeoEditorMode,
  type GeoObject,
  type GeoObjectMap,
  isBezier,
  LineWeight,
} from '@bettermarks/gizmo-types';
import { COLOR_FILTER_ID, FILTER_URL_FROM_ID } from '../constants';

export const colorFromMode = (mode: GeoEditorMode) =>
  mode === GeoEditorMode.COLORING
    ? ContentColor.BM_BLUE
    : (mode.replace(`${GeoEditorMode.COLORING}_`, '') as ContentColor);

export const isColorizable = (id: string, geoContentMap: GeoObjectMap<GeoObject>) => {
  const object = geoContentMap[id];
  return object.interactionType && object.interactionType === 'metrics-selection';
};

export const isColored = (id: string, geoContentMap: GeoObjectMap<GeoObject>, color: string) => {
  const object = geoContentMap[id];
  return object.decoration && object.decoration.marked && object.decoration.marked === color;
};

export const colorDecoration = (selectColor: ContentColor, geoObject: GeoObject) => ({
  ...(geoObject.originalDeco || geoObject.decoration),
  // setting `marked` since validator expects it and UI components understand it
  marked: selectColor,
});

export const colorHoverDecoration = (
  selectColor: ContentColor,
  geoObject: GeoObject,
  gizmoId: string
) =>
  isBezier(geoObject) && geoObject.subtype === BezierSubtype.areas
    ? {
        ...geoObject.decoration,
        filter: FILTER_URL_FROM_ID(COLOR_FILTER_ID(gizmoId)),
        fillColor: selectColor,
        fillTransparency: 0.5,
      }
    : {
        ...geoObject.decoration,
        marked: selectColor,
        lineWeight: LineWeight.thick,
      };
