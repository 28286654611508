import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { GIZMO_FONTSIZE } from '../components/Gizmo';
import { type ClockTimeContent } from '@bettermarks/importers';

export const measureClockAndTimeGizmo: Ruler<ClockTimeContent> = (_, content) => {
  const height = GIZMO_FONTSIZE + 6;
  return {
    height,
    refLine: height * 0.5,
  };
};

export const applyClockAndTimeGizmoStyles: ApplyStyles = (
  { time, clock }: ClockTimeContent,
  style
) => {
  const refs = clock ? [time, clock] : [time];
  return refs.map(({ $refid: refId }) => ({ refId, style }));
};
