import classNames from 'classnames';
import { DEFAULT_FONT_SIZE } from '../../../../gizmo-utils/measure';
import * as React from 'react';

import styles from './MathML.scss';

export type MathMLProps = {
  fontSize?: number;
  interactive?: boolean;
  tableCell?: boolean;
  tabIndex?: number;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  inCalculator?: boolean;
};

/**
 * The `MathML' component wraps other MathML components:
 * - `FEMLink`
 * - `MFenced`
 * - `MFrac`
 * - `MI`, 'MN`, `MO` and `MText` aka tokens
 * - `MOver`, `MUnder` and `MUnderOver`
 * - `MRoot` and `MSqrt`
 * - `MRow`
 * - `MSpace`
 * - `MSub`, `MSubSup` and `MSup`
 * - `Cursor`
 * - `Arrow`, `Segment`
 * - `Circumflex`, `Curlybracetop` and `Curlybracebottom` aka StretchedShape
 *
 * Those components are not concerned about measuring but are expecting to receive data,
 * that contains all required information (e.g. `height`, `strokeWidth`)
 * to be able to render correctly.
 *
 * This data is provided by the `Formula` gizmo which uses `enrichMathContent` to calculate
 * the metrics of each node by dispatching to the measure method that is part of each UIComponent.
 *
 * The result of the measurement needs to be in alignment with the layout
 * that the component is applying.
 *
 * `Cursor` component contains properties such as `height` to specify the height for the caret and
 * `still` will helps to disable the cursor's animation effects.
 *
 * To keep the following examples simple we are only showing trivial nesting here,
 * more complex examples can be found in the gizmo viewer.
 */

export const MathML: React.FC<MathMLProps> = ({
  children,
  fontSize = DEFAULT_FONT_SIZE,
  interactive,
  tableCell,
  onFocus,
  onBlur,
  tabIndex,
  inCalculator,
}) => (
  <span
    className={classNames(styles.mathml, {
      [styles.interactive]: interactive,
      [styles.tableCell]: tableCell,
      [styles.calculator]: inCalculator,
    })}
    style={{ fontSize }}
    tabIndex={interactive && onFocus ? tabIndex : undefined}
    onFocus={interactive ? onFocus : undefined}
    onBlur={interactive ? onBlur : undefined}
  >
    {children}
  </span>
);

MathML.displayName = 'MathML';
