import * as React from 'react';
import { isNil } from 'lodash';

import { type Severity } from '@bettermarks/umc-kotlin';
import styles from './Axes.scss';
import {
  type BarChartConfiguration,
  type ContentReference,
  HAlignment,
  VAlignment,
} from '@bettermarks/gizmo-types';
import { Marker } from '../../../components';
import { SVGForeignObject } from '../../../../gizmo-utils/svg-foreign-object/SVGForeignObject';
import {
  DEFAULT_ARROW_LENGTH,
  DEFAULT_AXIS_CONTRACTION_HEIGHT,
  DEFAULT_AXIS_LABEL_MARGIN,
  DEFAULT_AXIS_TICK_LENGTH_MAJOR,
  DEFAULT_AXIS_TICK_LENGTH_MINOR,
  DEFAULT_CHAR_HEIGHT,
  DEFAULT_CHAR_WIDTH,
  DEFAULT_READHELP_LABEL_BORDER_RADIUS,
  DEFAULT_READHELP_LABEL_PADDING,
  DEFAULT_TICK_WIDTH,
  DEFAULT_XAXIS_SPACE,
  DEFAULT_YAXIS_EXTENSION,
  DEFAULT_YAXIS_LABEL_PADDING,
  DEFAULT_YAXIS_SPACE,
  DEFAULT_YAXIS_TICK_LABEL_OFFSET,
} from '../../defaults';
import { AccordionPaths } from '../../../geo/components/AdvancedAxis';
import { getNamedColor } from '@bettermarks/importers';

export type YAxisProps = {
  configuration: BarChartConfiguration;
  readingHelpTicks: { value: number; severity?: Severity }[];
  axisContraction: number;
  id: string;
  label?: ContentReference;
  availableLabelWidth: number;
  additionalXSpace: number;
  interactive: boolean;
  activeValue?: number;
};

export const YAxis: React.FC<YAxisProps> = ({
  activeValue,
  additionalXSpace,
  availableLabelWidth,
  axisContraction,
  configuration,
  id,
  interactive,
  label,
  readingHelpTicks,
}) => {
  const { yTickValueInterval, yTickLabelInterval, yTickValueStart, yTickValueEnd } = configuration;
  const tickCount = (yTickValueEnd - yTickValueStart) / yTickValueInterval + 1;
  const height =
    tickCount * DEFAULT_TICK_WIDTH +
    (interactive ? DEFAULT_YAXIS_EXTENSION : DEFAULT_YAXIS_LABEL_PADDING);
  const additionalLeftSpace =
    DEFAULT_XAXIS_SPACE +
    additionalXSpace +
    (interactive || readingHelpTicks.length > 0 ? DEFAULT_READHELP_LABEL_PADDING : 0);

  return (
    <svg
      width={additionalLeftSpace}
      height={height + DEFAULT_YAXIS_SPACE + axisContraction}
      viewBox={`0 0 ${additionalLeftSpace}
           ${height + DEFAULT_YAXIS_SPACE + axisContraction}`}
      className={styles.overflow}
    >
      <Marker id={`arrowY-${id}`} flip={true} offset={DEFAULT_ARROW_LENGTH} />
      {axisContraction > 0 && (
        <>
          /* tricky part here: accordion part is drawn opposite to screen direction in y */
          <AccordionPaths
            x={additionalLeftSpace}
            y={height + axisContraction - 1}
            yStep={-DEFAULT_AXIS_CONTRACTION_HEIGHT / 2}
          />
          <line
            key={`yAxisTick_origin`}
            x1={additionalLeftSpace - DEFAULT_AXIS_TICK_LENGTH_MAJOR}
            y1={height + axisContraction - 1}
            x2={additionalLeftSpace}
            y2={height + axisContraction - 1}
            className={styles.tick}
          />
          <text
            x={
              additionalLeftSpace - DEFAULT_AXIS_TICK_LENGTH_MAJOR - DEFAULT_YAXIS_TICK_LABEL_OFFSET
            }
            y={height + 4 + axisContraction}
            textAnchor={'end'}
          >
            {0}
          </text>
        </>
      )}
      <line
        x1={additionalLeftSpace}
        y1={0}
        x2={additionalLeftSpace}
        y2={height - 1}
        className={styles.axis}
        markerStart={`url(#arrowY-${id})`}
      />
      {[...Array(tickCount)].map((_, i) => (
        <line
          key={`yAxisTick_${i}`}
          x1={
            additionalLeftSpace -
            (i % yTickLabelInterval === 0
              ? DEFAULT_AXIS_TICK_LENGTH_MAJOR
              : DEFAULT_AXIS_TICK_LENGTH_MINOR)
          }
          y1={height - i * DEFAULT_TICK_WIDTH - 1}
          x2={additionalLeftSpace}
          y2={height - i * DEFAULT_TICK_WIDTH - 1}
          className={styles.tick}
        />
      ))}
      {[...Array(tickCount)].map((_, i) =>
        i % yTickLabelInterval === 0 ? (
          <text
            key={`yAxisTickLabel_${i}`}
            transform={`translate(${
              additionalLeftSpace -
              DEFAULT_AXIS_TICK_LENGTH_MAJOR -
              DEFAULT_YAXIS_TICK_LABEL_OFFSET -
              1
            }, ${height - i * DEFAULT_TICK_WIDTH + 4})`}
            textAnchor={'end'}
            className={styles.tickLabel}
          >
            {yTickValueStart + i * yTickValueInterval}
          </text>
        ) : (
          ''
        )
      )}
      {readingHelpTicks.map((rht, idx) => {
        const tickValue = yTickValueStart + rht.value * yTickValueInterval;
        const digitCount = tickValue.toString().length;
        const tickValueLabelWidth =
          (digitCount + 1) * DEFAULT_CHAR_WIDTH +
          (digitCount - 1) * 2 +
          DEFAULT_READHELP_LABEL_PADDING;
        return (
          <g key={`readingHelpTick_${idx}`}>
            <rect
              key={`readingHelpTickLabelBox_${idx}`}
              x={additionalLeftSpace - DEFAULT_AXIS_TICK_LENGTH_MAJOR - tickValueLabelWidth}
              y={height - rht.value * DEFAULT_TICK_WIDTH - DEFAULT_CHAR_HEIGHT + 1}
              width={tickValueLabelWidth}
              height={DEFAULT_CHAR_HEIGHT + DEFAULT_READHELP_LABEL_PADDING}
              rx={DEFAULT_READHELP_LABEL_BORDER_RADIUS}
              ry={DEFAULT_READHELP_LABEL_BORDER_RADIUS}
              className={styles.readHelpRect}
              style={
                rht.severity && activeValue !== rht.value
                  ? { fill: getNamedColor(rht.severity, true) }
                  : {}
              }
            />
            <text
              key={`readingHelpTickLabel_${idx}`}
              transform={`translate(${
                additionalLeftSpace -
                DEFAULT_AXIS_TICK_LENGTH_MAJOR -
                1 -
                DEFAULT_YAXIS_TICK_LABEL_OFFSET
              },
                ${height - rht.value * DEFAULT_TICK_WIDTH + 4})`}
              textAnchor={'end'}
              className={styles.readHelpText}
            >
              {tickValue}
            </text>
          </g>
        );
      })}
      {!isNil(label) && (
        <SVGForeignObject
          content={label}
          x={additionalLeftSpace - 1 + DEFAULT_AXIS_TICK_LENGTH_MAJOR + DEFAULT_AXIS_LABEL_MARGIN}
          y={DEFAULT_YAXIS_LABEL_PADDING}
          availableWidth={availableLabelWidth}
          hAlign={HAlignment.start}
          vAlign={VAlignment.middle}
        />
      )}
    </svg>
  );
};

YAxis.displayName = 'YAxis';
