import * as React from 'react';
import { defaultTo } from 'lodash';
import { type ContextState } from '../../../../gizmo-utils/polymorphic-gizmo';
import {
  type GeoContent,
  type GeoContentPersistProps,
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type GeoScene,
  GRID,
  type MouseOrTouch,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  dragAction,
  endDragAction,
  geoMovePointsVerticallyReducer,
  initialState,
  snapAction,
  startDragAction,
  stopSnapAction,
} from './geoMovePointsVerticallyReducer';
import { offset, verticallyMovable } from '../helpers';
import { getSnapPoint } from '../../snap';
import { mousePos, transformY } from '@bettermarks/importers';

export type GeoMovePointsVerticallyState = GeoInteractiveBaseState & {
  selectedPoint: {
    id: string;
    screenX: number;
  };
};

export interface GeoMovePointsVerticallyCallbacks {
  readonly onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export type GeoMovePointsVerticallyProps = GeoContent &
  GeoMovePointsVerticallyCallbacks &
  ContextState;

// Actually this tool is the CurveChart-Editor
export const GeoMovePointsVertically: React.FC<GeoMovePointsVerticallyProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoMovePointsVerticallyReducer}
    componentName={`MovePointsVertically:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { geoContentMap, uniqueId, isTouch, matrix, onPersistLocalState, scale } = props;

      const startDrag = (id: string) => (evt: MouseOrTouch) =>
        verticallyMovable(id, geoContentMap) && dispatch(startDragAction({ id, matrix }));

      const pointDrag = (scene: GeoScene, snapPoint: SnapPoint) =>
        dispatch(dragAction({ snapPoint, matrix }));

      const drag =
        (scene: GeoScene, isTouchStart = false) =>
        (evt: MouseOrTouch) => {
          const mouseP = mousePos(
            defaultTo<string>(uniqueId, ''),
            offset(!!isTouch, isTouchStart)
          )(evt);

          const isPointSelected = !!state.selectedPoint.id;

          const mousePWithFixedX = {
            ...mouseP,
            ...(isPointSelected && {
              x: state.selectedPoint.screenX,
            }),
          };

          // The screen's y grows from the top to the bottom of the page, but the coordinate
          // system's y grows from the bottom to the top, so they need to be switched here.
          const screenYMin = transformY(matrix)(props.configuration.display.yMax);
          const screenYMax = transformY(matrix)(props.configuration.display.yMin);
          const screenYWithinRange = Math.min(Math.max(screenYMin, mouseP.y), screenYMax);

          // If subSnappingY is <= 0, use continuous dragging
          const subSnappingExists = props.configuration.subSnapping.y > 0;

          const snapPoint: SnapPoint | null =
            !isPointSelected || (isPointSelected && subSnappingExists)
              ? getSnapPoint(matrix, scene, scale, undefined, isPointSelected)(mousePWithFixedX)
              : { id: '', x: mousePWithFixedX.x, y: screenYWithinRange, snapObject: GRID };

          if (snapPoint) {
            if (isPointSelected || verticallyMovable(snapPoint.id, geoContentMap)) {
              dispatch(snapAction(snapPoint));
            } else {
              dispatch(stopSnapAction());
            }
            pointDrag(scene, snapPoint);
          } else {
            dispatch(stopSnapAction());
          }
        };

      const endDrag = () => dispatch(endDragAction({ onPersistLocalState }));

      const mouseLeave = () => dispatch(endDragAction({}));

      const onTouchStart = (scene: GeoScene) => (evt: MouseOrTouch) => drag(scene, true);

      const geoContentProps = { ...props, ...state.persistProps };

      return (
        <GeoRenderer
          mode={GeoEditorMode.MOVE_POINTS_VERTICALLY}
          onMouseMove={drag}
          onMouseUp={endDrag}
          onPointMouseDown={startDrag}
          onMouseLeave={mouseLeave}
          onTouchStart={onTouchStart}
          snapPoints={state.snapPoints}
          suppressSnapHighlight={true}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoMovePointsVertically.displayName = 'GeoMovePointsVertically';
