import * as React from 'react';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import { GeoEditorMode } from '@bettermarks/gizmo-types';
import { GeoRenderer } from '../../GeoRenderer';
import { persistProps } from '../persist';
import { colorConfigForSelect } from '../helpers';

import {
  deSelectAction,
  geoSelectReducer,
  hoverAction,
  initialState,
  outAction,
  selectAction,
} from './geoSelectReducer';
import { type GeoProps } from '../../Geo';
import { isSelectable, isSelected } from './helpers';

export const GeoSelect: React.FC<GeoProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoSelectReducer}
    componentName={`Select:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { configuration, geoContentMap, onPersistLocalState } = props;
      const selectColor = colorConfigForSelect(configuration);

      const hover = (id: string) => (evt: React.MouseEvent<any>) =>
        isSelectable(id, geoContentMap) && dispatch(hoverAction({ id, selectColor }));

      const toggleSelect = (id: string) => (evt: React.MouseEvent<any>) => {
        isSelectable(id, geoContentMap) &&
          (!isSelected(id, props.geoContentMap)
            ? dispatch(selectAction({ id, onPersistLocalState, selectColor }))
            : dispatch(deSelectAction({ id, onPersistLocalState, selectColor })));
      };

      const out = (id: string) => (evt: React.MouseEvent<any>) => {
        isSelectable(id, geoContentMap) && dispatch(outAction({ id, selectColor }));
      };

      const geoContentProps = { ...props, ...state.persistProps };
      return (
        <GeoRenderer
          mode={GeoEditorMode.SELECT}
          onBezierClick={toggleSelect}
          onBezierHover={hover}
          onBezierMouseOut={out}
          onCircleClick={toggleSelect}
          onCircleOver={hover}
          onCircleLeave={out}
          onPointClick={toggleSelect}
          onPointMouseOver={hover}
          onPointMouseOut={out}
          onLineClick={toggleSelect}
          onLineHover={hover}
          onLineOut={out}
          snapPoints={state.snapPoints}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoSelect.displayName = 'GeoSelect';
