import {
  type HasStroke,
  type MFrac as MFracContent,
  type MRow,
  numberFromStyles,
} from '@bettermarks/gizmo-types';
import * as React from 'react';
import { getFontMetric } from '../../../../../utils/fontMetric';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { Bucket } from '../Bucket';
import { enrich, onClickLeaf } from '../helpers';
import { type MProps, type SelectHandler } from '../types';

import styles from './MFrac.scss';

export const MFRAC_FRACTION_LINE_PADDING = numberFromStyles(styles.FRACTION_LINE_PADDING);
export const MFRAC_SPACE_HORIZONTAL = numberFromStyles(styles.MFRAC_SPACE_HORIZONTAL);

interface MFracSelectHandlers {
  onSelectNumerator?: SelectHandler;
  onSelectFractionLine?: SelectHandler;
  onSelectDenominator?: SelectHandler;
  onSelectOutside?: SelectHandler;
}

interface MFracProps extends MProps, HasStroke, MFracSelectHandlers {
  /**
   * [numerator, denominator]
   */
  children: [JSX.Element, JSX.Element];
  denominatorHeight?: number;
  numeratorHeight?: number;
}

interface DistributedSelectHandlers {
  onDenominator?: React.MouseEventHandler<HTMLElement>;
  onNumerator?: React.MouseEventHandler<HTMLElement>;
  onFractionLine: React.MouseEventHandler<HTMLElement>;
  onOutside?: React.MouseEventHandler<HTMLElement>;
}

export const isOnOuterEdge =
  (margin: number) =>
  ({ currentTarget, pageX }: React.MouseEvent<HTMLElement>): boolean => {
    const { left, width } = currentTarget.getBoundingClientRect();
    return pageX - left <= margin || left + width - pageX <= margin;
  };

/**
 * To make it easier to place the cursor before or after the fraction,
 * the same distance as the margin is subtracted from the overall click area.
 */
const distributeSelectHandlers: (handlers: MFracSelectHandlers) => DistributedSelectHandlers = ({
  onSelectDenominator,
  onSelectNumerator,
  onSelectFractionLine,
  onSelectOutside,
}) => {
  const onDenominator = onClickLeaf(onSelectDenominator);
  const onNumerator = onClickLeaf(onSelectNumerator);
  const onFractionLine = onClickLeaf(onSelectFractionLine);
  const onOutside = onClickLeaf(onSelectOutside);
  const isEdgy = isOnOuterEdge(MFRAC_SPACE_HORIZONTAL * 1.5);
  return {
    onDenominator: (event) => (isEdgy(event) ? onOutside : onDenominator)?.(event),
    onNumerator: (event) => (isEdgy(event) ? onOutside : onNumerator)?.(event),
    onFractionLine: (event) => (isEdgy(event) ? onOutside : onFractionLine)?.(event),
    onOutside,
  };
};

export const MFrac: React.FC<MFracProps> = ({
  children: [numerator, denominator],
  computedStyles: { color, backgroundColor },
  strokeWidth,
  denominatorHeight,
  numeratorHeight,
  ...selectHandlers
}) => {
  const { onDenominator, onFractionLine, onNumerator, onOutside } =
    distributeSelectHandlers(selectHandlers);
  // color is used for the fraction line via css class mfrac-fraction-line
  // backgroundColor is required so it covers the whole fraction
  return (
    <span
      className={styles.mfrac}
      style={{ color, backgroundColor }}
      role="button"
      onMouseDown={onOutside}
    >
      <div role="button" className={styles.mfracNumerator} onMouseDown={onNumerator}>
        <Bucket height={numeratorHeight}>{numerator}</Bucket>
      </div>
      <div
        role="button"
        className={styles.mfracFractionLine}
        style={{ height: strokeWidth }}
        onMouseDown={onFractionLine}
      />
      <div role="button" className={styles.mfracDenominator} onMouseDown={onDenominator}>
        <Bucket height={denominatorHeight}>{denominator}</Bucket>
      </div>
    </span>
  );
};

MFrac.displayName = 'MFrac';

export const enrichMFrac: EnrichNestedMathContent<MFracContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const { enrichedContent: denominator, height: denominatorHeight } = mathContentEnricher(
    formulaStyles,
    content.denominator,
    [...path, 'denominator']
  );
  const { enrichedContent: numerator, height: numeratorHeight } = mathContentEnricher(
    formulaStyles,
    content.numerator,
    [...path, 'numerator']
  );
  const { strokeWidth } = getFontMetric(formulaStyles.fontSize, formulaStyles.fontWeight);
  const height =
    numeratorHeight + denominatorHeight + strokeWidth + 2 * MFRAC_FRACTION_LINE_PADDING;
  const refLine = denominatorHeight + strokeWidth * 0.5 + MFRAC_FRACTION_LINE_PADDING;

  return enrich(
    {
      ...content,
      denominator,
      numerator,
      strokeWidth,
      numeratorHeight,
      denominatorHeight,
    },
    { height, refLine, padding: MFRAC_SPACE_HORIZONTAL },
    formulaStyles
  );
};
