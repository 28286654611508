import { FeedbackBox, ScaffoldingRow } from './styled-components';
import React, { useEffect, useRef } from 'react';
import { type FormulaContent } from '@bettermarks/gizmo-types';
import { insertNewEmptyRow, makeAllRowsNoninteractive, rowValidationFinished } from './actions';
import { equationFeedbacks, termFeedbacks } from './scaffolderFeedbacks';
import ScaffolderButtons from './ScaffolderButtons';
import { useDispatch, useSelector } from 'react-redux';
import {
  EquationValidatorResult,
  FINAL_SKIP_TERM_SOLUTION,
  getFormulaStringContent,
  prettifyOperators,
  validateEquation,
  validateTerm,
  wrongValidatorResults,
} from './helpers';
import { ApplicationState, DEFAULT_MEDIA_ROUTE, gizmoRegistry } from '@seriesplayer/seriesplayer';
import { CheckmarkCircle, CheckmarkCircleFilled } from './icons';
import { useTranslation } from 'react-i18next';
import { GizmoProvider, PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import { isTouchDevice } from '../seriesplayer/services';
import { getFeedbackTextAndColor, getNextFeedbackType } from './feedbackHelpers';
import { usePlaceholderLabels } from './usePlaceholderLabels';
import RowWithTransformation from './equation/RowWithTransformation';
import { rowsFromState, ScaffoldingMode } from './ScaffoldingGizmoRoute';
import TermRow from './term/TermRow';
import ValidatedRowWithTransformation from './equation/ValidatedRowWithTransformation';

export type ScaffoldingGizmoRowsProps = {
  mode: ScaffoldingMode;
  originalExpression: string;
  finished: boolean;
  setFinished: (value: boolean) => void;
  rowValidationNeededFromProps: boolean;
  rowValidationFromPropsFinished: () => void;
};

export enum FeedbackType {
  NONE,
  HINT,
  SUPPORT,
  WRONG,
  DONE,
  DONE_WITH_HELP,
}

export type Feedback = {
  text: string | JSX.Element;
  color: string;
};

export default function ScaffoldingGizmoRows({
  mode,
  originalExpression,
  finished,
  setFinished,
  rowValidationNeededFromProps,
  rowValidationFromPropsFinished,
}: ScaffoldingGizmoRowsProps) {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  let language = i18n.language || 'en';
  if (language.includes('_')) {
    language = language.split('_')[0];
  }

  const rowValidationNeededFromState = useSelector(
    (state: ApplicationState) => state.scaffolder?.rowValidationNeeded
  );
  const rows = useSelector((state: ApplicationState) => rowsFromState(state));
  const transformations = useSelector(
    (state: ApplicationState) => state.scaffolder?.transformations
  );
  const numberOfRows = rows ? Object.keys(rows).length : 0;

  const placeholderLabels = usePlaceholderLabels(numberOfRows === 0);

  const [solvedByStudent, setSolvedByStudent] = React.useState<boolean[]>([]);

  const [currentValidationResult, setCurrentValidationResult] =
    React.useState<EquationValidatorResult>();

  //const [validationResults, setValidationResults] = React.useState<EquationValidatorResult[]>([]);

  const [popupVisible, setPopupVisible] = React.useState<boolean>(false);
  const showPopupButtonRef: React.RefObject<HTMLDivElement> = useRef(null);

  const [currentFeedbackType, setCurrentFeedbackType] = React.useState<FeedbackType>(
    FeedbackType.NONE
  );

  const [feedbacks, setFeedbacks] = React.useState<Feedback[] | undefined>();

  useEffect(() => {
    dispatch(insertNewEmptyRow({ placeholderLabels: placeholderLabels }));
  }, []);

  useEffect(() => {
    if (rowValidationNeededFromState && !rowValidationNeededFromProps) {
      validateCurrentRowAndInsertNewRowIfNeeded(mode);
      dispatch(rowValidationFinished());
      console.log('rowValidationNeededFromState');
    } else if (rowValidationNeededFromProps && !rowValidationNeededFromState) {
      validateCurrentRowAndInsertNewRowIfNeeded(mode);
      rowValidationFromPropsFinished();
      console.log('rowValidationNeededFromProps');
    } else if (rowValidationNeededFromProps && rowValidationNeededFromState) {
      console.warn(
        'Illegal state: row validation was triggered from state and props at the same time!'
      );
      validateCurrentRowAndInsertNewRowIfNeeded(mode);
      dispatch(rowValidationFinished());
      rowValidationFromPropsFinished();
    }
  }, [rowValidationNeededFromState, rowValidationNeededFromProps]);

  const resetFeedbacks = () => {
    setCurrentFeedbackType(FeedbackType.NONE);
    setFeedbacks(undefined);
  };

  const handleShowSolution = (isFinalSolution: boolean) => {
    setSolvedByStudent((prevState) => [...prevState, false]);
    resetFeedbacks();

    if (isFinalSolution) {
      setCurrentFeedbackType(FeedbackType.DONE_WITH_HELP);
      setCurrentValidationResult(EquationValidatorResult.DONE_WITH_HELP);
      setFinished(true);
    } else {
      setCurrentValidationResult(EquationValidatorResult.CORRECT);
    }
  };

  const validateCurrentRowAndInsertNewRowIfNeeded = (mode: ScaffoldingMode) => {
    let allowNextRow = true;

    if (rows) {
      const lastIndex = numberOfRows - 1;
      const row = rows[lastIndex] as unknown as FormulaContent;
      const validationResult =
        mode === 'equation'
          ? validateEquation(row)
          : mode === 'skip'
          ? validateTerm(row, FINAL_SKIP_TERM_SOLUTION)
          : validateTerm(row);
      setCurrentValidationResult(validationResult);

      const isWrong = wrongValidatorResults.includes(validationResult);

      if (!(isWrong && currentFeedbackType === FeedbackType.WRONG)) {
        resetFeedbacks();
      }

      if (isWrong) {
        // If the user made a wrong attempt before, this is needed for the useEffect hook to
        // show the feedback of type FeedbackType.WRONG again after the reset, because the hook
        // only reacts if the currentFeedbackType changes.
        setCurrentFeedbackType(FeedbackType.NONE);
        setCurrentFeedbackType(FeedbackType.WRONG);
        allowNextRow = false;
      } else {
        setSolvedByStudent((prevState) => [...prevState, true]);

        if (validationResult === EquationValidatorResult.DONE) {
          setFinished(true);
          setCurrentFeedbackType(FeedbackType.DONE);
          dispatch(makeAllRowsNoninteractive());
          allowNextRow = false;
        }
      }
    }

    if (allowNextRow) {
      dispatch(insertNewEmptyRow({ placeholderLabels: placeholderLabels }));
      // In the future, we may also need: dispatch(insertNewRowWithContentFromPreviousRow());
    }
  };

  const isInInitialState = numberOfRows <= 1;
  const lastIndex = numberOfRows - 1;
  const secondLastIndex = lastIndex - 1;

  const previousInputAsString = isInInitialState
    ? originalExpression.replace(/\s/g, '') // remove all spaces
    : rows
    ? getFormulaStringContent(rows[secondLastIndex], false)
    : '';

  const condevFeedbacks = mode === 'equation' ? equationFeedbacks : termFeedbacks;
  const { hint, support, expected, expectedTransformation } =
    condevFeedbacks.find((feedback) => feedback.input === previousInputAsString) || {};

  const nextFeedbackType = getNextFeedbackType(currentFeedbackType);
  const nextFeedbackAvailable = () => {
    switch (nextFeedbackType) {
      case FeedbackType.HINT:
        return !!hint;
      case FeedbackType.SUPPORT:
        return !!support;
      default:
        return false;
    }
  };

  const handleShowNextFeedback = () => {
    if (nextFeedbackType && nextFeedbackAvailable()) {
      setCurrentFeedbackType(nextFeedbackType);
    }
  };

  React.useEffect(() => {
    const feedback = getFeedbackTextAndColor(
      currentFeedbackType,
      language,
      hint,
      support,
      currentValidationResult,
      nextFeedbackAvailable(),
      handleShowNextFeedback
    );

    if (feedback) {
      setFeedbacks((prevState) => {
        if (feedback) {
          return prevState ? [...prevState, feedback] : [feedback];
        }
      });
    }
  }, [currentFeedbackType, setFeedbacks]);

  const mergedContentDict = { ...rows, ...transformations };

  if (!rows || numberOfRows < 1) {
    return <></>;
  }

  if (mode === 'equation') {
    if (!transformations) {
      return <></>;
    }

    return (
      <GizmoProvider
        gizmos={gizmoRegistry}
        contentDict={mergedContentDict}
        dispatch={dispatch}
        isTouch={isTouchDevice()}
        selectedGizmoRef={React.createRef()}
        staticMediaUrl={DEFAULT_MEDIA_ROUTE}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* FEEDBACKS ================================================================================================*/}
          {feedbacks &&
            isInInitialState &&
            feedbacks.map((feedback, index) => (
              <FeedbackBox key={`feedback-${index}`} color={feedback.color}>
                {feedback.text}
              </FeedbackBox>
            ))}

          {/* ORIGINAL EQUATION ROW =====================================================================================*/}
          <RowWithTransformation
            left={originalExpression}
            transformation={transformations[`transformations:0`]}
            transformationIndex={0}
          />

          {/* RESULT ROWS ===============================================================================================*/}
          <>
            {Object.keys(rows)
              .slice(0, finished ? lastIndex + 1 : secondLastIndex)
              .map((_, rowIndex) => {
                return (
                  <ValidatedRowWithTransformation
                    key={`scaffolding-gizmo-row-${rowIndex}`}
                    row={rows[rowIndex]}
                    rowIndex={rowIndex}
                    transformation={transformations[`transformations:${rowIndex + 1}`]}
                    solvedByStudent={solvedByStudent[rowIndex]}
                  />
                );
              })}
          </>

          {/* FEEDBACKS ================================================================================================*/}
          {feedbacks &&
            !isInInitialState &&
            feedbacks.map((feedback, index) => (
              <FeedbackBox key={`feedback-${index}`} color={feedback.color}>
                {feedback.text}
              </FeedbackBox>
            ))}

          {/* ROW WITH CURRENT TRANSFORMATION */}
          {!finished && !isInInitialState && (
            <ValidatedRowWithTransformation
              key={`scaffolding-gizmo-row-${secondLastIndex}`}
              row={rows[secondLastIndex]}
              rowIndex={secondLastIndex}
              transformation={transformations[`transformations:${secondLastIndex + 1}`]}
              solvedByStudent={solvedByStudent[secondLastIndex]}
            />
          )}

          {/* CURRENT (EDITABLE) EQUATION ==============================================================================*/}
          {!finished && (
            <ScaffoldingRow key={`scaffolding-gizmo-row-${lastIndex}`}>
              <PolymorphicGizmo availableWidth={400} refid={`${lastIndex}`} />
            </ScaffoldingRow>
          )}

          {/* SUBMIT AND HELP BUTTONS */}
          {!finished && (
            <ScaffolderButtons
              index={lastIndex}
              rows={rows}
              mode={'equation'}
              showPopupButtonRef={showPopupButtonRef}
              popupVisible={popupVisible}
              setPopupVisible={setPopupVisible}
              expectedSolution={expected}
              expectedTransformation={expectedTransformation}
              nextFeedbackAvailable={nextFeedbackAvailable()}
              handleShowNextFeedback={handleShowNextFeedback}
              handleShowSolution={handleShowSolution}
              submit={() => {
                validateCurrentRowAndInsertNewRowIfNeeded('equation');
              }}
            />
          )}
        </div>
      </GizmoProvider>
    );
  }

  // mode === 'term' || mode === 'skip'
  return (
    <GizmoProvider
      gizmos={gizmoRegistry}
      contentDict={mergedContentDict}
      dispatch={dispatch}
      isTouch={isTouchDevice()}
      selectedGizmoRef={React.createRef()}
      staticMediaUrl={DEFAULT_MEDIA_ROUTE}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {/* ORIGINAL TERM ROW =====================================================================================*/}
        <ScaffoldingRow>{prettifyOperators(originalExpression)}</ScaffoldingRow>

        {/* RESULT ROWS ===============================================================================================*/}
        <>
          {Object.entries(rows)
            .slice(0, finished ? lastIndex + 1 : lastIndex)
            .map(([refId, term], rowIndex) => {
              return (
                term && (
                  <TermRow
                    key={`scaffolding-gizmo-row-${rowIndex}`}
                    term={term as unknown as FormulaContent}
                    refId={refId}
                    icon={
                      solvedByStudent[rowIndex] ? <CheckmarkCircleFilled /> : <CheckmarkCircle />
                    }
                  />
                )
              );
            })}
        </>

        {/* FEEDBACKS ================================================================================================*/}
        {feedbacks &&
          feedbacks.map((feedback, index) => (
            <div style={{ marginLeft: '21px' }}>
              <FeedbackBox key={`feedback-${index}`} color={feedback.color}>
                {feedback.text}
              </FeedbackBox>
            </div>
          ))}

        {/* CURRENT (EDITABLE) EQUATION ==============================================================================*/}
        {!finished && (
          <TermRow
            key={`scaffolding-gizmo-row-${lastIndex}`}
            term={rows[lastIndex] as unknown as FormulaContent}
            refId={`${lastIndex}`}
          />
        )}

        {/* SUBMIT AND HELP BUTTONS */}
        {!finished && (
          <ScaffolderButtons
            index={lastIndex}
            rows={rows}
            mode={mode}
            showPopupButtonRef={showPopupButtonRef}
            popupVisible={popupVisible}
            setPopupVisible={setPopupVisible}
            expectedSolution={expected}
            expectedTransformation={expectedTransformation}
            nextFeedbackAvailable={nextFeedbackAvailable()}
            handleShowNextFeedback={handleShowNextFeedback}
            handleShowSolution={handleShowSolution}
            submit={() => {
              validateCurrentRowAndInsertNewRowIfNeeded(mode);
            }}
          />
        )}
      </div>
    </GizmoProvider>
  );
}
