import {
  compose,
  eq,
  filter,
  flatMap,
  fromPairs,
  groupBy,
  isNil,
  map,
  omitBy,
  reduce,
  toPairs,
} from 'lodash/fp';
import {
  ContentDict,
  type DragSourceContent,
  type DropTargetContent,
  isDragSource,
  RS,
  type SetDropTargetContent,
} from '@bettermarks/gizmo-types';
import { resolveDragSource } from '@bettermarks/importers';
import { DROP_TARGET_PADDING, SET_MIN_HEIGHT } from '../../components/DragAndDrop';

type RefIDContentPair = [string, DragSourceContent | DropTargetContent];

/**
 * this helper function prefilters all drag sources and drop targets
 * and returns a list of key/value pairs for further processing
 */
const collectDnDContent: (contentDict: ContentDict) => RefIDContentPair[] = compose(
  filter<RefIDContentPair>(
    ([_, gizmo]) =>
      (gizmo.$renderStyle === RS.DRAG_SOURCE && gizmo.$interactionType === RS.DRAG_SOURCE) ||
      gizmo.$renderStyle === RS.DROP_TARGET ||
      gizmo.$renderStyle === RS.SET_DROP_TARGET
  ),
  toPairs
);

/**
 * Find out if all sizes of all drag sources have been reported.
 * Only if we have all drag source sizes, we can apply them to the target groups.
 */
const allSourceSizesReported = compose(
  reduce((acc, [_, gizmo]) => {
    return (
      acc &&
      (!isNil(gizmo.width) ||
        !isNil(gizmo.height) ||
        gizmo.$renderStyle === RS.DROP_TARGET ||
        gizmo.$renderStyle === RS.SET_DROP_TARGET)
    );
  }, true),
  collectDnDContent
);

type Size = {
  width?: number;
  height?: number;
};

/**
 * Find the maximum size in the list of given drag sources.
 * Drop targets in the list are ignored. Zero sizes are omitted.
 */
const maxSize: (items: RefIDContentPair[]) => Size = compose(
  omitBy(eq(0)),
  reduce<RefIDContentPair, { width: number; height: number }>(
    (acc, [_, gizmo]) =>
      gizmo.$renderStyle === RS.DRAG_SOURCE
        ? {
            width: Math.max(acc.width, gizmo.width || 0),
            height: Math.max(acc.height, gizmo.height || 0),
          }
        : acc,
    { width: 0, height: 0 }
  )
);

/**
 * Apply sizes to each target. Targets are sized either:
 * - like the biggest drag source in their target group
 */
export const autoSizeTargets = (c: ContentDict) => {
  if (!allSourceSizesReported(c)) return c;

  return {
    ...c,
    ...compose(
      fromPairs,
      flatMap(([_, items]) => {
        const size = maxSize(items);
        // now set the maximum size in each drop target
        return map(([key, gizmo]) => {
          if (gizmo.$renderStyle === RS.DROP_TARGET) {
            const g = gizmo as DropTargetContent;
            const ref = resolveDragSource(c, g);
            // Filled targets are sized according to the contained drag item.
            if (!isNil(ref)) {
              // We need to provide the size of the filled source here, so it can be used
              // by measureDropTarget.
              return [
                key,
                {
                  ...g,
                  width: ref.dragSource.width,
                  height: ref.dragSource.height,
                },
              ];
            }
            // empty targets use the max size of the drag sources (so the user cannot guess which
            // source he has to drop here).
            return [key, { ...g, ...size }];
          } else if (gizmo.$renderStyle === RS.SET_DROP_TARGET) {
            const g = gizmo as SetDropTargetContent;
            let minHeight = Math.max(size.height || 0, SET_MIN_HEIGHT);
            if (g.minHeight) {
              minHeight = Math.max(g.minHeight, (size.height || 0) + 2 * DROP_TARGET_PADDING);
            }
            return [
              key,
              {
                ...g,
                minWidth: size.width,
                minHeight,
              },
            ];
          }
          // drag sources go untouched
          return [key, gizmo];
        }, items);
      }),
      toPairs,
      groupBy(([_, gizmo]) => gizmo.targetGroup),
      collectDnDContent
    )(c),
  };
};

/**
 * Auto size drag sources according to their settings:
 * - autoSize alone will size all other auto sized drag sources.
 * - none autoSize drag sources are left untouched.
 */
export const autoSizeSources = (c: ContentDict) => {
  if (allSourceSizesReported(c)) {
    return {
      ...c,
      // read from bottom to top!
      ...compose(
        fromPairs, // make it an object again
        // apply sizes to items in same group
        flatMap(([group, items]) => {
          const size = maxSize(items);
          if (group !== 'none') {
            // apply max size of group and return
            return map(([key, gizmo]) => [key, { ...gizmo, ...size }], items);
          }
          return items;
        }),
        toPairs, // -> [groupid, RefIDContentPair[]]
        groupBy(([_, gizmo]) => {
          // groups items which share their size
          if (isDragSource(gizmo) && gizmo.autoSize) {
            return gizmo.targetGroup;
          } else {
            return 'none';
          }
        }),
        collectDnDContent // -> RefIDContentPair[]
      )(c),
    };
  }
  return c;
};

export const setDragSourceSize =
  (id: string, width: number, height: number, left: number, top: number) => (c: ContentDict) => {
    const ds = ContentDict.content<DragSourceContent>(c, id);
    return ds
      ? {
          ...c,
          [id]: {
            ...ds,
            ...(ds.width && ds.width > width ? {} : { width }),
            ...(ds.height && ds.height > height ? {} : { height }),
            pos: {
              translateX: left,
              translateY: top,
            },
          },
        }
      : c;
  };

export const applyDragSourceSize = (
  id: string,
  width: number,
  height: number,
  left: number,
  top: number
) => compose(autoSizeTargets, autoSizeSources, setDragSourceSize(id, width, height, left, top));
