import * as React from 'react';

import { PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';

import { type TableRendererContent } from '../../types';
import { borderFromStyle } from '../../helper';
import { DecoratedCell } from '../DecoratedCell';

export const ContainerTable: React.FC<TableRendererContent> = (table) => (
  <table
    style={{
      border: borderFromStyle(table.borderStyle),
      // do not compress cells on small screens (otherwise arrows off)
      tableLayout: 'fixed',
      width: table.rows[0].cells.reduce((acc, c) => acc + (c.width || 0), 0),
    }}
  >
    <tbody>
      {table.rows.map((r, i) => (
        <tr key={i}>
          {r.cells.map((c, j) => {
            return (
              <DecoratedCell key={j} {...c}>
                {c.content && (
                  <PolymorphicGizmo refid={c.content.$refid} availableWidth={c.width || 0} />
                )}
              </DecoratedCell>
            );
          })}
        </tr>
      ))}
    </tbody>
  </table>
);

ContainerTable.displayName = 'ContainerTable';
