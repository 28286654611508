import * as React from 'react';
import styles from './Comparison.scss';
import { simpleHash } from '../../gizmo-utils/simpleHash';

type ComparisonProps = {
  url1: string;
  url2: string;
};

export const Comparison: React.FC<ComparisonProps> = ({ url1, url2 }) => (
  <div className={styles.wrapper}>
    {[url1, url2].map((url, i) => (
      /*eslint-disable-next-line react/iframe-missing-sandbox*/
      <iframe key={`${simpleHash(url)}_${i}`} src={url} className={styles.halfASeriesplayer} />
    ))}
  </div>
);
