import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { MouseCursor, RS } from '@bettermarks/gizmo-types';
import {
  type BarChartConfiguration,
  type BarChartContent,
  type PieOrStackedBarChartContent,
} from '@bettermarks/gizmo-types';

export const BARCHART_DEFAULT_CONFIGURATION: BarChartConfiguration = {
  xAxisArrow: false,
  yTickLabelInterval: 0,
  yTickValueInterval: 0,
  yTickValueStart: 0,
  yTickValueEnd: 1,
};

export const BARCHART_DEFAULT_CONTENT: BarChartContent = {
  $: PLACEHOLDER,
  $renderStyle: RS.DIAGRAM_BAR_CHART,
  configuration: BARCHART_DEFAULT_CONFIGURATION,
  groups: [],
  axisContraction: 0,
  yAxisHeight: 0,
  totalHeight: 0,
  cursor: MouseCursor.default,
  labeledGroups: 0,
};

export const STACKED_BARCHART_DEFAULT_CONTENT: PieOrStackedBarChartContent = {
  $: PLACEHOLDER,
  uniqueId: '0',
  $renderStyle: RS.STACKED_BAR_CHART,
  hasLegend: true,
  slices: [],
  slicesWithLabels: 0,
  needsBorder: false,
};

export const PIE_CHART_DEFAULT_CONTENT: PieOrStackedBarChartContent = {
  $: PLACEHOLDER,
  uniqueId: '0',
  $renderStyle: RS.PIE_CHART,
  hasLegend: true,
  slices: [],
  slicesWithLabels: 0,
  needsBorder: false,
};
