import { type Content, type Defaults } from '../../xml-converter/core';
import { WHEEL_OF_FORTUNE } from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';

export type Sector = Readonly<{
  color: string;
  label?: number | string;
}>;

export interface WheelOfFortuneContent extends Readonly<Content> {
  readonly sectors: Sector[];
}

export const WHEEL_OF_FORTUNE_DEFAULT_CONTENT: Defaults<WheelOfFortuneContent> = {
  $: SPECIAL,
  $renderStyle: WHEEL_OF_FORTUNE,
  sectors: [],
};
