import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { ApplicationState } from '../../../../../types';
import { emRequested } from '../../../../emplayer/actions';
import { type FEMDispatchProps, FEMPlayer } from '../../../../emplayer/FEMPlayer';
import { toPreviousDialog } from '../../SeriesPlayer/actions';

import type { FEMStateProps } from '../../../../emplayer/FEMPlayerRoute';

const mapDispatchToProps = (dispatch: Dispatch): FEMDispatchProps => ({
  onCancel: () => dispatch(toPreviousDialog()),
  onInit: (initParams) => dispatch(emRequested(initParams)),
  dispatch,
});

type OwnProps = {
  id: string;
  onCloseFromWhiteboard?: () => void;
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): FEMStateProps => {
  let exerciseLocale: string | undefined;
  try {
    exerciseLocale = ApplicationState.toCurrentExercise.get(state).locale;
  } catch (e) {
    // This is a standalone FEM; there is no exercise.
  }

  const { data, status } = state.emData.fems;
  const { isTouch } = state.runtimeState;
  const id = ownProps.id ?? '';

  return {
    femData: data && data[id],
    /*
     * For the FEM dialog type, the payload must be defined and a string representing the FEM id.
     */
    id,
    isTouch,
    locale: exerciseLocale || '',
    status: status,
    staticServerUrl: state.appSettings.staticServerUrl,
    staticMediaUrl: state.appSettings.staticMediaUrl,
    encrypt: true,
    onCloseFromWhiteboard: ownProps.onCloseFromWhiteboard,
  };
};

export const FEMContainer = connect(mapStateToProps, mapDispatchToProps)(FEMPlayer);
