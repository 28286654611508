/**
 * This function calculates the available width for the left and right slider depending
 * on the min representation and min slider width.
 * If there is no space for either one of them or both, then this function returns, that
 * there is no space so the calling component can add the non-fitting sliders into the bottom sliders.
 *
 * @param screenWidth whole screen width
 * @param minMiddleWidth The width of the middle element, e.g. dynamic representation width
 * @param leftSide If there are no left elements, this is zero, if there are left elements,
 * this should be the min size the elements are allowed to have.
 * @param rightSide If there are no right elements, this is zero, if there are right elements,
 * this should be the min size the elements are allowed to have.
 * @param margin This is margin in between the left/middle/right element.
 * This is how the layout is intended:
 * <- margin -> <- leftSide -> <- margin -> <- minMiddleWidth -> <- margin -> <- rightSide -> <- margin ->
 *
 * returns [availableLeftSize, availableMiddleSize, availableRightSize, leftHasSpace, rightHasSpace]
 */

export const getDimensions = (
  screenWidth: number,
  minMiddleWidth: number,
  leftSide = 0,
  rightSide = 0,
  margin = 10
): [
  availableLeftSize: number,
  availableMiddleSize: number,
  availableRightSize: number,
  leftHasSpace: boolean,
  rightHasSpace: boolean
] => {
  const marginHorizontal = (leftSide > 0 ? 1 : 0) * margin + (rightSide > 0 ? 1 : 0) * margin;
  const screenWidthWithoutMargin = screenWidth - marginHorizontal;
  // screen is smaller than min middle width or no other elements on screen
  if (screenWidthWithoutMargin < minMiddleWidth || (leftSide === 0 && rightSide === 0)) {
    return [0, screenWidth, 0, false, false];
  }
  // There is no left elements, but right elements
  if (leftSide === 0 && rightSide > 0) {
    const redundantSpace = screenWidthWithoutMargin - (minMiddleWidth + rightSide + margin);
    if (redundantSpace < 0) {
      return [0, screenWidth, 0, false, false];
    } else {
      return [0, minMiddleWidth + redundantSpace / 2, rightSide + redundantSpace / 2, false, true];
    }
  }
  // There is no right elements, but left elements
  if (rightSide === 0 && leftSide > 0) {
    const redundantSpace = screenWidthWithoutMargin - (minMiddleWidth + leftSide + margin);
    if (redundantSpace < 0) {
      return [0, screenWidth, 0, false, false];
    } else {
      return [leftSide + redundantSpace / 2, minMiddleWidth + redundantSpace / 2, 0, true, false];
    }
  }
  // Left and right elements are here, left Element have priority.
  const redundantSpaceWithAllElements =
    screenWidthWithoutMargin - (minMiddleWidth + rightSide + leftSide + 2 * margin);
  // all elements fit
  if (redundantSpaceWithAllElements >= 0) {
    return [
      leftSide + redundantSpaceWithAllElements / 3,
      minMiddleWidth + redundantSpaceWithAllElements / 3,
      rightSide + redundantSpaceWithAllElements / 3,
      true,
      true,
    ];
  }
  // from this point on, we know, that only the right element can fit
  const redundantSpaceWithRightElement =
    screenWidthWithoutMargin - (minMiddleWidth + rightSide + margin);
  // right element fits
  if (redundantSpaceWithRightElement >= 0) {
    return [
      0,
      minMiddleWidth + redundantSpaceWithRightElement / 2,
      rightSide + redundantSpaceWithRightElement / 2,
      false,
      true,
    ];
  }
  // right element does not fit.
  return [0, screenWidth, 0, false, false];
};
