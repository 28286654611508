import * as React from 'react';
import {
  type Align,
  FlexAlign,
  StyledHLayout,
  StyledVLayout,
  HLayoutChildWrapper,
  VLayoutChildWrapper,
} from '@seriesplayer/common-ui';
import { Layout, type LayoutContainerContent } from '@bettermarks/gizmo-types';
import { BLayout } from './components';
import { type ContextState, renderGizmos } from '../../gizmo-utils/polymorphic-gizmo';

export const ALIGNMENT_MAP: Align = {
  center: FlexAlign.center,
  left: FlexAlign.flexStart,
  right: FlexAlign.flexEnd,
  middle: FlexAlign.center,
  top: FlexAlign.flexStart,
  bottom: FlexAlign.flexEnd,
};

export type LayoutGizmoProps = LayoutContainerContent & ContextState;

const wrapVLayoutChild = (child: any, i: number) => (
  <VLayoutChildWrapper key={i} noMargin={i === 0}>
    {child}
  </VLayoutChildWrapper>
);

const wrapHLayoutChild = (child: any, i: number) => (
  <HLayoutChildWrapper key={i}>{child}</HLayoutChildWrapper>
);

// we decided to not name the component LayoutContainer, since it is not a React container
export const LayoutGizmo: React.FC<LayoutGizmoProps> = (props) => {
  switch (props.layout) {
    case Layout.Vertical:
      return (
        <StyledVLayout
          align={ALIGNMENT_MAP[props.hAlignment]}
          gap={props.gap}
          stretch={props.fullWidth}
        >
          {renderGizmos(props.content, props.availableWidth).map(wrapVLayoutChild)}
        </StyledVLayout>
      );
    case Layout.Horizontal:
      return (
        <StyledHLayout align={ALIGNMENT_MAP[props.vAlignment]} gap={props.gap}>
          {renderGizmos(props.content, props.availableWidth).map(wrapHLayoutChild)}
        </StyledHLayout>
      );
    case Layout.Border:
      return <BLayout {...props} />;
    default:
      // TODO: this is the fallback for currently unsupported layouts
      return (
        <StyledVLayout>
          {/* fontSize and lineHeight are here to circumvent formula setting it to 0 */}
          {[
            <div
              key="layout-gizmo-default-case"
              style={{
                color: '#BB3300',
                background: '#E9E9E9',
                fontSize: 20,
                lineHeight: 1,
              }}
            >
              {props.$renderStyle}:{props.layout}
            </div>,
            ...renderGizmos(props.content, props.availableWidth),
          ].map(wrapVLayoutChild)}
        </StyledVLayout>
      );
  }
};

LayoutGizmo.displayName = 'LayoutGizmo';
