import * as React from 'react';
import classNames from 'classnames';
import { cond, stubTrue } from 'lodash';
import { type TFunction } from 'i18next';

import { ShadowScrollbars } from '../../components';

import { borderFromStyle, cellWidth } from '../helper';
import { PolymorphicGizmo, useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';
import { type RendererCell, type RendererRow, type TableRendererProps } from '../types';
import { DecoratedCell } from './DecoratedCell';
import styles from './HScrollTable.scss';
import { TableStyle } from '@bettermarks/gizmo-types';

const getMinWidth = (
  tableStyle: TableStyle,
  availableWidth: number,
  percentWidth: number,
  maxRowWidthFlash: number,
  cellWidth = 0,
  columnPercent = 0,
  tablePercentW = 1
) =>
  tableStyle === TableStyle.PLACE_VALUE
    ? cellWidth
    : cellWidth ||
      (availableWidth < maxRowWidthFlash * percentWidth
        ? // extend to full availableWidth, even if percentWidth < 1
          // (floor - to not exceed availableWidth because of bad rounding)
          Math.floor((availableWidth / percentWidth) * columnPercent * tablePercentW)
        : // respect columnPercent in relation to flash max width
          maxRowWidthFlash * columnPercent * tablePercentW);

/**
 * Insert column with decimal separator, if postDecimals given on "place-value-table"
 * @param style
 * @param row
 * @param rowIndex
 * @param cellIndex
 * @param postDecimals
 * @param t
 */
const decimalSeparatorForPlaceValueTable = (
  style: TableStyle,
  row: RendererRow,
  rowIndex: number,
  cellIndex: number,
  postDecimals?: number,
  t?: TFunction
) =>
  style === TableStyle.PLACE_VALUE &&
  postDecimals !== undefined &&
  cellIndex === row.cells.length - postDecimals && (
    <td className={styles.decimalPoint}>
      {rowIndex > 1 ? (t ? t('formula.decimalpoint') : '.') : ''}
    </td>
  );

export const HScrollTable: React.FC<TableRendererProps> = (props) => {
  const {
    columnPercents,
    width,
    availableWidth,
    style,
    withEqualSign,
    borderStyle,
    rows,
    noScale,
    maxRowWidth,
    maxRowWidthFlash,
    percentWidth,
    postDecimals,
  } = props;
  const t = useContentTranslation();

  const maxWidth =
    width || columnPercents
      ? // if any percents of table/column widths are given, apply them to maxRowWidth!!!
        percentWidth * maxRowWidth
      : // use availableWidth if no percent widths are given
        availableWidth;
  const avWidth = Math.min(maxWidth, availableWidth);
  const table = (
    <table
      className={classNames(styles.noWrap, {
        [styles.strict]:
          style === TableStyle.EQNARRAY || style === TableStyle.LINEAR_EQN_SYSTEM || withEqualSign,
        [styles.matrix]: style === TableStyle.MATRIX,
      })}
      style={{
        border: borderFromStyle(borderStyle),
      }}
    >
      <tbody>
        {rows.map((r, i) => {
          const colSpans = r.cells.reduce(
            (acc, cur) =>
              acc.length === 0 ? [cur.colspan] : [...acc, cur.colspan + acc[acc.length - 1]],
            []
          );
          return (
            <tr key={i} className={styles.fullHeight}>
              {r.cells.map((c, j) => {
                const maxCellW = cond<{ t: TableRendererProps; c: RendererCell }, number>([
                  [({ c }) => !!c.width, ({ c }) => c.width || 0],
                  [
                    ({ t }) => !!t.columnPercents,
                    ({ t }) =>
                      cellWidth(
                        t.columnPercents || [],
                        j,
                        percentWidth,
                        maxWidth - t.limitAvailableWidthBy,
                        c.colspan
                      ),
                  ],
                  [stubTrue, ({ t }) => avWidth - t.limitAvailableWidthBy],
                ])({ c, t: props });
                return (
                  <React.Fragment key={`${i}${j}`}>
                    {decimalSeparatorForPlaceValueTable(style, r, i, j, postDecimals, t)}
                    <DecoratedCell
                      {...c}
                      maxWidth={!c.childIsTable ? maxCellW : undefined}
                      minWidth={getMinWidth(
                        style,
                        noScale ? maxWidth : availableWidth,
                        percentWidth,
                        maxRowWidthFlash,
                        c.width,
                        columnPercents && columnPercents[colSpans[j] - 1],
                        width
                      )}
                    >
                      {c.content && (
                        <PolymorphicGizmo
                          refid={c.content.$refid}
                          availableWidth={maxCellW - c.limitAvailableWidthBy}
                        />
                      )}
                    </DecoratedCell>
                  </React.Fragment>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
  return !props.childOfHScroll ? (
    <ShadowScrollbars style={{ maxWidth: avWidth }} availableWidth={avWidth}>
      {table}
    </ShadowScrollbars>
  ) : (
    table
  );
};

HScrollTable.displayName = 'HScrollTable';
