import * as React from 'react';
import { type GeoEditorMode, type PointDecoration } from '@bettermarks/gizmo-types';
import { getStrokeWidth, POINT_PLUS_LENGTH } from '@bettermarks/importers';
import { getSnapColor } from './decorations';
import styles from './SnapPoint.scss';

export type CrossSnapShapeProps = {
  mode: GeoEditorMode;
  x: number;
  y: number;
  decoration: PointDecoration;
  transform: boolean;
};

export const CrossSnapShape: React.FC<CrossSnapShapeProps> = ({
  mode,
  x,
  y,
  decoration,
  transform,
}) => {
  const horizontalPos = Math.min(2 + getStrokeWidth(decoration), 5);
  const verticalPos = 8;

  const points = `${x - horizontalPos},${y + verticalPos}
                    ${x + horizontalPos},${y + verticalPos}
                    ${x + horizontalPos},${y + horizontalPos}
                    ${x + verticalPos},${y + horizontalPos}
                    ${x + verticalPos},${y - horizontalPos}
                    ${x + horizontalPos},${y - horizontalPos}
                    ${x + horizontalPos},${y - verticalPos}
                    ${x - horizontalPos},${y - verticalPos}
                    ${x - horizontalPos},${y - horizontalPos}
                    ${x - verticalPos},${y - horizontalPos}
                    ${x - verticalPos},${y + horizontalPos}
                    ${x - horizontalPos},${y + horizontalPos}
                    `;

  const rotationCoords = transform ? `rotate(45, ${x}, ${y})` : '';

  const strokeWidth = getStrokeWidth(decoration);
  const color = getSnapColor(mode);

  return (
    <g transform={rotationCoords} className={styles.baseSnappoint}>
      <polygon points={points} className={styles.polygon} stroke={color} />
      <line
        x1={x}
        y1={y - POINT_PLUS_LENGTH}
        x2={x}
        y2={y + POINT_PLUS_LENGTH}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1={x - POINT_PLUS_LENGTH}
        y1={y}
        x2={x + POINT_PLUS_LENGTH}
        y2={y}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </g>
  );
};

CrossSnapShape.displayName = 'CrossSnapShape';
