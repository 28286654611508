import * as React from 'react';
import styled from 'styled-components';
import {
  Button as BaseButton,
  ButtonKind,
  ButtonSize,
  ButtonBackground,
  Layout as BaseLayout,
  defaultTheme,
} from '@seriesplayer/common-ui';
import { identity } from '../../gizmo-utils/filters';
import { numberFromStyles } from '@bettermarks/gizmo-types';
import { Icon, type IconKey } from '../Icon';

export const DIALOG_HEADER_HEIGHT =
  numberFromStyles(defaultTheme.dimensions.spaceXs) * 2 +
  numberFromStyles(defaultTheme.dimensions.btnMinHeightL) +
  numberFromStyles(defaultTheme.dimensions.borderWidthS) * 2;

const HiddenButton = styled(BaseButton)`
  visibility: hidden;
`;

type LayoutProps = {
  fullWidth: boolean;
};

const Layout = styled(BaseLayout)<LayoutProps>`
  color: ${defaultTheme.colors.cTextDark};
  padding: ${defaultTheme.dimensions.spaceXs};
  min-height: ${DIALOG_HEADER_HEIGHT}px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  .title-text {
    text-align: center;
    flex-direction: column;
    flex: 1 1 auto;
    display: flex;
    align-self: center;
    padding: 0 ${defaultTheme.dimensions.spaceXs};
    font-size: ${defaultTheme.dimensions.fontSizeL};
    line-height: ${defaultTheme.dimensions.lineHeightL};
  }
`;

interface DialogButton {
  icon: IconKey;
  onClick?: () => void;
  wrapper?: (button: React.ReactChild) => JSX.Element;
  dataCy?: string;
}

export interface DialogHeaderProps {
  title: string;
  leftButton?: DialogButton;
  rightButton?: DialogButton;
  fullWidth?: boolean;
}

/**
 * DialogHeader used in Dialogs
 * @prop title: Title for the dialog header
 * @prop onCancel: Cancel handler
 * @prop cancelStyle: If show arrow on left or show close on right.
 */
export const DialogHeader: React.FC<DialogHeaderProps> = ({
  title,
  leftButton = null,
  rightButton = null,
  fullWidth = false,
}) => (
  <Layout fullWidth={fullWidth}>
    {renderButton(leftButton)}
    <div className="title-text" data-cy="dialog-title">
      {title}
    </div>
    {renderButton(rightButton)}
  </Layout>
);

function renderButton(buttonProps: DialogButton | null) {
  if (buttonProps === null) {
    return (
      <HiddenButton
        size={ButtonSize.l}
        kind={ButtonKind.action}
        background={ButtonBackground.transparent}
        collapseSpace
      >
        <Icon iconKey="ArrowLeftLargeBold" />
      </HiddenButton>
    );
  }

  const wrapper = buttonProps.wrapper || identity;
  const Button = buttonProps.onClick ? BaseButton : HiddenButton;

  return wrapper(
    <Button
      size={ButtonSize.l}
      kind={ButtonKind.action}
      background={ButtonBackground.transparent}
      onClick={buttonProps.onClick}
      dataCy={buttonProps.dataCy}
      collapseSpace
    >
      <Icon iconKey={buttonProps.icon} />
    </Button>
  );
}
