import { type Content } from '../../xml-converter/core';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { CIRCLE } from '../../gizmo-utils/configuration/renderStyles';

export type Bitmask = ReadonlyArray<boolean>;
export type FractionFormContent = Readonly<
  Content & {
    bitmasks: ReadonlyArray<Bitmask>;
  }
>;

export const enum toolModes {
  form_colorize = 'form-colorize',
  form_break_colorize = 'form-break-colorize',
  form_all = 'form-all',
}

export const FRACTIONFORM_DEFAULT_CONTENT: FractionFormContent = {
  $: PLACEHOLDER,
  $renderStyle: CIRCLE,
  bitmasks: [],
};
