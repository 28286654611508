import { type GeoContentPersistProps } from '@bettermarks/gizmo-types';
import { type IVertex, Vertex } from './Vertex';

const foldHeadsRecursively = <
  DirectedGraph extends GeoContentPersistProps['geoContentMap'],
  VertexID extends Extract<keyof DirectedGraph, string>
>(
  vertexID: VertexID,
  visitedEdges: ReadonlySet<VertexID>,
  directedGraph: DirectedGraph
): ReadonlySet<VertexID> => {
  const vertex = Vertex.fromGeoContentMap(directedGraph, vertexID) as IVertex<VertexID>;
  /**
   * Do deeps first traversal on the graph starting from the given vertex.
   * Visit each node in a pre-ordered manner.
   * Starting from the root node, accumulate all the ids of the incident edges / heads,
   * traverse each arc and apply the same procedure until all the connected
   * vertices have been explored.
   * Finally return the collected results
   */

  if (!vertex.hasHeads()) {
    /**
     * if the current vertex has no 'incident edge'/ 'heads' the return the
     * accumulated list of all the connected vertex up and including this one.
     */
    return new Set([...visitedEdges, vertex.vertexId]);
  }

  return vertex
    .getHeads()
    .reduce(
      (_visitedEdges, head) => foldHeadsRecursively(head as VertexID, _visitedEdges, directedGraph),
      new Set([...visitedEdges, vertex.vertexId])
    );
};

/**
 * Given a directed graph of type GeoContentMap and a valid vertex id,
 * it returns a list containing all ids of the incident edges recursively.
 */
export const getIncidentEdgesRecursively = <
  DirectedGraph extends GeoContentPersistProps['geoContentMap'],
  VertexID extends Extract<keyof DirectedGraph, string>
>(
  directedGraph: DirectedGraph,
  rootVertexID: VertexID
): ReadonlyArray<VertexID> =>
  !(rootVertexID in directedGraph)
    ? []
    : Array.from(foldHeadsRecursively(rootVertexID, new Set<VertexID>(), directedGraph));
