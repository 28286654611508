import {
  annotationInner,
  type Exporter,
  type FieldSetContent,
  semantics,
} from '@bettermarks/gizmo-types';
import { decorationAttribute } from '../../gizmo-utils/decoration';
import { exportMathContent } from '../formula/exporter/exportMathContent';

export const exportFieldSet: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as FieldSetContent;

  return semantics(
    `<mrow ${decorationAttribute(content.decoration)}>
       ${content.content.map((element) => exportMathContent(element, context)).join('')}
     </mrow>`,
    annotationInner(content.$, content)
  );
};
