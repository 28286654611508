import * as React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { DEFAULT_APP_SETTINGS } from '../../apps/store/constants';

export const useResultManagerUrl = (): string => {
  const { search } = useLocation();
  const { resultManagerUrl } = React.useMemo(() => qs.parse(search), [search]);
  return typeof resultManagerUrl === 'string'
    ? resultManagerUrl
    : DEFAULT_APP_SETTINGS.resultManagerUrl;
};
