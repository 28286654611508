import {
  annotationInner,
  type Content,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
  type SortingContent,
} from '@bettermarks/gizmo-types';

export const exportSorting: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as SortingContent;

  return semantics(
    `<mrow>
        <configuration>
          <mintext>${content.minText}</mintext>
          <maxtext>${content.maxText}</maxtext>
          <direction>${content.direction}</direction>
        </configuration>
        ${content.items
          .map((element, i) =>
            exportContent(element.$refid, {
              ...context,
              content: {
                ...context.content,
                // validator checks the $ids of our children to be "in order" and "start from 1"...
                // need to manipulate them before giving them to their exporter
                [element.$refid]: {
                  ...(context.content[element.$refid] as Content),
                  $id: (i + 1).toString(),
                },
              },
            })
          )
          .join('')}
      </mrow>`,
    annotationInner(content.$, content)
  );
};
