import { type Store } from 'redux';

import { ApplicationState } from '../../types';
import { getAction } from './shortcutRegistry';

/**
 * handles all keyboard events and tries to execute shortcut actions
 */
export class KeyboardShortcutHandler {
  private readonly store: Store<ApplicationState>;

  public constructor(store: Store<ApplicationState>) {
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('FlutterKeyDown', this.onFlutterKeyDown);
    this.store = store;
  }

  private readonly onKeyDown = (evt: KeyboardEvent): void => {
    const state = this.store.getState();
    if (ApplicationState.preventShortcuts(state)) return;

    const action = getAction(
      {
        key: evt.key,
        shift: evt.shiftKey,
        control: evt.ctrlKey,
        meta: evt.metaKey,
      },
      state.shortcuts
    );
    if (action) {
      evt.preventDefault();
      this.store.dispatch(action);
    }
  };
  // This is a KeyDown handler for the event which is originated from flutter app
  // Please check typings for `evt` from flutter.
  // lib/ui/screens/webview_screen.dart => void handleOnKeyEvent
  private readonly onFlutterKeyDown = (
    evt: CustomEvent<{
      key: string;
      shift: boolean;
      control: boolean;
      meta: boolean;
    }>
  ): void => {
    const state = this.store.getState();
    if (ApplicationState.preventShortcuts(state)) return;
    const action = getAction(
      {
        key: evt.detail.key,
        shift: evt.detail.shift,
        control: evt.detail.control,
        meta: evt.detail.meta,
      },
      state.shortcuts
    );
    if (action) {
      evt.preventDefault();
      this.store.dispatch(action);
    }
  };
}
