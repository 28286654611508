import * as React from 'react';
import { type TFunction } from 'i18next';
import { defaultTo, includes } from 'lodash';
import {
  AxisCapStyle,
  AxisDirection,
  AxisNullPosition,
  type AxisTick,
  type ContentReference,
  numberFromStyles,
} from '@bettermarks/gizmo-types';
import { AxisLabel, AxisLine, AxisTickLabels, AxisTicks } from './AxisRenderer';

import styles from './AxisRenderer.scss';
import { decmod, getTicks, roundNumber } from '@bettermarks/importers';
import { useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';

export interface AxisProps {
  matrix: number[];
  id: string;
  direction: AxisDirection;
  capStyle: AxisCapStyle;
  min: number;
  max: number;
  tickLabelInterval: number;
  tickValueInterval: number;
  label: string | ContentReference;
  scale: number;
  nullLabelPosition: AxisNullPosition;
}

const localizedTickLabel = (tick: number, t: TFunction | undefined): string =>
  tick
    .toLocaleString('en-US')
    // eslint-disable-next-line no-useless-escape
    .replace(/\,/g, t ? defaultTo(t('formula.thousandsseparator'), ',') : ',')
    .replace(/\./g, t ? defaultTo(t('formula.decimalpoint'), '.') : '.');

export const Axis: React.FC<AxisProps> = ({
  matrix,
  id,
  direction,
  capStyle,
  min,
  max,
  tickLabelInterval,
  tickValueInterval,
  label,
  nullLabelPosition,
  scale,
}) => {
  const t = useContentTranslation();
  const isVertical = direction === AxisDirection.vertical;
  const hasArrow = capStyle === AxisCapStyle.Triangle;
  const length = max - min;
  const labelsOn = tickLabelInterval !== -1;

  const predicate = (val: AxisTick) => {
    const pos = val.pos.x === 0 ? val.pos.y : val.pos.x;
    return (
      (!includes([AxisNullPosition.South, AxisNullPosition.West], nullLabelPosition)
        ? pos !== 0
        : true) &&
      pos > min &&
      pos < max
    );
  };

  // find the first major tick greater than/equal min
  const minMajorTick = [...Array(Math.ceil(length / tickValueInterval) + 1)]
    .map((_, i) => roundNumber(min) + i * tickValueInterval)
    .find((tickValue) => decmod(tickValue, tickValueInterval * tickLabelInterval) === 0) as number;

  // ticks and tick labels
  const tickDashes = getTicks(
    min,
    max,
    tickValueInterval,
    tickLabelInterval,
    minMajorTick,
    isVertical
  ).filter(predicate);

  const tickLabels = !labelsOn
    ? []
    : [...Array(Math.ceil(length / tickValueInterval / tickLabelInterval) + 1)]
        .map((_, i) => {
          const pos = minMajorTick + i * tickValueInterval * tickLabelInterval;
          return {
            major: true,
            pos: isVertical ? { x: 0, y: pos } : { x: pos, y: 0 },
            content: localizedTickLabel(
              minMajorTick + i * tickValueInterval * tickLabelInterval,
              t
            ),
          };
        })
        .filter(predicate);

  // that is sufficient for all our labels!
  const width = 200;
  const height = 30;

  const fontSize = numberFromStyles(styles.AXIS_LABEL_FONT_SIZE) * scale;

  const axisMin = isVertical ? { x: 0, y: min } : { x: min, y: 0 };
  const axisMax = isVertical ? { x: 0, y: max } : { x: max, y: 0 };

  return (
    <g>
      <AxisLine {...{ id, matrix, direction, hasArrow }} min={axisMin} max={axisMax} />
      <AxisTicks {...{ matrix, isVertical, max }} tickDashes={tickDashes} />
      <AxisLabel {...{ matrix, isVertical, label, fontSize }} x={axisMax.x} y={axisMax.y} />

      <AxisTickLabels {...{ matrix, isVertical, width, height, fontSize, tickLabels }} />
    </g>
  );
};

Axis.displayName = '_Axis';
