import { ContentColor } from '@bettermarks/gizmo-types';

export const DEFAULT_SELECT_COLOR = ContentColor.BM_BLUE;

/**
 * This constant is to keep coherency between float numbers from bm-generator
 * and bm-toolbox,
 * INFO: as was checked in Google Chrome, Firefox, Safari and node repl
 * javascript supports 16 digits after point then it starts truncating.
 * We need exactly this number for more accurate calculation.
 */
export const GENERATOR_MAX_DECIMALS = 16;
