import * as React from 'react';
import { range } from 'lodash';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import { Balanced, LeftDown, RightDown } from '../../components/icons/balance';
import { getNamedColor } from '@bettermarks/importers';
import { type ShapePadding } from '../components/BasicShape/types';
import { CircleShape } from '../components/BasicShape/CircleShape';
import {
  type BalanceContent,
  BalanceValue,
  type Bullet,
  ContentColor,
} from '@bettermarks/gizmo-types';

export type BalanceProps = BalanceContent & ContextState;

const BULLET_BORDER_WIDTH = 2.5;
const BALANCE_WIDTH = 264;
const HEIGHT_BALANCED_AND_RIGHTDOWN = 92;
const HEIGHT_LEFTDOWN = 107;
const BALANCE_LEFT_PADDING = 6;
const BULLET_SIZE_AND_MARGIN = 26;
const HORIZONTAL_DIST_BW_BALANCE = 146;

const BALANCE_TOP_PADDING = 20;
const UNBALANCE_DELTA_FACTOR = 28;
const BULLET_SCALE_FACTOR = 1.5;
const Y_OFFSET = 4;
const NUMBER_OF_ROWS = 4;
const MAX_HEIGHT_OF_BULLETS = BULLET_SIZE_AND_MARGIN * NUMBER_OF_ROWS + BALANCE_TOP_PADDING;

const FIRST_BULLET_Y = BULLET_SIZE_AND_MARGIN * 3;

type Positions = ReadonlyArray<[number, number]>;

const createRow = (
  y: number,
  MAX_BULLET_PER_ROW: number,
  delta: number,
  horizontalDistance?: number
): Positions =>
  range(0, MAX_BULLET_PER_ROW).map<[number, number]>((value) =>
    horizontalDistance
      ? [delta + horizontalDistance + BULLET_SIZE_AND_MARGIN * value, y]
      : [delta + BULLET_SIZE_AND_MARGIN * value, y]
  );

const POSITIONSLEFT: Positions = [
  ...createRow(FIRST_BULLET_Y, 4, 0),
  ...createRow(FIRST_BULLET_Y + Y_OFFSET - BULLET_SIZE_AND_MARGIN, 3, BULLET_SIZE_AND_MARGIN * 0.5),
  ...createRow(
    FIRST_BULLET_Y + Y_OFFSET * 2 - BULLET_SIZE_AND_MARGIN * 2,
    2,
    BULLET_SIZE_AND_MARGIN
  ),
  ...createRow(
    FIRST_BULLET_Y + Y_OFFSET * 3 - BULLET_SIZE_AND_MARGIN * 3,
    1,
    BULLET_SIZE_AND_MARGIN * 0.5 * 3
  ),
];

const POSITIONSRIGHT: Positions = [
  ...createRow(FIRST_BULLET_Y, 4, 0, HORIZONTAL_DIST_BW_BALANCE),
  ...createRow(
    FIRST_BULLET_Y + Y_OFFSET - BULLET_SIZE_AND_MARGIN,
    3,
    BULLET_SIZE_AND_MARGIN * 0.5,
    HORIZONTAL_DIST_BW_BALANCE
  ),
  ...createRow(
    FIRST_BULLET_Y + Y_OFFSET * 2 - BULLET_SIZE_AND_MARGIN * 2,
    2,
    BULLET_SIZE_AND_MARGIN,
    HORIZONTAL_DIST_BW_BALANCE
  ),
  ...createRow(
    FIRST_BULLET_Y + Y_OFFSET * 3 - BULLET_SIZE_AND_MARGIN * 3,
    1,
    BULLET_SIZE_AND_MARGIN * 0.5 * 3,
    HORIZONTAL_DIST_BW_BALANCE
  ),
];

const BULLET_PADDING: Readonly<ShapePadding> = {
  top: 2,
  bottom: 2,
  left: 2,
  right: 2,
};

const BalanceComponentMap = {
  [BalanceValue.BALANCED]: Balanced,
  [BalanceValue.LEFT_DOWN]: LeftDown,
  [BalanceValue.RIGHT_DOWN]: RightDown,
};

export const Balance: React.FC<BalanceProps> = ({
  balance,
  leftOneBullets,
  leftXBullets,
  rightOneBullets,
  rightXBullets,
}) => {
  const width = BALANCE_WIDTH;
  const TotalSvgHeight =
    (balance === (BalanceValue.BALANCED || BalanceValue.RIGHT_DOWN)
      ? HEIGHT_BALANCED_AND_RIGHTDOWN
      : HEIGHT_LEFTDOWN) + MAX_HEIGHT_OF_BULLETS;

  const getBullets = (
    xBullets: number | undefined,
    oneBullets: number | undefined
  ): ReadonlyArray<Bullet> => {
    const xSet: ReadonlyArray<Bullet> = xBullets
      ? range(xBullets).map((_, i) => ({
          color: ContentColor.BM_GREEN,
          label: 'X',
        }))
      : [];
    const oneSet: ReadonlyArray<Bullet> = oneBullets
      ? range(oneBullets).map((_, i) => ({
          color: ContentColor.BM_ORANGE,
          label: '1',
        }))
      : [];
    return [...xSet, ...oneSet];
  };

  return (
    <svg width={width} height={TotalSvgHeight}>
      {React.createElement(BalanceComponentMap[balance], {
        fill: getNamedColor(ContentColor.BM_GREY),
        width,
        height: TotalSvgHeight,
      })}

      {getBullets(leftXBullets, leftOneBullets).map((bullet, index) => {
        const [x, y] = POSITIONSLEFT[index];
        const left = BALANCE_LEFT_PADDING;
        const top =
          balance === BalanceValue.LEFT_DOWN
            ? BALANCE_TOP_PADDING + UNBALANCE_DELTA_FACTOR
            : BALANCE_TOP_PADDING;

        return (
          <g transform={`translate(${x + left} ${y + top})`} key={index}>
            <CircleShape
              scalefactor={BULLET_SCALE_FACTOR}
              color={bullet.color}
              gradientType="radial"
              label={bullet.label}
              padding={BULLET_PADDING}
              borderWidth={BULLET_BORDER_WIDTH}
            />
          </g>
        );
      })}

      {getBullets(rightXBullets, rightOneBullets).map((bullet, index) => {
        const [x, y] = POSITIONSRIGHT[index];
        const left = BALANCE_LEFT_PADDING;
        const top =
          balance === BalanceValue.RIGHT_DOWN
            ? BALANCE_TOP_PADDING + UNBALANCE_DELTA_FACTOR
            : BALANCE_TOP_PADDING;

        return (
          <g transform={`translate(${x + left} ${y + top})`} key={index}>
            <CircleShape
              scalefactor={BULLET_SCALE_FACTOR}
              color={bullet.color}
              gradientType="radial"
              label={bullet.label}
              padding={BULLET_PADDING}
              borderWidth={BULLET_BORDER_WIDTH}
            />
          </g>
        );
      })}
    </svg>
  );
};

Balance.displayName = 'Balance';
