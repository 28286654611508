import * as React from 'react';
import classNames from 'classnames';
import styles from './Keyboard.scss';
import { RadioButtonGroup } from '../../RadioButtonGroup';
import { type KeyHandler } from './Key';

export type LayoutProps = {
  onKey?: KeyHandler;
  onTogglePage?: (index: number) => void;
  hasNumbers?: boolean;
  hasDecimalPoint?: boolean;
  hasLetters?: boolean;
  operators: string[];
  selectedPage: number;
};

export type KeyboardLayoutProps = {
  toggles?: ReadonlyArray<React.FC | string>;
  size: 'small' | 'medium' | 'large' | 'extra-large';
  selectedPage: number;
  onTogglePage?: (index: number) => void;
};

/**
 * KeyboardLayout: Toggles between given pages
 */
export class KeyboardLayout extends React.Component<KeyboardLayoutProps> {
  constructor(props: KeyboardLayoutProps) {
    super(props);
  }

  render() {
    const { children, toggles, selectedPage, size } = this.props;
    const page = selectedPage < React.Children.toArray(children).length ? selectedPage : 0;
    return (
      <div className={classNames(styles.layout, styles[size])}>
        {toggles && toggles.length > 1 && (
          <div className={styles.toggles}>
            <RadioButtonGroup kind="toggle" onToggle={this.props.onTogglePage} value={page}>
              {toggles.map((t, i) => React.createElement(t, { key: `toggle-${i}` }))}
            </RadioButtonGroup>
          </div>
        )}
        {React.Children.toArray(children)[page]}
      </div>
    );
  }
}
