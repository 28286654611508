import { first, isNaN, isNil } from 'lodash';
import { type Action, createAction, handleActions } from 'redux-actions';
import {
  type CircleConfiguration,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type SnapType,
  type TickValueInterval,
} from '@bettermarks/gizmo-types';
import { type Coords, type GeoScene, type SnapPoint } from '@bettermarks/gizmo-types';
import { DEFAULT_PREVCIRCLE, GEO_DEFAULT_PERSIST_PROPS } from '@bettermarks/importers';
import { screenToWorld } from '@bettermarks/importers';
import { type GeoAddCircleState } from '@bettermarks/gizmo-types';
import { getCircleId } from '../../helpers';
import { addPreviewCircle } from './helpers';
import { persistCircle } from '../persist';

export interface AddCirclePayload {
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface AddPreviewCirclePayload {
  configuration: CircleConfiguration;
  tickValueInterval: TickValueInterval;
  snapType: SnapType;
  matrix: number[];
  mouseP: Coords;
  scene: GeoScene;
  scale: number;
}

export type GeoAddCirclePayload =
  | AddCirclePayload
  | AddPreviewCirclePayload
  | number[]
  | SnapPoint[]
  | null
  | void;

const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<GeoAddCirclePayload>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);
const SET_CIRCLE_CENTRE: 'SET_CIRCLE_CENTRE' = 'SET_CIRCLE_CENTRE';
export const setCircleCentreAction = createAction<number[]>(SET_CIRCLE_CENTRE);
const ADD_PREVIEW_CIRCLE: 'ADD_PREVIEW_CIRCLE' = 'ADD_PREVIEW_CIRCLE';
export const addPreviewCircleAction = createAction<AddPreviewCirclePayload>(ADD_PREVIEW_CIRCLE);
const ADD_CIRCLE: 'ADD_CIRCLE' = 'ADD_CIRCLE';
export const addCircleAction = createAction<AddCirclePayload>(ADD_CIRCLE);

export const initialState = {
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  prevCircle: DEFAULT_PREVCIRCLE,
  snapPoints: [],
  toolValueLabels: [],
};

export const geoAddCircleReducer = handleActions<GeoAddCircleState, GeoAddCirclePayload>(
  {
    [SNAP]: (state: GeoAddCircleState, { payload }: Action<SnapPoint[]>) => {
      if (!payload) {
        return state;
      }
      return {
        ...state,
        snapPoints: payload,
      };
    },

    [STOP_SNAP]: (state: GeoAddCircleState) => ({
      ...state,
      prevCircle: DEFAULT_PREVCIRCLE,
      snapPoints: [],
    }),

    [SET_CIRCLE_CENTRE]: (state: GeoAddCircleState, { payload }: Action<number[]>) => {
      // payload is the transformation matrix
      if (!payload) {
        return state;
      }
      if (isNaN(state.prevCircle.coords.x) && state.snapPoints.length > 0) {
        const snapPoint = first(state.snapPoints) as SnapPoint;
        const centreCoords: Coords = screenToWorld(payload)(snapPoint);
        return {
          ...state,
          prevCircle: {
            ...state.prevCircle,
            coords: centreCoords,
            screenCoords: {
              x: snapPoint.x,
              y: snapPoint.y,
            },
          },
        };
      }
      return {
        ...state,
        prevCircle: {
          ...DEFAULT_PREVCIRCLE,
          decoration: {
            ...state.prevCircle.decoration,
          },
        },
        snapPoints: [],
      };
    },

    [ADD_PREVIEW_CIRCLE]: (
      state: GeoAddCircleState,
      { payload }: Action<AddPreviewCirclePayload>
    ) => {
      if (!isNil(payload) && !isNaN(state.prevCircle.coords.x)) {
        const { configuration, matrix, mouseP, scene, snapType, scale, tickValueInterval } =
          payload;
        // draw preview circle if previewCircle has centre coords
        return addPreviewCircle(
          state,
          mouseP,
          matrix,
          snapType,
          scene,
          scale,
          tickValueInterval,
          configuration
        );
      }
      return state;
    },

    [ADD_CIRCLE]: (state: GeoAddCircleState, { payload }: Action<AddCirclePayload>) => {
      if (!payload) {
        return state;
      }

      const { configuration, onPersistLocalState } = payload;
      let newState: GeoAddCircleState = state;
      const { coords, radius } = newState.prevCircle;
      if (!isNil(coords) && radius !== 0) {
        const circleId = getCircleId(coords, radius);
        // only create new circle, if same linecircle does not yet exist
        if (!(circleId in newState.persistProps.geoContentMap)) {
          newState = persistCircle(newState, circleId, configuration);
          onPersistLocalState(newState.persistProps);
        }
      }

      return {
        ...newState,
        snapPoints: [],
        prevCircle: {
          ...DEFAULT_PREVCIRCLE,
          decoration: {
            ...state.prevCircle.decoration,
          },
        },
        toolValueLabels: [],
      };
    },
  },
  initialState
);
