import * as React from 'react';
import { get } from 'lodash';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import {
  type BarChartContent,
  type BarChartGroup,
  type CommonCallbacks,
  type MouseCursor,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import { WithLocalRedux } from '../../gizmo-utils/WithLocalRedux';
import { BarChartRenderer } from './BarChartRenderer';
import { type BarChartCallbacks } from './BarChartContainer';
import {
  barChartLocalReducer,
  changeActiveItemProps,
  initialState,
} from './reducer/BarChartReducer';
import { end, handleHover, move, start } from './reducer/actions';
import { DEFAULT_HANDLE_DISTANCE, DEFAULT_HANDLE_HEIGHT, DEFAULT_TICK_WIDTH } from './defaults';

export type BarChartState = {
  groups: BarChartGroup[];
  group: number; // active group
  item: number; // active item
  cursor: MouseCursor;
};

export type BarChartProps = BarChartContent & BarChartCallbacks & ContextState & CommonCallbacks;

export const BarChartEditor: React.FC<BarChartProps> = ({ $id, onPersist, uniqueId, ...props }) => (
  <WithLocalRedux
    store={{ ...initialState, groups: [...props.groups] }}
    reducer={barChartLocalReducer}
    componentName={`BarChartEditor:${uniqueId}`}
  >
    {(state, dispatch) => {
      const { yTickValueStart, yTickValueEnd, yTickValueInterval } = props.configuration;

      const yScreenToTickValue = (evt: MouseOrTouch): number => {
        const { yAxisHeight } = props;
        const dim = evt.currentTarget.getBoundingClientRect();

        let screenY: number;
        const touches = get({ ...evt }, 'touches');
        if (touches && touches.length > 0) {
          screenY = touches[0].clientY;
        } else {
          screenY = (evt as React.MouseEvent<any>).clientY;
        }

        return (
          yTickValueStart +
          yTickValueInterval *
            Math.round(
              (yAxisHeight -
                DEFAULT_HANDLE_DISTANCE -
                DEFAULT_HANDLE_HEIGHT / 2 +
                dim.top -
                screenY) /
                DEFAULT_TICK_WIDTH
            )
        );
      };

      const onMoveBar = (evt: MouseOrTouch) => {
        if (!isNaN(state.group)) {
          const tick = yScreenToTickValue(evt);
          if (tick >= yTickValueStart && tick <= yTickValueEnd) {
            dispatch(move(tick));
          }
        }
      };

      const onMoveStart = (group: number, item: number) => dispatch(start({ group, item }));

      const activeItemValueChanged = () =>
        state.groups[state.group].items[state.item].yValue !==
        props.groups[state.group].items[state.item].yValue;

      const onMoveEnd = () => {
        if (!isNaN(state.group) && activeItemValueChanged()) {
          // persist the current groups in the global store
          if (onPersist) {
            onPersist(
              changeActiveItemProps(state.groups, state.group, state.item, {
                active: false,
                readingHelp: false,
              })
            );
          }
        }
        dispatch(end());
      };

      const onHandleOver = () => dispatch(handleHover(true));

      const onHandleLeave = () => dispatch(handleHover(false));

      const localProps = {
        ...props,
        groups: state.groups,
        cursor: state.cursor,
        activeItem: [state.group, state.item] as [number, number],
      };
      const interactive = !props.disabled;

      return (
        <BarChartRenderer
          {...localProps}
          onMove={interactive ? onMoveBar : undefined}
          onMoveEnd={interactive ? onMoveEnd : undefined}
          onMoveStart={interactive ? onMoveStart : undefined}
          onHandleOver={interactive ? onHandleOver : undefined}
          onHandleLeave={interactive ? onHandleLeave : undefined}
        />
      );
    }}
  </WithLocalRedux>
);

BarChartEditor.displayName = 'BarChartEditor';
