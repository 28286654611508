import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type HelloGizmoContent,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportHelloGizmo: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as HelloGizmoContent;

  return semantics(
    `
      <mrow color="${content.color}" size="${content.size}">
        <mtext>${content.name}</mtext>
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
