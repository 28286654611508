import {
  DEFAULT_SERIES_SETTINGS,
  type GetParams,
  type ILoadExercisePayload,
  type ILoadFilesPayload,
  type LobLinkParams,
  type PdfReviewSeriesParams,
  type StartSeriesPayload,
} from '../../../../types';
import {
  FETCH_REMOTE_XML_SP,
  FETCH_SERIES,
} from '../../../../apps/seriesplayer/containers/SeriesPlayer/actions';
import {
  START_FROM_EXERCISE_ID,
  START_FROM_LOCAL_FILES,
  START_FROM_SERIES_FILE,
} from '../../../../apps/seriesplayer/containers/Upload';
import { DEFAULT_APP_SETTINGS } from '../../../store/constants';

import { type AppConfig } from '../../services/api/getAppConfig';
import { type SeriesResponse } from '../../services/api/content-manager';
import {
  fetchLobLinkSeries,
  fetchLocalSeries,
  fetchPdfReviewSeries,
} from '../../services/api/content-manager/exerciseSeries';
import {
  loadSeriesFromExerciseId,
  loadSeriesFromFileUpload,
  loadSeriesFromSeriesIdInNSP,
  type LoadSeriesFunc,
} from './startSeriesHelpers.helpers';

export type LoadSeriesArgs = GetParams | ILoadFilesPayload | ILoadExercisePayload | LobLinkParams;

/**
 * Fetch the remote exercise on action from Upload container and initialize exercises
 * @param {ILoadExercisePayload} payload
 */
export const loadSeriesFromFile: LoadSeriesFunc = async ({
  features,
  qaMode,
  seriesFile,
  seriesReview,
  previewMode,
  seriesSettings,
  staticUrl,
  assignmentEndDate,
  seriesOnStaticServer = false,
}: ILoadExercisePayload) => {
  const seriesResponse: SeriesResponse = await fetchLocalSeries(
    staticUrl,
    `${seriesFile}.json`,
    false,
    seriesOnStaticServer
  );

  const { title, currentExerciseIndex, exercises } = seriesResponse;
  return {
    appSettings: DEFAULT_APP_SETTINGS,
    exercises,
    features,
    ...(title && { title }),
    currentExerciseIndex,
    ...(seriesFile && { seriesId: seriesFile }),
    seriesReview,
    previewMode,
    seriesSettings,
    ...(assignmentEndDate && {
      assignment: {
        endDate: assignmentEndDate,
      },
    }),
  };
};

export const loadLobLinkSeries = async (
  { contentListId, locale, token }: LobLinkParams,
  appConfig: AppConfig
): Promise<StartSeriesPayload> => {
  const { appSettings, features } = appConfig;
  const { contentManagerUrl, contentManagerApiKey, staticServerUrl } = appSettings;
  const seriesResponse: SeriesResponse = await fetchLobLinkSeries(
    contentManagerUrl,
    staticServerUrl,
    contentListId,
    locale,
    token,
    contentManagerApiKey
  );

  const { title, currentExerciseIndex, settings, exercises } = seriesResponse;
  const seriesSettings = settings || DEFAULT_SERIES_SETTINGS;
  return {
    appSettings,
    exercises,
    features,
    ...(title && { title }),
    currentExerciseIndex,
    seriesSettings,
    seriesReview: false,
    previewMode: false,
  };
};

export const loadPdfReviewSeries = async (
  { seriesId, studentId, system, token }: PdfReviewSeriesParams,
  appConfig: AppConfig
): Promise<StartSeriesPayload> => {
  const { appSettings, features } = appConfig;
  const { contentManagerUrl, staticServerUrl } = appSettings;
  const seriesResponse: SeriesResponse = await fetchPdfReviewSeries(
    contentManagerUrl,
    staticServerUrl,
    seriesId,
    studentId,
    system,
    token
  );

  const { title, currentExerciseIndex, settings, exercises } = seriesResponse;
  const seriesSettings = settings || DEFAULT_SERIES_SETTINGS;
  return {
    appSettings,
    exercises,
    features,
    ...(title && { title }),
    currentExerciseIndex,
    seriesSettings,
    seriesReview: true,
    previewMode: false,
  };
};

/*
  It was not possible to use `switchMap` here as when mocking, it was not possible to override
   the references to the functions when mocking them.
 */
export const loadSeriesFunction = (type: string): LoadSeriesFunc => {
  switch (type) {
    case START_FROM_LOCAL_FILES:
      return loadSeriesFromFileUpload;
    case FETCH_REMOTE_XML_SP:
    case START_FROM_EXERCISE_ID:
      return loadSeriesFromExerciseId;
    case START_FROM_SERIES_FILE:
      return loadSeriesFromFile;
    case FETCH_SERIES:
      return loadSeriesFromSeriesIdInNSP;
    default:
      throw new Error('Unexpected start series action received');
  }
};
