import * as React from 'react';
import { RomanOne, RomanTwo, RomanThree, RomanFour } from '../../../components/icons/geo';

import { transformX, transformY } from '@bettermarks/importers';
import { type Coords } from '@bettermarks/gizmo-types';

import styles from './QuadrantLabels.scss';

export type QuadrantLabelsProps = {
  matrix: number[];
  tickValueInterval: Coords;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
};

export const QuadrantLabels: React.FC<QuadrantLabelsProps> = ({
  matrix,
  tickValueInterval,
  xMin,
  xMax,
  yMin,
  yMax,
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  // TODO:
  // Took those values from the SVG files (components/icons/roman...),
  // maybe we can do this more elegant
  const iconHeight = 48;
  const iconWidth = { one: 17.241, two: 39.066, three: 60.891, four: 66.494 };

  const minXOff = 2;
  const minYOff = 1;

  const [xOneWorld, yOneWorld] = [xMax * 0.5, yMax * 0.5];
  const [xOneScreen, yOneScreen] = [
    sx(xOneWorld) - iconWidth.one * 0.5,
    sy(yOneWorld) - iconHeight * 0.5,
  ];

  const [xTwoWorld, yTwoWorld] = [xMin * 0.5, yMax * 0.5];
  const [xTwoScreen, yTwoScreen] = [
    sx(xTwoWorld) - iconWidth.two * 0.5,
    sy(yTwoWorld) - iconHeight * 0.5,
  ];

  const [xThreeWorld, yThreeWorld] = [xMin * 0.5, yMin * 0.5];
  const [xThreeScreen, yThreeScreen] = [
    sx(xThreeWorld) - iconWidth.three * 0.5,
    sy(yThreeWorld) - iconHeight * 0.5,
  ];

  const [xFourWorld, yFourWorld] = [xMax * 0.5, yMin * 0.5];
  const [xFourScreen, yFourScreen] = [
    sx(xFourWorld) - iconWidth.four * 0.5,
    sy(yFourWorld) - iconHeight * 0.5,
  ];

  /**
   * Only show labels if they are not colliding with the axes
   * (i.e. if there is enough space in the quadrant)
   */
  const showOne =
    xOneWorld >= minXOff * tickValueInterval.x && yOneWorld >= minYOff * tickValueInterval.y;
  const showTwo =
    xTwoWorld <= -minXOff * tickValueInterval.x && yTwoWorld >= minYOff * tickValueInterval.y;
  const showThree =
    xThreeWorld <= -minXOff * tickValueInterval.x && yThreeWorld <= -minYOff * tickValueInterval.y;
  const showFour =
    xFourWorld >= minXOff * tickValueInterval.x && yFourWorld <= -minYOff * tickValueInterval.y;

  return (
    <g>
      {showOne && (
        <RomanOne
          className={styles.label}
          height={iconHeight}
          width={iconWidth.one}
          x={xOneScreen}
          y={yOneScreen}
        />
      )}
      {showTwo && (
        <RomanTwo
          className={styles.label}
          height={iconHeight}
          width={iconWidth.two}
          x={xTwoScreen}
          y={yTwoScreen}
        />
      )}
      {showThree && (
        <RomanThree
          className={styles.label}
          height={iconHeight}
          width={iconWidth.three}
          x={xThreeScreen}
          y={yThreeScreen}
        />
      )}
      {showFour && (
        <RomanFour
          className={styles.label}
          height={iconHeight}
          width={iconWidth.four}
          x={xFourScreen}
          y={yFourScreen}
        />
      )}
    </g>
  );
};

QuadrantLabels.displayName = 'QuadrantLabels';
