import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { submit } from '../../Exercise/actions';
import { EnterIrrevocably, type EnterIrrevocablyDispatchProps } from './EnterIrrevocably';
import { closeDialog } from '../../SeriesPlayer/actions';
import {
  type ShortcutCallbacks,
  type ShortcutsMap,
  withShortcuts,
  simpleShortcut,
} from '../../../../../gizmo-utils/keyboard';
import {
  registerShortcuts,
  unregisterShortcuts,
  type UnregisterShortcutsPayload,
} from '../../../../../gizmo-utils/redux/gizmoActions';

const mapDispatchToProps = (
  dispatch: Dispatch
): EnterIrrevocablyDispatchProps & ShortcutCallbacks => ({
  onCancel: () => dispatch(closeDialog()),
  onConfirm: () => dispatch(submit()),
  onRegisterShortcuts: (shortcuts: ShortcutsMap) => {
    dispatch(registerShortcuts(shortcuts));
  },
  onUnregisterShortcuts: (payload: UnregisterShortcutsPayload) => {
    dispatch(unregisterShortcuts(payload));
  },
});

export const EnterIrrevocablyContainer = connect(
  null,
  mapDispatchToProps
)(
  withShortcuts(
    {
      [simpleShortcut('Enter')]: submit(),
      [simpleShortcut('Escape')]: closeDialog(),
    },
    EnterIrrevocably
  )
);
