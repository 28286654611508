import {
  type AnimationContent,
  annotationInner,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportAnimationContent = (content: AnimationContent): string =>
  semantics(
    `
    <source>${content.source}</source>
    <width>${content.width}</width>
    <height>${content.height}</height>
    `,
    annotationInner(content.$, content)
  );

export const exportAnimation: Exporter = (contentRefId: string, context: ExporterContext): string =>
  exportAnimationContent(context.content[contentRefId] as AnimationContent);
