import { getNamedColor } from '@bettermarks/importers';
import {
  type ApplyStyles,
  type Enricher,
  type GizmoStyle,
  type GizmoStyleForRefId,
} from '../../gizmo-utils/configuration';
import {
  type BarChartContent,
  type CaptionContent,
  type ChartLabel,
  type ContentReference,
  DEFAULT_LAYOUT_METRICS,
  type FormulaStyles,
  isContentReference,
  type PieOrStackedBarChartContent,
} from '@bettermarks/gizmo-types';
import { mergeGizmoStyle } from '../formula/Formula/measure';
import { enrichSingleFormula } from '../formula/measure';

import styles from './charts.scss';

const TITLE_COLOR = getNamedColor(styles.TITLE_COLOR);

const addContentRefStyles = (
  target: GizmoStyleForRefId[],
  style: GizmoStyle,
  ref: Nullable<ContentReference>
): GizmoStyleForRefId[] => {
  if (isContentReference(ref)) {
    target.push({ refId: ref.$refid, style });
  }
  return target;
};

const addChartLabelStyles = (
  target: GizmoStyleForRefId[],
  outerStyles: GizmoStyle,
  label: ChartLabel | undefined
): GizmoStyleForRefId[] => {
  if (isContentReference(label)) {
    addContentRefStyles(target, outerStyles, label);
  }
  return target;
};

export const applyDiagramBarStyles: ApplyStyles = (
  { caption, xLabel, yLabel, title, groups }: BarChartContent,
  outerStyles
) => {
  const result: GizmoStyleForRefId[] = [];
  [caption, xLabel, yLabel].forEach((ref) => addContentRefStyles(result, outerStyles, ref));

  addContentRefStyles(result, mergeGizmoStyle({ color: TITLE_COLOR }, outerStyles), title);

  const groupLabelStyle = mergeGizmoStyle({ fontSize: 14 }, outerStyles);
  groups.forEach(({ label }) => addContentRefStyles(result, groupLabelStyle, label));

  return result;
};

export const applyCaptionStyles: ApplyStyles = ({ legend }: CaptionContent, style) =>
  legend.reduce((acc, { label }) => addChartLabelStyles(acc, style, label), []);

export const applyPieOrStackedBarChartStyles: ApplyStyles = (
  { slices, title }: PieOrStackedBarChartContent,
  style
) => {
  const result: GizmoStyleForRefId[] = [];

  addContentRefStyles(result, mergeGizmoStyle({ color: TITLE_COLOR }, style), title);

  const markedStyle = mergeGizmoStyle({ fontWeight: 'bold' }, style);
  slices.forEach(({ mark, legendLabel, sliceLabel }) => {
    addChartLabelStyles(result, mark ? markedStyle : style, sliceLabel);
    addChartLabelStyles(result, style, legendLabel);
  });

  return result;
};

const enrichChartLabel = (outerStyles: FormulaStyles, label: ChartLabel): ChartLabel =>
  isContentReference(label) ? label : enrichSingleFormula(label, outerStyles);

export const captionEnricher: Enricher<CaptionContent> = (outerStyles, content) => {
  const legend = content.legend.map((l) => ({
    ...l,
    label: enrichChartLabel(outerStyles, l.label),
  }));
  return {
    ...DEFAULT_LAYOUT_METRICS, // TODO is that good enough (and why)?
    enrichedContent: { ...content, legend },
  };
};

export const pieOrStackedBarChartEnricher: Enricher<PieOrStackedBarChartContent> = (
  outerStyles,
  content
) => {
  const markedStyle: FormulaStyles = { ...outerStyles, fontWeight: 'bold' };
  const slices = content.slices.map(({ mark, legendLabel, sliceLabel, ...slice }) => ({
    ...slice,
    mark,
    sliceLabel: sliceLabel && enrichChartLabel(mark ? markedStyle : outerStyles, sliceLabel),
    legendLabel: legendLabel && enrichChartLabel(outerStyles, legendLabel),
  }));
  return {
    ...DEFAULT_LAYOUT_METRICS, // TODO is that good enough (and why)?
    enrichedContent: { ...content, slices },
  };
};
