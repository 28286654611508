import * as React from 'react';
import classNames from 'classnames';

import { ContentRoot, LocaleProvider } from '../../gizmo-utils/polymorphic-gizmo';
import { type Dispatch } from '../../gizmo-utils/redux/types';
import { useBoundsMeasure } from '../../gizmo-utils/useMeasure';
import { type ContentDict } from '@bettermarks/gizmo-types';

import { VERY_SMALL_SCREEN_WIDTH } from '../seriesplayer/constants';
import { gizmos } from './configuration';
import styles from './FEMPlayer.scss';
import { FEMPLAYER_HORIZONTAL_PADDING } from './constants';
import { type StateWithHistory } from 'redux-undo';
import { reportHeightToParent } from './helper';

export type FEMContentProps = Readonly<{
  femData: StateWithHistory<ContentDict>;
  locale: string;
  dispatch: Dispatch;
  id: string;
  isTouch: boolean;
  inBook?: boolean;
  staticMediaUrl: string;
  availableWidth?: number;
}>;

export function FEMContent({
  femData,
  locale,
  dispatch,
  id,
  isTouch,
  inBook,
  staticMediaUrl,
  availableWidth,
}: FEMContentProps) {
  const [{ contentWidth, contentHeight }, contentRef] = useBoundsMeasure();

  React.useEffect(
    () =>
      inBook && contentHeight
        ? reportHeightToParent(contentHeight + 2 * FEMPLAYER_HORIZONTAL_PADDING, id, 'fem')
        : undefined,
    [contentHeight, inBook]
  );

  return (
    <LocaleProvider contentLocale={locale}>
      <div
        ref={contentRef}
        className={classNames({
          [styles.hidden]: contentWidth === 0,
          [styles.verticalScrollDisabled]: inBook,
        })}
        data-cy={`fem-${id}`}
      >
        <ContentRoot
          availableWidth={availableWidth || contentWidth || VERY_SMALL_SCREEN_WIDTH}
          contentDict={femData.present}
          gizmoRegistry={gizmos}
          dispatch={dispatch}
          isTouch={isTouch}
          staticMediaUrl={staticMediaUrl}
        />
      </div>
    </LocaleProvider>
  );
}
