import { type Severity } from '@bettermarks/umc-kotlin';
import { PolymorphicGizmo, useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';
import { curry, get } from 'lodash';
import * as React from 'react';

import { FT as T } from '@bettermarks/gizmo-types';

import {
  Cursor,
  MathAlien,
  MCal,
  type MCalProps,
  MExpansion,
  MFenced,
  MFrac,
  MI,
  MN,
  MO,
  MOver,
  MRoot,
  MRow,
  MSpace,
  MSqrt,
  MSub,
  MSubSup,
  MSup,
  MText,
  MUnder,
  MUnderOver,
  Placeholder,
  VerticalAlignedBuckets,
  VerticalAlignedRow,
} from './components';
import {
  bucketize,
  isTopLevel,
  selectClosingFence,
  selectDenominator,
  selectDigit,
  selectFractionLine,
  selectInRoot,
  selectNumerator,
  selectOpeningFence,
} from './mathContentHelpers';
import { MathOperatorSVG, SVG_OPERATORS } from './MathOperatorSVG';

export interface MathContentProps {
  richContent: T.MathContent & T.Interactable;
  path?: T.Path;
  onSelect?: T.PathHandler;
  severity?: Severity;
  skipPlaceholder?: boolean;
  availableWidth: number;
  hideHelpTools?: boolean;
  onClickFEM?: (id: string) => void;
  typeOfUnder?: string | undefined;
  inCalculator?: boolean;
}

/* eslint-disable-next-line complexity */
export const MathContent: React.FC<MathContentProps> = ({
  richContent,
  path = [],
  onSelect,
  severity,
  availableWidth,
  hideHelpTools,
  skipPlaceholder = false,
  onClickFEM,
  typeOfUnder,
  inCalculator,
}) => {
  const t = useContentTranslation();

  if (T.isContentReference(richContent)) {
    return (
      <MathAlien>
        <PolymorphicGizmo refid={richContent.$refid} availableWidth={availableWidth} />
      </MathAlien>
    );
  }

  const unwrapChildren = (
    children: T.MathContent[],
    availableWidth: number,
    severity?: Severity,
    localPath = path,
    typeOfUnder?: string | undefined
  ) =>
    children.map((c, i) => (
      <MathContent
        key={i}
        richContent={c}
        path={[...localPath, 'children', i]}
        onSelect={onSelect}
        onClickFEM={onClickFEM}
        severity={severity}
        availableWidth={availableWidth}
        hideHelpTools={hideHelpTools}
        typeOfUnder={typeOfUnder}
        inCalculator={inCalculator}
      />
    ));

  const unwrapChildrenIntoBuckets = (children: T.MathContent[], localPath = path) =>
    bucketize(children).map((b) => ({
      inline: b.inline,
      elements: b.elements.map((c) => (
        <MathContent
          key={c.index}
          richContent={c.content}
          path={[...localPath, 'children', c.index]}
          onSelect={onSelect}
          severity={severity}
          availableWidth={availableWidth}
          hideHelpTools={hideHelpTools}
          onClickFEM={onClickFEM}
          typeOfUnder={typeOfUnder}
          inCalculator={inCalculator}
        />
      )),
    }));

  const MRowContent = (
    richContent: T.MRow,
    localSkipPlaceholder = skipPlaceholder,
    localPath = path
  ) =>
    richContent.children.length === 0 && !localSkipPlaceholder
      ? [
          // tslint:disable-next-line:jsx-key
          <Placeholder
            computedStyles={richContent.computedStyles}
            severity={severity}
            onSelect={onSelect && curry(onSelect)([...localPath, 'children', 0])}
          />,
        ]
      : unwrapChildren(richContent.children, availableWidth, severity, localPath, typeOfUnder);

  const unaryMinus = `${t ? t('formula.unaryMinusSignFlashFree') : '-'}\u202F`;

  switch (richContent.$) {
    case T.$MCAL:
      return <MCal {...(richContent as MCalProps)} />;
    case T.$CURSOR:
      return <Cursor {...richContent} still={inCalculator || richContent.still} />;
    case T.$MOVER: {
      return (
        <MOver {...richContent}>
          <MathContent
            richContent={richContent.base}
            path={[...path, 'base']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.overscript}
            path={[...path, 'overscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MOver>
      );
    }
    case T.$MROW:
      const bucketize =
        isTopLevel(path) && richContent.children.length > 1 && !richContent.interactive;
      return (
        <MRow
          {...richContent}
          hideHelpTools={hideHelpTools}
          onClickFEM={onClickFEM}
          isTopLevel={isTopLevel(path)}
        >
          {bucketize
            ? VerticalAlignedBuckets(
                unwrapChildrenIntoBuckets(richContent.children, path),
                richContent.childrenAlignments
              )
            : VerticalAlignedRow(MRowContent(richContent), richContent.childrenAlignments)}
        </MRow>
      );
    case T.$MTEXT:
      const text = richContent.translate && t ? t(richContent.text) : richContent.text;
      return (
        <MText computedStyles={richContent.computedStyles} isTopLevel={isTopLevel(path)}>
          {text}
        </MText>
      );
    case T.$MABS:
      const cursorIsOnlyChild =
        richContent.value.children.length === 1 && T.isCursor(richContent.value.children[0]);
      return (
        <MFenced
          {...richContent}
          ensureMinSpace={cursorIsOnlyChild}
          open={T.ABSOLUTE_BAR}
          close={T.ABSOLUTE_BAR}
          onSelectOpeningFence={selectOpeningFence(path, onSelect)}
          onSelectClosingFence={selectClosingFence(
            path,
            richContent.value.children.length,
            onSelect
          )}
        >
          {VerticalAlignedRow(
            MRowContent(richContent.value, false, [...path, 'value']),
            richContent.value.childrenAlignments
          )}
        </MFenced>
      );
    case T.$MEXPANSION:
      // XXX: not sure what the path should be here..
      return (
        <MExpansion {...richContent}>
          <MathContent
            richContent={richContent.numeratorFactor}
            path={path}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
          />
          <MathContent
            richContent={richContent.lhsFraction}
            path={path}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
          />
          <MathContent
            richContent={richContent.operator}
            path={path}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
          />
          <MathContent
            richContent={richContent.rhsFraction}
            path={path}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
          />
          <MathContent
            richContent={richContent.denominatorFactor}
            path={path}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
          />
        </MExpansion>
      );
    case T.$MFENCED:
      return (
        <MFenced
          {...richContent}
          open={richContent.open}
          close={richContent.close}
          onSelectOpeningFence={selectOpeningFence(path, onSelect)}
          onSelectClosingFence={selectClosingFence(path, richContent.children.length, onSelect)}
        >
          {VerticalAlignedRow(
            unwrapChildren(richContent.children, availableWidth, severity),
            richContent.childrenAlignments
          )}
        </MFenced>
      );
    case T.$MN:
      const tSeparator = t ? t('formula.thousandsseparator') : '\u2009';
      const decimalPoint = t ? t('formula.decimalpoint') : '.';
      const mnText = richContent.text
        .replace(/,/g, tSeparator)
        .replace(/\./g, decimalPoint)
        .replace(/-/g, unaryMinus);
      return (
        <MN {...richContent} onSelect={selectDigit(path, onSelect)}>
          {mnText}
        </MN>
      );
    case T.$MI:
      const onSelectMI = onSelect && curry(onSelect)(path);
      return (
        <MI {...richContent} onSelect={onSelectMI}>
          {richContent.text}
        </MI>
      );
    case T.$MO:
      let moText = richContent.text
        .replace(/\+/g, t ? t('formula.additionSign') : '+')
        .replace(/(!?)\*/g, t ? t('formula.multiplicationSignFlashFree') : '×')
        .replace(/\//g, t ? t('formula.divisionSign') : '÷');

      if (Object.values(SVG_OPERATORS).includes(moText as SVG_OPERATORS)) {
        return <MathOperatorSVG operator={moText} computedStyles={richContent.computedStyles} />;
      }

      const onSelectMO = onSelect && curry(onSelect)(path);
      if (richContent.form === 'prefix') {
        moText = moText.replace(/-/g, unaryMinus);

        return (
          <MN {...richContent} onSelect={selectDigit(path, onSelect)}>
            {moText}
          </MN>
        );
      }

      moText = moText.replace(/-/g, t ? t('formula.subtractionSignFlashFree') : '−');

      return (
        <MO {...richContent} onSelect={onSelectMO}>
          {moText}
        </MO>
      );
    case T.$MFRAC:
      return (
        <MFrac
          {...richContent}
          computedStyles={richContent.computedStyles}
          strokeWidth={richContent.strokeWidth}
          onSelectNumerator={selectNumerator(path, onSelect)}
          onSelectDenominator={selectDenominator(path, onSelect)}
          onSelectFractionLine={selectFractionLine(path, onSelect)}
          onSelectOutside={onSelect && curry(onSelect)(path)}
        >
          <MathContent
            richContent={richContent.numerator}
            path={[...path, 'numerator']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.denominator}
            path={[...path, 'denominator']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MFrac>
      );
    case T.$MROOT:
      const indexContent = richContent.index;
      const radicandContent = richContent.radicand;
      return (
        <MRoot
          {...richContent}
          onSelectRadix={selectInRoot(path, 'radicand', onSelect)}
          onSelectIndex={selectInRoot(path, 'index', onSelect)}
        >
          <MathContent
            richContent={indexContent}
            path={[...path, 'index']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            skipPlaceholder={true}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={radicandContent}
            path={[...path, 'radicand']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MRoot>
      );
    case T.$MSPACE:
      return <MSpace {...richContent} availableWidth={availableWidth} typeOfUnder={typeOfUnder} />;
    case T.$MSQRT:
      return (
        <MSqrt {...richContent} onSelectRadix={selectInRoot(path, 'radicand', onSelect)}>
          <MathContent
            richContent={richContent.radicand}
            path={[...path, 'radicand']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MSqrt>
      );
    case T.$MSUB:
      return (
        <MSub {...(richContent as any)}>
          <MathContent
            richContent={richContent.base}
            path={[...path, 'base']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.subscript}
            path={[...path, 'subscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MSub>
      );
    case T.$MSUBSUP:
      return (
        <MSubSup {...(richContent as any)}>
          <MathContent
            richContent={richContent.base}
            path={[...path, 'base']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.subscript}
            path={[...path, 'subscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.superscript}
            path={[...path, 'superscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MSubSup>
      );
    case T.$MSUP:
      return (
        <MSup {...(richContent as any)}>
          <MathContent
            richContent={richContent.base}
            path={[...path, 'base']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.superscript}
            path={[...path, 'superscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MSup>
      );
    case T.$MUNDER:
      const _typeOfUnder = get<T.MUnder, any>(richContent, 'underscript.children.0.text');
      return (
        <MUnder {...richContent}>
          <MathContent
            richContent={richContent.base}
            path={[...path, 'base']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={_typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.underscript}
            path={[...path, 'underscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MUnder>
      );
    case T.$MUNDEROVER:
      return (
        <MUnderOver {...richContent}>
          <MathContent
            richContent={richContent.base}
            path={[...path, 'base']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.underscript}
            path={[...path, 'underscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
          <MathContent
            richContent={richContent.overscript}
            path={[...path, 'overscript']}
            onSelect={onSelect}
            severity={severity}
            availableWidth={availableWidth}
            typeOfUnder={typeOfUnder}
            inCalculator={inCalculator}
          />
        </MUnderOver>
      );
    default:
      return <span>unsupported content</span>;
  }
};

MathContent.displayName = 'MathContent';
