import * as React from 'react';
import { Bar, Button, ButtonKind, ButtonStretch, StyledBox as Box } from '@seriesplayer/common-ui';
import { BaseLayout, Content, DialogFooter, DialogHeader } from '../../../../../components';

export interface EnterIrrevocablyDispatchProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export interface EnterIrrevocablyOwnProps {
  title: string;
  mainText?: string;
  confirmText?: string;
  cancelText?: string;
}

type EnterIrrevocablyProps = EnterIrrevocablyDispatchProps & EnterIrrevocablyOwnProps;

export const EnterIrrevocably: React.FC<EnterIrrevocablyProps> = ({
  onCancel,
  onConfirm,
  ...textProps
}) => (
  <BaseLayout vertical>
    <DialogHeader
      title={textProps.title}
      leftButton={{
        icon: 'ArrowLeftLargeBold',
        onClick: onCancel,
      }}
    />
    <Bar />{' '}
    <Box stretch>
      <Content padding stretched colored>
        <p className="plaintext">{textProps.mainText}</p>
        <DialogFooter align="center">
          <Button
            key="enter"
            dataCy="enter-confirm-btn"
            kind={ButtonKind.warning}
            stretch={ButtonStretch.responsive}
            onClick={onConfirm}
          >
            {textProps.confirmText}
          </Button>
          <Button
            key="unquit"
            dataCy="dont-enter-btn"
            kind={ButtonKind.view}
            stretch={ButtonStretch.responsive}
            onClick={onCancel}
          >
            {textProps.cancelText}
          </Button>
        </DialogFooter>
      </Content>
    </Box>
  </BaseLayout>
);

EnterIrrevocably.displayName = 'EnterIrrevocably';
