import {
  $CURSOR,
  $MABS,
  $MFENCED,
  $MFRAC,
  $MROOT,
  $MROW,
  $MSQRT,
  $MSUP,
  type FormulaContent,
  isMToken,
  isPureMathContent,
  type MathContent,
  type MRow,
} from '@bettermarks/gizmo-types';

function rowString(mc: MRow, flattenMRows = true): string {
  const mapped = `${mc.children.map((it) => toMathString(it, flattenMRows)).join(' ')}`;
  // MROW with only one child are not considered
  // and we use two characters (with inner spaces), so they are always different from MFenced/MAbs
  return mc.children.length === 1 && flattenMRows ? mapped : `|[ ${mapped} ]|`;
}

export function toMathString(mc: MathContent, flattenMRows = true): string {
  if (isMToken(mc)) {
    return mc.text;
  }
  if (isPureMathContent(mc)) {
    if (mc.$ === $CURSOR) {
      return '¦'; // &brvbar;	&#166; https://unicode-table.com/en/00A6/
    }
    if (mc.$ === $MROW) {
      return rowString(mc, flattenMRows);
    }
    if (mc.$ === $MFRAC) {
      // space before and after / indicate fraction, to diff from MRoot and MSqrt
      return `${toMathString(mc.numerator, flattenMRows)} / ${toMathString(
        mc.denominator,
        flattenMRows
      )}`;
    }
    if (mc.$ === $MFENCED) {
      // empty fences (undefined or '') are replaced with ‹ and › to be visible
      return `${mc.open || '‹'} ${mc.children
        .map((it) => toMathString(it, flattenMRows))
        .join(' ')} ${mc.close || '›'}`;
    }
    if (mc.$ === $MABS) {
      return `| ${toMathString(mc.value, flattenMRows)} |`;
    }
    if (mc.$ === $MSUP) {
      return `${toMathString(mc.base, flattenMRows)}^${toMathString(mc.superscript, flattenMRows)}`;
    }
    if (mc.$ === $MROOT) {
      return `⌞${toMathString(mc.index, flattenMRows)}⌟√⌜${toMathString(
        mc.radicand,
        flattenMRows
      )}⌝`;
    }
    if (mc.$ === $MSQRT) {
      return `√⌜${toMathString(mc.radicand, flattenMRows)}⌝`;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { computedStyles, childrenAlignments, ...smc } = mc as any;
  return JSON.stringify(smc);
}

export function formulaString({ content }: FormulaContent, flattenMRows = true): string {
  return `{| ${content.map((it) => toMathString(it, flattenMRows)).join(' ')} |}`;
}
