import { colors } from '@seriesplayer/common-ui';
import { Popup } from '../../../components/ContextPopup';
import { PopupPlace } from '@seriesplayer/common-ui';
import { SliderHandle, SliderInput, SliderOrientation, SliderTrack } from '@reach/slider';
import * as React from 'react';
import styled from 'styled-components';
import Handle from './Handle';
import Track from './Track';
import DynamicCircle from './DynamicCircle';
import { useRef } from 'react';

interface SliderProps {
  min: number;
  max: number;
  value: number;
  step: number;
  penPreviewScale?: number;
  onChange(newValue: number): void;
}

const SliderContainer = styled.div`
  height: 120px;
  margin: 10px;
  cursor: pointer;
`;

const TrackContainer = styled.div`
  position: absolute;
  width: 32px;
  height: 164px;
  align-items: center;
  transform-origin: 82px 82px;
  left: 50%;
  transform: translate(-50%, 0);
  top: -22px;
`;

const inputTrackStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
};

export default function Slider(props: SliderProps) {
  const sliderRef: React.RefObject<HTMLDivElement> = useRef(null);
  const [isSliderHold, setIsSliderHold] = React.useState(false);
  const { penPreviewScale, ...sliderProps } = props;
  const onmousedown = () => {
    setIsSliderHold(true);
  };

  const onmouseup = () => {
    setIsSliderHold(false);
  };

  return (
    <SliderContainer>
      <div style={{ position: 'relative', height: 'auto' }}>
        <TrackContainer ref={sliderRef}>
          <Track color={colors.cGray600} />
        </TrackContainer>
      </div>
      <SliderInput
        {...sliderProps}
        orientation={SliderOrientation.Vertical}
        onMouseDown={() => onmousedown()}
        onMouseUp={() => onmouseup()}
        onTouchStart={() => onmousedown()}
        onTouchEnd={() => onmouseup()}
      >
        <SliderTrack style={inputTrackStyle}>
          <SliderHandle>
            <div>
              <Handle />
            </div>
          </SliderHandle>
        </SliderTrack>
      </SliderInput>
      <div
        style={{
          pointerEvents: 'none',
          zIndex: 50,
        }}
      >
        <Popup
          show={isSliderHold}
          kind="custom"
          place={PopupPlace.left}
          onClickOverlay={() => {}}
          width={200}
          elementBounds={sliderRef.current ? sliderRef.current.getBoundingClientRect() : undefined}
          showOverlayShadow={false}
        >
          <DynamicCircle radius={props.value} scale={props.penPreviewScale} />
        </Popup>
      </div>
    </SliderContainer>
  );
}
