import { type ValidatorStepResponse, Validity } from '@bettermarks/umc-kotlin';
import { type ContentDict, isDragSource, Lens } from '@bettermarks/gizmo-types';
import { switchMap } from '../../../gizmo-utils/fpTools';
import { withEmptyHistory } from '../../store/helper';
import {
  isMathValidatorStep,
  type NextStep,
  type NlpValidatorStepResponse,
  type ValidationPayload,
} from '../../store/types';
import {
  AppExercise,
  ApplicationState,
  AppStep,
  CollapsibleContentState,
  DEFAULT_TOOLBAR_SETTINGS,
  ExerciseStatus,
  type ExerciseTransformer,
  SeriesFlow,
  SeriesMode,
  SeriesStatus,
  type StartSeriesPayload,
  StepStatus,
} from '../../../types';
import {
  getDisplayOptions,
  getExerciseValidity,
  parseNextStepResponse,
  questionAndFeedbackFromValidationPayload,
} from '../validationHelpers';
import { compose, curry, filter, fromPairs, isNil, map, omit, set, toPairs } from 'lodash/fp';
import log from 'loglevel';
import { enrichContentDict as enrichContentDictUnstubable } from '../../../gizmo-utils/measure';
import { stylers } from '../../../gizmo-utils/configuration/stylers';
import { rulers } from '../../../gizmo-utils/configuration/rulers';
import { selectInteractiveChild } from '../selectHelpers';
import {
  completeCurrentAndStartNextStep,
  updateNextExercise,
  updatePrevExercise,
} from '../containers/Series/reducerHelpers';
import uuid from 'uuid';
import { deselectGizmo } from '../helper';

export const STUBABLE = {
  enrichContentDict: enrichContentDictUnstubable,
};

const updateExerciseWrapup = (exerciseStatus: ExerciseStatus) =>
  switchMap(
    {
      [SeriesMode.practice]:
        exerciseStatus === ExerciseStatus.completed
          ? CollapsibleContentState.expanded
          : CollapsibleContentState.hidden,
      // This is just for the sake of logical completeness, however in test mode you can never
      // complete and hand in a single exercise.
      [SeriesMode.test]: CollapsibleContentState.hidden,
    },
    CollapsibleContentState.hidden
  );

/**
 * Set the following properties on the exercise level:
 *  - status (started or completed)
 *  - switched (helper flag to suppress animation on exercise navigation)
 *  - validity (wrong, correct or sufficient)
 *  - toolbarDrawerIndex (hide toolbar when step is completed)
 *  - wrapupState (showing additional information when exercise is completed)
 *
 * @param exercise The source exercise
 * @param exerciseStatus The status to set on exercise
 * @param stepStatus The status of the validated step
 */
export const updateExercise = curry(
  (
    exerciseStatus: ExerciseStatus,
    _: StepStatus,
    mode: SeriesMode,
    exercise: AppExercise
  ): AppExercise => {
    const validity = getExerciseValidity(exercise, exerciseStatus);

    return {
      ...exercise,
      status: exerciseStatus,
      stepValidationLoaded: true,
      ...(validity && { validity }),
      wrapupState: updateExerciseWrapup(exerciseStatus)(mode),
    };
  }
);

/**
 * Updates the current step with information from validation.
 *
 * @param validationPayload Payload from the validation.
 * @param mode Series Mode.
 * @param exercise The source exercise
 */
export const updateCurrentStep = curry(
  (validationPayload: ValidationPayload, exercise: AppExercise): AppExercise => {
    const { aborted, numberOfErrors, validity, stepStatus: status, mode } = validationPayload;

    const currentStepPath = AppExercise.getCurrentStepPath(exercise);
    const currentStep = AppExercise.getCurrentStep(exercise.steps, exercise.currentStepId);

    if (isNil(currentStepPath) || isNil(currentStep)) {
      return exercise;
    }

    const { question, feedbacks } = questionAndFeedbackFromValidationPayload(
      validationPayload,
      currentStepPath,
      currentStep
    );

    const showBetty = mode === SeriesMode.practice && status === StepStatus.completed;

    return Lens.update(
      AppExercise.toCurrentStep,
      (currentStep) =>
        currentStep && {
          ...currentStep,
          feedbacks,
          numberOfErrors,
          validity,
          status,
          ...(showBetty && { showBetty }),
          aborted,
          ...getDisplayOptions(validity, status, mode),
          question: {
            ...currentStep.question,
            present: question,
            future: [],
            hasChangedSinceLastAttempt: false,
          },
        },
      exercise
    );
  }
);

const nextStepById = (stepId: string, exercise: AppExercise): NextStep => {
  const step: AppStep = exercise.steps.find((s) => s && s.id === stepId) as AppStep;

  const question = step.question.present;
  const { answer, answerXML, questionXML } = step;
  return {
    id: stepId,
    question,
    questionXML,
    answer,
    answerXML,
  };
};

/**
 * Updates nextStepId on exercise level which is the next step to display
 * and updated question and answer for this step in the steps array
 *
 * @param stepStatus If step status is not completed next step data is ignored
 * @param validatorStepResponse contains the data for the next step
 * @param exercise The source exercise
 */
export const updateNextStep = curry(
  (
    stepStatus: StepStatus,
    validatorStepResponse: ValidatorStepResponse | NlpValidatorStepResponse | null | undefined,
    exercise: AppExercise
  ): AppExercise => {
    if (!validatorStepResponse || stepStatus !== StepStatus.completed) {
      return exercise;
    }

    const nextStep: NextStep = isMathValidatorStep(validatorStepResponse)
      ? parseNextStepResponse(
          validatorStepResponse,
          AppExercise.getStepPathForStepId(exercise, validatorStepResponse.id)
        )
      : nextStepById(validatorStepResponse.id, exercise);

    const { id: nextStepId, answer, answerXML, question, questionXML } = nextStep;
    return {
      ...exercise,
      nextStepId,
      // one of the places where we filter out `undefined` in the steps array,
      // otherwise `step.id` would throw
      steps: exercise.steps.filter(Boolean).map((step) => {
        return step.id !== nextStepId
          ? step
          : {
              ...step,
              answer,
              answerXML,
              question: withEmptyHistory(question),
              questionXML,
            };
      }),
    };
  }
);

/**
 * Based on SeriesMode, set status for explanation & solution
 */
const skipStepStatus = switchMap(
  {
    [SeriesMode.test]: {
      explanationState: CollapsibleContentState.hidden,
      solutionState: CollapsibleContentState.hidden,
    },
    [SeriesMode.practice]: {
      explanationState: CollapsibleContentState.collapsed,
      solutionState: CollapsibleContentState.hidden,
    },
  },
  {
    explanationState: CollapsibleContentState.hidden,
    solutionState: CollapsibleContentState.hidden,
  }
);

/**
 * Marks all skipped steps as completed and correct for TestMode.
 * Changes status of the last skipped steps to started and set it's step id to the
 * currentStepId of exercise. Validation and status for this step will be updated
 * when `updateCurrentStep` is called afterwards.
 * Following steps stay untouched.
 * TestMode: Solution is shown & Explanation is hidden
 * @param skippedStepIds The list of step ids that where responded by validation as skipped
 * @param exercise The source exercise
 */
export const skipSteps = curry(
  (
    skippedStepIds: string[] | null | undefined,
    mode: SeriesMode,
    exercise: AppExercise
  ): AppExercise => {
    if (!skippedStepIds || skippedStepIds.length <= 0) {
      return exercise;
    }
    // The last step of skipped steps becomes active step and gets different properties
    const lastStepId = skippedStepIds.pop();
    // The current step & rest of the skipped steps are marked as finished and get same properties
    const finishedStepIds = [exercise.currentStepId, ...skippedStepIds];

    return {
      ...exercise,
      currentStepId: lastStepId ? lastStepId : '',
      steps: exercise.steps.map((step) => {
        if (finishedStepIds.indexOf(step.id) !== -1) {
          // The finished steps (current and skipped ones) are set to Validity correct
          return {
            ...step,
            question: withEmptyHistory(step.answer),
            validity: Validity.correct,
            status: StepStatus.completed,
            ...skipStepStatus(mode),
          };
        } else if (step.id === lastStepId) {
          // the last step (the solution was entered for) gets the active one
          return {
            ...step,
            status: StepStatus.started,
          };
        } else {
          // all following steps are not touched
          return step;
        }
      }),
    };
  }
);

export const goToNextStep = curry(
  (mode: SeriesMode, startTime: number, state: ApplicationState): ApplicationState => {
    const exercise = ApplicationState.toCurrentExercise.get(state);
    const nextStepId = exercise.nextStepId || exercise.currentStepId;

    if (!nextStepId) {
      log.error('currentStepId must not be undefined in this state');
      return state;
    }
    /**
     * First update steps status
     * Clear selection from completed steps
     * Select content in new next step
     */
    return compose(
      selectInteractiveChild(true),
      Lens.update(ApplicationState.toCurrentQuestion)(
        (question) => question && STUBABLE.enrichContentDict(question, stylers, rulers, false)
      ),
      selectInteractiveChild(false),
      Lens.update(ApplicationState.toCurrentExercise)(
        compose(
          omit('lastSelection') as ExerciseTransformer,
          set('currentStepId', nextStepId) as ExerciseTransformer,
          completeCurrentAndStartNextStep(mode, nextStepId, startTime)
        )
      )
    )(state);
  }
);

export const setExerciseIndex = (index: number) =>
  Lens.update(ApplicationState.toSeries)(set('currentExerciseIndex', index));

/**
 * Updates the previous exercise (the user just comes from) and the next exercise
 * (the use wants to navigate to) by passing parameters to matching helper function.
 *
 * @param linear Kind of navigation (linear navigation, random navigation)
 * @param exercises The list of exercises
 * @param previousExerciseIndex The index of the previous exercise
 * @param nextExerciseIndex The index of the next exercise
 * @param mode The mode of the series (test/practice) @param startTime The start time
 * to set on exercise/test
 */
export const updateExercisesForNavigation = (
  linear: boolean,
  previousExerciseIndex: number,
  nextExerciseIndex: number,
  mode: SeriesMode,
  startTime: number
) =>
  mode === SeriesMode.review
    ? setExerciseIndex(nextExerciseIndex)
    : compose(
        selectInteractiveChild(false),
        setExerciseIndex(nextExerciseIndex),
        Lens.update(ApplicationState.toExercise(nextExerciseIndex))(
          updateNextExercise(linear)(mode, startTime)
        ),
        Lens.update(ApplicationState.toExercise(previousExerciseIndex))(
          updatePrevExercise(linear)(mode)
        )
      );

/**
 * Handles selection of the correct gizmo.
 *  - if submitted with selected content it will be kept by validation and selected
 *  - if toolpad was closed (no inputfield selected) no content will be selected
 *  - lastSelection is not removed so when opening toolpad after validation the correct
 *    content gets selected
 *  - if step is already completed no selection happens
 */
export const updateSelection = (stepStatus: StepStatus) => {
  const isStepCompleted = stepStatus === StepStatus.completed;

  const enrichAndSelect = Lens.update(ApplicationState.toCurrentQuestion)(
    (question) => question && STUBABLE.enrichContentDict(question, stylers, rulers, isStepCompleted)
  );

  const selectContentInQuestion = isStepCompleted
    ? deselectGizmo(true)
    : selectInteractiveChild(false);

  return compose(enrichAndSelect, selectContentInQuestion);
};

export const startSeries =
  (startSeriesPayload: StartSeriesPayload) => (state: ApplicationState) => {
    const {
      appSettings,
      assignment,
      currentExerciseIndex,
      qaMode,
      seriesSettings,
      seriesReview,
      previewMode,
      title,
      seriesId,
      userId,
      features,
      groupId,
    } = startSeriesPayload;
    const id = seriesId || uuid.v4();
    // Due to setting mode from Uploader
    let seriesMode = SeriesMode.practice;
    // When starting series player in NSP, series mode is derived as follow:
    // 1. If backend returns seriesReview as `true`
    // then seriesPlayer will be always in review mode.
    // 2. If backend returns previewMode as `true`
    // then seriesPlayer will be always in preview mode.
    // 3. If backend returns seriesReview and previewMode as `false`
    // then seriesPlayer will decide mode based on the flow.
    // 4. Default is always set to practice mode.
    if (seriesReview) {
      seriesMode = SeriesMode.review;
    } else if (previewMode) {
      seriesMode = SeriesMode.preview;
    } else {
      if (seriesSettings && seriesSettings.flow === SeriesFlow.random) {
        seriesMode = SeriesMode.test;
      }
    }
    return {
      ...state,
      ...(appSettings && { appSettings }),
      ...(features && { features }),
      ...(qaMode && { qaMode }),
      series: {
        ...(assignment && { assignment }),
        currentExerciseIndex,
        exercises: startSeriesPayload.exercises.map((exercise) => ({
          ...exercise,
          // make sure to start exercises in testmode if nothing is defined yet!
          ...(seriesMode === SeriesMode.test &&
            !exercise.status && { status: ExerciseStatus.started }),
        })),
        id,
        mode: seriesMode,
        seriesStatus: SeriesStatus.started,
        seriesSettings,
        title,
        userId,
        groupId,
        toolbar: DEFAULT_TOOLBAR_SETTINGS,
      },
    };
  };

const setDragSourceAvailabilityInQuestion =
  (availableInDrawer: boolean) => (question: ContentDict) =>
    compose(
      fromPairs,
      map(([id, gizmo]) => [
        id,
        gizmo && isDragSource(gizmo) ? { ...gizmo, availableInDrawer } : gizmo,
      ]),
      toPairs
    )(question);

export const makeAllDragSourcesUnavailable = Lens.update(ApplicationState.toExercises)(
  map(
    Lens.update(AppExercise.toSteps)(
      compose(
        map(Lens.update(AppStep.toCurrentQuestion)(setDragSourceAvailabilityInQuestion(false))),
        // filtering out undefined in `steps` array, see
        //  https://bettermarks.atlassian.net/browse/SPL-108
        filter(Boolean)
      )
    )
  )
);

const makeDragSourcesInCurrentStepAvailable = Lens.update(ApplicationState.toCurrentQuestion)(
  setDragSourceAvailabilityInQuestion(true)
);

export const updateDragSourceDrawerState = compose(
  makeDragSourcesInCurrentStepAvailable,
  makeAllDragSourcesUnavailable
);
