import { ContentColor, DECORATION, SeverityColor } from '@bettermarks/gizmo-types';
import { camelCase, defaultTo, isEmpty, isNil, kebabCase, keys } from 'lodash';
import { Severity } from '@bettermarks/umc-kotlin';
import { isColorName } from '../components/colors';

export type FromClasses = 'fontWeight' | 'color';

export type ParsedDecoration<K extends string> = {
  severity: Severity | undefined;
  object: Partial<Record<K | FromClasses, string>>;
};
/**
 * First step of parsing a `decoration` string from the content.
 *
 * When the same key is set multiple time the last one wins (like in CSS).
 * The same is `true` for the `validation` that is parsed.
 *
 * - The `kebab-case` keys are converted using `lodash` `camelCase`.
 * - By telling which keys are possible as a result,
 *   this method provides typeSafe access to the related `string` values.
 * - All values are of type `string`, any custom typing or convertion needs to happen afterwards
 * - Since the class `emphasis` and the validation is parsed,
 *   this method already provides the keys defined in `FromClasses`.
 *
 * @param {string} input the `decoration` string from the content
 * @returns {ParsedDecoration<K>}
 *
 * @TODO: since the order matters, for allowing other classes we would need to change this function
 *        Idea: a 2nd optional argument that gets the class
 *        and returns the related Partial<ParsedDecoration<K>>.
 */
export const parseDecoString = <K extends string>(
  input: string,
  defaultDecoration?: ParsedDecoration<string>['object']
): ParsedDecoration<K> => {
  let severity: Severity | undefined;
  const inputWithoutSpaces = input.replace(/\s/g, '');
  let object: ParsedDecoration<string>['object'] = {};
  inputWithoutSpaces.split(';').forEach((deco) => {
    const [keyOrClass, value] = deco.split(':', 2);
    if (value === undefined) {
      switch (keyOrClass) {
        case 'defaultDecoration':
          object = defaultDecoration || {};
          break;
        case 'emphasis':
          object.fontWeight = 'bold';
          break;
        case 'calc-table-header':
          object.fontWeight = 'bold';
          object.color = ContentColor.BM_WHITE;
          break;
        case SeverityColor.OK:
          object.color = SeverityColor.OK;
          break;
        case SeverityColor.COMMENT:
          object.color = SeverityColor.COMMENT;
          break;
        case SeverityColor.ERROR:
          severity = Severity.error;
          break;
        case SeverityColor.REMARK:
          severity = Severity.remark;
          break;
        default:
          if (isColorName(keyOrClass)) {
            object.color = keyOrClass;
          }
      }
    } else {
      object[camelCase(keyOrClass)] = value;
    }
  });

  return { severity, object };
};

export function decorationToString(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decoration: any,
  keyMapper = kebabCase,
  severity?: Severity | undefined
): string {
  const decoString = keys(decoration).reduce(
    (acc: string, key: string): string =>
      isNil(decoration[key])
        ? acc
        : `${acc}${keyMapper(key)}:${defaultTo<string>(decoration[key], '')};`,
    ''
  );
  if (isEmpty(decoString)) {
    return severity || '';
  } else {
    return severity ? `${decoString} ${severity}` : decoString;
  }
}

export const decorationAttribute = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  decoration: any,
  keyMapper = kebabCase,
  severity?: Severity | undefined
): string => {
  return isEmpty(decoration) && !severity
    ? ''
    : ` ${DECORATION}="${decorationToString(decoration, keyMapper, severity)}"`;
};
