import { flatMap } from 'lodash';
import { UNKNOWN } from '../../gizmo-utils/configuration/renderStyles';
import { MATH, SEMANTICS } from '../../gizmo-utils/constants';
import { type UnknownContent } from './data';
import {
  type ContentReference,
  type FElement,
  type Importer,
  type ImporterContext,
} from '../../xml-converter/core';

/**
 * special content without render-style, just only the reference key to the localized resource
 * mostly used for the step instruction and standard feedback texts.
 */
export const importUnknown: Importer<UnknownContent> = (preContent, xml, context) => {
  const importChildren = (
    children: ReadonlyArray<FElement>,
    context: ImporterContext
  ): ContentReference[] =>
    flatMap(children, (child) =>
      child.localName === SEMANTICS || child.localName === MATH
        ? context.importXML(child)
        : importChildren(child.children, context)
    );
  const content = importChildren(xml.children, context);
  // when keeping $interactionType gizmo can not be found for rendering,
  // since renderStyle and IT are combined to resolve the Gizmo
  const { $interactionType, ...xmlProps } = preContent;
  return {
    ...xmlProps,
    $renderStyle: UNKNOWN,
    message:
      preContent.$renderStyle === UNKNOWN
        ? 'Unknown Importer Usage'
        : `Missing importer: "${preContent.$renderStyle}"`,
    content,
  };
};
