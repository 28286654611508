import * as React from 'react';
import classNames from 'classnames';

import styles from './OptionDecorator.scss';
import { type OptionDecoratorMode, type OptionState } from './types';

export type OptionDecoratorProps = {
  state: OptionState;
  mode: OptionDecoratorMode;
  interactive: boolean | undefined;
  hasButton: boolean | undefined;
  stretch?: boolean;
  id?: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

/**
 * The `OptionDecorator` component is a wrapper component for multiple choice options.
 *
 * ### Process State
 *
 * - Visuals: Working image tests
 * - Behaviour: ???
 *
 * ### Properties
 | Name           | Type                                    | Default   | Description               |
 |---             |---                                      |---        |---                        |
 | `state`        | one of `['selected', 'unselected', 'error']` | Required  | interaction states the choice can have |
 | `mode`         | one of `['single', 'multi']`                 | Required  | mode of the multiple choice gizmo that uses it |
 | `interactive`  | `boolean`                               | Required  | mc option interactive for user? |
 | `hasButton`    | `boolean`                               | Required  | multiple choice with radio button or checkbox? |
 | `stretch`      | `boolean`                               | Required  | stretch to available width? |
 | `onClick`      | `function`                              | Optional  | callback for click event    |
 */
// tslint:enable:max-line-length
const OptionDecorator: React.FC<OptionDecoratorProps> = (props) => {
  const { mode, state, interactive, hasButton, stretch, onClick, id, onMouseOver, onMouseLeave } =
    props;

  const style = classNames(styles[mode], styles[state], {
    [styles.button]: hasButton,
    [styles.interactive]: interactive,
    [styles.stretch]: stretch,
  });

  return (
    <div
      data-cy="multiple-choice-option"
      className={styles.wrapper}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      role="presentation"
    >
      <div id={id} className={style}>
        {props.children}
      </div>
    </div>
  );
};

OptionDecorator.displayName = 'OptionDecorator';

export default OptionDecorator;
