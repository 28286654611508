import { flattenDeep, isNil } from 'lodash/fp';
import {
  type ApplyStyles,
  type GizmoStyle,
  type GizmoStyleForRefId,
} from '../../../gizmo-utils/configuration';
import { type FEMContent, type KEMContent, EM } from '../../../types';
import { type ContentReference } from '@bettermarks/gizmo-types';
import { EM_EXAMPLE_GROUP_HEADING_COLOR, EM_EXAMPLE_GROUP_HEADING_FONT_SIZE } from './constants';

const gizmoStyle = (gizmoStyle: GizmoStyle, contentRef: ContentReference): GizmoStyleForRefId => ({
  refId: contentRef.$refid,
  style: gizmoStyle,
});

const gizmoStyleOrEmpty = (
  style: GizmoStyle,
  ...contentRefs: (ContentReference | undefined)[]
): GizmoStyleForRefId[] =>
  contentRefs
    .filter((contentRef): contentRef is ContentReference => !isNil(contentRef))
    .map((contentRef) => gizmoStyle(style, contentRef));

export const applyEMStyles = (elements: EM.ElementList, style: GizmoStyle) =>
  flattenDeep<GizmoStyleForRefId>(
    elements.map((element) => {
      switch (element.type) {
        case EM.ElementType.Content:
        case EM.ElementType.Comment:
        case EM.ElementType.Rule:
          return gizmoStyle(style, element.content);

        default:
          // EM.ElementType.ExampleGroup
          const exampleGroupHeadingStyle: GizmoStyle = {
            ...style,
            formulaStyles: {
              ...style.formulaStyles,
              color: EM_EXAMPLE_GROUP_HEADING_COLOR,
              fontSize: EM_EXAMPLE_GROUP_HEADING_FONT_SIZE,
            },
          };

          return [
            ...gizmoStyleOrEmpty(
              element.examples.length > 0 ? exampleGroupHeadingStyle : style,
              element.heading
            ),
            ...element.examples.map((example) => {
              switch (example.type) {
                case EM.ElementType.FreeExample:
                  return example.content.map(({ content }) => gizmoStyle(style, content));

                default:
                  // EM.ElementType.StructuredExample
                  return [
                    ...gizmoStyleOrEmpty(style, example.setting, example.overview),
                    ...(example.steps || []).map((step) =>
                      gizmoStyleOrEmpty(
                        style,
                        step.result.content,
                        step.comment && step.comment.content
                      )
                    ),
                  ];
              }
            }),
          ];
      }
    })
  );

export const applyFEMStyles: ApplyStyles = ({ elements }: FEMContent, style) =>
  applyEMStyles(elements, style);

export const applyKEMStyles: ApplyStyles = ({ pages }: KEMContent, style) =>
  flattenDeep<GizmoStyleForRefId>(pages.map(({ elements }) => applyEMStyles(elements, style)));
