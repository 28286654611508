import { get } from 'lodash';
import {
  $MABS,
  $MROW,
  Direction,
  hasBase,
  isPureMathContent,
  type MathContent,
} from '@bettermarks/gizmo-types';
import {
  CURSOR,
  cursorPath,
  getContainerInfo,
  lastPathElements,
  MROW,
  set,
} from '@bettermarks/importers';

/**
 * Delete content at the given cursor position according to the specified rules. Deletes the element
 * on the right (or left if reverse is set to true).
 * @param {MathContent} input the content containing the cursor somewhere deep within.
 * @param {Direction} direction which direction to delete.
 * @returns {MathContent} content with the element deleted.
 */
export const deleteContent = (input: MathContent, direction: Direction): MathContent => {
  const path = cursorPath(input);
  const [pos, ...mrowChildrenPath] = lastPathElements(path, 1);
  const cursorPos = pos as number;

  const container: MathContent[] = get(input, mrowChildrenPath);

  const adjacentPos = direction === Direction.Left ? cursorPos - 1 : cursorPos + 1;

  // check if container has an adjacent element (i.e., not on the edge)
  if (adjacentPos >= 0 && adjacentPos < container.length) {
    const adjacent = container[adjacentPos];

    if (isPureMathContent(adjacent)) {
      // if adjacent is <mabs/>, release content
      if (adjacent.$ === $MABS) {
        const mabsContent =
          isPureMathContent(adjacent.value) && adjacent.value.$ === $MROW
            ? adjacent.value.children
            : [adjacent.value];

        return set(input, mrowChildrenPath, [
          ...container.slice(0, adjacentPos),
          ...mabsContent,
          ...container.slice(adjacentPos + 1),
        ]);
      }

      if (hasBase(adjacent)) {
        const base = adjacent.base.children[0];
        // if deleting forward the base of some baseful element, put the cursor in the base
        if (direction === Direction.Right) {
          const newBaseful = {
            ...adjacent,
            base: MROW(CURSOR),
          };

          return set(input, mrowChildrenPath, [
            ...container.slice(0, adjacentPos - 1),
            newBaseful,
            ...container.slice(adjacentPos + 1),
          ]);
        } else {
          // delete the exponent
          return set(input, mrowChildrenPath, [
            ...container.slice(0, adjacentPos),
            base,
            ...container.slice(adjacentPos + 1),
          ]);
        }
      }
    }

    // in any other case, delete the adjacent element
    return set(input, mrowChildrenPath, [
      ...container.slice(0, adjacentPos),
      ...container.slice(adjacentPos + 1),
    ]);
  }

  // if the cursor is in the base of a baseful element and deleting to the left,
  // delete "over the edge", e.g. [MN(1), MSUP(CURSOR, MN(2))] -> [MSUP(CURSOR, MN(2))]
  if (mrowChildrenPath.length > 1 && direction === Direction.Left) {
    const { container, pos, parent, parentPath } = getContainerInfo(
      input,
      mrowChildrenPath.slice(0, -2)
    );
    const bucketName = mrowChildrenPath.slice(-2)[0];

    if (hasBase(container) && bucketName === 'base' && pos > 0) {
      return set(input, parentPath, [...parent.slice(0, pos - 1), ...parent.slice(pos)]);
    }
  }

  return input;
};
