import { getFontMetric } from '../../..//utils/fontMetric';
import { type ContentReference, NodeType, type TreeChartContent } from '@bettermarks/gizmo-types';
import {
  type ApplyStyles,
  type GizmoStyle,
  type Ruler,
} from '../../../gizmo-utils/configuration/types';
import { TREE_FORMULA_FONTSIZE } from '@bettermarks/importers';
import { filter } from './layoutTree';
import { compact } from 'lodash/fp';

export const applyTreeChartStyles: ApplyStyles = (
  { layoutTree }: TreeChartContent,
  { formulaStyles }: GizmoStyle
) =>
  layoutTree
    ? compact(
        filter((n) => n.type === NodeType.gizmo, layoutTree).map((n) =>
          n.content
            ? {
                refId: (n.content as ContentReference).$refid,
                style: {
                  formulaStyles: {
                    formulaStyles,
                    fontSize: TREE_FORMULA_FONTSIZE,
                  },
                  scaleProps: {},
                },
              }
            : undefined
        )
      )
    : [];

export const measureTreeChartGizmo: Ruler<TreeChartContent> = (_, content) => {
  const height = getFontMetric(TREE_FORMULA_FONTSIZE).height;

  return {
    height,
    refLine: height * 0.5,
  };
};
