import { isEmpty, isNil } from 'lodash';
import {
  $MN,
  $MROW,
  CONFIGURATION,
  type ContentColor,
  DIAGRAM_BAR_CHART,
  type FElement,
  type Importer,
  type ImporterContext,
  parseCommonAttribs,
  toFloat,
} from '@bettermarks/gizmo-types';
import {
  type Bar,
  type BarChartConfiguration,
  type BarChartContent,
  type BarChartGroup,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import {
  DEFAULT_AXIS_CONTRACTION_HEIGHT,
  DEFAULT_COLORS,
  DEFAULT_TICK_WIDTH,
  DEFAULT_YAXIS_EXTENSION,
  DEFAULT_YAXIS_LABEL_PADDING,
} from '../defaults';

export const importDiagramBar = (xml: FElement, idx: number, context: ImporterContext): Bar => {
  const config = xml.findChildTag(CONFIGURATION);
  const label = config.findChildTag('label');
  const color = config.findChildTag('color');
  const { severity } = parseDecoString(xml.findChildTag($MROW).attribute('decoration'));

  return {
    annotationInner: parseCommonAttribs(xml),
    color: !isEmpty(color.text) ? (color.text as ContentColor) : DEFAULT_COLORS[idx],
    marked: config.findChildTag('mark').text === '1',
    readingHelp: config.findChildTag('readHelp').text === '1',
    ...(label.exists &&
      label.firstChild.exists && {
        label: context.importXML(label.firstChild),
      }),
    yValue: toFloat(xml.findChildTag($MROW).findChildTag($MN).text),
    ...(!isNil(severity) && { severity }),
  };
};

const importBarChartGroup = (
  xml: FElement,
  groupIdx: number,
  context: ImporterContext
): { interactive: boolean; group: BarChartGroup } => {
  const mrow = xml.findChildTag($MROW);
  const label = mrow.findChildTag('label');

  const itemTags = mrow.findChildTag('set').getChildrenByTagName('item');

  // 1) per default a group with only one item and an empty color-tag should get a default color by
  //    groupIdx
  // 2) if you have more than one item per group then the default colors should be used within every
  //    single group
  const items = itemTags.map((item, idx) =>
    importDiagramBar(item.firstChild, itemTags.length > 1 ? idx : groupIdx, context)
  );

  return {
    interactive: !!items.find((i) => !isNil(i.annotationInner.$interactionType)),
    group: {
      annotationInner: parseCommonAttribs(xml),
      items,
      ...(label.exists && { label: context.importXML(label.firstChild) }),
    },
  };
};

const importBarChartConfiguration = (xml: FElement): BarChartConfiguration => ({
  yTickLabelInterval: toFloat(xml.findChildTag('yTickLabelInterval').text),
  yTickValueInterval: toFloat(xml.findChildTag('yTickValueInterval').text),
  yTickValueStart: toFloat(xml.findChildTag('yTickValueStart').text),
  yTickValueEnd: toFloat(xml.findChildTag('yTickValueEnd').text),
  xAxisArrow: toFloat(xml.findChildTag('xAxisArrow').text) > 0,
});

export const getHeights = (config: BarChartConfiguration, interactive: boolean) => {
  const { yTickValueEnd, yTickValueInterval, yTickValueStart } = config;
  const tickCount = (yTickValueEnd - yTickValueStart) / yTickValueInterval + 1;
  const yAxisHeight =
    tickCount * DEFAULT_TICK_WIDTH +
    (interactive ? DEFAULT_YAXIS_EXTENSION : DEFAULT_YAXIS_LABEL_PADDING);
  const axisContraction = yTickValueStart !== 0 ? DEFAULT_AXIS_CONTRACTION_HEIGHT : 0;
  const totalHeight = yAxisHeight + axisContraction;

  return { axisContraction, totalHeight, yAxisHeight };
};

export const importDiagramBarChart: Importer<BarChartContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag($MROW);
  const xLabel = mrow.findChildTag('xLabel');
  const yLabel = mrow.findChildTag('yLabel');
  const title = mrow.findChildTag('title');
  const config = mrow.findChildTag(CONFIGURATION);
  const groups = mrow.getChildrenByTagName('group');
  const caption = mrow.findChildTag('caption');

  const configuration = importBarChartConfiguration(config);
  let hasInteractiveBar = false;

  const importedGroups = groups.map((g, idx) => {
    const { interactive, group } = importBarChartGroup(g.firstChild, idx, context);

    hasInteractiveBar = hasInteractiveBar || interactive;

    return group;
  });

  return {
    ...preContent,
    /**
     * uniqueId for markers/clip-path/filters
     * (assumption: there are no duplicate $refids per exercise)
     */
    uniqueId: context.$refid,
    // set interaction-type for bar-chart in case any bar is interactive
    ...(hasInteractiveBar ? { $interactionType: DIAGRAM_BAR_CHART } : {}),
    ...(xLabel.exists && { xLabel: context.importXML(xLabel.firstChild) }),
    ...(yLabel.exists && { yLabel: context.importXML(yLabel.firstChild) }),
    ...(title.exists && { title: context.importXML(title.firstChild) }),
    ...(caption.exists && { caption: context.importXML(caption.firstChild) }),
    configuration,
    groups: importedGroups,
    labeledGroups: importedGroups.filter((g) => !isNil(g.label)).length,
    ...getHeights(configuration, hasInteractiveBar),
  };
};
