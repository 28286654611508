import * as React from 'react';

import { BASE, type MRow, type MUnder as MUnderContent } from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { Bucket } from '../Bucket';
import { enrich } from '../helpers';
import { type MUnderOverProps } from './types';

import styles from './UnderOver.scss';

/**
 * MUnder: The MathML <munder> element is used to attach an accent or a limit
 * under an expression. It uses the following syntax:
 * <munder>
 *    base
 *    underscript
 * </munder>
 *
 * Currently type, import and export are ready to correcty provide accent attribute,
 * but since the feature wasn't trivial to do it the way this conponent is currently build
 * and didn't have high prio the value is currently NOT written into the data structure
 * It can be enabled inside the `MUNDER` constructor.
 */
export const MUnder: React.FC<MUnderOverProps & MUnderContent> = ({
  children: [base, underscript],
  computedStyles: { backgroundColor },
  baseHeight,
  underscriptHeight,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <span className={styles.munder}>
      <Bucket height={baseHeight}>{base}</Bucket>
      <div className={styles.munderUnderscript}>
        <Bucket height={underscriptHeight} stretched>
          {underscript}
        </Bucket>
      </div>
    </span>
  </BackgroundColor>
);

MUnder.displayName = 'MUnder';

export const enrichMUnder: EnrichNestedMathContent<MUnderContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const {
    enrichedContent: base,
    height: baseHeight,
    refLine: baseRefLine,
  } = mathContentEnricher(formulaStyles, content.base, [...path, BASE]);
  const { enrichedContent: underscript, height: underscriptHeight } = mathContentEnricher(
    formulaStyles,
    content.underscript,
    [...path, 'underscript']
  );

  const height = baseHeight + underscriptHeight;
  const refLine = baseRefLine + underscriptHeight;

  return enrich(
    { ...content, base, underscript, baseHeight, underscriptHeight },
    { height, refLine },
    formulaStyles
  );
};
