import { GeoEditorMode } from '@bettermarks/gizmo-types';
import {
  GeoAddAngleLine,
  GeoAddCircle,
  GeoAddLabel,
  GeoAddLine,
  GeoAddPoint,
  GeoColoring,
  GeoDelete,
  GeoInterval,
  GeoMiddlepoint,
  GeoMoveBeziers,
  GeoMove,
  GeoMovePointsVertically,
  GeoParallels,
  GeoPerpendicular,
  GeoSelect,
  stripColor,
} from './tools';
import { GeoRenderer } from './GeoRenderer';

/* eslint-disable complexity */
export const getGeoComponent = (renderMode: GeoEditorMode) => {
  switch (stripColor(renderMode)) {
    case GeoEditorMode.ADD_ANGLE:
      return GeoAddAngleLine;
    case GeoEditorMode.ADD_CIRCLE:
      return GeoAddCircle;
    case GeoEditorMode.ADD_LABEL:
      return GeoAddLabel;
    case GeoEditorMode.ADD_POINT:
      return GeoAddPoint;
    case GeoEditorMode.ADD_RAY:
    case GeoEditorMode.ADD_RAY_DASHED:
    case GeoEditorMode.ADD_SEGMENT:
    case GeoEditorMode.ADD_SEGMENT_DASHED:
    case GeoEditorMode.ADD_STRAIGHTLINE:
    case GeoEditorMode.ADD_STRAIGHTLINE_DASHED:
    case GeoEditorMode.ADD_VECTOR:
    case GeoEditorMode.ADD_VECTOR_DASHED:
      return GeoAddLine;
    case GeoEditorMode.COLORING:
      return GeoColoring;
    case GeoEditorMode.SCROLL:
    case GeoEditorMode.DEFAULT:
    case GeoEditorMode.DEFAULT_CURSOR:
      return GeoMoveBeziers;
    case GeoEditorMode.INTERVAL:
      return GeoInterval;
    case GeoEditorMode.MIDDLEPOINT:
      return GeoMiddlepoint;
    case GeoEditorMode.MOVE:
      return GeoMove;
    case GeoEditorMode.MOVE_POINTS_VERTICALLY:
      return GeoMovePointsVertically;
    case GeoEditorMode.PARALLELS:
      return GeoParallels;
    case GeoEditorMode.PERPENDICULAR:
      return GeoPerpendicular;
    case GeoEditorMode.DELETE:
      return GeoDelete;
    case GeoEditorMode.SELECT:
      return GeoSelect;
    default:
      // NOT_INTERACTIVE or undefined
      // passing selectedMode to know even in disabled state which tool is currently selected
      return GeoRenderer;
  }
};
