import * as React from 'react';
import { SpriteSheetAPI } from './SpriteSheetAPI';

export interface SpriteSheetProps {
  width: number;
  height: number;
  startFrame: number;
  numFrames: number;
  frameRate?: number;
  loop?: boolean;
  src: string;
  onLoad?: (api: SpriteSheetAPI) => void;
  onFinished?: () => void;
}

const useImage = (src: string): HTMLImageElement | undefined => {
  const [image, setImage] = React.useState<HTMLImageElement>();

  React.useEffect(() => {
    const img = document.createElement('img');
    img.src = src;
    //  If the image is set via img.src="data url" and the onload callback is fired,
    //  the image is not yet really ready for drawing. Should call setImage in onload callback
    img.onload = () => {
      setImage(img);
    };
  }, [src]);
  return image;
};

export const SpriteSheet: React.FC<SpriteSheetProps> = ({
  width,
  height,
  startFrame,
  numFrames,
  frameRate = 10,
  loop,
  src,
  onLoad,
  onFinished,
}) => {
  const [sprite, setSprite] = React.useState<SpriteSheetAPI>();
  const image = useImage(src);

  const onRefMounted = React.useCallback(
    (canvas: HTMLCanvasElement) => {
      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          // stop the old sprite animation, so it can be removed by the garbage collector
          sprite && sprite.stop();
          setSprite(new SpriteSheetAPI(ctx));
        }
      }
    },
    [src]
  );

  React.useEffect(() => {
    if (sprite && image) {
      sprite.image = image;
      sprite.fw = width;
      sprite.fh = height;
      sprite.startFrame = startFrame;
      sprite.numFrames = numFrames;
      sprite.loop = !!loop;
      sprite.fps = frameRate;
      sprite.onFinished = onFinished;
      // always reset to first frame, as we don't know if the new image
      // supports the current frame number.
      sprite.frame = sprite.startFrame;
      sprite.drawFrame();
    }
  }, [sprite, image, width, height, startFrame, numFrames, loop, frameRate, onFinished]);

  React.useEffect(() => {
    if (sprite) {
      onLoad && onLoad(sprite);
    }
  }, [sprite, onLoad]);

  return <canvas ref={onRefMounted} width={width} height={height} />;
};

SpriteSheet.displayName = 'SpriteSheet';
