import { ensureFontsLoaded } from '@bettermarks/bm-font';
import type { Action } from 'redux-actions';
import { all, call, put, select } from 'redux-saga/effects';
import {
  type ApplicationState,
  DEFAULT_SERIES_SETTINGS,
  LoaderState,
  type StartSeriesPayload,
} from '../../types';
import { handleFontLoadingErrors } from '../handleFontLoadingErrors';
import { changeLoaderState } from '../seriesplayer/containers/SeriesPlayer/actions';
import { startAndInitSeries } from '../seriesplayer/containers/SeriesPlayer/loadSeriesSaga';
import { getExercises } from '../seriesplayer/containers/Upload/helper';

type ExampleModeSeriesParams = {
  exerciseId: string;
  staticUrl?: string;
};

export async function fetchSeries(
  exerciseId: string,
  staticServerUrl: string
): Promise<StartSeriesPayload> {
  const response = await fetch(`${staticServerUrl}/content_exercises/${exerciseId}.xml`);

  const xml = await response.text();
  const exercises = getExercises([xml], false, false);

  return {
    exercises,
    seriesSettings: DEFAULT_SERIES_SETTINGS,
    currentExerciseIndex: 0,
    seriesReview: false,
    previewMode: false,
  };
}

export function* initExampleModeSeriesSaga({ payload }: Action<ExampleModeSeriesParams>) {
  try {
    yield put(changeLoaderState(LoaderState.loading));
    const appState: ApplicationState = yield select();

    const [startSeriesPayload]: [StartSeriesPayload] = yield all([
      call(
        fetchSeries,
        payload.exerciseId,
        payload.staticUrl ? payload.staticUrl : appState.appSettings.staticServerUrl
      ),
      call(ensureFontsLoaded, 5000, handleFontLoadingErrors),
    ]);

    yield startAndInitSeries(startSeriesPayload);
  } catch (error) {
    yield put(changeLoaderState(LoaderState.success));
  }
}
