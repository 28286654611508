import { flatten, isNil } from 'lodash';
import {
  type ContentReference,
  ReadingOrder,
  type TableContainerContent,
  type TableContent,
  type WhiteSpace,
} from '@bettermarks/gizmo-types';
import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { type FontWeight } from '@bettermarks/bm-font';

export const applyTableStyles: ApplyStyles = (content: TableContent, outerStyles) =>
  flatten(
    content.rows.map((r) =>
      r.cells
        .filter((c) => !!c.content)
        .map((c) => ({
          refId: (c.content as ContentReference).$refid,
          style: outerStyles,
        }))
    )
  );

export const applyPlaceValueTableStyles: ApplyStyles = (content: TableContent, outerStyles) =>
  flatten(
    content.rows.map((r) =>
      r.cells
        .filter((c) => !!c.content)
        .map((c) => ({
          refId: (c.content as ContentReference).$refid,
          style: {
            ...outerStyles,
            ...(!isNil(r.class) && {
              formulaStyles: {
                ...outerStyles.formulaStyles,
                ...(r.class === 'title' && {
                  fontWeight: 'bold' as FontWeight,
                  whiteSpace: 'nowrap' as WhiteSpace,
                }),
                ...(r.class === 'subtitle' && {
                  fontSize: 10,
                }),
              },
            }),
          },
        }))
    )
  );

export const applyTableContainerStyles: ApplyStyles = (
  content: TableContainerContent,
  outerStyles,
  innerStyles
) => [
  ...content.arrows.map((a) => ({ refId: a.label.$refid, style: outerStyles })),
  ...applyTableStyles(content.table, outerStyles, innerStyles),
];

export const measureTable: Ruler<TableContent> = (outerStyles, content, getMetrics) => {
  let height = 0;

  // adding borders
  height +=
    2 * content.outerLineWidth + content.innerHorizontalLineWidth * (content.rows.length - 1);

  // adding height of rows
  if (!isNil(content.cellHeight)) {
    height += content.cellHeight * content.rows.length;
  } else {
    // this will only work for for gizmos without auto browser linebreak
    // (e.g. not for mtext in formula)
    if (content.readingOrder !== ReadingOrder.column) {
      height += content.rows.reduce(
        (accr, r) =>
          accr +
          r.cells.reduce((accc, c) => {
            const padding = c.padding ? c.padding.top + c.padding.bottom : 0;

            return c.content ? Math.max(accc, getMetrics(c.content.$refid).height + padding) : accc;
          }, 0),
        0
      );
    }
  }

  return {
    height,
    refLine: height * 0.5,
  };
};
