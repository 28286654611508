import { DEFAULT_MEDIA_ROUTE } from '../constants';
import * as React from 'react';
import qs from 'query-string';
import { identity } from 'lodash';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { type Dispatch } from '../redux/types';
import { type GizmoRegistry } from '../configuration';
import { useLocation } from 'react-router-dom';

export interface IGizmoProviderContext {
  gizmos: GizmoRegistry;
  contentDict: ContentDict;
  dispatch: Dispatch;
  hideHelpTools?: boolean;
  isTouch: boolean;
  selectedGizmoRef?: React.RefObject<HTMLDivElement>;
  staticMediaUrl: string;
}

export type GizmoProviderContext = Readonly<IGizmoProviderContext>;

// Creating GizmoContext using new React Context API to share data to this child components
export const GizmoContext = React.createContext<GizmoProviderContext>({
  gizmos: {},
  contentDict: {},
  dispatch: identity,
  isTouch: false,
  selectedGizmoRef: React.createRef(),
  staticMediaUrl: DEFAULT_MEDIA_ROUTE,
});

export class GizmoProvider extends React.PureComponent<GizmoProviderContext> {
  render() {
    return <GizmoContext.Provider value={this.props}>{this.props.children}</GizmoContext.Provider>;
  }
}

export const useGizmoContext = () => {
  const context = React.useContext<GizmoProviderContext>(GizmoContext);
  if (context === undefined) {
    throw new Error('useGizmoContext can only be used inside a GizmoProvider');
  }
  return context;
};

export const useStaticMediaUrl = (): string => {
  const { search } = useLocation();
  const { staticMediaUrl } = React.useMemo(() => qs.parse(search), [search]);
  const { staticMediaUrl: defaultStaticMediaUrl } =
    React.useContext<GizmoProviderContext>(GizmoContext);

  return typeof staticMediaUrl === 'string' ? staticMediaUrl : defaultStaticMediaUrl;
};
