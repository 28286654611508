import * as React from 'react';

import { EM } from '../../../types';

import { FreeExample } from './FreeExample';
import { StructuredExample } from './StructuredExample';

export type ExampleProps = Readonly<
  (EM.ElementFreeExample | EM.ElementStructuredExample) & {
    availableWidth: number;
  }
>;

export const Example: React.FC<ExampleProps> = (example) =>
  example.type === EM.ElementType.FreeExample ? (
    <FreeExample {...example} />
  ) : (
    // EM.ElementType.StructuredExample
    <StructuredExample {...example} />
  );

Example.displayName = 'Example';
