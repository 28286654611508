import * as React from 'react';
import { includes } from 'lodash';
import { Hover, type LineObject, type PointObject, SEGMENT } from '@bettermarks/gizmo-types';
import { Line } from '../../components';
import { type LineSetProps, type SetProps } from './types';
import { angle, visibleCenter } from '@bettermarks/importers';
import { isSelectableObject } from './setHelpers';

export type SegmentSetProps = SetProps &
  LineSetProps & {
    segments: ReadonlyArray<string>;
  };

export const SegmentSet: React.FC<SegmentSetProps> = ({
  segments,
  geoContentMap,
  matrix,
  borderRectId,
  highlight,
  configuration,
  mode,
  onClick,
  onHoverId,
  onMouseOutId,
  onMouseDownId,
  snapHighlightIds,
}) => {
  const [onHover, onMouseOut, onMouseDown] = [onHoverId, onMouseOutId, onMouseDownId];
  return (
    <g>
      {segments.map((id) => {
        const s = geoContentMap[id] as LineObject;
        const p1 = geoContentMap[s.p1Id] as PointObject;
        const p2 = geoContentMap[s.p2Id] as PointObject;

        const center = highlight
          ? visibleCenter(p1.coords, p2.coords, configuration.display, SEGMENT)
          : undefined;
        const rotate = highlight ? angle(p1.coords, p2.coords) : undefined;

        const callbacks = {
          ...(onClick && { onClick: onClick(id) }),
          ...(onHover && { onHover: onHover(id) }),
          ...(onMouseDown && { onMouseDown: onMouseDown(id) }),
          ...(onMouseOut && { onMouseOut: onMouseOut(id) }),
        };

        const hover = s.hover || (includes(snapHighlightIds, id) ? Hover.DEFAULT : undefined);

        return (
          <Line
            key={id}
            {...{ id, matrix, borderRectId, highlight, center, rotate, hover }}
            x1={p1.coords.x}
            y1={p1.coords.y}
            x2={p2.coords.x}
            y2={p2.coords.y}
            decoration={s.decoration}
            notLabelable={s.notLabelable}
            severity={s.severity}
            selectable={isSelectableObject(s, mode)}
            {...callbacks}
          />
        );
      })}
    </g>
  );
};

SegmentSet.displayName = 'SegmentSet';
