import { type Action } from 'redux-actions';
import { call, delay, put, select } from 'redux-saga/effects';
import {
  AppExercise,
  ApplicationState,
  AppStep,
  DialogType,
  ErrorHandleKind,
  ExerciseStatus,
  type LobLinkReportingSettings,
  SeriesMode,
  type SeriesScore,
  SeriesStatus,
} from '../../../../types';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { handleError } from '../../sagaHelpers';
import { getSeriesResult, saveStepResult } from '../../services/api/result-manager';
import { hideStepValidationLoader, SHOW_SOLUTION, validationProgress } from '../Exercise/actions';
import { validationSuccess } from '../../reducers/validationSuccess';
import { closeDialog, openDialog, showEmptyInputDialog } from '../SeriesPlayer/actions';
import { setSeriesScore, setSeriesStatus } from './actions';
import { getValidatorResponse } from '../../validationHelpers';
import { getStepResult } from '../../../../apps/seriesplayer/reducers/reporting';
import { type ExtendedValidatorResponse } from './ExtendedValidatorResponse';
import { Validity } from '@bettermarks/umc-kotlin';
import { unlockScreen } from '../../reducers';
import {
  reportLobLinkScore,
  reportLtiResult,
} from '../../services/api/result-manager/resultManager';
import { getSeriesScore } from '../Dialog/Endscreen/EndscreenContainer';
import { openDragAndDropDrawerSaga } from '../Toolbar/sagas';
import log from 'loglevel';
import { reduxStateFlowService } from '../../../store/jsstore';
import { getApplicationStatus } from '../../helper';

export type ValidateStepPayload = {
  exercise: AppExercise;
  exerciseIndex: number;
  step: AppStep;
  stepIndex: number;
  question: ContentDict;
};

// Time in seconds from startTime to the present moment
const secondsSince = (startTime: number) => Math.round((new Date().getTime() - startTime) / 1000);

export const getExerciseTimeUsed = (exercise: AppExercise) =>
  exercise.startTime > 0 && exercise.status === ExerciseStatus.completed
    ? secondsSince(exercise.startTime)
    : 0;

export const getStepTimeUsed = (step: AppStep) =>
  step.startTime > 0 ? secondsSince(step.startTime) : 0;

/**
 * This saga is used in practicemode to validate user input and report result to
 * backend. In testmode it is used in multi step exercises for enter irrevocably.
 * A loader is shown while this runs.
 *
 * @param action
 * @param _delay: allow to pass in mock for testing
 * @param _validateSubmittedStep: allow to pass in mock for testing
 * We needed to pass mockDelay function and validateSubmittedStep generator for testing purposes
 * as in tests clock will not move forward automatically
 * in case of fakeTimers. We tried mocking `delay` but
 * cannot find a reliable method to get it to work.
 * Also stubbing the generator we were not able to verify the arguments passed to it.
 */
export function* submitSaga(
  action: Action<any>,
  _delay = delay,
  _validateSubmittedStep = validateSubmittedStep
) {
  const state: ApplicationState = yield select();

  if (!state.runtimeState.isOnline) {
    yield put(
      openDialog({
        type: DialogType.error,
        payload: {
          kind: ErrorHandleKind.confirm,
          description: 'seriesplayer:dialog.retry.title',
        },
      })
    );

    return;
  }

  const currentExercise = ApplicationState.toCurrentExercise.get(state);
  /**
   * `currentStep` could be undefined only if there is no active step
   * or if the current exercise is completed.
   * The step, exercise and series are marked completed in the single submitSaga run
   * if the last step of the last exercise is completed.
   * Thus it can be safely assumed that this saga will not be triggered again afterwards.
   */
  const currentStep = AppExercise.toCurrentStep.get(currentExercise) as AppStep;
  const currentStepIndex = AppExercise.getCurrentStepIndex(
    currentExercise.steps,
    currentExercise.currentStepId
  );
  const currentQuestion = AppStep.toCurrentQuestion.get(currentStep);

  if (state.dialog && state.dialog.type === DialogType.enterInputConfirmation) {
    yield put(closeDialog());
  }

  // Change the state to indicate that we will start validation
  yield put(validationProgress({}));

  // Needs to wait a render cycle to disable submit button... Seems to be hacky
  yield _delay(10);

  yield* withErrorHandling(
    _validateSubmittedStep(
      {
        exercise: currentExercise,
        exerciseIndex: state.series.currentExerciseIndex,
        step: currentStep,
        stepIndex: currentStepIndex,
        question: currentQuestion,
      },
      action.type === SHOW_SOLUTION
    )
  );

  yield put(hideStepValidationLoader());

  // For test mode we need to toggle the DnD drawer here,
  // because the user does not emmit a NEXT_STEP action after submitting input of intermediate steps
  if (state.series.mode === SeriesMode.test) {
    yield* openDragAndDropDrawerSaga();
  }

  const { apiKey, reporting, seriesId, seriesStatus, resultManagerUrl, exercises } = yield select(
    (state: ApplicationState) => ({
      apiKey: state.appSettings.contentManagerApiKey,
      reporting: state.series.seriesSettings.reporting,
      seriesStatus: state.series.seriesStatus,
      seriesId: state.series.id,
      resultManagerUrl: state.appSettings.resultManagerUrl,
      exercises: state.series.exercises,
    })
  );

  if (seriesStatus === SeriesStatus.completed) {
    // If the series is finished, post a series event and get the result
    // Post result only for series started via backend. Others are dummy-series
    if (reporting) {
      const seriesScore: SeriesScore = yield call(getSeriesResult, resultManagerUrl, { seriesId });

      yield put(setSeriesScore(seriesScore));
    }

    if (state.ltiReporting) {
      const seriesScore: SeriesScore = getSeriesScore(exercises);

      yield call(
        reportLtiResult,
        resultManagerUrl,
        seriesScore ? (seriesScore.pointsReached / seriesScore.pointsMax).toPrecision(1) : '0'
      );
    }

    if (state.lobLinkReportingSettings) {
      const seriesScore: SeriesScore = getSeriesScore(exercises);
      const settings: LobLinkReportingSettings = state.lobLinkReportingSettings;

      yield call(
        reportLobLinkScore,
        resultManagerUrl,
        settings.userId,
        settings.lobId,
        seriesScore
          ? Math.floor((100 * seriesScore.pointsReached) / seriesScore.pointsMax).toString()
          : '0',
        settings.bookId,
        settings.token,
        apiKey
      );
    }

    if (state.features.persistReduxStateFlow) {
      try {
        reduxStateFlowService.instance?.removeSeriesById(state.series.id);
      } catch (err) {
        log.warn('Could not remove series from IndexedDB.', seriesId);
      }
    }
  }
}

/**
 * This saga will only save input without writing anything to store. This is needed
 * for being able to resume with this state when quitting the app in testmode.
 * This is a blocking call.
 */
export function* saveInputSaga() {
  const state: ApplicationState = yield select();
  const {
    currentExerciseIndex: exerciseIndex,
    seriesSettings: { reporting },
  } = state.series;
  const exercise = ApplicationState.toCurrentExercise.get(state);
  const step = AppExercise.toCurrentStep.get(exercise);
  // In gremlins and in NSP, when reporting is disabled (for teacher), don't report step.
  if (step && reporting) {
    const stepIndex = AppExercise.getCurrentStepIndex(exercise.steps, exercise.currentStepId);
    yield call(
      saveStepResult,
      state.appSettings.resultManagerUrl,
      getStepResult(state.series, exerciseIndex, stepIndex, getStepTimeUsed(step), 0)
    );
  }
}

/**
 * Validates the step and dispatch appropriate actions.
 * @param validateStepPayload
 * @param showSolution
 */
export function* validateSubmittedStep(
  validateStepPayload: ValidateStepPayload,
  showSolution: boolean
) {
  const validatorResponse = yield* validate(validateStepPayload, showSolution);

  const { exercise, exerciseIndex, step, stepIndex } = validateStepPayload;
  const { skippedStepIds } = validatorResponse;
  const numSkippedSteps = skippedStepIds?.length || 0;

  const shouldShowEmptyInputDialog = yield* inputIsEmptyOrNotChanged(
    validatorResponse,
    showSolution
  );

  if (shouldShowEmptyInputDialog) {
    yield put(showEmptyInputDialog());
  } else {
    yield put(validationSuccess(validatorResponse));
    yield* submitStepResult(
      exerciseIndex,
      stepIndex,
      numSkippedSteps,
      step,
      exercise,
      validatorResponse.seriesStatus
    );
  }
}

function* inputIsEmptyOrNotChanged(
  validatorResponse: ExtendedValidatorResponse,
  showSolution: boolean
) {
  const state: ApplicationState = yield select();
  const question = ApplicationState.getCurrentUndoableQuestion(state);

  // do not show "Empty Input" dialog for teachers
  if (!state.series.seriesSettings.reporting) return false;

  if (state.series.mode !== SeriesMode.practice) return false;

  if (showSolution) return false;

  if (validatorResponse.isEmptyInput) return true;

  // If answer is correct,we should immediately return
  // We need to check this before looking through history of changes
  if (validatorResponse.validity === Validity.correct) return false;
  if (validatorResponse.validity === Validity.valid) return false;

  // If user haven't updated history or rolled back to initial state, we should show dialog
  if (question?.past.length === 0) return true;

  if (question?.hasChangedSinceLastAttempt === false) return true;

  return false;
}

export function* withErrorHandling<T, TResult, TNext>(generator: Generator<T, TResult, TNext>) {
  try {
    return {
      result: yield* generator,
      error: null,
    };
  } catch (err) {
    yield call(handleError, err);

    return {
      result: null,
      error: err as Error,
    };
  }
}

function* validate(validateStepPayload: ValidateStepPayload, showSolution: boolean) {
  const state: ApplicationState = yield select();

  const {
    validation,
    appSettings: { validatorUrls },
  } = state;

  const mode = ApplicationState.toSeriesMode.get(state);

  const response: ExtendedValidatorResponse = yield call(
    getValidatorResponse,
    validateStepPayload,
    mode,
    validation,
    validatorUrls,
    showSolution
  );

  const { exercise, exerciseIndex, step } = validateStepPayload;

  const { seriesStatus, exerciseStatus, stepStatus } = getApplicationStatus(
    response,
    state.series,
    exercise,
    exerciseIndex,
    step
  );

  return {
    ...response,
    seriesStatus,
    exerciseStatus,
    stepStatus,
    aborted: showSolution,
    mode,
  };
}

function* submitStepResult(
  exerciseIndex: number,
  stepIndex: number,
  numSkippedSteps: any,
  step: AppStep,
  exercise: AppExercise,
  seriesStatus: SeriesStatus
) {
  const state: ApplicationState = yield select();
  // Post the data to the backend in case of practise mode and reporting being ON
  if (state.series.seriesSettings.reporting) {
    yield call(
      saveStepResult,
      state.appSettings.resultManagerUrl,
      getStepResult(
        state.series,
        exerciseIndex,
        stepIndex + numSkippedSteps,
        getStepTimeUsed(step),
        getExerciseTimeUsed(exercise)
      ),
      true
    );
    yield put(unlockScreen());
    // XXX: This should have been done before reporting!
    if (seriesStatus === SeriesStatus.completed) {
      yield put(setSeriesStatus(seriesStatus));
    }
  }
}
