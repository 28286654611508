import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { selectItem } from './multipleChoiceActions';
import { MultipleChoiceSingle, type MultipleChoiceSingleCallbacks } from './MultipleChoiceSingle';

const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): MultipleChoiceSingleCallbacks => ({
  itemChosen: (item: number) => dispatch(gizmoAction(selectItem(item), refid)),
});

export const MultipleChoiceSingleContainer = connectGizmo(mapDispatchToProps, MultipleChoiceSingle);
