import * as React from 'react';
import {
  StyledHLayout,
  StyledVLayout,
  HLayoutChildWrapper,
  VLayoutChildWrapper,
} from '@seriesplayer/common-ui';

import {
  type BChildDecoration,
  type BLayoutContent,
  type BorderChild,
  BLayoutAlign,
  OrientationWithWidth,
} from '@bettermarks/gizmo-types';
import {
  MAX_ROW_WIDTH,
  maxChildWidth,
  getDecoration,
  externalDecorators,
} from './borderLayoutHelpers';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { isNil, omit } from 'lodash';
import { ALIGNMENT_MAP } from '../LayoutGizmo';
import styles from './BLayout.scss';

const HChild: React.FC<{
  c: BorderChild;
  width: number;
  decoration?: BChildDecoration;
}> = ({ c, width, decoration }) => {
  const { verticalAlign, horizontalAlign, ...deco } = getDecoration(decoration, width);
  const w = deco.width ? deco.width : width;

  return (
    <div style={deco}>
      {isNil(horizontalAlign) ? (
        <PolymorphicGizmo refid={c.$refid} availableWidth={w} />
      ) : (
        <StyledVLayout gap={0} align={ALIGNMENT_MAP[horizontalAlign]} width={w}>
          <VLayoutChildWrapper noMargin>
            <PolymorphicGizmo refid={c.$refid} availableWidth={w} />
          </VLayoutChildWrapper>
        </StyledVLayout>
      )}
    </div>
  );
};

HChild.displayName = 'HChild';

const VChild: React.FC<{
  c: BorderChild;
  width: number;
  decoration?: BChildDecoration;
}> = ({ c, width, decoration }) => {
  const { width: w, verticalAlign, horizontalAlign, ...deco } = getDecoration(decoration, width);

  /**
   * to support horizontal alignment of northern and southern containers,
   * remove width: w from the style underneath.
   * Currently not supported to have 100% width on those elements.
   */
  return (
    <div style={{ ...deco, width: w }}>
      {isNil(verticalAlign) ? (
        <PolymorphicGizmo refid={c.$refid} availableWidth={w ? w : width} />
      ) : (
        <StyledHLayout gap={0} align={ALIGNMENT_MAP[verticalAlign]}>
          <HLayoutChildWrapper>
            <PolymorphicGizmo refid={c.$refid} availableWidth={w ? w : width} />
          </HLayoutChildWrapper>
        </StyledHLayout>
      )}
    </div>
  );
};

VChild.displayName = 'VChild';

/* eslint-disable-next-line complexity*/
export const BLayout: React.FC<BLayoutContent & { availableWidth?: number }> = ({
  availableWidth = MAX_ROW_WIDTH,
  content,
  hGap,
  vGap,
}) => {
  const { north: N, south: S }: Partial<Record<'north' | 'south', BorderChild>> = content.reduce(
    (acc, c) => (!(c.orientation in OrientationWithWidth) ? { ...acc, [c.orientation]: c } : acc),
    {}
  );

  const {
    west: W,
    center: C,
    east: E,
  }: Partial<Record<'west' | 'center' | 'east', BorderChild & { width: number }>> = content.reduce(
    (acc, c) => (c.orientation in OrientationWithWidth ? { ...acc, [c.orientation]: c } : acc),
    {}
  );

  const maxWidth = Math.min(availableWidth, MAX_ROW_WIDTH);
  const numberOfHBoxes = Number(!!W) + Number(!!C) + Number(!!E);
  /**
   * Dependent on the number of central boxes (W?, C?, E?) and the availableWidth,
   * determine if the last one in the row went below the other(s)
   * @type {boolean}
   */
  const lastHBoxDown =
    availableWidth < MAX_ROW_WIDTH &&
    W &&
    C &&
    E &&
    availableWidth >= (W.width + C.width) * MAX_ROW_WIDTH;
  /**
   * Dependent on the number of central boxes in one row determine the width that is
   * reserved for gaps
   * @type {number}
   */
  const totalHGap = (numberOfHBoxes - Number(lastHBoxDown) - 1) * hGap;

  return (
    <div style={{ maxWidth }} className={styles.noScroll}>
      <StyledVLayout gap={vGap} maxWidth={maxWidth}>
        {N && (
          <VLayoutChildWrapper
            noMargin
            decoration={externalDecorators(BLayoutAlign.horizontalAlign, N)}
          >
            <VChild
              c={N}
              width={maxWidth}
              decoration={omit(N.decoration, [BLayoutAlign.horizontalAlign])}
            />
          </VLayoutChildWrapper>
        )}
        <VLayoutChildWrapper noMargin={!N}>
          <StyledHLayout gap={hGap}>
            {W && (
              <HLayoutChildWrapper decoration={externalDecorators(BLayoutAlign.verticalAlign, W)}>
                <HChild
                  c={W}
                  width={maxChildWidth(
                    lastHBoxDown && C ? W.width / (W.width + C.width) : W.width,
                    maxWidth,
                    totalHGap,
                    !lastHBoxDown
                  )}
                  decoration={omit(W.decoration, [BLayoutAlign.verticalAlign])}
                />
              </HLayoutChildWrapper>
            )}
            {C && (
              <HLayoutChildWrapper decoration={externalDecorators(BLayoutAlign.verticalAlign, C)}>
                <HChild
                  c={C}
                  width={maxChildWidth(
                    lastHBoxDown && W ? C.width / (W.width + C.width) : C.width,
                    maxWidth,
                    totalHGap,
                    !lastHBoxDown
                  )}
                  decoration={omit(C.decoration, [BLayoutAlign.verticalAlign])}
                />
              </HLayoutChildWrapper>
            )}
            {E && (
              <HLayoutChildWrapper decoration={externalDecorators(BLayoutAlign.verticalAlign, E)}>
                <HChild
                  c={E}
                  width={maxChildWidth(E.width, maxWidth, totalHGap)}
                  decoration={omit(E.decoration, [BLayoutAlign.verticalAlign])}
                />
              </HLayoutChildWrapper>
            )}
          </StyledHLayout>
        </VLayoutChildWrapper>
        {S && (
          <VLayoutChildWrapper decoration={externalDecorators(BLayoutAlign.verticalAlign, S)}>
            <VChild
              c={S}
              width={maxWidth}
              decoration={omit(S.decoration, [BLayoutAlign.horizontalAlign])}
            />
          </VLayoutChildWrapper>
        )}
      </StyledVLayout>
    </div>
  );
};

BLayout.displayName = 'BLayout';
