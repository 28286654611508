import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import log from 'loglevel';
import uuid from 'uuid';
import { MediaButton, Seekbar } from './MediaPlayerControls';
import { initialize, set, requestProgress, play, pause } from './mediaControlActions';
import { numberFromStyles } from '@bettermarks/gizmo-types';

import {
  PauseCircle,
  PauseCircleInv,
  PlayCircle,
  PlayCircleInv,
  ReplayCircle,
  ReplayCircleInv,
  BettyOops,
} from '../icons';

import styles from './MediaPlayer.scss';

const SMALL_BUTTON_SIZE = numberFromStyles(styles.SMALL_BUTTON_SIZE);
const LARGE_BUTTON_SIZE = numberFromStyles(styles.LARGE_BUTTON_SIZE);
const LARGE_BUTTON_OVERLAY_BORDER = numberFromStyles(styles.LARGE_BUTTON_OVERLAY_BORDER);

// The possible (M)edia (C)ontrol (States) inside the player.
enum MCState {
  INITIAL = 'INITIAL',
  READY = 'READY',
  PLAYING = 'PLAYING',
  PAUSED_OR_FINISHED = 'PAUSED_OR_FINISHED',
  SELECTING = 'SELECTING',
}

// The possible (M)edia (C)ontrol (Action)s
enum MCAction {
  INITIALIZE = 'INITIALIZE',
  CLICK = 'CLICK',
  SELECT = 'SELECT',
  SELECT_FINISHED = 'SELECT_FINISHED',
  FINISH = 'FINISH',
}

type MediaPlayerProps = {
  src: string;
  width: number;
  height: number;
  scale: number;
  id?: string;
};

type MediaPlayerState = {
  // a unique identifier for the 'iframe window' in order to identify incoming
  // messages from the 'postMessage' API.
  frameUuid: string;
  // the current media control state.
  current: MCState;
  // the 'previous' media control state. Previous state can be undefined.
  previous: MCState | undefined;
  // the (zero based) 'current frame'
  currFrame: number;
  // the (zero based) 'highest frame'
  maxFrame: number;
};

// creates a combined key from one or optional two strings.
// we will use that with our state machine ...
const key = (k1: string, k2?: string): string => (!k2 ? k1 : `${k1}___${k2}`);

// comments see below
const _MCStateMachine: { [key: string]: any } = {
  [key(MCState.INITIAL)]: {
    [MCAction.INITIALIZE]: MCState.READY,
  },
  [key(MCState.READY)]: {
    [MCAction.CLICK]: MCState.PLAYING,
    [MCAction.SELECT]: MCState.SELECTING,
  },
  [key(MCState.PLAYING)]: {
    [MCAction.CLICK]: MCState.PAUSED_OR_FINISHED,
    [MCAction.FINISH]: MCState.PAUSED_OR_FINISHED,
    [MCAction.SELECT]: MCState.SELECTING,
    [MCAction.SELECT_FINISHED]: MCState.PLAYING,
  },
  [key(MCState.PAUSED_OR_FINISHED)]: {
    [MCAction.CLICK]: MCState.PLAYING,
    [MCAction.SELECT]: MCState.SELECTING,
    [MCAction.SELECT_FINISHED]: MCState.PAUSED_OR_FINISHED,
  },
  [key(MCState.SELECTING, MCState.READY)]: {
    [MCAction.SELECT]: MCState.SELECTING,
    [MCAction.SELECT_FINISHED]: MCState.PAUSED_OR_FINISHED,
    [MCAction.FINISH]: MCState.PAUSED_OR_FINISHED,
  },
  [key(MCState.SELECTING, MCState.PLAYING)]: {
    [MCAction.SELECT]: MCState.SELECTING,
    [MCAction.SELECT_FINISHED]: MCState.PLAYING,
    [MCAction.FINISH]: MCState.PAUSED_OR_FINISHED,
  },
  [key(MCState.SELECTING, MCState.PAUSED_OR_FINISHED)]: {
    [MCAction.SELECT]: MCState.SELECTING,
    [MCAction.SELECT_FINISHED]: MCState.PAUSED_OR_FINISHED,
    [MCAction.FINISH]: MCState.PAUSED_OR_FINISHED,
  },
};

/**
 * The (M)edia (C)ontrol (StateMachine) holding a 'transition' map.
 * This is kind of some 'extended state machine'. New state depends
 * not only on current state and action, but OPTIONALLY also on
 * 'previous state (the state 'before' the 'current state')! For details,
 * refer to
 * "https://softwareengineering.stackexchange.com/questions/379345/
 *   can-a-state-machine-transition-depend-on-the-previous-state"
 * The state model looks like this:
 * (new_state, current_state) = MCStateMachine(action, current_state, ?prev_state).
 * As a fallback, if a combined key of (current state and previous state) does not
 * give a result, only the 'current state' counts as a key!
 */
const MCStateMachine = (action: string, current: string, previous?: string) =>
  (_MCStateMachine[key(current, previous)] || _MCStateMachine[key(current)])[action] ||
  log.warn(
    `Warning: Next state not found for current=${current}, previous=${previous}, action=${action}`
  );

/**
 * The `MediaPlayer` is a component for rendering animations
 *
 * ### Properties
 | Name          | Type                                  | Default   | Description               |
 |---            |---                                    |---        |---                        |
 | `src`         | `string`                              | Required  | MediaPlayer source path   |
 | `height`      | `integer`                             | Required  | MediaPlayer height        |
 | `width`       | `integer`                             | Required  | MediaPlayer width         |
 | `id`          | `string`                              | none      | MediaPlayer element id    |
 */
export class MediaPlayer extends React.Component<MediaPlayerProps, MediaPlayerState> {
  iframeRef = React.createRef<HTMLIFrameElement>(); // ref is needed to manipulate iframe
  state = {
    frameUuid: uuid(),
    current: MCState.INITIAL,
    previous: undefined,
    currFrame: 0,
    maxFrame: Infinity,
  };

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  onLoadIframe = () => {
    const iframe = this.iframeRef.current;
    if (iframe) {
      requestProgress(iframe, this.state.frameUuid);
      this.transition(MCAction.INITIALIZE);
    }
    // we will be able to receive MessageEvent(s) now ...
    if (window.addEventListener) {
      window.addEventListener('message', this.onMessage, false);
    }
  };

  onMessage = (e: MessageEvent) => {
    const { frameUuid, msg, data } = e.data;
    // apply filter for global 'window' events
    if (frameUuid === this.state.frameUuid) {
      switch (msg) {
        // 'tick' message is fired, when a new frame is raised on the iframe
        case 'tick':
          this.onTick();
          break;
        // 'responseProgress' is fired, when result for 'requestProgress' message is available.
        case 'responseProgress':
          const [currFrame, maxFrame] = data;
          this.setState({ ...{ currFrame, maxFrame } });
          if (currFrame === maxFrame) {
            this.transition(MCAction.FINISH);
          }
          break;
        default:
      }
    }
  };

  onClickMediaControl = () => this.transition(MCAction.CLICK);

  // Callback for progress on the 'frame ticker'
  onTick = () => {
    const iframe = this.iframeRef.current;
    if (iframe) requestProgress(iframe, this.state.frameUuid);
  };

  // Callback for 'selecting' a frame using our seekbar
  onSelectFrame = (frame: number) => {
    if (this.iframeRef.current) {
      this.setState({ currFrame: frame });
      this.transition(MCAction.SELECT);
    }
  };

  // Callback for 'having a frame selected and 'confirmed' (released touch or mouse))
  onFrameSelected = (frame: number) => {
    // sometimes, when clicking very fast, the 'SELECTING' state (before)
    // has not detected the correct frame to set. This is done now.
    if (frame !== this.state.currFrame) {
      this.setState({ currFrame: frame });
    }
    return frame === this.state.maxFrame
      ? this.transition(MCAction.FINISH)
      : this.transition(MCAction.SELECT_FINISHED);
  };

  // The transition function for our media control state machine
  transition = (action: MCAction): void => {
    this.setState((state: Readonly<MediaPlayerState>) => {
      const iframe = this.iframeRef.current;
      const next = MCStateMachine(action, state.current, state.previous);

      if (iframe && next) {
        // apply action according to the next state!
        switch (next) {
          case MCState.READY:
            // initially register frame state here!
            initialize(iframe, this.state.frameUuid);
            set(iframe, this.state.frameUuid);
            break;
          case MCState.PLAYING:
            play(iframe, this.state.frameUuid);
            break;
          case MCState.PAUSED_OR_FINISHED:
            pause(iframe, this.state.frameUuid);
            break;
          case MCState.SELECTING:
            set(iframe, this.state.frameUuid, state.currFrame);
            break;
          default:
        }
        // previous state is only set to current, if next and current are different!!!!
        return {
          current: next,
          previous: state.current !== next ? state.current : state.previous,
        };
      }
      return null;
    });
  };

  render() {
    const { width, height, scale, src } = this.props;
    const { currFrame, maxFrame, current, previous } = this.state;
    const [start, end] = [currFrame === 0, currFrame === maxFrame];
    const scaledWidth = width * scale;
    const scaledHeight = height * scale;
    const relevant = current !== MCState.SELECTING ? current : previous;
    const Icons = (
      relevant === MCState.PAUSED_OR_FINISHED && end
        ? [ReplayCircle, ReplayCircleInv]
        : relevant === MCState.PLAYING
        ? [PauseCircle, PauseCircleInv]
        : [PlayCircle, PlayCircleInv]
    ) as [any, any];

    if (isEmpty(src)) {
      return <BettyOops {...{ width, height }} />;
    }
    return (
      <div className={styles.mediaPlayer} style={{ width: width * scale }}>
        <div className={styles.videoContainer} style={{ width: scaledWidth, height: scaledHeight }}>
          {/*eslint-disable-next-line react/iframe-missing-sandbox*/}
          <iframe
            ref={this.iframeRef}
            className={styles.video}
            style={{ ...{ width, height }, transform: `scale(${scale})` }}
            onLoad={this.onLoadIframe}
            sandbox="allow-same-origin allow-scripts"
            {...this.props}
          />
        </div>
        <div className={styles.toolbar}>
          <MediaButton Icons={Icons} onClick={this.onClickMediaControl} size={SMALL_BUTTON_SIZE} />
          <Seekbar
            {...{ currFrame, maxFrame }}
            width={scaledWidth - SMALL_BUTTON_SIZE - 16}
            onSelectFrame={this.onSelectFrame}
            onFrameSelected={this.onFrameSelected}
          />
        </div>
        {(current === MCState.READY || (current === MCState.PAUSED_OR_FINISHED && end)) && (
          <div
            className={styles.bigButtonOverlay}
            style={{
              display: start || end ? 'block' : 'none',
              left: (scaledWidth - LARGE_BUTTON_SIZE - 2 * LARGE_BUTTON_OVERLAY_BORDER) * 0.5,
              top: (scaledHeight - LARGE_BUTTON_SIZE - 2 * LARGE_BUTTON_OVERLAY_BORDER) * 0.5,
            }}
          >
            <MediaButton
              Icons={Icons}
              onClick={this.onClickMediaControl}
              size={LARGE_BUTTON_SIZE}
            />
          </div>
        )}
      </div>
    );
  }
}

export default MediaPlayer;
