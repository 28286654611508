import { type Action, createAction, handleActions } from 'redux-actions';
import { NLP_TEXT_DEFAULT_CONTENT, type NLPTextInputContent } from '@bettermarks/gizmo-types';

export const CHANGE_TEXT = 'CHANGE_TEXT';
export const changeText = createAction<string>(CHANGE_TEXT);

export const nlpTextReducer = handleActions<NLPTextInputContent, string>(
  {
    [CHANGE_TEXT]: (
      state: NLPTextInputContent,
      { payload }: Action<string>
    ): NLPTextInputContent => ({
      ...state,
      content: payload,
      validity: undefined,
    }),
  },
  NLP_TEXT_DEFAULT_CONTENT
);
