import { SPECIAL } from '../../gizmo-utils/constants';
import { PDF_LINK } from '../../gizmo-utils/configuration/renderStyles';
import { type Content, type Defaults } from '../../xml-converter/core';

/**
 * source: pdf link path
 * text: pdf link text
 */
export interface PdfLinkContent extends Content {
  source: string;
  text: string;
}

export const PDF_LINK_DEFAULT_CONTENT: Defaults<PdfLinkContent> = {
  $: SPECIAL,
  $renderStyle: PDF_LINK,
  source: '',
  text: '',
};
