import * as React from 'react';
import classNames from 'classnames';

import styles from './Canvas.scss';
import { Action, type Slice } from './types';
import { getOnTopZIndex, updateZIndex } from '../../utils/z-index/update-z-index';
import { colors } from '@seriesplayer/common-ui';
import { isNil } from 'lodash';
import { fillGaps } from './fillGaps';
import { DialogType } from '../../types/dialog';
import { useWhiteboardContext } from './whiteboard-context';
import { drawOrErase, replayActionStack, zeroIfUndefined } from './Canvas.utils';
import DrawingTip from './toolbar/DrawingTip';

export type CanvasProps = {
  height: number;
  width: number;
  contentAreaScrollTop: number;
};

export type CanvasRef = {
  onParentScroll: (newScrollPosition: number) => void;
  onZoom: (newScrollPosition: number) => void;
};

export const Canvas = React.forwardRef<CanvasRef, CanvasProps>(
  ({ height, width, contentAreaScrollTop }, ref) => {
    const {
      state: {
        selectedTool,
        activeDialog,
        toolConfig,
        contentToolConfig,
        scaleConfig,
        canvasActionStack: actionStack,
      },
      dispatch,
    } = useWhiteboardContext();

    const [drawingTipState, setDrawingTipState] = React.useState({
      position: { x: 0, y: 0 },
      visible: false,
    });

    const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
    const [rect, setRect] = React.useState(canvasRef.current?.getBoundingClientRect());
    const updateCanvasMeasures = () => setRect(canvasRef.current?.getBoundingClientRect());
    const appendToActionStack = (actionSlice: Slice) =>
      dispatch({
        type: 'set',
        payload: { canvasActionStack: [...actionStack, actionSlice] },
      });

    const offsetTop = zeroIfUndefined(rect?.top);
    const offsetLeft = zeroIfUndefined(rect?.left);

    const contextRef = React.useRef(canvasRef.current?.getContext('2d'));
    const [active, setActive] = React.useState(false);
    const [previousPosition, setPreviousPos] = React.useState<{ x: number; y: number } | undefined>(
      undefined
    );
    const [resized, setResized] = React.useState(false);

    React.useEffect(() => {
      updateCanvasMeasures();
    }, [selectedTool]);

    const [scrollPosition, setScrollPosition] = React.useState(0);

    const onParentScrollAndZoom = (newScrollPosition: number) => {
      const scrollDiff = newScrollPosition - scrollPosition;
      updateCanvasMeasures();

      // Update the drawing tip's position
      setDrawingTipState((prev) => ({
        ...prev,
        position: { x: prev.position.x, y: prev.position.y + scrollDiff }, // Adjust based on your scrolling direction
      }));

      // Update the scroll position
      setScrollPosition(newScrollPosition);
    };

    React.useImperativeHandle(ref, () => ({
      onParentScroll: onParentScrollAndZoom,
      onZoom: onParentScrollAndZoom,
    }));

    const radius = toolConfig.penThickness;
    const penColor = colors[toolConfig.color];

    const resizeCanvas = () => {
      setResized(true);
    };

    React.useEffect(() => {
      if (actionStack.length === 0) {
        contextRef.current?.clearRect(0, 0, width, height);
      }
    }, [actionStack, height, width]);

    // update canvas dimensions on resize
    React.useEffect(() => {
      window.addEventListener('resize', resizeCanvas);
      return () => window.removeEventListener('resize', resizeCanvas);
    }, []);

    // update canvas dimensions, if underlying content height changes
    React.useEffect(() => {
      resizeCanvas();
    }, [height]);

    // replay drawing/erasing after resize happened
    React.useEffect(() => {
      contextRef.current = canvasRef.current?.getContext('2d');
      if (!isNil(contextRef.current)) {
        contextRef.current.fillStyle = penColor;
        contextRef.current.lineWidth = 2 * radius;
      }
      contextRef.current?.clearRect(0, 0, width, height);
      replayActionStack(
        contextRef.current,
        actionStack,
        {
          canvasWidth: width,
          canvasHeight: height,
        },
        contentAreaScrollTop
      );
      setResized(false);
    }, [resized, contentAreaScrollTop, actionStack, height, width, penColor, radius]);

    const drawOrEraseAt = drawOrErase(
      contextRef.current,
      { toolConfig, contentToolConfig, selectedTool },
      { canvasWidth: width, canvasHeight: height },
      contentAreaScrollTop,
      appendToActionStack
    );

    const onStart = (_x: number, _y: number) => {
      const [x, y] = [_x / scaleConfig.currentScale, _y / scaleConfig.currentScale];

      setActive(true);

      if (!isNil(contextRef.current)) {
        contextRef.current.fillStyle = penColor;
        contextRef.current.lineWidth = 2 * radius;
      }
      drawOrEraseAt(x, y);
      setPreviousPos({ x, y });
    };

    const onMouseEnter = (_x: number, _y: number) => {
      const [x, y] = [_x / scaleConfig.currentScale, _y / scaleConfig.currentScale];
      setDrawingTipState({ position: { x: x, y: y }, visible: true });
    };

    const onMouseLeave = (_x: number, _y: number) => {
      const [x, y] = [_x / scaleConfig.currentScale, _y / scaleConfig.currentScale];
      setDrawingTipState({ position: { x: x, y: y }, visible: false });
    };

    const onCursorMove = (_x: number, _y: number) => {
      const [x, y] = [_x / scaleConfig.currentScale, _y / scaleConfig.currentScale];
      setDrawingTipState({ position: { x: x, y: y }, visible: true });
    };

    const onMove = (_x: number, _y: number) => {
      const [x, y] = [_x / scaleConfig.currentScale, _y / scaleConfig.currentScale];
      // fill gaps between two mouse positions, if mouse is moved fast
      fillGaps(
        contextRef.current,
        previousPosition,
        { x, y },
        {
          toolConfig,
          contentToolConfig,
          selectedTool,
        }
      );
      // draw or erase for current position
      drawOrEraseAt(x, y);
      setPreviousPos({ x, y });
      setDrawingTipState({ position: { x: x, y: y }, visible: true });
    };

    const onStop = () => {
      // we do this to know when drawing/erasing was finished, e.g.
      //  draw1, draw1, draw1, (pause), draw2, draw2, ...
      // should be interpreted as 2 individual drawing interactions to avoid filling gaps between
      // draw1 and draw2 on replay after resize
      appendToActionStack({
        action: Action.stop,
        position: { x: 0, y: 0 },
        dimensions: { canvasWidth: width, canvasHeight: height },
        toolInfo: { selectedTool, contentToolConfig, toolConfig },
      });
      setActive(false);
      setPreviousPos(undefined);
      setDrawingTipState((prev) => ({ ...prev, visible: false }));
    };

    const canvasZIndex = getOnTopZIndex();
    const penOrEraserSelected: boolean = selectedTool === 'pen' || selectedTool === 'eraser';

    return (
      <>
        <canvas
          data-cy={'whiteboard-canvas'}
          ref={canvasRef}
          style={{
            zIndex: canvasZIndex,
            cursor: `${drawingTipState.visible ? 'none' : undefined}`,
          }}
          width={width}
          height={height}
          className={classNames({
            [styles.canvas]: true,
            [styles.inactive]:
              selectedTool === null ||
              selectedTool === 'cursor' ||
              selectedTool === 'reset' ||
              selectedTool === 'content-tools',
            [styles.resetConfirmation]:
              activeDialog?.type === DialogType.resetWhiteboardConfirmation,
          })}
          onTouchStart={(evt) => {
            updateZIndex(canvasRef);
            onStart(evt.touches[0].clientX - offsetLeft, evt.touches[0].clientY - offsetTop);
          }}
          onMouseDown={(evt) => onStart(evt.clientX - offsetLeft, evt.clientY - offsetTop)}
          onTouchMove={
            active
              ? (evt) =>
                  onMove(evt.touches[0].clientX - offsetLeft, evt.touches[0].clientY - offsetTop)
              : undefined
          }
          onMouseMove={(evt) => {
            if (active) {
              onMove(evt.clientX - offsetLeft, evt.clientY - offsetTop);
            } else if (penOrEraserSelected) {
              onCursorMove(evt.clientX - offsetLeft, evt.clientY - offsetTop);
            }
          }}
          onTouchEnd={onStop}
          onMouseUp={onStop}
          onMouseOut={active ? onStop : undefined}
          onMouseEnter={
            penOrEraserSelected
              ? (evt) => onMouseEnter(evt.clientX - offsetLeft, evt.clientY - offsetTop)
              : undefined
          }
          onMouseLeave={
            penOrEraserSelected
              ? (evt) => onMouseLeave(evt.clientX - offsetLeft, evt.clientY - offsetTop)
              : undefined
          }
        />
        <DrawingTip
          mousePos={drawingTipState.position}
          visible={drawingTipState.visible}
          zIndex={+canvasZIndex + 1}
          color={selectedTool === 'pen' ? toolConfig.color : 'cGray800'}
          size={selectedTool === 'pen' ? toolConfig.penThickness : toolConfig.eraserThickness}
        />
      </>
    );
  }
);
