import * as React from 'react';
import { type ValueStepperReferenceContent } from '@bettermarks/gizmo-types';
import { type ContextState, useValueSetterContext } from '../../gizmo-utils/polymorphic-gizmo';
import { ValueStepper } from '../../components/ValueStepper';

export interface IValueStepperGizmoProps extends ValueStepperReferenceContent, ContextState {}

export const ValueStepperGizmo: React.FC<IValueStepperGizmoProps> = ({
  $id,
  binding = '',
}: IValueStepperGizmoProps) => {
  const id = $id ?? '';

  const { valueSetterMap, valueStepperConfigMap } = useValueSetterContext();
  if (!valueSetterMap[binding]) {
    throw new Error('The valueSetterMap did not provide an entry for the stepper ' + binding);
  }

  if (!valueStepperConfigMap[binding]) {
    throw new Error(
      'The valueStepperConfigMap did not provide configuration props for the stepper ' + binding
    );
  }

  const step = valueStepperConfigMap[binding].step;
  const decoration = valueStepperConfigMap[binding].decoration;
  const position = valueStepperConfigMap[binding].position;
  const initiallyActive = valueStepperConfigMap[binding].initiallyActive;

  const onChange = (value: number) => {
    valueStepperConfigMap[binding].onChange(value);
  };

  return (
    <ValueStepper
      id={id}
      value={valueSetterMap[binding].value}
      stepperPosition={position}
      onChange={onChange}
      min={valueStepperConfigMap[binding].min}
      max={valueStepperConfigMap[binding].max}
      step={step}
      decoration={decoration}
      initiallyActive={initiallyActive}
    ></ValueStepper>
  );
};

ValueStepperGizmo.displayName = 'ValueStepperGizmo';
