import * as React from 'react';
import { Key } from '../Keyboard/Key';
import { KEY_ICONS_CALCULATOR, type KeyCode } from '../Keyboard/keyCodes';
import { CalculatorLayout, type CalculatorProps } from './types';

import styles from './CalculatorKeyboard.scss';
import { OPERATOR_KEYS_FIXED, OPERATOR_KEYS_STRETCHED } from './CalculatorLayouts';

export type ButtonConfig = {
  kc: KeyCode;
  isRed?: boolean;
  colspan?: number;
  rowspan?: number;
};

export const CalculatorOperatorsBlock: React.FC<CalculatorProps> = ({ onKey, layout }) => (
  <div
    className={
      layout === CalculatorLayout.VERTICAL
        ? styles.operatorsBlockFixed
        : styles.operatorsBlockStretched
    }
  >
    {(layout === CalculatorLayout.VERTICAL ? OPERATOR_KEYS_FIXED : OPERATOR_KEYS_STRETCHED).map(
      (button: ButtonConfig) => (
        <Key
          key={`${button.kc}-calc`}
          keyCode={button.kc}
          iconProps={{
            onKey,
            type: button.isRed ? 'red' : 'very-dark',
            colspan: button.colspan,
            rowspan: button.rowspan,
            icons: KEY_ICONS_CALCULATOR,
          }}
        />
      )
    )}
  </div>
);

CalculatorOperatorsBlock.displayName = 'CalculatorOperatorsBlock';
