import { flatMap } from 'lodash';
import { call, delay, put, select } from 'redux-saga/effects';
import { openDialog, showResults } from '../SeriesPlayer/actions';
import {
  AppExercise,
  ApplicationState,
  AppStep,
  DialogType,
  ErrorHandleKind,
  ExerciseStatus,
  ExerciseValidity,
  type IApplicationState,
  type Series,
  SeriesMode,
  type SeriesScore,
  SeriesStatus,
  StepStatus,
  type ValidatorUrlMap,
} from '../../../../types';
import { hideStepValidationLoader, validationProgress } from '../Exercise/actions';
import { setExerciseCompletedAndValidities, setSeriesScore, setSeriesStatus } from './actions';
import { getExerciseTimeUsed, getStepTimeUsed } from './seriesSaga';
import {
  getSeriesResult as getSeriesResultUnstubable,
  postSeriesResults as postSeriesResultsUnstubable,
  reportTestHandedIn as reportTestHandedInUnstauble,
  saveStepResult,
} from '../../services/api/result-manager';
import { getStepResult } from '../../reducers/reporting';
import {
  getValidatorResponse,
  questionAndFeedbackFromValidationPayload,
} from '../../validationHelpers';
import { Validity } from '@bettermarks/umc-kotlin';
import { handleError } from '../../sagaHelpers';
import { type TestValidationPayload } from '../../../store/types';
import { withEmptyHistory } from '../../../store/helper';
import { ErrorReason, isTestHasBeenCollectedException } from '../../services/exception';
import { logMissingFields } from './helpers';

export const STUBABLE = {
  getSeriesResult: getSeriesResultUnstubable,
  reportTestHandedIn: reportTestHandedInUnstauble,
  postSeriesResults: postSeriesResultsUnstubable,
};

/**
 * This saga is used to hand in a test. It validates all unvalidated steps and
 * sends the results to the backend, when reporting is enabled (i.e. only for students using the
 * seriesplayer from within NSP).
 */
export function* handInSaga(useDelay = true) {
  const state = (yield select()) as IApplicationState;

  if (!state.runtimeState.isOnline) {
    yield put(
      openDialog({
        type: DialogType.error,
        payload: {
          kind: ErrorHandleKind.confirm,
          description: 'seriesplayer:dialog.retry.title',
        },
      })
    );

    return;
  }

  yield put(
    openDialog({
      type: DialogType.dataLossPrevention,
    })
  );

  const {
    appSettings: { resultManagerUrl },
    series,
  }: ApplicationState = state;

  const exercise: AppExercise = ApplicationState.toCurrentExercise.get(state);
  const step = ApplicationState.toCurrentStep.get(state);
  const stepIndex = AppExercise.getCurrentStepIndex(exercise.steps, step?.id);

  if (series.seriesSettings.reporting) {
    yield call(STUBABLE.reportTestHandedIn, resultManagerUrl, series.userId || '', series.id);
  }

  // report current step to have it in the DB for the re-hand in, in case hand in fails
  try {
    if (step && series.seriesSettings.reporting) {
      yield call(
        saveStepResult,
        resultManagerUrl,
        getStepResult(
          series,
          series.currentExerciseIndex,
          stepIndex,
          getStepTimeUsed(step),
          getExerciseTimeUsed(exercise)
        ),
        true
      );
    }
  } catch (err) {
    if (isTestHasBeenCollectedException(err)) {
      yield put(
        openDialog({
          type: DialogType.error,
          payload: {
            kind: ErrorHandleKind.quit,
            reasonCode: ErrorReason.TEST_HAS_BEEN_COLLECTED,
          },
        })
      );
      return;
    }
  }

  console.log({
    validationProgressRet: validationProgress({
      handIn: true,
      stepValidationLoaded: true,
    }),
    validationProgress,
  });
  // Change the state to indicate that we will start validation
  yield put(
    validationProgress({
      handIn: true,
      stepValidationLoaded: true,
    })
  );

  if (useDelay) {
    // Needs to wait a render cycle to disable submit button... Seems to be hacky
    yield delay(10);
  }

  yield put(setSeriesStatus(SeriesStatus.completed));

  const validatedSeries: Series = yield call(getValidatedSeries);

  if (series.seriesSettings.reporting) {
    yield reportSubmissions(validatedSeries, resultManagerUrl);
  }

  if (series.seriesSettings.showResults) {
    yield put(
      setExerciseCompletedAndValidities(
        validatedSeries.exercises.map((e) => ({
          validity: e.validity,
          steps: e.steps.map((s) => s.validity),
        }))
      )
    );
  }

  yield put(showResults({ animate: true }));
}

function* getValidatedSeries(): Generator {
  const state: ApplicationState = (yield select()) as ApplicationState;

  const {
    validation,
    appSettings: { validatorUrls },
  } = state;

  const series = ApplicationState.toSeries.get(state);
  let resultSeries = { ...series };

  // The for-loop makes sure that the validations are run in a sequence.
  // Otherwise, it blows up the memory when preparing all the validation promises
  // in advance (BM-50501).
  // @TODO: add a test showing that the validations happen in a sequence
  for (let exerciseIndex = 0; exerciseIndex < series.exercises.length; exerciseIndex++) {
    const exercise = series.exercises[exerciseIndex];
    for (let stepIndex = 0; stepIndex < exercise.steps.length; stepIndex++) {
      const step = exercise.steps[stepIndex];
      if (step.status !== StepStatus.completed) {
        try {
          resultSeries = (yield call(
            validateTestHandInStep,
            resultSeries,
            exercise,
            exerciseIndex,
            step,
            stepIndex,
            validation,
            validatorUrls
          )) as Series;
        } catch (err) {
          yield call(handleError, err);
        } finally {
          yield put(hideStepValidationLoader());
        }
      }
    }
  }

  return (yield resultSeries) as any;
}

export async function validateTestHandInStep(
  series: Series,
  exercise: AppExercise,
  exerciseIndex: number,
  step: AppStep,
  stepIndex: number,
  validation: Nullable<string>,
  validatorUrls: ValidatorUrlMap
): Promise<Series> {
  const stepValidatorResponse = await getValidatorResponse(
    {
      exercise,
      exerciseIndex,
      step,
      stepIndex,
      question: AppStep.toCurrentQuestion.get(step),
    },
    SeriesMode.test,
    validation,
    validatorUrls,
    false
  );

  return handleTestValidationSuccess(series, {
    ...stepValidatorResponse,
    exerciseIndex,
    stepIndex,
  });
}

export function handleTestValidationSuccess(
  series: Series,
  testValidationPayload: TestValidationPayload
): Series {
  const { exercises } = series;
  const { exerciseIndex, stepIndex, numberOfErrors, validity, skippedStepIds } =
    testValidationPayload;
  const numSkippedSteps = skippedStepIds?.length || 0;

  const { question, feedbacks } = questionAndFeedbackFromValidationPayload(
    {
      ...testValidationPayload,
      stepStatus: StepStatus.completed,
    },
    `steps[${stepIndex}]`,
    exercises[exerciseIndex].steps[stepIndex]
  );

  const currentStepStatus = series.exercises[exerciseIndex].steps[stepIndex].status;

  // only modify step, if it was not yet completed by skipping of a previous step
  return currentStepStatus === StepStatus.completed
    ? series
    : {
        ...series,
        exercises: exercises.map((exercise, index) => {
          return {
            ...exercise,
            ...(index === exerciseIndex && {
              status: ExerciseStatus.completed,

              validity:
                validity === Validity.correct && exercise.validity !== ExerciseValidity.wrong
                  ? ExerciseValidity.correct
                  : ExerciseValidity.wrong,
              steps: exercise.steps.map((step, stepIdx) => ({
                ...step,
                ...((stepIdx === stepIndex ||
                  // in case of skipped steps,
                  // modify all skipped steps to prevent them receiving validity "wrong" for being empty
                  (numSkippedSteps > 0 &&
                    stepIndex <= stepIdx &&
                    stepIdx <= stepIndex + numSkippedSteps)) && {
                  feedbacks,
                  question: withEmptyHistory(question),
                  status: StepStatus.completed,
                  numberOfErrors,
                  validity,
                }),
              })),
            }),
          };
        }),
      };
}

export function* reportSubmissions(series: Series, resultManagerUrl: string) {
  const stepSubmissions = flatMap(series.exercises, (exercise, exerciseIndex) =>
    exercise.steps.map((step, stepIndex) =>
      getStepResult(
        series,
        exerciseIndex,
        stepIndex,
        getStepTimeUsed(step),
        getExerciseTimeUsed(exercise)
      )
    )
  );

  // only call BE reporting route, if there is validated steps to report
  if (stepSubmissions.length > 0) {
    logMissingFields(stepSubmissions, 'hand-in');

    yield call(
      STUBABLE.postSeriesResults,
      resultManagerUrl,
      stepSubmissions,
      series.userId || '',
      series.id
    );
  }

  const seriesScore: SeriesScore = yield call(STUBABLE.getSeriesResult, resultManagerUrl, {
    seriesId: series.id,
  });

  yield put(setSeriesScore(seriesScore));
}
