import * as React from 'react';
import { type KeyHandler, Key } from './Key';
import { KeyCode } from './keyCodes';
import { compact } from 'lodash';

export interface NumBlockProps {
  slim?: boolean; // do we only have two rows
  onSinglePage?: boolean;
  onKey?: KeyHandler;
  hasDecimalPoint?: boolean;
}

/**
 * NumBlock: NumBlock
 */
export const NumBlock: React.FC<NumBlockProps> = ({
  hasDecimalPoint,
  slim,
  onSinglePage,
  onKey,
}) => (
  <>
    {slim
      ? [
          ...'162738495'
            .split('')
            .concat(compact([hasDecimalPoint && KeyCode.DecimalPoint]))
            .map((k) => <Key key={k} keyCode={k as KeyCode} iconProps={{ onKey }} />),
          /**
           * Handling "0" key differently since it depends on the existence of the decimal point key
           */
          <Key
            key={KeyCode.Num0}
            keyCode={KeyCode.Num0}
            iconProps={{ onKey, rowspan: hasDecimalPoint ? 2 : 1 }}
          />,
        ]
      : [
          ...'147258369'
            .split('')
            .map((k) => <Key key={k} keyCode={k as KeyCode} iconProps={{ onKey }} />),
          /**
           * Handling "0" key differently since it depends on the existence of the decimal point key
           * and the layout size
           */
          [
            <Key
              key={KeyCode.Num0}
              keyCode={KeyCode.Num0}
              iconProps={{ onKey, rowspan: (onSinglePage ? 1 : 2) + (hasDecimalPoint ? 0 : 1) }}
            />,
            hasDecimalPoint && (
              <Key
                key={KeyCode.DecimalPoint}
                keyCode={KeyCode.DecimalPoint}
                iconProps={{ onKey }}
              />
            ),
          ],
        ]}
  </>
);

NumBlock.displayName = 'NumBlock';
