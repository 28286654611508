import { type Content, type ContentReference } from '../../xml-converter/core';
import { UNKNOWN } from '../../gizmo-utils/configuration/renderStyles';

export interface UnknownContent extends Content {
  message: string;
  content: ContentReference[];
}

export const isUnknownContent = (content: Content | undefined): content is UnknownContent =>
  !!content && content.$renderStyle === UNKNOWN;
