import * as React from 'react';
import { type SetProps, type WithBorderRect } from './types';
import { Angle, Circle, Interval, Line, Point } from '..';
import {
  DEFAULT_PREVCIRCLE_DECORATION,
  DEFAULT_PREVINTERVAL_DECORATION,
  DEFAULT_PREVPOINT_DECORATION,
  inViewbox,
} from '@bettermarks/importers';
import {
  type AdvancedAxisObject,
  type AxisObject,
  type CircleDecoration,
  type Coords,
  type GeoConfiguration,
  type GeoPreviewObjects,
  type PreviewAngle,
  type PreviewCircle,
  type PreviewInterval,
  type PreviewLine,
  type PreviewPoint,
} from '@bettermarks/gizmo-types';

export type PreviewSetProps = SetProps &
  WithBorderRect &
  GeoPreviewObjects & {
    width: number;
    height: number;
    configuration: GeoConfiguration;
    horizontalAxis?: AxisObject | AdvancedAxisObject;
  };

export const PreviewSet: React.FC<PreviewSetProps> = ({
  lines,
  points,
  circles,
  angles,
  intervals,
  matrix,
  borderRectId,
  configuration,
}) => {
  return (
    <g>
      {lines &&
        lines
          .filter((l) => l.visible)
          .map((prevLine: PreviewLine, idx) => (
            <Line
              key={`prevLine_${idx}`}
              id={`prevLine_${idx}`}
              {...{ matrix, borderRectId }}
              x1={(prevLine.p1 as Coords).x}
              y1={(prevLine.p1 as Coords).y}
              x2={(prevLine.p2 as Coords).x}
              y2={(prevLine.p2 as Coords).y}
              decoration={prevLine.decoration}
              notLabelable={true}
            />
          ))}
      {angles &&
        angles
          .filter((a) => a.visible)
          .map((prevAngle: PreviewAngle, idx) => (
            <Angle
              key={`prevAngle_${idx}`}
              id={`prevAngle_${idx}`}
              {...{ matrix, borderRectId, configuration }}
              {...prevAngle}
            />
          ))}
      {points &&
        points
          .filter((p) => inViewbox(p.coords, configuration.display))
          .map((prevPoint: PreviewPoint, idx) => (
            <Point
              key={`prevPoint_${idx}`}
              id={`prevPoint_${idx}`}
              {...{ matrix }}
              {...prevPoint.coords}
              decoration={DEFAULT_PREVPOINT_DECORATION}
            />
          ))}

      {intervals &&
        intervals
          .filter((i) => i.visible)
          .map((prevInterval: PreviewInterval, idx) => (
            <Interval
              key={`prevInterval_${idx}`}
              id={`prevInterval_${idx}`}
              {...{ matrix, borderRectId }}
              {...prevInterval}
              interactionType={''}
              decoration={DEFAULT_PREVINTERVAL_DECORATION}
            />
          ))}

      {circles &&
        circles.map((prevCircle: PreviewCircle, idx) => {
          const { coords, decoration: circleDecoration, radius } = prevCircle;
          if (coords) {
            const decoration: CircleDecoration = {
              ...DEFAULT_PREVCIRCLE_DECORATION,
              ...circleDecoration,
            };
            return (
              <Circle
                key={`prevCircle_${idx}`}
                id={`prevCircle_${idx}`}
                {...{ borderRectId, decoration, matrix, radius, coords }}
              />
            );
          }
        })}
    </g>
  );
};

PreviewSet.displayName = 'PreviewSet';
