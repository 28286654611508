import { isNil } from 'lodash';
import { type RefObject } from 'react';

const updateElementZIndex = (element: HTMLElement) => {
  const onTopOfTheWorld = getOnTopZIndex();
  element.style.zIndex = onTopOfTheWorld;
};

export const getOnTopZIndex = () => Math.round(Date.now() / 1000).toString();

export const updateZIndex = (reactRef: RefObject<HTMLElement>) => {
  if (reactRef.current !== null) {
    updateElementZIndex(reactRef.current);
  }
};

export const updateFirstChildZIndex = (reactRef: RefObject<HTMLElement>) => {
  if (reactRef.current !== null) {
    const son = reactRef.current.firstElementChild;
    if (!isNil(son) && son instanceof HTMLElement) {
      updateElementZIndex(son);
      son.style.position = 'relative';
    }
  }
};
