import * as React from 'react';
import { isEmpty } from 'lodash';
import {
  type CommonCallbacks,
  type ComputedStyles,
  DEFAULT_MATH_CONTENT,
  DYNAMIC_DECORATION,
  forEachMathChild,
  type FormulaContent,
  type InputDecoration,
  type Interactable,
  isMN,
  isMText,
  isMToken,
  isOuterInteractive,
  isTableCell,
  type MathContent as MathContentType,
  type MRow,
  type PathHandler,
  PathMode,
  RS,
} from '@bettermarks/gizmo-types';
import { INPUT_FOCUS } from '../../../gizmo-utils/constants';
import { type ContextState, type ValueSetterMap } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  evaluateValueSetterExpression,
  evaluateValueSetterExpressionToString,
  type Severity,
} from '@bettermarks/umc-kotlin';
import { hasCursor, MROW, parseDecoration } from '@bettermarks/importers';
import { FormulaInput, MathML, Placeholder, type SelectHandler, Side } from './components';
import { MathContent } from './MathContent';
import { ShadowScrollbars } from '../../components';
import log from 'loglevel';
import { valueSetterMapToValidatorValueMap } from './helper';
import { enrichSingleFormula } from '../measure';
import { useMaybeExampleModeContext } from '../../../apps/whiteboard/FEMDispatchContext';
import { useLocation } from 'react-router-dom';

export type FormulaCallbacks = CommonCallbacks & {
  onSelect?: PathHandler;
  onBlur?: React.FocusEventHandler;
  onClickFEM?: (id: string, search: string) => void;
};

export type CalculatorFormula = {
  inCalculator?: boolean;
};

export type FormulaProps = FormulaCallbacks & FormulaContent & ContextState & CalculatorFormula;

const loadDynamicValue =
  (valueSetterMap: ValueSetterMap, setHasChildWithDynamicDecoration: (value: boolean) => void) =>
  (content: MathContentType) => {
    if (isMN(content) && content.hasOwnProperty('valueSetterRefId')) {
      content.text =
        (content.valueSetterRefId && valueSetterMap[content.valueSetterRefId].value.toString()) ||
        content.text;
    }
    if (isMToken(content) && content.dynamic) {
      const evaluationPayload = {
        expression: content.dynamic,
        valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
      };
      if (isMN(content)) {
        content.text = evaluateValueSetterExpression(evaluationPayload).toString();
      } else if (isMText(content)) {
        content.text = evaluateValueSetterExpressionToString(evaluationPayload);
      }
    }
    if (DYNAMIC_DECORATION in content && content.dynamicDecoration) {
      setHasChildWithDynamicDecoration(true);
      const decorationString = evaluateValueSetterExpressionToString({
        expression: content.dynamicDecoration,
        valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
      });
      content.decoration = parseDecoration(decorationString).decoration;
    }
    forEachMathChild(content, loadDynamicValue(valueSetterMap, setHasChildWithDynamicDecoration));
  };

const contentWithLoadedDynamicValues = (
  content: MathContentType[],
  valueSetterMap: ValueSetterMap,
  setHasChildWithDynamicDecoration: (value: boolean) => void
): MathContentType[] => {
  const withLoadedDynamicValues = [...content];
  withLoadedDynamicValues.forEach((el) => {
    loadDynamicValue(valueSetterMap, setHasChildWithDynamicDecoration)(el);
  });
  return withLoadedDynamicValues;
};

/**
 * Check if FormulaInput has cursor.
 *  - If current severity is default, then set decoration focus
 *  - If current severity is error/remark, don't set anything.
 * @param {Severity} severity
 * @param {MathContent[]} content
 * @return {InputDecoration}
 */
const getInputDecoration = (content: MathContentType[], severity?: Severity): InputDecoration => {
  // If formula has severity default, then check cursor else return severity decoration.
  // If formula has cursor, then apply the INPUT_FOCUS decoration else keep severity decoration.
  return severity ? severity : hasCursor(content) ? INPUT_FOCUS : undefined;
};

/**
 * Configures a "`SelectHandler`".
 * It will be called with `callback` to later be invoked
 * by passing `side` depending on click position.
 *
 * It only handels clicks in FormulaInput **around** actual MathML/MathContent,
 * so the only question here is "should the cursor go first or last?".
 * The reducer logic that is triggered by `callback` expects a "clickIndex"
 * to put the cursor before or after it.
 * Since we would need to know more about the current formula to implement "last",
 * we use -1 to indicate a "dynamic last index" (like in slice/splice/etc.).
 *
 * @see FormulaReducer::insertCursorIntoMRowChildren
 *
 * @param _ not used in this case but is seems to be required by types
 */
const onSelectWrapper: (_?: PathHandler) => SelectHandler | undefined = (callback) => {
  if (!callback) return undefined;
  return (side: Side) => {
    side === Side.Front
      ? callback(['children', 0], PathMode.Insert)
      : callback(['children', -1], PathMode.Append);
  };
};

export const disableCallBacks = (
  fromProps: FormulaCallbacks,
  condition: boolean
): FormulaCallbacks =>
  condition
    ? fromProps
    : {
        /*all are undefined*/
      };

/**
 * Send logging information when Formula doesn't have `computedStyles` in its props,
 * so we can debug and investigate why this is happening.
 */
function getComputedStylesWithLogging(
  propsComputedStyles: ComputedStyles | undefined,
  children: MathContentType[]
): ComputedStyles {
  let computedStyles = propsComputedStyles;

  if (computedStyles === undefined) {
    log.warn({
      message: 'computedStyles for Formula is undefined. Falling back to default styles.',
      extra: {
        content: JSON.stringify(children),
      },
    });

    // Formula component uses only fontSize from computedStyles.
    // Such fallback should be enough to make exercise solvable (but maybe ugly).
    computedStyles = DEFAULT_MATH_CONTENT.computedStyles;
  }

  return computedStyles;
}

// tslint:disable-next-line cyclomatic-complexity
export function Formula(props: FormulaProps) {
  const { search } = useLocation();
  // This is not implemented with React's useState, because a state change
  // would cause a re-rendering. We would end up in an infinite loop.
  let hasChildWithDynamicDecoration = false;
  const setHasChildWithDynamicDecoration = (value: boolean) => {
    hasChildWithDynamicDecoration = value;
  };

  // Check out FEMs_and_the_Whiteboard_Mode.md
  let onClickFEM = props.onClickFEM;
  const exampleModeContext = useMaybeExampleModeContext();
  if (exampleModeContext) {
    onClickFEM = (femId: string) => exampleModeContext.dispatchFEMOpen(femId);
  }

  const children =
    props.valueSetterMap && !isEmpty(props.valueSetterMap)
      ? contentWithLoadedDynamicValues(
          props.content,
          props.valueSetterMap,
          setHasChildWithDynamicDecoration
        )
      : props.content;

  const computedStyles = getComputedStylesWithLogging(props.computedStyles, children);

  let evaluatedDynamicDecoration = {};
  if (props.dynamicDecoration && props.valueSetterMap) {
    const decorationString = evaluateValueSetterExpressionToString({
      expression: props.dynamicDecoration,
      valueMap: valueSetterMapToValidatorValueMap(props.valueSetterMap),
    });
    evaluatedDynamicDecoration = parseDecoration(decorationString).decoration;
    setHasChildWithDynamicDecoration(true);
  }

  const updatedprops = { ...props, decoration: evaluatedDynamicDecoration };

  const properlyStyledFormula = hasChildWithDynamicDecoration
    ? enrichSingleFormula(updatedprops)
    : updatedprops;

  const interactive = !isEmpty(properlyStyledFormula.$interactionType);

  const mrow: MRow & Interactable = {
    ...MROW(...properlyStyledFormula.content),
    decoration: properlyStyledFormula.decoration,
    computedStyles: properlyStyledFormula.computedStyles,
    childrenAlignments: properlyStyledFormula.childrenAlignments,
    interactive,
  };
  const { onSelect, onFocus, onBlur } = disableCallBacks(props, interactive && !props.disabled);

  const tabIndex = interactive && !props.disabled ? 0 : undefined;
  let renderedContent;

  if (interactive && mrow.children.length === 0 && props.placeholderLabel) {
    renderedContent = (
      <Placeholder
        computedStyles={props.placeholderLabel.computedStyles}
        severity={props.severity}
        onSelect={onSelect && onSelect.bind(null, ['children', 0], PathMode.Insert)}
      >
        <MathContent
          richContent={props.placeholderLabel}
          severity={props.severity}
          availableWidth={props.availableWidth}
        />
      </Placeholder>
    );
  } else {
    renderedContent = (
      <MathContent
        richContent={mrow}
        onSelect={props.onSelect}
        severity={props.severity}
        skipPlaceholder={props.$renderStyle !== RS.INNER_TEXT}
        availableWidth={props.availableWidth}
        hideHelpTools={props.hideHelpTools}
        onClickFEM={(id) => onClickFEM?.(id, search)}
        inCalculator={props.inCalculator}
      />
    );
  }

  const formula = isOuterInteractive(props.$renderStyle, props.$interactionType) ? (
    <FormulaInput
      id={props.$id}
      disabled={props.disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      onSelect={onSelectWrapper(onSelect)}
      decoration={getInputDecoration(mrow.children, props.severity)}
      fontSize={computedStyles.fontSize}
      minWidth={props.minInputWidth}
      tableCell={isTableCell(props.$renderStyle)}
      marginLeft={props.decoration && props.decoration.marginLeft}
      marginRight={props.decoration && props.decoration.marginRight}
      tabIndex={tabIndex}
    >
      {/*
        This instance of MathML should respond to clicks, but not to tab key
        (hence not setting the tabIndex property here)
        since the FormulaInput is responsible for the focus handling
        */}
      <MathML interactive={interactive} fontSize={computedStyles.fontSize}>
        {renderedContent}
      </MathML>
    </FormulaInput>
  ) : (
    /*
    For RenderStyle Inner-Text (the interactive part of FieldSet),
    we don't show FormulaInput since FieldSet is taking care of it.
    so MathML needs to take care of focus handling
    */
    <MathML
      interactive={interactive}
      fontSize={computedStyles.fontSize}
      tableCell={isTableCell(props.$renderStyle)}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={tabIndex}
      inCalculator={props.inCalculator}
    >
      {renderedContent}
    </MathML>
  );

  return props.isRoot ? (
    <ShadowScrollbars availableWidth={props.availableWidth}>{formula}</ShadowScrollbars>
  ) : (
    formula
  );
}

Formula.displayName = 'Formula';
