import React, { useEffect } from 'react';
import { identity } from 'lodash';
import { type TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { LanguageCode } from 'iso-639-1';
import { getLanguageFromLocale } from './getLanguageFromLocale';
import { DEFAULT_LOCALE } from './constants';

export type LocaleProviderProps = {
  contentLocale?: string;
};

export interface LocaleContextProps {
  t: TFunction;
  contentLocale: string;
}

export const ContentLocaleContext = React.createContext<LocaleContextProps>({
  t: identity,
  contentLocale: '',
});

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children, contentLocale }) => {
  const [, i18n] = useTranslation();
  // if i18n is not available, we are falling back to identity.
  // i18n.language return locale with country (en_US), substr to use only 'en' as locale.
  // We are falling back to default locale of i18n.
  const locale = contentLocale ? contentLocale : i18n.language;
  const t = i18n.getFixedT(locale);

  const setLang = (lang: LanguageCode) => {
    document.documentElement.lang = lang;
  };

  useEffect(() => {
    setLang(getLanguageFromLocale(locale) || DEFAULT_LOCALE);
  }, [locale]);

  return (
    // Provider uses value props to pass the data to Consumers
    <ContentLocaleContext.Provider value={{ t, contentLocale: locale }}>
      {children}
    </ContentLocaleContext.Provider>
  );
};

LocaleProvider.displayName = 'LocaleProvider';

export const translateContent =
  <T extends Partial<{ t: TFunction }>>(
    Component: React.ComponentType<T>
  ): React.FC<Omit<T, 't'>> =>
  (props: T) =>
    (
      <ContentLocaleContext.Consumer>
        {({ t }) => <Component {...props} t={t} />}
      </ContentLocaleContext.Consumer>
    );

export const useContentTranslation = () => {
  const { t } = React.useContext<LocaleContextProps>(ContentLocaleContext);
  return t;
};
