import { enrichContentDict } from '../../../gizmo-utils/measure';
import { type FormulaContent, type FormulaStyles } from '@bettermarks/gizmo-types';
import { applyFormulaStyles, formulaEnricher } from '../Formula/measure';

/**
 * Measures and enriches a single formula that has no nested Gizmos,
 * so can be rendered correctly using the Formula GizmoComponent.
 *
 * This only works for rendered, not for interactive content.
 *
 * Useful to render FormulaContent that is generated on the fly (during the render cycle).
 */
export const enrichSingleFormula = (
  formula: FormulaContent,
  formulaStyles?: FormulaStyles
): FormulaContent =>
  enrichContentDict(
    { formula },
    { [formula.$renderStyle]: applyFormulaStyles },
    { [formula.$renderStyle]: formulaEnricher },
    false,
    formulaStyles
  ).formula as FormulaContent;
