import { createHashRouter } from 'react-router-dom';
import * as React from 'react';
import { SeriesPlayerContainer, UploadContainer } from './seriesplayer';
import { SeriesPlayerPdfReview } from './seriesplayer/containers/SeriesPlayer/SeriesPlayerPdfReview';
import { FEMPlayerRoute, KEMPlayerRoute } from './emplayer';
import CRIPlayerRoute from './criplayer/CRIPlayerRoute';
import { CalculatorContainer } from './seriesplayer/containers/Toolbar/Tools/Calculator';
import { ExerciseComparison } from './comparison/ExerciseComparison';
import { EMComparison } from './comparison/EMComparison';
import { CRIComparison } from './comparison/CRIComparison';
import ExampleModeRoute from './example-mode/ExampleModeRoute';
import { GizmoViewerContainer } from './gizmoviewer';
import ScaffoldingGizmoRoute from './scaffolding-gizmo-prototype/ScaffoldingGizmoRoute';

export const router = createHashRouter([
  {
    path: '/',
    element: <UploadContainer />,
  },
  {
    path: '/series',
    element: <SeriesPlayerContainer />,
    errorElement: <p>Error!</p>,
  },
  {
    path: '/start',
    element: <SeriesPlayerContainer />,
    errorElement: <p>Error!</p>,
  },
  {
    path: '/pdf-review',
    element: <SeriesPlayerPdfReview />,
  },
  {
    path: '/kem/:locale/:id',
    element: <KEMPlayerRoute />,
  },
  {
    path: '/fem/:locale/:id',
    element: <FEMPlayerRoute />,
  },
  {
    path: '/classroom-intro/:locale/:id',
    element: <CRIPlayerRoute />,
  },
  {
    path: '/:locale/:contentListId/:token',
    element: <SeriesPlayerContainer />,
  },
  {
    path: '/calculator',
    element: <CalculatorContainer />,
  },
  {
    path: '/exercise-comparison',
    element: <ExerciseComparison />,
  },
  {
    path: '/em-comparison',
    element: <EMComparison />,
  },
  {
    path: '/classroom-intro-comparison',
    element: <CRIComparison />,
  },
  {
    path: '/example',
    element: <ExampleModeRoute />,
  },
  {
    path: '/gizmo/:gizmo',
    element: <GizmoViewerContainer />,
  },
  {
    path: '/scaffolding-gizmo-prototype',
    element: <ScaffoldingGizmoRoute />,
  },
]);
