import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { GraphGizmo, type GraphGizmoProps, type GraphGizmoCallbacks } from './GraphGizmo';
import {
  setNodeSize,
  addNode,
  connectNodes,
  deleteEdge,
  deleteNode,
  labelNode,
  colorNode,
  moveNode,
  needGrab,
} from './graphActions';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): GraphGizmoCallbacks => ({
  onNodeSize: (id, size) =>
    dispatch(gizmoAction(setNodeSize({ id, size }), refid, { skipUndo: true })),
  onAddNode: (position) => dispatch(gizmoAction(addNode(position), refid)),
  onDeleteNode: (node) => dispatch(gizmoAction(deleteNode(node), refid)),
  onDeleteEdge: (edge) => dispatch(gizmoAction(deleteEdge(edge), refid)),
  onMoveNode: (id, position) => dispatch(gizmoAction(moveNode({ id, position }), refid)),
  onLabelNode: (id, index) => dispatch(gizmoAction(labelNode({ id, index }), refid)),
  onColorNode: (id) => dispatch(gizmoAction(colorNode(id), refid)),
  onConnectNode: (sourceId, targetId) =>
    dispatch(gizmoAction(connectNodes({ sourceId, targetId }), refid)),
  onNeedGrab: (flag) => dispatch(gizmoAction(needGrab(flag), refid, { skipUndo: true })),
});

export const GraphGizmoContainer = connectGizmo<GraphGizmoProps>(mapDispatchToProps, GraphGizmo);
