import {
  AppExercise,
  type AppStep,
  CollapsibleContentState,
  ExerciseStatus,
  SeriesMode,
  StepStatus,
} from '../../../../types';
import { completeStep, setFilledStatus, startStep } from './reducerHelpers';
import { identity, last } from 'lodash/fp';
import { Validity } from '@bettermarks/umc-kotlin';
import { interactiveChildren } from '../../../store/helper';
import { isCursor, isFormulaContent, Lens } from '@bettermarks/gizmo-types';
import { isEqual } from 'lodash';

/**
 * For non completed exercises:
 *  - Starts step and handles selection of interactive child (cursor to input)
 *  - Sets start time
 *  - starts current step if not locked
 * Test mode: checks if there is user-input and set the status accordingly
 */
export const updateNextExerciseLinearFlow =
  (_: SeriesMode, startTime: number) => (exercise: AppExercise) =>
    exercise.status === ExerciseStatus.review || exercise.status === ExerciseStatus.completed
      ? exercise
      : {
          ...exercise,
          ...(exercise.status !== ExerciseStatus.withInput && {
            status: ExerciseStatus.started,
          }),
          startTime,
          steps: exercise.steps.map((step) => {
            if (step.id === exercise.currentStepId && step.status === StepStatus.locked) {
              return startStep(step, startTime);
            } else {
              return step;
            }
          }),
        };
/**
 * Test mode: checks if there is user-input and set the status accordingly
 * Practice mode: no changes
 */
export const updatePrevExerciseNonLinearFlow = (mode: SeriesMode) =>
  mode === SeriesMode.test ? setFilledStatus : identity;
/**
 * Determines if an exercise is considered "filled" in **TEST MODE**.
 *
 * (to show filled circle in hand in confirmation and exercise navigation)
 *
 *  An exercise is considered filled, if
 *    A) last step's question has a history (e.g. last step was changed)
 *    B) current input of last step is not the same as initial question
 *      - Find all interactive children.
 *      - Remove cursor from them if any in content.
 *      - Do a deep comparison of the left over children, if same then not filled else it is filled.
 *    C) user skipped into last step
 *
 * @param steps
 */
export const isExerciseFilled = (steps: ReadonlyArray<AppStep>): boolean => {
  const lastStep = last(steps);

  // Sometimes we encounter undefined in steps array.
  // This should avoid errors in such situations.
  if (lastStep === undefined) return false;

  if (lastStep.status === StepStatus.completed) {
    return true;
  }

  // This code is reached if the user skipped into the last step. Consider the following exercise
  //
  // Calculate 1/4 * 2/3
  //   - first step "cancel down"  (expected answer 1/2 * 1/3)
  //   - second step "multiply"    (expected answer 1/6)
  // In case the student entered 1/6 in the first step we will skip into the second step, with:
  //   - validity: "correct"
  //   - question.past: []
  //
  // For those cases we want the exercise to be highlighted as "filled"
  // (student should not be confused by the feedback that he "still has to change something").
  if (lastStep.validity === Validity.correct) {
    return true;
  }

  const { question, questionIsAnswer } = lastStep;

  if (questionIsAnswer) {
    // If the question is the answer no user input is necessary
    return true;
  }

  if (question?.past.length === 0) {
    return false;
  }

  const presentChildren = interactiveChildren(question.present).map(([_, content]) =>
    isFormulaContent(content) ? content.content.filter((c) => !isCursor(c)) : content
  );
  const pastChildren = interactiveChildren(question.past[0]).map(([_, content]) =>
    isFormulaContent(content) ? content.content.filter((c) => !isCursor(c)) : content
  );

  return !isEqual(presentChildren, pastChildren);
};
/**
 * Test mode: starts step and handles selection of interactive child (cursor to input)
 * Practice mode: nothing to change as in practice mode you can only
 * navigate to completed exercises
 */
export const updateNextExerciseNonLinearFlow = (mode: SeriesMode, startTime: number) =>
  mode === SeriesMode.test
    ? Lens.update(AppExercise.toCurrentStep)((step) => step && startStep(step, startTime))
    : identity;
/**
 * Test mode: checks if there is user-input and set the status accordingly
 * Practice mode:
 *  - set steps to completed and exercise to review
 *  - finished exercise has no more current step id
 *  - collapse exp. & hide solution
 */
export const updatePrevExerciseLinearFlow = (mode: SeriesMode) =>
  mode === SeriesMode.test
    ? setFilledStatus
    : (exercise: AppExercise): AppExercise => ({
        ...exercise,
        currentStepId: undefined,
        status: ExerciseStatus.review,
        navigatedAwayFromExercise: true,
        wrapupState: CollapsibleContentState.collapsed,
        steps: exercise.steps.map((s) => completeStep(s)),
      });
