/**
 * Attempt to extract `translateX`  and ` translateY`  from a
 * transform `translateX(<a>px) translateY(<b>px)` css prop value
 *
 * @param translateStr the string starting with `translateX(...) translateY(...)`
 * @returns number value for both translateX and translateY
 */
export const extractTranslateProps = (
  translateStr: string
): { translateX: number; translateY: number } | undefined => {
  const translateRe =
    /translateX\((?<translateX>[+-]?\d+(\.\d+)?)px\)\s*translateY\((?<translateY>[+-]?\d+(\.\d+)?)px\)/;
  const matchResults = translateRe.exec(translateStr);
  if (matchResults && matchResults.groups) {
    const { translateX, translateY } = matchResults.groups;
    return { translateX: +translateX, translateY: +translateY };
  }
};

/**
 * Attempt to extract the number from the px css props.
 * For instance,
 *  * returns `6` from `"6px"`
 *  * returns `167.24` from `"167.24px"`
 * @param pxStr the pixel str (e.g. "6px")
 * @returns the pixel number value (e.g. `6`)
 */
export const extractPxValue = (pxStr: string): number | undefined => {
  const pixelRe = /(?<pixValue>[+-]?\d+(\.\d+)?)px/;
  const matchResults = pixelRe.exec(pxStr);
  if (matchResults && matchResults.groups) {
    const { pixValue } = matchResults.groups;
    return +pixValue;
  }
};
