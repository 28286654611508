export enum MarkerType {
  Arrow = 'ARROW',
  NarrowArrow = 'NARROW_ARROW',
  OpenTriangle = 'OPEN_TRIANGLE',
  TArrow = 'T_ARROW',
  Triangle = 'TRIANGLE',
  EquiTriangle = 'EQUI_TRIANGLE',
}

export interface MarkerProps {
  color?: string;
  flip?: boolean;
  id: string;
  height?: number;
  width?: number;
  offset?: number;
  type?: MarkerType;
  strokeWidth?: number;
}
