// import {ContentPathHandler} from '@bettermarks/umc-kotlin';
import { type FetchEMParams } from './em';
import { ContentManagerRoutes } from './constants';
import { getPlainTextResponse } from './parseHelpers';
import { Request } from '../bm-api';
import { ContentPathHandler } from '@bettermarks/umc-kotlin';
import { isEmpty } from 'lodash/fp';

export interface FetchCRIParams extends FetchEMParams {
  criId: string;
}

export const fetchCRI = async (
  { locale, criId, staticServerUrl, test }: FetchCRIParams,
  requestApi = Request
): Promise<string> => {
  const [id, variant] = criId.split('.VAR');
  const path = ContentPathHandler.pathCRIFile(id, Number(variant), locale, '.xml', test);
  const encrypt = isEmpty(staticServerUrl);
  const response = await requestApi.get(
    ContentManagerRoutes.getCRI({ path, staticServerUrl, encrypt }),
    'omit' // For static resources, we can `omit` credentials.
  );
  return getPlainTextResponse(await response.text());
};
