import * as React from 'react';
import { Key } from '../Keyboard/Key';
import { CalculatorLayout, type CalculatorProps } from './types';

import styles from './CalculatorKeyboard.scss';
import { NUMBER_KEYS_FIXED, NUMBER_KEYS_STRETCHED } from './CalculatorLayouts';

export const CalculatorNumBlock: React.FC<CalculatorProps> = ({ onKey, layout }) => (
  <div
    className={
      layout === CalculatorLayout.VERTICAL ? styles.numbersBlockFixed : styles.numbersBlockStretched
    }
  >
    {(layout === CalculatorLayout.VERTICAL ? NUMBER_KEYS_FIXED : NUMBER_KEYS_STRETCHED).map((k) => {
      const { kc, ...iconProps } = k;

      return <Key key={kc} keyCode={kc} iconProps={{ ...iconProps, onKey }} />;
    })}
  </div>
);

CalculatorNumBlock.displayName = 'CalculatorNumBlock';
