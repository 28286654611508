import * as React from 'react';
import { noop } from 'lodash';

export const DragNDropContext = React.createContext<{
  maxItemWidth: number;
  setMaxItemWidth: (maxItemWidth: number) => void;
}>({ maxItemWidth: 0, setMaxItemWidth: noop });

export const DragNDropContextProvider: React.FC = ({ children }) => {
  const [maxItemWidth, setMaxItemWidth] = React.useState<number>(0);

  return (
    <DragNDropContext.Provider value={{ maxItemWidth, setMaxItemWidth }}>
      {children}
    </DragNDropContext.Provider>
  );
};
