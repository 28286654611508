import * as React from 'react';
import { type ContentReference } from '@bettermarks/gizmo-types';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { styleName } from '../../../components/_utils';

import styles from './MultipleChoiceCollapsedView.scss';
/**
 * props
 */
export interface MCItemCollapsedProps {
  choicesElements: JSX.Element[];
  choiceToShow: ContentReference;
  stackLayout: boolean;
  availableWidth: number;
}

/**
 * The `MultipleChoiceItemCollapsed` component is a stupid layout wrapper around
 *
 * - choices content
 * - choice to be displayed
 *
 * of MC in collapsed view.
 */
const MCItemCollapsed: React.FC<MCItemCollapsedProps> = (props) => {
  const cName = styleName('choice-box', ['stack', props.stackLayout]);

  return (
    <div>
      {props.choicesElements}
      <br />
      <div className={styles[cName]}>
        <PolymorphicGizmo refid={props.choiceToShow.$refid} availableWidth={props.availableWidth} />
      </div>
    </div>
  );
};

MCItemCollapsed.displayName = 'MCItemCollapsed';

export default MCItemCollapsed;
