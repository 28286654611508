import * as React from 'react';
import Slider from './Slider';
import { ToolColumn } from './Toolbar';
import { ToolbarLabel } from './ToolbarLabel';
import styled from 'styled-components';
import { useWhiteboardContext } from '../whiteboard-context';
import { SECOND_SIDEBAR_WIDTH } from './constants';
import { useTranslation } from 'react-i18next';

const EraserToolColumn = styled(ToolColumn)`
  flex-flow: column-reverse;
`;

const SliderContainer = styled.div`
  text-align: center;
`;

export default function EraserSidebar(): JSX.Element {
  const { state, dispatch } = useWhiteboardContext();
  const eraserWidth = state.toolConfig.eraserThickness;
  const canvasScale = state.scaleConfig.currentScale;
  const [t] = useTranslation();

  return (
    <EraserToolColumn width={SECOND_SIDEBAR_WIDTH}>
      <SliderContainer title={t('seriesplayer:dialog.whiteboard.eraserTool.setThickness')}>
        <Slider
          min={2}
          max={32}
          step={2}
          value={eraserWidth}
          onChange={(value) => dispatch({ type: 'setThickness', tool: 'eraser', thickness: value })}
          penPreviewScale={canvasScale}
        />
      </SliderContainer>
      <ToolbarLabel
        style={{ marginBottom: '12px', marginTop: 'auto' }}
        data-testid="eraserWidthSliderLabel"
      >
        {t('seriesplayer:dialog.whiteboard.penTool.width')}
      </ToolbarLabel>
    </EraserToolColumn>
  );
}
