import { isEmpty } from 'lodash';
import { type Action, createAction, handleActions } from 'redux-actions';
import {
  type BezierGroupObject,
  type Coords,
  type GeoContentPersistProps,
  type GeoMoveBeziersState,
  type GeoObject,
  type GeoObjectMap,
  type PointObject,
  type TickValueInterval,
} from '@bettermarks/gizmo-types';
import {
  DEFAULT_BEZIER_DECORATION,
  DEFAULT_BEZIER_TRANSLATION,
  GEO_DEFAULT_PERSIST_PROPS,
  screenToWorld,
} from '@bettermarks/importers';
import { applyDragDecoration } from '../helpers';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */
export interface DragPayload {
  mouseP: Coords;
  matrix: number[];
  tickValueInterval: TickValueInterval;
}

export interface EndDragPayload {
  geoContentMap: GeoObjectMap<GeoObject>;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface OutPayload {
  id: string;
  origBezier: BezierGroupObject;
}

export interface HoverPayload {
  gizmoId: string;
  id: string;
  matrix: number[];
}

export interface StartDragPayload extends HoverPayload {
  startPos: Coords;
}

export type GeoMoveBezierPayload =
  | StartDragPayload
  | EndDragPayload
  | DragPayload
  | HoverPayload
  | OutPayload
  | void;

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const DRAG: 'DRAG' = 'DRAG';
export const dragAction = createAction<DragPayload>(DRAG);
const END_DRAG: 'END_DRAG' = 'END_DRAG';
export const endDragAction = createAction<EndDragPayload>(END_DRAG);
const START_DRAG: 'START_DRAG' = 'START_DRAG';
export const startDragAction = createAction<StartDragPayload>(START_DRAG);
const HOVER: 'HOVER' = 'HOVER';
export const hoverAction = createAction<HoverPayload>(HOVER);
const OUT: 'OUT' = 'OUT';
export const outAction = createAction<OutPayload>(OUT);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  selectedObjectId: '',
  startPos: { x: NaN, y: NaN },
};

export const geoMoveBeziersReducer = handleActions<GeoMoveBeziersState, GeoMoveBezierPayload>(
  {
    /* eslint-disable-next-line complexity*/
    [DRAG]: (state: GeoMoveBeziersState, { payload }: Action<DragPayload>) => {
      if (!payload || isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { matrix, mouseP, tickValueInterval } = payload;

      const mouseWorld = screenToWorld(matrix)(mouseP);
      const startWorld = screenToWorld(matrix)(state.startPos);

      const addX =
        (Math.floor(mouseWorld.x / tickValueInterval.x) -
          Math.floor(startWorld.x / tickValueInterval.x)) *
        tickValueInterval.x;
      const addY =
        (Math.floor(mouseWorld.y / tickValueInterval.y) -
          Math.floor(startWorld.y / tickValueInterval.y)) *
        tickValueInterval.y;

      if (addX === 0 && addY === 0) {
        return state;
      }

      const prevBezier = state.persistProps.geoContentMap[
        state.selectedObjectId
      ] as BezierGroupObject;
      const prevTrans = prevBezier.translation || DEFAULT_BEZIER_TRANSLATION;

      const doTranslateX =
        prevTrans.x + addX >= prevTrans.left && prevTrans.x + addX <= prevTrans.right;
      const doTranslateY =
        prevTrans.y + addY >= prevTrans.bottom && prevTrans.y + addY <= prevTrans.top;

      // no movement in any direction -> return previous state
      if ((!doTranslateX || addX === 0) && (!doTranslateY || addY === 0)) {
        return state;
      }

      const moveGroup = prevBezier.moveGroup
        ? prevBezier.moveGroup.reduce<GeoObjectMap<PointObject>>((acc, it) => {
            const prevPoint = state.persistProps.geoContentMap[it] as PointObject;
            return {
              ...acc,
              [it]: {
                ...prevPoint,
                coords: {
                  x: prevPoint.coords.x + Number(doTranslateX) * addX,
                  y: prevPoint.coords.y + Number(doTranslateY) * addY,
                },
              },
            };
          }, {})
        : {};

      return {
        ...state,
        startPos: {
          x: doTranslateX ? mouseP.x : state.startPos.x,
          y: doTranslateY ? mouseP.y : state.startPos.y,
        },
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            ...{ ...(!isEmpty(moveGroup) && moveGroup) },
            [state.selectedObjectId]: {
              ...prevBezier,
              translation: {
                ...prevTrans,
                x: prevTrans.x + Number(doTranslateX) * addX,
                y: prevTrans.y + Number(doTranslateY) * addY,
              },
            },
          },
        },
      };
    },
    [END_DRAG]: (state: GeoMoveBeziersState, { payload }: Action<EndDragPayload>) => {
      if (!payload || isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { geoContentMap, onPersistLocalState } = payload;

      const origBezier = geoContentMap[state.selectedObjectId];
      const prevBezier = state.persistProps.geoContentMap[state.selectedObjectId];

      const newState: GeoMoveBeziersState = {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [state.selectedObjectId]: {
              ...prevBezier,
              decoration: {
                ...DEFAULT_BEZIER_DECORATION,
                ...origBezier.decoration,
              },
            },
          },
        },
        selectedObjectId: '',
        startPos: { x: NaN, y: NaN },
        snapPoints: [],
      };

      onPersistLocalState(newState.persistProps);

      return newState;
    },
    [START_DRAG]: (state: GeoMoveBeziersState, { payload }: Action<StartDragPayload>) => {
      if (!payload) {
        return state;
      }

      const { gizmoId, id, startPos, matrix } = payload;

      return {
        ...applyDragDecoration(gizmoId, id, state, matrix, true),
        selectedObjectId: id,
        startPos,
      };
    },
    [HOVER]: (state: GeoMoveBeziersState, { payload }: Action<HoverPayload>) => {
      if (!payload || !isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { gizmoId, id, matrix } = payload;

      return applyDragDecoration(gizmoId, id, state, matrix);
    },
    [OUT]: (state: GeoMoveBeziersState, { payload }: Action<OutPayload>) => {
      if (!payload || !isEmpty(state.selectedObjectId)) {
        return state;
      }

      const { id, origBezier } = payload;
      const prevBezier = state.persistProps.geoContentMap[id];

      return {
        ...state,
        snapPoints: [],
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...prevBezier,
              decoration: {
                ...DEFAULT_BEZIER_DECORATION,
                ...origBezier.decoration,
              },
            },
          },
        },
      };
    },
  },
  initialState
);
