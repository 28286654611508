import * as React from 'react';
import { type EM } from '../../../types';
import { EMContentElement } from './EMContentElement';

export type FreeExampleProps = Readonly<EM.ElementFreeExample & { availableWidth: number }>;

export const FreeExample: React.FC<FreeExampleProps> = ({ content, availableWidth }) => (
  <div>
    {content.map((props, i) => (
      <EMContentElement
        {...props}
        availableWidth={availableWidth}
        first={i === 0}
        last={i === content.length - 1}
        key={i}
      />
    ))}
  </div>
);

FreeExample.displayName = 'FreeExample';
