import * as React from 'react';

import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { type KEMContent } from '../../../types';

import { KEMPage } from './KEMPage';

export type KEMProps = KEMContent & ContextState;

export const KEM: React.FC<KEMProps> = ({ pages, availableWidth }) => (
  <>
    {pages.map((page, i) => (
      <KEMPage {...page} availableWidth={availableWidth} key={i} />
    ))}
  </>
);

KEM.displayName = 'KEM';
