import * as React from 'react';

import { Spinner } from '../../../../components/icons/draft';
import styled, { keyframes, css } from 'styled-components';
import { VERY_SMALL_SCREEN_WIDTH } from '../../constants';

interface DialogProps {
  text: string;
  className?: string;
}

const Dialog: React.FC<DialogProps> = ({ text, className }) => (
  <div data-cy="data-loss-prevention-dialog" className={className}>
    <Spinner className="spinner" />
    <p>{text}</p>
  </div>
);

const spinnerAnimationKeyframes = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const spinnerAnimation = css`
  ${spinnerAnimationKeyframes} 1s linear infinite;
`;

export const DataLossPrevention = styled(Dialog)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${VERY_SMALL_SCREEN_WIDTH}) {
    height: 100%;
    margin: 20px;
  }

  .spinner {
    top: ${48 /* optical middle is slightly above geometric middle */}%;
    width: 100px;
    height: 100px;
    animation: ${spinnerAnimation};
  }
`;

DataLossPrevention.displayName = 'DataLossPrevention';
