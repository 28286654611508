import type { ContentReference } from '@bettermarks/gizmo-types';
import { RS } from '@bettermarks/gizmo-types';
import * as React from 'react';
import StudentTasksContainer from './StudentTasksContainer';
import StudentTask, { getCollapsedStudentTaskTitle } from './StudentTask';
import { useGizmoContext } from '../../../gizmo-utils/polymorphic-gizmo';
import log from 'loglevel';

type StudentTasksProps = {
  studentTasks: ContentReference[];
  availableWidth: number;
};

export default function StudentTasks({ studentTasks, availableWidth }: StudentTasksProps) {
  const contentDict = useGizmoContext()?.contentDict;

  return (
    <StudentTasksContainer availableWidth={availableWidth}>
      {studentTasks.map((studentTask, index) => {
        const contentFromDict = contentDict && (contentDict[studentTask.$refid] as any);

        let collapsedTitle: string;
        if (
          !contentFromDict ||
          contentFromDict.$renderStyle !== RS.TEXT ||
          !contentFromDict.content
        ) {
          log.warn({
            message: 'The content of a student task must exist and be of type text.',
            extra: { refid: studentTask.$refid },
          });
          collapsedTitle = '...';
        } else {
          collapsedTitle = getCollapsedStudentTaskTitle(contentFromDict);
        }

        return (
          <StudentTask
            refId={studentTask.$refid}
            collapsedTitle={collapsedTitle}
            availableWidth={availableWidth}
            key={`collapsible-list-item-${index}`}
          />
        );
      })}
    </StudentTasksContainer>
  );
}
