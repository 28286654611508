// THIS FILE WAS AUTOGENERATED FROM THE CORRESPONDING SCSS FILE. DO NOT EDIT UNLESS YOU KNOW WHAT YOU ARE DOING!!!
export default {
  hoverChange: 'rgb(34, 34, 34)',
  cWhite: 'rgb(255, 255, 255)',
  cGray800: 'rgb(51, 51, 51)',
  cGray700: 'rgb(102, 102, 102)',
  cGray600: 'rgb(153, 153, 153)',
  cGray500: 'rgb(170, 170, 170)',
  cGray400: 'rgb(204, 204, 204)',
  cGray300: 'rgb(221, 221, 221)',
  cGray200: 'rgb(233, 233, 233)',
  cGray100: 'rgb(246, 246, 246)',
  cRed800: 'rgb(187, 51, 0)',
  cRed700: 'rgb(221, 68, 34)',
  cRed600: 'rgb(238, 102, 68)',
  cRed500: 'rgb(255, 119, 102)',
  cRed400: 'rgb(255, 159, 140)',
  cRed300: 'rgb(255, 195, 188)',
  cRed200: 'rgb(255, 230, 227)',
  cRed100: 'rgb(255, 243, 243)',
  cGreen800: 'rgb(17, 136, 0)',
  cGreen700: 'rgb(51, 163, 0)',
  cGreen600: 'rgb(85, 187, 34)',
  cGreen500: 'rgb(136, 214, 51)',
  cGreen400: 'rgb(170, 238, 136)',
  cGreen300: 'rgb(195, 243, 176)',
  cGreen200: 'rgb(227, 252, 220)',
  cGreen100: 'rgb(240, 255, 236)',
  cBlue800: 'rgb(0, 119, 204)',
  cBlue700: 'rgb(17, 136, 238)',
  cBlue600: 'rgb(68, 170, 255)',
  cBlue500: 'rgb(119, 187, 255)',
  cBlue400: 'rgb(170, 214, 255)',
  cBlue300: 'rgb(202, 230, 255)',
  cBlue200: 'rgb(223, 240, 255)',
  cBlue100: 'rgb(238, 246, 255)',
  cYellow800: 'rgb(153, 119, 17)',
  cYellow700: 'rgb(191, 156, 0)',
  cYellow600: 'rgb(238, 187, 34)',
  cYellow500: 'rgb(255, 211, 51)',
  cYellow400: 'rgb(255, 230, 119)',
  cYellow300: 'rgb(255, 250, 187)',
  cYellow200: 'rgb(255, 255, 221)',
  cYellow100: 'rgb(255, 255, 236)',
  cOrange800: 'rgb(187, 102, 0)',
  cOrange700: 'rgb(221, 119, 17)',
  cOrange600: 'rgb(238, 153, 17)',
  cOrange500: 'rgb(255, 170, 17)',
  cOrange400: 'rgb(255, 187, 85)',
  cOrange300: 'rgb(255, 208, 153)',
  cOrange200: 'rgb(255, 239, 220)',
  cOrange100: 'rgb(255, 246, 236)',
  cPurple800: 'rgb(102, 34, 136)',
  cPurple700: 'rgb(119, 68, 170)',
  cPurple600: 'rgb(136, 102, 187)',
  cPurple500: 'rgb(170, 153, 221)',
  cPurple400: 'rgb(201, 187, 255)',
  cPurple300: 'rgb(224, 208, 255)',
  cPurple200: 'rgb(236, 223, 255)',
  cPurple100: 'rgb(243, 238, 255)',
  cSteelBlue800: 'rgb(0, 119, 170)',
  cSteelBlue700: 'rgb(17, 136, 187)',
  cSteelBlue600: 'rgb(85, 153, 204)',
  cSteelBlue500: 'rgb(119, 187, 221)',
  cSteelBlue400: 'rgb(159, 207, 234)',
  cSteelBlue300: 'rgb(197, 223, 236)',
  cSteelBlue200: 'rgb(219, 236, 245)',
  cSteelBlue100: 'rgb(236, 246, 252)',
  cPetrol800: 'rgb(0, 119, 119)',
  cPetrol700: 'rgb(0, 136, 136)',
  cPetrol600: 'rgb(51, 187, 187)',
  cPetrol500: 'rgb(102, 204, 204)',
  cPetrol400: 'rgb(136, 221, 221)',
  cPetrol300: 'rgb(204, 236, 236)',
  cPetrol200: 'rgb(217, 246, 246)',
  cPetrol100: 'rgb(238, 252, 252)',
  cPink800: 'rgb(204, 0, 119)',
  cPink700: 'rgb(221, 17, 187)',
  cPink600: 'rgb(255, 34, 255)',
  cPink500: 'rgb(255, 85, 255)',
  cPink400: 'rgb(255, 153, 255)',
  cPink300: 'rgb(255, 204, 255)',
  cPink200: 'rgb(255, 227, 255)',
  cPink100: 'rgb(255, 236, 255)',
  cYellowGreen800: 'rgb(68, 102, 0)',
  cYellowGreen700: 'rgb(102, 136, 0)',
  cYellowGreen600: 'rgb(153, 187, 0)',
  cYellowGreen500: 'rgb(188, 223, 51)',
  cYellowGreen400: 'rgb(204, 239, 119)',
  cYellowGreen300: 'rgb(221, 249, 153)',
  cYellowGreen200: 'rgb(236, 252, 179)',
  cYellowGreen100: 'rgb(246, 255, 233)',
  cShadowLight: 'rgba(0, 0, 0, 0.2)',
  cItemHover: 'rgba(0, 0, 0, 0.08)',
  cTextMedium: 'rgb(102, 102, 102)',
  cTextDark: 'rgb(51, 51, 51)',
  cTextBright: 'rgb(153, 153, 153)',
  cTextLink: 'rgb(17, 136, 238)',
  cTextLinkHover: 'rgb(0, 119, 204)',
  cTextDisabled: 'rgb(221, 221, 221)',
  cTextDisabledDark: 'rgb(204, 204, 204)',
  cTextError: 'rgb(187, 51, 0)',
  cTextRemark: 'rgb(153, 119, 17)',
  cTextOk: 'rgb(17, 136, 0)',
  cTextComment: 'rgb(17, 136, 0)',
  cTextCorrect: 'rgb(17, 136, 0)',
  cAreaBorder: 'rgb(170, 170, 170)',
  cGroupBorder: 'rgb(204, 204, 204)',
  cElementBorder: 'rgb(221, 221, 221)',
  cSelectedBorder: 'rgb(119, 187, 255)',
  cSelectedBackground: 'rgb(223, 240, 255)',
  cSelectedBackgroundHover: 'rgb(202, 230, 255)',
  cErrorBorder: 'rgb(221, 68, 34)',
  cErrorBackground: 'rgb(255, 230, 227)',
  cErrorBackgroundHover: 'rgb(255, 195, 188)',
  cRemarkBorder: 'rgb(191, 156, 0)',
  cRemarkBackground: 'rgb(255, 255, 221)',
  cRemarkBackgroundHover: 'rgb(255, 250, 187)',
  cCorrectBorder: 'rgb(51, 163, 0)',
  cOkBorder: 'rgb(51, 163, 0)',
  cCommentBorder: 'rgb(51, 163, 0)',
  cCorrectBackground: 'rgb(227, 252, 220)',
  cCorrectBackgroundHover: 'rgb(195, 243, 176)',
  cUnselectedBorder: 'rgb(170, 170, 170)',
  cUnselectedBackgroundHover: 'rgb(246, 246, 246)',
  cNeutralBackground: 'rgb(246, 246, 246)',
  cNeutralBorder: 'rgb(153, 153, 153)',
  cDisabledBorder: 'rgb(221, 221, 221)',
  cDisabledBorderDark: 'rgb(204, 204, 204)',
  cBorderExplanation: 'rgb(170, 214, 255)',
  cBorderAnswer: 'rgb(136, 214, 51)',
  btnTransparencyHover: 'rgba(0, 0, 0, 0.1)',
  btnTransparencyActive: 'rgba(0, 0, 0, 0.1)',
};
