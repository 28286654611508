import { getFontMetric } from '../../../../../utils/fontMetric';
import { defaultTo } from 'lodash';
import { type FontProps, type ScriptMetricInput, toNumberTuple } from '@bettermarks/gizmo-types';

import { SCRIPT_VERTICAL_MARGIN } from '../constants';

import { type ScriptMetric } from './types';

export const getSubScriptOverlap = ({ fontSize, fontWeight, fontStyle }: FontProps) =>
  Math.ceil(getFontMetric(fontSize, fontWeight, fontStyle).capHeight * 0.8);

export const getSuperScriptOverlap = ({ fontSize, fontWeight, fontStyle }: FontProps) =>
  getFontMetric(fontSize, fontWeight, fontStyle).capHeight;

export const getScriptMetric = (
  { height: baseHeight, padding: basePadding }: ScriptMetricInput,
  { height: scriptHeight, padding: scriptPadding }: ScriptMetricInput,
  overlap: number
  // height is not part of ScriptMetric, since it is not working for MSubSup
): ScriptMetric & { height: number } => {
  const offset = scriptHeight - overlap;

  const [, basePaddingRight] = toNumberTuple(basePadding);
  const [scriptPaddingLeft] = toNumberTuple(scriptPadding);
  const spacing =
    SCRIPT_VERTICAL_MARGIN -
    Math.round(defaultTo<number>(basePaddingRight, 0) + defaultTo<number>(scriptPaddingLeft, 0));
  return {
    height: baseHeight + Math.max(0, offset),
    offset,
    scriptHeight,
    shift: baseHeight - overlap,
    spacing,
  };
};
