import { isFontStyle, isFontWeight } from '@bettermarks/bm-font';
import { Severity } from '@bettermarks/umc-kotlin';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import {
  type Decoration,
  type DecorationWithSeverity,
  isTextAlignment,
} from '@bettermarks/gizmo-types';
import { convertFlashFontSize, flashFontSize } from '../fontSize';

type FormulaDecoKeys = 'backgroundColor' | 'fontSize' | 'fontStyle' | 'textAlign';

export const parseDecoration = (
  decoString: string,
  convertFontSize = true
): DecorationWithSeverity => {
  const { severity, object } = parseDecoString<FormulaDecoKeys>(decoString);

  const decoration: Decoration = {};
  if (object.backgroundColor) {
    decoration.backgroundColor = object.backgroundColor;
  }
  if (object.color) {
    decoration.color = object.color;
  }
  if (object.fontSize) {
    decoration.fontSize = convertFontSize
      ? convertFlashFontSize(object.fontSize)
      : flashFontSize(object.fontSize);
  }
  if (isFontStyle(object.fontStyle)) {
    decoration.fontStyle = object.fontStyle;
  }
  if (isFontWeight(object.fontWeight)) {
    decoration.fontWeight = object.fontWeight;
  }
  if (isTextAlignment(object.textAlign)) {
    decoration.textAlign = object.textAlign;
  }
  return severity ? { decoration, severity } : { decoration };
};

export const mapSeverityToDecoration = (severity?: Severity): Decoration => {
  switch (severity) {
    case Severity.remark:
      return { color: Severity.remark };
    case Severity.error:
      return { color: Severity.error };
    case Severity.comment:
    case Severity.ok:
      return { color: Severity.ok };
    default:
      return {};
  }
};
