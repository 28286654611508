import type { Content, ContentDict, ContentReference } from '@bettermarks/gizmo-types';
import { Lens } from '@bettermarks/gizmo-types';
import type { StateWithHistory } from 'redux-undo';
import type { CRIStatus } from '../apps/criplayer';
import { compose, get, identity, isNil, omit, set } from 'lodash/fp';
import { entries } from 'lodash';

export type CRIContent = Readonly<
  Content & {
    $renderStyle: 'cri';
    id: string;
    variant: CRIVariant | undefined;
    setting: ContentReference | undefined;
    canvasConfiguration: ContentReference;
    studentTasks: ContentReference[];
  }
>;

export type CRIVariant = {
  id: string;
  title: string;
};

export type CRI = Readonly<{
  data?: StateWithHistory<ContentDict>;
  status?: CRIStatus;
}>;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CRI {
  export const toContentDict: Lens<CRI, ContentDict> = {
    get: (classroomIntro: CRI) => get('data.present', classroomIntro),
    set: (contentDict) => (classroomIntro) => set('data.present', contentDict, classroomIntro),
  };

  export const toGizmoContent = (gizmoId: string): Lens<CRI, Content> => {
    return {
      get: (classroomIntro) => toContentDict.get(classroomIntro)[gizmoId] as Content,
      set: (content) => (classroomIntro) =>
        toContentDict.set({
          ...toContentDict.get(classroomIntro),
          [gizmoId]: content,
        })(classroomIntro),
    };
  };

  export const toCriContent: Lens<CRI, CRIContent | undefined> = {
    get: (classroomIntro: CRI) => {
      const contentDict = CRI.toContentDict.get(classroomIntro);
      const criContentEntry = entries(contentDict).find(
        ([, content]) => content && isCriContent(content)
      );
      const criContent = criContentEntry && criContentEntry[1];
      return criContent as CRIContent;
    },
    set: (criContent: CRIContent) => (classroomIntro) => {
      const contentDict = CRI.toContentDict.get(classroomIntro);
      const criContentEntry = entries(contentDict).find(
        ([, content]) => content && isCriContent(content)
      );
      const criRefId = criContentEntry && criContentEntry[0];
      return !isNil(criRefId)
        ? Lens.update(toGizmoContent(criRefId), (c: Content) => criContent, classroomIntro)
        : classroomIntro;
    },
  };

  export const toSelectedRefId: Lens<CRI, string | undefined> = {
    get: (classroomIntro) => {
      const contentDict = toContentDict.get(classroomIntro);
      const selected =
        contentDict && entries(contentDict).find(([, content]) => !!(content && content.selected));

      return selected && selected[0];
    },
    // make sure the selectedRefId points to an interactive content when calling.
    // otherwise it can happen that nothing gets selected
    set: (selectedRefId) =>
      Lens.update(toContentDict)(
        (contentDict) =>
          contentDict &&
          entries(contentDict).reduce(
            (acc, [refId, content]: [string, Content]) => ({
              ...acc,
              [refId]: compose(
                refId === selectedRefId &&
                  !isNil(content) &&
                  content.tool &&
                  content.$interactionType
                  ? (c: Content) => ({ ...c, selected: true })
                  : identity,
                omit('selected')
              )(content),
            }),
            {}
          )
      ),
  };

  export const toSelectedContent: Lens<CRI, Content | undefined> = {
    get: (classroomIntro: CRI) => {
      const refId = toSelectedRefId.get(classroomIntro);
      return !isNil(refId) ? toGizmoContent(refId).get(classroomIntro) : undefined;
    },
    set: (content) => (classroomIntro) => {
      const refId = toSelectedRefId.get(classroomIntro);
      return !isNil(refId) && !isNil(content)
        ? Lens.update(toGizmoContent(refId), (c: Content) => content, classroomIntro)
        : classroomIntro;
    },
  };

  export const isCriContent = (content: Content | undefined): content is CRIContent => {
    return content !== undefined && content.$renderStyle === 'cri';
  };
}
