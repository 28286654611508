import React from 'react';
import styled from 'styled-components';
import { DropdownContext } from './DropdownContext';
import { getBottom, getHeight, getTop } from './utils';

const _DropdownTrigger: React.FC<{ className?: string }> = ({ children, className }) => {
  const { toggle, triggerRef } = React.useContext(DropdownContext);

  return (
    <div className={className} onClick={toggle} ref={triggerRef}>
      {children}
    </div>
  );
};

export const DropdownTrigger = styled(_DropdownTrigger)`
  cursor: pointer;
  position: relative;
`;

DropdownTrigger.displayName = 'DropdownTrigger';

export interface DropdownContentProps {
  className?: string;
}

const _DropdownContent: React.FC<DropdownContentProps> = ({ className, children }) => {
  const { contentDynamicStyles, contentRef } = React.useContext(DropdownContext);
  return (
    <div className={className} style={contentDynamicStyles} ref={contentRef}>
      {children}
    </div>
  );
};

export const DropdownContent = styled(_DropdownContent)`
  position: absolute;
  width: 100%;
`;

DropdownContent.displayName = 'DropdownContent';

interface OverlayProps {
  show: boolean;
  zIndex: number;
}

const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(p) => p.zIndex};
  width: 100vw;
  height: 100vh;
  display: ${(p) => (p.show ? 'block' : 'none')};
`;

Overlay.displayName = 'Overlay';

export const enum DropdownDirection {
  top = 'top',
  bottom = 'bottom',
}

const directionMap: Record<DropdownDirection, DropdownDirection> = {
  [DropdownDirection.bottom]: DropdownDirection.top,
  [DropdownDirection.top]: DropdownDirection.bottom,
};

export interface DropdownProps {
  zIndex: number;
  className?: string;
  direction?: DropdownDirection;
  track?: boolean;
  stickToViewport?: boolean;
  useOverlay?: boolean;
  isOpen?: boolean;
}

const _Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    children,
    className,
    direction = DropdownDirection.top,
    track,
    stickToViewport,
    zIndex,
    useOverlay = true,
    isOpen = false,
  } = props;

  const [dynamicDirection, setDirection] = React.useState(direction);
  const [show, setShow] = React.useState(isOpen);
  const [additionalOffset, setAdditionalOffset] = React.useState(0);
  const [triggerHeight, setTriggerHeight] = React.useState(0);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const getDirection = React.useCallback(
    () =>
      (direction === DropdownDirection.top
        ? window.innerHeight - getBottom(triggerRef.current)
        : getTop(triggerRef.current)) > getHeight(contentRef.current)
        ? directionMap[directionMap[direction]]
        : directionMap[direction],
    [direction]
  );

  React.useEffect(() => {
    const triggerHeight = getHeight(triggerRef.current);
    setTriggerHeight(triggerHeight);

    let newDir = getDirection();

    setDirection(newDir);

    if (track) {
      const updateContentPosition = () => {
        newDir = getDirection();

        if (stickToViewport) {
          setAdditionalOffset(
            Math.min(
              0,
              getTop(triggerRef.current) + triggerHeight,
              window.innerHeight - getBottom(triggerRef.current) + triggerHeight
            )
          );
        }

        setDirection(newDir);
      };

      document.addEventListener('scroll', updateContentPosition);

      return () => document.removeEventListener('scroll', updateContentPosition);
    }
  }, [show, direction, track, stickToViewport, getDirection]);

  const toggle = React.useCallback(() => {
    setShow((s) => !s);
  }, []);

  return (
    <div className={className}>
      <DropdownContext.Provider
        value={{
          triggerRef,
          contentRef,
          toggle,
          isOpened: show,
          contentDynamicStyles: {
            [dynamicDirection]: `${triggerHeight + -additionalOffset}px`,
            visibility: show ? 'visible' : 'hidden',
          },
        }}
      >
        {children}
      </DropdownContext.Provider>
      {useOverlay && <Overlay zIndex={zIndex - 1} show={show} onClick={() => setShow(false)} />}
    </div>
  );
};

export const Dropdown = styled(_Dropdown)`
  position: relative;
  z-index: ${(p) => p.zIndex};

  ${DropdownTrigger} {
    z-index: ${(p) => p.zIndex};
  }

  ${DropdownContent} {
    z-index: ${(p) => p.zIndex};
  }
`;

Dropdown.displayName = 'Dropdown';
