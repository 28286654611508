import * as React from 'react';
import { colors } from '@seriesplayer/common-ui';

type DynamicCircleProps = {
  radius: number;
  scale?: number;
};

export default function DynamicCircle({ radius, scale = 1 }: DynamicCircleProps) {
  const divWidth = 200;
  const divHeight = 200;

  const relativeCircleWidth = ((radius * scale) / (divWidth / 2)) * 100;
  const circleStyle = {
    width: `${relativeCircleWidth}%`,
    paddingBottom: `${relativeCircleWidth}%`,
    borderRadius: '50%',
    backgroundColor: `${colors.cGray600}`,
  };

  const containerStyle = {
    width: divWidth,
    height: divHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={containerStyle}>
      <div style={circleStyle}></div>
    </div>
  );
}
