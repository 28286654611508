import { reduceReducers } from '../../../../../gizmo-utils/reduceReducers';
import { type Action, handleAction } from 'redux-actions';
import { ApplicationState, DEFAULT_TOOLBAR_SETTINGS, SeriesStatus } from '../../../../../types';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { getActiveToggles } from '../helpers';
import { initialSeriesplayerState } from '../../../../store/constants';
import { TOGGLE_TOOLBAR_DRAWER, type ToggleToolbarDrawerPayload } from '../actions';
import { isFormulaSelected } from '../../../../store/helper';
import { compose } from 'lodash/fp';
import { selectInteractiveChild } from '../../../selectHelpers';
import { identity } from '../../../../../gizmo-utils/filters';
import { SET_SERIES_STATUS } from '../../Series/actions';
import { deselectGizmo } from '../../../helper';

type ToolsToOpenInitially = ToolbarElements.modeSelector | ToolbarElements.dnd;
type ToolToCondition = {
  tool: ToolsToOpenInitially;
  condition: boolean;
};

export const setInitialDrawerState = (state: ApplicationState): ApplicationState => {
  const { tools } = state.toolbar;
  // NOTE:
  // - The conditions for the tools listed here should be exclusive, if not the order matters
  // - Opening the dnd drawer is handled by openDragAndDropDrawerSaga to provide a delay for the
  //   opening animation
  const openByCondition: ToolToCondition[] = [
    {
      tool: ToolbarElements.modeSelector,
      condition: tools.length === 1 && tools[0] === ToolbarElements.modeSelector,
    },
  ];
  const openDrawerName = openByCondition.reduce(
    (acc, tToC) => (tToC.condition ? tToC.tool : acc),
    null
  );
  return ApplicationState.toToolbarOpenDrawerName.set(openDrawerName)(state);
};

export const resolveTools = (state: ApplicationState): ApplicationState => {
  const tools = getActiveToggles(state);
  return ApplicationState.toToolbar.set({
    tools,
    openDrawerName: tools.length === 0 ? null : state.toolbar.openDrawerName,
  })(state);
};

const updateCurrentExercise = (formulaSelected: boolean) =>
  formulaSelected ? deselectGizmo(true) : identity;

const handleToggleToolbarDrawer = handleAction(
  TOGGLE_TOOLBAR_DRAWER,
  (state: ApplicationState, { payload }: Action<ToggleToolbarDrawerPayload>) => {
    const { drawerName, preserveIfOpened = false, toggledByUser = false } = payload;
    const openDrawerName = ApplicationState.toToolbarOpenDrawerName.get(state);
    const tools = new Set(ApplicationState.toToolbarTools.get(state));

    if (!tools.has(drawerName as ToolbarElements)) {
      return state;
    }

    const deselectFormulaIfSelected = updateCurrentExercise(
      isFormulaSelected(state) &&
        !(
          drawerName === ToolbarElements.calculator && openDrawerName === ToolbarElements.keyboard
        ) &&
        !(drawerName === ToolbarElements.keyboard && openDrawerName === ToolbarElements.calculator)
    );

    return compose(
      openDrawerName === null ? ApplicationState.toToolbarOpenDrawerName.set(drawerName) : identity,
      drawerName === openDrawerName
        ? compose(
            deselectFormulaIfSelected,
            ApplicationState.toToolbarOpenDrawerName.set(preserveIfOpened ? drawerName : null)
          )
        : compose(
            toggledByUser
              ? compose(
                  deselectFormulaIfSelected,
                  ApplicationState.toToolbarOpenDrawerName.set(drawerName)
                )
              : identity
          ),
      drawerName !== openDrawerName &&
        (drawerName === ToolbarElements.keyboard || drawerName === ToolbarElements.calculator)
        ? selectInteractiveChild(true)
        : identity
    )(state);
  },
  initialSeriesplayerState
);

const resetToolbar = handleAction(
  SET_SERIES_STATUS,
  (state: ApplicationState, { payload }: Action<SeriesStatus>) =>
    payload === SeriesStatus.completed
      ? ApplicationState.toToolbar.set(DEFAULT_TOOLBAR_SETTINGS)(state)
      : state,
  initialSeriesplayerState
);

export const toolbarReducer = reduceReducers(resetToolbar, handleToggleToolbarDrawer);
