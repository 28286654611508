import { createAction, handleActions, type Action } from 'redux-actions';
import { isNil } from 'lodash';
import { SORTING_DEFAULT_CONTENT, type SortingContent } from '@bettermarks/gizmo-types';

export type ReorderPayload = {
  sourceIndex: number;
  destinationIndex: number;
};

export const REORDER = 'REORDER';
export const reorder = createAction<ReorderPayload>(REORDER);

export type SortingGizmoPayload = ReorderPayload;

export const sortingReducer = handleActions<SortingContent, SortingGizmoPayload>(
  {
    [REORDER]: (state, { payload }: Action<ReorderPayload>) => {
      if (isNil(payload)) return state;

      const { sourceIndex, destinationIndex } = payload;

      // reset severity of items
      const items = state.items.map(({ severity, ...it }) => it);
      const moved = items[sourceIndex];
      const reduced = [...items.slice(0, sourceIndex), ...items.slice(sourceIndex + 1)];

      return {
        ...state,
        items: [...reduced.slice(0, destinationIndex), moved, ...reduced.slice(destinationIndex)],
      };
    },
  },
  SORTING_DEFAULT_CONTENT
);
