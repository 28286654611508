import { type Reducer, type ReducerMeta } from 'redux-actions';

import { type Content, type ContentDict } from '@bettermarks/gizmo-types';
import { combinedKey } from '../configuration/combinedKey';
import { type GizmoActionMeta } from './gizmoActions';

type GizmoReducer<Payload> = Reducer<Content, Payload>;

export interface GizmoReducerMap<Payload> {
  [actionType: string]: GizmoReducer<Payload>;
}

export type ContentDictReducer<P> = ReducerMeta<ContentDict, P, GizmoActionMeta>;

const createGizmoReducer =
  <P>(gizmoReducers: GizmoReducerMap<P>): ContentDictReducer<P> =>
  (state, action) => {
    const gizmoState = state[action.meta.gizmoId];

    if (gizmoState) {
      const gizmoType = combinedKey(gizmoState);
      const specificReducer = gizmoReducers[gizmoType];

      if (specificReducer) {
        const nextGizmoState = specificReducer(gizmoState, action);

        return {
          ...state,
          [action.meta.gizmoId]: nextGizmoState,
        };
      }
    }
    return state;
  };

export const createContentDictReducer =
  <P>(
    contentDictReducer: ContentDictReducer<P>,
    gizmoReducers: GizmoReducerMap<P>
  ): ContentDictReducer<P> =>
  (state, action) =>
    [contentDictReducer, createGizmoReducer(gizmoReducers)].reduce(
      (state, reducer) => reducer(state, action),
      state
    );
