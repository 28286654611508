import { ensureFontsLoaded as ensureFontsLoadedUnstubable } from '../../../../utils/fontMetric';
import { selectFirstInteractiveChildAction } from '../../../../gizmo-utils/redux';
import { type Action } from 'redux-actions';
import { all, call, put, select } from 'redux-saga/effects';
import {
  ErrorHandleKind,
  HttpStatusCode,
  LoaderState,
  type LobLinkParams,
  type PdfReviewSeriesParams,
  type StartSeriesPayload,
  type ApplicationState,
  StepStatus,
  SeriesMode,
} from '../../../../types';
import { handleFontLoadingErrors } from '../../../handleFontLoadingErrors';
import { startSeries } from '../../reducers';
import { handleError } from '../../sagaHelpers';
import { type AppConfig, getAppConfig, OPEN_SETTINGS_ROUTE } from '../../services/api/getAppConfig';
import {
  changeLoaderState,
  FETCH_REMOTE_XML_SP,
  FETCH_SERIES,
  hideContent,
  showErrorDialog,
  interceptBrowserBackButton,
} from './actions';
import {
  loadLobLinkSeries,
  loadPdfReviewSeries,
  type LoadSeriesArgs,
  loadSeriesFunction,
} from './startSeriesHelpers';
import { quitSeriesPlayerSaga } from './quitSeriesPlayerSaga';
import { START_FROM_EXERCISE_ID } from '../Upload';

export const STUBABLE = {
  ensureFontsLoaded: ensureFontsLoadedUnstubable,
};

export function* startAndInitSeries(startSeriesPayload: StartSeriesPayload) {
  yield put(startSeries(startSeriesPayload));

  yield put(selectFirstInteractiveChildAction(false));

  yield call(quitIfResultsAreHiddenForSeries);

  yield put(changeLoaderState(LoaderState.success));
}

/**
 * In test with disallowed results view, we want to prevent situation
 * when student goes "back" in browser history right after hand in
 * or when he goes to test with direct url.
 */
export function* quitIfResultsAreHiddenForSeries() {
  const state: ApplicationState = yield select();

  // checking if series is already handed in...
  const seriesCompleted = state.series.exercises.every((e) =>
    e.steps.every((s) => s.status === StepStatus.completed)
  );

  const showResults = state.series.seriesSettings.showResults;
  const testMode = state.series.mode === SeriesMode.test;

  if (testMode && !showResults && seriesCompleted) yield call(quitSeriesPlayerSaga);
}

export function* loadSeriesSaga({ type, payload }: Action<LoadSeriesArgs>) {
  try {
    yield put(changeLoaderState(LoaderState.loading));
    // Only for the case of fetchSeries our app is embedded.
    let appConfig: AppConfig | undefined;
    if (type === FETCH_SERIES) {
      appConfig = yield call(getAppConfig);
    }
    const [startSeriesPayload]: [StartSeriesPayload /*, undefined*/] = yield all([
      call(loadSeriesFunction(type), payload, appConfig),
      call(STUBABLE.ensureFontsLoaded, 5000, handleFontLoadingErrors),
    ]);
    yield startAndInitSeries(startSeriesPayload);

    // in case we are fetching from the seriesplayer route already,
    // no push to '/series' (FETCH_REMOTE_XML_SP)
    if (type !== FETCH_REMOTE_XML_SP && type !== START_FROM_EXERCISE_ID) {
      // @ts-expect-error this exists for these types
      payload?.navigate?.();
    } else if (type === START_FROM_EXERCISE_ID) {
      // TODO(reactv18)
      // starting "from exerciseid" additionally sets the exercise ID as query parameter
      // (this way the created URL becomes a copy-paste-able link to the wanted exercise)
      // yield navigate?.(
      //   `/series?exerciseid=${getFullExercisePath((payload as ILoadExercisePayload).exerciseid)}`
      // );
      // @ts-expect-error this exists for these types
      payload?.navigate?.();
    }

    yield put(interceptBrowserBackButton());
  } catch (error) {
    yield call(handleError, error);
    yield put(changeLoaderState(LoaderState.error));
  }
}

export function* loadLobLinkSeriesSaga({ payload }: Action<LobLinkParams>) {
  try {
    yield put(changeLoaderState(LoaderState.loading));
    const appConfig: AppConfig = yield call(getAppConfig, OPEN_SETTINGS_ROUTE);
    const [startSeriesPayload]: [StartSeriesPayload /*, undefined*/] = yield all([
      call(loadLobLinkSeries, payload, appConfig),
      call(STUBABLE.ensureFontsLoaded, 5000, handleFontLoadingErrors),
    ]);

    yield startAndInitSeries(startSeriesPayload);
  } catch (error) {
    yield put(hideContent());

    yield put(changeLoaderState(LoaderState.success));

    yield put(
      showErrorDialog({
        kind: ErrorHandleKind.back,
        description:
          error.response.status === HttpStatusCode.FORBIDDEN
            ? 'seriesplayer:dialog.lobLink.wrongToken'
            : 'seriesplayer:dialog.lobLink.wrongContent',
      })
    );
  }
}

export function* loadPdfReviewSaga({ payload }: Action<PdfReviewSeriesParams>) {
  try {
    yield put(changeLoaderState(LoaderState.loading));
    const appConfig: AppConfig = yield call(getAppConfig, OPEN_SETTINGS_ROUTE);
    const [startSeriesPayload]: [StartSeriesPayload /*, undefined*/] = yield all([
      call(loadPdfReviewSeries, payload, appConfig),
      call(STUBABLE.ensureFontsLoaded, 5000, handleFontLoadingErrors),
    ]);

    yield startAndInitSeries(startSeriesPayload);
  } catch (error) {
    yield put(changeLoaderState(LoaderState.success));
  }
}
