import { Maybe } from '../../utils/maybe';
import { SCROLL_SPEED } from './constants';
import { type ScrollPosition } from './types';

export abstract class DragScrollDirectionStrategy {
  private readonly scrollContainer: Maybe<HTMLElement>;
  protected initialOverflowValue: Maybe<string>;
  protected scrollRect: Maybe<ClientRect>;

  constructor(scrollContainer: Nullable<HTMLElement>) {
    this.scrollContainer = Maybe(scrollContainer);
    this.initialOverflowValue = this.scrollContainer.map(this.setInitialOverflowValue);
  }

  getScrollAmount = () => this.scrollContainer.ap(this.getScroll) || 0;

  incrementalScroll = (delta: number) => this.scrollContainer.ap(this.incrementScroll(delta));

  onMove = (position: ScrollPosition) => {
    const topDist = this.getTopDist(position);
    const bottomDist = this.getBottomDist(position);

    return { topDist, bottomDist };
  };

  scroll = (animSpeed: number, deltaT: number) =>
    this.scrollContainer
      .map(() => animSpeed * deltaT * SCROLL_SPEED)
      .ap((scroll) => {
        scroll !== 0 && this.scrollContainer.ap(this.incrementScroll(scroll));

        return this.getNewScrollPosition(scroll);
      });

  getContainer = () => this.scrollContainer;

  startScrolling = () => {
    this.scrollRect = this.scrollContainer.map((c) => c.getBoundingClientRect());
    this.scrollContainer.ap((c) => (c.style.scrollBehavior = 'auto'));
    this.scrollContainer.ap(this.hideOverflow);
  };

  stopScrolling = () => {
    this.scrollContainer.ap((c) => (c.style.scrollBehavior = 'smooth'));
    this.scrollContainer.ap(this.restoreOverflow);
  };

  getTopDist: (p: ScrollPosition) => number;

  getBottomDist: (p: ScrollPosition) => number;

  getScroll: (c: HTMLElement) => number;

  hideOverflow: (c: HTMLElement) => void;

  restoreOverflow: (c: HTMLElement) => void;

  incrementScroll: (delta: number) => (c: HTMLElement) => void;

  getNewScrollPosition: (scroll: number) => ScrollPosition;

  protected abstract setInitialOverflowValue(c: HTMLElement): string | null;
}
