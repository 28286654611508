import * as React from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';
import { isNil } from 'lodash';
import {
  FlexAlign,
  HLayoutChildWrapper,
  StyledHLayout,
  StyledVLayout,
  VLayoutChildWrapper,
} from '@seriesplayer/common-ui';
import { type Severity } from '@bettermarks/umc-kotlin';
import {
  isContentReference,
  isMN,
  isMRow,
  type MathContent,
  type MouseOrTouch,
  type Slice,
  type SliceWithLegendLabel,
} from '@bettermarks/gizmo-types';
import { getNamedColor } from '@bettermarks/importers';
import { default as Handle } from '../../components/Slider/Handle';
import { PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import { Formula } from '../formula/Formula';
import { LegendComponent, type LegendProps } from './components/legend/LegendComponent';
import { type PieChartProps } from './PieChartEditor';
import {
  DEFAULT_BORDER_STROKE,
  DEFAULT_LEGEND_GAP,
  DEFAULT_LEGEND_GAP_SMALL,
  DEFAULT_PIE_CHART_LABEL_OFFSET,
  DEFAULT_PIE_CHART_MARK_EXTENSION,
  DEFAULT_PIE_CHART_MIN_SCALE,
  DEFAULT_PIE_CHART_OFFSET_X,
  DEFAULT_PIE_CHART_OFFSET_Y,
  DEFAULT_PIE_CHART_RADIUS,
  MAX_ROW_WIDTH,
} from './defaults';

import { PieSlice } from './components/pie/PieSlice';
import { type Coords } from './components/pie/math';
import {
  addTouchStartListener,
  onMove,
  onTouchEnd,
  onTouchStart,
  removeTouchStartListener,
} from './preventScrollingTouch';

import styles from './PieChartRenderer.scss';
import chartsStyles from './charts.scss';
import { ShadowScrollbars } from '../components';

/**
 * Update label with error highlight
 * @param  {MathContent}
 * @param  {severity}
 * @return {MathContent}
 */
const updateLabelWithSeverity = (mContent: MathContent, severity: Severity): MathContent => ({
  ...mContent,
  ...('computedStyles' in mContent && {
    computedStyles: {
      ...mContent.computedStyles,
      color: getNamedColor(severity),
    },
  }),
});

/**
 * To update the <mn> tag with the value of the slice
 * @param  {MathContent} mContent node
 * @param  {string} id of the slice
 * @param  {number} value of the slice
 * @param  {Severity} severity if any, of the slice
 * @return {MathContent}
 */
const updateLabelWithValue = (
  mContent: MathContent,
  sliceId: string,
  value: number,
  severity?: Severity
): MathContent => {
  if (isMRow(mContent)) {
    return {
      ...mContent,
      children: mContent.children.map((child) =>
        updateLabelWithValue(child, sliceId, value, severity)
      ),
    };
  }
  const updatedMContent = severity ? updateLabelWithSeverity(mContent, severity) : mContent;
  // Eg:- Check for <mn id="101:value"/>
  if (isMN(updatedMContent) && updatedMContent.id === `${sliceId}:value`) {
    return {
      ...updatedMContent,
      text: value.toString(),
    };
  }
  return updatedMContent;
};

/**
 * To extract the props required to render LegendComponent from slices
 * @param  {number} availableWidth
 * @param  {Slice[]} slices
 * @param  {boolean} isTouch
 * @return {LegendProps}
 */
export const getLegendProps = (
  availableWidth: number,
  slices: SliceWithLegendLabel[],
  isTouch?: boolean
): LegendProps => {
  const legend = slices.map(({ color, id, legendLabel, severity, value }) => ({
    color,
    label: isContentReference(legendLabel)
      ? legendLabel
      : {
          ...legendLabel,
          content: legendLabel.content.map((mContent) =>
            updateLabelWithValue(mContent, id, value, severity)
          ),
        },
  }));

  return {
    availableWidth,
    isTouch,
    legend,
  };
};

export type LabelDim = { width: number; height: number };

export type PieChartState = {
  hoveredItem: number;
  selectedItem: number;
  measuredLabels: LabelDim[];
  maxLabelHeight: number;
  maxLabelWidth: number;
  initialMeasurement: boolean;
  scale: number;
};

export class PieChartRenderer extends React.Component<PieChartProps, PieChartState> {
  state = {
    hoveredItem: NaN,
    selectedItem: NaN,
    measuredLabels: [...Array(this.props.slices.length)].map((_) => ({
      width: 0,
      height: 0,
    })),
    maxLabelHeight: 0,
    maxLabelWidth: 0,
    initialMeasurement: this.props.slicesWithLabels > 0,
    scale: 1,
  };

  componentDidMount() {
    if (this.pieChartRef.current) {
      addTouchStartListener(this.pieChartRef);
    }
  }

  componentWillUnmount() {
    if (this.pieChartRef.current) {
      removeTouchStartListener(this.pieChartRef);
    }
  }

  pieChartRef = React.createRef<HTMLElement>();
  rootElemRef = React.createRef<HTMLDivElement>();

  onMoveStart = (item: number) => (evt: MouseOrTouch) => {
    if (this.props.isTouch) {
      onTouchStart(evt);
    }

    this.setState({ selectedItem: item });
    this.props.onMoveStart && this.props.onMoveStart(item);
  };

  onMoveEnd = () => {
    this.setState({ selectedItem: NaN });
    this.props.onMoveEnd && this.props.onMoveEnd();

    if (this.props.isTouch) {
      onTouchEnd();
    }
  };

  onMove = (evt: any) => {
    if (this.props.isTouch) {
      onMove(evt);
    }

    this.props.onMove && this.props.onMove(evt);
  };

  onHoverOver = (item: number) => () => this.setState({ hoveredItem: item });

  onHoverLeave = () => this.setState({ hoveredItem: NaN });

  onLabelResize =
    (idx: number) =>
    ({ offset }: any) =>
      this.setState((prevState) => ({
        measuredLabels: [
          ...prevState.measuredLabels.slice(0, idx),
          { ...offset },
          ...prevState.measuredLabels.slice(idx + 1),
        ],
        maxLabelHeight: Math.max(prevState.maxLabelHeight, offset.height),
        initialMeasurement:
          prevState.measuredLabels.reduce((acc, { width }) => (width > 0 ? acc + 1 : acc), 0) <
          this.props.slicesWithLabels - 1,
      }));

  onScaleChange = (availablePieWidth: number) => (_: any) =>
    this.setState({
      scale: Math.max(
        DEFAULT_PIE_CHART_MIN_SCALE,
        availablePieWidth < 2 * DEFAULT_PIE_CHART_RADIUS
          ? availablePieWidth / (2 * DEFAULT_PIE_CHART_RADIUS) - 0.05
          : 1
      ),
    });

  renderSlices = (slices: Slice[], radius: number) => {
    let cumulativeValue = 0;
    return slices.map(({ color, mark, severity, value }: Slice, i: number) => {
      cumulativeValue += value;
      return (
        <PieSlice
          key={i}
          color={color}
          radius={radius}
          startAngle={(cumulativeValue - value) * 3.6}
          endAngle={cumulativeValue * 3.6}
          severity={severity}
          mark={mark}
        />
      );
    });
  };

  cartesianFromAngleDeg = (center: Coords, radius: number, angle: number) => {
    const radAngle = ((angle - 90) / 180) * Math.PI;

    return {
      x: center.x + Math.cos(radAngle) * radius,
      y: center.y + Math.sin(radAngle) * radius,
    };
  };

  getLabelPos = (
    center: Coords,
    radius: number,
    value: number,
    accValue: number,
    idx: number,
    mark = false
  ) => {
    const startAngle = (accValue - value) * 3.6;
    const endAngle = accValue * 3.6;
    const halfAngle = (startAngle + endAngle) / 2;
    const radHalfAngle = ((halfAngle - 90) / 180) * Math.PI;

    const labelDim = this.state.measuredLabels[idx];
    const labelWidth = labelDim ? (labelDim as LabelDim).width : 0;
    const labelHeight = labelDim ? (labelDim as LabelDim).height : 0;

    const finalRadius =
      radius + DEFAULT_PIE_CHART_LABEL_OFFSET + (mark ? DEFAULT_PIE_CHART_MARK_EXTENSION : 0);

    return {
      anchorX: center.x + Math.cos(radHalfAngle) * finalRadius,
      anchorY: center.y + Math.sin(radHalfAngle) * finalRadius,
      shiftX: halfAngle > 180 ? -labelWidth : 0,
      shiftY: halfAngle < 90 || halfAngle > 270 ? -labelHeight : 0,
    };
  };

  getRequiredLabelSpace = (
    interactive: boolean,
    labelSpacePercentages: number[],
    positive: boolean,
    defaultOffset: number,
    hasMarkedSlice: true | undefined
  ) => {
    const { initialMeasurement, measuredLabels, scale } = this.state;

    if (interactive || initialMeasurement) {
      return defaultOffset;
    }

    const additionalRequiredLabelSpace = labelSpacePercentages.reduce((acc, p, idx) => {
      const labelIdx: number = positive ? idx : measuredLabels.length - 1 - idx;
      return Math.max(
        acc,
        measuredLabels[labelIdx].width - (1 - p) * DEFAULT_PIE_CHART_RADIUS * scale
      );
    }, 0);

    return Math.ceil(
      additionalRequiredLabelSpace +
        DEFAULT_PIE_CHART_LABEL_OFFSET +
        (hasMarkedSlice ? DEFAULT_PIE_CHART_MARK_EXTENSION : 0)
    );
  };

  getReqLabelSpaceY = (
    interactive: boolean,
    defaultOffset: number,
    hasMarkedSlice: true | undefined
  ) =>
    interactive || this.state.initialMeasurement
      ? defaultOffset
      : this.state.maxLabelHeight +
        DEFAULT_PIE_CHART_LABEL_OFFSET +
        (hasMarkedSlice ? DEFAULT_PIE_CHART_MARK_EXTENSION : 0);

  // calculate the ratio of the x-coordinate of the anchor-point for the label and the circle radius
  // e.g. at 90°+270° the ratio is 1, at 180°+360° it is 0
  getLabelAnchorToRadiusRatios = () => {
    let cumulativeValue = 0;
    let left: number[] = [];
    let right: number[] = [];
    this.props.slices.map(({ value }: Slice) => {
      cumulativeValue += value;
      const degAlpha = (cumulativeValue - value / 2) * 3.6;
      const radAlpha = ((degAlpha % 90) * Math.PI) / 180;
      const percentage =
        (degAlpha >= 90 && degAlpha < 180) || degAlpha >= 270
          ? Math.cos(radAlpha)
          : Math.sin(radAlpha);

      // for angles right to circle center we append, for angles left to circle center we prepend
      // then we can loop over the labels from the top of the circle for both easily
      if (degAlpha < 180) {
        right = [...right, percentage];
      } else {
        left = [percentage, ...left];
      }
    });

    return { left, right };
  };

  render() {
    const {
      $interactionType,
      availableWidth,
      disabled,
      hasLegend,
      hasMarkedSlice,
      isTouch,
      needsBorder,
      onMove,
      slices,
      title,
      uniqueId,
    } = this.props;

    const width = Math.min(availableWidth, MAX_ROW_WIDTH);
    const legendWidth =
      (hasLegend ? -DEFAULT_LEGEND_GAP : 0) +
      (availableWidth > width ? availableWidth - width : availableWidth);
    const interactive = !isNil($interactionType);
    const { initialMeasurement, selectedItem } = this.state;

    let accValue = 0;

    const labelAnchorToRadiusRatio = this.getLabelAnchorToRadiusRatios();

    const pieChartLabelSpaceXLeft = this.getRequiredLabelSpace(
      interactive,
      labelAnchorToRadiusRatio.left,
      false,
      DEFAULT_PIE_CHART_OFFSET_X,
      hasMarkedSlice
    );

    const pieChartLabelSpaceXRight = this.getRequiredLabelSpace(
      interactive,
      labelAnchorToRadiusRatio.right,
      true,
      DEFAULT_PIE_CHART_OFFSET_X,
      hasMarkedSlice
    );

    const availablePieWidth = availableWidth - pieChartLabelSpaceXLeft - pieChartLabelSpaceXRight;

    const newScale = Math.max(
      DEFAULT_PIE_CHART_MIN_SCALE,
      availablePieWidth < 2 * DEFAULT_PIE_CHART_RADIUS
        ? availablePieWidth / (2 * DEFAULT_PIE_CHART_RADIUS) - 0.05
        : 1
    );

    const radius = Math.floor(newScale * DEFAULT_PIE_CHART_RADIUS);

    const center = {
      x: radius + pieChartLabelSpaceXLeft,
      y: radius + this.getReqLabelSpaceY(interactive, DEFAULT_PIE_CHART_OFFSET_Y, hasMarkedSlice),
    };

    const totalWidth = center.x + radius + pieChartLabelSpaceXRight;
    const totalHeight = 2 * center.y;

    const legendAndPieChartInOneLine =
      legendWidth + totalWidth + (hasLegend ? DEFAULT_LEGEND_GAP : 0) <= availableWidth;

    return (
      <div ref={this.rootElemRef} className={initialMeasurement ? chartsStyles.invisible : ''}>
        <StyledVLayout align={FlexAlign.center}>
          {title && (
            <VLayoutChildWrapper noMargin>
              <div className={chartsStyles.title} style={{ maxWidth: width }}>
                <PolymorphicGizmo refid={title.$refid} availableWidth={width} />
              </div>
            </VLayoutChildWrapper>
          )}
          <VLayoutChildWrapper noMargin={!title}>
            <StyledHLayout
              gap={
                hasLegend
                  ? legendAndPieChartInOneLine
                    ? DEFAULT_LEGEND_GAP
                    : DEFAULT_LEGEND_GAP_SMALL
                  : 0
              }
            >
              <HLayoutChildWrapper>
                <div
                  id={`PIE_CHART:${uniqueId}`}
                  onMouseMove={onMove && onMove(this.rootElemRef.current)}
                  onMouseUp={this.onMoveEnd}
                  onMouseLeave={this.onMoveEnd}
                  onTouchMove={onMove && onMove(this.rootElemRef.current)}
                  onTouchEnd={this.onMoveEnd}
                  role="presentation"
                  className={classNames(styles.relative, {
                    [styles.grabbing]: !isNaN(selectedItem),
                  })}
                  style={{ maxWidth: width }}
                >
                  <ShadowScrollbars availableWidth={availableWidth}>
                    <Measure offset onResize={this.onScaleChange(availablePieWidth)}>
                      {({ measureRef }) => (
                        <div ref={measureRef}>
                          <svg
                            width={totalWidth}
                            height={totalHeight}
                            viewBox={`${-center.x} ${-center.y} ${totalWidth} ${totalHeight}`}
                          >
                            {this.renderSlices(slices, radius)}
                            {needsBorder && (
                              <circle
                                r={radius - 2}
                                cx={0}
                                cy={0}
                                fill={'none'}
                                stroke={DEFAULT_BORDER_STROKE}
                                strokeWidth={1}
                              />
                            )}
                          </svg>
                        </div>
                      )}
                    </Measure>
                    {slices.map(({ sliceLabel, value, mark, color }: Slice, i: number) => {
                      accValue += value;
                      const labelPos = this.getLabelPos(center, radius, value, accValue, i, mark);
                      const handleAngle = (accValue - value) * 3.6;
                      const handlePos = this.cartesianFromAngleDeg(center, radius, handleAngle);
                      return (
                        <div key={i}>
                          <div
                            className={styles.sliceLabel}
                            style={{
                              left: labelPos.anchorX + labelPos.shiftX,
                              top: labelPos.anchorY + labelPos.shiftY,
                            }}
                          >
                            {sliceLabel && (
                              <Measure offset onResize={this.onLabelResize(i)}>
                                {({ measureRef }) => (
                                  <div ref={measureRef}>
                                    {isContentReference(sliceLabel) ? (
                                      <PolymorphicGizmo
                                        refid={sliceLabel.$refid}
                                        availableWidth={availableWidth}
                                      />
                                    ) : (
                                      <Formula {...{ ...sliceLabel, availableWidth }} />
                                    )}
                                  </div>
                                )}
                              </Measure>
                            )}
                          </div>
                          {interactive && i > 0 && (
                            <div
                              role="presentation"
                              onMouseDown={this.onMoveStart(i)}
                              onTouchStart={this.onMoveStart(i)}
                              onMouseOver={this.onHoverOver(i)}
                              onMouseLeave={this.onHoverLeave}
                              className={styles.sliceLabel}
                              style={{
                                left: handlePos.x,
                                top: handlePos.y,
                              }}
                            >
                              <Handle
                                flip={(accValue - value) * 3.6 + 180}
                                handleShape={'raindrop'}
                                disabled={disabled}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </ShadowScrollbars>
                </div>
              </HLayoutChildWrapper>
              {hasLegend && (
                <HLayoutChildWrapper>
                  <div className={styles.legend}>
                    <LegendComponent
                      {...getLegendProps(legendWidth, slices as SliceWithLegendLabel[], isTouch)}
                    />
                  </div>
                </HLayoutChildWrapper>
              )}
            </StyledHLayout>
          </VLayoutChildWrapper>
        </StyledVLayout>
      </div>
    );
  }
}
