import * as React from 'react';
import { DropTarget } from '../../../components/DragAndDrop';
import { type ContextState, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  DragAndDropStyle,
  type DropCallbacks,
  type DropProps,
  type DropTargetContent,
  type FreeDragItemTranslate,
} from '@bettermarks/gizmo-types';
import { isNil, throttle } from 'lodash';
import { Severity } from '@bettermarks/umc-kotlin';
import DnD, { DragEvents } from '../../../components/DragAndDrop/dnd';

import styles from './DropTargetGizmo.scss';

const TOUCH_Y_OFFSET = 30;

export type DropTargetCallbacks = DropCallbacks;

export type DropTargetGizmoProps = DropTargetContent &
  DropTargetCallbacks &
  DropProps &
  ContextState;

export type DropTargetGizmoState = {
  canReceiveDrops: boolean;
  dragging: boolean;
  draggable: boolean;
  dropAllowed: boolean;
};

const getDragSourceContent = (ref) => ref.current?.firstElementChild?.firstElementChild;

/**
 * Connects the [[components/DragSource]] UIComponent to [[../DragSourceContent]].
 */
export class DropTargetGizmo extends React.Component<DropTargetGizmoProps, DropTargetGizmoState> {
  ref = React.createRef<HTMLSpanElement>();

  state = {
    canReceiveDrops: false, // whether there's a drag in progress somewhere
    dragging: false, // whether we are dragging from us
    draggable: true, // whether this item can be dragged
    dropAllowed: false, // whether a drop is allowed (same target group!)
  };

  componentDidMount() {
    DnD.addEventListener(DragEvents.dragStart, this.onDragStart);
    DnD.addEventListener(DragEvents.dragEnd, this.onAnyDragEnded);
    DnD.addEventListener(DragEvents.drop, this.onAnyDragEnded);
    if (this.ref.current) {
      this.ref.current.addEventListener('touchstart', this.onTouchStart, {
        passive: false,
      });
    }
  }

  componentWillUnmount() {
    DnD.removeEventListener(DragEvents.dragStart, this.onDragStart);
    DnD.removeEventListener(DragEvents.dragEnd, this.onAnyDragEnded);
    DnD.removeEventListener(DragEvents.drop, this.onAnyDragEnded);
  }

  onAnyDragEnded = () => {
    this.setState({ draggable: true });
  };

  onMouseDown = ({ currentTarget, clientX, clientY }: React.MouseEvent) => {
    this.startDrag(currentTarget, clientX, clientY);
  };

  onTouchStart = (evt: TouchEvent) => {
    const interactive = !isNil(this.props.$interactionType) && !this.props.disabled;
    if (evt.currentTarget && this.props.items.length !== 0 && interactive) {
      this.startDrag(evt.currentTarget as Element, evt.touches[0].clientX, evt.touches[0].clientY);
      evt.preventDefault();
    }
  };

  // Called by us, when we start a drag with one of our items.
  startDrag = (target: EventTarget & Element, x: number, y: number) => {
    if (this.props.items.length === 1) {
      const item = this.props.items[0];
      const targetElement = target.firstChild;
      if (!targetElement) return;
      const sourceRef = targetElement.firstChild as Element;
      if (!item.resolved) return;
      DnD.startDrag({
        targetGroup: this.props.targetGroup,
        originalSourceRefId: item.resolved.$refid,
        srcDropTargetRefId: this.props.refid,
        sourceRef,
        x,
        y,
        freeSnapping: item.resolved.dragSource.freeSnapping,
      });
      DnD.addEventListener(DragEvents.dragEnd, this.onDragEnd);
      DnD.addEventListener(DragEvents.drop, this.onDragAbort);
      this.setState({ dragging: true });
    }
  };

  // Our own touchend event handler on document. Here we check if we have been hit!
  onTouchEnd = (evt: TouchEvent) => {
    if (this.state.dropAllowed) {
      this.drop();
    }
  };

  dragEnter = () =>
    this.setState((state, props) => {
      if (!state.dropAllowed && DnD.canDrop(props.targetGroup)) {
        return { ...state, dropAllowed: true };
      } else {
        return state;
      }
    });

  dragLeave = () =>
    this.setState((state, props) => {
      if (state.dropAllowed) {
        return { ...state, dropAllowed: false };
      } else {
        return state;
      }
    });

  onTouchMove = throttle((evt: TouchEvent) => {
    const touch = evt.touches[0];
    const { left, right, top, bottom } = this.ref.current?.getBoundingClientRect() || {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    };

    const xOnTarget = touch.clientX > left && touch.clientX < right;
    const yOnTarget =
      touch.clientY > top &&
      // subtracting TOUCH_Y_OFFSET for better visibility of target highlight (not below finger)
      // assumption: in the majority of cases items are dragged to target from below target
      touch.clientY - TOUCH_Y_OFFSET < bottom;

    if (xOnTarget && yOnTarget) {
      this.dragEnter();
    } else {
      this.dragLeave();
    }
  }, 100);

  // Called by dnd api to notify us, that a drag with the current target group
  // has been started. We can listen now to touchend events (if we are on a
  // touch device) to find out if the drag ends on us and we have to initiate a
  // drop.
  onDragStart = () => {
    document.addEventListener('touchend', this.onTouchEnd);
    document.addEventListener('touchmove', this.onTouchMove);
    this.setState({ canReceiveDrops: true, draggable: false });
  };

  // Called by dnd api if a drag from this set target ended. We have to notify
  // the reducer in this case, that an item is to be removed.
  onDragEnd = () => {
    if (DnD.source?.freeSnapping) {
      const itemTranslate = {
        refid: DnD.source.originalSourceRefId || '',
        newPos: DnD.getDragItemTranslationPosition(),
      };
      this.props.onRemoveItem && this.props.onRemoveItem(0, itemTranslate);
    } else {
      this.props.onRemoveItem && this.props.onRemoveItem(0);
    }
    DnD.removeEventListener(DragEvents.dragEnd, this.onDragEnd);
    DnD.removeEventListener(DragEvents.drop, this.onDragAbort);
    this.setState({
      canReceiveDrops: false,
      dragging: false,
      dropAllowed: false,
    });
  };

  // Called by dnd api, if the drag ended (successfully or not)
  onDragAbort = () => {
    document.removeEventListener('touchend', this.onTouchEnd);
    document.removeEventListener('touchmove', this.onTouchMove);
    DnD.removeEventListener(DragEvents.dragEnd, this.onDragEnd);
    DnD.removeEventListener(DragEvents.drop, this.onDragAbort);
    this.setState({
      canReceiveDrops: false,
      dragging: false,
      dropAllowed: false,
    });
  };

  // called by us after a mouseup event here, when we were dragging or
  // after a touchend here. It will notify the reducers and the dnd api
  // of the successful drop.
  drop = () => {
    const source = DnD.source;
    if (!source) return;
    const item = this.props.items[0];

    const sameItem = item && item.resolved && item.resolved.$refid === source.originalSourceRefId;

    if (!sameItem) {
      if (this.props.onDropItem && source.targetGroup === this.props.targetGroup) {
        const newPos = DnD.getDragItemTranslationPosition();
        const freeItemTranslation: FreeDragItemTranslate = {
          newPos,
          refid: source.originalSourceRefId || '',
        };

        // Use translation between source and target
        // to consider the snapping of the item into the target
        const target = getDragSourceContent(this.ref);
        if (target) {
          const targetPos = DnD.getSourceToTargetTranslation(target);
          const sourcePos = DnD.getTargetToSourceTranslation(target);
          if (targetPos) {
            freeItemTranslation.newPos = targetPos;
            freeItemTranslation.sourcePos = sourcePos;
          }
        }

        if (source.srcDropTargetRefId) {
          this.props.onDropItem(source.srcDropTargetRefId, freeItemTranslation);
        } else if (source.originalSourceRefId) {
          this.props.onDropItem(source.originalSourceRefId, freeItemTranslation);
        }

        DnD.drop();
      } else {
        // not same target group -> do not drop (animate back to source)
        DnD.endDrag();
      }
    } else {
      if (source.srcDropTargetRefId === this.props.refid) {
        // Dropping on ourselves: Do not drop, but also do not animate back to source.
        // We don't need to notify anyone here, because we are the source.
        DnD.finishDrag();
        this.onDragAbort();
        DnD.dispatchEvent('dragend');
      } else {
        // Dropping same item on a filled target (us) -> do not drop and animate
        // back to source.
        DnD.endDrag();
      }
    }
    this.setState({
      canReceiveDrops: false,
      dragging: false,
      dropAllowed: false,
    });
  };

  /* eslint-disable-next-line complexity*/
  render() {
    const {
      availableWidth,
      backgroundAlpha,
      borderColor,
      fontSize,
      items,
      style,
      targetGroup,
      severity,
      width,
      height,
      disabled,
      $interactionType,
    } = this.props;
    const [item] = items;
    const dragSourceContent = item.resolved && item.resolved.dragSource;
    const interactive = !isNil($interactionType) && !disabled;
    const itemWidth =
      dragSourceContent && !dragSourceContent.autoSize ? dragSourceContent.width : width;
    const itemHeight =
      dragSourceContent && !dragSourceContent.autoSize ? dragSourceContent.height : height;
    const canReceiveDrops = this.state.canReceiveDrops && interactive;
    return (
      <span
        role="button"
        ref={this.ref}
        data-droptarget={this.props.targetGroup}
        onMouseDown={interactive && !isNil(dragSourceContent) ? this.onMouseDown : undefined}
        onMouseUp={canReceiveDrops ? this.drop : undefined}
        onMouseEnter={canReceiveDrops ? this.dragEnter : undefined}
        onMouseLeave={canReceiveDrops ? this.dragLeave : undefined}
        className={styles.wrapper}
      >
        <DropTarget
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          itemType={targetGroup}
          borderColor={borderColor}
          backgroundAlpha={backgroundAlpha}
          fontSize={fontSize}
          shape={style === DragAndDropStyle.none ? undefined : style}
          error={severity === Severity.error}
          remark={severity === Severity.remark}
          dragging={this.state.dragging}
          dragOver={this.state.dropAllowed}
          draggable={interactive && this.state.draggable && !isNil(item)}
        >
          {dragSourceContent ? (
            <PolymorphicGizmo
              refid={dragSourceContent.content.$refid}
              availableWidth={availableWidth}
              keepLineHeight
            />
          ) : undefined}
        </DropTarget>
      </span>
    );
  }
}
