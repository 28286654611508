import {
  annotationInner,
  type ClockGizmoContent,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportClock: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as ClockGizmoContent;
  return semantics(
    `
    <mrow>
      <mn>${content.hour}</mn>
      <mn>${content.minute}</mn>
    </mrow>
    `,
    annotationInner(content.$, content)
  );
};
