import * as React from 'react';
import { isNil } from 'lodash';
import {
  CAP_ARROW_REF_PIXEL,
  DEFAULT_LINE_DECORATION,
  getNamedColor,
  getStrokeWidth,
  HIGHLIGHT_DISPLAYED_PIXELLENGTH_LIMIT,
  transformX,
  transformY,
} from '@bettermarks/importers';
import { Marker } from '../../components';
import {
  type Coords,
  type GeoLineDecoration as LineDecoration,
  type Hover,
  LineCapStyleToMarkerType,
  type LineHighlight,
  type LineStyle,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import { HIGHLIGHT_MAP } from '../tools/constants';

import { getLineStyle } from './decorations';

import styles from './Line.scss';
import { type Severity } from '@bettermarks/umc-kotlin';
import { getHoverColor } from '../tools/helpers';

export type LineProps = {
  id: string;
  matrix: number[];
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  borderRectId: string;
  center?: Coords;
  hover?: Hover;
  rotate?: number;
  notLabelable?: true;
  selectable?: boolean;
  highlight?: LineHighlight;
  decoration?: LineDecoration;
  severity?: Severity;
  onClick?: (evt: React.MouseEvent<any>) => void;
  onHover?: (evt: MouseOrTouch) => void;
  onMouseDown?: (evt: MouseOrTouch) => void;
  onMouseOut?: (evt: MouseOrTouch) => void;
};

// is the highlight displayed? (depending on screen-length of line ...
const highlightDisplayed = (sLen: number): boolean => sLen >= HIGHLIGHT_DISPLAYED_PIXELLENGTH_LIMIT;

/* eslint-disable complexity */
export const Line: React.FC<LineProps> = ({
  id,
  matrix,
  x1,
  y1,
  x2,
  y2,
  decoration,
  borderRectId,
  notLabelable,
  highlight,
  center,
  rotate,
  selectable,
  onClick,
  onHover,
  onMouseDown,
  onMouseOut,
  hover,
  severity,
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  const lineDeco: LineDecoration = {
    ...DEFAULT_LINE_DECORATION,
    ...decoration,
    ...(decoration && decoration.marked && { color: getNamedColor(decoration.marked) }),
    ...(severity && { color: getNamedColor(severity) }),
    ...getHoverColor(hover),
  };
  const strokeWidth = getStrokeWidth(lineDeco, hover);

  const [capT, capB] = ['lineCapStyleTop', 'lineCapStyleBottom'].map((d) =>
    lineDeco.hasOwnProperty(d) ? lineDeco[d] : null
  );

  const [mIdT, mIdB] = [capT, capB].map((d, i) => (d !== null ? `marker-${i}-${id}` : null));

  // shorten the line by CAP_ARROW_REF_PIXELS + strokeWidth/2 pixels...
  // strokeWidth needs to be taken into account for not overlapping the arrow in case of thick lines
  const [sx1, sx2, sy1, sy2] = [sx(x1), sx(x2), sy(y1), sy(y2)];
  const [dx, dy] = [sx2 - sx1, sy2 - sy1];
  const len = Math.sqrt(dx * dx + dy * dy);
  const tb = (capB === 't-arrow' ? 0 : CAP_ARROW_REF_PIXEL + strokeWidth / 2) / len;
  const tt = (len - (capT === 't-arrow' ? 0 : CAP_ARROW_REF_PIXEL + strokeWidth / 2)) / len;
  const highlightSvg = highlightDisplayed(len) && highlight && HIGHLIGHT_MAP[highlight];

  const isInteractive = selectable && (onClick || onMouseDown || onHover || onMouseOut);

  // do not render lines that are condensed to a point (e.g. when moving one end point to
  // the other via move tool)
  if (len === 0) {
    return null;
  }

  return (
    <>
      {capT && (
        <Marker
          id={`${mIdT}`}
          type={LineCapStyleToMarkerType[capT as string]}
          color={lineDeco.color}
          strokeWidth={strokeWidth}
          offset={CAP_ARROW_REF_PIXEL - strokeWidth / 2}
        />
      )}
      {capB && (
        <Marker
          id={`${mIdB}`}
          type={LineCapStyleToMarkerType[capB]}
          color={lineDeco.color}
          flip={true}
          strokeWidth={strokeWidth}
          offset={CAP_ARROW_REF_PIXEL - strokeWidth / 2}
        />
      )}

      <line
        key={id}
        x1={capB ? sx1 + tb * dx : sx1}
        y1={capB ? sy1 + tb * dy : sy1}
        x2={capT ? sx1 + tt * dx : sx2}
        y2={capT ? sy1 + tt * dy : sy2}
        stroke={getNamedColor(lineDeco.color) as string}
        strokeWidth={strokeWidth}
        strokeDasharray={getLineStyle(lineDeco.lineStyle as LineStyle, strokeWidth)}
        strokeLinecap="round"
        clipPath={`url(#${borderRectId})`}
        markerEnd={mIdT ? `url(#${mIdT})` : ''}
        markerStart={mIdB ? `url(#${mIdB})` : ''}
      />

      {highlightSvg && center && !isNil(rotate) && (
        <g transform={`rotate(${-rotate} ${sx(center.x)} ${sy(center.y)})`}>
          {React.createElement(highlightSvg.icon, {
            height: highlightSvg.height,
            width: highlightSvg.width,
            x: sx(center.x) - highlightSvg.width * 0.5,
            y: sy(center.y) - highlightSvg.height * 0.5,
          })}
        </g>
      )}

      {isInteractive && (
        <line
          key={`clickAreaLine_${id}`}
          x1={sx1}
          y1={sy1}
          x2={sx2}
          y2={sy2}
          className={styles.interactive}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          onMouseOver={onHover}
          onMouseOut={onMouseOut}
        />
      )}
    </>
  );
};

Line.displayName = 'Line';
