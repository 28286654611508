import { isEmpty, isNil, pickBy } from 'lodash/fp';
import {
  type ChartLabel,
  ChartLabelType,
  ContentColor,
  type FElement,
  type FormulaContent,
  type Importer,
  type ImporterContext,
  isFormulaContent,
  type PieOrStackedBarChartContent as StackedBarChartContent,
  type Slice,
  toBoolean,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';

export const importLabel = (
  xml: FElement,
  context: ImporterContext,
  interactive: boolean
): ChartLabel | undefined => {
  const contentRef = context.importXML(xml);
  const contentRefId = contentRef.$refid;

  const gizmoContent = context.contentDict[contentRefId];

  // do not add empty formula labels => leads to problems when doing the initialMeasurement
  if (isFormulaContent(gizmoContent)) {
    if (gizmoContent.content.length === 0) {
      return undefined;
    } else {
      return gizmoContent;
    }
  }

  return interactive ? (gizmoContent as FormulaContent) : contentRef;
};

export const importSlice = (
  xml: FElement,
  context: ImporterContext,
  interactive: boolean
): Slice => {
  const {
    object: { fillColor },
    severity,
  } = parseDecoString(xml.attribute('decoration'));
  const id = xml.attribute('id');
  let legendLabel = xml.getChildrenByTagName('label').find((label) => !label.hasAttribute('type'));
  if (isNil(legendLabel)) {
    legendLabel = xml.findChildTagWithAttribute('label', 'type', ChartLabelType.LEGEND);
  }
  const sliceLabel = xml.findChildTagWithAttribute('label', 'type', ChartLabelType.SLICE);
  const mark = toBoolean(xml.attribute('mark'));
  const value = parseFloat(xml.findChildTag('value').text);
  return {
    color: fillColor as ContentColor,
    id,
    mark,
    ...(severity && { severity }),
    value,
    ...pickBy((v) => !isNil(v), {
      ...(legendLabel.exists && {
        legendLabel: importLabel(legendLabel.firstChild, context, interactive),
      }),
      ...(sliceLabel.exists && {
        sliceLabel: importLabel(sliceLabel.firstChild, context, interactive),
      }),
    }),
  };
};

export const importStackedBarChart: Importer<StackedBarChartContent> = (
  preContent,
  xml,
  context
) => {
  const chart = xml.findChildTag('chart');
  const hasLegend = toBoolean(chart.attribute('hasLegend'));
  const title = chart.findChildTag('title');
  const slices = chart
    .findChildTag('slices')
    .getChildrenByTagName('slice')
    .map((slice) => importSlice(slice, context, !isEmpty(preContent.$interactionType)));
  const slicesWithLabels = slices.filter((s) => !!s.sliceLabel).length;
  const needsBorder = slices.some((s) => s.color === ContentColor.BM_WHITE);
  return {
    ...preContent,
    /**
     * uniqueId for markers/clip-path/filters
     * (assumption: there are no duplicate $refids per exercise)
     */
    uniqueId: context.$refid,
    hasLegend,
    slices,
    slicesWithLabels,
    needsBorder,
    ...(title.exists && { title: context.importXML(title.firstChild) }),
  };
};
