import * as React from 'react';
import { type TFunction } from 'i18next';
import { identity } from 'lodash/fp';
import { Button, ButtonKind, ButtonSize, ButtonStretch } from '@seriesplayer/common-ui';
import { Formula } from '../../../../../../gizmos/formula/Formula';
import { type FormulaContent } from '@bettermarks/gizmo-types';
import { CalculatorLayout } from './types';
import { enrichSingleFormula } from '../../../../../../gizmos/formula/measure';
import { ChevronRightSmall } from '../../../../../../components/icons';

import styles from './Calculator.scss';
import { ShadowScrollbars } from '../../../../../../gizmos/components';

export interface CalculatorResultProps {
  availableWidth: number;
  content?: FormulaContent;
  layout: CalculatorLayout;
  enrich: boolean;
  onInsert?: () => void;
}

export const CalculatorResult: React.FC<CalculatorResultProps & { t: TFunction }> = ({
  availableWidth,
  content,
  enrich = true,
  layout,
  t,
  onInsert,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const enrichedContent: Function = enrich ? enrichSingleFormula : identity;

  return (
    <div
      className={
        layout === CalculatorLayout.HORIZONTAL ? styles.resultHorizontal : styles.resultVertical
      }
    >
      <div data-cy="calculator-result" className={styles.content}>
        <ShadowScrollbars
          availableWidth={availableWidth}
          fixedChildScrollWidth={availableWidth + 1} // forcing it into shadow scroll
        >
          {!!content && (
            <Formula {...enrichedContent(content)} availableWidth={availableWidth} inCalculator />
          )}
        </ShadowScrollbars>
      </div>
      <div className={styles.insertButtonClickArea} data-cy="calculator-insert-answer">
        <Button
          stretch={ButtonStretch.stretch}
          key={`insert_${layout}`}
          kind={ButtonKind.action}
          size={ButtonSize.s}
          onClick={onInsert}
        >
          <div className={styles.insertButtonContent}>
            <span className={styles.insertButtonLabel}>{t('calculator:insert.label')}</span>
            <span className={styles.insertButtonIcon}>
              <ChevronRightSmall />
            </span>
          </div>
        </Button>
      </div>
    </div>
  );
};

CalculatorResult.displayName = 'CalculatorResult';
