import * as React from 'react';

import styles from './Background.scss';

export const Background: React.FC = () => {
  return (
    <g className={styles.ballotContainer}>
      <path
        d="M122.53,8.164c-1.583-1.03-4.012-2.004-7.1-2.896c-8.462-2.823-21.829-4.134-32.551-4.742
            c-5.136-0.318-10.479-0.5-15.871-0.521C66.467,0.001,66.108,0,65.96,0c-0.149,
            0-0.51,0.001-1.054,0.005
            c-5.581,0.021-11.099,0.213-16.384,0.551C37.813,1.181,24.626,2.509,16.309,5.327
            c-3.133,0.921-5.574,1.927-7.128,2.992
            C3.261,12.381,0,17.95,0,24v117c0,5.845,3.08,11.208,8.907,15.51c6.61,4.88,
            31.138,8.49,57.053,8.49
            c25.583,0,50.035-3.542,56.879-8.332C128.747,152.533,132,146.969,132,141V24C132,
            17.762,128.637,12.138,122.53,8.164z
            M119.733,12.008c0.008,2.902-19.808,8.062-53.773,8.062c-33.967,
            0-53.775-5.069-53.775-8.009c0-0.842,1.974-1.838,5.192-2.877
            C26.533,6.51,43.61,4.198,63.395,4.013C64.242,4.006,65.093,4,65.96,4c0.885,
            0,1.753,0.006,2.617,0.013
            c19.649,0.183,36.382,2.398,45.591,5.035C117.624,10.119,119.731,11.149,119.733,
            12.008z M128,141
            c0,5.929-4.054,10.011-7.454,12.391c-5.277,3.693-27.646,7.68-54.586,7.68c-27.265,
            0-49.61-4.038-54.678-7.779
            C7.96,150.839,4,146.715,4,141V24c0-4.473,2.328-7.849,4.676-10.12c5.42,10.065,
            55.054,10.189,57.284,10.189
            c2.234,0,52.067-0.125,57.315-10.252C125.66,16.07,128,19.438,128,24V141z"
      />
    </g>
  );
};

Background.displayName = 'Background';
