import { identity, isEmpty, pickBy } from 'lodash/fp';
import {
  CONFIGURATION,
  type ContentReference,
  type FElement,
  importContent,
  type Importer,
  type ImporterContext,
  NodeType,
  parseCommonAttribs,
  RS,
  SEMANTICS,
  TREE_CHART_GIZMO_DEFAULT_CONTENT,
  type TreeChartContent,
  TreeChartDirection,
  type TreeChartNode,
  TreeChartNodeType,
  type TreeNode,
  xmlTextToBoolean,
} from '@bettermarks/gizmo-types';
import { parseNode } from '../Graph/importer';
import { flattenTree, layoutTree as createLayoutTree } from './layoutTree';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import { graph } from './layout';

const typeFromString = (typeString: string): TreeChartNodeType => {
  switch (typeString) {
    case 'tree-node-container':
      return TreeChartNodeType.node;
    case 'tree-leaf-container':
      return TreeChartNodeType.leaf;
    default:
      return TreeChartNodeType.leaf;
  }
};

const edgeDecoration = (xml: FElement) => {
  const {
    object: { lineColor, lineHighlightColor, lineStyle, lineWeight },
  } = parseDecoString<'lineColor' | 'lineHighlightColor' | 'lineStyle' | 'lineWeight'>(
    xml.attribute('decoration')
  );
  return pickBy(identity, {
    color: lineColor,
    highlightColor: lineHighlightColor,
    style: lineStyle,
    weight: lineWeight,
  });
};

/**
 * gather, if a 'border' should be paineted around the gizmo when painted within the tree
 * @param refId
 * @param context
 */
const hasBorder = (contentRef: ContentReference, context: ImporterContext) => {
  const content = context.content.get(contentRef.$refid);
  // Thanks to Frauke!!! <<string>> IN [<<some list of strings>>] will not work as expected!
  // And we are currently using only one render style to check.
  return content && (content.$renderStyle === RS.DROP_TARGET || content.$interactionType)
    ? false
    : true;
};

export const importTreeChartNode =
  (context: ImporterContext) =>
  /* eslint-disable-next-line complexity*/
  (xml: FElement): TreeNode => {
    const mrow = xml.findChildTag('mrow');
    const attribs = parseCommonAttribs(xml);
    const $id = attribs.$id;

    const childrenXML = mrow.findChildTag('children').getChildrenByTagName(SEMANTICS);
    const nodeXml = mrow.findChildTag('node').findChildTag(SEMANTICS);
    const edgeXml = mrow.findChildTag('edge').findChildTag(SEMANTICS);
    const edgeAttribs = parseCommonAttribs(edgeXml);
    const edgeMrowXml = edgeXml.findChildTag('mrow');
    const edgeProbXML = edgeMrowXml.findChildTag('probability');
    const edgeProbContent = edgeProbXML.exists
      ? importContent(edgeProbXML.findChildTag(SEMANTICS), context)
      : undefined;

    const edgeDeco = edgeDecoration(edgeMrowXml);
    const edge = {
      edge: {
        $id: edgeAttribs.$id || `EMPTY_${$id}`,
        ...(!isEmpty(edgeDeco) ? { decoration: edgeDeco } : {}),
        ...(edgeProbContent
          ? {
              probability: edgeProbContent,
              probabilityBorder: hasBorder(edgeProbContent, context),
            }
          : {
              invisibleNode: true,
            }),
      },
    };

    const resultTupleXML = mrow.findChildTag('resultTuple');
    const resultTupleContent = resultTupleXML.exists
      ? importContent(resultTupleXML.findChildTag(SEMANTICS), context)
      : undefined;
    const resultTupleDeco = edgeDecoration(resultTupleXML);
    const resultProbXML = mrow.findChildTag('resultProbability');
    const resultProbContent = resultProbXML.exists
      ? importContent(resultProbXML.findChildTag(SEMANTICS), context)
      : undefined;
    const resultProbDeco = edgeDecoration(resultProbXML);

    const type = typeFromString(attribs.$renderStyle);
    const shape = parseNode(context)(nodeXml);
    const children = childrenXML.map(importTreeChartNode(context));
    const result: TreeChartNode = {
      $id,
      type: TreeChartNodeType.node,
      shape,
      children,
      ...edge,
    };
    return type === TreeChartNodeType.node
      ? result
      : {
          ...result,
          type: TreeChartNodeType.leaf,
          ...(resultTupleContent
            ? {
                resultTuple: resultTupleContent,
                resultTupleBorder: hasBorder(resultTupleContent, context),
                ...(!isEmpty(resultTupleDeco) ? { resultTupleDecoration: resultTupleDeco } : {}),
              }
            : {}),
          ...(resultProbContent
            ? {
                resultProbability: resultProbContent,
                resultProbabilityBorder: hasBorder(resultProbContent, context),
                ...(!isEmpty(resultProbDeco)
                  ? { resultProbabilityDecoration: resultProbDeco }
                  : {}),
              }
            : {}),
        };
  };

const toTreeChartDirection = (xml: FElement): TreeChartDirection => {
  switch (xml.text.trim()) {
    case 'vertical':
      return TreeChartDirection.vertical;
    case 'horizontal':
      return TreeChartDirection.horizontal;
    default:
      return TreeChartDirection.vertical;
  }
};

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * color is optional in the XML, default value is `gray` (using [[TREE_CHART_GIZMO_DEFAULT_CONTENT]]
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 *
 * @returns The metadata and parsed xml as `Content`
 */
export const importTreeChartGizmo: Importer<TreeChartContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const configuration = mrow.findChildTag(CONFIGURATION);
  const children = mrow.findChildTag('children').getChildrenByTagName(SEMANTICS);

  const { edgeCorners } = configuration.tagsToProps(xmlTextToBoolean, [], ['edgeCorners']);
  const { direction } = configuration.tagsToProps(toTreeChartDirection, [], ['direction']);

  const tree: TreeNode = {
    type: TreeChartNodeType.node,
    children: children.map(importTreeChartNode(context)),
  };
  const layoutTree = createLayoutTree(tree);

  const hasGizmoNodes = !!flattenTree(layoutTree).find((n) => n.type === NodeType.gizmo);

  return {
    ...preContent,
    ...TREE_CHART_GIZMO_DEFAULT_CONTENT,
    edgeCorners,
    direction,
    layoutTree,
    graph: graph(direction, layoutTree),
    tree,
    visible: !hasGizmoNodes,
  };
};
