import * as React from 'react';
import * as ReactResponsive from 'react-responsive';

const enum Breakpoint {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}

export const RESPONSIVE_BREAKPOINT_SETTINGS_DICTIONARY: Readonly<
  Record<Breakpoint, ReactResponsive.MediaQueryAllQueryable>
> = {
  DESKTOP: { minWidth: 992 },
  TABLET: { minWidth: 768, maxWidth: 991 },
  MOBILE: { maxWidth: 767 },
};

export const useDesktopMediaQuery = (
  mediaQueryOnChangeCallBack?: (matches: boolean) => void
): boolean =>
  ReactResponsive.useMediaQuery(
    RESPONSIVE_BREAKPOINT_SETTINGS_DICTIONARY[Breakpoint.DESKTOP],
    undefined,
    mediaQueryOnChangeCallBack
  );

export const useTabletMediaQuery = (
  mediaQueryOnChangeCallBack?: (matches: boolean) => void
): boolean =>
  ReactResponsive.useMediaQuery(
    RESPONSIVE_BREAKPOINT_SETTINGS_DICTIONARY[Breakpoint.TABLET],
    undefined,
    mediaQueryOnChangeCallBack
  );

export const useMobileMediaQuery = (
  mediaQueryOnChangeCallBack?: (matches: boolean) => void
): boolean =>
  ReactResponsive.useMediaQuery(
    RESPONSIVE_BREAKPOINT_SETTINGS_DICTIONARY[Breakpoint.MOBILE],
    undefined,
    mediaQueryOnChangeCallBack
  );

export const Desktop: React.FC = ({ children }) => {
  const isDesktop = useDesktopMediaQuery();
  return isDesktop ? <>{children}</> : null;
};

export const Tablet: React.FC = ({ children }) => {
  const isTablet = useTabletMediaQuery();
  return isTablet ? <>{children}</> : null;
};

export const TabletAndBelow: React.FC = ({ children }) => {
  const isTablet = useTabletMediaQuery();
  const isMobile = useMobileMediaQuery();
  return isTablet || isMobile ? <>{children}</> : null;
};

export const Mobile: React.FC = ({ children }) => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? <>{children}</> : null;
};
