import * as React from 'react';

import {
  BASE,
  type Interactable,
  type MRow,
  type MSub as MSubContent,
} from '@bettermarks/gizmo-types';
import { type EnrichMathContent, type EnrichNestedMathContent } from '../../measure/measureStatic';

import { BackgroundColor } from '../BackgroundColor';
import { Bucket } from '../Bucket';
import { enrich, onClickBetweenChildren } from '../helpers';
import { type MPropsWithChildren } from '../types';

import { getScriptMetric, getSubScriptOverlap } from './getScriptMetric';
import { type ScriptMetric, type ScriptProps } from './types';

import styles from './MSub.scss';

export type MSubProps = MPropsWithChildren &
  Interactable &
  ScriptProps & {
    metric: ScriptMetric;
  };

/**
 * MSub: The MathML <msub> element is used to attach an accent or a limit
 * over an expression. It uses the following syntax:
 * <msub>
 *    base
 *    subscript
 * </msub>
 */
export const MSub: React.FC<MSubProps> = ({
  baseHeight,
  children: [base, subscript],
  computedStyles: { backgroundColor },
  interactive,
  lowerFontSize,
  metric: { spacing, shift, scriptHeight },
  onSelect,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <span
      className={styles.msubWrapper}
      onClick={onClickBetweenChildren(onSelect)}
      role={onSelect && 'button'}
    >
      <Bucket height={baseHeight} textAlign={interactive ? 'right' : undefined}>
        {base}
      </Bucket>
      <div
        className={styles.msubscript}
        style={{
          marginTop: shift,
          fontSize: lowerFontSize,
          marginLeft: spacing,
          textAlign: interactive ? 'left' : undefined,
        }}
      >
        <Bucket height={scriptHeight}>{subscript}</Bucket>
      </div>
    </span>
  </BackgroundColor>
);

MSub.displayName = 'MSub';

export const enrichMSub: EnrichNestedMathContent<MSubContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const {
    enrichedContent: base,
    refLine,
    ...baseLM
  } = mathContentEnricher(formulaStyles, content.base, [...path, BASE]);
  const { enrichedContent: subscript, ...scriptLM } = mathContentEnricher(
    formulaStyles,
    content.subscript,
    [...path, 'subscript']
  );

  const lowerFontSize = subscript.computedStyles.fontSize;

  const { height, ...metric } = getScriptMetric(
    baseLM,
    scriptLM,
    getSubScriptOverlap(formulaStyles)
  );

  const props: MSubContent & Partial<MSubProps> = {
    ...content,
    base,
    lowerFontSize,
    metric,
    subscript,
  };
  return enrich(
    props,
    { height, refLine: metric.offset + refLine, relativeToBaseLine: true },
    formulaStyles
  );
};
