import {
  connect,
  type ConnectedProps,
  type MapStateToProps,
  type MapDispatchToPropsFunction,
} from 'react-redux';
import {
  type ApplicationState,
  type ILoadFilesPayload,
  type ILoadExercisePayload,
  type LoaderState,
} from '../../../../types';

import { resetApp } from '../SeriesPlayer/actions';
import {
  startFromExerciseId,
  startFromSeriesFile,
  setValidation,
  startFromLocalFiles,
  setElementToFocus,
} from './actions';
import { Upload } from './Upload';

type DispatchProps = {
  initialize: () => void;
  setElementToFocus: (elementId: string) => void;
  setValidation: (value: string) => void;
  onStartFromFiles: (payload: ILoadFilesPayload) => void;
  onStartFromId: (payload: ILoadExercisePayload) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = (dispatch) => ({
  setElementToFocus: (elementId: string) => dispatch(setElementToFocus(elementId)),
  setValidation: (value: string) => dispatch(setValidation(value)),
  initialize: () => dispatch(resetApp()),
  onStartFromFiles: (payload: ILoadFilesPayload) => dispatch(startFromLocalFiles(payload)),
  onStartFromId: (payload: ILoadExercisePayload) =>
    payload.seriesFile
      ? dispatch(startFromSeriesFile(payload))
      : dispatch(startFromExerciseId(payload)),
});

type StateProps = {
  exerciseLoaded: boolean;
  loaderState: LoaderState;
  staticServerUrl: string;
};
// eslint-disable-next-line @typescript-eslint/ban-types
const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => {
  // XXX: This is still hacky but not as hacky as before. The new heuristic
  // assumes that an empty content dict belongs to an empty (not loaded)
  // exercise. The right way though (to be implemented later) would be, to
  // allow an empty series and an empty step list.
  // Anyhow, changing this requires a lot of work and will be done in the
  // series player story.
  return {
    exerciseLoaded: state.series.exercises.length !== 0,
    loaderState: state.loaderState,
    staticServerUrl: state.appSettings.staticServerUrl,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type UploadContainerReduxProps = ConnectedProps<typeof connector>;
export const UploadContainer = connector(Upload);
