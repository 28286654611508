import * as React from 'react';
import { first, isNil, last, pickBy } from 'lodash';
import classNames from 'classnames';
import {
  type Bezier,
  type BezierGroupObject,
  BezierSubtype,
  CAP_T_ARROW,
  ContentColor,
  type GeoDecoration,
  GeoEditorMode,
  type GeoLineDecoration as LineDecoration,
  type Hover,
  isLineCapProp,
  type LineCapStyle,
  LineCapStyleToMarkerType,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import {
  add,
  CAP_ARROW_REF_PIXEL,
  DEFAULT_BEZIER_DECORATION,
  DEFAULT_FILL_TRANSPARENCY,
  getNamedColor,
  getStrokeWidth,
  norm,
  smult,
  sub,
  transformX,
  transformY,
} from '@bettermarks/importers';

import styles from './Beziers.scss';
import { getLineStyle } from './decorations';
import { evaluateValueSetterExpression, Severity } from '@bettermarks/umc-kotlin';
import { Marker } from '../../components';
import { getHoverColor } from '../tools/helpers';
import { useValueSetterContext, type ValueSetterMap } from '../../../gizmo-utils/polymorphic-gizmo';
import { valueSetterMapToValidatorValueMap } from '../../formula/Formula/helper';
import { type StringOrNumber } from '@bettermarks/gizmo-types';

export type BezierCallbacks = {
  onHover?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onMouseDown?: (id: string) => (evt: MouseOrTouch) => void;
  onMouseOut?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
};

export type BeziersProps = {
  matrix: number[];
  geoId: string;
  id: string;
  beziers: DeepImmutableArray<Bezier>;
  mode: GeoEditorMode;
} & Partial<BezierGroupObject> &
  BezierCallbacks;

export interface BezierProps extends Bezier, BezierCallbacks {
  geoId: string;
  id: string;
  bezierId: string;
  decoration: LineDecoration;
  mode: GeoEditorMode;
  severity?: Severity;
  hover?: Hover;
  closed?: true;
}

const pathFromPoints = (points: [number, number][], closed: boolean): string =>
  points
    .reduce((acc, p, i) => {
      if (i === 0) {
        return `M${p.toString()}`;
      } else if (i % 2 === 1) {
        return `${acc} Q${p.toString()}`;
      } else if (i % 2 === 0 && i !== 0) {
        return `${acc} ${p.toString()}`;
      } else {
        return acc;
      }
    }, '')
    .concat(closed ? ' Z' : '');

export const shortenForCap = (
  points: [number, number][],
  end: string,
  markerW = CAP_ARROW_REF_PIXEL
) => {
  const [p1, p2, p3, ...rest] = end === 'top' ? points : points.reverse();

  const [P1, P2, P3] = [p1, p2, p3].map((p) => ({ x: p[0], y: p[1] }));

  const t = markerW / norm(P1, P2);

  const newP1 = add(P1, smult(t, sub(P1, P2)));
  const newP2 = add(P2, smult(t, sub(P2, P3)));

  const result: [number, number][] = [[newP1.x, newP1.y], [newP2.x, newP2.y], p3, ...rest];

  return end === 'top' ? result : result.reverse();
};

const keepLineCapProp = (
  key: string,
  beziers: DeepImmutableArray<Bezier>,
  b: DeepImmutableObject<Bezier>,
  outerDeco: LineDecoration
) =>
  beziers.length === 1 ||
  (key === 'lineCapStyleTop' &&
    ((b === first(beziers) && outerDeco.hasOwnProperty('lineCapStyleTop')) ||
      (!isNil(b.decoration) && b.decoration.hasOwnProperty('lineCapStyleTop')))) ||
  (key === 'lineCapStyleBottom' &&
    ((b === last(beziers) && outerDeco.hasOwnProperty('lineCapStyleBottom')) ||
      (!isNil(b.decoration) && b.decoration.hasOwnProperty('lineCapStyleBottom'))));

export const getBezierAreaColor = (
  severity: Severity | undefined,
  decoration: GeoDecoration,
  hover: Hover | undefined,
  inColorMode: boolean
) =>
  (hover && getNamedColor(decoration.fillColor, true)) ||
  // for the coloring tool the areas get a red border instead of a red fill color
  (!inColorMode && getNamedColor(severity, true)) ||
  getNamedColor(decoration.marked, true) ||
  getNamedColor(decoration.fillColor, true);

/* eslint-disable complexity */
export const BezierLine: React.FC<BezierProps> = ({
  points,
  decoration,
  closed,
  geoId,
  bezierId,
  id,
  severity,
  hover,
  onMouseDown,
  onMouseOut,
  onHover,
  onClick,
}) => {
  const bezierDeco = {
    ...decoration,
    ...(decoration.marked && !closed && { color: getNamedColor(decoration.marked, closed) }),
    ...(severity &&
      !(decoration.marked && closed) && {
        color: getNamedColor(severity, closed),
      }),
    ...getHoverColor(hover),
  };
  const strokeWidth = getStrokeWidth(bezierDeco, hover);
  const color = getNamedColor(bezierDeco.color);

  const { lineCapStyleTop, lineCapStyleBottom } = decoration;
  const [mIdTop, mIdBot] = [lineCapStyleTop, lineCapStyleBottom].map((d: LineCapStyle, i) =>
    d ? `marker-${d}-${i}-${geoId}-${id}-${bezierId}` : ''
  );

  let newPoints;
  newPoints =
    lineCapStyleTop && lineCapStyleTop !== CAP_T_ARROW ? shortenForCap(points, 'top') : points;
  newPoints =
    lineCapStyleBottom && lineCapStyleBottom !== CAP_T_ARROW
      ? shortenForCap(newPoints, 'bottom')
      : newPoints;

  const path = pathFromPoints(newPoints, !!closed);

  const isInteractive = onHover || onMouseOut || onMouseDown || onClick;

  return (
    <g>
      {lineCapStyleTop && (
        <Marker
          id={mIdTop}
          type={LineCapStyleToMarkerType[lineCapStyleTop]}
          color={color}
          flip={true}
          strokeWidth={strokeWidth}
          offset={CAP_ARROW_REF_PIXEL}
        />
      )}
      {lineCapStyleBottom && (
        <Marker
          id={mIdBot}
          type={LineCapStyleToMarkerType[lineCapStyleBottom]}
          color={color}
          strokeWidth={strokeWidth}
          offset={CAP_ARROW_REF_PIXEL}
        />
      )}
      <path
        className={styles.bezierLine}
        style={{
          stroke: color,
          strokeWidth,
          strokeDasharray: getLineStyle(decoration.lineStyle, strokeWidth),
        }}
        d={path}
        markerStart={`url(#${mIdTop})`}
        markerEnd={`url(#${mIdBot})`}
      />
      {isInteractive && (
        <path
          className={styles.bezierLineInteractive}
          d={path}
          onClick={onClick && onClick(id)}
          onMouseDown={onMouseDown && onMouseDown(id)}
          onTouchStart={onMouseDown && onMouseDown(id)}
          onMouseOver={onHover && onHover(id)}
          onMouseOut={onMouseOut && onMouseOut(id)}
        />
      )}
    </g>
  );
};

BezierLine.displayName = 'BezierLine';

/* eslint-disable complexity */
export const BezierArea: React.FC<BezierProps> = ({
  points,
  hitAreaPoints,
  decoration,
  severity,
  hover,
  bezierId,
  id,
  mode,
  onMouseDown,
  onMouseOut,
  onHover,
  onClick,
}) => {
  const inColorMode = mode.startsWith(GeoEditorMode.COLORING);
  const color = getBezierAreaColor(severity, decoration, hover, inColorMode);

  const opacity =
    severity && !inColorMode ? DEFAULT_FILL_TRANSPARENCY : decoration.fillTransparency;

  const path = pathFromPoints(points, true);
  const hitAreaPath = hitAreaPoints ? pathFromPoints(hitAreaPoints, true) : undefined;
  const severityClipPathID = `severity_border_clipping_${bezierId}`;

  const severeInColoring = inColorMode && severity;
  return (
    <>
      {severeInColoring && (
        <defs>
          <clipPath id={severityClipPathID}>
            <path d={path} />
          </clipPath>
        </defs>
      )}
      <path
        className={classNames(styles.bezierArea, {
          [styles.bezierAreaSeverity]: severity && inColorMode,
        })}
        style={{
          // white border "inside" severity border
          stroke: severeInColoring ? 'white' : color,
          strokeOpacity: severeInColoring ? 1 : opacity,
          fill: color,
          opacity,
          ...(severeInColoring && { clipPath: `url(#${severityClipPathID})` }),
          ...(decoration.filter && { filter: decoration.filter }),
        }}
        d={path}
        onMouseDown={isNil(hitAreaPath) ? onMouseDown && onMouseDown(id) : undefined}
        onTouchStart={isNil(hitAreaPath) ? onMouseDown && onMouseDown(id) : undefined}
        onMouseOver={isNil(hitAreaPath) ? onHover && onHover(id) : undefined}
        onMouseOut={isNil(hitAreaPath) ? onMouseOut && onMouseOut(id) : undefined}
        onClick={isNil(hitAreaPath) ? onClick && onClick(id) : undefined}
      />
      {hitAreaPath && (
        <path
          style={{
            fill: 'transparent',
          }}
          d={hitAreaPath}
          onMouseDown={onMouseDown && onMouseDown(id)}
          onTouchStart={onMouseDown && onMouseDown(id)}
          onMouseOver={onHover && onHover(id)}
          onMouseOut={onMouseOut && onMouseOut(id)}
          onClick={onClick && onClick(id)}
        />
      )}
      {
        // drawing the bezier area with a huge error/remark border on top
        // (clipped -> border only visible inside)
        severeInColoring && (
          <path
            className={classNames({
              [styles.bezierAreaError]: severity === Severity.error,
              [styles.bezierAreaRemark]: severity === Severity.remark,
            })}
            style={{
              clipPath: `url(#${severityClipPathID})`,
            }}
            d={path}
          />
        )
      }
    </>
  );
};

BezierArea.displayName = 'BezierArea';

const evaluateIfNeeded = (coordinate: StringOrNumber, valueSetterMap: ValueSetterMap) => {
  if (typeof coordinate === 'number') {
    return coordinate;
  }
  return evaluateValueSetterExpression({
    expression: coordinate,
    valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
  });
};

export const convertDynamicCoordinatesToNumbersIfNeeded = (
  points: DeepImmutableArray<[StringOrNumber, StringOrNumber]>,
  valueSetterMap: ValueSetterMap
) => points.map((point) => point.map((coordinate) => evaluateIfNeeded(coordinate, valueSetterMap)));

export const Beziers: React.FC<BeziersProps> = ({
  matrix,
  geoId,
  id,
  subtype,
  beziers,
  decoration,
  dynamicDecoration,
  severity,
  hover,
  translation,
  selectable,
  interactionType,
  mode,
  onMouseDown,
  onMouseOut,
  onHover,
  onClick,
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  const { valueSetterMap } = useValueSetterContext();

  // See https://bettermarks.atlassian.net/browse/BM-59597
  const disableBlackBordersIfNeeded = dynamicDecoration
    ? { color: ContentColor.BM_TRANSPARENT }
    : {};
  const deco = { ...DEFAULT_BEZIER_DECORATION, ...disableBlackBordersIfNeeded, ...decoration };

  const beziersOut = beziers.map((b, i) => {
    const key = `${id}:${i}`;

    const decoration = pickBy(
      {
        ...deco,
        ...b.decoration,
      },
      (_, k) => !isLineCapProp(k) || keepLineCapProp(k, beziers, b, deco)
    );

    const evaluatedPoints = b.dynamicPoints
      ? convertDynamicCoordinatesToNumbersIfNeeded(b.dynamicPoints, valueSetterMap)
      : b.points;

    const props = {
      geoId,
      matrix,
      mode,
      severity,
      points: evaluatedPoints.map((p) => [sx(p[0]), sy(p[1])]) as [number, number][],
      ...(b.hitAreaPoints && {
        hitAreaPoints: b.hitAreaPoints.map((p) => [sx(p[0]), sy(p[1])]) as [number, number][],
      }),
      decoration,
      id,
      bezierId: key,
    };

    switch (subtype) {
      case BezierSubtype.lines:
        return (
          <BezierLine
            key={key}
            {...props}
            hover={hover}
            {...{
              ...((translation || selectable) && {
                onMouseDown,
                onClick,
                onMouseOut,
                onHover,
              }),
            }}
          />
        );
      case BezierSubtype.areas:
        return (
          <g key={key}>
            <BezierArea
              {...props}
              hover={hover}
              {...{
                ...((translation || selectable || interactionType) && {
                  onMouseDown,
                  onClick,
                  onMouseOut,
                  onHover,
                }),
              }}
            />
            {((b.decoration && b.decoration.hasOwnProperty('color')) ||
              (decoration && decoration.hasOwnProperty('color'))) && (
              <BezierLine {...props} closed />
            )}
          </g>
        );
      default:
        return '';
    }
  });
  return translation ? (
    <g transform={`translate(${translation.x * matrix[0]},${translation.y * matrix[3]})`}>
      {beziersOut}
    </g>
  ) : (
    <>{beziersOut}</>
  );
};

Beziers.displayName = 'Beziers';
