import {
  DECORATION,
  type Importer,
  POINTER_HEIGHT,
  POINTER_PEAKWIDTH,
  POINTER_TAG,
  POINTER_WIDTH,
  type PointerContent,
} from '@bettermarks/gizmo-types';
import { defaultTo } from 'lodash';
import { parseDecoration } from './parseDecoration';

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * The default color value is `bm-black` (using [[POINTER_GIZMO_DEFAULT_CONTENT]]
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @returns The metadata and parsed xml as `Content`
 */
export const importPointer: Importer<PointerContent> = (preContent, xml, context) => {
  const pointer = xml.findChildTag(POINTER_TAG);
  const decoration = parseDecoration(pointer.attribute(DECORATION));
  const height = defaultTo<number>(parseFloat(pointer.attribute(POINTER_HEIGHT)), 0);
  const peakWidth = defaultTo<number>(parseFloat(pointer.attribute(POINTER_PEAKWIDTH)), 0);
  const width = defaultTo<number>(parseFloat(pointer.attribute(POINTER_WIDTH)), 27);

  return {
    ...preContent,
    /**
     * uniqueId for the markers (assumption: there are no duplicate $refids per exercise)
     */
    uniqueId: context.$refid,
    height,
    peakWidth,
    strokeWidth: 1,
    width,
    decoration,
  };
};
