import defaultTheme from '../../themes/defaultTheme';
import { numberFromStyles } from '../../utils/numberFromStyles';

export const POPUP_S = '300px';
export const POPUP_L = '565px';
export const TOOLTIP_WIDTH = '320px';
export const POPUP_MARGIN = defaultTheme.dimensions.spaceM;
export const POPUP_MARGIN_NUM = numberFromStyles(POPUP_MARGIN);
export const POPUP_STILL_MARGIN = '10px';
export const NOSE_BORDER = '8px';
export const NOSE_NEGATIVE_BORDER = '-8px';
