import * as React from 'react';
import { Bar, defaultTheme } from '@seriesplayer/common-ui';
import { BaseLayout, DialogHeader, plainText } from '../../../../../components';
import { ProblemReportedState } from '../../../../../types';
import { getBackToNsp } from '../../SeriesPlayer/quitSeriesPlayerSaga';
import { type ReportProblemPayload } from './types';
import { ContentProvider } from './components/ContentProvider';

import styled from 'styled-components';
import { numberFromStyles } from '@bettermarks/gizmo-types';
import { Loader } from '../../../components/Loader';
import { useMaybeWhiteboardContext } from '../../../../whiteboard/whiteboard-context';

export interface ReportProblemDispatchProps {
  onCancel: () => void;
  onSubmit: (data: ReportProblemPayload) => void;
}

export interface ReportProblemStateProps {
  reportState?: ProblemReportedState;
}

export interface ReportProblemOwnProps {
  title: string;
}

export type ReportProblemProps = ReportProblemStateProps &
  ReportProblemDispatchProps &
  ReportProblemOwnProps;

const Layout = styled(BaseLayout)`
  .boldtext {
    font-weight: bold;
    ${plainText}
  }

  .text-input {
    ${plainText}

    width: 100%;
    padding: ${defaultTheme.dimensions.spaceXs};
    border: ${defaultTheme.dimensions.borderWidthS} solid ${defaultTheme.colors.cAreaBorder};
    box-sizing: border-box;
    resize: none;
    min-height: ${4 * numberFromStyles(defaultTheme.dimensions.lineHeightXl)}px;
  }
`;

export function ReportProblem({
  onCancel: _onCancel,
  onSubmit,
  reportState,
  title,
}: ReportProblemProps) {
  const whiteboardContext = useMaybeWhiteboardContext();
  function onCancel() {
    if (whiteboardContext !== undefined) {
      whiteboardContext.dispatch({ type: 'closeDialog' });
    }
    _onCancel();
  }
  return (
    <Layout vertical heightAuto>
      <DialogHeader
        title={title}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
          dataCy: 'back-arrow-dialog-header-btn',
        }}
      />
      <Bar />
      <Loader loaded={reportState !== ProblemReportedState.progress} onQuit={getBackToNsp} />
      <ContentProvider onCancel={onCancel} onSubmit={onSubmit} reportState={reportState} />
    </Layout>
  );
}
ReportProblem.displayName = 'ReportProblem';
