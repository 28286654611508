import * as React from 'react';
import { INVISIBLE_NODE, TOUCH_OFFSET, NODE_RADIUS } from '@bettermarks/importers';
import { type GraphRendererProps, GraphRenderer } from '../GraphRenderer';
import {
  type GraphNode,
  type Position,
  type GraphEdge,
  type BubbleNode,
} from '@bettermarks/gizmo-types';
import { compact, throttle } from 'lodash/fp';
import { edgeStart, getWorldPos, pickGraphObject, useDOMEvent, onPickEvent } from '../helpers';
import { withHoverHighlight } from './withHoverHighlight';
import { Maybe } from '../../../../utils/maybe';
import {
  startScrolling,
  stopScrolling,
  type DragScrollableProps,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export type ConnectNodeProps = GraphRendererProps &
  DragScrollableProps & {
    onConnectNode?: (sourceId: string, targetId: string) => void;
  };

/**
 * Check if the given edge connects both nodes. Use it with filter!
 */
const isEdgeOf =
  (node1: GraphNode, node2: GraphNode) =>
  (edge: GraphEdge): boolean =>
    (node1.id === edge.nodeId1 && node2.id === edge.nodeId2) ||
    (node2.id === edge.nodeId1 && node1.id === edge.nodeId2);

/**
 * ConnectNode: Graph editor mode for connecting nodes with edges. The user can start a drag on a
 * node and while dragging a preview edge will apprear. It will connect, if she ends the drag on
 * another node. It dissapears if she ends the drag somewhere else.
 *
 * The preview edge is drawn like all other edges. The only difference is that the target node is
 * invisible, thus given the illusion of the edge connecting to the mouse cursor or the finger.
 *
 */
const ConnectNode_: React.FC<ConnectNodeProps> = (props) => {
  const { edges, onConnectNode, nodes, scrollBehaviour } = props;

  const ref = React.useRef<HTMLSpanElement>(null);
  /**
   * The node where we started the drag
   */
  const [sourceNode, setSourceNode] = React.useState<GraphNode | null>(null);
  /**
   * The node under the mouse cursor that is connected with the source node.
   */
  const [preview, setPreview] = React.useState<GraphNode | null>(null);
  const maybeScrollBehaviour = Maybe(scrollBehaviour);

  /**
   * Called when the connect operation ends. If we pick a node, we dispatch the connect action.
   * Otherwise we do nothing end just remove the preview edge.
   */
  const onUp = (x: number, y: number) => {
    maybeScrollBehaviour.ap(stopScrolling);

    const pos = getWorldPos(x, y, ref);
    const picked = pickGraphObject(pos, 2 * NODE_RADIUS, edges, nodes);
    if (
      sourceNode &&
      picked.node &&
      sourceNode.id &&
      picked.node.id &&
      edges.find(isEdgeOf(picked.node, sourceNode)) === undefined &&
      picked.node.id !== sourceNode.id
    ) {
      onConnectNode && onConnectNode(sourceNode.id, picked.node.id);
    }
    setSourceNode(null);
    setPreview(null);
  };

  /**
   * Set the (invisible) preview node (no action dispatched).
   */
  const onMove = (position: Position) => {
    const wp = getWorldPos(position.x, position.y, ref);

    /**
     These cases for preview have to be handled:
     - case1: Mouse is within start node -> no preview edge
     - case2: Mouse is within end Node -> 'snap preview' to end node
     - case3: all other cases: -> preview to current mousepos.
     **/
    // snapping 'experience' area  with 2 * radius feels good!
    const picked = pickGraphObject(wp, 2 * NODE_RADIUS, edges, nodes);
    let previewNode: BubbleNode | null = null;
    if (!(picked && picked.node && sourceNode !== null && picked.node.id === sourceNode.id)) {
      const prevPos = sourceNode && picked && picked.node ? edgeStart(picked.node, sourceNode) : wp;
      previewNode = {
        ...INVISIBLE_NODE,
        position: prevPos,
      };
    }
    setPreview(previewNode);
  };

  /*--------------------------------------------------------------------------------*
   *                              Event Handlers                                    *
   *--------------------------------------------------------------------------------*/

  const onTouchEnd = (e: TouchEvent) =>
    onUp(
      e.changedTouches[0].clientX + TOUCH_OFFSET.x,
      e.changedTouches[0].clientY + TOUCH_OFFSET.y
    );

  const onMouseMove: React.MouseEventHandler<any> = (evt) => {
    if (sourceNode) {
      onMove({ x: evt.clientX, y: evt.clientY });
    }
  };

  const onTouchMove = throttle(100, (evt: TouchEvent) => {
    if (sourceNode) {
      onMove({
        x: evt.changedTouches[0].clientX + TOUCH_OFFSET.x,
        y: evt.changedTouches[0].clientY + TOUCH_OFFSET.y,
      });
    }
  });

  const onNodeStart = (node: GraphNode) => {
    setSourceNode(node);
    maybeScrollBehaviour.ap(startScrolling);
  };

  useDOMEvent(
    'touchstart',
    onPickEvent<TouchEvent>(ref, edges, nodes, onNodeStart, undefined, undefined, false),
    ref,
    [nodes]
  );
  useDOMEvent('touchend', onTouchEnd, ref, [sourceNode]);
  useDOMEvent('touchmove', onTouchMove, ref, [sourceNode]);

  /*--------------------------------------------------------------------------------*
   *                         Transform Edges and Nodes                              *
   *--------------------------------------------------------------------------------*/

  // create an additional preview edge if necessary
  const previewEdge: GraphEdge | undefined =
    preview && sourceNode && preview.id && sourceNode.id
      ? {
          nodeId1: sourceNode.id,
          nodeId2: preview.id,
          highlight: true,
        }
      : undefined;
  // remove the source node from the other nodes, so we can highlight it (see below);
  const otherNodes = nodes.filter((n) => !(sourceNode && n.id === sourceNode.id));

  return (
    <span
      ref={ref}
      role="button"
      onMouseUp={(e) => onUp(e.clientX, e.clientY)}
      onMouseMove={onMouseMove}
      onMouseDown={onPickEvent(ref, edges, nodes, onNodeStart)}
    >
      <GraphRenderer
        {...props}
        // the new nodes including highlighted source node
        nodes={compact([...otherNodes, preview, { ...(sourceNode as GraphNode), highlight: true }])}
        edges={compact([...edges, previewEdge])}
        scrollBehaviour={scrollBehaviour}
      />
    </span>
  );
};

ConnectNode_.displayName = 'ConnectNode';

export const ConnectNode = withHoverHighlight(ConnectNode_);
