import { SPECIAL } from '../../gizmo-utils/constants';
import { IMAGE } from '../../gizmo-utils/configuration/renderStyles';
import { type Defaults, type ScalableContent } from '../../xml-converter/core';

/**
 * source: image filename.
 * width x height are maximal dimension to display the image
 */
export interface ImageContent extends ScalableContent {
  source: string;
  width: number;
  height: number;
  scalable: true;
  scaleCenter?: {
    x: number;
    y: number;
    pixelCoords: boolean;
  };
  dynamicScale?: string;
  valueSetterRefIdsScale?: string;
}
export const IMAGE_DEFAULT_CONTENT: Defaults<ImageContent> = {
  $: SPECIAL,
  $renderStyle: IMAGE,
  source: '',
  width: 0,
  height: 0,
  scalable: true,
};
