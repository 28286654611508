import * as React from 'react';
import { type Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { ApplicationState, type CalculatorState, type RuntimeState } from '../../../../../../types';
import { useContentTranslation } from '../../../../../../gizmo-utils/polymorphic-gizmo';
import { gizmoAction } from '../../../../../../gizmo-utils/redux';
import { type FormulaContent, ShouldEnrichKind } from '@bettermarks/gizmo-types';
import { enterMRow, setCursor } from '../../../../../../gizmos/formula/Formula/actions';

import { type KeyCode } from '../Keyboard/keyCodes';

import { Calculator } from './Calculator';
import { initialCalculatorState } from './reducer';
import { keyCodeToAction } from './keyCodeToAction';

const keyDispatcher = (dispatch: Dispatch, afterEval: boolean) => (key: KeyCode) => {
  if (key in keyCodeToAction) {
    const actionTuple = keyCodeToAction[key];
    if (actionTuple) {
      const [action, actionsAfterEval] = actionTuple;
      if (!afterEval) {
        dispatch(action);
      } else {
        actionsAfterEval.forEach(dispatch);
      }
    }
  }
};

const insertDispatcher =
  (dispatch: Dispatch, resultFormula?: FormulaContent, gizmoId?: string) => () => {
    if (resultFormula && gizmoId) {
      dispatch(
        gizmoAction(enterMRow(resultFormula.content), gizmoId, {
          shouldEnrich: ShouldEnrichKind.justEnrich,
        })
      );
    }
  };

type Selected = Pick<CalculatorState, 'inputFormula' | 'resultFormula' | 'afterEval' | 'error'> &
  Pick<RuntimeState, 'availableWidth'> & {
    // refid of the formula gizmo that will receive the result of the calculator,
    // once the user clicks on "insert"
    gizmoId?: string;
  };

const selector = (state: ApplicationState): Selected => {
  const { inputFormula, resultFormula, afterEval, error } =
    state.calculatorState || initialCalculatorState;
  return {
    inputFormula,
    resultFormula,
    afterEval,
    error,
    // on the '/calculator' route we do not have a current exercise
    gizmoId: ApplicationState.toCurrentExercise.get(state)
      ? ApplicationState.toSelectedRefId(state).get(state)
      : undefined,
    availableWidth: state.runtimeState.availableWidth,
  };
};

export const CalculatorContainer: React.FC = () => {
  const { inputFormula, resultFormula, gizmoId, availableWidth, afterEval, error } = useSelector<
    ApplicationState,
    Selected
  >(selector);

  const dispatch = useDispatch();
  const onKey = React.useCallback(keyDispatcher(dispatch, afterEval), [dispatch, afterEval]);
  const onSelect = React.useCallback(
    (path, mode) => dispatch(setCursor({ path, mode })),
    [dispatch]
  );
  const onInsert = React.useCallback(insertDispatcher(dispatch, resultFormula, gizmoId), [
    dispatch,
    resultFormula,
    gizmoId,
  ]);

  const t = useContentTranslation();

  return (
    <Calculator
      input={inputFormula}
      result={resultFormula}
      gizmoId={gizmoId}
      availableWidth={availableWidth}
      onKey={onKey}
      onInsert={resultFormula && gizmoId && isEmpty(error) ? onInsert : undefined}
      onSelect={onSelect}
      t={t}
    />
  );
};
