import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, Button, ButtonKind, ButtonStretch, Layout } from '@seriesplayer/common-ui';
import { DialogContent, DialogFooter, DialogHeader } from '../../../../../components';

export interface RetryConnectProps {
  onRetry: () => void;
  onCancel: () => void;
  showCloseBtn: boolean;
}

export const Retry: React.FC<RetryConnectProps> = ({ showCloseBtn, onRetry, onCancel }) => {
  const [t] = useTranslation();
  return (
    <Layout vertical>
      <DialogHeader
        title={'Retry'}
        leftButton={
          showCloseBtn
            ? {
                icon: 'ArrowLeftLargeBold',
                onClick: onCancel,
              }
            : undefined
        }
      />
      <Bar />
      <DialogContent>
        <p>{t('seriesplayer:dialog.retry.title')}</p>
        <DialogFooter align="center">
          <Button
            key="submitincomplete"
            dataCy="lost-connection-retry-btn"
            kind={ButtonKind.action}
            stretch={ButtonStretch.responsive}
            onClick={onRetry}
          >
            {t('seriesplayer:dialog.retry.retry')}
          </Button>
          {showCloseBtn && (
            <Button
              key="unquit"
              dataCy="lost-connection-cancel-btn"
              kind={ButtonKind.view}
              stretch={ButtonStretch.responsive}
              onClick={onCancel}
            >
              {t('seriesplayer:dialog.retry.cancel')}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Layout>
  );
};

Retry.displayName = 'Retry';
