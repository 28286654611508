import { type ErrorReason } from '../apps/seriesplayer/services/exception';

export const enum DialogType {
  closeConfirmation = 'closeConfirmation',
  closeConfirmationAfterDueDate = 'closeConfirmationAfterDueDate',
  emptyUserInput = 'emptyUserInput',
  exitWhiteboardModeConfirmation = 'exitWhiteboardModeConfirmation',
  dataLossPrevention = 'dataLossPrevention',
  endscreen = 'endscreen',
  enterInputConfirmation = 'enterInputConfirmation',
  error = 'error',
  exerciseNavigation = 'exerciseNavigation',
  exerciseOverview = 'exerciseOverview',
  fem = 'fem',
  handInConfirmation = 'handInConfirmation',
  help = 'help',
  hints = 'hints',
  none = 'none',
  notification = 'notification',
  reportProblem = 'reportProblem',
  resetWhiteboardConfirmation = 'resetWhiteboardConfirmation',
  retry = 'retry',
  submitIncompleteConfirmation = 'submitIncompleteConfirmation',
  textbook = 'textbook',
  whiteboardHelpMenu = 'whiteboardHelpMenu',
}
export type SimpleDialogTypes = Exclude<
  DialogType,
  DialogType.submitIncompleteConfirmation | DialogType.error | DialogType.endscreen | DialogType.fem
>;

export type BackButtonDialogs = DialogType.emptyUserInput | DialogType.closeConfirmation;

export type ConfirmSubmitIncompletePayload = {
  backButtonDialog: BackButtonDialogs;
};

export type EndscreenPayload = {
  animate: boolean;
};

export const enum ErrorHandleKind {
  back = 'back',
  confirm = 'confirm',
  quit = 'quit',
}

export type ErrorPayload = {
  kind: ErrorHandleKind;
  reasonCode?: Nullable<ErrorReason>;
  description?: string;
};

export type DialogPayload = EndscreenPayload | ErrorPayload | ConfirmSubmitIncompletePayload;

export const enum LightBoxType {
  none = 'none',
}
