import {
  type BubbleNode,
  exportContent,
  type Exporter,
  type ExporterContext,
  type GizmoNode,
  type Graph,
  type GraphEdge,
  type GraphNode,
  isBubbleNode,
  NodeType,
  placeholder,
  Position,
  semantics,
  toXmlElement,
} from '@bettermarks/gizmo-types';
import { decorationAttribute } from '../../../gizmo-utils/decoration';
import { identity, pickBy } from 'lodash';
import { GRAPH_MIN_WIDTH } from './constants';

const exportPosition = (position?: Position) =>
  position
    ? `
  <position>
    <x>${position.x}</x>
    <y>${position.y}</y>
  </position>
`
    : '';

const exportRoot = () => '<isRoot>true</isRoot>';

const exportGizmo = (node: GizmoNode, context: ExporterContext) =>
  node.content ? exportContent(node.content.$refid, context) : '';

const exportBubbleContent = ({ content }: BubbleNode) =>
  content
    ? `
  <select type="${content.type}">
    ${(content.options as string[])
      .map((opt) => `<option${content.selected === opt ? ' selected="true"' : ''}>${opt}</option>`)
      .join('')}
  </select>`
    : '<select><option selected="true"/></select>';

const exportShape = (node: GraphNode, context: ExporterContext): string => {
  switch (node.type) {
    case NodeType.gizmo:
      return exportGizmo(node, context);
    case NodeType.bubble:
      return exportBubbleContent(node);
    default:
      return '';
  }
};

const exportBubbleDeco = (node: GraphNode): string =>
  isBubbleNode(node)
    ? decorationAttribute(
        pickBy(
          {
            borderColor: node.borderColor,
            highlightColor: node.highlightColor,
            color: node.color,
            fontWeight: node.fontWeight,
          },
          identity
        )
      )
    : '';

const exportEdgeDeco = (edge: GraphEdge): string =>
  decorationAttribute(
    pickBy(
      {
        lineHighlightColor: edge.highlightColor,
        lineColor: edge.color,
        lineStyle: edge.style,
      },
      identity
    )
  );

export const exportNode =
  (context: ExporterContext, position = true) =>
  (node: GraphNode): string =>
    `<semantics>
    <mrow ${exportBubbleDeco(node)}>
      ${
        position || node.isRoot
          ? `
        <configuration>
          ${position ? exportPosition(node.position) : ''}
          ${node.isRoot ? exportRoot() : ''}
        </configuration>
        `
          : ''
      }
      ${exportShape(node, context)}
    </mrow>
    <annotation-xml encoding="bettermarks">
      <special id="${node.id}" render-style="tree-node"
        ${node.addedByUser ? 'interaction-type="tree-node"' : ''}
      />
    </annotation-xml>
  </semantics>`;

export const exportEdge = (edge: GraphEdge): string =>
  `<semantics>
     <mrow ${exportEdgeDeco(edge)}>
       <configuration>
         <refIds>
           <refId>${edge.nodeId1}</refId>
           <refId>${edge.nodeId2}</refId>
         </refIds>
       </configuration>
     </mrow>
     <annotation-xml encoding="bettermarks">
       <special
          id="${edge.$id}" render-style="graph-edge"
          ${edge.addedByUser ? 'interaction-type="empty"' : ''}
       />
     </annotation-xml>
   </semantics>`;

export const exportTemplate =
  (context: ExporterContext) =>
  (template?: BubbleNode): string => {
    if (!template) return '';

    const templateXml = exportNode(context, false)(template);
    const templateMrow = toXmlElement(templateXml).findChildTag('mrow');

    return `<template type='tree-node'>
    ${templateMrow.toString()}
  </template>`;
  };

export const exportGraph: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as Graph;
  const scale = content.ticksWidth / GRAPH_MIN_WIDTH;

  const result = semantics(
    `<mrow>
      <configuration>
        <ticksWidth>${content.ticksWidth}</ticksWidth>
        <ticksHeight>${content.ticksHeight}</ticksHeight>
        ${exportTemplate(context)(content.template)}
      </configuration>
      ${content.nodes
        .map((n) => ({ ...n, position: Position.scale(n.position, scale) }))
        .map(exportNode(context))
        .join('')}
      ${content.edges.map(exportEdge).join('')}
    </mrow>`,
    placeholder(content)
  );

  return `<math render-style="formula" display="block">${result}</math>`;
};
