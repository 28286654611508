import * as React from 'react';
import classNames from 'classnames';
import styles from './Keyboard.scss';
import { type IconMap, isMultiLangIcon, KEY_ICONS, KeyCode } from './keyCodes';
import { type TFunction } from 'i18next';
import { translateContent } from '../../../../../../gizmo-utils/polymorphic-gizmo';
import { ExcludedFromTabNavigationButton } from '../../../../../../components/ExcludedFromTabNavigation';

type KeyType = 'light' | 'dark' | 'empty' | 'red' | 'very-dark';

export type KeyHandler = (key: string) => void;

export type KeyIconProps = {
  onKey?: KeyHandler;
  type?: KeyType;
  rowspan?: number;
  colspan?: number;
  icons?: IconMap;
};

type KeyProps = {
  keyCode: KeyCode;
  iconProps: KeyIconProps;
  t: TFunction;
};

const _Key: React.FC<KeyProps> = ({ keyCode, iconProps, t }) => {
  const { onKey, type = 'light', rowspan = 1, colspan = 1, icons = KEY_ICONS } = iconProps;

  let iconEl: string | React.ReactElement = '';
  if (keyCode in icons) {
    const icon = icons[keyCode];
    if (isMultiLangIcon(icon)) {
      const translation = t(keyCode);
      if (translation in icon.translate) {
        iconEl = React.createElement(icon.translate[translation]);
      }
    } else {
      iconEl = React.createElement(icon);
    }
  }
  return (
    <ExcludedFromTabNavigationButton
      data-cy={`keyboard-${keyCode}`}
      className={classNames(
        styles.key,
        styles[type],
        keyCode === KeyCode.Empty && styles['empty'],
        styles[`rowspan-${rowspan}`],
        styles[`colspan-${colspan}`]
      )}
      onClick={() => onKey && onKey(keyCode)}
    >
      {iconEl}
    </ExcludedFromTabNavigationButton>
  );
};

export const Key = translateContent(_Key);

Key.displayName = 'Key';
