import * as React from 'react';
import { TriangleLeft, TriangleRight } from '../../../components';
import styles from './Arrow.scss';
import { MARKER_HEIGHT, MARKER_WIDTH } from './constants';
import { ArrowDirection } from './types';

type LabelArrowProps = {
  /**
   * The direction of the arrow:
   *  - --------> // right
   *  - <-------- // left
   */
  direction: ArrowDirection;

  /**
   * The height of the arrow wrapper.
   * The arrow will always be height-centered.
   * ex: if height is set at 80, the arrow will start at height 40.
   */
  height: number;
};

/**
 * `Arrow` component renders an horizontal arrow with a label above.
 *
 * ### Properties
 * | Name   | Type      | Default   | Description
 * |---     |---        |---        |---
 * | `direction` | `ArrowDirection` | - | The direction of the horizontal arrow (left or right)
 * | `height` | `number` | - | The height of the arrow wrapper.
 * | | | | Can be use to determine the space between the arrow and the label
 */
const Arrow: React.FC<LabelArrowProps> = ({ direction, height }) => {
  const isLeft = direction === ArrowDirection.left;
  // The arrow should always be centered, so svg line must start from half the height
  const lineY = height * 0.5;

  const arrowHeadProps = {
    preserveAspectRatio: 'none',
    width: MARKER_WIDTH,
    height: MARKER_HEIGHT,
    stroke: 'none',
    fill: styles.ARROW_COLOR,
  };

  const arrowHeadStyle = {
    alignSelf: 'center',
  };
  // The line should scale with the parent container in width (hence width at 100%)
  // without preserving ratio to avoid scaling the height too.
  // The height is a props of the component to manage the padding between
  // the element above or below and the arrow.
  // The line is centered, so a height of `32px` will give a padding above and below
  // of `16px`
  const line = (
    <svg
      width="100%"
      height={height}
      preserveAspectRatio="none"
      className={styles.arrowLine}
      viewBox={`0 0 10 ${height}`}
    >
      <path d={`M 0 ${lineY} L 10 ${lineY}`} />
    </svg>
  );
  return (
    <div className={styles.arrow}>
      {isLeft ? (
        <>
          <TriangleLeft style={arrowHeadStyle} {...arrowHeadProps} />
          {line}
        </>
      ) : (
        <>
          {line}
          <TriangleRight style={arrowHeadStyle} {...arrowHeadProps} />
        </>
      )}
    </div>
  );
};

export default Arrow;
