import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { flow } from 'lodash';
import * as React from 'react';
import { EMError } from '../../../apps/emplayer/EM/EMError';
import { getStaticServerURL, parseEMPlayerQueryString } from '../../../apps/emplayer/helper';
import { Loader } from '../../../apps/seriesplayer/components';
import { Request } from '../../../apps/seriesplayer/services/api/bm-api';
import { ContentManagerRoutes } from '../../../apps/seriesplayer/services/api/content-manager/constants';
import { getPlainTextResponse } from '../../../apps/seriesplayer/services/api/content-manager/parseHelpers';
import { ShadowScrollbars } from '../../../gizmos/components';
import { DEFAULT_IFRAME_HEIGHT, DEFAULT_IFRAME_WIDTH, INCLUDE_GIZMO_IFRAME_ID } from '../constants';
import { type IncludeGizmoContent } from '@bettermarks/gizmo-types';
import { useLocation } from 'react-router-dom';

export interface IncludeGizmoCallbacks {
  readonly onGizmoClicked?: () => void;
  readonly onMeasured?: () => void;
}

export type IncludeGizmoProps = IncludeGizmoContent &
  IncludeGizmoCallbacks &
  ContextState & { staticServerUrl?: string };
export type IncludeGizmoInternalProps = IncludeGizmoProps;

/**
 * Include gizmo renders foreign HTML as an HTML iframe
 */
export const IncludeGizmo: React.FC<React.PropsWithChildren<IncludeGizmoInternalProps>> = (
  props
) => {
  const {
    htmlPathname,
    availableWidth,
    width: widthFromContent,
    height: heightFromContent,
  } = props;
  const { search } = useLocation();
  const { staticServerUrl, encrypt } = flow(parseEMPlayerQueryString, getStaticServerURL)(search);
  const height = heightFromContent || DEFAULT_IFRAME_HEIGHT;
  const width = widthFromContent || DEFAULT_IFRAME_WIDTH;
  const errorKey = 'error';
  const dataCy = htmlPathname.split('/').pop();
  const [htmlDoc, setHtmlDoc] = React.useState<string>();

  React.useEffect(() => {
    const fetchHtml = async () => {
      const response = await Request.get(
        ContentManagerRoutes.getEM({
          path: htmlPathname,
          staticServerUrl,
          encrypt,
        }),
        'omit'
      );
      return getPlainTextResponse(await response.text());
    };
    fetchHtml()
      .then(setHtmlDoc)
      .catch(() => setHtmlDoc(errorKey));
  }, []);

  return (
    <Loader loaded={!!htmlDoc}>
      {htmlDoc === errorKey ? (
        <EMError />
      ) : (
        <ShadowScrollbars availableWidth={availableWidth} fixedChildScrollWidth={width}>
          <iframe
            id={INCLUDE_GIZMO_IFRAME_ID}
            title="include gizmo iframe"
            data-cy={dataCy}
            srcDoc={htmlDoc?.startsWith('<html') ? htmlDoc : `<html>${htmlDoc}</html>`}
            width={width}
            height={height}
            sandbox="allow-scripts allow-popups allow-popups-to-escape-sandbox"
          />
        </ShadowScrollbars>
      )}
    </Loader>
  );
};
