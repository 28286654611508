import { connect } from 'react-redux';
import { type Dispatch } from '../../../../gizmo-utils/redux';
import { ApplicationState, SeriesFlow } from '../../../../types';

import { Exercise, type ExerciseDispatchProps, type ExerciseStateProps } from './Exercise';
import { getAdditionalHelpType } from './helper';
import log from 'loglevel';
import { once } from 'lodash';

export const mapDispatchToProps = (dispatch: Dispatch): ExerciseDispatchProps => ({
  dispatch,
});

const warnOnce = once(log.warn);
const mapStateToProps = (state: ApplicationState): ExerciseStateProps => {
  const {
    steps,
    currentStepId,
    handIn,
    setting,
    wrapup,
    wrapupState,
    navigatedAwayFromExercise,
    id,
    status,
  } = ApplicationState.toCurrentExercise.get(state);
  const {
    runtimeState: { availableWidth, availableHeight, isTouch },
    series: { seriesSettings, mode },
    appSettings: { staticMediaUrl },
  } = state;

  const selectedRefId = ApplicationState.toSelectedRefId(state).get(state);
  const selectedContent = ApplicationState.toSelectedContent(state).get(state);
  const realSteps = steps.filter((s) => !!s);

  // TODO: need to see why we have undefined steps at this point
  // See. https://bettermarks.atlassian.net/browse/BM-54941
  if (steps.length > realSteps.length) {
    warnOnce({
      message: 'Undefined step when rendering exercise',
      extra: { exerciseId: id },
    });
  }

  return {
    steps: realSteps,
    currentStepId,
    handIn,
    hideHelpTools: seriesSettings ? seriesSettings.hideHelpTools : false,
    setting,
    wrapup,
    wrapupState,
    navigatedAwayFromExercise,
    mode,
    exerciseId: id,
    availableWidth,
    availableHeight,
    isTouch,
    status,
    selectedRefId,
    flow: seriesSettings ? seriesSettings.flow : SeriesFlow.linear,
    dialogType: state.dialog && state.dialog.type,
    additionalHelpType: getAdditionalHelpType(ApplicationState.toCurrentExercise.get(state)),
    staticMediaUrl,
    ...(selectedContent &&
      selectedContent.errorMessage && {
        toolTipMessage: selectedContent.errorMessage,
      }),
  };
};

export const ExerciseContainer = connect(mapStateToProps, mapDispatchToProps)(Exercise);
