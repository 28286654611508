import {
  annotationInner,
  type ContentReference,
  exportContent,
  type Exporter,
  GAP,
  ID,
  Layout,
  LAYOUT,
  type LayoutContainerContent,
  MATH,
  RENDER_STYLE,
  semantics,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { decorationAttribute } from '../../../gizmo-utils/decoration';

const exportDecoration = (content: LayoutContainerContent): string => {
  switch (content.layout) {
    case Layout.Horizontal:
      return decorationAttribute({ verticalAlign: content.vAlignment });
    case Layout.Vertical:
      // eslint-disable-next-line no-case-declarations
      const { hAlignment, fullWidth } = content;
      return decorationAttribute({ fullWidth, horizontalAlign: hAlignment });
    default:
      return '';
  }
};

export const exportLayoutContainer: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as LayoutContainerContent;
  const references: ReadonlyArray<ContentReference> = content.content;
  const exportedChildren = references.map((c) => exportContent(c.$refid, context)).join('');
  const decoration = exportDecoration(content);
  if (content.$ === MATH) {
    return `
      <math ${xmlAttr(RENDER_STYLE, content.$renderStyle)}${xmlAttr(
      ID,
      content.$id
    )}${decoration}${xmlAttr(LAYOUT, content.layout)}${xmlAttr(GAP, content.gap)}
      >
        ${exportedChildren}
      </math>
      `;
  }

  return semantics(
    `
      <mrow ${decoration}>
         ${exportedChildren}
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
