import { EPS, SQR_SNAP_RADIUS, squaredEuclidianDistance } from '@bettermarks/importers';
import {
  type Coords,
  type IdCoords,
  type SnapObject,
  type SnapPoint,
} from '@bettermarks/gizmo-types';

/**
 * helper function checking, if mouse is (snap) close to point
 */
export const snapsToPoint = (dist: number, scale: number): boolean =>
  dist < SQR_SNAP_RADIUS * scale * scale;

/**
 * helper function checking, if a point is in a given rectangle
 */

export const isInRect = (
  x: number,
  y: number,
  xMin: number,
  yMin: number,
  xMax: number,
  yMax: number
): boolean => x >= xMin && y >= yMin && x <= xMax && y <= yMax;

/**
 * Create a snapPoint from coords and snapObject
 *
 * @param {{id: string, coords: Coords | undefined}[]} snapPoints
 * @param {SnapObject} snapObject
 * @returns {SnapPoint} SnapPoint[] uses Screen coordinates!!
 */
export const createSnapPoints = (
  snapPoints: { id: string; coords: Coords | undefined }[],
  snapObject: SnapObject
): SnapPoint[] =>
  snapPoints
    .filter((sp) => sp.coords !== undefined)
    .map((s: IdCoords) => ({
      id: s.id,
      snapObject,
      ...s.coords,
    }));

export const squaredDistToCircle = (point: Coords, center: Coords, radius: number): number =>
  Math.pow(Math.sqrt(squaredEuclidianDistance(point, center)) - radius, 2);

export const filterForSnapPoints = (points: (IdCoords & { dist: number })[], scale = 1) =>
  points
    .sort((a, b) => a.dist - b.dist)
    // only take snap points with dist equal to smallest dist, not all that are in snap range
    .filter((p, i, arr) => Math.abs(p.dist - arr[0].dist) < EPS)
    .filter((p) => snapsToPoint(p.dist, scale));
