import { GizmoProvider } from '../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';
import StudentTasks from '../criplayer/student-tasks/StudentTasks';
import { STUDENT_TASKS_MARGIN } from './Whiteboard';
import { isTouchDevice } from '../seriesplayer/services';
import type { ClassroomIntroDataMessage, WhiteboardMessage } from '../iframe-posts';
import { gizmos } from '../criplayer';
import { DEFAULT_MEDIA_ROUTE } from '@bettermarks/gizmo-types';
import { useDispatch } from 'react-redux';

type ClassroomIntroStudentTasksProps = {
  availableWidth: number;
};
export function ClassroomIntroStudentTasks({ availableWidth }: ClassroomIntroStudentTasksProps) {
  const [classroomIntroData, setClassroomIntroData] = React.useState<ClassroomIntroDataMessage>();
  React.useEffect(() => {
    function handleReceiveMessage(e: MessageEvent<WhiteboardMessage>) {
      const data = e.data;
      if (data.type === 'classroomIntro') {
        setClassroomIntroData(data);
      }
    }

    window.addEventListener('message', handleReceiveMessage);
    return () => window.removeEventListener('message', handleReceiveMessage);
  }, []);

  const dispatch = useDispatch();

  return classroomIntroData && classroomIntroData?.studentTasks?.length > 0 ? (
    <div
      style={{
        margin: `${STUDENT_TASKS_MARGIN}`,
      }}
    >
      <GizmoProvider
        gizmos={gizmos}
        contentDict={classroomIntroData.contentDict}
        dispatch={dispatch}
        isTouch={isTouchDevice()}
        selectedGizmoRef={React.createRef()}
        staticMediaUrl={DEFAULT_MEDIA_ROUTE}
      >
        <StudentTasks
          studentTasks={classroomIntroData.studentTasks}
          availableWidth={availableWidth}
        />
      </GizmoProvider>
    </div>
  ) : (
    <React.Fragment />
  );
}
