import * as React from 'react';
import classNames from 'classnames';
import styles from '../FormStyles.scss';
import { HEIGHT, WIDTH } from './constants';

export type RectSegmentProps = {
  index: number;
  denom: number;
  filled: boolean;
  hover: boolean;
  pxWidth: number;
  onToggleSegment?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

export const RectSegment: React.FC<RectSegmentProps> = ({
  index,
  denom,
  filled,
  hover,
  pxWidth,
  onToggleSegment,
  onMouseOver,
  onMouseLeave,
}) => {
  const rows = [1, 1, 1, 2, 1, 2, 1, 2, 3, 2, 1, 3][denom - 1] || 1;
  const row = Math.floor(index / (denom / rows));

  const onePX = WIDTH / pxWidth;
  const width = (WIDTH - 2 * onePX) / (denom / rows);
  const height = (HEIGHT - 2 * onePX) / rows;
  const startx = onePX + (index % (denom / rows)) * width;
  const starty = onePX + row * height;

  const className = classNames(filled ? styles.filled : styles.empty, {
    [styles.hover]: hover,
  });
  return (
    <rect
      y={starty}
      x={startx}
      height={height}
      width={width}
      className={className}
      onClick={onToggleSegment}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};
