import { getFontMetric } from '../../../../utils/fontMetric';
import classNames from 'classnames';
import { isNil } from 'lodash';
import * as React from 'react';

import { type Cursor as CursorContent, type HasHeight } from '@bettermarks/gizmo-types';
import { type EnrichMathContent } from '../measure/measureStatic';

import { enrich } from './helpers';
import { Cursor as CursorSVG } from './images/index';

import styles from './MathML.scss';

export type TimeoutAPI = Pick<typeof window, 'setTimeout' | 'clearTimeout'>;
/**
 * The `Cursor' component renders a cursor.
 *
 * If the prop `still is set to true it will never blink,
 * otherwise the animation will start after `BLINK_DELAY`.
 * (For the duration of `BLINK_DELAY` it is always visible.)
 *
 * ### Properties
 | Name          | Type                                  | Default   | Description                 |
 |---            |---                                    |---        |---                          |
 | `height`      | number (default: 5)                   | Optional  | the cursors height in pixel |
 | `still`       | boolean (default: false)              | Optional  | disable cursor's blinking   |
 */
export type CursorProps = Partial<HasHeight> & {
  still?: boolean;
  timer?: TimeoutAPI;
};
export type CursorState = {
  /**
   * toggle the blinking of the cursor to have an initial delay
   */
  blink: boolean;
};
export const VERTICAL_FROM_BASLINE = -3;
const BLINK_DELAY = 500; // to enable the cursor's blink animation after 500ms
const CURSOR_DEFAULT_HEIGHT = 5;

export class Cursor extends React.Component<CursorProps, CursorState> {
  delayTimeout: number | undefined;

  state = {
    blink: false,
  };

  get timer(): TimeoutAPI {
    // allows to inject spys for testing, default to use window
    return this.props.timer || window;
  }

  delayBlink() {
    if (this.state.blink) {
      this.setState({ blink: false });
    }
    if (!this.props.still) {
      this.delayTimeout = this.timer.setTimeout(() => {
        this.setState({ blink: true });
        this.delayTimeout = undefined;
      }, BLINK_DELAY);
    }
  }

  stopDelay() {
    if (!isNil(this.delayTimeout)) {
      this.timer.clearTimeout(this.delayTimeout);
    }
  }

  componentDidMount() {
    this.delayBlink();
  }

  UNSAFE_componentWillReceiveProps() {
    // the component receives new props (with same values)
    // when the cursor has been moved or  new element was added
    // in this case any active delay should be stopped and a new one started
    // to avid the cursor blinking while moving it
    this.stopDelay();
    this.delayBlink();
  }

  componentWillUnmount() {
    this.stopDelay();
  }

  render() {
    const height = !this.props.height ? CURSOR_DEFAULT_HEIGHT : this.props.height;
    const cursorProps = {
      className: classNames(styles.cursor, {
        [styles.blinkOn]: this.state.blink,
      }),
      // since the svg grows towards the bottom we need to push it up
      style: { verticalAlign: height + VERTICAL_FROM_BASLINE },
    };
    return (
      <span {...cursorProps}>
        <CursorSVG style={{ height }} />
      </span>
    );
  }
}

export const enrichCursor: EnrichMathContent<CursorContent> = (formulaStyles, content) => {
  const { height, refLine } = getFontMetric(formulaStyles.fontSize);
  return enrich(
    { ...content, height: formulaStyles.fontSize }, // height approved by @bmux
    { height, refLine, relativeToBaseLine: true }
  );
};
