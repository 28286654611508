import * as React from 'react';
import { ExerciseButtonsContainer } from './ExerciseButtons';
import styles from './Toolbar.scss';
import { ModeSelectorDrawerToggleContainer } from './Tools/ModeSelector/ModeSelectorDrawerToggleContainer';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { KeyboardToggle } from './Tools/Keyboard/KeyboardToggle';
import { DragAndDropToggle } from './Tools/DragAndDrop/DragAndDropToggle';
import { CalculatorToggle } from './Tools/Calculator/CalculatorToggle';

export interface ToolbarStateProps {
  toggles: Set<ToolbarElements>;
  openDrawer: Nullable<ToolbarElements>;
}

export interface ToolbarDispatchProps {
  toggle: (drawerName: Nullable<ToolbarElements>, openDrawerName: Nullable<string>) => void;
}

export type ToolbarProps = ToolbarStateProps & ToolbarDispatchProps;

export const Toolbar: React.FC<ToolbarProps> = ({ toggles, openDrawer, toggle }) => {
  const isDndAvailable = toggles.has(ToolbarElements.dnd);
  const [wasDndAvailable, setWasDndAvailable] = React.useState<boolean>();

  React.useEffect(() => {
    if (wasDndAvailable && !isDndAvailable) {
      // the dnd drawer became unavailable just now
      setWasDndAvailable(false);
      if (openDrawer === ToolbarElements.dnd) {
        toggle(ToolbarElements.dnd, openDrawer);
      }
    } else if (!wasDndAvailable && isDndAvailable) {
      // the dnd drawer became available just now
      setWasDndAvailable(true);
      if (openDrawer !== ToolbarElements.dnd) {
        toggle(ToolbarElements.dnd, openDrawer);
      }
    }
  }, [isDndAvailable]);

  return (
    <div id="toolbar" className={styles.toolbar}>
      {toggles.has(ToolbarElements.dnd) && (
        <DragAndDropToggle
          on={ToolbarElements.dnd === openDrawer}
          onClick={() => {
            toggle(ToolbarElements.dnd, openDrawer);
          }}
        />
      )}
      {toggles.has(ToolbarElements.keyboard) && (
        <KeyboardToggle
          dataCy="toolbar-keyboard-icon"
          on={ToolbarElements.keyboard === openDrawer}
          onClick={() => {
            toggle(ToolbarElements.keyboard, openDrawer);
          }}
        />
      )}
      {toggles.has(ToolbarElements.calculator) && (
        <CalculatorToggle
          dataCy="toolbar-calculator-icon"
          on={ToolbarElements.calculator === openDrawer}
          onClick={() => {
            toggle(ToolbarElements.calculator, openDrawer);
          }}
        />
      )}
      {toggles.has(ToolbarElements.modeSelector) && (
        <ModeSelectorDrawerToggleContainer
          on={ToolbarElements.modeSelector === openDrawer}
          onClick={() => {
            toggle(ToolbarElements.modeSelector, openDrawer);
          }}
        />
      )}
      <ExerciseButtonsContainer />
    </div>
  );
};
