import * as React from 'react';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import calculatorDrawerStyles from './CalculatorAsideDrawer.scss';
import { AsideDrawer } from './AsideDrawer';
import { type DrawerSwitchProps, getDrawerStateForToolbarElement } from './DrawerSwitch';
import { CalculatorContainer } from '../Tools/Calculator';

export const AsideDrawerSwitch: React.FC<DrawerSwitchProps> = ({
  availableDrawers,
  openDrawer,
}) => (
  <>
    {availableDrawers.has(ToolbarElements.calculator) && (
      <AsideDrawer
        state={getDrawerStateForToolbarElement(ToolbarElements.calculator, openDrawer)}
        styles={calculatorDrawerStyles}
      >
        <CalculatorContainer />
      </AsideDrawer>
    )}
  </>
);

AsideDrawerSwitch.displayName = 'AsideDrawerSwitch';
