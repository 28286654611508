import * as React from 'react';
import { RECT_LARGE, RECT_SMALL } from '../../../constants';
import { Rectangle } from './Rectangle';
import { type WholeProps } from '../types';

export const WholeRect: React.FC<WholeProps> = ({ filled, small }) => (
  // a padding of a 1/10 of the rect's w and h are accounted for the width & height calculation
  <Rectangle
    width={small ? RECT_SMALL.width : RECT_LARGE.width}
    height={small ? RECT_SMALL.height : RECT_LARGE.height}
    filled={filled}
    hover={false}
    small={small}
  />
);
