import { type MathValidatorPayload } from '../store/types';
import { importContentTree as _importContentTree, importers } from '@bettermarks/importers';
import { DependencyCollector, toXmlElement } from '@bettermarks/gizmo-types';
import { StepStatus } from '../../types';
import { stylers } from '../../gizmo-utils/configuration/stylers';
import { rulers } from '../../gizmo-utils/configuration/rulers';
import { type ValidatorFeedback } from '@bettermarks/umc-kotlin';
import { _importFeedback, type ParsedResponse, STUBABLE } from './validationHelpers';

/**
 * Gets the validator response and imports and enriches all XML content.
 * Returns question and (list of) stepFeedback as `ContentDict`s
 *
 * For modifying the ImporterRegistry or DependencyCollector to use,
 * pass an instance of ContentTreeImporter as the last argument.
 * By default a proper one for the scope of this method call will be created.
 *
 * @param validationPayload
 * @param {string} pathToStep
 * @param {typeof _importFeedback} importFeedback
 * @param {ContentTreeImporter} importContentTree
 * @returns ParsedResponse
 */
export const parseAnnotatedAnswerAndFeedbacks = (
  validationPayload: Pick<
    MathValidatorPayload,
    'annotatedUserAnswerXML' | 'stepStatus' | 'feedbacks'
  >,
  pathToStep: string,
  importFeedback = _importFeedback,
  importContentTree = _importContentTree(importers, new DependencyCollector())
): ParsedResponse => {
  const { annotatedUserAnswerXML, stepStatus, feedbacks } = validationPayload;
  const userAnnotatedQuestion = importContentTree(
    toXmlElement(annotatedUserAnswerXML),
    `${pathToStep}.question`
  );

  const disabled = stepStatus === StepStatus.completed;
  // TODO: the following function has a lot of side effects and should not be called by an action
  // handler
  const question = STUBABLE.enrichContentDict(userAnnotatedQuestion, stylers, rulers, disabled);

  return {
    question,
    feedbacks: feedbacks.map((feedback: ValidatorFeedback, index) =>
      importFeedback(feedback, `${pathToStep}.feedbacks[${index}]`, importContentTree)
    ),
  };
};
