import { type Content } from '../../xml-converter/core';
import { HLINE } from '../../gizmo-utils/configuration/renderStyles';
import { LineStyle } from '../../types';
import { SPECIAL } from '../../gizmo-utils/constants';

export interface HLineContent extends Content {
  count: number;
  lineStyle: LineStyle;
}

export const DEFAULT_HLINE_CONTENT: HLineContent = {
  $: SPECIAL,
  $renderStyle: HLINE,
  count: 1,
  lineStyle: LineStyle.solid,
};
