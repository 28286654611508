import * as React from 'react';
import classNames from 'classnames';

import { type MTokenProps } from '../types';
import styles from './tokens.scss';

/**
 * The `MText' component renders a MathML <mtext> node
 */
export const MText: React.FC<MTokenProps & { isTopLevel?: boolean }> = ({
  children: text,
  computedStyles,
  isTopLevel = false,
}) => (
  <span
    className={classNames(styles.mtext, { [styles.isTopLevel]: isTopLevel })}
    style={computedStyles}
  >
    {text}
  </span>
);

MText.displayName = 'MText';
