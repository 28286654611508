import * as React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import styles from './ContextPopup.scss';
import { type Bounds } from './Popup';

export type ToolTipBounds = {
  bounds?: Bounds;
  noseBounds: Bounds;
  place?: 'top' | 'bottom';
};

export type ToolTipProps = ToolTipBounds & {
  show: boolean;
  still?: boolean;
  innerRef?: any;
  children?: React.ReactNode;
};

/**
 * Popup - UI Component
 * Used to render                                                the Popups and Tooltip
 *
 * ### Properties
 | Name       | Type                               | Default    | Description                     |
 |---         | ---                                |---         | ---                             |
 |bounds      | `Bounds`                           |Required    |tooltip's left and top positions |
 |noseBounds  | `Bounds`                           |Required    |Arrow head's left and top positions|
 |place       | one of `top`, `bottom`             |Required    |Arrow head at `place`            |
 |still       | `boolean`                          |Optional    |Relative position for styleguide |
 |show        | `boolean`                          |Required    |Toggles the tooltip visibility  |
 |---         | ---                                |---         | ---                             |
 */

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  show,
  still,
  bounds,
  noseBounds,
  place,
  innerRef,
}) => (
  <div
    ref={innerRef}
    className={classNames(styles.contextPopup, styles.tooltip, styles[`${place}`], {
      [styles.show]: show,
      [styles.still]: still,
      // Actual usage checks only on bounds.
      // For image-tests in styleguide, `still` overrides the behavior.
      [styles.hidden]: !still && isEmpty(bounds),
    })}
    style={bounds}
    role="button"
  >
    <div>{children}</div>
    <div className={styles[`${place}`]} style={noseBounds}>
      <div className={styles.innerArrow} />
    </div>
  </div>
);

export default React.forwardRef<HTMLDivElement, ToolTipProps>((props, ref) => (
  <ToolTip innerRef={ref} {...props} />
));
