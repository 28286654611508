import { isNil } from 'lodash';
import { AxisDirection, IntervalType, type PreviewInterval } from '@bettermarks/gizmo-types';

/**
 * based on two boolean values ('is-open-low-limit' resp. 'is-open-high-limit')
 * gets the appropriate 'Intervaltype' to be attached to the interval. For more
 * info, refer to the definition of 'IntervalType'.
 * @param {boolean | undefined} lo
 * @param {boolean | undefined} hi
 * @returns {IntervalType}
 */
export const intervalType = (lo: boolean | undefined, hi: boolean | undefined): IntervalType =>
  isNil(lo)
    ? IntervalType.none
    : lo && hi
    ? IntervalType.open
    : !lo && !hi
    ? IntervalType.closed
    : lo
    ? IntervalType.lowOpen
    : IntervalType.highOpen;

/**
 * based on an 'index' decides, if an according interval limit is open or closed (not open)
 * @param {number | undefined} index
 * @returns {boolean}
 */
export const isLimitOpen = (index: number | undefined) => !(index === 0 || isNil(index));

/**
 * checks, if at least one of our two 'limit-pickers' has not been confirmed
 * @param {[(boolean | undefined)]} pickers
 * @returns {boolean}
 */
export const limitPickersConfirmed = (pickers: [boolean | undefined, boolean | undefined]) =>
  !pickers.reduce((a, v) => a || isNil(v) || v, false);

/**
 * helper: returns a well fitted 'preview interval'
 **/
export const prevInterval = (
  start: number,
  snapPos: number,
  intervalType: IntervalType
): PreviewInterval => ({
  direction: AxisDirection.horizontal,
  min: Math.min(start, snapPos),
  max: Math.max(start, snapPos),
  intervalType: intervalType,
  visible: true,
  snapHighlight: snapPos < start ? 'min' : 'max',
});
