import { handleActions } from 'redux-actions';
import { HELLO_GIZMO_DEFAULT_CONTENT } from '@bettermarks/importers';
import { HELLO_GIZMO, type HelloGizmoContent } from '@bettermarks/gizmo-types';
import { CHANGE_COLOR, CHANGE_SIZE } from './helloGizmoActions';
import { GIZMO_COLORS, GIZMO_HEIGHT } from '../../components/Gizmo';

export const helloGizmoReducer = handleActions<HelloGizmoContent, void>(
  {
    /**
     * applies color from `action.payload`
     */
    [CHANGE_COLOR]: (state: HelloGizmoContent): HelloGizmoContent => {
      const current = GIZMO_COLORS.indexOf(state.color);
      const next = (current + 1) % GIZMO_COLORS.length;
      return {
        ...state,
        color: GIZMO_COLORS[next],
      };
    },
    [CHANGE_SIZE]: (state: HelloGizmoContent): HelloGizmoContent => {
      const random = Math.random();
      const factor = random > 0.5 ? 1 + random : 1.1 - random;
      return {
        ...state,
        size: factor * GIZMO_HEIGHT,
      };
    },
  },
  {
    ...HELLO_GIZMO_DEFAULT_CONTENT,
    $interactionType: HELLO_GIZMO,
  }
);
