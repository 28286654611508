import { defaultTo, flatten, isEmpty, isNil } from 'lodash';
import {
  $MFENCED,
  type BasefulElement,
  DEFAULT_UNALIGNED,
  DEFAULT_UNSTYLED,
  type FormulaContent,
  hasBase,
  isMFenced,
  isMN,
  isMO,
  isMRow,
  isMRowLike,
  isMSup,
  isMToken,
  mapMathChildren,
  type MathContent,
  type MFenced,
  type MN as MNContent,
  type MRow,
  ROUND_CLOSE,
  ROUND_OPEN,
} from '@bettermarks/gizmo-types';
import { MN, MO, MROW } from '../constructors';
import { compose, reverse } from 'lodash/fp';

const hasBasefulElements = (content: MathContent) =>
  isMRow(content) && content.children.some(hasBase);

/**
 * traverse the math content and apply the given callback if the current node passes the test.
 * @param {MathContent} content The math content subtree to traverse
 * @param {(content: MathContent) => MathContent} callback This callback is applied to each found
 * math content node if the test function of the next parameter returns true.
 * @param {(content: MathContent) => boolean} testFunction wether the callback should be applied to
 * the current node or not.
 * @returns {MathContent} the processed math content.
 */

const traverseMathContent = (
  content: MathContent,
  callback: (content: MathContent) => MathContent,
  testFunction: (content: MathContent) => boolean
): MathContent => {
  // apply callback if test succeeds
  const result = testFunction(content) ? callback(content) : content;

  // traverse tree
  const resultChildren = mapMathChildren(result, (child) =>
    traverseMathContent(child, callback, testFunction)
  );

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  return {
    ...result,
    ...resultChildren,
  } as MathContent;
};

/**
 * recursive helper function that turns fences into arrays of math content ("mrows").
 * The arrays will be flattened outside. This is necessary, because we maybe turn multiple
 * subtrees into arrays and have to combine them in the parent nodes.
 */
const strip = (input: MathContent): MathContent[] => {
  // primitive content is just returned
  if (isMRow(input)) {
    // mrows are "removed", i.e. we recur over all children and just return the array. This will
    // ensure, that we just have one mrow instead of multiple nested ones after the algorithm
    // terminates.
    return flatten(input.children.map(strip));
  } else if (isMFenced(input)) {
    // remove the fence and replace with an array containing bracket operators. Continue with the
    // recursion in the current "mrow"-context.
    let result: MathContent[] = flatten(input.children.map(strip));
    if (!isEmpty(input.open)) {
      result = [MO(defaultTo<string>(input.open, ROUND_OPEN)), ...result];
    }
    if (!isEmpty(input.close)) {
      result = [...result, MO(defaultTo<string>(input.close, ROUND_CLOSE))];
    }
    return result;
  }
  return [input];
};

type FenceResult = {
  element: MathContent;
  position: number;
};

/**
 * create a fence until the next closing ) or the end of the input
 * @param {MathContent[]} input the children of an mrow containing brackets as <mo>(</mo>
 * @param {number} position the current position in the input array.
 * @param {boolean} open set to true if we are currently in a fence context. This controls if we
 * return from it (leave the fence context to the outer caller) or continue (i.e. we are the outer
 * most caller and might create an unbalanced fence).
 * @returns {FenceResult} contains the created mfenced and the current position in the input array.
 */
const createFences = (input: MathContent[], position = 0, open = true): FenceResult => {
  let pos = position;
  const fence: MFenced = {
    ...DEFAULT_UNALIGNED,
    ...DEFAULT_UNSTYLED,
    height: 5,
    $: $MFENCED,
    children: [],
    open: open ? ROUND_OPEN : '',
  };
  const result: FenceResult = {
    element: fence,
    position,
  };

  for (; pos < input.length; pos++) {
    // we need to count up a variable, so a for-loop is adequate.
    const el = input[pos];
    if (isMToken(el)) {
      // process token
      if (el.text === ROUND_CLOSE) {
        result.position = pos;
        if (fence.open !== '') {
          // close open fence and return a new complete fence object
          fence.close = ROUND_CLOSE;
          result.element = fence;
          return result;
        }
        // or create an unopened fenced containing all current elements (i.e. everything from the
        // beginning up until here)
        const f: MFenced = {
          ...DEFAULT_UNALIGNED,
          ...DEFAULT_UNSTYLED,
          height: 5,
          $: $MFENCED,
          children: fence.children,
          close: ROUND_CLOSE,
          open: '',
        };
        fence.children = [f];
      } else if (el.text === ROUND_OPEN) {
        // create a new fence and descent
        const fenceResult = createFences(input, pos + 1);
        fence.children.push(fenceResult.element);
        pos = fenceResult.position;
      } else {
        // all other tokens are added to the enclosing fence
        fence.children.push(el);
      }
    } else if (hasBase(el)) {
      // Added to make things even more confusing: If we encounter a baseful element, i.e. base with
      // exponent, we need to check if it containes a closing bracket! If so, we have to stuff the
      // current fence inside the base!
      // Handles a case like this: (2+3)^5
      const base = el.base.children[0];
      if (isMToken(base) && base.text === ROUND_CLOSE) {
        fence.close = ROUND_CLOSE;
        el.base.children = [fence];
        result.element = el;
        result.position = pos;
        return result;
      }
      fence.children.push(el);
    } else {
      fence.children.push(el);
    }
  }

  return {
    ...result,
    element: {
      ...fence,
      close: fence.close || '',
    },
    position: pos,
  };
};

/**
 * Split a single mtoken into multiple <MNs>.
 * @param {MToken} mn the mn to split.
 * @returns {MToken[]} an array of mn tokens
 */
const splitMN = (mn: MNContent): MNContent[] => mn.text.split('').map(MN);

/**
 * Split all found <mn> tokens in an mrow into single <mn> tokens.
 * @param {MRow} mrow the mrow to consider
 * @returns {MRow} an mrow with splitted <mn> elements.
 */
const splitNumbersInMRow = (mrow: MRow): MRow => ({
  ...mrow,
  children: mrow.children.reduce(
    (acc, cur) => (isMN(cur) ? [...acc, ...splitMN(cur)] : [...acc, cur]),
    []
  ),
});

/**
 * For further processing we have to add another asumption: Every base of an
 * msub/msup/msubsup may only contain a single element. This function removes
 * all elements from the base except the last one.
 *
 * For example: [234]^x ---> 23[4]^x
 * Also (even if mathematically senseless): [xyz]^2 ---> xy[z]^2
 *
 * The function only works syntactially and splits mrows inside bases without
 * regarding this like e.g. [+]^3 yet
 */
const splitBase = (baseful: BasefulElement): MathContent[] => {
  const mrow = baseful.base;

  return [
    ...mrow.children.slice(0, -1),
    {
      ...baseful,
      base: { ...mrow, children: [...mrow.children.slice(-1)] },
    },
  ];
};

/**
 * Split all the bases in an mrow, i.e. make sure every base has only a single
 * elemented mrow.
 */
const splitBasesInMRow = (mrow: MRow): MRow => ({
  ...mrow,
  children: mrow.children.reduce(
    (acc, cur) => (hasBase(cur) ? [...acc, ...splitBase(cur)] : [...acc, cur]),
    []
  ),
});

/**
 * Is it possible to concatenate the elements inside a base. Only <mn> elements
 * are concatenated into the base.
 */
const canConcatBase = (prev: MathContent, cur: MathContent): boolean =>
  !isNil(prev) &&
  hasBase(prev) &&
  isMRow(prev.base) &&
  ((isMN(cur) && isMN(prev.base.children[0])) ||
    (prev.base.children.length === 0 && !hasBase(cur)));

/**
 * Reverse operation to {@link splitBasesInMRow}. Concatenates all numbers in
 * front of the base with the number inside the base. E.g.
 *
 * 234[5]^x ---> [2345]^x
 *
 */
const concatBaseInMRow = (mrow: MRow): MRow => ({
  ...mrow,
  children: reverse(
    reverse(mrow.children).reduce((acc, cur) => {
      const [prev] = acc.slice(-1);
      if (canConcatBase(prev, cur)) {
        const p: BasefulElement = prev as BasefulElement;
        const base: MRow = p.base;

        return [
          ...acc.slice(0, -1),
          {
            ...prev,
            base: {
              ...base,
              children: [cur, ...base.children],
            },
          },
        ];
      }

      return [...acc, cur];
    }, [] as MathContent[])
  ),
});

const sanitizeBasesInMRow = (mrow: MRow): MRow => ({
  ...mrow,
  children: mrow.children.reduce((acc, cur) => {
    if (hasBase(cur)) {
      if (cur.base.children.length === 1 && isMO(cur.base.children[0])) {
        return [
          ...acc,
          ...cur.base.children,
          ...(isMSup(cur) ? cur.superscript.children : cur.subscript.children),
        ];
      } else if (cur.base.children.length === 0) {
        return [...acc, ...(isMSup(cur) ? cur.superscript.children : cur.subscript.children)];
      }
    }
    return [...acc, cur];
  }, [] as MathContent[]),
});

/**
 * Concat all subsequent <mn> tokens in an mrow into combined <mn> tokens.
 * @param {MRow} mrow the mrow to consider
 * @returns {MRow} an mrow with combined <mn> elements.
 */
export const concatNumbersInMRow = (mrow: MRow): MRow => ({
  ...mrow,
  children: mrow.children.reduce((acc, cur) => {
    const len = acc.length;
    if (len > 0) {
      const prev = acc[len - 1];

      if (isMN(cur) && isMN(prev)) {
        return [...acc.slice(0, len - 1), MN(`${prev.text}${cur.text}`)];
      } else {
        return [...acc, cur];
      }
    }
    return [...acc, cur];
  }, [] as MathContent[]),
});

/**
 * Apply callback to mrows only in the given formula tree.
 * @param {MathContent} content input formula tree
 * @param {(mrow: MRow) => Mrow} callback callback to apply to the mrows
 * @returns {MathContent} new formula with the changed mrows
 */
const processMRows = (content: MathContent, callback: (mrow: MRow) => MRow) =>
  traverseMathContent(content, callback, isMRowLike);

/**
 * For easy processing cursor movements, deletion a.s.o we flatten all nested fences in a single
 * mrow containing only bracket operators. This process can later be reversed with the nestFences
 * function below.
 * @param {FormulaContent} input formula content with nested fences
 * @returns {FormulaContent} formula content without any mfenced object.
 */
export const stripFences = (input: FormulaContent): FormulaContent => ({
  ...input,
  content: (
    traverseMathContent(
      MROW(...input.content),
      (c) => MROW(...flatten(strip(c))),
      isMRowLike
    ) as MRow
  ).children,
});

/**
 * Scan all mrows for brackets and turn them into real <mfenced> objects
 * @param {FormulaContent} input formula content without fences (but bracket operators)
 * @returns {FormulaContent} formula content with brackets turned into fences. Unbalanced fences
 * are still created (mfenced with open or close undefined) so they can be sized correctly!
 */
export const nestFences = (input: FormulaContent): FormulaContent => ({
  ...input,
  // nest over an mrow always returns an mrow! Is possible to express this in typescript?
  content: (
    traverseMathContent(
      MROW(...input.content),
      (c: MRow) => {
        const result = createFences(c.children, 0, false).element;
        // strip bogus mfenced object (i.e. mfenced without brackets)
        const children = isMFenced(result) && result.close === '' ? result.children : [result];
        return { ...c, children };
      },
      isMRow
    ) as MRow
  ).children,
});

/**
 * Split all <mn> elements (e.g. 456) into multiple single <mn> elements (4, 5
 * and 6). This helps to place cursors and enter other operators in between.
 * @param {FormulaContent} input Formula content with combined number elements.
 * @returns {FormulaContent} formula content with splitted number elements.
 */
export const splitNumbers = (input: FormulaContent): FormulaContent => ({
  ...input,
  content: (processMRows(MROW(...input.content), splitNumbersInMRow) as MRow).children,
});

/**
 * combine adjacent <mn> elements. I.e. <mn>3</mn><mn>4</mn> will become <mn>34</mn>.
 * @param {FormulaContent} input Formula content, that may contain splitted adjacent <mn>s
 * @returns {FormulaContent} Formula content with combined <mn> elements.
 */
export const concatNumbers = (input: FormulaContent): FormulaContent => ({
  ...input,
  content: (processMRows(MROW(...input.content), concatNumbersInMRow) as MRow).children,
});

/**
 * Recursively traverse the math content and split all numbers inside
 * msub/msup/msubsup bases.
 */
export const splitBases = (input: FormulaContent): FormulaContent => ({
  ...input,
  content: (
    traverseMathContent(MROW(...input.content), splitBasesInMRow, hasBasefulElements) as MRow
  ).children,
});

/**
 * Recursively traverse the math content and concat all numbers in front
 * msub/msup/msubsup bases with the number inside the base.
 */
export const concatBases = (input: FormulaContent): FormulaContent => ({
  ...input,
  content: (
    traverseMathContent(MROW(...input.content), concatBaseInMRow, hasBasefulElements) as MRow
  ).children,
});

export const sanitizeBases = (input: FormulaContent): FormulaContent => ({
  ...input,
  content: (
    traverseMathContent(MROW(...input.content), sanitizeBasesInMRow, hasBasefulElements) as MRow
  ).children,
});

/**
 * Combined helpers: Create <mrows> from singular children (e.g. 2^x -> [2]^[x]), split all <mn>
 * elements into single numbers and replace all <mfenced> elements.
 * @param {MathContent} input the input formula
 * @returns {MathContent} preprocessed formula ready to use in the formula reducer.
 */
export const preprocessFormula = compose(splitBases, stripFences, splitNumbers);

/**
 * Restore the preprocessed formulas again to a state better suited for rendering.
 * @param {MathContent} input the preprocessed formula
 * @returns {MathContent} cleaned up formula that renders beautifully again.
 */
export const restoreFormula = compose(sanitizeBases, concatNumbers, concatBases, nestFences);
