import { LineStyle } from '../components/SquaredPaper/types';
import {
  ALIGN,
  CarryDigitAlignment,
  type ContentReference,
  DECORATION,
  type Digit,
  DigitType,
  type FElement,
  type FormulaContent,
  type Importer,
  type ImporterContext,
  type SquaredPaperLine as Line,
  LineAlignment,
  OVERLAY_DIGIT_FONT_SIZE,
  type SquaredPaperContent,
  SquaredPaperOrientation,
  switchMap,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../gizmo-utils/decoration';
import { isEmpty, maxBy } from 'lodash';
import { compose, groupBy, toPairs } from 'lodash/fp';
import { importFormula, parseDecoration } from '../formula/importer';
import { parseString, setFontSizeInFormula } from './helper';

/**
 * function to find horizontal line for rules
 */
type BoundaryFn = (memberDigits: Digit[]) => {
  maxX: number;
  minX: number;
  y: number;
};

/**
 * Groups are defined by a rule and a list member ids (ids of digits)
 */
type GroupRule = { rule: string; members: string[] };

const ORIENTATION = 'orientation';

/**
 * Validates carry digit alignment
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const parseCarryDigitAlignment = (align: string | undefined): { align: CarryDigitAlignment } | {} =>
  align === CarryDigitAlignment.top || align === CarryDigitAlignment.bottom ? { align } : {};

/**
 * Validates digit type.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const parseDigitType = (localName: string): { type: DigitType } | {} =>
  localName === 'carryDigit'
    ? { type: DigitType.carry }
    : localName === 'borrowDigit'
    ? { type: DigitType.borrow }
    : {};

/**
 * Import all simple digits defined in the square paper XML.
 */
const importSimpleDigit = (digitXml: FElement): Digit => {
  const pos: { x: number; y: number } = digitXml.attributesToProps(parseInt, ['x', 'y']);
  const id: string = digitXml.attribute('id');
  const { align, decoration } = digitXml.attributesToProps(parseString, [], [ALIGN, DECORATION]);
  const styles = parseDecoration(decoration || '');
  const value: string = digitXml.findChildTag('value').text;
  const crossedOut: Partial<Digit> = digitXml.hasChild('crossedOut') ? { crossedOut: true } : {};
  const decimalPoint: Partial<Digit> = digitXml.hasChild('decimalPoint')
    ? { decimalPoint: true }
    : {};

  return {
    id,
    ...pos,
    value,
    ...parseCarryDigitAlignment(align),
    ...parseDigitType(digitXml.localName),
    ...styles.decoration,
    ...crossedOut,
    ...decimalPoint,
  };
};

/**
 * Validate line alignment over the LineAlignment enumeration.
 * Default to top alignment
 */
const parseLineAlignment = switchMap(
  {
    top: LineAlignment.top,
    bottom: LineAlignment.bottom,
    left: LineAlignment.left,
    right: LineAlignment.right,
    carry: LineAlignment.carry,
  },
  LineAlignment.top
);

/**
 * Import all lines, expect division lines that needs to be
 * imported from a group rule.
 * @see importLinesFromGroup
 */
const importLine = (line: FElement): Line => {
  const { align, decoration, orientation } = line.attributesToProps(
    parseString,
    [],
    [ALIGN, DECORATION, ORIENTATION]
  );
  const { object } = decoration ? parseDecoString(decoration) : { object: {} };
  const numberOfLines: number = parseInt(line.findChildTag('numberOfLines').text, undefined);
  const [start, end] = line
    .getChildrenByTagName('pos')
    .map((pos) => pos.attributesToProps(parseInt, ['x', 'y']));

  const x2 = orientation !== SquaredPaperOrientation.vertical ? end.x + 1 : end.x;
  const y2 = orientation === SquaredPaperOrientation.vertical ? end.y + 1 : end.y;

  return {
    coord: {
      x1: start.x,
      y1: start.y,
      x2,
      y2,
    },
    ...(align && { align: parseLineAlignment(align) }),
    ...(numberOfLines === 2 ? { style: LineStyle.double } : {}),
    ...object,
  };
};

/**
 * Import all digit id's as member of a group with rule.
 */
const importGroupRule = (element: FElement): GroupRule => {
  const rule = element.attribute('rule').trim();
  const members = element
    .findChildTag('members')
    .text.split(';')
    .map((s) => s.trim());
  return { rule, members };
};

/*
 definition for line by rule 'divisionHLine'

 examples:

 1 2 3           1 2 3
   3 4 5  or   3 4 5
   - -         - - - -
 */
const divisionHLine: BoundaryFn = (
  memberDigits: Digit[]
): { maxX: number; minX: number; y: number } => {
  const digit = maxBy(memberDigits, (digit) => digit.y);
  if (digit) {
    const { y: lastLine } = digit;
    return memberDigits
      .filter((digit) => !isEmpty(digit.value))
      .reduce(
        ({ minX, maxX, y }, digit) => ({
          minX: digit.y !== lastLine ? Math.min(digit.x, minX) : minX,
          maxX: digit.y === lastLine ? Math.max(digit.x, maxX) : maxX,
          y,
        }),
        { minX: Number.MAX_VALUE, maxX: 0, y: lastLine }
      );
  }
  return { maxX: 0, minX: Number.MAX_VALUE, y: 0 }; // creates no line for minX > maxX
};

/*
 definition for line by rule 'divisionHLineMax'

 examples:

 1 2 3          1 2 3
 - - - -  or  - - - -
   3 4 5      3 4 5

 */
const divisionHLineMax: BoundaryFn = (
  memberDigits: Digit[]
): { maxX: number; minX: number; y: number } =>
  memberDigits
    .filter((digit) => !isEmpty(digit.value))
    .reduce(
      ({ minX, maxX, y }, digit) => ({
        minX: Math.min(digit.x, minX),
        maxX: Math.max(digit.x, maxX),
        y: Math.max(digit.y - 1, y),
      }),
      { minX: Number.MAX_VALUE, maxX: 0, y: 0 }
    );

/**
 * Recover digits base on member id
 */
const getMemberDigits = (members: string[], digits: Digit[]): Digit[] =>
  members.reduce<Digit[]>((mDigits, id) => {
    const memberDigit = digits.find((digit) => digit.id === id);
    return memberDigit ? [...mDigits, memberDigit] : mDigits;
  }, []);

/*
  Lines could be defined by group rules, these lines are dynamic growth in the 'flexclient'
  the length of a line depends if a cell has a value
   'groupRules' are defined by:
    - name of the rule
    - member list id's of digits (cells)

   Rules:
     'divisionHLine' - shows a line under the lowest cell row if at least one exits with content
     'divisionHLineMax' - shows a line above the lowest cell row if at least one exits with content
 */
const importLinesFromGroup = (groupRules: GroupRule[]) => (digits: Digit[]) => {
  const constructLinesFromGroupRule =
    (ruleName: string, lineStyle: Partial<Line>, boundaryFn: BoundaryFn) =>
    (lines: Line[], { rule, members }: GroupRule) => {
      const memberDigits = getMemberDigits(members, digits);
      if (rule === ruleName) {
        const { minX, maxX, y } = boundaryFn(memberDigits);
        if (minX <= maxX) {
          lines.push({
            coord: {
              x1: minX,
              x2: maxX + 1,
              y1: y + 1,
              y2: y + 1,
            },
            ...lineStyle,
          });
        }
      }
      return lines;
    };
  const maxLines = groupRules.reduce<Line[]>(
    constructLinesFromGroupRule('divisionHLineMax', { lineWeight: 'thick' }, divisionHLineMax),
    []
  );
  return groupRules.reduce<Line[]>(
    constructLinesFromGroupRule('divisionHLine', {}, divisionHLine),
    maxLines
  );
};

/**
 * Import the overlay digit element and hard set its fontSize
 * to fit in the paper cell
 */
const importOverlayContent = (
  textElement: FElement,
  context: ImporterContext
): ContentReference => {
  const temp = context.tempContext();
  const refId = context.generateId();
  const content = temp.invoke<FormulaContent>(importFormula, textElement);
  // ensure to got rid of isRoot boolean in content, since it's not true :)
  delete content.isRoot;
  // Force font size to make sure the overlay digit fits well in the square along with
  // the digit value
  context.content.set(refId, setFontSizeInFormula(OVERLAY_DIGIT_FONT_SIZE)(content));
  return { $refid: refId };
};

/**
 *  Import overlay digits XML math content as Digit with a content reference
 */
const importOverlays = (context: ImporterContext) => (overlayXml: FElement) =>
  overlayXml.children.map((overlay) => {
    const { x, y } = overlay.attributesToProps(parseInt, ['x', 'y']);
    return {
      id: '',
      x,
      y,
      value: '',
      overlay: importOverlayContent(overlay.firstChild, context),
    };
  });

/**
 * Import simple digits from XML as Digit
 */
const importSimpleDigits = (digitXml: FElement): Digit[] =>
  digitXml.children.map(importSimpleDigit);

/**
 * Import rules and its members as GroupRule
 */
const importGroupRules = (paperXml: FElement) =>
  paperXml.getChildrenByTagName('groupRule').map(importGroupRule);

/**
 * Import lines definitions from XML
 */
const importLines = (linesXml: FElement) => linesXml.children.map(importLine);

/**
 * Merge digit that has overlay digits inside same cell coordinates
 */
const mergeDigitsAndOverlay = (pairs: [string, Digit[]][]): Digit[] =>
  pairs.map(([_, digits]) => {
    const head = digits[0];
    return digits.reduce((digitWithOverlay, digit) => {
      const overlay = digit.overlay || digitWithOverlay.overlay;
      return {
        ...digitWithOverlay,
        ...(overlay && { overlay }),
      };
    }, head);
  });

/**
 * Reconciliate overlay digits with simple digits that contains value
 */
const reconciliateDigits = (overlayDigits: Digit[], digits: Digit[]): Digit[] =>
  compose(
    mergeDigitsAndOverlay,
    toPairs,
    groupBy<Digit>(({ x, y }) => `(${x},${y})`)
  )(digits.concat(overlayDigits));

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 * @param preContent
 * @param xml
 * @param context
 */
export const importSquaredPaper: Importer<SquaredPaperContent> = (preContent, xml, context) => {
  const paper = xml.findChildTag('paper');
  const { width, height } = paper
    .findChildTag('display')
    .attributesToProps(parseInt, ['width', 'height']);
  const items = paper.findChildTag('items');
  const digitElement = items.findChildTag('digits');
  const lineElements = items.findChildTag('lines');
  const overlayElement = items.findChildTag('overlays');
  const simpleDigits = importSimpleDigits(digitElement);
  const groupRules = importGroupRules(paper);
  const lines = importLines(lineElements).concat(importLinesFromGroup(groupRules)(simpleDigits));
  const overlays = importOverlays(context)(overlayElement);
  // Get rid of digits without value or background (only use to draw lines)
  // /!\ It should only be done after importing lines from group.
  const digitsWithValue = simpleDigits.filter(
    (digit) => !isEmpty(digit.value) || digit.backgroundColor
  );
  const digits = reconciliateDigits(overlays, digitsWithValue).map((digit) => {
    delete digit.id;
    return digit;
  });

  return {
    ...preContent,
    width,
    height,
    digits,
    lines,
  };
};
