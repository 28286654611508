import { reduceReducers } from '../../gizmo-utils/reduceReducers';
import { applyMiddleware, createStore as _createStore, type Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createSagaMiddleware from 'redux-saga';
import { type ApplicationState } from '../../types';
import { closeSeriesPlayer } from '../seriesplayer/constants';
import { exceptionLog, ExceptionType } from '../seriesplayer/services/logging';
import { initialSeriesplayerState } from './constants';
import { errorHandler, persistStateHistory } from './middleware';
import * as AppReducer from './reducer';
import rootSaga from './sagas';
import { type Store } from './types';

/**
 * allows usage of ReduxDevTools only in dev environment, not in production
 * @see https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm */
const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

/**
 * This handles a possibly unrecoverable state. Log and terminate application!
 * @param error
 */
const sagaErrorHandler = (error: Error) => {
  exceptionLog(ExceptionType.saga, error);
  // eslint-disable-next-line no-restricted-globals
  parent && parent.postMessage(closeSeriesPlayer, '*');
};

const rootReducer = (reducer: Reducer<ApplicationState>): Reducer<ApplicationState> =>
  reduceReducers((state) => state ?? initialSeriesplayerState, reducer);
/**
 * Create the redux store
 */
export function createStore(initialState = initialSeriesplayerState): Store {
  const sagaMiddleware = createSagaMiddleware({ onError: sagaErrorHandler });
  const store = _createStore(
    rootReducer(AppReducer.combinedSeriesplayerReducer),
    {
      ...initialState,
    },
    composeEnhancers(applyMiddleware(sagaMiddleware, persistStateHistory, errorHandler))
  );

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const appReducer = require('./reducer') as typeof AppReducer;
      store.replaceReducer(rootReducer(appReducer.combinedSeriesplayerReducer));
    });
  }
  sagaMiddleware.run(rootSaga);
  return store;
}
