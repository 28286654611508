import { range } from 'lodash/fp';
import {
  type DateCell,
  DateCellMark,
  type DateSpan,
  type DayGrid,
  type DaySpan,
  type FirstDayOfWeek,
} from './types';
import { type TFunction } from 'i18next';

/**
 * Compute whether a cell should be mark or not:
 *  - mark: if the day is between a day span but not a boundary
 *  - mark-start: if the day is the start date of a day span
 *  - mark-end: if the day is the last date of a day span
 * @param date date string formated as YYYY-MM-DD
 * @param daySpans  this list of day spans, from an to date stings formated as YYYY-MM-DD
 */
const computeDateCellMark = (date: string, daySpans: DaySpan[]): DateCellMark => {
  let state = DateCellMark.Empty;
  const betweenDaySpan = daySpans.find((span) => span.from <= date && date <= span.to);
  if (betweenDaySpan) {
    state = DateCellMark.Marked;
    if (date === betweenDaySpan.from) {
      state = DateCellMark.MarkStart;
    } else if (date === betweenDaySpan.to) {
      state = DateCellMark.MarkEnd;
    }
  }
  return state;
};

/**
 * Format the given date to YYYY-MM-DD pattern.
 * e.g. 2019-01-15 // 15th of January 2015
 */
const toSimpleDate = (date: Date): string => {
  const zeroPrefixed = (n: number): string => (n > 9 ? `${n}` : `0${n}`);
  const [yy, mm, dd] = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  return `${yy}-${zeroPrefixed(mm)}-${zeroPrefixed(dd)}`;
};

/**
 * Construct all day cells for the given month of the given year:
 *  - day: the day number of the month
 *  - state: if this date is a boundary or between a date span
 *
 * @param year  calendar year
 * @param monthIndex calendar month index (0-indexed, e.g. 0 for January and 11 for December)
 * @param dateSpan date intervals for days that should be marked
 */
const constructDayCells = (year: number, monthIndex: number, dateSpan: DateSpan[]): DateCell[] => {
  const date = new Date(year, monthIndex, 1);
  const currentMonthDaySpans: DaySpan[] = dateSpan.map((span) => ({
    from: toSimpleDate(span.from),
    to: toSimpleDate(span.to),
  }));

  return (
    range(1, 32)
      .map((idx) => {
        const mday = new Date(date);
        mday.setDate(idx);
        const day = toSimpleDate(mday);
        return { day, mark: computeDateCellMark(day, currentMonthDaySpans) };
      })
      // filter for months that have less than 31 days
      .filter((dateCell) => new Date(dateCell.day).getMonth() === monthIndex)
  );
};

export const getMonthName = (idx: number, t: TFunction) => t('calendar.months').split(',')[idx];

export const getWeekDaysHeader = (weekDay: FirstDayOfWeek, t: TFunction) => {
  let days = t('calendar.weekDays').split(',');
  if (weekDay === 'Mo') {
    // rotate left by 1.
    // e.g. [ 'Su', 'Mo' ..., 'Sa'] to ['Mo', 'Tu' ..., 'Su']
    const sunday = days.slice(0, 1);
    days = days.slice(1).concat(sunday);
  }
  return days;
};

/**
 * Construct a day grid needed for the `CalendarMonth` component.
 * {{{
 *  // Construct a DayGrid for the month of febuary 2019,
 *  // without any days marked. Starting week on Monday
 *  const febuary = constructDayGrid = (2019, 1, [], 'Mo')
 *
 *  // Construct a DayGrid for the month of june 2019 with:
 *  // - 12th to 17th of June marked (included)
 *  // - 21st to 27th of June marked (included)
 *  // - Week starting on Sunday
 *  const febuary = constructDayGrid(
 *      2019,
 *      5,
 *      [{ from: "2019-06-12", to: "2019-06-17"},
 *       { from: "2019-06-21", to: "2019-06-27"}]
 *      'So')
 * }}}
 * @param year the calendar year
 * @param monthIndex the 0-indexed month index (0 is January, 11 is December)
 * @param daySpans the day span array, calendar days
 *                 between any of the span are marked
 * @param firstDayOfWeek whether the weeks starts on Monday or Sunday
 */
export const constructDayGrid = (
  year: number,
  monthIndex: number,
  firstDayOfWeek: FirstDayOfWeek,
  daySpans?: DaySpan[]
): DayGrid => {
  const dayCells = constructDayCells(
    year,
    monthIndex,
    (daySpans || []).map((d) => ({
      from: new Date(d.from),
      to: new Date(d.to),
    }))
  );
  const firstDayOffset = firstDayOfWeek === 'Mo' ? 1 : 0;
  const toDayGrid = (rows: DayGrid, dayCell: DateCell) => {
    let row;
    const offset = new Date(dayCell.day).getDay();
    if (offset === firstDayOffset) {
      row = [dayCell];
    } else {
      const tail = rows.pop() || [];
      row = [...tail, dayCell];
    }
    return [...rows, row];
  };
  return dayCells.reduce(toDayGrid, []);
};
