import * as React from 'react';

import { type EM } from '../../../types';

import { EMContentElement } from './EMContentElement';
import styles from './EM.scss';

export type StructuredExampleStepProps = Readonly<
  EM.ElementStructuredExampleStep & {
    availableWidth: number;
    last: boolean;
  }
>;

export const StructuredExampleStep: React.FC<StructuredExampleStepProps> = ({
  title,
  result,
  comment,
  availableWidth,
  last,
}) => (
  <div className={styles.emStructuredExampleStep}>
    {title && (
      <h4 className={styles.emStructuredExampleStepTitle}>
        <span>{title}:</span>
      </h4>
    )}
    <EMContentElement {...result} availableWidth={availableWidth} first={false} last={false} />
    {comment && (
      <EMContentElement {...comment} availableWidth={availableWidth} first={false} last={last} />
    )}
  </div>
);

StructuredExampleStep.displayName = 'StructuredExampleStep';
