import * as React from 'react';
import { DialogWrapper } from '../../../../components/DialogWrapper';
import { LoadingErrorDialog } from './LoadingErrorDialog';
import { LoaderScreen } from './LoaderScreen/LoaderScreen';

export type LoaderProps = {
  loaded: boolean;
  onQuit?: () => void;
  hasError?: boolean;
};

export const Loader: React.FC<LoaderProps> = ({ hasError, loaded, onQuit, children }) =>
  loaded ? (
    <>{children}</>
  ) : hasError ? (
    <DialogWrapper>
      <LoadingErrorDialog onClick={onQuit} />
    </DialogWrapper>
  ) : (
    <LoaderScreen />
  );

Loader.displayName = 'Loader';
