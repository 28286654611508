import * as React from 'react';
import { isNil } from 'lodash';
import { POINT_RADIUS } from '@bettermarks/importers';
import { LabelAlignDirection, numberFromStyles } from '@bettermarks/gizmo-types';

import styles from './LabelWidget.scss';
import { type LabelProps } from './Label';
import classNames from 'classnames';
import { TrashXLargeBold } from '../../../components/icons';

export type LabelWidgetProps = LabelProps & {
  icon?: typeof TrashXLargeBold;
};

export const LabelWidget: React.FC<LabelWidgetProps> = (props) => {
  const { align, icon, id, onClick, onHover, onLeave, x, y } = props;

  const labelSize = numberFromStyles(styles.LABEL_SIZE);
  const noseH = numberFromStyles(styles.NOSE_HEIGHT);
  const noseW = numberFromStyles(styles.NOSE_WIDTH);
  const borderRadius = numberFromStyles(styles.BORDER_RADIUS);

  const noseDist = 2 * POINT_RADIUS + noseH;
  const noseYStart = align === LabelAlignDirection.top ? -2 * POINT_RADIUS : 2 * POINT_RADIUS;
  const noseYDiff = align === LabelAlignDirection.top ? -noseDist : noseDist;
  const yOffsetLabel = align === LabelAlignDirection.top ? -(noseDist + labelSize) : noseDist;

  const pickerStyles = classNames(styles.picker, {
    [styles.active]: !isNil(onHover || onClick),
  });

  const iconScaleFactor = 0.6;

  return (
    <>
      <rect
        x={x - labelSize / 2}
        y={y + yOffsetLabel}
        rx={borderRadius}
        ry={borderRadius}
        width={labelSize}
        height={labelSize}
        className={pickerStyles}
        onClick={onClick && onClick(id)}
        onTouchStart={onClick && onClick(id)}
        onMouseOver={onHover}
        onMouseLeave={onLeave}
      />
      <path
        d={`
            M${x} ${y + noseYStart}
            L${x + noseW} ${y + noseYStart + noseYDiff}
            L${x - noseW} ${y + noseYStart + noseYDiff}
            Z`}
        className={styles.pickerNose}
      />
      {icon ? (
        <TrashXLargeBold
          x={x - (iconScaleFactor / 2) * labelSize}
          y={y + yOffsetLabel + ((1 - iconScaleFactor) / 2) * labelSize}
          width={labelSize * iconScaleFactor}
          height={labelSize * iconScaleFactor}
          className={styles.delete}
        />
      ) : null}
    </>
  );
};

LabelWidget.displayName = 'LabelWidget';
