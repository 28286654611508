import {
  type Dispatch,
  gizmoAction,
  type GizmoActionOptions,
  connectGizmo,
} from '../../gizmo-utils/redux';
import { type GizmoProps } from '../../gizmo-utils/polymorphic-gizmo';
import { setParam, selectParam, cursorLeft, cursorRight } from './actions';
import { FunctionPlotter, type FunctionPlotterCallbacks } from './FunctionPlotter';
import { type GizmoShortcutsMap } from '../../gizmo-utils/keyboard';

const SKIP_UNDO: GizmoActionOptions = { skipUndo: true };

const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): FunctionPlotterCallbacks => ({
  onSetParam: (id) => (value) => dispatch(gizmoAction(setParam({ id, value }), refid)),
  onFocusParam: (id) => () => dispatch(gizmoAction(selectParam(id), refid, SKIP_UNDO)),
  onBlurParam: () => () => dispatch(gizmoAction(selectParam(''), refid, SKIP_UNDO)),
});

const shortcuts: GizmoShortcutsMap = {
  ArrowLeft: gizmoAction(cursorLeft(), 'dummy'),
  ArrowRight: gizmoAction(cursorRight(), 'dummy'),
};

export const FunctionPlotterContainer = connectGizmo(mapDispatchToProps, FunctionPlotter, {
  shortcuts,
});
