import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import {
  type Coords,
  HAlignment,
  type Hover,
  LabelAlignDirection,
  type LabelContent,
  GeoLabelType as LabelType,
  type MouseOrTouch,
  numberFromStyles,
  VAlignment,
} from '@bettermarks/gizmo-types';
import { LABEL_DISTANCE_PIXEL, SQRT2H } from '@bettermarks/importers';
import { GeoSVGForeignObject } from './primitives/GeoSVGForeignObject';

import styles from './LabelWidget.scss';
import { LabelWidget } from './LabelWidget';
import { TrashXLargeBold } from '../../../components/icons';

export type LabelProps = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  content: DeepImmutableObject<LabelContent>;
  matrix: number[];
  distance: number;
  align: LabelAlignDirection;
  hover?: Hover;
  customDirection?: Coords;
  labelType?: LabelType;
  className?: string;
  onClick?: (id: string) => (evt: MouseOrTouch) => void;
  onHover?: () => void;
  onLeave?: () => void;
};

/*
 A simple map mapping some Label positioning to a 6-tuple like so:
 - the according horizontal alignment of our ForeignObject
 - the according vertical alignment of our ForeignObject
 - a "distance" indicator for the x-direction (see dx below)
 - a "distance" indicator for the y-direction (see dy below)
 - some amount of 'translation correction' pixels x
 - some amount of 'translation correction' pixels y
 Those 'correction pixels are intended to compensate some SVG special
 alignment implementation. We want a look like the flex implementation.
*/
export const alignmentMap: {
  [key: string]: [HAlignment, VAlignment, number, number, number, number];
} = {
  [LabelAlignDirection.auto]: [HAlignment.middle, VAlignment.middle, 0, 0, 0, 0],
  [LabelAlignDirection.leftTop]: [HAlignment.end, VAlignment.bottom, -SQRT2H, -SQRT2H, -2, 0],
  [LabelAlignDirection.top]: [HAlignment.middle, VAlignment.bottom, 0, -1, 0, -1],
  [LabelAlignDirection.rightTop]: [HAlignment.start, VAlignment.bottom, SQRT2H, -SQRT2H, 2, 0],
  [LabelAlignDirection.left]: [HAlignment.end, VAlignment.middle, -1, 0, -2, -1],
  [LabelAlignDirection.center]: [HAlignment.middle, VAlignment.middle, 0, 0, 0, -1],
  [LabelAlignDirection.right]: [HAlignment.start, VAlignment.middle, 1, 0, 2, -1],
  [LabelAlignDirection.leftBottom]: [HAlignment.end, VAlignment.hanging, -SQRT2H, SQRT2H, -2, 2],
  [LabelAlignDirection.bottom]: [HAlignment.middle, VAlignment.hanging, 0, 1, 0, 1],
  [LabelAlignDirection.rightBottom]: [HAlignment.start, VAlignment.hanging, SQRT2H, SQRT2H, 2, 2],
};

export const Label: React.FC<LabelProps> = (props) => {
  const { align, className, customDirection, distance, id, onClick, labelType } = props;
  // eslint-disable-next-line prefer-const
  let [hAlign, vAlign, sx, sy, tx, ty] = alignmentMap[align];

  // if we have special label placement (via shift+distance) we calculate a normal vector
  // and choose the orientation that matches the normal vector direction best
  if (customDirection) {
    [sx, sy] = [customDirection.x, customDirection.y];

    // customDirection is a normed vector (length 1)
    // => -1 < customDir.x,customDir.y < 1
    // => Math.round(sx) returns -1, 0 or 1
    // => one directly address the correct hAlignment via an index shift by 1
    hAlign = [HAlignment.end, HAlignment.middle, HAlignment.start][Math.round(sx) + 1];
    vAlign = [VAlignment.bottom, VAlignment.middle, VAlignment.hanging][Math.round(sy) + 1];
  }

  const isInteractiveLabel = labelType === LabelType.initial || labelType === LabelType.delete;
  const labelSize = numberFromStyles(styles.LABEL_SIZE);
  const dist = isInteractiveLabel ? labelSize / 2 : distance * LABEL_DISTANCE_PIXEL;
  const [dx, dy] = [sx * dist + tx, sy * dist + ty];
  const labelStyle = classNames(className, {
    [styles.label]: isInteractiveLabel,
  });

  return (
    <g>
      {labelType && isInteractiveLabel && (
        <LabelWidget
          {...props}
          icon={labelType === LabelType.delete ? TrashXLargeBold : undefined}
        />
      )}
      <GeoSVGForeignObject
        {...props}
        {...{ dx, dy, hAlign, vAlign }}
        onClick={isNil(labelType) ? onClick && onClick(id) : undefined}
        className={labelStyle}
      />
    </g>
  );
};

Label.displayName = 'Label';
