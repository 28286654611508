import { type Exporter, type HLineContent, semantics, special } from '@bettermarks/gizmo-types';
import { decorationToString } from '../../gizmo-utils/decoration';

export const exportHLine: Exporter = (refid, context) => {
  const content = context.content[refid] as HLineContent;

  return semantics(
    `
      <mrow decoration="${decorationToString({
        lineStyle: content.lineStyle,
      })}">
        <configuration>
          <count>${content.count}</count>
        </configuration>
      </mrow>
    `,
    special(content)
  );
};
