import { isUnknownContent } from './data';
import {
  annotationInner,
  type ContentReference,
  type Exporter,
  type ExporterContext,
  semantics,
} from '../../xml-converter/core';
import { exportContent } from '../../xml-converter/content';

/**
 * Exports the Unknown Content to XML Data
 * @param contentRefId
 * @param context
 */
export const exportUnknown: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId];
  if (isUnknownContent(content)) {
    const references: ReadonlyArray<ContentReference> = content.content;
    return semantics(
      `
      ${references.map((c) => exportContent(c.$refid, context)).join('')}
      `,
      annotationInner(content.$, content)
    );
  } else {
    return '';
  }
};
