import {
  type ContentReference,
  type MathContent,
  type SolutionSetConfiguration,
  Spacing,
} from '@bettermarks/gizmo-types';
import { MTEXT } from '@bettermarks/importers';

/**
 * Get visible content based on current elements and SolutionSet Configuration
 * @param {SolutionSetConfiguration} configuration
 * @param {ContentReference[]} visibleElements
 * @return {MathContent[]}
 */
export const getVisibleContent = (
  configuration: SolutionSetConfiguration,
  visibleElements: ReadonlyArray<ContentReference>
): MathContent[] => {
  const result: MathContent[] = [];

  if (configuration.open) {
    result.push({
      ...MTEXT(configuration.open),
      computedStyles: { marginRight: Spacing.afterOpen },
    });
  }

  visibleElements.forEach((element, currentIndex) => {
    result.push(element);
    if (currentIndex < visibleElements.length - 1) {
      result.push({
        ...MTEXT(configuration.separator),
        computedStyles: {
          marginLeft: Spacing.beforeSeparator,
          marginRight: Spacing.afterSeparator,
        },
      });
    }
  });

  if (configuration.close) {
    result.push({
      ...MTEXT(configuration.close),
      computedStyles: { marginLeft: Spacing.beforeClose },
    });
  }

  return result;
};
