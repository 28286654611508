import {
  BasicDomAccess,
  type FontGroup,
  FontMetrics,
  getBuildTimeMetrics,
  type GetFontMetric,
  measureFontSize,
} from '@bettermarks/bm-font';
import log from 'loglevel';

const GlobalBasicDomAccess = new BasicDomAccess();
const doMeasureFontSize: (fontSize: number) => FontGroup = (size) =>
  measureFontSize(size, GlobalBasicDomAccess);

let GlobalFontMetric = new FontMetrics(doMeasureFontSize, (size, e) => {
  const fontMetric = getBuildTimeMetrics(size);

  if (!fontMetric) {
    throw e;
  }
  log.warn(`Default metrics has been used for font size: ${size}`);
  return fontMetric;
});

export const getFontMetric: GetFontMetric = (size, weight = 'normal', style = 'normal') =>
  GlobalFontMetric.get(size, weight, style);

/**
 * After calling this method `getFontMetric` will no longer try to render any font on a canvas.
 * It will instead always use the build time metrics from bm-font.
 */
export const disableCanvasUsage = () => {
  GlobalFontMetric = new FontMetrics(getBuildTimeMetrics);
};
// for tests * imports
export type { FontMetric } from '@bettermarks/bm-font';
export { fontKind, FontKind, ensureFontsLoaded } from '@bettermarks/bm-font';
