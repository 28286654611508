import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BettyOops } from '../../components';
import styles from './CRIError.scss';

export default function CRIError() {
  const [t] = useTranslation();
  return (
    <div className={styles.error} data-cy="em-betty-oops">
      <div>{t('errormessages:classroomIntro.loadFailed')}</div>
      <div>
        <BettyOops width={100} height={100} />
      </div>
    </div>
  );
}
