import {
  type CircleObject,
  CIRCLES,
  type Coords,
  type GeoObject,
  type GeoObjectMap,
  type PointObject,
  STRAIGHTLINE,
} from '@bettermarks/gizmo-types';
import { intersectLineCircle, project, squaredEuclidianDistance } from '@bettermarks/importers';
import { isLine } from '../../helpers';

export const perpendicularFoot = (
  mouseP: Coords,
  refId: string,
  geoContentMap: GeoObjectMap<GeoObject>
): Coords => {
  const refObj = geoContentMap[refId];

  if (refObj === undefined) {
    return mouseP;
  }

  if (isLine(refObj)) {
    const p1 = (geoContentMap[refObj.p1Id] as PointObject).coords;
    const p2 = (geoContentMap[refObj.p2Id] as PointObject).coords;
    return project(mouseP, [p1, p2], refObj.type);
  }
  if (refObj.type === CIRCLES) {
    const c = refObj as CircleObject;
    const sorted = intersectLineCircle([c.coords, mouseP], [c.coords, c.radius], STRAIGHTLINE)
      .map((ip) => ({ coords: ip, dist: squaredEuclidianDistance(ip, mouseP) }))
      .sort((a, b) => a.dist - b.dist);
    return sorted[0].coords; // take the closest intersection point
  }
  return mouseP;
};
