import { Severity, Validity } from '@bettermarks/umc-kotlin';
import { rulers } from '../../../../gizmo-utils/configuration/rulers';
import { stylers } from '../../../../gizmo-utils/configuration/stylers';
import { enrichContentDict } from '../../../../gizmo-utils/measure';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { compose, curry, omit, set } from 'lodash/fp';
import { type StateWithHistory } from 'redux-undo';
import { withEmptyHistory, withHistory } from '../../../store/helper';
import {
  type AppExercise,
  type AppStep,
  CollapsibleContentState,
  type ContentStep,
  ExerciseStatus,
  type ExerciseTransformer,
  SeriesMode,
  type StepFeedback,
  StepStatus,
} from '../../../../types';
import { type StepResultResponse } from '../../services/api/content-manager/types';
import { type Attempt, type UserInput } from '../../services/api/result-manager/types';
import {
  isExerciseFilled,
  updateNextExerciseLinearFlow,
  updateNextExerciseNonLinearFlow,
  updatePrevExerciseLinearFlow,
  updatePrevExerciseNonLinearFlow,
} from './updateFlowHelper';

const isAppStep = (step: AppStep | ContentStep): step is AppStep =>
  'solutionState' in step &&
  'explanationState' in step &&
  'numberOfErrors' in step &&
  'startTime' in step &&
  'past' in step.question &&
  'present' in step.question &&
  'future' in step.question;
export const getCompletedQuestion = (step: AppStep | ContentStep, userInput?: UserInput) => {
  let question;

  if (isAppStep(step)) {
    question = step.question.present;
  } else if (userInput) {
    question = userInput.question;
  } else {
    question = step.question;
  }

  return withEmptyHistory(enrichContentDict(question, stylers, rulers, true));
};

export const completeStep = (
  step: AppStep | ContentStep,
  stepResult?: StepResultResponse,
  userInput?: UserInput,
  attempts?: Attempt[],
  testMode?: boolean
): AppStep => {
  const {
    points: { errors, maxTries },
    aborted,
  } = stepResult || { points: { errors: 0, maxTries: 2 }, aborted: false };

  return {
    ...(omit('showBetty', step) as AppStep),
    aborted: isAppStep(step) ? step.aborted : aborted,
    explanationState: testMode ? CollapsibleContentState.hidden : CollapsibleContentState.collapsed,
    ...(userInput && { feedbacks: userInput.feedbacks }),
    ...(attempts && { attempts }),
    numberOfErrors: isAppStep(step) ? step.numberOfErrors : errors,
    question: getCompletedQuestion(step, userInput),
    solutionState:
      testMode && errors ? CollapsibleContentState.expanded : CollapsibleContentState.hidden,
    startTime: isAppStep(step) ? step.startTime : -1,
    status: StepStatus.completed,
    maxTries,
  };
};

/**
 * Creates a history for the step's question which can either be the passed question for
 * AppSteps or a history created from step and userInput depending on the use-case
 * (see below).
 *
 * @param step  The original step
 * @param userInput The user input returned by the backend
 * @param testMode Flag for the series mode
 */
export const getStartedQuestion = (
  step: AppStep | ContentStep,
  userInput?: UserInput,
  testMode?: boolean
): StateWithHistory<ContentDict> => {
  let question: StateWithHistory<ContentDict>;

  if (isAppStep(step)) {
    // for app steps we simply use the step's question that already is `StateWithHistory<ContentDict>`
    question = step.question;
  } else if (userInput && testMode) {
    // in testmode we add the original question to the history to keep the `withInput` state
    // to present we set the passed `userInput` question
    question = withHistory<ContentDict>([step.question], userInput.question);
  } else if (userInput) {
    // in practice mode we set the passed `userInput` question without history
    question = withEmptyHistory(userInput.question);
  } else {
    // when there is no `userInput` passed initialize the question with an empty history
    question = withEmptyHistory(step.question);
  }

  return question;
};

export const startStep = (
  step: AppStep | ContentStep,
  startTime: number,
  stepResult?: StepResultResponse,
  userInput?: UserInput,
  testMode?: boolean
): AppStep => {
  return {
    ...step,
    aborted: isAppStep(step) && step.aborted,
    solutionState: CollapsibleContentState.hidden,
    explanationState: CollapsibleContentState.hidden,
    ...(userInput && { feedbacks: userInput.feedbacks }),
    ...(userInput &&
      userInput.feedbacks.some((f) => f.severity === Severity.error) && {
        validity: Validity.wrong,
      }),
    numberOfErrors: stepResult ? stepResult.points.errors : 0,
    status: stepResult ? StepStatus.attempted : StepStatus.started,
    startTime,
    question: getStartedQuestion(step, userInput, testMode),
    maxTries: isAppStep(step) ? step.maxTries : 2,
  };
};

export const lockStep = (step: AppStep | ContentStep): AppStep => ({
  ...step,
  aborted: isAppStep(step) && step.aborted,
  solutionState: CollapsibleContentState.hidden,
  explanationState: CollapsibleContentState.hidden,
  numberOfErrors: 0,
  status: StepStatus.locked,
  startTime: -1,
  question: isAppStep(step) ? step.question : withEmptyHistory(step.question),
  maxTries: isAppStep(step) ? step.maxTries : 2,
});

export const solveStep = (
  step: AppStep | ContentStep,
  feedbacks: ReadonlyArray<StepFeedback>,
  qaMode?: boolean
): AppStep => ({
  ...step,
  aborted: isAppStep(step) && step.aborted,
  solutionState: CollapsibleContentState.expanded,
  explanationState: CollapsibleContentState.expanded,
  feedbacks,
  numberOfErrors: 0,
  startTime: -1,
  status: StepStatus.attempted,
  validity: Validity.wrong,
  question: isAppStep(step) ? step.question : withEmptyHistory(step.question),
  maxTries: isAppStep(step) ? step.maxTries : 2,
  ...(qaMode && { qaMode }),
});

/**
 * Completes the current step & starts the next Step, rest steps remain untouched.
 * @param {mode} SeriesMode
 * @param {nextStepId} String
 */
export const completeCurrentAndStartNextStep = curry(
  (mode: SeriesMode, nextStepId: string, startTime: number, exercise: AppExercise) => ({
    ...exercise,
    // filtering out undefined in steps array before iterating over it, see:
    //  https://bettermarks.atlassian.net/browse/SPL-109
    steps: exercise.steps.filter(Boolean).map((step: AppStep) => {
      if (step.id === nextStepId) {
        return startStep(step, startTime);
      } else if (step.id === exercise.currentStepId && mode === SeriesMode.practice) {
        return completeStep(step);
      }
      return step;
    }),
  })
);

/**
 * @param linear Linear navigation uses the next-exercise action and is limited to go
 *  from start to end exercises over the exercise list. Non-linear in contrast is a
 *  free navigation by clicking an arbitrary exercise out of the list.  */
export const updateNextExercise = (linear: boolean) => (mode: SeriesMode, startTime: number) =>
  linear
    ? updateNextExerciseLinearFlow(mode, startTime)
    : updateNextExerciseNonLinearFlow(mode, startTime);

export const setFilledStatus: ExerciseTransformer = (exercise) => ({
  ...exercise,
  status: isExerciseFilled(exercise.steps) ? ExerciseStatus.withInput : ExerciseStatus.started,
});

/**
 * @param linear Linear navigation uses the next-exercise action and is limited to go
 *  from start to end exercises over the exercise list. Non-linear in contrast is a
 *  free navigation by clicking an arbitrary exercise out of the list.
 */
export const updatePrevExercise =
  (linear: boolean) =>
  (mode: SeriesMode): ExerciseTransformer =>
    compose(
      set('switched', false) as ExerciseTransformer,
      linear ? updatePrevExerciseLinearFlow(mode) : updatePrevExerciseNonLinearFlow(mode)
    );
