import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { type ContentReference, type SquaredPaperContent } from '@bettermarks/gizmo-types';

export const applySquaredPaperStyles: ApplyStyles = ({ digits }: SquaredPaperContent, style) =>
  digits
    .reduce<ContentReference[]>(
      (refs, digit) => (digit.overlay ? [...refs, digit.overlay] : refs),
      []
    )
    .map(({ $refid: refId }) => ({ refId, style }));

export const measureSquaredPaper: Ruler<SquaredPaperContent> = (_, content) => {
  const height = content.height;
  return {
    height,
    refLine: height * 0.5,
  };
};
