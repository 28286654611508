import { OptionDecorator, RadioButton } from '../../../components';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { type Severity } from '@bettermarks/umc-kotlin';
import * as React from 'react';
import { type ContentReference } from '@bettermarks/gizmo-types';
import {
  BUTTON_WIDTH,
  getRenderState,
  OPTION_DECORATOR_WIDTHS,
} from '../../multiple-choice/constants';
import { OptionDecoratorMode } from '../../../components/MultipleChoice/types';

export type MultipleChoiceItemProps = {
  index: number;
  selected: boolean;
  content: ContentReference;
  availableWidth: number;
  radioButtons?: boolean;
  interactive?: boolean;
  onChosen?: (idx: number) => void;
  severity?: Severity;
  collapsedView?: boolean;
  onHover?: (idx: number, isHovered: boolean) => void;
  stackLayout?: boolean;
};

export const MultipleChoiceSingleItem: React.FC<MultipleChoiceItemProps> = (props) => {
  const {
    index,
    selected,
    content,
    interactive,
    severity,
    radioButtons,
    collapsedView,
    onHover,
    availableWidth,
    stackLayout,
  } = props;

  const showHoveredChoice = interactive
    ? () => {
        if (onHover) {
          onHover(index, true);
        }
      }
    : undefined;

  const hideHoveredChoice = interactive
    ? () => {
        if (onHover) {
          onHover(-1, false);
        }
      }
    : undefined;

  const decoratorProps = {
    mode: OptionDecoratorMode.single,
    state: getRenderState(selected, severity),
    interactive,
    hasButton: radioButtons && !collapsedView,
  };

  const radioProps = {
    state: decoratorProps.state,
    interactive,
    collapsedView,
  };

  const onClick = interactive
    ? () => {
        props.onChosen && props.onChosen(index);
      }
    : undefined;

  return collapsedView ? (
    <OptionDecorator
      {...decoratorProps}
      onClick={onClick}
      onMouseOver={showHoveredChoice}
      onMouseLeave={hideHoveredChoice}
    >
      <RadioButton {...radioProps} />
    </OptionDecorator>
  ) : (
    <OptionDecorator
      {...decoratorProps}
      onClick={onClick}
      onMouseOver={stackLayout ? showHoveredChoice : undefined}
      onMouseLeave={stackLayout ? hideHoveredChoice : undefined}
    >
      {radioButtons ? <RadioButton {...radioProps} /> : undefined}
      <PolymorphicGizmo
        refid={content.$refid}
        availableWidth={
          availableWidth - OPTION_DECORATOR_WIDTHS - Number(radioButtons) * BUTTON_WIDTH
        }
      />
    </OptionDecorator>
  );
};

MultipleChoiceSingleItem.displayName = 'MultipleChoiceSingleItem';
