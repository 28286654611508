import * as React from 'react';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';
import { GraphRenderer } from '../Graph/GraphRenderer';
import {
  TREE_CHART_GIZMO_DEFAULT_CONTENT,
  type TreeChartContent,
  type Size,
} from '@bettermarks/gizmo-types';

export interface TreeChartGizmoCallbacks {
  onNodeSize?: (id: string, size: Size) => void;
}

export type TreeChartGizmoProps = TreeChartContent & ContextState & TreeChartGizmoCallbacks;

/**
 * Renders a tree chart by converting the incoming tree into a graph data structure
 * and passing it to the graph renderer.
 */
export class TreeChartGizmo extends React.Component<TreeChartGizmoProps> {
  override render() {
    const props = { ...TREE_CHART_GIZMO_DEFAULT_CONTENT, ...this.props };
    const { onNodeSize, visible } = props;
    const g = props.graph;

    if (g) {
      // 200 pixels is the minimum width (height) of a tree!
      const height = g.ticksWidth < g.ticksHeight ? Math.max(g.ticksHeight, 200) : g.ticksHeight;
      const width = g.ticksWidth > g.ticksHeight ? Math.max(g.ticksWidth, 200) : g.ticksWidth;
      return (
        <span style={visible ? {} : { visibility: 'hidden' as const }}>
          <GraphRenderer
            availableWidth={this.props.availableWidth}
            onNodeSize={onNodeSize}
            {...g}
            width={g.ticksWidth > g.ticksHeight ? width : (height / g.ticksHeight) * g.ticksWidth}
            height={g.ticksWidth < g.ticksHeight ? height : (width / g.ticksWidth) * g.ticksHeight}
          />
        </span>
      );
    }
    return null;
  }
}
