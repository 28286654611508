import { isNumber } from 'lodash';
import {
  isContentReference,
  isMRow,
  isMSpace,
  isMText,
  type MathContent,
  type Path,
  type PathHandler,
  PathMode,
} from '@bettermarks/gizmo-types';
import { type SelectChildHandler, type SelectHandler, Side } from './components/types';

/**
 * Create mouse event handler for the opening bracket
 * click front -> fence path, insert
 * click back -> first element path, insert
 */
export const selectOpeningFence: (p: Path, ph?: PathHandler) => SelectHandler | undefined = (
  path,
  onSelectPath
) =>
  onSelectPath
    ? (side) => {
        if (side === Side.Front) {
          onSelectPath(path, PathMode.Insert); // just use parent path handler
        } else {
          onSelectPath([...path, 'children', 0], PathMode.Insert);
        }
      }
    : undefined;
/**
 * Create mouse event handler for the closing bracket
 * click front -> last element path, append
 * click back -> fence path, append
 */
export const selectClosingFence: (
  p: Path,
  c: number,
  ph?: PathHandler
) => SelectHandler | undefined = (path, childCount, onSelectPath) =>
  onSelectPath
    ? (side) => {
        if (side === Side.Front) {
          onSelectPath([...path, 'children', childCount - 1], PathMode.Append);
        } else {
          onSelectPath(path, PathMode.Append); // just use parent path handler
        }
      }
    : undefined;

/**
 * Creates undefined if `onSelectPath` is not provided, otherwise a function that
 * puts the cursor at the end or beginning of one element of a root.
 * Since interactive roots always support adding an index this is always possible.
 *
 * Side.Front adds to the beginning, Side.Rear adds to the end of an element.
 *
 * @param path the path of the root in the formula tree
 * @param target the bucket to which to add the cursor
 * @param onSelectPath the top level PathHandler
 *
 * @see Radix
 * @see radixClickHandler
 */
export const selectInRoot = (
  path: Path,
  target: 'index' | 'radicand',
  onSelectPath?: PathHandler
): SelectHandler | undefined =>
  onSelectPath
    ? (side) =>
        onSelectPath([...path, target], side === Side.Front ? PathMode.Insert : PathMode.Append)
    : undefined;

const simpleSelect: (np: Path) => (p: Path, ph?: PathHandler) => SelectHandler | undefined =
  (newPath) => (path, onSelectPath) =>
    onSelectPath
      ? (side) =>
          onSelectPath(
            [...path, ...newPath],
            side === Side.Front ? PathMode.Insert : PathMode.Append
          )
      : undefined;

export const selectNumerator = simpleSelect(['numerator']);
export const selectDenominator = simpleSelect(['denominator']);
export const selectFractionLine = simpleSelect(['numerator']);

export const selectDigit: (p: Path, ph?: PathHandler) => SelectChildHandler | undefined = (
  path,
  onSelectPath
) =>
  onSelectPath
    ? (index, side) =>
        onSelectPath(
          [...path, 'text', index],
          side === Side.Front ? PathMode.Insert : PathMode.Append
        )
    : undefined;

/**
 * "is top level" = is not contained in other m-tags than mrow or mfenced
 */
export const isTopLevel = (path: Path) =>
  path && path.filter((el) => el !== 'children' && !isNumber(el)).length === 0;

type NoBreakBucket = {
  elements: { content: MathContent; index: number }[];
  inline: boolean;
};

// if bucket contains linebreak
// --> inline (<br> inside inline-block is no good)
// if bucket contains text
// --> inline (otherwise unpredictable/too early breaks between no-text-bucket and text-bucket)
// if bucket is mrow (e.g. fem, decorated text, ...)
// --> inline (otherwise unpredictable/too early breaks fem/'decorated text' and surrounding)
const needsInline = (c: MathContent) => isMSpace(c, 'newline') || isMText(c) || isMRow(c);

/**
 * Puts children into "unbreakable buckets"
 * -> line breaks are only allowed BETWEEN the resulting buckets
 * @param children
 */
export const bucketize = (children: MathContent[]): NoBreakBucket[] =>
  children.reduce((acc, c, i, cs) => {
    const pre = cs[i - 1];
    const [currBucket] = acc.slice(-1); // last element in acc
    const prevBuckets = acc.slice(0, -1); // all but last elements in acc
    return (
      // start a fresh bucket, if child is an MROW or a MathAlien, or a successor of them
      // otherwise: add to the last bucket
      // example output:
      // [
      //   [no-mrow/no-alien, no-mrow/no-alien, no-mrow/no-alien],
      //   [mrow],
      //   [no-mrow/no-alien, no-mrow/no-alien],
      //   [math-alien],
      //   [no-mrow/no-alien, no-mrow/no-alien]
      // ]
      isMRow(c) || isContentReference(c) || isMRow(pre) || isContentReference(pre) || i === 0
        ? // new bucket
          [...acc, { elements: [{ content: c, index: i }], inline: needsInline(c) }]
        : // adding to current bucket
          [
            ...prevBuckets,
            {
              elements: [...currBucket.elements, { content: c, index: i }],
              inline: needsInline(c) || currBucket.inline,
            },
          ]
    );
  }, [] as NoBreakBucket[]);
