import * as React from 'react';
import { simpleHash } from '../../gizmo-utils/simpleHash';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import {
  CIRCLES,
  type GeoContent,
  GeoEditorMode,
  type GeoScene,
  isLineHighlight,
  isPointHighlight,
  type LabelStepperProps,
  type MouseOrTouch,
  RAY,
  SEGMENT,
  type SnapPoint as SnapPointType,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import { Axes, BackgroundImage, Grid, QuadrantLabels, SnapPoint } from './components';
import {
  BezierSet,
  CircleSet,
  DynamicBezierSet,
  IntervalSet,
  LabelSet,
  PointSet,
  PreviewSet,
  RaySet,
  ReadingHelpSet,
  SegmentSet,
  StraightlineSet,
  VectorSet,
} from './components/sets';
import { AreaInteractionFilters, ColorToolHoverFilter } from './components/filters';
import { getSnapHighlightObjects } from './snap';
import {
  BORDER_RECT,
  DEFAULT_SNAP_HIGHLIGHT_OBJECTS,
  inViewbox,
  MAX_POINT_RADIUS,
  screenToWorld,
  SVG_BORDER_EXTENSION,
  SVG_CANVAS,
  transformX,
  transformY,
} from '@bettermarks/importers';
import { getGeoScene, getSnapPointDecoration, loadDynamicValues } from './helpers';
import styles from './Geo.scss';
import { PickerWidgetAPO } from './components/primitives/PickerWidgetAPO';
import { isEmpty } from 'lodash/fp';

export interface GeoRendererCallbacks {
  onAnyOtherClick?: () => void;
  onBezierMouseDown?: (id: string) => (evt: MouseOrTouch) => void;
  onBezierMouseOut?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onBezierHover?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onBezierClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onClick?: (evt: React.MouseEvent<any>) => void;
  onLabelClick?: (id: string) => (evt: MouseOrTouch) => void;
  onLabelLeave?: () => void;
  onLabelOver?: () => void;
  onLineClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onMouseDown?: (evt: MouseOrTouch) => void;
  onMouseLeave?: () => void;
  onMouseMove?: (scene: GeoScene) => (evt: MouseOrTouch) => void;
  onTouchStart?: (scene: GeoScene) => (evt: MouseOrTouch) => void;
  onMouseUp?: () => void;
  onPointClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onPointMouseDown?: (id: string) => (evt: MouseOrTouch) => void;
  onPointMouseOver?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onPointMouseUp?: (evt: React.MouseEvent<any>) => void;
  onPointMouseOut?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onLineDown?: (id: string) => (evt: MouseOrTouch) => void;
  onLineOut?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onLineHover?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onCircleDown?: (id: string) => (evt: MouseOrTouch) => void;
  onCircleClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onCircleOver?: (id: string) => (evt: MouseOrTouch) => void;
  onCircleLeave?: (id: string) => (evt: MouseOrTouch) => void;
  onIntervalClick?: (id: string) => (evt: React.MouseEvent<any>) => void;
  onIntervalOver?: (id: string) => (evt: MouseOrTouch) => void;
  onIntervalLeave?: (id: string) => (evt: MouseOrTouch) => void;
}

export type GeoRendererProps = GeoContent &
  GeoRendererCallbacks &
  ContextState & {
    mode: GeoEditorMode;
    snapPoints?: SnapPointType[];
    labelSteppers?: LabelStepperProps[];
  };

/* eslint-disable complexity */
export const GeoRenderer: React.FC<GeoRendererProps> = (props: GeoRendererProps) => {
  const {
    configuration,
    diagonalAxis,
    horizontalAxis,
    verticalAxis,
    mode,
    snapPoints,
    grid,
    geoContentMap: geoContentMapFromProps,
    beziers,
    dynamicBeziers,
    points,
    circles,
    rays,
    segments,
    straightlines,
    vectors,
    labels,
    intervals,
    matrix,
    gridWidth,
    gridHeight,
    totalWidth,
    totalHeight,
    scale,
    backgroundImages,
    previewObjects,
    readinghelps,
    labelSteppers,
    highlight,
    toolValueLabels,
    persistToolValueLabels,
    suppressSnapHighlight,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onAnyOtherClick,
    valueSetterMap,
  } = props;
  const geoContentMap =
    valueSetterMap && !isEmpty(valueSetterMap)
      ? loadDynamicValues(geoContentMapFromProps, valueSetterMap)
      : geoContentMapFromProps;

  const { xMin, xMax, yMin, yMax } = configuration.display;
  const { showBorder } = configuration;

  const tickValueInterval = configuration.tickValueInterval;
  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  const borderRectProps = {
    x: sx(xMin),
    y: sy(yMax),
    width: gridWidth,
    height: gridHeight,
  };

  const borderRect = <rect {...borderRectProps} className={styles.border} />;
  const includeGeoSystemBorder = showBorder ? borderRect : '';

  const borderRectId = `${BORDER_RECT}:${props.uniqueId}`;
  const borderSpace = !!grid || showBorder ? 0.5 : 0;
  let borderExtensionLeft = configuration.borderExtension ? -MAX_POINT_RADIUS : -borderSpace;
  let borderExtensionTop = configuration.borderExtension ? -MAX_POINT_RADIUS : -borderSpace;

  if (props.$interactionType) {
    borderExtensionLeft = -MAX_POINT_RADIUS - SVG_BORDER_EXTENSION.left;
    borderExtensionTop = -MAX_POINT_RADIUS - SVG_BORDER_EXTENSION.top;
  }

  borderExtensionLeft = borderExtensionLeft * scale;
  borderExtensionTop = borderExtensionTop * scale;

  const includeGrid = grid ? <Grid content={grid} matrix={matrix} /> : '';

  const scene = getGeoScene(props);
  const onMove = props.onMouseMove && props.onMouseMove(scene);
  const lineCallbacks = {
    onClick: props.onLineClick,
    onHoverId: props.onLineHover,
    onMouseDownId: props.onLineDown,
    onMouseOutId: props.onLineOut,
  };

  const snapHighlightObjects =
    snapPoints && !suppressSnapHighlight
      ? getSnapHighlightObjects(snapPoints, matrix, scene)
      : { ...DEFAULT_SNAP_HIGHLIGHT_OBJECTS };

  return (
    <div className={styles.relative}>
      <svg
        id={`${SVG_CANVAS}:${props.uniqueId}`}
        width={totalWidth}
        height={totalHeight}
        viewBox={`
          ${borderExtensionLeft}
          ${borderExtensionTop}
          ${totalWidth}
          ${totalHeight}
        `}
        className={styles.system}
        onMouseMove={onMove}
        onTouchMove={onMove}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onTouchStart={props.onTouchStart && props.onTouchStart(scene)}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
      >
        <defs>
          <clipPath id={borderRectId}>{borderRect}</clipPath>
        </defs>
        {grid && grid.quadrantLabelsVisible && (
          <QuadrantLabels {...{ matrix, tickValueInterval, xMin, xMax, yMin, yMax }} />
        )}
        {backgroundImages &&
          backgroundImages.belowGrid &&
          backgroundImages.belowGrid.map((imgContent, i) => (
            <BackgroundImage
              key={`below:${i}`}
              {...imgContent}
              {...{ matrix, scale }}
              valueSetterMap={valueSetterMap}
              borderRect={borderRectProps}
            />
          ))}
        {includeGrid}
        {backgroundImages &&
          backgroundImages.aboveGrid &&
          backgroundImages.aboveGrid.map((imgContent, i) => (
            <BackgroundImage
              key={`above:${i}`}
              {...imgContent}
              {...{ matrix, scale }}
              valueSetterMap={valueSetterMap}
              borderRect={borderRectProps}
            />
          ))}
        <Axes
          {...{
            matrix,
            xMin,
            yMin,
            xMax,
            yMax,
            totalWidth,
            totalHeight,
            configuration,
            scale,
          }}
          horizontal={horizontalAxis}
          vertical={verticalAxis}
          diagonal={diagonalAxis}
        />
        {/* invisible click area to handle clicks that are not targeting objects with click callbacks */}
        {onAnyOtherClick && (
          <rect
            className={styles.invisibleClickArea}
            x={borderExtensionLeft}
            y={borderExtensionTop}
            width={totalWidth}
            height={totalHeight}
            onClick={onAnyOtherClick}
          />
        )}
        <IntervalSet
          {...{
            matrix,
            mode,
            intervals,
            geoContentMap,
            horizontalAxis,
            configuration,
            borderRectId,
          }}
          onHoverId={props.onIntervalOver}
          onMouseOutId={props.onIntervalLeave}
          onClick={props.onIntervalClick}
        />
        <AreaInteractionFilters id={props.uniqueId} />
        <ColorToolHoverFilter id={props.uniqueId} mode={props.mode} />
        <BezierSet
          {...{ matrix, mode, beziers, geoContentMap, borderRectId }}
          geoId={props.uniqueId}
          onMouseDownId={props.onBezierMouseDown}
          onMouseOutId={props.onBezierMouseOut}
          onHoverId={props.onBezierHover}
          onClick={props.onBezierClick}
        />
        <DynamicBezierSet
          {...{ matrix, mode, dynamicBeziers, geoContentMap, borderRectId }}
          geoId={props.uniqueId}
        />
        <CircleSet
          {...{ matrix, mode, circles, geoContentMap, borderRectId }}
          onMouseDownId={props.onCircleDown}
          onMouseOutId={props.onCircleLeave}
          onHoverId={props.onCircleOver}
          onClick={props.onCircleClick}
          snapHighlightIds={snapHighlightObjects[CIRCLES]}
          {...(isPointHighlight(highlight) && { highlight })}
        />
        <StraightlineSet
          {...{
            matrix,
            mode,
            straightlines,
            geoContentMap,
            borderRectId,
            configuration,
          }}
          {...(isLineHighlight(highlight) && { highlight })}
          snapHighlightIds={snapHighlightObjects[STRAIGHTLINE]}
          {...lineCallbacks}
        />
        <RaySet
          {...{
            matrix,
            mode,
            rays,
            geoContentMap,
            borderRectId,
            configuration,
          }}
          {...(isLineHighlight(highlight) && { highlight })}
          snapHighlightIds={snapHighlightObjects[RAY]}
          {...lineCallbacks}
        />
        <SegmentSet
          {...{
            matrix,
            mode,
            segments,
            geoContentMap,
            borderRectId,
            configuration,
          }}
          {...(isLineHighlight(highlight) && { highlight })}
          snapHighlightIds={snapHighlightObjects[SEGMENT]}
          {...lineCallbacks}
        />
        <VectorSet
          {...{
            matrix,
            mode,
            vectors,
            geoContentMap,
            borderRectId,
            configuration,
          }}
          {...(isLineHighlight(highlight) && { highlight })}
          snapHighlightIds={snapHighlightObjects[VECTOR]}
          {...lineCallbacks}
        />
        <ReadingHelpSet
          {...{
            matrix,
            mode,
            readinghelps,
            geoContentMap,
            borderRectId,
            configuration,
          }}
        />
        {includeGeoSystemBorder}
        {previewObjects && previewObjects.lines && (
          <PreviewSet
            {...{ matrix, mode, geoContentMap, borderRectId, configuration }}
            lines={previewObjects.lines}
            width={totalWidth}
            height={totalHeight}
          />
        )}
        <PointSet
          {...{ configuration, matrix, mode, points, geoContentMap }}
          {...(isPointHighlight(highlight) && { highlight })}
          {...(mode === GeoEditorMode.ADD_ANGLE && {
            lineIds: [...rays, ...segments, ...straightlines, ...vectors],
          })}
          onMouseDownId={props.onPointMouseDown}
          onMouseUp={props.onPointMouseUp}
          onHoverId={props.onPointMouseOver}
          onClick={props.onPointClick}
          onMouseOutId={props.onPointMouseOut}
        />
        {previewObjects && (
          <PreviewSet
            {...{ matrix, mode, geoContentMap, borderRectId, configuration }}
            {...{
              points: previewObjects.points,
              angles: previewObjects.angles,
              circles: previewObjects.circles,
              intervals: previewObjects.intervals,
            }}
            width={totalWidth}
            height={totalHeight}
          />
        )}
        <LabelSet
          {...{
            matrix,
            mode,
            labels,
            geoContentMap,
            configuration,
            toolValueLabels,
          }}
          width={totalWidth}
          height={totalHeight}
          onClick={props.onLabelClick}
          onHover={props.onLabelOver}
          onLeave={props.onLabelLeave}
        />
        {persistToolValueLabels && (
          <LabelSet
            {...{
              matrix,
              mode,
              labels,
              geoContentMap,
              configuration,
              toolValueLabels: persistToolValueLabels,
            }}
            width={totalWidth}
            height={totalHeight}
            onClick={props.onLabelClick}
            onHover={props.onLabelOver}
            onLeave={props.onLabelLeave}
          />
        )}
        {snapPoints &&
          snapPoints
            .filter((sp) =>
              inViewbox(screenToWorld(matrix)({ x: sp.x, y: sp.y }), configuration.display)
            )
            .map((snapPoint, i) => (
              <SnapPoint
                key={i}
                {...snapPoint}
                {...{ mode }}
                decoration={getSnapPointDecoration(points, snapPoint, geoContentMap)}
              />
            ))}
      </svg>
      {labelSteppers &&
        labelSteppers
          .filter((s) => s.active)
          .map((s) => (
            <PickerWidgetAPO
              // when using more than one labelsteppers, sometimes key ids are mixed
              // by react ... So -> DO NOT USE just key={i} here!
              key={simpleHash(s)}
              {...{
                matrix,
                borderExtensionLeft,
                borderExtensionTop,
                geoContentMap,
              }}
              configurationDisplay={configuration.display}
              labelStepper={s}
              height={gridHeight}
              width={totalWidth}
            />
          ))}
    </div>
  );
};

GeoRenderer.displayName = 'GeoRenderer';
