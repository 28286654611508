import styled from 'styled-components';
import { defaultTheme } from '@seriesplayer/common-ui';
import { DialogContent } from '../../../../../../components';

export const Content = styled(DialogContent)`
  .multi-line-body {
    white-space: pre-line;
  }

  .comment {
    font-size: ${defaultTheme.dimensions.fontSizeS};
    line-height: ${defaultTheme.dimensions.lineHeightS};
    color: ${defaultTheme.colors.cTextBright};
  }
`;
