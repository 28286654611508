import * as React from 'react';
import qs from 'query-string';
import { Comparison } from './Comparison';
import { useLocation } from 'react-router-dom';

type ParsedExerciseComparisonRouteParams = {
  exerciseId: string;
  exerciseId1?: string;
  exerciseId2?: string;
  staticUrl1?: string;
  staticUrl2?: string;
  qaMode?: boolean;
};

/**
 * Component to display two versions of an exercise next to each other.
 *
 * Example link:
 *
 *  https://gremlins.bettermarks.com/#/exercise-comparison?
 *    exerciseId=de_DE/EX/binaries/demo/02/AT01/test/BinariesDemo02.AT01_ex01&
 *    staticUrl1=http://staticdev.jenkinsdev01.inf00.inf.bm.loc/content_output/&
 *    staticUrl2=https://content-generator-jvm.bettermarks.com
 *
 * Possible query parameters, see ExerciseComparisonRouteParams.
 */
export function ExerciseComparison() {
  const location = useLocation();
  const { exerciseId, exerciseId1, exerciseId2, staticUrl1, staticUrl2, qaMode } = qs.parse(
    location.search,
    {
      parseBooleans: true,
    }
  ) as ParsedExerciseComparisonRouteParams;
  const exercises = [
    {
      exerciseId: exerciseId1 ? exerciseId1 : exerciseId,
      staticUrl: staticUrl1,
    },
    {
      exerciseId: exerciseId2 ? exerciseId2 : exerciseId,
      staticUrl: staticUrl2,
    },
  ];
  const [url1, url2] = exercises.map(
    (e) =>
      `https://gremlins.bettermarks.com/#/series?${qs.stringify({
        exerciseid: e.exerciseId,
        appLocale: e.exerciseId.split('/')[0],
        qaMode,
        ...(e.staticUrl && { staticUrl: e.staticUrl }),
      })}`
  );
  return <Comparison url1={url1} url2={url2} />;
}
