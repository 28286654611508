const SUPER_SECRET_KEY = 'm&aYRKh?Gnf:=H4U';
export const ENCRYPTION_VERSION = 'v1';

const xor = (input: string, key = SUPER_SECRET_KEY) => {
  let output = '';
  for (let i = 0; i < input.length; i++) {
    /* eslint-disable-next-line no-bitwise */
    output += String.fromCharCode(input.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return output;
};

export const decryptContent = (data: string) => {
  const FIELD_SEPARATOR = '////';
  const [, dataRaw] = data.split(FIELD_SEPARATOR);
  return xor(Buffer.from(dataRaw, 'base64').toString()).substring(49);
};
