import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar, Button, ButtonKind, ButtonStretch, StyledBox as Box } from '@seriesplayer/common-ui';
import { ErrorHandleKind, type ErrorPayload } from '../../../../../types';
import {
  BaseLayout,
  Content,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../../../../components';
import { ErrorReason } from '../../../services/exception';

export type ErrorDispatchProps = {
  onCancel: () => void;
  onQuit: () => void;
};

export type ErrorOwnProps = {
  payload: ErrorPayload;
};

export type ErrorProps = ErrorDispatchProps & ErrorOwnProps;

const ERROR_REASON_MESSAGES_MAP: Record<
  ErrorReason,
  { title: string; text: string; dataCy: string }
> = {
  [ErrorReason.DEFAULT]: {
    title: 'seriesplayer:dialog.error.title',
    text: 'seriesplayer:dialog.error.body',
    dataCy: 'general-error',
  },
  [ErrorReason.TEST_HAS_BEEN_COLLECTED]: {
    title: 'seriesplayer:dialog.quitAfterTestsCollected.title',
    text: 'seriesplayer:dialog.quitAfterTestsCollected.text',
    dataCy: 'test-has-been-collected',
  },
  [ErrorReason.SERIES_CANNOT_BE_REVIEWED]: {
    title: 'seriesplayer:dialog.seriesCannotBeReviewed.title',
    text: 'seriesplayer:dialog.seriesCannotBeReviewed.text',
    dataCy: 'series-cannot-be-reviewed',
  },
};

/**
 * Error Component
 * Used to render error messages.
 */
export const Error: React.FC<ErrorProps> = ({ payload, onCancel, onQuit }) => {
  const [t] = useTranslation();
  const { kind, reasonCode = ErrorReason.DEFAULT, description } = payload;
  const { title, text, dataCy } = ERROR_REASON_MESSAGES_MAP[reasonCode as ErrorReason];

  return (
    <BaseLayout vertical textCentered>
      <DialogHeader
        title={t(title)}
        leftButton={
          kind === ErrorHandleKind.confirm
            ? {
                icon: 'ArrowLeftLargeBold',
                onClick: onCancel,
              }
            : undefined
        }
      />
      <Bar />
      <Box stretch>
        <Content as={DialogContent} stretched>
          <p className="plaintext" data-cy={dataCy}>
            {(description && t(description)) || t(text)}
          </p>
          <DialogFooter align="center">
            {payload.kind === ErrorHandleKind.confirm && (
              <Button
                key="confirm"
                kind={ButtonKind.action}
                stretch={ButtonStretch.responsive}
                onClick={onCancel}
              >
                {t('seriesplayer:dialog.error.confirm')}
              </Button>
            )}
            {kind === ErrorHandleKind.quit && (
              <Button
                key="quit"
                kind={ButtonKind.action}
                stretch={ButtonStretch.responsive}
                onClick={onQuit}
              >
                {t('seriesplayer:dialog.error.quit')}
              </Button>
            )}
            {kind === ErrorHandleKind.back && (
              <Button
                key="back"
                kind={ButtonKind.action}
                stretch={ButtonStretch.responsive}
                onClick={window.history.back}
              >
                {t('seriesplayer:dialog.error.back')}
              </Button>
            )}
          </DialogFooter>
        </Content>
      </Box>
    </BaseLayout>
  );
};

Error.displayName = 'Error';
