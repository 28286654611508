import { measureBox } from '../../gizmos/_box/measure';
import { measureHelloGizmo } from '../../gizmos/_template/measure';
import { measureAnimation } from '../../gizmos/animation/measure';
import { measureBasicShape } from '../../gizmos/basic-shape/measure';
import { captionEnricher, pieOrStackedBarChartEnricher } from '../../gizmos/chart/measure';
import { measureClock } from '../../gizmos/clock/measure';
import { measureDayDuration } from '../../gizmos/day-duration/measure';
import { measureDragSource } from '../../gizmos/drag-and-drop/dragsource/measure';
import { measureDropTarget } from '../../gizmos/drag-and-drop/droptarget/measure';
import { measureSetDropTarget } from '../../gizmos/drag-and-drop/set-droptarget/measure';
import { enrichFieldSet } from '../../gizmos/fieldset/measure';
import { formulaEnricher } from '../../gizmos/formula/Formula/measure';
import { measureGeo } from '../../gizmos/geo/measure';
import { measureTreeChartGizmo } from '../../gizmos/graph/TreeChart/measure';
import { measureImage } from '../../gizmos/image/measure';
import { measureAbsoluteLayout } from '../../gizmos/layout-container/measure';
import { measureMultipleChoiceDropdown } from '../../gizmos/multiple-choice-drop-down/measure';
import { measurePointer } from '../../gizmos/pointer/measure';
import { measureSolutionSet } from '../../gizmos/solution-set/measure';
import { measureSquaredPaper } from '../../gizmos/squaredpaper/measure';
import { measureTable } from '../../gizmos/table/measure';
import { measureTimeDuration } from '../../gizmos/time-duration/measure';
import { measureTimeSpan } from '../../gizmos/time-span/measure';
import { measureClockAndTimeGizmo } from '../../gizmos/time/measure';

import { RS } from '@bettermarks/gizmo-types';
import { type RulerRegistry } from './types';
import { measureIncludeGizmo } from '../../gizmos/include-gizmo/measure';
import { measureListSelectionGizmo } from '../../gizmos/list-selection/measure';
import { measureValueStepper } from '../../gizmos/value-stepper/measure';

/**
 * This dictionary maps render styles to rulers
 * (static measuring using content data, instead of measuring rendered DOM elements)
 */
export const rulers: RulerRegistry = {
  [RS.ABSOLUTE_LAYOUT]: measureAbsoluteLayout,
  [RS.ANIMATION]: measureAnimation,
  [RS.BASIC_SHAPE]: measureBasicShape,
  [RS.CAPTION_BAR_CHART]: captionEnricher,
  [RS.CAPTION_CURVE_CHART]: captionEnricher,
  [RS.CAPTION_PIE_CHART]: captionEnricher,
  [RS.CLOCK]: measureClock,
  [RS.CLOCKTIME]: measureClockAndTimeGizmo,
  [RS.DAY_DURATION]: measureDayDuration,
  [RS.DIGIT]: formulaEnricher,
  [RS.DRAG_SOURCE]: measureDragSource,
  [RS.DROP_TARGET]: measureDropTarget,
  [RS.EMPTY]: measureBox,
  [RS.HELLO_GIZMO]: measureHelloGizmo,
  [RS.FIELD_SET]: enrichFieldSet,
  [RS.FORMULA]: formulaEnricher,
  [RS.METRICS_SYSTEM]: measureGeo,
  [RS.IMAGE]: measureImage,
  [RS.INCLUDE_GIZMO]: measureIncludeGizmo,
  [RS.INNER_TEXT]: formulaEnricher,
  [RS.LIST_SELECTION]: measureListSelectionGizmo,
  [RS.MULTIPLE_CHOICE_DROP_DOWN]: measureMultipleChoiceDropdown,
  [RS.PIE_CHART]: pieOrStackedBarChartEnricher,
  [RS.PLACE_VALUE_TABLE]: measureTable,
  [RS.POINTER]: measurePointer,
  [RS.SET_DROP_TARGET]: measureSetDropTarget,
  [RS.SIMPLE_TABLE]: measureTable,
  [RS.SOLUTION_SET]: measureSolutionSet,
  [RS.SQUARED_PAPER]: measureSquaredPaper,
  [RS.STACKED_BAR_CHART]: pieOrStackedBarChartEnricher,
  [RS.TABLECELL_TEXT]: formulaEnricher,
  [RS.TEXT]: formulaEnricher,
  [RS.TIME]: measureClockAndTimeGizmo,
  [RS.TIME_DURATION]: measureTimeDuration,
  [RS.TIMESPAN]: measureTimeSpan,
  [RS.TREE_DIAGRAM]: measureTreeChartGizmo,
  [RS.VALUE_STEPPER]: measureValueStepper,
};
