import { type Content } from '../../xml-converter/core';
import { LineStyle, LineWeight } from '../../types';
import { LINE_HEADER } from '../../gizmo-utils/configuration/renderStyles';
import { SPECIAL } from '../../gizmo-utils/constants';

export interface LineHeaderContent extends Readonly<Content> {
  /**
   * The decoration of the line (before and after text)
   */
  readonly decoration: LineDecoration;
  /**
   * The text of the headline
   */
  readonly text: string;
}

export interface LineDecoration {
  readonly lineStyle: LineStyle;
  readonly lineWeight: LineWeight;
}

export const DEFAULT_LINE_DECORATION: Readonly<LineDecoration> = {
  lineStyle: LineStyle.dashed,
  lineWeight: LineWeight.medium,
};

export const LINE_HEADER_DEFAULT_CONTENT: Readonly<LineHeaderContent> = {
  $: SPECIAL,
  $renderStyle: LINE_HEADER,
  text: '',
  decoration: DEFAULT_LINE_DECORATION,
};
