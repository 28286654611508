import { type SolutionSetContent } from '@bettermarks/gizmo-types';
import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { RS } from '@bettermarks/gizmo-types';
import { SOLUTION_SET_MIN_HEIGHT, SOLUTION_SET_PADDING } from '../components';

export const applySolutionSetStyles: ApplyStyles = (
  { visibleElements, hiddenElements }: SolutionSetContent,
  style
) =>
  [...visibleElements, ...hiddenElements].map(({ $refid: refId }) => ({
    refId,
    style,
  }));

export const measureSolutionSet: Ruler<SolutionSetContent> = (
  computedStyles,
  content,
  getMetrics
) => {
  let height = content.visibleElements.reduce((maxHeightFound, currentChild) => {
    const { height: childHeight } = getMetrics(currentChild.$refid);
    return Math.max(maxHeightFound, childHeight);
  }, 0);

  if (content.$interactionType === RS.SOLUTION_SET) {
    /* remove the paddings because we don't want to consider them when comparing
    the element to the children inside it */

    if (height + SOLUTION_SET_PADDING > SOLUTION_SET_MIN_HEIGHT) {
      height = height + SOLUTION_SET_PADDING * 2;
    } else {
      height = SOLUTION_SET_MIN_HEIGHT;
    }
  }

  const refLine = content.visibleElements.reduce((maxRefLineFound, currentChild) => {
    const { refLine: childrefLine } = getMetrics(currentChild.$refid);
    return Math.max(maxRefLineFound, childrefLine);
  }, 0);

  return {
    height,
    refLine,
    relativeToBaseLine: true,
  };
};
