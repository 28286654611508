import * as React from 'react';
import { type ContentColor } from '@bettermarks/gizmo-types';
import { getNamedColor } from '@bettermarks/importers';
import {
  DEFAULT_PIE_CHART_MARK_EXTENSION,
  DEFAULT_PIE_CHART_STROKE,
  DEFAULT_PIE_CHART_STROKE_WIDTH,
} from '../../defaults';
import { type Severity } from '@bettermarks/umc-kotlin';
import { add, dist, EPS, intersectSegments, midPoint, normUnitVec, smult } from './math';

export type SliceProps = {
  startAngle: number;
  endAngle: number;
  radius: number;
  color: ContentColor;
  mark?: boolean;
  severity?: Severity;
};

export const PieSlice: React.FC<SliceProps> = ({
  startAngle,
  endAngle,
  color,
  radius,
  mark,
  severity,
}) => {
  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDeg: number
  ) => {
    const angleInRadians = ((angleInDeg - 90) * Math.PI) / 180;

    return {
      x: radius * Math.cos(angleInRadians),
      y: radius * Math.sin(angleInRadians),
    };
  };

  const circleArc = (
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number
  ): {
    start: { x: number; y: number };
    end: { x: number; y: number };
    path: string;
  } => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y,
      'L',
      x,
      y,
      'Z',
    ].join(' ');

    return { path: d, start, end };
  };

  const finalRadius = radius + (mark ? DEFAULT_PIE_CHART_MARK_EXTENSION : 0);

  const circleArcOuter = circleArc(0, 0, finalRadius, startAngle, endAngle);
  const circleArcMiddle = circleArc(
    0,
    0,
    finalRadius - DEFAULT_PIE_CHART_STROKE_WIDTH,
    startAngle,
    endAngle
  );
  const circleArcInner = circleArc(
    0,
    0,
    finalRadius - 2 * DEFAULT_PIE_CHART_STROKE_WIDTH,
    startAngle,
    endAngle
  );
  const circleArcInnerMost = circleArc(
    0,
    0,
    finalRadius - 3 * DEFAULT_PIE_CHART_STROKE_WIDTH,
    startAngle,
    endAngle
  );

  const origin = { x: 0, y: 0 };
  const midStart = midPoint(origin, circleArcOuter.start);
  const midEnd = midPoint(origin, circleArcOuter.end);

  const outerS = circleArcMiddle.start;
  const outerE = circleArcMiddle.end;
  const middleS = circleArcInner.start;
  const middleE = circleArcInner.end;
  const innerS = circleArcInnerMost.start;
  const innerE = circleArcInnerMost.end;

  const facOuterOrth = -0.5;
  const facInnerOrth = -2;
  const facInnerMostOrth = -4;

  // need a negative factor (as the stroke is always drawn outside of actual position)
  // helper points for the outer lines (error ? red : white)
  const helpPtStartAngleO1 = add(smult(facOuterOrth, normUnitVec(origin, outerS)), midStart);
  const helpPtStartAngleO2 = add(smult(facOuterOrth, normUnitVec(origin, outerS)), outerS);

  const helpPtEndAngleO1 = add(smult(facOuterOrth, normUnitVec(outerE, origin)), midEnd);
  const helpPtEndAngleO2 = add(smult(facOuterOrth, normUnitVec(outerE, origin)), outerE);

  // intersection point of the two outer lines
  const intersectPtO = intersectSegments(
    { p1: helpPtStartAngleO1, p2: helpPtStartAngleO2 },
    { p1: helpPtEndAngleO2, p2: helpPtEndAngleO1 }
  );

  // helper points for the inner lines (error ? white : color)
  const helpPtStartAngleI1 = add(smult(facInnerOrth, normUnitVec(origin, middleS)), midStart);
  const helpPtStartAngleI2 = add(smult(facInnerOrth, normUnitVec(origin, middleS)), middleS);

  const helpPtEndAngleI1 = add(smult(facInnerOrth, normUnitVec(middleE, origin)), midEnd);
  const helpPtEndAngleI2 = add(smult(facInnerOrth, normUnitVec(middleE, origin)), middleE);

  // intersection point of the two inner lines
  const intersectPtI = intersectSegments(
    { p1: helpPtStartAngleI1, p2: helpPtStartAngleI2 },
    { p1: helpPtEndAngleI2, p2: helpPtEndAngleI1 }
  );

  // helper points for the inner lines (error ? white : color)
  const helpPtStartAngleIM1 = add(smult(facInnerMostOrth, normUnitVec(origin, innerS)), midStart);
  const helpPtStartAngleIM2 = add(smult(facInnerMostOrth, normUnitVec(origin, innerS)), innerS);

  const helpPtEndAngleIM1 = add(smult(facInnerMostOrth, normUnitVec(innerE, origin)), midEnd);
  const helpPtEndAngleIM2 = add(smult(facInnerMostOrth, normUnitVec(innerE, origin)), innerE);

  // intersection point of the two inner lines
  const intersectPtIM = intersectSegments(
    { p1: helpPtStartAngleIM1, p2: helpPtStartAngleIM2 },
    { p1: helpPtEndAngleIM2, p2: helpPtEndAngleIM1 }
  );

  // circle arcs that are drawn in the center of the pie
  // (to fill areas between intersection points and pie center)
  const circleArcInnerVeryMost = circleArc(0, 0, dist(intersectPtO, origin), startAngle, endAngle);

  const circleArcInnerVeryVeryMost = circleArc(
    0,
    0,
    dist(intersectPtI, origin),
    startAngle,
    endAngle
  );

  const circleArcInnerVeryVeryVeryMost = circleArc(
    0,
    0,
    dist(intersectPtIM, origin),
    startAngle,
    endAngle
  );

  return Math.abs(endAngle - startAngle) % 360 === 0 ? (
    <circle r={radius} fill={getNamedColor(color)} />
  ) : (
    <g>
      <path d={circleArcOuter.path} fill={getNamedColor(DEFAULT_PIE_CHART_STROKE)} />
      <path d={circleArcMiddle.path} fill={getNamedColor(severity ? severity : color)} />
      <path
        d={circleArcInner.path}
        fill={getNamedColor(severity ? DEFAULT_PIE_CHART_STROKE : color)}
      />
      <path d={circleArcInnerMost.path} fill={getNamedColor(color)} />
      {Math.abs(180 - Math.abs(endAngle - startAngle)) > EPS ? (
        <>
          <path d={circleArcInnerVeryMost.path} fill={getNamedColor(color)} />
          <path
            d={circleArcInnerVeryVeryMost.path}
            fill={getNamedColor(severity ? DEFAULT_PIE_CHART_STROKE : color)}
          />
          <path
            d={circleArcInnerVeryVeryVeryMost.path}
            fill={getNamedColor(severity ? DEFAULT_PIE_CHART_STROKE : color)}
          />
          <polyline
            points={`${helpPtStartAngleIM2.x}, ${helpPtStartAngleIM2.y}
             ${intersectPtIM.x}, ${intersectPtIM.y} ${helpPtEndAngleIM2.x}, ${helpPtEndAngleIM2.y}`}
            stroke={getNamedColor(severity ? DEFAULT_PIE_CHART_STROKE : color)}
            fill={'none'}
            strokeWidth={DEFAULT_PIE_CHART_STROKE_WIDTH}
          />
          <polyline
            points={`${helpPtStartAngleI2.x}, ${helpPtStartAngleI2.y}
             ${intersectPtI.x}, ${intersectPtI.y} ${helpPtEndAngleI2.x}, ${helpPtEndAngleI2.y}`}
            stroke={getNamedColor(severity ? severity : color)}
            fill={'none'}
            strokeWidth={DEFAULT_PIE_CHART_STROKE_WIDTH}
          />
          <polyline
            points={`${helpPtStartAngleO2.x}, ${helpPtStartAngleO2.y}
             ${intersectPtO.x}, ${intersectPtO.y} ${helpPtEndAngleO2.x}, ${helpPtEndAngleO2.y}`}
            stroke={getNamedColor(DEFAULT_PIE_CHART_STROKE)}
            fill={'none'}
            strokeLinecap={severity ? 'butt' : 'round'}
            strokeWidth={1}
          />
        </>
      ) : (
        <>
          <polyline
            points={`${helpPtStartAngleIM2.x}, ${helpPtStartAngleIM2.y}
             ${helpPtEndAngleIM2.x}, ${helpPtEndAngleIM2.y}`}
            stroke={getNamedColor(severity ? DEFAULT_PIE_CHART_STROKE : color)}
            fill={'none'}
            strokeWidth={DEFAULT_PIE_CHART_STROKE_WIDTH}
          />
          <polyline
            points={`${helpPtStartAngleI2.x}, ${helpPtStartAngleI2.y}
             ${helpPtEndAngleI2.x}, ${helpPtEndAngleI2.y}`}
            stroke={getNamedColor(severity ? severity : color)}
            fill={'none'}
            strokeWidth={DEFAULT_PIE_CHART_STROKE_WIDTH}
          />
          <polyline
            points={`${helpPtStartAngleO2.x}, ${helpPtStartAngleO2.y}
             ${helpPtEndAngleO2.x}, ${helpPtEndAngleO2.y}`}
            stroke={getNamedColor(DEFAULT_PIE_CHART_STROKE)}
            fill={'none'}
            strokeLinecap={severity ? 'butt' : 'round'}
            strokeWidth={1}
          />
        </>
      )}
      // make the lines sharp
      <polyline
        points={`${circleArcOuter.start.x}, ${circleArcOuter.start.y}
             0, 0 ${circleArcOuter.end.x}, ${circleArcOuter.end.y}`}
        stroke={getNamedColor(DEFAULT_PIE_CHART_STROKE)}
        fill={'none'}
        strokeLinecap={'round'}
        strokeWidth={1}
      />
    </g>
  );
};

PieSlice.displayName = 'PieSlice';
