import type { Coords } from '@bettermarks/gizmo-types';
import { norm } from '@bettermarks/importers';
import type { ToolInfo } from './types';

export type GapFiller = (
  ctx: CanvasRenderingContext2D | null | undefined,
  prevPos: Coords | undefined,
  currPos: Coords,
  toolInfo: ToolInfo
) => void;

export const fillGaps: GapFiller = (ctx, prevPos, currPos, { toolConfig, selectedTool }) => {
  const dist = (prevPos && norm(prevPos, currPos)) || 0;
  if (!ctx || prevPos === undefined) return;
  ctx.fillStyle = toolConfig.color;
  ctx.strokeStyle = ctx.fillStyle;
  const thickness = selectedTool === 'pen' ? toolConfig.penThickness : toolConfig.eraserThickness;
  if (dist > thickness) {
    ctx.beginPath();
    ctx.moveTo(prevPos.x, prevPos.y);
    ctx.lineWidth = thickness * 2;
    ctx.lineTo(currPos.x, currPos.y);

    ctx.stroke();
  }
  ctx.beginPath();
  ctx.arc(currPos.x, currPos.y, thickness, 0, 2 * Math.PI);
};
