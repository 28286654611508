import { type Action, createAction, handleActions } from 'redux-actions';
import { isEmpty } from 'lodash';
import { GEO_DEFAULT_PERSIST_PROPS, transformX, transformY } from '@bettermarks/importers';
import { type GeoMovePointsVerticallyState } from './GeoMovePointsVertically';
import { deepResetSeverity } from '../helpers';
import {
  type GeoContentPersistProps,
  type PointObject,
  type SnapPoint,
} from '@bettermarks/gizmo-types';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */

export interface DragPayload {
  matrix: number[];
  snapPoint: SnapPoint;
}

export interface StartDragPayload {
  id: string;
  matrix: number[];
}

export interface EndDragPayload {
  onPersistLocalState?: (props: GeoContentPersistProps) => void;
}

export type GeoMovePointsVerticallyPayload =
  | StartDragPayload
  | DragPayload
  | EndDragPayload
  | SnapPoint
  | void;

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const DRAG: 'DRAG' = 'DRAG';
export const dragAction = createAction<DragPayload>(DRAG);
const END_DRAG: 'END_DRAG' = 'END_DRAG';
export const endDragAction = createAction<EndDragPayload>(END_DRAG);
const START_DRAG: 'START_DRAG' = 'START_DRAG';
export const startDragAction = createAction<StartDragPayload>(START_DRAG);
const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPoint>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState = {
  selectedPoint: {
    id: '',
    screenX: NaN,
  },
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
};

export const geoMovePointsVerticallyReducer = handleActions<
  GeoMovePointsVerticallyState,
  GeoMovePointsVerticallyPayload
>(
  {
    [START_DRAG]: (state: GeoMovePointsVerticallyState, { payload }: Action<StartDragPayload>) => {
      if (!payload) {
        return state;
      }

      const { id, matrix } = payload;
      const contentMap = state.persistProps.geoContentMap;

      return {
        ...state,
        selectedPoint: {
          id,
          screenX: transformX(matrix)((contentMap[id] as PointObject).coords.x),
        },
        snapPoints: [],
        // reset severity of point and segments referring to point
        persistProps: {
          ...state.persistProps,
          geoContentMap: deepResetSeverity(contentMap, id),
        },
      };
    },
    [DRAG]: (state: GeoMovePointsVerticallyState, { payload }: Action<DragPayload>) => {
      if (!payload || isEmpty(state.selectedPoint.id)) {
        return state;
      }

      const { matrix, snapPoint } = payload;
      const selectedPoint = state.persistProps.geoContentMap[state.selectedPoint.id] as PointObject;

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [state.selectedPoint.id]: {
              ...selectedPoint,
              coords: {
                ...selectedPoint.coords,
                y: transformY(matrix, false)(snapPoint.y),
              },
            },
          },
        },
      };
    },
    [SNAP]: (state: GeoMovePointsVerticallyState, { payload }: Action<SnapPoint>) => ({
      ...state,
      snapPoints: payload ? [payload] : [],
    }),
    [STOP_SNAP]: (state: GeoMovePointsVerticallyState) => ({
      ...state,
      snapPoints: [],
    }),
    [END_DRAG]: (state: GeoMovePointsVerticallyState, { payload }: Action<EndDragPayload>) => {
      if (!payload) {
        return state;
      }

      const { onPersistLocalState } = payload;

      onPersistLocalState && onPersistLocalState(state.persistProps);

      return {
        ...initialState,
        persistProps: state.persistProps,
      };
    },
  },
  initialState
);
