import * as React from 'react';
import classNames from 'classnames';
import Measure, { type ContentRect, type MeasuredComponentProps } from 'react-measure';
import { Overlay } from '../Overlay';

import { STATE_SELECTED, STATE_UNSELECTED, STATE_ERROR } from './constants';
import styles from './DropdownOptionList.scss';
import { type DropdownOptionProps } from './DropdownOption';
import { getOnTopZIndex } from '../../utils/z-index/update-z-index';
const minMargin = parseFloat(styles.minMargin);

export interface DropdownOptionListProps {
  position: { top: number; left: number };
  scrollPosition: number;
  selectedIndex: number;
  open: boolean;
  defaultWidth: number;
  documentHeight: number;
  maxWidth?: string;
  limitOptionWidth?: boolean;
  overlayId?: string;
  onItemSelected: (index: number, content: JSX.Element) => void;
  onResizeList?: (clientRect: ContentRect) => void;
  onResizeListItem: (index: number, clientRect: ContentRect) => void;
}

const renderChildren = (children: React.ReactNode, props: DropdownOptionListProps) =>
  React.Children.map(children, (child: React.ReactElement<DropdownOptionProps>, i: number) =>
    React.cloneElement(child, {
      onItemClicked: props.onItemSelected.bind(null, i),
      onResizeListItem: props.onResizeListItem.bind(null, i),
      state:
        child.props.state === STATE_ERROR
          ? STATE_ERROR
          : i === props.selectedIndex
          ? STATE_SELECTED
          : STATE_UNSELECTED,
    })
  );

export class DropdownOptionList extends React.Component<DropdownOptionListProps> {
  domNode: HTMLDivElement;

  onRef = (n: HTMLDivElement) => {
    this.domNode = n;
  };

  componentWillUpdate() {
    if (this.domNode) {
      this.domNode.scrollTop = this.props.scrollPosition;
    }
  }

  render() {
    const {
      defaultWidth,
      maxWidth,
      children,
      limitOptionWidth,
      documentHeight,
      onResizeList,
      position,
      open,
      overlayId,
    } = this.props;
    return (
      <Overlay overlayId={overlayId}>
        <Measure
          bounds={true}
          offset={true}
          scroll={true}
          onResize={onResizeList}
          innerRef={this.onRef}
        >
          {({ measureRef }: MeasuredComponentProps) => (
            <div
              ref={measureRef}
              style={{
                top: position.top,
                ...(limitOptionWidth && { right: 10 }),
                left: position.left,
                minWidth: defaultWidth,
                maxWidth,
                maxHeight: documentHeight - 2 * minMargin,
                zIndex: getOnTopZIndex(),
              }}
              className={classNames(styles.optionList, {
                [styles.opened]: open,
                [styles.closed]: !open,
              })}
              data-cy="drop-down-list"
            >
              <ul>{renderChildren(children, this.props)}</ul>
            </div>
          )}
        </Measure>
      </Overlay>
    );
  }
}
