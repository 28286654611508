import classNames from 'classnames';
import { type HasStroke, numberFromStyles } from '@bettermarks/gizmo-types';
import { DEFAULT_FONT_SIZE } from '../../../../../gizmo-utils/measure';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { RADIX_CONTENT_SPACING, RADIX_PADDING_BOTTOM } from '../constants';
import { type SelectHandler, Side } from '../types';

import styles from './Root.scss';

const HOOK_WIDTH = numberFromStyles(styles.RADIX_HOOK_WIDTH);
const HOOK_HALF_WIDTH = HOOK_WIDTH / 2;

/*
 * The CONTENT_BUFFER is the size of the top slice for the roof and of the radicand content.
 * It is much a constant value in the viewBox that get's stretched according to the actual
 * dimensions of the radicand. The number should be larger than 1, because otherwise it gets
 * rounded and disappears on certain zoom levels (especially in Chrome).
 */
const CONTENT_BUFFER = numberFromStyles(styles.RADIX_CONTENT_BUFFER);

/**
 * Creates a click hadler for a click on the Radix component that is not covered by any child
 *
 * @param onSelectRadix
 * @param onSelectIndex optional because it also works for SquareRoots without an index
 */
const radixClickHandler =
  (onSelectRadix: SelectHandler, onSelectIndex?: SelectHandler) =>
  (event: React.MouseEvent<HTMLDivElement>) => {
    // prevent overriding handlers inside radicand/index
    if (event.currentTarget !== event.target) return;
    /*
     offsetX === 0 is where the hook starts on the top
     simplified aproach for cursor position:

        A |  B1  |  B2  |  C
          |      |      |
          |     /|------|-- ... --|
          |    /                  |
          |   /                   |
       \  |  /
        \ | /
         \|/

     A, B1 and B2 have the same width (HOOK_HALF_WIDTH),
     the width of C is unknown since it depends on the radicand content width
     - clicks in area A go to the end of the index content
     - clicks in area B1 and B2 go to the start of radix content
     - clicks in area C go to the end of radix content
    */
    const offsetX = event.nativeEvent.offsetX;

    if (offsetX > HOOK_HALF_WIDTH) {
      // C
      onSelectRadix(Side.Rear);
    } else if (Math.abs(offsetX) < HOOK_HALF_WIDTH) {
      // B1 and B2
      onSelectRadix(Side.Front);
    } else if (onSelectIndex) {
      // A
      onSelectIndex(Side.Rear);
    }
  };

type RadixProps = HasStroke & {
  radicandHeight: number;
  radicandRefLine: number;
  color: string;
  onSelectRadix?: SelectHandler;
  onSelectIndex?: SelectHandler;
  style?: React.CSSProperties;
};

const xOff = (h: number, w: number, strokeWidth: number) => {
  const len = Math.sqrt(w * w + h * h);

  return (strokeWidth * h) / len + (w / h) * (h + (strokeWidth * w) / len - h - strokeWidth);
};

/*
 * Radix - a component that draws a radix (for <msqrt> / <mroot>) as a border image.
 * The border looks like this:
 *
 *                    (roof)
 *              /------------------|
 *             /                   |  (dash)
 *            /                    |
 *      \    /
 *       \  /
 *        \/
 *
 * The radicand is the content. We add padding above the content, below the content and to
 * the right (contained within the radix border-image):
 *
 *
 *                                  (RADIX_CONTENT_SPACING)
 *                                           ↔
 *                                   /--------|
 *                                  /         |  ↕ (RADIX_CONTENT_SPACING)
 *                                 /   \ /    |
 *                    ↑  ---      /     X        ← the radicand
 *  radicand refLine: |     \    /     / \
 *                    ↓      \  /
 *                            \/                 ↕ (RADIX_PADDING_BOTTOM)
 *
 * For the minWidth to work (even for empty radicands)
 * `style` must contain the relevant `fontSize`.
 */
export const Radix: React.FC<RadixProps> = ({
  radicandHeight = 0,
  radicandRefLine = 0,
  strokeWidth,
  color = styles.DEFAULT_FONT_COLOR,
  onSelectRadix,
  onSelectIndex,
  children,
  style = { fontSize: DEFAULT_FONT_SIZE },
}) => {
  const height = RADIX_PADDING_BOTTOM + radicandHeight + RADIX_CONTENT_SPACING + CONTENT_BUFFER;
  const width = HOOK_WIDTH + CONTENT_BUFFER + CONTENT_BUFFER;
  const viewBox = `0 0 ${width} ${height}`;

  const xOffLeft = xOff(radicandRefLine, HOOK_HALF_WIDTH, strokeWidth);
  const xOffRight = xOff(height, HOOK_HALF_WIDTH, strokeWidth);

  const deltaFactor =
    (radicandHeight +
      radicandRefLine -
      (2 * radicandHeight * (xOffLeft + xOffRight)) / HOOK_WIDTH) /
    (radicandHeight + radicandRefLine);

  const deltaInnerTipX = deltaFactor * HOOK_HALF_WIDTH;
  const deltaInnerTipY = deltaFactor * (radicandRefLine + RADIX_PADDING_BOTTOM);

  const dashHeight = strokeWidth * 2.5;

  const path = [
    // draw the dash at the end (top right)
    `M${width} 0`,
    `v${CONTENT_BUFFER + dashHeight}`,
    `h${-CONTENT_BUFFER}`,
    `V${CONTENT_BUFFER}`,

    // draw the "roof"
    `h${-CONTENT_BUFFER}`,

    // draw the hook
    `L${HOOK_HALF_WIDTH} ${height}`,
    `L0 ${height - RADIX_PADDING_BOTTOM - radicandRefLine + strokeWidth * 0.5}`,

    // draw the minimal index line (left)
    `v${-strokeWidth}`,

    // back to the hook
    `h${xOffLeft}`,
    `l${deltaInnerTipX} ${deltaInnerTipY}`,
    `L${HOOK_WIDTH - xOffRight} ${CONTENT_BUFFER - strokeWidth}`,
    'V0',

    // close the hook back to the top right corner
    'z',
  ].join('');

  /* For debugging purposes, the following <path>s can be added to the svg:

      <path style={{fill: 'green', strokeWidth: 0}} d={[
        `M0 ${CONTENT_BUFFER}`,
        `v${RADIX_CONTENT_SPACING}`,
        `H${HOOK_WIDTH}`,
        `v${-RADIX_CONTENT_SPACING}`,
        'z'
      ].join('')}/>
      <path style={{fill: 'yellow', strokeWidth: 0}} d={[
        `M0 ${height}`,
        `v${-RADIX_PADDING_BOTTOM}`,
        `H${HOOK_WIDTH}`,
        `v${RADIX_PADDING_BOTTOM}`,
        'z'
      ].join('')}/>
      <path style={{fill: 'red', strokeWidth: 0}} d={[
        `M0 ${height - RADIX_PADDING_BOTTOM - radicandRefLine + strokeWidth * 0.5}`,
        `v${-strokeWidth}`,
        `H${HOOK_WIDTH}`,
        `v${strokeWidth}`,
        'z'
      ].join('')}/>

   */

  const base64Svg = btoa(
    renderToStaticMarkup(
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <path style={{ fill: color, strokeWidth: 0 }} d={path} />
      </svg>
    )
  );

  /*
   * Radix has to set a height to be rendered correctly, so we reuse here the CSS
   * of Bucket rather than using another component that would create another DOM element
   * around the content.
   */
  return (
    <div
      className={classNames(styles.radix, styles.bucket)}
      style={{
        ...style,
        height: radicandHeight,
        borderTopWidth: strokeWidth,
        borderRightWidth: strokeWidth,
        borderImageSource: `url(data:image/svg+xml;base64,${base64Svg})`,
      }}
      onMouseDown={onSelectRadix && radixClickHandler(onSelectRadix, onSelectIndex)}
      role={onSelectRadix && 'button'}
    >
      {children}
    </div>
  );
};

Radix.displayName = 'Radix';
