import * as React from 'react';
import { defaultTo } from 'lodash';
import {
  type AdvancedAxisObject,
  AxisNullPosition,
  type AxisObject,
  type AxisPosition,
  type GeoConfiguration,
  isAdvancedAxis,
} from '@bettermarks/gizmo-types';
import { Axis } from './Axis';
import { AdvancedAxis } from './AdvancedAxis';
import { AxesNullLabel } from './AxesNullLabel';

export type AxesProps = {
  matrix: number[];
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
  totalWidth: number;
  totalHeight: number;
  configuration: GeoConfiguration;
  scale: number;
  horizontal?: AxisObject | AdvancedAxisObject;
  vertical?: AxisObject | AdvancedAxisObject;
  diagonal?: AxisObject | AdvancedAxisObject;
};

/* eslint-disable complexity */
export const Axes: React.FC<AxesProps> = ({
  matrix,
  xMin,
  yMin,
  xMax,
  yMax,
  totalWidth,
  totalHeight,
  horizontal,
  vertical,
  diagonal,
  configuration,
  scale,
}) => {
  const { display, nullLabelPosition, tickValueInterval, showNullLabel } = configuration;
  const scaleNullLabel: number = isAdvancedAxis(horizontal) && isAdvancedAxis(vertical) ? 1 : scale;
  return (
    <g>
      {horizontal && !isAdvancedAxis(horizontal) && (
        <Axis {...{ matrix, nullLabelPosition, scale }} {...horizontal} min={xMin} max={xMax} />
      )}
      {horizontal && isAdvancedAxis(horizontal) && (
        <AdvancedAxis
          {...{ matrix, totalWidth, totalHeight }}
          {...horizontal}
          min={{ x: horizontal.onlyPositive ? 0 : xMin, y: 0 }}
          max={{
            x:
              xMin +
              Math.min(defaultTo<number>(horizontal.width, Infinity), display.width) *
                tickValueInterval.x,
            y: 0,
          }}
        />
      )}
      {vertical && !isAdvancedAxis(vertical) && (
        <Axis {...{ matrix, nullLabelPosition, scale }} {...vertical} min={yMin} max={yMax} />
      )}
      {vertical && isAdvancedAxis(vertical) && (
        <AdvancedAxis
          {...{ matrix, totalWidth, totalHeight }}
          {...vertical}
          min={{ x: 0, y: vertical.onlyPositive ? 0 : yMin }}
          max={{
            x: 0,
            y:
              yMin +
              Math.min(defaultTo<number>(vertical.height, Infinity), display.height) *
                tickValueInterval.y,
          }}
        />
      )}
      {diagonal && isAdvancedAxis(diagonal) && (
        <AdvancedAxis
          {...{ matrix, totalWidth, totalHeight }}
          {...diagonal}
          min={(diagonal.position as AxisPosition).min}
          max={(diagonal.position as AxisPosition).max}
        />
      )}
      {
        // conditions to render "0" at the origin
        // covering the cases where "0" has not been rendered as tick label,
        // but is specifically asked for via `showNullLabel` configuration
        showNullLabel &&
          nullLabelPosition === AxisNullPosition.SouthWest &&
          !diagonal &&
          ((vertical && !isAdvancedAxis(vertical) && vertical.tickLabelInterval !== -1) ||
            isAdvancedAxis(vertical) ||
            (horizontal && !isAdvancedAxis(horizontal) && horizontal.tickLabelInterval !== -1) ||
            isAdvancedAxis(horizontal)) && (
            // Here it comes
            //  |
            //  |____
            // 0
            <AxesNullLabel {...{ matrix, scale: scaleNullLabel }} />
          )
      }
    </g>
  );
};

Axes.displayName = 'Axes';
