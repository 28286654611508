import {
  annotationInner,
  type DayDurationGizmoContent,
  exportContent,
  type Exporter,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportDayDurationGizmo: Exporter = (contentRefId, context) => {
  const content = context.content[contentRefId] as DayDurationGizmoContent;

  return semantics(
    `
  <mrow>
    <configuration>
      <direction>${content.direction}</direction>
    </configuration>
    ${exportContent(content.labelRef.$refid, context)}
  </mrow>
  `,
    annotationInner(content.$, content)
  );
};
