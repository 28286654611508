import * as React from 'react';
import classNames from 'classnames';
import { defaultTo, flatten, isEmpty, isNaN, isNil } from 'lodash';
import {
  FlexAlign,
  FlexWrap,
  HLayoutChildWrapper,
  StyledHLayout,
  StyledVLayout,
  VLayoutChildWrapper,
} from '@seriesplayer/common-ui';

import { PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import { ShadowScrollbars } from '../components';

import { type BarChartGroup, type MouseOrTouch } from '@bettermarks/gizmo-types';
import {
  BarGroup,
  BarGroupLabel,
  ReadingHelp,
  readingHelpCoords,
  XAxis,
  YAxis,
} from './components/bar';
import {
  DEFAULT_ARROW_LENGTH,
  DEFAULT_AXIS_LABEL_MARGIN,
  DEFAULT_BAR_GAP,
  DEFAULT_BAR_WIDTH,
  DEFAULT_CHAR_WIDTH,
  DEFAULT_GROUPS_GAP_MEDIUM,
  DEFAULT_GROUPS_GAP_SMALL,
  DEFAULT_LEGEND_GAP,
  DEFAULT_XAXIS_LABEL_SPACE,
  DEFAULT_XAXIS_SPACE,
  DEFAULT_YAXIS_ADDITIONAL_BOTTOM_SPACE,
  DEFAULT_YAXIS_SPACE,
} from './defaults';
import { type BarChartProps } from './BarChartEditor';

import styles from './BarChartRenderer.scss';
import chartsStyles from './charts.scss';
import {
  addTouchStartListener,
  onMove,
  onTouchEnd,
  onTouchStart,
  removeTouchStartListener,
} from './preventScrollingTouch';

export type BarChartState = {
  groupsGap: number;
  xAxisLabelWidth: number;
  yAdditionalSpace: number;
  measured: { xAxis: boolean; groups: boolean[] };
  initialMeasurement: boolean;
};

export class BarChartRenderer extends React.Component<BarChartProps, BarChartState> {
  state = {
    groupsGap: !isNil(this.props.groups[0].label)
      ? DEFAULT_GROUPS_GAP_MEDIUM
      : DEFAULT_GROUPS_GAP_SMALL,
    xAxisLabelWidth: DEFAULT_XAXIS_LABEL_SPACE,
    yAdditionalSpace: DEFAULT_YAXIS_SPACE,
    measured: {
      xAxis: isNil(this.props.xLabel),
      groups: [...Array(this.props.groups.length)].map((_, idx) =>
        isNil(this.props.groups[idx].label)
      ),
    },
    initialMeasurement: !isNil(this.props.xLabel) || this.props.labeledGroups > 0,
  };

  componentDidMount() {
    if (this.barChartRef.current) {
      addTouchStartListener(this.barChartRef);
    }
  }

  componentWillUnmount() {
    if (this.barChartRef.current) {
      removeTouchStartListener(this.barChartRef);
    }
  }

  barChartRef = React.createRef<HTMLElement>();

  onMoveStart = (evt: MouseOrTouch) => (groupIdx: number, itemIdx: number) => {
    if (this.props.isTouch) {
      onTouchStart(evt);
    }

    this.props.onMoveStart && this.props.onMoveStart(groupIdx, itemIdx);
  };

  onMoveEnd = () => {
    this.props.onMoveEnd && this.props.onMoveEnd();

    if (this.props.isTouch) {
      onTouchEnd();
    }
  };

  onMove = (evt: any) => {
    if (this.props.isTouch) {
      onMove(evt);
    }

    this.props.onMove && this.props.onMove(evt);
  };

  onGroupMeasured = (idx: number) => (mWidth: number, mHeight: number, tooThick: boolean) =>
    this.setState((prevState) => ({
      groupsGap: tooThick
        ? Math.max(prevState.groupsGap, mWidth - DEFAULT_GROUPS_GAP_MEDIUM)
        : prevState.groupsGap,
      yAdditionalSpace: Math.max(
        prevState.yAdditionalSpace,
        mHeight + DEFAULT_YAXIS_ADDITIONAL_BOTTOM_SPACE
      ),
      measured: {
        ...prevState.measured,
        groups: [
          ...prevState.measured.groups.slice(0, idx),
          true,
          ...prevState.measured.groups.slice(idx + 1),
        ],
      },
      initialMeasurement:
        !prevState.measured.xAxis ||
        prevState.measured.groups.filter((g) => g).length < this.props.labeledGroups - 1,
    }));

  onXAxisLabelMeasured = (width: number) =>
    this.setState((prevState) => ({
      xAxisLabelWidth: width + 2 * DEFAULT_AXIS_LABEL_MARGIN,
      measured: {
        ...prevState.measured,
        xAxis: true,
      },
      initialMeasurement:
        prevState.measured.groups.filter((g) => g).length < this.props.labeledGroups,
    }));

  render() {
    const {
      activeItem,
      availableWidth,
      axisContraction,
      caption,
      configuration,
      cursor,
      disabled,
      groups,
      $interactionType,
      onHandleOver,
      onHandleLeave,
      onMove,
      title,
      totalHeight,
      xLabel,
      uniqueId,
      yAxisHeight,
      yLabel,
    } = this.props;

    const { yTickValueInterval, yTickValueStart } = configuration;

    const groupsGap = this.state.groupsGap;
    let currentPos = groupsGap / 2;
    const xPos = flatten([
      groupsGap / 2,
      groups.map((g: BarChartGroup) => {
        currentPos +=
          groupsGap + g.items.length * DEFAULT_BAR_WIDTH + (g.items.length - 1) * DEFAULT_BAR_GAP;
        return currentPos;
      }),
    ]);
    const width = Math.ceil(
      currentPos +
        (configuration.xAxisArrow ? DEFAULT_ARROW_LENGTH : 0) +
        (!isNil(xLabel) ? this.state.xAxisLabelWidth : 0)
    );
    const readingHelpTicks = flatten(
      groups.reduce(
        (acc, g) => [
          ...acc,
          g.items.reduce(
            (acc2, i) =>
              i.readingHelp || i.severity
                ? [
                    ...acc2,
                    {
                      value: (i.yValue - yTickValueStart) / yTickValueInterval,
                      severity: i.severity,
                    },
                  ]
                : acc2,
            []
          ),
        ],
        []
      )
    );

    const activeYValue =
      activeItem && !isNaN(activeItem[0])
        ? groups[activeItem[0]].items[activeItem[1]].yValue
        : undefined;

    const additionalLabelSpace = configuration.yTickValueEnd.toString().length * DEFAULT_CHAR_WIDTH;
    const shadowScrollBarMaxWidth = availableWidth - DEFAULT_XAXIS_SPACE - additionalLabelSpace;

    return (
      <div
        className={classNames(styles.barChart, {
          [chartsStyles.invisible]: this.state.initialMeasurement,
        })}
        style={{ width: availableWidth }}
      >
        <StyledVLayout align={FlexAlign.center}>
          {title && (
            <VLayoutChildWrapper noMargin>
              <div className={chartsStyles.title}>
                <PolymorphicGizmo refid={title.$refid} availableWidth={width} />
              </div>
            </VLayoutChildWrapper>
          )}
          <VLayoutChildWrapper noMargin={!title}>
            <StyledHLayout gap={caption ? DEFAULT_LEGEND_GAP : 0}>
              <HLayoutChildWrapper>
                <StyledHLayout gap={0} wrap={FlexWrap.NOWRAP}>
                  <YAxis
                    key={`YAxis_${uniqueId}`}
                    id={defaultTo(uniqueId, '')}
                    configuration={configuration}
                    label={yLabel}
                    readingHelpTicks={readingHelpTicks}
                    activeValue={activeYValue}
                    axisContraction={axisContraction}
                    availableLabelWidth={width}
                    additionalXSpace={additionalLabelSpace}
                    interactive={!isEmpty($interactionType)}
                  />
                  <ShadowScrollbars
                    availableWidth={shadowScrollBarMaxWidth}
                    // Since we already know the width of the chart, we pass it via
                    // fixedChildScrollWidth to avoid endless flickering between
                    // - rendering into overflow and
                    // - hiding the overflow in ShadowScrollbars
                    // See:
                    // https://bettermarks.atlassian.net/browse/BM-52413
                    // https://bettermarks.atlassian.net/browse/BM-53268
                    fixedChildScrollWidth={width}
                    style={{
                      height: totalHeight + this.state.yAdditionalSpace,
                    }}
                  >
                    <div className={styles.relative} style={{ cursor }}>
                      <svg
                        width={width}
                        height={totalHeight + this.state.yAdditionalSpace}
                        viewBox={`0 0 ${width} ${totalHeight + this.state.yAdditionalSpace}`}
                        onMouseMove={onMove}
                        onMouseUp={this.onMoveEnd}
                        onMouseLeave={this.onMoveEnd}
                        onTouchMove={onMove}
                        onTouchEnd={this.onMoveEnd}
                      >
                        <XAxis
                          id={defaultTo(uniqueId, '')}
                          y={yAxisHeight - 1 + axisContraction}
                          width={
                            width -
                            (configuration.xAxisArrow ? DEFAULT_ARROW_LENGTH : groupsGap / 2)
                          }
                          hasArrow={configuration.xAxisArrow}
                          label={xLabel}
                          onLabelMeasured={this.onXAxisLabelMeasured}
                          labelSpace={!isNil(xLabel) ? this.state.xAxisLabelWidth : 0}
                        />
                        {groups.map((g, idx) => (
                          <BarGroup
                            key={`BarGroup_${uniqueId}_${idx}`}
                            xOffset={xPos[idx]}
                            disabled={!!disabled}
                            items={g.items}
                            label={g.label}
                            yTickValueInterval={yTickValueInterval}
                            yTickValueStart={yTickValueStart}
                            height={yAxisHeight}
                            axisContraction={axisContraction}
                            onMouseDown={(evt) => (itemIdx) => this.onMoveStart(evt)(idx, itemIdx)}
                            onHandleOver={onHandleOver && ((itemIdx) => onHandleOver(idx, itemIdx))}
                            onHandleLeave={
                              onHandleLeave && ((itemIdx) => onHandleLeave(idx, itemIdx))
                            }
                          />
                        ))}
                        {
                          // always render the reading help (label+dashed line)
                          // of the active item on top
                          activeItem && !isNaN(activeItem[0]) && (
                            <ReadingHelp
                              idx={activeItem[0]}
                              {...readingHelpCoords(
                                activeItem[1],
                                groups[activeItem[0]].items[activeItem[1]].yValue,
                                xPos[activeItem[0]],
                                yAxisHeight,
                                yTickValueStart,
                                yTickValueInterval
                              )}
                            />
                          )
                        }
                      </svg>
                      {groups.map((g, idx) => {
                        const label = this.props.groups[idx].label;
                        const width = g.items.reduce(
                          (acc, _, itemIdx) =>
                            acc + DEFAULT_BAR_WIDTH + +(itemIdx > 0) * DEFAULT_BAR_GAP,
                          xPos[idx]
                        );
                        return (
                          label && (
                            <BarGroupLabel
                              key={`BarGroupLabel_${uniqueId}_${idx}`}
                              content={label}
                              x={xPos[idx] + (width - xPos[idx]) / 2}
                              y={yAxisHeight}
                              availableWidth={width - xPos[idx] + DEFAULT_GROUPS_GAP_MEDIUM}
                              onGroupMeasured={this.onGroupMeasured(idx)}
                            />
                          )
                        );
                      })}
                    </div>
                  </ShadowScrollbars>
                </StyledHLayout>
              </HLayoutChildWrapper>
              {!isNil(caption) && (
                <HLayoutChildWrapper>
                  <PolymorphicGizmo
                    refid={caption.$refid}
                    availableWidth={availableWidth - width}
                  />
                </HLayoutChildWrapper>
              )}
            </StyledHLayout>
          </VLayoutChildWrapper>
        </StyledVLayout>
      </div>
    );
  }
}
