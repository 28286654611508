import * as React from 'react';
import classNames from 'classnames';

import {
  type CircleDecoration,
  type Coords,
  GeoEditorMode,
  type Hover,
  type MouseOrTouch,
  numberFromStyles,
  PointHighlight,
} from '@bettermarks/gizmo-types';
import {
  DEFAULT_CIRCLE_DECORATION,
  getNamedColor,
  getStrokeWidth,
  scale,
  worldToScreen,
} from '@bettermarks/importers';
import { getLineStyle, getObjectColor } from './decorations';
import { PointMove } from '../../../components/icons/geo';

import pointStyles from './Point.scss';
import circleStyles from './Circle.scss';
import { type Severity } from '@bettermarks/umc-kotlin';
import { getHoverColor } from '../tools/helpers';

export type CircleProps = {
  id: string;
  coords: Coords;
  radius: number;
  matrix: number[];
  borderRectId: string;
  decoration?: CircleDecoration;
  selectable?: boolean;
  addedByUser?: true;
  mode?: GeoEditorMode;
  highlight?: PointHighlight;
  severity?: Severity;
  hover?: Hover;
  onMouseDown?: (evt: MouseOrTouch) => void;
  onMouseLeave?: (evt: MouseOrTouch) => void;
  onMouseOver?: (evt: MouseOrTouch) => void;
  onClick?: (evt: React.MouseEvent<any>) => void;
};

/* eslint-disable-next-line complexity*/
export const Circle: React.FC<CircleProps> = ({
  id,
  coords: { x, y },
  radius,
  matrix,
  mode,
  decoration,
  addedByUser,
  selectable,
  borderRectId,
  highlight,
  severity,
  hover,
  onMouseDown,
  onMouseOver,
  onMouseLeave,
  onClick,
}) => {
  const screenCoords = worldToScreen(matrix)({ x, y });
  const screenRadius = scale(matrix)(radius);

  const interactive =
    (addedByUser || selectable) && (onMouseDown || onMouseLeave || onMouseOver || onClick);

  const circleDeco: CircleDecoration = {
    ...DEFAULT_CIRCLE_DECORATION,
    ...decoration,
    // decoration.marked is only used by the COLORING tool, which is only used to color AREAS
    // -> fillColor
    ...(decoration &&
      decoration.marked && {
        fillColor: getNamedColor(decoration.marked, true),
      }),
    ...(severity && { color: getNamedColor(severity) }),
    ...(severity &&
      decoration &&
      decoration.marked && { fillColor: getNamedColor(severity, true) }),
    ...getHoverColor(hover),
  };

  const areaStyles: React.CSSProperties = {
    fill: getObjectColor(circleDeco, 'fillColor'),
    fillOpacity: circleDeco.fillTransparency,
  };

  const styles: React.CSSProperties = {
    stroke: getObjectColor(circleDeco),
    strokeWidth: getStrokeWidth(circleDeco, hover),
    strokeDasharray: getLineStyle(circleDeco.borderStyle, getStrokeWidth(circleDeco)),
    strokeLinecap: 'round',
    ...areaStyles,
  };

  const iconSize = numberFromStyles(pointStyles.DEFAULT_HIGHLIGHT_ICON_SIZE);

  return (
    <>
      <circle
        cx={screenCoords.x}
        cy={screenCoords.y}
        r={screenRadius}
        style={styles}
        clipPath={`url(#${borderRectId})`}
      />
      {highlight === PointHighlight.move && addedByUser ? (
        <PointMove
          width={iconSize}
          height={iconSize}
          x={screenCoords.x - iconSize * 0.5}
          y={screenCoords.y - iconSize * 0.5}
        />
      ) : null}
      {interactive && (
        <circle
          cx={screenCoords.x}
          cy={screenCoords.y}
          r={screenRadius}
          style={areaStyles}
          className={classNames(circleStyles.default, {
            [circleStyles.delete]:
              mode === GeoEditorMode.DELETE || (mode as string).includes(GeoEditorMode.SELECT),
            [circleStyles.color]: (mode as string).includes(GeoEditorMode.COLORING),
          })}
          clipPath={`url(#${borderRectId})`}
          {...{ onMouseDown, onMouseOver, onMouseLeave, onClick, id }}
          onTouchStart={onMouseDown}
        />
      )}
    </>
  );
};

Circle.displayName = 'Circle';
