import { getBaseMetadata as getBaseMetadataUnstubable } from '../..';
import { save } from '../bm-api';
import { ReportProblemManagerRoutes } from './constants';
import type { WhiteboardProblemReportPayload, ProblemReport } from './types';

export const STUBABLE = {
  getBaseMetadata: getBaseMetadataUnstubable,
};

export const saveProblemReport = async (
  baseURL: string,
  problemReport: ProblemReport
): Promise<number> =>
  save(
    [
      ReportProblemManagerRoutes.problemReport(baseURL),
      { ...problemReport, metadata: STUBABLE.getBaseMetadata() },
    ],
    1
  );

export const saveSubmitWhiteboardHelpMenuDialog = async (
  whiteboardProblemReport: WhiteboardProblemReportPayload
): Promise<number> =>
  save(
    [
      ReportProblemManagerRoutes.whiteboardProblemReport(whiteboardProblemReport.resultManagerUrl),
      { ...whiteboardProblemReport },
    ],
    1
  );
