import React from 'react';
import type { ActionStack } from '../whiteboard/types';
import { useWhiteboardContext } from '../whiteboard/whiteboard-context';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '@seriesplayer/common-ui';
import { TOOLBAR_BORDER } from '../whiteboard/toolbar/constants';

export type ExampleNavigationItem = 'exercise' | 'solution' | 'comparison';
type CanvasActionStackMap = Record<ExampleNavigationItem, ActionStack>;
type CanvasHeightMap = Record<ExampleNavigationItem, number>;
type ExampleNavigationProps = {
  toolbarsWidth: number;
};

const NoWrapText = styled.span`
  text-wrap: none;
`;

type NavigationBarProps = {
  toolbarsWidth: number;
};

const NavigationBar = styled.nav<NavigationBarProps>`
  left: ${(props) => props.toolbarsWidth};
  border-bottom: ${TOOLBAR_BORDER}px solid ${colors.cGray500};
  background: ${colors.cGray100};
  font-size: 0;
  line-height: 0;
  height: 44px;
  padding: 0 12px 0;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
`;

type ExampleNavigationButtonProps = {
  idx: number;
  active: boolean;
};

const ExampleNavigationButton = styled.a<ExampleNavigationButtonProps>`
  display: inline-block;
  vertical-align: middle;
  margin-left: ${(props) => (props.idx === 0 ? 0 : 12)}px;
  padding-top: 8px;
  min-height: 44px;
  min-width: 32px;
  cursor: pointer;
  color: ${(props) => (props.active ? '#FFF' : colors.cBlue700)};
  box-sizing: border-box;
`;

type ExampleNavigationButtonLabelProps = {
  active: boolean;
};

const ExampleNavigationButtonLabel = styled.div<ExampleNavigationButtonLabelProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => (props.active ? '#FFF' : colors.cBlue700)};
  border: 2px solid ${colors.cBlue700};
  padding: 2px 8px;
  border-radius: 4px;
  position: relative;
  background-color: ${(props) => (props.active ? colors.cBlue700 : undefined)};
  &:hover {
    background-color: ${(props) => !props.active && '#0000000d'};
  }
`;

export function ExampleNavigation({ toolbarsWidth }: ExampleNavigationProps) {
  const [t] = useTranslation();

  const {
    dispatch,
    state: { canvasActionStack, selectedNavigationItem, canvasHeight, selectedTool },
  } = useWhiteboardContext();

  //TODO: add comparison
  const navigationItems: ExampleNavigationItem[] = ['exercise', 'solution'];

  const [canvasActionStackMap, setCanvasActionStackMap] = React.useState<CanvasActionStackMap>({
    comparison: [],
    exercise: [],
    solution: [],
  });

  const [canvasHeightMap, setCanvasHeightMap] = React.useState<CanvasHeightMap>({
    comparison: 0,
    exercise: 0,
    solution: 0,
  });

  const onNavigationChange = (newNavigationItem: ExampleNavigationItem) => {
    dispatch({
      type: 'navigation',
      payload: {
        canvasActionStack: canvasActionStackMap[newNavigationItem],
        selectedNavigationItem: newNavigationItem,
        canvasHeight: canvasHeightMap[newNavigationItem],
      },
    });

    if (selectedTool === 'content-tools' && newNavigationItem !== 'exercise') {
      dispatch({
        type: 'tool',
        payload: 'cursor',
      });
    }

    setCanvasActionStackMap((oldMap: CanvasActionStackMap) => ({
      ...oldMap,
      [selectedNavigationItem]: canvasActionStack,
    }));

    setCanvasHeightMap((oldMap: CanvasHeightMap) => ({
      ...oldMap,
      [selectedNavigationItem]: canvasHeight,
    }));
  };

  return (
    <NavigationBar toolbarsWidth={toolbarsWidth}>
      {navigationItems.map((item, idx) => {
        const active = item === selectedNavigationItem;

        return (
          <ExampleNavigationButton
            idx={idx}
            active={active}
            key={idx}
            onClick={() => onNavigationChange(item)}
            data-cy={`example-navigation-${item}`}
          >
            <ExampleNavigationButtonLabel active={active}>
              <NoWrapText>{t(`seriesplayer:dialog.exampleMode.${item}`)}</NoWrapText>
            </ExampleNavigationButtonLabel>
          </ExampleNavigationButton>
        );
      })}
    </NavigationBar>
  );
}
