import * as React from 'react';
import { Point } from '../Point';
import { type SetProps } from './types';
import { inViewbox, TEMP_POINT } from '@bettermarks/importers';
import {
  type Coords,
  type GeoConfiguration,
  GeoEditorMode,
  type GeoObject,
  type GeoObjectMap,
  type PointDecoration,
  PointHighlight,
  type PointObject,
} from '@bettermarks/gizmo-types';
import { isPointOnAnyLine } from '../../helpers';

export type PointSetProps = SetProps & {
  points: ReadonlyArray<string>;
  configuration: GeoConfiguration;
  lineIds?: string[];
  highlight?: PointHighlight;
  onMouseUp?: (evt: any) => void;
  onMouseOver?: (evt: any) => void;
  onClick?: (evt: any) => void;
  onMouseOutId?: (evt: any) => void;
};

// ToDo: Refactor => try to get rid of tool differentiations here
function getPointHighLight(
  mode: GeoEditorMode,
  geoContentMap: GeoObjectMap<GeoObject>,
  point: Coords,
  highlight?: PointHighlight,
  lineIds?: string[],
  id?: string
): PointHighlight | undefined {
  // only highlight the currently moved point
  if (mode === GeoEditorMode.MOVE && id === TEMP_POINT) {
    return PointHighlight.move;
  }

  // Do not highlight points for ADD_ANGLE if point doesn't lie on a line
  if (mode !== GeoEditorMode.ADD_ANGLE || isPointOnAnyLine(geoContentMap, point, lineIds)) {
    return highlight;
  }
}

export const PointSet: React.FC<PointSetProps> = ({
  highlight,
  lineIds,
  points,
  mode,
  geoContentMap,
  matrix,
  onMouseDownId,
  onMouseUp,
  onClick,
  onHoverId,
  onMouseOutId,
  configuration,
}) => {
  return (
    <g>
      {points
        .filter((p) => inViewbox((geoContentMap[p] as PointObject).coords, configuration.display))
        .map((id) => {
          const {
            coords: { x, y },
            addedByUser,
            invisible,
            severity,
            hover,
          } = geoContentMap[id] as PointObject;
          const decoration = (geoContentMap[id] as PointObject).decoration as PointDecoration;
          const hasInteractionType = !!(geoContentMap[id] as PointObject).interactionType;

          return (
            <Point
              key={id}
              {...{
                id,
                decoration,
                matrix,
                x,
                y,
                mode,
                invisible,
                severity,
                addedByUser,
                hover,
                onClick,
                onMouseUp,
                hasInteractionType,
              }}
              highlight={getPointHighLight(mode, geoContentMap, { x, y }, highlight, lineIds, id)}
              onMouseDown={onMouseDownId}
              onMouseOver={onHoverId}
              onMouseOut={onMouseOutId}
            />
          );
        })}
    </g>
  );
};

PointSet.displayName = 'PointSet';
