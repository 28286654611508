import { type FontWeight } from '@bettermarks/bm-font';
import classNames from 'classnames';
import {
  ABSOLUTE_BAR,
  ANGLE_CLOSE,
  ANGLE_OPEN,
  CURLY_CLOSE,
  CURLY_OPEN,
  type FenceType,
  type HasHeight,
  NO_FENCE,
  numberFromStyles,
  ROUND_CLOSE,
  ROUND_OPEN,
  SQUARE_CLOSE,
  SQUARE_OPEN,
  type ValidFenceType,
} from '@bettermarks/gizmo-types';
import { defaultTo } from 'lodash';
import * as React from 'react';

import { DEFAULT_FONT_WEIGHT } from '../constants';
import {
  AbsoluteBar,
  AngleClose,
  AngleOpen,
  CurlyClose,
  CurlyOpen,
  RoundClose,
  RoundOpen,
  SquareClose,
  SquareOpen,
} from '../images';
import { type MProps } from '../types';

import styles from './MFenced.scss';

export const FENCE_VERTICAL_SHIFT = numberFromStyles(styles.FENCE_VERTICAL_SHIFT);
const DEFAULT_FONT_COLOR: string = defaultTo<string>(styles.DEFAULT_FONT_COLOR, 'red');

export type FenceProps = MProps &
  HasHeight & {
    fence: FenceType;
    onClick?: React.MouseEventHandler<any>;
  };

// eslint-disable-next-line @typescript-eslint/ban-types
type SvgSFC = React.SFC<React.SVGAttributes<{}>>;
type FenceTuple = [string, SvgSFC, boolean];

const FENCE_COMPONENTS: { [key in ValidFenceType]: FenceTuple } = {
  [ABSOLUTE_BAR]: ['absolute-bar', AbsoluteBar, false],
  [ANGLE_OPEN]: ['angle-open', AngleOpen, false],
  [ANGLE_CLOSE]: ['angle-close', AngleClose, false],
  [CURLY_OPEN]: ['curly-open', CurlyOpen, false],
  [CURLY_CLOSE]: ['curly-close', CurlyClose, false],
  [ROUND_OPEN]: ['round-open', RoundOpen, true],
  [ROUND_CLOSE]: ['round-close', RoundClose, true],
  [SQUARE_OPEN]: ['square-open', SquareOpen, false],
  [SQUARE_CLOSE]: ['square-close', SquareClose, false],
};

/**
 * The `Fence' component renders the below fence types.ts of <mfenced>
 * Angle Fences,
 * Curly Fences,
 * Round Fences,
 * Square Fences,
 * Absolutebar,
 * No Fence
 */

export const Fence: React.FC<FenceProps> = ({
  fence = NO_FENCE,
  height,
  computedStyles,
  onClick,
}) => {
  if (fence === NO_FENCE) {
    return null;
  }
  const [fenceName, FenceComponent, applyFillColor] = FENCE_COMPONENTS[fence];

  const color = defaultTo(computedStyles.color, DEFAULT_FONT_COLOR);
  const style: React.CSSProperties = { stroke: color };
  if (applyFillColor) {
    /* Applying fill to other shapes, such as curly braces, would make them be treated as
    if they were closed shapes, leading to weird visual artifacting. */
    style.fill = color;
  }
  const fontWeight = defaultTo<FontWeight>(computedStyles.fontWeight, DEFAULT_FONT_WEIGHT);

  return (
    <FenceComponent
      className={classNames(styles.fence, styles[fenceName], styles[fontWeight])}
      style={style}
      preserveAspectRatio="none"
      viewBox="0 0 1 1"
      height={height}
      width={5}
      role="button"
      onMouseDown={onClick}
    />
  );
};

Fence.displayName = 'Fence';
