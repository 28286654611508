import { Severity } from '@bettermarks/umc-kotlin';
import {
  BINDING,
  DEFAULT_TEXT,
  FLAVOUR,
  SELECTED,
  GAP,
  HALIGN,
  HIDE_CONTENT,
  ID,
  INTERACTION_TYPE,
  LAYOUT,
  MAX_INPUT_LENGTH,
  MIN_INPUT_WIDTH,
  NO_SCALE,
  PLACEHOLDER,
  POSX,
  POSY,
  REF_ID,
  RENDER_STYLE,
  REPLACEMENTS,
  SELECTED_MODE,
  SPECIAL,
  TOOL_SET,
  VALIGN,
} from '../../gizmo-utils/constants';
import { toBoolean, toInt } from '../../gizmo-utils/filters';
import { decodeHtml5Entities } from '../../lib/html-entities';
import { isNaN, isNil, isUndefined, noop } from 'lodash';
import { DOMParser, type Options } from '@xmldom/xmldom';
import { type ContentDict, type ContentKind } from '../core';
import { type Annotations, type Content } from './types';
import { createFElement, type FElement } from './xml';
import { type EditorMode, isHAlign, isVAlign, translateVAlign } from '../../gizmo-utils/types';
import log from 'loglevel';

const IGNORE_XML_WARNINGS = (): Options => ({
  errorHandler: {
    warning: noop,
  },
});

export const toXmlDoc = (str: string, options: Options = IGNORE_XML_WARNINGS()): XMLDocument => {
  return new DOMParser(options).parseFromString(decodeHtml5Entities(str));
};

export const toXmlElement = (str: string, options?: Options): FElement => {
  try {
    return createFElement(toXmlDoc(str, options).documentElement);
  } catch {
    log.warn({
      message: 'Failed to create XML element',
      extra: {
        rawStr: str,
      },
    });
    return createFElement(null);
  }
};

/**
 * Creates a string containing the XML of  semantics node as specified in MathML
 * and used a lot inside our content.
 *
 * @param mrowXML the XML of the actual data, specific to the renderStyle
 * @param annotationInner the XML containing [[Content]] attributes starting with `$`
 *        look at [[annotationInner]] for a helper if you don't want to build that string yourself
 */
export const semantics = (mrowXML: string, annotationInner: string): string => `
<semantics>
  ${mrowXML}
  <annotation-xml encoding="bettermarks">${annotationInner}</annotation-xml>
</semantics>
`;

export const placeholder = (annotations?: Partial<Annotations>) =>
  annotationInner(PLACEHOLDER, annotations);

export const omitAttr = (value?: boolean | number | string) =>
  // native isNaN  behaves strange with strings, so using lodash.isNaN
  isNil(value) || isNaN(value as number) || value === '';

export const special = (annotations?: Partial<Annotations>) =>
  annotationInner(SPECIAL, annotations);

/**
 * Creates an xml attribute `key="value"`
 * @param key The key of the xml attribute
 * @param value The value of the xml attribute
 * @param omit The attribute is not returned if the omit-function returns true
 */
export const xmlAttr = (key: string, value?: boolean | number | string, omit = omitAttr) =>
  omit(value) ? '' : ` ${key}="${value}"`;

/* eslint-disable complexity */
export const annotationInner = (contentKind: ContentKind, content?: Partial<Annotations>): string =>
  `<${contentKind} ${xmlAttr(RENDER_STYLE, content && content.$renderStyle)}${xmlAttr(
    INTERACTION_TYPE,
    content && content.$interactionType
  )}${xmlAttr(ID, content && content.$id)}${xmlAttr(BINDING, content && content.binding)}${xmlAttr(
    DEFAULT_TEXT,
    content && content.defaultText
  )}${xmlAttr(FLAVOUR, content && content.flavour)}${xmlAttr(
    HIDE_CONTENT,
    content && content.hidden
  )}${xmlAttr(NO_SCALE, content && content.noScale)}${xmlAttr(
    LAYOUT,
    content && content.layout
  )}${xmlAttr(REF_ID, content && content.xmlRefId)}${xmlAttr(
    REPLACEMENTS,
    content && content.replacements
  )}${xmlAttr(SELECTED, content && content.selected, (val) => val !== true)}${xmlAttr(
    MIN_INPUT_WIDTH,
    content && content.minInputWidth
  )}${xmlAttr(MAX_INPUT_LENGTH, content && content.maxInputLength)}${xmlAttr(
    GAP,
    content && content.gap
  )}${xmlAttr(HALIGN, content && content.hAlign)}${xmlAttr(
    VALIGN,
    content && content.vAlign && translateVAlign(content.vAlign)
  )}${xmlAttr(POSX, content && content.posX)}${xmlAttr(POSY, content && content.posY)}${xmlAttr(
    SELECTED_MODE,
    content && content.$interactionType && content.selectedMode
  )}${xmlAttr(TOOL_SET, content && content.toolSet)}/>`;

/* eslint-disable complexity */
export const getAnnotationAttribs = (element: FElement): Partial<Annotations> => {
  const result: Partial<Annotations> = {};
  if (element.hasAttribute(ID)) {
    result.$id = element.attribute(ID);
  }
  if (element.hasAttribute(RENDER_STYLE)) {
    result.$renderStyle = element.attribute(RENDER_STYLE);
  }
  if (element.hasAttribute(INTERACTION_TYPE) && element.attribute(INTERACTION_TYPE) !== '') {
    result.$interactionType = element.attribute(INTERACTION_TYPE);
  }
  if (element.hasAttribute(BINDING)) {
    result.binding = element.attribute(BINDING);
  }
  if (element.hasAttribute(DEFAULT_TEXT)) {
    result.defaultText = toBoolean(element.attribute(DEFAULT_TEXT));
  }
  if (element.hasAttribute(FLAVOUR)) {
    result.flavour = element.attribute(FLAVOUR);
  }
  if (element.hasAttribute(SELECTED)) {
    result.selected = true;
  }
  if (element.hasAttribute(GAP)) {
    result.gap = toInt(element.attribute(GAP));
  }
  if (element.hasAttribute(HALIGN)) {
    const hAlign = element.attribute(HALIGN);
    result.hAlign = isHAlign(hAlign) ? hAlign : undefined;
  }
  if (toBoolean(element.attribute(HIDE_CONTENT))) {
    result.hidden = true;
  }
  if (toBoolean(element.attribute(NO_SCALE))) {
    result.noScale = true;
  }
  if (element.hasAttribute(LAYOUT)) {
    result.layout = element.attribute(LAYOUT);
  }
  if (element.hasAttribute(REF_ID)) {
    result.xmlRefId = element.attribute(REF_ID);
  }
  if (element.hasAttribute(REPLACEMENTS)) {
    result.replacements = element.attribute(REPLACEMENTS);
  }
  if (element.hasAttribute(TOOL_SET)) {
    result.toolSet = element.attribute(TOOL_SET);
  }
  if (element.hasAttribute(SELECTED_MODE)) {
    result.selectedMode = element.attribute(SELECTED_MODE) as EditorMode;
  }
  if (element.hasAttribute(MIN_INPUT_WIDTH)) {
    result.minInputWidth = toInt(element.attribute(MIN_INPUT_WIDTH));
  }
  if (element.hasAttribute(MAX_INPUT_LENGTH)) {
    result.maxInputLength = toInt(element.attribute(MAX_INPUT_LENGTH));
  }
  if (element.hasAttribute(POSX)) {
    result.posX = toInt(element.attribute(POSX));
  }
  if (element.hasAttribute(POSY)) {
    result.posY = toInt(element.attribute(POSY));
  }
  if (element.hasAttribute(VALIGN)) {
    const vAlign = element.attribute(VALIGN);
    result.vAlign = isVAlign(vAlign) ? translateVAlign(vAlign) : undefined;
  }

  return result;
};

/**
 * Gets the severity for a Gizmo based on severity of it's children
 * - The severity of highest order from children is applied to the gizmo.
 * - Order of severity: Severity.error > Severity.remark > Default (undefined)
 * @param {string[]} childrenRefIds
 * @param {ContentDict} contentDict
 * @return {boolean}
 */
export const getSeverityFromChildren = (
  childrenRefIds: string[],
  contentDict: ContentDict
): Severity => {
  const finalSeverity = childrenRefIds.reduce((accSeverity: Severity, refId: string) => {
    const content = contentDict[refId] as Content;
    if (content) {
      switch (content.severity) {
        case Severity.remark:
          if (isUndefined(accSeverity)) {
            return content.severity;
          } else {
            return accSeverity;
          }
        case Severity.error:
          return content.severity;
        default:
          return accSeverity;
      }
    } else {
      return accSeverity;
    }
  }, undefined);
  return finalSeverity as Severity;
};
