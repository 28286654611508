import * as React from 'react';

import { ContentRoot, LocaleProvider } from '../../gizmo-utils/polymorphic-gizmo';
import { type Dispatch } from '../../gizmo-utils/redux/types';
import { type ContentDict, numberFromStyles } from '@bettermarks/gizmo-types';

import { isTouchDevice } from '../seriesplayer/services/runtime-manager/responsive';
import { gizmos } from './configuration';
import { useBoundsMeasure } from '../../gizmo-utils/useMeasure';
import { reportHeightToParent } from './helper';
import { isEmpty } from 'lodash';
import emStyles from './EM/EM.scss';

export type KEMContentProps = Readonly<{
  dispatch: Dispatch;
  locale: string;
  kemData: ContentDict;
  width: number;
  staticMediaUrl: string;
  id: string;
  inBook?: boolean;
}>;

const EM_CONTENT_ELEMENT_CONTENT_MARGIN_TOTAL = numberFromStyles(
  emStyles.EM_CONTENT_ELEMENT_CONTENT_MARGIN_TOTAL
);

export const KEMContent: React.FC<KEMContentProps> = ({
  dispatch,
  locale,
  kemData,
  width,
  staticMediaUrl,
  id,
  inBook,
}) => {
  const [{ contentHeight }, contentRef] = useBoundsMeasure();

  React.useEffect(
    () =>
      inBook && contentHeight
        ? reportHeightToParent(
            contentHeight +
              (isEmpty(kemData.title) ? 1 : 0) * EM_CONTENT_ELEMENT_CONTENT_MARGIN_TOTAL,
            id,
            'kem'
          )
        : undefined,
    [contentHeight, inBook]
  );

  return (
    <LocaleProvider contentLocale={locale}>
      <div ref={contentRef}>
        <ContentRoot
          availableWidth={width}
          contentDict={kemData}
          gizmoRegistry={gizmos}
          dispatch={dispatch}
          isTouch={isTouchDevice()}
          staticMediaUrl={staticMediaUrl}
        />
      </div>
    </LocaleProvider>
  );
};
