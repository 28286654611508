export const enum Stretch {
  auto = 'auto',
  stretched = 'stretched',
}

export const enum FlexWrap {
  WRAP = 'wrap',
  NOWRAP = 'nowrap',
}

export const enum FlexAlign {
  flexStart = 'flex-start',
  center = 'center',
  flexEnd = 'flex-end',
}

export const enum JustifyContent {
  spaceAround = 'space-around',
  spaceBetween = 'space-between',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
}

export interface ZIndexProps {
  zIndex?: number;
}
export const enum ValidationType {
  action = 'action',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export const enum ModalSize {
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
}
