import { compose, entries, get, identity, isNil, omit, set } from 'lodash/fp';
import { type Content, ContentDict, type Direction, Lens } from '@bettermarks/gizmo-types';
import { last } from 'lodash';
// The Exercise type as it is used in the app (with some additional properties)
import { type CoreExercise } from '../../src/types/internal';
import { type DialogType } from '../types';
import { AppStep, type CollapsibleContentState, type Question, StepStatus } from './AppStep';

export const enum ExerciseStatus {
  started = 'started',
  completed = 'completed',
  review = 'review',
  withInput = 'withInput',
}

export const enum ExerciseValidity {
  wrong = 'wrong',
  correct = 'correct',
  sufficient = 'sufficient',
}

export type AdditionalHelpType = DialogType.hints | DialogType.textbook | undefined;

export type ExerciseDisplayStatus = ExerciseValidity | ExerciseStatus | undefined;

export const enum ContentType {
  KEM = 'kem',
  FEM = 'fem',
  CRI = 'cri',
  EXERCISE = 'exercise',
  SCAFFOLDER = 'scaffolder',
}

interface IAppExercise extends CoreExercise {
  currentStepId?: string;
  handIn?: boolean;
  lastSelection?: string; // last selected gizmo refid
  navigatedAwayFromExercise?: boolean; // stores the visual state for feedbacks & explanation.
  nextStepId?: string;
  selectDirection?: Direction;
  startTime: number;
  stepValidationLoaded?: boolean;
  steps: ReadonlyArray<AppStep>; // AppExercise has steps of type AppStep
  validity?: ExerciseValidity;
  wrapupState: CollapsibleContentState;
}

export type AppExercise = Readonly<IAppExercise>;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AppExercise {
  export const toLastSelection = Lens.create<AppExercise>('lastSelection');
  export const toSteps = Lens.create<AppExercise, 'steps'>('steps');

  export const getCurrentStep = (steps: ReadonlyArray<AppStep>, currentStepId?: string) =>
    steps.find((step) => step.id === currentStepId);

  export const getCurrentStepIndex = (
    steps: ReadonlyArray<AppStep>,
    currentStepId?: string
  ): number => steps.findIndex((step) => step.id === currentStepId);

  export const isCurrentStepLast = (exercise: AppExercise): boolean => {
    const { steps, currentStepId } = exercise;
    const lastStep = last(steps) as AppStep; // there will be always steps so we can typecast
    return lastStep.id === currentStepId;
  };

  export const getStepPathForStepId = (exercise: AppExercise, stepId: string) => {
    const { steps } = exercise;
    // null save access on `step`, since logs revealed that we may face `undefined` in `steps` array
    //   https://bettermarks.atlassian.net/browse/SPL-109
    return `steps[${steps.findIndex((step) => step?.id === stepId)}]`;
  };

  export const getCurrentStepPath = (exercise: AppExercise): string | undefined => {
    const { currentStepId } = exercise;
    return currentStepId && getStepPathForStepId(exercise, currentStepId);
  };

  export const toCurrentStep: Lens<AppExercise, AppStep | undefined> = {
    get: (exercise) =>
      exercise.status !== ExerciseStatus.review
        ? getCurrentStep(exercise.steps, exercise.currentStepId)
        : undefined,
    set: (step) => (exercise) => {
      if (exercise.status === ExerciseStatus.review || !step) {
        return exercise;
      }

      const { steps, currentStepId } = exercise;
      const currentStepIndex = getCurrentStepIndex(steps, currentStepId);

      // Do nothing if there is no current step
      if (currentStepIndex < 0) {
        return exercise;
      }

      return {
        ...exercise,
        currentStepId: step.id,
        steps: [...steps.slice(0, currentStepIndex), step, ...steps.slice(currentStepIndex + 1)],
      };
    },
  };

  export const toCurrentQuestion: Lens<AppExercise, Question | undefined> = {
    get: (exercise) => {
      const step = toCurrentStep.get(exercise);

      return step && AppStep.toCurrentQuestion.get(step);
    },
    set: (question) => (exercise) => {
      const step = toCurrentStep.get(exercise);
      return step && question
        ? toCurrentStep.set(AppStep.toCurrentQuestion.set(question)(step))(exercise)
        : exercise;
    },
  };

  export const toGizmoContentDict = (gizmoId: string): Lens<AppExercise, ContentDict> => {
    // Get the path from the gizmoId and add '.present' if it's the question/kem.data/fems.data.[femId]
    const path = ContentDict.pathFromKey(gizmoId)
      .replace(/(\.question)$/, '$1.present')
      .replace(/(kem.data)$/, '$1.present')
      .replace(/(fems.data.*)$/, '$1.present');

    return {
      get: (exercise) => get(path, exercise),
      set: (contentDict) => (exercise) => set(path, contentDict, exercise),
    };
  };

  export const toGizmoContent = (gizmoId: string): Lens<AppExercise, Content> => {
    const toContentDict = toGizmoContentDict(gizmoId);

    return {
      get: (exercise) => toContentDict.get(exercise)[gizmoId] as Content,
      set: (content) => (exercise) =>
        toContentDict.set({
          ...toContentDict.get(exercise),
          [gizmoId]: content,
        })(exercise),
    };
  };

  export const toSelectedRefId: Lens<AppExercise, string | undefined> = {
    get: (exercise) => {
      const question = toCurrentQuestion.get(exercise);
      const selected =
        question && entries(question).find(([, content]) => !!(content && content.selected));

      return selected && selected[0];
    },
    // make sure the selectedRefId points to an interactive content when calling.
    // otherwise it can happen that nothing gets selected
    set: (selectedRefId) =>
      Lens.update(toCurrentQuestion)(
        (question) =>
          question &&
          entries(question).reduce(
            (acc, [refId, content]: [string, Content]) => ({
              ...acc,
              [refId]: compose(
                refId === selectedRefId &&
                  !isNil(content) &&
                  content.tool &&
                  content.$interactionType
                  ? (c: Content) => ({ ...c, selected: true })
                  : identity,
                omit('selected')
              )(content),
            }),
            {}
          )
      ),
  };

  export const toSelectedContent: Lens<AppExercise, Content | undefined> = {
    get: (exercise) => {
      const refId = toSelectedRefId.get(exercise);

      return !isNil(refId) ? toGizmoContent(refId).get(exercise) : undefined;
    },
    set: (content) => (exercise) => {
      const refId = toSelectedRefId.get(exercise);

      // no-op if content is undefined or if there is no selected content
      return !isNil(content) && !isNil(refId)
        ? // The new content must still stay selected!
          // So, we make sure to keep the `selected` property as it was.
          Lens.update(toGizmoContent(refId), ({ selected }) => ({ ...content, selected }), exercise)
        : exercise;
    },
  };

  export const isCompleted = (exercise: AppExercise): boolean =>
    exercise.steps.reduce((acc, step) => acc && step.status === StepStatus.completed, true);
}

export type ExerciseTransformer = (ex: AppExercise) => AppExercise;
