export function getValue(_obj: any, path: (string | number)[]): any | undefined {
  let i = 0,
    obj = _obj;
  const length = path.length;

  while (i < length) {
    if (obj === null || obj === undefined) {
      return undefined;
    }
    obj = obj[path[i++]];
  }

  return obj === null ? undefined : obj;
}
