import * as React from 'react';
import { HAlignment, numberFromStyles, VAlignment } from '@bettermarks/gizmo-types';
import { transformX, transformY } from '@bettermarks/importers';
import { GeoSVGForeignObject } from './primitives/GeoSVGForeignObject';

import styles from './AxisRenderer.scss';

type AxesNullLabelProps = {
  matrix: number[];
  scale: number;
};

export const AxesNullLabel: React.FC<AxesNullLabelProps> = ({ matrix, scale }) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  const fontSize = numberFromStyles(styles.AXIS_LABEL_FONT_SIZE) * scale;

  return (
    <GeoSVGForeignObject
      key={'tickLabel_00'}
      content={'0'}
      x={sx(0) - 4}
      y={sy(0) + 2}
      hAlign={HAlignment.end}
      vAlign={VAlignment.hanging}
      width={10}
      height={16}
      className={styles.label}
      fontSize={fontSize}
    />
  );
};
