import classNames from 'classnames';
import {
  BASIC_SHAPE_DEFAULT_CONTENT,
  type ContentColor,
  type Legend,
  SQUARE,
} from '@bettermarks/gizmo-types';
import { type ContextState, PolymorphicGizmo } from '../../../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';

import { BasicShape } from '../../../basic-shape/BasicShape';
import { Formula } from '../../../formula/Formula';

import styles from './Legend.scss';

export type LegendProps = ContextState & {
  legend: Legend[];
};

/**
 * To render the color corresponding to each legend entry
 * @param  {ContentColor} color
 * @return {JSX.Element}
 */
const ColorShape = (color: ContentColor, availableWidth: number): JSX.Element => (
  <div className={styles.legendListItemElement}>
    <BasicShape
      {...BASIC_SHAPE_DEFAULT_CONTENT}
      availableWidth={availableWidth}
      color={color}
      shape={SQUARE}
    />
  </div>
);
ColorShape.displayName = 'ColorShape';

/**
 * To render each entry of the legend
 * @param  {Legend}
 * @param  {number} key
 * @return {JSX.Element}
 */
const renderColorAndLabel =
  (availableWidth: number) =>
  ({ color, label }: Legend, key: number): JSX.Element =>
    (
      <div className={styles.legendList} key={key}>
        <div className={styles.legendListItem}>
          {ColorShape(color, availableWidth)}
          <div
            className={classNames(styles.legendListItemElement, styles.legendListItemElementLabel)}
          >
            {'$refid' in label ? (
              <PolymorphicGizmo refid={label.$refid} availableWidth={availableWidth} />
            ) : (
              <Formula {...label} availableWidth={availableWidth} />
            )}
          </div>
        </div>
      </div>
    );

export const LegendComponent: React.FC<LegendProps> = ({ availableWidth, legend }) => (
  <div>{legend.map(renderColorAndLabel(availableWidth))}</div>
);
LegendComponent.displayName = 'LegendComponent';
