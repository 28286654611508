import log from 'loglevel';
import { call, put, select } from 'redux-saga/effects';
import { type BMAction } from '../../../../../gizmo-utils/types';
import { toLogExtra } from '../../../../store/toLogExtra';
import { exceptionLog, ExceptionType } from '../../../services/logging';
import { reportProblemFailed, reportProblemProgress, reportProblemSuccess } from './actions';
import { type ReportProblemPayload } from './types';
import { ApplicationState } from '../../../../../types';
import { type ProblemReport } from '../../../services/api/report-problem-manager/types';
import { saveProblemReport } from '../../../services/api/report-problem-manager';
import { sleep } from '../../../services/api/bm-api/bmApi';
import { compress } from '../../../services/api/helper';
import { getReduxStateFlow } from './reportProblemSaga.helpers';
import { saveSubmitWhiteboardHelpMenuDialog } from '../../../services/api/report-problem-manager/reportProblemManager';

const getExerciseLink = (state: ApplicationState) => {
  const currentExercise = ApplicationState.toCurrentExercise.get(state);
  const isEmbedded = state.appSettings.embedded;
  if (!currentExercise) {
    return 'n/a';
  }
  return isEmbedded
    ? `${document.location.origin}/app/free-betty/seriesplayer/#/?autostart=true&exerciseid=${currentExercise.id}`
    : `http://gremlins.bettermarks.com/#/?autostart=true&exerciseid=${currentExercise.id}`;
};

export function* reportProblemSaga({ payload }: BMAction<ReportProblemPayload>) {
  try {
    yield put(reportProblemProgress());
    const state: ApplicationState = yield select();
    const {
      features,
      series: { currentExerciseIndex, id: seriesId },
      appSettings: { resultManagerUrl },
    } = state;
    if (features.reportProblemV2) {
      const currentExercise = ApplicationState.toCurrentExercise.get(state);
      let reduxStateFlow;
      if (features.persistReduxStateFlow) {
        try {
          reduxStateFlow = yield call(getReduxStateFlow, seriesId);
        } catch (err) {
          log.warn('Could not get redux state flow from IndexedDB.', err);
        }
      }

      const { category, message } = payload;

      const report: ProblemReport = {
        category,
        message: compress(message),
        exerciseLink: getExerciseLink(state),
        exerciseId: currentExercise && currentExercise.id,
        exerciseIndex: currentExerciseIndex,
        seriesId,
        reduxState: reduxStateFlow ? compress(reduxStateFlow) : undefined,
      };
      if (payload.type === 'seriesplayer') {
        yield call(saveProblemReport, resultManagerUrl, report);
      }
    } else {
      // faking to process problem report, see https://be2ttermarks.atlassian.net/browse/BM-53720
      yield sleep(1000);
    }
    if (payload.type === 'whiteboard') {
      yield call(saveSubmitWhiteboardHelpMenuDialog, payload);
    }
    yield put(reportProblemSuccess());
  } catch (error) {
    const extra = yield call(toLogExtra, yield select());
    exceptionLog(ExceptionType.saga, error, {
      ...extra,
      payload,
    });
    yield put(reportProblemFailed());
  }
}
