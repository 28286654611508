import { colors } from '@seriesplayer/common-ui';
import styled, { css } from 'styled-components';
import { Button } from './Button';
import { TOOLBUTTON_SIZE } from './constants';

export const ToolButton = styled(Button)`
  width: ${TOOLBUTTON_SIZE}px;

  ${({ selected }) =>
    selected &&
    css`
      background: ${colors.cBlue200};
      border-color: ${colors.cBlue500};

      &:hover {
        border-color: ${colors.cBlue500};
      }
    `}
`;
