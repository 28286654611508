export function onResize(callback: () => void) {
  window.addEventListener('resize', callback);
}

/**
 * returns the pixels available horizontally for our components to render
 * @see http://ryanve.com/lab/dimensions/
 * @returns {number}
 */
export function getAvailableWidth() {
  return document.documentElement.clientWidth;
}

/**
 * returns the pixels available vertically for our components to render
 * @see http://ryanve.com/lab/dimensions/
 * @returns {number}
 */
export function getAvailableHeight() {
  return document.documentElement.clientHeight;
}

/**
 * returns the pixels available horizontally for our components to render
 * @see http://ryanve.com/lab/dimensions/
 * @returns {number}
 */
export function getDocumentElementHeight() {
  return document.documentElement.clientHeight;
}

/**
 * returns if device is the touch capable also
 * @see https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
 * @returns {boolean}
 */
export function isTouchDevice() {
  return (
    'ontouchstart' in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.mxMaxTouchPoints > 0 ||
    navigator.maxTouchPoints > 0
  );
}

/**
 * Doing as good as we can to detect browser level zoom on desktops.
 * On mobile devices we control the viewport and are disabling zooming.
 * On desktops there is no way to prevent the user from zooming.
 * There are many examples for a devicePixelRatio of integer values
 * other then 1, e.g. 2 or 3 on HiDPI or Retina display.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
 *
 * We only assume zoom when the value isn't 1, 2 or 3 to prevent false warnings.
 * This means we don't cover all cases, e.g. 200% or 300%
 * on a screen with "normal" ratio of 1.
 * But this way we prevent false alerts like informing the user
 * he has zoomed when he actually has not.
 *
 * @param isTouch by default uses {@link isTouchDevice} to detect "mobile devices"
 * @param pixelRatio by default uses {@link Window.devicePixelRatio} trying to detect zoom level
 *
 * @see https://caniuse.com/#search=Window.devicePixelRatio
 *
 * @deprecated since most browsers offer to configure a default zoom level,
 *             in which case this will always give a false positive for that user.
 *             Also it would actually just be a problem when zooming during using the application.
 *             To improve the situation it would need to be compared
 *             with the "initial" devicePixelRation. Doing that could happen onResize.
 */
export const isDesktopZoomActive = (
  isTouch = isTouchDevice(),
  pixelRatio = window.devicePixelRatio
) => {
  if (isTouch) return false;
  return !(pixelRatio === 1 || pixelRatio === 2 || pixelRatio === 3);
};
