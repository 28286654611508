import { round } from './numbers';
import { type Coords, RAY, SEGMENT, toFloat, VECTOR } from '@bettermarks/gizmo-types';
import { EPS } from '../constants';

/**
 * Rounds each coordinate of a vector to a given number of decimal places
 * (default = 7) -> validator precision (ignores diff < 1E-7)
 *
 * @param {Coords} v ... a vector
 * @param {number} n ... number of decimal places to round
 *
 * @return the vector with rounded coordinates
 */
export const roundV = (v: Coords, n = 7): Coords => ({
  x: round(v.x, n),
  y: round(v.y, n),
});

/**
 * Squared euclidian distance between two points.
 *
 * @param {Coords} p1 Coordinates of point 1
 * @param {Coords} p2 Coordinates of point 2
 * @returns {number}
 */
export const squaredEuclidianDistance = (p1: Coords, p2: Coords): number =>
  (p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y);

/**
 * Checks, if two points (vectors) are equal.
 *
 * @param {Coords} p1 first vector
 * @param {Coords} p2 second vector
 *
 * @return the p1 === p2
 */
export const eq = (p1: Coords, p2: Coords): boolean => p1.x === p2.x && p1.y === p2.y;

/**
 * Checks, if two points (vectors) are numerically equal.
 *
 * @param {Coords} p1 first vector
 * @param {Coords} p2 second vector
 *
 * @return true, if the euclidian distance is smaller than EPS, false otherwise
 */
export const numeq = (p1: Coords, p2: Coords): boolean =>
  Math.sqrt(squaredEuclidianDistance(p1, p2)) < EPS;

/**
 * Adds two vectors.
 *
 * @param {Coords} p1 first vectors
 * @param {Coords} p2 second vectors
 *
 * @return the p1  + p2
 */
export const add = (p1: Coords, p2: Coords): Coords => ({
  x: p1.x + p2.x,
  y: p1.y + p2.y,
});

/**
 * truncates and rounds positive numbers and just truncates negative ones.
 * @param x
 * @param intPart
 * @param fracPart
 * @param truncate
 * @return number
 */
const safeTruncate = (x: number, intPart: string, fracPart: string, truncate: number): number =>
  toFloat(
    x / Math.abs(x) === 1 ? x.toFixed(truncate) : `${intPart}.${fracPart.substr(0, truncate)}`
  );

/**
 * Numbers like 0.12000000000000000088 or -2.999999999999999999995
 * will return true.
 * @param n
 */
const isTooMany0or9 = (n: number): boolean => !!(String(n).split('.')[1] || '').match(/(0|9){8,}/);

/**
 * checks if we get a valid fractional part after subtraction.
 * Cases like '132123123e-8' or '23000000000000001' will not pass.
 * @param aFrac
 * @param bFrac
 * @param sFrac
 */
const isFractionalPartValid = (aFrac: string, bFrac: string, sFrac: string): boolean =>
  (!aFrac.length || !bFrac.length || aFrac.length === bFrac.length) && !sFrac.match(/e/);

/**
 * performs a "safe" subtraction between decimal numbers.
 * Helps to avoid situations like:
 * 2.050303842 - 2.050303841579296 => 4.2070391614856817e-10
 * @param a
 * @param b
 */
export const safeSub = (a: number, b: number): number => {
  let [aInt, aFrac = ''] = String(a).split('.');
  let [bInt, bFrac = ''] = String(b).split('.');

  const unsafeResult = a - b;
  const sFrac = String(unsafeResult).split('.')[1] || '';

  if (isFractionalPartValid(aFrac, bFrac, sFrac) && !isTooMany0or9(unsafeResult)) {
    return unsafeResult;
  }

  const truncate = Math.min(aFrac.length, bFrac.length);

  const aTruncated = safeTruncate(a, aInt, aFrac, truncate);
  const bTruncated = safeTruncate(b, bInt, bFrac, truncate);
  /**
   * we need this for situations like 1.11112 - 1.11111
   * in order ot get 0 instead of 0.00009999999999998899
   */
  [aInt, aFrac = ''] = String(aTruncated).split('.');
  [bInt, bFrac = ''] = String(bTruncated).split('.');

  const result =
    Math.abs(Number(aFrac) - Number(bFrac)) === 1
      ? // in this case we don't need to calculate with the fractional part
        Number(aInt) - Number(bInt)
      : aTruncated - bTruncated;

  // in case if the "safe" result still unsafe then use unsafe result. :D
  return isTooMany0or9(result) ? unsafeResult : result;
};

/**
 * Subtract two vectors.
 *
 * @param {Coords} p1 first vector
 * @param {Coords} p2 second vector
 *
 * @return the p2 - p1
 */
export const sub = (p1: Coords, p2: Coords): Coords => ({
  x: p2.x - p1.x,
  y: p2.y - p1.y,
});

/**
 * Performs scalar multiplication.
 *
 * @param {number} s The scalar to scale the vector by
 * @param {Coords} p The vector to scale
 *
 * @return the c * p
 */
export const smult = (s: number, p: Coords): Coords => ({
  x: s * p.x,
  y: s * p.y,
});

/**
 * sqrt(euclidian) -> actual euclidian distance ;)
 *
 * @param {Coords} p1 Coordinates of point 1
 * @param {Coords} p2 Coordinates of point 2
 * @returns {number}
 */
export const norm = (p1: Coords, p2: Coords): number => Math.sqrt(squaredEuclidianDistance(p1, p2));

/**
 * Calculate the cross product of the two vectors.
 *
 * @param {Coords} p1 first vector
 * @param {Coords} p2 second vector
 *
 * @return the cross product result as a float. This is the same as the determinant
 * of the 2x2 matrix defined by the given vectors.
 */
export const cross2d = (p1: Coords, p2: Coords): number => p1.x * p2.y - p1.y * p2.x;

/**
 * Calculate the scalar product of the two vectors.
 *
 * @param {Coords} p1 first vector
 * @param {Coords} p2 second vector
 *
 * @return the scalar product result as a float
 */
export const sprod = (p1: Coords, p2: Coords): number => p1.x * p2.x + p1.y * p2.y;

/**
 * Calculate the normal vector of a given vector
 *
 * @param {Coords} v vector
 *
 * @return {Coords} normal vector
 */
export const normal = (v: Coords): Coords => ({ x: v.y, y: -v.x });

/**
 * Calculates foot point of perpendicular projection on line object.
 *
 * @param {Coords} p: point to project
 * @param {[Coords, Coords]} segment: points defining the line object
 * @param {'segment' | 'line' | 'ray'} lineType: type of line object to project on
 * @returns {Coords} coordinates of foot point
 */
export const project = (p: Coords, segment: [Coords, Coords], lineType: string): Coords => {
  const [p1, p2] = segment;

  const a = sub(p1, p2);
  const b = sub(p1, p);

  const param = sprod(b, a) / sprod(a, a);

  // point p is closest to one of the end points of segment
  // closest to p1
  if (param < 0 && (lineType === SEGMENT || lineType === VECTOR || lineType === RAY)) {
    return p1;
  }
  // closest to p2
  if (param > 1 && (lineType === SEGMENT || lineType === VECTOR)) {
    return p2;
  }

  // point p is closest to perpendicular projection on line
  return add(p1, smult(param, a));
};

/**
 * Calculate the abs of a given vector
 *
 * @param {Coords} v vector
 *
 * @return {number} abs
 */
export const abs = (v: Coords): number => Math.sqrt(v.x * v.x + v.y * v.y);

/**
 * Middlepoint between two points.
 *
 * @param {Coords} p1 first point
 * @param {Coords} p2 second point
 *
 * @return the (p1 + p2) / 2
 */
export const midpoint = (p1: Coords, p2: Coords): Coords => smult(0.5, add(p1, p2));

/**
 * Slope angle of line through 2 given points.
 *
 * @param {Coords} p1 first point
 * @param {Coords} p2 second point
 *
 * @return angle in deg (math positive orientation = counter clockwise)
 */
export const angle = (p1: Coords, p2: Coords): number =>
  (Math.atan((p2.y - p1.y) / (p2.x - p1.x)) * 180) / Math.PI;

/**
 * Transforms polar coordinates to cartesian corrdinates. ie, when an angle in radians
 * and radius are passed, it returns the {x, y} coordinates
 *
 * @param {Coords} point
 * @param {number} radius
 * @param {number} angleInRadians
 * @returns {Coords}
 */
export const polarToCartesian = (
  { x, y }: Coords,
  radius: number,
  angleInRadians: number
): Coords => ({
  x: x + radius * Math.cos(angleInRadians),
  y: y + radius * Math.sin(angleInRadians),
});
