import { type Dispatch, showFEM } from '../../../../../gizmo-utils/redux';
import { connect } from 'react-redux';
import { type FetchKEMParams } from '../../../services/api/content-manager';
import { ApplicationState } from '../../../../../types';
import { emRequested } from '../../../../emplayer/actions';
import { closeDialog } from '../../SeriesPlayer/actions';
import { Textbook, type TextbookDispatchProps, type TextbookStateProps } from './Textbook';
import { jumpToPastAndEnrich } from '../../../../store/combineSeriesplayerReducer';

const mapStateToProps = (state: ApplicationState): TextbookStateProps => {
  const { dependencies, locale } = ApplicationState.toCurrentExercise.get(state);
  const kem = state.emData.kem;

  return {
    kemId: dependencies.kems[0],
    locale,
    staticServerUrl: state.appSettings.staticServerUrl,
    staticMediaUrl: state.appSettings.staticMediaUrl,
    kemData: kem.data,
    status: kem.status,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): TextbookDispatchProps => ({
  onCancel: () => dispatch(closeDialog()),
  onShowFEM: (id: string, search: string) => dispatch(showFEM({ id, search })),
  onInit: (initParams: FetchKEMParams) => dispatch(emRequested(initParams)),
  onReset: () => dispatch(jumpToPastAndEnrich(0)),
  dispatch,
});

export const TextbookContainer = connect(mapStateToProps, mapDispatchToProps)(Textbook);
