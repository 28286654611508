import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { EmptyUserInput, type DispatchProps } from './EmptyUserInput';
import { closeDialog, openDialog } from '../../SeriesPlayer/actions';
import { showSolution } from '../../Exercise/actions';
import { DialogType } from '../../../../../types';

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onCancel: () => dispatch(closeDialog()),
  onShowSolution: () => {
    dispatch(closeDialog());
    dispatch(showSolution());
  },
  onSubmitEmptyInput: () => {
    dispatch(
      openDialog({
        type: DialogType.submitIncompleteConfirmation,
        payload: {
          backButtonDialog: DialogType.emptyUserInput,
        },
      })
    );
  },
});

export const EmptyUserInputContainer = connect(null, mapDispatchToProps)(EmptyUserInput);
