import { TriangleUp } from '../../../components/icons';
import * as React from 'react';
import styles from './ThirdDurationArrow.scss';
import classNames from 'classnames';

type ThirdDurationArrowEdgeProps = {
  hasArrowHead: boolean;
  edge: 'left' | 'right';
};

/**
 * `ThirdDurationArrows` components helps to draw an arrow like:
 *
 *  // right direction
 *
 *  |                      ^
 *  |                      |
 *  '_______3 h 32 min_____'
 *
 *  // left direction
 *
 *  ^                      .
 *  |                      |
 *  '________42 days_______'
 *
 * It is split in 5 parts in to adapt to the width
 * of time/day references above the arrow:
 *  - 1. `ThirdDurationArrowEdge` first and last part of the arrow
 *  - 2. `ThirdDurationArrowMiddle` between arrow edges and arrow label
 *  - 3. `ThirdDurationWithLabel` part of the arrow with the label
 *
 * Here is an example of the full TimeSpan component with `ThirdDurationArrow`:
 *
 *|...1...|.......2......|......3....|........2......|...1...|
 *
 *           0 h 08 min                 0 h 04 min
 *  12:12  -------------->    12:20   --------------> 12:24
 *    |                                                 ^
 *    |                    0 h 12 min                   |
 *    '-------------------------------------------------'
 *
 *|...1...|.......2......|......3....|........2......|...1...|
 *  Edge       Middle       WithLabel     Middle        Edge
 */

export const ThirdDurationArrowEdge: React.FC<ThirdDurationArrowEdgeProps> = ({
  hasArrowHead,
  edge,
}) => {
  return (
    <div className={styles.thirdDuration}>
      <div className={styles[edge]}>
        <div className={classNames({ [styles.withArrowLeftDiv]: hasArrowHead })} />
        <div>
          {false}
          {hasArrowHead && (
            <TriangleUp
              className={classNames(styles.marker, styles.eat)}
              fill={styles.ARROW_COLOR}
            />
          )}
        </div>
      </div>
      <div className={classNames({ [styles.withArrowBotDiv]: hasArrowHead })} />
    </div>
  );
};

export const ThirdDurationArrowMiddle: React.FC = () => (
  <div className={styles.thirdDuration}>
    <div className={styles.middle} />
    <div />
  </div>
);

export const ThirdDurationArrowWithLabel: React.FC = ({ children }) => (
  <div className={classNames(styles.thirdDuration, styles.withLabel)}>
    <div />
    <div>{children}</div>
  </div>
);
