import React from 'react';

/**
 * React Hook for handling scroll events on an HTML element of generic type `T`.
 * @template T Type of the HTML element. This should extend from HTMLElement.
 *
 * @param { (e: Event) => void } handleScroll Callback function to be invoked on each scroll event. It receives the scroll event object as its argument.
 *
 * @returns {React.MutableRefObject<T | null>} A mutable reference object, initialized to `null` by default, pointing to the HTML element of type `T`.
 *
 * @description
 * This hook sets up a scroll event listener on the element referenced by `ref`, and removes the listener when the component unmounts.
 * The `handleScroll` callback function is triggered on each scroll event.
 *
 * The hook initializes a `ref` object that can be associated with an HTML element of type `T` in the render method.
 * Note that the ref object is mutable, and it can be modified over the lifetime of the component without causing a re-render.
 *
 * It is recommended to use this hook at the top level of your functional component to ensure it's properly used following the Rules of Hooks.
 *
 * @example
 * const handleScroll = (e) => {
 *  console.log("Scrolling...");
 * }
 * const myElementRef = useScroll<HTMLDivElement>(handleScroll);
 *
 * // Then in your component render:
 * <div ref={myElementRef}>This is my scrollable area</div>
 *
 */
export function useScroll<T extends HTMLElement>(
  handleScroll: (e: Event) => void
): React.MutableRefObject<T | null> {
  const ref = React.useRef<T | null>(null);
  React.useEffect(() => {
    const canvasRefCurrent = ref.current;
    if (canvasRefCurrent === null) return;

    canvasRefCurrent.addEventListener('scroll', handleScroll);

    return () => {
      canvasRefCurrent.removeEventListener('scroll', handleScroll);
    };
  }, [ref, handleScroll]);

  return ref;
}
