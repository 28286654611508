import { connect } from 'react-redux';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { type ApplicationState, type ExerciseDisplayStatus } from '../../../../../types';
import { switchExerciseResumable } from '../../Exercise/actions';
import { getExerciseDisplayStatus } from '../../Series/helpers';
import {
  ExerciseNavigation,
  type ExerciseNavigationStateProps,
  type ExerciseNavigationDispatchProps,
} from './ExerciseNavigation';
import { closeDialog } from '../../SeriesPlayer/actions';

const mapDispatchToProps = (dispatch: Dispatch): ExerciseNavigationDispatchProps => ({
  onCancel: () => {
    dispatch(closeDialog());
  },
  onSwitchExercise: (index: number) => {
    dispatch(switchExerciseResumable(index));
    dispatch(closeDialog());
  },
});

const mapStateToProps = (state: ApplicationState): ExerciseNavigationStateProps => {
  const {
    series: { exercises, currentExerciseIndex },
    runtimeState: { availableWidth },
  } = state;
  const exerciseDisplayStatus: ExerciseDisplayStatus[] = getExerciseDisplayStatus(exercises);
  return {
    exerciseStatuses: exerciseDisplayStatus,
    currentExerciseIndex,
    availableWidth,
  };
};

export const ExerciseNavigationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseNavigation);
