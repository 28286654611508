import { createAction } from 'redux-actions';

import {
  type EndscreenPayload,
  type ErrorPayload,
  type GetParams,
  type ILoadExercisePayload,
  type LightBoxType,
  type LoaderState,
  type LobLinkParams,
  type PdfReviewSeriesParams,
  type SelectedDialog,
  type SeriesStatus,
} from '../../../../types';

import { type ResizePayload } from './SeriesPlayer';

export const TO_PREVIOUS_DIALOG = 'TO_PREVIOUS_DIALOG' as const;
export const toPreviousDialog = createAction(TO_PREVIOUS_DIALOG);

export const CLOSE_DIALOG = 'CLOSE_DIALOG' as const;
export const OPEN_DIALOG = 'OPEN_DIALOG' as const;
export const closeDialog = createAction(CLOSE_DIALOG);
export const openDialog = createAction<SelectedDialog>(OPEN_DIALOG);

export const CLOSE_FEM = 'CLOSE_FEM' as const;
export const closeFEM = createAction(CLOSE_FEM);
export const SHOW_ERROR_DIALOG = 'SHOW_ERROR_DIALOG' as const;
export const showErrorDialog = createAction<ErrorPayload>(SHOW_ERROR_DIALOG);

export const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX' as const;
export const closeLightBox = createAction(CLOSE_LIGHTBOX);

export const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX' as const;
export const openLightBox = createAction<LightBoxType>(OPEN_LIGHTBOX);

// Actions for Runtime events changes
export const WINDOW_RESIZED = 'WINDOW_RESIZED' as const;
export const windowResized = createAction<ResizePayload>(WINDOW_RESIZED);

export const SWITCH_TO_ONLINE = 'SWITCH_TO_ONLINE' as const;
export const switchToOnline = createAction(SWITCH_TO_ONLINE);

export const SWITCH_TO_OFFLINE = 'SWITCH_TO_OFFLINE' as const;
export const switchToOffline = createAction(SWITCH_TO_OFFLINE);

export const SHOW_RESULTS = 'SHOW_RESULTS' as const;
export const showResults = createAction<EndscreenPayload>(SHOW_RESULTS);

export const RESET_APP = 'RESET_APP' as const;
export const resetApp = createAction(RESET_APP);

export const QUIT_SERIES_PLAYER = 'QUIT_SERIES_PLAYER' as const;
export const quitSeriesPlayer = createAction('QUIT_SERIES_PLAYER');

export const FETCH_SERIES = 'FETCH_SERIES' as const;
export const fetchSeries = createAction<GetParams>(FETCH_SERIES);

export const FETCH_PDF_REVIEW_SERIES = 'FETCH_PDF_REVIEW_SERIES' as const;
export const fetchPdfReview = createAction<PdfReviewSeriesParams>(FETCH_PDF_REVIEW_SERIES);

export const FETCH_REMOTE_XML_SP = 'FETCH_REMOTE_XML_SP' as const;
export const spFetchRemoteXML = createAction<ILoadExercisePayload>(FETCH_REMOTE_XML_SP);

export const FETCH_LOBLINK_SERIES = 'FETCH_LOBLINK_SERIES' as const;
export const fetchLobLinkSeries = createAction<LobLinkParams>(FETCH_LOBLINK_SERIES);

export const CHANGE_LOADER_STATE = 'CHANGE_LOADER_STATE' as const;
export const changeLoaderState = createAction<LoaderState>(CHANGE_LOADER_STATE);

export const SET_SERIES_STATUS = 'SET_SERIES_STATUS' as const;
export const setSeriesStatus = createAction<SeriesStatus>(SET_SERIES_STATUS);

export const HIDE_CONTENT = 'HIDE_CONTENT' as const;
export const hideContent = createAction(HIDE_CONTENT);

export const SHOW_EMPTY_INPUT_DIALOG = 'SHOW_EMPTY_INPUT_DIALOG' as const;
export const showEmptyInputDialog = createAction(SHOW_EMPTY_INPUT_DIALOG);

export const INTERCEPT_BROWSER_BACK_BUTTON = 'INTERCEPT_BROWSER_BACK_BUTTON' as const;
export const interceptBrowserBackButton = createAction(INTERCEPT_BROWSER_BACK_BUTTON);

export const SET_REPORT_LOBLINK_SETTINGS = 'REPORT_LOB_LINK_SETTINGS' as const;
export const setReportLobLinkSettings = createAction(SET_REPORT_LOBLINK_SETTINGS);

export const SET_LTI_REPORTING = 'SET_LTI_REPORTING' as const;
export const setLtiReporting = createAction(SET_LTI_REPORTING);
