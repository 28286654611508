import { VERY_SMALL_SCREEN_HEIGHT, VERY_SMALL_SCREEN_WIDTH } from '../../constants';

/**
 * The consistent criteria whether to render the dragDropDrawer.
 * Only if there is not enough space for the drag sources to be available
 * as part of the content, they are showing up in the drawer.
 * The values relevant in this scenario are in `appState.runtimeState.available...`.
 *
 * We have an issue on older iOs devices, where the targets behave in strange ways
 * during interaction. Having the sources in the drawer helps (a bit).
 * @see https://bettermarks.atlassian.net/browse/BM-52003
 *
 * @param width available for the series player to render
 * @param height available for the series player to render
 */
export const isDragSourceDrawerAvailable = (width: number, height: number): boolean =>
  width <= VERY_SMALL_SCREEN_WIDTH || height <= VERY_SMALL_SCREEN_HEIGHT;
