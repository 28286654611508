import { getFontMetric } from '../..//utils/fontMetric';
import { type Enricher } from '../../gizmo-utils/configuration';
import { defaultTo } from 'lodash';
import { type PointerContent } from '@bettermarks/gizmo-types';

/**
 * here we set the strokewidth depending on the fontsize
 */
export const measurePointer: Enricher<PointerContent> = ({ fontSize }, content) => {
  const height = content.height;
  return {
    height,
    refLine: height * 0.5,
    enrichedContent: {
      ...content,
      strokeWidth: defaultTo<number>(
        content.decoration.thickness,
        getFontMetric(fontSize).strokeWidth
      ),
    },
  };
};
