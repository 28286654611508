import barStyles from '../../components/Bar/Bar.scss';
import navBarStyles from './components/Navigation/navigation.scss';
import exerciseNavigationButton from './components/ExerciseNavigationButton/exerciseNavigationButton.scss';
import seriesPlayerStyles from 'styles/responsive.scss';
import dimensions from 'styles/dimensions.scss';
import { numberFromStyles } from '@bettermarks/gizmo-types';
import { defaultTheme } from '@seriesplayer/common-ui';

export const VERY_SMALL_SCREEN_WIDTH = numberFromStyles(seriesPlayerStyles.VERY_SMALL_SCREEN_WIDTH);
export const VERY_SMALL_SCREEN_HEIGHT = numberFromStyles(
  seriesPlayerStyles.VERY_SMALL_SCREEN_HEIGHT
);

export const NAVBAR_WIDTH =
  numberFromStyles(navBarStyles.NAVBAR_WIDTH) + numberFromStyles(barStyles.BORDER_THICK);

export const PADDING_COLLAPSIBLE = numberFromStyles(dimensions.PADDING_CONTENT_COLLAPSIBLE);
export const BORDER_COLLAPSIBLE = numberFromStyles(dimensions.BORDER_COLLAPSIBLE);

export const PADDING_CONTEXT_NOTIFICATION = numberFromStyles(defaultTheme.dimensions.spaceXs);

export const BUTTON_WRAPPER_WIDTH = numberFromStyles(exerciseNavigationButton.BUTTON_WRAPPER_WIDTH);

/*
  This is the event payload posted to NSP.
 */
export const closeSeriesPlayer = {
  action: 'closeSeriesPlayer',
  from: 'externalInterface',
};
