import {
  Bar,
  colors,
  Layout,
  MenuItemElement,
  MenuItemElementLabel,
  VerticalMenu,
  VerticalMenuItem,
  type VerticalMenuItemProps,
  VerticalMenuItemType,
} from '@seriesplayer/common-ui';

import styled from 'styled-components';
import { Popup } from '../../../components/ContextPopup';
import React from 'react';
import { DialogHeader, Icon, Overlay } from '../../../components';
import { USER_SCALE_VALUES } from '../constants';
import { type BoundingRect } from 'react-measure';
import { useWhiteboardContext } from '../whiteboard-context';
import { useTranslation } from 'react-i18next';

type ZoomPopupItemLabel = 'Kleiner' | 'Größer' | 'Automatisch' | 'Originalgröße';

interface ZoomPopupMenuItemProps extends VerticalMenuItemProps {
  disabled?: boolean;
}
const ZoomPopupMenuItem = styled(VerticalMenuItem)<ZoomPopupMenuItemProps>`
  ${({ disabled }) =>
    disabled
      ? `
    * {
      color: ${colors.cGray500};
      cursor: pointer;
    }`
      : `
    * {
      color: ${colors.cGray800};
    }`}
`;

interface ZoomPopupProps {
  show: boolean;
  scale: number;
  zoomButtonRef: React.RefObject<HTMLDivElement>;
}

const itemIsDisabled = (scale: number, itemLabel: ZoomPopupItemLabel) => {
  const minUserScale = USER_SCALE_VALUES[0];
  const maxUserScale = USER_SCALE_VALUES[USER_SCALE_VALUES.length - 1];
  let disabled = false;
  if (itemLabel === 'Größer') {
    disabled = scale === maxUserScale;
  } else if (itemLabel === 'Kleiner') {
    disabled = scale === minUserScale;
  }
  return disabled;
};

export default function ZoomPopup({ show, scale, zoomButtonRef }: ZoomPopupProps) {
  const { dispatch } = useWhiteboardContext();
  const [zoomButtonBounds, setZoomButtonBounds] = React.useState<BoundingRect>();
  const [t] = useTranslation();

  React.useEffect(() => {
    const onResize = (): void => {
      if (zoomButtonRef.current) {
        // @ts-ignore
        setZoomButtonBounds(zoomButtonRef.current.getBoundingClientRect());
      }
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const popupItems: {
    label: ZoomPopupItemLabel;
    element?: React.ReactElement;
    handler: () => void;
    text: string;
  }[] = [
    {
      label: 'Kleiner',
      text: t('seriesplayer:dialog.whiteboard.zoom.smallerButtonText'),
      element: <Icon iconKey="MinusCircle" />,
      handler: () => dispatch({ type: 'setScale', scaleSetting: 'smaller' }),
    },
    {
      label: 'Größer',
      text: t('seriesplayer:dialog.whiteboard.zoom.biggerButtonText'),
      element: <Icon iconKey="PlusCircle" />,
      handler: () => dispatch({ type: 'setScale', scaleSetting: 'bigger' }),
    },
    {
      label: 'Automatisch',
      text: t('seriesplayer:dialog.whiteboard.zoom.automaticButtonText'),
      handler: () => dispatch({ type: 'setScale', scaleSetting: 'auto' }),
    },
    {
      label: 'Originalgröße',
      text: t('seriesplayer:dialog.whiteboard.zoom.originalButtonText'),
      handler: () => dispatch({ type: 'setScale', scaleSetting: 'off' }),
    },
  ];

  const onClose = () => dispatch({ type: 'magnifier' });

  return (
    <Overlay>
      <Popup
        kind="small"
        place="left"
        show={show}
        onClickOverlay={onClose}
        elementBounds={zoomButtonBounds}
      >
        <Layout vertical>
          <DialogHeader
            title={t('seriesplayer:dialog.whiteboard.zoom.header')}
            rightButton={{
              icon: 'TimesXLargeBold',
              onClick: onClose,
            }}
          ></DialogHeader>
          <Bar />
          <VerticalMenu limitHeight separator>
            {popupItems.map((item, idx) => {
              const disabled = itemIsDisabled(scale, item.label);
              return (
                <ZoomPopupMenuItem
                  key={idx}
                  disabled={disabled}
                  onClick={disabled ? undefined : item.handler}
                  type={VerticalMenuItemType.neutral}
                  data-cy={item.label}
                >
                  <MenuItemElement>{item.element}</MenuItemElement>
                  <MenuItemElementLabel>{item.text}</MenuItemElementLabel>
                </ZoomPopupMenuItem>
              );
            })}
          </VerticalMenu>
        </Layout>
      </Popup>
    </Overlay>
  );
}
