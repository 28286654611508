import * as React from 'react';
import { type FractionFormProps } from '../../FractionForm';
import { FractionFormRenderer } from '../FractionFormRenderer';

// removes all callbacks except the one to divide the form into a specified number of segments
export const Divide: React.FC<FractionFormProps> = ({
  onRemoveForm,
  onSetSegments,
  onToggleSegment,
  disabled,
  ...props
}) => <FractionFormRenderer {...props} onSetSegments={!disabled ? onSetSegments : undefined} />;
