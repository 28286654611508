import { type BubbleNode, ContentColor, NodeType } from '@bettermarks/gizmo-types';

export const BORDER_WIDTH = 2;
export const NODE_RADIUS = 16;
export const HIGHLIGHT_WIDTH = 4;
export const ERROR_HIGHLIGHT_WIDTH = 2;
export const HIGHLIGHT_PADDING = 2;
export const ROOT_RADIUS = 8;
export const GRAPH_MIN_WIDTH = 400;

export const MAX_NODE_RADIUS =
  NODE_RADIUS + BORDER_WIDTH + HIGHLIGHT_PADDING + 0.5 * HIGHLIGHT_WIDTH;

export const TOUCH_OFFSET = {
  x: 0,
  y: -50,
};

// Minimum movement in pixels until the touch offset is applied
export const TOUCH_OFFSET_MOVE_THRESHOLD = 10;

export const PREVIEW_NODE: BubbleNode = {
  id: 'preview',
  type: NodeType.bubble,
  borderColor: ContentColor.BM_LIGHT_GREY,
  highlight: true,
  position: { x: 0, y: 0 },
};

export const DEFAULT_NODE: BubbleNode = {
  type: NodeType.bubble,
  borderColor: ContentColor.BM_LIGHT_GREY,
  position: { x: 0, y: 0 },
};

export const INVISIBLE_NODE: BubbleNode = {
  id: 'invisible',
  type: NodeType.bubble,
  invisible: true,
  position: { x: 0, y: 0 },
};

// we need that at several palces
export const BUBBLENODE_RADIUS = 16;

export const MIN_NODE_RADIUS = NODE_RADIUS + HIGHLIGHT_PADDING + ERROR_HIGHLIGHT_WIDTH;
export const MIN_NODE_DIAMETER = 2 * MIN_NODE_RADIUS + 2;
