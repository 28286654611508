import * as React from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { Comparison } from './Comparison';
import { type EMKind } from '../emplayer';

type ParsedEMComparisonRouteParams = {
  emType: EMKind;
  /**
   * The IDs of the EMs to load.
   *
   * Example for KEM: PruZiPruZiGLPage1
   * Example for FEM: algorithm
   *
   * The methods pathFEMFile/pathKEMFile of ContentPathHandler will be used in
   *   src/packages/bm-toolbox/services/api/content-manager/em.ts
   * fetchFEM/fetchKEM to determine the full path to the EM resource
   */
  emId1: string;
  emId2: string;
  /**
   * Use isTest\d to specify if you want to load the test version of the EM or the published version
   *
   * Parameter is passed to the above-mentioned pathFEMFile/pathKEMFile
   * (if true, 'test__' will be prefixed).
   */
  isTest1: boolean;
  isTest2: boolean;
  /**
   * Specify the URL of the server that hosts the content that you want to fetch
   */
  staticUrl1?: string;
  staticUrl2?: string;
  locale1?: string;
  locale2?: string;
};

/**
 * Component to display two versions of an EM (fem or kem) next to each other.
 *
 * Example link:
 *
 *  https://gremlins.bettermarks.com/#/em-comparison?
 *    emType=fem&
 *    emId1=addition&
 *    emId2=cylinder&
 *    staticUrl1=http://staticdev.jenkinsdev01.inf00.inf.bm.loc/content_output/&
 *    staticUrl2=https://content-generator-jvm.bettermarks.com
 *    isTest1=false&
 *    isTest2=true
 *
 * Possible query parameters, see EMComparisonRouteParams.
 */
export function EMComparison() {
  const location = useLocation();
  const { emType, emId1, emId2, staticUrl1, staticUrl2, locale1, locale2, isTest1, isTest2 } =
    qs.parse(location.search, {
      parseBooleans: true,
    }) as ParsedEMComparisonRouteParams;
  const ems = [
    {
      emId: emId1,
      staticUrl: staticUrl1,
      locale: locale1,
      isTest: isTest1,
    },
    {
      emId: emId2,
      staticUrl: staticUrl2,
      locale: locale2,
      isTest: isTest2,
    },
  ];
  const [url1, url2] = ems.map(
    (em) =>
      `https://gremlins.bettermarks.com/#/${emType}/${em.locale}/${em.emId}?${qs.stringify({
        test: em.isTest,
        ...(em.staticUrl && { staticUrl: em.staticUrl }),
      })}`
  );
  return <Comparison url1={url1} url2={url2} />;
}
