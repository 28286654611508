import * as React from 'react';
import { isNil } from 'lodash';

import { Marker } from '../../../components';
import { type ContentReference, HAlignment, VAlignment } from '@bettermarks/gizmo-types';
import { SVGForeignObject } from '../../../../gizmo-utils/svg-foreign-object/SVGForeignObject';

import styles from './Axes.scss';
import {
  DEFAULT_ARROW_LENGTH,
  DEFAULT_AXIS_LABEL_MARGIN,
  DEFAULT_XAXIS_LABEL_SPACE,
} from '../../defaults';

export type XAxisProps = {
  y: number;
  width: number;
  hasArrow: boolean;
  id: string;
  labelSpace: number;
  label?: ContentReference;
  onLabelMeasured?: (width: number) => void;
};

export const XAxis: React.FC<XAxisProps> = ({
  hasArrow,
  id,
  label,
  labelSpace,
  onLabelMeasured,
  width,
  y,
}) => {
  return (
    <g>
      <Marker id={`arrowX-${id}`} offset={DEFAULT_ARROW_LENGTH} />
      <line
        x1="0"
        y1={y}
        x2={width - labelSpace}
        y2={y}
        className={styles.axis}
        markerEnd={hasArrow ? `url(#arrowX-${id})` : ''}
      />
      {!isNil(label) && (
        <SVGForeignObject
          content={label}
          x={width - (!isNil(label) ? labelSpace - 2 * DEFAULT_AXIS_LABEL_MARGIN : 0)}
          y={y}
          availableWidth={DEFAULT_XAXIS_LABEL_SPACE}
          hAlign={HAlignment.start}
          vAlign={VAlignment.middle}
          onMeasured={onLabelMeasured}
        />
      )}
    </g>
  );
};

XAxis.displayName = 'XAxis';
