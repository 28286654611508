import * as React from 'react';
import classNames from 'classnames';
import { OverlayShadow } from '@seriesplayer/common-ui';
import { Overlay } from '../Overlay';
import styles from './DialogWrapper.scss';
import { getOnTopZIndex } from '../../utils/z-index/update-z-index';

export enum DialogSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

type Props = {
  dataCy?: string;
  /**
   * Currently restricts the maximum width of the dialog.
   * Defaults to DialogSize.medium (due the value in DialogWrapper.scss)
   */
  dialogSize?: DialogSize;
  /**
   * Event handler to close the dialog.
   * If `onCloseDialog` is not passed, dialog won't be closable.
   * No close button will be rendered.
   */
  lightbox?: boolean;
  noVerticalStretch?: boolean;
  onClickOverlay?: () => void;
};

/**
 * Responsive dialog wrapper.
 */
export const DialogWrapper: React.FC<Props> = ({
  children,
  dataCy,
  dialogSize = {},
  lightbox = false,
  noVerticalStretch = false,
  onClickOverlay,
}) => (
  <Overlay>
    <div
      style={{ zIndex: getOnTopZIndex() }}
      data-cy="popup-overlay"
      className={classNames(styles.wrapper, {
        [styles.hide]: !children, // TODO: check this !dialogContent
        [styles.lightbox]: lightbox,
      })}
    >
      <OverlayShadow kind="dialog" onClick={onClickOverlay} />
      <div
        {...(dataCy && { 'data-cy': dataCy })}
        className={classNames(styles.dialog, {
          [styles.lightbox]: lightbox,
          [styles.noVerticalStretch]: noVerticalStretch,
          [styles.small]: dialogSize === DialogSize.small,
          [styles.large]: dialogSize === DialogSize.large,
        })}
      >
        {children}
      </div>
    </div>
  </Overlay>
);

DialogWrapper.displayName = 'DialogWrapper';
