import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type IncludeGizmoContent,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportIncludeGizmo: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as IncludeGizmoContent;

  return semantics(
    `
    <mrow>
        <content html="${content.htmlPathname}"/>
        <withReset>${content.withReset}</withReset>
        ${content.width ? `<width>${content.width}</width>` : ''}
        ${content.height ? `<height>${content.height}</height>` : ''}
    </mrow>
    `,
    annotationInner(content.$, content)
  );
};
