import { createAction } from 'redux-actions';
import { type ReportProblemPayload } from './types';

export const REPORT_PROBLEM = 'REPORT_PROBLEM';
export const reportProblem = createAction<ReportProblemPayload>(REPORT_PROBLEM);

export const REPORT_PROBLEM_PROGRESS = 'REPORT_PROBLEM_PROGRESS';
export const reportProblemProgress = createAction(REPORT_PROBLEM_PROGRESS);

export const REPORT_PROBLEM_SUCCESS = 'REPORT_PROBLEM_SUCCESS';
export const reportProblemSuccess = createAction(REPORT_PROBLEM_SUCCESS);

export const REPORT_PROBLEM_FAILED = 'REPORT_PROBLEM_FAILED';
export const reportProblemFailed = createAction(REPORT_PROBLEM_FAILED);
