import * as React from 'react';
import { DIALOG_HEADER_HEIGHT } from '../../components';
import { type ContentDict, numberFromStyles } from '@bettermarks/gizmo-types';
import { BettyOops } from '../../components/icons/draft';
import { type Dispatch } from '../../gizmo-utils/redux/types';
import { noop } from 'lodash/fp';
import { type FetchKEMParams } from '../seriesplayer/services/api/content-manager';
import { useTranslation } from 'react-i18next';
import { type ApplicationState, EMStatus } from '../../types';
import {
  getAvailableHeight,
  getAvailableWidth,
  isTouchDevice,
} from '../seriesplayer/services/runtime-manager';
import { KEMContent } from './KEMContent';
import styles from './KEMPlayer.scss';
import { type EMPlayerCommonProps } from './types';
import { getBackToNsp } from '../seriesplayer/containers/SeriesPlayer/quitSeriesPlayerSaga';
import { Loader } from '../seriesplayer/components/Loader';
import { Whiteboard } from '../whiteboard';
import { type StateWithHistory } from 'redux-undo';
import classNames from 'classnames';
import { EMKind } from './reducer';
import { useHandleReset } from '../whiteboard/useHandleReset';
import { FEMPlayerContainer } from './FEMPlayerRoute';
import { useSelector } from 'react-redux';
import { getEMPlayeriFrameUrl } from './helper';
import { postToWhiteboardParent } from '../iframe-posts';

const KEMPLAYER_MAX_WIDTH = numberFromStyles(styles.KEMPLAYER_MAX_WIDTH);

export type KEMPlayerStateProps = Readonly<
  EMPlayerCommonProps & {
    encrypt: boolean;
    kemData?: StateWithHistory<ContentDict>;
    noHeader?: true;
    status?: EMStatus;
  }
>;

export type KemPlayerDispatchProps = Readonly<{
  dispatch: Dispatch;
  onInit: (initParams: FetchKEMParams) => void;
  onReset?: () => void;
  onCancel?: () => void;
  onShowFEM?: (id: string) => void;
}>;

type KemPlayerProps = KEMPlayerStateProps & KemPlayerDispatchProps;

export type KEMDimensions = Readonly<{
  width: number;
  height: number;
}>;

const getDimensions = (noHeader?: true): KEMDimensions => ({
  width: Math.min(getAvailableWidth(), KEMPLAYER_MAX_WIDTH),
  height: getAvailableHeight() - (noHeader ? 0 : DIALOG_HEADER_HEIGHT),
});

export const KEMPlayer: React.FC<KemPlayerProps> = ({
  dispatch,
  status,
  id,
  kemData,
  locale,
  staticServerUrl,
  staticMediaUrl,
  noHeader,
  test,
  encrypt,
  forWhiteboard,
  inBook,
  onInit = noop,
}) => {
  const [{ width, height }, setDimensions] = React.useState(getDimensions(noHeader));

  React.useEffect(() => {
    !kemData &&
      onInit({
        kemId: id,
        locale,
        staticServerUrl,
        test,
        encrypt,
      });
  }, [id, locale]);

  React.useEffect(() => {
    if (status === EMStatus.loaded) {
      postToWhiteboardParent({ type: 'contentLoaded' });
    }
  }, [status]);

  React.useEffect(() => {
    const onWindowResize = () => {
      const dimensions = getDimensions(noHeader);
      if (dimensions.width !== width || dimensions.height !== height) {
        setDimensions(dimensions);
      }
    };

    window.addEventListener('resize', onWindowResize, false);

    return () => {
      window.removeEventListener('resize', onWindowResize, false);
    };
  }, []);

  useHandleReset();
  const { inBookNavigation } = useSelector((state: ApplicationState) => ({
    inBookNavigation: state.inBookNavigation,
  }));
  const [t] = useTranslation();

  let kemContent: JSX.Element | undefined;
  if (status === EMStatus.error) {
    kemContent = (
      <div className={styles.error} data-cy="em-loading-error">
        <div>{t('errormessages:explanation.loadFailed')}</div>
        <div>
          <BettyOops width={100} height={100} />
        </div>
      </div>
    );
  } else if (status === EMStatus.loaded) {
    kemContent =
      inBookNavigation?.femId === undefined ? (
        kemData && (
          <KEMContent
            {...{ locale, kemData: kemData.present, dispatch, width, staticMediaUrl, inBook, id }}
          />
        )
      ) : (
        <FEMPlayerContainer
          id={inBookNavigation.femId}
          locale={locale}
          staticServerUrl={staticServerUrl}
          isTouch={isTouchDevice()}
          staticMediaUrl={staticMediaUrl}
          encrypt={true}
        />
      );
  }

  return forWhiteboard ? (
    <Whiteboard
      iframeUrl={getEMPlayeriFrameUrl(locale, id, staticServerUrl, EMKind.kem, test)}
      id={id}
    />
  ) : (
    // setting measured height to ensure vertical scroll in standalone case (see KEMPlayerRoute)
    <div
      className={classNames(styles.kemPlayer, !inBook && styles.notInBook)}
      style={noHeader && { height }}
    >
      <Loader loaded={!!status} onQuit={getBackToNsp}>
        {kemContent}
      </Loader>
    </div>
  );
};
