import { isNil } from 'lodash';
import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';
import { exportContent } from '@bettermarks/gizmo-types';
import { type Slice, type PieOrStackedBarChartContent } from '@bettermarks/gizmo-types';
import { exportLabel } from './helper';

// ------------------------------------------------------------------------------------------------
// ATTENTION: all methods of the stacked bar chart exporter are also used for exporting pie charts
// ------------------------------------------------------------------------------------------------

export const exportSlice = (slices: Slice[], context: ExporterContext): string => {
  const chartSlices = slices.map(
    (slice: Slice) =>
      `<chart:slice id="${slice.id}"
      decoration='${slice.severity ? `${slice.severity}; ` : ''}fill-color:${slice.color}'
      ${!isNil(slice.mark) ? `mark="${slice.mark}"` : ''}>
      <chart:value>${slice.value}</chart:value>
      ${
        slice.legendLabel
          ? `<chart:label type='legend'>
        ${exportLabel(slice.legendLabel, context)}
      </chart:label>`
          : ''
      }
      ${
        slice.sliceLabel
          ? `<chart:label type='slice'>
          ${exportLabel(slice.sliceLabel, context)}
        </chart:label>`
          : ''
      }
    </chart:slice>`
  );
  return chartSlices.join('');
};

export const exportPieOrStackedBarChartXML = (contentRefId: string, context: ExporterContext) => {
  const content = context.content[contentRefId] as PieOrStackedBarChartContent;
  const { hasLegend, slices, title } = content;

  const sem = `${semantics(
    `<chart:chart xmlns:chart="http://bettermarks.com/mathcore/chart" hasLegend="${hasLegend}">
    ${
      !isNil(title)
        ? `<chart:title>
        ${exportContent(title.$refid, context)}
      </chart:title>`
        : ''
    }
    <chart:slices>
      ${exportSlice(slices, context)}
    </chart:slices>
  </chart:chart>`,
    annotationInner(content.$, {
      ...content,
    })
  )}`;

  // for the validation an additional math-node wrapping the semantics is required
  const withMath = `<math display="block" render-style="text"
 xmlns="http://www.w3.org/1998/Math/MathML">${sem}</math>`;

  return content.$interactionType ? withMath : sem;
};

export const exportStackedBarChart: Exporter = (contentRefId, context) =>
  exportPieOrStackedBarChartXML(contentRefId, context);
