import * as React from 'react';
import { isEmpty } from 'lodash/fp';
import { type ContextState, PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';

import { type ListSelectionContent } from '@bettermarks/gizmo-types';
import { evaluateValueSetterExpression } from '@bettermarks/umc-kotlin';
import { valueSetterMapToValidatorValueMap } from '../formula/Formula/helper';

export type ListSelectionProps = ListSelectionContent & ContextState;

export const ListSelection: React.FC<ListSelectionProps> = ({
  items,
  valueSetterMap,
  availableWidth,
  dynamicIndexExpression,
}) => {
  let selectedIndex = 0;

  if (valueSetterMap && !isEmpty(valueSetterMap)) {
    selectedIndex = evaluateValueSetterExpression({
      expression: dynamicIndexExpression,
      valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
    });
  }

  return <PolymorphicGizmo refid={items[selectedIndex].$refid} availableWidth={availableWidth} />;
};

ListSelection.displayName = 'ListSelection';
