import React from 'react';
import get from 'lodash/get';
import styled, { ThemeProvider } from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import { type Bounds, TooltipPlace } from './types';
import { InnerArrow, Nose } from './common';
import defaultTheme from '../../themes/defaultTheme';
import * as themes from './themes';
import { POPUP_MARGIN, POPUP_STILL_MARGIN, TOOLTIP_WIDTH } from './constants';

export type ToolTipBounds = {
  bounds?: Bounds;
  noseBounds: Bounds;
  place?: 'top' | 'bottom';
};

export type ToolTipProps = ToolTipBounds & {
  show: boolean;
  still?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
};

/**
 * Popup - UI Component
 * Used to render                                                the Popups and Tooltip
 *
 * ### Properties
 | Name       | Type                               | Default    | Description                     |
 |---         | ---                                |---         | ---                             |
 |bounds      | `Bounds`                           |Required    |tooltip's left and top positions |
 |noseBounds  | `Bounds`                           |Required    |Arrow head'sleft and top positions|
 |place       | one of `top`, `bottom`             |Required    |Arrow head at `place`            |
 |still       | `boolean`                          |Optional    |Relative position for styleguide |
 |show        | `boolean`                          |Required    |Toggles the tooltip visisibilty  |
 |---         | ---                                |---         | ---                             |
 */

const { dimensions, colors } = defaultTheme;

const StyledToolTip = styled.div<Partial<ToolTipProps> & { hidden: boolean }>`
  position: relative;
  background: ${colors.cWhite};
  border: ${dimensions.borderWidthS} solid ${colors.cAreaBorder};
  display: inline-block;
  text-align: center;
  box-shadow: 0 0 ${dimensions.shadowBlurMedium} 0 ${colors.cShadowLight};

  width: auto;
  max-width: ${TOOLTIP_WIDTH};
  height: auto;
  padding: ${dimensions.spaceXs};
  text-align: center;
  opacity: 0;
  display: inline-block;
  position: absolute;
  clear: both;
  pointer-events: none;
  .text {
    font-size: ${dimensions.fontSizeM};
    line-height: ${dimensions.lineHeightM};
    color: ${colors.cGray800};
  }

  ${({ show }) =>
    show &&
    `
    opacity: 1;
    z-index: ${dimensions.zIndexMiddle};
  `}

  ${({ still }) =>
    still &&
    `
    opacity: 1;
    position: relative;
    margin: ${POPUP_STILL_MARGIN};
  `}
  
  margin: ${({ place }) =>
    place &&
    `
    ${
      place === TooltipPlace.top
        ? `0 ${POPUP_MARGIN} ${POPUP_MARGIN};`
        : `${POPUP_MARGIN} ${POPUP_MARGIN} 0;`
    }
  `};

  ${({ hidden }) =>
    hidden &&
    `
    visibility: hidden;
  `};
`;

export const ToolTip: React.FC<ToolTipProps> = ({
  children,
  show,
  still,
  bounds,
  noseBounds,
  place,
  innerRef,
}) => (
  <StyledToolTip
    place={place}
    show={show}
    still={still}
    hidden={!still && isEmpty(bounds)}
    ref={innerRef}
    style={bounds}
    role="button"
  >
    <div>{children}</div>
    <ThemeProvider theme={get(themes, place || TooltipPlace.bottom, {})}>
      <Nose style={noseBounds}>
        <InnerArrow />
      </Nose>
    </ThemeProvider>
  </StyledToolTip>
);

export default React.forwardRef<HTMLDivElement, ToolTipProps>((props, ref) => (
  <ToolTip innerRef={ref} {...props} />
));
