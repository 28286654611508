import { createAction } from 'redux-actions';
import { type ContentDict } from '@bettermarks/gizmo-types';
import {
  type FetchFEMParams,
  type FetchKEMParams,
} from '../seriesplayer/services/api/content-manager';
import { type EMKind, type FEMLoadedPayload } from './reducer';
import { type StateWithHistory } from 'redux-undo';
import type { ViewedReportBody } from '../seriesplayer/services/api/report-problem-manager/types';

export const EM_REQUESTED = 'EM_REQUESTED';
export const emRequested = createAction<FetchKEMParams | FetchFEMParams>(EM_REQUESTED);

export const EM_LOADING_STARTED = 'EM_LOADING_STARTED';
export const emLoadingStarted = createAction<EMKind>(EM_LOADING_STARTED);

export const KEM_LOADED = 'KEM_LOADED';
export const kemLoaded = createAction<StateWithHistory<ContentDict>>(KEM_LOADED);

export const FEM_LOADED = 'FEM_LOADED';
export const femLoaded = createAction<FEMLoadedPayload>(FEM_LOADED);

export const HANDLE_EM_ERROR = 'HANDLE_EM_ERROR';
export const handleError = createAction<EMKind>(HANDLE_EM_ERROR);

export type QuitWhiteboardActionPayload = {
  viewedBody: ViewedReportBody;
  resultManagerUrl: string;
};

export const QUIT_WHITEBOARD = 'QUIT_WHITEBOARD';
export const quitWhiteboard = createAction<QuitWhiteboardActionPayload>(QUIT_WHITEBOARD);
