import { type FontWeight } from '@bettermarks/bm-font';
import { compact, isNil } from 'lodash';

import {
  mapSeverityToDecoration,
  MFENCED,
  MI,
  MN,
  MO,
  MSUB,
  MSUP,
  roundNumber,
} from '@bettermarks/importers';
import {
  DEFAULT_MATH_CONTENT,
  type DerivativeType,
  type FormulaContent,
  type FunctionParameter,
  FunctionType,
  type FunctToFormulaSign,
  isPureMathContent,
  type MathContent,
  type ParametricFunction,
  type PureMathContent,
} from '@bettermarks/gizmo-types';
import { getParameter } from './defaults';
import { functionParams, secantParamValues, switchStatement } from './helpers';
import { f as fVal, secant } from './math/evaluate';

/**
 * create the MN element for the given parameter and colorize it
 * @param p function parameter
 * @param sliders each slider has an associated color which will be used to
 * decorate the corresponding number inside the formula
 */
const paramMN = (p: FunctionParameter) => ({
  ...MN(`${roundNumber(p.value)}${p.unit ? p.unit : ''}`),
  decoration: {
    ...p.decoration,
    ...(!isNil(p.refId) ? { fontWeight: 'bold' as FontWeight } : {}),
    ...mapSeverityToDecoration(p.severity),
  },
});

const paramMO = (op: string, p: FunctionParameter) => ({
  ...MO(op),
  decoration: {
    ...p.decoration,
    ...mapSeverityToDecoration(p.severity),
  },
});

/* To check the Sign of the value and  to return a sign and value
depending on the simplified opertors in the expression
*/
const signAndValue = (p: FunctionParameter, sign: FunctToFormulaSign) => {
  return sign === '+'
    ? p.value < 0
      ? [paramMO('-', p), paramMN({ ...p, value: Math.abs(p.value) })]
      : [paramMO('+', p), paramMN({ ...p, value: Math.abs(p.value) })]
    : p.value <= 0
    ? [paramMO('+', p), paramMN({ ...p, value: Math.abs(p.value) })]
    : [paramMO('-', p), paramMN({ ...p, value: Math.abs(p.value) })];
};

/**
 * Determines if a parameter needs to be rendered. Usually, if it's a neutral
 * element of the outer term, e.g. if x + p where p=== 0 we can omit the term
 * and just write x. This is not true though, if the parameter is controlled by
 * a slider (checked via the presence of the "refid" property) - then it's
 * always shown.
 */
const isVisible = (p: FunctionParameter, v = 0) => !(isNil(p.refId) && p.value === v);

/**
 * generate a quadratic function formula from the given parameters
 * The formula follows this format: f(x) = a(x-d)^2 + e
 * @param f the input function data
 * @param a see formula format above
 * @param d see formula format above
 * @param e see formula format above (this param is controlled by the slider)
 */
export const quadraticFunctionToFormula: (f: ParametricFunction) => FormulaContent = (
  f: ParametricFunction,
  a = getParameter(f, 'a'),
  d = getParameter(f, 'd'),
  e = getParameter(f, 'e')
) => ({
  ...DEFAULT_MATH_CONTENT,
  content: compact([
    ...(isVisible(a)
      ? [
          isVisible(a, 1) && paramMN(a),
          MSUP(isVisible(d) ? MFENCED([MI('x'), ...signAndValue(d, '-')]) : MI('x'), MN('2')),
        ]
      : []),
    ...(isVisible(e)
      ? isVisible(a)
        ? signAndValue(e, '+')
        : [paramMN({ ...e, value: e.value })]
      : []),
  ]),
});

/**
 * generate a exponential function formula from the given parameters
 * @param f the input function data
 * @param sliders the sliders (needed to get the color)
 */
export const exponentialFunctionToFormula: (f: ParametricFunction) => FormulaContent = (
  f: ParametricFunction,
  a = getParameter(f, 'a'),
  b = getParameter(f, 'b'),
  d = getParameter(f, 'd'),
  e = getParameter(f, 'e')
): FormulaContent => ({
  ...DEFAULT_MATH_CONTENT,
  content: compact([
    ...(isVisible(a, 1) ? [paramMN(a), MO('*')] : []),
    isVisible(b, 2.718) &&
      MSUP(paramMN(b), isVisible(d) ? MFENCED([MI('x'), ...signAndValue(d, '-')]) : MI('x')),
    ...(isVisible(e) ? signAndValue(e, '+') : []),
  ]),
});

/**
 * generate a logarithmic function formula from the given parameters
 * @param f the input function data
 * @param sliders the sliders (needed to get the color)
 */
export const logarithmicFunctionToFormula: (f: ParametricFunction) => FormulaContent = (
  f: ParametricFunction,
  a = getParameter(f, 'a'),
  b = getParameter(f, 'b'),
  d = getParameter(f, 'd'),
  e = getParameter(f, 'e')
): FormulaContent => ({
  ...DEFAULT_MATH_CONTENT,
  content: compact([
    isVisible(a, 1) && paramMN(a),
    isVisible(b, 2.718) ? MSUB(MI('log'), paramMN(b)) : MI('log'),
    isVisible(d) ? MFENCED([MI('x'), ...signAndValue(d, '-')]) : MI('x'),
    ...(isVisible(e) ? signAndValue(e, '+') : []),
  ]),
});

/**
 * generate a sinus function formula from the given parameters
 * @param f the input function data
 * @param sliders the sliders (needed to get the color)
 */
export const sinusFunctionToFormula: (f: ParametricFunction) => FormulaContent = (
  f: ParametricFunction,
  a = getParameter(f, 'a'),
  b = getParameter(f, 'b'),
  c = getParameter(f, 'c'),
  d = getParameter(f, 'd')
): FormulaContent => ({
  ...DEFAULT_MATH_CONTENT,
  content: compact([
    isVisible(a, 1) && paramMN(a),
    MI('sin'),
    MFENCED(
      compact([
        isVisible(b, 1) && paramMN(b),
        MI('x'),
        ...(isVisible(c) ? signAndValue(c, '+') : []),
      ])
    ),
    ...(isVisible(d) ? signAndValue(d, '+') : []),
  ]),
});

/**
 * generate a power function formula from the given parameters
 * @param f the input function data
 * @param sliders the sliders (needed to get the color)
 */
export const powerFunctionToFormula: (f: ParametricFunction) => FormulaContent = (
  f: ParametricFunction,
  a = getParameter(f, 'a'),
  e = getParameter(f, 'e'),
  n = getParameter(f, 'n')
): FormulaContent => ({
  ...DEFAULT_MATH_CONTENT,
  content: compact([
    isVisible(a, 1) && paramMN(a),
    ...(isVisible(n, 1) ? [MSUP(MI('x'), paramMN(n))] : []),
    ...(isVisible(e) ? signAndValue(e, '+') : []),
  ]),
});

const firstPureMathContent = ([first, second]: MathContent[]): PureMathContent[] | undefined[] =>
  isPureMathContent(first) && isPureMathContent(second) ? [first, second] : [undefined, undefined];

const fixLeadingOperator = (
  content: MathContent[],
  [first, second] = firstPureMathContent(content)
): MathContent[] =>
  first && first.$ === 'mo' && second && second.$ === 'mn'
    ? compact([
        first.text === '-'
          ? {
              ...second,
              text: `${-1 * parseFloat(second.text)}`,
            }
          : second,
        ...content.splice(2),
      ])
    : content;

/**
 * generate a polynomial function formula from the given parameters
 * @param f the input function data
 * @param sliders the sliders (needed to get the color)
 */
export const polynomialFunctionToFormula: (f: ParametricFunction) => FormulaContent = (
  f: ParametricFunction,
  a0 = getParameter(f, 'a0'),
  a1 = getParameter(f, 'a1'),
  a2 = getParameter(f, 'a2'),
  a3 = getParameter(f, 'a3'),
  a4 = getParameter(f, 'a4')
): FormulaContent => ({
  ...DEFAULT_MATH_CONTENT,
  content: fixLeadingOperator(
    compact([
      ...(isVisible(a4, 0) ? [...signAndValue(a4, '+'), MSUP(MI('x'), MN('4'))] : []),
      ...(isVisible(a3, 0) ? [...signAndValue(a3, '+'), MSUP(MI('x'), MN('3'))] : []),
      ...(isVisible(a2, 0) ? [...signAndValue(a2, '+'), MSUP(MI('x'), MN('2'))] : []),
      ...(isVisible(a1, 0) ? [...signAndValue(a1, '+'), MI('x')] : []),
      ...(isVisible(a0, 0) ? [...signAndValue(a0, '+')] : []),
    ])
  ),
});

/**
 * generate a vline function formula from the given parameters
 * @param f the input function data
 * @param sliders the sliders (needed to get the color)
 */
export const vlineFunctionToFormula = (
  f: ParametricFunction,
  e = getParameter(f, 'e')
): FormulaContent => ({
  ...DEFAULT_MATH_CONTENT,
  content: compact([isVisible(e, 0) && paramMN(e)]),
});

/**
 * Convert a parametrized formula description to actual MathML content.
 * @param f the parametrized formula
 * @param sliders each slider has an associated color which will be used to
 * decorate the corresponding number inside the formula
 */
export const functionToFormula = (f: ParametricFunction): FormulaContent =>
  switchStatement<FormulaContent>(
    {
      [FunctionType.quadratic]: quadraticFunctionToFormula,
      [FunctionType.exponential]: exponentialFunctionToFormula,
      [FunctionType.logarithmic]: logarithmicFunctionToFormula,
      [FunctionType.sinus]: sinusFunctionToFormula,
      [FunctionType.power]: powerFunctionToFormula,
      [FunctionType.polynomial]: polynomialFunctionToFormula,
      [FunctionType.vline]: vlineFunctionToFormula,
    },
    { ...DEFAULT_MATH_CONTENT }
  )(f.type, f);

const numberToFormula = (v: number) => ({
  ...DEFAULT_MATH_CONTENT,
  content: [MN(`${roundNumber(v)}`)],
});

const evaluateToFormula = (f: ParametricFunction, d: DerivativeType): FormulaContent =>
  numberToFormula(
    fVal(f.type)(functionParams(f))(d)(getParameter(f, f.x === 'sx' ? 'sx1' : 'tx').value)
  );

/**
 * Evaluates the slope of the secant and renders it as a fomrmula.
 */
const secantSlopeFormula = (
  f: ParametricFunction,
  [s1, _, __, ___, s2] = secant(secantParamValues(f), f.type, functionParams(f))
): FormulaContent => numberToFormula((s2[1] - s1[1]) / (s2[0] - s1[0]));

/**
 * Evaluates the value of f at tx or sx1 and renders it as a formula
 */
export const valueFormula = (f: ParametricFunction): FormulaContent => evaluateToFormula(f, 0);

/**
 * Evaluates the slope of the tangent at tx or the secant and render it as a
 * formula.
 */
export const slopeFormula = (f: ParametricFunction): FormulaContent =>
  f.x === 'tx' ? evaluateToFormula(f, 1) : secantSlopeFormula(f);
