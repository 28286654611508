import * as React from 'react';
import { CircularButton } from '@seriesplayer/common-ui';
import { StepperPlus, StepperMinus } from '../../../components';
import styles from './Stepper.scss';

export type StepperProps = {
  min: number;
  max: number;
  value?: number;
  onChange?: (index: number) => void;
};

/**
 * The `Stepper` is a component for incrementing/decrementing integer values.
 *
 * ### Process State
 *
 * ### Properties
 | Name               | Type           | Default   | Description                   |
 |---                 |---             |---        |---                            |
 | `min`              | `number`       | Required  | minimum value of the stepper  |
 | `max`              | `number`       | Required  | maximum value of the stepper  |
 | `value`            | `number`       | Optional  | value that is currently shown |
 | `onChange`         | `Function`     | Optional  | callback to update the value  |
 */
const Stepper: React.FC<StepperProps> = ({ min, max, value = 1, onChange }) => {
  const minusEnabled = value > min;
  const plusEnabled = value < max;

  const clickMinus = () => onChange && onChange(value - 1);
  const clickPlus = () => onChange && onChange(value + 1);

  return (
    <div className={styles.wrapper}>
      <CircularButton enabled={minusEnabled} onClick={clickMinus}>
        <StepperMinus width={12} height={12} />
      </CircularButton>
      <div className={styles.value}>{value}</div>
      <CircularButton enabled={plusEnabled} onClick={clickPlus}>
        <StepperPlus width={12} height={12} />
      </CircularButton>
    </div>
  );
};

Stepper.displayName = 'Stepper';

export default Stepper;
