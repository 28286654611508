import {
  CONFIGURATION,
  type Importer,
  type NLPTextInputConfiguration,
  type NLPTextInputContent,
  type NLPTextInputValidationType,
  PIXEL_PER_CHAR,
} from '@bettermarks/gizmo-types';

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @param context
 * @returns The metadata and parsed xml as `Content`
 */
export const importNlpText: Importer<NLPTextInputContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  const config = mrow.findChildTag(CONFIGURATION);

  const referenceAnswers = config
    .findChildTag('referenceAnswers')
    .children.map((refAnswerTag) => refAnswerTag.text);

  const { minInputWidth, ...restPreContent } = preContent;

  const configuration: NLPTextInputConfiguration = {
    validationType: config.findChildTag('validationType').text as NLPTextInputValidationType,
    minInputWidth:
      minInputWidth || Math.min(...referenceAnswers.map((a) => a.length)) * PIXEL_PER_CHAR,
    referenceAnswers,
  };

  return {
    ...restPreContent,
    configuration,
    content: mrow.findChildTag('mtext').text,
    refId: context.$refid,
  };
};
