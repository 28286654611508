import { getLineCoords } from '../../helpers';
import { add, normal, screenToWorld } from '@bettermarks/importers';
import { snapLineToPoints } from '../../snap';
import { type Coords, type GeoScene, type SnapPoint, STRAIGHTLINE } from '@bettermarks/gizmo-types';

export type LineAndSnapPoints = {
  lineCoordinates: Coords[];
  snapPoints: SnapPoint[];
};

/**
 * On dragging perpendicular tool, we want to snap the line to existing
 * points on geo.
 *
 * Ex:
 *
 *     |
 *     |A          B
 *  --(.)---------(.)----  // perpendicular line (d) goes thru
 *     |              (d)  // A and B
 *     |
 *
 *  // When dragging (d), the line should be snapped to points A and B
 *  // when close enough
 *
 * @returns All points snapped to the line coordinates corresponding to the mouse cursor
 *
 */
export const getLineAndSnapPoints = (
  mouseP: Coords,
  selectedDirection: Coords,
  scene: GeoScene,
  matrix: number[],
  scale: number
): LineAndSnapPoints => {
  // Get line coordinates based on the mouse pointer
  let [p1, p2] = getLineCoords(mouseP, add(mouseP, normal(selectedDirection)), STRAIGHTLINE);

  // Get points close enough to this cursor pointer based line
  let snapPoints = snapLineToPoints(
    [p1, p2],
    STRAIGHTLINE,
    scene.points,
    scene.invisibleSnapPoints,
    matrix,
    scale
  );

  // If the line can be snapped to a point
  //  1. line coordinates need to changed to the one passing by this point
  //  2. Refresh snap points to this new line (it can be different)
  // Note: we select the closest snap point, e.g. the first one of recovered snapPoints
  const [firstSnapPoint] = snapPoints;
  if (firstSnapPoint) {
    const coords = screenToWorld(matrix)({
      x: firstSnapPoint.x,
      y: firstSnapPoint.y,
    });
    // 1. Refresh line coordinates with the one passing by the snapped point
    [p1, p2] = getLineCoords(coords, add(coords, normal(selectedDirection)), STRAIGHTLINE);
    // 2. Refresh snap points based on the new line coordinates
    snapPoints = snapLineToPoints(
      [p1, p2],
      STRAIGHTLINE,
      scene.points,
      scene.invisibleSnapPoints,
      matrix,
      scale
    );
  }

  return { lineCoordinates: [p1, p2], snapPoints };
};
