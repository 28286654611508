import { isEqual } from 'lodash';
import { connect, type MapDispatchToPropsFunction, type MapStateToProps } from 'react-redux';
import type log from 'loglevel';

import {
  ApplicationState,
  DialogType,
  type ExerciseDisplayStatus,
  type GetParams,
  type ILoadExercisePayload,
  type ILoadFilesPayload,
  type LoaderState,
  type LobLinkParams,
  type LobLinkQueryParams,
  type ScreenLockType,
  type SelectedDialog,
  SeriesMode,
} from '../../../../types';
import { type ToolbarElements } from '@bettermarks/gizmo-types';
import {
  withShortcuts,
  simpleShortcut,
  type ShortcutsMap,
  type ShortcutCallbacks,
} from '../../../../gizmo-utils/keyboard';
import * as GizmoActions from '../../../../gizmo-utils/redux/gizmoActions';

import { getExerciseDisplayStatus, getSeriesProgress } from '../Series/helpers';
import * as ExerciseActions from '../Exercise/actions';
import * as UploadActions from '../Upload/actions';
import * as SeriesActions from '../Series/actions';
import * as SeriesPlayerActions from './actions';
import { SeriesPlayer, type ResizePayload } from './SeriesPlayer';

type DispatchProps = {
  closeSeriesPlayerWhenTestModeAndAfterDueDate: () => void;
  onCloseDialog?: () => void;
  onFetchLobLinkSeries: (params: LobLinkParams) => void;
  onFetchRemoteXML: (getParams: ILoadExercisePayload) => void;
  onFetchSeriesFromFile: (payload: ILoadExercisePayload) => void;
  onFetchSeries: (getParams: GetParams & Partial<{ assignmentEndDate: string }>) => void;
  onLoadFiles: (data: ILoadFilesPayload) => void;
  onNextExercise: (index: number) => void;
  onOffline: () => void;
  onOnline: () => void;
  onPreviousExercise: (index: number) => void;
  onQuit: () => void;
  onResize: (payload: ResizePayload) => void;
  onSetLobLinkReportingParams: (params: LobLinkQueryParams) => void;
  onSetLtiReporting: () => void;
  onShowCloseConfirmation: (mode: SeriesMode) => void;
  onSwitchExercise: (index: number) => void;
} & ShortcutCallbacks;

type OwnProps = Record<string, never>;

export const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, OwnProps> = (
  dispatch
) => ({
  closeSeriesPlayerWhenTestModeAndAfterDueDate: () =>
    dispatch(
      SeriesPlayerActions.openDialog({
        type: DialogType.closeConfirmationAfterDueDate,
      })
    ),

  onCloseDialog: () => dispatch(SeriesPlayerActions.closeDialog()),

  onFetchLobLinkSeries: (params: LobLinkParams) =>
    dispatch(SeriesPlayerActions.fetchLobLinkSeries(params)),

  onFetchRemoteXML: (getParams: ILoadExercisePayload) =>
    dispatch(SeriesPlayerActions.spFetchRemoteXML(getParams)),

  onFetchSeriesFromFile: (payload: ILoadExercisePayload) =>
    dispatch(UploadActions.startFromSeriesFile(payload)),

  onFetchSeries: (getParams: GetParams & Partial<{ assignmentEndDate: string }>) =>
    dispatch(SeriesPlayerActions.fetchSeries(getParams)),

  onLoadFiles: (data: ILoadFilesPayload) => dispatch(UploadActions.startFromLocalFiles(data)),

  onNextExercise: (index: number) => dispatch(ExerciseActions.switchExerciseResumable(index + 1)),

  onOffline: () => dispatch(SeriesPlayerActions.switchToOffline()),

  onOnline: () => dispatch(SeriesPlayerActions.switchToOnline()),

  onPreviousExercise: (index: number) =>
    dispatch(ExerciseActions.switchExerciseResumable(index - 1)),

  onQuit: () => dispatch(SeriesPlayerActions.quitSeriesPlayer()),

  onRegisterShortcuts: (shortcuts: ShortcutsMap) =>
    dispatch(GizmoActions.registerShortcuts(shortcuts)),

  onResize: (availableDimensions: ResizePayload) =>
    dispatch(SeriesPlayerActions.windowResized(availableDimensions)),

  onSetLobLinkReportingParams: (params: LobLinkQueryParams) =>
    dispatch(SeriesPlayerActions.setReportLobLinkSettings(params)),

  onSetLtiReporting: () => dispatch(SeriesPlayerActions.setLtiReporting()),

  onShowCloseConfirmation: (mode: SeriesMode) => {
    mode === SeriesMode.test && dispatch(ExerciseActions.saveInput());
    dispatch(SeriesPlayerActions.openDialog({ type: DialogType.closeConfirmation }));
  },

  onSwitchExercise: (index: number) => dispatch(ExerciseActions.switchExerciseResumable(index)),

  onUnregisterShortcuts: (payload: GizmoActions.UnregisterShortcutsPayload) =>
    dispatch(GizmoActions.unregisterShortcuts(payload)),
});

type StateProps = {
  availableHeight: number;
  availableWidth: number;
  contentLocale?: string;
  currentExerciseIndex: number;
  dialog?: SelectedDialog;
  exerciseDisplayStatus: ExerciseDisplayStatus[];
  focusElement?: string;
  assignmentEndDate?: string;
  hideContent?: boolean;
  interceptBrowserBackButton?: boolean;
  isEmbedded: boolean;
  loaderState: LoaderState;
  logLevel: log.LogLevelDesc;
  mode: SeriesMode;
  openDrawerName: Nullable<ToolbarElements>;
  progress: number;
  quitOnBrowserBack?: boolean;
  screenLockType: ScreenLockType;
  stepValidationLoaded: boolean;
};
// eslint-disable-next-line @typescript-eslint/ban-types
const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => {
  const exercise = ApplicationState.toCurrentExercise.get(state);
  const openDrawerName = ApplicationState.toToolbarOpenDrawerName.get(
    state
  ) as Nullable<ToolbarElements>;
  const locale = exercise && exercise.locale;

  const {
    appSettings: { logLevel, embedded: isEmbedded },
    dialog,
    features: { quitOnBrowserBack },
    focusElement,
    hideContent,
    interceptBrowserBackButton,
    loaderState,
    runtimeState: { availableWidth, availableHeight },
    screenLock: { type: screenLockType },
    series: { currentExerciseIndex, exercises, mode, assignment },
  } = state;

  return {
    availableHeight,
    availableWidth,
    contentLocale: locale,
    currentExerciseIndex,
    dialog,
    exerciseDisplayStatus: getExerciseDisplayStatus(exercises),
    focusElement,
    assignmentEndDate: assignment?.endDate,
    hideContent,
    interceptBrowserBackButton,
    isEmbedded,
    loaderState,
    logLevel,
    mode,
    openDrawerName,
    progress: getSeriesProgress(exercises, mode),
    quitOnBrowserBack,
    screenLockType,
    stepValidationLoaded: !!(exercise && exercise.stepValidationLoaded),
  };
};

export const enterShortcut: ShortcutsMap = {
  [simpleShortcut('Enter')]: SeriesActions.userPressedEnter(),
};

const connector = connect(mapStateToProps, mapDispatchToProps, undefined, {
  areStatePropsEqual: isEqual,
});

export type SeriesPlayerReduxProps = StateProps & DispatchProps;

export const SeriesPlayerContainer: React.FC<OwnProps> = connector(
  withShortcuts(enterShortcut, SeriesPlayer)
);
