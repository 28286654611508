import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Bar, defaultTheme, Layout as BaseLayout } from '@seriesplayer/common-ui';

import { ContentPathHandler } from '@bettermarks/umc-kotlin';
import { DialogHeader } from '../../../../../components/index';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { type FetchKEMParams } from '../../../../../apps/seriesplayer/services/api/content-manager';
import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { type EMStatus } from '../../../../../types';

import { KEMPlayer } from '../../../../emplayer/KEMPlayer';
import { ContentManagerRoutes } from '../../../services/api/content-manager/constants';
import { useDesktopMediaQuery } from '../../../responsiveHelpers';
import { type StateWithHistory } from 'redux-undo';
import { useLocation } from 'react-router-dom';

export type TextbookStateProps = Readonly<{
  kemData?: StateWithHistory<ContentDict>;
  locale: string;
  kemId: string;
  staticServerUrl: string;
  staticMediaUrl: string;
  status?: EMStatus;
}>;

export type TextbookDispatchProps = Readonly<{
  onCancel: () => void;
  onShowFEM: (id: string, search: string) => void;
  onInit: (initParams: FetchKEMParams) => void;
  onReset: () => void;
  dispatch: Dispatch;
}>;

export type TextbookProps = TextbookStateProps & TextbookDispatchProps;

const Layout = styled(BaseLayout)`
  background-color: ${defaultTheme.colors.cWhite};
  box-sizing: border-box;
  max-width: ${defaultTheme.dimensions.dialogL};
  display: flex;
  flex-direction: column;
`;

function getPdfLink(kem: string, locale: string, staticServerUrl: string) {
  const path = ContentPathHandler.pathKEMFile(kem, locale, '.pdf');
  return ContentManagerRoutes.getEM({ staticServerUrl, path, encrypt: true });
}

export const Textbook: React.FC<TextbookProps> = ({
  dispatch,
  kemId,
  kemData,
  status,
  locale,
  onCancel,
  onShowFEM,
  onInit,
  onReset,
  staticServerUrl,
  staticMediaUrl,
}) => {
  const [t] = useTranslation();
  const { search } = useLocation();
  const isDesktop = useDesktopMediaQuery();

  return (
    <Layout vertical>
      <DialogHeader
        title={t('seriesplayer:dialog.help.textbook')}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
          dataCy: 'textbook-back-btn',
        }}
        rightButton={
          isDesktop
            ? {
                icon: 'UseFileXLarge',
                onClick: () => undefined,
                wrapper: (button) => (
                  <a
                    href={getPdfLink(kemId, locale, staticServerUrl)}
                    target="_blank"
                    rel="noreferrer"
                    download
                    data-cy="textbook-pdf-link"
                  >
                    {button}
                  </a>
                ),
              }
            : undefined
          /*
            For posterity:
            We have disabled the download pdf button for mobile devices because
            it was causing a bug when this view was accessed through the mobile app.
            [SPL-105: Download Button in KEM without function on mobile devices](https://bettermarks.atlassian.net/browse/SPL-105)
          */
        }
      />
      <Bar />
      <KEMPlayer
        id={kemId}
        dispatch={dispatch}
        encrypt={true}
        kemData={kemData}
        status={status}
        locale={locale}
        onCancel={onCancel}
        onShowFEM={(id) => onShowFEM(id, search)}
        onInit={onInit}
        onReset={onReset}
        staticServerUrl={staticServerUrl}
        staticMediaUrl={staticMediaUrl}
      />
    </Layout>
  );
};
