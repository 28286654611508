import * as React from 'react';
import type { PenColor } from '../types';
import { colors } from '@seriesplayer/common-ui';

interface IDrawingTip {
  mousePos: {
    x: number;
    y: number;
  };
  visible: boolean;
  zIndex: number;
  color: PenColor;
  size: number;
}

export default function DrawingTip({ mousePos, visible, zIndex, color, size }: IDrawingTip) {
  const drawingTipStyle: React.CSSProperties = {
    backgroundColor: colors[color],
    opacity: 0.7,
    height: `${size * 2}px`,
    width: `${size * 2}px`,
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    left: `${mousePos.x}px`,
    top: `${mousePos.y}px`,
    zIndex: zIndex,
    pointerEvents: 'none',
    visibility: `${visible ? 'visible' : 'hidden'}`,
    display: 'flex',
  };

  return <div style={drawingTipStyle}></div>;
}
