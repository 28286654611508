import {
  type Annotations,
  type FElement,
  type GizmoContent,
  type Importer,
  parseCommonAttribs,
  type SuperGizmoContent,
} from '@bettermarks/gizmo-types';

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 * It is special in that each child is treated as it's own content tree.
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @param context
 * @returns The metadata and parsed xml as `Content`
 */
export const importSuperGizmo: Importer<SuperGizmoContent> = (preContent, xml, context) => {
  const gizmoChildren = xml.children.filter((child) => {
    if (child.localName === 'annotation-xml') {
      return false;
    }
    return parseCommonAttribs(child).$renderStyle;
  });

  // each child of super gizmo is it's own content tree so we create a new root scope
  // const scope = createImporterContext(context.importers, context.idIterator);

  const gizmos: GizmoContent[] = gizmoChildren.map((gizmoNode: FElement, i) => {
    const name = gizmoNode.attribute('name', 'undefined');
    const content = context.importXML(gizmoNode);
    const { $id: instanceId = '' } = context.contentDict[content.$refid] as Annotations;
    return { name, instanceId, content };
  });

  // now we can merge the imported content trees into the outer context
  // context.mergeMissing(scope.content);
  // scope.dependencies.images.forEach(img => context.images.add(img));
  // scope.dependencies.fems.forEach(img => context.images.add(img));

  return { ...preContent, gizmos };
};
