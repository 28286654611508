import { type ViewedReportBody } from '../seriesplayer/services/api/report-problem-manager/types';

export function getParamsForViewedReporting(
  searchParamsString: string
): Omit<ViewedReportBody, 'viewDuration' | 'userAgent'> & { resultManagerUrl: string } {
  const params = new URLSearchParams(searchParamsString);
  return {
    // fallback to `lesson...` in case we had been started by an old mifro
    // TODO remove fallback once old mifros died out on client side
    learningUnitId: params.get('learningUnitId') ?? params.get('lessonUnitId') ?? undefined,
    learningUnitItemId:
      params.get('learningUnitItemId') ?? params.get('lessonUnitItemId') ?? undefined,
    assignmentId: params.get('assignmentId') ?? undefined,
    assignmentEndDate: params.get('assignmentEndDate') ?? undefined,
    groupId: params.get('groupId') ?? undefined,
    shortCode: params.get('shortCode') ?? undefined,
    resultManagerUrl: params.get('resultManagerUrl') ?? '',
  };
}

export function getDrawableAreaOverflowY(
  scrollableIframeHeight: number,
  currentVerticalScroll: number,
  overflowYOnContainer: boolean,
  overflowYisDisabled: boolean
) {
  let overflowY;
  if (overflowYisDisabled) {
    overflowY = 'hidden';
  } else {
    overflowY = scrollableIframeHeight
      ? overflowYOnContainer || currentVerticalScroll > 0
        ? 'auto'
        : 'hidden'
      : 'auto';
  }
  return overflowY;
}

function isTabletOrMobile() {
  return !!window.orientation;
}

function isLandscape() {
  return window.matchMedia('(orientation: landscape)').matches;
}

/**
 * Get the screen width for desktop, mobile and tablet (caring on device orientation)
 * @returns the screen width
 */
export function getScreenWidth() {
  let screenWidth = window.screen.width;
  if (isTabletOrMobile() && isLandscape()) {
    screenWidth = window.screen.height;
  }
  return screenWidth;
}

/**
 * Get the screen height for desktop, mobile and tablet (caring on device orientation)
 * @returns the screen height
 */
export function getScreenHeight() {
  let screenHeight = window.screen.height;
  if (isTabletOrMobile() && isLandscape()) {
    screenHeight = window.screen.width;
  }
  return screenHeight;
}
