import * as React from 'react';

import { play, prepare } from './AudioPlayer';
import { type HasAudio } from './types';

/**
 * A higher order component that injects the props `playSound` and `prepareSound` using the
 * AudioPlayer.
 */
/* eslint-disable @typescript-eslint/ban-types */
export const withAudio =
  <T extends React.PropsWithChildren<object> = {}>(
    Component: React.ComponentType<T & HasAudio>
  ): React.FC<Omit<T, keyof HasAudio>> =>
  (props: T) =>
    <Component {...props} playSound={play} prepareSound={prepare} />;
