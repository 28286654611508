import styled from 'styled-components';
import { colors } from '@seriesplayer/common-ui';

export const Wrapper = styled.div`
  height: 100vh;
  max-height: 100%;
  background-color: #fff;
  display: grid;
  grid-template-rows: 53px auto 102px 53px;
  grid-template-columns: 74px auto;
`;

export const Header = styled.div`
  grid-row: 1;
  grid-column: 2;
  background-color: #ddd;
  border-bottom: 2px solid #999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`;

export const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
`;

export const Main = styled.div`
  grid-row: 2;
  grid-column: 2;
  background-color: #fff;
  //overflow-y: scroll;
`;

export const Aside = styled.div`
  grid-row: 1 / span 4;
  grid-column: 1;
  background-color: ${colors.cGray100};
  border-right: 2px solid #999;
`;

export const KeyboardWrapper = styled.div`
  grid-row: 3;
  grid-column: 2;
  background: #ddd;
  border-top: 2px solid #aaa;
`;

export const ExerciseCircle = styled.div<{ $focused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 72px;
  padding: 8px 0;
  position: relative;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: transparent;
  ${(props) =>
    props.$focused &&
    `
      color: ${colors.cBlue600};
      border-color: rgb(221, 221, 221);
      background-color: ${colors.cBlue200};
  `}
  ${(props) =>
    !props.$focused &&
    `
    opacity: 0.2;
  `}
`;

export const Setting = styled.div`
  padding: 12px;
  border-bottom: 2px solid #ccc;
`;

export const Exercise = styled.div`
  //padding: 12px;
  //overflow-y: hidden;
`;

export const Button = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-right: 20px;
`;

export const ButtonLabel = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

export const Footer = styled.div`
  grid-row: 4;
  grid-column: 2;
  background-color: #ddd;
  border-top: 2px solid #999;
  box-sizing: border-box;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 0 12px;
`;

export const Input = styled.input`
  display: block;
  width: 540px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid #aaa;
  font-size: 16px;
  min-height: 44px;
  margin: 12px 0;
`;

export const ScaffoldingRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 540px;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  //margin: 12px 0;
  align-items: center;
  justify-content: space-between;
  height: 44px;
`;

export const FeedbackBox = styled.div<{ color: string }>`
  display: flex;
  border-style: solid;
  color: ${({ color }) => color};
  border-color: ${({ color }) => color};
  background-color: ${({ color }) => color}11;
  border-width: 2px;
  height: 40px;
  align-items: center;
  padding: 0 10px;
  width: fit-content;
  //margin-bottom: 10px;
`;

//export const X = styled.div``;

//export const X = styled.div``;

//export const X = styled.div``;

//export const X = styled.div``;

//export const X = styled.div``;
