import { EM } from '../../types';
import { compose } from 'lodash/fp';
import {
  type ContentReference,
  type FElement,
  type ImporterContext,
  xmlText,
} from '@bettermarks/gizmo-types';

const ContentElementFactory =
  <Type extends EM.ElementType>(type: Type) =>
  (content: ContentReference) => ({ type, content });
const createElementContent = ContentElementFactory<EM.ElementType.Content>(EM.ElementType.Content);
const createElementComment = ContentElementFactory<EM.ElementType.Comment>(EM.ElementType.Comment);
const createElementRule = ContentElementFactory<EM.ElementType.Rule>(EM.ElementType.Rule);

// The type attribute gives the fully qualified class name.
// We only need the class name, which appears right after the '::'.
const getKEMElementType = (element: FElement) => element.attribute('type').replace(/^.*::/, '');

/**
 * In the functions below, title is imported in multiple KEM elements as text.
 *
 * Some title tags in the KEM xml look like this:
 *   <title>Here is a title</title>
 *
 * And some look like this:
 *   <title type="com.bettermarks.mathcore.kfem.model::Content">
 *     <math display="block" render-style="text">
 *       <mtext>Here is a title</mtext>
 *     </math>
 *   </title>
 *
 * In both cases we can get the title text using FElement.text and hence using `xmlText` helper.
 */

export const importEMFreeExample = (
  element: FElement,
  importWrappedContent: (xml: FElement) => ContentReference
): EM.ElementFreeExample => ({
  ...element.tagsToProps(xmlText, [], ['title']),
  type: EM.ElementType.FreeExample,
  content: element
    .filterChildren((child) => {
      const type = getKEMElementType(child);

      return (
        child.localName === 'element' &&
        (type === EM.ElementType.Content || type === EM.ElementType.Comment)
      );
    })
    .map((element) => {
      const type = getKEMElementType(element);

      return type === EM.ElementType.Content
        ? compose(createElementContent, importWrappedContent)(element)
        : compose(createElementComment, importWrappedContent)(element);
    }),
});

export const importEMStructuredExampleStep = (
  element: FElement,
  importWrappedContent: (xml: FElement) => ContentReference
): EM.ElementStructuredExampleStep => {
  return {
    ...element.tagsToProps(xmlText, ['title']),
    ...element.tagsToProps(compose(createElementComment, importWrappedContent), [], ['comment']),
    ...element.tagsToProps(compose(createElementContent, importWrappedContent), ['result'], []),
  };
};

export const importEMStructuredExample = (
  element: FElement,
  importWrappedContent: (xml: FElement) => ContentReference
): EM.ElementStructuredExample => {
  return {
    ...element.tagsToProps(xmlText, [], ['title']),
    ...element.tagsToProps(importWrappedContent, [], ['overview', 'setting']),
    type: EM.ElementType.StructuredExample,
    steps: element
      .getChildrenByTagName('step')
      .map((element) => importEMStructuredExampleStep(element, importWrappedContent)),
  };
};

export const importEMExampleGroup = (
  element: FElement,
  importWrappedContent: (xml: FElement) => ContentReference
): EM.ElementExampleGroup => {
  return {
    ...element.tagsToProps(importWrappedContent, [], ['heading']),
    type: EM.ElementType.ExampleGroup,
    iq: Boolean(element.attribute('iq')),
    examples: element
      .filterChildren((child) => child.localName === 'example')
      .map((example) => {
        const type = getKEMElementType(example);

        if (type === EM.ElementType.FreeExample) {
          return importEMFreeExample(example, importWrappedContent);
        }

        if (type === EM.ElementType.StructuredExample) {
          return importEMStructuredExample(example, importWrappedContent);
        }

        throw new Error('Invalid example in ExampleGroup');
      }),
  };
};

export const importEMElement = (
  element: FElement,
  importWrappedContent: (xml: FElement) => ContentReference
): EM.ElementContent | EM.ElementComment | EM.ElementRule | EM.ElementExampleGroup => {
  switch (getKEMElementType(element)) {
    case EM.ElementType.Content:
      return compose(createElementContent, importWrappedContent)(element);

    case EM.ElementType.Comment:
      return compose(createElementComment, importWrappedContent)(element);

    case EM.ElementType.Rule:
      return compose(createElementRule, importWrappedContent)(element);

    default:
      return importEMExampleGroup(element, importWrappedContent);
  }
};

export const createImportWrappedContent =
  (context: ImporterContext) =>
  (xml: FElement): ContentReference =>
    context.importXML(xml.firstChild);
