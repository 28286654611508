export const EPS = 1e-7;

export type Coords = {
  x: number;
  y: number;
};

export type Segment = {
  p1: Coords;
  p2: Coords;
};

export const midPoint = (p1: Coords, p2: Coords) => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});

export const normUnitVec = (p1: Coords, p2: Coords) => {
  const normVec = { x: p1.y - p2.y, y: p2.x - p1.x };
  const absNormVec = Math.sqrt(normVec.x * normVec.x + normVec.y * normVec.y);
  return { x: normVec.x / absNormVec, y: normVec.y / absNormVec };
};

export const add = (p1: Coords, p2: Coords): Coords => ({
  x: p1.x + p2.x,
  y: p1.y + p2.y,
});

export const smult = (s: number, p: Coords): Coords => ({
  x: s * p.x,
  y: s * p.y,
});

export const dist = (p1: Coords, p2: Coords): number =>
  Math.sqrt((p2.x - p1.x) * (p2.x - p1.x) + (p2.y - p1.y) * (p2.y - p1.y));

export const intersectSegments = (seg1: Segment, seg2: Segment) => ({
  x:
    ((seg2.p2.x - seg2.p1.x) * (seg1.p2.x * seg1.p1.y - seg1.p1.x * seg1.p2.y) -
      (seg1.p2.x - seg1.p1.x) * (seg2.p2.x * seg2.p1.y - seg2.p1.x * seg2.p2.y)) /
    ((seg2.p2.y - seg2.p1.y) * (seg1.p2.x - seg1.p1.x) -
      (seg1.p2.y - seg1.p1.y) * (seg2.p2.x - seg2.p1.x)),
  y:
    ((seg1.p1.y - seg1.p2.y) * (seg2.p2.x * seg2.p1.y - seg2.p1.x * seg2.p2.y) -
      (seg2.p1.y - seg2.p2.y) * (seg1.p2.x * seg1.p1.y - seg1.p1.x * seg1.p2.y)) /
    ((seg2.p2.y - seg2.p1.y) * (seg1.p2.x - seg1.p1.x) -
      (seg1.p2.y - seg1.p1.y) * (seg2.p2.x - seg2.p1.x)),
});
