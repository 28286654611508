import { type ReadonlyDict } from '../../../../../../gizmo-utils/append-only';
import { type Action } from 'redux-actions';
import { KeyCode } from '../Keyboard/keyCodes';
import { clear, clearInp, evaluate } from './reducer';
import {
  backwardDelete,
  cursorLeft,
  cursorRight,
  enterBracket,
  enterFrac,
  enterIdentifier,
  enterNumber,
  enterOperator,
  enterRoot,
  enterSqr,
  enterSqrt,
  enterSup,
} from '../../../../../../gizmos/formula/Formula/actions';

/**
 * KeyCode -> [
 *  action to be performed for that key,
 *  array of actions to be performed for that key, if the previous action was "="
 * ]
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const keyCodeToAction: ReadonlyDict<[Action<{}>, Action<{}>[]]> = {
  [KeyCode.Equals]: [evaluate(), [evaluate()]],
  [KeyCode.Ans]: [enterIdentifier(KeyCode.Ans), [clearInp(), enterIdentifier(KeyCode.Ans)]],
  [KeyCode.Square]: [enterSqr(), [clearInp(), enterIdentifier(KeyCode.Ans), enterSqr()]],
  [KeyCode.XY]: [enterSup(), [clearInp(), enterIdentifier(KeyCode.Ans), enterSup()]],
  [KeyCode.Clear]: [clear(), [clear()]],
  [KeyCode.DecimalPoint]: [enterNumber('.'), [clearInp(), enterNumber('.')]],
  [KeyCode.Num0]: [enterNumber(KeyCode.Num0), [clearInp(), enterNumber(KeyCode.Num0)]],
  [KeyCode.Num1]: [enterNumber(KeyCode.Num1), [clearInp(), enterNumber(KeyCode.Num1)]],
  [KeyCode.Num2]: [enterNumber(KeyCode.Num2), [clearInp(), enterNumber(KeyCode.Num2)]],
  [KeyCode.Num3]: [enterNumber(KeyCode.Num3), [clearInp(), enterNumber(KeyCode.Num3)]],
  [KeyCode.Num4]: [enterNumber(KeyCode.Num4), [clearInp(), enterNumber(KeyCode.Num4)]],
  [KeyCode.Num5]: [enterNumber(KeyCode.Num5), [clearInp(), enterNumber(KeyCode.Num5)]],
  [KeyCode.Num6]: [enterNumber(KeyCode.Num6), [clearInp(), enterNumber(KeyCode.Num6)]],
  [KeyCode.Num7]: [enterNumber(KeyCode.Num7), [clearInp(), enterNumber(KeyCode.Num7)]],
  [KeyCode.Num8]: [enterNumber(KeyCode.Num8), [clearInp(), enterNumber(KeyCode.Num8)]],
  [KeyCode.Num9]: [enterNumber(KeyCode.Num9), [clearInp(), enterNumber(KeyCode.Num9)]],
  [KeyCode.Pi]: [enterIdentifier('π'), [clearInp(), enterIdentifier('π')]],
  [KeyCode.Add]: [
    enterOperator('+'),
    [clearInp(), enterIdentifier(KeyCode.Ans), enterOperator('+')],
  ],
  [KeyCode.Exponent]: [enterSup(), [clearInp(), enterIdentifier(KeyCode.Ans), enterSup()]],
  [KeyCode.Subtract]: [
    enterOperator('-'),
    [clearInp(), enterIdentifier(KeyCode.Ans), enterOperator('-')],
  ],
  [KeyCode.Multiply]: [
    enterOperator('*'),
    [clearInp(), enterIdentifier(KeyCode.Ans), enterOperator('*')],
  ],
  [KeyCode.Divide]: [
    enterOperator('/'),
    [clearInp(), enterIdentifier(KeyCode.Ans), enterOperator('/')],
  ],
  [KeyCode.CaretLeft]: [cursorLeft(), [cursorLeft()]],
  [KeyCode.CaretRight]: [cursorRight(), [cursorRight()]],
  [KeyCode.Delete]: [backwardDelete(), [backwardDelete()]],
  [KeyCode.BracketLeft]: [enterBracket('('), [clearInp(), enterBracket('(')]],
  [KeyCode.BracketRight]: [enterBracket(')'), [clearInp(), enterBracket(')')]],
  [KeyCode.Fraction]: [enterFrac(), [clearInp(), enterFrac()]],
  [KeyCode.Sqrt]: [
    enterSqrt(),
    [clearInp(), enterSqrt(), enterIdentifier(KeyCode.Ans), cursorRight()],
  ],
  [KeyCode.Root]: [
    enterRoot(),
    [clearInp(), enterRoot(), enterIdentifier(KeyCode.Ans), cursorRight()],
  ],
};
