import * as React from 'react';
import { Angle, ReadingHelp } from '../../components';
import {
  type Coords,
  type GeoConfiguration,
  isAngleObject,
  isPointReadingHelpObject,
  type PointObject,
} from '@bettermarks/gizmo-types';
import { type SetProps, type WithBorderRect } from './types';

export type ReadingHelpSetProps = SetProps &
  WithBorderRect & {
    readinghelps: ReadonlyArray<string>;
    configuration: GeoConfiguration;
  };

export function ReadingHelpSet({
  readinghelps,
  geoContentMap,
  matrix,
  borderRectId,
  configuration,
}: ReadingHelpSetProps) {
  return (
    <g>
      {readinghelps.map((id) => {
        const s = geoContentMap[id];
        if (isPointReadingHelpObject(s)) {
          const {
            coords: { x, y },
          } = geoContentMap[s.pointId] as PointObject;
          return (
            <ReadingHelp
              key={id}
              {...{ id, matrix, borderRectId, x, y }}
              decoration={s.decoration}
              axesPoints={s.axesPoints as Coords[]}
              planePoint={s.planePoint}
            />
          );
        } else if (isAngleObject(s) && s.endAngle !== s.startAngle) {
          return <Angle key={id} {...{ id, matrix, borderRectId, configuration }} {...s} />;
        }
        return null;
      })}
    </g>
  );
}
