import { MATH } from '@bettermarks/gizmo-types';
import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type FormulaConfig,
  type FormulaContent,
  hasInteractionType,
  semantics,
  xmlAttr,
} from '@bettermarks/gizmo-types';
import { removeCursor } from './helpers/removeCursor';
import { processFormula } from './helper';
import { exportDecoration, exportMathContent } from './exportMathContent';

const exportRestrictedToKeysChild = (tagName: string, content: string[] | undefined): string =>
  content ? `<${tagName}>${content.join(',')}</${tagName}>` : '';

const exportConfiguration = (config: FormulaConfig | undefined): string => {
  if (config === undefined) return '';

  const { restrictionType, restrictedToKeys } = config;

  return restrictionType !== undefined || restrictedToKeys !== undefined
    ? `<configuration>
        ${restrictionType ? `<restrictionType>${restrictionType}</restrictionType>` : ''}
        ${
          restrictedToKeys
            ? `<restrictedToKeys>
            ${exportRestrictedToKeysChild('brackets', restrictedToKeys.brackets)}
            ${exportRestrictedToKeysChild('letters', restrictedToKeys.letters)}
            ${exportRestrictedToKeysChild('numbers', restrictedToKeys.numbers)}
            ${exportRestrictedToKeysChild('operators', restrictedToKeys.operators)}
        </restrictedToKeys>`
            : ''
        }
      </configuration>
    `
    : '';
};

export const exportFormulaContent = (
  _content: FormulaContent,
  context: ExporterContext
): string => {
  const interactive = hasInteractionType(_content);
  const content = interactive
    ? // all custom handling of removing the cursor is better done the same way
      // as when DESELECT_CONTENT action hits the formula reducer:
      processFormula(removeCursor, false)(_content)
    : // not needed for non interactive content
      _content;

  const exported = content.content
    .map((c) => exportMathContent(c, context, _content.withConvertedFontSize))
    .join('');

  return content.$ === MATH
    ? `<math ${xmlAttr('render-style', content.$renderStyle)}${xmlAttr('id', content.$id)}>
      ${exported}
     </math>`
    : semantics(
        `<mrow ${exportDecoration(
          content.decoration,
          _content.withConvertedFontSize,
          content.severity
        )}>
          ${exported}${exportConfiguration(content.configuration)}
       </mrow>`,
        annotationInner(content.$, content)
      );
};

export const exportFormula: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const { defaultText, ...content } = context.content[contentRefId] as FormulaContent;
  return exportFormulaContent(content, context);
};
