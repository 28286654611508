import React from 'react';
import styled from 'styled-components';
import { colors, dimensions } from '@seriesplayer/common-ui';
import CollapsibleSection from './CollapsibleSection';
import { useTranslation } from 'react-i18next';

type StudentTasksContainerProps = {
  availableWidth: number;
  children: React.ReactNode;
};

export default function StudentTasksContainer({
  availableWidth,
  children,
}: StudentTasksContainerProps) {
  const [t] = useTranslation();

  return (
    <StudentTasksBorder availableWidth={availableWidth}>
      <CollapsibleSection title={t('seriesplayer:studentTasks')} initialMode={'collapsed'}>
        {children}
      </CollapsibleSection>
    </StudentTasksBorder>
  );
}

const StudentTasksBorder = styled.div<{ availableWidth: number }>`
  max-width: ${({ availableWidth }) => availableWidth}px;
  width: ${({ availableWidth }) => availableWidth}px;

  border: ${dimensions.borderWidthL} solid ${colors.cNeutralBorder};
  border-radius: ${dimensions.borderRadiusM};
  box-sizing: border-box;
`;
