import type {
  ContentDict,
  ContentReference,
  EditorMode,
  ToolbarElements,
} from '@bettermarks/gizmo-types';
import type { KeyboardStateProps } from './seriesplayer/containers/Toolbar/Tools/Keyboard/Keyboard';
import { IFRAME_CONTENT_TITLE } from './whiteboard/Whiteboard';

export type ClassroomIntroDataMessage = {
  type: 'classroomIntro';
  contentDict: ContentDict;
  studentTasks: ContentReference[];
};

export type LockScrollMessage = {
  type: 'lockScroll';
};

export type UnlockScrollMessage = {
  type: 'unlockScroll';
};

export type ContentLoadedMessage = {
  type: 'contentLoaded';
};

export type WhiteboardMessage =
  | {
      type: 'availableTools';
      tools: ToolbarElements[];
      modes: EditorMode[];
      keyboard?: Partial<KeyboardStateProps>;
      gizmoId?: string;
    }
  | {
      type: 'changeMode';
      mode: EditorMode;
    }
  | {
      type: 'setOpenDrawer';
      openDrawerName: Nullable<string>;
      gizmoId?: string;
    }
  | {
      type: 'keyboardInput';
      key: string;
      gizmoId: string;
    }
  | {
      type: 'showFEM';
      femId: string;
    }
  | ContentLoadedMessage
  | ClassroomIntroDataMessage
  | LockScrollMessage
  | UnlockScrollMessage;

export function postToWhiteboardChild(payload: WhiteboardMessage) {
  const iframe = document.querySelector(
    `iframe[title='${IFRAME_CONTENT_TITLE}']`
  ) as HTMLIFrameElement;
  if (iframe && iframe.contentWindow) {
    iframe.contentWindow.postMessage(payload, '*');
  }
}

export function postToWhiteboardParent(payload: WhiteboardMessage) {
  window.parent.postMessage(payload, '*');
}
