import { isUndefined } from 'lodash';
import {
  annotationInner,
  type ContentReference,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
  type SolutionSetContent,
  XML_TAG_SEPARATOR,
} from '@bettermarks/gizmo-types';

/*
  Unfortunately, hidden elements are under 'configuration' in the source XML.
  For simplicity, we do not provide a specific 'exportConfiguration' function
  like it has been done for other gizmos.
*/
export const exportSolutionSet: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as SolutionSetContent;
  const open = isUndefined(content.configuration.open)
    ? `<open/>`
    : `<open>${content.configuration.open}</open>`;
  const close = isUndefined(content.configuration.close)
    ? `<close/>`
    : `<close>${content.configuration.close}</close>`;

  return semantics(
    `
      <mrow>
         <configuration>
            <ordered>${content.configuration.ordered}</ordered>
            ${open}
            ${close}
            <${XML_TAG_SEPARATOR}>${content.configuration.separator}</${XML_TAG_SEPARATOR}>
            <empty-placeholder>
              <mrow/>
            </empty-placeholder>
            <set>
               ${content.hiddenElements
                 .map((element: ContentReference) => exportContent(element.$refid, context))
                 .join('')}
            </set>
          </configuration>
          ${content.visibleElements
            .map((element: ContentReference) => exportContent(element.$refid, context))
            .join('')}
      </mrow>
      `,
    annotationInner(content.$, content)
  );
};
