import * as React from 'react';
import { Resizable } from 're-resizable';
import { Drawer, type DrawerBaseProps, DrawerState } from './Drawer';
import styles from './ResizableDrawer.scss';
import dndDrawerStyles from './DragAndDropDrawer.scss';

const stopScrolling = (event: TouchEvent) => event.preventDefault();

const addStopScrolling = () =>
  document.addEventListener('touchmove', stopScrolling, { passive: false });
const removeStopScrolling = () => document.removeEventListener('touchmove', stopScrolling);

// Min height from design specs.
const MIN_HEIGHT = 54;

const RESIZABLE_ENABLED = {
  top: true,
};

const RESIZABLE_DEFAULT_SIZE = {
  // to maximize drawer by default (respecting max-height)
  height: '66vh',
  width: '100%',
};

const RESIZABLE_HANDLE_COMPONENT = {
  top: (
    <div className={styles.drawerKnobClickArea}>
      <span className={styles.drawerKnob}>
        <span className={styles.drawerKnobDecorativeHandle} />
      </span>
    </div>
  ),
};

interface ResizableDrawerProps extends DrawerBaseProps {
  contentHeight: number;
}

export const ResizableDrawer: React.FC<ResizableDrawerProps> = (props) => {
  const { contentHeight, state, children } = props;

  const isOpen = state === DrawerState.Open;

  const divRef = React.useRef<HTMLDivElement | null>(null);
  const [drawerHeight, setDrawerHeight] = React.useState<number>(0);
  const resizableStyles = { maxHeight: isOpen ? drawerHeight : 0 };

  React.useEffect(() => {
    if (isOpen && divRef.current) {
      setDrawerHeight(divRef.current.scrollHeight);
    }
  }, [divRef.current, isOpen]);

  // Drawer cannot be taller than 2/3 of content.
  const availableScreenHeight = (2 / 3) * contentHeight;

  // Neither it should be greater of it's contents.
  const maxHeight = Math.min(availableScreenHeight, drawerHeight);

  return (
    // This div id needed in order to make Resizable work.
    // TODO: investigate if this is really needed.
    <div>
      <Drawer state={state} style={resizableStyles} styles={dndDrawerStyles}>
        <Resizable
          enable={RESIZABLE_ENABLED}
          defaultSize={RESIZABLE_DEFAULT_SIZE}
          onResizeStart={addStopScrolling}
          onResizeStop={removeStopScrolling}
          maxHeight={maxHeight}
          minHeight={MIN_HEIGHT}
          handleComponent={RESIZABLE_HANDLE_COMPONENT}
        >
          <div ref={divRef} className={styles.toolbarRow}>
            {children}
          </div>
        </Resizable>
      </Drawer>
    </div>
  );
};

ResizableDrawer.displayName = 'ResizableDrawer';
