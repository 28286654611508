import * as React from 'react';
import { type GraphRendererProps, type GraphCallbacks } from '../GraphRenderer';
import { type GraphNode } from '@bettermarks/gizmo-types';
import { useDOMEvent, onPickEvent } from '../helpers';

export const withHoverHighlight = <P extends GraphRendererProps & GraphCallbacks>(
  WrappedComponent: React.FC<P>
): React.FC<P> =>
  function Wrapper(props) {
    // this is a named function to let the tslint rule react-hooks-nesting know it is a component
    const ref = React.useRef<HTMLSpanElement>(null);
    const [hoveredNode, setHoveredNode] = React.useState<GraphNode | null>(null);
    const { edges, nodes } = props;

    const onMouseMove = onPickEvent(ref, edges, nodes, setHoveredNode, undefined, () =>
      setHoveredNode(null)
    );

    const onTouchEnd = () => setHoveredNode(null);

    useDOMEvent(
      'touchmove',
      onPickEvent<TouchEvent>(ref, edges, nodes, setHoveredNode, undefined, () =>
        setHoveredNode(null)
      ),
      ref,
      [hoveredNode, nodes]
    );
    useDOMEvent('touchend', onTouchEnd, ref, [hoveredNode, nodes]);

    const highlightedNodes = nodes.map((n) =>
      hoveredNode && n.id === hoveredNode.id ? { ...n, highlight: true } : n
    );

    return (
      <span ref={ref} role="button" onMouseMove={onMouseMove}>
        <WrappedComponent {...props} nodes={highlightedNodes} />
      </span>
    );
  };
