import * as React from 'react';
import {
  CapArrow,
  CapEquiTriangle,
  CapNarrowArrow,
  CapOpenTriangle,
  CapTArrow,
  CapTriangle,
} from '../../../components';
import { getNamedColor } from '@bettermarks/importers';
import { type MarkerProps, MarkerType } from './types';
import styles from './Marker.scss';

const MarkerComponentMap = {
  [MarkerType.Arrow]: CapArrow,
  [MarkerType.NarrowArrow]: CapNarrowArrow,
  [MarkerType.OpenTriangle]: CapOpenTriangle,
  [MarkerType.Triangle]: CapTriangle,
  [MarkerType.TArrow]: CapTArrow,
  [MarkerType.EquiTriangle]: CapEquiTriangle,
};

export const Marker: React.FC<MarkerProps> = ({
  id,
  flip,
  color,
  type = MarkerType.Triangle,
  strokeWidth = 1,
  width = 16,
  height = type === MarkerType.Triangle || type === MarkerType.OpenTriangle ? 10 : 12,
  offset = 0,
}) => {
  const w = type === MarkerType.TArrow ? strokeWidth : width;
  const o = type === MarkerType.TArrow ? w / 2 : offset;
  const c = getNamedColor(color);
  const style = c ? (type === MarkerType.OpenTriangle ? { stroke: c } : { fill: c }) : {};
  return (
    <defs>
      <marker
        id={id}
        className={styles.marker}
        style={style}
        strokeDasharray="999 1"
        markerWidth={w}
        markerHeight={height}
        orient="auto"
        markerUnits="userSpaceOnUse"
        viewBox={`0 0 ${w} ${height}`}
        refX={flip ? w - o : o}
        refY={height * 0.5}
      >
        <g transform={`rotate(${flip ? 180 : 0} ${w * 0.5},${height * 0.5})`}>
          {React.createElement(MarkerComponentMap[type], { width: w, height })}
        </g>
      </marker>
    </defs>
  );
};

Marker.displayName = 'Marker';
