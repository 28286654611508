import * as React from 'react';
import { defaultTo } from 'lodash';
import classNames from 'classnames';
import { getFontMetric } from '../../../../../utils/fontMetric';
import {
  DEFAULT_LAYOUT_METRICS_RELATIVE,
  GOODBREAK,
  type HasLineHeight,
  type LinebreakKind,
  type MSpace as MSpaceContent,
  NEWLINE,
} from '@bettermarks/gizmo-types';
import { type EnrichMathContent } from '../../measure/measureStatic';

import styles from './tokens.scss';
import { CURLY_BOTTOM } from '../constants';

/**
 * TODO: a workaround for a kind of proper width calculation
 * should be removed once we get a more proper data for the braces rendering.
 * This values come from a proportion calculation in order to make static
 * 208 and 74 values flexible. You need to multiply current availableWidth on one of
 * coefficient depending on the curly fixed width.
 * The coefficients were obtained by dividing needed width
 * (taken from the browser console adjustments :) ) by the current availableWidth
 */
const CURLY_COEFFICIENT_MAP: Record<number, number> = {
  208: 0.34,
  74: 0.12,
};
const ADDITIONAL_MARGIN_THRESHOLD = 380;

export type MSpaceProps = HasLineHeight & {
  height?: number;
  linebreak?: LinebreakKind;
  width?: number;
  availableWidth: number;
  typeOfUnder?: string | undefined;
};

export const MSpace: React.FC<MSpaceProps> = ({
  height = 0,
  linebreak,
  lineHeight,
  width: _width = 0,
  availableWidth,
  typeOfUnder,
}) => {
  if (linebreak === GOODBREAK) return null;
  if (linebreak === NEWLINE) return <br />;

  /**
   * TODO: a workaround for a kind of proper width calculation
   * should be removed once we get a more proper data for the braces rendering
   */
  let width = _width;
  if (typeOfUnder === CURLY_BOTTOM) {
    width = CURLY_COEFFICIENT_MAP[width] * availableWidth || width;
  }

  const style: React.CSSProperties = { height };
  if (height > 0) {
    style.display = 'inline-block';
  }
  if (width > 0) {
    style.width = width;
    style.display = 'inline-block';
    // needed to fill whole background ('mrow-decoration' image test):
    style.height = lineHeight;
  }
  return (
    <span
      className={classNames(
        styles.mspace,
        typeOfUnder === CURLY_BOTTOM &&
          availableWidth <= ADDITIONAL_MARGIN_THRESHOLD &&
          styles.mspaceCurlyBottom
      )}
      style={style}
    />
  );
};

MSpace.displayName = 'MSpace';

export const enrichMSpace: EnrichMathContent<MSpaceContent> = ({ fontSize }, content) => {
  if (content.linebreak) {
    return {
      // needs to have relativeToBaseLine:true so the <br/> is not wrapped in a VerticalAlign
      ...DEFAULT_LAYOUT_METRICS_RELATIVE,
      enrichedContent: content,
    };
  }
  const height = defaultTo<number>(content.height, 0);
  if (height > 0) {
    return {
      height,
      refLine: height / 2,
      enrichedContent: content,
    };
  }

  // only width is set from the content
  const { height: lineHeight, refLine } = getFontMetric(fontSize);
  return {
    height: lineHeight,
    refLine,
    enrichedContent: { ...content, lineHeight },
  };
};
