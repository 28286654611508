import * as React from 'react';
import Measure, { type ContentRect } from 'react-measure';
import { type GraphRendererProps, GraphRenderer } from '../GraphRenderer';
import { type GraphNode, type GraphEdge } from '@bettermarks/gizmo-types';
import { type DialogPosition, positionDialog, Rect, Direction } from '../dialogPositioning';
import { NODE_RADIUS, MAX_NODE_RADIUS } from '@bettermarks/importers';
import { defaultTo } from 'lodash/fp';
import { omit } from '../../../../gizmo-utils/omit';
import {
  notSameNode,
  doesntConnectNode,
  connectsNode,
  highlightEdge,
  getWorldPos,
  pickGraphObject,
  onPickEvent,
  useDOMEvent,
  getScreenPos,
  getScreenPosByRef,
  scrollToXPosition,
} from '../helpers';
import { PopupBubble } from '../../../../components';
import { TrashXLargeBold } from '../../../../components/icons';
import { type DragScrollableProps } from '../../../../gizmo-utils/drag-scroll-behaviour';
import { withHoverHighlight } from './withHoverHighlight';
import { Maybe } from '../../../..//utils/maybe';
import { getScrollPosition } from '../../../../gizmo-utils/drag-scroll-behaviour/helpers';
import { ExcludedFromTabNavigationButton } from '../../../../components/ExcludedFromTabNavigation';

export type DeleteProps = GraphRendererProps &
  DragScrollableProps & {
    touch?: boolean;
    onDeleteEdge?: (id: string) => void;
    onDeleteNode?: (id: string) => void;
  };

/**
 * Get the position where to place the delete dialog when clicking on an edge.
 */
const edgeLabelPosition = (edge: GraphEdge, nodes: ReadonlyArray<GraphNode>) => {
  const node1 = nodes.find((n) => n.id === edge.nodeId1);
  const node2 = nodes.find((n) => n.id === edge.nodeId2);
  if (node1 && node2) {
    return {
      x: node1.position.x + 0.5 * (node2.position.x - node1.position.x),
      y: node1.position.y + 0.5 * (node2.position.y - node1.position.y),
    };
  }
  return { x: 0, y: 0 };
};

/**
 * Delete: Graph editor mode for deleting nodes and edges. On the desktop version the deletable
 * nodes and edges are highlighted when hovering. Clicking then immediately deletes them. On touch
 * devices, touching will highlight them and present us with a delete dialog to confirm the
 * deletion.
 */
/* eslint-disable-next-line complexity*/
const Delete_: React.FC<DeleteProps> = (props) => {
  const { touch, onDeleteEdge, onDeleteNode, edges, nodes, scrollBehaviour } = props;

  const divEl = React.useRef<HTMLDivElement>(null);
  /**
   * Here we store the measured size of the delete-dialog
   */
  const [bubbleRect, setBubbleRect] = React.useState<ContentRect>({
    client: { left: 0, top: 0, height: 0, width: 0 },
  });
  /**
   * The node to be deleted (i.e. highlighted with a red border)
   */
  const [deletedNode, setDeletedNode] = React.useState<GraphNode | null>(null);
  /**
   * The edge to be deleted (i.e. highlighted red)
   */
  const [deletedEdge, setDeletedEdge] = React.useState<GraphEdge | null>(null);
  const maybeScrollBehaviour = Maybe(scrollBehaviour);

  /*--------------------------------------------------------------------------------*
   *                              Event Handlers                                    *
   *--------------------------------------------------------------------------------*/

  const onNodeStart = (node: GraphNode) => {
    if (!node.addedByUser) return;
    if (touch) {
      setDeletedEdge(null);
      setDeletedNode(node);
    } else if (node.id) {
      onDeleteNode && onDeleteNode(node.id);
      setDeletedNode(null);
    }
    if (divEl.current) {
      scrollToXPosition(
        getScreenPos(node.position.x, node.position.y, maybeScrollBehaviour.ap(getScrollPosition)),
        divEl.current.getBoundingClientRect(),
        maybeScrollBehaviour
      );
    }
  };

  const onEdgeClicked = (edge: GraphEdge) => {
    if (!edge.addedByUser) return;
    if (touch) {
      setDeletedNode(null);
      setDeletedEdge(edge);
    } else if (edge.$id) {
      onDeleteEdge && onDeleteEdge(edge.$id);
      setDeletedEdge(null);
    }
    if (divEl.current) {
      const edgePos = edgeLabelPosition(edge, nodes);
      scrollToXPosition(
        getScreenPos(edgePos.x, edgePos.y, maybeScrollBehaviour.ap(getScrollPosition)),
        divEl.current.getBoundingClientRect(),
        maybeScrollBehaviour
      );
    }
  };

  const onClickDeleteButton = () => {
    if (!touch) return;
    if (deletedNode && deletedNode.id) {
      onDeleteNode && onDeleteNode(deletedNode.id);
    } else if (deletedEdge && deletedEdge.$id) {
      onDeleteEdge && onDeleteEdge(deletedEdge.$id);
    }
    setDeletedNode(null);
    setDeletedEdge(null);
  };

  const onMouseMove: React.MouseEventHandler<any> = (evt) => {
    setDeletedNode(null);
    setDeletedEdge(null);
    const pos = getWorldPos(evt.clientX, evt.clientY, divEl);
    const { edge, node } = pickGraphObject(pos, 2 * NODE_RADIUS, edges, nodes);
    if (node && node.addedByUser) {
      setDeletedNode(node);
    } else if (edge && edge.addedByUser) {
      setDeletedEdge(edge);
    }
  };

  const onClickElsewhere = (evt: TouchEvent | React.MouseEvent<any>) => {
    let el: HTMLElement | null = evt.target as HTMLElement;
    while (el) {
      if (el.id === 'graph-delete-box') return;
      el = el.parentElement;
    }
    setDeletedNode(null);
    setDeletedEdge(null);
  };

  useDOMEvent(
    'touchstart',
    onPickEvent<TouchEvent>(
      divEl,
      edges,
      nodes,
      onNodeStart,
      onEdgeClicked,
      onClickElsewhere,
      false
    ),
    divEl,
    [deletedNode, deletedEdge, nodes, edges]
  );

  /*--------------------------------------------------------------------------------*
   *                      Prepare and position delete dialog                        *
   *--------------------------------------------------------------------------------*/

  let dialogInfo: DialogPosition | undefined;
  const br = bubbleRect.client || { width: 0, height: 0 };
  if ((deletedNode && deletedNode.position) || (deletedEdge && divEl.current)) {
    const worldPos = deletedNode
      ? defaultTo({ x: 0, y: 0 }, deletedNode.position)
      : deletedEdge
      ? edgeLabelPosition(deletedEdge, nodes)
      : { x: 0, y: 0 };
    const pos = getScreenPosByRef(worldPos.x, worldPos.y, divEl);
    const nodeWidth = deletedNode ? MAX_NODE_RADIUS * 2 : 5;
    // silence compiler (typecheck doesn't work here)
    const boundary = (divEl.current as HTMLSpanElement).getBoundingClientRect();
    dialogInfo = positionDialog(
      Rect.atCenter(pos.x, pos.y, nodeWidth, nodeWidth),
      Rect.create(0, 0, boundary.width, boundary.height),
      br.width,
      br.height
    );
  }

  /*--------------------------------------------------------------------------------*
   *                         Transform Edges and Nodes                              *
   *--------------------------------------------------------------------------------*/

  // Mark the node to be deleted with a red highlight
  const markNodes = (nodes: ReadonlyArray<GraphNode>) =>
    deletedNode && deletedNode.id
      ? [
          ...nodes.filter(notSameNode(deletedNode.id)).map<GraphNode>(omit('deleteHighlight')),
          {
            ...deletedNode,
            deleteHighlight: true,
          },
        ]
      : nodes;

  // Mark all connected edges (or a single edge if an edge has been selected) with the red delete
  // highlight.
  const markEdges = (edges: ReadonlyArray<GraphEdge>) => {
    if (deletedNode && deletedNode.id) {
      return [
        ...edges.filter(doesntConnectNode(deletedNode.id)).map<GraphEdge>(omit('deleteHighlight')),
        ...edges.filter(connectsNode(deletedNode.id)).map<GraphEdge>(highlightEdge),
      ];
    } else if (deletedEdge) {
      return [
        ...edges.filter((e) => e.$id !== deletedEdge.$id).map<GraphEdge>(omit('deleteHighlight')),
        {
          ...deletedEdge,
          deleteHighlight: true,
        },
      ];
    }
    return edges;
  };

  const preprocessedNodes = nodes.map((n) => ({
    ...n,
    highlight: !n.addedByUser ? false : n.highlight,
  }));

  return (
    <div style={{ position: 'relative' as const }}>
      <span
        ref={divEl}
        role="button"
        onMouseMove={onMouseMove}
        onClick={onPickEvent(divEl, edges, nodes, onNodeStart, onEdgeClicked)}
      >
        <GraphRenderer {...props} nodes={markNodes(preprocessedNodes)} edges={markEdges(edges)} />
      </span>
      {touch && (deletedEdge || (deletedNode && dialogInfo)) && (
        <Measure client onResize={setBubbleRect}>
          {({ measureRef }) => {
            // silence compiler
            const di = dialogInfo as DialogPosition;
            return (
              <div
                id="graph-delete-box"
                ref={measureRef}
                style={{
                  position: 'absolute' as const,
                  // Add a magic pixel to center it properly. Unknown why this is necessary.
                  // Seems to be a combination of multiple things.
                  left: Math.round(di.position.x) + 1,
                  top: Math.round(di.position.y) + 1,
                }}
              >
                <PopupBubble
                  nose={{
                    up: !!dialogInfo && dialogInfo.nose === Direction.up,
                    xOffset:
                      dialogInfo && bubbleRect.client
                        ? -(0.5 - dialogInfo.noseX) * bubbleRect.client.width
                        : 0,
                  }}
                >
                  <ExcludedFromTabNavigationButton onClick={onClickDeleteButton} tabIndex={-1}>
                    <TrashXLargeBold />
                  </ExcludedFromTabNavigationButton>
                </PopupBubble>
              </div>
            );
          }}
        </Measure>
      )}
    </div>
  );
};

Delete_.displayName = 'Delete';

export const Delete = withHoverHighlight(Delete_);
