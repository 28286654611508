import * as React from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import { type DropdownState } from '../../../components';

import styles from './DropdownOption.scss';
import Measure, { type ContentRect, type MeasuredComponentProps } from 'react-measure';

export interface DropdownOptionProps {
  state?: DropdownState;
  onItemClicked?: (children: React.ReactNode) => void;
  onResizeListItem?: (contentRect: ContentRect) => void;
  lineBreak?: boolean;
}

const DropdownOption: React.FC<DropdownOptionProps> = ({
  lineBreak,
  onItemClicked = noop,
  onResizeListItem,
  state = 'unselected',
  children,
}) => (
  <Measure client={true} bounds={true} offset={true} onResize={onResizeListItem}>
    {({ measureRef }: MeasuredComponentProps) => (
      <li
        role="menuitem"
        className={classNames(styles.option, styles[state])}
        onClick={() => onItemClicked(children)}
        ref={measureRef}
      >
        <div
          className={classNames(styles.optionContent, styles[state], !lineBreak && styles.nowrap)}
        >
          {children}
        </div>
      </li>
    )}
  </Measure>
);

DropdownOption.displayName = 'DropdownOption';

export default DropdownOption;
