import { type Action, handleActions } from 'redux-actions';
import {
  GEO_PERSIST_LOCAL_STATE,
  type GeoPersistLocalStatePayload,
  type GeoScaleDownPayload,
  SCALE_DOWN,
} from './geoActions';
import { GEO_GIZMO_DEFAULT_CONTENT } from '@bettermarks/importers';
import { METRICS_SYSTEM } from '@bettermarks/gizmo-types';
import { type GeoContent, type GeoEditorMode } from '@bettermarks/gizmo-types';
import { SET_MODE } from '../../gizmo-utils/redux/gizmoActions';

type GeoPayload = GeoPersistLocalStatePayload | GeoScaleDownPayload | GeoEditorMode;

export const geoReducer = handleActions<GeoContent, GeoPayload>(
  {
    [GEO_PERSIST_LOCAL_STATE]: (
      state: GeoContent,
      { payload }: Action<GeoPersistLocalStatePayload>
    ): GeoContent => (payload ? { ...state, ...payload } : state),
    [SCALE_DOWN]: (state: GeoContent, { payload }: Action<GeoScaleDownPayload>) =>
      payload ? { ...state, scale: payload.scale } : state,
    [SET_MODE]: (state: GeoContent, { payload }: Action<GeoEditorMode>) =>
      payload ? { ...state, selectedMode: payload } : state,
  },
  {
    ...GEO_GIZMO_DEFAULT_CONTENT,
    $interactionType: METRICS_SYSTEM,
  }
);
