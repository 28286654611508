import { isEmpty, isNil, isUndefined } from 'lodash';
import log from 'loglevel';
import {
  type Content,
  ContentDict,
  type ContentPath,
  DEFAULT_LAYOUT_METRICS,
  DEFAULT_LAYOUT_METRICS_RELATIVE,
  type FormulaStyles,
  hasInteractionType,
  type LayoutMetrics,
  AppendOnlyMap,
} from '@bettermarks/gizmo-types';
import { type AppendOnlyArray, type ReadonlyDict } from '../append-only';
import {
  type ApplyStylesRegistry,
  DEFAULT_GIZMO_STYLE,
  type EnrichedContent,
  type GizmoStyle,
  type GizmoStyleForRefId,
  type RulerRegistry,
} from '../configuration';
import { createMetricsGetter } from './createMetricsGetter';

/**
 * Traverses a single tree in `contentDict` starting from `rootRefId` breadth first.
 *
 * Calls the appropriate `ApplyStyle` from `applyStylesRegistry` to:
 * - traverse the tree based on the values returned from each `ApplyStyles`
 * - connect the correct `GizmoStyles` to each rootRefId, starting with `outerStyles`.
 *
 * It returns an ordered list of those mappings (`GizmoStyleForRefId`),
 * where the first element is the mapping for `rootRefId`
 * and the last one is (one of) the children on the deepest level in the tree.
 *
 * The resulting task list is later used to measure all gizmos, now that we know what style will
 * be applied to them. The measurement process will proceed from the leaves upwards.
 * @see measureGizmos
 *
 * The innerStyles are used by the formula gizmo, to keep the styles that are applied to every
 * m-tag for the next step in which they will be measured. It is a Map, mapping the object-path
 * [lodash get() style, separated by colons] of every m-tag to its style. This will be used in
 * the measure cycle, to avoid having to style them again.
 *
 * @param {ContentDict} contentDict
 * @param {string} rootRefId - the root node to start applying styles from
 * @param {GizmoStyle} outerStyles - the initial external styles
 * @param {ApplyStylesRegistry} registry
 * @returns {ReadonlyArray<GizmoStyleForRefId>}
 */
export const applyAllStyles = (
  contentDict: ContentDict,
  rootRefId: string,
  outerStyles: GizmoStyle = DEFAULT_GIZMO_STYLE(),
  registry: ApplyStylesRegistry = {}
): ReadonlyArray<GizmoStyleForRefId> => {
  const pendingTasks: GizmoStyleForRefId[] = [
    {
      refId: rootRefId,
      style: outerStyles,
    },
  ];
  const finishedTasks: AppendOnlyArray<GizmoStyleForRefId> = [];

  while (pendingTasks.length > 0) {
    const task = pendingTasks.shift() as GizmoStyleForRefId;
    const content = contentDict[task.refId];
    const innerStyles = new AppendOnlyMap<FormulaStyles>();
    if (content && !content.hidden) {
      const applyStyles = registry[content.$renderStyle];
      if (applyStyles) {
        const stylesToPendingTasks = applyStyles(content, task.style, innerStyles);
        if (!isNil(stylesToPendingTasks)) {
          pendingTasks.push(...stylesToPendingTasks);
        }
      }
    }
    finishedTasks.push(innerStyles.size === 0 ? task : { ...task, innerStyles });
  }

  return finishedTasks;
};

const createStyleResolver = (innerStyles: ReadonlyMap<string, FormulaStyles> | undefined) => {
  if (!innerStyles) {
    return (path: ContentPath) => {
      throw new Error(`innerStyle not set but accessing path "${path.join(':')}"`);
    };
  }
  return (path: ContentPath) => {
    const s = path.join(':');
    if (!innerStyles.has(s)) {
      throw new Error(`path ${JSON.stringify(s)} not found in innerStyles`);
    }
    return innerStyles.get(s) as FormulaStyles;
  };
};

/**
 * Uses the mappings provided by `applyAllStyles` to traverse the tree
 * starting from the deepest children (reverse order),
 * to collect the `LayoutMetrics` returned by the corresponding `Ruler` from `rulerRegistry`.
 *
 * A special case of a `Ruler`, that also modifies the related content is an `Enricher`:
 * it produces `EnrichedContent` (which is also a `LayoutMetrics`).
 *
 * It returns a dictionary of refId to `LayoutMetrics` (or `EnrichedContent`).
 *
 * @see applyAllStyles
 *
 * @param {ContentDict} contentDict
 * @param {ReadonlyArray<GizmoStyleForRefId>} tasks
 * @param {RulerRegistry} rulerRegistry
 * @returns {ReadonlyDict<LayoutMetrics | EnrichedContent<Content>>}
 */
export const measureGizmos = (
  contentDict: ContentDict,
  tasks: ReadonlyArray<GizmoStyleForRefId>,
  rulerRegistry: RulerRegistry = {}
): ReadonlyDict<LayoutMetrics | EnrichedContent<Content>> => {
  const measurements = new AppendOnlyMap<LayoutMetrics | EnrichedContent<Content>>();
  // Passing empty map seems counter-intuitive.
  // However, map is filled along the way recursively and contains only children for the given node.
  const getMetrics = createMetricsGetter(measurements);
  // since `reverse` mutates an array in place (which it then also returns),
  // we spread it into a new one to prevent modification
  [...tasks].reverse().forEach((task) => {
    const content = contentDict[task.refId];
    if (content) {
      const targetDisabled = task.style.disabled;
      let patchedContent = content;
      if (
        !isUndefined(targetDisabled) &&
        hasInteractionType<Content>(content) &&
        targetDisabled !== content.disabled
      ) {
        if (targetDisabled) {
          patchedContent = { ...content, disabled: true };
        } else {
          const { disabled, ...extractedContent } = content;
          patchedContent = extractedContent;
        }
      }

      let measureResult: LayoutMetrics | EnrichedContent<Content>;
      if (content.hidden) {
        measureResult = DEFAULT_LAYOUT_METRICS_RELATIVE;
      } else {
        const ruler = rulerRegistry[content.$renderStyle];
        measureResult = ruler
          ? ruler(
              task.style.formulaStyles,
              patchedContent,
              getMetrics,
              createStyleResolver(task.innerStyles)
            )
          : DEFAULT_LAYOUT_METRICS;
      }

      // if we did not need to modify content regarding disabled
      // or the ruler is an enricher (that incorporates the patched content)
      if (patchedContent === content || 'enrichedContent' in measureResult) {
        // we can use the measure result directly
        measurements.set(task.refId, measureResult);
      } else {
        // if the content was patched and the ruler is not an Enricher
        // we need to return EnrichedContent, so that `disabled` wil be updated
        // (this also covers the case when there is no ruler in the registry)
        measurements.set(task.refId, {
          ...measureResult,
          enrichedContent: patchedContent,
        });
      }
    }
  });

  return measurements.toDict();
};

/**
 * For every mapping from `applyAllStyles` it picks the content
 * either from the `measurements` returned by `measureGizmos` or from `contentDict`.
 *
 * Produces a new `ContentDict` containing only the elements of the tree.
 *
 * @param {ReadonlyArray<GizmoStyleForRefId>} tasks
 * @param {ReadonlyDict<LayoutMetrics | EnrichedContent<Content>>} measurements
 * @param {ContentDict} sourceContentDict
 * @returns {ContentDict}
 */
const collectEnrichedContent = (
  tasks: ReadonlyArray<GizmoStyleForRefId>,
  measurements: ReadonlyDict<LayoutMetrics | EnrichedContent<Content>>,
  sourceContentDict: ContentDict
): ContentDict =>
  tasks
    .reduce((contentMap, { refId }) => {
      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion */
      const metric = measurements[refId] as EnrichedContent<Content>;
      const content = (
        metric && metric.enrichedContent ? metric.enrichedContent : sourceContentDict[refId]
      ) as Content;
      return contentMap.set(refId, content);
    }, new AppendOnlyMap<Content>())
    .toDict();

/**
 * DON'T CALL THIS FUNCTION, THIS IS AN INTERNAL-USE !!!ONLY!!! FUNCTION
 * THE ONLY ONE WHO IS ALLOWED TO CALL THIS FUNCTION IS enrichContentDict and tests
 * @see apps/gizmoviewer/gizmos/gizmosImportExport.spec.ts
 *
 * Traverses the tree below `rootRefId` (using `applyStylesRegistry`)
 * and measures/enriches all content below that (using `rulerRegistry`).
 *
 * Returns a subset of `contentDict` that only contains the content,
 * that is part of the tree **that needs enrichment** below (and including `rootRefId`).
 * To get the merged result, use `enrichContentDict`.
 *
 * @param {ContentDict} contentDict
 * @param {ApplyStylesRegistry} applyStylesRegistry
 * @param {RulerRegistry} rulerRegistry
 * @param {boolean} disabled
 * @param {FormulaStyles} formulaStyles
 *
 * @returns {ContentDict}
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace INTERNAL {
  export const enrichRootInContentDict = (
    contentDict: ContentDict,
    applyStylesRegistry: ApplyStylesRegistry,
    rulerRegistry: RulerRegistry,
    disabled?: boolean,
    formulaStyles?: FormulaStyles
  ): ContentDict => {
    const rootRefId = ContentDict.root(contentDict);
    const tasks = applyAllStyles(
      contentDict,
      rootRefId,
      DEFAULT_GIZMO_STYLE({ formulaStyles, disabled }),
      applyStylesRegistry
    );
    const measurements = measureGizmos(contentDict, tasks, rulerRegistry);
    /*
    log.debug(
      'enrichRootInContentDict',
      JSON.stringify(tasks.map(t => t.refId)),
      JSON.stringify(Object.keys(contentDict))
    );
  */
    return collectEnrichedContent(tasks, measurements, contentDict);
  };
}

/**
 * Merges the result of `enrichRootInContentDict` with `contentDict`.
 *
 * @see enrichRootInContentDict
 *
 * @param {ContentDict} contentDict
 * @param {ApplyStylesRegistry} applyStylesRegistry
 * @param {RulerRegistry} rulerRegistry
 * @param {boolean} disabled
 * @param {FormulaStyles} formulaStyles
 *
 * @returns {ContentDict}
 */
export const enrichContentDict = (
  contentDict: ContentDict,
  applyStylesRegistry: ApplyStylesRegistry,
  rulerRegistry: RulerRegistry,
  disabled?: boolean,
  formulaStyles?: FormulaStyles
): ContentDict => {
  if (isEmpty(contentDict)) {
    log.debug('Trying to enrich empty content-dict');
    return contentDict;
  }

  return {
    ...contentDict,
    ...INTERNAL.enrichRootInContentDict(
      contentDict,
      applyStylesRegistry,
      rulerRegistry,
      disabled,
      formulaStyles
    ),
  };
};
