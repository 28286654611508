import * as React from 'react';
import { CircularButton } from '@seriesplayer/common-ui';
import { RS, switchMap } from '@bettermarks/gizmo-types';
import { Stepper } from '../../../components';

import {
  CIRCLE_LARGE,
  CIRCLE_SMALL,
  FORM_MARGIN,
  FORMULA_WIDTH,
  MAX_NUMBER_OF_SEGMENTS,
  MIN_LARGE_CIRCLE,
  MIN_LARGE_RECTANGLE,
  MIN_NUMBER_OF_SEGMENTS,
  RECT_LARGE,
  RECT_SMALL,
} from '../constants';
import { type FractionFormProps } from '../FractionForm';

import { SegmentedPie, WholePie } from './forms/circle';
import { SegmentedRect, WholeRect } from './forms/rectangle';
import { type SegmentedProps, type WholeProps } from './forms/types';
import { ToolWrapper } from './tools';

import styles from './FractionFormRenderer.scss';
import { FractionFormRemove } from '../../../../components/icons';

const switchForm = switchMap<{
  Whole: React.FC<WholeProps>;
  Segmented: React.FC<SegmentedProps>;
  smallShift: number;
  largeShift: number;
  minLarge: number;
}>(
  {
    [RS.CIRCLE]: {
      Whole: WholePie,
      Segmented: SegmentedPie,
      smallShift: CIRCLE_SMALL.radius,
      largeShift: CIRCLE_LARGE.radius,
      minLarge: MIN_LARGE_CIRCLE,
    },
    [RS.RECTANGLE]: {
      Whole: WholeRect,
      Segmented: SegmentedRect,
      smallShift: RECT_SMALL.height * 0.5,
      largeShift: RECT_LARGE.height * 0.5,
      minLarge: MIN_LARGE_RECTANGLE,
    },
  },
  {
    Whole: WholePie,
    Segmented: SegmentedPie,
    smallShift: CIRCLE_SMALL.radius,
    largeShift: CIRCLE_LARGE.radius,
    minLarge: MIN_LARGE_CIRCLE,
  }
);

type FractionFormToggleProps = {
  hoverIndex?: number;
  onHoverSegment?: (index: number) => void;
  onLeaveSegment?: () => void;
};

export const FractionFormRenderer: React.FC<FractionFormProps & FractionFormToggleProps> = ({
  bitmasks,
  hoverIndex,
  $renderStyle,
  $interactionType,
  availableWidth,
  onSetSegments,
  onRemoveForm,
  onToggleSegment,
  onHoverSegment,
  onLeaveSegment,
}) => {
  const { Whole, Segmented, smallShift, largeShift, minLarge } = switchForm($renderStyle);

  const small =
    !$interactionType ||
    // if not at least 3 large forms + a formula fit the available width -> switch to small forms
    availableWidth < 3 * (minLarge + 2 * FORM_MARGIN) + FORMULA_WIDTH;

  return (
    <div
      className={styles.responsiveness}
      style={{ verticalAlign: -(small ? smallShift : largeShift) }}
    >
      {bitmasks.map((bitmask, i) => {
        return bitmask.length === 1 && i !== bitmasks.length - 1 ? (
          onRemoveForm ? (
            <ToolWrapper key={i}>
              <Whole key={i} filled={bitmask[0]} small={small} />
              <CircularButton onClick={() => onRemoveForm(i)} deleteStyle={true}>
                <FractionFormRemove width={12} height={12} />
              </CircularButton>
            </ToolWrapper>
          ) : (
            <Whole key={i} filled={bitmask[0]} small={small} />
          )
        ) : !onSetSegments ? (
          <Segmented
            key={i}
            small={small}
            bitmask={bitmask}
            hoverIndex={hoverIndex}
            onToggleSegment={onToggleSegment}
            onHoverSegment={onHoverSegment}
            onLeaveSegment={onLeaveSegment}
          />
        ) : (
          <ToolWrapper key={i}>
            <Segmented bitmask={bitmask} small={small} />
            <Stepper
              min={MIN_NUMBER_OF_SEGMENTS}
              max={MAX_NUMBER_OF_SEGMENTS}
              onChange={onSetSegments}
              value={bitmask.length}
            />
          </ToolWrapper>
        );
      })}
    </div>
  );
};
