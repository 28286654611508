import { isNil } from 'lodash';
import {
  annotationInner,
  type ContentReference,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
} from '@bettermarks/gizmo-types';
import { type CaptionContent, type Legend } from '@bettermarks/gizmo-types';
import { exportLabels } from './helper';

const exportColor = (legend: Legend[]): string =>
  legend.map(({ color }: Legend) => `<color>${color}</color>`).join('');

const exportHeader = (context: ExporterContext, header?: ContentReference): string =>
  !isNil(header) ? `<header>${exportContent(header.$refid, context)}</header>` : '';

export const exportCaption: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as CaptionContent;
  return semantics(
    `
      <configuration>
        ${exportColor(content.legend)}
      </configuration>
      <mrow>
        ${exportHeader(context, content.header)}
        ${exportLabels(content.legend, context)}
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
