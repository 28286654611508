import { type FElement, toXmlElement } from '@bettermarks/gizmo-types';
import { INTERVAL_LO, INTERVAL_HI } from '@bettermarks/gizmo-types';

/**
 * Definition of lo and hi limits to be used with the labelstepper in the interval tool.
 * We need to define 'labels', as the label stepper expects labels ...
 * TODO - enrich with some images and translations ...
 * @returns {FElement}
 */
export const intervalLimitsLo = (): FElement =>
  toXmlElement(`
    <g:label of="${INTERVAL_LO}" auto="false">
      <g:value>
        <math key="toolInterval.leftClosed"/>
      </g:value>
      <g:value>
        <math key="toolInterval.leftOpen"/>
      </g:value>
    </g:label>
 `);

export const intervalLimitsHi = (): FElement =>
  toXmlElement(`
    <g:label of="${INTERVAL_HI}" auto="false">
      <g:value>
        <math key="toolInterval.rightClosed"/>
      </g:value>
      <g:value>
        <math key="toolInterval.rightOpen"/>
      </g:value>
    </g:label>
 `);
