import { type Content, type ContentDict } from '@bettermarks/gizmo-types';
import { RS } from '@bettermarks/gizmo-types';
import { type IFreeDragAndDrop } from '@bettermarks/gizmo-types';
import { FREE_DROPPED_ITEM_COPY_SUFFIX } from './constants';
import { isTarget } from './dragging';

/**
 * Finds back the source id of a dropped item
 * @param dragItemRefid the $refid of the dropped item
 * @returns the $refid of the source item
 */
export const getFreeDroppedItemSourceId = (dragItemRefid: string) => {
  const [parentId] = dragItemRefid.split(FREE_DROPPED_ITEM_COPY_SUFFIX);
  return parentId;
};

export const findFreeDragItemParent = (
  content: ContentDict,
  freeDragItemRefId: string
): [string, Readonly<Content> | undefined] | undefined => {
  const pair = Object.entries(content).find(([, contentDict]) => {
    const isFreeDnD = contentDict?.$renderStyle === RS.FREE_DRAG_AND_DROP;
    if (isFreeDnD) {
      const sourceRefid = getFreeDroppedItemSourceId(freeDragItemRefId);
      const containsDragItem = (contentDict as IFreeDragAndDrop)?.items.find(
        (contentRef) => contentRef.$refid === sourceRefid
      );
      return containsDragItem;
    }

    return false;
  });
  return pair;
};

export const isFromTarget = (content: ContentDict, freeDragItemRefId: string): boolean => {
  const sourceRefid = getFreeDroppedItemSourceId(freeDragItemRefId);
  const pair = Object.entries(content).find(([, contentDict]) => {
    if (contentDict && isTarget(contentDict)) {
      const containsDragItem = (contentDict as IFreeDragAndDrop)?.items.find(
        (contentRef) => contentRef['dragSourceId'] === content[sourceRefid]!['$id']
      );
      return containsDragItem;
    }

    return false;
  });

  return pair ? pair.length > 0 : false;
};
