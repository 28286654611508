import { type Content, type ContentReference } from '../../xml-converter/core';

export interface ItemListContent extends Content {
  type: ItemListType;
  items: Item[];
}

export enum ItemListType {
  BULLET = 'bullet',
  FREE = 'free',
  NUMERIC = 'numeric',
}

export type Item = {
  content: ContentReference;
  bullet?: ContentReference;
};
