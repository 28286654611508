import { type MultipleChoiceSingleContent, numberFromStyles, RS } from '@bettermarks/gizmo-types';
import { PLACEHOLDER } from '../../gizmo-utils/constants';

import headerStyles from '../../apps/seriesplayer/containers/Header/header.scss';
import dimensionsStyles from 'styles/dimensions.scss';

const headerHeight = numberFromStyles(headerStyles.HEADER_CONTENT_HEIGHT);
const footerHeight = numberFromStyles(dimensionsStyles.FOOTER_HEIGHT);
const stepTitleHeight = numberFromStyles(dimensionsStyles.STEP_TITLE_HEIGHT);

/**
 * Sum of header's, footer's, and step title's sizes.
 */
export const MARGIN_SIZE_SUM = headerHeight + footerHeight + stepTitleHeight;

export const MULTIPLE_CHOICE_SINGLE_DEFAULT_CONTENT: Readonly<MultipleChoiceSingleContent> = {
  $: PLACEHOLDER,
  $renderStyle: RS.MULTIPLE_CHOICE_SINGLE,
  configuration: {},
  choices: [],
  checkedIdx: -1,
};
