import { type ApplyStylesRegistry } from './types';
import { RS } from '@bettermarks/gizmo-types';

import { applyClockAndTimeGizmoStyles } from '../../gizmos/time/measure';
import { applyDayDurationStyles } from '../../gizmos/day-duration/measure';
import { applyDragSourceStyles } from '../../gizmos/drag-and-drop/dragsource/measure';
import { applyDropTargetStyles } from '../../gizmos/drag-and-drop/droptarget/measure';
import { applySetDropTargetStyles } from '../../gizmos/drag-and-drop/set-droptarget/measure';
import { applyFormulaStyles } from '../../gizmos/formula/Formula/measure';
import { applyGraphStyles } from '../../gizmos/graph/Graph/measure';
import { applyTreeChartStyles } from '../../gizmos/graph/TreeChart/measure';
import { applyMultipleChoiceDropDownStyles } from '../../gizmos/multiple-choice-drop-down/measure';
import { applyMCMultiStyles } from '../../gizmos/multiple-choice-multi/measure';
import {
  applyMCSingleStyles,
  applyMCStackLayoutStyles,
} from '../../gizmos/multiple-choice-single/measure';
import { applySuperGizmoStyles } from '../../gizmos/_super-gizmo/measure';
import { applyUnknownStyles } from '../../gizmos/unknown/measure';
import { applyFieldSetStyles } from '../../gizmos/fieldset/measure';
import { applySolutionSetStyles } from '../../gizmos/solution-set/measure';
import { applyGeoStyles } from '../../gizmos/geo/measure';
import { applyFunctionPlotterStyles } from '../../gizmos/function-plotter/measure';
import {
  applyAbsoluteLayoutStyles,
  applyLayoutContainerStyles,
} from '../../gizmos/layout-container/measure';
import { applyItemListStyles } from '../../gizmos/item-list/measure';
import {
  applyPlaceValueTableStyles,
  applyTableContainerStyles,
  applyTableStyles,
} from '../../gizmos/table/measure';
import {
  applyCaptionStyles,
  applyDiagramBarStyles,
  applyPieOrStackedBarChartStyles,
} from '../../gizmos/chart/measure';
import { applySortingStyles } from '../../gizmos/sorting/measure';
import { applySquaredPaperStyles } from '../../gizmos/squaredpaper/measure';
import { applyTimeDurationStyles } from '../../gizmos/time-duration/measure';
import { applyTimeSpanStyles } from '../../gizmos/time-span/measure';
import { applyDynamicRepresentationStyles } from '../../gizmos/dynamic-representation/measure';
import { applyListSelectionStyles } from '../../gizmos/list-selection/measure';
import { applyDragAndDropStyles } from '../../gizmos/drag-and-drop/freedraganddrop/measure';

// arrange in alphabetical order
export const stylers: ApplyStylesRegistry = {
  [RS.ABSOLUTE_LAYOUT]: applyAbsoluteLayoutStyles,
  [RS.BORDER_LAYOUT]: applyLayoutContainerStyles,
  [RS.CAPTION_BAR_CHART]: applyCaptionStyles,
  [RS.CAPTION_CURVE_CHART]: applyCaptionStyles,
  [RS.CAPTION_PIE_CHART]: applyCaptionStyles,
  [RS.CLOCKTIME]: applyClockAndTimeGizmoStyles,
  [RS.DAY_DURATION]: applyDayDurationStyles,
  [RS.DIGIT]: applyFormulaStyles,
  [RS.DIAGRAM_BAR_CHART]: applyDiagramBarStyles,
  [RS.DRAG_SOURCE]: applyDragSourceStyles,
  [RS.DRAW2DGEO]: applyGeoStyles,
  [RS.DROP_TARGET]: applyDropTargetStyles,
  [RS.DYNAMIC_REPRESENTATION]: applyDynamicRepresentationStyles,
  [RS.FIELD_SET]: applyFieldSetStyles,
  [RS.FREE_DRAG_AND_DROP]: applyDragAndDropStyles,
  [RS.FORMULA]: applyFormulaStyles,
  [RS.GRAPH]: applyGraphStyles,
  [RS.INNER_TEXT]: applyFormulaStyles,
  [RS.ITEM_LIST]: applyItemListStyles,
  [RS.TABLECELL_TEXT]: applyFormulaStyles,
  [RS.TEXT]: applyFormulaStyles,
  [RS.METRICS_SYSTEM]: applyGeoStyles,
  [RS.FUNCTION_PLOTTER]: applyFunctionPlotterStyles,
  [RS.LAYOUT_CONTAINER]: applyLayoutContainerStyles,
  [RS.LIST_SELECTION]: applyListSelectionStyles,
  [RS.MULTIPLE_CHOICE_DROP_DOWN]: applyMultipleChoiceDropDownStyles,
  [RS.MULTIPLE_CHOICE_MULTI]: applyMCMultiStyles,
  [RS.MULTIPLE_CHOICE_SINGLE]: applyMCSingleStyles,
  [RS.PIE_CHART]: applyPieOrStackedBarChartStyles,
  [RS.PLACE_VALUE_TABLE]: applyPlaceValueTableStyles,
  [RS.SET_DROP_TARGET]: applySetDropTargetStyles,
  [RS.SIMPLE_TABLE]: applyTableStyles,
  [RS.SIMPLE_TABLE_CONTAINER]: applyTableContainerStyles,
  [RS.SOLUTION_SET]: applySolutionSetStyles,
  [RS.SORTING]: applySortingStyles,
  [RS.STACK_LAYOUT]: applyMCStackLayoutStyles,
  [RS.STACKED_BAR_CHART]: applyPieOrStackedBarChartStyles,
  [RS.SUPER_GIZMO]: applySuperGizmoStyles,
  [RS.SQUARED_PAPER]: applySquaredPaperStyles,
  [RS.TIME]: applyClockAndTimeGizmoStyles,
  [RS.TIME_DURATION]: applyTimeDurationStyles,
  [RS.TIMESPAN]: applyTimeSpanStyles,
  [RS.TREE_DIAGRAM]: applyTreeChartStyles,
  [RS.UNKNOWN]: applyUnknownStyles,
};
