import * as React from 'react';
import Measure, { type ContentRect } from 'react-measure';
import { useTranslation } from 'react-i18next';
import { Button, ButtonBackground, ButtonKind, ButtonSize } from '@seriesplayer/common-ui';

import { Icon } from '../../../../components/Icon';

import { type HeaderCommonProps, type HeaderProps } from './Header';
import styles from './header.scss';
export type HeaderLargeProps = Pick<
  HeaderProps,
  | 'closeButton'
  | 'currentExercise'
  | 'numExercises'
  | 'seriesTitle'
  | 'reportProblemButton'
  | 'showReportProblem'
> &
  HeaderCommonProps & {
    onResize: (contentRect: ContentRect) => void;
    showReportProblemText: boolean;
  };

export const HeaderLarge: React.FC<HeaderLargeProps> = ({
  children,
  closeButton,
  currentExercise,
  numExercises,
  onClose,
  onResize,
  reportProblemButton,
  seriesTitle,
  showReportProblem,
  showReportProblemText,
}) => {
  const [t] = useTranslation();

  return (
    <Measure onResize={onResize} bounds>
      {({ measureRef }) => (
        <div id="header" className={styles.exerciseHeader} ref={measureRef}>
          <div className={styles.text}>
            <span className={styles.exerciseInformation}>
              {t('seriesplayer:header.exercise', {
                currentExercise,
                total: numExercises,
              })}
            </span>
            <span className={styles.exerciseSeriesTitle}>{seriesTitle}</span>
          </div>
          <div className={styles.spacer} />
          {reportProblemButton && (
            <Button
              dataCy="report-problem-btn"
              kind={ButtonKind.view}
              size={ButtonSize.l}
              background={ButtonBackground.transparent}
              onClick={showReportProblem}
            >
              {(Wrapper: React.ComponentType) => (
                <>
                  <Wrapper>
                    <Icon iconKey="ReportProblemXLarge" style={{ paddingTop: 6 }} />
                  </Wrapper>
                  {showReportProblemText && <Wrapper>{t('seriesplayer:header.report')}</Wrapper>}
                </>
              )}
            </Button>
          )}
          {children}
          {closeButton && (
            <Button
              id="close-btn"
              dataCy="close-btn"
              kind={ButtonKind.view}
              size={ButtonSize.l}
              background={ButtonBackground.transparent}
              onClick={onClose}
            >
              <Icon iconKey="TimesLarge" />
            </Button>
          )}
        </div>
      )}
    </Measure>
  );
};
