import { SPECIAL } from '../../gizmo-utils/constants';
import { ANIMATION } from '../../gizmo-utils/configuration/renderStyles';
import { type Content, type Defaults } from '../../xml-converter/core';

/**
 * source: animation filename.
 * width x height are maximal dimension to display the image
 */
export interface AnimationContent extends Content {
  source: string;
  width: number;
  height: number;
}

export const ANIMATION_DEFAULT_CONTENT: Defaults<AnimationContent> = {
  $: SPECIAL,
  $renderStyle: ANIMATION,
  source: '',
  width: 0,
  height: 0,
};
