import { useTranslation } from 'react-i18next';

export const usePlaceholderLabels = (forFirstRow: boolean) => {
  const [, i18n] = useTranslation();
  let language = i18n.language || 'en';
  if (language.includes('_')) {
    language = language.split('_')[0];
  }

  let row;
  if (language === 'de') {
    row = forFirstRow
      ? 'Gib hier den ersten Schritt ein...'
      : 'Gib hier den nächsten Schritt ein...';
  } else {
    row = forFirstRow ? 'Enter first step...' : 'Enter next step...';
  }

  const transformation = language === 'de' ? 'Umformung...' : 'Transformation...';

  return { row, transformation };
};
