import { type Action, createAction, handleActions } from 'redux-actions';
import { first, isEmpty, last } from 'lodash';

import {
  type Coords,
  type GeoConfiguration,
  type GeoContentPersistProps,
  GeoEditorMode,
  type IdCoords,
  PointHighlight,
  type PreviewPoint,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import {
  DEFAULT_PREVLINE,
  GEO_DEFAULT_PERSIST_PROPS,
  getPointCoordsId,
  midpoint,
  screenToWorld,
} from '@bettermarks/importers';
import { type GeoMiddlepointState } from './GeoMiddlepoint';
import { addPreviewLine, addPreviewPoint } from '../helpers';
import { persistPoint } from '../../tools/persist';
import { getLineTypeProps } from '../../tools/constants';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */

export interface UpPayload {
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface DownPayload {
  matrix: number[];
  snapPoint?: SnapPoint | null;
}

export interface SnapPayload {
  configuration: GeoConfiguration;
  matrix: number[];
  snapPoint: SnapPoint;
  mouseP: Coords;
}

export type GeoMiddlepointPayload = UpPayload | SnapPayload | DownPayload | void;

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPayload>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);
const DOWN: 'DOWN' = 'DOWN';
export const downAction = createAction<DownPayload>(DOWN);
const UP: 'UP' = 'UP';
export const upAction = createAction<UpPayload>(UP);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState = {
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  snapPoints: [],
  prevPoints: [],
  prevLine: DEFAULT_PREVLINE,
  highlight: PointHighlight.dragOut,
};

export const geoMiddlepointReducer = handleActions<GeoMiddlepointState, GeoMiddlepointPayload>(
  {
    [SNAP]: (state: GeoMiddlepointState, { payload }: Action<SnapPayload>) => {
      if (!payload) {
        return state;
      }

      const { configuration, matrix, mouseP, snapPoint } = payload;

      let newState = {
        ...state,
        snapPoints: snapPoint ? [snapPoint] : [],
      };

      // draw preview line if snapPoint or mouseP second construction point
      if (!isEmpty(newState.prevPoints) && snapPoint) {
        newState = addPreviewLine<GeoMiddlepointState>(
          newState,
          mouseP,
          matrix,
          getLineTypeProps(GeoEditorMode.MIDDLEPOINT),
          configuration.defaultDecorations
        );

        const midPointCoords = midpoint(
          (first(newState.prevPoints) as PreviewPoint).coords,
          screenToWorld(matrix)(snapPoint)
        );

        return {
          ...newState,
          prevPoints: [
            ...newState.prevPoints,
            { id: getPointCoordsId(midPointCoords), coords: midPointCoords },
          ],
        };
      }

      return {
        ...state,
        snapPoints: [snapPoint],
      };
    },
    [STOP_SNAP]: (state: GeoMiddlepointState) => ({
      ...state,
      snapPoints: [],
      prevLine: DEFAULT_PREVLINE,
      prevPoints: [],
      highlight: PointHighlight.dragOut,
    }),
    [DOWN]: (state: GeoMiddlepointState, { payload }: Action<DownPayload>) => {
      if (!payload) {
        return state;
      }

      // for TOUCH we need to pass the snapPoint with the downAction as it is no two step process
      const { snapPoint, matrix } = payload;

      // snap point from payload (touch) or previous snap action (mouse)
      const previewPointScreen = snapPoint || first(state.snapPoints);

      return previewPointScreen
        ? {
            ...addPreviewPoint<GeoMiddlepointState>(state, previewPointScreen, matrix),
            highlight: undefined,
          }
        : state;
    },
    [UP]: (state: GeoMiddlepointState, { payload }: Action<UpPayload>) => {
      if (!payload) {
        return state;
      }

      const { configuration, onPersistLocalState } = payload;
      const newPoint: IdCoords = last(state.prevPoints) as IdCoords;

      let newState = state;
      if (newPoint && !(newPoint.id in newState.persistProps.geoContentMap)) {
        newState = persistPoint<GeoMiddlepointState>(
          newPoint,
          newState,
          configuration.defaultDecorations.points
        );

        onPersistLocalState(newState.persistProps);
      }

      return {
        ...initialState,
        persistProps: newState.persistProps,
      };
    },
  },
  initialState
);
