import * as React from 'react';
import { type BoxContent } from '@bettermarks/gizmo-types';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import { getNamedColor } from '@bettermarks/importers';

export type BoxProps = BoxContent & ContextState;
/**
 * Render the box according to the height,width and background-color
 * @param props
 */
export const Box: React.FC<BoxProps> = ({ decoration }) => (
  <div
    style={{
      ...decoration,
      backgroundColor: getNamedColor(decoration.backgroundColor),
    }}
  />
);

Box.displayName = 'Box';
