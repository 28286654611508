import {
  type ApplyStylesRegistry,
  type GizmoRegistry,
  type RulerRegistry,
} from '../../gizmo-utils/configuration';

import { stylers as _stylers } from '../../gizmo-utils/configuration/stylers';
import { rulers as _rulers } from '../../gizmo-utils/configuration/rulers';
import { gizmoRegistry as _gizmos } from '../../gizmo-utils/configuration/gizmos';

import { applyFEMStyles, applyKEMStyles } from './EM/measure';
import { FEM, KEM } from './EM';

export const stylers: ApplyStylesRegistry = {
  ..._stylers,
  fem: applyFEMStyles,
  kem: applyKEMStyles,
};

export const gizmos: GizmoRegistry = {
  ..._gizmos,
  fem: FEM,
  kem: KEM,
};

export const rulers: RulerRegistry = {
  ..._rulers,
};
