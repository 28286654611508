import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type LineHeaderContent,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportLineHeader: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as LineHeaderContent;
  return semantics(
    `
      <mrow decoration=
      "line-style:${content.decoration.lineStyle};line-weight:${content.decoration.lineWeight};">
        <mtext>${content.text}</mtext>
      </mrow>
      `,
    annotationInner(content.$, content)
  );
};
