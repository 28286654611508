import {
  type BoxContent,
  type BoxDecoration,
  type Defaults,
  RS,
  SPECIAL,
} from '@bettermarks/gizmo-types';

export const DEFAULT_DECORATION: Readonly<BoxDecoration> = {
  width: 100,
  height: 100,
  backgroundColor: 'bm-red',
};

export const EMPTY_DEFAULT_CONTENT: Defaults<BoxContent> = {
  $: SPECIAL,
  $renderStyle: RS.EMPTY,
  decoration: DEFAULT_DECORATION,
};
