import { type Action, handleAction } from 'redux-actions';
import { reduceReducers } from '../../../../gizmo-utils/reduceReducers';
import { type ApplicationState } from '../../../../types';
import { initialSeriesplayerState } from '../../../store/constants';
import { SET_VALIDATION, SET_ELEMENT_TO_FOCUS } from './actions';

const handleSetElementToFocus = handleAction(
  SET_ELEMENT_TO_FOCUS,
  (state: ApplicationState, { payload: focusElement }: Action<string>) => ({
    ...state,
    focusElement,
  }),
  initialSeriesplayerState
);

const handleValidation = handleAction(
  SET_VALIDATION,
  (state: ApplicationState, { payload: validation }: Action<string>) => ({
    ...state,
    validation,
  }),
  initialSeriesplayerState
);

export const uploadReducer = reduceReducers(handleSetElementToFocus, handleValidation);
