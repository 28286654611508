import * as React from 'react';
import Measure from 'react-measure';
import classNames from 'classnames';
import { isNil, last } from 'lodash';
import {
  FlexAlign,
  HLayoutChildWrapper,
  StyledHLayout,
  StyledVLayout,
  VLayoutChildWrapper,
} from '@seriesplayer/common-ui';
import { default as Handle } from '../../components/Slider/Handle';
import { PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import {
  isContentReference,
  type MouseOrTouch,
  type Slice,
  type SliceWithLegendLabel,
} from '@bettermarks/gizmo-types';
import { Formula } from '../formula/Formula';
import { LegendComponent } from './components/legend/LegendComponent';
import { Bar } from './components/stackedBar/Bar';
import { BarHighlight } from './components/stackedBar/BarHighlight';
import { type StackedBarChartProps } from './StackedBarChartEditor';
import {
  DEFAULT_BORDER_STROKE,
  DEFAULT_LEGEND_GAP,
  DEFAULT_STACKED_BAR_HEIGHT,
  DEFAULT_STACKED_BAR_LABEL_SPACE,
  DEFAULT_STACKED_BAR_MAX_WIDTH,
  DEFAULT_STACKED_BAR_STROKE_WIDTH,
} from './defaults';
import { getLegendProps } from './PieChartRenderer';
import {
  addTouchStartListener,
  onMove,
  onTouchEnd,
  onTouchStart,
  removeTouchStartListener,
} from './preventScrollingTouch';

import styles from './StackedBarChartRenderer.scss';
import chartsStyles from './charts.scss';

/**
 * Calculate the starting point of the slice
 * @param {number} sum of the value of the slices that come before the current slice
 * @param {number} width available for the stacked bar chart
 * @return {number}
 */
const getSliceStart = (cumulativeValue: number, width: number): number =>
  (cumulativeValue * width) / 100;

/**
 * Calculate the width of the slice as per its value
 * @param {number} value of the slice
 * @param {number} width available for the stacked bar chart
 * @return {number}
 */
const getSliceWidth = (value: number, width: number): number => (value * width) / 100;

export type StackedBarChartState = {
  hoveredItem: number;
  selectedItem: number;
  flipHandle: number[];
  labelWidths: number[];
  maxLabelHeight: number;
  initialMeasurement: boolean;
};

export class StackedBarChartRenderer extends React.Component<
  StackedBarChartProps,
  StackedBarChartState
> {
  state = {
    hoveredItem: NaN,
    selectedItem: NaN,
    flipHandle: this.props.slices.reduce((acc, _, i) => [...acc, i % 2 ? 0 : 180], []),
    labelWidths: [...Array(this.props.slices.length)].map((_) => 0),
    maxLabelHeight: 0,
    initialMeasurement: this.props.slicesWithLabels > 0,
  };

  componentDidMount() {
    if (this.stackedBarChartRef.current) {
      addTouchStartListener(this.stackedBarChartRef);
    }
  }

  componentWillUnmount() {
    if (this.stackedBarChartRef.current) {
      removeTouchStartListener(this.stackedBarChartRef);
    }
  }

  stackedBarChartRef = React.createRef<HTMLElement>();
  rootElemRef = React.createRef<HTMLDivElement>();

  onMoveStart = (item: number) => (evt: MouseOrTouch) => {
    if (this.props.isTouch) {
      onTouchStart(evt);
    }

    this.setState({ selectedItem: item });
    this.props.onMoveStart && this.props.onMoveStart(item);
  };

  onMoveEnd = () => {
    this.setState({ selectedItem: NaN });
    this.props.onMoveEnd && this.props.onMoveEnd();

    if (this.props.isTouch) {
      onTouchEnd();
    }
  };

  onMove = (evt: any) => {
    if (this.props.isTouch) {
      onMove(evt);
    }

    this.props.onMove && this.props.onMove(evt);
  };

  onHoverOver = (item: number) => () => this.setState({ hoveredItem: item });

  onHoverLeave = () => this.setState({ hoveredItem: NaN });

  renderStackedBar = (slices: Slice[], width: number, highlighted = false) => {
    let cumulativeValue = 0;
    let previousBarWithErrorHighlight = false;
    let nextBarWithErrorHighlight = false;
    return slices.map(({ color, severity, value }: Slice, i: number) => {
      const sliceStart = getSliceStart(cumulativeValue, width);
      const sliceWidth = getSliceWidth(value, width);

      if (highlighted) {
        // we need to know the severity of the neighbours
        // (to decide whether they can share the red line)
        previousBarWithErrorHighlight = cumulativeValue !== 0 && !isNil(severity);
        nextBarWithErrorHighlight = i < slices.length - 1 && !isNil(slices[i + 1].severity);
        cumulativeValue += value;

        return severity ? (
          <BarHighlight
            key={i}
            barStart={sliceStart}
            barWidth={sliceWidth}
            {...{
              severity,
              previousBarWithErrorHighlight,
              nextBarWithErrorHighlight,
            }}
          />
        ) : null;
      }
      cumulativeValue += value;
      return <Bar key={i} color={color} barStart={sliceStart} barWidth={sliceWidth} />;
    });
  };

  onResize =
    (idx: number) =>
    ({ offset }: any) =>
      this.setState((prevState) => ({
        labelWidths: [
          ...prevState.labelWidths.slice(0, idx),
          offset.width,
          ...prevState.labelWidths.slice(idx + 1),
        ],
        maxLabelHeight: Math.max(prevState.maxLabelHeight, offset.height),
        initialMeasurement:
          prevState.labelWidths.reduce((acc, width) => (width > 0 ? acc + 1 : acc), 0) <
          this.props.slicesWithLabels - 1,
      }));

  isActiveItem = (idx: number, disabled = false) =>
    !disabled && (isNaN(this.state.selectedItem) || idx === this.state.selectedItem);

  render() {
    const {
      $interactionType,
      availableWidth,
      disabled,
      hasLegend,
      isTouch,
      needsBorder,
      onMove,
      slices,
      title,
    } = this.props;

    // check if the label of the last slice is bigger then the slice itself =>
    // if so, reduce the width of the stacked bar chart by half the overlap
    // (as the label is always centered only the right overlap needs to be taken into account)
    const lastSliceWidth = getSliceWidth((last(this.props.slices) as Slice).value, availableWidth);
    const additionalLabelSpace = Math.max(
      0,
      ((last(this.state.labelWidths) || 0) - lastSliceWidth) / 2
    );

    const width = Math.min(availableWidth - additionalLabelSpace, DEFAULT_STACKED_BAR_MAX_WIDTH);

    const legendWidth =
      availableWidth > width + (hasLegend ? DEFAULT_LEGEND_GAP : 0)
        ? availableWidth - width
        : availableWidth;
    const interactive = !isNil($interactionType);
    let accWidth = 0;

    return (
      <div
        ref={this.rootElemRef}
        className={this.state.initialMeasurement ? chartsStyles.invisible : ''}
      >
        <StyledVLayout align={FlexAlign.center}>
          {title && (
            <VLayoutChildWrapper noMargin>
              <div className={chartsStyles.title} style={{ maxWidth: width }}>
                <PolymorphicGizmo refid={title.$refid} availableWidth={width} />
              </div>
            </VLayoutChildWrapper>
          )}
          <VLayoutChildWrapper noMargin={!title}>
            <StyledHLayout gap={hasLegend ? DEFAULT_LEGEND_GAP : 0}>
              <HLayoutChildWrapper>
                <div
                  onMouseMove={onMove && onMove(this.rootElemRef.current)}
                  onMouseUp={this.onMoveEnd}
                  onMouseLeave={this.onMoveEnd}
                  onTouchMove={onMove && onMove(this.rootElemRef.current)}
                  onTouchEnd={this.onMoveEnd}
                  role="presentation"
                  className={classNames(styles.relative, {
                    [styles.stackedBarChart]: interactive,
                    [styles.grabbing]: !isNaN(this.state.selectedItem),
                  })}
                  style={{
                    minHeight: this.state.maxLabelHeight + DEFAULT_STACKED_BAR_LABEL_SPACE,
                  }}
                >
                  <svg
                    width={width}
                    height={DEFAULT_STACKED_BAR_HEIGHT}
                    viewBox={`0 0 ${width} ${DEFAULT_STACKED_BAR_HEIGHT}`}
                  >
                    {this.renderStackedBar(slices, width)}
                    {needsBorder && (
                      <rect
                        x={DEFAULT_STACKED_BAR_STROKE_WIDTH - 1}
                        y={DEFAULT_STACKED_BAR_STROKE_WIDTH + 2}
                        width={width - DEFAULT_STACKED_BAR_STROKE_WIDTH - 1}
                        height={
                          DEFAULT_STACKED_BAR_HEIGHT - 2 * DEFAULT_STACKED_BAR_STROKE_WIDTH - 1
                        }
                        fill={'none'}
                        strokeWidth={DEFAULT_STACKED_BAR_STROKE_WIDTH}
                        stroke={DEFAULT_BORDER_STROKE}
                      />
                    )}
                    {this.renderStackedBar(slices, width, true)}
                  </svg>
                  {slices.map(({ sliceLabel, value }: Slice, i: number) => {
                    const sliceWidth = getSliceWidth(value, width);
                    const handlePos = accWidth;
                    accWidth += sliceWidth;
                    return (
                      <div key={i}>
                        <span
                          className={classNames(styles.absolute, styles.sliceLabel, {
                            [styles.handle]: interactive,
                          })}
                          style={{
                            left: accWidth - (sliceWidth + this.state.labelWidths[i]) / 2,
                          }}
                        >
                          {sliceLabel && (
                            <Measure offset onResize={this.onResize(i)}>
                              {({ measureRef }) => (
                                <div ref={measureRef}>
                                  {isContentReference(sliceLabel) ? (
                                    <PolymorphicGizmo
                                      refid={sliceLabel.$refid}
                                      availableWidth={availableWidth}
                                    />
                                  ) : (
                                    <Formula {...{ ...sliceLabel, availableWidth }} />
                                  )}
                                </div>
                              )}
                            </Measure>
                          )}
                        </span>
                        {interactive && (
                          <div
                            style={{ left: handlePos }}
                            className={classNames(
                              styles.absolute,
                              this.state.flipHandle[i - 1] ? styles.handleFlip : styles.handle
                            )}
                          >
                            {i !== 0 && (
                              <div
                                role="presentation"
                                onMouseDown={disabled ? undefined : this.onMoveStart(i)}
                                onTouchStart={disabled ? undefined : this.onMoveStart(i)}
                                onMouseOver={
                                  this.isActiveItem(i, disabled) ? this.onHoverOver(i) : undefined
                                }
                                onMouseLeave={this.onHoverLeave}
                              >
                                <Handle
                                  flip={this.state.flipHandle[i - 1]}
                                  disabled={disabled}
                                  handleShape={'raindrop'}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </HLayoutChildWrapper>
              {hasLegend && (
                <HLayoutChildWrapper>
                  <div className={styles.legend}>
                    <LegendComponent
                      {...getLegendProps(legendWidth, slices as SliceWithLegendLabel[], isTouch)}
                    />
                  </div>
                </HLayoutChildWrapper>
              )}
            </StyledHLayout>
          </VLayoutChildWrapper>
        </StyledVLayout>
      </div>
    );
  }
}
