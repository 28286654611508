import { type FontWeight } from '@bettermarks/bm-font';
import {
  DEFAULT_INV_POINT_COLOR,
  DEFAULT_POINT_COLOR,
  getNamedColor,
} from '@bettermarks/importers';
import {
  type AdvancedAxisObject,
  type ContentReference,
  type Coords,
  type FormulaStyles,
  type GeoContent,
  type GeoDecoration,
  type GeoObject,
  type GeoObjectMap,
  isAdvancedAxis,
  isContentReference,
  type LabelObject,
  type LabelValuesMap,
  numberFromStyles,
  SeverityColor,
  type StyleProps,
} from '@bettermarks/gizmo-types';
import { compact, keys, uniqBy } from 'lodash';
import {
  type ApplyStyles,
  type GizmoStyle,
  type GizmoStyleForRefId,
  type Ruler,
} from '../../gizmo-utils/configuration';
import {
  DEFAULT_GEO_LABEL_FONT_SIZE,
  fontSizeFromScale,
  MIN_SCALABLE_FONT_SIZE,
} from '../../gizmo-utils/measure';

import constantsFromStyles from './constantsFromStyles.scss';
import axisStyles from './components/AxisRenderer.scss';

const PICKER_LABEL_FONT_SIZE = numberFromStyles(constantsFromStyles.PICKER_LABEL_FONT_SIZE);

const MIN_LABEL_FONT_SIZE = 8;

const glow = (r: number, color: string): string =>
  [-1, -1, 0, 1, 1]
    .map((dx) => [-1, -1, 0, 1, 1].map((dy) => `${dx}px ${dy}px ${r}px ${color}`))
    .join(',');

export const getLabelContentStyle = (
  points: ReadonlyArray<string>,
  geoContentMap: GeoObjectMap<GeoObject>,
  formulaStyles: FormulaStyles,
  label: LabelObject,
  scale: number
): FormulaStyles => {
  const refid: string | undefined = label.refid;

  const inheritedDecoration: StyleProps =
    (refid && refid in geoContentMap && geoContentMap[refid].decoration) || {};
  const labelDecoration: Partial<FormulaStyles> = label.decoration || {};
  const severityDecoration = label.severity ? { color: getNamedColor(label.severity) } : {};

  // glow-filter special treatment: If it is set as key, then it is disabled!!!
  const glowDecoration = labelDecoration.hasOwnProperty('glowFilter')
    ? {}
    : { textShadow: glow(1, 'white') };

  return {
    ...formulaStyles,
    fontWeight: 'bold',
    color: refid && points.indexOf(refid) > -1 ? DEFAULT_POINT_COLOR : DEFAULT_INV_POINT_COLOR,
    ...inheritedDecoration,
    ...labelDecoration,
    ...severityDecoration,
    ...glowDecoration,
    fontSize: fontSizeFromScale(DEFAULT_GEO_LABEL_FONT_SIZE, scale, MIN_LABEL_FONT_SIZE),
  };
};

const getLabelStyles = (
  labels: ReadonlyArray<string>,
  points: ReadonlyArray<string>,
  geoContentMap: GeoObjectMap<GeoObject>,
  formulaStyles: FormulaStyles,
  scale: number
): GizmoStyleForRefId[] =>
  compact(
    labels
      .filter((labelVertexId) => {
        const labelVertex = geoContentMap[labelVertexId] as LabelObject;
        return isContentReference(labelVertex.content);
      })
      .map((labelVertexId) => {
        const labelVertex = geoContentMap[labelVertexId] as LabelObject;
        return {
          refId: (labelVertex.content as ContentReference).$refid,
          style: {
            formulaStyles: getLabelContentStyle(
              points,
              geoContentMap,
              formulaStyles,
              labelVertex,
              scale
            ),
            scaleProps: { minimumFontSize: MIN_LABEL_FONT_SIZE, scale },
          },
        };
      })
  );

const getAxesLabelStyles = (a: AdvancedAxisObject, scale: number): GizmoStyleForRefId[] => {
  const axisFontSize = parseInt(axisStyles.AXIS_LABEL_FONT_SIZE, 10);
  const style = {
    formulaStyles: {
      fontSize: fontSizeFromScale(scale, axisFontSize),
    },
    scaleProps: { minimumFontSize: MIN_SCALABLE_FONT_SIZE, scale },
  };

  const labels = isContentReference(a.label) ? [{ refId: a.label.$refid, style }] : [];
  const ticks = a.ticks
    .filter((t) => !!t.label)
    .map((t: { pos: Coords; label: ContentReference; decoration?: GeoDecoration }) => {
      return {
        refId: t.label.$refid,
        style: {
          ...style,
          formulaStyles: {
            ...style.formulaStyles,
            ...t.decoration,
          },
        },
      };
    });

  return [...ticks, ...labels];
};

const getPickerWidgetLabelStyles = (
  labels: ReadonlyArray<ContentReference>
): GizmoStyleForRefId[] => {
  const style = {
    formulaStyles: { fontSize: PICKER_LABEL_FONT_SIZE },
    scaleProps: { minimumFontSize: MIN_LABEL_FONT_SIZE },
  };

  return labels.map((label) => ({ refId: label.$refid, style }));
};

const getErrorLabelStyles = (
  labels: ReadonlyArray<ContentReference>,
  formulaStyles: Readonly<FormulaStyles>
): GizmoStyleForRefId[] => {
  const style = {
    formulaStyles: {
      ...formulaStyles,
      fontWeight: 'bold' as FontWeight,
      color: SeverityColor.ERROR,
      textShadow: glow(1, 'white'),
    },
    scaleProps: { minimumFontSize: MIN_LABEL_FONT_SIZE },
  };

  return labels.map((label) => ({ refId: label.$refid, style }));
};

export const applyGeoStyles: ApplyStyles = (
  {
    diagonalAxis,
    horizontalAxis,
    verticalAxis,
    labels,
    points,
    geoContentMap,
    configuration,
    scale,
  }: GeoContent,
  { formulaStyles }: GizmoStyle
) => {
  const result: GizmoStyleForRefId[] = [];
  const axes = [diagonalAxis, horizontalAxis, verticalAxis];

  axes.forEach((a) => {
    if (isAdvancedAxis(a)) {
      result.push(...getAxesLabelStyles(a, scale));
    }
  });

  const labelValuesMap: LabelValuesMap = configuration.labelValues;
  keys(labelValuesMap).forEach((k) => {
    result.push(...getPickerWidgetLabelStyles(labelValuesMap[k].pickerLabels));
    result.push(...getErrorLabelStyles(labelValuesMap[k].errorLabels, formulaStyles));
  });

  result.push(...getLabelStyles(labels, points, geoContentMap, formulaStyles, scale));

  return uniqBy(result, 'refId');
};

export const measureGeo: Ruler<GeoContent> = (outerStyles, content) => ({
  refLine: content.totalHeight * 0.5,
  height: content.totalHeight,
});
