import { createAction } from 'redux-actions';
import { type GizmoViewerLoadData } from '../../types';
import { type GizmoRoute } from '@bettermarks/importers';

export const LOAD_GIZMO = 'LOAD_GIZMO';
export const loadGizmo = createAction<GizmoRoute>(LOAD_GIZMO);

export const LOAD_GIZMO_PROGRESS = 'LOAD_GIZMO_PROGRESS';
export const loadGizmoProgress = createAction(LOAD_GIZMO_PROGRESS);

export const LOAD_GIZMO_SUCCESS = 'LOAD_GIZMO_SUCCESS';
export const loadGizmoSuccess = createAction<GizmoViewerLoadData>(LOAD_GIZMO_SUCCESS);

export const LOAD_GIZMO_FAILURE = 'LOAD_GIZMO_FAILURE';
export const loadGizmoFailure = createAction(LOAD_GIZMO_FAILURE);
