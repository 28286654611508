export enum DateCellMark {
  Empty = '',
  Marked = 'mark',
  MarkStart = 'mark-start',
  MarkEnd = 'mark-end',
}

export type DateCell = {
  day: string;
  mark: DateCellMark;
};

export type DayGrid = DateCell[][];

export type DaySpan = {
  from: string;
  to: string;
};

export type DateSpan = {
  from: Date;
  to: Date;
};

export type FirstDayOfWeek = 'Mo' | 'So';
