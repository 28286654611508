import * as React from 'react';
import { compact } from 'lodash';
import { KeyboardLayout, type LayoutProps } from './KeyboardLayout';
import { CharBlock } from './CharBlock';
import { NumBlock } from './NumBlock';
import { OperatorBlock } from './OperatorBlock';
import { KeyPage } from './KeyPage';
import { KeyNumbers, KeyLetters } from '../../icons/Keyboard';
import { padOperators, sortOperators } from './helpers';

export const LargeLayout: React.FC<LayoutProps> = ({
  onKey,
  onTogglePage,
  hasNumbers,
  hasDecimalPoint,
  hasLetters,
  operators,
  selectedPage,
}) => (
  <KeyboardLayout
    size="large"
    toggles={compact([
      (hasNumbers || operators.length > 0) && KeyNumbers,
      hasLetters && KeyLetters,
    ])}
    selectedPage={selectedPage}
    onTogglePage={onTogglePage}
  >
    <KeyPage onKey={onKey} slim>
      {hasNumbers && (
        <NumBlock
          onKey={onKey}
          slim
          hasDecimalPoint={hasDecimalPoint}
          onSinglePage={operators.length === 0}
        />
      )}
      <OperatorBlock onKey={onKey} operators={padOperators(sortOperators(operators, true), 2)} />
    </KeyPage>
    {hasLetters && (
      <KeyPage onKey={onKey} slim>
        <CharBlock onKey={onKey} size="large" />
      </KeyPage>
    )}
  </KeyboardLayout>
);

LargeLayout.displayName = 'LargeLayout';
