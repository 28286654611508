import { parse, type ParsedQuery } from 'query-string';
import { type GetParams } from '../../../../types';
import { isString, isBoolean } from 'lodash';

type ParsedSeriesPlayerQuery = Partial<{
  appLocale: string;

  /** ISO date sting  */
  assignmentEndDate: string;
  exerciseId: string;
  focusElement: string;
  remoteLogging: boolean;
  seriesId: string;
  seriesReview: boolean;
  studentId: string;
  userId: string;

  //
  calculator: boolean;
  qaMode: boolean;
  staticUrl: string;
  testMode: boolean;
  validationAtEndOfSeries: boolean;

  //
  exerciseid: string; // FIXME: Really, both `exerciseId` and `exerciseid`?

  //
  bookId: string;
  lobId: string;
  token: string;

  //
  ltiReporting: boolean;
}>;

export const parseSeriesPlayerQueryString = (
  queryString: string
): ParsedSeriesPlayerQuery & ParsedQuery<string | boolean> => {
  const parsedQuery = parse(queryString, { parseBooleans: true });
  // TODO: validate the expected output?
  return parsedQuery;
};

export const makeNullableGetParams = (
  queryStringRecord: ParsedQuery<string | boolean>
): undefined | (GetParams & Partial<{ assignmentEndDate: string }>) => {
  if (
    queryStringRecord.appLocale &&
    typeof queryStringRecord.appLocale === 'string' &&
    queryStringRecord.userId &&
    typeof queryStringRecord.userId === 'string'
  ) {
    const assignmentEndDate: undefined | string =
      isString(queryStringRecord.assignmentEndDate) &&
      !Number.isNaN(Date.parse(queryStringRecord.assignmentEndDate))
        ? // validate it is a correct date string
          new Date(Date.parse(queryStringRecord.assignmentEndDate)).toISOString()
        : // force to correct ISO string date format
          undefined;

    return {
      appLocale: queryStringRecord.appLocale,
      assignmentEndDate,
      exerciseId: isString(queryStringRecord.exerciseId) ? queryStringRecord.exerciseId : undefined,
      focusElement: isString(queryStringRecord.focusElement)
        ? queryStringRecord.focusElement
        : undefined,
      remoteLogging: isBoolean(queryStringRecord.remoteLogging)
        ? queryStringRecord.remoteLogging
        : false,
      seriesId: isString(queryStringRecord.seriesId) ? queryStringRecord.seriesId : undefined,
      previewMode: isBoolean(queryStringRecord.previewMode) && queryStringRecord.previewMode,
      seriesReview: isBoolean(queryStringRecord.seriesReview)
        ? queryStringRecord.seriesReview
        : false,
      studentId: isString(queryStringRecord.studentId) ? queryStringRecord.studentId : undefined,
      userId: queryStringRecord.userId,
    };
  }
  return undefined;
};
