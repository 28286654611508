import { compact, defaultTo, flatten, flowRight, get, isNaN, isNil, isString } from 'lodash';
import {
  BEZIER,
  type BezierGroupObject,
  BezierSubtype,
  ContentColor,
  type Coords,
  type FunctionLabel,
  type GeoContent,
  type GeoDecoration,
  isMToken,
  isPureMathContent,
  LABEL,
  LabelAlignDirection,
  type LabelObject,
  mapMathChildren,
  type MathContent,
  type ParametricFunction,
  POINT,
  type PointObject,
  type Readinghelp,
  type ReadingHelpObject,
  type Readinghelps,
} from '@bettermarks/gizmo-types';
import { roundNumber } from '@bettermarks/importers';
import { getLabelContentStyle } from '../geo/measure';
import { getParameter } from './defaults';
import { functionParams, secantParamValues, tangentParamValue } from './helpers';
import {
  getLabelPosition,
  placeLabel,
  SECANTLABEL1_KEY,
  SECANTLABEL2_KEY,
  TANGENTLABEL_KEY,
  VERTEXLABEL_KEY,
} from './labelPosition';
import { f as fVal, graph, type Graph, roots, secant, tangent } from './math/evaluate';

type Vertex = {
  id?: string;
  point: PointObject;
  label: LabelObject;
};

type Tangent = {
  tangent_id: string | null;
  tangent_point_id: string | null;
  payload: object | null;
};

type Secant = {
  secant_id: string | null;
  secant_point_id1: string | null;
  secant_point_id2: string | null;
  payload: object | null;
};

// checks, if a point (x, y) is inside the geo boundaries ...
const isInsideGeo = (p: Coords, geo: GeoContent, b = geo.configuration.display): boolean =>
  p.x >= b.xMin && p.x <= b.xMax && p.y >= b.yMin && p.y <= b.yMax;

/**
 * simply creates a 'point object' suitable for geo content.
 */
export const pointObject = (x: number, y: number, deco: GeoDecoration): PointObject => ({
  type: POINT,
  decoration: deco,
  coords: { x: x, y: y },
  referencedBy: [],
  referringTo: [],
});

/**
 * simply creates a 'bezier object' suitable for geo content.
 */
export const beziersObject = (
  points: Graph,
  deco: GeoDecoration,
  hasError: boolean
): BezierGroupObject => ({
  type: BEZIER,
  subtype: BezierSubtype.lines,
  decoration: {
    ...deco,
    ...(hasError ? { color: ContentColor.BM_RED } : null),
  },
  // in order to display beziers properly in SVG, they need to have an odd number of base points
  beziers: [
    {
      points: points.length % 2 === 1 ? points : [...points, ...points.slice(-1)],
    },
  ],
  referencedBy: [],
  referringTo: [],
});

/**
 * simply creates a 'redainghelp object' suitable for geo content.
 */
export const readinghelpObject = (pointId: string, deco: GeoDecoration): ReadingHelpObject => ({
  type: 'readinghelps',
  decoration: deco,
  pointId: pointId,
  referencedBy: [],
  referringTo: [pointId],
});

const fillLabel = (math: MathContent, ...ps: number[]): MathContent =>
  isMToken(math)
    ? {
        ...math,
        text: math.text.replace(/\$([0-9])/g, (_, p1) => `${ps[parseInt(p1, 10)]}`),
      }
    : // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      ({
        ...math,
        ...mapMathChildren(math, (c) => fillLabel(c, ...ps)),
      } as MathContent);

const getVertex = (
  f: ParametricFunction,
  a = getParameter(f, 'a'),
  d = getParameter(f, 'd'),
  e = getParameter(f, 'e'),
  labelId = `${f.id}_vertex_point_label`
): Vertex | undefined =>
  f.vertexLabel &&
  d &&
  e && {
    id: f.id, // needed to generate a vertex id in the geo data
    point: {
      // the actual point
      type: POINT,
      referencedBy: [labelId],
      referringTo: [],
      coords: { x: d.value, y: e.value },
    },
    label: {
      type: LABEL,
      referencedBy: [],
      referringTo: [`${f.id}_vertex_point`],
      refid: `${f.id}_vertex_point`,
      align: a.value >= 0 ? LabelAlignDirection.bottom : LabelAlignDirection.top,
      content: isString(f.vertexLabel.label)
        ? f.vertexLabel.label
        : fillLabel(f.vertexLabel.label, roundNumber(d.value), roundNumber(e.value)),
    },
  };

const getLabel =
  (labelType: string, geo: GeoContent) =>
  (
    f: ParametricFunction,
    _: any,
    __: any,
    functionLabel: FunctionLabel = get(f, labelType),
    labelId = `${f.id}_${labelType}_label`,
    { position, align } = placeLabel(f, labelType, geo.configuration.display)
  ): Vertex | undefined =>
    functionLabel && {
      id: f.id,
      point: {
        type: POINT,
        referencedBy: [labelId],
        referringTo: [],
        coords: position,
      },
      label: {
        type: LABEL,
        referencedBy: [],
        referringTo: [`${f.id}_${labelType}_point`],
        refid: `${f.id}_${labelType}_point`,
        align,
        content: functionLabel.label,
        decoration: {
          ...f.decoration,
          ...functionLabel.decoration,
        },
      },
    };

const getTangent = (
  f: ParametricFunction,
  geo: GeoContent,
  t = tangent(
    tangentParamValue(f),
    f.tangent ? defaultTo<number>(f.tangent.length, 0) : 0,
    f.type,
    functionParams(f)
  )
): Tangent => ({
  tangent_id: `${f.id}_tangent`,
  tangent_point_id: f.tangentLabel ? `${f.id}_tangent_point` : null,
  payload: {
    [`${f.id}_tangent`]: beziersObject(
      t,
      f.tangent && f.tangent.decoration ? f.tangent.decoration : {},
      false
    ),
    [`${f.id}_tangent_point`]: pointObject(
      tangentParamValue(f),
      fVal(f.type)(functionParams(f))(0)(tangentParamValue(f)),
      f.tangentLabel ? f.tangentLabel.decoration : {}
    ),
  },
});

const getSecant = (
  f: ParametricFunction,
  geo: GeoContent,
  s = secant(secantParamValues(f), f.type, functionParams(f)),
  [sx1InRange, sx2InRange] = secantParamValues(f).map((sx) =>
    isInsideGeo({ x: sx, y: fVal(f.type)(functionParams(f))(0)(sx) }, geo)
  )
): Secant => ({
  secant_id: `${f.id}_secant`,
  secant_point_id1: f.secantLabel1 && sx1InRange ? `${f.id}_secant_point1` : null,
  secant_point_id2: f.secantLabel2 && sx2InRange ? `${f.id}_secant_point2` : null,
  payload: {
    [`${f.id}_secant`]: beziersObject(
      s,
      f.secant && f.secant.decoration ? f.secant.decoration : {},
      false
    ),
    ...secantParamValues(f).reduce(
      (acc1, sx, i) => ({
        ...acc1,
        ...(!isNil(i === 0 ? f.secantLabel1 : f.secantLabel2) && (i === 0 ? sx1InRange : sx2InRange)
          ? {
              [`${f.id}_secant_point${i + 1}`]: pointObject(
                sx,
                fVal(f.type)(functionParams(f))(0)(sx),
                i === 0
                  ? f.secantLabel1 && f.secantLabel1.decoration
                    ? f.secantLabel1.decoration
                    : {}
                  : f.secantLabel2 && f.secantLabel2.decoration
                  ? f.secantLabel2.decoration
                  : {}
              ),
            }
          : {}),
      }),
      {}
    ),
  },
});

// this is just to make it usable in "map" which provides three arguments
const getVertex_ = (f: ParametricFunction) => getVertex(f);

const styleLabel = (geo: GeoContent, label: LabelObject) =>
  isString(label.content) || !isPureMathContent(label.content)
    ? label
    : {
        ...label,
        content: {
          ...label.content,
          decoration: getLabelContentStyle(
            geo.points,
            geo.geoContentMap,
            { fontSize: 16 },
            label,
            geo.scale
          ),
        },
      };

const geoStyleLabels = (geo: GeoContent): GeoContent => ({
  ...geo,
  geoContentMap: geo.labels.reduce(
    (a, l) => ({
      ...a,
      [l]: styleLabel(geo, geo.geoContentMap[l] as LabelObject),
    }),
    geo.geoContentMap
  ),
});

/**
 * Injects labels and point values at the vertices of a graph. The labels are
 * defined inside our parametric function data structure.
 *
 * @param functions the parametric functions containing the vertices
 * @param geo the geo content to fill
 * @returns geo content with injected vertex labels and points
 */
export const geoInjectGraphVertices =
  (
    fs: ParametricFunction[],
    vertices = compact(fs.filter((f) => !isNil(f.vertexLabel)).map(getVertex_))
  ) =>
  (geo: GeoContent): GeoContent =>
    geoStyleLabels({
      ...geo,
      points: [...geo.points, ...vertices.map((v) => `${v.id}_vertex_point`)],
      labels: [...geo.labels, ...vertices.map((v) => `${v.id}_vertex_point_label`)],
      geoContentMap: {
        ...geo.geoContentMap,
        ...vertices.reduce(
          (acc, v) => ({
            ...acc,
            [`${v.id}_vertex_point`]: v.point,
            [`${v.id}_vertex_point_label`]: v.label,
          }),
          {}
        ),
      },
    });

/**
 * Inject the function labels for a given type. The type name corresponds with the property
 * name in the ParametricFunction data structure - e.g. "graphLabel" or "tangentLabel".
 * The label is then placed at a point (or an invisible point) and decorated accodingly.
 * @param labelType label type (property name)
 * @param drawPont wether to draw the point where the label is attached to
 */
export const geoInjectFunctionLabels =
  (labelType: string) =>
  (fs: ParametricFunction[]) =>
  (
    geo: GeoContent,
    labels = compact(fs.filter((f) => !isNil(get(f, labelType))).map(getLabel(labelType, geo)))
  ): GeoContent =>
    geoStyleLabels({
      ...geo,
      invisiblePoints: [
        ...geo.invisiblePoints,
        ...compact(
          labels.map((l) =>
            isInsideGeo(l.point.coords, geo) ? `${l.id}_${labelType}_point` : null
          )
        ),
      ],
      labels: [
        ...geo.labels,
        ...compact(
          labels.map((l) =>
            isInsideGeo(l.point.coords, geo) ? `${l.id}_${labelType}_label` : null
          )
        ),
      ],
      geoContentMap: {
        ...geo.geoContentMap,
        ...labels.reduce(
          (acc, l) => ({
            ...acc,
            ...(isInsideGeo(l.point.coords, geo) && {
              [`${l.id}_${labelType}_point`]: l.point,
              [`${l.id}_${labelType}_label`]: l.label,
            }),
          }),
          {}
        ),
      },
    });

/**
 * injects tangent lines for each function (if available) into given geo context
 * @param {GeoContent} geo
 * @param {ParametricFunction[]} funcs - the parametric functions holding tangents!!!
 * @returns {GeoContent}
 */
export const geoInjectTangents =
  (funcs: ParametricFunction[]) =>
  (
    geo: GeoContent,
    tangentFuncs = funcs.filter((f) => !isNil(f.tangent)).map((f) => getTangent(f, geo))
  ): GeoContent => ({
    ...geo,
    beziers: [...geo.beziers, ...compact(tangentFuncs.map((f) => f.tangent_id))],
    points: [...geo.points, ...compact(tangentFuncs.map((f) => f.tangent_point_id))],
    geoContentMap: {
      ...geo.geoContentMap,
      ...tangentFuncs.reduce(
        (acc, f) => ({
          ...acc,
          ...f.payload,
        }),
        {}
      ),
    },
  });

/**
 * injects secant lines for each function (if available) into given geo context
 * @param {GeoContent} geo
 * @param {ParametricFunction[]} funcs - the parametric functions holding secants!
 * @returns {GeoContent}
 */
export const geoInjectSecants =
  (funcs: ParametricFunction[]) =>
  (
    geo: GeoContent,
    secantFuncs = funcs.filter((f) => !isNil(f.secant)).map((f) => getSecant(f, geo))
  ): GeoContent => ({
    ...geo,
    beziers: [...geo.beziers, ...compact(secantFuncs.map((f) => f.secant_id))],
    points: [
      ...geo.points,
      ...flatten(
        compact(secantFuncs.map((f) => compact([f.secant_point_id1, f.secant_point_id2])))
      ),
    ],
    geoContentMap: {
      ...geo.geoContentMap,
      ...secantFuncs.reduce(
        (acc, f) => ({
          ...acc,
          ...f.payload,
        }),
        {}
      ),
    },
  });

// a list of keys of the ParametricFunction providing information about specific reading help.
const readinghelpMap: { [key: string]: [string, string] } = {
  secantReadinghelp1: ['_secant_point1', SECANTLABEL1_KEY],
  secantReadinghelp2: ['_secant_point2', SECANTLABEL2_KEY],
  tangentReadinghelp: ['_tangent_point', TANGENTLABEL_KEY],
  vertexReadinghelp: ['_vertex_point', VERTEXLABEL_KEY],
};

/**
 * injects reading help lines for each function (if available) into given geo context
 * @param {GeoContent} geo
 * @param {ParametricFunction[]} funcs - the parametric functions holding reading helps!
 * @returns {GeoContent}
 */
export const geoInjectReadinghelps =
  (
    funcs: ParametricFunction[],
    id = (f: any, key: string): string => `${f.id}_readinghelp${readinghelpMap[key][0]}`,
    readinghelpFuncs = funcs.filter((f) =>
      Object.keys(readinghelpMap).reduce(
        (acc, key: keyof Readinghelps) => acc || !isNil(f[key]),
        false
      )
    )
  ) =>
  (geo: GeoContent): GeoContent => ({
    ...geo,
    readinghelps: [
      ...geo.readinghelps,
      ...compact(
        flatten(
          readinghelpFuncs.map((f) =>
            Object.keys(readinghelpMap).map(
              (key: keyof Readinghelps) =>
                !isNil(f[key]) &&
                isInsideGeo(getLabelPosition(readinghelpMap[key][1], f, geo), geo) &&
                id(f, key)
            )
          )
        )
      ),
    ],
    geoContentMap: {
      ...geo.geoContentMap,
      ...readinghelpFuncs.reduce(
        (acc, f) => ({
          ...acc,
          ...Object.keys(readinghelpMap).reduce(
            (acc1, key: keyof Readinghelps) => ({
              ...acc1,
              ...(!isNil(f[key]) &&
                isInsideGeo(getLabelPosition(readinghelpMap[key][1], f, geo), geo) &&
                id(f, key) && {
                  [id(f, key)]: readinghelpObject(
                    `${f.id}${readinghelpMap[key][0]}`,
                    (f[key] && ((f[key] as Readinghelp).decoration as GeoDecoration)) || {}
                  ),
                }),
            }),
            {}
          ),
        }),
        {}
      ),
    },
  });

/**
 * injects intersection line points for each function (if available) into given geo context
 * @param {GeoContent} geo
 * @param {ParametricFunction[]} funcs - the parametric functions holding the intersection lines
 * @returns {GeoContent}
 */
export const geoInjectIntersectionLines =
  (funcs: ParametricFunction[], filtered = funcs.filter((f) => !isNil(f.intersectionLines))) =>
  (geo: GeoContent): GeoContent => ({
    ...geo,
    points: [
      ...geo.points,
      ...compact(
        flatten(
          filtered.map((f) =>
            f.intersectionLines
              ? flatten(
                  f.intersectionLines.map((il, i) =>
                    il.x1 === il.x2
                      ? isInsideGeo(
                          {
                            x: il.x1,
                            y: fVal(f.type)(functionParams(f))(0)(il.x1),
                          },
                          geo
                        )
                        ? `${f.id}_intersectV_point_${i}`
                        : []
                      : roots(f.type)(functionParams(f))(il.y1).map(
                          (_, j) => `${f.id}_intersectH_point_${i}_${j}`
                        )
                  )
                )
              : []
          )
        )
      ),
    ],
    geoContentMap: {
      ...geo.geoContentMap,
      ...filtered.reduce(
        (acc, f) => ({
          ...acc,
          ...(f.intersectionLines
            ? f.intersectionLines.reduce(
                (acc1, il, i) => ({
                  ...acc1,
                  ...(il.x1 === il.x2
                    ? isInsideGeo(
                        {
                          x: il.x1,
                          y: fVal(f.type)(functionParams(f))(0)(il.x1),
                        },
                        geo
                      )
                      ? {
                          [`${f.id}_intersectV_point_${i}`]: pointObject(
                            il.x1,
                            fVal(f.type)(functionParams(f))(0)(il.x1),
                            il.decoration || {}
                          ),
                        }
                      : {}
                    : roots(f.type)(functionParams(f))(il.y1)
                        .filter((root) => !isNaN(root))
                        .reduce(
                          (acc2, r, j) => ({
                            ...acc2,
                            [`${f.id}_intersectH_point_${i}_${j}`]: pointObject(
                              r,
                              il.y1,
                              il.decoration || {}
                            ),
                          }),
                          {}
                        )),
                }),
                {}
              )
            : {}),
        }),
        {}
      ),
    },
  });

/**
 * Helper function that generates the bezier graphs object to be spread into
 * the geoContentMap. We need to do this first, to have all the ids, when we
 * actually put this into the GeoContent (see geoInjectBezierFunctions below)
 */
const getBezierGraphs = (
  funcs: ParametricFunction[],
  samples: number,
  geo: GeoContent,
  showGeoError: boolean
) =>
  funcs.reduce(
    (acc, gf: ParametricFunction) => ({
      ...acc,
      ...graph(
        // produces a list of graphs (where graph is a list of tuples)
        geo.configuration.display,
        samples,
        gf.type,
        functionParams(gf)
      ).reduce(
        // create a map of bezier object from the graphs
        (gs, g, i) => ({
          ...gs,
          [`${gf.id}_${i}`]: beziersObject(
            g,
            gf && gf.decoration ? gf.decoration : {},
            showGeoError
          ),
        }),
        {}
      ),
    }),
    {}
  );

/**
 * Injects function plotter graphs into geo content as bezier curves
 * @param {ParametricFunction[]} funcs - array of parametric functions
 * @param bezierFuncs
 * @param showGeoError
 * @returns {GeoContent}
 */
export const geoInjectBezierFunctions =
  (showGeoError: boolean) =>
  (funcs: ParametricFunction[], bezierFuncs = funcs.filter((f) => !isNil(f.id))) =>
  (
    geo: GeoContent,
    samples = 251,
    cfg = geo.configuration,
    graphs = getBezierGraphs(bezierFuncs, samples, geo, showGeoError)
  ): GeoContent => ({
    ...geo,
    beziers: [...geo.beziers, ...Object.keys(graphs)],
    geoContentMap: {
      ...geo.geoContentMap,
      ...graphs,
    },
  });

/**
 * Functional composition like flowRight, but also apply a common parameter to all functions.
 * @param fs List of functions with one fixed parameter
 */
const flowWith =
  <T, P>(fs: ((t: T) => (p: P) => P)[]) =>
  (t: T) =>
    flowRight(fs.map((f) => f(t)));

/**
 * Compososition of the different function that inject data in the geo data
 * structure, like tangents, beziers etc.
 * @returns {(geo: GeoContent) => GeoContent} function that transforms our geo content
 * @param showGeoError
 */
export const geoInject = (showGeoError = false) =>
  flowWith([
    geoInjectIntersectionLines,
    geoInjectReadinghelps,
    geoInjectGraphVertices,
    geoInjectFunctionLabels('ghostLabel'),
    geoInjectFunctionLabels('graphLabel'),
    geoInjectFunctionLabels('tangentLabel'),
    geoInjectFunctionLabels('secantLabel1'),
    geoInjectFunctionLabels('secantLabel2'),
    geoInjectSecants,
    geoInjectTangents,
    geoInjectBezierFunctions(showGeoError),
  ]);
