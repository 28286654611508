import * as React from 'react';
import { type Severity } from '@bettermarks/umc-kotlin';
import { getNamedColor } from '@bettermarks/importers';
import {
  DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH,
  DEFAULT_STACKED_BAR_HEIGHT,
  DEFAULT_STACKED_BAR_STROKE,
  DEFAULT_STACKED_BAR_STROKE_WIDTH,
} from '../../defaults';

export type BarHighlightProps = {
  barStart: number;
  barWidth: number;
  severity?: Severity;
  previousBarWithErrorHighlight: boolean;
  nextBarWithErrorHighlight: boolean;
};

export const BarHighlight: React.FC<BarHighlightProps> = ({
  barStart,
  barWidth,
  previousBarWithErrorHighlight,
  nextBarWithErrorHighlight,
  severity,
}) => {
  const xOffsetPrevBar = previousBarWithErrorHighlight ? -DEFAULT_STACKED_BAR_STROKE_WIDTH / 2 : 0;
  const widthAdjustment =
    (nextBarWithErrorHighlight ? 0 : -DEFAULT_STACKED_BAR_STROKE_WIDTH) +
    (previousBarWithErrorHighlight ? DEFAULT_STACKED_BAR_STROKE_WIDTH / 2 : 0);

  return (
    <>
      /* red border for error highlight */
      <rect
        x={barStart + DEFAULT_STACKED_BAR_STROKE_WIDTH / 2 + xOffsetPrevBar}
        y={2 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH}
        width={
          barWidth +
          DEFAULT_STACKED_BAR_STROKE_WIDTH / 2 -
          DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH +
          widthAdjustment
        }
        height={DEFAULT_STACKED_BAR_HEIGHT - 3 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH + 1}
        style={{
          fill: 'none',
          stroke: getNamedColor(severity),
          strokeWidth: DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH,
        }}
      />
      /* inner white border for error highlight*/
      <rect
        x={
          barStart +
          DEFAULT_STACKED_BAR_STROKE_WIDTH / 2 +
          DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH +
          xOffsetPrevBar
        }
        y={3 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH}
        width={
          barWidth +
          DEFAULT_STACKED_BAR_STROKE_WIDTH / 2 -
          3 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH +
          widthAdjustment
        }
        height={DEFAULT_STACKED_BAR_HEIGHT - 5 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH + 1}
        style={{
          fill: 'none',
          stroke: getNamedColor(DEFAULT_STACKED_BAR_STROKE),
          strokeWidth: DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH,
        }}
      />
    </>
  );
};

BarHighlight.displayName = 'BarHighlight';
