import {
  type Exporter,
  type FunctionPlotterContent,
  type ParametricFunction,
  RS,
  SEMANTICS,
} from '@bettermarks/gizmo-types';
import { decorationToString } from '../../gizmo-utils/decoration';
import { flatMap } from 'lodash/fp';

const allParams = flatMap((fun: ParametricFunction) => fun.parameters);

const OPENING_SEMANTICS_TAG = `<${SEMANTICS}`;

export const exportFunctionPlotter: Exporter = (
  contentRefId,
  context,
  fp: FunctionPlotterContent = context.content[contentRefId] as FunctionPlotterContent
) =>
  fp.xml
    .split(OPENING_SEMANTICS_TAG) // poor mans XML manipulation using RegEx needs proper scoping
    .map((part) => {
      /* slider values in XML need to be replaced with current values */
      if (!part.includes(` render-style='${RS.SLIDER}'`)) return part;
      const idMatch = / id='([^']+)'/.exec(part);
      if (!idMatch) return part;
      const [, id] = idMatch;
      /* each slider is a parameter of one of the functions */
      const param = allParams(fp.functions).find((p) => p.refId === id);
      if (!param) return part;
      return part
        .replace(/configuration>(\s*)<mn>[^<]+<\/mn/m, `configuration>$1<mn>${param.value}</mn`)
        .replace(
          /decoration='[^']*error[^']*'/,
          `decoration='${decorationToString(param.decoration)}'`
        );
    })
    .join(OPENING_SEMANTICS_TAG);
