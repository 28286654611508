import * as React from 'react';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import {
  type InputDecoration,
  type LayoutMetrics,
  numberFromStyles,
} from '@bettermarks/gizmo-types';
import { DEFAULT_FONT_SIZE } from '../../../../gizmo-utils/measure';
import { defaultTo } from 'lodash';
import styles from './FormulaInput.scss';
import { onClickLeaf } from './helpers';
import { type SelectHandler } from './types';

/**
 * The total amount of vertical pixels that will be added by FormulaInput
 * when wrapping content.
 */
const FORMULA_INPUT_PADDING_BORDER = numberFromStyles(styles.FORMULA_INPUT_PADDING_BORDER);

export interface FormulaInputProps {
  id?: string;
  disabled?: boolean;
  onSelect?: SelectHandler;
  onFocus?: undefined | React.FocusEventHandler;
  onBlur?: undefined | React.FocusEventHandler;
  decoration?: InputDecoration;
  fontSize?: number;
  tabIndex?: number;
  minWidth?: number;
  tableCell?: boolean;
  marginLeft?: number;
  marginRight?: number;
}

/**
 * The `FormulaInput` is a component for text input field.
 *
 * ### Process State
 *
 * - Visuals: Working image tests
 * - Behaviour: None
 *
 * ### Properties
 | Name          | Type                                  | Default   | Description               |
 |---            |---                                    |---        |---                        |
 | `decoration`  | one of                                | Optional  | Default is SEVERITY_DEFAULT|
 |               | `['default', 'remark', 'error']`      |           | the choice can have       |
 | `interactive` | `boolean`                             | Required  | mc option interactive for |
 |               |                                       |           | user?                     |
 | `fontSize`    | number                                | Optional  | font size of the input    |
 | `tabIndex`    | number                                | Optional  | the tab index of the DOM  |
 |               |                                       |           | element                   |
 | `minWidth`    | number                                | Optional  | minimum width in pixels   |
 | `minHeight`   | number                                | Optional  | minimum height in pixels  |
 */

export const FormulaInput: React.FC<FormulaInputProps> = ({
  id,
  decoration,
  disabled,
  onFocus,
  onBlur,
  onSelect,
  fontSize,
  tabIndex,
  children,
  minWidth,
  tableCell,
  marginLeft,
  marginRight,
}) => {
  const style = classNames(decoration ? styles[decoration] : styles.default, {
    [styles.disabled]: disabled,
    [styles.tableCell]: tableCell,
  });

  const inputProps = {
    id,
    type: 'text',
    className: style,
    style: {
      minWidth: defaultTo(minWidth, defaultTo(fontSize, DEFAULT_FONT_SIZE * 1.23)),
    },
    disabled,
    tabIndex,
  };

  /**
   * There are some timing issues when placing the cursor: When you click, you'll also give focus to
   * the formula input. Both clicking inside and focusing the input field will try to place the
   * cursor. The latter will try to put it at the end of the formula, the former will try to put it
   * at the designated position. The former should win, so to make sure of that, we place the cursor
   * there as early as possible (mouseDown) and if we get focus, we only place the cursor at the
   * end, if there isn't one already in the formula.
   *
   * Since the select handlers only handle the click on the currentTarget,
   * we need to apply the click handlers to all "outer" levels that should trigger the action.
   * (Especially since left and right margin specified in the content is applied on it.)
   * Since we are relying on the fact that there is exactly one HTML element per formula,
   * that has the role of a textbox and a tabIndex when it's interactive,
   * only the click handling is duplicated, not the focus handling.
   */
  const onMouseDown = disabled ? undefined : onClickLeaf(onSelect);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputRef.current && decoration === 'focus') {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div
      className={classNames(styles.formulaInputWrapper, {
        [styles.tableCell]: tableCell,
        [styles.scaffolderInputRow]:
          !id ||
          (id &&
            !id.startsWith('scaffolder-dummy-') &&
            !id.startsWith('scaffolder-transformation-')),
        [styles.scaffolderTransformation]: id && id.startsWith('scaffolder-transformation-'),
      })}
      style={{ marginLeft, marginRight }}
      role="button"
      onMouseDown={onMouseDown}
    >
      <div
        {...inputProps}
        onMouseDown={onMouseDown}
        tabIndex={disabled ? undefined : tabIndex}
        onFocus={onFocus}
        onBlur={onBlur}
        role="textbox"
        ref={inputRef}
        style={{ minHeight: '44px' }}
      >
        <span className={styles.formulaInputBox}>{children}</span>
      </div>
    </div>
  );
};

FormulaInput.displayName = 'FormulaInput';

/**
 * To know the LayoutMetrics for a Formula we need to check different conditions
 * depending on the gizmo. This method is a noop if the `isInteractive` condition is `false`,
 * otherwise it returns a `LayoutMetrics` that matches the behavior of wrapping the content
 * inside `FormulaInput`.
 *
 * @param {LayoutMetrics} fromContent
 * @param {boolean} isInteractive
 * @returns {LayoutMetrics}
 */
export const measureFormulaInput = (
  fromContent: LayoutMetrics,
  isInteractive: boolean
): LayoutMetrics =>
  !isInteractive
    ? fromContent
    : {
        height: fromContent.height + FORMULA_INPUT_PADDING_BORDER,
        refLine: fromContent.refLine + FORMULA_INPUT_PADDING_BORDER / 2,
        relativeToBaseLine: true,
      };
