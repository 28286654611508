import * as React from 'react';
import { defaultTo, last } from 'lodash';

import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import { type Dispatch } from '../../../../gizmo-utils/redux/types';
import { useTranslation } from 'react-i18next';
import {
  type AppStep,
  CollapsibleContentState,
  SeriesFlow,
  type StepFeedback,
} from '../../../../types';

import { CollapsibleContentStyle, ContentBox } from '../../components';
import { StepTitleTranslationKey } from './StepComponent';
import { STEP_CONTENT_PADDING } from './constants';
import { isEmptyInstruction } from './helper';
import styles from './step.scss';
import { StepAttemptBox } from './StepAttemptBox';
import { Severity } from '@bettermarks/umc-kotlin';
import { type Attempt } from '../../services/api/result-manager/types';
import { ContextNotification, ContextNotificationKind } from '@seriesplayer/common-ui';
import { getIconByKey } from '../../../../components';

export type StepComponentProps = Readonly<{
  step: AppStep;
  stepIndex: number;
  availableWidth: number;
  currentStepId?: string;
  hideHelpTools?: boolean;
  switched?: boolean;
  dispatch: Dispatch;
  isTouch: boolean;
  selectedGizmoRef: React.RefObject<HTMLDivElement>;
  flow: SeriesFlow;
  staticMediaUrl: string;
}>;

export type StepProps = StepComponentProps;

export const StepContentReview: React.FC<StepProps> = (props) => {
  const {
    step: {
      answer,
      explanation,
      instruction,
      instructionHelp,
      attempts,
      aborted,
      maxTries,
      numberOfErrors,
    },
    availableWidth,
    currentStepId,
    dispatch,
    hideHelpTools,
    switched,
    isTouch,
    flow,
    staticMediaUrl,
  } = props;

  const [t] = useTranslation();
  const availableChildWidth = availableWidth - STEP_CONTENT_PADDING * 2;

  const commonProps = {
    availableWidth: availableChildWidth,
    dispatch,
    gizmoRegistry,
    hideHelpTools,
    isTouch,
  };

  // Flow random is currently possible only for test mode.
  // const isTestMode = mode === SeriesMode.test;
  const isTestMode = flow === SeriesFlow.random;
  let attemptsCount = 0;

  const findSomeFeedback = (
    feedbacks: ReadonlyArray<StepFeedback> | undefined,
    condition: (f: StepFeedback) => boolean
  ) => !!feedbacks && feedbacks.some(condition);

  const hasErrorAttempt =
    !aborted &&
    attempts &&
    attempts.some(({ userInput: { feedbacks } }) =>
      findSomeFeedback(feedbacks, (f) => f.severity === Severity.error)
    );

  const showExpectedAnswer = (): boolean => {
    if (!attempts || attempts.length === 0 || (isTestMode && numberOfErrors > 0)) {
      return true;
    }

    return findSomeFeedback(
      (last(attempts) as Attempt).userInput.feedbacks,
      (f) => f.severity === Severity.error
    );
  };

  const getSeverity = (feedbacks: ReadonlyArray<StepFeedback>) => {
    // for testMode we can check the validity of the step to determine the attempt severity
    if (isTestMode && numberOfErrors > 0) {
      return Severity.error;
    }

    // in general take the severity of the first feedback of an attempt (as we assume all
    // feedbacks of an attempt to have the same severity)
    // in case no feedbacks are attached we interpret the attempt as correct
    // (can be the case for testmode and skipped steps)
    return feedbacks.length > 0 ? defaultTo(feedbacks[0].severity, Severity.error) : Severity.ok;
  };

  return (
    <div className={styles.content} key={currentStepId}>
      {!isEmptyInstruction(instruction, t) && (
        <ContentBox contentDict={instruction} staticMediaUrl={staticMediaUrl} {...commonProps} />
      )}
      {instructionHelp && (
        <ContextNotification kind={ContextNotificationKind.remark}>
          {getIconByKey(instructionHelp.type, ContextNotificationKind.remark)}
          {instructionHelp.text}
        </ContextNotification>
      )}

      {attempts &&
        attempts.map(({ userInput: { feedbacks, question } }, idx) => {
          if (isTestMode) {
            attemptsCount = maxTries;
          } else if (
            findSomeFeedback(feedbacks, (f) => f.severity !== Severity.remark) ||
            feedbacks.length === 0
          ) {
            /**
             * attempts with remark feedback do not count as attempt (stays on same attempt)
             * but if there is no feedback at all
             */
            attemptsCount++;
          }

          return (
            <StepAttemptBox
              key={idx}
              {...{ attemptsCount, feedbacks, question }}
              {...commonProps}
              severity={getSeverity(feedbacks)}
              singleTry={maxTries === 1 || isTestMode}
              staticMediaUrl={staticMediaUrl}
            />
          );
        })}

      {(attemptsCount === 0 || (attemptsCount < maxTries && hasErrorAttempt)) && (
        /**
         * in case there are no attempts at all or not all attempts have been used
         * the step is counted as skipped.
         */
        <StepAttemptBox
          feedbacks={[]}
          attemptsCount={attemptsCount}
          {...commonProps}
          severity={Severity.ok}
          singleTry={false}
          skipped={true}
          staticMediaUrl={staticMediaUrl}
        />
      )}

      {
        /*  last attempt wrong or no attempt made (in case of skipped step) */
        showExpectedAnswer() && (
          <ContentBox
            contentDict={answer}
            collapsibleKind={CollapsibleContentStyle.answer}
            title={t(StepTitleTranslationKey.expectedAnswer)}
            collapse={CollapsibleContentState.expanded}
            switched={switched}
            staticMediaUrl={staticMediaUrl}
            {...commonProps}
          />
        )
      }

      <ContentBox
        contentDict={explanation}
        collapsibleKind={CollapsibleContentStyle.explanation}
        title={t(StepTitleTranslationKey.explanation)}
        inactive={false}
        collapse={CollapsibleContentState.collapsed}
        switched={switched}
        staticMediaUrl={staticMediaUrl}
        {...commonProps}
      />
    </div>
  );
};

StepContentReview.displayName = 'StepContentReview';
