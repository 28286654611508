import { type ContentDict, type ContentReference } from '@bettermarks/gizmo-types';
import { type DragSourceContent, type DropTargetContent } from '@bettermarks/gizmo-types';
import { findKey } from 'lodash';

type FilledDragTarget = Readonly<Pick<DropTargetContent, 'items' | 'dragItemFixed'>>;
export const resolveDragSource = (
  contentDict: ContentDict,
  { items, dragItemFixed }: FilledDragTarget,
  index = 0
): undefined | (ContentReference & { dragSource: DragSourceContent }) => {
  let $refid: string | undefined;
  if (items.length > index) {
    $refid = findKey(contentDict, { $id: items[index].dragSourceId });
  } else if (dragItemFixed) {
    $refid = dragItemFixed;
  }
  if ($refid) {
    const dragSource = contentDict[$refid] as DragSourceContent;
    return { $refid, dragSource };
  }
};
