export const enum OptionState {
  selected = 'selected',
  unselected = 'unselected',
  error = 'error',
}

export interface BaseButton {
  state: OptionState;
  interactive: boolean | undefined;
}

export const enum OptionDecoratorMode {
  single = 'single',
  multi = 'multi',
}
