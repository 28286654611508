import { type Action, handleActions } from 'redux-actions';
import {
  type LayoutTreeNode,
  NodeType,
  type SizeMap,
  TREE_CHART_GIZMO_DEFAULT_CONTENT,
  type TreeChartContent,
} from '@bettermarks/gizmo-types';
import { type NodeSizePayload, SET_NODE_SIZE } from '../Graph/graphActions';
import { flattenTree, map } from './layoutTree';
import { graph } from './layout';

const allSizesReported = (layoutTree: LayoutTreeNode, nodeSizes: SizeMap) =>
  flattenTree(layoutTree).reduce(
    (acc, cur) => acc && (cur.type === NodeType.gizmo ? nodeSizes[cur.nodeId] !== undefined : true),
    true
  );

export const treeChartReducer = handleActions<TreeChartContent, NodeSizePayload>(
  {
    [SET_NODE_SIZE]: (state, { payload }: Action<NodeSizePayload>) => {
      if (!payload) return state;
      const withSize = {
        ...state,
        nodeSizes: { ...state.nodeSizes, [payload.id]: payload.size },
      };
      if (withSize.layoutTree) {
        const haveAllSizes = allSizesReported(withSize.layoutTree, withSize.nodeSizes);
        if (haveAllSizes) {
          return {
            ...withSize,
            graph: graph(
              withSize.direction,
              map(
                (n) =>
                  n.type === NodeType.gizmo
                    ? {
                        ...n,
                        size: withSize.nodeSizes[n.nodeId],
                      }
                    : n,
                withSize.layoutTree
              )
            ),
            visible: true,
          };
        }
      }
      return withSize;
    },
  },
  {
    ...TREE_CHART_GIZMO_DEFAULT_CONTENT,
  }
);
