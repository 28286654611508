import { isEmpty } from 'lodash';
import {
  type ImageContent,
  type Importer,
  toBoolean,
  xmlTextToFloat,
} from '@bettermarks/gizmo-types';

export const convertImageSource = (source: string): string => {
  let base = source;
  let extension = 'svg';
  // Expected structure: '[FILENAME].[EXT]' or '[FILENAME]'
  const match = /(.*)\.([^.]{3})$/i.exec(source);

  if (match) {
    base = match[1];
    extension = match[2];

    if (extension.toLowerCase() === 'swf') {
      extension = 'svg';
    }
  }

  return !isEmpty(base) ? `${base}.${extension}` : base;
};

export const importImage: Importer<ImageContent> = (preContent, xml, context) => {
  const rootElement = xml.hasChild('mrow') ? xml.findChildTag('mrow') : xml;

  const source = convertImageSource(rootElement.findChildTag('source').text);
  context.images.add(source);

  const { width, height } = rootElement.tagsToProps(xmlTextToFloat, ['width', 'height']);
  const scaleNode = rootElement.findChildTag('scale');

  const scaleCenterCoords = scaleNode.attributesToProps(parseFloat, ['centerX', 'centerY']);

  const pixelCoords = toBoolean(scaleNode.attribute('pixelCoords'));

  return {
    ...preContent,
    source,
    width,
    height,
    unscaledWidth: width,
    unscaledHeight: height,
    scalable: true,
    ...(scaleCenterCoords.hasOwnProperty('centerX') &&
      scaleCenterCoords.hasOwnProperty('centerY') && {
        scaleCenter: {
          x: scaleCenterCoords.centerX,
          y: scaleCenterCoords.centerY,
          pixelCoords,
        },
      }),
    ...(scaleNode.hasAttribute('dynamicScale') && {
      dynamicScale: scaleNode.attribute('dynamicScale'),
    }),
    ...(scaleNode.hasAttribute('valueSetterRefIdsScale') && {
      valueSetterRefIdsScale: scaleNode.attribute('valueSetterRefIdsScale'),
    }),
  };
};
