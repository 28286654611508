import React from 'react';
import CollapsibleSection from './CollapsibleSection';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import { HTML5_ENTITIES } from '../../../../lib/html-entities/html5-entities';

type StudentTaskProps = {
  refId: string;
  collapsedTitle: string;
  availableWidth: number;
};

export default function StudentTask({ refId, collapsedTitle, availableWidth }: StudentTaskProps) {
  const fullTextGizmo = <PolymorphicGizmo refid={refId} availableWidth={availableWidth} />;

  return (
    <CollapsibleSection title={collapsedTitle} initialMode={'open'} isChildSection={true}>
      {fullTextGizmo}
    </CollapsibleSection>
  );
}

const maxTitleWordCount = 3;

const endsWithWhitespace = (str: string) =>
  str.endsWith(' ') || str.endsWith(' ' + HTML5_ENTITIES.InvisibleComma);

/**
 * Extracts the text content from the student task's content dict entry
 * and trims it to the first few words. If one of those words happens to be
 * a formula or something else that cannot be displayed as plain text,
 * the title ends there and becomes even shorter.
 * */
export const getCollapsedStudentTaskTitle: (contentFromDict: {
  content: TextFragmentElement[];
}) => string = (contentFromDict) => {
  let collapsedTitle = '';
  let wordCount = 0;
  let done = false;

  const finish = () => {
    collapsedTitle += endsWithWhitespace(collapsedTitle) ? '...' : ' ...';
    done = true;
  };

  contentFromDict.content.forEach((textFragment) => {
    if (!done) {
      const text = getTextRecursively(textFragment);

      if (text) {
        const tokens = text.split(' ');
        const wordsLeft = maxTitleWordCount - wordCount;
        const tokensToUse = tokens.slice(0, wordsLeft);
        const joinedTokensToUse = tokensToUse.join(' ');

        collapsedTitle = collapsedTitle + joinedTokensToUse;
        wordCount += tokensToUse.length;
        if (wordCount >= maxTitleWordCount) finish();
      } else {
        finish();
      }
    }
  });

  return collapsedTitle;
};

type TextFragmentElement = {
  $: string;
  text?: string;
  children?: TextFragmentElement[];
};

const getTextRecursively: (textFragment: TextFragmentElement) => string | undefined = (
  fragment
) => {
  if (fragment.text !== undefined) {
    return fragment.text;
  }
  if (fragment.$ === 'mrow' && fragment.children) {
    return fragment.children.map((child) => getTextRecursively(child)).join('');
  }
  return undefined;
};
