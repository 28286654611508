import { css, type FlattenInterpolation } from 'styled-components';
import colors from '../../../sass/colors';
import { ButtonKind } from '../types';
import { type StyledButtonProps } from '../Button';
import { isFilled, isNotTransparent, or, orEmpty, resolveColors, resolveCursor } from './utils';

interface ColorSchema {
  baseColor: string;
  color: string;
  border: string;
  fill?: string;
  borderSecondary?: string;
  background?: string;
}

const getDecorationForColorSchema = ({
  baseColor,
  color,
  border,
  fill = color,
  borderSecondary = border,
  background = border,
}: ColorSchema) => css<StyledButtonProps>`
  color: ${resolveColors(or(baseColor, color), isFilled)};
  fill: ${resolveColors(or(baseColor, fill), isFilled)};

  --btn-border-color: ${resolveColors(or(border, borderSecondary), isFilled)};

  ${resolveColors(orEmpty('border-color: var(--btn-border-color);'), isNotTransparent)}
  ${resolveColors(orEmpty(`background-color: ${background};`), isFilled)}
  ${resolveCursor}
`;

const decorationMap: Record<ButtonKind, FlattenInterpolation<StyledButtonProps>> = {
  [ButtonKind.abort]: getDecorationForColorSchema({
    baseColor: colors.cWhite,
    color: colors.cRed700,
    border: colors.cRed600,
  }),
  [ButtonKind.action]: getDecorationForColorSchema({
    baseColor: colors.cWhite,
    color: colors.cBlue700,
    border: colors.cBlue600,
  }),
  [ButtonKind.confirm]: getDecorationForColorSchema({
    baseColor: colors.cWhite,
    color: colors.cGreen700,
    border: colors.cGreen600,
  }),
  [ButtonKind.disabled]: getDecorationForColorSchema({
    baseColor: colors.cWhite,
    color: colors.cGray400,
    border: colors.cGray300,
  }),
  [ButtonKind.final]: getDecorationForColorSchema({
    baseColor: colors.cWhite,
    color: colors.cOrange800,
    fill: colors.cOrange700,
    border: colors.cOrange600,
  }),
  [ButtonKind.view]: getDecorationForColorSchema({
    baseColor: colors.cWhite,
    color: colors.cGray700,
    border: colors.cGray600,
  }),
  [ButtonKind.warning]: getDecorationForColorSchema({
    baseColor: colors.cGray800,
    color: colors.cYellow800,
    fill: colors.cYellow700,
    border: colors.cYellow500,
    borderSecondary: colors.cYellow600,
  }),
};

export const applyDecoration = ({ kind }: StyledButtonProps) => decorationMap[kind];
