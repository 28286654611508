import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonStretch, ButtonKind } from '@seriesplayer/common-ui';
import { BaseLayout, Content, DialogContent, DialogFooter } from '../../../../components';

export interface LoadingErrorDialogProps {
  onClick?: () => void;
}

/**
 * During series player loading, it's higly possible that exception
 * will be raized before we have a redux state.
 * This component should not be connected to redux state.
 */
export const LoadingErrorDialog: React.FC<LoadingErrorDialogProps> = ({ onClick }) => {
  const [t] = useTranslation();
  return (
    <BaseLayout vertical textCentered>
      <DialogContent as={Content}>
        <p className="plaintext">{t('errormessages:series.loadFailed')}</p>
        <DialogFooter align="center">
          <Button
            key="quit"
            kind={ButtonKind.action}
            onClick={onClick}
            stretch={ButtonStretch.responsive}
          >
            {t('seriesplayer:dialog.error.confirm')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </BaseLayout>
  );
};
