import { type GeoObject, type GeoObjectMap, HINT_EXPECTED } from '@bettermarks/gizmo-types';
import { resetSeverity } from '../helpers';

export const isSelected = (id: string, geoContentMap: GeoObjectMap<GeoObject>) =>
  geoContentMap[id].hint && geoContentMap[id].hint === HINT_EXPECTED;

export const isSelectable = (id: string, geoContentMap: GeoObjectMap<GeoObject>) =>
  geoContentMap[id].addedByUser || geoContentMap[id].selectable;

export const deselected = (object: GeoObject): GeoObject => {
  const { hint, hover, ...woutHint } = resetSeverity(object);
  return {
    ...woutHint,
    decoration: woutHint.originalDeco || {},
  };
};
