import {
  $CURSOR,
  $MABS,
  $MFENCED,
  $MFRAC,
  $MI,
  $MN,
  $MO,
  $MOVER,
  $MROOT,
  $MROW,
  $MSPACE,
  $MSQRT,
  $MSUB,
  $MSUBSUP,
  $MSUP,
  $MTEXT,
  $MUNDER,
  $MUNDEROVER,
  type Cursor,
  type Decoration,
  DEFAULT_MATH_CONTENT,
  DEFAULT_UNALIGNED,
  DEFAULT_UNSTYLED,
  type FenceType,
  isMO,
  isMRow,
  type MAbs,
  type MathContent,
  type MFenced,
  type MFrac,
  type MI as MIContent,
  type MN as MNContent,
  type MO as MOContent,
  type MOver,
  type MRoot,
  type MRow,
  type MSpace,
  type MSqrt,
  type MSub,
  type MSubSup,
  type MSup,
  type MText,
  type MToken$,
  type MUnder,
  type MUnderOver,
  ROUND_CLOSE,
  ROUND_OPEN,
} from '@bettermarks/gizmo-types';

export const FORMULA_CONTENT = DEFAULT_MATH_CONTENT;

export const MROW = (...children: MathContent[]): MRow => ({
  ...DEFAULT_UNSTYLED,
  ...DEFAULT_UNALIGNED,
  $: $MROW,
  children,
});
export const ensureMRow = (content: MathContent, interactive?: boolean) => {
  const mrow: MRow = isMRow(content) ? content : MROW(content);
  if (!interactive !== !mrow.interactive) {
    return { ...mrow, interactive };
  }
  return mrow;
};

export const MI = (text: string): MIContent => ({
  ...DEFAULT_UNSTYLED,
  $: $MI,
  text,
});
export const MO = (text: string): MOContent => ({
  ...DEFAULT_UNSTYLED,
  $: $MO,
  text,
});
export const MN = (text: string): MNContent => ({
  ...DEFAULT_UNSTYLED,
  $: $MN,
  text,
});
export const MTEXT = (
  text: string,
  decoration?: Decoration,
  dynamicDecoration?: string
): MText => ({
  ...DEFAULT_UNSTYLED,
  $: $MTEXT,
  text,
  ...(decoration && { decoration }),
  ...(dynamicDecoration && { dynamicDecoration }),
});

export const MTOKEN = ($: MToken$, text: string) => {
  switch ($) {
    case $MI:
      return MI(text);
    case $MN:
      return MN(text);
    case $MO:
      return MO(text);
    default:
      return MTEXT(text);
  }
};

export const MFENCED = (
  children: MathContent[],
  open: FenceType = ROUND_OPEN,
  close: FenceType = ROUND_CLOSE
): MFenced => ({
  ...DEFAULT_UNSTYLED,
  ...DEFAULT_UNALIGNED,
  $: $MFENCED,
  open,
  close,
  children,
  height: 5,
});

const implicitAccent = (overscript: MathContent): boolean | undefined =>
  isMO(overscript) || undefined;

export const MOVER = (
  base: MathContent,
  overscript: MathContent,
  accent = implicitAccent(overscript)
): MOver => ({
  ...DEFAULT_UNSTYLED,
  $: $MOVER,
  ...(accent !== undefined && { accent }),
  base: ensureMRow(base),
  overscript: ensureMRow(overscript),
});

export const MUNDER = (
  base: MathContent,
  underscript: MathContent,
  accent = implicitAccent(underscript)
): MUnder => ({
  ...DEFAULT_UNSTYLED,
  $: $MUNDER,
  // UI is currently not easy to adopt according to accent,
  // importing would have impact on scaling fontSize
  // everything is ready from types.ts, import and export
  // to get and use the data in the UI activate the next line and remove this comment
  // ...(accent !== undefined && {accent}),
  base: ensureMRow(base),
  underscript: ensureMRow(underscript),
});

export const MUNDEROVER = (
  base: MathContent,
  overscript: MathContent,
  underscript: MathContent,
  accent = implicitAccent(overscript)
): MUnderOver => ({
  ...DEFAULT_UNSTYLED,
  $: $MUNDEROVER,
  ...(accent !== undefined && { accent }),
  base: ensureMRow(base),
  overscript: ensureMRow(overscript),
  underscript: ensureMRow(underscript),
});

export const MSUP = (base: MathContent, superscript: MathContent): MSup => ({
  ...DEFAULT_UNSTYLED,
  $: $MSUP,
  base: ensureMRow(base),
  superscript: ensureMRow(superscript),
});

export const MSUB = (base: MathContent, subscript: MathContent): MSub => ({
  ...DEFAULT_UNSTYLED,
  $: $MSUB,
  base: ensureMRow(base),
  subscript: ensureMRow(subscript),
});

export const MSUBSUP = (base: MathContent, sub: MathContent, sup: MathContent): MSubSup => ({
  ...DEFAULT_UNSTYLED,
  $: $MSUBSUP,
  base: ensureMRow(base),
  subscript: ensureMRow(sub),
  superscript: ensureMRow(sup),
});

export const MFRAC = (numerator: MathContent, denominator: MathContent): MFrac => ({
  ...DEFAULT_UNSTYLED,
  $: $MFRAC,
  numerator: ensureMRow(numerator),
  denominator: ensureMRow(denominator),
  strokeWidth: 0,
});

export const MROOT = (index: MathContent, radicand: MathContent): MRoot => ({
  ...DEFAULT_UNSTYLED,
  $: $MROOT,
  index: ensureMRow(index),
  radicand: ensureMRow(radicand),
  strokeWidth: 0,
});

export const MSQRT = (radicand: MathContent): MSqrt => ({
  ...DEFAULT_UNSTYLED,
  $: $MSQRT,
  radicand: ensureMRow(radicand),
  strokeWidth: 0,
});

export const MABS = (value: MathContent): MAbs => ({
  ...DEFAULT_UNSTYLED,
  $: $MABS,
  value: ensureMRow(value),
  height: 5,
});
export const CURSOR: Cursor = { $: $CURSOR, height: 16 };
export const MSPACE: Readonly<MSpace> = { $: $MSPACE, lineHeight: 1 };
export const PLUS = MO('+');
