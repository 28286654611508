import {
  ContentDict,
  createExporterContext,
  exportContent,
  type ExporterRegistry,
} from '@bettermarks/gizmo-types';

export const exportContentTree = (
  exporters: ExporterRegistry,
  contentDict: ContentDict,
  root = ContentDict.root(contentDict)
) => exportContent(root, createExporterContext(exporters, contentDict));
