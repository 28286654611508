import isNil from 'lodash/isNil';
import { type BoundingRect } from 'react-measure';
import { type Bounds, POPUP_MARGIN, type PopupBounds } from './Popup';
/**
 * Method returns calculated left and right margin values for the popup and
 * for its arrow head (nose) based on triggered element position and
 * param `place`.
 *
 *
 * Below calculations are required when the TriggeredElement is at
 *  - Left/Right in the screen
 * or Popup content height is higher than Triggered Element height
 * to align the Popup container horizontally (Left/Right) as well as to adjust
 * the nose point at the middle of TriggerElement's Height
 *
 *
 * @param triggerBounds - A Element's boundaries which we clicked
 * @param PopupBounds - Popup's boundaries
 * @param place - popup's arrow direction
 */
export const calculateHorizontalBounds = (
  triggerBounds: BoundingRect,
  PopupBounds: BoundingRect,
  place: string
): Partial<PopupBounds> => {
  let noseBounds: Bounds = {};
  const popupMargin = 4;
  const {
    height: elementHeight,
    width: elementWidth,
    left: elementLeft,
    top: elementTop,
  } = triggerBounds;
  const { height: popupHeight, width: popupWidth } = PopupBounds;
  const halfObjectHeight = elementHeight / 2;
  const halfPopupHeight = popupHeight / 2;
  const boundLeft =
    place === 'left'
      ? elementLeft + elementWidth + popupMargin
      : elementLeft - popupWidth - popupMargin - POPUP_MARGIN;
  let boundTop;
  if (halfPopupHeight >= window.innerHeight - elementTop - halfObjectHeight) {
    boundTop = elementTop + elementHeight - popupHeight;
    noseBounds = {
      top: `calc(100% - ${halfObjectHeight}px)`,
    };
  } else if (elementTop + halfObjectHeight <= halfPopupHeight) {
    boundTop = elementTop;
    noseBounds = {
      top: halfObjectHeight,
    };
  } else {
    boundTop = elementTop + halfObjectHeight - halfPopupHeight;
  }
  return {
    bounds: {
      top: boundTop - POPUP_MARGIN,
      left: boundLeft,
    },
    noseBounds,
  };
};

/**
 * Method returns calculated left and right margin values for the popup and
 * for its arrow head (nose) based on triggered element position and
 * param `place`.
 *
 *Below calculations are required when the TriggeredElement is at
 *  - top/bottom at the screen
 * or Popup content width is higher than Triggered Element width
 * to align the Popup container Vertically (At top/Bottom) as well as to adjust
 * the nose point at the middle of TriggerElement's Width.
 *
 * @param triggerBounds - A Element's boundaries which we clicked
 * @param PopupBounds - Popup's boundaries
 * @param place - popup's arrow direction
 */
export const calculateVerticalBounds = (
  triggerBounds: BoundingRect,
  PopupBounds: BoundingRect,
  place: string
) => {
  const MAXSCREENWIDTH = 1200,
    popupMargin = 2;
  let noseBounds: Bounds = {};
  const {
    height: elementHeight,
    width: elementWidth,
    left: elementLeft,
    top: elementTop,
  } = triggerBounds;
  const { height: popupHeight, width: popupWidth } = PopupBounds;
  const docWidth = window.innerWidth < MAXSCREENWIDTH ? window.innerWidth : MAXSCREENWIDTH;
  const halfObjectWidth = elementWidth / 2;
  const halfPopupWidth = popupWidth / 2;
  const boundTop =
    place === 'top'
      ? elementTop + elementHeight + popupMargin
      : elementTop - popupHeight - popupMargin;
  let boundLeft;
  if (halfPopupWidth >= docWidth - elementLeft - halfObjectWidth) {
    boundLeft = elementLeft + elementWidth - popupWidth;
    noseBounds = {
      left: `calc(100% - ${halfObjectWidth}px)`,
    };
  } else if (elementLeft + halfObjectWidth <= halfPopupWidth) {
    boundLeft = elementLeft;
    noseBounds = {
      left: halfObjectWidth,
    };
  } else {
    boundLeft = elementLeft + halfObjectWidth - halfPopupWidth;
  }
  return {
    bounds: {
      top: boundTop,
      left: boundLeft - POPUP_MARGIN,
    },
    noseBounds,
  };
};

/**
 * Method returns calculated Horizontal or vertical bounds depends
 * on the place param
 */
export const calculatePopupBounds = (
  elementBounds: BoundingRect,
  popupBounds: BoundingRect,
  place: string
): PopupBounds | undefined => {
  let calculatedBounds,
    verticalPopup = false;
  const noseHeight = 4;
  if (place === 'left' || place === 'right') {
    calculatedBounds = calculateHorizontalBounds(elementBounds, popupBounds, place);
  } else {
    calculatedBounds = calculateVerticalBounds(elementBounds, popupBounds, place);
    verticalPopup = true;
  }
  const { height } = popupBounds;
  const { top: elementTop, height: elementHeight } = elementBounds;
  const popupMargin = verticalPopup ? POPUP_MARGIN + noseHeight : 2 * POPUP_MARGIN;
  const maxHeight = window.innerHeight - popupMargin - (elementTop + elementHeight);
  const popupHeight = height >= maxHeight ? maxHeight : 'auto';
  return {
    ...calculatedBounds,
    popupHeight,
  };
};

export const areBoundsAvailable = (bounds?: BoundingRect) =>
  !isNil(bounds) && bounds.width !== 0 && bounds.height !== 0;
