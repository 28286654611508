import * as React from 'react';
import { ToolpadBtnForm, ToolpadBtnGeo, ToolpadBtnTreeChart } from '../../../components/icons';
import {
  type EditorMode,
  FractionFormEditorMode,
  GraphEditorModes,
} from '@bettermarks/gizmo-types';

type ContentToolIconProps = {
  editorModes: EditorMode[];
};

type InteractiveEditorType = 'Geo' | 'FractionForm' | 'Graph';

const getEditorTypeFromModes = (modes: EditorMode[]): InteractiveEditorType | undefined => {
  if (modes.length) {
    // the graph editor always allows adding nodes
    if (modes?.includes(GraphEditorModes.Add)) {
      return 'Graph';
      // the fraction form editor always allows coloring parts
    } else if (modes?.includes(FractionFormEditorMode.ToggleFill)) {
      return 'FractionForm';
    }
    return 'Geo';
  }
  return undefined;
};

export const getTooltipText = (modes: EditorMode[]): string => {
  const editorType = getEditorTypeFromModes(modes);
  switch (editorType) {
    case 'Graph':
      return 'seriesplayer:dialog.whiteboard.graphTools.tooltipText';
    case 'FractionForm':
      return 'seriesplayer:dialog.whiteboard.fractionTools.tooltipText';
    default:
      return 'seriesplayer:dialog.whiteboard.geoTools.tooltipText';
  }
};

export function ContentToolIcon({ editorModes }: ContentToolIconProps): JSX.Element {
  const editorType = getEditorTypeFromModes(editorModes);
  switch (editorType) {
    case 'Graph':
      return <ToolpadBtnTreeChart width={30} height={30} />;
    case 'FractionForm':
      return <ToolpadBtnForm width={30} height={30} />;
    default:
      return <ToolpadBtnGeo width={30} height={30} />;
  }
}
