import * as React from 'react';
import { Beziers } from '../../components';
import { type BezierGroupObject } from '@bettermarks/gizmo-types';
import { type SetProps, type WithBorderRect } from './types';
import { isSelectableObject } from './setHelpers';

export type BezierSetProps = SetProps &
  WithBorderRect & {
    beziers: ReadonlyArray<string>;
    geoId: string;
  };

export const BezierSet: React.FC<BezierSetProps> = ({
  beziers,
  geoContentMap,
  matrix,
  mode,
  borderRectId,
  geoId,
  onHoverId,
  onMouseDownId,
  onMouseOutId,
  onClick,
}) => {
  const [onHover, onMouseDown, onMouseOut] = [onHoverId, onMouseDownId, onMouseOutId];
  return (
    <g clipPath={`url(#${borderRectId})`}>
      {beziers.map((id) => {
        const bezierData = geoContentMap[id] as BezierGroupObject;
        return (
          <Beziers
            key={id}
            {...{
              id,
              geoId,
              matrix,
              mode,
              onHover,
              onMouseDown,
              onMouseOut,
              onClick,
            }}
            {...bezierData}
            selectable={isSelectableObject(bezierData, mode)}
          />
        );
      })}
    </g>
  );
};

BezierSet.displayName = 'BezierSet';
