import { type ApplyStyles } from '../../gizmo-utils/configuration/types';
import { type IDynamicRepresentationContent } from '@bettermarks/gizmo-types';

export const applyDynamicRepresentationStyles: ApplyStyles = (
  {
    sliders,
    onOffControls,
    optionsControls,
    representation: { $refid: refId },
  }: IDynamicRepresentationContent,
  outerStyles
) => {
  return [
    {
      refId,
      style: outerStyles,
    },
    ...sliders
      .filter((slider) => slider.label !== undefined)
      .map((s) => ({
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        refId: s.label!.$refid,
        style: outerStyles,
      })),
    ...optionsControls
      .filter((c) => c.label !== undefined)
      .map((c) => ({
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        refId: c.label!.$refid,
        style: outerStyles,
      })),
    ...optionsControls
      .flatMap((c) => c.options)
      .map((o) => ({
        refId: o.$refid,
        style: outerStyles,
      })),
    ...onOffControls.map((c) => ({
      refId: c.label.$refid,
      style: outerStyles,
    })),
  ];
};
