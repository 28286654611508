import {
  type AlignItems,
  type Annotations,
  type ContentReference,
  DEFAULT_DRAG_AND_DROP_CONFIGURATION,
  type DragAndDropConfiguration,
  type FElement,
  type IFreeDragAndDrop,
  type IDragAndDrop,
  type Importer,
  type ImporterContext,
  xmlTagsToProperties,
  xmlText,
} from '@bettermarks/gizmo-types';
import log from 'loglevel';

const convertToAlignItems = (s: string): AlignItems => {
  if (s === 'left' || s === 'right' || s === 'bottom' || s === 'top') {
    return s;
  }
  return 'left';
};

const convertToTuple = (s: string, separator = '|'): [number, number] => {
  const element = s.split(separator);
  try {
    return [Number(element[0]), Number(element[1])];
  } catch (e) {
    return DEFAULT_DRAG_AND_DROP_CONFIGURATION.screenRatio;
  }
};

const parseConfiguration = (mrow: FElement): DragAndDropConfiguration => {
  if (!mrow.hasChild('configuration')) {
    log.error('Expected there to be an items tag in drag and drop gizmo.');
    return {
      alignItems: 'left',
      screenRatio: [40, 60],
    };
  }
  const config = mrow.findChildTag('configuration');

  const { alignItems, screenRatio } = xmlTagsToProperties(config.children, xmlText, [
    'alignItems',
    'screenRatio',
  ]);

  return {
    alignItems: convertToAlignItems(alignItems),
    screenRatio: convertToTuple(screenRatio),
  };
};

const parseContent = (mrow: FElement, context: ImporterContext): ContentReference => {
  if (!mrow.hasChild('content')) {
    log.error('Expected there to be a content tag in free drag and drop gizmo.');
    return { $refid: '' };
  }
  return context.importXML(mrow.findChildTag('content').firstChild);
};

const parseItems = (preContent: Annotations, mrow: FElement, context: ImporterContext) => {
  if (!mrow.hasChild('items')) {
    log.error('Expected there to be an items tag in free drag and drop gizmo.');
    return [];
  }
  const items = mrow.findChildTag('items').getChildrenByTagName('semantics');

  return items.map((item) => context.importXML(item));
};

export const importFreeDragAndDrop: Importer<IFreeDragAndDrop> = (preContent, xml, context) => {
  const dndImported = importDragAndDrop(preContent, xml, context);
  return {
    ...dndImported,
    // important for reset functionality. We need all positions to initial state.
    translations: dndImported.items.reduce(
      (acc, item) => ({ ...acc, [item.$refid]: { translateX: 0, translateY: 0 } }),
      {}
    ),
    droppedItems: [],
    inTarget: false,
  };
};

export const importDragAndDrop: Importer<IDragAndDrop> = (preContent, xml, context) => {
  const mrow = xml.findChildTag('mrow');
  return {
    ...preContent,
    items: parseItems(preContent, mrow, context),
    content: parseContent(mrow, context),
    configuration: parseConfiguration(mrow),
  };
};
