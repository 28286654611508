import { call, put } from 'redux-saga/effects';
import { focusGizmo } from '../../../gizmo-utils/focusGizmo';
import { type GizmoSagas } from '../../../gizmo-utils/redux/gizmoSaga';

import { Direction, type FT as T } from '@bettermarks/gizmo-types';
import { CURSOR_LEFT, CURSOR_RIGHT } from './actions';
import { gizmoAction, selectContentDirection } from '../../../gizmo-utils/redux';
import { resetShouldMoveCursor } from '../../../gizmo-utils/redux/gizmoActions';
import log from 'loglevel';

/**
 * This saga receives a formula which has already been processed
 * by the FormulaReducer. The FormulaReducer has determined whether
 * the cursor should jump to the formula's sibling, but it cannot do
 * the cursor jump itself, because that would be a side effect, which
 * is not wanted in reducers. Hence the FormulaReducer sends this
 * saga the information whether the cursor should jump in the
 * attribute `shouldMoveCursorToSibling`.
 *
 * Of course the cursor jump can only happen if the `leftSibling`
 * actually exists, which has been checked in {@link moveInsideBucket},
 * the function that decides whether to set `shouldMoveCursorToSibling` to true.
 *
 * And because `shouldMoveCursorToSibling` is only meant to transport
 * information from the FormulaReducer to this saga, it is reset as soon
 * as it has arrived here. Just to be safe.
 */
export function* moveLeftFormulaSaga(
  ownRefId: string,
  { shouldMoveCursorToSibling, leftSibling }: T.FormulaContent
) {
  if (shouldMoveCursorToSibling) {
    if (!leftSibling) {
      log.warn({
        message: `moveLeftFormulaSaga was called with shouldMoveCursorToSibling=true, but leftSibling is missing. (ownRefId: ${ownRefId})`,
      });
    } else {
      yield put(gizmoAction(resetShouldMoveCursor(), ownRefId));
      yield put(gizmoAction(selectContentDirection(Direction.Left), leftSibling));
      yield call(focusGizmo, leftSibling, document);
    }
  }
}

/**
 * This saga receives a formula which has already been processed
 * by the FormulaReducer. The FormulaReducer has determined whether
 * the cursor should jump to the formula's sibling, but it cannot do
 * the cursor jump itself, because that would be a side effect, which
 * is not wanted in reducers. Hence the FormulaReducer sends this
 * saga the information whether the cursor should jump in the
 * attribute `shouldMoveCursorToSibling`.
 *
 * Of course the cursor jump can only happen if the `rightSibling`
 * actually exists, which has been checked in {@link moveInsideBucket},
 * the function that decides whether to set `shouldMoveCursorToSibling` to true.
 *
 * And because `shouldMoveCursorToSibling` is only meant to transport
 * information from the FormulaReducer to this saga, it is reset as soon
 * as it has arrived here. Just to be safe.
 */
export function* moveRightFormulaSaga(
  ownRefId: string,
  { shouldMoveCursorToSibling, rightSibling }: T.FormulaContent
) {
  if (shouldMoveCursorToSibling) {
    if (!rightSibling) {
      log.warn({
        message: `moveRightFormulaSaga was called with shouldMoveCursorToSibling=true, but rightSibling is missing. (ownRefId: ${ownRefId})`,
      });
    } else {
      yield put(gizmoAction(resetShouldMoveCursor(), ownRefId));
      yield put(gizmoAction(selectContentDirection(Direction.Right), rightSibling));
      yield call(focusGizmo, rightSibling, document);
    }
  }
}

export const formulaSaga: GizmoSagas = {
  [CURSOR_LEFT]: moveLeftFormulaSaga,
  [CURSOR_RIGHT]: moveRightFormulaSaga,
};
