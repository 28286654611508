import { type Content } from '../../xml-converter/core';
import { type Validity } from '@bettermarks/umc-kotlin';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { NLP_TEXT } from '../../gizmo-utils/configuration/renderStyles';

export type NLPTextInputContent = Content & {
  configuration: NLPTextInputConfiguration;
  content: string;
  refId: string;
  validity?: Validity;
};

export enum NLPTextInputValidationType {
  passive = 'PASSIVE',
}

export type NLPTextInputConfiguration = {
  validationType: NLPTextInputValidationType;
  minInputWidth: number;
  referenceAnswers: string[];
};

export const NLP_TEXT_DEFAULT_CONTENT: NLPTextInputContent = {
  $: PLACEHOLDER,
  $renderStyle: NLP_TEXT,
  $interactionType: NLP_TEXT,
  configuration: {
    validationType: NLPTextInputValidationType.passive,
    minInputWidth: 30,
    referenceAnswers: [],
  },
  content: '',
  refId: '',
};

export const PIXEL_PER_CHAR = 8;
