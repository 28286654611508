import * as React from 'react';
import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';

import styles from './LinearizeRowTable.scss';
import { type RendererCell, type TableRendererProps } from '../types';
import { borderFromStyle, cellWidth } from '../helper';
import { DecoratedCell } from './DecoratedCell';
import { HAlign, TableStyle } from '@bettermarks/gizmo-types';

// In case of style "simple-table" (aka given-and-find table, aka "Gegeben, Gesucht Gizmo")
// we need to merge the last two cells in a row to avoid that the unit (last = 3rd cell) breaks
// below the input field (one but last = 2nd cell)
const mergeLastTwoCells = (
  idx: number,
  cells: RendererCell[],
  avCellWidth: number,
  needToLinearize: boolean
) => {
  const currC = cells[idx];
  const nextC = cells[idx + 1];
  return (
    <DecoratedCell
      key={idx}
      {...{
        ...currC,
        maxWidth: avCellWidth,
        ...(needToLinearize && {
          borderTop: undefined,
          borderRight: undefined,
          borderBottom: undefined,
          borderLeft: undefined,
          horizontalAlign: HAlign.left,
        }),
      }}
    >
      <span className={styles.simpleTable}>
        {currC.content && (
          <PolymorphicGizmo refid={currC.content.$refid} availableWidth={avCellWidth} />
        )}
        {nextC && nextC.content && (
          <PolymorphicGizmo refid={nextC.content.$refid} availableWidth={avCellWidth} />
        )}
      </span>
    </DecoratedCell>
  );
};

export const LinearizeRowTable: React.FC<TableRendererProps> = ({
  rows,
  columnPercents,
  maxRowWidth,
  percentWidth,
  availableWidth,
  borderStyle,
  style,
}) => {
  const maxWidth = percentWidth * maxRowWidth;

  const width = Math.min(maxWidth, availableWidth);
  const needToLinearize = maxWidth > availableWidth;

  const availableCellWidth = (colIdx: number) =>
    needToLinearize || !columnPercents
      ? width
      : cellWidth(columnPercents, colIdx, percentWidth, width);

  return (
    <table
      className={needToLinearize ? styles.linearizeRow : undefined}
      style={{
        maxWidth: width,
        border: borderFromStyle(borderStyle),
      }}
    >
      <tbody>
        {rows.map((r, i) => (
          <tr key={i}>
            {r.cells.map((c, j) => {
              const avCellWidth = availableCellWidth(j);
              return style === TableStyle.SIMPLE_TABLE && j > 0 ? (
                j === 1 && mergeLastTwoCells(j, r.cells, avCellWidth, needToLinearize)
              ) : (
                <DecoratedCell
                  key={j}
                  {...{
                    ...c,
                    maxWidth: avCellWidth,
                    ...(needToLinearize && {
                      borderTop: undefined,
                      borderRight: undefined,
                      borderBottom: undefined,
                      borderLeft: undefined,
                    }),
                  }}
                >
                  {c.content && (
                    <PolymorphicGizmo refid={c.content.$refid} availableWidth={avCellWidth} />
                  )}
                </DecoratedCell>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

LinearizeRowTable.displayName = 'LinearizeRowTable';
