import { type Action, handleAction } from 'redux-actions';
import { type ApplicationState } from '../../types';
import { initialSeriesplayerState } from '../store/constants';
import {
  INITIALIZE_DUMMY_ROWS,
  INITIATE_ROW_VALIDATION,
  INSERT_NEW_EMPTY_ROW,
  type InsertEmptyRowPayload,
  MAKE_ALL_ROWS_NONINTERACTIVE,
  RESET_SCAFFOLDER,
  ROW_VALIDATION_FINISHED,
  SHOW_SCAFFOLDER_SOLUTION,
  type ShowSolutionPayload,
} from './actions';
import {
  type Content,
  type ContentDict,
  type ContentKind,
  type FormulaContent,
  InputToolTypes,
  type MathContent,
  type MRow,
} from '@bettermarks/gizmo-types';
import { compose, omit } from 'lodash/fp';
import { MROW, MTEXT, removeCursor } from '@bettermarks/importers';
import { INTERACTIVE } from '../../../../importers/src/gizmos/formula/fixtures/helpers';
import { rowsFromState } from './ScaffoldingGizmoRoute';

export const handleResetScaffolder = handleAction(
  RESET_SCAFFOLDER,
  (state: ApplicationState) => ({
    ...state,
    scaffolder: initialSeriesplayerState.scaffolder,
  }),
  initialSeriesplayerState
);

export const handleInitializeDummyRows = handleAction(
  INITIALIZE_DUMMY_ROWS,
  (state: ApplicationState) => ({
    ...state,
    scaffolder: {
      transformations: {},
      ...state.scaffolder,
      rows: {
        ...state.scaffolder?.rows,
        ['dummy-1']: emptyRow(1, '', 'dummy'),
        ['dummy-2']: emptyRow(2, '', 'dummy'),
        ['dummy-4']: emptyRow(4, '', 'dummy'),
      },
    },
  }),
  initialSeriesplayerState
);

export const handleInsertNewEmptyRow = handleAction(
  INSERT_NEW_EMPTY_ROW,
  (state: ApplicationState, { payload }: Action<InsertEmptyRowPayload>) => {
    const existingRows = rowsFromState(state);
    const indexOfNewRow = existingRows ? Object.keys(existingRows).length : 0;

    const result = {
      ...state,
      scaffolder: {
        ...state.scaffolder,
        rows: {
          ...state.scaffolder?.rows,
          ...makeFormulasNoninteractive(rowsFromState(state)),
          [indexOfNewRow]: emptyRow(indexOfNewRow, payload.placeholderLabels.row, 'row'),
        },
        transformations: {
          ...makeFormulasNoninteractive(state.scaffolder?.transformations),
          [`transformations:${indexOfNewRow}`]: emptyRow(
            indexOfNewRow,
            payload.placeholderLabels.transformation,
            'transformation'
          ),
        },
      },
    };

    return result;
  },
  initialSeriesplayerState
);

// export const handleInsertNewRowWithContentFromPreviousRow = handleAction(
//   INSERT_NEW_ROW_WITH_CONTENT_FROM_PREVIOUS_ROW,
//   (state: ApplicationState) => {
//     const existingRows = state.scaffolder?.rows;
//     const indexOfNewRow = existingRows ? Object.keys(existingRows).length : 0;
//
//     return {
//       ...state,
//       scaffolder: {
//         ...state.scaffolder,
//         rows: {
//           ...makeFormulasNoninteractive(state.scaffolder?.rows),
//           [indexOfNewRow]: state.scaffolder?.rows[indexOfNewRow - 1],
//         },
//         transformations: {
//           ...makeFormulasNoninteractive(state.scaffolder?.transformations),
//           [indexOfNewRow]:
//             state.scaffolder?.transformations[`transformations:${indexOfNewRow - 1}`],
//         },
//       },
//     };
//   },
//   initialSeriesplayerState
// );

export const handleMakeAllRowsNoninteractive = handleAction(
  MAKE_ALL_ROWS_NONINTERACTIVE,
  (state: ApplicationState) => ({
    ...state,
    scaffolder: {
      ...state.scaffolder,
      rows: {
        ...state.scaffolder?.rows,
        ...makeFormulasNoninteractive(rowsFromState(state)),
      },
      transformations: makeFormulasNoninteractive(state.scaffolder?.transformations),
    },
  }),
  initialSeriesplayerState
);

const solutionRow = (rowContent: MathContent[], rowId: string) => ({
  $: 'mrow' as ContentKind,
  $renderStyle: 'text',
  $id: rowId,
  content: rowContent,
  childrenAlignments: [],
  computedStyles: {
    fontSize: 16,
    width: '540px',
    minWidth: '540px',
    maxWidth: '100%',
    display: 'block',
    boxSizing: 'border-box',
  },
});

export const handleShowSolution = handleAction(
  SHOW_SCAFFOLDER_SOLUTION,
  (state: ApplicationState, { payload }: Action<ShowSolutionPayload>) => {
    const numberOfRowKeys = Object.keys(rowsFromState(state)).length;
    const indexOfLastRow = numberOfRowKeys > 0 ? numberOfRowKeys - 1 : 0;

    return {
      ...state,
      scaffolder: {
        ...state.scaffolder,
        rows: {
          ...state.scaffolder?.rows,
          ...makeFormulasNoninteractive(rowsFromState(state), true),
          [indexOfLastRow]: solutionRow(payload.solutionContent, rowId(indexOfLastRow, false)),
          ...(!payload.isFinalSolution && {
            [indexOfLastRow + 1]: emptyRow(
              indexOfLastRow + 1,
              payload.placeholderLabels.row,
              'row'
            ),
          }),
        },
        transformations: {
          ...makeFormulasNoninteractive(state.scaffolder?.transformations, true),
          [`transformations:${indexOfLastRow}`]: solutionRow(
            payload.transformationContent,
            rowId(indexOfLastRow, true)
          ),
          ...(!payload.isFinalSolution && {
            [`transformations:${indexOfLastRow + 1}`]: emptyRow(
              indexOfLastRow + 1,
              payload.placeholderLabels.transformation,
              'transformation'
            ),
          }),
        },
      },
    };
  },
  initialSeriesplayerState
);

export const handleInitiateRowValidation = handleAction(
  INITIATE_ROW_VALIDATION,
  (state: ApplicationState) => ({
    ...state,
    ...(state.scaffolder?.rows && {
      scaffolder: {
        ...state.scaffolder,
        rowValidationNeeded: true,
      },
    }),
  }),
  initialSeriesplayerState
);

export const handleRowValidationFinished = handleAction(
  ROW_VALIDATION_FINISHED,
  (state: ApplicationState) => ({
    ...state,
    ...(state.scaffolder?.rows && {
      scaffolder: {
        ...state.scaffolder,
        rowValidationNeeded: false,
      },
    }),
  }),
  initialSeriesplayerState
);

const makeFormulasNoninteractive = (
  contentDict: ContentDict | undefined,
  skipLastRow?: boolean
) => {
  if (!contentDict) {
    return {};
  }

  const indexOfLastRow = Object.keys(contentDict).length - 1;
  const formulas = skipLastRow
    ? Object.entries(contentDict).slice(0, indexOfLastRow)
    : Object.entries(contentDict);

  return {
    ...formulas.reduce(
      (acc, [refId, content]) => ({
        ...acc,
        [refId]: compose(
          (c: Content) => ({
            ...c,
            $: 'mrow',
            interactive: false,
            content:
              content && (content as unknown as FormulaContent).content
                ? (
                    removeCursor(
                      MROW(...(content as unknown as FormulaContent).content)
                    ) as unknown as MRow
                  ).children
                : [],
          }),
          omit(['$interactionType', '$', 'tool'])
        )(content),
      }),
      {}
    ),
  };
};

type RowKind = 'row' | 'transformation' | 'dummy';

const rowId = (index: number, isTransformationRow: boolean) =>
  isTransformationRow ? `scaffolder-transformation-${index}` : `scaffolder-row-${index}`;

const emptyRow = (index: number, placeholderValue: string, rowKind: RowKind) => ({
  placeholder: true,
  placeholderLabel: INTERACTIVE(MROW(MTEXT(placeholderValue))),
  $: 'placeholder' as ContentKind,
  $renderStyle: 'text',
  $id: `scaffolder-${rowKind}-${index}`,
  $interactionType: 'text',
  content: [],
  childrenAlignments: [],
  computedStyles: {
    fontSize: 16,
    width: '540px',
    minWidth: '540px',
    maxWidth: '100%',
    display: 'block',
    boxSizing: 'border-box',
  },
  tool: {
    type: InputToolTypes.keyboard as InputToolTypes.keyboard,
    layout: {
      brackets: true,
      decimal_point: true,
      fraction: false,
      letters: true,
      numbers: true,
      operators: true,
    },
    selectedPage: 0,
  },
});
