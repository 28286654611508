import { isNil } from 'lodash';
import { type GizmoStyle, type ScaleProps } from '../../../../gizmo-utils/configuration';
import { type Decoration } from '@bettermarks/gizmo-types';
import { fontSizeFromScale } from '../../../../gizmo-utils/measure';
import { isUndefined } from 'util';

/**
 * Creates a new instance of `GizmoStyle` (used for applyStyles cycle) using the `outer` one,
 * and adopting it with `scaledFontSize`, `localStyles` and `scaleProps`,
 * that are relevant for the next level of MathContent or Gizmo.
 *
 * Will return `outer` if nothing changes for the next level.
 *
 * @param {Decoration | undefined} localStyles
 * @param {GizmoStyle} outer
 * @param {number} scaledFontSize
 * @param {ScaleProps} scaleProps
 * @returns {GizmoStyle}
 */
export const mergeGizmoStyle = (
  localStyles: Decoration | undefined,
  outer: GizmoStyle,
  scaledFontSize = outer.formulaStyles.fontSize,
  scaleProps: ScaleProps = outer.scaleProps
): GizmoStyle => {
  if (
    !localStyles &&
    scaledFontSize === outer.formulaStyles.fontSize &&
    scaleProps === outer.scaleProps
  ) {
    return outer;
  }

  let prepLocalStyles = localStyles;
  if (!isNil(scaleProps.scale)) {
    prepLocalStyles = {
      ...localStyles,
      // only need to scale local styles,
      // outer styles have already been scaled and should not be scaled as often as they are
      // inherited
      fontSize:
        localStyles && localStyles.fontSize
          ? fontSizeFromScale(
              scaleProps.scale,
              localStyles && localStyles.fontSize,
              scaleProps.minimumFontSize
            )
          : outer.formulaStyles.fontSize,
    };
  }

  return {
    formulaStyles: {
      ...outer.formulaStyles,
      fontSize: scaledFontSize,
      ...prepLocalStyles,
    },
    scaleProps: scaleProps,
    ...(!isUndefined(outer.disabled) && { disabled: outer.disabled }),
  };
};
