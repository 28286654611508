import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState, CollapsibleContentState } from '../../../../types';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { Layout } from '@seriesplayer/common-ui';
import { CollapsibleContentStyle, ContentBox } from '../../components';
import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import { useTranslation } from 'react-i18next';

type SupportStateSelection = {
  supports?: ReadonlyArray<ContentDict>;
  isTouch: boolean;
  staticMediaUrl: string;
};

const selectSupportState = (state: ApplicationState): SupportStateSelection => {
  const currentStep = ApplicationState.toCurrentStep.get(state);
  const {
    runtimeState: { isTouch },
    appSettings: { staticMediaUrl },
  } = state;
  return {
    supports: currentStep?.supports,
    isTouch,
    staticMediaUrl,
  };
};

/**
 * Support is specific kind of hint.
 *
 * It needs to be displayed **only** after a failing attempt.
 *
 * It must **not** be displayed when a user clicks on help button > Show hint
 *
 */
export const Support: React.FC<{ availableWidth: number }> = ({ availableWidth }) => {
  const { supports, isTouch, staticMediaUrl } = useSelector<
    ApplicationState,
    SupportStateSelection
  >(selectSupportState);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  return (
    <Layout vertical data-cy="feedback-support">
      {supports &&
        supports.map((support, i) => (
          <ContentBox
            key={i}
            title={t('seriesplayer:dialog.hints.title')}
            collapse={CollapsibleContentState.collapsed}
            collapsibleKind={CollapsibleContentStyle.hint}
            availableWidth={availableWidth}
            contentDict={support}
            gizmoRegistry={gizmoRegistry}
            dispatch={dispatch}
            isTouch={isTouch}
            staticMediaUrl={staticMediaUrl}
          />
        ))}
    </Layout>
  );
};
