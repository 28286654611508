import { defaultTo, isEmpty, last, get } from 'lodash';

import { EPS } from '../constants';

/**
 * round to nearest 'modulo N' with optional maximum not to be exceeded!
 * for example (N=5): 21 -> 20, 23 -> 25
 * or (n=5, max=18): 21 -> 15, 14 -> 15, 9 -> 10
 * @param {number} i .. a number
 * @param {number} n .. the modulus
 * @returns {number} .. the result
 */
export const rdN = (i: number, n: number, max = 0): number =>
  Math.min(max === 0 ? Infinity : max - (max % n), Math.round(i / n) * n);

/**
 * besides usual modulo operation, gives positive modulo results for
 * negative numbers (5) 13 -> 3, -13 -> 2
 * @param {number} a
 * @param {number} n
 * @param {number} m
 * @returns {number}
 */
export const mod = (a: number, n: number, m = a % n) => (m >= 0 ? m : n + m);

/**
 * builds the absolute sum of the signs of two numbers
 * @param a
 * @param b
 * @returns {number}
 */
export const sigSum = (a: number, b: number) => Math.abs(Math.sign(a) + Math.sign(b));

/**
 * transforms values like 0.8300000000000001 into 0.83
 * values like 0.83340001 or 3.456000100034 won't be touched since
 * the number and order of zeros is insignificant.
 * @param a
 * @returns {number}
 */
export const truncateIfTooManyZeros = (a: number): number => {
  const fractionalPart = get(a.toString().split('.'), '1', '');
  const zeros = get(fractionalPart.match(/(0+)/), '1', '');

  if (zeros.length && zeros.length > fractionalPart.length / 2) {
    const toFixedNumber = get(fractionalPart.match(/([1-9]+)0+/), '1.length', 0);
    return parseFloat(a.toFixed(toFixedNumber));
  }

  return a;
};

/**
 * rounds a number 'a' to a given number (n) of decimal places
 * @param {number} a
 * @param {number} n
 * @returns {number}
 */
export const round = (a: number, n: number, exp = Math.pow(10, n)) =>
  truncateIfTooManyZeros(Math.round(a * exp) / exp);

/**
 * returns only those numbers of a number array, which are 'numerically' unique.
 * For example: [0, 0.00001, 0.5] -> [0, 0.5]
 * @param a
 */
export const numuniq = (a: number[]): number[] =>
  [...a]
    .sort((a, b) => a - b)
    .reduce(
      (acc, a) => [
        ...acc,
        ...(isEmpty(acc) || Math.abs(defaultTo(last(acc), a) - a) > EPS ? [a] : []),
      ],
      []
    );

/**
 * returns the precision of the decimal number
 *
 * @param  {number} value
 * @return {number}
 */
function getPrecision(value: number): number {
  const stringValue: string[] = value.toFixed(20).split('.');
  return stringValue[1].replace(/0*$/, '').length;
}

/**
 * Rounds a number as per the step
 * Eg:- To round a number to nearest 0.5 value, use roundNumber(1.389, 0.5) -> 1.5
 *
 * @param  {number} value
 * @param  {number} step
 * @return {number}
 */
export function roundNumber(value: number, step = EPS): number {
  if (Math.abs(value) <= EPS) {
    return 0;
  }

  // return step as the minimum value instead of 0
  const precision = getPrecision(step);
  const rounderValue = value > 0 && value < step ? step : Math.round(value / step) * step;
  if (precision > 0) {
    return parseFloat(rounderValue.toFixed(precision));
  }
  return parseFloat(rounderValue.toFixed());
}

/**
 * decimal extension of modulo operation
 * @param {number} x
 * @param {number} m
 * @returns {number}
 */
export const decmod = (x: number, m: number) => roundNumber(x + m * Math.ceil(-x / m));
