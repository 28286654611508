/**#
 * a simple hash used for unique keys based on an object
 * @param obj
 * @returns {number}
 */
export const simpleHash = (obj: any): number =>
  JSON.stringify(obj)
    .split('')
    /* eslint-disable-next-line no-bitwise */
    .reduce((hash, chr) => (hash << 5) - hash + chr.charCodeAt(0), 0);
