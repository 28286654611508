import { numberFromStyles } from '@bettermarks/gizmo-types';
import styles from './Betty.scss';

export const ENTER_ANIMATION_TIME = numberFromStyles(styles.ENTER_ANIMATION_TIME) * 1000;
export const EXIT_ANIMATION_TIME = numberFromStyles(styles.EXIT_ANIMATION_TIME) * 1000;

export const BETTY_SUCCESS_TEXTS = [
  'seriesplayer:betty.successful_exercise.text1',
  'seriesplayer:betty.successful_exercise.text2',
  'seriesplayer:betty.successful_exercise.text3',
  'seriesplayer:betty.successful_test.text1',
  'seriesplayer:betty.successful_test.text2',
  'seriesplayer:betty.successful_test.text3',
];

export const SPRITE_SHEET = 'assets/images/bettySpriteSheet.png';

export const BETTY_ANIMATIONS = {
  appear: { start: 18, count: 29 },
  disappear: { start: 71, count: 17 },
  init: { start: 200, count: 1 },
};
