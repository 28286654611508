import {
  type Importer,
  RS,
  SPECIAL,
  type ValueStepperReferenceContent,
} from '@bettermarks/gizmo-types';

/**
 * imports the value stepper XML and parses it.
 * @param preContent
 * @param xml
 */
export const importValueStepperReference: Importer<ValueStepperReferenceContent> = (
  preContent,
  xml
) => {
  const mrow = xml.findChildTag('mrow');
  const binding = mrow.attribute('valueStepperReference');

  return {
    ...preContent,
    $: SPECIAL,
    $renderStyle: RS.VALUE_STEPPER,
    binding: binding,
  };
};
