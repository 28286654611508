import * as React from 'react';
import { Arrow, MARKER_WIDTH, ArrowDirection } from '../Arrow';
import styles from './Duration.scss';

type DurationProps = {
  /**
   * Direction of the arrow (left or right)
   */
  direction: ArrowDirection;

  /**
   * # of pixels between label and arrow.
   * # of pixels between the ground and the arrow line.
   *
   * ```
   *  // labelVerticalPadding=32, label is "7 Days"
   *
   *      7 Days
   *        '
   *       16px
   *        '
   *  ----------------->
   *        '
   *        16px
   * ```
   */
  labelVerticalPadding: number;

  /**
   * # of pixel at the left and right of the label above the arrow.
   * Note: Duration component add the arrow marker width automatically.
   * ```
   *  // labelHorizontalPadding=24, label is "0 h 24 min"
   *
   *   -24px-  0 h 24 min  -24px- // + arrow marker width
   *  ---------------------------->
   * ```
   */
  labelHorizontalPadding: number;
};

/**
 * `Duration` component
 *
 * ### Properties
 * | Name   | Type      | Default   | Description
 * |---     |---        |---        |---
 * | `direction` | 'left' or 'right' | - | The arrow direction
 * | `labelVerticalPadding`| `number` | - | Space (px) between the arrow and the label
 * |  |  |  | and above the arrow
 * | `labelHorizontalPadding` | `number` | - | Space (px) left and right from the label
 * |  | | | **without** arrow marker width
 *
 */
const Duration: React.FC<DurationProps> = ({
  direction,
  labelVerticalPadding,
  labelHorizontalPadding,
  children,
}) => {
  const isLeftDirection = direction === ArrowDirection.left;
  // To center correctly the text with the arrow line, we need to take
  // the arrow marker size in consideration.
  const extraPaddingLeft = isLeftDirection ? MARKER_WIDTH : 0;
  const extraPaddingRight = isLeftDirection ? 0 : MARKER_WIDTH;
  const labelExtraStyle = {
    paddingLeft: labelHorizontalPadding + extraPaddingLeft,
    paddingRight: labelHorizontalPadding + extraPaddingRight,
  };

  return (
    <div className={styles.duration}>
      <div style={labelExtraStyle}>{children}</div>
      <Arrow direction={direction} height={labelVerticalPadding * 2} />
    </div>
  );
};

export default Duration;
