import * as React from 'react';
import classNames from 'classnames';
import Measure, { type MeasuredComponentProps } from 'react-measure';

import { type DropdownState } from './constants';

import styles from './DropdownOption.scss';

export interface DropdownOptionProps {
  state: DropdownState;
  onItemClicked: (index: number, content: JSX.Element) => void;
  listItemRef: (n: HTMLLIElement) => void;
  lineBreak?: boolean;
  onResizeListItem: (clientRect: ClientRect) => void;
  dataCy?: string;
}

export const DropdownOption = ({
  lineBreak,
  listItemRef,
  onItemClicked,
  state,
  onResizeListItem,
  children,
  dataCy,
}: any) => (
  <Measure client={true} bounds={true} offset={true} onResize={onResizeListItem}>
    {({ measureRef }: MeasuredComponentProps) => (
      <li
        role="menuitem"
        className={classNames(styles.option, styles[state])}
        onClick={onItemClicked.bind(null, children)}
        ref={measureRef}
        data-cy={dataCy}
      >
        <div
          className={classNames(styles.optionContent, styles[state], {
            [styles.nowrap]: !lineBreak,
          })}
        >
          {children}
        </div>
      </li>
    )}
  </Measure>
);
