import { type ContextState, renderGizmos } from '../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';
import styles from './ClockAndTimeGizmo.scss';
import { type ClockTimeContent } from '@bettermarks/importers';

export type ClockAndTimeGizmoProps = ClockTimeContent & ContextState;

/**
 * Analogic `Clock` gizmo.
 *
 */
export const ClockAndTimeGizmo: React.FC<ClockAndTimeGizmoProps> = ({
  clock,
  time,
  availableWidth,
}) => <div className={styles.timeAndClock}>{renderGizmos([clock, time], availableWidth)}</div>;
