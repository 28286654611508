import { type Action, type Dispatch, type Middleware, type MiddlewareAPI } from 'redux';
import { isEmpty, noop } from 'lodash';
import { type ApplicationState, ErrorHandleKind } from '../../types';
import { BM_TOOLBOX_SERIES, setCookie } from '../../utils/cookie';

import { showErrorDialog } from '../seriesplayer/containers/SeriesPlayer/actions';
import { START_FROM_EXERCISE_ID, START_FROM_LOCAL_FILES } from '../seriesplayer/containers/Upload';
import { exceptionLog, ExceptionType, type Extra } from '../seriesplayer/services/logging';

import { lastThreeActions, toLogExtra } from './toLogExtra';
import { reduxStateFlowService } from './jsstore';

/**
 * Error handler middleware to log errors from reducers
 * and show an error dialog to the user.
 */

export interface ActionWithPayload extends Action {
  payload?: any;
}

type DispatchWithPayload = Dispatch<ActionWithPayload>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const errorHandler: Middleware<{}, ApplicationState, DispatchWithPayload> =
  (api: MiddlewareAPI<DispatchWithPayload, ApplicationState>) =>
  (next: DispatchWithPayload) =>
  (action: ActionWithPayload): ActionWithPayload => {
    try {
      if (process.env.NODE_ENV === 'development' && action.type === START_FROM_EXERCISE_ID) {
        localStorage && localStorage.removeItem(BM_TOOLBOX_SERIES);
        setCookie(BM_TOOLBOX_SERIES, action.payload);
      }

      if (process.env.NODE_ENV === 'development' && action.type === START_FROM_LOCAL_FILES) {
        setCookie(BM_TOOLBOX_SERIES, '');
        localStorage && localStorage.setItem(BM_TOOLBOX_SERIES, JSON.stringify(action.payload));
      }

      return next(action);
    } catch (error) {
      const state = api.getState();
      const extra = toLogExtra(state);
      // stringify action as logstash hates changing types.ts (payload in this case)
      let extraInfo: Extra = {
        lastAction: JSON.stringify(action),
        ...extra,
      };

      lastThreeActions(state)
        .then((lastThreeActions) => {
          extraInfo = {
            ...extraInfo,
            lastThreeActions,
          };
        })
        .catch(noop);

      exceptionLog(ExceptionType.reducer, error, extraInfo);
      return next(showErrorDialog({ kind: ErrorHandleKind.confirm }));
    }
  };

// eslint-disable-next-line @typescript-eslint/ban-types
export const persistStateHistory: Middleware<{}, ApplicationState, DispatchWithPayload> =
  (store: MiddlewareAPI<DispatchWithPayload, ApplicationState>) =>
  (next: DispatchWithPayload) =>
  (action: ActionWithPayload): ActionWithPayload => {
    const currentState = store.getState();
    if (!currentState.features.persistReduxStateFlow) {
      return next(action);
    }

    try {
      if (!isEmpty(currentState.series.id)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        reduxStateFlowService.instance?.update(currentState.series.id, currentState, action);
      }
    } catch (err) {
      // do nothing
    }
    return next(action);
  };
