import type * as React from 'react';
import { type Reducer } from 'redux-actions';
import {
  type AppendOnlyMap,
  type Content,
  type Exporter,
  type FormulaStyles,
  type Importer,
  type LayoutMetrics,
  type MetricsGetter,
  type StyleResolver,
} from '@bettermarks/gizmo-types';
import { type ReadonlyDict } from '../append-only';
import { type ContextState } from '../polymorphic-gizmo';
import { DEFAULT_FONT_SIZE } from '../measure';

export type GizmoRegistry = Readonly<{
  [key: string]: React.ComponentType<Content & ContextState>;
}>;

export type ExporterRegistry = ReadonlyDict<Exporter>;

export type ImporterRegistry = ReadonlyDict<Importer>;

export type Ruler<T extends Content = Content> = (
  outerStyles: FormulaStyles,
  content: T,
  getMetricsForRefId: MetricsGetter,
  getInnerStyle: StyleResolver
) => LayoutMetrics;

export type EnrichedContent<T> = LayoutMetrics &
  Readonly<{
    enrichedContent: T;
  }>;

export type MeasureMap = ReadonlyMap<string, LayoutMetrics>;

export type Enricher<T> = (
  outerStyles: FormulaStyles,
  content: T,
  getMetricsForRefId: MetricsGetter,
  getInnerStyle: StyleResolver
) => EnrichedContent<T>;

export type RulerRegistry = ReadonlyDict<Ruler | Enricher<Content>>;

export interface ReducerRegistry {
  readonly [key: string]: Reducer<any, any>;
}

export type ScaleProps = {
  fractionScaling?: true;
  minimumFontSize?: number;
  scale?: number;
};

export type GizmoStyle = Readonly<{
  formulaStyles: Readonly<FormulaStyles>;
  scaleProps: Readonly<ScaleProps>;
  /**
   * if `undefined`: use disabled in content,
   * if `true`: disable all content,
   * if `false`: remove disabled from all content
   */
  disabled?: boolean;
}>;

export const DEFAULT_GIZMO_STYLE = (custom?: Partial<GizmoStyle> | false): GizmoStyle => {
  let formulaStyles: FormulaStyles = { fontSize: DEFAULT_FONT_SIZE };
  if (custom && custom.formulaStyles) {
    formulaStyles = custom.formulaStyles;
  }
  return { scaleProps: {}, ...custom, formulaStyles };
};

export type GizmoStyleForRefId = Readonly<{
  refId: string;
  style: GizmoStyle;
  innerStyles?: ReadonlyMap<string, FormulaStyles>;
}>;

/**
 * A function that returns a list of all inner ContentReferences with the according styling applied.
 * "Apply" means that the correct GizmoStyle is connected to a refId (from ContentReference)
 *
 * If the gizmo does not change any styling, it just passes on `outerStyles` to it's inner gizmos.
 */
export type ApplyStyles = (
  content: Content,
  outerStyles: GizmoStyle,
  innerStyles: AppendOnlyMap<FormulaStyles>
) => GizmoStyleForRefId[];

export type ApplyStylesRegistry = ReadonlyDict<ApplyStyles>;
