import * as React from 'react';
import { expectType, type TypeOf } from 'ts-expect';
import { useTranslation } from 'react-i18next';
import { type BoundingRect, type ContentRect } from 'react-measure';
import { useMediaQuery } from 'react-responsive';
import {
  Bar,
  BarStyle,
  BorderThickness,
  Button,
  ButtonKind,
  ButtonSize,
} from '@seriesplayer/common-ui';

import { Icon } from '../../../../components/Icon';
import { SeriesMode } from '../../../../types';

import { NAVBAR_WIDTH, VERY_SMALL_SCREEN_HEIGHT, VERY_SMALL_SCREEN_WIDTH } from '../../constants';
import { HelpMenuContainer } from '../Dialog';
import { HeaderLarge } from './HeaderLarge';
import { HeaderSmall } from './HeaderSmall';
import { type HeaderPropsFromRedux } from './HeaderContainer';

export interface HeaderProps {
  closeButton: boolean;
  closeSeriesPlayer: (currentISODateString: string) => void;

  /** The current exercise number starting from 1 */
  currentExercise: number;
  hideHelpTools: boolean;
  mode: SeriesMode;
  nextExercise: () => void;
  numExercises: number;
  previousExercise: () => void;
  progress: number;
  reportProblemButton: boolean;
  seriesTitle: undefined | string;
  showExerciseNavigation: () => void;
  showHandInConfirmation: () => void;
  showHelp: () => void;
  showReportProblem: () => void;
}

export type HeaderCommonProps = {
  onClose: (evt: React.MouseEvent<any>) => void;
};

// validating the contract between `HeaderContainer` and `Header`
expectType<TypeOf<HeaderPropsFromRedux, HeaderProps>>(true);

// Based on discussion on: http://trac.bm.loc/ticket/43289#comment:4
const LARGE_HEADER_TEXT_BOUNDS = 720 - NAVBAR_WIDTH;

export default function Header({
  closeButton,
  closeSeriesPlayer,
  currentExercise,
  hideHelpTools,
  mode,
  nextExercise,
  numExercises,
  previousExercise,
  progress,
  reportProblemButton,
  seriesTitle,
  showExerciseNavigation,
  showHandInConfirmation,
  showHelp,
  showReportProblem,
}: HeaderProps): JSX.Element {
  const [t] = useTranslation();
  const isLargeScreen = useMediaQuery({
    minWidth: VERY_SMALL_SCREEN_WIDTH,
    minHeight: VERY_SMALL_SCREEN_HEIGHT,
  });
  const [showReportProblemText, setShowReportProblemText] = React.useState(true);
  const [helpButtonBounds, setHelpButtonBounds] = React.useState<BoundingRect>();
  const helpButtonRef = React.useRef<HTMLDivElement>(null);
  // This listens for changes in size of large header to provide bounds of Help button for
  // position the help menu.
  const onResize = ({ bounds }: ContentRect): void => {
    if (helpButtonRef.current) {
      /*
        @ts-expect-error
        @seriesplayer/common-ui/Button does not properly expose the underling
        DOM element attribute: e.g. `getBoundingClientRect` missing from the type
        declaration.
       */
      // @ts-ignore
      setHelpButtonBounds(helpButtonRef.current.getBoundingClientRect());
      setShowReportProblemText(!!(bounds && bounds.width > LARGE_HEADER_TEXT_BOUNDS));
    }
  };

  const headerButton =
    mode === SeriesMode.test ? (
      <Button
        id="hand-in-btn"
        kind={ButtonKind.final}
        size={ButtonSize.l}
        onClick={showHandInConfirmation}
        buttonRef={helpButtonRef}
      >
        {t('seriesplayer:header.handIn')}
      </Button>
    ) : !hideHelpTools ? (
      <Button
        id="help-btn"
        dataCy="help-btn"
        kind={ButtonKind.action}
        size={ButtonSize.l}
        onClick={showHelp}
        buttonRef={helpButtonRef}
      >
        {(Wrapper: React.ComponentType) => (
          <>
            <Wrapper>
              <Icon iconKey="LifeRingXLarge" />
            </Wrapper>
            <Wrapper>{t('seriesplayer:header.help')}</Wrapper>
          </>
        )}
      </Button>
    ) : undefined;

  return (
    <Bar borderThickness={BorderThickness.THICK} barStyle={BarStyle.DARK}>
      {isLargeScreen ? (
        <HeaderLarge
          {...{
            closeButton,
            numExercises,
            seriesTitle,
            showReportProblemText,
          }}
          onClose={() => closeSeriesPlayer(new Date().toISOString())}
          onResize={onResize}
          currentExercise={currentExercise + 1}
          showReportProblem={showReportProblem}
          reportProblemButton={reportProblemButton}
        >
          {headerButton}
        </HeaderLarge>
      ) : (
        <HeaderSmall
          {...{
            closeButton,
            mode,
            numExercises,
            progress,
          }}
          currentExercise={currentExercise + 1}
          nextExercise={nextExercise}
          onClose={() => closeSeriesPlayer(new Date().toISOString())}
          previousExercise={previousExercise}
          showExerciseNavigation={showExerciseNavigation}
          showReportProblem={showReportProblem}
          reportProblemButton={reportProblemButton}
        >
          {headerButton}
        </HeaderSmall>
      )}
      {!hideHelpTools && <HelpMenuContainer elementBounds={helpButtonBounds} />}
    </Bar>
  );
}
