import * as React from 'react';
import { getMediaUrl } from '@bettermarks/importers';
import { type ContextState, useStaticMediaUrl } from '../../gizmo-utils/polymorphic-gizmo';
import { type PdfLinkContent } from '@bettermarks/gizmo-types';
import PdfLink from '../../components/PdfLink/PdfLink';

export type PdfLinkProps = PdfLinkContent & ContextState;

/**
 * Connects the [[components/PdfLink]] UIComponent to [[PdfLinkContent]].
 *
 * Props:
 *  - text: the text of the link/button
 *  - src: the pdf link source (this link will open in a new tab)
 */
export default function PdfLinkGizmo({ source, text }: PdfLinkProps): JSX.Element {
  const staticMediaUrl = useStaticMediaUrl();

  const pdfLinkUrl = getMediaUrl(source, staticMediaUrl);

  return <PdfLink src={pdfLinkUrl} text={text} />;
}

PdfLinkGizmo.displayName = 'PdfLinkGizmo';
