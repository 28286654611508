import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';
import { DialogFooter } from '../../../../../../components';
import { Content } from './Content';

type ConfirmationProps = {
  onCancel: () => void;
};

const enum TranslationKey {
  confirmationTitle = 'seriesplayer:dialog.reportProblem.confirmation.title',
  confirmationBody = 'seriesplayer:dialog.reportProblem.confirmation.body',
  confirmationOk = 'seriesplayer:dialog.reportProblem.confirmation.ok',
}

export const Confirmation: React.FC<ConfirmationProps> = ({ onCancel }) => {
  const [t] = useTranslation();

  return (
    <>
      <Content>
        <p className="boldtext">{t(TranslationKey.confirmationTitle)}</p>
        <p className="plaintext">{t(TranslationKey.confirmationBody)}</p>
      </Content>
      <DialogFooter align="center">
        <Button
          key="cancel"
          kind={ButtonKind.action}
          stretch={ButtonStretch.responsive}
          onClick={onCancel}
        >
          {t(TranslationKey.confirmationOk)}
        </Button>
      </DialogFooter>
    </>
  );
};
