import { reduxStateFlowService } from '../../../../store/jsstore';
export const getReduxStateFlow = async (seriesId: string): Promise<string | undefined> => {
  if (!reduxStateFlowService.instance) return undefined;

  const series = await reduxStateFlowService.instance.getSeries(seriesId);

  if (series.length === 0) return undefined;

  const { preloadedState, actions } = series[0];

  return JSON.stringify({
    preloadedState: JSON.stringify(preloadedState),
    payload: JSON.stringify(actions),
  });
};
