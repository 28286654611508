import {
  ANNOTATION_XML,
  type Arrow,
  type Content,
  type FElement,
  type Importer,
  type ImporterContext,
  parseCommonAttribs,
  RENDER_STYLE,
  RS,
  SEMANTICS,
  switchMap,
  TABLE_CONTAINER_DEFAULT_CONTENT,
  type TableContainerContent,
  type TableContent,
  TableOrientation,
  toBoolean,
} from '@bettermarks/gizmo-types';
import { importSimpleTable } from './importer';

type IdToCellMap = { [id: string]: [number, number] };

export const mapIdsToCells = (table: TableContent, context: ImporterContext): IdToCellMap =>
  table.rows.reduce(
    (outer, r, i) =>
      r.cells.reduce((inner, c, j) => {
        const content = !!c.content && (context.content.get(c.content.$refid) as Content);
        return content && content.$id ? { ...inner, [content.$id]: [i, j] } : inner;
      }, outer),
    {}
  );

/**
 * For flexclient and generator it is:
 *      N
 * E |table| W
 *      S
 *
 * We want:
 *      N
 * W |table| E
 *      S
 */
export const swapEastWest = switchMap<TableOrientation>(
  {
    [TableOrientation.west]: TableOrientation.east,
    [TableOrientation.east]: TableOrientation.west,
    [TableOrientation.south]: TableOrientation.south,
    [TableOrientation.north]: TableOrientation.north,
  },
  TableOrientation.north
);

const importArrow = (xml: FElement, context: ImporterContext, idToCell: IdToCellMap): Arrow => {
  const mrow = xml.findChildTag('mrow');
  const label = context.importXML(mrow.findChildTag(SEMANTICS));
  const from = mrow.getPath(['configuration', 'from']);
  const to = mrow.getPath(['configuration', 'to']);

  return {
    annotationInner: parseCommonAttribs(xml), // needed for seamless re-export
    orientation: swapEastWest(mrow.getPath(['configuration', 'orientation']).text),
    label,
    from: {
      id: from.attribute('semantics-ref-id'),
      head: toBoolean(from.attribute('arrowHead')),
      coords: idToCell[from.attribute('semantics-ref-id')] || [0, 0],
    },
    to: {
      id: to.attribute('semantics-ref-id'),
      head: toBoolean(to.attribute('arrowHead')),
      coords: idToCell[to.attribute('semantics-ref-id')] || [0, 0],
    },
  };
};

export const importTableContainer: Importer = (preContent, xml, context): TableContainerContent => {
  const content = xml.findChildTag('mrow');
  const [tableXML] = content.filterChildren(
    (child) =>
      child.findChildTag(ANNOTATION_XML).firstChild.attribute(RENDER_STYLE) === RS.SIMPLE_TABLE
  );
  const table = context.invoke(importSimpleTable, tableXML);

  const formulaIdToCell = mapIdsToCells(table, context);
  const arrows = content
    .filterChildren(
      (child) =>
        child.findChildTag(ANNOTATION_XML).firstChild.attribute(RENDER_STYLE) ===
          RS.ARROW_WITH_LABEL ||
        child.findChildTag(ANNOTATION_XML).firstChild.attribute(RENDER_STYLE) ===
          RS.ARROW_WITH_LABEL_HORIZONTAL
    )
    .map((a) => importArrow(a, context, formulaIdToCell));

  return {
    ...TABLE_CONTAINER_DEFAULT_CONTENT,
    ...preContent,
    arrows,
    table,
  };
};
