// Actions implemented for Adobe HTML5 Canvas animations

/**
 * general mechanism to communicate between document and embedded iframe in a specified
 * way without running into CORS issues!
 */
const postMessage = (
  iframe: HTMLIFrameElement,
  frameUuid: string,
  msg: { fun: string; params?: any[] }
) => {
  const win = iframe.contentWindow;
  if (win) {
    win.postMessage({ frameUuid, ...msg }, '*');
  }
};

/**
 * Initializes the whole animation by setting the 'onTick' callback.
 * For further details, refer to the createJs documentation!
 * @param iframe
 */
export const initialize = (iframe: HTMLIFrameElement, frameUuid: string) =>
  postMessage(iframe, frameUuid, { fun: 'initOnTick' });

/**
 *  Sets the animation: display first (or specified) frame and pause!
 * @param iframe
 * @param frame
 */
export const set = (iframe: HTMLIFrameElement, frameUuid: string, frame = 0) =>
  postMessage(iframe, frameUuid, { fun: 'set', params: [frame] });

/**
 * Fires a meesage 'requestProgress' to the iframe and expects a
 * 'responseProgress' message from the iframe (handled) at a different place.
 * The resulting progress will be a tuple [a, b] with 'a' representing the
 * current frame and 'b' representing the max frame number of the
 * animation.
 * @param iframe
 */
export const requestProgress = (iframe: HTMLIFrameElement, frameUuid: string) =>
  postMessage(iframe, frameUuid, { fun: 'requestProgress' });

/**
 * Actually 'plays' the animation
 * @param iframe
 */
export const play = (iframe: HTMLIFrameElement, frameUuid: string) =>
  postMessage(iframe, frameUuid, { fun: 'play' });

/**
 * Actually 'pauses' the animation
 * @param iframe
 */
export const pause = (iframe: HTMLIFrameElement, frameUuid: string) =>
  postMessage(iframe, frameUuid, { fun: 'pause' });
