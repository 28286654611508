import { type FontMetric, type FontStyle, type FontWeight } from '@bettermarks/bm-font';
import { type EnrichedContent } from '../../../../gizmo-utils/configuration';
import { zeroNaN } from '../../../../gizmo-utils/filters';
import { DEFAULT_FONT_SIZE } from '../../../../gizmo-utils/measure';
import {
  type FontProps,
  type FormulaStyles,
  type LayoutMetrics,
  type NumberOrTuple,
  toNumberTuple,
} from '@bettermarks/gizmo-types';
import { defaultTo } from 'lodash';
import { getFontMetric } from '../../../../utils/fontMetric';

import { DEFAULT_FONT_WEIGHT } from './constants';
import { mapToComputedStyles } from './mapToComputedStyles';
import { type SelectChildHandler, type SelectHandler, Side } from './types';

export const maxRefLine = (cs: ReadonlyArray<LayoutMetrics>) =>
  cs.length > 0 ? Math.max(...cs.map((c) => zeroNaN(c.refLine))) : 0;
export const maxTopPart = (cs: ReadonlyArray<LayoutMetrics>) =>
  cs.length > 0 ? Math.max(...cs.map((c) => zeroNaN(c.height - c.refLine))) : 0;

/**
 * The `LayoutMetrics` that allows all elements in `metrics` to fit inside.
 * (The vertical part of the bounding box.)
 *
 * The `relativeToBaseLine` value of the result can be provided using the second argument.
 */
export const boundingLayoutMetrics = (
  metrics: ReadonlyArray<LayoutMetrics>,
  relativeToBaseLine?: true,
  minimum?: LayoutMetrics
): LayoutMetrics => {
  const withMinimum = minimum ? [...metrics, minimum] : metrics;
  const refLine = maxRefLine(withMinimum);
  const [left] = toNumberTuple(metrics.length > 0 ? metrics[0].padding : 0);
  const [, right] = toNumberTuple(metrics.length > 0 ? metrics[metrics.length - 1].padding : 0);
  const padding: NumberOrTuple = left === right ? left : [left, right];
  return {
    height: refLine + maxTopPart(withMinimum),
    refLine,
    ...(relativeToBaseLine && { relativeToBaseLine }),
    ...(padding !== 0 && { padding }),
  };
};

/**
 * In certain scenarios, we are just interest in the maximum height from all the children.
 * @param {ReadonlyArray<LayoutMetrics>} metrics
 * @return {number}
 */
export const getMaximumHeightFromChildren = (metrics: ReadonlyArray<LayoutMetrics>): number => {
  return metrics.reduce((maxHeightFound, metric) => {
    return Math.max(maxHeightFound, metric.height);
  }, 0);
};

/**
 * Calculate the vertical alignment of a certain element according to its refLine.
 * The vertical alignment depends on the difference between the baseline of this element relative to
 * the baseline of the current font (as represented by the passed fontMetric).
 * An element with the same refLine as the current font is already on the baseline and doesn't need
 * to be pushed any further.
 */
export const calculateYOffset = (fontMetric: FontMetric, refLine: number) =>
  fontMetric.refLineFromBaseLine - refLine;

/**
 * This is a helper function used by all math enrichers. It gets the enriched content and the
 * bounding layout metrics and an optional "formulaStyle".
 *
 * It returns an EnrichedContent object that has the enriched content as the property
 * enrichedContent and the layout metrics.
 *
 * If the formulaStyle is passed to it, then the mapped styles (from decoration) are added to the
 * enrichedContent in the property "computedStyles".
 *
 * @param {T} content
 * @param {LayoutMetrics} metrics
 * @param {FormulaStyles} formulaStyle
 * @returns {EnrichedContent<T>}
 */
export const enrich = <T>(
  content: T,
  metrics: LayoutMetrics,
  formulaStyle?: FormulaStyles
): EnrichedContent<T> => ({
  ...metrics,
  enrichedContent: !formulaStyle
    ? content
    : {
        ...content,
        computedStyles: mapToComputedStyles(formulaStyle),
        ...(formulaStyle.interactive ? { interactive: true } : undefined),
      },
});

/**
 * If we hit an element in the first half of it's glyph, we insert the cursor
 * in front of it, otherwise it's appended to the element.
 */
export const isInsertClick = (evt: React.MouseEvent<HTMLElement>) => {
  const rect = evt.currentTarget.getBoundingClientRect();
  return evt.pageX - rect.left < rect.width / 2;
};

export const onClickLeaf: (
  onSelect?: SelectHandler
) => React.MouseEventHandler<HTMLElement> | undefined = (onSelect) =>
  onSelect
    ? (evt) => {
        if (evt.target === evt.currentTarget) {
          let parent: HTMLElement | null = evt.currentTarget;
          while (parent && parent.tabIndex !== 0) {
            parent = parent.parentElement;
          }
          setTimeout(() => {
            if (parent) {
              parent.focus();
            }
          }, 0);
          onSelect(isInsertClick(evt) ? Side.Front : Side.Rear);
        }
      }
    : undefined;

/**
 * Call this when you clicked between the children of an mrow or mfenced. It
 * will try to calculate the index of the next element after the gap and set
 * path mode to "Insert".
 */
export const onClickBetweenChildren: (
  s?: SelectChildHandler
) => React.MouseEventHandler<HTMLElement> | undefined = (onSelect) =>
  onSelect
    ? (evt) => {
        if (evt.target === evt.currentTarget) {
          const tgt = evt.currentTarget;
          const rect = tgt.getBoundingClientRect();
          const pos = evt.pageX - rect.left;
          const idx = Array.from(tgt.childNodes)
            .map((el: HTMLElement) => el.getBoundingClientRect().width)
            .reduce(
              // create array of accumulated sums of each width
              (acc: number[], cur: number) => {
                // eslint-disable-next-line no-restricted-globals
                acc.push(((acc.length && acc[length - 1]) || 0) + cur); // push sum with previous value
                return acc;
              },
              []
            )
            .findIndex((w) => w >= pos);
          onSelect(idx, Side.Front);
        }
      }
    : undefined;

/**
 * Provides the `LayoutMetrics` according to `fontSize` argument.
 *
 * As text tokens are positioned relative to their base line by default,
 * this method also sets returns `relativeToBaseLine` set to `true`.
 */
export const getFontLayoutMetrics = (
  fontSize = DEFAULT_FONT_SIZE,
  fontWeight = DEFAULT_FONT_WEIGHT,
  fontStyle?: FontStyle
): LayoutMetrics => {
  const { refLine, height } = getFontMetric(fontSize, fontWeight, fontStyle);
  return { refLine, height, relativeToBaseLine: true };
};

/**
 * Creates combined FontProps from default values and existing instance (`input`).
 * The instance can be `undefined` or a partial.
 *
 * @param {Partial<FontProps>} input instance to use values from when defined
 * @param {number} fontSize value to use when not part of `input`, defaults to `DEFAULT_FONT_SIZE`
 * @param {string} fontWeight value to use when not part of `input`,
 *                            defaults to `DEFAULT_FONT_WEIGHT`
 *
 * @returns {{fontSize: number, fontWeight: FontWeight}}
 *          (which is `FontProps` with non optional fontWeight)
 */
export const getFontProps = (
  input: Partial<FontProps> = {},
  fontSize = DEFAULT_FONT_SIZE,
  fontWeight = DEFAULT_FONT_WEIGHT
) => ({
  fontSize: defaultTo<number>(input.fontSize, fontSize),
  fontWeight: defaultTo<FontWeight>(input.fontWeight, fontWeight),
});
