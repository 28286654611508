import * as React from 'react';
import { useEffect } from 'react';

import { type ContextState, PolymorphicGizmo } from '../../gizmo-utils/polymorphic-gizmo';
import { type CRIContent } from '../../types/CRI';
import { dimensions } from '@seriesplayer/common-ui';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import type { ApplicationState } from '../../types';
import type { ContentDict } from '@bettermarks/gizmo-types';
import { postToWhiteboardParent } from '../iframe-posts';

export type CRIProps = CRIContent & ContextState;

const CONTENT_MARGIN = dimensions.spaceS;

export default function CRI({
  setting,
  canvasConfiguration,
  availableWidth,
  studentTasks,
}: CRIProps) {
  const contentDict: ContentDict | undefined = useSelector(
    (state: ApplicationState) => state.classroomIntro?.data?.present
  );

  const availableWidthInCRI = availableWidth - 2 * parseFloat(CONTENT_MARGIN);

  useEffect(() => {
    if (contentDict) postToWhiteboardParent({ type: 'classroomIntro', contentDict, studentTasks });
  }, [contentDict, studentTasks]);

  return (
    <CRIContainer>
      {setting && <PolymorphicGizmo refid={setting.$refid} availableWidth={availableWidthInCRI} />}
      <PolymorphicGizmo refid={canvasConfiguration.$refid} availableWidth={availableWidthInCRI} />
    </CRIContainer>
  );
}

const CRIContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin: ${CONTENT_MARGIN};
  }
`;
