import * as React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import { useContentTranslation } from '../../../../gizmo-utils/polymorphic-gizmo';
import { type Dispatch } from '../../../../gizmo-utils/redux/types';

import { DragNDropContextProvider } from '../../../../gizmos/drag-and-drop/context';
import { type AppStep, type SeriesFlow, SeriesMode, StepStatus } from '../../../../types';
import { CollapsibleContentStyle, ContentBox, StepHeader } from '../../components';
import { useAudio } from '../../providers/Audio';
import { StepFeedbacks } from '../StepFeedbacks/StepFeedbacks';
import { STEP_CONTENT_PADDING } from './constants';
import {
  getContentDictForQuestion,
  getSoundToPlay,
  isCurrentAbortedStep,
  isEmptyInstruction,
  isExplanationInactive,
  showFeedback,
} from './helper';
import styles from './step.scss';
import { StepContentReview } from './StepContentReview';
import { BettyContainer } from '../Betty';
import { ContextNotification, ContextNotificationKind } from '@seriesplayer/common-ui';
import { getIconByKey, Nothing } from '../../../../components';

const ContextNotificationWrapper = styled.div<{ $withMarginTop: boolean }>`
  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: ${styles.INSTRUCTIONS_HELP_MARGIN_TOP};
    `}
`;

export interface StepComponentCallbacks {
  readonly onCloseBetty?: () => void;
}

export enum StepTitleTranslationKey {
  hint = 'seriesplayer:step.hint.title',
  answer = 'seriesplayer:step.answer.title',
  expectedAnswer = 'seriesplayer:step.expectedAnswer.title',
  explanation = 'seriesplayer:step.explanation.title',
  wrapup = 'seriesplayer:step.wrapup.title',
}

export interface IStepCompontentProps {
  step: AppStep;
  stepIndex: number;
  showAdditionalFeedback?: boolean;
  showBetty?: boolean;
  availableWidth: number;
  currentStepId?: string;
  exerciseId: string;
  collapseLastStep?: boolean;
  hideHelpTools?: boolean;
  handIn?: boolean;
  switched?: boolean;
  dispatch: Dispatch;
  isTouch: boolean;
  selectedGizmoRef: React.RefObject<HTMLDivElement>;
  submitIrrevocable?: JSX.Element;
  mode: SeriesMode;
  flow: SeriesFlow;
  staticMediaUrl: string;
}

export type StepComponentProps = Readonly<IStepCompontentProps>;

export type StepProps = StepComponentCallbacks & StepComponentProps;

const StepContent: React.FC<StepProps> = (props) => {
  const t = useContentTranslation();

  const {
    step: {
      answer,
      explanation,
      hints,
      instruction,
      feedbacks,
      solutionState,
      explanationState,
      instructionHelp,
      supports,
      status,
      qaMode,
    },
    availableWidth,
    currentStepId,
    dispatch,
    hideHelpTools,
    switched,
    isTouch,
    selectedGizmoRef,
    children,
    showAdditionalFeedback,
    submitIrrevocable,
    stepIndex,
    staticMediaUrl,
    mode,
  } = props;

  const availableChildWidth = availableWidth - STEP_CONTENT_PADDING * 2;

  const commonProps = {
    availableWidth: availableChildWidth,
    dispatch,
    gizmoRegistry,
    hideHelpTools,
    isTouch,
  };
  return (
    <div
      className={classNames(styles.content, mode === SeriesMode.preview && styles.contentPreview)}
      key={currentStepId}
    >
      {instructionHelp && (
        <ContextNotificationWrapper $withMarginTop={mode === SeriesMode.preview}>
          <ContextNotification kind={ContextNotificationKind.remark}>
            {getIconByKey(
              instructionHelp.type,
              ContextNotificationKind.remark,
              styles.contextNotificationMargin
            )}
            {instructionHelp.text}
          </ContextNotification>
        </ContextNotificationWrapper>
      )}
      {instructionHelp && showFeedback(props) && (
        <div className={styles.contextNotificationSpacing} />
      )}
      {!isEmptyInstruction(instruction, t) && (
        <ContentBox contentDict={instruction} staticMediaUrl={staticMediaUrl} {...commonProps} />
      )}
      {showFeedback(props) && (
        <StepFeedbacks
          availableChildWidth={availableChildWidth}
          dispatch={dispatch}
          feedbacks={feedbacks}
          hideHelpTools={hideHelpTools}
          isTouch={isTouch}
          showAdditionalFeedback={showAdditionalFeedback}
          staticMediaUrl={staticMediaUrl}
          t={t}
        >
          {children}
        </StepFeedbacks>
      )}
      <BettyContainer />
      {qaMode &&
        supports &&
        supports.map((support, index) => (
          <ContentBox
            collapsibleKind={CollapsibleContentStyle.support}
            title={t(StepTitleTranslationKey.hint)}
            contentDict={support}
            {...commonProps}
            key={`support_${index}`}
            staticMediaUrl={staticMediaUrl}
          />
        ))}

      {qaMode &&
        hints &&
        hints.map((hint, index) => (
          <ContentBox
            collapsibleKind={CollapsibleContentStyle.hint}
            title={t(StepTitleTranslationKey.hint)}
            contentDict={hint}
            {...commonProps}
            key={`hint_${index}`}
            staticMediaUrl={staticMediaUrl}
          />
        ))}

      {!isCurrentAbortedStep(props) && (
        <ContentBox
          contentDict={getContentDictForQuestion(props)}
          fitToContent
          selectedGizmoRef={selectedGizmoRef}
          {...commonProps}
          staticMediaUrl={staticMediaUrl}
        />
      )}
      {status !== StepStatus.completed && submitIrrevocable}
      <ContentBox
        contentDict={answer}
        collapsibleKind={CollapsibleContentStyle.answer}
        title={t(StepTitleTranslationKey.answer)}
        collapse={solutionState}
        switched={switched}
        {...commonProps}
        staticMediaUrl={staticMediaUrl}
        dataCy={`answer-${stepIndex}`}
      />

      <ContentBox
        contentDict={explanation}
        collapsibleKind={CollapsibleContentStyle.explanation}
        title={t(StepTitleTranslationKey.explanation)}
        inactive={isExplanationInactive(props)}
        collapse={explanationState}
        switched={switched}
        {...commonProps}
        staticMediaUrl={staticMediaUrl}
        dataCy={`explanation-${stepIndex}`}
      />
    </div>
  );
};

StepContent.displayName = 'StepContent';

const modeToStepContentComponentMap: Record<SeriesMode, React.FC<StepProps>> = {
  [SeriesMode.review]: StepContentReview,
  [SeriesMode.preview]: StepContent,
  [SeriesMode.practice]: StepContent,
  [SeriesMode.test]: StepContent,
};

export const StepComponent: React.FC<StepProps> = (props) => {
  const [play] = useAudio();
  const { mode, stepIndex, step, exerciseId } = props;
  const { status, title, id } = step;

  const prevPropsRef = React.useRef<StepProps>();

  React.useEffect(() => {
    const prevProps = prevPropsRef.current;
    // In test mode, prevent sound from being played on validation when handing in series.
    // When handing in series, `handIn` property is set to `true`.
    // Also prevent playing sounds while navigating from one exercise to another
    const soundToPlay =
      !prevProps?.handIn &&
      prevProps?.exerciseId === exerciseId &&
      getSoundToPlay(
        [prevProps?.step.validity, prevProps?.step.numberOfErrors],
        [step.validity, step.numberOfErrors]
      );

    if (soundToPlay) {
      play(soundToPlay);
    }

    prevPropsRef.current = props;
  });

  const StepContentComponent =
    status !== StepStatus.locked ? modeToStepContentComponentMap[mode] : Nothing;

  return (
    <div className={styles.container} id={id}>
      {mode === SeriesMode.preview ? (
        <StepContentComponent {...props} />
      ) : (
        <>
          <StepHeader stepIndex={stepIndex} status={status} title={title} />
          <DragNDropContextProvider>
            <StepContentComponent {...props} />
          </DragNDropContextProvider>
        </>
      )}
    </div>
  );
};

StepComponent.displayName = 'StepComponent';
