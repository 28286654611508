import * as React from 'react';

import { ContentDict } from '@bettermarks/gizmo-types';
import { ErrorBoundaryContainer } from '../../apps/seriesplayer/containers/ErrorBoundary';

import { type GizmoRegistry } from '../configuration';
import { type Dispatch } from '../redux/types';
import { GizmoProvider } from './GizmoProvider';
import { PolymorphicGizmo } from './PolymorphicGizmo';

export type ContentRootCommonProps = {
  availableWidth: number;
  contentDict: ContentDict;
  gizmoRegistry: GizmoRegistry;
  hideHelpTools?: boolean;
  dispatch: Dispatch;
  isTouch: boolean;
  selectedGizmoRef?: React.RefObject<HTMLDivElement>;
  staticMediaUrl: string;
};

export type ContentRootProps = ContentRootCommonProps;

/**
 * This component can be used to render a gizmo-tree specified by refId and
 * below additional react children.
 */
export const ContentRoot: React.FC<ContentRootProps> = ({
  availableWidth,
  contentDict,
  gizmoRegistry,
  hideHelpTools,
  dispatch,
  isTouch,
  selectedGizmoRef,
  staticMediaUrl,
}) => (
  <GizmoProvider
    gizmos={gizmoRegistry}
    contentDict={contentDict}
    dispatch={dispatch}
    hideHelpTools={hideHelpTools}
    isTouch={isTouch}
    selectedGizmoRef={selectedGizmoRef}
    staticMediaUrl={staticMediaUrl}
  >
    <ErrorBoundaryContainer hideChildrenOnError={true}>
      <PolymorphicGizmo refid={ContentDict.root(contentDict)} availableWidth={availableWidth} />
    </ErrorBoundaryContainer>
  </GizmoProvider>
);

ContentRoot.displayName = 'ContentRoot';
