import { getFontMetric } from '../../../../../utils/fontMetric';
import * as React from 'react';

import {
  type HasStroke,
  INDEX,
  isEmptyContainer,
  type MRoot as MRootContent,
  type MRow,
} from '@bettermarks/gizmo-types';
import { type EnrichNestedMathContent, type EnrichMathContent } from '../../measure/measureStatic';

import { Bucket } from '../Bucket';
import { BackgroundColor } from '../BackgroundColor';
import { RADIX_CONTENT_SPACING, RADIX_PADDING_BOTTOM } from '../constants';
import { enrich } from '../helpers';
import { type MPropsWithChildren, type SelectHandler, Side } from '../types';

import { Radix } from './Radix';

import styles from './Root.scss';

export type MRootProps = MPropsWithChildren &
  HasStroke & {
    onSelectRadix?: SelectHandler;
    onSelectIndex?: SelectHandler;
    height?: number;
    indexHeight?: number;
    radicandHeight?: number;
    radicandRefLine?: number;
  };

const indexClickHandler =
  (onSelectIndex: SelectHandler) => (event: React.MouseEvent<HTMLElement>) => {
    // prevent overriding handlers inside radicand/index
    if (event.target !== event.currentTarget) return;

    // for now we always put the cursor at the end
    onSelectIndex(Side.Rear);
  };

/**
 * The `MRoot' component renders a MathML <mroot> node
 * It is assumed that an <mroot> has an mrow as child or one single child.
 */
export const MRoot: React.FC<MRootProps> = ({
  children: [index, radicand],
  computedStyles: { color = styles.DEFAULT_FONT_COLOR, backgroundColor, fontSize },
  strokeWidth,
  onSelectIndex,
  onSelectRadix,
  height,
  indexHeight,
  radicandHeight = 0,
  radicandRefLine = 0,
}) => (
  <BackgroundColor backgroundColor={backgroundColor}>
    <div className={styles.mroot} style={{ height }}>
      <div
        className={styles.mrootIndex}
        role="button"
        onMouseDown={onSelectIndex && indexClickHandler(onSelectIndex)}
      >
        <Bucket height={indexHeight}>{index}</Bucket>
        <div
          className={styles.mrootIndexLine}
          style={{
            borderTopWidth: strokeWidth,
            borderTopColor: color,
            height: RADIX_PADDING_BOTTOM + radicandRefLine - strokeWidth * 0.5,
          }}
        />
      </div>
      <Radix
        radicandHeight={radicandHeight}
        radicandRefLine={radicandRefLine}
        color={color}
        strokeWidth={strokeWidth}
        onSelectRadix={onSelectRadix}
        onSelectIndex={onSelectIndex}
        style={{ fontSize }}
      >
        {/* No need to use a <Bucket> with Radix, because Radix does the same thing */}
        {radicand}
      </Radix>
    </div>
  </BackgroundColor>
);

export const enrichMRoot: EnrichNestedMathContent<MRootContent> = (
  formulaStyles,
  content,
  path,
  mathContentEnricher: EnrichMathContent<MRow>
) => {
  const { height: indexHeight, enrichedContent: index } = mathContentEnricher(
    formulaStyles,
    content.index,
    [...path, INDEX]
  );

  const {
    height: radicandHeight,
    refLine: radicandRefLine,
    enrichedContent: radicand,
    relativeToBaseLine,
  } = mathContentEnricher(formulaStyles, content.radicand, [...path, 'radicand']);

  const { strokeWidth } = getFontMetric(formulaStyles.fontSize, formulaStyles.fontWeight);

  const lhsHeight = RADIX_PADDING_BOTTOM * 2 + radicandRefLine + strokeWidth * 0.5 + indexHeight;
  const rhsHeight = RADIX_PADDING_BOTTOM + radicandHeight + RADIX_CONTENT_SPACING + strokeWidth;

  // Either side could be height. The total height is the higher one of the two.
  const height = Math.max(lhsHeight, rhsHeight);
  return enrich(
    {
      ...content,
      index,
      radicand,
      radicandRefLine,
      radicandHeight,
      indexHeight,
      height,
      strokeWidth,
    },
    {
      height,
      refLine: radicandRefLine + RADIX_PADDING_BOTTOM,
      // special case of an impact of mrow with only empty tokens:
      // not visible but impacts alignment of the MRoot
      // when interactive the placeholder is relevant for alignment
      // see http://trac.bm.loc/ticket/43276 for details
      relativeToBaseLine: isEmptyContainer(radicand)
        ? formulaStyles.interactive
        : relativeToBaseLine,
    },
    formulaStyles
  );
};
