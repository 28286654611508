import classNames from 'classnames';
import * as React from 'react';
import { type LeafProps, type MTokenProps } from '../types';
import { onClickLeaf } from '../helpers';

import styles from './tokens.scss';

const enum IdentifierTypes {
  unit = 'unit',
  funcname = 'funcname',
}

export const MI: React.FC<MTokenProps & LeafProps> = ({
  children,
  computedStyles,
  interactive,
  onSelect,
  type,
}) => {
  return (
    <span
      role="button"
      className={classNames(styles.mi, {
        [styles.interactive]: interactive,
        [styles.unit]: type === IdentifierTypes.unit,
        [styles.funcname]: type === IdentifierTypes.funcname,
      })}
      style={computedStyles}
      onMouseDown={onClickLeaf(onSelect)}
    >
      {children}
    </span>
  );
};

MI.displayName = 'MI';
