import * as React from 'react';
import { defaultTo, first, flowRight, has, isNil } from 'lodash';
import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  type Coords,
  GeoEditorMode,
  type GeoScene,
  type LabelStepperProps,
  type MouseOrTouch,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { GeoRenderer } from '../../GeoRenderer';
import { mousePos, screenToWorld } from '@bettermarks/importers';
import { persistProps } from '../persist';
import { getSnapPoint } from '../../snap';
import {
  addPointAction,
  clickInitialLabelAction,
  closeLabelAction,
  confirmLabelAction,
  geoAddPointReducer,
  initialState,
  overInitialLabelAction,
  snapAction,
  stopSnapAction,
} from './geoAddPointReducer';
import { type GeoProps } from '../../Geo';
import { getLabelStepperProps, offset } from '../helpers';
import { addAdditionalButton } from '../addlabel/helpers';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';
import { Maybe } from '../../../../utils/maybe';

export const GeoAddPoint: React.FC<GeoProps & DragScrollableProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoAddPointReducer}
    componentName={`AddPoint:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { uniqueId, configuration, matrix, isTouch, onPersistLocalState, scale } = props;
      const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

      const snap =
        (scene: GeoScene, isTouchStart = false) =>
        (evt: MouseOrTouch) => {
          // calculates nearest snap point in world coordinates from position of Mouse/TouchEvent
          const snapPoint: SnapPoint | null = flowRight([
            getSnapPoint(matrix, scene, scale),
            mousePos(defaultTo<string>(uniqueId, ''), offset(!!isTouch, isTouchStart)),
          ])(evt);

          dispatch(snapAction({ configuration, onPersistLocalState, snapPoint }));
        };

      const startSnap = (scene: GeoScene) => (evt: MouseOrTouch) => {
        maybeScrollBehaviour.ap(startScrolling);
        return snap(scene, true)(evt);
      };

      const stopSnap = () => dispatch(stopSnapAction());

      const onMouseUp = () => {
        const snapPoint = first(state.snapPoints);
        maybeScrollBehaviour.ap(stopScrolling);
        if (snapPoint) {
          const point: Coords = screenToWorld(matrix)(snapPoint);
          const snapObject = snapPoint.snapObject;

          dispatch(
            addPointAction({
              point,
              snapObject,
              configuration,
              onPersistLocalState,
            })
          );
        }
      };

      const onConfirmLabel = (index: number, labelAlignTop: boolean) =>
        dispatch(confirmLabelAction({ index, labelAlignTop, onPersistLocalState }));

      const onLabelClick = (id: string) => (evt: React.MouseEvent<any>) =>
        dispatch(clickInitialLabelAction({ id, configuration }));

      const onLabelHover = () => dispatch(overInitialLabelAction({ onInitialLabelOver: true }));

      const onLabelLeave = () => dispatch(overInitialLabelAction({ onInitialLabelOver: false }));

      const onCloseLabel = () => dispatch(closeLabelAction({ onPersistLocalState }));

      const autoLabeling: boolean = has(
        configuration.autoLabeling,
        state.labels.labelingObjectType
      );

      const geoContentProps = { ...props, ...state.persistProps };

      let labelStepperProps: LabelStepperProps | undefined;
      if (state.labels) {
        labelStepperProps = getLabelStepperProps(
          state.labels,
          onConfirmLabel,
          autoLabeling ? addAdditionalButton(false, onCloseLabel) : undefined
        );
      }

      return (
        <GeoRenderer
          mode={GeoEditorMode.ADD_POINT}
          snapPoints={state.snapPoints}
          onAnyOtherClick={!state.labels.initial ? onCloseLabel : undefined}
          onLabelClick={onLabelClick}
          onLabelOver={onLabelHover}
          onLabelLeave={onLabelLeave}
          onMouseLeave={stopSnap}
          onMouseMove={!state.labels.active ? snap : undefined}
          onTouchStart={startSnap}
          onMouseUp={onMouseUp}
          labelSteppers={isNil(labelStepperProps) ? [] : [labelStepperProps]}
          {...geoContentProps}
        />
      );
    }}
  </WithLocalRedux>
);

GeoAddPoint.displayName = 'GeoAddPoint';
