import {
  annotationInner,
  exportContent,
  type Exporter,
  type ExporterContext,
  type ListSelectionContent,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportListSelection: Exporter = (contentRefId: string, context: ExporterContext) => {
  const listSelection = context.content[contentRefId] as ListSelectionContent;

  const xmlString = `<mrow dynamicIndexExpression="${listSelection.dynamicIndexExpression}">
    ${listSelection.items.map((item) => exportContent(item.$refid, context)).join('')}
    </mrow>`;

  return semantics(xmlString, annotationInner(listSelection.$, listSelection));
};
