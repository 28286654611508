import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  type PdfLinkContent,
  semantics,
} from '@bettermarks/gizmo-types';

export const exportPdfLink: Exporter = (contentRefId: string, context: ExporterContext): string => {
  const content = context.content[contentRefId] as PdfLinkContent;
  return semantics(
    `
    <source>${content.source}</source>
    <text>${content.text}</text>
    `,
    annotationInner(content.$, content)
  );
};
