import log from 'loglevel';
import { type ErrorHandler } from '@bettermarks/bm-font';

/**
 * Handle errors when the external font-loader failed loading the font.
 * See. https://github.com/bramstein/fontfaceobserver and https://github.com/bettermarks/bm-font
 * @param error the error returned by the fontfaceobserver library (via. bm-font)
 * @returns void
 */
export const handleFontLoadingErrors: ErrorHandler = (error) => {
  const errorMessage = /timeout exceeded/.test(error.message)
    ? 'Font loading timed out.'
    : `Unexpected Font Loading error: ${error.message}`;
  log.warn(errorMessage);
  return;
};
