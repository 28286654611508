import defaultTheme from '../../themes/defaultTheme';
import { NOSE_BORDER, NOSE_NEGATIVE_BORDER } from './constants';

export const leftRight = {
  borderTopWidth: NOSE_BORDER,
  borderBottomWidth: NOSE_BORDER,
  top: '50%',
  transform: 'translateY(-50%)',
};

export const left = {
  ...leftRight,
  borderRight: `${NOSE_BORDER} solid ${defaultTheme.colors.cAreaBorder}`,
  left: '0%',
  marginLeft: NOSE_NEGATIVE_BORDER,

  innerArrow: {
    borderTopWidth: NOSE_BORDER,
    borderBottomWidth: NOSE_BORDER,
    borderRight: `${NOSE_BORDER} solid ${defaultTheme.colors.cWhite}`,
    left: '2px',
    marginTop: NOSE_NEGATIVE_BORDER,
  },
};

export const right = {
  ...leftRight,
  borderLeft: `${NOSE_BORDER} solid ${defaultTheme.colors.cAreaBorder}`,
  left: '100%',
  marginRight: NOSE_NEGATIVE_BORDER,

  innerArrow: {
    borderTopWidth: NOSE_BORDER,
    borderBottomWidth: NOSE_BORDER,
    borderLeft: `${NOSE_BORDER} solid ${defaultTheme.colors.cWhite}`,
    right: '2px',
    marginTop: NOSE_NEGATIVE_BORDER,
  },
};

export const topBottom = {
  borderRightWidth: NOSE_BORDER,
  borderLeftWidth: NOSE_BORDER,
  left: '50%',
  transform: 'translateX(-50%)',
};

export const top = {
  ...topBottom,
  borderBottom: `${NOSE_BORDER} solid ${defaultTheme.colors.cAreaBorder}`,
  top: NOSE_NEGATIVE_BORDER,

  innerArrow: {
    borderRightWidth: NOSE_BORDER,
    borderLeftWidth: NOSE_BORDER,
    borderBottom: `${NOSE_BORDER} solid ${defaultTheme.colors.cWhite}`,
    top: '2px',
    left: NOSE_NEGATIVE_BORDER,
  },
};

export const bottom = {
  ...topBottom,
  borderTop: `${NOSE_BORDER} solid ${defaultTheme.colors.cAreaBorder}`,
  top: '100%',

  innerArrow: {
    borderRightWidth: NOSE_BORDER,
    borderLeftWidth: NOSE_BORDER,
    borderTop: `${NOSE_BORDER} solid ${defaultTheme.colors.cWhite}`,
    top: '-10px',
    right: NOSE_NEGATIVE_BORDER,
  },
};
