import { type Position } from '@bettermarks/gizmo-types';

export interface Rect {
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Rect {
  export const create = (xMin: number, yMin: number, xMax: number, yMax: number) => ({
    xMin: Math.min(xMin, xMax),
    yMin: Math.min(yMin, yMax),
    xMax: Math.max(xMin, xMax),
    yMax: Math.max(yMin, yMax),
  });

  export const atCenter = (x: number, y: number, width: number, height: number): Rect => ({
    xMin: x - 0.5 * width,
    yMin: y - 0.5 * height,
    xMax: x + 0.5 * width,
    yMax: y + 0.5 * height,
  });

  export const center = (rect: Rect): Position => ({
    x: rect.xMin + 0.5 * (rect.xMax - rect.xMin),
    y: rect.yMin + 0.5 * (rect.yMax - rect.yMin),
  });

  export const width = (rect: Rect): number => Math.abs(rect.xMax - rect.xMin);
  export const height = (rect: Rect): number => Math.abs(rect.yMax - rect.yMin);
}

export const enum Direction {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right',
}

export interface DialogPosition {
  position: Position;
  nose: Direction;
  noseX: number;
}

export const positionDialog = (
  anchor: Rect, // the anchor rectangle (can be of size 0)
  boundary: Rect, // the outer boundary
  dWidth: number, // dialog width
  dHeight: number // dialog height
): DialogPosition => {
  const ax = Rect.center(anchor).x;
  const ay = Rect.center(anchor).y;
  const ah = 0.5 * Rect.height(anchor);

  const x = Math.max(
    boundary.xMin, // stop at left boundary
    Math.min(
      boundary.xMax - dWidth, // stop at right boundary
      Rect.center(anchor).x - 0.5 * dWidth // center at anchor position
    )
  );

  const leftMargin = boundary.xMin + 0.5 * dWidth;
  const rightMargin = boundary.xMax - 0.5 * dWidth;
  let noseX = 0.5;

  if (ax < leftMargin) {
    noseX = (ax - boundary.xMin) / dWidth;
  } else if (ax > rightMargin) {
    noseX = 1 - (boundary.xMax - ax) / dWidth;
  }

  // first we assume the case that we are in the lower half of the bounary (i.e. where y is large)
  const upperMargin = boundary.yMax - dHeight - ah;
  let y = Math.min(upperMargin, ay + ah);
  let nose = Direction.up;
  if (ay > upperMargin) {
    nose = Direction.down;
    y = ay - ah - dHeight;
  }
  // now check if we are in the upper half of the bounary
  if (ay < boundary.yMin + 0.5 * (boundary.yMax - boundary.yMin)) {
    y = ay - ah - dHeight;
    nose = Direction.down;
    if (ay < boundary.yMin + ah + dHeight) {
      y = ay + ah;
      nose = Direction.up;
    }
  }

  return {
    position: { x, y },
    nose,
    noseX,
  };
};
