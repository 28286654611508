import { type Dict, type ReadonlyDict } from '../../../../gizmo-utils/append-only';
import { DEFAULT_APP_SETTINGS } from '../../../store/constants';
import {
  type AppSettings,
  AppSettingsKey,
  FeatureKey,
  type Features,
  Roles,
} from '../../../../types';
import { Request, Response } from './bm-api';

const CONFIG_ROUTE = '/config/user';
export const OPEN_SETTINGS_ROUTE = '/config/lobLinkSettings';

/**
 * The expected reply from the NSP backend, settings and features contain more fields
 * that are currently not needed by the series player.
 */
type AppConfigResponse = {
  settings: AppSettings & Dict;
  features: Features & Dict<boolean>;
  roles: Array<string>;
};

/**
 * A the required subset of what is coming from the NSP backend.
 * To avoid logging mixed types.ts into kibana (index problems) we are restricting `appSettings`
 * to the fields needed n the series player.
 */
export type AppConfig = Readonly<{
  appSettings: AppSettings;
  features: Features;
  isStudent: boolean;
}>;

const pickEnumKeys = (keyEnum: object, data: ReadonlyDict): Dict =>
  Object.keys(keyEnum).reduce<Dict>((result, key) => {
    if (data[key] !== undefined) {
      // don't store undefined as a value, to not override defaults
      result[key] = data[key];
    }
    return result;
  }, {});

/**
 * Fetches data from URL that is expected to return a JSON
 * with the data structure described in `AppConfigResponse`.
 *
 * @param url (default `/config/user`)
 *
 * @see AppConfigResponse
 */
export const getAppConfig = async (url = CONFIG_ROUTE): Promise<AppConfig> => {
  // remember: the below type AppConfigResponse is an assumption as compile time,
  // that is never checked at runtime! Especially regarding the possible values of fields.
  // Since the url to fetch from can be passed in this route could return anything.
  const response = await Response.parseJSON<AppConfigResponse>(await Request.get(url, 'include'));
  // pick only the fields that are part of the type, AppSettingsKey enum helps with the maintenance:
  // since we are logging it as part of exceptions, we need to make sure the fields present
  // don't have mixed types.ts like boolean | string.
  // We are just assuming that the right types.ts are present if the keys match,
  // because we own both sides, let's hope this works out
  const settings = pickEnumKeys(AppSettingsKey, response.settings);
  const features = pickEnumKeys(FeatureKey, response.features);
  // For lobLinks, the equivalent of /config/user doesn't return roles.
  // so for loblinks, we are considering the user to be a teacher.
  const isStudent = !!response.roles?.includes(Roles.rStudent);

  return {
    appSettings: {
      // since we don't know what comes in, let's be sure the defaults are present
      ...DEFAULT_APP_SETTINGS,
      ...settings,
      embedded: url === CONFIG_ROUTE,
    },
    features,
    isStudent,
  };
};
