import * as React from 'react';
import { defaultTo, first } from 'lodash';
import { ACCORDION_HEIGHT_PIXEL, ACCORDION_WIDTH_PIXEL } from '@bettermarks/importers';
import { transformX, transformY } from '@bettermarks/importers';
import { AxisLabel, AxisLine, type AxisTickLabel, AxisTickLabels, AxisTicks } from './AxisRenderer';
import {
  type AdvancedAxisObject,
  AxisCapStyle,
  AxisDirection,
  type Coords,
} from '@bettermarks/gizmo-types';

import styles from './AdvancedAxis.scss';

export type AdvancedAxisProps = AdvancedAxisObject & {
  matrix: number[];
  min: Coords;
  max: Coords;
  totalWidth: number;
  totalHeight: number;
};

export type AccordionProps = {
  matrix: number[];
  height: number;
  max: number;
};

export type AccordionLineProps = AccordionProps & {
  id: string;
  hasArrow: boolean;
  min: number;
};

export type AccordionPathsProps = {
  x: number;
  y: number;
  yStep: number;
};

export const AccordionPaths: React.FC<AccordionPathsProps> = ({ x, y, yStep }) => {
  return (
    <>
      <path
        className={styles.accordionCrisp}
        d={`M ${[
          [x, y],
          [0, ACCORDION_HEIGHT_PIXEL / 2 + yStep],
        ]
          .map((a: [number, number]) => a.join(' '))
          .join(' l ')}`}
      />
      <path
        className={styles.accordion}
        d={`M ${[
          [x, y + ACCORDION_HEIGHT_PIXEL / 2 + yStep],
          [ACCORDION_WIDTH_PIXEL, -ACCORDION_HEIGHT_PIXEL / 6],
          [-2 * ACCORDION_WIDTH_PIXEL, -ACCORDION_HEIGHT_PIXEL / 3],
          [2 * ACCORDION_WIDTH_PIXEL, -ACCORDION_HEIGHT_PIXEL / 3],
          [-ACCORDION_WIDTH_PIXEL, -ACCORDION_HEIGHT_PIXEL / 6],
        ]
          .map((a: [number, number]) => a.join(' '))
          .join(' l ')}`}
      />
      <path
        className={styles.accordionCrisp}
        d={`M ${[
          [x, y - ACCORDION_HEIGHT_PIXEL / 2 + yStep],
          [0, ACCORDION_HEIGHT_PIXEL / 2 + yStep],
        ]
          .map((a: [number, number]) => a.join(' '))
          .join(' l ')}`}
      />
    </>
  );
};

AccordionPaths.displayName = 'AccordionPaths';

export const Accordion: React.FC<AccordionProps> = ({ matrix, height, max }) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  const yStep = -sy(max - height) / 2;

  const [x0, y0] = [sx(0), sy(0)];

  /** accordion, i.e. zigzag line from origin to first y-axis tick **/
  return <AccordionPaths x={x0} y={y0} yStep={yStep} />;
};

Accordion.displayName = 'Accordion';

export const AccordionLine: React.FC<AccordionLineProps> = ({
  id,
  matrix,
  height,
  max,
  min,
  hasArrow,
}) => {
  return (
    <>
      <AxisLine
        {...{ matrix }}
        id={`${id}_l`}
        direction={AxisDirection.vertical}
        min={{ x: 0, y: min }}
        max={{ x: 0, y: 0 }}
        hasArrow={false}
      />
      <Accordion {...{ matrix, max, height }} />
      <AxisLine
        {...{ matrix, hasArrow }}
        id={`${id}_u`}
        direction={AxisDirection.vertical}
        min={{ x: 0, y: height }}
        max={{ x: 0, y: max }}
      />
    </>
  );
};

AccordionLine.displayName = 'AccordionLine';

export const AdvancedAxis: React.FC<AdvancedAxisProps> = ({
  matrix,
  id,
  direction,
  capStyle,
  min,
  max,
  totalWidth,
  totalHeight,
  label,
  ticks,
  contractionEnabled,
}) => {
  const isVertical = direction === AxisDirection.vertical;
  const isDiagonal = direction === AxisDirection.diagonal;
  const hasArrow = capStyle === AxisCapStyle.Triangle;
  const [tickDashes, tickLabels] = ticks.reduce(
    (acc, t) => {
      const dash = {
        pos: t.pos,
        major: !!t.label,
        decoration: t.decoration,
        style: t.style,
      };

      const label = {
        pos: t.pos,
        major: true,
        content: t.label,
      };

      return [
        [...acc[0], dash],
        [...acc[1], label],
      ];
    },
    [[], []]
  );

  const axis = !(contractionEnabled && isVertical) ? (
    <AxisLine {...{ id, matrix, direction, hasArrow, min, max }} />
  ) : (
    <AccordionLine
      {...{ id, matrix, hasArrow }}
      min={min.y}
      max={max.y}
      height={defaultTo<number>(
        first(
          tickDashes
            .map((d) => d.pos.y)
            .filter((y) => y > 0)
            .sort((a, b) => a - b)
        ),
        1
      )}
    />
  );

  const deltaX = max.x - min.x;
  const deltaY = max.y - min.y;

  const tickMax = isVertical ? max.y : (isDiagonal ? -1 : 1) * max.x;
  const labelMax = isVertical
    ? { x: 0, y: max.y }
    : isDiagonal
    ? {
        x: max.x + 0.6,
        y: max.y + (0.6 * deltaY) / deltaX,
      }
    : { x: max.x, y: 0 };

  return (
    <g>
      {axis}
      <AxisTicks {...{ matrix, isVertical }} tickDashes={tickDashes} max={tickMax} />
      <AxisLabel {...{ matrix, isVertical, label }} x={labelMax.x} y={labelMax.y} />
      <AxisTickLabels
        {...{ matrix, isVertical }}
        tickLabels={tickLabels as AxisTickLabel[]}
        width={totalWidth}
        height={totalHeight}
      />
    </g>
  );
};

AdvancedAxis.displayName = 'AdvancedAxis';
