import { findLastIndex, isNil } from 'lodash';

import {
  FRACTIONFORM_DEFAULT_CONTENT,
  type FractionFormContent,
  FractionFormEditorMode,
} from '@bettermarks/gizmo-types';
import { type Action, handleActions } from 'redux-actions';
import {
  type FractionFormActionPayload,
  REMOVE_FORM,
  SET_SEGMENT_COUNT,
  TOGGLE_SEGMENT,
} from './fractionFormActions';
import { SET_MODE } from '../../../gizmo-utils/redux/gizmoActions';

const addForm = (state: FractionFormContent) => ({
  ...state,
  bitmasks: state.bitmasks.length < 4 ? [[true], ...state.bitmasks] : state.bitmasks,
});

export const fractionFormReducer = handleActions<FractionFormContent, FractionFormActionPayload>(
  {
    [SET_MODE]: (state: FractionFormContent, { payload }: Action<FractionFormEditorMode>) =>
      payload
        ? payload === FractionFormEditorMode.Add
          ? addForm(state)
          : { ...state, selectedMode: payload }
        : state,
    [TOGGLE_SEGMENT]: (state, { payload }: Action<number>) => {
      if (isNil(payload)) {
        return state;
      }

      const [last] = state.bitmasks.slice(-1);
      const butLast = state.bitmasks.slice(0, -1);

      return {
        ...state,
        bitmasks: [
          ...butLast,
          [...last.slice(0, payload), !last[payload], ...last.slice(payload + 1)],
        ],
      };
    },
    [REMOVE_FORM]: (state, { payload }: Action<number>) =>
      payload !== undefined
        ? {
            ...state,
            bitmasks: [...state.bitmasks.slice(0, payload), ...state.bitmasks.slice(payload + 1)],
          }
        : state,
    [SET_SEGMENT_COUNT]: (state, { payload }: Action<number>) => {
      if (isNil(payload)) {
        return state;
      }

      const [last] = state.bitmasks.slice(-1);
      const butLast = state.bitmasks.slice(0, -1);

      if (last.length < payload) {
        return {
          ...state,
          bitmasks: [...butLast, [...last, false]],
        };
      }

      const lastFalse = findLastIndex(last, (it) => !it);

      const bitmask =
        lastFalse > -1
          ? [...last.slice(0, lastFalse), ...last.slice(lastFalse + 1)]
          : [...last.slice(0, -1)];

      return {
        ...state,
        bitmasks: [...butLast, [...bitmask]],
      };
    },
  },
  FRACTIONFORM_DEFAULT_CONTENT
);
