import { ButtonBackground } from '../types';
import { compose, defaultTo, get, negate } from 'lodash/fp';

// Maybe we can still consider using ramdajs instead of lodash since
// ramdajs has eq, or and many more other build-in functions
// ramdajs compose typing issue have to be checked before usage.
export const eq =
  <T>(a: T) =>
  (b: T) =>
    a === b;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const or = (left: any, right: any) => (cond: boolean) => cond ? left : right;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const orEmpty = (left: any) => or(left, '');

export const isFilled = eq<ButtonBackground>(ButtonBackground.filled);
export const isTransparent = eq<ButtonBackground>(ButtonBackground.transparent);
export const isBorder = eq<ButtonBackground>(ButtonBackground.border);
export const isNotTransparent = negate(isTransparent);
export const getBackgroundType = compose(defaultTo(ButtonBackground.filled), get('background'));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resolveColors = (orFn: typeof or, predicate: (_: any) => boolean) =>
  compose(orFn, predicate, getBackgroundType);
export const resolveCursor = compose(
  orEmpty('cursor: default;'),
  defaultTo(false),
  get('disabled')
);

export const applyCssForBorderType = (css: string) =>
  compose(orEmpty(css), isBorder, getBackgroundType);
