import { Severity } from '@bettermarks/umc-kotlin';
import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import { type Dispatch } from '../../../../gizmo-utils/redux';
import { type TFunction } from 'i18next';
import { groupBy } from 'lodash';
import * as React from 'react';
import { ApplicationState, type StepFeedback } from '../../../../types';
import { ContentBox } from '../../components';
import { PADDING_CONTEXT_NOTIFICATION } from '../../constants';
import { useSelector } from 'react-redux';

import styles from './StepFeedbacks.scss';
import { ContextNotification, ContextNotificationKind } from '@seriesplayer/common-ui';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { Support } from '../../containers/Support/Support';

export type StepFeedbacksProps = {
  availableChildWidth: number;
  dispatch: Dispatch;
  feedbacks: ReadonlyArray<StepFeedback>;
  hideHelpTools?: boolean;
  isTouch: boolean;
  showAdditionalFeedback?: boolean;
  staticMediaUrl: string;
  t: TFunction;
};

type FeedbackGroupProps = StepFeedbacksProps & {
  kind: ContextNotificationKind;
  additionalHelp?: boolean;
};

const severityMap = {
  [Severity.error]: ContextNotificationKind.error,
  [Severity.ok]: ContextNotificationKind.correct,
  [Severity.comment]: ContextNotificationKind.correct,
  [Severity.remark]: ContextNotificationKind.remark,
};

type SupportState = {
  supports?: ReadonlyArray<ContentDict>;
};

const selectSupportState = (state: ApplicationState): SupportState => {
  const currentStep = ApplicationState.toCurrentStep.get(state);
  return {
    supports: currentStep?.supports,
  };
};

const FeedbackGroup: React.FC<FeedbackGroupProps> = ({
  availableChildWidth,
  additionalHelp,
  dispatch,
  feedbacks,
  hideHelpTools,
  kind,
  isTouch,
  staticMediaUrl,
  t,
}) => {
  const availableWidth = availableChildWidth - PADDING_CONTEXT_NOTIFICATION * 2;
  const { supports } = useSelector(selectSupportState);
  const displaySupport = !!(additionalHelp && supports?.length);
  return (
    <>
      <div className={styles.feedbackWrapper} key={kind} data-cy={`feedback-${kind}`}>
        <ContextNotification kind={kind}>
          {feedbacks.map((feedback, index) => (
            <div className={styles.feedback} key={index}>
              {feedback.key && t(feedback.key)}
              {feedback.contentDict && (
                <ContentBox
                  availableWidth={availableWidth}
                  contentDict={feedback.contentDict}
                  dispatch={dispatch}
                  gizmoRegistry={gizmoRegistry}
                  hideHelpTools={hideHelpTools}
                  isTouch={isTouch}
                  topBottomMargin={false}
                  staticMediaUrl={staticMediaUrl}
                />
              )}
            </div>
          ))}
        </ContextNotification>
      </div>
      {displaySupport && <Support availableWidth={availableWidth} />}
    </>
  );
};

export const StepFeedbacks: React.FC<StepFeedbacksProps> = ({
  feedbacks,
  children,
  showAdditionalFeedback,
  ...rest
}) => {
  const groupedFeedbacks = groupBy(feedbacks, ({ severity }) => severityMap[severity]);
  return (
    <>
      {[
        ContextNotificationKind.error,
        ContextNotificationKind.remark,
        ContextNotificationKind.correct,
      ].map(
        (kind) =>
          groupedFeedbacks[kind] && (
            <FeedbackGroup
              kind={kind}
              feedbacks={groupedFeedbacks[kind]}
              key={kind}
              additionalHelp={showAdditionalFeedback && kind === ContextNotificationKind.error}
              {...rest}
            >
              {children}
            </FeedbackGroup>
          )
      )}
    </>
  );
};
