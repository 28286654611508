import { omit } from 'lodash';

import { type FontMetric } from '@bettermarks/bm-font';
import { getFontMetric } from '../../../../utils/fontMetric';
import { type AppendOnlyArray } from '../../../../gizmo-utils/append-only';
import { type EnrichedContent } from '../../../../gizmo-utils/configuration';
import {
  type AlignableRow,
  type ContentPath,
  type FormulaStyles,
  type Interactable,
  isMRow,
  isMSpace,
  type LayoutMetrics,
  type MathContent,
  type MDecoratable,
  NEWLINE,
  type PureMathContent,
} from '@bettermarks/gizmo-types';
import { mapToComputedStyles } from '../components/mapToComputedStyles';
import { boundingLayoutMetrics, calculateYOffset } from '../components/helpers';
import { type EnrichMathContent } from './measureStatic';

/**
 * Calculates the `LayoutMetric` that can contain all the elements in `childrenMetrics` after
 * alignment.
 * If `childrenMetrics` is empty, the `LayoutMetrics` according to `fontMetric` is returned.
 *
 * @param {ReadonlyArray<LayoutMetrics>} childrenMetrics
 * @param {FontMetric} fontMetric
 *
 * @returns {LayoutMetrics}
 *
 * @see boundingLayoutMetrics
 */
const measureFormulaRow = (
  childrenMetrics: ReadonlyArray<LayoutMetrics>,
  { height, refLine }: FontMetric
): LayoutMetrics =>
  boundingLayoutMetrics(
    childrenMetrics,
    // not the complete truth: when an mrow only has **empty** tokens
    // the result is not visible but also not correctly vertically aligned
    // since this is only a relevant use case for MRoot and MSqrt
    // this case is checked there
    // see http://trac.bm.loc/ticket/43276 for details
    true,
    // only ensure minimum when there are no children, otherwise allow smaller sizes
    childrenMetrics.length === 0 ? { height, refLine } : undefined
  );

/**
 * This function is a generic enricher for all math containers with a horizontal row of content.
 * Any horizontal row extends the type AlignRowWithChildren, which means that it has a property
 * "children" which is an array of content and a propery "childrenAlignments" which is an array
 * of vertical alignments corresponding to each member of "children".
 *
 * Besides enriching the content of all the children, this function updates the
 * "childrenAlignments" by aligning them according to their refLine. This essentially means that
 * the new bottom part (below the refLine) has the height of the largest refLine of the children,
 * and the new top part (above the refLine) has the height of the larget top part of the children.
 * @see measureFormulaRow
 *
 * Any child that has the flag "relativeToBaseLine" set will be ignored and not have any vertical
 * alignment applied to it.
 */
export function enrichFormulaRow<T extends AlignableRow>(
  formulaStyles: FormulaStyles,
  row: T,
  path: ContentPath,
  enricher: EnrichMathContent<MathContent>,
  measureRow: typeof measureFormulaRow = measureFormulaRow
): EnrichedContent<T & MDecoratable & Interactable> {
  const fontMetric = getFontMetric(formulaStyles.fontSize, formulaStyles.fontWeight);

  const enrichedChildren = row.children.map((child, i) =>
    enricher(formulaStyles, child, [...path, i])
  );
  const childrenMetrics = enrichedChildren.map(({ enrichedContent, ...metrics }) => metrics);

  let measuredRow: LayoutMetrics;

  if (isMRow(row) && row.children.some((child) => isMSpace(child, NEWLINE))) {
    const lines: AppendOnlyArray<AppendOnlyArray<MathContent>> = [[]];
    row.children.forEach((child: PureMathContent) => {
      // push child into current line (last element in lines)
      lines[lines.length - 1].push(child);
      if (isMSpace(child, NEWLINE)) {
        lines.push([]); // perform line break: create new line for children to be added
      }
    });
    const [height, lastRefLine] = lines.reduce<[number, number, number]>(
      ([sum, lastRefLine, lineStart], line) => {
        const { height, refLine } = measureRow(
          // measure only those that are in the current line
          childrenMetrics.slice(lineStart, lineStart + line.length),
          fontMetric
        );
        return [sum + height, refLine, lineStart + line.length];
      },
      [0, 0, 0]
    );
    measuredRow = {
      height,
      // simulating browser behavior the baseLine will be taken from the last element
      // might not be accurate when the last element is not relativeToBaseLine
      // but is the simplest solution we currently have
      refLine: lastRefLine,
      relativeToBaseLine: true, // should not be vertically aligned in outer Formula
    };
  } else {
    measuredRow = measureRow(childrenMetrics, fontMetric);
  }

  const children = enrichedChildren.map((emc) => emc.enrichedContent);
  const childrenAlignments = enrichedChildren.map((emc) =>
    emc.relativeToBaseLine ? 0 : calculateYOffset(fontMetric, emc.refLine)
  );

  if (formulaStyles.interactive) {
    // removing paddings from the style to avoid negative margins being applied to the
    // super-/subscript of M(Sub)(Sup) in case the exponent/index is an input-field (see #43091)
    measuredRow = omit(measuredRow, 'padding');
  }

  return {
    ...measuredRow,
    enrichedContent: {
      ...row,
      children,
      childrenAlignments,
      computedStyles: mapToComputedStyles(formulaStyles),
      ...(formulaStyles.interactive && { interactive: true }),
    },
  };
}
