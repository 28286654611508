import type * as React from 'react';
import { Maybe } from '../../utils/maybe';
import { notifyWhitboardToLockScroll, notifyWhitboardToUnlockScroll } from './helpers';

interface UseScrollLock {
  id?: string;
  element?: React.RefObject<HTMLElement>;
  direction?: Direction;
}

interface LockStrategy {
  lock: (el: HTMLElement) => void;
  unlock: (el: HTMLElement) => void;
}

export const enum Direction {
  Vertical = 'Vertical',
  Horizontal = 'Horizontal',
  // TODO: implement later if needed
  // Multi = 'Multi'
}

const preventScroll = (e: Event) => {
  e.preventDefault();
};

/**
 * Strategy to lock scrolling of a specified element on touch devices.
 *
 * Usages: Geo (don't scroll during geo interaction such as "add point", "add line", ...)
 *
 * History: Was solved by setting overflow(X/Y) to hidden before -> did not work on iOS
 */
const strategy: LockStrategy = {
  lock: (el: HTMLElement) => el.addEventListener('touchmove', preventScroll),
  unlock: (el: HTMLElement) => el.removeEventListener('touchmove', preventScroll),
};

const strategiesMap: { [key: string]: LockStrategy } = {
  [Direction.Vertical]: strategy,
  [Direction.Horizontal]: strategy,
};

export const useScrollLock = ({ id, element, direction = Direction.Vertical }: UseScrollLock) => {
  const maybeElement = id
    ? Maybe(document.getElementById(id))
    : Maybe(element).map((e) => e.current);

  const lockStrategy = strategiesMap[direction];

  const lock = () => {
    maybeElement.ap(lockStrategy.lock);
    notifyWhitboardToLockScroll();
  };
  const unlock = () => {
    maybeElement.ap(lockStrategy.unlock);
    notifyWhitboardToUnlockScroll();
  };

  return [lock, unlock];
};
