import * as React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { render } from 'react-dom';
import { defaultTo, isString } from 'lodash';
import qs from 'query-string';
import { I18nextProvider } from 'react-i18next';
import { debugGetFontMetric } from '@bettermarks/bm-font';
import { defaultTheme } from '@seriesplayer/common-ui';
import log from 'loglevel';
import smoothscroll from 'smoothscroll-polyfill';
import { KeyboardShortcutHandler } from '../gizmo-utils/keyboard/KeyboardShortcutHandler';
import { LocaleProvider } from '../gizmo-utils/polymorphic-gizmo';

import { lobLinkLocale } from './seriesplayer';
import { i18n } from './seriesplayer/i18n';
import { createStore } from './store';
import { router } from './router';

// kick off the polyfill for windows.scrollTo.
smoothscroll.polyfill();

// splitting at '?' is needed for hash like '#/?appLocale=es_UY'
// eslint-disable-next-line no-restricted-globals
const queryString = defaultTo(location.hash.split('?').pop(), '');
const { appLocale } = qs.parse(queryString);
// eslint-disable-next-line no-restricted-globals
const locale: undefined | string = isString(appLocale) ? appLocale : lobLinkLocale(location.hash);

// Add logging measured sizes and prevent clearing the dom elements that were used for measuring.
debugGetFontMetric(log.debug);

const store = createStore();

// do not remove this!!!
new KeyboardShortcutHandler(store);

export const APP_ROOT = 'root';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  render(
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <I18nextProvider i18n={await i18n(locale)}>
          <LocaleProvider>
            <RouterProvider router={router} />
          </LocaleProvider>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>,
    document.getElementById(APP_ROOT)
  );
})();
