import { type Action, createAction, handleActions } from 'redux-actions';
import { isNil } from 'lodash';
import {
  type Coords,
  type GeoConfiguration,
  type GeoContentPersistProps,
  type GeoPerpendicularState,
  type GeoScene,
  LineHighlight,
  type LineObject,
  type LineObjectType,
  LineStyle,
  type PointObject,
  type PreviewAngle,
  RAY,
  SEGMENT,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import {
  add,
  addReferencedBy,
  angle,
  createPoint,
  DEFAULT_PREVANGLE,
  DEFAULT_PREVLINE,
  DEFAULT_PREVLINE_DECORATION,
  GEO_DEFAULT_PERSIST_PROPS,
  getPointCoordsId,
  intersectLines,
  INV_POINT_PREFIX,
  isValidMap,
  linesAreEqul,
  sub,
  visibleCenter,
} from '@bettermarks/importers';
import { createLine, getLineCoords, getStraightlineId } from '../../helpers';
import { DEFAULT_HOVER_COLOR } from '../constants';
import { getLineAndSnapPoints } from './helper';

export const getHelperLines = (
  lineType: LineObjectType,
  [p1, p2]: [Coords, Coords],
  selDir: Coords
) => {
  const common = {
    decoration: { ...DEFAULT_PREVLINE_DECORATION, lineStyle: LineStyle.dashed },
    visible: true,
  };

  switch (lineType) {
    case STRAIGHTLINE:
    default:
      return [];
    case RAY:
      const [h1, h2] = getLineCoords(p1, sub(selDir, p1), RAY);
      return [
        {
          ...common,
          p1: h1,
          p2: h2,
        },
      ];
    case SEGMENT:
    case VECTOR:
      const [h11, h12] = getLineCoords(p1, sub(selDir, p1), RAY);
      const [h21, h22] = getLineCoords(p2, add(p2, selDir), RAY);
      return [
        {
          ...common,
          p1: h11,
          p2: h12,
        },
        {
          ...common,
          p1: h21,
          p2: h22,
        },
      ];
  }
};

export interface DragPayload {
  mouseP: Coords;
  scene: GeoScene;
  matrix: number[];
  scale: number;
}

export interface AddPayload {
  configuration: GeoConfiguration;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface OutPayload {
  id: string;
  origLine: LineObject;
}

export type GeoPerpendicularPayload = DragPayload | AddPayload | OutPayload | string | void;

const DRAG: 'DRAG' = 'DRAG';
export const dragAction = createAction<DragPayload>(DRAG);
const ADD_PERPENDICULAR: 'ADD_PERPENDICULAR' = 'ADD_PERPENDICULAR';
export const addPerpendicular = createAction<AddPayload>(ADD_PERPENDICULAR);
const START_DRAG: 'START_DRAG' = 'START_DRAG';
export const startDragAction = createAction<string>(START_DRAG);
const STOP_DRAG: 'STOP_DRAG' = 'STOP_DRAG';
export const stopDragAction = createAction(STOP_DRAG);
const HOVER: 'HOVER' = 'HOVER';
export const hoverAction = createAction<string>(HOVER);
const OUT: 'OUT' = 'OUT';
export const outAction = createAction<OutPayload>(OUT);

export const initialState: GeoPerpendicularState = {
  snapPoints: [],
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  selectedLineType: STRAIGHTLINE,
  selectedLine: [
    { x: NaN, y: NaN },
    { x: NaN, y: NaN },
  ],
  selectedAngle: 0,
  selectedDirection: { x: NaN, y: NaN },
  prevLine: DEFAULT_PREVLINE,
  helperLines: [],
  prevAngle: DEFAULT_PREVANGLE,
  highlight: LineHighlight.perpendicular,
  dragging: false,
};

export const geoPerpendicularReducer = handleActions<
  GeoPerpendicularState,
  GeoPerpendicularPayload
>(
  {
    [ADD_PERPENDICULAR]: (state: GeoPerpendicularState, { payload }: Action<AddPayload>) => {
      if (!payload) {
        return state;
      }

      const { configuration, onPersistLocalState } = payload;
      const p1 = createPoint(state.prevLine.p1 as Coords, {}, true);
      const p1Id = getPointCoordsId(p1.coords, INV_POINT_PREFIX);
      const p2 = createPoint(state.prevLine.p2 as Coords, {}, true);
      const p2Id = getPointCoordsId(p2.coords, INV_POINT_PREFIX);
      const lineId = getStraightlineId(p1Id, p2Id);
      const line = createLine(
        p1Id,
        p2Id,
        configuration.defaultDecorations[STRAIGHTLINE],
        STRAIGHTLINE
      );

      // do not add line, if it is not visible or already existing
      if (
        !visibleCenter(p1.coords, p2.coords, configuration.display, STRAIGHTLINE) ||
        !isNil(
          state.persistProps.straightlines.find((l) => {
            const line = state.persistProps.geoContentMap[l] as LineObject;
            const l1 = state.persistProps.geoContentMap[line.p1Id] as PointObject;
            const l2 = state.persistProps.geoContentMap[line.p2Id] as PointObject;
            return linesAreEqul([p1.coords, p2.coords], [l1.coords, l2.coords]);
          })
        )
      ) {
        return {
          ...initialState,
          persistProps: state.persistProps,
        };
      }

      const persistProps = {
        ...state.persistProps,
        invisiblePoints: [...state.persistProps.invisiblePoints, p1Id, p2Id],
        straightlines: [...state.persistProps.straightlines, lineId],
        geoContentMap: addReferencedBy({
          ...state.persistProps.geoContentMap,
          [p1Id]: p1,
          [p2Id]: p2,
          [lineId]: line,
        }),
      };

      onPersistLocalState(persistProps);

      return {
        ...initialState,
        persistProps,
      };
    },

    [DRAG]: (state: GeoPerpendicularState, { payload }: Action<DragPayload>) => {
      if (!payload || !state.dragging) {
        return state;
      }

      const { mouseP, scene, matrix, scale } = payload;
      const {
        lineCoordinates: [p1, p2],
        snapPoints,
      } = getLineAndSnapPoints(mouseP, state.selectedDirection, scene, matrix, scale);

      const prevLine = {
        p1,
        p2,
        decoration: DEFAULT_PREVLINE_DECORATION,
        visible: true,
      };
      const prevAngle: PreviewAngle = {
        ...DEFAULT_PREVANGLE,
        startAngle: state.selectedAngle,
        endAngle: state.selectedAngle + 90,
        isRightAngle: true,
        hasAngleLegs: false,
        coords: intersectLines(
          state.selectedLine,
          [p1, p2],
          isValidMap[`${STRAIGHTLINE}-${STRAIGHTLINE}`]
        ) as Coords,
        visible: true,
      };

      const helperLines = getHelperLines(
        state.selectedLineType,
        state.selectedLine,
        state.selectedDirection
      );
      return {
        ...state,
        snapPoints,
        prevLine,
        prevAngle,
        helperLines,
      };
    },

    [START_DRAG]: (state: GeoPerpendicularState, { payload }: Action<string>) => {
      if (!payload) {
        return state;
      }

      const line = state.persistProps.geoContentMap[payload] as LineObject;
      const [p1, p2] = [
        state.persistProps.geoContentMap[line.p1Id] as PointObject,
        state.persistProps.geoContentMap[line.p2Id] as PointObject,
      ];

      return {
        ...state,
        dragging: true,
        selectedLineType: line.type,
        selectedLine: [p1.coords, p2.coords],
        selectedDirection: sub(p1.coords, p2.coords),
        selectedAngle: angle(p1.coords, p2.coords),
        highlight: undefined,
      };
    },

    [STOP_DRAG]: (state: GeoPerpendicularState) => {
      return {
        ...initialState,
        persistProps: state.persistProps,
      };
    },

    [HOVER]: (state: GeoPerpendicularState, { payload }: Action<string>) => {
      if (!payload) {
        return state;
      }

      const line = state.persistProps.geoContentMap[payload] as LineObject;

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [payload]: {
              ...line,
              decoration: {
                ...line.decoration,
                color: DEFAULT_HOVER_COLOR,
              },
            },
          },
        },
      };
    },

    [OUT]: (state: GeoPerpendicularState, { payload }: Action<OutPayload>) => {
      if (!payload) {
        return state;
      }

      const { id, origLine } = payload;

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: origLine,
          },
        },
      };
    },
  },
  initialState
);
