import * as React from 'react';
import { withTranslation, type WithTranslation } from 'react-i18next';
import anime from 'animejs';
import classNames from 'classnames';
import { Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';
import { DialogFooter } from '../../../../../components';
import { type SeriesScore, StarModeEnum } from '../../../../../types';
import { ExerciseTable } from '../../../components/ExerciseTable';
import { type HasAudio, Sound, withAudio } from '../../../providers/Audio';
import {
  ENDSCREEN_ANIMATION_DURATION,
  ENDSCREEN_ANIMATION_START_DELAY,
  ENDSCREEN_ELEMENT_ANIMATION_DURATION,
  EndscreenMode,
} from './constants';

import styles from './Endscreen.scss';
import { EndscreenProgressBar, type EndscreenProgressBarProps } from './EndscreenProgressBar';

export type EndscreenDispatchProps = {
  onCloseEndscreen: () => void;
  onExerciseOverview: () => void;
};

export type EndscreenStateProps = {
  seriesScore?: SeriesScore;
  mode: EndscreenMode;
  numExercises: number;
  wrongExercises: ReadonlyArray<number>;
  animate?: boolean;
};

type EndscreenState = {
  starStyle?: React.CSSProperties;
};

export type EndscreenProps = EndscreenStateProps & EndscreenDispatchProps;

interface Props extends EndscreenProps, HasAudio, WithTranslation {}
/**
 * Endscreen Component
 */
export class _Endscreen extends React.Component<Props, EndscreenState> {
  state: EndscreenState = {};

  get scoreRatio(): number {
    return this.props.seriesScore
      ? this.props.seriesScore.pointsReached / this.props.seriesScore.pointsMax
      : -1;
  }

  /**
   * When a user's score is above three coins and completes series for the first time,
   * The user gets a star.
   * @returns {boolean}: true if user gets a star.
   */
  get starEarned(): boolean {
    if (!this.props.seriesScore) {
      return false;
    } else {
      return (
        this.scoreRatio >= this.props.seriesScore.coinThresholds[2] / 100 &&
        this.props.seriesScore.starMode === StarModeEnum.New
      );
    }
  }

  componentDidMount() {
    const { animate, seriesScore } = this.props;

    if (seriesScore && seriesScore.starMode === StarModeEnum.Previous) {
      this.setState({ starStyle: { opacity: 1 } });
    } else if (this.starEarned) {
      // For the rules for getting a star, see:
      // http://wiki.bm.loc/index.php/NSP:_Functional_details#Stars_and_coins
      if (animate) {
        anime({
          targets: `.${styles.starFilled}`,
          opacity: 1,
          easing: 'linear',
          duration: ENDSCREEN_ELEMENT_ANIMATION_DURATION,
          delay: ENDSCREEN_ANIMATION_START_DELAY + ENDSCREEN_ANIMATION_DURATION,
          begin: () => this.props.playSound(Sound.Star),
        });
      } else {
        this.setState({ starStyle: { opacity: 1 } });
      }
    }
  }

  scoreContent() {
    const {
      animate,
      mode,
      numExercises,
      onCloseEndscreen,
      onExerciseOverview,
      seriesScore,
      t,
      wrongExercises,
    } = this.props;
    if (!seriesScore) {
      return;
    }
    const { coinThresholds, pointsReached, pointsMax } = seriesScore;
    const progressBarProps: EndscreenProgressBarProps = {
      scoreRatio: this.scoreRatio,
      coinThresholds,
      animate,
    };
    return (
      <>
        <header className={classNames(styles.headerResult, styles.dialogSection)}>
          <h1 className={styles.headline}>
            {t('seriesplayer:dialog.endscreen.body1')}
            <span data-cy="endscreen-results-ratio" className={styles.score}>
              {' '}
              {pointsReached}/{pointsMax}{' '}
            </span>
            {t('seriesplayer:dialog.endscreen.body2')}
          </h1>
          <div className={styles.star}>
            <div className={styles.starFilled} style={this.state.starStyle} />
          </div>
        </header>
        <div className={styles.dialogSection}>
          <div className={styles.betty} />
          <EndscreenProgressBar {...progressBarProps} />
        </div>
        {mode === EndscreenMode.test && (
          <ExerciseTable
            exerciseText={t('seriesplayer:dialog.endscreen.exercise')}
            numExercises={numExercises}
            exercisesWithIconB={wrongExercises}
            iconPropsA={{
              iconKey: 'CheckMediumBold',
              style: { fill: styles.ICON_COLOR_CORRECT },
            }}
            iconPropsB={{
              iconKey: 'TimesSmallBold',
              style: { fill: styles.ICON_COLOR_WRONG },
            }}
          />
        )}
        <DialogFooter align="center">
          {mode === EndscreenMode.test && (
            <span className={styles.smallScreen}>
              <Button
                id="exercise-overview-btn"
                kind={ButtonKind.view}
                stretch={ButtonStretch.responsive}
                onClick={onExerciseOverview}
                type="dialog"
              >
                {t('seriesplayer:dialog.endscreen.exerciseOverview')}
              </Button>
            </span>
          )}
          <Button
            id="close-endscreen-btn"
            kind={ButtonKind.action}
            stretch={ButtonStretch.responsive}
            onClick={onCloseEndscreen}
            type="dialog"
          >
            {t('seriesplayer:dialog.endscreen.ok')}
          </Button>
        </DialogFooter>
      </>
    );
  }

  render() {
    const { mode } = this.props;

    const simpleContent = (
      <>
        <header className={styles.dialogSection}>
          <h1 className={styles.headline}>
            {this.props.t('seriesplayer:dialog.endscreen.disabledResult.title')}
          </h1>
        </header>
        <p className={classNames(styles.body, styles.dialogSection)}>
          {this.props.t('seriesplayer:dialog.endscreen.disabledResult.body')}
        </p>
        <DialogFooter align="center">
          <Button
            id="close-endscreen-btn"
            dataCy="close-endscreen-btn"
            kind={ButtonKind.action}
            stretch={ButtonStretch.responsive}
            onClick={this.props.onCloseEndscreen}
            type="dialog"
          >
            {this.props.t('seriesplayer:dialog.endscreen.ok')}
          </Button>
        </DialogFooter>
      </>
    );

    return (
      <div id="endscreen" className={styles.endscreen} data-cy="endscreen">
        {mode === EndscreenMode.message ? simpleContent : this.scoreContent()}
      </div>
    );
  }
}

export const Endscreen = withTranslation()(withAudio(_Endscreen));
Endscreen.displayName = 'Endscreen';
