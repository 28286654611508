import * as React from 'react';
import { includes, isEmpty } from 'lodash';

import { Circle } from '../../components';
import {
  type CircleDecoration,
  type CircleObject,
  type Coords,
  Hover,
  type LabelAlignDirection,
  type PointHighlight,
} from '@bettermarks/gizmo-types';
import { add, smult } from '@bettermarks/importers';
import { alignmentMap } from '../Label';
import { type SetProps, type WithBorderRect } from './types';
import { isSelectableObject } from './setHelpers';

export type CircleSetProps = SetProps &
  WithBorderRect & {
    circles: ReadonlyArray<string>;
    highlight?: PointHighlight;
    snapHighlightIds: string[];
  };

export const getCircleRefPointCoords = (
  radius: number,
  coords: Coords,
  cx: number,
  cy: number,
  align?: LabelAlignDirection
): Coords => {
  /**
   * Default positions will only affect the vertical axis, so x direction
   * won't be affected by default.
   */
  const defaultXDirection = 0;
  const defaultYDirection = cy <= coords.y ? -1 : 1;

  const alignmentOffsets = align ? alignmentMap[align] : [];
  const [, , sx, sy] = alignmentOffsets;

  /**
   * If alignment wasn't set, use the default direction
   * If alignment is 0, return 0. It means that the label will remain as it is
   * in that direction.
   * Otherwise, round the offset to its closest integer, so we have -1 or 1 as options
   */
  const xDirection = isEmpty(alignmentOffsets) ? defaultXDirection : sx === 0 ? 0 : Math.round(sx);

  /**
   * Multiply by -1 because the y axis has an opposite behavior. Meaning that increasing a
   * distance in the y direction means going down and vice versa.
   */
  const yDirection = isEmpty(alignmentOffsets)
    ? defaultYDirection
    : sy === 0
    ? 0
    : Math.round(sy) * -1;

  let result = add(coords, smult(yDirection * radius, { x: 0, y: 1 }));

  /**
   * For alignments left-top, right-top, left-bottom and right bottom,
   * x offset won't be applied
   */
  if (align && ['top', 'bottom', 'left', 'right'].indexOf(align) !== -1) {
    result = add(result, smult(xDirection * radius, { x: 1, y: 0 }));
  }

  return result;
};

export const CircleSet: React.FC<CircleSetProps> = ({
  circles,
  highlight,
  mode,
  geoContentMap,
  matrix,
  borderRectId,
  onMouseDownId,
  onMouseOutId,
  onHoverId,
  onClick,
  snapHighlightIds,
}) => {
  return (
    <g>
      {circles.map((id) => {
        const { centerHighlight, hover, decoration, ...circle } = geoContentMap[id] as CircleObject;

        const hoverType = hover || (includes(snapHighlightIds, id) ? Hover.DEFAULT : undefined);

        return (
          <Circle
            key={id}
            {...circle}
            {...{ id, mode, matrix, borderRectId }}
            decoration={decoration as CircleDecoration}
            selectable={isSelectableObject(circle, mode)}
            hover={hoverType}
            highlight={centerHighlight || highlight}
            onMouseDown={onMouseDownId && onMouseDownId(id)}
            onMouseOver={onHoverId && onHoverId(id)}
            onMouseLeave={onMouseOutId && onMouseOutId(id)}
            onClick={onClick && onClick(id)}
          />
        );
      })}
    </g>
  );
};

CircleSet.displayName = 'CircleSet';
