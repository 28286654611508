import clamp from 'lodash/clamp';

export interface ISpriteSheetAPI {
  play: () => void;
  stop: () => void;
}

export class SpriteSheetAPI implements ISpriteSheetAPI {
  ctx: CanvasRenderingContext2D;
  image: HTMLImageElement;
  fps: number;
  frame: number;
  fw: number;
  fh: number;
  startFrame: number;
  numFrames: number;
  loop: boolean;
  onFinished?: () => void;

  animFrame: number;
  startTime: number;

  constructor(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx;
    this.fps = 0;
    this.frame = 0;
    this.startFrame = 0;
    this.numFrames = 0;
    this.fw = 0;
    this.fh = 0;
    this.image = document.createElement('img');
    this.loop = false;
  }

  drawFrame() {
    const fhs = Math.floor(this.image.height / this.fh);
    const sw = (this.frame % fhs) * this.fw;
    const sh = Math.floor(this.frame / fhs) * this.fh;
    this.ctx.clearRect(0, 0, this.fw, this.fh);
    this.ctx.drawImage(this.image, sw, sh, this.fw, this.fh, 0, 0, this.fw, this.fh);
  }

  update = (timeStamp: number) => {
    const frameTime = Math.max(timeStamp - this.startTime, 0);
    const frames = Math.round((frameTime * this.fps) / 1000);
    let newFrame = this.frame;
    if (this.loop) {
      newFrame = this.startFrame + (frames % this.numFrames);
    } else {
      const endFrame = this.startFrame + this.numFrames;
      newFrame = clamp(this.startFrame + frames, this.startFrame, endFrame + 1);
      if (newFrame === endFrame) {
        window.cancelAnimationFrame(this.animFrame);
        this.onFinished && this.onFinished();
      }
    }
    if (this.frame !== newFrame) {
      this.frame = newFrame;
      this.drawFrame();
    }
    this.animFrame = window.requestAnimationFrame(this.update);
  };

  play() {
    this.startTime = window.performance.now();
    this.drawFrame();
    this.animFrame = window.requestAnimationFrame(this.update);
  }

  stop() {
    window.cancelAnimationFrame(this.animFrame);
  }

  currentFrame() {
    return this.frame;
  }
}
