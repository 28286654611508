import * as React from 'react';
import { isUndefined } from 'lodash';
import {
  type ExerciseDisplayStatus,
  ExerciseValidity,
  ExerciseStatus,
  SeriesMode,
} from '../../../../types';
import classNames from 'classnames';
import { Icon, type IconKey } from '../../../../components/Icon';

import styles from './exerciseNavigationButton.scss';
import { ExcludedFromTabNavigationButton } from '../../../../components/ExcludedFromTabNavigation';

export const enum Appearance {
  desktop = 'desktop',
  mobile = 'mobile',
  plain = 'plain',
}

export type ExerciseNavigationButtonProps = {
  appearance: Appearance;
  exerciseIndex: number;
  exerciseDisplayStatus: ExerciseDisplayStatus;
  mode?: SeriesMode;
  switchExercise: (index: number) => void;
  selected?: boolean;
};

const ICON_MAP: { [key: string]: IconKey } = {
  [ExerciseValidity.correct]: 'ExerciseStateCorrect',
  [ExerciseValidity.sufficient]: 'ExerciseStateSufficient',
  [ExerciseValidity.wrong]: 'ExerciseStateWrong',
  [ExerciseStatus.started]: 'ExerciseStateNeutral',
  [ExerciseStatus.withInput]: 'ExerciseStateWithInput',
};

const StatusIcon: React.FC<{ status: ExerciseDisplayStatus }> = ({ status }) => {
  const iconKey =
    !isUndefined(status) && status in ICON_MAP ? ICON_MAP[status] : 'ExerciseStateNeutral';

  return <Icon iconKey={iconKey} className={styles.icon} />;
};

export const ExerciseNavigationButton = React.forwardRef<
  HTMLDivElement,
  ExerciseNavigationButtonProps
>(({ exerciseIndex, exerciseDisplayStatus, switchExercise, selected, appearance, mode }, ref) => {
  const onSwitch =
    mode !== SeriesMode.test && isUndefined(exerciseDisplayStatus)
      ? undefined
      : () => switchExercise(exerciseIndex);

  return (
    <div
      ref={ref}
      className={classNames({
        [styles.wrapperMobile]: appearance === Appearance.mobile,
        [styles.wrapperPlain]: appearance === Appearance.plain,
      })}
    >
      <ExcludedFromTabNavigationButton
        onClick={onSwitch}
        data-cy={`ex-nav-btn-${exerciseIndex + 1}`}
        data-cy-selected={selected}
        className={classNames(
          styles.button,
          styles[appearance],
          exerciseDisplayStatus ? styles[exerciseDisplayStatus] : styles.disabled,
          styles[selected ? 'selected' : 'unselected']
        )}
      >
        <div className={styles.selectionBox}>
          <div className={styles.statusIndicator}>
            <StatusIcon status={exerciseDisplayStatus} />
            <div className={styles.label}>{exerciseIndex + 1}</div>
          </div>
        </div>
      </ExcludedFromTabNavigationButton>
    </div>
  );
});

ExerciseNavigationButton.displayName = 'ExerciseNavigationButton';
