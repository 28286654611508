import * as React from 'react';
import { type GizmoShortcutsMap, type ShortcutsMap } from '../keyboard';
import { type MapContentDictToProps } from './connectGizmo';
import { type ContextState, type GizmoProps } from '../polymorphic-gizmo';
import { type MapDispatchToPropsFunction } from 'react-redux';
import {
  type CommonCallbacks,
  type Content,
  type ContentDict,
  ShouldEnrichKind,
} from '@bettermarks/gizmo-types';
import {
  gizmoAction,
  registerShortcuts,
  selectContent,
  unregisterShortcuts,
  type UnregisterShortcutsPayload,
} from './gizmoActions';
import { type Dispatch } from './types';
import shallowEqual from 'react-redux/lib/utils/shallowEqual';
import { isEqual } from 'lodash';
import { initiateRowValidation } from '../../apps/scaffolding-gizmo-prototype/actions';

// eslint-disable-next-line @typescript-eslint/ban-types
type InteractiveGizmoProps<GizmoContent extends Content, Callbacks = {}> = {
  children: React.ComponentType<GizmoContent & Callbacks & CommonCallbacks & ContextState>;
  contentDict: ContentDict;
  dispatch: Dispatch;
  gizmoContentProps: GizmoContent & GizmoProps;
  mapDispatchToProps: MapDispatchToPropsFunction<Callbacks, GizmoProps>;
  options?: {
    mapContentDictToProps?: MapContentDictToProps<GizmoContent, GizmoProps>;
    shortcuts?: GizmoShortcutsMap;
  };
};

/**
 * This internal class is created so that we can have the shouldComponentUpdate lifecycle hook.
 * This is because the consumer of the react Context API bypassed its parent's
 * shouldComponentUpdate.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export class InteractiveGizmo<GizmoContent extends Content, Callbacks = {}> extends React.Component<
  InteractiveGizmoProps<GizmoContent, Callbacks>
> {
  shouldComponentUpdate({
    contentDict: nextContentDict,
    gizmoContentProps: nextGizmoContentProps,
  }: InteractiveGizmoProps<GizmoContent, Callbacks>) {
    const {
      options,
      contentDict: prevContentDict,
      gizmoContentProps: prevGizmoContentProps,
    } = this.props;
    const prevContent = prevContentDict[prevGizmoContentProps.refid] as GizmoContent;
    const nextContent = nextContentDict[nextGizmoContentProps.refid] as GizmoContent;

    /**
     * Compare the previous content with the new content and also the result of
     * mapContentDictToProps() if it exists.
     */
    return (
      !shallowEqual(prevGizmoContentProps, nextGizmoContentProps) ||
      !!(
        options &&
        options.mapContentDictToProps &&
        !isEqual(
          options.mapContentDictToProps(prevContent, prevContentDict),
          options.mapContentDictToProps(nextContent, nextContentDict)
        )
      )
    );
  }

  render() {
    const {
      children: Gizmo,
      contentDict,
      dispatch,
      gizmoContentProps,
      mapDispatchToProps,
      options,
    } = this.props;

    const props = {
      // props from PolymorphicGizmo (content + GizmoProps)
      ...gizmoContentProps,
      // callback props that dispatch actions to redux
      ...mapDispatchToProps(dispatch, gizmoContentProps),
      // common callbacks
      onFocus: (e: React.FocusEvent<any>) => {
        // XXX: stopping propagation will not be necessary in the final
        // implementation of the seriesplayer. It's only needed right now, because
        // currently ALL gizmos are connected to the store and thus the parent
        // (non interactive)
        // gizmo will also dispatch a focus action.
        e.stopPropagation();
        dispatch(
          gizmoAction(selectContent(), gizmoContentProps.refid, {
            skipUndo: true,
            shouldEnrich: ShouldEnrichKind.justEnrich,
            triggeredByUser: true,
          })
        );
      },
      onRegisterShortcuts: (shortcuts: ShortcutsMap) => {
        dispatch(
          registerShortcuts({
            ...shortcuts,
            ['=']: { ...shortcuts['+'], payload: '=' },
            ['Enter']: initiateRowValidation(),
          })
        );
      },
      onUnregisterShortcuts: (payload: UnregisterShortcutsPayload) => {
        const { shortcuts } = payload;
        const shortcutsWithEquals = {
          ...shortcuts,
          ['=']: { ...shortcuts['+'], payload: '=' },
          ['Enter']: initiateRowValidation(),
        };
        dispatch(unregisterShortcuts({ ...payload, shortcuts: shortcutsWithEquals }));
      },
      ...(options &&
        options.mapContentDictToProps &&
        options.mapContentDictToProps(
          contentDict[gizmoContentProps.refid] as GizmoContent,
          contentDict
        )),
    };

    return <Gizmo {...props} />;
  }
}
