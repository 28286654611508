import { createAction } from 'redux-actions';
import { type StartSeriesPayload } from '../../../types';

export const START_SERIES = 'START_SERIES';
export const startSeries = createAction<StartSeriesPayload>(START_SERIES);

export const SUBMIT_INCOMPLETE_SERIES = 'SUBMIT_INCOMPLETE_SERIES';
export const submitIncompleteSeries = createAction(SUBMIT_INCOMPLETE_SERIES);

export const LOCK_SCREEN = 'LOCK_SCREEN';
export const lockScreen = createAction(LOCK_SCREEN);

export const UNLOCK_SCREEN = 'UNLOCK_SCREEN';
export const unlockScreen = createAction(UNLOCK_SCREEN);
