import {
  $MROW,
  ANNOTATION_XML,
  type Content,
  type ContentReference,
  type FElement,
  type FieldSetContent,
  ID,
  importContent,
  type Importer,
  MAX_INPUT_LENGTH,
  type MutableElement,
  RENDER_STYLE,
  RS,
  SEMANTICS,
  SPECIAL,
  TOOL_SET,
} from '@bettermarks/gizmo-types';
import { importFieldSet } from '../fieldset/importer';
import { KeyboardToolInput } from '../formula/importer';

export interface ClockTimeContent extends Readonly<Content> {
  time: ContentReference;
  clock: ContentReference;
}

function updateInputField(placeholder: MutableElement) {
  placeholder.setAttribute(RENDER_STYLE, RS.INNER_TEXT);
  placeholder.setAttribute(TOOL_SET, KeyboardToolInput.numbers_letters);
  placeholder.setAttribute(MAX_INPUT_LENGTH, '2');
}

const findSemantics = (xml: FElement, renderStyle: string): FElement | undefined =>
  xml
    .findChildTag($MROW)
    .children.find(
      (child) =>
        child.localName === SEMANTICS &&
        child.findChildTag(ANNOTATION_XML).firstChild.attribute(RENDER_STYLE) === renderStyle
    );

/**
 * Split the element with the clock element (if any) ant the time element transformed
 * as a fieldset.
 */
function splitClockAndFieldSet(src: FElement): [FElement, FElement | undefined] {
  const xml = src.toMutableCopy();
  const clock: FElement | undefined = findSemantics(xml, RS.CLOCK);
  let fieldset: FElement | undefined = findSemantics(xml, RS.FIELD_SET);
  if (!fieldset) {
    if (clock) {
      xml.findChildTag($MROW).remove(clock);
    }
    fieldset = fakeFieldSet(xml);
  }
  return [fieldset, clock];
}

/**
 * Creates a fake fieldset XML.
 * It avoids creating a new gizmo as we can use FieldSet gizmo for the time input.
 */
function fakeFieldSet(xml: MutableElement): FElement {
  const special = xml.findChildTag(ANNOTATION_XML).findChildTag(SPECIAL);
  // We set a fake id by suffixing the time id with :field
  // for the fake fieldset element to avoid duplicated id with 'clock-time'
  // semantics.
  special.setAttribute(RENDER_STYLE, RS.FIELD_SET);
  special.setAttribute(ID, `${special.attribute(ID)}:field`);

  const mrow = xml.findChildTag($MROW);
  const [hour, min] = mrow.getChildrenByTagName(SEMANTICS);
  [hour, min]
    .map((child) => child.findChildTag(ANNOTATION_XML).firstChild)
    .forEach(updateInputField);
  mrow.insertBefore(min, '<mo>:</mo>');
  return xml;
}

/**
 * the render styles 'time' and 'clock-time' are imported to an 'fieldset'.
 */
export const importTime: Importer<ClockTimeContent | FieldSetContent> = (
  preContent,
  xml,
  outer
) => {
  const [fieldSetXML, clockXML] = splitClockAndFieldSet(xml);
  if (!clockXML) {
    return importFieldSet(preContent, fieldSetXML, outer);
  }
  return {
    ...preContent,
    clock: importContent(clockXML, outer),
    time: importContent(fieldSetXML, outer),
  };
};
