import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BettyOops } from '../../../components';
import styles from './EMError.scss';

export const EMError: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className={styles.error} data-cy="em-betty-oops">
      <div>{t('errormessages:explanation.loadFailed')}</div>
      <div>
        <BettyOops width={100} height={100} />
      </div>
    </div>
  );
};
