import * as React from 'react';
import classNames from 'classnames';
import { type ContextState, renderGizmos } from '../../../gizmo-utils/polymorphic-gizmo';
import { type TimeSpanContentReference, type TimeSpanGizmoContent } from '@bettermarks/gizmo-types';
import { DurationLabel } from './DurationLabel';
import {
  ThirdDurationArrowEdge,
  ThirdDurationArrowMiddle,
  ThirdDurationArrowWithLabel,
} from './ThirdDurationArrow';
import styles from './TimeSpan.scss';
import { ArrowDirection, ShadowScrollbars } from '../../components';

export type TimeSpanGizmoProps = TimeSpanGizmoContent & ContextState;

/**
 * `TimeSpanGizmo` component.
 *
 */
export const TimeSpanGizmo: React.FC<TimeSpanGizmoProps> = ({
  contentRefs,
  thirdDuration,
  spanType,
  availableWidth,
}) => {
  const [firstTime, firstDuration, secondTime, secondDuration, thirdTime] = renderGizmos(
    contentRefs,
    availableWidth
  );
  const [firstTimeRef, firstDurationRef, secondTimeRef, secondDurationRef, thirdTimeRef] =
    contentRefs;
  // Handlers for custom styling, depending on type of time and duration elements
  // this gizmo renders
  const withClock = (ref?: TimeSpanContentReference): boolean => !!ref && ref.withClock;
  const hasInteractiveContent = (ref?: TimeSpanContentReference): boolean =>
    !!ref && ref.isInteractive;

  const timeStyles = (ref?: TimeSpanContentReference) =>
    ref && {
      [styles.time]: true,
      [styles.noClock]: !withClock(ref),
      [styles.isInteractive]: hasInteractiveContent(ref),
    };

  return (
    <ShadowScrollbars availableWidth={availableWidth}>
      <div className={styles.timeSpanRework}>
        <div>
          <div className={classNames(timeStyles(firstTimeRef))}>{firstTime}</div>
          {thirdDuration && (
            <ThirdDurationArrowEdge
              edge="left"
              hasArrowHead={thirdDuration.direction === ArrowDirection.left}
            />
          )}
        </div>
        <div>
          <div
            className={classNames(styles.duration, {
              [styles.isInteractive]: hasInteractiveContent(firstDurationRef),
              [styles.isDay]: spanType === 'day',
            })}
          >
            {firstDuration}
          </div>
          {thirdDuration && <ThirdDurationArrowMiddle />}
        </div>
        <div
          className={classNames({
            // Corner case: We need to ensure width on one specific case:
            //  - have third rectangle duration arrow with a label
            //  - time input has no clocks
            [styles.narrow]: thirdDuration && !withClock(secondTimeRef),
          })}
        >
          <div className={classNames(timeStyles(secondTimeRef))}>{secondTime}</div>
          {thirdDuration && (
            <ThirdDurationArrowWithLabel>
              {<DurationLabel duration={thirdDuration} availableWidth={availableWidth} />}
            </ThirdDurationArrowWithLabel>
          )}
        </div>
        <div>
          <div
            className={classNames(styles.duration, {
              [styles.isInteractive]: hasInteractiveContent(secondDurationRef),
            })}
          >
            {secondDuration}
          </div>
          {thirdDuration && <ThirdDurationArrowMiddle />}
        </div>
        <div>
          <div className={classNames(timeStyles(thirdTimeRef))}>{thirdTime}</div>
          {thirdDuration && (
            <ThirdDurationArrowEdge
              edge="right"
              hasArrowHead={thirdDuration.direction === ArrowDirection.right}
            />
          )}
        </div>
      </div>
    </ShadowScrollbars>
  );
};

TimeSpanGizmo.displayName = 'TimeSpanGizmo';
