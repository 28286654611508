import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import * as React from 'react';
import { ReadingOrder, type TableContent, TableStyle } from '@bettermarks/gizmo-types';
import { HScrollTable, LinearizeColumnTable, LinearizeRowTable } from './components';
import { createRendererContent } from './helper';

export type TableGizmoProps = TableContent & ContextState;

export const TableGizmo: React.FC<TableGizmoProps> = (props) => {
  const rendererProps = {
    ...createRendererContent(props),
    availableWidth: props.availableWidth,
  };

  switch (props.readingOrder) {
    case ReadingOrder.column:
      return <LinearizeColumnTable {...rendererProps} />;
    case ReadingOrder.row:
      return <LinearizeRowTable {...rendererProps} />;
    default:
  }

  switch (props.style) {
    case TableStyle.SIMPLE_TABLE:
      const { columnPercents, ...woutCP } = rendererProps;
      return <LinearizeRowTable {...woutCP} />;
    default:
      return <HScrollTable {...rendererProps} noScale={props.noScale} />;
  }
};

TableGizmo.displayName = 'TableGizmo';
