import * as React from 'react';
import { isNil, throttle } from 'lodash';
import { HLayoutChildWrapper } from '@seriesplayer/common-ui';
import { Severity } from '@bettermarks/umc-kotlin';
import DnD, { DragEvents } from '../../../components/DragAndDrop/dnd';
import { MARGIN, NBSP, SetDragItem, SetDropTarget } from '../../../components/DragAndDrop';
import { type ContextState, PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';
import {
  DragAndDropStyle,
  type DropCallbacks,
  type DropProps,
  isResolved,
  type SetDropTargetContent,
} from '@bettermarks/gizmo-types';
import { DragNDropContext } from '../context';

export type SetDropTargetCallbacks = DropCallbacks & {
  onResize?: (width: number, height: number) => void;
  onDragStarted?: (index: number) => void;
};

export type SetDropTargetProps = SetDropTargetContent &
  SetDropTargetCallbacks &
  DropProps &
  ContextState & {
    dragging: boolean;
  };

const ADDITIONAL_FLEX_SPACE = 4;
/**
 * @param maxItemWidth
 * @param widthAsFactorOfMaxItemWidth
 * @param isRectangle
 * a helper function used for proper width calculation based on widthAsFactorOfMaxItemWidth value.
 * widthAsFactorOfMaxItemWidth + 1 -> number of spaces
 * e.g. ||*||*||*|| - for 3 items we have 4 spaces
 */
const calculateWidthUsingFactor = (
  maxItemWidth: number,
  widthAsFactorOfMaxItemWidth: number,
  isRectangle: boolean
) =>
  maxItemWidth * widthAsFactorOfMaxItemWidth +
  (isRectangle ? (widthAsFactorOfMaxItemWidth + 1) * 2 + ADDITIONAL_FLEX_SPACE : 0);

export const SetDropTargetGizmo: React.FC<SetDropTargetProps> = ({
  $interactionType,
  availableWidth,
  disabled,
  dragging,
  fontSize,
  isTouch,
  items,
  minHeight,
  minWidth,
  onDragStarted,
  onDropItem,
  onRemoveItem,
  onResize,
  percentWidth,
  refid,
  severity,
  style,
  targetGroup,
  verticalItemAlign,
  fixedWidth,
  widthAsFactorOfMaxItemWidth,
}) => {
  const { maxItemWidth } = React.useContext(DragNDropContext);
  /**
   * Whether we are able to receive drops (e.g. a drag has been started somewhere)
   */
  const [canReceiveDrops, setCanReceiveDrops] = React.useState<boolean>(false);
  /**
   * Whether a drop is allowed, i.e. a valid drag item of the same target group is hovering above us
   */
  const [dropAllowed, setDropAllowed] = React.useState<boolean>(false);
  /**
   * Whether you can drag items from us
   */
  const [draggable, setDraggable] = React.useState<boolean>(true);
  /**
   * The index of the currently dragged item
   */
  const [draggedItem, setDraggedItem] = React.useState<number | undefined>(undefined);

  const targetRef = React.useRef<HTMLSpanElement>(null);

  const draggedItemIndex = () =>
    DnD.source && DnD.source.srcDropTargetRefId === refid
      ? DnD.source.srcDropTargetIndex
      : undefined;

  /*
   * Called by us after a mouseup event here, when we were dragging or
   * after a touchend here. It will notify the reducers and the dnd api
   * of the successful drop.
   */
  const drop = () => {
    const source = DnD.source;
    if (source && onDropItem && source.targetGroup === targetGroup) {
      const newPos = DnD.getDragItemTranslationPosition();
      const freeItemTranslation = {
        newPos,
        refid: source.originalSourceRefId || '',
      };
      if (source.srcDropTargetRefId) {
        onDropItem(source.srcDropTargetRefId, freeItemTranslation, source.srcDropTargetIndex);
      } else if (source.originalSourceRefId) {
        onDropItem(source.originalSourceRefId, freeItemTranslation);
      }
      DnD.drop();
    } else {
      DnD.endDrag();
    }
    setCanReceiveDrops(false);
    setDropAllowed(false);
  };

  /**
   * A short explanation why we have to handle touch differently than mouse
   * (this is also valid for drop target and the drag source):
   *
   * Mouse events are dispatched on the elements that are under the mouse
   * cursor at the time of the event. This means, if you raise the mouse button
   * over a div, the mouseup event is fired there. Touch events though are
   * handled completely differently! A touch event "chain" - meaning
   * touchstart, touchmove and touchend - is dispatched on the element where
   * you started it! Thus, if you lift your finger over a different element,
   * your touchend listeners there will NOT fire, but the touchend listener of
   * the element where you started the touch. This makes a completely different
   * approach for things like drag & drop necessary, as dragging a source and
   * dropping it on a target means starting somewhere and ending somewhere
   * else! Thus we need to actually register the touchend and touchmove events
   * on some parent element (document) and figure out if we have been hit.
   *
   */
  const onTouchEnd = React.useCallback(
    (evt: TouchEvent) => {
      const touch = evt.changedTouches[0];
      let el = document.elementFromPoint(touch.pageX, touch.pageY);
      while (el) {
        if (el === targetRef.current) {
          // that's us!
          drop();
          break;
        }
        el = el.parentElement;
      }
    },
    [onDropItem, canReceiveDrops, dropAllowed]
  );

  const dragEnter = () => {
    if (!dropAllowed && DnD.canDrop(targetGroup)) {
      setDropAllowed(true);
    }
  };

  const dragLeave = () => {
    if (dropAllowed) {
      setDropAllowed(false);
    }
  };

  const onTouchMove = React.useCallback(
    throttle((evt: TouchEvent) => {
      const touch = evt.changedTouches[0];
      let el = document.elementFromPoint(touch.pageX, touch.pageY);
      while (el) {
        if (el === targetRef.current) {
          // that's us!
          dragEnter();
          return;
        }
        el = el.parentElement;
      }
      dragLeave();
    }, 100),
    [targetRef.current, dropAllowed]
  );

  /*
   * Called by dnd api to notify us, that a drag has been started. We can listen
   * now to touchend events (if we are on a touch device) to find out if the
   * drag ends on us and we have to initiate a drop.
   */
  const onDragStart = React.useCallback(() => {
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchmove', onTouchMove, { passive: false });
    setCanReceiveDrops(true);
    setDraggable(false);
    setDraggedItem(draggedItemIndex());
    return () => {
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);
    };
  }, [canReceiveDrops, draggable]);

  /*
   * Called by dnd api if a drag from this set target ended. We have to notifiy
   * the reducer in this case, that an item is to be removed.
   */
  const onDragEnd = React.useCallback(() => {
    if (DnD.source) {
      const index = DnD.source.srcDropTargetIndex;
      onRemoveItem && !isNil(index) && onRemoveItem(index);
    }
    DnD.removeEventListener(DragEvents.dragEnd, onDragEnd);
    DnD.removeEventListener(DragEvents.drop, onDragAbort);
    setCanReceiveDrops(false);
    setDropAllowed(false);
    setDraggedItem(undefined);
  }, [canReceiveDrops, dropAllowed]);

  // Called by dnd api, if the drag ended (successfully or not)
  const onDragAbort = React.useCallback(() => {
    document.removeEventListener('touchend', onTouchEnd);
    document.removeEventListener('touchmove', onTouchMove);
    DnD.removeEventListener(DragEvents.dragEnd, onDragEnd);
    DnD.removeEventListener(DragEvents.drop, onDragAbort);
    setCanReceiveDrops(false);
    setDropAllowed(false);
    setDraggedItem(undefined);
  }, [canReceiveDrops, dropAllowed, isTouch]);

  const onAnyDragEnded = React.useCallback(() => {
    setDraggable(true);
  }, [draggable]);

  /**
   * Called by us, when we start a drag with one of our items.
   */
  const startDrag = (target: EventTarget & Element, x: number, y: number, index: number) => {
    if (index < items.length) {
      const item = items[index];
      if (!item.resolved) return;
      onDragStarted && onDragStarted(index);
      DnD.startDrag({
        targetGroup: targetGroup,
        originalSourceRefId: item.resolved.$refid,
        srcDropTargetRefId: refid,
        srcDropTargetIndex: index,
        sourceRef: target,
        x,
        y,
        freeSnapping: item.resolved.dragSource.freeSnapping,
        instances: item.resolved.dragSource.instances,
      });
      DnD.addEventListener(DragEvents.dragEnd, onDragEnd); // drag ended nowhere
      DnD.addEventListener(DragEvents.drop, onDragAbort); // dropped item somewhere
    }
  };

  const onMouseDown =
    (index: number) =>
    ({ currentTarget, clientX, clientY }: React.MouseEvent) => {
      startDrag(currentTarget, clientX, clientY, index);
    };

  const onTouchStart = (index: number) => (evt: TouchEvent) => {
    const interactive = !isNil($interactionType) && !disabled;
    /*
     * Testing for interactive here, because it's not feasable to add/remove
     * event handlers in the DOM on component update. I prefer a function call
     * on touchstart and simple check here over some complicated event handler
     * adding/removing mechanism inside SetDragItem!
     */
    if (evt.currentTarget && interactive) {
      startDrag(
        evt.currentTarget as Element,
        evt.touches[0].clientX,
        evt.touches[0].clientY,
        index
      );
      evt.preventDefault();
    }
  };

  React.useEffect(() => {
    DnD.addEventListener(DragEvents.dragStart, onDragStart);
    DnD.addEventListener(DragEvents.dragEnd, onAnyDragEnded);
    DnD.addEventListener(DragEvents.drop, onAnyDragEnded);
    return () => {
      DnD.removeEventListener(DragEvents.dragStart, onDragStart);
      DnD.removeEventListener(DragEvents.dragEnd, onAnyDragEnded);
      DnD.removeEventListener(DragEvents.drop, onAnyDragEnded);
    };
  }, []);

  const interactive = !isNil($interactionType) && !disabled;
  const waitForDrop = canReceiveDrops && interactive;

  let width;
  const isRectangle = style === DragAndDropStyle.rectangle;
  const itemMargin = isRectangle ? 2 * MARGIN : 0;
  if (widthAsFactorOfMaxItemWidth || fixedWidth) {
    width = widthAsFactorOfMaxItemWidth
      ? calculateWidthUsingFactor(maxItemWidth, widthAsFactorOfMaxItemWidth, isRectangle)
      : (fixedWidth as number);
  } else {
    width = percentWidth * 0.01 * availableWidth - itemMargin;
  }

  const resolvedItems = items.filter(isResolved);

  return (
    <span
      role="button"
      ref={targetRef}
      data-droptarget={targetGroup}
      onMouseUp={waitForDrop ? drop : undefined}
      onMouseEnter={waitForDrop ? dragEnter : undefined}
      onMouseLeave={waitForDrop ? dragLeave : undefined}
    >
      <SetDropTarget
        width={width}
        minHeight={minHeight}
        minWidth={
          minWidth ? Math.max(minWidth, maxItemWidth + itemMargin) : maxItemWidth + itemMargin
        }
        onResize={onResize}
        shape={style === DragAndDropStyle.rectangle ? 'rectangle' : 'border'}
        verticalItemAlign={verticalItemAlign}
        itemType={targetGroup}
        error={severity === Severity.error}
        remark={severity === Severity.remark}
        dragOver={!!dropAllowed}
        dragging={dragging}
      >
        {resolvedItems.length ? (
          resolvedItems.map((item, idx: number) => {
            const source = item.resolved.dragSource;
            return (
              <HLayoutChildWrapper key={idx}>
                <SetDragItem
                  shape={source.style === DragAndDropStyle.none ? undefined : source.style}
                  itemType={targetGroup}
                  error={item.severity === Severity.error}
                  fontSize={fontSize}
                  onMouseDown={interactive ? onMouseDown(idx) : undefined}
                  onTouchStart={onTouchStart(idx)}
                  dragging={draggedItem === idx}
                  draggable={interactive && draggable}
                  width={source.width}
                  height={source.height}
                >
                  <PolymorphicGizmo
                    key={item.resolved.dragSource.content.$refid}
                    refid={item.resolved.dragSource.content.$refid}
                    availableWidth={availableWidth}
                    keepLineHeight
                  />
                </SetDragItem>
              </HLayoutChildWrapper>
            );
          })
        ) : (
          <HLayoutChildWrapper>
            <SetDragItem>{NBSP}</SetDragItem>
          </HLayoutChildWrapper>
        )}
      </SetDropTarget>
      <div style={{ lineHeight: 0, fontSize: 0 }}>&nbsp;</div>
    </span>
  );
};

SetDropTargetGizmo.displayName = 'SetDropTargetGizmo';
