import * as React from 'react';
import { isEmpty } from 'lodash/fp';

import {
  type ContextState,
  type ValueSetterMap,
  ValueSetterProvider,
  type ValueStepperConfigMap,
} from '../../gizmo-utils/polymorphic-gizmo';
import {
  type IDynamicRepresentationContent,
  type ValueStepper as ValueStepperType,
} from '@bettermarks/gizmo-types';
import styles from './DynamicRepesentation.scss';
import { evaluateValueSetterExpression } from '@bettermarks/umc-kotlin';
import { valueSetterMapToValidatorValueMap } from '../formula/Formula/helper';
import { DynamicRepresentationWithControls } from './DynamicRepresentationWithControls';

export type DynamicRepresentationCallbacks = {
  onPersistDynamicValue: (id: string) => (value: number) => void;
};

export type DynamicRepresentationProps = IDynamicRepresentationContent &
  DynamicRepresentationCallbacks &
  ContextState;

export const extractMinOrMax = (
  expression: number | string,
  valueSetterMap: ValueSetterMap | undefined
) => {
  if (typeof expression === 'string') {
    if (!valueSetterMap || isEmpty(valueSetterMap)) {
      throw new Error(
        'valueSetterMap is undefined or empty, but it is needed to evaluate the dynamic min or max.'
      );
    }

    return evaluateValueSetterExpression({
      expression,
      valueMap: valueSetterMapToValidatorValueMap(valueSetterMap),
    });
  } else {
    return expression;
  }
};

const PRECISION = Math.pow(10.0, 9);

export const DynamicRepresentation: React.FC<DynamicRepresentationProps> = ({
  sliders,
  onOffControls,
  optionsControls,
  valueSteppers,
  representation,
  availableWidth,
  disabled,
  isTouch,
  $interactionType,
  valueSetterMap,
  onPersistDynamicValue,
}) => {
  const controlValues = [...sliders, ...onOffControls, ...optionsControls].reduce(
    (acc: ValueSetterMap, s) => ({ ...acc, [s.binding]: { value: s.value } }),
    valueSetterMap || {}
  );

  const onValueStepperValueChange = (id: string) => (value: number) => {
    if (onPersistDynamicValue !== undefined) {
      onPersistDynamicValue(id)(Math.round(value * PRECISION) / PRECISION);
    }
  };

  const valueStepperValues = [...valueSteppers].reduce(
    (acc: ValueSetterMap, stepper) => ({
      ...acc,
      [stepper.binding]: {
        value: stepper.value,
      },
    }),
    valueSetterMap || {}
  );

  const [enrichedValueSetterMap, setEnrichedValueSetterMap] = React.useState<ValueSetterMap>({
    ...controlValues,
    ...valueStepperValues,
  });

  const initialValueStepperConfigs = [...valueSteppers].reduce(
    (acc: ValueStepperConfigMap, stepper) => ({
      ...acc,
      [stepper.binding]: {
        min: extractMinOrMax(stepper.min, enrichedValueSetterMap),
        max: extractMinOrMax(stepper.max, enrichedValueSetterMap),
        step: stepper.step,
        position: stepper.position,
        decoration: stepper.decoration,
        initiallyActive: stepper.initiallyActive,
        onChange: onValueStepperValueChange(stepper.binding),
      },
    }),
    {}
  );

  const [valueStepperConfigMap, setValueStepperConfigMap] = React.useState<ValueStepperConfigMap>(
    initialValueStepperConfigs
  );

  React.useEffect(() => {
    const dynamicSteppers: ValueStepperType[] = valueSteppers.filter((valueStepper) => {
      return typeof valueStepper.min === 'string' || typeof valueStepper.max === 'string';
    });

    const updatedSteppersConfig = [...dynamicSteppers].reduce(
      (acc: ValueStepperConfigMap, stepper) => ({
        ...acc,
        [stepper.binding]: {
          ...valueStepperConfigMap[stepper.binding],
          min: extractMinOrMax(stepper.min, enrichedValueSetterMap),
          max: extractMinOrMax(stepper.max, enrichedValueSetterMap),
        },
      }),
      valueStepperConfigMap
    );

    setValueStepperConfigMap(updatedSteppersConfig);
  }, [enrichedValueSetterMap]);

  // overwrite local state when sliders prop changes
  React.useEffect(
    () =>
      setEnrichedValueSetterMap({
        ...enrichedValueSetterMap,
        ...controlValues,
        ...valueStepperValues,
      }),
    [sliders, onOffControls, optionsControls, valueSteppers]
  );

  const onControlValueChange = (id: string) => (value: number) => {
    setEnrichedValueSetterMap({
      ...enrichedValueSetterMap,
      [id]: { ...enrichedValueSetterMap[id], value },
    });
  };

  return (
    <div className={styles.wrapper}>
      <ValueSetterProvider
        valueSetterMap={enrichedValueSetterMap}
        valueStepperConfigMap={valueStepperConfigMap}
      >
        <DynamicRepresentationWithControls
          onOffControls={onOffControls}
          optionsControls={optionsControls}
          sliders={sliders}
          representation={representation}
          onControlValueChange={onControlValueChange}
          onPersistDynamicValue={onPersistDynamicValue}
          availableWidth={availableWidth}
          disabled={disabled}
          isTouch={isTouch}
          $interactionType={$interactionType}
        />
      </ValueSetterProvider>
    </div>
  );
};
