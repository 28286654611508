import * as React from 'react';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import { type GeoCallbacks, type GeoContent, GeoEditorMode } from '@bettermarks/gizmo-types';
import { GeoRenderer } from '../geo';
import { PIXEL_PER_XTICK } from '@bettermarks/importers';
import { LEFTSPACE, transformationSettings } from './transform';

export type Draw2dGeoProps = GeoContent & GeoCallbacks & ContextState;

export const Draw2dGeo: React.FC<Draw2dGeoProps> = (props, context) => {
  const { configuration, availableWidth } = props;
  const { width } = configuration.display;

  // get a 'scale candidate by the proportion available width
  // and 20 ticks (that is the assigned base width!)
  const scaleC =
    availableWidth - 2 * LEFTSPACE < 20 * PIXEL_PER_XTICK
      ? (availableWidth - 2 * LEFTSPACE) / (20 * PIXEL_PER_XTICK)
      : 1;
  // 'lower' boundary for scale is based on minimum width of
  // 200 pixels, 'upper' boundary for scale is 1!
  const scale = Math.min(
    width * PIXEL_PER_XTICK * scaleC > 200 ? scaleC : 200 / (width * PIXEL_PER_XTICK),
    1
  );

  return (
    <GeoRenderer
      {...{
        ...props,
        ...transformationSettings(configuration, scale),
        mode: GeoEditorMode.NOT_INTERACTIVE,
        scale,
      }}
    />
  );
};
