import * as React from 'react';
import classNames from 'classnames';
import { flatten, isEmpty } from 'lodash';
import { SlidersContainer } from '../components';
import { getScale } from '../../gizmo-utils/getScale';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import { type CommonCallbacks, GeoEditorMode, ControlPosition } from '@bettermarks/gizmo-types';

import { GeoRenderer } from '../geo/GeoRenderer';
import { transformationSettings } from '@bettermarks/importers';

import {
  type FunctionPlotterContent,
  type ParametricFunction,
  RenderMode,
} from '@bettermarks/gizmo-types';
import { geoInject } from './geo';
import { mapSliders, setFunctionParameter, getSliderBoundariesSize } from './helpers';
import FormulaList from './FormulaList';

import styles from './FunctionPlotter.scss';
import { Severity } from '@bettermarks/umc-kotlin';

const RESPONSIVE_BREAKPOINT = 240;
const ADDITIONAL_WIDTH = 50;

export type FunctionPlotterCallbacks = {
  onFocusParam: (id: string) => React.FocusEventHandler<any>;
  onBlurParam: (id: string) => React.FocusEventHandler<any>;
  onSetParam: (id: string) => (value: number) => void;
};

export type FunctionPlotterProps = FunctionPlotterContent &
  FunctionPlotterCallbacks &
  CommonCallbacks &
  ContextState;

export interface FunctionPlotterState {
  functions?: ReadonlyArray<ParametricFunction>;
}

/**
 * TODO: this function plotter should be refactored, the state and properties
 * are very inconsistent which lead to very weird behaviour when refactoring other
 * components (in my case the slider)
 *
 * The FunctionPlotter is special regarding interaction in the following ways:
 * `rendererAllowsInteraction`:
 *   true: even when in rendered or disabled state the user can interact with it
 *   false: the user can only interact with it when in interactive state
 * Because of this it is important whether or not the sliders can be focused:
 * When it is in a setting it is usable for exploring values
 * (and those are changing the store)
 * but it shouldn't cause a gizmo in the question to get deselected.
 */
export class FunctionPlotter extends React.PureComponent<
  FunctionPlotterProps,
  FunctionPlotterState
> {
  state = { functions: undefined };

  onSetIntermediateParam = (id: string) => (value: number) => {
    this.setState((state) => ({
      functions: setFunctionParameter(id, value, state.functions || this.props.functions),
    }));
  };

  remainingWidthForHorizontalSlider = (scale: number | undefined) => {
    const { availableWidth, geo } = this.props;
    const remaining = availableWidth - (geo?.totalWidth || 0) * (scale || 1);
    // Due to flex layout, sliders break below geo when geo leaves less than 480px width
    // (480px = max-width of slider container).
    // In that case the slider container can receive full available width
    return remaining >= 480 ? remaining : availableWidth;
  };

  getCalculatedChildrenProps = () => {
    const { availableWidth, disabled, geo, $interactionType } = this.props;

    const selectable = !isEmpty($interactionType) && !disabled;
    const scale = geo && getScale(geo.totalWidth, availableWidth - ADDITIONAL_WIDTH * 1.5, 1);
    const functions = this.state.functions || this.props.functions;

    const geoWidth = geo ? geo.totalWidth : 0;
    const isSmallScreen = availableWidth - geoWidth - ADDITIONAL_WIDTH < RESPONSIVE_BREAKPOINT;

    /* eslint-disable-next-line no-bitwise */
    const formulas = flatten(functions.filter((f) => f.renderMode & RenderMode.Formula));

    const availableSize = getSliderBoundariesSize(
      geo ? geo.totalHeight * (scale || 1) : 0,
      mapSliders(functions).length > 1,
      RESPONSIVE_BREAKPOINT
    );

    return {
      selectable,
      scale,
      functions,
      formulas,
      isSmallScreen,
      availableSize,
    };
  };

  // overwrite local state when functions prop changes
  componentDidUpdate(prevProps: FunctionPlotterProps) {
    if (this.props.functions !== prevProps.functions) {
      this.setState({ functions: this.props.functions });
    }
  }

  render() {
    const {
      availableWidth,
      disabled,
      isTouch,
      geo,
      selectedParameter,
      onFocus,
      onFocusParam,
      onBlurParam,
      onSetParam,
    } = this.props;

    const { functions, formulas, selectable, scale, isSmallScreen, availableSize } =
      this.getCalculatedChildrenProps();

    const formulaList = (
      <div className={styles.formulaList}>
        <FormulaList
          availableWidth={this.props.availableWidth}
          formulas={formulas}
          isSmallScreen={isSmallScreen}
        />
      </div>
    );

    const isVertical = isSmallScreen;

    // needs to be done here, so that error decoration is removed once user interacts with slider after validation
    const hasError = Boolean(
      functions.find((f) => f.parameters.find((p) => p.severity === Severity.error))
    );

    return (
      <div
        onFocus={selectable ? onFocus : undefined}
        className={classNames(styles.functionPlotter, isSmallScreen && styles.functionPlotterXs)}
        style={{ width: availableWidth, display: 'flex' }}
      >
        {geo && (
          <GeoRenderer
            {...{
              ...geoInject(hasError)(
                /* eslint-disable-next-line no-bitwise */
                functions.filter((f) => f.renderMode & RenderMode.Graph)
              )(geo),
              ...transformationSettings(geo, scale),
              mode: GeoEditorMode.NOT_INTERACTIVE,
              scale,
            }}
          />
        )}
        <div className={classNames(styles.panel, isSmallScreen && styles.smallPanel)}>
          {!isSmallScreen && formulaList}
          <SlidersContainer
            availableSize={
              !isVertical ? this.remainingWidthForHorizontalSlider(scale) : availableSize
            }
            slidersData={mapSliders(functions)}
            selectable={selectable}
            onFocusParam={onFocusParam}
            onBlurParam={onBlurParam}
            onSetParam={onSetParam}
            onSetIntermediateParam={this.onSetIntermediateParam}
            isTouch={isTouch}
            disabled={disabled}
            selectedParameter={selectedParameter}
            isSmallScreen={isSmallScreen}
            position={ControlPosition.right}
          />
        </div>
        {isSmallScreen && formulaList}
      </div>
    );
  }
}
