import * as React from 'react';
import classNames from 'classnames';

import styles from '../FormStyles.scss';
import { CX, CY, HEIGHT, WIDTH } from './constants';

type CircleProps = {
  width: number;
  height: number;
  small: boolean;
  filled: boolean;
  hover: boolean;
  remove?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

export const Circle: React.FC<CircleProps> = ({
  width,
  height,
  small,
  filled,
  hover,
  onClick,
  onMouseOver,
  onMouseLeave,
}) => {
  const onePX = WIDTH / width;

  return (
    <svg
      className={small ? styles.outerSmall : styles.outer}
      width={width}
      height={height}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      <circle
        cx={CX}
        cy={CY}
        r={WIDTH / 2 - onePX}
        className={classNames(filled ? styles.filled : styles.empty, {
          [styles.hover]: hover,
        })}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    </svg>
  );
};
