import { type Action, type ActionFunction1 } from 'redux-actions';

import { gizmoAction, type GizmoAction, connectGizmo } from '../../../gizmo-utils/redux';
import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { ShouldEnrichKind } from '@bettermarks/gizmo-types';
import { type GizmoShortcutsMap, simpleShortcut } from '../../../gizmo-utils/keyboard';

import { Formula, type FormulaCallbacks } from './Formula';
import {
  backwardDelete,
  cursorLeft,
  cursorRight,
  enterBracket,
  enterFrac,
  enterIdentifier,
  enterInvalidIdentifier,
  enterNumber,
  enterOperator,
  enterSup,
  forwardDelete,
  setCursor,
} from './actions';
import { type MapDispatchToPropsFunction } from 'react-redux';

type MakeShortcutEntry = <T>(
  actionFunction: ActionFunction1<string, Action<T>>
) => (chr: string) => [string, Action<T>];
const makeShortcutEntry: MakeShortcutEntry = (actionFunction) => (chr) =>
  [simpleShortcut(chr), actionFunction(chr)];

// this list needs to be in sync with the available keys in Tools/Keyboard: CharBlock and keyCodes
const charActions: [string, Action<string>][] = 'abchmnpqrstuvxyzπ'
  .split('')
  .map(makeShortcutEntry(enterIdentifier));

// additional key not in the Tools/Keyboard, that should be accepted to trigger feedback
// instead of silently ignored
const invalidCharActions: [string, Action<string>][] = 'defgijklow'
  .split('')
  .map(makeShortcutEntry(enterInvalidIdentifier));

const numActions: [string, Action<string>][] = '0123456789.'
  .split('')
  .map(makeShortcutEntry(enterNumber));

const opActions: [string, Action<string>][] = '+*-/'
  .split('')
  .map(makeShortcutEntry(enterOperator));

const brackActions: [string, Action<string>][] = '()'
  .split('')
  .map(makeShortcutEntry(enterBracket));

export const shortcuts: GizmoShortcutsMap = [
  ...charActions,
  ...invalidCharActions,
  ...numActions,
  ...opActions,
  ...brackActions,
  [simpleShortcut(':'), enterOperator('/')],
  [simpleShortcut('/'), enterFrac()],
  [simpleShortcut('Dead'), enterSup()],
  [simpleShortcut(','), enterNumber('.')],
  [simpleShortcut('Backspace'), backwardDelete()],
  [simpleShortcut('Delete'), forwardDelete()],
]
  .map<[string, GizmoAction<any>]>(([shortcutKey, shortcutAction]) => [
    // convert to gizmo actions (with dummy id)
    shortcutKey as string,
    gizmoAction(shortcutAction as Action<any>, 'dummy', {
      shouldEnrich: ShouldEnrichKind.justEnrich,
    }),
  ])
  .concat([
    // append actions with skip undo set
    [
      simpleShortcut('ArrowLeft'),
      gizmoAction(cursorLeft(), 'dummy', {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        skipUndo: true,
      }),
    ],
    [
      simpleShortcut('ArrowRight'),
      gizmoAction(cursorRight(), 'dummy', {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        skipUndo: true,
      }),
    ],
  ])
  .reduce<GizmoShortcutsMap>(
    (gizmoShortcutsMap, [shortcutKey, shortcutGizmoAction]) => ({
      ...gizmoShortcutsMap,
      [shortcutKey]: shortcutGizmoAction,
    }),
    {}
  );

const mapDispatchToProps: MapDispatchToPropsFunction<FormulaCallbacks, GizmoProps> = (
  dispatch,
  { refid }
) => ({
  onSelect: (path, mode) => {
    dispatch(
      gizmoAction(setCursor({ path, mode }), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
        skipUndo: true,
      })
    );
  },
});

export const FormulaContainer = connectGizmo(mapDispatchToProps, Formula, {
  shortcuts,
});
FormulaContainer.displayName = 'FormulaContainer';
