import * as React from 'react';
import { defaultTo, isNil } from 'lodash';
import Measure, { type ContentRect } from 'react-measure';

import { PolymorphicGizmo } from '../../../gizmo-utils/polymorphic-gizmo';

import {
  type MultipleChoiceSingleChoiceContent as ChoiceContent,
  type VisualContent,
} from '@bettermarks/gizmo-types';
import MultipleChoiceCollapsedView from './MultipleChoiceCollapsedView';
import { type MultipleChoiceSingleProps } from './MultipleChoiceSingle';
import { MultipleChoiceSingleItem } from './MultipleChoiceSingleItem';
import styles from './StackLayout.scss';

/**
 * state
 */
export interface StackLayoutState {
  maxWidth: number;
  maxHeight: number;
  collapsedView: boolean;
  hoveredIdx: number;
  preRendering: boolean;
  widthMeasurementCount: number;
  heightMeasurementCount: number;
}

/**
 * The `StackLayout` component is a clever layout wrapper around
 *
 * - choices content
 * - visualization content
 *
 * of MC with visualization.
 */
export class StackLayout extends React.Component<MultipleChoiceSingleProps, StackLayoutState> {
  private preRenderStack: Partial<VisualContent>[];

  state: StackLayoutState = {
    maxWidth: 0,
    maxHeight: 0,
    collapsedView: false,
    hoveredIdx: -1,
    preRendering: true,
    widthMeasurementCount: 0,
    heightMeasurementCount: 0,
  };

  /**
   * In the component setup we want to calculate a dictionary of all the visualContent we need
   * to render, to avoid rendering twice the same element, once a choice is selected we have
   * already a mapping that indicates which VisualRefId is related to that ChoiceId, without the
   * need to have a VisualContent represented for each choice
   * */
  componentWillMount(): void {
    const visualRefIds: { [key: string]: boolean } = {};
    // We build up the most comprehensive list we can
    this.preRenderStack = [
      ...this.props.choices,
      this.props.configuration.stackLayout !== undefined
        ? this.props.configuration.stackLayout.defaultSelection
        : {},
    ]
      .map((choice: ChoiceContent) => {
        // we take only the two elements needed for this context
        const { visualContent, visualRefId } = { ...choice };
        return { visualContent, visualRefId };
      })
      .filter(
        (choice, idx: number, self) =>
          // finally we check if an element was not already copied here (double reference)
          self.indexOf(choice) === idx &&
          !(
            // and we check if the particular visualRefId has already been stored in the array, in
            // that case we don't need to store it twice, a visualRefId must represent always the
            // same visualContent
            (isNil(choice.visualContent) ||
            isNil(choice.visualRefId) ||
            visualRefIds.hasOwnProperty(choice.visualRefId)
              ? true
              : !(visualRefIds[choice.visualRefId] = true))
          )
      );
  }

  UNSAFE_componentWillReceiveProps(): void {
    this.updateLayout();
  }

  private readonly updateLayout = (): void => {
    this.setState((prevState: StackLayoutState, props: MultipleChoiceSingleProps) => ({
      collapsedView: props.availableWidth < prevState.maxWidth,
    }));
  };

  /**
   * We need two "pre rendering" measurement cycles to ge maxWidth and maxHeight
   *
   * 1. Render all visualizations next to each other (in one row) to measure maxHeight
   * 2. Render all visualizations below each other to measure maxWidth
   *
   * Doing those two measurements in a fixed order is now done with a counter for both dimensions.
   *
   * If the height counter is 0, then we take the measured height as maxHeight.
   *
   * If the width counter is 1 (we already measured a width in step 1., hence are now in step 2.),
   * we take the measured width as maxWidth and end the "pre rendering" phase.
   */
  private readonly setMaxWidth = ({ entry }: ContentRect): void => {
    if (entry) {
      this.setState((prevState: StackLayoutState, props: MultipleChoiceSingleProps) => {
        const maxWidth = prevState.widthMeasurementCount === 1 ? entry.width : prevState.maxWidth;
        return {
          ...prevState,
          preRendering: prevState.widthMeasurementCount < 1,
          maxWidth,
          collapsedView: props.availableWidth < maxWidth,
          widthMeasurementCount: prevState.widthMeasurementCount + 1,
        };
      });
    }
  };

  /**
   * See comment above setMaxWidth
   */
  private readonly setMaxHeight = ({ entry }: ContentRect): void => {
    if (entry) {
      this.setState((prevState) => {
        const maxHeight =
          prevState.heightMeasurementCount === 0 ? entry.height : prevState.maxHeight;
        return {
          ...prevState,
          maxHeight,
          preRendering: prevState.widthMeasurementCount < 1,
          heightMeasurementCount: prevState.heightMeasurementCount + 1,
        };
      });
    }
  };

  private readonly updateHoveredChoice = (hoveredIdx: number, isHovered: boolean): void => {
    this.setState({
      hoveredIdx: isHovered ? hoveredIdx : -1,
    });
  };

  private readonly getMCItems = (
    choices: ChoiceContent[],
    availableWidth: number
  ): JSX.Element[] => {
    const elementsPerLine = defaultTo<number>(
      this.props.configuration.elementsPerLine,
      Number.MAX_VALUE
    );
    const interactive = !this.props.disabled;

    let choicesElements = choices.map((element: ChoiceContent, i) => {
      let itemChosen;
      if (interactive && this.props.itemChosen) {
        itemChosen = this.props.itemChosen.bind(null, i);
      }

      const MCItem = (
        <MultipleChoiceSingleItem
          index={i}
          selected={this.props.checkedIdx === i}
          content={element.contentRef}
          interactive={interactive}
          radioButtons={this.props.configuration.radioButtons}
          onChosen={itemChosen}
          severity={this.props.severity}
          collapsedView={this.state.collapsedView}
          onHover={!this.props.isTouch && interactive ? this.updateHoveredChoice : undefined}
          availableWidth={availableWidth}
          stackLayout={true}
        />
      );
      return (
        <span key={i}>
          {MCItem}
          {(i + 1) % elementsPerLine === 0 && !this.state.collapsedView ? <br /> : ''}
        </span>
      );
    });

    if (this.state.collapsedView) {
      if (this.state.hoveredIdx !== -1 || this.props.checkedIdx !== -1) {
        const index = this.state.hoveredIdx !== -1 ? this.state.hoveredIdx : this.props.checkedIdx;
        const choiceToShow = choices[index].contentRef;
        choicesElements = [
          <MultipleChoiceCollapsedView
            key={`MultipleChoiceCollapsedView-${index}`}
            choicesElements={choicesElements}
            choiceToShow={choiceToShow}
            stackLayout={true}
            availableWidth={availableWidth}
          />,
        ];
      }
    }

    return choicesElements;
  };

  /**
   * returns the content $refid for the visual content
   * that corresponds to the selected choice
   *  or
   * the default visual content
   */
  private readonly getVisualContentRefId = (choices: any[]): string => {
    let visualContentObj = { visualContent: { $refid: '' } };

    if (
      this.props.configuration.stackLayout !== undefined &&
      this.props.configuration.stackLayout.defaultSelection !== undefined
    ) {
      visualContentObj = this.props.configuration.stackLayout.defaultSelection;
    }

    const visuIdx = this.state.hoveredIdx > -1 ? this.state.hoveredIdx : this.props.checkedIdx;
    if (visuIdx > -1) {
      visualContentObj = choices.find((obj, index) => {
        return index === visuIdx;
      });
    }

    return visualContentObj.visualContent.$refid;
  };

  private readonly currentVisu = (): JSX.Element[] => {
    return this.preRenderStack.map((choice: ChoiceContent, i: number) => (
      <Measure scroll onResize={this.setMaxHeight} key={i}>
        {({ measureRef }: any) => {
          const displayStyle =
            this.state.preRendering ||
            (!isNil(choice.visualContent) &&
              this.getVisualContentRefId(this.props.choices) === choice.visualContent.$refid)
              ? 'inherit'
              : 'none';

          return (
            <div ref={measureRef} style={{ display: displayStyle }}>
              {!isNil(choice.visualContent) && (
                <PolymorphicGizmo
                  refid={choice.visualContent.$refid}
                  availableWidth={this.props.availableWidth}
                />
              )}
            </div>
          );
        }}
      </Measure>
    ));
  };

  render(): JSX.Element {
    const styleInner = this.state.collapsedView ? styles.choicesBoxCollapsed : styles.visuBox;
    const visuBox = this.state.preRendering
      ? this.state.heightMeasurementCount === 0
        ? styles.visuBoxPrerenderingForHeightMeasurement
        : styles.visuBoxPrerenderingForWidthMeasurement
      : styles.visuBox;
    const visuStyle = this.state.collapsedView ? styles.visuBoxCollapsed : visuBox;

    const choicesElements = this.getMCItems(this.props.choices, this.props.availableWidth);

    const style = !this.state.collapsedView ? { height: this.state.maxHeight } : undefined;

    return (
      <Measure scroll onResize={this.setMaxWidth}>
        {({ measureRef }: any) => (
          <div ref={measureRef} className={styles.outer}>
            <div className={styleInner} style={style}>
              {choicesElements}
            </div>
            <div className={visuStyle} style={style}>
              {this.currentVisu()}
            </div>
          </div>
        )}
      </Measure>
    );
  }
}
