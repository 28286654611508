import * as React from 'react';
import { CIRCLE_LARGE, CIRCLE_SMALL } from '../../../constants';
import styles from '../FormStyles.scss';
import { PieSegment } from './PieSegment';
import { Circle } from './Circle';
import { HEIGHT, WIDTH } from './constants';
import { type SegmentedProps } from '../types';

export const SegmentedPie: React.FC<SegmentedProps> = ({
  bitmask,
  small,
  hoverIndex,
  onToggleSegment,
  onHoverSegment,
  onLeaveSegment,
}) => {
  const width = (small ? CIRCLE_SMALL.cx : CIRCLE_LARGE.cx) * 2;
  const height = (small ? CIRCLE_SMALL.cy : CIRCLE_LARGE.cy) * 2;
  return bitmask.length > 1 ? (
    <svg
      width={width}
      height={height}
      className={small ? styles.segmentedPieSmall : styles.segmentedPie}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      {bitmask.map((filled, i) => (
        <PieSegment
          key={i}
          index={i}
          denom={bitmask.length}
          filled={filled}
          hover={hoverIndex === i}
          pxWidth={width}
          onToggleSegment={onToggleSegment ? () => onToggleSegment(i) : undefined}
          onMouseOver={onHoverSegment ? () => onHoverSegment(i) : undefined}
          onMouseLeave={onLeaveSegment ? onLeaveSegment : undefined}
        />
      ))}
    </svg>
  ) : (
    <Circle
      width={width}
      height={height}
      small={small}
      filled={bitmask[0]}
      hover={hoverIndex === 0}
      onClick={onToggleSegment ? () => onToggleSegment(0) : undefined}
      onMouseOver={onHoverSegment ? () => onHoverSegment(0) : undefined}
      onMouseLeave={onLeaveSegment ? onLeaveSegment : undefined}
    />
  );
};
