import {
  type ALayoutContent,
  type ContentReference,
  type LayoutContainerContent,
} from '@bettermarks/gizmo-types';
import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration';
import { DEFAULT_FONT_SIZE_FLASH, fontSizeFromScale } from '../../gizmo-utils/measure';

export const applyLayoutContainerStyles: ApplyStyles = (content: LayoutContainerContent, style) => {
  const references: ReadonlyArray<ContentReference> = content.content;
  return references.map(({ $refid: refId }) => ({ refId, style }));
};

const MIN_A_LAYOUT_FONT_SIZE = 8;

export const applyAbsoluteLayoutStyles: ApplyStyles = (content: ALayoutContent, style) => {
  const references: ReadonlyArray<ContentReference> = content.content;
  const scale = content.scale;

  return references.map(({ $refid: refId }) => ({
    refId,
    style: {
      ...style,
      formulaStyles: {
        ...style.formulaStyles,
        fontSize: fontSizeFromScale(scale, DEFAULT_FONT_SIZE_FLASH, MIN_A_LAYOUT_FONT_SIZE),
      },
      scaleProps: { minimumFontSize: MIN_A_LAYOUT_FONT_SIZE, scale },
    },
  }));
};

export const measureAbsoluteLayout: Ruler = (_, { unscaledHeight }: ALayoutContent) => ({
  height: unscaledHeight,
  refLine: unscaledHeight * 0.5,
});
