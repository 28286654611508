import { type ExporterRegistry, RS } from '@bettermarks/gizmo-types';
import { exportAbsoluteLayout } from './gizmos/layout-container/exporter/exportAbsoluteLayout';
import { exportAnimation } from './gizmos/animation/exporter';
import { exportBalance } from './gizmos/balance/exporter';
import { exportBallotBox } from './gizmos/ballot-box/exporter';
import { exportBasicShape } from './gizmos/basic-shape/exporter';
import { exportBorderLayout } from './gizmos/layout-container/exporter/exportBorderLayout';
import { exportCalendar } from './gizmos/calendar/exporter';
import {
  exportCaption,
  exportDiagramBarChart,
  exportPieChart,
  exportStackedBarChart,
} from './gizmos/chart/exporter';
import { exportClock } from './gizmos/clock/exporter';
import { exportDayDurationGizmo } from './gizmos/day-duration/exporter';
import { exportFormula } from './gizmos/formula/exporter/exportFormula';
import { exportDraw2dGeo } from './gizmos/draw2d/exporter';
import { exportBox } from './gizmos/_box/exporter';
import { exportFieldSet } from './gizmos/fieldset/exporter';
import { exportFunctionPlotter } from './gizmos/function-plotter/exporter';
import { exportHelloGizmo } from './gizmos/_template/exporter';
import { exportHLine } from './gizmos/hline/exporter';
import { exportGraph } from './gizmos/graph/Graph/exporter';
import { exportImage } from './gizmos/image/exporter';
import { exportIncludeGizmo } from './gizmos/include-gizmo/exporter';
import { exportItemList } from './gizmos/item-list/exporter';
import { exportLayoutContainer } from './gizmos/layout-container/exporter/exportLayoutContainer';
import { exportLineHeader } from './gizmos/line-header/exporter';
import { exportGeo } from './gizmos/geo/exporter';
import { exportMultipleChoiceDropDown } from './gizmos/multiple-choice-drop-down/exporter';
import { exportMultipleChoiceMulti } from './gizmos/multiple-choice-multi/exporter';
import {
  exportMultipleChoiceSingle,
  exportMultipleChoiceSingleStack,
} from './gizmos/multiple-choice-single/exporter';
import { exportPointer } from './gizmos/pointer/exporter';
import { exportSolutionSet } from './gizmos/solution-set/exporter';
import { exportSorting } from './gizmos/sorting/exporter';
import { exportSquaredPaper } from './gizmos/squaredpaper/exporter';
import { exportThermometer } from './gizmos/thermometer/exporter';
import { exportTimeDuration } from './gizmos/time-duration/exporter';
import { exportTreeChartGizmo } from './gizmos/graph/TreeChart/exporter';
import { exportWheelOfFortune } from './gizmos/wheel-of-fortune/exporter';
import { exportDragSource } from './gizmos/drag-and-drop/dragsource/exporter';
import { exportDropTarget } from './gizmos/drag-and-drop/droptarget/exporter';
import { exportSetDropTarget } from './gizmos/drag-and-drop/set-droptarget/exporter';
import { exportFractionForm } from './gizmos/fraction-form/exporter';
import { exportTimeSpan } from './gizmos/time-span/exporter';
import { exportTime } from './gizmos/time/exporter';
import { exportTable, exportTableContainer } from './gizmos/table/exporter';
import { exportPdfLink } from './gizmos/pdf-link/exporter';
import { exportListSelection } from './gizmos/list-selection/exporter';

export const exporters: ExporterRegistry = {
  [RS.ABSOLUTE_LAYOUT]: exportAbsoluteLayout,
  [RS.ANIMATION]: exportAnimation,
  [RS.BALANCE]: exportBalance,
  [RS.BALLOT_BOX]: exportBallotBox,
  [RS.BASIC_SHAPE]: exportBasicShape,
  [RS.BORDER_LAYOUT]: exportBorderLayout,
  [RS.CALENDAR]: exportCalendar,
  [RS.CAPTION_BAR_CHART]: exportCaption,
  [RS.CAPTION_CURVE_CHART]: exportCaption,
  [RS.CAPTION_PIE_CHART]: exportCaption,
  [RS.CIRCLE]: exportFractionForm,
  [RS.CLOCK]: exportClock,
  [RS.CLOCKTIME]: exportTime,
  [RS.DAY_DURATION]: exportDayDurationGizmo,
  [RS.DIAGRAM_BAR_CHART]: exportDiagramBarChart,
  [RS.DIGIT]: exportFormula,
  [RS.DRAG_SOURCE]: exportDragSource,
  [RS.DRAW2DGEO]: exportDraw2dGeo,
  [RS.DROP_TARGET]: exportDropTarget,
  [RS.EMPTY]: exportBox,
  [RS.FIELD_SET]: exportFieldSet,
  [RS.FORMULA]: exportFormula,
  [RS.FUNCTION_PLOTTER]: exportFunctionPlotter,
  [RS.HELLO_GIZMO]: exportHelloGizmo,
  [RS.HLINE]: exportHLine,
  [RS.GRAPH]: exportGraph,
  [RS.IMAGE]: exportImage,
  [RS.INCLUDE_GIZMO]: exportIncludeGizmo,
  [RS.ITEM_LIST]: exportItemList,
  [RS.INNER_TEXT]: exportFormula,
  [RS.LAYOUT_CONTAINER]: exportLayoutContainer,
  [RS.LINE_HEADER]: exportLineHeader,
  [RS.LIST_SELECTION]: exportListSelection,
  [RS.METRICS_SYSTEM]: exportGeo,
  [RS.MULTIPLE_CHOICE_DROP_DOWN]: exportMultipleChoiceDropDown,
  [RS.MULTIPLE_CHOICE_MULTI]: exportMultipleChoiceMulti,
  [RS.MULTIPLE_CHOICE_SINGLE]: exportMultipleChoiceSingle,
  [RS.PDF_LINK]: exportPdfLink,
  [RS.PIE_CHART]: exportPieChart,
  [RS.PLACE_VALUE_TABLE]: exportTable,
  [RS.POINTER]: exportPointer,
  [RS.RECTANGLE]: exportFractionForm,
  [RS.SET_DROP_TARGET]: exportSetDropTarget,
  [RS.SIMPLE_TABLE]: exportTable,
  [RS.SIMPLE_TABLE_CONTAINER]: exportTableContainer,
  [RS.SOLUTION_SET]: exportSolutionSet,
  [RS.SORTING]: exportSorting,
  [RS.SQUARED_PAPER]: exportSquaredPaper,
  [RS.STACK_LAYOUT]: exportMultipleChoiceSingleStack,
  [RS.STACKED_BAR_CHART]: exportStackedBarChart,
  [RS.TABLECELL_TEXT]: exportFormula,
  [RS.TEXT]: exportFormula,
  [RS.THERMOMETER]: exportThermometer,
  [RS.TIME]: exportTime,
  [RS.TIME_DURATION]: exportTimeDuration,
  [RS.TIMESPAN]: exportTimeSpan,
  [RS.TREE_DIAGRAM]: exportTreeChartGizmo,
  [RS.WHEEL_OF_FORTUNE]: exportWheelOfFortune,
};
