import * as React from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonKind,
  ButtonSize,
  ContextNotification,
  ContextNotificationKind,
} from '@seriesplayer/common-ui';
import { type ContentDict, type ErrorMessage, numberFromStyles } from '@bettermarks/gizmo-types';
import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import { type Dispatch } from '../../../../gizmo-utils/redux/types';
import { identity } from 'lodash';
import { useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';
import {
  type AdditionalHelpType,
  type AppStep,
  type CollapsibleContentState,
  DialogType,
  type ExerciseStatus,
  type SeriesFlow,
  SeriesMode,
} from '../../../../types';
import { CollapsibleContentStyle, ContentBox, StepComponent, StepHeader } from '../../components';
import { StepTitleTranslationKey } from '../../components/Step/StepComponent';
import { closeDialog, openDialog } from '../SeriesPlayer/actions';
import { updateFilledStatus } from './actions';
import styles from './exercise.scss';
import { ExerciseToolTip } from './ExerciseToolTip';
import {
  getScrollableElement,
  getScrollTarget,
  isInViewport,
  shouldShowTooltip,
  showIrrevocablyButton,
  withoutSmoothScrolling,
} from './helper';
import classNames from 'classnames';

const ButtonFloatRight = styled(Button)`
  float: right;
`;

const PreviewStepsHeader = styled.h3`
  color: #999999;
  font-weight: normal;
  font-style: italic;
  padding: 8px 0 4px;
`;

const PreviewStep = styled(ContextNotification).attrs({
  kind: ContextNotificationKind.neutral,
  displayFlex: true,
})``;

const PreviewStepWrapper = styled.div`
  margin-top: 8px;
`;

export type ExerciseStateProps = Readonly<{
  additionalHelpType?: AdditionalHelpType;
  handIn?: boolean;
  steps: ReadonlyArray<AppStep>;
  currentStepId?: string;
  hideHelpTools?: boolean;
  setting: ContentDict;
  wrapup?: ContentDict;
  wrapupState: CollapsibleContentState;
  availableWidth: number;
  availableHeight: number;
  stepValidationLoaded?: boolean;
  navigatedAwayFromExercise?: boolean;
  exerciseId: string;
  switched?: boolean;
  isKeyboardOpen?: boolean;
  isTouch: boolean;
  status?: ExerciseStatus;
  selectedRefId?: string;
  toolTipMessage?: ErrorMessage;
  mode: SeriesMode;
  dialogType?: DialogType;
  flow: SeriesFlow;
  staticMediaUrl: string;
}>;

export type ExerciseDispatchProps = Readonly<{
  dispatch: Dispatch;
}>;

export type ExerciseProps = ExerciseStateProps & ExerciseDispatchProps;
interface Props extends ExerciseProps {
  t: TFunction;
}

export const StepAdditionHelpTitle = {
  [DialogType.hints]: 'seriesplayer:step.showHint',
  [DialogType.textbook]: 'seriesplayer:step.showExample',
};

class _Exercise extends React.Component<Props> {
  // Creating ref for the setting and list of steps
  stepRefs: React.RefObject<HTMLDivElement>[] = this.getStepRefs(this.props.exerciseId);
  selectedGizmoRef: React.RefObject<HTMLDivElement> = React.createRef();
  exerciseRef = React.createRef<HTMLDivElement>();

  getStepRefs(
    currentExerciseId: string,
    previousExerciseId?: string
  ): React.RefObject<HTMLDivElement>[] {
    return currentExerciseId !== previousExerciseId
      ? this.props.steps.map(() => React.createRef())
      : this.stepRefs;
  }

  scrollTo(scrollYPosition: number) {
    withoutSmoothScrolling((scrollableNode) => {
      scrollableNode.scrollTo(0, scrollYPosition);
    });
  }

  scrollIntoView(element: HTMLElement) {
    if (!isInViewport(element)) {
      withoutSmoothScrolling((scrollableNode) => {
        element.scrollIntoView(false);
        scrollableNode.scrollTo(0, scrollableNode.scrollTop + 10);
      });
    }
  }

  /**
   * The getSnapshotBeforeUpdate lifecycle hook is used here to
   * automatically scroll to current step
   */
  getSnapshotBeforeUpdate(prevProps: ExerciseProps) {
    const { exerciseId, isKeyboardOpen } = this.props;

    this.stepRefs = this.getStepRefs(exerciseId, prevProps.exerciseId);

    const scrollTarget = getScrollTarget(prevProps, this.props);
    if (scrollTarget && scrollTarget.type === 'setting') {
      this.scrollTo(0);
    } else if (scrollTarget && scrollTarget.type === 'step') {
      // Setting timeout to apply auto scroll once styling completed
      setTimeout(() => {
        // scroll the top of the step as high as possible in the viewport
        const currentStepElement =
          this.stepRefs &&
          this.stepRefs[scrollTarget.stepIndex] &&
          this.stepRefs[scrollTarget.stepIndex].current;
        if (currentStepElement) {
          const currentStepTop = currentStepElement.offsetTop;
          const viewportTop = getScrollableElement()?.getBoundingClientRect().top;
          if (viewportTop !== undefined) {
            this.scrollTo(currentStepTop - viewportTop);
          }
        }
      }, 500);
    } else if (isKeyboardOpen && prevProps.selectedRefId !== this.props.selectedRefId) {
      // scroll the input field to the bottom of viewport if the keyboard was opened
      setTimeout(() => {
        if (this.selectedGizmoRef.current) {
          this.scrollIntoView(this.selectedGizmoRef.current);
        }
      }, 500);
    }
    return null;
  }

  componentDidUpdate(prevProps: ExerciseProps) {
    const { dispatch, dialogType, toolTipMessage } = this.props;
    if (shouldShowTooltip(prevProps.dialogType, dialogType) && toolTipMessage) {
      dispatch(openDialog({ type: DialogType.notification }));
    }
  }

  onShowAdditionalHelp = () => {
    const { dispatch, additionalHelpType } = this.props;
    if (additionalHelpType) {
      dispatch(openDialog({ type: additionalHelpType }));
    }
  };

  onEnterIrrevocably = () => {
    this.props.dispatch(updateFilledStatus());
    this.props.dispatch(openDialog({ type: DialogType.enterInputConfirmation }));
  };

  /**
   * To hide the tooltip when its already opening
   */
  onHideTooltip = () => {
    const { dispatch, dialogType } = this.props;
    if (dialogType === DialogType.notification) {
      dispatch(closeDialog());
    }
  };

  render() {
    const {
      availableWidth,
      additionalHelpType,
      dispatch,
      exerciseId,
      handIn,
      hideHelpTools,
      steps,
      setting,
      wrapup,
      wrapupState,
      children,
      currentStepId,
      switched,
      isTouch,
      toolTipMessage,
      dialogType,
      t = identity,
      navigatedAwayFromExercise,
      mode,
      flow,
      staticMediaUrl,
    } = this.props;

    const availableChildWidth = availableWidth - numberFromStyles(styles.PADDING_SETTING_HALF) * 2;
    const exercise = toolTipMessage && (this.exerciseRef.current as HTMLDivElement);
    const element = toolTipMessage && (this.selectedGizmoRef.current as HTMLDivElement);

    const submitIrrevocable = showIrrevocablyButton(steps, mode, currentStepId) ? (
      <ButtonFloatRight
        id="enter-irrevocably"
        dataCy="enter-irrevocably"
        kind={ButtonKind.warning}
        size={ButtonSize.l}
        onClick={this.onEnterIrrevocably}
      >
        {t('seriesplayer:step.enterIrrevocably')}
      </ButtonFloatRight>
    ) : undefined;
    return (
      <div
        data-cy={exerciseId}
        ref={this.exerciseRef}
        className={classNames(
          styles.container,
          mode === SeriesMode.preview && styles.containerPreview
        )}
        // Don't let the content overflow; on small screen this breaks the whole UI
        style={mode === SeriesMode.preview ? undefined : { maxWidth: availableWidth }}
      >
        <div className={mode === SeriesMode.preview ? styles.settingPreview : styles.setting}>
          <ContentBox
            availableWidth={availableChildWidth}
            contentDict={setting}
            dispatch={dispatch}
            gizmoRegistry={gizmoRegistry}
            hideHelpTools={hideHelpTools}
            isTouch={isTouch}
            staticMediaUrl={staticMediaUrl}
          />
        </div>
        {mode === SeriesMode.preview && (
          <>
            <StepComponent
              stepIndex={0}
              step={steps[0]}
              currentStepId={currentStepId}
              exerciseId={exerciseId}
              availableWidth={availableWidth}
              collapseLastStep={navigatedAwayFromExercise}
              switched={switched}
              dispatch={dispatch}
              isTouch={isTouch}
              selectedGizmoRef={this.selectedGizmoRef}
              hideHelpTools={hideHelpTools}
              showAdditionalFeedback={additionalHelpType && !hideHelpTools}
              submitIrrevocable={submitIrrevocable}
              mode={mode}
              flow={flow}
              handIn={handIn}
              staticMediaUrl={staticMediaUrl}
            />
            <PreviewStepsHeader>{t('seriesplayer:previewMode.solutionSteps')}:</PreviewStepsHeader>
            {steps.map((step, index) => (
              <PreviewStepWrapper key={step.id}>
                <PreviewStep>
                  <StepHeader stepIndex={index} title={step.description || step.title} inactive />
                </PreviewStep>
              </PreviewStepWrapper>
            ))}
          </>
        )}
        {mode !== SeriesMode.preview &&
          steps.map((step, index) => (
            <div key={step.id} ref={this.stepRefs[index]}>
              <StepComponent
                stepIndex={index}
                step={step}
                key={step.id}
                currentStepId={currentStepId}
                exerciseId={exerciseId}
                availableWidth={availableWidth}
                collapseLastStep={navigatedAwayFromExercise}
                switched={switched}
                dispatch={dispatch}
                isTouch={isTouch}
                selectedGizmoRef={this.selectedGizmoRef}
                hideHelpTools={hideHelpTools}
                showAdditionalFeedback={additionalHelpType && !hideHelpTools}
                submitIrrevocable={submitIrrevocable}
                mode={mode}
                flow={flow}
                handIn={handIn}
                staticMediaUrl={staticMediaUrl}
              />
            </div>
          ))}
        {wrapup && (
          <div className={styles.wrapup}>
            <ContentBox
              contentDict={wrapup}
              collapsibleKind={CollapsibleContentStyle.wrapup}
              title={t(StepTitleTranslationKey.wrapup)}
              collapse={wrapupState}
              dispatch={dispatch}
              availableWidth={availableChildWidth}
              gizmoRegistry={gizmoRegistry}
              isTouch={isTouch}
              staticMediaUrl={staticMediaUrl}
            />
          </div>
        )}
        {children}
        {element && toolTipMessage && exercise && dialogType === DialogType.notification && (
          <ExerciseToolTip
            element={element}
            exercise={exercise}
            toolTipMessage={toolTipMessage}
            show
            onHide={this.onHideTooltip}
          />
        )}
      </div>
    );
  }
}

export const Exercise: React.FC<ExerciseProps> = (props) => {
  const [t] = useTranslation();
  return <_Exercise {...props} t={t} />;
};
