import {
  type FElement,
  type Importer,
  type WheelOfFortuneContent,
  xmlText,
} from '@bettermarks/gizmo-types';

/**
 * Imports only color tag directly
 * @param {FElement} xml
 * @returns Sector
 */
const importColor = (xml: FElement) => ({ color: xml.text });

/**
 * Imports item tag with color and label if available
 * @param {FElement} xml
 * @returns Sector
 */
const importItem = (xml: FElement) => xml.tagsToProps(xmlText, ['color'], ['label']);

/**
 * imports the Wheel Of Fortune XML and parses it.
 * It contains an array of objects containing a color value and an optional label value.
 * @param preContent
 * @param xml
 */
export const importWheelOfFortune: Importer<WheelOfFortuneContent> = (preContent, xml) => {
  const set = xml.findChildTag('mrow').findChildTag('set');

  const sectors = set.hasChild('item')
    ? set.children.map(importItem)
    : set.children.map(importColor);

  return {
    ...preContent,
    sectors,
  };
};
