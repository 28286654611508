import { KeyCode } from './keyCodes';

// The number of operators defines the breakpoints exactly!
// There are two breakpoints for each number of operators:
//
// - minimum layout -> medium three row
// - medium three row -> large two row
//
// 48=== minimum key size of 44px + 4px key gap
//
// To wrap it up: Currently the breakpoints are calculated by: toggle + numblock + special key
// size and then adding occupied columns of operators (e.g. with 3 rows and 7 operators we have 3
// occupied columns).

const KEY_MIN = 48;
const GRID_GAP = 4;

export const isSmall = (width: number, ops: number, hasNumbers?: boolean, hasLetters?: boolean) =>
  width <
  Math.max(
    (hasNumbers ? 288 : 0) + Math.ceil(ops / 3) * KEY_MIN + KEY_MIN + GRID_GAP,
    hasLetters ? 388 : 0
  );
export const isMedium = (
  width: number,
  ops: number,
  hasDecimalPoint?: boolean,
  hasNumbers?: boolean,
  hasLetters?: boolean
) =>
  width <
  Math.max(
    hasDecimalPoint
      ? (hasNumbers ? 384 : 0) + Math.ceil(ops / 2) * KEY_MIN + KEY_MIN + GRID_GAP
      : (hasNumbers ? 336 : 0) + Math.ceil(ops / 2) * KEY_MIN + KEY_MIN + GRID_GAP,
    hasLetters ? 532 : 0
  );
export const isLarge = (
  width: number,
  ops: number,
  hasDecimalPoint?: boolean,
  hasNumbers?: boolean,
  hasLetters?: boolean
) =>
  width <
  (hasNumbers ? (hasDecimalPoint ? 6 : 5) : 0) * KEY_MIN +
    (hasLetters ? 8 : 0) * KEY_MIN +
    Math.ceil(ops / 2) * KEY_MIN +
    2 * KEY_MIN +
    GRID_GAP;

/**
 * Our layouts are created by adding keys to a single <ul> element. So after adding the numblock,
 * operator and special keys to the layout, we only have a list of buttons (nothing nested!).  To
 * make sure, that the special keys are always at the correct position, it is necessary, to insert
 * empty keys. They are rendered like "gaps" and will not react to pointer events.
 * When and how keys are added is handled in this function.
 * @param operators the list of operators
 * @param rows the number of rows (2 or 3)
 */
export const padOperators = (operators: string[], rows: 2 | 3) => {
  const pad = operators.length % rows;
  if (rows === 3 && pad < 2 && operators.length > 0) {
    return [...operators, ...Array.from({ length: 2 - pad }, (k) => KeyCode.Empty)];
  } else if (rows === 2 && pad === 1) {
    return [...operators, KeyCode.Empty];
  }
  return operators;
};

/**
 * The requirement states, that the brackets always need to appear next to each other on one row.
 * Another requirement states, that the basic arithmetic operators are to be kept in a square block,
 * preceding the bracket. This functions makes sure, the operators are ordered accordingly, padding
 * them with empty keys if needed.
 * The algorithms assumes that the input operators are presorted like this:
 * [BasicOps, Brackets, Others].
 * This is done in the container, when the actual tool configuration is analyzed and translated into
 * an operator list.
 *
 * @param operators the list of operators to sort
 * @param slim the layout (2 (slim) or 3 rows)
 * @returns operators
 */
export const sortOperators = (operators: string[], slim?: boolean) => {
  const hasBasic = operators[0] === KeyCode.Add;
  const basic = [KeyCode.Add, KeyCode.Multiply, KeyCode.Subtract, KeyCode.Divide];

  const hasBrackets = operators[hasBasic ? 4 : 0] === KeyCode.BracketLeft;

  const stripped = operators.filter(
    (o) =>
      o !== KeyCode.Add &&
      o !== KeyCode.Subtract &&
      o !== KeyCode.Multiply &&
      o !== KeyCode.Divide &&
      o !== KeyCode.BracketLeft &&
      o !== KeyCode.BracketRight
  );

  // Here we create an inital list of keycodes, so that the brackets and basic operators are kept
  // in the right order. It's filled with empty keys if necessary. In the second step the empty keys
  // might be replaced by other operators, if we have enough to replace them. Otherwise they stay.
  // Remember: Keys are ordered colum wise:
  // 1 3 5
  // 2 4 and so on..
  let init: KeyCode[] = [];
  if (slim && hasBasic && hasBrackets) {
    init = [...basic, KeyCode.BracketLeft, KeyCode.Empty, KeyCode.BracketRight];
  } else if (slim && hasBasic) {
    init = [...basic];
  } else if (slim && hasBrackets) {
    init = [KeyCode.BracketLeft, KeyCode.Empty, KeyCode.BracketRight];
  } else if (hasBasic && hasBrackets) {
    init = [
      KeyCode.Add,
      KeyCode.Multiply,
      KeyCode.BracketLeft,
      KeyCode.Subtract,
      KeyCode.Divide,
      KeyCode.BracketRight,
    ];
  } else if (hasBasic) {
    init = [KeyCode.Add, KeyCode.Multiply, KeyCode.Empty, KeyCode.Subtract, KeyCode.Divide];
  } else if (hasBrackets) {
    init = [KeyCode.BracketLeft, KeyCode.Empty, KeyCode.Empty, KeyCode.BracketRight];
  }

  // Now we replace the empty keys with the remaining keys, if there are any
  return stripped
    .reduce((acc, cur) => {
      const emptyIdx = acc.findIndex((k) => k === KeyCode.Empty);
      if (emptyIdx !== -1) {
        return [...acc.slice(0, emptyIdx), cur, ...acc.slice(emptyIdx + 1)];
      }
      return [...acc, cur];
    }, init)
    .concat([KeyCode.Equals]);
};
