import * as React from 'react';
import {
  type Graph,
  type GraphEdge,
  type GraphNode,
  isBubbleNode,
  Position,
} from '@bettermarks/gizmo-types';
import {
  compact,
  compose,
  dropWhile,
  flatten,
  map,
  negate,
  reverse,
  sortBy,
  without,
} from 'lodash/fp';
import {
  BUBBLENODE_RADIUS,
  ERROR_HIGHLIGHT_WIDTH,
  GRAPH_MIN_WIDTH,
  HIGHLIGHT_PADDING,
  HIGHLIGHT_WIDTH,
  MAX_NODE_RADIUS,
  NODE_RADIUS,
  ROOT_RADIUS,
  TOUCH_OFFSET,
} from '@bettermarks/importers';
import { type DragScrollBehaviour } from '../../../gizmo-utils/drag-scroll-behaviour';
import { getScrollWrapper } from '../../components';
import { Maybe } from '../../../utils/maybe';
import { type ScrollPosition } from '../../../gizmo-utils/drag-scroll-behaviour/types';
import { incrementalScroll } from '../../../gizmo-utils/drag-scroll-behaviour/helpers';

// taken from : http://byronsalau.com/blog/how-to-create-a-guid-uuid-in-javascript/
/* eslint-disable no-bitwise */
export const createGuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
/* eslint-enable no-bitwise */

/**
 * Transform screen to world position.
 */
export const getWorldPos = (x: number, y: number, ref: React.RefObject<any>): Position => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect();
    const offset = { x: 0, y: 0 };
    const scrollWrapper = getScrollWrapper(ref.current);
    if (scrollWrapper) {
      offset.x = scrollWrapper.scrollLeft;
      offset.y = scrollWrapper.scrollTop;
    }
    return {
      x: x - (rect ? rect.left : 0) + offset.x,
      y: y - (rect ? rect.top : 0) + offset.y,
    };
  }
  return { x: x, y: y };
};

/**
 * Transform world to screen position.
 */
export const getScreenPosByRef = (x: number, y: number, ref: React.RefObject<any>): Position => {
  const offset = { x: 0, y: 0 };
  const scrollWrapper = getScrollWrapper(ref.current);
  if (scrollWrapper) {
    offset.x = scrollWrapper.scrollLeft;
    offset.y = scrollWrapper.scrollTop;
  }
  return { x: x - offset.x, y: y - offset.y };
};
export const getScreenPos = (x: number, y: number, offset: ScrollPosition): Position => ({
  x: x - offset.x,
  y: y - offset.y,
});

export const connectsNode = (id?: string) => (edge: GraphEdge) =>
  id === edge.nodeId1 || id === edge.nodeId2;

export const doesntConnectNode = (id?: string) => negate(connectsNode(id));

export const isSameNode = (id?: string) => (otherNode: GraphNode) => id === otherNode.id;
export const notSameNode = (id?: string) => negate(isSameNode(id));

export const highlightEdge = (edge: GraphEdge): GraphEdge => ({
  ...edge,
  deleteHighlight: true,
});

export const sqr = (x: number) => x * x;

export const dist2 = (v: Position, w: Position) => sqr(v.x - w.x) + sqr(v.y - w.y);

export const distToSegmentSquared = (p: Position, v: Position, w: Position) => {
  const l2 = dist2(v, w);
  if (l2 === 0) return dist2(p, v);
  // dot (p - v, w - v) / l2
  let t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
  t = Math.max(0, Math.min(1, t));
  return dist2(p, {
    x: v.x + t * (w.x - v.x),
    y: v.y + t * (w.y - v.y),
  });
};

export const edgeDist =
  (position: Position, nodes: ReadonlyArray<GraphNode>) => (edge: GraphEdge) => {
    const node1 = nodes.find((n) => n.id === edge.nodeId1);
    const node2 = nodes.find((n) => n.id === edge.nodeId2);
    if (node1 && node2) {
      return Math.sqrt(distToSegmentSquared(position, node1.position, node2.position));
    }
    return Number.MAX_VALUE;
  };

export const nodeDist = (position: Position) => (node: GraphNode) =>
  Position.distance(position, node.position);

export const nearestNode = (pos: Position, nodes: ReadonlyArray<GraphNode>) => {
  const nodeDistances = nodes.map(nodeDist(pos));
  const minNodeDist = Math.min(...nodeDistances);
  const minIndex = nodeDistances.findIndex((d) => d === minNodeDist);
  return nodes[minIndex];
};

export const edgeStart = (node1: GraphNode, node2: GraphNode) => {
  let radius = 0;
  if (isBubbleNode(node1) && !node1.invisible) {
    radius = Math.round(
      node1.isRoot
        ? ROOT_RADIUS
        : BUBBLENODE_RADIUS +
            (node1.deleteHighlight || node1.highlightColor
              ? HIGHLIGHT_PADDING + HIGHLIGHT_WIDTH
              : node1.severity
              ? HIGHLIGHT_PADDING + ERROR_HIGHLIGHT_WIDTH
              : 0)
    );
  }
  const diff = Position.subtract(node2.position, node1.position);
  return Position.add(node1.position, Position.scale(diff, radius / Position.magnitude(diff)));
};

export const pickGraphObject = (
  pos: Position,
  radius: number,
  edges: ReadonlyArray<GraphEdge>,
  nodes: ReadonlyArray<GraphNode>
) => {
  const edgeDistances = edges.map(edgeDist(pos, nodes));
  const nodeDistances = nodes.map(nodeDist(pos));
  const minEdgeDist = Math.min(...edgeDistances);
  const minNodeDist = Math.min(...nodeDistances);
  if (minNodeDist < radius) {
    const minIndex = nodeDistances.findIndex((d) => d === minNodeDist);
    return {
      node: nodes[minIndex],
    };
  }
  if (minEdgeDist < radius) {
    const minIndex = edgeDistances.findIndex((d) => d === minEdgeDist);
    return {
      edge: edges[minIndex],
    };
  }
  return {};
};

/**
 * Sets a flag in the exported xml. Not necessary for the client.
 */
export const markConnectedToRoot = (graph: Graph) => {
  const root = graph.nodes.find((n) => !!n.isRoot);
  if (!root) return graph;

  let { nodes, edges } = graph;
  let currentNodes = [root];

  while (currentNodes.length > 0) {
    const connectedEdges = flatten(
      currentNodes.map((n: GraphNode) => edges.filter(connectsNode(n.id)))
    ).filter((e) => !e.connectedToRoot);

    const connectedNodes = compact(
      connectedEdges.map((e) => {
        const node1 = nodes.find((n) => n.id === e.nodeId1);
        if (node1 && currentNodes.find((n: GraphNode) => n.id === node1.id)) {
          return nodes.find((n) => n.id === e.nodeId2);
        }
        return node1;
      })
    ).filter((n) => !n.connectedToRoot);

    edges = [
      ...without(connectedEdges, edges),
      ...connectedEdges.map((e) => ({ ...e, connectedToRoot: true })),
    ];
    nodes = [
      ...without(connectedNodes, nodes),
      ...connectedNodes.map((n) => ({ ...n, connectedToRoot: true })),
    ];
    currentNodes = connectedNodes;
  }

  return {
    ...graph,
    nodes,
    edges,
  };
};

/**
 * Register an DOM event handler on the given ref. This is necessary e.g. for touch event, as React
 * still doesn't allow to add passive event handlers that can prevent default scrolling behaviour.
 *
 * @param event the event name (e.g. 'touchstart')
 * @param callback the event handler
 * @param ref the ref on which to register the event handler
 * @param props any props (or other variables) that are used in the event handler and that might
 * change. As we use hooks this is a very important step. Otherwise the event handler will only have
 * the old values that it got when it had been registered the first time!
 */
export const useDOMEvent = (
  event: string,
  callback: EventListener,
  ref: React.RefObject<any>,
  props?: any[]
) => {
  return React.useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(event, callback, { passive: false });
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener(event, callback);
      }
    };
  }, [ref.current, ...(props ? props : [])]);
};

/**
 * Decides what object in the graph has been picked and will dispatch on of the three event handlers
 * that have been passed as arguments.
 *
 * @param ref the graph boundary ref
 * @param edges the graph's edges
 * @param nodes the graph's nodes
 * @param onNodePicked called when a node has been picked
 * @param onEdgePicked called when an edge has been picked
 * @param onNothing called when nothing has been picked
 * @param whether to apply a touch offset when picking
 */
export const onPickEvent =
  <T extends TouchEvent | React.MouseEvent<any>>(
    ref: React.RefObject<any>,
    edges: ReadonlyArray<GraphEdge>,
    nodes: ReadonlyArray<GraphNode>,
    onNodePicked?: (node: GraphNode) => void,
    onEdgePicked?: (edge: GraphEdge) => void,
    onNothing?: (evt: T) => void,
    offset = true
  ) =>
  (evt: T) => {
    let x = 0,
      y = 0;
    if ((evt as TouchEvent).touches) {
      x = (evt as TouchEvent).changedTouches[0].clientX + (offset ? TOUCH_OFFSET.x : 0);
      y = (evt as TouchEvent).changedTouches[0].clientY + (offset ? TOUCH_OFFSET.y : 0);
    } else {
      x = (evt as React.MouseEvent<any>).clientX;
      y = (evt as React.MouseEvent<any>).clientY;
    }
    const pos = getWorldPos(x, y, ref);
    const picked = pickGraphObject(pos, 2 * NODE_RADIUS, edges, nodes);
    if (picked.node) {
      onNodePicked && onNodePicked(picked.node);
      evt.preventDefault();
    } else if (picked.edge) {
      onEdgePicked && onEdgePicked(picked.edge);
      evt.preventDefault();
    } else {
      onNothing && onNothing(evt);
    }
  };

export const clampPosition = (
  position: Position,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number
) => ({
  x: Math.min(Math.max(position.x, minX), maxX),
  y: Math.min(Math.max(position.y, minY), maxY),
});

export type NodeDist = {
  node: GraphNode;
  dist: number;
};

export const coveredNodes = (position: Position, radius: number) =>
  compose(
    dropWhile((n: NodeDist) => n.dist > radius),
    reverse,
    sortBy((n: NodeDist) => n.dist),
    map((n: GraphNode) => ({
      node: n,
      dist: nodeDist(position)(n),
    }))
  );

const preventDefault = (e: TouchEvent) => e.preventDefault();

/**
 * Disable the default scrolling, i.e. the ability for the user to drag and scroll on the background
 * of the work area. This is used to prevent accidental scrolling when other interactions like
 * moving a node or connecting two nodes are active. Usually the user can then scroll by dragging
 * the moved node to the left or right border. Additionally she can use the grab tool.
 *
 * @param scrollBehaviour the scroll behaviour that controls the scroll area.
 *
 */

export const useNoDefaultScrolling = (scrollBehaviour?: DragScrollBehaviour) => {
  React.useEffect(
    () =>
      Maybe(scrollBehaviour)
        .map((b) => b.getScrollWrapper)
        .map((getScrollWrapper) => getScrollWrapper().xWrapper)
        .ap((wrapper) => {
          wrapper.addEventListener('touchstart', preventDefault, {
            passive: false,
          });
          return wrapper.removeEventListener('touchstart', preventDefault);
        }),
    [scrollBehaviour]
  );
};

/**
 * Determine if we need the Grab tool. Only if the available width is smaller than the graph width
 * and we are in touch mode, the grab tool should be shown.
 *
 * This is a bit of an anti pattern here. Both values are actually part of the application state.
 * Unfortunately our gizmos do not get the full state in their respective reducers. This is a rare
 * case where we need to change something in our content depending of the state of the whole app
 * (width & touch), because other parts of the app depend on it (modes).
 *
 * @param availableWidth the available width we get from the parent gizmo
 * @param isTouch wether we are on a touch device
 * @param needGrab the callback that is called when we determined if we need the grab tool or not.
 */
export function useGrabMode(
  availableWidth: number,
  isTouch?: boolean,
  needGrab?: (b: boolean) => void,
  watch?: any[]
) {
  React.useEffect(() => {
    if (needGrab) {
      if (isTouch && availableWidth < GRAPH_MIN_WIDTH) {
        needGrab(true);
      } else {
        needGrab(false);
      }
    }
  }, [availableWidth, isTouch].concat(watch));
}

/**
 * Scroll to the given position such that a dialog box can be drawn properly.
 */
export const scrollToXPosition = (
  screenPos: Position,
  clientRect: ClientRect,
  maybeScrollBehaviour: Maybe<DragScrollBehaviour>
) => {
  const leftBoundary = MAX_NODE_RADIUS;
  const rightBoundary = clientRect.width - MAX_NODE_RADIUS;
  if (screenPos.x > rightBoundary) {
    maybeScrollBehaviour.ap(incrementalScroll({ y: 0, x: -(rightBoundary - screenPos.x) }));
  }
  if (screenPos.x < leftBoundary) {
    maybeScrollBehaviour.ap(incrementalScroll({ y: 0, x: -(leftBoundary - screenPos.x) }));
  }
};
