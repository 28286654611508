import * as React from 'react';
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import Measure, { type BoundingRect, type ContentRect } from 'react-measure';
import { calculatePopupBounds, areBoundsAvailable } from './helper';
import { numberFromStyles } from '@bettermarks/gizmo-types';
import { OverlayShadow } from '@seriesplayer/common-ui';
import styles from './ContextPopup.scss';

export const POPUP_MARGIN = numberFromStyles(styles.POPUP_MARGIN);

export type Bounds = {
  top?: number | string;
  left?: number | string;
};

export type PopupBounds = {
  bounds?: Bounds;
  noseBounds?: Bounds;
  popupHeight: number | string;
};

export type PopupProps = {
  place: 'left' | 'bottom' | 'right' | 'top';
  kind: 'small' | 'large' | 'custom';
  still?: boolean;
  show: boolean;
  elementBounds?: BoundingRect;
  onClickOverlay: () => void;
  width?: number;
  showOverlayShadow?: boolean;
};

export type PopupState = {
  bounds?: BoundingRect;
};

/**
 * Popup - UI Component
 * Used to render the Popups - Small/Large
 * Positioning the popup based on `place` and Triggered Element position in the window
 *
 * ### Properties
 | Name       | Type                               | Default    | Description                     |
 |---         | ---                                |---         | ---                             |
 |place       | `left`, `right`, `top`, `bottom`   |Required    |popup with nose placed at `place`|
 |kind        | `small`, `large`                   |Required    |Popup type                       |
 |still       | `boolean`                          |Optional    |show the popup UI without overlay|
 |show        | `boolean`                          |Required    |to toggle the popup visisibilty  |
 |elementBounds| `BoundingRect`                    |Required    |Triggered elements boundaries    |
 |onClickOverlay| Mouse Event                      |Required  |event triggers when click on overlay|
 |---         | ---                                |---         | ---                             |
 */

class Popup extends React.Component<PopupProps, PopupState> {
  state: PopupState = {};

  onPopupResize = ({ bounds }: ContentRect) => this.setState({ bounds });

  // Calculate the dynamic styling to position/re-position the popup
  applyStyles = () => {
    const styles = {
      popupStyle: {},
      noseStyle: {},
      innerPopupStyle: {},
    };
    const { elementBounds, place } = this.props;
    const { bounds } = this.state;
    if (bounds && elementBounds) {
      const calculatedBounds: PopupBounds = calculatePopupBounds(
        elementBounds,
        bounds,
        place
      ) as PopupBounds;
      if (calculatedBounds) {
        const { bounds: updatedBounds, noseBounds, popupHeight } = calculatedBounds;
        styles.popupStyle = { ...updatedBounds };
        if (isNumber(popupHeight)) {
          styles.innerPopupStyle = (
            place === 'top' || place === 'bottom'
              ? {
                  height: popupHeight,
                  overflowY: 'auto',
                }
              : {}
          ) as React.CSSProperties;
        }
        styles.noseStyle = { ...noseBounds };
      }
    }
    if (this.props.kind === 'custom' && this.props.width) {
      styles.popupStyle = { ...styles.popupStyle, width: this.props.width };
    }
    return styles;
  };

  render() {
    const {
      children,
      onClickOverlay,
      kind,
      place,
      show,
      still,
      showOverlayShadow = true,
    } = this.props;
    const popupWrapClasses = classNames(styles.popup, {
      [styles.show]: show || still,
      [styles.still]: still,
      [styles.hidden]: !areBoundsAvailable(this.state.bounds),
    });
    const popupCls = classNames(styles.contextPopup, styles[`${kind}`], styles[`${place}`]);
    const { popupStyle, noseStyle, innerPopupStyle } = this.applyStyles();
    return (
      <div className={popupWrapClasses}>
        <Measure bounds onResize={this.onPopupResize}>
          {({ measureRef }) => (
            <div className={popupCls} ref={measureRef} style={popupStyle}>
              <div className={styles.popupContent} style={innerPopupStyle}>
                {children}
              </div>
              <div className={styles[`${place}`]} style={noseStyle}>
                <div className={styles.innerArrow} />
              </div>
            </div>
          )}
        </Measure>
        {show && showOverlayShadow && <OverlayShadow kind="popup" onClick={onClickOverlay} />}
      </div>
    );
  }
}

export default Popup;
