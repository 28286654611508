import { range } from 'lodash';
import { type DaySpan, type FirstDayOfWeek } from '@bettermarks/gizmo-types';
import { constructDayGrid } from '../../components/CalendarMonth';
import { type DateCell } from '../../components/CalendarMonth/types';

/**
 * Format a date string from MM-DD (EPL format) to YYYY-MM-DD
 *
 * It handles special case where a day span is over 2 different years.
 *
 * @param year calendar year for the date
 * @param date date string as MM-DD
 */
export const withYears = (year: number, daySpans: DaySpan[]): DaySpan[] => {
  const extractMonth = (dateStr: string): number => +dateStr.split('-')[0];
  return daySpans.map(({ from, to }) => {
    const [fromMonth, toMonth] = [extractMonth(from), extractMonth(to)];
    // Ex. dayspan is over 2 years with from 12-30 to 01-30, we need to
    // add a year to the 'to' date
    const toYear = toMonth < fromMonth ? year + 1 : year;
    return {
      from: `${year}-${from}`,
      to: `${toYear}-${to}`,
    };
  });
};

export const constructCalendarDayGrids = (
  month: number,
  year: number,
  count: number,
  daySpans: DaySpan[],
  firstDayOfWeek: FirstDayOfWeek
): DateCell[][][] => {
  const daySpanswithYears = withYears(year, daySpans);
  return range(0, count).map((i) => {
    const { y, m } =
      month + i > 11 ? { y: year + 1, m: (month + i) % 12 } : { y: year, m: month + i };
    return constructDayGrid(y, m, firstDayOfWeek, daySpanswithYears);
  });
};
