import { PIXEL_PER_XTICK, PIXEL_PER_YTICK } from '@bettermarks/importers';
import { type GeoConfiguration } from '@bettermarks/gizmo-types';

export const LEFTSPACE = 25; // values got from trying out some examples ....
export const RIGHTSPACE = 55;
export const TOPSPACE = 15;
export const BOTTOMSPACE = 55;

/**
 * These transformationsSettings replace the standard Geo-transformationSettings. We need some
 * custom fixed left, right, top and bottom margins to display 'large labels' correctly ...
 * @param configuration
 * @param scale
 */
export const transformationSettings = (
  configuration: GeoConfiguration,
  scale = 1
): {
  matrix: number[];
  gridWidth: number;
  gridHeight: number;
  totalWidth: number;
  totalHeight: number;
} => {
  const {
    display: { cx, cy, width, height },
    tickValueInterval,
  } = configuration;

  const gridWidth = width * PIXEL_PER_XTICK;
  const gridHeight = height * PIXEL_PER_YTICK;

  const matrix = [
    (PIXEL_PER_XTICK / tickValueInterval.x) * scale,
    0,
    0,
    (-PIXEL_PER_YTICK / tickValueInterval.y) * scale,
    PIXEL_PER_XTICK * (width * 0.5 - cx) * scale + LEFTSPACE,
    PIXEL_PER_YTICK * (height * 0.5 + cy) * scale + TOPSPACE,
  ];
  return {
    matrix,
    gridWidth: gridWidth * scale,
    gridHeight: gridHeight * scale,
    totalWidth: gridWidth * scale + LEFTSPACE + RIGHTSPACE,
    totalHeight: gridHeight * scale + TOPSPACE + BOTTOMSPACE,
  };
};
