import { type ApplyStyles, type Ruler } from '../../gizmo-utils/configuration/types';
import { CLOCK_SIZE } from '../clock/constants';
import { GIZMO_FONTSIZE } from '../components/Gizmo';
import { DURATION_TOTAL_HEIGHT } from '../day-duration/constants';
import { type TimeSpanGizmoContent } from '@bettermarks/gizmo-types';

export const applyTimeSpanStyles: ApplyStyles = (
  { contentRefs, thirdDuration }: TimeSpanGizmoContent,
  style
) => {
  const refs = thirdDuration ? [...contentRefs, ...thirdDuration.contentRefs] : contentRefs;
  return refs.map(({ $refid: refId }) => ({ refId, style }));
};

/**
 * 4 scenarios on which height will depend:
 *  1. simple duration
 *```
 *           duration
 *    from ------------> to
 * ```
 *  2. 2 duration with a third duration arrow
 * ```
 *            duration            duration
 *  from -----------------> step -----------> to <-.
 *    |                                            |
 *    :__________________duration__________________:
 * ```
 *  3. time duration with (at least a) clock(s)
 * ```
 *   ___
 *  / | \
 * |  +- |
 *  \___/
 *            duration
 *  from -----------------> to
 * ```
 *  4. time duration with (at least a) clock(s) and a third duration arrow
 * ```
 *   ___
 *  / | \
 * |  +- |
 *  \___/
 *            duration            duration
 *  from -----------------> step -----------> to <-.
 *    |                                            |
 *    :__________________duration__________________:
 * ```
 */
export const measureTimeSpan: Ruler<TimeSpanGizmoContent> = (_, content) => {
  const textArrowSpace = 8;
  const textSpace = GIZMO_FONTSIZE + 6;
  let height = DURATION_TOTAL_HEIGHT;
  if (content.contentRefs.some((contentRef) => contentRef.withClock)) {
    // clock with text underneath becomes the new max height component
    height = CLOCK_SIZE + textSpace + textArrowSpace;
  }
  if (content.thirdDuration) {
    // in case we have the third end to end rectangle arrow, we need to
    // append it to the total height
    height += textArrowSpace + GIZMO_FONTSIZE + 32;
  }

  return {
    height,
    refLine: height * 0.5,
  };
};
