import {
  DRAG_SOURCE,
  DROP_TARGET,
  SET_DROP_TARGET,
} from '../../gizmo-utils/configuration/renderStyles';
import { type Severity } from '@bettermarks/umc-kotlin';
import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';
import { PLACEHOLDER, SPECIAL } from '../../gizmo-utils/constants';

export const enum DragAndDropStyle {
  border = 'border',
  none = 'none',
  rectangle = 'rectangle',
}

export enum VerticalAlignment {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

export interface DragSourceContent extends Readonly<Content> {
  autoSize?: boolean;
  content: ContentReference;
  dragSourceId?: string; // used for the validator
  height?: number;
  instances: number;
  normalized?: string;
  style: DragAndDropStyle;
  targetGroup: number;
  width?: number;
  group?: number;
  /**
   * Indicate whether this drag source may appear in the drawer shown on small screens. This will be
   * set somewhere outside, so that e.g. drag sources from different steps are not shown.
   * We cannot derive this from other properties like e.g. disabled, because disabled is also set
   * during validation inside a step.
   */
  availableInDrawer?: boolean;

  // allow pausing the dragging of an item
  freeSnapping?: true;
  pos?: TranslateCoords;
}

export const isDragSource = (content: Content): content is DragSourceContent =>
  content.$renderStyle === DRAG_SOURCE;

export const DRAG_SOURCE_DEFAULT_CONTENT: Defaults<DragSourceContent, 'content'> = {
  $: SPECIAL,
  $renderStyle: DRAG_SOURCE,
  instances: 1,
  style: DragAndDropStyle.none,
  targetGroup: 1,
  pos: {
    translateX: 0,
    translateY: 0,
  },
};

export type ResolvedDragSource = ContentReference & {
  dragSource: DragSourceContent;
};

export interface DragItem {
  dragSourceId: string;
  severity?: Severity;
  resolved?: ResolvedDragSource;
}

export const isResolved = (item: DragItem): item is DragItem & { resolved: ResolvedDragSource } =>
  item.resolved !== undefined;

export interface DropTarget {
  targetGroup: number;
  items: ReadonlyArray<DragItem>;
  // refId of pre-filled item in case the target is not interactive
  dragItemFixed?: string;
}

export interface DropTargetContent extends Readonly<Content & DropTarget> {
  backgroundAlpha?: number;
  borderColor?: string;
  /**
   * added by enricher
   */
  fontSize?: number;
  height?: number;
  severity?: Severity;
  style: DragAndDropStyle;
  width?: number;
}

export interface SetDropTargetContent extends DropTargetContent {
  minHeight?: number;
  minWidth?: number;
  fixedWidth?: number;
  widthAsFactorOfMaxItemWidth?: number;
  percentWidth: number;
  verticalItemAlign: VerticalAlignment;
}

export const DROP_TARGET_DEFAULT_CONTENT: Defaults<DropTargetContent> = {
  $: PLACEHOLDER,
  $renderStyle: DROP_TARGET,
  items: [],
  style: DragAndDropStyle.none,
  targetGroup: 1,
};

export const SET_DROP_TARGET_DEFAULT_CONTENT: Defaults<SetDropTargetContent> = {
  $: PLACEHOLDER,
  $renderStyle: SET_DROP_TARGET,
  items: [],
  style: DragAndDropStyle.rectangle,
  targetGroup: 1,
  percentWidth: 100,
  verticalItemAlign: VerticalAlignment.top,
};

export interface DropCallbacks {
  onDropItem?: (
    sourceID: string,
    freeDragItemTranslate: FreeDragItemTranslate,
    index?: number
  ) => void;
  onRemoveItem?: (index?: number, freeDragItemTranslate?: FreeDragItemTranslate) => void;
}

export interface DropProps {
  refid: string;
}

export type AlignItems = 'bottom' | 'top' | 'right' | 'left';
export type ScreenRatio = [number, number];

export interface DragAndDropConfiguration {
  alignItems: AlignItems;
  screenRatio: ScreenRatio;
}

export const DEFAULT_DRAG_AND_DROP_CONFIGURATION: DragAndDropConfiguration = {
  screenRatio: [40, 60],
  alignItems: 'left',
};

export interface TranslateCoords {
  translateX: number;
  translateY: number;
}

export interface FreeDragItemTranslate {
  newPos: TranslateCoords;
  refid: string;
  sourcePos?: TranslateCoords;
}

export interface FreeDragAndDropTranslations {
  [k: string]: TranslateCoords;
}

export interface IFreeDragAndDrop extends IDragAndDrop {
  translations: FreeDragAndDropTranslations;
  droppedItems: Array<ContentReference>;
  inTarget: boolean;
}

export interface IDragAndDrop extends Readonly<Content> {
  items: Array<ContentReference>;
  content: ContentReference;
  configuration: DragAndDropConfiguration;
}
