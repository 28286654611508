import { fill, isNil, isUndefined, range } from 'lodash';
import {
  $MROW,
  type FractionFormContent,
  FractionFormEditorMode,
  hasInteractionType,
  type Importer,
  InputToolTypes,
  switchMap,
  toBoolean,
  toolModes,
  xmlTextToInt,
  zeroNaN,
} from '@bettermarks/gizmo-types';

const toolsFromInteractionType = switchMap(
  {
    [toolModes.form_colorize]: [FractionFormEditorMode.ToggleFill],
    [toolModes.form_break_colorize]: [
      FractionFormEditorMode.Divide,
      FractionFormEditorMode.ToggleFill,
    ],
    [toolModes.form_all]: [
      FractionFormEditorMode.Divide,
      FractionFormEditorMode.ToggleFill,
      FractionFormEditorMode.Add,
      FractionFormEditorMode.Remove,
    ],
  },
  []
);

/**
 * @param {number} wholes
 * @returns {boolean[][]}
 * @description This helper is used to obtain bitmask for wholes,
 * wholes as a parameter is passed. An array constuctor is called,
 * which would make an allocation equal to the wholes value. Then it fills each index with [true].
 * For example, The num = 2 then the respective bitmaskWholes returned would be [[true], [true]]
 * This displays the number of wholes.
 * */
export const bitmaskWholes = (wholes: number): boolean[][] => fill(Array(wholes), [true]);

/**
 * @param {number} num
 * @param {number} denom
 * @returns {boolean[]}
 * @description This helper is used to obtain segmented(fraction) bitmaskObject.
 * In this case num and denom is passed as params. A range for the value of denom
 * is created and for each of the value from start to end the value is compared with num,
 * if the value is less than num a true is returned for that index in an array. otherwise a false.
 * In this way true represents the number in num(colored segments) and the total(true +false)
 * length of this bitmask represents the number of segments in an object.
 * For example, The num is 2, denom is 3, the respective bitmaskObject returned would be,
 * [true, true, false]
 */
export const bitmaskFrac = (num: number, denom: number): boolean[] =>
  range(denom).map((i) => i < num);

export const importFractionForm: Importer<FractionFormContent> = (preContent, xml) => {
  const defaultContent: Omit<FractionFormContent, 'bitmasks'> = {
    ...preContent,
    ...(hasInteractionType(preContent) && {
      ...(isNil(preContent.selectedMode) && {
        selectedMode: toolsFromInteractionType(preContent.$interactionType)[0],
      }),
      tool: {
        type: InputToolTypes.modeSelector,
        modes: toolsFromInteractionType(preContent.$interactionType),
      },
    }),
  };

  const mrow = xml.findChildTag($MROW);
  const wholesSegmented = toBoolean(mrow.attribute('wholesSegmented'));

  if (mrow.hasAttribute('bitmasks')) {
    return {
      ...defaultContent,
      bitmasks: JSON.parse(mrow.attribute('bitmasks')),
    };
  }

  /**
   * The length of bitmasks here represent the total number of circles.
   * Example:
   * <mrow>
        <mn>2</mn>
        <mfrac>
            <mn>3</mn>
            <mn>4</mn>
        </mfrac>
    </mrow>
    Here in the above example, the first <mn> tag represents number of wholes
    and the <mfrac> tag represents the segmented circle. Within which,
    the first <mn> tag represents numerator and the second denominator.
    Bitmask is an array of arrays which contains booleans.
    In this case th Bitmask for wholes alone is represented as [[true], [true]]
    For Segments as [true, true, true, false]--> Here number of true's is the
    numerator. And the total length of this array represents the denominator,
    Combining wholes and segmented circle, we obtain bitmasks as,
    [
      [true], [true],
      [true, true, true, false]
    ]
   */

  const wholes = zeroNaN(xmlTextToInt(mrow.findChildTag('mn')));
  const [numerator, denominator] = mrow.findChildTag('mfrac').children.map(xmlTextToInt);

  /**
    Case 1: Only wholes are present. No <mfrac>
   */
  if (isUndefined(numerator || denominator)) {
    return {
      ...defaultContent,
      bitmasks: bitmaskWholes(wholes),
    };
  }

  /**
    Case 2: Only <mfrac> is present and denominator is greater than the numerator
    Case 3: Both wholes and <mfrac> are present but numerator is greater than the denominator
    Case 4: Both wholes and <mfrac> are present but numerator under <mfrac> is 0
   */
  const wholesFromMixed = Math.floor(wholes + numerator / denominator);

  /**
    Case 5: Both wholes and <mfrac> are present but numerator under <mfrac> is 0 just works
            will fill the whole bitmask with `false`
   */
  return {
    ...defaultContent,
    bitmasks: [
      ...(wholesSegmented
        ? [...Array(wholesFromMixed)].map((_) => bitmaskFrac(denominator, denominator))
        : bitmaskWholes(wholesFromMixed)),
      ...(numerator % denominator !== 0 || numerator === 0
        ? [bitmaskFrac(numerator, denominator)]
        : []),
    ],
  };
};
