import { BaseDragScrollBehaviour } from './BaseDragScrollBehaviour';
import { type DragScrollBehaviour, type ScrollPosition } from './types';

export type ScrollListener = (dx: number, dy: number) => void;

export class XYDragScrollBehaviour implements DragScrollBehaviour {
  private readonly xScrollBehaviour: DragScrollBehaviour;
  private readonly yScrollBehaviour: DragScrollBehaviour;

  constructor(scrollContainerX: Nullable<HTMLElement>, scrollContainerY: Nullable<HTMLElement>) {
    this.xScrollBehaviour = new BaseDragScrollBehaviour(scrollContainerX, true);
    this.yScrollBehaviour = new BaseDragScrollBehaviour(scrollContainerY);
  }

  startScrolling = () => {
    this.xScrollBehaviour.startScrolling();
    this.yScrollBehaviour.startScrolling();
  };

  stopScrolling = () => {
    this.xScrollBehaviour.stopScrolling();
    this.yScrollBehaviour.stopScrolling();
  };

  incrementalScroll = ({ x, y }: ScrollPosition) => {
    this.xScrollBehaviour.incrementalScroll(x);
    this.yScrollBehaviour.incrementalScroll(y);
  };

  getScrollWrapper = () => ({
    xWrapper: this.xScrollBehaviour.getScrollWrapper() as Nullable<HTMLElement>,
    yWrapper: this.yScrollBehaviour.getScrollWrapper() as Nullable<HTMLElement>,
  });

  getScrollAmount = () => ({
    x: this.xScrollBehaviour.getScrollAmount() as number,
    y: this.yScrollBehaviour.getScrollAmount() as number,
  });

  scroll = () => {
    this.xScrollBehaviour.scroll();
    this.yScrollBehaviour.scroll();
  };
}

export const getDragScrollBehaviour =
  (getY: () => Nullable<HTMLElement>) => (x: Nullable<HTMLElement>) =>
    new XYDragScrollBehaviour(x, getY());
