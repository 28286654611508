import * as React from 'react';
import { flow } from 'lodash/fp';
import { connect, type MapDispatchToPropsFunction, type MapStateToProps } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { type ApplicationState, type EMStatus } from '../../types';
import { type Dispatch } from '../../gizmo-utils/redux';
import { type ContentDict } from '@bettermarks/gizmo-types';

import { type FetchFEMParams } from '../seriesplayer/services/api/content-manager';
import { isTouchDevice } from '../seriesplayer/services/runtime-manager';
import { FEMPlayer } from './FEMPlayer';
import { emRequested } from './actions';
import { getStaticServerURL, parseEMPlayerQueryString } from './helper';
import { type EMPlayerCommonProps } from './types';
import styles from './FEMPlayerRoute.scss';
import { type StateWithHistory } from 'redux-undo';
import { jumpToPastAndEnrich } from '../store/combineSeriesplayerReducer';
import { resetIncludeGizmo } from '../../gizmos/include-gizmo/resetIncludeGizmo';

export type FEMStateProps = EMPlayerCommonProps & {
  encrypt: boolean;
  femData?: StateWithHistory<ContentDict>;
  inBook?: boolean;
  isTouch: boolean;
  status?: EMStatus;
  onCloseFromWhiteboard?: () => void;
};

export type FEMContainerOwnProps = Readonly<
  EMPlayerCommonProps & {
    encrypt: boolean;
    femData?: StateWithHistory<ContentDict>;
    isTouch: boolean;
    status?: EMStatus;
  }
>;

const mapStateToProps: MapStateToProps<FEMStateProps, FEMContainerOwnProps, ApplicationState> = (
  state,
  ownProps
) => {
  const fems = state.emData.fems;

  return {
    ...ownProps,
    femData: fems.data && fems.data[ownProps.id],
    status: fems.status,
  };
};

type DispatchProps = {
  dispatch: Dispatch;
  onInit: (initParams: FetchFEMParams) => void;
  onCancel?: () => void;
};

const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, FEMContainerOwnProps> = (
  dispatch
) => ({
  dispatch,
  onInit: ({ ...requestParams }) => {
    dispatch(emRequested(requestParams));
  },
  onReset: () => {
    // Reset gizmo is a special case;
    // it doesn't use any redux state, but content developers
    // can implenent in the content an 'onReset' method.
    // The content includes the script packages/bm-toolbox/public/js/reset-experiment.js
    // which adds the event listener that will react when `resetIncudeGizmo` is called.
    // See. http://i18nwiki.bm.loc/index.php/Edl:applyTemplate:include
    // for details on the EDL/EPL code.
    resetIncludeGizmo();
    dispatch(jumpToPastAndEnrich(0));
  },
});

export const FEMPlayerContainer = connect(mapStateToProps, mapDispatchToProps)(FEMPlayer);

export function FEMPlayerRoute() {
  const location = useLocation();
  const { id, locale } = useParams();

  const { staticServerUrl, staticMediaUrl, encrypt, inBook, test, forWhiteboard } = flow(
    parseEMPlayerQueryString,
    getStaticServerURL
  )(location.search);

  return (
    <div className={!forWhiteboard && !inBook ? styles.dialog : undefined}>
      <FEMPlayerContainer
        id={id ?? ''}
        locale={locale ?? ''}
        staticMediaUrl={staticMediaUrl}
        staticServerUrl={staticServerUrl}
        isTouch={isTouchDevice()}
        encrypt={encrypt}
        inBook={inBook}
        test={test}
        forWhiteboard={forWhiteboard}
      />
    </div>
  );
}
