import { identity, pickBy } from 'lodash/fp';
import {
  type ContentReference,
  exportContent,
  type Exporter,
  type ExporterContext,
  semantics,
  special,
  type TreeChartContent,
  TreeChartNodeType,
  type TreeEdge,
  type TreeNode,
} from '@bettermarks/gizmo-types';
import { exportNode } from '../Graph/exporter';
import { decorationAttribute } from '../../../gizmo-utils/decoration';

export const exportConfiguration = (tree: TreeChartContent): string => `
  <configuration>
    <direction>${tree.direction}</direction>
    <edgeCorners>${tree.edgeCorners}</edgeCorners>
  </configuration>
`;

const wrapContent = (tag: string, content: ContentReference, context: ExporterContext): string =>
  `<${tag}>${exportContent(content.$refid, context)}</${tag}>`;

const exportResultProbability = (node: TreeNode, context: ExporterContext): string =>
  node.type === TreeChartNodeType.leaf && node.resultProbability
    ? `<resultProbability ${
        node.resultProbabilityDecoration
          ? decorationAttribute(
              pickBy(identity, {
                lineColor: node.resultProbabilityDecoration.color,
                lineHighlightColor: node.resultProbabilityDecoration.highlightColor,
                lineStyle: node.resultProbabilityDecoration.style,
                lineWeight: node.resultProbabilityDecoration.weight,
              })
            )
          : ''
      }
      >
      ${exportContent(node.resultProbability.$refid, context)}
    </resultProbability>`
    : '';

const exportResultTuple = (node: TreeNode, context: ExporterContext): string =>
  node.type === TreeChartNodeType.leaf && node.resultTuple
    ? `<resultTuple ${
        node.resultTupleDecoration
          ? decorationAttribute(
              pickBy(identity, {
                lineColor: node.resultTupleDecoration.color,
                lineHighlightColor: node.resultTupleDecoration.highlightColor,
                lineStyle: node.resultTupleDecoration.style,
                lineWeight: node.resultTupleDecoration.weight,
              })
            )
          : ''
      }
    >
      ${exportContent(node.resultTuple.$refid, context)}
    </resultTuple>`
    : '';

const exportEdge = (edge: TreeEdge, context: ExporterContext): string => `
  <edge>
    <semantics>
      <mrow ${
        edge.decoration
          ? decorationAttribute(
              pickBy(identity, {
                lineColor: edge.decoration.color,
                lineHighlightColor: edge.decoration.highlightColor,
                lineStyle: edge.decoration.style,
                lineWeight: edge.decoration.weight,
              })
            )
          : ''
      }>
        ${edge.probability ? wrapContent('probability', edge.probability, context) : ''}
      </mrow>
      <annotation-xml encoding="bettermarks">
        <special id="${edge.$id}" render-style="tree-node-edge"/>
      </annotation-xml>
    </semantics>
  </edge>
`;

export const exportTreeNode = (context: ExporterContext) => (node: TreeNode) =>
  `
  <semantics>
    <mrow>
      <node>
        ${node.shape && exportNode(context, false)(node.shape)}
      </node>
      ${node.edge ? exportEdge(node.edge, context) : ''}
      ${exportResultProbability(node, context)}
      ${exportResultTuple(node, context)}
      ${exportChildren(node.children, context)}
    </mrow>
    <annotation-xml encoding="bettermarks">
      <special id="${node.$id}" render-style="${node.type}"/>
    </annotation-xml>
  </semantics>
`;

export const exportChildren = (
  children: ReadonlyArray<TreeNode>,
  context: ExporterContext
): string => `
  <children>
    ${children.map(exportTreeNode(context)).join('')}
  </children>
`;

export const exportTreeChartGizmo: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content = context.content[contentRefId] as TreeChartContent;
  return semantics(
    `<mrow>
      ${exportConfiguration(content)}
      ${exportChildren(content.tree.children, context)}
    </mrow>`,
    special(content)
  );
};
