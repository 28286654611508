import { ApplicationState, type IApplicationState } from '../../types';
import { reduxStateFlowService } from './jsstore';

type ToLogExtra = (state: Readonly<IApplicationState>) => {
  seriesId: string;
  currentExerciseId: 'unknown' | string;
  currentStepId: 'unknown' | string;
  appSetting: IApplicationState['appSettings'];
  seriesSettings: IApplicationState['series']['seriesSettings'];
};

/**
 * Pick data from `state` that should be logged as part of the `extra` argument
 * for `exceptionLog`.
 *
 * Be aware that we had multiple problems when trying to log the whole state
 * and that it also has an impact on the network traffic from each client.
 * So be picky about what fields to send and avoid large/nested object structures of dynamic data.
 *
 * @see exceptionLog
 */
export const toLogExtra: ToLogExtra = (state) => {
  const currentExercise = ApplicationState.toCurrentExercise.get(state);
  return {
    seriesId: state.series.id,
    currentExerciseId: currentExercise?.id || 'unknown',
    currentStepId: currentExercise?.currentStepId || 'unknown',
    appSetting: state.appSettings,
    seriesSettings: state.series.seriesSettings,
  };
};

export const lastThreeActions = async (state: Readonly<ApplicationState>): Promise<string> => {
  const stateFlowForSeriesId = await reduxStateFlowService.instance?.getSeries(state.series.id);
  const actions =
    (stateFlowForSeriesId &&
      stateFlowForSeriesId.length !== 0 &&
      stateFlowForSeriesId[0].actions) ||
    [];
  return JSON.stringify(actions?.splice(-3));
};
