import {
  type Defaults,
  type Importer,
  PLACEHOLDER,
  RS,
  type ThermometerContent,
  toInt,
  xmlText,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';
import { type Severity } from '@bettermarks/umc-kotlin';

export const THERMOMETER_DEFAULT_CONTENT: Defaults<ThermometerContent, 'configuration'> = {
  $: PLACEHOLDER,
  $renderStyle: RS.THERMOMETER,
  value: 0,
};

/**
 * imports the Thermometer XML and parses it.
 * It contains an optional start value and
   configuration object containing min value,max value and
   optional label.
 * @param preContent
 * @param xml
 */
export const importThermometer: Importer<ThermometerContent> = (preContent, xml) => {
  const mrow = xml.findChildTag('mrow');
  const config = mrow.findChildTag('configuration');
  const severity = mrow.attribute('decoration') as Severity;
  const value = mrow.hasChild('mn')
    ? toInt(mrow.findChildTag('mn').text)
    : THERMOMETER_DEFAULT_CONTENT.value;
  return {
    ...preContent,
    configuration: {
      ...config.tagsToProps(xmlTextToInt, ['min', 'max']),
      ...config.tagsToProps(xmlText, [], ['label']),
    },
    value,
    ...(severity && { severity: severity }),
  };
};
