import { isEmpty } from 'lodash';
import { ContentColor, type Importer, toBoolean } from '@bettermarks/gizmo-types';
import { type PieOrStackedBarChartContent as PieChartContent } from '@bettermarks/gizmo-types';
import { importSlice } from './importStackedBarChart';

export const importPieChart: Importer<PieChartContent> = (preContent, xml, context) => {
  const chart = xml.findChildTag('chart');
  const hasLegend = toBoolean(chart.attribute('hasLegend'));
  const title = chart.findChildTag('title');
  const slices = chart
    .findChildTag('slices')
    .getChildrenByTagName('slice')
    .map((slice) => importSlice(slice, context, !isEmpty(preContent.$interactionType)));
  const hasMarkedSlice = slices.find((s) => !!s.mark) ? true : undefined;
  const slicesWithLabels = slices.filter((s) => !!s.sliceLabel).length;
  const needsBorder = slices.some((s) => s.color === ContentColor.BM_WHITE);
  return {
    ...preContent,
    /**
     * uniqueId for markers/clip-path/filters
     * (assumption: there are no duplicate $refids per exercise)
     */
    uniqueId: context.$refid,
    hasLegend,
    slices,
    hasMarkedSlice,
    slicesWithLabels,
    needsBorder,
    ...(title.exists && { title: context.importXML(title.firstChild) }),
  };
};
