import {
  type ConditionalFeedback,
  ContentPathHandler,
  Severity,
  type Validation,
  ValidationType,
  ValidatorEngine,
} from '@bettermarks/umc-kotlin';
import { exporters, importContentTree, importers } from '@bettermarks/importers';
import { rulers } from '../../../../gizmo-utils/configuration/rulers';
import { stylers } from '../../../../gizmo-utils/configuration/stylers';
import { MATH, SEMANTICS } from '../../../../gizmo-utils/constants';
import { enrichContentDict } from '../../../../gizmo-utils/measure';
import { DependencyCollector, toXmlElement } from '@bettermarks/gizmo-types';
import { importExerciseXML } from '../../../../xml-converter/exercise';
import {
  type AppExercise,
  CollapsibleContentState,
  type ContentExercise,
  type ContentStep,
  ExerciseStatus,
  type StepFeedback,
} from '../../../../types';
import { lockStep, solveStep, startStep } from '../Series/reducerHelpers';
import { type BackendSeries } from '../../services/api/content-manager/types';
import { _importFeedback } from '../../validationHelpers';

const EXPANDABLE = () => /^([a-z]{2}_[A-Z]{2}(_[a-zA-Z]+)?)\/\.\.\.\/(\w+\.AT\d+)$/;

export const isExpandablePath = (str: string) => EXPANDABLE().test(str);
export const isExercisePath = (str: string) => /^[a-z]{2}_[A-Z]{2}(_[a-zA-Z]+)?\/EX\//.test(str);

/**
 * Gets the exercise ID that is shown in input filed of flash-seriesplayer.
 * e.g. 'en_US/.../FuDLFFZei12.AT2'.
 *
 * The shortened path consists of
 * '<locale>/.../<skillName><skillNumber>.<exerciseType>'.
 * The three dots are a shorthand notation of a path in the actual content structure.
 *
 * The above example result in the following string:
 * 'en_US/EX/fudlff/zei/12/AT2/release/FuDLFFZei12.AT2_ex01.xml'
 *
 * The Actionscript version of the code is at
 * http://gitlab.bm.loc/bettermarks/bm_mathcore_api/
 *                               blob/master/src/com/bettermarks/mathcore/util/ContentPathHandler.as
 *
 * The Kotlin version of the code is at
 * https://github.com/bettermarks/umc-kotlin/
 *                             blob/master/api-common/src/main/kotlin/umc/util/ContentPathHandler.kt
 *
 * @param {string} exerciseLocaleId
 * @returns {string}
 */
export const expandSeriesIdPath = (exerciseLocaleId: string): string => {
  const testPattern = EXPANDABLE().exec(exerciseLocaleId);
  if (!testPattern) {
    throw new Error(`unexpected format '${exerciseLocaleId}'`);
  }
  const [, locale, , exerciseType] = testPattern;

  const expandedPath = ContentPathHandler.getPathFromTypeId(exerciseType, locale);

  // skillNumber can be undefined
  return [expandedPath, 'release', `${exerciseType}_ex01`].filter((item) => item !== '').join('/');
};

/**
 * For given exerciseId, creates full path to fetch exercise
 * @param {string} exerciseid
 * @returns {string}
 */
export const getFullExercisePath = (exerciseid: string) => {
  const fullExerciseId = isExpandablePath(exerciseid) ? expandSeriesIdPath(exerciseid) : exerciseid;
  return isExercisePath(fullExerciseId) ? `content_exercises/${fullExerciseId}` : fullExerciseId;
};

const EMPTY_CONTENT_STEP = (): ContentStep => ({
  answer: {},
  description: '',
  explanation: {},
  showKEM: true,
  feedbacks: [],
  hints: [],
  id: 'step.id',
  instruction: {},
  knowledgeGaps: [],
  maxErrors: 2,
  mandatory: false,
  question: {},
  questionIsAnswer: false,
  supports: [],
  skill: '',
  title: '',
  type: '',
  answerXML: '',
  questionXML: '',
  validatorEngine: ValidatorEngine.mathcore,
  validation: {
    includedGlobalFeedbacks: [],
    includedGlobalFeedbacksInTest: [],
    includedPlaceholderFeedbacks: {},
    excludedGlobalFeedbacks: [],
    excludedGlobalFeedbacksInTest: [],
    excludedPlaceholderFeedbacks: {},
    validationType: ValidationType.default,
    embedPlaceholders: undefined,
    conditionCorrectExpr: undefined,
    conditionValidExpr: undefined,
    conditionValidPatterns: undefined,
    conditionCorrectPatterns: undefined,
    code: undefined,
    feedbacks: [
      {
        key: '',
        localKey: '',
        ignoreInTest: true,
        textXML: '',
        severity: Severity.ok,
        code: '',
        condition: '',
        required: false,
        patterns: '',
        learningObjectiveId: undefined,
      },
    ],
  },
});

const EMPTY_CONTENT_EXERCISE: ContentExercise = {
  id: '',
  locale: '',
  dependencies: {
    fems: [],
    kems: [],
    images: [],
    importedRenderStyles: [],
  },
  exerciseType: '',
  featuresTimestamp: '',
  setting: {},
  description: '',
  steps: [EMPTY_CONTENT_STEP()],
};

/**
 * Enriches all `Content` of the `contentDict` that is referenced
 * in the passed `exercise`.
 * @param {ContentExercise} exercise
 * @returns {ContentExercise}
 */
export const enrichExercise = ({
  wrapup,
  setting,
  steps,
  ...ex
}: ContentExercise): ContentExercise => ({
  ...ex,
  setting: enrichContentDict(setting, stylers, rulers, true),
  ...(wrapup && {
    wrapup: enrichContentDict(wrapup, stylers, rulers, true),
  }),
  steps: steps.map((step) => ({
    ...step,
    question: enrichContentDict(step.question, stylers, rulers),
    answer: enrichContentDict(step.answer, stylers, rulers, true),
    instruction: enrichContentDict(step.instruction, stylers, rulers, true),
    explanation: enrichContentDict(step.explanation, stylers, rulers, true),
    hints: step.hints
      ? step.hints.map((hint) => enrichContentDict(hint, stylers, rulers, true))
      : [],
    supports: step.supports
      ? step.supports.map((support) => enrichContentDict(support, stylers, rulers, true))
      : [],
  })),
});

const importFeedbacksFromValidation = (
  validation: Validation | undefined,
  pathToStep: string
): ReadonlyArray<StepFeedback> =>
  validation && validation.feedbacks
    ? validation.feedbacks.map((feedback: ConditionalFeedback, i) =>
        _importFeedback(feedback, `${pathToStep}.feedbacks[${i}]`)
      )
    : [];

const getQaSteps = (steps: ReadonlyArray<ContentStep>) =>
  steps.map((step, i) =>
    solveStep(step, importFeedbacksFromValidation(step.validation, `steps[${i}]`), true)
  );

export const initializeExercise = (
  exercise: ContentExercise,
  exerciseIndex: number,
  qaMode?: boolean,
  testMode?: boolean
): AppExercise => {
  const ex = enrichExercise(exercise);
  const now = new Date().getTime();
  const currentStepId = exercise.steps[0].id;

  let steps;

  if (qaMode) {
    steps = getQaSteps(ex.steps);
  } else {
    steps = ex.steps.map((step, stepIndex) =>
      stepIndex === 0 && exerciseIndex === 0 ? startStep(step, now) : lockStep(step)
    );
  }

  let status;
  if (qaMode) {
    status = ExerciseStatus.completed;
  } else if (exerciseIndex === 0 || testMode) {
    status = ExerciseStatus.started;
  }

  return {
    ...ex,
    startTime: exerciseIndex === 0 ? now : -1,
    steps,
    currentStepId,
    ...(status && { status }),
    wrapupState: qaMode ? CollapsibleContentState.expanded : CollapsibleContentState.hidden,
    stepValidationLoaded: true, // TODO: this is only to fix a bug and should be removed
  };
};

/**
 * Converts a list strings (from XML files) to a list of AppExercises.
 * Supports both MathML and "ExerciseML" as input.
 * In case of MathML each XML is imported into an exercise format,
 * where the question contains an interactive
 * and the setting contains a disabled version of the MathML.
 *
 * For modifying the ImporterRegistry or DependencyCollector to use,
 * pass an instance of ContentTreeImporter as the last argument.
 * By default a proper one for the scope of this method call will be created.
 *
 * @param {ReadonlyArray<string>} fileData
 * @param {boolean} qaMode passed to initializeExercise
 * @param {boolean} testMode passed to initializeExercise
 * @param {ContentTreeImporter} importTree
 * @returns {AppExercise[]}
 *
 * @see initializeExercise
 */
export const getExercises = (
  fileData: ReadonlyArray<string>,
  qaMode: boolean,
  testMode: boolean,
  importTree = importContentTree(importers, new DependencyCollector())
): ReadonlyArray<AppExercise> =>
  fileData.map((fileString, index): AppExercise => {
    const xml = toXmlElement(fileString);
    if (xml.localName === MATH || xml.localName === SEMANTICS) {
      const setting = importTree(xml, 'setting');
      const question = importTree(xml, 'steps[0].question');
      const dummyXMLElement = toXmlElement('<math render-style="text"><mtext>dummy</mtext></math>');

      const exercise: ContentExercise = {
        ...EMPTY_CONTENT_EXERCISE,
        setting,
        ...(index === 0 && { status: ExerciseStatus.started }),
        steps: [
          {
            ...EMPTY_CONTENT_STEP(),
            id: 'step.id',
            instruction: importTree(dummyXMLElement, 'steps[0].instruction'),
            question,
            explanation: importTree(dummyXMLElement, 'steps[0].explanation'),
            answer: importTree(dummyXMLElement, 'steps[0].answer'),
          },
        ],
      };
      return initializeExercise(exercise, index, qaMode, testMode);
    } else {
      const exercise = importExerciseXML(xml, importers, exporters);
      return initializeExercise(exercise, index, qaMode, testMode);
    }
  });

export const loadLocalXMLFiles = async (files: any): Promise<ReadonlyArray<string>> => {
  return Promise.all(files.map(async (file: any) => new Response(file).text()) as string);
};

export const loadLocalSeriesFile = async (file: string): Promise<BackendSeries> => {
  const series = require(`../../../../../cypress/fixtures/${file}`) as BackendSeries;
  return Promise.resolve(series);
};
