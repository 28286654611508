import { defaultTo } from 'lodash';
import { type ApplyStyles, type Ruler } from '../../../gizmo-utils/configuration';
import { type DragSourceContent } from '@bettermarks/gizmo-types';
import { PADDING } from '../../../components/DragAndDrop';

export const applyDragSourceStyles: ApplyStyles = (content: DragSourceContent, outerStyles) => [
  {
    refId: content.content.$refid,
    style: outerStyles,
  },
];

export const measureDragSource: Ruler<DragSourceContent> = (
  computedStyles,
  content,
  getMetrics
) => {
  const childMetrics = getMetrics(content.content.$refid);
  // for the drag source the height is either the content's height or the one determined by auto
  // sizing
  const defaultHeight = childMetrics.height + 2 * PADDING;

  const height = defaultTo(content.height, defaultHeight);

  return {
    height,
    refLine: 0.5 * height,
  };
};
