import { createAction } from 'redux-actions';

export const CHANGE_COLOR = 'CHANGE_COLOR';
export const CHANGE_SIZE = 'CHANGE_SIZE';

/**
 * `changeColor(color: GizmoColor)`: change the color of the Gizmo face
 */
export const changeColor = createAction(CHANGE_COLOR);

/**
 * `changeSize(color: GizmoColor)`: change the size of the Gizmo face
 */
export const changeSize = createAction(CHANGE_SIZE);

export type HelloGizmoActionPayload = void;
