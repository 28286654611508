import {
  connect,
  type ConnectedProps,
  type MapDispatchToPropsFunction,
  type MapStateToProps,
  type MergeProps,
} from 'react-redux';

import { type Dispatch } from '../../../../gizmo-utils/redux';
import { ApplicationState, DialogType, Series, SeriesMode } from '../../../../types';

import { getSeriesProgress } from '../Series/helpers';
import * as ExerciseActions from '../Exercise/actions';
import * as SeriesPlayerActions from '../SeriesPlayer/actions';
import { openDialog } from '../SeriesPlayer/actions';
import * as ToolbarActions from '../Toolbar/actions';
import Header from './Header';

type DispatchProps = {
  dispatch: Dispatch;
  showHandInConfirmation: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const mapDispatchToProps: MapDispatchToPropsFunction<DispatchProps, {}> = (dispatch) => ({
  showHandInConfirmation: () => {
    dispatch(ExerciseActions.updateFilledStatus());
    dispatch(ToolbarActions.toggleToolbarDrawer({ drawerName: null }));
    dispatch(SeriesPlayerActions.openDialog({ type: DialogType.handInConfirmation }));
  },
  dispatch,
});

type StateProps = {
  assignmentEndDate: undefined | string;
  closeButton: boolean;
  currentExercise: number;
  hideHelpTools: boolean;
  isEndOfSeries: boolean;
  mode: SeriesMode;
  numExercises: number;
  progress: number;
  reportProblemButton: boolean;
  seriesTitle: undefined | string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => {
  const series = ApplicationState.toSeries.get(state);
  const assignment = Series.toAssignment.get(series);
  const exercises = Series.toExercises.get(series);
  const mode = Series.toSeriesMode.get(series);
  const seriesSettings = Series.toSeriesSettings.get(series);

  const { features } = state;

  const hideHelpTools =
    series.mode === SeriesMode.review || (seriesSettings ? seriesSettings.hideHelpTools : false);

  return {
    assignmentEndDate: assignment?.endDate,
    closeButton: !features.hideCloseButton,
    currentExercise: series.currentExerciseIndex,
    hideHelpTools,
    isEndOfSeries: ApplicationState.isEndOfSeries(state),
    mode,
    numExercises: exercises.length,
    progress: getSeriesProgress(exercises, mode),
    reportProblemButton: !features.hideReportProblem,
    seriesTitle: series.title,
  };
};

type MergedProps = {
  closeSeriesPlayer: (currentISODateString: string) => void;
  nextExercise: () => void;
  previousExercise: () => void;
  showExerciseNavigation: () => void;
  showHelp: () => void;
  showReportProblem: () => void;
} & Omit<StateProps, 'isEndOfSeries' | 'assignmentEndDate'> &
  Omit<DispatchProps, 'dispatch'>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const mergeProps: MergeProps<StateProps, DispatchProps, {}, MergedProps> = (
  stateProps,
  dispatchProps
) => {
  const { isEndOfSeries, assignmentEndDate, ...restStateProps } = stateProps;
  const { dispatch, showHandInConfirmation } = dispatchProps;

  const showCloseConfirmation = (): void => {
    if (restStateProps.mode === SeriesMode.test) {
      dispatch(ExerciseActions.saveInput());
    }
    dispatch(SeriesPlayerActions.openDialog({ type: DialogType.closeConfirmation }));
  };

  const showExerciseNavigation = (): void => {
    if (restStateProps.mode === SeriesMode.test) {
      dispatch(ExerciseActions.updateFilledStatus());
    }
    dispatch(SeriesPlayerActions.openDialog({ type: DialogType.exerciseNavigation }));
  };

  const nextExercise = (): void => {
    if (isEndOfSeries && restStateProps.mode === SeriesMode.test) {
      showHandInConfirmation();
    } else if (restStateProps.currentExercise < restStateProps.numExercises - 1) {
      dispatch(ExerciseActions.switchExerciseResumable(restStateProps.currentExercise + 1));
    }
  };

  const previousExercise = (): void => {
    if (restStateProps.currentExercise > 0) {
      dispatch(ExerciseActions.switchExerciseResumable(restStateProps.currentExercise - 1));
    }
  };

  /**
   * this fn correctly delegates to the appropriate redux close action
   * depending on the current `SeriesMode` environment
   */
  const closeSeriesPlayer = (currentISODateString: string): void => {
    switch (restStateProps.mode) {
      case SeriesMode.review:
        dispatch(SeriesPlayerActions.quitSeriesPlayer());
        break;

      case SeriesMode.test: {
        if (
          assignmentEndDate &&
          Date.parse(currentISODateString) >= Date.parse(assignmentEndDate)
        ) {
          dispatch(
            SeriesPlayerActions.openDialog({
              type: DialogType.closeConfirmationAfterDueDate,
            })
          );
          break;
        }
      }
      /* eslint-disable-next-line no-fallthrough */
      default:
        showCloseConfirmation();
    }
  };

  return {
    ...restStateProps,
    closeSeriesPlayer,
    nextExercise,
    previousExercise,
    showExerciseNavigation,
    showHandInConfirmation,
    showHelp: () => dispatch(openDialog({ type: DialogType.help })),
    showReportProblem: () => dispatch(openDialog({ type: DialogType.reportProblem })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export type HeaderPropsFromRedux = ConnectedProps<typeof connector>;
export const HeaderContainer = connector(Header);
