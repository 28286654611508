import isNil from 'lodash/isNil';
import colors from 'styles/colors.scss';
import bmcolors from 'styles/bmcolors.scss';
import { SeverityColor, type ContentColor } from '@bettermarks/gizmo-types';

const area = (color: string) => `${color}-area`;

const SeverityColorValues: { [key: string]: string } = {
  [SeverityColor.ERROR]: colors.TEXT_ERROR,
  [area(SeverityColor.ERROR)]: colors.BORDER_ERROR,
  [SeverityColor.REMARK]: colors.TEXT_REMARK,
  [area(SeverityColor.REMARK)]: colors.BORDER_REMARK,
  [SeverityColor.OK]: colors.TEXT_OK,
  [area(SeverityColor.OK)]: colors.BORDER_OK,
  [SeverityColor.COMMENT]: colors.TEXT_COMMENT,
  [area(SeverityColor.COMMENT)]: colors.BORDER_COMMENT,
};

type StringOrNil = string | undefined;

export const isContentColor = (name?: string): name is ContentColor => !!name && name in bmcolors;
export const isSeverityColor = (name: string) => name in SeverityColorValues;

/**
 * Checks whether the given string is a known color name.
 * @param {string} name
 * @returns {boolean}
 */
export const isColorName = (name: string): name is ContentColor & SeverityColor =>
  isContentColor(name) || isSeverityColor(name);

/**
 * Attempts to convert a string into the CSS ready value:
 * - if it is a `SeverityColor` or `ContentColor`
 * - if it starts with `'0x...'` (converting to `'#...'`
 *
 * @param {StringOrNil} name
 * @param {boolean} isArea
 * @returns {StringOrNil}
 */
export const getNamedColor = (name: StringOrNil, isArea = false): StringOrNil => {
  if (isNil(name)) {
    return name;
  }

  const severityName = isArea ? area(name) : name;
  if (severityName in SeverityColorValues) {
    return SeverityColorValues[severityName];
  }

  if (name in bmcolors) {
    return bmcolors[name];
  }

  return name.replace('0x', '#');
};
