import * as React from 'react';
import styles from './Whiteboard.scss';
import { Canvas, type CanvasRef } from './Canvas';
import { ContentType, DialogType } from '../../types';
import { Dialog } from '../seriesplayer';
import classNames from 'classnames';
import { defaultWhiteboardState, type WhiteboardState } from './types';
import { RESET_MESSAGE } from './useHandleReset';
import { whiteboardReducer } from './reducer';
import PenSidebar from './toolbar/PenSidebar';
import EraserSidebar from './toolbar/EraserSidebar';
import Toolbar from './toolbar/Toolbar';
import { VIRTUAL_CONTENT_WIDTH } from './constants';
import { getToolbarsWidth, getWidthWithoutToolbars } from './utils';
import {
  getDrawableAreaOverflowY,
  getParamsForViewedReporting,
  getScreenHeight,
  getScreenWidth,
} from './util';
import { getBaseMetadata } from '../seriesplayer/services';
import { quitWhiteboard } from '../emplayer/actions';
import { useDispatch } from 'react-redux';
import { useWhiteboardContext, WhiteboardContextProvider } from './whiteboard-context';
import { useResultManagerUrl } from '../emplayer/useResultManagerUrl';
import { useScroll } from '../../utils/hooks';
import { useForceRerender } from '../../utils/hooks/useForceRerender';
import WhiteboardKeyboard from './tools/WhiteboardKeyboard';
import { dimensions } from '@seriesplayer/common-ui';
import { ClassroomIntroStudentTasks } from './ClassroomIntroStudentTasks';
import styled from 'styled-components';
import DisablePullToRefresh from './DisablePullToRefresh';
import { ExampleNavigation, type ExampleNavigationItem } from '../example-mode/ExampleNavigation';
import ContentToolsSidebar from './tools/ContentToolsSidebar';
import type { WhiteboardMessage } from '../iframe-posts';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { useLocation } from 'react-router-dom';

type StudentTaskContextValue = {
  reportHeight: (height: number) => void;
};

export const StudentTasksContext = React.createContext<StudentTaskContextValue | undefined>(
  undefined
);

export const RESET_MESSAGE_FOR_INCLUDE_GIZMO = 'resetIncludeGizmo';
export const IFRAME_CONTENT_TITLE = 'iframe whiteboard content';

export const STUDENT_TASKS_MIN_WIDTH = 300;
export const STUDENT_TASKS_MARGIN = dimensions.spaceS;

export type WhiteBoardProps = {
  iframeUrl: string;
  contentType?: ContentType;
  id?: string;
  selectedNavigationItem?: ExampleNavigationItem;
};

interface DrawableAreaProps {
  overflowY: string;
}

const DrawableArea = styled.div<DrawableAreaProps>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: ${({ overflowY }) => overflowY};
  overflow-x: hidden;
`;

const ContentFrame = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

interface ScaledContainerProps {
  height: number;
  width: number;
  scale: number;
}

const ScaledContainer = styled.div<ScaledContainerProps>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  transform: scale(${({ scale }) => scale});
  transform-origin: 0 0;
`;

const VerticalLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export function Whiteboard(props: WhiteBoardProps) {
  const resultManagerUrl = useResultManagerUrl();
  const initWhiteboardState: WhiteboardState = {
    ...defaultWhiteboardState,
    resultManagerUrl,
    selectedNavigationItem: props.selectedNavigationItem || 'exercise',
  };
  const [whiteboardState, dispatchWhiteboard] = React.useReducer(whiteboardReducer, {
    ...initWhiteboardState,
    id: props.id,
  });

  return (
    <WhiteboardContextProvider value={{ state: whiteboardState, dispatch: dispatchWhiteboard }}>
      <WhiteboardContent {...props} />
    </WhiteboardContextProvider>
  );
}

function WhiteboardContent({ iframeUrl, contentType, id }: WhiteBoardProps) {
  const { state: whiteboardState, dispatch: dispatchWhiteboard } = useWhiteboardContext();
  const {
    activeDialog,
    scaleConfig: { currentScale, dimensions, scaleType },
    selectedNavigationItem,
    canvasHeight,
    selectedTool,
    contentToolConfig,
  } = whiteboardState;
  const forceRerender = useForceRerender();
  const [startTime] = React.useState(Date.now());
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = React.useState<number>(dimensions.height);
  const canvasRef = React.useRef<CanvasRef | null>(null);
  const [overflowYisDisabled, disableOverflowY] = React.useState(false);
  const drawableAreaRef = useScroll<HTMLDivElement>((e: Event) => {
    if (canvasRef.current) {
      canvasRef.current.onParentScroll((e.target as HTMLElement).scrollTop / currentScale);
    }
  });
  const [femId, setFemId] = React.useState<string>();

  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current?.onZoom(currentVerticalScroll);
    }
  }, [whiteboardState.scaleConfig]);

  React.useEffect(() => {
    function handleFullScreenChange() {
      if (document.fullscreenElement) {
        dispatchWhiteboard({ type: 'set', payload: { isFullScreen: true } });
      } else {
        dispatchWhiteboard({ type: 'set', payload: { isFullScreen: false } });
      }
    }
    function onMessageReceived(event: MessageEvent) {
      if (event.data.height) {
        // embedded player reported new height, so let's update our iframe
        setIframeHeight(event.data.height);
        dispatchWhiteboard({
          type: 'set',
          payload: {
            canvasHeight: Math.max(event.data.height, getScreenHeight()),
          },
        });
      }
      if (event.data.type === 'showFEM') {
        // Check out FEMs_and_the_Whiteboard_Mode.md
        setFemId(event.data.femId);
        dispatchWhiteboard({
          type: 'openDialog',
          dialogType: { type: DialogType.fem, payload: event.data.femId },
        });
      } else if (event.data.type === 'lockScroll') {
        disableOverflowY(true);
      } else if (event.data.type === 'unlockScroll') {
        disableOverflowY(false);
      }
    }

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    window.addEventListener('message', onMessageReceived);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      window.removeEventListener('message', onMessageReceived);
    };
  }, [dispatch, dispatchWhiteboard, disableOverflowY]);

  const onCancelReset = () => dispatchWhiteboard({ type: 'closeDialog' });

  const onConfirmReset = () => {
    dispatchWhiteboard({ type: 'set', payload: { canvasActionStack: [] } });
    afterConfirmReset();
    dispatchWhiteboard({ type: 'closeDialog' });
  };

  const afterConfirmReset = () => {
    document
      .querySelectorAll('iframe')
      .forEach((el: HTMLIFrameElement) =>
        el.contentWindow?.postMessage(RESET_MESSAGE_FOR_INCLUDE_GIZMO, '*')
      );

    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(RESET_MESSAGE, '*');
    }

    dispatchWhiteboard({ type: 'closeDialog' });
  };

  const exitWhiteboardMode = () => {
    dispatchWhiteboard({ type: 'openDialog', dialogType: { type: DialogType.closeConfirmation } });
    const { resultManagerUrl, ...params } = getParamsForViewedReporting(location.search);
    const { userAgent } = getBaseMetadata();
    const viewDuration = Math.floor((Date.now() - startTime) / 1000);
    dispatch(
      quitWhiteboard({ viewedBody: { ...params, viewDuration, userAgent }, resultManagerUrl })
    );
  };

  const isPenBarVisible = selectedTool === 'pen';
  const isEraserBarVisible = selectedTool === 'eraser';
  const isContentToolsBarVisible =
    selectedTool === 'content-tools' && selectedNavigationItem === 'exercise';

  const hasTwoSidebars = isEraserBarVisible || isPenBarVisible || isContentToolsBarVisible;
  const toolbarsWidth = getToolbarsWidth(hasTwoSidebars);
  const availableWidth = getWidthWithoutToolbars(hasTwoSidebars, dimensions.width);

  const scaledAvailableWidth = availableWidth / currentScale;

  const scaledVirtualContentWidth = VIRTUAL_CONTENT_WIDTH * currentScale;

  const iframeWidth = Math.min(VIRTUAL_CONTENT_WIDTH, scaledAvailableWidth);

  const studentTasksFitRight: boolean =
    scaledAvailableWidth - VIRTUAL_CONTENT_WIDTH >
    STUDENT_TASKS_MIN_WIDTH + 2 * parseFloat(STUDENT_TASKS_MARGIN);

  const studentTasksWidth = studentTasksFitRight
    ? STUDENT_TASKS_MIN_WIDTH
    : iframeWidth - 2 * parseFloat(STUDENT_TASKS_MARGIN);

  const currentVerticalScroll = drawableAreaRef.current?.scrollTop || 0;

  const [studentTasksHeight, setStudentTasksHeight] = React.useState(0);

  const classroomIntroHeight = studentTasksFitRight
    ? Math.max(iframeHeight, studentTasksHeight)
    : iframeHeight + studentTasksHeight + 2 * parseFloat(STUDENT_TASKS_MARGIN);

  const contentHeight = contentType === ContentType.CRI ? classroomIntroHeight : iframeHeight;

  const overflowYOnContainer: boolean = contentHeight * currentScale > dimensions.height;

  // Hide scrollbar when you scroll back and there is enough space to display content
  const scrollHandler = () => {
    if (!drawableAreaRef.current) return;
    if (
      !(currentVerticalScroll === 0) &&
      drawableAreaRef.current.scrollTop === 0 &&
      !overflowYOnContainer
    ) {
      forceRerender();
    }
  };

  React.useEffect(() => {
    const onWindowResize = () => {
      dispatchWhiteboard({
        type: 'setDimensions',
        dimensions: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    };
    window.addEventListener('resize', onWindowResize);
    onWindowResize();
    return () => window.removeEventListener('resize', onWindowResize);
  }, [dispatchWhiteboard]);

  React.useEffect(() => {
    if (scaleType === 'auto') {
      dispatchWhiteboard({
        type: 'setScale',
        scaleSetting: 'auto',
      });
    }
  }, [dimensions, dispatchWhiteboard, scaleType]);

  React.useEffect(() => {
    function handleReceiveMessage(e: MessageEvent<WhiteboardMessage>) {
      const data = e.data;
      if (data.type === 'availableTools') {
        dispatchWhiteboard({
          type: 'content-tools',
          payload: {
            tools: data.tools,
            modes: data.modes,
            editorMode:
              contentToolConfig?.editorMode === undefined
                ? data.modes[0]
                : contentToolConfig.editorMode,
            keyboard: data.keyboard,
            gizmoId: data.gizmoId,
            openDrawerName: data.keyboard ? ToolbarElements.keyboard : undefined,
          },
        });
      } else if (data.type === 'setOpenDrawer') {
        dispatchWhiteboard({
          type: 'content-tools',
          payload: {
            ...whiteboardState.contentToolConfig,
            gizmoId: data.gizmoId,
            openDrawerName: data.openDrawerName,
          },
        });
      }
    }
    window.addEventListener('message', handleReceiveMessage);
    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  });

  function onCloseDialogDefault() {
    dispatchWhiteboard({ type: 'closeDialog' });
  }

  const extendedIframeUrl = iframeUrl + '&selectedNavigation=' + selectedNavigationItem;

  return (
    <>
      <DisablePullToRefresh />
      <div
        className={classNames({
          [styles.whiteboardContainer]: true,
        })}
        data-cy={'whiteboard-content'}
      >
        <Toolbar id={id} />
        {isPenBarVisible && <PenSidebar />}
        {isEraserBarVisible && <EraserSidebar />}
        {isContentToolsBarVisible && <ContentToolsSidebar />}
        <VerticalLayout>
          {contentType === ContentType.EXERCISE && (
            <ExampleNavigation toolbarsWidth={toolbarsWidth} />
          )}
          <DrawableArea
            onScroll={scrollHandler}
            overflowY={getDrawableAreaOverflowY(
              iframeHeight,
              currentVerticalScroll,
              overflowYOnContainer,
              overflowYisDisabled
            )}
            data-cy={'wb-mode-scroll-container'}
            ref={drawableAreaRef}
          >
            <ScaledContainer
              height={dimensions.height / currentScale}
              width={scaledAvailableWidth}
              scale={currentScale}
            >
              <Canvas
                ref={canvasRef}
                height={canvasHeight}
                width={getScreenWidth()}
                contentAreaScrollTop={0}
              />
              <ContentFrame data-cy={'content-frame'}>
                <iframe
                  ref={iframeRef}
                  width={iframeWidth}
                  height={iframeHeight ? `${iframeHeight}px` : '100%'}
                  src={extendedIframeUrl}
                  title={IFRAME_CONTENT_TITLE}
                  style={{ overflow: 'visible', display: 'inline-block' }}
                />
                <StudentTasksContext.Provider value={{ reportHeight: setStudentTasksHeight }}>
                  <ClassroomIntroStudentTasks availableWidth={studentTasksWidth} />
                </StudentTasksContext.Provider>
              </ContentFrame>
            </ScaledContainer>
          </DrawableArea>
        </VerticalLayout>
        {selectedNavigationItem === 'exercise' && (
          <WhiteboardKeyboard
            availableWidth={Math.min(scaledVirtualContentWidth, availableWidth)}
            toolbarsWidth={toolbarsWidth}
          />
        )}
        {activeDialog?.type === DialogType.resetWhiteboardConfirmation && (
          <Dialog dialog={activeDialog} onCloseDialog={onCancelReset} onConfirm={onConfirmReset} />
        )}
        {activeDialog?.type === DialogType.exitWhiteboardModeConfirmation && (
          <Dialog
            dialog={activeDialog}
            onCloseDialog={onCloseDialogDefault}
            onConfirm={() => exitWhiteboardMode()}
          />
        )}
        {activeDialog?.type === DialogType.whiteboardHelpMenu && (
          <Dialog dialog={{ type: DialogType.reportProblem }} />
        )}
        {activeDialog?.type === DialogType.fem && femId && (
          <Dialog
            dialog={{
              type: DialogType.fem,
              // Check out FEMs_and_the_Whiteboard_Mode.md
              payload: { id: femId, onCloseFromWhiteboard: onCloseDialogDefault },
            }}
          />
        )}
      </div>
    </>
  );
}
