import { type ApplyStyles } from '../../../gizmo-utils/configuration/types';
import { type IDragAndDrop } from '@bettermarks/gizmo-types';

export const applyDragAndDropStyles: ApplyStyles = (content: IDragAndDrop, outerStyles) => [
  {
    refId: content.content.$refid,
    style: outerStyles,
  },
  ...content.items.map((item) => ({
    refId: item.$refid,
    style: outerStyles,
  })),
];
