import { type FontWeight } from '@bettermarks/bm-font';
import { GRAPH, TREE_DIAGRAM } from '../../gizmo-utils/configuration/renderStyles';
import { PLACEHOLDER } from '../../gizmo-utils/constants';
import { type Content, type Defaults, type ContentReference } from '../../xml-converter/core';
import { type ContentColor } from '../../styles/colors';
import { type LineStyle, type LineWeight } from '../../types';
import { type Severity } from '@bettermarks/umc-kotlin';

export const enum NodeType {
  bubble = 'bubble',
  gizmo = 'gizmo',
}

export const enum NodeOptionType {
  colorChooser = 'colorchooser',
  selectBox = 'selectbox',
}

export interface ColorContent {
  type: NodeOptionType.colorChooser;
  options: ContentColor[];
  selected?: ContentColor;
}

export interface TextContent {
  type: NodeOptionType.selectBox;
  options: string[];
  selected?: string;
}

export type BubbleNodeContent = ColorContent | TextContent;

export interface EdgeDecoration {
  color?: ContentColor;
  highlightColor?: ContentColor;
  style?: LineStyle;
  weight?: LineWeight;
}

export interface NodeDecoration {
  borderColor?: ContentColor;
  color?: ContentColor;
  highlightColor?: ContentColor;
  fontWeight?: FontWeight;
}

export interface Position {
  x: number;
  y: number;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Position {
  export const subtract = (p1: Position, p2: Position) => ({
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  });
  export const add = (p1: Position, p2: Position) => ({
    x: p1.x + p2.x,
    y: p1.y + p2.y,
  });
  export const scale = (p: Position, s: number) => ({ x: p.x * s, y: p.y * s });
  export const magnitude = (p: Position) => Math.sqrt(p.x * p.x + p.y * p.y);
  export const round = (p: Position) => ({
    x: Math.round(p.x),
    y: Math.round(p.y),
  });
  export const distance = (p1: Position, p2: Position) => magnitude(subtract(p1, p2));
}

export interface Size {
  width: number;
  height: number;
}

export type SizeMap = { [key: string]: Size };

/**
 * Represents a node of a graph. The graph renderer will render all nodes at
 * their given position and connect it with edges.
 */
export interface GraphNodeBase {
  id?: string;
  isRoot?: boolean;
  interactive?: boolean;
  highlight?: boolean;
  deleteHighlight?: boolean | never;
  position: Position;
  severity?: Severity;
  size?: Size;
  connectedToRoot?: boolean; // needed by validator
  addedByUser?: boolean;
}

export interface GraphEdge extends EdgeDecoration {
  $id?: string;
  nodeId1: string;
  nodeId2: string;
  highlight?: boolean;
  deleteHighlight?: boolean;
  severity?: Severity;
  connectedToRoot?: boolean; // needed by validator
  addedByUser?: boolean;
}

export interface Graph extends Readonly<Content> {
  ticksWidth: number;
  ticksHeight: number;
  nodes: ReadonlyArray<GraphNode>;
  edges: ReadonlyArray<GraphEdge>;
  template?: BubbleNode;
}

export interface GizmoNode extends GraphNodeBase {
  type: NodeType.gizmo;
  content?: ContentReference;
  border?: boolean;
}

export interface BubbleNode extends GraphNodeBase, NodeDecoration {
  type: NodeType.bubble;
  content?: BubbleNodeContent;
  invisible?: boolean;
}

export type GraphNode = GizmoNode | BubbleNode;

export const isGizmoNode = (node: GraphNode): node is GizmoNode => node.type === NodeType.gizmo;
export const isBubbleNode = (node: GraphNode): node is BubbleNode => node.type === NodeType.bubble;

export const enum TreeChartDirection {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export const enum TreeChartNodeType {
  node = 'tree-node-container',
  leaf = 'tree-leaf-container',
}

export type TreeNode = TreeChartNode | TreeChartLeaf;

/**
 * Node of the tree chart.
 * @param shape - the node shape: bubble, gizmo or root node
 * @param children - the node's children
 */
export interface TreeNodeBase {
  $id?: string; // necessary for export/import
  shape?: GraphNode;
  edge?: TreeEdge;
  border?: boolean; // should the node have a border?
  children: ReadonlyArray<TreeNode>;
  type: TreeChartNodeType;
}

export interface TreeEdge {
  $id?: string;
  probability?: ContentReference;
  probabilityBorder?: boolean;
  decoration?: EdgeDecoration;
  invisibleNode?: boolean; // denotes 'artificial invisible nodes!
}

/**
 * Complexer node of the tree chart that also containes an edge propability
 * @param edgePropability - formula input field for edge propabilities
 */
export interface TreeChartNode extends TreeNodeBase {
  type: TreeChartNodeType.node;
}

/**
 * Complex leaf node with edge propability and result tuples / propabilities
 *
 * @remark -- Type wise not a real leaf (you can put stuff there). It is just rendered
 * differntly and will not be traversed further by the tree chart renderer.
 */
export interface TreeChartLeaf extends TreeNodeBase {
  type: TreeChartNodeType.leaf;
  resultTuple?: ContentReference;
  resultTupleDecoration?: EdgeDecoration;
  resultTupleBorder?: boolean;
  resultProbability?: ContentReference;
  resultProbabilityDecoration?: EdgeDecoration;
  resultProbabilityBorder?: boolean;
}

/**
 * The main content type containing configuration information and the tree itself
 * @param direction whether the tree is horizonal or vertical
 * @param edgeCorners whether the tree has straight or angular edges
 * @param tree the actual tree represented by it's root node
 */
export interface TreeChartContent extends Readonly<Content> {
  direction: TreeChartDirection;
  edgeCorners: boolean;
  tree: TreeNode;
  layoutTree?: LayoutTreeNode;
  graph?: Graph;
  nodeSizes: SizeMap;
  visible?: boolean;
}

export interface Tree {
  children: Tree[];
}

/**
 * artificial type only used for layouting a tree
 */
export interface LayoutTreeNode extends Tree {
  nodeId: string;
  type: NodeType;
  invisible?: boolean; // invisible flag in case of bubble node
  border?: boolean; // border flag in case of gizmo node
  content?: BubbleNodeContent | ContentReference;
  nodeDecoration?: NodeDecoration; // color etc. for node
  edgeDecoration?: EdgeDecoration; // color etc. for 'incoming edge'
  weightD: number; // some 'weight of a node -> depth distances depend on that value.
  indexB: number; // index in 'breadth dimension'
  indexD: number; // index in 'depth dimension'
  modB: number; // modifier in 'breadth dimension'
  preB: number; // position in 'breadth dimension'
  preD: number; // position in 'depth dimension'
  change: number; // temp. value needed to gather subtree separation (apportion)
  shift: number; // like change
  size: Size; // needed for layout in breadth and depth dimension
  parent?: LayoutTreeNode;
  ancestor?: LayoutTreeNode;
  thread?: LayoutTreeNode;
  children: LayoutTreeNode[];
}

export const GRAPH_GIZMO_DEFAULT_CONTENT: Defaults<Graph> = {
  $: PLACEHOLDER,
  $renderStyle: GRAPH,
  ticksWidth: 10,
  ticksHeight: 10,
  nodes: [],
  edges: [],
};

export const TREE_CHART_GIZMO_DEFAULT_CONTENT: Defaults<TreeChartContent> = {
  $: PLACEHOLDER,
  $renderStyle: TREE_DIAGRAM,
  direction: TreeChartDirection.horizontal,
  edgeCorners: false,
  nodeSizes: {},
  tree: {
    type: TreeChartNodeType.node,
    children: [],
  },
};
