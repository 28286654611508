import {
  type ContentDict,
  type ContentReference,
  hasInteractionType,
  type Importer,
  optionalRefId,
  SOLUTION_SET_DEFAULT_CONTENT,
  type SolutionSetConfiguration,
  type SolutionSetContent,
  Spacing,
  XML_TAG_SEPARATOR,
  xmlText,
  isFormulaContent,
  resolveContentReferences,
  type FormulaContent,
} from '@bettermarks/gizmo-types';

/**
 * Given a ContentDict, provides a method for finding the ContentReference
 * that points to interactive Content.
 *
 * It will do that by searching the referenced content and its first level of childRefIds.
 *
 * @param {ContentDict} contentDict
 * @returns {(ref?: ContentReference) => (undefined | ContentReference)}
 */
const firstInteractiveRef =
  (contentDict: ContentDict) =>
  (ref?: ContentReference): undefined | ContentReference => {
    if (!ref) {
      return undefined;
    }

    if (hasInteractionType(contentDict[ref.$refid])) {
      return ref;
    }
    const content = contentDict[ref.$refid];
    if (isFormulaContent(content)) {
      return resolveContentReferences(content).find((ref) =>
        hasInteractionType(contentDict[ref.$refid])
      );
    }
  };

/**
 * Converts XML data to `Content` structure defined for this gizmo.
 * This function is registered in [[gizmo-utils/configuration/importers]]
 *
 *
 * @param preContent The metadata of a gizmo containing
 *        content-type, id, render-style, interaction-type
 * @param xml The MathML (`semantics` Node) to parse
 * @param outer the current ImporterContext
 * @returns The metadata and parsed xml as `Content`
 */
export const importSolutionSet: Importer<SolutionSetContent> = (preContent, xml, outer) => {
  const defaults = SOLUTION_SET_DEFAULT_CONTENT();
  const mrow = xml.findChildTag('mrow');
  const config = mrow.findChildTag('configuration');

  const configuration: SolutionSetConfiguration = {
    ...defaults.configuration,
    ...config.tagsToProps(xmlText, ['ordered'], ['open', 'close']),
    separator: config.findChildTag(XML_TAG_SEPARATOR).text, // change in name requires extra code
  };

  const context = outer.tempContext();

  const visibleElements = mrow
    .getChildrenByTagName('semantics')
    .map((xml) => context.importXML(xml));

  const hiddenElements = config.findChildTag('set').children.map((xml) => context.importXML(xml));

  const interactiveRefIds = [...visibleElements, ...hiddenElements]
    .map(firstInteractiveRef(context.contentDict))
    .map(optionalRefId);

  interactiveRefIds.forEach((refId, index) => {
    if (refId) {
      const leftSibling = interactiveRefIds[index - 1];
      const rightSibling = interactiveRefIds[index + 1];

      const childContent = context.content.get(refId) as FormulaContent;
      const linkedChildContent: FormulaContent = {
        ...childContent,
        decoration: {
          ...childContent.decoration,
          marginLeft: Spacing.beforeFormulaInput,
          marginRight: Spacing.afterFormulaInput,
        },
        leftSibling,
        rightSibling,
      };

      outer.content.set(refId, linkedChildContent);
    }
  });
  const selectableRefIds = interactiveRefIds;

  outer.mergeMissing(context.content);

  return {
    ...preContent,
    configuration,
    visibleElements,
    hiddenElements,
    selectableRefIds,
  };
};
