import * as React from 'react';
import { identity } from 'lodash/fp';
import classNames from 'classnames';

import { Formula } from '../../../../../../gizmos/formula/Formula';
import { type FormulaContent, type PathHandler } from '@bettermarks/gizmo-types';
import { enrichSingleFormula } from '../../../../../../gizmos/formula/measure';

import { NavigationButton, ClickArea } from '@seriesplayer/common-ui';
import { ChevronLeftSmall, ChevronRightSmall } from '../../../../../../components/icons';
import { CalculatorLayout } from './types';
import styles from './Calculator.scss';
import { KeyCode } from '../Keyboard/keyCodes';
import { ShadowScrollbars } from '../../../../../../gizmos/components';

export interface CalculatorInputProps {
  availableWidth: number;
  content: FormulaContent;
  layout: CalculatorLayout;
  enrich: boolean;
  onKey: (key: KeyCode) => void;
  onSelect: PathHandler;
  innerRef?: React.RefObject<HTMLDivElement>;
}

export const _CalculatorInput: React.FC<CalculatorInputProps> = ({
  availableWidth,
  content,
  layout,
  enrich,
  onKey,
  onSelect,
  innerRef,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const enrichedContent: Function = enrich ? enrichSingleFormula : identity;

  return (
    <div
      data-cy="calculator-input"
      className={classNames({
        [styles.inputVertical]: layout === CalculatorLayout.VERTICAL,
        [styles.inputHorizontal]: layout === CalculatorLayout.HORIZONTAL,
      })}
      ref={innerRef}
    >
      <div className={styles.inputContent}>
        <ShadowScrollbars
          availableWidth={availableWidth}
          fixedChildScrollWidth={availableWidth + 1} // forcing it into shadow scroll
        >
          <Formula
            {...enrichedContent(content)}
            availableWidth={availableWidth}
            onSelect={onSelect}
            inCalculator
          />
        </ShadowScrollbars>
      </div>
      <div className={styles.cursorButtons}>
        <NavigationButton
          key="cursorLeft"
          onClick={() => onKey(KeyCode.CaretLeft)}
          clickArea={ClickArea.left}
        >
          <ChevronLeftSmall width={styles.CURSOR_ICON_SIZE} height={styles.CURSOR_ICON_SIZE} />
        </NavigationButton>
        <NavigationButton
          key="cursorRight"
          onClick={() => onKey(KeyCode.CaretRight)}
          clickArea={ClickArea.right}
        >
          <ChevronRightSmall width={styles.CURSOR_ICON_SIZE} height={styles.CURSOR_ICON_SIZE} />
        </NavigationButton>
      </div>
    </div>
  );
};

export const CalculatorInput = React.forwardRef(
  (props: CalculatorInputProps, ref: React.RefObject<HTMLDivElement>) => (
    <_CalculatorInput {...props} innerRef={ref} />
  )
);

CalculatorInput.displayName = 'CalculatorInput';
