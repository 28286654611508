import { ContentDict } from '@bettermarks/gizmo-types';
import React from 'react';
import { DialogHeader, Icon } from '../../components';
import { useDispatch } from 'react-redux';
import TextWithIcon from './TextWithIcon';
import {
  convertStringToMathContentArray,
  FINAL_EQUATION_SOLUTION,
  FINAL_SKIP_TERM_SOLUTION,
  FINAL_TERM_SOLUTION,
} from './helpers';
import { showScaffolderSolution } from './actions';
import { LifeRing, ReturnArrow } from './icons';
import { useTranslation } from 'react-i18next';
import Popup from '../../components/ContextPopup/Popup';
import {
  Bar,
  MenuItemElement,
  MenuItemElementLabel,
  VerticalMenu,
  VerticalMenuItem,
  VerticalMenuItemType,
} from '@seriesplayer/common-ui';
import { usePlaceholderLabels } from './usePlaceholderLabels';
import { ScaffoldingMode } from './ScaffoldingGizmoRoute';

export type EquationInputRowProps = {
  index: number;
  rows: ContentDict;
  mode: ScaffoldingMode;
  showPopupButtonRef: React.RefObject<HTMLDivElement>;
  setPopupVisible: (value: ((prevState: boolean) => boolean) | boolean) => void;
  popupVisible: boolean;
  expectedSolution?: string;
  expectedTransformation?: string;
  nextFeedbackAvailable: boolean;
  handleShowNextFeedback: () => void;
  handleShowSolution: (isFinalSolution: boolean) => void;
  submit: () => void;
};

export const linkColor = '#2b77e3';

export default function ScaffolderButtons({
  index,
  rows,
  mode,
  showPopupButtonRef,
  setPopupVisible,
  popupVisible,
  handleShowNextFeedback,
  expectedSolution,
  expectedTransformation,
  nextFeedbackAvailable,
  handleShowSolution,
  submit,
}: EquationInputRowProps) {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const inactiveColor = '#aaaaaa';

  const aidAvailable = nextFeedbackAvailable || expectedSolution;
  const aidButtonColors = {
    background: aidAvailable ? '#efc033' : inactiveColor,
    foreground: '#000000',
  };

  // const inputIsEmpty =
  //   (rows[index] as unknown as FormulaContent).content.filter((mathContent) =>
  //     isMToken(mathContent)
  //   ).length === 0;

  // TODO Kira: get rid off index and rows properties
  const isFirstRow = index === 0;
  const nextButtonLabel = useNextButtonLabel(isFirstRow);
  const nextRowPlaceholderLabels = usePlaceholderLabels(false);

  const finalSolution =
    mode === 'term'
      ? FINAL_TERM_SOLUTION
      : mode === 'equation'
      ? FINAL_EQUATION_SOLUTION
      : FINAL_SKIP_TERM_SOLUTION;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TextWithIcon
          icon={{ element: <ReturnArrow /> }}
          text={nextButtonLabel}
          onClick={submit}
          color={'white'}
          style={{
            ...buttonStyle,
            backgroundColor: linkColor,
            color: 'white',
            boxSizing: 'border-box',
          }}
        />
        <div style={{ margin: '0 4px' }} ref={showPopupButtonRef}>
          <TextWithIcon
            text={t('seriesplayer:scaffolding:aides')}
            icon={{ element: <LifeRing fillColor={aidButtonColors.foreground} /> }}
            style={{
              ...buttonStyle,
              backgroundColor: aidButtonColors.background,
              color: aidButtonColors.foreground,
              boxSizing: 'border-box',
            }}
            onClick={
              aidAvailable
                ? () => {
                    setPopupVisible(true);
                  }
                : undefined
            }
          />
        </div>
        <Popup
          elementBounds={
            showPopupButtonRef.current
              ? showPopupButtonRef.current.getBoundingClientRect()
              : undefined
          }
          onClickOverlay={() => {
            setPopupVisible(false);
          }}
          show={popupVisible}
          kind={'small'}
          place={'top'}
        >
          <DialogHeader
            fullWidth={true}
            title={t('seriesplayer:scaffolding:aides')}
            rightButton={{
              icon: 'TimesXLargeBold',
              onClick: () => {
                setPopupVisible(false);
              },
            }}
          />
          <Bar />
          <VerticalMenu separator={true}>
            {nextFeedbackAvailable && (
              <VerticalMenuItem
                type={VerticalMenuItemType.warning}
                onClick={() => {
                  setPopupVisible(false);
                  handleShowNextFeedback();
                }}
              >
                <MenuItemElement>
                  <Icon iconKey="InfoCircleXLarge" />
                </MenuItemElement>
                <MenuItemElementLabel>
                  {t('seriesplayer:scaffolding:aideMenuHint')}
                </MenuItemElementLabel>
              </VerticalMenuItem>
            )}
            {expectedSolution && (mode === 'term' || expectedTransformation) && (
              <VerticalMenuItem
                type={VerticalMenuItemType.confirm}
                onClick={() => {
                  setPopupVisible(false);
                  dispatch(
                    showScaffolderSolution({
                      solutionContent: convertStringToMathContentArray(expectedSolution),
                      transformationContent: expectedTransformation
                        ? convertStringToMathContentArray(expectedTransformation)
                        : [],
                      isFinalSolution: expectedSolution === finalSolution,
                      placeholderLabels: nextRowPlaceholderLabels,
                    })
                  );
                  handleShowSolution(expectedSolution === finalSolution);
                }}
              >
                <MenuItemElement>
                  <Icon iconKey="CheckXLargeBold" />
                </MenuItemElement>
                <MenuItemElementLabel>
                  {t('seriesplayer:scaffolding:aideMenuSolution')}
                </MenuItemElementLabel>
              </VerticalMenuItem>
            )}
          </VerticalMenu>
        </Popup>
      </div>
    </div>
  );
}

export const buttonStyle = {
  minHeight: '44px',
  minWidth: '100px',
  padding: '10px',
  marginRight: '0.5rem',
  borderRadius: '0.3rem',
  zIndex: 1,
  justifyContent: 'center',
};

export const useNextButtonLabel = (isFirstRow: boolean) => {
  const [, i18n] = useTranslation();
  let language = i18n.language || 'en';
  if (language.includes('_')) {
    language = language.split('_')[0];
  }

  let label;
  if (language === 'de') {
    label = isFirstRow ? 'Mit zweitem Schritt weitermachen' : 'Nächster Schritt';
  } else {
    label = isFirstRow ? 'Continue with second step' : 'Next step';
  }

  return label;
};
