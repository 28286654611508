import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBox as Box, Button, ButtonStretch, ButtonKind } from '@seriesplayer/common-ui';
import { Bar } from '@seriesplayer/common-ui';
import {
  BaseLayout,
  Content,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../../../../components';

export type DispatchProps = {
  onCancel: () => void;
  onShowSolution: () => void;
  onSubmitEmptyInput: () => void;
};

type Props = DispatchProps;

export const EmptyUserInput: React.FC<Props> = ({
  onCancel,
  onShowSolution,
  onSubmitEmptyInput,
}) => {
  const [t] = useTranslation();
  return (
    <BaseLayout vertical textCentered>
      <DialogHeader
        title={t('seriesplayer:dialog.emptyInput.title')}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
        }}
      />
      <Bar />
      <Box stretch>
        <Content as={DialogContent} stretched>
          <p className="plaintext">{t('seriesplayer:dialog.emptyInput.body')}</p>
          <DialogFooter align="center">
            <Button
              key="return-to-series"
              dataCy="return-to-series"
              kind={ButtonKind.action}
              onClick={onCancel}
              stretch={ButtonStretch.responsive}
            >
              {t('seriesplayer:dialog.emptyInput.returnToInput')}
            </Button>
            <Button
              key="show-solution"
              dataCy="show-solution-btn"
              kind={ButtonKind.confirm}
              onClick={onShowSolution}
              stretch={ButtonStretch.responsive}
            >
              {t('seriesplayer:dialog.emptyInput.showSolution')}
            </Button>
            <Button
              key="submit-empty-input"
              dataCy="submit-empty-input"
              kind={ButtonKind.warning}
              onClick={onSubmitEmptyInput}
              stretch={ButtonStretch.responsive}
            >
              {t('seriesplayer:dialog.emptyInput.submitUnfinishedSeries')}
            </Button>
          </DialogFooter>
        </Content>
      </Box>
    </BaseLayout>
  );
};

EmptyUserInput.displayName = 'EmptyUserInput';
