import { type ArrowDirection } from '../components/Arrow/types';

import { SPECIAL } from '../../gizmo-utils/constants';
import { type Content, type ContentReference, type Defaults } from '../../xml-converter/core';
import { TIMESPAN } from '../../gizmo-utils/configuration/renderStyles';

export type Duration = {
  contentRefs: ContentReference[];
  direction: ArrowDirection;
};

export enum SpanType {
  day = 'day',
  time = 'time',
}

/**
 * `TimeSpanGizmo` needs some extra information on the gizmo content reference
 * to render it right (adapt some padding and margins)
 */
export interface TimeSpanContentReference extends ContentReference {
  withClock: boolean;
  isInteractive: boolean;
}

/**
 * TimeSpan gizmo content.
 *
 * 3 scenarios for the Time Span gizmo:
 *
 * 1) time/day + duration + time/day
 * 2) time/day + duration + time/day + duration + time/day
 * 3) time/day + duration + time/day + duration + time/day + duration (from start to end)
 *
 * NOTE: time may contain a clock above
 */
export interface TimeSpanGizmoContent extends Content {
  /**
   * All gizmo's references
   */
  contentRefs: TimeSpanContentReference[];

  /**
   * Need to pass this `spanType` props because of a
   * slight style differences has to be manually adjust wether it's a
   * day or a time span.
   *
   */
  spanType: SpanType;

  /**
   * In scenario 3) only. The global time duration arrow needs a custom rendering
   * that should be handled by the TimeSpan gizmo.
   * This is why we extract it from `contentRefs`
   */
  thirdDuration?: Duration;
}

export const TIME_SPAN_DEFAULT_CONTENT: Defaults<TimeSpanGizmoContent, 'contentRefs'> = {
  $: SPECIAL,
  $renderStyle: TIMESPAN,
  spanType: SpanType.time,
};
