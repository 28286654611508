import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogSize, DialogWrapper } from '../../../../components/index';
import { DialogType, ErrorHandleKind, type SelectedDialog } from '../../../../types';

import { ConfirmSubmitIncompleteContainer } from './ConfirmSubmitIncomplete';
import { DataLossPrevention } from './DataLossPrevention';
import { EmptyUserInputContainer } from './EmptyUserInput';
import { EndscreenContainer } from './Endscreen';
import { EnterIrrevocablyContainer } from './EnterIrrevocably';
import { ErrorContainer } from './Error';
import { ExerciseNavigationContainer } from './ExerciseNavigation';
import { ExerciseOverviewContainer } from './ExerciseOverview';
import { FEMContainer } from './FEM';
import { HandInContainer } from './HandIn';
import { HintsContainer } from './Hints';
import { QuitConfirmationContainer } from './QuitConfirmation';
import { ReportProblemContainer } from './ReportProblem';
import { RetryContainer } from './Retry';
import { TextbookContainer } from './TextbookContainer';
import { ResetWhiteboardConfirmation } from './ResetWhiteboardConfirmation/ResetWhiteboardConfirmation';

const enum DataLossPreventionTranslationKey {
  text = 'seriesplayer:dialog.dataLossPrevention.text',
}

const enum ConfirmSubmitIncompleteTranslationKey {
  main = 'seriesplayer:dialog.submitUnfinished.body',
  restart = 'seriesplayer:dialog.quit.restart',
  abortRestart = 'seriesplayer:dialog.quit.abortRestart',
}

const enum EnterIrrevocablyTranslationKey {
  title = 'seriesplayer:dialog.enterIrrevocably.title',
  main = 'seriesplayer:dialog.enterIrrevocably.body',
  confirm = 'seriesplayer:dialog.enterIrrevocably.confirm',
  cancel = 'seriesplayer:dialog.enterIrrevocably.doNotEnter',
}

const enum QuitConfirmationTranslationKey {
  title = 'seriesplayer:dialog.quit.title',
  main1 = 'seriesplayer:dialog.quit.body1',
  main2 = 'seriesplayer:dialog.quit.body2',
  main2NoReporting = 'seriesplayer:dialog.quit.body2NoReporting',
  confirm = 'seriesplayer:dialog.quit.confirm',
  abort = 'seriesplayer:dialog.quit.abort',
  restart = 'seriesplayer:dialog.quit.restart',
}

const enum QuitWhiteboardModeConfirmationTranslationKey {
  title = 'seriesplayer:dialog.whiteboardQuit.title',
  main1 = 'seriesplayer:dialog.whiteboardQuit.body1',
  main2 = 'seriesplayer:dialog.whiteboardQuit.body2',
  main2NoReporting = 'seriesplayer:dialog.whiteboardQuit.body2NoReporting',
  confirm = 'seriesplayer:dialog.whiteboardQuit.confirm',
  abort = 'seriesplayer:dialog.whiteboardQuit.abort',
  restart = 'seriesplayer:dialog.whiteboardQuit.restart',
}

const enum QuitConfirmationAfterDueDateTranslationKey {
  title = 'seriesplayer:dialog.quitAfterDuedate.header',
  main1 = 'seriesplayer:dialog.quitAfterDuedate.body1',
  main2 = 'seriesplayer:dialog.quitAfterDuedate.body2',
  exercise = 'seriesplayer:dialog.quitAfterDuedate.exercise',
  singleTry = 'seriesplayer:dialog.quitAfterDuedate.singleTry',
  confirm = 'seriesplayer:dialog.quitAfterDuedate.confirm',
  confirmIncomplete = 'seriesplayer:dialog.quitAfterDuedate.confirmIncomplete',
  cancel = 'seriesplayer:dialog.quitAfterDuedate.cancel',
}

const enum HandInTranslationKey {
  title = 'seriesplayer:dialog.handIn.header',
  main1 = 'seriesplayer:dialog.handIn.body1',
  main2 = 'seriesplayer:dialog.handIn.body2',
  exercise = 'seriesplayer:dialog.handIn.exercise',
  singleTry = 'seriesplayer:dialog.handIn.singleTry',
  confirm = 'seriesplayer:dialog.handIn.confirm',
  confirmIncomplete = 'seriesplayer:dialog.handIn.confirmIncomplete',
  cancel = 'seriesplayer:dialog.handIn.cancel',
}

const enum ReportProblemTranslationKey {
  title = 'seriesplayer:dialog.reportProblem.title',
}

const enum ResetWhiteBoardConfirmationTranslationKey {
  title = 'seriesplayer:dialog.whiteboardReset.title',
  main1 = 'seriesplayer:dialog.whiteboardReset.main1',
  main2 = 'seriesplayer:dialog.whiteboardReset.main2',
  confirm = 'seriesplayer:dialog.whiteboardReset.confirm',
  cancel = 'seriesplayer:dialog.whiteboardReset.cancel',
}

export type DialogProps = {
  /**
   * Event handler to close the dialog.
   */
  onCloseDialog?: () => void;
  /**
   * Event handler for confirming main action.
   * Handy if you don't want to use redux for managing the dialog state.
   */
  onConfirm?: () => void;

  /**
   * Which dialog to display as content
   */
  dialog?: SelectedDialog;
};

/**
 * Responsive Dialog Component used to display different dialogs.
 */
export function Dialog({ onCloseDialog, onConfirm, dialog }: DialogProps) {
  const [t] = useTranslation();
  let dialogContent: React.ReactElement | undefined;
  let closable = true;
  let lightbox = false;
  let noVerticalStretch: boolean | undefined = undefined;
  let dialogSize: DialogSize | undefined;

  switch (dialog?.type) {
    case DialogType.closeConfirmation:
      dialogContent = (
        <QuitConfirmationContainer
          title={t(QuitConfirmationTranslationKey.title)}
          mainText1={t(QuitConfirmationTranslationKey.main1)}
          mainText2={t(QuitConfirmationTranslationKey.main2)}
          mainText2NoReporting={t(QuitConfirmationTranslationKey.main2NoReporting)}
          confirmText={t(QuitConfirmationTranslationKey.confirm)}
          abortText={t(QuitConfirmationTranslationKey.abort)}
          restartText={t(QuitConfirmationTranslationKey.restart)}
        />
      );
      break;

    case DialogType.exitWhiteboardModeConfirmation:
      dialogContent = (
        <QuitConfirmationContainer
          title={t(QuitWhiteboardModeConfirmationTranslationKey.title)}
          mainText1={t(QuitWhiteboardModeConfirmationTranslationKey.main1)}
          mainText2={t(QuitWhiteboardModeConfirmationTranslationKey.main2)}
          mainText2NoReporting={t(QuitWhiteboardModeConfirmationTranslationKey.main2NoReporting)}
          confirmText={t(QuitWhiteboardModeConfirmationTranslationKey.confirm)}
          abortText={t(QuitWhiteboardModeConfirmationTranslationKey.abort)}
          restartText={t(QuitWhiteboardModeConfirmationTranslationKey.restart)}
          onCancelCustom={onCloseDialog}
          onConfirmCustom={onConfirm}
        />
      );
      break;
    case DialogType.closeConfirmationAfterDueDate:
      dialogContent = (
        <HandInContainer
          dataCy="close_confirmation_after_due_date_dialog"
          title={t(QuitConfirmationAfterDueDateTranslationKey.title)}
          mainText1={t(QuitConfirmationAfterDueDateTranslationKey.main1)}
          mainText2={t(QuitConfirmationAfterDueDateTranslationKey.main2)}
          exerciseText={t(QuitConfirmationAfterDueDateTranslationKey.exercise)}
          singleTryText={t(QuitConfirmationAfterDueDateTranslationKey.singleTry)}
          confirmText={t(QuitConfirmationAfterDueDateTranslationKey.confirm)}
          confirmIncompleteText={t(QuitConfirmationAfterDueDateTranslationKey.confirmIncomplete)}
          cancelText={t(QuitConfirmationAfterDueDateTranslationKey.cancel)}
        />
      );
      break;

    case DialogType.dataLossPrevention:
      dialogContent = <DataLossPrevention text={t(DataLossPreventionTranslationKey.text)} />;
      noVerticalStretch = true;
      closable = false;
      break;
    case DialogType.error:
      closable = dialog.payload.kind !== ErrorHandleKind.quit;
      dialogContent = <ErrorContainer payload={dialog.payload} />;
      break;
    case DialogType.exerciseNavigation:
      dialogContent = (
        <ExerciseNavigationContainer
          title={t('seriesplayer:dialog.exerciseNavigation.switchExercise')}
        />
      );
      break;
    case DialogType.exerciseOverview:
      dialogContent = <ExerciseOverviewContainer />;
      break;
    case DialogType.endscreen:
      closable = false;
      dialogContent = <EndscreenContainer payload={dialog.payload} />;
      break;
    case DialogType.emptyUserInput:
      dialogContent = <EmptyUserInputContainer />;
      break;
    case DialogType.enterInputConfirmation:
      dialogContent = (
        <EnterIrrevocablyContainer
          title={t(EnterIrrevocablyTranslationKey.title)}
          mainText={t(EnterIrrevocablyTranslationKey.main)}
          confirmText={t(EnterIrrevocablyTranslationKey.confirm)}
          cancelText={t(EnterIrrevocablyTranslationKey.cancel)}
        />
      );
      break;
    case DialogType.hints:
      dialogSize = DialogSize.large;
      dialogContent = <HintsContainer />;
      break;
    case DialogType.handInConfirmation:
      dialogContent = (
        <HandInContainer
          dataCy="hand_in_confirmation"
          title={t(HandInTranslationKey.title)}
          mainText1={t(HandInTranslationKey.main1)}
          mainText2={t(HandInTranslationKey.main2)}
          exerciseText={t(HandInTranslationKey.exercise)}
          singleTryText={t(HandInTranslationKey.singleTry)}
          confirmText={t(HandInTranslationKey.confirm)}
          confirmIncompleteText={t(HandInTranslationKey.confirmIncomplete)}
          cancelText={t(HandInTranslationKey.cancel)}
        />
      );
      break;
    case DialogType.reportProblem:
      dialogContent = <ReportProblemContainer title={t(ReportProblemTranslationKey.title)} />;
      break;
    case DialogType.textbook:
      lightbox = true;
      dialogContent = <TextbookContainer />;
      break;
    case DialogType.fem:
      dialogSize = DialogSize.large;
      dialogContent = (
        <FEMContainer
          id={dialog.payload.id}
          onCloseFromWhiteboard={dialog.payload.onCloseFromWhiteboard}
        />
      );
      break;
    case DialogType.submitIncompleteConfirmation:
      dialogContent = (
        <ConfirmSubmitIncompleteContainer
          goBackDialog={dialog.payload.backButtonDialog}
          title={t(ConfirmSubmitIncompleteTranslationKey.restart)}
          mainText={t(ConfirmSubmitIncompleteTranslationKey.main)}
          restartText={t(ConfirmSubmitIncompleteTranslationKey.restart)}
          abortRestartText={t(ConfirmSubmitIncompleteTranslationKey.abortRestart)}
        />
      );
      break;
    case DialogType.retry:
      dialogContent = <RetryContainer />;
      break;
    case DialogType.resetWhiteboardConfirmation:
      dialogContent = (
        <ResetWhiteboardConfirmation
          onCancel={onCloseDialog}
          onConfirm={onConfirm}
          title={t(ResetWhiteBoardConfirmationTranslationKey.title)}
          mainText1={t(ResetWhiteBoardConfirmationTranslationKey.main1)}
          mainText2={t(ResetWhiteBoardConfirmationTranslationKey.main2)}
          confirmText={t(ResetWhiteBoardConfirmationTranslationKey.confirm)}
          cancelText={t(ResetWhiteBoardConfirmationTranslationKey.cancel)}
        />
      );
      break;
    default:
      dialogContent = undefined;
  }

  return (
    <DialogWrapper
      {...(dialog && { dataCy: dialog.type })}
      dialogSize={dialogSize}
      lightbox={lightbox}
      noVerticalStretch={noVerticalStretch}
      onClickOverlay={closable ? onCloseDialog : undefined}
    >
      {dialogContent}
    </DialogWrapper>
  );
}
