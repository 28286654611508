import { VIRTUAL_CONTENT_HEIGHT, VIRTUAL_CONTENT_WIDTH } from './constants';
import type {
  ActionStack,
  ContentToolConfig,
  Dimensions,
  PenColor,
  ScaleSetting,
  Tool,
  WhiteboardState,
} from './types';
import { calculateAutoScale, getScale } from './utils';
import { DialogType, type SelectedDialog } from '../../types';
import { type ExampleNavigationItem } from '../example-mode/ExampleNavigation';

type InitiateResetWhiteboard = { type: 'initiateReset' };
type OpenDialog = { type: 'openDialog'; dialogType: SelectedDialog };
type CloseDialog = { type: 'closeDialog' };
type SelectTool = { type: 'tool'; payload: Tool };
type SetContentTools = { type: 'content-tools'; payload: ContentToolConfig };
type SelectNavigationItem = {
  type: 'navigation';
  payload: {
    selectedNavigationItem: ExampleNavigationItem;
    canvasActionStack: ActionStack;
    canvasHeight: number;
  };
};
type SetWhiteboardState = { type: 'set'; payload: Partial<WhiteboardState> };
type UndoWhiteboard = { type: 'undo' };
type RedoWhiteboard = { type: 'redo' };
type MagnifierWhiteboard = { type: 'magnifier' };
type SetDimensions = { type: 'setDimensions'; dimensions: Dimensions };
type SetScale = { type: 'setScale'; scaleSetting: ScaleSetting };
type SetPenColor = { type: 'setPenColor'; color: PenColor };
type SetThickness = {
  type: 'setThickness';
  tool: Extract<Tool, 'pen' | 'eraser'>;
  thickness: number;
};
type FullScreen = { type: 'toggleFullscreen' };
type Noop = { type: 'noop' };

export type WhiteboardAction =
  | SelectTool
  | SelectNavigationItem
  | SetWhiteboardState
  | InitiateResetWhiteboard
  | UndoWhiteboard
  | RedoWhiteboard
  | MagnifierWhiteboard
  | SetPenColor
  | FullScreen
  | SetDimensions
  | SetScale
  | OpenDialog
  | CloseDialog
  | Noop
  | SetThickness
  | SetContentTools;

export function whiteboardReducer(
  state: WhiteboardState,
  action: WhiteboardAction
): WhiteboardState {
  switch (action.type) {
    case 'initiateReset':
      return {
        ...state,
        activeDialog: { type: DialogType.resetWhiteboardConfirmation },
      };
    case 'tool':
      // if the clicked tool is the currently selected tool, nothing should happen
      if (action.payload === state.selectedTool) {
        return state;
      }
      return { ...state, selectedTool: action.payload };
    case 'content-tools':
      return {
        ...state,
        contentToolConfig: {
          ...state.contentToolConfig,
          ...action.payload,
        },
      };
    case 'navigation':
      return {
        ...state,
        canvasActionStack: action.payload.canvasActionStack,
        selectedNavigationItem: action.payload.selectedNavigationItem,
        canvasHeight: action.payload.canvasHeight,
      };
    case 'set':
      return { ...state, ...action.payload };
    case 'openDialog':
      return { ...state, activeDialog: action.dialogType };
    case 'closeDialog':
      return { ...state, activeDialog: null };
    case 'undo':
      return state;
    case 'redo':
      return state;
    case 'magnifier':
      return { ...state, zoomPopupActive: !state.zoomPopupActive };
    case 'setDimensions':
      return { ...state, scaleConfig: { ...state.scaleConfig, dimensions: action.dimensions } };
    case 'setScale':
      /* eslint-disable no-case-declarations */
      const twoSidebars =
        state.selectedTool === 'pen' ||
        state.selectedTool === 'eraser' ||
        state.selectedTool === 'content-tools';
      const dimensions = state.scaleConfig.dimensions;
      const autoScaleFn =
        action.scaleSetting === 'auto'
          ? () =>
              calculateAutoScale(
                twoSidebars,
                VIRTUAL_CONTENT_WIDTH,
                VIRTUAL_CONTENT_HEIGHT,
                dimensions.width,
                dimensions.height
              )
          : () => 1;

      const newScale = getScale(state.scaleConfig.currentScale, action.scaleSetting, autoScaleFn);
      /* eslint-enable no-case-declarations */
      return {
        ...state,
        scaleConfig: {
          ...state.scaleConfig,
          scaleType: action.scaleSetting === 'auto' ? 'auto' : 'user',
          currentScale: newScale,
        },
      };
    case 'toggleFullscreen':
      // the change of the full screen happens inside the whiteboard component
      state.isFullScreen ? document.exitFullscreen() : document.body.requestFullscreen();
      return state;
    case 'setPenColor':
      return { ...state, toolConfig: { ...state.toolConfig, color: action.color } };
    case 'setThickness':
      return action.tool === 'pen'
        ? { ...state, toolConfig: { ...state.toolConfig, penThickness: action.thickness } }
        : { ...state, toolConfig: { ...state.toolConfig, eraserThickness: action.thickness } };
    case 'noop':
      return state;
  }
}
