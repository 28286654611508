import * as React from 'react';
import { type WheelOfFortuneContent } from '@bettermarks/gizmo-types';
import { SectorComponent } from './SectorComponent';
import styles from './styles.scss';
import { type ContextState } from '../../../gizmo-utils/polymorphic-gizmo';

export type WheelOfFortuneProps = WheelOfFortuneContent & ContextState;

const TRIANGLE_HEIGHT = 11;
const TRIANGLE_WIDTH = 14;
const SMALL_RADIUS = 6;
const YOFFSET = TRIANGLE_HEIGHT / 2;
export const RADIUS = 60;
export const CX = RADIUS + 0.5;
export const CY = RADIUS + YOFFSET;

export const WheelOfFortune: React.FC<WheelOfFortuneProps> = ({ sectors, $id }) => {
  const allSectors = sectors.map(({ label, color }, index, { length }) => (
    <SectorComponent key={index} total={length} color={color} label={label} index={index} />
  ));
  /* The three coordinates  of the triangle are calculated */
  const x1 = CX;
  const x2 = CX - TRIANGLE_WIDTH / 2;
  const x3 = CX + TRIANGLE_WIDTH / 2;
  const y1 = CY - RADIUS + TRIANGLE_HEIGHT / 2;
  const y2 = CY - RADIUS - TRIANGLE_HEIGHT / 2;
  const points = `${x1},${y1} ${x2},${y2} ${x3},${y2}`;

  return (
    <svg
      id={$id}
      width={2 * RADIUS}
      height={RADIUS * 2 + YOFFSET}
      viewBox={`0 0 ${2 * RADIUS} ${RADIUS * 2 + YOFFSET}`}
    >
      {allSectors}
      <circle cx={CX} cy={CY} r={SMALL_RADIUS} className={styles.centerCircle} />
      <polygon points={points} className={styles.triangle} />
    </svg>
  );
};

WheelOfFortune.displayName = 'WheelOfFortune';
