import * as React from 'react';
import classNames from 'classnames';
import { SmallLayout } from './SmallLayout';
import { MediumLayout } from './MediumLayout';
import { LargeLayout } from './LargeLayout';
import { ExtraLargeLayout } from './ExtraLargeLayout';
import { isSmall, isMedium, isLarge, padOperators, sortOperators } from './helpers';
import styles from './Keyboard.scss';
import { noop } from 'lodash/fp';

const LANDSCAPE_SMALL_HEIGHT = 420;

export type KeyboardDispatchProps = {
  onKey: (refid: string) => (key: string) => void;
  onTogglePage: (refid: string) => (index: number) => void;
};

export type KeyboardStateProps = {
  availableWidth: number;
  gizmoId?: string;
  operators: string[];
  hasDecimalPoint?: boolean;
  hasNumbers?: boolean;
  hasLetters?: boolean;
  selectedPage?: number;
};

export type KeyboardProps = KeyboardStateProps & KeyboardDispatchProps;

/**
 * Keyboard: Top level component for the virtual keyboard.
 *
 * ## Component Structure
 *
 * The Keyboard consists of a couple of layouts in three sizes (s, m & l) Each Layout takes a list
 * of toggle buttons, which must correspond to the number of pages you pass as children.
 *
 * A KeyPage defines a grid of keys. This means, that in the end the content of the KeyPage must
 * be Keys, so e.g. NumBlock will return a fragment with a number of keys inside!  This is
 * important so we are flexible about how to layout the keys and which layout mechanism to use
 * (grid, flex, table etc). So even if multiple blocks are combined inside a KeyPage, the KeyPage
 * ist just a list of keys.
 *
 * To control where the keys are placed, you have to change the order of the keys! The keys are
 * placed column wise. "Empty" keys are added as necessary, so we can e.g. place two brackets next
 * to each other in a row, if no other operators are present or it's not otherwise (by order)
 * possible.
 *
 * You can take a look in e.g. NumBlock how to control the order of the keys. For operators, you
 * have to change the order of the **operators** prop.
 *
 * In the end each key will get a KeyCode (a string) which it will send via the onKey callback.
 * This is the only callback which is passed outside. In the container we map the KeyCode to an
 * action (e.g. the key code KeyCode.Fraction will be mapped to createFrac()).
 *
 * ## Dynamic Breakpoints
 *
 * Another important aspect is the breakpoint mechanism (i.e. where do we toggle between the
 * layouts s, m and l). The break points are dynamic! The design requirement is, that space is
 * always used as much as possible. The only dynamic key block is the OperatorBlock. This means,
 * that the number of operators is directly related to the position of the breakpoints.
 *
 * The results of the functions isSmall & isMedium are calculated from the availableWidth and the
 * number of operators present. This might include "empty" operators, if e.g. only brackets are
 * present. Techincally those functions are just lookups in a pre calculated table of breakpoint
 * values (which depend on certain constants).
 */
export const Keyboard: React.FC<KeyboardProps> = ({
  availableWidth,
  gizmoId,
  hasDecimalPoint,
  hasNumbers,
  hasLetters,
  operators,
  onKey,
  onTogglePage,
  selectedPage = 0,
}) => {
  const layoutProps = {
    onKey: gizmoId !== undefined ? onKey(gizmoId) : noop,
    onTogglePage: gizmoId !== undefined ? onTogglePage(gizmoId) : noop,
    operators,
    hasDecimalPoint,
    hasNumbers,
    hasLetters,
    selectedPage,
  };

  /* We need to know how many (possibly EMPTY) operator buttons there will be in the final layouts to compute
   the correct width of the layout in the helper functions. This becomes especially relevant when there are brackets (supposed to be next to
   each other) and no further operators, which leads to more EMPTY buttons.*/
  const paddedOperators2Rows = padOperators(sortOperators(operators, true), 2);
  const paddedOperators3Rows = padOperators(sortOperators(operators), 3);

  return (
    <div
      id="keyboard"
      data-cy="keyboard"
      className={classNames(styles.keyboardWrapper, {
        [styles.landscape]: window.innerHeight <= LANDSCAPE_SMALL_HEIGHT,
      })}
    >
      {isSmall(availableWidth, paddedOperators3Rows.length, hasNumbers, hasLetters) ? (
        <SmallLayout {...layoutProps} />
      ) : isMedium(
          availableWidth,
          paddedOperators3Rows.length,
          hasDecimalPoint,
          hasNumbers,
          hasLetters
        ) ? (
        <MediumLayout {...layoutProps} />
      ) : isLarge(
          availableWidth,
          paddedOperators2Rows.length,
          hasDecimalPoint,
          hasNumbers,
          hasLetters
        ) ? (
        <LargeLayout {...layoutProps} />
      ) : (
        <ExtraLargeLayout {...layoutProps} />
      )}
    </div>
  );
};
