import * as React from 'react';
import { first, isNil, last, orderBy } from 'lodash';
import {
  type CircleObject,
  type Coords,
  type GeoConfiguration,
  type GeoConfigurationDisplay,
  GeoEditorMode,
  GeoLabelType as LabelType,
  type GeoObject,
  type GeoObjectMap,
  type GeoVerticalAlignment,
  INTERVAL,
  type IntervalObject,
  LabelAlignDirection,
  type LabelObject,
  type LineObject,
  POINT,
  type PointObject,
  RAY,
  SEGMENT,
  STRAIGHTLINE,
  type ToolValueLabel as ToolValueLabelType,
  VECTOR,
} from '@bettermarks/gizmo-types';
import { Label, ToolValueLabel } from '../../components';
import { type SetProps } from './types';
import {
  abs,
  add,
  DEFAULT_LABEL_DISTANCE,
  midpoint,
  normal,
  smult,
  sub,
  visibleCenter,
  worldToScreen,
} from '@bettermarks/importers';
import { getCircleRefPointCoords } from './CircleSet';
import { isLine } from '../../tools/helpers';

export type LabelSetProps = SetProps & {
  width: number;
  height: number;
  labels: ReadonlyArray<string>;
  configuration: GeoConfiguration;
  toolValueLabels?: ToolValueLabelType[];
  onClick?: (id: string) => (evt: any) => void;
  onHover?: () => void;
  onLeave?: () => void;
};

/* eslint-disable-next-line complexity*/
export const getDefaultLabelAlign = (
  refObj: GeoObject,
  anchorCoords: Coords,
  configuration: GeoConfiguration,
  labelType?: LabelType,
  verticalAlign?: GeoVerticalAlignment
): LabelAlignDirection => {
  const { display, tickValueInterval } = configuration;
  const { cy, xMin, xMax, yMin, yMax } = display;

  if (refObj.type !== 'circles') {
    let yAlign =
      anchorCoords && anchorCoords.y / tickValueInterval.y >= cy
        ? LabelAlignDirection.top
        : LabelAlignDirection.bottom;

    if (!isNil(verticalAlign)) {
      yAlign =
        anchorCoords.y / tickValueInterval.y < yMin + 2 ? LabelAlignDirection.top : verticalAlign;
      yAlign =
        anchorCoords.y / tickValueInterval.y > yMax - 2 ? LabelAlignDirection.bottom : yAlign;
    }

    if (labelType && labelType === (LabelType.initial || LabelType.delete)) {
      return yAlign;
    }

    if (anchorCoords.x / tickValueInterval.x > xMax - 1) {
      return `left-${yAlign}` as LabelAlignDirection;
    }

    if (anchorCoords.x / tickValueInterval.x < xMin + 1) {
      return `right-${yAlign}` as LabelAlignDirection;
    }

    return yAlign;
  } else {
    if (anchorCoords.y / tickValueInterval.y < yMin + 2) {
      return LabelAlignDirection.top;
    }

    if (anchorCoords.y / tickValueInterval.y > yMax - 2) {
      return LabelAlignDirection.bottom;
    }
    const circle = refObj as CircleObject;

    return circle.coords && circle.coords.y >= cy / tickValueInterval.y
      ? LabelAlignDirection.bottom
      : LabelAlignDirection.top;
  }
};

export const getRefPointCoords = (
  geoObj: GeoObject,
  contentMap: GeoObjectMap<GeoObject>,
  configurationDisplay: GeoConfigurationDisplay,
  align?: LabelAlignDirection
): Coords => {
  const { cx, cy } = configurationDisplay;

  switch (geoObj.type) {
    case POINT:
      return (geoObj as PointObject).coords;
    case 'circles':
      const { radius, coords } = geoObj as CircleObject;
      return getCircleRefPointCoords(radius, coords, cx, cy, align);
    case RAY:
    case SEGMENT:
    case VECTOR:
      return midpoint(
        (contentMap[(geoObj as LineObject).p1Id] as PointObject).coords,
        (contentMap[(geoObj as LineObject).p2Id] as PointObject).coords
      );
    case STRAIGHTLINE:
      // in case visibleCenter returns undefined we take the midpoint
      return (
        visibleCenter(
          (contentMap[(geoObj as LineObject).p1Id] as PointObject).coords,
          (contentMap[(geoObj as LineObject).p2Id] as PointObject).coords,
          configurationDisplay,
          STRAIGHTLINE
        ) ||
        midpoint(
          (contentMap[(geoObj as LineObject).p1Id] as PointObject).coords,
          (contentMap[(geoObj as LineObject).p2Id] as PointObject).coords
        )
      );
    case INTERVAL:
      const i = geoObj as IntervalObject;
      return { x: (i.max + i.min) / 2, y: 0 };
    default:
      return { x: 0, y: 0 };
  }
};

export const getDirectionVector = (refPoint1: Coords, refPoint2: Coords): Coords => {
  const directionVec: Coords = sub(refPoint1, refPoint2);

  return smult(1 / abs(directionVec), directionVec);
};

export const getShiftPositionCoords = (
  refPoint1: Coords,
  refPoint2: Coords,
  shift: number
): Coords => smult(shift, getDirectionVector(refPoint1, refPoint2));

export const getShiftedFinalCoords = (
  refPoint1: Coords,
  refPoint2: Coords,
  refPointCoords: Coords,
  shift: number
): Coords => add(refPointCoords, getShiftPositionCoords(refPoint1, refPoint2, shift));

export const getNormalVector = (refPoint1: Coords, refPoint2: Coords): Coords =>
  normal(getDirectionVector(refPoint1, refPoint2));

export const moveCoordsInsideBounds = (
  labelX: number,
  labelY: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  xMin: number,
  xMax: number,
  yMin: number,
  yMax: number
): Coords => {
  // Note that simply setting x to xMin/xMax and y to yMin/yMax is a bad idea since
  // if the label is outside both directions it will start moving along one direction
  // at some point and leave the line.
  let txInside = 0;
  if (x1 !== x2) {
    if (labelX < xMin) {
      txInside = (xMin - x1) / (x2 - x1);
    } else if (labelX > xMax) {
      txInside = (xMax - x1) / (x2 - x1);
    }
  }

  if (txInside !== 0) {
    labelX = x1 + txInside * (x2 - x1);
    labelY = y1 + txInside * (y2 - y1);
  }

  // move the y inside bounds
  let tyInside = 0;
  if (y1 !== y2) {
    if (labelY < yMin) {
      tyInside = (yMin - y1) / (y2 - y1);
    } else if (labelY > yMax) {
      tyInside = (yMax - y1) / (y2 - y1);
    }
  }

  if (tyInside !== 0) {
    labelX = x1 + tyInside * (x2 - x1);
    labelY = y1 + tyInside * (y2 - y1);
  }
  return { x: labelX, y: labelY };
};

export const getCoordsFromT = (
  refObject: GeoObject,
  geoContentMap: GeoObjectMap<GeoObject>,
  configurationDisplay: GeoConfigurationDisplay,
  t: number
): Coords => {
  // Handle edge case where we move start point and end point to line and
  // refObject.referringTo momentarily becomes undefined
  if (refObject === undefined) {
    return { x: -1000, y: -1000 };
  }

  // If the label has a t value, we need to position it relatively to the
  // start and end points of the line it belongs to
  const [point1Id, point2Id] = refObject.referringTo;
  const point1 = geoContentMap[point1Id] as PointObject;
  const point2 = geoContentMap[point2Id] as PointObject;

  const [x1, y1, x2, y2] = [point1.coords.x, point1.coords.y, point2.coords.x, point2.coords.y];
  const labelX = x1 + t * (x2 - x1);
  const labelY = y1 + t * (y2 - y1);

  // Deal with the possibility that coords may be outside the screen
  const { xMin, xMax, yMin, yMax } = configurationDisplay;
  return moveCoordsInsideBounds(labelX, labelY, x1, x2, y1, y2, xMin, xMax, yMin, yMax);
};

export const LabelSet: React.FC<LabelSetProps> = ({
  labels,
  toolValueLabels,
  width,
  height,
  geoContentMap,
  matrix,
  mode,
  onClick,
  onHover,
  onLeave,
  configuration,
}) => {
  const transform = worldToScreen(matrix);
  return (
    <g>
      {labels.map(
        /* eslint-disable-next-line complexity*/
        (id) => {
          const {
            addedByUser,
            align,
            content,
            distance,
            hover,
            labelType,
            position,
            refid,
            shift,
            verticalAlign,
          } = geoContentMap[id] as LabelObject;
          let coords: Coords | undefined;
          let refObject: GeoObject | undefined;

          // get the default refPoint position
          if (refid) {
            refObject = geoContentMap[refid];

            const labelGeoContent = geoContentMap[id] as LabelObject;
            if (labelGeoContent.t !== undefined) {
              coords = getCoordsFromT(
                refObject,
                geoContentMap,
                configuration.display,
                labelGeoContent.t
              );
            } else {
              coords = getRefPointCoords(refObject, geoContentMap, configuration.display, align);
            }
          }

          // if existing overwrite it by an explicitly defined position
          if (position) {
            coords = position;
          }

          let customDirection: Coords | undefined;
          if (refid && shift && refid in geoContentMap && isLine(geoContentMap[refid])) {
            const line = geoContentMap[refid] as LineObject;
            const p1Coords = (geoContentMap[line.p1Id] as PointObject).coords;
            const p2Coords = (geoContentMap[line.p2Id] as PointObject).coords;

            coords = getShiftedFinalCoords(p1Coords, p2Coords, coords as Coords, shift);

            // to flip the normal vector to the outside (as it is done in flex)
            const sortedCoords: Coords[] = orderBy([p1Coords, p2Coords], ['x'], ['asc']);

            customDirection = getNormalVector(
              transform(first(sortedCoords) as Coords),
              transform(last(sortedCoords) as Coords)
            );
          }

          if (!isNil(coords)) {
            let labelAlignDirection: LabelAlignDirection;
            let labelDistance: number;
            let refPointScreenCoords: Coords;
            if (!isNil(refObject)) {
              labelAlignDirection =
                align ||
                getDefaultLabelAlign(refObject, coords, configuration, labelType, verticalAlign);
              labelDistance = distance || DEFAULT_LABEL_DISTANCE;
              refPointScreenCoords = transform(coords);
            } else {
              // this only happens for angle labels (they do not refer to their angles)
              labelAlignDirection = LabelAlignDirection.center;
              labelDistance = distance || 0;
              refPointScreenCoords = transform(coords);
            }

            return (
              <Label
                key={id}
                {...{
                  matrix,
                  width,
                  height,
                  id,
                  customDirection,
                  hover,
                  onHover,
                  onLeave,
                }}
                align={labelAlignDirection}
                distance={labelDistance}
                x={refPointScreenCoords.x}
                y={refPointScreenCoords.y}
                content={content}
                onClick={
                  /** labels are only clickable
                   * - in ADD_LABEL mode when added by the user
                   * OR
                   * - when the label is the touch specific "delete button"
                   * OR
                   * - when the label is an initial label
                   *  --> otherwise labels can harm/overlap other objects you want to click with
                   *  their click area
                   *  */
                  (mode === GeoEditorMode.ADD_LABEL && addedByUser) ||
                  labelType === LabelType.delete ||
                  labelType === LabelType.initial
                    ? onClick
                    : undefined
                }
                labelType={labelType}
              />
            );
          }

          return null;
        }
      )}
      {toolValueLabels &&
        toolValueLabels.map((label, idx) => (
          <ToolValueLabel
            key={idx}
            {...{ matrix, width, height }}
            id={`toolValueLabel_${idx}`}
            content={label.content}
            coords={worldToScreen(matrix)(label.coords)}
            alternativeStyle={label.alternativeStyle}
          />
        ))}
    </g>
  );
};

LabelSet.displayName = 'LabelSet';
