import { type Annotations } from '@bettermarks/gizmo-types';
import { hasInteractionType } from '@bettermarks/gizmo-types';

/**
 * Combines `$renderStyle` and `$interactionType` to a key for looking up gizmos or reducers.
 * If `$interactionType` is not set it only returns the `$renderStyle`.
 *
 * @see hasInteractionType
 */
export const combinedKey = (content: Pick<Annotations, '$interactionType' | '$renderStyle'>) => {
  return content.$renderStyle + (hasInteractionType(content) ? ` ${content.$interactionType}` : '');
};
