import * as React from 'react';
import Measure, { type ContentRect } from 'react-measure';
import { type GraphRendererProps, GraphRenderer } from '../GraphRenderer';
import {
  type GraphNode,
  type BubbleNode,
  isBubbleNode,
  NodeOptionType,
  type BubbleNodeContent,
  type TextContent,
} from '@bettermarks/gizmo-types';
import { AdditionalButton, PickerWidget } from '@seriesplayer/common-ui';
import { BORDER_WIDTH, NODE_RADIUS, MIN_NODE_DIAMETER } from '@bettermarks/importers';
import { withHoverHighlight } from './withHoverHighlight';
import {
  onPickEvent,
  useDOMEvent,
  getScreenPos,
  getScreenPosByRef,
  scrollToXPosition,
} from '../helpers';
import { positionDialog, Rect, type DialogPosition, Direction } from '../dialogPositioning';
import { type DragScrollableProps } from '../../../../gizmo-utils/drag-scroll-behaviour';
import { Maybe } from '../../../../utils/maybe';
import { getScrollPosition } from '../../../../gizmo-utils/drag-scroll-behaviour/helpers';
import { icons } from '../../../../components/PickerWidget/constants';

export type LabelNodeProps = GraphRendererProps &
  DragScrollableProps & {
    onLabelNode?: (id: string, index: number) => void;
  };
const isTextContent = (content?: BubbleNodeContent): content is TextContent =>
  !!content && content.type === NodeOptionType.selectBox;

/**
 * LabelNode: Graph editor mode for labeling nodes. If you click on a node, it will be scrolled into
 * view and a label widget will be shown.
 */
const LabelNode_: React.FC<LabelNodeProps> = (props) => {
  const { edges, nodes, onLabelNode, scrollBehaviour } = props;

  const divEl = React.useRef<HTMLDivElement>(null);
  /**
   * The node with the label widget.
   */
  const [labeledNode, setLabeledNode] = React.useState<BubbleNode | null>(null);
  /**
   * Here we store the measured size of the label widget
   */
  const [pickerSize, setPickerSize] = React.useState<ContentRect>({
    client: { width: 0, height: 0, left: 0, top: 0 },
  });
  const maybeScrollBehaviour = Maybe(scrollBehaviour);

  /**
   * If the user confirms the dialog our container will dispatch an action here.
   */
  const setLabel = (node: BubbleNode) => (index: number) => {
    if (
      isBubbleNode(node) &&
      node.content &&
      node.content.type === NodeOptionType.selectBox &&
      node.content.selected !== node.content.options[index] &&
      node.id
    ) {
      onLabelNode && onLabelNode(node.id, index);
    }
    setLabeledNode(null);
  };

  /*--------------------------------------------------------------------------------*
   *                              Event Handlers                                    *
   *--------------------------------------------------------------------------------*/

  const onClickElsewhere = (evt: TouchEvent | React.MouseEvent<any>) => {
    let el: HTMLElement | null = evt.target as HTMLElement;
    while (el) {
      if (el.id === 'graph-label-picker') return;
      el = el.parentElement;
    }
    setLabeledNode(null);
  };

  const onNodeStart = (node: GraphNode) => {
    if (isBubbleNode(node) && node.addedByUser) {
      setLabeledNode(node);
    }
    if (divEl.current) {
      scrollToXPosition(
        getScreenPos(node.position.x, node.position.y, maybeScrollBehaviour.ap(getScrollPosition)),
        divEl.current.getBoundingClientRect(),
        maybeScrollBehaviour
      );
    }
  };

  useDOMEvent(
    'touchstart',
    onPickEvent<TouchEvent>(divEl, edges, nodes, onNodeStart, undefined, onClickElsewhere, false),
    divEl,
    [labeledNode, nodes, edges]
  );

  /*--------------------------------------------------------------------------------*
   *                      Prepare and position label dialog                         *
   *--------------------------------------------------------------------------------*/

  const labelIndex =
    labeledNode && isTextContent(labeledNode.content) && labeledNode.content.selected
      ? labeledNode.content.options.indexOf(labeledNode.content.selected)
      : 0;

  const showPicker = labeledNode && isTextContent(labeledNode.content);
  const pickerRect = pickerSize.client || { width: 0, height: 0 };

  let dialogInfo: DialogPosition | undefined;

  if (labeledNode && divEl.current) {
    const labelPos = getScreenPosByRef(labeledNode.position.x, labeledNode.position.y, divEl);
    const labelDist = labeledNode.severity ? MIN_NODE_DIAMETER : 2 * NODE_RADIUS + BORDER_WIDTH - 1;
    const boundary = divEl.current.getBoundingClientRect();
    dialogInfo = positionDialog(
      Rect.atCenter(labelPos.x, labelPos.y, labelDist, labelDist),
      Rect.create(0, 0, boundary.width, boundary.height),
      pickerRect.width,
      pickerRect.height
    );
  }

  const preprocessedNodes = nodes.map((n) => ({
    ...n,
    highlight: !n.addedByUser ? false : n.highlight,
  }));

  // XXX: This introduced a slightly different structure than the other modes. This enables us to
  // render the label stepper on top. I'm not sure anymore if this is necessary and if we can
  // prevent his. It could have an impact on performance because of the different DOM structure.
  return (
    <div style={{ position: 'relative' as const }}>
      <div
        ref={divEl}
        role="button"
        onClick={onPickEvent(divEl, edges, nodes, onNodeStart, undefined, onClickElsewhere)}
      >
        <GraphRenderer {...props} nodes={preprocessedNodes} />
      </div>
      {showPicker && (
        <Measure client onResize={setPickerSize}>
          {({ measureRef }) =>
            labeledNode && (
              <span
                ref={measureRef}
                style={{
                  position: 'absolute' as const,
                  ...(dialogInfo
                    ? {
                        // Add a magic pixel to center it properly. Unknown why this is necessary.
                        // Seems to be a combination of multiple things.
                        left: Math.round(dialogInfo.position.x) + 1,
                        top: Math.round(dialogInfo.position.y) + 1,
                      }
                    : {}),
                  ...(pickerRect.width === 0 ? { visibility: 'hidden' as const } : {}),
                }}
              >
                <PickerWidget
                  id="graph-label-picker"
                  onConfirm={setLabel(labeledNode)}
                  initialIndex={labelIndex}
                  list={labeledNode.content ? labeledNode.content.options : []}
                  nose={{
                    up: !!dialogInfo && dialogInfo.nose === Direction.up,
                    xOffset: dialogInfo ? -(0.5 - dialogInfo.noseX) * pickerRect.width : 0,
                  }}
                  additionalButton={{
                    mode: AdditionalButton.close,
                    onClose: () => setLabeledNode(null),
                  }}
                  icons={icons}
                />
              </span>
            )
          }
        </Measure>
      )}
    </div>
  );
};

LabelNode_.displayName = 'LabelNode';

export const LabelNode = withHoverHighlight(LabelNode_);
