import { ensureFontsLoaded as ensureFontsLoadedUnstubable } from '../../utils/fontMetric';
import { importers } from '@bettermarks/importers';
import { enrichContentDict as enrichContentDictUnstubable } from '../../gizmo-utils/measure';
import { type BMAction } from '../../gizmo-utils/types';
import { compose } from 'lodash/fp';
import { call, put } from 'redux-saga/effects';
import { type ContentDict, toXmlElement as toXmlElementUnstubable } from '@bettermarks/gizmo-types';
import {
  fetchCRI as fetchCRIUnstubable,
  type FetchCRIParams,
} from '../seriesplayer/services/api/content-manager';
import { isFetchException } from '../seriesplayer/services/exception';
import { exceptionLog, ExceptionType } from '../seriesplayer/services/logging';
import { criLoaded, criLoadingStarted, handleError, initCri } from './actions';
import { rulers, stylers } from './configuration';
import { withEmptyHistory } from '../store/helper';
import { importCRI as importCRIUnstubable } from '../../xml-converter/CRI';

export const STUBABLE = {
  fetchCRI: fetchCRIUnstubable,
  importCRI: importCRIUnstubable,
  toXmlElement: toXmlElementUnstubable,
  enrichContentDict: enrichContentDictUnstubable,
  ensureFontsLoaded: ensureFontsLoadedUnstubable,
};

export function* loadCRISaga({ payload }: BMAction<FetchCRIParams>): Generator {
  try {
    yield put(criLoadingStarted());
    const xml = yield call(STUBABLE.fetchCRI, payload);
    const contentDict = compose(
      (contentDict: ContentDict) =>
        withEmptyHistory(STUBABLE.enrichContentDict(contentDict, stylers, rulers)),
      (xml) => STUBABLE.importCRI(xml, importers),
      STUBABLE.toXmlElement
    )(xml as string);

    yield put(criLoaded(contentDict));
    yield put(initCri());
  } catch (error) {
    isFetchException(error)
      ? exceptionLog(
          ExceptionType.api,
          error,
          {
            response: error.response,
            url: error.url,
            requestInit: error.requestInit,
            type: error.type,
          },
          'warn'
        )
      : exceptionLog(ExceptionType.saga, error);
    yield put(handleError());
  }
}
