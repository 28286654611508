import { cond, constant, curry, dropWhile, gt, stubTrue, takeWhile } from 'lodash';
import { functionParams, secantParamValues, switchStatement, tangentParamValue } from './helpers';
import { f as fval, roots } from './math/evaluate';
import {
  FunctionType,
  type GeoConfigurationDisplay,
  LabelAlignDirection,
  type ParametricFunction,
} from '@bettermarks/gizmo-types';

const lt = curry(gt); // we have to flip the args to make partial application possible

const BORDER = 1; // border width (in units) that allows a label to be put in

export const GHOSTLABEL_KEY = 'ghostLabel';
export const GRAPHLABEL_KEY = 'graphLabel';
export const SECANTLABEL1_KEY = 'secantLabel1';
export const SECANTLABEL2_KEY = 'secantLabel2';
export const TANGENTLABEL_KEY = 'tangentLabel';
export const VERTEXLABEL_KEY = 'vertexLabel';

export type Position = { x: number; y: number };

/**
 * Position the 'ghost' label (i.e. the label linking the graph to the formula).
 * It is always placed near the border where the graph leaves it for good.
 * @param f parametric function to label
 * @param r geo rectangle configuration
 * @return {Position} label position
 */
const ghostLabelPosition_ = (
  f: ParametricFunction,
  r: GeoConfigurationDisplay,
  params = functionParams(f),
  top = Math.min(...dropWhile(roots(f.type)(params)(r.yMax), lt(r.xMin))),
  bottom = Math.min(...dropWhile(roots(f.type)(params)(r.yMin), lt(r.xMin))),
  left = fval(f.type)(params)(0)(r.xMin)
): Position =>
  left >= r.yMin && left <= r.yMax
    ? { x: r.xMin, y: left } // crossing the right border?
    : top <= bottom // last crossing on top
    ? { x: top, y: r.yMax }
    : { x: bottom, y: r.yMin }; // last crossing on bottom

/**
 * Position the graph label (i.e. the label linking the graph to the formula).
 * It is always placed near the border where the graph leaves it for good.
 * @param f parametric function to label
 * @param r geo rectangle configuration
 * @return {Position} label position
 */
const graphLabelPosition_ = (
  f: ParametricFunction,
  r: GeoConfigurationDisplay,
  params = functionParams(f),
  top = Math.max(...takeWhile(roots(f.type)(params)(r.yMax), lt(r.xMax))),
  bottom = Math.max(...takeWhile(roots(f.type)(params)(r.yMin), lt(r.xMax))),
  right = fval(f.type)(params)(0)(r.xMax)
): Position =>
  right <= r.yMax && right >= r.yMin
    ? { x: r.xMax, y: right } // crossing the right border?
    : top >= bottom // last crossing on top
    ? { x: top, y: r.yMax }
    : { x: bottom, y: r.yMin }; // last crossing on bottom

const vlineLabelPosition = (
  f: ParametricFunction,
  r: GeoConfigurationDisplay,
  params = functionParams(f)
): Position => ({ x: params.e, y: r.yMax });

const ghostLabelPosition = (f: ParametricFunction, r: GeoConfigurationDisplay) =>
  f.type === FunctionType.vline ? vlineLabelPosition(f, r) : ghostLabelPosition_(f, r);

const graphLabelPosition = (f: ParametricFunction, r: GeoConfigurationDisplay) =>
  f.type === FunctionType.vline ? vlineLabelPosition(f, r) : graphLabelPosition_(f, r);

/**
 * Position of the vertex label
 * @param f parametric function with a vertex to label
 * @return {Position} label position
 */
const vertexLabelPosition = (
  f: ParametricFunction,
  _: GeoConfigurationDisplay,
  params = functionParams(f)
) => ({ x: params.d, y: params.e });

/**
 * Position of the tangent label
 * @param f parametric function with a tangent value to label
 * @return {Position} label position
 */
const tangentLabelPosition = (
  f: ParametricFunction,
  _: GeoConfigurationDisplay,
  params = functionParams(f),
  tx = tangentParamValue(f)
) => ({ x: tx, y: fval(f.type)(params)(0)(tx) });

/**
 * Position of the first secant label
 * @param f parametric function with a sx1 value to label
 * @return {Position} label position
 */
const secantLabel1Position = (
  f: ParametricFunction,
  _: GeoConfigurationDisplay,
  params = functionParams(f),
  sx1 = secantParamValues(f)[0]
) => ({ x: sx1, y: fval(f.type)(params)(0)(sx1) });

/**
 * Position of the second secant label
 * @param f parametric function with a sx2 value to label
 * @return {Position} label position
 */
const secantLabel2Position = (
  f: ParametricFunction,
  _: GeoConfigurationDisplay,
  params = functionParams(f),
  sx2 = secantParamValues(f)[1]
) => ({ x: sx2, y: fval(f.type)(params)(0)(sx2) });

/**
 * Get the position of the label with the given type
 * @param type {string} label type (see ParametricFunction) - same as the property name
 * @param f parametric function to label
 * @param r geo rectangle
 * @returns {Position} label position
 */
export const getLabelPosition = switchStatement<Position>(
  {
    [GHOSTLABEL_KEY]: ghostLabelPosition,
    [GRAPHLABEL_KEY]: graphLabelPosition,
    [TANGENTLABEL_KEY]: tangentLabelPosition,
    [SECANTLABEL1_KEY]: secantLabel1Position,
    [SECANTLABEL2_KEY]: secantLabel2Position,
    [VERTEXLABEL_KEY]: vertexLabelPosition,
  },
  { x: 0, y: 0 }
);

/**
 * Get the proper label alignment depending on the position and the slope of
 * the function.
 * @param r geo editor configuration
 * @param slope slope of the function at the given position
 * @param position position where the label is to be placed
 */
const graphLabelAlignment = (
  r: GeoConfigurationDisplay,
  slope: number,
  position: Position
): LabelAlignDirection =>
  cond([
    [
      // upper right corner
      constant(position.y >= r.yMax - BORDER && position.x >= r.xMax - BORDER),
      constant(LabelAlignDirection.leftBottom),
    ],
    [
      // lower right corner
      constant(position.y <= r.yMin + BORDER && position.x >= r.xMax - BORDER),
      constant(LabelAlignDirection.leftTop),
    ],
    [
      // upper left corner
      constant(position.y >= r.yMax - BORDER && position.x <= r.xMin + BORDER),
      constant(LabelAlignDirection.rightBottom),
    ],
    [
      // lower left corner
      constant(position.y <= r.yMin + BORDER && position.x <= r.xMin + BORDER),
      constant(LabelAlignDirection.rightTop),
    ],
    [
      // upper border
      constant(position.y >= r.yMax - BORDER),
      (s: number) => (s >= 0 ? LabelAlignDirection.rightBottom : LabelAlignDirection.leftBottom),
    ],
    [
      // lower border
      constant(position.y <= r.yMin + BORDER),
      (s: number) => (s >= 0 ? LabelAlignDirection.leftTop : LabelAlignDirection.rightTop),
    ],
    [
      // left border
      constant(position.x <= r.xMin + BORDER),
      (s: number) => (s >= 0 ? LabelAlignDirection.rightBottom : LabelAlignDirection.rightTop),
    ],
    [
      // right border
      constant(position.x >= r.xMax - BORDER),
      (s: number) => (s >= 0 ? LabelAlignDirection.leftTop : LabelAlignDirection.leftBottom),
    ],
    [
      // anywhere else
      stubTrue,
      (s: number) =>
        cond([
          // check the slope and place the label accordingly
          [constant(s >= 1), constant(LabelAlignDirection.rightBottom)],
          [constant(s >= 0 && s < 1), constant(LabelAlignDirection.leftTop)],
          [constant(s <= 0 && s >= -1), constant(LabelAlignDirection.leftBottom)],
          [constant(s < -1), constant(LabelAlignDirection.rightTop)],
        ])(),
    ],
  ])(slope);

/**
 * Get the position and the alignment of the label for the given label type
 * @param f function to be labeled
 * @param labelType property name (i.e. label type) in ParametricFunction for this label
 * @param f geo configuration rectangle
 * @returns {position: Position, align: LabelAlignDirection} position and aligment
 */
export const placeLabel = (
  f: ParametricFunction,
  labelType: string,
  r: GeoConfigurationDisplay,
  position = getLabelPosition(labelType, f, r),
  slope = fval(f.type)(functionParams(f))(1)(position.x)
) => ({
  position,
  align: graphLabelAlignment(r, slope, position),
});
