import * as React from 'react';
import {
  AXIS_ARROW_PIXEL,
  AXIS_ARROW_STRETCH_FACTOR,
  AXIS_MAJOR_TICKS_PIXEL,
  AXIS_MINOR_TICKS_PIXEL,
  scale,
  transformX,
  transformY,
  X_AXIS_LABEL_OFFSET,
  X_AXIS_TICK_LABEL_OFFSET,
  Y_AXIS_LABEL_OFFSET,
  Y_AXIS_TICK_LABEL_OFFSET,
} from '@bettermarks/importers';
import { getObjectColor, getTickWidth } from './decorations';
import {
  AxisDirection,
  type AxisTick,
  AxisTickStyle,
  type ContentReference,
  type Coords,
  HAlignment,
  isContentReference,
  numberFromStyles,
  VAlignment,
} from '@bettermarks/gizmo-types';
import { GeoSVGForeignObject } from './primitives/GeoSVGForeignObject';

import styles from './AxisRenderer.scss';

export type AxisLineProps = {
  id: string;
  matrix: number[];
  direction: AxisDirection;
  hasArrow: boolean;
  min: Coords;
  max: Coords;
};

export type AxisTicksProps = {
  matrix: number[];
  isVertical: boolean;
  max: number;
  tickDashes: AxisTick[];
};

export type AxisTickLabel = {
  pos: Coords;
  major: boolean;
  content: ContentReference | string | undefined;
};

export type AxisTickLabelsProps = {
  matrix: number[];
  isVertical: boolean;
  width: number;
  height: number;
  tickLabels: AxisTickLabel[];
  fontSize?: number;
  direction?: AxisDirection;
};

export type AxisLabelProps = {
  matrix: number[];
  isVertical: boolean;
  x: number;
  y: number;
  label: string | ContentReference;
  fontSize?: number;
};

export const AxisLine: React.FC<AxisLineProps> = ({
  id,
  matrix,
  direction,
  hasArrow,
  min,
  max,
}) => {
  const uArrowMarkerId = (id: string, isVertical: boolean): string => `arrow${id}${isVertical}`;
  const [sx, sy] = [transformX(matrix), transformY(matrix)];

  const isVertical = direction === AxisDirection.vertical;
  const isHorizontal = direction === AxisDirection.horizontal;

  const capPixels = AXIS_ARROW_PIXEL * AXIS_ARROW_STRETCH_FACTOR;
  const deltaX = max.x - min.x;
  const deltaY = max.y - min.y;

  const distMinMax = Math.sqrt(deltaY * deltaY + deltaX * deltaX);
  const arrowOffset: { x: number; y: number } = hasArrow
    ? isVertical
      ? { x: 0, y: capPixels }
      : isHorizontal
      ? { x: -capPixels, y: 0 }
      : {
          x: (-capPixels * deltaX) / distMinMax,
          y: (capPixels * deltaY) / distMinMax,
        }
    : { x: 0, y: 0 };

  return (
    <>
      {/** arrow head at the end of the axis **/}
      <defs>
        <marker
          id={uArrowMarkerId(id, isVertical)}
          viewBox={`0 0 ${100 * AXIS_ARROW_STRETCH_FACTOR} 100`}
          refX="0"
          refY="50"
          markerWidth={AXIS_ARROW_PIXEL * AXIS_ARROW_STRETCH_FACTOR}
          markerHeight={AXIS_ARROW_PIXEL}
          orient="auto"
          className={styles.axisarrow}
          markerUnits="userSpaceOnUse"
        >
          <path d={`M 0 0 L ${100 * AXIS_ARROW_STRETCH_FACTOR} 50 L 0 100 z`} />
        </marker>
      </defs>
      {/** the line of the axis **/}
      <line
        markerEnd={hasArrow ? `url(#${uArrowMarkerId(id, isVertical)})` : ''}
        className={styles.axis}
        x1={sx(min.x)}
        y1={sy(min.y)}
        x2={sx(max.x) + arrowOffset.x}
        y2={sy(max.y) + arrowOffset.y}
      />
    </>
  );
};

AxisLine.displayName = 'AxisLine';

export const AxisTicks: React.FC<AxisTicksProps> = ({ matrix, isVertical, max, tickDashes }) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  return (
    <>
      {
        // ignore ticks near the arrows ...
        tickDashes
          .filter(
            (tick) =>
              (isVertical ? tick.pos.y : tick.pos.x) <
              max - 1.2 * scale(matrix, false)(AXIS_ARROW_PIXEL)
          )
          .map((tick, i) => {
            const customStyles: React.CSSProperties = tick.decoration
              ? {
                  stroke:
                    tick.style === AxisTickStyle.INVISIBLE
                      ? 'transparent'
                      : getObjectColor(tick.decoration),
                  strokeWidth: getTickWidth(tick.decoration),
                }
              : {};

            return (
              <line
                key={`tick_${i}`}
                role={tick.major ? 'majorticks' : 'minorticks'}
                className={tick.major ? styles.majorticks : styles.minorticks}
                style={customStyles}
                x1={sx(tick.pos.x)}
                y1={sy(tick.pos.y)}
                x2={
                  sx(tick.pos.x) -
                  (isVertical ? (tick.major ? AXIS_MAJOR_TICKS_PIXEL : AXIS_MINOR_TICKS_PIXEL) : 0)
                }
                y2={
                  sy(tick.pos.y) +
                  (isVertical ? 0 : tick.major ? AXIS_MAJOR_TICKS_PIXEL : AXIS_MINOR_TICKS_PIXEL)
                }
              />
            );
          })
      }
    </>
  );
};

AxisTicks.displayName = 'AxisTicks';

export const AxisTickLabels: React.FC<AxisTickLabelsProps> = ({
  matrix,
  isVertical,
  width,
  height,
  tickLabels,
  fontSize = numberFromStyles(styles.AXIS_LABEL_FONT_SIZE),
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  return (
    <>
      {tickLabels.map(
        (tick) =>
          tick.content && (
            <GeoSVGForeignObject
              key={`tickLabel_${tick.pos.x}_${tick.pos.y}`}
              content={tick.content}
              x={sx(tick.pos.x)}
              y={sy(tick.pos.y)}
              dx={isVertical ? Y_AXIS_TICK_LABEL_OFFSET : 0}
              dy={isVertical ? 0 : X_AXIS_TICK_LABEL_OFFSET}
              hAlign={isVertical ? HAlignment.end : HAlignment.middle}
              vAlign={isVertical ? VAlignment.middle : VAlignment.hanging}
              width={width}
              height={height}
              className={styles.label}
              fontSize={fontSize}
            />
          )
      )}
    </>
  );
};

AxisTickLabels.displayName = 'AxisTickLabels';

export const AxisLabel: React.FC<AxisLabelProps> = ({
  matrix,
  isVertical,
  x,
  y,
  label,
  fontSize = numberFromStyles(styles.AXIS_LABEL_FONT_SIZE),
}) => {
  const [sx, sy] = [transformX(matrix), transformY(matrix)];
  if (isContentReference(label)) {
    return (
      <GeoSVGForeignObject
        key={`axisLabel_${label.$refid || label}`}
        content={label}
        x={sx(x) + (isVertical ? AXIS_ARROW_PIXEL : -X_AXIS_LABEL_OFFSET)}
        y={sy(y) - (isVertical ? Y_AXIS_LABEL_OFFSET : AXIS_ARROW_PIXEL)}
        hAlign={isVertical ? HAlignment.start : HAlignment.end}
        vAlign={isVertical ? VAlignment.hanging : VAlignment.bottom}
        // These are magic numbers, but they seem sufficiently big and don't
        // affect the label's position.
        width={200}
        height={50}
        className={styles.label}
        fontSize={fontSize}
      />
    );
  }
  return (
    <text
      className={styles.label}
      style={{ fontSize }}
      textAnchor={isVertical ? 'start' : 'end'}
      dominantBaseline={isVertical ? 'hanging' : 'baseline'}
      x={sx(x) + (isVertical ? AXIS_ARROW_PIXEL : -X_AXIS_LABEL_OFFSET)}
      y={sy(y) - (isVertical ? Y_AXIS_LABEL_OFFSET : AXIS_ARROW_PIXEL)}
    >
      {label}
    </text>
  );
};

AxisLabel.displayName = 'AxisLabel';
