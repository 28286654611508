import * as React from 'react';
import classNames from 'classnames';

import { type ExerciseDisplayStatus, SeriesMode } from '../../../../types';
import { isInViewport } from '../../containers/Exercise/helper';
import { ExerciseNavigationButton } from '../ExerciseNavigationButton';
import { Appearance } from '../ExerciseNavigationButton/ExerciseNavigationButton';
import { ChevronUpMedium, ChevronDownMedium } from '../../../../components/icons';

import styles from './navigation.scss';
import { ExcludedFromTabNavigationButton } from '../../../../components/ExcludedFromTabNavigation';

export type NavigationLargeProps = {
  currentExerciseIndex: number;
  exerciseDisplayStatus: ExerciseDisplayStatus[];
  mode: SeriesMode;
  nextExercise: (index: number) => void;
  previousExercise: (index: number) => void;
  switchExercise: (index: number) => void;
};

export class NavigationLarge extends React.PureComponent<NavigationLargeProps> {
  activeExRef: React.RefObject<HTMLDivElement> = React.createRef();

  scrollIntoView(element: HTMLElement) {
    if (!isInViewport(element)) {
      element.scrollIntoView(false);
    }
  }

  componentDidUpdate() {
    if (this.activeExRef.current) {
      this.scrollIntoView(this.activeExRef.current);
    }
  }

  render() {
    const {
      currentExerciseIndex,
      exerciseDisplayStatus,
      mode,
      nextExercise,
      previousExercise,
      switchExercise,
    } = this.props;
    const isFirstExercise = currentExerciseIndex === 0;
    const isLastExercise = currentExerciseIndex === exerciseDisplayStatus.length - 1;
    const isTestMode = mode === SeriesMode.test;
    const onPrevExercise = isFirstExercise
      ? undefined
      : () => previousExercise(currentExerciseIndex);
    const onNextExercise = isLastExercise ? undefined : () => nextExercise(currentExerciseIndex);

    return (
      <>
        <div
          id="ex-nav-btns"
          data-cy="ex-nav-btns"
          className={classNames(styles.bar, { [styles.testMode]: isTestMode })}
        >
          {exerciseDisplayStatus.map((status, i) => (
            <ExerciseNavigationButton
              key={i}
              ref={currentExerciseIndex === i ? this.activeExRef : undefined}
              exerciseIndex={i}
              exerciseDisplayStatus={status}
              switchExercise={switchExercise}
              selected={currentExerciseIndex === i}
              appearance={Appearance.desktop}
              mode={mode}
            />
          ))}
        </div>
        {isTestMode && (
          <div id="ex-nav-arrows" className={styles.navigationArrows}>
            <div
              className={classNames(styles.prev, {
                [styles.inactive]: isFirstExercise,
              })}
            >
              <ExcludedFromTabNavigationButton onClick={onPrevExercise}>
                <ChevronUpMedium className={styles.icon} />
              </ExcludedFromTabNavigationButton>
            </div>
            <div className={styles.spacer} />
            <div
              className={classNames(styles.next, {
                [styles.inactive]: isLastExercise,
              })}
            >
              <ExcludedFromTabNavigationButton onClick={onNextExercise}>
                <ChevronDownMedium className={styles.icon} />
              </ExcludedFromTabNavigationButton>
            </div>
          </div>
        )}
      </>
    );
  }
}
