import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { type BoundingRect } from 'react-measure';
import { useMediaQuery } from 'react-responsive';
import {
  Bar,
  Layout,
  VerticalMenu,
  VerticalMenuItem,
  VerticalMenuItemType,
  MenuItemElement,
  MenuItemElementLabel,
} from '@seriesplayer/common-ui';

// Importing from components lead to undefined errors
import { DialogHeader, Icon, Overlay, Popup } from '../../../../../components/index';

import { VERY_SMALL_SCREEN_WIDTH } from '../../../constants';
import styles from './HelpMenu.scss';

export type HelpMenuDispatchProps = {
  onCancel: () => void;
  onShowHints: () => void;
  onShowSolution: () => void;
  onShowTextBook: () => void;
};

export type HelpMenuOwnProps = {
  elementBounds?: BoundingRect;
  overlayId?: string;
};

export type HelpMenuStateProps = {
  isHelpShown: boolean;
  isHintAvailable: boolean;
  isKEMAvailable: boolean;
  isShowSolutionAvailable: boolean;
};

export type HelpMenuProps = HelpMenuDispatchProps & HelpMenuStateProps & HelpMenuOwnProps;

export const enum MenuItemLabels {
  hint = 'seriesplayer:dialog.help.hint',
  textbook = 'seriesplayer:dialog.help.textbook',
  solution = 'seriesplayer:dialog.help.solution',
}

export const HelpMenu: React.FC<HelpMenuProps> = ({
  elementBounds,
  isHelpShown,
  isHintAvailable,
  isKEMAvailable,
  isShowSolutionAvailable,
  onCancel,
  onShowHints,
  onShowSolution,
  onShowTextBook,
  overlayId,
}) => {
  const [t] = useTranslation();

  const isLargeScreen = useMediaQuery({ minWidth: VERY_SMALL_SCREEN_WIDTH });

  const helpMenuContent = (
    <Layout vertical>
      <DialogHeader
        title={t('seriesplayer:dialog.help.title')}
        leftButton={isLargeScreen ? undefined : { icon: 'ArrowLeftLargeBold', onClick: onCancel }}
        rightButton={
          isLargeScreen
            ? {
                icon: 'TimesXLargeBold',
                onClick: onCancel,
                dataCy: 'close-help-menu',
              }
            : undefined
        }
      />
      <Bar />
      <VerticalMenu limitHeight separator>
        {isHintAvailable && (
          <VerticalMenuItem
            data-cy="show-hints"
            type={VerticalMenuItemType.warning}
            onClick={onShowHints}
          >
            <MenuItemElement>
              <Icon iconKey="InfoCircleXLarge" />
            </MenuItemElement>
            <MenuItemElementLabel>{t(MenuItemLabels.hint)}</MenuItemElementLabel>
          </VerticalMenuItem>
        )}
        {isKEMAvailable && (
          <VerticalMenuItem data-cy="show-textbook" onClick={onShowTextBook}>
            <MenuItemElement>
              <Icon iconKey="BookXLarge" />
            </MenuItemElement>
            <MenuItemElementLabel>{t(MenuItemLabels.textbook)}</MenuItemElementLabel>
          </VerticalMenuItem>
        )}
        {isShowSolutionAvailable && (
          <VerticalMenuItem
            data-cy="show-solution"
            type={VerticalMenuItemType.confirm}
            onClick={onShowSolution}
          >
            <MenuItemElement>
              <Icon iconKey="CheckMediumBold" />
            </MenuItemElement>
            <MenuItemElementLabel>{t(MenuItemLabels.solution)}</MenuItemElementLabel>
          </VerticalMenuItem>
        )}
      </VerticalMenu>
    </Layout>
  );
  return (
    <Overlay overlayId={overlayId}>
      {isLargeScreen ? (
        // For largeScreen, embed inside a Popup
        <Popup
          kind="small"
          show={isHelpShown}
          place="top"
          onClickOverlay={onCancel}
          elementBounds={elementBounds}
        >
          {helpMenuContent}
        </Popup>
      ) : (
        // For small-screen show full-screen dialog
        isHelpShown && <div className={styles.dialog}>{helpMenuContent}</div>
      )}
    </Overlay>
  );
};

HelpMenu.displayName = 'HelpMenu';
