import { type TFunction } from 'i18next';
import { type FC } from 'react';
import { FormAddIcon, FormDivideIcon, FormRemoveIcon } from '../icons/FractionForm';
import { AddNodeIcon, BondNodeIcon, GrabIcon } from '../icons/Tree';
import {
  AddCircleIcon,
  AddPointIcon,
  AddRayDashedIcon,
  AddRayIcon,
  AddSegmentDashedIcon,
  AddSegmentIcon,
  AddStraightlineDashedIcon,
  AddStraightlineIcon,
  AddVectorIcon,
  AngleLineIcon,
  DeleteIcon,
  IntervalIcon,
  LabelIcon,
  MarkupBlueIcon,
  MarkupOrangeIcon,
  MiddlepointIcon,
  MoveIcon,
  ParallelLineIcon,
  PerpendicularDEIcon,
  PerpendicularENIcon,
} from '../icons/Geo';
import bmcolors from 'styles/bmcolors.scss';
import { makeColoringIcon } from './ColoringIcon';
import { isContentColor } from '@bettermarks/importers';

type ButtonRegistry = { [key: string]: FC<any> };

export const buttonRegistry: ButtonRegistry = {
  form_add: FormAddIcon,
  form_remove: FormRemoveIcon,
  form_divide: FormDivideIcon,
  form_toggle_fill: MarkupOrangeIcon,
  graph_add: AddNodeIcon,
  graph_connect: BondNodeIcon,
  graph_move: MoveIcon,
  graph_remove: DeleteIcon,
  graph_label: LabelIcon,
  graph_grab: GrabIcon,
  metrics_add_circle: AddCircleIcon,
  metrics_add_point: AddPointIcon,
  metrics_add_label: LabelIcon,
  metrics_add_segment: AddSegmentIcon,
  metrics_add_segment_dashed: AddSegmentDashedIcon,
  metrics_add_segment_dash_dot: AddSegmentDashedIcon,
  metrics_add_ray: AddRayIcon,
  metrics_add_ray_dash_dot: AddRayDashedIcon,
  metrics_add_ray_dashed: AddRayDashedIcon,
  metrics_add_straightline: AddStraightlineIcon,
  metrics_add_straightline_dash_dot: AddStraightlineDashedIcon,
  metrics_add_straightline_dashed: AddStraightlineDashedIcon,
  metrics_add_vector: AddVectorIcon,
  metrics_default_cursor: MoveIcon,
  metrics_tool_angle: AngleLineIcon,
  metrics_tool_interval: IntervalIcon,
  metrics_tool_middlepoint: MiddlepointIcon,
  metrics_tool_parallels: ParallelLineIcon,
  metrics_tool_perpendicular: PerpendicularENIcon,
  metrics_tool_perpendicular_de: PerpendicularDEIcon,
  metrics_tool_scroll: GrabIcon,

  metrics_tool_selection: MarkupBlueIcon,
  // For each bm color we add a polygon selection icon
  ...Object.keys(bmcolors).reduce(
    (acc: ButtonRegistry, cur: string) =>
      isContentColor(cur)
        ? {
            ...acc,
            [`metrics_polygon_selection_${cur}`]: makeColoringIcon(cur),
          }
        : acc,
    {}
  ),
  // For each bm color we add a selection tool icon
  ...Object.keys(bmcolors).reduce(
    (acc: ButtonRegistry, cur: string) =>
      isContentColor(cur)
        ? { ...acc, [`metrics_tool_selection_${cur}`]: makeColoringIcon(cur) }
        : acc,
    {}
  ),
  metrics_move_points_vertically: MoveIcon,
  move: MoveIcon,
  remove: DeleteIcon,

  // For each bm color we add a colored icon
  ...Object.keys(bmcolors).reduce(
    (acc: ButtonRegistry, cur: string) =>
      isContentColor(cur) ? { ...acc, [cur]: makeColoringIcon(cur) } : acc,
    {}
  ),
};

// mapping: tool name -> i18n replacement key
export const localizedButtons: { [key: string]: string } = {
  metrics_tool_perpendicular: 'toolPerpendicular.icon',
};

export const localized = (name: string, t: TFunction) =>
  name in localizedButtons ? t(localizedButtons[name]) : name;
