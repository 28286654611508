/**
 * @module FormulaReducer
 */

import { type Severity } from '@bettermarks/umc-kotlin';
import { switchMap } from '../../../gizmo-utils/fpTools';
import { DESELECT_CONTENT, SELECT_CONTENT, SET_SEVERITY } from '../../../gizmo-utils/redux';
import {
  $MI,
  $MN,
  $MO,
  $MTEXT,
  type BasefulElement,
  Direction,
  type ErrorMessage,
  FORMULA_INTERACTIVE_CONTENT,
  type FormulaContent,
  isContainer,
  isMFrac,
  isMO,
  isMRow,
  isMSup,
  isMToken,
  type KeyboardTool,
  type MathContent,
  type MRow,
  type MSup,
  type MToken,
  type MToken$,
  type Path,
  type PathElement,
  PathMode,
  type RestrictedToKeys,
  Restriction,
  ROUND_CLOSE,
  ROUND_OPEN,
  type VirtualKeyboardConfig,
} from '@bettermarks/gizmo-types';
import { curry, defaultTo, flow, get, isNil, isNumber, omit } from 'lodash';
import log from 'loglevel';
import { type Action, handleActions } from 'redux-actions';
import {
  CURSOR,
  cursorPath,
  hasCursor,
  lastPathElements,
  MABS,
  MFRAC,
  MN,
  MO,
  MROOT,
  MROW,
  MSQRT,
  MSUB,
  MSUP,
  MTOKEN,
  process,
  processFormula,
  removeCursor,
  removeCursorSimple,
  set,
  unwrapContentIfNeeded,
  withFormula,
} from '@bettermarks/importers';

import { toMathString } from '../exporter/toString';
import * as Actions from './actions';
import { deleteContent } from './editor/deleteContent';
import { enterContainer } from './editor/enterContainer';
import { getLength } from './editor/getLength';
import { moveCursor } from './editor/moveCursor';
import {
  concatBases,
  concatNumbers,
  markCursor,
  removeMarkedCursor,
  sanitizeBases,
  splitBases,
} from './editor/preprocessing';
import { getValue } from '../../../components/_utils/objectUtils';

/**
 * It is really confusing that how flex is counting max input length.
 * Agreed with Condev/Product to increase the default to 30.
 * Implement a proper way to count input elements and have check in qa-tool.
 */
const FORMULA_DEFAULT_MAX_LENGTH = 30;
const ERROR_MSG = 'errorMessage';

type FormulaProcessorReducer = (
  input: FormulaContent,
  action: Action<Actions.FormulaActionPayload>
) => FormulaContent;

/**
 * Move the cursor one element to `direction`.
 * The cursor will move according to the specified rules,
 * e.g. will move in a fractions denominator if coming from the right.
 *
 * @param {MathContent} mathContent the content containing the cursor somewhere deep within.
 * @param {Direction} direction in which direction to move.
 *
 * @returns {MathContent} content with the cursor moved.
 */
export const move = (formula: FormulaContent, direction: Direction): FormulaContent => {
  const mathContent = MROW(...formula.content);

  const path = cursorPath(mathContent);
  if (path.length === 0) {
    return formula;
  }
  const [p, ...parentPath] = lastPathElements(path, 1);
  if (!isNumber(p) || !isFinite(p)) {
    // claim: "this assumption is true after pre-processing"
    // we have had errors reported for this action, trying to get more details
    // avoiding NaN of further calculations
    log.warn({
      message: 'Can not move cursor',
      extra: {
        direction,
        formulaInputString: mathContent && toMathString(mathContent, false),
        formulaPath: path,
        formulaReducerProblem: 'p is not a finite number',
      },
    });
    // and handling it by treating it as a noop instead of failing
    return formula;
  }

  const children: MathContent[] = get(mathContent, parentPath);
  if (isNil(children)) {
    // we have had errors reported for this action, trying to get more details
    // avoiding accessing slice and similar methods access on undefined
    log.warn({
      message: 'Can not move cursor',
      extra: {
        direction,
        formulaInputString: mathContent && toMathString(mathContent, false),
        formulaPath: path,
        formulaReducerProblem: `children is nil: ${children}`,
      },
    });
    // and handling it by treating it as a noop instead of failing
    return formula;
  }

  const newPos =
    direction === Direction.Left ? Math.max(0, p - 1) : Math.min(p + 1, children.length - 1);

  const nextElement = children[newPos];
  const contentWithoutCursor = removeCursorSimple(mathContent);

  if (p !== newPos && isContainer(nextElement)) {
    // place inside the container!
    const nextElPos = direction === Direction.Left ? newPos : p; // we removed the cursor...
    return {
      ...formula,
      content: unwrapContentIfNeeded(
        enterContainer(contentWithoutCursor, [...parentPath, nextElPos], direction)
      ),
    };
  }

  return moveCursor(contentWithoutCursor, path, direction, formula);
};

export const moveCursorLeft = (formula: FormulaContent): FormulaContent =>
  move(formula, Direction.Left);
export const moveCursorRight = (formula: FormulaContent): FormulaContent =>
  move(formula, Direction.Right);

export const deleteContentLeft = (content: MathContent): MathContent =>
  deleteContent(content, Direction.Left);
export const deleteContentRight = (content: MathContent): MathContent =>
  deleteContent(content, Direction.Right);

const restrictionToLayout = switchMap<VirtualKeyboardConfig>(
  {
    [Restriction.Letters]: { letters: true },
    [Restriction.Numbers]: { numbers: true },
    [Restriction.NumbersDotOverbar]: {
      numbers: true,
      decimal_point: true,
      overbar: true,
    },
    [Restriction.NumbersFractionbar]: { numbers: true, fraction: true },
    [Restriction.NumbersLetters]: { numbers: true, letters: true },
    [Restriction.NumbersLettersDotOverbar]: {
      numbers: true,
      decimal_point: true,
      letters: true,
      overbar: true,
    },
    [Restriction.NumbersLettersFractionbar]: {
      numbers: true,
      letters: true,
      fraction: true,
    },
  },
  {}
);

const restrictionTypeMessage = switchMap<string>(
  {
    [Restriction.Letters]: 'editors:formulaEditor.restrictionrule.onlyletters',
    [Restriction.Numbers]: 'editors:formulaEditor.restrictionrule.onlynumbers',
    [Restriction.NumbersDotOverbar]:
      'editors:formulaEditor.restrictionrule.onlynumbers_comma_and_periode',
    [Restriction.NumbersFractionbar]:
      'editors:formulaEditor.restrictionrule.onlynumbers_and_fraction',
    [Restriction.NumbersLetters]: 'editors:formulaEditor.restrictionrule.onlynumbers_and_letters',
    [Restriction.NumbersLettersDotOverbar]: `editors:formulaEditor.restrictionrule.onlynumbers_letters_comma_and_periode`,
    [Restriction.NumbersLettersFractionbar]:
      'editors:formulaEditor.restrictionrule.onlynumbers_letters_and_fraction',
  },
  ''
);

const containsMFrac = (content: MathContent[]): boolean =>
  content.filter((c) => isMFrac(c) || (isMRow(c) && containsMFrac(c.children))).length > 0;

/* eslint-disable complexity */
const restrictedByLayout = (
  action: Action<Actions.FormulaActionPayload>,
  layout: VirtualKeyboardConfig
) =>
  (action.type === Actions.ENTER_IDENTIFIER && !layout.letters) ||
  (action.type === Actions.ENTER_INVALID_IDENTIFIER && !layout.letters) ||
  (action.type === Actions.ENTER_NUMBER && !layout.numbers) ||
  (action.type === Actions.ENTER_NUMBER && action.payload === '.' && !layout.decimal_point) ||
  (action.type === Actions.ENTER_BRACKET && !layout.brackets) ||
  (action.type === Actions.ENTER_OPERATOR &&
    action.payload === '-' &&
    !(layout.operators || layout.numbers)) ||
  (action.type === Actions.ENTER_OPERATOR &&
    // Note: ':' is a shortcut for '/'. Meaning when user hits ':', '/' action is dispatched.
    (action.payload === '+' || action.payload === '*' || action.payload === '/') &&
    !layout.operators) ||
  (action.type === Actions.ENTER_CHAR && action.payload === 'π' && !layout.pi) ||
  (action.type === Actions.ENTER_ABS && !layout.absolute) ||
  (action.type === Actions.ENTER_FRAC && !layout.fraction) ||
  (action.type === Actions.ENTER_ROOT && !layout.root) ||
  (action.type === Actions.ENTER_SQRT && !layout.sqrt) ||
  (action.type === Actions.ENTER_SUP && !layout.exponent) ||
  // the following action can only be triggered by the calculator, when hitting "insert"
  // (calculator result can be: number, fraction or mixed fraction,
  // hence checking against "result contains fraction" and "fractions are restricted")
  (action.type === Actions.ENTER_MROW &&
    containsMFrac(action.payload as MathContent[]) &&
    !layout.fraction);

const restrictedByKeysList = (
  action: Action<Actions.FormulaActionPayload>,
  keys: RestrictedToKeys
) => {
  if (
    (action.type === Actions.ENTER_IDENTIFIER ||
      action.type === Actions.ENTER_INVALID_IDENTIFIER) &&
    keys.letters &&
    !keys.letters.includes(action.payload as string)
  )
    return keys.letters;

  if (
    action.type === Actions.ENTER_NUMBER &&
    keys.numbers &&
    !keys.numbers.includes(action.payload as string)
  )
    return keys.numbers;

  if (
    action.type === Actions.ENTER_BRACKET &&
    keys.brackets &&
    !keys.brackets.includes(action.payload as string)
  )
    return keys.brackets;

  if (
    action.type === Actions.ENTER_OPERATOR &&
    keys.operators &&
    !keys.operators.includes(action.payload as string)
  )
    return keys.operators;
};

const maxNestingFraction = {
  i18nKey: 'editors:formulaEditor.restrictionrule.maxlength.fractions',
  param: 3,
};

const maxNestingPower = {
  i18nKey: 'editors:formulaEditor.restrictionrule.maxlength.power',
  param: 2,
};

const maxNestingRoot = {
  i18nKey: 'editors:formulaEditor.restrictionrule.maxlength.roots',
  param: 3,
};

const maxFracNestingReached = (content: MathContent[]) =>
  cursorPath(MROW(...content)).filter((it) => it === 'denominator' || it === 'numerator').length ===
  maxNestingFraction.param;

const isInsideMSup = (pe: PathElement) => pe === 'superscript' || pe === 'base';

const hasMSupInside = (msup: MSup) =>
  msup.base.children.filter(isMSup).length > 0 ||
  msup.superscript.children.filter(isMSup).length > 0;

export const maxPowNestingReached = (content: MathContent[]) => {
  const wrapped = MROW(...content);
  const cp = cursorPath(wrapped);
  const [pos, ...mrow] = lastPathElements(cp, 1);

  if (isNumber(pos) && isFinite(pos) && pos > 0) {
    const pre = get(wrapped, [...mrow, pos - 1]);
    // cursor might be behind msup (e.g. [MSUP(MSUP(base, super), super), CURSOR])
    if (
      isMSup(pre) &&
      // is the msup before the cursor 2 levels nested?
      (hasMSupInside(pre) ||
        // is the cursor itself inside an msup? (e.g. MSUP([MSUP(base, super), CURSOR], super))
        cp.filter(isInsideMSup).length === maxNestingPower.param - 1)
    ) {
      return true;

      // cursor might be in superscript (e.g. MSUP(base, MSUP(base, [super, CURSOR])))
    } else {
      return cp.filter(isInsideMSup).length === maxNestingPower.param;
    }
  }

  return false;
};

const maxRootNestingReached = (content: MathContent[]) =>
  cursorPath(MROW(...content)).filter((it) => it === 'radicand').length === maxNestingRoot.param;

/**
 * Called when we enter a new mtag. Check if it's possible and write an error
 * message if necessary.
 */
export const errorFromRestrictions = (
  formula: FormulaContent,
  action: Action<Actions.FormulaActionPayload>
): ErrorMessage | undefined => {
  if (action && formula.configuration) {
    const { restrictionType, restrictedToKeys } = formula.configuration;

    if (restrictionType && restrictedByLayout(action, restrictionToLayout(restrictionType))) {
      return {
        i18nKey: restrictionTypeMessage(restrictionType),
      };
    }

    if (restrictedToKeys) {
      const keysList: string[] | undefined = restrictedByKeysList(action, restrictedToKeys);

      if (keysList) {
        return {
          i18nKey: 'editors:formulaEditor.restrictionrule.restrictedToKeys',
          param: keysList.join(', '),
        };
      }
    }
  }

  if (action.type === Actions.ENTER_FRAC && maxFracNestingReached(formula.content)) {
    return maxNestingFraction;
  }

  if (
    (action.type === Actions.ENTER_SUP || action.type === Actions.ENTER_SQR) &&
    maxPowNestingReached(formula.content)
  ) {
    return maxNestingPower;
  }

  if (
    (action.type === Actions.ENTER_ROOT || action.type === Actions.ENTER_SQRT) &&
    maxRootNestingReached(formula.content)
  ) {
    return maxNestingRoot;
  }

  const maxInputLength = defaultTo<number>(formula.maxInputLength, FORMULA_DEFAULT_MAX_LENGTH);
  const currFormulaLength = getLength(MROW(...formula.content));
  if (
    currFormulaLength >= maxInputLength ||
    (action.type === Actions.ENTER_MROW
      ? getLength(MROW(...(action.payload as MathContent[]))) + currFormulaLength > maxInputLength
      : false)
  ) {
    return {
      i18nKey: 'editors:formulaEditor.restrictionrule.maxlength.text',
      param: maxInputLength,
    };
  }
  return undefined;
};

const ifAllowed =
  (processor: FormulaProcessorReducer): FormulaProcessorReducer =>
  (formula, action) => {
    if (!action) return formula;

    /**
     * Doing the checks in the following order:
     *
     * 1. errorFromRestriction (formula.configuration.restrictionType)
     * 2. restrictedByLayout
     *    (e.g. no "letter key" on the virtual keyboard
     *     -> do not allow entering a letter via physical keyboard)
     *
     * Otherwise errors for restrictions that match "restrictions by layout" will not be shown!!!
     *
     * Example:
     *
     * - restriction: only numbers; layout: only number keys on the virtual keyboard
     * - user enters letter from "physical keyboard"
     *
     * -> error tooltip with error message "Numbers only" should be shown
     */
    const errorMessage = errorFromRestrictions(formula, action);
    if (errorMessage) return { ...formula, errorMessage };

    const tool = formula.tool as KeyboardTool;
    if (tool && restrictedByLayout(action, tool.layout)) return formula;

    return processor(omit(formula, ERROR_MSG), action);
  };

const notAllowed =
  (processor: FormulaProcessorReducer): FormulaProcessorReducer =>
  (formula, action) => {
    if (action) {
      const errorMessage = errorFromRestrictions(formula, action);
      if (errorMessage) return { ...formula, errorMessage };
    }

    return formula;
  };

/**
 * Adds `mtag` to the content "at the position of the cursor".
 *
 * If `mtag` contains a cursor the current cursor will be replaced with `mtag`.
 * Otherwise `mtag` will be put to the position that the cursor had before
 * and the cursor will be present after `mtag`.
 *
 * @param {MathContent} mtag The MTag to enter at the cursor path.
 * @param {MathContent} content The content to change.
 * @returns {MathContent[]} content containing `mtag`
 */
const enterMTagAtCursor2 = (mtag: MathContent, content: MathContent): MathContent => {
  const path = cursorPath(content);
  let result = hasCursor([mtag]) ? removeCursorSimple(content) : content;
  const idx = path.pop();
  if (isNumber(idx)) {
    const mrow = get(result, path) as MathContent[];
    result = set(result, path, [...mrow.slice(0, idx), mtag, ...mrow.slice(idx)]);

    return result;
  }

  return content;
};

/**
 * curried version of
 * @see enterMTagAtCursor2
 */
export const enterMTagAtCursor = curry(enterMTagAtCursor2);

const enterMFrac = (content: MathContent): MathContent => {
  const path = cursorPath(content);
  // This part handles the case, when we enter an mfrac into the base (in front of the exponent)
  // Then it should be surrounded with brackets!
  if (path.length > 3) {
    const [pp, container, ...parentPath] = lastPathElements(path.slice(0, -2), 2);
    if (container === 'base') {
      const mrow: MathContent[] = get(content, parentPath);
      const parentPos = pp as number;
      const baseful: BasefulElement = get(content, [...parentPath, parentPos]);
      return set(content, parentPath, [
        ...mrow.slice(0, parentPos),
        MO(ROUND_OPEN),
        MFRAC(CURSOR, MROW()),
        {
          ...baseful,
          base: MROW(MO(ROUND_CLOSE)),
        },
        ...mrow.slice(parentPos + 1),
      ]);
    }
  }
  // just enter the mfrac!
  return enterMTagAtCursor(MFRAC(CURSOR, MROW()), content);
};

const MTokenForAction: { [key: string]: MToken$ } = {
  [Actions.ENTER_NUMBER]: $MN,
  [Actions.ENTER_IDENTIFIER]: $MI,
  [Actions.ENTER_CHAR]: $MTEXT,
  [Actions.ENTER_OPERATOR]: $MO,
  [Actions.ENTER_BRACKET]: $MO,
  [Actions.ENTER_EQUALS]: $MO,
};

/**
 * This (curried) function usually simply enters an mtoken at the cursor
 * position. In the case where the cursor is inside a base of an msup/msub it
 * will place the token in front of the base while keeping the cursor in the
 * base. This ensures that there's only one element inside the base, which is
 * needed for the formula pre- and post-processing to work.
 * @param token the token to add
 */
const enterTokenInMathContent =
  (token: MToken) =>
  (content: MathContent): MathContent => {
    const path = cursorPath(content);
    if (path.length > 3) {
      const [pp, container, ...parentPath] = lastPathElements(path.slice(0, -2), 2);
      if (container === 'base') {
        const mrow: MathContent[] = get(content, parentPath);
        const parentPos = pp as number;
        return set(content, parentPath, [
          ...mrow.slice(0, parentPos),
          token,
          ...mrow.slice(parentPos),
        ]);
      }
    }
    return enterMTagAtCursor(token, content);
  };

const enterToken: FormulaProcessorReducer = (formula: FormulaContent, action: Action<string>) =>
  isNil(action) || isNil(action.payload)
    ? formula
    : processFormula(enterTokenInMathContent(MTOKEN(MTokenForAction[action.type], action.payload)))(
        formula
      );

const enterMRow: FormulaProcessorReducer = (
  formula: FormulaContent,
  action: Action<MathContent[]>
) =>
  isNil(action) || isNil(action.payload)
    ? formula
    : processFormula((content) => enterMTagAtCursor(MROW(...action.payload), content))(formula);

const enterBasefulElement =
  (element: BasefulElement, cursorAfter = false) =>
  (content: MathContent): MathContent => {
    const cp = cursorPath(content);
    if (cp.length === 0) {
      return content;
    }
    // extract position (last path element) and keep the rest of the path
    const [pos, ...path] = lastPathElements(cp, 1);
    if (!isNumber(pos) || !isFinite(pos)) {
      log.warn({
        message: 'Can not enter sup / sub',
        extra: {
          formulaInputString: content && toMathString(content, false),
          formulaPath: cp,
          formulaReducerProblem: 'position is not a finite number',
        },
      });
      return content;
    }
    if (pos === 0) {
      return content; // No base -> no exponent (or subscript)
    }

    const contentWithoutCursor = removeCursorSimple(content);
    const mrow: MathContent[] = get(contentWithoutCursor, path);
    const prev = mrow[pos - 1];
    const _cursor = cursorAfter ? [CURSOR] : [];

    // operators are not allowed as a base
    if (isMO(prev) && prev.text !== ROUND_CLOSE) {
      return content;
    } else if (isMFrac(prev)) {
      // surround the fraction with brackets
      return set(contentWithoutCursor, path, [
        ...mrow.slice(0, pos - 1),
        MO(ROUND_OPEN),
        prev,
        {
          ...element,
          base: MROW(MO(ROUND_CLOSE)),
        },
        ..._cursor,
        ...mrow.slice(pos),
      ]);
    }

    return set(contentWithoutCursor, path, [
      ...mrow.slice(0, pos - 1),
      {
        ...element,
        base: MROW(prev),
      },
      ..._cursor,
      ...mrow.slice(pos),
    ]);
  };

/**
 * Handle the case, where the path points inside an mn.
 */
const handleMNPath = (p: Path, mode: PathMode, input: MathContent) => {
  const mns: MathContent[] = [];
  const mnIndex = p[p.length - 1] as number; // the index of our character
  const csrIdx = mode === PathMode.Append ? mnIndex + 1 : mnIndex; // append or insert ?
  // mn needs to be splitted;
  const path = p.slice(0, -2); // the path of the mn (not the glyph inside)
  const mn = getValue(input, path);

  if (!isMToken(mn)) {
    log.warn(`FormulaReducer ${JSON.stringify(input)} does not have path ${JSON.stringify(p)}`);
    return undefined;
  }

  const text = mn.text.split('');
  const first = text.slice(0, csrIdx).join('');
  const last = text.slice(csrIdx).join('');
  /*
   * If we want to place the cursor inside an <mn> we have to split it in
   * two! We collect the mns here and flatten them into the formula later.
   */
  if (first.length > 0) {
    mns.push(MN(first));
  }
  mns.push(CURSOR);
  if (last.length > 0) {
    mns.push(MN(last));
  }
  return { mns, path };
};

export const insertCursorIntoMRowChildren = (
  mns: MathContent[],
  mrow: MathContent[],
  mode: PathMode,
  clickIndex: number
) => {
  // clicking into FormulaInput (outside of MathML/MathContent)
  // can request to put the cursor "at the end" by setting clickIndex to -1
  const realClickIndex = clickIndex < 0 ? mrow.length + clickIndex : clickIndex;
  const csrIdx =
    mode === PathMode.Append // append or insert ?
      ? realClickIndex + 1
      : realClickIndex;

  return mns.length === 0
    ? // just insert cursor
      [...mrow.slice(0, csrIdx), CURSOR, ...mrow.slice(csrIdx)]
    : // insert the splitted <mn>s
      [...mrow.slice(0, clickIndex), ...mns, ...mrow.slice(clickIndex + 1)];
};

/**
 * Set the cursor at the given path position.
 * @param path The path of the math element, where the cursor should be set
 * @param mode Whether the cursor should be inserted at (put in front of) or
 * appended to the glyph
 */
const setCursorInMathContent =
  (path: Path, mode: PathMode) =>
  (input: MathContent): MathContent => {
    /*
     * First we need to check if we clicked inside a multi-glyph token. Right
     * now this will only happen with <mn>s. These elements can contain longer
     * numbers for example.  The path to a specific glyph (character) inside
     * the <mn> will end with [..., 'text', index], where index is the index of
     * the character inside the string.
     */
    let mns: MathContent[] = [];
    if (isNumber(path[path.length - 1]) && path.length > 1 && path[path.length - 2] === 'text') {
      const res = handleMNPath(path, mode, input);
      if (!res) {
        path = path.slice(0, -2);
      } else {
        mns = res.mns;
        path = res.path;
      }
    }

    const last = path[path.length - 1];
    if (isNumber(last)) {
      // this means now we are inside an mrow
      const mrow: MathContent[] = getValue(input, path.slice(0, -1));
      if (!mrow) {
        // we have had errors reported for this action, trying to get more details
        log.warn({
          message: 'Can not set cursor',
          extra: {
            formulaInputString: input && toMathString(input, false),
            formulaPath: JSON.stringify(path),
            formulaReducerProblem: `mrow is falsy: ${mrow}`,
          },
        });
        // and handling it by treating it as a noop instead of failing
        return input;
      }
      return set(input, path.slice(0, -1), insertCursorIntoMRowChildren(mns, mrow, mode, last));
    } else {
      // we clicked on a container bucket (e.g. denominator of an <mfrac>)
      const mrow: MRow = get(input, path);
      return set(input, path, {
        ...mrow,
        children:
          mode === PathMode.Append ? [...mrow.children, CURSOR] : [CURSOR, ...mrow.children],
      });
    }
  };

const setCursor = (formula: FormulaContent, path: Path, mode: PathMode): FormulaContent => {
  if (path.length > 0) {
    const flowResult = omit(
      flow([
        withFormula(markCursor),
        withFormula(setCursorInMathContent(path, mode)),
        withFormula(removeMarkedCursor),
        splitBases, // sanitizes MSUB/MSUP elements, if we added a cursor to the base
        concatNumbers,
        concatBases,
        sanitizeBases,
      ])(formula) as FormulaContent,
      ERROR_MSG
    );
    return flowResult;
  }
  return {
    ...omit(formula, ERROR_MSG),
    content: mode === PathMode.Append ? [...formula.content, CURSOR] : [CURSOR, ...formula.content],
  };
};

export const formulaReducer = handleActions<
  FormulaContent,
  Actions.FormulaActionPayload | Direction
>(
  {
    [SELECT_CONTENT]: (formula) =>
      hasCursor(formula.content)
        ? formula // do not set the cursor, if there's already one inside (in case we clicked inside)
        : setCursor(
            omit(formula, 'selectDirection'),
            [],
            formula.selectDirection && formula.selectDirection === Direction.Right
              ? PathMode.Insert
              : PathMode.Append
          ),
    [Actions.ERROR]: (formula, { payload }: Action<string>) => {
      const errorMessage = payload && { errorMessage: { i18nKey: payload } };
      return { ...formula, ...errorMessage };
    },
    [Actions.TOGGLE_KEYBOARD_PAGE]: (formula, { payload }: Action<number>) =>
      isNil(payload)
        ? formula
        : {
            ...formula,
            tool: {
              ...(formula.tool as KeyboardTool),
              selectedPage: payload,
            },
          },
    [Actions.SET_CURSOR]: (formula, { payload }: Action<Actions.PathActionPayload>) =>
      isNil(payload)
        ? omit(formula, ERROR_MSG)
        : setCursor(omit(formula, ERROR_MSG), payload.path, payload.mode),
    [DESELECT_CONTENT]: (formula) => processFormula(removeCursor, false)(omit(formula, ERROR_MSG)),
    [SET_SEVERITY]: (formula, { payload }: Action<Severity>) => ({
      ...formula,
      severity: payload,
    }),
    [Actions.CURSOR_RIGHT]: (formula) => process(moveCursorRight, false)(omit(formula, ERROR_MSG)),
    [Actions.CURSOR_LEFT]: (formula) => process(moveCursorLeft, false)(omit(formula, ERROR_MSG)),
    [Actions.BACKWARD_DELETE]: (formula) =>
      processFormula(deleteContentLeft)(omit(formula, ERROR_MSG)),
    [Actions.FORWARD_DELETE]: (formula) =>
      processFormula(deleteContentRight)(omit(formula, ERROR_MSG)),
    [Actions.ENTER_NUMBER]: ifAllowed(enterToken),
    [Actions.ENTER_IDENTIFIER]: ifAllowed(enterToken),
    [Actions.ENTER_INVALID_IDENTIFIER]: notAllowed(enterToken),
    [Actions.ENTER_CHAR]: ifAllowed(enterToken),
    [Actions.ENTER_OPERATOR]: ifAllowed(enterToken),
    [Actions.ENTER_BRACKET]: ifAllowed(enterToken),
    [Actions.ENTER_FRAC]: ifAllowed(processFormula(enterMFrac)),
    [Actions.ENTER_SUB]: ifAllowed(processFormula(enterBasefulElement(MSUB(MROW(), CURSOR)))),
    [Actions.ENTER_SUP]: ifAllowed(processFormula(enterBasefulElement(MSUP(MROW(), CURSOR)))),
    [Actions.ENTER_SQR]: ifAllowed(
      processFormula(enterBasefulElement(MSUP(MROW(), MN('2')), true))
    ),
    [Actions.ENTER_ROOT]: ifAllowed(processFormula(enterMTagAtCursor(MROOT(MROW(), CURSOR)))),
    [Actions.ENTER_SQRT]: ifAllowed(processFormula(enterMTagAtCursor(MSQRT(CURSOR)))),
    [Actions.ENTER_ABS]: ifAllowed(processFormula(enterMTagAtCursor(MABS(CURSOR)))),
    [Actions.ENTER_MROW]: ifAllowed(enterMRow),
    [Actions.ENTER_EQUALS]: ifAllowed(enterToken),
  },
  FORMULA_INTERACTIVE_CONTENT()
);
