import classNames from 'classnames';
import { LockLargeSolid } from '../../../../components/icons';
import * as React from 'react';
import { StepStatus } from '../../../../types';

import styles from './StepHeader.scss';

export type StepHeaderProps = {
  status?: StepStatus;
  stepIndex: number;
  title?: string;
  inactive?: boolean;
};

export const StepHeader: React.FC<StepHeaderProps> = ({ status, stepIndex, title, inactive }) => {
  // get matching letter for step-index 0:A, 1:B, 2:C, ...
  const letter = String.fromCharCode(65 + stepIndex);
  // from the logs we know this component sometimes get's an undefined title
  // we don't want to append ` - ` when the title is undefined or an empty string
  // (or when step is locked)
  const stepTitle = status === StepStatus.locked || !title ? letter : `${letter} - ${title}`;
  const headerClassNames = classNames(
    styles.header,
    status && styles[status],
    inactive && styles.inactive
  );

  return (
    <div className={headerClassNames}>
      <span className={styles.title}>{stepTitle}</span>
      <div className={styles.spacer} />
      {status === StepStatus.locked && <LockLargeSolid className={styles.lock} />}
    </div>
  );
};

StepHeader.displayName = 'StepHeader';
