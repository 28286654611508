import { type ActionMeta, createAction } from 'redux-actions';
import { type Severity } from '@bettermarks/umc-kotlin';
import {
  type Direction,
  type EditorMode,
  type ShouldEnrich,
  ShouldEnrichKind,
} from '@bettermarks/gizmo-types';
import { type ShortcutsMap } from '../keyboard';
import { identity } from 'lodash/fp';
import { type AnyAction, hasPayload } from './types';

export const enrichMetaCreator = (): ShouldEnrich => ({
  shouldEnrich: ShouldEnrichKind.justEnrich,
});

export const _GIZMO_ACTION = 'GIZMO_ACTION';

export const GIZMO_ACTION = (actionType: string) => `${_GIZMO_ACTION}:${actionType}`;

/**
 * Simplifies the action type by removing the `GIZMO_ACTION:` prefix.
 * @returns undefined if the action type is not prefixed in that way.
 */
export const gizmoActionType = (gizmoActionType: string) => {
  const [actionNamespace, actionType] = gizmoActionType.split(':');
  return !!actionType && actionNamespace === _GIZMO_ACTION ? actionType : undefined;
};

export type GizmoActionOptions = ShouldEnrich &
  Partial<{
    /**
     * Used to specify that this action is not an undo-breakpoint.
     */
    skipUndo: true;
  }> & { triggeredByUser?: boolean };

export type GizmoActionMeta = GizmoActionOptions & {
  gizmoId: string;
};

export type GizmoAction<P = void> = ActionMeta<P, GizmoActionMeta>;

/**
 * Create a redux action that would be dispatched to a specified node in the contentDict.
 * The refId is passed in the meta field of the action ('gizmoId').
 * The third optional parameter is set to true if the action should trigger an enrichment.
 *
 * Note: From here on and through the reducers the payload type will be ```any```. The correct type
 * of our payload is ensured in the action creators on the one side. On the other side you have to
 * declare an action type on the action handler. In between the reducer logic doesn't care about
 * this and thus we should not complicate the type definitions here.
 *
 * To actually get the connection between creator and handler, we have to define the action type,
 * ensure the creator has this type and use the type in the handler:
 *
 */
export const gizmoAction = (
  action: AnyAction,
  gizmoId: string,
  gizmoActionOptions?: GizmoActionOptions
): GizmoAction => ({
  payload: hasPayload(action) ? action.payload : undefined,
  type: GIZMO_ACTION(action.type),
  meta: { gizmoId, ...gizmoActionOptions },
});

export interface AutoSizePayload {
  width: number;
  height: number;
  left: number;
  top: number;
  targetGroup?: number;
}

// for documentation see `./gizmoActions.md`

export const SELECT_CONTENT: 'SELECT_CONTENT' = 'SELECT_CONTENT';
export const selectContent = createAction(SELECT_CONTENT);

export const SELECT_CONTENT_DIRECTION: 'SELECT_CONTENT_DIRECTION' = 'SELECT_CONTENT_DIRECTION';
export const selectContentDirection = createAction<Direction>(SELECT_CONTENT_DIRECTION);

export const DESELECT_CONTENT: 'DESELECT_CONTENT' = 'DESELECT_CONTENT';
export const deselectContent = createAction(DESELECT_CONTENT);

export const RESET_SHOULD_MOVE_CURSOR: 'RESET_SHOULD_MOVE_CURSOR' = 'RESET_SHOULD_MOVE_CURSOR';
export const resetShouldMoveCursor = createAction(RESET_SHOULD_MOVE_CURSOR);

export const MOVE_LEFT: 'MOVE_LEFT' = 'MOVE_LEFT';
export const moveLeft = createAction(MOVE_LEFT);

export const MOVE_RIGHT: 'MOVE_RIGHT' = 'MOVE_RIGHT';
export const moveRight = createAction(MOVE_RIGHT);

export const SET_SEVERITY: 'SET_SEVERITY' = 'SET_SEVERITY';
export const setSeverity = createAction<Severity>(SET_SEVERITY);

export const SET_MODE: 'SET_MODE' = 'SET_MODE';
export const setMode = createAction<EditorMode>(SET_MODE);

/**
 * Action to select the first interactive child. The payload indicates, if you want to select it,
 * even if it's not initially unselected (formula).
 */
export const SELECT_FIRST_INTERACTIVE_CHILD = 'SELECT_FIRST_INTERACTIVE_CHILD';
export const selectFirstInteractiveChildAction = createAction<boolean, ShouldEnrich, boolean>(
  SELECT_FIRST_INTERACTIVE_CHILD,
  identity,
  enrichMetaCreator
);

export const REGISTER_SHORTCUTS = 'REGISTER_SHORTCUTS';
export const registerShortcuts = createAction<ShortcutsMap>(REGISTER_SHORTCUTS);

export type UnregisterShortcutsPayload = {
  shortcuts: ShortcutsMap;
  gizmoId?: string;
};

export const UNREGISTER_SHORTCUTS = 'UNREGISTER_SHORTCUTS';
export const unregisterShortcuts = createAction<UnregisterShortcutsPayload>(UNREGISTER_SHORTCUTS);

export const DND_AUTO_SIZE = 'DND_AUTO_SIZE';
export const dndAutoSize = createAction<AutoSizePayload>(DND_AUTO_SIZE);

export type ShowFEMPayload = {
  id: string;
  search: string;
  onCloseFromWhiteboard?: () => void;
};
export const SHOW_FEM = 'SHOW_FEM';
export const showFEM = createAction<ShowFEMPayload>(SHOW_FEM);
