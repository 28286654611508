import { connect } from 'react-redux';
import { DrawerSwitch, type DrawerSwitchProps } from './DrawerSwitch';
import { AsideDrawerSwitch } from './AsideDrawerSwitch';
import { getAvailableToggles } from '../getAvailableToggles';
import { isDragSourceDrawerAvailable } from '../isDragSourceDrawerAvailable';
import { ToolbarElements } from '@bettermarks/gizmo-types';
import { ApplicationState } from '../../../../../types';

const mapStateToProps = (state: ApplicationState): DrawerSwitchProps => ({
  availableDrawers: getAvailableToggles(
    {
      [ToolbarElements.dnd]:
        ApplicationState.currentStepIncludesDragSource(state) &&
        isDragSourceDrawerAvailable(
          state.runtimeState.availableWidth,
          state.runtimeState.availableHeight
        ),
    },
    ApplicationState.toToolbarTools.get(state) as ToolbarElements[]
  ),
  openDrawer: ApplicationState.toToolbarOpenDrawerName.get(state) as Nullable<ToolbarElements>,
  contentHeight: state.runtimeState.availableHeight,
});

export const drawerSwitchConnect = connect(mapStateToProps);

export const DrawerSwitchContainer = drawerSwitchConnect(DrawerSwitch);
export const AsideDrawerSwitchContainer = drawerSwitchConnect(AsideDrawerSwitch);
