import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { type ModeSelectorProps } from '../ModeSelector';
import styles from './ModeSelectorItemWrapper.scss';
import { useMaybeWhiteboardContext } from '../../../../../whiteboard/whiteboard-context';
import classNames from 'classnames';

const ModeSelectorItemsWrapper: React.FC = ({ children }) => {
  const whiteboardContext = useMaybeWhiteboardContext();
  return (
    <div
      className={classNames(styles.gridContainer, whiteboardContext && styles.gridContainerSidebar)}
      data-cy="mode-selector"
    >
      {children}
    </div>
  );
};

export const ModeSelectorItemWrapper: React.FC<{ mode: string }> = ({ mode, children }) => {
  const [t] = useTranslation();
  const whiteboardContext = useMaybeWhiteboardContext();
  return (
    <div className={`bm-font-roboto-condensed ${styles.gridItem}`}>
      <div
        className={classNames(
          styles.gridItemButton,
          whiteboardContext && styles.gridItemButtonSidebar
        )}
      >
        {children}
      </div>
      <span className={styles.gridItemLabel}>{t(`editors:geo.toolbar.tool.${mode}`)}</span>
    </div>
  );
};

export const injectModeSelectorToolWrappers =
  (InnerComponent: React.ComponentType<any>) => (props: ModeSelectorProps) =>
    (
      <InnerComponent
        {...props}
        modeItemWrapper={ModeSelectorItemWrapper}
        modeItemsWrapper={ModeSelectorItemsWrapper}
      />
    );
