import {
  type AngleConfiguration,
  AngleLineType,
  AngleType,
  AxisDirection,
  AxisNullPosition,
  CAP_NARROW_ARROW,
  type CircleConfiguration,
  type CircleDecoration,
  CIRCLES,
  ContentColor,
  DISC,
  type GeoContent,
  type GeoContentPersistProps,
  type GeoDecoration,
  GeoEditorMode,
  type GeoScene,
  type IdCoords,
  type IntervalConfiguration,
  IntervalType,
  INVISIBLE,
  LINE,
  type GeoLineDecoration as LineDecoration,
  LineStyle,
  LineWeight,
  METRICS_INTERVAL_X,
  type ParallelsConfiguration,
  type PointDecoration,
  type PreviewAngle,
  type PreviewCircle,
  type PreviewInterval,
  type PreviewLine,
  RAY,
  RS,
  SEGMENT,
  SnapType,
  SPECIAL,
  STRAIGHTLINE,
  VECTOR,
} from '@bettermarks/gizmo-types';
import { getStrokeWidth } from './helper';

export const SQRT2H = 0.7071067812; // the well known SQRT(2)/2 often used!
// the number of pixels (in x-direction) for 1 tickValueInterval unit
export const PIXEL_PER_XTICK = 27;
// the number of pixels (in y-direction) for 1 tickValueInterval unit
export const PIXEL_PER_YTICK = 27;

export const POINT_RADIUS = 3;
export const POINT_PLUS_LENGTH = 6;
// The 'snap radius' is now based on the average of the 'pixel-per-tick' values (50 percent)
export const SNAP_RADIUS = ((PIXEL_PER_XTICK + PIXEL_PER_YTICK) / 2) * 0.5; // Pixel!
export const SQR_SNAP_RADIUS = SNAP_RADIUS * SNAP_RADIUS; // Pixel!

export const AXIS_MINOR_TICKS_PIXEL = 5; // Pixelsize for minor axis ticks
export const AXIS_MAJOR_TICKS_PIXEL = 7; // Pixelsize for major axis ticks
export const AXIS_ARROW_PIXEL = 8; // Pixelsize for axis arrows (length)
export const AXIS_ARROW_STRETCH_FACTOR = 1.6; // the proportion of width and height of the arrow.
export const X_AXIS_TICK_LABEL_OFFSET = 7; // Tick label offset for x-axis
export const Y_AXIS_TICK_LABEL_OFFSET = -9; // Tick label offset for y-axis
export const X_AXIS_LABEL_OFFSET = 6; // Axis label offset for x-axis
export const Y_AXIS_LABEL_OFFSET = -2; // Axis label offset for y-axis
export const X_ADVANCED_AXIS_DEFAULT_LABEL_HEIGHT = 16;
export const X_SIMPLE_AXIS_DEFAULT_LABEL_HEIGHT = 16;
export const Y_ADVANCED_AXIS_DEFAULT_LABEL_WIDTH = 40;
export const Y_SIMPLE_AXIS_DEFAULT_DIGIT_WIDTH = 6.5;
export const Y_ADVANCED_AXIS_DEFAULT_DIGIT_WIDTH = 7;
export const ACCORDION_WIDTH_PIXEL = 8;
export const ACCORDION_HEIGHT_PIXEL = 20;
export const DEFAULT_LABEL_DISTANCE = 1; // Default label distance without unit, just as a 'factor'
export const LABEL_DISTANCE_PIXEL = 8; // Label distance in Pixels according to factor 1

export const SVG_BORDER_EXTENSION = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

export const SVG_CANVAS = 'SVGCanvas';
export const BORDER_RECT = 'borderRect';
export const TEMP_POINT = 'TEMP_POINT';

export const INV_POINT_PREFIX = 'invP';

export const GEO_DEFAULT_PERSIST_PROPS: GeoContentPersistProps = {
  geoContentMap: {},
  points: [],
  invisiblePoints: [],
  labels: [],
  beziers: [],
  dynamicBeziers: [],
  circles: [],
  rays: [],
  segments: [],
  straightlines: [],
  vectors: [],
  intervals: [],
  readinghelps: [],
};

export const GEO_DEFAULT_SCENE: GeoScene = {
  snappingGrid: [],
  points: [],
  rays: [],
  segments: [],
  straightlines: [],
  vectors: [],
  invisibleSnapPoints: [],
  circles: [],
  snapType: SnapType.grid,
};

/*
const DEFAULT_CONTENT = (scalable = false): GeoContent => ({
  ...GEO_GIZMO_DEFAULT_CONTENT,
  $id: '$id',
  scalable,
  unscaledHeight: 554,
  unscaledWidth: 554
});
*/
// FIXME: should be a method returning `Defaults<GeoContent, 'scalable'>`, but many things use this
export const GEO_GIZMO_DEFAULT_CONTENT: GeoContent = {
  ...GEO_DEFAULT_PERSIST_PROPS,
  $: SPECIAL,
  $renderStyle: RS.METRICS_SYSTEM,
  $id: 'id',
  uniqueId: '0',
  scale: 1,
  configuration: {
    display: {
      width: 20,
      height: 20,
      cx: 0,
      cy: 0,
      xMin: -10,
      xMax: 10,
      yMin: -10,
      yMax: 10,
    },
    tickValueInterval: {
      x: 1,
      y: 1,
    },
    showBorder: true,
    showNullLabel: false,
    snapType: SnapType.grid,
    nullLabelPosition: AxisNullPosition.None,
    defaultDecorations: {},
    subSnapping: {
      x: 1,
      y: 1,
    },
    labelValues: {},
    autoLabeling: {},
    toolConfiguration: {},
    borderExtension: false,
  },
  matrix: [27, 0, 0, -27, 271, 271],
  gridWidth: 542,
  gridHeight: 542,
  totalWidth: 554,
  totalHeight: 554,
  invisiblePoints: [],
  readinghelps: [],
  unscaledWidth: 554,
  unscaledHeight: 554,
  scalable: true,
  selectedMode: GeoEditorMode.NOT_INTERACTIVE,
};

// a 'snapping coord' denoting, that we use continuous snapping!
export const CONTINUOUS_SNAPPING_MARKER = { x: NaN, y: NaN };

export const DEFAULT_POINT_COLOR = ContentColor.BM_STEELBLUE;
export const DEFAULT_INV_POINT_COLOR = ContentColor.BM_BLACK;
export const DEFAULT_LINE_COLOR = ContentColor.BM_BLACK;
export const DEFAULT_PREVCIRCLE_COLOR = ContentColor.BM_LIGHT_GREY;
export const DEFAULT_PREVLINE_COLOR = ContentColor.BM_LIGHT_GREY;
export const DEFAULT_PREVPOINT_COLOR = ContentColor.BM_LIGHT_GREY;
export const DEFAULT_PREVANGLE_LOW_COLOR = ContentColor.BM_LIGHT_BLUE;
export const DEFAULT_PREVANGLE_HIGH_COLOR = '#DD7711'; // $c-orange-700
export const DEFAULT_PREVINTERVAL_COLOR = ContentColor.BM_GREY;
export const DEFAULT_FILL_COLOR = ContentColor.BM_TRANSPARENT;
export const DEFAULT_FILL_TRANSPARENCY = 0.5;
export const DEFAULT_CIRCLE_COLOR = ContentColor.BM_BLACK;
export const DEFAULT_CIRCLE_LABEL_COLOR = ContentColor.BM_BLACK;
export const DEFAULT_STROKE_WIDTH = 2;
export const DEFAULT_INTERVAL_RECT_COLOR = ContentColor.BM_GREY;
export const DEFAULT_INTERVAL_COLOR = ContentColor.BM_BLACK;
export const DEFAULT_ANGLE_ARC_COLOR = ContentColor.BM_BLACK;
export const DEFAULT_ANGLE_ARC_FILL_COLOR = ContentColor.BM_TRANSPARENT;
export const DEFAULT_ANGLE_ARC_FILL_OPACITY = 0.4;
export const DEFAULT_ANGLE_RADIUS = 1;
export const HIGHLIGHT_DISPLAYED_PIXELLENGTH_LIMIT = 24; // display limit for highlighting lines.
export const RIGHT_ANGLE_POINT_RADIUS = 1;
export const RIGHT_ANGLE_SQUARE_SIZE_FACTOR = 1.2; // 'Stretching factor for 'square right angles'.
export const MIN_ANGLE_FOR_LABEL = 10; // in degs
export const MIN_ANGLE_DRAWN = 2; // min angle that can be drawn by the angle line tool in degs.
export const EPS = 1e-7;
export const LINES = [RAY, SEGMENT, STRAIGHTLINE, VECTOR, LINE];

export const DEFAULT_POINT_DECORATION: PointDecoration = {
  color: DEFAULT_POINT_COLOR,
  lineWeight: LineWeight.medium,
  pointStyleType: DISC,
};

/**
 * When `decoration="defaultDecoration"` in content, color should
 * be BM_BLUE and not BM_STEELBLUE
 */
export const CONTENT_DEFAULT_POINT_DECORATION: PointDecoration = {
  ...DEFAULT_POINT_DECORATION,
  color: ContentColor.BM_BLUE,
};

export const DEFAULT_LINE_DECORATION: LineDecoration = {
  color: DEFAULT_LINE_COLOR,
  lineStyle: LineStyle.solid,
  lineWeight: LineWeight.medium,
};

export const DEFAULT_PREVLINE_DECORATION: LineDecoration = {
  ...DEFAULT_LINE_DECORATION,
  color: DEFAULT_PREVLINE_COLOR,
};

export const DEFAULT_PREVPOINT_DECORATION: PointDecoration = {
  color: DEFAULT_PREVPOINT_COLOR,
  lineWeight: LineWeight.medium,
};

export const DEFAULT_VECTOR_DECORATION: LineDecoration = {
  ...DEFAULT_LINE_DECORATION,
  lineCapStyleTop: CAP_NARROW_ARROW,
};

export const DEFAULT_BEZIER_DECORATION: LineDecoration = {
  ...DEFAULT_LINE_DECORATION,
  fillColor: DEFAULT_FILL_COLOR,
  fillTransparency: DEFAULT_FILL_TRANSPARENCY,
};

export const DEFAULT_CIRCLE_DECORATION: CircleDecoration = {
  ...DEFAULT_POINT_DECORATION,
  type: 'circle',
  color: DEFAULT_CIRCLE_COLOR,
  fillColor: 'none',
  lineWeight: LineWeight.medium,
  borderStyle: LineStyle.solid,
  strokeDasharray: 'none',
  fillTransparency: DEFAULT_FILL_TRANSPARENCY,
};

export const DEFAULT_PREVCIRCLE_DECORATION: CircleDecoration = {
  ...DEFAULT_CIRCLE_DECORATION,
  color: DEFAULT_PREVCIRCLE_COLOR,
};

export const DEFAULT_INTERVAL_DECORATION: GeoDecoration = {
  color: DEFAULT_INTERVAL_COLOR,
};

export const DEFAULT_INTERVAL_RECT_DECORATION: GeoDecoration = {
  color: DEFAULT_INTERVAL_RECT_COLOR,
};

export const DEFAULT_PREVINTERVAL_DECORATION: GeoDecoration = {
  color: DEFAULT_PREVINTERVAL_COLOR,
};

export const DASHED_LINE_DASH_LENGTH = 5;
export const DASH_DOT_LINE_DASH_LENGTH = 4;
export const DASH_DOT_LINE_DOT_RADIUS = 1;
// The amount of pixels to shorten a line in order to put an arrow on top so that line artefacts
// are no longer visible. (for details, see the MarkerTriangle Component)
export const CAP_ARROW_REF_PIXEL = 8;

export const DEFAULT_PREVLINE: PreviewLine = {
  p1: undefined,
  p2: undefined,
  visible: false,
  decoration: DEFAULT_PREVLINE_DECORATION,
};

export const DEFAULT_PREVANGLE: PreviewAngle = {
  coords: { x: NaN, y: NaN },
  startAngle: 0,
  endAngle: 0,
  hasAngleLegs: true,
  radius: DEFAULT_ANGLE_RADIUS,
  decoration: DEFAULT_PREVLINE_DECORATION,
  visible: false,
};

export const DEFAULT_PREVCIRCLE: PreviewCircle = {
  coords: { x: NaN, y: NaN },
  radius: 0,
  decoration: DEFAULT_PREVCIRCLE_DECORATION,
  visible: false,
};

export const DEFAULT_PREVINTERVAL: PreviewInterval = {
  direction: AxisDirection.horizontal,
  min: NaN,
  max: NaN,
  intervalType: IntervalType.none,
  visible: false,
  snapHighlight: 'max',
};

export const DEFAULT_CIRCLE_CONFIGURATION: CircleConfiguration = {
  snapInterval: 1,
  unit: '',
};

export const DEFAULT_IDCOORDS: IdCoords = {
  coords: {
    x: NaN,
    y: NaN,
  },
  id: '',
};

export const DEFAULT_ANGLE_CONFIGURATION: AngleConfiguration = {
  lineType: AngleLineType.RAY,
  snapAngle: 1,
  type: AngleType.BOTH_360,
  showPermanent: 'NONE',
};

export const DEFAULT_PARALLELS_CONFIGURATION: ParallelsConfiguration = {
  unit: 'cm',
};

export const DEFAULT_INTERVAL_CONFIGURATION: IntervalConfiguration = {
  scene: GEO_DEFAULT_SCENE,
  type: METRICS_INTERVAL_X,
  showDialog: true,
  decoration: {
    color: ContentColor.BM_BLUE,
  },
  helperLine: {
    decoration: {
      color: ContentColor.BM_GREY,
      lineStyle: LineStyle.solid,
      lineWeight: LineWeight.thin,
    },
    visible: true,
  },
};

export const BRACKET_HEIGHT = 20;
export const MIN_BRACKET = 'min';
export const MAX_BRACKET = 'max';

export const DEFAULT_LEFT_OPEN_BRACKET = ']';
export const DEFAULT_RIGHT_OPEN_BRACKET = '[';
export const DEFAULT_LEFT_CLOSED_BRACKET = '[';
export const DEFAULT_RIGHT_CLOSED_BRACKET = ']';

export const DEFAULT_BEZIER_TRANSLATION = {
  x: 0,
  y: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

export const DEFAULT_ANGLE_DECORATION: GeoDecoration = {
  color: DEFAULT_ANGLE_ARC_COLOR,
  fillColor: DEFAULT_ANGLE_ARC_FILL_COLOR,
};

export const PREVANGLE_LOW_DECORATION: GeoDecoration = {
  ...DEFAULT_ANGLE_DECORATION,
  color: DEFAULT_PREVANGLE_LOW_COLOR,
};

export const PREVANGLE_HIGH_DECORATION: GeoDecoration = {
  ...DEFAULT_ANGLE_DECORATION,
  color: DEFAULT_PREVANGLE_HIGH_COLOR,
};

export const DEFAULT_ANGLE_LEG_DECORATION: LineDecoration = {
  ...DEFAULT_LINE_DECORATION,
  lineStyle: LineStyle.dashed,
};

export const RIGHT_ANGLE_NOTATION_SQUARE = 'square';
export const RIGHT_ANGLE_NOTATION_DOT = 'arc-with-dot';

export const DEFAULT_TOUCH_STATE = {
  currentScale: 1,
  lastScale: 1,
  inPinch: false,
  currentPan: {
    x: 0,
    y: 0,
  },
  lastPan: {
    x: 0,
    y: 0,
  },
  inPan: false,
};

export const DEFAULT_SNAP_HIGHLIGHT_OBJECTS = {
  [CIRCLES]: [],
  [RAY]: [],
  [SEGMENT]: [],
  [STRAIGHTLINE]: [],
  [VECTOR]: [],
  [INVISIBLE]: [],
};

// calculate the maximum size of a point radius
// (that shall be displayed at the border without being cut off)
export const MAX_POINT_RADIUS = POINT_RADIUS + getStrokeWidth({ lineWeight: LineWeight.thick });
