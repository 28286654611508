import { type FElement, type ParametricFunction } from '@bettermarks/gizmo-types';
import { importFunction, importFunctionParameters } from './helper';

export const importDynamicFormula = (
  xml: FElement,
  formula = xml.findChildTag('mrow').findChildTag('configuration').findChildTag('formula')
): ParametricFunction => ({
  ...importFunction(formula.findChildTag('functionName')),
  parameters: importFunctionParameters(formula.findChildTag('parameters')),
});
