import {
  type Coords,
  type LabelableObject,
  type PreviewAngle,
  type PreviewCircle,
  type PreviewInterval,
  type PreviewLine,
  type PreviewPoint,
  type SnapPoint,
} from '../types';
import {
  type GeoContentPersistProps,
  type LabelType,
  type LineHighlight,
  type LineObjectType,
  type PointHighlight,
  type ToolValueLabel,
} from '../data/types';
import { type ContentReference } from '../../../xml-converter/core';
import { type AdditionalButtonProps } from '@seriesplayer/common-ui';

export interface GeoCallbacks {
  readonly onPersistLocalState: (props: GeoContentPersistProps) => void;
  readonly onScalingDown?: (scale: number) => void;
  readonly enableSmallScreenViewMode?: () => void;
  readonly enableLargeScreenViewEditMode?: () => void;
}

export type GeoInteractiveBaseState = {
  persistProps: GeoContentPersistProps;
  snapPoints: SnapPoint[];
  toolValueLabels?: ToolValueLabel[];
};

export type GeoInteractivePreviewState = {
  prevPoints: PreviewPoint[];
  prevLine: PreviewLine;
};

export type GeoAddLineBaseState = GeoInteractiveBaseState & GeoInteractivePreviewState;
export type GeoAddLineState = GeoAddLineBaseState & {
  labels: GeoAddLabelBaseState;
};

export type GeoAddLabelBaseState = {
  labelingObjectId: string;
  labelingObjectType: LabelableObject;
  labelList: ReadonlyArray<ContentReference>;
  pickerList: ReadonlyArray<ContentReference>;
  activeIndex: { [key: string]: number };
  active: boolean;
  onInitialLabelOver: boolean;
  initial: boolean;
  mouseScreenCoords?: Coords;
  t?: number;
};

export type GeoAddAngleLineState = GeoInteractiveBaseState & {
  selectedPointId: string;
  prevLine: PreviewLine;
  prevPoints: PreviewPoint[];
  prevAngles: PreviewAngle[];
  dragging: boolean;
  highlight?: PointHighlight;
};

export type GeoAddCircleState = GeoInteractiveBaseState & {
  prevCircle: PreviewCircle;
};

export type GeoAddPointState = GeoInteractiveBaseState & {
  prevPoints: PreviewPoint[];
  labels: GeoAddLabelBaseState;
};

export type GeoMoveBeziersState = GeoInteractiveBaseState & {
  selectedObjectId: string;
  startPos: Coords;
};

export type GeoIntervalState = GeoInteractiveBaseState & {
  start: number;
  end: number;
  limitPickerOpen: [boolean | undefined, boolean | undefined];
  limitOpen: [boolean | undefined, boolean | undefined];
  prevInterval: PreviewInterval;
  dragging: boolean;
  helperLine: PreviewLine;
};

export type GeoParallelsState = GeoInteractiveBaseState & {
  selectedLineType: LineObjectType;
  selectedDirection: Coords;
  selectedLine: [Coords, Coords];
  prevLines: ParallelToolPreviewLines;
  dragging: boolean;
  highlight?: LineHighlight;
};

export type GeoPerpendicularState = GeoInteractiveBaseState & {
  selectedLineType: LineObjectType;
  selectedDirection: Coords;
  selectedLine: [Coords, Coords];
  selectedAngle: number;
  prevLine: PreviewLine;
  helperLines: PreviewLine[];
  prevAngle: PreviewAngle;
  dragging: boolean;
  highlight?: LineHighlight;
};

/* Our former 'labelingObjectId' in the 'LabelStepperProps' can now
   be replaced by some 'coordinates object' alternatively! */
export const enum LabelingObjectRefType {
  id = 'id',
  coords = 'coords',
}

export interface LabelingObjectID {
  type: LabelingObjectRefType.id;
  id: string;
}

export interface LabelingObjectCoords {
  type: LabelingObjectRefType.coords;
  coords: Coords;
}

export type LabelingObjectRef = LabelingObjectID | LabelingObjectCoords;

export type LabelStepperProps = {
  active: boolean;
  initialIndex: number;
  list: ReadonlyArray<ContentReference>;
  labelingObjectRef: LabelingObjectRef;
  onConfirm: (index: number, up: boolean) => void;
  onSelect?: (index: number) => void;
  additionalButton?: AdditionalButtonProps;
  noseUp?: boolean;
  customNoseOffset?: number;
  mouseScreenCoords?: Coords;
};

export type GeoSelectState = GeoInteractiveBaseState;

export type GeoColoringState = GeoInteractiveBaseState;

export const enum Hover {
  DEFAULT = 'default',
  DELETE = 'delete',
  PREVIEW = 'preview',
  SELECT = 'select',
  COLORING = 'coloring',
}

export type ParallelToolPreviewLines = {
  perpendicular: PreviewLine;
  parallel: PreviewLine;
  others: PreviewLine[];
};

export type LabelWidgetProps = {
  labelAlignTop?: boolean;
  labelType?: LabelType;
};
