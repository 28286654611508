import log from 'loglevel';
import { isEmpty, isNil } from 'lodash';
import { type Severity } from '@bettermarks/umc-kotlin';
import * as RS from '../../gizmo-utils/configuration/renderStyles';
import { MATH, PLACEHOLDER, SPECIAL } from '../../gizmo-utils/constants';
import { DEFAULT_FONT_SIZE } from '../../gizmo-utils/measure/constants';
import { type ComputedStyles, type Decoration, type LayoutMetrics } from '../../gizmo-utils/types';
import {
  type Content,
  type ContentReference,
  type FElement,
  xmlTextToList,
} from '../../xml-converter/core';
import { type KeyboardTool } from '../../types';
import { Lens } from '../../gizmo-utils/fp/lens';
import { type TFunction } from 'i18next';

export const $MI = 'mi' as const;
export const $MN = 'mn' as const;
export const $MO = 'mo' as const;
export const $MTEXT = 'mtext' as const;
export type MToken$ = typeof $MI | typeof $MN | typeof $MO | typeof $MTEXT;

export const $MCAL = 'mcal' as const;
export const $CURSOR = 'cursor' as const;
export const $MSPACE = 'mspace' as const;
export type MLeaf$ = MToken$ | typeof $MCAL | typeof $CURSOR | typeof $MSPACE;

export const $MABS = 'mabs' as const;
export const $MFENCED = 'mfenced' as const;
export const $MROW = 'mrow' as const;
export type AligningContainer$ = typeof $MABS | typeof $MFENCED | typeof $MROW;

export const $MOVER = 'mover' as const;
export const $MUNDER = 'munder' as const;
export const $MUNDEROVER = 'munderover' as const;
export const $MSUP = 'msup' as const;
export const $MSUB = 'msub' as const;
export const $MSUBSUP = 'msubsup' as const;
export type BasefulContainer$ =
  | typeof $MOVER
  | typeof $MUNDER
  | typeof $MUNDEROVER
  | typeof $MSUB
  | typeof $MSUP
  | typeof $MSUBSUP;

export const $MEXPANSION = 'mexpansion' as const;
export const $MFRAC = 'mfrac' as const;

export const $MROOT = 'mroot' as const;
export const $MSQRT = 'msqrt' as const;

export const $MTABLE = 'mtable' as const;

export type PureMath$ =
  | MLeaf$
  | AligningContainer$
  | BasefulContainer$
  | typeof $MEXPANSION
  | typeof $MFRAC
  | typeof $MROOT
  | typeof $MSQRT;

export const BASE = 'base' as const;
export const INDEX = 'index' as const;
export const DENOMINATORFACTOR = 'denominatorFactor' as const;
export const LHS_FRACTION = 'lhsFraction' as const;
export const NUMERATORFACTOR = 'numeratorFactor' as const;
export const OPERATOR = 'operator' as const;
export const OVERSCRIPT = 'overscript' as const;
export const RHS_FRACTION = 'rhsFraction' as const;
export const SUBSCRIPT = 'subscript' as const;
export const SUPERSCRIPT = 'superscript' as const;
export const UNDERSCRIPT = 'underscript' as const;

export type PureMathContent =
  | Cursor
  | MAbs
  | MCalibrate
  | MExpansion
  | MFenced
  | MFrac
  | MOver
  | MRoot
  | MRow
  | MSpace
  | MSqrt
  | MSub
  | MSubSup
  | MSup
  | MToken
  | MUnder
  | MUnderOver;
export type MathContent = PureMathContent | ContentReference;

export type PathElement = string | number;
export type Path = PathElement[];

export interface MDecoratable {
  decoration?: Decoration;
  dynamicDecoration?: string;
  computedStyles: ComputedStyles;
}

export interface AlignedRow {
  childrenAlignments: ReadonlyArray<number>;
}
export interface AlignedRowWithChildren extends AlignedRow {
  children: MathContent[];
}
export type MContent<Kind = PureMath$> = {
  $?: Kind;
  $refid?: string;
};
export type AlignableRow = AlignedRowWithChildren & MContent<AligningContainer$>;

export const createAlignableRow = (
  children: MathContent[],
  $: AligningContainer$ = $MROW
): AlignableRow => ({ $, children, childrenAlignments: [] });

export function isPureMathContent(arg: MContent | undefined): arg is PureMathContent {
  // eslint-disable-next-line no-prototype-builtins
  return !!arg && arg.hasOwnProperty('$');
}

export function isContentReference(arg: unknown): arg is ContentReference {
  // eslint-disable-next-line no-prototype-builtins
  return !!arg && (arg as ContentReference).hasOwnProperty('$refid');
}

export const isMathContent = (arg: unknown): arg is MathContent =>
  isPureMathContent(arg as MathContent) || isContentReference(arg);

export const isContainer = (content: MContent): boolean =>
  isPureMathContent(content) &&
  (content.$ === $MSUB ||
    content.$ === $MSUP ||
    content.$ === $MSUBSUP ||
    content.$ === $MABS ||
    content.$ === $MROOT ||
    content.$ === $MSQRT ||
    content.$ === $MFRAC);

export const isCursor = (content: MContent): content is Cursor =>
  isPureMathContent(content) && content.$ === $CURSOR;

export type BasefulElement = MSup | MSub | MSubSup;

export const hasBase = (content: MContent): content is BasefulElement =>
  isPureMathContent(content) &&
  (content.$ === $MSUB || content.$ === $MSUP || content.$ === $MSUBSUP);

export const isMRowLike = (content: MContent): content is MRow | MFenced =>
  isMRow(content) || isMFenced(content);

/**
 * A token is empty if it's text is emtpy.
 * In rare cases this has an impact on vertical alignment.
 * @param content the token to check
 *
 * @see isEmptyContainer
 */
export const isEmptyToken = (content: MContent) => isMToken(content) && isEmpty(content.text);

/**
 * Can be used to check if an MRowLike container only contains tokens,
 * that have no text, in which case the alignment might not work as expected.
 *
 * @param content the container to check
 *
 * @see isMRowLike
 * @see isEmptyToken
 * @see http://trac.bm.loc/ticket/43276 for details
 relativeToBaseLine: isEmptyContainer(radicand) ? undefined : relativeToBaseLine
 */
export const isEmptyContainer = (content: MContent) =>
  isMRowLike(content) && content.children.every(isEmptyToken);

export const enum Restriction {
  Numbers = 'numbers',
  Letters = 'letters',
  NumbersLetters = 'numbers-letters',
  NumbersFractionbar = 'numbers-fractionbar',
  NumbersDotOverbar = 'numbers-dot-overbar',
  NumbersLettersFractionbar = 'numbers-letters-fractionbar',
  NumbersLettersDotOverbar = 'numbers-letters-dot-overbar',
}

export interface RestrictedToKeys {
  brackets?: string[];
  letters?: string[];
  numbers?: string[];
  operators?: string[];
}

export const toRestrictionType = (value: FElement) =>
  [
    Restriction.Numbers,
    Restriction.Letters,
    Restriction.NumbersLetters,
    Restriction.NumbersFractionbar,
    Restriction.NumbersDotOverbar,
    Restriction.NumbersLettersFractionbar,
    Restriction.NumbersLettersDotOverbar,
  ].find((r) => r === value.text);

export const toRestrictedToKeys = (value: FElement): RestrictedToKeys => ({
  ...value.tagsToProps(xmlTextToList(','), [], ['brackets']),
  ...value.tagsToProps(xmlTextToList(','), [], ['letters']),
  ...value.tagsToProps(xmlTextToList(','), [], ['numbers']),
  ...value.tagsToProps(xmlTextToList(','), [], ['operators']),
});

export interface FormulaConfig {
  restrictionType?: Restriction;
  restrictedToKeys?: RestrictedToKeys;
}

const formulaContentRenderStyles = new Set([
  RS.FORMULA,
  RS.TEXT,
  RS.TABLECELL_TEXT,
  RS.DIGIT,
  RS.INNER_TEXT,
]);

export const isFormulaContent = (content: Nullable<Content>): content is FormulaContent =>
  !!(content && formulaContentRenderStyles.has(content.$renderStyle));

export interface FormulaContent extends AlignedRow, Content, MDecoratable {
  content: MathContent[];
  configuration?: FormulaConfig;
  severity?: Severity;
  selected?: boolean;
  width?: number; // width in pixels (we need this to notify the user, when he hits the max)
  height?: number; // height in pixels
  placeholderLabel?: MRow;
  leftSibling?: string;
  rightSibling?: string;
  /** A signal from the FormulaReducer to the formulaSaga
   * to move the cursor to a sibling formula gizmo, if needed. */
  shouldMoveCursorToSibling?: true;
  isRoot?: boolean;
  /**
   * property to opt out of the standard conversion of flex-font-size to free-betty-font-size (+3)
   * if undefined, it is assumed to be true
   */
  withConvertedFontSize?: boolean;
}

export interface HasStroke {
  strokeWidth: number;
}

export interface HasHeight {
  height: number;
}

export interface HasAccent {
  accent?: boolean;
}
export const hasAccent = (content: MContent): boolean | undefined =>
  content && (content as HasAccent).accent;

export interface HasLineHeight {
  lineHeight: number;
}

export interface Interactable {
  interactive?: boolean;
}

/**
 * Returns true if `arg` is of type MToken.
 *
 * @see isMathLeaf to check for nodes, that doesn't contain nested math nodes.
 */
export function isMToken(arg: MContent): arg is MToken {
  if (!isPureMathContent(arg)) {
    return false;
  }
  return arg.$ === $MI || arg.$ === $MO || arg.$ === $MN || arg.$ === $MTEXT;
}
export function isMText(arg: MContent): arg is MText {
  return isMToken(arg) && arg.$ === $MTEXT;
}

export function isMO(arg: MContent): arg is MO {
  return isPureMathContent(arg) && arg.$ === $MO;
}

export function isMN(arg: MContent): arg is MN {
  return isPureMathContent(arg) && arg.$ === $MN;
}

export function isMI(arg: MContent): arg is MI {
  return isPureMathContent(arg) && arg.$ === $MI;
}

export function isMSpace(arg: MathContent, linebreak?: LinebreakKind): arg is MSpace {
  if (isPureMathContent(arg) && arg.$ === $MSPACE) {
    return linebreak ? arg.linebreak === linebreak : true;
  }
  return false;
}

export function isMFrac(arg: MContent): arg is MFrac {
  return isPureMathContent(arg) && arg.$ === $MFRAC;
}

export function isMSup(arg: MContent): arg is MSup {
  return isPureMathContent(arg) && arg.$ === $MSUP;
}

export function isMAbs(arg: MContent): arg is MAbs {
  return isPureMathContent(arg) && arg.$ === $MABS;
}

/**
 * Returns true if `arg` is of a math node type, that doesn't contain nested math nodes.
 *
 * @see isMToken to only check for type MToken
 */
export function isMathLeaf(arg: MContent): arg is MToken | MCalibrate | MSpace | Cursor {
  if (!isPureMathContent(arg)) {
    return false;
  }
  return isMToken(arg) || arg.$ === $MCAL || arg.$ === $MSPACE || arg.$ === $CURSOR;
}

export function isMRow(arg: MContent): arg is MRow {
  return isPureMathContent(arg) && arg.$ === $MROW;
}

export function isMFenced(arg: MContent): arg is MFenced {
  return isPureMathContent(arg) && arg.$ === $MFENCED;
}

export interface MTokenBase extends MDecoratable, Interactable {
  text: string;
  form?: string;
  id?: string;
  type?: string;
  isRemovedAtActivation?: true;
  dynamic?: string;
  valueSetterRefId?: string;
}

export interface MI extends MTokenBase {
  readonly $: typeof $MI;
}

export interface MN extends MTokenBase {
  readonly $: typeof $MN;
}

export interface MText extends MTokenBase {
  readonly $: typeof $MTEXT;
  translate?: boolean;
}

export interface MO extends MTokenBase, Partial<HasStroke> {
  readonly $: typeof $MO;
  stretchable?: boolean;
}

export type MToken = MI | MO | MN | MText;

export const NEWLINE = 'newline' as const;
export const GOODBREAK = 'goodbreak' as const;
// commented out NOBREAK and BADBREAK, these types are not present in our content
// export const NOBREAK = 'nobreak' as const;
// export const BADBREAK = 'badbreak' as const;

export type LinebreakKind = typeof NEWLINE | typeof GOODBREAK;
// typeof NOBREAK | typeof BADBREAK - unused linebreak type check

export function toLinebreakKind(value: string | undefined): LinebreakKind | null {
  return value === NEWLINE || value === GOODBREAK ? value : null;
  // value === NOBREAK || value === BADBREAK - unused linebreak type check
}

export interface MCalibrate extends Partial<LayoutMetrics> {
  readonly $: typeof $MCAL;
}

export interface MExpansion extends MDecoratable {
  readonly $: typeof $MEXPANSION;
  denominatorFactor: MathContent;
  lhsFraction: MathContent;
  rhsFraction: MathContent;
  numeratorFactor: MathContent;
  operator: MathContent;
}

// An empty string passed to the attributes open & close means no fence in that fenceType
export const NO_FENCE = '' as const;
export const ABSOLUTE_BAR = '|' as const;
// when we start drawing each fence then can change to character
export const ROUND_OPEN = '(' as const;
export const ROUND_CLOSE = ')' as const;
export const CURLY_OPEN = '{' as const;
export const CURLY_CLOSE = '}' as const;
export const SQUARE_OPEN = '[' as const;
export const SQUARE_CLOSE = ']' as const;
export const ANGLE_OPEN = '⟨' as const;
export const ANGLE_CLOSE = '⟩' as const;
export type ValidFenceType =
  | typeof ABSOLUTE_BAR
  | typeof ROUND_CLOSE
  | typeof ROUND_OPEN
  | typeof CURLY_OPEN
  | typeof CURLY_CLOSE
  | typeof SQUARE_OPEN
  | typeof SQUARE_CLOSE
  | typeof ANGLE_OPEN
  | typeof ANGLE_CLOSE;
export type FenceType = ValidFenceType | typeof NO_FENCE | typeof undefined;

export interface MFenced extends AlignedRowWithChildren, HasHeight, MDecoratable, Interactable {
  readonly $: typeof $MFENCED;
  open?: FenceType;
  close?: FenceType;
}

export interface MFrac extends HasStroke, MDecoratable, Interactable {
  readonly $: typeof $MFRAC;
  numerator: MRow;
  denominator: MRow;
}

export interface MOver extends MDecoratable, HasAccent {
  readonly $: typeof $MOVER;
  base: MRow;
  overscript: MRow;
}

export interface MRoot extends HasStroke, MDecoratable, Interactable {
  readonly $: typeof $MROOT;
  index: MRow;
  radicand: MRow;
}

export interface MRow extends AlignedRowWithChildren, MDecoratable, Interactable {
  readonly $: typeof $MROW;
  femLink?: string;
  placeholder?: boolean;
}

export interface MSpace extends HasLineHeight {
  readonly $: typeof $MSPACE;
  width?: number;
  height?: number;
  linebreak?: LinebreakKind;
}

export interface MSqrt extends HasStroke, MDecoratable, Interactable {
  readonly $: typeof $MSQRT;
  radicand: MRow;
}

export interface MAbs extends HasHeight, MDecoratable, Interactable {
  readonly $: typeof $MABS;
  value: MRow;
}

export interface MSup extends MDecoratable, Interactable {
  readonly $: typeof $MSUP;
  base: MRow;
  superscript: MRow;
}

export interface MSub extends MDecoratable {
  readonly $: typeof $MSUB;
  base: MRow;
  subscript: MRow;
}

export interface MSubSup extends MDecoratable {
  readonly $: typeof $MSUBSUP;
  base: MRow;
  subscript: MRow;
  superscript: MRow;
}

export interface MUnder extends MDecoratable, HasAccent {
  readonly $: typeof $MUNDER;
  base: MRow;
  underscript: MRow;
}

export interface MUnderOver extends MDecoratable, HasAccent {
  readonly $: typeof $MUNDEROVER;
  base: MRow;
  overscript: MRow;
  underscript: MRow;
}

export interface Cursor extends Partial<HasHeight> {
  readonly $: typeof $CURSOR;
  still?: boolean;
  remove?: boolean; // mark for removal (needed in the reducer)
}

/**
 * Like `[].forEach` but for `MathContent`:
 * Calls `callback` for every child of `content` (property with type `MathContent`).
 *
 * The using code doesn't need to know which kind of `MathContent` `content` is
 * or which properties contain children.
 *
 * To traverse a tree you need to write a recursive function that conditionally
 * uses itself to map/forEach over the children of a node (depth first)
 * or iterates over the result of map/forEach (breadth first).
 * For an example of depth first approach look at `mathContentEnricher`.
 *
 * @param {MathContent} content
 * @param {(child: MathContent, key: (string | number)) => void} callback
 * @see mapMathChildren
 */
/* eslint-disable-next-line complexity*/
export const forEachMathChild = (
  content: MathContent,
  callback: (child: MathContent, key: string | number) => void
) => {
  if (isPureMathContent(content)) {
    if (!isMathLeaf(content)) {
      switch (content.$) {
        case $MFRAC:
          callback(content.denominator, 'denominator');
          callback(content.numerator, 'numerator');
          break;
        case $MOVER:
          callback(content.base, 'base');
          callback(content.overscript, 'overscript');
          break;
        case $MROOT:
          callback(content.index, 'index');
          callback(content.radicand, 'radicand');
          break;
        case $MSQRT:
          callback(content.radicand, 'radicand');
          break;
        case $MABS:
          callback(content.value, 'value');
          break;
        case $MEXPANSION:
          callback(content.numeratorFactor, NUMERATORFACTOR);
          callback(content.lhsFraction, LHS_FRACTION);
          callback(content.operator, OPERATOR);
          callback(content.rhsFraction, RHS_FRACTION);
          callback(content.denominatorFactor, DENOMINATORFACTOR);
          break;
        case $MSUB:
          callback(content.base, BASE);
          callback(content.subscript, SUBSCRIPT);
          break;
        case $MSUBSUP:
          callback(content.base, BASE);
          callback(content.subscript, SUBSCRIPT);
          callback(content.superscript, SUPERSCRIPT);
          break;
        case $MSUP:
          callback(content.base, BASE);
          callback(content.superscript, SUPERSCRIPT);
          break;
        case $MUNDER:
          callback(content.base, BASE);
          callback(content.underscript, UNDERSCRIPT);
          break;
        case $MUNDEROVER:
          callback(content.base, BASE);
          callback(content.overscript, OVERSCRIPT);
          callback(content.underscript, UNDERSCRIPT);
          break;
        case $MFENCED:
        case $MROW:
          content.children.forEach(callback);
          break;
        default:
          log.error('forEachMathChild not configured for', content);
          throw new Error('forEachMathChild incomplete');
      }
    }
  }
};

/**
 * Like `[].map` but for `MathContent`:
 * Calls `callback` for every child of `content` (property with type `MathContent`)
 * and returns a new object that can be used to override the child properties
 * with the values returned by `callback`.
 *
 * Examples:
 * ```
 * const content: MRow = { $: $MROW, children: [{},{}]};
 * const mapped = mapMathChildren(content, (child, key) => [key, child]);
 * assert mapped === {
 *   children: [
 *     [0, {}], [1, {}]
 *   ]
 * };
 * ```
 * ```
 * const content: MSub = { $: $MSUB, base: {}, subscript: {}};
 * const mapped = mapMathChildren(content, (child, key) => [key, child]);
 * assert mapped === {
 *   base: ['base', {}],
 *  subscript: ['subscript', {}]
 * };
 * ```
 *
 * The using code doesn't need to know which kind of `MathContent` `content` is
 * or which properties contain children.
 *
 * To traverse a tree you need to write a recursive function that conditionally
 * uses itself to map/forEach over the children of a node (depth first)
 * or iterates over the result of map/forEach (breadth first).
 * For an example of depth first approach look at `mathContentEnricher`.
 *
 * @param {MathContent} content
 * @param {(child: MathContent, key: (string | number)) => void} callback
 * @see forEachMathChild
 */
/* eslint-disable-next-line complexity*/
export const mapMathChildren = (
  content: MathContent,
  callback: (child: MathContent, key: string | number) => MathContent
): Partial<MathContent> => {
  if (isPureMathContent(content)) {
    if (!isMathLeaf(content)) {
      switch (content.$) {
        case $MEXPANSION:
        case $MFRAC:
        case $MOVER:
        case $MROOT:
        case $MSQRT:
        case $MABS:
        case $MSUB:
        case $MSUBSUP:
        case $MSUP:
        case $MUNDER:
        case $MUNDEROVER:
          // eslint-disable-next-line no-case-declarations
          const result: Record<string, MathContent> = {};
          // eslint-disable-next-line no-case-declarations
          const mapper = (child: MathContent, key: keyof PureMathContent) => {
            result[key] = callback(child, key);
          };
          forEachMathChild(content, mapper);
          return result;
        case $MFENCED:
        case $MROW:
          return { children: content.children.map(callback) };
        default:
          log.error('mapMathChildren not configured for', content);
          throw new Error('mapMathChildren incomplete');
      }
    }
  }
  return {};
};

export const mapMathChildrenToArray = <T>(
  content: MathContent,
  callback: (child: MathContent, key: string | number) => T
): T[] => {
  if (isPureMathContent(content)) {
    if (!isMathLeaf(content)) {
      switch (content.$) {
        case $MFRAC:
        case $MROOT:
        case $MSQRT:
        case $MABS:
        case $MSUB:
        case $MSUBSUP:
        case $MSUP:
          // eslint-disable-next-line no-case-declarations
          const result: Array<T> = [];
          // eslint-disable-next-line no-case-declarations
          const mapper = (child: MathContent, key: keyof PureMathContent) => {
            result.push(callback(child, key));
          };
          forEachMathChild(content, mapper);
          return result;
        case $MFENCED:
        case $MROW:
          return content.children.map(callback);
        default:
          log.error('mapMathChildrenToArray not configured for', content);
          throw new Error('mapMathChildrenToArray incomplete');
      }
    }
  }
  return [];
};

export const DEFAULT_UNSTYLED: Readonly<MDecoratable> = {
  computedStyles: { fontSize: DEFAULT_FONT_SIZE },
};

export const DEFAULT_UNALIGNED: Readonly<AlignedRow> = {
  childrenAlignments: [],
};

export const DEFAULT_MATH_CONTENT: Readonly<FormulaContent> = {
  ...DEFAULT_UNSTYLED,
  ...DEFAULT_UNALIGNED,
  $: MATH,
  $renderStyle: RS.FORMULA,
  content: [],
};

export const DEFAULT_PLACEHOLDER_CONTENT: Readonly<FormulaContent> = {
  ...DEFAULT_UNSTYLED,
  ...DEFAULT_UNALIGNED,
  $: PLACEHOLDER,
  $renderStyle: RS.TEXT,
  content: [],
};

export const FORMULA_DEFAULT_CONTENT = (): Readonly<FormulaContent> => ({
  ...DEFAULT_UNSTYLED,
  ...DEFAULT_UNALIGNED,
  $: SPECIAL,
  $renderStyle: RS.TEXT,
  content: [],
});

export const FORMULA_INTERACTIVE_CONTENT = (): Readonly<FormulaContent> => ({
  ...FORMULA_DEFAULT_CONTENT(),
  $renderStyle: RS.FORMULA,
  $interactionType: RS.TEXT,
  initiallyUnselected: true, // formula does not get initial focus as it would trigger scrolling
});

export const enum PathMode {
  Insert,
  Append,
}

/**
 * Handle a path with the given mode (e.g. do something before or after the
 * given element)
 */
export type PathHandler = (path: Path, mode: PathMode) => void;

export const isOuterInteractive = (renderStyle: string, interactionType?: string) =>
  !isNil(interactionType) && renderStyle !== RS.INNER_TEXT;

export const resolveContentReferences = ({ content }: FormulaContent): ContentReference[] => {
  const result: ContentReference[] = [];
  const collectContentReferences = (child: MContent) => {
    if (isContentReference(child)) {
      result.push(child);
    }
  };
  content.forEach((content) => {
    collectContentReferences(content);
    forEachMathChild(content, collectContentReferences);
  });
  return result;
};

export const formulaToTool = Lens.create<FormulaContent>('tool');
export const toolToLayout = Lens.create<KeyboardTool>('layout');
export const formulaToToolLayout = Lens.compose(formulaToTool, toolToLayout);

export const isEmptyTranslationKey = (content: Content, t: TFunction) =>
  isFormulaContent(content) &&
  content.content.length === 1 &&
  isMText(content.content[0]) &&
  content.content[0].translate &&
  isEmpty(t(content.content[0].text));
