import * as React from 'react';
import { getCRIPlayeriFrameUrl } from './helper';
import { Whiteboard } from '../whiteboard';
import { useReportHeightToWhiteboard } from '../whiteboard/useReportHeightToWhiteboard';
import styled from 'styled-components';
import { CRIContent } from './CRIContent';
import { useLocation, useParams } from 'react-router-dom';
import { ContentType } from '../../types';
import { LocaleProvider } from '../../gizmo-utils/polymorphic-gizmo';
import WhiteBackground from '../example-mode/WhiteBackground';
import { DEFAULT_CONTENT_ROUTE } from '../../gizmo-utils/constants';

const ClassroomIntroWrapper = styled.div`
  overflow-y: hidden;
`;

export default function CRIPlayerRoute() {
  const location = useLocation();
  const { locale, id } = useParams();

  const staticUrlQuery = new URLSearchParams(location.search).get('staticUrl');
  const staticServerUrl = staticUrlQuery
    ? (staticUrlQuery + '/content_exercises').replace(
        /\/\/+content_exercises/g,
        '/content_exercises'
      )
    : DEFAULT_CONTENT_ROUTE;

  const forWhiteboard = Boolean(new URLSearchParams(location.search).get('forWhiteboard'));

  const testQuery = new URLSearchParams(location.search).get('test');
  const test = testQuery === 'true';

  const iframeUrl = getCRIPlayeriFrameUrl(locale ?? '', id ?? '', staticServerUrl, test);

  const { ref } = useReportHeightToWhiteboard({
    contentId: id ?? '',
    contentType: 'cri',
    playerHPadding: 0,
    shouldReportHeight: true,
  });

  return (
    <LocaleProvider contentLocale={locale}>
      {forWhiteboard ? (
        <Whiteboard iframeUrl={iframeUrl} contentType={ContentType.CRI} />
      ) : (
        <ClassroomIntroWrapper ref={ref} data-cy={`cri-${id}`}>
          <WhiteBackground />
          <CRIContent
            {...{
              id: id ?? '',
              locale: locale ?? '',
              staticServerUrl,
              test,
            }}
          />
        </ClassroomIntroWrapper>
      )}
    </LocaleProvider>
  );
}
