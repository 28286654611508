import * as React from 'react';
import { Bar, Button, ButtonKind, ButtonStretch } from '@seriesplayer/common-ui';
import { expectType, type TypeOf } from 'ts-expect';

import {
  BaseLayout,
  Content,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../../../../components/index';
import { type QuitConfirmationReduxProps } from './QuitConfirmationContainer';

export interface QuitConfirmationOwnProps {
  title: string;
  mainText1?: string;
  mainText2?: string;
  mainText2NoReporting?: string;
  confirmText?: string;
  abortText?: string;
  restartText?: string;
}

export interface QuitConfirmationProps extends QuitConfirmationOwnProps {
  onCancel: () => void;
  onQuit: () => void;
  onCancelCustom?: () => void;
  onConfirmCustom?: () => void;
  onSubmitIncomplete: () => void;
  canSubmitIncompleteSeries: boolean;
  hasStepReporting: boolean;
}

// validating the contract between `QuitConfirmationContainer` and `QuitConfirmation`
expectType<TypeOf<QuitConfirmationReduxProps, QuitConfirmationProps>>(true);

/**
 * QuitConfirmation Component
 * Used to render Quit dialog header, content and to handle events on it.
 * Here we are reusing Layout component for layouting and
 * Box Component for stretching the Dialog content
 * Wrapping inside a Dialog for styling
 */
export function QuitConfirmation({
  onCancel: _onCancel,
  onQuit: _onQuit,
  onCancelCustom,
  onConfirmCustom,
  onSubmitIncomplete,
  canSubmitIncompleteSeries = false,
  hasStepReporting = false,
  ...textProps
}: QuitConfirmationProps) {
  const onCancel = onCancelCustom ? onCancelCustom : _onCancel;
  const onQuit = onConfirmCustom ? onConfirmCustom : _onQuit;

  return (
    <BaseLayout vertical>
      <DialogHeader
        title={textProps.title}
        leftButton={{
          icon: 'ArrowLeftLargeBold',
          onClick: onCancel,
          dataCy: 'back-arrow-dialog-header-btn',
        }}
      />
      <Bar />
      <DialogContent as={Content}>
        <p className="plaintext">{textProps.mainText1}</p>
        <p className="plaintext">
          {hasStepReporting ? textProps.mainText2 : textProps.mainText2NoReporting}
        </p>
        <DialogFooter align="center">
          <Button
            id="quit-confirm-btn"
            dataCy="confirm-quit-btn"
            key="quit"
            kind={ButtonKind.action}
            stretch={ButtonStretch.responsive}
            onClick={onQuit}
          >
            {textProps.confirmText}
          </Button>
          <Button
            id="quit-abort-btn"
            dataCy="dont-quit-btn"
            key="unquit"
            kind={ButtonKind.view}
            stretch={ButtonStretch.responsive}
            onClick={onCancel}
          >
            {textProps.abortText}
          </Button>
          {canSubmitIncompleteSeries && (
            <Button
              id="quit-submit-btn"
              dataCy="submit-and-quit-btn"
              key="submitincomplete"
              kind={ButtonKind.warning}
              stretch={ButtonStretch.responsive}
              onClick={onSubmitIncomplete}
            >
              {textProps.restartText}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </BaseLayout>
  );
}

QuitConfirmation.displayName = 'QuitConfirmation';
