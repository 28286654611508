import { compact } from 'lodash';
import {
  abs,
  add,
  angle,
  cross2d,
  eq,
  midpoint,
  normal,
  project,
  roundV,
  smult,
  squaredEuclidianDistance,
  sub,
} from './vectors';
import { roundNumber as rn } from './numbers';
import { EPS } from '../constants';
import {
  type Coords,
  type GeoConfigurationDisplay,
  RAY,
  SEGMENT,
  STRAIGHTLINE,
  VECTOR,
  type ViewboxCorners,
} from '@bettermarks/gizmo-types';
import { GENERATOR_MAX_DECIMALS } from '../tools_constants';

export const intersectLines = (
  s1: [Coords, Coords],
  s2: [Coords, Coords],
  isValid: (t1: number, t2: number) => boolean
): Coords | null => {
  const [[p1, q1], [p2, q2]] = [s1, s2];
  const r = sub(p1, q1);
  const s = sub(p2, q2);
  const d = cross2d(r, s);

  // lines are parallel (or collinear)
  if (d === 0) return null;

  const t1 = cross2d(sub(p1, p2), s) / d;
  // as cross2d(s,r)=== -cross2d(r,s)
  const t2 = -cross2d(sub(p2, p1), r) / d;

  if (!isValid(t1, t2)) return null;

  return roundV(add(p1, smult(t1, r)), GENERATOR_MAX_DECIMALS);
};

export const isValidMap: {
  [key: string]: (t1: number, t2: number) => boolean;
} = {
  //      1
  //      |
  // 0----x--1
  //      |
  //      |
  //      0
  [`${SEGMENT}-${SEGMENT}`]: (t1, t2) => rn(t1) >= 0 && rn(t1) <= 1 && rn(t2) >= 0 && rn(t2) <= 1,

  //      1      1
  //      |      |
  // 0----x--1---x----------
  //      |      |
  //      |      |
  //      0      0
  [`${SEGMENT}-${RAY}`]: (t1, t2) => rn(t1) >= 0 && rn(t1) <= 1 && rn(t2) >= 0,

  //  1      1      1
  //  |      |      |
  // -x-0----x--1---x----------
  //  |      |      |
  //  |      |      |
  //  0      0      0
  [`${SEGMENT}-${STRAIGHTLINE}`]: (t1, t2) => rn(t1) >= 0 && rn(t1) <= 1,

  [`${RAY}-${RAY}`]: (t1, t2) => rn(t1) >= 0 && rn(t2) >= 0,

  [`${RAY}-${STRAIGHTLINE}`]: (t1, t2) => rn(t1) >= 0,

  [`${STRAIGHTLINE}-${STRAIGHTLINE}`]: (t1, t2) => true,

  // 0------x---1
  [SEGMENT]: (t1) => rn(t1) >= 0 && rn(t1) <= 1,

  // 0------x---1------x----------------
  [RAY]: (t1) => rn(t1) >= 0,

  // -x-0---x---1------x----------------
  [STRAIGHTLINE]: (t1) => true,
};

export const isPointOnLine = (
  s: [Coords, Coords],
  point: Coords,
  isValid: (t1: number, t2?: number) => boolean
): boolean => {
  const [p, q] = s;
  const r1 = sub(p, q); // q - p
  const r2 = sub(p, point); // point - p
  const mu = r2.x / r1.x; // mu
  const nu = r2.y / r1.y; // nu
  return isFinite(mu) && isFinite(nu) // the regular case ...
    ? Math.abs(mu - nu) < EPS && isValid(mu)
    : !isFinite(mu) // vertical line case
    ? Math.abs(point.x - p.x) < EPS && isValid(nu)
    : Math.abs(point.y - p.y) < EPS && isValid(mu); // horizontal line case
};

export const isPointOnCircle = (p: Coords, circleCenter: Coords, radius: number): boolean =>
  Math.abs(squaredEuclidianDistance(p, circleCenter) - radius * radius) < EPS;

export const linesAreEqul = (
  [p1, q1]: [Coords, Coords],
  [p2, q2]: [Coords, Coords],
  type = STRAIGHTLINE
): boolean => angle(p1, q1) === angle(p2, q2) && isPointOnLine([p1, q1], p2, isValidMap[type]);

// tslint:disable-next-line:cyclomatic-complexity
export const intersectLineCircle = (
  line: [Coords, Coords],
  circle: [Coords, number],
  lineType: string
): Coords[] => {
  const [p, q] = line;
  const [mid, r] = circle;
  const rsquare = r * r;

  const midfoot = project(mid, line, lineType);
  const midfootIsP = eq(p, midfoot);
  const midfootIsQ = eq(q, midfoot);

  const distMidLine = squaredEuclidianDistance(midfoot, mid);

  if (distMidLine > rsquare || ((midfootIsP || midfootIsQ) && distMidLine === rsquare)) return [];

  if (distMidLine === rsquare) return [midfoot];

  const dir = sub(p, q);
  const lenDir = abs(dir);
  let truefoot = midfoot;
  let t = Math.sqrt(rsquare - distMidLine) / lenDir;

  if (lineType !== STRAIGHTLINE) {
    truefoot = project(mid, line, STRAIGHTLINE);
    t = Math.sqrt(rsquare - squaredEuclidianDistance(truefoot, mid)) / lenDir;

    if (midfootIsP || (midfootIsQ && lineType !== RAY)) {
      const isec = add(truefoot, smult(midfootIsP ? t : -t, dir));

      if (
        (lineType === SEGMENT || lineType === VECTOR) &&
        squaredEuclidianDistance(midfoot, isec) > squaredEuclidianDistance(p, q)
      )
        return [];

      return [isec];
    }
  }

  return [add(truefoot, smult(t, dir)), add(truefoot, smult(-t, dir))];
};

export const intersectCircles = (c1: [Coords, number], c2: [Coords, number]): Coords[] => {
  const [mid1, r1] = c1;
  const [mid2, r2] = c2;

  const midconnect = sub(mid1, mid2);
  const middist = abs(midconnect);

  // Due to numerical issues, we assume to have NO intersection ONLY,
  // if there is some measurable gap between circles ...
  if (middist > r1 + r2 + EPS) return [];

  // ... otherwise, we assume to have ONE intersection point. The same applies to the
  // 'two point intersection case', where the distance between the two intersection points
  // would be too small. This case is also treated as the ONE intersection case.
  if (Math.abs(middist - (r1 + r2)) < EPS) return [add(mid1, smult(r1 / middist, midconnect))];

  /**
   * Much of what comes below uses distance calculations from
   *
   * http://mathworld.wolfram.com/Circle-CircleIntersection.html
   *
   * enhanced with proper directions given by the positions of the circles' midpoints
   */
  // calculate the midpoint between the two intersection points
  const unitMidConnect = smult(1 / middist, midconnect);
  const normMidConnect = normal(unitMidConnect);

  const m1ToMidIntersect = (r1 * r1 + middist * middist - r2 * r2) / (2 * middist);
  const midIntersect = add(mid1, smult(m1ToMidIntersect, unitMidConnect));

  // move to the intersection points from there
  const toIntersect =
    Math.sqrt(
      (middist + r1 + r2) * (-middist + r1 + r2) * (-middist + r1 - r2) * (-middist - r1 + r2)
    ) /
    (2 * middist);

  return [
    add(midIntersect, smult(toIntersect, normMidConnect)),
    add(midIntersect, smult(-toIntersect, normMidConnect)),
  ];
};

/**
 * Determines if a given point is within the current x/yMin/Max.
 *
 * @param {Coords} p point
 * @param {GeoConfigurationDisplay} display configuration which holds x/yMin/Max
 *
 * @return {boolean}
 */
export const inViewbox = (p: Coords, display: GeoConfigurationDisplay): boolean => {
  const { xMin, xMax, yMin, yMax } = display;
  return p.x >= xMin && p.x <= xMax && p.y >= yMin && p.y <= yMax;
};

/**
 * Calculates intersections of line objects with the "border/viewbox" of the geo
 *
 * @param {[Coords, Coords]} points that define the line object
 * @param {ViewboxCorners} viewboxCorners (coords of our geo viewbox corners)
 * @param {string} lineType type of the line object (one of segment, ray, ...)
 *
 * @return {Coords[]} array of intersection points
 */
export const intersectWithViewbox = (
  [p1, p2]: [Coords, Coords],
  viewboxCorners: ViewboxCorners,
  lineType: string
): Coords[] => {
  const { sw, se, nw, ne } = viewboxCorners;
  const isValid = isValidMap[`${SEGMENT}-${lineType}`];
  return compact([
    intersectLines([sw, se], [p1, p2], isValid),
    intersectLines([sw, nw], [p1, p2], isValid),
    intersectLines([nw, ne], [p1, p2], isValid),
    intersectLines([ne, se], [p1, p2], isValid),
  ]).reduce((acc, i) => (acc.find((j) => eq(i, j)) ? acc : [...acc, i]), []);
};

export const viewboxCorners = (display: GeoConfigurationDisplay): ViewboxCorners => {
  const { xMin, xMax, yMin, yMax } = display;

  return {
    sw: { x: xMin, y: yMin },
    se: { x: xMax, y: yMin },
    nw: { x: xMin, y: yMax },
    ne: { x: xMax, y: yMax },
  };
};

/**
 * Midpoint of a line object with regards to the viewbox's x/yMin/Max.
 *
 * @param {Coords} p1 first point
 * @param {Coords} p2 second point
 * @param {GeoConfigurationDisplay} display configuration which holds x/yMin/Max
 * @param {string} lineType one of SEGMENT, RAY, ...
 *
 * @return {Coords} "visible" center of a line object
 * A) -----|--------x--------|--
 * B)      |  -------x-------|--
 * C) -----|-------x-------  |
 * D)      |   ------x------ |
 *
 */
// tslint:disable-next-line:cyclomatic-complexity
export const visibleCenter = (
  p1: Coords,
  p2: Coords,
  display: GeoConfigurationDisplay,
  lineType: string
): Coords | undefined => {
  const vbCorners = viewboxCorners(display);
  if (inViewbox(p1, display) && inViewbox(p2, display) && lineType === SEGMENT) {
    return midpoint(p1, p2);
  } else if (
    (inViewbox(p1, display) && !inViewbox(p2, display) && lineType === SEGMENT) ||
    (inViewbox(p1, display) && lineType === RAY)
  ) {
    const [border] = intersectWithViewbox([p1, p2], vbCorners, lineType);
    return midpoint(p1, border);
  } else if (!inViewbox(p1, display) && inViewbox(p2, display) && lineType === SEGMENT) {
    const [border] = intersectWithViewbox([p1, p2], vbCorners, lineType);
    return midpoint(p2, border);
  } else {
    const [border1, border2] = intersectWithViewbox([p1, p2], vbCorners, lineType);
    return border1 && border2 ? midpoint(border1, border2) : undefined;
  }
};
