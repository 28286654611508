import { defaultTo, isFinite, isNaN } from 'lodash';
import {
  type DerivativeType,
  FunctionType,
  type GeoConfigurationDisplay,
} from '@bettermarks/gizmo-types';
import { DEFAULT_PARAMS } from '../defaults';
import { EPS } from './constants';
import { F_MAP } from './functions';
import { F_ROOTS_MAP } from './roots';
import { switchStatement } from '../helpers';
import { roundToPrecision } from '../../../utils/math';

const TANGENT_X_MIN = -100;
const TANGENT_X_MAX = 100;
const SECANT_X_MIN = -100;
const SECANT_X_MAX = 100;
// an 'infinity' value for graphs in order to draw corresponding SVGs correctly.
const GRAPH_INFINITY = 200;

type Args = { [key: string]: number };
type Tuple = [number, number];
export type Graph = Tuple[];

// isFlipped indicates, if x and y axes have to been flipped in order to
// provide 'non function'-functions like 'vertical line'.
const isFlipped = (ft: FunctionType): boolean => (ft === FunctionType.vline ? true : false);

// just creates a 2-tuple [x, y] or [y, x] from given x, y and 'isFlipped' flag.
const tuple = (x: number, y: number, isFlipped = false): Tuple => (isFlipped ? [y, x] : [x, y]);

// sets a number to a specific precision
// 3 digits of precision is a good value, 2 would be a little bit 'unsmooth'
const lessPrec = roundToPrecision(3);

// ensures a number is within a given 'range' [-GRAPH_INFINITY, GRAPH_INFINITY]
const intoRange = (y: number): number => Math.min(GRAPH_INFINITY, Math.max(y, -GRAPH_INFINITY));
/**
 * evaluates a function by a given type of the function, 'n' for the nth derivative
 * and a parameter list, additionnaly the x value where the function shall be evaluated.
 * @param {FunctionType} type  - The function type as a string
 * @param {{Args}} args - The function parameters as an object
 * @param {DerivativeType} ddx - 0th or first derivative (0, 1)
 * @param {number} x - to be evaluated at x.
 */
export const f =
  (type: FunctionType) =>
  (args: Args) =>
  (ddx: DerivativeType) =>
  (x: number): number =>
    F_MAP[type](
      ...Object.keys({ ...DEFAULT_PARAMS[type], ...args })
        .sort()
        .map((k) => ({ ...DEFAULT_PARAMS[type], ...args }[k]))
    )(ddx)(x);

/**
 * finds all roots of a given function and returns an array of x values
 * @param {FunctionType} type  - The function type as a string
 * @param {{Args}} args - The function parameters as an object
 * @param {number y=0} - An additional "translate by y" parameter, if the root should be placed
 * at y !== 0
 * @returns {number[]} an array of the roots
 */
export const roots =
  (type: FunctionType) =>
  (args: Args) =>
  (y: number): number[] =>
    F_ROOTS_MAP[type](
      ...Object.keys({ ...DEFAULT_PARAMS[type], ...args })
        .sort()
        .map((k) => ({ ...DEFAULT_PARAMS[type], ...args }[k]))
    )(y).filter((x: number) => !isNaN(x) && isFinite(x));

const logPoles = (args: Args): number[] => [args.d];
const powPoles = (args: Args): number[] => (args.n <= 0 ? [0] : []);

/**
 * calculate the list of poles for the given function type. You need to
 * assert, that the poles are sorted in accending order, or the following
 * algorithms won't work!
 * @param {FunctionType} type - The function type
 * @param {Args} args - The function args
 * @returns {number[]} the list of poles (x positions)
 */
const poles = switchStatement<number[]>(
  {
    [FunctionType.logarithmic]: logPoles,
    [FunctionType.power]: powPoles,
  },
  []
);

/**
 * calculates an array of (x,y) tuples representing the graph of a given function
 * @param {number} min - the 'start' of the independent var
 * @param {number} max - the 'end' of the independent var
 * @param {number} samples - number of samples for the graph
 * @param {FunctionType} ft - the function type for the graph
 * @param {Args} args - the parameter list for the function
 * @returns {[number , number][]}
 */
const graph_ = (r: GeoConfigurationDisplay, samples: number, ft: FunctionType, args: Args): Graph =>
  [...Array(samples)]
    .map((_, i) => r.xMin + ((r.xMax - r.xMin) / (samples - 1)) * i) // create array of x positions
    .map((x) => tuple(x, f(ft)(args)(0)(x), isFlipped(ft))) // map to x,y tuples
    .concat(roots(ft)(args)(r.yMin).map<Tuple>((x) => [x, r.yMin])) // add min-y crossings
    .concat(roots(ft)(args)(r.yMax).map<Tuple>((x) => [x, r.yMax])) // add max-y crossings
    .filter(
      // reduce not displayed samples (poles will be handled separately)
      (
        p // the magic 10 just denotes 'a little bit' below yMin (above yMax)
      ) => p[1] <= r.yMax + 10 && p[1] >= r.yMin - 10 && p[0] >= r.xMin && p[0] <= r.xMax
    )
    .sort((a, b) => a[0] - b[0]); // sort by x
/**
 * calculates an array of graphs. Usually you will only have one graph
 * contained, but if the function has poles it needs to be split into multiple
 * graphs with additional sample points at the upper and lower boundary of the
 * geo system. This is currently only necessary for logarithm and for the
 * reciproce power function. If we add rational functions, this should also
 * work by just calculating the poles in the poles function above.
 * @param min - the 'start' of the independent var
 * @param max - the 'end' of the independent var
 * @param samples - number of samples for the graph
 * @param ft - the function type for the graph
 * @param args - the parameter list for the function
 * @returns {Graph[]} a list of graphs
 * */
export const graph = (
  r: GeoConfigurationDisplay,
  samples: number,
  ft: FunctionType,
  args: Args,
  ps = graph_(r, samples, ft, args) // evaluate all sample points
): Graph[] =>
  poles(ft, args)
    .reduce(
      (acc, pole) => {
        const last = acc[acc.length - 1];
        // 100 * EPS gives mor pole 'sensitivity'
        const idx = acc[acc.length - 1].findIndex((p) => p[0] > pole + 100 * EPS);

        if (idx > 0) {
          const left = [...last.slice(0, idx - 1)];
          const right = [...last.slice(idx)];
          const stepLeft = Math.abs(defaultTo<Tuple>(left[left.length - 1], [pole, 0])[0] - pole);
          const stepRight = Math.abs(defaultTo<Tuple>(right[0], [pole, 0])[0] - pole);

          return [
            ...acc.slice(0, -1),
            // some nice round 'corners' at the poles with fixed 10! iteration points ..
            [
              ...left,
              ...[...Array(10)].map((_, i) => [
                pole - (stepLeft / 10) * (9 - i) - EPS,
                f(ft)(args)(0)(pole - (stepLeft / 10) * (9 - i) - EPS),
              ]),
            ],
            [
              ...[...Array(10)].map((_, i) => [
                pole + EPS + (stepRight / 10) * i,
                f(ft)(args)(0)(pole + EPS + (stepRight / 10) * i),
              ]),
              ...right,
            ],
          ];
        }
        return acc;
      },
      [ps]
    )
    // we do not want NaNs, even in Poles!! (the reason, why this is done here, and not in graph_)
    .map((g) => g.filter((t) => !isNaN(t[1])))
    // less precisions for better performance
    .map((g) => g.map((t: Tuple) => tuple(lessPrec(t[0]), lessPrec(intoRange(t[1])))));

/**
 * calculates a 5-tuple of (x, y) values representing a tangent line with a
 * a left, a middle,  a right point from a given 'x' point, where the
 * tangent touches the function and an optional length of the tangent line.
 * If not given, the left resp. right points are restricted to TANGENT_X_MIN
 * left resp. TANGENT_X_MAX right.
 *
 * Remark: We take 5 (and not 3) points to be in sync with secant rendering
 * and be able to perform a'smooth' transition from secant to tangent!
 * In the tangent-case, The 'inner' three points are just identical.
 *
 * @param {number} mid - the mid points x
 * @param {number} len - the optional length of the tangent line
 * @param {FunctionType} ft - the function type for the tangent
 * @param {Args} args - the parameter list for the function
 * @returns {[number , number][]}
 */
export const tangent = (
  x: number,
  len: number,
  ft: FunctionType,
  args: Args,
  y = f(ft)(args)(0)(x),
  m = f(ft)(args)(1)(x),
  c = y - m * x,
  s = len ? len / (2 * Math.sqrt(1 + m * m)) : 0
): Graph =>
  !isFinite(m) || isNaN(m)
    ? Array(5).fill([x, y])
    : [
        s ? [x - s, m * (x - s) + c] : [TANGENT_X_MIN, m * TANGENT_X_MIN + c],
        ...Array(3).fill([x, y]),
        s ? [x + s, m * (x + s) + c] : [TANGENT_X_MAX, m * TANGENT_X_MAX + c],
      ].map((t) => tuple(t[0], t[1], isFlipped(ft)) as Tuple);

/**
 * calculates a 5-tuple of (x, y) values representing a secant line with these points:
 * 0 ... the "left" point at x = SECANT_X_MIN
 * 1 ... the "left mid" point at min(sx1, sx2)
 * 2 ... the 'mid' point
 * 3 ... the "right mid" point at max(sx1, sx2)
 * 4 ... the "right point" at x = SECANT_X_MAX
 * In this context, "left" needs not to be actually "left", but is associated with sx1.
 * @param {[number, number]} x - the sx1 and sx2 values as a tuple
 * @param {FunctionType} ft - the function type for the tangent
 * @param {Args} args - the parameter list for the function
 * @returns {[number , number][]}
 */
export const secant = (
  x: Tuple,
  ft: FunctionType,
  args: Args,
  x0 = Math.min(x[0], x[1]),
  x1 = Math.max(x[0], x[1]),
  y0 = f(ft)(args)(0)(x0),
  y1 = f(ft)(args)(0)(x1),
  k = (y1 - y0) / (x1 - x0),
  d = y0 - k * x0
): Graph =>
  !isFinite(y0) || isNaN(y0)
    ? Array(5).fill([x1, y1])
    : !isFinite(y1) || isNaN(y1)
    ? Array(5).fill([x0, y0])
    : Math.abs(x1 - x0) < EPS
    ? tangent(x0, 0, ft, args)
    : [
        [SECANT_X_MIN, k * SECANT_X_MIN + d],
        [x0, y0],
        [(x0 + x1) / 2, (y0 + y1) / 2],
        [x1, y1],
        [SECANT_X_MAX, k * SECANT_X_MAX + d],
      ].map((t) => tuple(t[0], t[1], isFlipped(ft)) as Tuple);
