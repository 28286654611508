import { type Dispatch } from '../../../../../gizmo-utils/redux';
import { connect } from 'react-redux';
import {
  ApplicationState,
  DialogType,
  ExerciseStatus,
  SeriesMode,
  SeriesStatus,
  StepStatus,
} from '../../../../../types';
import {
  nextStep,
  updateFilledStatus,
  submit,
  switchExerciseResumable,
} from '../../Exercise/actions';
import { openDialog, showResults } from '../../SeriesPlayer/actions';
import { toggleToolbarDrawer } from '../actions';

import {
  ExerciseButtons,
  type ExerciseButtonsDispatchProps,
  type ExerciseButtonsStateProps,
  ExerciseButtonState,
} from './ExerciseButtons';
import { redoAndEnrich, undoAndEnrich } from '../../../../store/combineSeriesplayerReducer';

export const mapDispatchToProps = (dispatch: Dispatch): ExerciseButtonsDispatchProps => ({
  nextStep: () => dispatch(nextStep()),
  nextExercise: () => dispatch(switchExerciseResumable(undefined)),
  onShowHandInConfirmation: () => {
    dispatch(updateFilledStatus());
    dispatch(toggleToolbarDrawer({ drawerName: null }));
    dispatch(openDialog({ type: DialogType.handInConfirmation }));
  },
  redo: () => dispatch(redoAndEnrich()),
  results: () => dispatch(showResults({ animate: true })),
  submit: () => dispatch(submit()),
  undo: () => dispatch(undoAndEnrich()),
});

const getButtonStatePractice = (state: ApplicationState) => {
  const {
    series: { seriesStatus },
  } = state;

  if (seriesStatus === SeriesStatus.completed) {
    return ExerciseButtonState.results;
  } else {
    const exerciseStatus = ApplicationState.toCurrentExercise.get(state).status;
    if (exerciseStatus === ExerciseStatus.completed || exerciseStatus === ExerciseStatus.review) {
      return ExerciseButtonState.nextExercise;
    } else {
      const step = ApplicationState.toCurrentStep.get(state);
      if (step && step.status === StepStatus.completed) {
        return ExerciseButtonState.nextStep;
      } else {
        return ExerciseButtonState.submit;
      }
    }
  }
};

const getButtonStateTest = (state: ApplicationState) =>
  ApplicationState.isEndOfSeries(state)
    ? ExerciseButtonState.handIn
    : ExerciseButtonState.nextExerciseTestMode;

/**
 * Calculates the ExerciseButtonState that controls the buttons to be shown in the Toolbar
 * and the actions they trigger.
 * There is a basic difference between testmode and other modes:
 * In test-mode there can only be next-exercise or hand in state
 * In practice-mode there can be a submit, next-step, next-exercise or show-results state
 * When series is reviewed there can only be navigated to next exercise.
 * @param state
 */
export const getButtonState = (state: ApplicationState) => {
  const {
    series: { mode },
  } = state;
  return mode === SeriesMode.review
    ? ExerciseButtonState.nextExercise
    : mode === SeriesMode.test
    ? getButtonStateTest(state)
    : getButtonStatePractice(state);
};

export const mapStateToProps = (state: ApplicationState): ExerciseButtonsStateProps => {
  const currentStatus = ApplicationState.toCurrentExercise.get(state).status;
  const isTestModeWithInput =
    state.series.mode === SeriesMode.test && currentStatus === ExerciseStatus.withInput;
  const question =
    currentStatus === ExerciseStatus.started || isTestModeWithInput
      ? ApplicationState.getCurrentUndoableQuestion(state)
      : undefined;

  return {
    buttonState: getButtonState(state),
    nextExerciseDisabled: ApplicationState.isEndOfSeries(state),
    submitDisabled: !ApplicationState.toCurrentExercise.get(state).stepValidationLoaded,
    undoDisabled: !question || question.past.length === 0,
    redoDisabled: !question || question.future.length === 0,
  };
};

export const ExerciseButtonsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExerciseButtons);
