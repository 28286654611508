import { type Align } from '@seriesplayer/common-ui';
import { getNamedColor } from '@bettermarks/importers';
import {
  type BChildDecoration,
  type BLayoutAlign,
  type BLayoutDecoration,
  type BorderChild,
} from '@bettermarks/gizmo-types';
import { ALIGNMENT_MAP } from '../LayoutGizmo';

export const MAX_ROW_WIDTH = 800;

/**
 * Determines the maximum width of a center block (W, C, E) depending on
 * availableWidth, horizontal gap in the considered row,
 * and the question if the block ist the only one in the row
 * @param {number} childPerc
 * @param {number} availableWidth
 * @param {number} totalHGap
 * @param {boolean} singleBlockInRow
 * @return {number}
 */

export const maxChildWidth = (
  childPerc: number,
  availableWidth: number,
  totalHGap: number,
  singleBlockInRow = true
) =>
  availableWidth >= MAX_ROW_WIDTH || !singleBlockInRow
    ? (availableWidth - totalHGap) * childPerc
    : availableWidth;

/**+-----------------------------------------------------------------------------------------------+
 * +-----------------+ HELPERS FOR CUSTOM DECORATION +---------------------------------------------+
 * +-----------------------------------------------------------------------------------------------+
 */

const getDecoratedWidth = (
  { paddingLeft = 0, paddingRight = 0 }: BChildDecoration,
  width?: number
) => {
  return width ? width - paddingLeft - paddingRight : width;
};

export const getDecoration = (decoration?: BChildDecoration, width?: number) => {
  const backgroundColor = decoration && getNamedColor(decoration.backgroundColor);
  const verticalAlign = decoration ? decoration.verticalAlign : undefined;
  const horizontalAlign = decoration ? decoration.horizontalAlign : undefined;

  return {
    width: getDecoratedWidth({ ...decoration }, width),
    ...decoration,
    backgroundColor,
    verticalAlign,
    horizontalAlign,
  };
};

export const externalDecorators = (direction: BLayoutAlign, c: BorderChild): BLayoutDecoration => {
  return !(c.decoration && c.decoration[direction])
    ? {}
    : { alignSelf: ALIGNMENT_MAP[c.decoration[direction] as keyof Align] };
};
