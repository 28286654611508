import * as React from 'react';

import { type Severity } from '@bettermarks/umc-kotlin';
import { ContentColor, type MouseOrTouch } from '@bettermarks/gizmo-types';
import { getNamedColor } from '@bettermarks/importers';
import {
  DEFAULT_BAR_MIN_HEIGHT,
  DEFAULT_BAR_WIDTH,
  DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH,
  DEFAULT_HANDLE_DISTANCE,
  DEFAULT_HANDLE_HEIGHT,
  DEFAULT_TICK_WIDTH,
} from '../../defaults';
import { Handle } from '..';

export type BarProps = {
  color: ContentColor;
  yTick: number;
  height: number;
  x: number;
  interactive: boolean;
  axisContraction: number;
  severity?: Severity;
  active?: boolean;
  onMouseDown?: (evt: MouseOrTouch) => void;
  onHandleOver?: () => void;
  onHandleLeave?: () => void;
};

export const Bar: React.FC<BarProps> = ({
  active,
  axisContraction,
  color,
  height,
  interactive,
  onMouseDown,
  onHandleOver,
  onHandleLeave,
  severity,
  x,
  yTick,
}) => {
  const barHeight =
    yTick > 0
      ? yTick * DEFAULT_TICK_WIDTH + axisContraction
      : axisContraction > 0
      ? axisContraction
      : DEFAULT_BAR_MIN_HEIGHT;

  const y = height - barHeight - 1.5 + axisContraction;

  return (
    <>
      <rect
        x={x}
        y={y}
        width={DEFAULT_BAR_WIDTH}
        height={barHeight - 0.5}
        style={{ fill: getNamedColor(color) }}
      />
      {severity && (
        <>
          /* red border for error highlight */
          <rect
            x={x + 1}
            y={y + 1}
            width={DEFAULT_BAR_WIDTH - DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH}
            height={barHeight + 1 - DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH}
            style={{
              fill: 'none',
              stroke: getNamedColor(severity, true),
              strokeWidth: DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH,
            }}
          />
          /* inner white border for error highlight */
          <rect
            x={x + 1 + DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH}
            y={y + 1 + DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH}
            width={
              DEFAULT_BAR_WIDTH -
              DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH -
              2 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH
            }
            height={
              barHeight +
              1 -
              DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH -
              2 * DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH
            }
            style={{
              fill: 'none',
              stroke: getNamedColor(ContentColor.BM_WHITE),
              strokeWidth: DEFAULT_ERROR_HIGHLIGHT_STROKE_WIDTH,
            }}
          />
        </>
      )}
      {interactive && color !== ContentColor.BM_WHITE && (
        <g
          transform={`translate(${x + DEFAULT_HANDLE_DISTANCE},
        ${height - barHeight - DEFAULT_HANDLE_HEIGHT + axisContraction})`}
        >
          <Handle
            onMouseDown={onMouseDown}
            onMouseOver={onHandleOver}
            onMouseLeave={onHandleLeave}
            active={active}
          />
        </g>
      )}
    </>
  );
};

Bar.displayName = 'Bar';
