import * as React from 'react';
import { identity } from 'lodash/fp';
import { type ContextState } from '../../gizmo-utils/polymorphic-gizmo';
import {
  type CommonCallbacks,
  type GeoCallbacks,
  type GeoContent,
  GeoEditorMode,
} from '@bettermarks/gizmo-types';
import { GEO_GIZMO_DEFAULT_CONTENT, transformationSettings } from '@bettermarks/importers';
import { getGeoComponent } from './getGeoComponent';
import { ZoomIn, ZoomOut } from '../../components/icons';
import { ZoomButton } from './components/ZoomButton';

import styles from './Geo.scss';
// @ToDo: Should be organised better after identify partitions for types.ts/constants.
// @ToDo: Instead of having different modes, possibly we could interactive/non-interactive.
import { getScale } from '../../gizmo-utils/getScale';
import { ShadowScrollbars, useDragScrollBehaviour } from '../components';
import { getDragScrollBehaviour } from '../../gizmo-utils/drag-scroll-behaviour';
import { Maybe } from '../../utils/maybe';
import { CONTENT_ID } from '../../gizmo-utils/constants';
import { useScrollLock } from './useScrollLock';

export type GeoProps = GeoContent & GeoCallbacks & CommonCallbacks & ContextState;

const CONTENT_ID_PROP = { id: CONTENT_ID };
const scrollEnabledModes = [
  GeoEditorMode.SCROLL,
  GeoEditorMode.NOT_INTERACTIVE,
  GeoEditorMode.INTERVAL,
];

const wrapIf = (predicate?: boolean) => (render: (_: React.ReactNode) => React.ReactNode) =>
  predicate ? render : identity;

const wrapWithShadowScrollbars =
  (availableWidth: number, onRef: (el: HTMLDivElement) => void) => (geo: React.ReactNode) =>
    (
      <ShadowScrollbars availableWidth={availableWidth} ref={onRef}>
        {geo}
      </ShadowScrollbars>
    );

const applyStyle =
  (
    cssProperty: Exclude<
      keyof CSSStyleDeclaration,
      | 'length'
      | 'parentRule'
      | 'item'
      | 'getPropertyPriority'
      | 'getPropertyValue'
      | 'removeProperty'
      | 'setProperty'
    >,
    value: string
  ) =>
  (style: CSSStyleDeclaration) =>
    (style[cssProperty] = value);

const contentElementStyle = Maybe(document.getElementById(CONTENT_ID)).map(
  (el: HTMLElement) => el.style
);

const getYContainer = () => document.getElementById(CONTENT_ID);

export const Geo: React.FC<GeoProps> = (props) => {
  const {
    isTouch,
    $interactionType,
    disabled,
    availableWidth,
    totalWidth,
    onScalingDown,
    selectedMode,
    scalable,
  } = props;
  const currentScale = scalable ? getScale(totalWidth, availableWidth, 1) : 1;
  const renderMode = disabled ? GeoEditorMode.NOT_INTERACTIVE : selectedMode;
  const { scrollBehaviour, onRef } = useDragScrollBehaviour(
    undefined,
    undefined,
    getDragScrollBehaviour(getYContainer)
  );

  const [scale, setScale] = React.useState<number>(currentScale);
  const [lockContextYScroll, unlockContextYScroll] = useScrollLock(CONTENT_ID_PROP);

  React.useEffect(() => {
    setScale(currentScale);
  }, [currentScale]);

  React.useEffect(() => {
    /**
     * Takes care of decreasing the font size of formulas contained in geo
     * Only kicks in for scale smaller/EQUAL 1, since
     * - we might need to get back to 1, when scaling back up
     * - don't want to increase fonts when scaling > 1
     **/
    if (onScalingDown && scale <= 1 && props.scale !== scale) {
      onScalingDown(scale);
    }
  }, [props.scale, scale]);

  const [showShadowScrollbar, setShowShadowScrollbar] = React.useState<boolean>(false);

  React.useEffect(() => {
    contentElementStyle.ap(applyStyle('overflowX', 'hidden'));

    const timeoutID = setTimeout(() => {
      // this also covers the case of !scalable (meaning not scaling down to availableWidth)
      // since in that case scale will always be 1 (or more if zoom button is active)
      setShowShadowScrollbar(!!isTouch && scale >= 1);
      contentElementStyle.ap(applyStyle('overflowX', 'initial'));
    }, 300);

    return () => clearTimeout(timeoutID);
  }, [scale, isTouch]);

  const zoomHandle = React.useCallback(
    () => (scale === 1 ? setScale(currentScale) : setScale(1)),
    [scale, currentScale]
  );

  const childProps = {
    ...GEO_GIZMO_DEFAULT_CONTENT,
    ...props,
    ...transformationSettings(props, scale),
    scrollBehaviour,
  };

  const GeoComponent = getGeoComponent(renderMode);

  const withShadowScrollbars = wrapIf(showShadowScrollbar)(
    wrapWithShadowScrollbars(availableWidth, onRef)
  );

  const showZoomButton = currentScale < 1 && $interactionType && scalable && isTouch;
  const scrollLocked = !props.disabled && !scrollEnabledModes.includes(selectedMode);

  return (
    <>
      {showZoomButton && (
        <ZoomButton zoomHandle={zoomHandle}>{scale === 1 ? <ZoomOut /> : <ZoomIn />}</ZoomButton>
      )}
      <span
        className={styles.geoWrapper}
        onFocus={disabled || !$interactionType ? undefined : props.onFocus}
        onTouchStart={scrollLocked ? lockContextYScroll : undefined}
        onTouchEnd={scrollLocked ? unlockContextYScroll : undefined}
      >
        {withShadowScrollbars(<GeoComponent mode={selectedMode} {...childProps} scale={scale} />)}
      </span>
    </>
  );
};

Geo.displayName = 'Geo';
