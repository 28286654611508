import { isEmpty, isString } from 'lodash/fp';
import * as qs from 'query-string';

import { DEFAULT_CONTENT_ROUTE, DEFAULT_MEDIA_ROUTE } from '../../gizmo-utils/constants';
import { type EMPlayerQueryString } from '../../types';

type ParseEMPlayerQueryString = (
  queryString: string
) => Required<Omit<EMPlayerQueryString, 'staticUrl'>> &
  Pick<EMPlayerQueryString, 'staticUrl'> &
  Pick<EMPlayerQueryString, 'forWhiteboard'> & { test: boolean };
export const parseEMPlayerQueryString: ParseEMPlayerQueryString = (queryString) => {
  const parsedQuery: EMPlayerQueryString = qs.parse(queryString, {
    parseBooleans: true,
  });
  return {
    staticUrl: isString(parsedQuery.staticUrl) ? parsedQuery.staticUrl : undefined,
    inBook: Boolean(parsedQuery.inBook),
    test: Boolean(parsedQuery.test),
    forWhiteboard: Boolean(parsedQuery.forWhiteboard),
  };
};

export const getSeriesplayerBaseUrl = (doc = document) => {
  const standaloneIndicators = ['gremlins', 'ngrok', 'seriesplayer', 'localhost', '127.0.0.1'];
  return standaloneIndicators.some((s) => doc.location.origin.includes(s))
    ? doc.location.origin + doc.location.pathname
    : `${doc.location.origin}/app/free-betty/seriesplayer/`;
};

export const getEMPlayeriFrameUrl = (
  locale: string,
  id: string,
  staticServerUrl: string,
  kind: string,
  test = false
): string => {
  const baseUrl = getSeriesplayerBaseUrl();
  return `${baseUrl}#/${kind}/${locale}/${id}?inBook=true&staticUrl=${staticServerUrl.replace(
    /\/+content_exercises/g,
    ''
  )}&test=${test}`;
};

interface ParsedEMPlayerQuery {
  /**
   * This is required to load KEM/FEMs independently and when no staticUrl is
   * provided.
   */
  staticServerUrl: string;
  staticMediaUrl: string;
  test: boolean;
  /**
   * When we provide staticUrl server, we assumed it will be always un-encrypted.
   * We do encrypted only when, we go to default static server.
   */
  encrypt: boolean;
  inBook: boolean;
  forWhiteboard: boolean;
}
type GetStaticServerURL = (emplayerQueryString: EMPlayerQueryString) => ParsedEMPlayerQuery;
export const getStaticServerURL: GetStaticServerURL = ({
  staticUrl,
  test,
  inBook,
  forWhiteboard,
}) => ({
  staticServerUrl: staticUrl
    ? `${staticUrl}/content_exercises`.replace(/\/\/+content_exercises/g, '/content_exercises')
    : DEFAULT_CONTENT_ROUTE,
  staticMediaUrl: DEFAULT_MEDIA_ROUTE,
  test: Boolean(test),
  encrypt: isEmpty(staticUrl),
  inBook: Boolean(inBook),
  forWhiteboard: Boolean(forWhiteboard),
});

export type ReportContentType = 'fem' | 'kem' | 'cri' | 'example';

export const reportHeightToParent = (
  height: number,
  id: string,
  contentType: ReportContentType
) => {
  if (height) {
    window.parent.postMessage(
      {
        height,
        [`${contentType}Id`]: id,
      },
      '*'
    );
  }
};
