import { css, type FlattenInterpolation } from 'styled-components';
import colors from '../../sass/colors';
import { ContextNotificationKind, type ContextNotificationProps } from './types';

const actionCss = css`
  color: ${colors.cBlue700};
  background-color: ${colors.cSelectedBackground};
  border-color: ${colors.cSelectedBorder};
`;

const attentionCss = css`
  color: ${colors.cOrange700};
  background-color: ${colors.cOrange100};
  border-color: ${colors.cOrange600};
`;

const correctCss = css`
  color: ${colors.cTextCorrect};
  background-color: ${colors.cCorrectBackground};
  border-color: ${colors.cCorrectBorder};
`;

const errorCss = css`
  color: ${colors.cTextError};
  background-color: ${colors.cErrorBackground};
  border-color: ${colors.cErrorBorder};
`;

const neutralCss = css`
  color: ${colors.cTextDark};
  background-color: ${colors.cNeutralBackground};
  border-color: ${colors.cNeutralBorder};
`;

const remarkCss = css`
  color: ${colors.cTextRemark};
  background-color: ${colors.cRemarkBackground};
  border-color: ${colors.cRemarkBorder};
`;

const decorationMap: Record<
  ContextNotificationKind,
  FlattenInterpolation<ContextNotificationProps>
> = {
  [ContextNotificationKind.action]: actionCss,
  [ContextNotificationKind.attention]: attentionCss,
  [ContextNotificationKind.correct]: correctCss,
  [ContextNotificationKind.error]: errorCss,
  [ContextNotificationKind.neutral]: neutralCss,
  [ContextNotificationKind.remark]: remarkCss,
};

export const applyDecoration = ({ kind }: ContextNotificationProps) => decorationMap[kind];
