import React, { useEffect, useState } from 'react';
import {
  Aside,
  ButtonLabel,
  ExerciseCircle,
  Footer,
  Header,
  HeaderItems,
  KeyboardWrapper,
  Main,
  Setting,
  Wrapper,
} from './styled-components';
import buttonStyles from './BottomButtons.scss';
import classNames from 'classnames';
import { RedoIcon, UndoIcon } from './icons';
import { KeyCode } from '../seriesplayer/containers/Toolbar/Tools/Keyboard/keyCodes';
import { useDispatch, useSelector } from 'react-redux';

import ScaffoldingGizmoRows from './ScaffoldingGizmoRows';
import {
  createOnKeyGizmoAction,
  KeyboardContainer,
} from '../seriesplayer/containers/Toolbar/Tools/Keyboard/KeyboardContainer';
import { gizmoAction } from '../../gizmo-utils/redux';
import { toggleKeyboardPage } from '../../gizmos/formula/Formula/actions';
import { useTranslation } from 'react-i18next';
import useSetDocumentLanguage from './useSetDocumentLanguage';
import { Icon } from '../../components/index';
import { colors } from '@seriesplayer/common-ui';
import TextWithIcon from './TextWithIcon';
import { useLocation } from 'react-router-dom';
import DummyExercise from './DummyExercise';
import { ApplicationState } from '@seriesplayer/seriesplayer';
import { ContentDict } from '@bettermarks/gizmo-types';
import { initializeDummyRows, resetScaffolder } from './actions';

export type ScaffoldingMode = 'equation' | 'term' | 'skip';

export default function ScaffoldingGizmoRoute() {
  const location = useLocation();

  const modeFromURL = new URLSearchParams(location.search).get('mode');
  const mode: ScaffoldingMode =
    modeFromURL === 'term' ? 'term' : modeFromURL === 'skip' ? 'skip' : 'equation';

  const dispatch = useDispatch();

  const [finished, setFinished] = React.useState<boolean>(false);

  const alertNotAvailableYet = () => {
    alert('Diese Funktionalität steht noch nicht zur Verfügung.');
  };

  const formulas = useSelector((state: ApplicationState) => state.scaffolder?.rows);
  const dummyExerciseInputFields = formulas && filterContentDict(formulas, true);
  const [shouldHandleDummySubmit, setShouldHandleDummySubmit] = useState(false);
  const resetShouldHandleDummySubmit = () => setShouldHandleDummySubmit(false);

  const [shouldValidateScaffolderRow, setShouldValidateScaffolderRow] = useState<boolean>(false);
  const scaffolderRowValidationDone = () => setShouldValidateScaffolderRow(false);

  const headerHeight = 92;
  const footerHeight = 171;
  const scrollableSectionVerticalPadding = 24;
  const [scrollableSectionHeight, setScrollableSectionHeight] = React.useState(
    window.innerHeight - headerHeight - footerHeight - scrollableSectionVerticalPadding
  );
  useEffect(() => {
    const onWindowResize = () => {
      setScrollableSectionHeight(
        window.innerHeight - headerHeight - footerHeight - scrollableSectionVerticalPadding
      );
    };
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [window.innerHeight]);

  useEffect(() => {
    dispatch(initializeDummyRows());
  }, []);

  const [t] = useTranslation();
  useSetDocumentLanguage();

  const exerciseKeys = [1, 2, 3, 4];
  const [currentExerciseKey, setCurrentExerciseKey] = React.useState<number>(1);
  const switchToNextExercise = () => {
    if (currentExerciseKey < 4) {
      setCurrentExerciseKey((prevState) => prevState + 1);
      setFinished(false);
    } else {
      alert('Fertig!');
    }
  };
  const switchToExercise = (exerciseKey: number) => {
    setCurrentExerciseKey(exerciseKey);
    setFinished(false);
    if (currentExerciseKey === 3 && exerciseKey !== 3) {
      dispatch(resetScaffolder());
      dispatch(initializeDummyRows());
    }
  };
  const inDummyExercise = currentExerciseKey !== 3;

  return (
    <Wrapper>
      <Aside>
        {exerciseKeys.map((exerciseKey) => (
          <ExerciseCircle
            key={exerciseKey}
            $focused={exerciseKey === currentExerciseKey}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              switchToExercise(exerciseKey);
            }}
          >
            <Icon
              iconKey={'ExerciseStateNeutral'}
              fill={exerciseKey === currentExerciseKey ? colors.cBlue600 : undefined}
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {exerciseKey}
            </span>
          </ExerciseCircle>
        ))}
      </Aside>
      <Header>
        <HeaderItems>
          <span style={{ fontWeight: 'bold' }}>
            Aufgabe {currentExerciseKey} von {exerciseKeys.length}
          </span>
          <span className={'header-title'}>{currentExerciseKey}.1 Prototyp Nutzertest</span>
        </HeaderItems>
        <HeaderItems>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '6px' }}>
            <TextWithIcon
              onClick={() => {
                alertNotAvailableYet();
              }}
              icon={{ key: 'ReportProblemXLarge' }}
              text={t('seriesplayer:header.report')}
            />
          </div>
          <div
            style={{
              boxSizing: 'border-box',
              color: 'white',
              minHeight: '36px',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              columnGap: '6px',
              backgroundColor: 'rgb(68, 170, 255)',
              borderRadius: '0.3rem',
              padding: '4px 8px',
            }}
          >
            <TextWithIcon
              onClick={() => {
                alertNotAvailableYet();
              }}
              color={'white'}
              text={t('seriesplayer:header.help')}
              icon={{ key: 'LifeRingXLarge' }}
            />
          </div>
          <div
            onClick={() => {
              alertNotAvailableYet();
            }}
            style={{ cursor: 'pointer' }}
          >
            <Icon iconKey="TimesLarge" />
          </div>
        </HeaderItems>
      </Header>
      <Main>
        {inDummyExercise ? (
          dummyExerciseInputFields && (
            <DummyExercise
              mode={mode}
              exerciseKey={currentExerciseKey}
              contentDict={dummyExerciseInputFields}
              shouldHandleSubmit={shouldHandleDummySubmit}
              resetShouldHandleSubmit={resetShouldHandleDummySubmit}
              setFinished={setFinished}
            />
          )
        ) : (
          <>
            <Setting>
              <p>
                {mode === 'equation'
                  ? t('seriesplayer:scaffolding:subheading')
                  : 'Vereinfache den Term so weit wie möglich.'}
              </p>
            </Setting>

            <div style={{ maxHeight: scrollableSectionHeight, padding: '12px' }}>
              <ScaffoldingGizmoRows
                mode={mode}
                originalExpression={
                  mode === 'equation'
                    ? '6x + 4 = 3x + 16'
                    : mode === 'term'
                    ? '4 + 5 * (14 - 8)'
                    : '14,96 + 2,58 * (4,48 : 2,8)'
                }
                finished={finished}
                setFinished={setFinished}
                rowValidationNeededFromProps={shouldValidateScaffolderRow}
                rowValidationFromPropsFinished={scaffolderRowValidationDone}
              />
            </div>
          </>
        )}
      </Main>
      {!finished && (
        <KeyboardWrapper>
          <div style={{ maxWidth: '720px' }}>
            <KeyboardContainer
              operators={[
                KeyCode.Add,
                KeyCode.Subtract,
                KeyCode.Divide,
                KeyCode.Multiply,
                KeyCode.BracketLeft,
                KeyCode.BracketRight,
              ]}
              availableWidth={720}
              hasNumbers
              hasLetters
              onKey={(gizmoId: string) => (key: string) => {
                dispatch(createOnKeyGizmoAction(gizmoId)(key));
              }}
              onTogglePage={(refid: string) => (index: number) => {
                dispatch(gizmoAction(toggleKeyboardPage(index), refid, { skipUndo: true }));
              }}
            ></KeyboardContainer>
          </div>
        </KeyboardWrapper>
      )}
      <Footer>
        <div
          className={classNames(
            buttonStyles.button,
            buttonStyles.buttonTransparent,
            buttonStyles.buttonToolpad
          )}
          onClick={alertNotAvailableYet}
        >
          <div className={buttonStyles.buttonBody}>
            <UndoIcon />
          </div>
        </div>
        <div
          className={classNames(
            buttonStyles.button,
            buttonStyles.buttonTransparent,
            buttonStyles.buttonToolpad
          )}
          onClick={alertNotAvailableYet}
        >
          <div className={classNames(buttonStyles.buttonBody, buttonStyles.buttonTransparent)}>
            <RedoIcon />
          </div>
        </div>

        {!finished && !inDummyExercise && (
          /* SKIP EXERCISE BUTTON */
          <div
            className={classNames(buttonStyles.button, buttonStyles.buttonCancel)}
            onClick={switchToNextExercise}
          >
            <div className={buttonStyles.buttonBody}>
              <ButtonLabel className={buttonStyles.buttonLabel}>Überspringen</ButtonLabel>
            </div>
          </div>
        )}

        {finished ? (
          /* NEXT EXERCISE BUTTON */
          <div
            className={classNames(buttonStyles.button)}
            onClick={() => {
              switchToNextExercise();
            }}
          >
            <div className={buttonStyles.buttonBody}>
              <ButtonLabel className={buttonStyles.buttonLabel}>
                {t('seriesplayer:scaffolding:nextExercise')}
              </ButtonLabel>
            </div>
          </div>
        ) : (
          /* OK/SUBMIT BUTTON */
          <div
            className={classNames(buttonStyles.button, buttonStyles.buttonSubmit)}
            onClick={() => {
              inDummyExercise
                ? setShouldHandleDummySubmit(true)
                : setShouldValidateScaffolderRow(true);
            }}
          >
            <div className={buttonStyles.buttonBody}>
              <ButtonLabel className={buttonStyles.buttonLabel}>OK</ButtonLabel>
            </div>
          </div>
        )}
      </Footer>
    </Wrapper>
  );
}

export const rowsFromState = (state: ApplicationState) =>
  state.scaffolder?.rows ? filterContentDict(state.scaffolder?.rows, false) : {};

export const filterContentDict = (contentDict: ContentDict, keepDummies: boolean) => ({
  ...Object.keys(contentDict)
    .filter((key) => key.startsWith('dummy') === keepDummies)
    .reduce((newContentDict, key) => {
      newContentDict[key] = contentDict[key];
      return newContentDict;
    }, {}),
});
