import {
  annotationInner,
  type Exporter,
  type ExporterContext,
  semantics,
  type ThermometerConfiguration,
  type ThermometerContent,
} from '@bettermarks/gizmo-types';

/**
 *It accepts the config object and returns the configuration xml
 * @param config
 */
const exportConfiguration = (config: ThermometerConfiguration): string => {
  return `
    <configuration>
      <min>${config.min}</min>
      <max>${config.max}</max>
      ${config.label ? `<label>${config.label}</label>` : ''}
    </configuration>
  `;
};
/**
 * It exports the Thermometer Content to XML.
 * @param contentRefId
 * @param context
 */
export const exportThermometer: Exporter = (
  contentRefId: string,
  context: ExporterContext
): string => {
  const content: ThermometerContent = context.content[contentRefId] as ThermometerContent;
  const { severity } = content;
  return semantics(
    `
      <mrow ${severity ? `decoration ='${severity}'` : ''}>
      ${exportConfiguration(content.configuration)}
      ${`<mn>${content.value}</mn>`}
      </mrow>
    `,
    annotationInner(content.$, content)
  );
};
