import { has, isEmpty, omit } from 'lodash';

import { type GeoContentPersistProps } from '@bettermarks/gizmo-types';
import { type IVertex, Vertex } from './Vertex';

/**
 * Returns a clone of GeoContentPersistProps where all valid geoObjectIds have
 * been removed
 * @param {GeoContentPersistProps} geoContentPersistProps
 * @param {...(keyof GeoContentPersistProps['geoContentMap'])[]} geoObjectIds
 * @returns {GeoContentPersistProps}
 *
 * ### THE DATA STRUCTURE
 * GeoContentPersistProps is a compound data structure that has the
 * traits of both a Dictionary and of a Directed Graph.
 *
 * A directed graph (or digraph) is a graph that is made up of a set of
 * vertices connected by directed edges often called arcs.
 * `geoContentMap` is implemented as a regular Dictionary/Object but it is
 * trying to model a `directed graph`.
 *
 * In this context each `GeoObject` is a vertex. The representation of the
 * relationship between vertices, the edges, are store as a list of vertices ids
 * within the `referencedBy` and `referringTo` GeoObject properties.
 * The direction of the relationship for a vertex can be map as follows:
 * * INCIDENT EDGES or `HEADS` are expressed by the `referencedBy` property.
 * * OUTBOUND EDGES OR `TAILS` are expressed by the `referringTo` property.
 */
export const removeVertices = (
  geoContentPersistProps: GeoContentPersistProps,
  ...[
    vertexIdToRemove,
    ...nextVerticesIdsToRemove
  ]: (keyof GeoContentPersistProps['geoContentMap'])[]
): GeoContentPersistProps => {
  type GeoContentMap = GeoContentPersistProps['geoContentMap'];
  // base case: if no elements are marked to be removed then simply return
  if (isEmpty(vertexIdToRemove)) return geoContentPersistProps;

  // validate that the provided geoObjectIds exist in the data structure
  if (!has(geoContentPersistProps, ['geoContentMap', vertexIdToRemove]))
    return removeVertices(geoContentPersistProps, ...nextVerticesIdsToRemove);

  const currentVertex: IVertex = Vertex.fromGeoContentMap(
    geoContentPersistProps.geoContentMap,
    vertexIdToRemove
  );

  // THE RULE FOR REMOVAL:
  if (currentVertex.hasHeads()) {
    /*
     * GIVEN a single vertex (A) to delete
     * IF it has one or more `heads` / `INCIDENT edges` (e.g. a Line (A) referencedBy a Label (B))
     * THEN
     *    FOR all those (n) edges,
     *      visit each vertex (N),
     *        cut the graph by removing all the outbound references pointing back to the (A),
     *        references stored in the `referringTo` property,
     *      AND
     *        mark N Vertex for deletion.
     * FINALLY
     *    remove the requested vertex (A).
     */

    const incidentEdgesID = currentVertex.getHeads();
    const geoContentMap: GeoContentMap = incidentEdgesID.reduce(
      (_geoContentMap, incidentEdgeID) => {
        const incidentVertex: IVertex = Vertex.fromGeoContentMap(_geoContentMap, incidentEdgeID);
        return {
          ..._geoContentMap,
          [incidentEdgeID]: incidentVertex.removeTail(currentVertex.vertexId).toGeoObject(),
          [currentVertex.vertexId]: currentVertex.removeHead(incidentEdgeID).toGeoObject(),
        };
      },
      geoContentPersistProps.geoContentMap
    );

    const geoContentPersistPropsNextState: GeoContentPersistProps = {
      ...geoContentPersistProps,
      geoContentMap,
    };

    // now we can recurse while adding all incident edges to the deletion list
    return removeVertices(
      geoContentPersistPropsNextState,
      currentVertex.vertexId,
      ...incidentEdgesID,
      ...nextVerticesIdsToRemove
    );
  } else if (currentVertex.hasTails()) {
    /*
     * GIVEN a single vertex (A) to delete
     * IF it has one or more `tails` / `OUTBOUND edges`
     *    (e.g. a Line (A) referringTo Point (C) and (D))
     * THEN
     *    FOR all those (n) edges,
     *      visit each vertex (N),
     *        cut the graph by removing all the incident references pointing back to the (A),
     *        references stored in the `referencedBy` property,
     * FINALLY
     *    remove the request vertex (A).
     */
    const outboundEdgesID = currentVertex.getTails();

    const geoContentMap: GeoContentMap = outboundEdgesID.reduce(
      (_geoContentMap, outboundEdgeId) => {
        const outboundVertex: IVertex = Vertex.fromGeoContentMap(_geoContentMap, outboundEdgeId);
        return {
          ..._geoContentMap,
          [outboundEdgeId]: outboundVertex.removeHead(currentVertex.vertexId).toGeoObject(),
          [currentVertex.vertexId]: currentVertex.removeTail(outboundEdgeId).toGeoObject(),
        };
      },
      geoContentPersistProps.geoContentMap
    );

    /**
     * By eliminating all `tails` / `outbound edges` we have actually cut the directed graph
     * into subsets.
     */
    const geoContentPersistPropsNextState: GeoContentPersistProps = {
      ...geoContentPersistProps,
      geoContentMap,
    };

    /**
     * We can now recurse and let the other code branches take care of eliminating
     * all the current vertex.
     */
    return removeVertices(
      geoContentPersistPropsNextState,
      vertexIdToRemove,
      ...nextVerticesIdsToRemove
    );
  } else {
    /*
     * GIVEN a single vertex (A) to delete (e.g. a Point (D) )
     * IF it has NO `HEADS` / `INCIDENT EDGES` && NO  TAILS `OUTBOUND edges`
     * THEN
     *    it can be safely removed.
     */

    /** Do the deletion of the Vertex from the geoContentMap */
    const geoContentMap: GeoContentMap = omit(
      geoContentPersistProps.geoContentMap,
      vertexIdToRemove
    );

    const vertexType = currentVertex.getVertexType();

    /**
     * Removes the references of vertexIdToRemove from the map of vertices types.ts
     */
    const typeMap: Omit<GeoContentPersistProps, 'geoContentMap'> = {
      ...omit(geoContentPersistProps, 'geoContentMap'),
      [vertexType]: geoContentPersistProps[vertexType].filter(
        (id) => id !== currentVertex.vertexId
      ),
    };

    const geoContentPersistPropsNextState: GeoContentPersistProps = {
      ...geoContentPersistProps,
      ...typeMap,
      geoContentMap,
    };
    return removeVertices(geoContentPersistPropsNextState, ...nextVerticesIdsToRemove);
  }
};
