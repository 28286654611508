import { type Action } from 'redux-actions';
import {
  AppExercise,
  ApplicationState,
  type AppStep,
  DialogType,
  ErrorHandleKind,
  SeriesMode,
  StepStatus,
} from '../../../../types';
import { call, put, select } from 'redux-saga/effects';
import { isUndefined } from 'lodash';
import { saveStepResult as saveStepResultUnstubable } from '../../services/api/result-manager';
import { getStepResult } from '../../reducers/reporting';
import {
  ErrorReason,
  isNetworkException,
  isTestHasBeenCollectedException,
} from '../../services/exception';
import { openDialog } from '../SeriesPlayer/actions';
import { nextExercise, switchExercise } from './actions';
import { getStepTimeUsed } from './seriesSaga';

export const STUBABLE = {
  saveStepResult: saveStepResultUnstubable,
};

type StepInfo = { step: AppStep; stepIndex: number } | undefined;

const getStepInfoIfReportNeeded = (state: ApplicationState): StepInfo => {
  const exercise = ApplicationState.toCurrentExercise.get(state);
  const step = AppExercise.toCurrentStep.get(exercise);

  if (step && step.status !== StepStatus.completed) {
    const isTestMode = ApplicationState.toSeriesMode.get(state) === SeriesMode.test;
    const reporting = ApplicationState.toSeriesReportingSetting.get(state);
    const question = ApplicationState.getCurrentUndoableQuestion(state);
    const questionHasBeenTouched = !!(question && (question.future.length || question.past.length));

    if (isTestMode && reporting && questionHasBeenTouched) {
      const stepIndex = AppExercise.getCurrentStepIndex(exercise.steps, exercise.currentStepId);
      return { step, stepIndex };
    }
  }
};

export function* handleTestMode() {
  const state: ApplicationState = yield select();
  const stepInfo = getStepInfoIfReportNeeded(state);

  if (stepInfo) {
    const { step, stepIndex } = stepInfo;
    const { currentExerciseIndex } = state.series;
    yield call(
      STUBABLE.saveStepResult,
      state.appSettings.resultManagerUrl,
      getStepResult(state.series, currentExerciseIndex, stepIndex, 0, getStepTimeUsed(step)),
      true // use axios to have timeout
    );
  }
}

/**
 * This saga us used for switching exercises (next-exercise/switch-exercise). Additionally
 * the user input is saved for testmode with reporting enabled.
 *
 * @param index The exercise index to switch to.
 */
export function* switchExerciseSaga({ payload: index }: Action<number | undefined>) {
  try {
    yield handleTestMode();
    if (index !== -1) {
      yield put(isUndefined(index) ? nextExercise() : switchExercise(index));
    }
  } catch (err) {
    if (isTestHasBeenCollectedException(err)) {
      yield put(
        openDialog({
          type: DialogType.error,
          payload: {
            kind: ErrorHandleKind.quit,
            reasonCode: ErrorReason.TEST_HAS_BEEN_COLLECTED,
          },
        })
      );
    }
    if (isNetworkException(err)) {
      yield put(openDialog({ type: DialogType.retry }));
    }
  }
}
