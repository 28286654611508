import * as React from 'react';
import { compact, isNil } from 'lodash';
import { DEFAULT_MATH_CONTENT, type ParametricFunction } from '@bettermarks/gizmo-types';
import { functionToFormula, slopeFormula, valueFormula } from './formula';
import { Formula } from '../formula/Formula';
import { enrichSingleFormula } from '../formula/measure';

import styles from './FormulaList.scss';

const PREFIX_MARGIN = 5;

export interface FormulaListProps {
  availableWidth: number;
  formulas: ParametricFunction[];
  isSmallScreen: boolean;
}

const FormulaList: React.FC<FormulaListProps> = ({ availableWidth, isSmallScreen, formulas }) => {
  return (
    <ul className={isSmallScreen ? styles.formulasMobile : styles.formulas}>
      {formulas.map((f) =>
        compact([
          !isNil(f.prefix) && (
            <li key={`f_${f.id}`}>
              <Formula
                {...enrichSingleFormula({
                  ...DEFAULT_MATH_CONTENT,
                  content: [...f.prefix.content, ...functionToFormula(f).content],
                })}
                availableWidth={availableWidth}
              />
            </li>
          ),
          !isNil(f.valuePrefix) && (
            <li key={`value_${f.id}`}>
              <Formula
                {...enrichSingleFormula({
                  ...DEFAULT_MATH_CONTENT,
                  content: [
                    ...f.valuePrefix.content,
                    { $: 'mspace', lineHeight: NaN, width: PREFIX_MARGIN },
                    ...valueFormula(f).content,
                  ],
                })}
                availableWidth={availableWidth}
              />
            </li>
          ),
          !isNil(f.slopePrefix) && (
            <li key={`slope_${f.id}`}>
              <Formula
                {...enrichSingleFormula({
                  ...DEFAULT_MATH_CONTENT,
                  content: [
                    ...f.slopePrefix.content,
                    { $: 'mspace', lineHeight: NaN, width: PREFIX_MARGIN },
                    ...slopeFormula(f).content,
                  ],
                })}
                availableWidth={availableWidth}
              />
            </li>
          ),
        ])
      )}
    </ul>
  );
};

FormulaList.displayName = 'FormulaList';

export default FormulaList;
