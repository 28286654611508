import { type Action, createAction, handleActions } from 'redux-actions';
import { first, isEmpty } from 'lodash';
import { GEO_DEFAULT_PERSIST_PROPS } from '@bettermarks/importers';
import { DEFAULT_ADD_LABEL_STATE } from '../constants';
import { type GeoAddLabelState } from './GeoAddLabel';
import {
  type GeoContentPersistProps,
  Hover,
  type LabelableObject,
  type LabelObject,
  type LabelValuesMap,
  POINT,
  type SnapPoint,
  type Coords,
} from '@bettermarks/gizmo-types';
import { confirmLabel, deleteLabel } from './helpers';

/**
 * +--------------------------+
 * +--- PAYLOAD INTERFACES ---+
 * +--------------------------+
 */
export interface SnapPayload {
  snapPoint: SnapPoint | null;
  labelValues: LabelValuesMap;
  isTouch: boolean;
}

export interface ClickPayload {
  id: string;
  labelValues: LabelValuesMap;
  mouseScreenCoords?: Coords;
}

export interface LabelClickPayload extends ClickPayload {
  id: string;
}

export interface ConfirmLabelPayload {
  index: number;
  labelAlignTop: boolean;
  onPersistLocalState: (props: GeoContentPersistProps) => void;
  mouseWorldCoords?: Coords;
}

export interface DeleteLabelPayload {
  onPersistLocalState: (props: GeoContentPersistProps) => void;
}

export interface ToggleLineHoverPayload {
  id: string;
  hover: boolean;
}

export type GeoAddLabelPayload =
  | SnapPayload
  | ClickPayload
  | LabelClickPayload
  | ConfirmLabelPayload
  | DeleteLabelPayload
  | ToggleLineHoverPayload
  | void;

/**
 * +---------------+
 * +--- ACTIONS ---+
 * +---------------+
 */
const SNAP: 'SNAP' = 'SNAP';
export const snapAction = createAction<SnapPayload>(SNAP);
const STOP_SNAP: 'STOP_SNAP' = 'STOP_SNAP';
export const stopSnapAction = createAction(STOP_SNAP);
const CLICK: 'CLICK' = 'CLICK';
export const clickAction = createAction<ClickPayload>(CLICK);
const LABEL_CLICK: 'LABEL_CLICK' = 'LABEL_CLICK';
export const labelClickAction = createAction<LabelClickPayload>(LABEL_CLICK);
const CONFIRM_LABEL: 'CONFIRM_LABEL' = 'CONFIRM_LABEL';
export const confirmLabelAction = createAction<ConfirmLabelPayload>(CONFIRM_LABEL);
const DELETE_LABEL: 'DELETE_LABEL' = 'DELETE_LABEL';
export const deleteLabelAction = createAction<DeleteLabelPayload>(DELETE_LABEL);
const CLOSE_LABEL: 'CLOSE_LABEL' = 'CLOSE_LABEL';
export const closeLabelAction = createAction(CLOSE_LABEL);
const TOGGLE_LINE_HOVER: 'TOGGLE_LINE_HOVER' = 'TOGGLE_LINE_HOVER';
export const toggleLineHoverAction = createAction<ToggleLineHoverPayload>(TOGGLE_LINE_HOVER);

/**
 * +-----------------------------------+
 * +--- REDUCER (and initial state) ---+
 * +-----------------------------------+
 */
export const initialState = {
  ...DEFAULT_ADD_LABEL_STATE,
  persistProps: GEO_DEFAULT_PERSIST_PROPS,
  snapPoints: [],
  activeIndex: {},
};

export const geoAddLabelReducer = handleActions<GeoAddLabelState, GeoAddLabelPayload>(
  {
    [SNAP]: (state: GeoAddLabelState, { payload }: Action<SnapPayload>) => {
      if (!payload) {
        return state;
      }

      const { isTouch, snapPoint, labelValues } = payload;

      if (!state.active && snapPoint) {
        if (
          snapPoint.snapObject in labelValues &&
          !state.persistProps.geoContentMap[snapPoint.id].notLabelable
        ) {
          return {
            ...state,
            // ToDo: currently that's the only place where we set this id
            // => needs to be resolved when implementing touch mode
            labelingObjectId: snapPoint.id,
            labelingObjectType: snapPoint.snapObject as LabelableObject,
            snapPoints: snapPoint.snapObject === POINT && !isTouch ? [snapPoint] : [],
          };
        } else {
          return {
            ...state,
            snapPoints: [],
            labelingObjectId: '',
          };
        }
      }

      return {
        ...state,
        snapPoints: [],
      };
    },
    [STOP_SNAP]: (state: GeoAddLabelState) => ({ ...state, snapPoints: [] }),
    [CLICK]: (state: GeoAddLabelState, { payload }: Action<ClickPayload>) => {
      if (!payload || state.active) {
        return state;
      }

      const { id, labelValues, mouseScreenCoords } = payload;

      const labelingObjectType = isEmpty(state.labelingObjectId)
        ? (state.persistProps.geoContentMap[id].type as LabelableObject)
        : state.labelingObjectType;

      const labelingObjectId = isEmpty(state.labelingObjectId) ? id : state.labelingObjectId;

      if (
        !(labelingObjectType in labelValues) ||
        state.persistProps.geoContentMap[labelingObjectId].notLabelable
      ) {
        return state;
      }

      return {
        ...state,
        labelingObjectId,
        labelingObjectType,
        labelList: labelValues[labelingObjectType].geoLabels,
        pickerList: labelValues[labelingObjectType].pickerLabels,
        active: true,
        snapPoints: [],
        mouseScreenCoords,
      };
    },
    [LABEL_CLICK]: (state: GeoAddLabelState, { payload }: Action<LabelClickPayload>) => {
      if (!payload) {
        return state;
      }

      const { id, labelValues } = payload;
      const label = state.persistProps.geoContentMap[id] as LabelObject;
      const labelingObjectId = first(label.referringTo) as string;
      const labelingObjectType = state.persistProps.geoContentMap[labelingObjectId].type;

      // handle case that a label overlaps another object
      // (snapPoint belongs to another object one wants to label)
      if (
        state.snapPoints.length > 0 &&
        labelingObjectId !== (first(state.snapPoints) as SnapPoint).id
      ) {
        return state;
      }

      return {
        ...state,
        labelingObjectId,
        labelList: labelValues[labelingObjectType].geoLabels,
        pickerList: labelValues[labelingObjectType].pickerLabels,
        active: true,
      };
    },
    [CONFIRM_LABEL]: (state: GeoAddLabelState, { payload }: Action<ConfirmLabelPayload>) =>
      payload ? confirmLabel(state, payload) : state,
    [DELETE_LABEL]: (state: GeoAddLabelState, { payload }: Action<DeleteLabelPayload>) =>
      payload ? deleteLabel(state, payload) : state,
    [CLOSE_LABEL]: (state: GeoAddLabelState) => ({
      ...initialState,
      persistProps: state.persistProps,
    }),
    [TOGGLE_LINE_HOVER]: (state: GeoAddLabelState, { payload }: Action<ToggleLineHoverPayload>) => {
      if (!payload) {
        return state;
      }

      const { id, hover } = payload;

      if (!(id in state.persistProps.geoContentMap)) {
        return state;
      }

      return {
        ...state,
        persistProps: {
          ...state.persistProps,
          geoContentMap: {
            ...state.persistProps.geoContentMap,
            [id]: {
              ...state.persistProps.geoContentMap[id],
              hover: hover ? Hover.DEFAULT : undefined,
            },
          },
        },
      };
    },
  },
  initialState
);
