import {
  $MROW,
  createImporterContext,
  DEFAULT_MATH_CONTENT,
  type FElement,
  FunctionDerivative,
  type FunctionPlotterContent,
  type Importer,
  LAYER,
  LineWeight,
  type ParametricFunction,
  parseCommonAttribs,
  RenderMode,
  RS,
  SEMANTICS,
  SLIDER,
  toBoolean,
  toInt,
  toXmlElement,
} from '@bettermarks/gizmo-types';
import {
  curry,
  flatten,
  intersectionWith,
  isEmpty,
  isEqual,
  isNaN,
  isNil,
  isNumber,
  omit,
  omitBy,
  takeWhile,
  xorWith,
} from 'lodash';
import { importSingleElement, parseDecoration } from '../formula/importer';
import { importDecorationMap, importGeo } from '../geo/importer';
import { importFunction, importFunctionParameters } from './helper';
import { importDynamicFormula } from './importDynamicFormula';
import { importSlider } from './importSliders';

const LABEL_MAP = {
  'ghost:label': 'ghostLabel',
  'graph:label': 'graphLabel',
  'tangent:label': 'tangentLabel',
  'secant:label:sx1': 'secantLabel1',
  'secant:label:sx2': 'secantLabel2',
  vertex: 'vertexLabel',
};

/**
 * import additionalPoionts Tag
 * @param {FElement} xml
 *
 * @returns {any}
 *
 * something like that:
 *  <g:additionalPoints>
 *     <g:label type="graph:label">f</g:label>
 *     <g:label
 *        type="[vertex|tangent:label|secant:label]"
 *        extended="true|false"
 *        decoration="color:bm-green"
 *        reading-help="true">
 *        S
 *     </g:label>
 *     <g:intersectionLine x1="0" y1="3" x2="0" y2="5" decoration="color:bm-green"/>
 *   </g:additionalPoints>
 */
export const importAdditionalPoints = (xml: FElement): Partial<ParametricFunction> => ({
  ...xml.getChildrenByTagName('label').reduce(
    (acc, label) => ({
      ...acc,
      [LABEL_MAP[label.attribute('type') as keyof typeof LABEL_MAP]]: {
        decoration: importDecorationMap(label.attribute('decoration')).decoration,
        readinghelp: toBoolean(label.attribute('reading-help', 'false')),
        label: !label.hasChildren()
          ? label.text
          : importSingleElement(label.firstChild, createImporterContext()),
      },
    }),
    {}
  ),
  ...omitBy(
    {
      intersectionLines: [
        ...xml.getChildrenByTagName('intersectionLine').map((il) => {
          const { decoration } = importDecorationMap(il.attribute('decoration'));
          return {
            ...il.attributesToProps(parseFloat, ['x1', 'y1', 'x2', 'y2']),
            ...omitBy({ decoration }, isEmpty),
          };
        }),
      ],
    },
    isEmpty
  ),
});

/**
 * import the additionalLines tag
 * @param {FElement} xml
 * @returns {any}
 *
 * something like that:
 *
 * <g:additionalLines>
 *   <!-- Tangente als Gerade (length nicht angegeben) -->
 *   <g:tangent decoration="color:bm-green"/>
 *   <!-- Tangente als Strecke mit bestimmter Länge -->
 *   <g:tangent length="4" decoration="color:bm-green"/>
 *   <!-- Sekante als Gerade -->
 *   <g:secant decoration="color:bm-steelblue"/>
 *   <!-- Konfiguration für Ablesehilfe nach Typ -->
 *   <g:readinghelp
 *     type="[wie bei additionalPoints]"
 *     restrict="[horizontal|vertical]"
 *     straightLine="[true|false]"
 *     decoration="color:bm-grey;line-weight:thin; line-style:dashed"
 *   />
 * </g:additionalLines>
 *
 */
export const importAdditionalLines = (
  xml: FElement,
  readinghelpMap: { [key: string]: string } = {
    'secant:label:sx1': 'secantReadinghelp1',
    'secant:label:sx2': 'secantReadinghelp2',
    'tangent:label': 'tangentReadinghelp',
    vertex: 'vertexReadinghelp',
  }
): Partial<ParametricFunction> =>
  omitBy(
    {
      ...['tangent', 'secant'].reduce(
        (acc, tag) => ({
          ...acc,
          [tag]: omitBy(
            {
              decoration: importDecorationMap(xml.findChildTag(tag).attribute('decoration'))
                .decoration,
              length: toInt(xml.findChildTag(tag).attribute('length')),
            },
            (value) => (isNumber(value) ? isNaN(value) : isEmpty(value))
          ),
        }),
        {}
      ),
      ...xml.getChildrenByTagName('readinghelp').reduce(
        (acc, tag) => ({
          ...acc,
          [readinghelpMap[tag.attribute('type')]]: omitBy(
            {
              straightLine: toBoolean(tag.attribute('straightLine', 'false')),
              decoration: importDecorationMap(tag.attribute('decoration')).decoration,
              restrict: tag.attribute('restrict'),
            },
            isEmpty
          ),
        }),
        {}
      ),
    },
    isEmpty
  );

export const importGeoBezierSet = (bezierSet: FElement): ParametricFunction[] =>
  bezierSet.getChildrenByTagName('bezierFunction').map(
    (
      b: FElement,
      _,
      __,
      formula = b.findChildTag('formula'),
      functionDef = importFunction(formula.findChildTag('functionName'))
    ): ParametricFunction => ({
      ...functionDef,
      parameters: importFunctionParameters(formula.findChildTag('parameters')),
      id: b.attribute('id'),
      decoration: {
        ...importDecorationMap(b.attribute('decoration')).decoration,
        lineWeight: LineWeight.medium,
      },
      renderMode: RenderMode.Graph,
      ...importAdditionalPoints(formula.findChildTag('additionalPoints')),
      ...importAdditionalLines(formula.findChildTag('additionalLines')),
    })
  );

/**
 * Two functions are equal, if their parameters are equal. This means not only
 * the values must be equal, but also names and controlling sliders!
 */
const isEqualFun = curry((one: ParametricFunction, other: ParametricFunction): boolean =>
  isEqual(one.parameters, other.parameters)
);

export const getPrefix = (f: ParametricFunction) =>
  isNil(f.derivative)
    ? { prefix: f.prefix }
    : f.derivative === FunctionDerivative.slope
    ? {
        slopePrefix: f.prefix,
        x: f.x,
      }
    : {
        valuePrefix: f.prefix,
        x: f.x,
      };

/**
 * Merge two functions. This is primarily done, to merge slope, value & formula
 * display to one data structure. The prefix math content is collected in
 * different optional propeties.
 * This ensures a single source of truth for a parametric function.
 */
export const mergeFuns = (f1: ParametricFunction, f2: ParametricFunction): ParametricFunction => ({
  ...f1,
  ...getPrefix(f2),
});

/**
 *
 */
const mergeEqualFuns = (fs: ParametricFunction[]): ParametricFunction[] =>
  fs.reduce(
    (acc, f, _, __, i = acc.findIndex(isEqualFun(f))) =>
      i >= 0
        ? [
            // found one -> merge it with the new function
            ...acc.slice(0, i),
            mergeFuns(acc[i], f),
            ...acc.slice(i),
          ]
        : [
            // first of it's kind -> just map the prefix to the right property
            ...acc,
            {
              ...omit(f, ['prefix']),
              ...getPrefix(f),
            },
          ],
    []
  );

const collectPanels = (xml: FElement): FElement[] =>
  xml.localName === SEMANTICS && parseCommonAttribs(xml).$renderStyle === RS.PANEL
    ? [xml] // do not descent!
    : flatten(xml.children.map(collectPanels));

/**
 * import function plotter already expects a horizontal layout with a certain
 * structure. This means that the actual horizontal-layout-importer should
 * decide to call it or not, if it encounters the required structure.
 * horizontal-layout
 * - geo
 * - vertical-layout
 *   - panel
 *     - vertical-layout
 *       - formula
 *         - dynamic formula
 *       - sliders
 */
export const importFunctionPlotter: Importer<FunctionPlotterContent> = (
  // layout comes from the outer LayoutContainer we don't want it
  { layout, ...preContent },
  xml,
  context
) => {
  const xmlStr = xml
    .toString()
    // replacing templating syntax (e.g. {0}) with different templating syntax ($0),
    // that does not break the XMLParser of the validator
    .replace(/{([0-9])}/g, (_, p1) => `$${parseInt(p1, 10)}`)
    // replace double quotes with single quotes in order to prevent unreadable user submission JSON
    .replace(/"/g, "'");
  const fpXML = toXmlElement(xmlStr);

  const result: FunctionPlotterContent = {
    ...preContent,
    $renderStyle: RS.FUNCTION_PLOTTER,
    functions: [],
    xml: xmlStr,
  };

  const panels = collectPanels(fpXML);
  if (panels.length === 0) {
    return result;
  }

  // we assume only the second panel contains dynamic formulas
  const panelXml = panels.length === 2 ? panels[1] : panels[0];

  result.decoration = parseDecoration(
    panelXml.findChildTag($MROW).attribute('decoration')
  ).decoration;

  const percentWidth = panelXml
    .findChildTag($MROW)
    .findChildTag('configuration')
    .findChildTag('percentWidth');
  if (percentWidth) {
    result.percentWidth = parseFloat(percentWidth.text);
  }

  const geoParent = fpXML.findChildTag($MROW).findChildTag($MROW);
  if (geoParent) {
    const geoxml = geoParent.findChildTag(SEMANTICS);
    result.geo = context.invoke(importGeo, geoxml);

    /* additionally, we want to extract function plotter specific content in geo ...
      this content is only placed under 'bezier-set' ... */
    const bezierSet = geoxml
      .findChildTag('system')
      .findChildTagWithAttribute(LAYER, 'type', 'bezier-set');
    result.functions = importGeoBezierSet(bezierSet);
  }

  const verticalLayout = panelXml.findChildTag($MROW).findChildTag(SEMANTICS);
  const formulaSemantics = verticalLayout.hasChild($MROW)
    ? verticalLayout.findChildTag($MROW).children
    : [verticalLayout.findChildTag(SEMANTICS)];

  const preContents = formulaSemantics.map((x) => ({
    preContent: parseCommonAttribs(x),
    xml: x,
  }));

  const sliders = preContents
    .filter((x) => x.preContent.$renderStyle === RS.SLIDER)
    .map((x) => importSlider(x.xml, context));

  /**
   * In questions and answers the `rendererAllowsInteraction` is set to false
   * so it can be disabled when the user input is shown.
   * The part of enrichment that takes care of disabling only treats interactive gizmos
   * so we set a pseudo $interactionType to be able to disable it
   */
  if (sliders.find((s) => !s.rendererAllowsInteraction)) {
    result.$interactionType = SLIDER;
  }

  const formulas = mergeEqualFuns(
    preContents
      .filter((x) => x.preContent.$renderStyle === RS.FORMULA) // get the formula nodes
      .map((x) => ({
        // import
        ...importDynamicFormula(x.xml.findChildTag($MROW).findChildTag(SEMANTICS)),
        renderMode: RenderMode.Formula,
        prefix: {
          // all prefixing math nodes
          ...DEFAULT_MATH_CONTENT,
          content: takeWhile(
            x.xml.findChildTag($MROW).children,
            (x) => x.localName !== SEMANTICS
          ).map((x) => importSingleElement(x, createImporterContext())),
        },
      }))
  );

  // find functions that are only in the geo data or only in the formula data
  const diff = xorWith(result.functions, formulas, isEqualFun);
  // find common functions
  const intersection = intersectionWith(result.functions, formulas, isEqualFun);
  // merge both sets, while merging common functions to single objects
  result.functions = [
    ...diff,
    ...intersection.map((f) => ({
      ...f,
      ...formulas.find(isEqualFun(f)),
      /* eslint-disable-next-line no-bitwise */
      renderMode: RenderMode.Graph | RenderMode.Formula,
    })),
  ];

  // merge slider parameters
  result.functions = result.functions.map((f) => ({
    ...f,
    parameters: f.parameters.map((p) => ({
      ...p,
      ...(!isNil(p.refId) && sliders.find((s) => s.refId === p.refId)),
    })),
  }));

  return result;
};
