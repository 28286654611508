import classNames from 'classnames';
import { map } from 'lodash';
import { groupBy } from 'lodash/fp';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './GizmosList.scss';

import { gizmoViewerPath } from '../routes';
import { XMLs } from '@bettermarks/importers';

const GizmoLink = (link: string) => (
  <li key={link}>
    <Link to={gizmoViewerPath(link)} key={link}>
      {link}
    </Link>
  </li>
);

const GizmoLinkGroup = (items: string[], group: string) =>
  items.length === 1 ? (
    GizmoLink(items[0])
  ) : (
    <li key={group}>
      <h3>{group}</h3>
      <ul className={styles.list}>{items.sort().map(GizmoLink)}</ul>
    </li>
  );

const groupGizmos = groupBy<string>((it: string) => {
  if (it.startsWith('mc') || it.startsWith('multiple')) return 'multiple-choice';
  if (it === 'box' || it === 'hello_gizmo' || it === 'error_message') return 'dev-tools';
  return it.split('_')[0];
});

export const GizmosList: React.FC = () => (
  <ul className={classNames(styles.list, styles.scrollbar)}>
    {map(groupGizmos(Object.keys(XMLs)), GizmoLinkGroup)}
  </ul>
);

GizmosList.displayName = 'GizmosList';
