import * as React from 'react';
import classNames from 'classnames';
import styles from './ToolbarButton.scss';
import { ExcludedFromTabNavigationButton } from '../../../../components/ExcludedFromTabNavigation';

export interface ToggleProps {
  on?: boolean;
  onClick?: React.MouseEventHandler<any>;
  dataCy?: string;
}

export interface ToolbarButtonProps extends ToggleProps {
  kind?: 'action' | 'submit' | 'tool' | 'toggle';
  id?: string; // to style specific buttons ('next-exercise', 'next-step' etc.)
  disabled?: boolean;
  className?: string;
}

/**
 * ToolbarButton: A button for the toolbar. If you provide two content elements (icons), first one
 * will be used for the on state and the second for the off state.
 */
export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  children,
  kind,
  id,
  className,
  on = false,
  onClick,
  disabled = false,
  dataCy,
}) => {
  const childArray = React.Children.toArray(children);
  const buttonContent = childArray.length >= 2 ? (on ? childArray[0] : childArray[1]) : childArray;
  return (
    <ExcludedFromTabNavigationButton
      id={id}
      onClick={disabled ? undefined : onClick}
      data-cy={dataCy}
      className={classNames(
        className,
        styles.toolbarButton,
        kind && styles[kind],
        styles && id && styles[id],
        on && styles.on,
        disabled ? styles.disabled : undefined
      )}
    >
      {buttonContent}
    </ExcludedFromTabNavigationButton>
  );
};

ToolbarButton.displayName = 'ToolbarButton';
