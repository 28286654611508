import React from 'react';

type FEMDispatchContextValue = {
  /** Check out FEMs_and_the_Whiteboard_Mode.md */
  dispatchFEMOpen: (femId: string) => void;
};

export const FEMDispatchContext = React.createContext<
  undefined | Readonly<FEMDispatchContextValue>
>(undefined);

type FEMDispatchContextProviderProps = {
  children?: React.ReactNode;
  value: FEMDispatchContextValue;
};

/** The purpose of this context is to let the `Formula`/`FEMLink` know that it is being rendered
 * in Whiteboard mode and should hence use a different `onClick` callback to open FEMs. */
export function FEMDispatchContextProvider({ children, value }: FEMDispatchContextProviderProps) {
  return <FEMDispatchContext.Provider value={value}>{children}</FEMDispatchContext.Provider>;
}

export function useMaybeExampleModeContext(): Readonly<FEMDispatchContextValue> | undefined {
  return React.useContext(FEMDispatchContext);
}
