import { type ImporterRegistry } from '../../gizmo-utils/configuration';
import {
  type ContentDict,
  createImporterContext,
  DependencyCollector,
  type FElement,
} from '@bettermarks/gizmo-types';
import { SPECIAL } from '../../gizmo-utils/constants';
import { type CRIContent } from '../../types/CRI';

/**
 * This function parses a CRI XML and creates a `CRI` object.
 * classroomIntro is then added to ApplicationState
 *
 * @param xml
 * @param importers: Importer Registry
 * @param pathToCRI
 * @param dependencies any dependency collector that can be used
 *                     to collect from multiple import functions
 */
export const importCRI = (
  xml: FElement,
  importers: ImporterRegistry,
  pathToCRI = 'cri.data',
  dependencies = new DependencyCollector()
): ContentDict => {
  const id = xml.attribute('id');

  const importerContext = createImporterContext(
    importers,
    `${pathToCRI}.${id}`,
    dependencies,
    id
  ).withNextId();

  const variantNode = xml.findChildTag('variant');
  const variant = {
    id: variantNode.findChildTag('id').text,
    title: variantNode.findChildTag('title').text,
  };

  const settingNode = xml.findChildTag('setting');
  const setting = settingNode.firstChild.exists
    ? importerContext.importXML(settingNode.firstChild)
    : undefined;

  const canvasConfigNode = xml.findChildTag('canvasConfiguration');
  if (!canvasConfigNode.firstChild.exists) {
    throw new Error('The canvas configuration of a classroom intro must not be empty');
  }
  const canvasConfiguration = importerContext.importXML(canvasConfigNode.firstChild);

  const studentTasksNode = xml.findChildTag('studentTasks');
  const studentTasks = studentTasksNode.getChildrenByTagName('task').map((task) => {
    if (!task.firstChild.exists) {
      throw new Error('The canvas configuration of a classroom intro must not be empty');
    }
    return importerContext.importXML(task.firstChild);
  });

  const cri: CRIContent = {
    $: SPECIAL,
    $renderStyle: 'cri',
    id,
    variant,
    setting,
    canvasConfiguration,
    studentTasks,
  };

  importerContext.content.set(importerContext.root, cri);

  return importerContext.contentDict;
};
