import { gizmoRegistry } from '../../../../gizmo-utils/configuration/gizmos';
import * as React from 'react';
import { ContentBox } from '../index';
import { StepFeedbacks } from '../StepFeedbacks/StepFeedbacks';
import { STEP_CONTENT_PADDING } from './constants';
import { type ContentDict } from '@bettermarks/gizmo-types';
import { type StepFeedback } from '../../../../types/Series';
import { type Dispatch } from '../../../../gizmo-utils/redux/types';
import { useTranslation } from 'react-i18next';
import { StepAttemptHeader } from './StepAttemptHeader';
import { type Severity } from '@bettermarks/umc-kotlin';

export type StepAttemptProps = Readonly<{
  attemptsCount: number;
  availableWidth: number;
  dispatch: Dispatch;
  feedbacks: ReadonlyArray<StepFeedback>;
  hideHelpTools?: boolean;
  isTouch: boolean;
  severity: Severity;
  singleTry: boolean;
  skipped?: boolean;
  question?: ContentDict;
  staticMediaUrl: string;
}>;

export const StepAttemptBox: React.FC<StepAttemptProps> = (props) => {
  const [t] = useTranslation();

  const {
    attemptsCount,
    availableWidth,
    children,
    dispatch,
    feedbacks,
    hideHelpTools,
    isTouch,
    question,
    severity,
    singleTry,
    skipped,
    staticMediaUrl,
  } = props;

  const availableChildWidth = availableWidth - STEP_CONTENT_PADDING * 2;

  return (
    <div>
      <StepAttemptHeader count={attemptsCount} {...{ severity, singleTry, skipped, t }} />
      {question && (
        <ContentBox
          availableWidth={availableChildWidth}
          contentDict={question}
          fitToContent
          staticMediaUrl={staticMediaUrl}
          {...{ dispatch, gizmoRegistry, hideHelpTools, isTouch }}
        />
      )}
      <StepFeedbacks
        {...{
          availableChildWidth,
          dispatch,
          feedbacks,
          hideHelpTools,
          isTouch,
        }}
        showAdditionalFeedback={false}
        t={t}
        staticMediaUrl={staticMediaUrl}
      >
        {children}
      </StepFeedbacks>
    </div>
  );
};

StepAttemptBox.displayName = 'StepAttemptBox';
