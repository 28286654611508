import * as React from 'react';
import {
  type Coords,
  type GeoDecoration,
  type Hover,
  IntervalType,
  type MouseOrTouch,
} from '@bettermarks/gizmo-types';
import {
  BRACKET_HEIGHT,
  DEFAULT_INTERVAL_DECORATION,
  DEFAULT_INTERVAL_RECT_DECORATION,
  DEFAULT_LEFT_CLOSED_BRACKET,
  DEFAULT_LEFT_OPEN_BRACKET,
  DEFAULT_RIGHT_CLOSED_BRACKET,
  DEFAULT_RIGHT_OPEN_BRACKET,
  DEFAULT_STROKE_WIDTH,
  MAX_BRACKET,
  MIN_BRACKET,
  worldToScreen,
} from '@bettermarks/importers';
import { useContentTranslation } from '../../../gizmo-utils/polymorphic-gizmo';
import { DEFAULT_HOVER_COLOR } from '../tools/constants';
import {
  CircleBracketClosed,
  CircleBracketOpen,
  RoundBracketClosed,
  RoundBracketOpen,
  SquaredBracketClosed,
  SquaredBracketOpen,
} from '../../../components/icons/geo';
import { getObjectColor } from './decorations';

import { type Severity } from '@bettermarks/umc-kotlin';
import { getHoverColor } from '../tools/helpers';
import { getNamedColor } from '@bettermarks/importers';

export interface IntervalProps {
  id: string;
  min: number;
  max: number;
  matrix: number[];
  intervalType: IntervalType;
  interactionType: string;
  decoration?: GeoDecoration;
  severity?: Severity;
  hover?: Hover;
  snapHighlight?: 'min' | 'max';
  onMouseLeave?: (evt: MouseOrTouch) => void;
  onMouseOver?: (evt: MouseOrTouch) => void;
  onClick?: (evt: MouseOrTouch) => void;
}

/**
 * Transformation map to be applied to the brackets.
 * -1 means no bracket
 * 1 means open bracket
 * 0 means closed bracket
 */
export const transformationMap = {
  [IntervalType.open]: [1, 1],
  [IntervalType.none]: [-1, -1],
  [IntervalType.lowOpen]: [1, 0],
  [IntervalType.highOpen]: [0, 1],
  [IntervalType.closed]: [0, 0],
};

/**
 * Offset needs to be applied to set the interval bracket in the
 * middle of the horizontal line
 */
const offsetMap = {
  ['(' as string]: 10,
  [')' as string]: 10,
  ['[' as string]: 10,
  [']' as string]: 10,
  ['o-circle' as string]: 5,
  ['circle' as string]: 5,
};

export const getRoundBracket = (x: number, y: number, color: string, rotated: boolean) => {
  return !rotated ? (
    <RoundBracketOpen
      width={BRACKET_HEIGHT}
      height={BRACKET_HEIGHT}
      x={x}
      y={y}
      stroke={color}
      fill={color}
    />
  ) : (
    <RoundBracketClosed
      width={BRACKET_HEIGHT}
      height={BRACKET_HEIGHT}
      x={x}
      y={y}
      stroke={color}
      fill={color}
    />
  );
};

export const getSquaredBracket = (x: number, y: number, color: string, rotated: boolean) => {
  return !rotated ? (
    <SquaredBracketOpen width={BRACKET_HEIGHT} height={BRACKET_HEIGHT} x={x} y={y} fill={color} />
  ) : (
    <SquaredBracketClosed width={BRACKET_HEIGHT} height={BRACKET_HEIGHT} x={x} y={y} fill={color} />
  );
};

export const getCircleBracket = (x: number, y: number, color: string, rotated: boolean) => {
  const edgeLen = BRACKET_HEIGHT * 0.5;
  return rotated ? (
    <CircleBracketOpen width={edgeLen} height={edgeLen} x={x} y={y} fill={color} />
  ) : (
    <CircleBracketClosed width={edgeLen} height={edgeLen} x={x} y={y} fill={color} />
  );
};

const transformCoords = ({ pos, matrix }: any): Coords => worldToScreen(matrix)({ x: pos, y: 0 });

const getRectAxisCoords = (min: number, max: number, matrix: number[]) => {
  /**
   * Transform the current coords to get the width of the rect by calculating
   * the screen distance between the min and max positions.
   */
  const {
    minPos: { x: xMin, y: yMin },
    maxPos: { x: xMax },
  } = {
    minPos: worldToScreen(matrix)({ x: min, y: 0 }),
    maxPos: worldToScreen(matrix)({ x: max, y: 0 }),
  };

  return { xMin, xMax, yMiddle: yMin - BRACKET_HEIGHT / 2 };
};

const getLocalizedBracket = (
  bracketType: string,
  bracketSymbols: string[],
  x: number,
  y: number,
  intervalType: IntervalType,
  color: string
) => {
  const openMinTypes = [IntervalType.open, IntervalType.lowOpen];
  const openMaxTypes = [IntervalType.open, IntervalType.highOpen];

  const isOpenBracket =
    (bracketType === MIN_BRACKET && openMinTypes.indexOf(intervalType) !== -1) ||
    (bracketType === MAX_BRACKET && openMaxTypes.indexOf(intervalType) !== -1);

  const bracket = isOpenBracket ? bracketSymbols[0] : bracketSymbols[1];
  const offset: number = offsetMap[bracket] ? offsetMap[bracket] : 0;

  const xWithOffset = x - offset;
  const yWithOffset = y - offset;

  /*
    Offset needs to be applied because of the coordinate system.
  */
  switch (bracket) {
    case '(':
      return getRoundBracket(xWithOffset, yWithOffset, color, false);
    case ')':
      return getRoundBracket(xWithOffset, yWithOffset, color, true);
    case '[':
      return getSquaredBracket(xWithOffset, yWithOffset, color, false);
    case ']':
      return getSquaredBracket(xWithOffset, yWithOffset, color, true);
    case 'circle':
      return getCircleBracket(xWithOffset, yWithOffset, color, false);
    case 'o-circle':
      return getCircleBracket(xWithOffset, yWithOffset, color, true);
    default:
      return getSquaredBracket(xWithOffset, yWithOffset, color, false);
  }
};

export const Interval: React.FC<IntervalProps> = ({
  id,
  min,
  max,
  matrix,
  intervalType,
  decoration,
  hover,
  severity,
  snapHighlight,
  onClick,
  onMouseLeave,
  onMouseOver,
}) => {
  const t = useContentTranslation();
  const minCoords: Coords = transformCoords({
    pos: min,
    matrix,
    intervalType,
    bracketType: MIN_BRACKET,
  });

  const maxCoords: Coords = transformCoords({
    pos: max,
    matrix,
    intervalType,
    bracketType: MAX_BRACKET,
  });

  const [minTransform, maxTransform] = transformationMap[intervalType];

  // These coords will determine the rect length and position
  const { xMin, yMiddle, xMax } = getRectAxisCoords(min, max, matrix);
  const leftOpenBracket = t ? t('interval.leftOpen') : DEFAULT_LEFT_OPEN_BRACKET;
  const leftClosedBracket = t ? t('interval.leftClosed') : DEFAULT_LEFT_CLOSED_BRACKET;
  const rightOpenBracket = t ? t('interval.rightOpen') : DEFAULT_RIGHT_OPEN_BRACKET;
  const rightClosedBracket = t ? t('interval.rightClosed') : DEFAULT_RIGHT_CLOSED_BRACKET;

  const minBrackets = [leftOpenBracket, leftClosedBracket];
  const maxBrackets = [rightOpenBracket, rightClosedBracket];

  const rectDecoration: GeoDecoration = {
    ...DEFAULT_INTERVAL_RECT_DECORATION,
    ...decoration,
    ...(severity && { color: getNamedColor(severity) }),
    ...getHoverColor(hover),
  };

  const intervalDecoration: GeoDecoration = {
    ...DEFAULT_INTERVAL_DECORATION,
    ...decoration,
    ...(severity && { color: getNamedColor(severity) }),
    ...getHoverColor(hover),
  };

  const rectStyles = {
    fill: getObjectColor(rectDecoration),
    opacity: 0.3,
  };

  const lineStyle = {
    stroke: getNamedColor(DEFAULT_HOVER_COLOR),
    strokeWidth: DEFAULT_STROKE_WIDTH,
  };

  const intervalColor = getObjectColor(intervalDecoration);

  const snapLine = {
    x: snapHighlight === 'min' ? xMin : xMax,
    yMax: yMiddle + BRACKET_HEIGHT / 2 - 7,
    yMin: yMiddle + BRACKET_HEIGHT / 2 + 7,
  };

  return (
    <g>
      <rect
        x={xMin}
        y={yMiddle}
        style={rectStyles}
        width={xMax - xMin}
        height={BRACKET_HEIGHT}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
      {snapHighlight && (
        <line
          x1={snapLine.x}
          y1={snapLine.yMax}
          x2={snapLine.x}
          y2={snapLine.yMin}
          style={lineStyle}
        />
      )}
      {minTransform !== -1 &&
        getLocalizedBracket(
          MIN_BRACKET,
          minBrackets,
          minCoords.x,
          minCoords.y,
          intervalType,
          intervalColor as string
        )}
      {maxTransform !== -1 &&
        getLocalizedBracket(
          MAX_BRACKET,
          maxBrackets,
          maxCoords.x,
          maxCoords.y,
          intervalType,
          intervalColor as string
        )}
    </g>
  );
};

Interval.displayName = 'Interval';
