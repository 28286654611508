import { type Action, handleActions } from 'redux-actions';
import { GRAPH } from '@bettermarks/gizmo-types';
import { SET_MODE } from '../../../gizmo-utils/redux/gizmoActions';
import { type GraphEditorMode, GraphEditorModes } from '@bettermarks/gizmo-types';
import {
  GRAPH_GIZMO_DEFAULT_CONTENT,
  type Graph,
  type GraphNode,
  type GraphEdge,
  NodeOptionType,
  isBubbleNode,
  type Position,
} from '@bettermarks/gizmo-types';
import {
  ADD_NODE,
  CONNECT_NODES,
  COLOR_NODE,
  DELETE_EDGE,
  DELETE_NODE,
  MOVE_NODE,
  type ConnectNodesPayload,
  type GraphActionPayload,
  type NodeSizePayload,
  SET_NODE_SIZE,
  LABEL_NODE,
  type NodePositionPayload,
  type LabelNodePayload,
} from './graphActions';
import { compact, set } from 'lodash/fp';
import { omit } from '../../../gizmo-utils/omit';
import { createGuid, notSameNode, doesntConnectNode } from './helpers';
import { isContentColor } from '@bettermarks/importers';
import { DEFAULT_NODE } from '@bettermarks/importers';

const isEdgeOf =
  (nodes: ConnectNodesPayload) =>
  (edge: GraphEdge): boolean =>
    (nodes.sourceId === edge.nodeId1 && nodes.targetId === edge.nodeId2) ||
    (nodes.targetId === edge.nodeId1 && nodes.sourceId === edge.nodeId2);

export const graphReducer = handleActions<Graph, GraphActionPayload | GraphEditorMode>(
  {
    [SET_MODE]: (state, { payload }: Action<GraphEditorMode>) =>
      payload
        ? {
            ...state,
            selectedMode: payload,
            nodes: state.nodes.map<GraphNode>(omit('highlightColor')),
            edges: state.edges.map<GraphEdge>(omit('highlightColor')),
          }
        : state,
    [ADD_NODE]: (state, { payload }: Action<Position>) =>
      payload && state.selectedMode === GraphEditorModes.Add
        ? {
            ...state,
            nodes: [
              ...state.nodes,
              {
                ...state.template,
                ...DEFAULT_NODE,
                ...(state.template && state.template.content
                  ? {
                      content: {
                        ...state.template.content,
                        selected: undefined,
                      },
                    }
                  : {}),
                position: payload,
                addedByUser: true,
                id: createGuid(),
              },
            ],
          }
        : state,
    [CONNECT_NODES]: (state, { payload }: Action<ConnectNodesPayload>) =>
      payload && state.selectedMode === GraphEditorModes.Connect
        ? {
            ...state,
            edges: compact([
              ...state.edges,
              state.edges.find(isEdgeOf(payload)) === undefined &&
              !!payload.sourceId &&
              !!payload.targetId &&
              payload.sourceId !== payload.targetId
                ? {
                    $id: `user-edge-${payload.sourceId}-${payload.targetId}`,
                    nodeId1: payload.sourceId,
                    nodeId2: payload.targetId,
                    addedByUser: true,
                  }
                : undefined,
            ]),
            nodes: state.nodes.map((n) =>
              n.id === payload.sourceId || n.id === payload.targetId
                ? (omit('severity', n) as GraphNode)
                : n
            ),
          }
        : state,
    [DELETE_EDGE]: (state, { payload }: Action<string>) => {
      if (!(payload && state.selectedMode === GraphEditorModes.Remove)) return state;
      const edge = state.edges.find((e) => e.$id === payload);
      if (!edge) return state;
      return {
        ...state,
        edges: state.edges.filter((e) => e.$id !== payload),
        nodes: state.nodes.map((n) =>
          n.id === edge.nodeId1 || n.id === edge.nodeId2 ? (omit('severity', n) as GraphNode) : n
        ),
      };
    },
    [DELETE_NODE]: (state, { payload }: Action<string>) =>
      payload && state.selectedMode === GraphEditorModes.Remove
        ? {
            ...state,
            nodes: state.nodes.filter(notSameNode(payload)),
            edges: state.edges.filter(doesntConnectNode(payload)),
          }
        : state,
    [MOVE_NODE]: (state, { payload }: Action<NodePositionPayload>) => {
      if (!payload || state.selectedMode !== GraphEditorModes.Move) return state;
      const idx = state.nodes.findIndex((n) => n.id === payload.id);
      const node: GraphNode = state.nodes[idx];
      if (!node) return state;
      return {
        ...state,
        nodes: [
          ...state.nodes.slice(0, idx),
          {
            ...node,
            position: payload.position,
          },
          ...state.nodes.slice(idx + 1),
        ],
      };
    },
    [LABEL_NODE]: (state, { payload }: Action<LabelNodePayload>) => {
      if (!payload || state.selectedMode !== GraphEditorModes.Label) return state;
      const idx = state.nodes.findIndex((n: GraphNode) => n.id === payload.id);
      const node: GraphNode = state.nodes[idx];

      if (isBubbleNode(node) && node.content && node.content.type === NodeOptionType.selectBox) {
        return set(
          ['nodes', idx],
          {
            ...omit('severity', node),
            content: {
              ...node.content,
              selected: node.content.options[payload.index],
            },
          },
          state
        );
      }
      return state;
    },
    [COLOR_NODE]: (state, { payload }: Action<string>) => {
      if (!payload || !isContentColor(state.selectedMode)) return state;
      const idx = state.nodes.findIndex((n: GraphNode) => n.id === payload);
      const node: GraphNode = state.nodes[idx];

      if (isBubbleNode(node) && node.content && node.content.type === NodeOptionType.colorChooser) {
        return set(
          ['nodes', idx],
          {
            ...omit('severity', node),
            content: {
              ...node.content,
              selected: state.selectedMode,
            },
          },
          state
        );
      }
      return state;
    },
    [SET_NODE_SIZE]: (state, { payload }: Action<NodeSizePayload>) =>
      payload
        ? {
            ...state,
            nodes: state.nodes.map((n) => (n.id === payload.id ? { ...n, size: payload.size } : n)),
          }
        : state,
  },
  {
    ...GRAPH_GIZMO_DEFAULT_CONTENT,
    $interactionType: GRAPH,
  }
);
