import { AppendOnlyMap, type AppendOnlySet, type Dict } from '../../gizmo-utils/append-only';
import { type ImporterRegistry } from '../../gizmo-utils/configuration/types';
import { entries } from 'lodash';
import { parseCommonAttribs } from '../content';
import { importContent } from '../content/importer';
import {
  type Annotations,
  type Content,
  type ContentDict,
  type ContentMap,
  type ContentReference,
} from './types';
import { type FElement } from './xml';
import { identity } from '../../gizmo-utils/filters';
import { toXmlElement } from './helpers';

export type Importer<T extends Content = Content> = (
  preContent: Annotations,
  xml: FElement,
  context: ImporterContext
) => T;

export type Dependencies<S extends Iterable<string> = ReadonlyArray<string>> = {
  /**
   * collection of all media files needed for displaying the content
   */
  // TODO RSC 2018-12-17: As we also have animations now,
  // would it make sense to merge images and animations to 'media'?
  // This toic is postponed to a 'part2' of animations gizmo ...
  readonly images: S;
  /**
   * collection of all fem links contained in the content
   */
  readonly fems: S;
  /**
   * collection of all kems related to the exercise
   */
  readonly kems: S;
  /**
   * Collection of renderStyles that where used for importing.
   *
   *
   * Since some importers change the renderStyle of the resulting data,
   * collecting this information here is required
   * because it is not possible to derive it from somewhere else.
   */
  readonly importedRenderStyles: S;
};

export class DependencyCollector implements Dependencies<AppendOnlySet<string>> {
  // noinspection JSValidateJSDoc
  constructor(
    /**
     * collection of all media files needed for displaying the content
     */
    readonly images: AppendOnlySet<string> = new Set(),

    /**
     * collection of all fem links contained in the content
     */
    readonly fems: AppendOnlySet<string> = new Set(),
    /**
     * collection of all kems related to the exercise
     */
    readonly kems: AppendOnlySet<string> = new Set(),
    /**
     * Collection of renderStyles that where used for importing.
     *
     * Since some importers change the renderStyle of the resulting data,
     * collecting this information here is required
     * because it is not possible to derive it from somewhere else.
     */
    readonly importedRenderStyles: AppendOnlySet<string> = new Set()
  ) {}

  get dependencies(): Dependencies {
    return {
      fems: Array.from(this.fems),
      kems: Array.from(this.kems),
      images: Array.from(this.images),
      importedRenderStyles: Array.from(this.importedRenderStyles),
    };
  }
}

export type PathColonIdIterator = Iterator<string> & { path: string };

/**
 * An importer context with an allocated $refid.
 */
export interface ImporterContext extends ContentReference, Dependencies<AppendOnlySet<string>> {
  /**
   * The top level `$refid` for a content tree.
   */
  readonly root: string;

  /**
   * AppendOnlyMap of $refid to imported `Content`.
   */
  readonly content: ContentMap;

  /**
   * The flattened tree of gizmos that have been imported so far.
   */
  readonly contentDict: ContentDict;

  /**
   * The id of the currently imported content (exercise id, kem id, or fem id).
   * Defaults to '', if not present.
   */
  readonly contentId: string;

  /**
   * The snapshot of the dependencies collected so far grouped by type.
   */
  readonly dependencyCollector: Dependencies<AppendOnlySet<string>>;

  /**
   * all configured importers in this context
   */
  readonly importers: ImporterRegistry;

  /**
   * The iterator instance that is used to produce unique ids.
   * Usually you only need `generateId` or `withNextId`
   * @see generateId
   * @see withNextId
   */
  readonly idIterator: PathColonIdIterator;

  /**
   * Gizmos can tell their subtree to stop flash to fb font-size conversion (flash: 13, fb: 16)
   *
   * @default true
   */
  convertFontSize: boolean;

  /**
   * Can be set in a gizmo importer to indicate that the children should consider themselves as root
   *
   * Currently only used in layout-container
   * (direct formula children inside layout container (vertical, horizontal, border)
   * should consider themselves root to know they need to wrap in a h-scroll container)
   *
   * @default false
   */
  childrenAreRoot: boolean;

  /**
   * Can be set in a gizmo importer to indicate that all nodes below will be contained in
   * the current gizmo's HScrollContainer
   *
   * @default false
   */
  inHScrollContainer: boolean;

  /**
   * Some gizmos, namely table and border layout, limit their child gizmo's available width to a
   * given "percentage" (relative to the fixed width of the flex seriesplayer).
   * This gets particularly complex when those two are nested (e.g. table inside border layout,
   * table inside table). See bug tickets:
   *  - http://trac.bm.loc/ticket/43244
   *  - http://trac.bm.loc/ticket/43455
   * To be able to calculate the max width of such a nested table with a sufficient accuracy,
   * the widthTransformation they apply is stored in the context to be used by child gizmos.
   */
  widthTransformation: (width: number) => number;

  /**
   * Returns the next available id that is unique within this context.
   *
   * All instances of `ImporterContext` that are created using this root share the same IdGenerator.
   *
   * @see withNextId
   */
  generateId(): string;

  /**
   * Create an instance of `ImporterContext` with a fixed allocated unique id.
   *
   * @returns {ImporterContext}
   */
  withNextId(): ImporterContext;

  /**
   * Make use of `importers` and 'this' to convert `xml` into `Content`
   * and add it to `this.content`.
   *
   * Same as calling `importContent(xml, this)`.
   * @param {FElement} xml
   * @param {boolean} skip
   *  if true, don't increment $refid, but use current $refid to store imported content
   *  (needed to not add skipped formula nodes to the contentDict)
   *  @param preContent - the preContent of the skipped formula
   * @returns {ContentReference}
   */
  importXML(xml: FElement, skip?: boolean, preContent?: Annotations): ContentReference;

  /**
   * Invokes an `Importer` directly and returns the correctly typed result,
   * without adding it to `this.content`.
   * In case `xml` contains nested content, that one will be added to `this.content`,
   * `tempContext` can be used to avoid that:
   * ```
   * const temp = context.tempContext();
   * const content = temp.invoke(myImporter, xml);
   * // do your magic
   * context.content.set(temp.$refid, {...content, x, y, z});
   * // in case of nested content
   * context.mergeMissing(temp.content);
   * ```
   *
   * @param {Importer<T extends Content>} importer
   * @param {FElement} xml
   * @returns {T} the correctly typed content that was returned by `importer`
   */
  invoke<T extends Content>(importer: Importer<T>, xml: FElement): T;

  /**
   * Creates a ImporterContext with the only difference being an own `ContentMap`.
   * This is useful if you need to modify imported (child) content before putting it into the map.
   *
   * After adding the content that you need, you can use `mergeMissing` to add all other content
   * that has been imported to tempContent.
   *
   * @see invoke
   */
  tempContext(): ImporterContext;

  /**
   * Adds all entries, identified by the key ($refid), from `contentMap` (source)
   * to `this.content` (target) that are not already present.
   *
   * @see tempContext
   *
   * @param {ContentMap} contentMap the source
   */
  mergeMissing(contentMap: ContentMap): void;

  /**
   * Checks if an id from the xml can be resolved.
   *
   * @param {string} $id
   * @returns {boolean}
   */
  hasXmlId($id: string): boolean;

  /**
   * Creates a temporary mapping between an id from the XML/MathML
   * and the corresponding $refid.
   * The mapping only exists in the scope of a ContentTree.
   *
   * If `$id` has already been mapped before, this method takes care of
   * remapping all entries that pointed to the same $refid.
   * This is required for skipping semantics nodes.
   *
   * @param {string} $id the id from the xml
   * @param {string} $refid the related $refid that is a key in the contentDict
   */
  mapXmlId($id: string, $refid: string): void;

  /**
   * Resolves a mapped id from the XML or throws if no mapping is present.
   *
   * @see hasXmlId
   * @see mapXmlId
   *
   * @param {string} $id the id from the xml to resolve
   *
   * @returns {string} the $refid that `$id` points to
   */
  resolveXmlId($id: string): string;

  /**
   * Determines if the current $refid, is the $refid of the "root gizmo" of this context.
   *
   * The $refid is the root $refid, if it is identical to the path (e.g. "setting",
   * "steps[0].question", ...).
   *
   * See also: PathIdIterator (a $refid that is the root $refid will not contain a ":").
   */
  isRoot(): boolean;
}

export const PathIdIterator = (path: string): PathColonIdIterator => {
  let num = 0;

  return {
    next: () => ({
      value: `${path}:${num++}`,
      done: false,
    }),
    path,
  };
};

export class ImporterContextImpl implements ImporterContext {
  _convertFontSize: boolean;

  get contentDict() {
    return this.content.toDict();
  }

  get kems() {
    return this.dependencyCollector.kems;
  }

  get fems() {
    return this.dependencyCollector.fems;
  }

  get images() {
    return this.dependencyCollector.images;
  }

  get importedRenderStyles() {
    return this.dependencyCollector.importedRenderStyles;
  }

  get convertFontSize() {
    return this._convertFontSize;
  }

  set convertFontSize(value: boolean) {
    this._convertFontSize = value;
  }

  constructor(
    readonly importers: ImporterRegistry,
    readonly idIterator: PathColonIdIterator,
    readonly dependencyCollector: DependencyCollector,
    readonly contentId: string,
    readonly $refid: string = idIterator.next().value,
    readonly content: ContentMap = new AppendOnlyMap(),
    protected readonly xmlIds: Dict<string> = {},
    readonly root = idIterator.path,
    _convertFontSize = true,
    public childrenAreRoot = false,
    public inHScrollContainer = false,
    public widthTransformation = identity
  ) {
    this._convertFontSize = _convertFontSize;
  }

  importXML = (xml: FElement, skip = false, preContent?: Annotations): ContentReference =>
    importContent(xml, this, skip, preContent);

  generateId() {
    return this.idIterator.next().value;
  }

  withNextId() {
    const nextRefId = this.generateId();
    return new ImporterContextImpl(
      this.importers,
      this.idIterator,
      this.dependencyCollector,
      this.contentId,
      nextRefId,
      this.content,
      this.xmlIds,
      this.childrenAreRoot ? nextRefId : this.root,
      this._convertFontSize,
      undefined,
      this.inHScrollContainer,
      this.widthTransformation
    );
  }

  invoke<T extends Content>(importer: Importer<T>, xml: FElement): T {
    return importer(parseCommonAttribs(xml), xml, this);
  }

  /**
   * TODO: see if we should get rid of `this.root`; currently
   * `this.isRoot()` is always true.
   */
  tempContext() {
    return new ImporterContextImpl(
      this.importers,
      this.idIterator,
      this.dependencyCollector,
      this.contentId,
      this.$refid,
      new AppendOnlyMap(),
      this.xmlIds,
      this.root,
      this._convertFontSize
    );
  }

  mergeMissing(contentMap: ContentMap) {
    contentMap.forEach((content, refId) => {
      if (!this.content.has(refId)) {
        this.content.set(refId, content);
      }
    });
  }

  hasXmlId($id: string): boolean {
    return $id in this.xmlIds;
  }

  mapXmlId($id: string, $refid: string): void {
    if ($id in this.xmlIds) {
      const prefRefId = this.xmlIds[$id];
      entries(this.xmlIds).forEach(([xmlId, refId]) => {
        if (refId === prefRefId) {
          this.xmlIds[xmlId] = $refid;
        }
      });
    }
    this.xmlIds[$id] = $refid;
  }

  resolveXmlId($id: string): string {
    if (!($id in this.xmlIds)) {
      throw new Error(`${$id} could not be resolved by ${this.$refid} (root ${this.root})`);
    }
    return this.xmlIds[$id] as string;
  }

  isRoot(): boolean {
    return this.$refid === this.root;
  }
}

export const createImporterContext = (
  importers: ImporterRegistry = {},
  path = 'path',
  dependencies = new DependencyCollector(),
  contentId = ''
) => {
  /**
   We don't attach `:id` to the root key, we are using the plain path instead.
   So whenever you have the path to the contentDict you already know the root key. :)
   This also means we can not allow ':' in path,
   otherwise the root can no longer be derived from all keys.
   */
  if (/:/.test(path)) throw new Error(`Colon is not allowed in path but was '${path}'`);
  const idIterator = PathIdIterator(path);
  /**
   The above also means the "first nested child" would actually get the key `path:0`.
   While this could be irritating when looking at the contentDict,
   lots of tests currently assume some sequence of numbers in the `$refid`s.
   As a shortcut to not change all those numbers in the tests,
   we just drop the 0 from the IdGenerator.
   */
  idIterator.next();

  return new ImporterContextImpl(importers, idIterator, dependencies, contentId, idIterator.path);
};

export const applyImporter = <T extends Content = Content>(
  xml: string,
  importer: Importer<T>,
  importerRegistry: ImporterRegistry = {}
) => {
  const context = createImporterContext(importerRegistry);
  const xmlElement = toXmlElement(xml);
  return importer(parseCommonAttribs(xmlElement), xmlElement, context);
};
