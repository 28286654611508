import { type ContentDict, ShouldEnrichKind } from '@bettermarks/gizmo-types';
import { type ReducerMeta } from 'redux-actions';
import { enrichContentDict } from './enrichContent';
import { type GizmoActionMeta } from '../redux/gizmoActions';
import { type ApplyStylesRegistry, type RulerRegistry } from '../configuration';

export const enrichReducer =
  (
    rulers: RulerRegistry,
    stylers: ApplyStylesRegistry
    // eslint-disable-next-line @typescript-eslint/ban-types
  ): ReducerMeta<ContentDict, {}, GizmoActionMeta> =>
  (contentDict, action) => {
    const { meta } = action;
    // Any GizmoAction can trigger enriching as long as it provides
    // {meta: {shouldEnrich: ShouldEnrichKind}}
    const mode = meta && meta.shouldEnrich;
    if (mode) {
      const disabled =
        mode === ShouldEnrichKind.justEnrich ? undefined : mode === ShouldEnrichKind.enrichDisable;
      return enrichContentDict(contentDict, stylers, rulers, disabled);
    }
    return contentDict;
  };
