import * as React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import styles from './DragAndDrop.scss';
import { getNamedColor } from '@bettermarks/importers';

export const NBSP = '\u00A0';

export type BaseDropTargetProps = {
  shape?: 'border' | 'rectangle';
  itemType?: number;
  fontSize?: number;
  error?: boolean;
  remark?: boolean;
  dragging?: boolean;
  dragOver?: boolean;
  draggable?: boolean;
  setRef?: (el: HTMLDivElement) => void;
};

export type DropTargetProps = BaseDropTargetProps & {
  itemWidth?: number;
  itemHeight?: number;
  backgroundAlpha?: number;
  borderColor?: string;
  fontSize?: number;
  shape?: 'border' | 'rectangle';
  error?: boolean;
};

const transparentWhite = (alpha: number | false | undefined) =>
  alpha ? `rgba(255, 255, 255, ${alpha})` : undefined;

/**
 * DropTarget: Component that acts as a target for drag items */
/* eslint-disable complexity */
export const DropTarget: React.FC<DropTargetProps> = ({
  backgroundAlpha,
  borderColor,
  children,
  dragOver,
  dragging,
  draggable,
  error,
  remark,
  fontSize,
  itemType = 1,
  itemWidth,
  itemHeight,
  shape,
  setRef,
}) => {
  const hasChildren = !isEmpty(children);
  const errorOnTarget = (error && !hasChildren) || (error && shape !== 'rectangle');
  const remarkOnTarget = (remark && !hasChildren) || (remark && shape !== 'rectangle');
  // for shape border we have to subtract the border from the item width, because the border
  // will be rendered by the outer target, and the border of the drag item will be removed.
  const width = itemWidth && itemWidth - (shape === 'border' ? 2 : 0);
  const height = itemHeight && itemHeight - (shape === 'border' ? 2 : 0);
  return (
    <div
      className={classNames(
        styles.dropTarget,
        styles.alignInFormula,
        shape && styles[shape],
        styles[`itemType${itemType}`],
        {
          [styles.error]: errorOnTarget,
          [styles.remark]: remarkOnTarget,
          [styles.dragOver]: dragOver,
          [styles.draggable]: draggable,
        }
      )}
      style={{
        borderColor:
          !errorOnTarget && !remarkOnTarget && borderColor ? getNamedColor(borderColor) : undefined,
        backgroundColor: transparentWhite(shape !== 'rectangle' && backgroundAlpha),
      }}
    >
      <div
        className={classNames(
          styles.dragSource,
          shape && styles[shape],
          styles[`itemType${itemType}`],
          {
            [styles.stack0]: !hasChildren || dragging,
            [styles.error]: error && hasChildren,
            [styles.remark]: remark && hasChildren,
          }
        )}
        ref={setRef}
        style={{ width, height, fontSize }}
      >
        <span className={styles.content}>
          {/*
          currently alignment is based on browser default behavior
          of aligning the block element to the baseline of it's last/inline element
          which doesn't work if it doesn't contain anything
          so we are adding NBSP here for alignment to kick in,
          it will be hidden because of styles.stack0
          */}
          {hasChildren ? children : NBSP}
        </span>
      </div>
    </div>
  );
};

DropTarget.displayName = 'DropTarget';
