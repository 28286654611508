export const enum SeverityColor {
  OK = 'ok',
  COMMENT = 'comment',
  ERROR = 'error',
  REMARK = 'remark',
}

/**
 * The strings used for colors in the content.
 * For using the related colors
 * @see getNamedColor
 * There are only a few cases that require to using those strings in the code.
 * If required those names and the related color values
 * are also available in `styles/bmcolors.scss` to be used in SASS classes.
 */
export const enum ContentColor {
  BM_BEIGE = 'bm-beige',
  BM_BLACK = 'bm-black',
  BM_BLUE = 'bm-blue',
  BM_DARK_GREEN = 'bm-dark-green',
  BM_DARK_GREY = 'bm-dark-grey',
  BM_GRASS_GREEN = 'bm-grass-green',
  BM_GREEN = 'bm-green',
  BM_GREY = 'bm-grey',
  BM_LIGHT_BLUE = 'bm-light-blue',
  BM_LIGHT_GREEN = 'bm-light-green',
  BM_LIGHT_GREY = 'bm-light-grey',
  BM_LIGHT_STEELBLUE = 'bm-light-steelblue',
  BM_MEDIUM_BLUE = 'bm-medium-blue',
  BM_MEDIUM_PINK = 'bm-medium-pink',
  BM_MEDIUM_STEELBLUE = 'bm-medium-steelblue',
  BM_ORANGE = 'bm-orange',
  BM_PETROL = 'bm-petrol',
  BM_PINK = 'bm-pink',
  BM_PURPLE = 'bm-purple',
  BM_RED = 'bm-red',
  BM_STEELBLUE = 'bm-steelblue',
  BM_TRANSPARENT = 'bm-transparent',
  BM_VERY_LIGHT_BLUE = 'bm-very-light-blue',
  BM_VERY_LIGHT_STEELBLUE = 'bm-very-light-steelblue',
  BM_WHITE = 'bm-white',
  BM_YELLOW = 'bm-yellow',
}
