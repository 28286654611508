import { connect, type MapDispatchToProps, type MapStateToProps } from 'react-redux';
import { Betty as BettyPresenter, type StateProps, type DispatchProps } from './Betty';
import { closeBettySection } from '../../containers/Exercise/actions';
import { ApplicationState } from '../../../../types';
import { Validity } from '@bettermarks/umc-kotlin';

// eslint-disable-next-line @typescript-eslint/ban-types
const mapStateToProps: MapStateToProps<StateProps, {}, ApplicationState> = (state) => {
  const step = ApplicationState.toCurrentStep.get(state);

  const isSuccessful = step && step.validity === Validity.correct;

  const showBettyOn = step && step.showBetty;

  return {
    show: Boolean(showBettyOn && isSuccessful),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, null> = (dispatch) => {
  return {
    onClose: () => dispatch(closeBettySection()),
  };
};

export const BettyContainer = connect(mapStateToProps, mapDispatchToProps)(BettyPresenter);
