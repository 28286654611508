import * as React from 'react';
import { defaultTo, first, isEmpty } from 'lodash';

import { WithLocalRedux } from '../../../../gizmo-utils/WithLocalRedux';
import {
  GeoEditorMode,
  type GeoInteractiveBaseState,
  type GeoInteractivePreviewState,
  type GeoScene,
  INVISIBLE,
  POINT,
  type PointHighlight,
  type SnapObject,
  type SnapPoint,
} from '@bettermarks/gizmo-types';
import { type GeoProps } from '../../Geo';
import { GeoRenderer } from '../../GeoRenderer';
import { getSnapPoint } from '../../snap';
import { mousePos } from '@bettermarks/importers';
import { persistProps } from '../persist';
import {
  downAction,
  geoMiddlepointReducer,
  initialState,
  snapAction,
  stopSnapAction,
  upAction,
} from './geoMiddlepointReducer';
import { offset } from '../helpers';
import { Maybe } from '../../../../utils/maybe';
import {
  type DragScrollableProps,
  startScrolling,
  stopScrolling,
} from '../../../../gizmo-utils/drag-scroll-behaviour';

export type GeoMiddlepointState = GeoInteractiveBaseState &
  GeoInteractivePreviewState & {
    highlight?: PointHighlight;
  };

export const GeoMiddlepoint: React.FC<GeoProps & DragScrollableProps> = (props) => (
  <WithLocalRedux
    store={{
      ...initialState,
      persistProps: persistProps(props),
    }}
    reducer={geoMiddlepointReducer}
    componentName={`Middlepoint:${props.uniqueId}`}
  >
    {(state, dispatch) => {
      const { configuration, uniqueId, isTouch, matrix, onPersistLocalState, scale } = props;
      const maybeScrollBehaviour = Maybe(props.scrollBehaviour);

      const snap =
        (scene: GeoScene, isTouchStart = false) =>
        (evt: React.MouseEvent<any>) => {
          const mouseP = mousePos(
            defaultTo<string>(uniqueId, ''),
            offset(!!isTouch, isTouchStart)
          )(evt);
          const snapPoint: SnapPoint | null = getSnapPoint(matrix, scene, scale)(mouseP);

          if (snapPoint) {
            if (snapPoint.snapObject !== POINT && state.prevPoints.length === 0 && !isTouchStart) {
              dispatch(stopSnapAction());
            } else {
              dispatch(snapAction({ configuration, matrix, mouseP, snapPoint }));
            }
          }

          return snapPoint;
        };

      const stopSnap = () => dispatch(stopSnapAction());

      const onMouseDown = () =>
        isEmpty(state.prevPoints) &&
        state.snapPoints.length > 0 &&
        dispatch(downAction({ matrix }));

      const onMouseUp = () => {
        const snapPoint = first(state.snapPoints);
        if (
          snapPoint &&
          configuration.snapType === 'none' &&
          snapPoint.snapObject !== POINT &&
          (snapPoint.snapObject as SnapObject) !== INVISIBLE
        ) {
          stopSnap();
          return;
        } else if (snapPoint) {
          dispatch(upAction({ configuration, onPersistLocalState }));
        }
        maybeScrollBehaviour.ap(stopScrolling);
      };

      const touchStart = (scene: GeoScene) => (evt: React.MouseEvent<any>) => {
        const snapPoint = snap(scene, true)(evt);
        maybeScrollBehaviour.ap(startScrolling);
        dispatch(downAction({ snapPoint, matrix }));
      };

      const geoContentProps = { ...props, ...state.persistProps };

      return (
        <GeoRenderer
          mode={GeoEditorMode.MIDDLEPOINT}
          snapPoints={state.snapPoints}
          highlight={state.highlight}
          onMouseLeave={stopSnap}
          onMouseMove={snap}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchStart={touchStart}
          {...geoContentProps}
          previewObjects={{
            lines: [state.prevLine],
            points: state.prevPoints,
          }}
        />
      );
    }}
  </WithLocalRedux>
);

GeoMiddlepoint.displayName = 'GeoMiddlepoint';
