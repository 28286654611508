import { type GizmoProps } from '../../../gizmo-utils/polymorphic-gizmo';
import { connectGizmo, type Dispatch, gizmoAction } from '../../../gizmo-utils/redux';
import { ShouldEnrichKind } from '@bettermarks/gizmo-types';
import { HelloGizmo, type HelloGizmoProps, type HelloGizmoCallbacks } from './HelloGizmo';
import { changeColor, changeSize } from './helloGizmoActions';

export const mapDispatchToProps = (
  dispatch: Dispatch,
  { refid }: GizmoProps
): HelloGizmoCallbacks => ({
  onGizmoClicked: () => {
    dispatch(gizmoAction(changeColor(), refid));
    dispatch(
      gizmoAction(changeSize(), refid, {
        shouldEnrich: ShouldEnrichKind.justEnrich,
      })
    );
  },
});

export const HelloGizmoContainer = connectGizmo<HelloGizmoProps>(mapDispatchToProps, HelloGizmo);
