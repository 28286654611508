import {
  $MROW,
  type Annotations,
  CONFIGURATION,
  DECORATION,
  DROP_TARGET_DEFAULT_CONTENT,
  type DropTargetContent,
  type FElement,
  hasInteractionType,
  type Importer,
  type ImporterContext,
  SEMANTICS,
  xmlTextToInt,
} from '@bettermarks/gizmo-types';
import { parseDecoString } from '../../../gizmo-utils/decoration';
import {
  dragSourceConfig,
  importDragSourceId,
  parseDragAndDropStyle,
} from '../dragsource/importer';

export type ContainedItem =
  | undefined
  | Pick<DropTargetContent, 'items'>
  | Pick<DropTargetContent, 'dragItemFixed'>;

/**
 * When the target is not interactive but contains an item,
 * the item might not have a related source (it was created by content developer)
 * for this case we need to import the item as is.
 */
export const importItem = (
  preContent: Annotations,
  xml: FElement,
  context: ImporterContext
): ContainedItem => {
  const child = xml.findChildTag(SEMANTICS);
  if (!child.exists) return undefined;

  if (hasInteractionType(preContent)) {
    // the tag `dragSourceId` is only present for interactive items
    // and no more information is required for rendering it
    const item = importDragSourceId(dragSourceConfig(child));
    return (
      item && {
        items: [item],
      }
    );
  } else {
    // prefilled drop target
    return { dragItemFixed: context.importXML(child).$refid };
  }
};

export const importDropTarget: Importer<DropTargetContent> = (preContent, xml, context) => {
  const mrow = xml.findChildTag($MROW);
  const configuration = mrow.findChildTag(CONFIGURATION);
  // severity is always provided on the target,
  // in case it contains an item needs to be applied to rendering
  const {
    severity,
    object: { borderColor, backgroundAlpha },
  } = parseDecoString<'borderColor' | 'backgroundAlpha'>(mrow.attribute(DECORATION));

  return {
    ...DROP_TARGET_DEFAULT_CONTENT,
    ...preContent,
    ...configuration.tagsToProps(xmlTextToInt, [], ['targetGroup']),
    ...(backgroundAlpha && { backgroundAlpha: parseFloat(backgroundAlpha) }),
    ...(borderColor && { borderColor }),
    ...importItem(preContent, mrow, context),
    ...(severity && { severity }),
    style: parseDragAndDropStyle(configuration.findChildTag('style').text.trim()),
  };
};
